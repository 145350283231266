import './validate-reply-box.component.scss';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import {
  API,
  Campaign,
  Account,
  Outbox,
  User,
  UserModel
} from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Colleague } from '../../../../../common/services/api';
import { OutboxTagsService } from '../../../../../common/services/api/outbox-tags';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { ValidatePostsDissaproveComponent } from '../../validate-posts/validate-posts-box/validate-posts-dissaprove/validate-posts-dissaprove.component';

export enum OutboxValidationStatus {
  Approved = 'approved',
  Disapproved = 'disapproved'
}
@Component({
  selector: 'ssi-validate-reply-box',
  templateUrl: './validate-reply-box.component.html',
  styles: []
})
export class ValidateReplyBoxComponent implements OnInit, OnChanges {
  @Input() validation: Outbox;
  @Input() selected: Array<Outbox>;
  @Input() accounts: Account[];
  @Input() colleagues: Colleague[];
  @Input() validated: any;
  @Input() selectMode = false;

  @Output() postValidated = new EventEmitter<Outbox>();
  @Output() addToSelected = new EventEmitter<Outbox>();
  editMode = false;
  canValidate = false;
  authUser: User;

  pipe = '&vert;';
  validationStatus: OutboxValidationStatus = null;
  OutboxValidationStatus = OutboxValidationStatus;
  scheduledDate: string;
  parentCampaign: Campaign;
  account: Account;
  user: Colleague;
  disapprovalTags: { label: string; value: string }[];
  hasValidationTagging: boolean;
  inboxLink: {
    mode?: string;
    conversation?: string;
    activity?: string;
    reply?: string;
  } = {};

  constructor(
    public modal: NgbModal,
    private api: API,
    protected outboxTagsService: OutboxTagsService,
    protected compayService: CompanyService,
    protected userModel: UserModel
  ) {}

  async ngOnInit() {
    this.authUser = await this.userModel.getAuthUser();

    this.canValidate =
      this.authUser.hasAccountPermission(
        this.validation.account.id,
        'validate_account_posts'
      ) || this.authUser.hasCompanyPermission('validate_posts');
    if (this.validation.conversation_link) {
      const linkParams = this.validation.conversation_link.split('/#/')[1];
      this.inboxLink.mode = linkParams.split('?')[0];
      if (linkParams.includes('conversation=')) {
        this.inboxLink.conversation = linkParams
          .split('conversation=')[1]
          .split('&')[0];
      }
      if (linkParams.includes('activity=')) {
        this.inboxLink.activity = linkParams
          .split('activity=')[1]
          .split('&')[0];
      }
    }

    try {
      if (!Array.isArray(this.accounts)) {
        throw new Error(
          `Value for 'validate reply box accounts' not in expected format.`
        );
      }

      if (!Array.isArray(this.colleagues)) {
        throw new Error(
          `Value for 'validate reply box colleagues' not in expected format.`
        );
      }

      this.scheduledDate = moment(this.validation.send_at).format(
        'D MMM YY H:mm'
      );
      this.account = this.accounts.find((account) => {
        const accountId = this.validation.account_id;
        return accountId === account.id;
      });
      this.user = this.colleagues.find((user) => {
        const userId = this.validation.user_id;
        return user.id === userId;
      });
      this.hasValidationTagging = await this.compayService.hasFeatureAccess(
        'OUTBOX_VALIDATION_TAGGING'
      );
      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  ngOnChanges(changes) {
    if (
      this.validated &&
      Object.values(OutboxValidationStatus).includes(this.validated)
    ) {
      this.validationStatus = this.validated;
    }
  }

  public async validatePost(approved) {
    const validateRequest = {
      id: this.validation.id,
      account_id: this.validation.account_id,
      approved,
      seq: this.validation.seq,
      delete: false
    };
    let result;

    if (!approved) {
      const validationTags = await this.outboxTagsService.getValidationTags();
      this.disapprovalTags = validationTags.map((tag) => {
        return {
          label: tag.name,
          value: tag.name
        };
      });

      const modal = this.modal.open(ValidatePostsDissaproveComponent);
      result = await modal.result;
      validateRequest['validator_note'] = result.reason;
      validateRequest['email_only'] = false;
    }
    if (result !== 'cancel') {
      await this.api
        .post('outbox_v2/validation', validateRequest)
        .then((res) => {
          if (res.status === 200) {
            // const { data } = res;
            // this.postValidated.emit(data);
            this.validationStatus = approved
              ? OutboxValidationStatus.Approved
              : OutboxValidationStatus.Disapproved;
            this.validation['validated'] = this.validationStatus;
          }
        });
    }
  }

  getSelectedStatus() {
    return this.selected.includes(this.validation);
  }
}
