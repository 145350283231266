<div *ngIf="!messageSessionInvalid"
     #replyBox
     class="reply-box"
     [class.thin-border]="inboxApproval"
     [class.no-border]="noBorder"
     [class.active]="isActive"
     [class.expanded]="expanded"
     [class.unfinished]="!isActive && (this.replyText || attachmentsCount)"
     [class.inactive]="!isActive && !this.replyText"
     (ssiOffClick)="!firstCommentScheduling && collapseReply()">
  <div class="reply-box-above">
    <ng-container *ngIf="conversation">
      <div *ngIf="conversation['usersViewingNow']?.length"
           class="viewing viewing-conversation"
           [ssiTooltip]
           [tooltipTemplate]="conversation['usersViewingNow'].length > 1 ? multipleViewingTooltipTemplate : viewingTooltipTemplate"
           [tooltipTemplateContext]="{conversation: conversation}"
           [tooltipWindowClass]="conversation['usersViewingNow'].length > 1 ? 'tooltip-chunky-basic' : 'tooltip-chunky'">
        <i class="ssi ssi-eye"></i>
        <div class="count-badge"
             *ngIf="conversation['usersViewingNow'].length > 1">
          <span>{{conversation['usersViewingNow'].length}}</span>
        </div>
      </div>
    </ng-container>


    <div class="viewing"
         *ngIf="activity['usersViewingNow']?.length"
         [ssiTooltip]
         [tooltipTemplate]="activity['usersViewingNow'].length > 1 ? multipleViewingTooltipTemplate : viewingTooltipTemplate"
         [tooltipTemplateContext]="{activity: activity}"
         [tooltipWindowClass]="activity['usersViewingNow'].length > 1 ? 'tooltip-chunky-basic' : 'tooltip-chunky'">
      <i class="ssi ssi-eye"></i>
      <div class="count-badge"
           *ngIf="activity['usersViewingNow'].length > 1">
        <span>{{activity['usersViewingNow'].length}}</span>
      </div>
    </div>

    <div class="typing"
         *ngIf="activity['usersTypingNow']?.length"
         [ssiTooltip]
         [tooltipTemplate]="activity['usersTypingNow'].length > 1 ? multipleTypingTooltipTemplate : undefined"
         [tooltipTemplateContext]="{activity: activity}"
         [tooltipWindowClass]="'tooltip-chunky-basic'">
      <div class="avatar">
        <img *ngIf="activity['usersTypingNow'].length === 1"
             [imageSrc]="activity['usersTypingNow'][0].avatar_url"
             ssiFallbackImage="avatar">
        <i *ngIf="activity['usersTypingNow'].length > 1"
           class="ssi ssi-teams"></i>
      </div>
      <div class="text">
        <span *ngIf="activity['usersTypingNow'].length === 1">{{activity['usersTypingNow'][0].first_name}} {{ 'IS_TYPING' | translate }}</span>
        <span *ngIf="activity['usersTypingNow'].length > 1">{{ 'AGENTS_TYPING' | translate }}</span>
      </div>
      <ssi-wave-dots class="dots"></ssi-wave-dots>
    </div>

    <ssi-countdown *ngIf="isTimedSession && !messageSessionInvalid"
                   class="countdown"
                   [class.end-right]="activity['usersViewingNow']?.length || activity['usersTypingNow']?.length"
                   [startDate]="sessionCreatedAt"
                   [daysToCountdown]="daysToCountdown"
                   (onSessionValidityChange)="sessionValidityChange($event)"
                   [ssiTooltip]
                   [tooltipTemplate]="sessionExplanationTooltipTemplate"
                   [tooltipWindowClass]="'tooltip-chunky-big'"
                   tooltipVariant="dark"></ssi-countdown>

    <div class="single-reply-warning"
         *ngIf="!messageSessionInvalid && activityThread?.thread.current.interaction.social_type === 'review_trackers'">
      <i class="ssi ssi-warning"></i>
      <p>ReviewTrackers limits your response to <strong>one reply only</strong>
      </p>
    </div>
  </div>
  <div *ngIf="isActive && !firstCommentScheduling"
       [ngClass]="'reply-top-'+layout"
       class="reply-top">
    <div class="reply-top-left">
      <div class="reply-from">
        <span>{{(isPrivateThread ? 'PRIVATE_REPLY_FROM' : 'PUBLIC_REPLY_FROM') | translate}}</span>
        <span *ngIf="!networkReplyOptions.changeResponseAccount || isPrivateThread || firstCommentScheduling">@{{replyAccount.displayName}}</span>
        <ssi-dropdown-select-2 *ngIf="networkReplyOptions.changeResponseAccount && !isPrivateThread && !firstCommentScheduling"
                               size="sm"
                               headButtonStyleVariant="inbox"
                               [options]="replyAccounts"
                               [xOffset]="'-110px'"
                               [filterable]="true"
                               [filterPlaceholder]="'SEARCH_ACCOUNT_NAME' | translate"
                               [(ngModel)]="replyAccount"
                               (ngModelChange)="changeReplyAccount($event)">
          <ng-template ssiTemplateSelector="headButtonLabel"
                       let-expanded="expanded">
            <span>@{{replyAccount.displayName}}</span>
          </ng-template>

          <ng-template ssiTemplateSelector="optionIcon"
                       let-account="option">
            <img [imageSrc]="account.picture"
                 ssiFallbackImage="avatar">
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>

    <div class="reply-top-right">
      <div class="reply-to"
           *ngIf="replyingToVisible && !isSocialWall">
        <span>{{'REPLYING_TO_' | translate}}</span>
        <span *ngIf="includableReplyToUsers.length === 0">@{{activity.author.name}}</span>
        <ssi-dropdown-select-2 *ngIf="includableReplyToUsers.length > 0"
                               size="sm"
                               headButtonStyleVariant="inbox"
                               [options]="includableReplyToUsers"
                               [multiple]="true"
                               [xOffset]="'-80px'"
                               [toggleAllLabel]="'INCLUDE_ALL_OTHERS' | translate"
                               [headButtonIcon]="'ssi-edit'"
                               [(ngModel)]="includedReplyToUsers"
                               (ngModelChange)="updateReplyToUsers($event)">
          <ng-template ssiTemplateSelector="headButtonLabel"
                       let-expanded="expanded">
            <span *ngIf="includedReplyToUsers.length === 0">@{{activity.author.name}}</span>
            <span *ngIf="includedReplyToUsers.length > 0">@{{activity.author.name}} &nbsp; +{{includedReplyToUsers.length}} &nbsp;{{'MORE' | translate}}</span>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>

      <button *ngIf="shouldShowTranslate"
              (click)="translate()"
              class="translate-reply"
              [disabled]="translated">
        <i class="ssi ssi-translate"></i>
        <span>{{ translated ? 'Reply translated' : 'Translate reply' }}</span>
      </button>

      <div *ngIf="isActive && !hideArrowShortcuts && !isSocialWall && !firstCommentScheduling"
           class="keyboard-shortcuts-container"
           ngbDropdown
           #myDrop="ngbDropdown"
           placement="top-right">

        <i class="keyboard-icon ssi ssi-keyboard-correct"
           id="dropdownManual"
           ngbDropdownAnchor
           (focus)="myDrop.open()"></i>

        <i class="keyboard-icon keyboard-icon-absolute ssi ssi-keyboard-correct"
           (click)="$event.stopPropagation(); myDrop.open();"
           [ssiTooltip]="'KEYBOARD_SHORTCUTS' | translate"
           tooltipPlacement="top"
           [tooltipAppendToBody]="true"
           [tooltipWindowClass]="'tooltip-chunky'"></i>

        <ul class="keyboard-shortcuts-list"
            ngbDropdownMenu
            aria-labelledby="dropdownManual">
          <li class="shortcuts-heading">
            <span translate="INBOX_SHORTCUTS"></span>
          </li>

          <li ngbDropdownItem
              class="dropdown-list-item">
            <span translate="PREVIOUS_MESSAGE"></span>
            <span class="character-key">
              <i class="ssi ssi-arrow-up"></i>
            </span>
          </li>

          <li class="dropdown-list-item"
              ngbDropdownItem
              *ngIf="!hideArrowShortcuts">
            <span translate="NEXT_MESSAGE"></span>
            <span class="character-key">
              <i class="ssi ssi-arrow-down-correct"></i>
            </span>
          </li>
          <li class="dropdown-list-item"
              ngbDropdownItem
              *ngIf="!hideArrowShortcuts">
            <span translate="AMEND_SENTIMENT"></span>
            <span class="character-key">
              S
            </span>
          </li>

          <li class="dropdown-list-item"
              ngbDropdownItem
              *ngFor="let shortcut of keyboardShortcuts">
            {{ shortcut.description | translate }}
            <span class="character-key">{{ shortcut.character }}</span>
          </li>
          <li ngbDropdownItem
              class="dropdown-list-item">
            <span translate="USE_A_SNIPPET"></span>
            <span class="character-key">/</span>
          </li>
        </ul>
      </div>

      <div *ngIf="isActive && !inboxApproval"
           class="expand-icon-container">
        <ssi-icon *ngIf="isActive"
                  [ssiTooltip]="expanded ? 'Collapse reply box' : 'Expand reply box'"
                  tooltipWindowClass="tooltip-chunky"
                  class="toggle-expand"
                  [icon]="expanded ? 'minimize' : 'expand'"
                  height="18"
                  width="18"
                  (click)="toggleReplyView()"></ssi-icon>
      </div>
    </div>
  </div>
  <div class="attachments-bar"
       *ngIf="isActive && attachments">
    <ssi-label-tag [tagValue]="attachments"
                   (onRemoveTag)="removeAttachment()"></ssi-label-tag>
  </div>

  <div class="textarea-emoji-container"
       ssiEmojiFormControlContainer
       [(formControlValue)]="replyText">
    <textarea #textarea
              [ssiFocusOn]="focusReply"
              (focus)="textareaFocus()"
              (blur)="textareaBlur()"
              (paste)="paste($event)"
              (ngModelChange)="updateReplyTextValue($event)"
              [(ngModel)]="replyText"
              (keydown)="keyDown($event)"
              (keyup)="keyUp($event)"
              name="text"
              [placeholder]="placeholder || (isActive ? ('TYPE_SLASH_TO_ACCESS_TIMESAVING_SNIPPETS' | translate) : ('TYPE_AWAY_HERE' | translate)) + '...'"
              ssiActivitySnippets
              (onSnippetSelected)="updateReplyTextValue($event)"
              ssiEmojiFormControl></textarea>

    <ssi-emoji-autocomplete [textInputElement]="textarea"
                            [menuOffsetTop]="15"></ssi-emoji-autocomplete>

    <div *ngIf="isPredictive && prediction"
         class="prediction-container"
         tabindex="-1">
      <span #predictionElement
            [innerText]="prediction"
            class="prediction">
      </span>
    </div>

    <div class="reply-actions">
      <div class="actions-left"
           *ngIf="isActive">
        <button *ngIf="shouldShowPushToCrm"
                class="add-to-crm-action g-btn round-icon-only-button ssi ssi-add-to-crm"
                [ngStyle]="{ display: layout !== ReplyBoxLayout.Mobile ? 'block' : 'none' }"
                (click)="pushToCrm()"
                [ssiTooltip]="'PUSH_MESSAGES_TO_CRM' | translate"
                [tooltipWindowClass]="'tooltip-chunky'"></button>

        <ng-container *ngIf="supportsAddReplyLink && !isPrivateThread">
          <button *ngIf="!addPrivateReplyLinkToggled"
                  class="g-btn round-icon-only-button ssi ssi-dm-link"
                  (click)="addPrivateReplyLink()"
                  [ssiTooltip]="'ADD_DM_REPLY_LINK' | translate"
                  [tooltipWindowClass]="'tooltip-chunky'"></button>

          <button *ngIf="addPrivateReplyLinkToggled"
                  type="button"
                  class="g-btn border-radius-100 dm-reply-link-added-button"
                  (click)="addPrivateReplyLink()"
                  [ssiTooltip]="'DM_REPLY_LINK_ADDED' | translate"
                  [tooltipWindowClass]="'tooltip-chunky'"><span>{{'DM_REPLY_LINK' | translate}}</span><i class="ssi ssi-times"></i></button>
        </ng-container>

        <ng-container *ngIf="conversation">
          <!-- button -->
          <ssi-conversation-hold *ngIf="!conversation.resolved"
                                 [conversation]="conversation"
                                 [canReply]="canReply()"
                                 [menuPlacementY]="'bottom'"
                                 (onHoldChange)="holdConversationStatusChange($event)"></ssi-conversation-hold>

          <!-- button -->
          <ssi-conversation-resolve [conversation]="conversation"
                                    [activityThread]="activityThread"
                                    [replyAccount]="replyAccount"
                                    [isPrivateThread]="isPrivateThread"
                                    [sessionInvalid]="messageSessionInvalid"
                                    [canReply]="canReply()"
                                    [menuPlacementY]="'bottom'"
                                    (onResolve)="resolve($event)"></ssi-conversation-resolve>
        </ng-container>
      </div>
      <ul class="actions-right">
        <li class="chars-remaining"
            [class.limit-exceeded]="wordsRemaining < 0"
            *ngIf="isActive">
          {{ wordsRemaining }}
        </li>

        <li *ngIf="addPrivateReplyLinkToggled && accountTypeName === 'Twitter'">
          <a class="text-royal"
             href="https://business.twitter.com/en/help/campaign-editing-and-optimization/public-to-private-conversation.html"
             target="_blank"
             [ssiTooltip]="'MOVING_FROM_A_PUBLIC_TWEET_TO_A_PRIVATE_CONVERSATION' | translate"
             tooltipPlacement="top"
             [tooltipAppendToBody]="true"
             [tooltipWindowClass]="'tooltip-chunky'">
            <i class="ssi ssi-link"></i>
          </a>
        </li>

        <ssi-emoji-form-control-trigger popoverPlacement="top">
          <a href="javascript:;"
             *ngIf="!firstCommentScheduling">
            <i class="ssi ssi-emoji"></i>
          </a>
          <a href="javascript:;"
             *ngIf="firstCommentScheduling">
            <img src="https://www.orlo.app/app/web/production/24e0c22c807519b4db73d58713d628b1.png"
                 alt="Open emoji picker icon"
                 class="media-icon">
            <span class="a11y-label">Open emoji picker</span>
          </a>
        </ssi-emoji-form-control-trigger>

        <li *ngIf="!!mediaRestrictions.gif && ((!isPrivateThread && networkReplyOptions.responseMedia?.image.public) || (isPrivateThread && networkReplyOptions.responseMedia?.image.private))"
            ssiGifSearch
            (onGifFileUploaded)="gifUploadSuccess($event)"
            [ssiTooltip]="(!!attachments ? 'REPLACE_ATTACHMENT_WITH_GIF_FROM_GIPHY' : 'SELECT_A_GIF_FROM_GIPHY') | translate"
            tooltipPlacement="top"
            [tooltipAppendToBody]="true"
            [tooltipWindowClass]="'tooltip-chunky'">
          <i class="ssi ssi-gif"
             *ngIf="!firstCommentScheduling"></i>
          <img src="https://www.orlo.app/app/web/production/af6ebf3feaef8a21f3e4bfc03cb18391.svg"
               alt="Open Giphy picker - icon"
               class="media-icon"
               *ngIf="firstCommentScheduling">
          <span class="a11y-label">{{'SELECT_A_GIF_FROM_GIPHY' | translate}}</span>
        </li>

        <li *ngIf="!!mediaRestrictions.image && ((!isPrivateThread && networkReplyOptions.responseMedia?.image.public) || (isPrivateThread && networkReplyOptions.responseMedia?.image.private))"
            ssiFileUploader
            [accept]="mediaRestrictions.image.mimetypes"
            [restrictions]="mediaRestrictions"
            (uploadStart)="toggleMediaUpload(true)"
            (uploadFinally)="toggleMediaUpload(false)"
            (uploadSuccess)="fileUploadSuccess($event)"
            (uploadError)="fileUploadError($event)"
            [ssiTooltip]="(!!attachments ? 'REPLACE_ATTACHMENT_WITH_A_JPEG_OR_GIF' : 'UPLOAD_A_JPEG_OR_GIF') | translate"
            tooltipPlacement="top"
            [tooltipAppendToBody]="true"
            [tooltipWindowClass]="'tooltip-chunky'">
          <i class="ssi {{firstCommentScheduling ? 'ssi-attach-small' : 'ssi-image'}}"
             [class.media-icon]="firstCommentScheduling"
             [class.has-attachments]="!!attachments">
            <div *ngIf="attachmentsCount"
                 class="attachments-count">{{attachmentsCount}}</div>
          </i>
        </li>

        <li class="send-reply"
            *ngIf="!firstCommentScheduling">
          <button class="ssi ssi-send-icon"
                  (click)="sendReply(replyText)"
                  [disabled]="!canReply()"
                  type="button"></button>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="inboxApproval"
       class="save-edit-reply">
    <div class="buttons-container">
      <button (click)="onCancel.emit()"
              type="button"
              translate="CANCEL"></button>
      <button (click)="sendReply(replyText)"
              [disabled]="!canReply() || replyInProgress"
              type="button"
              translate="SAVE_EDIT"></button>
    </div>
  </div>
</div>

<div class="reply-box invalid"
     *ngIf="messageSessionInvalid">
  <span class="session-invalid-message"
        [ssiTooltip]
        [tooltipTemplate]="sessionExplanationTooltipTemplate"
        [tooltipWindowClass]="'tooltip-chunky-big'">
    <i class="ssi ssi-session"></i>
    <span class="validity-message"
          [innerHTML]="messageSessionExplanation.validityMessage"></span>
  </span>
  <div class="session-invalid-actions">
    <button *ngIf="shouldShowPushToCrm"
            class="g-btn round-icon-only-button ssi ssi-add-to-crm"
            (click)="pushToCrm()"
            [ssiTooltip]="'PUSH_MESSAGES_TO_CRM' | translate"
            tooltipPlacement="top"
            [tooltipAppendToBody]="true"
            [tooltipWindowClass]="'tooltip-chunky'"></button>

    <!-- button -->
    <ssi-conversation-resolve *ngIf="conversation"
                              [conversation]="conversation"
                              [activityThread]="activityThread"
                              [replyAccount]="replyAccount"
                              [isPrivateThread]="isPrivateThread"
                              [sessionInvalid]="messageSessionInvalid"
                              [menuPlacementY]="'bottom'"
                              [menuPlacementX]="'right'"
                              [canReply]="canReply()"
                              (onResolve)="resolve($event)"></ssi-conversation-resolve>
  </div>
</div>


<ng-template #viewingTooltipTemplate
             let-activity="activity"
             let-conversation="conversation">
  <div *ngIf="activity"
       class="collision-tooltip-content">
    <div class="content alone">
      <span>{{activity['usersViewingNow'][0].fullName}} {{ 'IS_VIEWING' | translate }}</span>
    </div>
  </div>

  <div *ngIf="conversation"
       class="collision-tooltip-content">
    <div class="content alone">
      <span>{{conversation['usersViewingNow'][0].fullName}} is viewing conversation</span>
    </div>
  </div>

  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template>

<ng-template #multipleViewingTooltipTemplate
             let-activity="activity"
             let-conversation="conversation">
  <div *ngIf="activity"
       class="collision-tooltip-content">
    <div class="title">
      <span>{{ 'MULTIPLE_USERS_VIEWING' | translate }}<ng-container *ngIf="conversation"> this conversation</ng-container></span>
    </div>
    <div class="content">
      <span *ngIf="! conversation">{{activity.usersViewingNowNamesLabel}}</span>
      <span *ngIf="conversation">{{conversation.usersViewingNowNamesLabel}}</span>
    </div>
    <div class="icon-container">
      <i class="ssi ssi-eye"></i>
    </div>
  </div>

  <div *ngIf="conversation"
       class="collision-tooltip-content">
    <div class="title">
      <span>Multiple users viewing conversation</span>
    </div>
    <div class="content">
      <span>{{conversation.usersViewingNowNamesLabel}}</span>
    </div>
    <div class="icon-container">
      <i class="ssi ssi-eye"></i>
    </div>
  </div>

  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template>

<ng-template #multipleTypingTooltipTemplate
             let-activity="activity">
  <div class="collision-tooltip-content">
    <div class="title">
      <span>{{ 'MULTIPLE_USERS_TYPING' | translate }}</span>
    </div>
    <div class="content">
      <span>{{activity.usersTypingNowNamesLabel}}</span>
    </div>

    <div class="icon-container">
      <i class="ssi ssi-status-bubble"></i>
    </div>
  </div>

  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template>

<ng-template #sessionExplanationTooltipTemplate>
  <div class="session-tooltip-content">
    <div class="title">
      <span>{{messageSessionExplanation.title}}</span>
    </div>
    <div class="content">
      <span translate="{{messageSessionExplanation.content}}"></span>
    </div>
    <div class="icon-container">
      <i class="{{messageSessionExplanation.iconClass}}"></i>
    </div>
  </div>

  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template>