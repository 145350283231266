<div class="share-first-comment-container">

	<div class="head">
		<h3>{{outboxPost || editPostModeAndHasAutoComment() ? 'Edit first comment' : 'Schedule your first comment for this post, you have time to edit until it goes live' }}</h3>

		<div class="selected-accounts-container">
			<div *ngIf="outboxPost"
				 class="account-icons">
				<ssi-social-network-icon size="20"
										 [accountTypeId]="outboxPost.account.account_type_id"></ssi-social-network-icon>
			</div>
			<div *ngIf="!outboxPost && composer"
				 class="account-icons">
				<ssi-social-network-icon *ngFor="let account of composer.accounts"
										 size="20"
										 [accountTypeId]="account.account_type_id"></ssi-social-network-icon>
				<div class="label-container">
					<span *ngIf="composer.socialNetworks.length === 1">{{composer.socialNetworks[0].config.accountTypeLabel}}</span>
					<span>First comment</span>
					<span *ngIf="composer.accounts.length > 1">(Multiple accounts)</span>
				</div>
			</div>
		</div>
	</div>

	<div class="body">
		<ssi-reply-box (onKeyUp)="onKeyUp($event)"
					   (onKeyDown)="onKeyDown($event)"
					   (onUpdateReplyTextValue)="onUpdateReplyText($event)"
					   (onFileUploadSuccess)="onFileUploadSuccess($event)"
					   (onRemoveAttachment)="onRemoveAttachment()"
					   (onAddPrivateReplyLink)="addPrivateReplyLink()"
					   (onTranslate)="translateReplyText()"
					   [isInbox]="false"
					   [isSocialWall]="false"
					   [noBorder]="true"
					   [firstCommentScheduling]="true"
					   [getMaxCharsCount]="getMaxCharsCount"
					   [placeholder]="'Type away or leave this text blank if you don\'t want to share a first comment...'"
					   [replyValue]="reply.text"
					   [prediction]="prediction"
					   [activity]="{interaction: {}, conversation: {}}"
					   [isDisabled]="false"
					   [accountTypeName]="reply.account.account_type_name"
					   [mediaRestrictions]="getMediaRestrictions()"
					   [replyInProgress]="reply.tracker.active"
					   [shouldShowPushToCrm]="false"
					   [replyAccounts]="[reply.account]"
					   [replyAccount]="reply.account"
					   [includableReplyToUsers]="reply.users"
					   [replyingToVisible]="false"
					   [networkReplyOptions]="getnetworkReplyOptions()"
					   [shouldShowTranslate]="!!translation"
					   [attachments]="reply.file"
					   [isTimedSession]="false"
					   [supportsAddReplyLink]="isSplit ? splitPostAccount.socialNetwork.getPrivateReplyLink : false"
					   [focusReply]="!!prepopulateReply"
					   [hideArrowShortcuts]="true"
					   [isPushMode]="false"></ssi-reply-box>

		<div class="account-controls"
			 *ngIf="composer.accounts.length > 1"
			 [class.active]="false">
			<button class="acc-control-button"
					*ngIf="!isSplit"
					(click)="setIsSplit(true)"
					[ssiTooltip]="'Split your post to have full network functionality for each channel selected'"
					tooltipTitle="Split posts"
					[tooltipWindowClass]="'tooltip-chunky-big'">
				<i class="ssi ssi-split-post"></i><span>Split posts</span>
			</button>

			<ng-container *ngIf="isSplit">
				<button class="acc-control-button"
						(click)="setIsSplit(false)"
						[ssiTooltip]="'This auto selects the post with the least amount of characters due to network limits'"
						[tooltipWindowClass]="'tooltip-chunky-big'"
						tooltipTitle="Combine posts"><i class="ssi ssi-combine-post"></i><span>Combine</span></button>

				<span *ngFor="let network of composer.socialNetworks">
					<ssi-menu #networkGroupMenu
							  *ngIf="network.accounts.length > 1"
							  class="dashboard-templates-menu"
							  [headAlignment]="'top left'"
							  [bodyAlignment]="'bottom left'">

						<ng-template ssiTemplateSelector="menuHead">
							<button class="acc-control-button"
									[ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
									[tooltipOffset]="[0, 20]"
									[tooltipWindowClass]="'tooltip-chunky-big'"
									[tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image.min || network.config.mediaRestrictions.post.gif.min || network.config.mediaRestrictions.post.video.min)"
									[class.active]="splitPostAccount.socialNetwork === network.config"
									[class.split-post]="splitPostAccount.socialNetwork !== network.config">
								<ssi-social-network-icon class="network-icon"
														 [accountTypeId]="network.config.accountTypeId"
														 [size]="16"></ssi-social-network-icon>
								<span *ngIf="splitPostAccount.socialNetwork === network.config">{{splitPostAccount.displayName | truncate:14 }}</span>
								<span class="caret"></span>
							</button>
						</ng-template>

						<ng-template ssiTemplateSelector="menuBody">
							<ul class="g-list network-accounts-list">
								<li class="g-list-item"
									*ngFor="let account of network.accounts"
									[class.active]="splitPostAccount === account"
									(click)="networkGroupMenu.close(); setSplitPostAccount(account)">
									<ssi-social-network-icon class="g-list-item-icon"
															 [accountTypeId]="network.config.accountTypeId"
															 [size]="16"></ssi-social-network-icon>
									<span>{{ account.displayName }}</span>
								</li>
							</ul>
						</ng-template>
					</ssi-menu>

					<button *ngIf="network.accounts.length === 1"
							class="acc-control-button network-select"
							[ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
							[tooltipOffset]="[0, 20]"
							[tooltipWindowClass]="'tooltip-chunky-big'"
							[tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image.min || network.config.mediaRestrictions.post.gif.min || network.config.mediaRestrictions.post.video.min)"
							[class.active]="splitPostAccount.socialNetwork === network.config"
							[class.split-post]="splitPostAccount.socialNetwork !== network.config"
							(click)="setSplitPostAccount(network.accounts[0])">
						<ssi-social-network-icon class="network-icon"
												 [accountTypeId]="network.config.accountTypeId"
												 [size]="16"></ssi-social-network-icon>
					</button>
				</span>
			</ng-container>

		</div>

	</div>

	<!-- <div class="foot g-box-shadow-top">
		<button class="g-btn g-btn-secondary bg-white delete-button"
				type="button"
				*ngIf="outboxPost || editPostModeAndHasAutoComment()"
				(click)="deleteMenu.show($event);"><i class="ssi ssi-small-delete"></i><span>Delete first comment</span>
		</button>

		<button class="g-btn g-btn-secondary bg-white cancel-button"
				type="button"
				*ngIf="editPostModeAndHasAutoComment()"
				(click)="activeModal.dismiss()"><span>{{'Cancel'}}</span></button>

		<button class="g-btn g-btn-secondary bg-white cancel-button"
				type="button"
				*ngIf="!editPostModeAndHasAutoComment()"
				(click)="activeModal.close()"><span>{{outboxPost ? 'Cancel' : 'Skip for this account'}}</span></button>

		<button class="g-btn submit-button"
				type="submit"
				(click)="replyBox.sendReply(replyBox.replyText)"
				[disabled]="!canPublish()"><span>{{outboxPost || editPostModeAndHasAutoComment() ? 'Edit first comment' : 'Schedule'}}</span></button>
	</div> -->
</div>