import './engagement-report.component.scss';
import {
  Component,
  Input,
  OnInit,
  HostListener,
  ElementRef,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { Location } from '@angular/common';
import { StateService } from '@uirouter/angular';
import { EngagementAnalyticsService } from '../engagement.service';
import { AnalyticsService } from '../../analytics.service';
import moment from 'moment';
import { Account, AccountModel } from '@ui-resources-angular';
import {
  commonDateRanges,
  compareToOptions
} from '../../../../../common/constants';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import { SpreadsheetService } from '../../../../../common/services/spreadsheet/spreadsheet.service';
import { FileMimeTypes } from '../../../../../common/directives/file-download/file-download.directive';
import { FileDownloadService } from '../../../../../common/services/file-download/file-download.service';
import { visibilityOptions } from '../../common/report-build-from/report-build-from.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../../../../../common/components/confirmation-modal/confirmation-modal.component';
import { Debounce } from '../../../../../common/decorators';
import { isElementWithinViewport } from '../../../../../common/utils';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import {
  ColleaguesService,
  ReportAutomationFrequency
} from '../../../../../common/services/api';
import { isEmptyObj } from '../../../../../common/util';
import { schedulesAutomatedReportsFeatureResolveFn } from '../../common/resolves/analytics-resolves';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { AutomateModalComponent } from '../../../../../common/components/automate-modal/automate-modal.component';

@Component({
  selector: 'ssi-engagement-report',
  templateUrl: './engagement-report.component.html',
  styles: []
})
export class EngagementReportComponent implements OnInit, OnDestroy {
  static resolve = [
    {
      token: 'schedulesAutomatedReportsFeature',
      resolveFn: schedulesAutomatedReportsFeatureResolveFn,
      deps: [CompanyService]
    }
  ];
  @Input() schedulesAutomatedReportsFeature = false;

  navList = [
    {
      label: 'Account Summary',
      tag: 'account-summary'
    },
    {
      label: 'Inbox Activity',
      tag: 'inbox-activity'
    },
    {
      label: 'Inbox Sentiment',
      tag: 'inbox-sentiment'
    },
    {
      label: 'Engagement Times',
      tag: 'engagement-times'
    },
    {
      label: 'Tags',
      tag: 'tags'
    },
    {
      label: 'Account Response Times',
      tag: 'response-times'
    },
    {
      label: 'Team Performance',
      tag: 'team-performance'
    },
    {
      label: 'User Performance',
      tag: 'user-performance'
    }
  ];
  reportActions;
  navItems = [];
  activeNav = 'account-summary';

  fileExport;

  isLoading = true;

  report: any = {
    stats: {
      activity: {
        period: {}
      },
      conversation: {
        period: {}
      }
    },
    filter: {
      tags: {
        include: [],
        exclude: []
      },
      visibility: 'all',
      accounts: [],
      range: {
        start: moment().subtract(1, 'month').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        compareTo: {
          comparator: 'previous_period',
          start: undefined,
          end: undefined
        }
      }
    }
  };

  accountIds: string[] = [];
  accounts: Account[] = [];

  onDestroy = new Subject();
  private _workFlowChangedSubscription: Subscription;

  constructor(
    protected renderer: Renderer2,
    protected state: StateService,
    public engagementAnalyticsService: EngagementAnalyticsService,
    private accountModel: AccountModel,
    private workflowManager: WorkflowManagerService,
    private spreadsheet: SpreadsheetService,
    private modal: NgbModal,
    private analyticsService: AnalyticsService,
    private colleaguesService: ColleaguesService,
    private fileDownload: FileDownloadService,
    protected elementRef: ElementRef,
    private location: Location
  ) {}

  async ngOnInit() {
    this._workFlowChangedSubscription = this.workflowManager.events.workflowChanged
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() =>
        this.state.go('auth.analytics.engagement.report', this.state.params)
      );

    if (this.state.params.accountIds) {
      const accountIds = Array.isArray(this.state.params.accountIds)
        ? this.state.params.accountIds
        : [this.state.params.accountIds];

      this.report.filter.accounts = accountIds.map((accountId) =>
        this.accountModel.get(accountId)
      );

      // date range...
      if (this.state.params.frequency) {
        // override report dates - frequency is set for automated reports (headless browser running on the server)
        const period =
          this.state.params.frequency === ReportAutomationFrequency.WEEKLY
            ? 'week'
            : 'month';

        const dateRange = commonDateRanges[period];
        this.state.params.startDate = dateRange.start.toISOString();
        this.state.params.endDate = dateRange.end.toISOString();

        const compareToOption = compareToOptions[this.state.params.compareTo];
        if (
          compareToOption &&
          compareToOption !== compareToOptions.custom &&
          this.state.params.previousStartDate &&
          this.state.params.previousEndDate
        ) {
          const compareToDateRange = compareToOption.getCompareToDates(
            new Date(this.state.params.startDate),
            new Date(this.state.params.endDate)
          );
          this.state.params.previousStartDate = compareToDateRange.start.toISOString();
          this.state.params.previousEndDate = compareToDateRange.end.toISOString();
        }

        this.state.params.frequency = undefined;
        this.state.go(this.state.current, this.state.params, { notify: false });
      }

      if (this.state.params.startDate) {
        this.report.filter.range.start = new Date(this.state.params.startDate);
      }
      if (this.state.params.endDate) {
        this.report.filter.range.end = new Date(this.state.params.endDate);
      }
      if (compareToOptions[this.state.params.compareTo]) {
        this.report.filter.range.compareTo.comparator = this.state.params.compareTo;
      }
      if (this.state.params.previousStartDate) {
        this.report.filter.range.compareTo.start = new Date(
          this.state.params.previousStartDate
        );
      }
      if (this.state.params.previousEndDate) {
        this.report.filter.range.compareTo.end = new Date(
          this.state.params.previousEndDate
        );
      }

      // visibility and tags...
      if (visibilityOptions[this.state.params.visibility]) {
        this.report.filter.visibility = this.state.params.visibility;
      }
      if (this.state.params.tagIdsToInclude) {
        this.report.filter.tags.include = Array.isArray(
          this.state.params.tagIdsToInclude
        )
          ? this.state.params.tagIdsToInclude
          : [this.state.params.tagIdsToInclude];
      }
      if (this.state.params.tagIdsToExclude) {
        this.report.filter.tags.exclude = Array.isArray(
          this.state.params.tagIdsToExclude
        )
          ? this.state.params.tagIdsToExclude
          : [this.state.params.tagIdsToExclude];
      }

      if (this.state.params.excludeImportDelay) {
        this.report.filter.excludeImportDelay = this.state.params.excludeImportDelay;
      }
    }

    const includeComparison =
      this.state.params.compareTo !== compareToOptions.noComparison.id;
    this.engagementAnalyticsService
      .buildReport(this.report, { withPrevious: includeComparison })
      .then(() => {
        this.isLoading = false;
        this.fileExport = this.readyXlsxExport(this.report);
        this.navItems.push(...this.getNavList(this.navList));
        this.populateNavItemElements();
      })
      .catch(async (error) => {
        console.error(error);
        const errorModal = this.modal.open(ConfirmationModalComponent, {
          windowClass: 'orlo-modal',
          backdrop: 'static'
        });
        errorModal.componentInstance.icon = 'ssi ssi-fail-microcopy';
        errorModal.componentInstance.title = 'Oops! Something’s up';
        errorModal.componentInstance.info = error;
        errorModal.componentInstance.confirmButton = `Back to build page`;
        if (await errorModal.result) {
          this.state.go('auth.analytics.engagement.build', this.state.params);
        }
      });

    if (this.state.params.disablePopup) {
      this.renderer.addClass(window.document.body, 'hide-pendo-popup');
    }

    this.reportActions = this._getReportActions();
  }
  @HostListener('window:scroll', [])
  @Debounce(40, false)
  onscroll() {
    this.setActiveNavItem();
  }

  reportDateSelected(event) {
    this.isLoading = true;
    this.report.filter.range.start = new Date(event.from);
    this.report.filter.range.end = new Date(event.to);
    this.report.filter.range.compareTo.start = '';
    this.report.filter.range.compareTo.end = '';
    this.engagementAnalyticsService.buildReport(this.report).then(() => {
      this.analyticsService.updateUrlParams({
        startDate: new Date(event.from),
        endDate: new Date(event.to)
      });
      this.isLoading = false;
      this.fileExport = this.readyXlsxExport(this.report);
    });
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNav = item.tag;
        }
      }
    });
  }

  private _getReportActions() {
    console.log('test: ', this.schedulesAutomatedReportsFeature);
    return [
      {
        icon: 'ssi-print',
        label: 'Export PDF',
        func: () => {
          if (this.isLoading) {
            console.log('still loading');
            return;
          }
          this.openBrowserPrint();
        }
      },
      {
        icon: 'ssi-print',
        label: 'Download XLSX',
        func: async () => {
          if (this.isLoading) {
            console.log('still loading');
            return;
          }
          const config = {
            base64decode: true,
            filename: `Orlo - Inbox report.xlsx`,
            mimetype: FileMimeTypes.Xlsx
          };
          this.fileDownload.download(await this.fileExport, config);
        }
      },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => {
          this.state.go('auth.analytics.engagement.build', this.state.params);
        }
      },
      {
        icon: 'ssi ssi-small-schedule',
        label: 'Automate report',
        featureFlag: this.schedulesAutomatedReportsFeature,
        func: () => {
          this.openAutomateReportModal();
        }
      }
    ].filter(
      (action) => !action.hasOwnProperty('featureFlag') || action.featureFlag
    );
  }

  getNavList(currentList) {
    let offset = 0;
    if (
      this.report.stats.conversation.period.current &&
      this.report.stats.conversation.period.current.sla &&
      this.report.stats.conversation.period.current.sla.length > 0
    ) {
      currentList.splice(4 + offset, 0, {
        label: 'Conversation SLAs',
        tag: 'conversation-slas'
      });
      offset++;
    }
    if (this.report.stats.conversation.period.current) {
      currentList.splice(4 + offset, 0, {
        label: 'Sentiment Change',
        tag: 'sentiment-change'
      });
      offset++;
    }
    if (this.report.stats.activity.period.current.inbound.top_authors.length) {
      currentList.splice(4 + offset, 0, {
        label: 'Top Engagers',
        tag: 'top-engagers'
      });
      offset++;
    }
    if (this.report.stats.activity.period.current.inbound.count > 0) {
      currentList.splice(4 + offset, 0, {
        label: 'Network Activity',
        tag: 'network-engagement'
      });
      offset++;
    }
    if (this.report.topUrls && this.report.topUrls.length > 0) {
      currentList.splice(7 + offset, 0, {
        label: 'Top URLs',
        tag: 'top-urls'
      });
      offset++;
    }
    if (
      this.report.stats.activity.period.current &&
      this.report.stats.activity.period.current.inbound &&
      !isEmptyObj(
        this.report.stats.activity.period.current.inbound.response_time_sla
      )
    ) {
      currentList.push({
        label: 'Response Time SLAs',
        tag: 'response-time-slas'
      });
    }

    return currentList;
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item['element'] = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });
    });
  }

  setXlsxExport(report, filteredUsers: any[]) {
    this.fileExport = this.readyXlsxExport(report, filteredUsers);
  }

  async readyXlsxExport(report, filteredUsers?) {
    if (filteredUsers) {
      report = cloneDeep(report);
      report.performance.users = filteredUsers;
    }
    const formattedReport = this.engagementAnalyticsService.createSpreadsheetExportFromReport(
      report,
      await this.colleaguesService.getAll()
    );
    return this.spreadsheet.jsonToXlsx(formattedReport).then((file) => file);
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -190;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNav = sectionId;
  }

  ngOnDestroy() {
    this.onDestroy.next();

    if (!!this._workFlowChangedSubscription) {
      this._workFlowChangedSubscription.unsubscribe();
    }
  }

  async openAutomateReportModal() {
    const automateModal = this.modal.open(AutomateModalComponent, {
      windowClass: 'orlo-modal'
    });
    automateModal.componentInstance.editMode = false;
    automateModal.componentInstance.reportUrl = this.state.href(
      this.state.current.name,
      this.state.params,
      { absolute: true }
    );
  }

  openBrowserPrint() {
    window.print();
  }
}
