import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageFooterComponent } from './message-footer.component';
import { MessageActionsModule } from '../message-actions/message-actions.module';
import { MessageActionsInlineModule } from '../message-actions-inline/message-actions-inline.module';

import { OffClickModule } from '../../../directives/off-click/off-click.module';

@NgModule({
  imports: [
    CommonModule,
    MessageActionsModule,
    MessageActionsInlineModule,
    OffClickModule
  ],
  declarations: [MessageFooterComponent],
  exports: [MessageFooterComponent]
})
export class MessageFooterModule {}
