import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { OrloComponentsModule } from 'orlo-components';
import { ActivityComponent } from './activity.component';

import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { MessageBoxModule } from '../message-box/message-box.module';
import { ActivityTextModule } from '../activity-text/activity-text.module';
import { InlineNotesModule } from '../inline-notes/inline-notes.module';
import { ReplyBoxModule } from '../reply-box/reply-box.module';
import { SecondsToHumanTimeModule } from '../../pipes/seconds-to-human-time/seconds-to-human-time.module';
import { SortByModule } from '../../pipes/sort-by/sort-by.module';
import { UniqueModuleModule } from '../../pipes/unique/unique.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { InboxAlertModalModule } from '../inbox-alert-modal/inbox-alert-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    OrloComponentsModule,
    TooltipModule,
    MessageBoxModule,
    ActivityTextModule,
    InlineNotesModule,
    ReplyBoxModule,
    SecondsToHumanTimeModule,
    UniqueModuleModule,
    SortByModule,
    HotkeyModule,
    InboxAlertModalModule
  ],
  entryComponents: [ActivityComponent],
  declarations: [ActivityComponent],
  exports: [ActivityComponent]
})
export class ActivityModule {}
