<div class="modal-header">
  <h4 class="modal-title" translate="CREATE_CHAT_BOT"></h4>
</div>

<div class="modal-body modal-chat-bot">
  <form #form="ngForm">
    <div class="form-field">
      <label for="name" translate="NAME_COLON"></label>
      <input
        id="name"
        type="text"
        class="form-control"
        name="name"
        [(ngModel)]="chatBotForm.name"
        required
      >
    </div>
    <div class="form-field">
      <label for="metabase_id" translate="METABASE_COLON"></label>
      <select
        name="metabase_id"
        id="metabase_id"
        placeholder="Choose a metabase"
        [(ngModel)]="chatBotForm.metabase_id"
        required
      >
        <option *ngFor="let metabase of metabases" value="{{metabase.id}}">
          {{metabase.name}}
        </option>
      </select>
    </div>
    <br>

    <div class="panel panel-default" *ngIf="!chatBot">
      <div class="panel-heading"><p translate="CHAT_BOT_MESSAGES"></p></div>
      <ul class="list-group">
        <li *ngFor="let message of messageFields" class="list-group-item">
          <p class="list-group-item-subheading">{{messageLabels[message]}}</p>
          <ul>
            <li class="form-field">
              <label [for]="message + 'OnEnter'" translate="ON_ENTER_COLON"></label>
              <input
                [id]="message + 'OnEnter'"
                type="text"
                class="form-control"
                [name]="message + 'OnEnter'"
                [(ngModel)]="chatBotForm.messages[message].on_enter[0].text"
                required
              >
            </li>
            <li *ngIf="!!chatBotForm.messages[message].no_topic_match" class="form-field">
              <label [for]="message + 'NoTopicMatch'" translate="NO_TOPIC_MATCH_COLON"></label>
              <input
                [id]="message + 'NoTopicMatch'"
                type="text"
                class="form-control"
                [name]="message + 'NoTopicMatch'"
                [(ngModel)]="chatBotForm.messages[message].no_topic_match[0].text"
                required
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="activeModal.close()"
    translate="CANCEL"
  >
  </button>
  <button
    *ngIf="!chatBot"
    [disabled]="form.invalid || form.pristine"
    type="button"
    class="btn btn-primary"
    (click)="activeModal.close(chatBotForm)"
    translate="CREATE_CHAT_BOT"
  >
  </button>
  <button
    *ngIf="chatBot"
    [disabled]="form.invalid || form.pristine"
    type="button"
    class="btn btn-primary"
    (click)="activeModal.close(chatBotForm)"
    translate="SAVE_CHAT_BOT"
  >
  </button>
</div>