import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBudget'
})
export class FormatBudgetPipe implements PipeTransform {
  transform(value): any {
    value = Number(value).toFixed(2);

    return value;
  }
}
