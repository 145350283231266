import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileInboxStatsComponent } from './profile-inbox-stats.component';
import { CeilModule } from '../../pipes/ceil/ceil.module';
import { CrmProfileSentimentBarModule } from './crm-profile-sentiment-bar/crm-profile-sentiment-bar.module';
import { TranslateModule } from '@ngx-translate/core';
import { BigNumberModule } from '../../pipes/big-number/big-number.module';

@NgModule({
  imports: [
    CommonModule,
    CeilModule,
    CrmProfileSentimentBarModule,
    TranslateModule,
    BigNumberModule
  ],
  declarations: [ProfileInboxStatsComponent],
  exports: [ProfileInboxStatsComponent]
})
export class ProfileInboxStatsModule {}
