import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PieChartComponent } from './pie-chart.component';
import { HighchartModule } from '../highchart/highchart.module';

@NgModule({
  imports: [CommonModule, HighchartModule],
  declarations: [PieChartComponent],
  exports: [PieChartComponent]
})
export class PieChartModule {}
