import './schedule-first-comment.component.scss';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import moment from 'moment';
import { uniqBy } from 'lodash-es';
import { format } from 'date-fns';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import {
  Account,
  AccountModel,
  AutoComment,
  Outbox,
  OutboxModel,
  OutboxPublisher,
  OutboxPublisherFile,
  OutboxPublisherMention
} from '@ui-resources-angular';
import { ApiService } from '../../services/api';
import { SocialPostCharactersRemainingPipe } from '../../pipes/social-post-characters-remaining/social-post-characters-remaining.pipe';
import { ReplyBoxComponent } from '../reply-box/reply-box.component';
import { UserPreferencesService } from '../../services/user-preferences/user-preferences.service';
import { PopupService } from '../../services/popup/popup.service';
import { CompanyService } from '../../services/company/company.service';
import { TranslationService } from '../../services/translation/translation.service';
import { WorkflowManagerService } from '../../services/workflow-manager/workflow-manager.service';
import { PredictedResponseService } from '../../services/predicted-response/predicted-response.service';
import { FilestackService } from '../../services/filestack/filestack.service';

export interface ActivityReplyUser {
  name: string;
  username: string;
  id: string;
  excluded: boolean;
}

@Component({
  selector: 'ssi-schedule-first-comment',
  templateUrl: './schedule-first-comment.component.html',
  styles: []
})
export class ScheduleFirstCommentComponent implements OnInit {
  @Input() outboxPost?: Outbox;
  @Input() account?: Account;
  @Input() publisher?: OutboxPublisher;
  @Input() prepopulateReply?: AutoComment;

  @ViewChild(ReplyBoxComponent) replyBox: ReplyBoxComponent;

  replyBoxVisible = false;

  post: {
    text: string;
    account: Account;
    outbox_files: OutboxPublisherFile[];
    mentions: OutboxPublisherMention[];
  } = {
    text: '',
    account: undefined,
    outbox_files: [],
    mentions: []
  };

  reply: {
    tracker: AsyncTracker;
    text?: string;
    account?: Account;
    visible?: boolean;
    add_dm_reply_link?: boolean;
    file?: any;
    link?: any;
    totalIncludedUsers: number;
    users: ActivityReplyUser[];
  } = {
    tracker: this.asyncTracker.create(),
    users: [],
    totalIncludedUsers: 0,
    visible: true,
    text: ''
  };

  translation: {
    translatedText: string;
    detectedSourceLanguage: string;
    activity: {
      account: Account;
      interaction: {
        content: string;
        entities: any;
      };
    };
  };

  signature: string;

  private _contentText: string = '';
  private _isPredictive: boolean = false;
  private _prediction: string = '';
  private _previousText: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    protected cdRef: ChangeDetectorRef,
    protected api: ApiService,
    protected popup: PopupService,
    protected company: CompanyService,
    protected translate: TranslateService,
    protected outboxModel: OutboxModel,
    protected accountModel: AccountModel,
    protected asyncTracker: AsyncTrackerFactory,
    protected filestackService: FilestackService,
    protected userPreferences: UserPreferencesService,
    protected translationService: TranslationService,
    protected workflowManager: WorkflowManagerService,
    protected predictedResponseService: PredictedResponseService,
    protected socialPostCharactersRemainingPipe: SocialPostCharactersRemainingPipe
  ) {}

  ngOnInit() {
    if (this.outboxPost) {
      this.post = this.outboxPost as any;
      this.post.mentions = this.outboxPost.getMentions();
      this.prepopulateReply = this.outboxPost.auto_comment[0];
    } else if (this.account && this.publisher) {
      this.post.account = this.account;
      this.post.text = this.publisher.getTextForAccount(this.post.account);
      this.post.outbox_files = this.publisher.getFilesForAccount(
        this.post.account
      );
      this.post.mentions = this.publisher.getMentionsForAccount(
        this.post.account
      );
    } else {
      throw new Error(
        'Either (outbox post) or (account and publisher instance) must be provided!'
      );
    }

    this.reply.account = this.post.account;

    if (this.prepopulateReply) {
      this.replyBoxVisible = true;
      this.reply.visible = true;
      this.reply.text = this.prepopulateReply.text;
      this.reply.file = this.prepopulateReply.file;

      if (!this.reply.file && this.prepopulateReply.image) {
        // edit first comment mode (bakcend only saves the image url).. get the file metadata from filestack..
        this.reply.file = {
          url: this.prepopulateReply.image,
          type: '',
          filename: '',
          mimetype: ''
        };
        this.filestackService
          .getFileMetadata(this.prepopulateReply.image)
          .then((file) => {
            this.reply.file = file;
          });
      }
    }

    this.userPreferences.getPreferences().then((userPreferences) => {
      this.signature = userPreferences.inbox_signature
        ? `${userPreferences.inbox_signature}`
        : '';
      this.reply.text = this.signature
        ? this.reply.text + ' ' + this.signature
        : this.reply.text;
    });
  }

  canPublish(): boolean {
    if (this.outboxPost && this.prepopulateReply) {
      if (
        this.reply.text === this.prepopulateReply.text &&
        (this.reply.file && this.reply.file.url) == this.prepopulateReply.image //tslint:disable-line
      ) {
        // edit mode - user didn't make any changes
        return false;
      }
    }

    return this.replyBox && this.replyBox.canReply();
  }

  updateReplyText(text: string) {
    // this.replyTextChanged();
    this.reply.text = text;
  }

  translateReplyText() {
    this.translationService
      .translateText({
        text: this.reply.text,
        target: this.translation.detectedSourceLanguage
      })
      .then((translations) => {
        this.reply.text = translations[0].translatedText;
      });
  }

  addPrivateReplyLink() {
    if (this.reply.account.socialNetwork.addPrivateReplyLinkAsAttachment) {
      this.reply.add_dm_reply_link = !this.reply.add_dm_reply_link;
    } else {
      const replyLink: string = this.reply.account.socialNetwork.getPrivateReplyLink(
        this.reply.account
      );
      const replyText = this.reply.text || '';
      if (!replyText.includes(replyLink)) {
        this.reply.text = [replyText, replyLink]
          .filter((part) => !!part)
          .join(' ');
      }
    }
  }

  async schedulePostOrEditFirstComment(replyContent: any) {
    this.reply.text = replyContent.text;
    const { socialNetwork } = this.reply.account;
    const errors: any = {};

    const maxPostChars = socialNetwork.maxPostCharacters['reply'];
    const charactersRemaining = this.socialPostCharactersRemainingPipe.transform(
      this.reply.text,
      maxPostChars,
      this.reply.account.account_type_name === 'Twitter'
    );
    const statusIsTooLong = charactersRemaining < 0;
    if (statusIsTooLong) {
      errors.statusLength = {};
    }

    if (
      socialNetwork.activity &&
      socialNetwork.activity.response &&
      socialNetwork.activity.response.validate
    ) {
      const validationResult = socialNetwork.activity.response.validate({
        text: this.reply.text
      });
      if (!validationResult.isValid) {
        Object.assign(errors, validationResult.errors);
      }
    }

    if (Object.values(errors).length > 0) {
      const errorStrings = [];

      if (errors.statusLength) {
        errorStrings.push(
          this.translate.instant(
            'YOUR_REPLY_IS__CHARACTERSTOOMANY__CHARACTERS_TOO_LONG',
            {
              charactersTooMany: Math.abs(charactersRemaining)
            }
          )
        );
      }

      if (errors.uppercase) {
        errorStrings.push(
          this.translate.instant('YOUR_REPLY_CANNOT_BE_ALL_UPPERCASE_LETTERS')
        );
      }

      if (errors.hashtags) {
        errorStrings.push(
          this.translate.instant(
            'YOUR_REPLY_CANNOT_CONTAIN_MORE_THAN__MAXALLOWEDHASHTAGS__HASHTAGS',
            {
              maxAllowedHashtags: errors.hashtags.amount.max
            }
          )
        );
      }

      if (errors.links) {
        errorStrings.push(
          this.translate.instant(
            'YOUR_REPLY_CANNOT_CONTAIN_MORE_THAN__MAXAMOUNTLINKS__LINKS',
            {
              maxAmountLinks: errors.links.amount.max
            }
          )
        );
      }

      this.popup.alert({
        isError: true,
        message: errorStrings.join('<br><br>')
      });
    } else {
      // const promise = activity.saveReply(reply).then((replyActivity) => {
      //   // this.reply.visible = false;
      //   // this.resetReply();
      // });

      // this.reply.tracker.add(promise);

      const comment: AutoComment = {
        text: this.reply.text ? this.reply.text : null,
        image: this.reply.file ? this.reply.file.url : null
      };

      if (this.outboxPost) {
        // edit/delete scheduled comment
        await this.editOrDeleteFirstComment(comment);
      } else {
        // create/edit scheduled post (will be handled by the publisher/composer)
        comment.file = this.reply.file;
        this.publisher.autoCommentByAccountId[this.account.id] = comment;
        this.activeModal.close();
      }
    }
  }

  async editOrDeleteFirstComment(comment: AutoComment | null): Promise<void> {
    if (this.editPostModeAndHasAutoComment()) {
      if (!comment) {
        this.publisher.edit.auto_comment = [];
        // TODO: need also to remove auto_comment from the outboxPost (locally).. (it's there even after [reload] - stays cached)
        this.activeModal.close();
        return;
      }
    }

    if (!this.outboxPost) {
      return;
    }

    const outboxMessage = await this.outboxPost.toOutboxPublisherMessageFormat();

    // delete if no comment passed
    outboxMessage.auto_comment = comment ? [comment] : [];

    const body = { message: outboxMessage };
    const config: any = {
      params: {
        _method: 'PUT'
      }
    };

    const response = await this.api
      .post(`${this.api.url}/outbox_v2/indexOutboxv2`, body, config)
      .toPromise();
    console.log('response: ', response);

    this.outboxPost.auto_comment = comment ? [comment] : [];
    this.activeModal.close();
  }

  editPostModeAndHasAutoComment(): boolean {
    return !!(
      this.publisher &&
      this.publisher.edit &&
      Array.isArray(this.publisher.edit.auto_comment) &&
      this.publisher.edit.auto_comment.length
    );
  }

  // ----
  // predictions stuff
  // ----

  public get messageTextValue(): string {
    return this.reply.text;
  }

  public set messageTextValue(value: string) {
    this.setResponse(value);
  }

  public get prediction(): string {
    return this._prediction;
  }

  public set prediction(value: string) {
    this._prediction = value;
  }

  public get isPredictive() {
    return this._isPredictive;
  }

  public async acceptPrediction(event: KeyboardEvent): Promise<boolean> {
    try {
      const prediction = String(this._prediction);
      const shouldContinue =
        !prediction.trim().length ||
        prediction.trim() === String(this.messageTextValue).trim();

      if (shouldContinue) {
        return false;
      }

      event.preventDefault();

      const shouldAddSpace =
        PredictedResponseService.CharactersRequiringSpaceSuffix.indexOf(
          prediction.substr(-1)
        ) === -1;
      const responseValue = prediction + (shouldAddSpace ? ' ' : '');

      const quantityOfWordsInPrediction: number = prediction
        .slice(this.messageTextValue.length)
        .trim()
        .split(' ').length;

      this.setResponse(responseValue);

      const storageKey = `ls` + format(new Date(), 'DD-MM-YYYY');
      const currentStoredValue: number =
        Number(window.localStorage.getItem(storageKey)) || 0;

      window.localStorage.setItem(
        storageKey,
        String(currentStoredValue + quantityOfWordsInPrediction)
      );

      await this.refreshPredictions(false);

      return true;
    } catch (error) {
      console.error('Error accepting prediction:', error);

      return false;
    }
  }

  public async onKeyDown(event: KeyboardEvent): Promise<boolean> {
    try {
      switch (event.key.toLowerCase()) {
        case 'arrowright':
          {
            const textarea = event.target as HTMLTextAreaElement;
            const cursor = textarea.selectionStart;
            const messageLength = this.reply.text.length;

            if (cursor >= messageLength) {
              return this.acceptPrediction(event);
            }
          }
          break;

        case 'tab':
          {
            return this.acceptPrediction(event);
          }
          break;
      }

      return true;
    } catch (error) {
      console.error('Error handling onKeyDown event:', error);

      return false;
    }
  }

  public async onKeyUp(event: KeyboardEvent): Promise<boolean> {
    try {
      this._previousText = this._contentText;
      this._contentText = this.reply.text;

      this.refreshPredictions(true);

      return true;
    } catch (error) {
      console.error('Error handling onKeyUp event:', error);

      return false;
    }
  }

  public async refreshPredictions(shouldFetchFreshPredictions: boolean) {
    try {
      // clear the prediction to reduce any visual lag
      // if e.g. lots of text is pasted in and the lookup is on a slow connection.

      this._prediction = '';

      if (!this.isPredictive) {
        return;
      }

      this._prediction = await this.predictedResponseService.getPredictionFor(
        this.reply.text,
        shouldFetchFreshPredictions
      );
      // .trim()
      // .replace(
      //   '{{name}}',
      //   this.activity.author.name
      //     ? this.activity.author.name.split(' ')[0]
      //     : ''
      // );

      return true;
    } catch (error) {
      console.error('Error refreshing the predictions', error);

      return false;
    }
  }

  public setResponse(text: string = '') {
    try {
      this.reply.text = text;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  // end of predictions stuff...
}
