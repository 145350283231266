<div class="insights-widget-grid-wrapper">
  <div class="grid-wrapper-inner"
       [class.grid-locked]="viewMode">
    <div #gridContainer
         class="grid-container"
         [class.filters-bar-visible]="filtersBarVisible">
      <ssi-ktd-grid 
                    #grid
                    [cols]="cols"
                    [rowHeight]="rowHeight"
                    [layout]="widgets"
                    [scrollableParent]="gridContainer"
                    (layoutUpdated)="onLayoutUpdated($event)">
        <ssi-ktd-grid-item *ngFor="let widget of widgets; let index = index; trackBy: trackById"
                           [id]="widget.id"
                           [draggable]="!viewMode"
                           [resizable]="!viewMode"
                           [typeConst]="widgetTypes[widget.apiData.type]">
          <div class="widget-wrapper"
               [class.grid-locked]="viewMode">
            <div class="widget-wrapper-inner">
              <div class="widget-actions">
                <span class="title">{{widget.apiData.name}}</span>
                <div class="widget-actions-right">
                  <div class="meta">
                    <span
                      *ngIf="widget.apiData?.filters.length"
                      class="widget-meta widget-meta-filter"
                      ssiTooltip
                      [tooltipTemplate]="tooltipFiltersTemplate"
                      [tooltipTemplateContext]="{ filters: widget.apiData?.filters }"
                      tooltipPlacement="bottom"
                      tooltipWindowClass="tooltip-chunky-basic"
                    >
                      <span class="widget-filter-badge">
                        <span class="widget-filter-badge-meta">
                          {{widget.apiData?.filters.length}}
                        </span>
                        <i class="ssi ssi-filter-trimmed"></i>
                      </span>
                    </span>
                  </div>
                  <div class="actions">
                    <!-- <i class="ssi ssi-correct-edit-small"></i>
                    <i class="ssi ssi-copy-paste-small"></i>
                    <i class="ssi ssi-small-add-circle"></i> -->
                    <i class="ssi ssi-small-delete delete-icon"
                       (click)="deleteWidget(widget, index)"></i>
                  </div>
                </div>
                </div>
              <div class="widget-container"
                   ssiLoader
                   ssiLoaderVariant="white"
                   ssiLoaderText="Loading your search results..."
                   [ssiLoaderVisible]="!loaders[index]">

                <ssi-widget-table class="widget"
                                  *ngIf="widget.apiData.type === widgetTypes.table.key"
                                  [globalFilters]="globalFilters"
                                  [filters]="widget.apiData.filters"
                                  [widget]="widget.apiData"
                                  [streamIds]="streamIds"
                                  (loaded)="loaders[index] = true"></ssi-widget-table>

                <ssi-widget-kpi class="widget"
                                *ngIf="widget.apiData.type === widgetTypes.kpi.key"
                                [globalFilters]="globalFilters"
                                [filters]="widget.apiData.filters"
                                [widget]="widget.apiData"
                                [streamIds]="streamIds"
                                [label]="widget.apiData.name"
                                (loaded)="loaders[index] = true"></ssi-widget-kpi>

                <ssi-widget-chart-bar class="widget"
                                      *ngIf="widget.apiData.type === widgetTypes.bar.key"
                                      [globalFilters]="globalFilters"
                                      [filters]="widget.apiData.filters"
                                      [widget]="widget.apiData"
                                      [streamIds]="streamIds"
                                      (loaded)="loaders[index] = true"
                                      (onApplyFilters)="onApplyFilters.emit($event)"></ssi-widget-chart-bar>

                <ssi-widget-chart-line class="widget"
                                      *ngIf="widget.apiData.type === widgetTypes.line.key"
                                      [globalFilters]="globalFilters"
                                      [filters]="widget.apiData.filters"
                                      [widget]="widget.apiData"
                                      [streamIds]="streamIds"
                                      (loaded)="loaders[index] = true"
                                      (onApplyFilters)="onApplyFilters.emit($event)"></ssi-widget-chart-line>

                <ssi-widget-chart-pie class="widget"
                                      *ngIf="widget.apiData.type === widgetTypes.pie.key"
                                      [globalFilters]="globalFilters"
                                      [filters]="widget.apiData.filters"
                                      [widget]="widget.apiData"
                                      [streamIds]="streamIds"
                                      [isDonut]="false"
                                      (loaded)="loaders[index] = true"
                                      (onApplyFilters)="onApplyFilters.emit($event)"></ssi-widget-chart-pie>

                <ssi-widget-chart-pie class="widget"
                                      *ngIf="widget.apiData.type === widgetTypes.donut.key"
                                      [globalFilters]="globalFilters"
                                      [filters]="widget.apiData.filters"
                                      [widget]="widget.apiData"
                                      [streamIds]="streamIds"
                                      [isDonut]="true"
                                      (loaded)="loaders[index] = true"
                                      (onApplyFilters)="onApplyFilters.emit($event)"></ssi-widget-chart-pie>

                <ssi-widget-swot class="widget"
                                 *ngIf="widget.apiData.type === widgetTypes.swot.key"
                                 [globalFilters]="globalFilters"
                                 [filters]="widget.apiData.filters"
                                 [widget]="widget.apiData"
                                 [streamIds]="streamIds"
                                 (loaded)="loaders[index] = true"></ssi-widget-swot>

                <ssi-sentiment-gauge class="widget"
                                 *ngIf="widget.apiData.type === widgetTypes.sentiment.key"
                                 [globalFilters]="globalFilters"
                                 [filters]="widget.apiData.filters"
                                 [widget]="widget.apiData"
                                 [streamIds]="streamIds"
                                 (loaded)="loaders[index] = true"></ssi-sentiment-gauge>
                
                <ssi-widget-influencers-list
                  *ngIf="widget.apiData.type === widgetTypes.influencers.key"
                  class="widget"
                  (loaded)="loaders[index] = true"
                ></ssi-widget-influencers-list>
                <ssi-widget-big class="widget"
                         *ngIf="widget.apiData.type === widgetTypes.big.key"
                         [globalFilters]="globalFilters"
                         [filters]="widget.apiData.filters"
                         [widget]="widget.apiData"
                         [schema]="schema"
                         [viewMode]="viewMode"
                         [streamIds]="streamIds"
                         (loaded)="loaders[index] = true"></ssi-widget-big>

                <ssi-widget-trustometer-gauge class="widget"
                                              *ngIf="widget.apiData.type === widgetTypes.trustometer.key"
                                              [globalFilters]="globalFilters"
                                              [filters]="widget.apiData.filters"
                                              [widget]="widget.apiData"
                                              [streamIds]="streamIds"
                                              (loaded)="loaders[index] = true"></ssi-widget-trustometer-gauge>


                <ssi-widget-top-interests-industries
                  class="widget"
                  *ngIf="widget.apiData.type === widgetTypes.interests.key"
                  [widgetSize]="{h: widget.h, w: widget.w}"
                  [globalFilters]="globalFilters"
                  [filters]="widget.apiData.filters"
                  [widget]="widget.apiData"
                  [streamIds]="streamIds"
                  (loaded)="loaders[index] = true"
                  (loadingAgain)="loaders[index] = false"
                  (onApplyFilters)="onApplyFilters.emit($event)"
                ></ssi-widget-top-interests-industries>
                
                <ssi-widget-top-interests-industries
                  class="widget"
                  *ngIf="widget.apiData.type === widgetTypes.industries.key"
                  [widgetSize]="{h: widget.h, w: widget.w}"
                  [globalFilters]="globalFilters"
                  [filters]="widget.apiData.filters"
                  [widget]="widget.apiData"
                  [streamIds]="streamIds"
                  (loaded)="loaders[index] = true"
                  (loadingAgain)="loaders[index] = false"
                  (onApplyFilters)="onApplyFilters.emit($event)"
                ></ssi-widget-top-interests-industries>

                <ssi-widget-positive-negative-themes
                  class="widget"
                  *ngIf="widget.apiData.type === widgetTypes.positive_themes.key"
                  [globalFilters]="globalFilters"
                  [filters]="widget.apiData.filters"
                  [widget]="widget.apiData"
                  [widgetName]="'positive_themes'"
                  [streamIds]="streamIds"
                  (loaded)="loaders[index] = true"
                  (loadingAgain)="loaders[index] = false"
                ></ssi-widget-positive-negative-themes>
                
                <ssi-widget-positive-negative-themes
                  class="widget"
                  *ngIf="widget.apiData.type === widgetTypes.negative_themes.key"
                  [globalFilters]="globalFilters"
                  [filters]="widget.apiData.filters"
                  [widget]="widget.apiData"
                  [widgetName]="'negative_themes'"
                  [streamIds]="streamIds"
                  (loaded)="loaders[index] = true"
                  (loadingAgain)="loaders[index] = false"
                ></ssi-widget-positive-negative-themes>
                
                <ssi-widget-theme-cloud
                  class="widget"
                  *ngIf="widget.apiData.type === widgetTypes.theme_cloud.key"
                  [globalFilters]="globalFilters"
                  [filters]="widget.apiData.filters"
                  [widget]="widget.apiData"
                  [streamIds]="streamIds"
                  (loaded)="loaders[index] = true"
                  (loadingAgain)="loaders[index] = false"
                ></ssi-widget-theme-cloud>
                
                <ssi-widget-chart-line
                  class="widget"
                  *ngIf="widget.apiData.type === widgetTypes.trustometer_over_time.key"
                  [globalFilters]="globalFilters"
                  [filters]="widget.apiData.filters"
                  [widget]="widget.apiData"
                  [streamIds]="streamIds"
                  (loaded)="loaders[index] = true"
                  (onApplyFilters)="onApplyFilters.emit($event)"
                ></ssi-widget-chart-line>

              </div>
            </div>
          </div>
        </ssi-ktd-grid-item>
      </ssi-ktd-grid>
    </div>
  </div>
</div>

<ng-template #tooltipFiltersTemplate let-filters="filters">
  <div class="tooltip-widget-filters-content">
    <h4>Widget Filters</h4>
    <ul>
      <li *ngFor="let filter of filters; let last = last">
        <ng-template #unknownFilter>unknown filter</ng-template>
        <ng-container
          *ngIf="filter.field else unknownFilter"
        >{{filter | formatWidgetFilter | async}}</ng-container>
        <ng-container *ngIf="!last">, </ng-container>
      </li>
    </ul>
  </div>
  <div data-popper-arrow class="tooltip-arrow"></div>
</ng-template>
