import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FactOverviewComponent } from './fact-overview.component';
import { StatChangeModule } from '../stat-change/stat-change.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { IconModule } from '../../../../../common/components/icon/icon.module';

@NgModule({
  declarations: [FactOverviewComponent],
  imports: [CommonModule, BigNumberModule, IconModule, StatChangeModule],
  exports: [FactOverviewComponent]
})
export class FactOverviewModule {}
