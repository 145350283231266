import './account-pill.component.scss';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-account-pill',
  templateUrl: './account-pill.component.html',
  styles: []
})
export class AccountPillComponent {
  @Input() icon: string;
  @Input() label: string;

  constructor() {}
}
