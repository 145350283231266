import './orlo-crm-person.component.scss';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrloCrmPersonComponent } from './orlo-crm-person.component';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, UIRouterModule, FormsModule, ReactiveFormsModule],
  declarations: [OrloCrmPersonComponent],
  exports: [OrloCrmPersonComponent]
})
export class OrloCrmPersonModule {}
