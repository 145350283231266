
<div class="edit-widget">
  <form
    *ngIf="widgetForm"
    class="content-form"
    (ngSubmit)="save()"
    [formGroup]="widgetForm"
  >
    <div class="form-actions">
      <a
          (click)="deleteWidgetTooltip.show()"
          #deleteWidgetTooltip="ssiTooltip"
          [ssiTooltip]
          tooltipPlacement="right"
          tooltipTrigger="manual"
          [tooltipTemplate]="deleteWidgetTooltipTemplate"
        >
        <!-- <orlo-button [state]="'primary'" [text]="'Delete'" [bgColor]="'#F0F3F9'" [textColor]="'#15BAE3'" (btnClick)="deleteWidgetTooltip.show" [type]="'button'" ></orlo-button> -->
      </a>
      <orlo-button [state]="'primary'" [text]="'Save changes'" [bgColor]="'#F0F3F9'" [textColor]="'#15BAE3'" [trailingIcon]="savingWidget ? 'ssi ssi-loading ssi-spin' : ''" [type]="'submit'" ></orlo-button>
      <ng-template #deleteWidgetTooltipTemplate>
        <div
          class="delete-widget-tooltip"
          (ssiOffClick)="deleteWidgetTooltip.hide()"
        >
          <h5>Delete for all eternity?</h5>
          <div class="buttons-container">
            <orlo-button
              [state]="'text'"
              [text]="'Cancel'"
              [textColor]="'#838eab'"
              (btnClick)="deleteWidgetTooltip.hide()"
              [type]="'button'"
            ></orlo-button>
            <orlo-button
              [state]="'text'"
              [text]="'Delete'"
              [textColor]="'#e29e8c'"
              (btnClick)="delete()"
              [type]="'button'"
            ></orlo-button>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="form-segment">
      <h2>Branding</h2>
      <div class="form-group">
        <orlo-text-input
          [label]="'Live chat widget name (internal setting)'"
          [name]="'name'"
          [parentForm]="widgetForm"
          [type]="'text'"
          [required]="true"
          [placeholder]="'Name your widget'"
          [invalid]="this.checkErrors && widgetForm.controls.name?.errors ? true : false || widgetForm.controls.name?.touched && widgetForm.controls.name?.errors ? true : false"
          [invalidMessage]="widgetForm.controls.name?.touched && widgetForm.controls.name?.errors && widgetForm.controls.name?.errors.maxlength ? 'Please enter a shorter name' : 'Woops! We\'ll need you to fill this one in...'"
        ></orlo-text-input>
        <div>
          <label class="control-label text-muted spacer-top">
            Primary colour
          </label>
          <button
            class="orlo-button primary plus box-color"
            type="button"
            [style.background]="widget.primaryColour"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [cpAlphaChannel]="'disabled'"
            [(colorPicker)]="widget.primaryColour"
          ><i class="ssi ssi-plus"></i></button>
        </div>
        <div>
          <label class="control-label text-muted spacer-top">
            Agent Background Color
          </label>
          <button
            class="orlo-button primary plus box-color"
            type="button"
            [style.background]="widget.incomingMessageBackground"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [cpAlphaChannel]="'disabled'"
            [(colorPicker)]="widget.incomingMessageBackground"
          ><i class="ssi ssi-plus"></i></button>
        </div>
        <div>
          <label class="control-label text-muted spacer-top">
            Agent Font Color
          </label>
          <button
            class="orlo-button primary plus box-color"
            type="button"
            [style.background]="widget.incomingMessageForeground"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [cpAlphaChannel]="'disabled'"
            [(colorPicker)]="widget.incomingMessageForeground"
          ><i class="ssi ssi-plus"></i></button>
        </div>
      </div>
      <div class="form-group">
        <orlo-text-input
          [label]="'Out of hours contact'"
          [name]="'customerContactEmail'"
          [parentForm]="widgetForm"
          [type]="'text'"
          [required]="true"
          [placeholder]="'Out of hours contact email address'"
          [invalid]="this.checkErrors && widgetForm.controls.customerContactEmail?.errors ? true : false || widgetForm.controls.customerContactEmail?.touched && widgetForm.controls.customerContactEmail?.errors ? true : false"
          [invalidMessage]="widgetForm.controls.customerContactEmail?.touched && widgetForm.controls.customerContactEmail?.errors && widgetForm.controls.customerContactEmail?.errors.maxlength ? 'Please enter a shorter email' : 'Woops! We\'ll need you to fill this one in...'"
        ></orlo-text-input>
        <div>
          <label class="control-label text-muted spacer-top">
            Visitor Background Color
          </label>
          <button
            class="orlo-button primary plus box-color"
            type="button"
            [style.background]="widget.outgoingMessageBackground"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [cpAlphaChannel]="'disabled'"
            [(colorPicker)]="widget.outgoingMessageBackground"
          ><i class="ssi ssi-plus"></i></button>
        </div>
        <div>
          <label class="control-label text-muted spacer-top">
            Visitor Font Color
          </label>
          <button
            class="orlo-button primary plus box-color"
            type="button"
            [style.background]="widget.outgoingMessageForeground"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [cpAlphaChannel]="'disabled'"
            [(colorPicker)]="widget.outgoingMessageForeground"
          ><i class="ssi ssi-plus"></i></button>
        </div>
      </div>
      <div class="form-group">
        <div class="form-toggle">
          <ssi-toggle-switch
            parentNgForm="widgetForm"
            formControlName="useContactLink"
            toggleText="Using Out Of Hours Link"
            label="Replace out of hours email with a link"
            toolTip="When your agents have finished their working day, you can now direct those trying to use Live Chat to a webpage, rather than an email address."
          >
          </ssi-toggle-switch>
        </div>
        <orlo-text-input
          *ngIf="widgetForm.value.useContactLink"
          [label]="'Out of hours link'"
          [name]="'customerContactLink'"
          [parentForm]="widgetForm"
          [type]="'text'"
          [required]="widgetForm.value.useContactLink"
          [placeholder]="'Out of hours web link'"
          [invalid]="this.checkErrors && widgetForm.controls.customerContactLink?.errors ? true : false || widgetForm.controls.customerContactLink?.touched && widgetForm.controls.customerContactLink?.errors ? true : false"
          [invalidMessage]="widgetForm.controls.customerContactLink?.touched && widgetForm.controls.customerContactLink?.errors && widgetForm.controls.customerContactLink?.errors.maxlength ? 'Please enter a shorter link' : 'Woops! We\'ll need you to fill this one in...'"
        ></orlo-text-input>
      </div>
    </div>
    
    <div class="form-segment">
      <h2>Image & Chat Button</h2>
      <div class="form-group">
        <div class="form-toggle">
          <ssi-toggle-switch
            parentNgForm="widgetForm"
            formControlName="isTriggerHidden"
            toggleText="Hide Standard Button"
          >
          </ssi-toggle-switch>
        </div>
        <div class="form-upload">
          <label class="control-label text-muted spacer-top">
            Custom Icon (SVG)
          </label>
          <img
            *ngIf="widget.customIcon"
            class="custom-icon"
            [src]="widget.customIcon"
          >
          <orlo-button [type]="'button'" [state]="'primary'" [text]="'Upload icon'" [bgColor]="'#F0F3F9'" [textColor]="'#15BAE3'" [leadingIcon]="'ssi ssi-plus'" (btnClick)="uploadCustomIcon()" ></orlo-button>
        </div>
        <div class="form-upload">
          <label class="control-label text-muted spacer-top">
            Header Image (350x170px)
          </label>
          <img
            *ngIf="widget.headerImage"
            class="header-image"
            [src]="widget.headerImage"
          >
          <orlo-button [type]="'button'" [state]="'primary'" [text]="'Upload image'" [bgColor]="'#F0F3F9'" [textColor]="'#15BAE3'" [leadingIcon]="'ssi ssi-plus'" (btnClick)="uploadHeaderImage()" ></orlo-button>
        </div>
      </div>
    </div>
    <div class="flex-grid thirds">
      <div class="col two-thirds">
        <div class="form-segment full-height margin-right">
          <h2>Widget Styling</h2>
          <div class="form-group">
            <div class="orlo-select">
              <label
                class="control-label text-muted spacer-top"
              >Visitor messages</label>
              <select
                formControlName="visitorMessagesPosition"
                name="visitorMessagesPosition"
              >
                <option *ngFor="let position of availableVisitorMessagesPositions" [ngValue]="position.value">{{position.name}}</option>
              </select>
              <i class="ssi ssi-arrow-down-correct"></i>
            </div>
            <orlo-text-input
              [label]="'Base font size (pixels)'"
              [name]="'baseFontSize'"
              [parentForm]="widgetForm"
              [type]="'number'"
              [placeholder]="'Choose your base font size...'"
            ></orlo-text-input>
          </div>
          <div class="form-group">
            <div class="orlo-select">
              <label
                class="control-label text-muted spacer-top"
              >Widget position</label>
              <select
                formControlName="position"
                name="position"
              >
                <option *ngFor="let position of availableWidgetPositions" [ngValue]="position.value">{{position.name}}</option>
              </select>
              <i class="ssi ssi-arrow-down-correct"></i>
            </div>
            <orlo-text-input
              [label]="'Horizontal padding'"
              [name]="'widgetHorizontalPadding'"
              [parentForm]="widgetForm"
              [type]="'number'"
              [placeholder]="'Choose your horizontal padding...'"
            ></orlo-text-input>
            <orlo-text-input
              [label]="'Vertical padding'"
              [name]="'widgetVerticalPadding'"
              [parentForm]="widgetForm"
              [type]="'number'"
              [placeholder]="'Choose your vertical padding...'"
            ></orlo-text-input>
          </div>
        </div>
      </div>

      <div class="col one-third">
        <div class="form-segment full-height">
          <h2>File Uploads</h2>
          <p>Choose whether you would like visitors to be able to upload files during chats</p>
          <div class="form-toggle">
            <ssi-toggle-switch
              parentNgForm="widgetForm"
              formControlName="canVisitorsUpload"
              toggleText="Visitors can upload files"
            >
            </ssi-toggle-switch>
          </div>
        </div>
      </div>
    </div>

    <div class="form-segment">
      <h2>New Chat View</h2>
      <div class="form-grid form-group">
        <div class="form-row">
          <div class="form-field">
            <orlo-text-input
              [label]="'Start chat button label'"
              [name]="'startPrompt'"
              [parentForm]="widgetForm"
              [type]="'text'"
              [placeholder]="'Start new chat...'"
            ></orlo-text-input>
          </div>
          <div class="form-field">
            <orlo-text-input
              [label]="'Widget title'"
              [name]="'widgetTitle'"
              [parentForm]="widgetForm"
              [type]="'text'"
              [placeholder]="'Chat with us...'"
            ></orlo-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-field">
            <orlo-text-input
              [label]="'Name input label'"
              [name]="'namePrompt'"
              [parentForm]="widgetForm"
              [type]="'text'"
              [placeholder]="'Type your name...'"
            ></orlo-text-input>
          </div>
          <div class="form-field">
            <orlo-text-input
              [label]="'Email input label'"
              [name]="'emailPrompt'"
              [parentForm]="widgetForm"
              [type]="'text'"
              [placeholder]="'Type your email...'"
            ></orlo-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-field">
            <orlo-text-input
              [label]="'Welcome title'"
              [name]="'welcomeTitle'"
              [parentForm]="widgetForm"
              [type]="'text'"
              [placeholder]="'Hey there...'"
            ></orlo-text-input>
          </div>
          <div class="form-field">
            <div class="orlo-textarea">
              <label
                class="control-label text-muted spacer-top"
              >Welcome message</label>
              <textarea 
                formControlName="welcomeMessage"
                name="welcomeMessage"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>
        
        <div formGroupName="welcomeFormFields">
          <h2>New chat questions</h2>
          <ng-container *ngFor="let slot of slotStrings">
            <div class="form-row new-chat-questions" [formGroupName]="slot">
              <div class="form-field">
                <div class="form-toggle">
                  <ssi-toggle-switch
                    parentNgForm="widgetForm"
                    formControlName="required"
                    toggleText="{{ widgetForm.value.welcomeFormFields[slot].required? 'Yes' : 'No'}}"
                    label="Required?"
                  >
                  </ssi-toggle-switch>
                </div>
              </div>
              <div class="form-field">
                <div class="input-container">
                  <label class="field-label">Label</label>
                  <input
                    type="text"
                    parentNgForm="widgetForm"
                    formControlName="label"
                    class="orlo-text-input"
                    placeholder="Enter label..."
                    maxlength="200"
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="orlo-select">
                  <label
                    class="control-label text-muted spacer-top"
                  >Field Type</label>
                  <select
                    formControlName="fieldType"
                    name="fieldType"
                  >
                    <option *ngFor="let type of welcomeFieldTypes" [ngValue]="type.value">{{type.name}}</option>
                  </select>
                  <i class="ssi ssi-arrow-down-correct"></i>
                </div>
              </div>
              <div class="form-field">
                <div class="input-container">
                  <label class="field-label">Field prompt text</label>
                  <input
                    type="text"
                    parentNgForm="widgetForm"
                    formControlName="placeholder"
                    class="orlo-text-input"
                    placeholder="Enter placeholder..."
                    maxlength="200"
                  />
                </div>
              </div>
              <div class="form-field">
                <div class="form-toggle no-label">
                  <ssi-toggle-switch
                    parentNgForm="widgetForm"
                    formControlName="enabled"
                    toggleText="Enabled"
                  >
                  </ssi-toggle-switch>
                </div>
              </div>
            </div>
          </ng-container>
          
          <!-- <div class="form-row new-chat-questions" formGroupName="slotOne">
            <div class="form-field">
              <div class="form-toggle no-label">
                <ssi-toggle-switch
                  parentNgForm="widgetForm"
                  formControlName="enabled"
                  toggleText="Enabled"
                >
                </ssi-toggle-switch>
              </div>
            </div>
            <div class="form-field">
              <div class="input-container">
                <label class="field-label">Label</label>
                <input
                  type="text"
                  parentNgForm="widgetForm"
                  formControlName="label"
                  class="orlo-text-input"
                  placeholder="Enter label..."
                  maxlength="200"
                />
              </div>
            </div>
            <div class="form-field">
              <div class="orlo-select">
                <label
                  class="control-label text-muted spacer-top"
                >Field Type</label>
                <select
                  formControlName="fieldType"
                  name="fieldType"
                >
                  <option *ngFor="let type of welcomeFieldTypes" [ngValue]="type.value">{{type.name}}</option>
                </select>
                <i class="ssi ssi-arrow-down-correct"></i>
              </div>
            </div>
            <div class="form-field">
              <div class="input-container">
                <label class="field-label">Field prompt text</label>
                <input
                  type="text"
                  parentNgForm="widgetForm"
                  formControlName="placeholder"
                  class="orlo-text-input"
                  placeholder="Enter placeholder..."
                  maxlength="200"
                />
              </div>
            </div>
            <div class="form-field">
              <div class="form-toggle">
                <ssi-toggle-switch
                  parentNgForm="widgetForm"
                  formControlName="required"
                  toggleText="Yes"
                  label="Required?"
                >
                </ssi-toggle-switch>
              </div>
            </div>
          </div> -->
          <!---->
          <!-- <div class="form-row new-chat-questions">
            <div class="form-field">
              <div class="form-toggle no-label">
                <ssi-toggle-switch
                  parentNgForm="widgetForm"
                  formControlName="canVisitorsUpload"
                  toggleText="Enabled"
                >
                </ssi-toggle-switch>
              </div>
            </div>
            <div class="form-field">
              <orlo-text-input
                [label]="'Label'"
                [name]="'welcomeTitle'"
                [parentForm]="widgetForm"
                [type]="'text'"
                [placeholder]="'Hey there...'"
              ></orlo-text-input>
            </div>
            <div class="form-field">
              <div class="orlo-select">
                <label
                  class="control-label text-muted spacer-top"
                >Field Type</label>
                <select
                  formControlName="visitorMessagesPosition"
                  name="visitorMessagesPosition"
                >
                  <option *ngFor="let position of availableVisitorMessagesPositions" [ngValue]="position.value">{{position.name}}</option>
                </select>
                <i class="ssi ssi-arrow-down-correct"></i>
              </div>
            </div>
            <div class="form-field">
              <orlo-text-input
                [label]="'Field prompt text'"
                [name]="'welcomeTitle'"
                [parentForm]="widgetForm"
                [type]="'text'"
                [placeholder]="'Hey there...'"
              ></orlo-text-input>
            </div>
            <div class="form-field">
              <div class="form-toggle">
                <ssi-toggle-switch
                  parentNgForm="widgetForm"
                  formControlName="canVisitorsUpload"
                  toggleText="Yes"
                  label="Required?"
                >
                </ssi-toggle-switch>
              </div>
            </div>
          </div> -->
          <!---->
          <!--<div class="form-row new-chat-questions">
            <div class="form-field">
              <div class="form-toggle no-label">
                <ssi-toggle-switch
                  parentNgForm="widgetForm"
                  formControlName="canVisitorsUpload"
                  toggleText="Enabled"
                >
                </ssi-toggle-switch>
              </div>
            </div>
            <div class="form-field">
              <orlo-text-input
                [label]="'Label'"
                [name]="'welcomeTitle'"
                [parentForm]="widgetForm"
                [type]="'text'"
                [placeholder]="'Hey there...'"
              ></orlo-text-input>
            </div>
            <div class="form-field">
              <div class="orlo-select">
                <label
                  class="control-label text-muted spacer-top"
                >Field Type</label>
                <select
                  formControlName="visitorMessagesPosition"
                  name="visitorMessagesPosition"
                >
                  <option *ngFor="let position of availableVisitorMessagesPositions" [ngValue]="position.value">{{position.name}}</option>
                </select>
                <i class="ssi ssi-arrow-down-correct"></i>
              </div>
            </div>
            <div class="form-field">
              <orlo-text-input
                [label]="'Field prompt text'"
                [name]="'welcomeTitle'"
                [parentForm]="widgetForm"
                [type]="'text'"
                [placeholder]="'Hey there...'"
              ></orlo-text-input>
            </div>
            <div class="form-field">
              <div class="form-toggle">
                <ssi-toggle-switch
                  parentNgForm="widgetForm"
                  formControlName="canVisitorsUpload"
                  toggleText="Yes"
                  label="Required?"
                >
                </ssi-toggle-switch>
              </div>
            </div>
          </div> -->
          <!---->
        </div>
      </div>
    </div>

    <div class="form-segment">
      <h2>Conversation View</h2>
      <div class="form-group form-grid full-width-fields">
        <div class="form-row">
          <orlo-text-input
            [label]="'Agents are offline'"
            [name]="'agentsOfflineNotice'"
            [parentForm]="widgetForm"
            [type]="'text'"
            [required]="true"
            [toolTip]="'This message will display in the widget launch screen when your agents are not online. You may want to let people know your office hours or direct them to other services.'"
          ></orlo-text-input>
        </div>
        <div class="form-row">
          <orlo-text-input
            [label]="'First response'"
            [name]="'firstResponse'"
            [parentForm]="widgetForm"
            [type]="'text'"
            [required]="true"
            [toolTip]="'This message will be automatically sent after someone submits their first message to you. You may want to say thanks for the message or let them know you’ll be with them shortly.'"            
          ></orlo-text-input>
        </div>
        <div class="form-row">
          <orlo-text-input
            [label]="'Last agent online'"
            [name]="'lastAgentNotice'"
            [parentForm]="widgetForm"
            [type]="'text'"
            [required]="true"
            [toolTip]="'This message will display in the widget when an agent assigned to the chat has gone offline and no other agents are online. You may want to let people know your office hours or direct them to other services.'"
          ></orlo-text-input>
        </div>
        <div class="form-row">
          <orlo-text-input
            [label]="'Notice of resolution'"
            [name]="'resolutionNoticeMessage'"
            [parentForm]="widgetForm"
            [type]="'text'"
            [required]="true"
            [toolTip]="'This message will display in the widget when the agent has marked a chat as resolved. You may want to ask people to open a new chat or direct them to other services.'"
          ></orlo-text-input>
        </div>
      </div>  
    </div>

    <div class="flex-grid thirds">
      <div class="col one-half">
        <div class="form-segment full-height margin-right">
          <h2>Chat Resolution Period</h2>
          <p>Set a time period to wait before a chat is archived after resolution.</p>
          <div class="form-group slim">
            <orlo-text-input
              [label]="'Time (seconds)'"
              [name]="'resolutionGracePeriod'"
              [parentForm]="widgetForm"
              [type]="'number'"
            ></orlo-text-input>
          </div>
        </div>
      </div>
      <div class="col one-half">
        <div class="form-segment full-height">
          <h2>Auto Resolution Timeout</h2>
          <p>Set a time period to wait before an idle chat that has an agent assigned is automatically resolved. <em>Set to 0 to disable</em></p>
          <div class="form-group slim">
            <orlo-text-input
              [label]="'Time (minutes)'"
              [name]="'autoResolutionTimeout'"
              [parentForm]="widgetForm"
              [type]="'number'"
            ></orlo-text-input>
          </div>
        </div>
      </div>
      <div class="col full">
        <div class="form-segment full-height">
          <h2>Chat Queue Limit</h2>
          <p>Set the maximum number of chats that can be waiting. Once reached, the messages below will be displayed until chat volume reduces. <em>Set to 0 for no limit</em></p>
          <div class="form-group slim">
            <orlo-text-input
              [label]="'Number of chats'"
              [name]="'maximumQueueSize'"
              [parentForm]="widgetForm"
              [type]="'number'"
            ></orlo-text-input>
            <div class="orlo-select">
              <label
                class="control-label text-muted spacer-top"
              >Queue group</label>
              <select
                formControlName="queueGroup"
                name="queueGroup"
              >
                <option *ngFor="let queue of queueGroups" [ngValue]="queue">{{queue}}</option>
              </select>
              <i class="ssi ssi-arrow-down-correct"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grid thirds">
      <div class="col two-thirds">
        <div class="form-segment full-height margin-right" *ngIf="chatBots && chatBots.length > 0 && hasChatbotFeature">
          <h2>Chatbot</h2>
          <div class="form-group">
            <div class="form-toggle compact">
              <ssi-toggle-switch
                name="chatbotEnabled"
                [(ngModel)]="chatbotEnabled"
                [ngModelOptions]="{standalone: true}"
                toggleText="Chatbot turned {{chatbotEnabled ? 'On' : 'Off'}}"
                label="Toggle chatbot"
              >
              </ssi-toggle-switch>
            </div>
            <div class="orlo-select wide">
              <label
                class="control-label text-muted spacer-top"
              >Select your chatbot</label>
              <select
                formControlName="chatbotId"
                name="chatbotId"
                [disabled]="!chatbotEnabled"
                
              >
                <option *ngFor="let chatbot of chatBots" [ngValue]="chatbot.id">{{chatbot.name}}</option>
              </select>
              <i class="ssi ssi-arrow-down-correct"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col one-third">
        <div class="form-segment full-height">
          <h2>Conversation History</h2>
          <p>Would you like visitors to be able to view past chats? This may be helpful for visitors to refer to:</p>
          <div class="form-toggle">
            <ssi-toggle-switch
              name="canVisitorsViewArchivedConversations"
              [(ngModel)]="canVisitorsViewArchivedConversations"
              [ngModelOptions]="{standalone: true}"
              toggleText="Enable archived conversations"
            >
            </ssi-toggle-switch>
          </div>
        </div>
      </div>
    </div>
    
    <div class="form-segment">
      <h2>Turn Widget On/Off</h2>
      <div class="form-group">
        <div class="form-toggle compact">
          <ssi-toggle-switch
            name="widgetEnabled"
            [(ngModel)]="widgetEnabled"
            [ngModelOptions]="{standalone: true}"
            toggleText="Widget turned {{widgetEnabled ? 'On' : 'Off'}}"
            label="Toggle widget"
          >
          </ssi-toggle-switch>
        </div>
        <div class="orlo-textarea">
          <label
            class="control-label text-muted spacer-top tooltip-attached"
            [ssiTooltip]="'This message will display in the widget on the start screen and in active chats when the widget is turned off or the queue limit has been reached. You may want to ask people to wait for the next available agent or direct them to other services.'"
            [tooltipWindowClass]="'tooltip-chunky-big'"
          >
            Message to show if widget is off / queue limit reached
            <i class="ssi ssi-information"></i>
          </label>
          <textarea 
            formControlName="disabledMessage"
            name="disabledMessage"
            rows="4"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="form-segment">
      <h2>Widget Security</h2>
      <div class="form-group">
        <div class="orlo-textarea">
          <label
            class="control-label text-muted spacer-top"
          >Your RSA public key (optional)</label>
          <textarea 
            formControlName="publicKey"
            name="publicKey"
            rows="10"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</div>