<button *ngIf="closable"
        class="g-btn g-btn-sm g-btn-secondary border-radius-100 close-button"
        (click)="close()">
    <span class="button-text">Close</span>
    <i class="ssi ssi-arrow-down-correct"></i>
</button>

<form #noteForm="ngForm"
      (submit)="addNewNote()">
    <ssi-notes-textarea name="content"
                        [required]="true"
                        [(ngModel)]="content"
                        [placeholder]="'Type @ to chat to your team...'"
                        [tagList]="tagList"></ssi-notes-textarea>

    <button class="g-btn g-btn-sm border-radius-100 save-button"
            [disabled]="noteForm.invalid"
            translate="SAVE_PROFILE_NOTE"
            type="submit"></button>
</form>

<ssi-notes *ngIf="crmPerson?.notes"
           [notes]="crmPerson.notes"
           (onDelete)="deleteNote($event)"></ssi-notes>