import './inbox-activity-thread.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { Activity, Conversation } from '@ui-resources-angular';
import {
  UserModel,
  User,
  ActivityThread,
  ActivityThreadFactory
} from '@ui-resources-angular';
import { InboxMode } from '../../../../modules/auth/inbox/inbox.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import debounce from 'debounce-promise';
import { CompanyService } from '../../../services/company/company.service';

const getRootActivity = (thread) => {
  if (thread.parents.length > 0) {
    return thread.parents[0];
  }

  return thread.current;
};

const ACTIVITY_LOAD_DEBOUNCE = 400;

export enum ReplyBoxStatus {
  Compressed = 'COMPRESSED',
  Expanded = 'EXPANDED',
  Hidden = 'HIDDEN',
  None = 'NONE',
  Active = 'ACTIVE'
}

@Component({
  selector: 'ssi-inbox-activity-thread',
  templateUrl: './inbox-activity-thread.component.html'
})
export class InboxActivityThreadComponent implements OnInit, OnChanges {
  @Input() activity: Activity;
  @Input() conversation: Conversation;
  @Input() inboxMode: InboxMode;
  @Input() hideArrowShortcuts: boolean;
  @Input() isPushMode: boolean;
  @Input() isReply: boolean;

  activityThread: ActivityThread;
  isNestedThread: boolean;
  exportFeatureEnabled: boolean;
  replyBoxSticky = true;
  replyBoxStatus: ReplyBoxStatus;
  trackById = (index: number, obj) => obj.id;
  promiseTrackerOptions = { activationDelay: 200 };
  loadingTrackers = {
    initial: this.asyncTrackerFactory.create(this.promiseTrackerOptions),
    children: this.asyncTrackerFactory.create(this.promiseTrackerOptions),
    siblings: {
      older: this.asyncTrackerFactory.create(this.promiseTrackerOptions),
      newer: this.asyncTrackerFactory.create(this.promiseTrackerOptions)
    }
  };
  loadThreadDebounced = debounce(
    () => this.loadThread(),
    ACTIVITY_LOAD_DEBOUNCE
  );
  ReplyBoxStatus = ReplyBoxStatus;
  private _conversationSplitDetails = undefined;
  private _user: User;

  @Output() onReplyTextChanged = new EventEmitter();
  @Output() onChangeActivityThread = new EventEmitter();
  @Output() onShowTags = new EventEmitter();
  @Output() onChangeActivity = new EventEmitter();
  @Output() onShowFilters = new EventEmitter();
  @Output() onChangeConversation = new EventEmitter();
  @Output() onResolveConversation = new EventEmitter();
  @Output() onHoldConversationStatusChange = new EventEmitter();

  @ViewChild('scroller') scroller: ElementRef;
  @ViewChild('activeActivity', { read: ElementRef }) activeActivity: ElementRef;

  constructor(
    private activityThreadFactory: ActivityThreadFactory,
    private userModel: UserModel,
    private modal: NgbModal,
    private company: CompanyService,
    protected cdr: ChangeDetectorRef,
    private asyncTrackerFactory: AsyncTrackerFactory
  ) {}

  ngOnInit() {
    this.userModel.getAuthUser().then((user) => {
      this._user = user;
    });

    this.company
      .hasFeatureAccess('CONVERSATION_EXPORT')
      .then((result) => (this.exportFeatureEnabled = !!result));

    this.replyBoxSticky = !['iPhone'].includes(navigator.platform);
  }

  ngOnChanges(changes) {
    if (changes.activity) {
      let isNewActivityInCurrentThread = false;
      if (this.activityThread && this.activity) {
        const threadActivities = [
          this.activityThread.thread.current,
          ...this.activityThread.thread.children.activities,
          ...this.activityThread.thread.siblings.newer.activities,
          ...this.activityThread.thread.siblings.older.activities,
          ...(this.activityThread.thread.children.hiddenActivities || []),
          ...(this.activityThread.thread.siblings.newer.hiddenActivities || []),
          ...(this.activityThread.thread.siblings.older.hiddenActivities || [])
        ];
        isNewActivityInCurrentThread = threadActivities.includes(this.activity);
      }
      if (isNewActivityInCurrentThread) {
        this.loadThread();
      } else {
        this.loadThreadDebounced();
        this.loadingTrackers.initial.add(
          new Promise((resolve) =>
            setTimeout(resolve, ACTIVITY_LOAD_DEBOUNCE, {})
          )
        );
      }
      this.scrollToActivity();
    }
  }

  loadThread() {
    if (this.activity) {
      const promise = this.activityThreadFactory
        .create(this.activity)
        .then((activityThread) => {
          this.activityThread = activityThread;

          this.isNestedThread =
            !this.activity.interaction.is_private &&
            this.activity.social_actions.reply_type === 'comment';

          if (this.isNestedThread) {
            activityThread.thread.children['hiddenActivities'] =
              activityThread.thread.children.activities;
            activityThread.thread.children.activities = [];
            activityThread.thread.children.missing +=
              activityThread.thread.children['hiddenActivities'].length;

            activityThread.thread.siblings.newer['hiddenActivities'] =
              activityThread.thread.siblings.newer.activities;
            activityThread.thread.siblings.newer.activities = [];
            activityThread.thread.siblings.newer.missing +=
              activityThread.thread.siblings.newer['hiddenActivities'].length;

            activityThread.thread.siblings.older['hiddenActivities'] =
              activityThread.thread.siblings.older.activities;
            activityThread.thread.siblings.older.activities = [];
            activityThread.thread.siblings.older.missing +=
              activityThread.thread.siblings.older['hiddenActivities'].length;
          }

          activityThread.thread['root'] = getRootActivity(
            activityThread.thread
          );
          this.handleStickyReplyBox({ status: this.replyBoxStatus });
          this.onChangeActivityThread.emit({
            activityThread: this.activityThread
          });

          if (this.isReply && this.activityThread.thread.children.missing > 0) {
            this.loadMoreChildren();
          }

          if (this.replyBoxSticky) {
            if (
              this.isReply &&
              this.activityThread.thread.current.interaction.is_private
            ) {
              this.scrollToBottomOfThread();
            } else {
              this.scrollToActivity();
            }
          }
        });
      this.loadingTrackers.initial.add(promise);
    }
  }

  loadMore(threadSection, loadingTracker, loadMoreMethod) {
    delete this.activityThread.thread.newReply;

    if (threadSection.hiddenActivities) {
      threadSection.missing -= threadSection.hiddenActivities.length;
      threadSection.activities = [
        ...threadSection.activities,
        ...threadSection.hiddenActivities
      ];
      delete threadSection.hiddenActivities;
      this.activityThread.orderThreadActivities();
    } else {
      const promise = this.activityThread[loadMoreMethod]();
      loadingTracker.add(promise);
    }
  }

  loadMoreOlderSiblings() {
    this.loadMore(
      this.activityThread.thread.siblings.older,
      this.loadingTrackers.siblings.older,
      'loadMoreOlderSiblings'
    );
  }

  loadMoreChildren() {
    this.loadMore(
      this.activityThread.thread.children,
      this.loadingTrackers.children,
      'loadMoreChildren'
    );
  }

  loadMoreNewerSiblings() {
    this.loadMore(
      this.activityThread.thread.siblings.newer,
      this.loadingTrackers.siblings.newer,
      'loadMoreNewerSiblings'
    );
  }

  changeActivity(activity: Activity) {
    this.closeSplitConversationPanel();
    if (
      activity.social_actions.reply_type === 'comment' ||
      activity.inbox.requires_action
    ) {
      this.onChangeActivity.emit({ activity });
      this.handleStickyReplyBox({ status: this.replyBoxStatus });
    }
    this.scrollToActivity();
  }

  replyCreated({
    activity,
    replyActivity
  }: {
    activity: Activity;
    replyActivity: Activity;
  }) {
    if (this.activity === activity) {
      // in case activity was changed during the reply
      const isCommentThread = activity.social_actions.reply_type === 'comment';
      const isPublic = !activity.interaction.is_private;
      const socialNetwork = activity.account.socialNetwork;
      const flattenPrivateThreads =
        socialNetwork.activity && socialNetwork.activity.flattenPrivateThreads;
      if (isCommentThread && (isPublic || !flattenPrivateThreads)) {
        this.activityThread.thread.children.activities.push(replyActivity);
      } else {
        this.activityThread.thread.siblings.newer.activities.push(
          replyActivity
        );
      }
      this.activityThread.orderThreadActivities();
      this.activityThread.thread.newReply = replyActivity;
      this.scrollToBottomOfThread();
    }
  }

  openSplitConversationPanel(conversation: Conversation, activity: Activity) {
    this._conversationSplitDetails = {
      activity,
      conversation
    };
  }

  handleStickyReplyBox({ status }) {
    this.onReplyBoxStatusChange({ status: status || ReplyBoxStatus.None });
  }

  onReplyBoxStatusChange({ status }) {
    this.replyBoxStatus = status;
  }

  scrollToActivity() {
    setTimeout(() => {
      if (!this.scroller || !this.activeActivity) {
        return;
      }

      const scrollContainer = this.scroller.nativeElement;
      const activeElement = this.activeActivity.nativeElement;
      scrollContainer.scroll({
        top: activeElement.offsetTop - activeElement.offsetHeight,
        behavior: 'smooth'
      });
    }, 0);
  }

  scrollToBottomOfThread() {
    setTimeout(() => {
      const scrollContainer = this.scroller.nativeElement;
      scrollContainer.scroll({
        top: scrollContainer.scrollHeight,
        behavior: 'smooth'
      });
    }, 0);
  }

  closeSplitConversationPanel() {
    this._conversationSplitDetails = undefined;
  }

  confirmConversationSplit() {
    const activity = this._conversationSplitDetails.activity;
    const conversation = this._conversationSplitDetails.conversation;

    conversation.split(activity).then(({ created }) => {
      this.onChangeConversation.emit({ conversation: created, activity });
      this._conversationSplitDetails = undefined;
    });
  }

  openExportConversationModal() {
    this.modal
      .open({
        component: 'ssiExportConversationModal',
        size: 'md'
      })
      .result.then(() => {});
  }

  isOutboundMessage(activity: Activity): boolean {
    if (!this._user || !activity || !activity.account || !activity.author) {
      return;
    }

    return String(activity.author.id) === String(activity.account.social_id);
  }
}
