import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoResultsComponent } from './no-results.component';

import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  imports: [CommonModule, SpinnerModule],
  exports: [NoResultsComponent],
  declarations: [NoResultsComponent]
})
export class NoResultsModule {}
