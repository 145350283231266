import './targeting-options.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { OutboxPublisher } from '@ui-resources-angular';
import { AccountTypeIdString, AccountTypeName } from '../../../enums';
import { AccordionComponent } from '../../accordion/accordion.component';
import { FacebookOptionsComponent } from './facebook-options/facebook-options.component';
import { LinkedInOptionsComponent } from './linkedin-options/linkedin-options.component';
import { NextdoorOptionsComponent } from './nextdoor-options/nextdoor-options.component';
import { AsyncTrackerFactory } from 'angular-async-tracker';

@Component({
  selector: 'ssi-targeting-options',
  templateUrl: './targeting-options.component.html'
})
export class TargetingOptionsComponent implements OnInit, OnChanges {
  @Input() post: OutboxPublisher;
  @Input() selectedAccountTypeIds: string[] = [];
  @Input() enabled = false;
  @Input() targetingPostsAccordion!: AccordionComponent;
  @Input() multipleAccounts = false;
  @Input() multipleNextdoorAccountTypes = false;
  @Input() previewToggled: boolean;

  @Output() formChange = new EventEmitter<boolean>();

  @ViewChild('facebookTargetingOptions')
  facebookTargetingOptions: FacebookOptionsComponent;
  @ViewChild('linkedinTargetingOptions')
  linkedinTargetingOptions: LinkedInOptionsComponent;
  @ViewChild('nextdoorTargetingOptions')
  nextdoorTargetingOptions: NextdoorOptionsComponent;
  @ViewChild('nextdoorUSTargetingOptions')
  nextdoorUSTargetingOptions: NextdoorOptionsComponent;

  facebookTargetingOptionsEnabled = false;
  linkedinTargetingOptionsEnabled = false;
  nextdoorTargetingOptionsEnabled = false;
  nextdoorUSTargetingOptionsEnabled = false;

  targetingLoadingTracker = this.asyncTrackerFactory.create();

  constructor(private asyncTrackerFactory: AsyncTrackerFactory) {}

  ngOnInit() {
    // todo: css styling
    // todo: translations
    // New...
    // todo: nextdoor boundaries are account based not company based
    // todo: add clear all button to accordions + No options selected message
    // todo: switch linkedin away for hardcoded json files
    // todo: add new restrictive targeting functionality (facebook)
    // todo: add new locale targeting (linkedin)
    // todo: facebook estimate audience
  }

  public readonly AccountTypeIdString = AccountTypeIdString;
  public readonly AccountTypeName = AccountTypeName;

  enable(value: AccountTypeIdString) {
    if (!this.multipleAccounts) {
      this.formChange.emit(true);
      this.enabled = true;
    }

    switch (value) {
      case AccountTypeIdString.Facebook:
        this.facebookTargetingOptionsEnabled = true;
        break;

      case AccountTypeIdString.LinkedIn:
        this.linkedinTargetingOptionsEnabled = true;
        break;

      case AccountTypeIdString.Nextdoor:
        this.nextdoorTargetingOptionsEnabled = true;
        break;

      case AccountTypeIdString.NextdoorUS:
        this.nextdoorUSTargetingOptionsEnabled = true;
        break;
    }
  }

  hasFacebookAccountType(): boolean {
    return this.selectedAccountTypeIds.includes(AccountTypeIdString.Facebook);
  }

  hasLinkedInAccountType(): boolean {
    return this.selectedAccountTypeIds.includes(AccountTypeIdString.LinkedIn);
  }

  hasNextdoorAccountType(): boolean {
    return this.selectedAccountTypeIds.includes(AccountTypeIdString.Nextdoor);
  }

  hasNextdoorUSAccountType(): boolean {
    return this.selectedAccountTypeIds.includes(AccountTypeIdString.NextdoorUS);
  }

  async copyToPost() {
    this.post.resetTargeting();

    let targeting = false;
    if (this.enabled) {
      if (this.multipleAccounts) {
        if (
          this.facebookTargetingOptionsEnabled &&
          this.facebookTargetingOptions
        ) {
          await this.facebookTargetingOptions.copyToPost();
          targeting = true;
        }
        if (
          this.linkedinTargetingOptionsEnabled &&
          this.linkedinTargetingOptions
        ) {
          await this.linkedinTargetingOptions.copyToPost();
          targeting = true;
        }
        if (
          this.nextdoorTargetingOptionsEnabled &&
          this.nextdoorTargetingOptions
        ) {
          await this.nextdoorTargetingOptions.copyToPost();
          targeting = true;
        }
        if (
          this.nextdoorUSTargetingOptionsEnabled &&
          this.nextdoorUSTargetingOptions
        ) {
          await this.nextdoorUSTargetingOptions.copyToPost();
          targeting = true;
        }
      } else {
        if (this.facebookTargetingOptions) {
          await this.facebookTargetingOptions.copyToPost();
          targeting = true;
        }
        if (this.linkedinTargetingOptions) {
          await this.linkedinTargetingOptions.copyToPost();
          targeting = true;
        }
        if (this.nextdoorTargetingOptions) {
          await this.nextdoorTargetingOptions.copyToPost();
          targeting = true;
        }
        if (this.nextdoorUSTargetingOptions) {
          await this.nextdoorUSTargetingOptions.copyToPost();
          targeting = true;
        }
      }
    }

    console.log(`post after targeting:`);
    console.dir(this.post.targeting);

    if (targeting) {
      const promise = this.post.validateTargeting();
      this.targetingLoadingTracker.add(promise);
      await promise;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => this.targetingPostsAccordion.accordionHeightRefresh());
  }
}
