import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleSwitchComponent } from './toggle-switch.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, TooltipModule],
  declarations: [ToggleSwitchComponent],
  exports: [ToggleSwitchComponent]
})
export class ToggleSwitchModule {}
