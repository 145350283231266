export function humanizeSeconds(s: number): string {
  if (!s) {
    return '0s';
  }

  const sInYear = 31536000;
  const sInDay = 86400;
  const sInHour = 3600;
  const sInMinute = 60;

  const years = Math.floor(s / sInYear);
  const days = Math.floor((s % sInYear) / sInDay);
  const hours = Math.floor(((s % sInYear) % sInDay) / sInHour);
  const mins = Math.floor((((s % sInYear) % sInDay) % sInHour) / sInMinute);
  const seconds = Math.floor((((s % sInYear) % sInDay) % sInHour) % sInMinute);

  let str = '';

  if (years > 0) {
    str += years + 'y, ';
  }

  if (days > 0) {
    str += days + 'd, ';
  }

  if (hours > 0) {
    str += hours + 'h, ';
  }

  if (mins > 0) {
    str += mins + 'm, ';
  }

  str += seconds + 's';

  return str;
}
