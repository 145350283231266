import './create-edit-listening-modal.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '../../../../../../common/components/spinner/spinner.component';
import {
  Account,
  AccountModel,
  ProfileModel,
  ProfileSearchResult
} from '@ui-resources-angular';
import { ApiService } from '../../../../../../common/services/api';
import { AccountTypeId } from '../../../../../../common/enums';
import {
  ListeningPage,
  ListeningStreamService
} from '../../../../../../common/services/api/listening-stream';

export enum ListeningModalVariant {
  'createGroup' = 'createGroup',
  'editGroup' = 'editGroup',
  'deleteGroup' = 'deleteGroup',
  'addPage' = 'addPage',
  'deletePage' = 'deletePage'
}

@Component({
  selector: 'ssi-create-edit-listening-modal',
  templateUrl: './create-edit-listening-modal.component.html'
})
export class CreateEditListeningModalComponent implements OnInit {
  @Input() actionButtonText: string = 'Save';
  @Input() title: string = 'Create a Facebook Listening group';
  @Input() variant: ListeningModalVariant = ListeningModalVariant.createGroup;
  @Input() page: ListeningPage; // ListeningPage | ProfileSearchResult;
  @Input() group: { name: string; id: string };

  modalVariant = ListeningModalVariant;

  variants: { [key in ListeningModalVariant]: any } = {
    createGroup: {
      title: 'Create a Facebook Listening group',
      description:
        'This group will be added to Insights Sources, you can then select Facebook as a source to listen to when building your stream. You can only add one Facebook page at a time',
      cancelButtonText: 'Cancel'
    },
    editGroup: {
      title: 'Edit Facebook Listening group name',
      description:
        'This is the group name that will be added to your Insights Sources to select as a source to listen to when building your stream.',
      cancelButtonText: 'Cancel'
    },
    deleteGroup: {
      title: 'Delete Facebook Listening Group',
      description:
        'Are you sure you want to delete this Facebook Listening Group? This action cannot be undone and will remove all the Facebook pages you have currently added.',
      cancelButtonText: 'Cancel'
    },
    addPage: {
      title: 'Add another Facebook page',
      description:
        'This Facebook page will be added to your group that is set up as source to listen to, when building your stream. You can only add one Facebook page at a time, with a limit of 500 pages.',
      cancelButtonText: 'Cancel'
    },
    deletePage: {
      title: 'Delete Facebook page',
      description:
        'Are you sure you want to delete this Facebook page? This cannot be undone',
      cancelButtonText: 'Cancel'
    }
  };
  accountToUse: Account;
  searching = false;
  groupName: string = '';
  groupId: string;
  pageId: string;
  accountTypeId: number = AccountTypeId.Facebook;
  pageSearchString: string;
  pageResults: ProfileSearchResult[] = [];
  selectedPages: Partial<ProfileSearchResult>[] = [];
  showAccountsDropdown = false;

  @ViewChild('pageSearchSpinner') pageSearchSpinner: SpinnerComponent;

  @Output() onSave = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private listeningStreamService: ListeningStreamService,
    private profileModel: ProfileModel,
    private accountModel: AccountModel,
    private api: ApiService
  ) {}

  async ngOnInit() {
    this.prepopulateForm();
  }

  prepopulateForm() {
    if (this.page) {
      this.selectedPages = [this.page as any];
    }
    if (this.group) {
      this.groupName = this.group.name;
      this.groupId = this.group.id;
    }
  }

  async saveGroup() {
    try {
      const createGroupReqParams = {
        name: this.groupName
      };
      const createGroupRes = await this.listeningStreamService.createGroup(
        createGroupReqParams
      );
      console.log('saveGroup createGroupRes:', createGroupRes);

      this.addPageToGroup(createGroupRes.id);
      this.onSave.emit(createGroupRes);
    } catch (error) {
      console.error('Error saving a listening group:', error);
    }
  }

  async updateGroup() {
    try {
      const reqParams = {
        name: this.groupName,
        id: this.groupId
      };
      const res = await this.listeningStreamService.updateGroup(reqParams);
      console.log('updateGroup res:', res);
      this.onSave.emit(res);
    } catch (error) {
      console.error('Error updating a listening group:', error);
    }
  }

  async deleteGroup() {
    try {
      const reqParams = {
        id: this.groupId
      };
      const res = await this.listeningStreamService.deleteGroup(reqParams);
      console.log('deleteGroup res:', res);
    } catch (error) {
      console.error('Error deleting a listening group:', error);
    }
  }

  async addPageToGroup(groupId?: string, accountTypeId?: number) {
    try {
      console.log('this.selectedPages', this.selectedPages);
      const reqParams = {
        listening_group_id: groupId ? groupId : this.groupId, // currently only one group is supported
        account_type_id: accountTypeId ? accountTypeId : AccountTypeId.Facebook,
        social_id: this.selectedPages[0].id,
        username: this.selectedPages[0].username,
        name: this.selectedPages[0].name
      };
      const res = await this.listeningStreamService.addPageToGroup(reqParams);
    } catch (error) {
      console.error('Error adding a page to the listening group:', error);
    }
  }

  async removePageFromGroup() {
    try {
      const reqParams = {
        listening_group_id: this.groupId,
        account_type_id: this.accountTypeId,
        social_id: this.pageId
      };
      const res = await this.listeningStreamService.removePageFromGroup(
        reqParams
      );
      console.log('removePageFromGroup res:', res);
    } catch (error) {
      console.error('Error removing a page from the listening group:', error);
    }
  }

  async searchForPages(event) {
    this.pageSearchSpinner.show();
    event.preventDefault();
    this.pageResults = [];

    const accs = await this.accountModel.findAccounts();
    const facebookAccounts = accs.filter(
      (a) =>
        a.account_type_id === String(AccountTypeId.Facebook) &&
        !a.is_reauth_required
    );
    this.accountToUse =
      facebookAccounts[Math.floor(Math.random() * facebookAccounts.length)];

    try {
      const searchResults = await this.profileModel.search(
        this.accountToUse.id,
        this.pageSearchString
      );

      this.showAccountsDropdown = true;
      this.pageResults = searchResults.results;
      this.pageSearchSpinner.hide();
    } catch (error) {
      console.error('Error searching for listening pages:', error);
      this.pageSearchSpinner.hide();
    }
  }

  addPage(page: ProfileSearchResult) {
    this.selectedPages.push(page);
    this.showAccountsDropdown = false;
    this.pageSearchString = null;
  }

  removePage(pageToRemove: ProfileSearchResult) {
    const pageIndexToRemove = this.selectedPages.findIndex(
      (page) => page.id === pageToRemove.id
    );
    this.selectedPages.splice(pageIndexToRemove, 1);
    this.showAccountsDropdown = false;
  }

  openDeleteGroupModal() {
    this.variant = this.modalVariant.deleteGroup;
  }

  async deletePage() {
    try {
      const reqParams = {
        listening_group_id: this.groupId,
        account_type_id: this.accountTypeId,
        social_id: this.page.id
      };
      const res = await this.listeningStreamService.removePageFromGroup(
        reqParams
      );
      this.onSave.emit(reqParams);
      console.log('removePageFromGroup res:', res);
    } catch (error) {
      this.searching = false;
      console.error('Error removing a listening page:', error);
    }
  }
}
