import './save-report-modal.component.scss';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TooltipDirective } from '../../../../../../common/directives/tooltip/tooltip.directive';
import { NotificationService } from '../../../../../../common/services/notification/notification.service';
import { CompanyPreferences } from '../../../../../../common/services/company/company.service';
import { CustomCalendarEvent } from '../../../../../../common/components/calendar-utils';
import {
  AccessOptions,
  AccessOption,
  accessOptions,
  accessOptionsIterable
} from '../../../../../../common/constants';
// import { ContentCalendarNewService } from '../../../content-calendar-new/content-calendar-new.service';

@Component({
  selector: 'ssi-save-report-modal',
  templateUrl: './save-report-modal.component.html',
  styles: []
})
export class SaveReportModalComponent implements OnInit {
  @ViewChild('deleteEventTooltip') deleteEventTooltip: TooltipDirective;

  @Input() mode: string = 'create';
  @Input() access;
  @Input() reportTitle: string = '';

  accessOptions: AccessOptions = accessOptions;
  accessOptionsIterable: AccessOption[] = accessOptionsIterable;
  selectedAccessOption = this.accessOptions.private;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.selectedAccessOption = this.access;
  }

  saveReport() {
    this.activeModal.close({
      title: this.reportTitle,
      access: this.selectedAccessOption,
      mode: this.mode
    });
  }
}
