<div class="action-buttons">
  <button class="btn btn-danger"
          *ngIf="selectedFileCount > 0"
          (click)="deleteSelectedFilesConfirmMenu.hide(); deleteSelectedFilesConfirmMenu.show($event);">
    <i class="fa fa-trash-o"></i>
    <span>Delete {{selectedFileCount}} selected files</span>
  </button>
  <ssi-menu #addFilesMenu>
    <ng-template ssiTemplateSelector="menuHead">
      <button class="btn btn-primary">
        <i class="fa fa-file"></i> <span translate="ADD_FILES_TO_CURRENT_DIRECTORY"></span> <span class="caret"></span>
      </button>
    </ng-template>
    <ng-template ssiTemplateSelector="menuBody">
      <ul class="g-list">
        <li class="g-list-item"
            (click)="createFileInActiveFolder('image')">
          <a href="javascript:;">
            <i class="fa fa-fw fa-file-image-o"></i> <span translate="IMAGE"></span>
          </a>
        </li>
        <li class="g-list-item"
            (click)="createFileInActiveFolder('video')">
          <a href="javascript:;">
            <i class="fa fa-fw fa-file-video-o"></i> <span translate="VIDEO"></span>
          </a>
        </li>
      </ul>
    </ng-template>
  </ssi-menu>
  <button class="btn btn-primary"
          (click)="newFolderName = ''; createFolderMenu.hide(); createFolderMenu.show($event);">
    <i class="fa fa-folder-open"></i> <span translate="CREATE_FOLDER_IN_CURRENT_DIRECTORY"></span>
  </button>
</div>

<div class="panel panel-default spacer-top">
  <div class="panel-body">
    <ol class="breadcrumb">
      <li *ngIf="activeFolderTree.length > 0"
          ssiDroppable
          dragOverClass="bg-dark"
          (drop)="$event.dropData.file.isSelected = true; moveSelectedFilesToParentFolder(0)">
        <a href="javascript:;"
           (click)="breadCrumbClicked(0)">
          <i class="fa fa-folder"></i> <span translate="ROOT"></span>
        </a>
      </li>
      <li *ngIf="activeFolderTree.length == 0"
          class="active">
        <i class="fa fa-folder"></i> <span translate="ROOT"></span>
      </li>
      <li *ngFor="let folder of activeFolderTree; let $index = index; let last = last;"
          [ngClass]="{'active': last}">
        <a href="javascript:;"
           *ngIf="!last"
           (click)="breadCrumbClicked($index + 1)"
           ssiDroppable
           dragOverClass="bg-dark"
           (drop)="$event.dropData.file.isSelected = true; moveSelectedFilesToParentFolder($index + 1)">
          {{ folder }}
        </a>
        <span *ngIf="last">{{ folder }}</span>
      </li>
    </ol>

    <div class="loading"
         *ngIf="loadingTracker.active;"></div>

    <div *ngIf="!loadingTracker.active">
      <div class="alert alert-info"
           *ngIf="activeFolderTree.length === 0 && activeFolderContents.files.length === 0 && activeFolderContents.folders.length === 0">
        <span translate="NO_FILES_HAVE_BEEN_UPLOADED_YET_TO_THIS_FOLDER"></span>
      </div>

      <div class="row">
        <div class="col-md-2 col-sm-3 col-xs-6"
             *ngFor="let folder of activeFolderContents.folders">
          <div class="panel panel-default text-center panel-folder"
               ssiDroppable
               dragOverClass="panel-primary"
               (drop)="$event.dropData.file.isSelected = true; moveSelectedFilesToChildFolder(folder)">
            <div class="panel-heading text-wrap">
              <div class="align-vertical">
                <a href="javascript:;"
                   (click)="loadFolder(folder)">{{ folder.name }}</a>
              </div>
            </div>
            <div class="panel-body">
              <div class="align-vertical">
                <a href="javascript:;"
                   (click)="loadFolder(folder)">
                  <i class="fa fa-5x fa-folder-open-o"></i>
                </a>
              </div>
            </div>
            <div class="panel-footer">
              <button class="btn btn-sm btn-danger"
                      [ssiTooltip]="'DELETE_FOLDER' | translate"
                      tooltipWindowClass="tooltip-chunky"
                      [tooltipAppendToBody]="true"
                      (click)="deleteFolderConfirmMenu.hide(); deleteFolderConfirmMenu.show($event, {folder: folder});">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-3 col-xs-6"
             *ngFor="let file of activeFolderContents.files">
          <div class="panel panel-default text-center panel-file"
               [ngClass]="{'panel-primary': file.isSelected}"
               ssiDraggable
               [dropData]="{file: file}"
               [dragAxis]="{ x: true, y: true }"
               [validateDrag]=""
               [touchStartLongPress]="{ delay: 300, delta: 30 }">
            <div class="panel-heading text-wrap mouse-pointer"
                 (click)="toggleFile(file)">
              <div class="align-vertical">
                <a href="{{ file.url }}"
                   target="_blank"
                   (click)="$event.stopPropagation()">{{ file.name | truncate: 60 }}</a>
              </div>
            </div>
            <div class="panel-body mouse-pointer"
                 (click)="toggleFile(file)">
              <div class="align-vertical">
                <a href="{{ file.url }}"
                   (click)="$event.stopPropagation()"
                   target="_blank"
                   *ngIf="file.mimetype.startsWith('video/')">
                  <i class="fa fa-5x fa-video-camera"></i>
                </a>
                <img class="img-responsive mouse-pointer"
                     style="height: 100px; margin: auto"
                     src="{{transformImage(file.url, transformImageParams)}}"
                     *ngIf="file.mimetype.startsWith('image/')"
                     (click)="$event.stopPropagation()"
                     [ssiEnlargeImage]="file.url">
                <i class="fa fa-5x fa-file"
                   *ngIf="!file.mimetype.startsWith('video/') && !file.mimetype.startsWith('image/')"></i>
              </div>
            </div>
            <ul class="list-group mouse-pointer"
                (click)="toggleFile(file)">
              <li class="list-group-item">
                <i class="fa fa-file"
                   [ssiTooltip]="file.size | prettyBytes"
                   tooltipWindowClass="tooltip-chunky"
                   [tooltipAppendToBody]="true">
                </i>
                <i class="fa fa-clock-o"
                   [ssiTooltip]="'LAST_MODIFIED__DATE_' | translate:{date: (file.last_modified | date:'medium')}"
                   tooltipWindowClass="tooltip-chunky"
                   [tooltipAppendToBody]="true">
                </i>
                <i class="fa fa-user"
                   *ngIf="file.metadata.created_by"
                   [ssiTooltip]="'CREATED_BY__NAME_' | translate:{name: getName(file.metadata.created_by)}"
                   tooltipWindowClass="tooltip-chunky"
                   [tooltipAppendToBody]="true">
                </i>
                <i class="fa fa-comment"
                   *ngIf="file.metadata.outbox_post?.text"
                   [ssiTooltip]="'SUGGESTED_POST_TEXT__TEXT_' | translate:{text: file.metadata.outbox_post.text}"
                   tooltipWindowClass="tooltip-chunky"
                   [tooltipAppendToBody]="true">
                </i>
              </li>
            </ul>
            <div class="panel-footer">
              <button class="btn btn-sm btn-primary"
                      [ssiTooltip]="'EDIT_FILE' | translate"
                      tooltipWindowClass="tooltip-chunky"
                      [tooltipAppendToBody]="true"
                      (click)="editFile(file)">
                <i class="fa fa-pencil"></i>
              </button>
              <button class="btn btn-sm btn-danger"
                      [ssiTooltip]="'DELETE_FILE' | translate"
                      tooltipWindowClass="tooltip-chunky"
                      [tooltipAppendToBody]="true"
                      (click)="deleteFileConfirmMenu.hide(); deleteFileConfirmMenu.show($event, {file: file});">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ssi-context-menu #deleteFileConfirmMenu>
  <ng-template let-ctx>
    <ssi-confirm-action [title]="'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_FILE_THIS_ACTION_CANNOT_BE_UNDONE' | translate"
                        dismissText="Cancel"
                        confirmText="Delete"
                        dismissTextColour="#838eab"
                        confirmTextColour="#f88c68"
                        (onDismiss)="deleteFileConfirmMenu.hide();"
                        (onConfirm)="deleteFileConfirmMenu.hide(); deleteItemInActiveFolder(ctx.file.name)"></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>

<ssi-context-menu #deleteSelectedFilesConfirmMenu>
  <ng-template let-ctx>
    <ssi-confirm-action [title]="'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THESE__SELECTEDFILECOUNT__FILES_THIS_CANNOT_BE_UNDONE' | translate: {selectedFileCount: selectedFileCount}"
                        dismissText="Cancel"
                        confirmText="Delete"
                        dismissTextColour="#838eab"
                        confirmTextColour="#f88c68"
                        (onDismiss)="deleteSelectedFilesConfirmMenu.hide();"
                        (onConfirm)="deleteSelectedFilesConfirmMenu.hide(); deleteSelectedFiles()"></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>

<ssi-context-menu #deleteFolderConfirmMenu>
  <ng-template let-ctx>
    <ssi-confirm-action [title]="'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_FOLDER_AND_ALL_OF_ITS_CONTENTS_THIS_ACTION_CANNOT_BE_UNDONE' | translate"
                        dismissText="Cancel"
                        confirmText="Delete"
                        dismissTextColour="#838eab"
                        confirmTextColour="#f88c68"
                        (onDismiss)="deleteFolderConfirmMenu.hide();"
                        (onConfirm)="deleteFolderConfirmMenu.hide(); deleteItemInActiveFolder(ctx.folder.name)"></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>

<ssi-context-menu #createFolderMenu>
  <ng-template let-ctx>
    <div class="g-box-shadow-bottom create-folder-menu-container">
      <h4>Create new folder</h4>
      <div class="input-group">
        <input class="form-control"
               placeholder="{{ 'FOLDER_NAME' | translate }}"
               [(ngModel)]="newFolderName">

        <span class="input-group-btn">
          <button class="btn btn-primary"
                  [disabled]="!newFolderName"
                  (click)="createFolderMenu.hide(); createFolderInActiveFolder(newFolderName);"
                  translate="CREATE">
          </button>
        </span>
      </div>
    </div>
  </ng-template>
</ssi-context-menu>