import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InstagramStoryStatsComponent } from './instagram-story-stats.component';
import { StatChangeModule } from '../stat-change/stat-change.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { IconModule } from '../../../../../common/components/icon/icon.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { FactOverviewModule } from '../fact-overview/fact-overview.module';

@NgModule({
  declarations: [InstagramStoryStatsComponent],
  imports: [
    CommonModule,
    BigNumberModule,
    IconModule,
    StatChangeModule,
    TooltipModule,
    FactOverviewModule
  ],
  exports: [InstagramStoryStatsComponent]
})
export class InstagramStoryStatsModule {}
