import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageWebhooksComponent } from './manage-webhooks.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    AlertsModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.manageWebhooks',
          url: '/settings/webhooks',
          component: ManageWebhooksComponent
        }
      ]
    })
  ],
  declarations: [ManageWebhooksComponent]
})
export class ManageWebhooksModule {}
