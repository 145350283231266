import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentimentTextComponent } from './sentiment-text.component';
import { GaugeChartModule } from '../../gauge-chart/gauge-chart.module';
import { GaugeModule } from 'angular-gauge';

@NgModule({
  imports: [CommonModule, GaugeChartModule, GaugeModule.forRoot()],
  declarations: [SentimentTextComponent],
  exports: [SentimentTextComponent]
})
export class SentimentTextModule {}
