import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverOffClickDirective } from './popover-off-click.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PopoverOffClickDirective],
  exports: [PopoverOffClickDirective]
})
export class PopoverOffClickModule {}
