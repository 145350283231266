<div class="theme-detectors-container">
  <div class="section-container">
    <ssi-theme-detector-create-edit [themeDetector]="themeDetectors[0]"></ssi-theme-detector-create-edit>
  </div>

  <div class="section-delimiter"></div>

  <div class="section-container detected-themes-section">
    <ssi-detected-themes [detectedThemesResponse]="responsesPerPage[pageChangeParams?.currentPage]"
                         [itemsPerPage]="itemsPerPage"
                         [itemsCount]="getLoadedPostsCount()"
                         [loading]="loading"
                         (reloadResults)="reInitResults()"
                         (openDrilldownModal)="openResultsModal($event)"
                         (pageChange)="onPageChange($event)"></ssi-detected-themes>
  </div>

  <div class="section-delimiter"></div>
</div>