import './cl-dropdown-select-f.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import { Account, AccountModel } from '@ui-resources-angular';
import { groupBy } from '../../../../../common/utils';

@Component({
  selector: 'ssi-cl-dropdown-select-f',
  templateUrl: './cl-dropdown-select-f.component.html',
  styles: []
})
export class ClDropdownSelectFComponent implements OnInit {
  @Input() accounts: Account[] = [];
  selectedAccounts: Account[] = [];

  createTagButtonVisible = false;

  constructor() {}

  ngOnInit() {}

  selectedAccountsChanged(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
  }
  create(value: string): void {
    console.log('create: ', value);
  }

  onOptionsFiltered(params: {
    filterTerm: string;
    filteredOptions: Account[];
  }): void {
    const optionExists = params.filteredOptions.some(
      (o) => o.name === params.filterTerm
    );
    this.createTagButtonVisible = !optionExists;
  }
}
