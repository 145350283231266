<div class="inner">
  <img
    *ngIf="!! url"
    [ngClass]="class"
    alt="avatar for {{ name }}"
    src="{{ url }}"
  >

  <span
    *ngIf="! url"
    [ngClass]="class"
    class="spinner"
  >
    <i class="ssi ssi-loading ssi-spin"></i>
  </span>

  <span
    *ngIf="!! statistic && !isOfflineAgent"
    class="statistic"
  >
    {{ statistic }}
  </span>

  <span
    *ngIf="!! isVerifiedVisitor"
    class="verified"
  >
    <i class="ssi ssi-verified-protection"></i>
  </span>
</div>
