<div class="sentiment-chart-container">
  <ssi-highchart [config]="sentimentChartConfig"></ssi-highchart>
  <div class="footer"
       [style.background-color]="sentiment?.bgColor2">
    <div class="left">
      <span>{{averageSentimentValue}}/5</span>
    </div>
    <div class="right">
      <ng-container *ngIf="sentiment === sentiments.neutral">
        <span>Sentiment is divided</span>
        <i class="ssi ssi-split-sentiment passive-new-icon"
           [style.color]="sentiment?.iconColor2"></i>
      </ng-container>
      <ng-container *ngIf="sentiment !== sentiments.neutral">
        <span>Mostly {{sentiment?.labelNew | translate}}</span>
        <i class="ssi {{sentiment?.icon}}"
           [style.color]="sentiment?.iconColor2"
           [style.background-color]="sentiment?.iconBgColor2"></i>
      </ng-container>
    </div>
  </div>
</div>