<div class="account-summary-section section-grid">
  <ssi-account-summary-card
    *ngFor="let network of socialAccounts"
    class="section-grid-item"
    [accountTitle]="network.socialNetwork.accountTypeLabel"
    [accountIcon]="network.socialNetwork.icon.web"
    [accountAmount]="'' + network.accounts.length"
    [messageAmount]="network.totals.activity ? '' + network.totals.activity : ''"
    [conversationAmount]="stats.conversation.period.current ? '' + network.totals.conversation : ''"
  ></ssi-account-summary-card>
</div>
<h3 *ngIf="!stats.conversation.period.current" class="section-subtitle">Inbox Summary</h3>
<h3 *ngIf="stats.conversation.period.current" class="section-subtitle">Individual Messages</h3>
<div class="section-grid section-grid-individual-msg">
  <ssi-total-stat-card
    [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_PUBLIC_COMMENTS_OR_MENTIONS_AND_PRIVATE_OR_DIRECT_MESSAGES_RECEIVED_FOR_THE_REPORTED_TIME_PERIOD' | translate"
    tooltipWindowClass="tooltip-chunky-big"
    class="section-grid-item"
    icon="ssi ssi-inbound-messages-stat"
    iconSize="46"
    [label]="'INBOUND_MESSAGES' | translate"
    [value]="stats.activity.period.current.inbound.count"
    [growthValue]="stats.activity.period.previous && '' + (stats.activity.period.current.inbound.count - stats.activity.period.previous.inbound.count | abs)"
    [growth]="stats.activity.period.previous && stats.activity.period.current.inbound.count - stats.activity.period.previous.inbound.count > 0"
  ></ssi-total-stat-card>
  <ssi-total-stat-card
    [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_DIFFERENT_PEOPLE_WHO_POSTED_A_PUBLIC_COMMENT_OR_MENTION_OR_MESSAGED_YOU_DIRECTLY_OR_PRIVATELY' | translate"
    tooltipWindowClass="tooltip-chunky-big"
    class="section-grid-item"
    icon="ssi ssi-teams"
    iconSize="40"
    [label]="'FROM_CUSTOMERS' | translate"
    [value]="stats.activity.period.current.inbound.users_engaged"
    [growthValue]="stats.activity.period.previous && '' + (stats.activity.period.current.inbound.users_engaged - stats.activity.period.previous.inbound.users_engaged | abs )"
    [growth]="stats.activity.period.previous && stats.activity.period.current.inbound.users_engaged - stats.activity.period.previous.inbound.users_engaged > 0"
  ></ssi-total-stat-card>
  <ssi-total-stat-card
    [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_PUBLIC_COMMENTS_AND_DIRECT_OR_PRIVATE_MESSAGES_YOU_SENT_IN_THE_REPORTED_TIME_PERIOD' | translate"
    tooltipWindowClass="tooltip-chunky-big"
    class="section-grid-item"
    icon="ssi ssi-total-reply-stat"
    iconSize="36"
    [label]="'OUR_REPLIES' | translate"
    [value]="stats.activity.period.current.replies.count"
    [growthValue]="stats.activity.period.previous && '' + (stats.activity.period.current.replies.count - stats.activity.period.previous.replies.count | abs)"
    [growth]="stats.activity.period.previous && stats.activity.period.current.replies.count - stats.activity.period.previous.replies.count > 0"
  ></ssi-total-stat-card>
  <ssi-total-stat-card
    [ssiTooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FOR_A_CUSTOMER_TO_RECEIVE_A_REPLY_ACROSS_ALL_INBOUND_MESSAGES' | translate"
    tooltipWindowClass="tooltip-chunky-big"
    class="section-grid-item"
    icon="ssi ssi-av-response-time-stat"
    iconSize="36"
    [label]="'AV_RESPONSE_TIME' | translate"
    [valueIsString]="true"
    [lowerIsPositive]="true"
    [value]="stats.activity.period.current.inbound.average_response_time.total | secondsToHumanTime"
    [growthValue]="stats.activity.period.previous && '' + (stats.activity.period.current.inbound.average_response_time.total - stats.activity.period.previous.inbound.average_response_time.total | abs | secondsToHumanTime)"
    [growth]="stats.activity.period.previous && stats.activity.period.current.inbound.average_response_time.total - stats.activity.period.previous.inbound.average_response_time.total < 0"
  ></ssi-total-stat-card>
</div>
<ng-container *ngIf="stats.conversation.period.current">
  <h3 class="section-subtitle">Conversations</h3>
  <div class="section-grid">
    <ssi-total-stat-card
      [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_CONVERSATIONS_THAT_TOOK_PLACE_DURING_THE_REPORTED_TIME_PERIOD_CONVERSATIONS_ARE_GROUPS_OF_INBOUND_MESSAGES_AND_OUTBOUND_REPLIES_FROM_THE_SAME_THREAD' | translate"
      tooltipWindowClass="tooltip-chunky-big"
      class="section-grid-item"
      icon="ssi ssi-total-conversations-stat"
      iconSize="26"
      iconColor="#89A4EA"
      iconBackgroundColor="#EDF1FC"
      height="200"
      [label]="'TOTAL_CONVERSATIONS' | translate"
      [value]="stats.conversation.period.current.count_conversations"
      [growthValue]="stats.activity.period.previous && '' + (stats.conversation.period.current.count_conversations - stats.conversation.period.previous.count_conversations | abs)"
      [growth]="stats.activity.period.previous && stats.conversation.period.current.count_conversations - stats.conversation.period.previous.count_conversations > 0"
    ></ssi-total-stat-card>
    <ssi-total-stat-card
      class="section-grid-item"
      icon="ssi ssi-av-total-messages-stat"
      iconSize="26"
      iconColor="#F88C68"
      iconBackgroundColor="#FFF1ED"
      height="200"
      [label]="'AV_TOTAL_MESSAGES' | translate"
      [value]="stats.conversation.period.current.avg_messages | roundDecimals"
      [growthValue]="stats.activity.period.previous && '' + (stats.conversation.period.current.avg_messages - stats.conversation.period.previous.avg_messages | abs | roundDecimals)"
      [growth]="stats.activity.period.previous && stats.conversation.period.current.avg_messages - stats.conversation.period.previous.avg_messages > 0"
    ></ssi-total-stat-card>
    <ssi-total-stat-card
      class="section-grid-item"
      icon="ssi ssi-total-reply-stat"
      iconSize="26"
      iconColor="#12ACA4"
      iconBackgroundColor="#EAF9F8"
      height="200"
      [label]="'AV_TOTAL_REPLIES' | translate"
      [value]="stats.conversation.period.current.avg_replies | roundDecimals"
      [growthValue]="stats.activity.period.previous && '' + (stats.conversation.period.current.avg_replies - stats.conversation.period.previous.avg_replies | abs | roundDecimals)"
      [growth]="stats.activity.period.previous && stats.conversation.period.current.avg_replies - stats.conversation.period.previous.avg_replies > 0"
    ></ssi-total-stat-card>
    <ssi-total-stat-card
      [ssiTooltip]="'THIS_TELLS_YOU_THE_AVERAGE_NUMBER_OF_INBOUND_MESSAGES_YOU_RECEIVED_IN_RESPONSE_TO_YOUR_OUTBOUND_REPLIES_PER_CONVERSATION' | translate"
      tooltipWindowClass="tooltip-chunky-big"
      class="section-grid-item"
      icon="ssi ssi-av-replies-per-message-stat"
      iconSize="18"
      iconColor="#D41D68"
      iconBackgroundColor="#FFECF4"
      height="200"
      [label]="'AV_REPLIES_PER_MESSAGE' | translate"
      [value]="stats.conversation.period.current.avg_replies_per_message | roundDecimals"
      [growthValue]="stats.activity.period.previous && '' + (stats.conversation.period.current.avg_replies_per_message - stats.conversation.period.previous.avg_replies_per_message | abs | roundDecimals)"
      [growth]="stats.activity.period.previous && stats.conversation.period.current.avg_replies_per_message - stats.conversation.period.previous.avg_replies_per_message > 0"
    ></ssi-total-stat-card>
    <ssi-total-stat-card
      [ssiTooltip]="'THIS_TELLS_YOU_THE_AVERAGE_NUMBER_OF_INBOUND_MESSAGES_AND_OUTBOUND_REPLIES_SENT_ACROSS_ALL_CONVERSATIONS_DIVIDED_BY_THE_TOTAL_NUMBER_OF_CONVERSATIONS' | translate"
      tooltipWindowClass="tooltip-chunky-big"
      class="section-grid-item"
      icon="ssi ssi-experience"
      iconSize="26"
      iconColor="#B2C614"
      iconBackgroundColor="#F5F7DE"
      height="200"
      [label]="'AV_CONVERSATION_LENGTH' | translate"
      [value]="stats.activity.period.current.replies.average_conversation_length | roundDecimals"
      [growthValue]="stats.activity.period.previous && '' + (stats.activity.period.current.replies.average_conversation_length - stats.activity.period.previous.replies.average_conversation_length | abs | roundDecimals)"
      [growth]="stats.activity.period.previous && stats.activity.period.current.replies.average_conversation_length - stats.activity.period.previous.replies.average_conversation_length > 0"
    ></ssi-total-stat-card>
    <ssi-total-stat-card
      [ssiTooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FOR_A_CUSTOMER_TO_RECEIVE_A_REPLY_TO_THEIR_FIRST_INBOUND_MESSAGE' | translate"
      tooltipWindowClass="tooltip-chunky-big"
      class="section-grid-item"
      icon="ssi ssi-av-response-time-stat"
      iconSize="26"
      iconColor="#F0B427"
      iconBackgroundColor="#FBF3E2"
      height="200"
      [label]="'AV_FIRST_RESPONSE_TIME' | translate"
      [valueIsString]="true"
      [lowerIsPositive]="true"
      [value]="stats.conversation.period.current.avg_first_response_time | secondsToHumanTime"
      [growthValue]="stats.activity.period.previous && '' + (stats.conversation.period.current.avg_first_response_time - stats.conversation.period.previous.avg_first_response_time | abs | secondsToHumanTime)"
      [growth]="stats.activity.period.previous && stats.conversation.period.current.avg_first_response_time - stats.conversation.period.previous.avg_first_response_time < 0"
    ></ssi-total-stat-card>
    <ssi-total-stat-card
      [ssiTooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FROM_A_CUSTOMER_RECEIVING_THEIR_FIRST_REPLY_TO_A_CONVERSATION_BEING_RESOLVED' | translate"
      tooltipWindowClass="tooltip-chunky-big"
      class="section-grid-item"
      icon="ssi ssi-av-handling-stat"
      iconSize="26"
      iconColor="#14BAE3"
      iconBackgroundColor="#E7F8FC"
      height="200"
      [label]="'AV_HANDLING_TIME' | translate"
      [valueIsString]="true"
      [lowerIsPositive]="true"
      [value]="stats.conversation.period.current.avg_handling_time | secondsToHumanTime"
      [growthValue]="stats.activity.period.previous && '' + (stats.conversation.period.current.avg_handling_time - stats.conversation.period.previous.avg_handling_time | abs | secondsToHumanTime)"
      [growth]="stats.activity.period.previous && stats.conversation.period.current.avg_handling_time - stats.conversation.period.previous.avg_handling_time < 0"
    ></ssi-total-stat-card>
    <ssi-total-stat-card
      [ssiTooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FROM_A_CUSTOMER_SENDING_THEIR_FIRST_INBOUND_MESSAGE_TO_A_CONVERSATION_BEING_RESOLVED' | translate"
      tooltipWindowClass="tooltip-chunky-big"
      class="section-grid-item"
      icon="ssi ssi-validations-lightbox"
      iconSize="26"
      iconColor="#425DEC"
      iconBackgroundColor="#F6F7FE"
      height="200"
      [label]="'AV_RESOLUTION_TIME' | translate"
      [valueIsString]="true"
      [lowerIsPositive]="true"
      [value]="stats.conversation.period.current.avg_resolved_time | secondsToHumanTime"
      [growthValue]="stats.activity.period.previous && '' + ((stats.conversation.period.current.avg_first_response_time + stats.conversation.period.current.avg_handling_time) - (stats.conversation.period.previous.avg_first_response_time + stats.conversation.period.previous.avg_handling_time) | abs | secondsToHumanTime)"
      [growth]="stats.activity.period.previous && (stats.conversation.period.current.avg_first_response_time + stats.conversation.period.current.avg_handling_time) - (stats.conversation.period.previous.avg_first_response_time + stats.conversation.period.previous.avg_handling_time) < 0"
    ></ssi-total-stat-card>
  </div>
</ng-container>