export const able = `able`;
export const active = `active`;
export const activity = `activity`;
export const activities = `activities`;
export const add = `add`;
export const agent = `agent`;
export const and = `and`;
export const angular = `angular`;
export const application = `application`;
export const april = `april`;
export const are = `are`;
export const assign = `assign`;
export const attach = `attach`;
export const august = `august`;
export const author = `author`;
export const available = `available`;
export const away = `away`;
export const busy = `busy`;
export const cache = `cache`;
export const chat = `chat`;
export const company = `company`;
export const companies = `companies`;
export const conversation = `conversation`;
export const day = `day`;
export const december = `december`;
export const deleted = `deleted`;
export const denied = `denied`;
export const detail = `detail`;
export const dis = `dis`;
export const ed = `ed`;
export const embed = `embed`;
export const enter = `enter`;
export const est = `est`;
export const event = `event`;
export const february = `february`;
export const file = `file`;
export const grant = `grant`;
export const go = `go`;
export const have = `have`;
export const hour = `hour`;
export const image = `image`;
export const inactive = `inactive`;
export const ing = `ing`;
export const ion = `ion`;
export const is = `is`;
export const it = `it`;
export const january = `january`;
export const july = `july`;
export const june = `june`;
export const line = `line`;
export const link = `link`;
export const list = `list`;
export const live = `live`;
export const long = `long`;
export const march = `march`;
export const may = `may`;
export const ment = `ment`;
export const message = `message`;
export const meta = `meta`;
export const minute = `minute`;
export const mode = `mode`;
export const network = `network`;
export const notification = `notification`;
export const november = `november`;
export const now = `now`;
export const october = `october`;
export const off = `off`;
export const old = `old`;
export const on = `on`;
export const open = `open`;
export const own = `own`;
export const preview = `preview`;
export const push = `push`;
export const receive = `receive`;
export const remove = `remove`;
export const request = `request`;
export const response = `response`;
export const robot = `robot`;
export const september = `september`;
export const short = `short`;
export const size = `size`;
export const smooth = `smooth`;
export const stale = `stale`;
export const state = `state`;
export const success = `success`;
export const suggest = `suggest`;
export const task = `task`;
export const test = `test`;
export const text = `text`;
export const toggle = `toggle`;
export const total = `total`;
export const update = `update`;
export const upload = `upload`;
export const use = `use`;
export const value = `value`;
export const visitor = `visitor`;
export const wait = `wait`;
export const widget = `widget`;
export const you = `you`;

// formed from the above

export const added = `${add}ed`;
export const agents = `${agent}s`;
export const ago = `a${go}`;
export const assigned = `${assign}${ed}`;
export const attachment = `${attach}${ment}`;
export const attachments = `${attachment}s`;
export const applications = `${application}s`;
export const authored = `${author}${ed}`;
export const authors = `${author}s`;
export const chats = `${chat}s`;
export const conversations = `${conversation}s`;
export const days = `${day}s`;
export const details = `${detail}s`;
export const disable = `${dis}${able}`;
export const disabled = `${disable}d`;
export const disables = `${disable}s`;
export const edit = `${ed}${it}`;
export const edits = `${edit}s`;
export const embeds = `${embed}s`;
export const enabled = `en${able}d`;
export const enables = `en${able}s`;
export const events = `${event}s`;
export const enters = `${enter}s`;
export const granted = `${grant}${ed}`;
export const going = `${go}${ing}`;
export const hours = `${hour}s`;
export const images = `${image}s`;
export const files = `${file}s`;
export const links = `${link}s`;
export const lists = `${list}s`;
export const longest = `${long}${est}`;
export const messages = `${message}s`;
export const minutes = `${minute}s`;
export const modes = `${mode}s`;
export const networks = `${network}s;`;
export const notifications = `${notification}s`;
export const offline = `${off}${line}`;
export const oldest = `${old}${est}`;
export const ongoing = `${on}${going}`;
export const online = `${on}${line}`;
export const owned = `${own}${ed}`;
export const owns = `${own}s`;
export const pushed = `${push}${ed}`;
export const pushes = `${push}es`;
export const received = `${receive}d`;
export const removed = `${remove}d`;
export const requests = `${request}s`;
export const resize = `re${size}`;
export const responses = `${response}s`;
export const robots = `${robot}s`;
export const shortest = `${short}${est}`;
export const stalest = `${stale}${est}`;
export const states = `${state}s`;
export const suggestion = `${suggest}${ion}`;
export const suggestions = `${suggestion}s`;
export const tasks = `${task}s`;
export const unassign = `un${assign}`;
export const unassigned = `${unassign}${ed}`;
export const updated = `${update}d`;
export const uploads = `${upload}s`;
export const user = `${use}r`;
export const users = `${user}s`;
export const values = `${value}s`;
export const visitors = `${visitor}s`;
export const waiting = `${wait}${ing}`;

export default Object.freeze({
  able,

  active,
  activity,
  activities,

  add,
  added,

  agent,
  agents,

  ago,

  and,

  angular,

  application,
  applications,

  are,

  assign,
  assigned,

  attach,
  attachment,
  attachments,

  author,
  authored,
  authors,

  available,

  away,

  busy,

  cache,

  chat,
  chats,

  company,
  companies,

  conversation,
  conversations,

  day,
  days,

  default: `default`,

  deleted,

  denied,

  detail,
  details,

  disable,
  disabled,
  disables,

  ed,
  edit,
  edits,

  embed,
  embeds,

  enabled,
  enables,

  enter,
  enters,

  est,

  event,
  events,

  file,
  files,

  grant,
  granted,

  go,
  going,

  have,

  hour,
  hours,

  image,
  images,

  inactive,

  ing,

  is,

  it,

  line,

  link,
  links,

  list,
  lists,

  live,

  long,
  longest,

  ment,

  message,
  messages,

  meta,

  minute,
  minutes,

  mode,
  modes,

  network,
  networks,

  new: 'new',

  notification,
  notifications,

  now,

  off,

  offline,

  old,
  oldest,

  on,

  ongoing,

  online,

  open,

  own,
  owned,
  owns,

  preview,

  push,
  pushed,
  pushes,

  receive,
  received,

  remove,
  removed,

  request,
  requests,

  resize,

  response,
  responses,

  robot,
  robots,

  short,
  shortest,

  size,

  smooth,

  stale,
  stalest,

  state,
  states,

  success,

  suggest,
  suggestion,
  suggestions,

  task,
  tasks,

  test,

  text,

  toggle,

  total,

  unassign,
  unassigned,

  update,
  updated,

  upload,
  uploads,

  use,
  user,
  users,

  value,
  values,

  visitor,
  visitors,

  wait,
  waiting,

  widget,

  you
});
