import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageAppsComponent } from './manage-apps.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    AlertsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageApps',
          url: '/settings/appstore',
          component: ManageAppsComponent
        }
      ]
    })
  ],
  declarations: [ManageAppsComponent]
})
export class ManageAppsModule {}
