/* tslint:disable max-classes-per-file */
import {
  Activity,
  ActivityAuthor,
  ActivityStatus,
  Conversation,
  ConversationVisibility,
  User,
  Account,
  ActivityMedia,
  Record,
  Model
} from '@ui-resources-angular';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractQuery,
  QueryFilter,
  QueryFilterOption
} from '../../abstract-query-helper/abstract-query-helper.service';
import { Chunk, groupArray, KeyValueObject } from '../../../util';
import { appInjector } from '../../../../../angular/app-injector';
import { Team, TeamsService, ColleaguesService, Colleague } from '../../api';
import moment from 'moment';
import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { uniqBy } from 'lodash-es';

export const NOT_ASSIGNED = 'no-one';
export const ME_OR_MY_TEAMS = 'me_or_my_teams';

export interface InboxSortOption {
  value: string;
  order: string;
  translateLabel: string;
  isDefault: boolean;
  groupBy: InboxQueryResultGroupByType;
  sortResults?<T>(
    records: Conversation[] | Activity[]
  ): Conversation[] | Activity[];
}

export function sortByLabel(itemA, itemB) {
  return itemA.label.localeCompare(itemB.label);
}

export function getUserOptions(
  colleagues: Colleague[]
): Array<QueryFilterOption<Colleague>> {
  return colleagues
    .filter((colleague) => colleague.is_active)
    .map((colleague) => ({
      value: colleague,
      label: colleague.fullName,
      trackBy: colleague.id
    }))
    .sort(sortByLabel);
}

export function getTeamOptions(teams: Team[]): Array<QueryFilterOption<Team>> {
  return teams
    .map((team) => ({ value: team, label: team.name, trackBy: team.id }))
    .sort(sortByLabel);
}

export function getVisibilityFilter(
  extra: Partial<InboxQueryFilter>,
  translate: TranslateService
) {
  return {
    ...extra,
    type: 'selectSingle',
    isDefault(value) {
      return value === extra.defaultValue;
    },
    translateLabel: 'PUBLIC__PRIVATE',
    icon: 'fa-eye',
    selectedText(value) {
      if (!Array.isArray(extra && extra.options)) {
        return;
      }

      return translate.instant(
        extra.options.find((option) => option.value === value).label
      );
    }
  };
}

export function getStatusFilter(
  extra: Partial<InboxQueryFilter>,
  translate: TranslateService
) {
  return {
    ...extra,
    type: 'selectMultiple',
    translateLabel: 'STATUS',
    icon: 'fa-exclamation-circle',
    selectedText(statuses: string[]) {
      return translate.instant('MESSAGE_STATUS__STATUSES_', {
        statuses: statuses.map((status) => status.toLowerCase()).join(', ')
      });
    }
  };
}

export function getAssignedFilter(
  extra: Partial<InboxQueryFilter>,
  translate: TranslateService,
  authUser: User,
  {
    prefixes: { user: userPrefix = 'user:', team: teamPrefix = 'team:' } = {}
  } = {}
) {
  return {
    ...extra,
    type: 'user',
    selectTeams: true,
    isDefault(value) {
      return !value;
    },
    serialize(userOrTeam) {
      if (userOrTeam) {
        if (userOrTeam instanceof Colleague) {
          if (+userOrTeam.id === +authUser.id) {
            return 'me';
          } else {
            return `${userPrefix}${userOrTeam.id}`;
          }
        } else if (userOrTeam instanceof Team) {
          return `${teamPrefix}${userOrTeam.id}`;
        } else if (userOrTeam === NOT_ASSIGNED) {
          return NOT_ASSIGNED;
        }
      } else {
        return userOrTeam;
      }
    },
    deserialize(idString) {
      if (idString === 'me') {
        idString = `${userPrefix}${authUser.id}`;
      }

      if (idString) {
        if (idString.startsWith(userPrefix)) {
          const colleagueId = idString.replace(userPrefix, '');
          const colleaguesService = appInjector().get(ColleaguesService);
          return colleaguesService.store.find(colleagueId);
        } else if (idString.startsWith(teamPrefix)) {
          const teamId = idString.replace(teamPrefix, '');
          const teamsService = appInjector().get(TeamsService);
          return teamsService.store.find(teamId);
        } else if (idString === ME_OR_MY_TEAMS) {
          return ME_OR_MY_TEAMS;
        } else {
          return NOT_ASSIGNED;
        }
      } else {
        return idString;
      }
    },
    translateLabel: 'ASSIGNED_TO',
    icon: 'fa-user',
    selectedText(userOrTeam) {
      if (userOrTeam === NOT_ASSIGNED) {
        return translate.instant('UNASSIGNED_ONLY');
      }
      if (userOrTeam === ME_OR_MY_TEAMS) {
        return translate.instant('ASSIGNED_TO_ME_OR_MY_TEAMS');
      }
      if (userOrTeam) {
        const name =
          userOrTeam instanceof Colleague
            ? userOrTeam.fullName
            : userOrTeam.name;
        return translate.instant('ASSIGNED_TO__NAME_', { name });
      } else {
        return '';
      }
    }
  };
}

export type OnInboxQuerySearch = (
  query: KeyValueObject,
  pagination: { limit: number; offset: number }
) => void;

export interface InboxQueryFilter extends QueryFilter {
  localRefresh?: boolean;
  users?: Array<QueryFilterOption<Colleague>>;
  teams?: Array<QueryFilterOption<Team>>;
  additionalOptions?: QueryFilterOption[];
  matches(value: any, record: Activity | Conversation): boolean;
  getDefaultValue(): any;
}

export function getSavedSortAndOrder(
  savedQuery: KeyValueObject<any>,
  sortOptions: KeyValueObject<InboxSortOption>
): { sort: string; order: string } {
  const sortOptionsValues = [...Object.values(sortOptions)];
  const savedQuerySortValueExists =
    savedQuery &&
    savedQuery.order &&
    savedQuery.sort &&
    sortOptionsValues.some((option) => option.value === savedQuery.sort);

  if (savedQuerySortValueExists) {
    return { sort: savedQuery.sort, order: savedQuery.order };
  }

  const defaultOption = sortOptionsValues.find((option) => option.isDefault);
  return { sort: defaultOption.value, order: defaultOption.order };
}

export function getAccountsWithViewInboxPermission(
  accounts: Account[],
  authUser: User
): Account[] {
  return accounts.filter((account) =>
    authUser.hasAccountPermission(account, 'view_inbox')
  );
}

export enum InboxQueryResultListItemType {
  Activity = 'activity',
  Conversation = 'conversation',
  Divider = 'divider'
}

export enum InboxQueryType {
  Activity = 'activity',
  Conversation = 'conversation'
}

export class InboxQueryResultListItem extends Record {
  uid: string;
  type: InboxQueryResultListItemType;
  divider?: {
    label: string;
  };
  result?: {
    id: string;
    account: Account;
    activity?: Activity;
    conversation?: Conversation;
    text: string;
    author: ActivityAuthor;
    date: Date;
    responseCount: number;
    status: ActivityStatus;
    visibility: ConversationVisibility;
    media: {
      total: number;
      image: ActivityMedia[];
      video: ActivityMedia[];
    };
  };
  expanded: boolean;
  expandTimeoutId: any;
}

@Injectable()
export class InboxQueryResultListItemModel extends Model<InboxQueryResultListItem> {
  constructor() {
    super('inboxQueryResultListItem', {
      idAttribute: 'uid',
      recordClass: InboxQueryResultListItem
    });
  }

  // crappy hack to prevent errors from js-data
  injectOrReturnExisting(itemRaw: any): InboxQueryResultListItem {
    if (!this.get(itemRaw.uid)) {
      return this.inject(itemRaw);
    }
    return this.get(itemRaw.uid);
  }
}

export const DEFAULT_DATE_DIVIDER_FORMAT = 'MMM D';

export function getDateDividerLabel(
  date: Date,
  translate: TranslateService
): string {
  if (moment(date).startOf('day').isSame(moment().startOf('day'))) {
    return translate.instant('TODAY');
  } else if (
    moment(date)
      .startOf('day')
      .isSame(moment().subtract(1, 'day').startOf('day'))
  ) {
    return translate.instant('YESTERDAY');
  } else if (moment().diff(moment(date), 'days') < 10) {
    return moment(date).fromNow();
  } else {
    return moment(date).format(DEFAULT_DATE_DIVIDER_FORMAT);
  }
}

const activeResultSymbol = Symbol('activeResult value');

export abstract class AbstractInboxQuery extends AbstractQuery {
  type: InboxQueryType;

  filters: InboxQueryFilter[];

  result: {
    list?: InboxQueryResultListItem[];
    totalLoaded: number;
  };

  loading = {
    initial: this.injector.get(AsyncTrackerFactory).create(),
    more: this.injector.get(AsyncTrackerFactory).create()
  };

  activeResult: {
    value: InboxQueryResultListItem;
    valueChanges: Subject<InboxQueryResultListItem>;
  };

  readonly params: {
    sort: string;
    order: string;
    status?: string[];
  };

  constructor(protected injector: Injector) {
    super();

    const query = this;
    this.activeResult = {
      valueChanges: new Subject(),
      get value(): InboxQueryResultListItem {
        return this[activeResultSymbol];
      },
      set value(value: InboxQueryResultListItem) {
        this[activeResultSymbol] = value;
        query.localRefresh();
        this.valueChanges.next(value);
      }
    };
  }

  loadNextPage() {
    if (!this.loading.more.active) {
      return this.search(this.result.totalLoaded);
    }
  }

  abstract localRefresh();
}

export enum InboxQueryResultGroupByType {
  Smart = 'smart',
  Date = 'date',
  Priority = 'priority',
  MessageCount = 'messageCount'
}

export function addInboxQueryResultListDividers(
  list: InboxQueryResultListItem[],
  sortOptions: KeyValueObject<InboxSortOption>,
  sortValue: string,
  injector: Injector
): InboxQueryResultListItem[] {
  const translate = injector.get(TranslateService);
  const uniqueList: InboxQueryResultListItem[] = uniqBy<InboxQueryResultListItem>(
    list,
    (item) => item.uid
  );

  const sortOptionsValues = [...Object.values(sortOptions)];
  const { groupBy } = sortOptionsValues.find(
    (option) => option.value === sortValue
  );

  let grouped: Array<Chunk<InboxQueryResultListItem>>;

  switch (groupBy) {
    case InboxQueryResultGroupByType.Smart:
      grouped = groupArray<InboxQueryResultListItem>(uniqueList, (item) => {
        if (item.result.status === ActivityStatus.Unread) {
          return translate.instant('UNREAD');
        } else if (item.result.status === ActivityStatus.Unactioned) {
          return translate.instant('UNACTIONED');
        } else {
          return translate.instant('ACTIONED');
        }
      });
      break;

    case InboxQueryResultGroupByType.Date:
    case InboxQueryResultGroupByType.MessageCount:
      grouped = groupArray<InboxQueryResultListItem>(uniqueList, (item) => {
        return getDateDividerLabel(
          moment(item.result.date).startOf('day').toDate(),
          translate
        );
      });
      break;

    case InboxQueryResultGroupByType.Priority:
      grouped = groupArray<InboxQueryResultListItem>(uniqueList, (item) => {
        if (item.result.activity && item.result.activity.inbox) {
          return item.result.activity.inbox.priority
            ? `Priority 0${item.result.activity.inbox.priority}`
            : 'No priority set';
        } else {
          return item.result.conversation.priority
            ? `Priority 0${item.result.conversation.priority}`
            : 'No priority set';
        }
      });
      break;

    default:
      throw new Error('Unknown sort type');
  }

  return grouped.reduce((previous, { items, label }) => {
    return [
      ...previous,
      injector.get(InboxQueryResultListItemModel).injectOrReturnExisting({
        uid: `divider-${label}`,
        type: InboxQueryResultListItemType.Divider,
        divider: {
          label
        }
      }),
      ...items
    ];
  }, []);
}

export function localRefresh(
  filters: InboxQueryFilter[],
  sortOptions: KeyValueObject<InboxSortOption>,
  testResult: (record, filters) => boolean,
  sortDirection: string,
  records: any[],
  totalResults: number
) {
  const oldRecordsLength = records.length;
  // opt into the localRefresh, otherwise we might hide some results if there are bugs with other local filters
  const localRefreshFilters = filters.filter(
    (filter) => filter.localRefresh && filter.matches
  );

  let filteredRecords = records.filter((record) =>
    testResult(record, localRefreshFilters)
  );

  const newTotalResults =
    totalResults - (oldRecordsLength - filteredRecords.length);
  const sortOptionsValues = [...Object.values(sortOptions)];
  const sortValue = sortOptionsValues.find(
    (sortType) => sortType.value === sortDirection
  );
  if (sortValue && sortValue.sortResults) {
    filteredRecords = sortValue.sortResults(filteredRecords);
  }

  return {
    records: filteredRecords,
    totalResults: newTotalResults
  };
}
