import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { BlockingWordsComponent } from './blocking-words.component';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { AutoHeightModule } from '../../../../../common/directives/auto-height/auto-height.module';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.blockingWords',
          url: '/settings/profanities',
          component: BlockingWordsComponent,
          resolve: BlockingWordsComponent.resolve
        }
      ]
    }),
    AlertsModule,
    TranslateModule,
    FormsModule,
    TooltipModule,
    AutoHeightModule,
    SortByModule
  ],
  declarations: [BlockingWordsComponent],
  entryComponents: [BlockingWordsComponent]
})
export class BlockingWordsModule {}
