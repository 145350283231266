import { Component } from '@angular/core';

@Component({
  selector: 'ssi-account',
  templateUrl: './account.component.html',
  styles: []
})
export class AccountComponent {
  constructor() {}
}
