import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePostsGalleryComponent } from './validate-posts-gallery.component';
import { EnlargeImageModule } from '../../../../../../common/directives/enlarge-image/enlarge-image.module';

@NgModule({
  imports: [CommonModule, EnlargeImageModule],
  declarations: [ValidatePostsGalleryComponent],
  exports: [ValidatePostsGalleryComponent]
})
export class ValidatePostsGalleryModule {}
