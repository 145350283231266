<div class="heading-container">
  <orlo-button
    [text]="'Back'"
    (click)="backToCompanies()"
    [textColor]="'#14BAE3'"
    [bgColor]="'#fff'"
    [leadingIcon]="'ssi ssi-line-arrow-small-down'"
  ></orlo-button>
  <h1 *ngIf="profileGroupMode.type === 'COMPETITOR'">
    {{adminMode.id === adminModes.edit.id ? 'Edit this Competitor' : 'Add a new Competitor'}}
  </h1>
  <h1 *ngIf="profileGroupMode.type === 'BRAND'">
    {{adminMode.id === adminModes.edit.id ? 'Edit this Brand' : 'Add a Brand of mine'}}
  </h1>
</div>
<div class="content-wrapper">
  <form
    *ngIf="competitorForm"
    class="content-form"
    (ngSubmit)="saveCompetitor()"
    [formGroup]="competitorForm"
  >
    <!-- <i
      *ngIf="adminMode.id === adminModes.edit.id"
      (click)="openDeleteModal()"
      class="delete ssi ssi-delete"
    ></i> -->
    <span *ngIf="adminMode.id === adminModes.edit.id" class="delete" (click)="openDeleteModal()">
      <ssi-icon icon="bin" height="14" width="14" color="#838eab"></ssi-icon>
    </span>
    <div class="form-group">
      <div class="input-container">
        <orlo-text-input
          [label]="profileGroupMode.label + ' name'"
          [name]="'name'"
          [parentForm]="competitorForm"
          [type]="'text'"
          [required]="false"
          [placeholder]="'Name your ' + profileGroupMode.id"
          [invalid]="competitorForm.controls.name?.touched && competitorForm.controls.name?.invalid ? true : false"
          [invalidMessage]="competitorForm.controls.name?.touched && competitorForm.controls.name?.invalid && competitorForm.controls.name?.errors.maxlength ? 'Please enter a shorter name' : 'Woops! We\'ll need you to fill this one in...'"
        ></orlo-text-input>
        <span
          class="input-counter"
          [class.error]="competitorForm.value.name.length > 80"
        >{{competitorForm.value.name.length}} / 80</span>
      </div>
      <div class="input-container">
        <div>
          <label class="control-label text-muted spacer-top">
            {{profileGroupMode.label}} colour
          </label>
          <button
            class="orlo-button primary plus box-color"
            type="button"
            [style.background]="competitor.colour"
            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
            [cpAlphaChannel]="'disabled'"
            [(colorPicker)]="competitor.colour"
          ><i class="ssi ssi-plus"></i></button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label text-muted spacer-top">
        Add networks
      </label>
      <div class="box-list">
        <div
          class="box"
          *ngFor="let channel of availableChannels"
          [ngClass]="{selected: selectedProfiles[channel.id], disabled: channel.disabled}"
          [ssiTooltip]="channel.disabled ? 'No linked account of this network type' : ''"
        >
          <div *ngIf="networkChannelSelected !== channel.id" class="channel">
            <img
              *ngIf="selectedProfiles[channel.id]?.avatar_url"
              class="box-control"
              [src]="selectedProfiles[channel.id]?.avatar_url"
            >
            <div class="name-details" [class.selected]="selectedProfiles[channel.id]">
              <label *ngIf="!selectedProfiles[channel.id]">{{channel.name}} {{selectedProfiles[channel.id] ? '' : 'account'}}</label>
              <label *ngIf="selectedProfiles[channel.id]">
                <i [ngClass]="accountModel.getSocialIcon(accountModel.getAccountTypeFromId(channel.id))"></i>
                {{selectedProfiles[channel.id]?.name}}
              </label>
              <span *ngIf="selectedProfiles[channel.id]"> @{{selectedProfiles[channel.id]?.username}}</span>
            </div>
            <div
              class="box-control"
              (click)="addOrRemoveProfile(channel.id)"
            >
              <i [ngClass]="selectedProfiles[channel.id] ? 'ssi ssi-minimise' : 'ssi ssi-plus'"></i>
            </div>
          </div>
          <div *ngIf="networkChannelSelected === channel.id" class="search-box">
            <input
              type="text"
              placeholder="Search handle"
              class="search-handle orlo-text-input"
              (keydown.enter)="searchForProfiles($event)"
              [(ngModel)]="profileSearchString"
              [ngModelOptions]="{standalone: true}"
            >
            <ssi-spinner #profileSearchSpinner></ssi-spinner>
            <button
              class="orlo-button search-button"
              [disabled]="!profileSearchString"
              type="button"
              (click)="searchForProfiles($event)"
            >
              Search
            </button>
            <div class="accounts-dropdown" *ngIf="showAccountsDropdown">
              <div
                class="accounts-list"
                *ngIf="profiles.length > 0 && profileSearchString"
              >
                <div
                  class="media"
                  *ngFor="let profile of profiles"
                >
                  <div class="media-left">
                    <img
                      class="profile img-circle"
                      [src]="profile.image_url"
                    >
                  </div>
                  <div class="media-body">
                    <div class="row">
                      <div class="col-sm-6 profile-info">
                        <a href="javascript:;">
                          <strong>{{ profile.name }}</strong>
                          <small class="text-muted">@{{ profile.username }}</small>
                        </a>
                        <br>
                        <small>{{ profile.description }}</small>
                        <div *ngIf="profile.location">
                          <small class="text-muted">
                            <i class="fa fa-map-marker"></i>
                            {{ profile.location.city ? profile.location.city : profile.location }}
                          </small>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <button
                          class="orlo-button primary plus"
                          (click)="addProfile(profile)"
                          type="button"
                        >
                          <i class="ssi ssi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="form-submit">
        <orlo-button
          [textColor]="'#14BAE3'"
          [bgColor]="'#fff'"
          [state]="'secondary'"
          [text]="'Cancel'"
          [type]="'button'"
          (btnClick)="backToCompanies()"
        ></orlo-button>
        <orlo-button
          [bgColor]="'#14bae3'"
          [textColor]="'#fff'"
          [state]="'primary'"
          [type]="'submit'"
          [disabled]="checkIfCompetitorIsValid()"
          [text]="adminMode.id === adminModes.edit.id ? 'Save' : 'Add'"
        ></orlo-button>
      </div>
    </div>
  </form>
  <div class="help-content">
    <img src="https://www.orlo.app/assets/v2/img/teammates-help-{{profileGroupMode.id}}.png" />
    <div class="text-container">
      <h3>Need help?</h3>
      <p *ngIf="profileGroupMode.type === 'COMPETITOR'">
        This is where you add a competitor, to use in your reports. Simply pop in a name for your competitor and assign
        a colour. The colour you choose will represent this competitor in the reports you create. Your final step is to
        add the social accounts of the competitor, make sure you are in the correct Workflow so Orlo knows which
        accounts to gather data from for your reports.
      </p>
      <p *ngIf="profileGroupMode.type === 'BRAND'">
        This is where you add your brand's social accounts into a brand profile. This allows Orlo to directly compare
        the social activity of your brand versus that of your competitors. Simply pop in a name for your brand and
        assign a colour. The colour you choose will represent this brand in the reports you create. You can also manage
        multiple brands, by creating additional profiles.
      </p>
    </div>
  </div>
</div>