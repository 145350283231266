import { Outbox } from '@ui-resources-angular';
import './validate-posts-gallery.component.scss';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ssi-validate-posts-gallery',
  templateUrl: './validate-posts-gallery.component.html',
  styles: []
})
export class ValidatePostsGalleryComponent implements OnInit {
  media;
  mediaStack: boolean;
  @Input() validation: Outbox | any;

  constructor() {}

  ngOnInit() {
    this.assignMedia();
  }

  assignMedia() {
    const post = this.validation.outbox
      ? this.validation.outbox
      : this.validation;
    if (post.outbox_files.length > 0) {
      this.media = Array.of(post.outbox_files[0].public_url);
    }
    if (post.outbox_files.length > 1) {
      this.mediaStack = true;
      this.media = post.outbox_files.map((image) => image.public_url);
    }
  }
}
