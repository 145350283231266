import './component-library.component.scss';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-component-library',
  templateUrl: './component-library.component.html',
  styles: []
})
export class ComponentLibraryComponent implements OnInit {
  static resolve = [];

  constructor() {}

  ngOnInit() {}
}
