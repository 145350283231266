import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-account-notification-settings',
  templateUrl: './account-notification-settings.component.html',
  styleUrls: []
})
export class AccountNotificationSettingsComponent implements OnInit {
  @Input() account: string;
  @Input() accounts: Account[];
  @Input() notifications: any;
  @Input() showActions?: boolean;
  @Output() resetSelectedAccount = new EventEmitter();
  @Output() updateSubscriptions = new EventEmitter();
  selectedAccount: any;
  trackById(index, notifications) {
    return notifications.id;
  }

  constructor() {}

  ngOnInit() {
    this.selectedAccount = this.accounts.find(
      (account) => account.id === this.account
    );
  }

  async updateSubscription(type, method) {
    await this.notifications.updateSubscription(
      this.selectedAccount,
      type,
      method,
      this.notifications.subscriptions[this.selectedAccount.id][type.id][
        method.id
      ].period
    );
    this.updateSubscriptions.emit();
  }
}
