<div class="theme-detector-create-edit-container">
  <div class="section-head">
    <div class="title">
      <h2>{{themeDetector ? 'Edit' : 'Create'}} emerging theme alert</h2>
    </div>
  </div>

  <div class="section-body">
    <form #form="ngForm"
          class="create-edit-form"
          (submit)="submit(form)">

      <div class="form-field-group">
        <div class="form-field">
          <label>ACCOUNTS</label>
          <div class="input-wrapper">
            <ssi-dropdown-select-2 name="accounts"
                                   size="lg"
                                   headButtonStyleVariant="analytics"
                                   [required]="!model.streams.length"
                                   [multiple]="true"
                                   [filterable]="true"
                                   [filterPlaceholder]="'Search for an account...'"
                                   [toggleAllLabel]="'Select all accounts'"
                                   [options]="accounts"
                                   [(ngModel)]="model.accounts"
                                   (ngModelChange)="onSelectedAccountsChange($event)">
              <ng-template ssiTemplateSelector="headButtonLabel"
                           let-expanded="expanded">
                <div class="select-placeholder"
                     *ngIf="!model.accounts.length">
                  <span>Select accounts...</span>
                </div>
                <div class="select-label"
                     *ngIf="model.accounts.length">
                  <span>{{model.accounts.length}} accounts</span>
                  <ssi-social-network-icon *ngFor="let accountTypeId of selectedAccountTypeIds"
                                           [accountTypeId]="accountTypeId"
                                           variant="fa"></ssi-social-network-icon>
                </div>
              </ng-template>

              <ng-template ssiTemplateSelector="optionIcon"
                           let-account="option">
                <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                         variant="fa"></ssi-social-network-icon>
              </ng-template>
            </ssi-dropdown-select-2>
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label>STREAMS</label>
          <div class="input-wrapper">
            <ssi-dropdown-select-2 name="streams"
                                   size="lg"
                                   headButtonStyleVariant="analytics"
                                   [required]="!model.accounts.length"
                                   [multiple]="true"
                                   [filterable]="true"
                                   [filterPlaceholder]="'Search for a stream...'"
                                   [toggleAllLabel]="'Select all streams'"
                                   [options]="streams"
                                   [(ngModel)]="model.streams"
                                   (ngModelChange)="onSelectedStreamsChange($event)">
              <ng-template ssiTemplateSelector="headButtonLabel"
                           let-expanded="expanded">
                <div class="select-placeholder"
                     *ngIf="!model.streams.length">
                  <span>Select streams...</span>
                </div>
                <div class="select-label"
                     *ngIf="model.streams.length">
                  <span>{{model.streams.length}} streams</span>
                </div>
              </ng-template>
            </ssi-dropdown-select-2>
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label [ssiTooltip]="'This sets the window of time that Orlo will use to detect emerging themes. Minimum 1 hours, maximum 24 hours.'"
                 tooltipWindowClass="tooltip-chunky">THEME WINDOW HOURS <i class="ssi ssi-information"></i></label>
          <div class="input-wrapper">
            <input type="number"
                   class="text-input"
                   name="queryWindowHours"
                   [required]="true"
                   [ssiMin]="1"
                   [ssiMax]="24"
                   [(ngModel)]="model.queryWindowHours"
                   (ngModelChange)="onQueryWindowHoursInput($event)"
                   placeholder="Enter hours for theme window...">
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label [ssiTooltip]="'This controls how long Orlo will remember a detected theme, to avoid repeating alerts. Minimum 168 hours (7 days), maximum 720 hours (30 days).'"
                 tooltipWindowClass="tooltip-chunky">THEME MEMORY HOURS <i class="ssi ssi-information"></i></label>
          <div class="input-wrapper">
            <input type="number"
                   class="text-input"
                   name="lifetimeHours"
                   [required]="true"
                   [ssiMin]="168"
                   [ssiMax]="720"
                   [(ngModel)]="model.lifetimeHours"
                   (ngModelChange)="onLifetimeHoursInput($event)"
                   placeholder="Enter hours for theme memory...">
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label [ssiTooltip]="'This tells Orlo how many results must be detected before alerting you. Minimum 10 results.'"
                 tooltipWindowClass="tooltip-chunky">THEME ALERT THRESHOLD <i class="ssi ssi-information"></i></label>
          <div class="input-wrapper">
            <input type="number"
                   class="text-input"
                   name="minClusterSize"
                   [required]="true"
                   [ssiMin]="10"
                   [(ngModel)]="model.minClusterSize"
                   (ngModelChange)="onMinClusterSizeInput($event)"
                   placeholder="Enter threshold for theme alert...">
          </div>
        </div>
      </div>

      <div class="form-field-group double-length">
        <div class="form-field">
          <label>NOTIFICATION EMAIL ADDRESSES</label>
          <div class="input-wrapper">
            <input class="text-input"
                   name="notificationEmails"
                   [required]="true"
                   [(ngModel)]="model.notificationEmails"
                   (ngModelChange)="onNotificationEmailsInput($event)"
                   placeholder="Enter multiple addresses separated by commas...">
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="section-foot">
    <div class="buttons">
      <button class="g-btn bg-red delete-button"
              type="button"
              *ngIf="themeDetector"
              (click)="deleteDetector()"><i class="ssi ssi-small-delete"></i><span>Delete alert</span></button>

      <button class="g-btn g-btn-secondary bg-white cancel-button"
              type="button"
              (click)="cancel()"><span>Cancel</span></button>

      <button class="g-btn submit-button"
              type="submit"
              (click)="submit(form)"
              [disabled]="!canSubmit()"><span>{{themeDetector ? 'Save changes' : 'Create alert'}}</span></button>
    </div>
  </div>
</div>