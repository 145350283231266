import { api } from '../../core/services/api';

const PREG_QUOTE_REGEXP = new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g');

export const pregQuote = (str: string): string => {
  return String(str).replace(PREG_QUOTE_REGEXP, '\\$&');
};

const regExpCache = {};

export const constructRegExp = (word: string): RegExp => {
  if (!regExpCache[word]) {
    regExpCache[word] = new RegExp(
      `(\\W|\\b)${pregQuote(word)
        .replace(/\\\*/g, '[^\\s]*')
        .replace(/\\\?/g, '\\S')}(\\W|\\b)`,
      'i'
    );
  }
  return regExpCache[word];
};

const blockingWordsCache = Symbol('blockingWordsCache');

export class BlockingWord {
  clearCache(): void {
    this[blockingWordsCache] = null;
  }

  getBlockingWords() {
    if (!this[blockingWordsCache]) {
      this[blockingWordsCache] = api
        .get('settings/blockingWord')
        .then(({ data }) => data);
    }
    return this[blockingWordsCache];
  }

  createBlockingWord(word: string) {
    return api.post('settings/blockingWord', { word }).then(({ data }) => {
      this.clearCache();
      return data;
    });
  }

  deleteBlockingWord(wordId: number) {
    return api
      .del('settings/blockingWord', { params: { word_id: wordId } })
      .then(() => {
        this.clearCache();
      });
  }

  resetBlockingWords() {
    return api
      .post('settings/blockingWord', { load_default_dictionary: 1 })
      .then(({ data }) => {
        this.clearCache();
        return data;
      });
  }

  matchText(rawWordsArray: string[], text: string): string[] {
    return rawWordsArray.filter((word) => constructRegExp(word).test(text));
  }

  findExactMatches(blockingWordsList: string[], text: string): any {
    let found = [];
    blockingWordsList.map((word) => {
      let match = text.match(constructRegExp(word));

      if (match[0]) {
        found.push(match[0].trim());
      }
    });
    return found;
  }
}
