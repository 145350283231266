<div
  class="panel panel-default container-fill-height"
  ssiAutoHeight
  [additionalHeight]="40">
  <div class="panel-body container-content-middle">
    <h1 class="text-muted text-center">
      <i class="fa fa-search fa-2x"></i>
      <i class="fa fa-plus fa-2x"></i>
      <i class="fa fa-envelope fa-2x"></i>
    </h1>
    <h1 class="text-muted text-center">
      <span translate="OUTBOX_SEARCH"></span><br/>
      <small translate="NO_RESULTS_CHECK_SEARCH_CRITERIA_OR_CLICK_SEARCH"></small>
    </h1>
  </div>
</div>