import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetLiveXStreamConfigComponent } from './widget-live-x-stream-config.component';
import { InboxQueryResultListModule } from '../../../../../inbox/inbox-query-result-list/inbox-query-result-list.module';
import { AutoHeightModule } from '../../../../../../../common/directives/auto-height/auto-height.module';
import { InboxActivityModalModule } from '../../../../../../../common/components/inbox-activity/inbox-activity-modal/inbox-activity-modal.module';
import { FormsModule } from '@angular/forms';
import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { TagsInput2Module } from '../../../../../../../common/components/tags-input-2/tags-input-2.module';

@NgModule({
  imports: [
    CommonModule,
    InboxQueryResultListModule,
    AutoHeightModule,
    InboxActivityModalModule,
    FormsModule,
    DropdownSelect2Module,
    TagsInput2Module
  ],
  exports: [WidgetLiveXStreamConfigComponent],
  declarations: [WidgetLiveXStreamConfigComponent]
})
export class WidgetLiveXStreamConfigModule {}
