<div class="my-details">
  <div class="flex-grid container">
    <div class="col">
      <div class="form">
        <form (ngSubmit)="onSubmit()" [formGroup]="socialPushModeForm">
          <div class="social-push-mode-input-wrap">
            <p class="social-push-mode-input-title">Enable Social Push Mode:</p>

            <label class="social-push-mode-input-label" for="socialPushModeTrue">True</label>
            <input
              id="socialPushModeTrue"
              [type]="'radio'"
              [name]="'enabled'"
              [value]="true"
              [formControlName]="'enabled'"
              [checked]="socialPushModeStatus.enabled" />

            <label class="social-push-mode-input-label" for="socialPushModeFalse">False</label>
            <input
              id="socialPushModeFalse"
              [type]="'radio'"
              [name]="'enabled'"
              [value]="false"
              [formControlName]="'enabled'"
              [checked]="!socialPushModeStatus.enabled" />
          </div>

          <orlo-text-input
            [width]="'300'"
            [label]="'Max queue size'"
            [name]="'max_queue_size'"
            [parentForm]="socialPushModeForm"
            [type]="'number'"
            [required]="true">
          </orlo-text-input>

          <div class="social-push-mode-input-wrap">
            <p class="social-push-mode-input-title">Enable Conversation Rules:</p>

            <label class="social-push-mode-input-label" for="conversationRulesTrue">True</label>
            <input
              id="conversationRulesTrue"
              [type]="'radio'"
              [name]="'conversation_rules_enabled'"
              [value]="true"
              [formControlName]="'conversation_rules_enabled'"
              [checked]="socialPushModeStatus.conversation_rules_enabled" />

            <label class="social-push-mode-input-label" for="conversationRulesFalse">False</label>
            <input
              id="conversationRulesFalse"
              [type]="'radio'"
              [name]="'conversation_rules_enabled'"
              [value]="false"
              [formControlName]="'conversation_rules_enabled'"
              [checked]="!socialPushModeStatus.conversation_rules_enabled" />
          </div>

          <orlo-text-input
            [width]="'300'"
            [label]="'Modify on hold internal status after (days)'"
            [name]="'modify_on_hold_internal_status_after'"
            [parentForm]="socialPushModeForm"
            [type]="'number'"
            [required]="false">
          </orlo-text-input>

          <div class="form-field orlo-select">
            <label for="modify_on_hold_internal_status_to">Modify on hold internal status to</label>
            <select
              id="modify_on_hold_internal_status_to"
              formControlName="modify_on_hold_internal_status_to"
              name="modify_on_hold_internal_status_to"
            >
              <option value="actioned" [selected]="socialPushModeStatus.modify_on_hold_internal_status_to == 'actioned'">Take off hold</option>
              <option value="resolved" [selected]="socialPushModeStatus.modify_on_hold_internal_status_to == 'resolved'">Resolved</option>
            </select>
            <i class="ssi ssi-arrow-down-correct"></i>
          </div>

          <orlo-text-input
            [width]="'300'"
            [label]="'Modify on hold external status after (days)'"
            [name]="'modify_on_hold_external_status_after'"
            [parentForm]="socialPushModeForm"
            [type]="'number'"
            [required]="false">
          </orlo-text-input>

          <div class="form-field orlo-select">
            <label for="modify_on_hold_external_status_to">Modify on hold external status to</label>
            <select
              id="modify_on_hold_external_status_to"
              formControlName="modify_on_hold_external_status_to"
              name="modify_on_hold_external_status_to"
            >
              <option value="actioned" [selected]="socialPushModeStatus.modify_on_hold_external_status_to == 'actioned'">Take off hold</option>
              <option value="resolved" [selected]="socialPushModeStatus.modify_on_hold_external_status_to == 'resolved'">Resolved</option>
            </select>
            <i class="ssi ssi-arrow-down-correct"></i>
          </div>

          <div class="form-submit">
            <orlo-button
              [text]="'Save changes'"
              [state]="'primary'"
              [type]="'submit'"
              [disabled]="socialPushModeForm?.invalid || equalsCurrentValues">
            </orlo-button>
          </div>
        </form>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
