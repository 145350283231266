import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentGeneratorService } from '../../content-generator/content-generator.service';
import { RssFeedArticleComponent } from './rss-feed-article.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TruncateModule } from '../../../../../common/pipes/truncate/truncate.module';

import { EnlargeImageModule } from '../../../../../common/directives/enlarge-image/enlarge-image.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    EnlargeImageModule,
    TooltipModule,
    TruncateModule
  ],
  declarations: [RssFeedArticleComponent],
  exports: [RssFeedArticleComponent],
  providers: [ContentGeneratorService]
})
export class RssFeedArticleModule {}
