<div class="section-heading">
  <h3 translate="POSTS_BREAKDOWN"></h3>
</div>
<div class="section-content">
  <div
    class="progress-bars-reach-totals"
    [ngClass]="{'wrapped': (networks | filter: showOnlySupportingReach).length === 0 || ((networks | filter: showOnlySupportingReach).length === 4 && (networks | filter: hideNetworkWithNoPosts).length === 4)}"
  >
    <div class="posts-by-network">
      <h4 translate="POSTS_BY_NETWORK"></h4>
      <ul>
        <li *ngFor="let network of report?.postsData.networksData">
          <span>{{network.label}}</span>
          <span>{{report?.postsData?.perNetwork[network.account_type_id].count_posts}}</span>
          <ssi-progress-bar
            [max]="report.postsData.networksData[0].count_posts"
            [progress]="report?.postsData?.perNetwork[network.account_type_id].count_posts"
            [thickness]="20"
            bgColor="#fff"
            [color]="network.color"
            [radius]="5"
          ></ssi-progress-bar>
        </li>
      </ul>
    </div>
    <div
      class="network-reach-container"
      *ngIf="(networks | filter: showOnlySupportingReach).length > 0"
    >
      <div
        *ngFor="let network of networks | filter: showOnlySupportingReach"
        class="account-fact-total"
      >
        <div
          class="fact-details"
          [ssiTooltip]="network.name === 'Twitter' ? ('CAMPAIGN_REACH_STAT_NETWORK_SPECIFIC_UNAVAILABLE' | translate) : 'CAMPAIGN_REACH_STAT_NETWORK_SPECIFIC_DESCRIPTION' | translate: {accountTypeName: network.name}"
          tooltipWindowClass="tooltip-chunky-big"
        >
          <i
            class="{{network.icon}}"
            [style.background]="network.gradientColor ? network.gradientColor : network.color"
          ></i>
          <div class="fact-header">
            <span>{{network.name === 'LinkedIn' ? network.label + ' (Video)' : network.label}}</span>
            <span translate="TOTAL_REACH"></span>
          </div>
        </div>
        <ssi-big-number
          [number]="report.postsData.perNetwork[network.id].sum_reach"
          [denotation]="{million: 'm', thousand: 'k'}"
        ></ssi-big-number>
      </div>
    </div>
  </div>

  <div class="reactions-inbound">
    <div class="single-facts">
      <div class="single-fact">
        <ssi-fact-overview
          [label]="'TOTAL_COMMENTS' | translate"
          [number]="report.postsData?.totals.sum_comments"
          iconClass="ssi-inbound-messages-stat"
          iconColor="#838EAB"
          circleColor="transparent"
          dividerColor="#F0B427"
          [iconSize]="'46px'"
          width="auto"
          [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_PUBLIC_COMMENTS_OR_MENTIONS_RECEIVED_FOR_THE_REPORTED_TIME_PERIOD' | translate"
          tooltipWindowClass="tooltip-chunky-big"
        >
        </ssi-fact-overview>
      </div>
      <div class="single-fact">
        <ssi-fact-overview
          [label]="'FROM_CUSTOMERS' | translate"
          [number]="report?.engagementData?.inbound?.users_engaged"
          iconClass="ssi-customers-stat"
          iconColor="#838EAB"
          circleColor="transparent"
          dividerColor="#14BAE3"
          [iconSize]="'46px'"
          width="auto"
          [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_DIFFERENT_PEOPLE_WHO_POSTED_A_PUBLIC_COMMENT_OR_MENTION' | translate"
          tooltipWindowClass="tooltip-chunky-big"
        >
        </ssi-fact-overview>
      </div>
      <div class="single-fact">
        <ssi-fact-overview
          [label]="'TOTAL_LIKES' | translate"
          [number]="report?.postsData?.totals.sum_likes"
          iconClass="ssi-fb-like"
          iconColor="#838EAB"
          circleColor="transparent"
          dividerColor="#D41D68"
          [iconSize]="'46px'"
          width="auto"
        >
        </ssi-fact-overview>
      </div>
      <div class="single-fact">
        <ssi-fact-overview
          [label]="'Shares / Reposts' | translate"
          [number]="report?.postsData?.totals.sum_shares"
          iconClass="ssi-shares"
          iconColor="#838EAB"
          circleColor="transparent"
          dividerColor="#B2C614"
          [iconSize]="'46px'"
          width="auto"
        >
        </ssi-fact-overview>
      </div>
    </div>
    <div class="inbound-comments-pie-chart">
      <h4 translate="INBOUND_COMMENTS"></h4>
      <ssi-highchart [config]="pieChartConfig"></ssi-highchart>
    </div>
  </div>
</div>