import { Pipe, PipeTransform } from '@angular/core';
import { nl2br } from '../../util';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return nl2br(value);
  }
}
