<div class="campaign-select" (click)="onSelect(campaign.id)"></div>
<div [ngClass]="{'campaign-container-ad' : hasAdvertising}" class="campaign-container">
  <section>
    <span 
      class="campaign-title"
      (click)="onSelect(campaign.id)"
      ssiTooltip
      [tooltipAppendToBody]="true"
      tooltipPlacement="top"
      [tooltipTemplate]="childCampaignsTemplate"
      [tooltipDisabled]="children.length === 0"
      tooltipWindowClass="tooltip-campaign">
      {{ campaign.name }}
      <em class="campaign-title-meta">{{children.length}} child campaigns</em>
    </span>
  </section>
  <div class="campaign-info" [ngClass]="{'campaign-stats-hide': settingsActive}">
    <section class="text-center spacer-top campaign-header">
      <span
        class="campaign-action"
        [ssiTooltip]="campaignTooltip"
        tooltipWindowClass="tooltip-campaign">
        <i class="ssi ssi-fw ssi-campaign"></i>
      </span>
      <span class="campaign-action settings-trigger" (click)="showSettings()">
        <i class="ssi ssi-fw ssi-settings"></i>
      </span>
    </section>
    <section class="campaign-section">
      <div class="campaign-subtitle spacer-bottom">
        <p>Total</p>
        <ssi-big-number [number]="stats?.outbox_messages"></ssi-big-number>
      </div>
      <i class="ssi ssi-content-microcopy campaign-subtitle-icon campaign-subtitle-icon-primary"></i>
      <ul class="campaign-stats">
        <li class="campaign-stat campaign-stat-clear">
          <p>Reach</p>
          <ssi-big-number [number]="stats?.outbox_reach"></ssi-big-number>
        </li>
        <li class="campaign-stat campaign-stat-clear">
          <p>Impressions</p>
          <ssi-big-number [number]="stats?.outbox_impressions"></ssi-big-number>
        </li>
        <li class="campaign-stat campaign-stat-clear">
          <p>Clicks</p>
          <ssi-big-number [number]="stats?.outbox_clicks"></ssi-big-number>
        </li>
      </ul>
      <ul class="campaign-stats">
        <li class="campaign-stat campaign-stat-clear">
          <p>Likes</p>
          <ssi-big-number [number]="stats?.outbox_likes"></ssi-big-number>
        </li>
        <li class="campaign-stat campaign-stat-clear">
          <p>Comments</p>
          <ssi-big-number [number]="stats?.outbox_comments"></ssi-big-number>
        </li>
        <li class="campaign-stat campaign-stat-clear">
          <p>Shares</p>
          <ssi-big-number [number]="stats?.outbox_shares"></ssi-big-number>
        </li>
      </ul>
    </section>
    <section class="campaign-section campaign-section-light">
      <div class="campaign-subtitle spacer-bottom">
        <p>Ads</p>
        <ssi-big-number [number]="stats?.advert_count_live"></ssi-big-number>
      </div>
      <i class="ssi ssi-ad campaign-subtitle-icon campaign-subtitle-icon-warning"></i>
      <ul class="campaign-stats">
        <li class="campaign-stat campaign-stat-clear">
          <p>Engagements</p>
          <ssi-big-number [number]="stats?.advert_engagement"></ssi-big-number>
        </li>
        <li class="campaign-stat campaign-stat-clear">
          <p>Clicks</p>
          <ssi-big-number [number]="stats?.advert_clicks"></ssi-big-number>
        </li>
      </ul>
      <ul class="campaign-stats">
        <li class="campaign-stat campaign-stat-clear">
          <p>Total Cost</p>
          <ssi-big-number [number]="stats?.advert_cost"></ssi-big-number>
        </li>
        <li class="campaign-stat campaign-stat-clear">
          <p>Impressions</p>
          <ssi-big-number [number]="stats?.advert_impressions"></ssi-big-number>
        </li>
      </ul>
    </section>
  </div>
</div>
<div [ngClass]="{'campaign-settings-ad' : hasAdvertising}" class="campaign-settings" *ngIf="settingsActive">
  <section class="text-center">
    <span class="campaign-action settings-trigger" (click)="showSettings()">
      <i class="ssi ssi-fw ssi-settings"></i>
    </span>
  </section>
  <ul>
    <li><a uiSref="auth.analytics.campaign.build" [uiParams]="{campaignId: campaign.id}"><button class="btn campaign-setting-button"><i class="ssi ssi-fw ssi-toolbar-analyse"></i>Build Analytics</button></a></li>
    <li><button class="btn campaign-setting-button" (click)="toggleCampaignEditor('add')"><i class="ssi ssi-fw ssi-child-campaign"></i>Add Nested Campaign</button></li>
    <li><button class="btn campaign-setting-button" (click)="toggleCampaignEditor('edit')"><i class="ssi ssi-fw ssi-edit"></i>Edit Campaign</button></li>
    <li><button class="btn campaign-setting-button" (click)="toggleCampaignEditor('delete')"><i class="ssi ssi-fw ssi-finish-campaign"></i>Finish Campaign</button></li>
  </ul>
</div>
<div class="campaign-editor" *ngIf="campaignEditorActive">
  <div *ngIf="campaignEditorMode === 'delete'">
    <p>Are you sure you would like to finish the campaign?</p>
    <button class="btn btn-primary btn-rounded btn-padding-sides" (click)="finishCampaign()">Finish</button>
    <button class="btn btn-primary btn-rounded btn-padding-sides" (click)="toggleCampaignEditor(null)">Cancel</button>
  </div> 
  <div *ngIf="campaignEditorMode !== 'delete'">
    <div class="form-group">
      <input placeholder="Type campaign name" [(ngModel)]="newCampaign.name"/>
    </div>
    <div class="form-group" *ngIf="campaignEditorMode === 'add'">
      <div class="select-wrapper">
        <select [(ngModel)]="newCampaign.parent_id">
          <option selected="selected" [value]="campaign.id">{{campaign.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <input
        class="form-control"
        type="text"
        mwlFlatpickr
        [(ngModel)]="newCampaign.started_at"
        [altInput]="true"
        [convertModelValue]="true"
        [enableTime]="true"
        dateFormat="Y-m-d H:i"
        [time24hr]="true"
        altFormat="M J Y, H:i"
        placeholder="Start"
        required
        name="start">
    </div>
    <div class="form-group">
        <input
          class="form-control"
          type="text"
          mwlFlatpickr
          [(ngModel)]="newCampaign.closed_at"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-d H:i"
          [minDate]="(newCampaign.started_at || now) | addSeconds:60"
          [time24hr]="true"
          altFormat="M J Y, H:i"
          placeholder="End"
          name="end">
      </div>
      <div>
        <button *ngIf="campaignEditorMode === 'add'" [disabled]="!newCampaign.name" class="btn btn-primary btn-rounded btn-padding-sides" (click)="addCampaign()">Add</button>
        <button *ngIf="campaignEditorMode === 'edit'" class="btn btn-primary btn-rounded btn-padding-sides" (click)="editCampaign()">Save</button>
        <button class="btn btn-primary btn-rounded btn-padding-sides" (click)="toggleCampaignEditor(null)">Cancel</button>
      </div>
    </div>
<!-- 
      [minDate]="now"
      [maxDate]="newCampaign.closed_at | addSeconds:-60" -->
</div>

<ng-template #childCampaignsTemplate class="campaign-children">
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      {{campaign.name}}
    </div>
    <div class="tooltip-body-contents">
      <ul>
        <li *ngFor="let childCampaign of children">{{childCampaign.name}}</li>
      </ul>
    </div>
  </div>
  <div data-popper-arrow class="tooltip-arrow"></div>
</ng-template>