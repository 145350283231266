import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialNetworkIconComponent } from './social-network-icon.component';

@NgModule({
  declarations: [SocialNetworkIconComponent],
  imports: [CommonModule],
  exports: [SocialNetworkIconComponent]
})
export class SocialNetworkIconModule {}
