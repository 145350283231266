import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ceil'
})
export class CeilPipe implements PipeTransform {
  transform(number: any): number {
    if (Number.isNaN(number)) {
      return 0;
    }
    return Math.ceil(number);
  }
}
