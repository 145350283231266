import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatBarTileComponent } from './stat-bar-tile.component';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';

@NgModule({
  imports: [CommonModule, BigNumberModule],
  declarations: [StatBarTileComponent],
  exports: [StatBarTileComponent]
})
export class StatBarTileModule {}
