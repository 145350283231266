import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../../../common/services/api';

@Injectable()
export class OfficeHoursService {
  constructor(private api: ApiService) {}

  getAccountOfficeHours(accountId: string): Promise<any> {
    const endpoint = `${this.api.url}/account/accountOfficeHours`;

    return this.api
      .get(`${endpoint}?id=${accountId}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  getAccountsOfficeHours(): Promise<any> {
    const endpoint = `${this.api.url}/account/accountOfficeHours`;

    return this.api
      .get(`${endpoint}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async saveAccountOfficeHours(accountWithOfficeHours): Promise<any> {
    const endpoint = `${this.api.url}/account/accountOfficeHours`;

    console.log('accountWithOfficeHours:', accountWithOfficeHours);
    return this.api
      .post(endpoint, accountWithOfficeHours)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
