import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ssi-analytics-ng1' // tslint:disable-line directive-selector
})
export class AnalyticsDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('ssiCampaignAnalyticsState', elementRef, injector);
  }
}
