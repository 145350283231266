import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxPostSubmodalComponent } from './outbox-post-submodal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OutboxPostSubmodalComponent],
  exports: [OutboxPostSubmodalComponent],
  entryComponents: [OutboxPostSubmodalComponent]
})
export class OutboxPostSubmodalModule {}
