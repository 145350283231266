<article class="tsc">
  <div class="tsc-body" [style.height.px]="height">
    <span
      class="tsc-icon-wrap"
      [ngClass]="{
        'tsc-icon-wrap-background': iconBackgroundColor,
        'tsc-icon-wrap-growth-label': growthValue
      }"
      [style.background-color]="iconBackgroundColor">
      <i
        class="tsc-icon"
        [ngClass]="icon"
        [ngStyle]="{
          'font-size.px': iconSize,
          'color': iconColor
        }"
      ></i>
    </span>
    <div class="tsc-label">
      <p [innerHTML]="label"></p>
    </div>
    <div class="tsc-value">
      <p *ngIf="!valueIsString"><ssi-big-number [number]="value"></ssi-big-number></p>
      <p *ngIf="valueIsString" [innerHTML]="value"></p>
    </div>
  </div>
  <div *ngIf="growthValue" class="tsc-growth-label-wrap" [class.tsc-growth-label-positive]="growth">
    <i *ngIf="growth && !lowerIsPositive" class="ssi ssi-line-arrow-small-up tsc-growth-label-icon"></i>
    <i *ngIf="growth && lowerIsPositive" class="ssi ssi-line-arrow-small-down tsc-growth-label-icon"></i>
    <i *ngIf="!growth && !lowerIsPositive" class="ssi ssi-line-arrow-small-down tsc-growth-label-icon"></i>
    <i *ngIf="!growth && lowerIsPositive" class="ssi ssi-line-arrow-small-up tsc-growth-label-icon"></i>
    <p *ngIf="!valueIsString" 
       class="tsc-growth-label">
        <ssi-big-number 
          [number]="growthValue">
        </ssi-big-number>
    </p>
    <p *ngIf="valueIsString"
       class="tsc-growth-label" 
       [innerHTML]="growthValue">
    </p>
  </div>
</article>
