import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  User as SSIUser,
  UserModel as SSIUserModel,
  Note,
  NoteModel,
  noteModelFactory
} from '@ui-resources-angular';
import {
  TeamsService,
  Team as SSITeam,
  ColleaguesService,
  Colleague
} from '../../services/api';
import { AngularFireDatabase } from '@angular/fire/database';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn,
  DocumentChangeAction
} from '@angular/fire/firestore';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireStorage } from '@angular/fire/storage';
import { LocalStorageService } from 'angular-2-local-storage';
import {
  addSeconds,
  isAfter,
  isBefore,
  startOfDay,
  subDays,
  subHours,
  subMinutes,
  differenceInHours,
  differenceInMilliseconds
} from 'date-fns';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { map, take } from 'rxjs/operators';

import liveChatConfig, {
  firebaseProject,
  keysForStorage,
  responseCacheMaximumAgeInHours,
  symbols as liveChatSymbols,
  taskTypes
} from '../../../../../library/constants/live-chat';
import strings, {
  agent as stringAgent,
  and as stringAnd,
  applications as stringApplications,
  assigned as stringAssigned,
  available as stringAvailable,
  busy as stringBusy,
  chat as stringChat,
  chats as stringChats,
  companies as stringCompanies,
  conversations as stringConversations,
  file as stringFile,
  have as stringHave,
  live as stringLive,
  longest as stringLongest,
  message as stringMessage,
  messages as stringMessages,
  offline as stringOffline,
  ongoing as stringOngoing,
  online as stringOnline,
  owned as stringOwned,
  shortest as stringShortest,
  success as stringSuccess,
  total as stringTotal,
  unassigned as stringUnassigned,
  upload as stringUpload,
  user as stringUser,
  waiting as stringWaiting,
  you as stringYou
} from '../../../../../library/constants/strings';
import symbols from '../../../../../library/constants/symbols';
import {
  get_database_network_state,
  get_firestore_network_state
} from '../../../../../library/helpers/firebase';
import { capitalize } from '../../../../../library/helpers/strings';
import {
  Agent,
  AgentFragment
} from '../../../../../library/interfaces/live-chat/agent';
import { Team } from '../../../../../library/interfaces/live-chat/team';
import { Application } from '../../../../../library/interfaces/live-chat/application';
import { Company } from '../../../../../library/interfaces/live-chat/company';
import {
  Conversation,
  ConversationFragment
} from '../../../../../library/interfaces/live-chat/conversation';
import {
  MessagePreview,
  MessageFragment
} from '../../../../../library/interfaces/live-chat/message';
import {
  ConversationUserEvent,
  ConversationUserEventItemsForUser
} from '../../../../../library/interfaces/live-chat/conversation-user-event';
import { Agent as AgentModel } from '../../../../../library/models/live-chat/agent';
import { Application as ApplicationModel } from '../../../../../library/models/live-chat/application';
import { Conversation as ConversationModel } from '../../../../../library/models/live-chat/conversation';
import { ConversationUserEvent as ConversationUserEventModel } from '../../../../../library/models/live-chat/conversation-user-event';
import { Message as MessageModel } from '../../../../../library/models/live-chat/message';
import { Visitor as VisitorModel } from '../../../../../library/models/live-chat/visitor';
import { LiveChatAuthenticationService } from '../../services/live-chat-authentication/live-chat-authentication.service';
import { CompanyService } from '../company/company.service';
import { PushNotificationsService } from '../push-notifications/push-notifications.service';
import { Person } from '../../../../../library/models/live-chat/person';
import { Company as CompanyModel } from '../../../../../library/models/live-chat/company';
import { ModalAttributes } from '../../../../../library/interfaces/modal-attributes';
import { AbstractTaskRequest } from '../../../../../library/interfaces/live-chat/abstract-task-request';
import { AbstractTask } from '../../../../../library/interfaces/live-chat/tasks/abstract-task';
import { timestamp_to_date } from '../../../../../library/helpers/datetimes';
import { TogglePushModeTask } from '../../../../../library/interfaces/live-chat/tasks/toggle-push-mode-task';
import { Visitor } from '../../../../../library/interfaces/live-chat/visitor';
import { ConstantPool } from '@angular/compiler';
import { NotificationService } from '../../../common/services/notification/notification.service';
import { ChatBotService } from '../../../common/services/chat-bot/chat-bot.service';
import { UserPreferencesService } from '../../services/user-preferences/user-preferences.service';

// ----------------

const isDebug = false;

// ----------------

interface CollectionReferenceMap {
  [key: string]: AngularFirestoreCollection<AngularFirestoreDocument>;
}

interface ConversationModelMap {
  [key: string]: ConversationModel;
}

@Injectable()
export class LiveChatService implements OnDestroy {
  static BrowserOfflineNoticeMinimumPeriod: number = 10000;

  // @todo now dust is settling, assess which if any of these public members can be private with public getters.
  public agentModel: AgentModel;
  public agents: Map<string, AgentModel> = new Map<string, AgentModel>();

  public activeMessages: MessageModel[] = [];
  public activeViewOfConversations: ConversationModel[] = [];
  public user: VisitorModel;
  public visitors: Map<string, VisitorModel> = new Map<string, VisitorModel>();

  public readonly config = liveChatConfig;

  private _activeApplication: ApplicationModel;
  private _activeConversationEvents: ConversationUserEventModel;
  private _activeConversationEventsCollection: AngularFirestoreCollection<ConversationUserEvent>;
  private _activeConversationEventsReference: AngularFirestoreDocument<ConversationUserEvent>;
  private _activeConversationEventsSubscription: Subscription;
  private _activeConversation: ConversationModel;
  private _activeConversationId: string;
  private _activeConversationReferenceSubscription: Subscription;
  private _activeFilter: string = stringUnassigned;
  private _activeMessagesReference: AngularFirestoreCollection<MessagePreview>;
  private _activeMessagesReferenceSubscription: Subscription;
  private _activeVisitor: VisitorModel;
  private _agentsReference: AngularFirestoreCollection<Agent>;
  private _agentSubscription: Subscription;
  private _applications: ApplicationModel[];
  private _archivedConversationCollectionReferences: Map<
    string,
    AngularFirestoreCollection
  > = new Map();
  private _areActiveConversationTagsUpdating: boolean = false;
  private _areSuggestionsAvailable: boolean = false;
  private _assignedConversationsIds: Set<string> = new Set<string>();
  private _assignedConversationsCollectionsReferences: CollectionReferenceMap = {};
  private _browserNetworkStateCheckedAt: Date;
  private _browserOfflineAt: Date;
  private _browserOfflineTotalInMilliseconds: number = 0;
  private _blockedIpReference: AngularFirestoreDocument;
  private _canAdministerAccounts = false;
  private _companiesReference: AngularFirestoreCollection<Company>;
  private _company: CompanyModel;
  private _companyReference: AngularFirestoreDocument<Company>;
  private _conversationCollectionReferences: Map<
    string,
    AngularFirestoreCollection
  > = new Map();
  private _conversations: Map<string, ConversationModel> = new Map<
    string,
    ConversationModel
  >();
  private _conversationIds: Set<string> = new Set<string>();
  private _initialisedAt: Date;
  private _intervalForGarbageCollectingAgents: NodeJS.Timer;
  private _intervalForGarbageCollectingConversations: NodeJS.Timer;
  private _intervalForGarbageCollectingVisitors: NodeJS.Timer;
  private _isActiveConversationLoaded: boolean = false;
  private _isActiveConversationLoading: boolean = false;
  private _isActiveConversationResolving: boolean = false;
  private _isActiveConversationReplying: boolean = false;
  private _isConversationListLoaded: boolean = false;
  private _isConversationListLoading: boolean = false;
  private _isBatchAssigning: boolean = false;
  private _isEnabled: boolean = false;
  private _isInitialised: boolean = false;
  private _isHandlingChangesOnAgents: boolean = true;
  private _isHandlingChangesOnConversations: boolean = true;
  private _isHandlingChangesOnVisitors: boolean = true;
  private _lastKnownAgentNetworkState: string;
  private _lastKnownBrowserNetworkState: string;
  private _modals: ModalAttributes[] = [];
  private _networkStateReference: firebase.database.Reference;
  private _ongoingConversationsIds: Set<string> = new Set<string>();
  // private _ongoingConversationsCollectionsReferences: CollectionReferenceMap = {};
  private _ownedConversationsIds: Set<string> = new Set<string>();
  // private _ownedConversationsCollectionsReferences: CollectionReferenceMap = {};
  private _periodOfDateRangeInDays =
    liveChatConfig.defaults.periodOfDateRangeInDays;
  private _permittedApplicationIds: string[] = [];
  private _quantityOfAssignedConversations: number = 0;
  private _quantityOfOngoingConversations: number = 0;
  private _quantityOfOwnedConversations: number = 0;
  private _quantityOfResolvedConversations: number = 0;
  private _quantityOfSuggestionsAvailable: number = 0;
  private _quantityOfTotalConversations: number = 0;
  private _quantityOfUnassignedConversations: number = 0;
  private _shouldIgnoreNetworkBounce: boolean = false;
  private _soundFiles;
  private _stalestConversation: ConversationModel;
  private _selectedSortingOptionForConversations: firebase.firestore.OrderByDirection =
    LiveChatService.SortingOptionsForConversations[1].value;
  private _subscriptionForChangesOnAgents: Subscription;
  private _subscriptionForChangesOnArchivedConversations: Subscription;
  private _subscriptionForChangesOnVisitors: Subscription;
  private _subscriptionForChangesOnConversations: Subscription;
  private _SSIUser: SSIUser;
  private _teams: Team[];
  private _unassignedConversationsIds: Set<string> = new Set<string>();
  private _agentLocalStateSetOffline: boolean = false;
  // private _unassignedConversationsCollectionsReferences: CollectionReferenceMap = {};
  private showBrowserDisconnectedModal;
  private setLocalStateToOffline;
  private userPreferences;
  private chatbotList = [];
  private hasChatbotFeature = false;

  static get SortingOptionsForConversations() {
    return [
      {
        translate: `${stringShortest}_${stringWaiting}`.toUpperCase(),
        value: symbols.descending as firebase.firestore.OrderByDirection
      },
      {
        translate: `${stringLongest}_${stringWaiting}`.toUpperCase(),
        value: symbols.ascending as firebase.firestore.OrderByDirection
      }
    ];
  }

  constructor(
    private ngZone: NgZone,
    private _localStorageService: LocalStorageService,
    private _SSIUserModel: SSIUserModel,
    private authentication: LiveChatAuthenticationService,
    private companyService: CompanyService,
    private database: AngularFirestore,
    private messaging: AngularFireMessaging,
    private pushNotifications: PushNotificationsService,
    private realtimeDatabase: AngularFireDatabase,
    private teamsService: TeamsService,
    private colleaguesService: ColleaguesService,
    private storage: AngularFireStorage,
    private notification: NotificationService,
    private chatbotService: ChatBotService,
    private userPreferencesService: UserPreferencesService
  ) {}

  public get activeApplication(): Application {
    return this._activeApplication;
  }

  public get activeConversation(): ConversationModel {
    return this._activeConversation;
  }

  // @todo this and activeConversationModel need a switcheroo
  public get activeConversationId(): string {
    return this._activeConversationId;
  }

  public set activeConversationId(value: string) {
    this.setActiveConversation(value);
  }

  public get activeConversationEvents(): ConversationUserEventModel {
    return this._activeConversationEvents;
  }

  public get activeFilter(): string {
    return this._activeFilter;
  }

  public set activeFilter(value: string) {
    this.refreshFilterOnConversations(value);
  }

  public get activeVisitor(): VisitorModel {
    return this._activeVisitor;
  }

  public get agentsReference(): AngularFirestoreCollection<Agent> {
    return this._agentsReference;
  }

  public get applications(): ApplicationModel[] {
    return this._applications;
  }

  public get areActiveConversationTagsUpdating(): boolean {
    return !!this._areActiveConversationTagsUpdating;
  }

  public get areSuggestionsAvailable(): boolean {
    return (
      !!this._quantityOfSuggestionsAvailable && !!this._areSuggestionsAvailable
    );
  }

  public get browserOfflineAt(): Date {
    return this._browserOfflineAt;
  }

  public get browserOfflineTotalInSeconds(): number {
    return Math.floor(this._browserOfflineTotalInMilliseconds / 1000);
  }

  public get canAdministerAccounts(): boolean {
    return !!this._canAdministerAccounts;
  }

  public get conversations() {
    return this._conversations;
  }

  public get isActiveConversationLoaded(): boolean {
    return this._isActiveConversationLoaded;
  }

  public get isActiveConversationLoading(): boolean {
    return this._isActiveConversationLoading;
  }

  public get isActiveConversationResolved(): boolean {
    return !!this.activeConversation && !!this.activeConversation.isResolved;
  }

  public get isActiveConversationResolving(): boolean {
    return this._isActiveConversationResolving;
  }

  public get isActiveConversationReplying(): boolean {
    return this._isActiveConversationReplying;
  }

  public get isBatchAssigning(): boolean {
    return this._isBatchAssigning;
  }

  public get isBrowserOffline(): boolean {
    return this._lastKnownBrowserNetworkState === stringOffline;
  }

  public get isConversationListReady(): boolean {
    return !!this._isConversationListLoaded && !this._isConversationListLoading;
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public isEnabled$ = new BehaviorSubject<boolean>(false);

  public get isHandlingChangesOnAgents(): boolean {
    return this._isHandlingChangesOnAgents;
  }

  public get isHandlingChangesOnConversations(): boolean {
    return this._isHandlingChangesOnConversations;
  }

  public get isHandlingChangesOnVisitors(): boolean {
    return this._isHandlingChangesOnVisitors;
  }

  public get isInterfaceDisabled(): boolean {
    return (
      this.isHandlingChangesOnConversations ||
      !!this.isActiveConversationReplying ||
      !!this.isActiveConversationResolving ||
      !!this.isActiveConversationLoading
    );
  }

  public get isPushModeEnabled(): boolean {
    if (!this._company) {
      return;
    }

    return !!this._company.isPushModeEnabled;
  }

  public set isPushModeEnabled(value: boolean) {
    this.togglePushMode();
  }

  public get modals(): ModalAttributes[] {
    return this._modals;
  }

  public get oldestPendingModal(): ModalAttributes {
    if (!this._modals || !this._modals.length) {
      return;
    }

    return this._modals.shift();
  }

  public get periodOfDateRangeInDays(): number {
    return this._periodOfDateRangeInDays;
  }

  public get pushModeAgentAssignmentPostResolutionDelay(): number {
    if (!this._company) {
      return;
    }

    return this._company.pushModeAgentAssignmentPostResolutionDelay;
  }

  public set pushModeAgentAssignmentPostResolutionDelay(value: number) {
    if (!this._company) {
      return;
    }

    this._company.pushModeAgentAssignmentPostResolutionDelay = value;
  }

  public get pushModeAgentAssignmentTarget(): number {
    if (!this._company) {
      return;
    }

    return this._company.pushModeAgentAssignmentTarget;
  }

  public set pushModeAgentAssignmentTarget(value: number) {
    if (!this._company) {
      return;
    }

    this._company.pushModeAgentAssignmentTarget = value;
  }

  public get quantityOfActiveAgents(): number {
    // @todo: set this as & when data comes in

    return Array.from(this.agents.values()).filter((agent) => agent.isOnline)
      .length;
  }

  public get quantityOfAssignedConversations(): number {
    return this._quantityOfAssignedConversations;
  }

  public get quantityOfOngoingConversations(): number {
    return this._quantityOfOngoingConversations;
  }

  public get quantityOfOwnedConversations(): number {
    return this._quantityOfOwnedConversations;
  }

  public get quantityOfResolvedConversations(): number {
    return this._quantityOfResolvedConversations;
  }

  public get quantityOfTotalConversations(): number {
    return this._quantityOfTotalConversations;
  }

  public get quantityOfUnassignedConversations(): number {
    return this._quantityOfUnassignedConversations;
  }

  public get quantityOfVisibleConversations(): number {
    if (!this.activeViewOfConversations) {
      return;
    }

    return this.activeViewOfConversations.length;
  }

  public get selectedSortingOptionForConversations(): firebase.firestore.OrderByDirection {
    return this._selectedSortingOptionForConversations;
  }

  public set selectedSortingOptionForConversations(
    selectedOption: firebase.firestore.OrderByDirection
  ) {
    try {
      this._selectedSortingOptionForConversations = selectedOption;
      this.initialiseConversations();
      this.refreshFilterOnConversations();
    } catch (error) {
      console.error(error);
    }
  }

  public get stalestConversation(): ConversationModel {
    return this._stalestConversation;
  }

  public get teams(): Team[] {
    return this._teams;
  }

  /**
   *
   * public methods
   *
   */

  public async addNoteOnMessage(
    content: string,
    message: MessageModel
  ): Promise<Note> {
    try {
      const newNote: NoteModel = noteModelFactory() as NoteModel;
      const showLoading: boolean = true;
      const subject: string = `livechat_message`;
      const subject_id: string =
        `` +
        `/${stringCompanies}/` +
        this.activeApplication.companyId +
        `/${stringApplications}/` +
        this.activeApplication.id +
        `/${stringConversations}/` +
        this.activeConversation.id +
        `/${stringMessages}/` +
        message.id;

      const note = await newNote.create(
        { content, subject, subject_id },
        { showLoading }
      );

      if (!(note instanceof Note)) {
        throw new Error(`Unexpected response in adding note!`);
      }

      return note;
    } catch (error) {
      console.error(error);
    }
  }

  public async addTagOnMessage(
    tag: string,
    message: MessageModel
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in: liveChatService.addTagOnMessage`);
      }

      const tags = message.tags;

      if (tags.indexOf(tag) !== -1) {
        return true;
      }

      tags.push(tag);

      return await this.updateTagsForMessage(tags, message);
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      if (isDebug) {
        console.log(`out: liveChatService.addTagOnMessage`);
      }
    }
  }

  public async closeActiveConversation() {
    try {
      await this.updateEventsOnActiveConversationForActiveAgent({
        lastTypedAt: null,
        lastViewedAt: null
      });
      this.unsubscribeFromActiveConversation();
      this._activeConversationId = undefined;
      this._activeConversation = undefined;
      this.activeMessages = [];

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async closeInterface() {
    await this.closeActiveConversation();
  }

  public async deleteNoteOnMessage(
    note: Note,
    message: MessageModel
  ): Promise<boolean> {
    try {
      await note.destroy();
      await Promise.all(
        this.activeMessages.map(async (foundMessage, index: number) => {
          try {
            if (foundMessage.id === message.id) {
              this.activeMessages[index].notes = await this.getNotesOnMessage(
                message
              );
            }

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        })
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async emitAudioNotification(index: number) {
    try {
      /*
        I *could* just grab some existing WebAudio library and use it here, but it will do way too much for what we need.
        and then having to look for which can be tree-shaken etc. will just be a distraction.

        I will look to polyfill this one way or another, using the <audio> tag.
      */

      if (!window.hasOwnProperty('AudioContext')) {
        // quietly fail if unsupported.
        return true;
      }

      if (index >= this._soundFiles.length) {
        throw new Error(`Invalid sound file index: ${index}!`);
      }

      const context: AudioContext = new AudioContext();
      const filename: string = this._soundFiles[index];

      const response: Response = await window.fetch(`${filename}`);
      const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
      const audioBuffer: AudioBuffer = await context.decodeAudioData(
        arrayBuffer
      );

      const source = context.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(context.destination);
      source.start();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public getResponseCacheForActiveConversation(): string {
    try {
      const key = `${keysForStorage.conversationResponseCache}.${this.activeConversationId}`;

      const value: any = this._localStorageService.get(key);

      if (!value || !value.updatedAt) {
        return '';
      }

      if (differenceInHours(new Date(), value.updatedAt) >= 8) {
        return '';
      }

      if (!value || !value.text) {
        return '';
      }

      return value.text;
    } catch (error) {
      console.error(error);

      return '';
    }
  }

  public getConversationsFilteredByAgentId(
    agentId: string
  ): ConversationModel[] {
    try {
      return this.getSortedViewOfConversationsForIds(
        Array.from(this.conversations.keys())
      ).filter(
        (conversation) =>
          conversation.agentID === agentId && !conversation.isResolved
      );
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public getFullModelForPerson(person: Person): Person {
    try {
      if (person instanceof AgentModel) {
        return this.agents.get(person.id);
      } else if (person instanceof VisitorModel) {
        return this.visitors.get(person.id);
      }

      return person;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public async getNotesOnMessage(message: MessageModel): Promise<Note[]> {
    try {
      const subject = liveChatSymbols.subjectForMessageNote;
      const subject_id =
        `` +
        `/${stringCompanies}/` +
        this.activeApplication.companyId +
        `/${stringApplications}/` +
        this.activeApplication.id +
        `/${stringConversations}/` +
        this.activeConversation.id +
        `/${stringMessages}/` +
        message.id;

      const noteModel = noteModelFactory() as NoteModel;
      const notes = await noteModel.findAll(
        { subject, subject_id },
        { showLoading: true }
      );

      return notes;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public getQuantityOfConversationsFilteredByAgentId(agentId: string): number {
    try {
      return Array.from(this.conversations.values()).filter(
        (conversation) =>
          conversation.agentID === agentId && !conversation.isResolved
      ).length;
    } catch (error) {
      console.error(error);

      return -1;
    }
  }

  public getVisitorByID(id: string): VisitorModel {
    try {
      return this.visitors.get(id);
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public async initialise(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`liveChatService~>initialise`);
      }

      this.initialiseResponseCache();

      this._soundFiles = [
        require('../../../../../binaries/mp3/pluck-double.mp3'),
        require('../../../../../binaries/mp3/notify.mp3')
        // require('../../../../../binaries/mp3/lip.mp3'),
        // require('../../../../../binaries/mp3/pluck.mp3'),
        // require('../../../../../binaries/mp3/pluck-short.mp3')
      ];

      this._companyReference = this.database
        .collection(this.config.collections.companies)
        .doc(String(this._SSIUser.company_id));

      const isCompanyInitialised = await this.initialiseCompany();
      if (!isCompanyInitialised) {
        throw new Error(`Could not initialise Company!`);
      }

      const areAgentsInitialised = await this.initialiseAgents();
      if (!areAgentsInitialised) {
        throw new Error(`Could not initialise Agents!`);
      }

      const areApplicationsInitialised = await this.initialiseApplications();
      if (!areApplicationsInitialised) {
        throw new Error(`Could not initialise Applications!`);
      }

      await this.refreshDateRange();

      this.initialiseNetworkState();
      // this.initialiseMessaging();

      await this.pushNotifications.requestPermission();

      this.userPreferences = await this.userPreferencesService.getPreferences();
      this.hasChatbotFeature = await this.companyService.hasFeatureAccess(
        'CHAT_BOT'
      );

      if (this.hasChatbotFeature) {
        this.chatbotList = await this.chatbotService.getChatBotList();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async login(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService.login`);
      }

      if (!firebaseProject) {
        throw new Error(`returning - no firebase project found.`);
      }

      if (this._isInitialised) {
        if (isDebug) {
          console.log(`returning - already initialised`);
        }

        return true;
      }

      const liveChatFeatureFlag =
        stringLive.toUpperCase() + stringChat.toUpperCase();

      const ifAccess = this.companyService.hasFeatureAccess(
        liveChatFeatureFlag
      );

      if (!ifAccess) {
        if (isDebug) {
          console.log(`returning - don't have feature flag access.`);
        }

        return true;
      }

      this._SSIUser = await this.getSSIUser();

      if (!(await this.authentication.authenticate(this._SSIUser))) {
        if (!!this.authentication.userPermissions.length) {
          throw new Error(`Cannot authenticate as Live Chat agent!`);
        }

        return true;
      }

      if (isDebug) {
        console.log(`Authenticated as Live Chat agent.`);
      }

      this._canAdministerAccounts = !!this._SSIUser.permissions.company
        .administer_users;

      this._isEnabled = true;
      this.isEnabled$.next(true);

      await this.initialise();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async logout(): Promise<boolean> {
    try {
      if (!this._isInitialised) {
        return false;
      }

      await this._networkStateReference.set(
        get_database_network_state(stringOffline)
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async markActiveConversationAsViewed(): Promise<boolean> {
    try {
      if (!!this.isActiveConversationReplying) {
        return true;
      }

      // @todo revisit later
      const actionedAt = firebase.firestore.FieldValue.serverTimestamp();
      const actionedBy = this.agentModel.id;

      if (
        !this.activeConversation.agentID ||
        this.activeConversation.agentID !== this.agentModel.id
      ) {
        return true;
      }

      if (
        !!this.activeConversation.lastMessage.actionedAt &&
        !!this.activeConversation.lastMessage.actionedBy
      ) {
        return true;
      }

      const lastMessage: MessagePreview = Object.assign(
        {},
        this.activeConversation.lastMessage.data,
        {
          actionedAt,
          actionedBy
        }
      );
      const updates: ConversationFragment = { lastMessage };

      await this._activeConversation.reference.update(updates);

      const batch = this.database.firestore.batch();
      const messages = await this._activeMessagesReference
        .snapshotChanges()
        .pipe(take(1))
        .toPromise();

      messages.forEach((message) => {
        const messageData = message.payload.doc.data() as MessagePreview;

        if (
          !(messageData.agent && messageData.agent.id) &&
          (!messageData.actionedAt || !messageData.actionedBy)
        ) {
          const updatedMessage = Object.assign({}, messageData, {
            actionedAt,
            actionedBy
          });
          batch.update(message.payload.doc.ref, updatedMessage);
        }
      });

      batch.commit();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public ngOnDestroy(): boolean {
    try {
      this.unsubscribeFromActiveConversation();

      if (!!this._subscriptionForChangesOnAgents) {
        this._subscriptionForChangesOnAgents.unsubscribe();
      }

      if (!!this._subscriptionForChangesOnArchivedConversations) {
        this._subscriptionForChangesOnArchivedConversations.unsubscribe();
      }

      if (!!this._subscriptionForChangesOnConversations) {
        this._subscriptionForChangesOnConversations.unsubscribe();
      }

      if (!!this._subscriptionForChangesOnVisitors) {
        this._subscriptionForChangesOnVisitors.unsubscribe();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async onChangeToActiveConversationsEventsSubscription(
    results: DocumentChangeAction<ConversationUserEvent>[]
  ): Promise<boolean> {
    try {
      // in theory, this should always exist already as should have been created
      // by Visitor starting chat.
      const eventDocumentReference = !!results.length
        ? results[0].payload.doc.ref
        : this._activeConversationEventsCollection.ref.doc();
      const eventDocument = await eventDocumentReference.get();
      const eventData = eventDocument.data();

      if (!eventData) {
        return true;
      }

      this._activeConversationEvents = ConversationUserEventModel.CreateFromQueryDocumentSnapshot(
        eventDocument
      );

      const conversation = this.activeConversation;
      const hasConversationAgent: boolean =
        !!eventData.agent && !!eventData.agent.id;
      const hasConversationVisitor: boolean =
        !!eventData.visitor && !!eventData.visitor.id;

      const relations = { conversation } as any;

      if (hasConversationAgent) {
        relations.agent = this.agents.get(eventData.agent.id);
      }

      if (hasConversationVisitor) {
        relations.visitor = this.visitors.get(eventData.visitor.id);
      }

      this._activeConversationEvents.setRelations(relations);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public onDisablePushMode() {
    try {
      const modalAttributes: ModalAttributes = {
        bodyContent: `Your manager has turned on Pull Mode, you will no longer be automatically assigned Chats. Go forth and grab all the chats you can!`,
        headerIcon: `ssi ssi-pull-mode`,
        headerText: `You are now in Pull Mode`,
        primaryButton: {
          action: async () => {},
          text: `Okay thanks!`
        }
      };

      this.modals.push(modalAttributes);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public onEnablePushMode() {
    try {
      const modalAttributes: ModalAttributes = {
        bodyContent:
          'Your manager has turned on Push Mode. Going online will start you being automatically assigned chats.',
        headerIcon: `ssi ssi-push-mode`,
        headerText: `Push mode time ${this.agentModel.displayName}!`,
        primaryButton: {
          action: async () => {
            await this.setAgentNetworkState(stringOnline, true);
          },
          text: `Go online`
        },
        secondaryButton: {
          action: async () => {
            await this.setAgentNetworkState(stringBusy, true);
          },
          text: `Set to unavailable`
        }
      };

      this.modals.push(modalAttributes);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async refreshDateRange(dateRange?: number): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`begin: liveChatService->refreshDateRange`);
      }

      if (!!dateRange) {
        this._periodOfDateRangeInDays = dateRange;
      }

      const areAgentsInitialised = await this.initialiseAgents();
      if (!areAgentsInitialised) {
        throw new Error(`Could not initialise Agents!`);
      }

      const areVisitorsInitialised = await this.initialiseVisitors();
      if (!areVisitorsInitialised) {
        throw new Error(`Could not initialise Visitors!`);
      }

      const areConversationsInitialised = await this.initialiseConversations();
      if (!areConversationsInitialised) {
        throw new Error(`Could not initialise Conversations!`);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async refreshFilterOnConversations(filter?: string) {
    try {
      const value = !!filter ? filter : this.activeFilter;
      this._activeFilter = value;

      switch (this.activeFilter) {
        case stringAssigned:
        case stringOngoing:
        case stringOwned:
        case stringUnassigned:
          const filteredConversations = this.getSortedConversations(
            Array.from(this.conversations.values()).filter((conversation) => {
              // malformed
              if (!(!!conversation && !!conversation.lastMessage)) {
                return false;
              }

              // already resolved
              if (!!conversation.isResolved) {
                return false;
              }

              const conversationState: string = !!conversation.agentID
                ? stringAssigned
                : stringUnassigned;

              if (conversationState === stringUnassigned) {
                return value === stringUnassigned;
              }

              if (value === stringAssigned) {
                return !!conversation.agentID;
              }

              if (value === stringOngoing) {
                return (
                  !!conversation.agentID &&
                  conversation.agentID === this.agentModel.id &&
                  !conversation.lastMessage.actionedAt &&
                  !conversation.lastMessage.agentID
                );
              }

              if (value === stringOwned) {
                return conversation.agentID === this.agentModel.id;
              }

              return false;
            })
          );

          this.activeViewOfConversations = filteredConversations;

          return;

        case stringTotal:
          this.activeViewOfConversations = this.getSortedConversations(
            Array.from(this.conversations.values())
          );

          return;

        default:
          const agentPrefix = `${stringAgent}-`;

          if (this.activeFilter.startsWith(agentPrefix)) {
            this.activeViewOfConversations = this.getConversationsFilteredByAgentId(
              this.activeFilter.slice(agentPrefix.length)
            );
          } else {
            this.activeFilter = stringAssigned;
          }

          return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async replyToActiveConversation(
    messageParameters: MessageFragment
  ): Promise<boolean> {
    try {
      if (
        (!messageParameters.text || !messageParameters.text.trim().length) &&
        !messageParameters.attachment
      ) {
        return false;
      }

      this._isActiveConversationReplying = true;

      const createdAt = firebase.firestore.FieldValue.serverTimestamp();
      const actionedAt = createdAt;
      const actionedBy = this.agentModel.id;
      const agent = {
        displayName: this.agentModel.displayName,
        id: this.agentModel.id
      };
      const lastReplyAgent = agent;
      const message = Object.assign({}, messageParameters, {
        actionedAt,
        actionedBy,
        agent,
        createdAt
      });
      const messageDocument = this._activeMessagesReference.ref.doc();
      const id = messageDocument.id;
      const lastMessage = Object.assign({}, message, { id });
      const lastAgentMessage = Object.assign({}, lastMessage);
      const queue = null;
      const visitorWaitingTime = createdAt;
      const payload = {
        lastAgentMessage,
        lastMessage,
        lastReplyAgent,
        queue,
        visitorWaitingTime
      } as any;

      const isTransactionSuccessful = await this.database.firestore.runTransaction(
        async (transaction) => {
          try {
            if (!this.activeConversation.hasAgent) {
              // assigning it to self

              if (this.isPushModeEnabled) {
                const latestAgent = await transaction.get(
                  this.agentModel.reference
                );
                const latestAgentData = latestAgent.data() as Agent;

                if (latestAgentData.statistics.isAtPushTarget) {
                  // already at assignment target.
                  // bail out of transaction

                  return true;
                }
              }

              payload.agent = agent;
            }

            transaction
              .set(messageDocument, message)
              .update(this._activeConversation.reference, payload);

            return true;
          } catch (error) {
            throw error;
          }
        }
      );

      if (!isTransactionSuccessful) {
        return false;
      }

      // await this.refreshActiveConversation();
      await this.markActiveConversationAsViewed();
      await this.updateEventsOnActiveConversationForActiveAgent({
        lastTypedAt: null
      });

      this._isActiveConversationReplying = false;

      return true;
    } catch (error) {
      console.error(error);

      this._isActiveConversationReplying = false;

      return false;
    }
  }

  public async requestTask(task: AbstractTask): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService.requestTask`);
        console.log(`task:`);
        console.dir(task);
      }

      if (Object.values(taskTypes).indexOf(task.type) === -1) {
        if (isDebug) {
          console.log(`returning - not a known task type!`);
        }

        return false;
      }

      const createdAt = firebase.firestore.FieldValue.serverTimestamp();
      const createdBy = {
        agent: {
          id: this.agentModel.id
        }
      };

      // @todo a factory may be useful in future when there are more task types.
      const taskRequest: AbstractTaskRequest = {
        createdAt,
        createdBy,
        task
      };

      const taskRequestsCollection: AngularFirestoreCollection<AbstractTaskRequest> = this._companyReference.collection<AbstractTaskRequest>(
        this.config.collections.taskRequests
      );

      if (isDebug) {
        console.log(`About to send task request:`);
        console.dir(taskRequest);
      }

      const response = await taskRequestsCollection.add(taskRequest);

      if (isDebug) {
        console.log(`Task request sent. Response:`);
        console.dir(response);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async toggleConversationResolution(
    conversation: ConversationModel,
    isResolving: boolean
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in toggleConversationResolution`);
        console.log(`isResolving: ${isResolving}`);
      }

      this._isHandlingChangesOnConversations = true;

      // Don't allow a conversation to be unresolved after a given length of time.
      if (
        !isResolving &&
        Date.now() >=
          addSeconds(
            conversation.dateOfResolution,
            this.applications.find(
              (application) => application.id === conversation.applicationId
            ).resolutionGracePeriod
          ).getTime()
      ) {
        if (isDebug) {
          console.log(`returning - conversation past resolution grace period.`);
        }

        return true;
      }

      const isSuccess = await this.database.firestore.runTransaction(
        async (transaction) => {
          try {
            // Since (un)resolving is a pretty big deal, ensure that there is an assigned agent, this agent.

            const agent: AgentFragment = {
              displayName: this.agentModel.displayName,
              id: this.agentModel.id
            };
            const conversationUpdates: ConversationFragment = {
              resolution: {
                agent: null,
                resolvedAt: null
              }
            };

            conversationUpdates.agent = agent;

            if (isResolving) {
              conversationUpdates.queue = null;
              conversationUpdates.resolution = {
                agent,
                resolvedAt: firebase.firestore.FieldValue.serverTimestamp()
              };
            }

            this._isActiveConversationResolving = true;

            if (isDebug) {
              console.log(`conversation updates:`);
              console.dir(conversationUpdates);
            }

            transaction.update(conversation.reference, conversationUpdates);

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        }
      );

      if (!isSuccess) {
        throw new Error(
          `Unsuccessful transaction when toggling conversation resolution.`
        );
      }

      // await this.refreshActiveConversation();
      await this.markActiveConversationAsViewed();
      await this.updateActiveAgentResponseCacheForActiveConversation('');

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      this._isActiveConversationResolving = false;
      this._isHandlingChangesOnConversations = false;
    }
  }

  public async selfAssignConversation(conversation: ConversationModel) {
    return this.toggleAssignmentToConversationForAgent(conversation);
  }

  public async selfAssignStalestUnassignedConversation(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(
          `in liveChatService~>selfAssignStalestUnassignedConversation`
        );
      }

      if (!this._stalestConversation) {
        if (isDebug) {
          console.log(`no stale conversation to assign`);
        }

        return true;
      }

      await this.selfAssignConversation(this._stalestConversation);

      if (!this._stalestConversation) {
        return true;
      }

      // @todo check if we truly want to open this chat immediately
      // I think some agents want it to be a simple 'gimme chats!' button
      // to be clicked 3 or 4 times.
      //
      // this.activeFilter = stringUnassigned;
      // this.activeConversationId = this._stalestConversation.id;
      // this.activeFilter = stringOwned;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async setAgentNetworkState(
    state: string,
    isManual: boolean = false,
    agent?: AgentModel,
    isConfirmed?: boolean
  ): Promise<string> {
    try {
      const previouslySetValue = this._localStorageService.get(
        keysForStorage.networkState
      ) as string;

      const isSettingSelfOffline: boolean =
        !!agent &&
        agent.id === this.agentModel.id &&
        state === stringOffline &&
        isManual &&
        !isConfirmed;

      if (isSettingSelfOffline) {
        this._shouldIgnoreNetworkBounce = true;
      }

      if (!!agent) {
        // Potentially stopping realtime database from being updated.
        // if (
        //   state === stringOffline &&
        //   agent.id !== this.agentModel.id && // @debug: === instead of !== for testing.
        //   !isConfirmed &&
        //   this.promptToSetOtherAgentOffline(agent, agent.networkStateValue)
        // ) {
        //   return state;
        // }

        const networkStateReference = this.realtimeDatabase.database.ref(
          `/${this.config.collections.companies}/${this._SSIUser.company_id}` +
            `/${this.config.collections.agents}/${agent.id}/` +
            `${this.config.symbols.networkStateField}`
        );

        const currentDatabaseState = get_database_network_state(state);

        if (isDebug) {
          console.log(`currentDatabaseState:`);
          console.log(currentDatabaseState);
        }

        await networkStateReference.set(currentDatabaseState);

        // const agentIndex = this.agents.findIndex(foundAgent => agent.id === foundAgent.id);
        // this.agents[agentIndex].networkState.value = state;

        if (agent.id === this.agentModel.id) {
          this.agentModel.networkState.value = state;

          // if (!isManual && !!previouslySetValue) {
          //   // automatic state change e.g. if connectivity changes
          //   state = previouslySetValue;
          // } else {
          //   if (isDebug) {
          //     console.log(`updating cached value for network state`);
          //   }

          //   this._localStorageService.set(keysForStorage.networkState, state);
          // }

          if (state !== stringOffline && !navigator.onLine) {
            throw new Error(
              `Can't go online; network appears to be unavailable.`
            );
          }
        }

        if (agent.id === this.agentModel.id) {
          this.agentModel.networkState.value = state;
        }

        if (isDebug) {
          console.log(
            `state: ${state}, previouslySetValue: ${previouslySetValue}`
          );
        }

        if (state === stringOnline && isManual) {
          if (isDebug) {
            console.log(
              `agent status newly online, notify them of push mode status`
            );
          }

          // 'force' due to potential race conditions
          this.notifyAgentOfPushModeStatus(true);
        }

        return state;
      }

      if (state === previouslySetValue && !!this._isInitialised) {
        if (isDebug) {
          console.log(`state === previouslySetValue: ${state}`);
        }

        return null;
      }

      // if (!isManual && !!previouslySetValue) {
      //   // automatic state change e.g. if connectivity changes
      //   state = previouslySetValue;
      // } else {
      //   if (isDebug) {
      //     console.log(`updating cached value for network state`);
      //   }

      //   this._localStorageService.set(keysForStorage.networkState, state);
      // }

      // if (state !== stringOffline && !navigator.onLine) {
      //   throw new Error(`Can't go online; network appears to be unavailable.`);
      // }

      await this.setAgentNetworkState(state, true, this.agentModel, true);

      const currentState = get_database_network_state(state);
      await this._networkStateReference.set(currentState);

      const newState = get_firestore_network_state(state);
      await this.agentModel.reference.update(newState);

      return state;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public async toggleAssignmentToConversationForAgent(
    conversation: ConversationModel,
    agent: AgentModel = this.agentModel
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(
          `begin: liveChatService.toggleAssignmentToConversationForAgent`
        );
      }

      if (
        !(
          !!conversation &&
          !conversation.isArchived &&
          !conversation.isResolved &&
          !agent.isOffline
        )
      ) {
        return true;
      }

      if (!agent) {
        throw new Error(`Invalid agent for conversation assignment.`);
      }

      // this._isHandlingChangesOnConversations = true;

      const isTransactionSuccessful = await this.database.firestore.runTransaction(
        async (transaction) => {
          try {
            const conversationUpdates: ConversationFragment = {
              agent: {
                id: null
              }
            };

            if (!conversation.isAssigned) {
              conversationUpdates.agent.displayName = agent.displayName;
              conversationUpdates.agent.id = agent.id;
            }

            const agentUpdates = {
              'statistics.latestAssignmentAt': firebase.firestore.FieldValue.serverTimestamp()
            };

            transaction.update(this.agentModel.reference, agentUpdates);
            transaction.update(conversation.reference, conversationUpdates);

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        }
      );

      if (!isTransactionSuccessful) {
        throw new Error(
          `Unsucesssful transaction when toggling conversation assignment.`
        );
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async assignNewAgentToConversation(
    conversation: ConversationModel,
    agent: AgentModel
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`begin: assignNewAgentToConversation`);
      }

      if (
        !(
          !!conversation &&
          !conversation.isArchived &&
          !conversation.isResolved &&
          !agent.isOffline
        )
      ) {
        return true;
      }

      if (!agent) {
        throw new Error(`Invalid agent for conversation assignment.`);
      }

      const agentModel = this.agents.get(agent.id);

      // this._isHandlingChangesOnConversations = true;

      const isTransactionSuccessful = await this.database.firestore.runTransaction(
        async (transaction) => {
          try {
            const conversationUpdates: ConversationFragment = {
              agent: {
                id: agent.id,
                displayName: agent.displayName
              }
            };

            const agentUpdates = {
              'statistics.latestAssignmentAt': firebase.firestore.FieldValue.serverTimestamp()
            };

            transaction.update(agentModel.reference, agentUpdates);
            transaction.update(conversation.reference, conversationUpdates);

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        }
      );

      if (!isTransactionSuccessful) {
        throw new Error(
          `Unsucesssful transaction when toggling conversation assignment.`
        );
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async unassignAgentFromConversation(
    conversation: ConversationModel
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`begin: unassignAgentFromConversation`);
      }

      if (
        !(
          !!conversation &&
          !conversation.isArchived &&
          !conversation.isResolved
        )
      ) {
        return true;
      }

      // this._isHandlingChangesOnConversations = true;

      const isTransactionSuccessful = await this.database.firestore.runTransaction(
        async (transaction) => {
          try {
            const conversationUpdates: ConversationFragment = {
              agent: {
                id: null,
                displayName: null
              }
            };

            transaction.update(conversation.reference, conversationUpdates);

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        }
      );

      if (!isTransactionSuccessful) {
        throw new Error(
          `Unsucesssful transaction when toggling conversation assignment.`
        );
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async togglePushMode(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`begin: liveChatService.togglePushMode`);
      }

      const task: TogglePushModeTask = {
        details: {
          agent: {
            id: this.agentModel.id
          },
          company: {
            id: this._company.id
          }
        },
        type: taskTypes.togglePushMode
      };

      await this.requestTask(task);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public unsubscribeFromActiveConversation(): boolean {
    try {
      if (!!this._activeMessagesReferenceSubscription) {
        this._activeMessagesReferenceSubscription.unsubscribe();
      }

      if (!!this._activeConversationReferenceSubscription) {
        this._activeConversationReferenceSubscription.unsubscribe();
      }

      if (!!this._activeConversationEventsSubscription) {
        this._activeConversationEventsSubscription.unsubscribe();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public updateActiveAgentResponseCacheForActiveConversation(
    text: string
  ): boolean {
    try {
      const key = `${keysForStorage.conversationResponseCache}.${this.activeConversation.id}`;

      this._localStorageService.set(key, {
        updatedAt: new Date(),
        text
      });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async updateEventsOnActiveConversationForActiveAgent(
    events: ConversationUserEventItemsForUser
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(
          `in liveChatService.updateEventsOnActiveConversationForActiveAgent`
        );
      }

      if (!this.agentModel || !this._activeConversationEvents) {
        if (isDebug) {
          console.log(
            `returning - agent model or conversation events, are not ready yet.`
          );
        }

        return true;
      }

      const items = Object.assign({}, this._activeConversationEvents.items);
      if (!!items.agents) {
        items.agents[this.agentModel.id] = Object.assign(
          {},
          items.agents[this.agentModel.id] || {},
          events
        );
      } else {
        items.agents = {
          [this.agentModel.id]: events
        };
      }

      const lastUpdatedAt = firebase.firestore.FieldValue.serverTimestamp();
      const updates = {
        items,
        lastUpdatedAt
      };

      await this._activeConversationEvents.reference.update(updates);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async updateTagsForMessage(
    tags: string[],
    message: MessageModel
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService.updateTagsForMesage`);
      }

      const updates = { tags };

      this._areActiveConversationTagsUpdating = true;

      const areTagsUpdated: boolean = await this.database.firestore.runTransaction(
        async (transaction) => {
          try {
            transaction.update(message.reference, updates);

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        }
      );

      if (!areTagsUpdated) {
        throw new Error(`Unable to update tags for message.`);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      this._areActiveConversationTagsUpdating = false;
    }
  }

  public async uploadToActiveConversation(file: File): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService.uploadToActiveConversation`);
      }

      const contentType: string = file.type;
      const metadata: firebase.storage.UploadMetadata = { contentType };
      const mimetype: string = contentType;
      const path = `/${stringUser}/${this.agentModel.id}/${Date.now()}/${
        file.name
      }`;

      const uploadResult: firebase.storage.UploadTaskSnapshot = await this.storage.upload(
        path,
        file,
        metadata
      );

      if (uploadResult.state !== stringSuccess) {
        throw new Error(`Upload was not a success.`);
      }

      const text = `${capitalize(stringFile)} ${stringUpload}`;
      const type = stringFile;
      const url = await uploadResult.ref.getDownloadURL();
      const attachment = {
        mimetype,
        type,
        url
      };
      const message = { attachment, text };

      return await this.replyToActiveConversation(message);
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  /**
   *
   * private methods
   *
   */

  private listenForNetworkStatusChanges(): void {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        const now = new Date();

        try {
          if (this._browserOfflineAt) {
            const timeDifference = differenceInMilliseconds(
              now,
              this._browserNetworkStateCheckedAt
            );

            if (isDebug) {
              console.log(`time difference: ${timeDifference}`);
            }

            this._browserOfflineTotalInMilliseconds += timeDifference;

            if (isDebug) {
              console.log(
                `returning - offline since ${this._browserOfflineAt}`
              );
            }

            return;
          }

          const currentBrowserNetworkState = !!navigator.onLine
            ? stringOnline
            : stringOffline;

          if (
            currentBrowserNetworkState === this._lastKnownBrowserNetworkState
          ) {
            return;
          }

          this.ngZone.run(() => {
            switch (currentBrowserNetworkState) {
              case stringOffline:
                {
                  this.onBrowserOffline();
                }
                break;

              case stringOnline:
                {
                  this.onBrowserOnline();
                }
                break;

              default: {
                throw new Error(
                  `Unexpected brower state! ${currentBrowserNetworkState}`
                );
              }
            }
          });
        } catch (error) {
          console.error(error);
        } finally {
          this._browserNetworkStateCheckedAt = now;
        }
      }, 1000);
    });
  }

  private async checkIfBrowserOnline(): Promise<boolean> {
    try {
      if (navigator.onLine) {
        return await this.onBrowserOnline();
      }

      this._browserOfflineTotalInMilliseconds +=
        LiveChatService.BrowserOfflineNoticeMinimumPeriod / 1000;
      setTimeout(
        async () => await this.checkIfBrowserOnline(),
        LiveChatService.BrowserOfflineNoticeMinimumPeriod
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private getReferenceToThisAgent(): AngularFirestoreCollection<Agent> {
    return this._companyReference.collection<Agent>(
      this.config.collections.agents,
      (reference) =>
        reference
          .where(`hasAnyWritePermission`, '==', true)
          .where(`isActive`, '==', true)
          .where(
            firebase.firestore.FieldPath.documentId(),
            '==',
            this._SSIUser.id
          )
    );
  }

  private async getSSIUser(): Promise<SSIUser> {
    try {
      const user = await this._SSIUserModel.getAuthUser();

      if (!user) {
        throw new Error(`Unable to get auth user of ssi user model!`);
      }

      return user;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  private getSortedConversations(
    conversations: ConversationModel[]
  ): ConversationModel[] {
    if (isDebug) {
      console.log(`in getSortedConversationsIds with ids:`);
      console.dir(conversations);
    }

    return this.selectedSortingOptionForConversations === symbols.ascending
      ? conversations.sort(
          (a: ConversationModel, b: ConversationModel) =>
            a.visitorWaitingTime.getTime() - b.visitorWaitingTime.getTime()
        )
      : conversations.sort(
          (a: ConversationModel, b: ConversationModel) =>
            b.visitorWaitingTime.getTime() - a.visitorWaitingTime.getTime()
        );
  }

  private getSortedConversationsIds(conversationIds: string[]): string[] {
    if (isDebug) {
      console.log(`in getSortedConversationsIds with ids:`);
      console.dir(conversationIds);
    }

    return this.selectedSortingOptionForConversations === symbols.ascending
      ? conversationIds.sort(
          (a, b) =>
            this.conversations.get(a).visitorWaitingTime.getTime() -
            this.conversations.get(b).visitorWaitingTime.getTime()
        )
      : conversationIds.sort(
          (a, b) =>
            this.conversations.get(b).visitorWaitingTime.getTime() -
            this.conversations.get(a).visitorWaitingTime.getTime()
        );
  }

  private getSortedViewOfConversationsForIds(
    conversationIds: string[],
    onlyReturnIds: boolean = false
  ) {
    return this.getSortedConversationsIds(
      conversationIds
    ).map((conversationId) => this.conversations.get(conversationId));
  }

  private async getTeams(): Promise<Team[]> {
    try {
      if (isDebug) {
        console.log(`begin: liveChatService.getTeams`);
      }

      const colleagues: Colleague[] = await this.colleaguesService.getAllActive();
      const foundTeams: SSITeam[] = await this.teamsService.getAll();

      let teams: Team[] = JSON.parse(JSON.stringify(foundTeams))
        .map((team: Team, index: number) => {
          team.agentsOnline = 0;

          return team;
        })
        .sort((a: Team, b: Team) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        );

      if (!(!!colleagues && Array.isArray(colleagues))) {
        throw new Error(
          `Value for 'live chat service colleagues' not in expected format.`
        );
      }

      Array.from(this.agents.values())
        .filter((agent) => agent.isOnline)
        .forEach((agent) => {
          const agentColleague = colleagues.find(
            (colleague) => agent.id === colleague.id
          );

          if (!agentColleague) {
            return;
          }

          agentColleague.teams.forEach((teamID: number) => {
            teams = teams.map((team) => {
              if (team.id === String(teamID)) {
                team.agentsOnline++;
              }

              return team;
            });
          });
        });

      return teams;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  private async initialiseAgents(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`begin: liveChatService.initialiseAgents`);
      }

      this._isHandlingChangesOnAgents = true;
      this.stopGarbageCollectingAgents();

      const agentSnapshot = await this.getReferenceToThisAgent()
        .snapshotChanges()
        .pipe(take(1))
        .toPromise<DocumentChangeAction<any>[]>();

      if (!agentSnapshot || !agentSnapshot.length) {
        throw new Error(`Unable to retrieve reference to agent!`);
      }

      this.agentModel = AgentModel.CreateFromQueryDocumentSnapshot(
        agentSnapshot[0].payload.doc
      );

      const threeDaysAgo = startOfDay(subDays(Date.now(), 3));

      this._agentsReference = this._companyReference.collection<Agent>(
        this.config.collections.agents,
        (reference) =>
          reference
            .where(`hasAnyWritePermission`, '==', true)
            .where(`isActive`, '==', true)
        // .where(`networkState.updatedAt`, `>`, threeDaysAgo)
      );

      const agentsSubscription = this._agentsReference
        .snapshotChanges()
        // .pipe(bufferTime(1000))
        // .pipe(map((arr) => arr.reduce((acc, curr) => acc.concat(curr), [])))
        .subscribe(async (initialData: DocumentChangeAction<Agent>[]) => {
          try {
            if (!(await this.handleInitialDataForAgents(initialData))) {
              throw new Error(
                `Unable to initialise data for ${strings.agents}!`
              );
            }
            agentsSubscription.unsubscribe();
            this._isHandlingChangesOnAgents = false;
            this.startGarbageCollectingAgents();

            this._agentsReference
              .stateChanges()
              .subscribe(async (changedData: DocumentChangeAction<Agent>[]) =>
                this.handleChangedDataForAgents(changedData)
              );
          } catch (error) {
            console.error(error);

            return false;
          }
        });
      if (isDebug) {
        console.log(`Agents initialised`);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async initialiseApplications(): Promise<boolean> {
    try {
      this._permittedApplicationIds = this.authentication.userPermissions.filter(
        (applicationId: string) =>
          this.agentModel.hasWritePermissionForApplicationID(applicationId)
      );

      // @todo: maybe 'handle' where an ID is one list but not in the other.

      // @todo: may want to consider some kind of 'read only' segregation here.

      const results = await combineLatest(
        this._permittedApplicationIds.map((applicationId: string) => {
          return this._companyReference
            .collection<Application>(
              this.config.collections.applications,
              (reference) =>
                reference.where(
                  firebase.firestore.FieldPath.documentId(),
                  `==`,
                  applicationId
                )
            )
            .snapshotChanges();
        })
      )
        .pipe(map((arr) => arr.reduce((acc, curr) => acc.concat(curr), [])))
        .pipe(take(1))
        .toPromise<DocumentChangeAction<any>[]>();

      this._applications = results.map((result) =>
        ApplicationModel.CreateFromQueryDocumentSnapshot(result.payload.doc)
      );

      if (!(!!this.applications && Array.isArray(this.applications))) {
        throw new Error(
          `Value for 'live chat service applications' not in expected format.`
        );
      }

      if (isDebug) {
        console.log(`Applications initialised`);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private initialiseCompany(): boolean {
    try {
      if (isDebug) {
        console.log(`begin: liveChatService.initialiseCompany`);
      }

      this._companyReference.snapshotChanges().subscribe(async (result) => {
        try {
          const companyDocument = await this._companyReference.ref.get();

          if (!companyDocument.exists) {
            this._isEnabled = false;
            this.isEnabled$.next(false);

            throw new Error(`Company not found!`);
          }

          this._company = CompanyModel.CreateFromQueryDocumentSnapshot(
            result.payload
          );
          this.notifyAgentOfPushModeStatus();

          return true;
        } catch (error) {
          console.error(error);

          return false;
        }
      });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async initialiseConversations(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (isDebug) {
          console.log(`begin: liveChatService.initialiseConversations`);
        }

        this._isConversationListLoaded = false;
        this._isConversationListLoading = true;

        this._isHandlingChangesOnConversations = true;
        this.stopGarbageCollectingConversations();

        const now: Date = new Date();
        const companyId = String(this._SSIUser.company_id);
        const daysAgo: Date = startOfDay(
          subDays(new Date(), this.periodOfDateRangeInDays)
        );

        // watch for conversations archived since we started this 'current' view of conversations

        const queryForFreshlyArchivedConversationsOnApplication: QueryFn = (
          reference: firebase.firestore.CollectionReference
        ) =>
          reference
            .where('archived', '==', true)
            .where('archivedAt', '>=', now)
            .orderBy('archivedAt', this.selectedSortingOptionForConversations);

        this._applications.forEach((application: ApplicationModel) => {
          const path =
            `${this.config.collections.companies}/` +
            `${companyId}/${this.config.collections.applications}/` +
            `${application.id}/${this.config.collections.conversations}`;

          this._archivedConversationCollectionReferences.set(
            application.id,
            this.database.collection<Conversation>(
              path,
              queryForFreshlyArchivedConversationsOnApplication
            )
          );
        });

        if (!!this._subscriptionForChangesOnArchivedConversations) {
          this._subscriptionForChangesOnArchivedConversations.unsubscribe();
        }

        this._subscriptionForChangesOnArchivedConversations = combineLatest<
          any[]
        >(
          ...Array.from(
            this._archivedConversationCollectionReferences.values()
          ).map((collectionReference) => collectionReference.stateChanges())
        )
          .pipe(map((arr) => arr.reduce((acc, curr) => acc.concat(curr), [])))
          .subscribe(async (changedData) =>
            this.handleChangedDataForArchivedConversations(changedData)
          );

        // standard querying

        const queryForAllConversationsOnApplication: QueryFn = (
          reference: firebase.firestore.CollectionReference
        ) =>
          reference
            .where('archived', '==', false)
            .where(`lastMessage.createdAt`, `>`, daysAgo)
            .orderBy(
              'lastMessage.createdAt',
              this.selectedSortingOptionForConversations
            )
            .orderBy(
              'visitorWaitingTime',
              this.selectedSortingOptionForConversations
            );

        this._applications.forEach((application: ApplicationModel) => {
          const path =
            `${this.config.collections.companies}/` +
            `${companyId}/${this.config.collections.applications}/` +
            `${application.id}/${this.config.collections.conversations}`;

          this._conversationCollectionReferences.set(
            application.id,
            this.database.collection<Conversation>(
              path,
              queryForAllConversationsOnApplication
            )
          );
        });

        if (!!this._subscriptionForChangesOnConversations) {
          this._subscriptionForChangesOnConversations.unsubscribe();
        }

        this.conversations.clear();
        this._quantityOfAssignedConversations = 0;
        this._quantityOfOngoingConversations = 0;
        this._quantityOfOwnedConversations = 0;
        this._quantityOfResolvedConversations = 0;
        this._quantityOfUnassignedConversations = 0;

        const initialConversationsSubscription = combineLatest<any[]>(
          ...Array.from(
            this._conversationCollectionReferences.values()
          ).map((collectionReference: AngularFirestoreCollection) =>
            collectionReference.snapshotChanges(['added'])
          )
        )
          .pipe(map((arr) => arr.reduce((acc, curr) => acc.concat(curr), [])))
          .pipe(take(1))
          .subscribe(async (initialData) => {
            try {
              await this.handleInitialDataForConversations(initialData);
              initialConversationsSubscription.unsubscribe();

              // this.startGarbageCollectingConversations();

              this._subscriptionForChangesOnConversations = combineLatest<
                any[]
              >(
                ...Array.from(
                  this._conversationCollectionReferences.values()
                ).map((collectionReference) =>
                  collectionReference.stateChanges()
                )
              )
                .pipe(
                  map((arr) => arr.reduce((acc, curr) => acc.concat(curr), []))
                )
                .subscribe(async (changedData) =>
                  this.handleChangedDataForConversations(changedData)
                );

              return resolve(true);
            } catch (error) {
              console.error(error);

              return reject(false);
            }
          });
      } catch (error) {
        console.error(error);

        return false;
      }
    });
  }

  private handleChangedDataForAgents(rawAgents: DocumentChangeAction<Agent>[]) {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>handleChangedDataForAgents`);
        console.log(`have ${rawAgents.length}`);
        console.dir(rawAgents);
      }

      this._isHandlingChangesOnAgents = true;

      rawAgents.forEach((rawAgent: DocumentChangeAction<Agent>) => {
        if (
          !(
            !!rawAgent &&
            !!rawAgent.payload &&
            !!rawAgent.payload.doc &&
            !!rawAgent.payload.doc.id &&
            !!rawAgent.payload.doc.data()
          )
        ) {
          console.error(`Unable to process data for agent:`);
          console.dir(rawAgent);

          return;
        }

        const agentId = rawAgent.payload.doc.id;
        const agentData = rawAgent.payload.doc.data() as Agent;

        if (!agentId) {
          console.error(`Invalid agent ID: ${agentId}`);

          return;
        }

        if (!agentData) {
          console.error(`Invalid data for agent ${agentId}!`);
          console.log(rawAgent);

          return;
        }

        // if (
        //   rawAgent.type === strings.removed ||
        //   rawAgent.type === strings.deleted
        // ) {
        //   this.agents.delete(rawAgent.payload.doc.id);

        //   return;
        // }

        let agent: AgentModel;

        if (this.agents.has(agentId)) {
          agent = this.agents.get(agentId).setDataAndRelations(agentData);
        } else {
          agent = AgentModel.CreateFromQueryDocumentSnapshot({
            data: agentData,
            id: agentId,
            ref: rawAgent.payload.doc.ref
          });
        }

        this.agents.set(agentId, agent);

        if (agentId === this._SSIUser.id) {
          this.agentModel = agent;

          if (isDebug) {
            console.log(`updating agentModel`);
            console.log(`network state: ` + agent.networkStateValue);
          }
        }
      });

      this._isHandlingChangesOnAgents = false;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private handleChangedDataForArchivedConversations(
    results: DocumentChangeAction<Conversation>[]
  ): boolean {
    try {
      if (isDebug) {
        console.log(
          `in liveChatService~>handleChangedDataForArchivedConversations`
        );
        console.log(`have ${results.length} archived conversations`);
      }

      results.forEach((rawConversation: DocumentChangeAction<Conversation>) => {
        if (isDebug) {
          console.log(`change to archived converstion!`);
          console.dir(rawConversation);
          console.dir(rawConversation.payload.doc.data());
        }

        if (
          !(
            !!rawConversation &&
            !!rawConversation.payload &&
            !!rawConversation.payload.doc &&
            !!rawConversation.payload.doc.id &&
            !!rawConversation.payload.doc.data()
          )
        ) {
          console.error(`Unable to process data for conversation:`);
          console.dir(rawConversation);

          return;
        }

        const conversationId: string = rawConversation.payload.doc.id;

        if (!conversationId) {
          console.error(`Invalid conversation ID: ${conversationId}`);

          return;
        }

        if (rawConversation.payload.type === 'added') {
          if (isDebug) {
            console.log(`newly archived conversation ${conversationId}`);
          }

          if (this.conversations.has(conversationId)) {
            if (isDebug) {
              console.log(`... found in local cache, deleting...`);
            }

            this.conversations.delete(conversationId);
            this._quantityOfResolvedConversations =
              this._quantityOfResolvedConversations > 0
                ? this._quantityOfResolvedConversations - 1
                : 0;
            this.refreshFilterOnConversations();
          } else {
            if (isDebug) {
              console.log(`... NOT found in local cache!`);
            }
          }
        }
      });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async handleChangedDataForConversations(
    results: DocumentChangeAction<Conversation>[]
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>handleChangedDataForConversations`);
        console.log(`have ${results.length} changed conversations`);
      }

      this._isHandlingChangesOnConversations = true;

      // grab all of the items in the collection and store them as conversation models.
      // const conversations: ConversationModel[] = [];

      let quantityOfOngoingChatsWithFreshMessages: number = 0;
      let quantityOfFreshUnassignedChats: number = 0;

      if (!this.userPreferences) {
        this.userPreferences = await this.userPreferencesService.getPreferences();
      }

      results.forEach((rawConversation: DocumentChangeAction<Conversation>) => {
        if (
          !(
            !!rawConversation &&
            !!rawConversation.payload &&
            !!rawConversation.payload.doc &&
            !!rawConversation.payload.doc.id &&
            !!rawConversation.payload.doc.data()
          )
        ) {
          console.error(`Unable to process data for conversation:`);
          console.dir(rawConversation);

          return;
        }

        const conversationId: string = rawConversation.payload.doc.id;
        const conversationData = rawConversation.payload.doc.data() as Conversation;

        if (!conversationId) {
          console.error(`Invalid conversation ID: ${conversationId}`);

          return;
        }

        if (!conversationData) {
          console.error(`Invalid data for conversation ${conversationId}!`);
          console.log(rawConversation);

          return;
        }

        if (isDebug) {
          console.log(
            `change to conversation ${conversationId}, of type: ${rawConversation.type}`
          );
        }

        if (rawConversation.type === 'removed') {
          return;
        }

        // an addition

        const conversationApplicationId: string =
          !!conversationData &&
          !!conversationData.application &&
          !!conversationData.application.id
            ? conversationData.application.id
            : null;

        if (!(!!rawConversation && !!conversationApplicationId)) {
          console.error(
            `returning - no application id for conversation ${conversationId}!`
          );

          return;
        }

        const conversationState: string =
          !!conversationData.agent &&
          !!conversationData.agent.id &&
          !!conversationData.agent.displayName
            ? stringAssigned
            : stringUnassigned;
        const isConversationResolved: boolean =
          !!conversationData.resolution &&
          !!conversationData.resolution.agent &&
          !!conversationData.resolution.agent.id &&
          !!conversationData.resolution.resolvedAt;
        const isConversationAssigned: boolean =
          conversationState === stringAssigned && !isConversationResolved;
        const isConversationOwned: boolean =
          isConversationAssigned &&
          conversationData.agent.id === this.agentModel.id;
        const isConversationOngoing: boolean =
          isConversationOwned &&
          !!conversationData.lastMessage &&
          !conversationData.lastMessage.actionedAt &&
          !!conversationData.lastMessage.agent &&
          !conversationData.lastMessage.agent.id;
        const isConversationUnassigned: boolean =
          conversationState === stringUnassigned && !isConversationResolved;

        let conversation: ConversationModel;

        if (this.conversations.has(conversationId)) {
          if (isDebug) {
            console.log(
              `conversation ${conversationId} already exists locally`
            );
          }

          const previousData = this.conversations.get(conversationId)
            .data as Conversation;
          // const conversationDifferences = deepDiff.diff(
          //   conversationData,
          //   previousData
          // );

          // if (!conversationDifferences) {
          //   if (isDebug) {
          //     console.log(`... and is unchanged since we last dealt with it.`);
          //   }

          //   // if (isDebug) {
          //   //   console.log(
          //   //     `... conversation ${conversationId} has no differences.`
          //   //   );
          //   // }

          //   // return;
          // } else {
          //   if (isDebug) {
          //     console.log(`!! conversation ${conversationId} has differences.`);
          //     console.dir(conversationDifferences);
          //   }
          // }

          const previousConversationState: string =
            !!previousData.agent &&
            !!previousData.agent.id &&
            !!previousData.agent.displayName
              ? stringAssigned
              : stringUnassigned;

          const hasStateChanged =
            conversationState !== previousConversationState;
          const wasConversationResolved: boolean =
            !!previousData.resolution &&
            !!previousData.resolution.agent &&
            !!previousData.resolution.agent.id &&
            !!previousData.resolution.resolvedAt;
          const wasConversationAssigned: boolean =
            previousConversationState === stringAssigned &&
            !wasConversationResolved;
          const wasConversationOwned: boolean =
            wasConversationAssigned &&
            previousData.agent.id === this.agentModel.id;
          const wasConversationOngoing: boolean =
            wasConversationOwned &&
            !!previousData.lastMessage &&
            !previousData.lastMessage.actionedAt &&
            !!previousData.lastMessage.agent &&
            !previousData.lastMessage.agent.id;
          const wasConversationUnassigned: boolean =
            previousConversationState === stringUnassigned &&
            !wasConversationResolved;

          const hasConversationChangedAgent: boolean =
            conversationData.agent.id !== previousData.agent.id;

          const isNewlyAssigned =
            isConversationAssigned && !wasConversationAssigned;
          const isNewlyResolved =
            isConversationResolved && !wasConversationResolved;
          const isNewlyUnresolved =
            wasConversationResolved && !isConversationResolved;

          if (hasStateChanged && !(isNewlyResolved || isNewlyUnresolved)) {
            if (isConversationAssigned) {
              this._quantityOfUnassignedConversations =
                this._quantityOfUnassignedConversations > 0
                  ? this._quantityOfUnassignedConversations - 1
                  : 0;
              this._quantityOfAssignedConversations++;

              if (isConversationOwned) {
                this._quantityOfOwnedConversations++;

                if (isConversationOngoing) {
                  this._quantityOfOngoingConversations++;
                }
              }
            } else {
              this._quantityOfAssignedConversations =
                this._quantityOfAssignedConversations > 0
                  ? this._quantityOfAssignedConversations - 1
                  : 0;
              this._quantityOfUnassignedConversations++;

              if (wasConversationOwned) {
                this._quantityOfOwnedConversations =
                  this._quantityOfOwnedConversations > 0
                    ? this._quantityOfOwnedConversations - 1
                    : 0;

                if (wasConversationOngoing) {
                  this._quantityOfOngoingConversations =
                    this._quantityOfOngoingConversations > 0
                      ? this._quantityOfOngoingConversations - 1
                      : 0;
                }
              }
            }
          } else {
            if (
              isConversationAssigned &&
              wasConversationAssigned &&
              !!conversationData.agent &&
              !!conversationData.agent.id &&
              !!previousData.agent &&
              !!previousData.agent.id &&
              conversationData.agent.id !== previousData.agent.id
            ) {
              // the chat has changed Agent ownership

              if (
                !!conversationData.agent &&
                !!conversationData.agent.id &&
                !!conversationData.agent.id === this.agentModel.id
              ) {
                this._quantityOfOwnedConversations++;

                if (isConversationOngoing && !wasConversationOngoing) {
                  this._quantityOfOngoingConversations++;
                }
              } else if (
                !!previousData.agent &&
                !!previousData.agent.id &&
                !!previousData.agent.id === this.agentModel.id
              ) {
                this._quantityOfOwnedConversations =
                  this._quantityOfOwnedConversations > 0
                    ? this._quantityOfOwnedConversations - 1
                    : 0;

                if (wasConversationOngoing && !isConversationOngoing) {
                  this._quantityOfOngoingConversations =
                    this._quantityOfOngoingConversations > 0
                      ? this._quantityOfOngoingConversations - 1
                      : 0;
                }
              }
            } else {
              if (isNewlyResolved) {
                this._quantityOfResolvedConversations++;

                if (wasConversationAssigned) {
                  this._quantityOfAssignedConversations =
                    this._quantityOfAssignedConversations > 0
                      ? this._quantityOfAssignedConversations - 1
                      : 0;

                  if (wasConversationOwned) {
                    this._quantityOfOwnedConversations =
                      this._quantityOfOwnedConversations > 0
                        ? this._quantityOfOwnedConversations - 1
                        : 0;

                    if (wasConversationOngoing) {
                      this._quantityOfOngoingConversations =
                        this._quantityOfOngoingConversations > 0
                          ? this._quantityOfOngoingConversations - 1
                          : 0;
                    }
                  }
                } else if (wasConversationUnassigned) {
                  this._quantityOfUnassignedConversations =
                    this._quantityOfUnassignedConversations > 0
                      ? this._quantityOfUnassignedConversations - 1
                      : 0;
                }
              } else if (isNewlyUnresolved) {
                // console.log(`conversation newly unresolved`);

                const assignedAgent = this.agents.get(
                  conversationData.agent.id
                );

                this._quantityOfResolvedConversations =
                  this._quantityOfResolvedConversations > 0
                    ? this._quantityOfResolvedConversations - 1
                    : 0;

                if (!!assignedAgent && assignedAgent.isOnline) {
                  this._quantityOfAssignedConversations++;

                  if (isConversationOwned) {
                    this._quantityOfOwnedConversations++;

                    if (isConversationOngoing) {
                      this._quantityOfOngoingConversations++;
                    }
                  }
                } else {
                  this._quantityOfUnassignedConversations++;
                }
              } else {
                if (wasConversationOngoing && !isConversationOngoing) {
                  this._quantityOfOngoingConversations =
                    this._quantityOfOngoingConversations > 0
                      ? this._quantityOfOngoingConversations - 1
                      : 0;
                } else if (!wasConversationOngoing && isConversationOngoing) {
                  this._quantityOfOngoingConversations++;
                }
              }
            }

            if (isConversationOngoing) {
              if (
                !!conversationData.lastVisitorMessage &&
                !!conversationData.lastVisitorMessage.createdAt &&
                !!previousData &&
                !!previousData.lastVisitorMessage &&
                !!previousData.lastVisitorMessage.createdAt &&
                isAfter(
                  timestamp_to_date(
                    conversationData.lastVisitorMessage.createdAt
                  ),
                  timestamp_to_date(previousData.lastVisitorMessage.createdAt)
                )
              ) {
                // new message on ongoing chat

                quantityOfOngoingChatsWithFreshMessages++;
              }
            }
          }

          const relations = {} as any;

          if (isNewlyAssigned) {
            const agent =
              !!conversationData.agent && !!conversationData.agent.id
                ? this.agents.get(conversationData.agent.id)
                : null;
            relations.agent = agent;
          }

          if (
            hasConversationChangedAgent &&
            conversationData.agent.id === this.agentModel.id
          ) {
            if (this.userPreferences.audio_chat_prompt) {
              this.emitAudioNotification(0);
            }

            if (this.userPreferences.notification_chat_prompt) {
              this.notification.open(
                `You have been assigned a current chat with ${conversationData.visitor.displayName}.`,
                {
                  class: 'ssi ssi-ongoing-chats',
                  color: '#838EAB'
                },
                null,
                true,
                true,
                true
              );
            }
          }

          conversation = this.conversations
            .get(conversationId)
            .setDataAndRelations(conversationData, relations);

          if (isConversationOwned) {
            const hasUnreadVisitorMessage =
              !!conversation.lastMessage.visitor &&
              (!this.activeConversation ||
                conversation.id !== this.activeConversation.id) &&
              isAfter(
                conversation.dateOfLastMessage,
                timestamp_to_date(previousData.lastMessage.createdAt)
              );

            if (
              hasUnreadVisitorMessage &&
              !conversation.lastVisitorMessage.robot
            ) {
              if (this.userPreferences.audio_chat_prompt) {
                this.emitAudioNotification(1);
              }

              const body =
                `${conversation.lastMessage.authorName}: \n` +
                `${conversation.lastMessage.preview}`;

              this.pushNotifications.generateNotification(
                `${capitalize(
                  strings.new
                )} ${stringMessage} on ${stringAssigned} ${stringChat}.`,
                { body }
              );

              if (this.userPreferences.notification_chat_prompt) {
                this.notification.open(
                  `You have a new ${stringMessage} from ${conversation.visitor.displayName}.`,
                  {
                    class: 'ssi ssi-ongoing-chats',
                    color: '#838EAB'
                  },
                  null,
                  true,
                  true,
                  true
                );
              }
            }
          } else if (isConversationUnassigned) {
            let isChatbotAgent = false;

            if (
              this.hasChatbotFeature &&
              this.chatbotList.length > 0 &&
              conversation.agent
            ) {
              isChatbotAgent = this.chatbotList.find(
                (bot) => bot.id === conversation.agent.id
              );
            }

            if (!isChatbotAgent) {
              if (this.userPreferences.audio_chat_prompt) {
                this.emitAudioNotification(0);
              }

              if (!!this.pushNotifications.isAvailable) {
                const body: string = `${conversation.firstMessage.preview}`;
                const title: string = `${capitalize(
                  strings.new
                )} ${stringUnassigned} ${stringChat}.`;

                this.pushNotifications.generateNotification(title, { body });
              }

              if (this.userPreferences.notification_chat_prompt) {
                this.notification.open(
                  `There is a new ${stringMessage} waiting on an ${stringUnassigned} ${stringChat}.`,
                  {
                    class: 'ssi ssi-ongoing-chats',
                    color: '#838EAB'
                  },
                  null,
                  true,
                  true,
                  true
                );
              }
            }
          }
        } else {
          const conversationDocument = rawConversation.payload.doc;
          const visitor = this.visitors.get(conversationData.visitor.id);
          const relations = { visitor } as any;

          if (conversationData.agent && conversationData.agent.id) {
            relations.agent = this.agents.get(conversationData.agent.id);
          }

          conversation = ConversationModel.CreateFromQueryDocumentSnapshot(
            conversationDocument
          );
          conversation.setRelations(relations);

          if (isConversationAssigned) {
            this._quantityOfAssignedConversations++;

            if (isConversationOwned) {
              this._quantityOfOwnedConversations++;

              if (isConversationOngoing) {
                this._quantityOfOngoingConversations++;
              }
            }
          } else {
            this._quantityOfUnassignedConversations++;

            if (this.userPreferences.audio_chat_prompt) {
              this.emitAudioNotification(0);
            }

            if (!!this.pushNotifications.isAvailable) {
              const body: string = `${conversation.firstMessage.preview}`;
              const title: string = `${capitalize(
                strings.new
              )} ${stringUnassigned} ${stringChat}.`;

              this.pushNotifications.generateNotification(title, { body });
            }

            if (this.userPreferences.notification_chat_prompt) {
              this.notification.open(
                `There is a new ${stringChat} waiting to be ${stringAssigned}.`,
                {
                  class: 'ssi ssi-new-message',
                  color: '#838EAB'
                },
                null,
                true,
                true,
                true
              );
            }

            if (
              !(
                !!conversation &&
                !!conversation.lastAgentMessage &&
                !!conversation.lastAgentMessage.createdAt
              )
            ) {
              quantityOfFreshUnassignedChats++;
            }
          }
        }

        if (!conversation.applicationName) {
          // will already have one if already fetched

          const conversationApplication = this.applications.find(
            (application) => application.id === conversation.applicationId
          );

          conversation.applicationName = !!conversationApplication
            ? conversationApplication.name
            : '';
        }

        this.conversations.set(conversationId, conversation);
      });

      this._quantityOfTotalConversations =
        this.quantityOfAssignedConversations +
        this.quantityOfResolvedConversations +
        this.quantityOfUnassignedConversations;

      this.refreshStalestConversation();
      this.refreshFilterOnConversations();

      this._isHandlingChangesOnConversations = false;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private handleChangedDataForVisitors(
    rawVisitors: DocumentChangeAction<Visitor>[]
  ) {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>handleChangedDataForVisitors`);
        console.log(`have ${rawVisitors.length}`);
        console.dir(rawVisitors);
      }

      this._isHandlingChangesOnVisitors = true;

      rawVisitors.forEach((rawVisitor: DocumentChangeAction<Visitor>) => {
        if (
          !(
            !!rawVisitor &&
            !!rawVisitor.payload &&
            !!rawVisitor.payload.doc &&
            !!rawVisitor.payload.doc.id &&
            !!rawVisitor.payload.doc.data()
          )
        ) {
          console.error(`Unable to process data for visitor:`);
          console.dir(rawVisitor);

          return;
        }

        const visitorId = rawVisitor.payload.doc.id;
        const visitorData = rawVisitor.payload.doc.data() as Visitor;

        if (!visitorId) {
          console.error(`Invalid visitor ID: ${visitorId}`);

          return;
        }

        if (!visitorData) {
          console.error(`Invalid data for visitor ${visitorId}!`);
          console.log(rawVisitor);

          return;
        }

        if (
          rawVisitor.type === strings.removed ||
          rawVisitor.type === strings.deleted
        ) {
          this.agents.delete(rawVisitor.payload.doc.id);

          return;
        }

        let visitor: VisitorModel;

        if (this.visitors.has(visitorId)) {
          visitor = this.visitors
            .get(visitorId)
            .setDataAndRelations(visitorData);
        } else {
          visitor = VisitorModel.CreateFromQueryDocumentSnapshot({
            data: visitorData,
            id: visitorId,
            ref: rawVisitor.payload.doc.ref
          });

          for (const [
            conversationId,
            conversation
          ] of this.conversations.entries()) {
            if (conversation.visitorID === visitorId) {
              this.conversations.set(
                conversationId,
                conversation.setRelations({ visitor })
              );
            }
          }
        }

        this.visitors.set(visitorId, visitor);
      });

      this._isHandlingChangesOnVisitors = false;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async handleInitialDataForAgents(
    results: DocumentChangeAction<Agent>[]
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>handleInitialDataForAgents`);
        console.log(`have ${results.length} results`);
      }

      const pairs: [string, AgentModel][] = results.map<[string, AgentModel]>(
        (agent) => {
          const agentModel: AgentModel = AgentModel.CreateFromQueryDocumentSnapshot(
            agent.payload.doc
          );
          const agentId: string = agentModel.id;

          if (agentModel.id === this._SSIUser.id) {
            this.agentModel = agentModel;
          }

          return [agentId, agentModel];
        }
      );

      this.agents = new Map<string, AgentModel>(pairs);

      this._teams = await this.getTeams();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private handleInitialDataForConversations(
    results: DocumentChangeAction<Conversation>[]
  ): boolean {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>handleInitialDataForConversations`);
        console.log(`have ${results.length} conversations`);
      }

      const allConversationIds: string[] = [];

      results.forEach((rawConversation: DocumentChangeAction<Conversation>) => {
        if (
          !(
            !!rawConversation &&
            !!rawConversation.payload &&
            !!rawConversation.payload.doc &&
            !!rawConversation.payload.doc.id &&
            !!rawConversation.payload.doc.data()
          )
        ) {
          console.error(`Unable to process data for conversation:`);
          console.dir(rawConversation);

          return;
        }

        const conversationDocument = rawConversation.payload.doc;
        const conversationId = conversationDocument.id;
        const conversationData = conversationDocument.data() as Conversation;
        const conversationApplicationId =
          !!conversationData &&
          !!conversationData.application &&
          !!conversationData.application.id
            ? conversationData.application.id
            : null;

        if (!(!!rawConversation && !!conversationApplicationId)) {
          // console.log(`returning - no app id`);

          return;
        }

        const conversationState: string =
          !!conversationData.agent &&
          !!conversationData.agent.id &&
          !!conversationData.agent.displayName
            ? stringAssigned
            : stringUnassigned;

        allConversationIds.push(conversationId);

        const hasConversationAgent =
          conversationData.agent && conversationData.agent.id;
        const visitor = this.visitors.get(conversationData.visitor.id);
        const relations = { visitor } as any;

        if (hasConversationAgent) {
          const conversationAgentId = conversationData.agent.id;

          relations.agent = this.agents.get(conversationAgentId);
        }

        let conversation: ConversationModel;
        conversation = ConversationModel.CreateFromQueryDocumentSnapshot(
          conversationDocument
        );
        conversation.setRelations(relations);

        if (!(!!this.applications && Array.isArray(this.applications))) {
          throw new Error(
            `Value for 'live chat service applications' not in expected format.`
          );
        }

        const conversationApplication = this.applications.find(
          (application) => application.id === conversation.applicationId
        );

        conversation.applicationName = !!conversationApplication
          ? conversationApplication.name
          : '';

        const isConversationResolved: boolean =
          !!conversation.resolution &&
          !!conversation.resolution.agent &&
          !!conversation.resolution.agent.id &&
          !!conversation.resolution.resolvedAt;

        const isConversationAssigned: boolean =
          conversationState === stringAssigned && !isConversationResolved;
        const isConversationOwned: boolean =
          isConversationAssigned &&
          conversationData.agent.id === this.agentModel.id;
        const isConversationOngoing: boolean =
          isConversationOwned &&
          !!conversationData.lastMessage &&
          !conversationData.lastMessage.actionedAt &&
          !!conversationData.lastMessage.agent &&
          !conversationData.lastMessage.agent.id;

        if (isConversationAssigned) {
          this._quantityOfAssignedConversations++;

          if (isConversationOwned) {
            this._quantityOfOwnedConversations++;

            if (isConversationOngoing) {
              this._quantityOfOngoingConversations++;
            }
          }
        } else if (isConversationResolved) {
          this._quantityOfResolvedConversations++;
        } else {
          this._quantityOfUnassignedConversations++;
        }

        this._conversationIds.add(conversationId);

        this.conversations.set(conversationId, conversation);
      });

      this._quantityOfTotalConversations =
        this.quantityOfAssignedConversations +
        this.quantityOfResolvedConversations +
        this.quantityOfUnassignedConversations;

      if (this.isConversationListReady && !this.agentModel.isOffline) {
        const shouldNotify: boolean =
          !!this.quantityOfUnassignedConversations ||
          !!this.quantityOfOwnedConversations;

        if (shouldNotify) {
          this.emitAudioNotification(0);

          if (!!this.pushNotifications.isAvailable) {
            const chatsTotal: number =
              this.quantityOfOwnedConversations +
              this.quantityOfUnassignedConversations;
            const title: string = `${chatsTotal} ${stringChats} ${capitalize(
              stringAvailable
            )}.`;
            const body: string =
              `${capitalize(stringYou)} ${stringHave} ` +
              `${this.quantityOfOwnedConversations} ` +
              `${capitalize(stringOngoing)} ` +
              `${stringChats} ${stringAnd} ` +
              `${this.quantityOfUnassignedConversations} ` +
              `${capitalize(stringAvailable)} ${stringChats}`;

            this.pushNotifications.generateNotification(title, { body });
          }
        }
      }

      this.refreshStalestConversation();

      this._isConversationListLoaded = true;
      this._isConversationListLoading = false;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private handleInitialDataForVisitors(
    rawVisitors: DocumentChangeAction<Visitor>[]
  ): boolean {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>handleInitialDataForVisitors`);
      }

      // @todo: shouldn't need to specify the generics return type again for map(),
      // a Typescript issue to be be fixed via upgrading to a later version possibly.
      const pairs: [string, VisitorModel][] = rawVisitors.map<
        [string, VisitorModel]
      >((visitor) => {
        const visitorModel: VisitorModel = VisitorModel.CreateFromQueryDocumentSnapshot(
          visitor.payload.doc
        );
        const visitorId: string = visitorModel.id;

        // refresh visitors for existing conversations

        if (!!this.conversations && !!this.conversations.size) {
          // @todo look into if worth moving this to be more pull than push
          // and to not use Array.from

          Array.from(this.conversations.keys()).filter(
            (conversationId: string) => {
              if (!this.conversations.get(conversationId)) {
                return;
              }

              const conversation = this.conversations.get(conversationId);
              conversation.visitor = visitorModel;

              this.conversations.set(conversationId, conversation);
            }
          );
        }

        return [visitorId, visitorModel];
      });

      this.visitors = new Map<string, VisitorModel>(pairs);

      if (isDebug) {
        console.log(`Finished initialising visitors data`);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async initialiseMessaging(): Promise<boolean> {
    try {
      this.messaging.requestToken.subscribe(
        async (token) => {
          try {
            if (isDebug) {
              console.log(`messaging permission granted!`);
              console.log(`token:`);
              console.log(token);
            }

            // monitor for token refresh

            await this.updateTokenForMessaging(token);

            // this.messaging.tokenChanges.subscribe(async (value) => {
            //   if (isDebug) {
            //     console.log(`new value:`);
            //     console.log(value);
            //   }

            //   const refreshedToken = await this.messaging.getToken
            //     .toPromise()
            //     .catch((error) => {
            //       throw new Error(error);
            //     });

            //   await this.updateTokenForMessaging(refreshedToken);
            // });

            this.messaging.messages.subscribe((message) => {
              if (isDebug) {
                console.log(`message:`);
                console.log(message);
              }
            });

            const taskRequest: AbstractTask = {
              details: {
                state: stringOffline,
                target: {
                  agent: {
                    id: this.agentModel.id
                  }
                  // company: {
                  //   id: this._company.id
                  // }
                }
              },
              type: taskTypes.testTask
            };

            if (isDebug) {
              console.log(`About to try and request task:`);
              console.log(taskRequest);
            }

            await this.requestTask(taskRequest);

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        },
        (error) => {
          console.log(`messaging permissions error:`);
          throw new Error(error);
        }
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private initialiseNetworkState(): boolean {
    try {
      if (isDebug) {
        console.log(`begin: liveChatService.initialiseNetworkState`);
      }

      // @todo support different networkState per-agent, per-application.

      const agentId = this.agentModel.id;
      const companyId = String(this._SSIUser.company_id);

      this._networkStateReference = this.realtimeDatabase.database.ref(
        `/${this.config.collections.companies}/${companyId}` +
          `/${this.config.collections.agents}/${agentId}/` +
          `${this.config.symbols.networkStateField}`
      );

      this._lastKnownAgentNetworkState = this.agentModel.networkStateValue;
      this._lastKnownBrowserNetworkState = !!navigator.onLine
        ? stringOnline
        : stringOffline;

      this.realtimeDatabase.database
        .ref('.info/connected')
        .on(`value`, async (snapshot) => {
          // console.log(`!!! value change to: ${snapshot.val()} !!!`);

          const previouslySetValue = this._localStorageService.get(
            keysForStorage.networkState
          ) as string;

          let state = stringOffline;

          await this._networkStateReference.once(
            'value',
            async (stateSnapshot) => {
              state = stateSnapshot.val().value;
            }
          );

          // determine state based on if this is initial connection, if network is available, if anything is in the cache.
          // let state = !this._isInitialised
          //   ? this.isPushModeEnabled
          //     ? stringBusy
          //     : !!previouslySetValue
          //       ? previouslySetValue
          //       : !!navigator.onLine
          //         ? stringOnline
          //         : stringOffline
          //   : !snapshot.val()
          //     ? stringOffline
          //     : stringOnline;

          if (
            state === stringOffline &&
            this._isInitialised &&
            !this._shouldIgnoreNetworkBounce
          ) {
            const lengthOfDelay = 20000;
            const delayRequestedAt = new Date().getTime();

            setTimeout(async () => {
              try {
                if (isDebug) {
                  console.log(
                    `in timeout for liveChatService.initialiseNetworkState`
                  );
                }

                const latestAgent = await this.agentModel.reference.get();
                const latestAgentData = latestAgent.data() as Agent;
                const hasNetworkStateUpdatedSinceDelayRequested = isAfter(
                  timestamp_to_date(latestAgentData.networkState.updatedAt),
                  delayRequestedAt
                );
                const hasNetworkStateValueChanged =
                  latestAgentData.networkState.value !==
                  this.agentModel.networkStateValue;

                if (isDebug) {
                  console.log(
                    `hasNetworkStateUpdatedSinceDelayRequested: ${hasNetworkStateUpdatedSinceDelayRequested}`
                  );
                  console.log(
                    `hasNetworkStateValueChanged: ${hasNetworkStateValueChanged}`
                  );
                }

                if (
                  hasNetworkStateUpdatedSinceDelayRequested &&
                  hasNetworkStateValueChanged
                ) {
                  if (isDebug) {
                    console.log(
                      `returning - network state has since changed to no longer be 'offline'.`
                    );
                  }

                  return true;
                }
                return await this.setAgentNetworkState(state, false);
              } catch (error) {
                console.error(error);

                return false;
              }
            }, lengthOfDelay);

            return true;
          }
          state = await this.setAgentNetworkState(state, false);

          if (!state) {
            // console.log(`Unable to set network state for agent!`);

            return false;
          }

          if (!this._isInitialised) {
            // if (state !== stringOffline) {
            // await this._networkStateReference
            //   .onDisconnect()
            //   .set(get_database_network_state(stringOffline));
            // }

            // this._agentSubscription = (await this.getReferenceToThisAgent())
            //   .snapshotChanges()
            //   .subscribe(async (changes) => this.onChangesToThisAgent(changes));

            this._isInitialised = true;
            this._initialisedAt = this._browserNetworkStateCheckedAt = new Date();

            this.listenForNetworkStatusChanges();

            if (isDebug) {
              console.log(`Network state initialised`);
            }

            this.notifyAgentOfPushModeStatus(true);

            const agentOriginUpdates: AgentFragment = {
              lastOrigin: {
                window: {
                  location: {
                    hash: window.location.hash,
                    href: window.location.href,
                    origin: window.location.origin,
                    pathname: window.location.pathname,
                    search: window.location.search
                  }
                }
              }
            };

            await this.agentModel.reference.update(agentOriginUpdates);
          } else {
            if (state === stringOnline) {
              this.notifyAgentOfPushModeStatus();
            }
          }
        });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private initialiseResponseCache(): boolean {
    try {
      this._localStorageService.remove(
        ...this._localStorageService.keys().filter((key) => {
          try {
            if (
              !(!!key && !!key.match(keysForStorage.conversationResponseCache))
            ) {
              return false;
            }

            const cacheValue: any = this._localStorageService.get(key);

            if (!(!!cacheValue && !!cacheValue.updatedAt)) {
              return true;
            }

            const ageOfCache: Date = new Date(cacheValue.updatedAt);
            const cacheCutOff: Date = subHours(
              new Date(),
              responseCacheMaximumAgeInHours
            );

            if (isBefore(ageOfCache, cacheCutOff)) {
              return true;
            }

            return false;
          } catch (error) {
            console.error(error);

            return false;
          }
        })
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async initialiseVisitors(): Promise<any> {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>initialiseVisitors`);
      }

      this._isHandlingChangesOnVisitors = true;
      this.stopGarbageCollectingVisitors();

      const companyId = String(this._SSIUser.company_id);
      const visitorCollectionReferences: AngularFirestoreCollection[] = [];
      // Observable<DocumentChangeAction<Visitor>[]>[] = [];

      const daysAgo = startOfDay(
        subDays(Date.now(), this.periodOfDateRangeInDays)
      );

      this._applications.forEach((application: ApplicationModel) => {
        const path =
          `${this.config.collections.companies}/` +
          `${companyId}/${this.config.collections.applications}/` +
          `${application.id}/${this.config.collections.visitors}`;

        visitorCollectionReferences.push(
          this.database.collection(path, (ref) =>
            ref.where(
              `lastUpdatedConversation.lastMessage.createdAt`,
              `>`,
              daysAgo
            )
          )
        );
      });

      return new Promise((resolve, reject) => {
        try {
          const visitorsReference = combineLatest<any[]>(
            ...visitorCollectionReferences.map((collectionReference) =>
              collectionReference.snapshotChanges()
            )
          ).pipe(map((arr) => arr.reduce((acc, curr) => acc.concat(curr), [])));
          const initialVisitorsSubscription = visitorsReference
            .pipe(take(1))
            .subscribe(async (initialData) => {
              try {
                await this.handleInitialDataForVisitors(initialData);
                initialVisitorsSubscription.unsubscribe();

                this.startGarbageCollectingVisitors();

                combineLatest<any[]>(
                  ...visitorCollectionReferences.map((collectionReference) =>
                    collectionReference.stateChanges()
                  )
                )
                  .pipe(
                    map((arr) =>
                      arr.reduce((acc, curr) => acc.concat(curr), [])
                    )
                  )
                  .subscribe(async (changedData) =>
                    this.handleChangedDataForVisitors(changedData)
                  );

                return resolve(true);
              } catch (error) {
                throw error;
              }
            });
        } catch (error) {
          console.error(error);

          return reject(false);
        }
      });
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private notifyAgentOfPushModeStatus(isForced?: boolean): boolean {
    try {
      if (isDebug) {
        console.log(`begin: liveChatService.notifyAgentOfPushModeStatus`);
      }

      if (!this.agentModel) {
        if (isDebug) {
          console.log(`returning - agent model not ready yet?`);
        }

        return true;
      }

      if (!isForced && this.agentModel.networkStateValue !== stringOnline) {
        if (isDebug) {
          console.log(`returning - agent is offline.`);
        }

        return true;
      }

      // has push mode changed / is it newly enabled?

      const isEnabledInCache =
        this._localStorageService.get(keysForStorage.isPushModeEnabled) ||
        false;

      if (isDebug) {
        console.log(`isEnabledInCache: ${isEnabledInCache}`);
        console.log(
          `companyPushModeStatus: ${this._company.isPushModeEnabled}`
        );
      }

      if (this._company.isPushModeEnabled === isEnabledInCache) {
        if (isDebug) {
          console.log(`returning - push mode status is unchanged.`);
        }

        return true;
      }

      // update cache
      this._localStorageService.set(
        keysForStorage.isPushModeEnabled,
        this._company.isPushModeEnabled
      );

      if (!!this._company.isPushModeEnabled) {
        this.onEnablePushMode();
      } else {
        this.onDisablePushMode();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async onBrowserOffline(): Promise<boolean> {
    try {
      this._lastKnownBrowserNetworkState = stringOffline;
      this._browserOfflineAt = new Date();

      if (!this._agentLocalStateSetOffline) {
        const modalAttributes: ModalAttributes = {
          bodyContent:
            'We’ve set you to offline because it looks as if you’ve lost connection - it might be worth checking your WiFi.',
          headerIcon: `ssi ssi-push-mode`,
          headerText: `Oops, you’ve lost connection!`,
          primaryButton: {
            action: () => {},
            text: `Understood`
          }
        };
        this.setLocalStateToOffline = setTimeout(() => {
          this._localStorageService.set(
            keysForStorage.networkState,
            stringOffline
          );
        }, 19800);
        this.showBrowserDisconnectedModal = setTimeout(() => {
          this.modals.push(modalAttributes);
          this._agentLocalStateSetOffline = true;
        }, 20000);
      }

      setTimeout(
        async () => await this.checkIfBrowserOnline(),
        LiveChatService.BrowserOfflineNoticeMinimumPeriod
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async onBrowserOnline(): Promise<boolean> {
    clearTimeout(this.setLocalStateToOffline);
    clearTimeout(this.showBrowserDisconnectedModal);
    try {
      this._lastKnownBrowserNetworkState = stringOnline;
      this._browserOfflineAt = null;
      this._agentLocalStateSetOffline = false;

      // if (!(await this.setAgentNetworkState(stringOnline, true))) {
      //   throw new Error(
      //     `Unable to set net agent network state to ${stringOnline}!`
      //   );
      // }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async onChangesToThisAgent(
    changes: DocumentChangeAction<Agent>[]
  ): Promise<boolean> {
    try {
      // @todo: need to filter out changes caused within ...

      if (!changes || !Array.isArray(changes) || changes.length !== 1) {
        throw new Error(`Unexpected number of results for reference to agent!`);
      }

      const agentData = changes[0].payload.doc.data() as Agent;
      const isOfflineElsewhere = agentData.networkState.value === stringOffline;

      if (isDebug) {
        console.log(
          `last known network state: ${this._lastKnownAgentNetworkState}`
        );
      }

      if (!isOfflineElsewhere) {
        if (isDebug) {
          console.log(
            `updating last known network state to: ${agentData.networkState.value}`
          );
        }

        this._lastKnownAgentNetworkState = agentData.networkState.value;

        return true;
      }

      if (isDebug) {
        console.log(
          `Network State for agent changed to Offline in another tab.`
        );
      }

      await Promise.all([
        this._networkStateReference.set(
          get_database_network_state(this._lastKnownAgentNetworkState)
        ),
        this.agentModel.reference.update(
          get_firestore_network_state(this._lastKnownAgentNetworkState)
        )
      ]);

      if (isDebug) {
        console.log(
          `updating last known network state to: ${agentData.networkState.value}`
        );
      }

      this._lastKnownAgentNetworkState = agentData.networkState.value;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  // @todo separate out later.
  // private produceConversationFromQueryDocumentSnapshot(
  //   doc: firebase.firestore.QueryDocumentSnapshot
  // ): ConversationModel {
  //   try {
  //     const visitor = this.visitors.find(
  //       (visitorModel) => visitorModel.id === doc.data().visitor.id
  //     );

  //     const hasConversationAgent = doc.data().agent && doc.data().agent.id;
  //     const relations = { visitor } as any;

  //     if (hasConversationAgent) {
  //       const conversationAgentId = doc.data().agent.id;

  //       relations.agent = this.agents.find(
  //         (agent: AgentModel) => agent.id === conversationAgentId
  //       );
  //     }

  //     const conversation = ConversationModel.CreateFromQueryDocumentSnapshot(
  //       doc
  //     );
  //     conversation.setRelations(relations);

  //     return conversation;
  //   } catch (error) {
  //     console.error(error);

  //     return null;
  //   }
  // }

  private async promptToSetOtherAgentOffline(
    agent: AgentModel,
    currentNetworkState: string
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in promptToSetOtherAgentOffline`);
      }

      if (agent.id === this.agentModel.id) {
        if (isDebug) {
          console.log(
            `agent id is mine: don't need to prompt to set me offline`
          );
        }

        return false;
      }

      if (currentNetworkState === stringOffline) {
        if (isDebug) {
          console.log(`No point in prompting if agent already offline`);
        }

        return true;
      }

      const quantityOfChatsAssignedToUser = this.getConversationsFilteredByAgentId(
        agent.id
      ).length;

      if (!quantityOfChatsAssignedToUser) {
        if (isDebug) {
          console.log(`No chats assigned, no modal required.`);
        }

        await this.requestTask({
          details: {
            state: stringOffline,
            target: {
              agent: {
                id: agent.id
              },
              company: {
                id: this._company.id
              }
            }
          },
          type: taskTypes.updateNetworkState
        });

        return true;
      }

      const chatsLabel: string =
        quantityOfChatsAssignedToUser === 1 ? stringChat : stringChats;
      const modalAttributes: ModalAttributes = {
        bodyContent:
          `Setting this agent's status to Offline will reassign the ${chatsLabel} ` +
          `which they currently are dealing with. Please confirm.`,
        headerIcon: `ssi ssi-eye`,
        headerText: `${agent.displayName} is busy with a customer`,
        primaryButton: {
          action: async () => {
            try {
              await this.requestTask({
                details: {
                  state: stringOffline,
                  target: {
                    agent: {
                      id: agent.id
                    },
                    company: {
                      id: this._company.id
                    }
                  }
                },
                type: taskTypes.updateNetworkState
              });

              return false;
            } catch (error) {
              console.error(error);

              return false;
            }
          },
          text: `Yes, set them as ${capitalize(stringOffline)}`
        },
        secondaryButton: {
          action: async () => {
            return;
          },
          text: `No, cancel`
        }
      };

      this.modals.push(modalAttributes);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private refreshStalestConversation(): boolean {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>refreshStalestConversation`);
      }

      const stalestConversation = this.getSortedConversations(
        Array.from(this.conversations.values())
      ).find((conversation) => {
        return (
          !!conversation && !conversation.agentID && !conversation.isResolved
        );
      });

      this._stalestConversation = !!stalestConversation
        ? stalestConversation
        : null;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async setActiveConversation(
    conversationId: string
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>setActiveConversation`);
      }

      this._isActiveConversationLoaded = false;
      this._isActiveConversationLoading = true;

      this.unsubscribeFromActiveConversation();

      this._activeConversationId = conversationId;
      this._activeConversation = this.activeViewOfConversations.find(
        (conversation) => conversation.id === conversationId
      );

      this._activeApplication = this.applications.find(
        (application) =>
          application.id === this._activeConversation.applicationId
      );

      const isConversationReady: boolean = await this.subscribeToActiveConversation();

      if (!isConversationReady) {
        // @todo: perhaps test for a bad network connection and retry ... ?

        // console.log(`Conversation not ready!`);

        return false;
      }

      await this.updateEventsOnActiveConversationForActiveAgent({
        lastViewedAt: firebase.firestore.FieldValue.serverTimestamp()
      });

      // message notes

      this._isActiveConversationLoaded = true;
      this._isActiveConversationLoading = false;

      return true;
    } catch (error) {
      return false;
    }
  }

  private startGarbageCollectingAgents(): boolean {
    try {
      this._intervalForGarbageCollectingAgents = setInterval(() => {
        const startOfQueryPeriod = startOfDay(
          subDays(Date.now(), this.periodOfDateRangeInDays)
        );

        for (const agent of this.agents.values()) {
          if (isAfter(startOfQueryPeriod, agent.networkStateUpdatedAt)) {
            this.agents.delete(agent.id);
          }
        }
      }, this.config.defaults.garbageCollectionCycleTimeInMilliseconds);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private startGarbageCollectingConversations(): boolean {
    try {
      this._intervalForGarbageCollectingConversations = setInterval(() => {
        const startOfQueryPeriod = startOfDay(
          subDays(Date.now(), this.periodOfDateRangeInDays)
        );

        for (const [
          conversationId,
          conversation
        ] of this.conversations.entries()) {
          if (isAfter(startOfQueryPeriod, conversation.lastMessage.createdAt)) {
            // remove if "too old" for the current query.
          }

          // @todo want to double-check this though I think it's okay.
          // if (!this._conversationIds.has(conversationId)) {
          //   this.conversations.delete(conversationId);
          // }
        }
      }, this.config.defaults.garbageCollectionCycleTimeInMilliseconds);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private startGarbageCollectingVisitors(): boolean {
    try {
      this._intervalForGarbageCollectingVisitors = setInterval(() => {
        const startOfQueryPeriod = startOfDay(
          subDays(Date.now(), this.periodOfDateRangeInDays)
        );

        for (const visitor of this.visitors.values()) {
          if (isAfter(startOfQueryPeriod, visitor.networkStateUpdatedAt)) {
            this.visitors.delete(visitor.id);
          }
        }
      }, this.config.defaults.garbageCollectionCycleTimeInMilliseconds);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private stopGarbageCollectingAgents(): boolean {
    try {
      if (this._intervalForGarbageCollectingAgents) {
        clearInterval(this._intervalForGarbageCollectingAgents);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private stopGarbageCollectingConversations(): boolean {
    try {
      if (this._intervalForGarbageCollectingConversations) {
        clearInterval(this._intervalForGarbageCollectingConversations);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private stopGarbageCollectingVisitors(): boolean {
    try {
      if (this._intervalForGarbageCollectingVisitors) {
        clearInterval(this._intervalForGarbageCollectingVisitors);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async subscribeToActiveConversation(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>subscribeToActiveConversastion`);
      }

      const results: boolean[] = await Promise.all([
        this.updateActiveEvents(),
        this.updateActiveMessages()
      ]);

      results.push(this.updateActiveVisitor());

      if (results.find((result) => !result)) {
        throw new Error(`Error in subscribing to active conversation!`);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  // ----------------
  // optional transaction argument
  // GET

  //
  // I believe that 'it should all just flow' now, and this isn't needed...
  //
  // private async refreshActiveConversation(
  //   transaction?: firebase.firestore.Transaction
  // ): Promise<boolean> {
  //   try {
  //     const conversation = !transaction
  //       ? await this.activeConversation.reference.get()
  //       : await transaction.get(this.activeConversation.reference);

  //     this._activeConversation = this.produceConversationFromQueryDocumentSnapshot(
  //       conversation
  //     );

  //     return true;
  //   } catch (error) {
  //     console.error(error);

  //     return false;
  //   }
  // }

  private async updateActiveEvents(): Promise<any> {
    try {
      if (isDebug) {
        console.log(`in liveChatService~>updateActiveEvents`);
      }

      const eventsPath =
        `companies/${this._SSIUser.company_id}/` +
        `applications/${this.activeConversation.applicationId}/` +
        `events`;

      this._activeConversationEventsCollection = this.database.collection(
        eventsPath,
        (ref) => ref.where(`conversation.id`, `==`, this.activeConversationId)
      );

      return new Promise((resolve, reject) => {
        this._activeConversationEventsSubscription = this._activeConversationEventsCollection
          .snapshotChanges()
          // .pipe(bufferTime(1000))
          .subscribe(async (results) => {
            try {
              if (isDebug) {
                console.log(
                  `in liveChatService~>updateActiveEvents~subscription`
                );
              }

              await this.onChangeToActiveConversationsEventsSubscription(
                results
              );

              return resolve(true);
            } catch (error) {
              console.error(error);

              return reject(false);
            }
          });
      });
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async updateActiveMessages(): Promise<any> {
    try {
      const applicationId = this._activeConversation.applicationId;
      const companyId = String(this._SSIUser.company_id);
      const path =
        `${this.config.collections.companies}/` +
        `${companyId}/${this.config.collections.applications}/` +
        `${applicationId}/${this.config.collections.conversations}/` +
        `${this._activeConversation.id}/${this.config.collections.messages}`;
      const query: QueryFn = (ref: firebase.firestore.CollectionReference) =>
        ref.orderBy(this.config.symbols.creationDatetimeField, 'asc');

      this._activeMessagesReference = this.database.collection<MessagePreview>(
        path,
        query
      );

      return new Promise((resolve, reject) => {
        this._activeMessagesReferenceSubscription = this._activeMessagesReference
          .snapshotChanges()
          // .pipe(bufferTime(1000))
          // .pipe(map((arr) => arr.reduce((acc, curr) => acc.concat(curr), [])))
          .subscribe(async (messages) => {
            try {
              const noteModel = noteModelFactory() as NoteModel;
              const newMessages = messages.map((rawMessage) => {
                const message = MessageModel.CreateFromQueryDocumentSnapshot(
                  rawMessage.payload.doc
                );

                if (message.visitorID) {
                  message.visitor = this.activeVisitor;
                }

                if (message.agentID) {
                  message.agent = this.agents.get(message.agentID);
                }

                return message;
              });

              const subject = liveChatSymbols.subjectForMessageNote;

              // @todo: this is unused, do something with it one way or another:
              const messagesWithNotes = newMessages.map(
                async (message: MessageModel, index) => {
                  const subject_id =
                    `` +
                    `/${stringCompanies}/` +
                    this.activeApplication.companyId +
                    `/${stringApplications}/` +
                    this.activeApplication.id +
                    `/${stringConversations}/` +
                    this.activeConversation.id +
                    `/${stringMessages}/` +
                    message.id;

                  const notes = await noteModel.findAll(
                    { subject, subject_id },
                    { showLoading: true }
                  );

                  message.notes = notes;

                  return message;
                }
              );
              const previousMessages = this.activeMessages;

              if (
                Array.isArray(newMessages) &&
                !!newMessages.length &&
                newMessages.length === previousMessages.length
              ) {
                const differences = newMessages
                  .map((newMessage: MessageModel, index: number) => {
                    const diff = newMessage.diff(previousMessages[index]);

                    // we aren't interested in these changes
                    // @todo add them to the local message models at time of actioning, if not already...

                    delete diff.actionedAt;
                    delete diff.actionedBy;

                    // @todo check that the lastTypedAt isn't coming through on the agent now.
                    if (diff.agent) {
                      delete diff.agent.lastTypedAt;
                    }

                    return diff;
                  })
                  .reduce((result, diff) => Object.assign(result, diff));

                if (isEmpty(differences)) {
                  return;
                }
              }

              this.activeMessages = newMessages;

              return resolve(true);
            } catch (error) {
              console.error(error);

              return reject(false);
            }
          });
      });
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private updateActiveVisitor(): boolean {
    try {
      this._activeVisitor = this.getVisitorByID(
        this._activeConversation.visitorID
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async updateTokenForMessaging(value: string): Promise<boolean> {
    try {
      const pathToAgentMessagingToken: string =
        `` +
        `/${this.config.collections.companies}/${this._company.id}` +
        `/${this.config.collections.agents}/${this.agentModel.id}/` +
        `${this.config.symbols.messagingTokenField}`;
      const tokenUpdates = {
        updatedAt: firebase.database.ServerValue.TIMESTAMP,
        value
      };

      // update token

      await this.realtimeDatabase
        .object(pathToAgentMessagingToken)
        .update(tokenUpdates);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async blockList() {
    const blockPath =
      `companies/${this._SSIUser.company_id}/` +
      `applications/${this.activeConversation.applicationId}/statistics/` +
      `blocked`;

    this._blockedIpReference = this.database.doc(blockPath);
    return this._blockedIpReference.snapshotChanges();
  }

  public async blockVisitorByIp(ip) {
    const blockPath =
      `companies/${this._SSIUser.company_id}/` +
      `applications/${this.activeConversation.applicationId}/statistics/` +
      `blocked`;
    const blockedUpdates = {
      ipAddresses: []
    };

    this._blockedIpReference = this.database.doc(blockPath);

    const blockDocument = await this._blockedIpReference.get();
    await blockDocument.subscribe((result) => {
      const blockedIps = result.data();
      if (blockedIps) {
        blockedUpdates.ipAddresses = [
          ...new Set([...blockedIps.ipAddresses, ...[ip]])
        ];
        this._blockedIpReference.update(blockedUpdates);
      } else {
        blockedUpdates.ipAddresses.push(ip);
        this._blockedIpReference.set(blockedUpdates);
      }
    });
  }

  public async unblockVisitorByIp(ip) {
    const blockPath =
      `companies/${this._SSIUser.company_id}/` +
      `applications/${this.activeConversation.applicationId}/statistics/` +
      `blocked`;
    const blockedUpdates = {
      ipAddresses: []
    };

    this._blockedIpReference = this.database.doc(blockPath);

    const blockDocument = await this._blockedIpReference.get();
    await blockDocument.subscribe((result) => {
      const blockedIps = result.data();

      blockedUpdates.ipAddresses = blockedIps.ipAddresses.filter(
        (blockedIp) => blockedIp !== ip
      );
      this._blockedIpReference.update(blockedUpdates);
    });
  }
}
