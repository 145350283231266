import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageTeamsComponent } from './manage-teams.component';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterModule } from '../../../../../common/pipes/filter/filter.module';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FilterModule,
    SortByModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageTeams',
          url: '/manage/teams',
          component: ManageTeamsComponent,
          data: {
            permissions: {
              company: 'administer_group_permissions'
            }
          },
          resolve: ManageTeamsComponent.resolve
        }
      ]
    })
  ],
  declarations: [ManageTeamsComponent],
  providers: [],
  exports: [ManageTeamsComponent]
})
export class ManageTeamsModule {}
