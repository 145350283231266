import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectChipsComponent } from './multi-select-chips.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    FormsModule
  ],
  declarations: [MultiSelectChipsComponent],
  exports: [MultiSelectChipsComponent]
})
export class MultiSelectChipsModule {}
