import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import { DateRange } from './report/team-report.component';
import { TranslateService } from '@ngx-translate/core';
import { TeamsService } from '../../../../common/services/api/teams';
import { ColleaguesService } from '../../../../common/services/api';

@Injectable({ providedIn: 'root' })
export class TeamService {
  constructor(
    protected api: API,
    private translate: TranslateService,
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService
  ) {}

  async loadReport(dateRanges: DateRange) {
    const report: any = await this.api.get('user/usage', {
      params: {
        start: dateRanges.start,
        end: dateRanges.end
      }
    });
    const teams = await this.colleaguesService.getAllActive().then(() => {
      return this.teamsService.getAllActive();
    });

    const fileExportData = [
      {
        rows: [],
        header: [
          this.translate.instant('USER'),
          this.translate.instant('LAST_ACTIVE'),
          this.translate.instant('TOTAL_MESSAGES_PUBLISHED'),
          this.translate.instant('TOTAL_INBOX_MESSAGES_ACTIONED')
        ],
        name: 'Users'
      },
      {
        rows: [],
        header: [
          this.translate.instant('TEAM'),
          this.translate.instant('TOTAL_MESSAGES_PUBLISHED'),
          this.translate.instant('TOTAL_INBOX_MESSAGES_ACTIONED')
        ],
        name: 'Teams'
      }
    ];

    // const totals = report.data.reduce(
    //   (totalStats, userStats) => {
    //     totalStats.outbox_posts += userStats.total_outbox_posts || 0;
    //     totalStats.actioned_activities +=
    //       userStats.total_actioned_activities || 0;
    //     return totalStats;
    //   },
    //   { session_duration: 0, outbox_posts: 0, actioned_activities: 0 }
    // );

    report.data.userStats = report.data
      .filter(
        (userStats) =>
          this.colleaguesService.store.find(userStats.user_id).is_active
      )
      .map((userStats) => {
        userStats.user = this.colleaguesService.store.find(userStats.user_id);
        userStats.total_outbox_posts = userStats.total_outbox_posts || 0;
        userStats.total_actioned_activities =
          userStats.total_actioned_activities || 0;

        if (userStats.user.is_active) {
          fileExportData[0].rows.push([
            userStats.user.fullName,
            userStats.user.last_login,
            userStats.total_outbox_posts,
            userStats.total_actioned_activities
          ]);
        }

        return userStats;
      });

    report.data.teamStats = teams.map((team) => {
      const teamStats = this.colleaguesService.store
        .filterByTeam(team.id)
        .map((c) => {
          return report.data.userStats.filter((stats) => {
            return stats.user === c;
          })[0];
        })
        .filter((stats) => {
          return !!stats;
        })
        .reduce(
          (statsTotal, currentUserStats) => {
            statsTotal.total_outbox_posts +=
              currentUserStats.total_outbox_posts;
            statsTotal.total_actioned_activities +=
              currentUserStats.total_actioned_activities;
            return statsTotal;
          },
          {
            team,
            total_outbox_posts: 0,
            total_actioned_activities: 0
          }
        );

      fileExportData[1].rows.push([
        teamStats.team.name,
        teamStats.total_outbox_posts,
        teamStats.total_actioned_activities
      ]);

      return teamStats;
    });

    return Object.assign(report.data, { fileExportData });
  }
}
