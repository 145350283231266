import { Filter, Filters } from '../../../components/filters';

export class Dashboard {
  id: string;
  name: string;
  widgets: DashboardWidget[];

  constructor(props: any) {
    this.id = props.id;
    this.name = props.name;
    this.widgets = props.widgets;
  }
}

export interface DashboardWidget {
  type: string;
  title: string;
  display_properties: {
    x: number;
    y: number;
    w: number;
    h: number;
  };

  // addl FE added props
  config?: { [key: string]: any };
  // filters?: Filters;
  activeFilters?: Partial<Filter>[]; // few props only needed for FE to recreate filters state
  typeConst: any; // WidgetType constant
  apiSearchParams?: { [key: string]: any };
  featureFlag?: string;
}
