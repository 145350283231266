// @todo: when there is free time (hee hee!), find a way to abstract out this Angular-specific dependency.
import { Note } from '@ui-resources-angular';

import strings from '../../constants/strings';
import {
  get_relative_age_of_date,
  timestamp_to_date
} from '../../helpers/datetimes';
import { capitalize } from '../../helpers/strings';
import { MessagePreview as MessagePreviewInterface } from '../../interfaces/live-chat/message';
import { MessageAttachment } from '../../interfaces/live-chat/message-attachment';
import Model from '../model';
import { Agent } from './agent';
import { Robot } from './robot';
import { User } from './user';
import { Visitor } from './visitor';
import { Person } from './person';

export const MESSAGE_TYPE_ERROR = `A Message requires at least 1 relation of type: agent, robot, visitor`;

export class Message extends Model implements MessagePreviewInterface {
  public agent: Agent | null;
  public notes: Note[] = [];
  public robot: Robot;
  public visitor: Visitor;

  public readonly previewLength = 120;

  protected _data: MessagePreviewInterface;

  private _dateActionedAt: Date;
  private _dateCreatedAt: Date;

  static CreateFromMessagePreview(data: MessagePreviewInterface): Message {
    const id = data.id;
    // delete data.id;

    return new this(id, data);
  }

  static CreateWithAgent(
    id: string,
    data: MessagePreviewInterface,
    agent: Agent
  ): Message {
    return new this(id, data, undefined, { agent });
  }

  static CreateWithVisitor(
    id: string,
    data: MessagePreviewInterface,
    visitor: Visitor
  ): Message {
    return new this(id, data, undefined, { visitor });
  }

  public get actionedAt(): null | Date {
    return this._dateActionedAt;
  }

  public set actionedAt(value: null | Date) {
    if (value instanceof Date) {
      // @todo not a use case for this currently.
      return;
    }

    this._data.actionedAt = value;
  }

  public get actionedBy(): string {
    return this._data.actionedBy;
  }

  public set actionedBy(value: string) {
    this._data.actionedBy = value;
  }

  public get agentID(): string {
    return this._data.agent ? this._data.agent.id : null;
  }

  public get attachment(): MessageAttachment {
    return this._data.attachment;
  }

  public get author(): Person | Robot {
    if (this.hasAgent) {
      return this.agent;
    }

    if (this.hasRobot) {
      return this.robot;
    }

    if (this.hasVisitor) {
      return this.visitor;
    }

    throw new Error(MESSAGE_TYPE_ERROR);
  }

  public get authorName(): string {
    return !!this.author && !!this.author.displayName
      ? this.author.displayName
      : capitalize(this.authorType);
  }

  public get authorType(): string {
    if (this.hasAgent) {
      return strings.agent;
    }

    if (this.hasRobot) {
      return strings.robot;
    }

    if (this.hasVisitor) {
      return strings.visitor;
    }

    throw new Error(MESSAGE_TYPE_ERROR);
  }

  public get createdAt(): Date {
    return this._dateCreatedAt;
  }

  public get dateCreatedAt(): Date {
    return this._dateCreatedAt;
  }

  public get fromAgent(): boolean {
    return this.hasAgent;
  }

  public get fromRobot(): boolean {
    return this.hasRobot;
  }

  public get fromVisitor(): boolean {
    return this.hasVisitor;
  }

  public get hasAgent(): boolean {
    return !!this.agent && !!this.agent.id;
  }

  public get hasAttachment(): boolean {
    return !!this._data.attachment;
  }

  public get hasImageAsAttachment(): boolean {
    return (
      this.hasAttachment &&
      this.attachment.mimetype.startsWith(`${strings.image}/`)
    );
  }

  public get hasRobot(): boolean {
    return !!this._data.robot;
  }

  public get hasTags(): boolean {
    return !!this.tags && Array.isArray(this.tags) && !!this.tags.length;
  }

  public get hasTextAsAttachment(): boolean {
    return (
      this.hasAttachment &&
      this.attachment.mimetype.startsWith(`${strings.text}/`)
    );
  }

  public get hasVisitor(): boolean {
    return !!this.visitor && !!this.visitor.id;
  }

  public get preview(): string {
    // @todo use translate service here.

    return this.hasAttachment
      ? `${capitalize(strings.file)} ${strings.attachment} ${strings.received}`
      : this.text.slice(0, this.previewLength);
  }

  public get quantityOfTags(): number {
    if (!this.hasTags) {
      return 0;
    }

    return this.tags.length;
  }

  public get relativeAge(): string {
    return get_relative_age_of_date(this.createdAt);
  }

  public get tags(): string[] {
    if (!this._data.tags) {
      return [];
    }

    return this._data.tags;
  }

  public get text(): string {
    // protect against bad data by helping keep a message 'valid'.

    if (this._data.text) {
      return this._data.text;
    }

    if (!this._data.attachment) {
      return '';
    }
  }

  public get visitorID(): string {
    return this._data.visitor ? this._data.visitor.id : null;
  }

  protected initialise() {
    if (!!this._data.actionedAt) {
      this._dateActionedAt = timestamp_to_date(this._data.actionedAt);
    }

    this._dateCreatedAt = timestamp_to_date(this._data.createdAt);

    if (!!this._data.agent && !!this._data.agent.id) {
      this.agent = new Agent(this._data.agent.id, this._data.agent);
    }

    if (!!this._data.robot) {
      this.robot = new Robot();
    }

    if (!!this._data.visitor && !!this._data.visitor.id) {
      this.visitor = new Visitor(this._data.visitor.id, this._data.visitor);
    }

    return this;
  }
}
