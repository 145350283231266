import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ActivityLinkPreviewComponent } from './activity-link-preview.component';

import './activity-link-preview.scss';

@NgModule({
  declarations: [ActivityLinkPreviewComponent],

  exports: [ActivityLinkPreviewComponent],

  imports: [CommonModule, TranslateModule]
})
export class ActivityLinkPreviewModule {}
