import './dashboard.component.scss';

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DashboardService } from '../../../common/services/api/dashboard';
import { ProfileModel } from '@ui-resources-angular';
export function isOnValidDashboardResolveFn(state: StateService) {
  const alreadyOnValidDashboard = state.params && state.params.id;
  console.log('state.params:', state.params);

  return alreadyOnValidDashboard;
}

export const isOnValidDashboard = {
  token: 'isOnValidDashboard',
  resolveFn: isOnValidDashboardResolveFn,
  deps: [StateService]
};

@Component({
  selector: 'ssi-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit, OnDestroy {
  static resolve = [isOnValidDashboard];

  @Input() isOnValidDashboard: boolean;

  protected destroyed$ = new Subject<void>();

  constructor(
    protected state: StateService,
    protected dashboardService: DashboardService,
    protected profileModel: ProfileModel
  ) {}

  ngOnInit() {
    this.dashboardService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dashboards) => {
        const alreadyOnValidDashboard =
          this.state.params &&
          this.state.params.id &&
          dashboards.some((d) => d.id === this.state.params.id);
        console.log('alreadyOnValidDashboard:', alreadyOnValidDashboard);
        if (alreadyOnValidDashboard) {
          return;
        } else {
          if (dashboards.length) {
            this.state.go('auth.dashboard.view', {
              id: dashboards[dashboards.length - 1].id
            });
          } else {
            this.state.go('auth.dashboard.create');
          }
        }
      });

    this.handleOpenNoteFromEmailRedirect();
  }

  handleOpenNoteFromEmailRedirect() {
    if (this.state.params.accountId && this.state.params.profileId) {
      this.profileModel['viewProfile']({
        accountId: this.state.params.accountId,
        profileId: this.state.params.profileId,
        noteId: this.state.params.noteId
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
