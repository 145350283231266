<form
  [ngClass]="{ hidden: isConversationResolved }"
>
  <div
    [(formControlValue)]="messageTextValue"
    ssiEmojiFormControlContainer
  >
    <textarea
      (blur)="onMessageBlur($event)"
      (change)="onValueChange()"
      (focus)="onMessageFocus($event)"
      (keydown)="onKeyDown($event)"
      (keyup)="onKeyUp($event)"
      [(ngModel)]="messageTextValue"
      [ngClass]="{
        disabled: isInterfaceDisabled
      }"
      autofocus="true"
      name="text"
      placeholder="{{ 'TYPE_SLASH_TO_ACCESS_TIMESAVING_SNIPPETS' | translate }}"
      ssiActivitySnippets
      ssiEmojiFormControl
    ></textarea>

    <div
      *ngIf="!! isPredictive"
      class="prediction-container"
      tabindex="-1"
    >
      <span
        #predictionElement
        [innerText]="prediction"
        class="prediction"
      ></span>
    </div>

    <ul class="additional">
      <li>
        <ul class="options">
          <li
            [ngClass]="{
              'disabled': !! isInterfaceDisabled
            }"
          >
            <ssi-emoji-form-control-trigger popoverPlacement="top">
              <button
                [disabled]="!! isInterfaceDisabled"
                type="button"
              >
                <i class="ssi ssi-smile-wink"></i>
              </button>
            </ssi-emoji-form-control-trigger>
          </li>

          <li
            [ngClass]="{
              'disabled': !! isInterfaceDisabled
            }"
            class="upload-attachment"
          >
            <label
              [ngClass]="{
                'disabled': !! isInterfaceDisabled
              }"
              for="upload"
            >
              <i class="ssi ssi-picture-frame"></i>

              <input
                #fileInput
                (change)="replyWithAttachment($event)"
                [disabled]="!! isInterfaceDisabled"
                id="upload"
                type="file"
              >
            </label>
          </li>

          <!--
          <li
            class="info"
          >
            <button
              (click)="openKeyboardShortcutList()"
              type="button"
            >
              <i class="ssi ssi-keyboard-shortcuts"></i>
            </button>
          </li>
          -->
        </ul>
      </li>
    </ul>
  </div>

  <ul class="buttons">
    <li>
      <button
        (click)="reply()"
        [disabled]="!! isInterfaceDisabled"
        class="reply"
        data-pendo="liveChat_replyToLiveChat"
        type="button"
      >
        <span
          *ngIf="! isReplying"
          translate="REPLY"
        ></span>

        <i
          *ngIf="!! isReplying"
          class="ssi ssi-loading ssi-spin"
        ></i>

        <span
          *ngIf="!! isReplying"
          translate="PLEASE_WAIT_ELLIPSIS"
        ></span>
      </button>
    </li>

    <li>
      <button
        (click)="replyAndResolve()"
        [disabled]="!! isInterfaceDisabled"
        class="resolve"
        data-pendo="liveChat_replyToAndResolveLiveChat"
        type="button"
      >
        <span
          *ngIf="! isResolving"
          translate="REPLY_AMP_RESOLVE"
        ></span>

        <i
          *ngIf="!! isResolving"
          class="ssi ssi-loading ssi-spin"
        ></i>

        <span
          *ngIf="!! isResolving"
          translate="PLEASE_WAIT_ELLIPSIS"
        ></span>
      </button>
    </li>

    <!--
    <li>
      <button
        (click)="addNote()"
        class="note"
        type="button"
      >
        <span
          translate="ADD_A_NOTE"
        ></span>

        <i
          class="ssi ssi-feather-note"
        ></i>
      </button>
    </li>
    -->
  </ul>
</form>
