import './stream-card.component.scss';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  Stream,
  sources,
  sourceTypeName
} from '../../../monitoring-streams/monitoring-streams.service';
import { MonitoringStream } from '../../../../../../common/services/api';

@Component({
  selector: 'ssi-stream-card',
  templateUrl: './stream-card.component.html',
  styles: []
})
export class StreamCardComponent implements AfterContentInit {
  @Input() stream: MonitoringStream;
  @Input() showEdit = true;
  @Input() showViewReport = true;
  @Output() onEditButtonClick = new EventEmitter();
  sources = sources;
  maximumSources = 5;

  constructor() {}

  ngAfterContentInit(): void {
    // add Facebook to `sources` array to maintain programatical
    // approach in ngFor to show source icons/labels in a tile

    if (
      this.stream &&
      this.stream.listening_group_ids &&
      Array.isArray(this.stream.listening_group_ids) &&
      this.stream.listening_group_ids.length > 0
    ) {
      this.stream.sources.push(sourceTypeName.Facebook);
    }
  }

  getRemainingSourcesList(
    streamSources: sourceTypeName[],
    maxSources: number
  ): string {
    const remainingSources = streamSources.slice(maxSources);
    return remainingSources.map((source) => sources[source].label).join(', ');
  }
}
