import {
  Directive,
  Output,
  EventEmitter,
  HostListener,
  AfterViewInit,
  ElementRef,
  Input
} from '@angular/core';

@Directive({
  selector: '[ssiPopoverOffClick]'
})
export class PopoverOffClickDirective implements AfterViewInit {
  @Input() popoverIsOpen: boolean;

  // tslint:disable-next-line
  @Output('ssiPopoverOffClick') offClick = new EventEmitter();

  private popoverContainer: HTMLElement;

  private viewInitialised = false;

  constructor(private elm: ElementRef) {
    setTimeout(() => (this.viewInitialised = true));
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (
      this.viewInitialised &&
      this.popoverIsOpen &&
      !this.popoverContainer.contains(event.target)
    ) {
      this.offClick.emit();
    }
  }

  ngAfterViewInit(): void {
    let popoverContainer: HTMLElement = this.elm.nativeElement;
    while (
      popoverContainer &&
      !popoverContainer.classList.contains('popover') &&
      popoverContainer !== document.body
    ) {
      popoverContainer = popoverContainer.parentNode as HTMLElement;
    }
    this.popoverContainer = popoverContainer;
  }
}
