import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnlargeImageModalComponent } from './enlarge-image-modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EnlargeImageModalComponent],
  exports: [EnlargeImageModalComponent]
})
export class EnlargeImageModalModule {}
