<div class="advertising-analytics-report-container">
  <ssi-report-header
    report="Advertising Report"
    [name]=""
    [dateRangesTemplate]="dateRangesRef"
    (dateSelected)="reportDateChanged($event)"
  ></ssi-report-header>
  <ssi-report-sidebar
    icon="ssi-marketing-analytics"
    color="#D41D68"
    [navList]="navItems"
    [actionList]="reportActions"
    [active]="activeNavItemTag"
    (navClicked)="scrollTo($event)"
  ></ssi-report-sidebar>

  <div
    class="report-body-loading"
    *ngIf="loading"
  >
    <div class="loading-container">
      <!-- <i class="ssi ssi-confetti"></i> -->
      <img src="https://www.orlo.app/app/web/production/f44ceeab6551db9be89255d952d86c0d.gif">
      <h3>We're building your report!</h3>
      <p>This may take a few moments
      </p>
    </div>
  </div>

  <div
    class="report-body"
    *ngIf="!loading"
  >

    <div class="section-delimiter"></div>

    <section
      id="engagement-summary"
      class="section-container section-container-engagement-summary"
    >
      <ssi-engagement-summary [report]="report"></ssi-engagement-summary>
    </section>

    <div class="section-delimiter"></div>
    <section
      id="ad-account-breakdown"
      class="section-container section-container-account-breakdown"
    >
      <ssi-ad-account-breakdown [report]="report"></ssi-ad-account-breakdown>
    </section>


    <div class="section-delimiter"></div>
    <section
      id="spend-summary"
      class="section-container section-container-spend-summary"
    >
      <h2 class="section-title">Spend Summary</h2>
      <div class="facts-container">
        <ssi-fact-overview
          label="Total Spend"
          width="100%"
          [number]="report?.totalsStats.allTotals.current.total_spend.amount"
          [previousValue]="report?.totalsStats.allTotals.previous?.total_spend.amount"
          [symbol]="currencySymbols[report?.totalsStats.allTotals.current.total_spend.currency]"
          [symbolBefore]="true"
          [maxFractionDigitsHover]="6"
          iconClass="ssi-total-spend"
          iconColor="#F88C68"
          circleColor="#FFF1ED"
          ssiTooltip="This tells you the total amount of money you’ve spent on ads in your account during the reporting period."
          tooltipWindowClass="tooltip-chunky-big"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="Conversions"
          width="100%"
          [number]="report?.totalsStats.allTotals.current.conversions"
          [previousValue]="report?.totalsStats.allTotals.previous?.conversions"
          iconClass="ssi-conversions"
          iconColor="#D41D68"
          circleColor="#D41D681A"
          ssiTooltip="This tells you the total number of times the desired action has been taken on ads in your account during the reporting period."
          tooltipWindowClass="tooltip-chunky-big"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="Cost per Conversion"
          width="100%"
          [number]="report?.totalsStats.allTotals.current.cost_per_conversion.amount"
          [previousValue]="report?.totalsStats.allTotals.previous?.cost_per_conversion.amount"
          [symbol]="currencySymbols[report?.totalsStats.allTotals.current.total_spend.currency]"
          [symbolBefore]="true"
          [maxFractionDigitsHover]="6"
          iconClass="ssi-conversions"
          iconColor="#F0B427"
          circleColor="#F0B4271A"
          ssiTooltip="This tells you the average cost per conversion of ads in your account during the reporting period."
          tooltipWindowClass="tooltip-chunky-big"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="CPC"
          width="100%"
          [number]="report?.totalsStats.allTotals.current.cost_per_click.amount"
          [previousValue]="report?.totalsStats.allTotals.previous?.cost_per_click.amount"
          [symbol]="currencySymbols[report?.totalsStats.allTotals.current.total_spend.currency]"
          [symbolBefore]="true"
          [maxFractionDigitsHover]="6"
          iconClass="ssi-cpc"
          iconColor="#FF87B8"
          circleColor="#FF87B81A"
          ssiTooltip="This tells you the average cost per click on ads in your account during the reporting period."
          tooltipWindowClass="tooltip-chunky-big"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="CPM"
          width="100%"
          [number]="report?.totalsStats.allTotals.current.cost_per_mille.amount"
          [previousValue]="report?.totalsStats.allTotals.previous?.cost_per_mille.amount"
          [symbol]="currencySymbols[report?.totalsStats.allTotals.current.total_spend.currency]"
          [symbolBefore]="true"
          [maxFractionDigitsHover]="6"
          iconClass="ssi-cpm"
          iconColor="#425DEC"
          circleColor="#425DEC1A"
          ssiTooltip="This tells you the average cost per 1000 impressions of ads in your account during the reporting period."
          tooltipWindowClass="tooltip-chunky-big"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="Purchase ROAS"
          width="100%"
          [number]="report?.totalsStats.allTotals.current.purchase_roas.amount"
          [previousValue]="report?.totalsStats.allTotals.previous?.purchase_roas.amount"
          [symbol]="currencySymbols[report?.totalsStats.allTotals.current.total_spend.currency]"
          [symbolBefore]="true"
          [maxFractionDigitsHover]="6"
          iconClass="ssi-purchase-roas"
          iconColor="#12ACA4"
          circleColor="#12ACA41A"
          ssiTooltip="This tells you the total return on ad spend achieved in your account during the reporting period."
          tooltipWindowClass="tooltip-chunky-big"
        ></ssi-fact-overview>
      </div>
    </section>

    <div class="section-delimiter"></div>
    <section
      id="spend-breakdown"
      class="section-container section-container-spend-breakdown"
    >
      <h2 class="section-title">Spend Breakdown</h2>
      <ssi-spend-breakdown [report]="report"></ssi-spend-breakdown>
    </section>

    <div class="section-delimiter"></div>
    <section
      id="spend-engagement"
      class="section-container section-container-spend-engagement"
    >
      <h2 class="section-title">Spend vs Engagement</h2>
      <ssi-spend-engagement [adStatsData]="report.timeSeriesStats" [dateRanges]="dateRanges"></ssi-spend-engagement>
    </section>


    <div class="section-delimiter"></div>
    <section
      id="spend-conversion"
      class="section-container section-container-spend-conversion"
    >
      <h2 class="section-title">Spend vs Conversion</h2>
      <ssi-spend-conversion [adStatsData]="report.timeSeriesStats" [dateRanges]="dateRanges"></ssi-spend-conversion>
    </section>
  </div>

  <ng-template #dateRangesRef>
    <div class="selected-date-ranges">
      <span class="current">{{dateRanges.current.start | date: 'dd MMM yy'}} - {{dateRanges.current.end | date: 'dd MMM
        yy'}}</span>
    </div>
  </ng-template>
</div>