import { Injectable } from '@angular/core';
import {
  FilestackAccess,
  FilestackService
} from '../filestack/filestack.service';
import {
  base64ToArrayBuffer,
  FileMimeTypes
} from '../../directives/file-download/file-download.directive';

interface FileDownloadConfig {
  base64decode: boolean;
  filename: string;
  mimetype: FileMimeTypes;
}

@Injectable()
export class FileDownloadService {
  constructor(private filestack: FilestackService) {}

  /** Download file while passing the file data and config */
  download(fileData: any, config: FileDownloadConfig): void {
    let contents = fileData;
    if (config.base64decode) {
      contents = base64ToArrayBuffer(contents);
    }

    const blob = new window.Blob([contents], {
      type: config.mimetype ? `${config.mimetype};charset=utf-8` : ''
    });

    this.filestack
      .getClient()
      .then((client) => {
        return client.upload(
          blob,
          {},
          {
            filename: config.filename,
            access: FilestackAccess.Public
          }
        );
      })
      .then((file) => {
        console.error('file-download.service: upload (filestack): Size'); // only for tracksjs
        window.open(file.url, '_blank');
      })
      .finally(() => {
        console.log('FInished downloading');
      })
      .catch((err) => console.error('Download error:', err));
  }
}
