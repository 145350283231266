import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByStringPipe } from './filter-by-string.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FilterByStringPipe],
  exports: [FilterByStringPipe]
})
export class FilterByStringModule {}
