import './icon-file.component.scss';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ssi-icon-file',
  templateUrl: './icon-file.component.html'
})
export class IconFileComponent implements OnInit {
  @Input() fileType;
  @Input() primaryColour;
  @Input() accentColour;
  fontSize = 80;
  verticalAlignment = 150;

  constructor() {}

  ngOnInit() {
    this.fontSize =
      240 / this.fileType.length > 80 ? 80 : 240 / this.fileType.length;
    this.verticalAlignment = 150 + this.fontSize / 2;
  }
}
