<div class="insights-post-container"
     [class.post-selected]="selected">

  <div class="post-head">
    <div class="bar left">
      <!-- <ssi-checkbox-2 *ngIf="!modalView"
                      [label]=""
                      [variant]="'square'"
                      [(ngModel)]="selected"
                      (ngModelChange)="toggleSelected()"></ssi-checkbox-2> -->

      <ssi-social-network-icon [accountTypeId]="activity.account?.account_type_id"
                               [socialType]="activity.interaction.social_type"
                               [size]="26"></ssi-social-network-icon>

      <img class="author-avatar"
           [src]="activity.author.avatar"
           [ssiFallbackImage]="activity.interaction?.social_type === SocialType.Moreover ? 'mediaSourceAvatarDefault' : 'userAvatarDefault'">

      <a
        class="author-name"
        *ngIf="activity.author.link && activity.author.name"
        [href]="activity.author.link"
        target="_blank"
      >{{activity.author.name}}</a>
      <a
        class="author-name inactive"
        *ngIf="!activity.author.link && !activity.author.name"
        role="button"
        [ssiTooltip]="'Network does not provide author\'s name'"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="false"
      >{{SocialTypeName[activity.interaction.social_type] + ' user comment'}} <i class="ssi ssi-information"></i></a>

      <span class="author-username"
            *ngIf="activity.author.username">@{{activity.author.username}}</span>

      <div class="rating"
           *ngIf="activity.interaction.rating">
        <i *ngFor="let star of stars"
           class="ssi ssi-starred"
           [class.active]="activity.interaction.rating >= star"></i>
      </div>
    </div>
    <div class="bar right">
      <div class="timestamp">
        <span>{{activity.interaction.created_at | date: 'dd MMM yyyy' }}</span> &nbsp;&nbsp;|&nbsp;&nbsp; <span>{{activity.interaction.created_at | date: 'HH:mm'}}</span>
      </div>

      <div class="visibility">
        <i class="ssi {{activity.interaction.is_private ? 'ssi-private-correct' : 'ssi-public-correct'}}"></i>
        <span>{{activity.interaction.is_private ? 'Private' : 'Public'}}</span>
      </div>

      <i *ngIf="modalView"
         class="close-modal-icon ssi ssi-delete-close"
         (click)="activeModal.dismiss()"></i>
    </div>
  </div>

  <div class="post-body">
    <div class="left">
      <div class="content">
        <p *ngIf="modalView"
           [innerHTML]="formattedContent"></p>
        <span *ngIf="!modalView"
              [innerHTML]="formattedContent"></span>
        <span *ngIf="contentCropped && !modalView"
              class="read-more-link"
              (click)="readMore()">read more</span>
      </div>

      <ssi-message-link-preview *ngIf="activity.linkPreview"
                                class="message-box-message-link-preview"
                                [selfPenned]="false"
                                [title]="activity.linkPreview.title"
                                [description]="activity.linkPreview.description"
                                [url]="activity.linkPreview.url"
                                [thumb]="activity.linkPreview.media"></ssi-message-link-preview>

      <div class="sentiment-and-emotions"
           [class.padding-bottom]="modalView">
        <div class="chip-item"
             *ngIf="sentimentConst">
          <i class="ssi {{sentimentConst.icon}}"
             [style.color]="sentimentConst.iconColor2"
             [style.background-color]="sentimentConst.iconBgColor2"></i>
          <span [style.background-color]="sentimentConst.bgColor2">{{sentimentConst.labelNew | translate}}</span>
        </div>
        <div class="chip-item"
             *ngFor="let emotion of activity.insights?.content.emotion">
          <i class="{{emotions[emotion].icon}}"
             [style.color]="emotions[emotion].iconColor"
             [style.background-color]="emotions[emotion].iconBgColor"></i>
          <span [style.background-color]="emotions[emotion].bgColor">{{emotions[emotion].label}}</span>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="media-gallery"
           *ngIf="activity.media.length">
        <ng-container *ngFor="let mediaItem of activity.media | slice:0:2 | reverse; let i=index">
          <ng-container *ngTemplateOutlet="mediaItemTemplate; context: {mediaItem: mediaItem, mediaItemIndex: i }"></ng-container>
        </ng-container>

        <div class="media-items-count"
             *ngIf="hasMultipleMediaItems()">
          <span>{{activity.media.length - 1}}+</span>
        </div>
      </div>

      <ng-template #mediaItemTemplate
                   let-mediaItem="mediaItem"
                   let-mediaItemIndex="mediaItemIndex">
        <img *ngIf="mediaItem.type === 'photo' || mediaItem.type === 'gif' || mediaItem.type === 'animated_gif'"
             [src]="mediaItem.link"
             [class.stacked]="mediaItemIndex > 0"
             [style.top]="(mediaItemIndex * 7) + 'px'"
             [style.left]="(mediaItemIndex * 7) + 'px'"
             ssiFallbackImage="brokenImageNew512x340">

        <ng-container *ngIf="mediaItem.type === 'video' && mediaItem.size?.thumb && !mediaItem.errorLoadingThumb">
          <img [src]="mediaItem.size?.thumb"
               [class.stacked]="mediaItemIndex > 0"
               [style.top]="(mediaItemIndex * 7) + 'px'"
               [style.left]="(mediaItemIndex * 7) + 'px'"
               ssiFallbackImage=""
               (fallbackImageSet)="mediaItem.errorLoadingThumb = true">
          <i class="ssi ssi-correct-play play-icon"
             [style.top]="25 + (mediaItemIndex * 7) + 'px'"
             [style.left]="50 + (mediaItemIndex * 7) + 'px'"></i>
        </ng-container>

        <div *ngIf="mediaItem.type === 'video' && (!mediaItem.size?.thumb || mediaItem.errorLoadingThumb)"
             class="video-icon-container"
             [class.stacked]="mediaItemIndex > 0"
             [style.top]="(mediaItemIndex * 7) + 'px'"
             [style.left]="(mediaItemIndex * 7) + 'px'">
          <i class="ssi ssi-video-circle"></i>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="post-foot">
    <div class="bar left">
      <div class="stats">
        <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['likes'].outbox && isNumber(activity.outbox.like_count)">
          <ssi-big-number class="stat-value"
                          [number]="activity.outbox.like_count"></ssi-big-number>
          <span class="stat-label">Likes</span>
        </div>

        <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['comments'].outbox && isNumber(activity.outbox.comment_count)">
          <ssi-big-number class="stat-value"
                          [number]="activity.outbox.comment_count"></ssi-big-number>
          <span class="stat-label">Comments</span>
        </div>

        <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['shares'].outbox && isNumber(activity.outbox.share_count)">
          <ssi-big-number class="stat-value"
                          [number]="activity.outbox.share_count"></ssi-big-number>
          <span class="stat-label">{{activity.account.isTwitter() ? 'Reposts' : 'Shares'}}</span>
        </div>

        <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['clicks'].outbox && isNumber(activity.outbox.clicks)">
          <ssi-big-number class="stat-value"
                          [number]="activity.outbox.clicks"></ssi-big-number>
          <span class="stat-label">Clicks</span>
        </div>

        <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['impressions'].outbox && isNumber(activity.outbox.impressions)">
          <ssi-big-number class="stat-value"
                          [number]="activity.outbox.impressions"></ssi-big-number>
          <span class="stat-label">Impressions</span>
        </div>

        <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['reach'].outbox && isNumber(activity.outbox.reach)">
          <ssi-big-number class="stat-value"
                          [number]="activity.outbox.reach"></ssi-big-number>
          <span class="stat-label">Reach</span>
        </div>

        <!-- <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['engagement'].outbox">
          <ssi-big-number class="stat-value"
                          [number]="activity.outbox.engagement_count"></ssi-big-number>
          <span class="stat-label">Engagement</span>
        </div> -->

        <!-- <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['followers'].outbox">
          <ssi-big-number class="stat-value"
                          [number]="67777"></ssi-big-number>
          <span class="stat-label">Followers</span>
        </div> -->

        <!-- <div class="stat"
             *ngIf="activity.outbox && activity.outbox.account.socialNetwork.statistics['popularity'].outbox">
          <ssi-big-number class="stat-value"
                          [number]="89"></ssi-big-number>
          <span class="stat-label">Popularity</span>
        </div> -->

        <div class="stat"
             *ngIf="activity.insights?.location">
          <span class="stat-value">{{activity.insights.location.address.country}}</span>
          <span class="stat-label">Location</span>
        </div>
        <div class="stat"
             *ngIf="activity.moreover_extra?.source_name && activity.interaction.social_type === SocialType.Moreover">
          <span class="stat-value">{{activity.moreover_extra?.source_name}}</span>
          <span class="stat-label">Source</span>
        </div>
        <div class="stat"
             *ngIf="activity.interaction.social_type && activity.interaction.social_type !== SocialType.Moreover">
          <span class="stat-value">{{SocialTypeName[activity.interaction.social_type]}}</span>
          <span class="stat-label">Source</span>
        </div>
        <div class="stat"
             *ngIf="activity.author?.name && activity.interaction.social_type === SocialType.Moreover">
          <span class="stat-value">{{activity.author.name}}</span>
          <span class="stat-label">Author</span>
        </div>
        <div class="stat"
             *ngIf="activity.author?.username && activity.interaction.social_type !== SocialType.Moreover">
          <span class="stat-value">@{{activity.author.username}}</span>
          <span class="stat-label">Author</span>
        </div>
        <div class="stat"
             *ngIf="activity.interaction?.title">
          <span class="stat-value title" title="{{activity.interaction.title}}">{{activity.interaction.title}}</span>
          <span class="stat-label">Title</span>
        </div>
      </div>
    </div>

    <div class="bar right">
      <div class="tags"
           *ngIf="activity.tags?.length && activity.tags.length === 1"
           (click)="tagsSelector.show($event)">
        <span>{{activity.tags[0]}}</span>
        <i class="ssi ssi-edit edit-icon"></i>
      </div>

      <div class="tags"
           *ngIf="activity.tags?.length && activity.tags.length > 1"
           (click)="tagsSelector.show($event)"
           [ssiTooltip]="activity.tags.join(', ')"
           tooltipWindowClass="tooltip-chunky"
           [tooltipAppendToBody]="false">
        <i class="ssi ssi-tags tag-icon"></i>
        <span>{{activity.tags.length}} Tags</span>
        <i class="ssi ssi-edit edit-icon"></i>
      </div>

      <div class="actions-menu-container">
        <ssi-menu #actionsMenu
                  [headAlignment]="'top right'"
                  [bodyAlignment]="'top right'"
                  cdkOverlayOrigin
                  #sentimentMenuTriggerOrigin="cdkOverlayOrigin"
                  >
          <ng-template ssiTemplateSelector="menuHead">
            <i class="ssi ssi-outbox-menu menu-trigger-icon"></i>
          </ng-template>

          <ng-template ssiTemplateSelector="menuBody">
            <ul class="g-list">
              <li class="g-list-item"
                  (click)="actionsMenu.close(); assignPost()">
                <i class="ssi ssi-assign-correct"></i>
                <span>Assign post</span>
              </li>
              <li class="g-list-item"
                  (click)="actionsMenu.close(); tagsSelector.show($event)">
                <i class="ssi ssi-tag-test"></i>
                <span>Tag post</span>
              </li>
              <!-- <li class="g-list-item"
                  *ngIf=""
                  (click)="actionsMenu.close(); pushToCompose()">
                <i class="ssi ssi-compose-post"></i>
                <span>Push to Compose</span>
              </li> -->
              <li class="g-list-item"
                  *ngIf="(post.type === InsightsPostType.Activity && !activity.inbox.requires_action && !activity.interaction.deleted_at) || (post.type === InsightsPostType.Monitoring && activity.interaction.social_type === SocialType.Twitter && accounts.length)"
                  (click)="actionsMenu.close(); post.type === InsightsPostType.Monitoring ? accountSelector.show($event) : pushToInbox()">
                <i class="ssi ssi-inbox"></i>
                <span>Push to Inbox</span>
              </li>
              <li class="g-list-item"
                  *ngIf="post.type === InsightsPostType.Activity"
                  (click)="actionsMenu.close(); emailPost()">
                <i class="ssi ssi-correct-forward-email"></i>
                <span>Email post</span>
              </li>
              <li class="g-list-item"
                  *ngIf="showNativeLink"
                  (click)="actionsMenu.close(); viewNatively()">
                <i class="ssi ssi-view-natively"></i>
                <span>View natively</span>
              </li>
              <li class="g-list-item sentiment"
                  (click)="actionsMenu.close(); showSentimentMenu = true">
                <i class="ssi ssi-add-sentiment"></i>
                <span>Amend Sentiment</span>
              </li>
              <li class="g-list-item"
                  (click)="actionsMenu.close(); emotionsSelector.show($event)">
                <i class="ssi ssi-emotion"></i>
                <span>Amend Emotion</span>
              </li>
            </ul>
          </ng-template>
        </ssi-menu>

        <ssi-dropdown-select-2 #tagsSelector
                               [headless]="true"
                               [options]="tags"
                               [yOffset]="'-28px'"
                               [xPlacement]="'right'"
                               [filterable]="true"
                               [multiple]="true"
                               [toggleAllEnabled]="false"
                               [filterPlaceholder]="'TYPE_TAG_NAME_SENTANCE_CASE' | translate"
                               [(ngModel)]="selectedTags"
                               (ngModelChange)="updateTags($event)"></ssi-dropdown-select-2>

        <ssi-dropdown-select-2 #emotionsSelector
                               [headless]="true"
                               [options]="emotionsSorted"
                               [yOffset]="'-28px'"
                               [xPlacement]="'right'"
                               [filterable]="true"
                               [multiple]="true"
                               [toggleAllEnabled]="false"
                               [filterPlaceholder]="'SEARCH_EMOTIONS' | translate"
                               [(ngModel)]="selectedEmotions"
                               (ngModelChange)="updateEmotions($event)"></ssi-dropdown-select-2>

        <ssi-dropdown-select-2 #accountSelector
                               [headless]="true"
                               [options]="accounts"
                               [yOffset]="'-28px'"
                               [xPlacement]="'right'"
                               [filterable]="true"
                               [filterPlaceholder]="'Search for an account...'"
                               [(ngModel)]="selectedAccount"
                               (ngModelChange)="pushToInbox(selectedAccount)"></ssi-dropdown-select-2>
      </div>

    </div>
  </div>
</div>

<ng-template
  #sentimentMenu="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="sentimentMenuTriggerOrigin"
  [cdkConnectedOverlayOpen]="showSentimentMenu"
  [cdkConnectedOverlayFlexibleDimensions]="false"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="showSentimentMenu = false"
>
  <div *ngIf="!activity.account?.isYoutube()">
    <ssi-sentiment-amend-box
      [isInsights]="true"
      [sentiment]="activity.sentiment ? activity.sentiment.value : null"
      (onChangeSentiment)="changeSentiment($event)"
    ></ssi-sentiment-amend-box>
  </div>
</ng-template>