import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { setDay, getDay, setHours } from 'date-fns';
import { cloneDeep, merge } from 'lodash-es';

import './publisher-schedule-time-picker.component.scss';

@Component({
  selector: 'ssi-publisher-schedule-time-picker',
  templateUrl: './publisher-schedule-time-picker.component.html'
})
export class PublisherScheduleTimePickerComponent
  implements OnChanges, OnDestroy {
  @Input() topPostTimes;

  @Input() scheduleDate: Date;

  @Output() timeSelected = new EventEmitter<Date>();

  topPostTimesChart;

  time: NgbTimeStruct;

  isDestroyed = false;

  ngOnChanges(changes) {
    if (changes.scheduleDate) {
      this.updateTime();
    }
    if (changes.topPostTimes) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  addToSchedule() {
    const clonedDate = new Date(this.scheduleDate.getTime());
    clonedDate.setHours(this.time.hour);
    clonedDate.setMinutes(this.time.minute);
    clonedDate.setSeconds(this.time.second);
    this.timeSelected.emit(clonedDate);
  }

  delayedUpdateChart() {
    // IE / Edge hack
    setTimeout(() => this.updateChart(), 2000);
  }

  updateChart() {
    const ctrl = this;
    if (!this.isDestroyed && this.topPostTimes) {
      // check required because this runs in a setTimeout above
      this.topPostTimesChart = merge(cloneDeep(this.topPostTimes.chart), {
        chart: {
          height: '280px',
          marginTop: 45,
          zoomType: 'none'
        },
        xAxis: {
          opposite: true,
          labels: {
            style: {
              fontSize: '9px',
              color: '#43537F'
            }
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '9px',
              color: '#43537F'
            }
          }
        },
        exporting: {
          enabled: false
        },
        colorAxis: {
          minColor: '#f2995d',
          maxColor: '#971754'
        },
        legend: {
          y: 12,
          symbolHeight: 196
        }
      });
      this.topPostTimesChart.series[0] = merge(
        this.topPostTimesChart.series[0],
        {
          borderWidth: 4,
          borderColor: '#d5d6db',
          dataLabels: {
            color: 'white',
            formatter() {
              if (!this.isDestroyed) {
                if (this.y === ctrl.getSelectedRowIndex()) {
                  const label =
                    this.point.x === ctrl.time.hour
                      ? '<i class="ssi ssi-tick-small"></i>'
                      : this.point.value;
                  return `<span style="opacity: 1.0">${label}</span>`;
                } else {
                  return this.point.value;
                }
              }
            },
            useHTML: true
          },
          point: {
            events: {
              click() {
                const hours = this.x;
                const day = this.y === 6 ? 0 : this.y + 1;
                ctrl.scheduleDate = setHours(
                  setDay(ctrl.scheduleDate, day),
                  hours
                );
                ctrl.updateTime();
                ctrl.updateChart();
              }
            }
          }
        }
      );
      this.topPostTimesChart.series[0].data = this.topPostTimesChart.series[0].data.map(
        (data) => {
          data.selected = data.y === this.getSelectedRowIndex();
          if (
            data.x === ctrl.time.hour &&
            data.y === ctrl.getSelectedRowIndex()
          ) {
            data.color = '#23ADD1';
          }
          return data;
        }
      );
    }
  }

  private updateTime() {
    this.time = {
      hour: this.scheduleDate.getHours(),
      minute: this.scheduleDate.getMinutes(),
      second: 0
    };
  }

  private getSelectedRowIndex() {
    const day = getDay(this.scheduleDate);
    return day === 0 ? 6 : day - 1;
  }
}
