import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';

import { TeamService } from './team.service';
import { TeamComponent } from './team.component';
import { TeamBuildComponent } from './build/team-build.component';
import { TeamReportComponent } from './report/team-report.component';

import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { Checkbox2Module } from '../../../../common/components/checkbox-2/checkbox-2.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { ToggleSwitchModule } from '../../../../common/components/toggle-switch/toggle-switch.module';
import { TreetableModule } from '../../../../common/components/treetable';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { ReportSidebarModule } from '../common/report-sidebar/report-sidebar.module';
import { ReportHeaderModule } from '../common/report-header/report-header.module';
import { StatChangeModule } from '../common/stat-change/stat-change.module';
import { FactOverviewModule } from '../common/fact-overview/fact-overview.module';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.team',
          url: '/team',
          abstract: true,
          component: TeamComponent
        },
        {
          name: 'auth.analytics.team.build',
          url: '?accountIds&startDate&endDate&dateRange',
          component: TeamBuildComponent
        },
        {
          name: 'auth.analytics.team.report',
          url: '/report?accountIds&startDate&endDate&dateRange',
          component: TeamReportComponent
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    OrloComponentsModule,
    TranslateModule,
    FlatpickrModule,
    DropdownSelect2Module,
    Checkbox2Module,
    TooltipModule,
    OffClickModule,
    SocialNetworkIconModule,
    ToggleSwitchModule,
    TreetableModule,
    BigNumberModule,
    ReportSidebarModule,
    ReportHeaderModule,
    StatChangeModule,
    FactOverviewModule,
    HighchartModule
  ],
  declarations: [TeamComponent, TeamBuildComponent, TeamReportComponent],
  entryComponents: [TeamComponent],
  providers: [TeamService]
})
export class TeamModule {}
