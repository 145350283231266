<div class="live-chat-widgets grey">
  <div class="flex-grid container">
    <div class="col">
        <!-- <div class="return-nav">
          <orlo-button [state]="'primary'" [text]="'Back to settings'" (btnClick)="return()"></orlo-button>
        </div> -->
        <ui-view></ui-view>
        <div *ngIf="location.path().replace('/settings/account-settings','') === '/live-chat-widgets'" class="segment">
          <h1>Push Mode</h1>
          <div class="push-mode-status" [class.loading-service]="livechatLoading" [class.on]="isPushModeEnabled" (click)="togglePushMode()">{{livechatLoading ? '' : isPushModeEnabled ? 'You currently have push mode enabled' : ' You currently have push mode disabled'}}<i *ngIf="livechatLoading" class="ssi ssi-loading ssi-spin"></i></div>
        </div>
        <div *ngIf="location.path().replace('/settings/account-settings','') === '/live-chat-widgets'" class="segment">
          <h1>Your Live Chat widgets</h1>
          <div class="add-new-widget clearfix">
            <orlo-button uiSref=".widget" [uiParams]="{widget: defaultValues}" [state]="'outline secondary'" [text]="'Add new widget'" [bgColor]="'#F0F3F9'" [textColor]="'#15BAE3'" [leadingIcon]="'ssi ssi-plus'" ></orlo-button>
          </div>
          <ul class="widget-list">
            <li *ngFor="let widget of widgets">
              <span class="widget-name">{{widget.name}}</span>
              <orlo-button uiSref=".embed" [uiParams]="{widget: widget}" [state]="'outline primary'" [text]="'Embed'" [bgColor]="'#DCF5FB'" [textColor]="'#15BAE3'"></orlo-button>
              <orlo-button uiSref=".widget" [uiParams]="{widget: widget}" [state]="'outline secondary'" [text]="'Edit'" [bgColor]="'#F0F3F9'" [textColor]="'#15BAE3'" [leadingIcon]="'ssi ssi-pencil'" ></orlo-button>
            </li>
          </ul>
        </div>
      </div>
  </div>
</div>