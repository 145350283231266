import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BulkUploadService {
  constructor() {}

  sendChunks(
    messageChunks: any[],
    sendSingleChunk: (chunk: any) => Promise<any>,
    delay?: number,
    indexCounter = 0
  ) {
    const chunk = messageChunks[indexCounter];
    const hasMoreChunks = indexCounter < messageChunks.length - 1;
    return sendSingleChunk(chunk)
      .then(() => {
        if (hasMoreChunks && delay) {
          return setTimeout(() => '', delay);
        }
      })
      .then(() => {
        if (hasMoreChunks) {
          return this.sendChunks(
            messageChunks,
            sendSingleChunk,
            delay,
            indexCounter + 1
          );
        }
      });
  }
}
