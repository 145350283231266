import { Inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api';
import { removeUndefinedValuesFromObject } from '../../util';

export interface ProfileSearchLocation {
  latitude: number;
  longitude: number;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  /**
   * Constructed on-the-go after API response
   */
  fullLocation?: string;
}

export interface Profile {
  description: string;
  id: string;
  image_url: string;
  location: ProfileSearchLocation;
  name: string;
  username: string;
  verified: boolean | null;
}

export interface ProfileSearchResult {
  links: { next: { page: string } };
  results: Profile[];
}

@Injectable({ providedIn: 'root' })
export class ProfilesService {
  constructor(protected api: ApiService) {}

  search(
    accountId: string,
    searchText: string,
    { size, page }: { size?: number; page?: string } = {}
  ): Promise<ProfileSearchResult> {
    const req = {
      params: {
        account_id: accountId,
        q: searchText,
        size,
        page
      }
    };
    const opts = {
      params: removeUndefinedValuesFromObject(req.params)
    };
    const url = `${this.api.url}/profile_v2/search`;

    return this.api
      .get(url, opts)
      .pipe(
        map((response: ProfileSearchResult) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, url))
      )
      .toPromise();
  }
}
