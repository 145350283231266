import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InboxPriorityIndicatorComponent } from './inbox-priority-indicator/inbox-priority-indicator.component';
import { InboxPrioritySelectComponent } from './inbox-priority-select/inbox-priority-select.component';

import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { ContextMenuModule } from '../context-menu/context-menu.module';

@NgModule({
  imports: [CommonModule, TooltipModule, ContextMenuModule],
  exports: [InboxPriorityIndicatorComponent, InboxPrioritySelectComponent],
  declarations: [InboxPriorityIndicatorComponent, InboxPrioritySelectComponent]
})
export class InboxPriorityModule {}
