import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStore } from 'js-data';
import {
  campaignModelFactory,
  CampaignModel
} from '../../modules/campaign/services/campaignModel';

@NgModule()
export class CampaignModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CampaignModule,
      providers: [
        {
          provide: CampaignModel,
          useFactory: campaignModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
