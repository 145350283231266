<div class="drilldown-modal-content-container modal-popup">
  <div class="heading">
    <h2 class="title">Top 10 Influencers<span *ngIf="title"> "{{title}}"</span></h2>
    <div>
      <button (click)="modalInstance.dismiss()">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="drilldown-content">
    <ssi-influencers-list
      [type]="title"
      [authorsByFollowers]="authorsByFollowers"
      [authorsByEngagements]="authorsByEngagements"
      [drillDownParams]="drillDownParams"
    ></ssi-influencers-list>
  </div>
    
</div>