<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Manage accounts</h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div class="page-header">
    <h1>
      <span translate="MANAGE_YOUR_SOCIAL_ACCOUNTS"></span>
      <div class="pull-right" *ngIf="hasAdministerAccountsPermission">
        <button class="btn btn-primary" (click)="loadAllAccountsDashboard()">
          <i class="fa fa-check"></i> <span translate="FINISHED"></span>
        </button>
      </div>
    </h1>
  </div>

  <ngb-tabset>

    <ngb-tab [title]="'ADD_ACCOUNT' | translate">

      <ng-template ngbTabContent>
        <div *ngIf="authSessionOptions">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3>
                <span translate="{{provider.name === 'Facebook Group' ? 'SELECT_GROUP_TO_ADD' : 'SELECT_PAGE_TO_ADD'}}"></span>
                <button
                  class="btn btn-default pull-right"
                  (click)="destroySession()"
                  translate="FINISHED">
                </button>
              </h3>
            </div>
            <div class="panel-body">

              <p translate="AFTER_ADDING_PAGESGROUPS_IT_MAY_TAKE_SOME_TIME_BEFORE_THEY_ARE_POPULATED_WITH_DATA_BUT_YOU_CAN_PUBLISH_TO_THEM_STRAIGHT_AWAY"></p>
              <table class="table">
                <tr *ngFor="let option of authSessionOptions.options; trackBy:trackByProfileId">
                  <td>
                    <img class="profile-small img-circle" [src]="option.profile.picture" *ngIf="option.profile.picture">
                    <a [href]="option.link" target="_blank">
                      {{ option.profile.name }}
                      (<span *ngIf="option.profile.username !== option.profile.name">{{ option.profile.username }}</span>)
                    </a>
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      *ngIf="!option.profile['added']"
                      [disabled]="option.profile['adding']"
                      (click)="addAccount(option)"
                    >
                      <i class="fa fa-plus" *ngIf="!option.profile['adding']"></i>
                      <i class="fa fa-spin fa-spinner" *ngIf="option.profile['adding']"></i>
                      <span translate="{{provider.name === 'Facebook Group' ? 'ADD_GROUP' : 'ADD_PAGE'}}"></span>
                    </button>
                    <button
                      class="btn btn-sm btn-success"
                      *ngIf="option.profile['added']"
                      disabled>
                      <i class="fa fa-check"></i> <span translate="{{provider.name === 'Facebook Group' ? 'GROUP_ADDED' : 'PAGE_ADDED'}}"></span>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="loading" *ngIf="loadingTracker.active"></div>

        <div class="alert alert-info" [hidden]="hasAdministerAccountsPermission">
          <div translate="YOU_NEED_THE_ADMINISTER_ACCOUNTS_COMPANY_PERMISSION_TO_BE_ABLE_TO_ADD_NEW_SOCIAL_ACCOUNTS"></div>
        </div>

        <div [hidden]="authSessionOptions || !hasAdministerAccountsPermission">
          <div class="d-flex add-accounts-panels">
            <div class="panel panel-default text-center" *ngFor="let provider of authProviders; trackBy:trackByName">
              <div class="panel-heading">
                <a (click)="openAuthPopup(provider)">
                  <h1>{{ provider.isAdvertisingAccount ?
                    provider.socialNetwork.advertising.accountTypeLabel :
                    provider.socialNetwork.accountTypeLabel }}
                  </h1>
                </a>
              </div>
              <div class="panel-body">
                <a (click)="openAuthPopup(provider)">
                  <img
                    [src]="provider.isAdvertisingAccount ? 
                    provider.socialNetwork.advertising.logoImg :
                    provider.socialNetwork.logoImg"
                    [ngStyle]="{height: '60px'}"
                  />
                </a>
                <br>
                <button
                  class="btn btn-primary btn-lg btn-block"
                  (click)="openAuthPopup(provider)"
                  ssiTooltip="Facebook Groups associated with the new Pages experience cannot be added"
                  [tooltipAppendToBody]="true"
                  tooltipWindowClass="tooltip-chunky"
                  [tooltipDisabled]="provider.name !== 'Facebook Group'"
                >
                  <span translate="ADD_ACCOUNT"></span>
                </button>
              </div>
            </div>
            <ng-container *ngIf="hasTwilioEnabled">
              <div class="panel panel-default text-center" *ngFor="let provider of customProviders">
                  <div class="panel-heading">
                    <a (click)="addCustomProvider('twilio')">
                      <h1>{{ provider.label }}</h1>
                    </a>
                  </div>
                  <div class="panel-body">
                    <a (click)="addCustomProvider(provider)">
                      <img
                        [src]="provider.socialNetwork.logoImg"
                        [ngStyle]="{height: '60px'}"
                      />
                    </a>
                    <br>
                    <button class="btn btn-primary btn-lg btn-block" (click)="addCustomProvider(provider)">
                      <span translate="ADD_ACCOUNT"></span>
                    </button>
                  </div>
              </div>
            </ng-container>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title" translate="FINISHED_ADDING_ACCOUNTS"></h3>
            </div>
            <div class="panel-body">
              <p translate="ONCE_YOU_ARE_FINISHED_LOADING_YOUR_ACCOUNTS_CLICK_BELOW_TO_GET_SOCIAL_AND_START_USING_YOUR_NEW_ACCOUNTS"></p>
              <p class="text-center">
                <br/>
                <button class="btn btn-primary" (click)="loadAllAccountsDashboard()">
                  <i class="fa fa-check"></i> <span translate="FINISHED"></span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </ng-template>

    </ngb-tab>

    <ngb-tab [title]="'EDIT_ACCOUNTS' | translate">

      <ng-template ngbTabContent>
        <table class="table table-striped" [hidden]="!hasAdministerAccountsPermission">

          <thead>
            <tr>
              <th translate="PICTURE"></th>
              <th translate="NAME"></th>
              <th translate="DISPLAY_NAME"></th>
              <th translate="USERNAME"></th>
              <th translate="VANITY_URL"></th>
              <th class="text-center" translate="ACTIONS"></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let account of orderedAccounts; trackBy:trackById">
              <td>
                <img class="img-rounded" [src]="account.picture" width="50" height="50">
              </td>
              <td>
                <i [class]="account.socialNetwork.icon.web"></i> {{ account.displayName }}
              </td>
              <td>
                <div *ngIf="edit?.account !== account">
                  {{ account.alias }}
                  <em translate="NOT_SET" *ngIf="!account.alias"></em>
                </div>
                <div class="input-group" *ngIf="edit?.account === account">
                  <input type="text" class="form-control" [(ngModel)]="edit.form.alias">
                  <span class="input-group-btn">
                    <button class="btn btn-primary" (click)="saveEdit()">
                      <i class="fa fa-save"></i>
                    </button>
                  </span>
                </div>
              </td>
              <td>{{ account.username }}</td>
              <td>
                <div *ngIf="edit?.account !== account">
                  {{ account.default_vanity_domain }}
                </div>
                <div *ngIf="edit?.account === account">
                  <div class="input-group">
                    <select class="form-control" [(ngModel)]="edit?.form.default_vanity_domain_id">
                      <option [ngValue]="nullSelectValue">{{ defaultVanityDomain.domain }}</option>
                      <option
                        *ngFor="let vanityDomain of vanityDomains; trackBy:trackById"
                        [ngValue]="vanityDomain.id">
                        {{ vanityDomain.domain }}
                      </option>
                    </select>
                    <span class="input-group-btn">
                      <button class="btn btn-primary" (click)="saveEdit()">
                        <i class="fa fa-save"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </td>
              <td class="nowrap text-center">
                <button
                  class="btn btn-warning"
                  [ssiTooltip]="'THE_ACCOUNT_REQUIRES_REAUTHENTICATING_WITH_THE_SOCIAL_NETWORK' | translate"
                  uiSref="auth.reAuthAccount"
                  *ngIf="account.is_reauth_required">
                  <i class="fa fa-warning"></i>
                </button>

                <button
                  class="btn btn-primary"
                  (click)="cancelEdit()"
                  *ngIf="edit?.account === account"
                  [ssiTooltip]="'CANCEL' | translate">
                  <i class="fa fa-times"></i>
                </button>

                <button
                  class="btn btn-primary"
                  (click)="startEdit(account)"
                  *ngIf="edit?.account !== account"
                  [ssiTooltip]="'EDIT_ACCOUNT' | translate">
                  <i class="fa fa-edit"></i>
                </button>
                
                <button
                  class="btn btn-default"
                  [ssiTooltip]="'VIEW_ON_SOCIALSIGNIN' | translate"
                  (click)="viewProfile(account)"
                  [disabled]="!account.socialNetwork.profile?.isViewable || !authUser.permissions[account.id]?.view_inbox">
                  <i class="fa fa-folder-o"></i>
                </button>

                <a
                  class="btn btn-default"
                  target="_blank"
                  [href]="account.externalUrl"
                  [ssiTooltip]="'VIEW_ON_SOCIAL_NETWORK' | translate">
                  <i class="fa fa-external-link"></i>
                </a>

                <button
                  class="btn btn-danger"
                  *ngIf="hasAdministerAccountsPermission"
                  (click)="showRemoveAccountModal(account)"
                  [ssiTooltip]="'DELETE' | translate">
                  <i class="fa fa-trash-o"></i>
                </button>

              </td>
            </tr>
          </tbody>

        </table>
      </ng-template>

    </ngb-tab>

  </ngb-tabset>

</div>

