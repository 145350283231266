<div class="notes-component-wrapper"
     [ngClass]="{'notes-component-wrapper-ad': theme === 'ad'}">
  <div [ngClass]="{'notes-list-wrapper-fade': notes?.length !== 0}"
       class="notes-list-wrapper">
    <div class="notes-empty"
         *ngIf="notes?.length === 0">
      <i class="ssi ssi-feather-microcopy notes-empty-icon"></i>
      <p>Need to keep your team members in the loop about anything? Drop them a note then!</p>
    </div>
    <ul *ngIf="notes?.length"
        class="validate-post-notes-list">
      <li [ngClass]="{'note-own' : note.created_by === user.id}"
          class="note"
          *ngFor="let note of notes">
        <ul class="note-header">
          <li class="note-author">
            <span>{{ authoredBy(note) }}</span>
          </li>
          <li *ngIf="note.created_by === user.id">
            <button class="delete-button"
                    (click)="deleteNote(note)">
              <i class="ssi ssi-trash button-icon"></i>
              <span class="button-text"
                    translate="DELETE"></span>
            </button>
          </li>
          <li class="note-date">{{ note.created_at | date:'d MMM yyyy' }}</li>
          <li class="note-time">{{ note.created_at | date:'HH:mm' }}</li>
        </ul>
        <p class="note-content disabled-links"
           [innerHTML]="note.content | sanitizedHtml"></p>
      </li>
    </ul>
  </div>
  <div class="notes-input-wrapper">
    <div class="notes-input">
      <div class="notes-input-body"
           *ngIf="!disablePosting">
        <textarea [(ngModel)]="content"
                  (ngModelChange)="contentChange($event)"
                  #textarea
                  [placeholder]="'Type @ to bring up your team members...'"
                  [mention]="tagList"
                  [mentionConfig]="{labelKey: mentionlabelKey}"></textarea>
        <div class="notes-tag"
             [innerHTML]="tags | sanitizedHtml"></div>
      </div>
      <div class="notes-input-footer">
        <button [ngClass]="{'submit-note-disabled' : disablePosting}"
                class="btn btn-default submit-note"
                type="submit"
                (click)="saveNote()"
                *ngIf="!disablePosting">Add notes</button>
      </div>
    </div>
  </div>
</div>