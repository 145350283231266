
<button
  (click)="toggleSidebar()"
  class="toggle"
  type="button"
>
  <i class="fa fa-compress"></i>
</button>

<ssi-live-chat-network-state
  *ngIf="!! conversation.visitor"
  [isAvatarOnly]="true"
  [user]="conversation.visitor"
></ssi-live-chat-network-state>

<p
  *ngIf="!! conversation.visitor"
  [ngClass]="{
    'verified': !! conversation.visitor.isLoggedIn
  }"
  class="primary-details"
>
  <span
    class="name"
  >
    {{ conversation.visitor.displayName }}
  </span>

  <span
    class="network-state"
  >
    {{ conversation.visitor.networkStateValue }}
  </span>
</p>

<div class="inner">
  <dl
    *ngIf="!! conversation.visitor && !! conversation.visitor.isLoggedIn"
    [ngClass]="{
      'expanded': !! isVerifiedDetailsVisible
    }"
    class="details"
  >
    <dt
      (click)="toggleVisibilityOfVerifiedDetails()"
      translate="VERIFIED_DETAILS"
    ></dt>

    <dd
      *ngIf="!! isVerifiedDetailsVisible"
    >
      <ul>
        <li
          *ngIf="!! conversation.visitor?.displayName"
        >
          <dl>
            <dt
              class="verified"
              translate="NAME"
            ></dt>

            <dd>
                {{ conversation.visitor.displayName }}
            </dd>
          </dl>        
        </li>

        <li
          *ngIf="!! conversation.visitor?.email"
        >
          <dl>
            <dt
              class="verified"
              translate="EMAIL"
            ></dt>

            <dd>
                {{ conversation.visitor.email }}
            </dd>
          </dl>        
        </li>

        <li
        *ngFor="let information of visitorInformation"
        >
          <dl>
            <dt
              class="verified"
            >{{information.label}}</dt>

            <dd>
                {{information.value}}
            </dd>
          </dl>        
        </li>

        <li
          *ngIf="!! conversation.originUrl"
          class="origin"
        >
          <dl>
            <dt
              translate="ORIGINATING_URL"
            ></dt>

            <dd>
              {{ conversation.originUrl }}
            </dd>
          </dl>
        </li>
      
        <li
          *ngIf="!! conversation.lastUrl"
          class="origin"
        >
          <dl>
            <dt
              translate="LAST_KNOWN_URL"
            ></dt>

            <dd>
              {{ conversation.lastUrl }}
            </dd>
          </dl>
        </li>
      
        <li
          *ngFor="let item of conversation.visitor?.metadata"
        >
          <dl>
            <dt
                class="verified"
            >
              {{ item.name }}
            </dt>

            <dd>
              {{ item.value }}
            </dd>
          </dl>
        </li>
      </ul>
    </dd>
  </dl>

  <dl
    [ngClass]="{
      'expanded': !! isChatDetailsVisible
    }"
    class="details"
  >
    <!-- <dt
      (click)="toggleVisibilityOfChatDetails()"
      translate="CHAT_DETAILS"
    ></dt> -->

    <dd
      *ngIf="!! isChatDetailsVisible && !! conversation.visitor"
    >
      <ul>
        <li
          *ngIf="! conversation.visitor.isLoggedIn && !! conversation.visitor?.displayName"
        >
          <dl>
            <dt
              translate="NAME"
            ></dt>
      
            <dd>
                {{ conversation.visitor.displayName }}
            </dd>
          </dl>        
        </li>
      
        <li
          *ngIf="! conversation.visitor.isLoggedIn && !! conversation.visitor?.email"
        >
          <dl>
            <dt
              translate="EMAIL"
            ></dt>
      
            <dd>
                {{ conversation.visitor.email }}
            </dd>
          </dl>        
        </li>

        <li
        *ngFor="let information of visitorInformation"
        >
          <dl>
            <dt
              class="verified"
            >{{information.label}}</dt>

            <dd>
                {{information.value}}
            </dd>
          </dl>        
        </li>

        <li
          *ngIf="!! conversation.originUrl"
          class="origin"
        >
          <dl>
            <dt
              translate="ORIGINATING_URL"
            ></dt>

            <dd>
              {{ conversation.originUrl }}
            </dd>
          </dl>
        </li>
      
        <li
          *ngIf="!! conversation.lastUrl"
          class="origin"
        >
          <dl>
            <dt
              translate="LAST_KNOWN_URL"
            ></dt>

            <dd>
              {{ conversation.lastUrl }}
            </dd>
          </dl>
        </li>
      
        <li
          *ngIf="!! lastSeen"
        >
          <dl>
            <dt
              translate="LAST_SEEN"
            ></dt>

            <dd>
                {{ lastSeen }}
            </dd>
          </dl>        
        </li>

        <li
          *ngIf="!! firstSeen"
        >
          <dl>
            <dt
              translate="FIRST_SEEN"
            ></dt>

            <dd>
                {{ firstSeen }}
            </dd>
          </dl>        
        </li>

        <li
          *ngIf="!! conversation.visitor?.browser"
        >
          <dl>
            <dt
              translate="BROWSER"
            ></dt>

            <dd>
                {{ conversation.visitor.browser }}
            </dd>
          </dl>        
        </li>

        <li
          *ngIf="!! conversation.visitor?.operatingSystem"
        >
          <dl>
            <dt
              translate="OPERATING_SYSTEM"
            ></dt>

            <dd>
                {{ conversation.visitor.operatingSystem }}
            </dd>
          </dl>        
        </li>

        <li
          *ngIf="!! conversation.visitor?.userAgentLanguage"
        >
          <dl>
            <dt
              translate="LANGUAGE" 
            ></dt>

            <dd>
                {{ conversation.visitor.userAgentLanguage }}
            </dd>
          </dl>        
        </li>
        <li
        *ngIf="!! conversation.visitor?.data.clientIp"
      >
        <dl>
          <dt
            translate="IP_ADDRESS" 
          ></dt>

          <dd>
              {{ conversation.visitor.data.clientIp }}
          </dd>
        </dl>        
      </li>
      <li *ngIf="visitorIpLocation">
        <dl>
          <dt
            translate="LOCATION" 
          ></dt>

          <dd>
              {{ visitorIpLocation.location.city_name }}, {{visitorIpLocation.location.region_name}}, {{visitorIpLocation.location.country_name}}
          </dd>
        </dl>        
      </li>
      <li *ngIf="visitorIpLocation">
        <ssi-map
          [zoom]="12"
          [center]="latLngCenter"
          [mapHeight]="200"
          [markers]="latLngMarker"
          [zoomOnClick]="false"
        >
        </ssi-map>
      </li>
      </ul>
    </dd>
    <ng-container *ngIf="!blockingDisabled">
      <dt
          (click)="blockVisitor()"
          *ngIf="!isVisitorBlocked"
          class="block"
        >Block visitor</dt>
      <dt
        (click)="unblockVisitor()"
        *ngIf="!!isVisitorBlocked"
        class="block"
        >Unblock visitor</dt>
    </ng-container>
  </dl>
</div>
