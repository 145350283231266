import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { LiveChatWidgetsComponent } from './live-chat-widgets.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmbedWidgetModule } from './embed-widget/embed-widget.module';
import { EditWidgetModule } from './edit-widget/edit-widget.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.accountsettings.livechatwidgets',
          url: '/live-chat-widgets',
          component: LiveChatWidgetsComponent,
          resolve: LiveChatWidgetsComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    EmbedWidgetModule,
    EditWidgetModule
  ],
  declarations: [LiveChatWidgetsComponent],
  entryComponents: [LiveChatWidgetsComponent]
})
export class LiveChatWidgetsModule {}
