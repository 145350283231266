import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from './notification.component';

@Injectable()
export class NotificationService {
  activeNotifications = [];

  constructor(private ngbModal: NgbModal) {}

  open(
    message: string | TemplateRef<any>,
    icon?: { class: string; color: string },
    duration?: number,
    dismissable = false,
    clear = true,
    animate = false
  ) {
    if (clear) {
      for (const notificationInstance of this.activeNotifications) {
        notificationInstance.dismiss();
      }

      const notification = this.ngbModal.open(NotificationComponent, {
        backdrop: false,
        windowClass: `notification-window notification-window-no-backdrop ${
          animate ? 'notification-animation' : ''
        }`
      });
      notification.componentInstance.message = message;
      notification.componentInstance.iconClass = icon.class;
      notification.componentInstance.iconColor = icon.color;
      notification.componentInstance.dismissable = dismissable;

      this.activeNotifications.push(notification);

      if (duration) {
        setTimeout(() => {
          notification.dismiss();
        }, duration);
      }
    }
  }
}
