import './influencers-list.component.scss';
import { Input, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DrilldownModalComponent } from '../../../modules/auth/insights/common/components/drilldown-modal/drilldown-modal.component';

export interface Influencer {
  avatar: 'string';
  city: string;
  country: string;
  engagements: number;
  followers: number | string | null;
  id: string;
  name: string;
  username: string;
}

export enum Sort {
  Followers = 'authorsByFollowers',
  Engagements = 'authorsByEngagements'
}

@Component({
  selector: 'ssi-influencers-list',
  templateUrl: './influencers-list.component.html'
})
export class InfluencersListComponent implements OnInit {
  @Input() type: string;
  @Input() authorsByFollowers: Influencer[];
  @Input() authorsByEngagements: Influencer[];
  @Input() drillDownParams;
  influencers = [];
  activeSort = Sort.Followers;
  sortType = Sort;

  constructor(private modal: NgbModal) {}

  ngOnInit() {
    this.influencers = this[Sort.Followers];
  }

  sortInfluencers(sortBy: Sort) {
    this.activeSort = sortBy;
    // this.influencers = this.influencers.sort((a, b) => b[sortBy] - a[sortBy]);
    this.influencers = this[sortBy];
  }

  openDrilldownModal(influencerId: string) {
    const defaulIndustryFilters = [
      {
        field: 'Gender',
        in: ['org']
      },
      {
        field: 'Industries',
        in: [this.type]
      }
    ];
    const defaulInterestFilters = [
      {
        field: 'Gender',
        in: ['male', 'female']
      },
      {
        field: 'Interests',
        in: [this.type]
      }
    ];
    const params = this.drillDownParams.globalFilters
      .filter(
        (param) => !['Gender', 'Interests', 'Industries'].includes(param.field)
      )
      .concat(
        {
          field: 'Author Id',
          in: [influencerId]
        },
        this.drillDownParams.widgetType === 'industries'
          ? defaulIndustryFilters
          : defaulInterestFilters
      );

    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });
    Object.assign(modal.componentInstance, this.drillDownParams.streamIds);
    modal.componentInstance.displayApplyFilters = false;
    modal.componentInstance.widgetFilters = params;
  }
}
