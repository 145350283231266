import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePostsActionsComponent } from './validate-posts-actions.component';
import { UIRouterModule } from '@uirouter/angular';
import { TriggerPublishModule } from '../../../../../../common/directives/trigger-publish/trigger-publish.module';

@NgModule({
  imports: [CommonModule, TriggerPublishModule, UIRouterModule],
  declarations: [ValidatePostsActionsComponent],
  exports: [ValidatePostsActionsComponent]
})
export class ValidatePostsActionsModule {}
