<div class="modal-header">
  <h4 class="modal-title" [style.display]="'inline'">Examples for "{{topic.name}}"</h4>
  <button class="close pull-right" (click)="activeModal.close()">
    <i class="fa fa-times"></i>
  </button>
</div> 

<div class="modal-body modal-example-body">
  <p>Add Examples of questions that may be asked by customers to give your Chat Bot an idea of what to expect from conversations and begin learning the ropes for how to respond.</p>
  <hr>
  <ng-container *ngIf="examples">
    <h4>Examples:</h4>
    <div class="panel panel-default" *ngFor="let example of examples">
      <div class="panel-body">
        <p
          *ngIf="editing?.id !== example.id"
          class="well well-sm">
          {{example.text}}
        </p>
        <textarea
          *ngIf="editing?.id === example.id"
          [style.width.%]="100"
          [style.height.px]="250"
          [(ngModel)]="example.text"
        ></textarea>
      </div>
      <div *ngIf="example.id" class="panel-footer">
        <ng-container *ngIf="editing?.id !== example.id">
          <button type="button" class="btn btn-primary" (click)="editing = example">Edit</button>
          <button type="button" class="btn btn-danger" (click)="deleteExample(example, topic, metabase)">Delete</button>
        </ng-container>
        <button *ngIf="editing?.id === example.id" type="button" class="btn btn-success" (click)="editExample(example, topic, metabase)">Save</button>
      </div>
    </div>
  </ng-container>

  <hr *ngIf="!editing && examples.length">
  
  <div *ngIf="!editing" class="panel panel-default">
    <div class="panel-heading">Add Example:</div>
    <div class="panel-body">
      <textarea [(ngModel)]="create.text" [style.width.%]="100"></textarea>
    </div>
    <div class="panel-footer">
      <button type="button" class="btn btn-success" (click)="addExample(create, metabase, topic)">Create</button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="activeModal.close()"
  >
    Close
  </button>
</div>