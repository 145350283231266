import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsInputComponent } from './tags-input.component';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, FormsModule, NgbTypeaheadModule.forRoot()],
  declarations: [TagsInputComponent],
  exports: [TagsInputComponent]
})
export class TagsInputModule {}
