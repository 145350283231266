import { Injectable } from '@angular/core';
import { AccountModel } from '@ui-resources-angular';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../../../common/services/api';

@Injectable()
export class AiWritingToolService {
  constructor(private api: ApiService) {}

  async getUsageAndLimits() {
    const endpoint = `${this.api.url}/writingTool/companyUsage`;

    return this.api
      .get(endpoint)
      .pipe(
        map(
          (response: {
            success: boolean;
            ratelimit: { usage: number; limit: number };
          }) => {
            return response.ratelimit;
          }
        ),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async getParaphrase(req: { text: string; tone: string; length: string }) {
    const endpoint = `${this.api.url}/writingTool/paraphrase`;
    const reqParams = {
      text: req.text,
      tone: req.tone,
      length: req.length
    };

    return this.api
      .post(endpoint, reqParams)
      .pipe(
        map((response: any) => {
          return response.text;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async getHashtags(req: { text: string; tone: string }) {
    const endpoint = `${this.api.url}/writingTool/hashtags`;
    const reqParams = {
      text: req.text,
      tone: req.tone
    };

    return this.api
      .post(endpoint, reqParams)
      .pipe(
        map((response: any) => {
          return response.hashtags;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
