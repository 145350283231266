import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { DashboardComponent } from './dashboard.component';

import { CreateModule } from './create/create.module';
import { EditModule } from './edit/edit.module';
import { ViewModule } from './view/view.module';
import { AlertsModule } from '../../../common/components/alerts/alerts.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.dashboard',
          url: '/dashboard?accountId&profileId&noteId',
          component: DashboardComponent,
          resolve: DashboardComponent.resolve
        }
      ]
    }),
    ViewModule,
    CreateModule,
    EditModule,
    AlertsModule
  ],
  declarations: [DashboardComponent],
  entryComponents: [DashboardComponent]
})
export class DashboardModule {}
