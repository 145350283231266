import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPartialPipe } from './filter-partial.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FilterPartialPipe],
  exports: [FilterPartialPipe]
})
export class FilterPartialModule {}
