<div class="modal-header">
  <div class="row">
    <h3 class="col-sm-5" style="margin: 0">
      <span *ngIf="!loadingTracker.active">
        {{ profile.info.name }}
        <small
          class="text-muted"
          *ngIf="profile.info.username && profile.info.username !== profile.info.name">
          @{{ profile.info.username }}
        </small>
        <small
          class="text-muted"
          *ngIf="profile.account.account_type_name === 'Facebook'
          && profile.info.name === profile.info.username
          && hasFacebookProfileIdFeature"
          [ssiTooltip]="'This ID number allows you to quickly distinguish between different user profiles of the same name.'"
          [tooltipAppendToBody]="true"
          [tooltipPlacement]="'bottom'"
          [tooltipOffset]="[0, 20]">
          {{ profile.id }}
        </small>
      </span>
      <span *ngIf="loadingTracker.active" translate="LOADING"></span>
    </h3>
    <div class="col-sm-7 text-right">
      <span class="inline-block text-left" *ngIf="profile.account.account_type_name === 'Twitter'">
        <div ngbDropdown>
          <button
            class="btn btn-sm btn-primary dropdown-toggle"
            id="dropdownMessage"
            ngbDropdownToggle>
            <span translate="MESSAGE"></span> <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownMessage">
            <li *ngIf="profile.info.relationship.followed_by" ngbDropdownItem role="button">
              <div (click)="privateMessage()">
                <i class="fa fa-envelope"></i> <span translate="PRIVATE_MESSAGE"></span>
              </div>
            </li>
            <li ngbDropdownItem role="button">
              <div
                (click)="close()"
                [ssiTriggerPublish]="{create: {
                  text: '@' + profile.info.username + ' ',
                  accounts: [profile.account]
                }}">
                <i class="ssi ssi-x-logo"></i> <span>Post</span>
              </div>
            </li>
          </ul>
        </div>
      </span>
      <span class="inline-block text-left" *ngIf="otherAccounts.length > 1">
        <div ngbDropdown>
          <button
            class="btn btn-sm btn-info dropdown-toggle"
            id="dropdownViewAs"
            ngbDropdownToggle>
            <span translate="VIEW_AS"></span> <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownViewAs">
            <li
              ngbDropdownItem
              role="button"
              *ngFor="let account of otherAccounts"
              [ngClass]="{active: account.id == profile.account_id}">
              <div (click)="changeAccount(account)">
                <i [ngClass]="account.socialNetwork.icon.web"></i> {{ account.displayName }}
              </div>
            </li>
          </ul>
        </div>
      </span>
      <button class="btn btn-default btn-sm" (click)="close()"><i class="fa fa-times"></i></button>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="loading" [hidden]="!loadingTracker.active"></div>

  <ngb-tabset
    #profileTabs="ngbTabset"
    [destroyOnHide]="false"
    (tabChange)="beforeTabChange($event)"
    [hidden]="loadingTracker.active">
    <ngb-tab id="tabProfile">
      <ng-template ngbTabTitle>
        <i class="fa fa-user"></i> <span translate="PROFILE"></span>
      </ng-template>
      <ng-template ngbTabContent>
        <div class="row">
          <div class="col-sm-2">
            <div class="verified-wrap">
              <img class="profile-large img-circle" [src]="profile.info.picture">
              <i *ngIf="profile.info.verified" class="verified ssi ssi-verified-icon"></i>
            </div>
          </div>
          <div class="col-sm-10">
            <h4>{{ profile.info.description }}</h4>
            <div class="row">
              <div class="col-sm-6" *ngIf="profile.info.url">
                <p class="no-wrap">
                  <a [href]="profile.info.url" target="_blank">
                    <i class="fa fa-globe"></i> {{ profile.info.url }}
                  </a>
                </p>
              </div>
              <div class="col-sm-6">
                <p *ngIf="profile.info.location" class="no-wrap">
                  <i class="fa fa-map-marker"></i> {{ profile.info.location }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6" *ngIf="profile.info.connection_count">
                <i class="fa fa-user"></i> <span translate="CONNECTIONS__AMOUNT_" [translateParams]="{amount: profile.info.connection_count}"></span>
              </div>
              <div class="col-sm-6" >
                <p *ngIf="profile.info.status_count">
                  <i class="fa fa-comment-o"></i> <span translate="UPDATES__AMOUNT_" [translateParams]="{amount: profile.info.status_count}"></span>
                </p>
              </div>
            </div>
            <div
              class="row"
              *ngIf="profile.info.extra?.is_page">
              <p class="col-xs-12">
                <i class="fa fa-comment"></i>
                <a
                  href="javascript:;"
                  [ssiTooltip]="'USE_THIS_TAG_TO_MENTION_THIS_PAGE_WHEN_PUBLISHING' | translate"
                  translate="MENTION_TAG__VALUE_"
                  [translateParams]="{value: profile.info.extra.mention_tag}"
                  [ssiTriggerPublish]="{create: {
                    text: profile.info.extra.mention_tag + ' ',
                    accounts: [profile.account]
                  }}"
                  (click)="close()">
                </a>
              </p>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ng-container *ngIf="profile.support.follow">
                  <i class="fa fa-compress"></i>
                  <span translate="RELATIONSHIP"></span>:
                  <span
                    translate="_PROFILENAME__IS_FOLLOWING_YOU"
                    [translateParams]="{profileName: profile.info.name}"
                    *ngIf="profile.info.relationship.followed_by">
                  </span>
                  <span
                    [innerHTML]="'_PROFILENAME__IS_STRONGNOTSTRONG_FOLLOWING_YOU' | translate: { profileName: profile.info.name }"
                    *ngIf="!profile.info.relationship.followed_by">
                  </span>
                  -
                  <button
                    class="btn btn-danger btn-xs"
                    *ngIf="profile.info.relationship.following"
                    (click)="profile.unfollow()"
                    translate="UNFOLLOW">
                  </button>
                  <button
                    class="btn btn-success btn-xs"
                    *ngIf="!profile.info.relationship.following"
                    (click)="profile.follow()"
                    translate="FOLLOW">
                  </button>
                  -
                </ng-container>

                <button
                  *ngIf="profile.support.block && !profile.info.blocked"
                  class="btn btn-danger btn-xs"
                  (click)="blockProfileModal()">
                  {{ 'BLOCK' | translate }}
                </button>
                <button
                  *ngIf="profile.support.block && profile.info.blocked"
                  class="btn btn-danger btn-xs"
                  (click)="unblockProfileModal()">
                  {{ 'UNBLOCK' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <br>
        <ssi-profile-inbox-stats
          [profile]="profile"
          (onViewEngagements)="profileTabs.select('tabInteractions')">
        </ssi-profile-inbox-stats>

        <div
          class="row text-center"
          *ngIf="profile.support.posts && !profile.feed">
          <div class="col-xs-4"><hr></div>
          <div class="col-xs-4">
            <button
              *ngIf="!company?.feature_flags.includes('HIDE_OLD_MONITORING')"
              (click)="loadingTracker.add(profile.getFeed())"
              class="btn btn-sm btn-primary btn-block">
              <i class="fa fa-list-ul"></i> <span translate="LOAD_FEED"></span>
            </button>
          </div>
          <div class="col-xs-4"><hr/></div>
        </div>

        <hr *ngIf="profile.feed">

        <ssi-social-post
          *ngFor="let post of profile.feed | unique: 'id'"
          [post]="post"
          [author]="profile">
        </ssi-social-post>

        <div *ngIf="profile.feedNextPage" class="clearfix text-center spacer-bottom">
          <div *ngIf="loadingMoreTracker.active" class="loading"></div>
          <div class="col-xs-offset-4 col-xs-4">
            <button
              class="btn btn-sm btn-primary btn-block"
              (click)="loadingMoreTracker.add(profile.getFeedNextPage(profile.feedNextPage))"
              [disabled]="loadingMoreTracker.active"
            >Load More Posts</button>
          </div>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab id="tabInfo">
      <ng-template ngbTabTitle>
        <i class="fa fa-info"></i> <span translate="INFO"></span>
      </ng-template>
      <ng-template ngbTabContent>
        <label><i class="fa fa-tag"></i> <span translate="PROFILE_TAGS"></span></label>
        <p *ngIf="profile.tags.length < 0" class="text-center" translate="NO_TAGS_ADDED_TO_THIS_PROFILE_YET"></p>

        <ssi-crm-person-tags [crmPerson]="crmPerson"></ssi-crm-person-tags>

        <br><br>

        <label>
          <i class="fa fa-pencil-square-o"></i> <span translate="PROFILE_NOTES"></span>
        </label>

        <ssi-crm-person-notes [crmPerson]="crmPerson"></ssi-crm-person-notes>
      </ng-template>
    </ngb-tab>

    <ngb-tab id="tabInteractions">
      <ng-template ngbTabTitle>
        <i class="fa fa-comment"></i> <span translate="INTERACTIONS"></span>
      </ng-template>
      <ng-template ngbTabContent>
        <p
          class="text-center"
          *ngIf="!profile.pastActivities || !profile.pastActivities.length"
          translate="SORRY_THERE_HAVE_BEEN_NO_RECORDED_COMMUNICATIONS_BETWEEN_YOU_AND_THIS_USER_YET">
        </p>
        <hr/>
        <div *ngIf="profile.pastActivities && profile.pastActivities.length">
          <div class="status" *ngFor="let activity of profile.pastActivities | unique: 'id'">
            <ssi-activity [activity]="activity"></ssi-activity>
          </div>
          <div class="text-center">
            <button
              class="btn btn-primary"
              (click)="loadingTracker.add(profile.getPastActivity(profile.pastActivities.length))"
              translate="LOAD_MORE">
            </button>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    
    <ngb-tab
      id="tabStatistics"
      *ngIf="profile.support.stats && !company?.feature_flags.includes('HIDE_OLD_MONITORING')"
      >
      <ng-template ngbTabTitle>
        <i class="fa fa-bar-chart-o"></i> <span translate="STATISTICS"></span>
      </ng-template>
      <ng-template ngbTabContent>
        <div *ngIf="profile.statistics">
          <div>

            <h3 class="page-header"><i class="fa fa-trophy"></i> <span translate="KEY_STATS"></span></h3>
            <div class="row text-center">

              <div class="col-xs-4">
                <div class="statcard padding-sides-none">
                  <h3 class="statcard-number">
                    {{ profile.statistics.count }}
                  </h3>
                  <span class="statcard-desc" translate="TOTAL_STATUSES_ANALYSED"></span>
                </div>
              </div>

              <div class="col-xs-4">
                <div class="statcard padding-sides-none">
                  <h3 class="statcard-number">
                    {{ profile.statistics.like_count / (profile.statistics.count - profile.statistics.shared_count) | number: '1.0-3' }}
                  </h3>
                  <span class="statcard-desc" translate="AVG_LIKES_PER_STATUS"></span>
                </div>
              </div>

              <div class="col-xs-4">
                <div class="statcard padding-sides-none">
                  <h3 class="statcard-number">
                    {{ profile.statistics.share_count / (profile.statistics.count - profile.statistics.shared_count) | number: '1.0-3' }}
                  </h3>
                  <span class="statcard-desc" translate="AVG_SHARES_PER_STATUS"></span>
                </div>
              </div>

            </div>

            <div class="row text-center">
              <div class="col-xs-6 col-xs-offset-3">
                <ssi-gauge-chart class="gauge-chart-green">
                  <mwl-gauge
                    [min]="0"
                    [max]="100"
                    [dialStartAngle]="-90"
                    [dialEndAngle]="-90.001"
                    [showValue]="false"
                    [value]="profile.statistics.shared_count/(profile.statistics.count) * 100"
                    >
                  </mwl-gauge>
                  <span class="mwl-gauge-label mwl-gauge-label-large">
                    {{profile.statistics.shared_count/(profile.statistics.count) * 100 | number: '1.0-0'}}%
                  </span>
                </ssi-gauge-chart>
                <h3 style="margin: 0">
                  {{profile.statistics.shared_count/(profile.statistics.count) * 100 | number: '1.0-2'}}%
                </h3>
                <small
                  class="text-muted text-uppercase"
                  translate="PERCENT_SHARED_FROM_OTHER_USERS">
                </small>
              </div>
            </div>

            <br>

            <h3 class="page-header"><i class="fa fa-map-marker"></i> <span translate="LOCALITY_INFO"></span></h3>
            <p *ngIf="profile.statistics.coords.length > 0">
              <img [src]="mapUrl" class="img img-responsive img-polaroid">
            </p>
            <p
              *ngIf="profile.statistics.coords.length === 0"
              class="text-center"
              translate="THIS_USER_DOES_NOT_GEOGRAPHICALLY_TAG_MESSAGES">
            </p>
            <br/>

            <div *ngIf="profile.statistics?.tags.total">
              <h3 class="page-header"><strong>#</strong> <span translate="FREQUENT_HASHTAGS"></span></h3>
              <h5 class="text-muted" translate="TOP_HASHTAGS"></h5>
              <div class="row text-center">
                <div
                  class="col-xs-4"
                  *ngFor="let tag of profile.statistics.tags.top"
                  [ngClass]="{
                    'col-xs-offset-4': profile.statistics.tags.top.length === 1,
                    'col-xs-offset-1': profile.statistics.tags.top.length === 2
                  }">
                  <ssi-gauge-chart class="gauge-chart-small gauge-chart-orange">
                    <mwl-gauge
                      [min]="0"
                      [max]="100"
                      [dialStartAngle]="-90"
                      [dialEndAngle]="-90.001"
                      [showValue]="false"
                      [value]="tag.count / profile.statistics.tags.total * 100"
                      >
                    </mwl-gauge>
                    <span class="mwl-gauge-label">
                      {{tag.count / profile.statistics.tags.total * 100 | number: '1.0-0'}}%
                    </span>
                  </ssi-gauge-chart>
                  <h5 class="text-primary">{{ tag.label }}</h5>
                  <small class="text-muted">
                    {{tag.count}} {{tag.count == 1 ? 'use' : 'uses'}}
                  </small>
                </div>
              </div>
              <div *ngIf="profile.statistics.tags.remaining.length > 0">
                <br>
                <h5 class="text-muted" translate="OTHER_COMMONLY_USED_HASHTAGS"></h5>
                <span *ngFor="let tag of profile.statistics.tags.remaining" class="inline-block">
                  <span class="label label-primary"> {{ tag.label }} (x{{ tag.count }})</span>
                </span>
              </div>
              <br><br>
            </div>

            <div *ngIf="profile.statistics?.mentions.total > 0">
              <h3 class="page-header"><i class="fa fa-users"></i> <span translate="FREQUENT_USERS_MENTIONED"></span></h3>
              <h5 class="text-muted" translate="TOP_MENTIONS"></h5>
              <div class="row text-center">
                <div
                  class="col-xs-4"
                  *ngFor="let tag of profile.statistics.mentions.top"
                  [ngClass]="{
                    'col-xs-offset-4': profile.statistics.mentions.top.length === 1,
                    'col-xs-offset-1': profile.statistics.mentions.top.length === 2
                  }">
                  <ssi-gauge-chart class="gauge-chart-small gauge-chart-blue">
                    <mwl-gauge
                      [min]="0"
                      [max]="100"
                      [dialStartAngle]="-90"
                      [dialEndAngle]="-90.001"
                      [showValue]="false"
                      [value]="tag.count / profile.statistics.mentions.total * 100"
                      >
                    </mwl-gauge>
                    <span class="mwl-gauge-label">
                      {{tag.count / profile.statistics.mentions.total * 100 | number: '1.0-0'}}%
                    </span>
                  </ssi-gauge-chart>
                  <h5 class="text-primary">{{ tag.label }}</h5>
                  <small class="text-muted">
                    {{tag.count}} {{tag.count == 1 ? 'mention' : 'mentions'}}
                  </small>
                </div>
              </div>
              <div *ngIf="profile.statistics.mentions.remaining.length > 0">
                <br>
                <h5 class="text-muted" translate="OTHER_COMMONLY_USED_MENTIONS"></h5>
                <span *ngFor="let mention of profile.statistics.mentions.remaining" class="inline-block">
                  <span class="label label-primary"> {{ mention.label }} (x{{ mention.count }})</span>
                </span>
              </div>
              <br><br>
            </div>

            <h3 class="page-header">
              <i class="fa fa-cloud"></i> <span translate="WORDCLOUD"></span>
            </h3>
            <span *ngFor="let item of profile.statistics.words | keyvalue" class="inline-block">
              <span class="label label-primary">{{item.key}} x{{item.value}}</span>&nbsp;
            </span>
            <br><br>

            <br>
            <h3 class="page-header"><i class="fa fa-camera-retro"></i> <span translate="MEDIA_TYPES"></span></h3>
            <ssi-pie-chart
              [seriesData]="mediaTypesChart"
              [innerSize]="'60%'"
              [name]="'MEDIA_TYPE' | translate">
            </ssi-pie-chart>
          </div>
        </div>
        <div *ngIf="!profile.statistics">
          <h4 class="text-center text-muted" translate="SORRY_WE_ARE_STRUGGLING_TO_FETCH_THE_DATA_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER"></h4>
        </div>

      </ng-template>
    </ngb-tab>

  </ngb-tabset>

</div>

<div class="modal-footer">
  <button class="btn btn-primary pull-right" (click)="close()">
    <i class="fa fa-times"></i> <span translate="DONE"></span>
  </button>
</div>
