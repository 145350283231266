import './map.component.scss';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { GOOGLE_MAPS } from '../../constants';
import MarkerClusterer from '@googlemaps/markerclustererplus';
@Component({
  selector: 'ssi-map',
  templateUrl: './map.component.html'
})
export class MapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() center: google.maps.LatLng[];
  @Input() markers?: google.maps.LatLng[];
  @Input() zoom?: number;
  @Input() zoomOnClick?: boolean;
  @Input() circleOptions?: google.maps.CircleOptions;
  @Input() mapHeight?: number;
  @Input() styles?: google.maps.MapTypeStyle[] = [];
  @Input()
  restriction?: google.maps.MapRestriction = {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180
    },
    strictBounds: true
  };

  map: google.maps.Map;
  markerClusterer: any;

  constructor() {}

  ngOnInit() {
    this.styles = this.initMapStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markers && this.map) {
      this.drawMap();
    }
  }

  drawMap() {
    if (!this.markers) {
      return;
    }
    const markers = this.markers.map(
      (location: google.maps.LatLng) =>
        new google.maps.Marker({
          position: location,
          draggable: false,
          icon: GOOGLE_MAPS.mapMarkerImage
        })
    ) as any[];

    this.markerClusterer = new MarkerClusterer(this.map, markers, {
      imagePath: GOOGLE_MAPS.markerClusterImages,
      gridSize: 20,
      maxZoom: null,
      zoomOnClick: this.zoomOnClick,
      styles: [
        {
          textColor: '#fff',
          textSize: 12,
          fontWeight: '900',
          url: 'https://www.orlo.app/assets/v2/img/marker-cluster.png',
          height: 56,
          width: 56,
          anchorText: [23, 0]
        }
      ]
    });
  }

  onMapReady(map: google.maps.Map) {
    this.map = map;
    this.drawMap();
  }

  ngOnDestroy() {
    this.clearMarkers();
  }

  clearMarkers() {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
    }
  }

  initMapStyles(): google.maps.MapTypeStyle[] {
    const styledMapType: google.maps.MapTypeStyle[] = [
      { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c9b2a6' }]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#dcd2be' }]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#ae9e90' }]
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#93817c' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [{ color: '#a5b076' }]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#447530' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#f5f1e6' }]
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#fdfcf8' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#f8c967' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#e9bc62' }]
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{ color: '#e98d58' }]
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#db8555' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#806b63' }]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }]
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#8f7d77' }]
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#ebe3cd' }]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#B9D3C1' }]
      }
    ];
    return [...styledMapType, ...this.styles];
  }
}
