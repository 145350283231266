import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { TooltipWindowComponent } from './tooltip-window/tooltip-window.component';
import { DOCUMENT } from '@angular/common';
import { createPopper, Placement } from '@popperjs/core';

@Directive({
  selector: '[ssiTooltip]',
  exportAs: 'ssiTooltip'
})
export class TooltipDirective implements OnDestroy, OnChanges {
  @Input('ssiTooltipId') tooltipId: number;
  @Input('ssiTooltip') contents: string;
  @Input('tooltipPlacement') placement: Placement = 'top';
  @Input('tooltipAppendToBody') appendToBody: boolean;
  @Input('tooltipTitle') title: string;
  @Input('tooltipIconClass') iconClass: string;
  @Input('tooltipTrailingIconClass') trailingIconClass: string;
  @Input('tooltipDisabled') disabled = false;
  @Input('tooltipPositionFixed') positionFixed = false;
  @Input('tooltipDelay') delay = 0;
  @Input('tooltipHost') host: HTMLElement;
  @Input('tooltipTrigger') trigger = 'hover';
  @Input('tooltipTemplate') template: TemplateRef<any>;
  @Input('tooltipTemplateContext') templateContext: any;
  @Input('tooltipWindowClass') windowClass: string;
  @Input('tooltipOffset') offset: number[] = [];

  private tooltipFactory: ComponentFactory<TooltipWindowComponent>;
  private tooltipRef: ComponentRef<TooltipWindowComponent>;
  private popper;
  private showTimeout;

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private document
  ) {
    this.tooltipFactory = componentFactoryResolver.resolveComponentFactory(
      TooltipWindowComponent
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateTooltipInputs();
  }

  ngOnDestroy(): void {
    this.hide();
  }

  @HostListener('mouseenter')
  onMouseOver(): void {
    if (this.trigger === 'hover') {
      this.show();
    }
  }

  @HostListener('mouseleave')
  onMouseOut(): void {
    if (this.trigger === 'hover') {
      this.hide();
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this.trigger === 'click') {
      if (!this.tooltipRef) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  show(): void {
    const doShow = () => {
      this.tooltipRef = this.viewContainerRef.createComponent(
        this.tooltipFactory,
        0,
        this.injector,
        []
      );
      this.updateTooltipInputs();
      if (this.appendToBody) {
        this.document.body.appendChild(this.tooltipRef.location.nativeElement);
      }
      requestAnimationFrame(() => {
        this.positionTooltip();
      });
    };

    if (
      !this.tooltipRef &&
      (this.contents || this.template) &&
      !this.disabled
    ) {
      if (this.delay) {
        this.showTimeout = setTimeout(() => {
          doShow();
        }, this.delay);
      } else {
        doShow();
      }
    }
  }

  hide(): void {
    if (this.tooltipRef) {
      this.tooltipRef.location.nativeElement.style.display = 'none';
      this.viewContainerRef.remove(
        this.viewContainerRef.indexOf(this.tooltipRef.hostView)
      );
      this.tooltipRef = null;
    }
    if (this.popper) {
      this.popper.destroy();
    }
    if (this.showTimeout) {
      clearTimeout(this.showTimeout);
    }
  }

  private positionTooltip(): void {
    if (this.tooltipRef) {
      const tooltipElm: HTMLElement = this.tooltipRef.location.nativeElement;
      const hostElement = this.host || this.elementRef.nativeElement;

      this.popper = createPopper(hostElement, tooltipElm, {
        placement: this.placement,
        strategy: this.positionFixed ? 'fixed' : 'absolute',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: this.offset.length ? this.offset : [0, 11] // arrow
            }
          }
        ]
      });
    }
  }

  private updateTooltipInputs() {
    if (this.tooltipRef) {
      this.tooltipRef.instance.contents = this.contents;
      this.tooltipRef.instance.placement = this.placement;
      this.tooltipRef.instance.title = this.title;
      this.tooltipRef.instance.iconClass = this.iconClass;
      this.tooltipRef.instance.trailingIconClass = this.trailingIconClass;
      this.tooltipRef.instance.windowClass = this.windowClass;
      this.tooltipRef.instance.template = this.template;
      this.tooltipRef.instance.templateContext = this.templateContext;
    }
  }
}
