import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReportHeaderComponent } from './report-header.component';
import { HighchartControlsModule } from '../../../marketing/index/highchart-controls/highchart-controls.module';
@NgModule({
  imports: [CommonModule, FormsModule, HighchartControlsModule],
  declarations: [ReportHeaderComponent],
  exports: [ReportHeaderComponent]
})
export class ReportHeaderModule {}
