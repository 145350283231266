import { Injectable } from '@angular/core';
import {
  API,
  AccountModel,
  // OutboxModel,
  Account
} from '@ui-resources-angular';
// import { WorkflowManagerService } from '../../../../common/services/workflow-manager/workflow-manager.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { HighchartsHelperService } from '../../../../common/services/highcharts-helper/highcharts-helper.service';
// import { groupArray } from '../../../../common/util';

export interface DateRange {
  start: Date;
  end: Date;
}
export interface DateRanges {
  current: DateRange;
  previous: DateRange;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    protected api: API,
    // private workflowManager: WorkflowManagerService,
    private translate: TranslateService,
    private highchartsHelper: HighchartsHelperService,
    private accountModel: AccountModel // private outboxModel: OutboxModel,
  ) {}

  private _createSeries(primaryLabel, secondaryLabel, color = '#3d81c1') {
    return [
      {
        data: [],
        name: this.translate.instant(primaryLabel),
        color
      },
      {
        data: [],
        name: this.translate.instant(secondaryLabel),
        dashStyle: 'ShortDash',
        type: 'line',
        color,
        marker: {
          enabled: false
        }
      }
    ];
  }

  async loadReport(accounts: Account[], dateRanges: DateRanges) {
    const API_DATE_FORMAT = 'YYYY-MM-DD';
    const report: any = {};

    accounts =
      accounts.sort(
        (account1, account2) =>
          (account1.account_type_id as any) - (account2.account_type_id as any)
      ) || [];
    const accountIds = accounts.map((account) => account.id);

    const dateParams = {
      current: {
        start_date: moment(dateRanges.current.start).format(API_DATE_FORMAT),
        end_date: moment(dateRanges.current.end).format(API_DATE_FORMAT)
      },
      previous: {
        start_date: moment(dateRanges.previous.start).format(API_DATE_FORMAT),
        end_date: moment(dateRanges.previous.end)
          .subtract(1, 'day')
          .format(API_DATE_FORMAT)
      }
    };

    const charts = {
      clicks: this.highchartsHelper.generateChart({
        type: 'areaspline',
        series: this._createSeries(
          'TOTAL_CLICKS',
          'TOTAL_CLICKS_PREVIOUS_PERIOD'
        ),
        xAxis: {
          type: 'datetime'
        }
      }),
      reach: this.highchartsHelper.generateChart({
        type: 'areaspline',
        series: this._createSeries(
          'AVG_REACH_PER_DAY',
          'AVG_REACH_PER_DAY_PREVIOUS_PERIOD'
        ),
        xAxis: {
          type: 'datetime'
        }
      }),
      messages: this.highchartsHelper.generateChart({
        type: 'areaspline',
        series: [
          ...this._createSeries('MESSAGES_IN', 'MESSAGES_IN_PREVIOUS_PERIOD'),
          ...this._createSeries(
            'MESSAGES_OUT',
            'MESSAGES_OUT_PREVIOUS_PERIOD',
            '#4e47c0'
          )
        ],
        xAxis: { type: 'datetime' }
      })
    };

    const currentTotals = await this.api
      .post(
        'stats/accountStats',
        Object.assign(
          {
            account_ids: accountIds,
            with: ['totals']
          },
          dateParams.current
        )
      )
      .then(({ data }) => data);

    const previousTotals = await this.api
      .post(
        'stats/accountStats',
        Object.assign(
          {
            account_ids: accountIds,
            with: ['totals']
          },
          dateParams.previous
        )
      )
      .then(({ data }) => data);

    const transformTotals = (accountTotals) => {
      if (!accountTotals) {
        return {};
      }
      Object.keys(accountTotals).forEach((key) => {
        accountTotals[key] = parseFloat(accountTotals[key]);
        if (Number.isNaN(accountTotals[key])) {
          accountTotals[key] = 0;
        }
      });

      delete accountTotals.account_id;
      return accountTotals;
    };

    Object.assign(report, {
      range: {
        current: currentTotals.range,
        previous: previousTotals.range
      },
      totals: currentTotals.totals.map((currentAccountTotals) => {
        const accountId = +currentAccountTotals.account_id;
        return {
          account: this.accountModel.get(accountId),
          current: transformTotals(currentAccountTotals),
          previous: transformTotals(
            previousTotals.totals.find(
              (totals) => +totals.account_id === accountId
            )
          )
        };
      })
      // .filter((accountTotals) => !!accountTotals.account)
    });

    // const allTotals = {
    //   current: {},
    //   previous: {}
    // };

    // const totalsByAccountType = {};
    // const orderedTotals = orderByFilter(report.totals, [
    //   'account.account_type_name'
    // ]);

    // groupArray(
    //   report,
    //   (item) => item.account.account_type_name
    // ).forEach((groupedStats) => {
    //   totalsByAccountType[
    //     groupedStats.label
    //   ] = groupedStats.items.reduce((totalsSummed, accountTotals) => {
    //     ['current', 'previous'].forEach((totalTypeKey) => {
    //       if (!totalsSummed[totalTypeKey]) {
    //         totalsSummed[totalTypeKey] = {};
    //       }

    //       Object.entries(accountTotals[totalTypeKey]).forEach(
    //         ([statKey, amount]: [string, number]) => {
    //           if (!totalsSummed[totalTypeKey][statKey]) {
    //             totalsSummed[totalTypeKey][statKey] = 0;
    //           }
    //           amount = Math.round(amount);
    //           totalsSummed[totalTypeKey][statKey] += amount;
    //           allTotals[totalTypeKey][statKey] =
    //             allTotals[totalTypeKey][statKey] || 0;
    //           allTotals[totalTypeKey][statKey] += amount;
    //         }
    //       );
    //     });

    //     totalsSummed.totalAccounts = totalsSummed.totalAccounts || 0;
    //     totalsSummed.totalAccounts++;

    //     return totalsSummed;
    //   }, {});

    //   ['current', 'previous'].forEach((statType) => {
    //     const stats = totalsByAccountType[groupedStats.label][
    //       statType
    //     ];
    //   });
    // });

    return report;

    // if (withTopPosts) {
    //   const topPostsPromise = await this.api
    //     .post(
    //       'stats/account_v3',
    //       Object.assign(
    //         {
    //           account_ids: accountIds,
    //           with: ['top_posts']
    //         },
    //         dateParams.current
    //       )
    //     )
    //     .then(({ data }) => {
    //       const injectOutboxPost = (post) => {
    //         post.outbox = this.outboxModel.inject(post);
    //         return post;
    //       };

    //       report.top_posts = {
    //         by_clicks: data.top_posts.by_clicks.map(injectOutboxPost),
    //         by_reach: data.top_posts.by_reach.map(injectOutboxPost),
    //         by_impressions: data.top_posts.by_impressions.map(
    //           injectOutboxPost
    //         )
    //       };
    //     });
    // }

    // if (withClicks) {
    //   const clicksPromise = await this.api
    //     .post(
    //       'stats/account_v3',
    //       Object.assign(
    //         {
    //           account_ids: accountIds,
    //           with: ['clicks']
    //         },
    //         dateParams.current
    //       )
    //     )
    //     .then(({ data }) => {
    //       report.mapMarkers = [];
    //       data.clicks.forEach((location) => {
    //         for (let i = 0; i < +location.count; i++) {
    //           this.data.mapMarkers.push({
    //             lat: parseFloat(location.lat) + i / 100000,
    //             lng: parseFloat(location.lng)
    //           });
    //         }
    //       });
    //     });

    // }

    // if (withTimeSeries) {
    //   report.time_series = {
    //     total_clicks: {},
    //     total_reach: {},
    //     messages_in: {},
    //     messages_out: {}
    //   };

    //   const adjustedRange = {
    //     start: dateParams.current.start_date,
    //     end: dateParams.current.end_date
    //   };

    //   const timeSeriesCurrentPromise = await this.api
    //     .post(
    //       'stats/account_v3',
    //       Object.assign(
    //         {
    //           account_ids: accountIds,
    //           with: ['time_series']
    //         },
    //         dateParams.current
    //       )
    //     )
    //     .then(({ data }) => {
    //       Object.keys(data.time_series).forEach((timeSeriesKey) => {
    //         this.data.time_series[timeSeriesKey].current =
    //           data.time_series[timeSeriesKey];
    //       });

    //       charts.clicks.series[0].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.total_clicks,
    //         adjustedRange
    //       );

    //       charts.reach.series[0].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.total_reach,
    //         adjustedRange
    //       );

    //       charts.messages.series[0].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.messages_in,
    //         adjustedRange
    //       );

    //       charts.messages.series[2].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.messages_out,
    //         adjustedRange
    //       );
    //     });

    //   const timeSeriesPreviousPromise = await this.api
    //     .post(
    //       'stats/account_v3',
    //       Object.assign(
    //         {
    //           account_ids: accountIds,
    //           with: ['time_series']
    //         },
    //         dateParams.previous
    //       )
    //     )
    //     .then(({ data }) => {
    //       Object.keys(data.time_series).forEach((timeSeriesKey) => {
    //         Object.entries(data.time_series[timeSeriesKey].values).forEach(
    //           ([date, amount]) => {
    //             const daysDiff = moment(this.range.start).diff(
    //               moment(this.range.compareTo.start),
    //               'days'
    //             );
    //             data.time_series[timeSeriesKey].values[
    //               moment(date).add(daysDiff, 'days').format()
    //             ] = amount;
    //           }
    //         );
    //         this.data.time_series[timeSeriesKey].previous =
    //           data.time_series[timeSeriesKey];
    //       });

    //       charts.clicks.series[1].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.total_clicks,
    //         adjustedRange
    //       );

    //       charts.reach.series[1].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.total_reach,
    //         adjustedRange
    //       );

    //       charts.messages.series[1].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.messages_in,
    //         adjustedRange
    //       );

    //       charts.messages.series[3].data = this.highchartsHelper.apiIimeSeriesDataToHighcharts(
    //         data.time_series.messages_out,
    //         adjustedRange
    //       );
    //     });
    // }
  }

  createSpreadsheetExport(data) {
    return [
      {
        name: 'Account report',
        rows: data.totals.map((accountTotals) => {
          return [
            accountTotals.account.accountTypeLabel,
            accountTotals.account.displayName,
            accountTotals.account.username,
            accountTotals.current.connections,
            accountTotals.current.messages_in,
            accountTotals.current.messages_out,
            accountTotals.current.engagement_rate,
            accountTotals.current.positive_sentiment,
            accountTotals.current.semi_positive_sentiment,
            accountTotals.current.neutral_sentiment,
            accountTotals.current.semi_negative_sentiment,
            accountTotals.current.negative_sentiment,
            accountTotals.current.male_connections + '%',
            accountTotals.current.female_connections + '%',
            accountTotals.current.total_clicks_es,
            accountTotals.current.total_reach,
            accountTotals.current.total_impressions,
            accountTotals.current.klout_score,
            accountTotals.current.total_clicks
          ];
        }),
        header: [
          'Account type',
          'Account name',
          'Account username',
          'Connections',
          'Messages in',
          'Messages out',
          'Engagement rate',
          'Positive messages',
          'Semi positive messages',
          'Neutral messages',
          'Semi negative messages',
          'Negative messages',
          'Male connections',
          'Female connections',
          'Link clicks',
          'Total reach',
          'Total impressions'
        ]
      }
    ];
  }

  // persistToLocation() {
  //   $location.search({
  //     accounts: this.accounts.map((account) => account.id),
  //     start_date: this.range.start.toISOString(),
  //     end_date: this.range.end.toISOString(),
  //     workflow: this.workflowManager.getCurrentId(),
  //     compare_to: this.range.compareTo.comparator,
  //     previous_start_date: this.range.compareTo.start.toISOString(),
  //     previous_end_date: this.range.compareTo.end.toISOString()
  //   });
  // };
}
