<div
  class="dashboard-widget dashboard-widget-live-x-stream"
  [ngClass]="'view-' + viewClass"
>
  <div
    class="scroll-container"
    ssiVirtualScroll
    [items]="searchStreams"
    (scrolled)="loadMore()"
    [bufferAmount]="4"
    [getItemHeight]="getItemHeight"
    (viewPortItemsUpdated)="viewPortItemsUpdated($event)"
  >

    <ssi-stream-post
      *ngFor="let stream of viewPortItems"
      [stream]="stream"
      [accountId]="widget.apiSearchParams.account_id"
    >
    </ssi-stream-post>
  </div>
</div>