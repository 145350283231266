import { differenceInMinutes } from 'date-fns';

import strings from '../../constants/strings';
import { timestamp_to_date } from '../../helpers/datetimes';
import {
  ConversationOrigin,
  NewConversation
} from '../../interfaces/live-chat/new-conversation';
import { Agent } from '../../models/live-chat/agent';
import { Message } from '../../models/live-chat/message';

import Model from '../model';
import { Visitor } from './visitor';

// ----------------

const isDebug: boolean = false;

// ----------------

export class Conversation extends Model implements NewConversation {
  public agent: Agent = null;
  public visitor: Visitor = null;

  protected _data: NewConversation;

  private _applicationName: string;
  private _dateOfFirstMessage: Date;
  private _dateOfLastAgentMessage: Date;
  private _dateOfLastMessage: Date;
  private _dateOfLastVisitorMessage: Date;
  private _dateOfResolution: Date;
  private _firstMessage: Message;
  private _formattedResolutionTime: string;
  private _lastAgentMessage: Message;
  private _lastMessage: Message;
  private _lastVisitorMessage: Message;
  private _nameOfResolvingAgent: string;
  private _resolutionTimeInMinutes: number;
  private _visitorWaitingTime: Date;

  public get agentID(): string {
    return this.hasAgent ? this._data.agent.id : null;
  }

  public get agentName(): string {
    return this.hasAgent ? this._data.agent.displayName : strings.unassigned;
  }

  public get ageOfLastMessage(): Date {
    if (!this.lastMessage) {
      return;
    }

    return this.lastMessage.dateCreatedAt;
  }

  public get applicationId(): string {
    return this._data.application.id;
  }

  public get applicationName(): string {
    return this._applicationName;
  }

  public set applicationName(value: string) {
    this._applicationName = value;
  }

  public get assignmentStatus(): string {
    return this.isAssigned ? strings.assigned : strings.unassigned;
  }

  public get authorName(): string {
    if (this.visitor && this.visitor.displayName) {
      return this.visitor.displayName;
    }

    return '';
  }

  public get dateOfFirstMessage(): Date {
    return this._dateOfFirstMessage;
  }

  public get dateOfLastAgentMessage(): Date {
    return this._dateOfLastAgentMessage;
  }

  public get dateOfLastMessage(): Date {
    return this._dateOfLastMessage;
  }

  public get dateOfLastVisitorMessage(): Date {
    return this._dateOfLastVisitorMessage;
  }

  public get dateOfResolution(): Date {
    return this._dateOfResolution;
  }

  public get firstMessage(): Message {
    if (!this._firstMessage) {
      return;
    }

    return this._firstMessage;
  }

  public get formattedResolutionTime(): string {
    return this._formattedResolutionTime;
  }

  public get hasAgent(): boolean {
    return !!this._data.agent && !!this._data.agent.id;
  }

  public get hasAgentModel(): boolean {
    return !!this.agent && !!this.agent.id;
  }

  /*
  public get isAgentTyping(): boolean {
    return false;

    // @todo: differently track volatile user event state.
    // return !!this._data.agent.lastTypedAt;
  }
*/

  public get isArchived(): boolean {
    return !!this._data.archived;
  }

  public get isAssigned(): boolean {
    return this.hasAgent;
  }

  public get isResolved(): boolean {
    return !!this._data.resolution && !!this._data.resolution.resolvedAt;
  }

  /*
  public get isVisitorTyping(): boolean {
    return false;

    // @todo: differently track volatile user event state.
    // return !!this._data.visitor.lastTypedAt;
  }
  */

  public get lastAgentMessage(): Message {
    if (!this._lastAgentMessage) {
      return;
    }

    return this._lastAgentMessage;
  }

  public get lastMessage(): Message {
    if (!this._lastMessage) {
      return;
    }

    return this._lastMessage;
  }

  public get lastUrl(): string {
    if (
      !this._data.lastOrigin ||
      !this._data.lastOrigin.window ||
      !this._data.lastOrigin.window.location ||
      !this._data.lastOrigin.window.location.href
    ) {
      return;
    }

    return this._data.lastOrigin.window.location.href;
  }

  public get lastVisitorMessage(): Message {
    if (!this._lastVisitorMessage) {
      return;
    }

    return this._lastVisitorMessage;
  }

  public get nameOfResolvingAgent(): string {
    return this._nameOfResolvingAgent;
  }

  public get originUrl(): string {
    if (
      !this._data.origin ||
      !this._data.origin.window ||
      !this._data.origin.window.location ||
      !this._data.origin.window.location.href
    ) {
      return;
    }

    return this._data.origin.window.location.href;
  }

  public get preview() {
    return {
      relativeAge: this.visitorWaitingTime,
      text: this.lastMessage.text
    };
  }

  public get queuePosition(): number {
    if (!this._data.queue) {
      return;
    }

    return this._data.queue.position;
  }

  public get queueSize(): number {
    if (!this._data.queue) {
      return;
    }

    return this._data.queue.total;
  }

  public get resolution(): any {
    return this._data.resolution;
  }

  public get resolutionTimeInMinutes(): number {
    return this._resolutionTimeInMinutes;
  }

  public get visitorID(): string {
    return this._data.visitor ? this._data.visitor.id : undefined;
  }

  public get visitorName(): string {
    return this.visitor ? this.visitor.displayName : '';
  }

  public get visitorWaitingTime() {
    return this._visitorWaitingTime;
  }

  public setAgent(agent: Agent | null): Conversation {
    if (!agent) {
      this._data.agent = {
        id: null
      };
      this.agent = null;

      return;
    }

    this.agent = agent;

    this._data.agent = {
      id: agent.id
    };

    return this;
  }

  protected getFormattedResolutionTime(): string {
    if (isDebug) {
      console.log(`conversation~>getFormattedResolutionTime`);
    }

    // @todo use the get_relative_age_of_date helper here,
    // first updating to take an optional date to compare to,
    // and also a unit cap and/or set of permitted units.

    const totalMinutes = this.resolutionTimeInMinutes || 0;
    const minutesInDay = 1440;
    const days = Math.floor(totalMinutes / minutesInDay) || 0;
    const hours = Math.floor((totalMinutes % minutesInDay) / 60) || 0;
    const minutesSoFar = days * minutesInDay + hours * 60;
    const minutes = totalMinutes - minutesSoFar || 0;

    let duration = '';
    if (days >= 1) {
      duration += `${days} `;
      duration += days > 1 ? strings.days : strings.day;
    }

    if (hours >= 1) {
      if (duration.length) {
        duration += ', ';
      }

      duration += `${hours} `;
      duration += hours > 1 ? strings.hours : strings.hour;
    }

    if (minutes >= 1) {
      if (duration.length) {
        duration += ', ';
      }

      duration += `${minutes} `;
      duration += minutes > 1 ? strings.minutes : strings.minute;
    }

    if (!duration) {
      duration = 'under a minute';
    }

    return duration;
  }

  protected initialise() {
    try {
      if (isDebug) {
        console.log(`conversation~>initialise`);
      }

      this._visitorWaitingTime =
        timestamp_to_date(this._data.visitorWaitingTime) || new Date();

      if (this._data.firstMessage) {
        this._firstMessage = Message.CreateFromMessagePreview(
          this._data.firstMessage
        );
        this._dateOfFirstMessage = this.firstMessage.createdAt;
      }

      if (this._data.lastMessage) {
        this._lastMessage = Message.CreateFromMessagePreview(
          this._data.lastMessage
        );
        this._dateOfLastMessage = this.lastMessage.createdAt;
      }

      if (this._data.lastAgentMessage) {
        this._lastAgentMessage = Message.CreateFromMessagePreview(
          this._data.lastAgentMessage
        );
        this._dateOfLastAgentMessage = this.lastAgentMessage.createdAt;
      }

      if (this._data.lastVisitorMessage) {
        this._lastVisitorMessage = Message.CreateFromMessagePreview(
          this._data.lastVisitorMessage
        );
        this._dateOfLastVisitorMessage = this.lastVisitorMessage.createdAt;
      }

      this._dateOfResolution = !!this.isResolved
        ? timestamp_to_date(this._data.resolution.resolvedAt)
        : null;
      this._nameOfResolvingAgent = !this.isResolved
        ? '(not resolved)'
        : !!this.resolution.agent && !!this.resolution.agent.displayName
          ? this.resolution.agent.displayName
          : '(unknown)';

      this._resolutionTimeInMinutes = !!this.isResolved
        ? differenceInMinutes(this.dateOfResolution, this._dateOfFirstMessage)
        : null;
      this._formattedResolutionTime = this.getFormattedResolutionTime();

      return this;
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}
