import { objKeysMatch } from '../../../utils';

export class Colleague {
  id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  avatar_url: string;
  last_login: string;
  is_active: boolean;
  has_mobile_token: boolean;
  teams: number[];

  constructor(props: any = {}) {
    this.id = props.id;
    this.first_name = props.first_name;
    this.last_name = props.last_name;
    this.email_address = props.email_address;
    this.avatar_url = props.avatar_url;
    this.last_login = props.last_login;
    this.is_active = props.is_active;
    this.has_mobile_token = props.has_mobile_token;
    this.teams = props.teams;
  }

  get mentionAttribute() {
    return 'user-id';
  }
  // setter must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set mentionAttribute(val) {}

  /*
   * For `angular-mentions` to highlight colleague tag from the textarea. Search `[mention]=` in code for more
   */
  get mentionName() {
    return `${this.first_name}${this.last_name}`;
  }
  set mentionName(val) {}

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }
  set fullName(val) {}

  get name() {
    return this.fullName;
  }
  set name(val) {}

  /**
   * Checks whether the provided object is an instance of Team,
   * but in a way of checking if the object has a set of property keys defined on the Team class.
   *
   * Helpful in a situation where 'value instanceof Team' can't be used
   * (e.g. the actual instance of Team is re-mapped to plain JS object)
   */
  static is(value: any): boolean {
    return objKeysMatch(value, new Colleague());
  }
}
