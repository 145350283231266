import './widget-positive-negative-themes.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { findSentimentConst } from '../../../../../../common/constants';
import {
  FieldName,
  filtersFieldDefinitions,
  formatFilterTypeData
} from '../../constants/filters-field-definitions';
import { Filter } from '../../../reports/view/view-report.component';
import {
  InisightsClusterDocument,
  InsightsService,
  KPI
} from '../../../insights.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ClusterDocument,
  ClusteringStatusSource,
  DrilldownModalComponent
} from '../drilldown-modal/drilldown-modal.component';
import { Subject, Subscription, interval, merge, throwError } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SocketEventManagerService } from '../../../../../../common/services/sockets/socket-event-manager.service';

export interface ClusteringJob {
  clusteringObservables: any;
  stopConditions$: any;
  pollingDocumentStatus$: Subscription;
  clusteringDone$: Subject<any>;
  clusteringFailed$: Subject<any>;
  documentReady$: Subject<any>;
  clusteringJobFailed: boolean;
  loadingClusters: boolean;
  themes: string[];
}

export interface KpiData {
  label: string;
  val: string[];
}

@Component({
  selector: 'ssi-widget-positive-negative-themes',
  templateUrl: './widget-positive-negative-themes.component.html',
  styles: []
})
export class WidgetPositiveNegativeThemesComponent
  implements OnChanges, OnInit, OnDestroy {
  @Input() label: string;
  @Input() widget: KPI;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() widgetName: 'positive_themes' | 'negative_themes';

  @Output() loaded = new EventEmitter<void>();
  @Output() requiredRenderSize = new EventEmitter<{ w: number; h: number }>();
  @Output() loadingAgain = new EventEmitter<void>();

  themesData: Array<{
    openai_theme: string;
    color: string;
    size: number;
  }> = [];
  averageThemeSize: number;

  // clustering - web sockets - API polling
  clusteringJob: ClusteringJob = {
    clusteringObservables: undefined,
    stopConditions$: undefined,
    pollingDocumentStatus$: undefined,
    clusteringDone$: new Subject(),
    clusteringFailed$: new Subject(),
    documentReady$: new Subject(),
    clusteringJobFailed: false,
    loadingClusters: false,
    themes: []
  };

  onDestroy = new Subject();

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal,
    private ngZone: NgZone,
    private socketEventManagerService: SocketEventManagerService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters && changes.filters.currentValue) ||
      (changes.globalFilters && changes.globalFilters.currentValue)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    this.loadingAgain.emit();
    this.themesData = [];
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data, metadata }) => {
        console.log('Themes data raw:', data);
        const ticketName =
          this.widgetName === 'positive_themes'
            ? 'positive_ticket'
            : 'negative_ticket';
        this.getClustersData(metadata[ticketName]);
      });
  }

  private async getClustersData(ticket: string) {
    if (!ticket) {
      return;
    }

    this.clusteringJob.loadingClusters = true;
    this.clusteringJob.themes = [];

    this.clusteringJob.stopConditions$ = merge(
      this.clusteringJob.documentReady$,
      this.clusteringJob.clusteringFailed$,
      this.clusteringJob.clusteringDone$,
      this.onDestroy
    ).pipe(
      tap((s) => console.log(`Stop checking for ${this.widgetName} clusters`))
    );

    this.clusteringJob.clusteringObservables = merge(
      this.socketEventManagerService.clusteringDone.pipe(
        takeUntil(this.clusteringJob.stopConditions$),
        map((data) => ({ source: ClusteringStatusSource.clusteringDone, data }))
      ),
      this.socketEventManagerService.clusteringFailed.pipe(
        takeUntil(this.clusteringJob.stopConditions$),
        map((data) => ({
          source: ClusteringStatusSource.clusteringFailed,
          data
        }))
      ),
      interval(5000).pipe(
        takeUntil(this.clusteringJob.stopConditions$),
        startWith(0),
        switchMap(() => {
          console.log('Polling the ticket:', ticket);
          return this.insightsService.getInsightsClusterDocumentStatus(ticket);
        }),
        map((data) => ({ source: ClusteringStatusSource.apiPolling, data }))
      )
    );

    this.clusteringJob.pollingDocumentStatus$ = this.clusteringJob.clusteringObservables.subscribe(
      async (res) => {
        console.log('Document ready response:', res);
        if (
          (res.source === ClusteringStatusSource.apiPolling &&
            !res.data.completed) ||
          res.data.ticket !== ticket
        ) {
          return;
        }

        if (res.source === ClusteringStatusSource.clusteringFailed) {
          this.clusteringJob.clusteringFailed$.next();
          this.clusteringJob.loadingClusters = false;
          return throwError('WebSockets sent clusteringFailed event');
        }

        this.clusteringJob.documentReady$.next();
        const clusterDocument: InisightsClusterDocument[] = await this.insightsService.getInsightsClusterDocument(
          res.data.ticket
        );

        console.log(
          `clusterDocument ready for ${this.widgetName}:`,
          clusterDocument
        );
        if (clusterDocument && clusterDocument.length > 0) {
          this.themesData = clusterDocument.splice(0, 5).map((doc, index) => {
            return {
              ...doc,
              openai_theme: doc.openai_theme,
              color: this.widgetName === 'positive_themes' ? 'green' : 'red',
              size: doc.size,
              jobTicket: res.data.ticket
            };
          });
        }
        this.loaded.emit();

        this.ngZone.run(() => {
          this.clusteringJob.loadingClusters = false;
        });
      },
      (err) => {
        console.error('Error polling document status:', err);
        this.loaded.emit();

        this.ngZone.run(() => {
          this.clusteringJob.clusteringJobFailed = true;
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  openDrilldownModal(theme: any) {
    const filterType =
      filtersFieldDefinitions[FieldName.Sentiment].preferedFilterType;

    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });
    modal.componentInstance.clusterId = theme.id;
    modal.componentInstance.jobTicket = theme.jobTicket;
    modal.componentInstance.enableThemeClusters = false;
    modal.componentInstance.globalFilters = this.globalFilters;
    modal.componentInstance.streamIds = this.streamIds;
    modal.componentInstance.selectedFilters = [
      {
        field: FieldName.Sentiment,
        [filterType]: formatFilterTypeData(
          this.widgetName === 'positive_themes'
            ? 'Very Positive'
            : 'Very Negative',
          filterType
        )
      }
    ];
    modal.componentInstance.widgetFilters = this.widget.filters;
  }
}
