import './inbox-activity.component.scss';

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer2,
  OnDestroy
} from '@angular/core';
import {
  AccountModel,
  Activity,
  ActivityAuthor,
  ActivityThread,
  Conversation,
  ConversationStatus,
  CrmExternalIntegration,
  CrmExternalIntegrationModel,
  CrmExternalIntegrationPerson,
  CrmPerson,
  CrmPersonModel,
  Profile,
  ProfileModel,
  User
} from '@ui-resources-angular';
import { Subscription } from 'rxjs';
import { InboxMode } from '../../../modules/auth/inbox/inbox.component';
import { InboxPinnedActivitiesService } from '../inbox-pinned-activities/inbox-pinned-activities.service';
import { PluginService } from '../../services/plugin/plugin.service';
import { PopupService } from '../../services/popup/popup.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ActivityStatus } from '../action-pad/action-pad.component';
import { SENTIMENT_CONFIG } from '../../constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash-es';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { LocalStorageService } from 'angular-2-local-storage';
import { Plugin } from '../plugin/plugin.component';
import { InboxLinkExternalCrmModalComponent } from './inbox-crm-sidebar/inbox-link-external-crm-modal/inbox-link-external-crm-modal.component';
import { RealtimeSocketEvent } from '../../services/realtime-inbox-helper/realtime-inbox-helper.service';
import { RealtimeSocketEvent as RealtimeSocketConversationEvent } from '../../services/realtime-conversation-helper/realtime-conversation-helper.service';

export const PROFILE_LOAD_DEBOUNCE = 500;

export interface LinkedExternalIntegration {
  external: any;
  integration: CrmExternalIntegration;
}

@Component({
  selector: 'ssi-inbox-activity',
  templateUrl: './inbox-activity.component.html'
})
export class InboxActivityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activeActivity: Activity;
  @Input() pinnedActivities: Activity[];

  @Input() activity: Activity;
  @Input() conversation: Conversation;
  /**
   *   return of RealtimeInboxHelper.create()
   */
  @Input() realtimeHelper: any;
  @Input() realtimeConversationHelper: any;
  @Input() inboxMode: InboxMode;
  @Input() authUser?: User;
  @Input() hideArrowShortcuts?: boolean;
  @Input() userCanCreateNewActivityTags?: boolean;
  @Input() isPushMode?: boolean = false;
  @Input() isReply?: boolean = false;

  @Output() onClose = new EventEmitter<any>();
  @Output() onChangeActivity = new EventEmitter<any>();
  @Output() onReloadResults = new EventEmitter<any>();
  @Output() onShowFilters = new EventEmitter<any>();
  @Output() onChangeConversation = new EventEmitter<any>();
  @Output() onExitConversation = new EventEmitter<any>();
  @Output() onExitActivity = new EventEmitter<any>();
  @Output() onResolveConversation = new EventEmitter<any>();
  @Output() onHoldConversationStatusChanged = new EventEmitter<any>();

  hideProfileCrm: boolean;
  loadActivityProfile;
  windowResizeListener = () => {};
  profile: Profile;
  activityAction: string;
  activityThread: ActivityThread;
  previousActivityAction: string;
  crmPerson: CrmPerson;
  externalCrmPerson: CrmExternalIntegrationPerson;
  externalCrmIntegration: LinkedExternalIntegration;
  personLoadingTracker: AsyncTracker;
  profileSentiment;
  externalIntegrations: {
    linked: LinkedExternalIntegration[];
    unlinked: CrmExternalIntegration[];
  };
  linkedSocialProfiles: Array<{
    account_id: number;
    account_type_id: number;
    id: string;
    name: string;
    social_id: string;
  }>;
  plugins: Plugin[];
  initialisedPlugins = {};

  _initialisedPluginsSubscription: Subscription;
  _pluginConnectionSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private accountModel: AccountModel,
    private profileModel: ProfileModel,
    private inboxPinnedActivitiesService: InboxPinnedActivitiesService,
    private _renderer: Renderer2,
    private crmPersonModel: CrmPersonModel,
    private asyncTracker: AsyncTrackerFactory,
    private ngbModal: NgbModal,
    private crmExternalIntegrationModel: CrmExternalIntegrationModel,
    private plugin: PluginService,
    private popup: PopupService,
    private notificationService: NotificationService
  ) {
    this.hideProfileCrm = this.localStorage.get('hideInboxProfileCrm');
    this.personLoadingTracker = this.asyncTracker.create();

    this.loadActivityProfile = debounce((bypassCache) => {
      // if (!socialNetworkSettings[this.activity.account.account_type_name].profile.isViewable) {
      //   return;
      // }

      if (this.activity) {
        this.profile = null;
        this.crmPerson = null;

        const profileLoaded = this.profileModel
          .findByIdAndAccountId(
            this.activity.author.id,
            this.activity.account.id,
            true,
            !bypassCache
          )
          .then((profile) => {
            this.profile = profile;
            return profile;
          });

        const crmPersonLoaded = this.crmPersonModel
          .findOrCreateFromSocialProfile(
            this.activity.author,
            this.activity.account
          )
          .then((crmPerson) => {
            this.crmPerson = crmPerson;
            return crmPerson;
          });

        Promise.all([profileLoaded, crmPersonLoaded]).then(() => {
          this.crmExternalIntegrationModel.findAllActive().then(() => {
            this.updateLinkedCrms();
          });

          if (this.crmPerson instanceof CrmPerson) {
            this.crmPerson.getNotes();
          }

          const sentiment = {
            positive:
              this.profile.inbox_stats.positive_sentiment +
              this.profile.inbox_stats.semi_positive_sentiment,
            neutral: this.profile.inbox_stats.neutral_sentiment,
            negative:
              this.profile.inbox_stats.negative_sentiment +
              this.profile.inbox_stats.semi_negative_sentiment
          };

          let predominantSentiment = 'neutral';
          if (this.profile.inbox_stats.total > 0) {
            predominantSentiment = Object.entries(sentiment).reduce(
              ([previousKey, previousValue], [currentKey, currentValue]) => {
                if (currentValue > previousValue) {
                  return [currentKey, currentValue];
                } else {
                  return [previousKey, previousValue];
                }
              },
              [predominantSentiment, sentiment[predominantSentiment]]
            )[0];
          }

          this.profileSentiment = SENTIMENT_CONFIG[predominantSentiment];
          this.closeSelectedIntegration();
        });
      }
    }, PROFILE_LOAD_DEBOUNCE);

    this.windowResizeListener = this._renderer.listen(window, 'resize', (e) => {
      if (window.innerWidth > 1350 && this.activityAction === 'crm') {
        this.activityAction = null;
      }
      if (
        // ssi-screen-width-sm: 1350px;
        window.innerWidth >= 1350 &&
        !this.localStorage.get('hideInboxProfileCrm')
      ) {
        this.hideProfileCrm = false;
      } else {
        this.hideProfileCrm = true;
      }
    });

    this.plugin
      .fetchInstalledPlugins()
      .then(({ data }: { data: { plugins: Plugin[] } }) => {
        this.plugins = data.plugins;
      });

    this._initialisedPluginsSubscription = this.plugin
      .$initialisedPlugins()
      .subscribe(
        (plugins: {
          [key: number]: {
            actions: { callbackid: number; name: string; type: string }[];
            context: any;
          };
        }) => {
          console.log('plugins:', plugins);
          this.initialisedPlugins = plugins;
        }
      );

    this._pluginConnectionSubscription = this.plugin
      .$pluginConnectionObservable()
      .subscribe((pluginConnection) => {
        const { type } = pluginConnection;
        const { params } = pluginConnection;
        if (type === 'link') {
          this.crmPerson.plugins.push({
            plugin_id: params.pluginId,
            plugin_person_id: params.personPluginId
          });
        } else if (type === 'unlink') {
          this.crmPerson.plugins = this.crmPerson.plugins.filter(
            (linkedPlugin) => linkedPlugin.plugin_id !== params.pluginId
          );
        }
      });

    this.initialisedPlugins = this.plugin.getInitialisedPlugins();
  }

  async ngOnInit() {
    this.pinnedActivities = await this.inboxPinnedActivitiesService.restore();
  }

  ngOnChanges(changes) {
    if (changes.activity && this.realtimeHelper) {
      this.profile = null;
      this.activityAction = null;
      this.externalCrmPerson = null;
      this.externalCrmIntegration = null;
      if (this.activity) {
        if (this.activity.inbox && !this.activity.inbox.is_viewed) {
          this.activity.changeStatus(ActivityStatus.Unactioned);
        }
        this.realtimeHelper.setOpenActivity(this.activity);
        this.loadActivityProfile();
      } else {
        this.realtimeHelper.activityClosed();
      }
    }

    if (changes.conversation && this.realtimeConversationHelper) {
      if (this.conversation) {
        this.realtimeConversationHelper.setOpenConversation(this.conversation);
      } else {
        this.realtimeConversationHelper.conversationClosed();
      }
    }
  }

  toggleProfileCrm(inPlaceOfMessage: boolean) {
    if (inPlaceOfMessage) {
      this.activityAction = 'crm';
    } else {
      this.hideProfileCrm = !this.hideProfileCrm;
      this.localStorage.set('hideInboxProfileCrm', this.hideProfileCrm);
    }
  }

  loadResponses(activity: Activity) {
    activity.getResponses().then((responses) => {
      activity.conversation['response_objects'] = responses;
    });
  }

  onReplyTextChanged(isDefault: boolean, activity: Activity) {
    console.log('onReplyTextChanged:', isDefault, activity);
    const typingReply = !isDefault;
    this.realtimeHelper.meta.typingReplies =
      this.realtimeHelper.meta.typingReplies || [];
    const previousTypingReply = this.realtimeHelper.meta.typingReplies.includes(
      activity.id
    );
    if (typingReply !== previousTypingReply) {
      if (typingReply) {
        this.realtimeHelper.meta.typingReplies.push(activity.id);
      } else {
        this.realtimeHelper.meta.typingReplies = this.realtimeHelper.meta.typingReplies.filter(
          (id) => id !== activity.id
        );
      }
      this.realtimeHelper.emitActivityEvent(
        RealtimeSocketEvent.View,
        this.activity.id
      );

      this.realtimeConversationHelper.emitConversationEvent(
        RealtimeSocketConversationEvent.View,
        this.activity.id
      );
    }
  }

  linkIntegration(integration: CrmExternalIntegration) {
    console.log('integration:', integration);
    this.ngbModal
      .open({
        component: InboxLinkExternalCrmModalComponent,
        windowClass: 'modal-vertical',
        resolve: {
          externalIntegrations: (crmExternalIntegrationModel) => {
            'ngInject';
            return crmExternalIntegrationModel.findAllActive();
          },
          person: () => this.crmPerson,
          selectedIntegration: () => integration,
          socialProfile: () => this.profile,
          account: () => this.activity.account,
          activityProfile: () => this.activity.author,
          activity: () => this.activity
        }
      })
      .result.then((crmPerson) => {
        this.crmPerson = crmPerson;
        this.updateLinkedCrms();
      });
  }

  handlePluginCallback(type, callbackId, context) {
    try {
      if (
        !(
          !!this.crmPerson &&
          !!this.crmPerson.plugins &&
          Array.isArray(this.crmPerson.plugins)
        )
      ) {
        throw new Error(
          `value for 'inbox activity plugins' is not in the expected format.`
        );
      }

      const personLinkedInPlugin = this.crmPerson.plugins.find(
        (linkedPlugin) => linkedPlugin.plugin_id === context.nativeElement.id
      );

      switch (type) {
        case 'crm':
          if (personLinkedInPlugin) {
            this.plugin.trigger(
              {
                callbackId,
                params: {
                  orloCrmPersonUUID: this.crmPerson.uuid,
                  pluginPersonID: personLinkedInPlugin.plugin_person_id
                }
              },
              context.nativeElement.contentWindow
            );
          }
          break;
        case 'link':
          this.plugin.trigger(
            { callbackId, params: { orloCrmPersonUUID: this.crmPerson.uuid } },
            context.nativeElement.contentWindow
          );
          break;
        default:
          console.log(type);
          this.plugin.trigger(
            { callbackId, params: {} },
            context.nativeElement.contentWindow
          );
          break;
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  showExternalCrmPerson(integration: LinkedExternalIntegration) {
    this.externalCrmIntegration = integration;
    const promise = this.externalCrmIntegration.integration
      .getExternalPerson(integration.external)
      .then((externalCrmPerson) => {
        this.externalCrmPerson = externalCrmPerson;
      });
    this.personLoadingTracker.add(promise);
  }

  closeSelectedIntegration() {
    this.externalCrmIntegration = null;
    this.externalCrmPerson = null;
  }

  unlinkIntegration(integration: LinkedExternalIntegration) {
    this.crmPerson
      .unlinkExternalIntegration(integration.integration, integration.external)
      .then(() => {
        this.updateLinkedCrms();
      });
    this.closeSelectedIntegration();
  }

  expandExternalCrmPerson() {
    this.hideProfileCrm = true;
    this.activityAction = 'externalCrmPerson';
  }

  contractExternalCrmPerson() {
    this.hideProfileCrm = this.localStorage.get('hideInboxProfileCrm');
    this.activityAction = this.previousActivityAction;
  }

  onChangeActivityThread(activityThread: ActivityThread) {
    this.activityThread = activityThread;
  }

  showCollapsedViewExternalCrmPerson(integration: LinkedExternalIntegration) {
    this.previousActivityAction = this.activityAction;
    this.showExternalCrmPerson(integration);
    this.expandExternalCrmPerson();
  }

  public onResolve(opts: any): Promise<any> {
    console.log('onResolve:', opts);
    if (!this.conversation) {
      return;
    }

    return this.conversation
      .resolve(opts)
      .then(() => this.onResolveConversation.emit(opts));
    // might need to move confirmation modal bits from coversation resolve component to here
    // .then(() => {
    // if (this.conversation.survey_scheduled) {
    //   this.popup.alert({
    //     title: 'Conversation has been resolved & survey has been sent!',
    //     message:
    //       'Please remember that this account can only receive one survey during a 24-hour period.'
    //   });
    // } else {
    // this.showSuccessSnackbar(`Your conversation has been resolved`);
    // }
    // });
  }

  onHoldConversationStatusChange(opts: any): void {
    console.log('opts:', opts);
    if (
      !opts.skipConversationStatusCheck &&
      (this.conversation.status === ConversationStatus.Unread ||
        this.conversation.status === ConversationStatus.Unactioned)
    ) {
      this.showErrorSnackbar(
        'There are messages in this conversation that need to be actioned, before it can be put on hold.'
      );
      return;
    }

    this.conversation
      .changeOnHoldStatus(opts)
      .then(() => {
        const message = this.conversation.onHold
          ? `Your conversation has been put on hold. It will open again automatically when a new message is received.`
          : `Conversation has been pushed back to Social Push Mode queue`;
        this.showSuccessSnackbar(message);
      })
      .then((res) => {
        this.onHoldConversationStatusChanged.emit(opts);
      });
  }

  showSuccessSnackbar(message: string): void {
    this.notificationService.open(
      message,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      4000
    );
  }

  showErrorSnackbar(message: string): void {
    this.notificationService.open(
      message,
      {
        class: 'ssi ssi-error',
        color: '#be3609'
      },
      4000
    );
  }

  private updateLinkedCrms() {
    this.crmExternalIntegrationModel.findAllActive().then((integrations) => {
      const linkedIntegration = [];
      const linked = Array.isArray(this.crmPerson.external)
        ? this.crmPerson.external.map((external) => {
            const integration = this.crmExternalIntegrationModel.get(
              external.integration_uuid
            );
            linkedIntegration.push(integration);
            return {
              external,
              integration
            };
          })
        : [];
      const unlinked = integrations.filter(
        (integration) => !linkedIntegration.includes(integration)
      );
      this.externalIntegrations = {
        linked,
        unlinked
      };
      this.linkedSocialProfiles = [];
      if (
        this.crmPerson &&
        Array.isArray(this.crmPerson.profiles) &&
        this.profile &&
        this.profile.id
      ) {
        this.linkedSocialProfiles = this.crmPerson.profiles
          .map((profile) => {
            const profileClone: any = Object.assign({}, profile);
            profileClone.account = this.accountModel.get(profile.account_id);
            return profileClone;
          })
          .filter((profile) => !!profile.account)
          .filter((profile) => profile.social_id !== this.profile.id);
      }
    });
  }

  togglePinnedActivities(activity: Activity) {
    this.pinnedActivities = this.inboxPinnedActivitiesService.toggle(
      this.pinnedActivities,
      activity
    );
  }

  removePinnedActivity(activity: Activity) {
    this.pinnedActivities = this.inboxPinnedActivitiesService.remove(
      this.pinnedActivities,
      activity
    );
  }

  ngOnDestroy() {
    this.windowResizeListener();
    if (!!this._initialisedPluginsSubscription) {
      this._initialisedPluginsSubscription.unsubscribe();
    }

    if (!!this._pluginConnectionSubscription) {
      this._pluginConnectionSubscription.unsubscribe();
    }
  }
}
