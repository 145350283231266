import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmActionComponent } from './confirm-action.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OffClickModule } from '../../off-click/off-click.module';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    OffClickModule,
    OrloComponentsModule
  ],
  declarations: [ConfirmActionComponent],
  exports: [ConfirmActionComponent]
})
export class ConfirmActionModule {}
