import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '@ui-resources-angular';

import { Message } from '../../../../../../library/models/live-chat/message';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

@Component({
  selector: 'ssi-live-chat-note-form',
  templateUrl: './live-chat-note-form.component.html',
  styles: []
})
export class LiveChatNoteFormComponent implements OnInit {
  @Input() public isNoteFormFocused = false;
  @Input() public message: Message;
  @Input() public user: User;

  @Output()
  public isNoteFormFocusedChange: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output()
  public isResponseFocusedChange: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  public newNote: FormControl = new FormControl();

  private _isSavingNote = false;
  private _isValidNote = false;

  public get author(): string {
    if (!this.user) {
      return;
    }

    return this.user.fullName;
  }

  public get canSave(): boolean {
    return !!String(this.newNote.value).trim().length;
  }

  public get isSavingNote(): boolean {
    return this._isSavingNote;
  }

  public get isValidNote(): boolean {
    return this._isValidNote;
  }

  constructor(private liveChatService: LiveChatService) {}

  ngOnInit() {
    this.newNote.setValue(``);
  }

  public onBlurNoteForm(): void {
    this.isNoteFormFocusedChange.emit(false);
  }

  public onFocusNoteForm(): void {
    this.isNoteFormFocusedChange.emit(true);
  }

  public async save(): Promise<void> {
    const content = this.newNote.value;

    this._isSavingNote = true;

    try {
      const note = await this.liveChatService.addNoteOnMessage(
        content,
        this.message
      );

      this.message.notes.push(note);

      this.newNote.setValue('');
    } catch (error) {
      console.error(error);
    } finally {
      this._isSavingNote = false;
    }
  }
}
