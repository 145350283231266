<div class="organic-reach-container">

  <div class="section-head">
    <h2>Organic Reach</h2>
    <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
  </div>

  <div class="section-body"
       ssiLoader
       [ssiLoaderVisible]="loading">
    <ssi-highchart [config]="chartConfig"></ssi-highchart>
    <div class="total-reach-card-container"
         ssiTooltip="This gives you the average number of times per day your content has been seen by different people for content added across the whole account. X doesn't provide this figure on the native platform and therefore we're unable to provide this insight."
         tooltipWindowClass="tooltip-chunky-big">
      <ssi-total-stat-card class="section-grid-item"
                           icon="ssi ssi-reach"
                           iconSize="36"
                           iconColor="#D41D68"
                           iconBackgroundColor="rgba(212,29,104,0.1)"
                           label="Average Reach"
                           [value]="currentTotal | abs"
                           [growthValue]="statsToDisplay?.previous && (currentTotal - previousTotal) | abs"
                           [growth]="(currentTotal - previousTotal) >= 0"></ssi-total-stat-card>
    </div>
  </div>
</div>