import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { ComponentLibraryComponent } from './component-library.component';
import { ClDropdownSelectModule } from './cl-dropdown-select/cl-dropdown-select.module';
import { ClMenuModule } from './cl-menu/cl-menu.module';
import { ClContextMenuModule } from './cl-context-menu/cl-context-menu.module';
import { ClButtonModule } from './cl-button/cl-button.module';
import { ClPostSchedulerModule } from './cl-post-scheduler/cl-post-scheduler.module';
import { ClAccordionModule } from './cl-accordion/cl-accordion.module';
import { ClDateTimePickerModule } from './cl-date-time-picker/cl-date-time-picker.module';
import { ClConfirmationModalModule } from './cl-confirmation-modal/cl-confirmation-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.component-library',
          url: '/component-library',
          component: ComponentLibraryComponent,
          resolve: ComponentLibraryComponent.resolve
        }
      ]
    }),
    ClDropdownSelectModule,
    ClButtonModule,
    ClMenuModule,
    ClContextMenuModule,
    ClPostSchedulerModule,
    ClAccordionModule,
    ClDateTimePickerModule,
    ClConfirmationModalModule
  ],
  exports: [ComponentLibraryComponent],
  declarations: [ComponentLibraryComponent]
})
export class ComponentLibraryModule {}
