import { Component, Input } from '@angular/core';
import { User } from '@ui-resources-angular';
import { authUser } from '../../../common-resolves';
import { CompanyService } from '../../../../common/services/company/company.service';

export async function featureFlagsFn(company: CompanyService) {
  const companyConfig = await company.getConfig();
  return companyConfig.feature_flags;
}

@Component({
  selector: 'ssi-settings-index',
  templateUrl: './index.component.html'
})
export class IndexComponent {
  static resolve = [
    {
      token: 'featureFlags',
      resolveFn: featureFlagsFn,
      deps: [CompanyService]
    },
    authUser
  ];

  @Input() authUser: User;
  @Input() featureFlags;

  settingsIndex = {
    mySettings: [
      {
        link: 'auth.settings.mysettings.mydetails',
        header: 'My Details',
        text: 'Here you can pop in your name & email settings',
        icon: 'ssi ssi-mydetails',
        permission: null,
        flag: null
      },
      {
        link: 'auth.settings.mysettings.mypassword',
        header: 'My Password',
        text: 'Need to change your password? Click right here!',
        icon: 'ssi ssi-mypassword',
        permission: null,
        flag: null
      },
      {
        link: 'auth.settings.mysettings.mypreferences',
        header: 'My Preferences',
        text: 'All Orlo preferences, like email selections are in this section',
        icon: 'ssi ssi-mypreferences',
        permission: null,
        flag: null
      },
      {
        link: 'auth.settings.mysettings.loginactivity',
        header: 'Login Activity',
        text: 'View your login activity right here',
        icon: 'ssi ssi-loginactivity',
        permission: null,
        flag: null
      },
      {
        link: 'auth.notificationSettings',
        header: 'Notifications',
        text: 'Choose the notifications you want to see & when',
        icon: 'ssi ssi-notifications',
        permission: null,
        flag: null
      }
    ],
    accountSettings: [
      {
        link: 'auth.manageAccounts',
        header: 'Manage Social Accounts',
        text: 'Add new or delete existing accounts from Orlo',
        icon: 'ssi ssi-managesocialaccounts',
        permission: 'administer_accounts',
        flag: null
      },
      {
        link: 'auth.settings.accountsettings.livechatwidgets',
        header: 'Manage Live Chat Widgets',
        text: 'Amend your brand colours & chat view for your customers',
        icon: 'ssi ssi-livechat',
        permission: 'administer_accounts',
        flag: null
      },
      {
        link: 'auth.reAuthAccount',
        header: 'Re-Authenticate Accounts',
        text: 'Stay up to speed with social network updates',
        icon: 'ssi ssi-reauthaccounts',
        permission: 'administer_accounts',
        flag: null
      },
      {
        link: 'auth.blockingWords',
        header: 'Profanity Filter',
        text: 'Safeguard your company accounts from bad language',
        icon: 'ssi ssi-blockedwords',
        permission: 'administer_accounts',
        flag: null
      },
      {
        link: 'auth.manageSocialPolicy',
        header: 'Social Policy',
        text: `Setup your company's social policy for users to agree to`,
        icon: 'ssi ssi-socialpolicy',
        permission: 'administer_accounts',
        flag: null
      }
    ],
    companySettings: [
      {
        link: 'auth.manageUsers',
        header: 'Manage Users',
        text: 'Manage & specify what features in Orlo users can access',
        icon: 'ssi ssi-manageusers',
        permission: 'administer_users',
        flag: null
      },
      {
        link: 'auth.manageTeams',
        header: 'Company Teams',
        text: 'Create / edit teams & assign their permissions',
        icon: 'ssi ssi-companyteams',
        permission: 'administer_group_permissions',
        flag: null
      },
      {
        link: 'auth.settings.companysettings.publishSettings',
        header: 'Publish Settings',
        text: 'Set a custom vanity url & UTM link tracking defaults',
        icon: 'ssi ssi-publishsettings',
        permission: 'administer_company',
        flag: null
      },
      {
        link: 'auth.authenticationSettings',
        header: 'Authentication',
        text: 'Manage how users can log into the platform',
        icon: 'ssi ssi-authentication',
        permission: 'administer_company',
        flag: null
      },
      {
        link: 'auth.inboxSettings',
        header: 'Inbox Settings',
        text: 'Manage your Inbox features, such as snippets or tags here',
        icon: 'ssi ssi-inboxsettings',
        permission: 'administer_company',
        flag: null
      },
      {
        id: 'InboxPushMode',
        link: 'auth.settings.companysettings.socialPushMode',
        header: 'Inbox Push Mode',
        text: 'Manage push mode for social here',
        icon: 'ssi ssi-push-mode',
        permission: 'administer_company',
        flag: 'CONVERSATION_PUSH_MODE'
      },
      {
        link: 'auth.officeHours',
        header: 'Office Hours',
        text:
          'Set office hours, this helps calculate your Inbox response times',
        icon: 'ssi ssi-experience',
        permission: 'administer_company',
        flag: null
      },
      {
        link: 'auth.settings.companysettings.internationalization',
        header: 'Internationalization',
        text: 'Set your preferred company currency here',
        icon: 'ssi ssi-internationalization',
        permission: 'administer_company',
        flag: null
      },
      {
        link: 'auth.settings.companysettings.companylockdown',
        header: 'Company Lockdown',
        text: 'In an emergency, this will instantly stop any social activity',
        icon: 'ssi ssi-companylockdown',
        permission: 'administer_company',
        flag: null
      },
      {
        link: 'auth.settings.companysettings.manageWebhooks',
        header: 'Manage Webhooks',
        text: 'Send events from Orlo to a url of your choosing!',
        icon: 'ssi ssi-managewebhooks',
        permission: 'administer_company',
        flag: null
      },
      {
        link: 'auth.manageApps',
        header: 'Manage Apps',
        text: 'Manage all your external App integrations for Orlo here',
        icon: 'ssi ssi-manageapps',
        permission: 'administer_company',
        flag: null
      },
      {
        link: 'auth.settings.companysettings.surveySettings',
        header: 'Survey Settings',
        text: 'Manage all your external App integrations for Orlo here',
        icon: 'ssi ssi-survey',
        permission: 'administer_company',
        flag: 'SURVEYS'
      },
      {
        link: 'auth.settings.companysettings.chatbot',
        header: 'Chatbot',
        text: 'Create and manage chatbots and responses.',
        icon: 'ssi ssi-new-chat',
        permission: 'administer_company',
        flag: 'CHAT_BOT'
      },
      {
        link: 'auth.settings.companysettings.enableSocialChatbots',
        header: 'Enable Social Chatbots',
        text: 'Configure chatbots for your social accounts',
        icon: 'ssi ssi-chatbot',
        permission: 'administer_company',
        flag: 'CHAT_BOT'
      },
      {
        link: 'auth.settings.companysettings.automatedReports',
        header: 'Automated Reports',
        text: 'Manage your Analytics and Insights report automation',
        icon: 'ssi ssi-small-schedule',
        permission: 'administer_company',
        flag: 'SCHEDULED_REPORTS'
      }
    ]
  };

  constructor() {}

  checkPermission(permission) {
    return permission ? this.authUser.hasCompanyPermission(permission) : true;
  }

  checkFlag(flag) {
    return flag ? this.featureFlags.includes(flag) : true;
  }
}
