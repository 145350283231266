import { Pipe, PipeTransform } from '@angular/core';
import {
  Account,
  OutboxPublisherLink,
  OutboxPublisherMention
} from '@ui-resources-angular';
import twitterText, { MentionWithIndices, UrlWithIndices } from 'twitter-text';

@Pipe({
  name: 'outboxPostTextLinkify'
})
export class OutboxPostTextLinkifyPipe implements PipeTransform {
  transform(
    text: string,
    account: Account,
    links: OutboxPublisherLink[],
    mentions: OutboxPublisherMention[]
  ): any {
    function getUrlDisplayText(url: string) {
      return account.socialNetwork.publish.getUrlDisplayText
        ? account.socialNetwork.publish.getUrlDisplayText(url)
        : url;
    }

    const urlEntities = links.map((link) => {
      return {
        url: link.data.url,
        indices: [link.indices.start, link.indices.end]
      } as UrlWithIndices;
    });

    const hashtagEntities = account.socialNetwork.urlBases.hashtag
      ? twitterText.extractHashtagsWithIndices(text)
      : [];

    const mentionEntities = mentions.map((mention) => {
      return {
        screenName: `${mention.data.username}`,
        indices: [mention.indices.start, mention.indices.end]
      } as MentionWithIndices;
    });

    return twitterText.autoLinkEntities(
      text,
      [...urlEntities, ...hashtagEntities, ...mentionEntities],
      {
        targetBlank: true,
        suppressNoFollow: true,
        hashtagUrlBase: account.socialNetwork.urlBases.hashtag,
        usernameUrlBase: account.socialNetwork.urlBases.username,
        urlEntities: urlEntities
          .filter(
            (entity, index) =>
              links[index].data.shorten ||
              getUrlDisplayText(links[index].data.url) !== links[index].data.url
          )
          .map((entity, index) => {
            const link = links[index];
            const linkSuffix = `${
              link.data.prefix ? link.data.prefix + '_' : ''
            }${link.data.link || 'xxxxx'}`;
            const fullDisplayUrl = links[index].data.shorten
              ? `http://${account.default_vanity_domain}/${linkSuffix}`
              : links[index].data.url;

            return Object.assign({}, entity, {
              display_url: getUrlDisplayText(fullDisplayUrl),
              expanded_url: entity.url
            });
          })
      }
    );
  }
}
