import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// import { FlatpickrModule } from 'angularx-flatpickr';

import { ThemeDetectorCreateEditComponent } from './theme-detector-create-edit.component';

import { MinModule } from '../../../../../common/directives/min/min.module';
import { MaxModule } from '../../../../../common/directives/max/max.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { Checkbox2Module } from '../../../../../common/components/checkbox-2/checkbox-2.module';
import { ToggleSwitch2Module } from '../../../../../common/components/toggle-switch-2/toggle-switch-2.module';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../common/components/social-network-icon/social-network-icon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MinModule,
    MaxModule,
    TooltipModule,
    Checkbox2Module,
    ToggleSwitch2Module,
    DropdownSelect2Module,
    SocialNetworkIconModule
  ],
  exports: [ThemeDetectorCreateEditComponent],
  declarations: [ThemeDetectorCreateEditComponent]
})
export class ThemeDetectorCreateEditModule {}
