import './inbox-link-external-crm-modal.component.scss';
import { Component, OnInit, Input } from '@angular/core';
import { ActivityAuthor, Account, Activity } from '@ui-resources-angular';
import {
  CrmPersonModel,
  CrmPerson,
  CrmExternalIntegrationSearchResult,
  CrmExternalIntegrationPerson,
  CrmExternalIntegration
} from '@ui-resources-angular';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';

@Component({
  selector: 'ssi-inbox-link-external-crm-modal',
  templateUrl: './inbox-link-external-crm-modal.component.html'
})
export class InboxLinkExternalCrmModalComponent implements OnInit {
  loadingTracker: AsyncTracker;
  personLoadingTracker: AsyncTracker;
  searchText: string;
  resolve: {
    activityProfile: ActivityAuthor;
    selectedIntegration: CrmExternalIntegration;
    person: CrmPerson;
    account: Account;
    activity: Activity;
  };
  searchResults: CrmExternalIntegrationSearchResult[];
  selectedSearchResult: CrmExternalIntegrationSearchResult;
  externalIntegrationPerson: CrmExternalIntegrationPerson;
  close: (args: { $value: any }) => void;

  constructor(
    private promiseTracker: AsyncTrackerFactory,
    private crmPersonModel: CrmPersonModel
  ) {}

  ngOnInit() {
    this.loadingTracker = this.promiseTracker.create();
    this.personLoadingTracker = this.promiseTracker.create({
      activationDelay: 200
    });

    this.searchText = this.resolve.activityProfile.name;
    this.searchExternalIntegration(this.searchText);
  }

  searchExternalIntegration(query: string) {
    const promise = this.resolve.selectedIntegration
      .searchPeople(query)
      .then((searchResults) => {
        this.searchResults = searchResults;
        if (searchResults.length > 0) {
          this.selectExternalIntegrationPerson(searchResults[0]);
        }
      });

    this.loadingTracker.add(promise);
  }

  selectExternalIntegrationPerson(searchResult) {
    const promise = this.resolve.selectedIntegration
      .getExternalPerson(searchResult)
      .then((externalIntegrationPerson) => {
        this.externalIntegrationPerson = externalIntegrationPerson;
        this.selectedSearchResult = searchResult;
      });

    this.personLoadingTracker.add(promise);
  }

  linkPerson() {
    const socialProfileLinkedPerson = this.resolve.person;

    const promise = this.crmPersonModel
      .findFromExternalIntegration(
        this.resolve.selectedIntegration,
        this.selectedSearchResult
      )
      .then((externalIntegrationLinkedPerson) => {
        if (!socialProfileLinkedPerson) {
          if (externalIntegrationLinkedPerson) {
            return externalIntegrationLinkedPerson
              .linkSocialProfile(
                this.resolve.activityProfile,
                this.resolve.account
              )
              .then(() => {
                return externalIntegrationLinkedPerson;
              });
          } else {
            return this.crmPersonModel
              .createFromSocialProfile(
                this.resolve.activityProfile,
                this.resolve.account
              )
              .then((newPerson) => {
                return newPerson
                  .linkExternalIntegration(
                    this.resolve.selectedIntegration,
                    this.selectedSearchResult
                  )
                  .then(() => newPerson);
              });
          }
        } else if (externalIntegrationLinkedPerson) {
          return socialProfileLinkedPerson.mergeWith(
            externalIntegrationLinkedPerson
          );
        } else {
          return socialProfileLinkedPerson
            .linkExternalIntegration(
              this.resolve.selectedIntegration,
              this.selectedSearchResult
            )
            .then(() => {
              return socialProfileLinkedPerson;
            });
        }
      })
      .then((crmPerson) => {
        this.close({ $value: crmPerson });
      });

    this.loadingTracker.add(promise);
  }
}
