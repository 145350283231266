import './cl-post-scheduler.component.scss';

import { ConnectionPositionPair } from '@angular/cdk/overlay';

import { Component, OnInit } from '@angular/core';

import { AccountModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-cl-post-scheduler',
  templateUrl: './cl-post-scheduler.component.html',
  styles: []
})
export class ClPostSchedulerComponent implements OnInit {
  showPostScheduler = false;
  facebookFriendsnaps: any[];
  scheduledDate;

  constructor(private accountModel: AccountModel) {}

  async ngOnInit() {
    const accounts = await this.accountModel.findAccounts();
    this.facebookFriendsnaps = [
      accounts.find((account) => account.id === '18')
    ];
  }
}
