<div class="language-selector-container">
  <input
    #searchInput
    class="language-selector-input"
    type="text"
    [(ngModel)]="searchTerm"
    (ngModelChange)="filterValues(searchTerm)"
    placeholder="{{ (placeholder || 'TYPE_LANGUAGE') | translate }}...">
  <span
    *ngIf="canAdd && searchTerm && !itemValues.includes(searchTerm)"
    class="language-selector-input-action"
    (click)="onAdd.emit(searchTerm); filterValues(searchTerm)">
    + New
  </span>
  <ul class="language-selector-list custom-scrollbar">
    <li
      *ngFor="let language of filteredItems"
      class="language-selector-list-item"
      [ngClass]="{'language-selector-list-item-active': activeItems?.includes(language.value)}">
      <span class="language-selector-list-item-label" (click)="onChangeItem.emit(language.value)">
        {{ language.label }}
        <span *ngIf="activeItems?.includes(language.value)" class="ssi ssi-tick"></span>
      </span>
    </li>
  </ul>
</div>