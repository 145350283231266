import './widget-inbox-conversations.component.scss';

import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  Account,
  AccountModel,
  ActivityModel,
  CampaignModel,
  Conversation,
  UserModel
} from '@ui-resources-angular';
import {
  CampaignsService,
  TeamsService,
  ColleaguesService
} from '../../../../../../../common/services/api';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import {
  InboxConversationQuery,
  InboxQueryFactoryService
} from '../../../../../../../common/services/inbox-query-factory/inbox-query-factory.service';
import { CompanyService } from '../../../../../../../common/services/company/company.service';
import { InboxQueryResultListItem } from '../../../../../../../common/services/inbox-query-factory/queries/common';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import { ConversationPushModeService } from '../../../../../../../common/services/conversation-push-mode/conversation-push-mode.service';
import { LiveChatService } from '../../../../../../../common/services/live-chat/live-chat.service';
import { InboxActivityModalComponent } from '../../../../../../../common/components/inbox-activity/inbox-activity-modal/inbox-activity-modal.component';
import { ROW_HEIGHT } from '../../widgets-grid/widgets-grid.component';

@Component({
  selector: 'ssi-widget-inbox-conversations',
  templateUrl: './widget-inbox-conversations.component.html',
  styles: []
})
export class WidgetInboxConversationsComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() widget: DashboardWidget;
  @Output() loaded = new EventEmitter<boolean>();

  inboxQuery: InboxConversationQuery;
  containerHeight: number;
  recentlyOpenedConversation: Conversation;
  isLiveChatEnabled = false;
  isLiveChatActive = false;
  hasConversationPushModeEnabled = false;
  inboxActivityModal: NgbModalRef;

  protected destroyed$ = new Subject<void>();

  constructor(
    protected modal: NgbModal,
    protected injector: Injector,
    protected userModel: UserModel,
    protected accountModel: AccountModel,
    protected activityModel: ActivityModel,
    protected campaignModel: CampaignModel,
    protected companyService: CompanyService,
    protected teamsService: TeamsService,
    protected colleaguesService: ColleaguesService,
    protected campaignsService: CampaignsService,
    protected workflowManager: WorkflowManagerService,
    protected inboxQueryFactory: InboxQueryFactoryService,
    protected liveChatService: LiveChatService,
    protected conversationPushModeService: ConversationPushModeService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['widget'] && changes['widget'].currentValue) {
      this.containerHeight = this.widget.display_properties.h * ROW_HEIGHT - 48; // - widget header height
    }
  }

  async ngOnInit() {
    this.liveChatService.login();
    this.liveChatService.isEnabled$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((enabled) => {
        this.isLiveChatEnabled = enabled;
        this.isLiveChatActive = false; // this.isLiveChatEnabled;
      });

    this.conversationPushModeService
      .getCompanySettings()
      .then((socialPushSettings) => {
        this.hasConversationPushModeEnabled =
          socialPushSettings && socialPushSettings.enabled;
      });

    const [
      allAccounts,
      workflowAccounts,
      authUser,
      teams,
      colleagues,
      campaigns,
      companyConfig
    ] = await Promise.all([
      this.accountModel.findAccounts(),
      this.accountModel.findAllAccounts(this.workflowManager.getCurrentId()),
      this.userModel.getAuthUser(),
      this.teamsService.getAll(),
      this.colleaguesService.getAll(),
      this.campaignModel.findAll(),
      this.companyService.getConfig()
    ]);

    this.inboxQuery = this.inboxQueryFactory.createConversationQuery({
      allAccounts,
      authUser,
      colleagues,
      teams,
      savedQuery: JSON.parse(JSON.stringify(this.widget.apiSearchParams)), // keep the instance - for some reason it's being modified by the inbox query
      onSearch() {
        // console.log('onSearch...');
      }
    });

    const queryOverrides = JSON.parse(
      JSON.stringify(this.widget.apiSearchParams)
    );

    this.inboxQuery.search(0, 20, queryOverrides).then(() => {
      this.loaded.emit(true);
    });

    this.inboxQuery.activeResult.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: InboxQueryResultListItem) => {
        const conversation = value
          ? value.result.conversation
          : this.recentlyOpenedConversation;

        this.recentlyOpenedConversation = conversation;

        const inboxActivityQuery = this.inboxQueryFactory.createActivityQuery({
          allAccounts,
          authUser,
          colleagues,
          teams,
          campaigns,
          savedQuery: {
            root_thread_id: conversation.thread_id
          },
          onSearch() {}
        });

        inboxActivityQuery.search().then(() => {
          // console.log('results: ', inboxActivityQuery.result.list);
          // console.log('activities: ', inboxActivityQuery.result.activities);

          this.inboxActivityModal = this.modal.open(
            InboxActivityModalComponent,
            {
              windowClass: 'xxl-modal'
            }
          );
          this.inboxActivityModal.componentInstance.authUser = authUser;
          this.inboxActivityModal.componentInstance.activity =
            inboxActivityQuery.result.activities[0];
          this.inboxActivityModal.componentInstance.conversation = conversation;
          this.inboxActivityModal.componentInstance.onConversationListUpdated = this.onConversationListUpdated;
        });
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateLiveChatStatus(value: boolean) {
    // if (!value) {
    //   this.onExitLiveChat.emit();
    // }
    // this.isLiveChatActive = value;
  }

  onConversationListUpdated = (opts?: any): void => {
    // called when convo resolved, put on hold, or on reload results
    console.log('Convo list updated: ', opts);
    this.inboxActivityModal.close();
    setTimeout(() => {
      // wait 1s for the conversation status to be updated (socket event)
      this.inboxQuery.search();
    }, 1000);
  };
}
