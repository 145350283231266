import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-analytics',
  templateUrl: './analytics.component.html'
})
export class AnalyticsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
