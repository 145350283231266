import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SocialPostComponent } from './social-post.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TriggerPublishModule } from '../../directives/trigger-publish/trigger-publish.module';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownModule.forRoot(),
    TriggerPublishModule,
    ClickOutsideModule
  ],
  declarations: [SocialPostComponent],
  exports: [SocialPostComponent]
})
export class SocialPostModule {}
