import './widget-rss-feeds-config.component.scss';

import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { orderBy } from 'lodash-es';
import { fromEvent, merge, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Account,
  AccountModel,
  CampaignModel,
  UserModel
} from '@ui-resources-angular';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { ROW_HEIGHT } from '../../widgets-grid/widgets-grid.component';
import {
  ContentGeneratorService,
  Feed
} from '../../../../../../../modules/auth/marketing/content-generator/content-generator.service';

@Component({
  selector: 'ssi-widget-rss-feeds-config',
  templateUrl: './widget-rss-feeds-config.component.html',
  styles: []
})
export class WidgetRssFeedsConfigComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() widget: DashboardWidget;
  @Output() loaded = new EventEmitter<boolean>();
  @Output() onFormValidityChange = new EventEmitter<boolean>();

  widgetTitleMaxLength = 60;

  feeds: Feed[] = [];
  companyFeeds: Feed[] = [];
  accountFeeds: Feed[] = [];
  selectedAccountFeeds: Feed[] = [];
  selectedCompanyFeeds: Feed[] = [];

  containerHeight: number;
  protected destroyed$ = new Subject<void>();

  constructor(
    protected modal: NgbModal,
    protected injector: Injector,
    protected userModel: UserModel,
    protected accountModel: AccountModel,
    protected contentGeneratorService: ContentGeneratorService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['widget'] && changes['widget'].currentValue) {
      this.containerHeight = this.widget.display_properties.h * ROW_HEIGHT - 48; // - widget header height
    }
  }

  async ngOnInit() {
    this.loadFeeds();
    this.onFormValidityChange.emit(false);
  }

  async loadFeeds() {
    this.feeds = await this.contentGeneratorService.getRssFeed();
    this.feeds = orderBy(this.feeds, [(feed) => feed.name.toLowerCase()]);
    // console.log('RSS Feeds:', this.feeds);
    this.companyFeeds = this.feeds.filter((f) => f.company_id && !f.account_id);
    this.accountFeeds = this.feeds.filter((f) => f.account_id && !f.company_id);

    if (
      this.widget.apiSearchParams &&
      this.widget.apiSearchParams.hasOwnProperty('feedIds')
    ) {
      this.selectedAccountFeeds = this.widget.apiSearchParams.feedIds
        .map((feedId: number) => this.accountFeeds.find((f) => f.id === feedId))
        .filter((id: string) => id);

      this.selectedCompanyFeeds = this.widget.apiSearchParams.feedIds
        .map((feedId: number) => this.companyFeeds.find((f) => f.id === feedId))
        .filter((id: string) => id);
    } else {
      // TODO: set default feeds - empty array?
      // this.onSelectedAccountFeedsChange(this.selectedAccountFeeds);
      // this.onSelectedCompanyFeedsChange(this.selectedCompanyFeeds);
    }
  }

  onSelectedAccountFeedsChange(event: Feed[]) {
    this.widget.apiSearchParams = {
      ...this.widget.apiSearchParams,
      feedIds: [
        ...this.selectedAccountFeeds.map((f) => f.id),
        ...this.selectedCompanyFeeds.map((f) => f.id)
      ]
    };
    if (
      (this.selectedAccountFeeds && this.selectedAccountFeeds.length > 0) ||
      (this.selectedCompanyFeeds && this.selectedCompanyFeeds.length > 0)
    ) {
      this.onFormValidityChange.emit(true);
    } else {
      this.onFormValidityChange.emit(false);
    }
  }

  onSelectedCompanyFeedsChange(event: Feed[]) {
    this.widget.apiSearchParams = {
      ...this.widget.apiSearchParams,
      feedIds: [
        ...this.selectedAccountFeeds.map((f) => f.id),
        ...this.selectedCompanyFeeds.map((f) => f.id)
      ]
    };
    if (
      (this.selectedCompanyFeeds && this.selectedCompanyFeeds.length > 0) ||
      (this.selectedAccountFeeds && this.selectedAccountFeeds.length > 0)
    ) {
      this.onFormValidityChange.emit(true);
    } else {
      this.onFormValidityChange.emit(false);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
