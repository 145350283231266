import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Directive, OnInit, forwardRef, Input } from '@angular/core';

@Directive({
  selector: '[ssiMax]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxDirective, multi: true }
  ]
})
export class MaxDirective implements Validator, OnInit {
  @Input() ssiMax: number;

  constructor() {}

  ngOnInit() {}

  validate(c: FormControl) {
    const value = +c.value;

    const errorMessage = `Expected number equal to or lower than ${this.ssiMax}`;

    if (isNaN(value)) {
      return { max: true, message: errorMessage };
    }

    if (value > this.ssiMax) {
      return { max: true, message: errorMessage };
    }

    return null;
  }
}
