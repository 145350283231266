import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetectedThemesComponent } from './detected-themes.component';

import { TreetableModule } from '../../../../../common/components/treetable';
import { PaginatorModule } from '../../../../../common/components/paginator';
import { NoResultsModule } from '../../../../../common/components/no-results/no-results.module';

@NgModule({
  imports: [CommonModule, TreetableModule, PaginatorModule, NoResultsModule],
  exports: [DetectedThemesComponent],
  declarations: [DetectedThemesComponent]
})
export class DetectedThemesModule {}
