import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComposerCancelModalComponent } from './composer-cancel-modal.component';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [CommonModule, OrloComponentsModule],
  declarations: [ComposerCancelModalComponent],
  exports: [ComposerCancelModalComponent]
})
export class ComposerCancelModalModule {}
