import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TextEditorComponent } from './text-editor.component';

@NgModule({
  imports: [CommonModule, AngularEditorModule, FormsModule],
  declarations: [TextEditorComponent],
  exports: [TextEditorComponent]
})
export class TextEditorModule {}
