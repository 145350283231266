import './team-performance.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { SecondsToHumanTimePipe } from '../../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';
import { humanizeSeconds } from '../../../../../../common/utils';

@Component({
  selector: 'ssi-team-performance',
  templateUrl: './team-performance.component.html',
  styles: []
})
export class TeamPerformanceComponent implements OnInit {
  @Input() teamData;
  dataToDisplay: any[] = [];
  proTipOpen = false;

  constructor() {}

  ngOnInit() {
    this.dataToDisplay = this.teamData.map(
      ({
        team,
        avgFirstResponseTime,
        avgHandlingTime,
        avgResponseTime,
        ...data
      }) => {
        return {
          team,
          current: Object.assign(data, {
            avgFirstResponseTime,
            avgHandlingTime,
            avgResponseTime
          })
        };
      }
    );
  }

  isTimeField(field: string): boolean {
    return (
      field === 'current.avgHandlingTime' ||
      field === 'current.avgResponseTime' ||
      field === 'current.avgFirstResponseTime'
    );
  }

  secondsToHumanTime(seconds: number): string {
    return humanizeSeconds(seconds);
  }
}
