<div class="posts-container"
     [class.filters-bar-visible]="filtersBarVisible"
     [ssiObserveWidthAndHeight]="true"
     (onWidthChange)="onContainerWidthChange($event)">

   <div class="posts-wrapper"
        ssiLoader
        ssiLoaderText="Loading your search results..."
        [ssiLoaderVisible]="loading && (!initalLoading || !initialLoadingTemplate)">
      <div class="column"
           *ngIf="leftPosts.length">
         <div class="post-wrapper"
              *ngFor="let post of leftPosts; let index = index;">
            <ssi-insights-post [post]="post"
                               [selected]="isPostSelected(post)"
                               (toggleSelect)="onPostToggleSelect(post)"></ssi-insights-post>
         </div>
      </div>
      <div class="column"
           *ngIf="rightPosts.length">
         <div class="post-wrapper"
              *ngFor="let post of rightPosts; let index = index;">
            <ssi-insights-post [post]="post"
                               [selected]="isPostSelected(post)"
                               (toggleSelect)="onPostToggleSelect(post)"></ssi-insights-post>
         </div>
      </div>
   </div>

   <div class="no-posts-wrapper"
        *ngIf="!leftPosts.length && !rightPosts.length">
      <ssi-no-results *ngIf="!loading && activeFilters"
                      title="No results"
                      description="Maybe widen your keyword search or filters?"></ssi-no-results>

      <ng-container *ngIf="!loading && !activeFilters && beforeLoadTemplate">
         <ng-container *ngTemplateOutlet="beforeLoadTemplate"></ng-container>
      </ng-container>

      <ng-container *ngIf="loading && initalLoading && initialLoadingTemplate">
         <ng-container *ngTemplateOutlet="initialLoadingTemplate"></ng-container>
      </ng-container>
   </div>

   <div class="paginator-wrapper"
        [class.hidden]="getLoadedPostsCount() === 0">
      <ssi-paginator [itemsCount]="getLoadedPostsCount()"
                     [pageLinksSize]="5"
                     [itemsPerPage]="itemsPerPage"
                     [autoInit]="false"
                     [reInitOnChanges]="false"
                     (onPageChange)="onPageChange($event)"></ssi-paginator>
   </div>
</div>