import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { StateService } from '@uirouter/angular';

import { DeviceService } from '../device/device.service';

@Injectable()
export class RedirectService {
  constructor(
    private state: StateService,
    private location: Location,
    private device: DeviceService
  ) {}

  logout(): Promise<any> {
    if (
      !this.state.current.name ||
      this.state.current.name.startsWith('auth.') ||
      this.state.current.name === 'external'
    ) {
      return this.state.go('entry.landing.login', {
        returnTo: this.location.path()
      });
    } else {
      return Promise.resolve();
    }
  }

  login(): Promise<any> {
    return this.state.go(
      this.device.isMobile() ? 'auth.inbox' : 'auth.dashboard'
    );
  }
}
