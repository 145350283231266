<div class="inbox-sentiment-container">
  <div class="inbox-sentiment-pie-chart">
    <ssi-highchart [config]="pieChartConfig"></ssi-highchart>
  </div>
  <div class="sentiment-boxes">
    <ssi-fact-overview [label]="'INBOUND_MESSAGES' | translate"
                       [number]="stats.activity.period.current.inbound.count"
                       iconClass="ssi-inbound-messages-stat"
                       iconColor="#838EAB"
                       circleColor="transparent"
                       [iconSize]="'46px'"
                       width="100%"
                       [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_PUBLIC_COMMENTS_OR_MENTIONS_AND_PRIVATE_OR_DIRECT_MESSAGES_RECEIVED_EACH_DAY' | translate"
                       tooltipWindowClass="tooltip-chunky-big"
                       ></ssi-fact-overview>

    <ssi-sentiment-card *ngFor="let sentiment of sentimentsIterable"
                        [label]="'MESSAGES' | translate"
                        [sentiment]="sentiment"
                        [showStatChange]="stats.activity.period.previous"
                        [currentValue]="stats.activity.period.current.inbound.sentiment[sentiment.key]"
                        [previousValue]="stats.activity.period.previous?.inbound.sentiment[sentiment.key]"></ssi-sentiment-card>
  </div>
</div>