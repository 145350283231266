import './detected-theme-results.component.scss';

import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Filter } from '../../reports/view/view-report.component';

@Component({
  selector: 'ssi-detected-theme-results',
  templateUrl: './detected-theme-results.component.html',
  styles: []
})
export class DetectedThemeResultsComponent implements OnInit {
  @Input() activeFilters: Filter[] = [];
  @Input() accountIds: string[];
  @Input() streamIds: string[];
  @Input() sanitizeFilters = false;

  constructor(public modalInstance: NgbActiveModal) {}

  ngOnInit() {}
}
