<div
  class="list-select-option"
  [class.active]="isSelected">

  <a
    href="javascript:;"
    (click)="listSelect.optionClicked({isSelected: isSelected, value: value})">
    <ng-content></ng-content>
  </a>

  <span class="list-select-option-icon-outer">
    <i
      class="fa mouse-pointer"
      (click)="listSelect.optionClicked({isSelected: isSelected, value: value})"
      [ngClass]="isSelected ? iconSelected : iconNotSelected">
    </i>
  </span>

</div>
