import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent, OrloComponentsModule } from 'orlo-components';
import { LoaderModule } from '../../directives';
import { NoResultsModule } from '../no-results/no-results.module';

import { AuditEventModule } from '../audit-event/audit-event.module';
import { AuditComponent } from './audit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OrloComponentsModule,
    TranslateModule,
    LoaderModule,
    NoResultsModule,
    AuditEventModule
  ],
  exports: [AuditComponent],
  declarations: [AuditComponent]
})
export class AuditModule {}
