<header>
  <h1>
    <span translate="LIVE_CHAT"></span>
  </h1>

  <h2
    translate="TODAYS_TEAM_UPDATE_BOARD"
  ></h2>
</header>

<ssi-live-chat-team-statistics
  [agents]="agents"
  [quantityOfUnassignedConversations]="quantityOfUnassignedConversations"
  [quantityOfTotalConversations]="quantityOfTotalConversations"
  [quantityOfAssignedConversations]="quantityOfAssignedConversations"
  [stalestConversation]="stalestConversation"
  [teams]="teams"
></ssi-live-chat-team-statistics>

<ssi-live-chat-team
  [agents]="agents"
></ssi-live-chat-team>
