<div class="shared-post-preview-container"
     *ngIf="shareView && (shareView.text != 'Could not load; server error. Try again later?') else defaultShareViewLink">
  <a [href]="shareView.external_link"
     target="_blank"
     class="shared-post-container link-to-shared-post">

    <div class="metadata">
      <img [src]="shareView.author.avatar_url"
           ssiFallbackImage="brokenImage">

      <a class="name"
         [href]="shareView.external_link"
         target="_blank">{{shareView.author.name || 'Unknown'}}</a>

      <div class="icon-container">
        <ssi-social-network-icon [accountTypeId]="activity.account.account_type_id"
                                 variant="fa"></ssi-social-network-icon>
      </div>

      <div class="username">
        <span>@{{shareView.author.username}}</span>
      </div>

      <div class="timestamp">
        <span>{{createdAtTime}}</span> &nbsp;&nbsp;|&nbsp;&nbsp; <span>{{createdAtDate}}</span>
      </div>
    </div>

    <div class="content">
      <p [innerHTML]="shareView.text | linkify"></p>
    </div>
  </a>

  <a [href]="shareView.external_link"
     target="_blank"
     class="media-container link-to-shared-post"
     *ngIf="shareView.media.length">
    <div class="gallery">
      <ng-container *ngFor="let mediaItem of shareView.media | slice:0:2 | reverse; let i=index">
        <ng-container *ngTemplateOutlet="meidaItemTemplate; context: {mediaItem: mediaItem, mediaItemIndex: i }"></ng-container>
      </ng-container>

      <div class="media-items-count"
           *ngIf="shareView.media.length > 1">
        <span>{{shareView.media.length - 1}}+</span>
      </div>
    </div>

    <ng-template #meidaItemTemplate
                 let-mediaItem="mediaItem"
                 let-mediaItemIndex="mediaItemIndex">
      <img [src]="mediaItem.image_url"
           ssiFallbackImage="brokenImage"
           [class.stacked]="mediaItemIndex > 0"
           [style.top]="(mediaItemIndex * 7) + 'px'"
           [style.left]="(mediaItemIndex * 7) + 'px'">
    </ng-template>
  </a>
</div>
<ng-template #defaultShareViewLink>
  <p class="message-box-content">
    This shared content could not be loaded<br>
    <a href="{{ sharePreview.url }}"
       target="_blank">View post on Linked In</a>
  </p>
</ng-template>