<div class="form-group">
  <ng-container *ngFor="let filter of [{label: 'ACCOUNT_FEEDS', accountIdFilter: accountFilterFn}, {label: 'COMPANY_FEEDS', accountIdFilter: companyFilterFn}]; let last = last;">
    <ng-container *ngIf="(feeds | filter: filter.accountIdFilter).length">
      <label>
        {{ filter.label | translate }}
      </label>
      <ul class="cgf-list list-unstyled">
        <li *ngFor="let feed of (feeds | filter: filter.accountIdFilter) | sortBy: 'name'; trackBy: trackById">
          <ssi-checkbox-2
            name="feed.name"
            [label]="feed.name"
            [(ngModel)]="feed.isSelected"
            (ngModelChange)="feedChange(feed)"
          ></ssi-checkbox-2>
          <button
            class="btn btn-danger btn-xs"
            (click)="onDelete.emit(feed)"
          >
            <i class="fa fa-times"></i>
          </button>
        </li>
      </ul>
      <hr *ngIf="!last">
    </ng-container>
  </ng-container>
</div>
