<div class="panel panel-default">
  <div class="panel-heading">
    <h4 class="panel-title" translate="PRESET_FILTERS"></h4>
  </div>
  <div class="list-group">
    <a
      class="list-group-item"
      *ngFor="let preset of query.presets"
      [ngClass]="{active: preset === query.queryState.activePreset}"
      (click)="query.activatePreset(preset); onSelected.emit()">
      <i class="fa-fw" [ngClass]="preset.icon"></i> {{ preset.label | translate }}
    </a>
  </div>
</div>
