<div class="manage-ifttt-applet-service manage-ifttt-applet-stage">

  <div>
    <div class="manage-ifttt-applet-stage-header">
      <h2 class="manage-ifttt-applet-stage-header-title">Triggers</h2>
      <p class="manage-ifttt-applet-stage-header-sub-title">Customisation over your platform, First step, <em>choose your Platform Module:</em></p>
    </div>

    <div class="manage-ifttt-applet-stage-body">

      <div class="manage-ifttt-applet-service-item"
           *ngFor="let service of services"
           [uiSref]="service.name === 'monitoring.imported' ? '^.serviceSelected.streams' : '^.serviceSelected.accounts'"
           [uiParams]="{serviceName: service.name}">
        <h4>{{ service.translationIds.title | translate }}</h4>
        <span [ngClass]="service.brand.iconClass"
              class="manage-ifttt-applet-trigger-icon"></span>
      </div>

    </div>
  </div>

</div>