<div class="facebook-post-header">
  <img class="img-circle pull-left"
       [src]="account.picture"
       ssiFallbackImage="avatar">
  <div>
    <div>
      <a [href]="account.externalUrl"
         target="_blank">{{ account.name }}</a>
    </div>
    <div>
      <small *ngIf="post.schedules.length === 0">Published by Orlo &middot; Just now &middot; <i class="fa fa-globe"></i></small>
      <small *ngIf="post.schedules.length > 0">{{ post.schedules[0] | date:'d MMMM H:mm' }}</small>
    </div>
  </div>
</div>
<div class="facebook-post-body">
  <div
    *ngIf="!textWithoutGifUrl"
    class="facebook-post-text"
  >
    <span *ngIf="isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br | truncate: limit"></span>
    <span *ngIf="!isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br"></span>
    <span class="text-read-more" *ngIf="isTruncated" (click)="isTruncated = false; limit = text.length">Read More</span>
  </div>
  <div
    *ngIf="textWithoutGifUrl"
    class="facebook-post-text"
  >
    <span [ssiEmojiText]="textWithoutGifUrl | outboxPostTextLinkify:account:linksWithoutGifUrl:mentions | nl2br | truncate: limit"></span>
    <span class="text-read-more" *ngIf="isTruncated" (click)="isTruncated = false; limit = text.length">Read More</span>
  </div>
  <div
    *ngIf="files.length > 0 && ['image', 'gif'].includes(files[0].type)"
    class="collage-container"
    [class.collage-multiple]="files.length > 1"
  >
    <div
      class="facebook-post-image count-{{files.length}}"
      [class.more-than-two]="files.length > 2"
    >
      <img
        *ngFor="let file of files | slice: 0:4; let index = index"
        [src]="file.url"
        [class.has-counter]="index === 3"
      >
      <div class="image-counter" *ngIf="files.length > 4">
        +{{files.length - 4}}
      </div>
    </div>
  </div>

  <div class="facebook-post-video"
       *ngIf="files.length === 1 && files[0].type === 'video'">
    <video controls>
      <source [src]="files[0].url"
              type="video/mp4">
      Sorry but we could not load the video preview.
    </video>
  </div>

  <a *ngIf="preview && !files.length"
     [href]="preview.url"
     target="_blank"
     class="facebook-post-link-preview">
    <img [src]="preview.image">
    <div class="facebook-post-link-preview-footer">
      <div class="facebook-post-link-preview-title">
        {{ preview.title }}
      </div>
      <div class="facebook-post-link-preview-description">
        {{ preview.description }}
      </div>
      <div class="facebook-post-link-preview-hostname">
        {{ preview.hostname }}
      </div>
    </div>
  </a>

</div>
<div class="facebook-post-footer">
  <span class="facebook-post-footer-item"><i [class]="account.socialNetwork.socialInteractions.like.icon.web"></i> Like </span>
  <span class="facebook-post-footer-item"><i class="fa fa-comment-o"></i> Comment </span>
  <span class="facebook-post-footer-item"><i class="fa fa-share"></i> Share </span>
</div>