<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Social policy</h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>

  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-file"></i> <span translate="COMPANY_POLICY_SETTINGS"></span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <p translate="ADD_YOUR_COMPANYS_SOCIAL_POLICY_HERE_OR_USE_OUR_DEFAULT_POLICY_CAREFULLY_DRAFTED_TO_COVER_YOU_IN_MOST_CASES_WHEN_YOU_CHANGE_THE_POLICY_OR_USERS_SIGNIN_FOR_THE_FIRST_TIME_THEY_WILL_BE_ASKED_TO_AGREE_TO_YOUR_POLICY_BEFORE_CONTINUING"></p>
      <ssi-text-editor [(policy)]="socialPolicy.policy" [height]="'300'"></ssi-text-editor>
    </div>
    <div class="panel-footer text-right">
      <button (click)="socialPolicy.policy = socialPolicy.default_policy" class="btn btn-default">
        <i class="fa fa-plus"></i> <span translate="LOAD_DEFAULT_POLICY"></span>
      </button>
      <button (click)="socialPolicy.policy = ''; savePolicy()" class="btn btn-danger">
        <i class="fa fa-times"></i> <span translate="DISABLE_POLICY"></span>
      </button>
      <button class="btn btn-success" (click)="savePolicy()">
        <i class="fa fa-floppy-o"></i> <span translate="SAVE_POLICY"></span>
      </button>
    </div>
  </div>
</div>
