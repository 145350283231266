import { Component, OnInit } from '@angular/core';
import { AccountModel, API, Account } from '@ui-resources-angular';
import { PopupService } from '../../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncTrackerFactory } from 'angular-async-tracker';

@Component({
  selector: 'ssi-remove-account-modal',
  templateUrl: './remove-account-modal.component.html'
})
export class RemoveAccountModalComponent {
  account: Account;

  loadingTracker = this.asyncTracker.create();

  confirmationText: string;

  constructor(
    private api: API,
    private popup: PopupService,
    private accountModel: AccountModel,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private asyncTracker: AsyncTrackerFactory
  ) {}

  removeAccount() {
    const promise = this.api
      .del('account/index', { params: { account_id: this.account.id } })
      .then(() => {
        this.activeModal.close();
        this.popup.alert({
          title: this.translate.instant('ACCOUNT_REMOVED'),
          message: this.translate.instant(
            '_ACCOUNTNAME__WAS_REMOVED_SUCCESSFULLY',
            { accountName: this.account.displayName }
          )
        });
        this.accountModel.eject(this.account);
      });
    this.loadingTracker.add(promise);
  }
}
