<div class="marketing-analytics-build-container">
  <div class="head">
    <h3 translate="POST_APPROVALS_REPORT"></h3>
    <h2>Build your report</h2>
  </div>

  <div class="body">
    <div class="body-inner">
      <ssi-report-build-from
        *ngIf="validationTags"
        report="disapprovals"
        [showTagsSection]="true"
        [tags]="validationTags"
        [accountTypeIdsToExclude]="[16,6]"
        [formValue]="formValue"
        (formSubmit)="onFormSubmit($event)"
      ></ssi-report-build-from>
    </div>
  </div>
</div>