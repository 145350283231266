import { API, Model, Record } from '@ui-resources-angular';
import {
  IftttTriggerActionParams,
  TriggerConfig,
  ActionConfig
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import { serviceMappings } from '../service-mappings';
import { AppletCollection } from '../applets/applet-collection';
import { Applet } from '../applets/applet';
import { AppletCollection as APIAppletCollection } from '../applets/api-applet-interfaces';
import { merge, cloneDeep, flatten } from 'lodash-es';
import { IngredientConfig, IngredientType } from '../service-mappings/util';
import { Injectable } from '@angular/core';

export interface ActionParam {
  name: string;
  required: boolean;
  type: '@ActionParam';
  variableType: string;
}

export interface Action {
  type: '@Action';
  name: string;
  acceptForeignServices: boolean;
  onlyTriggeredOncePerEvent: boolean;
  params: ActionParam[];
  output_queue: string;
  deprecated?: boolean;
  deprecatedReplacementAction?: string;
}

export interface Ingredient {
  name: string;
  type: '@Ingredient';
  variableType: string;
}

export interface DeferredIngredient {
  type: '@DeferredIngredient';
  name: string;
  variableType: string;
  cost: number;
  remote_service: {
    type: '@RemoteService';
    endpoint_name: string;
    parameters: Array<{
      type: '@RemoteServiceParameter';
      name: string;
      value: IngredientReference;
    }>;
  };
}

export interface IngredientReference {
  type: '@IngredientReference';
  value: string;
}

export interface Trigger {
  type: '@Trigger';
  name: string;
  required: boolean;
  params: TriggerParam[];
  expression: BinaryExpression;
}

export interface TriggerParam {
  name: string;
  required: boolean;
  type: '@TriggerParam';
  variableType: string;
}

export interface BinaryExpression {
  type: '@BinaryExpression';
  left: Constant | IngredientReference | ParameterReference | FunctionCall;
  right: Constant | IngredientReference | ParameterReference | FunctionCall;
  op: 'EQ' | 'NEQ' | 'IN';
}

export interface Constant {
  type: '@Constant';
  variableType: string;
  value: boolean | string | null | string[] | number[];
}

export interface IngredientReference {
  type: '@IngredientReference';
  value: string;
}

export interface ParameterReference {
  type: '@ParameterReference';
  value: string;
}

export interface FunctionCall {
  type: '@FunctionCall';
  identifier: string;
  arguments: Array<Constant | IngredientReference | ParameterReference>;
}

export class IftttService extends Record {
  type: '@Service';
  name: string;
  description: string;
  ingredients: Array<Ingredient | DeferredIngredient>;
  triggers: Trigger[];
  mutually_exclusive_triggers: any;
  actions: Action[];

  get translationIds(): any {
    return getServiceMapping(this.name)
      ? getServiceMapping(this.name).translationIds
      : {};
  }

  get brand(): any {
    return getServiceMapping(this.name)
      ? getServiceMapping(this.name).brand
      : {};
  }

  getTriggers(): Promise<TriggerConfig[]> {
    return this.injector
      .get(IftttTriggerActionParams)
      .mapTriggers(this, getServiceMapping(this.name).triggers);
  }

  getExclusiveTriggers() {
    return this.mutually_exclusive_triggers
      ? this.mutually_exclusive_triggers[0].triggers
      : {};
  }

  async getActions(): Promise<ActionConfig[]> {
    const globalServices = await this.injector
      .get(IftttServiceModel)
      .findAllGlobal();

    const actionsPromises = [this, ...globalServices].map((service) =>
      this.injector
        .get(IftttTriggerActionParams)
        .mapActions(service, getServiceMapping(service.name).actions)
    );

    return flatten(await Promise.all(actionsPromises));
  }

  getIngredients(type: IngredientType): IngredientConfig[] {
    try {
      if (!(!!this.ingredients && Array.isArray(this.ingredients))) {
        throw new Error(
          `Value for 'ifttt service model ingredients' not in expected format.`
        );
      }

      return getServiceMapping(this.name)
        .ingredients.filter(
          (ingredient: IngredientConfig) => ingredient.type === type
        )
        .map((ingredient) => {
          return merge({}, cloneDeep(ingredient), {
            api: {
              ingredient: this.ingredients.find(
                (apiIngredient) => apiIngredient.name === ingredient.api.name
              )
            }
          });
        })
        .filter((ingredient: IngredientConfig) => !!ingredient.api.ingredient);
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  async getAppletCollection(): Promise<AppletCollection> {
    const [triggers, actions]: [
      TriggerConfig[],
      ActionConfig[]
    ] = await Promise.all([this.getTriggers(), this.getActions()]);

    const apiAppletCollection: {
      version: number;
      value: APIAppletCollection;
    } = await this.injector
      .get(API)
      .get('ifttt/iftttApplet', { params: { service_name: this.name } })
      .then(({ data }: any) => {
        // filter out applets that has actions not defined on the frontend....
        // NOTE: If some applets get filtered out from the list and user attempts to create a new trigger(applet), it will result in
        // deletion of all filtered out applets since they are all sent to the backend as a list when creating new triggers - should never happen in production tho
        data.value.applets = data.value.applets.filter((applet) => {
          const feMissingAction = applet.actions.find(
            (a) => !actions.some((fa) => fa.api.name === a.name)
          );
          if (feMissingAction) {
            console.error(
              `Action '${feMissingAction.name}' used in applet '${applet.name}' is missing its definition on the frontend, filtering out the applet from the list...`
            );
          }
          return !feMissingAction;
        });

        // filter out applets that has triggers not defined on the frontend....
        // NOTE: If some applets get filtered out from the list and user attempts to create a new trigger(applet), it will result in
        // deletion of all filtered out applets since they are all sent to the backend as a list when creating new triggers - should never happen in production tho
        data.value.applets = data.value.applets.filter((applet) => {
          const feMissingTrigger = applet.triggers.find(
            (t) => !triggers.some((ft) => ft.api.name === t.name)
          );
          if (feMissingTrigger) {
            console.error(
              `Trigger '${feMissingTrigger.name}' used in applet '${applet.name}' is missing its definition on the frontend, filtering out the applet from the list...`
            );
          }
          return !feMissingTrigger;
        });

        return data;
      });

    return new AppletCollection(
      apiAppletCollection.version,
      this,
      apiAppletCollection.value,
      triggers,
      actions,
      this.injector
    );
  }

  async createEmptyApplet(appletCollection: AppletCollection): Promise<Applet> {
    const [triggers, actions] = await Promise.all([
      appletCollection.service.getTriggers(),
      appletCollection.service.getActions()
    ]);

    return new Applet(
      appletCollection,
      {
        type: '@Applet',
        name: '',
        enabled: true,
        triggers: [],
        actions: []
      },
      triggers,
      actions,
      this.injector
    );
  }

  async saveAppletCollection(appletCollection: AppletCollection): Promise<any> {
    const { data } = await this.injector
      .get(API)
      .post('ifttt/iftttApplet', appletCollection.serialise(), {
        params: {
          service_name: appletCollection.service.name,
          version: appletCollection.version
        }
      });
    appletCollection.version++;
    return data;
  }
}

function getServiceMapping(id) {
  try {
    if (!Array.isArray(serviceMappings)) {
      throw new Error(`Value for 'applet params' not in expected format.`);
    }

    return serviceMappings.find((service) => service.id === id);
  } catch (error) {
    console.error(error);

    return null;
  }
}

function filterNotSupportedActions(
  apiService: IftttService,
  frontendActions: ActionConfig[]
): void {
  apiService.actions = apiService.actions.filter((apiAction) => {
    const feAction = frontendActions.find((a) => a.api.name === apiAction.name);
    if (!feAction) {
      console.error(
        `Service '${apiService.name}': cannot find corresponding frontend action '${apiAction.name}', filtering out...`
      );
    }

    return !!feAction;
  });
}

function filterNotSupportedTriggers(
  apiService: IftttService,
  frontendTriggers: TriggerConfig[]
): void {
  apiService.triggers = apiService.triggers.filter((apiTrigger) => {
    const feTrigger = frontendTriggers.find(
      (a) => a.api.name === apiTrigger.name
    );
    if (!feTrigger) {
      console.error(
        `Service '${apiService.name}': cannot find corresponding frontend trigger '${apiTrigger.name}', filtering out...`
      );
      console.log('apiService: ', apiService.name);
    }

    return !!feTrigger;
  });
}

@Injectable()
export class IftttServiceModel extends Model<IftttService> {
  // tslint:disable-line
  constructor() {
    super('iftttService', {
      idAttribute: 'name',
      endpoint: 'ifttt/iftttService',
      deserialize(resourceConfig, attrs) {
        return attrs.data.services;
      },
      recordClass: IftttService
    });
  }

  async findAllActive(): Promise<IftttService[]> {
    const services = await this.findAll();
    const activeServices = services.filter(
      (service) => service.triggers.length > 0 && service.actions.length > 0
    );

    activeServices.forEach((apiService) => {
      const serviceMapping = getServiceMapping(apiService.name);
      filterNotSupportedActions(apiService, serviceMapping.actions);
      filterNotSupportedTriggers(apiService, serviceMapping.triggers);
    });

    return activeServices;
  }

  async findAllGlobal(): Promise<IftttService[]> {
    const services = await this.findAll();
    return services.filter((service) => service.triggers.length === 0);
  }
}
