import './upload-subtitles-modal.component.scss';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupService } from '../../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../../constants';

enum SubtitleMimeType {
  Xml = 'text/xml',
  Srt = 'text/srt'
}

@Component({
  selector: 'ssi-upload-subtitles-modal',
  templateUrl: './upload-subtitles-modal.component.html'
})
export class UploadSubtitlesModalComponent {
  @Input() videoFile: any;

  locales = Object.keys(LANGUAGES)
    .filter((l) => l !== 'en-gb')
    .map((key) => {
      return {
        locale: key,
        displayName: LANGUAGES[key]
      };
    });
  selectedLocale = { locale: '', displayName: 'Select your language' };
  searchLanguage = '';
  subtitleMimeType = ['.srt', 'text/srt'];
  subtitleFile = { captions_url: '', locale: '' };
  subtitleFilename = null;
  toggleLanguageSearch = false;
  videoDuration: number;
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

  constructor(
    public activeModal: NgbActiveModal,
    private popup: PopupService,
    private translate: TranslateService
  ) {}

  onMetadata(event: Event): void {
    if (this.videoElement) {
      this.videoDuration = this.videoElement.nativeElement.duration;
    }
  }

  fileUploadStarted() {}

  fileUploadComplete() {}

  fileUploadSuccess(success) {
    if (success[0].filestackFile) {
      this.subtitleFile.captions_url = success[0].filestackFile.url;
      this.subtitleFilename = success[0].filestackFile.filename;
    }
  }
  async fileUploadError(error) {
    const errorMessage = `The file extension of ${error[0].filestackFile.filename} does not match the contents of the file`;
    await this.popup.confirm({
      title: this.translate.instant('FILE_UPLOAD_FAILED'),
      message: errorMessage
    });
    throw new Error(error);
  }

  save() {
    this.subtitleFile.locale = this.selectedLocale.locale;
    this.activeModal.close({
      subtitleFile: this.subtitleFile,
      fileName: this.subtitleFilename,
      languageName: this.selectedLocale.displayName
    });
  }

  removeCaptions() {
    console.log('Removing captions');
    this.subtitleFilename = null;
    this.selectedLocale = null;
    // this.subtitleFile = {};
  }
}
