<ul>
  <li
    *ngFor="let statistic of chatStatistics"
  >
    <button
      (click)="toggleStatisticFilter(statistic)"
      type="button"
    >
      <dl>
        <dt>
          <span
            translate="{{ statistic.label }}"
          ></span>
        </dt>

        <dd class="value">
          {{ chatStatisticsValues[statistic.values] }}
        </dd>

        <dd class="icon">
          <i class="{{ statistic.icon }}"></i>
        </dd>
      </dl>
    </button>
  </li>
  
  <li
    *ngFor="let team of teams"
  >
    <button
      (click)="toggleStatisticFilter(team)"
      type="button"
    >
      <dl>
        <dt>
          <span>{{ team.name }}</span>
        </dt>

        <dd class="value">
          {{ team.agentsOnline }}
        </dd>

        <dd class="icon">
          <i class="ssi ssi-person-outline"></i>
        </dd>
      </dl>
    </button>
  </li>
</ul>
