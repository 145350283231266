import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { EditWidgetComponent } from './edit-widget.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { ToggleSwitchModule } from '../../../../../../common/components/toggle-switch/toggle-switch.module';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.accountsettings.livechatwidgets.widget',
          url: '/widget',
          component: EditWidgetComponent,
          params: {
            widget: '',
            chatBots: []
          }
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ColorPickerModule,
    ToggleSwitchModule,
    TooltipModule
  ],
  declarations: [EditWidgetComponent],
  entryComponents: [EditWidgetComponent]
})
export class EditWidgetModule {}
