<div [ssiTooltip]="ssiTooltip"
     [tooltipPlacement]="tooltipPlacement"
     [tooltipAppendToBody]="tooltipAppendToBody"
     [tooltipTitle]="tooltipTitle"
     [tooltipIconClass]="tooltipIconClass"
     [tooltipDisabled]="tooltipDisabled"
     [tooltipPositionFixed]="tooltipPositionFixed"
     [tooltipHost]="tooltipHost"
     [tooltipTemplate]="tooltipTemplate"
     [tooltipTemplateContext]="tooltipTemplateContext"
     [tooltipWindowClass]="tooltipWindowClass"
     [tooltipOffset]="tooltipOffset">
     
    <ng-content></ng-content>
</div>