import './assign-message.component.scss';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AssignMessageComponent } from './assign-message.component';
import { FormsModule } from '@angular/forms';
import { ButtonComponent, OrloComponentsModule } from 'orlo-components';

@NgModule({
  declarations: [AssignMessageComponent],

  exports: [AssignMessageComponent],

  imports: [CommonModule, FormsModule, OrloComponentsModule, TranslateModule]
})
export class AssignMessageModule {}
