<button type="button"
        class="media-nav-arrow left"
        *ngIf="files.length > 1"
        [disabled]="activeFileIndex === 0"
        (click)="activeFileIndexChange.emit(activeFileIndex - 1)">
     <i class="ssi ssi-keyboard-arrow-left"></i>
</button>

<ng-container *ngIf="activeFileIsVideo">
     <video controls>
          <source [src]="activeFileUrl"
                  type="video/mp4">
          Sorry but we could not load the video preview.
     </video>
</ng-container>

<ng-container *ngIf="!activeFileIsVideo">
     <img *ngIf="!useBackgroundImage"
          [src]="activeFileUrl"
          ssiEnlargeImage>
     <div class="flex-1 media-nav-img"
          *ngIf="useBackgroundImage"
          [style.background-image]="'url(' + activeFileUrl + ')'"
          [ssiEnlargeImage]="activeFileUrl"></div>
</ng-container>

<button type="button"
        class="media-nav-arrow right"
        *ngIf="files.length > 1"
        [disabled]="activeFileIndex === files.length - 1"
        (click)="activeFileIndexChange.emit(activeFileIndex + 1)">
     <i class="ssi ssi-keyboard-arrow-right"></i>
</button>