import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';

import { AdStatsService } from './ad-stats.service';
import { AdStatsComponent } from './ad-stats.component';
import { AdStatsBuildComponent } from './build/ad-stats-build.component';
import { AdStatsReportComponent } from './report/ad-stats-report.component';
import { EngagementSummaryComponent } from './report/engagement-summary/engagement-summary.component';
import { AdAccountBreakdownComponent } from './report/ad-account-breakdown/ad-account-breakdown.component';

import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { Checkbox2Module } from '../../../../common/components/checkbox-2/checkbox-2.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { ToggleSwitchModule } from '../../../../common/components/toggle-switch/toggle-switch.module';
import { TreetableModule } from '../../../../common/components/treetable';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { ReportSidebarModule } from '../common/report-sidebar/report-sidebar.module';
import { ReportHeaderModule } from '../common/report-header/report-header.module';
import { StatChangeModule } from '../common/stat-change/stat-change.module';
import { FactOverviewModule } from '../common/fact-overview/fact-overview.module';
import { SpendEngagementComponent } from './report/spend-engagement/spend-engagement.component';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';
import { SpendConversionComponent } from './report/spend-conversion/spend-conversion.component';

import { SpendBreakdownComponent } from './report/spend-breakdown/spend-breakdown.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.adStats',
          url: '/ad-stats',
          abstract: true,
          component: AdStatsComponent
        },
        {
          name: 'auth.analytics.adStats.build',
          url:
            '?accountIds&startDate&endDate&previousStartDate&previousEndDate&dateRange&compareTo&currency',
          component: AdStatsBuildComponent
        },
        {
          name: 'auth.analytics.adStats.report',
          url:
            '/report?accountIds&startDate&endDate&previousStartDate&previousEndDate&dateRange&compareTo&currency',
          component: AdStatsReportComponent
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    OrloComponentsModule,
    TranslateModule,
    FlatpickrModule,
    DropdownSelect2Module,
    Checkbox2Module,
    TooltipModule,
    OffClickModule,
    SocialNetworkIconModule,
    ToggleSwitchModule,
    TreetableModule,
    BigNumberModule,
    ReportSidebarModule,
    ReportHeaderModule,
    StatChangeModule,
    FactOverviewModule,
    HighchartModule
  ],
  declarations: [
    AdStatsComponent,
    AdStatsBuildComponent,
    AdStatsReportComponent,
    EngagementSummaryComponent,
    AdAccountBreakdownComponent,
    SpendBreakdownComponent,
    SpendEngagementComponent,
    SpendConversionComponent
  ],
  entryComponents: [AdStatsComponent],
  providers: [AdStatsService]
})
export class AdStatsModule {}
