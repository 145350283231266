<div class="heading-container">
  <orlo-button
    [text]="'Back'"
    (click)="backToReports()"
    [textColor]="'#14BAE3'"
    [bgColor]="'#fff'"
    [leadingIcon]="'ssi ssi-line-arrow-small-down'"
  ></orlo-button>
  <h1>Manage all companies</h1>
</div>
<div class="content-wrapper">
  <div
    class="box-list-container"
    [ngSwitch]="templateView"
  >
    <div class="tabs">
      <nav role="tablist">
        <a
          [ngClass]="{'active': templateView === 'brands'}"
          (click)="templateView = 'brands'"
          role="tab"
        >My brands</a>
        <a
          [ngClass]="{'active': templateView === 'competitors'}"
          (click)="templateView = 'competitors'"
          role="tab"
        >Competitors</a>
      </nav>
    </div>
    <div class="sub-menu">
      <div class="filter-search">
        <input 
          type="text"
          class="orlo-text-input"
          placeholder="Search"
          [(ngModel)]="filterQuery"
          />
        <i class="ssi ssi-search-small"></i>
      </div>
      <ssi-menu-item
        uiSref="^.reportCreate"
        label="New report"
        iconClass="ssi-addsmall"
      >
      </ssi-menu-item>
    </div>
    <div class="content">
      <div
        class="profile-grid"
        *ngSwitchCase="'brands'"
      >
        <!-- <div
          *ngIf="remainingSlots.brand > 0"
          class="box text"
          uiSref="^.brandCreate"
        >
          <div class="box-control">
            <i class="ssi ssi-plus"></i>
          </div>
          <label>Add {{remainingSlots.brand}} more brands</label>
        </div> -->
        <div
          class="profile-grid-item"
          *ngFor="let brand of profileGroups | filter: {type: 'BRAND'} | filterByString: filterQuery"
        >
          <div class="profile">
            <div
              class="profile-avatar"
              [ngStyle]="brand.firstPickedAvatar ? {'background-image': 'url(' + brand.firstPickedAvatar + ')'} : {'background-color': brand.colour}"
              (click)="deleteProfileGroup(brand)"
            >
              <div class="profile-overlay">
                <i class="ssi ssi-delete"></i>
              </div>
            </div>
            <div class="profile-meta">
              <p>{{brand.name}}</p>
              <ul>
                <li *ngFor="let network of brand.profiles">
                  <i [ngClass]="accountModel.getSocialIcon(accountModel.getAccountTypeFromId(network.account_type_id.toString()))">
                  </i>
                </li>
              </ul>
            </div>
            <div class="profile-action" (click)="editProfileGroup(brand)">
              <i class="ssi ssi-correctedit"></i>
            </div>
          </div>
        </div>

        <!-- <h4
          class="limit-info"
          *ngIf="remainingSlots.brand === 0"
        >
          You have reached your maximum number of brands, you can either delete a current brand account, or
          <a (click)="toggleLiveChat()">chat to us</a> now for more information on how to get more brand spaces.
        </h4> -->
      </div>
      
      <div
        class="profile-grid"
        *ngSwitchDefault
      >
        <!-- <div
          *ngIf="remainingSlots.competitor > 0"
          class="box text"
          uiSref="^.competitorCreate"
        >
          <div class="box-control">
            <i class="ssi ssi-plus"></i>
          </div>
          <label>Add {{remainingSlots.competitor}} more competitors</label>
        </div> -->
        <div
          class="profile-grid-item"
          *ngFor="let competitor of profileGroups | filter: {type: 'COMPETITOR'} | filterByString: filterQuery"
        >
          <div class="profile">
            <div
              class="profile-avatar"
              [ngStyle]="competitor.firstPickedAvatar ? {'background-image': 'url(' + competitor.firstPickedAvatar + ')'} : {'background-color': competitor.colour}"
              (click)="deleteProfileGroup(competitor)"
            >
              <div class="profile-overlay">
                <i class="ssi ssi-delete"></i>
              </div>
            </div>
            <div class="profile-meta">
              <p>{{competitor.name}}</p>
              <ul>
                <li *ngFor="let network of competitor.profiles">
                  <i [ngClass]="accountModel.getSocialIcon(accountModel.getAccountTypeFromId(network.account_type_id.toString()))">
                  </i>
                </li>
              </ul>
            </div>
            <div class="profile-action" (click)="editProfileGroup(competitor)">
              <i class="ssi ssi-correctedit"></i>
            </div>
          </div>
        </div>
        <!-- <h4
          class="limit-info"
          *ngIf="remainingSlots.competitor === 0"
        >
          You have reached your maximum number of competitors, you can either delete a current competitor account, or
          <a (click)="toggleLiveChat()">chat to us</a> now for more information on how to get more compeitior spaces.
        </h4> -->
      </div>
    </div>
  </div>
  <div class="help-content" *ngIf="templateView === 'competitors' && remainingSlots.competitor === 0">
    <img src="https://www.orlo.app/assets/v2/img/want-more.png" />
    <div class="text-container">
      <h3>Want more?</h3>
      <p>With more competitors, you can gain a broader insight into your performance. Chat with us if you'd like to widen the net.
      </p>
      <orlo-button
        [state]="'primary'"
        [text]="'Chat to us!'"
        (click)="toggleLiveChat()"
      ></orlo-button>
    </div>
  </div>
</div>