import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AuthenticationSettingsComponent } from './authentication-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    AlertsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.authenticationSettings',
          url: '/settings/authentication',
          component: AuthenticationSettingsComponent,
          data: {
            permissions: {
              company: 'administer_company'
            }
          },
          resolve: AuthenticationSettingsComponent.resolve
        }
      ]
    })
  ],
  declarations: [AuthenticationSettingsComponent]
})
export class AuthenticationSettingsModule {}
