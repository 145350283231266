<div class="my-details">
    <div class="flex-grid container">
      <div class="col">
        <div class="form">
          <form (ngSubmit)="onSubmit()" [formGroup]="myPasswordForm">
            <orlo-text-input [width]="'300'" [label]="'New Password'" [name]="'password'" [parentForm]="myPasswordForm" [type]="'password'" [required]="true" [placeholder]="'Type your new password...'" [invalid]="myPasswordForm.controls.password?.touched && myPasswordForm.controls.password?.invalid ? true : false" [invalidMessage]="'Woops! We\'ll need you to fill this one in...'"></orlo-text-input>
            <orlo-text-input [width]="'300'" [label]="'Retype New Password'" [name]="'retype_password'" [parentForm]="myPasswordForm" [type]="'password'" [required]="true" [placeholder]="'Retype your new password...'" [invalid]="myPasswordForm.controls.retype_password?.touched && myPasswordForm.controls.retype_password?.invalid ? true : false" [invalidMessage]="'Your passwords need to match!...'"></orlo-text-input>
            <div class="form-submit">
              <orlo-button [text]="'Save changes'" [state]="'primary'" [type]="'submit'" [disabled]="myPasswordForm?.invalid"></orlo-button>
            </div>
          </form>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
  