import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkifyPipe } from './linkify.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LinkifyPipe],
  exports: [LinkifyPipe],
  providers: [LinkifyPipe]
})
export class LinkifyModule {}
