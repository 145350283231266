<div class="composer-cancel-modal">
  <div class="composer-cancel-modal-header">
    <div class="composer-cancel-modal-header-left">
      <h2>Cancel your {{ isDraft ? 'draft' : 'post' }}?</h2>
    </div>
    <div class="composer-cancel-modal-header-right">
      <button (click)="activeModal.close(false)" class="composer-cancel-modal-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="composer-cancel-modal-body">
    <div class="composer-cancel-modal-body-row">
      <p>If you leave now, your changes will not be saved.
        <span *ngIf="!isDraft">You could always save this post as a draft to carry on with later.</span>
      </p>
    </div>
  </div>

  <div class="composer-cancel-modal-footer">
    <div class="composer-cancel-modal-footer-left">
      <orlo-button
        class="composer-cancel-modal-button composer-cancel-modal-button-link"
        text="Save as new draft"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(1)"
      ></orlo-button>
    </div>
    <div class="composer-cancel-modal-footer-right">
      <orlo-button
        class="composer-cancel-modal-button composer-cancel-modal-button-secondary"
        text="Continue editing"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        class="composer-cancel-modal-button"
        text="Cancel {{ isDraft ? 'draft' : 'post'}}"
        textColor="#cc3300"
        bgColor="#FFEDE7"
        (click)="activeModal.close(2)"
      ></orlo-button>
    </div>
  </div>
</div>