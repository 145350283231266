import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChatbotComponent } from './chatbot.component';
import { ChatbotBuildComponent } from './build/chatbot-build.component';
import { ChatbotListComponent } from './chatbot-list/chatbot-list.component';
import { ChatbotReportComponent } from './report/chatbot-report.component';
import { ReportHeaderModule } from './../common/report-header/report-header.module';
import { ReportSidebarModule } from './../common/report-sidebar/report-sidebar.module';
import { ChatbotAnalyticsService } from './chatbot-analytics.service';
import { FileDownloadModule } from '../../../../common/directives/file-download/file-download.module';
import { FactOverviewModule } from '../common/fact-overview/fact-overview.module';
import { ProgressBarModule } from '../../../../common/components/progress-bar/progress-bar.module';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';
import { FilterModule } from '../../../../common/pipes/filter/filter.module';
import { TreetableModule } from '../../../../common/components/treetable';
import { LoaderModule } from '../../../../common/directives/loader';
import { OrloComponentsModule } from 'orlo-components';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { Checkbox2Module } from '../../../../common/components/checkbox-2/checkbox-2.module';
import { TemplateSelectorModule } from '../../../../common/directives/template-selector/template-selector.module';
import { VarModule } from '../../../../common/directives/var/var.module';
import { MenuItemModule } from '../common/menu-item/menu-item.module';
import { ConfirmActionModule } from '../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { FocusOnModule } from '../../../../common/directives/focus-on/focus-on.module';
import { SortByModule } from '../../../../common/pipes/sort-by/sort-by.module';
import { TotalSessionsComponent } from './report/total-sessions/total-sessions.component';
import { HandoverTimesComponent } from './report/handover-times/handover-times.component';
import { ChatbotFeedbackComponent } from './report/chatbot-feedback/chatbot-feedback.component';
import { TopUrlsModule } from '../common/top-urls/top-urls.module';
import { TopicUsageComponent } from './report/topic-usage/topic-usage.component';
import { TopicFeedbackComponent } from './report/topic-feedback/topic-feedback.component';
import { TopTopicsComponent } from './report/top-topics/top-topics.component';
import { TopicReportComponent } from './topic-report/topic-report.component';
import { ToggleSwitchModule } from '../../../../common/components/toggle-switch/toggle-switch.module';
import { ToggleSwitch2Module } from '../../../../common/components/toggle-switch-2/toggle-switch-2.module';
import { TopicReportService } from './topic-report/topic-report.service';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.chatbot',
          url: '/chatbot',
          component: ChatbotComponent
        },
        // hidden for now, report is accessed from chatbot settings page
        // {
        //   name: 'auth.analytics.chatbot.index',
        //   url: '/overview',
        //   component: ChatbotListComponent
        // },
        // {
        //   name: 'auth.analytics.chatbot.build',
        //   url: '/build/:botId?name&startDate&endDate&dateRange',
        //   component: ChatbotBuildComponent
        // },
        {
          name: 'auth.analytics.chatbot.report',
          url: '/report?:botId&name&startDate&endDate&dateRange',
          component: ChatbotReportComponent
        },
        {
          name: 'auth.analytics.chatbot.topicReport',
          url: '/topic-report?:botId&name&startDate&endDate&dateRange',
          component: TopicReportComponent
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    ReportHeaderModule,
    ReportSidebarModule,
    FileDownloadModule,
    FactOverviewModule,
    ProgressBarModule,
    BigNumberModule,
    HighchartModule,
    FilterModule,
    TreetableModule,
    LoaderModule,
    OrloComponentsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlatpickrModule,
    VarModule,
    TemplateSelectorModule,
    DropdownSelect2Module,
    Checkbox2Module,
    MenuItemModule,
    ConfirmActionModule,
    TooltipModule,
    DropdownSelect2Module,
    OffClickModule,
    FocusOnModule,
    SortByModule,
    TopUrlsModule,
    ToggleSwitch2Module
  ],
  declarations: [
    ChatbotComponent,
    ChatbotListComponent,
    ChatbotReportComponent,
    ChatbotBuildComponent,
    TotalSessionsComponent,
    HandoverTimesComponent,
    ChatbotFeedbackComponent,
    TopicUsageComponent,
    TopicFeedbackComponent,
    TopTopicsComponent,
    TopicReportComponent
  ],
  entryComponents: [ChatbotComponent],
  providers: [ChatbotAnalyticsService, TopicReportService, DatePipe]
})
export class ChatBotModule {}
