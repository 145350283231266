import './instagram-post-options-select.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { OutboxPublisher } from '@ui-resources-angular';
import { groupBy } from '../../../../common/utils';
import { Option } from '../../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { MediaCategory } from '../../../../../../library/ui-resources/modules/publish/services/media-restrictions';

export const instagramPostOptions = {
  [MediaCategory.Post]: {
    key: MediaCategory.Post,
    label: 'Instagram Post / Carousel',
    icon: 'ssi-ig-carousel'
  },
  [MediaCategory.Reel]: {
    key: MediaCategory.Reel,
    label: 'Instagram Reel',
    icon: 'ssi-ig-reels'
  },
  [MediaCategory.Story]: {
    key: MediaCategory.Story,
    label: 'Instagram Story',
    icon: 'ssi-ig-stories'
  }
};

@Component({
  selector: 'ssi-instagram-post-options-select',
  templateUrl: './instagram-post-options-select.component.html',
  styles: []
})
export class InstagramPostOptionsSelectComponent implements OnInit, OnChanges {
  @Input() composer: OutboxPublisher;
  @Input() mediaCategory: MediaCategory;

  options: { [key: string]: Option } = instagramPostOptions;
  optionsIterable: Option[] = Object.values(this.options);
  selectedOption: Option = this.options.post;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mediaCategory'] && changes['mediaCategory'].currentValue) {
      this.selectedOption = this.options[this.mediaCategory];
    }
  }

  ngOnInit() {}

  selectedOptionChanged(selectedOption: Option): void {
    this.selectedOption = selectedOption;
    this.composer.mediaCategory = selectedOption.key;

    // TODO: warning before removing files?
    this.composer.files.forEach((file) => {
      this.composer.removeFile(file);
    });
  }
}
