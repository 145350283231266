<div class="score-and-filters-section">
  <div class="score-and-filters-section-head">
    <div class="title">
      <i class="ssi ssi-survey"></i>
      <h2>Score & Filters</h2>
    </div>
  </div>

  <div class="score-and-filters-section-body">

    <div class="score-card">
      <div class="score">
        <div class="rank">
          <div></div>
          <i class="ssi ssi-social-score"></i>
        </div>
        <div class="value">
          <div class="number">{{avgScore}}</div>
          <div class="subtitle">
            <span class="g-uppercase"
                  *ngIf="avgScoreSurveyTypeLabel == 'NPS'"
                  [ssiTooltip]="'Net Promoter Score (NPS) is a measure used to gauge customer loyalty and satisfaction with a product which is calculated by asking customers a single survey question e.g. “On a scale of 0 to 10, how likely are you to recommend this product/company to a friend or colleague?”. \n'+
'NPS = % promoters minus % detractors.\n'+
'A score of 9 or 10 indicates a promoter, a score of 7 or 8 indicates a passive response and a score of 0 to 6 indicates a detractor.\n'"
                  tooltipWindowClass="tooltip-chunky-big">{{avgScoreSurveyTypeLabel}} SCORE <i class="ssi ssi-information"></i></span>
            <span class="g-uppercase"
                  *ngIf="avgScoreSurveyTypeLabel == 'CSAT'"
                  [ssiTooltip]="'Customer Satisfaction Score (CSAT) measures customer satisfaction with a business, purchase, or interaction. It’s obtained by asking questions like: “How satisfied were you with your experience?” To answer, you are able to define a corresponding survey scale, without restriction e.g. 1-10. '"
                  tooltipWindowClass="tooltip-chunky-big">{{avgScoreSurveyTypeLabel}} SCORE <i class="ssi ssi-information"></i></span>
          </div>
        </div>
      </div>
    </div>

    <form #form="ngForm"
          class="filters-form"
          (submit)="submit(form)">
      <div class="form-field-group">
        <div class="form-field half">
          <label translate="START_DATE"></label>
          <div class="input-wrapper"
               #startDateContainer>
            <input mwlFlatpickr
                   class="text-input"
                   type="text"
                   name="startDate"
                   [(ngModel)]="model.startDate"
                   (ngModelChange)="onStartDatePicked($event)"
                   [altInput]="true"
                   [convertModelValue]="true"
                   [enableTime]="true"
                   [defaultHour]="00"
                   [defaultMinute]="00"
                   [defaultSeconds]="00"
                   dateFormat="Y-m-d H:i"
                   altFormat="M J Y, H:i"
                   [maxDate]="endDate"
                   [time24hr]="true"
                   [appendTo]="startDateContainer.nativeElement"
                   placeholder="Select..."
                   [required]="true"
                   (flatpickrOpen)="onFlatpickrOpen()">
          </div>
        </div>
        <div class="form-field half">
          <label translate="END_DATE"></label>
          <div class="input-wrapper"
               #endDateContainer>
            <input mwlFlatpickr
                   class="text-input"
                   type="text"
                   name="endDate"
                   [(ngModel)]="model.endDate"
                   (ngModelChange)="onEndDatePicked($event)"
                   [altInput]="true"
                   [convertModelValue]="true"
                   [enableTime]="true"
                   [defaultHour]="23"
                   [defaultMinute]="59"
                   [defaultSeconds]="59"
                   dateFormat="Y-m-d H:i"
                   altFormat="M J Y, H:i"
                   [minDate]="startDate"
                   [maxDate]="today"
                   [time24hr]="true"
                   [appendTo]="endDateContainer.nativeElement"
                   placeholder="Select..."
                   [required]="true"
                   (flatpickrOpen)="onFlatpickrOpen()">
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label>SURVEY TYPE</label>
          <div class="input-wrapper">
            <ssi-dropdown-select-2 name="surveyType"
                                   size="lg"
                                   headButtonStyleVariant="analytics"
                                   [options]="surveyTypesIterable"
                                   [(ngModel)]="model.surveyType"
                                   (ngModelChange)="onSurveyTypeChange($event)">
              <ng-template ssiTemplateSelector="headButtonLabel"
                           let-expanded="expanded">
                <div class="select-placeholder"
                     *ngIf="!model.surveyType">
                  <span>Select survey type...</span>
                </div>
                <div class="select-label"
                     *ngIf="model.surveyType">
                  <span>{{model.surveyType.label}}</span>
                </div>
              </ng-template>
            </ssi-dropdown-select-2>
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field half">
          <label>SCORE RANGE</label>
          <div class="input-wrapper">
            <input type="number"
                   class="text-input"
                   name="minScore"
                   [(ngModel)]="model.minScore"
                   (ngModelChange)="onMinScoreInput($event)"
                   placeholder="Min score...">
          </div>
        </div>

        <div class="form-field half">
          <label></label>
          <div class="input-wrapper">
            <input type="number"
                   class="text-input"
                   name="maxScore"
                   [(ngModel)]="model.maxScore"
                   (ngModelChange)="onMaxScoreInput($event)"
                   placeholder="Max score...">
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label  [ssiTooltip]="'The display name of the user of the account that received the survey.'"
                  tooltipWindowClass="tooltip-chunky">SURVEYEE <i class="ssi ssi-information"></i></label>
          <div class="input-wrapper">
            <input type="text"
                   class="text-input"
                   name="surveyee"
                   [(ngModel)]="model.surveyee"
                   (ngModelChange)="onSurveyeeInput($event)"
                   placeholder="Search surveyee name...">
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label  [ssiTooltip]="'The orlo user that sent the survey.'"
                  tooltipWindowClass="tooltip-chunky">AGENT <i class="ssi ssi-information"></i></label>
          <div class="input-wrapper">
            <ssi-dropdown-select-2 name="colleagues"
                                   size="lg"
                                   headButtonStyleVariant="analytics"
                                   [multiple]="true"
                                   [filterable]="true"
                                   [filterPlaceholder]="'Search...'"
                                   [toggleAllLabel]="'Select all'"
                                   [getOptionLabel]="getColleagueOptionLabel"
                                   [options]="colleagues"
                                   [(ngModel)]="model.colleagues"
                                   (ngModelChange)="onColleaguesChange($event)">
              <ng-template ssiTemplateSelector="headButtonLabel"
                           let-expanded="expanded">
                <div class="select-placeholder"
                     *ngIf="!model.colleagues.length">
                  <span>Select agent...</span>
                </div>
                <div class="select-label"
                     *ngIf="model.colleagues.length">
                  <span *ngIf="model.colleagues.length === 1">{{model.colleagues[0].fullName}}</span>
                  <span *ngIf="model.colleagues.length !== 1">{{model.colleagues.length}} agents</span>
                </div>
              </ng-template>

              <!-- <ng-template ssiTemplateSelector="optionIcon"
                           let-colleague="option">
              </ng-template> -->
            </ssi-dropdown-select-2>
          </div>
        </div>
      </div>

      <div class="form-field-group">
        <div class="form-field">
          <label>KEYWORD SEARCH</label>
          <div class="input-wrapper">
            <input type="text"
                   class="text-input"
                   name="comment"
                   [(ngModel)]="model.comment"
                   (ngModelChange)="onCommentInput($event)"
                   placeholder="Search comments for keyword...">
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="score-and-filters-section-foot">
    <div class="buttons">
      <button class="g-btn g-btn-secondary bg-red clear-button"
              type="button"
              *ngIf="hasActiveFilters()"
              (click)="clearFilters()"><i class="ssi ssi-delete-close"></i><span>Clear all filters</span></button>

      <button class="g-btn submit-button"
              type="submit"
              (click)="submit(form)"
              [disabled]="!canSubmit()"><span>Apply filters</span></button>
    </div>
  </div>
</div>