<div class="stream-builder-nav">
  <div class="back">
    <a uiSref="^.desc"><i class="ssi ssi-line-arrow-small-up left"></i>  Back</a>
    <span *ngIf="streamQuery.id" (click)="deleteMenu.hide(); deleteMenu.show($event, {streamQuery: streamQuery});" class="stream-builder-nav-action">
      <i class="ssi ssi-delete"></i><span>Delete stream</span>
    </span>
  </div>
  <div class="nav-list">
    <h3 class="active">Description<i class="ssi ssi-tick"></i></h3>
    <div class="line active completed"></div>
    <h3 class="active">Build Query</h3>
    <div class="line active"></div>
    <h3>Sources & Save</h3>
  </div>
  <div [class.disabled]="!isQueryValid()" class="next">
    <a (click)="saveStep()" [uiSref]="isQueryValid() ? '^.finalise' : '.'">Save Query  <i class="ssi ssi-line-arrow-small-up right"></i></a>
  </div>
</div>

<div class="stream-builder-query">
  <div *ngIf="guideEnabled && (rootQuery.conditions.length || rootQuery.subQueries.length) && showGuide" class="query-guide-container">
    <div class="query-guide-label">
      <p>You are listening for:</p>
    </div>
    <p class="query-guide-sentence" [innerHTML]="rootQuery | humanQuery"></p>
  </div>
  
  <div class="query-actionbar-container">
    <div class="query-actionbar-container-column query-actionbar-container-column-left">
      <orlo-button
        class="query-actionbar-button"
        text="Pro Tips"
        textColor="#14BAE3"
        bgColor="#fff"
        leadingIcon="ssi ssi-pro-tip"
        (btnClick)="modal.open(proTipModal, {
          windowClass: 'query-builder-pro-tip-modal',
          centered: true
        })"
      ></orlo-button>
      <ng-container *ngIf="guideEnabled && (rootQuery.conditions.length || rootQuery.subQueries.length)">
        <orlo-button
          *ngIf="!showGuide"
          class="query-actionbar-button query-actionbar-button-border"
          text="View Guide"
          bgColor="#ECF5F5"
          textColor="#12ACA4"
          leadingIcon="ssi ssi-copy-paste"
          (btnClick)="showGuide = true"
        ></orlo-button>
        <orlo-button
          *ngIf="showGuide"
          class="query-actionbar-button query-actionbar-button-border"
          text="Close Guide"
          bgColor="#ECF5F5"
          textColor="#12ACA4"
          leadingIcon="ssi ssi-hide"
          (btnClick)="showGuide = false"
        ></orlo-button>
      </ng-container>
    </div>
    <div class="query-actionbar-container-column query-actionbar-container-column-center">
      <div *ngIf="allowRootConditions" class="actionbar-toggle">
        <div class="actionbar-toggle-item actionbar-toggle-item-blue">
          <input [(ngModel)]="rootQuery.operator" type="radio" id="actionbarOr" value="$or" name="operator" checked>
          <label for="actionbarOr">
            <span>Or</span>
          </label>
        </div>
        <div class="actionbar-toggle-item actionbar-toggle-item-yellow">
          <input [(ngModel)]="rootQuery.operator" type="radio" id="actionbarAnd" value="$and" name="operator">
          <label for="actionbarAnd">
            <span>And</span>
          </label>
        </div>
      </div>
    </div>
    <div class="query-actionbar-container-column query-actionbar-container-column-right">
      <orlo-button
        *ngIf="allowRootConditions"
        class="query-actionbar-button"
        text="Add Condition"
        textColor="#fff"
        bgColor="#12ACA4"
        leadingIcon="ssi ssi-addsmall"
        (btnClick)="addRootCondition()"
      ></orlo-button>
      <orlo-button
        class="query-actionbar-button"
        text="Add Group"
        textColor="#fff"
        bgColor="#89A4EA"
        leadingIcon="ssi ssi-addsmall"
        (btnClick)="addGroup()"
      ></orlo-button>
    </div>
  </div>

  <div *ngIf="!rootQuery.conditions.length && !rootQuery.subQueries.length" class="query-container">
    <div class="query-container-no-query">
      <i class="ssi ssi-total-results"></i>
      <h2>Get building...</h2>
      <p>Start adding your conditions, remember best practice keep to a maximum of 15 conditions.</p>
    </div>
  </div>

  <div *ngIf="rootQuery.conditions.length || rootQuery.subQueries.length" class="query-container-builder">

    <div *ngIf="requireAccountId" class="query-container-builder-row-condition-select-wrap">
      <div class="query-container-builder-row-condition-select">
        <select [(ngModel)]="rootQuery.accountId">
          <option [value]="undefined">Select default account</option>
          <option *ngFor="let account of accounts" [value]="account.id">{{account.name}} ({{account.accountTypeLabel}})</option>
        </select>
        <i class="ssi ssi-arrow-down-new"></i>
      </div>
    </div>

    <ssi-query-builder-condition *ngFor="let condition of rootQuery.conditions; let i = index"
      [operator]="rootQuery.operator"
      [condition]="condition"
      [branchClass]="rootQuery.conditions.length | branchClass: i : rootQuery.subQueries.length > 0"
      [overrideDefaultOption]="overrideDefaultOption"
      (deleteCondition)="removeCondition(rootQuery.conditions, i)"
    ></ssi-query-builder-condition>

    <ng-container *ngFor="let subQuery of rootQuery.subQueries; let subQueryIndex = index">
      <div class="query-container-builder-group">
        <div class="query-container-builder-row-condition-select-wrap">
          <div *ngIf="requireAccountId" class="query-container-builder-row-condition-select">
            <select [(ngModel)]="subQuery.accountId">
              <option [value]="undefined">Override default account</option>
              <option *ngFor="let account of accounts" [value]="account.id">{{account.name}} ({{account.accountTypeLabel}})</option>
            </select>
            <i class="ssi ssi-arrow-down-new"></i>
          </div>
        </div>

        <ssi-query-builder-condition *ngFor="let condition of subQuery.conditions; let i = index"
          [ngClass]="subQuery.conditions.length | branchClass: i : rootQuery.subQueries.length - 1 !== subQueryIndex"
          [operator]="subQuery.operator"
          [condition]="condition"
          [isRoot]="i === 0"
          [branchClass]="subQuery.conditions.length | branchClass: i : rootQuery.subQueries.length - 1 !== subQueryIndex"
          [overrideDefaultOption]="overrideDefaultOption"
          (deleteCondition)="subQuery.conditions.length > 1 ? removeCondition(subQuery.conditions, i) : removeGroup(subQueryIndex)"
          (operatorToggle)="subQuery.operator = subQuery.operator === '$or' ? '$and' : '$or'"
        ></ssi-query-builder-condition>
        <div class="query-container-builder-row">
          <div class="query-container-builder-row-tree"></div>
          <button class="query-container-builder-group-add" (click)="addSubCondition(subQueryIndex)">
            <i class="ssi ssi-addsmall"></i>
          </button>
        </div>
        <div class="query-container-builder-group-delete" (click)="removeGroup(subQueryIndex)">
          <i class="ssi ssi-delete"></i>
          <p>Remove Group</p>
        </div>
      </div>
  
      <div
        *ngIf="subQueryIndex !== rootQuery.subQueries.length - 1"
        class="query-container-builder-group-join"
      >
        <span class="query-container-builder-group-join-label">
          {{rootQuery.operator === '$or' ? 'Or' : rootQuery.operator === '$and' ? 'And' : '-'}}
        </span>
        <span class="query-container-builder-group-join-guides"></span>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #proTipModal let-close="close">
  <button
    type="button"
    class="modal-close-button"
    (click)="close()">
    <i class="ssi ssi-addsmall"></i>
  </button>
  <div class="pro-modal-header">
    <h2 class="pro-modal-title">Take notes, be a query building pro!</h2>
    <p>Drill down through the noise and make your Inbox as refined and focused as ever. This data will also be saved to further study in your Analytics. If you need a refresher, watch this <a target="_blank" href="https://support.orlo.tech/customer-training-videos/insights/creating-an-insights-listening-stream">helper video here</a> on how to build your query</p>
    <p>All these terms can seem confusing at first but once you understand our three boolean terms you will be good to go. Try building out a few examples and you will get a nice guide sentence that tells you what messages will be brought into your Inbox. If you’re still finding it a little confusing, take a look at our <a target="_blank" href="https://support.orlo.tech/query-builder-breakdown">help article</a> that provides some examples of how a stream can be set up or contact your CSM who can guide you through the process.</p>
  </div>
  <div class="pro-modal-body">
    <div class="pro-modal-body-well">
      <h3>Your cheatsheet</h3>
      <div class="pro-modal-body-content">
        <dl class="pro-modal-body-legend">
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-blue">Or</span>
          </dt>
          <dd>Let's use the example Orlo <strong>OR</strong> Elephant, this will run a search and bring in any messages that contain the keywords 'Orlo' or 'Elephant'.</dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-yellow">And</span>
          </dt>
          <dd>Let's use the example Orlo <strong>AND</strong> Elephant, this will run a search and bring in all messages that only contain both keywords 'Orlo' and 'Elephant'.</dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-orange">Exclude</span>
          </dt>
          <dd>To exclude keywords from a search, select "does not include", to remove any messages that mention certain profiles select "does not mention".</dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-white">Tip</span>
          </dt>
          <dd>Remember to keep in mind common mispellings, phrases and abbreviations for you brand or search term!</dd>
        </dl>
        <img src="https://www.orlo.app/app/web/production/e993c7845534372477d9ee154c59728e.png" alt="average first response guidance">
      </div>
    </div>
  </div>
</ng-template>

<ssi-context-menu #deleteMenu
                  (ssiOffClick)="deleteMenu.hide();">
  <ng-template let-ctx>
    <ssi-confirm-action title="Are you sure you want to delete?"
                        dismissText="Cancel"
                        confirmText="Delete"
                        dismissTextColour="#43537f"
                        confirmTextColour="#F88C68"
                        (onDismiss)="deleteMenu.hide();"
                        (onConfirm)="deleteMenu.hide(); deleteStream(ctx.streamQuery);"></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>