import './service.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { IftttService } from '../../ifttt-utils/ifttt-service-model/ifttt-service-model';
import { CompanyService } from '../../../../../common/services/company/company.service';

@Component({
  templateUrl: './service.component.html'
})
export class ServiceComponent implements OnInit {
  @Input() services: IftttService[];

  constructor(private company: CompanyService) {}

  async ngOnInit(): Promise<void> {
    if (!(await this.company.hasFeatureAccess('ORLO_INSIGHTS'))) {
      this.services = this.services.filter(
        (s) => s.name !== 'monitoring.imported'
      );
    }
  }
}
