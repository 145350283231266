<div class="dashboard-edit-container has-widgets"
     *ngIf="dashboard">
  <ssi-dashboard-navbar [dashboardName]="dashboard.name"
                        [mode]="'edit'"
                        (dashboardNameChange)="dashboard.name = $event"
                        (widgetAdd)="openAddWidgetModal()"
                        (dashboardDelete)="delete()"></ssi-dashboard-navbar>

  <div class="dashboard-content">
    <div class="actions-bar">
      <div class="left">
        <ng-container *ngIf="dashboardTemplates.length">
          <ng-container *ngTemplateOutlet="dashboardTemplatesMenuTemplate"></ng-container>
        </ng-container>
      </div>
      <div class="right">
        <button type="button"
                class="g-btn g-btn-secondary bg-white cancel-btn"
                (click)="activeChanges ? cancelChangesTooltip.show() : cancel()"
                #cancelChangesTooltip="ssiTooltip"
                [ssiTooltip]
                [tooltipWindowClass]="'confirm-action-tooltip-window'"
                [tooltipPlacement]="'bottom'"
                [tooltipTrigger]="'manual'"
                [tooltipTemplate]="cancelChangesTooltipTemplate">Cancel</button>
        <button *ngIf="authUser?.hasCompanyPermission('administer_company')"
                type="button"
                [disabled]="!dashboard.name"
                class="g-btn g-btn-secondary bg-white save-as-template-btn"
                (click)="saveAsTemplate()">Save as {{selectedDashboardTemplate ? 'new' : ''}} dashboard template</button>
        <button type="button"
                [disabled]="!dashboard.name"
                class="g-btn save-btn"
                (click)="selectedDashboardTemplate && authUser?.hasCompanyPermission('administer_company') ? editTooltip.show(): save()"
                #editTooltip="ssiTooltip"
                [ssiTooltip]
                [tooltipWindowClass]="'confirm-action-tooltip-window'"
                [tooltipPlacement]="'bottom'"
                [tooltipTrigger]="'manual'"
                [tooltipTemplate]="editTooltipTemplate">Save</button>
      </div>
    </div>

    <ssi-widgets-grid #widgetsGrid
                      [apiWidgets]="dashboard.widgets"
                      [currentDashboard]="dashboard"
                      (onEditWidget)="editWidget($event)"></ssi-widgets-grid>
  </div>
</div>

<ng-template #cancelChangesTooltipTemplate>
  <ssi-confirm-action title="Looks like you've made some changes, still want to cancel your edits?"
                      [dismissText]="'CANCEL' | translate"
                      [confirmText]="'SAVE_EDITS' | translate"
                      confirmTextColour="#14bae3"
                      dismissTextColour="#43537f"
                      (onDismiss)="cancel()"
                      (onConfirm)="cancelChangesTooltip.hide()"></ssi-confirm-action>
</ng-template>

<ng-template #editTooltipTemplate>
  <ssi-confirm-action title="Do you also want to update the dashboard template?"
                      [dismissText]="'No'"
                      [confirmText]="'Yes, please!'"
                      confirmTextColour="#14bae3"
                      dismissTextColour="#43537f"
                      (onDismiss)="editTooltip.hide(); save()"
                      (onConfirm)="editTooltip.hide(); save(true)"></ssi-confirm-action>
</ng-template>

<ng-template #dashboardTemplatesMenuTemplate>
  <ssi-menu #templatesMenu
            class="dashboard-templates-menu"
            [headAlignment]="'top left'"
            [bodyAlignment]="'top left'">
    <ng-template ssiTemplateSelector="menuHead">
      <button type="button"
              class="g-btn g-btn-secondary bg-white templates-button">
        <i class="ssi ssi-dash-create"></i>
        <span class="head-button-label">{{selectedDashboardTemplate ? selectedDashboardTemplate.name : 'Dashboard templates'}}</span>
        <i class="ssi ssi-arrow-down-new templates-select-arrow-icon"></i></button>
    </ng-template>
    <ng-template ssiTemplateSelector="menuBody">
      <ul class="g-list">
        <li class="g-list-item templates-list-item"
            *ngFor="let template of dashboardTemplates"
            (click)="templatesMenu.close(); applyTemplate(template)">
          <span>{{template.name}}</span>
          <i *ngIf="authUser?.hasCompanyPermission('administer_company')"
             class="ssi ssi-small-delete delete-icon"
             (click)="$event.stopPropagation(); deleteTemplate(template);"></i>
        </li>
      </ul>
    </ng-template>
  </ssi-menu>
</ng-template>
