import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlatpickrModule } from 'angularx-flatpickr';

import { TreetableModule } from '../../../../../common/components/treetable';
import { PaginatorModule } from '../../../../../common/components/paginator';
import { BigNumberModule } from '../../../../../common/pipes/big-number/big-number.module';
import { BigNumberModule as BigNumberComponentModule } from '../../../../../common/components/big-number/big-number.module';
import { AbsModule } from '../../../../../common/pipes/abs/abs.module';
import { SecondsToHumanTimeModule } from '../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.module';
import { Checkbox2Module } from '../../../../../common/components/checkbox-2/checkbox-2.module';
import { ToggleSwitch2Module } from '../../../../../common/components/toggle-switch-2/toggle-switch-2.module';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../common/components/social-network-icon/social-network-icon.module';
import { HighchartModule } from '../../../../../common/components/highchart/highchart.module';
import { ProgressBarModule } from '../../../../../common/components/progress-bar/progress-bar.module';
import { NoResultsModule } from '../../../../../common/components/no-results/no-results.module';
import { LinkifyModule } from '../../../../../common/pipes/linkify/linkify.module';
import { EnlargeImageModule } from '../../../../../common/directives/enlarge-image/enlarge-image.module';
import { ReverseModule } from '../../../../../common/pipes/reverse/reverse.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

import { FallbackImageModule } from '../../../../../common/directives/fallback-image/fallback-image.module';
import { LoaderModule } from '../../../../../common/directives/loader';

import { ReportComponent } from './report.component';
import { ReportHeaderModule } from '../../common/report-header/report-header.module';
import { ReportSidebarModule } from '../../common/report-sidebar/report-sidebar.module';
import { StatChangeModule } from '../../common/stat-change/stat-change.module';
import { SocialNetworkSelectModule } from '../../common/social-network-select/social-network-select.module';

import { SurveyResponsesComponent } from './survey-responses/survey-responses.component';
import { FiltersComponent } from './filters/filters.component';
import { SurveySummaryComponent } from './survey-summary/survey-summary.component';
import { FactOverviewModule } from '../../common/fact-overview/fact-overview.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.survey.report',
          url: '/report?accountIds&startDate&endDate&dateRange',
          component: ReportComponent
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    FlatpickrModule.forRoot(),
    TreetableModule,
    PaginatorModule,
    BigNumberModule,
    BigNumberComponentModule,
    AbsModule,
    SecondsToHumanTimeModule,
    Checkbox2Module,
    ToggleSwitch2Module,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    HighchartModule,
    ProgressBarModule,
    NoResultsModule,
    LinkifyModule,
    EnlargeImageModule,
    ReverseModule,
    TooltipModule,
    FallbackImageModule,
    LoaderModule,
    ReportHeaderModule,
    ReportSidebarModule,
    StatChangeModule,
    SocialNetworkSelectModule,
    FactOverviewModule
  ],
  declarations: [
    ReportComponent,
    SurveyResponsesComponent,
    FiltersComponent,
    SurveySummaryComponent
  ]
})
export class ReportModule {}
