<div class="calendar-event-modal">
  <div class="calendar-event-modal-header">
    <div class="calendar-event-modal-header-left">
      <h2 translate="CALENDAR_EVENT"></h2>
    </div>
    <div class="calendar-event-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="calendar-event-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="calendar-event-modal-body">

    <div class="calendar-event-modal-action calendar-event-modal-body-row">
      <div
        class="input-wrapper"
        #eventTitleContainer
      >
        <label translate="TITLE"></label>
        <input
          class="field-input title"
          [class.has-value]="eventTitle"
          type="text"
          name="eventTitle"
          placeholder="{{'ADD_A_TITLE' | translate}}"
          [(ngModel)]="eventTitle"
        >
        <span
          [class.invalid-value]="eventTitle?.length > 60"
          class="char-counter"
        >{{60 - eventTitle?.length}}
        </span>
      </div>
      <div
        class="input-wrapper"
        #eventTitleContainer
      >
        <label translate="CHANGE_COLOUR"></label>
        <button
          class="orlo-button primary colour-picker"
          type="button"
          [style.background]="eventColour ? eventColour : '#2889e9'"
          [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
          [cpOutputFormat]="'rgba'"
          [cpAlphaChannel]="'disabled'"
          [(colorPicker)]="eventColour"
        ><i class="ssi ssi-plus"></i></button>
      </div>
     
    </div>
    <div class="calendar-event-modal-action calendar-event-modal-body-row">
      <div
        class="input-wrapper"
        [class.has-value]="startDate"
        #startDateContainer
      >
        <label translate="START_DATE"></label>
        <input
          mwlFlatpickr
          class="field-input"
          type="text"
          name="startDate"
          [(ngModel)]="startDate"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          [defaultHour]="00"
          [defaultMinute]="00"
          [defaultSeconds]="00"
          dateFormat="Y-m-d H:i"
          altFormat="M J Y, H:i"
          [minDate]="startDate"
          [time24hr]="true"
          [appendTo]="startDateContainer.nativeElement"
          placeholder="Select start date..."
          [required]="true"
        >
        <i class="ssi ssi-ad-schedule"></i>
      </div>
      <div
        class="input-wrapper"
        [class.has-value]="endDate"
        #endDateContainer
      >
        <label translate="END_DATE"></label>
        <input
          mwlFlatpickr
          class="field-input"
          type="text"
          name="endDate"
          [(ngModel)]="endDate"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          [defaultHour]="00"
          [defaultMinute]="00"
          [defaultSeconds]="00"
          dateFormat="Y-m-d H:i"
          altFormat="M J Y, H:i"
          [minDate]="startDate"
          [time24hr]="true"
          [appendTo]="endDateContainer.nativeElement"
          placeholder="Select end date..."
          [required]="true"
        >
        <i class="ssi ssi-ad-schedule"></i>
      </div>
    </div>
  </div>

  <div class="calendar-event-modal-footer">
    <div class="calendar-event-modal-footer-left">
      <orlo-button
        class="calendar-event-modal-button"
        *ngIf="event"
        [textColor]="'#43537F'"
        [bgColor]="'transparent'"
        state="primary"
        [leadingIcon]="'ssi ssi-small-delete'"
        text="Delete Calendar Event"
        (btnClick)="deleteEventTooltip.show()"
        #deleteEventTooltip="ssiTooltip"
        [ssiTooltip]
        tooltipWindowClass="confirm-action-tooltip-window"
        [tooltipPlacement]="isMobile ? 'top' : 'right'"
        tooltipTrigger="manual"
        [tooltipTemplate]="deleteEventTooltipTemplate"
      >
        >
      </orlo-button>
    </div>
    <div class="calendar-event-modal-footer-right">
      <orlo-button
        class="calendar-event-modal-button calendar-event-modal-button-secondary"
        [text]="'CANCEL' | translate"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        class="calendar-event-modal-button"
        [text]="'SAVE' | translate"
        [textColor]="(!startDate || !endDate || eventTitle.length > 60 || !eventTitle) ? '#838EAB' : '#FFF'"
        [bgColor]="(!startDate || !endDate || eventTitle.length > 60 || !eventTitle) ? '#F4F4FA' : '#14BAE3'"
        [disabled]="!startDate || !endDate || eventTitle.length > 60 || !eventTitle"
        (click)="saveEvent()"
      ></orlo-button>
    </div>
  </div>
</div>

<ng-template #deleteEventTooltipTemplate>
  <ssi-confirm-action
    title="Are you sure you want to delete this event permanently?"
    dismissText="Cancel"
    confirmText="Delete"
    confirmTextColour="#F88C68"
    dismissTextColour="#43537f"
    (onDismiss)="deleteEventTooltip.hide()"
    (onConfirm)="deleteEvent()"
  ></ssi-confirm-action>
</ng-template>