<div class="save-draft-modal">
  <div class="save-draft-modal-header">
    <div class="save-draft-modal-header-left">
      <h2>Link Editor</h2>
    </div>
    <div class="save-draft-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="save-draft-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div
    class="save-draft-modal-body"
    *ngIf="loader.active"
  >
    <div class="loading"></div>
  </div>

  <div
    class="save-draft-modal-body"
    *ngIf="! loader.active"
  >
    <ng-container *ngIf="linkData.data.preview.images && linkData.data.preview.images.length > 0; else elseTemplate">
      <div class="save-draft-modal-body-row save-draft-modal-body-row-parent">
        <div class="save-draft-modal-body-col">
          <p class="edit-links-label">Link Title Preview</p>
          <p class="edit-link-title">{{linkData.data.preview.title}}</p>
          <p class="edit-links-label">Link Description Preview</p>
          <p class="edit-link-desc">{{linkData.data.preview.description}}</p>
        </div>
        <div class="save-draft-modal-body-col save-draft-modal-body-col-grow">
          <p class="edit-links-label">Link Preview Image</p>
          <ng-template #previewWithoutNav>
            <img
              class="edit-links-preview"
              [style.background-image]="'url(' + linkData.data.preview.images[0].url + ')'"
            >
          </ng-template>
          <ssi-media-nav
            [variant]="'redesign'"
            *ngIf="canSetCustomLinkedInImage && supportsCustomLinkOption; else previewWithoutNav"
            class="nav-btn-sm"
            [(activeFileIndex)]="linkData.data.preview.selectedImageIndex"
            (activeFileIndexChange)="activeImageIndexChanged($event)"
            [files]="linkData.data.preview.images"
            [useBackgroundImage]="true"
          ></ssi-media-nav>
          <button
            *ngIf="canSetCustomLinkedInImage && supportsCustomLinkOption"
            class="add-image-button"
            (click)="openAddCustomImage()"
          ><i class="ssi ssi-addsmall"></i>New image</button>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="error-box">
        <div class="error-message">
          <i class="ssi ssi-warning"></i>
          <p>The website you are sharing a link to is blocking us from generating a preview.
          </p>
        </div>
      </div>
    </ng-template>

    <div class="save-draft-modal-body-row">
      <label
        class="save-draft-modal-label"
        for="link-tracking"
      >
        <span>Link tracking</span>
        <i
          class="ssi ssi-information"
          ssiTooltip="This shortens the link & let's you know how many people have clicked on it. Turning this off will prevent Orlo from being able to see how many people have clicked on your link & will also turn off UTM Tracking."
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky-big-white"
        ></i>
      </label>
      <div class="save-draft-modal-body-row-input">
        <div class="dwm-body-row-input">
          <ssi-toggle-switch-2
            name="link-tracking"
            class="save-draft-modal-body-row-input-toggle"
            [class.toggled]="linkTracking"
            [label]="'Link Tracking is ' + (linkTracking ? 'on': 'off')"
            [(ngModel)]="linkTracking"
            (ngModelChange)="onLinkTrackingChange()"
          ></ssi-toggle-switch-2>
        </div>
      </div>
    </div>

    <div class="save-draft-modal-body-row">
      <label
        class="save-draft-modal-label"
        for="utm-tracking"
      >
        <span>UTM</span>
        <i
          class="ssi ssi-information"
          ssiTooltip="Turning this off will prevent Orlo from being able to add it's own UTM parameters. You can turn this off and add your own parameters by including them in the link you're sharing."
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky-big-white"
        ></i>
      </label>
      <div class="save-draft-modal-body-row-input">
        <div class="dwm-body-row-input">
          <ssi-toggle-switch-2
            name="utm-tracking"
            [disabled]="! linkTracking"
            class="save-draft-modal-body-row-input-toggle"
            [class.toggled]="utmTracking"
            [label]="'UTM is ' + (utmTracking ? 'on': 'off')"
            [(ngModel)]="utmTracking"
            (ngModelChange)="onFormChange()"
          ></ssi-toggle-switch-2>
        </div>
      </div>
    </div>

    <div class="save-draft-modal-body-row">
      <ng-container *ngIf="! linkTracking">
        <p class="edit-links-warning-heading">no link tracking available:</p>
        <p class="edit-links-warning">Link Tracking turned off. If Orlo isn't able to shorten the URL you are sharing,
          we can't tell how many times the link has been clicked. This means that you will not be able to report on link
          clicks within our analytics.</p>
      </ng-container>
      <ng-container *ngIf="linkTracking">

        <label
          class="save-draft-modal-label"
          for="customise-link"
        >
          <span>Customise your link</span>
          <i
            class="ssi ssi-information"
            ssiTooltip="Customising your link can be useful to give your audience an idea of what they are clicking on, e.g. you could type the name of your event or campaign. It's optional, so no worries if nothing comes to mind."
            tooltipPlacement="top"
            tooltipWindowClass="tooltip-chunky-big-white"
          ></i>
        </label>

        <div class="save-draft-modal-body-row-input">
          <div class="publisher-link-config-prefix">
            <!-- <span class="input-group-addon">http://{{ post.vanityDomains?.primary.domain || 'socsi.in' }}/</span> -->
            <div class="publisher-link-config-prefix-static">
              <span>http://{{ vanityDomain || 'socsi.in' }}/</span>
              <i
                class="ssi ssi-information"
                [ssiTooltip]="vanityDomain ? 'This is your vanity URL and each link shared will begin with this. You can change this URL in your settings.' : 'This is Orlo\'s vanity URL, each link shared will begin with this. If you own your own vanity URL, you can connect that to Orlo to be used instead in your settings.'"
                tooltipPlacement="top"
                tooltipWindowClass="tooltip-chunky-big-white"
              ></i>
            </div>
            <div class="input-wrapper">
              <input
                type="text"
                name="customise-link"
                placeholder="Type option custom prefix..."
                [(ngModel)]="prefix"
                (ngModelChange)="onFormChange()"
                autofocus
                autocomplete="off"
              >
              <i
                class="ssi ssi-information"
                ssiTooltip="Here you could type the name of your event or campaign so your audience know what they are clicking on. Remember it's optional, so no worries if nothing comes to mind!"
                tooltipPlacement="top"
                tooltipWindowClass="tooltip-chunky-big-white"
              ></i>
            </div>
            <div class="publisher-link-config-prefix-static">
              {{ linkData.data.link || 'xxxxx' }}
              <i
                class="ssi ssi-information"
                ssiTooltip="This is a set of randomised alpha numerical characters to ensure your link is unique."
                tooltipPlacement="top"
                tooltipWindowClass="tooltip-chunky-big-white"
              ></i>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

  </div>

  <div class="save-draft-modal-footer">
    <div class="save-draft-modal-footer-left"></div>
    <div class="save-draft-modal-footer-right">
      <orlo-button
        class="save-draft-modal-button save-draft-modal-button-secondary"
        text="Cancel"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        class="save-draft-modal-button"
        text="Save Link Edits"
        textColor="#fff"
        bgColor="#14BAE3"
        [disabled]="! hasChanges"
        (click)="saveLinkEdits()"
      ></orlo-button>
    </div>
  </div>
</div>