export function parseBooleanQuery(query) {
  // // test cases
  // parseBooleanQuery(''); //
  // parseBooleanQuery('  '); //
  // parseBooleanQuery('a   d'); // AND: ['a', 'd']
  // parseBooleanQuery(' a  d '); // AND: ['a', 'd']
  // parseBooleanQuery(' "b c" '); // AND: ['b c']
  // parseBooleanQuery('cat dog mat'); // AND: ['cat', 'dog', 'mat']
  // parseBooleanQuery('cat | dog mat'); // AND: ['mat'], ANY: ['cat', 'dog']
  // parseBooleanQuery('cat | dog mat -doggy'); // AND: ['mat'], ANY: ['cat', 'dog'], NONE: ['doggy']
  // parseBooleanQuery('cat | dog | fox - doggy "cat sat"'); // AND: ['doggy', 'cat sat'], ANY: ['cat', 'dog', 'fox'] // invalid NOT
  // parseBooleanQuery('a "b c" "df" g "h i'); // AND: ['a', 'b c', 'df', 'g', 'h i']
  // parseBooleanQuery('a "b c" -e f | g g'); // AND: ['b c', 'a', 'g'], ANY: ['f', 'g'], NONE: ['e']
  // parseBooleanQuery('"cat sat" -"dog sat" ?'); // AND: ['cat sat', 'dog sat', '?'] // invalid NOT

  // parseBooleanQuery('test tag | tagging -id'); // AND: ['test'], ANY: ['tag', 'tagging'], NONE: ['id']

  let and = [];
  let any = [];
  let none = [];

  query = removeExtraSpaces(query);

  const exact = getExactPhrases(query);
  query = exact.query;
  and = [...and, ...exact.phrases];

  const notContainsWords = getNotContainsWords(query);
  query = notContainsWords.query;
  none = [...none, ...notContainsWords.words];
  //   console.log('none: ', none);

  const orWords = getOrWords(query);
  query = orWords.query;
  any = [...any, ...orWords.words];
  //   console.log('any: ', any);

  const andWords = getAndWords(query);
  query = andWords.query;
  and = [...and, ...andWords.words];
  //   console.log('and: ', and);

  return {
    and,
    any,
    none
  };
}

function removeExtraSpaces(str) {
  // including tabs, newlines, etc.

  // // test cases
  // removeExtraSpaces('a   d'); // 'a d';
  // removeExtraSpaces(' a  d'); // 'a d';
  // removeExtraSpaces(' a  d '); // 'a d';
  return str.trim().replace(/\s\s+/g, ' ');
}

function getExactPhrases(query, phrases = []) {
  // // test cases
  // getExactPhrases('a "b c" d').phrases; // 'b c';
  // getExactPhrases('a "b c d').phrases; // 'b c d';
  // getExactPhrases('"a b c d').phrases; // 'a b c d';
  // getExactPhrases('"a b c d"').phrases; // 'a b c d';
  // getExactPhrases('a b c d').phrases; // '';
  // getExactPhrases('a "b c" d "e f"').phrases; // 'b c' and 'e f';
  // getExactPhrases('a "b c" d "e f').phrases; // 'b c' and 'e f';
  // getExactPhrases('a "b c" d "e f" g ""').phrases; // 'b c' and 'e f';
  // getExactPhrases('"" a "b c" d "e f" g ""').phrases; // 'b c' and 'e f';

  if (!query.includes('"')) {
    // returns modified query (question marks and value between removed) and array of extracted phrases
    phrases = phrases.map((p) => p.replace(/"/g, '')).filter((p) => !!p);
    return { query, phrases };
  }

  query = removeExtraSpaces(query);

  const start = query.substring(query.indexOf('"') + 1);
  const endIdx = start.indexOf('"');
  const phrase =
    endIdx === -1 ? '"' + start : '"' + start.substring(0, endIdx) + '"';

  if (phrases.indexOf(phrase) === -1) {
    phrases.push(phrase);
  }
  query = query.replace(phrase, '');

  return getExactPhrases(query, phrases);
}

function getNotContainsWords(query, words = []) {
  // // test cases
  // getNotContainsWords('a -b -c d').words; // 'b', 'c';
  // getNotContainsWords('a -b -c d -e').words; // 'b', 'c', 'e';
  // getNotContainsWords('- a -b -c d -e f -g').words; // 'b', 'c', 'e', 'g';
  // getNotContainsWords('-a -b -c | d -a f -g h -').words; // 'a', 'b', 'c', 'g';

  if (!query.includes('-')) {
    // returns modified query (question marks and value between removed) and array of extracted words
    words = words.map((p) => p.replace(/-/g, '')).filter((p) => !!p);
    return { query, words };
  }

  query = removeExtraSpaces(query);

  const start = query.substring(query.indexOf('-') + 1);
  const endIdx = start.indexOf(' ');
  const word = endIdx === -1 ? '-' + start : '-' + start.substring(0, endIdx);

  if (words.indexOf(word) === -1) {
    words.push(word);
  }
  query = query.replace(word, '');

  return getNotContainsWords(query, words);
}

function getOrWords(query, words = []) {
  // // test cases
  // getOrWords('|'); // ;
  // getOrWords('a | b | c d'); // 'a', 'b', 'c';
  // getOrWords('a | b b | c d'); // 'a', 'b', 'c';
  // getOrWords('a | b | c d | e'); // 'a', 'b', 'c', 'd', 'e';
  // getOrWords('| a | b | c c d | e f | g'); // 'a', 'b', 'c', 'd', 'e', 'f', 'g';
  // getOrWords('|a | bbb| c | d | e f | g h |'); // 'a', 'bbb', 'c', 'd', 'e', 'f', 'g', 'h';

  if (!query.includes('|')) {
    // returns modified query (question marks and value between removed) and array of extracted words
    words = words.map((p) => p.replace(/\|/g, '')).filter((p) => !!p);
    return { query, words };
  }

  query = removeExtraSpaces(query);

  const firstPipeIdx = query.indexOf('|');
  const strBefore = query.substring(0, firstPipeIdx);
  const strAfter = query.substring(firstPipeIdx + 1);

  let wordBeforePipeStartIndex = -1;
  for (let i = strBefore.length - 1; i >= 0; i--) {
    if (i !== strBefore.length - 1 && strBefore[i] === ' ') {
      wordBeforePipeStartIndex = i;
      break;
    }
  }

  const wordBefore = strBefore.substring(wordBeforePipeStartIndex + 1);
  if (words.indexOf(wordBefore.trim()) === -1) {
    words.push(wordBefore.trim());
  }

  let wordAfterPipeEndIndex = strAfter.length;
  for (let i = 0; i <= strAfter.length - 1; i++) {
    if (i !== 0 && strAfter[i] === ' ') {
      wordAfterPipeEndIndex = i;
      break;
    }
  }

  const wordAfter = strAfter.substring(0, wordAfterPipeEndIndex + 1);
  if (words.indexOf(wordAfter.trim()) === -1) {
    words.push(wordAfter.trim());
  }

  query = query.replace(wordBefore + '|' + wordAfter, '');

  return getOrWords(query, words);
}

function getAndWords(query, words = []) {
  query = removeExtraSpaces(query);

  return {
    query: '',
    words: query.split(' ').filter((w) => !!w)
  };
}
