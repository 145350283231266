import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { DiagnosticsComponent } from './diagnostics.component';

@NgModule({
  declarations: [DiagnosticsComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'diagnostics',
          url: '/diagnostics?restore',
          component: DiagnosticsComponent,
          resolve: DiagnosticsComponent.resolve
        }
      ]
    })
  ]
})
export class DiagnosticsModule {}
