import { Component, Input, OnInit, ViewChild } from '@angular/core';
import _ from 'lodash';
import { API, UserModel } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import liveChatConfig from '../../../../../../../../library/constants/live-chat';
import {
  CUSTOM_SOURCE_NAME,
  FilestackClient,
  FilestackPickOptions,
  FilestackService
} from '../../../../../../common/services/filestack/filestack.service';
import { CompanyService } from '../../../../../../common/services/company/company.service';
import {
  acceptedFileTypesForImages,
  permittedSources,
  maximumFileSizeForImagesInBytes
} from '../../../../../../../../library/constants/filestack';
import './edit-widget.scss';
import { TooltipDirective } from '../../../../../../common/directives/tooltip/tooltip.directive';
import {
  ChatBot,
  ChatBotService
} from '../../../../../../common/services/chat-bot/chat-bot.service';

const AVAILABLE_VISITOR_MESSAGES_POSITIONS = [
  {
    name: `Left`,
    value: `LEFT`
  },
  {
    name: `Right`,
    value: `RIGHT`
  }
];

const AVAILABLE_WIDGET_POSITIONS = [
  {
    name: `Top left`,
    value: 'TOP_LEFT'
  },
  {
    name: `Upper left`,
    value: 'UPPER_LEFT'
  },
  {
    name: `Top right`,
    value: `TOP_RIGHT`
  },
  {
    name: `Upper right`,
    value: `UPPER_RIGHT`
  },
  {
    name: `Bottom right`,
    value: `BOTTOM_RIGHT`
  },
  {
    name: `Lower right`,
    value: `LOWER_RIGHT`
  },
  {
    name: `Bottom left`,
    value: `BOTTOM_LEFT`
  },
  {
    name: `Lower left`,
    value: `LOWER_LEFT`
  }
];

const WELCOME_FIELD_TYPES = [
  {
    name: 'Text',
    value: 'text'
  },
  {
    name: 'Number',
    value: 'number'
  },
  {
    name: 'Date',
    value: 'date'
  },
  {
    name: 'Telephone',
    value: 'tel'
  },
  {
    name: 'Email',
    value: 'email'
  }
];

const MAX_IMAGE_SIZE = 5000000;

const QUEUE_GROUPS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

@Component({
  selector: 'ssi-edit-widget',
  templateUrl: './edit-widget.component.html',
  styles: []
})
export class EditWidgetComponent implements OnInit {
  @ViewChild('deleteWidgetTooltip') deleteWidgetTooltip: TooltipDirective;

  activeView = 'website';
  widget;
  chatBots: ChatBot[];
  widgetForm: FormGroup;
  availableWidgetPositions: any[] = AVAILABLE_WIDGET_POSITIONS;
  availableVisitorMessagesPositions: any[] = AVAILABLE_VISITOR_MESSAGES_POSITIONS;
  welcomeFieldTypes: any[] = WELCOME_FIELD_TYPES;
  queueGroups: number[] = QUEUE_GROUPS;
  user;
  savingWidget: boolean = false;
  widgetEnabled: boolean;
  chatbotEnabled: boolean;
  canVisitorsViewArchivedConversations: boolean;
  checkErrors: boolean = false;
  slotStrings: string[];

  constructor(
    public api: API,
    private state: StateService,
    private filestack: FilestackService,
    private userModel: UserModel,
    private company: CompanyService,
    private chatbotService: ChatBotService
  ) {}

  async ngOnInit() {
    try {
      if (!this.state.params.widget) {
        this.state.go('^');
      } else {
        this.chatBots = await this.chatbotService.getChatBotList();
        this.widget = this.state.params.widget;
        this.generateForm();
        this.widgetEnabled = !this.widget.isDisabled;
        this.chatbotEnabled = this.widget.chatbotId;
        this.canVisitorsViewArchivedConversations = this.widget.canVisitorsViewArchivedConversations;
        this.user = await this.userModel.getAuthUser();

        this.slotStrings = Object.keys(this.widget.welcomeFormFields);
      }
    } catch (error) {
      console.error('Error loading Edit widget page:', error);
    }
  }

  public generateForm() {
    this.widgetForm = new FormGroup({
      name: new FormControl(this.widget.name, [
        Validators.required,
        Validators.maxLength(20)
      ]),
      customerContactEmail: new FormControl(this.widget.customerContactEmail, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      customerContactLink: new FormControl(this.widget.customerContactLink),
      useContactLink: new FormControl(this.widget.useContactLink),
      isTriggerHidden: new FormControl(this.widget.isTriggerHidden),
      visitorMessagesPosition: new FormControl(
        this.widget.visitorMessagesPosition
      ),
      baseFontSize: new FormControl(this.widget.baseFontSize, [
        Validators.required
      ]),
      position: new FormControl(this.widget.position),
      widgetHorizontalPadding: new FormControl(
        this.widget.widgetHorizontalPadding,
        [Validators.required]
      ),
      widgetVerticalPadding: new FormControl(
        this.widget.widgetVerticalPadding,
        [Validators.required]
      ),
      canVisitorsUpload: new FormControl(this.widget.canVisitorsUpload),
      startPrompt: new FormControl(this.widget.startPrompt, [
        Validators.required
      ]),
      widgetTitle: new FormControl(this.widget.widgetTitle, [
        Validators.required
      ]),
      namePrompt: new FormControl(this.widget.namePrompt, [
        Validators.required
      ]),
      emailPrompt: new FormControl(this.widget.emailPrompt, [
        Validators.required
      ]),
      welcomeTitle: new FormControl(this.widget.welcomeTitle, [
        Validators.required
      ]),
      welcomeMessage: new FormControl(this.widget.welcomeMessage, [
        Validators.required
      ]),
      welcomeFormFields: new FormGroup({
        slotOne: new FormGroup({
          enabled: new FormControl(
            this.widget.welcomeFormFields.slotOne.enabled
          ),
          fieldType: new FormControl(
            this.widget.welcomeFormFields.slotOne.fieldType
          ),
          label: new FormControl(this.widget.welcomeFormFields.slotOne.label),
          placeholder: new FormControl(
            this.widget.welcomeFormFields.slotOne.placeholder
          ),
          required: new FormControl(
            this.widget.welcomeFormFields.slotOne.required
          )
        }),
        slotTwo: new FormGroup({
          enabled: new FormControl(
            this.widget.welcomeFormFields.slotTwo.enabled
          ),
          fieldType: new FormControl(
            this.widget.welcomeFormFields.slotTwo.fieldType
          ),
          label: new FormControl(this.widget.welcomeFormFields.slotTwo.label),
          placeholder: new FormControl(
            this.widget.welcomeFormFields.slotTwo.placeholder
          ),
          required: new FormControl(
            this.widget.welcomeFormFields.slotTwo.required
          )
        })
        // slotThree: new FormGroup({
        //   enabled: new FormControl(
        //     this.widget.welcomeFormFields.slotThree.enabled
        //   ),
        //   fieldType: new FormControl(
        //     this.widget.welcomeFormFields.slotThree.fieldType
        //   ),
        //   label: new FormControl(this.widget.welcomeFormFields.slotThree.label),
        //   placeholder: new FormControl(
        //     this.widget.welcomeFormFields.slotThree.placeholder
        //   ),
        //   required: new FormControl(
        //     this.widget.welcomeFormFields.slotThree.required
        //   )
        // }),
        // slotFour: new FormGroup({
        //   enabled: new FormControl(
        //     this.widget.welcomeFormFields.slotFour.enabled
        //   ),
        //   fieldType: new FormControl(
        //     this.widget.welcomeFormFields.slotFour.fieldType
        //   ),
        //   label: new FormControl(this.widget.welcomeFormFields.slotFour.label),
        //   placeholder: new FormControl(
        //     this.widget.welcomeFormFields.slotFour.placeholder
        //   ),
        //   required: new FormControl(
        //     this.widget.welcomeFormFields.slotFour.required
        //   )
        // })
      }),
      agentsOfflineNotice: new FormControl(this.widget.agentsOfflineNotice, [
        Validators.required
      ]),
      firstResponse: new FormControl(this.widget.firstResponse, [
        Validators.required
      ]),
      lastAgentNotice: new FormControl(this.widget.lastAgentNotice, [
        Validators.required
      ]),
      resolutionNoticeMessage: new FormControl(
        this.widget.resolutionNoticeMessage,
        [Validators.required]
      ),
      resolutionGracePeriod: new FormControl(
        this.widget.resolutionGracePeriod,
        [Validators.required]
      ),
      autoResolutionTimeout: new FormControl(
        this.widget.autoResolutionTimeout,
        [Validators.required]
      ),
      maximumQueueSize: new FormControl(this.widget.maximumQueueSize, [
        Validators.required
      ]),
      queueGroup: new FormControl(this.widget.queueGroup),
      disabledMessage: new FormControl(this.widget.disabledMessage, [
        Validators.required
      ]),
      publicKey: new FormControl(this.widget.publicKey)
    });

    if (
      this.hasChatbotFeature &&
      (this.widget.chatbotId || (this.chatBots && this.chatBots.length) > 0)
    ) {
      this.widgetForm.addControl(
        'chatbotId',
        new FormControl(
          this.widget.chatbotId ? this.widget.chatbotId : this.chatBots[0].id
        )
      );
    }
  }

  public delete() {
    this.api
      .del(liveChatConfig.routes.widget, {
        params: { id: this.widget.id }
      })
      .then((response) => {
        if (response.status === 200) {
          this.return();
        }
      });
  }

  public save() {
    if (this.widgetForm.status === 'INVALID') {
      this.checkErrors = true;
      return;
    } else {
      this.checkErrors = false;
    }

    this.widgetForm.value.isDisabled = !this.widgetEnabled;
    this.widgetForm.value.chatbotId = this.chatbotEnabled
      ? this.widgetForm.value.chatbotId
      : null;
    this.widgetForm.value.visitorViewArchivedConversations = this.canVisitorsViewArchivedConversations;
    const parameters = Object.assign(
      {},
      this.state.params.widget.asParameters,
      this.widgetForm.value
    );
    const { id } = parameters;

    this.savingWidget = true;
    // fix for known issue with angular [type]
    parameters.baseFontSize = parseInt(parameters.baseFontSize, 10);
    parameters.widgetHorizontalPadding = parseInt(
      parameters.widgetHorizontalPadding,
      10
    );
    parameters.widgetVerticalPadding = parseInt(
      parameters.widgetHorizontalPadding,
      10
    );
    parameters.autoResolutionTimeout = parseInt(
      parameters.autoResolutionTimeout,
      10
    );
    parameters.resolutionGracePeriod = parseInt(
      parameters.resolutionGracePeriod,
      10
    );
    parameters.customerContactLink = parameters.useContactLink
      ? parameters.customerContactLink
      : '';

    parameters.chatbotName = parameters.chatbotId
      ? this.chatBots[
          this.chatBots.findIndex(
            (chatbot) => chatbot.id === parameters.chatbotId
          )
        ].name
      : null;

    if (id) {
      this.api
        .put(`${liveChatConfig.routes.widget}?id=${id}`, parameters)
        .then((response) => {
          if (response.status === 200) {
            this.savingWidget = false;
            this.return();
          }
        });
    } else {
      this.api
        .post(liveChatConfig.routes.widget, parameters)
        .then((response) => {
          if (response.status === 200) {
            this.savingWidget = false;
            this.return();
          }
        });
    }
  }

  public async uploadCustomIcon() {
    const client = await this.filestack.getClient();
    const customSourcePath: string = `company-files/${this.user.company_id}/`;
    const options: FilestackPickOptions = {
      accept: acceptedFileTypesForImages,
      customSourcePath,
      disableTransformer: true,
      fromSources: permittedSources,
      maxFiles: 1,
      maxSize: maximumFileSizeForImagesInBytes,
      onUploadDone: (result) => {
        if (
          !(
            !!result &&
            !!result.filesUploaded &&
            !!Array.isArray(result.filesUploaded) &&
            !!result.filesUploaded.length &&
            !!result.filesUploaded[0] &&
            !!result.filesUploaded[0].url
          )
        ) {
          throw new Error(`Unexpected response in file upload!`);
        }

        if (result.filesUploaded[0].size >= maximumFileSizeForImagesInBytes) {
          throw new Error(`File over size limit.`);
        }

        this.widget.customIcon = result.filesUploaded[0].url;
      },
      customSourceName: CUSTOM_SOURCE_NAME
    };

    client.picker(options).open();
  }

  public async uploadHeaderImage() {
    const client = await this.filestack.getClient();
    const options: FilestackPickOptions = {
      imageDim: [350, 170],
      maxFiles: 1,
      accept: ['image/png'],
      maxSize: MAX_IMAGE_SIZE,
      transformations: {
        crop: {
          aspectRatio: 2.05 / 1 // 350 by 170 = 2.0588
        }
      },
      onUploadDone: (result) => {
        if (
          !(
            !!result &&
            !!result.filesUploaded &&
            !!Array.isArray(result.filesUploaded) &&
            !!result.filesUploaded.length &&
            !!result.filesUploaded[0] &&
            !!result.filesUploaded[0].url
          )
        ) {
          throw new Error(`Unexpected response in file upload!`);
        }

        if (result.filesUploaded[0].size >= MAX_IMAGE_SIZE) {
          throw new Error(`File over size limit.`);
        }
        this.widget.headerImage = result.filesUploaded[0].url;
      }
    };

    client.picker(options).open();
  }

  public hasChatbotFeature() {
    return this.company
      .hasFeatureAccess('CHAT_BOT')
      .then((isEnabled) => isEnabled);
  }

  return() {
    this.state.go('^');
  }
}
