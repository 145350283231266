import './text-editor.component.scss';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'ssi-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: []
})
export class TextEditorComponent implements OnInit {
  @Input() policy;
  @Input() height: string;
  @Output() policyChange = new EventEmitter();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    width: 'auto',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: 'Times',
    defaultFontSize: '',
    fonts: [{ class: 'times-new-roman', name: 'Times New Roman' }],
    sanitize: false,
    toolbarPosition: 'top'
  };

  constructor() {}

  ngOnInit() {}
}
