import './network-engagement.component.scss';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-network-engagement',
  templateUrl: './network-engagement.component.html',
  styles: []
})
export class NetworkEngagementComponent {
  @Input() sourcesData;
  @Input() languageData;

  constructor() {}
}
