import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { isNumeric } from '../../utils';

const ONE_MILLION = 1_000_000;
const ONE_THOUSAND = 1_000;

export interface BigNumberDenotation {
  million: string;
  thousand: string;
}

@Pipe({
  name: 'bigNumber'
})
export class BigNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    value: number | string = 0,
    denotation: BigNumberDenotation = { million: 'M', thousand: 'K' }
  ): any {
    if (!isNumeric(value)) {
      return 0;
    }

    const num = Number(value);

    if (Math.abs(num) >= ONE_MILLION) {
      return `${+(num / ONE_MILLION).toFixed(1)}${denotation.million}`;
    }

    if (Math.abs(num) >= ONE_THOUSAND) {
      return `${+(num / ONE_THOUSAND).toFixed(1)}${denotation.thousand}`;
    }

    return this.decimalPipe.transform(num, '1.0-2');
  }
}
