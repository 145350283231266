import './cl-accordion.component.scss';

import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'ssi-cl-accordion',
  templateUrl: './cl-accordion.component.html',
  styles: []
})
export class ClAccordionComponent implements OnInit {
  accordionOpen = 0;

  constructor() {}

  ngOnInit() {}
}
