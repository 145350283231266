

<div #header class="heading-container">
  <orlo-button
    [text]="'Back'"
    (click)="backToReports()"
    [textColor]="'#14BAE3'"
    [bgColor]="'#fff'"
    [leadingIcon]="'ssi ssi-line-arrow-small-down'"
  ></orlo-button>
  <h1>{{adminMode.label}} report</h1>
</div>
<div class="content-wrapper">
  <form
    *ngIf="reportForm"
    (ngSubmit)="createReport()"
    [formGroup]="reportForm"
    class="content-form"
  >
    <div *ngIf="adminMode.id === adminModes.edit.id" class="delete">
      <ssi-icon
        icon="bin"
        height="14"
        width="14"
        color="#838eab"
        (click)="deleteReportTooltip.show()"
        #deleteReportTooltip="ssiTooltip"
        [ssiTooltip]
        tooltipPlacement="left"
        tooltipTrigger="manual"
        [tooltipTemplate]="deleteReportTooltipTemplate">
      </ssi-icon>
    </div>
    <ng-template #deleteReportTooltipTemplate>
      <div
        class="delete-report-tooltip"
        (ssiOffClick)="deleteReportTooltip.hide()"
      >
        <h5>Are you sure you want to delete this Report permanently?</h5>
        <div class="buttons-container">
          <orlo-button
            [state]="'text'"
            [text]="'Cancel'"
            [type]="'button'"
            [textColor]="'#838eab'"
            (btnClick)="deleteReportTooltip.hide()"
          ></orlo-button>
          <orlo-button
            [text]="'Delete'"
            [textColor]="'#e29e8c'"
            (btnClick)="deleteReport(report)"
          ></orlo-button>
        </div>
      </div>
    </ng-template>
    <div class="form-group" style="max-width: 990px">
      <div class="input-container">
        <orlo-text-input
          [label]="'Report name'"
          [name]="'name'"
          [parentForm]="reportForm"
          [type]="'text'"
          [required]="true"
          [placeholder]="'Name your report...'"
          [invalid]="reportForm.controls.name?.touched && reportForm.controls.name?.invalid ? true : false"
          [invalidMessage]="reportForm.controls.name?.touched && reportForm.controls.name?.invalid && reportForm.controls.name.errors.maxlength ? 'Please enter a shorter name' : 'Woops! We\'ll need you to fill this one in...'"
        ></orlo-text-input>
        <span
          class="input-counter"
          [class.error]="reportForm.value.name.length > 50"
        >{{reportForm.value.name.length}} / 50</span>
      </div>
      <div class="input-container">
        <orlo-text-input
          [label]="'Report description'"
          [name]="'description'"
          [parentForm]="reportForm"
          [type]="'text'"
          [required]="false"
          [placeholder]="'Explain the purpose of this report...'"
          [invalid]="reportForm.controls.description.errors?.maxlength"
          [invalidMessage]="reportForm.controls.description.errors?.maxlength && 'Please enter a shorter description'"
        ></orlo-text-input>
        <span
          class="input-counter"
          [class.error]="reportForm.value.description.length > 80"
        >{{reportForm.value.description.length}} / 80</span>
      </div>
    </div>

    <div class="form-section">
      <label class="section-title">companies included in report</label>
      <div class="profile-grid">
        <ng-container *ngFor="let profile of profileGroups">
          <div *ngIf="report.profile_group_ids.includes(+profile.id)" class="profile-grid-item">
            <div class="profile profile-included" [class.focus]="report.focus_id == profile.id">
              <div
                class="profile-avatar"
                [ngStyle]="profile.firstPickedAvatar ? {'background-image': 'url(' + profile.firstPickedAvatar + ')'} : {'background-color': profile.colour}"
                (click)="focusProfile(profile.id)"
              >
                <i class="ssi ssi-target"></i>
              </div>
              <div class="profile-meta">
                <p>{{profile.name}}</p>
                <ul>
                  <li *ngFor="let network of profile.profiles">
                    <i [ngClass]="accountModel.getSocialIcon(accountModel.getAccountTypeFromId(network.account_type_id.toString()))">
                    </i>
                  </li>
                </ul>
              </div>
              <div class="profile-action" (click)="attachCompetitorToReport(profile, false)">
                <i class="ssi ssi-addsmall"></i>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let number of [1,2,3,4]">
          <div *ngIf="number > report.profile_group_ids.length" class="profile-grid-item">
            <div class="profile profile-empty">
              <div class="profile-avatar">
                <i class="ssi ssi-companysettings"></i>
              </div>
              <p>Report Slot</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="form-section form-group">
      <label class="section-title">Select brands</label>
      <div class="profile-grid">
        <div class="profile-grid-item" *ngFor="let brand of profileGroups | filter: {type: 'BRAND'}">
          <div class="profile" [ngClass]="{'profile-selected': isCompetitiorSelected(brand.id)}">
            <div
              class="profile-avatar"
              [ngStyle]="brand.firstPickedAvatar ? {'background-image': 'url(' + brand.firstPickedAvatar + ')'} : {'background-color': brand.colour}"
            ></div>
            <div class="profile-meta">
              <p>{{brand.name}}</p>
              <ul>
                <li *ngFor="let network of brand.profiles">
                  <i [ngClass]="accountModel.getSocialIcon(accountModel.getAccountTypeFromId(network.account_type_id.toString()))">
                  </i>
                </li>
              </ul>
            </div>
            <div class="profile-action" (click)="attachCompetitorToReport(brand, true)">
              <i *ngIf="isCompetitiorSelected(brand.id)" class="ssi ssi-tick-small"></i>
              <i *ngIf="!isCompetitiorSelected(brand.id)" class="ssi ssi-addsmall"></i>
            </div>
          </div>
        </div>
        <div class="limit-info" *ngIf="(profileGroups | filter: {type: 'BRAND'}).length === 1">
          <h4>
            Just one Brand? Increase your industry visibility, get in touch
            with us to <a (click)="toggleLiveChat()">request more spaces</a> now.
          </h4>
        </div>
      </div>
    </div>
    
    <div class="form-section form-group">
      <label class="section-title">Select competitors</label>
      <div class="profile-grid">
        <div class="profile-grid-item" *ngFor="let competitor of profileGroups | filter: {type: 'COMPETITOR'}">
          <div class="profile" [ngClass]="{'profile-selected': isCompetitiorSelected(competitor.id)}">
            <div
              class="profile-avatar"
              [ngStyle]="competitor.firstPickedAvatar ? {'background-image': 'url(' + competitor.firstPickedAvatar + ')'} : {'background-color': competitor.colour}"
            ></div>
            <div class="profile-meta">
              <p>{{competitor.name}}</p>
              <ul>
                <li *ngFor="let network of competitor.profiles">
                  <i [ngClass]="accountModel.getSocialIcon(accountModel.getAccountTypeFromId(network.account_type_id.toString()))">
                  </i>
                </li>
              </ul>
            </div>
            <div class="profile-action" (click)="attachCompetitorToReport(competitor, true)">
              <i *ngIf="isCompetitiorSelected(competitor.id)" class="ssi ssi-tick-small"></i>
              <i *ngIf="!isCompetitiorSelected(competitor.id)" class="ssi ssi-addsmall"></i>
            </div>
          </div>
        </div>
        <div class="limit-info" *ngIf="(profileGroups | filter: {type: 'COMPETITOR'}).length === 1">
          <h4>
            Just one Competitor? Increase your industry visibility, get in touch
            with us to <a (click)="toggleLiveChat()">request more spaces</a> now.
          </h4>
        </div>
      </div>
    </div>
    <div class="form-submit">
      <orlo-button
        [textColor]="'#14BAE3'"
        [bgColor]="'#fff'"
        [state]="'secondary'"
        [text]="'Cancel'"
        [type]="'button'"
        (btnClick)="backToReports()"
      ></orlo-button>
      <orlo-button
        [bgColor]="'#14bae3'"
        [textColor]="'#fff'"
        [state]="'primary'"
        [type]="'submit'"
        [disabled]="reportForm.invalid"
        [text]="adminMode.label +' report'"
      ></orlo-button>
    </div>
  </form>
  <div class="help-content">
    <img src="https://www.orlo.app/app/web/production/8ec9b08b769ca04956f711aa9d7a81ea.png" />
    <div class="text-container">
      <h3>Need help?</h3>
      <p>This is where you build your report, simply select your brand that you would like to compare against your competitors - then add the relevant competitors. Make sure you add a description to help your team members understand the purpose of this report.</p>
    </div>
  </div>
</div>