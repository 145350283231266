import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { BuildComponent } from './build.component';
import { ReportBuildFromModule } from '../../common/report-build-from/report-build-from.module';

@NgModule({
  declarations: [BuildComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.survey.build',
          url: '?accountIds&startDate&endDate&dateRange', // default route for 'auth.analytics.survey' state
          component: BuildComponent
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    ReportBuildFromModule
  ]
})
export class BuildModule {}
