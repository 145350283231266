import './reduce-image-size-prompt.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-reduce-image-size-prompt',
  templateUrl: './reduce-image-size-prompt.component.html',
  styles: []
})
export class ReduceImageSizePromptComponent implements OnInit {
  @Input() isPngImage = false;
  @Input() isGifImage = false;
  @Input() description = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
