<div class="cl-confirmation-modal-container">
  <div class="head">
    <h2>Confirmation Modal</h2>
  </div>

  <div class="body">

    <div>
      <h4>A</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-confirmation-modal/cl-confirmation-modal.component.html">
        View code on GitHub
      </a>
      <p>Basic version</p>

      <button class="g-btn g-btn-secondary bg-white"
              (click)="openModalA()">
        <span>Cick to open modal</span>
      </button>
    </div>

    <div>
      <h4>B</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-confirmation-modal/cl-confirmation-modal.component.html">
        View code on GitHub
      </a>
      <p>Do not ask again checkbox; footer addl template</p>

      <button class="g-btn g-btn-secondary bg-white"
              (click)="openModalB()">
        <span>Cick to open modal</span>
      </button>
    </div>
  </div>

</div>

<ng-template #addlFooterTemplate>
  <ssi-checkbox-2 [label]="'DO NOT ASK AGAIN'"
                  [variant]="'square'"
                  [(ngModel)]="doNotAskAgainChecked"></ssi-checkbox-2>
</ng-template>