import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { TypeofModule } from '../../pipes/type-of/type-of.module';

@NgModule({
  imports: [CommonModule, TypeofModule],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent],
  entryComponents: [SnackbarComponent]
})
export class SnackbarModule {}
