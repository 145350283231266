<div class="composer-modal">
  <div class="composer-modal-header">
    <div class="composer-modal-header-left">
      <h2 translate="ADD_ALT_TEXT"></h2>
    </div>
    <div class="composer-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="composer-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="composer-modal-body">
    <div class="content-interaction">
      <div class="composer-modal-body-row">
        <p
          translate="PROVIDING_A_DESCRIPTION_FOR_YOUR_IMAGE_OR_GIF_ALLOWS_PEOPLE_OF_ALL_ABILITIES_TO_ENGAGE_WITH_YOUR_CONTENT_ALT_TEXT_IS_AVAILABLE_FOR_FACEBOOK_LINKEDIN__X">
        </p>
      </div>

      <div class="textarea-input-container">
        <label
          for="text-input"
          translate="ALT_TEXT"
        ></label>
        <textarea
          class="composer-modal-input"
          [(ngModel)]="altText"
          (ngModelChange)="updateCharacters($event)"
          [placeholder]="'Add a description of your image here......'"
          rows="5"
        >
      </textarea>
        <div class="char-count-container">
          <span
            class="font-weight-normal"
            [class.disabled]="altText.length && altText.length > maxCharacters"
            [class.text-primary]="charactersRemaining > 0"
            [class.text-danger]="charactersRemaining < 0"
          >
            {{ charactersRemaining }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="thumbnail-image"
      *ngIf="imageFile.url"
    >
      <img
        [src]="imageFile.url"
        alt="{{imageFile.alt_text ? imageFile.alt_text : ''}}"
      >
      <span *ngIf="imageFile.filename">{{imageFile.filename}}</span>
    </div>
  </div>

  <div class="composer-modal-footer">
    <div class="composer-modal-footer-right">
      <orlo-button
        class="composer-modal-button composer-modal-button-secondary"
        [text]="'CANCEL' | translate"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(null)"
      ></orlo-button>
      <orlo-button
        class="composer-modal-button"
        [text]="'SAVE_ALT_TEXT' | translate"
        [textColor]="altText.length && altText.length < maxCharacters ? '#FFF' : '#838EAB'"
        [bgColor]="altText.length && altText.length < maxCharacters ? '#14BAE3' : '#F4F4FA'"
        (click)="save()"
        [disabled]="altText.length > maxCharacters"
      ></orlo-button>
    </div>
  </div>
</div>