import { objKeysMatch } from '../../../utils';

export class Team {
  id: string;
  name: string;
  description: string;
  is_admins: boolean;

  constructor(props: any = {}) {
    this.id = props.id;
    this.name = props.name;
    this.description = props.description;
    this.is_admins = props.is_admins;
  }

  get mentionAttribute(): string {
    return 'group-id';
  }
  // setter must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set mentionAttribute(value: string) {}

  /**
   * For `angular-mentions` to highlight team tag from the textarea. Search `[mention]=` in code for more
   */
  get mentionName(): string {
    return this.name.replace(/ /g, '');
  }
  // setter must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set mentionName(value: string) {}

  /**
   * Checks whether the provided object is an instance of Team,
   * but in a way of checking if the object has a set of property keys defined on the Team class.
   *
   * Helpful in a situation where 'value instanceof Team' can't be used
   * (e.g. the actual instance of Team is re-mapped to plain JS object)
   */
  static is(value: any): boolean {
    return objKeysMatch(value, new Team());
  }
}
