import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxPostListComponent } from './outbox-post-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { OutboxPostListNoResultsModule } from './outbox-post-list-no-results/outbox-post-list-no-results.module';
import { AutoHeightModule } from '../../directives/auto-height/auto-height.module';
import { UniquePipe } from '../../pipes/unique/unique.pipe';
import { OutboxPostModule } from '../outbox-post/outbox-post.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UniqueModuleModule } from '../../pipes/unique/unique.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule,
    OutboxPostListNoResultsModule,
    AutoHeightModule,
    OutboxPostModule,
    InfiniteScrollModule,
    UniqueModuleModule,
    VirtualScrollModule
  ],
  declarations: [OutboxPostListComponent],
  exports: [OutboxPostListComponent],
  providers: [UniquePipe]
})
export class OutboxPostListModule {}
