<div class="modal-header">
  <div class="row">
    <h3 class="col-sm-6 no-wrap">
      <i class="fa fa-user"></i>
      <span
        translate="SELECT__USERORTEAM_"
        [translateParams]="{userOrTeam: ((selectedTeam ? 'USER' : 'TEAM') | translate)}">
      </span>
    </h3>
    <h3 class="form-group col-sm-6">
      <input
        [(ngModel)]="searchText"
        type="text"
        class="form-control"
        placeholder="{{ 'SEARCH_NAME_OR_EMAIL_ADDRESS' | translate }}">
    </h3>
  </div>
</div>
<div class="modal-body">
  <ng-container *ngIf="teams.length !== 0">
    <table class="table table-condensed table-hover" *ngIf="selectedTeam === undefined">
      <ng-container *ngFor="let team of teams | sortBy: 'name'">
        <tr *ngIf="filterByText(team.name)">
          <td (click)="selectedTeam = team; searchText = ''">
            <i class="fa fa-users"></i> {{ team.name }} - <small class="muted">Team</small> <i class="fa fa-chevron-right pull-right"></i>
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-container>
  <div *ngIf="selectedTeam">
    <div class="list-group">
      <a class="list-group-item" (click)="activeModal.close({team: selectedTeam})">
        <i class="fa fa-users"></i> <span translate="_TEAMNAME___SELECT_TEAM" [translateParams]="{teamName: selectedTeam.name}"></span>
        <span class="badge">&nbsp;<i class="fa fa-chevron-right"></i></span>
      </a>
      <a class="list-group-item" (click)="selectedTeam = undefined; searchText = ''">
        <span translate="BACK_TO_ALL_GROUPS"></span>
        <span class="badge"><i class="fa fa-chevron-left"></i>&nbsp;</span>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-3"><hr/></div>
    <div class="col-xs-6 text-center no-wrap">
      <span *ngIf="!selectedTeam">
        <select name="filterActiveUsers"
          [(ngModel)]="filterActiveUsers">
          <option value="false" translate="ALL_USERS"></option>
          <option value="true">Active Users</option>
        </select>
      </span>
      <span
        *ngIf="selectedTeam">
        <select
          name="filterActiveUsers"
          [(ngModel)]="filterActiveUsers">
          <option
            value="false"
            translate="USERS_IN__TEAMNAME__TEAM"
            [translateParams]="{teamName: selectedTeam.name}"></option>
          <option value="true">Active Users in {{selectedTeam.name}} team</option>
        </select>
      </span>
    </div>
    <div class="col-xs-3"><hr/></div>
  </div>
  <div class="list-group">
   <ng-container *ngFor="let colleague of (selectedTeam ? getColleaguesByTeam(selectedTeam.id) : colleagues) | sortBy: 'fullName'">
      <ng-container *ngIf="filterByText(colleague.fullName ? colleague.fullName : colleague.first_name + colleague.last_name)">
        <span
          class="list-group-item"
          *ngIf="filterActiveUsers === 'false' || colleague.is_active"
          (click)="activeModal.close({user: colleague})">
          <i class="fa fa-user"></i> {{ colleague.fullName }}
          <small translate="LAST_LOGIN__DATE_" [translateParams]="{date: (colleague.last_login | date:'MMM d HH:mm')}"></small>
          <span class="badge">&nbsp;<i class="fa fa-chevron-right"></i></span>
        </span>
      </ng-container>
    </ng-container>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-primary pull-right btn-sm" (click)="activeModal.dismiss()">
    <i class="fa fa-times"></i> <span translate="CANCEL"></span>
  </button>
</div>
