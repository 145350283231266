import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { MyDetailsComponent } from './my-details.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.mysettings.mydetails',
          url: '/my-details',
          component: MyDetailsComponent,
          resolve: MyDetailsComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  declarations: [MyDetailsComponent],
  entryComponents: [MyDetailsComponent]
})
export class MyDetailsModule {}
