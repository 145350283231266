import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { MonitoringStreamsComponent } from './monitoring-streams.component';
import { MonitoringStreamsService } from './monitoring-streams.service';

import { OrloComponentsModule } from 'orlo-components';
import { StreamCardModule } from '../common/components/stream-card/stream-card.module';
import { SortByModule } from '../../../../common/pipes/sort-by/sort-by.module';
import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { PaginatorModule } from '../../../../common/components/paginator';
import { CreateEditListeningModalModule } from '../common/components/create-edit-listening-modal/create-edit-listening.module';
import { CreateEditListeningModalComponent } from '../common/components/create-edit-listening-modal/create-edit-listening-modal.component';
import { TreetableModule } from '../../../../common/components/treetable';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmActionModule } from '../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.monitoringStreamsNew',
          url: '/monitoring/streams/:tab',
          component: MonitoringStreamsComponent,
          params: {
            tab: null
          },
          resolve: MonitoringStreamsComponent.resolve
        }
      ]
    }),
    OrloComponentsModule,
    StreamCardModule,
    SortByModule,
    FormsModule,
    DropdownSelect2Module,
    TranslateModule,
    PaginatorModule,
    CreateEditListeningModalModule,
    TreetableModule,
    ConfirmActionModule,
    TooltipModule,
    OffClickModule
  ],
  declarations: [MonitoringStreamsComponent],
  providers: [MonitoringStreamsService],
  entryComponents: [
    MonitoringStreamsComponent,
    CreateEditListeningModalComponent
  ]
})
export class MonitoringStreamsModule {}
