import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupMessageModalComponent } from './popup-message-modal.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, DropdownSelect2Module],
  declarations: [PopupMessageModalComponent],
  exports: [PopupMessageModalComponent],
  entryComponents: [PopupMessageModalComponent]
})
export class PopupMessageModalModule {}
