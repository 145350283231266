import { Component, OnInit } from '@angular/core';
import * as raphael from 'exports-loader?Raphael!script-loader!raphael';

declare var window: any;

@Component({
  selector: 'ssi-gauge-chart',
  templateUrl: './gauge-chart.component.html'
})
export class GaugeChartComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.eve = raphael.default.eve;
  }
}
