import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';

import { HomeComponent } from './home.component';

import { LoaderModule } from '../../../../../common/directives';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { NoResultsModule } from '../../../../../common/components/no-results/no-results.module';
import { PaginatorModule } from '../../../../../common/components/paginator';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights.reports.home',
          url: '', // default route for 'auth.insights.reports' abstract state
          component: HomeComponent
        }
      ]
    }),
    FormsModule,
    LoaderModule,
    TooltipModule,
    NoResultsModule,
    PaginatorModule,
    DropdownSelect2Module
  ],
  declarations: [HomeComponent],
  entryComponents: [HomeComponent]
})
export class HomeModule {}
