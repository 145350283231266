import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import {
  AccountModel,
  Account,
  OutboxModel,
  Outbox
} from '@ui-resources-angular';
import { ApiService } from '../../../../common/services/api';
import { HighchartsHelperService } from '../../../../common/services/highcharts-helper/highcharts-helper.service';
import { ReportSpreadsheetTransformersService } from '../../../../common/services/report-spreadsheet-transformers/report-spreadsheet-transformers.service';
import { groupBy } from '../../../../common/utils';
import { sentimentsIterable } from '../../../../common/constants';
import { LinkClicksData } from '../analytics.service';
import {
  AnalyticsService,
  InstagramStoryStatsResponse,
  VideoMetricStatsResponse
} from '../analytics.service';

export interface DateRange {
  start: Date;
  end: Date;
}
export interface DateRanges {
  current: DateRange;
  previous: DateRange;
}

export interface SentimentTotals {
  positive: number;
  semi_positive: number;
  neutral: number;
  semi_negative: number;
  negative: number;
}

export interface Totals {
  connections: number;
  female_connections: number;
  male_connections: number;

  outbox_engagement_rate: number;
  klout_score: number;

  messages_in: number;
  messages_out: number;

  total_clicks: number;
  total_clicks_es: number;
  outbox_engagement: number;

  account_impressions: number;
  outbox_impressions: number;
  account_reach: number;
  outbox_reach: number;

  positive_sentiment: number;
  semi_positive_sentiment: number;
  neutral_sentiment: number;
  semi_negative_sentiment: number;
  negative_sentiment: number;

  /* formatted for 'Brand Sentiment' section */
  sentiments: SentimentTotals;
}

export interface TotalsStats {
  /** for all accounts */
  allTotals: {
    current: Totals;
    previous?: Totals;
  };
  /** per account */
  totals: Array<{
    current: Totals;
    previous?: Totals;
    account: Account;
  }>;
  totalsByAccountType: {
    /** per social network */
    [account_type: string]: {
      current: Totals;
      previous?: Totals;
      totalAccounts: number;
      account: {
        // should not really be an 'account', but keep the data format consistent so it's easier to display data in the table later
        account_type_id: number;
        account_type_name: string;
        nme: string;
      };
    };
  };
}

export class ApiTimeSeries {
  /** 1d | 1h ... */
  bucket_size: string;
  /** missing values count? ... */
  missing: number;
  /** values ... */
  values: { [dateTime: string]: number };
}

export interface ApiTimeSeriesStats {
  messages_in: ApiTimeSeries;
  messages_out: ApiTimeSeries;
  total_clicks: ApiTimeSeries;
  total_reach: ApiTimeSeries;
}

export interface TimeSeriesStats {
  current: ApiTimeSeriesStats;
  previous?: ApiTimeSeriesStats;
}

@Injectable({ providedIn: 'root' })
export class MarketingService {
  endpoint = `${this.api.url}/stats/accountStats`;

  constructor(
    protected injector: Injector,
    protected api: ApiService,
    protected translate: TranslateService,
    protected outboxModel: OutboxModel,
    protected accountModel: AccountModel,
    protected highchartsHelper: HighchartsHelperService,
    protected reportSpreadsheetTransformers: ReportSpreadsheetTransformersService,
    private analyticsService: AnalyticsService
  ) {}

  loadTotals(
    accountIds: string[],
    dateRanges: DateRanges,
    tagsToInclude?,
    tagsToExclude?
  ): Promise<TotalsStats> {
    const promises = [];

    const currentTotalsPromise = this.api
      .post(this.endpoint, {
        account_ids: accountIds,
        with: ['totals'],
        start_date: dateRanges.current.start,
        end_date: dateRanges.current.end,
        include_tags: tagsToInclude,
        exclude_tags: tagsToExclude
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
    promises.push(currentTotalsPromise);

    if (dateRanges.previous.start && dateRanges.previous.end) {
      const previousTotalsPromise = this.api
        .post(this.endpoint, {
          account_ids: accountIds,
          with: ['totals'],
          start_date: dateRanges.previous.start,
          end_date: dateRanges.previous.end,
          include_tags: tagsToInclude,
          exclude_tags: tagsToExclude
        })
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError((e) => this.api.mapError(e, this.endpoint))
        )
        .toPromise();
      promises.push(previousTotalsPromise);
    }

    return Promise.all(promises).then(([currentTotals, previousTotals]) => {
      const transformTotals = (accountTotals) => {
        if (!accountTotals) {
          return {};
        }
        Object.keys(accountTotals).forEach((key) => {
          accountTotals[key] = parseFloat(accountTotals[key]);
          if (Number.isNaN(accountTotals[key])) {
            accountTotals[key] = 0;
          }
        });

        delete accountTotals.account_id;
        return accountTotals;
      };

      const data: any = {
        totals: currentTotals.totals.map((currentAccountTotals) => {
          const accountId = +currentAccountTotals.account_id;
          return {
            account: this.accountModel.get(accountId),
            current: transformTotals(currentAccountTotals),
            previous:
              previousTotals &&
              transformTotals(
                previousTotals.totals.find(
                  (totals) => +totals.account_id === accountId
                )
              )
          };
        }),
        allTotals: {
          current: {},
          previous: previousTotals && {}
        },
        totalsByAccountType: {}
      };

      data.totals.sort((a, b) => {
        return a.account.account_type_id - b.account.account_type_id;
      });

      const groupes = groupBy(
        data.totals,
        (item) => item.account.account_type_name
      );

      groupes.forEach((group) => {
        const accountTypeId = group[0].account.account_type_id;
        const accountTypeName = group[0].account.account_type_name;

        data.totalsByAccountType[accountTypeName] = group.reduce(
          (totalsSummed, accountTotals) => {
            const periods = previousTotals
              ? ['current', 'previous']
              : ['current'];

            periods.forEach((period) => {
              if (!totalsSummed[period]) {
                totalsSummed[period] = {};
              }

              Object.entries(accountTotals[period]).forEach(
                ([statKey, amount]: [string, number]) => {
                  if (!totalsSummed[period][statKey]) {
                    totalsSummed[period][statKey] = 0;
                  }
                  // amount = Math.round(amount);
                  totalsSummed[period][statKey] += amount;
                  data.allTotals[period][statKey] =
                    data.allTotals[period][statKey] || 0;
                  data.allTotals[period][statKey] += amount;
                }
              );
            });

            totalsSummed.account = {
              // should not really be an 'account', but keep the data format consistent so it's easier to display data in the table later
              account_type_id: accountTypeId,
              account_type_name: accountTypeName,
              name: accountTypeName
            };

            totalsSummed.totalAccounts = totalsSummed.totalAccounts || 0;
            totalsSummed.totalAccounts++;

            return totalsSummed;
          },
          {}
        );
      });

      const totalCurrentConnections =
        data.allTotals.current.male_connections +
        data.allTotals.current.female_connections;

      data.allTotals.current.male_connections /= totalCurrentConnections / 100;
      data.allTotals.current.female_connections /=
        totalCurrentConnections / 100;

      if (previousTotals) {
        const totalPreviousConnections =
          data.allTotals.previous.male_connections +
          data.allTotals.previous.female_connections;

        data.allTotals.previous.male_connections /=
          totalPreviousConnections / 100;
        data.allTotals.previous.female_connections /=
          totalPreviousConnections / 100;
      }

      const apiSentimentKeysMap = {
        positive: 'positive_sentiment',
        semi_positive: 'semi_positive_sentiment',
        neutral: 'neutral_sentiment',
        semi_negative: 'semi_negative_sentiment',
        negative: 'negative_sentiment'
      };

      // format data for 'Brand Sentiment' chart, e.g. 'positive_sentiment' -> 'sentiments.positive'
      Object.keys(apiSentimentKeysMap).forEach((key) => {
        if (!data.allTotals.current.sentiments) {
          data.allTotals.current.sentiments = {};
        }
        data.allTotals.current.sentiments[key] =
          data.allTotals.current[apiSentimentKeysMap[key]];

        if (previousTotals) {
          if (!data.allTotals.previous.sentiments) {
            data.allTotals.previous.sentiments = {};
          }
          data.allTotals.previous.sentiments[key] =
            data.allTotals.previous[apiSentimentKeysMap[key]];
        }

        Object.keys(data.totalsByAccountType).forEach((accountTypeKey) => {
          if (!data.totalsByAccountType[accountTypeKey].current.sentiments) {
            data.totalsByAccountType[accountTypeKey].current.sentiments = {};
          }
          data.totalsByAccountType[accountTypeKey].current.sentiments[key] =
            data.totalsByAccountType[accountTypeKey].current[
              apiSentimentKeysMap[key]
            ];

          if (previousTotals) {
            if (!data.totalsByAccountType[accountTypeKey].previous.sentiments) {
              data.totalsByAccountType[accountTypeKey].previous.sentiments = {};
            }
            data.totalsByAccountType[accountTypeKey].previous.sentiments[key] =
              data.totalsByAccountType[accountTypeKey].previous[
                apiSentimentKeysMap[key]
              ];
          }
        });
      });

      return data;
    });
  }

  loadTimeSeries(
    accountIds: string[],
    dateRanges: DateRanges,
    tagsToInclude,
    tagsToExclude
  ): Promise<TimeSeriesStats> {
    const promises = [];

    const currentTimeSeriesPromise = this.api
      .post(this.endpoint, {
        account_ids: accountIds,
        with: ['time_series'],
        start_date: dateRanges.current.start,
        end_date: dateRanges.current.end,
        include_tags: tagsToInclude,
        exclude_tags: tagsToExclude
      })
      .pipe(
        map((response: { time_series: ApiTimeSeriesStats }) => {
          return response.time_series;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
    promises.push(currentTimeSeriesPromise);

    if (dateRanges.previous.start && dateRanges.previous.end) {
      const previousTimeSeriesPromise = this.api
        .post(this.endpoint, {
          account_ids: accountIds,
          with: ['time_series'],
          start_date: dateRanges.previous.start,
          end_date: dateRanges.previous.end,
          include_tags: tagsToInclude,
          exclude_tags: tagsToExclude
        })
        .pipe(
          map((response: { time_series: ApiTimeSeriesStats }) => {
            return response.time_series;
          }),
          catchError((e) => this.api.mapError(e, this.endpoint))
        )
        .toPromise();
      promises.push(previousTimeSeriesPromise);
    }

    return Promise.all(promises).then(
      ([currentTimeSeries, previousTimeSeries]) => {
        if (previousTimeSeries) {
          Object.keys(previousTimeSeries).forEach((tsKey) => {
            Object.entries(previousTimeSeries[tsKey].values).forEach(
              ([date, amount]) => {
                const daysDiff = moment(dateRanges.current.start).diff(
                  moment(dateRanges.previous.start),
                  'days'
                );
                delete previousTimeSeries[tsKey].values[date];
                previousTimeSeries[tsKey].values[
                  moment(date).add(daysDiff, 'days').format()
                ] = amount;
              }
            );
          });
        }

        const data: TimeSeriesStats = {
          current: currentTimeSeries,
          previous: previousTimeSeries
        };

        return data;
      }
    );
  }

  getTopPostTimes(
    accountIds: string[],
    dateRanges: DateRanges,
    stats = ['clicks', 'shares', 'comments', 'likes'],
    includeEmptyValues = false,
    tagsToInclude?,
    tagsToExclude?
  ) {
    const WEIGHTS = Object.freeze({
      likes: 10,
      shares: 30,
      comments: 40,
      clicks: 20,
      click_es: 20
    });

    function iterateData(weekData, action) {
      Object.values(weekData).forEach((dayData) => {
        Object.entries(dayData).forEach(([hourKey, hourData]) => {
          dayData[hourKey] = action(hourData);
        });
      });
    }

    function calculateScore(weekData) {
      // brutal way to deep copy an object
      const clone = JSON.parse(JSON.stringify(weekData));

      const keys = Object.keys(WEIGHTS);
      const sums = {};
      iterateData(clone, (hourData) => {
        keys.forEach((key) => {
          sums[key] = sums[key] || 0;
          sums[key] += hourData[key];
        });
        return hourData;
      });

      const scores = [];

      iterateData(clone, (hourData) => {
        hourData.score = 0;
        keys.forEach((key) => {
          hourData.score +=
            (sums[key] > 0 ? hourData[key] / sums[key] : 0) * WEIGHTS[key];
        });
        scores.push(hourData.score);
        return hourData;
      });

      const maxScore = Math.max(...scores);

      iterateData(clone, (hourData) => {
        const normalisedScore =
          (maxScore > 0 ? hourData.score / maxScore : 0) * 100;
        delete hourData.score;
        return {
          value: normalisedScore,
          data: {
            totals: hourData
          }
        };
      });

      return clone;
    }

    function aggregateHours(weekData) {
      const hours = {};
      const keys = Object.keys(WEIGHTS);
      Object.values(weekData).forEach((dayData) => {
        Object.entries(dayData).forEach(([hourKey, hourData]) => {
          hours[hourKey] = hours[hourKey] || {};
          keys.forEach((key) => {
            hours[hourKey][key] = hours[hourKey][key] || 0;
            hours[hourKey][key] += hourData[key];
          });
        });
      });
      return hours;
    }

    return this.api
      .post(`${this.api.url}/outbox/hourOfDayAggregation_v2`, {
        account_ids: accountIds,
        start: dateRanges.current.start,
        end: dateRanges.current.end,
        // tz_offset: TIMEZONE_OFFSET,
        with: stats,
        include_tags: tagsToInclude,
        exclude_tags: tagsToExclude
      })
      .pipe(
        map((response) => {
          const chartData = calculateScore(response);
          const aggregatedScores = calculateScore({
            all: aggregateHours(response)
          }).all;

          const topHours = Object.entries(aggregatedScores).map(
            ([hour, { value }]: [string, { value: number }]) => {
              return {
                label: moment()
                  .hours(+hour)
                  .format('ha'),
                percent: value,
                hour: +hour
              };
            }
          );

          topHours.sort((a, b) => b.percent - a.percent);

          return { chartData, topHours };
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  /** Per city lat, lng map markers */
  getLinkClicksLocations(
    accountIds: string[],
    dateRanges: DateRanges,
    tagsToInclude,
    tagsToExclude
  ): Promise<{ lat: number; lng: number }[]> {
    return this.api
      .post(this.endpoint, {
        account_ids: accountIds,
        with: ['clicks'],
        start_date: dateRanges.current.start,
        end_date: dateRanges.current.end,
        include_tags: tagsToInclude,
        exclude_tags: tagsToExclude
      })
      .pipe(
        map((response: any) => {
          const data: any = response.clicks.map((loc) => {
            return { lat: Number(loc.lat), lng: Number(loc.lng) };
          });
          return data;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  patchTotalReachValues(
    values: { [key: string]: number }
    // startDate: Date,
    // endDate: Date
  ): { [key: string]: number } {
    // Backend data might be completely missing, rather than just 0, (account re-auth, bugs, etc)
    const patchedValues = Object.assign({}, values);
    let lastDateValue: moment.Moment;

    Object.keys(values).forEach((date) => {
      const momentDate = moment(date);
      if (lastDateValue) {
        const dayDiff = momentDate.diff(lastDateValue, 'days');
        if (dayDiff > 1) {
          for (let i = 1; i < dayDiff; i++) {
            const _lastDateValue = moment(lastDateValue);
            patchedValues[_lastDateValue.add(i, 'days').toISOString()] = 0;
          }
        }
      }
      lastDateValue = moment(date);
    });
    return patchedValues;
  }

  createSpreadsheetExport(
    accounts: Account[],
    dateRanges: DateRanges,
    totalsStats: TotalsStats,
    timeSeriesStats: TimeSeriesStats,
    linkClicksStats: LinkClicksData,
    topTimesToPostStats: any,
    topPosts: Outbox[],
    videoMetrics: {
      currentStats: VideoMetricStatsResponse;
      previousStats: VideoMetricStatsResponse;
    },
    InstagramStories: {
      currentStats: InstagramStoryStatsResponse;
      previousStats: InstagramStoryStatsResponse;
    }
  ) {
    const compareMode = !!(
      dateRanges.previous.start && dateRanges.previous.end
    );

    const ranges: any = {
      current:
        moment(dateRanges.current.start).format('MM/DD/YY') +
        ' - ' +
        moment(dateRanges.current.end).format('MM/DD/YY')
    };

    if (compareMode) {
      ranges.previous =
        moment(dateRanges.previous.start).format('MM/DD/YY') +
        ' - ' +
        moment(dateRanges.previous.end).format('MM/DD/YY');
    }

    return [
      () => {
        const accountTypeSection = [];
        Object.entries(totalsStats.totals).forEach(
          ([accountType, totals]: [string, any]) => {
            const periods = compareMode ? ['current', 'previous'] : ['current'];

            periods.forEach((period) => {
              accountTypeSection.push([
                totals.account.name +
                  ' (' +
                  totals.account.account_type_name +
                  ') ' +
                  ranges[period],
                totals[period].connections,
                totals[period].profile_likes,
                totals[period].account_impressions,
                totals[period].outbox_impressions,
                totals[period].account_reach,
                totals[period].outbox_reach,
                totals[period].total_clicks_es,
                totals[period].messages_out,
                totals[period].outbox_engagement_rate
              ]);
            });
            accountTypeSection.push([]);
          }
        );

        return {
          name: 'Account Summary',
          rows: [
            ['Marketing Analytics - Account Summary'],
            [],
            ...this.reportSpreadsheetTransformers.accountsList(accounts),
            [],
            [
              '',
              'Followers',
              'Fans',
              'Account Impressions',
              'Post Impressions',
              'Account Reach',
              'Post Reach',
              'Link clicks',
              'Published Posts',
              'Post Engagement Rate'
            ],
            ...accountTypeSection
          ]
        };
      },
      () => {
        return {
          name: 'Link clicks',
          rows: [
            ['Marketing Analytics - Link Clicks'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              {
                header: 'Clicks',
                data: linkClicksStats.response.by_datetime
              }
            ])
          ]
        };
      },
      () => {
        return {
          name: 'Video Metrics',
          rows: [
            ['Marketing Analytics - Video Metrics'],
            [],
            ['Metric', 'Current', 'Previous'],
            [
              'Video Views',
              Math.round(videoMetrics.currentStats.video_views),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.video_views)
                : 0
            ],
            [
              'Average View Time (secs)',
              Math.round(videoMetrics.currentStats.average_view_time),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.average_view_time)
                : 0
            ],
            [
              'Impressions',
              Math.round(videoMetrics.currentStats.impressions),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.impressions)
                : 0
            ],
            [
              'Reach',
              Math.round(videoMetrics.currentStats.reach),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.reach)
                : 0
            ],
            [
              'Link Clicks',
              Math.round(videoMetrics.currentStats.link_clicks),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.link_clicks)
                : 0
            ],
            [
              'Likes',
              Math.round(videoMetrics.currentStats.likes),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.likes)
                : 0
            ],
            [
              'Comments',
              Math.round(videoMetrics.currentStats.comments),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.comments)
                : 0
            ],
            [
              'Shares',
              Math.round(videoMetrics.currentStats.shares),
              videoMetrics.previousStats
                ? Math.round(videoMetrics.previousStats.shares)
                : 0
            ]
          ]
        };
      },
      () => {
        return {
          name: 'Instagram Stories',
          rows: [
            ['Marketing Analytics - Instagram Stories'],
            [],
            ['Metric', 'Current', 'Previous'],
            [
              'Story Views',
              Math.round(InstagramStories.currentStats.sum_impressions),
              InstagramStories.previousStats
                ? Math.round(InstagramStories.previousStats.sum_impressions)
                : 0
            ],
            [
              'Reach',
              Math.round(InstagramStories.currentStats['sum_reach']),
              InstagramStories.previousStats
                ? Math.round(InstagramStories.previousStats.sum_reach)
                : 0
            ],
            [
              'Tap Back',
              Math.round(InstagramStories.currentStats.sum_taps_back),
              InstagramStories.previousStats
                ? Math.round(InstagramStories.previousStats.sum_taps_back)
                : 0
            ],
            [
              'Tap Forward',
              Math.round(InstagramStories.currentStats.sum_taps_forward),
              InstagramStories.previousStats
                ? Math.round(InstagramStories.previousStats.sum_taps_forward)
                : 0
            ],
            [
              'Exit Story',
              Math.round(InstagramStories.currentStats['sum_exits']),
              InstagramStories.previousStats
                ? Math.round(InstagramStories.previousStats.sum_exits)
                : 0
            ]
          ]
        };
      },
      () => {
        return {
          name: 'Organic Reach',
          rows: [
            ['Marketing Analytics - Organic Reach'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              {
                header: 'Reach',
                data: this.patchTotalReachValues(
                  timeSeriesStats.current.total_reach.values
                )
              }
            ])
          ]
        };
      },
      () => {
        return {
          name: 'Brand Sentiment',
          rows: [
            ['Marketing Analytics - Brand Sentiment'],
            [],
            ['Sentiment', 'Comments'],
            ...sentimentsIterable.map((sentiment) => {
              return [
                this.translate.instant(sentiment.label),
                totalsStats.allTotals.current.sentiments[sentiment.key]
              ];
            })
          ]
        };
      },
      () => {
        const rows = [
          ['Marketing Analytics - Followers'],
          [],
          ['', 'Male', 'Female'],
          [
            ranges.current,
            totalsStats.allTotals.current.male_connections + '%',
            totalsStats.allTotals.current.female_connections + '%'
          ]
        ];

        if (compareMode) {
          rows.push([
            ranges.previous,
            totalsStats.allTotals.previous.male_connections + '%',
            totalsStats.allTotals.previous.female_connections + '%'
          ]);
        }

        return {
          name: 'Followers',
          rows
        };
      },
      () => {
        const messages = Object.keys(
          timeSeriesStats.current.messages_in.values
        ).map((date) => {
          return [
            moment(new Date(date)).format('MM/DD/YY'),
            timeSeriesStats.current.messages_in.values[date],
            timeSeriesStats.current.messages_out.values[date]
          ];
        });

        return {
          name: 'Post Engagement',
          rows: [
            ['Marketing Analytics - Post Engagement'],
            [],
            ['Date', 'Comments in', 'Published posts'],
            ...messages
          ]
        };
      },
      () => {
        return {
          name: 'Top posts',
          rows: [
            ['Marketing Analytics - Top posts'],
            [],
            ['Top posts by clicks'],
            ...this.reportSpreadsheetTransformers.outboxPosts2(topPosts),
            []
            // ['Top posts by reach'],
            // ...this.reportSpreadsheetTransformers.outboxPosts(
            //   accountReport.data.top_posts.by_reach
            // ),
            // [],
            // ['Top posts by impressions'],
            // ...this.reportSpreadsheetTransformers.outboxPosts(
            //   accountReport.data.top_posts.by_impressions
            // )
          ]
        };
      }
    ]
      .map((fn) => fn())
      .filter(Boolean);
  }
}
