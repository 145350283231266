<div *ngIf="targetingOptionGroups">

  <div class="audience-table">
    <ul class="audience-table-head audience-table-row">
      <li class="audience-table-col audience-table-head-main">
        <p>Audience:</p>
      </li>
      <li class="audience-table-col audience-table-head-name">
        <p>Included audience</p>
      </li>
      <li class="audience-table-col audience-table-head-name">
        <p>Excluded audience</p>
      </li>
    </ul>
    <div class="audience-table-body">
      <ul class="audience-table-row" *ngIf="optionGroupValues.length === 0">
        <li class="audience-table-col"></li>
        <li class="audience-table-col"></li>
        <li class="audience-table-col"></li>
      </ul>
      <ul class="audience-table-row" *ngFor="let optionGroupValue of optionGroupValues">
        <li class="audience-table-col">{{ optionGroupValue.optionGroup.name }}</li>
        <li class="audience-table-col">
          <span *ngFor="let value of optionGroupValue.INCLUDE; trackById">
            <label class="label label-warning audience-option-label">
              {{ value.name }}
              <span
                class="mouse-pointer"
                (click)="removeOptionGroupValue(optionGroupValue, AdvertisingType.Include, value)">
                <i class="fa fa-times"></i>
              </span>
            </label>&nbsp;
          </span>
        </li>
        <li class="audience-table-col">
          <span *ngFor="let value of optionGroupValue.EXCLUDE; trackById">
            <label class="label label-primary audience-option-label">
              {{ value.name }}
               <span
                 class="mouse-pointer"
                 (click)="removeOptionGroupValue(optionGroupValue, AdvertisingType.Exclude, value)">
                  <i class="fa fa-times"></i>
                </span>
            </label>&nbsp;
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-8 col-xs-offset-2">
      <ul class="list-unstyled targeting-options">
        <li
          data-targeting-option-group
          *ngFor="let optionGroup of targetingOptionGroups; trackBy:trackByName"
          [class.active]="optionGroup.isActive"
          [class.disabled]="optionGroup.isDisabled"
          [class.compulsory]="optionGroup.isCompulsory"
          (click)="showOptionPanel(optionGroup)">

          <span
            [ssiTooltip]="optionGroup.options[0].description"
            [tooltipAppendToBody]="true">
            {{ optionGroup.options[0].displayName }}
          </span>

          <div
            class="targeting-option-panel"
            *ngIf="optionPanelOpen && optionPanelOpen.optionGroup === optionGroup"
            (click)="$event.stopPropagation()"
            (ssiOffClick)="optionPanelOpen = null">
            <div class="targeting-option-panel-inner">

              <input
                type="search"
                class="form-control spacer-bottom spacer-top"
                placeholder="Type to search..."
                [(ngModel)]="optionPanelOpen.queryText"
                (ngModelChange)="searchOptionValues()"
                *ngIf="optionPanelOpen.optionGroup.options[0].supports_query"
                data-option-search>

              <input
                type="search"
                class="form-control spacer-bottom spacer-top"
                placeholder="Type to search..."
                [(ngModel)]="optionPanelOpen.queryText"
                (ngModelChange)="filterLocalOptions()"
                *ngIf="optionPanelOpen.optionGroup.options[0].supports_list"
                data-option-search>

              <div class="loading" *ngIf="!optionPanelOpen.values && !optionPanelOpen.optionGroup.options[0].supports_query"></div>

              <table class="table" *ngIf="optionPanelOpen.values">
                <thead>
                <tr class="advertising-text-muted">
                  <th class="text-left">Select as many audiences as you need</th>
                  <th class="text-center">Include</th>
                  <th class="text-center" *ngIf="supportsExclude(optionGroup)">Exclude</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="optionPanelOpen.filteredValues && optionPanelOpen.filteredValues.length === 0">
                  <td colspan="3">No results found</td>
                </tr>
                <tr
                  *ngFor="let value of (optionPanelOpen.filteredValues || optionPanelOpen.values); trackBy:trackById"
                  data-targeting-option-value>
                  <td
                    class="font-weight-normal text-left"
                    [class.advertising-text-muted]="!value.type || value.type === AdvertisingType.Remove"
                    [class.text-warning]="value.type === AdvertisingType.Include"
                    [class.text-primary]="value.type === AdvertisingType.Exclude">
                    {{ value.value.name }}
                    <i class="fa fa-check text-warning" *ngIf="value.type === AdvertisingType.Include"></i>
                    <i class="fa fa-times text-primary" *ngIf="value.type === AdvertisingType.Exclude"></i>
                  </td>
                  <td class="text-center">
                    <div class="ui-radio">
                        <input
                          type="radio"
                          [name]="value.value.id"
                          [class.ui-radio-checked]="value.type === AdvertisingType.Include"
                          [value]="AdvertisingType.Include"
                          [(ngModel)]="value.type"
                          (click)="toggleOptionRadioValue(value, AdvertisingType.Include)"
                          data-targeting-option-value-include>
                        <label for="value.value.id"></label>
                    </div>
                  </td>
                  <td class="text-center" *ngIf="supportsExclude(optionGroup)">
                    <div class="ui-radio">
                      <input
                        type="radio"
                        [name]="value.value.id"
                        [class.ui-radio-checked]="value.type === AdvertisingType.Exclude"
                        [value]="AdvertisingType.Exclude"
                        [(ngModel)]="value.type"
                        (click)="toggleOptionRadioValue(value, AdvertisingType.Exclude)"
                        data-targeting-option-value-exclude>
                        <label for="value.value.id"></label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

              <button
                class="btn btn-warning btn-rounded targeting-option-panel-close"
                (click)="$event.stopPropagation(); closeOptionPanel()"
                data-targeting-option-group-save>
                Save
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-xs-2">
      <div class="text-center advertising-text-muted" *ngIf="estimatedAudienceSize >= 0">
        <div>
          <span class="fa-stack fa-lg">
            <i class="fa fa-circle fa-stack-2x text-warning"></i>
            <i class="fa fa-users fa-stack-1x fa-inverse"></i>
          </span>
        </div>
        <div class="spacer-top font-weight-normal">
          <em>Estimated target audience:</em>
        </div>
        <div class="text-primary spacer-top">
          <h4><b><ssi-big-number [number]="estimatedAudienceSize" data-estimated-audience-size></ssi-big-number></b></h4>
        </div>
        <div class="spacer-top font-weight-normal">
          Platform members
        </div>
      </div>
    </div>
  </div>
</div>
