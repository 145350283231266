import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverDirective } from './popover.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PopoverDirective],
  exports: [PopoverDirective]
})
export class PopoverModule {}
