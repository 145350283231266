import './toggle-switch.component.scss';
import {
  Input,
  Component,
  ViewChild,
  Renderer2,
  forwardRef
} from '@angular/core';
import {
  NgForm,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ssi-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true
    }
  ]
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input() parentNgForm: NgForm;
  @Input() label: string;
  @Input() saveAsString?: boolean;
  @Input() toggleText: string;
  @Input() toolTip: string;
  @Input() disabled = false;
  @ViewChild('toggleInput') _toggleInput;

  id = uuidv4();

  isChecked: boolean;

  constructor(private renderer: Renderer2) {}

  // registers 'fn' that will be fired after changes are made
  // this is how we emit the changes back to the form
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  switchToggle() {
    this.isChecked = !this.isChecked;
    if (this.saveAsString) {
      this.propagateChange(String(this.isChecked));
    } else {
      this.propagateChange(this.isChecked);
    }
  }

  writeValue(value: boolean) {
    this.isChecked = value;
    this.renderer.setAttribute(
      this._toggleInput.nativeElement,
      'value',
      String(value)
    );
  }

  public registerOnTouched() {}

  private propagateChange = (_: any) => {};
}
