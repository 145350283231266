import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlatpickrModule } from 'angularx-flatpickr';

import { FiltersComponent } from './filters.component';

import { OffClickModule } from '../../directives/off-click/off-click.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { VarModule } from '../../directives/var/var.module';
import { SocialNetworkIconModule } from '../social-network-icon/social-network-icon.module';
import { Checkbox2Module } from '../checkbox-2/checkbox-2.module';
import { ListSelect2Module } from '../list-select2/list-select2.module';
import { FilterModule } from '../../pipes/filter/filter.module';
import { TagsSelectCreateModule } from '../tags-select-create/tags-select-create.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    OffClickModule,
    TooltipModule,
    VarModule,
    SocialNetworkIconModule,
    Checkbox2Module,
    ListSelect2Module,
    FilterModule,
    FlatpickrModule.forRoot(),
    TagsSelectCreateModule
  ],
  exports: [FiltersComponent],
  declarations: [FiltersComponent]
})
export class FiltersModule {}
