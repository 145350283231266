import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByString'
})
export class FilterByStringPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return typeof it.displayName !== 'undefined'
        ? it.displayName.toLowerCase().includes(searchText)
        : it.toLowerCase().includes(searchText);
    });
  }
}
