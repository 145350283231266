<div class="prompt">
  <div class="prompt-header">
    <div class="prompt-header-left">
      <h2>{{title}}</h2>
    </div>
    <div class="prompt-header-right">
      <button (click)="activeModal.close(false)"
              class="prompt-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="prompt-body">
    <p>{{description}}</p>
    <p class="input-label">{{passphraseDescription}}</p>
    <input type="text"
           class="g-input bg-black"
           [class.border-red]="passphraseModel && passphraseModel !== passphrase"
           [(ngModel)]="passphraseModel"
           placeholder="Enter security phrase here...">
    <div class="prompt-body-row">
    </div>
  </div>

  <div class="prompt-footer">
    <div class="prompt-footer-left">
    </div>
    <div class="prompt-footer-right">
      <button type="button"
              class="g-btn g-btn-lg g-btn-secondary bg-black"
              (click)="activeModal.close(false)">Cancel</button>

      <button type="button"
              class="g-btn g-btn-lg bg-red"
              [disabled]="passphraseModel !== passphrase"
              (click)="activeModal.close(true)">{{confirmButtonText}}</button>
    </div>
  </div>
</div>