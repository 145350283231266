<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body">
  <ng-container *ngIf="meta">
    <p [innerHTML]="meta"></p>
    <hr>
  </ng-container>
  <label for="name">Name: </label>
  <form #singleInputForm="ngForm">
    <input
      id="name"
      type="text"
      class="form-control"
      name="name"
      [placeholder]="placeholder"
      [(ngModel)]="singleInput"
      required
    >
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="activeModal.close()"
    translate="CANCEL"
  >
  </button>
  <button
    [disabled]="singleInputForm.invalid"
    type="button"
    class="btn btn-primary"
    (click)="activeModal.close(singleInput)"
  >
    {{actionButton}}
  </button>
</div>