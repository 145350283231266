<div *ngIf="type === 'default'" class="stat-graph-container">
  <div class="stat-graph-header">
    <div class="stat-graph-header-heading-container">
      <p class="stat-graph-header-heading">{{totalNumber}}</p>
      <p class="stat-graph-header-sub">Total Posts</p>
    </div>
    <ul class="stat-graph-header-meta">
      <li class="stat-graph-network" *ngIf="['total', '2'].includes(activeNetwork)">
        <span class="ssi ssi-x-logo"></span>
      </li>
      <li class="stat-graph-network" *ngIf="['total', '3'].includes(activeNetwork)">
        <span class="fa fa-facebook-f"></span>
      </li>
      <li class="stat-graph-network" *ngIf="['total', '12'].includes(activeNetwork)">
        <span class="fa fa-instagram"></span>
      </li>
    </ul>
  </div>

  <hr class="stat-graph-header-break">

  <div class="stat-graph-content">
    <div class="stat-graph-chart-container">
      <ssi-highchart [config]="pieChartConfig"></ssi-highchart>
    </div>
    <ul class="stat-graph-chart-key">
      <li *ngFor="let data of pieChartConfig.series[0].data">
        <span
          class="stat-graph-chart-legend"
          [ngStyle]="{'background-color': data.color}">
        </span>
        <p class="stat-graph-chart-amount">{{data.y}}</p>
        <p class="stat-graph-chart-name">{{data.name}}</p>
      </li>
    </ul>
  </div>
</div>

<div
  *ngIf="['large', 'large-thin'].includes(type)"
  class="stat-graph-container stat-graph-container-large"
  [ngClass]="{'stat-graph-container-large-thin': type === 'large-thin'}"
  >
  <div class="stat-graph-large-left">
    <div class="stat-graph-competitor-container">
      <p class="stat-graph-competitor">
        <span class="stat-graph-competitor-colour" [style.background]="competitorColour"></span>
        {{competitorName}}
      </p>
    </div>
    <div class="stat-graph-chart-container">
      <ssi-highchart [config]="pieChartConfig"></ssi-highchart>
    </div>
    <ul class="stat-graph-large-left-footer">
      <li class="stat-graph-network" *ngIf="['total', '2'].includes(activeNetwork)">
        <span class="ssi ssi-x-logo"></span>
      </li>
      <li class="stat-graph-network" *ngIf="['total', '3'].includes(activeNetwork)">
        <span class="fa fa-facebook-f"></span>
      </li>
      <li class="stat-graph-network" *ngIf="['total', '12'].includes(activeNetwork)">
        <span class="fa fa-instagram"></span>
      </li>
    </ul>
  </div>

  <div class="stat-graph-large-right">
    <div class="stat-graph-large-right-header">
      <div class="stat-graph-large-right-header-title">
        <p class="stat-graph-header-heading">{{totalNumber}}</p>
        <p class="stat-graph-header-sub">Total Posts</p>
      </div>
      <div *ngIf="isTop" class="stat-graph-large-right-header-badge stat-graph-badge">
        <p>1</p>
        <ssi-icon class="stat-graph-badge-wreathe" icon="wreathe" height="46" width="53"></ssi-icon>
      </div>
    </div>
    <ul class="stat-graph-chart-key">
      <li *ngFor="let data of pieChartConfig.series[0].data">
        <span
          class="stat-graph-chart-legend"
          [ngStyle]="{'background-color': data.color}">
        </span>
        <p class="stat-graph-chart-amount">{{data.y}}</p>
        <p class="stat-graph-chart-name">{{data.name}}</p>
      </li>
    </ul>
  </div>
</div>