import './time-picker.component.scss';
import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Account } from '@ui-resources-angular';
import {
  subMonths,
  getHours,
  getMinutes,
  setHours,
  setMinutes
} from 'date-fns';
import { MarketingAnalyticsService } from '../../services/marketing-analytics/marketing-analytics.service';

export const TIME_PICKER_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimePickerComponent), //tslint:disable-line
  multi: true
};

@Component({
  selector: 'ssi-time-picker',
  templateUrl: './time-picker.component.html',
  providers: [TIME_PICKER_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() timeLabel: string;
  @Input() accounts: Account[];
  @Input() selectedTime: any;
  @Input() dayOfTheWeek: number;
  @Input() fullWidth: boolean;
  meridian: 'am' | 'pm' = 'am';
  isMeridianDisabled = false;
  hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
  ];
  otherHours = [
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];
  minutes = Array.from(Array(60).keys())
    .map((n) => n.toString())
    .map((stringNo, i) => {
      if (i <= 9) {
        return `0${stringNo}`;
      } else {
        return stringNo;
      }
    });
  daysOfTheWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  suggestedTimes;
  showTimesMenu = false;
  triggerOrigin: any;
  todaysDate = new Date();

  @Output() onTimeSelected = new EventEmitter();

  constructor(private marketingAnalytics: MarketingAnalyticsService) {}

  ngOnInit() {}

  /**
   * expecting `0` to `23` (24-hr format from the API (`outbox/hourOfDayAggregation_v2`)
   */
  setCustomHour(newHour: number) {
    this.selectedTime = setHours(this.selectedTime, newHour);

    if (newHour >= 12) {
      this.meridian = 'pm';
    } else {
      this.meridian = 'am';
    }
    this.setViewValue(this.selectedTime);
    this.showTimesMenu = false;
  }

  async triggerTimesMenu(event, trigger) {
    event.stopPropagation();

    this.triggerOrigin = trigger;

    const allSuggestedTimes = await this.marketingAnalytics.getTopPostTimes({
      accountIds: this.accounts.map((account) => account.id),
      start: subMonths(new Date(), 1),
      end: new Date(),
      dayOfTheWeek: this.dayOfTheWeek
    });
    this.suggestedTimes = allSuggestedTimes.todaySuggestedTimes;

    this.showTimesMenu = true;
  }

  private onChangeCallback: (_: any) => void = () => {};

  writeValue(newValue: Date): void {
    this.selectedTime = newValue;
    if (this.selectedTime) {
      this.meridian = this.selectedTime.getHours() >= 12 ? 'pm' : 'am';
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  private setViewValue(newValue: Date) {
    this.writeValue(newValue);
    this.onChangeCallback(newValue);
  }

  onSelectTime() {
    this.onTimeSelected.emit(this.selectedTime);
    this.setViewValue(this.selectedTime);
  }

  onHourChange(hour: string) {
    if (!this.selectedTime) {
      const defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate());
      this.selectedTime = setMinutes(defaultDate, 0);
    }

    this.selectedTime = setHours(this.selectedTime, Number(hour));
    if (this.meridian === 'pm') {
      this.setMeridianTime('pm');
    }
    this.onSelectTime();
  }

  onMinuteChange(minute: string) {
    if (!this.selectedTime) {
      const defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate());
      this.selectedTime = defaultDate;
    }

    this.selectedTime = setMinutes(this.selectedTime, Number(minute));
    this.onSelectTime();
  }

  setMeridianTime(value: 'am' | 'pm') {
    if (!this.selectedTime) {
      const defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate());
      this.selectedTime = setHours(setMinutes(defaultDate, 0), 0);
    }

    this.meridian = value;
    this.selectedTime =
      value === 'am'
        ? setHours(this.selectedTime, getHours(this.selectedTime) - 12)
        : setHours(this.selectedTime, getHours(this.selectedTime) + 12);
    this.onSelectTime();
  }
}
