import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DashboardWidgetModalComponent } from './dashboard-widget-modal.component';

import { OrloComponentsModule } from 'orlo-components';
import { FiltersModule } from '../filters';
import { ToggleSwitch2Module } from '../toggle-switch-2/toggle-switch-2.module';
import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';

import { WidgetDefaultConfigModule } from '../../../modules/auth/dashboard/common/components/widgets/widget-default-config/widget-default-config.module';
import { WidgetInboxMessageConfigModule } from '../../../modules/auth/dashboard/common/components/widgets/widget-inbox-message-config/widget-inbox-message-config.module';
import { WidgetInboxConversationsConfigModule } from '../../../modules/auth/dashboard/common/components/widgets/widget-inbox-conversations-config/widget-inbox-conversations-config.module';
import { WidgetRssFeedsConfigModule } from '../../../modules/auth/dashboard/common/components/widgets/widget-rss-feeds-config/widget-rss-feeds-config.module';
import { WidgetMarketingPostsConfigModule } from '../../../modules/auth/dashboard/common/components/widgets/widget-marketing-posts-config/widget-marketing-posts-config.module';
import { WidgetLiveXStreamConfigModule } from '../../../modules/auth/dashboard/common/components/widgets/widget-live-x-stream-config/widget-live-x-stream-config.module';
import { WidgetMonitoringStreamConfigModule } from '../../../modules/auth/dashboard/common/components/widgets/widget-monitoring-stream-config/widget-monitoring-stream-config.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OrloComponentsModule,
    FiltersModule,
    ToggleSwitch2Module,
    DropdownSelect2Module,
    WidgetDefaultConfigModule,
    WidgetInboxMessageConfigModule,
    WidgetInboxConversationsConfigModule,
    WidgetRssFeedsConfigModule,
    WidgetMarketingPostsConfigModule,
    WidgetLiveXStreamConfigModule,
    WidgetMonitoringStreamConfigModule
  ],
  declarations: [DashboardWidgetModalComponent],
  exports: [DashboardWidgetModalComponent],
  providers: []
})
export class DashboardWidgetModalModule {}
