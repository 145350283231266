import './edit-links-modal.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Account, OutboxPublisherLink, User } from '@ui-resources-angular';
import { AsyncTracker } from 'angular-async-tracker';
import { CompanyService } from '../../services/company/company.service';
import { AccountTypeIdNameMap, AccountTypeIdString } from '../../enums';
import {
  CUSTOM_SOURCE_NAME,
  FilestackPickOptions,
  FilestackService
} from '../../services/filestack/filestack.service';
import {
  acceptedFileTypesForImages,
  maximumFileSizeForImagesInBytes,
  permittedSources
} from '../../../../../library/constants/filestack';

@Component({
  selector: 'ssi-edit-links-modal',
  templateUrl: './edit-links-modal.component.html'
})
export class EditLinksModalComponent implements OnInit {
  @Input() linkData: OutboxPublisherLink;
  @Input() loader: AsyncTracker;
  @Input() vanityDomain: string | null;
  @Input() postAccounts: Array<Account>;
  @Input() authUser: User;
  @Input() maxImageSize: number;

  linkTracking: boolean;
  utmTracking: boolean;
  prefix: string;
  imageChanged: boolean = false;
  initialImageIndex: number = 0;

  hasChanges = false;
  canSetCustomLinkedInImage: boolean = false;
  supportsCustomLinkOption: boolean = false;

  @Output() addCustomImageClicked = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    protected company: CompanyService,
    protected filestack: FilestackService
  ) {}

  async ngOnInit() {
    this.linkTracking = this.linkData.data.shorten;
    this.utmTracking = this.linkData.data.utm.enabled;
    this.prefix = this.linkData.data.prefix;
    this.initialImageIndex = this.linkData.data.preview.selectedImageIndex;

    this.canSetCustomLinkedInImage = !!(await this.company.hasFeatureAccess(
      'LINKEDIN_LINK_IMAGE_POST'
    ));

    this.supportsCustomLinkOption = this.postAccounts.every((acc) =>
      [AccountTypeIdString.LinkedIn, AccountTypeIdString.LinkedInAds].includes(
        acc.account_type_id as any
      )
    );
  }

  openAddCustomImage() {
    this.openAddCustomLinkImage();
  }

  onLinkTrackingChange() {
    if (!this.linkTracking) {
      this.utmTracking = false;
    }

    this.onFormChange();
  }

  onFormChange() {
    this.hasChanges =
      this.linkTracking !== this.linkData.data.shorten ||
      this.utmTracking !== this.linkData.data.utm.enabled ||
      this.prefix !== this.linkData.data.prefix ||
      this.imageChanged ||
      this.initialImageIndex !== this.linkData.data.preview.selectedImageIndex;
  }

  public async openAddCustomLinkImage() {
    try {
      const client = await this.filestack.getClient();
      const customSourcePath: string = `company-files/${this.authUser.company_id}/`;
      const options: FilestackPickOptions = {
        accept: acceptedFileTypesForImages,
        customSourcePath,
        disableTransformer: true,
        fromSources: permittedSources,
        maxFiles: 1,
        maxSize: this.maxImageSize,
        onUploadDone: (result) => {
          try {
            if (
              !(
                !!result &&
                !!result.filesUploaded &&
                !!result.filesUploaded.length
              )
            ) {
              throw new Error(`Unexpected result when trying to upload image!`);
            }

            const file = result.filesUploaded[0];
            if (file.size >= maximumFileSizeForImagesInBytes) {
              console.error(`File over size limit.`);

              return true;
            }

            const url: string = file.url;
            this.linkData.data.preview.images.push({ url });
            this.linkData.data.preview.selectedImageIndex =
              this.linkData.data.preview.images.length - 1;

            this.imageChanged = true;
            this.onFormChange();

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        },
        onClose: () => {},
        customSourceName: CUSTOM_SOURCE_NAME
      };

      client.picker(options).open();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  activeImageIndexChanged(index: number) {
    this.onFormChange();
  }

  saveLinkEdits() {
    this.activeModal.close({
      linkTracking: this.linkTracking,
      utmTracking: this.utmTracking,
      prefix: this.prefix,
      selectedImageIndex: this.linkData.data.preview.selectedImageIndex
    });
  }
}
