import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { TagsInputComponent } from './tags-input.component';

import { RadioListModule } from '../radio-list/radio-list.module';
import { DropdownSelect2Module } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  declarations: [TagsInputComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RadioListModule,
    TooltipModule,
    DropdownSelect2Module
  ],
  exports: [TagsInputComponent]
})
export class TagsInputModule {}
