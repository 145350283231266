<div class="targeting-options-panel">
  <div class="network-information">
    <div class="network-avatar">
      <ssi-social-network-icon
        [accountTypeId]="AccountTypeId.Nextdoor"
        [size]="20"
      ></ssi-social-network-icon>
    </div>
    <div class="network-body">
      <h4
        class="accordion-content-title"
        translate="NEXTDOOR_TARGETING_OPTIONS"
      ></h4>
      <p
        class="accordion-content-body"
        translate="ANYONE_THAT_FALLS_WITHIN_THIS_BOUNDARY_WILL_SEE_THIS_POST_IN_THEIR_NEWSFEEDS"
      ></p>
    </div>
  </div>

  <div class="targeting-options-form">
    <div class="nextdoor-boundary-select">
      <label
        class="control-label text-muted spacer-top"
        translate="BOUNDARIES"
      ></label>
      <div class="input-wrapper">
        <ssi-dropdown-select-2
          size="lg"
          name="boundaries"
          [disabled]="post.edit?.social_id"
          [multiple]="true"
          [filterable]="true"
          [toggleAllEnabled]="false"
          [placeholdersKeyword]="'boundaries'"
          [toggleAllLabel]="'all boundaries'"
          [options]="boundaries"
          [(ngModel)]="targetingService.options[accountTypeName].selectedBoundaries"
          (ngModelChange)="onSelectedBoundariesChange($event)"
        >
          <ng-template
            ssiTemplateSelector="headButtonLabel"
            let-toggle="toggle"
            let-expanded="expanded"
          >
            <div
              class="select-placeholder"
              *ngIf="targetingService.options[accountTypeName].selectedBoundaries && targetingService.options[accountTypeName].selectedBoundaries.length === 0"
            >
              <span translate="SEARCH_BOUNDARIES_TO_INCLUDE"></span>
            </div>
            <div class="selected-options-container">
              <div
                #chipsContainer
                class="chips-container"
                *ngIf="targetingService.options[accountTypeName].selectedBoundaries && targetingService.options[accountTypeName].selectedBoundaries.length > 0"
              >
                <div
                  class="chip"
                  *ngFor="let boundary of targetingService.options[accountTypeName].selectedBoundaries | slice:0:2;"
                >
                  <span>{{boundary.name}}</span>
                  <i
                    class="ssi ssi-delete-close"
                    (click)="$event.stopPropagation(); removeBoundary(boundary.group_id);"
                  ></i>
                </div>
              </div>
              <button
                *ngIf="targetingService.options[accountTypeName].selectedBoundaries && targetingService.options[accountTypeName].selectedBoundaries.length > 2"
                type="button"
                class="g-btn text-only-button"
                (click)="$event.stopPropagation(); toggle();"
              >{{targetingService.options[accountTypeName].selectedBoundaries.length - 2}} {{'MORE' |
                translate}}</button>
            </div>
          </ng-template>

          <ng-template
            ssiTemplateSelector="bodyHeader"
            let-toggle="toggle"
          >
            <div class="menu-header">
              <span
                *ngIf="targetingService.options[accountTypeName].selectedBoundaries && targetingService.options[accountTypeName].selectedBoundaries.length > 0"
                class="g-text"
              >{{targetingService.options[accountTypeName].selectedBoundaries.length}} {{'BOUNDARIES_SELECTED' |
                translate}}</span>
              <span
                *ngIf="!targetingService.options[accountTypeName].selectedBoundaries.length"
                class="g-text no-boundaries-selected-label"
                translate="NO_BOUNDARIES_SELECTED"
              ></span>
              <button
                *ngIf="targetingService.options[accountTypeName].selectedBoundaries && targetingService.options[accountTypeName].selectedBoundaries.length > 0"
                type="button"
                class="g-btn text-only-button underlined"
                (click)="$event.stopPropagation(); deselectAll();"
              ><span translate="CLEAR_ALL"></span></button>
              <i
                class="ssi ssi-arrow-down-new collapse-icon"
                (click)="toggle()"
              ></i>
            </div>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>
  </div>
</div>