import './inbox-language-selector.component.scss';

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit
} from '@angular/core';

export interface DropDownItem {
  label: string;
  value: string;
}

@Component({
  selector: 'ssi-inbox-language-selector',
  templateUrl: './inbox-language-selector.component.html'
})
export class InboxLanguageSelectorComponent implements OnInit, AfterViewInit {
  @Input() activeItems: string[];
  @Input() items: DropDownItem[];
  @Input() placeholder: string;
  @Input() canAdd: boolean;
  @Output() onAdd = new EventEmitter();
  filteredItems: DropDownItem[];
  itemValues: string[];
  searchTerm: string;

  @Output() onChangeItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor() {}

  ngOnInit() {
    this.filteredItems = this.items;
    this.itemValues = this.items.map((item) => item.value);
  }

  ngAfterViewInit(): void {
    // had to use timeout to avoid shortcut key 't' being shown in the input field
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    });
  }

  filterValues(searchTerm: string) {
    this.filteredItems = this.items.filter((item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
