import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-engagement',
  templateUrl: './engagement.component.html',
  styles: []
})
export class EngagementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
