<div class="media">
  <div class="media-left media-middle hidden-print">
    <span class="fa-stack fa-3x spacer-top">
      <i class="fa fa-circle fa-stack-2x" [ngClass]="iconBackgroundClass"></i>
      <i class="fa fa-stack-1x fa-inverse" [ngClass]="iconClass"></i>
    </span>
  </div>
  <div class="media-body text-center">
    <div class="statcard padding-sides-none">
      <h3 class="statcard-number">
        <strong>
          <ssi-big-number [number]="currentTotal" *ngIf="!time"></ssi-big-number>
          <span *ngIf="time">{{ currentTotal | secondsToHumanTime }}</span>
        </strong>
        <ssi-total-change
          [currentTotal]="currentTotal"
          [previousTotal]="previousTotal"
          [delta]="true"
          [round]="totalChangeRound"
          [time]="time"
          [lowerIsPositive]="totalChangeLowerIsPositive">
        </ssi-total-change>
      </h3>
      <span class="statcard-desc">{{ labelTranslateKey | translate }}</span>
    </div>
  </div>
</div>