import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatBudgetPipe } from './format-budget.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FormatBudgetPipe],
  exports: [FormatBudgetPipe]
})
export class FormatBudgetModule {}
