import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportFilterBarComponent } from './report-filter-bar.component';

@NgModule({
  declarations: [ReportFilterBarComponent],
  imports: [CommonModule],
  exports: [ReportFilterBarComponent]
})
export class ReportFilterBarModule {}
