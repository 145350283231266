<style type="text/css">
  body {
    background-color: #5DADE0;
  }
</style>

<div class="container">
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <div class="panel panel-default">
        <div class="panel-body">
          <p translate="THE_DIAGNOSTICS_TOOL_GIVES_YOU_DETAILED_INFORMATION_ABOUT_YOUR_COMPUTER_AND_BROWSER_USE_THIS_TO_HELP_OUR_SUPPORT_STAFF_DIAGNOSE_ISSUES_WITH_YOUR_SETUP"></p>
          <p>
            <small>
              <strong>If any items are <span class="text-red">red</span> then it means there is a problem with your browser that must be rectified before you can use all features of Orlo.</strong>
            </small>
          </p>
          <br>
          <div *ngIf="shareUrl">
            <div class="form-group">
              <label class="control-label"
                     translate="SHARE_THIS_LINK_WITH_OUR_SUPPORT_STAFF"></label>
              <input type="url"
                     [ngModel]="shareUrl"
                     class="form-control input-lg"
                     ssi-focus-input
                     readonly>
            </div>
            <br>
          </div>
          <div *ngIf="!shareUrl">
            <div class="alert alert-warning"><i class="fa fa-warning"></i> This has been restored from the shared url.</div>
          </div>
          <table class="table table-bordered">
            <tbody>
              <tr *ngIf="currentBrowser">
                <th translate="BROWSER"></th>
                <td>
                  <i class="fa fa-fw"
                     [ngClass]="currentBrowser.icon"
                     [hidden]="currentBrowser.unknown"></i> {{ currentBrowser.name }} {{ currentBrowser.major }}
                  <span [hidden]="currentBrowser.unknown">
                    <span class="label label-success pull-right"
                          *ngIf="!currentBrowserOutdated">
                      <i class="fa fa-check"></i> <span translate="UP_TO_DATE"></span>
                    </span>
                    <span class="label label-warning pull-right"
                          *ngIf="currentBrowserOutdated">
                      <i class="fa fa-exclamation"></i> <span translate="OUT_OF_DATE_LATEST_VERSION__LATESTVERSION_"
                            translate-values="{latestVersion: currentBrowser.latestVersion}"></span>
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <th translate="OPERATING_SYSTEM"></th>
                <td>{{ currentOS?.name }} {{ currentOS?.version }}</td>
              </tr>
              <tr>
                <th translate="SCREEN_SIZE"></th>
                <td>{{ screen?.width }} x {{ screen?.height }}</td>
              </tr>
              <tr>
                <th translate="APP_VERSION"></th>
                <td>{{ appVersion }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th>Websockets</th>
                <td>
                  <span class="label label-success pull-right"
                        *ngIf="socketsConnected">
                    <i class="fa fa-check"></i> <span translate="ENABLED"></span>
                  </span>
                  <span class="label label-danger pull-right"
                        *ngIf="!socketsConnected">
                    <i class="fa fa-exclamation"></i> <span translate="BROKEN"></span>
                  </span>
                </td>
              </tr>
              <tr *ngFor="let feature of featuresIterable">
                <th>{{ feature.label }}</th>
                <td>
                  <div *ngIf="!feature.loadingTracker?.active">
                    <span class="label label-success pull-right"
                          *ngIf="feature.supported">
                      <i class="fa fa-check"></i> <span translate="ENABLED"></span>
                    </span>
                    <span class="label label-danger pull-right"
                          *ngIf="!feature.supported">
                      <i class="fa fa-exclamation"></i> <span translate="BROKEN"></span>
                    </span>
                  </div>
                  <div *ngIf="feature.loadingTracker?.active">
                    <span class="label label-info pull-right">
                      <i class="fa fa-spinner fa-spin"></i> <span translate="LOADING"></span>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>