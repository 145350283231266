<ng-container *ngIf="!isPopupTemplate; else popupTemplate">
  <dl class="inbox-sidebar-useful-things">
    <dt
      *ngIf="authUser?.hasCompanyPermission('administer_company')"
      class="inbox-sidebar-item-bg-light inbox-sidebar-item-bg-light-hover text-light"
      (click)="showUsefulThings = !showUsefulThings"
    >
      <span translate="USEFUL_THINGS"></span>
      <i
        class="ssi"
        [ngClass]="{
          'ssi-arrow-up': !showUsefulThings,
          'ssi-arrow-down-correct active': showUsefulThings
        }"
      ></i>
    </dt>

    <dd
      class="inbox-useful-things-collpase"
      [class.inbox-useful-things-collpased]="!showUsefulThings"
    >
      <ul>
        <li
          *ngFor="let video of tutorialVideos"
          (click)="onShowVideoTutorial.emit({video: video})"
        >
          <span>{{ video.label }}</span>
          <i class="ssi ssi-fw ssi-play"></i>
        </li>

        <li
          *ngIf="authUser?.hasCompanyPermission('administer_company')"
          class="btn-export"
          (click)="onExportInbox.emit(); showUsefulThings = false"
        >
          <span translate="INBOX_EXPORT"></span>
          <i class="ssi ssi-export-convo"></i>
        </li>
        <li
          class="mark-all-actioned"
          (click)="onMarkAllActioned.emit()"
        >
          <span
            class="inbox-search-results-btn-label"
            translate="MARK_ALL_ACTIONED"
          ></span>
        </li>
        <li
          class="mark-all-unactioned"
          (click)="onMarkAllUnactioned.emit()"
        >
          <span
            class="inbox-search-results-btn-label"
            translate="MARK_ALL_UNACTIONED"
          ></span>
        </li>
      </ul>
    </dd>
  </dl>
</ng-container>

<ng-template #popupTemplate>
  <div class="inbox-useful-things-popup">
    <div class="inbox-useful-things-body">
      <div class="inbox-useful-things-title">
        <a href="javascript:;" (click)="onClose.emit()">
          <span translate="CLOSE_USEFUL_THINGS"></span> <i class="fa fa-minus fa-fw"></i>
        </a>
      </div>
      <div class="inbox-useful-things-list">
        <ul class="list-unstyled">
          <li *ngFor="let video of tutorialVideos">
            <a href="javascript:;" (click)="onShowVideoTutorial.emit({video: video})">
              {{ video.label }} <i class="ssi ssi-play ssi-fw"></i>
            </a>
          </li>
          <li
            *ngIf="authUser?.hasCompanyPermission('administer_company')"
            class="inbox-export"
          >
            <a href="javascript:;" (click)="onExportInbox.emit()">
              <span translate="INBOX_EXPORT"></span> <i class="ssi ssi-export-convo"></i>
            </a>
          </li>
          <li
            *ngIf="authUser?.hasCompanyPermission('administer_company')"
            class="mark-all-actioned"
          >
            <a href="javascript:;" (click)="onMarkAllActioned.emit()">
              <span translate="MARK_ALL_ACTIONED"></span>
            </a>
          </li>
          <li
            *ngIf="authUser?.hasCompanyPermission('administer_company')"
            class="mark-all-unactioned"
          >
            <a href="javascript:;" (click)="onMarkAllUnactioned.emit()">
              <span translate="MARK_ALL_UNACTIONED"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
