import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import './gif-search-modal.component.scss';

import { GiphyFetch, ICategory } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { renderGrid } from '@giphy/js-components';
import { FormGroup } from '@angular/forms';

const gf = new GiphyFetch('Z1elOUyRIsmBo6bWQZ950fntG447y9mS');

@Component({
  selector: 'ssi-gif-search-modal',
  templateUrl: './gif-search-modal.component.html',
  styles: []
})
export class GifSearchModalComponent implements OnInit, OnDestroy {
  filterTerm: string = '';
  gifs: IGif[];
  gifsCategories: ICategory[];
  modelChanged = new Subject<string>();
  modelChangeSubscription: Subscription;
  currentOffset = 0;

  @ViewChild('gifElRef') gifElRef: ElementRef;
  gifSearchForm: FormGroup;

  constructor(public modalInstance: NgbActiveModal) {}

  async ngOnInit() {
    this.getCategories();

    // enable some time for user input instead of inflating the API with call on each keystroke
    this.modelChangeSubscription = this.modelChanged
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.renderGiphyGrid();
      });
  }

  renderGiphyGrid() {
    renderGrid(
      {
        width: 600,
        fetchGifs: () => {
          this.currentOffset += 25;
          return gf.search(this.filterTerm, {
            offset: this.currentOffset,
            limit: 25
          });
        },
        columns: 2,
        gutter: 6,
        onGifClick: (gif: IGif) => {
          this.selectGif(gif);
        },
        key: this.filterTerm,
        noLink: true
      },
      this.gifElRef.nativeElement
    );
  }

  async getCategories() {
    const { data: categories } = await gf.categories({ limit: 10 });
    this.gifsCategories = categories;
  }

  selectCategory(category: ICategory) {
    this.filterTerm = category.name;
    this.modelChanged.next();
  }

  searchInputChanged() {
    this.modelChanged.next();
  }

  selectGif(gif: IGif) {
    this.modalInstance.close(gif.images.original.url);
  }

  closeModal() {
    this.modalInstance.dismiss(null);
  }

  ngOnDestroy(): void {
    this.modelChangeSubscription.unsubscribe();
  }
}
