import { ModuleWithProviders, NgModule, InjectionToken } from '@angular/core';
import { RedirectService } from './redirect/redirect.service';
import {
  CommonModule,
  Location,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';

import animateScrollTo from 'animated-scroll-to';
import getCaretCoordinates from 'textarea-caret';
import { ApiService } from './api/api.service';
import { TeamsService } from './api/teams/teams.service';
import { ColleaguesService } from './api/colleagues/colleagues.service';
import { CampaignsService } from './api/campaigns/campaigns.service';
import { NotesService } from './api';
import { AbstractQueryHelperService } from './abstract-query-helper/abstract-query-helper.service';
import { InboxQueryFactoryService } from './inbox-query-factory/inbox-query-factory.service';
import { WebhookModel } from './webhook-model/webhook-model';
import { UserSelectorModalService } from './user-selector-modal/user-selector-modal.service';
import { LazyLoadService } from './lazy-load/lazy-load.service';
import { VideoInfoService } from './video-info/video-info.service';
import { FilestackService } from './filestack/filestack.service';
import { ServerService } from './server/server.service';
import { CountryFinderService } from './country-finder/country-finder.service';
import { ImageHelperService } from './image-helper/image-helper.service';
import { PopupMessageModalModule } from './popup/popup-message-modal/popup-message-modal.module';
import { PopupService } from './popup/popup.service';
import { UserPreferencesService } from './user-preferences/user-preferences.service';
import { HighchartsHelperService } from './highcharts-helper/highcharts-helper.service';
import { ReportSpreadsheetTransformersService } from './report-spreadsheet-transformers/report-spreadsheet-transformers.service';
import { MarketingAnalyticsService } from './marketing-analytics/marketing-analytics.service';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { SpreadsheetService } from './spreadsheet/spreadsheet.service';
import { StorageService } from './storage/storage.service';
import { OutboxQueryFactoryService } from './outbox-query-factory/outbox-query-factory.service';
import { CompanyService } from './company/company.service';
import { InboxQueryResultListItemModel } from './inbox-query-factory/queries/common';
import { AuthService } from './auth/auth.service';
import { NotificationManagerService } from './notification-manager/notification-manager.service';
import { TwitterThreadService } from '../../modules/auth/outbox/twitter-threads/service/twitter-threads.service';
import { CampaignModelService } from '../../modules/auth/marketing/index/campaign-model/campaign-model.service';
import { NotesModelService } from '../../modules/auth/marketing/validate-posts/validate-posts-box/validate-posts-notes/notes-model/notes-model.service';
import { LiveChatService } from './live-chat/live-chat.service';
import { LiveChatAuthenticationService } from './live-chat-authentication/live-chat-authentication.service';
import { PushNotificationsService } from './push-notifications/push-notifications.service';
import { PluginService } from './plugin/plugin.service';
import { PredictedResponseService } from './predicted-response/predicted-response.service';
import { ModalFactoryService } from './modal-factory/modal-factory.service';
import { InboxPinnedActivitiesService } from '../components/inbox-pinned-activities/inbox-pinned-activities.service';
import { ConversationPushModeService } from './conversation-push-mode/conversation-push-mode.service';
import { UpdateManagerService } from './update-manager/update-manager.service';
import { FileDownloadService } from './file-download/file-download.service';
import { SnackbarService } from './snackbar/snackbar.service';
import { SocialService } from './social/social.service';
import { SnackbarModule } from './snackbar/snackbar.module';
import { NotificationModule } from './notification/notification.module';
import { NotificationService } from './notification/notification.service';
import { ChatBotService } from './chat-bot/chat-bot.service';
import { SocketsService } from './sockets/sockets.service';
import { SocketEventManagerService } from './sockets/socket-event-manager.service';
import { WorkflowManagerService } from './workflow-manager/workflow-manager.service';
import { InsightsService } from './insights/insights';
import { InboxKeyboardShortcutsService } from './inbox-keyboard-shortcuts/inbox-keyboard-shortcuts.service';
import { OfficeHoursService } from '../../modules/auth/settings/company-settings/office-hours/office-hours.service';
import { TranslationService } from './translation/translation.service';
import { BrowserlessPDFExportService } from './browserless-pdf-export/browserless-pdf-export.service';
import { DeviceService } from './device/device.service';
import { RealtimeConversationHelperService } from './realtime-conversation-helper/realtime-conversation-helper.service';
import { RealtimeInboxHelperService } from './realtime-inbox-helper/realtime-inbox-helper.service';
import { PageVisibilityService } from './page-visibility/page-visibility.service';
import { ProfileHelperService } from './profile-helper/profile-helper.service';
import { ProfilesService } from './profile/profiles.service';

export const ANIMATE_SCROLL_TO = new InjectionToken('animateScrollTo');

export const GET_TEXTAREA_CARET_COORDINATES = new InjectionToken(
  'getTextareaCaretCoordinates'
);

export function animateScrollToFactory() {
  return animateScrollTo;
}

export function getTextareaCaretCoordinatesFactory() {
  return getCaretCoordinates;
}

export const SERVICE_PROVIDERS = [
  Location,
  { provide: LocationStrategy, useClass: HashLocationStrategy }, // needed for the redirect service
  RedirectService,
  ApiService,
  TeamsService,
  ColleaguesService,
  CampaignsService,
  NotesService,
  AbstractQueryHelperService,
  InboxQueryFactoryService,
  WebhookModel,
  UserSelectorModalService,
  LazyLoadService,
  VideoInfoService,
  ServerService,
  CountryFinderService,
  ImageHelperService,
  PopupService,
  UserPreferencesService,
  HighchartsHelperService,
  ReportSpreadsheetTransformersService,
  MarketingAnalyticsService,
  ConversationPushModeService,
  ErrorHandlerService,
  FilestackService,
  SpreadsheetService,
  StorageService,
  OutboxQueryFactoryService,
  CompanyService,
  InboxQueryResultListItemModel,
  AuthService,
  NotificationManagerService,
  TwitterThreadService,
  CampaignModelService,
  NotesModelService,
  InboxQueryResultListItemModel,
  PushNotificationsService,
  PluginService,
  ProfileHelperService,
  { provide: ANIMATE_SCROLL_TO, useFactory: animateScrollToFactory },
  {
    provide: GET_TEXTAREA_CARET_COORDINATES,
    useFactory: getTextareaCaretCoordinatesFactory
  },
  LiveChatService,
  ModalFactoryService,
  PredictedResponseService,
  InboxPinnedActivitiesService,
  UpdateManagerService,
  FileDownloadService,
  SnackbarService,
  SocialService,
  NotificationService,
  ChatBotService,
  SocketsService,
  SocketEventManagerService,
  WorkflowManagerService,
  InsightsService,
  InboxKeyboardShortcutsService,
  OfficeHoursService,
  TranslationService,
  BrowserlessPDFExportService,
  DeviceService,
  RealtimeConversationHelperService,
  RealtimeInboxHelperService,
  PageVisibilityService,
  ProfilesService
];

@NgModule({
  imports: [
    CommonModule,
    PopupMessageModalModule,
    SnackbarModule,
    NotificationModule
  ]
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServicesModule,
      providers: [
        ...SERVICE_PROVIDERS,

        // this note is old but still basically true:
        // AngularFire2 and specifically its Auth,
        // is a broken PITA which complicates testing in Angular.js (not Angular 2+).
        // As we have no real need to test Live Chat in Angular.js, there's no reason to provide it there.

        LiveChatService,
        LiveChatAuthenticationService
      ]
    };
  }
}
