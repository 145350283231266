import { NetworkState } from '../../interfaces/live-chat/network-state';
import { User as UserInterface } from '../../interfaces/live-chat/user';
import Model from '../model';
import strings from '../../constants/strings';

export class User extends Model implements UserInterface {
  protected _data: UserInterface;

  public get displayName(): string {
    return this._data.displayName;
  }

  /*
  public get lastViewedAt(): null | Date {
    return this._data.lastViewedAt;
  }

  public get lastTypedAt(): Date {
    return this._data.lastTypedAt;
  }
  */
}
