<div class="publisher-action-buttons-container">
  <button class="action-button preview-btn"
          (click)="!disable?.previewPost && showPostPreview.emit()"
          [class.disabled]="!!disable?.previewPost || !post.validity.isValid"
          [ssiTooltip]="'You must write some post text to be able to publish your schedules.'"
          [tooltipWindowClass]="'tooltip-chunky-big'"
          [tooltipAppendToBody]="true"
          tooltipPlacement="top"
          [tooltipDisabled]="!!disable?.previewPost ? true : post.validity.isValid"
          [tooltipTemplate]="!post.validity.isValid ? postInvalidTooltipTemplate : null">
    <i class="ssi ssi-impressions-correct"></i>
    <span class="g-hide-if-large-screen">Preview Post</span>
  </button>

  <button class="action-button publish-btn"
          (click)="publish.emit()"
          [class.disabled]="!post.validity.isValid || !post.schedules.length"
          [ssiTooltip]="post.validity.isValid ?
              (!post.schedules.length ? 'You must select a time and click “Add schedule” to be able to schedule your post' :
                'Schedule ' + post.schedules.length + ' posts')
                : 'You must write some post text to be able to publish your schedules.'"
          [tooltipWindowClass]="'tooltip-chunky-big'"
          [tooltipAppendToBody]="true"
          tooltipPlacement="top"
          [tooltipTemplate]="!post.validity.isValid ? postInvalidTooltipTemplate : null">
    <i class="ssi ssi-send-icon"></i>
    <span class="g-hide-if-large-screen">Publish now</span>
  </button>
</div>