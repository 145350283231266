import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBoxComponent } from './message-box.component';
import { MessageActionsMenuModule } from './message-actions-menu/message-actions-menu.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { MessageModule } from './message/message.module';
import { MessageAttachmentsModule } from './message-attachments/message-attachments.module';
import { MessageActionsModule } from './message-actions/message-actions.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../../components/icon/icon.module';
import { LinkifyModule } from '../../pipes/linkify/linkify.module';
import { MessageSplitPanelComponent } from '../message-split-panel/message-split-panel.component';

@NgModule({
  imports: [
    CommonModule,
    FallbackImageModule,
    OverlayModule,
    MessageActionsMenuModule,
    TooltipModule,
    MessageModule,
    MessageAttachmentsModule,
    MessageActionsModule,
    OffClickModule,
    TranslateModule,
    IconModule,
    LinkifyModule
  ],
  declarations: [MessageBoxComponent, MessageSplitPanelComponent],
  exports: [MessageBoxComponent]
})
export class MessageBoxModule {}
