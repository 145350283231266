import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { WidgetProfileSearchComponent } from './widget-profile-search.component';
import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../../../common/components/social-network-icon/social-network-icon.module';
import { TemplateSelectorModule } from '../../../../../../../common/directives/template-selector/template-selector.module';
import { VirtualScrollModule } from '../../../../../../../common/components/virtual-scroll/virtual-scroll.module';
import { NoResultsModule } from '../../../../../../../common/components/no-results/no-results.module';
import { ActivityModule } from '../../../../../../../common/components/activity/activity.module';
import { SocialPostModule } from '../../../../../../../common/components/social-post/social-post.module';
import { SpinnerModule } from '../../../../../../../common/components/spinner/spinner.module';
import { StreamPostModule } from '../../../../../../../common/components/stream-post/stream-post.module';
import { TagsInput2Module } from '../../../../../../../common/components/tags-input-2/tags-input-2.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    InfiniteScrollModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    TemplateSelectorModule,
    VirtualScrollModule,
    NoResultsModule,
    ActivityModule,
    SocialPostModule,
    SpinnerModule,
    StreamPostModule,
    TagsInput2Module
  ],
  exports: [WidgetProfileSearchComponent],
  declarations: [WidgetProfileSearchComponent]
})
export class WidgetProfileSearchModule {}
