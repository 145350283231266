<div
  *ngIf="!! agent"
  [ngClass]="{ 'online': isAgentOnline, 'offline': !isAgentOnline }"
>
  <ssi-live-chat-network-state
    [isAvatarAndMenuOnly]="true"
    [user]="agent"
    [disabled]="isInterfaceDisabled"
    [hasConversationPushModeEnabled]="hasConversationPushModeEnabled"
  ></ssi-live-chat-network-state>

  <ssi-live-chat-conversation-statistics
    (conversationsFilterChange)="updateFilter($event)"
    [ageOfNewestAssignedConversation]="ageOfNewestAssignedConversation"
    [ageOfNewestUnassignedConversation]="ageOfNewestUnassignedConversation"
    [isAgentOnline]="isAgentOnline"
    [quantityOfOngoingConversations]="quantityOfOngoingConversations"
    [quantityOfOwnedConversations]="quantityOfOwnedConversations"
    [quantityOfUnassignedConversations]="quantityOfUnassignedConversations"
  ></ssi-live-chat-conversation-statistics>
</div>

<div
  *ngIf="! agent"
>
  <div class="not-enabled">
    <i class="ssi ssi-loading ssi-spin"></i>
  </div>
</div>
