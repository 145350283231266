import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PluginComponent } from './plugin.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

@NgModule({
  imports: [CommonModule, TranslateModule, UIRouterModule],
  declarations: [PluginComponent],
  exports: [PluginComponent]
})
export class PluginModule {}
