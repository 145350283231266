import './build.component.scss';

import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AccountModel, Account } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import {
  ReportBuildFormValue,
  visibilityOptions
} from '../../common/report-build-from/report-build-from.component';

import { commonDateRanges } from '../../../../../common/constants/common-date-ranges';
import { compareToOptions } from '../../../../../common/constants/compare-to-options';
import { OutboxTagsService } from '../../../../../common/services/api/outbox-tags';

@Component({
  selector: 'ssi-build',
  templateUrl: './build.component.html',
  styles: []
})
export class BuildComponent implements OnInit {
  formValue?: Partial<ReportBuildFormValue>;
  validationTags: string[];

  constructor(
    protected state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected outboxTagsService: OutboxTagsService
  ) {}

  async ngOnInit() {
    const allTags = await this.outboxTagsService.getValidationTags();
    this.validationTags = allTags.map((t) => t.name);
    if (
      this.state.params.accountIds &&
      this.state.params.startDate &&
      this.state.params.endDate
    ) {
      // edit mode
      const accountIds = Array.isArray(this.state.params.accountIds)
        ? this.state.params.accountIds
        : [this.state.params.accountIds];

      const tagsToInclude = Array.isArray(this.state.params.includeTags)
        ? this.state.params.includeTags
        : [this.state.params.includeTags];

      const tagsToExclude = Array.isArray(this.state.params.excludeTags)
        ? this.state.params.excludeTags
        : [this.state.params.excludeTags];

      this.formValue = {
        accounts: accountIds.map((id) => this.accountModel.get(id)),
        startDate: new Date(this.state.params.startDate),
        endDate: new Date(this.state.params.endDate),
        previousStartDate:
          this.state.params.previousStartDate &&
          new Date(this.state.params.previousStartDate),
        previousEndDate:
          this.state.params.previousEndDate &&
          new Date(this.state.params.previousEndDate),
        dateRange: commonDateRanges[this.state.params.dateRange],
        tagsToInclude,
        tagsToExclude
      };
    }
  }

  onFormSubmit(output: ReportBuildFormValue) {
    console.log('form output: ', output);

    this.state.go('auth.analytics.disapprovals.report', {
      accountIds: output.accounts.map((a) => a.id),
      startDate: output.startDate.toISOString(),
      endDate: output.endDate.toISOString(),
      previousStartDate:
        output.previousStartDate && output.previousStartDate.toISOString(),
      previousEndDate:
        output.previousEndDate && output.previousEndDate.toISOString(),
      dateRange: output.dateRange && output.dateRange.id,
      includeTags: output.tagsToInclude && output.tagsToInclude,
      excludeTags: output.tagsToExclude && output.tagsToExclude
    });
  }
}
