import {
  AccountModel,
  Account,
  CampaignModel,
  Campaign,
  CrmExternalIntegrationModel,
  CrmExternalIntegration
} from '@ui-resources-angular';
import { snakeCase } from 'lodash-es';
import {
  IftttService,
  Trigger as APITrigger,
  TriggerParam as APITriggerParam,
  Action as APIAction,
  ActionParam as APIActionParam
} from '../ifttt-service-model/ifttt-service-model';
import { AppletActionParam, AppletTriggerParam } from '../applets/applet';
import {
  Webhook,
  WebhookModel,
  WebhookStatus
} from '../../../../../common/services/webhook-model/webhook-model';
import { TranslateService } from '@ngx-translate/core';
import { SENTIMENT_CONFIG, LANGUAGES } from '../../../../../common/constants';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague,
  MonitoringStreamsService,
  MonitoringStream
} from '../../../../../../angular/common/services/api';
import { Injectable, Injector } from '@angular/core';
import { CompanyService } from '../../../../../common/services/company/company.service';

export interface ParamKeyValueMap {
  [paramName: string]: any;
}

export enum UIParamFormType {
  SelectSingle = 'selectSingle',
  SelectMultiple = 'selectMultiple',
  TextInput = 'textInput',
  Textarea = 'textarea',
  Sentiment = 'sentiment',
  Hidden = 'hidden',
  Range = 'range',
  TagsInput = 'tagsInput'
}

export interface UIParam {
  form?: {
    type: UIParamFormType;
    select?: {
      options?: Array<{
        value: any;
        label: string;
        trackBy: number | string;
      }>;
      searchPlaceholderTranslationKey?: string;
    };
    input?: {
      placeholderTranslationKey?: string;
      promptOptions?: string[];
      type?: string;
    };
    sentiment?: {
      options?: any[];
    };
    textArea?: {
      hidePopover: boolean;
    };
    defaultValue?: any;
    startColumn?: boolean;
    showAsCheckboxInput?: boolean;
    maxValues?: number;
  };
  remove?: boolean;
  deserialise?(value: any, allParamValues?: ParamKeyValueMap): any;
  serialise?(value: any, allParamValues?: ParamKeyValueMap): any;
}

export interface TriggerParamConfig extends APITriggerParam, UIParam {}

export interface ActionParamConfig extends APIActionParam, UIParam {}

interface BaseTriggerOrActionConfig {
  translationIds?: {
    title: string;
    description: string;
  };
  iconClass?: string;
  overlayDimensions?: {
    width: number;
    height: number;
  };
  paramOverrides?: {
    [paramName: string]: (injector: Injector) => UIParam | Promise<UIParam>;
  };
  migrateParams?(params: ParamKeyValueMap): ParamKeyValueMap;
}

export interface TriggerConfig extends BaseTriggerOrActionConfig {
  api: {
    name: string;
    trigger?: APITrigger;
  };
  params?: TriggerParamConfig[];
  isAccountTrigger?: boolean;
  isStreamTrigger?: boolean;
  getLabel(
    params: AppletTriggerParam[],
    injector: Injector,
    service: IftttService
  ): any;
}

export interface ActionConfig extends BaseTriggerOrActionConfig {
  api: {
    name: string;
    action?: APIAction;
  };
  service?: IftttService;
  params?: ActionParamConfig[];
  getLabel(
    params: AppletActionParam[],
    injector: Injector,
    service: IftttService
  ): any;
}

interface ParamsObject {
  [variableType: string]: () => Promise<UIParam> | UIParam;
}

export const staticParams = {
  textInput(): UIParam {
    return {
      form: {
        type: UIParamFormType.TextInput,
        input: {
          placeholderTranslationKey: 'TYPE_TEXT_HERE'
        }
      }
    };
  },
  textarea(): UIParam {
    return {
      form: {
        type: UIParamFormType.Textarea,
        input: {
          placeholderTranslationKey: 'TYPE_TEXT_HERE'
        }
      },
      deserialise(text: string): string {
        return text.replace(/^<template>/, '').replace(/<\/template>$/, '');
      },
      serialise(text: string): string {
        return `<template>${text}</template>`;
      }
    };
  },
  tagsInput(): UIParam {
    return {
      form: {
        type: UIParamFormType.TagsInput,
        input: {
          placeholderTranslationKey: 'TYPE_YOUR_KEYWORD_AND_HIT_ENTER'
        }
      }
    };
  },
  accountSelect(): UIParam {
    return {
      form: {
        type: UIParamFormType.SelectSingle,
        select: {
          searchPlaceholderTranslationKey: 'Search for a X account...'
        }
      }
    };
  }
};

@Injectable()
export class IftttTriggerActionParams {
  constructor(
    private translate: TranslateService,
    private injector: Injector,
    private colleaguesService: ColleaguesService,
    private accountModel: AccountModel,
    private monitoringStreamsService: MonitoringStreamsService,
    private teamsService: TeamsService,
    private campaignModel: CampaignModel,
    private webhookModel: WebhookModel,
    private crmExternalIntegrationModel: CrmExternalIntegrationModel,
    private company: CompanyService
  ) {}

  mapTriggers(
    service: IftttService,
    triggers: TriggerConfig[]
  ): Promise<TriggerConfig[]> {
    try {
      if (!Array.isArray(service && service.triggers)) {
        throw new Error(
          `Value for 'ifttt trigger actions triggers' not in expected format.`
        );
      }

      return Promise.all(
        triggers
          .filter((feTrigger) => {
            const apiTrigger = service.triggers.find(
              (at) => at.name === feTrigger.api.name
            );
            if (!apiTrigger) {
              console.error(
                `Trigger ${feTrigger.api.name} not coming from the backend, filtering out...`
              );
            }

            return !!apiTrigger;
          })
          .map((trigger: TriggerConfig) => {
            const apiTrigger = service.triggers.find(
              (at) => at.name === trigger.api.name
            );

            trigger.api.trigger = apiTrigger;

            return this.mapParams<TriggerParamConfig>(
              trigger,
              trigger.api.trigger.params
            ).then((mappedParams) => {
              trigger.params = mappedParams;
              return trigger;
            });
          })
      );
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  async mapActions(
    service: IftttService,
    actions: ActionConfig[]
  ): Promise<ActionConfig[]> {
    try {
      if (!Array.isArray(service && service.actions)) {
        throw new Error(
          `Value for 'ifttt trigger actions' not in expected format.`
        );
      }

      return Promise.all(
        actions
          .filter((a) => {
            const apiAction = service.actions.find(
              (as) => as.name === a.api.name
            );
            if (!apiAction) {
              console.error(
                `Action ${a.api.name} not coming from the backend, filtering out...`
              );
            }

            return !!apiAction;
          })
          .map((action: ActionConfig) => {
            action.service = service;
            action.api.action = service.actions.find(
              (iApiAction) => iApiAction.name === action.api.name
            );

            return this.mapParams<ActionParamConfig>(
              action,
              action.api.action.params
            ).then((mappedParams) => {
              action.params = mappedParams;
              return action;
            });
          })
      );
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  private async mapParams<ParamType>(
    triggerOrAction: TriggerConfig | ActionConfig,
    params
  ): Promise<ParamType[]> {
    const paramConfigFactories = this.getParamConfigFactories();

    const result: any = await Promise.all(
      params.map(
        async (param): Promise<ParamType> => {
          let uiParam: UIParam;
          if (
            triggerOrAction.paramOverrides &&
            triggerOrAction.paramOverrides[param.name]
          ) {
            uiParam = await triggerOrAction.paramOverrides[param.name](
              this.injector
            );
          } else if (paramConfigFactories[param.variableType]) {
            uiParam = await paramConfigFactories[param.variableType]();
          } else {
            console.error(`Unknown param type "${param.variableType}"`);
          }
          return Object.assign({}, param, uiParam) as ParamType;
        }
      )
    );

    return result.filter((param: UIParam) => !param.remove);
  }

  private getParamConfigFactories(): ParamsObject {
    const {
      colleaguesService,
      accountModel,
      monitoringStreamsService,
      teamsService,
      campaignModel,
      translate,
      webhookModel,
      crmExternalIntegrationModel
    } = this;

    const sortByLabel = (itemA, itemB) =>
      itemA.label.localeCompare(itemB.label);

    function userIdParamFactory(type: UIParamFormType, deserialise, serialise) {
      return () =>
        colleaguesService.getAll().then((colleagues) => {
          const activeUsers = colleagues
            .filter((colleague) => colleague.is_active)
            .map((colleague) => ({
              value: colleague,
              label: colleague.fullName,
              trackBy: colleague.id
            }))
            .sort(sortByLabel);

          return {
            form: {
              type,
              select: {
                options: activeUsers,
                searchPlaceholderTranslationKey: 'SEARCH_USER_NAME'
              }
            },
            deserialise,
            serialise
          };
        });
    }

    function teamIdParamFactory(type: UIParamFormType, deserialise, serialise) {
      return () =>
        teamsService.getAll().then((teams) => {
          const teamOptions = teams
            .map((team) => ({
              value: team,
              label: team.name,
              trackBy: team.id
            }))
            .sort(sortByLabel);

          return {
            form: {
              type,
              select: {
                options: teamOptions,
                searchPlaceholderTranslationKey: 'SEARCH_TEAM_NAME'
              }
            },
            deserialise,
            serialise
          };
        });
    }

    return {
      'AccountId[]'(): Promise<UIParam> {
        // make sure accounts are in the cache, as they may not be loaded if accessing the triggers page on first load
        return accountModel.findAll().then(() => {
          return {
            form: {
              type: 'selectMultiple'
            },
            deserialise(accountIds: number[]): Account[] {
              return accountIds
                .map((accountId) => accountModel.get(accountId))
                .filter((account) => !!account);
            },
            serialise(accounts: Account[]): number[] {
              return accounts.map((account) => +account.id);
            }
          };
        }) as any;
      },
      'SearchStreamId[]'(): Promise<UIParam> {
        // make sure streams are in the cache, as they may not be loaded if accessing the triggers page on first load
        return monitoringStreamsService.getAll().then((allStreams) => {
          return {
            form: {
              type: 'selectMultiple'
            },
            deserialise(streamsIds: string[]): MonitoringStream[] {
              return streamsIds
                .map((id) => allStreams.find((s) => s.id === id))
                .filter((stream) => !!stream);
            },
            serialise(streams: MonitoringStream[]): string[] {
              return streams.map((s) => s.id);
            }
          };
        }) as any;
      },
      UserId: userIdParamFactory(
        UIParamFormType.SelectSingle,
        (userId: number): Colleague => colleaguesService.store.find(userId),
        (colleague: Colleague): number => colleague && +colleague.id
      ) as any,
      ['UserId[]']: userIdParamFactory(
        UIParamFormType.SelectMultiple,
        (userIds: number[]): Colleague[] =>
          userIds.map((userId) => colleaguesService.store.find(userId)),
        (colleagues: Colleague[]): number[] =>
          colleagues.map((colleague) => colleague && +colleague.id)
      ) as any,
      TeamId: teamIdParamFactory(
        UIParamFormType.SelectSingle,
        (teamId: string | number): Team => teamsService.store.find(teamId),
        (team: Team): number => team && +team.id
      ) as any,
      ['TeamId[]']: teamIdParamFactory(
        UIParamFormType.SelectMultiple,
        (teamIds: string[]): Team[] =>
          teamIds.map((teamId) => teamsService.store.find(teamId)),
        (teams: Team[]): number[] => teams.map((team) => team && +team.id)
      ) as any,
      CampaignId(): Promise<UIParam> {
        return campaignModel.findAll().then((campaigns) => {
          const campaignOptions = campaigns
            .filter((campaign) => !campaign.is_closed && !campaign.is_deleted)
            .map((campaign) => ({
              value: campaign,
              label: campaign.name,
              trackBy: campaign.id
            }))
            .sort(sortByLabel);

          return {
            form: {
              type: UIParamFormType.SelectSingle,
              select: {
                options: campaignOptions,
                searchPlaceholderTranslationKey: 'SEARCH_CAMPAIGN_NAME'
              }
            },
            deserialise(campaignId: number): Campaign {
              return campaignModel.get(campaignId);
            },
            serialise(campaign: Campaign): number {
              return campaign && +campaign.id;
            }
          };
        }) as any;
      },
      WebHookUUID(): Promise<UIParam> {
        return webhookModel.findAll().then((webhooks) => {
          const webhookOptions = webhooks
            .filter((webhook) => webhook.status === WebhookStatus.Enabled)
            .map((webhook) => ({
              value: webhook,
              label: webhook.name,
              trackBy: webhook.uuid
            }))
            .sort(sortByLabel);

          return {
            form: {
              type: UIParamFormType.SelectSingle,
              select: {
                options: webhookOptions,
                searchPlaceholderTranslationKey: 'SEARCH_WEBHOOKS'
              }
            },
            deserialise(webhookUUID: number): Webhook {
              return webhookModel.get(webhookUUID);
            },
            serialise(webhook: Webhook): string {
              return webhook && webhook.uuid;
            }
          };
        }) as any;
      },
      ['EmailAddress[]'](): UIParam {
        return {
          form: {
            type: UIParamFormType.Textarea,
            textArea: {
              hidePopover: true
            },
            input: {
              placeholderTranslationKey: 'ENTER_EMAIL_ADDRESS'
            }
          },
          deserialise(email_addresses: string[]): string {
            return email_addresses.join(' ');
          },
          serialise(email_addresses: string): string[] {
            const cleanedEmailAddresses = email_addresses.replace(
              /[,\t\n\r]/g,
              ' '
            );
            return cleanedEmailAddresses.split(' ').filter((val) => val);
          }
        };
      },
      'Language[]'(): UIParam {
        return {
          form: {
            type: UIParamFormType.SelectMultiple,
            select: {
              options: Object.entries(LANGUAGES)
                .map(([value, label]) => ({ value, label, trackBy: value }))
                .sort(sortByLabel),
              searchPlaceholderTranslationKey: 'SEARCH_LANGUAGES'
            }
          }
        };
      },
      'Sentiment[]'(): UIParam {
        return {
          form: {
            type: UIParamFormType.Sentiment,
            sentiment: {
              options: Object.entries(SENTIMENT_CONFIG)
                .map(([value, sentiment]: [string, any]) => {
                  return {
                    value: snakeCase(value),
                    sentiment,
                    label: translate.instant(sentiment.translateKey)
                  };
                })
                .reverse()
            }
          }
        };
      },
      'IntegrationUUID[]'(): Promise<UIParam> {
        return crmExternalIntegrationModel.findAll().then((integrations) => {
          const integrationOptions = integrations
            .filter((integration) => !integration.is_disabled)
            .map((integration) => ({
              value: integration,
              label: integration.name,
              trackBy: integration.id
            }))
            .sort(sortByLabel);

          return {
            form: {
              type: UIParamFormType.SelectMultiple,
              select: {
                options: integrationOptions,
                searchPlaceholderTranslationKey: 'SEARCH_CRMS'
              }
            },
            deserialise(ids: number[]): CrmExternalIntegration[] {
              return ids.map((id) => crmExternalIntegrationModel.get(id));
            },
            serialise(
              selectedIntegrations: CrmExternalIntegration[]
            ): string[] {
              return selectedIntegrations.map((integration) => integration.id);
            }
          };
        }) as any;
      },
      'AiClassification[]'(): UIParam {
        return {
          form: {
            type: UIParamFormType.SelectMultiple,
            select: {
              options: [
                {
                  value: 'disaster_related',
                  label: 'Disaster Related',
                  trackBy: 'disaster_related'
                }
              ],
              searchPlaceholderTranslationKey: 'Search classifications'
            }
          }
        };
      },
      string: staticParams.textInput,
      Template: staticParams.textarea,
      'string[]': staticParams.tagsInput,
      int: staticParams.textInput
    };
  }
}
