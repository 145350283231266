import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {
  PhotoEditorSDKUI,
  EditorApi,
  UIEvent,
  ExportFormat,
  Tool,
  TransformIdentifier,
  ImageFormat
} from 'photoeditorsdk/no-polyfills';
import { PHOTO_EDITOR_SDK_LICENSE } from '../../../constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FilestackFile,
  FilestackService
} from '../../../services/filestack/filestack.service';
import * as React from 'react'; // the PhotoEditorSDK expects React and ReactDom to be available in global/window
import * as ReactDom from 'react-dom';

declare global {
  interface Window {
    React: any;
    ReactDom: any;
  }
}
window.React = window.React || React;
window.ReactDom = window.ReactDom || ReactDom;

@Component({
  selector: 'ssi-photoeditorsdk-desktopui',
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class PhotoEditorSDKDesktopUIComponent implements AfterViewInit {
  @Input() imageUrl: string;
  @Input() isPngImage = false;

  @Output() exportedImage = new EventEmitter<FilestackFile | void>();

  editor: EditorApi;

  constructor(
    private el: ElementRef,
    public activeModal: NgbActiveModal,
    private filestack: FilestackService
  ) {}

  ngAfterViewInit() {
    this.instantiateEditor();
  }

  async instantiateEditor() {
    const imageEl = new Image();
    imageEl.crossOrigin = 'anonymous';

    // if this.imageUrl is our S3 bucket, and does not already have query params,
    // add cache busting, see https://github.com/orlo/orlo/issues/266
    if (
      this.imageUrl.startsWith(
        'https://filebox-bc764e08f3d5.s3.eu-west-1.amazonaws.com/'
      ) &&
      this.imageUrl.indexOf('?') === -1
    ) {
      imageEl.src = this.imageUrl + '?x-cache-bust=' + new Date().getTime();
    } else {
      imageEl.src = this.imageUrl;
    }

    try {
      this.editor = await PhotoEditorSDKUI.init({
        container: this.el.nativeElement,
        license: PHOTO_EDITOR_SDK_LICENSE,
        image: imageEl,
        assetBaseUrl:
          'https://website-app.s3-eu-west-1.amazonaws.com/assets/photo-editor-assets',
        layout: 'basic',
        export: {
          image: {
            format: this.isPngImage ? ImageFormat.PNG : ImageFormat.JPEG, // stupid, but it seems that pesdk export-format defaults to PNG which causes issues for JPEG images (JPEG e.g. enters as 0.5 MB and exits as 10MB due to conversion to PNG)
            exportType: ExportFormat.DATA_URL,
            // quality: 0.8,
            enableDownload: false
          }
        },
        custom: {
          languages: {
            en: {
              mainCanvasActions: {
                buttonExport: 'Attach to Post'
              }
            }
          }
        },
        tools: [
          Tool.TRANSFORM,
          Tool.FRAME,
          Tool.FILTER,
          Tool.FOCUS,
          Tool.BRUSH,
          Tool.TEXT,
          Tool.TEXT_DESIGN
        ],
        defaultTool: Tool.TRANSFORM,
        transform: {
          categories: [
            {
              identifier: TransformIdentifier.CommonCategory
            },
            {
              identifier: TransformIdentifier.FacebookCategory,
              name: 'Facebook',
              items: [
                {
                  identifier: TransformIdentifier.FacebookPost,
                  ratio: 1.91 / 1,
                  name: 'Post',
                  dimensions: { x: 1200, y: 628 },
                  forceDimensions: true,
                  lockDimensions: true
                }
              ]
            },
            {
              identifier: TransformIdentifier.InstagramCategory
            },
            {
              identifier: TransformIdentifier.TwitterCategory,
              name: 'Twitter',
              items: [
                {
                  identifier: 'custom_twitter_tweet',
                  ratio: 16 / 9,
                  name: 'Tweet'
                  // dimensions: { x: 1200, y: 675 },
                }
              ]
            },
            {
              identifier: 'custom_linkedin',
              name: 'LinkedIn',
              items: [
                {
                  identifier: 'custom_linkedin_post',
                  ratio: 1.91 / 1,
                  name: 'Post',
                  dimensions: { x: 1200, y: 627 },
                  forceDimensions: true,
                  lockDimensions: true
                }
              ]
            }
          ]
        }
      });

      this.editor.on(UIEvent.EXPORT, async (base64Image) => {
        const filestackClient = await this.filestack.getClient();
        this.el.nativeElement.firstElementChild.classList.add(
          'photoeditorsdk-editor-saving'
        );
        const exportedImage = await filestackClient.upload(base64Image);
        this.exportedImage.emit(exportedImage);
        this.activeModal.close();
      });
      this.editor.on(UIEvent.CLOSE, () => {
        this.exportedImage.emit();
        this.activeModal.close();
      });
    } catch (error) {
      console.error('Image export error:', error);
    }
  }
}
