<div class="campaigns-stats">
  <div class="campaign-stats-row">
    <div class="campaign-stats-col validation-filters-aside">
      <div
        *ngIf="selected?.length > 0"
        class="campaigns-bulk"
      >
        <i class="ssi ssi-validations-microcopy campaigns-bulk-icon"></i>
        <p class="campaigns-bulk-title">With your {{selected?.length}} selected replies...</p>
        <p class="campaigns-bulk-meta">TIP: To select multiple replies at once, hold the Shift key and select the
          replies you would like to disapprove or approve!</p>
        <button
          (click)="validateSelected(false)"
          class="btn btn-style-1 btn-danger"
          translate="DISAPPROVE"
        >
        </button>
        <button
          (click)="validateSelected(true)"
          class="btn btn-style-1 btn-primary"
          translate="APPROVE"
        >
        </button>
      </div>
      <div class="campaigns-selection">
        <div class="campaigns-selection-actions-wrap">
          <div class="campaigns-info">
            <i
              (click)="refresh()"
              class="ssi ssi-correct-refresh campaigns-selection-action-refresh campaigns-selection-action"
            ></i>
            <p>Showing {{validations?.length}} of {{validations?.length}}</p>
          </div>
          <ul class="campaigns-selection-actions">
            <li>
              <p
                class="campaigns-selection-action"
                (click)="selectAll()"
                translate="SELECT_ALL"
              ></p>
            </li>
            <li>
              <p
                class="campaigns-selection-action"
                (click)="selected = []"
                translate="DESELECT_ALL"
              ></p>
            </li>
          </ul>
        </div>
      </div>
      <ssi-validate-replies-filters
        #filterComponent
        [workflowAccounts]="workflowAccounts"
        [colleagues]="colleagues"
        [authUser]="authUser"
        [postPerPage]="postPerPage"
        [(validations)]="validations"
        [(isLoading)]="isLoading"
        (updatePagination)="updatePagination($event)"
      >
      </ssi-validate-replies-filters>
    </div>

    <div class="campaign-stats-col campain-stats-main">
      <div
        class="loading"
        *ngIf="isLoading"
      ></div>
      <ul
        class="reset-list-style"
        *ngIf="!isLoading"
      >
        <li *ngFor="let validation of validations">
          <ssi-validate-reply-box
            [ngClass]="{'validate-posts-box-select-mode': selectMode}"
            [validation]="validation"
            [colleagues]="colleagues"
            [accounts]="workflowAccounts"
            [selected]="selected"
            [validated]="validation?.is_validated"
            [selectMode]="selectMode"
            (addToSelected)="addToSelected($event)"
            (postValidated)="postValidated($event)"
            (click)="selectValidation($event, validation)"
          >
          </ssi-validate-reply-box>
        </li>
      </ul>
      <ol class="advert-table-pagination">
        <li
          *ngFor="let page of pages"
          (click)="changePage(page)"
          class="pagination-item"
          [ngClass]="{'pagination-item-active': page.active}"
        >
          {{page.number}}
        </li>
      </ol>
      <div
        *ngIf="validations.length === 0"
        class="no-validation-posts"
      >
        <span class="ssi ssi-microcopy-koala no-validation-posts-image"></span>
        <p class="no-validation-posts-title">Muy bien! All done</p>
        <p class="no-validation-posts-meta">You have no more validations here, the cuddly koala salutes you.</p>
      </div>
    </div>
  </div>
</div>