
    <svg-icon
      *ngIf="this.validIcon"
      [src]="this.imgMap[this.icon]"
      [class]="this.wrapperClass ? this.wrapperClass : ''"
      [svgStyle]="{
        'height.px': this.height || 14,
        'width.px': this.width || 14,
        'fill': this.color
      }">
    </svg-icon>
  