import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import './notes-modal.component.scss';
import { NotesModelService, Note } from '../notes-model/notes-model.service';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../../../common/services/api';
import _ from 'lodash';

@Component({
  selector: 'ssi-notes-modal',
  templateUrl: './notes-modal.component.html'
})
export class NotesModalComponent {
  validation: any;
  notes: Note[];
  colleagues: Colleague[];
  teams: Team[];
  user: Colleague;
  updateNotes: any;

  constructor(public modalInstance: NgbActiveModal) {}
}
