<div class="publisher-expiry-modal">
  <div class="publisher-expiry-modal-header">
    <div class="publisher-expiry-modal-header-left">
      <h2 translate="SET_POST_EXPIRY_DATE"></h2>
    </div>
    <div class="publisher-expiry-modal-header-right">
      <button (click)="activeModal.close(false)" class="publisher-expiry-modal-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="publisher-expiry-modal-body">
    <p translate="SET_AN_EXPIRY_DATE_ON_THIS_POST_SO_THAT_ON_THE_SELECTED_DATE_AND_AT_THE_SELECTED_TIME_IT_IS_REMOVED_FROM_THE_ORLO_PLATFORM_AND_THE_RELEVANT_NATIVE_NETWORK"></p>
    <div class="publisher-expiry-modal-action publisher-expiry-modal-body-row">
      <label>Expiry Date:</label>
      <div class="input-wrapper" #expiryDateContainer>
        <input
          mwlFlatpickr
          class="date-picker-input"
          type="text"
          name="expiryDate"
          [(ngModel)]="expiryDate"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          [defaultHour]="00"
          [defaultMinute]="00"
          [defaultSeconds]="00"
          dateFormat="Y-m-d H:i"
          altFormat="M J Y, H:i"
          [minDate]="startDate"
          [time24hr]="true"
          [appendTo]="expiryDateContainer.nativeElement"
          placeholder="Set expiration date..."
          [required]="true"
        >
        <i class="ssi ssi-ad-schedule"></i>
      </div>
    </div>
  </div>

  <div class="publisher-expiry-modal-footer">
    <div class="publisher-expiry-modal-footer-left"></div>
    <div class="publisher-expiry-modal-footer-right">
      <orlo-button
        class="publisher-expiry-modal-button publisher-expiry-modal-button-secondary"
        text="Cancel"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        class="publisher-expiry-modal-button"
        [text]="actionButtonText"
        textColor="#fff"
        bgColor="#14BAE3"
        [disabled]="!expiryDate"
        (click)="expiryDate && activeModal.close(expiryDate)"
      ></orlo-button>
    </div>
  </div>
</div>