import './calendar-event-modal.component.scss';

import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TooltipDirective } from '../../../../../../../common/directives/tooltip/tooltip.directive';
import { NotificationService } from '../../../../../../../common/services/notification/notification.service';
import { CompanyPreferences } from '../../../../../../../common/services/company/company.service';
import { CustomCalendarEvent } from '../../../../../../../common/components/calendar-utils';
import { ContentCalendarService } from '../../../../content-calendar/content-calendar.service';

@Component({
  selector: 'ssi-calendar-event-modal',
  templateUrl: './calendar-event-modal.component.html',
  styles: []
})
export class CalendarEventModalComponent {
  @Input() event?: CustomCalendarEvent;
  @Input() startDate?: Date;
  @Input() showDeleteOption = false;
  @Input() eventTitle: string = '';
  @Input() endDate: Date;
  @Input() companyPreferences: CompanyPreferences;
  @Input() events: CustomCalendarEvent[];
  @Input() eventColour: string = '#2889e9';
  @Input() isMobile = false;

  @ViewChild('deleteEventTooltip') deleteEventTooltip: TooltipDirective;

  constructor(
    public activeModal: NgbActiveModal,
    private notification: NotificationService,
    private contentCalendarNewService: ContentCalendarService
  ) {}

  async deleteEvent() {
    this.events = this.events.filter(({ id }) => id !== this.event.id);

    this.deleteEventTooltip.hide();

    await this.contentCalendarNewService
      .saveCalendarEvents(this.events, this.companyPreferences)
      .then(({ success }) => {
        if (success) {
          this.activeModal.close(this.events);
          this.notification.open(
            'Event has been deleted successfully',
            {
              class: 'ssi ssi-completed-notification',
              color: '#F0B427'
            },
            3000
          );
        } else {
          this.activeModal.close();
        }
      });
  }

  async saveEvent() {
    if (this.event && this.event.id) {
      const updatedEvent = this.events.find(({ id }) => {
        return id === this.event.id;
      });
      updatedEvent.title = this.eventTitle;
      updatedEvent.start = this.startDate;
      updatedEvent.end = this.endDate;
      updatedEvent.colour = this.eventColour.replace('rgb(', '').slice(0, -1);
    } else {
      this.events.push({
        title: this.eventTitle,
        start: this.startDate,
        end: this.endDate,
        colour: this.eventColour.replace('rgb(', '').slice(0, -1), // Stupid workaround because angular doesn't like hex
        id: Math.random()
          .toString(36)
          .slice(2, 7)
          .split('')
          .reduce((a, b) => (a = (a << 5) - a + b.charCodeAt(0)) & a, 0) // create hash because we are working with a JSON blob on the backend
      });
    }
    await this.contentCalendarNewService
      .saveCalendarEvents(this.events, this.companyPreferences)
      .then(({ success }) => {
        if (success) {
          this.activeModal.close(this.events);
          this.notification.open(
            'Event has been saved successfully',
            {
              class: 'ssi ssi-completed-notification',
              color: '#F0B427'
            },
            3000
          );
        } else {
          this.activeModal.close();
        }
      });
  }
}
