import './twitter-threads.component.scss';
import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { API, AccountModel, Account, Outbox } from '@ui-resources-angular';
import {
  Colleague,
  ColleaguesService
} from '../../../../common/services/api/colleagues';
import {
  PUBLISHER_ACTIVE,
  PublisherActive,
  PublisherActiveParams
} from '../../../../common/components/publisher/publisher-active';
import { TwitterThreadService } from './service/twitter-threads.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Campaign,
  CampaignsService
} from '../../../../common/services/api/campaigns';

export async function threadFn(transition: Transition, api: API) {
  const id = transition.params().outboxId;
  return api
    .get(`outbox/socialIdThread?outbox_id=${id}`)
    .then(({ data }) => data.filter((post) => !post.deleted_at));
}

@Component({
  selector: 'ssi-twitter-threads',
  templateUrl: './twitter-threads.component.html'
})
export class TwitterThreadsComponent implements OnInit, OnDestroy {
  static resolve = [
    {
      token: 'thread',
      resolveFn: threadFn,
      deps: [Transition, API]
    }
  ];

  @Input() thread: Outbox[];
  account: Account;
  colleagues: Colleague[];
  publisherOpen: boolean;
  pendingPublish: boolean;
  displayAdd: boolean;
  publisherActiveSubscription: Subscription;
  twitterThreadsSubscription: Subscription;
  displayAddSubscription: Subscription;
  onDestroy = new Subject();

  constructor(
    @Inject('Window') public window: any,
    private accountModel: AccountModel,
    private colleaguesService: ColleaguesService,
    private twitterThreadService: TwitterThreadService,
    // private api: API,
    private campaignModel: CampaignsService,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive
  ) {}

  async ngOnInit() {
    if (!this.thread.length) {
      return;
    }
    this.account = await this.accountModel.get(this.thread[0].account_id);
    this.colleagues = await this.colleaguesService.getAllActive();
    this.thread = this.formatThread(this.thread);

    this.publisherActiveSubscription = this.publisherActive
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data: PublisherActiveParams) => {
        this.publisherOpen = data.isActive;
        if (data.published) {
          this.pendingPublish = data.published;
        }
      });

    this.twitterThreadsSubscription = this.twitterThreadService.newOutbox.subscribe(
      (data: any) => {
        if (
          data.deleted_at &&
          this.thread.find((message) => message.id === data.id)
        ) {
          const deletedMessageId = this.thread.find(
            (message) => message.id === data.id
          ).id;
          this.thread = this.thread.filter(
            (message) => message.id !== deletedMessageId
          );
          return;
        }
        if (data.social_id) {
          const tempPost = this.thread.find(
            (post) => +post.id === data.outbox_id
          );
          if (tempPost) {
            tempPost.social_id = data.social_id;
          }
          return;
        }
        if (
          data.in_reply_to_social_id ===
          this.thread[this.thread.length - 1].social_id
        ) {
          this.thread.push(data);
          this.thread = this.formatThread(this.thread);
          return;
        }
      }
    );

    this.displayAddSubscription = this.twitterThreadService.displayAdd.subscribe(
      (data: boolean) => {
        this.displayAdd = data;
      }
    );
  }

  ngOnDestroy() {
    if (this.publisherActiveSubscription) {
      this.publisherActiveSubscription.unsubscribe();
    }
    if (this.twitterThreadsSubscription) {
      this.twitterThreadsSubscription.unsubscribe();
    }
    if (this.displayAddSubscription) {
      this.displayAddSubscription.unsubscribe();
    }
  }

  private formatThread(thread) {
    this.thread.forEach(async (threadItem) => {
      threadItem.externalLink = this.account.socialNetwork.getExternalLink(
        new Outbox({ account: this.account, social_id: threadItem.social_id })
      );
      // threadItem.toOutboxPublisherMessageFormat = (): Promise<OutboxMessage> => {
      //   return this.api
      //     .get<{ data: { message: OutboxMessage } }>('outbox_v2/indexOutboxv2', {
      //       params: {
      //         mention_tag: 1,
      //         id: threadItem.id
      //       }
      //     })
      //     .then(({ data }) => data.message);
      // }

      // Returned as string from API, but OutboxModel is using an old CampaignModel which has defined it as a string so using `any`
      threadItem.campaign = (await this.campaignModel.getById(
        threadItem.campaign_id
      )) as any;
    });
    this.orderThread(thread);
    return thread;
  }

  private orderThread(thread): Outbox[] {
    return thread.sort((a, b) => {
      const valA = new Date(a.send_at).getTime();
      const valB = new Date(b.send_at).getTime();
      return valA < valB ? -1 : valA > valB ? 1 : 0;
    });
  }

  public getAuthor(authorId): Colleague {
    if (!this.colleagues) {
      return;
    }

    return Object.assign(
      {},
      ...this.colleagues.filter((colleague) => colleague.id === authorId)
    );
  }
}
