import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { AnalyticsService } from './analytics.service';
import { AnalyticsComponent } from './analytics.component';
import { AdStatsModule } from './ad-stats/ad-stats.module';
import { CampaignModule } from './campaign/campaign.module';
import { MarketingModule } from './marketing/marketing.module';
import { EngagementModule } from './engagement/engagement.module';
import { CompetitorAnalysisModule } from './competitor-analysis/competitor-analysis.module';
import { ChatBotModule } from './chatbot/chatbot.module';
import { SurveyModule } from './survey/survey.module';
import { DisapprovalsModule } from './disapprovals/disapprovals.module';
import { AccountModule } from './account/account.module';
import { TeamModule } from './team/team.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics',
          url: '/analytics',
          component: AnalyticsComponent
        }
      ]
    }),
    AdStatsModule,
    CampaignModule,
    MarketingModule,
    CompetitorAnalysisModule,
    EngagementModule,
    ChatBotModule,
    SurveyModule,
    DisapprovalsModule,
    AccountModule,
    TeamModule
  ],
  declarations: [AnalyticsComponent],
  entryComponents: [AnalyticsComponent],
  providers: [AnalyticsService]
})
export class AnalyticsModule {}
