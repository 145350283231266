import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BigNumberComponent } from './big-number.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BigNumberComponent],
  exports: [BigNumberComponent]
})
export class BigNumberModule {}
