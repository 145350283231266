<div class="dashboard-widget-modal">

  <button (click)="activeModal.dismiss()"
          class="dwm-close">
    <i class="ssi ssi-addsmall"></i>
  </button>

  <div class="dwm-container">

    <div class="dwm-aside">
      <div class="dwm-aside-header">
        <h1>Edit your widget</h1>
      </div>

      <div class="dwm-aside-body">
        <div class="thumbnail-action">
          <div class="item-thumb ssi ssi-line-chart"></div>
          <button class="thumbnail-button"
                  type="button"><i class="ssi ssi-delete-close"></i></button>
        </div>

        <h2>{{widget.typeConst.label}}</h2>
        <p>{{widget.typeConst.tooltipExplainer}}</p>

        <div *ngIf="widget.typeConst.widgetBuilderExplainer"
             class="dwm-aside-meta">
          <p>{{widget.typeConst.widgetBuilderExplainer}}</p>
        </div>
      </div>
    </div>

    <div class="dwm-body">
      <div class="dwm-body-header">
        <a href="javascript:;"
           (click)="activeModal.dismiss()">
          <i class="ssi ssi-line-arrow-small-down"></i>
          <p>Back to widgets</p>
        </a>
      </div>

      <div class="dwm-body-content">
        <ng-container [ngSwitch]="widget.typeConst">
          <ssi-widget-inbox-message-config *ngSwitchCase="widgetTypes.inboxMessageMode"
                                           #configComponent
                                           class="widget-config"
                                           [widget]="widget"
                                           (selectedPresetChange)="onSelectedPresetChange($event)"></ssi-widget-inbox-message-config>

          <ssi-widget-inbox-conversations-config *ngSwitchCase="widgetTypes.inboxConversationMode"
                                                 #configComponent
                                                 class="widget-config"
                                                 [widget]="widget"
                                                 (selectedPresetChange)="onSelectedPresetChange($event)"></ssi-widget-inbox-conversations-config>

          <ssi-widget-marketing-posts-config *ngSwitchCase="widgetTypes.marketingPosts"
                                             #configComponent
                                             class="widget-config"
                                             [widget]="widget"
                                             (selectedPresetChange)="onSelectedPresetChange($event)"></ssi-widget-marketing-posts-config>

          <ssi-widget-rss-feeds-config *ngSwitchCase="widgetTypes.rssFeeds"
                                       class="widget-config"
                                       [widget]="widget"
                                       (onFormValidityChange)="formValid = $event"></ssi-widget-rss-feeds-config>

          <ssi-widget-live-x-stream-config *ngSwitchCase="widgetTypes.liveXStream"
                                           class="widget-config"
                                           [widget]="widget"
                                           (onFormValidityChange)="formValid = $event"></ssi-widget-live-x-stream-config>

          <ssi-widget-monitoring-stream-config *ngSwitchCase="widgetTypes.monitoringStream"
                                               class="widget-config"
                                               [widget]="widget"></ssi-widget-monitoring-stream-config>

          <ssi-widget-default-config *ngSwitchDefault
                                     class="widget-config"
                                     [widget]="widget"></ssi-widget-default-config>
        </ng-container>

        <div class="dwm-filters"
             *ngIf="widget.typeConst === widgetTypes.inboxMessageMode || widget.typeConst === widgetTypes.inboxConversationMode || widget.typeConst === widgetTypes.marketingPosts">
          <label class="dwm-label">{{widget.typeConst === widgetTypes.marketingPosts ? 'Marketing post filters' : 'Inbox filters'}}</label>
          <ssi-filters [config]="widget.typeConst === widgetTypes.inboxMessageMode ? 'inboxMessage' : widget.typeConst === widgetTypes.inboxConversationMode ? 'inboxConversation' : 'outbox'"
                       [standalone]="false"
                       [visible]="true"
                       [savedApiParams]="widget.apiSearchParams"
                       [hideTimePeriodFilter]="widget.typeConst === widgetTypes.marketingPosts"
                       (onFiltersChange)="onActiveFiltersChange($event)"></ssi-filters>
        </div>
      </div>
    </div>

  </div>

  <div class="dwm-footer">
    <div class="dwm-footer-left"></div>
    <div class="dwm-footer-right">
      <button type="button"
              class="g-btn g-btn-secondary bg-white"
              (click)="activeModal.dismiss()">Cancel</button>
      <button type="button"
              class="g-btn save-button"
              [disabled]="!widget.title || !formValid"
              (click)="addWidget($event)">{{edit ? 'Save' : 'Add widget'}}</button>
    </div>
  </div>
</div>