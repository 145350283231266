import './automated-reports.component.scss';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Debounce } from '../../../../../common/decorators';
import {
  AutomatedReportsService,
  AutomatedReport,
  AutomatedReportType
} from '../../../../../common/services/api';
import { AutomateModalComponent } from '../../../../../common/components/automate-modal/automate-modal.component';
import { NotificationService } from '../../../../../common/services/notification/notification.service';

@Component({
  selector: 'ssi-automated-reports',
  templateUrl: './automated-reports.component.html',
  styles: []
})
export class AutomatedReportsComponent implements OnInit, OnDestroy {
  searchTerm = '';
  reportsToShow: AutomatedReport[] = [];
  activeTab = AutomatedReportType.Analytics;
  AutomatedReportType = AutomatedReportType;

  reports = {
    [AutomatedReportType.Analytics]: [],
    [AutomatedReportType.Insights]: []
  };

  protected destroyed$ = new Subject<void>();

  constructor(
    protected modal: NgbModal,
    protected automatedReportsService: AutomatedReportsService,
    protected notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.automatedReportsService.getAll();
    this.automatedReportsService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((reports) => {
        console.log('subscribe reports: ', reports);
        this.reports[AutomatedReportType.Analytics] = reports.filter((r) =>
          r.isAnalyticsReport()
        );
        this.reports[AutomatedReportType.Insights] = reports.filter((r) =>
          r.isInsightsReport()
        );
        this.setReportsToShow();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeTab(tab: AutomatedReportType) {
    this.activeTab = tab;
    this.searchTerm = '';
    this.setReportsToShow();
  }

  @Debounce(400, false)
  onFilterInput(event: any): void {
    this.setReportsToShow();
  }

  setReportsToShow(): void {
    console.log('before: ', this.reports);
    if (!this.searchTerm) {
      this.reportsToShow = [...this.reports[this.activeTab]];
      return;
    }

    this.reportsToShow = this.reports[this.activeTab].filter(
      (c) =>
        (c.name || '').toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
    );
  }

  async viewReport(report: AutomatedReport): Promise<void> {
    window.open(report.url, '_blank').focus();
  }

  async editReport(report: AutomatedReport): Promise<void> {
    const automateModal = this.modal.open(AutomateModalComponent, {
      windowClass: 'orlo-modal'
    });
    automateModal.componentInstance.editMode = true;
    automateModal.componentInstance.reportData = report;

    const result = await automateModal.result;
  }

  async deleteReport(report: AutomatedReport): Promise<void> {
    await this.automatedReportsService.delete(report);

    this.notificationService.open(
      `'${report.name}' report has been deleted`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      4000
    );
  }
}
