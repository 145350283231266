import './average-response-time.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SecondsToHumanTimePipe } from '../../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';
import { Options } from 'highcharts';
import { SnackbarService } from '../../../../../../common/services/snackbar/snackbar.service';

export enum Days {
  mon = 'Monday',
  tue = 'Tuesday',
  wed = 'Wednesday',
  thu = 'Thursday',
  fri = 'Friday',
  sat = 'Saturday',
  sun = 'Sunday'
}

@Component({
  selector: 'ssi-average-response-time',
  templateUrl: './average-response-time.component.html',
  styles: []
})
export class AverageResponseTimeComponent implements OnInit {
  @Input() stats;
  chartConfig: Options;
  copyStats = {
    good: {
      day: '',
      time: '',
      text: ''
    },
    bad: {
      day: '',
      time: '',
      text: ''
    }
  };

  constructor(
    private secondsToHumanTimePipe: SecondsToHumanTimePipe,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.chartConfig = this.getChartConfig();
    this.copyStats = this.getCopyStats(this.stats.chartData);
  }

  copyText(isGood) {
    const text = isGood ? this.copyStats.good.text : this.copyStats.bad.text;
    const formattedText = text.split('<br>').join(' ');
    const tempInput = document.createElement('textarea');
    document.body.appendChild(tempInput);
    tempInput.value = formattedText;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.snackbar.open('Copied to clipboard!', { duration: 3 });
  }

  private getCopyStats(stats) {
    const dayAverages: { [key: string]: number } = {};
    Object.keys(stats).forEach((day) => {
      if (stats[day].length > 1) {
        const total = Object.values(stats[day]).reduce(
          (a, b) => (a as any).value + (b as any).value,
          0
        );
        return (dayAverages[day] = total / stats[day].length);
      }
      return (dayAverages[day] = (Object.values(stats[day])[0] as any).value);
    });
    const lowestDayAverage = Math.min(...Object.values(dayAverages));
    const highestDayAverage = Math.max(...Object.values(dayAverages));

    const hourData = {};
    Object.keys(stats).forEach((day) => {
      Object.keys(stats[day]).forEach((hour) => {
        if (hourData.hasOwnProperty(hour)) {
          hourData[hour].total += stats[day][hour].value;
          hourData[hour].days += 1;
          return;
        }
        hourData[hour] = { total: stats[day][hour].value, days: 1 };
      });
    });
    const hourAverages: { [key: string]: number } = {};
    Object.keys(hourData).forEach(
      (key) => (hourAverages[key] = hourData[key].total / hourData[key].days)
    );
    const lowestHourAverage = Math.min(...Object.values(hourAverages));
    const highestHourAverage = Math.max(...Object.values(hourAverages));

    const formatTime = (time) => (time > 12 ? `${time - 12}pm` : `${time}am`);

    const newStats = {
      good: {
        day:
          Days[
            Object.keys(dayAverages).find(
              (key) => dayAverages[key] === lowestDayAverage
            )
          ],
        time: formatTime(
          Object.keys(hourAverages).find(
            (key) => hourAverages[key] === lowestHourAverage
          )
        ),
        text: ''
      },
      bad: {
        day:
          Days[
            Object.keys(dayAverages).find(
              (key) => dayAverages[key] === highestDayAverage
            )
          ],
        time: formatTime(
          Object.keys(hourAverages).find(
            (key) => hourAverages[key] === highestHourAverage
          )
        ),
        text: ''
      }
    };
    newStats.good.text = this.translate.instant('AVERAGE_RESPONSE_TIME_GOOD', {
      day: newStats.good.day,
      time: newStats.good.time
    });
    newStats.bad.text = this.translate.instant('AVERAGE_RESPONSE_TIME_BAD', {
      day: newStats.bad.day,
      time: newStats.bad.time
    });
    return newStats;
  }

  private getChartConfig(): any {
    const includeEmptyValues = false;

    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(moment().hours(i).format('ha'));
    }

    const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    const seriesData = [];
    Object.keys(this.stats.chartData).forEach((dayKey) => {
      const dayIndex = days.findIndex((dow) => dow.toLowerCase() === dayKey);
      if (dayIndex > -1) {
        Object.keys(this.stats.chartData[dayKey]).forEach((hourIndex) => {
          const value = Math.round(
            this.stats.chartData[dayKey][hourIndex].value
          );
          const data = this.stats.chartData[dayKey][hourIndex].data;
          if (value > 0 || includeEmptyValues) {
            const point: {
              x: number;
              y: number;
              data: any;
              value: string | number;
              color?: string;
            } = {
              x: +hourIndex,
              y: dayIndex,
              data,
              value
            };

            if (value === 0) {
              point.value = '';
              point.color = 'white';
            }

            seriesData.push(point);
          }
        });
      }
    });
    const secondsToHumanTimePipe = this.secondsToHumanTimePipe;

    return {
      global: {
        useUTC: false
      },
      chart: {
        type: 'heatmap',
        defaultSeriesType: 'areaspline',
        height: 340,
        zoomType: 'x',
        version: 2,
        backgroundColor: null,
        spacingTop: 5,
        spacingLeft: 10,
        spacingRight: 20
      },
      series: [
        {
          data: seriesData,
          name: '',
          color: '#D41D68',
          borderWidth: 2,
          borderColor: '#F4F4FA',
          dataLabels: {
            enabled: true,
            color: 'black',
            style: {
              textOutline: false,
              fontSize: 12,
              fontWeight: 900
            },
            /* tslint:disable */
            formatter: function () {
              return secondsToHumanTimePipe
                .transform(this.point.value, true, false, {
                  secondLabel: 's',
                  minuteLabel: 'm',
                  dayLabel: 'd',
                  hourLabel: 'h'
                })
                .split(', ')[0];
            }
            /* tslint:enable */
          }
        }
      ],
      xAxis: {
        title: '',
        categories: hours,
        min: 0,
        max: hours.length - 1,
        labels: {
          style: {
            color: '#838EAB'
          }
        },
        dateTimeLabelFormats: {
          month: '%e %b',
          year: '%b'
        }
      },
      yAxis: {
        title: '',
        categories: days,
        min: 0,
        max: days.length - 1,
        reversed: true,
        labels: {
          align: 'center',
          style: {
            color: '#838EAB'
          }
        },
        gridLineWidth: 2,
        gridLineColor: '#F4F4FA'
      },
      colorAxis: {
        min: 0,
        stops: [
          [0, '#2BC1E6'],
          [0.25, '#B2F0FF'],
          [0.5, '#FEE9E2'],
          [0.75, '#FFBFA9'],
          [1, '#F88C68']
        ]
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        /* tslint:disable */
        formatter: function () {
          return `
            At <b>${this.series.xAxis.categories[this.point.x]}</b> on <b>${
            this.series.yAxis.categories[this.point.y]
          }</b>,
            the average response time is ${secondsToHumanTimePipe.transform(
              this.point.value,
              true
            )}
          `;
        }
        /* tslint:enable */
      }
    };
  }
}
