<div class="composer-modal">
  <div class="composer-modal-header">
    <div class="composer-modal-header-left">
      <h2 translate="CREATE_A_NEW_CAMPAIGN"></h2>
    </div>
    <div class="composer-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="composer-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="composer-modal-body">
    <div class="content-interaction">

      <div class="interaction-field input-container">
        <label
          for="text-input"
          translate="CAMPAIGN_NAME"
        ></label>
        <input
          class="composer-modal-input"
          [placeholder]="'NAME_YOUR_CAMPAIGN' | translate"
          [(ngModel)]="campaignForm.name"
          (ngModelChange)="campaignNameChanged($event)"
          type="text"
        >
        <div class="char-count-container">
          <span
            class="font-weight-normal"
            [class.disabled]="campaignForm.name.length && campaignForm.name.length > maxCharacters"
            [class.text-primary]="charactersRemaining > 0"
            [class.text-danger]="charactersRemaining < 0"
          >
            {{ charactersRemaining }}
          </span>
        </div>
      </div>

      <div class="interaction-field">
        <label translate="ADD_TO_A_PARENT_CAMPAIGN"></label>
        <ssi-dropdown-select-2
          headButtonStyleVariant="default"
          [options]="parentCampaignsOptions"
          [(ngModel)]="campaignForm.parentCampaign"
          name="parentCampaign"
          (ngModelChange)="onParentCampaignSelect($event)"
        ></ssi-dropdown-select-2>
      </div>

      <div class="interaction-field">
        <ssi-date-time-picker
          [showTime]="false"
          [dateLabel]="'START_DATE' | translate"
          [timeLabel]="'START_DATE' | translate"
          [pastDateTooltipTitle]="'Unavailable day to set the start'"
          [pastDateTooltipBody]="'Campaign can\'t be set to start in the past'"
          [placeholder]="'SELECT_A_START_DATE' | translate"
          name="dateOnly"
          [(ngModel)]="campaignForm.startDate"
          (ngModelChange)="startDateChanged($event)"
        >
        </ssi-date-time-picker>
      </div>

      <div class="interaction-field">
        <ssi-date-time-picker
          [showTime]="false"
          [dateLabel]="'END_DATE' | translate"
          [timeLabel]="'END_DATE' | translate"
          [pastDateTooltipTitle]="'Unavailable day to set the end'"
          [pastDateTooltipBody]="'Campaign can\'t be set to end in the past'"
          [placeholder]="'THIS_IS_OPTIONAL' | translate"
          name="dateOnly"
          [(ngModel)]="campaignForm.endDate"
          (ngModelChange)="endDateChanged($event)"
        >
        </ssi-date-time-picker>
      </div>
    </div>
  </div>

  <div class="composer-modal-footer">
    <div class="composer-modal-footer-right">
      <orlo-button
        class="composer-modal-button composer-modal-button-secondary"
        [text]="'CANCEL' | translate"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(null)"
      ></orlo-button>
      <orlo-button
        class="composer-modal-button"
        [text]="'CREATE_CAMPAIGN' | translate"
        [textColor]="campaignForm.name.length && campaignForm.name.length < maxCharacters && campaignForm.startDate ? '#FFF' : '#838EAB'"
        [bgColor]="campaignForm.name.length && campaignForm.name.length < maxCharacters && campaignForm.startDate ? '#14BAE3' : '#F4F4FA'"
        (click)="create()"
        [disabled]="campaignForm.name.length > maxCharacters || !campaignForm.name.length || !campaignForm.startDate"
      ></orlo-button>
    </div>
  </div>
</div>