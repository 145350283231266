import { Component, Input, ViewChild } from '@angular/core';
import { EmojiFormControlContainerDirective } from '../emoji-form-control-container.directive';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { JoyPixelsEmoji } from '../emoji-picker/emoji-picker.component';

@Component({
  selector: 'ssi-emoji-form-control-trigger',
  templateUrl: './emoji-form-control-trigger.component.html',
  styles: [
    `
      span {
        display: inline-block;
      }
    `
  ]
})
export class EmojiFormControlTriggerComponent {
  @Input() popoverPlacement = 'right';

  @ViewChild(NgbPopover) popover: NgbPopover;

  emojiFormControlContainer: EmojiFormControlContainerDirective;

  popoverIsOpen: boolean;

  constructor(config: NgbPopoverConfig) {
    config.autoClose = 'outside';
  }

  emojiSelected(emoji: JoyPixelsEmoji) {
    this.popover.close();
    this.emojiFormControlContainer.addEmoji(emoji);
  }

  closePopover() {
    const textContainer = document.querySelector(
      '.publisher-textarea-container'
    );
    if (textContainer) {
      textContainer.classList.add('zero-zindex');
      textContainer.classList.remove('override-zindex');
    }
    this.popover.close();
  }
}
