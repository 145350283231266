import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OrloComponentsModule } from 'orlo-components';

import { AddWidgetComponent } from './add-widget/add-widget.component';
import { CustomWidgetComponent } from './custom-widget/custom-widget.component';
import { CustomWidgetFieldModule } from './custom-widget/custom-widget-field/custom-widget-field.component.module';

import { WidgetTableModule } from '../common/components/widget-table/widget-table.module';
import { WidgetChartBarModule } from '../common/components/widget-chart-bar/widget-chart-bar.module';
import { WidgetChartLineModule } from '../common/components/widget-chart-line/widget-chart-line.module';
import { WidgetChartPieModule } from '../common/components/widget-chart-pie/widget-chart-pie.module';
import { WidgetSwotModule } from '../common/components/widget-swot/widget-swot.module';
import { WidgetBigModule } from '../common/components/widget-big/widget-big.module';
import { SentimentGaugeModule } from '../common/components/sentiment-gauge/sentiment-gauge.module';
import { WidgetKpiModule } from '../common/components/widget-kpi/widget-kpi.module';
import { GlobalFiltersModule } from '../common/components/global-filters/global-filters.module';

import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OrloComponentsModule,
    TooltipModule,
    CustomWidgetFieldModule,
    WidgetTableModule,
    WidgetChartBarModule,
    WidgetChartLineModule,
    WidgetChartPieModule,
    WidgetSwotModule,
    WidgetBigModule,
    SentimentGaugeModule,
    WidgetKpiModule,
    GlobalFiltersModule
  ],
  declarations: [AddWidgetComponent, CustomWidgetComponent],
  entryComponents: [AddWidgetComponent, CustomWidgetComponent],
  providers: []
})
export class WidgetsModule {}
