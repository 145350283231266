import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AuthPopupComponent } from './auth-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    AlertsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.authPopup',
          url: '/settings/auth-popup?id',
          component: AuthPopupComponent,
          params: {
            id: {
              dynamic: true
            }
          }
        }
      ]
    })
  ],
  declarations: [AuthPopupComponent]
})
export class AuthPopupModule {}
