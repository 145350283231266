import './social-post.component.scss';
import { Component, OnInit, Input, Inject, Injector } from '@angular/core';
import { API } from '@ui-resources-angular';

@Component({
  selector: 'ssi-social-post',
  templateUrl: './social-post.component.html'
})
export class SocialPostComponent implements OnInit {
  @Input() post;
  @Input() author;
  showRetweetDropdown = false;

  constructor(private api: API, private injector: Injector) {}

  ngOnInit() {}

  async socialAction(action: string) {
    this.showRetweetDropdown = false;
    return await this.api
      .post('social/' + action, {
        account_id: this.author.account.id,
        social_id: this.post.id
      })
      .then(({ data }) => {
        if (data.success) {
          if (action === 'like') {
            this.post.have_liked = true;
            this.post.like_count = this.post.like_count + 1;
          } else if (action === 'share') {
            this.post.have_shared = true;
            this.post.share_count = this.post.share_count + 1;
          }
        }
        return data;
      });
  }

  enlargeImage() {
    const modal = this.injector.get('$injector').get('$uibModal');
    modal.open({
      size: 'lg',
      component: 'ssiEnlargeImageModal',
      resolve: {
        imageSrc: () => this.post.image_url
      }
    });
  }
}
