import './account-summary.component.scss';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-account-summary',
  templateUrl: './account-summary.component.html',
  styles: []
})
export class AccountSummaryComponent {
  @Input() socialAccounts;
  @Input() stats;

  constructor() {}
}
