<ng-template #weekViewTemplate let-isModalViewOverride="isModalViewOverride">
  <div
    class="post-box-container"
    [ngClass]="{
      'failed-post': post.last_attempt_at && !post.social_id,
      'deleted-post': post.deleted_at,
      'day-view-mobile': view === 'dayMobile',
      'post-box-container-modal-inner': isModalViewOverride || isModalView
    }"
    (click)="onPostClicked()"
  >
    <div class="post-box-scroll"></div>
    <div class="top-info">{{(post.sent_at || post.send_at) | date:'HH:mm'}}</div>
    <div class="content">
      <div class="text-content">
        <div class="network-name">
          <ssi-social-network-icon
            [accountTypeId]="post.account?.account_type_id"
            [size]="18"
          ></ssi-social-network-icon>
          <h5>{{post.account?.name}}</h5>
        </div>
        <div class="text">
          {{post.text}}
        </div>
      </div>
      <div
        class="media-preview"
        *ngIf="!(isModalViewOverride || isModalView || isMobile) && post.outbox_files.length > 0"
      >
        <ng-container *ngIf="post.outbox_files.length > 0 && post.outbox_files[0].mimetype.indexOf('image') > -1">
          <img
            [src]="post.outbox_files[0].public_url"
            ssiFallbackImage="brokenImageNew"
          >
          <span class="opn-gallery-badge" *ngIf="post.outbox_files.length > 1">
            {{post.outbox_files.length - 1}}+
          </span>
        </ng-container>
        <ng-container *ngIf="post.outbox_files.length > 0 && post.outbox_files[0].mimetype.indexOf('video') > -1">
          <div class="post-video">
            <i class="ssi ssi-social-video-source"></i>
          </div>
        </ng-container>
      </div>
      <div
        *ngIf="(isModalViewOverride || isModalView || isMobile) && post.outbox_files && post.outbox_files.length"
        class="post-box-gallery-prev"
        [ngClass]="{'post-box-gallery-prev-multiple': post.outbox_files.length > 1}"
      >
        <img
          [src]="post.outbox_files[0].public_url"
          ssiFallbackImage="brokenImage"
        >
        <ng-container *ngIf="post.outbox_files.length > 1">
          <img
            [src]="post.outbox_files[1].public_url"
            ssiFallbackImage="brokenImage"
          >
          <span class="post-box-gallery-badge-prev">
            {{post.outbox_files.length - 1}}+
          </span>
        </ng-container>
      </div>
    </div>
    <div class="bottom-actions">
      <div #buttonActions class="bottom-actions-wrap">
        <div
          #buttonActionsLabels
          [ngClass]="{'bottom-actions-wrap-labels-show': buttonActionsIcons === false}"
          class="bottom-actions-wrap-labels"
        >
          <ng-container *ngFor="let postStatus of postStatuses">
            <button
              *ngIf="!postStatus.link && postStatus.conditional"
              [class]="postStatus.class"
            >{{postStatus.label}}</button>
            <a
              *ngIf="postStatus.link && postStatus.conditional"
              [class]="postStatus.class"
              [uiSref]="postStatus.link"
              [uiParams]="postStatus.linkParams"
              (click)="$event.stopPropagation()"
            >{{postStatus.label}}</a>
          </ng-container>
        </div>
        <div
          [ngClass]="{'bottom-actions-wrap-icons-show': buttonActionsIcons === true}"
          class="bottom-actions-wrap-icons"
        >
          <ng-container *ngFor="let postStatus of postStatuses">
            <button
              *ngIf="!postStatus.link && postStatus.conditional"
              [class]="postStatus.class"
              [ssiTooltip]="postStatus.label"
              [tooltipAppendToBody]="true"
              tooltipWindowClass="tooltip-chunky"
              tooltipPlacement="top"
            ><i [class]="postStatus.icon"></i></button>
            <a
              *ngIf="postStatus.link && postStatus.conditional"
              [class]="postStatus.class"
              [uiSref]="postStatus.link"
              [uiParams]="postStatus.linkParams"
              [ssiTooltip]="postStatus.label"
              [tooltipAppendToBody]="true"
              tooltipWindowClass="tooltip-chunky"
              tooltipPlacement="top"
              (click)="$event.stopPropagation()"
            ><i [class]="postStatus.icon"></i></a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="view === 'week' || (view === 'day' && isMobile)">
  <ng-container *ngTemplateOutlet="weekViewTemplate"></ng-container>
</div>

<ng-container *ngIf="view === 'day' && !isMobile">
  <div
    class="day-view"
    [class.live-post]="post.social_id && !post.deleted_at && post.is_validated && !post.is_draft && (post.last_attempt_at && !post.social_id)"
    [class.approval-post]="!post.is_validated && !post.social_id"
    [class.scheduled-post]="post.send_at && !post.social_id"
    [class.failed-post]="!post.social_id && post.is_validated && !post.send_at"
    [class.deleted-post]="post.deleted_at"
    [class.thread-post]="post.is_thread_parent"
    [class.draft-post]="post.is_draft"
    cdkOverlayOrigin
    #viewPostBoxTriggerOrigin="cdkOverlayOrigin"
    (click)="onPostClicked()"
  >
    <div class="post-box-scroll"></div>
    <div
      class="content"
      (mouseover)="!isMobile && showPostBoxPreview = true"
      (mouseleave)="showPostBoxPreview = false"
    >
      <ssi-social-network-icon
        [accountTypeId]="post.account?.account_type_id"
        [size]="26"
      ></ssi-social-network-icon>
      <div class="time-info">{{(post.sent_at || post.send_at) | date:'HH:mm'}}</div>
      <div class="text">
        {{post.text}}
      </div>
    </div>

    <ng-template
      #actionsMenu="cdkConnectedOverlay"
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="viewPostBoxTriggerOrigin"
      [cdkConnectedOverlayOpen]="showPostBoxPreview"
      [cdkConnectedOverlayFlexibleDimensions]="false"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="false"
      [cdkConnectedOverlayPush]="true"
      (backdropClick)="showPostBoxPreview = false"
    >
      <div class="post-box-preview-window">
        <ng-container
          [ngTemplateOutlet]="weekViewTemplate"
          [ngTemplateOutletContext]="{ isModalViewOverride: true }"
        ></ng-container>
      </div>
    </ng-template>

  </div>
</ng-container>

<div
  class="month-view"
  *ngIf="view === 'month'"
>
  <div
    class="content"
    [class.failed-post]="post.last_attempt_at && !post.social_id"
  >
    <ssi-social-network-icon
      [accountTypeId]="post.account?.account_type_id"
      [size]="20"
    ></ssi-social-network-icon>
    <div class="post-counter">01</div>
  </div>
</div>