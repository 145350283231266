<div class="insights-np">
	<div class="insights-np-header">
		<h1>Insights</h1>
	</div>

	<div class="insights-np-body">
		<div class="insights-np-card insights-np-card-main">
			<div class="insights-np-left">
				<h2>Turn insight into action with Orlo Insights</h2>
				<p>Combine the power of Social Listening Media Monitoring and AI to really understand the voice of your community</p>
				<a href="mailto:support@orlo.tech">
					<i class="ssi ssi-csm-chat"></i>
					<span>Contact us now</span>
				</a>
			</div>
			<div class="insights-np-right">
				<img src="https://www.orlo.app/app/web/production/34348f192d3ac8c6e1aad089f9775a12.png">
			</div>
		</div>

		<div class="insights-np-body-grid">
			<div class="insights-np-card">
				<i class="insights-np-card-icon ssi ssi-gauge-chart"></i>
				<h2>Trust Indicator</h2>
				<p>Orlo's Trust Indicator allows you to research and measure the driving factors of trust and distrust amongst your community and actively manage your organisation's reputation in the community.</p>
			</div>

			<div class="insights-np-card">
				<i class="insights-np-card-icon ssi ssi-theme-alerts"></i>
				<h2>AI Alerts</h2>
				<p>Automatically get notified of new emerging themes that could potentially become a crisis and set up alerts for particular themes that are of interest to your organisation. Go beyond keywords and look at semantically similar themes.</p>
			</div>

			<div class="insights-np-card">
				<i class="insights-np-card-icon ssi ssi-community-intelligence"></i>
				<h2>Community Intelligence</h2>
				<p>Get a breakdown of your engaged audience's demographics to provide insight into your most engaged interest groups, industries and top influencers. Discover the who, what and why behind your community's conversations.</p>
			</div>

			<div class="insights-np-card">
				<i class="insights-np-card-icon ssi ssi-insights-reports"></i>
				<h2>But that's not all…</h2>
				<p>Inbox Analysis, Theme Clustering, Emotion & Sentiment Analysis, Anomaly Detection. Want to know more about how Orlo Insights could help your organisation? Get in touch with your Customer Success Manager today!</p>
			</div>
		</div>
	</div>
</div>