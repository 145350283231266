import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {
  Campaign,
  CampaignsService
} from '../../../../../common/services/api/campaigns';

@Injectable()
export class CampaignModelService {
  public $activeCampaign = new Subject();

  constructor(private campaignsService: CampaignsService) {}

  set currentActiveCampaign(campaignId: number) {
    try {
      if (campaignId) {
        this.campaignsService.getAll().then((campaigns: Campaign[]) => {
          if (!(!!campaigns && Array.isArray(campaigns))) {
            throw new Error(
              `Value for 'campaign model services' not in expected format.`
            );
          }

          const activeCampaign = campaigns.find(
            (campaign) => campaign.id === campaignId.toString()
          );
          this.$activeCampaign.next(activeCampaign);
        });
      } else {
        this.$activeCampaign.next(null);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public filterOutChildCampaigns(campaigns, returnCompleted) {
    return campaigns.filter(
      (campaign) =>
        campaign.is_closed === returnCompleted && !campaign.parent_id
    );
  }

  public filterInChildCampaigns(campaigns, returnCompleted, activeCampaignId) {
    return campaigns.filter(
      (campaign) =>
        returnCompleted === campaign.is_closed &&
        campaign.parent_id === activeCampaignId
    );
  }

  public addCampaign(campaign) {
    const request = { ...campaign };
    if (request.parent_id && request.parent_id === 'null') {
      delete request.parent_id;
    }
    this.post(request);
  }

  public editCampaign(campaign, id) {
    const request = { ...campaign, id };
    this.post(request);
  }

  public deleteCampaign(campaign) {
    const request = { ...campaign };
    request['closed_at'] = new Date();
    this.post(request);
  }

  private post(request) {
    this.campaignsService.createOrUpdate(request);
  }
}
