import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { ReportsComponent } from './reports.component';

import { HomeModule } from './home/home.module';
import { CreateModule } from './create/create.module';
import { EditModule } from './edit/edit.module';
import { ViewReportModule } from './view/view-report.module';
import { CommonInsightsModule } from '../common/common-insights.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights.reports',
          url: '/reports',
          abstract: true, // will auto redirect to 'auth.insights.reports.home' child state
          component: ReportsComponent
        }
      ]
    }),
    HomeModule,
    CreateModule,
    EditModule,
    ViewReportModule,
    CommonInsightsModule
  ],
  exports: [],
  declarations: [ReportsComponent]
})
export class ReportsModule {}
