import { Component, Input, OnInit } from '@angular/core';
import { Account, Outbox, User } from '@ui-resources-angular';
import { Transition, StateService } from '@uirouter/angular';
import { trackByProperty } from '../../../../../common/util';
import {
  advertisingTargetingPresets,
  authUser
} from '../../../../common-resolves';
import {
  AdvertisingPreset,
  TargetingOption
} from '../advertising-preset-model/advertising-preset-model.service';
import {
  Advert,
  AdvertModelService
} from '../advert-model/advert-model.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import {
  CompanyConfig,
  CompanyService
} from '../../../../../common/services/company/company.service';
import {
  AdvertisingTargetingService,
  BillingEvent,
  BudgetSuggestion
} from '../advertising-targeting/advertising-targeting.service';

export function activeAdFn(
  transition: Transition,
  advertModel: AdvertModelService
) {
  const params = transition.params();
  const advert = advertModel.findAll(
    { id: params.advertId },
    { bypassCache: true }
  );
  return advert;
}

interface AdvertLanguage {
  label: string;
  locale: string;
  country: string;
  isDefault?: boolean;
}

const DEFAULT_AD_SPEND = '0.00';

@Component({
  selector: 'ssi-advertising-advert-edit',
  templateUrl: './advertising-advert-edit.component.html'
})
export class AdvertisingAdvertEditComponent implements OnInit {
  static resolve = [
    authUser,
    advertisingTargetingPresets,
    {
      token: 'activeAdvert',
      resolveFn: activeAdFn,
      deps: [Transition, AdvertModelService]
    }
  ];

  @Input() activeAdvert: any;

  @Input() advertisingTargetingPresets: AdvertisingPreset[];

  @Input() authUser: User;

  accountPresets: AdvertisingPreset[] = [];

  trackById = trackByProperty('id');

  now = new Date();

  loadingTracker = this.asyncTracker.create();

  canCreateAdUnvalidated: boolean;

  advertLanguages: AdvertLanguage[] = [
    { label: 'Bahasa Indonesia', locale: 'in', country: 'ID' },
    { label: 'Čeština', locale: 'cs', country: 'CZ' },
    { label: 'Dansk', locale: 'da', country: 'DK' },
    { label: 'Nederlands', locale: 'nl', country: 'NL' },
    { label: 'English', locale: 'en', country: 'US', isDefault: true },
    { label: 'Français', locale: 'fr', country: 'FR' },
    { label: 'Deutsch', locale: 'de', country: 'DE' },
    { label: 'Italiano', locale: 'it', country: 'IT' },
    { label: '日本語', locale: 'ja', country: 'JP' },
    { label: '한국어', locale: 'ko', country: 'KR' },
    { label: 'Bahasa Malaysia', locale: 'ms', country: 'MY' },
    { label: 'Norsk', locale: 'no', country: 'NO' },
    { label: 'Polski', locale: 'pl', country: 'PL' },
    { label: 'Português', locale: 'pt', country: 'BR' },
    { label: 'Română', locale: 'ro', country: 'RO' },
    { label: 'Русский', locale: 'ru', country: 'RU' },
    { label: 'Español', locale: 'es', country: 'ES' },
    { label: 'Svenska', locale: 'sv', country: 'SE' },
    { label: 'Türkçe', locale: 'tr', country: 'TR' }
  ];

  currencySymbols = {
    USD: '$',
    EUR: '€',
    CRC: '₡',
    GBP: '£',
    ILS: '₪',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    NGN: '₦',
    PHP: '₱',
    PLN: 'zł',
    PYG: '₲',
    THB: '฿',
    UAH: '₴',
    VND: '₫'
  };

  budgetSuggestion: BudgetSuggestion;

  constructor(
    private targeting: AdvertisingTargetingService,
    private asyncTracker: AsyncTrackerFactory
  ) {}

  async ngOnInit() {
    this.getBudgetSuggestion();
  }

  getLanguage(locale) {
    const language = this.advertLanguages.filter(
      (lang) => lang.locale === locale
    );
    const label = language[0].label;
    return label;
  }

  async getBudgetSuggestion() {
    this.budgetSuggestion = null;
    if (
      this.activeAdvert &&
      this.activeAdvert.account &&
      this.activeAdvert.account.socialNetwork.advertising.features
        .budgetSuggestion &&
      this.activeAdvert.targeting_options.length > 0
    ) {
      this.budgetSuggestion = await this.targeting.getBudgetSuggestion(
        this.activeAdvert.account,
        this.activeAdvert.targeting_options,
        this.activeAdvert.bidding.bidding_event,
        this.activeAdvert.account.ad_currency
      );
      if (
        this.activeAdvert.budget.daily_budget &&
        this.activeAdvert.budget.daily_budget.amount === DEFAULT_AD_SPEND
      ) {
        this.activeAdvert.budget.daily_budget.amount = this.budgetSuggestion.dailyBudget.default.amount;
      }
      if (this.activeAdvert.bidding.amount === DEFAULT_AD_SPEND) {
        this.activeAdvert.bidding.amount = this.budgetSuggestion.suggestedBid.default.amount;
      }
    }
  }
}
