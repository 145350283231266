import './dropdown-menu.component.scss';
import { Component, Input } from '@angular/core';

export interface DropdownMenuItem {
  iconClass: string;
  label: string;
  readonly action: () => void;
}

@Component({
  selector: 'ssi-dropdown-menu',
  templateUrl: './dropdown-menu.component.html'
})
export class DropdownMenuComponent {
  @Input() menuList: DropdownMenuItem[];

  constructor() {}
}
