<div class="outbox-post-container">
  <div class="left">
    <img class="account-image"
         [src]="post.account.picture"
         ssiFallbackImage="brokenImage">
  </div>

  <div class="view-post-link">
    <a [href]="post.externalLink"
       target="_blank">View post</a>
  </div>

  <div class="middle">
    <div class="metadata">
      <a [href]="post.externalLink"
         target="_blank">{{post.account.username}}</a>
      <div class="icon-container">
        <ssi-social-network-icon [accountTypeId]="post.account.account_type_id"
                                 variant="fa"></ssi-social-network-icon>
      </div>
      <div class="timestamp">
        <span>{{createdAtDate}}</span> &nbsp;&nbsp;|&nbsp;&nbsp; <span>{{createdAtTime}}</span>
      </div>
    </div>

    <div class="content">
      <p *ngIf="post.text"
         [innerHTML]="post.text | linkify: {mentions: mentions}"></p>
    </div>
  </div>

  <div class="right">
    <div class="gallery"
         *ngIf="post.outbox_files.length">
      <ng-container *ngFor="let file of post.outbox_files | slice:0:2 | reverse; let i=index">
        <ng-container *ngTemplateOutlet="fileItemTemplate; context: {fileItem: file, fileItemIndex: i }"></ng-container>
      </ng-container>

      <div class="media-items-count"
           *ngIf="post.outbox_files.length > 1">
        <span>{{post.outbox_files.length - 1}}+</span>
      </div>
    </div>

    <ng-template #fileItemTemplate
                 let-fileItem="fileItem"
                 let-fileItemIndex="fileItemIndex">
      <img *ngIf="fileItem.mimetype.indexOf('image') > -1"
           [src]="fileItem.public_url"
           [class.stacked]="fileItemIndex > 0"
           [style.top]="(fileItemIndex * 7) + 'px'"
           [style.left]="(fileItemIndex * 7) + 'px'">

      <div *ngIf="fileItem.mimetype.indexOf('image') === -1"
           class="video-icon-container"
           [class.stacked]="fileItemIndex > 0"
           [style.top]="(fileItemIndex * 7) + 'px'"
           [style.left]="(fileItemIndex * 7) + 'px'">
        <i class="ssi ssi-video-circle"></i>
      </div>
    </ng-template>
  </div>

  <ssi-outbox-post-menu [post]="post"></ssi-outbox-post-menu>
</div>