import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { WidgetPositiveNegativeThemesComponent } from './widget-positive-negative-themes.component';
import { TreetableModule } from '../../../../../../common/components/treetable';
import { BigNumberModule } from '../../../../../../common/pipes/big-number/big-number.module';
import { BigNumberModule as BigNumberComponentModule } from '../../../../../../common/components/big-number/big-number.module';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { ObserveWidthAndHeightModule } from '../../../../../../common/directives/observe-width/observe-width-and-height.module';
import { PaginatorModule } from '../../../../../../common/components/paginator';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TreetableModule,
    BigNumberModule,
    BigNumberComponentModule,
    TooltipModule,
    ObserveWidthAndHeightModule,
    PaginatorModule
  ],
  exports: [WidgetPositiveNegativeThemesComponent],
  declarations: [WidgetPositiveNegativeThemesComponent]
})
export class WidgetPositiveNegativeThemesModule {}
