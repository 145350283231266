import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import { KeyValueObject } from '../../util';

const preferencesPromiseCache = Symbol('preferencesPromiseCache');

export interface UserPreferences {
  dashboards?: string; // string array -> "[{\"name\":\"My dashboard\",\"structure\":{\"id\":\"50-50\"}]"
  account_summary_email?: string; // "0"
  account_summary_email_time?: string; // "10"
  inbox_custom_preset_conversation_filters?: string; // string array
  inbox_custom_preset_filters?: string; // string array
  inbox_message_read_more?: string; // "true"
  inbox_signature?: string; // 'Sig 2'
  inbox_v4_landing_read_at?: string;
  last_product_article_read_at?: string;
  last_service_alert_article_read_at?: string;
  outbox_passed_validation_email?: string; // "false"
  timezone?: string; // "Europe/London"
  audio_chat_prompt?: string; // "true"
  notification_chat_prompt?: string; // "false"
}
@Injectable()
export class UserPreferencesService {
  preferencesCache: UserPreferences = {};

  constructor(private api: API) {}

  getPreferences(): Promise<UserPreferences> {
    if (!this[preferencesPromiseCache]) {
      this[preferencesPromiseCache] = this.api
        .get<{ data: { user_preferences: KeyValueObject<string> } }>(
          'settings/userPreference'
        )
        .then(({ data }) => {
          Object.entries(data.user_preferences).forEach(([key, value]) => {
            try {
              value = JSON.parse(value); // hacky way of telling if json or not
            } catch (e) {} // tslint:disable-line
            this.preferencesCache[key] = value;
          });
          return this.preferencesCache;
        });
    }

    return this[preferencesPromiseCache];
  }

  savePreferences(newPreferences) {
    const preferencesClone = Object.assign({}, newPreferences);
    for (const key in preferencesClone) {
      if (typeof preferencesClone[key] === 'object') {
        preferencesClone[key] = JSON.stringify(preferencesClone[key]);
      }
    }
    return this.api
      .post('settings/userPreference', { user_preferences: preferencesClone })
      .then(() => {
        return Object.assign(this.preferencesCache, newPreferences);
      });
  }

  clearCache() {
    delete this[preferencesPromiseCache];
    this.preferencesCache = {};
  }
}
