<div class="modal-body text-center">
  <i class="ssi ssi-rocket ssi-5x text-primary"></i>
  <h1 translate="LOADING"></h1>
  <p>
    <em translate="IT_WILL_JUST_BE_A_MOMENT_AS_WE_UPDATE_YOUR_BULK_SELECTION"></em>
  </p>
  <br>
  <ngb-progressbar
    type="primary"
    [value]="progress.completed"
    [max]="progress.total"
  ></ngb-progressbar>
</div>