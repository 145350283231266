import './dashboard-navbar.component.scss';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { Dashboard } from '../../../../../../common/services/api/dashboard';

@Component({
  selector: 'ssi-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styles: []
})
export class DashboardNavbarComponent implements OnInit {
  selectedDashboard: Dashboard;

  @Input() dashboardName = '';
  @Input() dashboardID: string;
  @Input() mode: 'view' | 'create' | 'edit' = 'view';
  @Input() dashboards = [];

  @Output() widgetAdd = new EventEmitter<void>();
  @Output() dashboardNameChange = new EventEmitter<string>();
  @Output() dashboardDelete = new EventEmitter<void>();
  @Output() dashboardChange = new EventEmitter<string>();

  constructor(private state: StateService) {}

  ngOnInit() {
    this.dashboardName =
      (this.state.params && this.state.params.reportName) || this.dashboardName;
    this.onDashboardNameChange(); // emit it on init since it's undefined except the user change it

    this.selectedDashboard = this.dashboards.find(
      (d) => d.id === this.state.params.id
    ); // needs setting up with local storage
  }

  onDashboardNameChange() {
    this.dashboardNameChange.emit(this.dashboardName);
  }

  onDashboardChange(dashboard) {
    this.dashboardChange.emit(dashboard.id);
  }

  refresh() {
    this.state.reload();
  }

  edit() {
    this.state.go('auth.dashboard.edit', {
      id: this.dashboardID
    });
  }

  create() {
    this.state.go('auth.dashboard.create');
  }
}
