<ng-template #unsupportedActionTooltipTemplate>
  <div class="tooltip-icon tooltip-icon-padding">
    <i class="ssi ssi-forbidden"></i>
  </div>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      Not yet...
    </div>
    <div class="tooltip-body-contents">
      The network you have chosen does not allow for this functionality
    </div>
  </div>
  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>

<ng-template #targetingUnsupportedTooltipTemplate>
  <div class="tooltip-icon tooltip-icon-padding">
    <i class="ssi ssi-skills"></i>
  </div>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      First things first...
    </div>
    <div class="tooltip-body-contents">
      Targeting is only available on Facebook and LinkedIn. Select at least one of these accounts to get started.
    </div>
  </div>
  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>
<ng-template #scheduleAccountsRequiredTooltipTemplate>
  <div class="tooltip-icon tooltip-icon-padding">
    <i class="ssi ssi-forbidden"></i>
  </div>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      First things first...
    </div>
    <div class="tooltip-body-contents">
      You need to select at least one account, write your post and add content before you can schedule your post.
    </div>
  </div>
  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>

<ng-template #contentApprovalEnabledTooltipTemplate>
  <div class="tooltip-icon tooltip-icon-padding">
    <i class="ssi ssi-check"></i>
  </div>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      Post Approval
    </div>
    <div class="tooltip-body-contents">
      Your post will be sent for approval, where a team member will review your post before it’s published.
    </div>
  </div>
  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>

<ng-template #contentApprovalDisabledTooltipTemplate>
  <div class="tooltip-icon tooltip-icon-padding">
    <i class="ssi ssi-check"></i>
  </div>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      Post Approval
    </div>
    <div class="tooltip-body-contents">
      Select this option to send your post for approval, where a team member will review your post before it’s
      published.
    </div>
  </div>
  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>
<div class="publisher-actions-wrapper">
  <div class="publisher-actions">
    <div class="publisher-close-compose">
      <span
        class="pro-tip"
        *ngIf="!!post.splitPostAccount && !!protipTooltips[post.splitPostAccount?.account_type_id]"
        tooltipTitle="Posting to {{protipTooltips[post.splitPostAccount?.account_type_id].accountName}}"
        [ssiTooltip]="protipTooltips[post.splitPostAccount?.account_type_id].description | translate"
        [tooltipAppendToBody]="true"
        tooltipPlacement="bottom"
        [tooltipOffset]="[0, 20]"
        [tooltipWindowClass]="'tooltip-chunky-big'"
      ><i class="ssi ssi-pro-tip"></i>PRO TIP</span>
      <button
        class="publisher-close-btn"
        (click)="publisherActive.next({isActive: false})"
      >
        <span
          translate="CANCEL_POST"
          *ngIf="!post.edit"
        ></span>
        <span
          translate="CANCEL_EDIT"
          *ngIf="post.edit"
        ></span>
      </button>
    </div>

    <div
      class="publisher-actions-row"
      [class.loading-cover-visible]="videoResizingInProgress"
    >

      <div
        class="loading-cover"
        *ngIf="videoResizingInProgress"
      >
        <i class="ssi ssi-3x ssi-loading ssi-spin"></i>
        <span translate="WE_ARE_RESIZING_YOUR_VIDEO"></span>
      </div>
      
      <button
        class="publisher-action publisher-action-preview"
        [class.active]="!disable?.previewPost && post.validity.isValid && !postPreviewUnsupported()"
        [class.half-width]="post.hasInstagramSelected()"
        [disabled]="!!disable?.previewPost || !post.validity.isValid || postPreviewUnsupported()"
        (click)="!disable?.previewPost && showPostPreview.emit()"
      >
        <div
          class="publisher-action-inner"
          [ssiTooltip]="!hasMedia || !post.validity.isValid ? null : 'Preview message'"
          [tooltipAppendToBody]="true"
          [tooltipPlacement]="'bottom'"
          [tooltipTemplate]="!post.validity.isValid ? postInvalidTooltipTemplate : null"
        >
          <div>
            <i class="ssi ssi-eye"></i>
            <div class="publisher-action-title">Preview Post</div>
          </div>
        </div>
      </button>
      <button
        *ngIf="!post.hasInstagramSelected()"
        class="publisher-action publisher-action-targeting"
        [disabled]="!!disable?.changeTargeting || !post.features.targeting"
        (click)="!disable?.changeTargeting && changeStage.emit({stage: stages.parent.Targeting, root: false})"
      >
        <div
          class="publisher-action-inner"
          [ssiTooltip]="!hasMedia ? null : 'Targeting'"
          [tooltipAppendToBody]="true"
          [tooltipPlacement]="actionTooltipPlacement"
          [tooltipTemplate]="!hasMedia ? (post.accounts.length === 0 ? targetingUnsupportedTooltipTemplate : unsupportedActionTooltipTemplate) : null"
          [tooltipDisabled]="post.features.targeting && !hasMedia"
        >
          <div>
            <i class="ssi ssi-targeting"></i>
            <div class="publisher-action-title">Targeting</div>
          </div>
        </div>
      </button>

      <button
        class="publisher-action publisher-action-content-approval"
        [class.active]="post.requiresValidation"
        [class.half-width]="post.hasInstagramSelected()"
        [disabled]="!!disable?.requestingValidation"
        (click)="!disable?.requestingValidation && post.requiresValidation = !post.requiresValidation"
      >
        <div
          class="publisher-action-inner"
          [ssiTooltip]="!hasMedia ? null : (post.requiresValidation ? 'Content approval requested' : 'Content approval disabled')"
          [tooltipAppendToBody]="true"
          [tooltipTemplate]="!hasMedia ? (post.requiresValidation ? contentApprovalEnabledTooltipTemplate : contentApprovalDisabledTooltipTemplate) : null"
          [tooltipPlacement]="actionTooltipPlacement"
        >
          <div>
            <i class="ssi ssi-request-validation"></i>
            <div class="publisher-action-title">Request Approval</div>
          </div>
        </div>
      </button>
      <button
        class="publisher-action publisher-action-schedule"
        [ssiTooltip]="'Edit ' + post.schedules.length + ' schedules'"
        [tooltipWindowClass]="'tooltip-chunky'"
        [tooltipDisabled]="post.schedules.length === 0"
        [tooltipPlacement]="'bottom'"
        [class.active]="post.schedules.length > 0"
        [disabled]="!!disable?.scheduling || post.accounts.length === 0"
        (click)="!disable?.scheduling && changeStage.emit({stage: stages.root.Schedule, root: true})"
      >
        <div
          class="publisher-action-inner"
          [ssiTooltip]
          [tooltipAppendToBody]="true"
          [tooltipPlacement]="actionTooltipPlacement"
          [tooltipTemplate]="post.accounts.length === 0 ? scheduleAccountsRequiredTooltipTemplate : null"
        >
          <div>
            <i class="ssi ssi-experience"></i>
            <div class="publisher-action-title">{{post.schedules.length > 0 ? 'Edit schedules' : 'Schedule post'}}</div>
          </div>
        </div>
      </button>
      <button
        class="publisher-action publisher-action-draft"
        [ssiTooltip]="'Save as draft'"
        [tooltipWindowClass]="'tooltip-chunky'"
        [tooltipDisabled]="post.accounts.length === 0"
        [tooltipPlacement]="'bottom'"
        [class.active]="post.validity.isValid"
        [disabled]="!post.validity.isValid"
        (click)="saveAsDraft.emit()"
      >
        <div
          class="publisher-action-inner"
          [ssiTooltip]
          [tooltipAppendToBody]="true"
          [tooltipPlacement]="actionTooltipPlacement"
          [tooltipTemplate]="!post.validity.isValid ? postInvalidTooltipTemplate : null"
        >
          <div>
            <ssi-icon icon="save" width="28" height="28" color="{{post.validity.isValid ? '#fff' : '#b4bbce'}}"></ssi-icon>
            <div class="publisher-action-title">Save as Draft</div>
          </div>
        </div>
      </button>
      <button
        class="publisher-action publisher-action-publish"
        [class.active]="post.validity.isValid && !disablePublish"
        [disabled]="!post.validity.isValid || publishingLoadingTracker.active || disablePublish"
        ssiTooltip="Uploading media. Publish will become active when media has uploaded"
        tooltipWindowClass="tooltip-chunky"
        tooltipPlacement="bottom"
        [tooltipAppendToBody]="true"
        [tooltipDisabled]="!post.validity.isValid || !disablePublish" 
        (click)="publish.emit()"
      >
        <div
          class="publisher-action-inner"
          [ssiTooltip]="!hasMedia || !post.validity.isValid ? null : (post.schedules.length === 0 ? 'Publish now' : (post.edit ? 'Update ' : 'Publish ') + post.schedules.length + ' schedules')"
          [tooltipAppendToBody]="true"
          [tooltipPlacement]="'bottom'"
          [tooltipTemplate]="!post.validity.isValid ? postInvalidTooltipTemplate : null"
        >
          <div>
            <i
              class="ssi ssi-send-icon"
              *ngIf="!publishingLoadingTracker.active"
            ></i>
            <i
              class="fa fa-spinner fa-spin"
              *ngIf="publishingLoadingTracker.active"
            ></i>
            <div class="publisher-action-title">
              <span *ngIf="post.schedules.length === 0">
                <span>
                  Publish now
                </span>
              </span>
              <span *ngIf="post.schedules.length > 0">
                <span *ngIf="!post.edit">{{ 'Schedule ' + post.schedules.length + ' posts' }}</span>
                <span *ngIf="post.edit">{{ 'Update ' + post.schedules.length + ' schedules' }}</span>
              </span>
            </div>
          </div>
        </div>
      </button>
    </div>
  </div>

  <div
    class="publisher-media"
    *ngIf="hasMedia"
  >
    <div class="d-flex publisher-media-actions">
      <span class="ig-specific-media-label"
            *ngIf="activeMediaItem.mediaCategory === MediaCategory.Story">Instagram Story</span>
      <span class="ig-specific-media-label"
            *ngIf="activeMediaItem.mediaCategory === MediaCategory.Reel">Instagram Reel</span>
      <ng-container *ngIf="activeMediaItem.mediaCategory !== MediaCategory.Story && activeMediaItem.mediaCategory !== MediaCategory.Reel && post.instagramAccountsOnly()">
        <span class="ig-specific-media-label" *ngIf="post.files.length === 1">Instagram Post</span>
        <span class="ig-specific-media-label" *ngIf="post.files.length > 1">Carousel Post</span>
      </ng-container>

      <ng-container *ngIf="!disable?.editMedia">
        <ng-container *ngIf="activeMediaItem.type === OutboxFileType.Image || activeMediaItem.type === OutboxFileType.Gif">
          <a
            class="edit-image"
            *ngIf="activeMediaItem.type === OutboxFileType.Image"
            href="javascript:;"
            (click)="imageLoadingTracker.add(editImage(activeMediaItem))"
          >
            <i class="ssi ssi-edit-image-v2"></i> Edit image
          </a>
          <a
            href="javascript:;"
            *ngIf="canAddAltText(this.post.accounts)"
            [ssiTooltip]="'ALT_TEXT_DESCRIPTION_NETWORK_SPECIFIC' | translate"
            [tooltipWindowClass]="'dark rounded'"
            [tooltipAppendToBody]="true"
            [tooltipPlacement]="actionTooltipPlacement"
            [class.alt-text]="activeMediaItem?.alt_text"
            (click)="addAltText(activeMediaItem)"
          >
            <i class="ssi ssi-alt-text"></i>
            {{activeMediaItem?.alt_text ? 'Edit Alt Text' : 'Alt text' }}
          </a>
        </ng-container>
        <ng-container *ngIf="activeMediaItem.type === OutboxFileType.Video">
          <a
            class="add-subtitles"
            *ngIf="post.splitPostAccount?.socialNetwork.publish.features.videoCaptions"
            href="javascript:;"
            [ssiTooltip]="'Adding captions ensure your videos are accesible for users with hearing problems.'"
            [tooltipAppendToBody]="true"
            [tooltipPlacement]="actionTooltipPlacement"
            [tooltipWindowClass]="'dark rounded'"
            [class.alt-text]="activeMediaItem.subtitles"
            (click)="addSubtitles(activeMediaItem)"
          >
            <i
              class="ssi"
              [ngClass]="!!activeMediaItem.subtitles ? 'ssi-correctedit' : 'ssi-subtitles'"
            ></i>
            {{!!activeMediaItem.subtitles ? 'Change Captions' : 'Add Captions'}}
          </a>

          <ssi-checkbox-2 *ngIf="activeMediaItem.mediaCategory === MediaCategory.Reel" 
                          [label]="'SHARE TO FEED'"
                          [variant]="'square'"
                          [(ngModel)]="post.igShareToFeed"></ssi-checkbox-2>
        </ng-container>
        <a class="remove-icon-container"
          href="javascript:;"
          (click)="removeMediaItem(activeMediaItem)"
        >
          <i class="ssi ssi-delete remove-image"><span>Remove {{activeMediaItem.type === OutboxFileType.Video ? 'video' : 'image'}}</span></i>
        </a>
      </ng-container>
    </div>
    <ssi-media-nav
      *ngIf="!imageLoadingTracker.active"
      [files]="post.files"
      [useBackgroundImage]="true"
      [(activeFileIndex)]="activeMediaItemIndex"
      [activeFileIsVideo]="activeMediaItem.type === OutboxFileType.Video"
      (activeFileIndexChange)="onActiveMediaItemIndexChange($event)"
    >
    </ssi-media-nav>
    <div
      class="loading flex-1"
      *ngIf="imageLoadingTracker.active"
    ></div>
  </div>
</div>