import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Directive, OnInit, forwardRef, Input } from '@angular/core';

@Directive({
  selector: '[ssiMin]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinDirective, multi: true }
  ]
})
export class MinDirective implements Validator, OnInit {
  @Input() ssiMin: number;

  constructor() {}

  ngOnInit() {}

  validate(c: FormControl) {
    const value = +c.value;

    const errorMessage = `Expected number equal to or greater than ${this.ssiMin}`;

    if (isNaN(value)) {
      return { min: true, message: errorMessage };
    }

    if (value < this.ssiMin) {
      return { min: true, message: errorMessage };
    }

    return null;
  }
}
