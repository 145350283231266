import { Injectable } from '@angular/core';
import { API, Account } from '@ui-resources-angular';
import { flatten } from 'lodash-es';
import { TargetingOption } from '../advertising-preset-model/advertising-preset-model.service';
import { LANGUAGES } from '../../../../../common/constants';

export enum AdvertisingType {
  Include = 'INCLUDE',
  Exclude = 'EXCLUDE',
  Remove = 'Remove'
}

export interface AdvertisingTargetingOption {
  id: string;
  name: string;
  type: AdvertisingType;
  supports_list: boolean;
  supports_query: boolean;
  data: any;
  displayName?: string;
  description?: string;
}

export interface AdvertisingTargetingOptionSuggestedValue {
  id: string;
  name: string;
}

export enum BillingEvent {
  CPC = 'CPC',
  CPM = 'CPM'
}

interface Money {
  amount: string;
  currency: string;
}

export interface BudgetSuggestion {
  bid: {
    max: Money;
    min: Money;
  };
  dailyBudget: {
    max: Money;
    min: Money;
    default: Money;
  };
  suggestedBid: {
    max: Money;
    min: Money;
    default: Money;
  };
}

export function serialiseTargetingOptions(targetingOptions: TargetingOption[]) {
  return targetingOptions.map(({ option, ...props }) => {
    return { ...props, id: option.id };
  });
}

const optionNameOverrides = {
  Titles: 'Current Job Title',
  'Titles Past': 'Past Job Titles',
  'Titles All': 'All Job Titles',
  Employers: 'Current Employers',
  'Employers Past': 'Past Employers',
  'Interface Locales': 'Languages'
};

// these are only used internally at linkedin and will be removed in the future
const hiddenOptions = [
  'Profile Locations / Country Group',
  'Profile Locations / Country',
  'Profile Locations / State',
  'Profile Locations / Region',
  'First Degree Connection Companies',
  'Contextual Companies',
  'Contextual Countries'
];

// source: https://www.linkedin.com/help/lms/topics/8169/8179/722
const optionDescriptions = {
  Genders:
    'Determination of whether a member is female or male is inferred based on member profile information.',
  Industries:
    'The primary industry of the company where the member is employed.',
  'Job Functions':
    'Based on standardized groupings of the job titles entered by LinkedIn members.',
  Seniorities:
    "Describes the rank and influence of a member's current role in their organization.",
  'Age Ranges':
    'An estimation of how old a member is based on their profile information.',
  Locations:
    'Allows you to reach members based on where they are living or visiting. Geographic location is based on the location specified by the member in their profile and/or their IP address location.',
  'Staff Count Ranges':
    "Allows you to reach members based on the size of the organization where they work. Company size is determined by the number of employees listed on the organization's Company Page.",
  'Current Employers':
    'The organization a member lists as his or her employer. These are based on LinkedIn Company Pages, which are maintained by company employees.',
  'Past Employers':
    'The organization a member lists as his or her employer. These are based on LinkedIn Company Pages, which are maintained by company employees.',
  'Employers All':
    'The organization a member lists as his or her employer. These are based on LinkedIn Company Pages, which are maintained by company employees.',
  Groups:
    'LinkedIn Groups where members who share interests or professional associations can gather, discover new information, and ask questions.',
  'Current Job Title':
    "When members update their LinkedIn profiles with new roles, these job titles are grouped by LinkedIn's algorithms and organized into standardized titles.",
  'Past Job Titles':
    "When members update their LinkedIn profiles with new roles, these job titles are grouped by LinkedIn's algorithms and organized into standardized titles.",
  'All Job Titles':
    "When members update their LinkedIn profiles with new roles, these job titles are grouped by LinkedIn's algorithms and organized into standardized titles.",
  Skills:
    "Highly relevant keywords found within a member's profile and skills section that indicate expertise in a particular area. Member-entered skills in the Skills & Endorsements section on their profile, skills mentioned in their profile text, or inferred skills based on their listed skills.",
  Schools:
    'The school, college, university, or other learning institution where a member completed a course.',
  'Interface Locales': 'The language the member uses the LinkedIn platform in',
  'Followed Companies':
    'Specific companies to match against those the member is following.',
  Degrees:
    'Recognized ranks granted by a college, university, or other learning institution.',
  'Fields Of Study':
    "The major or area of study within a member's degree. Standardized from member-entered degrees",
  'Years Of Experience Ranges':
    "Allows you to reach a target audience based on the years of professional experience they've accumulated over their career. We exclude any gaps in experience and also do not double-count overlapping positions."
};

@Injectable()
export class AdvertisingTargetingService {
  constructor(private api: API) {}

  async getOptions(account: Account) {
    const {
      data: { available_options }
    } = await this.api.get<{
      data: { available_options: AdvertisingTargetingOption[] };
    }>('advertising/advertisingAvailableOptions', {
      params: {
        account_id: account.id
      }
    });
    return available_options
      .filter((option) => !hiddenOptions.includes(option.name))
      .map(({ name, ...rest }) => {
        return {
          displayName: optionNameOverrides[name] || name,
          name,
          description: optionDescriptions[name],
          ...rest
        };
      });
  }

  async getSuggestedValues(
    account: Account,
    option: AdvertisingTargetingOption,
    { query }: { query?: string } = {}
  ) {
    const { data } = await this.api.get<{
      data: AdvertisingTargetingOptionSuggestedValue[];
    }>('advertising/advertisingSuggestedValues', {
      params: {
        account_id: account.id,
        option_id: option.id,
        q: query
      }
    });
    if (option.data.entityType === 'LOCALE') {
      return data.filter(({ id }) => !!LANGUAGES[id]).map(({ id, name }) => {
        return { id, name: LANGUAGES[id] || name };
      });
    }
    return data;
  }

  async getEstimatedAudienceSize(
    account: Account,
    targetingOptions: Map<
      AdvertisingTargetingOption,
      AdvertisingTargetingOptionSuggestedValue[]
    >
  ) {
    const optionsArray = flatten(
      Array.from(targetingOptions.entries()).map(([option, values]) =>
        values.map((value) => ({ id: option.id, value: value.id }))
      )
    );
    const {
      data: { size }
    } = await this.api.post<{ data: { size: number } }>(
      'advertising/advertisingEstimatedSize',
      {
        account_id: account.id,
        targeting_options: optionsArray
      }
    );
    return size;
  }

  async getBudgetSuggestion(
    account: Account,
    targetingOptions: TargetingOption[],
    billingEvent: BillingEvent,
    currency: string
  ) {
    const { data } = await this.api.post<{ data: BudgetSuggestion }>(
      'advertising/advertisingLinkedInBudgetSuggestion',
      {
        account_id: account.id,
        billing_event: billingEvent,
        targeting_options: serialiseTargetingOptions(targetingOptions),
        currency
      }
    );
    return data;
  }
}
