import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxCrmSidebarComponent } from './inbox-crm-sidebar.component';
import { IconModule } from '../../icon/icon.module';
import { BigNumberModule } from '../../../pipes/big-number/big-number.module';
import { CrmProfileSentimentBarModule } from '../../../components/profile-inbox-stats/crm-profile-sentiment-bar/crm-profile-sentiment-bar.module';
import { CrmPersonTagsModule } from '../../crm-person-tags/crm-person-tags.module';
import { FallbackImageModule } from '../../../directives/fallback-image/fallback-image.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterModule } from '../../../pipes/filter/filter.module';
import { Nl2brModule } from '../../../pipes/nl2br/nl2br.module';
import { CrmPersonNotesModule } from '../../crm-person-notes/crm-person-notes.module';
import { InboxCrmExternalIntegrationPersonModule } from './inbox-crm-external-integration-person/inbox-crm-external-integration-person.module';
@NgModule({
  imports: [
    CommonModule,
    IconModule,
    BigNumberModule,
    CrmProfileSentimentBarModule,
    CrmPersonTagsModule,
    FallbackImageModule,
    TranslateModule,
    NgbDropdownModule,
    NgbPopoverModule,
    FilterModule,
    Nl2brModule,
    CrmPersonNotesModule,
    InboxCrmExternalIntegrationPersonModule
  ],
  declarations: [InboxCrmSidebarComponent],
  exports: [InboxCrmSidebarComponent]
})
export class InboxCrmSidebarModule {}
