import './add-widget.component.scss';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../../../../../../common/services/company/company.service';
import { asyncFilter } from '../../../../../../common/utils/async-filter';

import {
  DashboardService,
  DashboardWidget
} from '../../../../../../common/services/api/dashboard';

@Component({
  selector: 'ssi-add-widget',
  templateUrl: './add-widget.component.html',
  styles: []
})
export class AddWidgetComponent implements OnInit {
  searchTerm = '';

  widgets: DashboardWidget[] = [];
  filteredWidgets: DashboardWidget[] = [];

  // widgetTypes: WidgetTypes = widgetTypes;
  // widgetTypesIterable: WidgetType[] = widgetTypesIterable;
  // filteredWidgetTypesIterable: WidgetType[] = widgetTypesIterable;

  @Output() widgetAdded = new EventEmitter<DashboardWidget>();

  constructor(
    public activeModal: NgbActiveModal,
    protected dashboardService: DashboardService,
    protected company: CompanyService
  ) {}

  async ngOnInit() {
    const widgets = this.dashboardService.getWalletWidgets();
    this.widgets = (await asyncFilter(widgets, async (widget) => {
      return (await widget.featureFlag)
        ? this.company.hasFeatureAccess(widget.featureFlag)
        : true;
    })) as DashboardWidget[];
    this.filteredWidgets = [...this.widgets];
  }

  filterValues() {
    this.filteredWidgets = this.widgets.filter((w) =>
      w.typeConst.label.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
}
