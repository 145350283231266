import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-single-input-modal',
  templateUrl: './single-input-modal.component.html',
  styles: []
})
export class SingleInputModalComponent {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() actionButton: string;
  @Input() meta: string;
  @Input() singleInput = '';

  constructor(public activeModal: NgbActiveModal) {}
}
