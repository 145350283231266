import { Pipe, PipeTransform } from '@angular/core';

export interface SecondsToHumanTimeLabels {
  secondLabel: string;
  minuteLabel: string;
  hourLabel: string;
  dayLabel: string;
}

const ONE_SECOND = 1;
const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

const SECOND_LABEL = ' secs';
const MINUTE_LABEL = ' mins';
const HOUR_LABEL = ' hrs';
const DAY_LABEL = ' days';

@Pipe({
  name: 'secondsToHumanTime'
})
export class SecondsToHumanTimePipe implements PipeTransform {
  transform(
    seconds: number = 0,
    isLong: boolean = false,
    altRound?: boolean,
    timeLabels: SecondsToHumanTimeLabels = {
      secondLabel: SECOND_LABEL,
      minuteLabel: MINUTE_LABEL,
      hourLabel: HOUR_LABEL,
      dayLabel: DAY_LABEL
    }
  ): string {
    return this.secondsToHumanTime(seconds, isLong, altRound, timeLabels);
  }

  secondsToHumanTime(
    seconds: number = 0,
    isLong: boolean = false,
    altRound?: boolean,
    timeLabels: SecondsToHumanTimeLabels = {
      secondLabel: SECOND_LABEL,
      minuteLabel: MINUTE_LABEL,
      hourLabel: HOUR_LABEL,
      dayLabel: DAY_LABEL
    }
  ): string {
    seconds = Math.round(seconds);

    let interval: number;
    let label: string;

    if (seconds < ONE_MINUTE) {
      interval = ONE_SECOND;
      label = timeLabels.secondLabel;
    } else if (seconds < 2 * ONE_HOUR) {
      interval = ONE_MINUTE;
      label = timeLabels.minuteLabel;
    } else if (seconds < 2 * ONE_DAY) {
      interval = ONE_HOUR;
      label = timeLabels.hourLabel;
    } else {
      interval = ONE_DAY;
      label = timeLabels.dayLabel;
    }

    if (isLong) {
      const remainder = seconds % interval;
      let result = `${Math.floor(seconds / interval)}${label}`;
      if (remainder > 0) {
        result += `, ${this.secondsToHumanTime(
          remainder,
          isLong,
          false,
          timeLabels
        )}`;
      }
      return result;
    } else {
      if (altRound) {
        const result: number = Math.floor(seconds / interval);
        if (seconds % interval > 0) {
          const remainder = this.secondsToHumanTime(
            seconds % interval,
            true,
            false,
            timeLabels
          )
            .slice(0, 2)
            .trim();
          return `${result}:${
            remainder.length > 1 ? remainder : `0${remainder}`
          }${label}`;
        }
        return `${result}${label}`;
      }
      const num: number = seconds / interval;
      const numberRounded: string =
        seconds % interval !== 0 ? num.toFixed(2) : num + '';
      return `${numberRounded}${label}`;
    }
  }
}
