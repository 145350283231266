import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  ElementRef
} from '@angular/core';
import { TIMEZONE_OFFSET } from '../../constants';
import { API } from '@ui-resources-angular';

@Component({
  selector: 'ssi-graph',
  templateUrl: './graph.component.html'
})
export class GraphComponent implements OnInit, OnChanges {
  @Input() graphName: string;
  @Input() params: object;
  @Input() height: string;

  isError = false;
  chartConfig: Highcharts.Options = {};

  constructor(private api: API, private element: ElementRef) {}

  ngOnInit() {
    this.element.nativeElement.classList.add('loading');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.params) {
      let loadGraph = true;
      Object.values(this.params).forEach((value) => {
        if (typeof value === 'undefined') {
          loadGraph = false;
        }
      });

      if (loadGraph) {
        this.drawChart();
      }
    }
  }

  async drawChart() {
    const extraParams = { timeZoneOffset: TIMEZONE_OFFSET };
    this.isError = false;
    try {
      const result = await this.api.post(
        'graph/' + this.graphName,
        Object.assign({}, this.params, extraParams)
      );
      const chartData = result.data;
      chartData.chart.height = this.height;
      this.chartConfig = chartData;
      this.chartConfig.series = chartData.series;
    } catch (error) {
      this.isError = true;
      console.error('Graph error:', error);
    } finally {
      this.element.nativeElement.classList.remove('loading');
    }
  }
}
