<div
  class="inbox-priority-select-container"
  [ssiTooltip]="selectedPriority ? 'Message priority 0' + selectedPriority : 'No priority'"
  [tooltipWindowClass]="'tooltip-chunky'"
  [tooltipAppendToBody]="true"
>

  <ng-container *ngIf="showSingleValue; else elseTemplate">
    <div
      class="inbox-priority-container single-value"
      (click)="prioritySelectMenu.hide(); prioritySelectMenu.show($event);"
    >
      <div *ngIf="selectedPriority" class="inbox-priority-badge P{{selectedPriority}}">
        <span>{{'P' + selectedPriority}}</span>
      </div>
      <i *ngIf="!selectedPriority" class="menu-item-icon ssi ssi-priority priority-icon"></i>
    </div>
  </ng-container>

  <ng-template #elseTemplate>
    <ssi-inbox-priority-indicator
      *ngFor="let p of priorities"
      [priority]="p"
      [size]="'M'"
      [type]="p === selectedPriority ? 'badge' : 'dot'"
      (click)="select(p)"
    ></ssi-inbox-priority-indicator>
  </ng-template>
</div>

<ssi-context-menu #prioritySelectMenu>
  <ng-template>
    <ul class="g-list">
      <span><i class="menu-item-icon ssi ssi-priority priority-icon"></i>
        {{selectedPriority ? 'Change priority' : 'No priority'}}
      </span>
      <li
        class="g-list-item"
        *ngFor="let p of priorities"
        [class.active]="p === selectedPriority"
        (click)="prioritySelectMenu.hide(); select(p)"
      >
        <span class="inbox-priority-dot P{{p}}"></span>
        <span>{{'Priority 0' + p}}</span>
        <i class="ssi ssi-tick-small tick"></i>
      </li>
    </ul>
  </ng-template>
</ssi-context-menu>