import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';

import strings from '../../../../../../library/constants/strings';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

@Component({
  selector: 'ssi-live-chat-conversation-statistics',
  templateUrl: './live-chat-conversation-statistics.component.html',
  styles: []
})
export class LiveChatConversationStatisticsComponent implements OnInit {
  @Input() ageOfNewestAssignedConversation: string;
  @Input() ageOfNewestUnassignedConversation: string;
  @Input() isAgentOnline: boolean;
  @Input() quantityOfOngoingConversations: number;
  @Input() quantityOfOwnedConversations: number;
  @Input() quantityOfUnassignedConversations: number;

  @Output() conversationsFilterChange = new EventEmitter();

  constructor(
    private state: StateService,
    private liveChatService: LiveChatService
  ) {}

  ngOnInit() {}

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public filterByOwned() {
    if (this.goToInboxIfOnDashboard()) {
      return;
    }

    this.conversationsFilterChange.emit(strings.owned);
  }

  public filterByOngoing() {
    if (this.goToInboxIfOnDashboard()) {
      return;
    }

    this.conversationsFilterChange.emit(strings.ongoing);
  }

  public filterByUnassigned() {
    if (this.goToInboxIfOnDashboard()) {
      return;
    }

    this.conversationsFilterChange.emit(strings.unassigned);
  }

  goToInboxIfOnDashboard(): boolean {
    if (this.state.current.name.indexOf('auth.dashboard') > -1) {
      this.state.go('auth.inbox', {
        // liveChat: true
      });
      return true;
    }

    return false;
  }
}
