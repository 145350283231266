import { VideoRestrictions } from '@ui-resources/angular/module';

export interface MinMaxNumber {
  min?: number;
  max?: number;
}

export interface MinMaxBoolean {
  min?: boolean;
  max?: boolean;
}

export interface Metadata {
  duration: number;
  dimensions: {
    height: number;
    width: number;
  };
}

export interface Errors {
  duration?: MinMaxBoolean;

  dimensions?: {
    width?: MinMaxBoolean;
    height?: MinMaxBoolean;
  };
}

export interface ValidateResult {
  isValid: boolean;
  errors?: Errors;
}

export class Video {
  validate(
    metadata: Metadata,
    restrictions: VideoRestrictions
  ): ValidateResult {
    const errors: Errors = {};

    if (restrictions.duration) {
      if (metadata.duration < restrictions.duration.min) {
        errors.duration = errors.duration || {};
        errors.duration.min = true;
      } else if (metadata.duration > restrictions.duration.max) {
        errors.duration = errors.duration || {};
        errors.duration.max = true;
      }
    }

    if (restrictions.dimensions && restrictions.dimensions.width) {
      if (
        restrictions.dimensions.width.min &&
        metadata.dimensions.width < restrictions.dimensions.width.min
      ) {
        errors.dimensions = errors.dimensions || { width: {} };
        errors.dimensions.width.min = true;
      } else if (
        restrictions.dimensions.width.max &&
        metadata.dimensions.width > restrictions.dimensions.width.max
      ) {
        errors.dimensions = errors.dimensions || { width: {} };
        errors.dimensions.width.max = true;
      }
    }

    if (restrictions.dimensions && restrictions.dimensions.height) {
      if (
        restrictions.dimensions.height.min &&
        metadata.dimensions.height < restrictions.dimensions.height.min
      ) {
        errors.dimensions = errors.dimensions || {};
        errors.dimensions.height = {
          min: true
        };
      } else if (
        restrictions.dimensions.height.max &&
        metadata.dimensions.height > restrictions.dimensions.height.max
      ) {
        errors.dimensions = errors.dimensions || {};
        errors.dimensions.height = {
          max: true
        };
      }
    }

    if (Object.keys(errors).length > 0) {
      return { isValid: false, errors };
    }

    return { isValid: true };
  }
}
