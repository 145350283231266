<article class="post-article {{ viewClass }}">
  <div class="post-heading">
    <div class="post-url">
      <a [href]="article.feed.url" target="_blank">
        <span *ngIf="article.logo"><img class="feed-logo" [src]="article.logo"> {{ article.feed.name }}</span>
        <span *ngIf="!article.logo"><i class="fa fa-tags"></i> {{ article.feed.name }}</span>
      </a>
    </div>
    <div class="post-warning">
      <span *ngIf="postToAccount.id != article.feed.account_id"><i class="ssi ssi-warning-style-02"></i>Feed post not associated with this account</span>
    </div>
    <div class="post-date">{{ article.created_at | date:'dd MMM yyyy | HH:mm' }}</div>
  </div>
  <div class="post-body">
    <div class="post-content">
      <h3 class="post-title">{{ article.title }}</h3>
      <span class="post-description" *ngIf="expandedView"
         [innerHTML]="formattedContent"></span>
      <span *ngIf="!expandedView"
            [innerHTML]="formattedContent"></span>
      <span *ngIf="descriptionCropped && !expandedView"
            class="read-more-link"
            (click)="readMore()">read more</span>

      <i *ngIf="expandedView"
         class="close-modal-icon ssi ssi-delete-close"
         (click)="readLess()"></i>
    </div>
    <div class="post-image">
      <img
        *ngIf="article.img"

        [src]="article.img"
        [ssiEnlargeImage]="article.img"
      >
    </div>
  </div>
  <div class="post-footer">
    <a class="post-create" href="javascript:" (click)="createPost(article)">
      <i class="ssi ssi-compose-post"></i>
      <span translate="CREATE_POST"></span>
      <span
        class="italic"
        [ssiTooltip]="'WE_AUTOMATICALLY_PICK_THE_BEST_TIME_TO_SCHEDULE_THIS_POST_BASED_ON_PREVIOUSLY_SUCCESSFUL_POST_TIMES' | translate"
        [tooltipAppendToBody]="true"
        tooltipWindowClass="tooltip-chunky-big"
      >i</span>
    </a>
    <a class="post-view" [href]="article.url" target="_blank">
      <i class="ssi ssi-view-natively"></i> <span translate="VIEW_NATIVELY"></span>
    </a>
  </div>
</article>
