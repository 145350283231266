import './topic-report.component.scss';

import { Component, OnInit, ViewChild } from '@angular/core';
import { commonDateRanges } from '../../../../../common/constants/common-date-ranges';
import moment from 'moment';
import { TopicReportService, TopicReportMetrics } from './topic-report.service';
import { StateService } from '@uirouter/angular';
import { ChatBotService } from '../../../../../common/services/chat-bot/chat-bot.service';
import { NgForm } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

export interface DropDownItem {
  label: string;
  value: string;
}

@Component({
  selector: 'ssi-topic-report',
  templateUrl: './topic-report.component.html'
})
export class TopicReportComponent implements OnInit {
  // form fields
  feedbackOptions: any[] = [
    { id: 'POSITIVE', name: 'Positive' },
    { id: 'NEGATIVE', name: 'Negative' },
    { id: 'NONE', name: 'None' }
  ];

  handoverOptions: any[] = [
    { id: 'YES', name: 'YES' },
    { id: 'NO', name: 'NO' }
  ];

  widgetOptions: any[] = [];
  topicOptions: any[];
  urlOptions: any[];

  startDate: Date = commonDateRanges.week.start;
  endDate: Date = commonDateRanges.week.end;
  today: Date = moment().endOf('day').toDate();
  selectedFeedback: any[];
  selectedHandover: any[];
  confidenceMin: number = 0;
  confidenceMax: number = 1;
  selectedTopics: any = [];
  selectedUrls: any = [];
  selectedWidgets: any[] = [];

  formChangesSubscription: any;
  @ViewChild('form') ngForm: NgForm;

  // table
  reportData: any;
  filteredTopics: any[];
  itemValues: string[];
  searchTerm: string;

  constructor(
    private state: StateService,
    private topicReportService: TopicReportService,
    private chatbotService: ChatBotService
  ) {}

  async ngOnInit() {
    this.formChangesSubscription = this.ngForm.form.valueChanges
      .pipe(debounceTime(500))
      .subscribe((form) => {
        console.log('Updated form:', form);
        console.log('this.ngForm:', this.ngForm);
        if (this.ngForm.dirty) {
          this.getReport();
        }
      });

    this.selectedFeedback = [...this.feedbackOptions];
    this.selectedHandover = [...this.handoverOptions];

    try {
      const widgets = await this.chatbotService.getChatBotList();
      this.widgetOptions = widgets.map((w) => {
        return {
          id: w.id,
          name: w.name
        };
      });

      this.selectedWidgets = [
        this.widgetOptions.find((o) => o.id === this.state.params.botId)
      ];

      this.getReport();
    } catch (error) {
      console.error('Error getting the topic report data:', error);
    }
  }

  async getReport() {
    const reqParams = {
      chat_bot_ids: this.selectedWidgets.map((w) => w.id),
      datetime: {
        gte: this.startDate,
        lte: this.endDate
      },
      query_text: this.searchTerm,
      feedback: this.selectedFeedback.map((f) => f.id),
      handover: this.selectedHandover.map((h) => h.id),
      confidence: {
        gte: this.confidenceMin,
        lte: this.confidenceMax
      },
      topics: this.selectedTopics.map((t) => t.id),
      urls: this.selectedUrls.map((u) => u.id)
    };
    this.reportData = await this.topicReportService.getTopicReport(reqParams);
    this.topicOptions = this.topicOptions
      ? this.topicOptions
      : this.reportData.topics;
    this.urlOptions = this.urlOptions ? this.urlOptions : this.reportData.urls;
    this.filteredTopics = this.reportData.rows;
  }

  filterValues() {
    this.getReport();
  }

  onFlatpickrOpen() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 200);
  }

  onStartDatePicked(startDate: Date): void {
    if (!startDate) {
      return;
    }
    this.startDate = startDate;
  }

  onEndDatePicked(endDate: Date): void {
    if (!endDate) {
      return;
    }
    this.endDate = endDate;
  }
}
