import './marketing.component.scss';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-marketing',
  templateUrl: './marketing.component.html',
  styles: []
})
export class MarketingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
