import './drafts-library.component.scss';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { User, UserModel } from '@ui-resources-angular';
import { NoteSubject } from '../../../../common/services/api';
import {
  PaginatorComponent,
  PageChangeParams
} from '../../../../common/components/paginator';
import {
  DraftListResponse,
  DraftListItem,
  DraftsLibraryService,
  Draft,
  DraftType
} from './drafts-library.service';
import {
  PUBLISHER_ACTIVE,
  PublisherActive
} from '../../../../common/components/publisher/publisher-active';
import { NotesModalComponent } from '../../../../common/components/notes-modal/notes-modal.component';

@Component({
  selector: 'ssi-drafts-library',
  templateUrl: './drafts-library.component.html'
})
export class DraftsLibraryComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  currentPageResponse: DraftListResponse;
  loading = true;
  initialLoading = true;
  draftType = DraftType;
  selectedDraftType = DraftType.Private;
  filters: any;
  onDestroy = new Subject();
  authUser: User;

  readonly itemsPerPage = 5;

  constructor(
    private state: StateService,
    private modal: NgbModal,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive,
    private userModel: UserModel,
    private draftsLibraryService: DraftsLibraryService
  ) {}

  async ngOnInit() {
    this.publisherActive
      .pipe(takeUntil(this.onDestroy))
      .subscribe(({ isActive }) => {
        if (isActive === false) {
          this.paginator.init();
        }
      });

    if (this.state.params.draftId && this.state.params.noteId) {
      this.selectedDraftType = DraftType.Public;
      this.draftsLibraryService
        .getDraft(this.state.params.draftId)
        .then((draft) => {
          this.showDraftNotes(draft);
        });
    }

    this.userModel.getAuthUser().then((user) => {
      this.authUser = user;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  ngAfterViewInit(): void {
    this.paginator.init();
  }

  async onPageChange(pageChangeParams: PageChangeParams): Promise<void> {
    // will also fire on paginator init

    this.loading = true;
    try {
      this.currentPageResponse = await this.draftsLibraryService.getDraftList(
        pageChangeParams.take,
        pageChangeParams.skip,
        this.selectedDraftType
      );
    } catch (e) {
      this.currentPageResponse = undefined;
      console.error('Error loading drafts: ', e);
    }
    this.loading = false;
    this.initialLoading = false;
  }

  async deleteDraft(draft: DraftListItem): Promise<void> {
    await this.draftsLibraryService.deleteDraft(draft.id);
    this.paginator.init();
  }

  trackById(index: number, draft: DraftListItem): string {
    return draft.id;
  }

  showDraftNotes(draft: Draft | DraftListItem): void {
    const modal = this.modal.open(NotesModalComponent, {
      windowClass: 'm-modal rounded-corners-15',
      centered: true
    });
    modal.componentInstance.subject = NoteSubject.Draft;
    modal.componentInstance.subjectId = draft.id;
    modal.componentInstance.modalHeader = `${draft.title}: Notes`;
    modal.componentInstance.disableTags =
      draft.draft_type === DraftType.Private;
  }

  async onFilterTypeChange(type: DraftType) {
    this.selectedDraftType = type;
    this.paginator.init();
  }
}
