import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingIndexComponent } from './advertising-index.component';
import { AdvertisingNavModule } from '../advertising-nav/advertising-nav.module';
import { AdvertisingTableFiltersModule } from '../advertising-table/advertising-table-filters/advertising-table-filters.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartModule } from '../../../../../common/components/highchart/highchart.module';
import { HighchartControlsModule } from '../../../../../modules/auth/marketing/index/highchart-controls/highchart-controls.module';
import { OutboxPostModalModule } from '../../content-calendar/common/components/outbox-post-modal/outbox-post-modal.module';
import { OutboxPostSubmodalModule } from '../../content-calendar/common/components/outbox-post-modal/outbox-post-submodal/outbox-post-submodal.module';

@NgModule({
  imports: [
    CommonModule,
    AdvertisingTableFiltersModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.index',
          url: '/adverts?id&outbox_id&account_id',
          component: AdvertisingIndexComponent,
          resolve: AdvertisingIndexComponent.resolve
        }
      ]
    }),
    AdvertisingNavModule,
    HighchartModule,
    HighchartControlsModule,
    TooltipModule,
    OutboxPostModalModule,
    OutboxPostSubmodalModule
  ],
  declarations: [AdvertisingIndexComponent],
  entryComponents: [AdvertisingIndexComponent]
})
export class AdvertisingIndexModule {}
