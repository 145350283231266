import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Renderer2,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IngredientConfig,
  replaceTemplateIngredients
} from '../../../../ifttt-utils/service-mappings/util';
import { TranslateService } from '@ngx-translate/core';
import { SimpleChanges } from '@angular/core';
import { HighlightTag } from '../../../../../../../common/components/text-input-highlight';

const UNICODE_SEPARATOR = '\u2063';

function ingredientToXmlTag(ingredient: IngredientConfig) {
  return `<ingredient name=\'${ingredient.api.name}\'/>`;
}

function ingredientToTextareaString(
  translate: TranslateService,
  ingredient: IngredientConfig
) {
  return translate.instant(ingredient.translationIds.label) + UNICODE_SEPARATOR;
}

export const INGREDIENT_TEXTAREA_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TriggersIngredientTextareaDirective),
  multi: true
};

@Directive({
  selector: 'textarea[ssiTriggersIngredientTextarea]',
  providers: [INGREDIENT_TEXTAREA_CONTROL_VALUE_ACCESSOR],
  exportAs: 'triggersIngredientTextarea'
})
export class TriggersIngredientTextareaDirective
  implements ControlValueAccessor, OnChanges {
  @Input() ingredients: IngredientConfig[];

  @Output()
  valueChanged: EventEmitter<{
    value: string;
    highlight: HighlightTag[];
  }> = new EventEmitter();

  private ingredientsReplacerRegexpCache: WeakMap<IngredientConfig, RegExp>;

  private onChange = (_: any) => {};

  private onTouched = () => {};

  constructor(
    private renderer: Renderer2,
    private elm: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ingredients && this.ingredients) {
      // as RegExp constructor is expensive, do it once and cache them
      this.ingredientsReplacerRegexpCache = new WeakMap();
      this.ingredients.forEach((ingredient) => {
        const regexp = new RegExp(
          ingredientToTextareaString(this.translate, ingredient),
          'g'
        );
        this.ingredientsReplacerRegexpCache.set(ingredient, regexp);
      });
    }
  }

  writeValue(value: any): void {
    const normalizedValue = value || '';
    const innerModelValue = replaceTemplateIngredients(
      normalizedValue,
      this.ingredients,
      (ingredient) => ingredientToTextareaString(this.translate, ingredient)
    );
    this.renderer.setProperty(this.elm.nativeElement, 'value', innerModelValue);
    this.onInput(innerModelValue);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  addIngredientAtCaret(ingredient: IngredientConfig, insertAt: number) {
    const textarea: HTMLTextAreaElement = this.elm.nativeElement;
    const textareaValue: string = textarea.value;
    const beforeInsertAt: string = textareaValue.slice(0, insertAt);
    const afterInsertAt: string = textareaValue.slice(insertAt);
    const lastCharacter: string = beforeInsertAt[beforeInsertAt.length - 1];
    const paddingBefore: string =
      lastCharacter && lastCharacter.trim() !== '' ? ' ' : '';
    const paddingAfter: string =
      afterInsertAt[0] && afterInsertAt[0].trim() !== '' ? ' ' : '';
    const ingredientString: string =
      paddingBefore +
      ingredientToTextareaString(this.translate, ingredient) +
      paddingAfter;
    textarea.value = beforeInsertAt + ingredientString + afterInsertAt;
    this.onInput(textarea.value);
    textarea.focus();
    textarea.selectionEnd = textarea.selectionStart =
      insertAt + ingredientString.length;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const highlight: HighlightTag[] = [];
    let newValue = value;
    this.ingredients.forEach((ingredient) => {
      const find = this.ingredientsReplacerRegexpCache.get(ingredient);
      const replaceWith = ingredientToXmlTag(ingredient);
      newValue = newValue.replace(find, replaceWith);
      let match = find.exec(value);
      const textareaIngredientLength = ingredientToTextareaString(
        this.translate,
        ingredient
      ).length;
      while (match !== null) {
        highlight.push({
          indices: {
            start: match.index,
            end: match.index + textareaIngredientLength
          }
        });
        match = find.exec(value);
      }
    });
    this.onChange(newValue);
    this.valueChanged.emit({ value, highlight });
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }
}
