import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../store';
import { ApiService } from '../api.service';
import {
  AuditEventsResponse,
  AuditEvent,
  AuditEventObject,
  AuditEventVerb
} from './audit-event.model';

@Injectable({ providedIn: 'root' })
export class AuditEventsService {
  // store = new Store<AuditEvent>(AuditEvent);
  endpoint = `${this.api.url}/auditLog`;
  eventsCountPerLoad = 50;

  constructor(protected api: ApiService) {}

  getAll(obj: AuditEventObject, token?: string): Promise<AuditEventsResponse> {
    const endpoint = `${this.endpoint}/getEvents`;

    const opts: any = {
      params: {
        count: this.eventsCountPerLoad
      }
    };

    if (obj.activity_id) {
      opts.params.activity_id = obj.activity_id;
    }

    if (obj.outbox_id) {
      opts.params.outbox_id = obj.outbox_id;
    }

    if (token) {
      opts.params.token = token;
    }

    return this.api
      .get(endpoint, opts)
      .pipe(
        map((response: AuditEventsResponse) => {
          return new AuditEventsResponse(response);
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  createActivityEvent(
    activityId: string,
    verb: AuditEventVerb
  ): Promise<{ success: boolean }> {
    const endpoint = `${this.endpoint}/addActivityEvent`;
    const payload = {
      id: activityId,
      event: verb
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: { success: boolean }) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
