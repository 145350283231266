import {
  AccountClass,
  AccountModel,
  CampaignModel,
  UserModel,
  API
} from '@ui-resources-angular';
import { CompanyService } from '../common/services/company/company.service';
import { WorkflowManagerService } from '../common/services/workflow-manager/workflow-manager.service';
import { AccountAuthService } from '../common/services/account-auth/account-auth.service';
import { AdvertisingPresetModelService } from './auth/marketing/advertising/advertising-preset-model/advertising-preset-model.service';
import { ServerService } from '../common/services/server/server.service';
import {
  TeamsService,
  ColleaguesService,
  OutboxTagsService
} from '../common/services/api';

export function accountsResolveFn(accountModel: AccountModel) {
  // Fetches all accounts regardless of perms
  return accountModel.findAllAccounts(null, true);
}

export const accounts = {
  token: 'accounts',
  resolveFn: accountsResolveFn,
  deps: [AccountModel]
};

export function workflowAccountsResolveFn(
  accountModel: AccountModel,
  workflowManager: WorkflowManagerService
) {
  return accountModel.findAllAccounts(workflowManager.getCurrentId());
}

export const workflowAccounts = {
  token: 'workflowAccounts',
  resolveFn: workflowAccountsResolveFn,
  deps: [AccountModel, WorkflowManagerService]
};

export function authUserResolveFn(userModel: UserModel) {
  return userModel.getAuthUser();
}

export const authUser = {
  token: 'authUser',
  resolveFn: authUserResolveFn,
  deps: [UserModel]
};

export function campaignsResolveFn(campaignModel: CampaignModel) {
  return campaignModel.findAll();
}

export const campaigns = {
  token: 'campaigns',
  resolveFn: campaignsResolveFn,
  deps: [CampaignModel]
};

export function postTagsResolveFn(outboxTagsService: OutboxTagsService) {
  return outboxTagsService.getPostTags();
}

export const postTags = {
  token: 'postTags',
  resolveFn: postTagsResolveFn,
  deps: [OutboxTagsService]
};

export function colleaguesResolveFn(colleaguesService: ColleaguesService) {
  return colleaguesService.getAll();
}

export const colleagues = {
  token: 'colleagues',
  resolveFn: colleaguesResolveFn,
  deps: [ColleaguesService]
};

export function teamsResolveFn(teamsService: TeamsService) {
  return teamsService.getAll();
}

export const teams = {
  token: 'teams',
  resolveFn: teamsResolveFn,
  deps: [TeamsService]
};

export function companyPreferencesResolveFn(company: CompanyService) {
  return company.getPreferences();
}

export const companyPreferences = {
  token: 'companyPreferences',
  resolveFn: companyPreferencesResolveFn,
  deps: [CompanyService]
};

export function authProvidersFn(accountAuth: AccountAuthService) {
  return accountAuth.getProviders();
}

export const authProviders = {
  token: 'authProviders',
  resolveFn: authProvidersFn,
  deps: [AccountAuthService]
};

export function advertisingAccountsFn(
  accountModel: AccountModel,
  workflowManager: WorkflowManagerService
) {
  return accountModel.findAccounts(workflowManager.getCurrentId(), {
    accountClass: AccountClass.Advertising
  });
}

export const advertisingAccounts = {
  token: 'advertisingAccounts',
  resolveFn: advertisingAccountsFn,
  deps: [AccountModel, WorkflowManagerService]
};

export function advertisingTargetingPresetsFn(
  presetModel: AdvertisingPresetModelService
) {
  return presetModel.findAll();
}

export const advertisingTargetingPresets = {
  token: 'advertisingTargetingPresets',
  resolveFn: advertisingTargetingPresetsFn,
  deps: [AdvertisingPresetModelService]
};

export function companyConfigResolveFn(company: CompanyService) {
  return company.getConfig();
}

export const companyConfig = {
  token: 'companyConfig',
  resolveFn: companyConfigResolveFn,
  deps: [CompanyService]
};

export function serverSettingsResolveFn(server: ServerService) {
  return server.getSettings();
}

export const serverSettings = {
  token: 'serverSettings',
  resolveFn: serverSettingsResolveFn,
  deps: [ServerService]
};

export async function userSettingsResolveFn(api: API) {
  const { data } = await api.get('settings/user');
  return data;
}

export const userSettings = {
  token: 'userSettings',
  resolveFn: userSettingsResolveFn,
  deps: [API]
};
