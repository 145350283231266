<div class="outbox-message">

  <div class="outbox-message-header">
    <a
      [href]="message.externalLink"
      target="_blank"
      *ngIf="message.externalLink && message.status === 'Live'"
      class="outbox-message-tab"
    >
      <p translate="VIEW_POST"></p>
    </a>
  </div>

  <div class="outbox-message-body">

    <div class="outbox-message-thumb">
      <img [src]="account?.picture">
    </div>

    <div class="outbox-message-content">
      <div class="outbox-message-content-meta">
        <ul>
          <li class="outbox-message-content-meta-primary">
            <img class="outbox-message-thumb-mobile" [src]="account?.picture">
            <p>{{account?.name}}</p>
            <span class="outbox-message-content-meta-primary-icon">
              <i [ngClass]="account?.socialNetwork.icon.web"></i>
            </span>
          </li>
          <li class="outbox-message-content-meta-pill" [style.margin-left.em]="0.1">
            <p>@{{account?.alias}}</p>
          </li>
          <li *ngIf="message.sent_at" class="outbox-message-content-meta-split">
            <p>{{ message.sent_at | date:'d MMM yyyy' }}</p>
            <p>{{ message.sent_at | date:'HH:mm' }}</p></li>
          <li ssiTooltip="Author" [tooltipAppendToBody]="true">
            <i *ngIf="author" class="ssi ssi-compose-post"></i>
            <p>{{ author?.first_name }} {{author?.last_name}}</p>
          </li>
          <li *ngIf="campaign" ssiTooltip="Campaign" [tooltipAppendToBody]="true">
            <i class="ssi ssi-campaign"></i>
            <p>{{campaign.name}}</p>
          </li>
        </ul>
      </div>

      <div class="outbox-message-content-body">
        <p [ssiEmojiText]="message.text | escapeHtml | nl2br | htmlToText | linkify: { mentions: mentions }"></p>
      </div>

      <div *ngIf="message.outbox_files.length" class="outbox-message-gallery-mobile">
        <div *ngIf="message.outbox_files.length === 1" class="outbox-message-gallery-wrap">
          <img [src]="message.outbox_files[0].public_url" [alt]="message.outbox_files[0].alt_text">
        </div>
  
        <div
          *ngIf="message.outbox_files.length > 1"
          class="outbox-message-gallery-wrap outbox-message-gallery-wrap-multiple"
        >
          <img [src]="message.outbox_files[0].public_url" [alt]="message.outbox_files[0].alt_text">
          <img [src]="message.outbox_files[0].public_url" [alt]="message.outbox_files[0].alt_text">
          <span class="outbox-message-gallery-wrap-multiple-badge">{{message.outbox_files.length > 3 ? '3+' : message.outbox_files.length}}</span>
        </div>
      </div>

      <div class="outbox-message-content-stats">
        <div
          class="outbox-message-content-label"
          [ngClass]="{
            'outbox-message-content-label-live': message.status === 'Live',
            'outbox-message-content-label-scheduled': message.status === 'Scheduled',
            'outbox-message-content-label-approval': message.status === 'Awaiting Approval'
          }"
        >
          <p *ngIf="message.status === 'Awaiting Approval'">Awaiting Approval</p>
          <p *ngIf="message.status === 'Scheduled'">Scheduled</p>
          <p *ngIf="message.status === 'Live'">Live</p>
        </div>

        <ul>
          <li ssiTooltip="Likes" [tooltipAppendToBody]="true">
            <i class="ssi ssi-twitter-preview-like"></i>
            <p>{{message.like_count}}</p>
          </li>
          <li ssiTooltip="Comments" [tooltipAppendToBody]="true">
            <i class="ssi ssi-total-engagement"></i>
            <p>{{message.comment_count}}</p>
          </li>
          <li ssiTooltip="Reach" [tooltipAppendToBody]="true">
            <i class="ssi ssi-reach"></i>
            <p>{{message.reach}}</p>
          </li>
          <li ssiTooltip="Impressions" [tooltipAppendToBody]="true">
            <i class="ssi ssi-eye"></i>
            <p>{{message.impressions}}</p>
          </li>
          <!-- <li><i class="ssi ssi-engagement-analytics"></i></li> -->
          <!-- <li><i class="ssi ssi-clicks"></i></li> -->
          <li ssiTooltip="Reposts" [tooltipAppendToBody]="true">
            <i class="ssi ssi-correct-retweet"></i>
            <p>{{message.share_count}}</p>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="message.outbox_files.length" class="outbox-message-gallery">
      <div *ngIf="message.outbox_files.length === 1" class="outbox-message-gallery-wrap">
        <img [src]="message.outbox_files[0].public_url" [alt]="message.outbox_files[0].alt_text">
      </div>

      <div
        *ngIf="message.outbox_files.length > 1"
        class="outbox-message-gallery-wrap outbox-message-gallery-wrap-multiple"
      >
        <img [src]="message.outbox_files[0].public_url" [alt]="message.outbox_files[0].alt_text">
        <img [src]="message.outbox_files[0].public_url" [alt]="message.outbox_files[0].alt_text">
        <span class="outbox-message-gallery-wrap-multiple-badge">{{message.outbox_files.length > 3 ? '3+' : message.outbox_files.length}}</span>
      </div>
    </div>

  </div>
</div>
