<div class="add-note">
    <div class="add-note-input-wrap">
        <label class="add-note-input-label"
               for="noteInput">
            <textarea [(ngModel)]="note"
                      #noteInput
                      (ngModelChange)="contentChange($event)"
                      id="noteInput"
                      class="add-note-input"
                      name="note"
                      [placeholder]="disableTags ? 'Enter note text...' : 'Type @ to bring up team members...'"
                      (scroll)="onNoteInputScroll($event)"
                      [mention]="disableTags ? undefined : tagList"
                      [mentionConfig]="{labelKey:'mentionName', triggerChar: '@'}"></textarea>
        </label>
        <div #noteOverlay
             class="notes-tag"
             [innerHTML]="noteWithTags | sanitizedHtml"></div>
    </div>
    <ul class="add-note-actions">
        <li (click)="note = ''; noteWithTags = ''; cancelNote.emit()"
            class="add-note-action">
            <p>Cancel</p>
        </li>
        <li class="add-note-action"
            [ngClass]="{'add-note-action-disabled': !note}"
            (click)="addNote.emit(noteWithTags); note = ''; noteWithTags = ''">
            <p>Save Note</p>
        </li>
    </ul>
</div>