import { utils } from 'js-data';
import { get, groupBy } from 'lodash-es';
import {
  getLowestValue,
  objHasAnyValueInProps,
  oneRecordHasField
} from '../../../../../apps/angular/common/util';
import {
  Account,
  AccountModel,
  AccountType
} from '../../account/services/accountModel';
import { CampaignModel } from '../../campaign/services/campaignModel';
import { User } from '../../user/services/userModel';
import {
  ImageMimetype,
  SocialNetwork,
  SocialNetworkLinkPreview,
  socialNetworkSettings,
  getMimetypeFromUrl
} from '../../constants';
import { api } from '../../core/services/api';
import {
  ChoiceWithIndices,
  getChoiceIndex as getMentionIndex
} from '../../../../../apps/angular/common/components/text-input-autocomplete';
import { Draft } from '../../../../../apps/angular/modules/auth/marketing/drafts-library/drafts-library.service';
import { Debounce } from '../../../../../apps/angular/common/decorators';
import {
  Campaign,
  CampaignsService
} from '../../../../../apps/angular/common/services/api';
import {
  AccountTypeId,
  AccountTypeIdString,
  AccountTypeName
} from '../../../../../apps/angular/common/enums';
import socialPostCharactersRemaining from '../filters/socialPostCharactersRemaining';
import { Outbox, OutboxModel } from './outboxModel';
import { Subject } from 'rxjs/Subject';
import { services } from '../../common';
import {
  ProfileModel,
  ProfileSearchLocation,
  ProfileSearchResult
} from '../../profile/services/profileModel';
import { BlockingWord } from './blockingWord';
import { Activity } from '../../activity/services/activityModel';
import { MediaCategory } from '../../../modules/publish/services/media-restrictions';
import isBefore from 'date-fns/is_before';
import differenceInHours from 'date-fns/difference_in_hours';
import format from 'date-fns/format';
import { MediaRestrictions } from './media-restrictions';
import twitterText, { UrlWithIndices } from 'twitter-text';
import { ProfilesService } from '../../../../../apps/angular/common/services/profile/profiles.service';
import { appInjector } from '../../../../../apps/angular/app-injector';
import { isAfter } from 'date-fns';

export interface OutboxVideoSubtitles {
  caption_url: string;
  locale: string;
}

export enum OutboxFileType {
  Image = 'image',
  Video = 'video',
  Gif = 'gif'
}

export enum YoutubeVisibility {
  Public = 'public',
  Unlisted = 'unlisted',
  Private = 'private'
}

export interface OutboxPublisherFile {
  id?: number;
  url: string;
  handle?: string;
  mimetype?: string;
  mediaCategory?: MediaCategory;
  type?: OutboxFileType;
  alt_text?: string;
  size?: number;
  filename?: string;
  filestackFile?: any;
  album?: {
    caption: string;
  };
  video?: {
    title: string;
  };
  gif?: {
    title: string;
  };
  subtitles?: OutboxVideoSubtitles[];
  accountIdsToLinkTo?: string[];
  visibility?: string;
  /** If the image file is edited in editor to filter out those when going back to combine mode */
  edited?: boolean;
}

export enum OutboxPublisherHighlightType {
  Link = 'link',
  Mention = 'mention'
}

export interface OutboxPublisherLink {
  indices: {
    start: number;
    end: number;
  };
  cssClass: string;
  data: {
    type: OutboxPublisherHighlightType;
    id?: string;
    url: string;
    preview: {
      title: string;
      description: string;
      images: Array<{ url: string }>;
      selectedImageIndex: number;
      isSelected: boolean;
      isLoaded: boolean;
      meta?: Array<{
        name: string;
        value: string;
      }>;
      blockedSocialNetworks: AccountType[];
    };
    shorten: boolean;
    prefix: string;
    utm: {
      enabled: boolean;
      source?: string;
      medium?: string;
      campaign?: string;
      term?: string;
      content?: string;
    };
    domain?: string;
    link?: string;
  };
}

export interface OutboxPublisherMention {
  indices: {
    start: number;
    end: number;
  };
  cssClass: string;
  data: {
    type: OutboxPublisherHighlightType;
    url: string;
    username: string;
    id: string;
    account_ids?: Array<number | string>; // number comes from the backend, declared as a string locally
    account_type_id: number | string; // number comes from the backend, declared as a string locally
  };
}

export interface OutboxPublisherText {
  value: string;
  links: OutboxPublisherLink[];
}

function getPublisherType(publisher: OutboxPublisher): string {
  if (publisher.reply) {
    return 'reply';
  } else if (publisher.privateMessage) {
    return 'privateMessage';
  } else {
    return 'post';
  }
}

// TODO - refactor the config to use the same properties as in getPublisherType
function getMaxCharactersField(publisher: OutboxPublisher): string {
  if (publisher.reply) {
    return 'reply';
  } else if (publisher.privateMessage) {
    return 'private';
  } else {
    return 'public';
  }
}

function normaliseUrl(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = `https://${url}`;
  }
  const urlParsed = new URL(url);
  // force IE / Edge to url encode params
  urlParsed.search = urlParsed.searchParams.toString();
  return urlParsed.toString();
}

function extractUtmParams(
  url: string,
  stripUtmParamsFromUrl = false
): { extracted: string; utm: { [key: string]: string } } {
  const parsed = new URL(normaliseUrl(url));

  const utmParamNames = Object.freeze([
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
  ]);

  const utm = {};
  utmParamNames.forEach((paramName) => {
    if (parsed.searchParams.has(paramName)) {
      utm[paramName] = parsed.searchParams.get(paramName);

      if (stripUtmParamsFromUrl) {
        parsed.searchParams.delete(paramName);
      }
    }
  });
  return { extracted: normaliseUrl(parsed.toString()), utm };
}

function addUtmParams(url: string, utm: { [key: string]: string }): string {
  const parsed = new URL(url);
  Object.entries(utm).forEach(([key, value]) => {
    if (value) {
      parsed.searchParams.set(key, value);
    }
  });
  return normaliseUrl(parsed.toString());
}

function getImageInfo(
  url: string
): Promise<{
  url: string;
  isLoaded: boolean;
  dimensions?: { width: number; height: number };
}> {
  return new utils.Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        url,
        isLoaded: true,
        dimensions: {
          width: img.naturalWidth,
          height: img.naturalHeight
        }
      });
    };
    img.onerror = () => {
      resolve({ url, isLoaded: false });
    };
    img.src = url;
  });
}

const DEFAULT_UTM_MEDIUM = 'social';

const DEFAULT_UTM_CAMPAIGN = 'Orlo';

const MINIMUM_IMAGE_PREVIEW_DIMENSIONS = 200;

const IMAGE_PREVIEW_META_TAGS = Object.freeze(['og:image', 'twitter:image']);

function escapeXmlEntities(input: string): string {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
}

function unescapeXmlEntities(input: string): string {
  return input
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, '');
}

function sanitizeLinkPrefix(prefix: string): string {
  return prefix.trim().replace(/[^a-zA-Z0-9-_]/g, '_');
}

function appendMentionTags(
  text,
  account,
  selectedMentions: OutboxPublisherMention[]
) {
  function createCompanyIdAttribute(
    mention: OutboxPublisherMention,
    accountName
  ) {
    let attribute = '';
    switch (accountName) {
      case 'LinkedIn':
        attribute = `li='${mention.data.id}'`;
        break;
      case 'Twitter':
        attribute = `tw='${mention.data.username}'`;
        break;
      case 'Facebook':
      case 'Facebook Group':
        attribute = `fb='${mention.data.username}'`;
        break;
      default:
        break;
    }
    return attribute;
  }

  selectedMentions
    .sort((mA, mB) => mB.indices.start - mA.indices.start)
    .forEach((mention) => {
      const label = OutboxPublisher.getMentionLabel(mention.data);
      const labels = selectedMentions.map((m) =>
        OutboxPublisher.getMentionLabel(m.data)
      );
      const escapedLabel = escapeXmlEntities(label);
      const escapedLabels = labels.map((l) => escapeXmlEntities(l));

      const index = getMentionIndex(text, escapedLabel, escapedLabels);
      if (index === -1) {
        console.error(
          `OP.ts: Mention label (${escapedLabel}) not found in the text: ${text}`
        );
        return;
      }

      const accAttr = createCompanyIdAttribute(
        mention,
        account.account_type_name
      );
      const tag = `<mention ${accAttr}>${escapedLabel}</mention>`;

      const partBefore = text.slice(0, index);
      const partAfter = text.slice(index + escapedLabel.length);

      text = partBefore + tag + partAfter;
    });

  return text;
}

export function serialiseOutboxPublisherText(
  text: string,
  links: OutboxPublisherLink[],
  account: Account,
  campaign?: Campaign,
  selectedMentions?: OutboxPublisherMention[]
): string {
  // assume links are already sorted in ascending order
  let tokens = links.reduce(
    (build: string[], link: OutboxPublisherLink, linkIndex: number) => {
      const prevTextIndexStart =
        linkIndex === 0 ? 0 : links[linkIndex - 1].indices.end;
      const prevTextIndexEnd = link.indices.start;
      const prevText = escapeXmlEntities(
        text.substring(prevTextIndexStart, prevTextIndexEnd)
      );

      const { extracted: url, utm } = extractUtmParams(link.data.url, false);

      const utmParams = link.data.utm.enabled
        ? [
            {
              key: 'utm_source',
              value:
                utm.utm_source ||
                link.data.utm.source ||
                account.account_type_name
            },
            {
              key: 'utm_medium',
              value:
                utm.utm_medium || link.data.utm.medium || DEFAULT_UTM_MEDIUM
            },
            {
              key: 'utm_campaign',
              value:
                utm.utm_campaign ||
                link.data.utm.campaign ||
                DEFAULT_UTM_CAMPAIGN
            },
            {
              key: 'utm_term',
              value: utm.utm_term || link.data.utm.term
            },
            {
              key: 'utm_content',
              value:
                utm.utm_content ||
                link.data.utm.content ||
                (campaign ? campaign.name : '')
            }
          ]
        : [];

      const params = [
        {
          key: 'shorten',
          value: link.data.shorten ? 'true' : 'false'
        },
        {
          key: 'domain',
          value: link.data.domain || account.default_vanity_domain
        },
        {
          key: 'link_id',
          value: link.data.id
        },
        {
          key: 'link',
          value: link.data.link
        },
        {
          key: 'prefix',
          // it shouldn't even be possible to set weird characters in the prefix, but some woman from a devon council managed it
          value: link.data.prefix
            ? sanitizeLinkPrefix(link.data.prefix)
            : link.data.prefix
        },
        ...utmParams
      ];

      const paramString = params
        .filter((param) => param.value)
        .map((param) => `${param.key}="${escapeXmlEntities(param.value)}"`)
        .join(' ');

      const escapedUrl = escapeXmlEntities(url);
      const xmlLink = `<link ${paramString}>${escapedUrl}</link>`;

      return [...build, prevText, xmlLink];
    },
    []
  );

  const remainingTextIndexStart =
    links.length > 0 ? links[links.length - 1].indices.end : 0;

  tokens.push(escapeXmlEntities(text.substr(remainingTextIndexStart)));
  let message = tokens.join('');

  if (selectedMentions && selectedMentions.length) {
    message = appendMentionTags(message, account, selectedMentions);
  }

  return (
    '<?xml version="1.0" encoding="utf-8"?>\n' +
    `<outbox-text>${message}</outbox-text>`
  );
}

function htmlDecode(text: string): string {
  const tempTextAreaElm = document.createElement('textarea');
  tempTextAreaElm.innerHTML = text;
  return tempTextAreaElm.value;
}

function arelinksShortened(
  links: OutboxPublisherLink[],
  vanityDomains: string[]
): boolean {
  return links.some((link) => {
    return vanityDomains.some((domain) => {
      return (
        link.data.url.startsWith(`http://${domain}/`) ||
        link.data.url.startsWith(`https://${domain}/`)
      );
    });
  });
}

export enum OutboxMessageType {
  StatusUpdate = 'status_update',
  Picture = 'picture',
  Album = 'album',
  Video = 'video',
  Reply = 'reply',
  ReplyPicture = 'reply_picture',
  ReplyAlbum = 'reply_album',
  ReplyVideo = 'reply_video',
  AutoComment = 'auto_comment',
  PrivateMessage = 'private_message',
  PrivatePictureMessage = 'private_picture_message',
  MultiImage = 'multi_image', // used for IG carousels too
  Share = 'share',
  InstagramStory = 'instagram_story',
  InstagramReel = 'instagram_reel'
}

export enum OutboxMessageClass {
  Post,
  Reply,
  PrivateMessage
}

export function getOutboxMessageClass(
  type: OutboxMessageType
): OutboxMessageClass {
  switch (type) {
    case OutboxMessageType.StatusUpdate:
    case OutboxMessageType.Picture:
    case OutboxMessageType.Album:
    case OutboxMessageType.Video:
    case OutboxMessageType.MultiImage:
    case OutboxMessageType.Share:
    case OutboxMessageType.InstagramStory:
    case OutboxMessageType.InstagramReel:
      return OutboxMessageClass.Post;

    case OutboxMessageType.Reply:
    case OutboxMessageType.ReplyPicture:
    case OutboxMessageType.ReplyAlbum:
    case OutboxMessageType.ReplyVideo:
    case OutboxMessageType.AutoComment:
      return OutboxMessageClass.Reply;

    case OutboxMessageType.PrivateMessage:
    case OutboxMessageType.PrivatePictureMessage:
      return OutboxMessageClass.PrivateMessage;

    default:
      throw new Error(`Unknown message type ${type}`);
  }
}

export enum OutboxMessageAttachmentType {
  Image = '@Image',
  Video = '@Video',
  VideoWithTitle = '@VideoWithTitle',
  Album = '@Album',
  ImageWithCaption = '@ImageWithCaption',
  LinkPreview = '@LinkPreview',
  DirectMessageReplyLink = '@DirectMessageReplyLink',
  QuoteRetweet = '@QuoteRetweet',
  FacebookShare = '@FacebookShare',
  Location = '@Location'
}

export interface OutboxMessageAttachmentImage {
  type: OutboxMessageAttachmentType.Image;
  url: string;
  id?: number;
  alt_text?: string;
}

export interface OutboxMessageAttachmentVideo {
  type: OutboxMessageAttachmentType.Video;
  url: string;
  id?: number;
  subtitles?: OutboxVideoSubtitles[];
}

export interface OutboxMessageAttachmentVideoWithTitle {
  type: OutboxMessageAttachmentType.VideoWithTitle;
  url: string;
  id?: number;
  title: string;
  subtitles?: OutboxVideoSubtitles[];
  alt_text?: string;
}

export interface OutboxMessageAttachmentAlbum {
  type: OutboxMessageAttachmentType.Album;
  name: string;
  images: OutboxMessageAttachmentImageWithCaption[];
}

export interface OutboxMessageAttachmentImageWithCaption {
  type: OutboxMessageAttachmentType.ImageWithCaption;
  url: string;
  id?: number;
  caption: string;
  alt_text?: string;
}

export interface OutboxMessageAttachmentLinkPreview {
  type: OutboxMessageAttachmentType.LinkPreview;
  url: string;
  image?: string;
  title?: string;
  description?: string;
}

export interface OutboxMessageAttachmentDirectMessageReplyLink {
  type: OutboxMessageAttachmentType.DirectMessageReplyLink;
}

export interface OutboxMessageAttachmentQuoteRetweet {
  type: OutboxMessageAttachmentType.QuoteRetweet;
  url: string;
}

export interface OutboxMessageAttachmentFacebookShare {
  type: OutboxMessageAttachmentType.FacebookShare;
  post_id: string;
}

export interface OutboxMessageAttachmentLocation {
  type: OutboxMessageAttachmentType.Location;
  id: string; // e.g. 396282320429148
  name: string; // e.g. Rugby - Warwickshire . UK
  external_link: string; // e.g. https://facebook.com/396282320429148
}

export type OutboxMessageAttachment =
  | OutboxMessageAttachmentImage
  | OutboxMessageAttachmentVideo
  | OutboxMessageAttachmentVideoWithTitle
  | OutboxMessageAttachmentAlbum
  | OutboxMessageAttachmentLinkPreview
  | OutboxMessageAttachmentDirectMessageReplyLink
  | OutboxMessageAttachmentQuoteRetweet
  | OutboxMessageAttachmentFacebookShare
  | OutboxMessageAttachmentLocation;

/**
 * For Outbox replies only, doesn't handle original posts
 */
export function getOutboxMessageType(
  currentMessageType: OutboxMessageType,
  files: OutboxPublisherFile[]
): OutboxMessageType {
  // console.log('INBOX_REPLY_PRIVATE_TYPES:', INBOX_REPLY_PRIVATE_TYPES)
  const isPrivateMessage = [
    OutboxMessageType.PrivateMessage,
    OutboxMessageType.PrivatePictureMessage
  ].includes(currentMessageType);

  if (Array.isArray(files) && files.length > 0) {
    if (files.length > 1) {
      return OutboxMessageType.ReplyAlbum;
    } else {
      return isPrivateMessage
        ? OutboxMessageType.PrivatePictureMessage
        : OutboxMessageType.ReplyPicture;
    }
    return OutboxMessageType.ReplyPicture;
  } else {
    return isPrivateMessage
      ? OutboxMessageType.PrivateMessage
      : OutboxMessageType.Reply;
  }
}

export function filesToOutboxMessageAttachments(
  account: Account,
  files: OutboxPublisherFile[],
  type: 'reply' | 'post' | 'privateMessage' = 'reply',
  addDmReplyLink?: boolean
): OutboxMessageAttachment[] {
  const attachments: OutboxMessageAttachment[] = [];

  if (files.length > 0) {
    const hasVideo = files.some((file) => file.type === OutboxFileType.Video);
    if (
      hasVideo &&
      account.socialNetwork.mediaRestrictions[type].video.max > 0
    ) {
      const titleRequired =
        get(
          account,
          `socialNetwork.mediaRestrictions.${type}.video.title.length.min`
        ) > 0;

      if (titleRequired) {
        const attachment: OutboxMessageAttachmentVideoWithTitle = {
          type: OutboxMessageAttachmentType.VideoWithTitle,
          url: files[0].url,
          title: files[0].video.title
        };
        if (files[0].subtitles) {
          attachment.subtitles = [...files[0].subtitles];
        }

        attachments.push(attachment);
      } else {
        const attachment: OutboxMessageAttachmentVideo = {
          type: OutboxMessageAttachmentType.Video,
          url: files[0].url
        };
        if (files[0].subtitles) {
          attachment.subtitles = [...files[0].subtitles];
        }
        attachments.push(attachment);
      }
    } else if (
      files.length > 1 &&
      account.socialNetwork.mediaRestrictions[type].image.max > 0
    ) {
      files.forEach((file) => {
        const attachment: OutboxMessageAttachmentImage = {
          type: OutboxMessageAttachmentType.Image,
          url: file.url,
          alt_text: file.alt_text
        };
        attachments.push(attachment);
      });
    } else {
      // is single image post
      const addGifAsVideo =
        files[0].type === OutboxFileType.Gif &&
        get(
          account,
          `socialNetwork.mediaRestrictions.${type}.gif.title.length.min`
        ) > 0;
      let attachment:
        | OutboxMessageAttachmentImage
        | OutboxMessageAttachmentVideoWithTitle;
      if (addGifAsVideo) {
        attachment = {
          type: OutboxMessageAttachmentType.VideoWithTitle,
          url: files[0].url,
          title: files[0].gif.title,
          alt_text: files[0].alt_text
        };
      } else {
        attachment = {
          type: OutboxMessageAttachmentType.Image,
          url: files[0].url,
          alt_text: files[0].alt_text
        };
      }
      attachments.push(attachment);
    }
  }

  if (addDmReplyLink && account.socialNetwork.addPrivateReplyLinkAsAttachment) {
    attachments.push({
      type: OutboxMessageAttachmentType.DirectMessageReplyLink
    });
  }

  return attachments;
}

export interface OutboxMessage {
  type: '@OutboxMessage';
  id?: number;
  message_type: OutboxMessageType;
  account_id: number;
  campaign_id: number | null;
  text: string;
  requires_validation: boolean;
  send_at?: string;
  attachments: OutboxMessageAttachment[];
  targeting?: any;
  recipient_social_id?: string;
  reply_to_activity_id?: string;
  reply_to_social_id?: string;
  reply_exclude_users?: string[];
  social_id?: string;
  delete_at?: Date;
  // push_notification_user_id?: number;
  tags: string[];
  youtube_visibility?: YoutubeVisibility;
  instagram_share_to_feed?: boolean;
  auto_comment?: AutoComment[];
}

/**
 * The actual payload for `activity/reply` endpoint when replying in inbox
 */
export interface ActivityReply {
  id?: string; // created on the go
  account_id: string;
  exclude_users: string[];
  humanAgent: boolean;
  recursive: boolean;
  reply: string;
  file?: any;
  link?: any;
  add_dm_reply_link?: boolean;
}

export interface AutoComment {
  text: string | null; // backend expected prop
  image: string | null; // backend expected prop (image url)
  file?: OutboxPublisherFile; // locally used only - backend throws an error if any prop except the two above are present?
}

export const MAXIMUM_OUTBOX_POST_SCHEDULES = 12;

export class OutboxPublisher {
  static events = {
    created: new Subject<{ posts: Outbox[] }>(),
    updated: new Subject<{ posts: Outbox[] }>()
  };

  delete_at?: Date;
  igShareToFeed = true;
  scheduleFirstCommentToggled = false;
  mediaCategory = MediaCategory.Post;

  readonly selectableAccounts: ReadonlyArray<Account>;

  spamRestrictedSocialNetworks = [AccountType.Twitter, AccountType.TwitterAds];
  campaign: Campaign;
  multiImage = true;
  private _allFiles: ReadonlyArray<OutboxPublisherFile> = [];
  tags: string[] = [];
  profileWithLocation: ProfileSearchResult;

  album: {
    name?: string;
    description?: string;
    coverPhoto?: OutboxPublisherFile;
  };

  autoCommentByAccountId: { [key: string]: AutoComment } = {};

  requiresValidation = false;

  targeting: {
    [AccountTypeName.Facebook]?: {
      min_age: number;
      max_age: number;
      gender: string;
      relationship_status: string;
      countries: string[];
    };
    [AccountTypeName.LinkedIn]?: {
      geos: string[];
      companySizes: string[];
      jobFunc: string[];
      industries: string[];
      seniorities: string[];
    };
    [AccountTypeName.Nextdoor]?: {
      group_ids: string[];
    };
    [AccountTypeName.NextdoorUS]?: {
      group_ids: string[];
    };
  } = {
    [AccountTypeName.Facebook]: {
      min_age: null,
      max_age: null,
      gender: null,
      relationship_status: null,
      countries: []
    },
    [AccountTypeName.LinkedIn]: {
      geos: [],
      companySizes: [],
      jobFunc: [],
      industries: [],
      seniorities: []
    },
    [AccountTypeName.Nextdoor]: {
      group_ids: []
    },
    [AccountTypeName.NextdoorUS]: {
      group_ids: []
    }
  };

  socialNetworks: Array<{ config: SocialNetwork; accounts: Account[] }> = [];

  readonly features: {
    album: boolean;
    targeting: boolean;
    customiseLinkPreview: boolean;
    changeAccounts: boolean;
    combinePostText: boolean;
    videoCaptions: boolean;
  } = {
    album: false,
    targeting: false,
    customiseLinkPreview: false,
    changeAccounts: true,
    combinePostText: true,
    videoCaptions: false
  };

  mediaRestrictions = new MediaRestrictions();

  validity: {
    isValid: boolean;
    errors: {
      videoRequired: boolean;
      linkRequired: boolean;
      videoTitleRequired: boolean;
      gifTitleRequired: boolean;
      textRequired: boolean;
      accountsRequired: boolean;
      characterLimitExceeded: boolean;
      targetingInvalid: boolean;
      mediaRequired: boolean;
      linksAlreadyShortened: boolean;
      albumCannotBeMultiScheduled: boolean;
      duplicatePostText: boolean;
      schedulingErrors: boolean;
      expiryDateMissing: boolean;
      expiryDateInPast: boolean;
    };
    errorCount?: number;
    duplicatePostTextAccounts: Array<{
      accounts: Account[];
    }>;
  };

  charactersRemaining: number;

  readonly scheduleCount = {
    maximum: MAXIMUM_OUTBOX_POST_SCHEDULES,
    remaining: MAXIMUM_OUTBOX_POST_SCHEDULES
  };

  vanityDomains: {
    primary: { domain: string; accounts: Account[] };
    others: Array<{ domain: string; accounts: Account[] }>;
  };

  blockingWordMatches: string[] = [];
  public schedulingErrors = false;
  exactBlockingMatchesInText: string[] = [];
  quoteRetweetUrl?: string;
  shareId?: string;
  replyExcludeUsers: string[] = [];
  youtubeVisibility?: YoutubeVisibility = YoutubeVisibility.Public;

  isDraft: boolean = false;

  postsRequiringValidation = 0;

  shareToFeed: boolean = false;

  accountsContainingBlockingWords: Partial<Account>[] = [];
  accountsContainingBlockingWordsLabel: string;

  expiryEnabled: boolean = false;

  get canAddDmReplyLink() {
    return (
      this.reply &&
      this.accounts.length > 0 &&
      this.accounts[0].socialNetwork.addPrivateReplyLinkAsAttachment &&
      this._allFiles.length === 0
    );
  }

  get addDmReplyLink() {
    return this._addDmReplyLink;
  }

  set addDmReplyLink(addDmReplyLink: boolean) {
    if (
      addDmReplyLink &&
      this.accounts.length > 0 &&
      !this.accounts[0].socialNetwork.addPrivateReplyLinkAsAttachment
    ) {
      throw new Error(
        'The selected account type does not support adding DM reply links as attachments'
      );
    }
    if (addDmReplyLink && !this.canAddDmReplyLink) {
      throw new Error('You cannot add a DM reply link to this reply');
    }
    this._addDmReplyLink = addDmReplyLink;
    this.updateMediaRemaining();
  }

  private _addDmReplyLink: boolean;

  readonly edit: OutboxMessage;

  readonly reply?: {
    readonly socialId?: string;
    readonly activityId?: string;
  };

  readonly privateMessage?: {
    readonly socialId?: string;
    readonly activityId?: string;
  };

  private _accounts: ReadonlyArray<Account> = [];

  private _text: {
    combined: OutboxPublisherText;
    split: Map<Account, OutboxPublisherText>;
    mode: 'combined' | 'split';
  } = {
    combined: {
      value: '',
      links: []
    },
    split: new Map(),
    mode: 'combined'
  };

  private _schedules: ReadonlyArray<Date> = Object.freeze([]);
  private _splitPostAccount: Account;
  private _maxCombinedPostLength: number;
  private _targetingValid = true;
  private _blockingWord = new BlockingWord();

  constructor(
    private workflowAccounts: Account[],
    private authUser: User,
    private utmEnabledDefault: boolean,
    private linkShorteningEnabled: boolean,
    private blockingWords: string[],
    {
      edit,
      replyToSocialId,
      privateMessageSocialId,
      replyToActivity,
      videoGifUrls
    }: {
      edit?: OutboxMessage;
      replyToSocialId?: string;
      privateMessageSocialId?: string;
      replyToActivity?: Activity;
      videoGifUrls?: string[];
    } = {},
    multiImage?
  ) {
    if (edit) {
      this.edit = edit;
    } else if (replyToSocialId) {
      this.reply = Object.freeze({ socialId: replyToSocialId });
    } else if (privateMessageSocialId) {
      this.privateMessage = Object.freeze({ socialId: privateMessageSocialId });
    } else if (replyToActivity) {
      if (replyToActivity.interaction.is_private) {
        this.privateMessage = Object.freeze({ activityId: replyToActivity.id });
      } else {
        this.reply = Object.freeze({ activityId: replyToActivity.id });
      }
    }

    this.selectableAccounts = Object.freeze(
      this.sortSelectableAccounts(
        workflowAccounts.filter((account) => {
          if (account.account_type_id === '7') {
            // Don't show old IG type accounts.
            // Remove this when all customers migrate to Instagram Business.
            return false;
          }

          return authUser.hasAccountPermission(account.id, 'post');
        })
      )
    );

    let albumName = '';
    const publisher = this;
    this.album = {
      set name(name: string) {
        albumName = name;
        publisher.updateValidity();
      },
      get name(): string {
        return albumName;
      },
      description: ''
    };

    this.updateScheduleCount();
    this.updateValidity();

    if (edit) {
      const account = services.models
        .get<AccountModel>('account')
        .get(String(edit.account_id));
      this.accounts = [account];
      this.features.changeAccounts = false;

      if (edit.campaign_id) {
        const campaignsService = appInjector().get(CampaignsService);
        this.campaign = campaignsService.findById(edit.campaign_id.toString());
      }

      if (Array.isArray(edit.tags)) {
        this.tags = edit.tags;
      }

      if (edit.delete_at) {
        this.expiryEnabled = true;
      }

      const { text, links, mentions } = this.deserialiseOutboxPublisherText(
        edit.text,
        account
      );

      this.text = text;

      this.allMentions = [...mentions];

      this.links.forEach((link) => {
        const deserialisedLink = links.find((l) => l.url === link.data.url);
        if (deserialisedLink) {
          Object.assign(link.data, deserialisedLink);
        } else {
          // No backend link annotation means shortening wasn't enabled
          // (Backend only saves links for which shortening is enabled)
          link.data.shorten = false;
          link.data.utm.enabled = false;
        }
      });

      this.requiresValidation = edit.requires_validation;

      if (edit.send_at && new Date(edit.send_at) > new Date()) {
        this.addSchedule(new Date(edit.send_at));
      }

      if (
        edit.targeting &&
        Object.keys(edit.targeting).length > 0 &&
        this.targeting[account.account_type_name]
      ) {
        Object.assign(
          this.targeting[account.account_type_name],
          edit.targeting
        );
      }

      if (edit.reply_to_social_id) {
        this.reply = Object.freeze({ socialId: edit.reply_to_social_id });
      } else if (edit.recipient_social_id) {
        this.privateMessage = Object.freeze({
          socialId: edit.recipient_social_id
        });
      } else if (edit.reply_to_activity_id) {
        if (
          getOutboxMessageClass(edit.message_type) === OutboxMessageClass.Reply
        ) {
          this.reply = Object.freeze({ activityId: edit.reply_to_activity_id });
        } else {
          this.privateMessage = Object.freeze({
            activityId: edit.reply_to_activity_id
          });
        }
      }

      if (edit.reply_exclude_users) {
        this.replyExcludeUsers = edit.reply_exclude_users;
      }

      if (typeof edit.instagram_share_to_feed === 'boolean') {
        this.igShareToFeed = edit.instagram_share_to_feed;
      }

      if (Array.isArray(edit.auto_comment) && edit.auto_comment.length) {
        this.scheduleFirstCommentToggled = true;
        this.autoCommentByAccountId[edit.account_id] = edit.auto_comment[0];
      }

      this.deserializeAttachments(edit, videoGifUrls);

      this.mediaCategory = this.files[0]
        ? this.files[0].mediaCategory
        : MediaCategory.Post;
    }
  }

  deserializeAttachments(
    outboxMessage: OutboxMessage,
    videoGifUrls?: string[]
  ): void {
    const account = this.getAccountFromId(outboxMessage.account_id);

    outboxMessage.attachments.forEach((attachment) => {
      console.log('attachment: ', attachment);
      const attachmentUrl = (<any>attachment).url || '';
      if (
        attachmentUrl.indexOf('https://cdn.uploads.orlo.app') === 0 ||
        attachmentUrl.indexOf('https://cdn.filestackcontent.com') === 0
      ) {
        console.error(
          `deserializeAttachments: attachment still uses filesack CDN url: ${attachmentUrl}`
        ); // trackjs
      }

      switch (attachment.type) {
        case OutboxMessageAttachmentType.Image:
          this.addFile(
            {
              id: attachment.id,
              url: attachment.url,
              type: OutboxFileType.Image,
              mediaCategory:
                outboxMessage.message_type === OutboxMessageType.InstagramStory
                  ? MediaCategory.Story
                  : MediaCategory.Post,
              alt_text: attachment.alt_text
            },
            undefined,
            false
          );
          break;

        case OutboxMessageAttachmentType.LinkPreview:
          const attachmentUrlWithoutUtmParams = extractUtmParams(
            attachment.url,
            true
          ).extracted;
          const link = this.links.find(
            (iLink) =>
              attachmentUrlWithoutUtmParams ===
              extractUtmParams(iLink.data.url, true).extracted
          );
          link.data.preview.isSelected = true;
          link.data.preview.title = attachment.title;
          link.data.preview.description = attachment.description;
          link.data.preview.images = [{ url: attachment.image }];
          break;

        case OutboxMessageAttachmentType.Album:
          this.album.name = attachment.name;
          // the api expects the album description to be the text
          this.album.description = this.deserialiseOutboxPublisherText(
            outboxMessage.text,
            account
          ).text;
          // set the post text to be the wall post text which is the cover photo's text
          this.text = attachment.images[0].caption;
          attachment.images.forEach((image) => {
            this.addFile(
              {
                id: image.id,
                url: image.url,
                type: OutboxFileType.Image
              },
              {
                albumCaption: image.caption
              },
              false
            );
          });
          break;

        case OutboxMessageAttachmentType.Video:
          this.addFile(
            {
              subtitles: attachment.subtitles,
              id: attachment.id,
              url: attachment.url,
              type: OutboxFileType.Video,
              mediaCategory:
                outboxMessage.message_type === OutboxMessageType.InstagramStory
                  ? MediaCategory.Story
                  : outboxMessage.message_type ===
                    OutboxMessageType.InstagramReel
                  ? MediaCategory.Reel
                  : MediaCategory.Post
            },
            undefined,
            false
          );
          break;

        case OutboxMessageAttachmentType.VideoWithTitle:
          const gifTitleMinLength = get(
            account,
            'socialNetwork.mediaRestrictions.post.gif.title.length.min',
            0
          );

          if (
            gifTitleMinLength > 0 &&
            Array.isArray(videoGifUrls) &&
            videoGifUrls.indexOf(attachment.url) > -1
          ) {
            // gifs come from the backend as videos / @VideoWithTitle (they have been uploaded as images originally, but passed to the backend as videos since FB requires GIFs to have a title) - so make them images/GIFs again
            this.addFile(
              {
                id: attachment.id,
                url: attachment.url,
                type: OutboxFileType.Gif,
                mimetype: ImageMimetype.Gif,
                alt_text: attachment.alt_text
              },
              {
                gifTitle: attachment.title
              },
              false
            );
          } else {
            this.addFile(
              {
                subtitles: attachment.subtitles,
                id: attachment.id,
                url: attachment.url,
                type: OutboxFileType.Video
              },
              {
                videoTitle: attachment.title
              },
              false
            );
          }
          break;

        case OutboxMessageAttachmentType.DirectMessageReplyLink:
          this.addDmReplyLink = true;
          break;

        case OutboxMessageAttachmentType.QuoteRetweet:
          this.quoteRetweetUrl = attachment.url;
          break;

        case OutboxMessageAttachmentType.FacebookShare:
          this.shareId = attachment.post_id;
      }
    });

    console.log('allFiles: ', this._allFiles);
  }

  static async getVideoGifUrls(urls: string[]): Promise<string[]> {
    if (!Array.isArray(urls) || !urls.length) {
      return [];
    }

    const videoGifUrls = [];

    try {
      const mimePromises = urls.map((url) => getMimetypeFromUrl(url));
      const mimes = await Promise.all(mimePromises);

      mimes.forEach((mime, i) => {
        if (mime === ImageMimetype.Gif) {
          videoGifUrls.push(urls[i]);
        }
      });
    } catch (e) {
      console.error('unable to get mime from attachment url: ', e);
    }

    return videoGifUrls;
  }

  /** Assigning mediaRestrictions value per account or combining it if in combine mode */
  getMediaRestrictions() {
    const type = getPublisherType(this);

    if (this.isSplit) {
      this.mediaRestrictions = new MediaRestrictions().forAccount(
        this.splitPostAccount,
        type
      );
    } else {
      this.mediaRestrictions = new MediaRestrictions().combine(
        this.socialNetworks,
        type
      );
    }

    this.updateMediaRemaining();
  }

  set accounts(accounts: Account[]) {
    if (!this.features.changeAccounts) {
      throw new Error('You cannot change accounts when editing a post');
    }

    accounts.forEach((account) => {
      if (!this.selectableAccounts.includes(account)) {
        throw new Error(
          `Account ${account.id} does not have the post permission / is not in the current workflow accounts`
        );
      }
    });

    if ((this.reply || this.privateMessage) && accounts.length > 1) {
      throw new Error('You can only reply or private message one account');
    }

    if (accounts.length > this.accounts.length) {
      const addedAccount = accounts.find(
        (account) => !this.accounts.find((a) => a.id === account.id)
      );
      if (!this.isSplit) {
        this._allFiles.forEach((file) => {
          file.accountIdsToLinkTo.push(addedAccount.id);
        });
      }
    } else if (accounts.length < this.accounts.length) {
      const removedAccount = this.accounts.find(
        (account) => !accounts.find((a) => a.id === account.id)
      );

      this._allFiles.forEach((file) => {
        file.accountIdsToLinkTo = file.accountIdsToLinkTo.filter(
          (id) => id !== removedAccount.id
        );
      });

      if (accounts.length) {
        // preserve the files if no account is selected, handling the specific case
        // brought up in this ticket: https://orlo.atlassian.net/browse/CT-470
        this._allFiles = this._allFiles.filter((file) => {
          return !!file.accountIdsToLinkTo.length;
        });
      }
    }

    this._accounts = Object.freeze([...accounts]);

    this.socialNetworks = Object.entries(socialNetworkSettings)
      .map(([key, config]) => ({
        config,
        accounts: accounts.filter(
          (account) => account.account_type_name === key
        )
      }))
      .filter((network) => network.accounts.length > 0);

    Object.freeze(this.socialNetworks);

    // handle no accounts selected
    if (accounts.length === 0) {
      this.isSplit = false;
      this.splitPostAccount = undefined;
    } else if (!accounts.includes(this.splitPostAccount)) {
      this.splitPostAccount = this.socialNetworks[0].accounts[0];
    }

    this.updateAlbumRequired();
    this.features.targeting = oneRecordHasField(
      this.socialNetworks,
      'config.publish.features.targeting'
    );
    this.features.customiseLinkPreview = oneRecordHasField(
      this.socialNetworks,
      'config.publish.features.customiseLinkPreview'
    );

    this.features.videoCaptions = oneRecordHasField(
      this.socialNetworks,
      'config.publish.features.videoCaptions'
    );

    this.getMediaRestrictions();

    const uniqueAccountTypes = this.socialNetworks.every((networkGroup) => {
      return (
        networkGroup.accounts.length < 2 ||
        networkGroup.accounts.every(
          (acc: Account) =>
            !this.spamRestrictedSocialNetworks.includes(acc.account_type_name)
        )
      );
    });

    this.features.combinePostText = uniqueAccountTypes;

    this._maxCombinedPostLength =
      getLowestValue(
        this.socialNetworks,
        `config.maxPostCharacters.${getMaxCharactersField(this)}`
      ) || 0;

    if (
      (this.mediaRestrictions.image.max === 0 &&
        this._allFiles.length > 0 &&
        this._allFiles[0].type === OutboxFileType.Image) ||
      (this.mediaRestrictions.video.max === 0 &&
        this._allFiles.length > 0 &&
        this._allFiles[0].type === OutboxFileType.Video) ||
      (this.mediaRestrictions.gif.max === 0 &&
        this._allFiles.length > 0 &&
        this._allFiles[0].type === OutboxFileType.Gif)
    ) {
      this._allFiles = [];
    }

    if (this._allFiles.length > this.mediaRestrictions.image.max) {
      this._allFiles = this._allFiles.slice(
        0,
        this.mediaRestrictions.image.max
      );
    }

    this.updateVanityDomains();
    this.updateMediaRemaining();
    this.updateCharactersRemaining();
    this.updateValidity();

    if (!this.features.combinePostText && !this.edit && !this.isSplit) {
      this.isSplit = true;
    }

    if (this.accounts.length && this.allMentions.length) {
      if (!this.isSplit) {
        // need to trigger it here manually so mentions get assigned accountIds correctly
        this.onSelectedChoicesChange(
          this.mentions.map((m) => {
            return { choice: m.data, indices: m.indices };
          })
        );
      }
    } else {
      this.allMentions = [];
    }
  }

  get accounts(): Account[] {
    return this._accounts as Account[];
  }

  get isSplit(): boolean {
    return this._text.mode === 'split';
  }

  set isSplit(isSplit: boolean) {
    this._text.mode = isSplit ? 'split' : 'combined';

    if (isSplit) {
      if (this.edit) {
        throw new Error('Cannot split posts when editing a post');
      }
      if (this.accounts.length === 0) {
        throw new Error(
          'At least one account must be selected to split a post'
        );
      }

      this._text.split = new Map();
      // do selectableAccounts to handle the case of accounts being selected after splitting the post
      this.selectableAccounts.forEach((account, i) => {
        const combinedCopy = utils.copy(this._text.combined, {});
        this._text.split.set(account, combinedCopy);
      });
    } //
    else {
      if (this._text.split.get(this.splitPostAccount)) {
        this._text.combined = this._text.split.get(this.splitPostAccount);

        if (this.instagramAccountsOnly()) {
          const splitPostAccountFiles = this.getFilesForAccount(
            this.splitPostAccount
          );
          const anyFile = splitPostAccountFiles[0];
          if (anyFile) {
            this.mediaCategory = anyFile.mediaCategory;
          }
          // remove all the combined files and get the split post account files and add them to all accounts (combine)
          this.files.forEach((file) => {
            this.removeFile(file);
          });
          splitPostAccountFiles.forEach((file) => {
            this.addFile(file);
          });
        }
      }

      this.allMentions = this.allMentions.filter((m) => {
        return this.text.indexOf(m.data.username) > -1;
      });

      // need to trigger it here manually so mentions get assigned accountIds correctly
      this.onSelectedChoicesChange(
        this.mentions.map((m) => {
          return { choice: m.data, indices: m.indices };
        })
      );
    }

    this.updateCharactersRemaining();
    this.updateVanityDomains();
    this.updateValidity();
    this.getMediaRestrictions();
  }

  get selectedTab(): OutboxPublisherText {
    return this.isSplit
      ? this._text.split.get(this.splitPostAccount)
      : this._text.combined;
  }

  get text(): string {
    return this.selectedTab.value;
  }

  set text(text: string) {
    const existingLinks = this.links;
    text = text || '';

    const newLinks: UrlWithIndices[] = twitterText.extractUrlsWithIndices(text);

    // quick hack to stop twitter text identifying road.safety.training@eastriding.gov.uk as a link. orlo/orlo#1203
    const wantedLinks = newLinks.filter((link) => {
      // TODO: currently any link ending with the dot will not be registered, need better solution here?
      const endOfLink = link.indices[1];
      return text[endOfLink] !== '.';
    });

    const links: OutboxPublisherLink[] = wantedLinks.map((link) => {
      const isLinkPreviewLink =
        link === newLinks[newLinks.length - 1] && this._allFiles.length === 0;
      // compare against links without utm parameters in case the user edited them
      const { extracted } = extractUtmParams(link.url, true);
      const existingLink = existingLinks.find(
        (iLink) =>
          extractUtmParams(iLink.data.url, true).extracted === extracted
      );
      if (existingLink) {
        existingLink.data.url = link.url;
      }
      return {
        indices: {
          start: link.indices[0],
          end: link.indices[1]
        },
        cssClass: isLinkPreviewLink
          ? 'publisher-link-tag-primary'
          : 'publisher-link-tag-warning',
        data: existingLink
          ? existingLink.data
          : {
              type: OutboxPublisherHighlightType.Link,
              preview: {
                title: '',
                description: '',
                images: [],
                selectedImageIndex: 0,
                isSelected: false,
                isLoaded: false,
                blockedSocialNetworks: []
              },
              url: link.url,
              shorten: this.linkShorteningEnabled,
              get prefix(): string {
                return this._prefix || '';
              },
              set prefix(prefix: string) {
                this._prefix = sanitizeLinkPrefix(prefix || '');
              },
              utm: {
                enabled: this.utmEnabledDefault
              }
            }
      };
    });

    if (this.isSplit) {
      this._text.split.set(this.splitPostAccount, {
        value: text,
        links
      });
    } else {
      this._text.combined = {
        value: text,
        links
      };
    }

    this.onSetText();
  }

  getTextForAccount(account: Account): string {
    return this.isSplit
      ? this._text.split.get(account).value
      : this._text.combined.value;
  }

  @Debounce(600, false)
  onSetText(): void {
    this.updateCharactersRemaining();
    this.updateValidity();
  }

  getAccountsContainingBlockingWords() {
    this.accountsContainingBlockingWordsLabel = '';
    this.accountsContainingBlockingWords = [];
    if (this.isAnyAccContainingBlockingWords()) {
      // when in split mode, only look at the text in each split post
      if (this.isSplit) {
        this.accounts.forEach((acc) => {
          if (
            this._blockingWord.matchText(
              this.blockingWords,
              this._text.split.get(acc).value
            ).length > 0
          ) {
            this.accountsContainingBlockingWords.push({
              displayName: acc.displayName,
              accountTypeLabel: acc.accountTypeLabel
            });
          }
        });
      } else {
        if (
          this._blockingWord.matchText(
            this.blockingWords,
            this._text.combined.value
          ).length > 0
        ) {
          this.accounts.forEach((acc) => {
            this.accountsContainingBlockingWords.push({
              displayName: acc.displayName,
              accountTypeLabel: acc.accountTypeLabel
            });
          });
        }
      }
      let tempAccArray = [];
      this.accountsContainingBlockingWords.map((a) => {
        tempAccArray.push(
          `${a.displayName} <span>(${a.accountTypeLabel})</span>`
        );
        this.accountsContainingBlockingWordsLabel = tempAccArray.join(', ');
      });
    }
  }

  isAnyAccContainingBlockingWords(): boolean {
    let blockingWordMatchesPerAcc: string[] = [];
    if (this.isSplit) {
      this.accounts.forEach((acc) => {
        if (this._text.split.get(acc) && this._text.split.get(acc).value) {
          const matched = this._blockingWord.matchText(
            this.blockingWords,
            this._text.split.get(acc).value
          );
          if (matched.length > 0) {
            blockingWordMatchesPerAcc = matched;
          }
        }
      });
    }
    let blockingWordMatchesCombined = this._blockingWord.matchText(
      this.blockingWords,
      this._text.combined.value
    );
    this.blockingWordMatches = [
      ...blockingWordMatchesCombined,
      ...blockingWordMatchesPerAcc
    ].filter((match, i, arr) => arr.indexOf(match) === i);
    const exactBlockingMatchesInTextWithDupes: string[] = this._blockingWord.findExactMatches(
      this.blockingWordMatches,
      this._text.combined.value
    );
    this.exactBlockingMatchesInText = [
      ...new Set(exactBlockingMatchesInTextWithDupes)
    ];
    return this.blockingWordMatches.length > 0;
  }

  /** Get the files for the current split account or combined if it's initial view (no files added) */
  get files(): OutboxPublisherFile[] {
    // console.log('this._allFiles: ', this._allFiles);
    if (this.isSplit) {
      return this.getFilesForAccount(this.splitPostAccount);
    } else {
      const originalFiles = this._allFiles.filter((f) => !f.edited);
      return [...originalFiles];
    }
  }

  get imageFiles(): OutboxPublisherFile[] {
    return this.files.filter(
      (f) => f.type === OutboxFileType.Image || f.type === OutboxFileType.Gif
    );
  }

  get videoFiles(): OutboxPublisherFile[] {
    return this.files.filter((f) => f.type === OutboxFileType.Video);
  }

  getFilesForAccount(account: Account): OutboxPublisherFile[] {
    return this._allFiles.filter((f) =>
      f.accountIdsToLinkTo.includes(account.id)
    );
  }

  get links(): OutboxPublisherLink[] {
    return this.selectedTab.links;
  }

  private _allMentions: OutboxPublisherMention[] = [];
  get allMentions(): OutboxPublisherMention[] {
    return this._allMentions;
  }

  set allMentions(mentions: OutboxPublisherMention[]) {
    // merge mentions with the same username and account type id (needed when e.g. parsing a draft)
    const mergedMentions = [];
    mentions.forEach((m) => {
      const uniqueMention = mergedMentions.find(
        (mm) =>
          mm.data.username === m.data.username &&
          mm.data.account_type_id === m.data.account_type_id
      );

      if (uniqueMention) {
        m.data.account_ids.forEach((id) => {
          if (uniqueMention.data.account_ids.indexOf(id) === -1) {
            uniqueMention.data.account_ids.push(id);
          }
        });
      } else {
        mergedMentions.push(m);
      }
    });

    this._allMentions = mergedMentions;
  }

  get mentions(): OutboxPublisherMention[] {
    let mentions;
    if (this.isSplit) {
      mentions = this.getMentionsForAccount(this.splitPostAccount);
    } else {
      mentions = this.allMentions.filter((m) => {
        return !!this.accounts.find((a) =>
          m.data.account_ids.some((id) => id === a.id)
        );
      });
    }
    return mentions;
  }

  getMentionsForAccount(a: Account): OutboxPublisherMention[] {
    return this.allMentions.filter((m) =>
      m.data.account_ids.some((id) => id === a.id)
    );
  }

  get highlightEntities(): Array<OutboxPublisherLink | OutboxPublisherMention> {
    return [...this.links, ...this.mentions];
  }

  get splitPostAccount(): Account {
    return this._splitPostAccount;
  }

  set splitPostAccount(account: Account) {
    this._splitPostAccount = account;

    if (account && account.isInstagram()) {
      // so that instagram post options dropdown is updated
      const anyFile = this.getFilesForAccount(account)[0];
      if (anyFile) {
        this.mediaCategory = anyFile.mediaCategory;
      }
    } else {
      this.mediaCategory = MediaCategory.Post;
    }

    this.updateVanityDomains();
    this.updateCharactersRemaining();
    this.getMediaRestrictions();
  }

  static setFileType(file: OutboxPublisherFile): void {
    if (file.mimetype) {
      const type = file.mimetype.split('/')[0];

      if (file.mimetype === ImageMimetype.Gif) {
        file.type = OutboxFileType.Gif;
      } else if (type === OutboxFileType.Image) {
        file.type = OutboxFileType.Image;
      } else if (type === OutboxFileType.Video) {
        file.type = OutboxFileType.Video;
      } else {
        throw new Error('Unknown file type: ' + type);
      }
    } else if (!file.type) {
      throw new Error(
        'You must specify either the type or mimetype of the file'
      );
    }
  }

  addFile(
    file: OutboxPublisherFile,
    { videoTitle = '', albumCaption = '', gifTitle = '' } = {},
    addToAll: boolean = true
  ) {
    const publisher = this;
    if (this.accounts.length === 0) {
      throw new Error('You must select some accounts first');
    }

    OutboxPublisher.setFileType(file);

    if (
      this.mediaRestrictions.image.max === 0 &&
      file.type === OutboxFileType.Image
    ) {
      throw new Error('The selected account types do not support images');
    }
    if (
      this.mediaRestrictions.video.max === 0 &&
      file.type === OutboxFileType.Video
    ) {
      throw new Error('The selected account types do not support videos');
    }
    if (
      this.mediaRestrictions.gif.max === 0 &&
      file.type === OutboxFileType.Gif
    ) {
      throw new Error('The selected account types do not support gifs');
    }
    if (
      this.files.length > 0 &&
      this.files[0].type !== file.type &&
      !this.instagramAccountsOnly()
    ) {
      throw new Error('You cannot add both images and videos to a post');
    }
    if (
      file.type === OutboxFileType.Image &&
      this.mediaRestrictions.image.max > 0 &&
      this.files.length >= this.mediaRestrictions.image.max
    ) {
      throw new Error(
        `You can only add ${this.mediaRestrictions.image.max} images`
      );
    }
    if (
      file.type === OutboxFileType.Video &&
      this.mediaRestrictions.video.max > 0 &&
      this.files.length >= this.mediaRestrictions.video.max
    ) {
      throw new Error(
        `You can only add ${this.mediaRestrictions.video.max} videos`
      );
    }
    if (
      file.type === OutboxFileType.Gif &&
      this.mediaRestrictions.gif.max > 0 &&
      this.files.length >= this.mediaRestrictions.gif.max
    ) {
      throw new Error(
        `You can only add ${this.mediaRestrictions.gif.max} gifs`
      );
    }

    const fileCopy: OutboxPublisherFile = { ...file };

    if (file.type === OutboxFileType.Image) {
      fileCopy.album = { caption: albumCaption };
    } else if (file.type === OutboxFileType.Video) {
      fileCopy.video = {
        set title(title: string) {
          videoTitle = title;
          publisher.updateValidity();
        },
        get title(): string {
          return videoTitle;
        }
      };
    } else {
      fileCopy.gif = {
        set title(title: string) {
          gifTitle = title;
          publisher.updateValidity();
        },
        get title(): string {
          return gifTitle;
        }
      };
    }

    if (!this.album.coverPhoto) {
      this.album.coverPhoto = fileCopy;
    }

    if (this.isSplit && this.splitPostAccount && !addToAll) {
      fileCopy.accountIdsToLinkTo = [this.splitPostAccount.id];
    } else {
      fileCopy.accountIdsToLinkTo = this.accounts.map((a) => a.id);
    }

    this._allFiles = [...this._allFiles, fileCopy];

    this.updateMediaRemaining();

    this.text = this.text; // hack to force link preview highlight tag css classes to update
    this.updateScheduleCount();
    this.updateValidity();
  }

  removeFile(file: OutboxPublisherFile) {
    if (this.isSplit) {
      const fileToRemove = this._allFiles.find((f) => f === file);
      fileToRemove.accountIdsToLinkTo = fileToRemove.accountIdsToLinkTo.filter(
        (id) => id !== this.splitPostAccount.id
      );
      if (!fileToRemove.accountIdsToLinkTo.length) {
        this._allFiles = this._allFiles.filter((f) => f !== file);
      }
    } else {
      this._allFiles = this._allFiles.filter((f) => f !== file);
    }

    if (this.album.coverPhoto === file) {
      if (this._allFiles.length > 0) {
        this.album.coverPhoto = this._allFiles[0];
      } else {
        this.album.coverPhoto = undefined;
      }
    }

    this.updateMediaRemaining();
    this.text = this.text; // hack to force link preview highlight tag css classes to update
    this.updateScheduleCount();
    this.updateValidity();
  }

  get schedules() {
    return this._schedules;
  }

  addSchedule(
    date: Date,
    post?: OutboxPublisher
  ): {
    isValid: boolean;
    error?: {
      dateInPast?: true;
      dateTooCloseTo?: Date;
      maxSchedulesReached?: true;
    };
  } {
    if (this.schedules.length >= this.scheduleCount.maximum) {
      this.schedulingErrors = true;

      return {
        isValid: false,
        error: {
          maxSchedulesReached: true
        }
      };
    }

    if (date < new Date() && this.edit && !this.edit.social_id) {
      this.schedulingErrors = true;

      return {
        isValid: false,
        error: {
          dateInPast: true
        }
      };
    }

    const similarTimes = this.schedules.filter(
      (schedule) => Math.abs(differenceInHours(schedule, date)) < 168
    );

    if (post) {
      if (post.hasTwitterSelected() && similarTimes.length > 0) {
        this.schedulingErrors = true;

        return {
          isValid: false,
          error: {
            dateTooCloseTo: similarTimes[0]
          }
        };
      }
    } else {
      if (similarTimes.length > 0) {
        this.schedulingErrors = true;

        return {
          isValid: false,
          error: {
            dateTooCloseTo: similarTimes[0]
          }
        };
      }
    }

    this._schedules = Object.freeze([
      ...this._schedules,
      new Date(date.getTime())
    ]);
    this.updateScheduleCount();
    this.updateValidity();

    this.schedulingErrors = false;
    return { isValid: true };
  }

  removeSchedule(date: Date) {
    this._schedules = Object.freeze(
      this._schedules.filter(
        (schedule) => schedule.getTime() !== date.getTime()
      )
    );
    this.updateScheduleCount();
    this.updateValidity();
  }

  getLinkPreview(
    link: OutboxPublisherLink,
    markSelected = true,
    autoError = true
  ): Promise<void> {
    if (link.data.preview.isLoaded) {
      return utils.Promise.resolve();
    }

    return api
      .get<{
        data: {
          description: string;
          images: string[];
          title: string;
          meta: Array<{
            name: string;
            value: string;
          }>;
          robots: {
            social_networks: {
              [accountType: string]: {
                user_agent: string;
                is_allowed: boolean;
              };
            };
          };
        };
      }>('outbox/linkPreview', {
        params: { url: normaliseUrl(link.data.url) },
        autoError
      })
      .then((result) => {
        const metaImages = result.data.meta
          .filter((meta) => IMAGE_PREVIEW_META_TAGS.includes(meta.name))
          .map((meta) => meta.value);

        const imageInfoPromises = [...metaImages, ...result.data.images].map(
          getImageInfo
        );

        return utils.Promise.all(imageInfoPromises).then((imageInfo) => {
          const validImageSrcs = imageInfo
            .filter((image) => image.isLoaded)
            .filter(
              (image) =>
                image.dimensions.width >= MINIMUM_IMAGE_PREVIEW_DIMENSIONS
            )
            .filter(
              (image) =>
                image.dimensions.height >= MINIMUM_IMAGE_PREVIEW_DIMENSIONS
            )
            .map((image) => image.url);

          result.data.images = result.data.images.filter((url) =>
            validImageSrcs.includes(url)
          );

          result.data.meta = result.data.meta.filter(
            (meta) =>
              !IMAGE_PREVIEW_META_TAGS.includes(meta.name) ||
              validImageSrcs.includes(meta.value)
          );
          return result;
        });
      })
      .then((result) => {
        if (markSelected) {
          this.links.forEach((l) => (l.data.preview.isSelected = false));
          link.data.preview.isSelected = true;
        }

        const blockedSocialNetworks = Object.entries(
          result.data.robots.social_networks
        )
          .filter(([, { is_allowed: isAllowed }]) => !isAllowed)
          .map(([network]) => network);

        Object.assign(link.data.preview, {
          isLoaded: true,
          images: [
            ...link.data.preview.images,
            // allow the user to select meta images, and make them a priority over random images
            ...result.data.meta
              .filter((meta) => IMAGE_PREVIEW_META_TAGS.includes(meta.name))
              .map((meta) => ({ url: meta.value })),
            ...result.data.images.map((url) => ({ url }))
          ],
          title: link.data.preview.title || result.data.title,
          description: link.data.preview.description || result.data.description,
          meta: result.data.meta,
          blockedSocialNetworks
        });
      });
  }

  getSocialNetworkTextPreview(
    account: Account
  ): {
    value: string;
    links: OutboxPublisherLink[];
    mentions: OutboxPublisherMention[];
  } {
    const text = this.isSplit
      ? this._text.split.get(account)
      : this._text.combined;

    const value = account.socialNetwork.publish.getTextPreview
      ? account.socialNetwork.publish.getTextPreview(text, this)
      : text.value;

    const mentions = this.getMentionsForAccount(account);

    return { value, links: text.links, mentions };
  }

  getSocialNetworkLinkPreview(
    account: Account
  ): Promise<SocialNetworkLinkPreview | void> {
    const { links } = this.getSocialNetworkTextPreview(account);

    return utils.Promise.all(
      links.map((link) => this.getLinkPreview(link, false, false))
    ).then(() => {
      const socialNetworkLinkPreview = account.socialNetwork.publish.getLinkPreview(
        links,
        this.files
      );
      if (socialNetworkLinkPreview) {
        const link = this.links.find(
          (iLink) => iLink.data.url === socialNetworkLinkPreview.url
        );
        if (
          link.data.preview.blockedSocialNetworks.includes(
            account.account_type_name
          )
        ) {
          return utils.Promise.reject({
            robotsTxtBlocked: true
          }) as Promise<any>;
        }
      }
      return socialNetworkLinkPreview;
    });
  }

  resetTargeting() {
    console.log('WIP resetTargeting');
    this.targeting = {
      Facebook: {
        min_age: null,
        max_age: null,
        gender: null,
        relationship_status: null,
        countries: []
      },
      LinkedIn: {
        geos: [],
        companySizes: [],
        jobFunc: [],
        industries: [],
        seniorities: []
      },
      'Nextdoor Agency': {
        group_ids: []
      },
      'Nextdoor Agency US': {
        group_ids: []
      }
    };
  }

  validateTargeting() {
    this._targetingValid = false;
    this.updateValidity();
    const promises = this.socialNetworks
      .map(({ accounts, config }) => {
        const validate: (
          accounts: Account[],
          targeting: object
        ) => Promise<any> = get(config, 'publish.targeting.validate');

        return { accounts, validate, network: config };
      })
      .filter(({ accounts, validate }) => {
        return (
          accounts.length > 0 &&
          validate &&
          objHasAnyValueInProps(this.targeting[accounts[0].account_type_name])
        );
      })
      .map(({ accounts, validate }) => {
        return validate(
          accounts,
          this.targeting[accounts[0].account_type_name]
        );
      });

    if (promises.length === 0) {
      this._targetingValid = true;
      this.updateValidity();
    }
    return utils.Promise.all(promises).then((result) => {
      this._targetingValid = true;
      this.updateValidity();
      return result;
    });
  }

  hasTargetingSet(): boolean {
    return Object.values(this.targeting).some((t) => objHasAnyValueInProps(t));
  }

  getLocationTags(searchText: string): Promise<ProfileSearchResult[]> {
    if (!searchText) {
      return utils.Promise.resolve([]);
    }

    const account = this.isSplit ? this.splitPostAccount : this.accounts[0];
    const profileModel = services.models.get<ProfileModel>('profile');
    return profileModel
      .searchLocation(account.id, searchText)
      .then((results) => {
        return results.map((result: ProfileSearchResult) => {
          return result;
        });
      });
  }

  getAutocompleteProfiles(searchText: string): Promise<ProfileSearchResult[]> {
    if (!searchText || !this.autocompleteProfilesAvailable()) {
      return utils.Promise.resolve([]);
    }

    const account = this.isSplit ? this.splitPostAccount : this.accounts[0];
    const profileModel = appInjector().get(ProfilesService);

    return profileModel.search(account.id, searchText).then(({ results }) => {
      return results
        .map((result: ProfileSearchResult) => {
          // FB can fallback to the profile id if the username is not set
          result.username = result.username || result.id;
          return result;
        })
        .filter((result: ProfileSearchResult) => {
          const existingMention = this.mentions.find(
            (m) => m.data.username === result.username
          );
          return (
            !existingMention ||
            existingMention.data.username.toLowerCase() ===
              searchText.toLowerCase()
          );
        });
    });
  }

  autocompleteProfilesAvailable(): boolean {
    if (this.isSplit) {
      return !!this.splitPostAccount.socialNetwork.publish
        .autocompleteProfilesAvailable;
    }

    return (
      this.socialNetworks.length === 1 &&
      !!this.socialNetworks[0].config.publish.autocompleteProfilesAvailable
    );
  }

  async toOutboxMessages(): Promise<OutboxMessage[]> {
    this.updateValidity();
    if (!this.validity.isValid) {
      return utils.Promise.reject('The post is not valid!');
    }

    // workaround linkedin not scraping the title and setting the title to the socsi.in link
    let linkPreviewsGenerated: Promise<any> = utils.Promise.resolve();
    if (this.features.customiseLinkPreview) {
      const getLinkPreview = (links: OutboxPublisherLink[]) => {
        const hasSelectedLink = links.some(
          (link) => link.data.preview.isSelected
        );
        if (!hasSelectedLink && links.length > 0) {
          return this.getLinkPreview(links[0], true, false).catch((err) => {
            // if the link preview couldn't be loaded, dont stop it publishing the post
            console.error('Could not auto load link preview', err);
          });
        } else {
          return utils.Promise.resolve();
        }
      };

      if (this.isSplit) {
        linkPreviewsGenerated = utils.Promise.all(
          this.accounts
            .filter(
              (account) =>
                account.socialNetwork.publish.features.customiseLinkPreview
            )
            .map((account) =>
              getLinkPreview(this._text.split.get(account).links)
            )
        );
      } else {
        linkPreviewsGenerated = getLinkPreview(this.links);
      }
    }

    return linkPreviewsGenerated.then(() => {
      const messages: OutboxMessage[] = [];

      this.accounts.forEach((account) => {
        let text: string;
        let links: OutboxPublisherLink[];
        let share: boolean;

        if (this.isSplit) {
          text = this._text.split.get(account).value;
          links = this._text.split.get(account).links;
        } else {
          text = this._text.combined.value;
          links = this._text.combined.links;
        }

        share = this.shareId ? true : false;
        if (this.schedules.length > 0) {
          messages.push(
            ...this.schedules.map((sendAt) =>
              this.outboxPublisherToOutboxMessage(
                text,
                links,
                account,
                sendAt,
                this.getMentionsForAccount(account),
                this.getFilesForAccount(account),
                share
              )
            )
          );
        } else {
          messages.push(
            this.outboxPublisherToOutboxMessage(
              text,
              links,
              account,
              undefined,
              this.getMentionsForAccount(account),
              this.getFilesForAccount(account),
              share
            )
          );
        }
      });

      return messages;
    });
  }

  publish(httpConfig: any = {}): Promise<Outbox[]> {
    return this.toOutboxMessages()
      .then((messages: OutboxMessage[]) => {
        let body;
        if (this.edit) {
          body = { message: messages[0] };

          if (this.edit.social_id) {
            return api.post('outbox_v2/editPublishedPost', body, httpConfig);
          }

          httpConfig.params = httpConfig.params || {};
          httpConfig.params._method = 'PUT';
        } else {
          body = { messages };
        }

        return api.post('outbox_v2/indexOutboxv2', body, httpConfig);
      })
      .then(({ data }) => {
        let outboxMessage = data;
        if (this.edit && this.edit.social_id) {
          outboxMessage = { id: data.outbox_id };
        }
        const posts = services.models
          .get<OutboxModel>('outbox')
          .inject(outboxMessage as any[]);
        if (this.edit) {
          OutboxPublisher.events.updated.next({ posts });
        } else {
          OutboxPublisher.events.created.next({ posts });
        }
        return posts;
      });
  }

  public updateValidity() {
    let textRequired = false;
    let linksAlreadyShortened = false;

    const vanityDomains =
      this.vanityDomains && this.vanityDomains.primary
        ? [this.vanityDomains.primary, ...this.vanityDomains.others].map(
            (vanityDomain) => vanityDomain.domain
          )
        : [];

    const duplicatePostTextAccounts = [];

    this.socialNetworks.forEach((network) => {
      const accountsWithText = network.accounts
        .map((account) => {
          return {
            account,
            text: this.isSplit
              ? this._text.split.get(account).value
              : this._text.combined.value
          };
        })
        .filter(({ text }) => !!text);
      const duplicateSocialNetworkPostTextAccounts = Object.values(
        groupBy(accountsWithText, 'text')
      )
        .filter((accounts: any[]) => accounts.length > 1)
        .map((accounts: any) => {
          return {
            accounts: accounts.map((accountWithText) => accountWithText.account)
          };
        })
        .filter((accountGroup: any) =>
          accountGroup.accounts.some((acc: Account) =>
            this.spamRestrictedSocialNetworks.includes(acc.account_type_name)
          )
        );

      duplicatePostTextAccounts.push(...duplicateSocialNetworkPostTextAccounts);
    });

    const type = getPublisherType(this);

    let linkRequired =
      this.mediaRestrictions.image.linkRequired && this._allFiles.length > 0;

    if (this.isSplit) {
      textRequired = this.accounts.some(
        (account) =>
          !this._text.split.get(account).value &&
          !this.splitPostAccount.isInstagram()
      );

      linksAlreadyShortened = this.accounts.some((account) =>
        arelinksShortened(this._text.split.get(account).links, vanityDomains)
      );
      linkRequired =
        linkRequired &&
        this.accounts
          .filter(
            (account) =>
              account.socialNetwork.mediaRestrictions[type].image.linkRequired
          )
          .some((account) => this._text.split.get(account).links.length === 0);
    } else {
      const tikTokOrInstagramAccountsOnly = this.accounts.every(
        (a) => a.isTikTok() || a.isInstagram()
      );

      textRequired = tikTokOrInstagramAccountsOnly
        ? false
        : !this._text.combined.value;

      linksAlreadyShortened = arelinksShortened(
        this._text.combined.links,
        vanityDomains
      );
      linkRequired = linkRequired && this.links.length === 0;
    }

    const isVideoTitleMissing = (): boolean => {
      const foundFile = this._allFiles.find((file) => {
        if (file.type !== OutboxFileType.Video) {
          return false;
        }

        const account = this.accounts.find((a) => {
          return file.accountIdsToLinkTo.includes(a.id);
        });
        if (!account) {
          // possible in case user attached a video file and then deselected all accounts
          // see: https://orlo.atlassian.net/browse/CT-470
          return false;
        }

        const restrictions = new MediaRestrictions().forAccount(account, type);
        const titleRequired =
          restrictions &&
          restrictions.video &&
          restrictions.video.title &&
          restrictions.video.title.length.min > 0;
        return titleRequired;
      });
      return !!foundFile && !foundFile.video.title;
    };

    this.getAccountsContainingBlockingWords();
    this.updateValidationsRequired();

    const errors = Object.freeze({
      videoRequired:
        this.mediaRestrictions.video.min > 0 && this._allFiles.length === 0,
      linkRequired,
      videoTitleRequired: isVideoTitleMissing(),
      gifTitleRequired:
        this.mediaRestrictions.gif.title.length.min > 0 &&
        this._allFiles.length > 0 &&
        this._allFiles[0].type === OutboxFileType.Gif &&
        !this._allFiles[0].gif.title,
      textRequired,
      accountsRequired: this.accounts.length === 0,
      characterLimitExceeded: this.charactersRemaining < 0, // TODO - if split post, check other networks
      targetingInvalid: !this._targetingValid,
      mediaRequired: this.socialNetworks.some((network) => {
        const minFiles = network.config.mediaRestrictions[type].min;
        if (typeof minFiles !== 'undefined') {
          return this._allFiles.length < minFiles;
        }
        return false;
      }),
      linksAlreadyShortened,
      albumCannotBeMultiScheduled: false,
      duplicatePostText: duplicatePostTextAccounts.length > 0,
      schedulingErrors: this.schedulingErrors,
      expiryDateMissing: this.expiryEnabled && !this.delete_at,
      expiryDateInPast:
        this.expiryEnabled && isBefore(this.delete_at, new Date())
    });

    this.validity = Object.freeze({
      isValid: !Object.values(errors).some(Boolean),
      errors,
      errorCount: Object.values(errors).filter(Boolean).length,
      duplicatePostTextAccounts
    });
  }

  updateValidationsRequired() {
    if (!this.accounts.length) {
      this.postsRequiringValidation = 0;
    }

    this.postsRequiringValidation = this.accounts.reduce(
      (acc: number, curVal: Account) =>
        (acc =
          acc +
          (this.authUser.permissions[curVal.id]['post_un-validated']
            ? this._allFiles.length &&
              !this.authUser.permissions[curVal.id]['post_image_un-validated']
              ? 1
              : 0
            : 1)),
      0
    );
  }

  hasMoreThanOneFilePerAccount(): boolean {
    if (this._allFiles.length < 2) {
      return false;
    }

    if (!this.isSplit) {
      return true;
    }

    const filesCountPerAccount = {};
    this._allFiles.forEach((f) => {
      f.accountIdsToLinkTo.forEach((id) => {
        filesCountPerAccount[id] = filesCountPerAccount[id]
          ? filesCountPerAccount[id] + 1
          : 1;
      });
    });

    return Object.values(filesCountPerAccount).some((c) => c > 1);
  }

  private updateScheduleCount(): void {
    if (this.edit) {
      this.scheduleCount.maximum = 1;
    } else {
      this.scheduleCount.maximum = MAXIMUM_OUTBOX_POST_SCHEDULES;
    }
    this.scheduleCount.remaining =
      this.scheduleCount.maximum - this.schedules.length;
  }

  public updateCharactersRemaining(): void {
    if (!this.accounts.length) {
      return;
    }

    const processedText = this.getTextWithProcessedLinks();

    const maxLength = this.isSplit
      ? this.splitPostAccount.socialNetwork.maxPostCharacters[
          getMaxCharactersField(this)
        ]
      : this._maxCombinedPostLength;

    const hasTwitter = this.isSplit
      ? this.splitPostAccount.socialNetwork === socialNetworkSettings.Twitter
      : this.hasTwitterSelected();

    this.charactersRemaining = socialPostCharactersRemaining(
      processedText,
      maxLength,
      hasTwitter
    );
  }

  getTextWithProcessedLinks(): string {
    if (!this.links.length) {
      return this.text;
    }

    const activeAccount: Account = this.isSplit
      ? this.splitPostAccount
      : this.accounts[0];

    let processedText = this.text;
    let offset = 0;

    this.links.forEach((link) => {
      const linkSuffix = `${link.data.prefix ? link.data.prefix + '_' : ''}${
        link.data.link || 'xxxxx'
      }`;
      const processedUrl = link.data.shorten
        ? `http://${activeAccount.default_vanity_domain}/${linkSuffix}`
        : link.data.url;

      processedText =
        processedText.substring(0, link.indices.start + offset) +
        processedUrl +
        processedText.substring(link.indices.end + offset);

      offset += processedUrl.length - (link.indices.end - link.indices.start);
    });

    return processedText;
  }

  private updateMediaRemaining(): void {
    if (this.instagramAccountsOnly()) {
      if (!this.files.length) {
        this.mediaRestrictions.image.remaining = this.mediaRestrictions.image.max;
        this.mediaRestrictions.video.remaining = this.mediaRestrictions.video.max;
        this.mediaRestrictions.gif.remaining = this.mediaRestrictions.gif.max;
        this.mediaRestrictions.imageStory.remaining = this.mediaRestrictions.imageStory.max;
        this.mediaRestrictions.videoStory.remaining = this.mediaRestrictions.videoStory.max;
        this.mediaRestrictions.reel.remaining = this.mediaRestrictions.reel.max;
        return;
      }

      const hasReel = this.files.some(
        (f) => f.mediaCategory === MediaCategory.Reel
      );
      if (hasReel) {
        this.mediaRestrictions.image.remaining = 0;
        this.mediaRestrictions.video.remaining = 0;
        this.mediaRestrictions.gif.remaining = 0;
        this.mediaRestrictions.imageStory.remaining = 0;
        this.mediaRestrictions.videoStory.remaining = 0;
        this.mediaRestrictions.reel.remaining =
          this.mediaRestrictions.reel.max - this.files.length;
        return;
      }

      const hasStory = this.files.some(
        (f) => f.mediaCategory === MediaCategory.Story
      );
      if (hasStory) {
        this.mediaRestrictions.image.remaining = 0;
        this.mediaRestrictions.video.remaining = 0;
        this.mediaRestrictions.gif.remaining = 0;
        this.mediaRestrictions.reel.remaining = 0;
        this.mediaRestrictions.imageStory.remaining =
          this.mediaRestrictions.imageStory.max - this.files.length;
        this.mediaRestrictions.videoStory.remaining =
          this.mediaRestrictions.imageStory.max - this.files.length;
        return;
      }

      // carousel...
      this.mediaRestrictions.gif.remaining = 0;
      this.mediaRestrictions.imageStory.remaining = 0;
      this.mediaRestrictions.videoStory.remaining = 0;
      this.mediaRestrictions.reel.remaining = 0;
      this.mediaRestrictions.image.remaining =
        this.mediaRestrictions.image.max - this.files.length;
      this.mediaRestrictions.video.remaining =
        this.mediaRestrictions.video.max - this.files.length;

      return;
    }

    // for all other networks (including IG), split or combined...
    if (this.addDmReplyLink) {
      this.mediaRestrictions.image.remaining = 0;
      this.mediaRestrictions.video.remaining = 0;
      this.mediaRestrictions.gif.remaining = 0;
      this.mediaRestrictions.imageStory.remaining = 0;
      this.mediaRestrictions.videoStory.remaining = 0;
      this.mediaRestrictions.reel.remaining = 0;
    } else if (this.videoFiles.length) {
      this.mediaRestrictions.image.remaining = 0;
      this.mediaRestrictions.gif.remaining = 0;
      this.mediaRestrictions.imageStory.remaining = 0;
      this.mediaRestrictions.videoStory.remaining = 0;
      this.mediaRestrictions.reel.remaining = 0;
      this.mediaRestrictions.video.remaining =
        this.mediaRestrictions.video.max - this.files.length;
    } else if (this.imageFiles.length) {
      this.mediaRestrictions.video.remaining = 0;
      this.mediaRestrictions.imageStory.remaining = 0;
      this.mediaRestrictions.videoStory.remaining = 0;
      this.mediaRestrictions.reel.remaining = 0;
      this.mediaRestrictions.gif.remaining =
        this.mediaRestrictions.gif.max - this.files.length;
      this.mediaRestrictions.image.remaining =
        this.mediaRestrictions.image.max - this.files.length;
    } else {
      this.mediaRestrictions.image.remaining = this.mediaRestrictions.image.max;
      this.mediaRestrictions.video.remaining = this.mediaRestrictions.video.max;
      this.mediaRestrictions.gif.remaining = this.mediaRestrictions.gif.max;
      this.mediaRestrictions.imageStory.remaining = this.mediaRestrictions.imageStory.max;
      this.mediaRestrictions.videoStory.remaining = this.mediaRestrictions.videoStory.max;
      this.mediaRestrictions.reel.remaining = this.mediaRestrictions.reel.max;
    }

    this.updateAlbumRequired();
  }

  private updateAlbumRequired() {
    const someAccountsSupportAlbums = oneRecordHasField(
      this.socialNetworks,
      'config.publish.features.album'
    );
    const multipleImagesSupportedForAllAccounts =
      this.mediaRestrictions.image.max >= 2;

    const postHasVideo = this.videoFiles.length;
    this.features.album =
      someAccountsSupportAlbums &&
      multipleImagesSupportedForAllAccounts &&
      !postHasVideo;
  }

  private updateVanityDomains() {
    let postAccounts = this.accounts;
    if (this.isSplit) {
      postAccounts = [this.splitPostAccount];
    }

    const domains = groupBy(postAccounts, 'default_vanity_domain');
    const domainsSorted = Object.entries(domains)
      .map(([domain, accounts]) => {
        return { domain, accounts };
      })
      .sort((itemA: any, itemB: any) => {
        return itemB.accounts.length - itemA.accounts.length;
      });
    const [primary, ...others] = domainsSorted;

    this.vanityDomains = Object.freeze({
      primary,
      others
    }) as any;
  }

  hasVideoAttachedToAnyPost(): boolean {
    return this._allFiles.some((file) => file.type === OutboxFileType.Video);
  }

  hasTwitterSelected(): boolean {
    return this.accounts.some((account) =>
      ['2', '10'].includes(account.account_type_id)
    );
  }

  hasInstagramSelected(): boolean {
    return this.accounts.some((account) =>
      ['7', '12'].includes(account.account_type_id)
    );
  }

  hasYoutubeSelected(): boolean {
    return this.accounts.some((account) =>
      ['6'].includes(account.account_type_id)
    );
  }

  instagramAccountsOnly(): boolean {
    if (this.isSplit) {
      return this.splitPostAccount.isInstagram();
    }

    return this.accounts.every((a) => a.isInstagram());
  }

  metaAccountsOnly(): boolean {
    if (this.isSplit) {
      return this.splitPostAccount.isMeta();
    }

    return this.accounts.every((a) => a.isMeta());
  }

  scheduleFirstCommentToggleVisible(): boolean {
    const enabledForAllAccsSelected = this.accounts.every((a) => {
      return (
        a.isFacebook() || a.isInstagram() || a.isLinkedin() || a.isTwitter()
      );
    });

    if (enabledForAllAccsSelected) {
      return true;
    }

    this.scheduleFirstCommentToggled = false;
    return false;
  }

  onSelectedChoicesChange(cwis: ChoiceWithIndices[]): void {
    // Called when a choice is selected, removed, or if any of the choices' indices change
    if (this.isSplit) {
      this.allMentions = this.allMentions.filter((m) => {
        const index = m.data.account_ids.indexOf(this.splitPostAccount.id);
        if (index > -1) {
          m.data.account_ids.splice(index, 1);

          const cwi = cwis.find(
            (cwi) => cwi.choice.username === m.data.username
          );
          if (cwi) {
            cwi.choice.account_ids = m.data.account_ids;
            return false;
          } else {
            return !!m.data.account_ids.length;
          }
        }

        return true;
      });

      this.allMentions = [
        ...this.allMentions,
        ...cwis.map((cwi) => this.constructMention(cwi.choice, cwi.indices))
      ];
    } else {
      this.allMentions = [
        ...cwis.map((cwi) => this.constructMention(cwi.choice, cwi.indices))
      ];
    }

    // console.log(
    //   'allMentions: ',
    //   JSON.stringify(this.allMentions.map((m) => m.data.account_ids))
    // );
  }

  private constructMention(
    profile: {
      username: string;
      id: string;
      account_ids?: string[];
      account_type_id?: string;
    },
    indices?: {
      start: number;
      end: number;
    }
  ): OutboxPublisherMention {
    if (this.isSplit) {
      Array.isArray(profile.account_ids)
        ? profile.account_ids.push(this.splitPostAccount.id)
        : (profile.account_ids = [this.splitPostAccount.id]);
    } else {
      const allAccountsSingleNetwork = this.accounts.every(
        (a) => a.account_type_id === this.accounts[0].account_type_id
      );
      // when in combine mode and there are accounts from different networks user cannot add new mention,
      // only can update or remove a mention
      const accountIds = allAccountsSingleNetwork
        ? this.accounts.map((a) => a.id)
        : this.accounts
            .filter((a) => a.account_type_id === profile.account_type_id)
            .map((a) => a.id);

      profile.account_ids = accountIds;
    }

    const account = this.getAccountFromId(profile.account_ids[0]);

    profile.account_type_id = account.account_type_id;

    let url = '';
    if (account.isLinkedin()) {
      const idParts = profile.id.split(':');
      url =
        idParts[2] === 'organization'
          ? account.socialNetwork.urlBases.username +
            idParts[idParts.length - 1]
          : '';
    } else {
      url = account.socialNetwork.urlBases.username + profile.username;
    }

    return {
      indices: indices || {
        start: -1,
        end: -1
      },
      cssClass: 'publisher-link-tag-primary',
      data: {
        type: OutboxPublisherHighlightType.Mention,
        username: profile.username,
        id: profile.id,
        account_ids: profile.account_ids,
        account_type_id: profile.account_type_id,
        url: url
      }
    };
  }

  static getMentionLabel(profile: { username: string; id: string }) {
    return `@${profile.username}`;
  }

  private sortSelectableAccounts(selectableAccounts) {
    const accountsByNetworks = selectableAccounts.reduce(
      (networks, account) => {
        if (!networks[account.account_type_name]) {
          networks[account.account_type_name] = [];
        }
        networks[account.account_type_name].push(account);
        return networks;
      },
      {}
    );
    Object.keys(accountsByNetworks).forEach((network) =>
      accountsByNetworks[network].sort((a: Account, b: Account) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      )
    );
    const sortedAccounts = Object.keys(accountsByNetworks)
      .sort()
      .reduce((result, network) => {
        result.push(...accountsByNetworks[network]);
        return result;
      }, []);
    return sortedAccounts;
  }

  fromDraft(draft: Draft, videoGifUrls?: string[]): OutboxPublisher {
    const schedules = [];
    const uniqueAccountIdMessages = [];

    draft.outbox_messages.forEach((m) => {
      if (m.send_at && schedules.indexOf(m.send_at) === -1) {
        // schedules are the same for each account, even if post is split
        // just add all unique send_at dates
        schedules.push(m.send_at);
      }

      const accountIdExists = uniqueAccountIdMessages.some(
        (mssg) => mssg.account_id === m.account_id
      );
      if (!accountIdExists) {
        // assume for now that if there are multiple messages with the same account_id it's because the post is scheduled multiple times - only send_at property is different
        // take only one message with the same account_id (schedules are already extracted above)
        uniqueAccountIdMessages.push(m);
      }
    });

    schedules.forEach((sendAt) => {
      if (new Date(sendAt) > new Date()) {
        this.addSchedule(new Date(sendAt), this);
      }
    });

    this.accounts = uniqueAccountIdMessages.map((m) => {
      return this.getAccountFromId(m.account_id);
    });

    if (uniqueAccountIdMessages[0].campaign_id) {
      // campaign is the same for all accs, whether the post is combined or split
      const campaignsService = appInjector().get(CampaignsService);
      campaignsService
        .getById(uniqueAccountIdMessages[0].campaign_id)
        .then((campaign) => {
          this.campaign = campaign;
        });
    }

    if (Array.isArray(uniqueAccountIdMessages[0].tags)) {
      // tags are the same for all accounts/messages, whether the post is combined or split
      this.tags = uniqueAccountIdMessages[0].tags;
    }

    if (draft.extra.targeting) {
      this.targeting = draft.extra.targeting;
    }

    this.igShareToFeed = draft.extra.igShareToFeed;
    this.scheduleFirstCommentToggled = draft.extra.scheduleFirstCommentToggled;

    uniqueAccountIdMessages
      .filter((m) => !!m.auto_comment)
      .forEach((m) => {
        this.autoCommentByAccountId[m.account_id] = m.auto_comment;
      });

    if (draft.extra.isSplit) {
      this.isSplit = true;

      uniqueAccountIdMessages.forEach((m) => {
        this.splitPostAccount = this.getAccountFromId(m.account_id);

        const { text, links, mentions } = this.deserialiseOutboxPublisherText(
          m.text,
          this.splitPostAccount,
          false
        );

        this.text = text;
        this.deserializeAttachments(m, videoGifUrls);

        this.links.forEach((link) => {
          const deserialisedLink = links.find((l) => l.url === link.data.url);
          if (deserialisedLink) {
            link.data.shorten = deserialisedLink.shorten;
            link.data.utm.enabled = deserialisedLink.utm.enabled;
          } else {
            // No backend link annotation means shortening wasn't enabled
            // (Backend only saves links for which shortening is enabled)
            link.data.shorten = false;
            link.data.utm.enabled = false;
          }
        });

        this.allMentions = [...this.allMentions, ...mentions];
      });

      this.splitPostAccount = this.getAccountFromId(
        draft.extra.splitPostAccountId
      );
    } //
    else {
      this.isSplit = false;

      let deserializedText = '';
      let deserializedLinks = [];
      uniqueAccountIdMessages.forEach((m, i) => {
        const { text, links, mentions } = this.deserialiseOutboxPublisherText(
          m.text,
          this.getAccountFromId(m.account_id),
          false
        );

        deserializedText = text;
        deserializedLinks = links;

        this.allMentions = [...this.allMentions, ...mentions];
      });

      // as text should be the same for all accounts
      this.text = deserializedText;

      // as attachments should be the same for all accounts
      this.deserializeAttachments(uniqueAccountIdMessages[0], videoGifUrls);

      this.links.forEach((link) => {
        const deserialisedLink = deserializedLinks.find(
          (l) => l.url === link.data.url
        );
        if (deserialisedLink) {
          link.data.shorten = deserialisedLink.shorten;
          link.data.utm.enabled = deserialisedLink.utm.enabled;
        } else {
          // No backend link annotation means shortening wasn't enabled
          // (Backend only saves links for which shortening is enabled)
          link.data.shorten = false;
          link.data.utm.enabled = false;
        }
      });
    }

    this.mediaCategory = this.files[0]
      ? this.files[0].mediaCategory
      : MediaCategory.Post;

    return this;
  }

  private getAccountFromId(id: string | number): Account {
    return services.models.get<AccountModel>('account').get(String(id));
  }

  deserialiseOutboxPublisherText(
    xmlString: string,
    account: Account,
    appendUtmParams = true
  ) {
    const parser = new DOMParser();
    // link is a reserved html tag, so replace it with outbox-link instead
    const xmlDoc = parser.parseFromString(
      xmlString
        .replace(/<link/g, '<outbox-link')
        .replace(/<\/link>/g, '</outbox-link>'),
      'text/html'
    );
    let text = htmlDecode(
      unescapeXmlEntities(
        xmlDoc.getElementsByTagName('outbox-text')[0].innerHTML
      )
    );

    const links = [];
    Array.from(xmlDoc.getElementsByTagName('outbox-link')).forEach((link) => {
      function getAttribute(name: string) {
        const value = link.getAttribute(name);
        if (value) {
          return unescapeXmlEntities(value.trim());
        } else {
          return value;
        }
      }

      let unescapedLink = unescapeXmlEntities(link.innerHTML);
      if (appendUtmParams) {
        unescapedLink = addUtmParams(unescapedLink, {
          utm_source: getAttribute('utm_source'),
          utm_medium: getAttribute('utm_medium'),
          utm_campaign: getAttribute('utm_campaign'),
          utm_term: getAttribute('utm_term'),
          utm_content: getAttribute('utm_content')
        });
      }

      text = text.replace(unescapeXmlEntities(link.outerHTML), unescapedLink);

      const utm = {
        source: getAttribute('utm_source'),
        medium: getAttribute('utm_medium'),
        campaign: getAttribute('utm_campaign'),
        term: getAttribute('utm_term'),
        content: getAttribute('utm_content'),
        enabled: false
      };
      utm.enabled = Object.values(utm).some(Boolean);
      links.push({
        url: unescapedLink,
        shorten: getAttribute('shorten') === 'true',
        domain: getAttribute('domain'),
        id: getAttribute('link_id'),
        prefix: getAttribute('prefix'),
        link: getAttribute('link'),
        utm
      });
    });

    const mentions = [];
    Array.from(xmlDoc.getElementsByTagName('mention')).forEach((mentionTag) => {
      const mention = {
        id: '',
        username: '',
        account_ids: [], // will be filled later (this.constructMention method)
        account_type_id: account.account_type_id
      };
      switch (account.account_type_name) {
        case 'LinkedIn':
          mention.id = mentionTag.getAttribute('li');
          mention.username = unescapeXmlEntities(mentionTag.innerHTML).replace(
            '@',
            ''
          );
          break;
        case 'Twitter':
          mention.id = mentionTag.getAttribute('tw');
          mention.username = mentionTag.getAttribute('tw');
          break;
        case 'Facebook':
        case 'Facebook Group':
          mention.id = mentionTag.getAttribute('fb');
          mention.username = mentionTag.getAttribute('fb');
          break;
        default:
          break;
      }

      text = text.replace(
        unescapeXmlEntities(mentionTag.outerHTML),
        unescapeXmlEntities(mentionTag.innerHTML)
      );

      mentions.push(this.constructMention(mention));
    });

    return { text, links, mentions };
  }

  outboxPublisherToOutboxMessage(
    text: string,
    links: OutboxPublisherLink[],
    account: Account,
    sendAt?: Date,
    selectedMentions?: OutboxPublisherMention[],
    files?: OutboxPublisherFile[],
    share?: boolean
  ): OutboxMessage {
    let messageType: OutboxMessageType;

    const attachments: OutboxMessageAttachment[] = [];

    const type = getPublisherType(this);

    if (files.length > 0) {
      if (account.isInstagram() && this.instagramAccountsOnly()) {
        const hasStory = files.some(
          (file) => file.mediaCategory === MediaCategory.Story
        );
        const hasReel = files.some(
          (file) => file.mediaCategory === MediaCategory.Reel
        );

        if (hasStory) {
          if (files.length !== 1) {
            throw new Error(
              'Only 1 image or video allowed when composing an IG story!'
            );
          }
          messageType = OutboxMessageType.InstagramStory;

          if (files[0].type === OutboxFileType.Image) {
            const attachment: OutboxMessageAttachmentImage = {
              type: OutboxMessageAttachmentType.Image,
              url: files[0].url
            };
            if (files[0].id && this.edit) {
              attachment.id = files[0].id;
            }
            attachments.push(attachment);
          } //
          else {
            const attachment: OutboxMessageAttachmentVideo = {
              type: OutboxMessageAttachmentType.Video,
              url: files[0].url
            };
            if (files[0].id && this.edit) {
              attachment.id = files[0].id;
            }
            attachments.push(attachment);
          }
        } //
        else if (hasReel) {
          if (files.length !== 1) {
            throw new Error('Only 1 video allowed if composing an IG Reel!');
          }
          messageType = OutboxMessageType.InstagramReel;

          const attachment: OutboxMessageAttachmentVideo = {
            type: OutboxMessageAttachmentType.Video,
            url: files[0].url
          };
          if (files[0].id && this.edit) {
            attachment.id = files[0].id;
          }
          attachments.push(attachment);
        } //
        else {
          // carousel...
          if (files.length === 1) {
            console.log(
              'IG Carousels (multi_image) should have min 2 files attached, images, videos, or both!'
            );
            if (files[0].type === OutboxFileType.Image) {
              messageType = OutboxMessageType.Picture;
            } else {
              // should never enter this block, as this scenario should be prevented earlier (publisher.component.ts) with a warning popup
              messageType = OutboxMessageType.InstagramReel;
            }
          } else {
            messageType = OutboxMessageType.MultiImage;
          }

          files.forEach((file) => {
            if (file.type === OutboxFileType.Image) {
              const attachment: OutboxMessageAttachmentImage = {
                type: OutboxMessageAttachmentType.Image,
                url: file.url
              };
              if (file.id && this.edit) {
                attachment.id = file.id;
              }
              attachments.push(attachment);
            } //
            else {
              const attachment: OutboxMessageAttachmentVideo = {
                type: OutboxMessageAttachmentType.Video,
                url: file.url
              };
              if (file.id && this.edit) {
                attachment.id = file.id;
              }
              attachments.push(attachment);
            }
          });
        }
      } else {
        const hasVideo = files.some(
          (file) => file.type === OutboxFileType.Video
        );
        if (hasVideo && this.mediaRestrictions.video.max > 0) {
          // is video post
          messageType = OutboxMessageType.Video;

          const titleRequired =
            get(
              account,
              `socialNetwork.mediaRestrictions.${type}.video.title.length.min`
            ) > 0;

          if (titleRequired) {
            const attachment: OutboxMessageAttachmentVideoWithTitle = {
              type: OutboxMessageAttachmentType.VideoWithTitle,
              url: files[0].url,
              title: files[0].video.title
            };
            if (files[0].subtitles) {
              attachment.subtitles = [...files[0].subtitles];
            }
            if (files[0].id && this.edit) {
              attachment.id = files[0].id;
            }

            attachments.push(attachment);
          } else {
            const attachment: OutboxMessageAttachmentVideo = {
              type: OutboxMessageAttachmentType.Video,
              url: files[0].url
            };
            if (files[0].subtitles) {
              attachment.subtitles = [...files[0].subtitles];
            }
            if (files[0].id && this.edit) {
              attachment.id = files[0].id;
            }
            attachments.push(attachment);
          }
        } else if (
          // is album
          files.length > 1 &&
          this.mediaRestrictions.image.max > 0
        ) {
          messageType = OutboxMessageType.MultiImage;
          files.forEach((file) => {
            const attachment: OutboxMessageAttachmentImage = {
              type: OutboxMessageAttachmentType.Image,
              url: file.url,
              alt_text: file.alt_text
            };
            if (file.id && this.edit) {
              attachment.id = file.id;
            }
            attachments.push(attachment);
          });
        } else {
          // is single image post
          const addGifAsVideo =
            files[0].type === OutboxFileType.Gif &&
            get(
              account,
              `socialNetwork.mediaRestrictions.${type}.gif.title.length.min`
            ) > 0;
          let attachment:
            | OutboxMessageAttachmentImage
            | OutboxMessageAttachmentVideoWithTitle;
          if (addGifAsVideo) {
            messageType = OutboxMessageType.Video;
            attachment = {
              type: OutboxMessageAttachmentType.VideoWithTitle,
              url: files[0].url,
              title: files[0].gif.title,
              alt_text: files[0].alt_text
            };
          } else {
            messageType = OutboxMessageType.Picture;
            attachment = {
              type: OutboxMessageAttachmentType.Image,
              url: files[0].url,
              alt_text: files[0].alt_text
            };
          }
          if (files[0].id && this.edit) {
            attachment.id = files[0].id;
          }
          attachments.push(attachment);
        }
      }
    } else if (share) {
      messageType = OutboxMessageType.Share;
    } else {
      messageType = OutboxMessageType.StatusUpdate;
    }

    if (!Array.isArray(links)) {
      throw new Error(
        `value for 'outbox publisher links' is not in expected format.`
      );
    }

    if (this.profileWithLocation && account.isMeta()) {
      const locationTag: OutboxMessageAttachmentLocation = {
        id: this.profileWithLocation.id,
        name: this.profileWithLocation.name,
        type: OutboxMessageAttachmentType.Location,
        external_link: `https://facebook.com/${this.profileWithLocation.id}`
      };

      attachments.push(locationTag);
    }

    const previewLink = links.find((link) => link.data.preview.isSelected);
    if (
      get(account, 'socialNetwork.publish.features.customiseLinkPreview') &&
      previewLink &&
      (messageType === OutboxMessageType.Picture ||
        previewLink.data.preview.images[
          previewLink.data.preview.selectedImageIndex
        ]) &&
      messageType !== OutboxMessageType.MultiImage &&
      messageType !== OutboxMessageType.Video
    ) {
      const linkPreviewAttachment: OutboxMessageAttachmentLinkPreview = {
        type: OutboxMessageAttachmentType.LinkPreview,
        url: normaliseUrl(previewLink.data.url),
        title: previewLink.data.preview.title,
        description: previewLink.data.preview.description
      };
      if (messageType !== OutboxMessageType.Picture) {
        linkPreviewAttachment.image =
          previewLink.data.preview.images[
            previewLink.data.preview.selectedImageIndex
          ].url;
      }
      attachments.push(linkPreviewAttachment);
    }

    const linkRequired = get(
      account,
      `socialNetwork.mediaRestrictions.${type}.image.linkRequired`
    );
    const hasImageAttached = attachments.some(
      (attachment) => attachment.type === OutboxMessageAttachmentType.Image
    );
    const hasNoLinkAttached = !attachments.some(
      (attachment) =>
        attachment.type === OutboxMessageAttachmentType.LinkPreview
    );

    if (linkRequired && hasImageAttached && hasNoLinkAttached) {
      attachments.push({
        type: OutboxMessageAttachmentType.LinkPreview,
        url: normaliseUrl(links[links.length - 1].data.url)
      });
    }

    if (this.quoteRetweetUrl) {
      attachments.push({
        type: OutboxMessageAttachmentType.QuoteRetweet,
        url: this.quoteRetweetUrl
      });
    }

    if (this.shareId) {
      attachments.push({
        type: OutboxMessageAttachmentType.FacebookShare,
        post_id: this.shareId
      });
    }

    const message: OutboxMessage = {
      type: '@OutboxMessage',
      message_type: messageType,
      account_id: +account.id,
      campaign_id: this.campaign ? +this.campaign.id : null,
      text: serialiseOutboxPublisherText(
        text,
        links,
        account,
        this.campaign,
        selectedMentions
      ),
      requires_validation: this.requiresValidation,
      attachments,
      delete_at: this.delete_at,
      tags: this.tags
    };

    if (
      messageType === OutboxMessageType.Video &&
      account.account_type_id === AccountTypeIdString.YouTube
    ) {
      message.youtube_visibility = this.youtubeVisibility;
    }

    if (
      messageType === OutboxMessageType.InstagramReel &&
      account.account_type_id === AccountTypeIdString.Instagram
    ) {
      message.instagram_share_to_feed = this.igShareToFeed;
    }

    const auoComment = this.autoCommentByAccountId[account.id];
    if (auoComment) {
      message.auto_comment = [
        {
          text: auoComment.text,
          image: auoComment.image
        }
      ];
    }

    if (this.edit) {
      message.id = this.edit.id;
    }

    if (sendAt) {
      message.send_at = format(sendAt, 'YYYY-MM-DDTHH:mm:ssZ');
    }

    if (this.targeting[account.account_type_name]) {
      if (objHasAnyValueInProps(this.targeting[account.account_type_name])) {
        message.targeting = this.targeting[account.account_type_name];
      }
    }

    // if (
    //   account.socialNetwork.accountTypeGroupName === 'instagram' &&
    //   account.default_instagram_publisher &&
    //   !this.reply &&
    //   !this.privateMessage
    // ) {
    //   message.push_notification_user_id = +account.default_instagram_publisher;
    // }

    if (this.privateMessage) {
      if (this.privateMessage.socialId) {
        message.recipient_social_id = this.privateMessage.socialId;
      } else {
        message.reply_to_activity_id = this.privateMessage.activityId;
      }

      switch (message.message_type) {
        case OutboxMessageType.StatusUpdate:
          message.message_type = OutboxMessageType.PrivateMessage;
          break;

        default:
          throw new Error(
            `Cannot send a private message of type "${message.message_type}"`
          );
      }
    }

    if (this.reply) {
      if (this.reply.socialId) {
        message.reply_to_social_id = this.reply.socialId;
      } else {
        message.reply_to_activity_id = this.reply.activityId;
      }

      message.reply_exclude_users = this.replyExcludeUsers;

      switch (message.message_type) {
        case OutboxMessageType.StatusUpdate:
          message.message_type = OutboxMessageType.Reply;
          break;

        case OutboxMessageType.Picture:
          message.message_type = OutboxMessageType.ReplyPicture;
          break;

        case OutboxMessageType.Album:
          message.message_type = OutboxMessageType.ReplyAlbum;
          break;

        case OutboxMessageType.Video:
          message.message_type = OutboxMessageType.ReplyVideo;
          break;

        case OutboxMessageType.MultiImage:
          message.message_type = OutboxMessageType.ReplyAlbum;
          break;

        default:
          throw new Error('Cannot reply to a private message!');
      }

      if (
        this.addDmReplyLink &&
        this.accounts[0].socialNetwork.addPrivateReplyLinkAsAttachment
      ) {
        attachments.push({
          type: OutboxMessageAttachmentType.DirectMessageReplyLink
        });
      }
    }

    return message;
  }
}
