import './toggle-switch-2.component.scss';
import {
  Input,
  Component,
  Renderer2,
  forwardRef,
  OnInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ssi-toggle-switch-2',
  templateUrl: './toggle-switch-2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitch2Component),
      multi: true
    }
  ]
})
export class ToggleSwitch2Component implements OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() label = '';

  @ViewChild('inputRef') inputRef: ElementRef;

  id = uuidv4();
  modelValue: boolean;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  private onChangeCallback: (_: any) => void = () => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  private onTouchCallback: () => void = () => {};
  registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  writeValue(value: boolean) {
    this.modelValue = value;
  }

  inputChange(event: any): void {
    this.modelValue = !this.modelValue;
    this.onChangeCallback(this.modelValue);
  }
}
