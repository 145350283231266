import './cl-confirmation-modal.component.scss';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationModalComponent } from '../../../../common/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'ssi-cl-confirmation-modal',
  templateUrl: './cl-confirmation-modal.component.html',
  styles: []
})
export class ClConfirmationModalComponent implements OnInit {
  @ViewChild('addlFooterTemplate') addlFooterTemplate: ElementRef;
  doNotAskAgainChecked = false;

  constructor(protected modal: NgbModal) {}

  ngOnInit() {}

  async openModalA(): Promise<void> {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
    confirmationModal.componentInstance.title = 'Attach to all?';
    confirmationModal.componentInstance.info = `Would you like to attach this image to all your split posts?`;
    confirmationModal.componentInstance.cancelButton = 'No thanks';
    confirmationModal.componentInstance.confirmButton = `Yes please`;

    const result = await confirmationModal.result;
    console.log('result: ', result);
  }

  async openModalB(): Promise<void> {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
    confirmationModal.componentInstance.title = 'Attach to all?';
    confirmationModal.componentInstance.info = `Would you like to attach this image to all your split posts?`;
    confirmationModal.componentInstance.cancelButton = 'No thanks';
    confirmationModal.componentInstance.confirmButton = `Yes please`;

    confirmationModal.componentInstance.footerTemplate = this.addlFooterTemplate;

    const result = await confirmationModal.result;
    console.log('result: ', result);
    console.log('DO NOT ASK AGAIN: ', this.doNotAskAgainChecked);
  }
}
