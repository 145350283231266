import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingNotPurchasedComponent } from './advertising-not-purchased.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.notPurchased',
          url: '/not-purchased',
          component: AdvertisingNotPurchasedComponent
        }
      ]
    })
  ],
  declarations: [AdvertisingNotPurchasedComponent],
  exports: [AdvertisingNotPurchasedComponent]
})
export class AdvertisingNotPurchasedModule {}
