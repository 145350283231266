<div class="publisher-schedule-time-picker-inner">
  <div class="publisher-schedule-time-picker-footer">
    <div class="publisher-schedule-time-picker-footer-date-wrap">
      <button
        mwlCalendarPreviousView
        view="day"
        [(viewDate)]="scheduleDate"
        (click)="updateChart()">
        <i class="ssi ssi-chevron-left"></i>
      </button>
      <div class="publisher-schedule-time-picker-footer-date">
        {{ scheduleDate | date:'d MMMM yyyy' }}
      </div>
      <button
        mwlCalendarNextView
        view="day"
        [(viewDate)]="scheduleDate"
        (click)="updateChart()">
        <i class="ssi ssi-chevron-right"></i>
      </button>
    </div>
    <div class="publisher-schedule-time-picker-footer-timepicker-wrap">
      <ngb-timepicker
        [(ngModel)]="time"
        [meridian]="true"
        (ngModelChange)="updateChart()">
      </ngb-timepicker>
    </div>
    <div class="publisher-schedule-btn-wrap">
      <button
        class="btn btn-success btn-rounded schedule-btn schedule-btn-green"
        (click)="addToSchedule()">
        Add schedule
      </button>
    </div>
  </div>
  <ssi-highchart class="g-hide-if-mobile" [config]="topPostTimesChart" (highchartsLoaded)="delayedUpdateChart()"></ssi-highchart>
</div>