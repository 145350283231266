import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [CommonModule, FormsModule, FlatpickrModule, OrloComponentsModule],
  declarations: [ConfirmationModalComponent],
  exports: [ConfirmationModalComponent],
  providers: []
})
export class ConfirmationModalModule {}
