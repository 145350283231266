<div class="modal-header">
  <button
    type="button"
    class="close"
    (click)="modalInstance.dismiss()"
  >×</button>
  <h4
    class="modal-title"
    translate="NEW_RELEASE_AVAILABLE"
  ></h4>
</div>
<div
  class="modal-body"
  translate="_A_NEW_RELEASE_OF_THE_APP_IS_NOW_AVAILABLE_CLICK_THE_REFRESH_AND_UPDATE_BUTTON_TO_RELOAD_THE_PAGE_AND_UPDATE_YOUR_DASHBOARD"
>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="modalInstance.dismiss()"
    translate="CANCEL"
  >
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="modalInstance.close()"
    translate="REFRESH_AND_UPDATE_APP"
  >
  </button>
</div>