<div
  class="panel panel-default"
  *ngFor="let account of filteredAccounts"
>
  <div class="panel-heading">
    <div class="row">
      <div class="col-md-8">
        <h4>
          <i class="{{ account.info?.socialNetwork.icon.web }}"></i>

          {{ account.info?.name || account.username }}

          <small *ngIf="account.info?.username">
            (@{{ account.info.username }})
          </small>

          <span *ngIf="account.account_type_id === 1">
            -
            <span translate="COMPANY_PERMISSIONS"></span>
          </span>
        </h4>
      </div>

      <div class="col-md-4 text-right">
        <h4 [hidden]="account.account_type_id === 1 && disableCompanyPermissions">
          <div [hidden]="anyPermissionEnabled(permissions[account.id])">
            <button
              class="btn btn-success"
              (click)="toggleAllPermissions(account.id, true)"
            >
              <i class="fa fa-check"></i>

              <span translate="ENABLE_ALL"></span>
            </button>

            <i
              class="fa fa-eye-slash pull-right fa-2x"
              ssiTooltip="{{ 'THE_USER_WONT_BE_ABLE_TO_SEE_THIS_ACCOUNT_WHEN_THEY_LOGIN' | translate }}"
              [tooltipAppendToBody]="true"
            ></i>
          </div>

          <div *ngIf="anyPermissionEnabled(permissions[account.id])">
            <button
              class="btn btn-danger"
              (click)="toggleAllPermissions(account.id, false)"
            >
              <i class="fa fa-times"></i>

              <span translate="DISABLE_ALL"></span>
            </button>

            <i
              class="fa fa-eye pull-right fa-2x"
              ssiTooltip="{{ 'THE_USER_WILL_BE_ABLE_TO_SEE_THIS_ACCOUNT_WHEN_THEY_LOGIN' | translate }}"
              [tooltipAppendToBody]="true"
            ></i>
          </div>
        </h4>
      </div>
    </div>
  </div>

  <div class="panel-body">
    <table class="table table-striped">
      <tbody>
        <tr *ngFor="let permission of account.permissions">
          <td translate="{{ permission.name.toUpperCase() | replace:'-':'' }}"></td>

          <td translate="{{ 'PERMISSION_' + (permission.name.toUpperCase() | replace:'-':'') + '_DESCRIPTION' }}"></td>
          <td>
            <button
              class="btn btn-sm btn-success pull-right"
              (click)="togglePermission(account.id, permission.name, false);"
              *ngIf="isEnabled(account, permission) && !(account.account_type_id === 1 && disableCompanyPermissions)"
            >
              <i class="fa fa-check"></i>

              <span translate="ENABLED"></span>
            </button>

            <button
              class="btn btn-sm btn-danger pull-right"
              (click)="togglePermission(account.id, permission.name, true);"
              *ngIf="!isEnabled(account, permission)"
            >
              <i class="fa fa-check"></i>

              <span translate="DISABLED"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>