import './campaign-add-box.component.scss';
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { Campaign } from '@ui-resources-angular';
import { CampaignModelService } from '../../../auth/marketing/index/campaign-model/campaign-model.service';

@Component({
  selector: 'ssi-campaign-add-box',
  templateUrl: './campaign-add-box.component.html',
  styles: []
})
export class CampaignAddBoxComponent implements OnInit {
  @Input() newCampaign: any;
  @Input() addCampaignActive: any;
  @Input() campaigns: Campaign[];
  @Input() activeCampaignId: string;
  @ViewChild('startDate') startDate;
  @ViewChild('EndDate') EndDate;
  @Output() toggleAddCampaignDropdown: EventEmitter<any> = new EventEmitter();
  startDateElement;
  EndDateElement;

  now = new Date();

  constructor(private campaignModelService: CampaignModelService) {}

  ngOnInit() {
    this.startDateElement = this.startDate.nativeElement;
    this.EndDateElement = this.EndDate.nativeElement;
    this.formValid();
  }

  public addCampaign() {
    if (this.newCampaign.parent_id !== this.activeCampaignId) {
      this.campaignModelService.currentActiveCampaign = this.newCampaign.parent_id;
    }
    this.campaignModelService.addCampaign(this.newCampaign);
    this.resetForm();
    this.toggleAddCampaignDropdown.emit();
  }

  formValid() {
    if (this.newCampaign.name && this.newCampaign.started_at) {
      return false;
    }
    return true;
  }

  resetForm() {
    this.newCampaign.name = '';
    this.newCampaign.started_at = '';
    this.newCampaign.closed_at = '';
  }
}
