import './cl-dropdown-select-h.component.scss';

import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { groupBy } from '../../../../../common/utils';
import { Option } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.component';

@Component({
  selector: 'ssi-cl-dropdown-select-h',
  templateUrl: './cl-dropdown-select-h.component.html',
  styles: []
})
export class ClDropdownSelectHComponent implements OnInit {
  options: { [key: string]: Option } = {
    private: {
      key: 'private',
      label: 'Private',
      icon: 'ssi-broken-example-2',
      description:
        'Private: Only you and people you choose can watch your video'
    },
    unlisted: {
      key: 'unlisted',
      label: 'Unlisted',
      icon: 'ssi-unlisted',
      description: 'Unlisted: Anyone with the video link can watch your video'
    },
    public: {
      key: 'public',
      label: 'Public',
      icon: 'ssi-everyone',
      description:
        'Public: Everyone can watch your video, with no need to grant access or a link'
    }
  };
  optionsIterable: Option[] = Object.values(this.options);
  selectedOption: Option = this.options.private;

  constructor() {}

  ngOnInit() {}

  selectedOptionChanged(selectedOption: Option): void {
    // this.selectedOption = selectedOption;
  }
}
