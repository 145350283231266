import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaNavComponent } from './media-nav.component';
import { EnlargeImageModule } from '../../directives/enlarge-image/enlarge-image.module';

@NgModule({
  imports: [CommonModule, EnlargeImageModule],
  declarations: [MediaNavComponent],
  exports: [MediaNavComponent]
})
export class MediaNavModule {}
