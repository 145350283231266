import { Directive, ElementRef, InjectionToken } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `KtdGridDragHandleDirective`. It serves as
 * alternative token to the actual `KtdGridDragHandleDirective` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const KTD_GRID_DRAG_HANDLE = new InjectionToken<KtdGridDragHandleDirective>(
  'KtdGridDragHandleDirective'
);

/** Handle that can be used to drag a KtdGridItem instance. */
@Directive({
  selector: '[ssiKtdGridDragHandle]',
  host: {
    class: 'ktd-grid-drag-handle'
  },
  providers: [
    { provide: KTD_GRID_DRAG_HANDLE, useExisting: KtdGridDragHandleDirective }
  ]
})
// tslint:disable-next-line:directive-class-suffix
export class KtdGridDragHandleDirective {
  constructor(public element: ElementRef<HTMLElement>) {}
}
