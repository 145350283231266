<form #form="ngForm">
  <div class="form-row">
    <div class="form-field field-group wider">
      <div class="form-field">
        <label translate="START_DATE"></label>
        <div
          class="input-wrapper"
          #startDateContainer
        >
          <input
            mwlFlatpickr
            class="date-picker-input"
            type="text"
            name="startDate"
            [ngModel]="startDate"
            (ngModelChange)="onStartDatePicked($event)"
            [altInput]="true"
            [convertModelValue]="true"
            dateFormat="Y-m-d H:i"
            altFormat="M J Y, H:i"
            [maxDate]="endDate"
            [time24hr]="true"
            [appendTo]="startDateContainer.nativeElement"
            placeholder="Select..."
            [required]="true"
            (flatpickrOpen)="onFlatpickrOpen()"
          >
        </div>
      </div>
      <div class="form-field date-picker">
        <label translate="END_DATE"></label>
        <div
          class="input-wrapper"
          #endDateContainer
        >
          <input
            mwlFlatpickr
            class="date-picker-input"
            type="text"
            name="endDate"
            [ngModel]="endDate"
            (ngModelChange)="onEndDatePicked($event)"
            [altInput]="true"
            [convertModelValue]="true"
            dateFormat="Y-m-d H:i"
            altFormat="M J Y, H:i"
            [minDate]="startDate"
            [maxDate]="today"
            [time24hr]="true"
            [appendTo]="endDateContainer.nativeElement"
            placeholder="Select..."
            [required]="true"
            (flatpickrOpen)="onFlatpickrOpen()"
          >
        </div>
      </div>
    </div>
    <div class="form-field">
      <label translate="FEEDBACK"></label>
      <ssi-dropdown-select-2
        headButtonStyleVariant="analytics"
        [options]="feedbackOptions"
        name="feedbackOptions"
        [multiple]="true"
        [(ngModel)]="selectedFeedback"
      >
        <ng-template
          ssiTemplateSelector="headButtonLabel"
          let-expanded="expanded"
        >
          <div
            class="select-accounts-placeholder"
            *ngIf="!selectedFeedback.length"
          >
            <span>Select feedback...</span>
          </div>
          <div
            class="select-accounts-label"
            *ngIf="selectedFeedback?.length === feedbackOptions?.length"
          >
            <span>All options</span>
          </div>
          <div
            class="select-accounts-label"
            *ngIf="selectedFeedback?.length && selectedFeedback?.length !== feedbackOptions?.length"
          >
            <span>{{selectedFeedback.length}} selected</span>
          </div>
        </ng-template>
      </ssi-dropdown-select-2>
    </div>

    <div class="form-field">
      <div class="form-field">
        <label>Handover</label>
        <div class="input-wrapper">
          <ssi-dropdown-select-2
            name="handoverOptions"
            class="accounts-selector"
            headButtonStyleVariant="analytics"
            [required]="true"
            [multiple]="true"
            [toggleAllLabel]="'Select all options'"
            [options]="handoverOptions"
            [(ngModel)]="selectedHandover"
          >
            <ng-template
              ssiTemplateSelector="headButtonLabel"
              let-expanded="expanded"
            >
              <div
                class="select-accounts-placeholder"
                *ngIf="!selectedHandover.length"
              >
                <span>Select handover...</span>
              </div>
              <div
                class="select-accounts-label"
                *ngIf="selectedHandover.length === handoverOptions.length"
              >
                <span>All options</span>
              </div>
              <div
                class="select-accounts-label"
                *ngIf="selectedHandover?.length && selectedHandover?.length !== handoverOptions?.length"
              >
                <span>{{selectedHandover.length}} selected</span>
              </div>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
      </div>
    </div>

    <div class="form-field">
      <label>Confidence</label>
      <div class="input-range">
        <input
          class="number-input"
          type="number"
          name="confidenceMin"
          min="0"
          step="0.1"
          [(ngModel)]="confidenceMin"
        >
        <input
          class="number-input"
          type="number"
          name="confidenceMax"
          max="1"
          step="0.1"
          [(ngModel)]="confidenceMax"
        >
      </div>
    </div>
  </div>


  <div class="form-row">
    <div class="form-field search-field wider">
      <input
        type="text"
        name="searchTerm"
        [(ngModel)]="searchTerm"
        placeholder="Search by query text"
      >
    </div>
    <div class="form-field">
      <div class="form-field">
        <label>Topics</label>
        <div class="input-wrapper">
          <ssi-dropdown-select-2
            name="topics"
            class="accounts-selector"
            headButtonStyleVariant="analytics"
            [required]="true"
            [multiple]="true"
            [filterable]="true"
            [filterPlaceholder]="'Search for a topic...'"
            [toggleAllLabel]="'Select all topics'"
            [options]="topicOptions"
            [(ngModel)]="selectedTopics"
            [disabled]="topicOptions?.length === 0"
          >
            <ng-template
              ssiTemplateSelector="headButtonLabel"
              let-expanded="expanded"
            >
              <div
                class="select-accounts-placeholder"
                *ngIf="!selectedTopics.length"
              >
                <span>Select topics...</span>
              </div>
              <div
                class="select-accounts-label"
                *ngIf="selectedTopics?.length === topicOptions?.length"
              >
                <span>All options</span>
              </div>
              <div
                class="select-accounts-label"
                *ngIf="selectedTopics?.length && selectedTopics?.length !== topicOptions?.length"
              >
                <span>{{selectedTopics.length}} selected</span>
              </div>
            </ng-template>
            <ng-template
              ssiTemplateSelector="optionLabel"
              let-topic="option"
            >
              <div class="name-count-dropdown">
                <span>{{topic.name}}</span>
                <span>{{topic.count}}</span>
              </div>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
      </div>
    </div>
    <div class="form-field">
      <div class="form-field">
        <label>URLs</label>
        <div class="input-wrapper">
          <ssi-dropdown-select-2
            name="urls"
            class="accounts-selector"
            headButtonStyleVariant="analytics"
            [required]="true"
            [multiple]="true"
            [filterable]="true"
            [filterPlaceholder]="'Search for an URL...'"
            [toggleAllLabel]="'Select all URLs'"
            [options]="urlOptions"
            [(ngModel)]="selectedUrls"
            [disabled]="urlOptions?.length === 0"
          >
            <ng-template
              ssiTemplateSelector="headButtonLabel"
              let-expanded="expanded"
            >
              <div
                class="select-accounts-placeholder"
                *ngIf="!selectedUrls.length"
              >
                <span>Select URLs...</span>
              </div>
              <div
                class="select-accounts-label"
                *ngIf="selectedUrls?.length === urlOptions?.length"
              >
                <span>All options</span>
              </div>
              <div
                class="select-accounts-label"
                *ngIf="selectedUrls?.length && selectedUrls?.length !== urlOptions?.length"
              >
                <span>{{selectedUrls?.length}} selected</span>
              </div>
            </ng-template>
            <ng-template
              ssiTemplateSelector="optionLabel"
              let-url="option"
            >
              <div class="name-count-dropdown">
                <span>{{url.id}}</span>
                <span>{{url.count}}</span>
              </div>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
      </div>
    </div>
    <div class="form-field">
      <div class="form-field">
        <label>Widgets</label>
        <div class="input-wrapper">
          <ssi-dropdown-select-2
            name="widgets"
            class="accounts-selector"
            headButtonStyleVariant="analytics"
            [required]="true"
            [multiple]="true"
            [selectedMin]="1"
            [filterable]="true"
            [filterPlaceholder]="'Search for a widget...'"
            [toggleAllLabel]="'Select all widgets'"
            [options]="widgetOptions"
            [(ngModel)]="selectedWidgets"
          >
            <ng-template
              ssiTemplateSelector="headButtonLabel"
              let-expanded="expanded"
            >
              <div
                class="select-accounts-placeholder"
                *ngIf="!selectedWidgets.length"
              >
                <span>Select widgets...</span>
              </div>
              <div
                class="select-accounts-label"
                *ngIf="selectedWidgets.length"
              >
                <span>{{selectedWidgets.length}} selected</span>
              </div>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
      </div>
    </div>
  </div>
</form>

<div
  class="table-container"
  *ngIf="reportData && reportData.rows"
>
  <ssi-treetable
    [data]="filteredTopics"
    [bodyStyle]="{'max-height': ''}"
    [rowStyle]="{'min-height': '68px'}"
    [virtualScroll]="false"
    [resizeMode]="'fit'"
    [reorderable]="true"
    [saveSettings]="false"
    [settingsStorageKey]="'treetable-chatbot-topic-report'"
    [globalFilterInputRef]=""
    id="treetable-chatbot-topic-report"
  >
    <ssi-column
      [header]="'Query text'"
      [sortable]="false"
      field="query"
      [templateRefs]="{bodyCell: nameCellTemplate}"
    >
    </ssi-column>
    <ssi-column
      [header]="'Matched Intent'"
      [sortable]="false"
      field="topic"
      [templateRefs]="{bodyCell: nameCellTemplate}"
    ></ssi-column>
    <ssi-column
      header="Confidence"
      [sortable]="false"
      field="confidence"
      [templateRefs]="{bodyCell: nameCellTemplate}"
    ></ssi-column>
    <ssi-column
      header="Feedback"
      [sortable]="false"
      field="feedback"
      [templateRefs]="{bodyCell: nameCellTemplate}"
    ></ssi-column>
    <ssi-column
      header="Handover"
      [sortable]="false"
      field="handover"
      [templateRefs]="{bodyCell: nameCellTemplate}"
    ></ssi-column>
    <ssi-column
      header="Count"
      [sortable]="false"
      field="count"
      [templateRefs]="{bodyCell: nameCellTemplate}"
    ></ssi-column>

    <ssi-pagination
      [itemsPerPage]="10"
      [pageLinksSize]="5"
    ></ssi-pagination>
  </ssi-treetable>

  <ng-template
    #nameCellTemplate
    ssiTemplateSelector="bodyCell"
    let-rowIndex="rowIndex"
    let-rowData="rowData"
    let-fieldValue="fieldValue"
  >
    <div
      class="table-cell chatbot-name"
      [class.row-even]="rowIndex % 2 !== 0"
    >
      <ng-container>
        <span>{{fieldValue}}</span>
      </ng-container>
    </div>
  </ng-template>
</div>