import './message-split-panel.component.scss';
import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-message-split-panel',
  templateUrl: './message-split-panel.component.html'
})
export class MessageSplitPanelComponent {
  @Output() cancel = new EventEmitter<any>();
  @Output() splitConversationConfirmed = new EventEmitter<any>();

  constructor() {}
}
