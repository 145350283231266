import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmProfileSentimentBarComponent } from './crm-profile-sentiment-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, TranslateModule, TooltipModule],
  declarations: [CrmProfileSentimentBarComponent],
  exports: [CrmProfileSentimentBarComponent]
})
export class CrmProfileSentimentBarModule {}
