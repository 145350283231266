import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NotesModalComponent } from './notes-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { SanitizedHtmlModule } from '../../../../../../../common/pipes/sanitized/sanitized.module';
import { NotesModule } from './../notes/notes.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule,
    FormsModule,
    MentionModule,
    SanitizedHtmlModule,
    NotesModule
  ],
  declarations: [NotesModalComponent],
  exports: [NotesModalComponent],
  entryComponents: [NotesModalComponent]
})
export class NotesModalModule {}
