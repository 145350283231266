import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { EmbedWidgetComponent } from './embed-widget.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.accountsettings.livechatwidgets.embed',
          url: '/embed',
          component: EmbedWidgetComponent,
          params: {
            widget: ''
          }
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [EmbedWidgetComponent],
  entryComponents: [EmbedWidgetComponent]
})
export class EmbedWidgetModule {}
