import './entry.component.scss';
import { Component } from '@angular/core';

@Component({
  selector: 'ssi-entry',
  templateUrl: './entry.component.html'
})
export class EntryComponent {
  constructor() {}
}
