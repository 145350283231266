import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { AccountAuthService } from '../../../../../common/services/account-auth/account-auth.service';

@Component({
  selector: 'ssi-auth-popup',
  templateUrl: './auth-popup.component.html'
})
export class AuthPopupComponent implements OnInit {
  constructor(
    private transition: Transition,
    private accountAuth: AccountAuthService
  ) {}

  async ngOnInit(): Promise<boolean> {
    try {
      const { id } = this.transition.params();

      const data = await this.accountAuth.fetchAuthenticatedSessionParams(id);

      if (!(!!window && !!window.opener)) {
        throw new Error(
          `No window or window opener available to post a message.`
        );
      }

      window.opener.postMessage({ uuid: data.uuid }, location.origin);

      location.assign(data.authorization_url);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}
