import './post-box.component.scss';

import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  SimpleChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
// import { Outbox } from '@ui-resources-angular';
import { PostModalComponent } from '../post-modal/post-modal.component';

export interface PostStatus {
  conditional: boolean;
  class: string;
  label: string;
  icon: string;
  link?: string;
  linkParams?: {};
}

@Component({
  selector: 'ssi-post-box',
  templateUrl: './post-box.component.html',
  styles: []
})
export class PostBoxComponent implements OnInit, OnChanges, AfterViewInit {
  // @Input() post: Outbox;
  @Input() post: any;
  @Input() view: 'day' | 'week' | 'month' | 'dayMobile' = 'week';
  @Input() isModalView = false;
  @Input() isMobile = false;
  @ViewChild('buttonActions') buttonActionsElm: ElementRef;
  @ViewChild('buttonActionsLabels') buttonActionsLabelsElm: ElementRef;

  showPostBoxPreview = false;
  buttonActionsIcons: boolean;
  postStatuses: PostStatus[];

  constructor(protected modal: NgbModal, private state: StateService) {}

  ngOnInit(): void {
    this.postStatuses = this._getPostStatuses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.post) {
      this.resizeButtonActions();
    }
  }

  ngAfterViewInit(): void {
    this.resizeButtonActions();
  }

  private _getPostStatuses(): PostStatus[] {
    return [
      {
        conditional:
          this.post.social_id &&
          this.post.is_validated &&
          !this.post.deleted_at,
        class: 'live-post',
        label: 'Live',
        icon: 'ssi ssi-live'
      },
      {
        conditional:
          !this.post.is_validated &&
          !this.post.rejected_at &&
          !this.post.deleted_at,
        class: 'approval-post',
        label: 'In approval',
        icon: 'ssi ssi-request-approval '
      },
      {
        conditional:
          this.post.send_at && !this.post.sent_at && !this.post.deleted_at,
        class: 'scheduled-post',
        label: 'Scheduled',
        icon: 'ssi ssi-schedule'
      },
      {
        conditional:
          (this.post.in_reply_to_social_id || this.post.is_thread_parent) &&
          !this.post.deleted_at,
        class: 'thread-post',
        label: 'View Thread',
        icon: 'ssi ssi-thread',
        link: 'auth.twitterThreads',
        linkParams: { outboxId: this.post.id }
      },
      {
        conditional:
          this.post.social_id &&
          !!this.post.account.socialNetwork.outbox.threadReplies &&
          !(this.post.in_reply_to_social_id || this.post.is_thread_parent) &&
          !this.post.deleted_at,
        class: 'create-thread-post',
        label: 'Create Thread',
        icon: 'ssi ssi-add-small',
        link: 'auth.twitterThreads',
        linkParams: { outboxId: this.post.id }
      },
      {
        conditional: this.post.last_attempt_at && !this.post.social_id,
        class: 'failed-post',
        label: 'Failed',
        icon: 'ssi ssi-failed-post'
      },
      {
        conditional: this.post.deleted_at,
        class: 'deleted-post',
        label: 'Deleted',
        icon: 'ssi ssi-delete'
      },
      {
        conditional: this.post.rejected_at,
        class: 'deleted-post',
        label: 'Disapproved',
        icon: 'ssi ssi-delete-close'
      },
      {
        conditional: this.post.is_draft && this.post.is_draft !== '0',
        class: 'draft-post',
        label: 'Draft Post',
        icon: 'ssi ssi-drafts'
      }
    ];
  }

  public resizeButtonActions() {
    if (this.view !== 'week') {
      return;
    }
    setTimeout(() => {
      this.buttonActionsIcons =
        this.buttonActionsElm.nativeElement.clientWidth <=
        this.buttonActionsLabelsElm.nativeElement.clientWidth;
    });
  }

  createThread() {
    console.log('Create thread!');
    this.onPostClicked();
  }

  public async onPostClicked() {
    const modal = await this.modal.open(PostModalComponent, {
      windowClass: 'orlo-modal orlo-modal-1000',
      backdropClass: 'orlo-modal-backdrop'
    });
    modal.componentInstance.post = this.post;
    modal.result.finally(() => {
      this.state.go(
        'auth.marketing.contentCalendar',
        { outboxId: null },
        { reload: false }
      );
    });
  }
}
