import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy
} from '@angular/core';
import { ListSelectComponent } from '../list-select.component';

const ICON_DEFAULT = 'fa-circle icon-selected';

@Component({
  selector: 'ssi-list-select-option',
  templateUrl: './list-select-option.component.html'
})
export class ListSelectOptionComponent implements OnDestroy {
  @Input() value: any;

  @Input() iconSelected: string = ICON_DEFAULT;

  @Input() iconNotSelected: string = ICON_DEFAULT;

  isSelected: boolean;

  constructor(
    @Inject(forwardRef(() => ListSelectComponent))
    public listSelect: ListSelectComponent,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.listSelect.options = this.listSelect.options.filter(
      (option) => option !== this
    );
  }
}
