import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { API } from '@ui-resources-angular';
import axios from 'axios';
import { APP_VERSION } from '../../../../angular/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewVersionModalComponent } from '../../components/navbar/new-version-modal/new-version-modal.component';
import { async } from '@angular/core/testing';
import { PopupService } from '../popup/popup.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UpdateManagerService {
  public initialisedPlugins = {};
  latestKnownVersion = APP_VERSION;
  fiveMinutesInSeconds = 1000 * 5 * 60;
  newVersionAvailable$ = new Subject();

  constructor(private modal: NgbModal) {}

  initialise() {
    const intervalTrigger = timer(
      this.fiveMinutesInSeconds,
      this.fiveMinutesInSeconds
    );
    intervalTrigger.subscribe(() => this.checkForUpdates());
  }

  async checkForUpdates() {
    const appVersionEndpoint = `${window.location.origin}/app/web/config.json`;
    axios.interceptors.request.use(null, () => {
      return;
    });
    const { data: config } = await axios.get(
      `${appVersionEndpoint}?time='${Date.now()}`
    );
    const latestVersion = config.latestVersion;
    if (latestVersion !== this.latestKnownVersion) {
      this.latestKnownVersion = latestVersion;
      this.newVersionAvailable$.next();
    }
  }

  async showNewVersionModal() {
    const modal = this.modal.open(NewVersionModalComponent, {
      size: 'sm'
    });
    modal.result
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        return;
      });
  }
}
