import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ActivityTags } from '@ui-resources-angular';

import { Message } from '../../../../../../library/models/live-chat/message';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

@Component({
  selector: 'ssi-live-chat-message-tags',
  templateUrl: './live-chat-message-tags.component.html',
  styles: []
})
export class LiveChatMessageTagsComponent implements OnChanges, OnInit {
  @Input() areActiveTagsVisible = true;
  @Input() areAvailableTagsVisible = true;
  @Input() shouldIgnoreHotkeys = false;
  @Input() message: Message;
  @Output() messageUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('tagsFilter') tagsFilter: ElementRef;

  private _activeTags: string[];
  private _allTags: string[];
  private _indexOfSelection = -1;
  private _isInitialised = false;
  private _filter = '';
  private _filteredTags: string[];

  constructor(
    private activityTags: ActivityTags,
    private liveChatService: LiveChatService
  ) {}

  public get activeTags() {
    return this._activeTags;
  }

  public get allTags() {
    return this._allTags;
  }

  public get filterPattern(): RegExp {
    return new RegExp('^[a-z0-9ä-ÿ]+$');
  }

  public get filteredTags() {
    return this._filteredTags;
  }

  public get indexOfSelection() {
    return this._indexOfSelection;
  }

  public isActiveTag(selection: string): boolean {
    if (!(!!this.activeTags && Array.isArray(this.activeTags))) {
      return false;
    }

    return !!this.activeTags.find((tag) => tag === selection);
  }

  public get isInitialised(): boolean {
    return !!this._isInitialised;
  }

  public isSelectedTagIndex(index: number) {
    return this.indexOfSelection === index;
  }

  async ngOnChanges(changes) {
    if (!!changes.message) {
      const hasPreviousVersion =
        !!changes.message.previousValue && !!changes.message.previousValue.id;
      const currentVersion: Message = changes.message.currentValue as Message;

      // changed the open message

      if (!!currentVersion) {
        if (hasPreviousVersion) {
          const previousVersion: Message = changes.message
            .previousValue as Message;

          if (previousVersion.id !== currentVersion.id) {
            // save any changes

            if (this.activeTags !== previousVersion.tags) {
              await this.updateMessage(this.activeTags, previousVersion);
            }
          }
        }
      }

      // refresh the UI

      this._activeTags = Object.assign(
        [],
        !!currentVersion ? currentVersion.tags : []
      );
    }
  }

  async ngOnInit() {
    try {
      const tags = await this.activityTags.getTags();
      this._allTags = tags;

      this.updateFilter();

      const input = this.tagsFilter.nativeElement as HTMLInputElement;
      requestAnimationFrame(() => input.focus());
    } catch (error) {
      console.error(error);
    }
  }

  async onClose() {
    event.stopImmediatePropagation();
    event.preventDefault();

    await this.updateMessage();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!!this.shouldIgnoreHotkeys) {
      return;
    }

    const input = this.tagsFilter.nativeElement;
    if (document.activeElement !== input) {
      return;
    }

    switch (event.key) {
      case 'ArrowDown':
        if (this.indexOfSelection < this.filteredTags.length - 1) {
          this.select(this._indexOfSelection + 1);
        }

        return;

      case 'ArrowUp':
        if (this.indexOfSelection > 0) {
          this.select(this._indexOfSelection - 1);
        }

        return;

      case 'Backspace':
        if (!!this._filter.length) {
          this._filter = this._filter.slice(0, -1);
          this.updateFilter();
        }

        return;

      case 'Enter':
        if (this.indexOfSelection > -1) {
          this.toggle(event, this.filteredTags[this.indexOfSelection]);
        }

        return;

      case 'Alt':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'CapsLock':
      case 'Control':
      case 'Ctrl':
      case 'Dead':
      case 'Delete':
      case 'End':
      case 'Escape':
      case 'F1':
      case 'F2':
      case 'F3':
      case 'F4':
      case 'F5':
      case 'F6':
      case 'F7':
      case 'F8':
      case 'F9':
      case 'F10':
      case 'F11':
      case 'F12':
      case 'F13':
      case 'F14':
      case 'F15':
      case 'F16':
      case 'Home':
      case 'Meta':
      case 'PageDown':
      case 'PageUp':
      case 'Shift':
      case 'Tab':
        return;

      default:
        // @todo: better trapping of non-alphnumeric characters
        if (!!this.filterPattern.test(event.key.toLocaleLowerCase())) {
          this.updateFilter(event.key);
        }

        return true;
    }
  }

  select(index: number) {
    this._indexOfSelection = index;
  }

  toggle(event: Event, selection: string): void {
    event.stopImmediatePropagation();

    if (this.activeTags.indexOf(selection) >= 0) {
      this._activeTags = this.activeTags.filter((tag) => tag !== selection);
    } else {
      this._activeTags.push(selection);
    }
  }

  updateFilter(character?: string) {
    if (!!character) {
      this._filter += character.toLocaleLowerCase();
    }

    this._filteredTags = !this._filter.length
      ? this.allTags
      : this.allTags.filter(
          (tag) =>
            tag
              .toLocaleLowerCase()
              .indexOf(this._filter.toLocaleLowerCase()) !== -1
        );

    this._indexOfSelection = -1;
  }

  async updateMessage(
    tags: string[] = this._activeTags,
    message: Message = this.message
  ) {
    this.messageUpdated.emit(true);
    await this.liveChatService.updateTagsForMessage(tags, message);
  }
}
