import './brand-sentiment.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Options } from 'highcharts';
import Highcharts from 'highcharts';

import { Account } from '@ui-resources-angular';
import { HighchartsHelperService } from '../../../../../../common/services/highcharts-helper/highcharts-helper.service';
import {
  Sentiment,
  Sentiments,
  sentiments,
  sentimentsIterable
} from '../../../../../../common/constants';

import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';
import {
  DateRanges,
  SentimentTotals,
  TimeSeriesStats,
  TotalsStats
} from '../../marketing.service';

@Component({
  selector: 'ssi-brand-sentiment',
  templateUrl: './brand-sentiment.component.html',
  styles: []
})
export class BrandSentimentComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() dateRanges: DateRanges;
  @Input() stats: TotalsStats;

  currentSentiments: SentimentTotals;
  previousSentiments?: SentimentTotals;
  currentSentimentsTotal: number;

  chartConfig: Options;

  sentimentsConst: Sentiments = sentiments;
  sentimentsConstIterable: Sentiment[] = sentimentsIterable;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  constructor(
    protected translate: TranslateService,
    protected highchartsHelper: HighchartsHelperService
  ) {}

  ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return this.accounts.some((a) => a.account_type_id === n.key);
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);
    this.selectedNetwork = this.selectableNetworks[0];

    this.setSentimentTotals();
    this.chartConfig = this.getChartConfig();
  }

  onNetworkSelect(sn: NetworkSelectItem): void {
    this.selectedNetwork = sn;

    this.setSentimentTotals();
    this.chartConfig = this.getChartConfig();
  }

  setSentimentTotals(): void {
    if (this.selectedNetwork === networkSelectItems[0]) {
      this.currentSentiments = this.stats.allTotals.current.sentiments;
      this.previousSentiments =
        this.stats.allTotals.previous &&
        this.stats.allTotals.previous.sentiments;

      this.currentSentimentsTotal = Object.values(
        this.currentSentiments
      ).reduce((a, b) => a + b, 0);
    } else {
      const totalsByAccountType = this.stats.totalsByAccountType[
        this.selectedNetwork.account_type_name
      ];

      this.currentSentiments = totalsByAccountType.current.sentiments;
      this.previousSentiments =
        totalsByAccountType.previous && totalsByAccountType.previous.sentiments;

      this.currentSentimentsTotal = Object.values(
        this.currentSentiments
      ).reduce((a, b) => a + b, 0);
    }
  }

  getChartConfig(): any {
    const chartData = Object.entries(this.currentSentiments).map((keyValue) => {
      return {
        name: `<div class="highchart-legend-inline">${(
          (keyValue[1] / this.currentSentimentsTotal) *
          100
        ).toFixed(1)}% </div>  |  <span>${this.translate.instant(
          this.sentimentsConst[keyValue[0]].label
        )}</span>`,
        y: keyValue[1]
      };
    });

    const colours = this.sentimentsConstIterable.map((s) => s.color);

    Highcharts.setOptions({
      colors: colours
    });
    return {
      chart: {
        type: 'pie',
        backgroundColor: null,
        spacing: [70, 70, 70, 70]
      },
      credits: {
        enabled: false
      },
      legend: {
        maxHeight: 300,
        width: 180,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginBottom: 30,
        x: -20,
        useHTML: true,
        itemStyle: {
          fontSize: '14px',
          fontWeight: '900',
          color: '#101525',
          fontFamily: 'Lato, Arial, sans-serif'
        }
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      accessibility: {
        description: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [
        {
          name: 'Messages',
          colorByPoint: true,
          minPointSize: 10,
          innerSize: '60%',
          zMin: 0,
          data: chartData
        }
      ],
      loading: false
    };
  }
}
