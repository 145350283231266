import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsToHumanTimePipe } from './seconds-to-human-time.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SecondsToHumanTimePipe],
  exports: [SecondsToHumanTimePipe]
})
export class SecondsToHumanTimeModule {}
