import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { MarketingComponent } from './marketing.component';
import { AlertsModule } from '../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContentCalendarModule } from './content-calendar/content-calendar.module';
import { TriggerPublishModule } from '../../../common/directives/trigger-publish/trigger-publish.module';
import { BulkUploadModule } from './bulk-upload/bulk-upload.module';
import { DraftsLibraryModule } from './drafts-library/drafts-library.module';
import { ContentGeneratorModule } from './content-generator/content-generator.module';
import { ContentLibraryModule } from './content-library/content-library.module';
import { ValidatePostsModule } from './validate-posts/validate-posts.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { OutboxSearchModule } from './outbox-search/outbox-search.module';
import { IndexModule } from './index/index.module';
import { AdvertisingModule } from './advertising/advertising.module';
import { FormsModule } from '@angular/forms';
import { AddSecondsModule } from '../../../common/pipes/add-seconds/add-seconds.module';
import { flatpickrFactory } from '../../../common/components/publisher/publisher.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CampaignBoxModule } from './index/campaign-box/campaign-box.module';
import { CampaignAddBoxModule } from './campaign-add-box/campaign-add-box.module';
import { ValidateRepliesModule } from './validate-replies/validate-replies.module';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    TranslateModule,
    TriggerPublishModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing',
          url: '/marketing',
          component: MarketingComponent,
          resolve: MarketingComponent.resolve,
          abstract: true
        }
      ]
    }),
    FlatpickrModule.forRoot(),
    ContentCalendarModule,
    BulkUploadModule,
    DraftsLibraryModule,
    ContentGeneratorModule,
    ContentLibraryModule,
    ValidatePostsModule,
    AnalyticsModule,
    OutboxSearchModule,
    IndexModule,
    AdvertisingModule,
    FormsModule,
    AddSecondsModule,
    CampaignBoxModule,
    CampaignAddBoxModule,
    ValidateRepliesModule
  ],
  declarations: [MarketingComponent]
})
export class MarketingModule {}
