<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1 translate="PROFANITY_FILTER"></h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>

  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-minus-circle"></i> <span translate="PROFANITY_FILTER"></span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <span translate="PROFANITIES_CAN_BE_ANY_WORD_THAT_YOU_DEEM_INAPPROPRIATE_TO_GO_OUT_THROUGH_ANY_OF_YOUR_SOCIAL_ACCOUNTS_IF_A_USER_TRIES_TO_USE_ONE_OF_THESE_WORDS_THE_ADMINISTRATORS_WILL_BE_ASKED_TO_CHECK_IT"></span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <form (ngSubmit)="saveFacebookSettings()" novalidate>
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              <i class="fa fa-facebook"></i> <span translate="FACEBOOK_WALL_MONITOR"></span>
            </h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <div class="control-group">
                <select [(ngModel)]="blockingWordSettings.delete_offensive_posts" name="deleteOffensivePosts" class="form-control">
                  <option value="2" translate="HIDE_ONLY"></option>
                  <option value="1" translate="HIDE_OR_DELETE"></option>
                  <option value="0" translate="OFF"></option>
                </select>
              </div>
            </div>
            <p [innerHTML]="'SELECT_STRONGHIDE_ONLYSTRONG_TO_ATTEMPT_TO_HIDE_POSTS_THAT_CONTAIN_PROFANITIES_IF_A_POST_CANNOT_BE_HIDDEN_IT_WILL_STILL_BE_VISIBLE_SELECT_STRONGHIDE_OR_DELETESTRONG_TO_ATTEMPT_TO_HIDE_POSTS_THAT_CONTAIN_PROFANITIES_IF_A_POST_CANNOT_BE_HIDDEN_IT_WILL_BE_DELETED' | translate"></p>
          </div>
          <div class="panel-footer text-right">
            <button type="submit" class="btn btn-success" [disabled]="loadingTracker.active">
              <i class="fa fa-floppy-o"></i> <span translate="SAVE"></span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading panel-heading-btn">
          <h3 class="panel-title">
            <i class="fa fa-font"></i> <span translate="WORD_LIST"></span>
          </h3>
          <button
            class="btn btn-primary"
            (click)="loadDefaultBlockingWords()"
            [disabled]="loadingTracker.active">
            <i class="fa fa-spin fa-spinner" *ngIf="loadingTracker.active"></i>
            <span translate="LOAD_DEFAULT_WORDS"></span>
          </button>
          <div class="clearfix"></div>
        </div>
        <div class="panel-body">
          <form class="input-group" #newWordForm="ngForm">
            <input type="text" [(ngModel)]="newWord" name="newWord" class="form-control" required>
            <span class="input-group-btn">
              <button
                class="btn btn-success"
                [disabled]="!newWordForm.valid || loadingTracker.active"
                (click)="addBlockingWord(newWord)">
                <i class="fa fa-plus"></i> <span translate="ADD_WORD"></span>
              </button>
            </span>
          </form>
          <hr>
          <input
            placeholder="{{ 'SEARCH' | translate }}"
            type="text"
            [(ngModel)]="wordSearchText"
            (ngModelChange)="filterBlockingWords()"
            class="form-control blocking-word-input">
          <div ssiAutoHeight [additionalHeight]="100" class="blocking-word-list">
            <div *ngFor="let word of displayedBlockingWords | sortBy: 'word'">
              <a
                class="fa fa-times"
                (click)="removeBlockingWord(word)"
                [ssiTooltip]="'REMOVE_WORD' | translate"
                [tooltipAppendToBody]="true"
                tooltipPlacement="right">
              </a> - {{ word.word }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
