import './edit.component.scss';

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User, UserModel } from '@ui-resources-angular';
import {
  AddWidgetComponent,
  GridLayoutItem,
  WidgetsGridComponent
} from '../common/components';
import {
  DashboardService,
  DashboardWidget,
  Dashboard
} from '../../../../common/services/api/dashboard';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { DashboardWidgetModalComponent } from '../../../../common/components/dashboard-widget-modal/dashboard-widget-modal.component';
import { TooltipDirective } from '../../../../common/directives/tooltip/tooltip.directive';
import { Filter } from '../../../../common/components/filters';

@Component({
  selector: 'ssi-edit',
  templateUrl: './edit.component.html',
  styles: []
})
export class EditComponent implements OnInit, OnDestroy {
  hasWidgets = false;
  dashboard: Dashboard;
  activeChanges = false;
  dashboardTemplates: Dashboard[] = [];
  selectedDashboardTemplate: Dashboard;
  authUser: User;

  protected destroyed$ = new Subject<void>();

  @ViewChild('widgetsGrid') widgetsGrid: WidgetsGridComponent;
  @ViewChild('cancelChangesTooltip') cancelChangesTooltip: TooltipDirective;
  @ViewChild('editTooltip') editTooltip: TooltipDirective;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected state: StateService,
    protected modal: NgbModal,
    protected popup: PopupService,
    protected userModel: UserModel,
    protected dashboardService: DashboardService,
    protected notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.userModel.getAuthUser().then((user) => {
      this.authUser = user;
    });

    this.dashboardService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dashboards) => {
        console.log('subscribe dashboards: ', dashboards);
        this.dashboard = dashboards.find(
          (dashboard) => dashboard.id === this.state.params.id
        );
      });

    this.dashboardService.templatesStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((templates) => {
        console.log('subscribe dashboard tempaltes: ', templates);
        this.dashboardTemplates = templates;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  applyTemplate(template: Dashboard): void {
    this.dashboard = { ...template, id: this.dashboard.id }; // keep id
    this.selectedDashboardTemplate = template;
  }

  async openAddWidgetModal() {
    const widgetWalletModal = this.modal.open(AddWidgetComponent, {
      windowClass: 'xxl-modal'
    });

    widgetWalletModal.componentInstance.widgetAdded.subscribe(
      async (widget: DashboardWidget) => {
        const modal = this.modal.open(DashboardWidgetModalComponent, {
          windowClass: 'xxl-modal'
        });
        modal.componentInstance.widget = widget;

        const widgetToAdd = await modal.result;

        if (widgetToAdd) {
          this.hasWidgets = true;
          this.cdRef.detectChanges();
          this.widgetsGrid.addNewWidget(widgetToAdd);
          console.log('widget added: ', widgetToAdd);

          this.activeChanges =
            JSON.stringify(
              this.widgetsGrid.toApiWidgets(this.widgetsGrid.widgets)
            ) === JSON.stringify(this.dashboard.widgets)
              ? false
              : true;
          // modal.close();
          widgetWalletModal.close();
        }
      }
    );
  }

  async editWidget(widget: GridLayoutItem) {
    const modal = this.modal.open(DashboardWidgetModalComponent, {
      windowClass: 'xxl-modal'
    });
    modal.componentInstance.edit = true;
    modal.componentInstance.widget = widget.apiData;

    this.widgetsGrid.updateWidget(widget, await modal.result);
    this.widgetsGrid.reRenderWidgets(); // so new config and filters are applied
  }

  async save(updateTemplate = false): Promise<void> {
    const widgetsToSave = this.widgetsGrid.toApiWidgets(
      this.widgetsGrid.widgets
    );
    // widgetsToSave.forEach((w) => {
    //   // strip props not need saving
    //   delete w.typeConst;
    // });
    this.dashboard.widgets = widgetsToSave;

    if (updateTemplate) {
      this.selectedDashboardTemplate.name = this.dashboard.name;
      this.selectedDashboardTemplate.widgets = widgetsToSave;
      this.dashboardService
        .updateTemplate(this.selectedDashboardTemplate)
        .then((updatedTemplate) => {
          console.log('updated template: ', updatedTemplate);
          this.notificationService.open(
            `Dashboard template has been updated, success!`,
            {
              class: 'ssi ssi-completed-notification',
              color: '#B2C614'
            },
            2000
          );
        })
        .catch(() => {
          this.notificationService.open(
            `There was an error saving your dashboard template!`,
            {
              class: 'ssi ssi-small-delete',
              color: '#F88C68'
            },
            2000
          );
        });
    }

    this.dashboardService
      .update(this.dashboard)
      .then((updatedDashboard) => {
        console.log('updated dashboard: ', updatedDashboard);
        this.notificationService.open(
          `Your dashboard has been saved, success!`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          2000
        );

        this.state.go('auth.dashboard.view', {
          id: this.dashboard.id
        });
      })
      .catch(() => {
        this.notificationService.open(
          `There was an error updating your dashboard!`,
          {
            class: 'ssi ssi-small-delete',
            color: '#F88C68'
          },
          2000
        );
      });
  }

  async saveAsTemplate(): Promise<void> {
    const widgetsToSave = this.widgetsGrid.toApiWidgets(
      this.widgetsGrid.widgets
    );
    // widgetsToSave.forEach((w) => {
    //   // strip props not need saving
    //   delete w.typeConst;
    // });
    this.dashboard.widgets = widgetsToSave;

    this.dashboardService
      .createTemplate(this.dashboard)
      .then((createdTemplate) => {
        console.log('created template: ', createdTemplate);
        this.selectedDashboardTemplate = createdTemplate;
        this.notificationService.open(
          `Dashboard template has been saved, success!`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          2000
        );
      })
      .catch(() => {
        this.notificationService.open(
          `There was an error saving your dashboard template!`,
          {
            class: 'ssi ssi-small-delete',
            color: '#F88C68'
          },
          2000
        );
      });
  }

  async delete() {
    const shouldDelete = await this.popup.confirm({
      title: 'Delete custom dashboard?',
      message: `You cannot undo this action`,
      okText: 'Delete dashboard',
      windowClass: 'modal-rounded-corners',
      hideClose: true,
      iconClass: 'ssi ssi-delete-microcopy',
      backdrop: true
    });

    if (shouldDelete) {
      this.dashboardService.delete(this.dashboard).then(() => {
        this.notificationService.open(
          `Your dashboard has been successfully deleted!`,
          {
            class: 'ssi ssi-small-delete',
            color: '#F88C68'
          },
          2000
        );
        this.state.go('auth.dashboard', null, { reload: true });
      });
    }
  }

  cancel(): void {
    window.history.go(-1);
  }

  async deleteTemplate(template: Dashboard): Promise<void> {
    const shouldDelete = await this.popup.confirm({
      title: `Delete '${template.name}' dashboard template?`,
      message: `You cannot undo this action`,
      okText: 'Delete template',
      windowClass: 'modal-rounded-corners',
      hideClose: true,
      iconClass: 'ssi ssi-delete-microcopy',
      backdrop: true
    });

    if (shouldDelete) {
      await this.dashboardService.deleteTemplate(template);
      if (this.selectedDashboardTemplate === template) {
        this.selectedDashboardTemplate = undefined;
      }
      this.notificationService.open(
        `Dashboard template has been successfully deleted!`,
        {
          class: 'ssi ssi-small-delete',
          color: '#F88C68'
        },
        2000
      );
    }
  }
}
