import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';

import { ViewComponent } from './view.component';
import { CommonModule as DashboardCommonModule } from '../common/common.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardCommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.dashboard.view',
          url: '/view/:id',
          component: ViewComponent
        }
      ]
    })
  ],
  declarations: [ViewComponent]
})
export class ViewModule {}
