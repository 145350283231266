<div
  class="scroll-y outbox-post-list-responsive"
  [ngStyle]="{maxHeight: maxHeight ? maxHeight + 'px' : 'auto'}"
  ssiAutoHeight
  [additionalHeight]="20"
  [autoHeightDisabled]="disableAutoHeight"
  infiniteScroll
  [infiniteScrollThrottle]="100"
  [infiniteScrollDistance]="2"
  [infiniteScrollDisabled]="overrideScrollDisable || (outboxQuery.loading?.more.active || (outboxQuery.interactionTotals && outboxQuery.outboxPosts?.length >= outboxQuery.interactionTotals.total))"
  (scrolled)="onScrolled()"
  [scrollWindow]="false"
>
  <div
    class="loading"
    *ngIf="outboxQuery.loading?.initial.active"
  ></div>
  <div [hidden]="outboxQuery.loading?.initial.active || outboxQuery.outboxPosts?.length > 0">
    <ng-content select="[no-results]"></ng-content>
  </div>

  <div *ngIf="outboxQuery.outboxPosts?.length > 0 && !outboxQuery.loading?.initial.active">
    <div>
      <div *ngFor="let post of outboxQuery.outboxPosts | unique:'id'">
        <ssi-outbox-post
          [post]="post"
          [campaigns]="campaigns"
          [showValidation]="showValidation"
          [isSelectable]="isSelectable"
          [isSelected]="isPostSelected(post)"
          [shouldRefreshPublisher]="shouldRefreshPublisher"
          (onSelectToggle)="onSelectToggle.emit({isSelected: $event.isSelected, post: post})"
          (closeModal)="closeModal.emit()"
        >
        </ssi-outbox-post>
      </div>
      <h5 *ngIf="outboxQuery.interactionTotals"
        [hidden]="outboxQuery.outboxPosts?.length === 0 || outboxQuery.loading?.initial.active"
        class="text-center"
        translate="SHOWING__FILTEREDAMOUNT__OF__TOTALRESULTS_"
        [translateParams]="{filteredAmount: outboxQuery.outboxPosts?.length, totalResults: outboxQuery.interactionTotals.total}"
      >
      </h5>
      <div
        class="loading"
        *ngIf="outboxQuery.loading?.more.active"
      ></div>
    </div>
  </div>
</div>