import {
  NgModule,
  Pipe,
  PipeTransform,
  ModuleWithProviders,
  Injector
} from '@angular/core';
import { DataStore } from 'js-data';
import axios from 'axios';
import dateDifferenceFilter from '../../modules/core/filters/dateDifference';
import replaceFilter from '../../modules/core/filters/replace';
import secondsToHumanTimeFilter from '../../modules/core/filters/secondsToHumanTime';
import { Video } from '../../modules/core/services/video';
import { api, API } from '../../modules/core/services/api';
import { services } from '../../modules/common';
import { Record } from '../record';
import { accountModelFactory } from '../../modules/account/services/accountModel';
import { workflowModelFactory } from '../../modules/account/services/workflowModel';
import { activityModelFactory } from '../../modules/activity/services/activityModel';
import { campaignModelFactory } from '../../modules/campaign/services/campaignModel';
import { noteModelFactory } from '../../modules/note/services/noteModel';
import { profileModelFactory } from '../../modules/profile/services/profileModel';
import { outboxModelFactory } from '../../modules/publish/services/outboxModel';
import { userModelFactory } from '../../modules/user/services/userModel';
import { crmPersonModelFactory } from '../../modules/crm/services/crmPersonModel';
import { crmExternalIntegrationModelFactory } from '../../modules/crm/services/crmExternalIntegrationModel';
import { vanityDomainModelFactory } from '../../modules/account/services/vanityDomainModel';
import { conversationModelFactory } from '../../modules/activity/services/conversationModel';

@Pipe({
  name: 'dateDifference'
})
export class DateDifference implements PipeTransform {
  transform(earlierDate: Date | string, laterDate: Date | string): string {
    return dateDifferenceFilter(earlierDate, laterDate);
  }
}

@Pipe({
  name: 'replace'
})
export class Replace implements PipeTransform {
  transform(input: string, find: string, replace: string): string {
    return replaceFilter(input, find, replace);
  }
}

@Pipe({
  name: 'secondsToHumanTime'
})
export class SecondsToHumanTime implements PipeTransform {
  transform(seconds: number, isLong: boolean, altRound?: boolean): string {
    return secondsToHumanTimeFilter(seconds, isLong, altRound);
  }
}

export function dataStoreFactory(injector: Injector): any {
  const store = new DataStore();

  api.http = axios;

  store.registerAdapter('http', api, { default: true });

  services.store = store;

  services.models.reset();

  // bit of a hack, will be better once we completely get rid of angularjs and can go back to using angulars DI
  Record.prototype['injector'] = injector;

  // weird hack required to get tests to pass on the webapp when mocking models on previous runs
  accountModelFactory();
  workflowModelFactory();
  activityModelFactory();
  campaignModelFactory();
  noteModelFactory();
  profileModelFactory();
  outboxModelFactory();
  userModelFactory();
  crmPersonModelFactory();
  crmExternalIntegrationModelFactory();
  vanityDomainModelFactory();
  conversationModelFactory();

  return store;
}

export function apiFactory(): any {
  api.http = axios;
  return api;
}

@NgModule({
  declarations: [DateDifference, Replace, SecondsToHumanTime],
  exports: [DateDifference, Replace, SecondsToHumanTime]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        Video,
        {
          provide: API,
          useFactory: apiFactory
        },
        {
          provide: DataStore,
          useFactory: dataStoreFactory,
          deps: [Injector]
        }
      ]
    };
  }
}
