<div class="manage-ifttt-applet-accounts manage-ifttt-applet-stage">

  <div>
    <div class="manage-ifttt-applet-stage-header">
      <h2 class="manage-ifttt-applet-stage-header-title">{{ service.translationIds.title | translate }}</h2>
      <p class="manage-ifttt-applet-stage-header-sub-title">Now select the account(s) you would like to add triggers to</p>
    </div>

    <div class="manage-ifttt-applet-stage-body">

      <div class="manage-ifttt-applet-stage-body-inner">
        <input
          type="text"
          class="form-control form-control-minimal form-control-text-lg font-weight-normal"
          placeholder="{{ 'SEARCH_NAME' | translate }}"
          [(ngModel)]="searchText"
          (change)="filterAccounts()"
          #searchInput>
        <i class="fa fa-search text-primary form-control-icon"></i>

        <div class="text-right spacer-top font-weight-normal">
          <small>
            <em>
              <a href="javascript:;" (click)="selectAllAccounts();">Select all</a>
              |
              <a href="javascript:;" (click)="deSelectAllAccounts()">Deselect all</a>
            </em>
          </small>
        </div>

        <div class="scroll-vertical spacer-top">
          <div class="text-center text-danger font-weight-normal" *ngIf="filteredAccounts.length === 0">
            <em>No accounts found</em>
          </div>
          <ssi-list-select
            class="options-light options-lg option-circles-hidden"
            [(ngModel)]="applet.accounts"
            [multiple]="true">
            <ssi-list-select-option
              *ngFor="let account of filteredAccounts; trackBy:trackById"
              [value]="account">
              {{ account.displayName }}
              <span class="list-select-option-icon-inner">
                <i
                  class="fa-fw"
                  [ngClass]="account.socialNetwork.icon.web">
                </i>
              </span>
            </ssi-list-select-option>
          </ssi-list-select>
        </div>

        <br>

        <div class="text-center">
          <button
            class="btn btn-primary btn-stage-next"
            uiSref="^.name"
            [disabled]="applet.accounts.length === 0">
            Save
          </button>
        </div>
      </div>

    </div>
  </div>

</div>