import { Component, Input, OnInit } from '@angular/core';
import { Account, Outbox, OutboxModel, User } from '@ui-resources-angular';
import { Transition, StateService } from '@uirouter/angular';
import { trackByProperty } from '../../../../../common/util';
import {
  advertisingAccounts as advertisingAccountsResolve,
  advertisingTargetingPresets,
  authUser
} from '../../../../common-resolves';
import {
  AdvertisingPreset,
  TargetingOption
} from '../advertising-preset-model/advertising-preset-model.service';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { TruncatePipe } from '../../../../../common/pipes/truncate/truncate.pipe';
import { AdvertModelService } from '../advert-model/advert-model.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import {
  CompanyConfig,
  CompanyService
} from '../../../../../common/services/company/company.service';
import {
  AdvertisingTargetingService,
  BillingEvent,
  BudgetSuggestion,
  serialiseTargetingOptions
} from '../advertising-targeting/advertising-targeting.service';
import { Currency } from '../../../../../common/services/server/server.service';

export function outboxPostsFn(
  transition: Transition,
  outboxModel: OutboxModel
) {
  const params = transition.params();
  const outboxIds =
    transition.to().name === 'auth.marketing.advertising.boostPost'
      ? [params.outboxId]
      : params.outboxIds;
  return Promise.all(
    outboxIds.map((id) => outboxModel.get(id) || outboxModel.findAll({ id }))
  );
}

export function companyCurrencyFn(company: CompanyService) {
  return company.getCurrency();
}

interface AdvertLanguage {
  label: string;
  locale: string;
  country: string;
  isDefault?: boolean;
}

interface AdvertForm {
  outboxPost: Outbox;
  advertisingAccounts: Account[];
  targetingPresets: AdvertisingPreset[];
  account?: any;
  start?: Date;
  end?: Date;
  targetingOptions?: TargetingOption[];
  preset?: AdvertisingPreset;
  _preset: AdvertisingPreset;
  name: string;
  budget: {
    daily: string;
    total: string;
  };
  bidding: {
    amount: string;
    event: BillingEvent;
  };
  requiresValidation: boolean;
  language: AdvertLanguage;
  specifiedEndDate?: boolean;
  specialAdCategory?: string | undefined;
}

const DEFAULT_AD_SPEND = '0.00';

@Component({
  selector: 'ssi-advertising-boost-post',
  templateUrl: './advertising-boost-post.component.html'
})
export class AdvertisingBoostPostComponent implements OnInit {
  static resolve = [
    authUser,
    advertisingAccountsResolve,
    advertisingTargetingPresets,
    {
      token: 'outboxPosts',
      resolveFn: outboxPostsFn,
      deps: [Transition, OutboxModel]
    },
    {
      token: 'companyCurrency',
      resolveFn: companyCurrencyFn,
      deps: [CompanyService]
    }
  ];

  @Input() advertisingAccounts: Account[];

  @Input() outboxPosts: Outbox[];

  @Input() advertisingTargetingPresets: AdvertisingPreset[];

  @Input() authUser: User;

  @Input() companyCurrency: Currency;

  accountPresets: AdvertisingPreset[] = [];

  trackById = trackByProperty('id');

  now = new Date();

  null = null;

  loadingTracker = this.asyncTracker.create();

  adverts: AdvertForm[];

  activeAdvert: AdvertForm;

  canCreateAdUnvalidated: boolean;

  advertLanguages: AdvertLanguage[] = [
    { label: 'Bahasa Indonesia', locale: 'in', country: 'ID' },
    { label: 'Čeština', locale: 'cs', country: 'CZ' },
    { label: 'Dansk', locale: 'da', country: 'DK' },
    { label: 'Nederlands', locale: 'nl', country: 'NL' },
    { label: 'English', locale: 'en', country: 'US', isDefault: true },
    { label: 'Français', locale: 'fr', country: 'FR' },
    { label: 'Deutsch', locale: 'de', country: 'DE' },
    { label: 'Italiano', locale: 'it', country: 'IT' },
    { label: '日本語', locale: 'ja', country: 'JP' },
    { label: '한국어', locale: 'ko', country: 'KR' },
    { label: 'Bahasa Malaysia', locale: 'ms', country: 'MY' },
    { label: 'Norsk', locale: 'no', country: 'NO' },
    { label: 'Polski', locale: 'pl', country: 'PL' },
    { label: 'Português', locale: 'pt', country: 'BR' },
    { label: 'Română', locale: 'ro', country: 'RO' },
    { label: 'Русский', locale: 'ru', country: 'RU' },
    { label: 'Español', locale: 'es', country: 'ES' },
    { label: 'Svenska', locale: 'sv', country: 'SE' },
    { label: 'Türkçe', locale: 'tr', country: 'TR' }
  ];

  currencySymbols = {
    USD: '$',
    EUR: '€',
    CRC: '₡',
    GBP: '£',
    ILS: '₪',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    NGN: '₦',
    PHP: '₱',
    PLN: 'zł',
    PYG: '₲',
    THB: '฿',
    UAH: '₴',
    VND: '₫'
  };

  budgetSuggestion: BudgetSuggestion;

  constructor(
    private popup: PopupService,
    private state: StateService,
    private targeting: AdvertisingTargetingService,
    private advertModel: AdvertModelService,
    private asyncTracker: AsyncTrackerFactory
  ) {}

  ngOnInit() {
    try {
      if (!(!!this.advertLanguages && Array.isArray(this.advertLanguages))) {
        throw new Error(
          `Value for 'advertising boost post advert languages' not in expected format.`
        );
      }

      this.adverts = this.outboxPosts.map((outboxPost) => {
        const advertisingAccounts = this.advertisingAccounts.filter((account) =>
          account.can_promote.includes(outboxPost.account.id)
        );

        if (advertisingAccounts.length === 0) {
          this.popup.alert({
            title: 'No advertising accounts',
            message:
              "We couldn't find the ad account that's linked to the account of the post you're trying to boost. In order to boost a post, it must have at least one advertising account."
          });
          this.state.go('auth.marketing.advertising.index');
        }

        const advert = {
          outboxPost,
          advertisingAccounts,
          account: null,
          name: '',
          _preset: null,
          set preset(preset: AdvertisingPreset) {
            this.targetingOptions = preset ? preset.targeting_options : [];
            this._preset = preset;
          },
          get preset() {
            return this._preset;
          },
          budget: {
            daily: DEFAULT_AD_SPEND,
            total: DEFAULT_AD_SPEND
          },
          bidding: {
            amount: DEFAULT_AD_SPEND,
            event:
              advertisingAccounts[0].account_type_id === '9'
                ? BillingEvent.CPM
                : BillingEvent.CPC
          },
          targetingPresets: [],
          requiresValidation: false,
          language: this.advertLanguages.find((lang) => lang.isDefault)
        };

        if (advertisingAccounts[0].account_type_id === '9') {
          advert['specialAdCategory'] = undefined;
        }

        if (advertisingAccounts.length === 1) {
          advert.account = advertisingAccounts[0];
          this.accountUpdated(advert);
        }

        return advert;
      });

      this.activeAdvert = this.adverts[0];

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  accountUpdated(advert: AdvertForm = this.activeAdvert) {
    advert.preset = null;
    advert.targetingPresets = this.advertisingTargetingPresets.filter(
      (preset) => preset.account === advert.account
    );
    this.canCreateAdUnvalidated = this.authUser.hasAccountPermission(
      advert.account,
      'create_ad_unvalidated'
    );
    this.getBudgetSuggestion();
  }

  async saveBoostedPost() {
    const advertParams = {
      account_id: +this.activeAdvert.account.id,
      name: this.activeAdvert.name,
      outbox_id: +this.activeAdvert.outboxPost.id,
      targeting_options: serialiseTargetingOptions(
        this.activeAdvert.targetingOptions
      ),
      budget: {
        daily_budget: {
          amount: this.activeAdvert.budget.daily,
          currency: this.activeAdvert.account.ad_currency
        },
        total_budget: {
          amount: this.activeAdvert.budget.total,
          currency: this.activeAdvert.account.ad_currency
        }
      },
      bidding: {
        amount: {
          amount: this.activeAdvert.bidding.amount,
          currency: this.activeAdvert.account.ad_currency
        },
        bidding_event: this.activeAdvert.bidding.event
      },
      schedule: {
        start: this.activeAdvert.start.toISOString(),
        ...(this.activeAdvert.specifiedEndDate && {
          end: this.activeAdvert.end.toISOString()
        })
      },
      locale: {
        language: this.activeAdvert.language.locale,
        country: this.activeAdvert.language.country
      },
      requires_validation: this.activeAdvert.requiresValidation
    };
    if (this.activeAdvert.account.account_type_id === '9') {
      advertParams['parameters'] = {
        special_ad_category: this.activeAdvert.specialAdCategory
      };
    }
    const advert = await this.advertModel.createBoostedPost(advertParams);

    if (this.activeAdvert === this.adverts[this.adverts.length - 1]) {
      const params: { id?: string } = {};
      if (this.adverts.length === 1) {
        params.id = advert.id;
      }
      await this.state.go('auth.marketing.advertising.confirmed');
    } else {
      const newIndex = this.adverts.indexOf(this.activeAdvert) + 1;
      this.activeAdvert = this.adverts[newIndex];
      window.scrollTo(0, 0); // scroll to the top of the page
    }
  }

  async getBudgetSuggestion() {
    this.budgetSuggestion = null;
    if (
      this.activeAdvert &&
      this.activeAdvert.account &&
      this.activeAdvert.account.socialNetwork.advertising.features
        .budgetSuggestion &&
      this.activeAdvert.targetingOptions.length > 0
    ) {
      this.budgetSuggestion = await this.targeting.getBudgetSuggestion(
        this.activeAdvert.account,
        this.activeAdvert.targetingOptions,
        this.activeAdvert.bidding.event,
        this.activeAdvert.account.ad_currency
      );
      if (this.activeAdvert.budget.daily === DEFAULT_AD_SPEND) {
        this.activeAdvert.budget.daily = this.budgetSuggestion.dailyBudget.default.amount;
      }
      if (this.activeAdvert.bidding.amount === DEFAULT_AD_SPEND) {
        this.activeAdvert.bidding.amount = this.budgetSuggestion.suggestedBid.default.amount;
      }
    }
  }
}
