import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PaginatorModule } from '../paginator';
import { ObserveWidthAndHeightModule } from '../../directives/observe-width/observe-width-and-height.module';
import { VarModule } from '../../directives/var/var.module';
import { TemplateSelectorModule } from '../../directives/template-selector/template-selector.module';

import { TreetableComponent } from './treetable.component';
import { ColumnComponent } from './column/column.component';
import { PaginationComponent } from './pagination/pagination.component';

import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    PaginatorModule,
    ObserveWidthAndHeightModule,
    TooltipModule,
    VarModule,
    TemplateSelectorModule
  ],
  exports: [
    TreetableComponent,
    ColumnComponent,
    PaginationComponent,
    TemplateSelectorModule
  ],
  declarations: [TreetableComponent, ColumnComponent, PaginationComponent]
})
export class TreetableModule {}
