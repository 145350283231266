<div class="campaigns">
  <div class="exit" (click)="activeModal.close(null)">
    <span><i class="ssi ssi-times"></i></span>
  </div>
  <div class="header">
      <h2 *ngIf="title">{{title}}</h2>
      <p class="info" *ngIf="info">{{info}}</p>
  </div>
  <ng-template #campaignLabelTemplate>
    <span *ngIf="!campaign">Select campaign (optional)</span>
  </ng-template>
  <ng-template #campaignOptionTemplate let-campaign="option" let-isSelected="isSelected">
    <div class="d-flex" *ngIf="!campaign.parent_id">
      <div>
        {{ campaign.name }}
      </div>
      <div class="flex-1 text-right" *ngIf="isSelected">
        <i class="ssi ssi-tick"></i>
      </div>
    </div>
    <div
      class="publisher-landing-dropdown-item-indent"
      [class.underline]="!isSelected"
      *ngIf="campaign.parent_id">
      <i
        class="fa"
        [class.fa-circle-thin]="!isSelected"
        [class.fa-circle]="isSelected">
      </i>{{ campaign.name }}
    </div>
  </ng-template>
  <ssi-dropdown-select
    [(ngModel)]="campaign"
    [labelTemplate]="campaignLabelTemplate"
    [optionTemplate]="campaignOptionTemplate"
    [options]="campaigns"
    [searchable]="true"
    [dropdownIsVisible]="true"
    [closeOnSelection]="false"
    theme="inverse"
    searchPlaceholder="Search for a campaign...">
  </ssi-dropdown-select>
  <div class="footer">
    <orlo-button
      [state]="'primary'"
      [text]="'Confirm'"
      (click)="activeModal.close(campaign)"
    ></orlo-button>
    <button class="orlo-button text" (click)="activeModal.close(null)">Cancel</button>
  </div>
</div>
