import './publisher.component.scss';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { PublisherComponent } from './publisher.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import flatpickr from 'flatpickr';
import { FlatpickrModule } from 'angularx-flatpickr';
import { PUBLISHER_ACTIVE, PublisherActive } from './publisher-active';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmojiFormControlContainerModule } from '../../directives/emoji-form-control-container/emoji-form-control-container.module';
import { DropdownSelectModule } from '../dropdown-select/dropdown-select.module';
import { ScheduleFirstCommentModule } from '../schedule-first-comment/schedule-first-comment.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { EnlargeImageModule } from '../../directives/enlarge-image/enlarge-image.module';
import { PublisherLandingComponent } from './publisher-landing/publisher-landing.component';
import { PublisherTargetingComponent } from './publisher-landing/publisher-targeting/publisher-targeting.component';
import { PublisherActionsAndMediaComponent } from './publisher-landing/publisher-actions-and-media/publisher-actions-and-media.component';
import { UIResourcesModule } from '@ui-resources-angular';
import {
  CalendarCommonModule,
  CalendarDateFormatter
} from 'angular-calendar/modules/common';
import { CalendarMonthModule } from 'angular-calendar/modules/month';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { PublisherScheduleComponent } from './publisher-schedule/publisher-schedule.component';
import { PublisherScheduleDatePickerComponent } from './publisher-schedule/publisher-schedule-date-picker/publisher-schedule-date-picker.component';
import { PublisherScheduleTimePickerComponent } from './publisher-schedule/publisher-schedule-time-picker/publisher-schedule-time-picker.component';
import { HighchartModule } from '../highchart/highchart.module';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PublisherSecondaryViewNavComponent } from './publisher-secondary-view-nav/publisher-secondary-view-nav.component';
import { FileUploaderModule } from '../../directives/file-uploader/file-uploader.module';
import { ElementLifecycleModule } from '../../directives/element-lifecycle/element-lifecycle.module';
import { InputUrlModule } from '../../directives/input-url/input-url.module';
import { FocusOnModule } from '../../directives/focus-on/focus-on.module';
import { NgUpgradeModule } from '../../ng-upgrade.module';
import { TextInputAutocompleteModule } from '../../components/text-input-autocomplete';
import { TextInputHighlightModule } from '../../components/text-input-highlight';
import { ContextMenuModule } from '../../components/context-menu/context-menu.module';
import { PublisherMentionsAutocompleteMenuComponent } from './publisher-landing/publisher-mentions-autocomplete-menu/publisher-mentions-autocomplete-menu.component';
import { PublisherSocialNetworkPreviewModalModule } from './publisher-social-network-preview-modal/publisher-social-network-preview-modal.module';
import { QuoteRetweetModalComponent } from './quote-retweet-modal/quote-retweet-modal.component';
import { FacebookShareModalComponent } from './facebook-share-modal/facebook-share-modal.component';
import { AltTextModalComponent } from './alt-text-modal/alt-text-modal.component';
import { UploadSubtitlesModalComponent } from './upload-subtitles-modal/upload-subtitles-modal.component';
import { CalendarShortDateFormatterService } from './publisher-schedule/publisher-schedule-date-picker/calendar-short-date-formatter/calendar-short-date-formatter.service';
import { MediaNavModule } from '../media-nav/media-nav.module';
import { TruncateModule } from '../../pipes/truncate/truncate.module';
import { FilterByStringModule } from '../../pipes/filter-by-string/filter-by-string.module';
import { PhotoEditorSDKDesktopUIModule } from './pesdk/pesdk-desktopui.module';
import { PesdkModalComponent } from './publisher-album/pesdk-modal/pesdk-modal.component';
import { PublisherExpiryModalComponent } from './publisher-landing/publisher-actions-and-media/publisher-expiry-modal/publisher-expiry-modal.component';
import { OutboxTypeaheadModule } from '../outbox-typeahead/outbox-typeahead.module';
import { ListSelectModule } from '../../../common/components/list-select/list-select.module';
import { OutboxPostListModule } from '../outbox-post-list/outbox-post-list.module';
import { PublisherLandingModule } from './publisher-landing/publisher-landing.module';
import { SortByModule } from '../../pipes/sort-by/sort-by.module';
import { SuggestedTextModalComponent } from './suggested-text-modal/suggested-text-modal.component';
import { OrloComponentsModule } from 'orlo-components';
import { AiWritingToolModalComponent } from './publisher-landing/ai-writing-tool-modal/ai-writing-tool-modal.component';
import { AiWritingToolService } from './publisher-landing/ai-writing-tool-modal/ai-writing-tool.service';
import { PublisherScheduleActionButtonsComponent } from './publisher-schedule-action-buttons/publisher-schedule-action-buttons.component';
import { PrettyBytesModule } from '../../pipes/pretty-bytes/pretty-bytes.module';
import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { SecondsToHumanTimeModule } from '../../pipes/seconds-to-human-time/seconds-to-human-time.module';

export function publisherActiveFactory(): PublisherActive {
  return new Subject();
}

export function flatpickrFactory() {
  return flatpickr;
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule,
    PublisherLandingModule,
    EmojiFormControlContainerModule,
    DropdownSelectModule,
    ListSelectModule,
    TooltipModule,
    EnlargeImageModule,
    UIResourcesModule,
    TextInputHighlightModule,
    TextInputAutocompleteModule,
    ContextMenuModule,
    OffClickModule,
    CalendarCommonModule.forRoot({
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CalendarShortDateFormatterService
      }
    }),
    CalendarMonthModule,
    FlatpickrModule.forRoot(),
    HighchartModule,
    NgbTimepickerModule.forRoot(),
    NgbDropdownModule.forRoot(),
    FileUploaderModule,
    ElementLifecycleModule,
    InputUrlModule,
    FocusOnModule,
    PublisherSocialNetworkPreviewModalModule,
    MediaNavModule,
    TruncateModule,
    FilterByStringModule,
    NgUpgradeModule, // so that the upgrade outbox-post-list component works
    PhotoEditorSDKDesktopUIModule,
    OutboxTypeaheadModule,
    OutboxPostListModule,
    SortByModule,
    ScheduleFirstCommentModule,
    OrloComponentsModule,
    PrettyBytesModule,
    DropdownSelect2Module,
    SecondsToHumanTimeModule
  ],
  declarations: [
    PublisherComponent,
    // PublisherTargetingComponent,
    // PublisherActionsAndMediaComponent,
    PublisherScheduleComponent,
    PublisherScheduleDatePickerComponent,
    PublisherScheduleTimePickerComponent,
    PublisherSecondaryViewNavComponent,
    PublisherMentionsAutocompleteMenuComponent,
    QuoteRetweetModalComponent,
    FacebookShareModalComponent,
    AltTextModalComponent,
    UploadSubtitlesModalComponent,
    PesdkModalComponent,
    SuggestedTextModalComponent,
    PublisherExpiryModalComponent,
    PublisherScheduleActionButtonsComponent
  ],
  providers: [
    { provide: PUBLISHER_ACTIVE, useFactory: publisherActiveFactory },
    DatePipe,
    AiWritingToolService
  ],
  exports: [FacebookShareModalComponent],
  entryComponents: [
    PublisherMentionsAutocompleteMenuComponent,
    QuoteRetweetModalComponent,
    FacebookShareModalComponent,
    AltTextModalComponent,
    UploadSubtitlesModalComponent,
    PesdkModalComponent,
    SuggestedTextModalComponent,
    PublisherExpiryModalComponent,
    AiWritingToolModalComponent
  ]
})
export class PublisherModule {}
