<div class="manage-ifttt-applet-triggers-and-actions">

  <div class="manage-ifttt-applet-triggers-and-actions-top">

    <div class="manage-ifttt-applet-triggers-and-actions-top-inner">

      <div class="applet-config-columns-nav-arrow"
           (click)="goBack()">
        <i class="ssi ssi-keyboard-arrow-left"></i>
      </div>

      <div class="applet-config-columns-container">

        <div class="applet-name-container"
             uiSref="^.name">
          <span class="applet-name">{{ applet.name }}</span> <i class="ssi ssi-pencil"></i>
        </div>

        <div class="applet-config-columns">

          <div class="applet-config-column service-and-accounts has-items">
            <div class="applet-config-column-header"
                 [uiSref]="applet.streams.length ? '^.streams' : '^.accounts'">
              <span class="applet-config-column-top-label">Posts to my:</span> <i class="ssi ssi-pencil"></i>
            </div>
            <div class="applet-config-column-body"
                 [uiSref]="applet.streams.length ? '^.streams' : '^.accounts'">
              <div>
                <div>
                  <i [ngClass]="service.brand.iconClass"></i>
                </div>
                <div>
                  <div class="applet-config-column-body-title">{{ service.translationIds.title | translate }}</div>
                  <div *ngIf="!applet.streams.length" class="applet-config-column-body-sub-title">{{ applet.accounts.length }} accounts</div>
                  <div *ngIf="applet.streams.length" class="applet-config-column-body-sub-title">{{ applet.streams.length }} streams</div>
                </div>
              </div>
            </div>
            <div class="applet-config-column-footer active"
                 [uiSref]="applet.streams.length ? '^.streams' : '^.accounts'">
              Module
            </div>
          </div>

          <div class="applet-config-column triggers"
               [class.active]="mode === 'triggers'"
               [class.has-items]="applet.triggers.length > 0">
            <div class="applet-config-column-header"
                 uiSref="^.triggersAndActions"
                 [uiParams]="{mode: 'triggers'}">
              <span class="applet-config-column-top-label">That contain:</span>
              <i class="ssi ssi-pencil"
                 *ngIf="mode !== 'triggers'"></i>
            </div>
            <div class="applet-config-column-body"
                 uiSref="^.triggersAndActions"
                 [uiParams]="{mode: 'triggers'}">
              <div *ngIf="applet.triggers.length > 0">
                <div class="applet-config-column-icons">
                  <i *ngFor="let trigger of applet.triggers; trackBy:trackByName"
                     [ngClass]="trigger.trigger.iconClass"
                     [ngbPopover]="trigger.label"
                     triggers="mouseenter mouseleave"
                     popoverClass="popover-dark"
                     placement="top">
                  </i>
                </div>
                <div>
                  <div class="applet-config-column-body-title">{{ applet.triggers.length }} triggers</div>
                  <div class="applet-config-column-body-sub-title">
                    <span *ngFor="let trigger of applet.triggers; trackBy:trackByName; let last = last">
                      {{ trigger.trigger.translationIds.title | translate }}<span *ngIf="!last">, </span>
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="applet.triggers.length === 0">
                <i class="ssi ssi-plus"></i>
              </div>
            </div>
            <div class="applet-config-column-footer active"
                 *ngIf="mode === 'triggers'"
                 uiSref="^.triggersAndActions"
                 [uiParams]="{mode: 'actions'}">
              Save &amp; move to actions
            </div>
            <div class="applet-config-column-footer active"
                 *ngIf="mode === 'actions'"
                 uiSref="^.triggersAndActions"
                 [uiParams]="{mode: 'triggers'}">
              Trigger
            </div>
          </div>

          <div class="applet-config-column actions"
               [class.future]="mode === 'triggers'"
               [class.active]="mode === 'actions'"
               [class.has-items]="applet.actions.length > 0">
            <div class="applet-config-column-header"
                 uiSref="^.triggersAndActions"
                 [uiParams]="{mode: 'actions'}">
              <span class="applet-config-column-top-label">Do the following:</span>
              <i class="ssi ssi-pencil"
                 *ngIf="mode !== 'actions'"></i>
            </div>
            <div class="applet-config-column-body"
                 uiSref="^.triggersAndActions"
                 [uiParams]="{mode: 'actions'}">
              <div *ngIf="applet.actions.length > 0">
                <div class="applet-config-column-icons">
                  <span *ngFor="let action of applet.actions; trackBy:trackByName">
                    <ng-template #popoverContent>
                      <span [innerHtml]="action.label | nl2br"></span>
                    </ng-template>
                    <i [ngClass]="action.action.iconClass"
                       [ngbPopover]="popoverContent"
                       triggers="mouseenter mouseleave"
                       popoverClass="popover-dark"
                       placement="top">
                    </i>
                  </span>
                </div>
                <div>
                  <div class="applet-config-column-body-title">{{ applet.actions.length }} actions</div>
                  <div class="applet-config-column-body-sub-title">
                    <span *ngFor="let action of applet.actions; trackBy:trackByName; let last = last">
                      {{ action.action.translationIds.title | translate }}<span *ngIf="!last">, </span>
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="applet.actions.length === 0">
                <i class="ssi ssi-plus"></i>
              </div>
            </div>
            <div class="applet-config-column-footer disabled"
                 *ngIf="mode === 'triggers' || (mode === 'actions' && applet.actions.length === 0)">
              Action
            </div>
            <div class="applet-config-column-footer active"
                 uiSref="^.summary"
                 *ngIf="mode === 'actions' && applet.actions.length > 0">
              Save & complete trigger
            </div>
          </div>

        </div>

      </div>

      <div class="applet-config-columns-nav-arrow"
           (click)="goForward()">
        <i class="ssi ssi-keyboard-arrow-right"></i>
      </div>

    </div>

  </div>

  <div class="manage-ifttt-applet-triggers-and-actions-bottom">

    <div class="manage-ifttt-applet-triggers-and-actions-bottom-inner">
      <h3 class="manage-ifttt-applet-triggers-and-actions-bottom-title">
        {{ service.translationIds[mode] | translate }}
      </h3>

      <p class="manage-ifttt-applet-triggers-and-actions-bottom-sub-title">
        Simply add the triggers you would like to prompt an action:
      </p>

      <div class="manage-ifttt-applet-cards">

        <ng-template #cardTitleTemplate
                     let-triggerOrAction="triggerOrAction">
          {{ triggerOrAction.item.translationIds.title | translate }}
        </ng-template>

        <ng-template #cardIconTemplate
                     let-triggerOrAction="triggerOrAction">
          <i [ngClass]="triggerOrAction.item.iconClass"></i>
        </ng-template>

        <ng-template #cardDescriptionTemplate
                     let-triggerOrAction="triggerOrAction">
          {{ triggerOrAction.description }}
        </ng-template>

        <ng-template #cardValueLabelTemplate
                     let-triggerOrAction="triggerOrAction">
          <span [innerHtml]="triggerOrAction.valueLabel | nl2br"></span>
        </ng-template>

        <ng-template #cardOverlayTemplate
                     let-triggerOrAction="triggerOrAction">
          <div class="card-form-elements-columns"
               [formGroup]="edit.paramForm">
            <div class="card-form-elements"
                 *ngFor="let column of triggerOrAction.paramColumns; trackBy:trackByIndex; let last = last; let columnIndex = index;">
              <div *ngFor="let param of column; trackBy:trackByIndex"
                   [ngSwitch]="param.form.type"
                   [hidden]="param.form.type === UIParamFormType.Hidden"
                   [class.card-form-element]="
                  param.form.type !== UIParamFormType.TextInput &&
                  param.form.type !== UIParamFormType.Hidden &&
                  param.form.type !== UIParamFormType.Range
                "
                   [class.flex-basis]="param.name === 'queries'">
                <div class="card-form-element spacer-top"
                     *ngSwitchCase="(param.form.type === UIParamFormType.SelectSingle || param.form.type === UIParamFormType.SelectMultiple) ? param.form.type : ''">
                  <div class="spacer-bottom"
                       *ngIf="param.form.select.searchPlaceholderTranslationKey">
                    <input type="text"
                           class="form-control form-control-minimal text-center form-control-text-lg"
                           [placeholder]="param.form.select.searchPlaceholderTranslationKey | translate"
                           [(ngModel)]="edit.search"
                           [ngModelOptions]="{standalone: true}"
                           (keyup)="filterOptions(param)"
                           [ssiFocusOn]="edit.triggerOrAction === triggerOrAction.item">
                    <i class="fa fa-search text-primary form-control-icon"></i>
                  </div>
                  <div class="scroll-vertical">
                    <ssi-list-select class="options-light options-lg"
                                     [class.option-active-success]="mode === 'actions'"
                                     [class.option-active-royal]="mode === 'triggers'"
                                     [formControlName]="param.name"
                                     [multiple]="param.form.type === UIParamFormType.SelectMultiple"
                                     [allowUnselect]="true">
                      <ssi-list-select-option *ngFor="let option of (edit.filteredParamOptions[param.name] || param.form.select.options); trackBy:trackBySelectOption"
                                              [value]="option.value">
                        {{ option.label }}
                      </ssi-list-select-option>
                    </ssi-list-select>
                  </div>
                </div>

                <div class="spacer-top"
                     *ngSwitchCase="UIParamFormType.TextInput">
                  <input 
                    class="form-control text-center form-control-text-lg card-form-element-input"
                    [type]="param.form.input.type || 'text'"
                    [placeholder]="param.form.input.placeholderTranslationKey | translate"
                    [ssiFocusOn]="edit.triggerOrAction === triggerOrAction.item"
                    [formControlName]="param.name"
                    (keyup)="param.form.input.type && convertType(param.name, 'integer')"
                  >
                </div>

                <div class="card-form-element spacer-top"
                     *ngSwitchCase="UIParamFormType.Textarea"
                     ssiTextInputHighlightContainer>

                  <ng-template #ingredientsPopoverTemplate>
                    <h4>Select your input</h4>
                    <div class="scroll-vertical"
                         (ssiOffClick)="toggleIngredientsPopover(ingredientsPopover)">
                      <ssi-list-select class="options-light options-lg option-circles-hidden">
                        <ssi-list-select-option *ngFor="let ingredient of ingredients; trackBy:trackByName"
                                                [value]="ingredient"
                                                (click)="addIngredientToTextParam(ingredient, ingredientsPopover, ingredientsTextarea)">
                          {{ ingredient.translationIds.label | translate }}
                          <span class="list-select-option-icon-inner">
                            <i class="fa fa-plus"></i>
                          </span>
                        </ssi-list-select-option>
                      </ssi-list-select>
                    </div>
                  </ng-template>

                  <textarea ssiTextInputElement
                            class="form-control form-control-text-lg card-form-element-input"
                            rows="5"
                            [placeholder]="param.form.input.placeholderTranslationKey | translate"
                            [formControlName]="param.name"
                            [ssiFocusOn]="edit.triggerOrAction === triggerOrAction.item"
                            #paramTextarea
                            [ngbPopover]="ingredientsPopoverTemplate"
                            [placement]="columnIndex > 0 ? 'left' : 'right'"
                            triggers="manual"
                            #ingredientsPopover="ngbPopover"
                            popoverClass="ingredients-popover"
                            ssiTriggersIngredientTextarea
                            [ingredients]="ingredients"
                            #ingredientsTextarea="triggersIngredientTextarea"
                            (valueChanged)="edit.textareaHighlightTags = $event.highlight"
                            (blur)="edit.textareaSelectionStart = paramTextarea.selectionStart">
                  </textarea>
                  <ssi-textarea-caret-tracker class="spacer-top"
                                              *ngIf="mode === 'actions' && !param.form.textArea?.hidePopover"
                                              [textarea]="paramTextarea"
                                              [isActive]="edit.showIngredients"
                                              (iconClicked)="toggleIngredientsPopover(ingredientsPopover)"></ssi-textarea-caret-tracker>
                  <ssi-text-input-highlight [tags]="edit.textareaHighlightTags"
                                            tagCssClass="text-highlight-element-tag"
                                            [textInputElement]="paramTextarea"></ssi-text-input-highlight>
                </div>

                <div class="card-form-element"
                     *ngSwitchCase="UIParamFormType.Sentiment">
                  <ssi-triggers-sentiment-selector [formControlName]="param.name"
                                                   [sentiment]="param.form.sentiment.options"></ssi-triggers-sentiment-selector>
                </div>

                <div class="card-form-element"
                     *ngSwitchCase="UIParamFormType.TagsInput">
                  <div class="scroll-vertical">
                    <ssi-tags-input [formControlName]="param.name"
                                    [placeholder]="param.form.input.placeholderTranslationKey | translate"
                                    [minLength]="2"
                                    [updateModelValue]="true"
                                    [maxTags]="param.form.maxValues"
                                    [typeaheadOptions]="param.form.input.promptOptions"></ssi-tags-input>
                  </div>

                  <div class="exact-matches-checkbox-wrap"
                       *ngIf="param.name === 'queries' && showExactMatchesCheckbox(triggerOrAction)">
                    <ssi-checkbox-2 [label]="'Exact matches'"
                                    [highlightLabel]="false"
                                    [formControlName]="'exactMatches'"></ssi-checkbox-2>
                    <a href="https://support.orlo.tech/keyword-searches"
                       target="_blank"><i class="ssi ssi-help"></i></a>
                  </div>
                </div>

                <div *ngSwitchCase="UIParamFormType.Range">
                  <ssi-triggers-range-selector [allParams]="column"
                                               [param]="param"
                                               [form]="edit.paramForm"></ssi-triggers-range-selector>
                </div>

                <div *ngSwitchDefault>
                  Unknown form type: {{ param.form.type }}
                </div>

              </div>

              <button *ngIf="last"
                      class="btn btn-primary btn-block spacer-top"
                      translate="SAVE"
                      [disabled]="!edit.paramForm.valid"
                      (click)="saveEditTriggerOrActionValue(edit.triggerOrAction, edit.paramForm.value)"></button>
            </div>
          </div>
        </ng-template>

        <ssi-trigger-or-action-card *ngFor="let triggerOrAction of triggersAndActions[mode]; trackBy:trackByApiName"
                                    [class.has-value]="triggerOrAction.hasValue"
                                    [class.triggers]="mode === 'triggers'"
                                    [class.actions]="mode === 'actions'"
                                    [overlayWidth]="triggerOrAction.item.overlayDimensions?.width"
                                    [overlayHeight]="triggerOrAction.item.overlayDimensions?.height"
                                    [overlayVisible]="edit?.triggerOrAction === triggerOrAction.item"
                                    [hasValue]="triggerOrAction.hasValue"
                                    [triggerOrAction]="triggerOrAction"
                                    [cardTitleTemplate]="cardTitleTemplate"
                                    [cardIconTemplate]="cardIconTemplate"
                                    [cardDescriptionTemplate]="cardDescriptionTemplate"
                                    [cardValueLabelTemplate]="cardValueLabelTemplate"
                                    [cardOverlayTemplate]="cardOverlayTemplate"
                                    (cardClicked)="startEditTriggerOrActionValue(triggerOrAction.item)"
                                    (cardOffClick)="cancelEdit()"
                                    (clearValue)="saveEditTriggerOrActionValue(triggerOrAction.item, null)"></ssi-trigger-or-action-card>
      </div>
    </div>

  </div>

</div>