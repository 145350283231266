<div class="query-container-builder-row" [ngClass]="'query-container-builder-row-' + branchClass">
  <div class="query-container-builder-row-tree">
    <span 
      class="query-container-builder-row-tree-label"
      [ngClass]="{'query-container-builder-row-tree-label-is-root': isRoot}"
      [ngStyle]="{
        'background-color': isRoot && (operator === '$or' ? '#425DEC' : operator === '$and' ? '#F0B427' : '#eee')
      }"
      (click)="isRoot && operatorToggle.emit()"
    >
      <span [innerHTML]="operator === '$or' ? 'Or' : operator === '$and' ? 'And' : '-'"></span>
    </span>
    <span class="query-container-builder-row-tree-guides"></span>
  </div>
  <div class="query-container-builder-row-condition">
    <div class="query-container-builder-row-condition-select">
      <select
        [(ngModel)]="condition.type"
        (ngModelChange)="condition.type !== 'undefined' && condition.comparison = conditionOptions[condition.type].comparisonOperators[0].type"
      >
        <option *ngIf="overrideDefaultOption" [value]="undefined">Override default value</option>
        <option
          *ngFor="let option of conditionOptionsIterable"
          [value]="option.type"
        >{{option.label}}</option>
      </select>
      <i class="ssi ssi-arrow-down-new"></i>
    </div>
    <div *ngIf="condition.type !== 'undefined'" class="query-container-builder-row-condition-select">
      <select
        [(ngModel)]="condition.comparison"
        class="{{condition.comparison}}"
      >
        <option
          *ngFor="let option of conditionOptions[condition.type]?.comparisonOperators"
          [value]="option.type"
        >{{option.label}}</option>
      </select>
      <i class="ssi ssi-arrow-down-new"></i>
    </div>
    <input [(ngModel)]="condition.value" class="query-container-builder-row-condition-text" type="text" required>
    <button class="query-container-builder-row-condition-delete" (click)="deleteCondition.emit()">
      <i class="ssi ssi-delete"></i>
    </button>
  </div>
</div>