import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { InternationalizationComponent } from './internationalization.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { ToggleSwitchModule } from '../../../../../common/components/toggle-switch/toggle-switch.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.internationalization',
          url: '/internationalization',
          component: InternationalizationComponent,
          resolve: InternationalizationComponent.resolve
        }
      ]
    }),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AlertsModule,
    ToggleSwitchModule
  ],
  declarations: [InternationalizationComponent],
  entryComponents: [InternationalizationComponent]
})
export class InternationalizationModule {}
