<div class="tooltip-actions">
	<h5 *ngIf="title">{{title}}</h5>
	<div class="buttons-container">
		<button type="button"
				class="g-btn text-only-button dismiss-button"
				(click)="$event.stopPropagation(); dismiss();"><span [style.color]="dismissTextColour">{{dismissText}}</span></button>
		<button type="button"
				class="g-btn text-only-button confirm-button"
				(click)="$event.stopPropagation(); confirm();"><span [style.color]="confirmTextColour">{{confirmText}}</span></button>
	</div>
</div>