import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { Transition, StateService } from '@uirouter/angular';
import {
  Campaign,
  CampaignModel,
  Account,
  API,
  User
} from '@ui-resources-angular';
import { transition, trigger, useAnimation } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { Subscription } from 'rxjs';

import './advertising-index.component.scss';
import {
  Advert,
  AdvertModelService
} from '../advert-model/advert-model.service';
import {
  campaigns,
  workflowAccounts,
  authUser,
  colleagues,
  teams,
  advertisingAccounts
} from '../../../../common-resolves';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../common/services/api';
import { trackByProperty } from '../../../../../common/util';
import { CampaignModelService } from '../../../../auth/marketing/index/campaign-model/campaign-model.service';
import { slideIn, slideOut } from '../../../../../common/animations';
import { OutboxPostModalComponent } from '../../content-calendar/common/components/outbox-post-modal/outbox-post-modal.component';

export async function advertsFn(
  advertModel: AdvertModelService,
  campaignModel: CampaignModel,
  campaignModalService: CampaignModelService,
  pageTransition: Transition,
  accounts: Account[]
) {
  const params = { ...pageTransition.params() };
  const queryParams = params.query ? JSON.parse(params.query) : null;
  if (!params.id && !params.outbox_id && !params.account_id) {
    params.account_ids = accounts.map((account) => account.id);
    if (queryParams) {
      params.campaign_ids = new Array(queryParams.campaign_id);
    }
  }
  if (params.campaign_ids) {
    const allCampaigns = await campaignModel.findAll();
    const childCampaigns = campaignModalService.filterInChildCampaigns(
      allCampaigns,
      true,
      queryParams.campaign_id
    );
    if (childCampaigns.length) {
      const childCampaignIds = childCampaigns.filter(
        (campaign) => !campaign.is_closed && campaign.id
      );
      params.campaign_ids.push(...childCampaignIds);
    }
  }
  Object.keys(params).forEach((key) => {
    return params[key] === undefined ? delete params[key] : '';
  });
  advertModel.ejectAll();
  const adverts = await advertModel.findAll(params, { bypassCache: true });
  if (!Array.isArray(adverts)) {
    return [adverts];
  }
  return adverts;
}

@Component({
  selector: 'ssi-advertising-index',
  templateUrl: './advertising-index.component.html',
  animations: [
    trigger('slideInOut', [
      transition(
        'void => *',
        useAnimation(slideIn, { params: { duration: '200ms' } })
      ),
      transition(
        '* => void',
        useAnimation(slideOut, { params: { duration: '200ms' } })
      )
    ])
  ]
})
export class AdvertisingIndexComponent implements OnDestroy, OnInit {
  static resolve = [
    authUser,
    colleagues,
    campaigns,
    teams,
    workflowAccounts,
    advertisingAccounts,
    {
      token: 'adverts',
      resolveFn: advertsFn,
      deps: [
        AdvertModelService,
        CampaignModel,
        CampaignModelService,
        Transition,
        'advertisingAccounts'
      ]
    }
  ];

  @Input() adverts: Advert[];
  @Input() authUser: User;
  @Input() colleagues: Colleague[];
  @Input() teams: Team[];
  @Input() campaigns: Campaign[];
  @Input() workflowAccounts: Account[];

  trackById = trackByProperty('id');
  campaignsList: Campaign[];
  activeCampaignId;
  $activeCampaignSub: Subscription;
  chartOptions = {
    loading: true
  };
  now = new Date();
  chartSearchDates = {
    from: null,
    to: null
  };
  chartDefaultStartDate = '2018-01-01T00:00:00+00';
  graphVisible = false;
  nextPage: string = null;
  activeSocialFilter = 0;
  activeStatusFilter: 'all' | 'live' | 'scheduled' | 'paused' | 'completed' =
    'all';
  activeSort = 'datetime';
  sortLabel = 'recently posted';

  constructor(
    private campaignModelService: CampaignModelService,
    private state: StateService,
    private api: API,
    private modal: NgbModal
  ) {}

  ngOnDestroy() {
    if (!!this.$activeCampaignSub) {
      this.$activeCampaignSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.fetchLinkClickStats(null, this.formatToDate('lastMonth'));

    this.$activeCampaignSub = this.campaignModelService.$activeCampaign.subscribe(
      (activeCampaign) => {
        this.activeCampaignId = activeCampaign
          ? (activeCampaign as any).id
          : null;
        this.sortByCampaign(this.activeCampaignId);
        this.fetchLinkClickStats(
          this.activeCampaignId,
          this.chartDefaultStartDate
        );

        this.updateLocationParams({
          query: this.activeCampaignId
            ? JSON.stringify({ campaign_id: this.activeCampaignId })
            : undefined
        });
      }
    );
  }

  changeGraphState() {
    this.graphVisible = !this.graphVisible;
  }

  formatToDate(timespan) {
    let timestamp;

    switch (timespan) {
      case 'lastQuarter':
        timestamp = moment(this.now).subtract(1, 'Q').format();
        break;
      case 'lastMonth':
        timestamp = moment(this.now).subtract(1, 'months').format();
        break;
      case 'lastWeek':
        timestamp = moment(this.now).subtract(7, 'days').format();
        break;
      case 'yesterday':
        timestamp = moment(this.now).subtract(1, 'days').format();
        break;
    }

    return timestamp;
  }

  chartChangeDate(timespan) {
    const from = this.formatToDate(timespan);
    const getCampaign = this.activeCampaignId || null;

    this.chartOptions = { loading: true };
    this.fetchLinkClickStats(getCampaign, from);
  }

  chartChangeDateCustom() {
    if (!moment(this.chartSearchDates.from).isValid()) {
      return;
    }

    if (!moment(this.chartSearchDates.to).isValid()) {
      return;
    }

    const lastDayCompensateHours = moment(this.chartSearchDates.to)
      .add(1, 'days')
      .format();
    const getCampaign = this.activeCampaignId || null;

    this.chartOptions = { loading: true };
    this.fetchLinkClickStats(
      getCampaign,
      this.chartSearchDates.from,
      lastDayCompensateHours
    );
  }

  fetchLinkClickStats(campaignId, from?, to?) {
    const linkClicksRequest = {
      account_ids: this.workflowAccounts.map((acc) => acc.id),
      since: from || this.chartDefaultStartDate,
      until: to || moment(this.now).format(),
      group_by: ['datetime'],
      ...(campaignId && {
        campaign_ids: [campaignId]
      })
    };

    this.api
      .post('stats/eslinkclicks', linkClicksRequest)
      .then(
        (res) =>
          (this.chartOptions = this.formatDataForChart(res.data.by_datetime))
      );
  }

  updateLocationParams(params) {
    this.state.go('auth.marketing.advertising.index', params);
  }

  getAdvertCurrency(advert) {
    if (advert.budget.hasOwnProperty('total_budget')) {
      return advert.budget.total_budget.currency;
    }
    if (advert.budget.hasOwnProperty('daily_budget')) {
      return advert.budget.daily_budget.currency;
    }
    return 'GBP';
  }

  formatDataForChart(data) {
    return {
      chart: {
        type: 'areaspline'
      },
      title: {
        text: null
      },
      xAxis: {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
        categories: Object.keys(data).map((date) => {
          return moment(date).format('D MMM');
        })
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null
        }
      },
      series: [
        // {
        //   name: '',
        //   color: '#596168',
        //   fillColor: '#454c56',
        //   fillOpacity: 1,
        //   data: Object.values(data).map((item: any) => Math.random() * 10)
        // },
        {
          name: '',
          color: '#e2b928',
          fillColor: 'transparent',
          data: Object.values(data).map((item: any) => item.count)
        }
      ],
      loading: false
    };
  }

  openPost(post) {
    const modal = this.modal.open(OutboxPostModalComponent, {
      windowClass: 'xl-modal post-modal-wrapper'
    });
    modal.componentInstance.post = post.outbox;
    modal.componentInstance.colleagues = this.colleagues;
    modal.componentInstance.teams = this.teams;
    modal.componentInstance.campaigns = this.campaigns;
    modal.componentInstance.user = this.authUser;
  }

  getFilteredAccounts(accountTypeId) {
    return this.workflowAccounts.filter((account) => {
      if (accountTypeId === 3) {
        return (
          account.account_type_id === '3' || account.account_type_id === '9'
        );
      } else if (accountTypeId === 4) {
        return (
          account.account_type_id === '4' || account.account_type_id === '8'
        );
      } else {
        return account.account_type_id === String(accountTypeId);
      }
    });
  }

  async filterStatus(filterStatus) {
    const filteredAccounts = this.getFilteredAccounts(this.activeSocialFilter);
    const params = {
      account_ids: Array.from(
        this.activeSocialFilter === 0
          ? this.workflowAccounts
          : filteredAccounts,
        (obj) => obj.id
      ),
      status: filterStatus,
      limit: 25
    };
    if (this.activeCampaignId) {
      params['campaign_ids'] = new Array(this.activeCampaignId);
    }
    const { data } = await this.api.get('advertising/advertisingAdverts_v2', {
      params
    });
    this.activeStatusFilter = filterStatus;
    this.adverts = [...data.data];
  }

  async filterAccount(accountTypeId) {
    this.activeSocialFilter = accountTypeId;
    const accounts = this.getFilteredAccounts(accountTypeId);
    const params = {
      account_ids: Array.from(
        accountTypeId === 0 ? this.workflowAccounts : accounts,
        (obj) => obj.id
      ),
      status: this.activeStatusFilter ? this.activeStatusFilter : 'all',
      limit: 25
    };
    if (this.activeCampaignId) {
      params['campaign_ids'] = new Array(this.activeCampaignId);
    }
    const { data } = await this.api.get('advertising/advertisingAdverts_v2', {
      params
    });
    this.adverts = [...data.data];
  }

  async loadMore(nextPage) {
    const params = {
      page: nextPage,
      account_ids: Array.from(this.workflowAccounts, (obj) => obj.id),
      limit: 25
    };
    if (this.activeCampaignId) {
      params['campaign_ids'] = new Array(this.activeCampaignId);
    }
    const { data } = await this.api.get('advertising/advertisingAdverts_v2', {
      params
    });
    this.adverts = this.adverts.concat([...data.data]);

    this.nextPage = data.next_page ? data.next_page : null;
  }

  async sortAccount(sort) {
    switch (sort) {
      case 'datetime':
        this.sortLabel = 'recently posted';
        break;
      case 'impressions':
        this.sortLabel = 'best impression rate';
        break;
      case 'clicks':
        this.sortLabel = 'best click rate';
        break;
      case 'engagements':
        this.sortLabel = 'best engagement rate';
        break;
    }
    this.activeSort = sort;
    const params = {
      sort,
      account_ids: Array.from(this.workflowAccounts, (obj) => obj.id),
      limit: 25
    };
    if (this.activeCampaignId) {
      params['campaign_ids'] = new Array(this.activeCampaignId);
    }
    const { data } = await this.api.get('advertising/advertisingAdverts_v2', {
      params
    });
    this.adverts = [...data.data];

    this.nextPage = data.next_page ? data.next_page : null;
  }

  async sortByCampaign(activeCampaignId) {
    const params = {
      account_ids: Array.from(this.workflowAccounts, (obj) => obj.id)
    };
    if (this.activeCampaignId) {
      const campaignsParam = new Array(this.activeCampaignId);
      const campaignsParamChildren = this.campaignModelService.filterInChildCampaigns(
        this.campaigns,
        true,
        this.activeCampaignId
      );
      if (campaignsParamChildren.length) {
        const campaignsParamChildrenIds = campaignsParamChildren.filter(
          (campaign) => !campaign.is_closed && campaign.id
        );
        campaignsParam.push(...campaignsParamChildrenIds);
      }
      params['campaign_ids'] = campaignsParam;
    }
    const { data } = await this.api.get('advertising/advertisingAdverts_v2', {
      params
    });
    this.adverts = [...data.data];

    this.nextPage = data.next_page ? data.next_page : null;
  }
}
