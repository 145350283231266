<div class="media padding-bottom margin-bottom">
  <div class="media-left">
    <img
      class="img-circle profile"
      src="{{ author.info.picture }}"
    >
  </div>
  <div class="media-body">
    <div>
      <a>
        <strong>{{ author.info.name }}</strong>
        <small class="text-muted">@{{ author.info.username }}</small>
      </a>
      <div class="text-muted pull-right">
        <small>
          <a target="_blank">{{ post.created_at | date :'MMM d HH:mm' }}</a>
        </small>
      </div>
    </div>

    <p [innerHTML]="post.text"></p>

    <p
      class="img-window img-rounded"
      *ngIf="post.image_url"
      [ngStyle]="{'background-image':'url('+post.image_url+')'}"
      (click)="enlargeImage()"
    >
    </p>

    <div class="row" *ngIf="author.account?.account_type_name === 'Facebook'">
      <a
        href="javascript:;"
        [ssiTriggerPublish]="{create: {accounts: [author.account], shareId: post.id}}"
      >
        <i class="fa fa-share-square-o"></i> <span translate="SHARE"></span> 
      </a>
    </div>

    <div
      class="row"
      *ngIf="author.account?.account_type_name === 'Twitter'"
    >
      <small>
        <div class="col-xs-3">
          <a
            href="javascript:;"
            [ssiTriggerPublish]="{create: {accounts: [author.account], replyToSocialId: post.id, text: '@'+author.info.username + ' '}}"
          >
            <i class="fa fa-share-square-o"></i><span translate="REPLY"></span>
          </a>
        </div>
        <div class="col-xs-3">
          <div
            class="d-inline-block"
            (clickOutside)="showRetweetDropdown = false"
          >
            <a href="javascript:;" (click)="showRetweetDropdown = true">
              <i class="fa fa-retweet"></i> <span>Repost</span> ({{ post.share_count }})
            </a>
            <ul
              *ngIf="showRetweetDropdown"
              class="social-post-dropdown"
            >
              <li>
                <a
                  href="javascript:;"
                  (click)="socialAction('share')"
                >
                  <i class="fa fa-retweet"></i> <span>Auto Repost</span>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  (click)="showRetweetDropdown = false"
                  [ssiTriggerPublish]="{create: {accounts: [author.account], quoteRetweet: 'https://twitter.com/' + author.info.username + '/status/' + post.id}}"
                >
                  <i class="fa fa-quote-left"></i> <span>Quote Repost</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-xs-3"
          *ngIf="!post.have_liked"
        >
          <a
            href="javascript:;"
            (click)="socialAction('like')"
          >
            <i class="fa {{author.account.socialNetwork.socialInteractions.like.icon.web}}"></i>
            {{ author.account.socialNetwork.socialInteractions.like.text }} ({{ post.like_count }})
          </a>
        </div>
        <div class="col-xs-3">
          <a
            href="{{ post.link }}"
            target="_blank"
          >
            <i class="fa fa-external-link"></i> <span translate="VIEW"></span>
          </a>
        </div>
      </small>
    </div>
    <div *ngIf="author.account?.account_type_name === 'Twitter'">
      <small>
        <span *ngIf="author.account?.account_type_name !== 'Facebook' || post.have_liked">
          <i class="fa fa-thumbs-up"></i> {{ post.like_count }}
        </span>
        <span *ngIf="author.account?.account_type_name === 'Facebook' && !post.have_liked">
          <a
            href="javascript:;"
            (click)="socialAction('like')"
          >
            <i class="fa {{author.account.socialNetwork.socialInteractions.like.icon.web}}"></i>
            {{ author.account.socialNetwork.socialInteractions.like.text }} ({{ post.like_count }})
          </a>
        </span>
      </small>
      &nbsp;-&nbsp;
      <small><i class="fa fa-share-square-o"></i> {{ post.share_count }}</small>
      <small>
        <a
          href="{{ post.link }}"
          target="_blank"
        >
          <i class="fa fa-external-link"></i> <span translate="VIEW"></span>
        </a>
      </small>
    </div>

  </div>
</div>

<hr class="thin">