import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';

import { StreamPostComponent } from './stream-post.component';
import { MenuModule } from '../menu/menu.module';
import { Checkbox2Module } from '../checkbox-2/checkbox-2.module';
import { SocialNetworkIconModule } from '../social-network-icon/social-network-icon.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { EnlargeImageModule } from '../../directives/enlarge-image/enlarge-image.module';
import { LinkifyModule } from '../../pipes/linkify/linkify.module';
import { ReverseModule } from '../../pipes/reverse/reverse.module';
import { BigNumberModule } from '../big-number/big-number.module';
import { BigNumberModule as BigNumberPipeModule } from '../../pipes/big-number/big-number.module';
import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { MessageModule } from '../message-box/message/message.module';
import { SentimentAmendBoxModule } from '../sentiment-amend-box/sentiment-amend-box.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { TriggerPublishModule } from '../../directives/trigger-publish/trigger-publish.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MenuModule,
    Checkbox2Module,
    SocialNetworkIconModule,
    FallbackImageModule,
    LinkifyModule,
    EnlargeImageModule,
    ReverseModule,
    BigNumberModule,
    BigNumberPipeModule,
    TooltipModule,
    DropdownSelect2Module,
    MessageModule,
    OverlayModule,
    SentimentAmendBoxModule,
    OffClickModule,
    TriggerPublishModule,
    EnlargeImageModule
  ],
  exports: [StreamPostComponent],
  declarations: [StreamPostComponent],
  providers: [NgbActiveModal],
  entryComponents: [StreamPostComponent]
})
export class StreamPostModule {}
