import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialPushModeMenuComponent } from './social-push-mode-menu.component';

@NgModule({
  declarations: [SocialPushModeMenuComponent],
  imports: [CommonModule],
  exports: [SocialPushModeMenuComponent],
  entryComponents: [SocialPushModeMenuComponent]
})
export class SocialPushModeMenuModule {}
