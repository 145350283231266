<div class="inbox-activity-header">
  <button
    class="inbox-activity-header-left inbox-activity-header-back"
    (click)="onClose.emit()"
    type="button"
  >
    <i class="fa fa-chevron-left"></i>
    
    <span translate="BACK"></span>
  </button>

  <div class="inbox-activity-header-right inbox-activity-header-external-crm-person">
    <div class="inbox-activity-header-external-crm-person-inner">
      <div class="inbox-activity-header-external-crm-person-inner-right">
        <span
          class="inbox-activity-header-external-crm-person-unlink"
          (click)="unlinkIntegration.emit({integration: externalIntegration})"
          [ngbPopover]="'UNLINK_CRM' | translate"
          triggers="mouseenter"
          popoverClass="popover-dark"
          placement="left"
          container="body"
        >
          <i class="fa fa-chain-broken"></i>
        </span>
        <img [src]="activityProfile?.avatar">
      </div>
    </div>
  </div>
</div>

<ssi-inbox-crm-external-integration-person
  *ngIf="externalIntegrationPerson"
  [externalIntegrationPerson]="externalIntegrationPerson"
  [activity]="activity"
  [crmPerson]="crmPerson"
></ssi-inbox-crm-external-integration-person>
