import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { ValidateBoxComponent } from './validate-posts-box.component';
import { AddSecondsModule } from '../../../../../common/pipes/add-seconds/add-seconds.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { ValidatePostsActionsModule } from './validate-posts-actions/validate-posts-actions.module';
import { ValidatePostsBoostModule } from './validate-posts-boost/validate-posts-boost.module';
import { ValidatePostsContentModule } from './validate-posts-content/validate-posts-content.module';
import { ValidatePostsGalleryModule } from './validate-posts-gallery/validate-posts-gallery.module';
import { ValidatePostsNotesModule } from './validate-posts-notes/validate-posts-notes.module';
import { ValidatePostsDissaproveModule } from './validate-posts-dissaprove/validate-posts-dissaprove.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    FlatpickrModule.forRoot(),
    AddSecondsModule,
    TooltipModule,
    BigNumberModule,
    FormsModule,
    ValidatePostsBoostModule,
    ValidatePostsActionsModule,
    ValidatePostsContentModule,
    ValidatePostsGalleryModule,
    ValidatePostsNotesModule,
    ValidatePostsDissaproveModule
  ],
  declarations: [ValidateBoxComponent],
  exports: [ValidateBoxComponent]
})
export class ValidatesPostsBoxModule {}
