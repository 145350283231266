import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetLiveXStreamComponent } from './widget-live-x-stream.component';
import { UniqueModuleModule } from '../../../../../../../common/pipes/unique/unique.module';
import { StreamPostModule } from '../../../../../../../common/components/stream-post/stream-post.module';
import { VirtualScrollModule } from '../../../../../../../common/components/virtual-scroll/virtual-scroll.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutoHeightModule } from '../../../../../../../common/directives/auto-height/auto-height.module';

@NgModule({
  imports: [
    CommonModule,
    StreamPostModule,
    UniqueModuleModule,
    VirtualScrollModule,
    InfiniteScrollModule,
    AutoHeightModule
  ],
  exports: [WidgetLiveXStreamComponent],
  declarations: [WidgetLiveXStreamComponent]
})
export class WidgetLiveXStreamModule {}
