<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Manage team</h1>
</div>
<div class="container container-app-page" *ngIf="settings">
  <ssi-alerts></ssi-alerts>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-users"></i>
        <span translate="_TEAMNAME__TEAM" [translateParams]="{teamName: settings.group.name}"></span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <div class="form-horizontal">
        <div class="form-group" *ngIf="settings.group.description">
          <label class="col-sm-2 control-label" translate="TEAM_DESCRIPTION"></label>
          <div class="col-sm-10">
            <p class="form-control-static">{{ settings.group.description }}</p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label" translate="ADD_USER_TO_TEAM"></label>
          <div class="col-sm-10">                
            <div
              #users="ngbDropdown"
              ngbDropdown
              class="nya-bs-select form-control btn-group"
              autoClose="outside">
              <button class="btn btn-default dropdown-toggle" ngbDropdownToggle>
                <span class="pull-left">
                  {{selectedUser ? selectedUser.first_name + ' ' + selectedUser.last_name : 'Select User'}}
                </span>
                <span class="caret"></span>
              </button>
              <div ngbDropdownMenu class="dropdown-menu">
                <ul class="dropdown-menu inner">
                  <li class="clearfix">
                    <div class="col-xs-12">
                      <input type="text" [(ngModel)]="userSearchText" (ngModelChange)="filterUsers()" class="form-control">
                    </div>
                  </li>
                  <li
                    *ngFor="let user of settings.users | filter: {is_member: false, is_active: true} | sortBy: 'first_name'; let index = index"
                    class="nya-bs-option"
                    [ngClass]="{selected: selectedUserIndex == index}"
                    (click)="selectedUser = user; selectedUserIndex = index; users.close()">
                    <a href="javascript:;">
                      {{ user.first_name + ' ' + user.last_name }}
                      <span class="fa fa-check check-mark"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-10">
            <button
              type="button"
              class="btn btn-success"
              [disabled]="selectedUser === undefined"
              (click)="groupToggleUser(selectedUser, true)">
              <i class="fa fa-plus"></i> <span translate="ADD_USER_TO_TEAM"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped" *ngIf="settings.users">
      <tbody>
        <tr *ngFor="let user of memberUsers">
          <td>{{ user.first_name }} {{ user.last_name }}</td>
          <td>{{ user.email_address }}</td>
          <td>
            <button
              class="btn btn-sm btn-danger pull-right"
              [hidden]="settings.group.is_admins == true && authUser.id == user.id"
              (click)="groupToggleUser(user, false)">
              <i class="fa fa-times"></i> <span translate="REMOVE"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="panel-title">
        <i class="fa fa-lock"></i> Accounts &amp; permissions in team
      </h3>
    </div>
    <div class="panel-body">
      <label translate="FILTER_ACCOUNTS"></label>
      <input
        [(ngModel)]="searchText"
        type="text"
        name="searchText"
        class="form-control"
        placeholder="{{ 'ACCOUNT_NAME' | translate }}">
    </div>
  </div>

  <ssi-permissions
    [accounts]="settings.accounts"
    [permissions]="settings.permissions"
    [searchText]="searchText"
    (onTogglePermission)="togglePermission($event)">
  </ssi-permissions>
</div>
