import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CeilPipe } from './ceil.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CeilPipe],
  exports: [CeilPipe]
})
export class CeilModule {}
