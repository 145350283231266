<div class="marketing-analytics-report-container">
  <ssi-report-header report="Marketing Report"
                     [name]=""
                     [dateRangesTemplate]="dateRangesRef"
                     (dateSelected)="reportDateChanged($event)"></ssi-report-header>
  <ssi-report-sidebar icon="ssi-marketing-analytics"
                      color="#D41D68"
                      [navList]="navItems"
                      [actionList]="reportActions"
                      [active]="activeNavItemTag"
                      (navClicked)="scrollTo($event)"></ssi-report-sidebar>

  <div class="report-body-loading"
       *ngIf="loading">
    <div class="loading-container">
      <!-- <i class="ssi ssi-confetti"></i> -->
      <img src="https://www.orlo.app/app/web/production/f44ceeab6551db9be89255d952d86c0d.gif">
      <h3>We're building your report!</h3>
      <p>This may take a few moments
      </p>
    </div>
  </div>

  <div class="report-body"
       *ngIf="!loading">
    <div class="section-container">
      <div class="account-list">
        <div class="account-list-item"
             *ngFor="let account of accounts">
          <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                   variant="fa"></ssi-social-network-icon>
          <span>{{account.name}}</span>
        </div>
      </div>
    </div>

    <div class="section-delimiter"></div>

    <ssi-account-summary id="account-summary"
                         [accounts]="accounts"
                         [dateRanges]="dateRanges"
                         [stats]="totalsStats"></ssi-account-summary>

    <div class="section-delimiter"></div>

    <ssi-video-metrics id="video-metrics"
                       [accounts]="accounts"
                       [dateRanges]="dateRanges"
                       [tagsToInclude]="tagsToInclude"
                       [tagsToExclude]="tagIdsToExclude"></ssi-video-metrics>

    <div class="section-delimiter"></div>

    <ssi-instagram-story-stats
      id="instagram-stories"
      [accounts]="accounts"
      [dateRanges]="dateRanges"
      [tagsToInclude]="tagsToInclude"
      [tagsToExclude]="tagIdsToExclude"></ssi-instagram-story-stats>
    <div class="section-delimiter"></div>

    <!-- common (used on campaign report too) -->
    <ssi-link-clicks-section
      id="link-clicks"
      [isMarketingReport]="true"
      [accounts]="accounts"
      [mapMarkers]="linkClicksLocationStats"
      [filterPeriod]="dateRanges.current"
      [clicksData]="linkClicksStats"
      [tagsToInclude]="tagsToInclude"
      [tagsToExclude]="tagIdsToExclude"
    ></ssi-link-clicks-section>

    <div class="section-delimiter"></div>

    <ssi-organic-reach id="organic-reach"
                       [accounts]="accounts"
                       [dateRanges]="dateRanges"
                       [stats]="timeSeriesStats"
                       [tagsToInclude]="tagsToInclude"
                       [tagsToExclude]="tagIdsToExclude"></ssi-organic-reach>

    <div class="section-delimiter"></div>

    <ssi-brand-sentiment id="brand-sentiment"
                         [accounts]="accounts"
                         [dateRanges]="dateRanges"
                         [stats]="totalsStats"></ssi-brand-sentiment>

    <div class="section-delimiter"></div>

    <ssi-follower-count id="followers"
                        [accounts]="accounts"
                        [dateRanges]="dateRanges"
                        [stats]="totalsStats"></ssi-follower-count>

    <div class="section-delimiter"></div>

    <ssi-post-engagement id="post-engagement"
                         [accounts]="accounts"
                         [dateRanges]="dateRanges"
                         [stats]="timeSeriesStats"
                         [tagsToInclude]="tagsToInclude"
                         [tagsToExclude]="tagIdsToExclude"></ssi-post-engagement>

    <div class="section-delimiter"></div>

    <ssi-top-times-to-post id="top-times-to-post"
                           [accounts]="accounts"
                           [dateRanges]="dateRanges"
                           [stats]="topTimesToPostStats"></ssi-top-times-to-post>

    <div class="section-delimiter"></div>

    <ssi-top-posts id="top-posts"
                   [includeDeletedInTopPosts]="state.params.includeDeletedInTopPosts"
                   [accounts]="accounts"
                   [dateRange]="dateRanges.current"
                   (postsLoaded)="onTopPostsLoaded($event)"
                   [tagsToInclude]="tagsToInclude"
                   [tagsToExclude]="tagIdsToExclude"></ssi-top-posts>

    <div class="section-delimiter"></div>
  </div>

  <ng-template #dateRangesRef>
    <div class="selected-date-ranges">
      <span class="current">{{dateRanges.current.start | date: 'dd MMM yy'}} - {{dateRanges.current.end | date: 'dd MMM yy'}}</span>
      <ng-container *ngIf="dateRanges.previous.start && dateRanges.previous.end">
        <span class="previous">vs</span>
        <span class="previous">{{dateRanges.previous.start | date: 'dd MMM yy'}} - {{dateRanges.previous.end | date: 'dd MMM yy'}}</span>
      </ng-container>
    </div>
  </ng-template>
</div>