import './medal-score.component.scss';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-medal-score',
  templateUrl: './medal-score.component.html',
  styles: []
})
export class MedalScoreComponent implements OnInit {
  @Input() place: number;
  @Input() color? = '#838EAB';
  @Input() variant? = 'small'; // 'big'
  @Input() circleColor? = '#FBF3E2';
  @Input() wreathColor? = '#F0B427';

  constructor() {}

  ngOnInit() {}
}
