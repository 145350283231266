import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchClassPipe } from './branch-class.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [BranchClassPipe],
  exports: [BranchClassPipe]
})
export class BranchClassModule {}
