<ssi-highchart
  [config]="chartConfig"
  *ngIf="!isError"
></ssi-highchart>
<h3
  style="padding-top: 50px;"
  class="muted text-center"
  *ngIf="isError"
>
  <i class="icon-warning-sign"></i> <span translate="ERROR_RENDERING_GRAPH"></span>
</h3>