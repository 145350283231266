import './add-widget.component.scss';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  WidgetTypes,
  widgetTypes,
  widgetTypesIterable,
  WidgetType
} from '../../common/constants';
import { InsightsService, InsightsWidget } from '../../insights.service';

@Component({
  selector: 'ssi-add-widget',
  templateUrl: './add-widget.component.html',
  styles: []
})
export class AddWidgetComponent implements OnInit {
  widgets: InsightsWidget[] = [];
  filteredWidgets: InsightsWidget[] = [];

  searchTerm = '';

  widgetTypes: WidgetTypes = widgetTypes;
  widgetTypesIterable: WidgetType[] = widgetTypesIterable.filter(
    (w) =>
      w.key !== widgetTypes.pie.key &&
      w.key !== widgetTypes.influencers.key &&
      w.key !== widgetTypes.table.key
  );
  selectedWidgetType?: WidgetType;

  @Output() widgetAdded = new EventEmitter<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private insightsService: InsightsService
  ) {}

  async ngOnInit() {
    this.widgets = await this.insightsService.getWidgets();
    console.log('this.widgets: ', this.widgets);
    console.log(this.widgets);

    this.selectWidgetType(undefined);
  }

  filterValues() {
    if (!this.selectedWidgetType) {
      this.filteredWidgets = [...this.widgets];
    } else {
      this.filteredWidgets = this.widgets.filter(
        (w) => w.type === this.selectedWidgetType.key
      );
    }

    this.filteredWidgets = this.filteredWidgets.filter((w) =>
      w.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  selectWidgetType(widgetType?: WidgetType) {
    this.selectedWidgetType = widgetType;
    this.filterValues();
  }
}
