import { Component, Input } from '@angular/core';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import './login-activity.scss';

export async function loginFn(api: API) {
  return api.get('settings/logins').then(({ data }) => data);
}

@Component({
  selector: 'ssi-login-activity',
  templateUrl: './login-activity.component.html',
  styles: []
})
export class LoginActivityComponent {
  static resolve = [
    {
      token: 'logins',
      resolveFn: loginFn,
      deps: [API]
    }
  ];

  @Input() logins: any;

  constructor(public api: API, private state: StateService) {}

  logoutSession(login) {
    login.deleting = true;
    this.api
      .del('settings/logins', { params: { auth_id: login.id } })
      .then(() => {
        this.logins = this.logins.filter((iLogin) => iLogin !== login);
      })
      .finally(() => {
        login.deleting = false;
      });
  }

  return() {
    this.state.go('^');
  }
}
