import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ValidateFiltersComponent } from './validate-posts-filters.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, UIRouterModule, FormsModule],
  declarations: [ValidateFiltersComponent],
  exports: [ValidateFiltersComponent]
})
export class ValidatePostsFiltersModule {}
