import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatNetworkStateModule } from '../live-chat-network-state/live-chat-network-state.module';
import { LiveChatConversationIndicatorsComponent } from './live-chat-conversation-indicators.component';
import './live-chat-conversation-indicators.scss';

@NgModule({
  declarations: [LiveChatConversationIndicatorsComponent],

  exports: [LiveChatConversationIndicatorsComponent],

  imports: [CommonModule, LiveChatNetworkStateModule, TranslateModule]
})
export class LiveChatConversationIndicatorsModule {}
