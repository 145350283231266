export function isNumeric(value: any): boolean {
  return (
    (typeof value === 'number' ||
      (typeof value === 'string' && value.trim() !== '')) &&
    !isNaN(value as number)
  );

  // version 2 - a bit different output when testing with the values below
  // const numValue = parseFloat(value);
  // return !!numValue || numValue === 0;
}

const testValues = [
  0,
  1,
  23234234,
  '234234234',
  '0',
  '5',
  '1.1',
  '-1',
  '-1.234',
  '-234234523523',
  -1,
  -32.1,
  '0x1',
  22.222,
  '0005',
  '   23423',
  '23423   ',
  undefined,
  null,
  NaN,
  '123a',
  'abcd',
  '',
  '   '
];

// testValues.forEach((v) => {
//   console.log(v, isNumeric(v));
// });
