import { environment } from '../../../environments/environment';
import { capitalize } from '../helpers/strings';
import {
  agents as stringAgents,
  applications as stringApplications,
  assigned as stringAssigned,
  april as stringApril,
  are as stringAre,
  august as stringAugust,
  available as stringAvailable,
  busy as stringBusy,
  cache as stringCache,
  chat as stringChat,
  companies as stringCompanies,
  conversation as stringConversation,
  conversations as stringConversations,
  december as stringDecember,
  enabled as stringEnabled,
  february as stringFebruary,
  is as stringIs,
  january as stringJanuary,
  july as stringJuly,
  june as stringJune,
  live as stringLive,
  march as stringMarch,
  may as stringMay,
  message as stringMessage,
  messages as stringMessages,
  mode as stringMode,
  network as stringNetwork,
  november as stringNovember,
  october as stringOctober,
  offline as stringOffline,
  ongoing as stringOngoing,
  online as stringOnline,
  owned as stringOwned,
  push as stringPush,
  requests as stringRequests,
  resize as stringResize,
  response as stringResponse,
  september as stringSeptember,
  state as stringState,
  suggestions as stringSuggestions,
  task as stringTask,
  test as stringTest,
  toggle as stringToggle,
  unassigned as stringUnassigned,
  update as stringUpdate,
  updated as stringUpdated,
  visitors as stringVisitors,
  widget as stringWidget
} from './strings';

export const collections = Object.freeze({
  agents: stringAgents,
  applications: stringApplications,
  companies: stringCompanies,
  conversations: stringConversations,
  messages: stringMessages,
  taskRequests: `${stringTask}${capitalize(stringRequests)}`,
  visitors: stringVisitors
});

export const defaults = Object.freeze({
  avatar: 'https://images.socialsignin.net/avatar/avatar-incoming.png',
  garbageCollectionCycleTimeInMilliseconds: 600000,
  periodOfDateRangeInDays: 3,
  resolutionGracePeriod: 600
});

// @todo: move to non-LC specific constants file; move strings to strings list.

export const keysForStorage = {
  areSuggestionsAvailable:
    `` +
    `${stringLive}-` +
    `${stringChat}-` +
    `${stringAre}-` +
    `${stringSuggestions}-` +
    `${stringAvailable}`,
  conversationResponseCache:
    `` +
    `${stringLive}-` +
    `${stringChat}-` +
    `${stringConversation}-` +
    `${stringResponse}-` +
    `${stringCache}`,
  isPushModeEnabled:
    `` +
    `${stringLive}-` +
    `${stringChat}-` +
    `${stringIs}-` +
    `${stringPush}-` +
    `${stringMode}-` +
    `${stringEnabled}`,
  networkState:
    `` +
    `${stringLive}-` +
    `${stringChat}-` +
    `${stringNetwork}-` +
    `${stringState}`
};

export const months = [
  capitalize(stringJanuary),
  capitalize(stringFebruary),
  capitalize(stringMarch),
  capitalize(stringApril),
  capitalize(stringMay),
  capitalize(stringJune),
  capitalize(stringJuly),
  capitalize(stringAugust),
  capitalize(stringSeptember),
  capitalize(stringOctober),
  capitalize(stringNovember),
  capitalize(stringDecember)
];

export const namespacePrefix = `_orlo_`;
export const orloAccountTypeName = `${capitalize(stringLive)} ${capitalize(
  stringChat
)}`;
export const customerSupportWidgetId = `369d2bba-098d-4683-b82c-c31d003a243b`;
export const customerSupportWidgetNamespace = `${namespacePrefix}${customerSupportWidgetId}`;

export const firebaseProject = {
  credentials: environment['credentials'],
  name: 'net.socialsignin.app'
};

export const responseCacheMaximumAgeInHours: number = 8;

export const routes = Object.freeze({
  accounts: `account/index?with[]=livechat`,
  jwt: `user/livechatVisitorJwt`,
  token: `user/livechat`,
  widget: `account/livechat_v2`,
  widgetDefaults: `account/livechatSchema`
});

export const states = Object.freeze({
  conversation: Object.freeze([
    stringAssigned,
    stringOngoing,
    stringOwned,
    // stringTotal,
    stringUnassigned
  ]),
  network: Object.freeze([stringBusy, stringOffline, stringOnline])
});

export const symbols = Object.freeze({
  creationDatetimeField: `createdAt`,
  messagingTokenField: `fcmToken`,
  networkStateField: `networkState`,
  subjectForMessageNote: `${stringLive}${stringChat}_${stringMessage}`,
  widgetNamespaceForMobile: `m`,
  widgetNamespaceForUser: `u`
});

export const taskTypes = {
  testTask: `${stringTest}.${stringTask}`,
  togglePushMode: `${stringToggle}.${stringPush}.${stringMode}`,
  updateNetworkState: `${stringUpdate}.${stringNetwork}.${stringState}`
};

export const widgetEmissions = {
  conversationUpdated: `${stringConversation}-${stringUpdated}`,
  widgetResize: `${stringWidget}-${stringResize}`
};

// per-company configuration

export const config = Object.freeze({
  collections,
  defaults,
  firebaseProject,
  routes,
  states,
  symbols
});

export default config;
