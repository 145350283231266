<div class="post-engagement-container">

  <div class="section-head">
    <h2>Account Engagement</h2>
    <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
  </div>

  <div class="section-body"
       ssiLoader
       [ssiLoaderVisible]="loading">
    <ssi-highchart [config]="chartConfig"></ssi-highchart>
    <div class="cards-container">
      <div class="card">
        <span class="title">Published posts</span>
        <div class="break-line"></div>
        <ssi-big-number class="count"
                        [number]="currentMessagesOutTotal"
                        [denotation]="{million: 'm', thousand: 'k'}"></ssi-big-number>
      </div>
      <div class="card">
        <span
          class="title"
          ssiTooltip="The Inbound Engagement figure shows the daily number of engagements made with your selected accounts. This number includes comments, private messages, tags and mentions."
          tooltipWindowClass="tooltip-chunky-big"
        >Inbound Engagements</span>
        <div class="break-line"></div>
        <ssi-big-number class="count"
                        [number]="currentMessagesInTotal"
                        [denotation]="{million: 'm', thousand: 'k'}"></ssi-big-number>
      </div>
    </div>
  </div>
</div>