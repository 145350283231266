<div class="tags-input">
  <!-- <div class="tags-input-dropdown-wrap">
    <div class="dropdown" ngbDropdown>
      <div class="dropdown-toggle" ngbDropdownToggle>
        <p>{{condition}}</p>
        <p>Search</p>
        <i class="ssi ssi-arrow-down-new"></i>
      </div>
      <div class="dropdown-menu">
        <ssi-radio-list
          [listItems]="conditionValues"
          [value]="condition"
          (onValueChange)="conditionUpdated($event)"
        ></ssi-radio-list>
      </div>
    </div>
  </div> -->

  <ssi-dropdown-select-2 size="sm"
                         [options]="searchTypesIterable"
                         [yPlacement]=""
                         [xPlacement]=""
                         [(ngModel)]="selectedSearchType"
                         (ngModelChange)="onKeywordSearchTypeChange($event)"
                         [class.compact]="compact">
    <ng-template ssiTemplateSelector="headButton"
                 let-expanded="expanded"
                 let-toggle="toggle">

      <button type="button"
              class="g-btn g-btn-secondary search-type-btn"
              (click)="toggle()">
        <span>{{selectedSearchType.label}}</span><i class="ssi ssi-arrow-down-new"></i>
      </button>
    </ng-template>
  </ssi-dropdown-select-2>

  <div class="tags-input-input-wrap"
       [ngClass]="{'tags-input-input-wrap-focused': inputFocused}">
    <div class="tags-input-input-container custom-scrollbar" [class.compact]="compact">
      <ul class="tags-input-tags">
        <li *ngFor="let tag of tags"
            class="tags-input-tag">
          <span>{{tag}}</span>
          <button class="tags-input-tag-action"
                  (click)="deleteTag(tag)">
            <i class="ssi ssi-delete-close"></i>
          </button>
        </li>
      </ul>
      <input id="tagsInput"
             class="tags-input-input"
             [class.compact]="compact"
             type="text"
             [placeholder]="compact ? 'Search...' : selectedSearchType.placeholder"
             [(ngModel)]="tagsInput"
             [disabled]="tags.length"
             (focus)="inputFocused = true"
             (blur)="inputFocused = false"
             (keyup)="onKeyUp($event)">
      <label for="tagsInput">
        <i class="ssi ssi-correct-search tags-input-input-container-icon" (click)="addTag(tagsInput);"></i>
      </label>
    </div>
  </div>

  <button *ngIf="selectedSearchType === searchTypes.advanced && !compact"
          type="button"
          class="g-btn g-btn-secondary pro-tip-btn"
          [ssiTooltip]="'Pro tips to help you build a query'"
          [tooltipWindowClass]="'tooltip-chunky'"
          (click)="modal.open(proTipModal, {
    windowClass: 'insights-advanced-search-pro-tip-modal',
    centered: true
  })">
    <i class="ssi ssi-pro-tip"></i>
  </button>
</div>


<ng-template #actionLinkShare>
  <div class="tooltip-insights">
    <p class="tooltip-insights-title">Can't share private reports</p>
    <p class="tooltip-insights-body">You can only share public reports. If you would like to share this report please change the access to 'public' in report settings</p>
  </div>
  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template>

<ng-template #proTipModal
             let-close="close">
  <button type="button"
          class="modal-close-button"
          (click)="close()">
    <i class="ssi ssi-addsmall"></i>
  </button>
  <div class="pro-modal-header">
    <h2 class="pro-modal-title">Advanced Search Pro Tips</h2>
    <p>Drill down through the noise and make your results as refined and focused as ever. The advanced search uses boolean operators to allow more complex filters to be run against your Insights data.
      Here's a little cheat sheet to help guide you when you're building a boolean search query:</p>
  </div>
  <div class="pro-modal-body">
    <div class="pro-modal-body-well">
      <h3>Your cheatsheet</h3>
      <div class="pro-modal-body-content">
        <dl class="pro-modal-body-legend">
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-yellow">cat dog mat</span>
          </dt>
          <dd>If you're looking for combinations of words, leave a space between each one. This example will return results that include <strong>all of these words</strong></dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-green">“the cat sat on the mat”</span>
          </dt>
          <dd>For results that contain an exact word or phrase, place speech marks ( “” ) either side. This example will return results that include <strong>the exact phrase</strong></dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-blue">cat | dog</span>
          </dt>
          <dd>To search for results that contain one word or another, use the pipe ( | ) symbol. This example will return <strong>any results that contain either word</strong></dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-orange">cat -dog -mat</span>
          </dt>
          <dd>To remove results that contain a certain word, use the ( - ) minus symbol just before the world you want to filter out. This example will return results that contain cat, <strong>but don't contain dog or mat</strong></dd>
          <dt>
            <span class="pro-modal-body-key pro-modal-body-key-gray">"cat dog"~3</span>
          </dt>
          <dd>Putting a tilde symbol ( ~ ) just after the words in quotes with a number (X) will return results that contain the words, as long as they are no more than X words away from each other. This example will return results that contain the phrases "cat and dog", "the cat hated the dog" or "the dog chased the cat", <strong>but not "the dog wanted to play with the cat”.</strong></dd>
        </dl>
        <img src="https://www.orlo.app/app/web/production/e993c7845534372477d9ee154c59728e.png"
             alt="average first response guidance">
      </div>
    </div>
  </div>
</ng-template>