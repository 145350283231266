import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EngagementAnalyticsSentimentComponent } from './engagement-analytics-sentiment.component';
import { SentimentModule } from '../sentiment/sentiment.module';
import { SentimentTextModule } from '../sentiment/sentiment-text/sentiment-text.module';
import { TotalChangeModule } from '../total-change/total-change.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    SentimentModule,
    SentimentTextModule,
    TotalChangeModule,
    TranslateModule
  ],
  declarations: [EngagementAnalyticsSentimentComponent],
  exports: [EngagementAnalyticsSentimentComponent]
})
export class EngagementAnalyticsSentimentModule {}
