import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetsGridComponent } from './widgets-grid.component';

import { KtdGridModule } from '../../../../../../common/components/ktd-grid/grid.module';
import { LoaderModule } from '../../../../../../common/directives';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';

import { WidgetInboxMessageModule } from '../widgets/widget-inbox-message/widget-inbox-message.module';
import { WidgetInboxConversationsModule } from '../widgets/widget-inbox-conversations/widget-inbox-conversations.module';
import { WidgetLiveXStreamModule } from '../widgets/widget-live-x-stream/widget-live-x-stream.module';
import { WidgetRssFeedsModule } from '../widgets/widget-rss-feeds/widget-rss-feeds.module';
import { WidgetMarketingPostsModule } from '../widgets/widget-marketing-posts/widget-marketing-posts.module';
import { WidgetMonitoringStreamModule } from '../widgets/widget-monitoring-stream/widget-monitoring-stream.module';
import { WidgetSocialWallModule } from '../widgets/widget-social-wall/widget-social-wall.module';
import { WidgetProfileSearchModule } from '../widgets/widget-profile-search/widget-profile-search.module';

@NgModule({
  imports: [
    CommonModule,
    KtdGridModule,
    LoaderModule,
    TooltipModule,
    WidgetInboxMessageModule,
    WidgetInboxConversationsModule,
    WidgetLiveXStreamModule,
    WidgetRssFeedsModule,
    WidgetMarketingPostsModule,
    WidgetMonitoringStreamModule,
    WidgetSocialWallModule,
    WidgetProfileSearchModule
  ],
  exports: [WidgetsGridComponent],
  declarations: [WidgetsGridComponent]
})
export class WidgetsGridModule {}
