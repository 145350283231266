import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { Checkbox2Component } from './checkbox-2.component';

import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, TooltipModule],
  declarations: [Checkbox2Component],
  exports: [Checkbox2Component]
})
export class Checkbox2Module {}
