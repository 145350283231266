import { Component, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';
import { API, Account } from '@ui-resources-angular';
import { workflowAccounts } from '../../../../../../common-resolves';
import { StateService } from '@uirouter/angular';
import './competitor-report-list.component.scss';
import {
  Report,
  reportsFn,
  ProfileGroupType,
  ProfileGroup,
  competitorsFn
} from '../../../competitor-analysis.component';
import { TooltipDirective } from '../../../../../../../common/directives/tooltip/tooltip.directive';
import { CompanyService } from '../../../../../../../common/services/company/company.service';
import { customerSupportWidgetNamespace } from '../../../../../../../../../library/constants/live-chat';

export interface Competitor {
  name: string;
  colour: string;
  type: ProfileGroupType;
  profiles: {
    account_type_id: number;
    social_id: string;
    username: string;
    name?: string;
    avatar_url?: string;
  }[];
  id?: string;
}

@Component({
  selector: 'ssi-competitor-report-list',
  templateUrl: './competitor-report-list.component.html',
  styles: []
})
export class CompetitorReportListComponent implements OnInit {
  static resolve = [
    {
      token: 'reports',
      resolveFn: reportsFn,
      deps: [API, CompanyService]
    }
  ];

  @Input() reports: Report[];
  @Input() profileGroups: ProfileGroup[];
  @Input() workflowAccounts: Account[];
  rowToDeleteIndex: number;

  @ViewChild('deleteReportTooltip') deleteReportTooltip: TooltipDirective;

  usedFeatures: {
    competitors: boolean;
    brands: boolean;
    reports: boolean;
  };

  competitorAnalysisEnabled = false;

  constructor(
    private api: API,
    private state: StateService,
    private company: CompanyService,
    private renderer: Renderer2
  ) {}

  async ngOnInit() {
    this.competitorAnalysisEnabled = await this.company.hasFeatureAccess(
      'COMPETITIVE_ANALYSIS'
    );
    if (!this.competitorAnalysisEnabled) {
      this.renderer.addClass(document.body, 'dark-bg');
    }

    this.usedFeatures = {
      competitors:
        this.profileGroups &&
        this.profileGroups.some(
          (group) => group.type === ProfileGroupType.Competitor
        ),
      brands:
        this.profileGroups &&
        this.profileGroups.some(
          (group) => group.type === ProfileGroupType.Brand
        ),
      reports: this.reports && this.reports.length > 0
    };
  }

  focusDelete(index: number) {
    this.rowToDeleteIndex = index;
  }

  async deleteReport(selectedReport: Report) {
    this.deleteReportTooltip.hide();

    this.api
      .delete(`competitiveAnalysis/report?id=${selectedReport.id}`)
      .then((res) => {
        if (res.status === 200) {
          this.reports = this.reports.filter(
            (report: Report) => selectedReport.id !== report.id
          );
        }
      });
  }

  editReport(selectedReport: Report) {
    this.state.go('^.reportEdit', {
      reportId: selectedReport && selectedReport.id
    });
  }

  viewReport(report: Report) {
    this.state.go('^.report', {
      reportId: report.id,
      reportName: report.name
    });
  }

  toggleLiveChat() {
    window.postMessage(
      { id: `${customerSupportWidgetNamespace}-widget-toggle` },
      '*'
    );
  }
}
