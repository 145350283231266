import './manage-accounts.component.scss';
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AccountModel,
  User,
  VanityDomainModel,
  Account,
  VanityDomain,
  ProfileModel,
  AccountType,
  getSocialNetwork,
  API
} from '@ui-resources-angular';
import {
  authUser,
  colleagues,
  authProviders
} from '../../../../common-resolves';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { orderBy } from 'lodash-es';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemoveAccountModalComponent } from './remove-account-modal/remove-account-modal.component';
import {
  AccountAuthService,
  AccountAuthSession,
  AuthProvider,
  AuthSessionOption,
  AuthSessionOptionsResult,
  AuthSessionOptionType
} from '../../../../../common/services/account-auth/account-auth.service';
import { Colleague } from '../../../../../common/services/api';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import {
  trackByNestedProperty,
  trackByProperty
} from '../../../../../common/util';
import { mapToIterable } from '../../../../../common/utils';
import { AddCustomProviderModalComponent } from './add-custom-provider-modal/add-custom-provider-modal.component';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { ProfileHelperService } from '../../../../../common/services/profile-helper/profile-helper.service';

export function allAccountsFn(accountModel: AccountModel) {
  return accountModel.getAll();
}

export function vanityDomainsFn(vanityDomainModel: VanityDomainModel) {
  return vanityDomainModel.findAll();
}

const accountsMap = new WeakMap<ManageAccountsComponent, Account[]>();

@Component({
  selector: 'ssi-manage-accounts',
  templateUrl: './manage-accounts.component.html'
})
export class ManageAccountsComponent implements OnInit, OnDestroy {
  static resolve = [
    authUser,
    colleagues,
    authProviders,
    {
      token: 'allAccounts',
      resolveFn: allAccountsFn,
      deps: [AccountModel]
    },
    {
      token: 'vanityDomains',
      resolveFn: vanityDomainsFn,
      deps: [VanityDomainModel]
    }
  ];

  @Input() authUser: User;
  @Input() colleagues: Colleague[];

  get allAccounts(): Account[] {
    return accountsMap.get(this);
  }

  @Input()
  set allAccounts(accounts: Account[]) {
    accountsMap.set(this, accounts);
    this.orderedAccounts = orderBy(accounts, ['account_type_name', 'name']);
  }

  @Input() vanityDomains: VanityDomain[];
  @Input() authProviders: AuthProvider[];
  customProviders: any = [
    {
      id: 'whatsapp',
      name: 'Twilio WhatsApp',
      label: 'WhatsApp',
      socialNetwork: getSocialNetwork(AccountType.TwilioWhatsApp)
    },
    {
      id: 'sms',
      name: 'Twilio SMS',
      label: 'SMS',
      socialNetwork: getSocialNetwork(AccountType.TwilioSMS)
    }
  ];

  hasAdministerAccountsPermission: boolean;
  hasTwilioEnabled = false;
  defaultVanityDomain: VanityDomain;
  loadingTracker = this.asyncTracker.create();
  orderedAccounts: Account[];

  edit?: {
    account: Account;
    form: {
      alias: string;
      default_vanity_domain_id: number | null;
    };
  };

  nullSelectValue = null;
  authSession: AccountAuthSession;
  authSessionOptions: AuthSessionOptionsResult;
  authSessionParams;

  trackById = trackByProperty('id');
  trackByName = trackByProperty('name');
  trackByProfileId = trackByNestedProperty('profile.id');

  provider: AuthProvider;
  uuid: string;
  popupWindow: any;

  constructor(
    private asyncTracker: AsyncTrackerFactory,
    private profileModel: ProfileModel,
    private ngbModal: NgbModal,
    private accountAuth: AccountAuthService,
    private popup: PopupService,
    private translate: TranslateService,
    private injector: Injector,
    private companyService: CompanyService,
    private api: API,
    private workflowManager: WorkflowManagerService,
    private profileHelper: ProfileHelperService
  ) {}

  async ngOnInit() {
    try {
      this.companyService.hasFeatureAccess('TWILIO').then((enabled) => {
        this.hasTwilioEnabled = !!enabled;
      });
      this.hasAdministerAccountsPermission = this.authUser.hasCompanyPermission(
        'administer_accounts'
      );
      if (!Array.isArray(this.vanityDomains)) {
        throw new Error(
          `Value for 'manage accounts vanity domains' not in expected format.`
        );
      }

      const { data: accountsObject } = await this.api.get(
        'account/index?with[]=company_accounts&with[]=nextdoor_agency'
      );
      const accountsIncludingCompany: Account[] = mapToIterable(accountsObject);
      const companyAccount = accountsIncludingCompany.find(
        (account) => account.account_type_id === '1'
      );
      this.defaultVanityDomain = {
        domain: companyAccount.default_vanity_domain,
        id: companyAccount.default_vanity_domain_id
      } as any;

      this.vanityDomains = this.vanityDomains.filter(
        (domain) => domain.id !== this.defaultVanityDomain.id
      );

      this.authPopupCallback = this.authPopupCallback.bind(this);
      window.addEventListener('message', this.authPopupCallback, false);
    } catch (error) {
      console.error(error);
    }
  }

  async ngOnDestroy() {
    window.removeEventListener('message', this.authPopupCallback, false);
    await this.destroySession();
  }

  authPopupCallback(event) {
    if (event.origin === location.origin || 'https://www.orlo.app') {
      const { data } = event;
      if (data.uuid) {
        this.uuid = data.uuid;
      } else if (data.queryString) {
        this.createAuthSession(data);
      }
    } else {
      return;
    }
  }

  addCustomProvider(provider) {
    const modal = this.ngbModal.open(AddCustomProviderModalComponent);
    modal.componentInstance.provider = provider;
  }

  showRemoveAccountModal(account: Account) {
    const modal = this.ngbModal.open(RemoveAccountModalComponent);
    modal.componentInstance.account = account;
    modal.result.then(() => {
      this.allAccounts = this.allAccounts.filter(
        (iAccount) => iAccount !== account
      );
    });
  }

  async startEdit(account: Account) {
    this.edit = {
      account,
      form: {
        alias: account.displayName,
        default_vanity_domain_id:
          account.default_vanity_domain_id === this.defaultVanityDomain.id
            ? null
            : account.default_vanity_domain_id
      }
    };
  }

  cancelEdit() {
    this.edit = null;
  }

  saveEdit() {
    try {
      if (!Array.isArray(this.vanityDomains)) {
        throw new Error(
          `Value for 'validate posts box campaigns' not in expected format.`
        );
      }

      this.edit.account.update(this.edit.form).then(() => {
        if (this.edit.form.default_vanity_domain_id) {
          this.edit.account.default_vanity_domain = this.vanityDomains.find(
            ({ id }) => id === this.edit.form.default_vanity_domain_id
          ).domain;
        } else {
          this.edit.account.default_vanity_domain = this.defaultVanityDomain.domain;
        }

        this.cancelEdit();
      });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async openAuthPopup(provider: AuthProvider) {
    this.provider = provider;
    this.popupWindow = window.open(
      `#/settings/auth-popup?id=${provider.id}`,
      'auth',
      'width=400,height=600'
    );
    await this.destroySession();
  }

  async createAuthSession(data) {
    this.popupWindow.close();
    this.authSession = await this.accountAuth.createAuthenticatedSession(
      data,
      this.provider,
      this.uuid
    );
    this.authSessionOptions = await this.authSession.getSessionOptions({
      type: AuthSessionOptionType.Add
    });

    if (this.authSessionOptions.options.length === 0) {
      this.popup.alert({
        isError: true,
        message: this.translate.instant(
          'EITHER_ALL_OF_THE__ACCOUNTTYPE__ACCOUNTS_YOU_HAVE_ACCESS_TO_HAVE_ALREADY_BEEN_ADDED_TO_THE_SOCIALSIGNIN_PLATFORM_OR_YOU_ARE_NOT_AN_ADMINISTRATOR_ON_THE__ACCOUNTTYPE__PAGE_YOU_ARE_TRYING_TO_ADD',
          { accountType: this.provider.socialNetwork.accountTypeLabel }
        )
      });
      await this.destroySession();
    }
  }

  async addAccount(option: AuthSessionOption) {
    option.profile['adding'] = true;
    try {
      await this.authSession.addAccount(option);
      option.profile['added'] = true;
      this.workflowManager.loadWorkflow(0, { forceRefresh: true });
    } finally {
      option.profile['adding'] = false;
    }
  }

  async destroySession() {
    if (this.authSession) {
      await this.authSession.clearSession();
      this.authSession = null;
      this.authSessionOptions = null;
    }
  }

  loadAllAccountsDashboard() {
    this.workflowManager.loadWorkflow(0, {
      forceRefresh: true,
      redirectToDashboard: true
    });
  }

  viewProfile(account: Account) {
    this.profileHelper.viewProfile({
      accountId: account.id,
      profileId: account.social_id
    });
  }
}
