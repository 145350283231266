import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxSearchResultsOverlayComponent } from './inbox-search-results-overlay.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [InboxSearchResultsOverlayComponent],
  exports: [InboxSearchResultsOverlayComponent],
  entryComponents: [InboxSearchResultsOverlayComponent]
})
export class InboxSearchResultsOverlayModule {}
