import { Injectable, Injector } from '@angular/core';
import { fromEvent, Subject, interval } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { VisiblityChangeListener } from 'visibilityjs';

import { Conversation, OutboxModel } from '@ui-resources-angular';

@Injectable({ providedIn: 'root' })
export class PageVisibilityService {
  newConversation = new Subject<Partial<Conversation>>();

  constructor() {}
  delayedHide({ onHide, onShow, delay }) {
    const loadVisibility: any = () => import('visibilityjs');

    let visibilityTimeout;
    let isHidden = false;
    let listener: VisiblityChangeListener;

    const cancelTimeout = () => {
      if (visibilityTimeout) {
        clearTimeout(visibilityTimeout);
        visibilityTimeout = null;
      }
    };

    loadVisibility().then((visibility: any) => {
      listener = visibility.change(
        (e: Event, state: 'hidden' | 'visible' | 'prerender') => {
          if (state === 'hidden') {
            visibilityTimeout = setTimeout(() => {
              isHidden = true;
              onHide();
            }, delay);
          } else {
            cancelTimeout();

            if (isHidden) {
              isHidden = false;
              onShow();
            }
          }
        }
      );
    });

    return () => {
      // deregister function
      cancelTimeout();
      loadVisibility().then((visibility) => {
        visibility.unbind(listener);
      });
    };
  }
}
