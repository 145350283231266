import './widget-default-config.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import { widgetTypes } from '../../../constants';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';

@Component({
  selector: 'ssi-widget-default-config',
  templateUrl: './widget-default-config.component.html',
  styles: []
})
export class WidgetDefaultConfigComponent implements OnInit {
  @Input() widget: DashboardWidget;

  widgetTypes = widgetTypes;
  widgetTitleMaxLength = 60;

  constructor() {}

  ngOnInit() {}
}
