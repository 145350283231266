import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { WidgetMarketingPostsComponent } from './widget-marketing-posts.component';
import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../../../common/components/social-network-icon/social-network-icon.module';
import { TemplateSelectorModule } from '../../../../../../../common/directives/template-selector/template-selector.module';
import { OutboxPostListModule } from '../../../../../../../common/components/outbox-post-list/outbox-post-list.module';
import { TooltipModule } from '../../../../../../../common/directives/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [WidgetMarketingPostsComponent],
  imports: [
    CommonModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    TemplateSelectorModule,
    FormsModule,
    OutboxPostListModule,
    TooltipModule,
    TranslateModule
  ],
  declarations: [WidgetMarketingPostsComponent]
})
export class WidgetMarketingPostsModule {}
