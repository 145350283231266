import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxPostListNoResultsComponent } from './outbox-post-list-no-results.component';
import { AutoHeightModule } from '../../../directives/auto-height/auto-height.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, AutoHeightModule, TranslateModule],
  declarations: [OutboxPostListNoResultsComponent],
  exports: [OutboxPostListNoResultsComponent]
})
export class OutboxPostListNoResultsModule {}
