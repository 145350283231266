import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxSidebarComponent } from './inbox-sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { InboxUsefulThingsModule } from '../inbox-useful-things/inbox-useful-things.module';
import {
  NgbCollapseModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';
import { RequirePermissionModule } from '../../../../common/directives/require-permission/require-permission.module';
import { UIRouterModule } from '@uirouter/angular';
import { JsonStringifyModule } from '../../../../common/pipes/json-stringify/json-stringify.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    InboxUsefulThingsModule,
    NgbCollapseModule,
    RequirePermissionModule,
    NgbPopoverModule,
    UIRouterModule,
    JsonStringifyModule
  ],
  declarations: [InboxSidebarComponent],
  exports: [InboxSidebarComponent]
})
export class InboxSidebarModule {}
