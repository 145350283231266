import './widget-live-x-stream-config.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { AccountType } from '@ui-resources-angular';

import { Account, AccountModel } from '@ui-resources-angular';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { ROW_HEIGHT } from '../../widgets-grid/widgets-grid.component';

export interface WidgetLiveXStreamQuery {
  words: {
    all: string[];
    any: string[];
    none: string[];
  };
  hashtags: {
    all: string[];
    any: string[];
    none: string[];
  };
  author: {
    any: string[];
  };
  language?: string;
  include_retweet?: boolean;
}

@Component({
  selector: 'ssi-widget-live-x-stream-config',
  templateUrl: './widget-live-x-stream-config.component.html',
  styles: []
})
export class WidgetLiveXStreamConfigComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() widget: DashboardWidget;
  @Output() loaded = new EventEmitter<boolean>();
  @Output() onFormValidityChange = new EventEmitter<boolean>();

  widgetTitleMaxLength = 60;

  twitterAccounts: Account[] = [];
  selectedAccount: Account;

  widgetQuery: WidgetLiveXStreamQuery = {
    words: {
      all: [],
      any: [],
      none: []
    },
    hashtags: {
      all: [],
      any: [],
      none: []
    },
    author: {
      any: []
    }
  };

  containerHeight: number; // - widget header height
  protected destroyed$ = new Subject<void>();

  constructor(private accountModel: AccountModel) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['widget'] && changes['widget'].currentValue) {
      this.containerHeight = this.widget.display_properties.h * ROW_HEIGHT - 48; // - widget header height
    }
  }

  async ngOnInit() {
    this.onFormValidityChange.emit(false);
    const allAccounts = await this.accountModel.findAccounts();
    this.twitterAccounts = allAccounts.filter(
      (account) => account.account_type_name === AccountType.Twitter
    );

    if (
      this.widget &&
      this.widget.apiSearchParams &&
      this.widget.apiSearchParams.hasOwnProperty('query')
    ) {
      this.widgetQuery = { ...this.widget.apiSearchParams.query };
    }

    if (
      this.widget &&
      this.widget.apiSearchParams &&
      this.widget.apiSearchParams.hasOwnProperty('account_id')
    ) {
      this.selectedAccount = this.twitterAccounts.find(
        (account) => account.id === this.widget.apiSearchParams.account_id
      );
    }
  }

  onSelectedAccountsChange(event: Account[]) {
    this.widget.apiSearchParams = {
      ...this.widget.apiSearchParams,
      account_id: this.selectedAccount.id
    };
  }

  onTagChange(tags: string[]) {
    this.widget.apiSearchParams = {
      ...this.widget.apiSearchParams,
      query: { ...this.widgetQuery }
    };
    console.log('this.widgetQuery:', this.widgetQuery);

    const queryArray = Object.values(this.widgetQuery);

    const hasConditions = queryArray.some((conditions) =>
      Object.values(conditions).some(
        (condition: Array<any>) => condition.length > 0
      )
    );
    if (this.selectedAccount.id && hasConditions) {
      this.onFormValidityChange.emit(true);
    } else {
      this.onFormValidityChange.emit(false);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
