<div class="big-chart-section">
  <div class="section-chart-wrap">
    <div class="top-row">
      <div class="sentiments-area">
        <div
          class="sentiment-block"
          *ngFor="let s of sentimentsConstIterable | reverse"
        >
          <span
            [ngStyle]="{'background-color': s.color2}"
            class="sentiment-color"
          ></span><span
            class="sentiment-label"
            translate="{{s.labelNew}}"
          ></span>
        </div>
      </div>
    </div>
    <div class="table-container">
      <table
        class="theme-map-table"
        [class.full-height]="rows && rows.length > 5"
      >
        <tr
          class="t-header-row"
          height="40"
        >
          <th class="dropdown-table-header">
            <ssi-dropdown-select-2
              headButtonStyleVariant="inbox"
              [options]="breakdownFields"
              [(ngModel)]="selectedBreakdownField"
              (ngModelChange)="headers && headers.length && requestData()"
              name="selectedBreakdownField"
            ></ssi-dropdown-select-2>
          </th>
          <th
            *ngFor="let heading of headers"
            [class.blank]="!heading"
            class="t-header"
          >
            <div class="content">
              <span
                [ssiTooltip]="heading.name"
                tooltipWindowClass="tooltip-chunky"
                [tooltipAppendToBody]="true"
              >{{heading.name}}</span>
              <button
                class="content-action"
                (click)="removeTheme(heading.name)"
              ><i class="ssi ssi-small-delete"></i></button>
            </div>
          </th>
          <th
            class="t-header action-header"
            [class.blank]="!headers"
          >
            <button
              class="theme-search-button"
              (click)="$event.stopPropagation(); showThemeAdding = true"
              cdkOverlayOrigin
              #addThemeTriggerOrigin="cdkOverlayOrigin"
              [ssiTooltip]="'You can create a maximum of 10 theme searches for this widget'"
              tooltipWindowClass="tooltip-chunky-big"
              [tooltipAppendToBody]="true"
              [disabled]="headers && headers.length === 10"
            >
              <i class="ssi ssi-add-small"></i>
              Theme search
            </button>
          </th>
        </tr>
        <tbody
          class="loading-overlay"
          [class.full-height]="!themeLoaded"
          ssiLoader
          ssiLoaderVariant="white"
          ssiLoaderText="Loading your search results..."
          [ssiLoaderVisible]="!themeLoaded"
        >
          <tr
            *ngFor="let row of rows"
            class="t-row"
          >
            <td class="t-breakdown">{{row.name}}</td>
            <td
              height="40"
              class="table-cell-data"
              [ngClass]="{'has-data': value.count}"
              [ngStyle]="{'background-color': value.sentimentColor}"
              *ngFor="let value of row.columns"
            >
              <div
                *ngIf="value.count"
                (click)="openDrilldown(value, row.name)"
              >{{value.count}} posts</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="themeLoaded && !rows.length" class="theme-map-table-prompt">
        <div class="theme-map-table-prompt-wrap">
          <i class="ssi ssi-custom-theme-search"></i>
          <h2>Add custom themes</h2>
          <p>Run searches to find themes across your results and select the data point you want to report on</p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  #actionsMenu="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="addThemeTriggerOrigin"
  [cdkConnectedOverlayOpen]="showThemeAdding"
  [cdkConnectedOverlayFlexibleDimensions]="false"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="showThemeAdding = false"
>
  <div class="add-theme-window">
    <h3>Add theme search</h3>
    <div class="form-field">
      <label
        [tooltipTitle]="'Thematic Search'"
        [ssiTooltip]="'THEMATIC_SEARCH_ALLOWS_YOU_TO_FIND_WORDS_OR_PHRASES_THAT_ARE_SIMILAR_TO_YOUR_SEARCH_TERM' | translate"
        tooltipWindowClass="tooltip-chunky-big"
        [tooltipAppendToBody]="true"
      >
        Thematic search<i class="ssi ssi-information"></i>
      </label>
      <input
        #thematic
        type="text"
        class="orlo-text-input"
        [class.invalid]="thematic.errors?.required"
        [placeholder]="'Type a thematic search...'"
        [(ngModel)]="thematicSearch"
        name="thematic"
        required
      />
    </div>
    <div class="action-buttons">
      <button
        class="action-button"
        (click)="showThemeAdding = false"
      >Cancel</button>
      <button
        class="action-button"
        (click)="requestDataAndAddColumn()"
        [disabled]="!thematicSearch"
      >Add theme</button>
    </div>
  </div>
</ng-template>