import './confirm-leave-modal.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-confirm-leave-modal',
  templateUrl: './confirm-leave-modal.component.html'
})
export class ConfirmLeaveModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  close(value: boolean) {
    this.activeModal.close(value);
  }
}
