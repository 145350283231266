<p class="validation-filters-title">Filter your results</p>

<div class="validation-filters-wrap">
    <ul *ngIf="showFilters === 'parent'" class="validation-filters reset-list-style">
        <li
            class="validation-filter"
            [ngClass]="{'validation-filter-active': activeFilters.account.length}"
            (click)="changeFilters('account')">
            Accounts
            <i class="fa fa-circle validation-filter-status"></i>
        </li>
        <li
            class="validation-filter"
            [ngClass]="{'validation-filter-active': activeFilters.users.length, 'validation-filter-disabled': activeFilters.account.length === 0}"
            (click)="changeFilters('users')">
            Users
            <i class="fa fa-circle validation-filter-status"></i>
        </li>
        <li
            class="validation-filter"
            [ngClass]="{'validation-filter-active': activeFilters.campaign.length, 'validation-filter-disabled': activeFilters.account.length === 0}"
            (click)="changeFilters('campaign')">
            Campaign
            <i class="fa fa-circle validation-filter-status"></i>
        </li>
        <li
            class="validation-filter"
            [ngClass]="{'validation-filter-active': activeFilters.overdueScheduledPost, 'validation-filter-disabled': activeFilters.account.length === 0}"
            (click)="toggleFilterBool('overdueScheduledPost')">
            Overdue scheduled post
            <i class="fa fa-circle validation-filter-status"></i>
        </li>
    </ul>
</div>

<div class="validation-filters-wrap" *ngIf="showFilters === 'account'">
    <div class="validation-filters-head">
        <p class="validation-filters-subtitle">Accounts</p>
        <ul class="validation-filters-actions">
            <li (click)="toggleSelectAll('select', 'account')">Select all</li>
            <li (click)="toggleSelectAll('deselect', 'account')">Deselect all</li>
        </ul>
    </div>

    <div class="validation-filters-scroll">
        <ul class="validation-filters">
            <li><input
                class="filters-text-filter"
                type="text"
                (keyup)="filterByText($event, 'allAccounts')"
                placeholder="Search Accounts"></li>
            <li *ngFor="let account of allAccounts">
                <input
                id="{{account.id}}"
                class="validation-filter-input"
                (change)="getFilterValues($event, 'account')"
                type="checkbox"
                name="{{'users-'+account.id}}"
                value="{{account.id}}"
                [checked]="activeFilters.account.includes(account.id)">
                <label class="validation-filter" for="{{account.id}}">
                    {{account.displayName}}
                    <i class="fa fa-circle validation-filter-status"></i>
                    <i class="fa-fw validation-filter-input-icon" [ngClass]="account.socialNetwork.icon.web"></i>
                </label>
            </li>
        </ul>
    </div>
</div>

<div class="validation-filters-wrap" *ngIf="showFilters === 'users'">
    <div class="validation-filters-head">
        <p class="validation-filters-subtitle">Users</p>
    </div>
    <div class="validation-filters-scroll">
        <ul class="validation-filters">
            <li><input
                class="filters-text-filter"
                type="text"
                (keyup)="filterByText($event, 'activeColleagues')"
                placeholder="Search Users"></li>
            <li *ngFor="let colleague of activeColleagues">
                <input
                id="{{colleague.id}}"
                class="validation-filter-input"
                type="radio"
                name="users"
                [(ngModel)]="filters.users"
                (click)="toggleOptionRadioValue($event, 'users')"
                value="{{colleague.id}}">
                <label class="validation-filter" for="{{colleague.id}}">
                {{colleague.fullName}}
                <i class="fa fa-circle validation-filter-status"></i>
                </label>
            </li>
        </ul>
    </div>
</div>

<div class="validation-filters-wrap"  *ngIf="showFilters === 'campaign'">
    <div class="validation-filters-head">
        <p class="validation-filters-subtitle">Campaigns</p>
    </div>
    <div class="validation-filters-scroll">
        <ul class="validation-filters">
            <li><input
                class="filters-text-filter"
                type="text"
                (keyup)="filterByText($event, 'allCampaigns')"
                placeholder="Search Campaigns"></li>
            <li *ngFor="let campaign of allCampaigns">
                <input
                id="{{campaign.id}}"
                class="validation-filter-input"
                type="radio"
                name="users"
                [(ngModel)]="filters.campaign"
                (click)="toggleOptionRadioValue($event, 'campaign')"
                value="{{campaign.id}}">
                <label class="validation-filter" for="{{campaign.id}}">
                    {{campaign.name}}
                    <i class="fa fa-circle validation-filter-status"></i>
                </label>
            </li>
        </ul>
    </div>
</div>
        
<div class="filter-actions">
    <button
        *ngIf="showFilters !== 'parent'"
        class="apply-filter-button"
        (click)="changeFilters('parent')">
        Back to Filters
    </button>
    <button
        class="apply-filter-button"
        [ngClass]="{'apply-filter-button-primary': this.filters.account.length !== 0}"
        (click)="updateValidations(1)">
        Apply Filter
    </button>
</div>