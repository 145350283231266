export function mapNumberOnDifferentScale(
  num: number,
  scaleA: number[],
  scaleB: number[]
): number {
  const percentage = (num - scaleA[0]) / (scaleA[1] - scaleA[0]);

  const result = percentage * (scaleB[1] - scaleB[0]) + scaleB[0];

  return result;
}

// // EXAMPLE / usage
// // 0.25 on [-2, 2] scale maps to 2.8125 on [0, 5] scale
// const num = 0.25;
// const mapped = mapNumberOnDifferentScale(num, [-2, 2], [0, 5]);
// console.log('mapped: ', mapped); // 2.8125
