<div class="reauth-accounts">
  <div class="settings-nav">
    <orlo-button
      leadingIcon="ssi ssi-chevron-left"
      [state]="'primary'"
      textColor="#14bae3"
      bgColor="#f8f9fc"
      [text]="'Back'"
      (btnClick)="return()"
    ></orlo-button>
    <ul>
      <li><i
          class="ssi ssi-dashboard"
          uiSref="auth.settings.index"
        ></i></li>
      <li><i
          class="ssi ssi-mysettings"
          uiSref="auth.settings.mysettings"
        ></i></li>
      <li><i
          class="ssi ssi-websitewidget"
          uiSref="auth.settings.accountsettings"
        ></i></li>
      <li><i
          class="ssi ssi-companysettings"
          uiSref="auth.settings.companysettings"
        ></i></li>
    </ul>
    <h1>Re-authenticate accounts</h1>
  </div>
  <div
    class="alert alert-info"
    [hidden]="canReAuthAccounts || totalReauthAccounts === 0"
  >
    <div translate="YOU_NEED_THE_ADMINISTER_ACCOUNTS_COMPANY_PERMISSION_TO_BE_ABLE_TO_REAUTHENTICATE_SOCIAL_ACCOUNTS">
    </div>
  </div>
  <div class="flex-grid container">
    <div class="rounded-container extra-padding">
      <h2 translate="REAUTHENTICATE_YOUR_SOCIAL_ACCOUNTS"></h2>
      <p
        translate="FROM_TIME_TO_TIME_SOCIAL_NETWORKS_UPDATE_THEIR_API_AND_CHANGE_PERMISSIONS_TO_MAKE_SURE_THAT_YOU_ARE_GETTING_THE_MOST_OUT_OF_ORLO_WE_MAY_NEED_TO_GATHER_EXTRA_PERMISSIONS_FROM_YOU">
      </p>
    </div>
    <div
      class="rounded-container"
      *ngFor="let row of providerAccounts; trackBy:trackByProviderName"
    >
      <h3>
        <i [class]="row.provider.socialNetwork.icon.web"></i>
        <span
          translate="_ACCOUNTTYPE__PAGES"
          [translateParams]="{accountType: row.provider.name}"
        >
        </span>
      </h3>
      <ul class="reauth-list">
        <li *ngFor="let account of row.accounts; trackBy:trackById">
          <img
            class="profile-small img-circle"
            [src]="account.picture"
          >
          <label class="account-name">
            {{ account.displayName }}
          </label>
          &nbsp; | &nbsp;
          <label
            class="added-by"
            *ngIf="account.createdBy"
            translate="ADDED_BY__NAME_"
            [translateParams]="{name: account.createdBy.fullName}"
          >
          </label>
          &nbsp; | &nbsp;
          <label
            *ngIf="account.last_reported_reauth_at"
            translate="REAUTH_REQUIRED_SINCE__DATE_"
            [translateParams]="{date: (account.last_reported_reauth_at | date:'medium')}"
          >
          </label>
        </li>
      </ul>
      <div class="reauth-action">
        <orlo-button
          [text]="'REAUTHENTICATE_ACCOUNTS' | translate"
          (btnClick)="loadingTracker.add(reauthProvider(row))"
          [textColor]="'#fff'"
          leadingIcon="ssi ssi-update"
          [bgColor]="'#14bae3'"
          ssiTooltip="Facebook Groups associated with the new Pages experience cannot be re-authenticated"
          [tooltipAppendToBody]="true"
          tooltipWindowClass="tooltip-chunky"
          [tooltipDisabled]="row.id !== AccountTypeId.FacebookGroup"
        ></orlo-button>
      </div>
    </div>

    <div
      class="rounded-container no-accounts"
      *ngIf="totalReauthAccounts === 0"
    >
      <i class="ssi ssi-confetti"></i>
      <h3 translate="YOURE_ALL_UP_TO_DATE">
      </h3>
      <p translate="TIME_TO_PUT_THE_KETTLE_ON"></p>
    </div>
  </div>
</div>

<div
  class="loading"
  *ngIf="loadingTracker.active"
></div>