import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxLanguageSelectorComponent } from './inbox-language-selector.component';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TranslateModule, TooltipModule, FormsModule],
  declarations: [InboxLanguageSelectorComponent],
  exports: [InboxLanguageSelectorComponent]
})
export class InboxLanguageSelectorModule {}
