import './outbox-post.component.scss';
import {
  Input,
  Component,
  OnInit,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  Injector,
  ViewChild,
  Inject,
  TemplateRef,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Advert } from '../../../modules/auth/marketing/advertising/advert-model/advert-model.service';
import {
  Outbox,
  UserModel,
  OutboxModel,
  User,
  ProfileModel,
  OutboxPublisherMention,
  OutboxMessageType,
  AutoComment
} from '@ui-resources-angular';
import { OutboxTagsService, Tag, TagType } from '../../services/api';
import { CompanyService } from '../../services/company/company.service';
import { PopupService } from '../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { Nl2brPipe } from '../../pipes/nl2br/nl2br.pipe';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { OutboxNotesModalComponent } from '../outbox-notes-modal/outbox-notes-modal.component';
import { OutboxCampaignModalComponent } from '../outbox-campaign-modal/outbox-campaign-modal.component';
import { ScheduleFirstCommentComponent } from '../schedule-first-comment/schedule-first-comment.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditComponent } from '../audit/audit.component';
import { NotificationService } from '../../services/notification/notification.service';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '../../components/publisher/publisher-active';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';

@Component({
  selector: 'ssi-outbox-post',
  templateUrl: './outbox-post.component.html'
})
export class OutboxPostComponent implements OnInit, OnChanges, OnDestroy {
  @Input() post: Outbox;
  @Input() campaigns?: any;
  @Input() clicks?: number;
  @Input() minimal?: boolean;
  @Input() showValidation?: boolean;
  @Input() isSelectable?: boolean;
  @Input() isSelected?: boolean;
  @Input() advert?: Advert;
  @Input() shouldRefreshPublisher = false;
  @Output() closeModal = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output()
  onSelectToggle: EventEmitter<{
    isSelected: boolean;
  }> = new EventEmitter();

  @ViewChild('adTargetingTooltip') adTargetingTooltip: TooltipDirective;
  @ViewChild('adSpendTooltip') adSpendTooltip: TooltipDirective;
  @ViewChild('publisherConfrimationModalTemplate')
  publisherConfrimationModalTemplate: TemplateRef<any>;

  mentions: OutboxPublisherMention[] = [];
  activeImageIndex = 0;

  adTooltips: {
    targeting: string;
    spend: string;
  };

  authUser: User;
  loadingTracker = this.asyncTracker.create();
  OutboxMessageType = OutboxMessageType;
  hasImage = false;
  hasVideo = false;
  auditingEnabled = false;
  createTagButtonVisible = false;

  tags: Tag[] = [];
  selectedTags: Tag[] = [];

  destroyed$ = new Subject<void>();
  disapprovalTags: { label: string; value: string }[];

  constructor(
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive,
    private injector: Injector,
    private userModel: UserModel,
    private companyService: CompanyService,
    private outboxTagsService: OutboxTagsService,
    private popup: PopupService,
    private translate: TranslateService,
    private state: StateService,
    private outboxModel: OutboxModel,
    private nl2br: Nl2brPipe,
    private profileModel: ProfileModel,
    private asyncTracker: AsyncTrackerFactory,
    private modal: NgbModal,
    private notification: NotificationService,
    private profileHelper: ProfileHelperService
  ) {}

  async ngOnInit() {
    this.outboxTagsService.postTagsStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags) => {
        this.tags = tags;

        this.selectedTags = this.post.post_tags
          .map((tag) => {
            return this.outboxTagsService.postTagsStore.find(tag.id);
          })
          .filter((tag) => !!tag);
      });

    this.companyService.hasFeatureAccess('AUDIT_LOG').then((enabled) => {
      this.auditingEnabled = enabled;
    });

    this.authUser = await this.userModel.getAuthUser();
    this.mentions = this.post.getMentions();
    this.post.getNotes();

    // this.outboxTagsService.postTagsStore.value$
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe((tags) => {
    //     this.postTags = tags.map((t) => ({ label: t.name, value: t.name }));
    //     this.selectedPostTags = this.post.post_tags.map(({ name }) => name);
    //   });

    if (
      this.post.outbox_files[0] &&
      this.post.outbox_files[0].public_url &&
      this.post.outbox_files[0].mimetype
    ) {
      this.hasImage = this.post.outbox_files[0].mimetype.indexOf('image') > -1;
      this.hasVideo = this.post.outbox_files[0].mimetype.indexOf('video') > -1;
    }

    const validationTags = await this.outboxTagsService.getValidationTags();
    this.disapprovalTags = validationTags.map((tag) => {
      return {
        label: tag.name,
        value: tag.name
      };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.post) {
      this.activeImageIndex = 0;

      if (this.post) {
        if (!this.outboxModel.is(this.post)) {
          throw new Error('The post is not injected into the outbox model');
        }
        if (!this.clicks) {
          this.clicks = this.post.clicks_es;
        }
        if (this.post.social_id && this.post.type !== 'video') {
          this.post[
            'activity_id'
          ] = `${this.post.account_id}_${this.post.social_id}`;
        }
      }
    }
    if (changes.advert) {
      if (this.advert) {
        this.adTooltips = {
          targeting:
            'Advertising targeting:<br>' +
            this.nl2br.transform(this.advert.targetingOptionsLabel),
          spend:
            (this.advert.budget.daily_budget
              ? 'Daily budget: ' +
                (this.advert.budget.daily_budget === undefined
                  ? 'Not Set'
                  : this.advert.budget.daily_budget.amount +
                    this.advert.budget.daily_budget.currency) +
                '<br>'
              : '') +
            'Total budget: ' +
            (this.advert.budget.total_budget === undefined
              ? 'Not Set'
              : this.advert.budget.total_budget.amount +
                this.advert.budget.total_budget.currency) +
            '<br> Bid amount (' +
            this.advert.bidding.bidding_event +
            '): ' +
            this.advert.bidding.amount.amount +
            this.advert.bidding.amount.currency
        };
      } else {
        this.adTooltips = null;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  deletePost() {
    this.popup
      .confirm({
        title: this.translate.instant('DELETE_POST'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_POST'
        )
      })
      .then((shouldDelete) => {
        if (shouldDelete) {
          const promise = this.post.remove().then(() => this.onDelete.emit());
          this.loadingTracker.add(promise);
        }
      });
  }

  showNotes() {
    const modal = this.modal.open(OutboxNotesModalComponent, {
      size: 'lg'
    });
    modal.componentInstance.post = this.post;
  }

  async showCampaignModal() {
    const modal = this.modal.open(OutboxCampaignModalComponent, {
      windowClass: 'orlo-modal modal-high-zindex',
      backdropClass: 'backdrop-high-zindex'
    });
    modal.componentInstance.post = this.post;
    const result = await modal.result;
    this.post.campaign_id = result;
  }

  canManagePost() {
    return (
      !this.post.is_validated &&
      !this.post.rejected_at &&
      !this.post.deleted_at &&
      this.authUser &&
      this.authUser.hasAccountPermission(
        [this.post.account_id],
        [['post'], ['post_un-validated'], ['validate_posts']]
      )
    );
  }

  approve() {
    this.popup
      .confirm({
        title: this.translate.instant('APPROVE_POST'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WANT_TO_APPROVE_THIS_POST'
        )
      })
      .then((shouldApprove) => {
        if (shouldApprove) {
          this.post.approve();
        }
      });
  }

  boostPost() {
    this.state.go('auth.marketing.advertising.boostPost', {
      outboxId: this.post.id
    });
    const modal = this.injector.get('$injector').get('$uibModalStack');

    modal.dismissAll();
  }

  async disapprove() {
    this.popup
      .prompt({
        title: this.translate.instant('NOTIFY_THE_USER'),
        template: this.translate.instant(
          'PLEASE_ENTER_SOME_NOTES_FOR_THE_USER_TO_REVIEW'
        ),
        approvalTags: (await this.companyService.hasFeatureAccess(
          'OUTBOX_VALIDATION_TAGGING'
        ))
          ? this.disapprovalTags
          : null
      })
      .then((response) => {
        if (response !== false) {
          return this.post.disapprove(
            (response as any).reason,
            (response as any).tags.map((t) => t.value)
          );
        }
      });
  }

  viewProfile(props: {
    accountId: string;
    profileId: string;
    loadFeed: boolean;
  }) {
    console.log('props:', props);
    this.profileHelper.viewProfile(props);
    // defined as decorator at angularjs/common/common.run.ts, so have to call it this way
  }

  retryFailed(outboxId: string) {
    this.post.retryFailed(outboxId);
    this.notification.open(
      'Post resubmitted for publishing',
      { class: 'ssi ssi-information', color: '#43537f' },
      3
    );
  }

  openAudit(): void {
    const modal = this.modal.open(AuditComponent, {
      windowClass: 'm-modal rounded-corners-15',
      centered: true
    });
    modal.componentInstance.outbox = this.post;
  }

  public async triggerPublish(params) {
    if (this.shouldRefreshPublisher) {
      const confirmationModal = this.modal.open(
        this.publisherConfrimationModalTemplate,
        {
          windowClass: 'orlo-modal modal-high-zindex',
          backdropClass: 'backdrop-high-zindex'
        }
      );
      const result = await confirmationModal.result;
      if (!result) {
        return;
      }
      await this.publisherActive.next({ isActive: false });
      this.closeModal.emit();
    }
    await this.publisherActive.next({ isActive: true, ...params });
  }

  postTagsChange(selectedTags: Tag[]): void {
    this.post.updateTags(this.selectedTags);
  }

  onOptionsFiltered(params: {
    filterTerm: string;
    filteredOptions: Tag[];
  }): void {
    const optionExists = params.filteredOptions.some(
      (o) => o.name === params.filterTerm
    );
    this.createTagButtonVisible = !optionExists;
  }

  createTag(name: string): void {
    this.outboxTagsService.createTag(name, TagType.Post).then((createdTag) => {
      this.selectedTags.push(this.tags.find((t) => t.id === createdTag.id));
      this.postTagsChange(this.selectedTags);
    });
  }

  getTagsTooltip(): string {
    return this.selectedTags.map((t) => t.name).join(', ');
  }

  async editAutoComment(post: Outbox): Promise<void> {
    try {
      await this.showScheduleFirstCommentModal(post);
    } catch (e) {
      console.log('Cancelled / modal dismissed..');
      return;
    }
  }

  async showScheduleFirstCommentModal(
    post: Outbox
  ): Promise<AutoComment | undefined> {
    const modal = await this.modal.open(ScheduleFirstCommentComponent, {
      windowClass: 'orlo-modal orlo-modal-1200 position-top-20',
      backdropClass: 'orlo-modal-backdrop'
    });
    modal.componentInstance.outboxPost = post;

    return await modal.result;
  }
}
