import './reports.component.scss';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-reports',
  templateUrl: './reports.component.html',
  styles: []
})
export class ReportsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
