import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotesModalComponent } from './notes-modal.component';

import { AddNoteModule } from '../add-note/add-note.module';
import { NoteModule } from '../note/note.module';

@NgModule({
  imports: [CommonModule, AddNoteModule, NoteModule],
  exports: [NotesModalComponent],
  declarations: [NotesModalComponent],
  entryComponents: [NotesModalComponent]
})
export class NotesModalModule {}
