<div class="container-fluid container-app-page">
  <ssi-alerts></ssi-alerts>

  <div class="page-header">
    <h1>
      <span
        translate="EDIT__GROUPNAME__WORKFLOW"
        [translateParams]="{'groupName': workflow.name}">
      </span>
      <div class="pull-right">
        <button class="btn btn-primary" uiSref="auth.workflows">
          <i class="fa fa-plus"></i> <span translate="SAVE"></span>
        </button>
        <button class="btn btn-danger" (click)="deleteWorkflowConfirmationModal()">
          <span translate="DELETE_WORKFLOW"></span>
        </button>
      </div>
    </h1>
  </div>
  <div class="row">
    <div class="col-md-4 col-md-offset-2" >
      <div class="panel panel-default">
        <div class="panel-body">
          <h1 class="text-center"><span translate="UNLINKED_ACCOUNTS"></span></h1>
        </div>
        <div class="list-group">
          <ng-container *ngFor="let account of accounts.unlinked | unique: 'id'; trackBy:trackById">
            <a
              href="javascript:;"
              class="list-group-item"
              (click)="addAccount(account)"
            >
              <span class="badge" [ssiTooltip]="'ADD_TO_THE_WORKFLOW' | translate">&nbsp;<i class="fa fa-chevron-right"></i>&nbsp;</span>
              <img [src]="account.picture" class="profile-small img-circle"> {{account.displayName}} ({{account.accountTypeLabel}})
            </a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-4" >
      <div class="panel panel-default">
        <div class="panel-body">
          <h1 class="text-center">{{workflow.name}}</h1>
          <p class="lead text-muted" *ngIf="workflow.accounts.length === 0">
            <br>
            <span
              translate="LETS_GET_STARTED_CLICK_ON_THE_NAME_OF_THE_UNLINKED_ACCOUNT_WHICH_YOU_WISH_TO_ADD">
            </span>
          </p>
        </div>
        <div class="list-group">
          <ng-container *ngFor="let account of accounts.linked | unique:'id'; trackBy: trackById">
            <a
              href="javascript:;"
              class="list-group-item"
              (click)="removeAccount(account)"
            >
              <span class="badge" [ssiTooltip]="'REMOVE_FROM_THE_WORKFLOW' | translate">&nbsp;<i class="fa fa-times"></i>&nbsp;</span>
              <img [src]="account.picture" class="profile-small img-circle"> {{ account.displayName }} ({{ account.accountTypeLabel }})
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
