import './widget-marketing-posts.component.scss';

import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
  Inject
} from '@angular/core';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { orderBy } from 'lodash-es';

import {
  Account,
  AccountModel,
  CampaignModel,
  UserModel
} from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import { KeyValueObject, groupBy } from '../../../../../../../common/utils';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '../../../../../../../common/components/publisher/publisher-active';
import {
  OutboxQuery,
  OutboxQueryFactoryService
} from '../../../../../../../common/services/outbox-query-factory/outbox-query-factory.service';
import { ROW_HEIGHT } from '../../widgets-grid/widgets-grid.component';

@Component({
  selector: 'ssi-widget-marketing-posts',
  templateUrl: './widget-marketing-posts.component.html',
  styles: []
})
export class WidgetMarketingPostsComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() widget: DashboardWidget;
  @Output() loaded = new EventEmitter<boolean>();

  outboxQuery: OutboxQuery;

  accounts: Account[] = [];
  selectedAccounts: Account[] = [];
  selectedAccountTypeIds: string[] = [];
  selectAllAccountsChecked = false;

  containerHeight: number; // - widget header height

  queryOverrides: KeyValueObject = {};
  lastMonthOnly = true;
  limit = 10;

  protected destroyed$ = new Subject<void>();

  constructor(
    protected userModel: UserModel,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected outboxQueryFactory: OutboxQueryFactoryService,
    @Inject(PUBLISHER_ACTIVE) protected publisherActive: PublisherActive,
    protected campaignModel: CampaignModel
  ) {}

  async ngOnInit() {
    this.publisherActive
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter(({ isActive }) => !isActive))
      .subscribe(() => {
        this.requestData();
      });

    this.accounts = await this.accountModel.findAllAccounts(
      this.workflowManager.getCurrentId()
    );
    this.accounts = orderBy(this.accounts, ['account_type_name', 'name']);

    this.selectedAccounts = this.accounts.filter((account) => {
      return this.widget.apiSearchParams.accounts.includes(account.id);
    });
    this.selectedAccountsChanged(this.selectedAccounts);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async requestData() {
    if (!this.selectedAccounts.length) {
      return;
    }
    const allAccounts = this.accounts;
    const authUser = await this.userModel.getAuthUser();
    const campaigns = await this.campaignModel.findAll();
    const savedQuery = JSON.parse(JSON.stringify(this.widget.apiSearchParams));

    savedQuery['accounts'] = this.selectedAccounts.map((a) => a.id);
    savedQuery['start_date'] = this.lastMonthOnly
      ? this.lastMonthDate().toISOString()
      : null;

    // so it also gets saved on the backend
    this.widget.apiSearchParams['accounts'] = savedQuery['accounts'];

    // todo: add post tags?
    this.outboxQuery = this.outboxQueryFactory.create({
      allAccounts,
      authUser,
      savedQuery,
      campaigns
    });

    // OutboxQuery is internally modifying the passed query object - override everything with the query from the new filters (until we get rid of OutboxQuery)
    this.queryOverrides = JSON.parse(
      JSON.stringify(this.widget.apiSearchParams)
    );
    this.queryOverrides['accounts'] = this.selectedAccounts.map((a) => a.id);
    savedQuery['start_date'] = this.lastMonthOnly
      ? this.lastMonthDate().toISOString()
      : null;

    this.outboxQuery
      .search(0, this.limit, true, this.queryOverrides)
      .then(() => {
        this.loaded.emit();
      });
  }

  lastMonthDate() {
    const today = new Date();
    return new Date(new Date().setDate(today.getDate() - 30));
  }

  selectedAccountsChanged(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
    this.selectAllAccountsChecked =
      this.selectedAccounts.length &&
      this.selectedAccounts.length === this.accounts.length;

    this.setSelectedAccountTypeIds();

    this.requestData();
  }

  setSelectedAccountTypeIds(): void {
    this.selectedAccountTypeIds = [];

    const accountGroups = groupBy(
      this.selectedAccounts,
      (account) => account.account_type_id
    );

    accountGroups.forEach((accountGroup) => {
      this.selectedAccountTypeIds.push(accountGroup[0].account_type_id);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['widget'] && changes['widget'].currentValue) {
      this.containerHeight =
        this.widget.display_properties.h * ROW_HEIGHT - 136; // - widget header height
    }
  }

  toggleFilter() {
    this.lastMonthOnly = !this.lastMonthOnly;
    this.requestData();
  }
}
