<ssi-advertising-nav></ssi-advertising-nav>

<ssi-advertising-table-filters
  [sortEnabled]="false"
  [activeSocialFilter]="filter"
  (filterAccount)="filterAccount($event)">
</ssi-advertising-table-filters>
<div class="advert-table">
  <ul class="advert-table-head advert-table-head-objectives" [ngClass]="{active: objectives.length}">
    <li class="advert-table-col-objectives"><i class="ssi ssi-ads advert-table-head-icon"></i></li>
    <li
      class="advert-table-head-label advert-table-col-objectives"
      [ngClass]="{active: sort === 'clicks'}"
      (click)="sortFilterObjectives('clicks', null)">
      <p
        [ssiTooltip]="'The number of clicks your ads have received, excluding some social actions such as likes, comments and shares'"
        [tooltipAppendToBody]="true"
        tooltipWindowClass="tooltip-ad-table"
        translate="CLICKS">
    </p>
    </li>
    <li
      class="advert-table-head-label advert-table-col-objectives"
      [ngClass]="{active: sort === 'reach', hide: [0, 4].includes(filter)}"
      (click)="sortFilterObjectives('reach', null)">
      <p
        [ssiTooltip]="'The number of times people saw your ad'"
        [tooltipAppendToBody]="true"
        tooltipWindowClass="tooltip-ad-table"
        translate="IMPRESSIONS">
      </p>
    </li>
    <li
      class="advert-table-head-label advert-table-col-objectives"
      [ngClass]="{active: sort === 'engagement'}"
      (click)="sortFilterObjectives('engagement', null)">
      <p
        [ssiTooltip]="'The number of social engagements your ad received'"
        [tooltipAppendToBody]="true"
        tooltipWindowClass="tooltip-ad-table"
        translate="ENGAGEMENTS">
      </p>
    </li>
  </ul>
</div>

<div class="advert-table-objectives-body-wrap" [ngClass]="{active: objectives.length}">
  <div *ngIf="objectives.length > 0" class="advert-table-objectives-body">
      <ul class="objectives">
        <li class="objective-wrap" *ngFor="let objective of objectives">
          <ssi-post
            [post]="objective"
            [colleagues]="colleagues"
            [postType]="'objective'"
            (deletePost)="deletePost($event)">
          </ssi-post>
        </li>
      </ul>
  </div>

  <div *ngIf="objectives.length === 0" class="objectives-none">
    <i class="ssi ssi-strategic-microcopy objectives-none-icon"></i>
    <h2 class="objectives-none-title">Get strategic with your boosting!</h2>
    <p class="objectives-none-meta">Filter your boost suggestions by the outcome you want your paid activity to achieve:</p>
    <ul class="objectives-none-meta-list">
      <li translate="CLICKS">Clicks</li>
      <li translate="IMPRESSIONS"></li>
      <li translate="ENGAGEMENTS"></li>
    </ul>
  </div>
</div>