import { mapToIterable } from '../utils';

export interface Emotion {
  key: string;
  label: string;
  icon: string;
  iconColor: string;
  iconBgColor: string;
  bgColor: string;
  positive: boolean;
}

export interface Emotions {
  // positive emotions
  admiration: Emotion;
  amusement: Emotion;
  approval: Emotion;
  caring: Emotion;
  desire: Emotion;
  excitement: Emotion;
  curiosity: Emotion;
  gratitude: Emotion;
  joy: Emotion;
  love: Emotion;
  optimism: Emotion;
  pride: Emotion;
  realization: Emotion;
  relief: Emotion;
  surprise: Emotion;
  neutral: Emotion;
  // negative emotions
  anger: Emotion;
  annoyance: Emotion;
  confusion: Emotion;
  disappointment: Emotion;
  disapproval: Emotion;
  disgust: Emotion;
  embarrassment: Emotion;
  fear: Emotion;
  grief: Emotion;
  nervousness: Emotion;
  remorse: Emotion;
  sadness: Emotion;
}

export const emotions: Emotions = {
  admiration: {
    key: 'admiration',
    label: 'Admiration',
    icon: 'ssi ssi-admiration-chip',
    iconColor: '#fff',
    iconBgColor: '#668EE5',
    bgColor: '#EFF3FC',
    positive: true
  },
  amusement: {
    key: 'amusement',
    label: 'Amusement',
    icon: 'ssi ssi-amusement-chip',
    iconColor: '#43537F',
    iconBgColor: '#FFD600',
    bgColor: '#FFFBE8',
    positive: true
  },
  approval: {
    key: 'approval',
    label: 'Approval',
    icon: 'ssi ssi-thumbs-up-like',
    iconColor: '#fff',
    iconBgColor: '#35B3A1',
    bgColor: '#EAF7F5',
    positive: true
  },
  caring: {
    key: 'caring',
    label: 'Caring',
    icon: 'ssi ssi-caring-chip',
    iconColor: '#fff',
    iconBgColor: '#EC74A6',
    bgColor: '#FDF1F6',
    positive: true
  },
  desire: {
    key: 'desire',
    label: 'Desire',
    icon: 'ssi ssi-desire-chip',
    iconColor: '#fff',
    iconBgColor: '#EC4180',
    bgColor: '#FDECF2',
    positive: true
  },
  excitement: {
    key: 'excitement',
    label: 'Excitement',
    icon: 'ssi ssi-excitement-chip',
    iconColor: '#fff',
    iconBgColor: '#F8B829',
    bgColor: '#FEF8E9',
    positive: true
  },
  curiosity: {
    key: 'curiosity',
    label: 'Curiosity',
    icon: 'ssi ssi-amend-sentiment-new',
    iconColor: '#43537F',
    iconBgColor: '#FFF59A',
    bgColor: '#FFFBD7',
    positive: true
  },
  gratitude: {
    key: 'gratitude',
    label: 'Gratitude',
    icon: 'ssi ssi-gratitude-chip',
    iconColor: '#fff',
    iconBgColor: '#38C6C6',
    bgColor: '#EBF9F9',
    positive: true
  },
  joy: {
    key: 'joy',
    label: 'Joy',
    icon: 'ssi ssi-joy-chip',
    iconColor: '#43537F',
    iconBgColor: '#FFCF40',
    bgColor: '#FFFAEC',
    positive: true
  },
  love: {
    key: 'love',
    label: 'Love',
    icon: 'ssi ssi-love-chip',
    iconColor: '#fff',
    iconBgColor: '#DF354F',
    bgColor: '#FCEAED',
    positive: true
  },
  optimism: {
    key: 'optimism',
    label: 'Optimism',
    icon: 'ssi ssi-optimism-chip',
    iconColor: '#43537F',
    iconBgColor: '#FFD702',
    bgColor: '#FFF6E8',
    positive: true
  },
  pride: {
    key: 'pride',
    label: 'Pride',
    icon: 'ssi ssi-pride-chip',
    iconColor: '#fff',
    iconBgColor: '#77C594',
    bgColor: '#F1F9F4',
    positive: true
  },
  realization: {
    key: 'realization',
    label: 'Realization',
    icon: 'ssi ssi-bulb',
    iconColor: '#fff',
    iconBgColor: '#35B8E1',
    bgColor: '#35b8e11a',
    positive: true
  },
  relief: {
    key: 'relief',
    label: 'Relief',
    icon: 'ssi ssi-relief-chip',
    iconColor: '#fff',
    iconBgColor: '#84CE49',
    bgColor: '#84ce491a',
    positive: true
  },
  surprise: {
    key: 'surprise',
    label: 'Surprise',
    icon: 'ssi ssi-surprise-chip',
    iconColor: '#fff',
    iconBgColor: '#6BE898',
    bgColor: '#6be8981a',
    positive: true
  },
  neutral: {
    key: 'neutral',
    label: 'Neutral',
    icon: 'ssi ssi-neutral-new',
    iconColor: '#657fc8',
    iconBgColor: '#B5C5EE',
    bgColor: '#D3DCF5',
    positive: true
  },
  anger: {
    key: 'anger',
    label: 'Anger',
    icon: 'ssi ssi-anger-chip',
    iconColor: '#fff',
    iconBgColor: '#EA0000',
    bgColor: '#FDE5E5',
    positive: false
  },
  annoyance: {
    key: 'annoyance',
    label: 'Annoyance',
    icon: 'ssi ssi-annoyance-chip',
    iconColor: '#fff',
    iconBgColor: '#FF8686',
    bgColor: '#FFF2F2',
    positive: false
  },
  confusion: {
    key: 'confusion',
    label: 'Confusion',
    icon: 'ssi ssi-confusion-chip',
    iconColor: '#fff',
    iconBgColor: '#FF7456',
    bgColor: '#FFF1EE',
    positive: false
  },
  disappointment: {
    key: 'disappointment',
    label: 'Disappointment',
    icon: 'ssi ssi-disappointment-chip',
    iconColor: '#fff',
    iconBgColor: '#4F5388',
    bgColor: '#EDEDF3',
    positive: false
  },
  disapproval: {
    key: 'disapproval',
    label: 'Disapproval',
    icon: 'ssi ssi-disapproval-chip',
    iconColor: '#fff',
    iconBgColor: '#6C6631',
    bgColor: '#F0EFEA',
    positive: false
  },
  disgust: {
    key: 'disgust',
    label: 'Disgust',
    icon: 'ssi ssi-disgust-chip',
    iconColor: '#fff',
    iconBgColor: '#A29005',
    bgColor: '#F6F4E6',
    positive: false
  },
  embarrassment: {
    key: 'embarrassment',
    label: 'Embarrassment',
    icon: 'ssi ssi-embarrassment-chip',
    iconColor: '#fff',
    iconBgColor: '#F77',
    bgColor: '#FFF1F1',
    positive: false
  },
  fear: {
    key: 'fear',
    label: 'Fear',
    icon: 'ssi ssi-fear-chip',
    iconColor: '#fff',
    iconBgColor: '#302100',
    bgColor: '#EAE8E5',
    positive: false
  },
  grief: {
    key: 'grief',
    label: 'Grief',
    icon: 'ssi ssi-grief-chip',
    iconColor: '#fff',
    iconBgColor: '#08357B',
    bgColor: '#E6EAF2',
    positive: false
  },
  nervousness: {
    key: 'nervousness',
    label: 'Nervousness',
    icon: 'ssi ssi-nervousness-chip',
    iconColor: '#fff',
    iconBgColor: '#912E0E',
    bgColor: '#FEF3F0',
    positive: false
  },
  remorse: {
    key: 'remorse',
    label: 'Remorse',
    icon: 'ssi ssi-remorse-chip',
    iconColor: '#fff',
    iconBgColor: '#05667C',
    bgColor: '#05667c1a',
    positive: false
  },
  sadness: {
    key: 'sadness',
    label: 'Sadness',
    icon: 'ssi ssi-sadness-chip',
    iconColor: '#fff',
    iconBgColor: '#2574BC',
    bgColor: '#2574bc1a',
    positive: false
  }
};

export const emotionsIterable: Emotion[] = mapToIterable(emotions);
export const emotionsIterableSortedAZ: Emotion[] = mapToIterable(
  emotions
).sort((e1, e2) => e1.label.localeCompare(e2.label));
