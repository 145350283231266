import './filters.component.scss';

import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  animate,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash-es';

import {
  AccountModel,
  ActivityTags,
  OutboxMessageClass,
  ConversationStatus,
  ActivityStatus
} from '@ui-resources-angular';
import { WorkflowManagerService } from '../../services/workflow-manager/workflow-manager.service';
import {
  KeyValueObject,
  getUniqueValues,
  mapToIterable,
  parseBooleanQuery
} from '../../utils';
import {
  ColleaguesService,
  CampaignsService,
  OutboxTagsService,
  Campaign,
  TeamsService
} from '../../services/api';
import {
  LANGUAGES,
  inboxPriorities,
  messageTypesIterable,
  sentimentsIterable
} from '../../constants';
import { AccountTypeId, AccountTypeIdString } from '../../enums';
import { DatePipe } from '@angular/common';

export enum InboxSortField {
  Smart = 'SMART',
  Date = 'DATE',
  DateTime = 'DATETIME',
  Priority = 'PRIORITY',
  MessageCount = 'MESSAGE_COUNT'
}

export enum InboxSortOrder {
  Asc = 'asc',
  Desc = 'desc',
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum FilterType {
  Select = 'select',
  MultiSelect = 'multiSelect',
  MultiSelectTree = 'multiSelectTree', // campaigns
  TagsIncludeExcludeSelect = 'tagsIncludeExcludeSelect',
  InputSearch = 'inputSearch',
  DateRange = 'dateRange'
}

export enum FilterKey {
  Keyword = 'keyword',
  BooleanQuery = 'booleanQuery',
  Account = 'account',
  CreatedBy = 'createdBy',
  Campaign = 'campaign',
  PostTag = 'postTag',
  MessageType = 'messageType',
  ScheduledPost = 'scheduledPost',
  InApproval = 'inApproval',
  FailedPost = 'failedPost',
  DeletedPost = 'deletedPost',
  SortDirection = 'sortDirection',
  TimePeriod = 'timePeriod',
  InboxTag = 'inboxTag',
  Priority = 'priority',
  Sentiment = 'sentiment',
  Visibility = 'visibility',
  Comments = 'comments',
  Language = 'language',
  AssignedTo = 'assignedTo',
  ActionedBy = 'actionedBy',
  MessageStatus = 'messageStatus',
  ConversationStatus = 'conversationStatus',
  IsSilenced = 'isSilenced',
  Author = 'author'
}

export interface Filter {
  key: FilterKey;
  title: string;
  type: FilterType;
  icon: string;
  expanded?: boolean;
  placeholder?: string; // for input filters
  filterPlaceholder?: string; // for select and multiSelect filters, if defined input to search options should appear
  options?: any[]; // for select and multiSelect filters
  modelValue: any; // type depends on the filter type
  defaultModelValue: any; // type depends on the filter type
  getAppliedValuesCount: (key: FilterKey) => number;
  getAppliedValuesLabel: () => string;
  toApiParam: () => KeyValueObject;
  fromApiParam: (apiParams: KeyValueObject) => void;
}

export type Filters = { [key in FilterKey]?: Filter };

export enum Config {
  Outbox = 'outbox',
  InboxMessage = 'inboxMessage',
  InboxConversation = 'inboxConversation'
}

export const configs: { [key in Config]: FilterKey[] } = {
  [Config.Outbox]: [
    FilterKey.BooleanQuery,
    FilterKey.Account,
    FilterKey.TimePeriod,
    FilterKey.CreatedBy,
    FilterKey.Campaign,
    FilterKey.PostTag,
    FilterKey.MessageType,
    FilterKey.ScheduledPost,
    FilterKey.InApproval,
    FilterKey.FailedPost,
    FilterKey.DeletedPost,
    FilterKey.SortDirection
  ],
  [Config.InboxMessage]: [
    FilterKey.Keyword,
    FilterKey.Account,
    FilterKey.TimePeriod,
    FilterKey.InboxTag,
    FilterKey.Priority,
    FilterKey.Sentiment,
    FilterKey.Visibility,
    FilterKey.Comments,
    FilterKey.Language,
    FilterKey.AssignedTo,
    FilterKey.ActionedBy,
    FilterKey.MessageStatus,
    FilterKey.IsSilenced,
    FilterKey.Campaign,
    FilterKey.Author
  ],
  [Config.InboxConversation]: [
    FilterKey.Account,
    FilterKey.TimePeriod,
    FilterKey.Visibility,
    FilterKey.Priority,
    FilterKey.ConversationStatus,
    FilterKey.AssignedTo
  ]
};

@Component({
  selector: 'ssi-filters',
  templateUrl: './filters.component.html',
  styles: [],
  animations: [
    trigger('expansion', [
      transition('void => expansion', [
        style({ height: 0 }),
        // update toggleRow method if animation duration is changed
        animate('120ms ease-out', style({ height: '*' }))
      ]),
      transition('expansion => void', [
        style({ height: '*' }),
        animate('120ms ease-out', style({ height: '0' }))
      ])
    ])
  ]
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() visible = false;
  @Input() standalone = true;
  @Input() config: Config = Config.Outbox;
  @Input() hideTimePeriodFilter = false;
  @Input() hidePrivateMessages = false;
  @Input() applyOnInit = true;

  // recreate state inputs
  @Input() savedApiParams?: KeyValueObject;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onApply = new EventEmitter<Filters>();
  @Output() onFiltersChange = new EventEmitter<Filter[]>();
  @Output() onBeforeFilterClear = new EventEmitter<Filter>();

  FilterKey = FilterKey;
  FilterType = FilterType;

  filters: Filters = {};
  filtersIterable: Filter[] = [];

  // defaultFiltersSnapshot = '';
  appliedFiltersSnapshot = '';

  appliedModelsChanged = false;
  activeFilters: Filter[] = [];

  keywordSearchTempModelValue = '';

  constructor(
    public modal: NgbModal,
    protected datePipe: DatePipe,
    protected translate: TranslateService,
    protected accountModel: AccountModel,
    protected workflowManagerService: WorkflowManagerService,
    protected teamsService: TeamsService,
    protected colleaguesService: ColleaguesService,
    protected outboxTagsService: OutboxTagsService,
    protected campaignsService: CampaignsService,
    protected activityTags: ActivityTags
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['config'] && changes['config'].currentValue) {
      await this.init();
    }

    if (
      changes['savedApiParams'] &&
      changes['savedApiParams'].currentValue &&
      !changes['savedApiParams'].firstChange
    ) {
      this.fromApiParams(this.savedApiParams);
      this.appliedFiltersSnapshot = this.getFiltersSnaphot();
      this.appliedModelsChanged = false;
    }
  }

  async ngOnInit() {}

  async init() {
    await this.initFilters();

    if (this.savedApiParams) {
      this.fromApiParams(this.savedApiParams);
    }

    if (this.applyOnInit) {
      this.apply();
    } else {
      this.appliedFiltersSnapshot = this.getFiltersSnaphot();
      this.appliedModelsChanged = false;
    }
  }

  /* public - accessed outside of this component (from the parent) */
  public toApiParams(): KeyValueObject {
    let apiParams = this.savedApiParams || {};
    Object.values(this.filters).forEach((f) => {
      apiParams = { ...apiParams, ...f.toApiParam() };
    });

    return apiParams;
  }

  fromApiParams(apiParams: KeyValueObject): void {
    Object.values(this.filters).forEach((f) => {
      f.fromApiParam(apiParams);
    });

    this.onFilterModelValueChange(null, null);
  }

  getRemainingApiParams(apiParams: KeyValueObject): KeyValueObject {
    const remainingParams: any = {};

    // common params...
    if (!apiParams.hasOwnProperty('limit')) {
      remainingParams.limit = 20;
    }
    if (!apiParams.hasOwnProperty('offset')) {
      remainingParams.offset = 0;
    }

    // Config.InboxMessage...
    if (this.config === Config.InboxMessage) {
      if (!apiParams.hasOwnProperty('v')) {
        remainingParams.v = 4;
      }
      if (!apiParams.hasOwnProperty('sort')) {
        remainingParams.sort = InboxSortField.Date;
      }
      if (!apiParams.hasOwnProperty('order')) {
        remainingParams.order = InboxSortOrder.Desc;
      }
    }

    // Config.InboxConversation...
    if (this.config === Config.InboxConversation) {
      if (!apiParams.hasOwnProperty('sort')) {
        remainingParams.sort = InboxSortField.DateTime;
      }
      if (!apiParams.hasOwnProperty('order')) {
        remainingParams.order = InboxSortOrder.DESC;
      }
    }

    // Config.Outbox...
    if (this.config === Config.Outbox) {
      if (!apiParams.hasOwnProperty('v')) {
        remainingParams.v = 2;
      }
      // if timePeriod filter not included set default values
      if (configs[Config.Outbox].indexOf(FilterKey.TimePeriod) === -1) {
        remainingParams.start_date = null;
        remainingParams.end_date = null;
      }
    }

    return remainingParams;
  }

  toggle(): void {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }

  hide(): void {
    if (!this.visible) {
      return;
    }
    this.visible = false;
    this.visibleChange.emit(false);
  }

  getFiltersSnaphot(): string {
    const values = this.filtersIterable.map((filter) => {
      // take all properties except 'expanded'
      return { ...filter, ...{ expanded: false } };
    });

    return JSON.stringify(values);
  }

  apply(event?: MouseEvent): void {
    this.appliedFiltersSnapshot = this.getFiltersSnaphot();
    this.appliedModelsChanged = false;

    this.onApply.emit(this.filters);

    if (event && this.standalone) {
      this.hide();
    }
  }

  async restoreDefaults(): Promise<void> {
    if (!this.activeFilters.length) {
      return;
    }

    this.keywordSearchTempModelValue = '';
    await this.initFilters();

    this.activeFilters = [];
    this.onFiltersChange.emit(this.activeFilters);

    this.appliedModelsChanged =
      this.appliedFiltersSnapshot !== this.getFiltersSnaphot();
  }

  async clearFilter(filter: Filter): Promise<void> {
    this.onBeforeFilterClear.emit(filter);

    await this.initFilters(filter.key);

    this.activeFilters = this.getActiveFilters();
    this.onFiltersChange.emit(this.activeFilters);

    this.appliedModelsChanged =
      this.appliedFiltersSnapshot !== this.getFiltersSnaphot();
  }

  onFilterModelValueChange(modelValue: any, filter: Filter): void {
    this.activeFilters = this.getActiveFilters();
    this.onFiltersChange.emit(this.activeFilters);

    this.appliedModelsChanged =
      this.appliedFiltersSnapshot !== this.getFiltersSnaphot();
  }

  /* public - accessed outside of this component (from the parent) */
  public getActiveFilters(): Filter[] {
    return this.filtersIterable.filter(
      (f) => f.getAppliedValuesCount(f.key) > 0
    );
  }

  onCampaignToggle(parent: Campaign | any): void {
    if (parent._children) {
      parent._children.expanded = true;
    }
  }

  onChildCampaignToggle(child: Campaign, parent: Campaign): void {}

  expandCollapseCampaign(parent: Campaign | any): void {
    parent._children.expanded = !parent._children.expanded;
  }

  insideAccordion(filter: Filter): boolean {
    return (
      filter.key !== FilterKey.Keyword && filter.key !== FilterKey.BooleanQuery
    );
  }

  singleSelectAppliedValuesCount = (filterKey: FilterKey): number => {
    const filter = this.filters[filterKey];
    return filter.modelValue === filter.defaultModelValue ? 0 : 1;
  };

  multiSelectAppliedValuesCount = (filterKey: FilterKey): number => {
    const filter = this.filters[filterKey];
    return filter.modelValue.length;
  };

  removeKeywordSearchChip(): void {
    this.keywordSearchTempModelValue = '';
    if (this.filters.keyword) {
      this.filters.keyword.modelValue = '';
      this.onFilterModelValueChange(
        this.keywordSearchTempModelValue,
        this.filters.keyword
      );
    } else if (this.filters.booleanQuery) {
      this.filters.booleanQuery.modelValue = '';
      this.onFilterModelValueChange(
        this.keywordSearchTempModelValue,
        this.filters.booleanQuery
      );
    }
  }

  onKeywordSearchInputKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.filters.keyword) {
        this.filters.keyword.modelValue = this.keywordSearchTempModelValue;
        this.onFilterModelValueChange(
          this.keywordSearchTempModelValue,
          this.filters.keyword
        );
      } else if (this.filters.booleanQuery) {
        this.filters.booleanQuery.modelValue = this.keywordSearchTempModelValue;
        this.onFilterModelValueChange(
          this.keywordSearchTempModelValue,
          this.filters.booleanQuery
        );
      }
    }
  }

  getFilterKeys(): FilterKey[] {
    if (this.hideTimePeriodFilter) {
      return configs[this.config].filter((key) => key !== FilterKey.TimePeriod);
    }
    return [...configs[this.config]];
  }

  async initFilters(filterKey?: FilterKey): Promise<Filters> {
    if (filterKey) {
      // init single filter only
      this.filters[filterKey] = await this[`${filterKey}Filter`]();
      this.filtersIterable = mapToIterable(this.filters);
    } else {
      const filterKeys = this.getFilterKeys();
      const filterFactories = filterKeys.map((key) => {
        if (!this[`${key}Filter`]) {
          throw new Error(
            `Filter factory for filter (key) '${key}' not found!`
          );
        }
        return this[`${key}Filter`]();
      });

      const filterPromises = Promise.all(filterFactories);

      this.filters = {};
      this.filtersIterable = [];
      this.filtersIterable = await filterPromises;
      this.filtersIterable.forEach((filter) => {
        this.filters[filter.key] = filter;
      });
    }

    return this.filters;
  }

  async keywordFilter(): Promise<Filter> {
    return {
      key: FilterKey.Keyword,
      title: 'Keyword Search',
      type: FilterType.InputSearch,
      icon: 'ssi-search-small',
      placeholder: 'Search keyword...',
      modelValue: '',
      defaultModelValue: '',
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.keyword;
        return filter.modelValue ? 1 : 0;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.keyword;
        return filter.modelValue ? `Keywords: "${filter.modelValue}"` : ``;
      },
      expanded: this.filters.keyword ? this.filters.keyword.expanded : false,
      toApiParam: () => {
        const value = this.filters.keyword.modelValue;
        return this.config === Config.Outbox
          ? { text: value ? value : null }
          : { q: value ? value : null };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const paramKey = this.config === Config.Outbox ? 'text' : 'q';
        const param = apiParams[paramKey];
        this.filters.keyword.modelValue = this.keywordSearchTempModelValue = param
          ? param
          : this.filters.keyword.defaultModelValue;
      }
    };
  }

  async booleanQueryFilter(): Promise<Filter> {
    return {
      key: FilterKey.BooleanQuery,
      title: 'Search Query',
      type: FilterType.InputSearch,
      icon: 'ssi-search-small',
      placeholder: 'Type in your boolean search query...',
      modelValue: '',
      defaultModelValue: '',
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.booleanQuery;
        return filter.modelValue ? 1 : 0;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.booleanQuery;
        return filter.modelValue ? `Query: "${filter.modelValue}"` : ``;
      },
      expanded: this.filters.booleanQuery
        ? this.filters.booleanQuery.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.booleanQuery.modelValue;
        return {
          text: value ? { query: value, ...parseBooleanQuery(value) } : null
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const paramKey = 'text';
        const param = apiParams[paramKey];
        this.filters.booleanQuery.modelValue = this.keywordSearchTempModelValue =
          param && param.query
            ? param.query
            : this.filters.booleanQuery.defaultModelValue;
      }
    };
  }

  async accountFilter(): Promise<Filter> {
    let accounts = await this.accountModel.findAccounts(
      this.workflowManagerService.getCurrentId()
    );
    if (
      this.config === Config.InboxMessage ||
      this.config === Config.InboxConversation
    ) {
      // only TikTOk business accounts should be in Inbox filters,
      // see: https://orlo.slack.com/archives/C03ET98N2AW/p1722247941649329
      accounts = accounts.filter(
        (a) => a.account_type_id !== AccountTypeIdString.TikTok
      );
    }
    accounts = orderBy(accounts, ['account_type_name', 'name']);

    return {
      key: FilterKey.Account,
      title: 'Account',
      type: FilterType.MultiSelect,
      icon: 'ssi-account',
      filterPlaceholder: 'Search for an account...',
      options: accounts,
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.account;
        if (
          !filter.modelValue.length ||
          filter.modelValue.length === accounts.length
        ) {
          return 0;
        }
        return filter.modelValue.length;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.account;
        const count = filter.getAppliedValuesCount(filter.key);
        return count ? `${count} ${count === 1 ? 'account' : 'accounts'}` : ``;
      },
      expanded: this.filters.account ? this.filters.account.expanded : false,
      toApiParam: () => {
        // api doesn't accept null or empty array value as a default (to not do any filtering by accounts),
        // if nothing selected by the user then just pass all accounts to the backend
        const value = this.filters.account.modelValue.length
          ? this.filters.account.modelValue
          : accounts;

        if (this.config === Config.Outbox) {
          return { accounts: value.map((a) => a.id) };
        } else if (this.config === Config.InboxMessage) {
          const accountIdsObj = {};
          value.forEach((a) => {
            accountIdsObj[a.id] = '1';
          });
          return {
            ac: accountIdsObj
          };
        } else {
          return { account_ids: value.map((a) => a.id) };
        }
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const accIds =
          this.config === Config.Outbox
            ? apiParams.accounts
            : this.config === Config.InboxMessage
            ? apiParams.ac && Object.keys(apiParams.ac)
            : apiParams.account_ids;

        if (!Array.isArray(accIds) || accIds.length === accounts.length) {
          // all selected is treated the same as if nothing selected
          this.filters.account.modelValue = [
            ...this.filters.account.defaultModelValue
          ];
          return;
        }

        this.filters.account.modelValue = accIds
          .map((id) => accounts.find((a) => a.id === id))
          .filter((value) => !!value);
      }
    };
  }

  async createdByFilter(): Promise<Filter> {
    let colleagues = await this.colleaguesService.getAllActive();
    colleagues = orderBy(colleagues, [(c) => c.name.toLowerCase()]);

    return {
      key: FilterKey.CreatedBy,
      title: 'Author',
      type: FilterType.MultiSelect,
      icon: 'ssi-author',
      filterPlaceholder: 'Search for an author...',
      options: colleagues,
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: this.multiSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.createdBy;
        const count = filter.getAppliedValuesCount(filter.key);
        return count ? `${count} ${count === 1 ? 'author' : 'authors'}` : ``;
      },
      expanded: this.filters.createdBy
        ? this.filters.createdBy.expanded
        : false,
      toApiParam: () => {
        const colleages = this.filters.createdBy.modelValue;
        return {
          created_by: colleages.length
            ? colleages.map((c) => `user:${c.id}`)
            : null
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!Array.isArray(apiParams.created_by)) {
          this.filters.createdBy.modelValue = [
            ...this.filters.createdBy.defaultModelValue
          ];
          return;
        }
        this.filters.createdBy.modelValue = apiParams.created_by
          .map((cb) => {
            const userId = cb.split(':')[1];
            return colleagues.find((c) => c.id === userId);
          })
          .filter((value) => !!value);
      }
    };
  }

  async campaignFilter(): Promise<Filter> {
    let campaigns = await this.campaignsService.getAll();
    campaigns = orderBy(campaigns, [(c) => c.name.toLowerCase()]);

    const apiParamKey =
      this.config === Config.Outbox ? 'campaign_id' : 'campaign_ids';

    return {
      key: FilterKey.Campaign,
      title: 'Campaign',
      type: FilterType.MultiSelectTree,
      icon: 'ssi-campaign',
      filterPlaceholder: 'Search campaigns...',
      options: campaigns
        .filter((c) => !c.parent_id)
        .map((p) => {
          const children = campaigns.filter((c) => c.parent_id === p.id);
          return {
            ...p,
            _children: children.length
              ? {
                  expanded: false,
                  options: children,
                  modelValue: [],
                  defaultModelValue: []
                }
              : undefined
          };
        }),
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.campaign;
        let count = filter.modelValue.length;
        // count children too
        filter.options.forEach((parent) => {
          if (parent._children) {
            count += parent._children.modelValue.length;
          }
        });
        return count;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.campaign;
        const count = filter.getAppliedValuesCount(filter.key);
        return count
          ? `${count} ${count === 1 ? 'campaign' : 'campaigns'}`
          : ``;
      },
      expanded: this.filters.campaign ? this.filters.campaign.expanded : false,
      toApiParam: () => {
        const filter = this.filters.campaign;

        let allCampaigns = [...filter.modelValue]; // add parents first

        filter.options.forEach((parent) => {
          if (parent._children) {
            allCampaigns = [...allCampaigns, ...parent._children.modelValue];
          }
        });

        return {
          [apiParamKey]: allCampaigns.length
            ? allCampaigns.map((c) => c.id)
            : undefined
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const apiValue = apiParams[apiParamKey];

        if (!Array.isArray(apiValue)) {
          this.filters.campaign.modelValue = [
            ...this.filters.campaign.defaultModelValue
          ];
          this.filters.campaign.options.forEach((parent) => {
            if (parent._children) {
              parent._children.modelValue = [
                ...parent._children.defaultModelValue
              ];
            }
          });
          return;
        }

        this.filters.campaign.modelValue = this.filters.campaign.options.filter(
          (c) => !!apiValue.find((id) => id === c.id)
        );

        this.filters.campaign.options.forEach((parent) => {
          if (parent._children) {
            parent._children.modelValue = parent._children.options.filter(
              (c) => !!apiValue.find((id) => id === c.id)
            );
            if (parent._children.modelValue.length) {
              parent._children.expanded = true;
            }
          }
        });
      }
    };
  }

  async postTagFilter(): Promise<Filter> {
    const postTags = await this.outboxTagsService.getPostTags();

    return {
      key: FilterKey.PostTag,
      title: 'Post Tag',
      type: FilterType.MultiSelect,
      icon: 'ssi-tag-test',
      filterPlaceholder: 'Search for a post tag...',
      options: postTags,
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: this.multiSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.postTag;
        const count = filter.getAppliedValuesCount(filter.key);
        return count ? `${count} ${count === 1 ? 'tag' : 'tags'}` : ``;
      },
      expanded: this.filters.postTag ? this.filters.postTag.expanded : false,
      toApiParam: () => {
        const value = this.filters.postTag.modelValue;
        return { post_tags: value.length ? value.map((t) => t.name) : null };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!Array.isArray(apiParams.post_tags)) {
          this.filters.postTag.modelValue = [
            ...this.filters.postTag.defaultModelValue
          ];
          return;
        }
        this.filters.postTag.modelValue = apiParams.post_tags
          .map((tagStr) => postTags.find((t) => t.name === tagStr))
          .filter((value) => !!value);
      }
    };
  }

  async inboxTagFilter(): Promise<Filter> {
    return {
      key: FilterKey.InboxTag,
      title: 'Inbox Tag',
      type: FilterType.TagsIncludeExcludeSelect,
      icon: 'ssi-tag-test',
      filterPlaceholder: 'Search for an inbox tag...',
      options: this.outboxTagsService.inboxTagsStore.value,
      modelValue: {
        include: [],
        exclude: []
      },
      defaultModelValue: {
        include: [],
        exclude: []
      },
      getAppliedValuesCount: (filterKey) => {
        const filter = this.filters.inboxTag;
        return (
          filter.modelValue.include.length + filter.modelValue.exclude.length
        );
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.inboxTag;
        const count = filter.getAppliedValuesCount(filter.key);
        return count ? `${count} ${count === 1 ? 'tag' : 'tags'}` : ``;
      },
      expanded: this.filters.inboxTag ? this.filters.inboxTag.expanded : false,
      toApiParam: () => {
        const value = this.filters.inboxTag.modelValue;
        return {
          tags: {
            join: 'and',
            values: {
              include: value.include.map((t) => t.name),
              exclude: value.exclude.map((t) => t.name)
            }
          }
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!apiParams.tags) {
          this.filters.inboxTag.modelValue = {
            include: [...this.filters.inboxTag.defaultModelValue.include],
            exclude: [...this.filters.inboxTag.defaultModelValue.exclude]
          };
          return;
        }
        const tags = this.outboxTagsService.inboxTagsStore.value;
        this.filters.inboxTag.modelValue = {
          include: apiParams.tags.values.include
            .map((tagStr) => tags.find((t) => t.name === tagStr))
            .filter((value) => !!value),
          exclude: apiParams.tags.values.exclude
            .map((tagStr) => tags.find((t) => t.name === tagStr))
            .filter((value) => !!value)
        };
      }
    };
  }

  async messageTypeFilter(): Promise<Filter> {
    let postAndReplyMessageTypes = [...messageTypesIterable];

    if (this.hidePrivateMessages) {
      postAndReplyMessageTypes = postAndReplyMessageTypes.filter(
        (t) =>
          t.class === OutboxMessageClass.Post ||
          t.class === OutboxMessageClass.Reply
      );
    }

    const postMessageTypes = postAndReplyMessageTypes.filter(
      (t) => t.class === OutboxMessageClass.Post
    );

    return {
      key: FilterKey.MessageType,
      title: 'Message Type',
      type: FilterType.MultiSelect,
      icon: 'ssi-post-results',
      options: postAndReplyMessageTypes,
      modelValue: [...postMessageTypes],
      defaultModelValue: [...postMessageTypes],
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.messageType;
        // in this case changed values count is needed, not applied values count
        // (as some values are applied by default)
        return getUniqueValues(filter.modelValue, filter.defaultModelValue)
          .length;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.messageType;
        const count = filter.getAppliedValuesCount(filter.key);
        return count
          ? `${count} ${count === 1 ? 'message type' : 'message types'}`
          : ``;
      },
      expanded: this.filters.messageType
        ? this.filters.messageType.expanded
        : false,
      toApiParam: () => {
        // api doesn't accept null or empty array value as a default (to not do any filtering by message type),
        // if nothing selected by the user then just pass default message types to the backend
        const value = this.filters.messageType.modelValue.length
          ? this.filters.messageType.modelValue
          : this.filters.messageType.defaultModelValue;

        return { types: value.map((t) => t.key) };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!Array.isArray(apiParams.types)) {
          this.filters.messageType.modelValue = [
            ...this.filters.messageType.modelValue
          ];
          return;
        }

        this.filters.messageType.modelValue = apiParams.types
          .map((type) => postAndReplyMessageTypes.find((t) => t.key === type))
          .filter((value) => !!value);
      }
    };
  }

  async scheduledPostFilter(): Promise<Filter> {
    const scheduledOptions = {
      include: {
        key: 'include',
        label: 'Include',
        description: 'Scheduled posts included'
      },
      exclude: {
        key: 'exclude',
        label: 'Exclude',
        description: 'Scheduled posts excluded'
      },
      exclusive: {
        key: 'exclusive',
        label: 'Only scheduled posts',
        description: 'Scheduled posts only'
      }
    };

    return {
      key: FilterKey.ScheduledPost,
      title: 'Scheduled Posts',
      type: FilterType.Select,
      icon: 'ssi-schedule',
      options: mapToIterable(scheduledOptions),
      modelValue: scheduledOptions.include,
      defaultModelValue: scheduledOptions.include,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.scheduledPost;
        return filter.modelValue ? filter.modelValue.description : ``;
      },
      expanded: this.filters.scheduledPost
        ? this.filters.scheduledPost.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.scheduledPost.modelValue;
        return { scheduled: value.key };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = scheduledOptions[apiParams.scheduled];
        if (savedOption) {
          this.filters.scheduledPost.modelValue = savedOption;
        } else {
          this.filters.scheduledPost.modelValue = this.filters.scheduledPost.defaultModelValue;
        }
      }
    };
  }

  async inApprovalFilter(): Promise<Filter> {
    const approvalsOptions = {
      all: {
        key: 'all',
        label: 'All posts',
        apiParam: { validated: null }
      },
      waitingApproval: {
        key: 'waitingApproval',
        label: 'Waiting for approval',
        apiParam: { validated: '0' }
      },
      approvedOnly: {
        key: 'approvedOnly',
        label: 'Approved only',
        apiParam: { validated: '1' }
      },
      disapprovedOnly: {
        key: 'disapprovedOnly',
        label: 'Disapproved only',
        apiParam: { validated: '2' }
      }
    };

    return {
      key: FilterKey.InApproval,
      title: 'In Approval',
      type: FilterType.Select,
      icon: 'ssi-total-approvals-requested',
      options: mapToIterable(approvalsOptions),
      modelValue: approvalsOptions.all,
      defaultModelValue: approvalsOptions.all,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.inApproval;
        return filter.modelValue ? filter.modelValue.label : ``;
      },
      expanded: this.filters.inApproval
        ? this.filters.inApproval.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.inApproval.modelValue;
        return value.apiParam;
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = Object.values(approvalsOptions).find(
          (o) => o.apiParam.validated === apiParams.validated
        );
        if (savedOption) {
          this.filters.inApproval.modelValue = savedOption;
        } else {
          this.filters.inApproval.modelValue = this.filters.inApproval.defaultModelValue;
        }
      }
    };
  }

  async failedPostFilter(): Promise<Filter> {
    const failedOptions = {
      include: {
        key: 'include',
        label: 'Include',
        description: 'Failed posts included',
        apiParam: { status: null }
      },
      exclude: {
        key: 'exclude',
        label: 'Exclude',
        description: 'Failed posts excluded',
        apiParam: { status: 'published' }
      },
      exclusive: {
        key: 'exclusive',
        label: 'Only failed posts',
        description: 'Failed posts only',
        apiParam: { status: 'publish_failed' }
      }
    };

    return {
      key: FilterKey.FailedPost,
      title: 'Failed Posts',
      type: FilterType.Select,
      icon: 'ssi-failed-post',
      options: mapToIterable(failedOptions),
      modelValue: failedOptions.include,
      defaultModelValue: failedOptions.include,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.failedPost;
        return filter.modelValue ? filter.modelValue.description : ``;
      },
      expanded: this.filters.failedPost
        ? this.filters.failedPost.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.failedPost.modelValue;
        return value.apiParam;
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = Object.values(failedOptions).find(
          (o) => o.apiParam.status === apiParams.status
        );
        if (savedOption) {
          this.filters.failedPost.modelValue = savedOption;
        } else {
          this.filters.failedPost.modelValue = this.filters.failedPost.defaultModelValue;
        }
      }
    };
  }

  async deletedPostFilter(): Promise<Filter> {
    const deletedOptions = {
      include: {
        key: 'include',
        label: 'Include',
        description: 'Deleted posts included'
      },
      exclude: {
        key: 'exclude',
        label: 'Exclude',
        description: 'Deleted posts excluded'
      },
      exclusive: {
        key: 'exclusive',
        label: 'Only deleted posts',
        description: 'Deleted posts only'
      }
    };

    return {
      key: FilterKey.DeletedPost,
      title: 'Deleted Posts',
      type: FilterType.Select,
      icon: 'ssi-delete',
      options: mapToIterable(deletedOptions),
      modelValue: deletedOptions.exclude,
      defaultModelValue: deletedOptions.exclude,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.deletedPost;
        return filter.modelValue ? filter.modelValue.description : ``;
      },
      expanded: this.filters.deletedPost
        ? this.filters.deletedPost.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.deletedPost.modelValue;
        return { deleted: value.key };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = deletedOptions[apiParams.deleted];
        if (savedOption) {
          this.filters.deletedPost.modelValue = savedOption;
        } else {
          this.filters.deletedPost.modelValue = this.filters.deletedPost.defaultModelValue;
        }
      }
    };
  }

  async sortDirectionFilter(): Promise<Filter> {
    const sortOptions = {
      asc: {
        key: 'asc',
        label: 'Ascending'
      },
      desc: {
        key: 'desc',
        label: 'Descending'
      }
    };

    return {
      key: FilterKey.SortDirection,
      title: 'Sort Direction',
      type: FilterType.Select,
      icon: 'ssi-calendar-sorting-filters',
      options: mapToIterable(sortOptions),
      modelValue: sortOptions.desc,
      defaultModelValue: sortOptions.desc,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.sortDirection;
        return filter.modelValue ? filter.modelValue.label + ' order' : ``;
      },
      expanded: this.filters.sortDirection
        ? this.filters.sortDirection.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.sortDirection.modelValue;
        return { sort_dir: value.key };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = sortOptions[apiParams.sort_dir];
        if (savedOption) {
          this.filters.sortDirection.modelValue = savedOption;
        } else {
          this.filters.sortDirection.modelValue = this.filters.sortDirection.defaultModelValue;
        }
      }
    };
  }

  async priorityFilter(): Promise<Filter> {
    const priorities = inboxPriorities.map((p) => {
      return {
        key: p,
        label: `P${p}`
      };
    });

    priorities.push({
      key: null,
      label: 'No priority set'
    });

    return {
      key: FilterKey.Priority,
      title: 'Priority Level',
      type: FilterType.MultiSelect,
      icon: 'ssi-priority',
      // filterPlaceholder: '',
      options: priorities,
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: this.multiSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.priority;
        return filter.modelValue.length
          ? `Prioirty: ${filter.modelValue.map((p) => p.key).join(', ')}`
          : ``;
      },
      expanded: this.filters.priority ? this.filters.priority.expanded : false,
      toApiParam: () => {
        const value = this.filters.priority.modelValue;
        return { priority: value.length ? value.map((p) => p.key) : null };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!Array.isArray(apiParams.priority)) {
          this.filters.priority.modelValue = [
            ...this.filters.priority.defaultModelValue
          ];
          return;
        }
        this.filters.priority.modelValue = apiParams.priority
          .map((pKey) => priorities.find((p) => p.key === pKey))
          .filter((value) => !!value);
      }
    };
  }

  async sentimentFilter(): Promise<Filter> {
    const sentiments = sentimentsIterable.map((s) => {
      return {
        key: s.key,
        label: this.translate.instant(s.labelNew)
      };
    });

    return {
      key: FilterKey.Sentiment,
      title: 'Sentiment',
      type: FilterType.MultiSelect,
      icon: 'ssi-amend-sentiment-new',
      // filterPlaceholder: '',
      options: sentiments,
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters[filterKey];
        if (
          !filter.modelValue.length ||
          filter.modelValue.length === sentiments.length
        ) {
          return 0;
        }
        return filter.modelValue.length;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.sentiment;
        return filter.modelValue.length
          ? `Sentiment: ${filter.modelValue.map((p) => p.label).join(', ')}`
          : ``;
      },
      expanded: this.filters.sentiment
        ? this.filters.sentiment.expanded
        : false,
      toApiParam: () => {
        // api doesn't accept null or empty array value as a default (to not do any filtering by sentiment),
        // if nothing selected by the user then pass all sentimetns to the backend
        const value = this.filters.sentiment.modelValue.length
          ? this.filters.sentiment.modelValue
          : sentiments;
        return {
          sentiment: value.map((s) => s.key)
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (
          !Array.isArray(apiParams.sentiment) ||
          apiParams.sentiment.length === sentiments.length
        ) {
          // all selected is treated the same as if nothing selected
          this.filters.sentiment.modelValue = [
            ...this.filters.sentiment.defaultModelValue
          ];
          return;
        }

        this.filters.sentiment.modelValue = apiParams.sentiment
          .map((sKey) => sentiments.find((s) => s.key === sKey))
          .filter((value) => !!value);
      }
    };
  }

  async visibilityFilter(): Promise<Filter> {
    const visibilityOptions = {
      all: {
        key: 'all',
        key2: 'all',
        label: 'All messages'
      },
      private: {
        key: 'private',
        key2: 'PRIVATE',
        label: 'Private messages only'
      },
      public: {
        key: 'public',
        key2: 'PUBLIC',
        label: 'Public messages only'
      }
    };

    return {
      key: FilterKey.Visibility,
      title: 'Visibility',
      type: FilterType.Select,
      icon: 'ssi-visibility-filter',
      options: mapToIterable(visibilityOptions),
      modelValue: visibilityOptions.all,
      defaultModelValue: visibilityOptions.all,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.visibility;
        return filter.modelValue ? filter.modelValue.label : ``;
      },
      expanded: this.filters.visibility
        ? this.filters.visibility.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.visibility.modelValue;
        return {
          visibility:
            this.config === Config.InboxMessage ? value.key : value.key2
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = Object.values(visibilityOptions).find(
          (o) =>
            o.key === apiParams.visibility || o.key2 === apiParams.visibility
        );
        if (savedOption) {
          this.filters.visibility.modelValue = savedOption;
        } else {
          this.filters.visibility.modelValue = this.filters.visibility.defaultModelValue;
        }
      }
    };
  }

  async commentsFilter(): Promise<Filter> {
    const commentsOptions = {
      include: {
        key: 'include',
        key2: 'INCLUDE',
        label: 'All messages'
      },
      exclusive: {
        key: 'exclusive',
        key2: 'EXCLUSIVE',
        label: 'Paid Comments'
      },
      exclude: {
        key: 'exclude',
        key2: 'EXCLUDE',
        label: 'Organic Comments'
      }
    };

    return {
      key: FilterKey.Comments,
      title: 'Ad Comments',
      type: FilterType.Select,
      icon: 'ssi-ad-post',
      options: mapToIterable(commentsOptions),
      modelValue: commentsOptions.include,
      defaultModelValue: commentsOptions.include,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.comments;
        return filter.modelValue.label;
      },
      expanded: this.filters.comments ? this.filters.comments.expanded : false,
      toApiParam: () => {
        const value = this.filters.comments.modelValue;
        return {
          paid_post: value.key
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = Object.values(commentsOptions).find(
          (o) => o.key === apiParams.paid_post
        );
        if (savedOption) {
          this.filters.comments.modelValue = savedOption;
        } else {
          this.filters.comments.modelValue = this.filters.comments.defaultModelValue;
        }
      }
    };
  }

  async languageFilter(): Promise<Filter> {
    const languages = Object.entries(LANGUAGES)
      .filter(([code, label]) => code !== 'en-gb')
      .map(([code, label]) => ({ key: code, label }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return {
      key: FilterKey.Language,
      title: 'Language',
      type: FilterType.MultiSelect,
      icon: 'ssi-language-test',
      filterPlaceholder: 'Search for a language...',
      options: languages,
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: this.multiSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.language;
        const count = filter.getAppliedValuesCount(filter.key);
        return count
          ? `${count} ${count === 1 ? 'language' : 'languages'}`
          : ``;
      },

      expanded: this.filters.language ? this.filters.language.expanded : false,
      toApiParam: () => {
        const value = this.filters.language.modelValue;
        return { lang: value.map((l) => l.key) };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!Array.isArray(apiParams.lang)) {
          this.filters.language.modelValue = [
            ...this.filters.language.defaultModelValue
          ];
          return;
        }

        this.filters.language.modelValue = apiParams.lang
          .map((code) => languages.find((l) => l.key === code))
          .filter((value) => !!value);
      }
    };
  }

  async assignedToFilter(): Promise<Filter> {
    let teams = await this.teamsService.getAllActive();
    teams = orderBy(teams, [(c) => c.name.toLowerCase()]);

    let colleagues = await this.colleaguesService.getAllActive();
    colleagues = orderBy(colleagues, [(c) => c.name.toLowerCase()]);

    const optionsIterable = [
      ...teams.map((t) => {
        return {
          key: `group:${t.id}`,
          key2: `team:${t.id}`,
          label: t.name,
          icon: 'ssi-users-group',
          type: 'team'
        };
      }),
      ...colleagues.map((c) => {
        return {
          key: `user:${c.id}`,
          key2: `user:${c.id}`,
          label: c.name,
          icon: 'ssi-user',
          type: 'user'
        };
      })
    ];

    if (this.config === Config.InboxMessage) {
      optionsIterable.unshift({
        key: 'no-one',
        key2: '',
        label: 'Unassigned only',
        icon: 'ssi-unassigned',
        type: undefined
      });
      optionsIterable.unshift({
        key: 'me',
        key2: '',
        label: 'Assigned to me',
        icon: 'ssi-assigned',
        type: undefined
      });
    } else {
      optionsIterable.unshift({
        key: null,
        key2: 'me_or_my_teams',
        label: 'Assigned to me',
        icon: 'ssi-assigned',
        type: undefined
      });
    }

    return {
      key: FilterKey.AssignedTo,
      title: 'Assigned to',
      type: FilterType.Select,
      icon: 'ssi-assigned',
      filterPlaceholder: 'Search teams or users...',
      options: optionsIterable,
      modelValue: undefined,
      defaultModelValue: undefined,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.assignedTo;
        if (!filter.modelValue) {
          return '';
        }
        if (!filter.modelValue.type) {
          return filter.modelValue.label;
        }
        return `Assigned to ${filter.modelValue.label}`;
      },
      expanded: this.filters.assignedTo
        ? this.filters.assignedTo.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.assignedTo.modelValue;
        return this.config === Config.InboxMessage
          ? { assigned: value ? value.key : null }
          : { assignment: value ? value.key2 : '' };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const paramKey =
          this.config === Config.InboxMessage ? 'assigned' : 'assignment';
        if (!apiParams[paramKey]) {
          this.filters.assignedTo.modelValue = this.filters.assignedTo.defaultModelValue;
          return;
        }

        this.filters.assignedTo.modelValue = optionsIterable.find(
          (o) => o.key === apiParams[paramKey] || o.key2 === apiParams[paramKey]
        );
      }
    };
  }

  async actionedByFilter(): Promise<Filter> {
    let colleagues = await this.colleaguesService.getAllActive();
    colleagues = orderBy(colleagues, [(c) => c.name.toLowerCase()]);

    return {
      key: FilterKey.ActionedBy,
      title: 'Actioned by',
      type: FilterType.Select,
      icon: 'ssi-actioned-new',
      filterPlaceholder: 'Search users...',
      options: colleagues,
      modelValue: undefined,
      defaultModelValue: undefined,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.actionedBy;
        return filter.modelValue ? `Actioned by ${filter.modelValue.name}` : ``;
      },
      expanded: this.filters.actionedBy
        ? this.filters.actionedBy.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.actionedBy.modelValue;
        return { actioned_by: value ? value.id : null };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!apiParams.actioned_by) {
          this.filters.actionedBy.modelValue = this.filters.actionedBy.defaultModelValue;
          return;
        }
        this.filters.actionedBy.modelValue = colleagues.find(
          (c) => c.id === apiParams.actioned_by
        );
      }
    };
  }

  async messageStatusFilter(): Promise<Filter> {
    const statusOptions = {
      [ActivityStatus.Unread]: {
        key: ActivityStatus.Unread,
        label: 'Unread'
      },
      [ActivityStatus.Unactioned]: {
        key: ActivityStatus.Unactioned,
        label: 'Unactioned'
      },
      [ActivityStatus.Actioned]: {
        key: ActivityStatus.Actioned,
        label: 'Actioned'
      }
    };

    const statusOptionsIterable = mapToIterable(statusOptions);

    return {
      key: FilterKey.MessageStatus,
      title: 'Message Status',
      type: FilterType.MultiSelect,
      icon: 'ssi-ongoing-message',
      options: statusOptionsIterable,
      modelValue: [],
      defaultModelValue: [],
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters[filterKey];
        if (
          !filter.modelValue.length ||
          filter.modelValue.length === statusOptionsIterable.length
        ) {
          return 0;
        }
        return filter.modelValue.length;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.messageStatus;
        return filter.modelValue.length
          ? `Status: ${filter.modelValue.map((s) => s.label).join(', ')}`
          : ``;
      },
      expanded: this.filters.messageStatus
        ? this.filters.messageStatus.expanded
        : false,
      toApiParam: () => {
        // api doesn't accept null or empty array value as a default (to not do any filtering by status),
        // if nothing selected by the user then just pass all statuses to the backend
        const value = this.filters.messageStatus.modelValue.length
          ? this.filters.messageStatus.modelValue
          : statusOptionsIterable;
        return {
          status: value.map((s) => s.key)
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (
          !Array.isArray(apiParams.status) ||
          apiParams.status.length === statusOptionsIterable.length
        ) {
          // all selected is treated the same as if nothing selected
          this.filters.messageStatus.modelValue = [
            ...this.filters.messageStatus.defaultModelValue
          ];
          return;
        }

        this.filters.messageStatus.modelValue = apiParams.status
          .map((status) => statusOptions[status])
          .filter((value) => !!value);
      }
    };
  }

  async conversationStatusFilter(): Promise<Filter> {
    const statusOptions = {
      [ConversationStatus.Unread]: {
        key: ConversationStatus.Unread,
        label: 'Unread'
      },
      [ConversationStatus.Unactioned]: {
        key: ConversationStatus.Unactioned,
        label: 'Unactioned'
      },
      [ConversationStatus.Actioned]: {
        key: ConversationStatus.Actioned,
        label: 'Actioned'
      },
      [ConversationStatus.Resolved]: {
        key: ConversationStatus.Resolved,
        label: 'Resolved'
      },
      // [ConversationStatus.OnHold]: {
      //   key: ConversationStatus.OnHold,
      //   label: 'On Hold'
      // },
      [ConversationStatus.OnHoldInternal]: {
        key: ConversationStatus.OnHoldInternal,
        label: 'On Hold Internal'
      },
      [ConversationStatus.OnHoldExternal]: {
        key: ConversationStatus.OnHoldExternal,
        label: 'On Hold External'
      }
    };

    return {
      key: FilterKey.ConversationStatus,
      title: 'Conversation Status',
      type: FilterType.MultiSelect,
      icon: 'ssi-ongoing-message',
      options: mapToIterable(statusOptions),
      modelValue: [
        statusOptions.UNREAD,
        statusOptions.UNACTIONED,
        statusOptions.ACTIONED
      ],
      defaultModelValue: [
        statusOptions.UNREAD,
        statusOptions.UNACTIONED,
        statusOptions.ACTIONED
      ],
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.conversationStatus;
        // in this case changed values count is needed, not applied values count
        // (as some values are applied by default)
        return getUniqueValues(filter.modelValue, filter.defaultModelValue)
          .length;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.conversationStatus;
        return filter.modelValue.length
          ? `Status: ${filter.modelValue.map((s) => s.label).join(', ')}`
          : ``;
      },
      expanded: this.filters.conversationStatus
        ? this.filters.conversationStatus.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.conversationStatus.modelValue;
        return {
          status: value.map((s) => s.key)
        };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        if (!Array.isArray(apiParams.status)) {
          this.filters.conversationStatus.modelValue = [
            ...this.filters.conversationStatus.defaultModelValue
          ];
          return;
        }

        this.filters.conversationStatus.modelValue = apiParams.status
          .map((status) => statusOptions[status])
          .filter((value) => !!value);
      }
    };
  }

  async isSilencedFilter(): Promise<Filter> {
    const isSilencedOptions = {
      include: {
        key: 'include',
        label: 'All messages'
      },
      exclusive: {
        key: 'exclusive',
        label: 'Silenced messages only'
      },
      exclude: {
        key: 'exclude',
        label: 'Unsilenced messages only'
      }
    };

    return {
      key: FilterKey.IsSilenced,
      title: 'Silenced messages',
      type: FilterType.Select,
      icon: 'ssi-silence-thread',
      options: mapToIterable(isSilencedOptions),
      modelValue: isSilencedOptions.exclude,
      defaultModelValue: isSilencedOptions.exclude,
      getAppliedValuesCount: this.singleSelectAppliedValuesCount,
      getAppliedValuesLabel: () => {
        const filter = this.filters.isSilenced;
        return filter.modelValue ? filter.modelValue.label : ``;
      },
      expanded: this.filters.isSilenced
        ? this.filters.isSilenced.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.isSilenced.modelValue;
        return { is_silenced: value.key };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const savedOption = isSilencedOptions[apiParams.is_silenced];
        if (savedOption) {
          this.filters.isSilenced.modelValue = savedOption;
        } else {
          this.filters.isSilenced.modelValue = this.filters.isSilenced.defaultModelValue;
        }
      }
    };
  }

  async timePeriodFilter(): Promise<Filter> {
    return {
      key: FilterKey.TimePeriod,
      title: 'Time Period',
      type: FilterType.DateRange,
      icon: 'ssi-date-preset',
      modelValue: {
        start: null,
        end: null
      },
      defaultModelValue: {
        start: null,
        end: null
      },
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.timePeriod;
        let count = 0;
        if (filter.modelValue.start !== filter.defaultModelValue.start) {
          count++;
        }
        if (filter.modelValue.end !== filter.defaultModelValue.end) {
          count++;
        }
        return count;
      },
      getAppliedValuesLabel: () => {
        const value = this.filters.timePeriod.modelValue;
        let label = '';
        if (value.start) {
          const startTransformed = this.datePipe.transform(
            value.start,
            'MMM d, y, HH:mm:ss'
          );
          label += `Since: ${startTransformed}`;
        }
        if (value.end) {
          if (value.start) {
            label += `, `;
          }
          const endTransformed = this.datePipe.transform(
            value.end,
            'MMM d, y, HH:mm:ss'
          );
          label += `Until: ${endTransformed}`;
        }
        return label;
      },
      expanded: this.filters.timePeriod
        ? this.filters.timePeriod.expanded
        : false,
      toApiParam: () => {
        const value = this.filters.timePeriod.modelValue;

        if (this.config === Config.InboxMessage) {
          return {
            dt_after: value.start ? value.start : null, // TODO: convert to UTC?
            dt_before: value.end ? value.end : null
          };
        } else if (this.config === Config.InboxConversation) {
          return {
            since: value.start ? value.start : null,
            until: value.end ? value.end : null
          };
        } else if (this.config === Config.Outbox) {
          return {
            start_date: value.start ? value.start : null,
            end_date: value.end ? value.end : null
          };
        } else {
          return {
            start: value.start ? value.start : null,
            end: value.end ? value.end : null
          };
        }
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const paramKeys =
          this.config === Config.InboxMessage
            ? { start: 'dt_after', end: 'dt_before' }
            : this.config === Config.InboxConversation
            ? { start: 'since', end: 'until' }
            : this.config === Config.Outbox
            ? { start: 'start_date', end: 'end_date' }
            : { start: 'start', end: 'end' };

        if (apiParams[paramKeys.start]) {
          this.filters.timePeriod.modelValue.start = apiParams[paramKeys.start];
        } else {
          this.filters.timePeriod.modelValue.start = this.filters.timePeriod.defaultModelValue.start;
        }

        if (apiParams[paramKeys.end]) {
          this.filters.timePeriod.modelValue.end = apiParams[paramKeys.end];
        } else {
          this.filters.timePeriod.modelValue.end = this.filters.timePeriod.defaultModelValue.end;
        }
      }
    };
  }

  async authorFilter(): Promise<Filter> {
    return {
      key: FilterKey.Author,
      title: 'Author',
      type: FilterType.InputSearch,
      icon: 'ssi-search-small',
      placeholder: 'Search for an author...',
      modelValue: '',
      defaultModelValue: '',
      getAppliedValuesCount: (filterKey: FilterKey) => {
        const filter = this.filters.author;
        return filter.modelValue ? 1 : 0;
      },
      getAppliedValuesLabel: () => {
        const filter = this.filters.author;
        return filter.modelValue ? `Author: "${filter.modelValue}"` : ``;
      },
      expanded: this.filters.author ? this.filters.author.expanded : false,
      toApiParam: () => {
        const value = this.filters.author.modelValue;
        return { author: value ? value : null };
      },
      fromApiParam: (apiParams: KeyValueObject) => {
        const param = apiParams['author'];
        this.filters.author.modelValue = param
          ? param
          : this.filters.author.defaultModelValue;
      }
    };
  }
}
