import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { ThemeDetectorsComponent } from './theme-detectors.component';
import { ThemeDetectorCreateEditModule } from './theme-detector-create-edit/theme-detector-create-edit.module';
import { DetectedThemesModule } from './detected-themes/detected-themes.module';

import { NoResultsModule } from '../../../../common/components/no-results/no-results.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights.theme-detectors',
          url: '/theme-detectors',
          component: ThemeDetectorsComponent
        }
      ]
    }),
    ThemeDetectorCreateEditModule,
    DetectedThemesModule,
    NoResultsModule
  ],
  exports: [],
  declarations: [ThemeDetectorsComponent]
})
export class ThemeDetectorsModule {}
