<div
  class="inbox-activity-left"
  [ngClass]="{'crm-hidden': hideProfileCrm}"
>
  <ssi-inbox-pinned-activities
    *ngIf="pinnedActivities && pinnedActivities.length > 0"
    [activeActivity]="activity"
    [pinnedActivities]="pinnedActivities"
    (onUnpin)="removePinnedActivity($event)"
    (onOpenActivity)="onChangeActivity.emit($event)"
  >
  </ssi-inbox-pinned-activities>

  <div class="inbox-activity-body">

    <div
      class="inbox-activity-thread-container"
      *ngIf="!activityAction"
    >

      <ssi-action-pad
        [activity]="activity"
        [activityThread]="activityThread"
        [conversation]="conversation"
        [crmHidden]="hideProfileCrm"
        [inboxMode]="inboxMode"
        [isPushMode]="isPushMode"
        [actionRedact]="activity?.inbox.requires_action && !activity.interaction.redacted_by"
        (onShowProfile)="toggleProfileCrm($event)"
        (onPin)="togglePinnedActivities($event.activity)"
        (onRedactConfirmed)="activity.redact()"
        (onResolveConversation)="onResolve($event)"
        (onHoldConversationStatusChange)="onHoldConversationStatusChange($event)"
        (onExitConversation)="onExitConversation.emit()"
        (onExitActivity)="onExitActivity.emit()"
        (onChangeLanguage)="activity.updateLanguage($event)"
        (onReloadResults)="onReloadResults.emit()"
      >
      </ssi-action-pad>

      <ssi-inbox-activity-thread
        *ngIf="activity"
        [activity]="activity"
        [conversation]="conversation"
        [inboxMode]="inboxMode"
        [hideArrowShortcuts]="hideArrowShortcuts"
        [isPushMode]="isPushMode"
        [isReply]="isReply"
        (onReplyTextChanged)="onReplyTextChanged($event.isDefault, $event.activity)"
        (onChangeActivityThread)="onChangeActivityThread($event.activityThread)"
        (onShowFilters)="onShowFilters.emit()"
        (onChangeActivity)="onChangeActivity.emit($event)"
        (onReloadResults)="onReloadResults.emit()"
        (onChangeConversation)="onChangeConversation.emit($event)"
        (onExitConversation)="onExitConversation.emit()"
        (onResolveConversation)="onResolve($event.opts)"
        (onHoldConversationStatusChange)="onHoldConversationStatusChange($event.opts)"
      >
      </ssi-inbox-activity-thread>
    </div>

    <ssi-inbox-activity-crm
      *ngIf="activityAction === 'crm'"
      [account]="activity.account"
      [profile]="profile"
      [plugins]="plugins"
      [activityProfile]="activity.author"
      [crmPerson]="crmPerson"
      [linkedSocialProfiles]="linkedSocialProfiles"
      [externalIntegrations]="externalIntegrations"
      (linkIntegration)="linkIntegration($event.integration)"
      (showExternalCrmPerson)="showCollapsedViewExternalCrmPerson($event.integration)"
      (onClose)="activityAction = null; previousActivityAction = null"
    >
    </ssi-inbox-activity-crm>

    <ssi-inbox-activity-external-crm-person
      *ngIf="activityAction === 'externalCrmPerson'"
      [externalIntegrationPerson]="externalCrmPerson"
      [externalIntegration]="externalCrmIntegration"
      [activityProfile]="activity?.author"
      [activity]="activity"
      [crmPerson]="crmPerson"
      (unlinkIntegration)="unlinkIntegration($event.integration); contractExternalCrmPerson()"
      (onClose)="contractExternalCrmPerson()"
    ></ssi-inbox-activity-external-crm-person>

  </div>

</div>

<ssi-inbox-crm-sidebar
  [ngClass]="{'pinned-activities-visible': pinnedActivities && pinnedActivities.length > 0}"
  *ngIf="!hideProfileCrm"
  [account]="activity?.account"
  [activity]="activity"
  [activityProfile]="activity?.author"
  [profile]="profile"
  [crmPerson]="crmPerson"
  [personLoadingTracker]="personLoadingTracker"
  [profileSentiment]="profileSentiment"
  [selectedIntegration]="externalCrmIntegration"
  [externalIntegrationPerson]="externalCrmPerson"
  [externalIntegrations]="externalIntegrations"
  [linkedSocialProfiles]="linkedSocialProfiles"
  [plugins]="plugins"
  [initialisedPlugins]="initialisedPlugins"
  (onHide)="toggleProfileCrm(false)"
  (onExpandCrmProfile)="expandExternalCrmPerson()"
  (closeSelectedIntegration)="closeSelectedIntegration()"
  (unlinkIntegration)="unlinkIntegration($event.integration)"
  (showExternalCrmPerson)="showExternalCrmPerson($event.integration)"
  (linkIntegration)="linkIntegration($event.integration)"
  (handlePluginCallback)="handlePluginCallback($event.type, $event.callbackId, $event.context)"
>
</ssi-inbox-crm-sidebar>