import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PublisherFacebookPostPreviewComponent } from './publisher-facebook-post-preview/publisher-facebook-post-preview.component';
import { PublisherTwitterPostPreviewComponent } from './publisher-twitter-post-preview/publisher-twitter-post-preview.component';
import { PublisherLinkedinPostPreviewComponent } from './publisher-linkedin-post-preview/publisher-linkedin-post-preview.component';
import { PublisherYoutubePostPreviewComponent } from './publisher-youtube-post-preview/publisher-youtube-post-preview.component';
import { PublisherInstagramPostPreviewComponent } from './publisher-instagram-post-preview/publisher-instagram-post-preview.component';
import { PublisherSocialNetworkPreviewModalComponent } from './publisher-social-network-preview-modal.component';
import { EmojiTextModule } from '../../../directives/emoji-text/emoji-text.module';
import { FallbackImageModule } from '../../../directives/fallback-image/fallback-image.module';
import { OutboxPostTextLinkifyPipe } from './outbox-post-text-linkify/outbox-post-text-linkify.pipe';
import { Nl2brModule } from '../../../pipes/nl2br/nl2br.module';
import { OrloComponentsModule } from 'orlo-components';
import { FilterModule } from '../../../pipes/filter/filter.module';
import { AlertModule } from '../../alert/alert.module';
import { TruncateModule } from '../../../pipes/truncate/truncate.module';

@NgModule({
  imports: [
    CommonModule,
    EmojiTextModule,
    FallbackImageModule,
    Nl2brModule,
    NgbDropdownModule.forRoot(),
    OrloComponentsModule,
    FilterModule,
    AlertModule,
    TruncateModule
  ],
  declarations: [
    PublisherSocialNetworkPreviewModalComponent,
    PublisherFacebookPostPreviewComponent,
    PublisherTwitterPostPreviewComponent,
    PublisherLinkedinPostPreviewComponent,
    PublisherYoutubePostPreviewComponent,
    PublisherInstagramPostPreviewComponent,
    OutboxPostTextLinkifyPipe
  ],
  providers: [OutboxPostTextLinkifyPipe],
  entryComponents: [PublisherSocialNetworkPreviewModalComponent],
  exports: [PublisherSocialNetworkPreviewModalComponent]
})
export class PublisherSocialNetworkPreviewModalModule {}
