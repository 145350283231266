<div class="fact-overview"
     [ngStyle]="{width: width, height: height}"
     [class.extra-padding]="isNumber(previousValue)">
  <i *ngIf="iconClass"
     [ngStyle]="{'background-color': circleColor, 'font-size': iconSize}"
     class="ssi {{iconClass}} stat-icon"
     [style.color]="iconColor"></i>
  <div *ngIf="svgIcon"
       class="svg-icon-wrap"
       [ngStyle]="{'background-color': circleColor}">
    <ssi-icon [icon]="svgIcon"
              height="26"
              width="26"
              [color]="iconColor"></ssi-icon>
  </div>
  <span>{{label}}</span>
  <div class="divider"
       [ngStyle]="{'background-color': dividerColor}"></div>
  <h5>
    <ng-container *ngIf="showString; else bigNumberTemplate">
      <span [innerHTML]="symbolBefore && symbol ? symbol : ''"></span>
      <span>{{number}}</span>
      <span [innerHTML]="!symbolBefore && symbol ? symbol : ''"></span>
    </ng-container>
    <ng-template #bigNumberTemplate>
      <span [innerHTML]="symbolBefore && symbol ? symbol : ''"></span>
      <ssi-big-number
        [number]="number"
        [maxFractionDigitsHover]="maxFractionDigitsHover"
        [denotation]="{million: 'm', thousand: 'k'}"
      >
      </ssi-big-number>
      <span [innerHTML]="!symbolBefore && symbol ? symbol : ''"></span>
    </ng-template>
  </h5>

  <div *ngIf="isNumber(previousValue)"
       class="stat-change-container">
    <ssi-stat-change [current]="number"
                     [previous]="previousValue"
                     [boxed]="true"
                     [suffix]="''"
                     position="bottom"></ssi-stat-change>
  </div>
</div>