import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignAddBoxComponent } from './campaign-add-box.component';
import { FormsModule } from '@angular/forms';
import { AddSecondsModule } from '../../../../common/pipes/add-seconds/add-seconds.module';
import { flatpickrFactory } from '../../../../common/components/publisher/publisher.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { FlatpickrModule } from 'angularx-flatpickr';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AddSecondsModule,
    OffClickModule,
    FlatpickrModule.forRoot()
  ],
  declarations: [CampaignAddBoxComponent],
  exports: [CampaignAddBoxComponent]
})
export class CampaignAddBoxModule {}
