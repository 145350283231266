<div class="advertising-float-top">
  <button class="btn btn-default btn-rounded btn-top" uiSref="^.presetList">
    Close create target audience
  </button>
</div>

<div class="advertising-section preset-form">
  <div class="advertising-section-bullet-wrap">
    <span class="advertising-section-bullet">
      <i [ngClass]="newPreset.account && newPreset.account.socialNetwork.icon.web"></i>
    </span>
    <!-- <span class="advertising-section-bullet">
      <i class="ssi ssi-campaign"></i>
    </span> -->
  </div>
  <div class="advertising-preset-info">
    <div>
      <div class="advertising-preset-wrap">
        <p class="advertising-label">Account:</p>
        <div class="preset-form-dropdown">
          <select [(ngModel)]="newPreset.account" required>
            <option [ngValue]="undefined" disabled>Select an account</option>
            <option *ngFor="let account of advertisingAccounts; trackBy:trackById" [ngValue]="account">
              {{ account.name }} ({{ account.accountTypeLabel }})
            </option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>
      <div class="advertising-preset-wrap">
        <p class="advertising-label">Name your target audience:</p>
        <input type="text" class="preset-form-input" placeholder="Target Audience Name" [(ngModel)]="newPreset.name" required>
      </div>
    </div>
    <div class="advertising-preset-info-copy">
      <!-- <p class="advertising-label advertising-label-block">Copy Ad Preset</p>
      <i class="ssi ssi-copy-paste"></i> -->
    </div>
  </div>
</div>

<div class="advertising-section" *ngIf="newPreset.account">
  <div class="advertising-section-bullet-wrap">
    <span class="advertising-section-bullet">
      <i class="ssi ssi-audience"></i>
    </span>
  </div>
  <ssi-advertising-targeting-builder
    class="advertising-page-section"
    [account]="newPreset.account"
    [(targetingOptions)]="newPreset.targetingOptions">
  </ssi-advertising-targeting-builder>
</div>

<!-- <div class="advertising-section advertising-section-collapse" *ngIf="newPreset.account">
  <div class="advertising-section-bullet-wrap">
    <span class="advertising-section-bullet">
      <i class="ssi ssi-budget"></i>
    </span>
  </div>
  <div class="advertising-preset-wrap">
    <div class="advertising-preset-wrap">
      <p class="advertising-label">Budget:</p>
      <div class="preset-form-dropdown-group">
        <div class="preset-form-dropdown">
          <select>
            <option value="" disabled selected>Daily Limit</option>
            <option value="weekly">Weekly Limit</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
        <div class="preset-form-dropdown">
          <select>
            <option value="" disabled selected>£0.00</option>
            <option value="1">£1.00</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>
    </div>
    <div class="advertising-preset-wrap">
      <p class="advertising-label">Bidding:</p>
      <div class="preset-form-dropdown-group">
        <div class="preset-form-dropdown">
          <select>
            <option value="" disabled selected>Bidding type</option>
            <option value="cpi">CPI</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
        <div class="preset-form-dropdown">
          <select>
            <option value="" disabled selected>£0.00</option>
            <option value="1">£1.00</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>
    </div>
    <div class="advertising-preset-wrap">
      <p class="advertising-label advertising-label-hide">Budget:</p>
      <div class="preset-form-dropdown-group">
        <div class="preset-form-dropdown">
          <select>
            <option value="" disabled selected>Advert Language</option>
            <option value="english">English</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
        <div class="preset-form-dropdown">
          <select>
            <option value="" disabled selected>Advert Country</option>
            <option value="england">England</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="advertising-section" *ngIf="newPreset.account">
  <div class="advertising-section-bullet-wrap">
    <span class="advertising-section-bullet">
      <i class="ssi ssi-wand"></i>
    </span>
  </div>
  <!-- <div class="advertising-preset-wrap">
    <p class="advertising-label"><span translate="SCHEDULE"></span>:</p>
    <button
      class="btn btn-warning btn-advertising-preset"
      [disabled]="!newPreset.name || isTargetingInvalid()"
      (click)="savePreset()"
      translate="RUN_MY_AD_PRESET_CONTINUOUSLY_STARTING_TODAY">
    </button>
  </div> -->

  <div class="advertising-preset-wrap">
    <p class="advertising-label">Saving Time!</p>
    <button
      class="btn btn-primary btn-advertising-preset"
      uiSref="^.presetList">
      Cancel
    </button>
    <button
      class="btn btn-warning btn-advertising-preset"
      [disabled]="!newPreset.name || isTargetingInvalid()"
      (click)="savePreset()">
      Save Target Audience
    </button>
  </div>
</div>
