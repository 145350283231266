import {
  API,
  UserModel,
  Auth,
  OutboxPublisher,
  OutboxMessageClass,
  ProfileModel,
  AccountModel
} from '@ui-resources-angular';
import { UpgradeModule } from '@angular/upgrade/static';
import { UrlService } from '@uirouter/angular';
import axios from 'axios';
import { ng1module } from '../angularjs/app.module';
import { RedirectService } from './common/services/redirect/redirect.service';
import {
  API_ENDPOINT,
  APP_VERSION,
  ENVIRONMENT,
  EnvironmentType
} from './environment';
import { ErrorHandlerService } from './common/services/error-handler/error-handler.service';
import { CompanyService } from './common/services/company/company.service';
import { StateService, TransitionService, Transition } from '@uirouter/angular';
import { AuthService } from './common/services/auth/auth.service';
import { ServerService } from './common/services/server/server.service';
import { UpdateManagerService } from './common/services/update-manager/update-manager.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MAXIMUM_WORKFLOW_ACCOUNTS, WEEK_STARTS_ON } from './common/constants';
import objectFitImages from 'object-fit-images';
import { SocketsService } from './common/services/sockets/sockets.service';
import { UserPreferencesService } from './common/services/user-preferences/user-preferences.service';
import { WorkflowManagerService } from './common/services/workflow-manager/workflow-manager.service';
import {
  CampaignsService,
  ColleaguesService,
  TeamsService
} from './common/services/api';
import { appInjector } from './app-injector';

export function bootstrap(upgrade: UpgradeModule) {
  console.log('logging!!! bootstrap');

  const api = upgrade.injector.get(API);
  api.log = undefined;
  api.error = undefined;
  api.basePath = `${API_ENDPOINT}/`;

  axios.interceptors.request.use((config) => {
    const $auth: Auth = upgrade.$injector.get('$auth');
    const satellizerConfig = upgrade.$injector.get('SatellizerConfig');
    if (!config['skipAuthorization'] && $auth.isAuthenticated()) {
      config.headers[satellizerConfig.tokenHeader] = `${
        satellizerConfig.tokenType
      } ${$auth.getToken()}`;
    }
    return config;
  });

  upgrade.injector.get(ErrorHandlerService).addAngularInterceptors();

  const userModel = upgrade.injector.get(UserModel);

  userModel.events.loggedIn.subscribe(({ token }) => {
    const $auth: Auth = upgrade.$injector.get('$auth');
    $auth.setToken(token);
  });

  userModel.events.loggedOut.subscribe(() => {
    const $auth: Auth = upgrade.$injector.get('$auth');
    const redirect: RedirectService = upgrade.injector.get<RedirectService>(
      RedirectService
    );
    if ($auth.isAuthenticated()) {
      $auth.logout();
    }
    redirect.logout();
  });

  userModel.events.changed.subscribe(async ({ user }) => {
    const company: CompanyService = upgrade.injector.get(CompanyService);
    const companyConfig = await company.getConfig();
    const fetchBrowserlessCookie = document.cookie.match(
      new RegExp('(^| )' + 'browserless_io' + '=([^;]+)')
    );
    const browserlessMode = fetchBrowserlessCookie
      ? fetchBrowserlessCookie[2] === 'true'
      : false;
    if (!browserlessMode) {
      (window as any).pendo.initialize({
        excludeAllText: true,
        visitor: {
          id: user.id,
          billingPermission: user.permissions.company.billing
        },
        account: {
          id: user.company_name,
          companyID: user.company_id,
          creationDate: user.created_at,
          ADVERTS: companyConfig.feature_flags.includes('ADVERTS'),
          LIVECHAT: companyConfig.feature_flags.includes('LIVECHAT'),
          LINKEDIN_LINK_IMAGE_POST: companyConfig.feature_flags.includes(
            'LINKEDIN_LINK_IMAGE_POST'
          ),
          ORLO_PLUGIN: companyConfig.feature_flags.includes('ORLO_PLUGIN'),
          PREDICTIVE_TEXT: companyConfig.feature_flags.includes(
            'PREDICTIVE_TEXT'
          ),
          IS_PUBLIC_SECTOR_CLIENT: companyConfig.feature_flags.includes(
            'IS_PUBLIC_SECTOR_CLIENT'
          )
        }
      });

      (window as any).aptrinsic(
        'identify',
        {
          // User Fields
          id: user.id, // Required for logged in app users
          email: user.email_address,
          firstName: user.first_name,
          lastName: user.last_name,
          billingPermission: user.permissions.company.billing
        },
        {
          // Account Fields
          id: user.company_id, // Required
          name: user.company_name,
          creationDate: user.created_at,
          livechat: companyConfig.feature_flags.includes('LIVECHAT'),
          insights: companyConfig.feature_flags.includes('ORLO_INSIGHTS'),
          socialListening: companyConfig.feature_flags.includes(
            'SOCIAL_LISTENING'
          ),
          isPublicSector: companyConfig.feature_flags.includes(
            'IS_PUBLIC_SECTOR_CLIENT'
          )
        }
      );
    }
  });

  api.httpConfig.paramsSerializer = (params) => {
    return upgrade.$injector.get('$httpParamSerializerJQLike')(params);
  };

  // common.run.ts
  (window as any).trackJs.configure({
    version: APP_VERSION
  });

  // const state: StateService = upgrade.injector.get(StateService);
  // state.defaultErrorHandler((error) => {
  //   const ignoreCodes = [
  //     2, // RejectType.SUPERSEDED
  //     3 // RejectType.ABORTED
  //   ];
  //   if (error && ignoreCodes.includes(error.type)) {
  //     return;
  //   }
  //   error.message = 'State change error: ' + error.message;
  //   console.error(error);
  //   window['requestIdleCallback'](() => {
  //     (window as any).track(error);
  //   });
  // });

  // const transitions: TransitionService = upgrade.injector.get(
  //   TransitionService
  // );
  // const redirect: RedirectService = upgrade.injector.get(RedirectService);
  // const auth: AuthService = upgrade.injector.get(AuthService);

  // transitions.onStart({}, (transition) => {
  //   const toStateData = transition.$to().data;
  //   if (
  //     toStateData &&
  //     toStateData.permissions &&
  //     toStateData.permissions.company &&
  //     auth.isAuthenticated()
  //   ) {
  //     return userModel.getAuthUser().then((user) => {
  //       if (!user.hasCompanyPermission(toStateData.permissions.company)) {
  //         return redirect.login();
  //       }
  //     });
  //   }
  // });

  const server: ServerService = upgrade.injector.get(ServerService);

  server.getSettings().then(({ oauth }) => {
    const satellizerConfig = upgrade.$injector.get('SatellizerConfig');
    console.log('satellizerConfig ref:', satellizerConfig);
    console.log('satellizerConfig oauth:', oauth);

    satellizerConfig.providers.google.scope = ['email'];
    satellizerConfig.providers.google.clientId = oauth['Google+'].clientId;
    satellizerConfig.providers.google.url = '/user/login';
    satellizerConfig.providers.google.optionalUrlParams.push('prompt');
    satellizerConfig.providers.google.prompt = 'select_account';
  });

  if (ENVIRONMENT === EnvironmentType.Production) {
    const updateManager: UpdateManagerService = upgrade.injector.get(
      UpdateManagerService
    );

    updateManager.initialise();
  }

  const translate: TranslateService = upgrade.injector.get(TranslateService);
  translate.use(translate.currentLang);

  moment.locale('en_gb', {
    week: {
      dow: WEEK_STARTS_ON // Monday is the first day of the week
    }
  } as any);

  objectFitImages();

  userModel.events.loggedOut.subscribe(() => {
    const socket: SocketsService = upgrade.injector.get(SocketsService);
    const userPreferences: UserPreferencesService = upgrade.injector.get(
      UserPreferencesService
    );

    if (socket) {
      socket.disconnect();
    }

    userPreferences.clearCache();
  });

  // const transitions: TransitionService = upgrade.injector.get(
  //   TransitionService
  // );

  // transitions.onStart(
  //   {
  //     to: 'auth.**'
  //   },
  //   (transition: Transition) => {
  //     const accountModel: AccountModel = upgrade.injector.get(AccountModel);
  //     const errorHandler: ErrorHandlerService = upgrade.injector.get(
  //       ErrorHandlerService
  //     );
  //     const workflowManager: WorkflowManagerService = upgrade.injector.get(
  //       WorkflowManagerService
  //     );

  //     const toStateData = transition.$to().data;
  //     const isWorkflowPage = toStateData && toStateData.isWorkflowPage;

  //     if (this.auth.isAuthenticated() && !isWorkflowPage) {
  //       return accountModel
  //         .findAccounts(workflowManager.getCurrentId())
  //         .then((accounts) => {
  //           if (accounts.length > MAXIMUM_WORKFLOW_ACCOUNTS) {
  //             // too many accounts

  //             errorHandler.error({
  //               message: translate.instant(
  //                 'YOU_CAN_ONLY_LOAD_A_MAXIMUM_OF__MAXIMUMWORKFLOWACCOUNTS__ACCOUNTS_' +
  //                   'AT_ONCE_PLEASE_ORGANISE_YOUR_ACCOUNTS_INTO_A_WORKFLOW',
  //                 { MAXIMUM_WORKFLOW_ACCOUNTS }
  //               )
  //             });
  //             // return state.go('auth.workflows');
  //             state.go('auth.workflows');
  //           }
  //         });
  //     }
  //   }
  // );

  userModel.events.changed.subscribe(({ user }) => {
    const campaignModel: CampaignsService = upgrade.injector.get(
      CampaignsService
    );
    const colleaguesService: ColleaguesService = upgrade.injector.get(
      ColleaguesService
    );
    const teamsService: TeamsService = upgrade.injector.get(TeamsService);

    campaignModel.getAll();
    colleaguesService.getAll();
    teamsService.getAll();

    if (window.ga) {
      const gaCustomProperties = {
        dimension1: user.id,
        dimension2: user.company_id
      };
      window.ga('set', '&uid', user.id);
      window.ga('set', gaCustomProperties);
      window.ga('websiteTracker.set', 'userId', user.id);
      window.ga('websiteTracker.set', gaCustomProperties);
    }

    (window as any).trackJs.configure({
      userId: user.id
    });
  });

  translate.onLangChange.subscribe(({ lang }) => {
    translate.use(lang);
  });

  // const transitions: TransitionService = upgrade.injector.get(
  //   TransitionService
  // );
  // const cancelListener = transitions.onStart({}, (transition) => {
  //   const $auth: Auth = upgrade.$injector.get('$auth');
  //   const redirect: RedirectService = upgrade.injector.get<RedirectService>(
  //     RedirectService
  //   );

  //   cancelListener();
  //   if ($auth.isAuthenticated()) {
  //     // doing a request to the server is the most accurate way of telling if
  //     // a user is logged in or not and should also prevent self DDoS if the API goes down
  //     return userModel
  //       .findAll({}, { autoError: false })
  //       .then(([authUser]) => {
  //         // don't return the promise, otherwise if it fails it will
  //         // log the user out or cause the page routing to fail
  //         // authUser.refreshAuthToken($auth);
  //       })
  //       .catch(() => {
  //         userModel.logout();
  //         // .finally(() => authService.clearJWT());
  //       });
  //   } else if (transition.$to().name.startsWith('auth.')) {
  //     // not already trying to go to an unauthenticated page
  //     redirect.logout();
  //   }
  // });

  // end of common.run.ts

  upgrade.bootstrap(document.body, [ng1module.name], { strictDi: true });

  const url = upgrade.injector.get<UrlService>(UrlService);
  url.listen();
  url.sync();
}
