import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Activity, User, UserModel } from '@ui-resources-angular';

import { Colleague, ColleaguesService } from '../../../services/api/colleagues';
import { Team, TeamsService } from '../../../services/api/teams';

import { Agent } from '../../../../../../library/models/live-chat/agent';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { Subscription } from 'rxjs';

export enum AssignmentType {
  Message = 'message',
  Conversation = 'conversation'
}

@Component({
  selector: 'ssi-live-chat-assign-agent',
  templateUrl: './live-chat-assign-agent.component.html'
})
export class LiveChatAssignAgentComponent implements OnInit {
  @Input() conversation: any;
  @Input() agent: Agent;

  @Output() onAssign = new EventEmitter<Colleague | Team | User>();
  @Output() onAssigned = new EventEmitter<Colleague | Team | User>();

  @ViewChild('conversation') conversationInput: ElementRef;

  searchTerm: string;
  teams: Team[] = [];
  colleagues: Colleague[] = [];
  filteredTeams: Team[] = [];
  filteredColleagues: Colleague[] = [];
  selectedAssignmentType = AssignmentType.Message;
  assignedUserOrTeam: Colleague | Team | User;
  selectedUserOrTeam: Colleague | Team | User;
  conversationAssignedUserOrTeam: Colleague | Team | User;
  conversationSelectedUserOrTeam: Colleague | Team | User;
  authUser: User;
  AssignmentType = AssignmentType;

  public sortedTeams: Team[] = [];
  public sortedColleagues: Colleague[] = [];
  public agents: Agent[];
  private _agentsSubscription: Subscription;

  assignmentTypes = [
    {
      label: 'ASSIGN_SELECTED_MESSAGE',
      value: AssignmentType.Message
    },
    {
      label: 'ASSIGN_WHOLE_CONVERSATION',
      value: AssignmentType.Conversation
    }
  ];

  availableAgentsModel = [];

  constructor(
    public activeModal: NgbActiveModal,
    private userModel: UserModel,
    private teamsService: TeamsService,
    private colleaguesService: ColleaguesService,
    private liveChatService: LiveChatService
  ) {}

  async ngOnInit() {
    const [colleagues, teams] = await Promise.all([
      this.colleaguesService.getAllActive(),
      this.teamsService.getAllActive()
    ]);
    const sortAlphabetically = (a: Agent, b: Agent) =>
      a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;

    this.sortedTeams = teams.sort(this.sortByTeamName);
    this.sortedColleagues = colleagues.sort(this.sortByFullName);

    this._agentsSubscription = await this.liveChatService.agentsReference
      .snapshotChanges()
      .subscribe((results) => {
        const agents = results.map(
          (result) =>
            new Agent(result.payload.doc.id, result.payload.doc.data())
        );

        const sortedAgents = [
          ...agents.filter((agent) => agent.isOnline).sort(sortAlphabetically),
          ...agents.filter((agent) => agent.isAway).sort(sortAlphabetically),
          ...agents.filter((agent) => agent.isOffline).sort(sortAlphabetically)
        ];

        sortedAgents.some((agent, index) => {
          if (agent.id === this.liveChatService.agentModel.id) {
            sortedAgents.splice(index, 1);
            sortedAgents.unshift(agent);

            return true;
          }
        });

        this.agents = sortedAgents;

        this.availableAgentsModel = this.sortedTeams.map((team) => {
          const { id, name, mentionName } = team;
          const activeAgents = this.findAllActiveAgents(id);
          return { id, name, mentionName, activeAgents };
        });
      });
  }

  findAllActiveAgents(teamId) {
    return this.agents.filter((agent) => {
      const matchedColleaguetoAgent = this.sortedColleagues.find(
        (colleague) => colleague.id === agent.id
      );
      return matchedColleaguetoAgent &&
        matchedColleaguetoAgent.teams.includes(teamId)
        ? true
        : false;
    });
  }

  sortByFullName(itemA: Colleague, itemB: Colleague) {
    return itemA.fullName.localeCompare(itemB.fullName);
  }

  sortByTeamName(itemA: Team, itemB: Team) {
    return itemA.name.localeCompare(itemB.name);
  }

  quantityOfActiveChats(id): number {
    return this.liveChatService.getQuantityOfConversationsFilteredByAgentId(id);
  }

  assign(agent: Agent) {
    this.activeModal.close(agent);
  }

  filterValues(searchTerm: string) {
    this.availableAgentsModel = this.availableAgentsModel.filter((team: Team) =>
      team.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
