import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialPushModeComponent } from './social-push-mode.component';
import { OrloComponentsModule } from 'orlo-components';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.socialPushMode',
          url: '/settings/social-push-mode',
          component: SocialPushModeComponent,
          resolve: SocialPushModeComponent.resolve
        }
      ]
    })
  ],
  declarations: [SocialPushModeComponent],
  entryComponents: [SocialPushModeComponent]
})
export class SocialPushModeModule {}
