<div class="detected-theme-results-container modal-popup">
  <div class="heading">
    <h2 class="title">Results</h2>
    <div>
      <button type="button"
              class="g-btn g-btn-secondary bg-white"
              (click)="modalInstance.dismiss()">Close</button>
    </div>
  </div>

  <div class="body">
    <div class="posts-area">
      <ssi-insights-posts [activeFilters]="activeFilters"
                          [sanitizeFilters]="sanitizeFilters"
                          [streamIds]="streamIds"
                          [initialLoadingTemplate]="initialLoadingTemplate"></ssi-insights-posts>
    </div>
  </div>
</div>


<ng-template #initialLoadingTemplate>
  <div class="message-info">
    <ssi-spinner [visible]="true"></ssi-spinner>
    <h5>Please wait!</h5>
    <p>
      We are loading the posts that match your theme selection
    </p>
  </div>
</ng-template>