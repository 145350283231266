import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialPushModeModalComponent } from './social-push-mode-modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SocialPushModeModalComponent],
  exports: [SocialPushModeModalComponent]
})
export class SocialPushModeModalModule {}
