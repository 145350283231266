import './topic-feedback.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { FilterPeriod } from '../../chatbot-analytics.service';

@Component({
  selector: 'ssi-topic-feedback',
  templateUrl: './topic-feedback.component.html',
  styles: []
})
export class TopicFeedbackComponent implements OnInit {
  @Input() topicFeedback: { name: string; score: number }[];
  @Input() filterPeriod: FilterPeriod;
  proTipOpen = false;

  constructor() {}

  ngOnInit(): void {}
}
