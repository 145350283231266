import outbox from './outbox';
import inbox from './inbox';
import stream from './stream';
import conversation from './conversation';
import notificationEmail from './notification-email';
import notificationExternalEmail from './notification-external-email';
import notificationMobilePush from './notification-mobile-push';

export const serviceMappings = [
  inbox,
  outbox,
  stream,
  conversation,
  notificationEmail,
  notificationExternalEmail,
  notificationMobilePush
];
