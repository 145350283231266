<div class="survey-summary-container">
  <div class="section-head">
    <h2>Survey Summary</h2>
  </div>
  <div class="facts-container">
    <ssi-fact-overview
      label="Total Surveys Sent"
      width="100%"
      [number]="aggregation?.count_sent"
      iconClass="ssi-survey-sent"
      iconColor="#89A4EA"
      circleColor="#F3F6FD"
    >
    </ssi-fact-overview>
    <ssi-fact-overview
      label="Total Scores Received"
      width="100%"
      [number]="aggregation?.count_responses"
      iconClass="ssi-survey-scores"
      iconColor="#89A4EA"
      circleColor="#F3F6FD"
    >
    </ssi-fact-overview>
    <ssi-fact-overview
      label="Total Comments Received"
      width="100%"
      [number]="aggregation?.count_have_comment"
      iconClass="ssi-survey-comments"
      iconColor="#89A4EA"
      circleColor="#F3F6FD"
    >
    </ssi-fact-overview>
  </div>
</div>