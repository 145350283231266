<div class="manage-ifttt-applet" [ngClass]="containerCssClass">

  <div class="manage-ifttt-applet-header-left">
    <button
      class="btn btn-default btn-trigger"
      uiSref="auth.iftttAppletCollectionList">
      My Lists
    </button>
  </div>

  <ui-view></ui-view>

</div>
