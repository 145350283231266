import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { CompanyLockdownComponent } from './company-lockdown.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.companylockdown',
          url: '/company-lockdown',
          component: CompanyLockdownComponent,
          resolve: CompanyLockdownComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CompanyLockdownComponent],
  entryComponents: [CompanyLockdownComponent]
})
export class CompanyLockdownModule {}
