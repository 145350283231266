<agm-map 
  (mapReady)="onMapReady($event)"
  [latitude]="center[0]"
  [longitude]="center[1]"
  [zoom]="zoom"
  [restriction]="restriction"
  [styles]="styles"
  [ngStyle]="{height: mapHeight + 'px'}">
    <agm-marker 
      *ngFor="let latLng of markers"
      [latitude]="latLng.lat" 
      [longitude]="latLng.lng"
    >
    </agm-marker>
    <agm-circle 
      *ngIf="circleOptions"
      [latitude]="circleOptions.center?.lat || center[0]"
      [longitude]="circleOptions.center?.lng || center[1]"
      fillColor="#4EA429"
      [radius]="circleOptions.radius"
    >
    </agm-circle>
</agm-map>
