<div class="follower-count-container">
  <div class="section-head">
    <h2>Follower Count</h2>
  </div>

  <div class="section-body">
    <div class="followers-by-gender">
      <div class="card"
           [ssiTooltip]="'WE_UNDERSTAND_THESE_METRICS_ARENT_INCLUSIVE_WHICH_WE_KNOW_SUCKS_THIS_METRIC_IS_A_COMBINATION_OF_LIMITED_INFORMATION_ON_THE_GENDER_OF_CUSTOMERS_AND_BEST_ASSUMPTIONS_BASED_ON_THINGS_LIKE_USER_NAME_SO_WE_RECOMMEND_APPROACHING_THIS_ONE_WITH_CAUTION' | translate"
           tooltipWindowClass="tooltip-chunky-big">
        <div class="half top">
          <div class="title">
            <span>Male engagement</span>
            <i class="ssi ssi-information"></i>
          </div>
          <div class="percentage">
            <span>{{stats.allTotals.current.male_connections | number: '1.0-1'}}%</span>
            <i class="ssi ssi-male"></i>
          </div>
        </div>
        <div class="half bottom">
          <ssi-progress-bar [max]="100"
                            [progress]="stats.allTotals.current.male_connections"
                            [thickness]="4"
                            [color]="'#89A4EA'"></ssi-progress-bar>
        </div>
      </div>
      <div class="card"
           [ssiTooltip]="'WE_UNDERSTAND_THESE_METRICS_ARENT_INCLUSIVE_WHICH_WE_KNOW_SUCKS_THIS_METRIC_IS_A_COMBINATION_OF_LIMITED_INFORMATION_ON_THE_GENDER_OF_CUSTOMERS_AND_BEST_ASSUMPTIONS_BASED_ON_THINGS_LIKE_USER_NAME_SO_WE_RECOMMEND_APPROACHING_THIS_ONE_WITH_CAUTION' | translate"
           tooltipWindowClass="tooltip-chunky-big">
        <div class="half top">
          <div class="title">
            <span>Female engagement</span>
            <i class="ssi ssi-information"></i>
          </div>
          <div class="percentage">
            <span>{{stats.allTotals.current.female_connections | number: '1.0-1'}}%</span>
            <i class="ssi ssi-female"></i>
          </div>
        </div>
        <div class="half bottom">
          <ssi-progress-bar [max]="100"
                            [progress]="stats.allTotals.current.female_connections"
                            [thickness]="4"
                            [color]="'#D41D68'"
                            [reversed]="true"></ssi-progress-bar>
        </div>
      </div>
      <div class="card">
        <div class="half top">
          <div class="title">
            <span>Total followers</span>
          </div>
          <div class="percentage">
            <span>
              <ssi-big-number [number]="stats.allTotals.current.connections"
                              [denotation]="{million: 'm', thousand: 'k'}"></ssi-big-number>
            </span>
            <i class="ssi ssi-audience-growth"></i>
          </div>
        </div>
        <div class="half bottom">
          <ssi-stat-change *ngIf="stats.allTotals.previous"
                           [boxed]="true"
                           [current]="stats.allTotals.current.connections"
                           [previous]="stats.allTotals.previous.connections"></ssi-stat-change>
        </div>
      </div>
    </div>
    <div class="followers-by-network">
      <div class="card"
           *ngFor="let network of totalsByAccountTypeIterable">
        <div class="half top">
          <div class="network-info">
            <ssi-social-network-icon class="network-icon"
                                     [accountTypeId]="network.account.account_type_id"
                                     [size]="40"></ssi-social-network-icon>
            <div>
              <div class="network-name"><span>{{networksConst[network.account.account_type_name]?.label}}</span></div>
              <div class="accounts-count"><span>{{network.totalAccounts}} {{network.totalAccounts === 1 ? 'Account' : 'Accounts'}}</span></div>
            </div>
          </div>
        </div>
        <div class="half bottom">
          <ssi-stat-change *ngIf="network.previous"
                           [boxed]="true"
                           [current]="network.current.connections"
                           [previous]="network.previous.connections"></ssi-stat-change>
          <div class="followers-current-count">
            <ssi-big-number class="count"
                            [number]="network.current.connections"
                            [denotation]="{million: 'm', thousand: 'k'}"></ssi-big-number>
            <div class="break-line"></div>
            <span>Followers</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>