import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementLifecycleDirective } from './element-lifecycle.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ElementLifecycleDirective],
  exports: [ElementLifecycleDirective]
})
export class ElementLifecycleModule {}
