<ul
  #activities
>
  <div
    #inlineNotesContainer
    [style.display]="! areNotesVisible ? 'none' : 'flex'"
    class="inline-notes-container"  
  >
    <ssi-live-chat-inline-notes
      *ngIf="!! selectedMessage"
      (isNoteFormFocusedChange)="onIsNoteFormFocusedChange($event)"
      (openNotesScreen)="onOpenNotesScreen()"
      [isExpanded]="areNotesExpanded"
      [isNoteFormFocused]="isNoteFormFocused"
      [message]="selectedMessage"
    ></ssi-live-chat-inline-notes>
  </div>
  
  <li
    *ngFor="let message of messages; let index = index; last as isLast"
    (click)="selectMessage(message)"
    [attr.message-id]="message.id"
    [ngClass]="{
      last: isLast
    }"
    class="message {{ message.authorType }}"
    data-pendo="liveChat_focusOnMessage"
  >
    <ssi-live-chat-message
      (openNotes)="onOpenNotes($event)"
      (openTags)="onOpenTags()"
      (translationDetectedLanguage)="onTranslationDetectedLangugage($event)"
      [translationMode]="translationMode"
      [isSelected]="isSelectedMessage(message)"
      [message]="message"
      [ngClass]="{
        selected: isSelectedMessage(message)
      }"
      class="{{ message.authorType }}"
    ></ssi-live-chat-message>
  </li>
</ul>
