import { Colleague } from '.';
import { Store } from '../store';

export class ColleaguesStore extends Store<Colleague> {
  constructor() {
    super(Colleague);
  }

  filterByTeam(teamId: string, includeInactive = false): Colleague[] {
    const colleagues: Colleague[] = includeInactive
      ? this.value
      : this.filter({ is_active: true });

    return colleagues.filter((c) => {
      return (c.teams || []).find((id) => String(id) === String(teamId));
    });
  }
}
