import './enlarge-image-modal.component.scss';
import {
  Component,
  AfterViewInit,
  HostListener,
  ViewChild,
  OnInit,
  ElementRef,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Optional } from '@angular/core';

@Component({
  selector: 'ssi-enlarge-image-modal',
  templateUrl: './enlarge-image-modal.component.html',
  styles: [
    `
      .scroll-container {
        overflow: auto;
        cursor: pointer;
      }
      .scroll-container img {
        width: 100%;
        transform-origin: 0 0;
      }
    `
  ]
})
export class EnlargeImageModalComponent implements OnInit, AfterViewInit {
  @Input() imageSrc: string | string[];
  @Input() wrapperClass: string;
  @Input() close;
  @Input() altText: string | string[];

  resolve;

  mouseStart: {
    x: number;
    y: number;
  };

  scroll: {
    left: number;
    top: number;
  };

  scale = 1.0;

  multiImage = false;
  currentImage = 0;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(@Optional() public activeModal?: NgbActiveModal) {}

  ngOnInit() {
    this.multiImage = Array.isArray(this.imageSrc) && this.imageSrc.length > 1;
  }

  ngAfterViewInit() {
    this.resetScroll();
  }

  resetScroll() {
    this.setScroll(0, 0);
  }

  zoom(amount) {
    this.scale += amount;
    this.resetScroll();
  }

  onDrag($event) {
    $event.preventDefault(); // stop the default image dragging effect
    if (this.mouseStart) {
      this.setScroll(
        this.mouseStart.y - $event.clientY,
        this.mouseStart.x - $event.clientX
      );
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event) {
    this.mouseStart = {
      x: event.clientX + this.scroll.left,
      y: event.clientY + this.scroll.top
    };
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.mouseStart = null;
  }

  private setScroll(top, left) {
    this.scroll = {
      top,
      left
    };
    this.scrollContainer.nativeElement.scrollTop = top;
    this.scrollContainer.nativeElement.scrollLeft = left;
  }

  handleGalleryTransition(transition: 'next' | 'previous') {
    if (transition === 'previous') {
      this.currentImage = this.currentImage - 1;
    } else if (transition === 'next') {
      this.currentImage = this.currentImage + 1;
    }
  }
}
