import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AddNoteComponent } from './add-note.component';
import { MentionModule } from 'angular-mentions';
import { SanitizedHtmlModule } from '../../../common/pipes/sanitized/sanitized.module';

@NgModule({
  imports: [CommonModule, FormsModule, MentionModule, SanitizedHtmlModule],
  declarations: [AddNoteComponent],
  exports: [AddNoteComponent]
})
export class AddNoteModule {}
