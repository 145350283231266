<div class="drilldown-modal-content-container modal-popup">
  <div class="heading">
    <h2 class="title">View Post Results</h2>
    <div class="buttons">
      <button
        *ngIf="displayApplyFilters"
        class="g-btn"
        (click)="applyFilter()"
      >
        Apply Filter
      </button>
      <button class="g-btn g-btn-secondary bg-white" 
              (click)="modalInstance.dismiss()">
        Close
      </button>
    </div>
  </div>

  <div class="tabs" *ngIf="enableThemeClusters">
    <div
      class="tab-name"
      [class.active]="activeTab === 'results'"
      (click)="changeTab('results')"
    >Results</div>
    <div
      class="tab-name"
      [class.active]="activeTab === 'clusters'"
      (click)="changeTab('clusters')"
    >Theme Clusters</div>
  </div>

  <div class="drilldown-content">
    <div
      *ngIf="activeTab === 'results'"
      class="posts-area"
    >
      <ssi-insights-posts
        [clusterId]="clusterId"
        [jobTicket]="jobTicket"
        [activeFilters]="activeFilters"
        [sanitizeFilters]="false"
        [streamIds]="streamIds"
        [initialLoadingTemplate]="initialLoadingTemplate"
      ></ssi-insights-posts>
    </div>

    <div
      *ngIf="activeTab === 'clusters'"
      class="interaction-area"
    >
      <div class="drilldown-content">
        <div class="interaction-area">
          <!-- <div class="sentiments-area">
          <div
            class="sentiment-block"
            *ngFor="let s of sentimentsConstIterable"
          >
            <span
              [ngStyle]="{'background-color': s.color2}"
              class="sentiment-color"
            ></span><span
              class="sentiment-label"
              translate="{{s.label}}"
            ></span>
          </div>
        </div> -->
          <div
            class="themes-container"
            [class.centered]="loadingClusters"
          >
            <div
              class="themes-loading"
              *ngIf="loadingClusters; else themesContent"
            >
              <div class="message-info">
                <ng-container *ngIf="querySubmitted && !clusteringJobFailed">
                  <ssi-spinner [visible]="loadingClusters"></ssi-spinner>
                  <h5>Hang tight</h5>
                  <p>
                    I'm using AI clustering technology to search for themes across loads of results, phew!
                  </p>
                </ng-container>
                <ng-container *ngIf="querySubmitted && clusteringJobFailed">
                  <h5>Job failed!</h5>
                  <p>
                    Oops, we couldn't find the data for your query.
                  </p>
                </ng-container>
                <ng-container *ngIf="!querySubmitted">
                  <h5>Query failed!</h5>
                  <p>
                    Please try new search or clicking on a different widget
                  </p>
                </ng-container>

              </div>
            </div>
            <ng-template #themesContent>
              <div class="themes-content">
                <ul>
                  <li
                    *ngFor="let theme of clusterData; let index = index"
                    (click)="selectClusterContent(theme.content)"
                  >
                    <span class="theme-index">{{index + 1}}</span> <span class="theme-name">"{{theme.openai_theme ||
                      'Unknown'}}"</span>
                    <ssi-big-number [number]="theme.size"></ssi-big-number>
                    <span class="theme-results">results</span>
                  </li>
                </ul>
              </div>
            </ng-template>

          </div>
        </div>

        <div
          class="posts-area"
          [class.dotted]="!clusterFilters"
        >
          <ssi-insights-posts
            [clusterId]="clusterId"
            [jobTicket]="jobTicket"
            [activeFilters]="clusterFilters"
            [sanitizeFilters]="false"
            [streamIds]="streamIds"
            [beforeLoadTemplate]="beforeLoadTemplate"
            [initialLoadingTemplate]="initialLoadingTemplate"
          ></ssi-insights-posts>
        </div>
      </div>
    </div>

  </div>
</div>


<ng-template #beforeLoadTemplate>
  <div class="message-info">
    <i class="ssi ssi-select-microcopy"></i>
    <h5>Select a Cluster!</h5>
    <p>
      Drill down further and view stream results from your Cluster
    </p>
  </div>
</ng-template>

<ng-template #initialLoadingTemplate>
  <div class="message-info">
    <ssi-spinner [visible]="true"></ssi-spinner>
    <h5>Please wait!</h5>
    <p>
      We are loading the posts that match your theme selection
    </p>
  </div>
</ng-template>