<ul class="advertising-nav list-unstyled">
  <li *ngIf="currentAdvertisingPage === 'index'" class="advertising-nav-link">
      <a uiSref="^.index">
        <i class="ssi ssi-view-graph"></i>
        <span>My Ads</span>
        <p class="advertising-nav-meta">Click here to see your ads and how they are performing</p>
      </a>
    <!-- <span (click)="changeGraphState.emit()">
      <i class="ssi ssi-view-graph"></i>
      <span translate="VIEW_LIVE_AD_GRAPH"></span>
      <p class="advertising-nav-meta">Click here to see how your paid posts compare to your organic ones. Support your strategic planning to deliver tactical value to your campaigns.</p>
    </span> -->
  </li>
  <!-- <li *ngIf="showSuggestions && currentAdvertisingPage === 'index'" class="advertising-nav-link advertising-nav-link-highlight">
    <a uiSref="^.index">
      <i class="ssi ssi-wand"></i>
      <span translate="GIVE_ME_AD_SUGGESTIONS"></span>
    </a>
  </li> -->
  <li *ngIf="currentAdvertisingPage !== 'index'" class="advertising-nav-link">
    <a uiSref="^.index">
      <i class="ssi ssi-view-graph"></i>
      <!-- <span translate="BACK_TO_MY_AD_STATS"></span> -->
      <span>My Ads</span>
      <p class="advertising-nav-meta">Click here to see your ads and how they are performing</p>
    </a>
  </li>
  <li class="advertising-nav-link" [ngClass]="{active: currentAdvertisingPage === 'adObjectives'}">
    <a uiSref="^.adObjectives">
      <i class="ssi ssi-ad-objectives"></i>
      <span>My Top Organic Posts</span>
      <p class="advertising-nav-meta">Click here to see the statistically best posts to boost. It gets better, you can also catagories boosting by reach, engagement and more...</p>
    </a>
  </li>
  <li class="advertising-nav-link" [ngClass]="{active: currentAdvertisingPage === 'presetList'}">
      <a uiSref="^.presetList">
        <i class="ssi ssi-ad-presets"></i>
        <span>My Saved Audiences</span>
        <p class="advertising-nav-meta">Click here to speed up your workflow by creating a range of target audiences or keep track of your collection you have already made.</p>
      </a>
  </li>
</ul>