import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef
} from '@angular/core';
import { userAgent } from '../../constants';

const browser = userAgent.getBrowser();
const isIE = browser.name === 'IE' && +browser.major <= 11;

@Directive({
  selector: '[ssiFocusOn]'
})
export class FocusOnDirective implements OnChanges {
  @Input('ssiFocusOn') focusOn: boolean;

  constructor(private elm: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.focusOn && this.focusOn === true && !isIE) {
      this.elm.nativeElement.focus();
    }
  }
}
