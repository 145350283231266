import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { BigNumberModule } from '../../../../../common/pipes/big-number/big-number.module';
import { BigNumberModule as BigNumberComponentModule } from '../../../../../common/components/big-number/big-number.module';

import { StatChangeModule } from '../stat-change/stat-change.module';

import { SentimentCardComponent } from './sentiment-card.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    BigNumberModule,
    BigNumberComponentModule,
    StatChangeModule
  ],
  exports: [SentimentCardComponent],
  declarations: [SentimentCardComponent]
})
export class SentimentCardModule {}
