import { Account } from '@ui-resources-angular';
import { Injector } from '@angular/core';

import { MonitoringStream } from '../../../../../common/services/api';
import {
  Applet as APIApplet,
  AppletActionParameterType,
  AppletTriggerType,
  AppletTriggerParameterType,
  AppletActionType,
  LiteralType,
  TemplateType,
  Literal,
  Template
} from './api-applet-interfaces';
import {
  TriggerConfig,
  TriggerParamConfig,
  ActionParamConfig,
  ActionConfig,
  ParamKeyValueMap
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import { AppletCollection } from './applet-collection';

export interface AppletTriggerParam {
  name: string;
  value: any;
  param: TriggerParamConfig;
}

interface AppletTrigger {
  name: string;
  params: AppletTriggerParam[];
  trigger: TriggerConfig;
  label: string;
}

export interface AppletActionParam {
  name: string;
  value: any;
  type?: 'literal' | 'template' | 'ingredient';
  param: ActionParamConfig;
}

export interface AppletAction {
  name: string;
  params: AppletActionParam[];
  action: ActionConfig;
  apiAction?: any;
  label: string;
}

function findParamByName<
  ParamType extends TriggerParamConfig | ActionParamConfig
>(params: ParamType[], name: string): ParamType {
  try {
    if (!(!!params && Array.isArray(params))) {
      throw new Error(`Value for 'applet params' not in expected format.`);
    }

    return params.find((param) => param.name === name);
  } catch (error) {
    console.error(error);

    return null;
  }
}

export class Applet {
  name: string;
  enabled: boolean;
  accounts: Account[] = [];
  streams: MonitoringStream[] = [];
  triggers: AppletTrigger[] = [];
  actions: AppletAction[] = [];

  labels: {
    triggers?: any[];
    actions?: string[];
  } = {};

  private accountTrigger: TriggerConfig;
  private streamTrigger: TriggerConfig;

  constructor(
    private collection: AppletCollection,
    apiApplet: APIApplet,
    triggers: TriggerConfig[],
    actions: ActionConfig[],
    private injector: Injector
  ) {
    if (!Array.isArray(actions)) {
      throw new Error(`Value for 'applet actions' not in expected format.`);
    }

    if (!Array.isArray(triggers)) {
      throw new Error(`Value for 'applet triggers' not in expected format.`);
    }

    triggers = triggers.filter((trigger) => trigger);
    this.name = apiApplet.name;
    this.enabled = apiApplet.enabled;
    this.accountTrigger = triggers.find((trigger) => trigger.isAccountTrigger);
    this.streamTrigger = triggers.find((trigger) => trigger.isStreamTrigger);

    function deserialiseParams(apiParams, paramsConfig): ParamKeyValueMap {
      const paramKeyValueMap: ParamKeyValueMap = {};

      apiParams.forEach((apiParam) => {
        const name = apiParam.name;
        const value = apiParam.value.value;

        const paramConfig = findParamByName(paramsConfig, name);

        paramKeyValueMap[name] = paramConfig.deserialise
          ? paramConfig.deserialise(value, paramKeyValueMap)
          : value;
      });

      return paramKeyValueMap;
    }

    apiApplet.triggers.forEach((apiTrigger) => {
      const trigger = triggers.find(
        (iTrigger) => iTrigger.api.name === apiTrigger.name
      );
      const params = deserialiseParams(apiTrigger.params, trigger.params);
      if (trigger.isAccountTrigger) {
        this.accounts = params.accounts;
      } else if (trigger.isStreamTrigger) {
        this.streams = params.streams;
      } else {
        this.addTrigger(trigger, params);
      }
    });

    const deprecatedActions: Array<{
      action: ActionConfig;
      apiAction;
      params;
    }> = [];

    apiApplet.actions.forEach((apiAction) => {
      const action = actions.find(
        (iAction) => iAction.api.name === apiAction.name
      );
      const params = deserialiseParams(apiAction.params, action.params);
      if (action.api.action.deprecated) {
        deprecatedActions.push({ action, params, apiAction });
      } else {
        this.addAction(action, params, apiAction);
      }
    });

    deprecatedActions.forEach(({ action, params, apiAction }) => {
      const replacement = actions.find(
        (iAction) =>
          iAction.api.name === action.api.action.deprecatedReplacementAction
      );
      const migratedValue = action.migrateParams(params);
      if (
        !this.actions.find((iAction) => iAction.name === replacement.api.name)
      ) {
        this.addAction(replacement, migratedValue, apiAction);
      }
    });
  }

  addTrigger(trigger: TriggerConfig, paramValues: ParamKeyValueMap) {
    const params = trigger.params.map((param) => {
      return {
        name: param.name,
        value: paramValues[param.name],
        param
      };
    });

    this.triggers.push({
      name: trigger.api.name,
      trigger,
      params,
      label: trigger.getLabel(params, this.injector, this.collection.service)
    });
    this.updateTriggersLabel();
  }

  removeTrigger(trigger: TriggerConfig) {
    this.triggers = this.triggers.filter(
      (iTrigger) => iTrigger.trigger !== trigger
    );
    this.updateTriggersLabel();
  }

  addAction(action: ActionConfig, paramValues: ParamKeyValueMap, apiAction?) {
    const regexUnescape = new RegExp('&amp;', 'g');
    const params = action.params.map((param) => {
      return {
        name: param.name,
        value:
          typeof paramValues[param.name] === 'string'
            ? paramValues[param.name].replace(regexUnescape, '&')
            : paramValues[param.name],
        param
      };
    });

    this.actions.push({
      name: action.api.name,
      action,
      apiAction,
      params,
      label: action.getLabel(params, this.injector, this.collection.service)
    });
    this.updateActionsLabel();
  }

  removeAction(action: ActionConfig) {
    this.actions = this.actions.filter((iAction) => iAction.action !== action);
    this.updateActionsLabel();
  }

  serialise(): APIApplet {
    let triggers: AppletTrigger[] = [];
    if (this.accountTrigger) {
      triggers.push({
        name: this.accountTrigger.api.name,
        params: [
          {
            name: this.accountTrigger.params[0].name,
            value: this.accounts,
            param: this.accountTrigger.params[0]
          }
        ],
        trigger: this.accountTrigger,
        label: ''
      });
    }

    if (this.streamTrigger) {
      triggers.push({
        name: this.streamTrigger.api.name,
        params: [
          {
            name: this.streamTrigger.params[0].name,
            value: this.streams,
            param: this.streamTrigger.params[0]
          }
        ],
        trigger: this.streamTrigger,
        label: ''
      });
    }

    triggers = [...triggers, ...this.triggers];

    function getParamMap(triggerOrAction): ParamKeyValueMap {
      const paramValueMap: ParamKeyValueMap = {};
      triggerOrAction.params.forEach((param) => {
        paramValueMap[param.name] = param.value;
      });
      return paramValueMap;
    }

    return {
      type: '@Applet',
      name: this.name,
      enabled: this.enabled,
      triggers: triggers.map((trigger) => {
        const paramValueMap = getParamMap(trigger);
        return {
          type: '@AppletTrigger' as AppletTriggerType,
          name: trigger.name,
          params: trigger.params.map((param) => {
            const paramConfig: TriggerParamConfig = findParamByName<TriggerParamConfig>(
              trigger.trigger.params,
              param.name
            );
            const value = paramConfig.serialise
              ? paramConfig.serialise(param.value, paramValueMap)
              : param.value;
            return {
              type: '@AppletTriggerParameter' as AppletTriggerParameterType,
              name: param.name,
              value: {
                type: '@Literal' as LiteralType,
                value
              }
            };
          })
        };
      }),
      actions: this.actions.map((action) => {
        if (Applet.actionInvalid(action) && !!action.apiAction) {
          // In order to update any of the triggers all of them are sent to the backend as
          // a collection (POST https://app.socialsignin.co.uk/ifttt/iftttApplet?service_name=activity.imported.incoming&version=1503),
          // the backend validates each applet and returns 500 if ANY of the applets are invalid (eg its actions broken - undefined params) - making it
          // impossible to update a single applet separately (the same problem occurs when creating a new trigger - the backend will
          // fail if any of the existing triggers from the list is invalid).
          // Therefore replace invalid actions with their api/backend state (i.e. without serializing/deserializing), in order
          // to make the update possible. See also: https://orlo.slack.com/archives/CKDQ831T2/p1628853383050500
          return action.apiAction;
        } else {
          const paramValueMap = getParamMap(action);
          return {
            type: '@AppletAction' as AppletActionType,
            name: action.name,
            service: action.action.service.name,
            params: action.params
              .map((param) => {
                const paramConfig: ActionParamConfig = findParamByName<ActionParamConfig>(
                  action.action.params,
                  param.name
                );
                let value = paramConfig.serialise
                  ? paramConfig.serialise(param.value, paramValueMap)
                  : param.value;

                const regexEscape = new RegExp('&', 'g');
                value =
                  value && typeof value === 'string'
                    ? value.replace(regexEscape, '&amp;')
                    : value;

                if (Array.isArray(value)) {
                  value = value.filter((x) => x !== undefined);
                }

                let paramValue: Literal | Template;

                switch (paramConfig.variableType) {
                  case 'Template':
                    paramValue = {
                      type: '@Template' as TemplateType,
                      value
                    } as Template;
                    break;

                  default:
                    paramValue = {
                      type: '@Literal' as LiteralType,
                      value
                    } as Literal;
                }

                return {
                  type: '@AppletActionParameter' as AppletActionParameterType,
                  name: param.name,
                  value: paramValue
                };
              })
              .filter((param) => typeof param.value.value !== 'undefined')
          };
        }
      })
    };
  }

  private updateTriggersLabel() {
    this.labels.triggers = this.triggers.map((trigger) =>
      trigger.trigger.getLabel(
        trigger.params,
        this.injector,
        this.collection.service
      )
    );
  }

  private updateActionsLabel() {
    this.labels.actions = this.actions.map((action) =>
      action.action.getLabel(
        action.params,
        this.injector,
        this.collection.service
      )
    );
  }

  static actionInvalid = (action: AppletAction): boolean => {
    const actionParamInvalid = (param): boolean => {
      if (param.param.required) {
        if (!param.value) {
          return true;
        }

        if (
          Array.isArray(param.value) &&
          (!param.value.length ||
            param.value.some((item) => item === undefined))
        ) {
          return true;
        }
      }

      return false;
    };

    return action.params.some((param) => actionParamInvalid(param));
  };
}
