<div class="publisher-secondary-nav-web">
  <button class="g-btn g-btn-lg border-radius-100 cancel-btn"
          translate="CANCEL_POST"
          (click)="publisherActive.next({isActive: false})">
  </button>

  <ng-content></ng-content>
</div>

<div class="publisher-secondary-nav-mobile">
  <button class="g-btn cancel-btn"
          translate="CANCEL_POST"
          (click)="publisherActive.next({isActive: false})">
  </button>

  <button class="g-btn edit-post-btn"
          (click)="back.emit()">
    <i class="ssi ssi-line-arrow"></i>
    <span>Edit post</span>
  </button>
</div>