<div class="outbox-menu"
     *ngIf="post.social_id && !post.account.socialNetwork.inboundInitiated">
  <a class="btn btn-default btn-xs btn-rounded outbox-menu-trigger"
     href="javascript:;"
     (click)="$event.stopPropagation()">
    <i class="ssi ssi-outbox-menu text-primary"></i>
  </a>
  <div class="outbox-menu-items"
       (click)="$event.stopPropagation()">
    <a class="text-danger"
       href="javascript:;"
       (click)="deletePost()"
       ssiTooltip="{{ 'DELETE' | translate }}"
       [hidden]="post.deleted_at"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-delete"></i>
    </a>
    <a ssiTooltip="{{ 'BOOST_POST' | translate }}"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'"
       *ngIf="post.account.promoted_by.length > 0 && !advert && ['3','4','8','9'].includes(post.account.account_type_id)"
       (click)="boostPost()">
      <i class="ssi ssi-ads"></i>
    </a>
    <a ssiTooltip="{{post.campaign_id ? 'Change Campaign' : 'Add Campaign'}}"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'"
       (click)="showCampaignModal()">
      <i class="ssi ssi-campaign"></i>
    </a>
    <a href="javascript:;"
       [ssiTriggerPublish]="{create: {copy: post}}"
       ssiTooltip="{{ 'COPY' | translate }}"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-copy-paste"></i>
    </a>
    <a *ngIf="post.account.socialNetwork.outbox.threadReplies"
       href="javascript:;"
       [ssiTriggerPublish]="{create: {accounts: [post.account], replyToSocialId: post.social_id}}"
       ssiTooltip="{{ 'ADD_TO_THREAD' | translate }}"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-plus"></i>
    </a>
    <a [href]="post.externalLink"
       target="_blank"
       *ngIf="post.externalLink"
       [ssiTooltip]="'VIEW_POST_NATIVELY' | translate"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-view-natively"></i>
    </a>
    <a [href]="advert?.external_link"
       target="_blank"
       *ngIf="advert?.external_link"
       [ssiTooltip]="'VIEW_ADVERT_NATIVELY' | translate"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="fa fa-external-link"></i>
    </a>
    <a uiSref="auth.inbox"
       [uiParams]="{activity: post.activity_id }"
       *ngIf="post.activity_id"
       ssiTooltip="View in inbox"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-toolbar-inbox"></i>
    </a>
  </div>
</div>

<div class="outbox-menu"
     *ngIf="!post.social_id && !post.account.socialNetwork.inboundInitiated">
  <a class="btn btn-default btn-xs btn-rounded outbox-menu-trigger"
     href="javascript:;"
     (click)="$event.stopPropagation()">
    <i class="ssi ssi-outbox-menu text-primary"></i>
  </a>
  <div class="outbox-menu-items"
       (click)="$event.stopPropagation()">
    <a class="text-danger"
       href="javascript:;"
       (click)="deletePost()"
       ssiTooltip="{{ 'DELETE' | translate }}"
       [hidden]="post.deleted_at"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-delete"></i>
    </a>
    <a ssiTooltip="{{ 'BOOST_POST' | translate }}"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'"
       *ngIf="post.account.promoted_by.length > 0 && !advert && ['3','4','8','9'].includes(post.account.account_type_id)"
       (click)="boostPost()">
      <i class="ssi ssi-ads"></i>
    </a>
    <a ssiTooltip="Edit Post"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'"
       [ssiTriggerPublish]="{edit: {post: post}}"
       [hidden]="post.deleted_at">
      <i class="ssi ssi-correctedit"></i>
    </a>
    <a ssiTooltip="{{post.campaign_id ? 'Change Campaign' : 'Add Campaign'}}"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'"
       (click)="showCampaignModal()">
      <i class="ssi ssi-campaign"></i>
    </a>
    <a href="javascript:;"
       [ssiTriggerPublish]="{create: {copy: post}}"
       ssiTooltip="{{ 'COPY' | translate }}"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-copy-paste"></i>
    </a>
    <a uiSref="auth.inbox"
       [uiParams]="{activity: post.activity_id }"
       *ngIf="post.activity_id"
       ssiTooltip="View in inbox"
       [tooltipPositionFixed]="true"
       [tooltipAppendToBody]="true"
       [tooltipWindowClass]="'tooltip-chunky'">
      <i class="ssi ssi-toolbar-inbox"></i>
    </a>
  </div>
</div>