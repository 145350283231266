import { Component, OnInit, Inject } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { API, Auth } from '@ui-resources-angular';

import { SocketsService } from '../../common/services/sockets/sockets.service';
import { DeviceService } from '../../common/services/device/device.service';

@Component({
  selector: 'ssi-saml',
  template: ``
})
export class SamlComponent implements OnInit {
  constructor(
    private state: StateService,
    private api: API,
    private socket: SocketsService,
    private device: DeviceService,
    @Inject('$auth') protected $auth: Auth
  ) {}

  ngOnInit() {
    this.api
      .post('user/samlToken', { jwt: this.state.params.jwt })
      .then(({ data: { permanent_token: token } }) => {
        this.$auth.setToken(token);
        this.socket.instantiate();
        return this.state.go(
          this.device.isMobile() ? 'auth.inbox' : 'auth.dashboard'
        );
      })
      .catch(() => {
        this.state.go('entry.landing.login');
      });
  }
}
