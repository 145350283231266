<div class="loading" *ngIf="loadingTrackers.initial.active"></div>

<div
  #scroller
  *ngIf="!loadingTrackers.initial.active"
  class="scroll-container"
  id="thread-scroll-container"
  [ngClass]="{
    'scroll-container-reply': replyBoxSticky,
    'scroll-container-reply-compressed': replyBoxStatus === ReplyBoxStatus.Compressed,
    'scroll-container-reply-expanded': replyBoxStatus === ReplyBoxStatus.Expanded,
    'scroll-container-reply-hidden': replyBoxStatus === ReplyBoxStatus.Hidden,
    'scroll-container-reply-none': replyBoxStatus === ReplyBoxStatus.None
  }"
>
  <div
    *ngFor="let parent of activityThread?.thread.parents | unique: 'id'; trackBy: trackById; let index = index"
    [ngClass]="'inbox-activity-thread-indent-' + index"
  >
    <ssi-activity
      [activity]="parent"
      [isAddNoteButtonVisible]="true"
      [isNestedThread]="isNestedThread"
      [isInbox]="true"
      [isParent]="true"
      [isSelfPenned]="isOutboundMessage(parent)"
      [isActionable]="parent.social_actions.reply_type === 'comment' || parent.inbox.requires_action"
      [ngClass]="{
        'self-penned': isOutboundMessage(parent),
        'activity-mouse-pointer': parent.social_actions.reply_type === 'comment' || parent.inbox.requires_action,
        'hide-activity-top': index === 0 && activityThread?.thread.current.social_actions.reply_type === 'comment' && !activityThread?.thread.current.interaction.is_private
      }"
      (onMessageboxClick)="changeActivity(parent)"
      [conversation]="conversation"
      [replyBoxSticky]="replyBoxSticky"
      (scrollToBottomOfThread)="scrollToBottomOfThread()"
      [inboxMode]="inboxMode"
      [canSplitConversation]="parent.interaction.is_private && conversation"
      (openSplitConversationPanel)="openSplitConversationPanel(conversation, parent)"
      (closeSplitConversationPanel)="closeSplitConversationPanel()"
      (splitConversation)="confirmConversationSplit()"
      [isPushMode]="isPushMode"
      (onReplyBoxStatusChanged)="onReplyBoxStatusChange($event)"
      (onResolveConversation)="onResolveConversation.emit($event)"
      (onHoldConversationStatusChange)="onHoldConversationStatusChange.emit($event)"
    ></ssi-activity>
  </div>

  <div
    [class.inbox-activity-thread-children]="activityThread?.thread.parents.length > 0"
    [ngClass]="'inbox-activity-thread-indent-' + activityThread?.thread.parents.length"
  >
    <div
      class="inbox-activity-thread-history"
      [ngClass]="{ expanded: !activityThread?.thread.siblings.older.missing }"
    >
      <div
        *ngIf="activityThread?.thread.siblings.older.missing > 0"
        class="inbox-activity-thread-expander"
        [ngClass]="{
          info: activityThread?.thread.current.conversation.depth == 0 && isNestedThread,
          warning: activityThread?.thread.current.conversation.depth == 1 && isNestedThread,
          royal: activityThread?.thread.current.conversation.depth == 2 && isNestedThread
        }"
      >
        <span *ngIf="!loadingTrackers.siblings.older.active">
          <a (click)="loadMoreOlderSiblings()">
            <span
              translate="VIEW__COUNT__MORE"
              [translateParams]="{count: activityThread?.thread.siblings.older.missing}"
            ></span>
          </a>
        </span>

        <span *ngIf="loadingTrackers.siblings.older.active">
          <i class="fa fa-spin fa-spinner"></i>
        </span>
      </div>

      <div
        *ngFor="let sibling of activityThread?.thread.siblings.older.activities | unique: 'id'; trackBy: trackById"
        class="inbox-activity-thread-container"
      >
        <ssi-activity
          [activity]="sibling"
          [isAddNoteButtonVisible]="true"
          [isNestedThread]="isNestedThread"
          [isInbox]="true"
          [isChild]="true"
          (onMessageboxClick)="changeActivity(sibling)"
          [isSelfPenned]="isOutboundMessage(sibling)"
          [isActionable]="sibling.social_actions.reply_type === 'comment' || sibling.inbox.requires_action"
          [ngClass]="{
            'self-penned': isOutboundMessage(sibling),
            'activity-mouse-pointer': sibling.social_actions.reply_type === 'comment' || sibling.inbox.requires_action
          }"
          [conversation]="conversation"
          [replyBoxSticky]="replyBoxSticky"
          (scrollToBottomOfThread)="scrollToBottomOfThread()"
          [inboxMode]="inboxMode"
          [canSplitConversation]="sibling.interaction.is_private && conversation"
          (openSplitConversationPanel)="openSplitConversationPanel(conversation, sibling)"
          (closeSplitConversationPanel)="closeSplitConversationPanel()"
          (splitConversation)="confirmConversationSplit()"
          [isPushMode]="isPushMode"
          (onReplyBoxStatusChanged)="onReplyBoxStatusChange($event)"
          (onResolveConversation)="onResolveConversation.emit($event)"
          (onHoldConversationStatusChange)="onHoldConversationStatusChange.emit($event)"
        ></ssi-activity>
      </div>
    </div>

    <div class="inbox-activity-reply-container">
      <ssi-activity
        class="active"
        [activity]="activityThread?.thread.current"
        [activityThread]="activityThread"
        [isAddNoteButtonVisible]="true"
        [isInbox]="true"
        [isNestedThread]="isNestedThread"
        [isActive]="true"
        [showReply]="!activity.interaction.deleted_at"
        [hideArrowShortcuts]="hideArrowShortcuts"
        (onReplyTextChanged)="onReplyTextChanged.emit({isDefault: $event.isDefault, activity: $event.activity})"
        (onShowTags)="onShowTags.emit()"
        (onShowFilters)="onShowFilters.emit()"
        (onReplyCreated)="replyCreated({activity: $event.activity, replyActivity: $event.replyActivity})"
        [parentActivity]="activityThread?.thread.parents[activityThread?.thread.parents.length - 1]"
        (onChangeActivity)="changeActivity(activity)"
        [isSelfPenned]="isOutboundMessage(activityThread?.thread.current)"
        [ngClass]="{
          'activity-nested-thread': activityThread?.thread.parents.length > 0,
          'self-penned': isOutboundMessage(activityThread?.thread.current)
        }"
        [conversation]="conversation"
        [replyBoxSticky]="replyBoxSticky"
        #activeActivity
        (scrollToBottomOfThread)="scrollToBottomOfThread()"
        [inboxMode]="inboxMode"
        [canSplitConversation]="activityThread?.thread.current.interaction.is_private && conversation"
        (openSplitConversationPanel)="openSplitConversationPanel(conversation, activityThread?.thread.current)"
        (closeSplitConversationPanel)="closeSplitConversationPanel()"
        (splitConversation)="confirmConversationSplit()"
        [isPushMode]="isPushMode"
        (onReplyBoxStatusChanged)="onReplyBoxStatusChange($event)"
        (onResolveConversation)="onResolveConversation.emit($event)"
        (onHoldConversationStatusChange)="onHoldConversationStatusChange.emit($event)"
      ></ssi-activity>
    </div>

    <div class="inbox-activity-thread-children">
      <div
        *ngIf="activityThread?.thread.children.missing > 0"
        class="inbox-activity-thread-expander"
        [ngClass]="{
          warning: activityThread?.thread.current.conversation.depth == 0 && isNestedThread,
          royal: activityThread?.thread.current.conversation.depth == 1 && isNestedThread
        }"
      >
        <span *ngIf="!loadingTrackers.children.active">
          <a (click)="loadMoreChildren()">
            <span
              translate="VIEW__COUNT__MORE"
              [translateParams]="{count: activityThread?.thread.children.missing}"
            ></span>
          </a>
        </span>

        <span *ngIf="loadingTrackers.children.active">
          <i class="fa fa-spin fa-spinner"></i>
        </span>
      </div>

      <div *ngIf="activityThread?.thread.siblings.newer.missing > 0">
        <div
          class="inbox-activity-thread-expander"
          [ngClass]="{
            info: activityThread?.thread.current.conversation.depth == 0 && isNestedThread,
            warning: activityThread?.thread.current.conversation.depth == 1 && isNestedThread,
            royal: activityThread?.thread.current.conversation.depth == 2 && isNestedThread
          }"
        >
          <span *ngIf="!loadingTrackers.siblings.newer.active">
            <a (click)="loadMoreNewerSiblings()">
              <span
                translate="VIEW__COUNT__MORE"
                [translateParams]="{count: activityThread?.thread.siblings.newer.missing}"
              ></span>
            </a>
          </span>

          <span *ngIf="loadingTrackers.siblings.newer.active">
            <i class="fa fa-spin fa-spinner"></i>
          </span>
        </div>
      </div>

      <div *ngFor="let child of activityThread?.thread.children.activities; trackBy: trackById">
        <ssi-activity
          [activity]="child"
          [isAddNoteButtonVisible]="true"
          [isInbox]="true"
          [isNestedThread]="isNestedThread"
          [isChild]="true"
          (onMessageboxClick)="changeActivity(child)"
          [isSelfPenned]="isOutboundMessage(child)"
          [isActionable]="child.social_actions.reply_type === 'comment' || child.inbox.requires_action"
          [ngClass]="{
            'inbox-activity-flash-on-entry': activityThread?.thread.newReply === child && child.flash,
            'activity-mouse-pointer': child.social_actions.reply_type === 'comment' || child.inbox.requires_action,
            'self-penned': isOutboundMessage(child)
          }"
          [conversation]="conversation"
          [replyBoxSticky]="replyBoxSticky"
          (onAfterScroll)="child.flash = true"
          #activeActivity
          (scrollToBottomOfThread)="scrollToBottomOfThread()"
          [inboxMode]="inboxMode"
          (canSplitConversation)="child.interaction.is_private && conversation"
          (openSplitConversationPanel)="openSplitConversationPanel(conversation, child)"
          (closeSplitConversationPanel)="closeSplitConversationPanel()"
          (splitConversation)="confirmConversationSplit()"
          [isPushMode]="isPushMode"
          (onReplyBoxStatusChanged)="onReplyBoxStatusChange($event)"
          (onResolveConversation)="onResolveConversation.emit($event)"
          (onHoldConversationStatusChange)="onHoldConversationStatusChange.emit($event)"
        ></ssi-activity>
      </div>
    </div>

    <div
      *ngFor="let sibling of activityThread?.thread.siblings.newer.activities; trackBy: trackById"
      class="inbox-activity-thread-newer-activities"
      [ngClass]="{
        expanded: loadingTrackers.siblings.newer.active
      }"
    >
      <ssi-activity
        [activity]="sibling"
        [isAddNoteButtonVisible]="true"
        [isInbox]="true"
        [isNestedThread]="isNestedThread"
        [isChild]="true"
        (onMessageboxClick)="changeActivity(sibling)"
        [isSelfPenned]="isOutboundMessage(sibling)"
        [isActionable]="sibling.social_actions.reply_type === 'comment' || sibling.inbox.requires_action"
        [ngClass]="{
          'self-penned': isOutboundMessage(sibling),
          'inbox-activity-flash-on-entry': activityThread?.thread.newReply === sibling && sibling.flash,
          'activity-mouse-pointer': sibling.social_actions.reply_type === 'comment' || sibling.inbox.requires_action
        }"
        [conversation]="conversation"
        [replyBoxSticky]="replyBoxSticky"
        (onAfterScroll)="sibling.flash = true"
        #activeActivity
        (scrollToBottomOfThread)="scrollToBottomOfThread()"
        [inboxMode]="inboxMode"
        (canSplitConversation)="sibling.interaction.is_private && conversation"
        (openSplitConversationPanel)="openSplitConversationPanel(conversation, sibling)"
        (closeSplitConversationPanel)="closeSplitConversationPanel()"
        (splitConversation)="confirmConversationSplit()"
        [isPushMode]="isPushMode"
        (onReplyBoxStatusChanged)="onReplyBoxStatusChange($event)"
        (onResolveConversation)="onResolveConversation.emit($event)"
        (onHoldConversationStatusChange)="onHoldConversationStatusChange.emit($event)"
      ></ssi-activity>
    </div>

    <div
      *ngIf="conversation && conversation.onHold"
      class="conversation-on-hold-indicator-container"
    >
      <div class="line"></div>
      <div class="conversation-on-hold-indicator">
        <i class="ssi ssi-on-hold"></i>
        <span>This conversation was put on hold by {{conversation.onHoldByUser?.name}}</span>
      </div>
      <div class="line"></div>
    </div>
  </div>
</div>
