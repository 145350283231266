<div
  class="groups-container"
  [class.centered]="topAudienceData.length === 0"
  [ngClass]="'view-' + viewClass + ' ' + 'view-' + viewClassForPartialResults"
>
  <div
    class="single-group"
    *ngFor="let audience of topAudienceData; let i = index"
  >
    <div class="main-facts">
      <div>
        <span class="value">{{audience.percentOfProfiles}}</span>
        <svg
          width="160"
          height="160"
          viewBox="0 0 160 160"
          style="transform: rotate(-90deg)"
        >
          <circle
            r="70"
            cx="80"
            cy="80"
            fill="transparent"
            stroke="#F4F4FA"
            stroke-width="17px"
          ></circle>
          <circle
            class="progress"
            r="70"
            cx="80"
            cy="80"
            fill="transparent"
            stroke-linecap="round"
            stroke-width="17px"
            [attr.stroke-dasharray]="circleLengthInUnits + 'px'"
            [attr.stroke-dashoffset]="audience.calculatedPercentage"
          ></circle>
        </svg>
      </div>

      <div
        class="top-word"
        (click)="openDrilldownModal(widget.type === 'industries' ? filterFields.Industries : filterFields.Interests, audience.name)"
      >
        <i class="ssi ssi-social-score"><span>{{i + 1}}</span></i>
        <span>{{audience.name}}</span>
      </div>
    </div>

    <div
      class="geo-demo-facts"
      (click)="openDemographicDrilldownModal(audience, audience.name)"
    >
      <div
        class="fact"
        *ngIf="audience.top_demographic && audience.top_demographic.gender"
      >
        <svg
          *ngIf="audience.top_demographic.gender === 'male'"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <circle
            cx="9"
            cy="9"
            r="9"
            fill="#DFF3FC"
          />
          <path
            d="M14.1268 4.2442C13.8629 3.9607 13.4198 3.9446 13.137 4.2071C12.8542 4.4696 12.8374 4.9127 13.0999 5.1962C14.0673 6.2378 14.6 7.5888 14.6 9C14.6 10.4238 14.061 11.7216 13.1832 12.7107C12.8983 11.9911 12.4321 11.3527 11.8196 10.8613C12.2543 10.2712 12.493 9.5586 12.493 8.8131C12.493 8.4029 12.4671 8.1117 12.3369 7.7624C12.8976 5.7093 11.4724 4.7034 11.4087 4.66C11.0909 4.4423 10.6604 4.5214 10.4399 4.835C10.1172 5.2935 9.6951 5.2837 9.6167 5.3075L9 5.3215C7.0743 5.3215 5.5077 6.8881 5.5077 8.8131C5.5077 9.5586 5.7464 10.2712 6.1804 10.8606C5.5679 11.352 5.1024 11.9911 4.8175 12.7107C3.939 11.7216 3.4 10.4238 3.4 9C3.4 5.9123 5.9123 3.4 9 3.4C9.8253 3.4 10.6275 3.5813 11.3849 3.9397C11.737 4.1049 12.1521 3.9544 12.3173 3.6058C12.4818 3.2565 12.3327 2.8393 11.9834 2.6734C11.037 2.2268 10.0339 2 9 2C5.1402 2 2 5.1402 2 9C2 12.8598 5.1402 16 9 16C12.8598 16 16 12.8598 16 9C16 7.2346 15.335 5.5448 14.1268 4.2442ZM5.9732 13.704C6.165 12.8115 6.7502 12.0415 7.5881 11.6068C7.7981 11.4976 7.9388 11.289 7.9619 11.0531C7.985 10.8172 7.887 10.5862 7.7015 10.4378C7.1968 10.036 6.9077 9.4438 6.9077 8.8131C6.9077 7.6595 7.8464 6.7215 9.0161 6.7208L9.5985 6.7061C9.9086 6.7222 10.4448 6.6298 10.9544 6.2735C11.0734 6.5584 11.1329 6.9847 10.9313 7.5734C10.8725 7.7463 10.883 7.9353 10.9614 8.1005C11.0734 8.3371 11.093 8.4435 11.093 8.8131C11.093 9.4438 10.8032 10.0367 10.2985 10.4399C10.113 10.5876 10.0157 10.8193 10.0388 11.0552C10.0619 11.2911 10.2026 11.499 10.4133 11.6082C11.2512 12.0422 11.835 12.8108 12.0275 13.7033C11.1532 14.2682 10.1158 14.6 9 14.6C7.8849 14.6 6.8468 14.2682 5.9732 13.704Z"
            fill="#40A2E2"
          />
          <path
            d="M9 16C5.1402 16 2 12.8598 2 9C2 5.1402 5.1402 2 9 2C10.0339 2 11.037 2.2268 11.9841 2.6741C12.3334 2.8393 12.4825 3.2572 12.318 3.6065C12.1528 3.9558 11.7377 4.1056 11.3856 3.9404C10.6275 3.5813 9.8253 3.4 9 3.4C5.9123 3.4 3.4 5.9123 3.4 9C3.4 12.0877 5.9123 14.6 9 14.6C12.0877 14.6 14.6 12.0877 14.6 9C14.6 7.5888 14.0673 6.2378 13.0999 5.1962C12.8374 4.9127 12.8535 4.4696 13.137 4.2071C13.4205 3.9446 13.8629 3.96 14.1268 4.2442C15.335 5.5448 16 7.2346 16 9C16 12.8598 12.8598 16 9 16Z"
            fill="#40A2E2"
          />
        </svg>

        <svg
          *ngIf="audience.top_demographic.gender === 'female'"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <circle
            cx="9"
            cy="9"
            r="9"
            fill="#FCE5E7"
          />
          <path
            d="M14.1268 4.2442C13.8643 3.9607 13.4205 3.9446 13.1377 4.2071C12.8542 4.4696 12.8374 4.9127 13.1006 5.1962C14.0673 6.2378 14.6 7.5888 14.6 9C14.6 10.5554 13.9616 11.9638 12.934 12.9795C12.7765 12.6232 12.5735 12.2865 12.3264 11.9841C13.1769 11.5165 13.6074 10.9985 13.6648 10.9264C13.851 10.6933 13.8685 10.3678 13.7089 10.1158C13.0852 9.1302 13.0376 8.4904 13.0348 8.4498C12.7149 5.1381 10.323 4.3492 9.0959 4.3821C5.9949 4.4962 5.073 7.0932 5.0121 8.4953C4.842 9.4998 4.4465 9.8673 4.4444 9.8701C4.1469 10.1081 4.0944 10.5407 4.3268 10.8431C4.7489 11.3926 5.2445 11.7573 5.6673 11.9932C5.423 12.2935 5.2228 12.6274 5.0667 12.9809C4.0391 11.9645 3.4 10.5561 3.4 9C3.4 5.9123 5.9123 3.4 9 3.4C9.8246 3.4 10.6275 3.5813 11.3849 3.9397C11.7342 4.1042 12.1521 3.9558 12.3173 3.6058C12.4825 3.2558 12.3327 2.8393 11.9834 2.6734C11.037 2.2268 10.0332 2 9 2C5.1402 2 2 5.1402 2 9C2 12.8598 5.1402 16 9 16C12.8598 16 16 12.8598 16 9C16 7.2346 15.335 5.5455 14.1268 4.2442ZM10.8543 11.0888C10.6002 11.1686 10.4133 11.3856 10.372 11.6495C10.3314 11.9127 10.4434 12.1766 10.6618 12.3299C11.1945 12.7044 11.5753 13.2532 11.7629 13.865C10.9467 14.3305 10.0052 14.6 9 14.6C7.9955 14.6 7.0547 14.3305 6.2392 13.8657C6.4282 13.2483 6.8125 12.6953 7.3515 12.3208C7.5769 12.164 7.6896 11.8903 7.6406 11.6208C7.5916 11.3506 7.3893 11.1343 7.1226 11.0671C6.2294 10.8375 5.801 10.3818 5.801 10.3818C6.3316 9.434 6.41 8.5863 6.41 8.5863C6.4135 8.4757 6.5192 5.878 9.1456 5.7814C9.2366 5.7709 11.3667 5.7401 11.639 8.5387C11.6418 8.6248 11.6803 9.3213 12.2354 10.365C11.7188 10.8319 10.8543 11.0888 10.8543 11.0888Z"
            fill="#EB6773"
          />
          <path
            d="M9 16C5.1402 16 2 12.8598 2 9C2 5.1402 5.1402 2 9 2C10.0332 2 11.037 2.2268 11.9834 2.6734C12.3334 2.8386 12.4825 3.2558 12.3173 3.6058C12.1521 3.9558 11.7342 4.1042 11.3849 3.9397C10.6275 3.5813 9.8246 3.4 9 3.4C5.9123 3.4 3.4 5.9123 3.4 9C3.4 12.0877 5.9123 14.6 9 14.6C12.0877 14.6 14.6 12.0877 14.6 9C14.6 7.5888 14.0673 6.2378 13.1006 5.1962C12.8374 4.9127 12.8542 4.4696 13.1377 4.2071C13.4205 3.9446 13.8643 3.9607 14.1268 4.2442C15.335 5.5455 16 7.2346 16 9C16 12.8598 12.8598 16 9 16Z"
            fill="#EB6773"
          />
        </svg>

        <div class="fact-name">
          <div translate="GENDER"></div>
          <div class="fact-value">{{ audience.top_demographic.gender | translate }}</div>
        </div>
      </div>
      <div
        class="fact"
        *ngIf="widget.type === 'industries' && audience.authors_by_engagements[0]"
      >
        <img
          *ngIf="audience.authors_by_engagements[0].avatar"
          [src]="audience.authors_by_engagements[0].avatar"
          alt="{{audience.authors_by_engagements[0].name}} avatar"
          ssiFallbackImage="avatarOutgoing"
        >
        <div class="fact-name">
          <div translate="MOST_ACTIVE_BRAND"></div>
          <div class="fact-value">{{audience.authors_by_engagements[0].name}}</div>
        </div>
      </div>
      <div
        class="fact"
        *ngIf="widget.type === 'interests'"
      >
        <i class="ssi ssi-age-range"></i>
        <div class="fact-name">
          <div translate="AGE"></div>
          <div class="fact-value">{{ageOptions[audience.top_demographic.age].label}}</div>
        </div>
      </div>
      <div
        class="fact"
        *ngIf="widget.type === 'interests' && audience.top_demographic"
      >
        <i class="ssi ssi-location-filter"></i>
        <div class="fact-name">
          <div translate="LOCATION"></div>
          <div class="fact-value">{{audience.top_demographic.city}}, {{audience.top_demographic.country}}</div>
        </div>
      </div>
      <div
        class="fact"
        *ngIf="widget.type === 'industries'"
      >
        <i class="ssi ssi-location-filter"></i>
        <div class="fact-name">
          <div translate="LOCATION"></div>
          <div class="fact-value">{{audience.authors_by_engagements[0].city}},
            {{audience.authors_by_engagements[0].country}}</div>
        </div>
      </div>
    </div>

    <div class="bio-keywords">
      <h5>TOP BIO KEYWORDS</h5>
      <div class="keywords">
        <span
          *ngFor="let keyword of audience.keywords"
          (click)="openDrilldownModal(filterFields.BioKeywords, keyword, audience.name)"
        >{{keyword}}</span>
      </div>
    </div>

    <div class="top-emotions">
      <h5>TOP EMOTIONS</h5>
      <div class="emotions-list">
        <span
          class="chip-item"
          *ngFor="let emotionKey of audience.emotions"
          (click)="openDrilldownModal(filterFields.Emotion, emotionKey, audience.name)"
        >
          <i
            class="ssi {{emotions[emotionKey].icon}}"
            [style.color]="emotions[emotionKey].iconColor"
            [style.background-color]="emotions[emotionKey].iconBgColor"
          ></i>
          <span [style.background-color]="emotions[emotionKey].secondaryBgColor">{{emotions[emotionKey].label}}</span>
        </span>
      </div>
    </div>

    <div class="top-influencer">
      <h5>{{widget.type === 'interests' ? 'TOP INFLUENCER' : 'TOP BRAND INFLUENCER'}}<span
          (click)="viewMoreInflucencers(
            audience.name,
            audience.authors_by_followers,
            audience.authors_by_engagements
          )"
          class="view-more"
        ><i class="ssi ssi-user-small"></i>{{'VIEW_MORE' | translate}}</span>
      </h5>
      <div class="social-profile-dets">
        <img
          class="avatar"
          [src]="audience.authors_by_followers[0].avatar"
          alt="social profile avatar"
          ssiFallbackImage="avatarOutgoing"
        > <span class="linked-name">{{audience.authors_by_followers[0].name}}</span> <span
          class="handle">&nbsp;(@{{audience.authors_by_followers[0].username}})</span>
        <span class="followers-count">
          <ssi-big-number [number]="audience.authors_by_followers[0].followers || 0"></ssi-big-number>
        </span>
      </div>
    </div>
  </div>
  <div
    *ngIf="!topAudienceData || topAudienceData && topAudienceData.length === 0"
    class="no-results-state"
  >
    <i class="ssi ssi-no-results-option"></i>
    <h4>No data to display</h4>
    <p>This may be due to filters applied or too many varying themes</p>
  </div>
</div>