<div class="conversation-slas-section">

  <ul class="time-range-list">
    <li *ngFor="let sla of slaData.top_level; let i = index">
      <div class="time-range-list-item">
        <div class="time-range-list-range">
          <span
            [innerHTML]="(sla.from || '0') | secondsToHumanTime:true:true:{secondLabel: 's', minuteLabel: 'm', dayLabel: 'd', hourLabel: 'h'}"
          ></span>
          <ng-container *ngIf="sla.to">
            <span class="time-range-list-range-hyphen">-</span>
            <span
              [innerHTML]="(sla.to || '0') | secondsToHumanTime:true:true:{secondLabel: 's', minuteLabel: 'm', dayLabel: 'd', hourLabel: 'h'}">
            </span>
          </ng-container>
          <ng-container *ngIf="!sla.to">&nbsp;+</ng-container>
        </div>
        <div class="time-range-list-progress">
          <span
            *ngIf="slaDataPrevious && slaDataPrevious.top_level"
            [style.width.%]="sla.value * 100 / slaDataPrevious.top_level[i].value"
          ></span>
        </div>
        <div class="time-range-list-amount">
          <p class="time-range-list-amount-format-wrap">
            <span class="time-range-list-amount-formatted">
              {{ sla.value === 1 ? (sla.value | bigNumber) + ' Response' : (sla.value | bigNumber) + ' Responses' }}
            </span>
            <span class="time-range-list-amount-raw">
              {{ sla.value === 1 ? sla.value + ' Response' : sla.value + ' Responses' }}
            </span>
          </p>
          <p>{{ ((100 * sla.value) / total) | number : '1.0-2' }}%</p>
        </div>
      </div>
    </li>
  </ul>

  <ssi-treetable
    *ngIf="dataToDisplay.length"
    [data]="dataToDisplay"
    [bodyStyle]="{'max-height': '408px'}"
    [rowStyle]="{'height': '68px'}"
    [virtualScroll]="false"
    [resizeMode]="'fit'"
    [reorderable]="true"
    [saveSettings]="true"
    [settingsStorageKey]="'treetable-engagement-analytics-response-time-sla'"
    [globalFilterInputRef]=""
    id="treetable-engagement-analytics-response-time-sla"
  >
    <ssi-column
      header="User"
      field="user.fullName"
      [templateRefs]="{bodyCell: userBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      *ngIf="slas[0]"
      [header]="slas[0].header"
      field="responseSla0"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      *ngIf="slas[1]"
      [header]="slas[1].header"
      field="responseSla1"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      *ngIf="slas[2]"
      [header]="slas[2].header"
      field="responseSla2"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      *ngIf="slas[3]"
      [header]="slas[3].header"
      field="responseSla3"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      *ngIf="slas[4]"
      [header]="slas[4].header"
      field="responseSla4"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      *ngIf="slas[5]"
      [header]="slas[5].header"
      field="responseSla5"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      *ngIf="slas[6]"
      [header]="slas[6].header"
      field="responseSla6"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
  </ssi-treetable>

  <ng-template #userBodyCellTemplate
               ssiTemplateSelector="bodyCell"
               let-rowIndex="rowIndex"
               let-rowData="rowData"
               let-fieldValue="fieldValue">
    <div class="body-cell body-cell-primary">
      <i class="ssi ssi-user"></i>
      <p>{{fieldValue}}</p>
    </div>
  </ng-template>

  <ng-template #statBodyCellTemplate
               ssiTemplateSelector="bodyCell"
               let-rowIndex="rowIndex"
               let-rowData="rowData"
               let-column="column"
               let-columnIndex="columnIndex"
               let-columns="columns"
               let-columnsMap="columnsMap"
               let-fieldValue="fieldValue">
    <div class="body-cell stat" [class.row-even]="rowIndex % 2 !== 0">
      <p>{{fieldValue}}</p>
    </div>
  </ng-template>

</div>