import './automate-modal.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '@ui-resources-angular';
import { mapToIterable } from '../../utils';
import {
  AutomatedReportsService,
  AutomatedReport,
  ReportAutomationFrequency
} from '../../services/api';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import { isEmptyObj } from '../../util';

export interface AutomatedModalFrequencyOption {
  key: ReportAutomationFrequency;
  label: string;
}

export const automatedModalFrequencyOptions: any = {
  [ReportAutomationFrequency.WEEKLY]: {
    key: ReportAutomationFrequency.WEEKLY,
    label: 'Weekly'
  },
  [ReportAutomationFrequency.MONTHLY]: {
    key: ReportAutomationFrequency.MONTHLY,
    label: 'Monthly'
  }
};

export const automatedModalFrequencyOptionsIterable: AutomatedModalFrequencyOption[] = mapToIterable(
  automatedModalFrequencyOptions
);

@Component({
  selector: 'ssi-automate-modal',
  templateUrl: './automate-modal.component.html'
})
export class AutomateModalComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() reportUrl: string;
  @Input() reportData: AutomatedReport;

  loading = false;
  emailTo: string;
  copy: boolean;
  reportName: string;
  currentUserEmail: string;
  selectedAutomatedModalFrequencyOption: AutomatedModalFrequencyOption;
  automatedModalFrequencyOptionsIterable: AutomatedModalFrequencyOption[] = automatedModalFrequencyOptionsIterable;
  automatedModalFrequencyOptions = automatedModalFrequencyOptions;

  constructor(
    public activeModal: NgbActiveModal,
    private automatedReportsService: AutomatedReportsService,
    private userModel: UserModel,
    private snackbar: SnackbarService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUserEmail = (await this.userModel.getAuthUser()).email_address;

    if (this.reportData) {
      await this.populateForm();
    }
  }

  populateForm() {
    this.reportUrl = this.reportData.url;
    this.emailTo = this.reportData.recipients.join(', ');
    this.copy = this.reportData.recipients.includes(this.currentUserEmail);
    this.reportName = this.reportData.name;
    this.selectedAutomatedModalFrequencyOption =
      automatedModalFrequencyOptions[this.reportData.frequency];
  }

  async handleReportUpdate() {
    try {
      const changes = this.getReportChanges();
      if (isEmptyObj(changes)) {
        this.loading = false;
        return;
      }

      await this.automatedReportsService
        .update(Object.assign(changes, { id: this.reportData.id }))
        .then((updatedReport) => {
          this.loading = false;

          this.activeModal.close();
          this.snackbar.open(
            `Successfully edited automated report ${this.reportName}`,
            {
              duration: 3,
              position: 'bottom',
              snackbarClass: 'snackbar-style-1'
            }
          );
        });
    } catch (error) {
      console.error(`Could not update automated report: `, error);
      this.loading = false;
    }
  }

  async handleReportCreate() {
    try {
      const recipients = this.formatRecipients(this.emailTo);
      const reportUrlSubDirectory = this.reportUrl.split('/#')[1];
      const orloUrl = 'https://www.orlo.app/#' + reportUrlSubDirectory;

      if (this.copy) {
        if (recipients.includes(this.currentUserEmail)) {
          recipients.push(this.currentUserEmail);
        }
      }

      await this.automatedReportsService
        .create(
          this.reportName,
          orloUrl +
            `&disablePopup=true&frequency=${this.selectedAutomatedModalFrequencyOption.key}`,
          recipients,
          this.selectedAutomatedModalFrequencyOption.key
        )
        .then((createdReport) => {
          this.loading = false;

          this.activeModal.close();
          this.snackbar.open(
            `Added scheduled automated report ${this.reportName}`,
            {
              duration: 3,
              position: 'bottom',
              snackbarClass: 'snackbar-style-1'
            }
          );
        });
    } catch (error) {
      console.error(`Could not create automated report: `, error);
      this.loading = false;
    }
  }

  submit() {
    if (
      this.loading ||
      !this.emailTo ||
      !this.reportName ||
      !this.selectedAutomatedModalFrequencyOption
    ) {
      return;
    }

    this.loading = true;

    if (this.editMode) {
      this.handleReportUpdate();
    } else {
      this.handleReportCreate();
    }
  }

  linkToReport() {
    window.open(this.reportData.url, '_blank');
  }

  getReportChanges(): Partial<AutomatedReport> {
    const changes: Partial<AutomatedReport> = {};

    if (this.emailTo !== this.reportData.recipients.join(', ')) {
      changes.recipients = this.formatRecipients(this.emailTo);
    }

    if (this.reportName !== this.reportData.name) {
      changes.name = this.reportName;
    }

    if (
      this.selectedAutomatedModalFrequencyOption.key !==
      this.reportData.frequency
    ) {
      changes.frequency = this.selectedAutomatedModalFrequencyOption.key;

      if (this.reportData.url.indexOf('frequency=') === -1) {
        // if someone attempts to update existing reports that were created before the frequency query param was added
        changes.url = this.reportData.url + `&frequency=${changes.frequency}`;
      } else {
        changes.url = this.reportData.url.replace(
          `frequency=${this.reportData.frequency}`,
          `frequency=${changes.frequency}`
        );
      }
    }
    return changes;
  }

  formatRecipients(emailAddresses: string): string[] {
    return emailAddresses.split(',').map((email) => email.trim());
  }
}
