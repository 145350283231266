<svg
  class="icon-file"
  width="40px"
  height="40px"
  enable-background="new 0 0 309.537 309.537"
  version="1.1"
  viewBox="0 0 309.54 309.54"
  xml:space="preserve"
  xmlns="http://www.w3.org/2000/svg">
  <path
    class="icon-file-background"
    d="m270.74 0.271v-0.271s-156.83 0.193-212.57 0.251c-10.931 0-19.378 8.215-19.378 19.378v289.9h212.62c10.67 0 19.329-8.659 19.329-19.329v-270.61h19.329v-19.329c1e-3 0-19.328 0.01-19.328 0.01z"
    [attr.fill]="primaryColour"/>
  <path
    class="icon-file-lip"
    d="m232.09 290.21c0-10.67 8.659-19.329 19.329-19.329h-231.95c-10.67 0-19.329 8.659-19.329 19.329s8.659 19.329 19.329 19.329h231.95c-10.669 1e-3 -19.328-8.659-19.328-19.329z"
    [attr.fill]="accentColour"/>
  <path
    class="icon-file-lip"
    d="m290.07 0.271c-10.67 0-19.329 8.659-19.329 19.329v19.329h19.329c10.67 0 19.329-8.659 19.329-19.329 0-10.68-8.659-19.329-19.329-19.329z"
    [attr.fill]="accentColour"/>
  <text
    class="icon-file-meta"
    x="50%"
    text-anchor="middle"
    [attr.y]="verticalAlignment"
    [attr.font-size]="fontSize"
    fill="#fff">
    {{fileType}}
  </text>
</svg>