import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryPresetsComponent } from './query-presets.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [QueryPresetsComponent],
  exports: [QueryPresetsComponent]
})
export class QueryPresetsModule {}
