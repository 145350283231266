import './icon.component.scss';
import { Component, OnInit, Input } from '@angular/core';
import * as svgFiles from './index';

@Component({
  selector: 'ssi-icon',
  template: `
    <svg-icon
      *ngIf="this.validIcon"
      [src]="this.imgMap[this.icon]"
      [class]="this.wrapperClass ? this.wrapperClass : ''"
      [svgStyle]="{
        'height.px': this.height || 14,
        'width.px': this.width || 14,
        'fill': this.color
      }">
    </svg-icon>
  `
})
export class IconComponent implements OnInit {
  @Input() icon: string;
  @Input() wrapperClass?: string;
  @Input() height?: string | number;
  @Input() width?: string | number;
  @Input() color?: string;
  imgMap = {};
  validIcon = false;

  constructor() {}

  ngOnInit() {
    this.imgMap = Object.entries(svgFiles).reduce(
      (accumulator, [key, value]) => ({ ...accumulator, [key]: value }),
      {}
    );
    this.validIcon = Object.keys(this.imgMap).includes(this.icon);
  }
}
