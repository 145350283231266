import { Component, OnInit, Input } from '@angular/core';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  companyPreferencesResolveFn,
  authUser
} from './../../../../common-resolves';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { PopupService } from '../../../../../common/services/popup/popup.service';

export async function samlIdentitiesFn(api: API) {
  return api
    .get('settings/samlIdentityProvider', { autoError: false })
    .then(({ data }) => data)
    .catch(() => {});
}

@Component({
  selector: 'ssi-authentication-settings',
  templateUrl: './authentication-settings.component.html',
  styleUrls: []
})
export class AuthenticationSettingsComponent implements OnInit {
  static resolve = [
    {
      token: 'settings',
      resolveFn: companyPreferencesResolveFn,
      deps: [CompanyService]
    },
    {
      token: 'samlIdentities',
      resolveFn: samlIdentitiesFn,
      deps: [API]
    },
    authUser
  ];

  @Input() settings;
  @Input() samlIdentities;
  @Input() authUser;
  allSamlIdentities;
  selectedIdentityProvider;
  loadingTracker = this.asyncTrackerFactory.create();
  betaOkta: boolean;

  constructor(
    private company: CompanyService,
    private state: StateService,
    private api: API,
    private translate: TranslateService,
    private popup: PopupService,
    private asyncTrackerFactory: AsyncTrackerFactory
  ) {}

  async ngOnInit() {
    this.betaOkta = await this.company.hasFeatureAccess('okta');
    if (this.samlIdentities) {
      this.allSamlIdentities = [
        ...this.samlIdentities.active,
        ...this.samlIdentities.configurable
      ];
      this.selectedIdentityProvider = {
        name: ''
      };
    }
  }

  saveSettings() {
    const promise = this.api
      .post('settings/companyPreference', this.settings)
      .then(() => {
        return this.state.go('auth.settings.index');
      })
      .then(() => {
        this.popup.alert({
          title: this.translate.instant('SETTINGS_SAVED'),
          message: this.translate.instant(
            'YOUR_SETTINGS_WERE_SAVED_SUCCESSFULLY'
          )
        });
      });
    this.loadingTracker.add(promise);
  }

  identityProviderChanged(name) {
    try {
      this.selectedIdentityProvider = {
        name
      };

      if (
        !(!!this.allSamlIdentities && Array.isArray(this.allSamlIdentities))
      ) {
        throw new Error(
          `Value for 'authentication settings identities' is not as expected.`
        );
      }

      const existingConfig =
        this.allSamlIdentities.find((provider) => provider.name === name) || {};
      Object.assign(this.selectedIdentityProvider, existingConfig);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  saveIdentityProvider() {
    this.api
      .post('settings/samlIdentityProvider', this.selectedIdentityProvider)
      .then(({ data: providerConfig }) => {
        try {
          this.selectedIdentityProvider = {};

          if (
            !(!!this.allSamlIdentities && Array.isArray(this.allSamlIdentities))
          ) {
            throw new Error(
              `Value for 'authentication settings identities' is not as expected.`
            );
          }

          const existingProvider = this.allSamlIdentities.find(
            (provider) => provider.name === providerConfig.name
          );
          if (existingProvider) {
            Object.assign(existingProvider, providerConfig);
          } else {
            this.allSamlIdentities.push(providerConfig);
          }
          this.popup.alert({
            title: this.translate.instant('IDENTITY_SAVED'),
            message: this.translate.instant(
              'THE_IDENTITY_PROVIDER_WAS_SAVED_SUCCESSFULLY'
            )
          });
        } catch (error) {
          console.error(error);

          return false;
        }
      });
  }

  deleteIdentityProvider(provider) {
    this.popup
      .confirm({
        title: this.translate.instant('DELETE_IDENTITY_PROVIDER'),
        message: this.translate.instant(
          'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_IDENTITY_PROVIDER'
        )
      })
      .then((shouldDelete) => {
        if (shouldDelete) {
          this.api
            .del('settings/samlIdentityProvider', {
              params: { id: provider.id }
            })
            .then(() => {
              this.allSamlIdentities.forEach((existingProvider) => {
                if (existingProvider.name === provider.name) {
                  delete existingProvider.id;
                  delete existingProvider.entity_id;
                  delete existingProvider.sso_url;
                  delete existingProvider.cert;
                }
              });
              this.selectedIdentityProvider = {};
            });
        }
      });
  }
}
