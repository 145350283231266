import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import { Subject } from 'rxjs';

export interface AgentStatus {
  id: AgentStatusID;
  label: string;
  cssClass: string;
}

export enum AgentStatusID {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  Busy = 'BUSY'
}

export const AgentStatuses = {
  offline: {
    id: AgentStatusID.Offline,
    label: 'Offline',
    cssClass: 'offline'
  },
  online: {
    id: AgentStatusID.Online,
    label: 'Online',
    cssClass: 'online'
  },
  busy: {
    id: AgentStatusID.Busy,
    label: 'Busy',
    cssClass: 'busy'
  }
};

@Injectable()
export class ConversationPushModeService {
  private _pushModeStatusCache: AgentStatusID;
  public pushModeStatusChange = new Subject<string>();

  constructor(private api: API) {}

  async getAgentStatus(): Promise<AgentStatusID> {
    if (!this._pushModeStatusCache) {
      const response = await this.api.get(
        'conversation/conversationPushModeAgentStatus'
      );
      this._pushModeStatusCache = response.data.status;
      this.pushModeStatusChange.next(this._pushModeStatusCache);
    }
    return this._pushModeStatusCache;
  }

  async changeAgentStatus(newStatus: AgentStatusID, userId = null) {
    const response = await this.api.post(
      'conversation/conversationPushModeAgentStatus',
      {
        status: newStatus,
        for_user_id: userId
      }
    );
    this._pushModeStatusCache = response.data.status;
    this.pushModeStatusChange.next(this._pushModeStatusCache);
    return this._pushModeStatusCache;
  }

  async getCompanySettings(): Promise<{
    enabled: boolean;
    max_queue_size: number;
    success: boolean;
  }> {
    const { data } = await this.api.get(
      'conversation/conversationPushModeCompanyStatus'
    );
    return data;
  }

  clearCache() {
    delete this._pushModeStatusCache;
    this.pushModeStatusChange.next(null);
  }
}
