<div class="survey-analytics-build-container">
  <div class="head">
    <h3 translate="SURVEY_REPORT"></h3>
    <h2>Build your report</h2>
  </div>

  <div class="body">
    <div class="body-inner">
      <ssi-report-build-from report="survey"
                             [formValue]="formValue"
                             [accountTypeIds]="[AccountTypeId.Twitter, AccountTypeId.Facebook, AccountTypeId.LiveChat]"
                             [includeLiveChatAccounts]="true"
                             (formSubmit)="onFormSubmit($event)"></ssi-report-build-from>
    </div>
  </div>
</div>