export const ascending = `asc`;
export const day = `d`;
export const descending = `desc`;
export const hour = `h`;
export const minute = `m`;
export const newestChats = `SHORTEST_WAITING`; // @todo eh? Nah.
export const oldestChats = `LONGEST_WAITING`; // as above
export const week = `w`;
export const year = `y`;

export default {
  ascending,
  day,
  descending,
  hour,
  minute,
  newestChats,
  oldestChats,
  week,
  year
};
