import './wave-dots.component.scss';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-wave-dots',
  templateUrl: './wave-dots.component.html'
})
export class WaveDotsComponent implements OnInit {
  @Input() color = '#FFF';

  ngOnInit() {}
}
