import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { Activity, API } from '@ui-resources-angular';
import { normalise_url } from '../../../../../library/helpers/strings';
import {
  images as stringImages,
  link as stringLink,
  meta as stringMeta,
  preview as stringPreview
} from '../../../../../library/constants/strings';

import defaultImage from './default.png';

// ----------------

const isDebug: boolean = false;

// ----------------

@Component({
  selector: 'ssi-activity-link-preview',
  templateUrl: './activity-link-preview.component.html',
  styles: []
})
export class ActivityLinkPreviewComponent
  implements OnChanges, OnDestroy, OnInit {
  @Input() public activity: Activity;

  @ViewChild('linkTrigger') public linkTrigger: ElementRef;

  protected _link: any;
  protected _linkDescription: string;
  protected _linkImage: string;
  protected _linkTitle: string;
  protected _linkUrl: string;

  public defaultImage = defaultImage;

  constructor(protected api: API) {}

  public get hasLink(): boolean {
    return !!this._link;
  }

  public get linkDescription(): string {
    return this._linkDescription;
  }

  public get linkImage(): string {
    return this._linkImage || this.defaultImage;
  }

  public get linkTitle(): string {
    return this._linkTitle;
  }

  public get linkUrl(): string {
    return this._linkUrl;
  }

  public async ngOnChanges(changes): Promise<boolean> {
    try {
      const hasActivityChanged: boolean =
        !!changes && !!changes.hasOwnProperty('activity');

      if (hasActivityChanged) {
        if (isDebug) {
          console.log(`ActivityLinkPreview: activity has changed`);
        }

        this.reset();

        const currentValue = changes.activity.currentValue as Activity;
        const linkTypeSymbol = `${stringLink}-${stringPreview}`;
        const link =
          !!currentValue &&
          !!currentValue.media &&
          Array.isArray(currentValue.media)
            ? currentValue.media.find((item) => item.type === linkTypeSymbol)
            : null;

        if (!link) {
          if (isDebug) {
            console.log(`ActivityLinkPreview: no link media found`);
          }

          return true;
        }

        this._link = link;

        requestAnimationFrame(() => {
          this._linkDescription = link['description'];
          this._linkImage = link['media'];
          this._linkTitle = link['title'];
          this._linkUrl = normalise_url(link['url']);

          const linkTriggerElement = this.linkTrigger
            .nativeElement as HTMLAnchorElement;
          linkTriggerElement.setAttribute('href', this._linkUrl);

          if (isDebug) {
            console.log('ActivityLinkPreview: completed defining of link data');
          }
        });
      }

      return true;
    } catch (error) {
      console.error(error);

      this.reset();

      return false;
    }
  }

  public ngOnDestroy(): boolean {
    try {
      if (isDebug) {
        console.log(`in activityLinkPreview~>ngOnDestroy`);
      }

      this.reset();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public ngOnInit(): boolean {
    try {
      if (isDebug) {
        console.log(`in activityLinkPreview~>ngOnInit`);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public reset(): boolean {
    try {
      if (isDebug) {
        console.log(`in: activityLinkComponent~>reset`);
      }

      this._link = undefined;
      this._linkDescription = undefined;
      this._linkImage = undefined;
      this._linkTitle = undefined;
      this._linkUrl = undefined;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}
