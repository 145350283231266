import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatWidgetFilterPipe } from './format-widget-filter.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FormatWidgetFilterPipe],
  exports: [FormatWidgetFilterPipe]
})
export class FormatWidgetFilterModule {}
