import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Agent as AgentModel } from '../../../../../../library/models/live-chat/agent';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { format } from 'date-fns';

@Component({
  selector: 'ssi-live-chat-next-chat',
  templateUrl: './live-chat-next-chat.component.html',
  styles: []
})
export class LiveChatNextChatComponent {
  @Input() ageOfNewestAssignedConversation: string;
  @Input() ageOfNewestUnassignedConversation: string;
  @Input() quantityOfOngoingConversations: number;
  @Input() quantityOfOwnedConversations: number;
  @Input() quantityOfUnassignedConversations: number;
  @Input() quantityOfVisibleConversations: number;

  @Output() conversationsFilterChange = new EventEmitter();

  private _isAssigning = false;

  constructor(private liveChatService: LiveChatService) {}

  public get agent(): AgentModel {
    return this.liveChatService.agentModel;
  }

  public get areUnassignedChatsAvailable(): boolean {
    return !!this.isAgentOnline && !!this.quantityOfUnassignedConversations;
  }

  public get browserOfflineAt(): string {
    return format(this.liveChatService.browserOfflineAt, `H:mmA`);
  }

  public get browserOfflineTotalInSeconds(): number {
    return this.liveChatService.browserOfflineTotalInSeconds;
  }

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public get isAgentOffline(): boolean {
    return this.agent ? this.agent.isOffline : true;
  }

  public get isAgentOnline(): boolean {
    return this.agent ? this.agent.isOnline : false;
  }

  public get isAssigning(): boolean {
    return this._isAssigning;
  }

  public get isAwaitingPushedChats(): boolean {
    return this.isPushModeEnabled && !!this.quantityOfAwaitedPushedChats;
  }

  public get isBrowserOffline(): boolean {
    return this.liveChatService.isBrowserOffline;
  }

  public get isButtonDisabled(): boolean {
    return (
      !this.areUnassignedChatsAvailable ||
      !!this.isAssigning ||
      !!this.isInterfaceDisabled
    );
  }

  public get isPushModeEnabled(): boolean {
    return this.liveChatService.isPushModeEnabled;
  }

  public get pendoTagForButton(): string {
    return this.isButtonDisabled
      ? 'liveChat_attemptNextChatPlease'
      : 'liveChat_nextChatPlease';
  }

  public get quantityOfAwaitedPushedChats(): number {
    if (!this.isPushModeEnabled) {
      return 0;
    }

    // @todo: fetch if needed ...
    const quantityOfChatsAssigned: number = 0;

    if (
      this.liveChatService.pushModeAgentAssignmentTarget <=
      quantityOfChatsAssigned
    ) {
      return 0;
    }

    return (
      this.liveChatService.pushModeAgentAssignmentTarget -
      quantityOfChatsAssigned
    );
  }

  public selfAssignStalestUnassignedConversation() {
    if (!this.areUnassignedChatsAvailable) {
      return;
    }

    this._isAssigning = true;

    this.liveChatService.selfAssignStalestUnassignedConversation().then(() => {
      this._isAssigning = false;
    });
  }

  public updateFilter(value) {
    this.conversationsFilterChange.emit(value);
  }
}
