<div class="section-heading">
  <h3 translate="CHATBOT_HANDOVER"></h3>
  <div class="section-tip-wrap">
    <div class="section-tip">
      <button
        class="section-tip-icon"
        (click)="proTipOpen = !proTipOpen"
      ><i class="ssi ssi-pro-tip"></i></button>
      <p [style.display]="proTipOpen ? 'inline-flex' : 'none'">
        {{'CHATBOT_HANDOVER_TIMES_PRO_TIP' | translate}}
      </p>
    </div>
  </div>
</div>
<div class="section-content">
  <ssi-highchart [config]="handoverTimesChartConfig"></ssi-highchart>
</div>