import './widget-profile-search.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import {
  Dashboard,
  DashboardService,
  DashboardWidget
} from '../../../../../../../common/services/api/dashboard';
import {
  Account,
  AccountModel,
  Activity,
  ActivityModel,
  ProfileModel,
  ProfileSearchResult
} from '@ui-resources-angular';
import { orderBy } from 'lodash-es';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import {
  ContentGeneratorService,
  RssArticle
} from '../../../../../marketing/content-generator/content-generator.service';
import { StateService } from '@uirouter/angular';
import { ApiService } from '../../../../../../../common/services/api';
import { AccountTypeId } from '../../../../../../../common/enums';
import {
  Profile,
  ProfileFeedItem
} from '@ui-resources/modules/profile/services/profileModel';
import { SearchStream } from '../widget-live-x-stream/widget-live-x-stream.component';

export const SCROLL_INCREMENT_AMOUNT = 10;

@Component({
  selector: 'ssi-widget-profile-search',
  templateUrl: './widget-profile-search.component.html',
  styles: []
})
export class WidgetProfileSearchComponent implements OnInit, OnChanges {
  @Input() widget: DashboardWidget;
  @Input() currentDashboard: Dashboard;

  accounts: Account[] = [];
  selectedAccount: Account;
  viewClass: string;
  searchText: Array<string> = [];
  rateLimited: boolean;
  currentView: 'profile' | 'feed' = 'profile';
  profile: Profile;

  activeSearchText: string;
  profiles: ProfileSearchResult[] = [];
  feedItems: SearchStream[] = [];
  // posts: SearchStream;
  viewPortFeedItems: ProfileFeedItem[] = [];
  searchingProfiles = false;
  searchingFeed = false;

  loadingTracker: angular.promisetracker.PromiseTracker;
  activityLoadError: boolean;
  displayMax: number;
  disableLoadMore: boolean;
  nextPage: string;
  feedNextPage: string;

  @Output() loaded = new EventEmitter<boolean>();

  constructor(
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected contentGeneratorService: ContentGeneratorService,
    protected profileModel: ProfileModel,
    protected state: StateService,
    protected dashboardService: DashboardService,
    protected api: ApiService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.viewClass = `${this.widget.display_properties.w}x${this.widget.display_properties.h}`;
  }

  async ngOnInit() {
    this.viewClass = `${this.widget.display_properties.w}x${this.widget.display_properties.h}`;

    this.accounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId()
    );
    this.accounts = this.accounts.filter(
      (acc) =>
        acc.account_type_id === String(AccountTypeId.Twitter) ||
        acc.account_type_id === String(AccountTypeId.Facebook)
    );
    this.accounts = orderBy(this.accounts, ['account_type_name', 'name']);

    this.loaded.emit(true);
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.activeSearchText = this.searchText[0];
      this.search();
    }
  }

  onTagChange($event: Array<string>) {
    if (this.searchText && this.searchText.length) {
      this.activeSearchText = this.searchText[0];
      this.search();
    } else {
      this.profiles = null;
      this.feedItems = null;
      this.activeSearchText = null;
    }
  }

  async search(page?: string) {
    const isInitial = !page;
    try {
      this.currentView = 'profile';
      this.searchingProfiles = true;
      const promise = this.profileModel
        .search(this.selectedAccount.id, this.activeSearchText, {
          page,
          size: 35
        })
        .then(({ results, links }) => {
          if (isInitial) {
            this.profiles = results;
          } else {
            this.profiles = [...this.profiles, ...results];
          }
          this.feedItems = null;
          this.searchingProfiles = false;
          this.nextPage = links.next.page;
        });
    } catch (error) {
      console.error('Error searching for profiles from the Dashboard:', error);
      this.searchingProfiles = false;

      this.rateLimited = error.status === 503;
    }
  }

  async loadFeed(profile: ProfileSearchResult | any, page?: string) {
    try {
      this.currentView = 'feed';
      this.searchingFeed = true;
      const foundProfile = await this.profileModel.findByIdAndAccountId(
        profile.id,
        this.selectedAccount.id,
        false
      );
      this.profile = foundProfile;
      const res = await foundProfile.getFeed();
      this.searchingFeed = false;

      this.feedNextPage = res.next;
      this.feedItems = res.posts.map((feedItem: ProfileFeedItem) => {
        return {
          ...feedItem,
          text: feedItem.text,
          created_at: feedItem.created_at,
          isLiked: feedItem.have_liked,
          author: {
            ...this.profile,
            name: this.profile.info.name,
            username: this.profile.info.username,
            avatar: this.profile.info.picture,
            link: this.profile.externalUrl
          },
          social_id: feedItem.id,
          media: [{ id: feedItem.id, url: feedItem.image_url, type: 'image' }]
        };
      });
    } catch (error) {
      this.searchingFeed = false;
      console.error(
        `Error loading feed in Dashboard for ${profile.id}:`,
        error
      );
      this.feedItems = [];
    }
  }

  async getFeedNextPage() {
    try {
      this.searchingFeed = true;
      const res = await this.profile.getFeedNextPage(this.feedNextPage);
      this.searchingFeed = false;

      this.feedNextPage = res.next;
      this.feedItems = [
        ...this.feedItems,
        ...res.posts.map((feedItem: ProfileFeedItem) => {
          return {
            ...feedItem,
            text: feedItem.text,
            created_at: feedItem.created_at,
            isLiked: feedItem.have_liked,
            author: {
              ...this.profile,
              name: this.profile.info.name,
              username: this.profile.info.username,
              avatar: this.profile.info.picture,
              link: this.profile.externalUrl
            },
            social_id: feedItem.id,
            media: [{ id: feedItem.id, url: feedItem.image_url, type: 'image' }]
          };
        })
      ];
    } catch (error) {
      this.searchingFeed = false;
      console.error('Error loading more feed posts:', error);
    }
  }

  accountSelected() {
    this.searchText = [];
    this.activeSearchText = null;
    this.profiles = null;
    this.feedItems = null;
  }

  selectedAccountChanged(selectedAccount: Account): void {
    this.selectedAccount = selectedAccount;
  }

  getHeight = () => {
    return 284;
  };

  viewPortFeedItemsUpdated(items: ProfileFeedItem[]): void {
    // console.log('viewPortItemsUpdated: ', items);
    this.viewPortFeedItems = items;
  }

  onScrolled(): void {
    // console.log('scrolled...');
  }
}
