import './theme-detectors.component.scss';

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  DetectedTheme,
  DetectedThemesResponse,
  ThemeDetector,
  ThemeDetectorFormValue,
  ThemeDetectorsService
} from '../../../../common/services/api';
import { PageChangeParams } from '../../../../common/components/paginator';
import { DetectedThemesComponent } from './detected-themes/detected-themes.component';
import { FieldName, filtersFieldDefinitions } from '../common/constants';
import { DetectedThemeResultsComponent } from './detected-theme-results/detected-theme-results.component';

@Component({
  selector: 'ssi-theme-detectors',
  templateUrl: './theme-detectors.component.html',
  styles: []
})
export class ThemeDetectorsComponent implements OnInit, OnDestroy {
  @ViewChild(DetectedThemesComponent)
  detectedThemesComponent: DetectedThemesComponent;

  themeDetectors: ThemeDetector[] = [];

  pageChangeParams: PageChangeParams;
  responsesPerPage: { [key: number]: DetectedThemesResponse } = {}; // not used for caching results purposes, only to track page load tokens

  loading = false;
  readonly itemsPerPage = 50;
  destroyed$: Subject<void> = new Subject<void>();

  constructor(
    protected modal: NgbModal,
    protected themeDetectorsService: ThemeDetectorsService
  ) {}

  ngOnInit() {
    this.themeDetectorsService.getAll();

    this.themeDetectorsService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((themeDetectors) => {
        this.themeDetectors = themeDetectors;
        this.responsesPerPage = {};
        if (
          this.detectedThemesComponent &&
          this.detectedThemesComponent.paginator
        ) {
          // this will fire loading the detected themes
          this.detectedThemesComponent.paginator.init();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onPageChange(pageChangeParams: PageChangeParams): Promise<void> {
    this.pageChangeParams = pageChangeParams;

    if (this.themeDetectors[0]) {
      await this.loadAndSetDetectedThemes();
    }
  }

  async loadAndSetDetectedThemes(): Promise<void> {
    if (!this.themeDetectors[0]) {
      return;
    }

    // remove all 'next' responses, as the number of results can change on the backend
    Object.keys(this.responsesPerPage).forEach((pageNumber) => {
      if (Number(pageNumber) > this.pageChangeParams.currentPage) {
        delete this.responsesPerPage[pageNumber];
      }
    });

    const previousPageResponse = this.responsesPerPage[
      this.pageChangeParams.currentPage - 1
    ];
    const pageTokenToLoad =
      previousPageResponse &&
      previousPageResponse.pagination &&
      previousPageResponse.pagination.next
        ? previousPageResponse.pagination.next
        : ''; // empty string will always load the first page

    this.loading = true;
    try {
      const response = await this.themeDetectorsService.getDetectedThemes(
        this.themeDetectors[0].id,
        pageTokenToLoad
      );

      this.responsesPerPage[this.pageChangeParams.currentPage] = response;

      this.loading = false;
    } catch (e) {
      console.error('Error loading/mapping detected themes: ', e);
      this.loading = false;

      this.responsesPerPage = {};
      if (this.pageChangeParams.currentPage !== 1) {
        this.detectedThemesComponent.paginator.init();
      }
    }
  }

  getLoadedPostsCount(): number {
    let count = 0;
    Object.values(this.responsesPerPage).forEach((response, i) => {
      count += response.detected_themes.length;

      // if last loaded page has next.page defined (not null) then add +1 to total resulsts loaded count just so the next page in the pagintor is visbile/clickable (can be loaded)
      const isLastPageResponse =
        i === Object.values(this.responsesPerPage).length - 1;
      if (
        isLastPageResponse &&
        response.pagination &&
        response.pagination.next
      ) {
        count += 1;
      }
    });

    return count;
  }

  reInitResults(): void {
    this.responsesPerPage = {};
    this.detectedThemesComponent.paginator.init();
  }

  openResultsModal(theme: DetectedTheme) {
    console.log('theme: ', theme);

    const filterType =
      filtersFieldDefinitions[FieldName.DiscoveredTheme].preferedFilterType;

    const modal = this.modal.open(DetectedThemeResultsComponent, {
      windowClass: 'xxl-modal'
    });
    modal.componentInstance.accountIds = this.themeDetectors[0].account_ids;
    modal.componentInstance.streamIds = this.themeDetectors[0].search_stream_ids;
    modal.componentInstance.sanitizeFilters = false;
    modal.componentInstance.activeFilters = [
      {
        field: FieldName.DiscoveredTheme,
        [filterType]: theme.id
      }
    ];
  }
}
