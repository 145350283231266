import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NotificationSettingsComponent } from './notification-settings.component';
import { AccountNotificationSettingsComponent } from './account-notification-settings/account-notification-settings.component';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';
import { FilterModule } from '../../../../../common/pipes/filter/filter.module';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    TranslateModule,
    FormsModule,
    SortByModule,
    FilterModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.notificationSettings',
          url: '/user/notifications',
          component: NotificationSettingsComponent,
          resolve: NotificationSettingsComponent.resolve
        }
      ]
    })
  ],
  declarations: [
    NotificationSettingsComponent,
    AccountNotificationSettingsComponent
  ],
  providers: [],
  entryComponents: [NotificationSettingsComponent]
})
export class NotificationSettingsModule {}
