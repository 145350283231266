import { Pipe, PipeTransform } from '@angular/core';
import { getHours, getMinutes } from 'date-fns';

@Pipe({
  name: 'timeToSelectValue'
})
export class TimeToSelectValuePipe implements PipeTransform {
  transform(value: number, measure: 'hours' | 'mins' | 'meridian'): string {
    let formattedValue = '';

    if (measure === 'hours') {
      const hours = getHours(value);
      const newHours = hours > 12 ? hours - 12 : hours;
      formattedValue = newHours <= 9 ? `0${newHours}` : `${newHours}`;
    }

    if (measure === 'mins') {
      const mins = getMinutes(value);
      formattedValue = mins <= 9 ? `0${mins}` : `${mins}`;
    }

    if (measure === 'meridian') {
      const hours = getHours(value);
      formattedValue = hours > 12 ? 'pm' : 'am';
    }

    return formattedValue;
  }
}
