import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupMessageModalComponent } from './popup-message-modal/popup-message-modal.component';

export enum PopupType {
  Alert = 'alert',
  Confirm = 'confirm',
  Prompt = 'prompt'
}

export interface PopupOptions {
  type?: PopupType;
  message?: string;
  template?: string;
  okText?: string;
  cancelText?: string;
  isError?: boolean;
  title?: string;
  subTitle?: string;
  inputRequired?: boolean;
  httpErrorMessage?: string;
  link?: {
    href: string;
    label: string;
  };
  customClass?: string;
  windowClass?: string;
  hideClose?: boolean;
  iconClass?: string;
  backdropClass?: string;
  backdrop?: boolean | 'static';
  approvalTags?: { label: string; value: string }[];
}

@Injectable()
export class PopupService {
  constructor(private ngbModal: NgbModal) {}

  alert(options: PopupOptions): Promise<any> {
    options.type = PopupType.Alert;
    return this.openModal(options).result;
  }

  confirm(
    options: PopupOptions
  ): Promise<boolean | { approved: boolean; tags: any[] }> {
    options.type = PopupType.Confirm;
    return this.openModal(options).result.catch(() => false);
  }

  prompt(
    options: PopupOptions
  ): Promise<string | { reason: string; tags: any[] } | false> {
    options.type = PopupType.Prompt;
    return this.openModal(options).result.catch(() => false);
  }

  private openModal(options: PopupOptions) {
    const modal = this.ngbModal.open(PopupMessageModalComponent, {
      backdrop: options.backdrop || false,
      windowClass: `modal-vertical ${options.windowClass}`,
      backdropClass: `${options.backdropClass}`
    });
    modal.componentInstance.options = options;
    return modal;
  }
}
