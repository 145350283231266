import './engagement-times.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Options } from 'highcharts';
import { SecondsToHumanTimePipe } from '../../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';

@Component({
  selector: 'ssi-engagement-times',
  templateUrl: './engagement-times.component.html',
  styles: []
})
export class EngagementTimesComponent implements OnInit {
  @Input() stats;
  chartConfig: Options;
  proTipOpen = false;

  constructor(private secondsToHumanTimePipe: SecondsToHumanTimePipe) {}

  ngOnInit() {
    this.chartConfig = this.getChartConfig();
  }

  getChartConfig(): any {
    const includeEmptyValues = false;

    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(moment().hours(i).format('ha'));
    }

    const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    const seriesData = [];
    Object.keys(this.stats.chartData).forEach((dayKey) => {
      const dayIndex = days.findIndex((dow) => dow.toLowerCase() === dayKey);
      if (dayIndex > -1) {
        Object.keys(this.stats.chartData[dayKey]).forEach((hourIndex) => {
          const value = Math.round(
            this.stats.chartData[dayKey][hourIndex].value
          );
          const data = this.stats.chartData[dayKey][hourIndex].data;
          if (value > 0 || includeEmptyValues) {
            const point: {
              x: number;
              y: number;
              data: any;
              value: string | number;
              color?: string;
            } = {
              x: +hourIndex,
              y: dayIndex,
              data,
              value
            };

            if (value === 0) {
              point.value = '';
              point.color = 'white';
            }

            seriesData.push(point);
          }
        });
      }
    });
    const secondsToHumanTimePipe = this.secondsToHumanTimePipe;

    return {
      global: {
        useUTC: false
      },
      chart: {
        type: 'heatmap',
        defaultSeriesType: 'areaspline',
        zoomType: 'x',
        version: 2,
        backgroundColor: null,
        spacingTop: 5,
        spacingLeft: 10,
        spacingRight: 20
      },
      series: [
        {
          data: seriesData,
          name: '',
          color: '#D41D68',
          borderWidth: 2,
          borderColor: '#F4F4FA',
          dataLabels: {
            enabled: true,
            color: 'black',
            style: {
              textOutline: false,
              fontSize: 12,
              fontWeight: 900
            }
          }
        }
      ],
      xAxis: {
        title: '',
        categories: hours,
        min: 0,
        max: hours.length - 1,
        labels: {
          style: {
            color: '#838EAB'
          }
        },
        dateTimeLabelFormats: {
          month: '%e %b',
          year: '%b'
        }
      },
      yAxis: {
        title: '',
        categories: days,
        min: 0,
        max: days.length - 1,
        reversed: true,
        labels: {
          align: 'center',
          style: {
            color: '#838EAB'
          }
        },
        gridLineWidth: 2,
        gridLineColor: '#F4F4FA'
      },
      colorAxis: {
        min: 0,
        stops: [
          [0, '#e7f7f6s'],
          [0.25, '#b2f0ff'],
          [0.5, '#72d6ee'],
          [0.75, '#14bae3'],
          [1, '#425dec']
        ]
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        /* tslint:disable */
        formatter: function () {
          if (this.point.value === 100) {
            return `
        At <b>${this.series.xAxis.categories[this.point.x]}</b> on
        <b>${
          this.series.yAxis.categories[this.point.y]
        }</b> this is your Peak Time (100%)
          with <b>${this.point.data.total}</b> inbound messages
      `;
          } else {
            return `
        At <b>${this.series.xAxis.categories[this.point.x]}</b> on <b>${
              this.series.yAxis.categories[this.point.y]
            }</b>,
        <b>${this.point.data.total}</b> incoming messages rate at <b>${
              this.point.value
            }</b>% of the peak time
      `;
          }
        }
        /* tslint:enable */
      }
    };
  }
}
