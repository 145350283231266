
<div class="quote-retweet">
  <div class="exit" (click)="activeModal.close(null)">
    <span><i class="ssi ssi-times"></i></span>
  </div>
  <div class="header">
    <i class="ssi ssi-x-logo"></i>
    <h2>Quote repost this message</h2>
  </div>
  <div class="content">
    <div
      ssiEmojiFormControlContainer
      [(formControlValue)]="post.text">
      <textarea
        class="form-control form-control-minimal"
        [(ngModel)]="post.text"
        ssiEmojiFormControl
        [placeholder]="'Type your post for @' + post.accounts[0].username + '...'"
        rows="5">
      </textarea>
      <div class="quote-retweet-modal-form-control-footer">
        <a
          target="_blank"
          [href]="post.quoteRetweetUrl"
          class="orlo-button text pull-left">
          <!-- <i class="ssi ssi-arrow-down-correct"></i> -->
          View post
        </a>
        <span
          class="character-count"
          [class.text-primary]="post.charactersRemaining > 0"
          [class.text-danger]="post.charactersRemaining < 0">
          {{ post.charactersRemaining }}
        </span>
        <ssi-emoji-form-control-trigger popoverPlacement="top">
          <a href="javascript:;">
            <i class="ssi ssi-emoji"></i>
          </a>
        </ssi-emoji-form-control-trigger>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="d-flex">
      <div
        class="flex-1 scheduler"
        [ngClass]="{'scheduler-empty': !sendAtDate}"
      >
        <i class="ssi ssi-ad-schedule"></i>
        <input
          class="form-control orlo-button schedule-button"
          type="text"
          mwlFlatpickr
          [(ngModel)]="sendAtDate"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-d H:i"
          [minDate]="now"
          [time24hr]="true"
          altFormat="M J Y, H:i"
          placeholder="Schedule repost"
        >
        <button
          *ngIf="sendAtDate"
          class="cancel-schedule"
          type="button"
          (click)="sendAtDate = null">
          <i class="fa fa-times text-danger"></i>
        </button>
      </div>
      <orlo-button
        class="pull-right"
        state="text"
        [text]="sendAtDate ? 'Schedule Repost' : 'Repost now'"
        textClass="uppercase"
        textColor="#14bae3"
        leadingIcon="ssi ssi-retweet-new"
        [disabled]="loadingTracker.active || !post.validity.isValid"
        (btnClick)="publish()"
		></orlo-button>
    </div>
  </div>
</div>
