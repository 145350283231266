import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NouisliderModule } from 'ng2-nouislider';
import { TriggersAndActionsComponent } from './triggers-and-actions.component';
import { TriggerOrActionCardComponent } from './trigger-or-action-card/trigger-or-action-card.component';
import { TriggersSentimentSelectorComponent } from './triggers-sentiment-selector/triggers-sentiment-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ListSelectModule } from '../../../../../../common/components/list-select/list-select.module';
import { OffClickModule } from '../../../../../../common/directives/off-click/off-click.module';
import { FocusOnModule } from '../../../../../../common/directives/focus-on/focus-on.module';
import { TagsInputModule } from '../../../../../../common/components/tags-input/tags-input.module';
import { TextareaCaretTrackerComponent } from './textarea-caret-tracker/textarea-caret-tracker.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TriggersIngredientTextareaDirective } from './triggers-ingredient-textarea/triggers-ingredient-textarea.directive';
import { TextInputHighlightModule } from '../../../../../../common/components/text-input-highlight';
import { Checkbox2Module } from '../../../../../../common/components/checkbox-2/checkbox-2.module';
import { PopoverModule } from '../../../../../../common/directives/popover/popover.module';
import { TriggersRangeSelectorComponent } from './triggers-range-selector/triggers-range-selector.component';
import { Nl2brModule } from '../../../../../../common/pipes/nl2br/nl2br.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ListSelectModule,
    OffClickModule,
    FocusOnModule,
    TagsInputModule,
    NgbPopoverModule.forRoot(),
    PopoverModule,
    TextInputHighlightModule,
    Checkbox2Module,
    NouisliderModule,
    Nl2brModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet.serviceSelected.triggersAndActions',
          url: '/triggers-and-actions/:mode',
          component: TriggersAndActionsComponent,
          resolve: TriggersAndActionsComponent.resolve,
          params: {
            mode: 'triggers'
          },
          data: {
            containerCssClass: 'triggers-and-actions'
          }
        }
      ]
    })
  ],
  declarations: [
    TriggersAndActionsComponent,
    TriggerOrActionCardComponent,
    TriggersSentimentSelectorComponent,
    TextareaCaretTrackerComponent,
    TriggersIngredientTextareaDirective,
    TriggersRangeSelectorComponent
  ]
})
export class TriggersAndActionsModule {}
