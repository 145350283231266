import { Component, OnInit, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { API, User } from '@ui-resources-angular';
import { ColleaguesService } from '../../../../../../angular/common/services/api';
import { PopupService } from '../../../../../../angular/common/services/popup/popup.service';
import { authUser } from '../../../../common-resolves';

export async function usersFn(api: API) {
  return api.get('settings/users').then(({ data }) => data.users);
}

@Component({
  selector: 'ssi-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: []
})
export class ManageUsersComponent implements OnInit {
  static resolve = [
    {
      token: 'users',
      resolveFn: usersFn,
      deps: [API]
    },
    authUser
  ];

  @Input() users;
  @Input() authUser;

  usersCache;
  userEditing;
  newUser;
  showDisabled: boolean;
  searchText: string;
  trackById(index, choice) {
    return choice.id;
  }

  constructor(
    private state: StateService,
    private api: API,
    private translate: TranslateService,
    private colleaguesService: ColleaguesService,
    private popup: PopupService
  ) {}

  ngOnInit() {
    this.usersCache = this.users;
  }

  editUser(user) {
    this.userEditing = Object.assign({}, user);
  }

  saveUser(user, changes = {}) {
    this.api
      .post('settings/users', Object.assign({}, user, changes))
      .then(({ data: newUser }) => {
        if (typeof user.id === 'undefined') {
          this.state.go('auth.manageUserPermissions', { userId: newUser.id });
          this.popup.alert({
            title: this.translate.instant('NOW_LETS_ADD_SOME_PERMISSIONS'),
            template: this.translate.instant(
              'YOU_WILL_NOW_NEED_TO_ADD_SOME_PERMISSIONS_TO_THE_USER_BEFORE_THEY_CAN_LOGIN'
            )
          });
        } else {
          Object.assign(user, changes);
        }

        this.colleaguesService.getAll({ refreshStore: true });
      });

    this.userEditing = false;
    this.newUser = null;
  }

  updateUserTable() {
    const searchTerm = this.searchText.toUpperCase();
    this.users = this.usersCache.filter((user) => {
      const full_name = [
        user.first_name ? user.first_name.toUpperCase() : '',
        user.last_name ? user.last_name.toUpperCase() : ''
      ].join(' ');
      return full_name.includes(searchTerm);
    });
  }
}
