<div class="targeting-options-panel">
  <div class="network-information">
    <div class="network-avatar">
      <ssi-social-network-icon
        [accountTypeId]="AccountTypeId.Facebook"
        [size]="20"
      ></ssi-social-network-icon>
    </div>
    <div class="network-body">
      <h4
        class="accordion-content-title"
        translate="FACEBOOK_TARGETING_OPTIONS"
      ></h4>
      <p
        class="accordion-content-body"
        translate="ANYONE_THAT_FALLS_WITHIN_THE_PARAMETERS_SET_WILL_BE_MORE_LIKELY_TO_SEE_THE_POSTS_OTHERS_WILL_BE_LESS_LIKELY_BUT_MAY_STILL_SEE_IT_ANYWAY_EVENTUALLY_ON_THEIR_FEED"
      ></p>
    </div>
  </div>

  <div
    class="targeting-form"
    [class.preview-toggled]="!previewToggled"
  >
    <div
      *ngFor="let input of targetingService.options?.Facebook.multiValueInputs | slice:0:2"
      class="orlo-typeahead"
    >
      <label><span [translate]="input.labelTranslateKey"></span></label>
      <ssi-outbox-typeahead
        *ngIf="input.key === 'countries'"
        (valueChange)="onFacebookCountriesChange()"
        [(value)]="input.value"
        [disabled]="input.disable || post.edit?.social_id"
        [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
        [suggestions]="input.options"
        [class.has-value]="input.value.length > 0"
      ></ssi-outbox-typeahead>

      <ssi-outbox-typeahead
        *ngIf="input.key === 'cities'"
        (filterChange)="onFacebookCitiesFilterChange($event)"
        (valueChange)="onFacebookCitiesChange()"
        [(value)]="input.value"
        [disabled]="input.disable || post.edit?.social_id"
        [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
        [suggestions]="input.options"
        [class.has-value]="input.value.length > 0"
      ></ssi-outbox-typeahead>
    </div>

    <div class="dropdown-menu-container">
      <label translate="GENDER">
      </label>
      <ssi-dropdown-select-2
        size="lg"
        name="gender"
        [disabled]="post.edit?.social_id"
        [multiple]="false"
        [filterable]="false"
        [toggleAllEnabled]="false"
        [placeholdersKeyword]="'gender'"
        [canDeselect]="true"
        [options]="socialNetworkSettings.Facebook.targeting.gender"
        (ngModelChange)="setSingleValueInput('gender', $event)"
        [(ngModel)]="targetingService.options?.Facebook.singleValueInputs.gender"
      >
      </ssi-dropdown-select-2>
    </div>

    <div class="dropdown-menu-container">
      <label translate="RELATIONSHIP_STATUS">
      </label>
      <ssi-dropdown-select-2
        size="lg"
        name="relationship_status"
        [disabled]="post.edit?.social_id"
        [multiple]="false"
        [filterable]="false"
        [toggleAllEnabled]="false"
        [placeholdersKeyword]="'relationship status'"
        [canDeselect]="true"
        [options]="socialNetworkSettings.Facebook.targeting.relationshipStatus"
        (ngModelChange)="setSingleValueInput('relationship_status', $event)"
        [(ngModel)]="targetingService.options?.Facebook.singleValueInputs.relationship_status"
      >
      </ssi-dropdown-select-2>
    </div>

    <div class="orlo-age-range dropdown-menu-container">
      <label translate="AGE_RANGE">
      </label>
      <div class="select-range">
        <ssi-dropdown-select-2
          size="lg"
          name="min_age"
          [disabled]="post.edit?.social_id"
          [multiple]="false"
          [filterable]="false"
          [toggleAllEnabled]="false"
          [canDeselect]="true"
          [options]="socialNetworkSettings.Facebook.targeting.ages.min"
          (ngModelChange)="setSingleValueInput('min_age', $event)"
          [(ngModel)]="targetingService.options?.Facebook.singleValueInputs.min_age"
        >
          <ng-template
            ssiTemplateSelector="headButtonLabel"
            let-toggle="toggle"
            let-expanded="expanded"
          >
            <div
              class="select-placeholder"
              *ngIf="!targetingService.options?.Facebook.singleValueInputs.min_age"
            >
              <span>18</span>
            </div>
            <div
              class="select-placeholder"
              *ngIf="targetingService.options?.Facebook.singleValueInputs.min_age"
            >
              <span>{{targetingService.options?.Facebook.singleValueInputs.min_age}}</span>
            </div>
          </ng-template>
          <ng-template
            ssiTemplateSelector="optionLabel"
            let-age="option"
          >
            <div class="age-value-label">
              <span>{{age}}</span>
            </div>
          </ng-template>
        </ssi-dropdown-select-2>
        <ssi-dropdown-select-2
          size="lg"
          name="max_age"
          [disabled]="post.edit?.social_id"
          [multiple]="false"
          [filterable]="false"
          [toggleAllEnabled]="false"
          [canDeselect]="true"
          [options]="getAgeGreaterThanMin()"
          (ngModelChange)="setSingleValueInput('max_age', $event)"
          [(ngModel)]="targetingService.options?.Facebook.singleValueInputs.max_age"
        >
          <ng-template
            ssiTemplateSelector="headButtonLabel"
            let-toggle="toggle"
            let-expanded="expanded"
          >
            <div
              class="select-placeholder"
              *ngIf="!targetingService.options?.Facebook.singleValueInputs.max_age"
            >
              <span>65+</span>
            </div>
            <div
              class="select-placeholder"
              *ngIf="targetingService.options?.Facebook.singleValueInputs.max_age"
            >
              <span>{{targetingService.options?.Facebook.singleValueInputs.max_age}}</span>
            </div>
          </ng-template>
          <ng-template
            ssiTemplateSelector="optionLabel"
            let-age="option"
          >
            <div class="age-value-label">
              <span>{{age}}</span>
            </div>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>
  </div>
</div>