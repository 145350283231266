<aside class="confirmation-message">
  <i class="{{ messageIcon }} message-icon"></i>

  <header
    class="message-text"
  >
    <h1
      translate="{{ primaryMessageText }}"
    ></h1>

    <h2
      translate="{{ secondaryMessageText }}"
    ></h2>
  </header>

  <nav>
    <button
        class="dismiss-button orlo-button"
        (click)="onDismiss.emit()"
    >
      Okay!
    </button>
  </nav>
</aside>
