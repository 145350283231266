import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraftComponent } from './draft.component';
import { LinkifyModule } from '../../pipes/linkify/linkify.module';
import { EscapeHtmlModule } from '../../pipes/escape-html/escape-html.module';
import { Nl2brModule } from '../../pipes/nl2br/nl2br.module';
import { EmojiTextModule } from '../../directives/emoji-text/emoji-text.module';
import { IconModule } from '../icon/icon.module';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { NotesModalModule } from '../notes-modal/notes-modal.module';
import { SocialNetworkIconModule } from '../../components/social-network-icon/social-network-icon.module';
import { HtmlToTextModule } from '../../pipes/html-to-text/html-to-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    LinkifyModule,
    EscapeHtmlModule,
    Nl2brModule,
    EmojiTextModule,
    IconModule,
    OrloComponentsModule,
    TooltipModule,
    OffClickModule,
    NotesModalModule,
    SocialNetworkIconModule,
    HtmlToTextModule
  ],
  declarations: [DraftComponent],
  exports: [DraftComponent]
})
export class DraftModule {}
