import './message-share-preview.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import moment from 'moment';

import { Activity, SharePreview } from '@ui-resources-angular';
import { ShareView, SocialService } from '../../../services/social';
import { KeyValueObject } from '../../../utils';
import { AccountTypeId } from '../../../enums';
@Component({
  selector: 'ssi-message-share-preview',
  templateUrl: './message-share-preview.component.html'
})
export class MessageSharePreviewComponent implements OnInit {
  @Input() sharePreview: SharePreview;
  @Input() activity: Activity;

  shareView: ShareView;
  createdAtDate: string;
  createdAtTime: string;

  constructor(protected socialService: SocialService) {}

  async ngOnInit() {
    try {
      this.shareView = await this.socialService.viewShare(
        this.activity.account_id,
        this.sharePreview
      );

      this.createdAtDate = moment(this.shareView.created_at).format(
        'DD MMM YYYY'
      );
      this.createdAtTime = moment(this.shareView.created_at).format('HH:mm');
    } catch (e) {
      console.log(e);
    }
  }
}
