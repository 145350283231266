import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxPostNewStatComponent } from './outbox-post-new-stat.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OutboxPostNewStatComponent],
  exports: [OutboxPostNewStatComponent],
  providers: []
})
export class OutboxPostNewStatModule {}
