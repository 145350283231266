import './campaign-box.scss';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampaignModelService } from '../campaign-model/campaign-model.service';
import moment from 'moment';

@Component({
  selector: 'ssi-campaign-box',
  templateUrl: './campaign-box.component.html',
  styles: []
})
export class CampaignBoxComponent implements OnInit {
  settingsActive = false;
  campaignEditorActive = false;
  campaignEditorMode: string;
  now = new Date();
  campaignTooltip = '';
  newCampaign = {
    name: '',
    parent_id: null,
    started_at: undefined,
    closed_at: undefined
  };

  @Input() campaign: any;
  @Input() children: string;
  @Input() stats: any;
  @Input() hasAdvertising: boolean;
  @Output() selectCampaign = new EventEmitter<string>();

  constructor(private campaignModelService: CampaignModelService) {}

  ngOnInit() {
    this.campaignTooltip = this.getTooltip();
  }

  getTooltip() {
    const startDate = moment(
      this.campaign.started_at || this.campaign.created_at
    ).format('DD/MMM/YYYY');
    const EndDate = this.campaign.closed_at
      ? moment(this.campaign.closed_at).format('DD/MMM/YYYY')
      : 'indefinitely';
    return 'Running from: ' + startDate + ' - ' + EndDate;
  }

  public showSettings() {
    this.settingsActive = !this.settingsActive;
  }

  public onSelect(id) {
    this.selectCampaign.emit(id);
  }

  public toggleCampaignEditor(mode) {
    this.campaignEditorMode = mode;
    this.campaignEditorActive = !this.campaignEditorActive;

    if (mode === 'add') {
      this.newCampaign.parent_id = this.campaign.id;
      this.newCampaign.started_at = this.now;
    } else if (mode === 'edit') {
      this.newCampaign.name = this.campaign.name;
      this.newCampaign.started_at = new Date(this.campaign.started_at);
      this.newCampaign.closed_at = new Date(this.campaign.closed_at);
    }
  }

  public addCampaign() {
    this.campaignModelService.addCampaign(this.newCampaign);
    this.toggleCampaignEditor(null);
  }

  public editCampaign() {
    this.campaignModelService.editCampaign(this.newCampaign, this.campaign.id);
    this.toggleCampaignEditor(null);
  }

  public finishCampaign() {
    this.campaignModelService.deleteCampaign(this.campaign);
    this.toggleCampaignEditor(null);
  }
}
