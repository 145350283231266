import './view.component.scss';

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AddWidgetComponent, WidgetsGridComponent } from '../common/components';
import {
  DashboardService,
  DashboardWidget,
  Dashboard
} from '../../../../common/services/api/dashboard';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { DashboardWidgetModalComponent } from '../../../../common/components/dashboard-widget-modal/dashboard-widget-modal.component';
import { Filter } from '../../../../common/components/filters';

@Component({
  selector: 'ssi-view',
  templateUrl: './view.component.html',
  styles: []
})
export class ViewComponent implements OnInit, OnDestroy {
  dashboard: Dashboard;
  dashboards: Dashboard[];

  protected destroyed$ = new Subject<void>();

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected state: StateService,
    protected modal: NgbModal,
    protected popup: PopupService,
    protected dashboardService: DashboardService,
    protected notificationService: NotificationService
  ) {}

  async ngOnInit() {
    await this.dashboardService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dashboards: Dashboard[]) => {
        console.log('subscribe dashboards: ', dashboards);
        this.dashboards = dashboards;
        this.dashboard = dashboards.find(
          (dashboard) => dashboard.id === this.state.params.id
        );
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onDashboardChange(id) {
    this.state.go(this.state.current, { id });
  }

  cancel(): void {
    this.state.go('auth.dashboard.create');
  }
}
