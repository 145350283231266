import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { BulkEditComponent } from './bulk-edit.component';
import { FormsModule } from '@angular/forms';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { FilterModule } from '../../../../common/pipes/filter/filter.module';
import { PublisherLandingModule } from '../../../../common/components/publisher/publisher-landing/publisher-landing.module';
import { DropdownSelectModule } from '../../../../common/components/dropdown-select/dropdown-select.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.bulkEdit',
          url: '/bulk-edit?posts',
          component: BulkEditComponent,
          resolve: BulkEditComponent.resolve,
          params: {
            posts: {
              array: true
            }
          }
        }
      ]
    }),
    FormsModule,
    NgbProgressbarModule,
    OrloComponentsModule,
    TooltipModule,
    FilterModule,
    DropdownSelectModule,
    PublisherLandingModule
  ],
  declarations: [BulkEditComponent],
  entryComponents: [BulkEditComponent]
})
export class BulkEditModule {}
