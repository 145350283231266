<!-- Values and labels used in Insights -->
<ul
  class="sentiment-list insights"
  *ngIf="isInsights"
>
  <li
    [ssiTooltip]="'Very Negative'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === -0.75}"
    (click)="sentiment = -0.75; changeSentiment.emit(-0.75)"
    class="sentiment-list-item sentiment-list-item-1"
  >
    <i class="ssi ssi-negative-new"></i>
  </li>
  <li
    [ssiTooltip]="'Negative'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === -0.25}"
    (click)="sentiment = -0.25; changeSentiment.emit(-0.25)"
    class="sentiment-list-item sentiment-list-item-2"
  >
    <i class="ssi ssi-semi-negative-new"></i>
  </li>
  <li
    [ssiTooltip]="'Passive'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === 0}"
    (click)="sentiment = 0; changeSentiment.emit(0)"
    class="sentiment-list-item sentiment-list-item-3"
  >
    <i class="ssi ssi-neutral-new"></i>
  </li>
  <li
    [ssiTooltip]="'Positive'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === 0.25}"
    (click)="sentiment = 0.25; changeSentiment.emit(0.25)"
    class="sentiment-list-item sentiment-list-item-4"
  >
    <i class="ssi ssi-semi-positive-new"></i>
  </li>
  <li
    [ssiTooltip]="'Very Positive'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === 0.75}"
    (click)="sentiment = 0.75; changeSentiment.emit(0.75)"
    class="sentiment-list-item sentiment-list-item-5"
  >
    <i class="ssi ssi-positive-new"></i>
  </li>
</ul>

<!-- Values and labels used in Inbox -->
<ul
  class="sentiment-list"
  *ngIf="!isInsights"
>
  <li
    [ssiTooltip]="'Negative'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === -0.75}"
    (click)="sentiment = -0.75; changeSentiment.emit(-0.75)"
    class="sentiment-list-item sentiment-list-item-1">
    <i class="ssi ssi-negative-new"></i>
  </li>
  <li
    [ssiTooltip]="'Semi Negative'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === -0.25}"
    (click)="sentiment = -0.25; changeSentiment.emit(-0.25)"
    class="sentiment-list-item sentiment-list-item-2">
    <i class="ssi ssi-semi-negative-new"></i>
  </li>
  <li
    [ssiTooltip]="'Neutral'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === 0}"
    (click)="sentiment = 0; changeSentiment.emit(0)"
    class="sentiment-list-item sentiment-list-item-3">
    <i class="ssi ssi-neutral-new"></i>
  </li>
  <li
    [ssiTooltip]="'Semi Positive'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === 0.25}"
    (click)="sentiment = 0.25; changeSentiment.emit(0.25)"
    class="sentiment-list-item sentiment-list-item-4">
    <i class="ssi ssi-semi-positive-new"></i>
  </li>
  <li
    [ssiTooltip]="'Positive'"
    [tooltipWindowClass]="'tooltip-chunky'"
    [ngClass]="{'sentiment-list-item-active': sentiment === 0.75}"
    (click)="sentiment = 0.75; changeSentiment.emit(0.75)"
    class="sentiment-list-item sentiment-list-item-5">
    <i class="ssi ssi-positive-new"></i>
  </li>
</ul>