import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ChatBotComponent } from './chat-bot.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';
import { SingleInputModalModule } from './single-input-modal/single-input-modal.module';
import { TopicModalModule } from './topic-modal/topic-modal.module';
import { ExampleModalModule } from './example-modal/example-modal.module';
import { ChatBotModalModule } from './chat-bot-modal/chat-bot-modal.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.chatbot',
          url: '/chat-bot',
          component: ChatBotComponent,
          resolve: ChatBotComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    SortByModule,
    SingleInputModalModule,
    ExampleModalModule,
    TopicModalModule,
    ChatBotModalModule,
    TooltipModule
  ],
  declarations: [ChatBotComponent],
  entryComponents: [ChatBotComponent]
})
export class ChatBotModule {}
