import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ConversationResolveComponent } from './conversation-resolve.component';

import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, FormsModule, DropdownSelect2Module, TooltipModule],
  exports: [ConversationResolveComponent],
  declarations: [ConversationResolveComponent]
})
export class ConversationResolveModule {}
