<div [hidden]="exportSuccess" class="modal-body export-conversation">
    <h4><i class="ssi ssi-verified-protection"></i> Would you like to check against tampering?</h4>
    <p>If you would like to establish whether this conversation has been tampered with in the future, you can add a Tamper Check below which provides a digital signature.</p>

    <p>This digital signature is your security code you create to go along with this specific export. Make a note of this code and keep the export we email to you safe, as we will need them both to verify if this conversation has been altered.</p>

    <form [formGroup]="form" (ngSubmit)="exportMessages()">
        <div class="export-input">
            <input type="radio" formControlName="tamper" value="yes" name="tamper" required>
            <label for="tamper">Yes, export with Tamper Check</label>
        </div>
        <div *ngIf="showTamperCode">
            <div class="export-code-input">
                <input [attr.type]="showPassword ? 'text' : 'password'" formControlName="tamperCode" placeholder="Type your security code" required minlength="5"/>
                <i class="ssi show-hide" [ngClass]="showPassword ? 'ssi-eye' : 'ssi-eyeclosed'" (click)="togglePasswordDisplay()"></i>
                <span class="export-message invalid" *ngIf="form.status === 'INVALID'"><i class="ssi ssi-character-count"></i> Your code should be 5 characters or more</span>
                <span class="export-message valid" *ngIf="form.status === 'VALID'"><i class="ssi ssi-tick"></i> Looks great!</span>
            </div>
            <p class="export-disclaimer">Orlo make no representations and give no warranties as to whether the functionality of signing the CSV file using our platform will be suitable, adequate or meet any required threshold (legal or otherwise) for any intended purpose that you will be using such feature for, regardless of whether you have made us aware of your intended potential uses of such feature prior to using it. You should make your own enquiries to satisfy yourself that this feature and any technology used in this feature will provide the functionality that you require for any purpose for which you use this feature. By providing a digital signature on export of a file you acknowledge this statement and agree to be bound by it.</p>
        </div>  
        <div class="export-input">
            <input type="radio" formControlName="tamper" value="no" name="tamper" required>
            <label for="tamper">No need for a tamper check</label>
        </div>
        <div class="export-buttons">
            <button class="btn btn-default export-cancel" type="button" (click)="activeModal.close()">Cancel Export</button>
            <button class="btn btn-primary export-accept" type="submit" [disabled]="!form.valid">Export Messages</button>
        </div>
    </form>
</div>
<div *ngIf="exportSuccess" class="modal-body export-conversation confirmation">
    <div><i class="ssi ssi-microcopy-fireworks export-fireworks"></i></div>
    <h4>Export Success</h4>
    <p>Your Conversation Export has been sent to the email address registered to this account.  You have 24 hours to download this file</p>
    <div *ngIf="showCodeConfirmation">
        <p>Remember to make a note of your Security Code:</p>
        <div class="export-code-confirmation">{{form.value.tamperCode}}</div>
    </div>
    <div class="export-buttons">
        <button class="btn btn-primary" type="button" (click)="activeModal.close()">Okay thanks!</button>
    </div>
</div>