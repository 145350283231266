import './triggers-sentiment-selector.component.scss';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const TRIGGERS_SENTIMENT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TriggersSentimentSelectorComponent), //tslint:disable-line
  multi: true
};

@Component({
  selector: 'ssi-triggers-sentiment-selector',
  templateUrl: './triggers-sentiment-selector.component.html',
  providers: [TRIGGERS_SENTIMENT_CONTROL_VALUE_ACCESSOR]
})
export class TriggersSentimentSelectorComponent
  implements ControlValueAccessor {
  @Input() sentiment;

  selectedValues = {};

  private onChangeCallback: (_: any) => void = () => {};

  writeValue(selectedSentiments: string[]): void {
    this.selectedValues = {};
    if (selectedSentiments) {
      selectedSentiments.forEach((key) => {
        this.selectedValues[key] = true;
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  toggleValue(option: any) {
    this.selectedValues[option.value] = !this.selectedValues[option.value];
    const newModelValue: string[] = Object.entries(this.selectedValues)
      .filter(([, isSelected]) => isSelected)
      .map(([key]) => key);
    this.writeValue(newModelValue);
    this.onChangeCallback(newModelValue);
  }

  trackByValue(index, sentiment) {
    return sentiment.value;
  }
}
