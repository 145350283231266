import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InboxActivityModalComponent } from './inbox-activity-modal.component';
import { InboxActivityModule } from '../inbox-activity.module';

@NgModule({
  imports: [CommonModule, InboxActivityModule],
  exports: [InboxActivityModalComponent],
  declarations: [InboxActivityModalComponent],
  entryComponents: [InboxActivityModalComponent]
})
export class InboxActivityModalModule {}
