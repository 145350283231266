import './countdown.component.scss';
import {
  Input,
  Component,
  OnInit,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'ssi-countdown',
  templateUrl: './countdown.component.html'
})

// This could possibly be replaced with directive, once everything is migrated to ng2
export class CountdownComponent implements OnInit, OnChanges {
  @Input() startDate: any;
  @Input() daysToCountdown?: number = 1;

  days: number;
  hours: number;
  minutes: number;
  seconds: number;

  remainingDetails: string;

  invalid: boolean = false;
  countDownDate: any;

  @Output() onSessionValidityChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  countdown() {
    const dayInMiliseconds = 86400000;
    const hourInMiliseconds = 3600000;
    const minuteInMiliseconds = 60000;
    const totalValidityPeriod = this.daysToCountdown * dayInMiliseconds;

    this.countDownDate =
      new Date(this.startDate).getTime() + totalValidityPeriod;
    const startCountdown = setInterval(() => {
      const now = new Date().getTime();

      // Find the distance between now and the countdown date
      const distance = this.countDownDate - now;
      this.days = Math.floor(
        (distance % totalValidityPeriod) / dayInMiliseconds
      );

      this.hours = Math.floor(
        (distance % dayInMiliseconds) / hourInMiliseconds
      );
      this.minutes = Math.floor(
        (distance % hourInMiliseconds) / minuteInMiliseconds
      );

      this.remainingDetails = `${this.days} days ${this.hours} hrs ${
        this.minutes
      } minutes`;

      if (distance < 0) {
        clearInterval(startCountdown);
        this.onSessionValidityChange.emit(true);
        this.invalid = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onSessionValidityChange.emit(false);
    this.countdown();
  }
}
