import './insights.component.scss';

import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { CompanyService } from '../../../common/services/company/company.service';

@Component({
  selector: 'ssi-insights',
  templateUrl: './insights.component.html',
  styles: []
})
export class InsightsComponent implements OnInit {
  orloInsightsEnabled = false;
  constructor(private company: CompanyService, private state: StateService) {}

  async ngOnInit() {
    this.orloInsightsEnabled = await this.company.hasFeatureAccess(
      'ORLO_INSIGHTS'
    );

    if (!this.orloInsightsEnabled) {
      this.state.go('auth.insights.notPurchased');
    }
  }
}
