import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxDashboardComponent } from './inbox-dashboard.component';
import { TranslateModule } from '@ngx-translate/core';
import { SocialPushModeMenuModule } from '../../../../common/components/social-push-mode-menu/social-push-mode-menu.module';
import { ConversationResolutionConfirmationModule } from './conversation-resolution-confirmation/conversation-resolution-confirmation.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { ClockModule } from '../../../../common/components/clock/clock.module';
import { FallbackImageModule } from '../../../../common/directives/fallback-image/fallback-image.module';
import { SecondsToHumanTimeModule } from '../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterModule } from '../../../../common/pipes/filter/filter.module';
import { UIRouterModule } from '@uirouter/angular';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SocialPushModeMenuModule,
    ConversationResolutionConfirmationModule,
    TooltipModule,
    ClockModule,
    FallbackImageModule,
    SecondsToHumanTimeModule,
    NgbDropdownModule.forRoot(),
    FilterModule,
    UIRouterModule
  ],
  declarations: [InboxDashboardComponent],
  exports: [InboxDashboardComponent]
})
export class InboxDashboardModule {}
