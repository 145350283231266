import './cl-context-menu.component.scss';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-cl-context-menu',
  templateUrl: './cl-context-menu.component.html',
  styles: []
})
export class ClContextMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  delete(message: string): void {
    window.alert(message);
  }
}
