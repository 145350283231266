<div [class.post-box-targeting-open]="validation.outbox" class="validate-post-box post-box panel">
  <div *ngIf="validationStatus" class="post-box-validation" [ngClass]="validationStatus">
    <p *ngIf="validationStatus === 'approved'">You have approved this {{type}}!</p>
    <p *ngIf="validationStatus === 'disapproved'">You have disapproved this {{type}}!</p>
  </div>
  <div *ngIf="postWithBoost && validationStatus === 'approved'" class="post-box-validation boosted">
    <div>{{user.first_name}} has also boosted this post, shall we go ahead and boost it for you?</div>
    <button class="btn btn-style-1 btn-danger" (click)="validateAdvert(false)">
      Disapprove boost
    </button>
    <button class="btn btn-style-1 btn-primary" (click)="validateAdvert(true)">
      Go ahead &amp; boost!
    </button>
    <div><a (click)="toggleBoost()">Review audience</a></div>
  </div>
  <div class="panel-top post-box-wrap">
    <div class="panel-header post-box-header">
      <ssi-validate-posts-actions
        [validation]="validation"
        [scheduledDate]="scheduledDate"
        [campaignName]="parentCampaign?.name"
        [account]="account">
      </ssi-validate-posts-actions>
    </div>
    <div class="panel-body post-box-panel-body">
      <ssi-validate-posts-content
        [validation]="validation"
        [user]="user"
        [selected]="selected"
        [selectMode]="selectMode"
        (addToSelected)="addToSelected.emit(validation)">
      </ssi-validate-posts-content>
    </div>
    <ssi-validate-posts-gallery
      *ngIf="validation.outbox && validation.outbox.outbox_files.length > 0 || validation.outbox_files && validation.outbox_files.length > 0"
      [validation]="validation">
    </ssi-validate-posts-gallery>
  </div>

  <ssi-validate-posts-boost
    *ngIf="validation.outbox"
    [validation]="validation"
    [campaignName]="parentCampaign?.name"
    (validateAdvert)="validateAdvert($event)">
  </ssi-validate-posts-boost>

  <div *ngIf="!validation.outbox" [ngClass]="{'post-box-footer-selected': getSelectedStatus()}" class="panel-footer post-box-footer">
    <button
      class="btn btn-style-1 btn-danger"
      [disabled]="getSelectedStatus()"
      (click)="validation.outbox ? validateAdvert(false) : validatePost(false)">
      Disapprove {{validation.outbox ? "boost" : "post"}}
    </button>
    <button
      class="btn btn-style-1 btn-primary"
      [disabled]="getSelectedStatus()"
      (click)="validation.outbox ? validateAdvert(true) : validatePost(true)">
      {{validation.outbox ? "Go ahead and boost" : "Approve post"}}
    </button>
  </div>
  
  <ssi-validate-posts-notes
    [validation]="validation"
    [user]="user"
    [teams]="teams"
    [colleagues]="colleagues">
  </ssi-validate-posts-notes>
</div>
