<div class="emoji-container">
  <div class="emoji-input">
    <input
      [(ngModel)]="searchText"
      [ssiFocusOn]="true"
      placeholder="Search emojis"
      class="form-control input-sm form-control-no-border"
      type="text"
    >
    <i class="ssi ssi-correct-search"></i>
  </div>
  
  <div *ngIf="!filteredEmojis" class="loading"></div>
  
  <ng-container *ngIf="filteredEmojis">
    <ng-container *ngIf="frequentlyUsedEmojis.length">
      <p>Frequently used</p>
      <div class="freq-emoji-list">
        <ng-container *ngFor="let emoji of frequentlyUsedEmojis; trackBy: trackByIndex">
          <a
            class="emoji-icon"
            title="{{emoji.name}}"
            (click)="selectEmoji(emoji)"
            [ssiEmojiText]="emoji.char"
            [spriteSize]="'24'"
            [sprites]="true"
          ></a>
        </ng-container>
      </div>
    </ng-container>

    <p>{{currentCategory.label}}</p>
    <div
      class="emoji-list"
      ssiVirtualScroll
      [items]="filteredEmojiRows"
      [bufferAmount]="0"
      [getItemHeight]="getItemHeight"
      (viewPortItemsUpdated)="viewPortItemsUpdated($event)"
    >
      <div
        *ngFor="let emojiRow of viewPortEmojiRows"
        class="emojis-row"
      >
        <div
          *ngFor="let emoji of emojiRow; trackBy: trackByShortname"
          class="emoji-icon-container"
        >
          <a
            class="emoji-icon"
            title="{{emoji.name}}"
            (click)="selectEmoji(emoji)"
            [ssiEmojiText]="emoji.char"
            [spriteSize]="'24'"
            [sprites]="true"
          ></a>
        </div>
      </div>
    </div>
  </ng-container>
  
  <div class="emoji-categories">
    <a
      *ngFor="let category of categories"
      (click)="changeCategory(category)"
      [class.active]="currentCategory === category"
      [ssiTooltip]="category.label"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-chunky"
    >
      <i [ngClass]="category.icon"></i>
    </a>
  </div>
</div>