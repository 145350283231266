import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WaveDotsComponent } from './wave-dots.component';

@NgModule({
  declarations: [WaveDotsComponent],
  imports: [CommonModule],
  exports: [WaveDotsComponent],
  entryComponents: [WaveDotsComponent]
})
export class WaveDotsModule {}
