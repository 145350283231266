import { mapToIterable } from '../utils';

export interface Industry {
  key: string;
  label: string;
}

export interface Industries {
  Manufacturing: Industry;
  'Professional, scientific & technical': Industry;
  'Transport & storage (inc postal)': Industry;
  Wholesale: Industry;
  'Public administration & defence': Industry;
  Construction: Industry;
  'Mining, quarrying & utilities': Industry;
  'Agriculture, forestry & fishing': Industry;
  Property: Industry;
  'Information & communication': Industry;
  'Retail, Hospitality and Leisure': Industry;
  'Business administration and support services': Industry;
  'Arts, entertainment, recreation and other services': Industry;
  'Finance & insurance': Industry;
  Health: Industry;
  Education: Industry;
}

export const industries: Industries = {
  Manufacturing: {
    key: 'Manufacturing',
    label: 'Manufacturing'
  },
  'Professional, scientific & technical': {
    key: 'Professional, scientific & technical',
    label: 'Professional, scientific & technical'
  },
  'Transport & storage (inc postal)': {
    key: 'Transport & storage (inc postal)',
    label: 'Transport & storage (inc postal)'
  },
  Wholesale: {
    key: 'Wholesale',
    label: 'Wholesale'
  },
  'Public administration & defence': {
    key: 'Public administration & defence',
    label: 'Public administration & defence'
  },
  Construction: {
    key: 'Construction',
    label: 'Construction'
  },
  'Mining, quarrying & utilities': {
    key: 'Mining, quarrying & utilities',
    label: 'Mining, quarrying & utilities'
  },
  'Agriculture, forestry & fishing': {
    key: 'Agriculture, forestry & fishing',
    label: 'Agriculture, forestry & fishing'
  },
  Property: {
    key: 'Property',
    label: 'Property'
  },
  'Information & communication': {
    key: 'Information & communication',
    label: 'Information & communication'
  },
  'Retail, Hospitality and Leisure': {
    key: 'Retail, Hospitality and Leisure',
    label: 'Retail, Hospitality and Leisure'
  },
  'Business administration and support services': {
    key: 'Business administration and support services',
    label: 'Business administration and support services'
  },
  'Arts, entertainment, recreation and other services': {
    key: 'Arts, entertainment, recreation and other services',
    label: 'Arts, entertainment, recreation and other services'
  },
  'Finance & insurance': {
    key: 'Finance & insurance',
    label: 'Finance & insurance'
  },
  Health: {
    key: 'Health',
    label: 'Health'
  },
  Education: {
    key: 'Education',
    label: 'Education'
  }
};

export const industriesIterable: Industry[] = mapToIterable(industries);
export const industriesIterableSortedAZ: Industry[] = mapToIterable(
  industries
).sort((a, b) => a.label.localeCompare(b.label));
