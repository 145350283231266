import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { MessageComponent } from './message.component';
import { OrloComponentsModule } from 'orlo-components';
import { MessageLinkPreviewComponent } from '../message-link-preview/message-link-preview.component';
import { MessageSharePreviewModule } from '../message-share-preview/message-share-preview.module';
import { MessageFooterModule } from '../message-footer/message-footer.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { LinkifyModule } from '../../../../common/pipes/linkify/linkify.module';
import { MessageAttachmentsModule } from '../message-attachments/message-attachments.module';
import { MessageActionsModule } from '../message-actions/message-actions.module';
import { MessageActionsInlineModule } from '../message-actions-inline/message-actions-inline.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    OverlayModule,
    OrloComponentsModule,
    TooltipModule,
    LinkifyModule,
    MessageAttachmentsModule,
    MessageActionsModule,
    MessageFooterModule,
    MessageSharePreviewModule,
    MessageActionsInlineModule
  ],
  declarations: [MessageComponent, MessageLinkPreviewComponent],
  exports: [MessageComponent, MessageLinkPreviewComponent]
})
export class MessageModule {}
