import { Injectable } from '@angular/core';

import countries from 'world-countries/countries.json';

@Injectable()
export class CountryFinderService {
  getCountryFromCode(code) {
    try {
      if (!(!!countries && Array.isArray(countries))) {
        throw new Error(
          `Value for 'query helper presets' not in expected format.`
        );
      }

      return countries.find(
        (country) => country.cca2.toLowerCase() === code.toLowerCase()
      );
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}
