import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClAccordionComponent } from './cl-accordion.component';
import { AccordionModule } from '../../../../common/components/accordion/accordion.module';
import { ToggleSwitch2Module } from '../../../../common/components/toggle-switch-2/toggle-switch-2.module';

@NgModule({
  imports: [CommonModule, AccordionModule, ToggleSwitch2Module],
  declarations: [ClAccordionComponent],
  exports: [ClAccordionComponent],
  entryComponents: [ClAccordionComponent]
})
export class ClAccordionModule {}
