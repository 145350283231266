import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxPinnedActivitiesComponent } from './inbox-pinned-activities.component';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { EmojiTextModule } from '../../directives/emoji-text/emoji-text.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    OrloComponentsModule,
    TooltipModule,
    OffClickModule,
    FallbackImageModule,
    EmojiTextModule
  ],
  declarations: [InboxPinnedActivitiesComponent],
  exports: [InboxPinnedActivitiesComponent]
})
export class InboxPinnedActivitiesModule {}
