import './list-select2.component.scss';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  AfterContentInit,
  ContentChildren,
  QueryList,
  forwardRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  DoCheck
} from '@angular/core';
import { animate, style, trigger, transition } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TemplateSelectorDirective } from '../../directives/template-selector/template-selector.directive';
import { KeyValueObject } from '../../utils';

export class DDTemplateSelectors {
  static readonly optionLabel = 'optionLabel';
  static readonly optionIcon = 'optionIcon';
  static readonly expandedContent = 'expandedContent';
}

export interface Option {
  id?: string;
  key?: string;

  name?: string;
  label?: string;
  displayName?: string;
}

@Component({
  selector: 'ssi-list-select2',
  templateUrl: './list-select2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListSelect2Component), //tslint:disable-line
      multi: true
    }
  ],
  styles: []
})
export class ListSelect2Component
  implements
    DoCheck,
    OnChanges,
    OnInit,
    AfterContentInit,
    AfterViewInit,
    ControlValueAccessor {
  @Input() options: Option[] = [];
  @Input() filteredOptions: Option[] = [];
  @Input() templateRefs: any = {};
  @Input() multiple = false;
  @Input() toggleAllEnabled = true;
  @Input() filterable = false;
  // @Input() disabled = false;
  @Input() filterPlaceholder = 'Search...';
  @Input() filterTerm = '';
  @Input() selectedMin = 0; // minimum number of items selected (multiple mode)
  @Input() selectedMax; // maximum number of items selected (multiple mode)
  @Input() toggleAllLabel = 'Select all';
  @Input() getOptionLabel?: (option: any) => string;
  @Input() getTooltipContent?: (option: Option) => string;

  @Output() onOptionToggle = new EventEmitter<any>();

  @ContentChildren(TemplateSelectorDirective)
  templateList: QueryList<TemplateSelectorDirective>;

  DDTemplateSelectors = DDTemplateSelectors;
  modelValue: Option | Option[] | any; // 'any' so TS is happy
  toggleAllChecked = false;

  constructor(
    protected elementRef: ElementRef,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  ngDoCheck() {
    this.toggleAllChecked = this.allOptionsSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && Array.isArray(this.options)) {
      this.filter(this.filterTerm);

      if (this.multiple) {
        this.modelValue = this.modelValue || [];
        if (!Array.isArray(this.modelValue)) {
          throw new Error(
            'Multi select variant requires ngModel to be an array.'
          );
        }
      }
    }
  }

  ngOnInit() {}

  ngAfterContentInit() {
    this.collectTemplateRefs();
  }

  ngAfterViewInit(): void {}

  findOptionLabel(option: Option): string {
    return (
      (option && (option.name || option.label || option.displayName)) || ''
    );
  }

  optionSelected(option: Option): boolean {
    return this.multiple
      ? Array.isArray(this.modelValue) && this.modelValue.includes(option)
      : this.modelValue === option;
  }

  allOptionsSelected(): boolean {
    return (
      Array.isArray(this.options) &&
      !this.options.some((o) => !this.optionSelected(o))
    );
  }

  noOptionSelected(): boolean {
    return !this.options.some((o) => this.optionSelected(o));
  }

  toggleOption(option: Option): void {
    if (this.multiple) {
      if (this.optionSelected(option)) {
        if (this.selectedMin && this.modelValue.length === this.selectedMin) {
          console.log(`At least ${this.selectedMin} needs to be selected!`);
          return;
        }
        this.modelValue.splice(this.modelValue.indexOf(option), 1);
        this.toggleAllChecked = false;
      } else {
        if (this.selectedMax && this.modelValue.length === this.selectedMax) {
          console.log(`No more than ${this.selectedMax} to be selected!`);
          return;
        }
        this.modelValue.push(option);
        if (this.allOptionsSelected()) {
          this.toggleAllChecked = true;
        }
      }
    } else {
      this.modelValue = option;
    }

    this.onChangeCallback(this.modelValue);
    this.onOptionToggle.emit(option);
  }

  toggleAllOptions(checked: boolean): void {
    if (!this.multiple) {
      return;
    }

    if (this.toggleAllChecked) {
      this.options.forEach((o) => {
        if (this.modelValue.indexOf(o) === -1) {
          this.modelValue.push(o);
        }
      });
    } else {
      this.options.forEach((o) => {
        if (this.modelValue.indexOf(o) > -1) {
          this.modelValue.splice(this.modelValue.indexOf(o), 1);
        }
      });
    }

    this.onChangeCallback(this.modelValue);
  }

  writeValue(value: any): void {
    this.modelValue = value;
  }

  private onChangeCallback: (_: any) => void = () => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  private onTouchCallback: () => void = () => {};
  registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  filter(filterTerm: string): void {
    if (!Array.isArray(this.options)) {
      this.filteredOptions = [];
      return;
    }

    this.filteredOptions = this.options.filter((option) => {
      return Object.values(option).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(filterTerm.toLowerCase())
      );
    });
  }

  optionsExpandable(): boolean {
    return !!this.templateRefs[this.DDTemplateSelectors.expandedContent];
  }

  collectTemplateRefs(): void {
    this.templateList.toArray().forEach((t: TemplateSelectorDirective) => {
      if (!this.DDTemplateSelectors[t.selector]) {
        console.error(
          `Unknown template selector: ${
            t.selector
          }. Possible value/s: ${Object.values(this.DDTemplateSelectors).join(
            ', '
          )}.`
        );
        return;
      }

      this.templateRefs[t.selector] = t.templateRef;
    });
  }
}
