import './insights-not-purchased.component.scss';
import { Component } from '@angular/core';

@Component({
  selector: 'ssi-insights-not-purchased',
  templateUrl: './insights-not-purchased.component.html',
  styles: []
})
export class InsightsNotPurchasedComponent {
  constructor() {}
}
