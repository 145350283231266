import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'ssi-pie-chart',
  templateUrl: './pie-chart.component.html'
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() innerSize: string;
  @Input() name: string;
  @Input() width: number;
  @Input() seriesData: any[];

  chartConfig: any = {};

  static drawPlaceholderCircle = (chart) => {
    const { cx, cy, radius } = PieChartComponent.getPlaceholderCircleCoords(
      chart
    );
    chart.pieOutline = chart.renderer
      .circle(cx, cy, radius)
      .attr({
        fill: '#ddd',
        stroke: 'black',
        'stroke-width': 1
      })
      .add();
  };

  static getPlaceholderCircleCoords = (chart) => {
    const radius = Math.min(chart.plotWidth / 2, chart.plotHeight / 2) * 0.9;
    const cy = chart.plotHeight / 2 + chart.plotTop;
    const cx = chart.plotWidth / 2 + chart.plotLeft;
    return { radius, cx, cy };
  };

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.seriesData) {
      this.chartConfig = {
        chart: {
          width: this.width,
          height: this.width,
          events: {
            load() {
              const chart = this;
              if (!chart.hasData()) {
                PieChartComponent.drawPlaceholderCircle(chart);
              }
            },
            redraw() {
              const chart = this;
              if (chart.pieOutline && chart.pieOutline.element) {
                if (chart.hasData()) {
                  chart.pieOutline.destroy();
                } else {
                  const {
                    cx,
                    cy,
                    radius
                  } = PieChartComponent.getPlaceholderCircleCoords(chart);
                  chart.pieOutline.attr({
                    cx,
                    cy,
                    r: radius
                  });
                }
              } else if (!chart.hasData()) {
                PieChartComponent.drawPlaceholderCircle(chart);
              }
            }
          }
        },
        credits: { enabled: false },
        colors: [
          '#dd7722',
          '#2288cc',
          '#dd3322',
          '#22aa99',
          '#bb4488',
          '#ddaa00',
          '#6655cc',
          '#99aa00'
        ],
        plotOptions: {
          pie: { dataLabels: { enabled: true } },
          series: { dataLabels: { distance: 0 } }
        },
        series: [
          {
            innerSize: this.innerSize || '50%',
            type: 'pie',
            name: this.name,
            data: this.seriesData
          }
        ],
        title: {
          text: null
        }
      };
    }
  }
}
