import { appInjector } from '../../../../../../angular/app-injector';
import { Account, AccountModel } from '@ui-resources-angular';
import {
  MonitoringStream,
  MonitoringStreamsService
} from '../../monitoring-streams';

export interface ThemeDetectorFormValue {
  minClusterSize: number;
  queryWindowHours: number;
  lifetimeHours: number;
  accounts: Account[];
  streams: MonitoringStream[];
  notificationEmails: string; // comma separated list of emails
}

export class ThemeDetector {
  id?: number; // not present before created on the backend
  min_cluster_size: number;
  query_window_hours: number;
  lifetime_hours: number;
  account_ids: number[];
  search_stream_ids: string[];
  notification_emails: string[];

  constructor(props: any = {}) {
    this.id = props.id;
    this.min_cluster_size = props.min_cluster_size;
    this.query_window_hours = props.query_window_hours;
    this.lifetime_hours = props.lifetime_hours;
    this.account_ids = props.account_ids;
    this.search_stream_ids = props.search_stream_ids;
    this.notification_emails = props.notification_emails;
  }

  toFormValue(): ThemeDetectorFormValue {
    const accountModel = appInjector().get(AccountModel);
    const monitoringStreamsService = appInjector().get(
      MonitoringStreamsService
    );

    return {
      minClusterSize: this.min_cluster_size,
      queryWindowHours: this.query_window_hours,
      lifetimeHours: this.lifetime_hours,
      accounts: this.account_ids
        .map((id) => accountModel.get(id))
        .filter((a) => !!a),
      streams: this.search_stream_ids
        .map((id) => monitoringStreamsService.store.find(id))
        .filter((s) => !!s),
      notificationEmails: this.notification_emails.join(', ')
    };
  }

  static fromFormValue(formValue: ThemeDetectorFormValue): ThemeDetector {
    return new ThemeDetector({
      min_cluster_size: formValue.minClusterSize,
      query_window_hours: formValue.queryWindowHours,
      lifetime_hours: formValue.lifetimeHours,
      account_ids: formValue.accounts.map((a) => Number(a.id)),
      search_stream_ids: formValue.streams.map((s) => s.id),
      notification_emails: formValue.notificationEmails
        .split(',')
        .map((emailAddress) => emailAddress.trim())
        .filter((strValue) => !!strValue)
    });
  }
}
