<div
    class="post-box-gallery"
    [ssiEnlargeImage]="media"
>
    <span
        *ngIf="mediaStack"
        class="post-box-gallery-badge"
    >{{validation.outbox ? validation.outbox.outbox_files.length : validation.outbox_files.length}}</span>
    <img
        [alt]="validation.outbox ? validation.outbox.outbox_files[0].alt_text : validation.outbox_files[0].alt_text || ''"
        [src]="validation.outbox ? validation.outbox.outbox_files[0].public_url : (validation.outbox_files[0].public_url ? validation.outbox_files[0].public_url : validation.outbox_files[0].url)"
        class="post-box-gallery-frame"
    >
</div>