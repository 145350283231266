import './inbox-crm-external-integration-person.component.scss';
import { Component, OnChanges, Input } from '@angular/core';
import {
  Activity,
  CrmExternalIntegrationPerson,
  CrmPerson
} from '@ui-resources-angular';

@Component({
  selector: 'ssi-inbox-crm-external-integration-person',
  templateUrl: './inbox-crm-external-integration-person.component.html'
})
export class InboxCrmExternalIntegrationPersonComponent implements OnChanges {
  @Input() externalIntegrationPerson: CrmExternalIntegrationPerson;
  @Input() activity: Activity;
  @Input() crmPerson: CrmPerson;

  activitiesShown = false;
  iframeUrl;
  iframeLoaded = false;

  constructor() {}

  ngOnChanges(changes) {
    if (changes.externalIntegrationPerson) {
      this.hideActivities();
      this.iframeLoaded = false;
      if (
        this.externalIntegrationPerson &&
        this.externalIntegrationPerson.potentialAction
      ) {
        const url = this.externalIntegrationPerson.potentialAction.target
          .urlTemplate;

        const messageUrlParams = {
          type: 'activity',
          activity_id: this.activity.id
        };

        const params = {
          message_sentiment:
            this.activity.sentiment && this.activity.sentiment.key,
          message_socialsignin_url: `${
            location.origin
          }/#/external?params=${encodeURIComponent(
            JSON.stringify(messageUrlParams)
          )}`,
          message_author_social_network_id: this.activity.author.id,
          message_language_code: this.activity.properties.language,
          message_socialsignin_id: this.activity.id,
          message_social_network_id: this.activity.interaction.id,
          message_social_network_type: this.activity.interaction.social_type,
          message_author_socialsignin_id: this.crmPerson.uuid
        };

        const paramsString = Object.entries(params)
          .map(([name, value]) => {
            return `${name}=${encodeURIComponent(value)}`;
          })
          .join('&');

        // this.iframeUrl = this.$sce.trustAsResourceUrl(`${url}&${paramsString}`);
        this.iframeUrl = `${url}&${paramsString}`;
      } else {
        this.iframeUrl = null;
      }
    }
  }

  showActivities() {
    this.activitiesShown = true;
  }

  hideActivities() {
    this.activitiesShown = false;
  }
}
