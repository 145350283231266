<div
  class="sentiment-progressbar"
  [ngClass]="{'sentiment-bar-spacing-before': !spacingBefore, 'sentiment-progressbar-thin': thin}">
  <div
    class="sentiment-bar sentiment-bar-negative"
    [style.width.%]="(((profile.inbox_stats.negative_sentiment + profile.inbox_stats.semi_negative_sentiment) / profile.inbox_stats.total) * 100).toFixed(0)"
    [ssiTooltip]="'_AMOUNT__PREVIOUS_NEGATIVE_ENGAGEMENTS' | translate:{amount: profile.inbox_stats.negative_sentiment + profile.inbox_stats.semi_negative_sentiment}">
  </div>
  <div
    class="sentiment-bar sentiment-bar-neutral"
    [style.width.%]="(((profile.inbox_stats.neutral_sentiment) / profile.inbox_stats.total) * 100).toFixed(0)"
    [ssiTooltip]="'_AMOUNT__PREVIOUS_NEUTRAL_ENGAGEMENTS' | translate:{amount: profile.inbox_stats.neutral_sentiment}">
  </div>
  <div
    class="sentiment-bar sentiment-bar-positive"
    [style.width.%]="(((profile.inbox_stats.positive_sentiment + profile.inbox_stats.semi_positive_sentiment) / profile.inbox_stats.total) * 100).toFixed(0)"
    [ssiTooltip]="'_AMOUNT__PREVIOUS_POSITIVE_ENGAGEMENTS' | translate:{amount: profile.inbox_stats.positive_sentiment + profile.inbox_stats.semi_positive_sentiment}">
  </div>
</div>
