<div class="login-activity settings-row grey">
  <div class="flex-grid container">
    <div class="col">
      <div class="login-table">
        <table class="table orlo-table">
          <thead>
            <tr>
              <th translate="IP_ADDRESS"></th>
              <th translate="BROWSER"></th>
              <th translate="LOGGED_IN"></th>
              <th translate="DUE_TO_EXPIRE"></th>
              <th>Sessions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let login of logins">
              <td>{{ login.ip_address }}</td>
              <td class="small">{{ login.user_agent }}</td>
              <td>{{ login.created_at| date:'MMM d HH:mm' }}</td>
              <td>{{ login.expires_at| date:'MMM d HH:mm' }}</td>
              <td>
                <orlo-button *ngIf="!login.current_session" [disabled]="login.deleting" [state]="'outline danger'" [text]="'Logout'" (btnClick)="logoutSession(login)"></orlo-button>
                <span *ngIf="login.current_session">
                  <span translate="YOUR_SESSION"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>