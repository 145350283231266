<div class="response-times-section">
  <div class="section-chart-wrap">
    <ssi-highchart [config]="chartData"></ssi-highchart>
    <ul class="section-chart-meta" *ngIf="chartNetworks.length > 0">
      <li class="section-chart-meta-item" *ngFor="let network of chartNetworks">
        <span class="section-chart-meta-icon">
          <i [ngClass]="network?.socialNetwork.icon.web"></i>
        </span>
        <div class="section-chart-meta-data">
          <p>{{network?.socialNetwork.accountTypeGroupName || network?.socialNetwork.accountTypeLabel}}</p>
          <p>{{network?.accounts.length}} {{network?.accounts.length === '1' ? 'Account' : 'Accounts'}}</p>
        </div>
        <div
          *ngIf="activityStats.previous"
          class="section-chart-meta-data-diff"
          [ngClass]="{'section-chart-meta-data-diff-less': (activityStats.current.inbound.average_response_time.by_account_type[network?.socialNetwork.publishKey] - activityStats.previous.inbound.average_response_time.by_account_type [network?.socialNetwork.publishKey]) < 0}"
        >
          <p class="section-chart-meta-data-diff-none" *ngIf="!activityStats.previous.inbound.average_response_time.by_account_type[network?.socialNetwork.publishKey]">
            No previous data
          </p>
          <i class="ssi ssi-line-arrow-small-up section-chart-meta-data-diff-icon"></i>
          <p
            *ngIf="activityStats.previous.inbound.average_response_time.by_account_type[network?.socialNetwork.publishKey]"
            class="section-chart-meta-data-diff-stat"
            [innerHTML]="
            (activityStats.current.inbound.average_response_time.by_account_type[network?.socialNetwork.publishKey] -
            activityStats.previous.inbound.average_response_time.by_account_type[network?.socialNetwork.publishKey])
            | abs | secondsToHumanTime:true:true:{secondLabel: 's', minuteLabel: 'm', dayLabel: 'd', hourLabel: 'h'}"
          ></p>
      </div>
      </li>
    </ul>
  </div>
</div>