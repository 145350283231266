import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostSchedulerComponent } from './post-scheduler.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
  CalendarCommonModule,
  CalendarDateFormatter
} from 'angular-calendar/modules/common';
import { CalendarMonthModule } from 'angular-calendar/modules/month';
import { CalendarShortDateFormatterService } from '../publisher/publisher-schedule/publisher-schedule-date-picker/calendar-short-date-formatter/calendar-short-date-formatter.service';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { SocialNetworkIconModule } from '../social-network-icon/social-network-icon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CalendarCommonModule.forRoot({
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CalendarShortDateFormatterService
      }
    }),
    CalendarMonthModule,
    OrloComponentsModule,
    TooltipModule,
    OverlayModule,
    SocialNetworkIconModule
  ],
  declarations: [PostSchedulerComponent],
  exports: [PostSchedulerComponent]
})
export class PostSchedulerModule {}
