import './widget-top-interests-industries.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Filter } from '../../../reports/view/view-report.component';
import {
  InsightsService,
  TopIndustries,
  TopInterests
} from '../../../insights.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  emotions,
  emotionsIterable,
  Emotion,
  Emotions,
  emotionsIterableSortedAZ
} from '../../../../../../common/constants/emotions';
import { Influencer } from '../../../../../../common/components/influencers-list/influencers-list.component';
import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';
import {
  FieldName,
  filtersFieldDefinitions,
  formatFilterTypeData
} from '../../constants';
import { ModalListComponent } from '../modal-list/modal-list.component';
import {
  ageOptions,
  ageOptionsIterable
} from '../../../../../../common/constants';

export interface TopIndustriesInterests {
  authors_by_engagements: {
    avatar: string;
    country: string;
    city: string;
    engagements: number;
    followers: number;
    id: string;
    name: string;
    username: string;
  }[];
  authors_by_followers: {
    avatar: string;
    engagements: number;
    followers: number;
    id: string;
    name: string;
    username: string;
  }[];
  count_profiles: number;
  emotions: string[];
  keywords: string[];
  percent_profiles: number;
  top_demographic?: {
    country: string;
    city: string;
    age: number;
    gender: 'male' | 'female';
  };
  // Properties we assign values to in FE
  percentOfProfiles?: string;
  calculatedPercentage?: string;
}

@Component({
  selector: 'ssi-widget-top-interests-industries',
  templateUrl: './widget-top-interests-industries.component.html',
  styles: []
})
export class WidgetTopInterestsIndustriesComponent
  implements OnChanges, OnInit {
  @Input() label: string;
  @Input() widget: TopInterests | TopIndustries;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];

  @Input() widgetSize: { w: number; h: number };

  @Output() loaded = new EventEmitter<void>();
  @Output() loadingAgain = new EventEmitter<void>();
  @Output() onApplyFilters = new EventEmitter();

  emotions: Emotions = emotions;
  emotionsIterable: Emotion[] = emotionsIterable;

  viewClassesForPartialResults = ['1x1', '2x2', '2x2', '2x2'];
  viewClassForPartialResults: string = '';

  circleLengthInUnits = 439.6;
  // '4x2' | '2x4' | '4x4'
  viewClass: string;

  topAudienceData: TopIndustriesInterests[] = [];
  filterFields = FieldName;
  ageOptions = ageOptions;

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.viewClass = `${this.widgetSize.w}x${this.widgetSize.h}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters && changes.filters.currentValue) ||
      (changes.globalFilters && changes.globalFilters.currentValue)
    ) {
      this.requestData();
    }

    if (changes.widgetSize && changes.widgetSize.currentValue) {
      console.log('widgetSize:', this.widgetSize);
      this.viewClass = `${this.widgetSize.w}x${this.widgetSize.h}`;
    }
  }

  async requestData() {
    this.loadingAgain.emit();

    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }: { data: { [key: string]: TopIndustriesInterests } }) => {
        console.log('data:::', data);
        this.topAudienceData = Object.entries(data)
          .map((keyVal) => ({
            name: keyVal[0],
            ...keyVal[1]
          }))
          .map((audience) => {
            audience.emotions.splice(5), audience.keywords.splice(5);
            audience.percentOfProfiles =
              audience.percent_profiles === 100
                ? '100%'
                : (Math.round(audience.percent_profiles * 10) / 10).toFixed(1) +
                  '%';
            audience.calculatedPercentage =
              this.calculatePercentage(audience.percent_profiles) + 'px';

            return audience;
          });
        console.log('this.topAudienceData Array:', this.topAudienceData);
        if (this.topAudienceData && this.topAudienceData.length < 4) {
          this.viewClassForPartialResults = this.viewClassesForPartialResults[
            this.topAudienceData.length - 1
          ];
        } else {
          this.viewClassForPartialResults = '';
        }
      })
      .catch(() => this.loaded.emit());

    this.loaded.emit();
  }

  calculatePercentage(percentage: number) {
    const translated = (percentage / 100) * this.circleLengthInUnits;
    const proggressOnCircle = this.circleLengthInUnits - translated;

    return proggressOnCircle;
  }

  viewMoreInflucencers(
    title: string,
    authorsByFollowers: Influencer[],
    authorsByEngagements: Influencer[]
  ) {
    const influencerModal = this.modal.open(ModalListComponent, {
      windowClass: 'orlo-modal xl-modal'
    });
    influencerModal.componentInstance.title = title;
    influencerModal.componentInstance.authorsByFollowers = authorsByFollowers;
    influencerModal.componentInstance.authorsByEngagements = authorsByEngagements;
    influencerModal.componentInstance.drillDownParams = {
      streamIds: this.streamIds,
      globalFilters: this.globalFilters,
      widgetType: this.widget.type
    };
  }

  openDrilldownModal(
    field: 'Emotion' | 'Industries' | 'Interests' | 'Bio Keywords',
    value,
    audienceInterestOrIndustry?: string
  ) {
    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });
    const filterType = filtersFieldDefinitions[field].preferedFilterType;
    modal.componentInstance.streamIds = this.streamIds;
    modal.componentInstance.displayApplyFilters = true;
    modal.componentInstance.globalFilters = this.globalFilters;
    modal.componentInstance.widgetFilters = [];
    modal.componentInstance.selectedFilters = [
      {
        field,
        [filtersFieldDefinitions[field]
          .preferedFilterType]: formatFilterTypeData(value, filterType)
      }
    ];
    if (field !== 'Industries' && field !== 'Interests') {
      modal.componentInstance.selectedFilters.push({
        field:
          this.widget.type === 'interests'
            ? this.filterFields.Interests
            : this.filterFields.Industries,
        [filtersFieldDefinitions[field]
          .preferedFilterType]: formatFilterTypeData(
          audienceInterestOrIndustry,
          filterType
        )
      });
    }
    modal.componentInstance.onApplyFilters.subscribe((filters) =>
      this.onApplyFilters.emit(filters)
    );
  }

  openDemographicDrilldownModal(
    audience: TopIndustriesInterests,
    audienceInterestOrIndustry?: string
  ) {
    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });
    const demographicFilters = [
      this.filterFields.Age,
      this.filterFields.Gender,
      this.filterFields.Country,
      this.filterFields.City
    ];

    const demographicFieldsValues = {
      [this.filterFields.Age]:
        this.widget.type === 'interests'
          ? ageOptions[audience.top_demographic.age].label
          : null,
      [this.filterFields.Gender]:
        this.widget.type === 'interests'
          ? audience.top_demographic.gender
          : null,
      [this.filterFields.Country]:
        this.widget.type === 'interests'
          ? audience.top_demographic.country
          : audience.authors_by_engagements[0].country,
      [this.filterFields.City]:
        this.widget.type === 'interests'
          ? audience.top_demographic.city
          : audience.authors_by_engagements[0].city
    };

    modal.componentInstance.streamIds = this.streamIds;
    modal.componentInstance.displayApplyFilters = true;
    modal.componentInstance.globalFilters = this.globalFilters;
    modal.componentInstance.widgetFilters = [];
    modal.componentInstance.selectedFilters = [];

    demographicFilters.map((demographicField) => {
      const demographicFilterType =
        filtersFieldDefinitions[demographicField].preferedFilterType;
      if (
        demographicFieldsValues &&
        demographicFieldsValues[demographicField]
      ) {
        modal.componentInstance.selectedFilters.push({
          field: demographicField,
          [filtersFieldDefinitions[demographicField]
            .preferedFilterType]: formatFilterTypeData(
            demographicFieldsValues[demographicField],
            demographicFilterType
          )
        });
      }
    });

    const field =
      this.widget.type === 'interests'
        ? this.filterFields.Interests
        : this.filterFields.Industries;
    const filterType = filtersFieldDefinitions[field].preferedFilterType;

    modal.componentInstance.selectedFilters.push({
      field:
        this.widget.type === 'interests'
          ? this.filterFields.Interests
          : this.filterFields.Industries,
      [filtersFieldDefinitions[field].preferedFilterType]: formatFilterTypeData(
        audienceInterestOrIndustry,
        filterType
      )
    });

    modal.componentInstance.onApplyFilters.subscribe((filters) =>
      this.onApplyFilters.emit(filters)
    );
  }
}
