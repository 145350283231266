import { Directive, HostListener, Inject, Input } from '@angular/core';
import {
  PUBLISHER_ACTIVE,
  PublisherActive
} from '../../components/publisher/publisher-active';

@Directive({
  selector: '[ssiTriggerPublish]'
})
export class TriggerPublishDirective {
  @Input('ssiTriggerPublish') params: object;

  constructor(
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive
  ) {}

  @HostListener('click')
  onClick() {
    this.publisherActive.next({
      isActive: true,
      ...this.params
    });
  }
}
