import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxMessageComponent } from './outbox-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { Nl2brModule } from '../../pipes/nl2br/nl2br.module';
import { EmojiTextModule } from '../../directives/emoji-text/emoji-text.module';
import { LinkifyModule } from '../../pipes/linkify/linkify.module';
import { EscapeHtmlModule } from '../../pipes/escape-html/escape-html.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TriggerPublishModule } from '../../directives/trigger-publish/trigger-publish.module';
import { HtmlToTextModule } from '../../pipes/html-to-text/html-to-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    Nl2brModule,
    EmojiTextModule,
    LinkifyModule,
    EscapeHtmlModule,
    TooltipModule,
    TriggerPublishModule,
    HtmlToTextModule
  ],
  declarations: [OutboxMessageComponent],
  exports: [OutboxMessageComponent]
})
export class OutboxMessageModule {}
