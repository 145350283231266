import { Company as CompanyInterface } from '../../interfaces/live-chat/company';
import Model from '../model';

export class Company extends Model implements CompanyInterface {
  public static MaximumAgentAssignmentTarget = 10;
  public static MaximumAgentAssignmentPostResolutionDelay = 120;
  public static MinimumAgentAssignmentTarget = 1;
  public static MinimumAgentAssignmentPostResolutionDelay = 0;

  protected _data: CompanyInterface;

  public get company_id(): string {
    return this._data.company_id;
  }

  public get companyId(): string {
    return this.company_id;
  }

  public get isPushModeEnabled(): boolean {
    return !!this._data.pushMode && !!this._data.pushMode.isEnabled;
  }

  public get name(): string {
    return this._data.name;
  }

  public get pushModeAgentAssignmentPostResolutionDelay(): number {
    return (
      this._data.pushMode.agentAssignmentPostResolutionDelay ||
      Company.MinimumAgentAssignmentPostResolutionDelay
    );
  }

  public set pushModeAgentAssignmentPostResolutionDelay(value: number) {
    if (typeof value === 'undefined') {
      return;
    }

    if (value < Company.MinimumAgentAssignmentPostResolutionDelay) {
      return;
    }

    if (value > Company.MaximumAgentAssignmentPostResolutionDelay) {
      return;
    }

    const updates = {
      'pushMode.agentAssignmentPostResolutionDelay': value
    };

    this.update(updates);
  }

  public get pushModeAgentAssignmentTarget(): number {
    return (
      this._data.pushMode.agentAssignmentTarget ||
      Company.MinimumAgentAssignmentPostResolutionDelay
    );
  }

  public set pushModeAgentAssignmentTarget(value: number) {
    if (typeof value === 'undefined') {
      return;
    }

    if (value < Company.MinimumAgentAssignmentTarget) {
      return;
    }

    if (value > Company.MaximumAgentAssignmentTarget) {
      return;
    }

    const updates = {
      'pushMode.agentAssignmentTarget': value
    };

    this.update(updates);
  }
}
