<div class="message-box-row">
  <div class="message-box-panel">
    <div class="message-box-panel-body">
      <p translate="ARE_YOU_SURE_YOU_WANT_TO_SPLIT_THIS_CONVERSATION_HERE"></p>
    </div>
    <ul class="message-box-panel-actions">
      <li class="message-box-panel-action dismiss">
        <p
          (click)="cancel.emit()"
          translate="CANCEL"
        ></p>
      </li>
      <li class="message-box-panel-action confirm">
        <p
          (click)="splitConversationConfirmed.emit()"
          translate="SPLIT_NOW"
        ></p>
      </li>
    </ul>
  </div>
</div>