import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetInboxMessageComponent } from './widget-inbox-message.component';
import { InboxQueryResultListModule } from '../../../../../../auth/inbox/inbox-query-result-list/inbox-query-result-list.module';
import { AutoHeightModule } from '../../../../../../../common/directives/auto-height/auto-height.module';
import { InboxActivityModalModule } from '../../../../../../../common/components/inbox-activity/inbox-activity-modal/inbox-activity-modal.module';
import { LiveChatModule } from '../../../../../../../common/components/live-chat/live-chat/live-chat.module';

@NgModule({
  imports: [
    CommonModule,
    InboxQueryResultListModule,
    AutoHeightModule,
    InboxActivityModalModule,
    LiveChatModule
  ],
  exports: [WidgetInboxMessageComponent],
  declarations: [WidgetInboxMessageComponent]
})
export class WidgetInboxMessageModule {}
