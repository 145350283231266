<i class="ssi ssi-session-warning">
</i>

<ng-container *ngIf="!invalid">
	<span
		*ngIf="days"
		[ngPlural]="days"
		[ssiTimeToDetails]="remainingDetails"
	>
	<!-- {{remainingDetails}} -->
		<ng-template ngPluralCase="1">
			{{days}} day to respond
		</ng-template>

		<ng-template ngPluralCase="other">
			{{days}} days to respond
		</ng-template>
	</span>

	<span
		*ngIf="hours && !days"
		[ngPlural]="hours"
	>
		<ng-template ngPluralCase="1">
			{{hours}}hr
		</ng-template>

		<ng-template ngPluralCase="other">
			{{hours}}hrs
		</ng-template>
	</span>

	<span
		*ngIf="minutes && !days"
		[ngPlural]="minutes"
	>
		<ng-template ngPluralCase="1">
			{{minutes}}min
		</ng-template>

		<ng-template ngPluralCase="other">
			{{minutes}}mins
		</ng-template>
	</span>
	<span
		*ngIf="!days"
		translate="TO_RESPOND"
	></span>
</ng-container>

<!-- <ng-template #timerExplanationTooltipTemplate>
  <div class="tooltip-content">
    <div class="title">
      <span>{{ 'MULTIPLE_USERS_VIEWING' | translate }}</span>
    </div>
    <div class="content">
      <span>{{activity.usersViewingNowNamesLabel}}</span>
    </div>
    <div class="icon-container">
      <i class="ssi ssi-eye"></i>
    </div>
  </div>

  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template> -->