import { mapToIterable } from '../../../../../common/utils';

export interface WidgetType {
  key: string;
  label: string;
  iconClassName: string;
  tooltipExplainer: string;
  widgetBuilderExplainer?: string;
  defaultSize: { w: number; h: number };
  featureFlag?: string;
}

export interface WidgetTypes {
  rssFeeds: WidgetType;
  inboxMessageMode: WidgetType;
  inboxConversationMode: WidgetType;
  // inboxPushMode: WidgetType;
  liveXStream: WidgetType;
  marketingPosts: WidgetType;
  monitoringStream: WidgetType;
  profileSearch: WidgetType;
  socialWall: WidgetType;
  // scorecards: WidgetType;
}

export const widgetTypes: WidgetTypes = {
  rssFeeds: {
    key: 'rssFeeds',
    label: 'RSS Feeds',
    iconClassName: 'ssi ssi-contentgenerator',
    tooltipExplainer: 'View latest articles from feeds connected to Orlo',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  inboxMessageMode: {
    key: 'inboxMessageMode',
    label: 'Inbox: Individual Message Mode',
    iconClassName: 'ssi ssi-individual-message-mode',
    tooltipExplainer: 'A customisable view of Inbox messages',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  inboxConversationMode: {
    key: 'inboxConversationMode',
    label: 'Inbox: Conversation Mode',
    iconClassName: 'ssi ssi-conversation-mode',
    tooltipExplainer: 'A customisable view of Inbox conversations',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  // inboxPushMode: {
  //   key: 'inboxPushMode',
  //   label: 'Inbox: Push Mode',
  //   iconClassName: 'ssi ssi-pushmode',
  //   tooltipExplainer: 'Full explainer right here.',
  //   defaultSize: {
  //     w: 2,
  //     h: 2
  //   }
  // },
  liveXStream: {
    key: 'liveXStream',
    label: 'Live X Stream',
    iconClassName: 'ssi ssi-x-logo',
    tooltipExplainer: 'Monitor keywords, hashtags and authors on X',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  marketingPosts: {
    key: 'marketingPosts',
    label: 'Marketing Posts',
    iconClassName: 'ssi ssi-compose-post',
    tooltipExplainer: 'A customisable view of your Marketing posts',
    widgetBuilderExplainer:
      'By default, posts from all accounts will shown on your Marketing Posts widget.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  monitoringStream: {
    key: 'monitoringStream',
    label: 'Insights Streams',
    iconClassName: 'ssi ssi-orlo-insights',
    tooltipExplainer: 'View results from your Insights streams',
    defaultSize: {
      w: 2,
      h: 2
    },
    featureFlag: 'ORLO_INSIGHTS'
  },
  profileSearch: {
    key: 'profileSearch',
    label: 'Profile Search',
    iconClassName: 'ssi ssi-socialprofile',
    tooltipExplainer: 'Search for and show content from public profiles',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  socialWall: {
    key: 'socialWall',
    label: 'Social Wall',
    iconClassName: 'ssi ssi-social-wall',
    tooltipExplainer: 'View the social walls of your own accounts',
    defaultSize: {
      w: 2,
      h: 2
    }
  }
  // scorecards: {
  //   key: 'scorecards',
  //   label: 'Scorecards',
  //   iconClassName: 'ssi ssi-scorecard',
  //   tooltipExplainer: 'Full explainer right here.',
  //   defaultSize: {
  //     w: 2,
  //     h: 2
  //   }
  // }
};

export const widgetTypesIterable: WidgetType[] = mapToIterable(widgetTypes);
