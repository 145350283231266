import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatNavigationComponent } from './live-chat-navigation.component';
import './live-chat-navigation.scss';

@NgModule({
  declarations: [LiveChatNavigationComponent],

  exports: [LiveChatNavigationComponent],

  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule.forRoot(),
    TranslateModule
  ]
})
export class LiveChatNavigationModule {}
