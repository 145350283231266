import './tags-input.component.scss';

import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { mapToIterable } from '../../../../../../common/utils';
import { Option } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.component';

export enum KeywordSearchType {
  Basic = 'basic',
  Advanced = 'advanced'
}

@Component({
  selector: 'ssi-tags-input',
  templateUrl: './tags-input.component.html',
  styles: []
})
export class TagsInputComponent implements OnInit, OnChanges {
  @Input() tags: string[] = [];
  @Input() condition: string;
  @Input() disabled: boolean = false;
  @Input() compact: boolean = false;

  @Output() onTagsChange = new EventEmitter<{
    tags: string[];
    condition: string;
  }>();

  // @Output() onConditionChange = new EventEmitter<string>();

  // conditionValues: string[] = [
  //   'Any',
  //   'All',
  //   'Exact phrase',
  //   'None of these',
  //   'Semantic search'
  // ];
  inputFocused: boolean = false;
  tagsInput = '';

  searchTypes: { [key: string]: Option } = {
    [KeywordSearchType.Basic]: {
      key: KeywordSearchType.Basic,
      label: 'Basic',
      placeholder: this.translate.instant(
        'SEARCH_KEYWORD_OR_HASHTAG_PLACEHOLDER'
      ),
      condition: 'match'
    },
    [KeywordSearchType.Advanced]: {
      key: KeywordSearchType.Advanced,
      label: 'Advanced',
      placeholder: 'Type in your boolean search query...',
      condition: 'query'
    }
  };
  searchTypesIterable: Option[] = mapToIterable(this.searchTypes);
  selectedSearchType: Option = this.searchTypes.basic;

  constructor(public modal: NgbModal, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['condition'] && changes['condition'].currentValue) {
      this.selectedSearchType = this.searchTypesIterable.find(
        (t) => t.condition === this.condition
      );
    }

    if (changes['tags'] && changes['tags'].currentValue) {
      this.tags.forEach((t) => {
        this.addTag(t);
      });
    }
  }

  ngOnInit(): void {}

  deleteTag(tag: string) {
    this.tags = this.tags.filter((iTag) => iTag !== tag);
    this.onTagsChange.emit({
      tags: this.tags,
      condition: this.selectedSearchType.condition
    });
  }

  clearTags(): void {
    this.tags = [];
    this.tagsInput = '';
    this.onTagsChange.emit({
      tags: this.tags,
      condition: this.selectedSearchType.condition
    });
  }

  addTag(tag: string) {
    if (!this.tags.includes(tag) && tag.trim()) {
      this.tags.push(tag);
      this.onTagsChange.emit({
        tags: this.tags,
        condition: this.selectedSearchType.condition
      });
    }
    this.tagsInput = '';
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.addTag(this.tagsInput);
    }
  }

  // conditionUpdated(condition) {
  //   this.condition = condition;
  //   this.onConditionChange.emit(condition);
  // }

  onKeywordSearchTypeChange(searchType: Option): void {
    this.clearTags();
  }
}
