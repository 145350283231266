import { Injectable } from '@angular/core';
import { Subject, interval } from 'rxjs';

import {
  ConversationModel,
  Conversation,
  Activity,
  User
} from '@ui-resources-angular';
import { Socket } from 'socket.io-client';
import { PageVisibilityService } from '../page-visibility/page-visibility.service';
import { appInjector } from '../../../app-injector';
import { ColleaguesService } from '../api';

export enum RealtimeSocketEvent {
  View = 'conversation:view',
  Close = 'conversation:close',
  Viewers = 'conversation:viewers'
}

@Injectable({ providedIn: 'root' })
export class RealtimeConversationHelperService {
  constructor() {}

  create(params: { socket: Socket; authUser: User }) {
    const socket = params.socket;
    const authUser = params.authUser;

    const conversationModel = appInjector().get(ConversationModel);
    const pageVisibility = appInjector().get(PageVisibilityService);
    const colleaguesService = appInjector().get(ColleaguesService);

    const manager = {
      meta: {},
      viewHeartbeatEnabled: true,
      activeConversation: undefined,
      socket,
      setOpenConversation(conversation: Conversation) {
        if (conversation) {
          if (
            typeof conversation === 'string' &&
            !conversationModel.is(conversation)
          ) {
            conversation = conversationModel.get(conversation);
          }
          this.conversationClosed();
          this.emitConversationEvent(RealtimeSocketEvent.View, conversation.id);
          this.activeConversation = conversation;
        }
      },
      conversationClosed() {
        this.meta = {};
        if (this.activeConversation) {
          this.emitConversationEvent(
            RealtimeSocketEvent.Close,
            this.activeConversation.id
          );
          this.activeConversation = null;
        }
      },
      emitConversationEvent(
        event: RealtimeSocketEvent,
        conversationId: string
      ) {
        if (conversationId) {
          socket.emit(event, {
            conversation: {
              id: conversationId
            },
            user: {
              id: this.authUser.id,
              meta: this.meta
            }
          });
        }
      },
      onConversationViewersEvent(data: {
        conversation: Partial<Conversation>;
        heartbeat: { interval: number };
        users: Array<{ expiresAt: string; id: number; meta: any }>;
      }) {
        try {
          const conversation = conversationModel.get(data.conversation.id);
          if (conversation) {
            if (!Array.isArray(data.users)) {
              throw new Error(
                `value for 'realtime conversation helper data users' is not in the expected format.`
              );
            }

            conversationModel.getAll().forEach((iConversation) => {
              delete iConversation['usersTypingNow'];
              delete iConversation['usersViewingNow'];
              delete iConversation['usersViewingNowNamesLabel'];
            });

            conversation['usersViewingNow'] = data.users
              .map((user) => colleaguesService.store.find(user.id))
              .filter((user) => user && user.id !== this.authUser.id);

            conversation['usersViewingNowNamesLabel'] = conversation[
              'usersViewingNow'
            ]
              .map((user) => user.fullName)
              .join(', ');

            for (const user of conversation['usersViewingNow']) {
              const meta = data.users.find(
                (socketUser) => +socketUser.id === +user.id
              ).meta;

              if (meta && meta.typingReplies) {
                meta.typingReplies.forEach((conversationId) => {
                  const typingConversation = conversationModel.get(
                    conversationId
                  );
                  if (typingConversation) {
                    typingConversation['usersTypingNow'] =
                      typingConversation['usersTypingNow'] || [];

                    typingConversation['usersTypingNow'].push(user);

                    typingConversation[
                      'usersTypingNowNamesLabel'
                    ] = typingConversation['usersTypingNow']
                      .map((u) => u.fullName)
                      .join(', ');
                  }
                });
              }
            }
          }

          if (!this.heartbeatInterval) {
            this.heartbeatInterval = setInterval(() => {
              if (this.viewHeartbeatEnabled && this.activeConversation) {
                this.emitConversationEvent(
                  RealtimeSocketEvent.View,
                  this.activeConversation.id
                );
              }
            }, data.heartbeat.interval);
          }

          return true;
        } catch (error) {
          console.error(error);

          return false;
        }
      },
      destroy() {
        this.conversationClosed();
        this.deregisterSocketListener();
        if (this.heartbeatInterval) {
          clearInterval(this.heartbeatInterval);
        }
        this.deregisterVisibilityListener();
      }
    };

    const wrapViewersEvent = (data) => manager.onConversationViewersEvent(data);

    Object.assign(manager, {
      authUser,
      deregisterSocketListener() {
        socket.removeListener(RealtimeSocketEvent.View, wrapViewersEvent);
      },
      deregisterVisibilityListener: pageVisibility.delayedHide({
        onHide() {
          manager.viewHeartbeatEnabled = false;
          if (manager.activeConversation) {
            manager.emitConversationEvent(
              RealtimeSocketEvent.Close,
              manager.activeConversation.id
            );
          }
        },
        onShow() {
          manager.viewHeartbeatEnabled = true;
          if (manager.activeConversation) {
            manager.emitConversationEvent(
              RealtimeSocketEvent.View,
              manager.activeConversation.id
            );
          }
        },
        delay: 60 * 1000
      })
    });

    manager.socket.on(RealtimeSocketEvent.Viewers, wrapViewersEvent);

    return manager;
  }
}
