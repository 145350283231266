import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { WidgetMonitoringStreamConfigComponent } from './widget-monitoring-stream-config.component';
import { GlobalFiltersModule } from '../../../../../insights/common/components/global-filters/global-filters.module';
import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { ToggleSwitch2Module } from '../../../../../../../common/components/toggle-switch-2/toggle-switch-2.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GlobalFiltersModule,
    TranslateModule,
    DropdownSelect2Module,
    ToggleSwitch2Module
  ],
  exports: [WidgetMonitoringStreamConfigComponent],
  declarations: [WidgetMonitoringStreamConfigComponent]
})
export class WidgetMonitoringStreamConfigModule {}
