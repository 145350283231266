import './sentiment-amend-box.component.scss';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-sentiment-amend-box',
  templateUrl: './sentiment-amend-box.component.html'
})
export class SentimentAmendBoxComponent {
  @Input() isInsights: boolean = true;
  @Input() sentiment: number;
  @Output() onChangeSentiment = new EventEmitter<number>();

  constructor() {}
}
