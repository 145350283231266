<div class="composer-modal"></div>
<div class="composer-modal-header">
  <div class="composer-modal-header-left">
    <h2 translate="VIDEO_CAPTIONS"></h2>
  </div>
  <div class="composer-modal-header-right">
    <button
      (click)="activeModal.close(false)"
      class="composer-modal-close"
    >
      <i class="ssi ssi-addsmall"></i>
    </button>
  </div>
</div>

<div class="composer-modal-body">
  <div
    class="thumbnail-image"
    *ngIf="videoFile.url"
  >
    <div class="video-properties">
      <h5 translate="VIDEO_PROPERTIES"></h5>
      <div
        class="prop"
        *ngIf="videoFile.filename"
      >{{'FILE_NAME' | translate}}: {{videoFile.filename}}</div>
      <div
        class="prop"
        *ngIf="videoFile.size"
      >{{'SIZE' | translate}}: {{videoFile.size | prettyBytes}}</div>
      <div class="prop">{{'LENGTH' | translate}}: {{videoDuration | secondsToHumanTime}}</div>
    </div>
    <video
      (loadedmetadata)="onMetadata($event)"
      width="280"
      #videoElement
    >
      <source
        [src]="videoFile.url + '#t=0.1'"
        type="video/mp4"
      >
    </video>
  </div>

  <div class="vertical-line"></div>

  <div class="content-interaction">
    <div class="interaction-field">
      <label translate="VIDEO_CAPTIONS"></label>
      <button
        class="upload-file-btn"
        [class.uploaded]="subtitleFilename"
        ssiFileUploader
        [accept]="subtitleMimeType"
        [maxFiles]="1"
        (uploadStart)="fileUploadStarted()"
        (uploadFinally)="fileUploadComplete()"
        (uploadSuccess)="fileUploadSuccess($event)"
        (uploadError)="fileUploadError($event)"
      >
        {{!subtitleFilename ? ('UPLOAD_AN_SRT_FILE' | translate) : ''}}
        <i class="ssi
          {{
          subtitleFilename
          ? 'ssi-correctedit'
          : 'ssi-addsmall'
        }}"></i>
        <ul
          *ngIf="subtitleFilename"
          class="file-list"
        >
          <li class="single-file">
            <span>{{subtitleFilename}}</span>
            <button
              class="file-input-action"
              (click)="removeCaptions()"
            >
              <i class="ssi ssi-delete-close"></i>
            </button>
          </li>
        </ul>
      </button>

    </div>

    <div class="interaction-field">
      <label translate="CAPTION_LANGUAGE"></label>
      <ssi-dropdown-select-2
        headButtonStyleVariant="default"
        [options]="locales"
        [(ngModel)]="selectedLocale"
        name="selectedLocale"
        (ngModelChange)="toggleLanguageSearch = !toggleLanguageSearch"
      ></ssi-dropdown-select-2>
    </div>
  </div>
</div>

<div class="composer-modal-footer">
  <div class="composer-modal-footer-right">
    <orlo-button
      class="composer-modal-button composer-modal-button-secondary"
      [text]="'CANCEL' | translate"
      textColor="#43537F"
      bgColor="#fff"
      (click)="activeModal.close(null)"
    ></orlo-button>
    <orlo-button
      class="composer-modal-button"
      [text]="'ADD_VIDEO_CAPTIONS' | translate"
      [textColor]="subtitleFilename && selectedLocale ? '#FFF' : '#838EAB'"
      [bgColor]="subtitleFilename && selectedLocale ? '#14BAE3' : '#F4F4FA'"
      (click)="save()"
      [disabled]="!subtitleFilename && !selectedLocale"
    ></orlo-button>
  </div>
</div>