<div class="instagram-post-header">
  <img class="img-circle pull-left" [src]="account.picture" ssiFallbackImage="avatar">
  <a class="instagram-post-author-username" [href]="account.externalUrl" target="_blank">{{ account.username }}</a>
  <div class="clearfix"></div>
</div>

<div class="instagram-post-image" 
     *ngIf="files.length === 1 && files[0].type === OutboxFileType.Image">
  <img [src]="files[0].url">
</div>
<div
  class="instagram-post-images"
  *ngIf="files.length > 1 && files[0].type === OutboxFileType.Image"
>
  <button class="instagram-post-images-action" (click)="slide = slide > 0 ? slide - 1 : slide">
    <i class="ssi ssi-keyboard-arrow-left"></i>
  </button>
  <div
    [style.transform]="'translateX(-'+ 498 * slide +'px)'"
    class="instagram-post-images-image" *ngFor="let imageFile of files; let index = index"
  >
    <img [src]="imageFile.url">
  </div>
  <button class="instagram-post-images-action" (click)="slide = slide === files.length - 1 ? slide : slide + 1">
    <i class="ssi ssi-keyboard-arrow-right"></i>
  </button>
</div>

<div class="instagram-post-video" 
     *ngIf="files.length === 1 && files[0].type === OutboxFileType.Video">
  <video controls>
    <source [src]="files[0].url" type="video/mp4">
    Sorry but we could not load the video preview.
  </video>
</div>

<div class="instagram-post-body">
  <div class="instagram-post-actions">
    <i [class]="account.socialNetwork.socialInteractions.like.icon.web"></i>
    <i class="fa fa-comment-o"></i>
  </div>
  <div>
    <a class="instagram-post-author-username" [href]="account.externalUrl" target="_blank">{{ account.username }}</a>
    <span class="instagram-post-text">
      <span *ngIf="isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br | truncate: limit"></span>
      <span *ngIf="!isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br"></span>
      <span class="text-read-more" *ngIf="isTruncated" (click)="isTruncated = false; limit = text.length">Read More</span>
    </span>
  </div>
  <div class="instagram-post-date-time">Just now</div>
</div>
