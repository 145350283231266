import { Component, Input } from '@angular/core';
import './total-stat-card.component.scss';

@Component({
  selector: 'ssi-total-stat-card',
  templateUrl: './total-stat-card.component.html',
  styles: []
})
export class TotalStatCardComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() value: string | number;
  @Input() iconSize: string;
  @Input() iconColor: string;
  @Input() growthValue?: string | number;
  @Input() growth?: boolean;
  @Input() iconBackgroundColor?: string;
  @Input() height?: string;
  @Input() valueIsString?: boolean = false;
  @Input() lowerIsPositive?: boolean;

  constructor() {}
}
