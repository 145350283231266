import { Component, Input, OnInit } from '@angular/core';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import './embed-widget.scss';

@Component({
  selector: 'ssi-embed-widget',
  templateUrl: './embed-widget.component.html',
  styles: []
})
export class EmbedWidgetComponent implements OnInit {
  activeView = 'website';
  widget;

  constructor(public api: API, private state: StateService) {}

  ngOnInit() {
    if (!this.state.params.widget) {
      this.state.go('^');
    } else {
      this.widget = this.state.params.widget;
    }
  }

  return() {
    this.state.go('^');
  }
}
