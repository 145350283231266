import { mapToIterable } from '../utils';

export interface Interest {
  key: string;
  label: string;
}

export interface Interests {
  'Arts and Culture': Interest;
  'Sports and Recreation': Interest;
  'Science and Innovation': Interest;
  'Politics and Social Issues': Interest;
  'Home and Garden': Interest;
  'Pets and Animals': Interest;
  'Cars and Motorcycles': Interest;
  'Spirituality and Religion': Interest;
  'Family and relationships': Interest;
  'Travel and Adventure': Interest;
  'Technology and Gadgets': Interest;
  'Education and Learning': Interest;
  'Health and Wellness': Interest;
  'Food and Cooking': Interest;
  'Fashion and Beauty': Interest;
  'Business and Finance': Interest;
  'Environment and Nature': Interest;
}

export const interests: Interests = {
  'Arts and Culture': {
    key: 'Arts and Culture',
    label: 'Arts and Culture'
  },
  'Sports and Recreation': {
    key: 'Sports and Recreation',
    label: 'Sports and Recreation'
  },
  'Science and Innovation': {
    key: 'Science and Innovation',
    label: 'Science and Innovation'
  },
  'Politics and Social Issues': {
    key: 'Politics and Social Issues',
    label: 'Politics and Social Issues'
  },
  'Home and Garden': {
    key: 'Home and Garden',
    label: 'Home and Garden'
  },
  'Pets and Animals': {
    key: 'Pets and Animals',
    label: 'Pets and Animals'
  },
  'Cars and Motorcycles': {
    key: 'Cars and Motorcycles',
    label: 'Cars and Motorcycles'
  },
  'Spirituality and Religion': {
    key: 'Spirituality and Religion',
    label: 'Spirituality and Religion'
  },
  'Family and relationships': {
    key: 'Family and relationships',
    label: 'Family and relationships'
  },
  'Travel and Adventure': {
    key: 'Travel and Adventure',
    label: 'Travel and Adventure'
  },
  'Technology and Gadgets': {
    key: 'Technology and Gadgets',
    label: 'Technology and Gadgets'
  },
  'Education and Learning': {
    key: 'Education and Learning',
    label: 'Education and Learning'
  },
  'Health and Wellness': {
    key: 'Health and Wellness',
    label: 'Health and Wellness'
  },
  'Food and Cooking': {
    key: 'Food and Cooking',
    label: 'Food and Cooking'
  },
  'Fashion and Beauty': {
    key: 'Fashion and Beauty',
    label: 'Fashion and Beauty'
  },
  'Business and Finance': {
    key: 'Business and Finance',
    label: 'Business and Finance'
  },
  'Environment and Nature': {
    key: 'Environment and Nature',
    label: 'Environment and Nature'
  }
};

export const interestsIterable: Interest[] = mapToIterable(interests);
export const interestsIterableSortedAZ: Interest[] = mapToIterable(
  interests
).sort((a, b) => a.label.localeCompare(b.label));
