import './user-performance.component.scss';

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SecondsToHumanTimePipe } from '../../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';
import { TeamsService, Team } from '../../../../../../common/services/api';
import { humanizeSeconds } from '../../../../../../common/utils';

@Component({
  selector: 'ssi-user-performance',
  templateUrl: './user-performance.component.html',
  styles: []
})
export class UserPerformanceComponent implements OnInit {
  @Input() userData;
  // tableLoading = false;
  teams: Team[] = [];
  dataToDisplay: any[] = [];
  selectedTeam;
  @Output() usersFiltered = new EventEmitter();

  constructor(private teamsService: TeamsService) {}

  async ngOnInit() {
    this.teams = await this.teamsService.getAllActive();
    this.dataToDisplay = this._formatUserData(this.userData);
  }

  private _formatUserData(userData: any[]) {
    return userData.map(
      ({
        user,
        avgFirstResponseTime,
        avgHandlingTime,
        avgResponseTime,
        ...data
      }) => {
        return {
          user,
          current: Object.assign(data, {
            avgFirstResponseTime,
            avgHandlingTime,
            avgResponseTime
          })
        };
      }
    );
  }

  filterByTeam(teamId) {
    if (teamId === 'undefined') {
      this.dataToDisplay = this._formatUserData(this.userData);
      this.usersFiltered.emit(this.userData);
      return;
    }
    const filteredUsers = this.userData.filter((user) =>
      user.user.teams.includes(teamId)
    );
    this.dataToDisplay = this._formatUserData(filteredUsers);
    this.usersFiltered.emit(filteredUsers);
  }

  isTimeField(field: string): boolean {
    return (
      field === 'current.avgHandlingTime' ||
      field === 'current.avgResponseTime' ||
      field === 'current.avgFirstResponseTime'
    );
  }

  secondsToHumanTime(seconds: number): string {
    return humanizeSeconds(seconds);
  }
}
