import angular from 'angular';
import { TransitionService, StateService } from '@uirouter/angularjs';
import { UserModel, Auth } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../angular/common/services/workflow-manager/workflow-manager.service';
import { RedirectService } from '../../angular/common/services/redirect/redirect.service';
// these run blocks couldn't be replaced with anything in apps/angular since
// StateService and TransitionService depend on AngularJS uirouter
// which at the time of bootstrap of Angular app aren't accesible yet and throw:
// Unhandled Promise rejection: Trying to get the AngularJS injector before it being set. ;
// Zone: <root> ; Task: Promise.then ; Value: Error: Trying to get the AngularJS injector before it being set.
export default angular
  .module('common.run', [])
  .run(($log: angular.ILogService, $state: StateService) => {
    'ngInject';
    $state.defaultErrorHandler((error) => {
      const ignoreCodes = [
        2, // RejectType.SUPERSEDED
        3 // RejectType.ABORTED
      ];
      if (error && ignoreCodes.includes(error.type)) {
        return;
      }
      error.message = 'State change error: ' + error.message;
      $log.error(error);
      // window['requestIdleCallback'](() => {
      //   trackJs.track(error);
      // });
    });
  })
  .run(($auth, $transitions: TransitionService, userModel, redirect) => {
    'ngInject';
    $transitions.onStart({}, (transition) => {
      const toStateData = transition.$to().data;
      if (
        toStateData &&
        toStateData.permissions &&
        toStateData.permissions.company &&
        $auth.isAuthenticated()
      ) {
        return userModel.getAuthUser().then((user) => {
          if (!user.hasCompanyPermission(toStateData.permissions.company)) {
            return redirect.login();
          }
        });
      }
    });
  })
  .run(
    (
      $auth,
      $state: StateService,
      $transitions: TransitionService,
      $translate: angular.translate.ITranslateService,
      accountModel,
      workflowManager: WorkflowManagerService,
      errorHandler
    ) => {
      'ngInject';
      $transitions.onStart(
        {
          to: 'auth.**'
        },
        (transition) => {
          const toStateData = transition.$to().data;
          const isWorkflowPage = toStateData && toStateData.isWorkflowPage;

          if ($auth.isAuthenticated() && !isWorkflowPage) {
            return accountModel
              .findAccounts(workflowManager.getCurrentId())
              .then((accounts) => {
                if (accounts.length > 2000) {
                  // too many accounts

                  errorHandler.error({
                    message: $translate.instant(
                      'YOU_CAN_ONLY_LOAD_A_MAXIMUM_OF__MAXIMUMWORKFLOWACCOUNTS__ACCOUNTS_' +
                        'AT_ONCE_PLEASE_ORGANISE_YOUR_ACCOUNTS_INTO_A_WORKFLOW',
                      2000
                    )
                  });
                  return $state.go('auth.workflows');
                }
              });
          }
        }
      );
    }
  )
  .run(
    (
      $auth: Auth,
      $transitions: TransitionService,
      userModel: UserModel,
      redirect: RedirectService
    ) => {
      'ngInject';
      const cancelListener = $transitions.onStart({}, (transition) => {
        cancelListener();
        if ($auth.isAuthenticated()) {
          // doing a request to the server is the most accurate way of telling if
          // a user is logged in or not and should also prevent self DDoS if the API goes down
          return userModel
            .findAll({}, { autoError: false })
            .then(([authUser]) => {
              // don't return the promise, otherwise if it fails it will
              // log the user out or cause the page routing to fail
              // authUser.refreshAuthToken($auth);
            })
            .catch(() => {
              userModel.logout();
              // .finally(() => authService.clearJWT());
            });
        } else if (transition.$to().name.startsWith('auth.')) {
          // not already trying to go to an unauthenticated page
          redirect.logout();
        }
      });
    }
  );
