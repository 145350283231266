import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  User,
  UserModel,
  AccountModel,
  Account,
  API
} from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { PluginService } from '../../../../../../angular/common/services/plugin/plugin.service';
import { PopupService } from '../../../../../common/services/popup/popup.service';

@Component({
  selector: 'ssi-manage-apps',
  templateUrl: './manage-apps.component.html'
})
export class ManageAppsComponent implements OnDestroy, OnInit {
  @ViewChild('editAppModal') editAppModal: TemplateRef<any>;

  editing;
  apps = [];
  initialisedPlugins: any;

  _initialisedPluginsSubscription: Subscription;

  constructor(
    private plugin: PluginService,
    private api: API,
    private modalService: NgbModal,
    private popup: PopupService
  ) {}

  ngOnDestroy() {
    if (!!this._initialisedPluginsSubscription) {
      this._initialisedPluginsSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.api
      .get('plugin/plugin')
      .then(({ data }) => (this.apps = data.plugins));

    this._initialisedPluginsSubscription = this.plugin
      .$initialisedPlugins()
      .subscribe((plugins) => {
        this.initialisedPlugins = plugins;
      });

    this.initialisedPlugins = this.plugin.getInitialisedPlugins();
  }

  async editApps(app) {
    this.editing = {
      app: Object.assign({}, app)
    };
    const changes = await this.modalService.open(this.editAppModal, {
      backdrop: true
    }).result;

    if (changes) {
      this.saveApp(changes);
    }
  }

  openSettings(app) {
    try {
      if (
        !(
          !!app &&
          !!app.id &&
          (!!this.initialisedPlugins &&
            !!this.initialisedPlugins[app.id] &&
            !!this.initialisedPlugins[app.id].actions) &&
          Array.isArray(this.initialisedPlugins[app.id].actions)
        )
      ) {
        throw new Error(
          `Value for 'initialised plugins' not in expected format.`
        );
      }

      const settingsAction = this.initialisedPlugins[app.id].actions.find(
        (action) => action.type === 'settings'
      );
      this.plugin.trigger(
        { callbackId: settingsAction.callbackId, params: {} },
        this.initialisedPlugins[app.id].context.nativeElement.contentWindow
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async saveApp(app) {
    await this.api
      .post('plugin/plugin', app)
      .then(({ data }) => this.apps.push(data));
  }

  async deleteApp(app) {
    await this.api.del(`plugin/plugin?id=${app.id}`);
    this.apps = this.apps.filter((appObj) => appObj.id !== app.id);
  }
}
