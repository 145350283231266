import { Collection, Mapper, Query, Record } from 'js-data';
import { services, mapperDefaults } from './common';

// wrapped to shim compatibility with js-data 2.x, improve typings and work nicer with angulars DI
export abstract class Model<RecordType> {
  private mapper: Mapper;

  constructor(private name: string, opts: any) {
    // opts are always cloned to workaround a bug where if you pass the same
    // opts to 2 different queries they get mutated by each other and break things
    this.mapper = services.store.defineMapper(
      name,
      Object.assign(opts, mapperDefaults)
    );
    services.models.set(name, this);
    // TODO - add all supported hooks
    if (opts.beforeAdd) {
      services.store.getCollection(name).beforeAdd = opts.beforeAdd;
    }
  }

  add(records: any[], opts?: any): RecordType[];
  add(records: any, opts?: any): RecordType;
  add(records: any, opts?: any): any {
    return services.store.add(
      this.mapper.name,
      records,
      Object.assign({}, opts)
    );
  }

  between(leftKeys: any, rightKeys: any, opts?: any): RecordType[] {
    return services.store.between(
      this.mapper.name,
      leftKeys,
      rightKeys,
      Object.assign({}, opts)
    );
  }

  cachedFind(id: string | number, opts: any): RecordType {
    return services.store.cachedFind(
      this.mapper.name,
      id,
      Object.assign({}, opts)
    );
  }

  cachedFindAll(query: string, opts: any): RecordType {
    return services.store.cachedFindAll(
      this.mapper.name,
      query,
      Object.assign({}, opts)
    );
  }

  cacheFind(data: any, id: string | number, opts: any): void {
    services.store.cacheFind(
      this.mapper.name,
      data,
      id,
      Object.assign({}, opts)
    );
  }

  cacheFindAll(data: any, hash: string, opts: any): void {
    return services.store.cacheFindAll(
      this.mapper.name,
      data,
      hash,
      Object.assign({}, opts)
    );
  }

  createIndex(name: any, fieldList: any, opts?: any): Collection {
    return services.store.createIndex(
      this.mapper.name,
      name,
      fieldList,
      Object.assign({}, opts)
    );
  }

  filter(query: any, thisArg?: any): RecordType[] {
    return services.store.filter(this.mapper.name, query, thisArg);
  }

  get(id: string | number): RecordType {
    const record = services.store.get(this.mapper.name, id);
    // for backwards compat with js-data 2.x
    /* istanbul ignore next */
    if (!record && typeof id === 'number') {
      return services.store.get(this.mapper.name, String(id));
    } else {
      return record;
    }
  }

  getAll(...args): RecordType[] {
    return services.store.getAll(this.mapper.name, ...args);
  }

  query(): Query {
    return services.store.query(this.mapper.name);
  }

  remove(id: string | number, opts?: any): RecordType {
    return services.store.remove(this.mapper.name, id, Object.assign({}, opts));
  }

  removeAll(query?: any, opts?: any): void | RecordType[] {
    return services.store.removeAll(
      this.mapper.name,
      query,
      Object.assign({}, opts)
    );
  }

  create(record: any, opts?: any): Promise<RecordType> {
    return services.store.create(
      this.mapper.name,
      record,
      Object.assign({}, opts)
    );
  }

  createMany(records: any[], opts?: any): Promise<RecordType[]> {
    return services.store.createMany(
      this.mapper.name,
      records,
      Object.assign({}, opts)
    );
  }

  destroy(id: any, opts?: any): Promise<RecordType> {
    return services.store.destroy(
      this.mapper.name,
      id,
      Object.assign({}, opts)
    );
  }

  destroyAll(query?: any, opts?: any): Promise<RecordType[]> {
    return services.store.destroyAll(
      this.mapper.name,
      query,
      Object.assign({}, opts)
    );
  }

  find(id: any, opts?: any): Promise<RecordType> {
    opts = Object.assign({}, opts);
    // for backwards compat with js-data 2.x
    if (opts.bypassCache) {
      opts.force = true;
      delete opts.bypassCache;
    }
    return services.store.find(this.mapper.name, id, Object.assign({}, opts));
  }

  findAll(query?: any, opts?: any): Promise<RecordType[]> {
    opts = Object.assign({}, opts);
    // for backwards compat with js-data 2.x
    if (opts.bypassCache) {
      opts.force = true;
      delete opts.bypassCache;
    }
    return services.store.findAll(
      this.mapper.name,
      query,
      Object.assign({}, opts)
    );
  }

  getCollection(): Collection {
    return services.store.getCollection(this.mapper.name);
  }

  update(id: any, record: any, opts?: any): Promise<RecordType> {
    return services.store.update(
      this.mapper.name,
      id,
      record,
      Object.assign({}, opts)
    );
  }

  updateAll(props: any, query?: any, opts?: any): Promise<RecordType[]> {
    return services.store.updateAll(
      this.mapper.name,
      props,
      query,
      Object.assign({}, opts)
    );
  }

  updateMany(records: any, opts?: any): Promise<RecordType[]> {
    return services.store.updateMany(
      this.mapper.name,
      records,
      Object.assign({}, opts)
    );
  }

  is(record: any): boolean {
    return this.mapper.is(record);
  }

  createRecord(props?: any, opts?: any) {
    return services.store.createRecord(
      this.mapper.name,
      props,
      Object.assign({}, opts)
    );
  }

  // for backwards compat
  inject(records: any[], opts?: any): RecordType[];
  inject(records: any, opts?: any): RecordType;
  inject(records: any, opts?: any): any {
    return this.add(records, Object.assign({}, opts));
  }

  eject(id: string | number | RecordType, opts?: any) {
    // for backwards compatibility
    if (typeof id === 'object') {
      id = id[this.mapper.idAttribute];
    }
    return this.remove(id as string | number, Object.assign({}, opts));
  }

  // for backwards compatibility
  createInstance(props?: any, opts?: any) {
    return this.createRecord(props, opts);
  }

  // for backwards compatibility
  ejectAll(): void {
    this.getAll().forEach((record) => {
      this.remove(record[this.mapper.idAttribute]);
    });
  }
}
