import { Component, Input } from '@angular/core';
import { CrmPerson } from '@ui-resources-angular';

@Component({
  selector: 'ssi-crm-person-tags',
  templateUrl: './crm-person-tags.component.html'
})
export class CrmPersonTagsComponent {
  @Input() crmPerson: CrmPerson;

  constructor() {}
}
