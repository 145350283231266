import './chatbot-report.component.scss';

import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import {
  ChatbotAnalyticsService,
  FilterPeriod
} from '../chatbot-analytics.service';
import { SpreadsheetService } from '../../../../../common/services/spreadsheet/spreadsheet.service';
import { FileDownloadService } from '../../../../../common/services/file-download/file-download.service';
import { FileMimeTypes } from '../../../../../common/directives/file-download/file-download.directive';
import { AccountTypeId } from '../../../../../common/enums';
import { Debounce } from '../../../../../common/decorators';
import { GlobalLoaderService } from '../../../../../common/directives';
import { isElementWithinViewport } from '../../../../../common/utils';
import { commonDateRanges } from '../../../../../common/constants';
import { endOfDay, startOfDay } from 'date-fns';

export const reportColors = [
  '#14BAE3',
  '#D41D68',
  '#B2C614',
  '#425DEC',
  '#F88C68',
  '#F0B427',
  '#85A7FF',
  '#12ACA4',
  '#FFA7D1',
  '#FF0D0D'
];
@Component({
  selector: 'ssi-chatbot-report',
  templateUrl: './chatbot-report.component.html',
  styles: []
})
export class ChatbotReportComponent implements OnInit {
  chatbot: {
    id: string;
    name: string;
  } = {
    id: '',
    name: ''
  };
  navItems = this.getNavItems();
  reportActions = this.getReportActions();
  activeNavItemTag = 'summary';

  AccountTypeId = AccountTypeId;

  loading = false;

  report: {
    filter: {
      period: FilterPeriod;
    };
    reportData: any;
  } = {
    filter: {
      period: {
        start: '',
        end: ''
      }
    },
    reportData: {}
  };

  constructor(
    private state: StateService,
    private elementRef: ElementRef,
    private chatbotAnalytics: ChatbotAnalyticsService,
    private spreadsheet: SpreadsheetService,
    private fileDownload: FileDownloadService,
    private globalLoader: GlobalLoaderService
  ) {}

  async ngOnInit() {
    try {
      if (!this.state.params.botId) {
        return;
      }

      this.chatbot.id = this.state.params.botId;

      if (this.state.params.startDate && this.state.params.endDate) {
        this.report.filter.period.start = new Date(
          this.state.params.startDate
        ).toISOString();
        this.report.filter.period.end = new Date(
          this.state.params.endDate
        ).toISOString();
        this.loadReport();
      } else {
        if (this.state.params.name) {
          this.chatbot.name = this.state.params.name;
        }

        this.report.filter.period.start = new Date(
          commonDateRanges.month.start
        ).toISOString();
        this.report.filter.period.end = new Date(
          commonDateRanges.month.end
        ).toISOString();
        this.loadReport();
      }
    } catch (error) {
      console.error('Error loading Chatbot report page', error);
    }
  }

  async loadReport() {
    this.loading = true;
    try {
      const botMetricsData = await this.chatbotAnalytics.getBotMetrics(
        this.state.params.botId,
        this.report.filter.period
      );
      const formattedData = {
        ...botMetricsData,
        topUrls: Object.entries(botMetricsData.top_urls).map(
          ([url, countAndPercent]) => {
            return {
              url,
              visits: countAndPercent.count,
              percent: countAndPercent.percent
            };
          }
        ),
        topicUsage: Object.entries(botMetricsData.intent_usage).map(
          ([topicId, countAndPercent]) => {
            return {
              name: botMetricsData.names[topicId],
              count: countAndPercent.count,
              percent: countAndPercent.percent.toFixed(2) + '%'
            };
          }
        ),
        topicFeedback: Object.entries(botMetricsData.intent_net_feedback).map(
          ([topicId, score]) => {
            return {
              name: botMetricsData.names[topicId],
              score
            };
          }
        ),
        topTopics: Object.entries(botMetricsData.intent_usage_over_time).map(
          ([topicId, dateAndValue], index) => {
            return {
              name: botMetricsData.names[topicId],
              count: dateAndValue,
              color: reportColors[index]
            };
          }
        ),
        handoverPercent:
          (botMetricsData.metrics.handover_percent &&
            botMetricsData.metrics.handover_percent.toFixed(2) + '%') ||
          'N/A',
        deflectionPercent:
          (botMetricsData.metrics.deflection_percent &&
            botMetricsData.metrics.deflection_percent.toFixed(2) + '%') ||
          'N/A',
        feedbackResolvedPercent:
          (botMetricsData.metrics.feedback_resolved_percent &&
            botMetricsData.metrics.feedback_resolved_percent.toFixed(2) +
              '%') ||
          'N/A',
        feedbackUnresolvedPercent:
          (botMetricsData.metrics.feedback_unresolved_percent &&
            botMetricsData.metrics.feedback_unresolved_percent.toFixed(2) +
              '%') ||
          'N/A',
        feedbackNonePercent:
          (botMetricsData.metrics.feedback_none_percent &&
            botMetricsData.metrics.feedback_none_percent.toFixed(2) + '%') ||
          'N/A'
      };

      this.report = {
        filter: this.report.filter,
        reportData: formattedData
      };
      this.populateNavItemElements();
      this.loading = false;
    } catch (error) {
      console.error('Error loading Chatbot report:', error);

      return this.state.go('auth.settings.companysettings.chatbot');
    }
  }

  async reportDateSelected(event) {
    try {
      this.loading = true;
      this.report.filter.period = {
        start: startOfDay(new Date(event.from)).toISOString(),
        end: endOfDay(new Date(event.to)).toISOString()
      };
      await this.loadReport();
    } catch (error) {
      console.error('Error loading Chatbot report on date selected:', error);
    }
    this.loading = false;
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Summary',
        tag: 'summary',
        element: undefined
      },
      {
        label: 'Sessions',
        tag: 'sessions',
        element: undefined
      },
      {
        label: 'Handover',
        tag: 'handover',
        element: undefined
      },
      {
        label: 'Feedback',
        tag: 'feedback',
        element: undefined
      },
      {
        label: 'Top URLs',
        tag: 'top-urls',
        element: undefined
      },
      {
        label: 'Topic Usage',
        tag: 'topic-usage',
        element: undefined
      },
      {
        label: 'Top 10 Topics',
        tag: 'top-topics',
        element: undefined
      },
      {
        label: 'Topic Feedback',
        tag: 'topic-feedback',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      {
        icon: 'ssi-print',
        label: 'Download XLSX',
        func: () => this.downloadReport()
      },
      {
        icon: 'ssi-line-arrow-small-down',
        label: 'Back to Chatbots',
        func: () => {
          this.state.go('auth.settings.companysettings.chatbot');
        }
      }
    ];
  }

  async downloadReport() {
    this.globalLoader.show();
    try {
      const spreadsheetData = this.chatbotAnalytics.createSpreadsheetFromReport(
        this.report.filter.period,
        this.report.reportData,
        this.chatbot
      );
      const xlsx = await this.spreadsheet.jsonToXlsx(spreadsheetData);

      const config = {
        base64decode: true,
        filename: `Orlo - ${this.chatbot.name} - Chatbot report.xlsx`,
        mimetype: FileMimeTypes.Xlsx
      };

      this.fileDownload.download(xlsx, config);
    } catch (e) {
      console.error('Error generating report: ', e);
    }
    this.globalLoader.hide();
  }

  navigateToBuildPage() {
    this.state.go('auth.analytics.chatbot.build', {
      ...this.state.params,
      botId: this.chatbot.id
    });
  }
}
