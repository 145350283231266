<div class="top-posts-container">

  <div class="section-head">
    <h2>Top Posts</h2>
    <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
  </div>

  <div class="section-body"
       ssiLoader
       [ssiLoaderVisible]="loading">
    <ssi-top-post-list [posts]="postsToDisplay"
                       [selectedSortType]="selectedSortType"
                       (sortTypeChange)="onSortTypeChange($event)"></ssi-top-post-list>
  </div>
</div>