import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisingTargetingBuilderComponent } from './advertising-targeting-builder.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../../common/directives/off-click/off-click.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BigNumberModule,
    TranslateModule,
    TooltipModule,
    OffClickModule
  ],
  declarations: [AdvertisingTargetingBuilderComponent],
  exports: [AdvertisingTargetingBuilderComponent]
})
export class AdvertisingTargetingBuilderModule {}
