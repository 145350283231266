import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import strings from '../../../../../../library/constants/strings';
import { Agent } from '../../../../../../library/models/live-chat/agent';

import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { Conversation as ConversationModel } from '../../../../../../library/models/live-chat/conversation';

const STATUS_SORT_ORDER = [strings.online, strings.busy, strings.offline];

@Component({
  selector: 'ssi-live-chat-team-dashboard',
  templateUrl: './live-chat-team-dashboard.component.html',
  styles: []
})
export class LiveChatTeamDashboardComponent implements OnDestroy, OnInit {
  @Input() quantityOfUnassignedConversations: number;
  @Input() quantityOfTotalConversations: number;
  @Input() quantityOfAssignedConversations: number;
  @Input() stalestConversation: ConversationModel;
  @Input() teams: ConversationModel;

  public agents: Agent[];

  private _agentsSubscription: Subscription;

  constructor(private liveChatService: LiveChatService) {}

  public ngOnDestroy() {}

  public ngOnInit() {
    const sortAlphabetically = (a: Agent, b: Agent) =>
      a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;

    this._agentsSubscription = this.liveChatService.agentsReference
      .snapshotChanges()
      .subscribe((results) => {
        const agents = results.map(
          (result) =>
            new Agent(result.payload.doc.id, result.payload.doc.data())
        );

        const sortedAgents = [
          ...agents.filter((agent) => agent.isOnline).sort(sortAlphabetically),
          ...agents.filter((agent) => agent.isAway).sort(sortAlphabetically),
          ...agents.filter((agent) => agent.isOffline).sort(sortAlphabetically)
        ];

        sortedAgents.some((agent, index) => {
          if (agent.id === this.liveChatService.agentModel.id) {
            sortedAgents.splice(index, 1);
            sortedAgents.unshift(agent);

            return true;
          }
        });

        this.agents = sortedAgents;
      });
  }
}
