<div class="message-box-actions-menu">
  <div class="message-box-actions-menu-item-button">
    <i class="menu-item-icon ssi ssi-add-sentiment"></i>
    <p class="menu-item-title">
      Amend Sentiment
    </p>
  </div>
  <div class="message-box-actions-menu-item-body">
    <ssi-sentiment-list
      [isInsights]="isInsights"
      [sentiment]="sentiment"
      (changeSentiment)="onChangeSentiment.emit($event)"
    ></ssi-sentiment-list>
  </div>
</div>