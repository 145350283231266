import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { OrloComponentsModule } from 'orlo-components';
import { EmojiFormControlContainerModule } from '../../directives/emoji-form-control-container/emoji-form-control-container.module';
import { ReplyBoxComponent } from './reply-box.component';
import { FileUploaderModule } from '../../directives/file-uploader/file-uploader.module';
import { LabelTagModule } from '../label-tag/label-tag.module';
import { CountdownModule } from '../countdown/countdown.module';
import { WaveDotsModule } from '../wave-dots/wave-dots.module';
import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { ActivitySnippetsModule } from '../../directives/activity-snippets/activity-snippets.module';
import { TemplateSelectorModule } from '../../directives/template-selector/template-selector.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { IconModule } from '../icon/icon.module';
import { ConversationResolveModule } from '../conversation-resolve/conversation-resolve.module';
import { ConversationHoldModule } from '../conversation-hold/conversation-hold.module';
import { EmojiAutocompleteModule } from '../emoji-autocomplete/emoji-autocomplete.module';
import { GifSearchModule } from '../../directives/gif-search/gif-search.module';
import { FocusOnModule } from '../../directives/focus-on/focus-on.module';

@NgModule({
  imports: [
    CommonModule,
    EmojiFormControlContainerModule,
    FormsModule,
    TranslateModule,
    FileUploaderModule,
    OrloComponentsModule,
    LabelTagModule,
    CountdownModule,
    WaveDotsModule,
    DropdownSelect2Module,
    TooltipModule,
    NgbDropdownModule,
    OffClickModule,
    ActivitySnippetsModule,
    TemplateSelectorModule,
    FallbackImageModule,
    IconModule,
    ConversationResolveModule,
    ConversationHoldModule,
    EmojiAutocompleteModule,
    GifSearchModule,
    FocusOnModule
  ],
  declarations: [ReplyBoxComponent],
  exports: [ReplyBoxComponent]
})
export class ReplyBoxModule {}
