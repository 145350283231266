import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { IconModule } from '../../../../../../common/components/icon/icon.module';
import { WidgetTrustometerGaugeComponent } from './widget-trustometer-gauge.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WidgetTrustometerGaugeComponent],
  imports: [
    CommonModule,
    NgbModule,
    TooltipModule,
    IconModule,
    TranslateModule
  ],
  exports: [WidgetTrustometerGaugeComponent]
})
export class WidgetTrustometerGaugeModule {}
