import { appInjector } from '../../../../app-injector';
import { objKeysMatch } from '../../../utils';
import { Colleague, ColleaguesService } from '../colleagues';

export interface ListeningPage {
  id: string;
  social_id: string;
  name: string;
  account_type_id: number;
  username: string;
  created_at: string;
  created_by: number;
  listening_group_id?: string;
}

export interface ListeningGroup {
  id: string;
  name: string;
}

export class ListeningGroup {
  id: string;
  name: string;

  constructor(props: any = {}) {
    this.id = props.id;
    this.name = props.name;
  }
}

export class ListeningPage {
  id: string;
  social_id: string;
  name: string;
  account_type_id: number;
  username: string;
  created_at: string;
  created_by: number;
  listening_group_id?: string;

  constructor(props: any = {}) {
    this.id = props.social_id;
    this.social_id = props.social_id;
    this.name = props.name;
    this.account_type_id = props.account_type_id;
    this.username = `@${props.username}`;
    this.created_at = props.created_at;
    this.created_by = props.created_by;
  }

  get createdBy() {
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.created_by);
  }

  /**
   * Checks whether the provided object is an instance of Team,
   * but in a way of checking if the object has a set of property keys defined on the Team class.
   *
   * Helpful in a situation where 'value instanceof Team' can't be used
   * (e.g. the actual instance of Team is re-mapped to plain JS object)
   */
  static is(value: any): boolean {
    return objKeysMatch(value, new ListeningPage());
  }
}
