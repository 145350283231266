import { Component, Input, TemplateRef } from '@angular/core';
import { WeekViewHourSegment } from '../../../../../common/components/calendar-utils';

@Component({
  selector: 'ssi-calendar-week-view-hour-segment',
  template: `
    <ng-template
      #defaultTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
      let-daysInWeek="daysInWeek"
      let-columnEvents="columnEvents"
    >
      <div
        [attr.aria-hidden]="
          {}
            | calendarA11y
              : (daysInWeek === 1
                  ? 'hideDayHourSegment'
                  : 'hideWeekHourSegment')
        "
        class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
      >
        <div class="cal-time" *ngIf="isTimeLabel">
          {{
            segment.displayDate
              | calendarDate
                : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour')
                : locale
          }}
        </div>
      </div>
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        segment: segment,
        locale: locale,
        segmentHeight: segmentHeight,
        isTimeLabel: isTimeLabel,
        daysInWeek: daysInWeek,
        columnEvents: columnEvents
      }"
    >
    </ng-template>
  `
})
export class CalendarWeekViewHourSegmentComponent {
  @Input() segment: WeekViewHourSegment;
  @Input() segmentHeight: number;
  @Input() columnEvents: any[];
  @Input() locale: string;
  @Input() isTimeLabel: boolean;
  @Input() daysInWeek: number;
  @Input() customTemplate: TemplateRef<any>;
}
