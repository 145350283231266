<div class="list-select2-container">
  <div class="filter-container"
       *ngIf="filterable">
    <input class="g-input no-border"
           type="search"
           [(ngModel)]="filterTerm"
           (ngModelChange)="filter(filterTerm)"
           [placeholder]="filterPlaceholder">
    <i class="ssi ssi-search-small"></i>
  </div>

  <div class="toggle-all-container"
       [class.filter-visible]="filterable"
       *ngIf="multiple && toggleAllEnabled">
    <ssi-checkbox-2 [label]="toggleAllLabel"
                    [variant]="'square'"
                    [reversed]="true"
                    [(ngModel)]="toggleAllChecked"
                    (ngModelChange)="toggleAllOptions($event)"></ssi-checkbox-2>
  </div>

  <ul>
    <li *ngFor="let option of filteredOptions"
        [class.selected]="optionSelected(option)">
      <div class="main-content"
           (click)="toggleOption(option);"
           [ssiTooltip]="getTooltipContent ? getTooltipContent(option) : ''"
           [tooltipDisabled]="!getTooltipContent"
           [tooltipDelay]="1000"
           tooltipWindowClass="tooltip-chunky">

        <div class="icon-container"
             *ngIf="templateRefs[DDTemplateSelectors.optionIcon]">
          <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.optionIcon]; context: {option: option, selected: optionSelected(option)}"></ng-container>
        </div>

        <span *ngIf="!templateRefs[DDTemplateSelectors.optionLabel]">{{getOptionLabel ? getOptionLabel(option) : findOptionLabel(option)}}</span>
        <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.optionLabel]; context: {option: option, selected: optionSelected(option)}"></ng-container>

        <div class="tick-container"
             [class.radio]="!multiple"
             [class.checked]="optionSelected(option)">
          <i class="ssi ssi-tick-small tick"></i>
        </div>
      </div>

      <div class="expanded-content-container"
           *ngIf="templateRefs[DDTemplateSelectors.expandedContent]">
        <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.expandedContent]; context: {option: option, selected: optionSelected(option)}"></ng-container>
      </div>
    </li>
  </ul>
</div>