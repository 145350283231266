import {
  Account,
  ActivityTags,
  CrmExternalIntegration
} from '@ui-resources-angular';
import inboxImg from './img/inbox-infographic.png';
import {
  TriggerConfig,
  ActionConfig,
  UIParam,
  UIParamFormType,
  staticParams,
  ParamKeyValueMap
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import {
  ServiceMapping,
  overlayDimensions,
  mapSelectOptions,
  getParamByName,
  minShouldMatchParamFactory,
  IngredientConfig,
  replaceTemplateIngredientsWithHtml,
  IngredientType
} from './util';
import { AppletTriggerParam, AppletActionParam } from '../applets/applet';
import { Webhook } from '../../../../../common/services/webhook-model/webhook-model';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../common/services/api';
import { Injector } from '@angular/core';
import {
  IftttServiceModel,
  IftttService
} from '../ifttt-service-model/ifttt-service-model';
import { inboxPriorities } from '../../../../../common/constants';

const triggers: TriggerConfig[] = [
  {
    api: {
      name: 'from account'
    },
    isAccountTrigger: true,
    getLabel(params: AppletTriggerParam[]) {
      const accounts: Account[] = getParamByName(params, 'accounts').value;
      return (
        'Is on account ' +
        accounts
          .map(
            (account) => `${account.displayName} (${account.account_type_name})`
          )
          .join(' or ')
      );
    }
  },
  {
    api: {
      name: 'in reply to outbox message'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_IN_REPLY_TO_OUTBOX_MESSAGE_TITLE',
      description: 'INBOX_TRIGGER_IN_REPLY_TO_OUTBOX_MESSAGE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-outbox',
    getLabel() {
      return `Is in reply to an outbox message`;
    }
  },
  {
    api: {
      name: 'in reply to outbox author'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_IN_REPLY_TO_OUTBOX_AUTHOR_TITLE',
      description: 'INBOX_TRIGGER_IN_REPLY_TO_OUTBOX_AUTHOR_DESCRIPTION'
    },
    iconClass: 'ssi ssi-users-group',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const users: Colleague[] = getParamByName(params, 'users').value.map(
        (user) => user.fullName
      );
      return `Is in reply to outbox messages by ${users.join(' or ')}`;
    }
  },
  {
    api: {
      name: 'queries match content'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_CONTAINING_WORD_TITLE',
      description: 'INBOX_TRIGGER_CONTAINING_WORD_DESCRIPTION'
    },
    iconClass: 'ssi ssi-document-help',
    overlayDimensions: overlayDimensions.tagsInput,
    getLabel(params: AppletTriggerParam[]) {
      const mode: number = getParamByName(params, 'mode').value;
      const queries: string[] = getParamByName(params, 'queries').value;
      const operand: string =
        getParamByName(params, 'min_should_match').value === 'or'
          ? 'OR'
          : 'AND';
      return `${
        mode === 0 ? 'Contains the text' : 'Matches word(s)'
      } ${queries.map((query) => `"${query}"`).join(` ${operand} `)}`;
    },
    paramOverrides: {
      min_should_match: () => minShouldMatchParamFactory(),
      mode(): UIParam {
        return {
          form: {
            type: UIParamFormType.Hidden,
            defaultValue: 0,
            showAsCheckboxInput: true
          }
        };
      }
    }
  },
  {
    api: {
      name: 'social profile linked to crm integration'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_AUTHOR_HAS_CRM_RECORD_TITLE',
      description: 'INBOX_TRIGGER_AUTHOR_HAS_CRM_RECORD_DESCRIPTION'
    },
    iconClass: 'fa fa-link',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: overlayDimensions.select.height + 150
    },
    getLabel(params: AppletTriggerParam[]) {
      const operand: string = getParamByName(params, 'min_should_match').value;
      const integrations: CrmExternalIntegration[] = getParamByName(
        params,
        'integration_uuids'
      ).value;
      const integrationsLabel = integrations
        .map((integration) => integration.name)
        .join(` ${operand} `);
      return `Author has a CRM record on ${integrationsLabel}`;
    },
    paramOverrides: {
      min_should_match: () =>
        minShouldMatchParamFactory({
          orLabel: 'Author is linked to any of these crm integrations',
          andLabel: 'Author is linked to all of these crm integrations'
        })
    }
  },
  {
    api: {
      name: 'sentiment'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_SENTIMENT_TITLE',
      description: 'INBOX_TRIGGER_SENTIMENT_DESCRIPTION'
    },
    iconClass: 'ssi ssi-semi-positive',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 220
    },
    getLabel(params: AppletTriggerParam[]) {
      const sentimentKeys = getParamByName(
        params,
        'sentiments'
      ) as AppletTriggerParam;
      const sentiment: string[] = mapSelectOptions(
        sentimentKeys,
        sentimentKeys.param.form.sentiment.options
      );
      return `Sentiment is ${sentiment.join(' or ')}`;
    }
  },
  {
    api: {
      name: 'language'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_LANGUAGE_TITLE',
      description: 'INBOX_TRIGGER_LANGUAGE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-language',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const languageKeys = getParamByName(
        params,
        'languages'
      ) as AppletTriggerParam;
      const langs: string[] = mapSelectOptions(
        languageKeys,
        languageKeys.param.form.select.options
      );
      return `Language is ${langs.join(' or ')}`;
    }
  },
  {
    api: {
      name: 'is public'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_IS_PUBLIC_TITLE',
      description: 'INBOX_TRIGGER_IS_PUBLIC_DESCRIPTION'
    },
    iconClass: 'ssi ssi-public-correct',
    getLabel() {
      return `Is public`;
    }
  },
  {
    api: {
      name: 'is private'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_IS_PRIVATE_TITLE',
      description: 'INBOX_TRIGGER_IS_PRIVATE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-private-correct',
    getLabel() {
      return `Is private`;
    }
  },
  {
    api: {
      name: 'profile has tags'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_PROFILE_HAS_TAG_TITLE',
      description: 'INBOX_TRIGGER_PROFILE_HAS_TAG_DESCRIPTION'
    },
    iconClass: 'ssi ssi-tag',
    overlayDimensions: overlayDimensions.tagsInput,
    getLabel(params: AppletTriggerParam[]) {
      const tags: string[] = getParamByName(params, 'tags').value.map(
        (tag) => `"${tag}"`
      );
      const operand: string =
        getParamByName(params, 'min_should_match').value === 'or'
          ? 'OR'
          : 'AND';
      return `Profile has tags ${tags.join(` ${operand} `)}`;
    },
    paramOverrides: {
      min_should_match: () => minShouldMatchParamFactory()
    }
  },
  {
    api: {
      name: 'ai classifications'
    },
    translationIds: {
      title: 'AI Classifications',
      description: 'All messages given a classification by AI'
    },
    iconClass: 'fa fa-warning',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: overlayDimensions.select.height
    },
    getLabel(params: AppletTriggerParam[]) {
      const classificationsKeys = getParamByName(
        params,
        'classifications'
      ) as AppletTriggerParam;
      const classifications: string[] = mapSelectOptions(
        classificationsKeys,
        classificationsKeys.param.form.select.options
      );
      const operand: string =
        getParamByName(params, 'min_should_match').value === 'or'
          ? 'OR'
          : 'AND';
      return `Messages have the classification of ${classifications.join(
        ` ${operand} `
      )}`;
    },
    paramOverrides: {
      min_should_match: () => minShouldMatchParamFactory()
    }
  },
  {
    api: {
      name: 'created in office hours'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_CREATED_IN_OFFICE_HOURS_TITLE',
      description: 'INBOX_TRIGGER_CREATED_IN_OFFICE_HOURS_DESCRIPTION'
    },
    iconClass: 'ssi ssi-clock',
    getLabel(params: AppletTriggerParam[]) {
      const inHours: boolean = getParamByName(params, 'in_hours').value;
      return inHours
        ? 'Created within office hours'
        : 'Created outside of office hours';
    },
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 270
    },
    paramOverrides: {
      in_hours() {
        return {
          form: {
            type: UIParamFormType.SelectSingle,
            select: {
              options: [
                {
                  value: true,
                  label: 'Is within office hours',
                  trackBy: 0
                },
                {
                  value: false,
                  label: 'Is outside of office hours',
                  trackBy: 1
                },
                {
                  value: undefined,
                  label: 'All hours',
                  trackBy: 2
                }
              ]
            }
          }
        };
      }
    }
  },
  {
    api: {
      name: 'influence in range'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_INFLUENCE_IN_RANGE_TITLE',
      description: 'INBOX_TRIGGER_INFLUENCE_IN_RANGE_DESCRIPTION'
    },
    iconClass: 'fa fa-star',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 220
    },
    getLabel(params: AppletTriggerParam[]) {
      const lowerBound = getParamByName(params, 'lower_bound').value;
      const upperBound = getParamByName(params, 'upper_bound').value;
      return `Author influence score is between ${lowerBound} and ${upperBound}`;
    },
    paramOverrides: {
      lower_bound(): UIParam {
        return {
          form: {
            type: UIParamFormType.Range,
            defaultValue: 0
          }
        };
      },
      upper_bound(): UIParam {
        return {
          form: {
            type: UIParamFormType.Range,
            defaultValue: 100
          }
        };
      },
      in_range(): UIParam {
        return {
          form: {
            type: UIParamFormType.Hidden,
            defaultValue: true
          }
        };
      }
    }
  },
  {
    api: {
      name: 'word count'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_WORD_COUNT_TITLE',
      description: 'INBOX_TRIGGER_WORD_COUNT_DESCRIPTION'
    },
    iconClass: 'ssi ssi-character-count',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 320
    },
    getLabel(params: AppletTriggerParam[]) {
      const limit = getParamByName(params, 'limit').value;
      const mode = getParamByName(params, 'compare_mode').value;
      const labels = {
        GT: 'greater than',
        LT: 'less than',
        GTE: 'greater than or equal to',
        LTE: 'less than or equal to'
      };
      return `Message word count is ${labels[mode]} ${limit}`;
    },
    paramOverrides: {
      compare_mode(): UIParam {
        return {
          form: {
            type: UIParamFormType.SelectSingle,
            select: {
              options: [
                {
                  value: 'GT',
                  label: 'Greater than',
                  trackBy: 0
                },
                {
                  value: 'LT',
                  label: 'Less than',
                  trackBy: 1
                },
                {
                  value: 'GTE',
                  label: 'Greater than or equal to',
                  trackBy: 2
                },
                {
                  value: 'LTE',
                  label: 'Less than or equal to',
                  trackBy: 3
                }
              ]
            }
          }
        };
      },
      limit(): UIParam {
        return {
          form: {
            type: UIParamFormType.TextInput,
            input: {
              placeholderTranslationKey: 'INBOX_TRIGGER_WORD_COUNT_PLACEHOLDER',
              type: 'number'
            }
          }
        };
      }
    }
  }
];

const actions: ActionConfig[] = [
  {
    api: {
      name: 'create note'
    },
    translationIds: {
      title: 'INBOX_ACTION_CREATE_NOTE_TITLE',
      description: 'INBOX_ACTION_CREATE_NOTE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-feather-note',
    overlayDimensions: overlayDimensions.textarea,
    getLabel(
      params: AppletActionParam[],
      injector: Injector,
      iftttService: IftttService
    ) {
      const text = replaceTemplateIngredientsWithHtml(
        getParamByName(params, 'body').value,
        injector,
        iftttService
      );
      return `Add the note "${text}"`;
    }
  },
  {
    api: {
      name: 'assign to user'
    },
    translationIds: {
      title: 'INBOX_ACTION_ASSIGN_TO_USER_TITLE',
      description: 'INBOX_ACTION_ASSIGN_TO_USER_DESCRIPTION'
    },
    iconClass: 'ssi ssi-oval-user-icon',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const user: Colleague = getParamByName(params, 'user_id').value;
      return `Assign to ${user && user.fullName}`;
    }
  },
  {
    api: {
      name: 'assign to team'
    },
    translationIds: {
      title: 'INBOX_ACTION_ASSIGN_TO_TEAM_TITLE',
      description: 'INBOX_ACTION_ASSIGN_TO_TEAM_DESCRIPTION'
    },
    iconClass: 'ssi ssi-assign-team',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const team: Team = getParamByName(params, 'team_id').value;
      return `Assign to ${!!team ? team.name : 'unknown'}`;
    }
  },
  {
    api: {
      name: 'set priority'
    },
    translationIds: {
      title: 'SET_PRIORITY',
      description: 'SET_PRIORITY_TO_THE_MESSAGE'
    },
    iconClass: 'ssi ssi-priority',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      return `Set Priority 0${getParamByName(params, 'priority').value}`;
    },
    paramOverrides: {
      priority(): UIParam {
        // will override 'int' param defined in ifttt-trigger-action-params.service.ts, getParamConfigFactories
        // (variableType comes in as 'int' from the backend - GET /ifttt/iftttService)
        return {
          form: {
            type: UIParamFormType.SelectSingle,
            select: {
              options: inboxPriorities.map((p) => {
                return { value: p, label: `Priority 0${p}`, trackBy: p };
              })
            }
          }
        };
      }
    }
  },
  {
    api: {
      name: 'add tag'
    },
    translationIds: {
      title: 'INBOX_ACTION_ADD_TAG_TITLE',
      description: 'INBOX_ACTION_ADD_TAG_DESCRIPTION'
    },
    iconClass: 'ssi ssi-tag',
    overlayDimensions: overlayDimensions.textInput,
    getLabel(params: AppletActionParam[]) {
      const tag: string = getParamByName(params, 'tag').value;
      return `Add the tag "${tag}"`;
    },
    migrateParams(params: ParamKeyValueMap): ParamKeyValueMap {
      return {
        tags: [params.tag]
      };
    }
  },
  {
    api: {
      name: 'add tags'
    },
    translationIds: {
      title: 'INBOX_ACTION_ADD_TAGS_TITLE',
      description: 'INBOX_ACTION_ADD_TAGS_DESCRIPTION'
    },
    iconClass: 'ssi ssi-tag',
    overlayDimensions: overlayDimensions.tagsInput,
    getLabel(params: AppletActionParam[]) {
      const tags: string[] = getParamByName(params, 'tags').value;
      return `Add the tags "${tags.join('", "')}"`;
    },
    paramOverrides: {
      async tags(injector: Injector) {
        const tags = await injector.get(ActivityTags).getTags();
        const paramConfig = staticParams.tagsInput();
        paramConfig.form.input.promptOptions = tags;
        paramConfig.form.input.placeholderTranslationKey =
          'TYPE_YOUR_TAG_AND_PRESS_ENTER';
        return paramConfig;
      }
    }
  },
  {
    api: {
      name: 'send webhook message'
    },
    translationIds: {
      title: 'OUTBOX_ACTION_SEND_WEBHOOK_MESSAGE_TITLE',
      description: 'OUTBOX_ACTION_SEND_WEBHOOK_MESSAGE_DESCRIPTION'
    },
    iconClass: 'fa fa-plug',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const webhook: Webhook = getParamByName(params, 'webhook_id').value;
      return `Send a webhook notification to "${webhook && webhook.name}"`;
    }
  },
  {
    api: {
      name: 'action message'
    },
    translationIds: {
      title: 'OUTBOX_ACTION_MARK_AS_ACTIONED_TITLE',
      description: 'OUTBOX_ACTION_MARK_AS_ACTIONED_DESCRIPTION'
    },
    iconClass: 'ssi ssi-actioned',
    getLabel() {
      return 'Mark all messages as "Actioned" in your inbox';
    }
  }
];

const ingredients: IngredientConfig[] = [
  {
    type: IngredientType.Template,
    api: {
      name: 'author.name'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_AUTHOR_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'account.name'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_ACCOUNT_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'account_type.name'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_ACCOUNT_TYPE_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'interaction.content'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_INTERACTION_CONTENT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'last_replier.name'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_LAST_REPLIER_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'sentiment'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_SENTIMENT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'visibility'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_VISIBILITY_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'interaction.created_at'
    },
    translationIds: {
      label: 'INBOX_INGREDIENT_CREATED_AT_LABEL'
    }
  }
];

const service: ServiceMapping = {
  id: 'activity.imported.incoming',
  brand: {
    img: inboxImg,
    iconClass: 'ssi ssi-ongoing-message'
  },
  triggers,
  actions,
  ingredients,
  translationIds: {
    title: 'INBOX_TITLE',
    triggers: 'INBOX_TRIGGERS',
    actions: 'INBOX_ACTIONS'
  }
};

export default service;
