import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetsGridComponent } from './widgets-grid.component';

import { KtdGridModule } from '../../../../../../common/components/ktd-grid/grid.module';
import { HighchartModule } from '../../../../../../common/components/highchart/highchart.module';
import { LoaderModule } from '../../../../../../common/directives';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { FormatWidgetFilterModule } from '../../../common/pipes/format-widget-filter/format-widget-filter.module';
import { WidgetTableModule } from '../widget-table/widget-table.module';
import { WidgetKpiModule } from '../widget-kpi/widget-kpi.module';
import { WidgetChartBarModule } from '../widget-chart-bar/widget-chart-bar.module';
import { WidgetChartLineModule } from '../widget-chart-line/widget-chart-line.module';
import { WidgetChartPieModule } from '../widget-chart-pie/widget-chart-pie.module';
import { WidgetSwotModule } from '../widget-swot/widget-swot.module';
import { WidgetBigModule } from '../widget-big/widget-big.module';
import { WidgetInfluencersListModule } from '../widget-influencers-list/widget-influencers-list.module';
import { SentimentGaugeModule } from '../sentiment-gauge/sentiment-gauge.module';
import { WidgetTopInterestsIndustriesModule } from '../widget-top-interests-industries/widget-top-interests-industries.module';
import { WidgetTrustometerGaugeModule } from '../widget-trustometer-gauge/widget-trustometer-gauge.module';
import { WidgetPositiveNegativeThemesModule } from '../widget-positive-negative-themes/widget-positive-negative-themes.module';
import { WidgetThemeCloudModule } from '../widget-theme-cloud/widget-theme-cloud.module';

@NgModule({
  imports: [
    CommonModule,
    KtdGridModule,
    HighchartModule,
    LoaderModule,
    TooltipModule,
    FormatWidgetFilterModule,
    WidgetTableModule,
    WidgetKpiModule,
    WidgetChartBarModule,
    WidgetChartLineModule,
    WidgetChartPieModule,
    WidgetSwotModule,
    WidgetBigModule,
    WidgetInfluencersListModule,
    SentimentGaugeModule,
    WidgetTopInterestsIndustriesModule,
    SentimentGaugeModule,
    WidgetTrustometerGaugeModule,
    SentimentGaugeModule,
    WidgetPositiveNegativeThemesModule,
    WidgetThemeCloudModule
  ],
  exports: [WidgetsGridComponent],
  declarations: [WidgetsGridComponent]
})
export class WidgetsGridModule {}
