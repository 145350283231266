import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';

import { SurveyComponent } from './survey.component';

import { BuildModule } from './build/build.module';
import { ReportModule } from './report/report.module';

@NgModule({
  declarations: [SurveyComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.survey',
          url: '/survey',
          abstract: true, // will auto redirect to 'auth.analytics.survey.build' child state
          component: SurveyComponent
        }
      ]
    }),
    BuildModule,
    ReportModule
  ],
  providers: [],
  entryComponents: [SurveyComponent]
})
export class SurveyModule {}
