<!-- Inbox events--------------------------------------------------------------------------------------- -->
<div *ngIf="auditEvent.verb === AuditEventVerb.Viewed"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-viewed action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>viewed this message</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.StatusChange"
     class="audit-event verb-{{auditEvent.verb}} status-{{auditEvent.params.status}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i *ngIf="auditEvent.params.status === 'unread'"
         class="ssi ssi-new-message action-icon"></i>
      <i *ngIf="auditEvent.params.status === 'actioned'"
         class="ssi ssi-actioned-new action-icon"></i>
      <i *ngIf="auditEvent.params.status === 'unactioned'"
         class="ssi ssi-unactioned action-icon"></i>
      <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>marked this message</strong> <strong class="capitalized">{{auditEvent.params.status}}</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Assigned"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-assigned action-icon"></i>
      <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>assigned this message</strong></span>
      <span>to {{auditEvent.params.userOrTeamName}}</span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Unassigned"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-unassigned action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>unassigned this message</strong></span>
      <span>from {{auditEvent.params.userOrTeamName}}</span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.SentimentChanged"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi {{auditEvent.params.sentimentConst.icon}} action-icon"
         [style.color]="auditEvent.params.sentimentConst.iconColor"
         [style.background-color]="auditEvent.params.sentimentConst.iconBgColor"></i>
      <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>amended the sentiment of this message to {{auditEvent.params.sentimentConst.label | translate}}</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.AddedTags"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-tags action-icon"></i>
      <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>added Message Tags</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
    <div class="params">
      <span class="tag added-tag"
            *ngFor="let tag of auditEvent.params.tags">{{tag}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.RemovedTags"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-tags action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>removed Message Tags</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
    <div class="params">
      <span class="tag removed-tag"
            *ngFor="let tag of auditEvent.params.tags">{{tag}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.AddedNote"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-orlo-note action-icon"></i>
      <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>added Message Note</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
    <div class="params">
      <ng-container *ngIf="auditEvent.result.note_text.length > 220 && !expandedNoteText">
        <div
          class="note"
          [innerHTML]="(auditEvent.result.note_text | nl2br | slice:0:220) + '...'"
        ></div>
        <span
          class="note-action"
          (click)="expandedNoteText = true"
        >read more</span>
      </ng-container>
      <div
        *ngIf="220 >= auditEvent.result.note_text.length || expandedNoteText"
        class="note"
        [innerHTML]="auditEvent.result.note_text | nl2br"
      ></div>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.DeletedNote"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-orlo-note action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>deleted Message Note</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
    <div class="params">
      <div class="note"
           [innerHTML]="auditEvent.result.note_text | nl2br"></div>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.SocialLiked"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-liked action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>liked this message</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.SocialUnliked"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-like action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>unliked this message</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.SocialHide"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-hide-action action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>hid this comment</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.SocialUnHide"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-unhide action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>unhid this comment</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Emailed"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-forward action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>forwarded an email of this message</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Blocked"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-blocked action-icon"></i>
      <!-- <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span> -->
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>blocked</strong></span>
      <span>{{auditEvent.object.profile ? '@'+auditEvent.object.profile.info.username : auditEvent.object.profile_id}} on {{auditEvent.account.account_type_name}}</span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Unblocked"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-tick-small action-icon"></i>
      <!-- <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span> -->
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>unblocked</strong></span>
      <span>{{auditEvent.object.profile ? '@'+auditEvent.object.profile.info.username : auditEvent.object.profile_id}} on {{auditEvent.account.account_type_name}}</span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Redacted"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-redacted action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>redacted this message</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.SensitiveMediaViewed"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-actioned-new action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>revealed the attachment</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.SensitiveMediaHidden"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-unactioned action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>hid the attachment</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<!-- Common (Inbox and Outbox) events------------------------------------------------------------------------------ -->
<div *ngIf="auditEvent.verb === AuditEventVerb.Deleted"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-delete action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>deleted this {{auditEvent.object.activity_id ? 'message' : 'post'}}</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>


<!-- Outbox events--------------------------------------------------------------------------------------- -->
<div *ngIf="auditEvent.verb === AuditEventVerb.Created"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-compose-post action-icon"
         *ngIf="auditEvent.params.published_date"></i>
      <i class="ssi ssi-schedule-small action-icon"
         *ngIf="!auditEvent.params.published_date"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>created this post at</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
      <ng-container *ngIf="auditEvent.params.published_date">
        <span>and it was <strong>published at</strong></span>
        <span class="time">{{auditEvent.params.published_date | date: 'hh:mm a'}}</span>
      </ng-container>
      <ng-container *ngIf="auditEvent.params.scheduled_date">
        <span>and <strong>scheduled it for</strong></span>
        <span class="time">{{auditEvent.params.scheduled_date | date: 'hh:mm a, dd/MM/yy'}}</span>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Updated"
     class="audit-event verb-{{auditEvent.verb}}"
     [class.re-scheduled]="auditEvent.params.scheduled_date">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-schedule-small action-icon"
         *ngIf="auditEvent.params.scheduled_date"></i>
      <i class="ssi ssi-correct-edit-small action-icon"
         *ngIf="!auditEvent.params.scheduled_date"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <ng-container *ngIf="auditEvent.params.scheduled_date">
        <span>{{auditEvent.actor.name}}</span>
        <span><strong>re-scheduled this post for</strong></span>
        <span class="time">{{auditEvent.params.scheduled_date | date: 'hh:mm a, dd/MM/yy'}}</span>
      </ng-container>
      <ng-container *ngIf="!auditEvent.params.scheduled_date">
        <span>{{auditEvent.actor.name}}</span>
        <span><strong>edited this post</strong></span>
        <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Accepted"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-tick-small action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>approved this post</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Rejected"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-times action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>did not approve this post</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Published"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-compose-post action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>published this post</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.PublishFailed"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-warning action-icon"></i>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>failed to publish this post</strong></span>
      <span class="time">{{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Resolved"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-completed-notification action-icon"></i>
      <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>resolved this message</strong></span>
      <span class="time">at {{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>

<div *ngIf="auditEvent.verb === AuditEventVerb.Unresolved"
     class="audit-event verb-{{auditEvent.verb}}">
  <div class="date-box">
    <span>{{auditEvent.timestamp | date: 'dd LLL'}}</span>
  </div>
  <div class="icon-box">
    <div class="icon-container">
      <i class="ssi ssi-fail action-icon"></i>
      <span class="trigger-label"
            *ngIf="auditEvent.actor.isTrigger">Trigger</span>
    </div>
    <div class="delimiter"
         *ngIf="!last"></div>
  </div>
  <div class="result-box">
    <div class="message">
      <span>{{auditEvent.actor.name}}</span>
      <span><strong>unresolved this message</strong></span>
      <span class="time">at {{auditEvent.timestamp | date: 'hh:mm a'}}</span>
    </div>
  </div>
</div>