<ul
  *ngIf="isInitialised"
>
  <li
    class="exit"
  >
    <button
      (click)="closeLiveChatView()"
      [disabled]="!! isInterfaceDisabled"
      type="button"
    >
      <i class="icon ssi ssi-chevron-left"></i>

      <span
        class="text"
        translate="EXIT_LIVE_CHAT"
      ></span>
    </button>
  </li>

  <li
    class="sorting"
  >
    <button
      [disabled]="!! isInterfaceDisabled"
      ngbDropdown
      ngbDropdownToggle
      type="button"
    >
      <span
        class="text"
        translate="{{ selectedOption.translate }}"
      ></span>

      <i class="icon ssi ssi-arrow-down-correct"></i>

      <ul
        class="dropdown-menu"
        ngbDropdownMenu
      >
      <!-- click handler won't work when created via for loop ... ?
        <li
          *ngFor="let option of sortingOptions; let index = index"
          (click)="selectOption(option.value)"
        >
          <button
            (click)="selectOption(option.value)"
            type="button"
          >
            <span
              translate="{{ option.translate }}"
            ></span>
          </button>
        </li> -->

        <li>
          <button
            (click)="selectOption('desc')"
            type="button"
          >
            <span
              translate="SHORTEST_WAITING"
            ></span>
          </button>
        </li>

        <li>
          <button
            (click)="selectOption('asc')"
            type="button"
          >
            <span
              translate="LONGEST_WAITING"
            ></span>
          </button>
        </li>
      </ul>
    </button>
  </li>

  <li
    class="date-range"
  >
    <button
      [disabled]="!! isInterfaceDisabled"
      ngbDropdown
      ngbDropdownToggle
      type="button"
    >
      <!-- click handler won't work when created via for loop ... ? -->

      <span
        class="text"
        [translate]="selectedDateRange.translate"
      ></span>

      <i class="icon ssi ssi-arrow-down-correct"></i>

      <ul
        class="dropdown-menu"
        ngbDropdownMenu
      >
        <li>
          <button
            (click)="selectDateRangeByIndex(0)"
            type="button"
          >
            <span
              [translate]="availableDateRanges[0].translate"
            ></span>
          </button>
        </li>

        <li>
          <button
            (click)="selectDateRangeByIndex(1)"
            type="button"
          >
            <span
              [translate]="availableDateRanges[1].translate"
            ></span>
          </button>
        </li>

        <li>
          <button
            (click)="selectDateRangeByIndex(2)"
            type="button"
          >
            <span
              [translate]="availableDateRanges[2].translate"
            ></span>
          </button>
        </li>

        <li>
          <button
            (click)="selectDateRangeByIndex(3)"
            type="button"
          >
            <span
              [translate]="availableDateRanges[3].translate"
            ></span>
          </button>
        </li>
      </ul>
    </button>
  </li>

  <!-- <li>
    PERHAPS PUT A DROP DOWN HERE FOR THE DATE RANGE SELECTION.
  </li> -->
</ul>
