import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStore } from 'js-data';
import {
  userModelFactory,
  UserModel
} from '../../modules/user/services/userModel';
import { NotificationPreferences } from '../../modules/user/services/notificationPreferences';

@NgModule()
export class UserModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UserModule,
      providers: [
        NotificationPreferences,
        {
          provide: UserModel,
          useFactory: userModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
