import './inbox-pinned-activities.component.scss';

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Activity } from '@ui-resources-angular';

@Component({
  selector: 'ssi-inbox-pinned-activities',
  templateUrl: './inbox-pinned-activities.component.html'
})
export class InboxPinnedActivitiesComponent implements OnInit, OnChanges {
  @Input() activeActivity: Activity;
  @Input() pinnedActivities: Activity[];

  @Output() onOpenActivity = new EventEmitter<{ activity: Activity }>();
  @Output() onUnpin = new EventEmitter<Activity>();

  additionalActivities = 0;
  maxVisibleActivities = 3;
  showAdditional = false;

  constructor() {}

  ngOnInit() {
    this.calculateAdditionalActivities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pinnedActivities && changes.pinnedActivities.currentValue) {
      this.calculateAdditionalActivities();
    }
  }

  calculateAdditionalActivities() {
    this.additionalActivities =
      this.pinnedActivities.length - this.maxVisibleActivities;
  }

  toggleAdditional() {
    this.showAdditional = !this.showAdditional;
  }

  open(activity: Activity) {
    this.onOpenActivity.emit({ activity });
  }

  unpin(activity: Activity) {
    this.onUnpin.emit(activity);
  }

  shorten(message: string): string {
    if (message.length <= 50) {
      return message;
    }

    return `${message.slice(0, 50)}...`;
  }
}
