import './bulk-upload.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  authUser,
  campaigns,
  workflowAccounts
} from '../../../common-resolves';
import {
  Account,
  AccountModel,
  Campaign,
  User,
  MediaRestrictions,
  socialNetworkSettings,
  OutboxPublisher
} from '@ui-resources-angular';
import {
  CompanyConfig,
  CompanyService
} from '../../../../common/services/company/company.service';
import {
  FilestackService,
  FilestackSources,
  FilestackFile
} from '../../../../common/services/filestack/filestack.service';
import { FileUploaderService } from '../../../../common/directives/file-uploader/file-uploader.service';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { SocialPostCharactersRemainingPipe } from '../../../../common/pipes/social-post-characters-remaining/social-post-characters-remaining.pipe';
import { trackByIndex } from '../../../../common/util';
import { SpreadsheetService } from '../../../../common/services';
import { BulkUploadService } from './bulk-upload.service';

export interface DateFormat {
  regex: RegExp;
  regexAlt: RegExp;
  momentFormat: string;
}

export function allAccountsFn(accountModel: AccountModel) {
  return accountModel.findAccounts();
}

export function companyConfigFn(company: CompanyService) {
  return company.getConfig();
}

@Component({
  selector: 'ssi-bulk-upload',
  templateUrl: './bulk-upload.component.html'
})
export class BulkUploadComponent implements OnInit {
  static resolve = [
    campaigns,
    workflowAccounts,
    authUser,
    {
      token: 'allAccounts',
      resolveFn: allAccountsFn,
      deps: [AccountModel]
    },
    {
      token: 'companyConfig',
      resolveFn: companyConfigFn,
      deps: [CompanyService]
    }
  ];

  @Input() campaigns: Campaign[];
  @Input() workflowAccounts: Account[];
  @Input() authUser: User;
  @Input() allAccounts: Account[];
  @Input() companyConfig: CompanyConfig;
  allowedDateFormats: DateFormat[] = [
    {
      regex: /^\d{4}-\d{2}-\d{2}\s+\d{2}:\d{2}:\d{2}$/,
      momentFormat: 'YYYY-MM-DD HH:mm:ss',
      // alternative regex to allow e.g. '2021-05-21 7:00' (not just strictly e.g. '2021-05-21 07:00:00')
      regexAlt: /^\d{4}-\d{2}-\d{2}\s+\d{1,2}:\d{2}(:\d{2})?$/
    },
    {
      regex: /^\d{4}\/\d{2}\/\d{2}\s+\d{2}:\d{2}:\d{2}$/,
      momentFormat: 'YYYY/MM/DD HH:mm:ss',
      regexAlt: /^\d{4}\/\d{2}\/\d{2}\s+\d{1,2}:\d{2}(:\d{2})?$/
    }
  ];

  postAccounts: Account[];
  loading = true;
  outboxFileSources: string[];
  spreadsheetFileSources = [
    FilestackSources.LocalFileSystem,
    FilestackSources.Dropbox,
    FilestackSources.Googledrive
  ];
  sampleSpreadsheet: string;
  posts: any[];
  fileParsed: boolean;
  file: any;
  totalCsvRows: number;
  totalCsvRowsParsed: number;
  hasAccountsColumn: boolean;
  selectedAccounts: string[] = [];
  loadingPercentage: number;
  selectedCampaignID: string;
  selectedDateFormat: DateFormat;
  trackByIndex = trackByIndex;

  constructor(
    private state: StateService,
    private translate: TranslateService,
    private popup: PopupService,
    private spreadsheetService: SpreadsheetService,
    private filestack: FilestackService,
    private fileUploaderService: FileUploaderService,
    private bulkUploadService: BulkUploadService,
    private accountModel: AccountModel,
    private socialPostCharactersRemainingPipe: SocialPostCharactersRemainingPipe
  ) {}

  ngOnInit() {
    this.postAccounts = this._filterPostAccounts(
      this.workflowAccounts,
      this.authUser
    );

    if (this.companyConfig.only_use_managed_files) {
      this.outboxFileSources = [FilestackSources.CustomSource];
    }

    if (this.state.params.accounts) {
      if (!Array.isArray(this.state.params.accounts)) {
        this.state.params.accounts = [this.state.params.accounts];
      }
      this.postAccounts = this._filterPostAccounts(
        this.state.params.accounts.map((accountId) =>
          this.accountModel.get(accountId)
        ),
        this.authUser
      );
    }

    this.campaigns = this.campaigns.filter(
      (campaign) => !campaign.is_closed && !campaign.is_deleted
    );

    this.spreadsheetService
      .jsonToXlsx([
        {
          header: [
            'Text (Do not delete this column)',
            'Image URL (Do not delete this column)',
            'Date (Do not delete this column)',
            'Alt text (Do not delete this column)',
            'Optional account ids separated by commas e.g. 3113, 3432. The account ids list is in the account ids sheet in this spreadsheet. ' +
              'If you leave this column blank then you can select them after uploading the spreadsheet'
          ],
          rows: [
            [
              'Example post in 7 days',
              'https://pbs.twimg.com/media/BoUP9kXIcAA0Tbo.png:large',
              `${moment(moment(), 'YYYY-MM-DD')
                .add(7, 'days')
                .format('YYYY-MM-DD HH:mm:ss')}`,
              'Platform screenshot'
            ],
            [
              'Example post in three hours',
              'https://pbs.twimg.com/media/FQUFgeVXsAQ0yMQ.png',
              `${moment(moment(), 'YYYY-MM-DD')
                .add(3, 'hours')
                .format('YYYY-MM-DD HH:mm:ss')}`,
              'A bird'
            ]
          ],
          name: 'Sample data'
        },
        {
          header: ['Orlo account id', 'Name', 'Type'],
          rows: this._filterPostAccounts(
            this.allAccounts,
            this.authUser
          ).map((account) => [
            account.id,
            account.displayName,
            account.account_type_name
          ]),
          name: 'Orlo account ids'
        }
      ])
      .then((file) => {
        this.sampleSpreadsheet = file;
      });
    this.loading = false;
  }

  public checkPostTextLengthValid(post: any): void {
    delete post.errors.textLength;
    if (post.accounts.length > 0) {
      const maxChars = post.accounts.reduce((currentMax, account) => {
        if (
          !currentMax ||
          currentMax > account.socialNetwork.maxPostCharacters.public
        ) {
          return account.socialNetwork.maxPostCharacters.public;
        }
        return currentMax;
      }, 0);
      const charsRemaining = this.socialPostCharactersRemainingPipe.transform(
        post.text,
        maxChars,
        post.accounts.some((account) => account.account_type_name === 'Twitter')
      );
      if (charsRemaining < 0) {
        post.errors.textLength = {
          maximumCharacters: maxChars,
          charactersTooMany: Math.abs(charsRemaining)
        };
      }
    }
  }

  public async csvUploaded(files): Promise<void> {
    this.loading = true;

    const file = files[0].filestackFile;
    const isHeader = (row) =>
      row[0] &&
      row[0].trim().toLowerCase().indexOf('text') === 0 &&
      row[1] &&
      row[1].trim().toLowerCase().indexOf('image') === 0 &&
      row[2] &&
      row[2].trim().toLowerCase().indexOf('date') === 0 &&
      row[3] &&
      row[3].trim().toLowerCase().indexOf('alt') === 0;
    const isBlankLine = (row) => row.filter((cell) => !!cell).length === 0;

    this.posts = [];
    this.fileParsed = false;
    this.file = file;
    this.totalCsvRows = 100;
    this.totalCsvRowsParsed = 0;

    const parsedFile: any = await this.spreadsheetService.fileToJson(file);
    let rows = [];

    if (parsedFile.fileType === 'csv') {
      rows = parsedFile.csv;
    } else if (parsedFile.fileType === 'xlsx') {
      delete parsedFile.sheets['Orlo account ids'];
      Object.keys(parsedFile.sheets).forEach((sheetName) => {
        rows = rows.concat(parsedFile.sheets[sheetName]);
      });
    } else {
      throw new Error('Unknown bulk uploader file type!');
    }

    this.totalCsvRows = rows.length;
    this.hasAccountsColumn =
      rows.filter((row) => !!row[4] && !isHeader(row)).length > 0;

    const filePromises = [];
    rows.forEach((row) => {
      if (isHeader(row) || isBlankLine(row)) {
        this.totalCsvRowsParsed++;
        return;
      }

      const post: any = {
        text: '',
        files: [],
        errors: {},
        schedules: [],
        accounts: [],
        mediaRestrictions: undefined
      };

      // handle the TEXT column
      post.text = row[0]
        .replace(/^\s+|\s+$/g, '')
        .replace(/[\t\f\v ]{2,}/g, ' ');
      if (parsedFile.fileType === 'csv') {
        post.text = post.text.replace(/[\u0100-\uFFFF]/g, ''); // replace all unicode characters from CSVs
      }

      // handle the FILE column
      if (row[1] && row[1].length) {
        const filePromise = this.filestack
          .getClient()
          .then((client) => client.storeURL(row[1]))
          .then(async (imageFile: FilestackFile) => {
            console.error('bulk-upload.component.ts: upload (filestack): Size'); // only for tracksjs
            if (!imageFile.mimetype.startsWith('image')) {
              post.errors.image = {
                message: this.translate.instant('INVALID_IMAGE')
              };
              return;
            }

            if (post.mediaRestrictions) {
              // restrictions cannot be determined if no accounts set
              const result = await this.fileUploaderService.validateAndConvertImage(
                imageFile,
                post.mediaRestrictions
              );

              if (result.errors) {
                const error = Object.values(result.errors).find((e) => !!e);
                post.errors.image = {
                  message: error.message
                };
                return;
              } else {
                // update the file with new props that might have changed during the validation procedure (url, size, etc.)
                imageFile = { ...imageFile, ...result.file };
              }
            }

            post.files.push(imageFile);
            this.checkPostTextLengthValid(post);
          })
          .catch((err) => {
            post.errors.image = {
              message: this.translate.instant('INVALID_IMAGE')
            };
            console.error('Error loading/storing image: ', err);
          })
          .finally(() => {
            this.totalCsvRowsParsed++;
          });
        filePromises.push(filePromise);
      } else {
        this.totalCsvRowsParsed++;
      }

      // handle the Alt text column
      post.alt_text = row[3]
        .replace(/^\s+|\s+$/g, '')
        .replace(/[\t\f\v ]{2,}/g, ' ');
      if (parsedFile.fileType === 'csv') {
        post.alt_text = post.alt_text.replace(/[\u0100-\uFFFF]/g, ''); // replace all unicode characters from CSVs
      }

      // handle the DATE column
      if (
        (row[2] || '').trim().match(this.selectedDateFormat.regex) ||
        (row[2] || '').trim().match(this.selectedDateFormat.regexAlt)
      ) {
        const scheduleDate = moment(
          row[2],
          this.selectedDateFormat.momentFormat
        );
        if (!scheduleDate.isValid()) {
          post.errors.date = {
            invalid: true,
            given: row[2]
          };
        } else if (!scheduleDate.isAfter(moment())) {
          post.errors.date = {
            isPast: true,
            given: row[2]
          };
        } else if (post.schedules.length === 0) {
          post.schedules.push(scheduleDate.toDate());
        }
      } else {
        post.errors.date = {
          invalid: true,
          given: row[2]
        };
      }

      // handle the ACCOUNT column
      if (row[4]) {
        post.accounts = row[4]
          .split(',')
          .map((accountId) => {
            return accountId ? this.accountModel.get(accountId.trim()) : null;
          })
          .filter((account) => {
            return !!account;
          });
      }

      this.checkPostTextLengthValid(post);
      this._updatePostMediaRestrictions(post);

      this.posts.push(post);
    });

    await Promise.all(filePromises)
      .then(() => (this.fileParsed = true))
      .catch((err) => {
        let message = err.message || err;
        if (err.type === 'InvalidFile') {
          message = this.translate.instant(
            'ERROR_READING_DATA_IN_YOUR_FILE_FOR_UPLOAD_WE_ONLY_ALLOW_CSV_XLS_AND_XLSX_FILES'
          );
        }
        this.popup.alert({ isError: true, message });
        console.error('Bulk Upload reading data error: ', message);
      });

    this.loading = false;
  }

  private _filterPostAccounts(accounts: Account[], thisUser: User): Account[] {
    return accounts.filter((account) =>
      thisUser.hasAccountPermission(account, 'post')
    );
  }

  private _updatePostMediaRestrictions(post): void {
    if (!post.accounts.length) {
      return;
    }

    if (post.accounts.length === 1) {
      post.mediaRestrictions = new MediaRestrictions().forAccount(
        post.accounts[0]
      );
    } else {
      const socialNetworks = Object.entries(socialNetworkSettings)
        .map(([key, config]) => ({
          config,
          accounts: post.accounts.filter(
            (account) => account.account_type_name === key
          )
        }))
        .filter((network) => network.accounts.length > 0);

      post.mediaRestrictions = new MediaRestrictions().combine(socialNetworks);
    }
  }

  public updateAllPostAccounts(): void {
    this.posts.forEach(async (post) => {
      post.accounts = this.selectedAccounts.map((accountID) =>
        this.postAccounts.find((account) => account.id === accountID)
      );

      this.checkPostTextLengthValid(post);
      this._updatePostMediaRestrictions(post);

      if (post.files.length && post.mediaRestrictions) {
        // re-validate all the images as different accounts have different restrictions
        const result = await this.fileUploaderService.validateAndConvertImage(
          post.files[0],
          post.mediaRestrictions
        );

        if (result.errors) {
          const error = Object.values(result.errors).find((e) => !!e);
          post.errors.image = {
            message: error.message
          };
        } else {
          // update the file with new props that might have changed during the validation procedure (url, size, etc.)
          post.files[0] = { ...post.files[0], ...result.file };
        }
      }
    });
  }

  public postIsValid(post): boolean {
    return (
      post.text &&
      post.accounts.length > 0 &&
      !post.errors.date &&
      !post.errors.textLength
    );
  }

  public imageUploadSuccess(post, files): void {
    post.files[0] = files[0].filestackFile;
    post.errors.image = null;
    this.checkPostTextLengthValid(post);
  }

  public imageUploadStart(post): void {
    post.errors.image = null;
  }

  public imageUploadError(post, error): void {
    console.log('imageUploadError::', error);
    post.files = [];
  }

  public async startUpload() {
    this.loading = true;
    let completedTotal = 0;
    await this.bulkUploadService
      .sendChunks(this.posts, (post) => {
        const publisher = new OutboxPublisher(
          this.postAccounts,
          this.authUser,
          this.companyConfig.use_utm_link_tracking,
          this.companyConfig.use_link_shortening,
          []
        );

        publisher.accounts = post.accounts;
        publisher.text = post.text;
        publisher.campaign = this.campaigns.find(
          (campaign) => +campaign.id === +this.selectedCampaignID
        ) as any;
        post.schedules.forEach((date) => publisher.addSchedule(date));
        post.files.forEach((file) =>
          publisher.addFile({ ...file, alt_text: post.alt_text })
        );

        if (publisher.mediaRestrictions.gif.title.length.min > 0) {
          publisher.files.forEach((file) =>
            file.gif ? (file.gif.title = 'Gif') : null
          );
        }

        post.loading = true;
        post.status = this.translate.instant('QUEUING');

        return publisher
          .publish({ autoError: false })
          .then(() => {
            post.status = this.translate.instant('SUCCESSFULLY_QUEUED');
          })
          .catch((err) => {
            post.status = this.translate.instant(
              'ERROR_QUEUING_POST_BECAUSE__ERRORMESSAGE_',
              { errorMessage: err.response.data.message || err.data.message }
            );
            console.error('Bulk Upload publish error: ', err);
          })
          .finally(() => {
            post.loading = false;
            completedTotal++;
            this.loadingPercentage = (completedTotal / this.posts.length) * 100;
          });
      })
      .then(() => {
        this.loadingPercentage = 100;
      });
    this.loading = false;
  }
}
