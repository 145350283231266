<ngb-alert
  type="danger"
  *ngIf="isOldIE"
  (close)="isOldIE = false">
  Your version of Internet Explorer is no longer supported by Microsoft which means your browser could be vulnerable to security exploits.
  We encourage you to either switch to an alternative browser such as Chrome or Firefox or upgrade to Internet Explorer 11.
  <a href="https://socsi.in/IE-EOL_3LLrG" target="_blank">Read more.</a>
</ngb-alert>

<ngb-alert
  type="danger"
  *ngIf="isUnsupportedFirefox"
  (close)="isUnsupportedFirefox = false">
  Your version of Firefox is no longer supported by Mozilla and will not receive security updates. It is recommended that you upgrade your version of Firefox to the latest supported version.
</ngb-alert>

<ngb-alert
  type="danger"
  *ngIf="isUnsupportedChrome"
  (close)="isUnsupportedChrome = false">
  Your version of Chrome is no longer supported by Google and will not receive security updates. It is recommended that you upgrade your version of Chrome to the latest supported version.
</ngb-alert>

<div
  class="text-center alert alert-info"
  *ngIf="user && user.trial_days_left < 5 && user.plan_type == 0">
  <span
    translate="YOUR__TOTALTRIALDAYS__DAY_TRIAL_IS_DUE_TO_EXPIRE_IN__TRIALDAYSLEFT__DAYS_PLEASE_CALL_US_TO_DISCUSS_YOUR_ACCOUNT__TELEPHONE_"
    [translateParams]="{totalTrialDays: user.total_trial_days, trialDaysLeft: user.trial_days_left, telephone: telephone}">
  </span>
</div>

<div
  class="alert alert-danger"
  *ngIf="overduePayment">
  <span
    [innerHtml]="'THERE_ARE_OUTSTANDING_INVOICES_ON_YOUR_ACCOUNT_THIS_CAN_LEAD_TO_SUSPENSION_OF_YOUR_ACCOUNT_AND_COULD_POTENTIALLY_CAUSE_PERIODS_OF_DATA_LOSS_PLEASE_CONTACT_OUR_ACCOUNTS_DEPARTMENT_ON__TELEPHONE__OR_ON__EMAILADDRESS_' | translate:{telephone: '<a href=\'tel:' + telephone + '\'>' + telephone + '</a>', emailAddress: '<a href=\'mailto:accounts@orlo.tech\'>accounts@orlo.tech</a>'}">
  </span>
</div>

<ngb-alert
  type="danger"
  (close)="hideReauthRequiredAlert = true"
  *ngIf="accountsRequiringReauth && accountsRequiringReauth.length > 0 && !hideReauthRequiredAlert && !browserlessMode">
  <div class="row">
    <div class="col-xs-10">
      <i class="fa fa-exclamation-circle"></i>&nbsp;
      <span
        translate="{{canAdministerAccounts ? 'YOU_HAVE__TOTALACCOUNTS__ACCOUNTS_NEEDING_REAUTHENTICATION_UNTIL_THEY_HAVE_BEEN_REAUTHENTICATED_WE_CANNOT_PUBLISH_ANY_POSTS_FROM_THESE_ACCOUNTS_SEE_THE' : 'YOU_HAVE__TOTALACCOUNTS__ACCOUNTS_NEEDING_REAUTHENTICATION_UNTIL_THEY_HAVE_BEEN_REAUTHENTICATED_WE_CANNOT_PUBLISH_ANY_POSTS_FROM_THESE_ACCOUNTS_PLEASE_CONTACT_AN_ORLO_ADMINISTRATOR_AT_YOUR_ORGANISATION_TO_RESOLVE_THIS_SEE_THE'}}"
        [translateParams]="{totalAccounts: accountsRequiringReauth.length}">
      </span>
      <a href="https://support.orlo.tech/re-authenticating-your-social-media-accounts" 
         target="_blank">FAQ</a>
      <span translate="FOR_MORE_HELP"></span>
    </div>
    <div class="col-xs-2" style="margin-right: -40px">
      <button
        class="btn btn-success btn-block"
        translate="REAUTHENTICATE_NOW"
        uiSref="auth.reAuthAccount"
        *ngIf="canAdministerAccounts">
      </button>
    </div>
  </div>
</ngb-alert>

<ngb-alert
  type="warning"
  (close)="hideReauthAdvisedAlert = true"
  *ngIf="accountsReauthAdvised && accountsReauthAdvised.length > 0 && canAdministerAccounts && !hideReauthAdvisedAlert">
  <div class="row">
    <div class="col-xs-10">
      <i class="fa fa-warning"></i>&nbsp;
      <span
        translate="YOU_HAVE__TOTALACCOUNTS__ACCOUNTS_THAT_WE_RECOMMEND_REAUTHENTICATING_WITH_SOCIALSIGNIN_TO_GRANT_ADDITIONAL_PERMISSIONS_FOR_NEW_FEATURES_AVAILABLE"
        [translateParams]="{totalAccounts: accountsReauthAdvised.length}">
      </span>
    </div>
    <div class="col-xs-2" style="margin-right: -40px">
      <button
        type="button"
        class="btn btn-success btn-block"
        translate="REAUTHENTICATE_NOW"
        (click)="markAccountsAsReauthRequired()">
      </button>
    </div>
  </div>
</ngb-alert>