import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { EnlargeImageModule } from '../../../../common/directives/enlarge-image/enlarge-image.module';
import { FallbackImageModule } from '../../../../common/directives/fallback-image/fallback-image.module';
import { VirtualScrollModule } from '../../../../common/components/virtual-scroll/virtual-scroll.module';
import { InboxQueryResultListComponent } from './inbox-query-result-list.component';
import { VarModule } from '../../../../common/directives/var/var.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { PopoverModule } from '../../../../common/directives/popover/popover.module';
import { EmojiTextModule } from '../../../../common/directives/emoji-text/emoji-text.module';
import { InboxPriorityModule } from '../../../../common/components/inbox-priority/inbox-priority.module';
import { BlurImageModule } from '../../../../common/directives/blur-image/blur-image.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbTypeaheadModule.forRoot(),
    NgbPopoverModule.forRoot(),
    TooltipModule,
    InfiniteScrollModule,
    EnlargeImageModule,
    OffClickModule,
    VarModule,
    PopoverModule,
    EmojiTextModule,
    FallbackImageModule,
    VirtualScrollModule,
    InboxPriorityModule,
    BlurImageModule,
    SocialNetworkIconModule
  ],
  declarations: [InboxQueryResultListComponent],
  exports: [InboxQueryResultListComponent]
})
export class InboxQueryResultListModule {}
