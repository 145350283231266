<div class="row" *ngIf="profile.account.account_type_id !== '6'">
  <div class="col-xs-6">
    <div class="statcard padding-none text-center">
      <h3 class="statcard-number">
        <i class="fa fa-envelope"></i>
        <strong>{{profile.inbox_stats.total | bigNumber}}
        </strong>
      </h3>
      <span
        class="statcard-desc"
        translate="PAST_ENGAGEMENTS"
      ></span>
    </div>
  </div>
  <div class="col-xs-6">
    <div class="statcard padding-none text-center">
      <h3 class="statcard-number">
        <i class="fa fa-dashboard"></i>
        <strong>
          {{
            ((profile.inbox_stats.positive_sentiment -
            (profile.inbox_stats.negative_sentiment -
            profile.inbox_stats.semi_negative_sentiment)) / profile.inbox_stats.total) * 100 |
            ceil
          }}%
        </strong>
      </h3>
      <span
        class="statcard-desc"
        translate="SOCIAL_ENDORSER_SCORE"
      ></span>
    </div>
  </div>
</div>

<br>

<div class="text-center spacer-bottom">
  <button
    class="btn btn-primary"
    (click)="onViewEngagements.emit()"
  >
    <span
      class="statcard-desc"
      translate="VIEW_ALL_ENGAGEMENTS"
    ></span>
  </button>
</div>

<div class="spacer-bottom text-muted text-center" *ngIf="profile.account.account_type_id !== '6'">
  <small>
    <span
      class="statcard-desc"
      translate="ENGAGEMENT_SENTIMENT"
    ></span>
  </small>
</div>

<ssi-crm-profile-sentiment-bar *ngIf="profile.account.account_type_id !== '6'" [thin]="true" [spacingBefore]="false" [profile]="profile"></ssi-crm-profile-sentiment-bar>