import './post-errors.component.scss';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Account, Outbox, OutboxPublisher } from '@ui-resources-angular';
import { AccordionComponent } from '../../accordion/accordion.component';

@Component({
  selector: 'ssi-post-errors',
  templateUrl: './post-errors.component.html'
})
export class PostErrorsComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() shareFirstCommentErrors: string[];

  @Output() onScheduledPostTimesDeleted = new EventEmitter();

  @ViewChild('postErrorsAccordion')
  postErrorsAccordion: AccordionComponent;

  constructor() {}

  ngOnInit() {}
}
