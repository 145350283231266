import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TreetableModule } from '../../../../../common/components/treetable';
import { BigNumberModule } from '../../../../../common/pipes/big-number/big-number.module';
import { BigNumberModule as BigNumberComponentModule } from '../../../../../common/components/big-number/big-number.module';
import { AbsModule } from '../../../../../common/pipes/abs/abs.module';
import { SecondsToHumanTimeModule } from '../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.module';
import { Checkbox2Module } from '../../../../../common/components/checkbox-2/checkbox-2.module';
import { ToggleSwitch2Module } from '../../../../../common/components/toggle-switch-2/toggle-switch-2.module';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../common/components/social-network-icon/social-network-icon.module';
import { HighchartModule } from '../../../../../common/components/highchart/highchart.module';
import { ProgressBarModule } from '../../../../../common/components/progress-bar/progress-bar.module';
import { LinkifyModule } from '../../../../../common/pipes/linkify/linkify.module';
import { EnlargeImageModule } from '../../../../../common/directives/enlarge-image/enlarge-image.module';
import { ReverseModule } from '../../../../../common/pipes/reverse/reverse.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

import { VarModule } from '../../../../../common/directives/var/var.module';
import { TemplateSelectorModule } from '../../../../../common/directives/template-selector/template-selector.module';
import { FallbackImageModule } from '../../../../../common/directives/fallback-image/fallback-image.module';
import { LoaderModule } from '../../../../../common/directives/loader';

import { ReportComponent } from './report.component';
import { ReportHeaderModule } from '../../common/report-header/report-header.module';
import { ReportSidebarModule } from '../../common/report-sidebar/report-sidebar.module';
import { StatChangeModule } from '../../common/stat-change/stat-change.module';
import { SocialNetworkSelectModule } from '../../common/social-network-select/social-network-select.module';
import { TotalStatCardModule } from '../../common/total-stat-card/total-stat-card.module';
import { FactOverviewModule } from '../../common/fact-overview/fact-overview.module';
import { SentimentCardModule } from '../../common/sentiment-card/sentiment-card.module';
import { MedalScoreModule } from '../../common/medal-score/medal-score.module';
import { LinkClicksSectionModule } from '../../common/link-clicks-section/link-clicks-section.module';
import { TopPostListModule } from '../../common/top-post-list/top-post-list.module';

import { AccountSummaryComponent } from './account-summary/account-summary.component';
import { OrganicReachComponent } from './organic-reach/organic-reach.component';
import { FollowerCountComponent } from './follower-count/follower-count.component';
import { BrandSentimentComponent } from './brand-sentiment/brand-sentiment.component';
import { NetworkInboundCommentsComponent } from './brand-sentiment/network-inbound-comments/network-inbound-comments.component';
import { PostEngagementComponent } from './post-engagement/post-engagement.component';
import { TopPostsComponent } from './top-posts/top-posts.component';
import { TopTimesToPostComponent } from './top-times-to-post/top-times-to-post.component';
import { InstagramStoryStatsModule } from '../../common/instagram-story-stats/instagram-story-stats.module';
import { AutomateModalModule } from '../../../../../common/components/automate-modal/automate-modal.module';
import { VideoMetricsModule } from '../../common/video-metrics/video-metrics.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.marketing.report',
          // url: '/report?account_ids&start_date&end_date&workflow&compare_to&previous_start_date&previous_end_date',
          url:
            '/report?accountIds&startDate&endDate&previousStartDate&previousEndDate&dateRange&compareTo&tagIdsToInclude&tagIdsToExclude&includeDeletedInTopPosts&disablePopup&frequency',
          component: ReportComponent,
          resolve: ReportComponent.resolve
        }
      ]
    }),
    FormsModule,
    AutomateModalModule,
    TranslateModule,
    TreetableModule,
    BigNumberModule,
    BigNumberComponentModule,
    AbsModule,
    SecondsToHumanTimeModule,
    Checkbox2Module,
    ToggleSwitch2Module,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    HighchartModule,
    ProgressBarModule,
    LinkifyModule,
    EnlargeImageModule,
    ReverseModule,
    TooltipModule,
    VarModule,
    TemplateSelectorModule,
    FallbackImageModule,
    LoaderModule,
    ReportHeaderModule,
    ReportSidebarModule,
    StatChangeModule,
    SocialNetworkSelectModule,
    TotalStatCardModule,
    FactOverviewModule,
    SentimentCardModule,
    MedalScoreModule,
    LinkClicksSectionModule,
    TopPostListModule,
    InstagramStoryStatsModule,
    VideoMetricsModule
  ],
  declarations: [
    ReportComponent,
    AccountSummaryComponent,
    OrganicReachComponent,
    BrandSentimentComponent,
    NetworkInboundCommentsComponent,
    FollowerCountComponent,
    PostEngagementComponent,
    TopPostsComponent,
    TopTimesToPostComponent
  ]
})
export class ReportModule {}
