import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import './chat-bot.component.scss';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { SingleInputModalComponent } from './single-input-modal/single-input-modal.component';
import { TopicModalComponent } from './topic-modal/topic-modal.component';
import { ExampleModalComponent } from './example-modal/example-modal.component';
import { ChatBotModalComponent } from './chat-bot-modal/chat-bot-modal.component';
import {
  ChatBotService,
  Metabase,
  Topic,
  Example,
  ChatBot
} from '../../../../../common/services/chat-bot/chat-bot.service';
import { SnackbarService } from '../../../../../common/services/snackbar/snackbar.service';
import { debounceTime } from 'rxjs/operators/debounceTime';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs';

export async function metabaseFn(chatBot: ChatBotService): Promise<Metabase[]> {
  return await chatBot.getMetabaseList();
}

export async function chatBotsFn(chatBot: ChatBotService): Promise<ChatBot[]> {
  return await chatBot.getChatBotList();
}

@Component({
  selector: 'ssi-chat-bot',
  templateUrl: './chat-bot.component.html',
  styles: []
})
export class ChatBotComponent implements OnInit, OnDestroy {
  static resolve = [
    {
      token: 'metabases',
      resolveFn: metabaseFn,
      deps: [ChatBotService]
    },
    {
      token: 'chatBots',
      resolveFn: chatBotsFn,
      deps: [ChatBotService]
    }
  ];

  @Input() chatBots: ChatBot[];
  @Input() metabases: Metabase[];
  chatBotsCache: ChatBot[] = [];
  metabasesCache: Metabase[] = [];
  topics: Topic[] = [];
  topicsCache: Topic[] = [];
  examples: Example[] = [];

  tableLoading = false;
  searchTextTable1: string;
  searchTextTable2: string;
  selectedMetabase: Metabase | undefined;

  _searchSubject = new Subject<string>();
  _searchSubjectSubscription: Subscription;

  constructor(
    public chatBot: ChatBotService,
    private modalService: NgbModal,
    private popup: PopupService,
    private snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.metabasesCache.push(...this.metabases);
    this.chatBots.forEach(
      (chatBot) =>
        (chatBot['metabase'] = this.metabases.find(
          (metabase) => metabase.id === chatBot.metabase_id
        ))
    );
    this.chatBotsCache.push(...this.chatBots);
  }

  ngOnDestroy() {
    if (!!this._searchSubjectSubscription) {
      this._searchSubjectSubscription.unsubscribe();
    }
  }

  updateTable(searchInput, tableName, tableCache, searchProperties) {
    this._searchSubject.next();
    this._searchSubjectSubscription = this._searchSubject
      .pipe(debounceTime(300))
      .subscribe(() => {
        const searchTerm = this[searchInput].toUpperCase();
        this[tableName] = tableCache.filter((tableItem) => {
          return searchProperties.some((property) => {
            const propertyPath = property.split('.');
            const traversedTableItem = propertyPath.reduce(
              (propertyTableItem, nestedProperty) =>
                propertyTableItem[nestedProperty],
              tableItem
            );
            return (
              traversedTableItem &&
              traversedTableItem.toUpperCase().includes(searchTerm)
            );
          });
        });
      });
  }

  private async _refreshMetabaseList() {
    this.tableLoading = true;
    this.metabasesCache = await this.chatBot.getMetabaseList();
    this.metabases = Array.from(this.metabasesCache);
    this.chatBotsCache = await this.chatBot.getChatBotList();
    this.chatBots = Array.from(this.chatBotsCache);
    this.chatBots.forEach(
      (chatBot) =>
        (chatBot['metabase'] = this.metabases.find(
          (metabase) => metabase.id === chatBot.metabase_id
        ))
    );
    this.tableLoading = false;
  }

  async createMetabase() {
    const modal = await this.modalService.open(SingleInputModalComponent, {
      backdrop: true
    });
    Object.assign(modal.componentInstance, {
      title: 'Create Metabase',
      placeholder: 'Metabase Name',
      actionButton: 'Create new metabase'
    });
    modal.result.then(async (name) => {
      if (name) {
        await this.chatBot.createMetabaseList(name);
        this._refreshMetabaseList();
      }
    });
  }

  async createBot(metabases: Metabase[]) {
    const modal = await this.modalService.open(ChatBotModalComponent, {});
    Object.assign(modal.componentInstance, { metabases });
    modal.result.then(async (chatBot) => {
      if (chatBot) {
        const id = await this.chatBot.createChatBot(chatBot);
        const chatBotWithMetabase: ChatBot = Object.assign({}, chatBot);
        chatBotWithMetabase['metabase'] = metabases.find(
          (iChatBot) => iChatBot.id === chatBot.metabase_id
        );
        chatBotWithMetabase['id'] = id;
        this.chatBotsCache.push(chatBotWithMetabase);
        this.chatBots = Array.from(this.chatBotsCache);
      }
    });
  }

  async scrapeMetabase(metabase: Metabase) {
    const modal = await this.modalService.open(SingleInputModalComponent, {
      backdrop: true
    });
    Object.assign(modal.componentInstance, {
      title: 'Scrape',
      placeholder: 'https://support.orlo.tech',
      actionButton: 'Scrape',
      meta:
        'Add the URL of your chosen webpage here and Orlo will trawl the page to automatically generate a summarised response. The response will be named using the web page title and the link will be the URL you add. Be sure to give it all a once over - the AI sometimes needs a helping hand!'
    });
    modal.result.then(async (url) => {
      if (url) {
        await this.chatBot.queueScrapeTopics(metabase.id, url);
        this.snackbar.open('Scraping Initialized', { duration: 5 });
      }
    });
  }

  importMetabaseCSV(metabase: Metabase) {
    this.popup
      .confirm({
        title: 'Import CSV',
        template:
          'Before uploading your CSV, make sure you’ve got your ducks in a row. You’ll need to have columns for ‘Name’, ‘Response’ and ‘Link’ to ensure the file can upload and the fields can be correctly matched.'
      })
      .then(async (confirmed) => {
        if (confirmed) {
          this.chatBot.uploadCSV(metabase.id);
        }
      });
  }

  async displayTopics(metabase: Metabase) {
    this.tableLoading = true;
    this.selectedMetabase = metabase;
    this.topicsCache = await this.chatBot.getTopicList(metabase.id);
    this.topics = Array.from(this.topicsCache);
    this.tableLoading = false;
  }

  toggleHandover(topic: Topic) {
    topic.is_conversational = false;
    topic.is_handover_request = !topic.is_handover_request;

    this.chatBot.editTopic(this.selectedMetabase.id, topic.id, {
      is_handover_request: topic.is_handover_request,
      is_conversational: topic.is_conversational
    });
  }

  toggleConversational(topic: Topic) {
    topic.is_handover_request = false;
    topic.is_conversational = !topic.is_conversational;

    this.chatBot.editTopic(this.selectedMetabase.id, topic.id, {
      is_conversational: topic.is_conversational,
      is_handover_request: topic.is_handover_request
    });
  }

  fetchTopicType(topic: Topic) {
    if (topic.is_conversational) {
      return 'Conversational';
    } else if (topic.is_handover_request) {
      return 'Force Handover';
    } else if (topic.is_no_handover) {
      return 'Prevent Handover';
    } else {
      return 'Default';
    }
  }

  async editTopic(topic: Partial<Topic>, selectedMetabase: Metabase) {
    const isEdit = topic.hasOwnProperty('id');
    const modal = await this.modalService.open(TopicModalComponent, {});
    Object.assign(modal.componentInstance, {
      title: isEdit ? 'Edit Topic' : 'Create Topic',
      actionButton: isEdit ? 'Save Edit' : 'Create Topic',
      topic
    });
    modal.result.then(async (result: Topic) => {
      if (!result) {
        return;
      }
      if (!isEdit) {
        const id = await this.chatBot.createTopic(selectedMetabase.id, result);
        result = Object.assign({ id }, result);
        this.topicsCache.push(result);
        this.topics = Array.from(this.topicsCache);
      }
      if (isEdit) {
        const changedKeys = Object.keys(result).filter(
          (key) => result[key] !== topic[key]
        );
        const changes = changedKeys.reduce((acc, key) => {
          acc[key] = result[key];
          return acc;
        }, {});
        await this.chatBot.editTopic(selectedMetabase.id, topic.id, changes);
        changedKeys.forEach(
          (changeKey) => (topic[changeKey] = changes[changeKey])
        );
      }
    });
  }

  deleteTopic(metabase: Metabase, topic: Topic) {
    this.popup
      .confirm({
        title: 'Delete Topic',
        template: 'Delete this Topic?'
      })
      .then(async (shouldDelete) => {
        if (shouldDelete) {
          await this.chatBot.deleteTopic(metabase.id, topic.id);
          this.topicsCache = this.topicsCache.filter(
            (iTopic) => iTopic.id !== topic.id
          );
          this.topics = Array.from(this.topicsCache);
        }
      });
  }

  async editMetabase(metabase: Metabase) {
    const modal = await this.modalService.open(SingleInputModalComponent, {
      backdrop: true
    });
    Object.assign(modal.componentInstance, {
      title: 'Edit Metabase',
      placeholder: 'Metabase Name',
      actionButton: 'Save Metabase Name',
      singleInput: metabase.name
    });
    modal.result.then(async (name) => {
      if (name) {
        await this.chatBot.editMetabase(metabase.id, name);
        this._refreshMetabaseList();
      }
    });
  }

  deleteMetabase(metabase: Metabase) {
    this.popup
      .confirm({
        title: 'Delete Metabase',
        template: 'Delete this Metabase?'
      })
      .then(async (shouldDelete) => {
        if (shouldDelete) {
          await this.chatBot.deleteMetabase(metabase.id);
          this.metabasesCache = this.metabasesCache.filter(
            (iMetabase) => iMetabase.id !== metabase.id
          );
          this.metabases = Array.from(this.metabasesCache);
        }
      });
  }

  async editChatbot(chatBot: ChatBot = null, metabases: Metabase[]) {
    const modal = await this.modalService.open(ChatBotModalComponent, {});
    Object.assign(modal.componentInstance, { chatBot, metabases });
    modal.result.then(async (chatBotUpdate) => {
      if (chatBot) {
        await this.chatBot.editChatBot(chatBotUpdate, chatBot.id);
        this.chatBotsCache = this.chatBotsCache.filter(
          ({ id }) => id !== chatBot.id
        );
        const chatBotWithMetabase: ChatBot = Object.assign({}, chatBotUpdate);
        chatBotWithMetabase['metabase'] = metabases.find(
          (iChatBot) => iChatBot.id === chatBotUpdate.metabase_id
        );
        chatBotWithMetabase['id'] = chatBot.id;
        this.chatBotsCache.push(chatBotWithMetabase);
        this.chatBots = Array.from(this.chatBotsCache);
      }
    });
  }

  deleteChatbot(chatbot: ChatBot) {
    this.popup
      .confirm({
        title: 'Delete Chatbot',
        template: 'Delete this Chatbot?'
      })
      .then(async (shouldDelete) => {
        if (shouldDelete) {
          await this.chatBot.deleteChatbot(chatbot.id);
          this.chatBotsCache = this.chatBotsCache.filter(
            (iChatbot) => iChatbot.id !== chatbot.id
          );
          this.chatBots = Array.from(this.chatBotsCache);
        }
      });
  }

  async openExamplesModal(metabase: Metabase, topic: Topic) {
    const examples = await this.chatBot.getTopicExamples(metabase.id, topic.id);
    const modal = await this.modalService.open(ExampleModalComponent, {});
    modal.componentInstance.examples = examples;
    modal.componentInstance.topic = topic;
    modal.componentInstance.metabase = metabase;
  }
}
