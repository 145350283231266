import './delete-modal.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-delete-modal',
  templateUrl: './delete-modal.component.html'
})
export class DeleteModalComponent {
  @Input() info: string;
  deleteString: string;
  isInvalid = true;

  constructor(public activeModal: NgbActiveModal) {}

  validate(event) {
    this.isInvalid = event !== 'DELETE' ? true : false;
  }
}
