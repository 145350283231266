import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStore } from 'js-data';
import {
  noteModelFactory,
  NoteModel
} from '../../modules/note/services/noteModel';

@NgModule()
export class NoteModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NoteModule,
      providers: [
        {
          provide: NoteModel,
          useFactory: noteModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
