<div class="widget-table-container">
  <ssi-treetable *ngIf="response"
                 [data]="response.data"
                 [bodyStyle]="{'max-height': tableBodyMaxHeight}"
                 [rowStyle]="{'height': '60px'}"
                 [virtualScroll]="false"
                 [resizeMode]="'fit'"
                 [reorderable]="false"
                 [globalFilterInputRef]=""
                 id="treetable-marketing-analytics-account-summary"
                 [ssiObserveWidthAndHeight]="true"
                 (onHeightChange)="onTableHeightChange($event)">

    <ssi-column *ngFor="let column of response.metadata.columns; let index = index;"
                [field]="index + ''"
                [header]="column.function ? column.field + ' (' + column.function + ')' : column.field"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

    <ssi-pagination [itemsPerPage]="5"
                    [pageLinksSize]="5"></ssi-pagination>
  </ssi-treetable>

  <ng-template #statBodyCellTemplate
               ssiTemplateSelector="bodyCell"
               let-rowIndex="rowIndex"
               let-rowData="rowData"
               let-column="column"
               let-columnIndex="columnIndex"
               let-columns="columns"
               let-columnsMap="columnsMap"
               let-fieldValue="fieldValue">
    <div class="body-cell stat"
         [class.row-even]="rowIndex % 2 === 0">
      <span *ngIf="!fieldValue"
            [ssiTooltip]=""
            tooltipWindowClass="tooltip-chunky-big">N/A</span>
      <ng-container *ngIf="fieldValue">
        <span *ngIf="!isNumber(response.data[rowIndex][columnIndex])"
              title="{{fieldValue}}">{{fieldValue}}</span>
        <ssi-big-number *ngIf="isNumber(response.data[rowIndex][columnIndex])"
                        [number]="fieldValue"
                        title="{{fieldValue}}"></ssi-big-number>
      </ng-container>
    </div>
  </ng-template>
</div>