import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ListSelect2Component } from './list-select2.component';

import { CheckboxModule } from '../../components/checkbox/checkbox.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { FocusOnModule } from '../../directives/focus-on/focus-on.module';
import { TemplateSelectorModule } from '../../directives/template-selector/template-selector.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { Checkbox2Module } from '../../components/checkbox-2/checkbox-2.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CheckboxModule,
    OffClickModule,
    FocusOnModule,
    TemplateSelectorModule,
    FallbackImageModule,
    Checkbox2Module,
    TooltipModule
  ],
  exports: [ListSelect2Component, TemplateSelectorModule],
  declarations: [ListSelect2Component]
})
export class ListSelect2Module {}
