<div class="insights-reports-edit-container">
  <ssi-insights-navbar
    [reportID]="report?.id"
    (widgetAdd)="openAddWidgetModal()"
    (widgetCustomAdd)="openAddCustomWidgetModal()"
    (reportNameChange)="reportName = $event"
    (reportDelete)="openDeleteReportModal()"
  ></ssi-insights-navbar>

  <div class="cool-class-name">
    <div class="actions-bar">
      <div class="left">
        <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                               [options]="accessOptionsIterable"
                               [(ngModel)]="selectedAccessOption"
                               (ngModelChange)="onAccessChange($event)">
          <ng-template ssiTemplateSelector="headButtonLabel">
            <span class="head-button-label">Access: <span>{{selectedAccessOption.label}}</span></span>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
      <div class="right">
        <button type="button"
                class="btn cancel-btn"
                (click)="cancel()">Cancel</button>
        <button type="button"
                class="btn save-btn"
                (click)="save()">Save</button>
      </div>
    </div>

    <ssi-widgets-grid #widgetsGrid
                      [apiWidgets]="report?.widgets"></ssi-widgets-grid>
  </div>
</div>