import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlToTextPipe } from './html-to-text.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [HtmlToTextPipe],
  exports: [HtmlToTextPipe]
})
export class HtmlToTextModule {}
