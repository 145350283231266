<div class="tags-table-section">
  <ssi-treetable
    [data]="dataToDisplay"
    [bodyStyle]="{'max-height': '408px'}"
    [rowStyle]="{'height': '68px'}"
    [virtualScroll]="false"
    [resizeMode]="'fit'"
    [reorderable]="true"
    [saveSettings]="true"
    [settingsStorageKey]="'treetable-engagement-analytics-tags-table'"
    [globalFilterInputRef]=""
    id="treetable-engagement-analytics-tags-table"
  >
    <ssi-column
      header="Tag"
      field="tag.name"
      [templateRefs]="{bodyCell: tagBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.count_actioned"
      header="Messages Actioned"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.sentiment.negative"
      header="Negative"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.sentiment.semi_negative"
      header="Semi Negative"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.sentiment.neutral"
      header="Neutral"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.sentiment.semi_positive"
      header="Semi Positive"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.sentiment.positive"
      header="Positive"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.sentiment.missing"
      header="No Sentiment"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.count"
      header="Number of messages"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
    ></ssi-column>
    <ssi-column
      field="current.avg_response_time"
      header="Average response time"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
      [tooltip]="'The response time of all messages averaged over the range of the report'"
    ></ssi-column>
    <ssi-column
      field="current.sum_response_time"
      header="Total response time"
      [templateRefs]="{bodyCell: statBodyCellTemplate}"
      [tooltip]="'The total response time of all messages for the range of the report'"
    ></ssi-column>
  </ssi-treetable>

  <ng-template #tagBodyCellTemplate
    ssiTemplateSelector="bodyCell"
    let-rowIndex="rowIndex"
    let-rowData="rowData"
    let-fieldValue="fieldValue"
  >
    <div class="body-cell body-cell-primary">
    <p
      class="body-cell-label"
      [ssiTooltip]="fieldValue"
      tooltipPlacement="bottom"
      tooltipWindowClass="tooltip-chunky"
      [tooltipAppendToBody]="true"
    >{{fieldValue}}</p>
    </div>
  </ng-template>

  <ng-template #statBodyCellTemplate
    ssiTemplateSelector="bodyCell"
    let-rowIndex="rowIndex"
    let-rowData="rowData"
    let-fieldValue="fieldValue"
  >
    <div class="body-cell body-cell-stat-wrap body-cell-primary">
    <p class="body-cell-stat">{{fieldValue}}</p>
    </div>
  </ng-template>
</div>