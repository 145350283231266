import {
  NgModule,
  Pipe,
  PipeTransform,
  ModuleWithProviders
} from '@angular/core';
import { DataStore } from 'js-data';
import greaterThanFilter from '../../modules/publish/filters/greaterThan';
import socialPostCharactersRemainingFilter from '../../modules/publish/filters/socialPostCharactersRemaining';
import {
  outboxModelFactory,
  OutboxModel
} from '../../modules/publish/services/outboxModel';
import { BlockingWord } from '../../modules/publish/services/blockingWord';

@Pipe({
  name: 'greaterThan'
})
export class GreaterThan implements PipeTransform {
  transform(input: number[], amount: number): number[] {
    return greaterThanFilter(input, amount);
  }
}

@Pipe({
  name: 'socialPostCharactersRemaining'
})
export class SocialPostCharactersRemaining implements PipeTransform {
  transform(input: string, maxLength: number, isTwitter: boolean): number {
    return socialPostCharactersRemainingFilter(input, maxLength, isTwitter);
  }
}

@NgModule({
  declarations: [GreaterThan, SocialPostCharactersRemaining],
  exports: [GreaterThan, SocialPostCharactersRemaining]
})
export class PublishModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PublishModule,
      providers: [
        BlockingWord,
        {
          provide: OutboxModel,
          useFactory: outboxModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
