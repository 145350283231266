<ul
  class="live-chat-notes-header"
>
  <li
    class="button"
  >
    <button
      (click)="onClose()"
    >
      <i
        class="fa fa-chevron-left button-icon"
      ></i>

      <span
        class="button-text"
        translate="BACK_TO_MESSAGE"
      ></span>
    </button>
  </li>

  <li class="title">
    <span
      class="title-text"
      translate="MESSAGE_NOTES"
    ></span>

    <i
      class="ssi ssi-feather-note title-icon"
    ></i>
  </li>
</ul>

<div
  class="live-chat-notes-content"
>
  <ssi-live-chat-message
    [message]="message"
    class="visitor"
  ></ssi-live-chat-message>

  <ssi-live-chat-note-form
    (isNoteFormFocusedChange)="onIsNoteFormFocusedChange($event)"
    [isNoteFormFocused]="isNoteFormFocused"
    [message]="message"
    [user]="user"
  ></ssi-live-chat-note-form>
  
  <ul
    class="message-notes"
  >
    <li
      *ngFor="let note of message.notes"
    >
      <ssi-live-chat-message-note
        [canAddMore]="false"
        [canViewMore]="false"
        [message]="message"
        [note]="note"
        [user]="user"
      ></ssi-live-chat-message-note>
    </li>
  </ul>
</div>
