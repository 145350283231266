import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MessageActionsMenuComponent } from './message-actions-menu.component';
import { SentimentListModule } from '../../sentiment-list/sentiment-list.module';
import { AddNoteModule } from '../../add-note/add-note.module';
import { MultiSelectModule } from '../../multi-select/multi-select.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { InboxPriorityModule } from '../../inbox-priority/inbox-priority.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AddNoteModule,
    SentimentListModule,
    TooltipModule,
    InboxPriorityModule,
    MultiSelectModule,
    TooltipModule
  ],
  declarations: [MessageActionsMenuComponent],
  exports: [MessageActionsMenuComponent]
})
export class MessageActionsMenuModule {}
