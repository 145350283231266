import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ClContextMenuComponent } from './cl-context-menu.component';

import { ContextMenuModule } from '../../../../common/components/context-menu/context-menu.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { ConfirmActionModule } from '../../../../common/directives/tooltip/confirm-action/confirm-action.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ContextMenuModule,
    SocialNetworkIconModule,
    ConfirmActionModule
  ],
  exports: [ClContextMenuComponent],
  declarations: [ClContextMenuComponent]
})
export class ClContextMenuModule {}
