import './blocking-words.component.scss';
import { Component, OnInit, Input } from '@angular/core';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { BlockingWord, API } from '@ui-resources-angular';

export async function blockingWordSettingsFn(blockingWord: BlockingWord) {
  return blockingWord.getBlockingWords();
}

@Component({
  selector: 'ssi-blocking-words',
  templateUrl: './blocking-words.component.html',
  styleUrls: []
})
export class BlockingWordsComponent implements OnInit {
  static resolve = [
    {
      token: 'blockingWordSettings',
      resolveFn: blockingWordSettingsFn,
      deps: [BlockingWord]
    }
  ];

  @Input() blockingWordSettings;
  loadingTracker = this.asyncTrackerFactory.create();
  newWord;
  wordSearchText;
  displayedBlockingWords;

  constructor(
    private api: API,
    private blockingWord: BlockingWord,
    private state: StateService,
    private popup: PopupService,
    private translate: TranslateService,
    private asyncTrackerFactory: AsyncTrackerFactory
  ) {}

  ngOnInit() {
    this.displayedBlockingWords = this.blockingWordSettings.words;
  }

  saveFacebookSettings() {
    const promise = this.api
      .post('settings/blockingWord', {
        delete_offensive_posts: this.blockingWordSettings.delete_offensive_posts
      })
      .then(() => {
        this.blockingWord.clearCache();
        return this.state.go('auth.settings.index');
      })
      .then(() => {
        this.popup.alert({
          title: this.translate.instant('SETTINGS_SAVED'),
          message: this.translate.instant(
            'YOUR_SETTINGS_WERE_SAVED_SUCCESSFULLY'
          )
        });
      });
    this.loadingTracker.add(promise);
  }

  loadDefaultBlockingWords() {
    const promise = this.blockingWord.resetBlockingWords().then((words) => {
      this.blockingWordSettings.words = words;
    });
    this.loadingTracker.add(promise);
  }

  addBlockingWord(word) {
    const promise = this.blockingWord
      .createBlockingWord(word)
      .then((newWord) => {
        this.blockingWordSettings.words.push(newWord);
        this.displayedBlockingWords = this.blockingWordSettings.words;
        this.newWord = '';
      });
    this.loadingTracker.add(promise);
  }

  removeBlockingWord(word) {
    const wordId = word.id;
    const promise = this.blockingWord.deleteBlockingWord(wordId).then(() => {
      this.blockingWordSettings.words = this.blockingWordSettings.words.filter(
        (iWord) => iWord.id !== wordId
      );
      this.displayedBlockingWords = this.blockingWordSettings.words;
    });
    this.loadingTracker.add(promise);
  }

  filterBlockingWords() {
    this.displayedBlockingWords = this.blockingWordSettings.words.filter(
      (blockingWord) => {
        return blockingWord.word
          .toUpperCase()
          .includes(this.wordSearchText.toUpperCase());
      }
    );
  }
}
