import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes.component';
import { Nl2brModule } from '../../pipes/nl2br/nl2br.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, Nl2brModule, TranslateModule],
  declarations: [NotesComponent],
  exports: [NotesComponent]
})
export class NotesModule {}
