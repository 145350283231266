import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { get_relative_age_of_date } from '../../../../../../library/helpers/datetimes';
import { Conversation } from '../../../../../../library/interfaces/live-chat/conversation';
import { Agent } from '../../../../../../library/interfaces/live-chat/agent';
import { Conversation as ConversationModel } from '../../../../../../library/models/live-chat/conversation';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

@Component({
  selector: 'ssi-live-chat-conversation-tree',
  templateUrl: './live-chat-conversation-tree.component.html',
  styles: []
})
export class LiveChatConversationTreeComponent implements OnChanges {
  @Input() activeConversationId: string;
  @Input() agent: Agent;
  @Input() conversations: Conversation[];

  @Output() activeConversationChange = new EventEmitter<string>();
  @Output() conversationAssignmentUpdated = new EventEmitter<Conversation>();

  constructor(private liveChatService: LiveChatService) {}

  public get isAgentOffline() {
    return this.agent ? this.agent.isOffline : false;
  }

  public get isAgentOnline() {
    return this.agent ? this.agent.isOnline : false;
  }

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public get isPushModeEnabled(): boolean {
    return this.liveChatService.isPushModeEnabled;
  }

  public get isReady(): boolean {
    return !!this.liveChatService.isConversationListReady;
  }

  public canChangeAssignmentForConversation(
    conversation: ConversationModel
  ): boolean {
    return (
      !this.liveChatService.isPushModeEnabled &&
      this.liveChatService.agentModel.hasWritePermissionForApplicationID(
        conversation.applicationId
      )
    );
  }

  public getRelativeAgeForConversation(conversation) {
    return get_relative_age_of_date(conversation.preview.relativeAge);
  }

  public ngOnChanges(values) {
    if (values.hasOwnProperty('activeConversation') && values.currentValue) {
      this.selectConversation(values.currentValue);
    }
  }

  public selectConversation(id) {
    if (this.isInterfaceDisabled) {
      return;
    }

    if (this.activeConversationId === id) {
      return;
    }

    this.activeConversationChange.emit(id);
  }

  public toggleAssignmentToConversationForAgent(conversation: Conversation) {
    this.conversationAssignmentUpdated.emit(conversation);
  }
}
