import './query-builder-step.component.scss';
import { Component, OnInit } from '@angular/core';
import { StreamBuilderService, StreamQuery } from '../stream-builder.service';
import {
  conditionOptions,
  conditionOptionsIterable,
  Query
} from '../../common/constants/query-builder-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountModel } from '@ui-resources-angular';

const DEFAULT_OPERATOR = '$or';

@Component({
  selector: 'ssi-query-builder-step',
  templateUrl: './query-builder-step.component.html',
  styles: []
})
export class QueryBuilderStepComponent implements OnInit {
  streamQuery: StreamQuery;
  rootQuery = {
    operator: DEFAULT_OPERATOR,
    accountId: undefined,
    conditions: [],
    subQueries: []
  };
  conditionOptions = conditionOptions;
  conditionOptionsIterable = conditionOptionsIterable;
  accounts = [];
  requireAccountId = false;
  allowRootConditions = true;
  overrideDefaultOption = false;
  showGuide = true;
  guideEnabled =
    this.requireAccountId === false &&
    this.allowRootConditions === true &&
    this.overrideDefaultOption === false;

  constructor(
    private streamBuilderService: StreamBuilderService,
    private accountModel: AccountModel,
    public modal: NgbModal
  ) {}

  async ngOnInit() {
    this.streamQuery = this.streamBuilderService.fetchStreamQuery();
    console.log(this.streamQuery);

    if (this.requireAccountId) {
      this.accounts = await this.accountModel.findAllAccounts();
    }

    if (
      this.streamQuery &&
      this.streamQuery.query &&
      Object.values(this.streamQuery.query).length
    ) {
      this._normalizeQuery(this.streamQuery.query);
    }
  }

  private _normalizeQuery(query) {
    Object.entries(query).forEach(
      ([rootOperand, subQueriesOrConditions]: [string, any]) => {
        this.rootQuery.operator = rootOperand;
        subQueriesOrConditions.forEach((condition) => {
          if (condition.$and || condition.$or) {
            Object.entries(condition).forEach(([operator, conditions]) => {
              this.rootQuery.subQueries.push({
                operator,
                conditions
              });
            });
          } else {
            this.rootQuery.conditions.push(condition);
          }
        });
      }
    );
  }

  addRootCondition() {
    this.rootQuery.conditions.push({
      type: this.conditionOptionsIterable[0].type,
      comparison: this.conditionOptionsIterable[0].comparisonOperators[0].type
    });
  }

  addSubCondition(subQueryIndex) {
    this.rootQuery.subQueries[subQueryIndex].conditions.push({
      type: this.conditionOptionsIterable[0].type,
      comparison: this.conditionOptionsIterable[0].comparisonOperators[0].type
    });
  }

  removeCondition(conditions, conditionIndex) {
    conditions.splice(conditionIndex, 1);
  }

  addGroup() {
    const newGroup = {
      operator: DEFAULT_OPERATOR,
      accountId: undefined,
      conditions: [
        {
          type: this.conditionOptionsIterable[0].type,
          comparison: this.conditionOptionsIterable[0].comparisonOperators[0]
            .type
        }
      ]
    };

    this.rootQuery.subQueries.push(newGroup);
  }

  removeGroup(subQueryIndex) {
    this.rootQuery.subQueries.splice(subQueryIndex, 1);
  }

  deleteStream(streamQuery) {
    this.streamBuilderService.deleteSearchStream(this.streamQuery.id, true);
  }

  isQueryValid(): boolean {
    const hasRootQuery = !!this.rootQuery.conditions.length;
    const hasSubQuery = !!this.rootQuery.subQueries.length;
    const rootQueryHasValues =
      hasRootQuery &&
      this.rootQuery.conditions.every((condition) => condition.value);
    const subQueryHasValues =
      hasSubQuery &&
      this.rootQuery.subQueries.every((subQuery) =>
        subQuery.conditions.every((condition) => condition.value)
      );
    return (
      (!hasSubQuery && rootQueryHasValues) ||
      (!hasRootQuery && subQueryHasValues) ||
      (subQueryHasValues && rootQueryHasValues)
    );
  }

  saveStep() {
    const buildQuery = {};
    buildQuery[this.rootQuery.operator] = this.rootQuery.conditions;
    this.rootQuery.subQueries.forEach((subQuery) => {
      const buildSubQuery = {};
      buildSubQuery[subQuery.operator] = subQuery.conditions;
      buildQuery[this.rootQuery.operator].push(buildSubQuery);
    });
    this.streamQuery.query = buildQuery;
    this.streamBuilderService.updateStreamQuery(this.streamQuery);
  }
}
