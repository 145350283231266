import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TranslateModule } from '@ngx-translate/core';
import { OrloComponentsModule } from 'orlo-components';

import { GlobalFiltersComponent } from './global-filters.component';

import { Checkbox2Module } from '../../../../../../common/components/checkbox-2/checkbox-2.module';
import { DropdownSelect2Module } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../../common/components/social-network-icon/social-network-icon.module';
import { FilterModule } from '../../../../../../common/pipes/filter/filter.module';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { TagsInputModule } from '../tags-input/tags-input.module';
import { InsightsFiltersService } from './insights-filters.service';

@NgModule({
  declarations: [GlobalFiltersComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule,
    TranslateModule,
    OrloComponentsModule,
    Checkbox2Module,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    FilterModule,
    TooltipModule,
    TagsInputModule
  ],
  exports: [GlobalFiltersComponent],
  providers: [InsightsFiltersService]
})
export class GlobalFiltersModule {}
