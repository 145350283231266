import { Component, OnInit, Input } from '@angular/core';
import './my-preferences.component.scss';
import { FormGroup, FormBuilder } from '@angular/forms';
import { serverSettings } from '../../../../common-resolves';
import { StateService } from '@uirouter/angular';
import {
  UserPreferences,
  UserPreferencesService
} from '../../../../../common/services/user-preferences/user-preferences.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { UserModel } from '../../../../../../../library/ui-resources/modules/user/services/userModel';

@Component({
  selector: 'ssi-my-preferences',
  templateUrl: './my-preferences.component.html',
  styles: []
})
export class MyPreferencesComponent implements OnInit {
  static resolve = [serverSettings];
  @Input() serverSettings;
  accountSummaryEmailEnabled = false;

  userPermissions;
  userPreferences: UserPreferences;
  loadingTracker = this.asyncTrackerFactory.create();

  userPreferencesForm: FormGroup;

  hideLiveChatPreferences = true;

  constructor(
    private state: StateService,
    private userPreferencesService: UserPreferencesService,
    private fb: FormBuilder,
    private asyncTrackerFactory: AsyncTrackerFactory,
    private companyService: CompanyService,
    private userModel: UserModel
  ) {}

  async ngOnInit() {
    this.userPreferences = await this.userPreferencesService.getPreferences();
    this.accountSummaryEmailEnabled = await this.companyService.hasFeatureAccess(
      'DAILY_EMAIL'
    );

    this.userPreferencesForm = this.fb.group({
      account_summary_email: [
        JSON.parse(this.userPreferences.account_summary_email)
      ],
      account_summary_email_time: [
        this.userPreferences.account_summary_email_time
      ],
      outbox_passed_validation_email: [
        JSON.parse(this.userPreferences.outbox_passed_validation_email)
      ],
      timezone: [this.userPreferences.timezone],
      inbox_signature: [this.userPreferences.inbox_signature],
      inbox_message_read_more: JSON.parse(
        this.userPreferences.inbox_message_read_more
      ),
      audio_chat_prompt: JSON.parse(this.userPreferences.audio_chat_prompt),
      notification_chat_prompt: JSON.parse(
        this.userPreferences.notification_chat_prompt
      )
    });

    this.hideLiveChatPreferences = await this.checkIfUserHasWidgetAccess();
  }

  async saveUserPreferences() {
    const formToSave = {
      ...this.userPreferencesForm.value,
      account_summary_email: Number(
        this.userPreferencesForm.get('account_summary_email').value
      ).toString(),
      outbox_passed_validation_email: this.userPreferencesForm
        .get('outbox_passed_validation_email')
        .value.toString(),
      inbox_message_read_more: this.userPreferencesForm
        .get('inbox_message_read_more')
        .value.toString(),
      audio_chat_prompt: this.userPreferencesForm
        .get('audio_chat_prompt')
        .value.toString(),
      notification_chat_prompt: this.userPreferencesForm
        .get('notification_chat_prompt')
        .value.toString()
    };
    const promise = this.userPreferencesService.savePreferences(formToSave);
    this.loadingTracker.add(promise);
    this.return();
  }

  private async checkIfUserHasWidgetAccess(): Promise<boolean> {
    const userPermissions = await this.userModel.getPermissions(true);
    const widgetsWithAgentPermission = Object.values(userPermissions).filter(
      (accountPermission) => {
        return (accountPermission as any).agent;
      }
    );
    return widgetsWithAgentPermission.length ? true : false;
  }

  return() {
    this.state.go('^');
  }
}
