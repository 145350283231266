import { Activity, ActivityModel } from './activityModel';
import { OutboxModel } from '@ui-resources-angular';
import { api } from '../../core/services/api';
import { services } from '../../common';

export interface ActivityThreadPartialActivityCollection {
  activities: Activity[];
  missing: number;
  pagination: {
    next: object;
  };
  hiddenActivities?: Activity[];
}

export class ActivityThread {
  thread: {
    parents: Activity[];
    current: Activity;
    children: ActivityThreadPartialActivityCollection;
    siblings: {
      newer: ActivityThreadPartialActivityCollection;
      older: ActivityThreadPartialActivityCollection;
    };
    root: Activity;
    newReply?: Activity;
    requires_validation?: any[];
  };

  constructor(private activity: Activity) {}

  loadThread(): Promise<ActivityThread> {
    const activityModel: ActivityModel = services.models.get<ActivityModel>(
      'activity'
    );
    return api
      .get('inbox/thread', { params: { for: this.activity.id } })
      .then(({ data: thread }) => {
        if (thread.children) {
          thread.children.activities = activityModel.inject(
            thread.children.activities
          );
        } else {
          thread.children = {
            activities: []
          };
        }

        if (thread.parents) {
          thread.parents = activityModel.inject(thread.parents);
        } else {
          thread.parents = [];
        }

        const socialNetwork = this.activity.account.socialNetwork;

        if (
          this.activity.interaction.is_private &&
          socialNetwork.activity &&
          socialNetwork.activity.flattenPrivateThreads
        ) {
          thread.parents = [];
        }

        thread.current = activityModel.inject(thread.current);

        thread.siblings.newer.activities = activityModel.inject(
          thread.siblings.newer.activities
        );

        thread.siblings.older.activities = activityModel.inject(
          thread.siblings.older.activities
        );

        this.thread = thread;

        this.orderThreadActivities();

        return this;
      });
  }

  orderThreadActivities(): void {
    function sortActivities(activity1, activity2): number {
      const createdAt1: number = new Date(
        activity1.interaction.created_at
      ).getTime();
      const createdAt2: number = new Date(
        activity2.interaction.created_at
      ).getTime();
      return createdAt1 - createdAt2;
    }

    this.thread.children.activities.sort(sortActivities);
    this.thread.siblings.older.activities.sort(sortActivities);
    this.thread.siblings.newer.activities.sort(sortActivities);

    if (this.thread.requires_validation.length) {
      this.thread.children.activities = this.thread.children.activities.filter(
        (activity) => !activity.isConvertedToActivity
      );
      this.thread.siblings.older.activities = this.thread.siblings.older.activities.filter(
        (activity) => !activity.isConvertedToActivity
      );
      this.thread.siblings.newer.activities = this.thread.siblings.newer.activities.filter(
        (activity) => !activity.isConvertedToActivity
      );

      this.injectRequiresValidationOutboxMessages();

      // put temp activities under activities in validation
      this.thread.siblings.newer.activities
        .filter((activity) => activity.isTempActivity)
        .forEach((activity) => {
          this.thread.siblings.newer.activities.push(
            this.thread.siblings.newer.activities.splice(
              this.thread.siblings.newer.activities.findIndex(
                (_activity) => _activity.id === activity.id
              ),
              1
            )[0]
          );
        });
    }
  }

  injectRequiresValidationOutboxMessages() {
    const sortByCreated = (a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return dateA > dateB ? 1 : -1;
    };

    if (this.thread.current.interaction.is_private) {
      this.thread.requires_validation.sort(sortByCreated).forEach((outbox) => {
        this.thread.siblings.newer.activities.push(
          services.models
            .get<OutboxModel>('outbox')
            .inject(outbox)
            .convertToActivity(this.thread.current)
        );
      });
    } else {
      this.thread.requires_validation.sort(sortByCreated).forEach((outbox) => {
        if (
          outbox.in_reply_to_social_id === this.thread.current.interaction.id
        ) {
          this.thread.children.activities.push(
            services.models
              .get<OutboxModel>('outbox')
              .inject(outbox)
              .convertToActivity(this.thread.current)
          );
        }
        if (
          this.thread.parents.find(
            (parentActivity) =>
              parentActivity.interaction.id === outbox.in_reply_to_social_id
          )
        ) {
          this.thread.siblings.newer.activities.push(
            services.models
              .get<OutboxModel>('outbox')
              .inject(outbox)
              .convertToActivity(this.thread.current)
          );
        }

        if (
          this.thread.siblings.newer.activities.find(
            (newerActivity) =>
              newerActivity.interaction.id === outbox.in_reply_to_social_id
          ) ||
          this.thread.siblings.older.activities.find(
            (olderActivity) =>
              olderActivity.interaction.id === outbox.in_reply_to_social_id
          )
        ) {
          const isCommentThread =
            this.thread.current.social_actions.reply_type === 'comment';
          const socialNetwork = this.thread.current.account.socialNetwork;
          const flattenPrivateThreads =
            socialNetwork.activity &&
            socialNetwork.activity.flattenPrivateThreads;
          if (isCommentThread && !flattenPrivateThreads) {
            // TODO: push for sibling children, requires fake tier implementation in UI
          } else {
            this.thread.siblings.newer.activities.push(
              services.models
                .get<OutboxModel>('outbox')
                .inject(outbox)
                .convertToActivity(this.thread.current)
            );
          }
        }
      });
    }
  }

  loadMoreOlderSiblings(): Promise<ActivityThread> {
    return this.loadMore(this.thread.siblings.older);
  }

  loadMoreChildren(): Promise<ActivityThread> {
    return this.loadMore(this.thread.children);
  }

  loadMoreNewerSiblings(): Promise<ActivityThread> {
    return this.loadMore(this.thread.siblings.newer);
  }

  private loadMore(
    threadSection: ActivityThreadPartialActivityCollection
  ): Promise<ActivityThread> {
    const params = Object.assign(
      { for: this.activity.id },
      threadSection.pagination.next
    );
    return api
      .get<{ data: { activities: any[] } }>('inbox/thread', { params })
      .then(({ data }) => {
        const activities = [
          ...threadSection.activities,
          ...services.models
            .get<ActivityModel>('activity')
            .inject(data.activities)
        ];
        Object.assign(threadSection, data, { activities });
        this.orderThreadActivities();
        return this;
      });
  }
}

export class ActivityThreadFactory {
  // tslint:disable-line

  create(activity: Activity): Promise<ActivityThread> {
    const thread: ActivityThread = new ActivityThread(activity);
    return thread.loadThread();
  }
}
