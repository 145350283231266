import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';

import { CreateComponent } from './create.component';

import { WidgetsGridModule } from '../../common/components/widgets-grid/widgets-grid.module';
import { InsightsNavbarModule } from '../../common/components/insights-navbar/insights-navbar.module';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights.reports.create',
          url: '/create?baseName&reportIdToCopy',
          component: CreateComponent,
          resolve: CreateComponent.resolve
        }
      ]
    }),
    WidgetsGridModule,
    InsightsNavbarModule,
    DropdownSelect2Module,
    TranslateModule
  ],
  exports: [],
  declarations: [CreateComponent]
})
export class CreateModule {}
