<div class="manage-ifttt-applet-summary">

  <div class="manage-ifttt-applet-summary-inner">
    <h1 class="manage-ifttt-applet-summary-title">
      {{ applet.name }}
      <div class="manage-ifttt-applet-summary-title-separator"></div>
    </h1>

    <div class="manage-ifttt-applet-summary-sub-title">
      Check that you are happy with your trigger, then hit save! You will find your new trigger in <span class="text-primary">My triggers</span>
    </div>

    <div class="manage-ifttt-applet-summary-columns">
      <div class="manage-ifttt-applet-summary-column accounts">
        <div *ngIf="!applet.streams.length" class="manage-ifttt-applet-summary-column-title">
          Posts to my <a uiSref="^.accounts"><i class="ssi ssi-pencil"></i></a>
        </div>
        <div *ngIf="applet.streams.length" class="manage-ifttt-applet-summary-column-title">
          Posts to <a uiSref="^.streams"><i class="ssi ssi-pencil"></i></a>
        </div>
        <div class="manage-ifttt-applet-summary-column-items">
          <ng-container *ngIf="!applet.streams.length">
            <div
              class="manage-ifttt-applet-summary-column-item"
              *ngFor="let account of applet.accounts; trackBy:trackByIndex">
              <div class="manage-ifttt-applet-summary-column-item-icon">
                <i [ngClass]="account.socialNetwork.icon.web"></i>
              </div>
              <div class="manage-ifttt-applet-summary-column-item-title-container">
                <div class="manage-ifttt-applet-summary-column-item-title">{{ account.displayName }}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="applet.streams.length">
            <div
              class="manage-ifttt-applet-summary-column-item"
              *ngFor="let stream of applet.streams; trackBy:trackByIndex">
              <!-- <div class="manage-ifttt-applet-summary-column-item-icon">
                <i [ngClass]="account.socialNetwork.icon.web"></i>
              </div> -->
              <div class="manage-ifttt-applet-summary-column-item-title-container">
                <div class="manage-ifttt-applet-summary-column-item-title">{{ stream.name }}</div>
              </div>
            </div>
          </ng-container>
        </div>

      </div>

      <div class="manage-ifttt-applet-summary-column triggers">
        <div class="manage-ifttt-applet-summary-column-title">
          Which contains <a uiSref="^.triggersAndActions"><i class="ssi ssi-pencil"></i></a>
        </div>
        <div class="manage-ifttt-applet-summary-column-items">
          <div class="manage-ifttt-applet-summary-column-item" *ngFor="let trigger of applet.triggers; trackBy:trackByIndex">
            <div class="manage-ifttt-applet-summary-column-item-icon">
              <i [ngClass]="trigger.trigger.iconClass"></i>
            </div>
            <div class="manage-ifttt-applet-summary-column-item-title-container">
              <div class="manage-ifttt-applet-summary-column-item-title">{{ trigger.label }}</div>
            </div>
          </div>
        </div>

      </div>

      <div class="manage-ifttt-applet-summary-column actions">
        <div class="manage-ifttt-applet-summary-column-title">
          The action is <a uiSref="^.triggersAndActions" [uiParams]="{mode: 'actions'}"><i class="ssi ssi-pencil"></i></a>
        </div>
        <div class="manage-ifttt-applet-summary-column-items">
          <div
            class="manage-ifttt-applet-summary-column-item"
            *ngFor="let action of applet.actions; trackBy:trackByIndex">
            <div class="manage-ifttt-applet-summary-column-item-icon">
              <i [ngClass]="action.action.iconClass"></i>
            </div>
            <div class="manage-ifttt-applet-summary-column-item-title-container">
              <div
                class="manage-ifttt-applet-summary-column-item-title"
                [innerHtml]="action.label | nl2br">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="manage-ifttt-applet-summary-save">
      <button class="btn btn-primary" (click)="saveApplet()">Save</button>
    </div>
  </div>

</div>