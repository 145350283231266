import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { OfficeHoursComponent } from './office-hours.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ToggleSwitch2Module } from '../../../../../common/components/toggle-switch-2/toggle-switch-2.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.officeHours',
          url: '/settings/office-hours',
          component: OfficeHoursComponent,
          resolve: OfficeHoursComponent.resolve
        }
      ]
    }),
    TranslateModule,
    FormsModule,
    AlertsModule,
    NgbTimepickerModule,
    ToggleSwitch2Module
  ],
  declarations: [OfficeHoursComponent],
  entryComponents: [OfficeHoursComponent]
})
export class OfficeHoursModule {}
