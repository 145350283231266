<form #advertForm="ngForm" *ngIf="activeAdvert">
  <div class="advertising-section">
    <div class="row">
      <div class="col-xs-8 col-xs-offset-2">
        <ssi-outbox-post [post]="activeAdvert.outbox"></ssi-outbox-post>
      </div>
    </div>
  </div>

  <div class="advertising-section">
    <div class="advertising-section-bullet-wrap">
      <span class="advertising-section-bullet">
        <i [ngClass]="activeAdvert.account && activeAdvert.account?.socialNetwork.icon.web"></i>
      </span>
    </div>
    <div class="advertising-preset-info">
      <div>
        <div class="advertising-preset-wrap">
          <p class="advertising-label">Name:</p>
          <p class="advertising-static">{{activeAdvert.name}}</p>
        </div>
        <div class="advertising-preset-wrap">
          <p class="advertising-label">Account:</p>
          <p class="advertising-static">{{activeAdvert.account?.accountTypeLabel}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="advertising-section">
    <div class="advertising-section-bullet-wrap">
      <span class="advertising-section-bullet">
        <i class="ssi ssi-audience"></i>
      </span>
    </div>
    <ssi-advertising-targeting-builder
      [account]="activeAdvert.account"
      [(targetingOptions)]="activeAdvert.targeting_options"
      (targetingOptionsChange)="getBudgetSuggestion()"
      *ngIf="activeAdvert.account">
    </ssi-advertising-targeting-builder>
  </div>

  <div class="advertising-section advertising-section-wrap">
    <div class="advertising-section-bullet-wrap">
      <span class="advertising-section-bullet">
        <i class="ssi ssi-budget"></i>
      </span>
    </div>
    <div class="advertising-preset-wrap">
      <div class="advertising-preset-wrap" *ngIf="activeAdvert.budget.daily_budget">
        <p class="advertising-label"><span translate="DAILY_BUDGET"></span>:</p>
        <div class="preset-form-input-group input-group">
          <span class="input-group-addon">{{ currencySymbols[activeAdvert.budget.daily_budget.currency] }}</span>
          <input type="text"
                 class="preset-form-input"
                 (ngModelChange)="activeAdvert.budget.daily_budget.amount=$event"
                 [ngModel]="activeAdvert.budget.daily_budget.amount | formatBudget"
                 [ngModelOptions]="{updateOn: 'blur'}"
                 required
                 name="dailyBudget">
        </div>
        <div class="col-sm-6" *ngIf="budgetSuggestion">
          <p class="form-control-static"><em><span translate="MINIMUM_BUDGET"></span> {{ budgetSuggestion?.dailyBudget.min.amount | currency: budgetSuggestion?.dailyBudget.min.currency }}</em></p>
        </div>
      </div>

      <div class="advertising-preset-wrap" *ngIf="activeAdvert.budget.total_budget">
        <p class="advertising-label"><span translate="TOTAL_BUDGET"></span>:</p>
        <div class="preset-form-input-group input-group">
          <span class="input-group-addon">{{ currencySymbols[activeAdvert.budget.total_budget.currency] }}</span>
          <input type="text"
                 class="preset-form-input"
                 (ngModelChange)="activeAdvert.budget.total_budget.amount=$event"
                 [ngModel]="activeAdvert.budget.total_budget.amount | formatBudget"
                 [ngModelOptions]="{updateOn: 'blur'}"
                 required
                 name="totalBudget">
        </div>
        <div class="col-sm-6" *ngIf="budgetSuggestion">
          <p class="form-control-static"><em><span translate="MINIMUM_BUDGET"></span> {{ budgetSuggestion?.dailyBudget.min.amount | currency: budgetSuggestion?.dailyBudget.min.currency }}</em></p>
        </div>
      </div>

      <div class="advertising-preset-wrap">
        <div class="advertising-preset-wrap">
          <p class="advertising-label"><span translate="BID_TYPE"></span>:</p>
          <p class="advertising-static">{{activeAdvert.bidding.bidding_event}}</p>
        </div>
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="BID_AMOUNT"></span>:</p>
        <div class="preset-form-input-group input-group">
          <span class="input-group-addon">{{ currencySymbols[activeAdvert.bidding.amount.currency] }}</span>
          <input type="text"
                 class="preset-form-input"
                 (ngModelChange)="activeAdvert.bidding.amount.amount=$event"
                 [ngModel]="activeAdvert.bidding.amount.amount | formatBudget"
                 [ngModelOptions]="{updateOn: 'blur'}"
                 required
                 name="bidAmount">
        </div>
        <div class="col-sm-6" *ngIf="budgetSuggestion">
          <p class="form-control-static">
            <em>
              The suggested bid to reach your audience and spend your budget is: {{ budgetSuggestion.suggestedBid.default.amount | currency:budgetSuggestion.suggestedBid.default.currency }}.
              (Other similar advertisers are bidding between {{ budgetSuggestion.suggestedBid.min.amount | currency:budgetSuggestion.suggestedBid.min.currency }} – {{ budgetSuggestion.suggestedBid.max.amount | currency:budgetSuggestion.suggestedBid.max.currency }}.)
            </em>
          </p>
          <div>
            <p class="form-control-static">
              <em>
                <span translate="MINIMUM_BID"></span> {{ budgetSuggestion.bid.min.amount | currency:budgetSuggestion.bid.min.currency }}.
              </em>
            </p>
          </div>
        </div>
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="START"></span>:</p>
        <p *ngIf="activeAdvert.external_status === 'ACTIVE'" class="advertising-static">{{activeAdvert.schedule.start  | date:'d MMM y, hh:mm'}}</p>
        <input
          *ngIf="activeAdvert.external_status !== 'ACTIVE'"
          class="preset-form-input"
          type="text"
          mwlFlatpickr
          [(ngModel)]="activeAdvert.schedule.start"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-d H:i"
          [minDate]="now"
          [maxDate]="activeAdvert.schedule.end | addSeconds:-60"
          [time24hr]="true"
          altFormat="M J Y, H:i"
          placeholder="Start"
          required
          name="start">
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="END"></span>:</p>
        <input
          class="preset-form-input"
          type="text"
          mwlFlatpickr
          [(ngModel)]="activeAdvert.schedule.end"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-d H:i"
          [minDate]="(activeAdvert.schedule.start || now) | addSeconds:60"
          [time24hr]="true"
          altFormat="M J Y, H:i"
          placeholder="End"
          name="end">
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="LANGUAGE"></span>:</p>
        <p class="advertising-static">{{getLanguage(activeAdvert.locale.language)}}</p>
      </div>
    </div>

    <div class="advertising-preset-wrap">
      <div class="advertising-section-bullet-wrap">
        <span class="advertising-section-bullet">
          <i class="ssi ssi-ad-schedule"></i>
        </span>
      </div>
      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="SCHEDULE"></span>:</p>
        <button
          class="btn btn-warning btn-advertising-preset"
          [disabled]="loadingTracker.active || advertForm.invalid">
          <i class="fa fa-spinner fa-spin" *ngIf="loadingTracker.active"></i> Edit Ad
        </button>
      </div>
    </div>
  </div>

</form>
