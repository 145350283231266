<div class="team-build-container">
  <div class="head">
    <h3>Team Report</h3>
    <h2>Build your report</h2>
  </div>

  <div class="body">
    <div class="body-inner">
      <form
        #form="ngForm"
        (submit)="submit(form)"
      >
        <div class="form-row">
          <div class="form-field half">
            <label translate="START_DATE"></label>
            <div
              class="input-wrapper"
              #startDateContainer
            >
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="startDate"
                [(ngModel)]="startDate"
                (ngModelChange)="onStartDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="false"
                [defaultHour]="00"
                [defaultMinute]="00"
                [defaultSeconds]="00"
                dateFormat="Y-m-d"
                altFormat="M J Y"
                [maxDate]="endDate"
                [time24hr]="true"
                [appendTo]="startDateContainer.nativeElement"
                placeholder="Select..."
                [required]="true"
                (flatpickrOpen)="onFlatpickrOpen()"
              >
            </div>
          </div>
          <div class="form-field half">
            <label translate="END_DATE"></label>
            <div class="input-wrapper" #endDateContainer>
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="endDate"
                [(ngModel)]="endDate"
                (ngModelChange)="onEndDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="false"
                [defaultHour]="23"
                [defaultMinute]="59"
                [defaultSeconds]="59"
                dateFormat="Y-m-d"
                altFormat="M J Y"
                [minDate]="startDate"
                [maxDate]="today"
                [time24hr]="true"
                [appendTo]="endDateContainer.nativeElement"
                placeholder="Select..."
                [required]="true"
                (flatpickrOpen)="onFlatpickrOpen()"
              >
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
            <div class="input-wrapper">
              <div
                *ngFor="let range of dateRangesIterable"
                class="inline-select-item"
                [class.selected]="range.id === selectedDateRange?.id"
                (click)="selectPresetDateRange(range)"
              >
                <i
                  *ngIf="range.id === selectedDateRange?.id"
                  class="ssi ssi-date-preset"
                ></i>
                <span>{{range.label}}</span>
              </div>
            </div>
          </div>
        </div>
      
        <button
          class="btn submit-button"
          type="submit"
          [disabled]="form.invalid"
        >Build analytics</button>
      </form>
    </div>
  </div>
</div>