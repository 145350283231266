import './redact-modal.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-redact-modal',
  templateUrl: './redact-modal.component.html'
})
export class RedactModalComponent {
  @Input() info: string;
  redactString: string;
  isInvalid = true;

  constructor(public activeModal: NgbActiveModal) {}

  validate(event) {
    this.isInvalid = event !== 'REDACT' ? true : false;
  }
}
