<div class="container">
  <h1 class="policy-title" translate="COMPANY_POLICY_AGREEMENT"></h1>

  <div
    *ngIf="policy.html"
    class="well scroll-y"
    [innerHTML]="policy.html"
  ></div>

  <div class="row text-right">
    <br>
    <button class="btn btn-lg btn-success" (click)="agree()">
      <i class="fa fa-check"></i> <span translate="I_AGREE_LETS_GET_STARTED"></span>
    </button>
  </div>
</div>
