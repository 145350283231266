<div class="breakdown-container">
  <div class="section-head">
    <h2>
      <span>
        Breakdown
      </span>
    </h2>
    <!-- <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select> -->
  </div>

  <div class="section-body table-container">
    <div class="options-row">
      <div class="dropdown-select">
        <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                               [options]="showTypes"
                               [(ngModel)]="selectedShowType"
                               (ngModelChange)="onShowTypeChange($event)"></ssi-dropdown-select-2>
      </div>
    </div>

    <ssi-treetable [data]="dataToDisplay"
                   [bodyStyle]="{'max-height': ''}"
                   [rowStyle]="{'height': '60px'}"
                   [virtualScroll]="false"
                   [resizeMode]="'expand'"
                   [reorderable]="true"
                   [saveSettings]="true"
                   [settingsStorageKey]="'treetable-validation-tags-breakdown'"
                   [globalFilterInputRef]=""
                   id="treetable-rejected-posts-analytics-breakdown">

      <ssi-column [field]="dataProp"
                  [header]="dataTitle"
                  width="12%"
                  [templateRefs]="{bodyCell: accountBodyCellTemplate}"></ssi-column>

      <ssi-column *ngFor="let tag of stats.validationsTagNames"
                  [field]="tag"
                  [header]="tag"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <!-- template for treetable options (global filter and columns selector) -->
      <!-- <ng-template ssiTemplateSelector="options"
                   let-columns="columns"
                   let-columnsMap="columnsMap">
        <div class="">
          <span class="global-filter">
            <input type="text"
                   id="account-summary-stats-table-global-filter"
                   placeholder="Filter..."
                   value="">
          </span>
        </div>
      </ng-template> -->
    </ssi-treetable>


    <ng-template #accountBodyCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-fieldValue="fieldValue">
      <div class="body-cell account"
           [class.row-even]="rowIndex % 2 !== 0">
        <ssi-social-network-icon *ngIf="rowData.account" [accountTypeId]="rowData.account.account_type_id"
                                 [size]="20"></ssi-social-network-icon>
        <span title="{{fieldValue}}">{{fieldValue}}</span>
      </div>
    </ng-template>

    <ng-template #statBodyCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-column="column"
                 let-columnIndex="columnIndex"
                 let-columns="columns"
                 let-columnsMap="columnsMap"
                 let-fieldValue="fieldValue">
      <div class="body-cell stat"
           [class.row-even]="rowIndex % 2 !== 0">
        <span *ngIf="!isNumber(fieldValue)"
              [ssiTooltip]="'This tag has not been applied to any post for this network within the given period'"
              tooltipWindowClass="tooltip-chunky-big">0</span>
        <ng-container *ngIf="isNumber(fieldValue)">
          <ng-container>
            <span>{{fieldValue}}</span>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>