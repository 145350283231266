import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingEditPresetComponent } from './advertising-edit-preset.component';
import { TranslateModule } from '@ngx-translate/core';
import { AdvertisingTargetingBuilderModule } from '../advertising-targeting-builder/advertising-targeting-builder.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.editPreset',
          url: '/preset/:presetId/edit',
          component: AdvertisingEditPresetComponent,
          resolve: AdvertisingEditPresetComponent.resolve
        }
      ]
    }),
    AdvertisingTargetingBuilderModule
  ],
  declarations: [AdvertisingEditPresetComponent],
  exports: [AdvertisingEditPresetComponent]
})
export class AdvertisingEditPresetModule {}
