<div class="schedule-first-comment-container">
	<div class="head g-box-shadow-bottom">
		<h3>{{outboxPost || editPostModeAndHasAutoComment() ? 'Edit first comment' : 'Schedule first comment' }}</h3>
		<i class="close-icon ssi ssi-times"
		   (click)="activeModal.dismiss()"></i>
	</div>

	<div class="body">
		<ng-container *ngTemplateOutlet="outboxPostTemplate; context: {post: post}"></ng-container>

		<div class="connector"></div>

		<div class="comment-add-button-section"
			 *ngIf="!replyBoxVisible">
			<button type="button"
					class="g-btn border-radius-10"
					(click)="replyBoxVisible = true"><span class="plus-sign">+</span><span>Schedule first comment</span></button>
		</div>

		<ssi-reply-box *ngIf="replyBoxVisible"
					   (onKeyUp)="onKeyUp($event)"
					   (onKeyDown)="onKeyDown($event)"
					   (onSendReply)="schedulePostOrEditFirstComment($event)"
					   (onUpdateReplyTextValue)="updateReplyText($event)"
					   (onRemoveAttachment)="reply.file = undefined"
					   [isInbox]="false"
					   [isSocialWall]="false"
					   [firstCommentScheduling]="true"
					   [replyValue]="reply.text"
					   [prediction]="prediction"
					   [activity]="{interaction: {}, conversation: {}}"
					   [isDisabled]="false"
					   [accountTypeName]="reply.account.account_type_name"
					   [mediaRestrictions]="reply.account.socialNetwork.mediaRestrictions.reply"
					   [replyInProgress]="reply.tracker.active"
					   (onFileUploadSuccess)="reply.file = $event"
					   [shouldShowPushToCrm]="false"
					   [replyAccounts]="[reply.account]"
					   [replyAccount]="reply.account"
					   [includableReplyToUsers]="reply.users"
					   [replyingToVisible]="false"
					   [networkReplyOptions]="reply.account.socialNetwork.activity"
					   [shouldShowTranslate]="!!translation"
					   (onTranslate)="translateReplyText()"
					   [attachments]="reply.file"
					   [isTimedSession]="false"
					   [supportsAddReplyLink]="reply.account.socialNetwork.getPrivateReplyLink"
					   (onAddPrivateReplyLink)="addPrivateReplyLink()"
					   [focusReply]="!!prepopulateReply"
					   [hideArrowShortcuts]="true"
					   [isPushMode]="false"></ssi-reply-box>

	</div>

	<div class="foot g-box-shadow-top">
		<button class="g-btn g-btn-secondary bg-white delete-button"
				type="button"
				*ngIf="outboxPost || editPostModeAndHasAutoComment()"
				(click)="deleteMenu.show($event);"><i class="ssi ssi-small-delete"></i><span>Delete first comment</span>
		</button>

		<button class="g-btn g-btn-secondary bg-white cancel-button"
				type="button"
				*ngIf="editPostModeAndHasAutoComment()"
				(click)="activeModal.dismiss()"><span>{{'Cancel'}}</span></button>

		<button class="g-btn g-btn-secondary bg-white cancel-button"
				type="button"
				*ngIf="!editPostModeAndHasAutoComment()"
				(click)="activeModal.close()"><span>{{outboxPost ? 'Cancel' : 'Skip for this account'}}</span></button>

		<button class="g-btn submit-button"
				type="submit"
				(click)="replyBox.sendReply(replyBox.replyText)"
				[disabled]="!canPublish()"><span>{{outboxPost || editPostModeAndHasAutoComment() ? 'Edit first comment' : 'Schedule'}}</span></button>
	</div>
</div>

<ng-template #outboxPostTemplate
			 let-post="post">
	<div class="outbox-post-container">
		<div class="left">
			<img class="account-image"
				 [src]="post.account.picture"
				 ssiFallbackImage="brokenImage">
		</div>

		<div class="middle">
			<div class="metadata">
				<span>{{post.account.username}}</span>
				<div class="icon-container">
					<ssi-social-network-icon [accountTypeId]="post.account.account_type_id"
											 variant="fa"></ssi-social-network-icon>
				</div>
				<div class="timestamp">
					<!-- <span>{{post.createdAtDate}}</span> &nbsp;&nbsp;|&nbsp;&nbsp; <span>{{post.createdAtTime}}</span> -->
				</div>
			</div>

			<div class="content">
				<p *ngIf="post.text"
				   [innerHTML]="post.text | linkify: {mentions: post.mentions}"></p>
			</div>
		</div>

		<div class="right">
			<div class="gallery"
				 *ngIf="post.outbox_files.length">
				<ng-container *ngFor="let file of post.outbox_files | slice:0:2 | reverse; let i=index">
					<ng-container *ngTemplateOutlet="fileItemTemplate; context: {fileItem: file, fileItemIndex: i }"></ng-container>
				</ng-container>

				<div class="media-items-count"
					 *ngIf="post.outbox_files.length > 1">
					<span>{{post.outbox_files.length - 1}}+</span>
				</div>
			</div>

			<ng-template #fileItemTemplate
						 let-fileItem="fileItem"
						 let-fileItemIndex="fileItemIndex">
				<img *ngIf="fileItem.type ? fileItem.type === 'image' : fileItem.mimetype.indexOf('image') > -1"
					 [src]="fileItem.url || fileItem.public_url"
					 [class.stacked]="fileItemIndex > 0"
					 [style.top]="(fileItemIndex * 7) + 'px'"
					 [style.left]="(fileItemIndex * 7) + 'px'">

				<div *ngIf="fileItem.type ? fileItem.type !== 'image' : fileItem.mimetype.indexOf('image') === -1"
					 class="video-icon-container"
					 [class.stacked]="fileItemIndex > 0"
					 [style.top]="(fileItemIndex * 7) + 'px'"
					 [style.left]="(fileItemIndex * 7) + 'px'">
					<i class="ssi ssi-video-circle"></i>
				</div>
			</ng-template>
		</div>

		<!-- <ssi-outbox-post-menu [post]="post"></ssi-outbox-post-menu> -->
	</div>
</ng-template>


<ssi-context-menu #deleteMenu
				  [offsetLeft]="-300"
				  (ssiOffClick)="deleteMenu.hide();">
	<ng-template let-ctx>
		<ssi-confirm-action title="Are you sure you want to delete this comment?"
							dismissText="Cancel"
							confirmText="Delete"
							dismissTextColour="#43537f"
							confirmTextColour="#F88C68"
							(onDismiss)="deleteMenu.hide();"
							(onConfirm)="deleteMenu.hide(); editOrDeleteFirstComment(null);"></ssi-confirm-action>
	</ng-template>
</ssi-context-menu>