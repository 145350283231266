import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetThemeCloudComponent } from './widget-theme-cloud.component';
import { BigNumberModule } from '../../../../../../common/components/big-number/big-number.module';
import { SortByModule } from '../../../../../../common/pipes/sort-by/sort-by.module';

@NgModule({
  declarations: [WidgetThemeCloudComponent],
  imports: [CommonModule, BigNumberModule, SortByModule],
  exports: [WidgetThemeCloudComponent]
})
export class WidgetThemeCloudModule {}
