<ng-container
  #container
  *ngIf="!! isLiveChatEnabled"
>
  <ssi-live-chat-tally-tile
    [hasConversationPushModeEnabled]="hasConversationPushModeEnabled"
    *ngIf="! isLiveChatActive"
    (isLiveChatActiveChange)="updateLiveChatStatus($event)"
    [ageOfNewestAssignedConversation]="ageOfNewestAssignedConversation"
    [ageOfNewestUnassignedConversation]="ageOfNewestUnassignedConversation"
    [quantityOfOngoingConversations]="quantityOfOngoingConversations"
    [quantityOfOwnedConversations]="quantityOfOwnedConversations"
    [quantityOfUnassignedConversations]="quantityOfUnassignedConversations"
  ></ssi-live-chat-tally-tile>

  <ssi-live-chat-navigation
    *ngIf="isLiveChatActive"
    (isLiveChatActiveChange)="updateLiveChatStatus(false)"
    [isLiveChatActive]="!! isLiveChatActive"
    class="spacer-bottom"
  ></ssi-live-chat-navigation>

<!--
  <ssi-live-chat-tally-tile
    *ngIf="isLiveChatActive && isPushModeEnabled"
    (isLiveChatActiveChange)="updateLiveChatStatus($event)"
    [ageOfNewestAssignedConversation]="ageOfNewestAssignedConversation"
    [ageOfNewestUnassignedConversation]="ageOfNewestUnassignedConversation"
    [quantityOfOngoingConversations]="quantityOfOngoingConversations"
    [quantityOfOwnedConversations]="quantityOfOwnedConversations"
    [quantityOfUnassignedConversations]="quantityOfUnassignedConversations"
    class="spacer-bottom"
  ></ssi-live-chat-tally-tile>
-->

  <ssi-live-chat-next-chat
    *ngIf="isLiveChatActive"
    (conversationsFilterChange)="updateFilter($event)"
    [ageOfNewestAssignedConversation]="ageOfNewestAssignedConversation"
    [ageOfNewestUnassignedConversation]="ageOfNewestUnassignedConversation"
    [class.expanded]="! isPushModeEnabled && ! quantityOfVisibleConversations"
    [quantityOfOngoingConversations]="quantityOfOngoingConversations"
    [quantityOfOwnedConversations]="quantityOfOwnedConversations"
    [quantityOfUnassignedConversations]="quantityOfUnassignedConversations"
    [quantityOfVisibleConversations]="quantityOfVisibleConversations"
  ></ssi-live-chat-next-chat>

  <ssi-live-chat-conversation-tree
    *ngIf="!! isLiveChatActive && !agent.isOffline && !! quantityOfVisibleConversations"
    (activeConversationChange)="onActiveConversationChange($event)"
    (conversationAssignmentUpdated)="toggleAssignmentToConversationForAgent($event)"
    [activeConversationId]="activeConversationId"
    [agent]="agent"
    [conversations]="activeViewOfConversations"
  ></ssi-live-chat-conversation-tree>
<!--
  <ssi-live-chat-push-mode-agent-encouragement
    *ngIf="isLiveChatActive && isPushModeEnabled"
    [agent]="agent"
  ></ssi-live-chat-push-mode-agent-encouragement> -->
</ng-container>
