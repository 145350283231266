import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetectedThemeResultsComponent } from './detected-theme-results.component';
import { InsightsPostsModule } from '../../common/components/insights-posts/insights-posts.module';
import { SpinnerModule } from '../../../../../common/components/spinner/spinner.module';

@NgModule({
  imports: [CommonModule, InsightsPostsModule, SpinnerModule],
  exports: [DetectedThemeResultsComponent],
  declarations: [DetectedThemeResultsComponent],
  entryComponents: [DetectedThemeResultsComponent]
})
export class DetectedThemeResultsModule {}
