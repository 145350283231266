import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';

import { DisapprovalsComponent } from './disapprovals.component';
import { DisapprovalsService } from './disapprovals.service';

import { BuildModule } from './build/build.module';
import { ReportModule } from './report/report.module';

@NgModule({
  declarations: [DisapprovalsComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.disapprovals',
          url: '/disapprovals',
          abstract: true, // will auto redirect to 'auth.analytics.marketing.build' child state
          component: DisapprovalsComponent
        }
      ]
    }),
    BuildModule,
    ReportModule
  ],
  providers: [DisapprovalsService],
  entryComponents: [DisapprovalsComponent]
})
export class DisapprovalsModule {}
