import { Injectable } from '@angular/core';
import {
  AccountModel,
  API,
  Account,
  SocialNetwork,
  AccountType,
  getSocialNetwork
} from '@ui-resources-angular';
import { AccountTypeId } from '../../enums';

export interface AuthProvider {
  id: number;
  name: AccountType;
  socialNetwork: SocialNetwork;
  isAdvertisingAccount: boolean;
}

export enum AuthSessionOptionType {
  Add = 'add',
  Reauth = 'reauth'
}

export interface AuthSessionOption {
  type: AuthSessionOptionType;
  profile: {
    id: string;
    name: string;
    username: string;
    picture: string;
  };
  account?: Account;
  link: string;
}

export interface AuthSessionOptionsResult {
  options: AuthSessionOption[];
  links?: {
    next: {
      page: string;
    };
  };
}

export class AccountAuthSession {
  constructor(
    private api: API,
    private accountModel: AccountModel,
    private sessionUUID: string,
    private provider: AuthProvider
  ) {}

  async getSessionOptions({
    page,
    type
  }: { page?: string; type?: AuthSessionOptionType } = {}): Promise<{
    options: AuthSessionOption[];
    nextPage?: string;
  }> {
    const { data } = await this.api.get<{ data: AuthSessionOptionsResult }>(
      'account/authSessionOptions',
      {
        params: {
          session_uuid: this.sessionUUID,
          page
        }
      }
    );
    return {
      options: data.options
        .filter((option) => !type || option.type === type)
        .map((option) => {
          // map to the real account
          if (option.type === AuthSessionOptionType.Reauth) {
            const {
              account: { id },
              ...rest
            } = option;
            const account = this.accountModel.get(String(id));
            return {
              account,
              link: this.provider.socialNetwork.account.getExternalUrl(account),
              ...rest
            };
          } else {
            const fakeAccount = new Account({
              social_id: option.profile.id,
              name: option.profile.name,
              username: option.profile.username
            });
            return {
              ...option,
              link: this.provider.socialNetwork.account.getExternalUrl(
                fakeAccount
              )
            };
          }
        }),
      nextPage: data.links ? data.links.next.page : undefined
    };
  }

  async addAccount(option: AuthSessionOption) {
    const {
      data: { id }
    } = await this.api.post<{
      data: { success: true; id: string };
    }>('account/authAddAccount', {
      profile_id: option.profile.id,
      session_uuid: this.sessionUUID
    });
    return { id };
  }

  async reauthAccount(option: AuthSessionOption) {
    await this.api.post<{ data: { success: true } }>(
      'account/authReAuthAccount',
      {
        account_id: option.account.id,
        session_uuid: this.sessionUUID
      }
    );
    // this is what the backend does when reauthing the account
    Object.assign(option.account, {
      is_reauth_advised: false,
      is_reauth_required: false,
      name: option.profile.name,
      username: option.profile.username,
      picture: option.profile.picture
    });
  }

  async clearSession() {
    await this.api.post<{ data: { success: true } }>(
      'account/authSessions',
      {},
      {
        params: {
          _method: 'DELETE',
          session_uuid: this.sessionUUID
        }
      }
    );
  }
}

export const OAUTH_REDIRECT_URI =
  'https://www.orlo.app/assets/v2/html/network-oauth-callback.html';

@Injectable() // tslint:disable-line max-classes-per-file
export class AccountAuthService {
  uuid: string;
  constructor(private api: API, private accountModel: AccountModel) {}

  async getProviders() {
    const { data: providers } = await this.api.get<{
      data: AuthProvider[];
    }>('account/authProviders');
    return providers
      .map((provider) => {
        const socialNetwork = getSocialNetwork(provider.name);
        return {
          ...provider,
          socialNetwork: getSocialNetwork(provider.name),
          isAdvertisingAccount:
            socialNetwork.advertising &&
            socialNetwork.advertising.accountTypeName === provider.name
        };
      })
      .filter((provider) => provider.id !== AccountTypeId.TikTok);
  }

  async createAuthenticatedSession(result, provider, uuid) {
    if (typeof result.queryString !== 'string') {
      throw new Error('No query string received from oauth popup!');
    }
    const params = new URLSearchParams(result.queryString);
    if (params.has('error')) {
      throw new Error(params.get('error_description') || params.get('error'));
    }
    const isOauth1 = params.has('oauth_token') && params.has('oauth_verifier');
    const isOauth2 = params.has('state') && params.has('code');
    if ((isOauth1 && isOauth2) || (!isOauth1 && !isOauth2)) {
      throw new Error('Missing or invalid oauth query params');
    }
    const dataForServer = isOauth1
      ? {
          oauth_token: params.get('oauth_token'),
          oauth_verifier: params.get('oauth_verifier')
        }
      : { state: params.get('state'), code: params.get('code') };
    await this.api.post<{ data: { success: true } }>(
      'account/authSessions',
      dataForServer,
      {
        params: {
          _method: 'PATCH',
          session_uuid: uuid
        }
      }
    );
    return new AccountAuthSession(this.api, this.accountModel, uuid, provider);
  }

  async fetchAuthenticatedSessionParams(id) {
    const { data } = await this.api.post<{
      data: { uuid: string; authorization_url: string };
    }>('account/authSessions', {
      account_type_id: id,
      redirect_uri: OAUTH_REDIRECT_URI
    });

    return data;
  }
}
