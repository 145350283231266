import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { SentimentAmendBoxComponent } from './sentiment-amend-box.component';
import { SentimentListModule } from '../sentiment-list/sentiment-list.module';

@NgModule({
  imports: [CommonModule, TooltipModule, SentimentListModule],
  declarations: [SentimentAmendBoxComponent],
  exports: [SentimentAmendBoxComponent]
})
export class SentimentAmendBoxModule {}
