<div
  *ngIf="!iframeUrl"
  class="person-details-container"
>
  <div class="person-key-value-list">
    <dl>
      <dt>Name</dt>
      <dd>{{ externalIntegrationPerson.name }}</dd>
      <dt *ngIf="externalIntegrationPerson.worksFor.name">Company</dt>
      <dd *ngIf="externalIntegrationPerson.worksFor.name">
        {{ externalIntegrationPerson.worksFor.name }}
      </dd>
    </dl>
    <ng-container *ngFor="let contact of externalIntegrationPerson.contactPoint">
      <dl *ngIf="contact.telephone || contact.email">
        <dt>{{ contact.telephone ? 'Telephone' : 'Email' }}</dt>
        <dd>
          <a *ngIf="contact.telephone" [href]="'tel:' + contact.telephone">{{ contact.telephone }}</a>
          <a *ngIf="contact.email" [href]="'mailto:' + contact.email">{{ contact.email }}</a>
        </dd>
      </dl>
    </ng-container>
    <dl *ngIf="externalIntegrationPerson.deal">
      <dt>Owner</dt>
      <dd>
        <a [href]="'mailto:' + externalIntegrationPerson.deal.owner.email">
          {{ externalIntegrationPerson.deal.owner.name }}
        </a>
      </dd>
      <dt>Stage</dt>
      <dd>{{ externalIntegrationPerson.deal.stage.name }}</dd>
    </dl>
  </div>
  <div
    *ngIf="externalIntegrationPerson['@reverse'].about"
    class="section-footer"
    (click)="showActivities()"
  >
    Activity
  </div>
</div>

<div *ngIf="!iframeLoaded && iframeUrl" class="loading"></div>

<iframe
  ngIf="iframeLoaded && iframeUrl"
  [src]="iframeUrl"
  ssiOnDomEventName="load"
  (onDomEvent)="iframeLoaded = true">
</iframe>

<div
  *ngIf="externalIntegrationPerson['@reverse'].about"
  class="person-activities-container"
  [ngClass]="{visible: activitiesShown}"
>
  <div class="person-activities">
    <div
      *ngFor="let activity of externalIntegrationPerson['@reverse'].about"
      class="person-activity"
    >
      <div class="person-activity-header">
        {{ activity.dateCreated | date:'d MMM yy HH:mm' }} | <a [href]="'mailto:' + activity.author.email">{{ activity.author.name }}</a>
      </div>
      <div class="person-activity-body">
        {{ activity.text }}
      </div>
    </div>
  </div>

  <div
    class="section-footer"
    (click)="hideActivities()">
    User details
  </div>

</div>
