import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  transform(input: string, find: string, replaceWith: string): any {
    return input.replace(new RegExp(find, 'g'), replaceWith);
  }
}
