import './sentiment-change.component.scss';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-sentiment-change',
  templateUrl: './sentiment-change.component.html',
  styles: []
})
export class SentimentChangeComponent {
  @Input() totalMessages: number;
  @Input() totalConversations: number;
  @Input() chartConfig;

  constructor() {}
}
