import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { CompanyService } from '../../../../../common/services/company/company.service';
import './company-lockdown.scss';
import { colleagues } from '../../../../common-resolves';
import { Colleague } from '../../../../../common/services/api';

export function companyConfigFn(company: CompanyService) {
  return company.getConfig();
}

@Component({
  selector: 'ssi-company-lockdown',
  templateUrl: './company-lockdown.component.html',
  styles: []
})
export class CompanyLockdownComponent implements OnInit {
  static resolve = [
    {
      token: 'companyConfig',
      resolveFn: companyConfigFn,
      deps: [CompanyService]
    },
    colleagues
  ];

  @Input() companyConfig;
  @Input() colleagues: Colleague[];

  companyLockdownForm: FormGroup;
  companyLockedBy: Colleague;

  constructor(private state: StateService, private company: CompanyService) {}

  ngOnInit() {
    this.companyLockdownForm = new FormGroup({});
    this.companyLockdownForm.addControl(
      'password',
      new FormControl('', Validators.pattern(/^lockdown$/i))
    );
    this.companyLockedBy = this.colleagues.find(
      (colleague) => this.companyConfig.locked_by === colleague.id
    );
  }

  lockdownCompany() {
    this.company.lock().then(() => {
      this.company.getConfig().then((config) => {
        this.companyConfig = config;
        this.companyLockedBy = this.colleagues.find(
          (colleague) => this.companyConfig.locked_by === colleague.id
        );
      });
    });
  }

  unlockCompany() {
    this.company.unlock().then(() => {
      this.company.getConfig().then((config) => (this.companyConfig = config));
    });
  }

  return() {
    this.state.go('^');
  }
}
