import './cl-dropdown-select-b.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import { Account, AccountModel } from '@ui-resources-angular';
import { groupBy } from '../../../../../common/utils';

@Component({
  selector: 'ssi-cl-dropdown-select-b',
  templateUrl: './cl-dropdown-select-b.component.html',
  styles: []
})
export class ClDropdownSelectBComponent implements OnInit {
  @Input() accounts: Account[] = [];
  selectedAccounts: Account[] = [];

  constructor() {}

  ngOnInit() {}

  selectedAccountsChanged(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
  }
}
