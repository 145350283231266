<div class="stat-change {{position}}"
     [ngClass]="{'positive': positive, 'negative': negative, 'no-change': change === 0, 'hidden': change === 0, 'boxed': boxed}">
   <i *ngIf="change > 0"
      class="ssi ssi-line-arrow-small-up"></i>
   <i *ngIf="change < 0"
      class="ssi ssi-line-arrow-small-down"></i>
   <i *ngIf="change === 0"
      class="ssi ssi-no-change"></i>
   <span *ngIf="!bigNumber">{{change}}</span>
   <ssi-big-number *ngIf="bigNumber"
                   [number]="change"></ssi-big-number>
   <span *ngIf="suffix">{{suffix}}</span>
</div>