import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineNoteComponent } from './inline-note.component';
import { TranslateModule } from '@ngx-translate/core';
import { Nl2brModule } from '../../../pipes/nl2br/nl2br.module';

@NgModule({
  imports: [CommonModule, TranslateModule, Nl2brModule],
  declarations: [InlineNoteComponent],
  exports: [InlineNoteComponent]
})
export class InlineNoteModule {}
