import './inbox-activity-modal.component.scss';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Activity, Conversation, User, UserModel } from '@ui-resources-angular';
import { RealtimeInboxHelperService } from '../../../services/realtime-inbox-helper/realtime-inbox-helper.service';
import { SocketsService } from '../../../services/sockets/sockets.service';

@Component({
  selector: 'ssi-inbox-activity-modal',
  templateUrl: './inbox-activity-modal.component.html',
  styles: []
})
export class InboxActivityModalComponent implements OnInit, OnDestroy {
  @Input() authUser: User;
  @Input() activity: Activity;
  @Input() conversation: Conversation;
  @Input() onConversationListUpdated: (opts?: any) => void;

  realtimeHelper: any;

  constructor(
    public activeModal: NgbActiveModal,
    protected userModel: UserModel,
    protected realtimeInboxHelperService: RealtimeInboxHelperService,
    protected socket: SocketsService
  ) {}

  async ngOnInit() {
    this.realtimeHelper = this.realtimeInboxHelperService.create({
      socket: this.socket.getSocketInstance(),
      authUser: this.authUser
    });
  }

  ngOnDestroy() {
    this.realtimeHelper.destroy();
  }

  onChangeActivity(activity: Activity): void {
    this.activity = activity;
  }
}
