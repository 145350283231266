import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AdvertisingBoostConfirmedComponent } from './advertising-boost-confirmed.component';
import { AdvertisingNavModule } from '../advertising-nav/advertising-nav.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.confirmed',
          url: '/confirmed',
          component: AdvertisingBoostConfirmedComponent
        }
      ]
    }),
    AdvertisingNavModule
  ],
  declarations: [AdvertisingBoostConfirmedComponent],
  exports: [AdvertisingBoostConfirmedComponent]
})
export class AdvertisingBoostConfirmedModule {}
