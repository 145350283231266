<div class="top-engagers-container">
  <div class="inbound-count">
    <ssi-medal-score
      [place]="1"
      color="#F0B427"
      variant="big"
    ></ssi-medal-score>
    <img
      class="avatar-img"
      [src]="topEngager.avatar"
      alt="Engager avatar"
    >
    <i class="{{topEngager.networkIcon}} network-icon"></i>
    <span class="text">{{topEngager.name}}</span>
    <span class="number big">{{topEngager.count}}<span
        class="number"
        translate="MESSAGES"
      ></span></span>
  </div>
  <div class="inbound-percentage">
    <div class="percentage-details">
      <h3>{{topUserInboundPercentage.toFixed(2)}}%</h3>
      <h4
        translate="THE_TOP__TOTALAUTHORS__PEOPLE_CREATED__PERCENTAGE__OF_THE_TOTAL_INBOUND_MESSAGES"
        [translateParams]="{totalAuthors: topEngagers.length, percentage: (topUserInboundPercentage.toFixed(2)) + '%'}"
      ></h4>
    </div>
    <i class="ssi ssi-followers-portion"></i>
  </div>
</div>

<div class="top-engagers-tables">
  <table>
    <thead>
      <th></th>
      <th translate="CUSTOMER"></th>
      <th translate="TOTAL_MESSAGES"></th>
    </thead>
    <tbody>
      <tr *ngFor="let user of topEngagers | slice: 0:10; let i = index">
        <td class="number">{{i + 1}}.</td>
        <td>
          <img
            class="avatar-img"
            [src]="user.avatar"
            ssiFallbackImage="avatarOutgoing"
            alt="Engager avatar"
          >
          <i class="{{user.networkIcon}} network-icon"></i>
          <span class="text">{{user.name}}</span><a
            (click)="viewProfile({accountId: user.account_id, profileId: user.author_id})"
            class="text"
          >@{{user.username}}</a>
          <i *ngIf="user.verified" class="verified ssi ssi-verified-icon"></i>
        </td>
        <td><span class="number">{{user.count}}</span></td>
      </tr>
    </tbody>
  </table>
  <table *ngIf="topEngagers.length > 10">
    <thead>
      <th></th>
      <th translate="CUSTOMER"></th>
      <th translate="TOTAL_MESSAGES"></th>
    </thead>
    <tbody>
      <tr *ngFor="let user of topEngagers | slice: 10; let i = index">
        <td class="number">{{i + 11}}.</td>
        <td>
          <img
            class="avatar-img"
            [src]="user.avatar"
            ssiFallbackImage="avatarOutgoing"
            alt="Engager avatar"
          >
          <i class="{{user.networkIcon}} network-icon"></i>
          <span class="text">{{user.name}}</span><a
            (click)="viewProfile({accountId: user.account_id, profileId: user.author_id})"
            class="text"
          >@{{user.username}}</a>
          <i *ngIf="user.verified" class="verified ssi ssi-verified-icon"></i>
        </td>
        <td><span class="number">{{user.count}}</span></td>
      </tr>
    </tbody>
  </table>
</div>