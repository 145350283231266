import {
  ActionConfig,
  staticParams,
  UIParam,
  UIParamFormType
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import {
  ServiceMapping,
  overlayDimensions,
  getParamByName,
  replaceTemplateIngredientsWithHtml
} from './util';
import { AppletActionParam } from '../applets/applet';
import { Injector } from '@angular/core';
import { IftttService } from '../ifttt-service-model/ifttt-service-model';

const actions: ActionConfig[] = [
  {
    api: {
      name: 'email external users'
    },
    translationIds: {
      title: 'NOTIFICATION_EMAIL_EXTERNALLY_ACTION_EMAIL_USER_TITLE',
      description: 'NOTIFICATION_EXTERNAL_EMAIL_ACTION_EMAIL_USER_DESCRIPTION'
    },
    iconClass: 'ssi ssi-envelope-forward',
    overlayDimensions: overlayDimensions.notification,
    getLabel(
      params: AppletActionParam[],
      injector: Injector,
      iftttService: IftttService
    ) {
      const email_address: string = getParamByName(params, 'email_addresses')
        .value;
      const subject: string = getParamByName(params, 'subject').value;

      const message = replaceTemplateIngredientsWithHtml(
        getParamByName(params, 'message').value,
        injector,
        iftttService
      );

      return `Email ${email_address} with the subject "${subject}" saying "${message}"`;
    },
    paramOverrides: {
      subject(): UIParam {
        return {
          form: {
            startColumn: true,
            type: UIParamFormType.TextInput,
            input: {
              placeholderTranslationKey: 'TYPE_YOUR_SUBJECT_LINE'
            }
          },
          serialise: staticParams.textarea().serialise,
          deserialise: staticParams.textarea().deserialise
        };
      }
    }
  }
];

const service: ServiceMapping = {
  id: 'notification.external_email',
  triggers: [],
  actions,
  ingredients: []
};

export default service;
