import './top-tags-list-box.component.scss';

import { Component, Input } from '@angular/core';

import { getHighestValue } from '../../../../../../common/util';

export interface TopTag {
  word: string;
  count: number;
}

@Component({
  selector: 'ssi-top-tags-list-box',
  templateUrl: './top-tags-list-box.component.html',
  styles: []
})
export class TopTagsListBoxComponent {
  @Input() competitor: any;
  @Input() tags: TopTag[] = [];
  @Input() slim: boolean = true;

  constructor() {}

  get topTagCount(): number {
    return getHighestValue(this.tags, 'count');
  }
}
