import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../store';
import { ApiService } from '../api.service';
import { Team } from './team.model';
import { ColleaguesService, Colleague } from '../colleagues';

@Injectable({ providedIn: 'root' })
export class TeamsService {
  store = new Store<Team>(Team);
  endpoint = `${this.api.url}/settings/groups`;

  constructor(
    protected api: ApiService,
    protected colleaguesService: ColleaguesService
  ) {}

  getAll(opts = { refreshStore: false }): Promise<Team[]> {
    if (this.store.value.length && !opts.refreshStore) {
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(this.endpoint)
      .pipe(
        map((response: any) => {
          this.store.value = response.groups;
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  createOrUpdate(team: Team): Promise<Team> {
    return this.api
      .post(this.endpoint, team)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  delete(id: string): Promise<{ success: boolean }> {
    return this.api
      .delete(this.endpoint, {
        params: { group_id: id }
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  async getAllActive(): Promise<Team[]> {
    const [teams, colleagues] = await Promise.all([
      this.getAll(),
      this.colleaguesService.getAll()
    ]);

    return teams.filter(
      (team) => this.colleaguesService.store.filterByTeam(team.id).length > 0
    );
  }
}
