import { Injectable } from '@angular/core';
import { AccountModel } from '@ui-resources-angular';
import { CountryFinderService } from '../../../../common/services/country-finder/country-finder.service';
import { ApiService } from '../../../../common/services/api';
import { ReportSpreadsheetTransformersService } from '../../../../common/services/report-spreadsheet-transformers/report-spreadsheet-transformers.service';
import { WorkflowManagerService } from '../../../../common/services/workflow-manager/workflow-manager.service';
import { catchError, map } from 'rxjs/operators';

export async function workflowAccountsResolveFn(
  accountModel: AccountModel,
  workflowManager: WorkflowManagerService
) {
  const accounts = await accountModel.findAccounts(
    workflowManager.getCurrentId()
  );
  return accounts;
}

export interface BotMetrics {
  metrics: {
    session_count: number;
    handover_percent: number;
    deflection_percent: number;
    feedback_unresolved_percent: number;
    feedback_resolved_percent: number;
    feedback_none_percent: number;
  };
  session_start_times: {
    [key: string]: number;
  };
  handover_times: {
    [key: string]: number;
  };
  non_handover_times: {
    [key: string]: number;
  };
  top_urls: {
    [key: string]: {
      count: number;
      percent: number;
    };
  };
  intent_usage: {
    [key: string]: {
      count: number;
      percent: number;
    };
  };
  intent_handover_usage: {
    [key: string]: {
      count: number;
      percent: number;
    };
  };
  feedback: {
    no_feedback_given: {
      [key: string]: number;
    };
    resolved: {
      [key: string]: number;
    };
    unresolved: {
      [key: string]: number;
    };
  };
  intent_net_feedback: {
    [key: string]: number;
  };
  intent_usage_over_time: {
    [key: string]: {
      [date: string]: number;
    };
  };
  names: {
    [key: string]: string;
  };
}

export interface FilterPeriod {
  start: string;
  end: string;
}

@Injectable()
export class ChatbotAnalyticsService {
  constructor(
    private api: ApiService,
    private countryFinder: CountryFinderService,
    private reportSpreadsheetTransformers: ReportSpreadsheetTransformersService
  ) {}

  async getBotMetrics(botId: string, filterPeriod: FilterPeriod) {
    // GET /chatbot/botMetrics?bot_id={bot.id}&since={datetime}&until={datetime}&tz_offset={tz offset milliseconds}
    // https://github.com/orlo/orlo/blob/master/chatbot/api.md#bot-metics

    const endpoint = `${this.api.url}/chatbot/botMetrics`;
    const reportParams = {
      bot_id: botId,
      since: filterPeriod.start,
      until: filterPeriod.end
    };

    return this.api
      .get(endpoint, {
        params: reportParams
      })
      .pipe(
        map((response: BotMetrics) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  /**
   * Create the Spreadsheet in frontend to be downloaded
   * @param report report data to build the report with
   * @param chatbot chatbot data to be used on the spreadsheet
   * @param filterPeriod filter date to be shown in the spreadsheet
   */
  createSpreadsheetFromReport(
    filterPeriod: FilterPeriod,
    report: any,
    chatbot: any
  ) {
    return [
      () => {
        return {
          name: 'Summary',
          rows: [
            ['Chatbot - ' + chatbot.name],
            [],

            ['Session count', filterPeriod.start],
            ['Report end', filterPeriod.end],
            ['Chatbot sessions', report.metrics.session_count],
            ['Handover rate', report.metrics.handover_percent.toFixed(2) + '%'],
            [
              'Deflection rate',
              report.metrics.deflection_percent.toFixed(2) + '%'
            ],
            [
              'Resolved feedback',
              report.metrics.feedback_resolved_percent.toFixed(2) + '%'
            ],
            [
              'Unresolved feedback',
              report.metrics.feedback_unresolved_percent.toFixed(2) + '%'
            ],
            [
              'No feedback',
              report.metrics.feedback_none_percent.toFixed(2) + '%'
            ]
          ]
        };
      },
      () => {
        return {
          name: 'Sessions by date',
          rows: [
            ['Chatbot sessions by date'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              { header: 'Sessions', data: report.session_start_times }
            ])
          ]
        };
      },
      () => {
        return {
          name: 'Handover by date',
          rows: [
            ['Sessions'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              { header: 'Handed Over', data: report.handover_times },
              { header: 'Not Handed Over', data: report.non_handover_times }
            ])
          ]
        };
      },
      () => {
        return {
          name: 'Feedback - count',
          rows: [
            ['Sessions'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              { header: 'Resolved', data: report.feedback.resolved },
              { header: 'Unresolved', data: report.feedback.unresolved },
              { header: 'No feedback', data: report.feedback.no_feedback_given }
            ])
          ]
        };
      },
      () => {
        const topUrls = (report && report.topUrls) || [
          { url: 'N/A', visits: 'N/A' }
        ];
        return {
          name: 'Top URLs prompting Chatbot',
          rows: [
            ['Chatbot report - Top URLs prompting Chatbot'],
            [],
            ['URL', 'Total visits'],
            ...topUrls.map((item) => [item.url, item.visits])
          ]
        };
      },
      () => {
        const topicUsage = (report && report.topicUsage) || [
          { name: 'N/A', count: 'N/A', percent: 'N/A' }
        ];
        return {
          name: 'Topic Usage',
          rows: [
            ['Chatbot report - Topic Usage'],
            [],
            ['Topic', 'Count', 'Percent'],
            ...topicUsage.map((item) => [item.name, item.count, item.percent])
          ]
        };
      },
      () => {
        const topTopics = (report && report.topTopics) || [
          { name: 'N/A', count: 'N/A' }
        ];
        return {
          name: 'Top 10 Topics',
          rows: [
            ['Chatbot report - Top 10 Topics'],
            [],
            ['Topic', 'Count'],
            ...topTopics.map((item) => [
              item.name,
              Object.values(item.count)[0]
            ])
          ]
        };
      },
      () => {
        const topicFeedback = (report && report.topicFeedback) || [
          { name: 'N/A', score: 'N/A' }
        ];
        return {
          name: 'Topic Feedback',
          rows: [
            ['Chatbot report - Topic Feedback'],
            [],
            ['Topic', 'Score'],
            ...topicFeedback.map((item) => [item.name, item.score])
          ]
        };
      }
    ].map((fn) => fn());
  }
}
