import './inline-add-note.component.scss';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-inline-add-note',
  templateUrl: './inline-add-note.component.html',
  styles: []
})
export class InlineAddNoteComponent {
  @Input() isPublic: boolean;
  @Input() showGuide = false;
  @Input() inverse = false;
  @Output() addNote = new EventEmitter();
  @Output() cancelNote = new EventEmitter();

  constructor() {}
}
