<div class="settings-row grey">
  <div class="setting-element" *ngFor="let setting of settingsIndex.mySettings">
    <a uiSref="{{setting.link}}" *ngIf="checkFlag(setting.flag) && checkPermission(setting.permission)">
      <orlo-dialog [width]="250" [height]="210" [header]="setting.header" [text]="setting.text" [isLink]="true" [centered]="true" [iconClass]="setting.icon" [mode]="'light'"></orlo-dialog>
    </a>
  </div>
</div>
<div class="settings-row m-grey">
  <h1>Account Settings</h1>
  <div class="setting-element" *ngFor="let setting of settingsIndex.accountSettings">
    <a uiSref="{{setting.link}}" *ngIf="checkFlag(setting.flag) && checkPermission(setting.permission)">
      <orlo-dialog [width]="250" [height]="210" [header]="setting.header" [text]="setting.text" [isLink]="true" [centered]="true" [iconClass]="setting.icon" [mode]="'light'"></orlo-dialog>
    </a>
  </div>
</div>
<div class="settings-row d-grey">
  <h1>Company Settings</h1>
  <div class="setting-element" *ngFor="let setting of settingsIndex.companySettings">
    <a uiSref="{{setting.link}}" *ngIf="checkFlag(setting.flag) && checkPermission(setting.permission)">
      <orlo-dialog [width]="250" [height]="210" [header]="setting.header" [text]="setting.text" [isLink]="true" [centered]="true" [iconClass]="setting.icon" [mode]="'light'"></orlo-dialog>
    </a>
  </div>
</div>