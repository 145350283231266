import { Inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { User, UserModel } from '@ui-resources-angular';
import { appInjector } from '../../../../../angular/app-injector';
import { Store } from '../store';
import { ApiService } from '../api.service';
import {
  AutomatedReport,
  ReportAutomationFrequency
} from './automated-report.model';

@Injectable({
  providedIn: 'root'
})
export class AutomatedReportsService {
  store = new Store<AutomatedReport>(AutomatedReport);
  endpoint = `${this.api.url}/company/scheduledReport`;

  constructor(protected api: ApiService) {}

  getAll(opts = { refreshStore: false }): Promise<AutomatedReport[]> {
    if (this.store.value.length && !opts.refreshStore) {
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(this.endpoint)
      .pipe(
        map((response: any[]) => {
          this.store.value = response;
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  async create(
    name: string,
    url: string,
    recipients: string[],
    frequency: ReportAutomationFrequency
  ): Promise<AutomatedReport> {
    const authUser = await appInjector().get(UserModel).getAuthUser();

    const reportToCreate: Partial<AutomatedReport> = {
      name,
      url,
      recipients,
      frequency
    };

    return this.api
      .post(this.endpoint, reportToCreate)
      .pipe(
        map((response: { success: boolean; id: string }) => {
          reportToCreate.id = response.id;
          reportToCreate.created_by = authUser.id;

          this.store.add(reportToCreate);
          return this.store.find(reportToCreate.id);
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  update(changes: Partial<AutomatedReport>): Promise<AutomatedReport> {
    const payload = changes;
    const opts = {
      params: {}
    };

    return this.api
      .put(this.endpoint, payload, opts)
      .pipe(
        map((response: { success: boolean }) => {
          this.store.update(changes);
          return this.store.find(changes.id);
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  delete(report: AutomatedReport): Promise<{ success: boolean }> {
    const opts = {
      params: {
        id: report.id
      }
    };

    return this.api
      .delete(this.endpoint, opts)
      .pipe(
        map((response: any) => {
          this.store.remove(report.id);
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }
}
