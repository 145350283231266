<div class="youtube-post-video" *ngIf="files.length === 1 && files[0].type === 'video'">
  <video controls>
    <source [src]="files[0].url" type="video/mp4">
    Sorry but we could not load the video preview.
  </video>
</div>

<div class="youtube-post-header">
  <h3 class="youtube-post-title">{{ files[0].video.title }}</h3>
  <div class="youtube-post-views">0 views</div>
</div>

<div class="youtube-post-body d-flex">
  <div class="youtube-post-author-avatar">
    <img [src]="account.picture" class="img-circle" ssiFallbackImage="avatar">
  </div>
  <div class="flex-1">
    <div class="youtube-post-author-name">
      <a target="_blank" [href]="account.externalUrl">{{ account.name }}</a>
    </div>
    <div class="youtube-post-time">Published just now</div>
    <div class="youtube-post-description">
      <span *ngIf="isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br | truncate: limit"></span>
      <span *ngIf="!isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br"></span>
      <span class="text-read-more" *ngIf="isTruncated" (click)="isTruncated = false; limit = text.length">Read More</span>
    </div>
  </div>
</div>
