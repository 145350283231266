<div class="inbox-activity-modal">
   <i class="ssi ssi-delete-close g-btn g-btn-secondary round-icon-only-button close-icon"
      (click)="activeModal.close()"></i>

   <ssi-inbox-activity [activity]="activity"
                       [conversation]="conversation"
                       [realtimeHelper]="realtimeHelper"
                       [hideArrowShortcuts]="true"
                       [authUser]="authUser"
                       [userCanCreateNewActivityTags]="true"
                       (onClose)="activeModal.close()"
                       (onChangeActivity)="onChangeActivity($event.activity)"
                       (onExitConversation)="activeModal.close()"
                       (onExitActivity)="activeModal.close()"
                       (onReloadResults)="onConversationListUpdated()"
                       (onResolveConversation)="onConversationListUpdated($event)"
                       (onHoldConversationStatusChanged)="onConversationListUpdated($event)"></ssi-inbox-activity>
</div>