import { Component, Input } from '@angular/core';
import { Account, API } from '@ui-resources-angular';
import { CompanyService } from '../../../../common/services/company/company.service';
import './competitor-analysis.component.scss';

export async function competitorsConfigFn(
  api: API
): Promise<CompetitorsConfigResponse> {
  return api.get('competitiveAnalysis/config').then(({ data }) => data);
}

export async function reportsFn(api: API, company: CompanyService) {
  const competitorAnalysisEnabled = await company.hasFeatureAccess(
    'COMPETITIVE_ANALYSIS'
  );
  return competitorAnalysisEnabled
    ? api.get('competitiveAnalysis/report').then(({ data }) => data.reports)
    : new Promise((resolve, reject) => resolve([]));
}

export async function competitorsFn(api: API) {
  return api
    .get('competitiveAnalysis/profileGroup')
    .then(({ data }) => data.profile_groups);
}

export interface CompetitorsConfigResponse {
  max_brands: number;
  max_competitors: number;
}

export enum ProfileGroupType {
  Competitor = 'COMPETITOR',
  Brand = 'BRAND'
}

export interface Competitor {
  name: string;
  colour: string;
  type: ProfileGroupType.Competitor | ProfileGroupType.Brand;
  profiles: {
    account_type_id: number;
    social_id: string;
    username: string;
    name?: string;
    avatar_url?: string;
  }[];
  id?: string | number;
}

export interface Brand extends Competitor {
  type: ProfileGroupType.Brand;
}

export interface CompetitorProfile {
  account_type_id: number;
  social_id: string;
  username: string;
  name?: string;
  avatar_url?: string;
  audience_change?: any;
  audience_count?: any;
  count_messages?: number;
  posts_per_day?: number;
  sum_interactions?: number;
  sum_likes?: number;
  sum_shares?: number;
  top_media_type?: {
    image?: number;
    text?: number;
    video?: number;
  };
  top_posts?: any[];
  top_tags?: { [key: string]: number };
  top_words?: { [key: string]: number };
  total_follower_count?: number;
}

export interface ProfileGroup {
  id?: number;
  name: string;
  type: ProfileGroupType.Competitor | ProfileGroupType.Brand;
  colour: string;
  profiles: CompetitorProfile[];
  displayName?: string;
  socialScore?: number;
}

export interface Report {
  name: string;
  description: string;
  profile_group_ids: number[];
  id?: string;
  created_at?: string;
  focus_id?: number;
}

@Component({
  selector: 'ssi-competitor-analysis',
  templateUrl: './competitor-analysis.component.html',
  styles: []
})
export class CompetitorAnalysisComponent {
  @Input() competitorsConfig: CompetitorsConfigResponse;
  @Input() reports: Report[];
  @Input() profileGroups: ProfileGroup[];
  @Input() workflowAccounts: Account[];
  constructor() {}
}
