import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReauthAccountsComponent } from './reauth-accounts.component';
import { AccountAuthService } from '../../../../../common/services/account-auth/account-auth.service';
import { ServicesModule } from '../../../../../common/services/services.module';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    ServicesModule,
    TranslateModule,
    OrloComponentsModule,
    TooltipModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.reAuthAccount',
          url: '/accounts/reauth',
          component: ReauthAccountsComponent,
          resolve: ReauthAccountsComponent.resolve,
          data: {
            permissions: {
              company: 'administer_accounts'
            }
          }
        }
      ]
    })
  ],
  declarations: [ReauthAccountsComponent],
  providers: [AccountAuthService],
  entryComponents: [ReauthAccountsComponent]
})
export class ReauthAccountsModule {}
