import './schedule-posts.component.scss';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Account } from '@ui-resources-angular';
import { AccordionComponent } from '../../accordion/accordion.component';

@Component({
  selector: 'ssi-schedule-posts',
  templateUrl: './schedule-posts.component.html'
})
export class SchedulePostsComponent implements OnInit {
  @Input() scheduledPostTime: Date | undefined;
  @Input() error: string | undefined;
  @Input() accounts: Account[] = [];
  @Input() fullWidth: boolean;

  showPostScheduler: boolean;
  scheduledDate: Date;

  @Output() onScheduledPostTimesUpdate = new EventEmitter<Date>();
  @Output() onScheduledPostTimesDeleted = new EventEmitter();

  @ViewChild('schedulePostsAccordion')
  schedulePostsAccordion: AccordionComponent;

  constructor() {}

  ngOnInit() {}

  scheduledPostTimesUpdated(newDate: Date) {
    this.onScheduledPostTimesUpdate.emit(newDate);
  }
}
