import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';

const serverCacheKey = Symbol('server cache');

export interface Currency {
  name: string;
  code: string;
  symbol: string;
}

@Injectable()
export class ServerService {
  constructor(private api: API) {}

  getSettings(): Promise<{ [key: string]: any }> {
    if (!this[serverCacheKey]) {
      this[serverCacheKey] = this.api.get('server/index');
    }
    return this[serverCacheKey].then(({ data }) => {
      return Object.assign({}, data);
    });
  }
}
