<div class="dashboard-widgets-grid-wrapper">
  <div class="grid-wrapper-inner"
       [class.grid-locked]="viewMode">
    <div #gridContainer
         class="grid-container"
         [class.filters-bar-visible]="filtersBarVisible">
      <ssi-ktd-grid #grid
                    [cols]="cols"
                    [rowHeight]="rowHeight"
                    [layout]="widgets"
                    [scrollableParent]="gridContainer"
                    (layoutUpdated)="onLayoutUpdated($event)">
        <ssi-ktd-grid-item *ngFor="let widget of widgets; let index = index; trackBy: trackById"
                           [id]="widget.id"
                           [draggable]="!viewMode"
                           [resizable]="!viewMode"
                           [typeConst]="widgetTypes[widget.apiData.type]">
          <div class="widget-wrapper"
               [class.grid-locked]="viewMode">
            <div class="widget-wrapper-inner">
              <div class="widget-actions">
                <span class="title">{{widget.apiData.title}}</span>
                <div class="widget-actions-right">
                  <div class="meta">
                    <span *ngIf="widget.apiData?.activeFilters?.length"
                          class="widget-meta widget-meta-filter"
                          ssiTooltip
                          [tooltipTemplate]="tooltipFiltersTemplate"
                          [tooltipTemplateContext]="{ filters: widget.apiData.activeFilters }"
                          tooltipPlacement="bottom"
                          tooltipWindowClass="tooltip-chunky-basic">
                      <span class="widget-filter-badge">
                        <span class="widget-filter-badge-meta">
                          {{widget.apiData.activeFilters.length}}
                        </span>
                        <i class="ssi ssi-filter-trimmed"></i>
                      </span>
                    </span>
                  </div>
                  <div class="actions">
                    <i class="ssi ssi-correct-edit-small"
                       (click)="onEditWidget.emit(widget)"></i>
                    <!-- <i class="ssi ssi-copy-paste-small"></i>
                    <i class="ssi ssi-small-add-circle"></i> -->
                    <i class="ssi ssi-small-delete delete-icon"
                       (click)="deleteWidget(widget, index)"></i>
                  </div>
                </div>
              </div>
              <div class="widget-container"
                   ssiLoader
                   ssiLoaderVariant="white"
                   ssiLoaderText="Loading your search results..."
                   [ssiLoaderVisible]="!loaders[index]">

                <ssi-widget-inbox-message *ngIf="widget.apiData.type === widgetTypes.inboxMessageMode.key"
                                          [widget]="widget.apiData"
                                          (loaded)="loaders[index] = true"></ssi-widget-inbox-message>

                <ssi-widget-inbox-conversations *ngIf="widget.apiData.type === widgetTypes.inboxConversationMode.key"
                                                [widget]="widget.apiData"
                                                (loaded)="loaders[index] = true"></ssi-widget-inbox-conversations>

                <ssi-widget-live-x-stream *ngIf="widget.apiData.type === widgetTypes.liveXStream.key"
                                          [widget]="widget.apiData"
                                          [widgetSize]="{h: widget.h, w: widget.w}"
                                          (loaded)="loaders[index] = true"></ssi-widget-live-x-stream>

                <ssi-widget-rss-feeds *ngIf="widget.apiData.type === widgetTypes.rssFeeds.key"
                                      [widget]="widget.apiData"
                                      (loaded)="loaders[index] = true"></ssi-widget-rss-feeds>

                <ssi-widget-marketing-posts *ngIf="widget.apiData.type === widgetTypes.marketingPosts.key"
                                            [widget]="widget.apiData"
                                            (loaded)="loaders[index] = true"></ssi-widget-marketing-posts>

                <ssi-widget-monitoring-stream *ngIf="widget.apiData.type === widgetTypes.monitoringStream.key"
                                              [widget]="widget.apiData"
                                              (loaded)="loaders[index] = true"></ssi-widget-monitoring-stream>

                <ssi-widget-social-wall *ngIf="widget.apiData.type === widgetTypes.socialWall.key"
                                        [widget]="widget.apiData"
                                        [currentDashboard]="currentDashboard"
                                        (loaded)="loaders[index] = true"></ssi-widget-social-wall>

                <ssi-widget-profile-search *ngIf="widget.apiData.type === widgetTypes.profileSearch.key"
                                           [widget]="widget.apiData"
                                           [currentDashboard]="currentDashboard"
                                           (loaded)="loaders[index] = true"></ssi-widget-profile-search>

              </div>
            </div>
          </div>
        </ssi-ktd-grid-item>
      </ssi-ktd-grid>
    </div>
  </div>
</div>

<ng-template #tooltipFiltersTemplate
             let-filters="filters">
  <div class="tooltip-widget-filters-content">
    <h4>Widget Filters</h4>
    <ul>
      <li *ngFor="let filter of filters; let last = last">
        <div>{{filter.title}}</div>
      </li>
    </ul>
  </div>
  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template>