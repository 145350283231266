import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { CompanySettingsComponent } from './company-settings.component';

import { OrloComponentsModule } from 'orlo-components';
import { CompanyLockdownModule } from './company-lockdown/company-lockdown.module';
import { PublishSettingsModule } from './publish-settings/publish-settings.module';
import { InternationalizationModule } from './internationalization/internationalization.module';
import { ManageWebhooksModule } from './manage-webhooks/manage-webhooks.module';
import { ManageTeamModule } from './manage-team/manage.team.module';
import { ManageTeamsModule } from './manage-teams/manage-teams.module';
import { ManageUserPermissionsModule } from './manage-user-permissions/manage-user-permissions.module';
import { OfficeHoursModule } from './office-hours/office-hours.module';
import { ManageUsersModule } from './manage-users/manage-users.module';
import { AuthenticationSettingsModule } from './authentication-settings/authentication-settings.module';
import { InboxSettingsModule } from './inbox-settings/inbox-settings.module';
import { SocialPushModeModule } from './social-push-mode/social-push-mode.module';
import { ManageAppsModule } from './manage-apps/manage-apps.module';
import { SurveySettingsModule } from './survey-settings/survey-settings.module';
import { ChatBotModule } from './chat-bot/chat-bot.module';
import { EnableSocialChatbotsModule } from './enable-social-chatbots/enable-social-chatbots.module';
import { AutomatedReportsModule } from './automated-reports/automated-reports.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings',
          url: '/company-settings',
          component: CompanySettingsComponent,
          resolve: CompanySettingsComponent.resolve
        }
      ]
    }),
    CompanyLockdownModule,
    PublishSettingsModule,
    InternationalizationModule,
    ManageWebhooksModule,
    ManageTeamModule,
    ManageTeamsModule,
    ManageUserPermissionsModule,
    OfficeHoursModule,
    ManageUsersModule,
    AuthenticationSettingsModule,
    InboxSettingsModule,
    SocialPushModeModule,
    ManageAppsModule,
    SurveySettingsModule,
    ChatBotModule,
    EnableSocialChatbotsModule,
    AutomatedReportsModule
  ],
  declarations: [CompanySettingsComponent],
  entryComponents: [CompanySettingsComponent]
})
export class CompanySettingsModule {}
