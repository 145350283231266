<div class="note-container">
  <p [innerHTML]="note.content"></p>

  <div class="note-meta-and-actions">
    <span (click)="deleteNote.emit()"
          class="delete-icon-container">
      <i class="ssi ssi-delete"></i>
    </span>

    <span>{{note.created_at | date:'HH:mm'}}</span>
    <span> | </span>
    <span>{{note.created_at | date:'d MMM yyyy'}}</span>
    <span> | </span>
    <span>By {{createdByYou? 'You' : note.author.fullName}}</span>
  </div>
</div>