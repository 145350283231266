<div class="company-lockdown">
    <div class="flex-grid container">
      <div class="col full header">
        <h2>Company Lockdown</h2>
        <p translate="USE_THIS_TO_IMMEDIATELY_STOP_ALL_SOCIAL_ACTIVITY_THROUGH_ORLO"></p>
      </div>
      <div class="col">
        <div class="info-list">
          <p>Once Activated:</p>
          <ul>
            <li><i class="ssi ssi-tick"></i><span translate="ALL_USERS_WILL_BE_LOGGED_OUT"></span></li>
            <li><i class="ssi ssi-tick"></i><span translate="YOUR_SCHEDULED_CONTENT_WILL_NOT_BE_PUBLISHED"></span></li>
            <li><i class="ssi ssi-tick"></i><span translate="ONLY_ADMINS_CAN_LOGIN_TO_DEACTIVATE_THE_LOCKDOWN"></span></li>
            <li><i class="ssi ssi-tick"></i><span translate="YOUR_MONITORING_AND_INBOUND_MESSAGES_WILL_NOT_BE_AFFECTED"></span></li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="form" *ngIf="!companyConfig.locked_by">
          <form (ngSubmit)="lockdownCompany()" [formGroup]="companyLockdownForm">
            <label translate="TO_ACTIVATE_COMPANY_LOCKDOWN_PLEASE_TYPE_THE_SECURITY_PHRASE_LOCKDOWN_BELOW"></label>
            <orlo-text-input [width]="'300'" [name]="'password'" [parentForm]="companyLockdownForm" [type]="'text'" [required]="true" [placeholder]="'Enter security phrase...'" [invalid]="companyLockdownForm.controls.password?.touched && companyLockdownForm.controls.password?.invalid ? true : false"></orlo-text-input>
            <div class="form-submit">
              <orlo-button [text]="'Confirm'" [state]="'primary'" [type]="'Submit'" [disabled]="companyLockdownForm?.invalid"></orlo-button>
            </div>
          </form>
        </div>
        <div class="unlock" *ngIf="companyConfig.locked_by">
            <p>The company has been locked down by {{companyLockedBy.first_name}}</p>
            <orlo-button [text]="'Unlock'" [state]="'primary'" (btnClick)="unlockCompany()" ></orlo-button>
        </div>
      </div>
    </div>
  </div>
  