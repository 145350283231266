import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import './spinner.component.scss';

@Component({
  selector: 'ssi-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {
  @Input() size: number;
  @Input() visible = false;
  @Input() borderColor: string;
  @Input() borderTopColor: string;
  @Input() variant: 'blue' | 'white' = 'blue';

  constructor() {}

  ngOnInit() {}

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  isVisible() {
    return this.visible;
  }
}
