<div class="notes-modal modal-header">
  <button
    type="button"
    class="btn btn-default pull-right"
    (click)="modalInstance.close()">
    <span translate="CLOSE"></span> <i class="fa fa-times"></i>
  </button>
  <h4 class="modal-title">View Notes</h4>
</div>
<div class="modal-body notes-modal-body">
  <ssi-notes [validation]="validation" [notes]="notes" [user]="user" [teams]="teams" [colleagues]="colleagues" (updateNotes)="updateNotes($event)"></ssi-notes>
</div>