import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialNetworkSelectComponent } from './social-network-select.component';

@NgModule({
  imports: [CommonModule],
  exports: [SocialNetworkSelectComponent],
  declarations: [SocialNetworkSelectComponent]
})
export class SocialNetworkSelectModule {}
