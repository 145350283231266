<div class="publish-settings">
  <ssi-alerts></ssi-alerts>

  <div class="flex-grid container">
    <form
      [formGroup]="publishSettingsForm"
      *ngIf="publishSettingsForm"
    >
      <div class="upper-section">
        <div class="upper-section-item">
          <div class="rounded-container">
            <label class="field-title">UTM Link Tracking</label>
            <p class="description">This setting allows you to disable UTM parameters from being applied
              automatically to shortened links.
            </p>
            <ssi-toggle-switch
              parentNgForm="publishSettingsForm"
              formControlName="use_utm_link_tracking"
              [disabled]="!companyConfig.use_link_shortening && !companyConfig.use_utm_link_tracking"
              toggleText="UTM Link Tracking {{ publishSettingsForm.controls.use_utm_link_tracking.value ? 'On' : 'Off'}}"
            >
            </ssi-toggle-switch>
          </div>
        </div>
        <div class="upper-section-item">
          <div class="rounded-container">
            <label class="field-title">Content Library</label>
            <p class="description">Have complete control over what media goes out with your content, by restricting
              media
              selection to your Content Library.
            </p>
            <ssi-toggle-switch
              parentNgForm="publishSettingsForm"
              formControlName="only_use_managed_files"
              toggleText="Media uploads from Content Library only"
            >
            </ssi-toggle-switch>
          </div>
        </div>
        <div class="upper-section-item">
          <div class="rounded-container">
            <label class="field-title">Link Shortening</label>
            <p class="description">Turn off link shortening to stop this being available by default for all accounts,
              company wide.
            </p>
            <ssi-toggle-switch
              parentNgForm="publishSettingsForm"
              formControlName="use_link_shortening"
              toggleText="Link Shortening {{ publishSettingsForm.controls.use_link_shortening.value ? 'On' : 'Off'}}"
            >
            </ssi-toggle-switch>
          </div>
        </div>
        <div class="upper-section-item">
          <div class="rounded-container">
            <label class="field-title">Alt Text Required</label>
            <p class="description">Turning this on will require all uploaded images to contain valid alt text before
              submitting the post.
            </p>
            <ssi-toggle-switch
              parentNgForm="publishSettingsForm"
              formControlName="alt_text_required"
              toggleText="Alt Text Required {{ publishSettingsForm.controls?.alt_text_required?.value ? 'On' : 'Off'}}"
            >
            </ssi-toggle-switch>
          </div>
        </div>
      </div>
      <div class="rounded-container">
        <div class="vanity-input">
          <div class="input-label-desc">
            <label class="field-title">Vanity URL</label>
            <p class="description">Add your own custom vanity URL. You must first setup your domain name to point to our
              servers. After
              entering
              the new URL use our button to check that it's working. <a
                href="https://support.orlo.tech/vanity-urls"
                target="_blank"
              >More info here</a>
            </p>
          </div>
          <div class="d-flex align-items-center full-width">
            <input
              type="text"
              placeholder="http://"
              class="orlo-text-input no-right-radius"
              (keydown.enter)="addVanityDomain()"
              [(ngModel)]="newVanityUrl"
              [ngModelOptions]="{standalone: true}"
            >
            <button
              [disabled]="!newVanityUrl"
              class="orlo-input-submit"
              [class.active]="newVanityUrl"
              (click)="addVanityDomain()"
              type="button"
            >
              <i class="ssi ssi-plus"></i>
              Install new URL
            </button>
          </div>
        </div>
        <div class="domains-list-table">
          <table class="orlo-table">
            <thead>
              <tr>
                <th>Domain</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              <tr
                *ngFor="let domain of vanityDomains"
                class="has-delete"
              >
                <td>
                  <strong>{{domain.domain}}</strong>
                </td>
                <ng-template #deleteDomainTooltipTemplate>
                  <ssi-confirm-action
                    #deleteDomainTooltipTemplate
                    (ssiOffClick)="deleteDomainTooltip.hide()"
                    title="Are you sure you want to delete this domain?"
                    dismissText="Cancel"
                    confirmText="Delete"
                    (onDismiss)="deleteDomainTooltip.hide()"
                    (onConfirm)="deleteVanityDomain(domain)"
                  >
                  </ssi-confirm-action>
                </ng-template>

                <td class="controls">
                  <ssi-checkbox
                    parentNgForm="publishSettingsForm"
                    formControlName="shortening_url"
                    [workWithValue]="domain.domain"
                    (onChecked)="changeDefaultVanityDomain(domain)"
                    checkboxText="Set as default"
                    [checked]="domain.domain === publishSettingsForm.controls.shortening_url.value"
                  >
                  </ssi-checkbox>
                  <div class="text-right">
                    <a
                      (click)="deleteDomainTooltip.show()"
                      #deleteDomainTooltip="ssiTooltip"
                      [ssiTooltip]
                      tooltipPlacement="right"
                      tooltipTrigger="manual"
                      [tooltipTemplate]="deleteDomainTooltipTemplate"
                    ><i class="ssi ssi-delete visible"></i></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="rounded-container">
        <div class="post-tags">
          <div class="input-label-desc">
            <label class="field-title">Post Tags</label>
            <p class="description">Create a set of tags that can be applied to posts.
            </p>
          </div>
          <div class="d-flex align-items-center add-tag">
            <input
              type="text"
              placeholder="Enter tag"
              class="orlo-text-input no-right-radius"
              (keydown.enter)="addPostTag()"
              [(ngModel)]="newPostTag"
              [ngModelOptions]="{standalone: true}"
            >
            <button
              [disabled]="!newPostTag"
              class="orlo-input-submit"
              [class.active]="newPostTag"
              (click)="addPostTag()"
              type="button"
            >
              <i class="ssi ssi-plus"></i>
              Add Tag
            </button>
          </div>
          <div>
            <ul class="clearfix">
              <li *ngFor="let tag of postTags">
                <ng-template #deletePostTagTooltipTemplate>
                  <ssi-confirm-action
                    #deletePostTagTooltipTemplate
                    (ssiOffClick)="deletePostTagTooltip.hide()"
                    title="Are you sure you want to delete this tag?"
                    dismissText="Cancel"
                    confirmText="Delete"
                    (onDismiss)="deletePostTagTooltip.hide()"
                    (onConfirm)="removePostTag(tag); deletePostTagTooltip.hide()"
                  >
                  </ssi-confirm-action>
                </ng-template>
                {{tag.name}}
                <i
                  class="ssi ssi-times"
                  (click)="deletePostTagTooltip.show()"
                  #deletePostTagTooltip="ssiTooltip"
                  [ssiTooltip]
                  tooltipPlacement="right"
                  tooltipTrigger="manual"
                  [tooltipTemplate]="deletePostTagTooltipTemplate"
                ></i>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="rounded-container" *ngIf="hasValidationTagging">
        <div class="disapproval-tags">
          <div class="input-label-desc">
            <label
              class="field-title"
              translate="DISAPPROVED_POST_TAGS"
            ></label>
            <p
              class="description"
              translate="CREATE_TAGS_FOR_WHEN_DISAPPROVING_POSTS_IN_THE_APPROVAL_PROCESS"
            >
            </p>
          </div>
          <div class="d-flex align-items-center add-tag">
            <input
              type="text"
              placeholder="Enter tag"
              class="orlo-text-input no-right-radius"
              (keydown.enter)="addDisapprovalTag()"
              [(ngModel)]="newDisapprovalTag"
              [ngModelOptions]="{standalone: true}"
            >
            <button
              [disabled]="!newDisapprovalTag"
              class="orlo-input-submit"
              [class.active]="newDisapprovalTag"
              (click)="addDisapprovalTag()"
              type="button"
            >
              <i class="ssi ssi-plus"></i>
              Add Tag
            </button>
          </div>
          <div>
            <ul class="clearfix">
              <li *ngFor="let tag of disapprovalTags">
                <ng-template #deletePostTagTooltipTemplate>
                  <ssi-confirm-action
                    #deletePostTagTooltipTemplate
                    (ssiOffClick)="deletePostTagTooltip.hide()"
                    title="Are you sure you want to delete this tag?"
                    dismissText="Cancel"
                    confirmText="Delete"
                    (onDismiss)="deletePostTagTooltip.hide()"
                    (onConfirm)="removeDisapprovalTag(tag); deletePostTagTooltip.hide()"
                  >
                  </ssi-confirm-action>
                </ng-template>
                {{tag.name}}
                <i
                  class="ssi ssi-times"
                  (click)="deletePostTagTooltip.show()"
                  #deletePostTagTooltip="ssiTooltip"
                  [ssiTooltip]
                  tooltipPlacement="right"
                  tooltipTrigger="manual"
                  [tooltipTemplate]="deletePostTagTooltipTemplate"
                ></i>
              </li>
            </ul>
          </div>
        </div>
      </div>



      <!-- <div class="domains-list-table">
          <table class="orlo-table">
            <thead>
              <tr>
                <th>Domain</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              <tr
                *ngFor="let domain of vanityDomains"
                class="has-delete"
              >
                <td>
                  <strong>{{domain.domain}}</strong>
                </td>
                <ng-template #deleteDomainTooltipTemplate>
                  <ssi-confirm-action #deleteDomainTooltipTemplate
                    (ssiOffClick)="deleteDomainTooltip.hide()"
                    title="Are you sure you want to delete this domain?"
                    dismissText="Cancel"
                    confirmText="Delete"
                    (onDismiss)="deleteDomainTooltip.hide()"
                    (onConfirm)="deleteVanityDomain(domain)"
                  >
                  </ssi-confirm-action>
                </ng-template>

                <td class="controls">
                  <ssi-checkbox
                    parentNgForm="publishSettingsForm"
                    formControlName="shortening_url"
                    [workWithValue]="domain.domain"
                    (onChecked)="changeDefaultVanityDomain(domain)"
                    checkboxText="Set as default"
                    [checked]="domain.domain === publishSettingsForm.controls.shortening_url.value"
                  >
                  </ssi-checkbox>
                  <div class="text-right">
                    <a
                      (click)="deleteDomainTooltip.show()"
                      #deleteDomainTooltip="ssiTooltip"
                      [ssiTooltip]
                      tooltipPlacement="right"
                      tooltipTrigger="manual"
                      [tooltipTemplate]="deleteDomainTooltipTemplate"
                    ><i class="ssi ssi-delete visible"></i></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
    </form>
  </div>
</div>