import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FlatpickrModule } from 'angularx-flatpickr';

import { ViewReportComponent } from './view-report.component';

import { InsightsNavbarModule } from '../../common/components/insights-navbar/insights-navbar.module';
import { InsightsPostsModule } from '../../common/components/insights-posts/insights-posts.module';
import { WidgetsGridModule } from '../../common/components/widgets-grid/widgets-grid.module';
import { ReportActionBarModule } from '../../common/components/report-action-bar/report-action-bar.module';
import { ReportFilterBarModule } from '../../common/components/report-filter-bar/report-filter-bar.module';
import { GlobalFiltersModule } from '../../common/components/global-filters/global-filters.module';
import { SaveReportModalModule } from '../../common/components/save-report-modal/save-report-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights.reports.view',
          url: '/view/:id?mode?template&disablePopup&frequency',
          component: ViewReportComponent,
          resolve: ViewReportComponent.resolve,
          params: {
            template: {
              value: 'false',
              dynamic: false
            },
            mode: {
              value: 'graph',
              dynamic: true
            }
          }
        }
      ]
    }),
    TranslateModule,
    FlatpickrModule,
    InsightsNavbarModule,
    ReportActionBarModule,
    ReportFilterBarModule,
    GlobalFiltersModule,
    InsightsPostsModule,
    WidgetsGridModule,
    SaveReportModalModule
  ],
  exports: [],
  declarations: [ViewReportComponent]
})
export class ViewReportModule {}
