import './publisher-schedule-date-picker.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CalendarMonthViewDay } from 'angular-calendar/modules/month';
import { isSameDay, endOfDay, isPast, startOfDay } from 'date-fns';
import { Subject } from 'rxjs/Subject';
import { OutboxPublisher, UserModel, Campaign } from '@ui-resources-angular';
import { OutboxQueryFactoryService } from '../../../../services/outbox-query-factory/outbox-query-factory.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyValueObject } from '../../../../util';

interface MonthDayMeta {
  isDisabled?: boolean;
  isScheduled?: boolean;
  scheduledCount?: number;
}

type MonthDay = CalendarMonthViewDay<MonthDayMeta>;

function markDisabled(day: MonthDay) {
  day.cssClass = 'disabled';
  day.meta = {
    isDisabled: true
  };
}

function markScheduled(day: MonthDay, scheduledCount: number) {
  day.cssClass = 'scheduled';
  day.meta = {
    isScheduled: true,
    scheduledCount
  };
}

const CLICK_DELAY = 300;

@Component({
  selector: 'ssi-publisher-schedule-date-picker',
  templateUrl: './publisher-schedule-date-picker.component.html',
  providers: [DatePipe]
})
export class PublisherScheduleDatePickerComponent implements OnInit {
  @Input() post: OutboxPublisher;

  @Input() campaigns: Campaign[] = [];

  @Output() dateSelected = new EventEmitter<Date>();

  @ViewChild('outboxPostListModalTemplate')
  outboxPostListModalTemplate: TemplateRef<any>;

  calendarDate = new Date();

  selectedDate: Date;

  refreshCalendar = new Subject();

  outboxQuery;

  monthViewTotals: KeyValueObject<KeyValueObject<number>> = {};

  preventDayClick = false;

  pendingClickHandler;

  constructor(
    private outboxQueryFactory: OutboxQueryFactoryService,
    private userModel: UserModel,
    private datePipe: DatePipe,
    private modal: NgbModal
  ) {}

  async ngOnInit() {
    this.outboxQuery = this.outboxQueryFactory.create({
      allAccounts: this.post.accounts,
      authUser: await this.userModel.getAuthUser(),
      savedQuery: false
    });
    this.monthChanged();
  }

  dayClicked(day: MonthDay) {
    // horrible hack to get double clicks to work, as you cant anticipate when the user will double click
    if (!this.pendingClickHandler) {
      this.pendingClickHandler = setTimeout(() => {
        this.pendingClickHandler = undefined;
        if (this.preventDayClick) {
          this.preventDayClick = false;
        } else if (!day.meta || !day.meta.isDisabled) {
          if (isSameDay(this.selectedDate, day.date)) {
            this.selectedDate = null;
          } else {
            this.selectedDate = day.date;
          }
          this.refreshCalendar.next();
        }
      }, CLICK_DELAY);
    }
  }

  async dayDoubleClicked(day: MonthDay) {
    if (day.meta && day.meta.isScheduled) {
      this.preventDayClick = true;
      this.modal.open(this.outboxPostListModalTemplate);
      this.outboxQuery.apiQuery.start_date = startOfDay(day.date);
      this.outboxQuery.apiQuery.end_date = endOfDay(day.date);
      await this.outboxQuery.search();
    }
  }

  addCssClasses(days: MonthDay[]) {
    days.forEach((day) => {
      if (isPast(endOfDay(day.date))) {
        markDisabled(day);
      } else if (isSameDay(day.date, this.selectedDate)) {
        day.cssClass = 'active';
      } else {
        const monthViewTotalKey = this.datePipe.transform(day.date, 'y-MM-dd');
        if (this.monthViewTotals[monthViewTotalKey]) {
          const total = Object.values(
            this.monthViewTotals[monthViewTotalKey]
          ).reduce((a, b) => a + b, 0);
          markScheduled(day, total);
        }
      }
    });
  }

  async monthChanged() {
    this.monthViewTotals = {}; // prevent screen flicker
    this.monthViewTotals = await this.outboxQuery.getCalendarMonthTotals(
      this.calendarDate
    );
    this.refreshCalendar.next();
  }

  calendarSelectToday() {
    this.calendarDate = new Date();
    this.monthChanged();
  }
}
