<div class="close-modal">
  <button
  type="button"
  class="btn btn-default pull-right close close-modal"
  (click)="modalInstance.close()">
  <i class="ssi ssi-times"></i>
  </button>
</div>
<div class="modal-body post-modal">
  <div class="post-wrap" [ngClass]="{'post-wrap-expanded': postExpanded}">
    <div class="btn btn-default post-action-expand" (click)="postExpanded = !postExpanded">
      <i *ngIf="!postExpanded" class="ssi ssi-correct-expand"></i>
      <i *ngIf="postExpanded" class="ssi ssi-minimize"></i>
    </div>
    <ssi-post
      [post]="post"
      [colleagues]="colleagues"
      [postType]="'post'"
      (deletePost)="deletePost()"
      (closeModal)="closeModal()">
    </ssi-post>
    <div class="post-stats">
      <div
        *ngIf="!postExpanded && post.account?.account_type_id !== '6'"
        class="stat"
        tooltipTitle="Post score is calculated using this formula:"
        [ssiTooltip]="'(Comments x 4) + (Shares x 3) + (Clicks x 2) + Likes'"
        [tooltipAppendToBody]="true">
        <div class="stat stat-primary">
          <div class="stat-score">{{post.score}}</div>
          <p class="stat-label">Post Score</p>
        </div>
      </div>
      <div *ngIf="!postExpanded && post.account?.account_type_id !== '6'" class="stat">
        <div class="average-score-wrap">
          <svg class="average-score" viewBox="0 0 120 120">
            <defs>
              <lineargradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#00bc9b" />
                <stop offset="100%" stop-color="#5eaefd" />
              </lineargradient>
            </defs>
            <circle cx="60" cy="60" r="50" class="average-score-progress" />
            <circle cx="60" cy="60" r="50" stroke="url(#gradient)" stroke-width="6" fill="none" class="average-score-progressbar" />
            <text x="60" y="78" class="average-score-meta">{{averageScore}}</text>
          </svg>
          <!-- <div class="average-score-circle">
            <p class="average-score-">{{averageScore}}</p>
          </div> -->
          <p class="stat-label">Score Average</p>
        </div>
      </div>
      <div class="stat">
        <div [ngClass]="{'note-trigger-active': notesActive}" class="note-trigger" (click)="toggleNotes()">
          <span class="note-label" *ngIf="notes?.length" [innerHTML]="notes?.length > 9 ? '9+' : notes?.length"></span>
          <i class="ssi ssi-post-notes-view"></i>
          <button class="stat-label stat-label-button">
            {{notesActive ? 'Close post notes' : '+Post notes'}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="notes" *ngIf="notesActive">
    <ssi-notes [validation]="post" [disablePosting]="disablePostNotes" [notes]="notes" [user]="user" [teams]="teams" [colleagues]="colleagues" (updateNotes)="updateNotes($event)"></ssi-notes>
  </div>
  <div class="row">
    <div class="post-stats section">
      <div class="stat" *ngIf="postExpanded && post.account?.account_type_id !== '6'">
        <div class="stat-score">{{post.score}}</div>
        <p class="stat-label">Score</p>
      </div>
      <div class="stat" *ngIf="postExpanded && post.account?.account_type_id !== '6'">
        <div class="average-score-wrap">
          <svg class="average-score" viewBox="0 0 120 120">
            <defs>
              <lineargradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#00bc9b" />
                <stop offset="100%" stop-color="#5eaefd" />
              </lineargradient>
            </defs>
            <circle cx="60" cy="60" r="50" class="average-score-progress" />
            <circle cx="60" cy="60" r="50" stroke="url(#gradient1)" stroke-width="6" fill="none" class="average-score-progressbar" />
            <text x="60" y="78" class="average-score-meta">{{averageScore}}</text>
          </svg>
          <p class="stat-label">Score Average</p>
        </div>
      </div>
      <ng-container *ngIf="['3','9'].includes(post.account?.account_type_id) && post.account?.socialNetwork.statistics.reach.outbox">
        <div
          class="stat"
          [ssiTooltip]="'Reach is the number of people who have seen your content on their screen'"
          [tooltipAppendToBody]="true">
          <div class="stat-score">{{post.reach}}</div>
          <p class="stat-label">Organic Reach</p>
        </div>
      </ng-container>
      <ng-container *ngIf="['2', '3','4','8','9'].includes(post.account?.account_type_id)">
        <div
          class="stat"
          [ssiTooltip]="'Impressions is the number of times your content has been displayed on someone\'s screen'"
          [tooltipAppendToBody]="true">
          <div class="stat-score">{{post.impressions}}</div>
          <p class="stat-label">Organic Impressions</p>
        </div>
      </ng-container>
      <ng-container *ngIf="!['2', '3','4','8','9'].includes(post.account?.account_type_id) && post.account?.socialNetwork.statistics.reach.outbox">
        <div
          class="stat"
          [ssiTooltip]="'Potential Reach is the total number of users who could have seen your content'"
          [tooltipAppendToBody]="true">
          <div class="stat-score">{{post.reach}}</div>
          <p class="stat-label">Reach</p>
        </div>
        <div class="stat">
          <div
            class="stat-score"
            [ssiTooltip]="post.type === OutboxMessageType.InstagramReel ? 'Currently unavailable due to network API restrictions' : ''"
            [tooltipAppendToBody]="true"
          >{{post.type === OutboxMessageType.InstagramReel ? 'N/A' : post.impressions}}</div>
          <p class="stat-label">Impressions</p>
        </div>
      </ng-container>
      <div
        class="stat"
        [ssiTooltip]="post.type === OutboxMessageType.InstagramReel ? 'Currently unavailable due to network API restrictions' : 'This is the total engagements on a post divided by the total number of impressions multiplied by 100. Engagements may include Reactions, likes, clicks, comments and shares, depending on the network
              We are unable to provide this figure when more than 5000 messages have been selected.'"
        [tooltipAppendToBody]="true"
      >
        <div class="stat-score">{{post.type === OutboxMessageType.InstagramReel ? 'N/A' : post.engagement_rate}}</div>
        <p class="stat-label">Engagement rate</p>
      </div>
      <div class="stat">
        <div class="stat-score">{{post.clicks}}</div>
        <p class="stat-label">Clicks</p>
      </div>
      <div class="stat">
        <div class="stat-score">{{post.comment_count}}</div>
        <p class="stat-label">Comments</p>
      </div>
      <div class="stat">
        <div class="stat-score">{{post.like_count}}</div>
        <p class="stat-label">Likes</p>
      </div>
      <div
        class="stat"
        [ssiTooltip]="post.type === OutboxMessageType.InstagramReel ? 'Currently unavailable due to network API restrictions' : ''"
        [tooltipAppendToBody]="true"
      >
        <div class="stat-score">{{post.type === OutboxMessageType.InstagramReel ? 'N/A' : post.share_count}}</div>
        <p class="stat-label">Shares</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="post-ads" [ngClass]="{
      'post-ads-paused': activeAdvert?.external_status === 'PAUSED',
      'post-ads-live': activeAdvert?.external_status === 'ACTIVE',
      'post-ads-scheduled': activeAdvert?.external_status === null}"
      *ngIf="adverts.length > 0">
      <ul class="post-ads-nav clearfix">
        <li *ngFor="let advert of adverts"
            class="post-ad"
            [ngClass]="{active: advert.id === activeAdvert?.id,
                        'post-ad-paused': advert.id === activeAdvert?.id && activeAdvert?.external_status === 'PAUSED',
                        'post-ad-live': advert.id === activeAdvert?.id && activeAdvert?.external_status === 'ACTIVE',
                        'post-ad-scheduled': advert.id === activeAdvert?.id && activeAdvert?.external_status === null}"
            (click)="setActiveAdvert(advert)">
            <span *ngIf="advert.id === activeAdvert?.id">
              <i *ngIf="['ACTIVE', 'UNKNOWN'].includes(advert.external_status)" class="ssi ssi-live"></i>
              <i *ngIf="advert.external_status === null" class="ssi ssi-mini-schedule"></i>
              <i *ngIf="advert.external_status === 'PAUSED'" class="fa fa-pause-circle"></i>
              <i *ngIf="['COMPLETE', 'DELETED'].includes(advert.external_status)" class="ssi ssi-tick"></i>
            </span> {{advert.name}}
        </li>
      </ul>
      <div class="post-stats" *ngIf="activeAdvert?.external_status !== null"
           [ngClass]="{
             'post-stats-paused': activeAdvert?.external_status === 'PAUSED',
             'post-stats-live': activeAdvert?.external_status === 'ACTIVE',
             'post-stats-scheduled': activeAdvert?.external_status === null}">
        <div class="stat">
          <div class="stat-score">{{activeAdvert?.statistics.impressions_paid}}</div>
          <p class="stat-label">Impressions</p>
        </div>
        <div class="stat">
          <div class="stat-score">{{activeAdvert?.statistics.clicks_paid}}</div>
          <p class="stat-label">Clicks</p>
        </div>
        <div class="stat">
          <div class="stat-score">{{activeAdvert?.statistics.engagements_paid}}</div>
          <p class="stat-label">Engagement</p>
        </div>
        <div class="stat">
          <div class="stat-score">{{activeAdvert?.statistics.total_spend}}</div>
          <p class="stat-label">Total spend</p>
        </div>
      </div>
      <div class="post-ads-info">
        <div class="ad-status-color">
          <span class="post-ads-info-lowercase">
            {{activeAdvert?.external_status === 'ACTIVE' ? 'Live ' : ''}}
            {{activeAdvert?.external_status === null ? 'Scheduled ' : ''}}
            {{['ACTIVE', null].includes(activeAdvert?.external_status) ? '' : activeAdvert?.external_status.toLowerCase() + ''}}
            Ad
          </span>
        </div>
        <div *ngIf="activeAdvert?.budget.daily_budget">
          Daily budget:  £{{activeAdvert.budget.daily_budget.amount}}
        </div>
        <div *ngIf="activeAdvert?.budget.total_budget">
          Total Budget:  £{{activeAdvert.budget.total_budget.amount}}
        </div>
        <div>
          Start Date:  {{formatDate(activeAdvert?.schedule.start)}}
        </div>
        <div *ngIf="activeAdvert?.schedule.end">
          End Date:  {{formatDate(activeAdvert?.schedule.end)}}
        </div>
      </div>
      <div class="post-ads-action">
        <button class="btn btn-default" (click)="toggleAdNotes()"><i class="ssi ssi-feather"></i> Add notes</button>
        <!-- <button class="btn btn-default" (click)="DeleteAd()"><i class="ssi ssi-delete"></i> Delete Ad</button> -->
        <!-- <button class="btn btn-default" (click)="editAd()"><i class="ssi ssi-edit"></i> Edit Ad</button> -->
        <button *ngIf="['PAUSED', 'ACTIVE'].includes(activeAdvert?.external_status)" class="btn btn-default" (click)="toggleAdStatus()">
          <i class="ssi ssi-pause"></i>
          <span *ngIf="activeAdvert?.external_status !== 'PAUSED'"> Pause Ad</span>
          <span *ngIf="activeAdvert?.external_status === 'PAUSED'"> Unpause Ad</span>
        </button>
      </div>
      <div *ngIf="adNotesActive">
        <div class="notes">
          <ssi-notes [validation]="activeAdvert" [theme]="'ad'" [disablePosting]="disableAdNotes" [notes]="adNotes" [user]="user" [teams]="teams" [colleagues]="colleagues" (updateNotes)="updateAdNotes($event)"></ssi-notes>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row manage"
    *ngIf="canManagePost()"
  >
    <button
      class="btn btn-primary btn-rounded btn-padding-sides disapprove"
      (click)="disapprovePost()"
    >
      Disapprove post
    </button>
    <button
      class="btn btn-primary btn-rounded btn-padding-sides approve"
      (click)="approvePost()"
    >
      Approve post
    </button>
  </div>
  <div
    class="overlay-confirmation {{postAction}}"
    *ngIf="showConfirmation"
  >
    <i
      class="ssi ssi-times"
      (click)="showConfirmation = !showConfirmation"
    >
    </i>
    <h1>
      You have {{postAction}} this post!
    </h1>
  </div>
</div>
