<div class="disapprovals-analytics-report-container">
  <ssi-report-header
    report="Post Approvals Report"
    [name]=""
    [dateRangesTemplate]="dateRangesRef"
    (dateSelected)="reportDateChanged($event)"
  ></ssi-report-header>
  <ssi-report-sidebar
    icon="ssi-marketing-analytics"
    color="#D41D68"
    [navList]="navItems"
    [actionList]="reportActions"
    [active]="activeNavItemTag"
    (navClicked)="scrollTo($event)"
  ></ssi-report-sidebar>

  <div
    class="report-body-loading"
    *ngIf="loading"
  >
    <div class="loading-container">
      <!-- <i class="ssi ssi-confetti"></i> -->
      <img src="https://www.orlo.app/app/web/production/f44ceeab6551db9be89255d952d86c0d.gif">
      <h3>We're building your report!</h3>
      <p>This may take a few moments
      </p>
    </div>
  </div>

  <div
    class="report-body"
    *ngIf="!loading"
  >

    <section
      id="summary"
      class="section-container section-container-summary"
    >
      <h2 class="section-title">Summary</h2>
      <div class="facts-container">
        <ssi-fact-overview
          label="Total Approvals Requested"
          width="100%"
          [number]="
            totalsStats.allTotals.totals.count_approved +
            totalsStats.allTotals.totals.count_rejected +
            totalsStats.allTotals.totals.count_currently_require_validation
          "
          iconClass="ssi-total-approvals-requested"
          iconColor="#89A4EA"
          circleColor="#F3F6FD"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="Total Approved"
          width="100%"
          [number]="totalsStats.allTotals.totals.count_approved"
          iconClass="ssi-total-approved"
          iconColor="#12ACA4"
          circleColor="#E7F7F6"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="Total Disapproved"
          width="100%"
          [number]="totalsStats.allTotals.count"
          iconClass="ssi-total-disapproved"
          iconColor="#F88C68"
          circleColor="#FFF1ED"
        ></ssi-fact-overview>
        <ssi-fact-overview
          label="Disapproval Percentage"
          width="100%"
          [number]="
            (totalsStats.allTotals.count /
            (totalsStats.allTotals.totals.count_rejected +
            totalsStats.allTotals.totals.count_approved +
            totalsStats.allTotals.totals.count_currently_require_validation)
            * 100) | number: '1.0-2'
          "
          symbol="%"
          iconClass="ssi-percentage-disapproved-risk"
          iconColor="#E34916"
          circleColor="#FCEDE8"
        ></ssi-fact-overview>
        <ssi-fact-overview
          *ngFor="let tag of totalsStats.validationsTagsTotals; let index = index"
          [label]="tag.name"
          [width]="'100%'"
          [number]="tag.count"
          iconClass="ssi-tag-test"
          [iconColor]="'rgb(' + rgbColors[index] + ')'"
          [circleColor]="'rgba(' + rgbColors[index] + ', 0.1)'"
        >
        </ssi-fact-overview>
      </div>
    </section>

    <div class="section-delimiter"></div>

    <section
      id="network-summary"
      class="section-container section-container-network-summary"
    >
      <h2 class="section-title">Network Summary</h2>
      <div class="summaries-per-account-container">
        <article
          class="social-network"
          *ngFor="let network of totalsByAccountTypeIterable"
        >
          <div class="social-network-left">
            <div class="social-network-thumb">
              <i [ngClass]="network.account.socialNetwork.icon.web"></i>
            </div>
          </div>
          <div class="social-network-right">
            <p
              class="social-network-title"
              [innerHTML]="network.account.accountTypeLabel"
            ></p>
            <p class="social-network-meta">{{network?.totalAccounts}} {{network?.totalAccounts?.length === '1' ? 'Account' : 'Accounts'}}</p>
            <ul class="social-network-stats">
              <li class="social-network-stat">
                <p
                  class="social-network-stat-data"
                  [innerHTML]="network.disapprovals || '0'"
                ></p>
                <p class="social-network-stat-label">Disapprovals</p>
              </li>
            </ul>
          </div>
        </article>


      </div>
    </section>
    <div class="section-delimiter"></div>


    <section
      id="breakdown"
      class="section-container section-container-breakdown"
    >
      <ssi-breakdown
        [accounts]="accounts"
        [stats]="totalsStats"
      ></ssi-breakdown>
    </section>

    <div class="section-delimiter"></div>
  </div>

  <ng-template #dateRangesRef>
    <div class="selected-date-ranges">
      <span class="current">{{dateRanges.current.start | date: 'dd MMM yy'}} - {{dateRanges.current.end | date: 'dd MMM
        yy'}}</span>
    </div>
  </ng-template>
</div>