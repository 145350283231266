import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ClConfirmationModalComponent } from './cl-confirmation-modal.component';

import { ConfirmationModalModule } from '../../../../common/components/confirmation-modal/confirmation-modal.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { Checkbox2Module } from '../../../../common/components/checkbox-2/checkbox-2.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ConfirmationModalModule,
    SocialNetworkIconModule,
    Checkbox2Module
  ],
  exports: [ClConfirmationModalComponent],
  declarations: [ClConfirmationModalComponent]
})
export class ClConfirmationModalModule {}
