import './ai-writing-tool-modal.component.scss';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { mapToIterable } from '../../../../../common/utils';
import { AiWritingToolService } from './ai-writing-tool.service';
import { SpinnerComponent } from '../../../spinner/spinner.component';

export const postToneOptions: any = {
  casual: {
    key: 'informal',
    icon: 'ssi ssi-casual',
    label: 'Casual'
  },
  formal: {
    key: 'formal',
    icon: 'ssi ssi-formal',
    label: 'Formal'
  }
};

export const postToneOptionsIterable: any[] = mapToIterable(postToneOptions);

@Component({
  selector: 'ssi-ai-writing-tool-modal',
  templateUrl: './ai-writing-tool-modal.component.html'
})
export class AiWritingToolModalComponent implements OnInit {
  @Input() actionButtonText: string = 'Yes, use this!';
  @Input() topicText: string;
  @Input() twitterSelected: boolean = false;
  @Input() title: string = 'Generate Post';
  @Input() titleIconClass: string = 'ssi ssi-ai-writing-tool';
  @Input() variant: 'generate' | 'rephrase' | 'hashtags';

  @ViewChild('suggestionsSpinner') suggestionsSpinner: SpinnerComponent;

  selectedTone: 'formal' | 'informal' = 'formal';
  suggestedText: string;
  searching = false;
  suggestionRequested = false;
  rateLimit: {
    usage: number;
    limit: number;
  } = {
    usage: null,
    limit: null
  };

  postToneOptionsIterable: any[] = postToneOptionsIterable;

  variants = {
    generate: {
      title: 'GENERATE_POST',
      titleIconClass: 'ssi ssi-ai-writing-tool',
      actionButtonText: 'Yes, use this!',
      cancelButtonText: 'No, nevermind'
    },
    rephrase: {
      title: 'REPHRASE_POST',
      titleIconClass: 'ssi ssi-rephrase',
      actionButtonText: 'Yes, use this!',
      cancelButtonText: 'No, nevermind'
    },
    hashtags: {
      title: 'POPULATE_HASHTAGS',
      titleIconClass: 'ssi ssi-populate-hashtags',
      actionButtonText: 'Add to post',
      cancelButtonText: 'Cancel'
    }
  };

  constructor(
    public activeModal: NgbActiveModal,
    private writingToolService: AiWritingToolService
  ) {}

  async ngOnInit() {
    await this.checkUsageAndLimits();
    if (this.rateLimit.usage >= this.rateLimit.limit) {
      return;
    }
    console.log('variant:', this.variant);
    if (this.variant === 'rephrase') {
      this.generate();
    }
    if (this.variant === 'hashtags') {
      this.generateHashtags();
    }
    console.log('twitterSelected:', this.twitterSelected);
  }

  async checkUsageAndLimits() {
    this.rateLimit = await this.writingToolService.getUsageAndLimits();
    console.log('this.rateLimit:', this.rateLimit);
  }

  async generate() {
    try {
      const reqParams = {
        text: this.topicText,
        tone: this.selectedTone,
        length: this.twitterSelected ? 'short' : 'long'
      };
      this.suggestedText = null;
      this.searching = true;
      this.suggestionRequested = true;
      const res = await this.writingToolService.getParaphrase(reqParams);
      this.rateLimit.usage += 1;
      this.suggestedText = res;
      this.searching = false;
    } catch (error) {
      this.searching = false;
      console.error('Error getting the passphrase:', error);
    }
  }

  async generateHashtags() {
    try {
      const reqParams = {
        text: this.topicText,
        tone: this.selectedTone
      };
      this.suggestedText = null;
      this.searching = true;
      this.suggestionRequested = true;
      const res = await this.writingToolService.getHashtags(reqParams);
      this.suggestedText = res;
      this.searching = false;
    } catch (error) {
      this.searching = false;
      console.error('Error getting the hashtags:', error);
    }
  }
}
