import './inbox-activity.component.scss';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-engagement-inbox-activity',
  templateUrl: './inbox-activity.component.html',
  styles: []
})
export class InboxActivityComponent implements OnInit {
  @Input() messagesChart;
  @Input() activityStats;

  constructor() {}

  ngOnInit(): void {}
}
