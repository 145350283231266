import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ValidateRepliesFiltersComponent } from './validate-replies-filters.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, UIRouterModule, FormsModule],
  declarations: [ValidateRepliesFiltersComponent],
  exports: [ValidateRepliesFiltersComponent]
})
export class ValidateRepliesFiltersModule {}
