<div class="emoji-dropdown-container"
     *ngIf="menuCtrl"
     [style.top]="menuCtrl.position.top + 'px'"
     [style.left]="menuCtrl.position.left + 'px'">

    <ul *ngIf="!menuCtrl.template && choices.length"
        class="emoji-dropdown">
        <li *ngFor="let choice of choices"
            [class.active]="choice === activeChoice"
            (mouseup)="selectChoice(choice)">
            <span>{{choice.label}}</span>
        </li>
    </ul>

    <ng-container *ngIf="menuCtrl.template">
        <ng-container *ngTemplateOutlet="menuCtrl.template; context: menuCtrl.context"></ng-container>

        <!-- Usage example -->
        <!-- <ssi-emoji-autocomplete [textInputElement]="textareaRef"
                                     [menuTemplate]="menuTemplateRef"></ssi-emoji-autocomplete>

        <ng-template #menuTemplateRef
                     let-choices="choices"
                     let-selectChoice="selectChoice">
            <ul *ngIf="choices.length"
                class="emoji-dropdown">
                <li *ngFor="let choice of choices"
                    [class.active]="choice === activeChoice">
                    <a class="text-primary"
                       (click)="selectChoice(choice)">
                        <span>X {{choice.label}}</span>
                    </a>
                </li>
            </ul>
        </ng-template> -->
    </ng-container>
</div>