<div class="modal-header">
  <div class="row">
    <h3 class="col-sm-12 no-wrap" translate="PRIVATE_MESSAGE"></h3>
  </div>
</div>
<div class="modal-body">
  <div class="loading" *ngIf="loadingTracker.active"></div>
  <div *ngIf="!loadingTracker.active">
    <div *ngIf="!result">
      <div class="row">
        <div class="col-sm-2">
          <img [src]="resolve.profile.info.picture" class="img-circle profile-large" />
        </div>
        <div class="col-sm-10">
          <h4>
            {{ resolve.profile.info.name }}
            <small class="pull-right"><i class="fa fa-lock"></i> <span translate="PRIVATE"></span></small>
            <small *ngIf="resolve.profile.account.account_type_name === 'Twitter'">
              <br>@{{ resolve.profile.info.username }}
            </small>
          </h4>
        </div>
      </div>
      <hr/>
      <div class="form-group">
        <textarea [(ngModel)]="message" placeholder="{{ 'TYPE_YOUR_MESSAGE_HERE' | translate }}" class="form-control" rows="4"></textarea>
      </div>
    </div>
  </div>
  <div *ngIf="result && !loadingTracker.active">
    <h3>
      <i class="fa fa-check"></i>
      <span translate="YOUR_MESSAGE_HAS_BEEN_SENT_TO_BE_CHECKED_BY_A_VALIDATOR" *ngIf="!result.is_validated"></span>
      <span translate="YOUR_MESSAGE_HAS_BEEN_QUEUED_TO_BE_SENT" *ngIf="result.is_validated"></span>
    </h3>
    <div class="text-center">
      <button class="btn btn-primary" (click)="activeModal ? activeModal.close() : close()">
        <i class="fa fa-times"></i> <span translate="CLOSE"></span>
      </button>
    </div>
  </div>
</div>
<div class="modal-footer text-right">
  <button
    class="btn btn-success"
    (click)="send()"
    [disabled]="!message"
    *ngIf="!result && !loadingTracker.active">
    <i class="fa fa-envelope"></i> <span translate="SEND"></span>
  </button>
  <button class="btn btn-default" (click)="activeModal ? activeModal.dismiss() : dismiss()">
    <i class="fa fa-times"></i> <span translate="CANCEL"></span>
  </button>
</div>
