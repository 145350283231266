import './validate-posts-modal.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-validate-posts-modal',
  templateUrl: './validate-posts-modal.component.html',
  styles: []
})
export class ValidatePostsModalComponent {
  @Input() modalConfig;

  constructor(public modalInstance: NgbActiveModal) {}
}
