import { Component, Input, OnInit } from '@angular/core';
import './alert.component.scss';

@Component({
  selector: 'ssi-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
  @Input() content: string;
  @Input() icon: string;
  @Input() iconColour: string;

  constructor() {}

  async ngOnInit() {}
}
