import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../store';
import { ApiService } from '../api.service';
import { MonitoringStream } from './monitoring-stream.model';

@Injectable({ providedIn: 'root' })
export class MonitoringStreamsService {
  store = new Store<MonitoringStream>(MonitoringStream);
  endpoint = `${this.api.url}/monitoring/index_v2`;

  constructor(protected api: ApiService) {}

  getAll(opts = { refreshStore: false }): Promise<MonitoringStream[]> {
    if (this.store.value.length && !opts.refreshStore) {
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(this.endpoint)
      .pipe(
        map((response: MonitoringStream[]) => {
          this.store.value = response;
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  // TODO: needs implementation for the rest of the CRUD
  delete(id: string) {}
}
