import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AlertsModule } from '../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SettingsComponent } from './settings.component';
import { IndexModule } from './index/index.module';
import { MySettingsModule } from './my-settings/my-settings.module';
import { AccountSettingsModule } from './account-settings/account-settings.module';
import { CompanySettingsModule } from './company-settings/company-settings.module';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings',
          url: '/settings',
          component: SettingsComponent,
          abstract: true
        }
      ]
    }),
    FormsModule,
    IndexModule,
    MySettingsModule,
    AccountSettingsModule,
    CompanySettingsModule,
    OrloComponentsModule
  ],
  declarations: [SettingsComponent]
})
export class SettingsModule {}
