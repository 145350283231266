import './post-engagement.component.scss';

import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Options } from 'highcharts';
import Highcharts from 'highcharts';

import { Account } from '@ui-resources-angular';
import { HighchartsHelperService } from '../../../../../../common/services/highcharts-helper/highcharts-helper.service';
import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';
import {
  MarketingService,
  DateRanges,
  TimeSeriesStats
} from '../../marketing.service';

@Component({
  selector: 'ssi-post-engagement',
  templateUrl: './post-engagement.component.html',
  styles: []
})
export class PostEngagementComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() dateRanges: DateRanges;
  @Input() stats: TimeSeriesStats;
  @Input() tagsToInclude: string[];
  @Input() tagsToExclude: string[];

  statsToDisplay: TimeSeriesStats;
  chartConfig: Options;
  currentMessagesInTotal = 0;
  currentMessagesOutTotal = 0;
  loading = false;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];
  statsByNetwork: { [key: string]: TimeSeriesStats } = {};

  constructor(
    protected elementRef: ElementRef,
    protected translate: TranslateService,
    protected marketingService: MarketingService,
    protected highchartsHelper: HighchartsHelperService
  ) {}

  ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return this.accounts.some((a) => a.account_type_id === n.key);
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);
    this.selectedNetwork = this.selectableNetworks[0];

    // stats for 'all' network already loaded (in parent component) - store the value
    this.statsByNetwork[this.selectedNetwork.key] = this.stats;

    this.statsToDisplay = this.stats;
    this.setTotals();
    this.chartConfig = this.getChartConfig();
  }

  async onNetworkSelect(nsi: NetworkSelectItem): Promise<void> {
    const loadedSuccess = await this.loadAndStoreStatsForNetwork(nsi);
    if (loadedSuccess) {
      this.selectedNetwork = nsi;

      this.statsToDisplay = this.statsByNetwork[nsi.key];
      this.setTotals();
      this.chartConfig = this.getChartConfig();
    }
  }

  async loadAndStoreStatsForNetwork(nsi: NetworkSelectItem): Promise<boolean> {
    let loaded = true;

    if (!this.statsByNetwork[nsi.key]) {
      // if not already loaded (stored)
      this.loading = true;
      try {
        const networkStats = await this.marketingService.loadTimeSeries(
          this.accounts
            .filter((a) => a.account_type_id === nsi.key)
            .map((a) => a.id),
          this.dateRanges,
          this.tagsToInclude,
          this.tagsToExclude
        );

        this.statsByNetwork[nsi.key] = networkStats;
        loaded = true;
      } catch (e) {
        console.error('Error loading/mapping stats: ', e);
        loaded = false;
      }
      this.loading = false;
    }

    return loaded;
  }

  setTotals(): void {
    this.currentMessagesInTotal = Object.values(
      this.statsToDisplay.current.messages_in.values
    ).reduce((a, b) => a + b, 0);

    this.currentMessagesOutTotal = Object.values(
      this.statsToDisplay.current.messages_out.values
    ).reduce((a, b) => a + b, 0);
  }

  getChartConfig(): any {
    const series: any[] = [
      {
        data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
          this.statsToDisplay.current.messages_in,
          this.dateRanges.current
        ),
        // name: this.translate.instant(primaryLabel),
        name: 'INBOUND ENGAGEMENTS',
        color: '#89A4EA'
      },
      {
        data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
          this.statsToDisplay.current.messages_out,
          this.dateRanges.current
        ),
        // name: this.translate.instant(primaryLabel),
        name: 'PUBLISHED POSTS',
        color: '#D41D68'
      }
    ];

    if (this.statsToDisplay.previous) {
      series.push(
        {
          data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
            this.statsToDisplay.previous.messages_in,
            this.dateRanges.current
          ),
          // name: this.translate.instant(secondaryLabel),
          name: 'PREVIOUS PERIOD',
          dashStyle: 'ShortDash',
          type: 'line',
          color: '#89A4EA',
          marker: {
            enabled: false
          }
        },
        {
          data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
            this.statsToDisplay.previous.messages_out,
            this.dateRanges.current
          ),
          // name: this.translate.instant(secondaryLabel),
          name: 'PREVIOUS PERIOD',
          dashStyle: 'ShortDash',
          type: 'line',
          color: '#D41D68',
          marker: {
            enabled: false
          }
        }
      );
    }

    return {
      loading: false,
      chart: {
        // https://www.highcharts.com/docs/chart-design-and-style/design-and-style#chart
        type: 'areaspline',
        zoomType: 'x',
        spacing: [60, 40, 20, 40]
      },
      series,
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: '#838EAB'
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'COUNT',
          margin: 30,
          style: {
            color: '#838EAB'
          }
        },
        labels: {
          style: {
            color: '#838EAB'
          }
        },
        gridLineWidth: 3,
        gridLineColor: '#F4F4FA'
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        // maxHeight: 300,
        // width: 360,
        // align: 'bottom',
        // layout: 'horizontal',
        // verticalAlign: 'middle',
        // itemMarginBottom: 30,
        // y: 20,
        // useHTML: true,
        itemMarginTop: 30,
        // itemMarginRight: 80,
        itemStyle: {
          fontSize: '10px',
          fontWeight: '900',
          color: '#43537F',
          letterSpacing: '1px',
          fontFamily: 'Lato, Arial, sans-serif'
        }
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolSize: 15,
            symbolStroke: '#838EAB',
            x: 35,
            y: -35
          }
        }
      },
      accessibility: {
        description: ''
      },
      time: {
        useUTC: false
      }
      // plotOptions: {
      //   pie: {
      //     allowPointSelect: true,
      //     cursor: 'pointer',
      //     dataLabels: {
      //       enabled: false
      //     },
      //     showInLegend: true
      //   }
      // }
    };
  }
}
