<div class="advert-graph-wrap" *ngIf="graphVisible" [@slideInOut]>
  <ssi-highchart [theme]="'dark'" [config]="chartOptions"></ssi-highchart>
  <ssi-highchart-controls
    (chartChangeDate)="chartChangeDate($event.timestamp)"
    (chartChangeDateCustom)="chartChangeDateCustom()"
    [now]="now"
    [chartSearchDates]="chartSearchDates"
    [chartDefaultStartDate]="chartDefaultStartDate">
  </ssi-highchart-controls>
</div>

<ssi-advertising-nav
  [showSuggestions]="adverts.length === 0"
  (changeGraphState)="changeGraphState()">
</ssi-advertising-nav>
<ssi-advertising-table-filters
  [sortEnabled]="true"
  [statusEnabled]="true"
  [activeSort]="activeSort"
  [sortLabel]="sortLabel"
  [activeSocialFilter]="activeSocialFilter"
  [activeStatusFilter]="activeStatusFilter"
  (filterStatus)="filterStatus($event)"
  (filterAccount)="filterAccount($event)"
  (sortAccount)="sortAccount($event)">
</ssi-advertising-table-filters>
<div class="advert-table">
  <ul class="advert-table-head" [ngClass]="{active: adverts.length}">
    <li class="advert-table-col"><i class="ssi ssi-ads advert-table-head-icon"></i></li>
    <li class="advert-table-head-label advert-table-col"><p class="hide" translate="AD_DESCRIPTION"></p></li>
    <li class="advert-table-head-label advert-table-col">
      <p
      [ssiTooltip]="'The number of clicks your ads have received, excluding some social actions such as likes, comments and shares'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table"
      translate="CLICKS"></p>
    </li>
    <!-- <li class="advert-table-head-label advert-table-col">
      <p [ssiTooltip]="'Reach is Lorem ipsum dolor sit amet, consectetur'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table">Reach</p>
    </li> -->
    <li class="advert-table-head-label advert-table-col">
      <p
      [ssiTooltip]="'The number of times people saw your ad'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table"
      translate="IMPRESSIONS"></p>
    </li>
    <li class="advert-table-head-label advert-table-col">
      <p
      [ssiTooltip]="'The number of social engagements your ad received'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table"
      translate="ENGAGEMENTS"></p>
    </li>
    <li class="advert-table-head-label advert-table-col">
      <p
      [ssiTooltip]="'The total spent on your ads divided by the total number of clicks'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table">Avg.CPC</p>
    </li>
    <li class="advert-table-head-label advert-table-col">
      <p
      [ssiTooltip]="'The total spent on your ads divided by the total number of impressions multiplied by 1000'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table">Avg.CPM</p>
    </li>
    <li class="advert-table-head-label advert-table-col">
      <p
      [ssiTooltip]="'The total amount you have spent on ads impressions or interactions'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table"
      translate="TOTAL_SPEND"></p>
    </li>
    <!-- <li class="advert-table-head-label advert-table-col">
      <p
      [ssiTooltip]="'Website Conversions are Lorem ipsum dolor sit amet, consectetur'"
      [tooltipAppendToBody]="true"
      tooltipWindowClass="tooltip-ad-table"
      translate="WEBSITE_CONVERSIONS"></p>
    </li> -->
  </ul>
</div>

<div class="advert-table-body-wrap" [ngClass]="{
  'advert-table-body-wrap-paused': activeStatusFilter === 'paused',
  'advert-table-body-wrap-completed': activeStatusFilter === 'completed'}">
  <div *ngIf="adverts.length > 0" class="advert-table-body">
    <ul *ngFor="let advert of adverts; trackBy:trackById" class="ad">
      <li *ngIf="advert.outbox.outbox_files.length > 0" class="advert-table-col ad-thumbnail-col">
        <div class="ad-thumbnail-wrap"
          [ngClass]="{
            'ad-thumbnail-live': ['ACTIVE', 'UNKNOWN'].includes(advert.external_status),
            'ad-thumbnail-scheduled': advert.external_status === null,
            'ad-thumbnail-paused': advert.external_status === 'PAUSED',
            'ad-thumbnail-completed': ['COMPLETE', 'DELETED'].includes(advert.external_status)}">
          <div
            *ngIf="advert.outbox.outbox_files[0].mimetype === 'image/jpeg'"
            class="ad-thumbnail" (click)="openPost(advert)"
            [ngStyle]="{'background-image': 'url(' + advert.outbox.outbox_files[0].public_url + ')'}">
          </div>
          <div
            *ngIf="advert.outbox.outbox_files[0].mimetype === 'video/mp4'"
            class="ad-thumbnail" (click)="openPost(advert)">
            <video width="145" height="100" controls>
              <source [src]="advert.outbox.outbox_files[0].public_url" type="video/mp4">
            </video>
          </div>
        </div>
      </li>
      <li *ngIf="advert.outbox.outbox_files.length === 0" class="advert-table-col ad-thumbnail-col">
          <div class="ad-thumbnail-wrap"
            [ngClass]="{
              'ad-thumbnail-live': ['ACTIVE', 'UNKNOWN'].includes(advert.external_status),
              'ad-thumbnail-scheduled': advert.external_status === null,
              'ad-thumbnail-paused': advert.external_status === 'PAUSED',
              'ad-thumbnail-completed': ['COMPLETE', 'DELETED'].includes(advert.external_status)}">
            <div class="ad-thumbnail" (click)="openPost(advert)"></div>
          </div>
      </li>
      <li class="advert-table-col ad-description">
        <div *ngIf="['ACTIVE', 'UNKNOWN'].includes(advert.external_status)" class="ad-status ad-status-live" (click)="openPost(advert)">
          <i class="ssi ssi-live"></i>
          <p>View live ad</p>
        </div>
        <div *ngIf="advert.external_status === null" class="ad-status ad-status-scheduled" (click)="openPost(advert)">
          <i class="ssi ssi-mini-schedule"></i>
          <p>View scheduled ad</p>
        </div>
        <div *ngIf="advert.external_status === 'PAUSED'" class="ad-status ad-status-paused" (click)="openPost(advert)">
          <i class="fa fa-pause-circle"></i>
          <p>View paused ad</p>
        </div>
        <div *ngIf="['COMPLETE', 'DELETED'].includes(advert.external_status)" class="ad-status ad-status-completed" (click)="openPost(advert)">
          <i class="ssi ssi-tick"></i>
          <p>View completed ad</p>
        </div>
        <p class="ad-description-title">{{advert.name}}</p>
        <p class="ad-description-body">{{advert.outbox.text}}</p>
      </li>
      <li class="advert-table-col ad-stat">{{advert.statistics.clicks_paid || 'n/a'}}</li>
      <!-- <li class="advert-table-col ad-stat">{{advert.outbox.reach || 'n/a'}}</li> -->
      <li class="advert-table-col ad-stat">{{advert.statistics.impressions_paid || 'n/a'}}</li>
      <li class="advert-table-col ad-stat">{{advert.statistics.engagements_paid || 'n/a'}}</li>
      <li class="advert-table-col ad-stat">
        {{!!advert.statistics.total_spend && !!advert.statistics.clicks_paid ?
          (advert.statistics.total_spend/advert.statistics.clicks_paid | currency:getAdvertCurrency(advert)) :
          'n/a'}}
      </li>
      <li class="advert-table-col ad-stat">
        {{!!advert.statistics.impressions_paid && !!advert.statistics.total_spend ?
          (advert.statistics.total_spend/advert.statistics.impressions_paid*1000 | currency:getAdvertCurrency(advert)) :
          'n/a'}}</li>
      <li class="advert-table-col ad-stat">
        {{advert.statistics.total_spend ? (advert.statistics.total_spend | currency:getAdvertCurrency(advert)) : 'n/a'}}
      </li>
      <!-- <li class="advert-table-col ad-stat">n/a</li> -->
    </ul>
  </div>
  
  <!-- <ol *ngIf="adverts.length > 0" class="advert-table-pagination">
    <li class="pagination-control"><i class="ssi ssi-chevron-left"></i></li>
    <li class="pagination-item">1</li>
    <li class="pagination-item">2</li>
    <li class="pagination-item pagination-item-active">3</li>
    <li class="pagination-item">4</li>
    <li class="pagination-item">5</li>
    <li class="pagination-control"><i class="ssi ssi-chevron-right"></i></li>
  </ol> -->

  <div class="advert-table-load-more" *ngIf="nextPage">
    <button class="btn btn-primary btn-rounded btn-padding-sides" (click)="loadMore(nextPage)" translate="LOAD_MORE"></button>
  </div>

  <div *ngIf="adverts.length === 0" class="adverts-none-wrap">
    <div class="adverts-none">
      <i class="ssi ssi-ad-microcopy adverts-none-icon"></i>
      <h2 class="objectives-none-title">
        Oh Shucks...you have no 
        <span *ngIf="activeStatusFilter === 'live'">live </span>
        <span *ngIf="activeStatusFilter === 'scheduled'">scheduled </span>
        <span *ngIf="activeStatusFilter === 'paused'">paused </span>
        <span *ngIf="activeStatusFilter === 'completed'">completed </span>
        ads
      </h2>
      <p class="objectives-none-meta">Check out your <a uiSref="^.adObjectives">top organic posts</a> to see your statistically best posts to boost</p>
    </div>
  </div>
</div>
