<div class="report-sidebar">
  <div class="report-icon">
    <i [class]="'ssi ' + icon"></i>
  </div>
  <ul>
    <li 
      *ngFor="let nav of navList" 
      [style.background]="active === nav.tag ? color : 'none'"
      (click)="navClicked.emit(nav.tag)">
      {{nav.label}}
    </li>
  </ul>
  <hr />
  <ul>
    <li 
      *ngFor="let action of actionList" 
      (click)="action.func()">
      <i [class]="'ssi ' + action.icon"></i>
      {{action.label}}
    </li>
  </ul>
</div>