<div [ngClass]="{'content-calendar-container-mobile': isMobile}"
     class="content-calendar-container"
     [class.month-view]="view === CalendarView.Month">

  <div class="content-calendar-header">
    <ssi-content-calendar-nav [view]="view"
                              [viewDate]="viewDate"
                              [totalPostsCountLabel]="getTotalPostsCountLabel()"
                              [activeFilters]="activeFilters"
                              (viewDateChange)="setViewDate($event)"
                              (viewChange)="setView($event)"
                              (filtersShow)="filtersVisible = true;"
                              (createPostClicked)="publisherActive.next({isActive: true})"
                              (createEventClicked)="openCreateEventModal()"
                              (toggleCampaignsClicked)="toggleShowCampaigns()"></ssi-content-calendar-nav>

    <ssi-filters #filtersRef
                 [config]="'outbox'"
                 [(visible)]="filtersVisible"
                 [hideTimePeriodFilter]="true"
                 [hidePrivateMessages]="true"
                 (onFiltersChange)="activeFilters = $event;"
                 (onApply)="applyFilters($event)"></ssi-filters>
  </div>

  <div class="content-calendar-header-scroll-margin"></div>

  <div class="campaigns-and-events-box above-table"
       *ngIf="view === CalendarView.Day">

    <ng-container *ngIf="showCampaigns">
      <div *ngFor="let campaignEvent of filterEventsByDay(campaignEvents, viewDate)"
           class="single-item"
           [ngStyle]="{'background-color': 'rgba(' + campaignEvent.colour + ', 0.1)', 'border-color': 'rgb(' + campaignEvent.colour + ')'}">
        <i class="ssi ssi-starred"
           [style.color]="'rgb(' + campaignEvent.colour + ')'"></i>
        <span>{{campaignEvent.name}}</span>
      </div>
    </ng-container>

    <div *ngFor="let customEvent of filterEventsByDay(customEvents, viewDate)"
         (click)="openManageEventModal(customEvent)"
         class="single-item custom-event"
         [ngStyle]="{'background-color': 'rgba(' + customEvent.colour + ', 0.1)', 'border-color': 'rgb(' + customEvent.colour + ')'}">
      <i class="ssi ssi-calendar-event"
         [style.color]="'rgb(' + customEvent.colour + ')'"></i>
      <span>{{customEvent.title}}</span>
    </div>
  </div>

  <div [ngSwitch]="view">
    <div #calendarScrollTop
         class="calendar-scroll-top"></div>
    <ssi-calendar-month-view *ngSwitchCase="CalendarView.Month"
                             [viewDate]="viewDate"
                             [events]="events"
                             [refresh]="refresh"
                             [weekStartsOn]="WEEK_STARTS_ON"
                             [activeDayIsOpen]="false"
                             [cellTemplate]="dayCellTemplate"
                             [headerTemplate]="emptyTemplate"
                             (dayClicked)="dayClicked($event.day)"
                             (headerRefreshed)="onMontViewhHeaderRefreshed($event)"
                             (bodyRefreshed)="onMontViewhBodyRefreshed($event)"
                             (eventClicked)="handleEvent('Clicked', $event.event)"
                             (eventTimesChanged)="eventTimesChanged($event)"></ssi-calendar-month-view>

    <ssi-calendar-week-view *ngSwitchCase="CalendarView.Week"
                            [calendarView]="view"
                            [viewDate]="viewDate"
                            [events]="events"
                            [refresh]="refresh"
                            [weekStartsOn]="WEEK_STARTS_ON"
                            [hourDuration]="60"
                            [hourSegments]="1"
                            [hourSegmentHeight]="44"
                            [minimumEventHeight]="humuhumunukunukuapuaa ? 124 : 44"
                            [hourSegmentTemplate]="hourCellTemplate"
                            [eventTemplate]="eventTemplate"
                            [headerTemplate]="weekHeadRowTemplate"
                            [humuhumunukunukuapuaa]="humuhumunukunukuapuaa"
                            [showCampaigns]="showCampaigns"
                            (headerRefreshed)="onWeekViewHeaderRefreshed($event)"
                            (bodyRefreshed)="onWeekViewBodyRefreshed($event)"
                            (eventClicked)="handleEvent('Clicked', $event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)"
                            (calculateHeaderHeight)="calculateHeaderHeight($event)"></ssi-calendar-week-view>

    <ssi-calendar-day-view *ngSwitchCase="CalendarView.Day"
                           [ngClass]="isMobile ? 'calendar-day-view-mobile' : 'calendar-day-view'"
                           [viewDate]="viewDate"
                           [events]="events"
                           [hourSegmentHeight]="isMobile ? 44 : 39"
                           [hourSegments]="isMobile ? 1 : 4"
                           [refresh]="refresh"
                           [isMobile]="isMobile"
                           [hourSegmentTemplate]="hourCellTemplate"
                           [eventTemplate]="eventTemplate"
                           (eventClicked)="handleEvent('Clicked', $event.event)"
                           (eventTimesChanged)="eventTimesChanged($event)"></ssi-calendar-day-view>

  </div>
</div>

<ng-template #hourCellTemplate
             let-segment="segment"
             let-locale="locale"
             let-segmentHeight="segmentHeight"
             let-isTimeLabel="isTimeLabel"
             let-daysInWeek="daysInWeek"
             let-columnEvents="columnEvents">
  <div [attr.aria-hidden]="{} | calendarA11y : (daysInWeek === 1 ? 'hideDayHourSegment' : 'hideWeekHourSegment')"
       class="cal-hour-segment"
       [style.height.px]="segmentHeight"
       [class.cal-hour-start]="segment.isStart"
       [class.cal-after-hour-start]="!segment.isStart"
       [ngClass]="segment.cssClass"
       (click)="cellActionsMenu.hide(); postsPreviewMenu.hide();">
    <ng-container *ngIf="view === CalendarView.Day">
      <div *ngIf="isTimeLabel"
           class="cal-time">
        <h4 *ngIf="isMobile && (segment.date | date: 'H') === '0'"
            class="cal-time-date">
          {{ viewDate | date: 'EEE' }}
          <span>{{ viewDate | date: 'dd' }}</span>
        </h4>
        <span>{{segment.displayDate | calendarDate : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour') : locale}}</span>
        <span *ngIf="+segment.date.getTime() > +now.getTime()"
              class="cell-add-icon-left"
              (click)="cellActionsMenu.hide(); cellActionsMenu.show($event, segment);"></span>
      </div>
      <span *ngIf="+segment.date.getTime() > +now.getTime()"
            class="cell-add-icon-left"
            (click)="cellActionsMenu.hide(); cellActionsMenu.show($event, segment);"></span>
    </ng-container>

    <ng-container *ngIf="view === CalendarView.Week">
      <div class="cal-time"
           *ngIf="isTimeLabel">
        {{segment.displayDate | calendarDate : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour') : locale}}
      </div>
      <span *ngIf="+segment.date.getTime() > +now.getTime()"
            class="cell-add-icon"
            (click)="cellActionsMenu.hide(); cellActionsMenu.show($event, segment);"></span>
    </ng-container>

    <div class="hidden-events-btn-container"
         *ngIf="!isTimeLabel && view === CalendarView.Week && columnEvents.length && getHiddenHourEventsCount(segment.date) > 0">
      <button type="button"
              class="show-more-btn"
              (click)="cellActionsMenu.hide(); postsPreviewMenu.hide(); postsPreviewMenu.show($event, {events: getHourEvents(segment.date), draggedFrom: segment});">+
        {{getHiddenHourEventsCount(segment.date)}} more</button>
    </div>
    <div class="hidden-events-btn-container mobile"
         *ngIf="view === CalendarView.Day && isMobile && columnEvents.length && getHiddenHourEventsCount(segment.date) > 0">
      <button type="button"
              class="show-more-btn"
              (click)="cellActionsMenu.hide(); postsPreviewMenu.hide(); postsPreviewMenu.show($event, {events: getHourEvents(segment.date), draggedFrom: segment});">View
        {{getHiddenHourEventsCount(segment.date)}} more <i class="ssi ssi-arrow-down-new"></i></button>
    </div>
  </div>
</ng-template>

<ng-template #dayCellTemplate
             let-day="day"
             let-openDay="openDay"
             let-locale="locale"
             let-tooltipPlacement="tooltipPlacement"
             let-highlightDay="highlightDay"
             let-unhighlightDay="unhighlightDay"
             let-eventClicked="eventClicked"
             let-tooltipTemplate="tooltipTemplate"
             let-tooltipAppendToBody="tooltipAppendToBody"
             let-tooltipDelay="tooltipDelay"
             let-trackByEventId="trackByEventId"
             let-validateDrag="validateDrag"
             let-rowIndex="rowIndex">
  <div class="day-cell-inner-wrapper"
       (click)="cellActionsMenu.hide(); this.postsPreviewMenu.hide();">
    <div class="cell-top"
         [attr.aria-label]="{ day: day, locale: locale } | calendarA11y: 'monthCell'">
      <span class="day-name-wrapper">
        <span class="day-name"
              *ngIf="rowIndex === 0">{{day.date | date: 'EEE'}}</span>
      </span>
      <span class="day-number-wrapper"
            [class.today]="day.isToday"
            [class.out-of-month]="!day.inMonth"
            aria-hidden="true">
        <!-- <span class="cal-day-badge"
       *ngIf="day.badgeTotal > 0">{{day.badgeTotal}}</span> -->
        <span class="day-number">{{day.date | date: 'dd'}}</span>
      </span>
    </div>

    <!-- <ng-container *ssiVar="filterCustomEventsByMonthDay(day.date) as filtered222CustomEvents"></ng-container> -->

    <div class="campaigns-and-events-box">
      <ng-container *ngIf="showCampaigns">
        <div *ngFor="let campaignEvent of visibleCampaignEventsByDay[day.date]"
             class="single-item"
             [class.spans-next-day]="campaignEvent.spansNextDay"
             [class.spans-previous-day]="campaignEvent.spansPreviousDay"
             [ngStyle]="{'background-color': 'rgba(' + campaignEvent.colour + ', 0.1)', 'border-color': 'rgb(' + campaignEvent.colour + ')'}">
          <i class="ssi ssi-starred"
             [style.color]="'rgb(' + campaignEvent.colour + ')'"></i>
          <span>{{campaignEvent.name}}</span>
        </div>
      </ng-container>

      <div *ngFor="let customEvent of visibleCustomEventsByDay[day.date]"
           (click)="openManageEventModal(customEvent)"
           class="single-item custom-event"
           [class.spans-next-day]="customEvent.spansNextDay"
           [class.spans-previous-day]="customEvent.spansPreviousDay"
           [ngStyle]="{'background-color': 'rgba(' + customEvent.colour + ', 0.1)', 'border-color': 'rgb(' + customEvent.colour + ')'}">
        <i class="ssi ssi-calendar-event"
           [style.color]="'rgb(' + customEvent.colour + ')'"></i>
        <span>{{customEvent.title}}</span>
      </div>

      <button *ngIf="!allCampaignAndCustomEventsVisible && hiddenCampaignAndCustomEventsCount(day) > 0 && day.inMonth"
              (click)="toggleShowMoreCampaignAndCustomEvents()">
        {{hiddenCampaignAndCustomEventsCount(day)}} more
        <i class="ssi ssi-arrow-down-new"></i>
      </button>
      <button class="close-collapse"
              *ngIf="allCampaignAndCustomEventsVisible && customEventsByDay[day.date].length > 1 && day.inMonth && (showCampaigns ? (!!customEventsByDay[day.date].length || !!campaignEventsByDay[day.date].length) : !!customEventsByDay[day.date].length)"
              (click)="toggleShowMoreCampaignAndCustomEvents()">
        Close
        <i class="ssi ssi-arrow-down-new"></i>
      </button>
    </div>

    <div class="cal-events">
      <!-- <div class="cal-event"
    *ngFor="let event of day.events; trackBy: trackByEventId"
    [ngStyle]="{ backgroundColor: event.color?.primary }"
    [ngClass]="event?.cssClass"
    (mouseenter)="highlightDay.emit({ event: event })"
    (mouseleave)="unhighlightDay.emit({ event: event })"
    [ssiCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
    [tooltipPlacement]="tooltipPlacement"
    [tooltipEvent]="event"
    [tooltipTemplate]="tooltipTemplate"
    [tooltipAppendToBody]="tooltipAppendToBody"
    [tooltipDelay]="tooltipDelay"
    ssiDraggable
    [class.cal-draggable]="event.draggable"
    dragActiveClass="cal-drag-active"
    [dropData]="{ event: event, draggedFrom: day }"
    [dragAxis]="{ x: event.draggable, y: event.draggable }"
    [validateDrag]="validateDrag"
    [touchStartLongPress]="{ delay: 300, delta: 30 }"
    (ssiClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
    [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"></div> -->

      <ng-container *ngIf="useMonthViewTotals">
        <div *ngFor="let event of day.events;"
             class="post-chip"
             (click)="$event.stopPropagation(); onPostChipClick($event, event);">
          <ssi-social-network-icon [accountTypeId]="event.metas.group.account_type_id"
                                   [size]="20"></ssi-social-network-icon>
          <div class="post-count">
            <span *ngIf="event.metas.group.postsCount >= 10">{{event.metas.group.postsCount}}</span>
            <span *ngIf="event.metas.group.postsCount < 10">0{{event.metas.group.postsCount}}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!useMonthViewTotals">
        <ng-container *ngFor="let group of groupPostsByAccountTypeId(day); trackBy: trackByGroupId">
          <div class="post-chip"
               (click)="$event.stopPropagation(); cellActionsMenu.hide(); postsPreviewMenu.hide(); postsPreviewMenu.show($event, {events: group.events, draggedFrom: group.day});"
               ssiDraggable
               [class.cal-draggable]="group.draggable"
               dragActiveClass="cal-drag-active"
               [dropData]="{ event: {group: group}, draggedFrom: day }"
               [dragAxis]="{ x: group.draggable, y: group.draggable }"
               [validateDrag]=""
               [touchStartLongPress]="{ delay: 300, delta: 30 }"
               [class.failed-post]="false">
            <ssi-social-network-icon [accountTypeId]="group.id"
                                     [size]="20"></ssi-social-network-icon>
            <div class="post-count">
              <span *ngIf="group.posts.length >= 10">{{group.posts.length}}</span>
              <span *ngIf="group.posts.length < 10">0{{group.posts.length}}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="cell-bottom"
         *ngIf="day.inMonth">
      <div class="posts-count-chip"
           [class.has-posts]="day.badgeTotal > 0">
        <span class="posts-count">{{day.badgeTotal}}</span>
      </div>
    </div>
    <span *ngIf="+day.date.getTime() >= +startOfToday.getTime()"
          class="cell-add-icon"
          (click)="cellActionsMenu.hide(); cellActionsMenu.show($event, day);"></span>
  </div>
</ng-template>

<ng-template #emptyTemplate></ng-template>

<ng-template #weekHeadRowTemplate
             let-days="days"
             let-locale="locale"
             let-dayHeaderClicked="dayHeaderClicked"
             let-eventDropped="eventDropped"
             let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
             let-dragEnter="dragEnter">
  <div class="cal-day-headers custom-scrollbar"
       role="row">
    <div class="cal-header"
         *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
         [class.past]="day.isPast"
         [class.today]="day.isToday"
         [class.future]="day.isFuture"
         [class.weekend]="day.isWeekend"
         [ngClass]="day.cssClass"
         (ssiClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })"
         ssiDroppable
         dragOverClass="cal-drag-over"
         (drop)="eventDropped.emit({event: $event.dropData.event, newStart: day.date})"
         (dragEnter)="dragEnter.emit({ date: day.date })"
         tabindex="0"
         role="columnheader">
      <div class="week-head-cell-top">
        <span>{{ day.date | date: 'EEE' }}</span>
        <b [class.today]="day.isToday">{{day.date | date: 'dd'}}</b>
      </div>
      <div class="week-head-cell-bottom">
        <div class="posts-count-chip"
             [class.has-posts]="!!getDayEvents(day.date).length">
          <span class="posts-count">{{getDayEvents(day.date).length}}</span>
        </div>
        <div class="campaigns-and-events-box">
          <ng-container *ngIf="showCampaigns">
            <div *ngFor="let campaignEvent of visibleCampaignEventsByDay[day.date]"
                 class="single-item"
                 [class.spans-next-day]="campaignEvent.spansNextDay"
                 [class.spans-previous-day]="campaignEvent.spansPreviousDay"
                 [ngStyle]="{'background-color': 'rgba(' + campaignEvent.colour + ', 0.1)', 'border-color': 'rgb(' + campaignEvent.colour + ')'}">
              <i class="ssi ssi-starred"
                 [style.color]="'rgb(' + campaignEvent.colour + ')'"></i>
              <span>{{campaignEvent.name}}</span>
            </div>
          </ng-container>
          <div *ngFor="let customEvent of visibleCustomEventsByDay[day.date]"
               (click)="openManageEventModal(customEvent)"
               class="single-item custom-event"
               [class.spans-next-day]="customEvent.spansNextDay"
               [class.spans-previous-day]="customEvent.spansPreviousDay"
               [ngStyle]="{'background-color': 'rgba(' + customEvent.colour + ', 0.1)', 'border-color': 'rgb(' + customEvent.colour + ')'}">
            <i class="ssi ssi-calendar-event"
               [style.color]="'rgb(' + customEvent.colour + ')'"></i>
            <span>{{customEvent.title}}</span>
          </div>

          <button *ngIf="!allCampaignAndCustomEventsVisible && hiddenCampaignAndCustomEventsCount(day) > 0"
                  (click)="toggleShowMoreCampaignAndCustomEvents()">
            {{hiddenCampaignAndCustomEventsCount(day)}} more
            <i class="ssi ssi-arrow-down-new"></i>
          </button>
          <button class="close-collapse"
                  *ngIf="allCampaignAndCustomEventsVisible && (showCampaigns ? (!!customEventsByDay[day.date].length || !!campaignEventsByDay[day.date].length) : !!customEventsByDay[day.date].length)"
                  (click)="toggleShowMoreCampaignAndCustomEvents()">
            Close
            <i class="ssi ssi-arrow-down-new"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #eventTemplate
             let-weekEvent="weekEvent"
             let-tooltipPlacement="tooltipPlacement"
             let-eventClicked="eventClicked"
             let-tooltipTemplate="tooltipTemplate"
             let-tooltipAppendToBody="tooltipAppendToBody"
             let-tooltipDisabled="tooltipDisabled"
             let-tooltipDelay="tooltipDelay"
             let-column="column"
             let-daysInWeek="daysInWeek"
             let-locale="locale"
             let-eventActionsTemplate="eventActionsTemplate"
             let-eventTitleTemplate="eventTitleTemplate">
  <!-- <div class="cal-event"
       [ngStyle]="{color: weekEvent.event.color?.secondaryText, backgroundColor: weekEvent.event.color?.secondary, borderColor: weekEvent.event.color?.primary}"
       [ssiCalendarTooltip]="!tooltipDisabled ? (weekEvent.event.title | calendarEventTitle : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip') : weekEvent.tempEvent || weekEvent.event) : ''"
       [tooltipPlacement]="tooltipPlacement"
       [tooltipEvent]="weekEvent.tempEvent || weekEvent.event"
       [tooltipTemplate]="tooltipTemplate"
       [tooltipAppendToBody]="tooltipAppendToBody"
       [tooltipDelay]="tooltipDelay"
       (ssiClick)="eventClicked.emit({ sourceEvent: $event })"
       (ssiKeydownEnter)="eventClicked.emit({ sourceEvent: $event })"
       tabindex="0"
       role="application"
       [attr.aria-label]="{ event: weekEvent.tempEvent || weekEvent.event, locale: locale } | calendarA11y: 'eventDescription'">
    <ssi-calendar-event-actions [event]="weekEvent.tempEvent || weekEvent.event"
                                [customTemplate]="eventActionsTemplate"></ssi-calendar-event-actions>
    &ngsp;
    <ssi-calendar-event-title [event]="weekEvent.tempEvent || weekEvent.event"
                              [customTemplate]="eventTitleTemplate"
                              [view]="daysInWeek === 1 ? 'day' : 'week'"></ssi-calendar-event-title> CUSTOM TEMPLATE
  </div> -->

  <!-- for day and week view, month view has a separate logic -->
  <ssi-post-box #postBox
                [view]="view"
                [post]="weekEvent.event.metas.post"
                [isMobile]="isMobile"
                (click)="updateUrlParams(weekEvent.event.metas.post)"
                ssiDraggable
                [class.cal-draggable]="weekEvent.event.draggable"
                dragActiveClass="cal-drag-active"
                [dropData]="{ event: weekEvent.event, draggedFrom: weekEvent.event }"
                [dragAxis]="{ x: weekEvent.event.draggable && view !== CalendarView.Day, y: weekEvent.event.draggable }"
                [validateDrag]=""
                [touchStartLongPress]="{ delay: 300, delta: 30 }"></ssi-post-box>
</ng-template>

<ssi-context-menu #postsPreviewMenu
                  [preventBackgroundScroll]="true">
  <ng-template let-ctx>
    <div class="posts-preview-menu">
      <!-- for all views, when show more is clicked -->
      <ssi-post-box *ngFor="let event of ctx.events"
                    [view]="'week'"
                    [post]="event.metas.post"
                    [isModalView]="true"
                    [isMobile]="isMobile"
                    (click)="postsPreviewMenu.hide(); updateUrlParams(event.metas.post);"
                    ssiDraggable
                    [class.cal-draggable]="event.draggable"
                    dragActiveClass="cal-drag-active"
                    [dropData]="{ event: event, draggedFrom: ctx.draggedFrom }"
                    [dragAxis]="{ x: event.draggable && view !== CalendarView.Day, y: event.draggable }"
                    [validateDrag]=""
                    [touchStartLongPress]="{ delay: 300, delta: 30 }"></ssi-post-box>
    </div>
  </ng-template>
</ssi-context-menu>

<ssi-context-menu #cellActionsMenu
                  [preventBackgroundScroll]="true">
  <ng-template let-hourSegment>
    <ul class="g-list">
      <li class="g-list-item"
          (click)="cellActionsMenu.hide(); createPostHere(hourSegment)">
        <i class="ssi ssi-compose-post"></i>
        <span>Create post</span>
      </li>
      <!-- <li class="g-list-item"
          (click)="cellActionsMenu.hide(); createDraft(hourSegment);"
          [ssiTriggerPublish]>
        <i class="ssi ssi-drafts"></i>
        <span>Draft post</span>
      </li>
      <li class="g-list-item"
          (click)="cellActionsMenu.hide(); createPlaceholder(hourSegment);">
        <i class="ssi ssi-placeholder"></i>
        <span>Placeholder</span>
      </li> -->
      <li class="g-list-item"
          (click)="cellActionsMenu.hide(); openCreateEventModal(hourSegment);">
        <i class="ssi ssi-calendar-event"></i>
        <span>Calendar event</span>
      </li>
    </ul>
  </ng-template>
</ssi-context-menu>