<div class="post-box-content-body">
    <div class="post-box-content-status">
        <div
            [ngClass]="{'post-box-selected': isSelected()}"
            (click)="!selectMode && addToSelected.emit()"
        >
            <i
                *ngIf="isSelected()"
                class="ssi ssi-tick post-box-selected-icon"
            ></i>
            <img
                class="img-circle"
                [src]="validation.account?.picture"
                ssiFallbackImage="avatar"
            >
        </div>
    </div>
    <div class="post-box-content">
        <div class="post-box-content-meta">
            <!-- <a
                (click)="toggleEditMode()"
                translate="EDIT_REPLY"
            ></a>
            <span
                class="post-box-content-divide"
                [innerHTML]="pipe"
            ></span> -->
            <i class="text-primary ssi ssi-fw ssi-user-small"></i>
            <span>{{user?.first_name}}&nbsp;{{user?.last_name}}</span>
            <span
                class="post-box-content-divide"
                [innerHTML]="pipe"
            ></span>
            <span>Sent for approval: {{validation.created_at | date: 'd MMM y'}}</span>
            <span
                class="post-box-content-divide"
                [innerHTML]="pipe"
            ></span>
            <span>Account: @{{validation.account?.displayName}} <i
                    class="network-icon"
                    [ngClass]="validation.account?.socialNetwork.icon.web"
                ></i></span>
            <span
                class="post-box-content-divide"
                [innerHTML]="pipe"
            ></span>
            <span *ngIf="validation?.visibility === 'public'"><i
                    class="ssi ssi-public-correct"></i>&nbsp;{{'PUBLIC_REPLY' | translate}}</span>
            <span *ngIf="validation?.visibility === 'private'"><i
                    class="ssi ssi-private-correct"></i>&nbsp;{{'PRIVATE_REPLY' | translate}}</span>
        </div>

        <ssi-reply-box
            *ngIf="editMode"
            (onKeyUp)="onKeyUp($event)"
            (onKeyDown)="onKeyDown($event)"
            (onCancel)="toggleEditMode()"
            (onSendReply)="saveReplyEdit($event)"
            (onRemoveAttachment)="validation.outbox_files = null"
            [isInbox]="false"
            [isSocialWall]="false"
            [firstCommentScheduling]="true"
            [inboxApproval]="true"
            [replyValue]="validation.text"
            [prediction]="prediction"
            [activity]="{interaction: {}, conversation: {}}"
            [isDisabled]="false"
            [accountTypeName]="validation.account?.account_type_name"
            [mediaRestrictions]="validation.account?.socialNetwork.mediaRestrictions.reply"
            [replyInProgress]="savingEdit"
            (onFileUploadSuccess)="onFileUploadSuccess($event)"
            [shouldShowPushToCrm]="false"
            [replyAccounts]="[validation.account]"
            [replyAccount]="validation.account"
            [includableReplyToUsers]="[]"
            [replyingToVisible]="false"
            [networkReplyOptions]="validation.account?.socialNetwork.activity"
            [shouldShowTranslate]="!!translation"
            (onTranslate)="translateReplyText()"
            [attachments]="validation.outbox_files?.length && validation.outbox_files[0]"
            [isTimedSession]="false"
            [supportsAddReplyLink]="validation.account?.socialNetwork.getPrivateReplyLink"
            (onAddPrivateReplyLink)="addPrivateReplyLink()"
            [hideArrowShortcuts]="true"
            [isPushMode]="false"
        ></ssi-reply-box>

        <p
            *ngIf="!editMode"
            [innerHtml]="validation.text | linkify: {mentions: mentions}"
        >
        </p>
    </div>
</div>