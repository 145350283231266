import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ObserveWidthAndHeightDirective } from './observe-width-and-height.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ObserveWidthAndHeightDirective],
  exports: [ObserveWidthAndHeightDirective]
})
export class ObserveWidthAndHeightModule {}
