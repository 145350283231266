import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityTextComponent } from './activity-text.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ActivityTextComponent],
  exports: [ActivityTextComponent]
})
export class ActivityTextModule {}
