<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Office hours</h1>
</div>

<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>

  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title"><i class="fa fa-wrench"></i> <span translate="OUT_OF_HOURS_RESPONSE_TIMES"></span></h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <div class="form-horizontal" role="form">
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="INCLUDE_OUT_OF_HOURS_OOH_PERIOD"></label>
          <div class="col-sm-9">
            <select [(ngModel)]="settings.company_preferences.exclude_last_out_of_hours" class="form-control">
              <option value="1" translate="NO__RESPONSE_TIMES_FOR_MESSAGES_RECEIVED_AND_ACTIONED_IN_THE_SAME_OOH_PERIOD_WILL_BE_COUNTED_AS_0_SECONDS"></option>
              <option value="0" translate="YES__RESPONSE_TIMES_FOR_MESSAGES_RECEIVED_AND_ACTIONED_IN_THE_SAME_OOH_PERIOD_WILL_BE_CALCULATED_IN_FULL"></option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer text-right">
      <button type="button" class="btn btn-success" (click)="saveSettings()">
        <i class="fa fa-floppy-o"></i> <span translate="SAVE"></span>
      </button>
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title"><i class="fa fa-search"></i> <span translate="SEARCH"></span></h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <label translate="FILTER_ACCOUNTS"></label>
      <input
        type="text"
        [(ngModel)]="searchText"
        class="form-control"
        placeholder="{{ 'ACCOUNT_NAME' | translate }}"
        (ngModelChange)="search()"
      >
    </div>
  </div>

  <div class="panel panel-default" *ngIf="!searchText">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-clock-o"></i> <span>Company office hours</span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <div class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="TIMEZONE"></label>
          <div class="col-sm-9">
            <select
              [(ngModel)]="officeHours.timezone"
              class="form-control">
              <option *ngFor="let timezone of serverSettings.timezones" [value]="timezone.name">
                {{timezone.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-3 text-right">
            <label class="control-label" translate="OPENING_HOURS"></label>
            <br><br>
            <button type="button" class="btn btn-primary" (click)="addOfficeHour()">
              <i class="fa fa-plus"></i> <span translate="ADD_NEW"></span>
            </button>
          </div>
          <div class="col-sm-9">
            <table class="table">
              <thead>
                <tr>
                  <th translate="DAY_OF_THE_WEEK"></th>
                  <th translate="OPENS_AT"></th>
                  <th translate="CLOSES_AT"></th>
                  <th translate="REMOVE"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let openingPeriod of scheduleFormatted; trackBy: trackByIndex; let index = index;">
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="openingPeriod.day">
                      <option *ngFor="let day of daysOfTheWeek" [value]="day">
                        {{day | date:'EEEE'}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <ngb-timepicker
                      [(ngModel)]="openingPeriod.open"
                      [meridian]="true">
                    </ngb-timepicker>
                  </td>
                  <td>
                    <ngb-timepicker
                      [(ngModel)]="openingPeriod.close"
                      [meridian]="true">
                    </ngb-timepicker>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="scheduleFormatted.splice(index, 1)">
                      <i class="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer text-right">
      <button
        type="button"
        class="btn btn-success"
        (click)="saveOfficeHours()"
        [disabled]="loadingTracker.active">
        <i class="fa fa-spin fa-spinner" *ngIf="loadingTracker.active"></i>
        <i class="fa fa-floppy-o" *ngIf="!loadingTracker.active"></i>
        <span translate="SAVE"></span>
      </button>
    </div>
  </div>

  <div class="panel panel-default" *ngFor="let account of filteredAccounts">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="network-icon {{ account.socialNetwork.icon.web }}"></i><span>{{account.name}}</span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <div class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="TIMEZONE"></label>
          <div class="col-sm-9">
            <select
              [(ngModel)]="account?.officeHours.timezone"
              class="form-control">
              <option *ngFor="let timezone of serverSettings.timezones" [value]="timezone.name">
                {{timezone.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-3 text-right">
            <label class="control-label" translate="OPENING_HOURS"></label>
            <br><br>
            <button type="button" class="btn btn-primary" (click)="addOfficeHoursForAccount(account)">
              <i class="fa fa-plus"></i> <span translate="ADD_NEW"></span>
            </button>
          </div>
          <div class="col-sm-9">
            <table class="table">
              <thead>
                <tr>
                  <th translate="DAY_OF_THE_WEEK"></th>
                  <th translate="OPENS_AT"></th>
                  <th translate="CLOSES_AT"></th>
                  <th translate="REMOVE"></th>
                </tr>
              </thead>
              <tbody *ngIf="account.scheduleFormatted">
                <tr *ngFor="let accountOpeningPeriod of account.scheduleFormatted; trackBy: trackByIndex; let index = index;">
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="accountOpeningPeriod.day">
                      <option *ngFor="let day of daysOfTheWeek" [value]="day">
                        {{day | date:'EEEE'}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <ngb-timepicker
                      [(ngModel)]="accountOpeningPeriod.open"
                      [meridian]="true">
                    </ngb-timepicker>
                  </td>
                  <td>
                    <ngb-timepicker
                      [(ngModel)]="accountOpeningPeriod.close"
                      [meridian]="true">
                    </ngb-timepicker>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="account.scheduleFormatted.splice(index, 1)">
                      <i class="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer text-right">
      <button
        type="button"
        class="btn btn-success"
        (click)="saveOfficeHoursForAccount(account)"
        [disabled]="loadingTracker.active">
        <i class="fa fa-spin fa-spinner" *ngIf="loadingTracker.active"></i>
        <i class="fa fa-floppy-o" *ngIf="!loadingTracker.active"></i>
        <span>Save for this account</span>
      </button>
    </div>
  </div>
</div>