import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputUrlDirective } from './input-url.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [InputUrlDirective],
  exports: [InputUrlDirective]
})
export class InputUrlModule {}
