export enum SocialType {
  // first 3: 'web', 'moreover' and 'twitter' are for monitoring results,
  // the rest are for activities with the excpetion for 'twitter' which is for both
  // see: https://orlo.slack.com/archives/C020DUYTZU0/p1651156186315789
  Web = 'web',
  Moreover = 'moreover',
  Twitter = 'twitter',
  Facebook = 'facebook',
  Whatsapp = 'whatsapp',
  Linkedin = 'linkedin',
  Youtube = 'youtube',
  GooglePlus = 'google+', // dead
  Instagram = 'instagram', // dead
  InstagramGraph = 'instagram_graph',
  InstagramGraphHashtag = 'instagram_graph_hashtag',
  Livechat = 'livechat',
  ReviewTrackers = 'review_trackers',
  Sms = 'sms',
  Tiktok = 'tiktok', // does not exist?
  Nextdoor = 'nextdoor',
  TiktokBusiness = 'tiktok_business',
  NextdoorUS = 'nextdoor_us'
}

export enum SocialTypeName {
  // first 3: 'web', 'moreover' and 'twitter' are for monitoring results,
  // the rest are for activities with the excpetion for 'twitter' which is for both
  // see: https://orlo.slack.com/archives/C020DUYTZU0/p1651156186315789
  'web' = 'Web',
  'moreover' = 'Moreover',
  'twitter' = 'Twitter',
  'facebook' = 'Facebook',
  'whatsapp' = 'Whatsapp',
  'linkedin' = 'Linkedin',
  'youtube' = 'Youtube',
  'google+' = 'GooglePlus',
  'instagram' = 'Instagram',
  'instagram_graph' = 'InstagramGraph',
  'livechat' = 'Livechat',
  'review_trackers' = 'ReviewTrackers',
  'sms' = 'Sms',
  'tiktok' = 'TikTok',
  'nextdoor' = 'Nextdoor'
}
