import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-engagement-analytics-sentiment',
  templateUrl: './engagement-analytics-sentiment.component.html',
  styles: []
})
export class EngagementAnalyticsSentimentComponent {
  @Input() report;

  constructor() {}
}
