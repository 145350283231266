import './sentiment-list.component.scss';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-sentiment-list',
  templateUrl: './sentiment-list.component.html'
})
export class SentimentListComponent {
  @Input() isInsights: boolean = false;
  @Input() sentiment: number;
  @Output() changeSentiment = new EventEmitter<number>();

  constructor() {}
}
