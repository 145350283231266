import './linkedin-options.component.scss';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountTypeId, AccountTypeName } from '../../../../enums';
import { OutboxPublisher } from '@ui-resources-angular';
import { TargetingOptionsService } from '../targeting-options.service';

@Component({
  selector: 'ssi-linkedin-options',
  templateUrl: './linkedin-options.component.html'
})
export class LinkedInOptionsComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() previewToggled: boolean;

  @Output() select = new EventEmitter();

  constructor(public targetingService: TargetingOptionsService) {}

  ngOnInit() {
    console.log('Should initialise LinkedIn targeting...');
    this.targetingService.initialiseTargeting(
      AccountTypeName.LinkedIn,
      this.post.targeting.LinkedIn
    );
    this.buildFromPost();
  }

  onChange() {
    this.select.emit();
  }

  async buildFromPost() {
    this.targetingService.options.LinkedIn.multiValueInputs.forEach((input) => {
      input.value = (this.post.targeting.LinkedIn[input.key] || []).map(
        (value) => {
          if (input.key === 'cities' || input.key === 'interests') {
            return value;
          }

          if (!(!!input && !!input.options && Array.isArray(input.options))) {
            return;
          }

          return input.options.find((option) =>
            typeof option.code === 'string'
              ? option.code.toLocaleLowerCase() === value.toLocaleLowerCase()
              : option.code === value
          );
        }
      );
    });
  }

  async copyToPost() {
    this.targetingService.options.LinkedIn.multiValueInputs.forEach((input) => {
      if (input.key === 'cities' || input.key === 'interests') {
        this.post.targeting.LinkedIn[input.key] = input.value;
      } else {
        this.post.targeting.LinkedIn[input.key] = input.value.map(
          (value) => value.code
        );
      }
    });
  }

  public readonly AccountTypeId = AccountTypeId;
}
