import { BehaviorSubject } from 'rxjs';

export class Store<T> {
  // Make _valueSource private so it's not accessible from the outside,
  // expose it as value$ observable (read-only) instead.
  // Write to _valueSource only through specified store methods below.
  private readonly _valueSource = new BehaviorSubject<T[]>([]);

  // Exposed observable (read-only).
  readonly value$ = this._valueSource.asObservable();

  // Indicates whether the store has been set with a value at least once.
  // Can be used to control whether to fetch the initial data from the server.
  // Other way to check if cached data can be resolved is to check if the value is (not) an
  // empty array (in that case an empty response from the server would be treated as invalid response).
  valueSet = false;

  constructor(
    private _entityConstructor: new (...args: any[]) => T,
    protected itemIdentifierKey = 'id'
  ) {}

  get value(): T[] {
    return this._valueSource.getValue();
  }

  set value(value: T[]) {
    if (!Array.isArray(value)) {
      console.error('Store should always hold an array value!');
      value = [];
    }

    value = value.map((x) => {
      return x instanceof this._entityConstructor
        ? x
        : new this._entityConstructor(x);
    });

    this._valueSource.next(value);
    this.valueSet = true;
  }

  clear(): void {
    this.value = [];
    this.valueSet = false;
  }

  find(identifier: string | number, identifierKey?: string): T {
    identifierKey = identifierKey || this.itemIdentifierKey;
    return this.value.find(
      (x) => String(x[identifierKey]) === String(identifier)
    );
  }

  filter(filter: { [key: string]: any }): T[] {
    return this.value.filter((x) => {
      return !Object.keys(filter).find((key) => x[key] !== filter[key]);
    });
  }

  add(item: T | Partial<T>): void {
    if (!item[this.itemIdentifierKey]) {
      console.error('The item to add is missing an identifier: ', item);
      return;
    }

    this.value = [...this.value, new this._entityConstructor(item)];
  }

  update(item: T | Partial<T>): void {
    if (!item[this.itemIdentifierKey]) {
      console.error('The item to update does not have an identifier: ', item);
      return;
    }

    this.value = this.value.map((x) => {
      return String(x[this.itemIdentifierKey]) ===
        String(item[this.itemIdentifierKey])
        ? new this._entityConstructor(Object.assign(x, item))
        : x;
    });
  }

  remove(identifier: string | number): void {
    this.value = this.value.filter(
      (x) => String(x[this.itemIdentifierKey]) !== String(identifier)
    );
  }
}
