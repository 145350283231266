import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostErrorsComponent } from './post-errors.component';
import { SocialNetworkIconModule } from '../../social-network-icon/social-network-icon.module';
import { DropdownSelect2Module } from '../../dropdown-select-2/dropdown-select-2.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    SocialNetworkIconModule,
    DropdownSelect2Module,
    TranslateModule,
    FormsModule
  ],
  declarations: [PostErrorsComponent],
  exports: [PostErrorsComponent]
})
export class PostErrorsModule {}
