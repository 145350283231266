<div class="ad-modal">
    <i class="ssi ssi-delete-microcopy ad-modal-icon"></i>
    <div *ngIf="type === 'delete'">
        <p class="ad-modal-title">You are about to delete your ad</p>
        <p class="ad-modal-meta">This will stop your ad from running immediately and also delete it from your ads catalog, are you sure?</p>
        <div class="ad-modal-actions">
            <button class="btn btn-default" (click)="activeModal.dismiss()">No, cancel</button>
            <button class="btn btn-primary">Yes, delete</button>
        </div>
    </div>
    <div *ngIf="type === 'pause'">
        <p class="ad-modal-title">Success, this ad is now paused</p>
        <p class="ad-modal-meta">Your ad is no longer live, but can still be found in the <i>paused view</i> in your Ad Stats Table.</p>
        <div class="ad-modal-actions">
            <button class="btn btn-default" (click)="unpause()">Don't Pause</button>
            <button class="btn btn-primary" (click)="activeModal.dismiss()">Okay</button>
        </div>
    </div>
</div>