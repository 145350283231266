import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { MyPasswordComponent } from './my-password.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.mysettings.mypassword',
          url: '/my-password',
          component: MyPasswordComponent
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [MyPasswordComponent],
  entryComponents: [MyPasswordComponent]
})
export class MyPasswordModule {}
