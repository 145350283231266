import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageActionsComponent } from './message-actions.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { InboxPriorityModule } from '../../inbox-priority/inbox-priority.module';

@NgModule({
  imports: [CommonModule, OverlayModule, TooltipModule, InboxPriorityModule],
  declarations: [MessageActionsComponent],
  exports: [MessageActionsComponent]
})
export class MessageActionsModule {}
