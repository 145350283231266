<div [ngClass]="wrapperClass">
  <div class="modal-header">
    <div class="pull-left">
      <button
        type="button"
        class="btn btn-default eim-zoom"
        (click)="zoom(0.25)">
        <i class="fa fa-lg fa-search-plus"></i>
      </button>
      <button
        type="button"
        class="btn btn-default eim-zoom"
        (click)="zoom(-0.25)"
        [disabled]="scale <= 0.25">
        <i class="fa fa-lg fa-search-minus"></i>
      </button>
      <ng-container *ngIf="multiImage">
        <button
          type="button"
          class="btn btn-default"
          (click)="handleGalleryTransition('previous')"
          [disabled]="currentImage === 0">
          <i class="fa fa-lg fa-angle-left"></i>    	
          <i class="ssi ssi-arrow-down-new arrow-down-new-left"></i>
        </button>
        <button
          type="button"
          class="btn btn-default"
          (click)="handleGalleryTransition('next')"
          [disabled]="(currentImage + 1) === imageSrc.length">
          <i class="fa fa-lg fa-angle-right"></i>  	
          <i class="ssi ssi-arrow-down-new arrow-down-new-right"></i>
        </button>
        <span class="eim-count">{{currentImage + 1}} of {{imageSrc.length}}</span>
      </ng-container>
    </div>
    <div class="pull-right">
      <button
        (click)="close ? close() : activeModal.close()"
        type="button"
        class="btn btn-default">
        <i class="fa fa-times"></i> <span translate="CLOSE"></span>
      </button>
    </div>
  </div>
  
  <div class="modal-body">
    <div
      class="scroll-container"
      (mousemove)="onDrag($event)"
      #scrollContainer>
  
      <img *ngIf="!multiImage"
        [src]="imageSrc"
        class="img-responsive"
        [style.transform]="'scale(' + scale + ')'">
  
      <div *ngIf="multiImage">
        <ng-container *ngFor="let image of imageSrc; let i=index">
          <img *ngIf="i === currentImage"
          [src]="image"
          class="img-responsive"
          [style.transform]="'scale(' + scale + ')'">
        </ng-container>
      </div>
    </div>
    <div *ngIf="altText && altText.length" class="eim-alt-text">
      <p *ngIf="!multiImage" [innerHTML]="altText"></p>
      <div *ngIf="multiImage">
        <ng-container *ngFor="let image of imageSrc; let i = index">
          <p *ngIf="i === currentImage" [innerHTML]="altText[i]"></p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
