import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStore } from 'js-data';
import {
  crmPersonModelFactory,
  CrmPersonModel
} from '../../modules/crm/services/crmPersonModel';
import {
  crmExternalIntegrationModelFactory,
  CrmExternalIntegrationModel
} from '../../modules/crm/services/crmExternalIntegrationModel';

@NgModule()
export class CrmModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CrmModule,
      providers: [
        {
          provide: CrmPersonModel,
          useFactory: crmPersonModelFactory,
          deps: [DataStore]
        },
        {
          provide: CrmExternalIntegrationModel,
          useFactory: crmExternalIntegrationModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
