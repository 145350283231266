import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FilestackService } from '../../../../../common/services/filestack/filestack.service';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'ssi-my-password',
  templateUrl: './my-password.component.html',
  styles: []
})
export class MyPasswordComponent implements OnInit {
  myPasswordForm: FormGroup;
  model = {
    firstname: '',
    lastname: '',
    email: '',
    mobile: ''
  };
  avatar: string;
  uploadedFile;

  constructor(
    private filestack: FilestackService,
    private api: API,
    private state: StateService
  ) {}

  ngOnInit() {
    this.myPasswordForm = new FormGroup({});
    this.myPasswordForm.addControl(
      'password',
      new FormControl('', Validators.required)
    );
    this.myPasswordForm.addControl(
      'retype_password',
      new FormControl('', [
        Validators.compose([
          Validators.required,
          this.validateAreEqual.bind(this)
        ])
      ])
    );
  }

  private validateAreEqual(fieldControl: FormControl) {
    return fieldControl.value === this.myPasswordForm.get('password').value
      ? null
      : {
          NotEqual: true
        };
  }

  onSubmit() {
    const settings = Object.assign({}, this.myPasswordForm.value);
    delete settings.retype_password;

    this.api.post('settings/password', settings).then((result) => {
      if (result.status === 200) {
        this.state.go('auth.settings.index');
      }
    });
  }

  return() {
    this.state.go('^');
  }
}
