<div class="dropdown-select-container"
     [ngClass]="theme"
     (ssiOffClick)="hideDropdown()">
  <button class="btn btn-block dd-toggle-btn"
          [disabled]="disabled"
          [ngClass]="'btn-' + theme"
          (click)="showDropdown()">
    <i class="ssi ssi-arrow-down-correct pull-right"></i>
    <ng-template [ngTemplateOutlet]="labelTemplate"
                 [ngTemplateOutletContext]="{}">
    </ng-template>
  </button>

  <div class="dropdown-select-menu"
       [class.width-extended]="widthExtended"
       *ngIf="dropdownIsVisible"
       [@slideInOut]>

    <div class="dropdown-search-container"
         *ngIf="searchable">
      <label [for]="searchId" class="a11y-label">Search Text:</label>
      <input 
         #searchTextInput
         class="form-control"
         type="search"
         [id]="searchId"
         [name]="searchId"
         [(ngModel)]="searchText"
         (ngModelChange)="updateSelectedItems()"
         [placeholder]="searchPlaceholder"
         [ssiFocusOn]="true"
      >
      <i class="ssi ssi-search-small"></i>
    </div>

    <div class="dropdown-toggle-all"
         *ngIf="toggleAll && multiple">
      <a href="javascript:;"
         (click)="setModelValue(dropdownItems)"
         translate="SELECT_ALL"></a>
      <a href="javascript:;"
         (click)="setModelValue([])"
         translate="DESELECT_ALL"></a>
    </div>

    <div class="dropdown-select-menu-items">
      <button class="dropdown-select-menu-item"
           *ngFor="let item of dropdownItems"
           [class.active]="item.isSelected"
           (click)="toggleItem(item)">
        <ng-template [ngTemplateOutlet]="optionTemplate"
                     [ngTemplateOutletContext]="item">
        </ng-template>
      </button>
      <div class="dropdown-select-menu-item text-center"
           *ngIf="dropdownItems.length === 0 && searchText">
        <em translate="NO_RESULTS_FOUND"></em>
      </div>
    </div>

  </div>
</div>