<div
  class="insights-widget"
  *ngIf="themesData && themesData.length > 0"
>
  <ng-container *ngFor="let theme of themesData">
    <div
      *ngIf="theme.openai_theme"
      class="theme {{theme.sizeClass}}"
      [ngClass]="theme.size >= averageThemeSize ? 'large' : 'small'"
      (click)="openDrilldownModal(theme)"
    >
      <span [ngClass]="theme.sentiment.key">{{theme.openai_theme}}</span>
    </div>
  </ng-container>
</div>

<div
  *ngIf="!themesData || themesData && themesData.length === 0"
  class="no-results-state"
>
  <i class="ssi ssi-no-results-option"></i>
  <h4>No data to display</h4>
  <p>This may be due to filters applied or too many varying themes</p>
</div>