import './social-push-mode-modal.component.scss';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StateService } from '@uirouter/angular';
import {
  ConversationPushModeService,
  AgentStatusID
} from '../../../common/services/conversation-push-mode/conversation-push-mode.service';

@Component({
  selector: 'ssi-social-push-mode-modal',
  templateUrl: './social-push-mode-modal.component.html'
})
export class SocialPushModeModalComponent implements OnInit {
  @Input() isEnabled: boolean;
  @Output() close = new EventEmitter();

  constructor(
    private state: StateService,
    private conversationPushModeService: ConversationPushModeService
  ) {}

  ngOnInit() {}

  changeAgentStatus(status) {
    if (status === AgentStatusID.Online) {
      this.state.go('auth.push');
    }
    this.conversationPushModeService.changeAgentStatus(status);
    this.close.emit();
    if (!this.isEnabled) {
      this.state.go('auth.inbox');
    }
  }
}
