import { ErrorHandler, Injectable } from '@angular/core';
declare var trackJs: any;

@Injectable()
export class TrackJsErrorHandler implements ErrorHandler {
  handleError(error: any) {
    console.error('Runtime Error:', error);
    if ((window as any).trackJs) {
      trackJs.track(error.originalError || error);
    }
  }
}
