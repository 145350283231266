import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetInboxConversationsComponent } from './widget-inbox-conversations.component';
import { InboxQueryResultListModule } from '../../../../../../auth/inbox/inbox-query-result-list/inbox-query-result-list.module';
import { AutoHeightModule } from '../../../../../../../common/directives/auto-height/auto-height.module';
import { LiveChatModule } from '../../../../../../../common/components/live-chat/live-chat/live-chat.module';

@NgModule({
  imports: [
    CommonModule,
    InboxQueryResultListModule,
    AutoHeightModule,
    LiveChatModule
  ],
  exports: [WidgetInboxConversationsComponent],
  declarations: [WidgetInboxConversationsComponent]
})
export class WidgetInboxConversationsModule {}
