import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { SentimentListComponent } from './sentiment-list.component';

@NgModule({
  imports: [CommonModule, TooltipModule],
  declarations: [SentimentListComponent],
  exports: [SentimentListComponent]
})
export class SentimentListModule {}
