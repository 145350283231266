import { Component, OnInit } from '@angular/core';
import {
  User,
  UserModel,
  AccountModel,
  Account,
  API,
  AccountClass
} from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { userAgent } from '../../constants';
import { ServerService } from '../../services/server/server.service';

const LOWEST_SUPPORTED_IE_VERSION = 11;
const LOWEST_SUPPORTED_FF_VERSION = 45; // ESR releases: https://en.wikipedia.org/wiki/Firefox_version_history
const LOWEST_SUPPORTED_CHROME_VERSION = 50; // based on GA usage

const browser = userAgent.getBrowser();

@Component({
  selector: 'ssi-alerts',
  templateUrl: './alerts.component.html'
})
export class AlertsComponent implements OnInit {
  isOldIE =
    browser.name === 'IE' && +browser.major < LOWEST_SUPPORTED_IE_VERSION;

  isUnsupportedFirefox =
    browser.name === 'Firefox' && +browser.major < LOWEST_SUPPORTED_FF_VERSION;

  isUnsupportedChrome =
    browser.name === 'Chrome' &&
    +browser.major < LOWEST_SUPPORTED_CHROME_VERSION;

  overduePayment: any;

  user: User;

  canAdministerAccounts: boolean;

  accountsRequiringReauth: Account[];

  accountsReauthAdvised: Account[];

  telephone: string;

  hideReauthRequiredAlert: boolean;

  hideReauthAdvisedAlert: boolean;

  browserlessMode: boolean = false;

  constructor(
    private state: StateService,
    private userModel: UserModel,
    private server: ServerService,
    private api: API,
    private accountModel: AccountModel
  ) {}

  async ngOnInit() {
    const [
      overdue,
      user,
      accounts,
      server,
      advertisingAccounts
    ] = await Promise.all([
      this.api.get('company/overdue'),
      this.userModel.getAuthUser(),
      this.accountModel.findAccounts(),
      this.server.getSettings(),
      this.accountModel.findAccounts(null, {
        accountClass: AccountClass.Advertising
      })
    ]);

    this.browserlessMode = localStorage.getItem('browserless-io') === 'true';

    this.overduePayment = overdue.data.overdue;
    this.user = user;
    this.canAdministerAccounts = user.hasCompanyPermission(
      'administer_accounts'
    );
    const allAccounts = [...accounts, ...advertisingAccounts];
    this.accountsRequiringReauth = allAccounts.filter(
      (account) => account.is_reauth_required
    );
    this.accountsReauthAdvised = allAccounts.filter(
      (account) => account.is_reauth_advised && !account.is_reauth_required
    );
    this.telephone = server.telephone;
  }

  async markAccountsAsReauthRequired() {
    const promises = this.accountsReauthAdvised.map((account) =>
      account.markAsNeedingReauth()
    );
    await Promise.all(promises);
    this.state.go('auth.reAuthAccount');
  }
}
