<div class="highchart-controls-filter" *ngIf="!reportMode">
    <select class="highchart-controls-filter-select" >
        <option value="clicks" translate="TOTAL_CLICKS"></option>
    </select>
    <i class="ssi ssi-arrow-down-correct highchart-controls-filter-select-icon"></i>
</div>
<ul class="highchart-controls-actions" *ngIf="!showSearch">
    <li [class.highchart-controls-action-active]="display === 'yesterday'"
        class="highchart-controls-action"
        (click)="chartChangeDate.emit({event: $event, timestamp:'yesterday'}); display = 'yesterday'"
        translate="YESTERDAY">
    </li>
    <li [class.highchart-controls-action-active]="display === 'lastWeek'"
        class="highchart-controls-action"
        (click)="chartChangeDate.emit({event: $event, timestamp:'lastWeek'}); display = 'lastWeek'"
        translate="LAST_WEEK">
    </li>
    <li [class.highchart-controls-action-active]="display === 'lastMonth'"
        class="highchart-controls-action highchart-controls-action-active"
        (click)="chartChangeDate.emit({event: $event, timestamp:'lastMonth'}); display = 'lastMonth'"
        translate="LAST_MONTH">
    </li>
    <li [class.highchart-controls-action-active]="display === 'lastQuarter'"
        class="highchart-controls-action"
        (click)="chartChangeDate.emit({event: $event, timestamp:'lastQuarter'}); display = 'lastQuarter'"
        translate="LAST_QUARTER">
    </li>
    <li class="highchart-controls-action"
        (click)="toggleSearchBox()">
        <span translate="CUSTOM"></span>
        <i class="ssi ssi-custom-date highchart-controls-action-icon"></i>
    </li>
</ul>

<ul class="highchart-controls-date-wrap" *ngIf="showSearch">
    <li class="highchart-controls-date-picker form-group">
        <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="chartSearchDates.from"
            (ngModelChange)="chartChangeDateCustom.emit()"
            [altInput]="true"
            [convertModelValue]="true"
            [enableTime]="false"
            [minDate]="chartDefaultStartDate"
            [maxDate]="chartSearchDates.to ? (chartSearchDates.to | addSeconds:-60) : now"
            [time24hr]="true"
            placeholder="Select start date"
            required
            name="start">
        <i class="ssi ssi-plus"></i>
    </li>
    <li class="highchart-controls-date-picker form-group">
        <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="chartSearchDates.to"
            (ngModelChange)="chartChangeDateCustom.emit()"
            [altInput]="true"
            [convertModelValue]="true"
            [enableTime]="false"
            [minDate]="chartSearchDates.from ? (chartSearchDates.from | addSeconds:60) : chartDefaultStartDate"
            [maxDate]="now"
            [time24hr]="true"
            placeholder="Select end date"
            name="end">
        <i class="ssi ssi-plus"></i>
    </li>
    <!-- <li class="highchart-controls-date-action">
        <span class="btn btn-default" (click)="chartChangeDateCustom.emit(); display = 'search'">Search Dates</span>
    </li> -->
    <li class="highchart-controls-date-toggle" (click)="toggleSearchBox()">
        <i class="ssi ssi-toolbar-analyse highchart-controls-date-toggle-icon"></i>
    </li>
</ul>