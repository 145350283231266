import { Component, Input } from '@angular/core';
import './stat-bar-tile.component.scss';

@Component({
  selector: 'ssi-stat-bar-tile',
  templateUrl: './stat-bar-tile.component.html',
  styles: []
})
export class StatBarTileComponent {
  @Input() title: string;
  @Input() stat: string | number;
  @Input() iconClass: string;
  @Input() iconSize?: string | number;
  @Input() progressPercentage?: string;
  @Input() progressColor?: string;

  constructor() {}
}
