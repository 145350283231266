<div #accountSelectorDropdown="ngbDropdown" ngbDropdown autoClose="outside" class="ac-select">
  <button class="btn btn-default ac-select-trigger" ngbDropdownToggle>
    <span *ngIf="selectedAccount" class="pull-left ac-select-trigger-title">
      <i [ngClass]="selectedAccount.socialNetwork.icon.web"></i> {{ selectedAccount.displayName }}
    </span>
    <span *ngIf="!selectedAccount" class="pull-left ac-select-trigger-title">{{ title }}</span>
    <span class="caret"></span>
  </button>
  <div class="ac-dropdown" ngbDropdownMenu>
    <input
      class="form-control ac-filter-input"
      type="text"
      [(ngModel)]="filterTerm"
    >
    <ol class="ac-account-list">
      <ng-container *ngFor="let account of accounts | filterByString: filterTerm | sortBy: sortAccountsFn; trackBy: trackById">
        <li class="ac-item">
          <button
            class="ac-item-trigger"
            [ngClass]="{'ac-item-trigger-selected': selectedAccount?.id === account.id}"
            (click)="accountChange(account)"
          >
            <span>
              <i [ngClass]="account.socialNetwork.icon.web"></i> {{ account.displayName }}
            </span>
            <span class="fa fa-check ac-item-check-mark"></span>
          </button>
        </li>
      </ng-container>
    </ol>
  </div>
</div>
