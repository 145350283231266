import './create-campaign-modal.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../../../../../common/services/api';

@Component({
  selector: 'ssi-create-campaign-modal',
  templateUrl: './create-campaign-modal.component.html'
})
export class CreateCampaignModalComponent implements OnInit {
  @Input() campaigns: Campaign[];
  charactersRemaining: number = 100;
  maxCharacters: number = 100;

  campaignForm: {
    name: string;
    parentCampaign?: Campaign;
    startDate: Date;
    endDate?: Date;
  } = {
    name: '',
    parentCampaign: null,
    startDate: null,
    endDate: null
  };

  campaign: Partial<Campaign> = {};
  parentCampaignsOptions: Campaign[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.parentCampaignsOptions = this.campaigns.filter((c) => !c.parent_id);
  }

  create() {
    this.activeModal.close(this.campaign);
  }

  campaignNameChanged(event: string) {
    this.charactersRemaining = this.maxCharacters - event.length;
    this.campaign.name = event;
  }

  onParentCampaignSelect($event) {
    console.log('onParentCampaignSelect $event:', $event);
    this.campaign.parent_id = $event.id;
  }

  startDateChanged($event) {
    console.log('startDateChanged $event:', $event);
    this.campaign.started_at = $event;
  }
  endDateChanged($event) {
    console.log('endDateChanged $event:', $event);
    this.campaign.closed_at = $event;
  }
}
