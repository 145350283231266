<div class="survey-responses-container">
  <div class="section-head">
    <h2>Survey Responses</h2>
  </div>

  <div class="section-body table-container">
    <ssi-treetable [data]="report?.surveys"
                   [bodyStyle]="{'max-height': ''}"
                   [rowStyle]="{'min-height': '60px'}"
                   [virtualScroll]="false"
                   [resizeMode]="'fit'"
                   [reorderable]="true"
                   [saveSettings]="true"
                   [serverSorting]="true"
                   [settingsStorageKey]="'treetable-survey-analytics-survey-responses'"
                   [globalFilterInputRef]=""
                   (serverSortParamsChange)="onServerSortParamsChange($event)"
                   id="treetable-survey-analytics-survey-summary">

      <ssi-column field="dt"
                  header="DATE"
                  width="20%"
                  [templateRefs]="{bodyCell: dateBodyCellTemplate}"></ssi-column>

      <ssi-column field="recipient_name"
                  header="SURVEYEE"
                  width="20%"
                  [sortable]="false"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>

      <ssi-column field="user.fullName"
                  header="AGENT"
                  [sortable]="false"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>

      <!-- <ssi-column field=""
                  header="TEAM"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column> -->

      <ssi-column field="score"
                  header="SCORE"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>

      <ssi-column field="comment"
                  header="COMMENT"
                  width="30%"
                  [sortable]="false"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>

      <!-- template for treetable options (global filter and/or columns selector) -->
      <!-- <ng-template ssiTemplateSelector="options"
                   let-columns="columns"
                   let-columnsMap="columnsMap">
        <div class="">
          <span class="global-filter">
            <input type="text"
                   id="survey-responses-stats-table-global-filter"
                   placeholder="Filter..."
                   value="">
          </span>
        </div>
      </ng-template> -->

      <!-- template for no results view -->
      <ng-template ssiTemplateSelector="noResults">
        <ssi-no-results *ngIf="!loading"
                        title="No results"
                        description="Why not try a different set of filters?"></ssi-no-results>
      </ng-template>
    </ssi-treetable>

    <ssi-paginator [itemsCount]="itemsCount"
                   [pageLinksSize]="5"
                   [itemsPerPage]="itemsPerPage"
                   [reInitOnChanges]="false"
                   (onPageChange)="onPageChange($event)"></ssi-paginator>
  </div>
</div>

<ng-template #dateBodyCellTemplate
             ssiTemplateSelector="bodyCell"
             let-rowIndex="rowIndex"
             let-rowData="rowData"
             let-column="column"
             let-columnIndex="columnIndex"
             let-columns="columns"
             let-columnsMap="columnsMap"
             let-fieldValue="fieldValue">
  <div class="body-cell stat"
       [class.row-even]="rowIndex % 2 !== 0">
    <span class="bold">{{fieldValue | date: 'dd MMM y'}} | {{fieldValue | date: 'HH:mm'}}</span>
  </div>
</ng-template>

<ng-template #bodyCellTemplate
             ssiTemplateSelector="bodyCell"
             let-rowIndex="rowIndex"
             let-rowData="rowData"
             let-column="column"
             let-columnIndex="columnIndex"
             let-columns="columns"
             let-columnsMap="columnsMap"
             let-fieldValue="fieldValue">
  <div class="body-cell stat"
       [class.row-even]="rowIndex % 2 !== 0">
    <span [class.bold]="column.field === 'date' || column.field === 'score' || column.field === 'comment'">{{fieldValue}}</span>
  </div>
</ng-template>