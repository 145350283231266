import './email-to-modal.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Activity, ActivityThread, API } from '@ui-resources-angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ssi-email-to-modal',
  templateUrl: './email-to-modal.component.html'
})
export class EmailToModalComponent implements OnInit {
  @Input() activity: Activity;
  @Input() activityThread: ActivityThread;

  sendToForm: FormGroup;
  threadActivities: any[];
  selectedActivityIds: string[] = [];
  recentEmails: string[] = [];
  sending = false;

  constructor(public activeModal: NgbActiveModal, private api: API) {}

  ngOnInit() {
    let activities: Activity[];
    if (this.activityThread) {
      activities = [
        this.activityThread.thread.current,
        ...this.activityThread.thread.children.activities,
        ...this.activityThread.thread.siblings.newer.activities,
        ...this.activityThread.thread.siblings.older.activities,
        ...(this.activityThread.thread.children.hiddenActivities || []),
        ...(this.activityThread.thread.siblings.newer.hiddenActivities || []),
        ...(this.activityThread.thread.siblings.older.hiddenActivities || [])
      ];

      this.selectedActivityIds.push(this.activityThread.thread.root.id);
    } else {
      activities = [this.activity];
      this.selectedActivityIds.push(this.activity.id);
    }

    function sortByCreated(a, b) {
      const dateA = new Date(a.interaction.created_at).getTime();
      const dateB = new Date(b.interaction.created_at).getTime();
      return dateA > dateB ? 1 : -1;
    }

    this.threadActivities = activities.sort(sortByCreated);

    this.sendToForm = new FormGroup({
      emailAddress: new FormControl('', [Validators.required]),
      emailBody: new FormControl(
        'Please take a look at this social post and get back to me with your thoughts...',
        [Validators.maxLength(2000)]
      ),
      sendCopy: new FormControl(false)
    });

    const storedRecentEmails = window.localStorage.getItem(
      'orlo-recent-emails'
    );
    if (storedRecentEmails) {
      this.recentEmails = storedRecentEmails.split(',');
    }
  }

  async submit() {
    if (this.sending) {
      return;
    }

    const emails = this.sendToForm.value.emailAddress.split(', ');
    window.localStorage.setItem(
      'orlo-recent-emails',
      emails.slice(Math.max(emails.length - 3, 0))
    );

    this.sending = true;
    try {
      await this.api.post('activity/email_v2', {
        activity_ids: this.selectedActivityIds,
        message: this.sendToForm.value.emailBody,
        send_copy: this.sendToForm.value.sendCopy,
        to_email: this.sendToForm.value.emailAddress.split(', ')
      });

      this.activeModal.close();
    } catch (e) {
      console.error(e);
    }
    this.sending = false;
  }

  selectActivity(activity) {
    if (activity.id.includes('outbox')) {
      return;
    }
    const index = this.selectedActivityIds.findIndex(
      (selectedId) => selectedId === activity.id
    );
    index > -1
      ? this.selectedActivityIds.splice(index, 1)
      : this.selectedActivityIds.push(activity.id);
  }

  selectAllActivities() {
    this.selectedActivityIds = this.threadActivities.map(
      (activity) => activity.id
    );
    this.selectedActivityIds = this.selectedActivityIds.filter(
      (activity) => activity.includes('outbox') === false
    );
  }

  isActivitySelected(activity) {
    return this.selectedActivityIds.find(
      (selectedId) => selectedId === activity.id
    );
  }

  isOutboundMessage(activity): boolean {
    return String(activity.author.id) !== String(this.activity.author.id);
  }

  parseMessage(activityContent: string) {
    return (activityContent || '').replace(/<[^>]*>?/gm, '');
  }

  addEmail(email) {
    this.sendToForm.patchValue({
      emailAddress: `${this.sendToForm.value.emailAddress}${
        this.sendToForm.value.emailAddress ? ', ' : ''
      }${email}`
    });
  }
}
