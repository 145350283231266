<div class="cw-field">
  <div class="cw-field-trigger-wrap">
    <ng-container *ngIf="chosenMeasure">
      <button
        class="cw-field-trigger cw-field-trigger-meta"
        [ngClass]="{
          'cw-field-trigger-active': selectedField,
          'cw-field-trigger-pointer': fields[selectedField].aggregateFunctions.length <= 1
        }"
        (click)="fields[selectedField].aggregateFunctions.length > 1 && isDropdownMetaOpen = true"
      >
        <p class="cw-field-trigger-label">{{AggregateLabels[chosenMeasure]}}</p>
        <i
          *ngIf="fields[selectedField].aggregateFunctions.length > 1"
          class="ssi ssi-arrow-down-new cw-field-trigger-icon"
        ></i>
      </button>
        <div
          *ngIf="isDropdownMetaOpen"
          class="cw-dropdown cw-dropdown-meta"
          (clickOutside)="isDropdownMetaOpen = false"
          [delayClickOutsideInit]="true"
        >
        <ul class="cw-dropdown-list">
          <li
            *ngFor="let measureItem of fields[selectedField].aggregateFunctions"
            class="cw-popup-dropdown-menu-item"
          >
            <input
              type="radio"
              [id]="measureItem"
              [value]="measureItem"
              [(ngModel)]="measure"
              (ngModelChange)="saveMeasure(measure)"
            >
            <label [for]="measureItem">{{AggregateLabels[measureItem]}}</label>
            <i class="ssi ssi-tick-small cw-dropdown-list-item-check"></i>
          </li>
        </ul>
      </div>
    </ng-container>
    
    <button
      class="cw-field-trigger"
      [class.cw-field-trigger-active]="!!selectedField"
      (click)="isDropdownOpen = true"
    >
      <p class="cw-field-trigger-label">
        {{selectedField ? fields[selectedField].label : 'Select singular field...'}}
      </p>
      <i class="ssi ssi-arrow-down-new cw-field-trigger-icon"></i>
    </button>
    <div
      *ngIf="isDropdownOpen"
      class="cw-dropdown"
      (clickOutside)="isDropdownOpen = false"
      [delayClickOutsideInit]="true"
    >
      <div class="cw-dropdown-filter-wrap">
        <label class="hidden" for="cwDropdownFilter">Filter Labels</label>
        <input
          id="cwDropdownFilter"
          class="cw-dropdown-filter"
          [(ngModel)]="searchTerm"
          placeholder="Search fields..."
          type="text">
      </div>
      <ul class="cw-dropdown-list">
        <ng-container *ngFor="let field of fieldsIterable">
          <li
            *ngIf="field[fieldOption.type] && (!searchTerm || field.key.toLowerCase().includes(searchTerm.toLowerCase()))"
            class="cw-dropdown-list-item"
          >
            <input
              type="radio"
              name="field"
              [id]="field.id"
              [value]="field.key"
              [(ngModel)]="selectedField"
              (ngModelChange)="onFieldSelected()"
              (click)="onFieldClicked(field)"
            >
            <label [for]="field.id">{{field.label}}</label>
            <i class="ssi ssi-tick-small cw-dropdown-list-item-check"></i>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
