import './custom-widget.component.scss';
import { flatten, orderBy, uniq } from 'lodash-es';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { mapToIterable } from '../../../../../common/utils';
import { widgetTypes, WidgetTypes } from '../../common/constants';
import { Colleague, Team } from '../../../../../common/services/api';
import { commonDateRanges, DateRanges } from '../../../../../common/constants';
import { Account, User } from '@ui-resources-angular';
import {
  dedupeAndMergeFiltersForAPI,
  InsightsService,
  InsightsWidget,
  Measure,
  networksWithInsightsSources,
  sanitizeFiltersForAPI,
  Schema
} from '../../insights.service';
import { TranslateService } from '@ngx-translate/core';
import { Filter } from '../../reports/view/view-report.component';
import {
  GlobalFiltersStyleMode,
  GlobalFiltersFunctionalityMode
} from '../../common/components/global-filters/global-filters.component';
import { InsightsFiltersService } from '../../common/components/global-filters/insights-filters.service';
import { InsightsFilter } from '../../common/components/global-filters/global-filters.component';

export enum Stage {
  Build = 'build',
  Filter = 'filter'
}

export enum ConfigType {
  Dimension = 'dimension',
  Measure = 'measure',
  Filter = 'filters'
}

export interface ConfigOptions {
  name?: string;
  label?: string;
  type?: ConfigType;
  required?: boolean;
  multivalued?: boolean;
  default?: string;
  disableMeasure?: boolean;
}

export interface ChartOption {
  key: string;
  widgetKey: string;
  label: string;
  icon: string;
  disabled?: boolean;
  refreshOnNameChange: boolean;
  configOptions: ConfigOptions[];
}

export interface ChartOptions {
  bar: ChartOption;
  line: ChartOption;
  donut: ChartOption;
  pie: ChartOption;
  kpi: ChartOption;
  // sentiment_gauge: ChartOption;
  // swot: ChartOption;
  // big: ChartOption;
}

export enum AggregateLabels {
  MIN = 'Minimum',
  MAX = 'Maximum',
  SUM = 'Total',
  AVG = 'Average',
  COUNT = 'Total'
}

@Component({
  selector: 'ssi-custom-widget',
  templateUrl: './custom-widget.component.html',
  styles: []
})
export class CustomWidgetComponent implements OnInit {
  activeStageTab = Stage.Build;
  stages = Stage;
  isLoadingChart = false;
  showWidget = false;
  showChartChangeConfirmation = false;
  clickedChartOption = '';
  formInvalid = true;
  isFormDirty = false;
  updateDisabled = true;
  chartType = widgetTypes.bar.key;
  globalFiltersModel;

  widgets: InsightsWidget[] = [];
  schema: Schema;

  widgetTypes: WidgetTypes = widgetTypes;
  chartOptions: ChartOptions = {
    bar: {
      key: 'bar',
      widgetKey: widgetTypes.bar.key,
      label: 'Bar',
      icon: 'ssi ssi-bar-chart-vertical',
      refreshOnNameChange: false,
      configOptions: [
        {
          name: 'y_axis',
          label: 'Report on',
          type: ConfigType.Measure,
          required: false,
          multivalued: false,
          default: 'ID'
        },
        {
          name: 'x_axis',
          label: 'By',
          type: ConfigType.Dimension,
          required: true,
          disableMeasure: true
        },
        {
          name: 'break_down_by',
          label: 'Broken down by',
          type: ConfigType.Dimension,
          required: false,
          multivalued: false,
          disableMeasure: true
        }
      ]
    },
    line: {
      key: 'line',
      widgetKey: widgetTypes.line.key,
      label: 'Line',
      icon: 'ssi ssi-line-chart',
      refreshOnNameChange: false,
      configOptions: [
        {
          name: 'y_axis',
          label: 'Report on',
          type: ConfigType.Measure,
          required: false,
          default: 'ID'
        },
        {
          name: 'x_axis',
          label: 'By',
          type: ConfigType.Dimension,
          required: true,
          multivalued: false,
          disableMeasure: true
        }
      ]
    },
    donut: {
      key: 'donut',
      widgetKey: widgetTypes.donut.key,
      label: 'Donut',
      icon: 'ssi ssi-doughnut-chart',
      refreshOnNameChange: false,
      configOptions: [
        {
          name: 'value',
          label: 'Report on',
          type: ConfigType.Measure,
          required: false,
          multivalued: false,
          default: 'ID'
        },
        {
          name: 'break_down_by',
          label: 'Broken down by',
          type: ConfigType.Dimension,
          required: true,
          disableMeasure: true
        }
      ]
    },
    pie: {
      key: 'pie',
      widgetKey: widgetTypes.pie.key,
      label: 'Pie',
      icon: 'ssi ssi-pie-chart-vis',
      refreshOnNameChange: false,
      configOptions: [
        {
          name: 'value',
          label: 'Report on',
          type: ConfigType.Measure,
          required: false,
          multivalued: false,
          default: 'ID'
        },
        {
          name: 'break_down_by',
          label: 'Broken down by',
          type: ConfigType.Dimension,
          required: true,
          disableMeasure: true
        }
      ]
    },
    kpi: {
      key: 'kpi',
      widgetKey: widgetTypes.kpi.key,
      label: 'KPI',
      icon: 'ssi ssi-totals-chart',
      refreshOnNameChange: true,
      configOptions: [
        {
          name: 'values',
          label: 'Report on',
          type: ConfigType.Measure,
          required: false,
          multivalued: true,
          default: 'ID'
        },
        {
          name: 'group_by',
          label: 'Grouped By',
          type: ConfigType.Dimension,
          required: false,
          disableMeasure: true
        }
      ]
    }
    // sentiment_gauge: {
    //   key: 'sentiment_gauge',
    //   widgetKey: widgetTypes.sentiment.key,
    //   label: 'Sentiment Gauge',
    //   icon: 'ssi ssi-gauge-chart',
    //   configOptions: []
    // },
    // swot: {
    //   key: 'swot',
    //   widgetKey: widgetTypes.swot.key,
    //   label: 'SWOT',
    //   icon: 'ssi ssi-swot-chart',
    //   configOptions: []
    // },
    // big: {
    //   key: 'big',
    //   widgetKey: widgetTypes.big.key,
    //   label: 'Theme map',
    //   icon: 'ssi ssi-theme-mapping',
    //   configOptions: [
    //     // {name: 'categories', label: 'Categories', type: ConfigType.Filter, required: true},
    //     {name: 'values', label: 'Values', type: ConfigType.Measure, required: false, multivalued: true},
    //     {name: 'break_down_by', label: 'Broken down by', type: ConfigType.Dimension, required: true}
    //   ]
    // }
  };
  chartOptionsIterable = mapToIterable(this.chartOptions);
  configOptionsAllNames: string[] = [];

  formData = {
    type: widgetTypes.bar.key,
    name: '',
    description: '',
    display_properties: {
      x: 0,
      y: 0,
      w: widgetTypes.bar.defaultSize.w,
      h: widgetTypes.bar.defaultSize.h
    },
    filters: [],
    x_axis: null,
    break_down_by: null,
    y_axis: null,
    typeConst: widgetTypes.bar
  };
  widgetData: any = {};
  dateRanges: DateRanges = commonDateRanges;
  activeFilters: Filter[] = []; // USED BY API
  overrideChartSize = { w: 0, h: 0 };

  GlobalFiltersStyleMode = GlobalFiltersStyleMode;
  GlobalFiltersFunctionalityMode = GlobalFiltersFunctionalityMode;

  @Input() colleagues: Colleague[];
  @Input() teams: Team[];
  @Input() workflowAccounts: Account[];
  @Input() streams;
  @Input() authUser: User[];

  @Output() widgetAdded = new EventEmitter<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private insightsService: InsightsService,
    private translate: TranslateService,
    private insightsFiltersService: InsightsFiltersService
  ) {}

  async ngOnInit() {
    this.widgets = await this.insightsService.getWidgets();
    this.schema = await this.insightsService.getInsightSchema();
    this.globalFiltersModel = await this.insightsFiltersService.generateInsightsFiltersModel();
    this.workflowAccounts = orderBy(this.workflowAccounts, [
      'account_type_name',
      'name'
    ]);

    this.configOptionsAllNames = uniq(
      flatten(
        this.chartOptionsIterable.map((chartOption) =>
          chartOption.configOptions.map((configOption) => configOption.name)
        )
      )
    );
  }

  updateWidget() {
    this.isLoadingChart = true;
    if (Object.keys(this.widgetData).length === 0) {
      this.showWidget = true;
      this.widgetData = Object.assign({}, this.formData);
      this.widgetData.filters = sanitizeFiltersForAPI(this.activeFilters);
      this.widgetData.filters = dedupeAndMergeFiltersForAPI(
        this.widgetData.filters
      );
      if (this.chartType === 'big') {
        // big loaded issue
        this.isLoadingChart = false;
      }
    } else {
      this.showWidget = false;
      setTimeout(() => {
        this.widgetData = Object.assign({}, this.formData);
        this.widgetData.filters = sanitizeFiltersForAPI(this.activeFilters);
        this.widgetData.filters = dedupeAndMergeFiltersForAPI(
          this.widgetData.filters
        );
        this.showWidget = true;
        if (this.chartType === 'big') {
          // big loaded issue
          this.isLoadingChart = false;
        }
      }, 2000); // workaround for highcharts update rendering issue
    }
    this.formInvalid = this._isFormInvalid();
  }

  saveWidget() {
    if (this.overrideChartSize.w !== 0) {
      this.widgetData.display_properties.w = this.overrideChartSize.w;
      this.widgetData.display_properties.h = this.overrideChartSize.h;
    }
    if (this.formData.name) {
      this.widgetData.name = this.formData.name;
    }
    this.widgetAdded.emit(this.widgetData);
    this.activeModal.close(null);
  }

  onWidgetNameUpdate() {
    this.formInvalid = this._isFormInvalid();
    if (
      this.widgetData.type &&
      this.chartOptions[this.chartType].refreshOnNameChange
    ) {
      this.updateWidget();
    }
  }

  onChartTypeClick(event: MouseEvent, chartKey: string) {
    this.clickedChartOption = chartKey;
    if (this.isFormDirty) {
      event.preventDefault();
      this.showChartChangeConfirmation = true;
    }
  }

  onChartTypeChange() {
    this.isFormDirty = false;
    this.configOptionsAllNames.forEach(
      (configOptionName) => delete this.formData[configOptionName]
    );
    this.chartOptions[this.chartType].configOptions.forEach(
      (configOption) =>
        (this.formData[configOption.name] = configOption.multivalued
          ? []
          : null)
    );
    if (this.chartType === 'big') {
      this.formData['categories'] = [];
    }

    this.formData.type = this.chartOptions[this.chartType].widgetKey;
    this.formData.typeConst = this.widgetTypes[this.formData.type];
    Object.assign(
      this.formData.display_properties,
      this.widgetTypes[this.formData.type].defaultSize
    );
    this.updateDisabled = this._isChartFromInvalid();
  }

  onFieldOptionChange(chosenFieldOption: Measure, fieldChanged: string) {
    this.isFormDirty = true;
    const isMultiValuedField = this.chartOptions[
      this.chartType
    ].configOptions.find((configOption) => configOption.name === fieldChanged)
      .multivalued;
    if (isMultiValuedField) {
      // ** multivalued fields not supported in builder yet
      // this.formData[fieldChanged].push(chosenFieldOption);
      this.formData[fieldChanged] = [chosenFieldOption];
    } else {
      this.formData[fieldChanged] = chosenFieldOption;
    }
    this.updateDisabled = this._isChartFromInvalid();
  }

  onKeywordFilter(search) {
    // Keyword functionality is kept seperate from the globalFiltersModel to avoid contention in the view report file
    for (const filter of this.activeFilters) {
      if (filter.key !== 'keyword') {
        continue;
      } else if (!search.tags.includes(filter.all)) {
        // remove keywords that aren't selected
        this.activeFilters.splice(
          this.activeFilters.findIndex(
            (activeFilter) => filter.all === activeFilter.all
          ),
          1
        );
      }
    }

    search.tags.map((tag) => {
      if (
        !this.activeFilters.find((filter) => filter.label === `Keyword: ${tag}`)
      ) {
        this.activeFilters.push({
          key: 'keyword',
          label: `Keyword: ${tag}`,
          field: 'String',
          all: tag,
          [search.condition]: tag
        });
      }
    });

    this.activeFilters = Object.assign([], this.activeFilters);
  }

  async onApplyWidgetFilters(filters: InsightsFilter) {
    console.log('APPLIED FILTERS', filters);
    this.globalFiltersModel = filters;
    const activeFilters = await this.insightsFiltersService.getOnlyActiveFilters(
      filters
    );

    const keywordFilters = this.activeFilters.filter(
      (filter) => filter.key === 'keyword'
    );

    this.activeFilters = activeFilters;
    // keywords come from report action bar only currently so need to be seperated from existing filter model so they're not overwritten.
    this.activeFilters = this.activeFilters.concat(keywordFilters);

    this.activeStageTab = this.stages.Build;

    if (!this.updateDisabled) {
      this.updateWidget();
    }
  }

  private _isFormInvalid(): boolean {
    if (this.formData.name.length === 0) {
      return true;
    }
    if (this._isChartFromInvalid()) {
      return true;
    }
    return false;
  }

  private _isChartFromInvalid(): boolean {
    const requiredOptions = this.chartOptions[
      this.chartType
    ].configOptions.filter((configOption) => configOption.required);
    if (
      requiredOptions.filter((requiredOption) => requiredOption.required).length
    ) {
      return requiredOptions.every(
        (requiredOption) => !this.formData[requiredOption.name]
      );
    }
    return false;
  }
}
