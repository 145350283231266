import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmPersonTagsComponent } from './crm-person-tags.component';
import { TranslateModule } from '@ngx-translate/core';
import { TagsInputModule } from '../tags-input/tags-input.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TranslateModule, TagsInputModule, FormsModule],
  declarations: [CrmPersonTagsComponent],
  exports: [CrmPersonTagsComponent]
})
export class CrmPersonTagsModule {}
