<div class="cl-dropdown-select-f-container">
  <h4>F</h4>
  <a target="_blank"
     href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-dropdown-select/cl-dropdown-select-f/cl-dropdown-select-f.component.html">
    View code on GitHub
  </a>
  <p>headButton template</p>
  <p>MD size - multi-select version; emptyState and bodyFooter templates</p>

  <div class="accounts-select-container">
    <ssi-dropdown-select-2 #accountsSelector
                           size="md"
                           name="accounts"
                           [required]="true"
                           [multiple]="true"
                           [filterable]="true"
                           [toggleAllEnabled]="false"
                           [placeholdersKeyword]="'accounts'"
                           [options]="accounts"
                           [(ngModel)]="selectedAccounts"
                           (ngModelChange)="selectedAccountsChanged($event)"
                           (optionsFiltered)="onOptionsFiltered($event)">
      <ng-template ssiTemplateSelector="headButton"
                   let-expanded="expanded"
                   let-toggle="toggle">
        <button type="button"
                class="accounts-selector-btn"
                (click)="toggle()">
          <i class="ssi ssi-tags"></i>
        </button>
      </ng-template>

      <ng-template ssiTemplateSelector="emptyState"
                   let-toggle="toggle">
        <div class="no-accounts-state"
             *ngIf="!accountsSelector.filterTerm">
          <i class="ssi ssi-tags"></i>
          <p class="title">No Accounts yet!</p>
          <p class="desc">Just start typing then create your new account</p>
        </div>
      </ng-template>

      <ng-template ssiTemplateSelector="bodyFooter"
                   let-toggle="toggle">
        <div class="no-accounts-state"
             *ngIf="accountsSelector.filterTerm && createTagButtonVisible">
          <button type="button"
                  class="g-btn"
                  *ngIf="accountsSelector.filterTerm"
                  (click)="create(accountsSelector.filterTerm)"><span>Create "{{accountsSelector.filterTerm}}"</span><i class="ssi ssi-plus"></i></button>
        </div>
      </ng-template>
    </ssi-dropdown-select-2>

    <div class="selected-accounts-count">
      <span>{{selectedAccounts.length}}</span>
    </div>
  </div>

</div>