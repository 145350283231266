import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../store';
import { ApiService } from '../api.service';
import { ListeningGroup, ListeningPage } from './listening-stream.model';
import { ColleaguesService, Colleague } from '../colleagues';
import { UserModel } from '@ui-resources-angular';

@Injectable({ providedIn: 'root' })
export class ListeningStreamService {
  store = new Store<ListeningPage>(ListeningPage, 'social_id');
  groupsStore = new Store<ListeningGroup>(ListeningGroup);

  constructor(
    protected api: ApiService,
    protected colleaguesService: ColleaguesService,
    protected userModel: UserModel
  ) {}

  listGroups(opts = { refreshStore: false }): Promise<ListeningGroup[]> {
    const endpoint = `${this.api.url}/insights/insightsListeningGroup`;

    if (this.groupsStore.value.length && !opts.refreshStore) {
      return Promise.resolve(this.groupsStore.value);
    }

    return this.api
      .get(endpoint)
      .pipe(
        map((response: Array<{ id: string; name: string }>) => {
          this.groupsStore.value = response;
          return this.groupsStore.value;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  //

  async createGroup(req: { name: string }) {
    const endpoint = `${this.api.url}/insights/insightsListeningGroup`;
    const reqParams = {
      name: req.name
    };

    return this.api
      .post(endpoint, reqParams)
      .pipe(
        map((response: { success: boolean; id: string }) => {
          this.groupsStore.add({ id: response.id, name: req.name });
          return this.groupsStore.find(response.id);
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async updateGroup(req: { name: string; id: string }) {
    const endpoint = `${this.api.url}/insights/insightsListeningGroup?id=${req.id}`;
    const reqParams = {
      name: req.name
    };

    return this.api
      .put(endpoint, reqParams)
      .pipe(
        map((response: { success: boolean }) => {
          this.groupsStore.update({ id: req.id, name: req.name });
          return this.groupsStore.find(req.id);
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async deleteGroup(req: { id: string }) {
    const endpoint = `${this.api.url}/insights/insightsListeningGroup?id=${req.id}`;

    return this.api
      .delete(endpoint)
      .pipe(
        map((response: { success: boolean }) => {
          this.groupsStore.remove(req.id);
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async addPageToGroup(req: {
    listening_group_id: string;
    account_type_id: number;
    social_id: string;
    username: string;
    name: string;
  }) {
    const endpoint = `${this.api.url}/insights/insightsListeningGroupMember`;
    const reqParams = {
      listening_group_id: req.listening_group_id,
      account_type_id: req.account_type_id,
      social_id: req.social_id,
      username: req.username,
      name: req.name
    };
    const authUser = await this.userModel.getAuthUser();

    return this.api
      .post(endpoint, reqParams)
      .pipe(
        map((response: { success: boolean }) => {
          this.store.add({
            id: req.social_id,
            listening_group_id: req.listening_group_id,
            account_type_id: req.account_type_id,
            social_id: req.social_id,
            username: req.username,
            name: req.name,
            created_at: new Date().toISOString(),
            created_by: Number(authUser.id),
            createdBy: authUser
          });
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async removePageFromGroup(req: {
    listening_group_id: string;
    account_type_id: number;
    social_id: string;
  }) {
    const endpoint = `${this.api.url}/insights/insightsListeningGroupMember?listening_group_id=${req.listening_group_id}&account_type_id=${req.account_type_id}&social_id=${req.social_id}`;

    return this.api
      .delete(endpoint)
      .pipe(
        map((response: { success: boolean }) => {
          this.store.remove(req.social_id);
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async listPagesInGroup(groupId: string, opts = { refreshStore: false }) {
    const endpoint = `${this.api.url}/insights/insightsListeningGroupMember?listening_group_id=${groupId}`;

    if (this.store.value.length && !opts.refreshStore) {
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(endpoint)
      .pipe(
        map((response: Array<ListeningPage>) => {
          this.store.value = response;
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
