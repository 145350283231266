import './link-clicks-section.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import Highcharts, { Options } from 'highcharts';

import { Account } from '@ui-resources-angular';
import { HighchartsHelperService } from '../../../../../common/services/highcharts-helper/highcharts-helper.service';
import {
  NetworkSelectItem,
  networkSelectItems
} from '../../common/social-network-select/social-network-select.component';
import { AnalyticsService, LinkClicksData } from '../../analytics.service';

@Component({
  selector: 'ssi-link-clicks-section',
  templateUrl: './link-clicks-section.component.html',
  styles: []
})
export class LinkClicksSectionComponent implements OnInit {
  @Input() clicksData: LinkClicksData;
  @Input() filterPeriod: { start: string; end: string };
  @Input() campaignIds?: string[];
  @Input() accounts?: Account[];
  @Input() mapMarkers?: { lat: number; lng: number }[];
  @Input() isMarketingReport: boolean;
  @Input() tagsToInclude: string[];
  @Input() tagsToExclude: string[];

  loading = false;
  viewClicksOnPosts = false;
  chartOptions: Highcharts.Options;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  constructor(
    private highchartsHelper: HighchartsHelperService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (this.accounts) {
      this.selectableNetworks = networkSelectItems.filter((n) => {
        return this.accounts.some((a) => a.account_type_id === n.key);
      });
      this.selectableNetworks.unshift(networkSelectItems[0]);
    }

    this.chartOptions = this.formatDataForLinkClicksGraph();
  }

  async onNetworkSelect(nsi: NetworkSelectItem) {
    const clicksData = await this.loadLinkClicksData(nsi);
    this.selectedNetwork = nsi;
    if (clicksData) {
      this.clicksData = clicksData;
      this.chartOptions = this.formatDataForLinkClicksGraph();
      this.mapMarkers = this.clicksData.mapMarkers;
    }
  }

  async onViewClicksOnPostsToggled(checked: boolean) {
    const clicksData = await this.loadLinkClicksData(this.selectedNetwork);
    if (clicksData) {
      this.clicksData = clicksData;
      this.chartOptions = this.formatDataForLinkClicksGraph();
      this.mapMarkers = this.clicksData.mapMarkers;
    }
  }

  async loadLinkClicksData(nsi: NetworkSelectItem): Promise<LinkClicksData> {
    this.loading = true;
    try {
      let clicksData;
      if (this.accounts) {
        const accountIds =
          nsi.key === 'all'
            ? this.accounts.map((a) => a.id)
            : this.accounts
                .filter((a) => a.account_type_id === nsi.key)
                .map((a) => a.id);

        clicksData = await this.analyticsService.getLinkClicksData(
          this.filterPeriod,
          this.campaignIds,
          undefined,
          accountIds,
          this.viewClicksOnPosts,
          this.tagsToInclude,
          this.tagsToExclude
        );
      } else {
        clicksData = await this.analyticsService.getLinkClicksData(
          this.filterPeriod,
          this.campaignIds,
          nsi.key === 'all' ? undefined : [Number(nsi.key) as any],
          undefined,
          this.viewClicksOnPosts,
          this.tagsToInclude,
          this.tagsToExclude
        );
      }

      this.loading = false;
      return clicksData;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);

      this.loading = false;
      return undefined;
    }
  }

  formatDataForLinkClicksGraph(): Options {
    return {
      chart: {
        type: 'areaspline',
        spacing: [60, 40, 20, 40],
        zoomType: 'x'
      },
      title: {
        text: null
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolSize: 15,
            symbolStroke: '#838EAB',
            x: 25,
            y: -35
          }
        }
      },
      legend: {
        useHTML: true,
        itemStyle: {
          color: '#43537F',
          textTransform: 'uppercase',
          fontSize: '10px',
          letterSpacing: '1px'
        },
        margin: 40
      },
      xAxis: {
        type: 'datetime',
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: 'Link clicks',
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          },
          margin: 30
        },
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            textTransform: 'lowercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        alternateGridColor: '#F8F9FD'
      },
      series: [
        {
          name: 'Clicks per day',
          color: '#e2b928',
          type: 'areaspline',
          fillColor: 'rgba(240, 180, 39, 0.4)',
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.clicksData.response.by_datetime,
            this.filterPeriod
          )
        }
      ],
      time: {
        useUTC: false
      },
      credits: {
        enabled: false
      }
    };
  }
}
