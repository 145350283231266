import './multi-select-chips.component.scss';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { sources } from '../../../monitoring-streams/monitoring-streams.service';
import { mapToIterable } from '../../../../../../common/utils';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'ssi-multi-select-chips',
  templateUrl: './multi-select-chips.component.html',
  styles: []
})
export class MultiSelectChipsComponent implements OnInit {
  @Input() selectedSources: string[] = [];
  @Input() providedSources: {
    [key: string]: {
      id: string;
      label: string;
      icon: string;
      color: string;
      name: string;
      hideOption: boolean;
    };
  };
  @Output() onSelectedSourcesChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  filterTerm = '';
  sources;
  sourcesIterable = mapToIterable(sources);
  sourcesToRender = [];
  allSelected = false;

  constructor() {}

  ngOnInit() {
    if (this.providedSources) {
      this.sources = this.providedSources;
      this.sourcesIterable = mapToIterable(this.providedSources);
      console.log('sources:', this.sources);
    } else {
      this.sources = sources;
    }
    this.sourcesToRender = this.sourcesIterable.filter(
      (source) => !source.hideOption
    );
    if (this._checkIfAllSelected()) {
      this.allSelected = true;
    }
  }

  onFilterTermChange() {
    this.sourcesToRender = this.sourcesIterable
      .filter((source) => !source.hideOption)
      .filter(
        (source) =>
          source.label.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1
      );
  }

  toggleSelectedSource(source) {
    if (this.selectedSources.includes(source)) {
      this.selectedSources.splice(this.selectedSources.indexOf(source), 1);
      this.allSelected = false;
    } else {
      this.selectedSources.push(source);
      if (this._checkIfAllSelected()) {
        this.allSelected = true;
      }
    }
    this.onSelectedSourcesChange.emit(this.selectedSources);
  }

  toggleSelectAllSource() {
    if (this._checkIfAllSelected()) {
      this.selectedSources = [];
      this.allSelected = false;
    } else {
      this.selectedSources = this.sourcesIterable
        .filter((source) => !source.hideOption)
        .map((source) => source.name);
      this.allSelected = true;
    }
    this.onSelectedSourcesChange.emit(this.selectedSources);
  }

  private _checkIfAllSelected(): boolean {
    if (!this.selectedSources) {
      return false;
    }
    return isEqual(
      this.selectedSources.sort(),
      this.sourcesIterable
        .filter((source) => !source.hideOption)
        .map((source) => source.name)
        .sort()
    );
  }
}
