import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../../icon/icon.module';

import { MessageActionsInlineComponent } from './message-actions-inline.component';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { ContextMenuModule } from '../../context-menu/context-menu.module';
import { ConfirmActionModule } from '../../../directives/tooltip/confirm-action/confirm-action.module';
import { OffClickModule } from '../../../directives/off-click/off-click.module';
import { InboxPriorityModule } from '../../inbox-priority/inbox-priority.module';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    TooltipModule,
    TranslateModule,
    IconModule,
    ContextMenuModule,
    ConfirmActionModule,
    OffClickModule,
    InboxPriorityModule
  ],
  declarations: [MessageActionsInlineComponent],
  exports: [MessageActionsInlineComponent]
})
export class MessageActionsInlineModule {}
