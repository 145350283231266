<div class="suggested-text-modal-container">
  <i class="ssi ssi-delete-close close-icon"
     (click)="ignore()"></i>

  <div class="head"
       *ngIf="suggestedText">
    <h3>Add suggested post text?</h3>
  </div>
  <div class="head"
       *ngIf="mediaNote && !suggestedText">
    <h3>Media Guidance</h3>
  </div>

  <div class="body">
    <ng-container *ngIf="mediaNote">
      <p class="info">
        Heads up! There is some media guidance attached to this file you have selected:
      </p>
      <h5>MEDIA GUIDANCE:</h5>
      <p class="info highlighted">
        "{{mediaNote}}"
      </p>
    </ng-container>

    <hr *ngIf="suggestedText && mediaNote">

    <ng-container *ngIf="suggestedText">
      <p class="info"
         [class.g-margin-top-40]="!!mediaNote">
        The file you selected has the following suggested post text below, you
        can either add this suggested text to you existing post, or overwrite your post entirely which will replace any
        text you have currently typed.
      </p>
      <h5>SUGGESTED POST TEXT:</h5>
      <p class="info highlighted primary">
        "{{suggestedText}}"
      </p>
    </ng-container>
  </div>

  <div class="foot"
       *ngIf="suggestedText">
    <div class="left">
      <div class="addl-footer-content-container">
        <button class="g-btn g-btn-secondary text-only-button underlined"
                (click)="ignore()"><span>IGNORE SUGGESTION</span></button>
      </div>
    </div>

    <div class="right">
      <button class="g-btn g-btn-secondary bg-white"
              (click)="overwrite()"><span>Overwrite current post</span></button>
      <button class="g-btn"
              (click)="addToPost()"><span>Add to post</span></button>
    </div>
  </div>

  <div class="foot"
       *ngIf="mediaNote && !suggestedText">
    <div class="left">
    </div>
    <div class="right">
      <button class="g-btn"
              (click)="ignore()"><span>Okay thanks!</span></button>
    </div>
  </div>
</div>