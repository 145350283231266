import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LiveChatConversationDashboardModule } from '../live-chat-conversation-dashboard/live-chat-conversation-dashboard.module';
import { LiveChatTeamDashboardModule } from '../live-chat-team-dashboard/live-chat-team-dashboard.module';
import { LiveChatDashboardComponent } from './live-chat-dashboard.component';
import './live-chat-dashboard.scss';

@NgModule({
  declarations: [LiveChatDashboardComponent],

  exports: [LiveChatDashboardComponent],

  imports: [
    CommonModule,
    LiveChatConversationDashboardModule,
    LiveChatTeamDashboardModule
  ]
})
export class LiveChatDashboardModule {}
