<div class="add-dashboard-widget-content-container modal-popup">
  <i class="ssi ssi-delete-close close-icon"
     (click)="activeModal.dismiss()"></i>

  <div class="head">
    <h2 class="title">Add your widgets</h2>
    <div class="heading-right">
      <div class="search-field"
           [class.active]="!!searchTerm">
        <i class="ssi ssi-correct-search"></i>
        <input type="text"
               [(ngModel)]="searchTerm"
               (ngModelChange)="filterValues()"
               placeholder="Search widgets...">
      </div>
    </div>
  </div>

  <div class="body">
    <div class="items-list-container">
      <div class="items-list widget-list">
        <ng-container *ngFor="let widget of filteredWidgets; let index = index;">
          <div class="item-box widget-box">
            <div class="thumbnail-action">
              <div class="item-thumb {{widget.typeConst.iconClassName}}"></div>
              <button type="button"
                      class="g-btn round-icon-only-button add-widget-button"
                      (click)="widgetAdded.emit(widget)"><i class="ssi ssi-addsmall"></i></button>
            </div>
            <h4 class="title">
              {{widget.typeConst.label}}
            </h4>
            <p class="description">
              {{widget.typeConst.tooltipExplainer}}
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>