import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { WidgetInboxMessageConfigComponent } from './widget-inbox-message-config.component';

import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { ToggleSwitch2Module } from '../../../../../../../common/components/toggle-switch-2/toggle-switch-2.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    DropdownSelect2Module,
    ToggleSwitch2Module
  ],
  exports: [WidgetInboxMessageConfigComponent],
  declarations: [WidgetInboxMessageConfigComponent]
})
export class WidgetInboxMessageConfigModule {}
