import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundDecimals'
})
export class RoundDecimalsPipe implements PipeTransform {
  transform(value: number | string = 0): number {
    let num = Number(value);
    if (Number.isNaN(num)) {
      return 0;
    }

    num = Math.round((num + Number.EPSILON) * 100) / 100;

    return num;
  }
}
