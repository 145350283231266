<ng-template [ngIf]="templateRefs[GridTemplates.options]">
    <div class="options">
        <ng-container *ngTemplateOutlet="templateRefs[GridTemplates.options]; context: { 'columns': cols, 'columnsMap': colsMap, 'level': level }"></ng-container>
    </div>
</ng-template>


<div class="content"
     #contentRef
     [class.scrollable-horizontally]="!inheritsMetas && metas.resizeMode.value === ResizeModes.expand">
    <div class="content-inner"
         #contentInnerRef
         [style.width]="metas.width.value">
        <ng-template [ngIf]="includeHead">
            <div class="head"
                 #headRef>
                <div class="head-inner">
                    <div class="row">
                        <ng-template ngFor
                                     let-col
                                     [ngForOf]="cols"
                                     let-colIndex="index">
                            <ng-template [ngIf]="col.metas.visibility.value">
                                <div class="cell"
                                     [style.width]="col.metas.width.value"
                                     [class.dragged]="col.dragged"
                                     (mousedown)="stopPropagation($event); handleColDragging($event, col)">
                                    <div class="cell-inner-cover"
                                         [class.active]="col.dragged"></div>

                                    <ng-template [ngIf]="col.templateRefs[ColumnTemplates.headCell]">
                                        <ng-container
                                                      *ngTemplateOutlet="col.templateRefs[ColumnTemplates.headCell]; context: { 'column': col, 'columnIndex': colIndex, 'columns': cols, 'columnsMap': colsMap, 'level': level }">
                                        </ng-container>
                                    </ng-template>

                                    <ng-template [ngIf]="!col.templateRefs[ColumnTemplates.headCell]">
                                        <div class="cell-inner">
                                            <div class="col-resizer left flx-no-select"
                                                 *ngIf="shouldEmbedColResizer(col, colIndex, 'left')"
                                                 (mousedown)="stopPropagation($event); handleColResizing($event, colIndex, 'left')"></div>
                                            <div class="col-header flx-no-select">
                                                <span
                                                  [ssiTooltip]="col.tooltip"
                                                  tooltipWindowClass="tooltip-chunky-big"
                                                  title="{{col.header}}">
                                                  {{col.header}}
                                                </span>
                                            </div>
                                            <div class="col-sorter flx-no-select"
                                                 (click)="stopPropagation($event); col.sort($event)"
                                                 (mousedown)="stopPropagation($event)"
                                                 *ngIf="col.sortable && !!col.field">
                                                <i *ngIf="col.metas.sortOrder.value === 1"
                                                   class="triangle up active"></i>
                                                <i *ngIf="col.metas.sortOrder.value === 0 || col.metas.sortOrder.value === -1"
                                                   class="triangle down"
                                                   [class.active]="col.metas.sortOrder.value !== 0"></i>
                                            </div>
                                            <div class="col-resizer right flx-no-select"
                                                 *ngIf="shouldEmbedColResizer(col, colIndex, 'right')"
                                                 (mousedown)="stopPropagation($event); handleColResizing($event, colIndex, 'right')"></div>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="body"
             #bodyRef
             [ngClass]="level === 0 ? 'root': 'nested'"
             [class.in-focus]="hasScrollFocus"
             [ngStyle]="bodyStyle"
             [ssiObserveWidthAndHeight]="readyToProcessData && metas.resizeMode.value !== ResizeModes.expand"
             (onWidthChange)="onBodyWidthChange($event)">
            <ng-template [ngIf]="allData.length">
                <div class="body-inner"
                     #bodyInnerRef
                     [ngStyle]="bodyInnerStyle">
                    <ng-template ngFor
                                 let-rowData
                                 [ngForOf]="renderData"
                                 let-rowIndex="index"
                                 let-firstRow="first"
                                 let-lastRow="last">
                        <div class="row"
                             [class.expanded]="rowData.ttExpanded"
                             [ngStyle]="rowStyle">
                            <ng-template ngFor
                                         let-col
                                         [ngForOf]="cols"
                                         let-colIndex="index">
                                <ng-template [ngIf]="col.metas.visibility.value">
                                    <div class="cell"
                                         *ssiVar="resolveFieldValue(rowData, col.field) as resolvedFieldValue"
                                         [style.width]="col.metas.width.value">
                                        <ng-template [ngIf]="col.templateRefs[ColumnTemplates.bodyCell]">
                                            <ng-container
                                                          *ngTemplateOutlet="col.templateRefs[ColumnTemplates.bodyCell]; context: { 'rowData': rowData, 'rowIndex': rowData.ttIndex, 'column': col, 'columnIndex': colIndex, 'columns': cols, 'columnsMap': colsMap, 'level': level, 'toggleRow': bindFnContext(toggleRow), fieldValue: resolvedFieldValue }">
                                            </ng-container>
                                        </ng-template>

                                        <ng-template [ngIf]="!col.templateRefs[ColumnTemplates.bodyCell]">
                                            <div class="cell-inner">
                                                <ng-template [ngIf]="col.expander">
                                                    <div class="flx-css-icon-container"
                                                         [style.margin-left]="level * 30 + 'px'">
                                                        <span class="flx-chevron flx-chevron-circle flx-clickable flx-no-select"
                                                              [class.flx-chevron-down]="rowData.ttExpanded"
                                                              (click)="toggleRow(rowData, rowData.ttIndex, $event)"
                                                              *ngIf="isRowExpandable(rowData)"></span>
                                                    </div>
                                                </ng-template>
                                                <ng-template [ngIf]="!!col.field">
                                                    <span class="field-value"
                                                          title="{{resolvedFieldValue}}">{{resolvedFieldValue}}</span>
                                                </ng-template>
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>

                        <ng-template [ngIf]="rowData.ttExpanded && virtualScroll">
                            <!-- virtual scroll doesn't work properly with animations (there is no way to add animations conditionally, need better solution here) -->
                            <div class="row-expanded-content-container">
                                <ng-container
                                              *ngTemplateOutlet="templateRefs[GridTemplates.rowExpansion]; context: { 'rowData': rowData, 'rowIndex': rowData.ttIndex, 'columns': cols, 'columnsMap': colsMap, 'level': level, 'self': self }">
                                </ng-container>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="rowData.ttExpanded && !virtualScroll">
                            <div class="row-expanded-content-container"
                                 [@rowExpansion]="'rowExpansion'"
                                 (@rowExpansion.done)="onRowExpansionAnimationDone($event, rowData)">
                                <ng-container
                                              *ngTemplateOutlet="templateRefs[GridTemplates.rowExpansion]; context: { 'rowData': rowData, 'rowIndex': rowData.ttIndex, 'columns': cols, 'columnsMap': colsMap, 'level': level, 'self': self }">
                                </ng-container>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </ng-template>

            <ng-template [ngIf]="!allData.length && templateRefs[GridTemplates.noResults]">
                <div class="body-no-results-template-container">
                    <ng-container *ngTemplateOutlet="templateRefs[GridTemplates.noResults]; context: { 'columns': cols, 'columnsMap': colsMap, 'level': level }"></ng-container>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<ng-template [ngIf]="pagination && readyToProcessData">
    <div class="paginator-container flx-no-select">
        <ssi-paginator [itemsCount]="filteredData.length"
                       [pageLinksSize]="pagination.pageLinksSize"
                       [itemsPerPage]="metas.itemsPerPage.value"
                       [itemsPerPageOptions]="pagination.itemsPerPageOptions"
                       (onPageChange)="onPageChange($event)"
                       (onItemsPerPageValueChange)="onItemsPerPageValueChange($event)"></ssi-paginator>
    </div>
</ng-template>


<ng-template [ngIf]="templateRefs[GridTemplates.footer]">
    <div class="foot"
         #foot>
        <ng-container *ngTemplateOutlet="templateRefs[GridTemplates.footer]; context: { 'columns': cols, 'columnsMap': colsMap, 'level': level }"></ng-container>
    </div>
</ng-template>

<!-- <ng-template [ngIf]="templateRefs[GridTemplates.headContextMenu] && headRef">
    <flx-contextMenu [hostElementRef]="headRef"
                     [templateRef]="templateRefs[GridTemplates.headContextMenu]"
                     [templateCtx]="{ 'columns': cols, 'columnsMap': colsMap }"></flx-contextMenu>
</ng-template> -->