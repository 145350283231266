import { api } from '../../core/services/api';
import { services } from '../../common';
import { Activity, ActivityModel } from './activityModel';
import { utils } from 'js-data';
import { UserModel } from '../../user/services/userModel';

export interface Tag {
  id: number;
  name: string;
  disabled: boolean;
}

export class ActivityTags {
  private _tagsCache: Tag[] = null;

  clearCache() {
    this._tagsCache = null;
  }

  async getTags(query: string = ''): Promise<string[]> {
    const params: any = {
      params: {
        tag_type: 'inbox',
        include_diabled: false
      }
    };

    if (!this._tagsCache) {
      this._tagsCache = await api
        .get('company/tagsv2', params)
        .then(({ data }: { data: any }): Tag[] => {
          return data.sort((tag1, tag2) => tag1.name.localeCompare(tag2.name));
        });
    }
    if (query) {
      return this._tagsCache
        .filter((tag: Tag) =>
          tag.name.toLowerCase().startsWith(query.toLowerCase())
        )
        .map((tag) => tag.name);
    }
    return this._tagsCache.map((tag) => tag.name);
  }

  async createTag(tag: string): Promise<void> {
    const payload = {
      tag,
      tag_type: 'inbox'
    };

    return await api.post('company/tagsv2', payload).then(({ data }) => {
      this._tagsCache.push(data.tag);
    });
  }

  async deleteTag(tag: string, purge: boolean = false): Promise<void> {
    const tagId = this._tagsCache.find((_tag) => _tag.name === tag).id;
    const params: any = { id: tagId };
    if (purge) {
      params.purge = true;
    }
    return api.del('company/tagsv2', { params }).then(() => {
      this._tagsCache.filter((_tag) => _tag.id !== tagId);
    });
  }

  purgeTagFromActivities(tag: string): void {
    services.models
      .get<ActivityModel>('activity')
      .getAll()
      .forEach((activity: Activity) => {
        activity.tags = (activity.tags || []).filter(
          (activityTag) => activityTag.toLowerCase() !== tag.toLowerCase()
        );
      });
  }

  getCanCreateNewTags(): Promise<boolean> {
    return utils.Promise.all([
      api.get('settings/companyPreference').then(({ data }) => data),
      services.models.get<UserModel>('user').getAuthUser()
    ]).then(([companyPreferences, authUser]) => {
      return (
        companyPreferences.company_preferences.all_users_can_create_tags ===
          '1' || authUser.hasCompanyPermission('administer_company')
      );
    });
  }
}
