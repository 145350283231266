import {
  Component,
  OnInit,
  Input,
  Inject,
  Injector,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { API, AccountModel, Account } from '@ui-resources-angular';

export interface TogglePermissionEvent {
  account_id: string;
  permission: string;
  state: 'enabled' | 'disabled';
}

@Component({
  selector: 'ssi-permissions',
  templateUrl: './permissions.component.html'
})
export class PermissionsComponent implements OnInit, OnChanges {
  @Input() accounts: Partial<Account>;
  @Input() permissions;
  @Input() searchText: string;
  @Input() disableCompanyPermissions: boolean;

  @Output() onTogglePermission = new EventEmitter<any>();

  filteredAccounts: any[];

  constructor(private accountModel: AccountModel) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchText || changes.accounts) {
      this.filteredAccounts =
        this.accounts &&
        Object.values(this.accounts).map((account: { id: string }) => {
          return Object.assign({}, account, {
            info: this.accountModel.get(account.id)
          });
        });
      if (this.searchText) {
        this.filteredAccounts = this.filteredAccounts.filter((account) => {
          return (
            this.contains(account, 'username', this.searchText) ||
            this.contains(account.info, 'name', this.searchText)
          );
        });
      }
    }
  }

  anyPermissionEnabled(permissions = {}) {
    try {
      const permissionsValues = [...Object.values(permissions)];

      return !!permissionsValues.find((value) => !!value);
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  togglePermission(accountId, permissionName, isEnabled) {
    const params = {
      account_id: accountId,
      permission: permissionName,
      state: isEnabled ? 'enabled' : 'disabled'
    };

    Promise.resolve(this.onTogglePermission.emit(params)).catch(() => {
      this.permissions[accountId][permissionName] = !isEnabled;
    });

    this.permissions[accountId] = this.permissions[accountId] || {};
    this.permissions[accountId][permissionName] = isEnabled;
  }

  toggleAllPermissions(accountId, isEnabled) {
    Object.values(this.accounts[accountId].permissions).forEach(
      (permission: { name: string }) => {
        if (
          this.permissions[accountId] &&
          this.permissions[accountId][permission.name] &&
          !isEnabled
        ) {
          this.togglePermission(accountId, permission.name, isEnabled);
        } else if (
          (!this.permissions[accountId] ||
            !this.permissions[accountId][permission.name]) &&
          isEnabled
        ) {
          this.togglePermission(accountId, permission.name, isEnabled);
        }
      }
    );
  }

  contains(account, field: string, searchText: string): boolean {
    return (
      account &&
      account[field] &&
      account[field].toLowerCase().includes(searchText.toLowerCase())
    );
  }

  isEnabled(account: any, permission: any) {
    return (
      this.permissions[account.id] &&
      !!this.permissions[account.id][permission.name]
    );
  }
}
