<div class="container-fluid container-app-page">
  <ssi-alerts></ssi-alerts>

  <div class="page-header">
    <h1>
      <span translate="WORKFLOWS"></span>
      <div class="pull-right">
        <button
          *ngIf="!showCreateGroup"
          class="btn btn-primary"
          (click)="showCreateGroup = true"
        ><i class="fa fa-plus"></i> <span translate="NEW_WORKFLOW"></span></button>
        <button
          class="btn btn-default"
          (click)="loadAllAccounts()"
        ><span translate="LOAD_ALL_ACCOUNTS"></span></button>
      </div>
    </h1>
  </div>

  <div class="panel panel-default" *ngIf="showCreateGroup">
    <div class="panel-body">
      <p class="lead">
        <span
          translate="CREATE_A_NEW_WORKFLOW_BY_SIMPLY_ENTERING_A_NAME_THEN_ADD_THE_ACCOUNTS_YOU_WOULD_LIKE_TO_ASSOCIATE_WITH_THE_WORK_FLOW_THEN_YOURE_DONE"
        ></span>
      </p>
      <form
        [formGroup]="newWorkflowForm"
        class="form-horizontal"
        name="newWorkflowForm"
        (submit)="createNew()"
      >
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="WORKFLOW_NAME"></label>
          <div class="col-sm-6">
            <input type="text" class="form-control" formControlName="newGroupName">
          </div>
          <div class="col-sm-3">
            <button
              class="btn btn-success"
              [disabled]="newWorkflowForm.invalid"
              type="submit"
            >
              <i class="fa fa-check"></i> <span translate="CREATE"></span>
            </button>
            <a
              class="btn btn-default"
              (click)="showCreateGroup = false"
            ><i class="fa fa-times"></i> <span translate="CANCEL"></span></a>
          </div>
        </div>
      </form>
    </div>
  </div>

  <h1 class="text-center text-muted lead" *ngIf="workflows.length === 0">
    <i class="fa fa-random fa-4x"></i><br/><br/>
    <span
      translate="QUICKLY_SPLIT_UP_YOUR_ACCOUNTS_BY_BRAND_OR_REGION_OR_PRODUCTS"
    ></span><br/>
    <small>
      <span
        translate="TO_GET_STARTED_CREATE_YOUR_FIRST_WORKFLOW_BY_ENTERING_A_NAME_AND_CLICK_CREATE"
      ></span>
    </small>
  </h1>

  <div class="row">
    <div *ngFor="let workflow of workflows; let i = index">
      <div class="col-md-4" [ngClass]="{'col-md-offset-2': i % 2 === 0}">
        <div class="panel panel-default">
          <div class="panel-body">
            <a (click)="loadState(workflow, 'auth.dashboard')">
              <h1 class="text-center no-wrap">{{ workflow.name }}</h1>
              <p class="list-group-item-text text-center">
                <ng-container *ngFor="let account of workflow.accounts | slice:0:4">
                  <img [src]="account.picture" class="profile profile-large">
                </ng-container>
                <br/>
              </p>
            </a>
            <hr/>
            <div class="row text-muted">
              <div class="col-xs-6">

                <div class="dropdown" ngbDropdown>
                  <button
                    class="btn btn-default btn-sm dropdown-toggle"
                    ngbDropdownToggle
                  >
                    <i class="fa fa-caret-down"></i> <span translate="TOOLS"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a (click)="loadState(workflow, 'auth.accountReport')">
                        <div class="row">
                          <div class="col-xs-3">
                            <i class="fa fa-bar-chart-o fa-2x"></i>
                          </div>
                          <div class="col-xs-9">
                            <small><span translate="VIEW_STATS"></span></small><br/>
                            <small class="text-muted">
                              <span translate="VIEW_STATS_FOR_WORKFLOW"></span>
                            </small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a (click)="loadState(workflow, 'auth.marketing.bulkUpload')">
                        <div class="row">
                          <div class="col-xs-3">
                            <i class="fa fa-list-ol fa-2x"></i>
                          </div>
                          <div class="col-xs-9">
                            <small><span translate="BULK_UPLOAD"></span></small><br/>
                            <small class="text-muted">
                              <span translate="BULK_UPLOAD_CSV_TO_WORKFOLOW"></span>
                            </small>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-6 text-right">
                <span
                  translate="_TOTALACCOUNTS__ACCOUNTS"
                  [translateParams]="{totalAccounts: workflow.accounts.length}"
                ></span> -
                <a
                  uiSref="auth.editWorkflow"
                  [uiParams]="{ workflowId: workflow.id }"
                >
                  <span translate="EDIT"></span> <i class="fa fa-cog"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="i % 2 === 1"></div>
      <div class="clearfix" *ngIf="i % 2 === 1"></div>
    </div>
  </div>

</div>
