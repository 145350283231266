<div class="settings-row grey">
  <div class="settings-chat-bot container container-app-page">
    <div class="panel panel-default">
      <div class="panel-heading panel-heading-btn">
        <h3 class="panel-title"><span translate="BOTS"></span></h3>
        <div class="pull-right">
          <button class="btn btn-primary" (click)="createBot(metabases)">
            <i class="fa fa-plus"></i> <span translate="CREATE_BOT"></span>
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th><p translate="NAME"></p></th>
            <th><p translate="METABASE"></p></th>
            <th>
              <div class="input-group pull-right" style="width: 300px">
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  class="form-control"
                  placeholder="{{ 'SEARCH' | translate }}"
                  type="search"
                  [(ngModel)]="searchTextTable1"
                  (ngModelChange)="updateTable('searchTextTable1', 'chatBots', chatBotsCache, ['name', 'metabase.name'])">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let chatBot of chatBots | sortBy: 'name'">
            <td><p>{{ chatBot.name }}</p></td>
            <td><p>{{ chatBot.metabase.name }}</p></td>
            <td class="text-right">
              <button
                class="btn btn-sm btn-secondary"
                (click)="editChatbot(chatBot,metabases)"
              >
                <span translate="EDIT"></span>
              </button>
              <button class="btn btn-sm btn-default" 
                      uiSref="auth.analytics.chatbot.report"
                      [uiParams]="{botId: chatBot.id, name: chatBot.name}">
                <span translate="VIEW_REPORT"></span>
              </button>
              <button
                class="btn btn-sm btn-default"
                uiSref="auth.analytics.chatbot.topicReport"
                [uiParams]="{botId: chatBot.id, name: chatBot.name}"
              >
                <span>View Topic report</span>
              </button>
              <button
                class="btn btn-sm btn-danger"
                (click)="deleteChatbot(chatBot)"
              >
              <span translate="DELETE"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr>

    <div *ngIf="!selectedMetabase" class="panel panel-default">
      <div class="panel-heading panel-heading-btn">
        <h3 class="panel-title"><span translate="METABASES"></span></h3>
        <div class="pull-right">
          <button class="btn btn-primary" (click)="createMetabase()">
            <i class="fa fa-plus"></i> <span translate="CREATE_METABASE"></span>
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th><p translate="NAME"></p></th>
            <th>
              <div class="input-group pull-right" style="width: 300px">
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  class="form-control"
                  placeholder="{{ 'SEARCH' | translate }}"
                  type="search"
                  [(ngModel)]="searchTextTable2"
                  (ngModelChange)="updateTable('searchTextTable2', 'metabases', metabasesCache, ['name'])">
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="tableLoading">
          <div class="loading"></div>
        </tbody>
        <tbody *ngIf="!tableLoading">
          <tr *ngFor="let metabase of metabases | sortBy: 'name'">
            <td><p>{{ metabase.name }}</p></td>
            <td class="text-right">
              <button
                class="btn btn-sm btn-secondary"
                (click)="editMetabase(metabase)"
              >
                <span translate="EDIT"></span>
              </button>
              <button
                class="btn btn-sm btn-default"
                ssiTooltip="These are the subjects that a customer may be talking about"
                [tooltipAppendToBody]="true"
                (click)="displayTopics(metabase)"
              >
                <span translate="TOPICS"></span>
              </button>
              <button
                class="btn btn-sm btn-success"
                ssiTooltip="Got a webpage with all the information for this Topic? Use the Scrape tool to automatically create a summarised response"
                [tooltipAppendToBody]="true"
                (click)="scrapeMetabase(metabase)"
              >
                <i class="fa fa-cloud-download"></i> <span translate="SCRAPE"></span>
              </button>
              <button
                class="btn btn-sm btn-success"
                ssiTooltip="Got a CSV file containing all the information for this Topic? Upload it here to generate your responses"
                [tooltipAppendToBody]="true"
                (click)="importMetabaseCSV(metabase)"
              >
                <i class="fa fa-upload"></i> <span translate="IMPORT_CSV"></span>
              </button>
              <button
                class="btn btn-sm btn-danger"
                (click)="deleteMetabase(metabase)"
              >
              <span translate="DELETE"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="selectedMetabase" class="panel panel-default">
      <div class="panel-heading panel-heading-btn">
        <h3 class="panel-title"><span>{{selectedMetabase.name}} - {{'TOPICS' | translate}}</span></h3>
        <div class="pull-right">
          <button class="btn btn-primary" (click)="editTopic({}, selectedMetabase)">
            <i class="fa fa-plus"></i> <span translate="CREATE_TOPIC"></span>
          </button>
          <button class="btn btn-default" (click)="selectedMetabase = undefined">
            <i class="fa fa-times"></i> <span translate="BACK"></span>
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th><p translate="NAME"></p></th>
            <th><p translate="RESPONSE"></p></th>
            <th><p translate="LINK"></p></th>
            <th><p translate="TYPE"></p></th>
            <th>
              <div class="input-group pull-right" style="width: 300px">
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  class="form-control"
                  placeholder="{{ 'SEARCH' | translate }}"
                  type="search"
                  [(ngModel)]="searchTextTable2"
                  (ngModelChange)="updateTable('searchTextTable2', 'topics', topicsCache, ['name', 'response', 'link'])">
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="tableLoading">
          <div class="loading"></div>
        </tbody>
        <tbody *ngIf="!tableLoading">
          <tr *ngFor="let topic of topics | sortBy: 'name'">
            <td><p>{{ topic.name }}</p></td>
            <td><p>{{ topic.response }}</p></td>
            <td>
              <p
                [ngStyle]="{
                  'word-break': 'break-all',
                  'min-width.px': '200'
                }">
                {{ topic.link }}
              </p>
            </td>
            <td>
              <p
                [ngStyle]="{
                  'white-space': 'nowrap'
                }">
                {{fetchTopicType(topic)}}
              </p>
            </td>
            <td class="text-right">
              <ng-container>
                <button
                  class="btn btn-sm btn-primary"
                  ssiTooltip="Click here to edit the Topics and Responses you have saved"
                  [tooltipAppendToBody]="true"
                  (click)="editTopic(topic, selectedMetabase)"
                >
                  <i class="fa fa-pencil-square-o"></i> <span>Edit</span>
                </button>
                <button
                  class="btn btn-sm btn-default"
                  ssiTooltip="Got Example questions for your Chat Bot to learn from? You can add those here"
                  [tooltipAppendToBody]="true"
                  (click)="openExamplesModal(selectedMetabase, topic)"
                >
                  <span>Examples</span>
                </button>
                <button
                  class="btn btn-sm"
                  [ngClass]="topic.is_enabled ? 'btn-success' : 'btn-default'"
                  ssiTooltip="When a Topic is Enabled, the Chat Bot will be able to use the Response in relevant conversations"
                  [tooltipAppendToBody]="true"
                  (click)="topic.is_enabled = !topic.is_enabled; chatBot.editTopic(selectedMetabase.id, topic.id, {is_enabled: topic.is_enabled})"
                >
                  <i class="fa" [ngClass]="topic.is_enabled ? 'fa-check-circle' : 'fa-circle'"></i> <span>Enabled</span>
                </button>
                <!-- <button
                  class="btn btn-sm"
                  [ngClass]="topic.is_handover_request ? 'btn-success' : 'btn-default'"
                  ssiTooltip="When Handover is turned on, the Chat Bot will ask the customer if they would like to talk to a human about this Topic"
                  [tooltipAppendToBody]="true"
                  (click)="toggleHandover(topic)"
                >
                  <i class="fa" [ngClass]="topic.is_handover_request ? 'fa-check-circle' : 'fa-circle'"></i> <span>Handover</span>
                </button>
                <button
                  class="btn btn-sm"
                  [ngClass]="topic.is_conversational ? 'btn-success' : 'btn-default'"
                  ssiTooltip="Conversational topics will return the response and wait for more input, it will not ask for feedback"
                  [tooltipAppendToBody]="true"
                  (click)="toggleConversational(topic)"
                >
                  <i class="fa" [ngClass]="topic.is_conversational ? 'fa-check-circle' : 'fa-circle'"></i> <span>Conversational</span>
                </button> -->
                <button
                  class="btn btn-sm btn-danger"
                  ssiTooltip="Click here to permanently delete this Topic and Response. Careful, there's no undo button for this one!"
                  [tooltipAppendToBody]="true"
                  (click)="deleteTopic(selectedMetabase, topic)"
                >
                  <i class="fa fa-trash-alt"></i> <span>Delete</span>
                </button>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
