<div class="widget-marketing-posts-config-container">
  <div class="dwm-form">

    <div class="dwm-form-half">
      <!-- Widget Text Input -->
      <div class="dwm-body-row">
        <label class="dwm-label"
               for="widgetTitle">Widget Title (Optional)</label>
        <div class="dwm-body-row-input">
          <input class="dwm-input"
                 id="widgetTitle"
                 type="text"
                 placeholder="Type your widget title here..."
                 [(ngModel)]="widget.title">
          <span [ngClass]="{'dwm-body-row-input-meta-active':  widget.title.length}"
                class="dwm-body-row-input-meta">
            {{widgetTitleMaxLength - widget.title.length}}
          </span>
        </div>
      </div>
    </div>

    <div class="dwm-form-half">
      <!-- Widget Dropdown Input -->
      <div class="dwm-body-row">
        <label class="dwm-label"
               for="presets">Preset filters / Default filters</label>
        <ssi-dropdown-select-2 name="presets"
                               class="dwm-dropdown-selector"
                               [required]="true"
                               [multiple]="false"
                               [toggleAllEnabled]="false"
                               [filterable]="false"
                               [placeholdersKeyword]="'preset'"
                               [options]="presetsIterable"
                               [(ngModel)]="selectedPreset"
                               (ngModelChange)="onSelectedPresetChange($event)"></ssi-dropdown-select-2>
      </div>

    </div>
  </div>
</div>