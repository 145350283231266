import './query-builder-condition.component.scss';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  Query,
  conditionOptions,
  conditionOptionsIterable
} from '../../../common/constants/query-builder-options';
import { BranchClassName } from '../../../common/pipes/branch-class/branch-class.pipe';

@Component({
  selector: 'ssi-query-builder-condition',
  templateUrl: './query-builder-condition.component.html',
  styles: []
})
export class QueryBuilderConditionComponent {
  @Input() condition: Query;
  @Input() operator: '$or' | '$and';
  @Input() isRoot = false;
  @Input() branchClass: BranchClassName;
  @Input() overrideDefaultOption: boolean;
  conditionOptions = conditionOptions;
  conditionOptionsIterable = conditionOptionsIterable;
  @Output() deleteCondition = new EventEmitter();
  @Output() operatorToggle = new EventEmitter();

  constructor() {}
}
