import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopTagsListBoxComponent } from './top-tags-list-box.component';
import { MedalScoreModule } from '../../../common/medal-score/medal-score.module';
import { ProgressBarModule } from '../../../../../../common/components/progress-bar/progress-bar.module';

@NgModule({
  declarations: [TopTagsListBoxComponent],
  imports: [CommonModule, ProgressBarModule, MedalScoreModule],
  exports: [TopTagsListBoxComponent]
})
export class TopTagsListBoxModule {}
