import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { authUser } from '../../../common-resolves';
import { User } from '@ui-resources-angular';
import { CompanyService } from '../../../../common/services/company/company.service';

export async function featureFlagsFn(company: CompanyService) {
  const companyConfig = await company.getConfig();
  return companyConfig.feature_flags;
}

@Component({
  selector: 'ssi-company-settings',
  templateUrl: './company-settings.component.html',
  styles: []
})
export class CompanySettingsComponent {
  static resolve = [
    {
      token: 'featureFlags',
      resolveFn: featureFlagsFn,
      deps: [CompanyService]
    },
    authUser
  ];

  @Input() authUser: User;
  @Input() featureFlags;

  companySettings = [
    {
      link: 'auth.manageUsers',
      header: 'Manage Users',
      text: 'Manage & specifiy what features in Orlo users can access',
      icon: 'ssi ssi-manageusers',
      permission: 'administer_users',
      flag: null
    },
    {
      link: 'auth.manageTeams',
      header: 'Company Teams',
      text: 'Create / edit teams & assign their permissions',
      icon: 'ssi ssi-companyteams',
      permission: 'administer_group_permissions',
      flag: null
    },
    {
      link: '.publishSettings',
      header: 'Publish Settings',
      text: 'Set a custom vanity url & UTM link tracking defaults',
      icon: 'ssi ssi-publishsettings',
      permission: 'administer_company',
      flag: null
    },
    {
      link: 'auth.authenticationSettings',
      header: 'Authentication',
      text: 'Manage how users can log into the platform',
      icon: 'ssi ssi-authentication',
      permission: 'administer_company',
      flag: null
    },
    {
      link: 'auth.inboxSettings',
      header: 'Inbox Settings',
      text: 'Manage your Inbox features, such as snippets or tags here',
      icon: 'ssi ssi-inboxsettings',
      permission: 'administer_company',
      flag: null
    },
    {
      id: 'InboxPushMode',
      link: 'auth.settings.companysettings.socialPushMode',
      header: 'Inbox Push Mode',
      text: 'Manage push mode for social here',
      icon: 'ssi ssi-push-mode',
      permission: 'administer_company',
      flag: 'CONVERSATION_PUSH_MODE'
    },
    {
      link: 'auth.officeHours',
      header: 'Office Hours',
      text: 'Set office hours, this helps calculate your Inbox response times',
      icon: 'ssi ssi-experience',
      permission: 'administer_company',
      flag: null
    },
    {
      link: 'auth.settings.companysettings.internationalization',
      header: 'Internationalization',
      text: 'Set your preferred company currency here',
      icon: 'ssi ssi-internationalization',
      permission: 'administer_company',
      flag: null
    },
    {
      link: '.companylockdown',
      header: 'Company Lockdown',
      text: 'In an emergency, this will instantly stop any social activity',
      icon: 'ssi ssi-companylockdown',
      permission: 'administer_company',
      flag: null
    },
    {
      link: '.manageWebhooks',
      header: 'Manage Webhooks',
      text: 'Send events from Orlo to a url of your choosing!',
      icon: 'ssi ssi-managewebhooks',
      permission: 'administer_company',
      flag: null
    },
    {
      link: 'auth.manageApps',
      header: 'Manage Apps',
      text: 'Manage all your external App integrations for Orlo here',
      icon: 'ssi ssi-manageapps',
      permission: 'administer_company',
      flag: null
    },
    {
      link: 'auth.settings.companysettings.surveySettings',
      header: 'Survey Settings',
      text: 'Set up NPS/CSAT surveys for your inbox',
      icon: 'ssi ssi-survey',
      permission: 'administer_company',
      flag: 'SURVEYS'
    },
    {
      link: 'auth.settings.companysettings.chatbot',
      header: 'Chatbot',
      text: 'Create and manage chatbots and responses.',
      icon: 'ssi ssi-new-chat',
      permission: 'administer_company',
      flag: 'CHAT_BOT'
    },
    {
      link: 'auth.settings.companysettings.enableSocialChatbots',
      header: 'Enable Social Chatbots',
      text: 'Configure chatbots for your social accounts',
      icon: 'ssi ssi-chatbot',
      permission: 'administer_company',
      flag: 'CHAT_BOT'
    },
    {
      link: 'auth.settings.companysettings.automatedReports',
      header: 'Automated Reports',
      text: 'Manage your Analytics and Insights report automation',
      icon: 'ssi ssi-small-schedule',
      permission: 'administer_company',
      flag: 'SCHEDULED_REPORTS'
    }
  ];

  constructor(public location: Location) {}

  checkPermission(permission) {
    return permission ? this.authUser.hasCompanyPermission(permission) : true;
  }

  checkFlag(flag) {
    return flag ? this.featureFlags.includes(flag) : true;
  }
}
