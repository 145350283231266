import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';
import { TooltipWindowComponent } from './tooltip-window/tooltip-window.component';
import { TooltipWrapComponent } from './tooltip-wrap/tooltip-wrap.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { ConfirmActionModule } from './confirm-action/confirm-action.module';

@NgModule({
  imports: [CommonModule, ConfirmActionModule],
  declarations: [
    TooltipDirective,
    TooltipWindowComponent,
    TooltipWrapComponent
  ],
  entryComponents: [TooltipWindowComponent, TooltipWrapComponent],
  exports: [TooltipDirective, TooltipWrapComponent]
})
export class TooltipModule {}
