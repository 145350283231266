import { merge } from 'lodash-es';
import {
  ActionConfig,
  staticParams,
  UIParam
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import {
  ServiceMapping,
  overlayDimensions,
  getParamByName,
  replaceTemplateIngredientsWithHtml
} from './util';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../common/services/api';
import { AppletActionParam } from '../applets/applet';
import { Injector } from '@angular/core';
import { IftttService } from '../ifttt-service-model/ifttt-service-model';

const actions: ActionConfig[] = [
  {
    api: {
      name: 'notify users'
    },
    translationIds: {
      title: 'NOTIFICATION_MOBILE_PUSH_ACTION_EMAIL_USER_TITLE',
      description: 'NOTIFICATION_MOBILE_PUSH_ACTION_EMAIL_USER_DESCRIPTION'
    },
    iconClass: 'ssi ssi-mobile',
    overlayDimensions: overlayDimensions.notification,
    getLabel(
      params: AppletActionParam[],
      injector: Injector,
      iftttService: IftttService
    ) {
      const names: string = getParamByName(params, 'user_ids')
        .value.map((user: Colleague) => user.fullName)
        .join(', ');
      const message = replaceTemplateIngredientsWithHtml(
        getParamByName(params, 'message').value,
        injector,
        iftttService
      );
      return `Send an app push notification to ${names} saying "${message}"`;
    },
    paramOverrides: {
      message(): UIParam {
        return merge(staticParams.textarea(), {
          form: {
            startColumn: true
          }
        });
      },
      user_id(): UIParam {
        return {
          remove: true
        };
      }
    }
  },
  {
    api: {
      name: 'notify teams'
    },
    translationIds: {
      title: 'NOTIFICATION_MOBILE_PUSH_ACTION_EMAIL_TEAM_TITLE',
      description: 'NOTIFICATION_MOBILE_PUSH_ACTION_EMAIL_TEAM_DESCRIPTION'
    },
    iconClass: 'ssi ssi-team-notification',
    overlayDimensions: overlayDimensions.notification,
    getLabel(
      params: AppletActionParam[],
      injector: Injector,
      iftttService: IftttService
    ) {
      const names: string = getParamByName(params, 'team_ids')
        .value.map((team: Team) => (!!team ? team.name : 'unknown'))
        .join(', ');
      const message = replaceTemplateIngredientsWithHtml(
        getParamByName(params, 'message').value,
        injector,
        iftttService
      );
      return `Send an app push notification to the team ${names} saying "${message}"`;
    },
    paramOverrides: {
      message(): UIParam {
        return merge(staticParams.textarea(), {
          form: {
            startColumn: true
          }
        });
      }
    }
  }
];

const service: ServiceMapping = {
  id: 'notification.mobile_push',
  triggers: [],
  actions,
  ingredients: []
};

export default service;
