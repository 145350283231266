import './audit-event.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import { AuditEvent, AuditEventVerb } from '../../services/api';

@Component({
  selector: 'ssi-audit-event',
  templateUrl: './audit-event.component.html',
  styles: []
})
export class AuditEventComponent implements OnInit {
  @Input() auditEvent: AuditEvent;
  @Input() last = false;
  expandedNoteText = false;

  AuditEventVerb = AuditEventVerb;

  constructor() {}

  ngOnInit() {}
}
