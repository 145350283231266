import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesTextareaComponent } from './notes-textarea.component';
import { FormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { SanitizedHtmlModule } from '../../pipes/sanitized/sanitized.module';

@NgModule({
  imports: [CommonModule, FormsModule, MentionModule, SanitizedHtmlModule],
  declarations: [NotesTextareaComponent],
  exports: [NotesTextareaComponent]
})
export class NotesTextareaModule {}
