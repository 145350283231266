import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { OrderByDirection } from '@firebase/firestore-types';

// ----------------

const isDebug = false;

// ----------------

const availableDateRanges = [
  {
    translate: '3_DAYS',
    value: 3
  },
  {
    translate: '7_DAYS',
    value: 7
  },
  {
    translate: '14_DAYS',
    value: 14
  },
  {
    translate: '30_DAYS',
    value: 30
  }
];

@Component({
  selector: 'ssi-live-chat-navigation',
  templateUrl: './live-chat-navigation.component.html',
  styles: []
})
export class LiveChatNavigationComponent implements OnInit {
  @Input() public isLiveChatActive: boolean;
  @Output()
  public isLiveChatActiveChange: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  private _indexOfSelectedDateRange: number;
  private _isInitialised: boolean = false;
  private _selectedDateRange;

  constructor(private liveChatService: LiveChatService) {}

  public get availableDateRanges() {
    return availableDateRanges;
  }

  public get periodOfDateRangeInDays(): number {
    return this.liveChatService.periodOfDateRangeInDays;
  }

  public get isInitialised(): boolean {
    return this._isInitialised;
  }

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public get selectedDateRange() {
    return this._selectedDateRange;
  }

  public get selectedOption() {
    if (!(!!this.sortingOptions && Array.isArray(this.sortingOptions))) {
      return;
    }

    return this.sortingOptions.find(
      (option) =>
        option.value ===
        this.liveChatService.selectedSortingOptionForConversations
    );
  }

  public get sortingOptions() {
    return LiveChatService.SortingOptionsForConversations;
  }

  public async closeLiveChatView() {
    await this.liveChatService.closeInterface();
    this.isLiveChatActiveChange.emit(false);
  }

  public async ngOnInit() {
    try {
      await this.selectDateRangeByIndex(0, true);

      this._isInitialised = true;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public selectOption(option: OrderByDirection) {
    this.liveChatService.selectedSortingOptionForConversations = option;
  }

  public async selectDateRangeByIndex(
    index: number,
    shouldNotRefresh?: boolean
  ): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`liveChatNavigation~>selectDateRangeByIndex`);
      }

      if (index >= this.availableDateRanges.length) {
        throw new Error(`Date range index ${index} too large!`);
      }

      this._indexOfSelectedDateRange = index;

      const dateRange = this.availableDateRanges[index];

      if (!dateRange) {
        throw new Error(`Unable to find date range for index ${index}!`);
      }

      this._selectedDateRange = dateRange;

      if (!shouldNotRefresh) {
        const periodOfDateRangeInDays = dateRange.value;

        await this.liveChatService.refreshDateRange(periodOfDateRangeInDays);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}
