import twitterText from 'twitter-text';

export default function socialPostCharactersRemaining(
  input: string,
  maxLength: number,
  hasTwitter: boolean
): number {
  if (!input || input.length === 0) {
    return maxLength;
  }
  if (hasTwitter) {
    return maxLength - twitterText.parseTweet(input).weightedLength;
  } else {
    return maxLength - input.length;
  }
}
