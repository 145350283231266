import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { ValidateReplyBoxComponent } from './validate-reply-box.component';
import { AddSecondsModule } from '../../../../../common/pipes/add-seconds/add-seconds.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { ValidateReplyBoxContentModule } from '../validate-reply-box-content/validate-reply-box-content.module';
import { TranslateModule } from '@ngx-translate/core';
import { ValidatePostsGalleryModule } from '../../validate-posts/validate-posts-box/validate-posts-gallery/validate-posts-gallery.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    FlatpickrModule.forRoot(),
    AddSecondsModule,
    TooltipModule,
    BigNumberModule,
    FormsModule,
    ValidateReplyBoxContentModule,
    ValidatePostsGalleryModule,
    TranslateModule
  ],
  declarations: [ValidateReplyBoxComponent],
  exports: [ValidateReplyBoxComponent]
})
export class ValidateReplyBoxModule {}
