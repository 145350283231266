import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { LoaderService, LoaderVariant } from './loader.service';

@Directive({
  selector: '[ssiLoader]'
})
export class LoaderDirective implements OnInit, OnChanges {
  @Input() ssiLoaderVisible = false; // Controlling the loader by providing the flag
  @Input() ssiLoaderVariant: LoaderVariant = LoaderVariant.Transparent;
  @Input() ssiLoaderHideTimeout = 0; // ms
  @Input() ssiLoaderText = '';

  loaderElem: HTMLElement;
  visible = false;

  constructor(
    protected elementRef: ElementRef,
    protected loaderService: LoaderService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.loaderElem) {
      // This is also needed here as the change might happen before ngOnInit is fired
      this.loaderElem = this.loaderService.appendLoader(
        this.elementRef.nativeElement,
        this.ssiLoaderVariant,
        this.ssiLoaderText
      );
    }

    if (changes['ssiLoaderVisible']) {
      const visible = changes['ssiLoaderVisible'].currentValue;
      if (visible) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  ngOnInit(): void {
    if (!this.loaderElem) {
      this.loaderElem = this.loaderService.appendLoader(
        this.elementRef.nativeElement,
        this.ssiLoaderVariant,
        this.ssiLoaderText
      );
    }
  }

  show(): void {
    if (this.loaderElem) {
      this.loaderElem.style['display'] = 'flex';
      this.visible = true;
    }
  }

  hide(): void {
    if (this.loaderElem) {
      // setTimeout(() => {
      this.loaderElem.style['display'] = 'none';
      this.visible = false;
      // }, this.ssiLoaderHideTimeout);
    }
  }

  isVisible() {
    return this.visible;
  }
}
