import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { DraftsLibraryComponent } from './drafts-library.component';
import { AlertsModule } from '../../../../common/components/alerts/alerts.module';
import { DraftModule } from '../../../../common/components/draft/draft.module';
import { PaginatorModule } from '../../../../common/components/paginator';
import { TriggerPublishModule } from '../../../../common/directives/trigger-publish/trigger-publish.module';
import { LoaderModule } from '../../../../common/directives';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    DraftModule,
    PaginatorModule,
    TriggerPublishModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.draftsLibrary',
          url: '/drafts-library?draftId&noteId',
          component: DraftsLibraryComponent
        }
      ]
    }),
    TriggerPublishModule,
    LoaderModule
  ],
  declarations: [DraftsLibraryComponent],
  entryComponents: [DraftsLibraryComponent]
})
export class DraftsLibraryModule {}
