import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesModule } from '../../../common/services/services.module';
import { BulkEditModule } from './bulk-edit/bulk-edit.module';
import { TwitterThreadsModule } from './twitter-threads/twitter-threads.module';

@NgModule({
  imports: [CommonModule, ServicesModule, BulkEditModule, TwitterThreadsModule]
})
export class OutboxModule {}
