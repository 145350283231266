import './content-generator-feed.component.scss';
import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { Feed } from '../content-generator.service';

@Component({
  selector: 'ssi-content-generator-feed',
  templateUrl: './content-generator-feed.component.html'
})
export class ContentGeneratorFeedComponent implements OnChanges {
  @Input() feeds: Feed[] = [];
  @Input() selectedFeeds: Feed[];
  @Output() onFeedChange: EventEmitter<Feed[]> = new EventEmitter<Feed[]>();
  @Output() onDelete: EventEmitter<Feed> = new EventEmitter<Feed>();
  trackById = (index: number, feed: Feed) => feed.id;
  accountFilterFn = (feed: Feed) => feed.account_id;
  companyFilterFn = (feed: Feed) => !feed.account_id;

  constructor() {}

  public feedChange(feed): void {
    if (feed.isSelected) {
      this.selectedFeeds.push(feed);
    } else {
      this.selectedFeeds = this.selectedFeeds.filter(
        (_feed) => _feed.id !== feed.id
      );
    }
    this.onFeedChange.emit(this.selectedFeeds);
  }

  ngOnChanges(changes): void {
    if (changes.feeds) {
      this.feeds.forEach((feed) => {
        if (typeof feed.isSelected === 'undefined') {
          feed.isSelected = true;
        }
      });
      this.selectedFeeds = this.feeds;
      this.onFeedChange.emit(this.selectedFeeds);
    }
  }
}
