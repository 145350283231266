
    <div class="notes-textarea-container">
      <textarea
        #textarea
        ngModel
        class="textarea-input"
        [class.borderless]="borderless"
        [mention]="tagList"
        [mentionConfig]="{
          labelKey: mentionLabelKey,
          mentionSelect: onMentionSelect
        }"
        (ngModelChange)="contentChange($event)"
        [attr.placeholder]="placeholder"
        [attr.rows]="rows"
      >
      </textarea>
      <div
        class="notes-tag"
        [innerHTML]="contentWithTags | sanitizedHtml"
      ></div>
    </div>
  