<div class="insights-navbar">
  <div class="insights-navbar-column">
    <div class="insights-navbar-item">
      <span class="insights-navbar-name insights-navbar-button">
        <i class="ssi ssi-text"></i>
        <input
          required
          placeholder="Type the report name..."
          type="text"
          [(ngModel)]="reportName"
          (ngModelChange)="onReportNameChange()"
        >
      </span>
    </div>
    
    <!-- <div
      class="insights-navbar-item"
      ssiTooltip="Add Note"
      tooltipWindowClass="tooltip-chunky"
      tooltipPlacement="bottom"
    >
      <button>
        <ssi-icon icon="alignmentRight" height="14" width="14"></ssi-icon>
        <span class="hidden">Add Note</span>
      </button>
    </div>
    <div
      class="insights-navbar-item"
      ssiTooltip="Add Bullet Points"
      tooltipWindowClass="tooltip-chunky"
      tooltipPlacement="bottom"
    >
      <button class="insights-navbar-button">
        <ssi-icon icon="bulletPoints" height="14" width="17"></ssi-icon>
        <span class="hidden">Add Bullet Points</span>
      </button>
    </div>
    <div
      class="insights-navbar-item"
      ssiTooltip="Add Image"
      tooltipWindowClass="tooltip-chunky"
      tooltipPlacement="bottom"
    >
      <button class="insights-navbar-button">
        <i class="ssi ssi-image-small"></i>
        <span class="hidden">Add Image</span>
      </button>
    </div>
    <div
      class="insights-navbar-item"
      ssiTooltip="Add Video"
      tooltipWindowClass="tooltip-chunky"
      tooltipPlacement="bottom"
    >
      <button class="insights-navbar-button">
        <i class="ssi ssi-play-button"></i>
        <span class="hidden">Add Video</span>
      </button>
    </div>
    <div
      class="insights-navbar-item"
      ssiTooltip="Embed Link"
      tooltipWindowClass="tooltip-chunky"
      tooltipPlacement="bottom"
    >
      <button class="insights-navbar-button">
        <i class="ssi ssi-link-small"></i>
        <span class="hidden">Embed Link</span>
      </button>
    </div> -->
    
    <div
      class="insights-navbar-item"
      ssiTooltip="Refresh"
      tooltipWindowClass="tooltip-chunky"
      tooltipPlacement="bottom"
    >
      <button
        class="insights-navbar-button"
        (click)="refresh()"
      >
        <i class="ssi ssi-refresh-to-check"></i>
        <span class="hidden">Refresh</span>
      </button>
    </div>
    <div class="insights-navbar-item">
      <button type="button" class="insights-navbar-button insights-navbar-button-long" (click)="widgetAdd.emit()">
        <i class="ssi ssi-addsmall"></i>
        <span>Add widget</span>
      </button>
    </div>
    <div class="insights-navbar-item">
      <button class="insights-navbar-button insights-navbar-button-long" (click)="widgetCustomAdd.emit()">
        <i class="ssi ssi-addsmall"></i>
        <span>Custom widget</span>
      </button>
    </div>
  </div>
  <div class="insights-navbar-column insights-navbar-column-right">
    <div class="insights-navbar-item">
      <div *ngIf="reportID" class="insights-navbar-dropdown dropdown" ngbDropdown>
        <span class="dropdown-toggle" ngbDropdownToggle>
          <button class="insights-navbar-button insights-navbar-button-long">
            <span>Report actions</span>
            &nbsp;
            <i class="ssi ssi-arrow-down-new"></i>
          </button>
        </span>
        <ul class="dropdown-menu">
          <!-- <li><i class="ssi ssi-image-small"></i><p>Insert media or text</p></li> -->
          <!-- <li><i class="ssi ssi-link-small"></i><p>Embed link</p></li> -->
          <li (click)="goToCreateReport()"><i class="ssi ssi-copy-paste-correct"></i><p>Copy report</p></li>
          <!-- <li><i class="ssi ssi-settings-cog"></i><p>Report settings</p></li> -->
          <li class="insights-navbar-item-break"><hr></li>
          <li (click)="reportDelete.emit()"><i class="ssi ssi-small-delete insights-navbar-item-icon-red"></i><p>Delete report</p></li>
        </ul>
      </div>
    </div>
  </div>
</div>