<ng-container
  *ngIf="isInitialised"
>
  <ssi-live-chat-message-note
    *ngIf="! isExpanded && !! oldestNote"
    (clickAddNote)="onToggleForm()"
    (clickMoreNotes)="onOpenNotesScreen()"
    [canAddMore]="true"
    [canViewMore]="true"
    [message]="message"
    [note]="oldestNote"
    [user]="user"
  ></ssi-live-chat-message-note>

  <ssi-live-chat-note-form
    *ngIf="!! shouldShowNoteForm"
    (isNoteFormFocusedChange)="onIsNoteFormFocusedChange($event)"
    [isNoteFormFocused]="isNoteFormFocused"
    [message]="message"
    [user]="user"
  ></ssi-live-chat-note-form>
</ng-container>
