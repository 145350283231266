import {
  Directive,
  Input,
  Output,
  NgZone,
  Renderer2,
  ElementRef,
  OnInit,
  OnChanges,
  AfterViewChecked,
  SimpleChanges,
  EventEmitter
} from '@angular/core';

@Directive({
  selector: '[ssiObserveWidthAndHeight]'
})
export class ObserveWidthAndHeightDirective
  implements OnInit, OnChanges, AfterViewChecked {
  @Input('ssiObserveWidthAndHeight') shouldObserve = false;

  @Output() onWidthChange = new EventEmitter<string>();
  @Output() onHeightChange = new EventEmitter<string>();

  width = '';
  height = '';
  windowResizeListener: any;

  constructor(
    private _ngZone: NgZone,
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldObserve) {
      if (changes.shouldObserve.currentValue) {
        this.width = getComputedStyle(this._elementRef.nativeElement).width;
        this.onWidthChange.emit(this.width);

        this.height = getComputedStyle(this._elementRef.nativeElement).height;
        this.onHeightChange.emit(this.height);

        this.windowResizeListener = this._renderer.listen(
          window,
          'resize',
          (e) => {
            const width = getComputedStyle(this._elementRef.nativeElement)
              .width;
            if (this.width !== width) {
              this.width = width;
              this.onWidthChange.emit(this.width);
            }

            const height = getComputedStyle(this._elementRef.nativeElement)
              .height;
            if (this.height !== height) {
              this.height = height;
              this.onHeightChange.emit(this.height);
            }
          }
        );
      } else {
        if (this.width) {
          this.width = '';
          this.onWidthChange.emit(this.width);
        }

        if (this.height) {
          this.height = '';
          this.onHeightChange.emit(this.height);
        }

        if (this.windowResizeListener) {
          this.windowResizeListener(); // unbind
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    if (!this.shouldObserve) {
      return;
    }

    const width = getComputedStyle(this._elementRef.nativeElement).width;
    if (width !== this.width) {
      this.width = width;
      this.onWidthChange.emit(this.width);
    }

    const height = getComputedStyle(this._elementRef.nativeElement).height;
    if (height !== this.height) {
      this.height = height;
      this.onHeightChange.emit(this.height);
    }
  }
}
