import './triggers-and-actions.component.scss';
import { Component, OnInit, Input, Injector } from '@angular/core';
import { Applet } from '../../../ifttt-utils/applets/applet';
import {
  ActionConfig,
  ActionParamConfig,
  ParamKeyValueMap,
  TriggerConfig,
  TriggerParamConfig,
  UIParamFormType
} from '../../../ifttt-utils/ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import { IftttService } from '../../../ifttt-utils/ifttt-service-model/ifttt-service-model';
import { TranslateService } from '@ngx-translate/core';
import { StateService, Transition } from '@uirouter/angular';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
  IngredientConfig,
  IngredientType
} from '../../../ifttt-utils/service-mappings/util';
import { TriggersIngredientTextareaDirective } from './triggers-ingredient-textarea/triggers-ingredient-textarea.directive';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HighlightTag } from '../../../../../../common/components/text-input-highlight';
import { CompanyService } from '../../../../../../common/services/company/company.service';

function requiredList(fromControl: FormControl) {
  return fromControl.value && fromControl.value.length > 0
    ? null
    : {
        requiredList: {
          valid: false
        }
      };
}

const paramTypeValidators = Object.freeze({
  [UIParamFormType.SelectSingle]: Validators.required,
  [UIParamFormType.SelectMultiple]: requiredList,
  [UIParamFormType.TextInput]: Validators.required,
  [UIParamFormType.Textarea]: Validators.required,
  [UIParamFormType.Sentiment]: requiredList,
  [UIParamFormType.TagsInput]: requiredList,
  [UIParamFormType.Range]: Validators.required
});

export async function triggersResolveFn(
  service: IftttService
): Promise<TriggerConfig[]> {
  try {
    return await service.getTriggers();
  } catch (e) {
    console.log(e);
  }
}

export async function exclusiveTriggersResolveFn(service: IftttService) {
  try {
    return await service.getExclusiveTriggers();
  } catch (e) {
    console.log(e);
  }
}

export async function actionsResolveFn(
  service: IftttService
): Promise<ActionConfig[]> {
  try {
    return await service.getActions();
  } catch (e) {
    console.log(e);
  }
}

@Component({
  templateUrl: './triggers-and-actions.component.html'
})
export class TriggersAndActionsComponent implements OnInit {
  static resolve = [
    {
      token: 'triggers',
      resolveFn: triggersResolveFn,
      deps: ['service']
    },
    {
      token: 'exclusiveTriggers',
      resolveFn: exclusiveTriggersResolveFn,
      deps: ['service']
    },
    {
      token: 'actions',
      resolveFn: actionsResolveFn,
      deps: ['service']
    }
  ];

  @Input() applet: Applet;
  @Input() triggers: TriggerConfig[];
  @Input() exclusiveTriggers: any;
  @Input() actions: ActionConfig[];
  @Input() service: IftttService;

  UIParamFormType = UIParamFormType;
  mode: 'triggers' | 'actions' = 'triggers';

  triggersAndActions: {
    triggers: Array<{
      item: TriggerConfig;
      hasValue: boolean;
      valueLabel: string;
      paramColumns: TriggerParamConfig[][];
    }>;
    actions: Array<{
      item: ActionConfig;
      hasValue: boolean;
      valueLabel: string;
      paramColumns: ActionParamConfig[][];
    }>;
  };

  edit: {
    triggerOrAction?: TriggerConfig | ActionConfig;
    search?: string;
    filteredParamOptions: any;
    showIngredients?: boolean;
    paramForm?: FormGroup;
    textareaHighlightTags?: HighlightTag[];
    textareaSelectionStart?: number;
  } = {
    filteredParamOptions: {}
  };

  ingredients: IngredientConfig[];

  disabledTriggers: any = [];
  disabledActions: string[] = [];

  constructor(
    private state: StateService,
    private transition: Transition,
    private translate: TranslateService,
    private injector: Injector,
    private company: CompanyService
  ) {}

  async ngOnInit() {
    console.log('applet: ', this.applet);
    console.log('triggers: ', this.triggers);
    console.log('exclusiveTriggers: ', this.exclusiveTriggers);
    console.log('actions: ', this.actions);
    console.log('service: ', this.service);

    this.triggers = this.triggers.filter((trigger) => trigger);
    this.mode = this.transition.params().mode;
    this.updateTriggersAndActions();
    this.ingredients = this.service.getIngredients(IngredientType.Template);
  }

  updateTriggersAndActions() {
    try {
      if (
        !(
          !!this.applet &&
          !!this.applet.triggers &&
          !!this.applet.actions &&
          Array.isArray(this.applet.triggers) &&
          Array.isArray(this.applet.actions)
        )
      ) {
        throw new Error(
          `Value for 'triggers and actions applet triggers' not in expected format.`
        );
      }

      this.triggersAndActions = {
        triggers: this.triggers
          .filter(
            (trigger) => !trigger.isAccountTrigger && !trigger.isStreamTrigger
          )
          .map((trigger) => {
            const selectedTrigger = this.applet.triggers.find(
              (iTrigger) => iTrigger.trigger === trigger
            );
            const hasValue = !!selectedTrigger;
            return {
              item: trigger,
              hasValue,
              description: this.translate.instant(
                trigger.translationIds.description
              ),
              valueLabel: hasValue
                ? selectedTrigger.trigger.getLabel(
                    selectedTrigger.params,
                    this.injector,
                    this.service
                  )
                : '',
              paramColumns: this.getChunkParams(trigger.params)
            };
          })
          .filter(
            (trigger) => !this.disabledTriggers.includes(trigger.item.api.name)
          ),
        actions: this.actions
          .filter((action) => !action.api.action.deprecated)
          .map((action) => {
            const selectedAction = this.applet.actions.find(
              (iAction) => iAction.action === action
            );
            const hasValue = !!selectedAction;
            return {
              item: action,
              hasValue,
              description: this.translate.instant(
                action.translationIds.description
              ),
              valueLabel: hasValue
                ? selectedAction.action.getLabel(
                    selectedAction.params,
                    this.injector,
                    this.service
                  )
                : '',
              paramColumns: this.getChunkParams(action.params)
            };
          })
          .filter(
            (action) => !this.disabledActions.includes(action.item.api.name)
          )
      };

      if (Object.keys(this.exclusiveTriggers).length !== 0) {
        this.setExclusiveTriggers();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  setExclusiveTriggers() {
    let activeTriggers = [];

    if (this.applet.labels.hasOwnProperty('triggers')) {
      activeTriggers = this.applet.labels.triggers.map((trigger) =>
        trigger.toLowerCase()
      );
    }

    const exclusiveTrigger = activeTriggers.filter((trigger) =>
      this.exclusiveTriggers.includes(trigger)
    );

    const disabledTriggers = this.exclusiveTriggers.filter(
      (trigger) => !exclusiveTrigger.includes(trigger)
    );

    if (exclusiveTrigger.length) {
      this.triggersAndActions.triggers = this.triggersAndActions.triggers.filter(
        (trigger) => {
          return !disabledTriggers.includes(trigger.item.api.name);
        }
      );
    }
  }

  startEditTriggerOrActionValue(triggerOrAction: TriggerConfig | ActionConfig) {
    try {
      if (
        !(
          !!this.applet &&
          !!this.applet.triggers &&
          !!this.applet.actions &&
          Array.isArray(this.applet.triggers) &&
          Array.isArray(this.applet.actions)
        )
      ) {
        throw new Error(
          `Value for 'triggers and actions applet triggers' not in expected format.`
        );
      }

      const selectedTriggerOrAction: any =
        this.mode === 'triggers'
          ? this.applet.triggers.find(
              (iTrigger) => iTrigger.trigger === triggerOrAction
            )
          : this.applet.actions.find(
              (iAction) => iAction.action === triggerOrAction
            );

      if (triggerOrAction.params.length > 0) {
        this.edit = {
          triggerOrAction,
          filteredParamOptions: {},
          showIngredients: false
        };

        const formControls = {};

        if (selectedTriggerOrAction) {
          // trigger or action is already selected, prepopulate with existing user inputted params
          selectedTriggerOrAction.params.forEach((param) => {
            formControls[param.name] = new FormControl(
              param.value,
              param.form ? paramTypeValidators[param.form.type] : undefined
            );

            if (
              param.name === 'mode' &&
              this.showExactMatchesCheckbox(selectedTriggerOrAction)
            ) {
              formControls['exactMatches'] = new FormControl(param.value === 1);
            }
          });
        } else {
          // trigger or action is not yet selected, prepopulate with default params
          (triggerOrAction as TriggerConfig).params.map((param) => {
            formControls[param.name] = new FormControl(
              param.form.defaultValue,
              param.form ? paramTypeValidators[param.form.type] : undefined
            );

            if (
              param.name === 'mode' &&
              this.showExactMatchesCheckbox(triggerOrAction)
            ) {
              formControls['exactMatches'] = new FormControl(false);
            }
          });
        }
        this.edit.paramForm = new FormGroup(formControls);
      } else {
        // is a boolean trigger
        this.saveEditTriggerOrActionValue(
          triggerOrAction,
          selectedTriggerOrAction ? null : true
        );
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  showExactMatchesCheckbox(triggerOrAction): boolean {
    const triggerApi =
      triggerOrAction.api ||
      (triggerOrAction.item && triggerOrAction.item.api) ||
      (triggerOrAction.trigger && triggerOrAction.trigger.api) ||
      triggerOrAction;

    return triggerApi.name === 'queries match content';
  }

  saveEditTriggerOrActionValue(
    triggerOrAction: TriggerConfig | ActionConfig,
    values: ParamKeyValueMap | null | true
  ) {
    // always remove the trigger or action first
    if (this.mode === 'triggers') {
      this.applet.removeTrigger(triggerOrAction as TriggerConfig);
    } else {
      this.applet.removeAction(triggerOrAction as ActionConfig);
    }

    let hasSetValue = false;

    if (values === true) {
      hasSetValue = true;
    } else {
      hasSetValue =
        values &&
        Object.values(values).some((value) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          } else if (typeof value === 'string') {
            return value.length > 0;
          } else {
            return typeof value !== 'undefined';
          }
        });
    }

    if (hasSetValue) {
      if (this.mode === 'triggers') {
        if (
          values &&
          typeof values === 'object' &&
          values.hasOwnProperty('mode') &&
          values.hasOwnProperty('exactMatches')
        ) {
          values['mode'] = values['exactMatches'] ? 1 : 0;
          delete values['exactMatches'];
        }

        this.applet.addTrigger(
          triggerOrAction as TriggerConfig,
          values as ParamKeyValueMap
        );
      } else {
        this.applet.addAction(
          triggerOrAction as ActionConfig,
          values as ParamKeyValueMap
        );
      }
    }

    this.updateTriggersAndActions();
    this.cancelEdit();
  }

  cancelEdit() {
    this.edit = {
      filteredParamOptions: {},
      showIngredients: false
    };
  }

  goBack() {
    if (this.mode === 'triggers') {
      this.applet.streams.length
        ? this.state.go('^.streams')
        : this.state.go('^.accounts');
    } else {
      this.state.go('^.triggersAndActions', { mode: 'triggers' });
    }
  }

  goForward() {
    if (this.mode === 'triggers') {
      this.state.go('^.triggersAndActions', { mode: 'actions' });
    } else {
      this.state.go('^.summary');
    }
  }

  trackByName(index, triggerOrAction): string {
    return triggerOrAction.name;
  }

  trackByApiName(index, triggerOrAction): string {
    return triggerOrAction.item.api.name;
  }

  trackByIndex(index, item) {
    return index;
  }

  trackBySelectOption(index, option) {
    return option.trackBy;
  }

  filterOptions(param: TriggerParamConfig) {
    if (this.edit.search) {
      this.edit.filteredParamOptions[
        param.name
      ] = param.form.select.options.filter((option) => {
        return option.label
          .toLowerCase()
          .includes(this.edit.search.toLowerCase());
      });
    } else {
      delete this.edit.filteredParamOptions[param.name];
    }
  }

  toggleIngredientsPopover(popover: NgbPopover) {
    this.edit.showIngredients = !this.edit.showIngredients;
    if (this.edit.showIngredients) {
      popover.open();
    } else {
      popover.close();
    }
  }

  addIngredientToTextParam(
    ingredient: IngredientConfig,
    ingredientsPopover: NgbPopover,
    ingredientsTextarea: TriggersIngredientTextareaDirective
  ) {
    this.toggleIngredientsPopover(ingredientsPopover);
    ingredientsTextarea.addIngredientAtCaret(
      ingredient,
      this.edit.textareaSelectionStart
    );
  }

  convertType(formItemName: string, type: 'integer') {
    const value = this.edit.paramForm.controls[formItemName].value;
    if (!value) {
      return;
    }
    switch (type) {
      case 'integer':
        this.edit.paramForm.controls[formItemName].setValue(+value);
        break;
      default:
        console.error("Can't convert type because type not supported");
    }
  }

  protected getChunkParams(params) {
    const columns = [];
    let chunk = [];

    params.forEach((param) => {
      if (param.form.startColumn) {
        columns.push(chunk);
        chunk = [];
      }
      chunk.push(param);
    });
    columns.push(chunk);

    return columns;
  }
}
