import {
  Account,
  AccountModel,
  Profile,
  ProfileModel
} from '@ui-resources-angular';
import { appInjector } from '../../../../../angular/app-injector';
import { Sentiment, sentimentsIterable } from '../../../constants/sentiments';
import { Colleague, ColleaguesService } from '../colleagues';
import { Team, TeamsService } from '../teams';

export enum AuditEventVerb {
  // Inbox events
  Viewed = 'viewed',
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  StatusChange = 'statusChange',
  SentimentChanged = 'sentimentChanged',
  PriorityChanged = 'priorityChanged',
  AddedTags = 'addedTags',
  RemovedTags = 'removedTags',
  AddedNote = 'addedNote',
  DeletedNote = 'deletedNote',
  SocialLiked = 'socialLiked',
  SocialUnliked = 'socialUnliked',
  SocialHide = 'socialHide',
  SocialUnHide = 'socialUnHide',
  Emailed = 'emailed',
  Resolved = 'resolved',
  Unresolved = 'unresolved',
  Blocked = 'blocked',
  Unblocked = 'unblocked',
  Redacted = 'redacted',
  SensitiveMediaViewed = 'sensitiveMediaViewed',
  SensitiveMediaHidden = 'sensitiveMediaHidden',

  // Common
  Deleted = 'deleted',

  // Outbox events
  Created = 'created',
  Updated = 'updated',
  Accepted = 'accepted', // approved
  Rejected = 'rejected',
  Published = 'published',
  PublishFailed = 'publish-failed'
}

export class AuditEventActor {
  user_id: number;
  image?: string;
  displayName: string;
  trigger_name: string;

  constructor(props: any) {
    this.user_id = props.user_id;
    this.image = props.image;
    this.displayName = props.displayName;
    this.trigger_name = props.trigger_name;
  }

  get isTrigger(): boolean {
    return !!this.trigger_name;
  }

  get name(): string {
    return this.isTrigger ? `"${this.trigger_name}"` : this.displayName;
  }
}

export class AuditEventObject {
  activity_id?: string;
  outbox_id?: string;
  profile_id?: string;

  // derived props
  profile?: Profile;

  constructor(props: any, accountId: number) {
    this.activity_id = props.activity_id;
    this.outbox_id = props.outbox_id;
    this.profile_id = props.profile_id;

    if (this.profile_id && accountId) {
      appInjector()
        .get(ProfileModel)
        .findByIdAndAccountId(this.profile_id, accountId, null, false)
        .then((profile) => {
          this.profile = profile;
        })
        .catch((e) => {
          console.error('Cannot get profie info for audit:', e);
        });
    }
  }
}

export class AuditEventResult {
  // presence of these props depends on the verb (event type)
  // Inbox
  note_id?: string;
  note_text?: string;

  // Outbox
  social_id?: string; // present for 'published' event

  constructor(props: any) {
    this.note_id = props.note_id;
    this.note_text = props.note_text;

    this.social_id = props.social_id;
  }
}

export class AuditEventParams {
  // presence of these props depends on the verb (event type)
  // Inbox
  group_id?: number;
  user_id?: number;
  status?: string;
  sentiment?: number;
  tags?: string[];

  // Outbox
  scheduled_date?: string;
  published_date?: string;
  text?: string;

  constructor(props: any) {
    this.group_id = props.group_id;
    this.user_id = props.user_id;
    this.status = props.status;
    this.sentiment = props.sentiment;
    this.tags = props.tags;

    this.scheduled_date = props.scheduled_date;
    this.published_date = props.published_date;
    this.text = props.text;
  }

  get user(): Colleague {
    if (!this.user_id) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.user_id);
  }

  get team(): Team {
    if (!this.group_id) {
      return undefined;
    }
    const teamsService = appInjector().get(TeamsService);
    return teamsService.store.find(this.group_id);
  }

  get userOrTeamName(): string {
    return this.user ? this.user.fullName : this.team ? this.team.name : '';
  }

  get sentimentConst(): Sentiment {
    return sentimentsIterable.find((s) => s.numericKey === this.sentiment);
  }
}

export class AuditEvent {
  account_id: number;
  timestamp: string;
  verb: AuditEventVerb;
  actor: AuditEventActor;
  object: AuditEventObject;
  result?: AuditEventResult;
  params?: AuditEventParams;

  // derived props
  account: Account;

  constructor(props: any) {
    this.account_id = props.account_id;
    this.timestamp = props.timestamp;
    this.verb = props.verb;
    this.actor = new AuditEventActor(props.actor);
    this.object = new AuditEventObject(props.object, this.account_id);
    this.result = props.result && new AuditEventResult(props.result);
    this.params = props.params && new AuditEventParams(props.params);

    if (
      this.timestamp &&
      this.params &&
      this.params.scheduled_date &&
      this.timestamp === this.params.scheduled_date
    ) {
      // TODO: ask backend to remove scheduled_date if it's the same as created date!?
      this.params.scheduled_date = undefined;
    }

    if (this.account_id) {
      this.account = appInjector().get(AccountModel).get(this.account_id);
    }
  }
}

export class AuditEventsPagination {
  total: number;
  next?: string;
  previous?: string;

  constructor(props: any) {
    this.total = props.total;
    this.next = props.next;
    this.previous = props.previous;
  }
}

export class AuditEventsResponse {
  events: AuditEvent[];
  pagination: AuditEventsPagination;

  constructor(props: any) {
    this.events = props.events.map((e) => new AuditEvent(e));
    this.pagination = new AuditEventsPagination(props.pagination);
  }
}
