import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ComposeAreaComponent } from './compose-area.component';
import { YoutubePostOptionsSelectComponent } from '../youtube-post-options-select/youtube-post-options-select.component';
import { InstagramPostOptionsSelectComponent } from '../instagram-post-options-select/instagram-post-options-select.component';
import { FacebookPostOptionsSelectComponent } from '../facebook-post-options-select/facebook-post-options-select.component';
import { TwitterPostOptionsSelectComponent } from '../twitter-post-options-select/twitter-post-options-select.component';

import { DropdownSelectModule } from '../../dropdown-select/dropdown-select.module';
import { DropdownSelect2Module } from '../../dropdown-select-2/dropdown-select-2.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ListSelectModule } from '../../list-select/list-select.module';
import { TooltipModule } from '../../../directives/tooltip/tooltip.module';
import { MediaNavModule } from '../../media-nav/media-nav.module';
import { EnlargeImageModule } from '../../../directives/enlarge-image/enlarge-image.module';
import { ContextMenuModule } from '../../context-menu/context-menu.module';
import { TextInputAutocompleteModule } from '../../text-input-autocomplete';
import { TextInputHighlightModule } from '../../text-input-highlight';
import { EmojiFormControlContainerModule } from '../../../directives/emoji-form-control-container/emoji-form-control-container.module';
import { TruncateModule } from '../../../pipes/truncate/truncate.module';
import { FileUploaderModule } from '../../../directives/file-uploader/file-uploader.module';
import { OutboxTypeaheadModule } from '../../outbox-typeahead/outbox-typeahead.module';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '../../publisher/publisher-active';
import { Subject } from 'rxjs';
import { PublishModule } from '../../../../../../library/ui-resources/angular/modules/publish.module';
import { OffClickModule } from '../../../directives/off-click/off-click.module';
import { EmojiAutocompleteModule } from '../../emoji-autocomplete/emoji-autocomplete.module';
import { IconModule } from '../../icon/icon.module';
import { GifSearchModule } from '../../../directives/gif-search/gif-search.module';
import { ToggleSwitch2Module } from '../../toggle-switch-2/toggle-switch-2.module';
import { OrloComponentsModule } from 'orlo-components';
import { Checkbox2Module } from '../../checkbox-2/checkbox-2.module';
import { SpinnerModule } from '../../spinner/spinner.module';
import { MenuModule } from '../../menu/menu.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { CheckboxModule } from '../../checkbox/checkbox.module';
import { AccountSelectorModule } from '../account-selector/account-selector.module';
import { SocialNetworkIconModule } from '../../social-network-icon/social-network-icon.module';

export function publisherActiveFactory(): PublisherActive {
  return new Subject();
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    DropdownSelectModule,
    DropdownSelect2Module,
    ListSelectModule,
    TooltipModule,
    MediaNavModule,
    EmojiFormControlContainerModule,
    TruncateModule,
    TranslateModule,
    FileUploaderModule,
    OutboxTypeaheadModule,
    PublishModule,
    EnlargeImageModule,
    ContextMenuModule,
    TextInputAutocompleteModule,
    TextInputHighlightModule,
    OffClickModule,
    EmojiAutocompleteModule,
    IconModule,
    GifSearchModule,
    ToggleSwitch2Module,
    OrloComponentsModule,
    Checkbox2Module,
    SpinnerModule,
    OverlayModule,
    MenuModule,
    SpinnerModule,
    CheckboxModule,
    AccountSelectorModule,
    SocialNetworkIconModule
  ],
  declarations: [
    ComposeAreaComponent,
    YoutubePostOptionsSelectComponent,
    InstagramPostOptionsSelectComponent,
    FacebookPostOptionsSelectComponent,
    TwitterPostOptionsSelectComponent
  ],
  providers: [],
  exports: [ComposeAreaComponent]
})
export class ComposeAreaModule {}
