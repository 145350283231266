import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { CampaignBoxComponent } from './campaign-box.component';
import { AddSecondsModule } from '../../../../../common/pipes/add-seconds/add-seconds.module';
import { flatpickrFactory } from '../../../../../common/components/publisher/publisher.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    FlatpickrModule.forRoot(),
    AddSecondsModule,
    TooltipModule,
    BigNumberModule,
    FormsModule
  ],
  declarations: [CampaignBoxComponent],
  exports: [CampaignBoxComponent]
})
export class CampaignBoxModule {}
