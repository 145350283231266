<a
  [href]="account.externalUrl"
  target="_blank"
  class="twitter-post-header"
>
  <img
    class="img-circle pull-left"
    [src]="account.picture"
    ssiFallbackImage="avatar"
  >
  <div class="twitter-post-account-name">{{ account.name }}<span
      class="twitter-post-account-username">@{{ account.username }}</span>
    <i class="ssi ssi-twitter-preview-more"></i>
  </div>


</a>
<div class="twitter-post-body">

  <div
    class="twitter-post-reply-to"
    *ngIf="post.reply"
  >
    Replying to
    <a
      target="_blank"
      [href]="'https://twitter.com/' + account.username + '/status/' + post.reply.socialId"
      *ngIf="mentions.length === 0"
    >
      @{{ account.username }}
    </a>
    <span *ngFor="let mention of mentions; let last = last">
      <a
        target="_blank"
        [href]="mention.data.url"
      >@{{ mention.data.username }}</a><span *ngIf="!last">, </span>
    </span>
  </div>

  <div
    class="twitter-post-text"
    [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br"
  ></div>

  <div
    class="twitter-post-images"
    [class.single-image]="files.length === 1"
    [class.two-images]="files.length === 2"
    [class.three-images]="files.length === 3"
    [class.four-images]="files.length === 4"
    *ngIf="files.length > 0 && files[0].type === 'image'"
  >
    <div class="twitter-post-images-left">
      <img [src]="files[0].url">
    </div>
    <div
      class="twitter-post-images-right"
      *ngIf="files.length > 1"
    >
      <img [src]="files[1].url">
      <img
        [src]="files[2].url"
        *ngIf="files[2]"
      >
      <img
        [src]="files[3].url"
        *ngIf="files[3]"
      >
    </div>
  </div>

  <div
    class="twitter-post-video"
    *ngIf="files.length === 1 && files[0].type === 'video'"
  >
    <video controls>
      <source
        [src]="files[0].url"
        type="video/mp4"
      >
      Sorry but we could not load the video preview.
    </video>
  </div>

  <a
    [href]="preview.url"
    target="_blank"
    class="twitter-post-link-preview"
    *ngIf="preview && files.length === 0"
  >
    <img [src]="preview.image">
    <div class="twitter-post-link-preview-footer">
      <div class="twitter-post-link-preview-title">
        {{ preview.title }}
      </div>
      <div class="twitter-post-link-preview-description">
        {{ preview.description }}
      </div>
      <div class="twitter-post-link-preview-hostname">
        <i class="ssi ssi-twitter-preview-link"></i>
        {{ preview.hostname }}
      </div>
    </div>
  </a>

</div>
<div class="twitter-post-footer">
  <span class="twitter-post-footer-item"><i class="ssi ssi-twitter-preview-replies"></i></span>
  <span class="twitter-post-footer-item"><i class="ssi ssi-twitter-preview-retweet"></i></span>
  <span class="twitter-post-footer-item"><i class="ssi ssi-twitter-preview-like"></i></span>
  <span class="twitter-post-footer-item"><i class="ssi ssi-twitter-preview-actions"></i></span>
  <span class="twitter-post-footer-item"><i class="twitter-analytics"></i></span>
</div>