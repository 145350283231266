<div class="my-details">
	<div class="flex-grid container">
		<div class="col">
			<div class="form">
				<form (ngSubmit)="onSubmit()" [formGroup]="myDetailsForm">
					<orlo-text-input
						[disabled]="!authUser.hasCompanyPermission('administer_users')"
						[ssiTooltip]="'Administer users permission required'"
						[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')"
						[width]="'300'"
						[label]="'First name'"
						[name]="'first_name'"
						[parentForm]="myDetailsForm"
						[type]="'text'" [required]="true"
						[placeholder]="'Your first name...'"
						[invalid]="myDetailsForm.controls.first_name?.touched && myDetailsForm.controls.first_name?.invalid ? true : false"
						[invalidMessage]="'Woops! We\'ll need you to fill this one in...'">
					</orlo-text-input>
					<orlo-text-input
						[disabled]="!authUser.hasCompanyPermission('administer_users')"
						[ssiTooltip]="'Administer users permission required'"
						[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')"
						[width]="'300'"
						[label]="'Last name'"
						[name]="'last_name'"
						[parentForm]="myDetailsForm"
						[type]="'text'"
						[required]="true"
						[placeholder]="'Your last name...'"
						[invalid]="myDetailsForm.controls.last_name?.touched && myDetailsForm.controls.last_name?.invalid ? true : false"
						[invalidMessage]="'Woops! We\'ll need you to fill this one in...'">
					</orlo-text-input>
					<orlo-text-input
						[disabled]="!authUser.hasCompanyPermission('administer_users')"
						[ssiTooltip]="'Administer users permission required'"
						[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')"
						[width]="'300'"
						[label]="'Email'"
						[name]="'email_address'"
						[parentForm]="myDetailsForm"
						[type]="'email'"
						[required]="true"
						[placeholder]="'For your Orlo login...'"
						[invalid]="myDetailsForm.controls.email?.touched && myDetailsForm.controls.email?.invalid ? true : false"
						[invalidMessage]="'Woops! We\'ll need you to fill this one in...'">
					</orlo-text-input>
					<orlo-text-input
						[disabled]="!authUser.hasCompanyPermission('administer_users')"
						[ssiTooltip]="'Administer users permission required'"
						[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')"
						[width]="'300'"
						[label]="'Mobile (for Two-Factor auth)'"
						[name]="'mobile'"
						[parentForm]="myDetailsForm"
						[type]="'tel'"
						[placeholder]="'+44 format'">
					</orlo-text-input>
					<div class="form-submit">
						<orlo-button [text]="'Save changes'" [state]="'primary'" [type]="'submit'" [disabled]="myDetailsForm.invalid || myDetailsForm.pristine"></orlo-button>
					</div>
				</form>
			</div>
		</div>
		<div class="col">
			<div class="avatar">
				<i class="ssi ssi-mydetails"></i>
				<span class="avatar-label">
				Profile picture
				</span>
				<div class="avatar-image">
					<img [src]="sanitizeURL(avatar)" />
					<button class="avatar-delete" (click)="deleteAvatar()"><i class="ssi ssi-delete"></i></button>
				</div>
				<div>
				<orlo-button [state]="'primary'" [text]="'Change Picture'" (btnClick)="pickAvatar()"></orlo-button>
				</div>
			</div>
		</div>
	</div>
</div>
