import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AddWidgetComponent } from './add-widget.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [],
  providers: [],
  declarations: [AddWidgetComponent],
  entryComponents: [AddWidgetComponent]
})
export class AddWidgetModule {}
