import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownMenuComponent } from './dropdown-menu.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DropdownMenuComponent],
  exports: [DropdownMenuComponent],
  providers: []
})
export class DropdownMenuModule {}
