import { mapToIterable } from '../utils';

export interface Sentiment {
  key: string;
  key2: string;
  key3: string;
  numericKey: number;
  numericKey2: number;
  label: string;
  labelNew: string;
  icon: string;
  color: string;
  color2: string;
  // colorNew: string;
  bgColor: string;
  bgColor2: string;
  iconColor: string;
  iconColor2: string;
  iconBgColor: string;
  iconBgColor2: string;
  // secondaryBgColor: string;
  lowerIsPositive: boolean;
}

export interface Sentiments {
  positive: Sentiment;
  semi_positive: Sentiment;
  neutral: Sentiment;
  semi_negative: Sentiment;
  negative: Sentiment;
}

export const sentiments: Sentiments = {
  positive: {
    key: 'positive',
    key2: 'positive',
    key3: 'Very Positive',
    numericKey: 2,
    numericKey2: 0.75,
    label: 'POSITIVE',
    labelNew: 'VERY_POSITIVE',
    icon: 'ssi-positive-new',
    color: '#00bde9',
    color2: '#00C673',
    // colorNew: '#14BAE3',
    bgColor: '#00bde9',
    bgColor2: 'rgb(0, 198, 115, 0.2)',
    iconColor: '#fff',
    iconColor2: '#fff',
    iconBgColor: '#14bae3',
    iconBgColor2: '#00C673',
    // secondaryBgColor: '#14bae31a',
    lowerIsPositive: false
  },
  semi_positive: {
    key: 'semi_positive',
    key2: 'semiPositive',
    key3: 'Positive',
    numericKey: 1,
    numericKey2: 0.25,
    label: 'SEMI_POSITIVE',
    labelNew: 'POSITIVE',
    icon: 'ssi-semi-positive-new',
    color: '#98efff',
    color2: '#9FD700',
    // colorNew: '#B2F0FF',
    bgColor: '#F4F4FA',
    bgColor2: 'rgb(159, 215, 0, 0.2)',
    iconColor: '#14bae3',
    iconColor2: '#749D00',
    iconBgColor: '#dff9ff',
    iconBgColor2: '#DEEC74',
    // secondaryBgColor: '#e0f9ff80',
    lowerIsPositive: false
  },
  neutral: {
    key: 'neutral',
    key2: 'neutral',
    key3: 'Passive',
    numericKey: 0,
    numericKey2: 0,
    label: 'NEUTRAL',
    labelNew: 'PASSIVE',
    icon: 'ssi-neutral-new',
    color: '#d4e0fa',
    color2: '#D6DFF7',
    // colorNew: '#D6DFF7',
    bgColor: '#F4F4FA',
    bgColor2: 'rgb(214, 223, 247, 0.2)',
    iconColor: '#657fc8',
    iconColor2: '#43537F',
    iconBgColor: '#d6dff7b3',
    iconBgColor2: '#D6DFF7',
    // secondaryBgColor: '#d6dff780',
    lowerIsPositive: false
  },
  semi_negative: {
    key: 'semi_negative',
    key2: 'semiNegative',
    key3: 'Negative',
    numericKey: -1,
    numericKey2: -0.25,
    label: 'SEMI_NEGATIVE',
    labelNew: 'NEGATIVE',
    icon: 'ssi-semi-negative-new',
    color: '#ffe6e0',
    color2: '#FFB801',
    // colorNew: '#FFBFA9',
    bgColor: '#FFF1ED',
    bgColor2: 'rgb(255, 184, 1, 0.2)',
    iconColor: '#f88c68',
    iconColor2: '#fff',
    iconBgColor: '#fff1ed',
    iconBgColor2: '#FFA962',
    // secondaryBgColor: '#fff1ed80',
    lowerIsPositive: true
  },
  negative: {
    key: 'negative',
    key2: 'negative',
    key3: 'Very Negative',
    numericKey: -2,
    numericKey2: -0.75,
    label: 'NEGATIVE',
    labelNew: 'VERY_NEGATIVE',
    icon: 'ssi-negative-new',
    color: '#ff865e',
    color2: '#FF4D11',
    // colorNew: '#F88C68',
    bgColor: '#FFF1ED',
    bgColor2: 'rgb(255, 77, 17, 0.2)',
    iconColor: '#fff',
    iconColor2: '#fff',
    iconBgColor: '#f88c68',
    iconBgColor2: '#FF6E3D',
    // secondaryBgColor: '#f88c681a',
    lowerIsPositive: true
  }
};

export const sentimentsIterable: Sentiment[] = mapToIterable(sentiments);

export function findSentimentConst(key: string, value: any): Sentiment {
  return sentimentsIterable.find((s) => String(s[key]) === String(value));
}
