<div class="top-tags">
  <div class="tag-podiums">
    <div class="tag-bar top g-stat-box" *ngFor="let tag of tags | slice:0:3; let i = index;">
      <ssi-medal-score [place]="i + 1"></ssi-medal-score>
      <div class="progress-bar-wrapper">
        <div><span>#{{tag.value}}</span></div>
        <ssi-progress-bar [max]="topTagCount"
                          [progress]="tag.amount"
                          [color]="'#89A4EA'"></ssi-progress-bar>
        </div>
        <div class="tag-count">
          <span>{{tag.amount}}</span>
        </div>
    </div>
  </div>
  <div class="tag-list">
    <div class="tag-bar" *ngFor="let tag of tags | slice:0:200; let i = index;">
      <div class="tag-position">
        {{i+1}}.
      </div>
      <div class="progress-bar-wrapper">
        <div><span>#{{tag.value}}</span><span class="percent">({{tag.amount / topTagCount * 100 | number: '1.0-0'}}%)</span></div>
        <ssi-progress-bar [max]="topTagCount"
                          [progress]="tag.amount"
                          [color]="'#89A4EA'"></ssi-progress-bar>
      </div>
      <div class="tag-count">
        <span>{{tag.amount}}</span>
      </div>
    </div>
  </div>
</div>