import './view-profile-modal.component.scss';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {
  Account,
  AccountModel,
  Profile,
  ProfileModel,
  CrmPerson,
  CrmPersonModel
} from '@ui-resources-angular';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { PopupService } from '../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { googleMapsAPIKey } from '../../../../angular/common/constants';
import {
  NgbTabset,
  NgbTabChangeEvent,
  NgbModal,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { PrivateMessageModalComponent } from '../private-message-modal/private-message-modal.component';
import {
  CompanyConfig,
  CompanyService
} from '../../services/company/company.service';

@Component({
  selector: 'ssi-view-profile-modal',
  templateUrl: './view-profile-modal.component.html',
  styles: []
})
export class ViewProfileModalComponent implements OnInit, AfterViewInit {
  @Input() workflowAccounts: Account[];
  @Input() showInteractions?: boolean;
  @Input() noteId?: string;
  @Input() profile: Profile;
  @Input() loadFeed?: boolean;
  @Input() accountId: string;
  @Input() dismiss: () => void;
  @ViewChild('profileTabs') profileTabs: NgbTabset;
  loadingTracker = this.asyncTracker.create();
  loadingMoreTracker = this.asyncTracker.create();
  profileTags: string[];
  mapUrl: string;
  mediaTypesChart: Array<{ name: string; y: number }>;
  otherAccounts: Account[];
  crmPerson: CrmPerson;
  company: CompanyConfig;
  hasFacebookProfileIdFeature: boolean = false;

  constructor(
    private asyncTracker: AsyncTrackerFactory,
    private profileModel: ProfileModel,
    private crmPersonModel: CrmPersonModel,
    private accountModel: AccountModel,
    private popup: PopupService,
    private translate: TranslateService,
    private modal: NgbModal,
    private activeModal: NgbActiveModal,
    private companyService: CompanyService
  ) {}

  async ngOnInit() {
    try {
      this.otherAccounts = this.workflowAccounts.filter(
        (account) =>
          !!account &&
          !!this.profile &&
          !!this.profile.account &&
          account.account_type_name === this.profile.account.account_type_name
      );

      if (this.loadFeed) {
        this.loadingTracker.add(this.profile.getFeed());
      }

      this.company = await this.companyService.getConfig();

      this.companyService
        .hasFeatureAccess('FACEBOOK_SHOW_PROFILE_ID')
        .then((enabled) => {
          this.hasFacebookProfileIdFeature = enabled;
        });

      this.crmPersonModel
        .findOrCreateFromSocialProfile(
          {
            id: this.profile.id,
            name: this.profile.info.name
          },
          this.accountModel.get(this.accountId)
        )
        .then((crmPerson) => {
          this.crmPerson = crmPerson;
        });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  close() {
    this.activeModal.close();
  }

  ngAfterViewInit() {
    if (this.noteId) {
      this.profileTabs.select('tabInfo');
    } else if (this.showInteractions) {
      this.profileTabs.select('tabInteractions');
    }
  }

  beforeTabChange(event: NgbTabChangeEvent) {
    switch (event.nextId) {
      case 'tabInteractions':
        this.loadingTracker.add(this.profile.getPastActivity(0));
        break;
      case 'tabStatistics':
        this.loadStatistics();
        break;
      default:
        break;
    }
  }

  loadStatistics() {
    const promise = this.profile.getStatistics().then(() => {
      this.mapUrl = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=roadmap&key=${googleMapsAPIKey}"`;
      this.mapUrl += this.profile.statistics.coords
        .map(
          (coord, index) =>
            `&markers=color:red|label:${index}|${coord.lng},${coord.lat}`
        )
        .join('');
      this.mediaTypesChart = Object.entries(
        this.profile.statistics.media_type
      ).map(([mediaType, value]: [string, number]) => {
        return {
          name: mediaType.replace(/_/, ' '),
          y: value
        };
      });

      ['tags', 'mentions'].forEach((type) => {
        let total = 0;
        const typeArray = Object.entries(this.profile.statistics[type]).map(
          ([label, count]: [string, number]) => {
            total += count;
            return { label, count };
          }
        );
        typeArray.sort((a, b) => b.count - a.count);
        this.profile.statistics[type] = {
          top: typeArray.splice(0, 3),
          remaining: typeArray,
          total
        };
      });
    });
    this.loadingTracker.add(promise);
  }

  changeAccount(account) {
    const refreshFeed = !!this.profile.feed;
    const promise = this.profileModel
      .findByIdAndAccountId(this.profile.id, account.id, false)
      .then((newProfile) => {
        this.profile = newProfile;
        this.profileTags = newProfile.tags;
        if (refreshFeed) {
          this.profile.getFeed();
        }
      })
      .catch(() => {
        this.dismiss();
      });
    this.loadingTracker.add(promise);
  }

  privateMessage() {
    this.close();
    const modal = this.modal.open(PrivateMessageModalComponent, {});
    modal.componentInstance.resolve = { profile: this.profile };
  }

  blockProfileModal() {
    this.popup
      .confirm({
        title: this.translate.instant('BLOCK_USER'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WANT_TO_BLOCK_THIS_USER'
        )
      })
      .then((shouldBlock) => {
        if (shouldBlock) {
          this.blockProfile();
        }
      });
  }

  unblockProfileModal() {
    this.popup
      .confirm({
        title: this.translate.instant('UNBLOCK_USER'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WANT_TO_UNBLOCK_THIS_USER'
        )
      })
      .then((shouldUnblock) => {
        if (shouldUnblock) {
          this.unblockProfile();
        }
      });
  }

  blockProfile() {
    const promise = this.profile.block().then(() => {
      this.profile.info.blocked = true;
      this.popup.alert({
        title: this.translate.instant('USER_BLOCKED'),
        message: this.translate.instant(
          'YOU_HAVE_SUCCESSFULLY_BLOCKED_THE_USER'
        )
      });
    });
    this.loadingTracker.add(promise);
  }

  unblockProfile() {
    const promise = this.profile.unblock().then(() => {
      this.profile.info.blocked = false;
      this.popup.alert({
        title: this.translate.instant('USER_UNBLOCKED'),
        message: this.translate.instant(
          'YOU_HAVE_SUCCESSFULLY_UNBLOCKED_THE_USER'
        )
      });
    });
    this.loadingTracker.add(promise);
  }
}
