import { WorkflowManagerService } from '../../../angular/common/services/workflow-manager/workflow-manager.service';
import { StateParams } from '@uirouter/angular';
import { PublisherActive } from '../../../angular/common/components/publisher/publisher-active';

class AuthCtrl {
  constructor(
    workflowManager: WorkflowManagerService,
    private $stateParams: StateParams,
    private publisherActive: PublisherActive
  ) {
    'ngInject';
    workflowManager.loadWorkflow(workflowManager.getCurrentId() || 0);
  }

  $postLink() {
    if (this.$stateParams.showPublisher) {
      this.publisherActive.next({ isActive: true });
    }
  }
}

export default AuthCtrl;
