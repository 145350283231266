import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetChartPieComponent } from './widget-chart-pie.component';
import { BigNumberModule } from '../../../../../../common/pipes/big-number/big-number.module';
import { HighchartModule } from '../../../../../../common/components/highchart/highchart.module';

@NgModule({
  declarations: [WidgetChartPieComponent],
  imports: [CommonModule, HighchartModule, BigNumberModule],
  exports: [WidgetChartPieComponent]
})
export class WidgetChartPieModule {}
