import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AccordionModule } from '../../accordion/accordion.module';
import { FacebookOptionsComponent } from './facebook-options/facebook-options.component';
import { LinkedInOptionsComponent } from './linkedin-options/linkedin-options.component';
import { NextdoorOptionsComponent } from './nextdoor-options/nextdoor-options.component';
import { TargetingOptionsComponent } from './targeting-options.component';
import { ToggleSwitch2Module } from '../../toggle-switch-2/toggle-switch-2.module';
import { CommonModule } from '@angular/common';
import { SocialNetworkIconModule } from '../../social-network-icon/social-network-icon.module';
import { DropdownSelect2Module } from '../../dropdown-select-2/dropdown-select-2.module';
import { TemplateSelectorModule } from '../../../directives/template-selector/template-selector.module';
import { OutboxTypeaheadModule } from '../../outbox-typeahead/outbox-typeahead.module';
import { TranslateModule } from '@ngx-translate/core';
import { PublishModule } from '@ui-resources-angular';

@NgModule({
  imports: [
    AccordionModule,
    CommonModule,
    FormsModule,
    ToggleSwitch2Module,
    SocialNetworkIconModule,
    DropdownSelect2Module,
    TemplateSelectorModule,
    OutboxTypeaheadModule,
    TranslateModule,
    PublishModule
  ],
  exports: [
    TargetingOptionsComponent,
    FacebookOptionsComponent,
    LinkedInOptionsComponent,
    NextdoorOptionsComponent
  ],
  declarations: [
    TargetingOptionsComponent,
    FacebookOptionsComponent,
    LinkedInOptionsComponent,
    NextdoorOptionsComponent
  ]
})
export class TargetingOptionsModule {}
