<div class="sentiment-left">
  <div class="upper">
    <ssi-big-number class="message-count"
                    [number]="currentValue"
                    [denotation]="{million: 'm', thousand: 'k'}"></ssi-big-number>
    <span>{{label}}</span>
  </div>
  <ssi-stat-change *ngIf="showStatChange"
                   [boxed]="true"
                   [lowerIsPositive]="sentiment.lowerIsPositive"
                   [current]="currentValue"
                   [previous]="previousValue"></ssi-stat-change>
</div>
<div class="sentiment-right"
     title="{{sentiment.label | translate}}">
  <i class="{{sentiment.key}} ssi {{sentiment.icon}}"
     [style.color]="sentiment.iconColor"
     [style.background-color]="sentiment.iconBgColor"></i>
</div>