import './outbox-campaign-modal.component.scss';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash-es';

import { API, Outbox } from '@ui-resources-angular';
import { CampaignsService, Campaign } from '../../../common/services/api';
@Component({
  selector: 'ssi-outbox-campaign-modal',
  templateUrl: './outbox-campaign-modal.component.html',
  styles: []
})
export class OutboxCampaignModalComponent implements OnInit {
  @Input() post: Outbox;

  parents: Campaign[] = [];
  selectedCampaign: Campaign;

  constructor(
    private api: API,
    public modalInstance: NgbActiveModal,
    private campaignsService: CampaignsService
  ) {}

  async ngOnInit() {
    let campaigns = await this.campaignsService.getAll();

    this.selectedCampaign =
      this.post.campaign_id &&
      campaigns.find((c) => c.id === this.post.campaign_id);

    campaigns = campaigns.filter((c) => !c.is_closed && !c.is_deleted);
    campaigns = orderBy(campaigns, [(c) => c.name.toLowerCase()]);

    this.parents = campaigns.filter((c) => !c.parent_id);

    this.parents.forEach((parent) => {
      const children = campaigns.filter((c) => c.parent_id === parent.id);
      if (children.length) {
        (parent as any)._children = {
          expanded: false,
          options: children
        };

        if (children.some((c) => c === this.selectedCampaign)) {
          (parent as any)._children.expanded = true;
        }
      }
    });
  }

  changeCampaign() {
    const newCampaignId = this.selectedCampaign
      ? this.selectedCampaign.id
      : null;

    const params = {
      outbox_id: this.post.id,
      new_campaign_id: newCampaignId
    };

    this.api
      .post('campaign/updateOutbox', Object.assign({}, params))
      .then(() => (this.post.campaign = this.selectedCampaign))
      .then(() => this.modalInstance.close(newCampaignId));
  }

  onModelValueChange(parent: Campaign | any): void {
    if (parent && parent._children) {
      parent._children.expanded = true;
    }
  }

  onChildModelValueChange(child: Campaign, parent: Campaign): void {}

  expandCollapseCampaign(parent: Campaign | any): void {
    parent._children.expanded = !parent._children.expanded;
  }
}
