<div class="internationlization">
  <ssi-alerts></ssi-alerts>

  <div class="flex-grid container">
    <form
      (ngSubmit)="saveInternationalization()"
      [formGroup]="internationalizationForm"
      *ngIf="internationalizationForm"
      class="form-grid align-left"
    >
      <div class="form-row">
        <div class="form-field orlo-select">
          <label
            class="form-field-label"
            translate="PREFERRED_CURRENCY"
          ></label>
          <select
            formControlName="currency_code"
            name="currency_code"
          >
            <option
              *ngFor="let currency of serverSettings.currency"
              [ngValue]="currency.code"
            >
              {{currency.name}}
            </option>

          </select>
          <i class="ssi ssi-arrow-down-correct"></i>
        </div>
      </div>

      <div class="form-submit-fixed">
        <orlo-button
          [text]="'Save changes'"
          [state]="'primary'"
          [type]="'submit'"
          [disabled]="internationalizationForm.invalid || internationalizationForm.pristine"
        ></orlo-button>
      </div>
    </form>
  </div>
</div>