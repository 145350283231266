import './advertising-edit-preset.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import {
  AdvertisingPreset,
  TargetingOption
} from '../advertising-preset-model/advertising-preset-model.service';
import { advertisingTargetingPresets } from '../../../../common-resolves';
import { Transition, StateService } from '@uirouter/angular';

@Component({
  selector: 'ssi-advertising-edit-preset',
  templateUrl: './advertising-edit-preset.component.html'
})
export class AdvertisingEditPresetComponent implements OnInit {
  static resolve = [advertisingTargetingPresets];

  @Input() advertisingTargetingPresets: AdvertisingPreset[];

  edit: {
    preset: AdvertisingPreset;
    name: string;
    targetingOptions: TargetingOption[];
  };

  constructor(private transition: Transition, private state: StateService) {}

  ngOnInit() {
    try {
      if (
        !(
          !!this.advertisingTargetingPresets &&
          Array.isArray(this.advertisingTargetingPresets)
        )
      ) {
        throw new Error(
          `Value for 'advertising targeting presets' not in expected format.`
        );
      }

      const preset = this.advertisingTargetingPresets.find(
        (iPreset) => iPreset.id === this.transition.params().presetId
      );
      this.edit = {
        preset,
        name: preset.name,
        targetingOptions: [...preset.targeting_options]
      };
      this.isTargetingInvalid();
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  isTargetingInvalid() {
    switch (this.edit.preset.account.account_type_id) {
      case '8':
        const compulsoryOptions = this.edit.targetingOptions.filter(
          (target) => target.option.name === 'Locations'
        );
        return compulsoryOptions.length === 0 ? true : false;
        break;
      case '9':
        const compulsoryOption = this.edit.targetingOptions.filter(
          (target) => target.option.name === 'Country'
        );
        return compulsoryOption.length === 0 ? true : false;
        break;
      case '10':
        return this.edit.targetingOptions.length === 0 ? true : false;
        break;
      default:
        return true;
    }
  }

  async saveChanges() {
    await this.edit.preset.update({
      name: this.edit.name,
      targetingOptions: this.edit.targetingOptions
    });
    await this.state.go('^.presetList');
  }
}
