import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Activity,
  ActivityAuthor,
  CrmExternalIntegration,
  CrmPerson
} from '@ui-resources-angular';

@Component({
  selector: 'ssi-inbox-activity-external-crm-person',
  templateUrl: './inbox-activity-external-crm-person.component.html'
})
export class InboxActivityExternalCrmPersonComponent implements OnInit {
  @Input() externalIntegrationPerson: CrmExternalIntegration;
  @Input() activityProfile: ActivityAuthor;
  @Input() externalIntegration: any;
  @Input() activity: Activity;
  @Input() crmPerson: CrmPerson;

  @Output() unlinkIntegration = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
