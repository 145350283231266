import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TimePickerComponent } from './time-picker.component';
import { TimeToSelectValuePipe } from './timeToSelectValue/timeToSelectValue.pipe';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbTimepickerModule,
    OverlayModule,
    TooltipModule
  ],
  declarations: [TimePickerComponent, TimeToSelectValuePipe],
  exports: [TimePickerComponent, TimeToSelectValuePipe],
  providers: [TimeToSelectValuePipe]
})
export class TimePickerModule {}
