import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopicModalComponent } from './topic-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, TooltipModule],
  declarations: [TopicModalComponent],
  entryComponents: [TopicModalComponent]
})
export class TopicModalModule {}
