import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError, fromEvent } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  isMobile$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.setIsMobile(window);

    window.addEventListener('resize', (event) => {
      this.setIsMobile(event.target);
    });
  }

  setIsMobile(element: any): void {
    if (element.innerWidth <= 768) {
      if (!this.isMobile$.getValue()) {
        this.isMobile$.next(true);
      }
    } else {
      if (this.isMobile$.getValue()) {
        this.isMobile$.next(false);
      }
    }
  }

  isMobile(): boolean {
    return this.isMobile$.getValue();
  }
}
