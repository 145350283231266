import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';

// ----------------

const isDebug = false;

// ----------------

const predictionRoute = `/inbox/predict`;

@Injectable()
export class PredictedResponseService {
  public static CharactersRequiringSpaceSuffix = [
    "'",
    ':',
    '.',
    ',',
    '?',
    '!',
    '/',
    '_'
  ];
  public static CharactersUnwantingOfSpacePrefix = ["'", ':', '/', '_', '@'];
  protected static ConfidenceThreshold = 0;
  protected static MaximumPredictions = 10;

  protected latestPredictions: string[] = [];

  constructor(private api: API) {}

  public async getPredictionFor(
    untrimmedText: string,
    shouldFetchFreshPredictions: boolean
  ): Promise<string> {
    try {
      if (isDebug) {
        console.log(`liveChatPredictedResponseService.getPredictionsFor`);
      }

      const text = String(untrimmedText).trim();

      if (!text) {
        return '';
      }

      if (!shouldFetchFreshPredictions) {
        if (!this.latestPredictions.length) {
          return '';
        }

        return text + this.latestPredictions.join('');
      }

      if (isDebug) {
        console.log(`want new prediction for text: ${text}`);
      }

      const response = await this.api.post(predictionRoute, { text });

      if (isDebug) {
        console.log(`response:`);
        console.dir(response);
      }

      const hasPredictions: boolean =
        !!response &&
        !!response.data &&
        Array.isArray(response.data) &&
        !!response.data.length;

      if (!hasPredictions) {
        return '';
      }

      const predictions = [];
      const predictionsData = response.data.slice(
        0,
        PredictedResponseService.MaximumPredictions
      );

      predictionsData.forEach((prediction) => {
        if (!prediction) {
          return;
        }

        const hasPredictionConfidence = !!prediction.hasOwnProperty(
          'confidence'
        );
        const hasPredictionText = !!prediction.hasOwnProperty('text');

        if (!hasPredictionConfidence || !hasPredictionText) {
          if (isDebug) {
            console.log(`skipping - prediction data invalid!`);
          }

          return;
        }

        let newPrediction = prediction.text;
        const confidence = prediction.confidence;

        if (confidence < PredictedResponseService.ConfidenceThreshold) {
          return;
        }

        const shouldAddSpace =
          PredictedResponseService.CharactersRequiringSpaceSuffix.indexOf(
            newPrediction
          ) === -1 &&
          PredictedResponseService.CharactersUnwantingOfSpacePrefix.indexOf(
            text.substr(-1)
          ) === -1;

        newPrediction = `${shouldAddSpace ? ' ' : ''}${newPrediction}`;

        predictions.push(newPrediction);
      });

      this.latestPredictions = predictions;

      return this.getPredictionFor(text, false);
    } catch (error) {
      console.error(error);

      return '';
    }
  }
}
