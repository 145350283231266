import './fact-overview.component.scss';

import { Component, Input } from '@angular/core';
import { isNumeric } from '../../../../../common/utils';

@Component({
  selector: 'ssi-fact-overview',
  templateUrl: './fact-overview.component.html',
  styles: []
})
export class FactOverviewComponent {
  @Input() label: string;
  @Input() number: number = 0;
  @Input() showString: boolean = false;
  @Input() iconClass?: string;
  @Input() svgIcon?: string;
  @Input() iconSize?: string = '26px'; // px
  @Input() iconColor = '#14BAE3';
  @Input() circleColor? = '#FBF3E2';
  @Input() dividerColor? = '#d6dff7';
  @Input() width?: string = '172px'; // px
  @Input() height?: string = 'auto'; // px
  @Input() symbol?: string;
  @Input() symbolBefore?: boolean;
  @Input() previousValue: number;
  @Input() maxFractionDigitsHover = 2;

  constructor() {}

  isNumber(value: any): boolean {
    return isNumeric(value);
  }
}
