import './stream-post.component.scss';

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import {
  Activity,
  ActivityTags,
  OutboxModel,
  Account,
  AccountModel,
  ProfileModel
} from '@ui-resources-angular';
import { LinkifyPipe } from '../../pipes/linkify/linkify.pipe';
import {
  DropdownSelect2Component,
  Option
} from '../../components/dropdown-select-2/dropdown-select-2.component';
import { NotificationService } from '../../services/notification/notification.service';
import {
  AccountTypeId,
  AccountTypeIdString,
  SocialType,
  SocialTypeName
} from '../../enums';
import { ApiService, Colleague, Team } from '../../services/api';
import { SearchStream } from '../../../modules/auth/dashboard/common/components/widgets/widget-live-x-stream/widget-live-x-stream.component';
import { StateService } from '@uirouter/angular';
import { MenuComponent } from '../menu/menu.component';
import {
  PUBLISHER_ACTIVE,
  PublisherActive
} from '../publisher/publisher-active';
import { ProfileFeedItem } from '@ui-resources/modules/profile/services/profileModel';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';

@Component({
  selector: 'ssi-stream-post',
  templateUrl: './stream-post.component.html',
  styles: []
})
export class StreamPostComponent implements OnInit {
  @Input() stream: SearchStream;
  @Input() account: Account;
  @Input() accountId: string;
  @Input() accountTypeLabel?: string;
  @Input() accountTypeId?: AccountTypeIdString = AccountTypeIdString.Twitter;
  @Input() variant: 'liveXStream' | 'profileSearch' = 'liveXStream';
  @Input() selected = false;

  @Output() toggleSelect = new EventEmitter<void>();

  @ViewChild('actionsMenu') actionsMenu: MenuComponent;
  @ViewChild('repostMenu') repostMenu: MenuComponent;
  @ViewChild(DropdownSelect2Component)
  accountSelector: DropdownSelect2Component;

  formattedContent: string;
  contentCropped = false;
  showNativeLink = false;
  SocialType = SocialType;
  SocialTypeName = SocialTypeName;
  AccountTypeId = AccountTypeId;
  AccountTypeIdString = AccountTypeIdString;
  activeMenu: 'like' | 'pushToInbox' | 'repost';
  expandRepostMenu = false;
  expandQuoteRepostMenu = false;
  showRepostMenu = false;
  fullTextView = false;

  tags: Option[] = [];
  selectedTags: Option[] = [];

  accounts: Account[] = [];
  selectedAccount: Account;

  constructor(
    protected outboxModel: OutboxModel,
    protected accountModel: AccountModel,
    protected notificationService: NotificationService,
    protected linkifyPipe: LinkifyPipe,
    protected activityTags: ActivityTags,
    protected api: ApiService,
    protected state: StateService,
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive,
    protected profileHelper: ProfileHelperService
  ) {}

  ngOnInit() {
    // monitoring results are instantiated from ActivityModel as well

    this.accounts = JSON.parse(localStorage.getItem('workflowAccountIds'))
      .map((a) => this.accountModel.inject(a))
      .filter((a) => a.isTwitter());

    this.formattedContent = this.formatContent();
  }

  toggleSelected(): void {
    this.toggleSelect.emit();
  }

  formatContent(): string {
    const maxLength = 240;
    const content = this.stream.text || '';

    function canCrop(text: string, index: number): boolean {
      const char = text[index];
      return char === '\n' || char === ' ';
    }

    if (content.length <= maxLength) {
      return this.linkifyPipe.transform(content);
    }

    // crop content on white space only (avoid breaking links, mentions, hashtags, etc.)
    // find the first space before maxLength and crop content there
    for (let i = maxLength - 1; i >= 0; i--) {
      if (canCrop(content, i) && !canCrop(content, i - 1)) {
        const croppedContent = `${content.substring(0, i)}...`;
        this.contentCropped = true;
        return this.linkifyPipe.transform(croppedContent);
      }
    }

    return '';
  }

  readMore() {
    this.fullTextView = true;
    this.formattedContent = this.linkifyPipe.transform(this.stream.text || '');
  }

  hasMultipleMediaItems(): boolean {
    const mediaItems =
      Array.isArray(this.stream.media) &&
      this.stream.media.filter(
        (m) =>
          m.type === 'photo' ||
          m.type === 'gif' ||
          m.type === 'animated_gif' ||
          m.type === 'video'
      );

    return mediaItems.length > 1;
  }

  viewNatively(): void {
    window.open(this.stream.link);
  }

  openLike($event: MouseEvent) {
    if (this.stream.isLiked) {
      return;
    }
    this.openAccountSelector($event);
    this.activeMenu = 'like';
  }

  openPushToInbox($event: MouseEvent) {
    this.openAccountSelector($event);
    this.activeMenu = 'pushToInbox';
  }

  openAutoRepost($event: MouseEvent) {
    this.openAccountSelector($event);
    this.activeMenu = 'repost';
  }

  openAccountSelector($event: MouseEvent) {
    this.actionsMenu.close();
    this.selectedAccount = null;
    this.accountSelector.show($event);
  }

  selectRepostAccount(account: Account) {
    this.selectedAccount = account;
    this.autoRepost(this.stream);
  }

  selectQuoteRepostAccount(account: Account) {
    this.selectedAccount = account;
    this.publisherActive.next({
      isActive: true,
      create: {
        accounts: [account],
        quoteRetweet: this.stream.link
      }
    });
  }

  onAccountSelected(account: Account) {
    console.log('account:', account);
    switch (this.activeMenu) {
      case 'like':
        this.likeStream(this.stream);
        break;
      case 'pushToInbox':
        this.pushToInbox(this.selectedAccount);
        break;
      case 'repost':
        this.autoRepost(this.stream);
        break;

      default:
        break;
    }
  }

  async likeStream(stream: SearchStream) {
    const endpoint = `${this.api.url}/social/like`;

    if (stream.isLiked) {
      return;
    }
    const params = {
      account_id: this.selectedAccount
        ? this.selectedAccount.id
        : this.accountId,
      social_id: stream.social_id
    };
    await this.api
      .post(endpoint, params)
      .toPromise()
      .then(() => {
        stream.isLiked = true;
        stream.like_count++;
      });
  }

  async autoRepost(stream: SearchStream) {
    this.showRepostMenu = false;
    const endpoint = `${this.api.url}/social/share`;

    try {
      const params = {
        account_id: this.selectedAccount
          ? this.selectedAccount.id
          : this.accountId,
        social_id: stream.social_id
      };
      await this.api.post(endpoint, params).toPromise();
    } catch (error) {
      console.error('Error autoreposting:', error);
    }
  }

  async pushToInbox(account: Account) {
    const endpoint = `${this.api.url}/activity/inbox`;

    try {
      const activity_id = `${account.id}_${this.stream.social_id}`;
      await this.api.post(endpoint, { id: activity_id }).toPromise();
      this.state.go('auth.inbox', {
        activity: activity_id
      });
    } catch (error) {
      console.error('Error pushing stream to the inbox:', error);
    }
  }

  viewProfile() {
    this.profileHelper.viewProfile({
      accountId: this.accountId,
      profileId: this.stream.author.id
    });
  }
}
