<div class="validate-posts-modal-body modal-body">
    <i
      *ngIf="modalConfig.icon"
      class="validate-posts-modal-icon"
      [ngClass]="modalConfig.icon">
    </i>
    <p class="validate-posts-modal-title" *ngIf="modalConfig.title">
      {{modalConfig.title}}
    </p> 
    <p *ngIf="modalConfig.meta">{{modalConfig.meta}}</p>
    <button
      class="btn btn-danger validate-posts-modal-button"
      (click)="modalInstance.close('cancel')">
      {{modalConfig.cancelButton ? modalConfig.cancelButton : 'Cancel'}}
    </button>
    <button
      class="btn btn-primary validate-posts-modal-button"
      (click)="modalInstance.close('ok')">
      {{modalConfig.okButton ? modalConfig.okButton : 'Ok'}}
    </button>
</div>