import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-outbox-post-list-no-results',
  templateUrl: './outbox-post-list-no-results.component.html'
})
export class OutboxPostListNoResultsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
