import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePostsModalComponent } from '././validate-posts-modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [ValidatePostsModalComponent],
  exports: [ValidatePostsModalComponent],
  entryComponents: [ValidatePostsModalComponent]
})
export class ValidatePostsModalModule {}
