<div
  (clickOutside)="onClose()"
  [delayClickOutsideInit]="true"
  class="container"
>
  <ul
    *ngIf="!! areActiveTagsVisible"
    class="active-tags"
  >
    <li
      *ngFor="let tag of activeTags; let index = index"
      (click)="toggle($event, tag)"
      data-pendo="liveChat_removeTag"
    >
      <span
        class="text"
      >
        {{ tag }}
      </span>
      
      <span
        class="remove"
      >
        <i class="fa fa-remove"></i>
      </span>
    </li>
  </ul>

  <input
    #tagsFilter
    autofocus="true"
    class="tags-filter"
    placeholder="{{ 'TYPE_TAG' | translate }}"
    type="text"
  >
  
  <ul
    *ngIf="!! areAvailableTagsVisible"
    class="available-tags"
  >
    <li
      *ngFor="let tag of filteredTags; let index = index"
      (mouseover)="select(index)"
      (click)="toggle($event, tag)"
      [ngClass]="{
        active: isActiveTag(tag),
        selected: isSelectedTagIndex(index)
      }"
      data-pendo="liveChat_addTag"
    >
      <span>
          {{ tag }}
      </span>
      
      <i
        *ngIf="isActiveTag(tag)"
        class="ssi ssi-tick"
      ></i>
    </li>
  </ul>

</div>
