<div class="opn-container">
  <div
    class="opn-wrap"
    [ngClass]="{
      'opn-wrap-deleted': post.deleted_at,
      'opn-wrap-failed': post.last_attempt_at && !post.social_id
    }"
  >
    <div class="opn-header opn-row">
      <div class="opn-title">
        <div
          class="opn-title-icon"
          [style.background-color]="account?.socialNetwork.brand.color"
        >
          <i [ngClass]="account?.socialNetwork.icon.web"></i>
        </div>
        <h2>{{account?.displayName}}</h2>
        <ul class="opn-title-pills">
          <li><p>@{{account?.username}}</p></li>
          <li class="opn-title-pill-warning" *ngIf="post.deleted_at">
            <i class="delete ssi ssi-small-delete"></i>
            <p>
              Deleted on {{post.deleted_at | date:'d MMM y'}}
              <ng-container *ngIf="post.deletedBy">
                by {{post.deletedBy.fullName}}
              </ng-container>
            </p>
          </li>
        </ul>
      </div>

      <div class="opn-meta">
        <ul *ngIf="post.sent_at && post.social_id" class="opn-meta-list">
          <li><p>{{post.sent_at | date:'d MMM y'}}</p></li>
          <li><p>{{post.sent_at | date:'HH:mm'}}</p></li>
        </ul>
        <div *ngIf="post.last_attempt_at && !post.social_id" class="opn-meta-date opn-meta-date-failed">
          <i class="ssi ssi-warning"></i>
          <ul>
            <li><p>{{post.last_attempt_at | date:'d MMM y'}}</p></li>
            <li><p>{{post.last_attempt_at | date:'HH:mm'}}</p></li>
          </ul>
        </div>
        <div *ngIf="!post.sent_at && post.send_at" class="opn-meta-date">
          <i class="ssi ssi-scheduling"></i>
          <ul>
            <li><p>{{post.send_at | date:'d MMM y'}}</p></li>
            <li><p>{{post.send_at | date:'HH:mm'}}</p></li>
          </ul>
        </div>
        <div class="opn-meta-author">
          <i class="ssi ssi-author"></i>
          <p *ngIf="post.author?.id !== '14'">{{post.author?.fullName}}</p>
          <p *ngIf="post.author?.id === '14'">Natively posted</p>
        </div>
      </div>
    </div>

    <div class="opn-body opn-row">
      <p [ssiEmojiText]="post.text | escapeHtml | nl2br | htmlToText | linkify: { mentions: mentions }"></p>

      <div
        *ngIf="post.outbox_files && post.outbox_files.length > 0 && post.outbox_files[0].mimetype !== 'video/mp4'"
        class="opn-gallery"
        [ngClass]="{'opn-gallery-multiple': post.outbox_files.length > 1}"
        [ssiEnlargeImage]="media"
        [altText]="mediaAltText"
        modalClassName="orlo-modal"
        galleryWrapperClassName="opn-modal-gallery"
      >
        <img
          [src]="media[0]"
          ssiFallbackImage="brokenImageNew"
        >
        <span class="opn-gallery-badge" *ngIf="post.outbox_files.length > 1">
          {{post.outbox_files.length - 1}}+
        </span>
      </div>
      <div
        *ngIf="post.outbox_files.length > 0 && post.outbox_files[0].mimetype === 'video/mp4'"
        class="opn-gallery"
      >
        <a [href]="post.outbox_files[0].public_url" target="_blank">
          <div class="post-video">
            <i class="ssi ssi-social-video-source"></i>
          </div>
        </a>
      </div>
    </div>

    <div class="opn-footer opn-row">
      <div class="opn-actions">
        <ul class="opn-actions-left" *ngIf="!account?.socialNetwork.inboundInitiated">
          <li
            *ngIf="post.social_id && !post.deleted_at && authUser?.hasAccountPermission(post.account_id, 'post_edit')"  
            class="opn-actions-item"
          >
            <button
              (click)="closeModal.emit()"
              [ssiTriggerPublish]="{
                edit: {post: post},
                disable: {
                  previewPost: true,
                  changeAccount: true,
                  changeCampaign: true,
                  changeTargeting: true,
                  requestingValidation: true,
                  scheduling: true,
                  editMedia: true
                }
              }"
            >
              <i class="ssi ssi-drafts"></i>
              <p>Edit Live Post</p>
            </button>
          </li>
          <li
            *ngIf="!post.social_id && !post.deleted_at"  
            class="opn-actions-item"
          >
            <button
              (click)="closeModal.emit()"
              [ssiTriggerPublish]="{edit: {post: post}}"
            >
              <i class="ssi ssi-drafts"></i>
              <p>Edit Post</p>
            </button>
          </li>
          <li
            class="opn-actions-item"
          >
            <div
              class="tags-container"
              (click)="$event.stopPropagation()"
            >
              <ng-container *ngTemplateOutlet="tagsMenuTemplate; context: { postLive: true }"></ng-container>
            </div>
          </li>
          <li class="opn-actions-item">
            <button
              (click)="closeModal.emit()"
              [ssiTriggerPublish]="{create: {copy: post}}"
            >
              <i class="ssi ssi-copy-paste-correct"></i>
              <p>Copy Post</p>
            </button>
          </li>
          <li
            *ngIf="post.account.promoted_by.length > 0 && !post.deleted_at &&
            [
              AccountTypeId.Facebook,
              AccountTypeId.LinkedIn,
              AccountTypeId.LinkedInAds,
              AccountTypeId.FacebookAds
            ].includes(+account?.account_type_id)"
            class="opn-actions-item"
          >
            <button (click)="navigateToBoost({outboxId: post.id})">
              <i class="ssi ssi-boosting-new-cc"></i>
              <p>Boost Post</p>
            </button>
          </li>
          <li
            *ngIf="post.social_id && !!account?.socialNetwork.outbox.threadReplies"
            class="opn-actions-item"
          >
            <a
              (click)="onAddToThread()"
            >
              <i class="ssi ssi-addsmall"></i>
              <p>Add to Thread</p>
            </a>
          </li>
          <li *ngIf="!post.deleted_at" class="opn-actions-item">
            <button (click)="openCampaignModal()">
              <i class="ssi ssi-campaign"></i>
              <!-- <p>{{post.campaign ? 'Edit' : 'Add'}} Campaign</p> -->
              <p>Campaign</p>
            </button>
          </li>
          <li *ngIf="post.canEditAutoComment()" class="opn-actions-item">
            <button (click)="editAutoComment(post)">
              <i class="ssi ssi-first-comment-temporary"></i>
              <p>Edit first comment</p>
            </button>
          </li>
          <li *ngIf="post.social_id && post.externalLink" class="opn-actions-item">
            <a [href]="post.externalLink" target="_blank">
              <i class="ssi ssi-view-natively"></i>
              <p>View Natively</p>
            </a>
          </li>
          <!-- <li *ngIf="!post.social_id" class="opn-actions-item">
            <button (click)="openPostPreviewModal()">
              <i class="ssi ssi-preview-small"></i>
              <p>Preview Post</p>
            </button>
          </li> -->
        </ul>
        <ul class="opn-actions-right">
          <a
            *ngIf="showDelete"
            (click)="showDeletePostTooltip()"
            #deletePostTooltip="ssiTooltip"
            [ssiTooltip]
            tooltipPlacement="right"
            tooltipTrigger="manual"
            [tooltipTemplate]="deletePostTooltipTemplate"
          >
            <i class="delete ssi ssi-small-delete"></i>
          </a>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #deletePostTooltipTemplate>
  <div
    class="delete-post-tooltip"
    (ssiOffClick)="deletePostTooltip.hide()"
  >
    <h5>Would you like to delete this post?</h5>
    <div class="buttons-container">
      <orlo-button
        state="text"
        text="Cancel"
        textColor="#43537F"
        (btnClick)="deletePostTooltip.hide()"
      ></orlo-button>
      <orlo-button
        state="text"
        text="Delete Post"
        textColor="#E34916"
        (btnClick)="
          post.remove();
          onDeletedPost.emit();
          showDelete = false;
          deletePostTooltip.hide()
        "
      ></orlo-button>
    </div>
  </div>
</ng-template>

<ng-template
  #tagsMenuTemplate
  let-postLive="postLive"
>
  <ssi-dropdown-select-2
    #tagsSelector
    [options]="tags"
    [filterable]="true"
    [multiple]="true"
    [toggleAllEnabled]="false"
    [filterPlaceholder]="'Type post tags...'"
    [(ngModel)]="selectedTags"
    (ngModelChange)="postTagsChange($event)"
  >
    <ng-template
      ssiTemplateSelector="headButton"
      let-expanded="expanded"
      let-toggle="toggle"
    >
      <a
        href="javascript:;"
        class="outbox-post-stat"
        (click)="toggle();"
      >
      <small *ngIf="selectedTags && selectedTags.length">{{ selectedTags.length }}</small>
        <i class="tag ssi ssi-tag-test"></i> <p class="outbox-post-stat-count">{{selectedTags.length > 0 ? 'Edit Post Tags' : 'Post Tags'}}</p>
      </a>
    </ng-template>

    <ng-template
      ssiTemplateSelector="emptyState"
      let-toggle="toggle"
    >
      <div
        class="no-tags-state"
        *ngIf="!tagsSelector.filterTerm"
      >
        <i class="ssi ssi-tags"></i>
        <p class="title">No Post Tags yet!</p>
        <p class="desc">Just start typing then create your new tags</p>
      </div>
      <div
        class="no-tags-state"
        *ngIf="tagsSelector.filterTerm"
      >
        <button
          type="button"
          class="g-btn"
          *ngIf="tagsSelector.filterTerm"
          (click)="createTag(tagsSelector.filterTerm)"
        ><span>Create "{{tagsSelector.filterTerm}}"</span><i class="ssi ssi-plus"></i></button>
      </div>
    </ng-template>
  </ssi-dropdown-select-2>
</ng-template>