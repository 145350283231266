import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeofPipe } from './type-of.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TypeofPipe],
  exports: [TypeofPipe]
})
export class TypeofModule {}
