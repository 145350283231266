import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output
} from '@angular/core';

import { Agent } from '../../../../../../library/models/live-chat/agent';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import strings from '../../../../../../library/constants/strings';

@Component({
  selector: 'ssi-live-chat-team',
  templateUrl: './live-chat-team.component.html',
  styles: []
})
export class LiveChatTeamComponent implements OnChanges {
  @Input() public agents: Agent[] = [];
  @Input() public isIgnoringClicks = false;

  @Output() public memberClick: EventEmitter<Agent> = new EventEmitter<Agent>();

  private _team: Agent[] = [];

  constructor() {}

  public get team(): Agent[] {
    // console.log(this._team)
    return this._team;
  }

  getNetworkStateValueForTeamMember(agent: Agent) {
    if (!agent || !agent.hasNetworkState) {
      return;
    }

    return agent.networkStateValue;
  }

  isTeamMemberOffline(agent: Agent) {
    if (!agent || !agent.hasNetworkState) {
      return;
    }

    return agent.networkStateValue === strings.offline;
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty(strings.agents)) {
      if (!changes.agents.currentValue) {
        return (this._team = []);
      }

      const agents = changes.agents.currentValue;
      const team = [];
      team.push(agents.shift());
      team.push(...agents);

      this._team = team;
    }
  }

  onMemberClick(agent: Agent) {
    this.memberClick.emit(agent);
  }
}
