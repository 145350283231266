import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStore } from 'js-data';
import {
  activityModelFactory,
  ActivityModel
} from '../../modules/activity/services/activityModel';
import { ActivityTags } from '../../modules/activity/services/activityTags';
import { ActivityThreadFactory } from '../../modules/activity/services/activityThreadFactory';
import {
  ConversationModel,
  conversationModelFactory
} from '../../modules/activity/services/conversationModel';

@NgModule()
export class ActivityModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ActivityModule,
      providers: [
        ActivityTags,
        ActivityThreadFactory,
        {
          provide: ActivityModel,
          useFactory: activityModelFactory,
          deps: [DataStore]
        },
        {
          provide: ConversationModel,
          useFactory: conversationModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
