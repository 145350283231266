import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxActivityThreadComponent } from './inbox-activity-thread.component';

import { ActivityModule } from '../../activity/activity.module';
import { TranslateModule } from '@ngx-translate/core';
import { UniqueModuleModule } from '../../../pipes/unique/unique.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ActivityModule, UniqueModuleModule],
  declarations: [InboxActivityThreadComponent],
  entryComponents: [InboxActivityThreadComponent],
  exports: [InboxActivityThreadComponent]
})
export class InboxActivityThreadModule {}
