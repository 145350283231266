import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderModule } from '../../directives/file-uploader/file-uploader.module';
import { TranslateModule } from '@ngx-translate/core';
import { OrloComponentsModule } from 'orlo-components';
import { LabelTagComponent } from './label-tag.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FileUploaderModule,
    OrloComponentsModule
  ],
  declarations: [LabelTagComponent],
  exports: [LabelTagComponent]
})
export class LabelTagModule {}
