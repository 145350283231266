import { Pipe, PipeTransform } from '@angular/core';

export type BranchClassName = 'single' | 'parent' | 'child' | 'parent-child';

@Pipe({
  name: 'branchClass'
})
export class BranchClassPipe implements PipeTransform {
  transform(
    ArrayLength: number,
    ArrayIndex: number,
    isEnd: boolean
  ): BranchClassName {
    if (ArrayLength === 1) {
      if (isEnd) {
        return 'parent';
      }
      return 'single';
    }
    if (ArrayIndex === 0) {
      return 'parent';
    }
    if (ArrayIndex === ArrayLength - 1) {
      if (isEnd) {
        return 'parent-child';
      }
      return 'child';
    }
    return 'parent-child';
  }
}
