import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';

import { InsightsPostComponent } from './insights-post.component';

import { MenuModule } from '../../../../../../../common/components/menu/menu.module';
import { Checkbox2Module } from '../../../../../../../common/components/checkbox-2/checkbox-2.module';
import { SocialNetworkIconModule } from '../../../../../../../common/components/social-network-icon/social-network-icon.module';
import { FallbackImageModule } from '../../../../../../../common/directives/fallback-image/fallback-image.module';
import { LinkifyModule } from '../../../../../../../common/pipes/linkify/linkify.module';
import { EnlargeImageModule } from '../../../../../../../common/directives/enlarge-image/enlarge-image.module';
import { ReverseModule } from '../../../../../../../common/pipes/reverse/reverse.module';
import { BigNumberModule } from '../../../../../../../common/components/big-number/big-number.module';
import { BigNumberModule as BigNumberPipeModule } from '../../../../../../../common/pipes/big-number/big-number.module';
import { TooltipModule } from '../../../../../../../common/directives/tooltip/tooltip.module';
import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { MessageModule } from '../../../../../../../common/components/message-box/message/message.module';
import { SentimentAmendBoxModule } from '../../../../../../../common/components/sentiment-amend-box/sentiment-amend-box.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MenuModule,
    Checkbox2Module,
    SocialNetworkIconModule,
    FallbackImageModule,
    LinkifyModule,
    EnlargeImageModule,
    ReverseModule,
    BigNumberModule,
    BigNumberPipeModule,
    TooltipModule,
    DropdownSelect2Module,
    MessageModule,
    OverlayModule,
    SentimentAmendBoxModule
  ],
  exports: [InsightsPostComponent],
  declarations: [InsightsPostComponent],
  providers: [NgbActiveModal],
  entryComponents: [InsightsPostComponent]
})
export class InsightsPostModule {}
