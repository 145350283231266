import './stat-change.component.scss';

import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'ssi-stat-change',
  templateUrl: './stat-change.component.html',
  styles: []
})
export class StatChangeComponent implements OnInit, OnChanges {
  @Input() change: number;
  @Input() current: number;
  @Input() previous: number;
  @Input() lowerIsPositive = false;
  @Input() boxed = false;
  @Input() position: 'middle' | 'bottom' = 'middle';
  @Input() suffix = '';
  @Input() bigNumber = true;

  positive = false;
  negative = false;

  constructor() {}

  ngOnInit() {
    this.calculateChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.current || changes.previous)) {
      this.calculateChange();
    }
  }

  calculateChange() {
    this.change = parseFloat((this.current - this.previous).toFixed(2)) || 0;

    if (this.change !== 0) {
      this.positive =
        (this.change > 0 && !this.lowerIsPositive) ||
        (this.change < 0 && this.lowerIsPositive);

      this.negative = !this.positive;
    }
  }
}
