<div class="post-sheduler-container">
	<div class="post-scheduler-header">
		<h3>Select schedule date</h3>
	</div>

	<div class="post-scheduler-body">
		<div class="publisher-schedule-date-picker-outer">
			<div class="publisher-schedule-date-picker-inner">
			  <div class="d-flex publisher-schedule-date-picker-title-wrap">
					<div class="publisher-schedule-date-picker-title-nav publisher-schedule-date-picker-title-nav-prev">
				  	<a
							href="javascript:;"
							mwlCalendarPreviousView
							view="month"
							[(viewDate)]="calendarDate"
							(click)="monthChanged()"
						>
							<i class="ssi ssi-chevron-small"></i>
				  	</a>
					</div>
					<div class="flex-1 publisher-schedule-date-picker-title">
						<p>{{ calendarDate | calendarDate:'monthViewTitle':'en' }}</p>
					</div>
					<div class="publisher-schedule-date-picker-title-nav publisher-schedule-date-picker-title-nav-next">
						<button
							mwlCalendarNextView
							view="month"
							[(viewDate)]="calendarDate"
							(click)="monthChanged()"
						>
							<i class="ssi ssi-chevron-small point-right"></i>
						</button>
					</div>
				</div>

			  <mwl-calendar-month-view
					[viewDate]="calendarDate"
					[weekStartsOn]="0"
					[refresh]="refreshCalendar"
					[cellTemplate]="monthCellTemplate"
					(dayClicked)="dayClicked($event.day)"
					(beforeViewRender)="addCssClasses($event.body)"
				></mwl-calendar-month-view>
				
			</div>
		</div>
	</div>
	
	<div class="post-scheduler-footer">
		<orlo-button
			class="post-scheduler-button post-scheduler-button-secondary"
			text="Cancel"
			textColor="#43537F"
			bgColor="#fff"
			(click)="onCloseClick.emit()"
		></orlo-button>
		<orlo-button
			class="post-scheduler-button"
			text="Add Schedule"
			textColor="#fff"
			bgColor="#14BAE3"
			[disabled]="! selectedDate"
			(click)="onAddScheduleClick.emit(selectedDate)"
		></orlo-button>
	</div>
</div>

<ng-template
  #monthCellTemplate
  let-day="day"
  let-locale="locale">

  <ng-template #calendarDayTooltipScheduled>
    <div class="tooltip-schedule">
      <p *ngIf="day.meta?.scheduledCount" class="tooltip-schedule-body text-center">
				{{ day.meta?.scheduledCount }} post{{ day.meta?.scheduledCount !== '1' && 's' }} already scheduled, double click to view
			</p>
			<p *ngIf="!day.meta?.scheduledCount" class="tooltip-schedule-body text-center">Selected date</p>
    </div>
    <div data-popper-arrow class="tooltip-arrow"></div>
  </ng-template>

  <ng-template #calendarDayTooltipDisabled>
    <div class="tooltip-schedule">
      <p class="tooltip-schedule-title">Unavailable day to schedule</p>
      <p class="tooltip-schedule-body">This may be due to the same post being less than 48 hours apart or a date is in the past</p>
    </div>
    <div data-popper-arrow class="tooltip-arrow"></div>
  </ng-template>

  <button
    class="cal-cell-top"
    ssiTooltip
    [tooltipTemplate]="day.meta && day.meta.isDisabled ? calendarDayTooltipDisabled : calendarDayTooltipScheduled"
    [tooltipWindowClass]="day.meta?.isScheduled ? 'tooltip-chunky' : 'tooltip-chunky-big'"
    [tooltipDisabled]="!day.meta?.isScheduled && !day.meta?.isDisabled"
		cdkOverlayOrigin
		#dateTriggerOrigin="cdkOverlayOrigin"
    (dblclick)="openScheduledPostsOverlay(dateTriggerOrigin, day)"
	>
    <span class="cal-day-number">
      {{ day.date | calendarDate:'monthViewDayNumber':locale }}
    </span>
  </button>
</ng-template>

<ng-template
	#actionsMenu="cdkConnectedOverlay"
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="dateTriggerOrigin"
	[cdkConnectedOverlayOpen]="showDatePosts"
	[cdkConnectedOverlayFlexibleDimensions]="false"
	[cdkConnectedOverlayHasBackdrop]="true"
	[cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
	[cdkConnectedOverlayPush]="true"
	(backdropClick)="showDatePosts = false"
>
	<div class="scheduled-posts-overlay">
		<div class="scheduled-posts-overlay-container">
			<div class="scheduled-posts-overlay-header">
				<i class="ssi ssi-small-schedule"></i>
				<p>{{ activeDay.meta?.scheduledCount }} post{{ activeDay.meta?.scheduledCount !== '1' && 's' }} scheduled for your selected accounts:</p>
			</div>
			<div class="scheduled-posts-overlay-body">
				<div class="meta">
					<ssi-social-network-icon
						[accountTypeId]="outboxQuery.outboxPosts[activePost].account.account_type_id"
            variant="svg"
						[size]="20"
					></ssi-social-network-icon>
					<h5>{{outboxQuery.outboxPosts[activePost]?.account.name}}</h5>
					<span>{{outboxQuery.outboxPosts[activePost].send_at | date: 'hh:mm a'}}</span>
				</div>
				<p>{{outboxQuery.outboxPosts[activePost].text}}</p>
			</div>
			<div class="scheduled-posts-overlay-footer">
				<p class="fake-link" (click)="showPostModal()">view post</p>
				<div
					*ngIf="outboxQuery.outboxPosts.length > 1"
					class="scheduled-posts-overlay-footer-action"
					(click)="showNextScheduledPost()"
				>
					<p>Next post</p>
					<i class="ssi ssi-line-arrow-small-down rotate-90"></i>
				</div>
			</div>
		</div>
	</div>
</ng-template>