import { Injector } from '@angular/core';

let appInjectorRef: Injector;
export let appInjector = (injector?: Injector): Injector => {
  if (injector) {
    appInjectorRef = injector;
  }

  return appInjectorRef;
};

/**
 * Should be used in specs only.
 * Seems to be the only way to stub ES6 imports.
 */
export const stubAppInjector = (stub: any): void => {
  appInjector = stub;
};
