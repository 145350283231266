import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedactModalComponent } from './redact-modal.component';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';
import { DropdownSelectModule } from '../dropdown-select/dropdown-select.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlatpickrModule,
    OrloComponentsModule,
    DropdownSelectModule
  ],
  declarations: [RedactModalComponent],
  exports: [RedactModalComponent],
  providers: []
})
export class RedactModalModule {}
