import './accordion.component.scss';

import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  HostListener,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Debounce } from '../../decorators';

@Component({
  selector: 'ssi-accordion',
  templateUrl: './accordion.component.html',
  styles: []
})
export class AccordionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() label: string | TemplateRef<any>;
  @Input() isOpen: boolean;
  @Input() isValid: boolean;
  @Input() stayOpen = false;
  @Input() disabled = false;

  accordionId = uuidv4();
  labelHeight = 46;
  bodyHeight: number;

  @Output() onAccordionOpenClose = new EventEmitter<boolean>();

  @ViewChild('accordionBody') accordionBody: ElementRef;

  constructor() {}

  @HostListener('window:resize', ['$event'])
  @Debounce(200, false)
  onResize(event) {
    this.accordionHeightRefresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.accordionHeightRefresh();
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.accordionHeightRefresh();
  }

  public accordionHeightRefresh() {
    this.bodyHeight = this.accordionBody.nativeElement.clientHeight;
  }

  toggleOpenClose(isOpen) {
    this.isOpen = isOpen;
    this.onAccordionOpenClose.emit(isOpen);
  }
}
