import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePostsNotesComponent } from './validate-posts-notes.component';
import { NotesModalModule } from './notes-modal/notes-modal.module';

@NgModule({
  imports: [CommonModule, NotesModalModule],
  declarations: [ValidatePostsNotesComponent],
  exports: [ValidatePostsNotesComponent]
})
export class ValidatePostsNotesModule {}
