import './build.component.scss';

import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AccountModel, Account } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import { ReportBuildFormValue } from '../../common/report-build-from/report-build-from.component';

import { AccountTypeId } from '../../../../../common/enums';
import { commonDateRanges } from '../../../../../common/constants/common-date-ranges';
import { compareToOptions } from '../../../../../common/constants/compare-to-options';

@Component({
  selector: 'ssi-build',
  templateUrl: './build.component.html',
  styles: []
})
export class BuildComponent implements OnInit {
  formValue?: ReportBuildFormValue;
  AccountTypeId = AccountTypeId;

  constructor(
    protected state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  ngOnInit() {
    if (
      this.state.params.accountIds &&
      this.state.params.startDate &&
      this.state.params.endDate
    ) {
      // edit mode
      const accountIds = Array.isArray(this.state.params.accountIds)
        ? this.state.params.accountIds
        : [this.state.params.accountIds];

      this.formValue = {
        accounts: accountIds.map((id) => this.accountModel.get(id)),
        startDate: new Date(this.state.params.startDate),
        endDate: new Date(this.state.params.endDate),
        previousStartDate:
          this.state.params.previousStartDate &&
          new Date(this.state.params.previousStartDate),
        previousEndDate:
          this.state.params.previousEndDate &&
          new Date(this.state.params.previousEndDate),
        dateRange: commonDateRanges[this.state.params.dateRange],
        compareToOption: compareToOptions[this.state.params.compareTo],
        visibilityOption: undefined,
        tagsToInclude: undefined,
        tagsToExclude: undefined
      };
    }
  }

  onFormSubmit(output: ReportBuildFormValue) {
    console.log('form output: ', output);

    this.state.go('auth.analytics.survey.report', {
      accountIds: output.accounts.map((a) => a.id),
      startDate: output.startDate.toISOString(),
      endDate: output.endDate.toISOString(),
      dateRange: output.dateRange && output.dateRange.id
    });
  }
}
