import { Injectable } from '@angular/core';
import { OutboxModel, Account, Outbox } from '@ui-resources-angular';
import moment from 'moment';

const getAccountName = (account) =>
  account.displayName +
  (account.name !== account.username && account.username
    ? ` (@${account.username})`
    : '');

@Injectable()
export class ReportSpreadsheetTransformersService {
  constructor(private outboxModel: OutboxModel) {}

  outboxPosts(posts = []) {
    const rows = posts.map((post) => {
      const outbox = this.outboxModel.inject(post.outbox);

      const linkClicks = post.link
        ? post.link.clicks_es || post.link.clicks || 0
        : post.clicks_es || 0;
      const engagement_rate =
        outbox.impressions &&
        (
          ((Number(outbox.like_count) +
            Number(outbox.comment_count) +
            Number(outbox.share_count) +
            Number(linkClicks)) /
            Number(outbox.impressions)) *
          100
        ).toFixed(2) + '%';
      return [
        getAccountName(outbox.account),
        moment(new Date(post.outbox.send_at)).format('MM/DD/YY'),
        outbox.author ? outbox.author.fullName : 'Unknown',
        outbox.reach,
        outbox.share_count,
        outbox.like_count,
        outbox.comment_count,
        outbox.impressions,
        engagement_rate,
        outbox.text,
        outbox.outbox_files.length > 0 ? outbox.outbox_files[0].public_url : ''
      ];
    });
    return [
      [
        'Account',
        'Date',
        'User',
        'Reach',
        'Shares',
        'Likes',
        'Comments',
        'Impressions',
        'Engagement rate',
        'Text',
        'Image'
      ],
      ...rows
    ];
  }

  outboxPosts2(posts: Outbox[] = []) {
    const rows = posts.map((outbox) => {
      return [
        getAccountName(outbox.account),
        moment(new Date(outbox.send_at)).format('MM/DD/YY'),
        outbox.author ? outbox.author.fullName : 'Unknown',
        outbox.clicks,
        Number(outbox.like_count),
        Number(outbox.share_count),
        Number(outbox.comment_count),
        Number(outbox.reach),
        Number(outbox.impressions),
        outbox.engagement_rate,
        outbox.text,
        outbox.outbox_files.length > 0 ? outbox.outbox_files[0].public_url : ''
      ];
    });

    return [
      [
        'Account',
        'Date',
        'User',
        'Clicks',
        'Likes',
        'Shares',
        'Comments',
        'Reach',
        'Impressions',
        'Engagement Rate',
        'Text',
        'Image'
      ],
      ...rows
    ];
  }

  getBucketSize(dates: Array<string | Date>): 'hour' | 'day' | 'month' {
    const first10Dates = dates
      .slice(0, 10)
      .map((d) => moment.utc(d).format('YYYY-MM-DD HH:mm:ss'));

    if (first10Dates.every((d) => d.split('-').pop() === '01 00:00:00')) {
      return 'month';
    }

    if (first10Dates.every((d) => d.split(' ').pop() === '00:00:00')) {
      return 'day';
    }

    return 'hour';
  }

  timeSeries(allSeries) {
    const allDates: string[] = [];
    const header: string[] = ['Date'];

    allSeries.forEach((series) => {
      header.push(series.header);
      const data = (series.data && series.data.values) || series.data;
      Object.keys(data).forEach((date) => {
        if (allDates.indexOf(date) === -1) {
          allDates.push(date);
        }
      });
    });

    allDates.sort((a, b) => {
      const timeA = new Date(a).getTime();
      const timeB = new Date(b).getTime();
      return timeA - timeB;
    });

    const bucketSize = this.getBucketSize(allDates);

    const rows = allDates.map((date) => {
      const formattedDate =
        bucketSize === 'month'
          ? moment.utc(date).format('MM/YY')
          : bucketSize === 'day'
          ? moment.utc(date).format('MM/DD/YY')
          : moment.utc(date).format('MM/DD/YY HH:mm:ss');

      return [
        formattedDate,
        ...allSeries.map((series) => {
          const data = (series.data && series.data.values) || series.data;
          const value = (data[date] && data[date].count) || data[date];
          return +value || 0;
        })
      ];
    });

    return [header, ...rows];
  }

  accountsList(accounts: Account[]) {
    const accountsSection = accounts.map((account) => [
      '',
      getAccountName(account),
      account.accountTypeLabel
    ]);
    return [['Accounts included in report'], ...accountsSection];
  }

  pieChart(pieData) {
    return pieData.map((item) => [item.name, item.y]);
  }
}
