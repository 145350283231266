<label class="field-label"
	   *ngIf="!toolTip && label">{{label}}</label>
<label *ngIf="toolTip"
	   class="field-label"
	   [ssiTooltip]="toolTip"
	   [tooltipWindowClass]="'tooltip-chunky-big'">
	{{label}}
	<i class="ssi ssi-information"></i>
</label>
<div class="toggle-switch-container" [class.active]="isChecked">
	<span>{{toggleText}}</span>

	<label class="switch"
		   [for]="id">
		<input #toggleInput
			   type="checkbox"
			   name="name"
			   class="checkbox"
			   [id]="id"
			   (change)="switchToggle()"
			   [ngClass]="{'disabled' : disabled, 'is-checked' : isChecked}"
			   [checked]="isChecked"
			   [disabled]="disabled"
			   [value]="isChecked">
		<span class="slider round"
			  [class.disabled]="disabled"></span>
	</label>
</div>