<div class="draft-container">
  <div class="draft" [class.active-delete]="awaitingDeleteConfirmation">
    <div class="draft-header draft-row">
      <div class="draft-title">
        <div class="draft-title-icons-container">
          <ng-container *ngFor="let account of draft.accounts">
            <ssi-social-network-icon *ngIf="account" 
                                    [accountTypeId]="account?.account_type_id"
                                    variant="svg"
                                    [size]="26"
                                    class="draft-title-icon"></ssi-social-network-icon>
          </ng-container>
        </div>
        <h2>{{draft.title}}</h2>
        <ul class="draft-title-pills">
          <li *ngFor="let account of draft.accounts">
            <p>@{{account?.username}}</p>
          </li>
        </ul>
      </div>

      <div class="draft-meta">
        <ul class="draft-meta-list">
          <li><p>{{draft.created_at | date:'d MMM y'}}</p></li>
          <li><p>{{draft.created_at | date:'HH:mm'}}</p></li>
        </ul>
        <div class="draft-meta-author">
          <i class="ssi ssi-author"></i>
          <p>{{draft.author.fullName}}</p>
        </div>
      </div>
    </div>

    <div class="draft-body draft-row">
      <p [ssiEmojiText]="draft.content | escapeHtml | nl2br | htmlToText | linkify: { mentions: [] }"></p>

      <div
        *ngIf="draft.media?.length"
        class="draft-gallery"
      >
      <ng-container *ngIf="draft.media[0].type === 'VIDEO'">
        <video controls>
          <source [src]="draft.media[0].url">
            Sorry but we could not load the video preview.
          </video>
        </ng-container>
        <ng-container *ngIf="draft.media[0].type === 'IMAGE'">
          <img [src]="draft.media[0].url">
          <span *ngIf="draft.media.length > 1" class="draft-gallery-badge">
            {{draft.media.length - 1}}+
          </span>
        </ng-container>
      </div>
    </div>

    <div class="draft-footer draft-row">
      <div class="draft-actions">
        <ul class="draft-actions-left">
          <li (click)="showNotes()" class="draft-actions-item">
            <i class="ssi ssi-orlo-note"></i>
            <p>Notes</p>
          </li>
          <li
            (click)="triggerEditDraft()"
            class="draft-actions-item"
            [class.disabled]="missingAccountAccess"
            [ssiTooltip]="missingAccountAccess ? 'This draft contains accounts that you do not have access to. Contact your company administrator for further assistance.' : ''"
            tooltipPlacement="top"
            tooltipWindowClass="tooltip-chunky-big"
            [tooltipAppendToBody]="true"
          >
            <i class="ssi ssi-drafts"></i>
            <p>Edit Title</p>
          </li>
          <li
            (click)="triggerPublishDraft(draft.id)"
            class="draft-actions-item"
            [class.disabled]="missingAccountAccess"
            [ssiTooltip]="missingAccountAccess ? 'This draft contains accounts that you do not have access to. Contact your company administrator for further assistance.' : ''"
            tooltipPlacement="top"
            tooltipWindowClass="tooltip-chunky-big"
            [tooltipAppendToBody]="true"
          >
            <i class="ssi ssi-send-icon"></i>
            <p>Use Draft</p>
          </li>
          <!-- <li class="draft-actions-item">
            <i class="ssi ssi-scheduling"></i>
            <p>Draft Audit</p>
          </li> -->
        </ul>
        <ul class="draft-actions-right">
          <a
            *ngIf="showDelete"
            (click)="showDeleteDraftTooltip()"
            #deleteDraftTooltip="ssiTooltip"
            [ssiTooltip]
            tooltipPlacement="right"
            tooltipTrigger="manual"
            [tooltipTemplate]="deleteDraftTooltipTemplate"
          >
            <i class="delete ssi ssi-small-delete"></i>
          </a>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteDraftTooltipTemplate>
  <div
    class="delete-draft-tooltip"
    (ssiOffClick)="hideDeleteDraftTooltip()"
  >
    <h5>Would you like to delete this draft?</h5>
    <div class="buttons-container">
      <orlo-button
        [state]="'text'"
        [text]="'Cancel'"
        [textColor]="'#43537F'"
        (btnClick)="hideDeleteDraftTooltip()"
      ></orlo-button>
      <orlo-button
        [state]="'text'"
        [text]="'Delete draft'"
        [textColor]="'#E34916'"
        (btnClick)="deleteDraft(draft)"
      ></orlo-button>
    </div>
  </div>
</ng-template>