<div class="statistics-container">
  <button
    (click)="selfAssignStalestUnassignedConversation()"
    (input)="null"
    attr.data-pendo="{{ pendoTagForButton }}"
    [disabled]="isButtonDisabled"
    class="btn  next-chat-button"
    type="button"
  >
    <span
      *ngIf="! isAssigning"
      translate="NEXT_CHAT_PLEASE"
    ></span>

    <span
      *ngIf="!! isAssigning"
    >
      <i class="ssi ssi-loading ssi-spin"></i>

      <span
        translate="PLEASE_WAIT_ELLIPSIS"
      ></span>
    </span>
  </button>

  <ssi-live-chat-conversation-statistics
    (conversationsFilterChange)="updateFilter($event)"
    [ageOfNewestAssignedConversation]="ageOfNewestAssignedConversation"
    [ageOfNewestUnassignedConversation]="ageOfNewestUnassignedConversation"
    [isAgentOnline]="isAgentOnline"
    [quantityOfOngoingConversations]="quantityOfOngoingConversations"
    [quantityOfOwnedConversations]="quantityOfOwnedConversations"
    [quantityOfUnassignedConversations]="quantityOfUnassignedConversations"
  ></ssi-live-chat-conversation-statistics>
</div>

<div
  *ngIf="isBrowserOffline"
  class="browser-offline"
>
  <strong>
    Your browser is offline since {{ browserOfflineAt }}
    
    <span
      *ngIf="!! browserOfflineTotalInSeconds"
    >
      ; {{ browserOfflineTotalInSeconds }} seconds this session.
    </span>
  </strong>

  <strong>
    Your Live Chat connection will be restored once your browser is online.
  </strong>
</div>

<ssi-live-chat-offline-agent-notice
  *ngIf="isAgentOffline"
  [agent]="agent"
></ssi-live-chat-offline-agent-notice>

<ng-container
  *ngIf="!isAgentOffline"
>
  <div
    *ngIf="! isPushModeEnabled && !quantityOfVisibleConversations"
    class="no-chats-visible"
  >
    <div
      *ngIf="quantityOfUnassignedConversations"
      class="available-unassigned-chats"
    >
      <div class="encouragement">
        <i class="encouragement-icon  ssi  ssi-microcopy-wave"></i>

        <span
          class="encouragement-message"
          translate="RIDE_THE_WAVE_AMP_STAY_PRODUCTIVE"
        ></span>
      </div>

      <button
        (click)="selfAssignStalestUnassignedConversation()"
        (input)="null"
        [disabled]="!areUnassignedChatsAvailable || !! isAssigning || !! isInterfaceDisabled"
        class="btn"
        type="button"
      >
        <span translate="NEXT_CHAT_PLEASE"></span>
      </button>
    </div>

    <div
      *ngIf="!quantityOfUnassignedConversations"
      class="unavailable-unassigned-chats"
    >
      <div class="encouragement">
        <i class="ssi ssi-microcopy-koala encouragement-icon"></i>

        <span
          class="encouragement-message"
          translate="GOOD_WORK_CUDDLY_KOALA_SALUTES_YOU"
        ></span>
      </div>

      <button
        [disabled]="true"
        class="btn"
        type="button"
      >
        <span translate="CURRENTLY_NO_CHATS"></span>
      </button>
    </div>
  </div>
</ng-container>
