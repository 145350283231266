<div class="publisher-targeting-header d-flex">
  <button
    class="publisher-close-btn"
    (click)="publisherActive.next({isActive: false})"
  >
    <span
      translate="CANCEL_POST"
      *ngIf="!post.edit"
    ></span>
    <span
      translate="CANCEL_EDIT"
      *ngIf="post.edit"
    ></span>
  </button>
  <div class="flex-1 text-right">
    <a
      *ngIf="hasFacebook"
      class="tab-nav"
      [class.active]="activeNetwork === TargetingNetwork.Facebook"
      (click)="activeNetwork = TargetingNetwork.Facebook"
    >
      Facebook
    </a>

    <a
      *ngIf="hasLinkedIn"
      class="tab-nav"
      [class.active]="activeNetwork === TargetingNetwork.LinkedIn"
      (click)="activeNetwork = TargetingNetwork.LinkedIn"
    >
      LinkedIn
    </a>

    <a
      *ngIf="hasNextdoor"
      class="tab-nav"
      [class.active]="activeNetwork === TargetingNetwork['Nextdoor Agency']"
      (click)="activeNetwork = TargetingNetwork['Nextdoor Agency']"
    >
      Nextdoor
    </a>

    <a
      *ngIf="hasNextdoorUS"
      class="tab-nav"
      [class.active]="activeNetwork === TargetingNetwork['Nextdoor Agency']"
      (click)="activeNetwork = TargetingNetwork['Nextdoor Agency']"
    >
      Nextdoor US
    </a>

    <button
      class="tab-nav exit"
      (click)="goBack()"
      [disabled]="targetingLoadingTracker.active"
    >
      Exit Targeting
    </button>
  </div>

</div>

<div
  class="publisher-targeting-body"
  *ngIf="activeNetwork === TargetingNetwork.Facebook"
>

  <div
    class="row"
    *ngFor="let row of targetingInputs.Facebook"
  >
    <div
      *ngFor="let input of row | slice:0:2"
      class="col-xs-6"
    >
      <label><span [translate]="input.labelTranslateKey"></span></label>
      <ssi-outbox-typeahead
        *ngIf="input.key === 'countries'"
        (valueChange)="onFacebookCountriesChange()"
        [(value)]="input.value"
        [disabled]="input.disable"
        [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
        [suggestions]="input.options"
        [class.has-value]="input.value.length > 0"
      ></ssi-outbox-typeahead>

      <ssi-outbox-typeahead
        *ngIf="input.key === 'cities'"
        (filterChange)="onFacebookCitiesFilterChange($event)"
        (valueChange)="onFacebookCitiesChange()"
        [(value)]="input.value"
        [disabled]="input.disable"
        [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
        [suggestions]="input.options"
        [class.has-value]="input.value.length > 0"
      ></ssi-outbox-typeahead>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 dropdown-menu-container">
      <label>
        Age range
      </label>
      <div class="select-range">
        <button
          class="dropdown-menu-trigger"
          ngbDropdown
          ngbDropdownToggle
          [class.has-value]="post.targeting.Facebook.min_age"
          type="button"
        >
          <span>
            <ng-container *ngIf="!post.targeting.Facebook.min_age">
              18
            </ng-container>

            <ng-container *ngIf="post.targeting.Facebook.min_age">
              {{ post.targeting.Facebook.min_age }}
            </ng-container>
          </span>
          <i class="ssi ssi-arrow-down-correct trigger-icon"></i>

          <ul
            class="dropdown-menu"
            ngbDropdownMenu
          >
            <li>
              <button (click)="post.targeting.Facebook.min_age = null">
                Not specified
              </button>
            </li>

            <li *ngFor="let age of socialNetworkSettings.Facebook.targeting.ages.min">
              <button (click)="post.targeting.Facebook.min_age = age">
                {{ age }}
              </button>
            </li>
          </ul>
        </button>
        <button
          class="dropdown-menu-trigger"
          [class.has-value]="post.targeting.Facebook.max_age"
          ngbDropdown
          ngbDropdownToggle
          type="button"
        >
          <span>
            <ng-container *ngIf="!post.targeting.Facebook.max_age">
              65+
            </ng-container>

            <ng-container *ngIf="post.targeting.Facebook.max_age">
              {{ post.targeting.Facebook.max_age }}
            </ng-container>
          </span>
          <i class="ssi ssi-arrow-down-correct trigger-icon"></i>

          <ul
            class="dropdown-menu"
            ngbDropdownMenu
          >
            <li>
              <button (click)="post.targeting.Facebook.max_age = null">
                Not specified
              </button>
            </li>

            <li
              *ngFor="let age of socialNetworkSettings.Facebook.targeting.ages.max | greaterThan:post.targeting.Facebook.min_age"
            >
              <button (click)="post.targeting.Facebook.max_age = age">
                {{ age }}
              </button>
            </li>
          </ul>
        </button>
      </div>
    </div>

    <div class="col-xs-6  dropdown-menu-container">
      <label>
        Gender
      </label>

      <button
        class="dropdown-menu-trigger"
        [class.has-value]="singleValueInputs.Facebook.gender"
        ngbDropdown
        ngbDropdownToggle
        type="button"
      >
        <span>
          <ng-container *ngIf="!singleValueInputs.Facebook.gender">
            Select
          </ng-container>

          <ng-container *ngIf="singleValueInputs.Facebook.gender">
            {{ singleValueInputs.Facebook.gender.label }}
          </ng-container>
        </span>
        <i class="ssi ssi-arrow-down-correct trigger-icon"></i>

        <ul
          class="dropdown-menu"
          ngbDropdownMenu
        >
          <li>
            <button (click)="singleValueInputs.Facebook.gender = null">
              Not specified
            </button>
          </li>

          <li *ngFor="let gender of socialNetworkSettings.Facebook.targeting.gender">
            <button (click)="singleValueInputs.Facebook.gender = gender">
              {{ gender.label }}
            </button>
          </li>
        </ul>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6  dropdown-menu-container">
      <label>
        Relationship status
      </label>

      <button
        class="dropdown-menu-trigger"
        [class.has-value]="singleValueInputs.Facebook.relationship_status"
        ngbDropdown
        ngbDropdownToggle
        type="button"
      >
        <span>
          <ng-container *ngIf="!singleValueInputs.Facebook.relationship_status">
            Select status
          </ng-container>

          <ng-container *ngIf="singleValueInputs.Facebook.relationship_status">
            {{ singleValueInputs.Facebook.relationship_status.label }}
          </ng-container>
        </span>
        <i class="ssi ssi-arrow-down-correct trigger-icon"></i>

        <ul
          class="dropdown-menu"
          ngbDropdownMenu
        >
          <li *ngFor="let relationshipStatus of socialNetworkSettings.Facebook.targeting.relationshipStatus">
            <button (click)="singleValueInputs.Facebook.relationship_status = relationshipStatus">
              {{ relationshipStatus.label }}
            </button>
          </li>
        </ul>
      </button>
    </div>
  </div>
</div>

<div
  class="publisher-targeting-body"
  *ngIf="activeNetwork === TargetingNetwork.LinkedIn"
>
  <div
    class="row"
    *ngFor="let row of targetingInputs.LinkedIn"
  >
    <div
      *ngFor="let input of row"
      [class]="'col-xs-' + (12 / row.length)"
    >
      <label><span [translate]="input.labelTranslateKey"></span></label>

      <ssi-outbox-typeahead
        [(value)]="input.value"
        [disabled]="input.disable"
        [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
        [suggestions]="input.options"
        [class.has-value]="input.value.length > 0"
      ></ssi-outbox-typeahead>
    </div>
  </div>

</div>

<div
  class="publisher-targeting-body"
  *ngIf="activeNetwork === TargetingNetwork['Nextdoor Agency'] || activeNetwork === TargetingNetwork['Nextdoor Agency US']"
>
  <div class="row">
    <div class="orlo-select">
      <label
        class="control-label text-muted spacer-top"
      >Boundaries</label>
      <div class="input-wrapper">
        <ssi-dropdown-select-2 name="boundaries"
                                class="boundary-selector"
                                headButtonStyleVariant="analytics"
                                size="large"
                                [multiple]="true"
                                [filterable]="true"
                                [toggleAllEnabled]="false"
                                [filterPlaceholder]="'Search boundaries to include...'"
                                [headButtonIcon]="'ssi ssi-addsmall'"
                                [toggleAllLabel]="'Select all boundaries'"
                                [options]="boundaries"
                                [(ngModel)]="selectedBoundaries"
                                (ngModelChange)="onSelectedBoundariesChange($event)">
          <ng-template ssiTemplateSelector="headButtonLabel"
                        let-expanded="expanded">
            <div class="select-boundaries-placeholder">
              <span>Select your boundaries</span>
            </div>
            <!-- <div class="select-tag-labels"
                  *ngIf="post.targeting['Nextdoor Agency'].group_ids.length">
              <ng-container *ngFor="let boundary of post.targeting['Nextdoor Agency'].group_ids">
                <span (click)="$event.stopPropagation(); removeIncludedTag(tag)">
                  {{boundary.name}} <i class="ssi ssi-times"></i>
                </span>
              </ng-container>
            </div> -->
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
      <!-- <select
        name="group_ids"
        [(ngModel)]="post.targeting['Nextdoor Agency'].group_ids"
      >
        <option *ngFor="let boundary of boundaries" [ngValue]="boundary.group_id">{{boundary.name}}</option>
      </select>
      <i class="ssi ssi-arrow-down-correct"></i> -->
    </div>
    <ul class="boundary-chips">
      <li *ngFor="let boundary of selectedBoundaries">
        <span>{{boundary.name}}</span><i (click)="removeBoundary(boundary.group_id)" class="ssi ssi-times"></i>
      </li>
    </ul>
  </div>
  <!-- <div
    class="row"
    *ngFor="let row of targetingInputs.LinkedIn"
  >
    <div
      *ngFor="let input of row"
      [class]="'col-xs-' + (12 / row.length)"
    >
      <label><span [translate]="input.labelTranslateKey"></span></label>

      <ssi-outbox-typeahead
        [(value)]="input.value"
        [disabled]="input.disable"
        [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
        [suggestions]="input.options"
        [class.has-value]="input.value.length > 0"
      ></ssi-outbox-typeahead>
    </div>
  </div> -->

</div>

<div class="publisher-targeting-footer text-center">
  <span
    [ssiTooltip]
    [tooltipAppendToBody]="true"
    [tooltipTemplate]="targetingTooltipTemplate"
  ><i class="ssi ssi-pro-tip"></i>PRO TIP</span>
  <button
    [disabled]="targetingLoadingTracker.active"
    (click)="goBack()"
  >
    <i
      *ngIf="targetingLoadingTracker.active"
      class="fa fa-spinner fa-spin fa-fw"
    ></i>

    Save targeting selection
  </button>
</div>

<ng-template #targetingTooltipTemplate>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      What is Targeting?
    </div>
    <div class="tooltip-body-contents">
      <p>
        Targeting is a way to narrow down your Facebook and LinkedIn audiences to those you want to speak to most.
        Forget shouting into the void, filter your audience based on age, interests and other demographics to ensure
        your content packs a punch and leaves them wanting more.
      </p>
    </div>
  </div>
  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>