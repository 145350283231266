<ul>
  <li
    *ngFor="let member of team"
    (click)="onMemberClick(member)"
    class="member {{ getNetworkStateValueForTeamMember(member) }}"
  >
    <ssi-live-chat-network-state
      [disabled]="isTeamMemberOffline(member)"
      [isIgnoringClicks]="isIgnoringClicks"
      [user]="member"
    ></ssi-live-chat-network-state>
  </li>
</ul>
