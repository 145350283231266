import { Directive, ElementRef, InjectionToken } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `KtdGridResizeHandleDirective`. It serves as
 * alternative token to the actual `KtdGridResizeHandleDirective` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const KTD_GRID_RESIZE_HANDLE = new InjectionToken<KtdGridResizeHandleDirective>(
  'KtdGridResizeHandleDirective'
);

/** Handle that can be used to drag a KtdGridItem instance. */
@Directive({
  selector: '[ssiKtdGridResizeHandle]',
  host: {
    class: 'ktd-grid-resize-handle'
  },
  providers: [
    {
      provide: KTD_GRID_RESIZE_HANDLE,
      useExisting: KtdGridResizeHandleDirective
    }
  ]
})
export class KtdGridResizeHandleDirective {
  constructor(public element: ElementRef<HTMLElement>) {}
}
