<ssi-live-chat-network-state
  *ngIf="! message.fromRobot"
  [isAvatarOnly]="true"
  [user]="author"
></ssi-live-chat-network-state>

<div class="message-body">
  <button
    *ngIf="message.hasTags && isSelected"
    (click)="onClickTags()"
    class="tags-icon  has-tags"
    data-pendo="liveChat_clickToAddTagFromWithinMessage"
    type="button"
  >
    <i class="ssi ssi-tag-test"></i>
  </button>

    <!--
  <button
    *ngIf="! isSelected && message.fromVisitor"
    class="tags-icon  tags-available"
    type="button"
  >
    <i class="ssi ssi-addsmall"></i>
  </button> -->

  <p class="author">
    {{ message.author.displayName }}
  </p>

  <p class="additional">
    <span
      *ngIf="! message.fromRobot"
      class="network-state"
    >
      {{ networkStateForAuthor }}
    </span>

    <span
      *ngIf="!! applicationName"
      class="application"
    >
      {{ applicationName }}
    </span>

    <span class="age">
      {{ message.relativeAge }}
    </span>
    <span class= "translated" *ngIf="translation && translation.detectedSourceLanguage !== 'en'">
      Translated from {{fetchLanguageTranslation()}}
    </span>
  </p>
  <p
    *ngIf="!! message.text && ! message.attachment && !translation"
    [innerHtml]="message.text | nl2br | linkify"
    class="text"
  ></p>

  <p
    *ngIf="translation && ! message.attachment"
    [innerHtml]="translation.translatedText | nl2br | linkify"
    class="text"
  ></p>

  <p
    *ngIf="!! message.attachment"
    class="attachment"
  >
    <img
      *ngIf="message.hasImageAsAttachment"
      src="{{ message.attachment.url }}"
    >

    <a
      *ngIf="!message.hasImageAsAttachment"
      href="{{ message.attachment.url }}"
      target="_blank"
    >
      Download file
    </a>
  </p>
</div>

<ul
  *ngIf="message.fromVisitor"
  class="message-footer"
>
  <li
    (click)="onClickNotes()"
    class="message-footer-notes"
  >
    <i
      class="ssi ssi-feather-note link-icon"
    ></i>

    <span
      *ngIf="!! message.notes.length"
      class="message-footer-notes-label"
    >
      <span
        class="message-footer-notes-label-text"
        translate="NOTES"
      ></span>

      <span
        class="message-footer-notes-label-value"
      >
        ({{ message.notes.length }})
      </span>
    </span>
  </li>
</ul>
