import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Auth } from '@ui-resources-angular';

// import { API_ENDPOINT } from '@orloApp/environment';
import { API_ENDPOINT } from '../../../environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
  readonly url = API_ENDPOINT;

  constructor(
    protected http: HttpClient,
    @Inject('$auth') protected $auth: Auth
  ) {}

  get<T>(url: string, opts = {}): Observable<T> {
    this.log(`GET:  ${url}`);
    this.appendDefaultOpts(opts);

    return this.http
      .get<T>(url, opts)
      .pipe(catchError((error) => throwError(error)));
  }

  post<T>(url: string, body = {}, opts = {}): Observable<T> {
    this.log(`POST:  ${url}`);
    this.appendDefaultOpts(opts);

    return this.http
      .post<T>(url, body, opts)
      .pipe(catchError((error) => throwError(error)));
  }

  put<T>(url: string, body = {}, opts = {}): Observable<T> {
    this.log(`PUT:  ${url}`);
    this.appendDefaultOpts(opts);

    return this.http
      .put<T>(url, body, opts)
      .pipe(catchError((error) => throwError(error)));
  }

  delete<T>(url: string, opts = {}): Observable<T> {
    this.log(`DELETE:  ${url}`);
    this.appendDefaultOpts(opts);

    return this.http
      .delete<T>(url, opts)
      .pipe(catchError((error) => throwError(error)));
  }

  buildQuery(params: { [key: string]: any }): string {
    const query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    return query;
  }

  mapError(error: any, url: string) {
    if (error instanceof HttpErrorResponse) {
      console.error(
        `API error:`,
        JSON.stringify({
          url,
          description: 'Server error',
          rawError: error
        })
      );

      return throwError(error);
    }

    if (error instanceof Error) {
      console.error(
        `API error:`,
        JSON.stringify({
          url,
          description: 'Invalid server response (mapping error)',
          rawError: error
        })
      );
      return throwError(error);
    }

    console.error(
      `API error:`,
      JSON.stringify({
        url,
        description: 'Unknown error',
        rawError: error
      })
    );

    return throwError(error);
  }

  appendDefaultOpts(opts: any): void {
    // opts.observe = opts.observe || 'response';
    // opts.responseType = opts.responseType || 'json';
    opts.headers = opts.headers || new HttpHeaders();
    opts.headers = opts.headers.append(
      'X-Authorization',
      `Bearer ${this.$auth.getToken()}`
    );

    if (!opts.headers.has('Content-Type')) {
      opts.headers = opts.headers.append('Content-Type', 'application/json');
    }
    if (!opts.headers.has('Accept')) {
      opts.headers = opts.headers.append('Accept', 'application/json');
    }
  }

  log(log: string): void {
    console.log(log);
  }
}
