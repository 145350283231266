import { utils, Record } from 'js-data';
import { api } from '../../core/services/api';
import { Account, AccountModel } from '../../account/services/accountModel';
import { Activity, ActivityModel } from '../../activity/services/activityModel';
import { services } from '../../common';
import { Model } from '../../model';
import { Outbox } from '../../publish/services/outboxModel';

export interface ProfileFeedItem {
  created_at: Date;
  have_liked: boolean;
  have_shared: boolean;
  id: string;
  image_url: string;
  like_count: number;
  location: string | null;
  share_count: number;
  text: string;
  link?: string;
}

export interface ProfileFeed {
  next: string;
  posts: ProfileFeedItem[];
}

export interface ProfileSearchLocation {
  latitude: number;
  longitude: number;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  /**
   * Constructed on-the-go after API response
   */
  fullLocation?: string;
}

export interface ProfileSearchResult {
  description: string;
  id: string;
  image_url: string;
  location: ProfileSearchLocation;
  name: string;
  username: string;
  verified: boolean | null;
}

export class Profile extends Record {
  account_id: string;
  account: Account;
  id: string;
  flags: any[];

  inbox_stats: {
    negative_sentiment: number;
    neutral_sentiment: number;
    positive_sentiment: number;
    semi_negative_sentiment: number;
    semi_positive_sentiment: number;
    total: number;
  };

  info: {
    connection_count?: number;
    description?: string;
    extra?: any;
    location?: string;
    name?: string;
    picture?: string;
    relationship?: {
      can_message: boolean;
      followed_by: boolean;
      following: boolean;
      marked_spam: boolean;
    };
    status_count?: number;
    url?: string;
    username?: string;
    verified?: boolean;
    /** Tells if the profile is blocked or not */
    blocked?: boolean;
  };

  support: {
    block?: boolean;
    follow?: boolean;
    info?: boolean;
    klout?: boolean;
    posts?: boolean;
    search?: boolean;
    stats?: boolean;
  };

  tags: string[];

  pastActivities?: Activity[];

  statistics: {
    coords?: any;
    count?: number;
    like_count?: number;
    liked_count?: number;
    max_created_at?: string;
    media_type?: any;
    mentions?: any;
    min_created_at?: string;
    share_count?: number;
    shared_count?: number;
    tags?: any;
    words?: any;
  };

  klout?: {
    score: number;
  };

  feed?: ProfileFeedItem[];
  feedNextPage?: string;

  get externalUrl() {
    if (this.account && this.account.account_type_name) {
      return `https://${this.account.account_type_name}.com/${this.info.username}`;
    }
  }

  set externalUrl(val) {}

  get socialEndorserScore() {
    const totalNegative: number =
      this.inbox_stats.negative_sentiment -
      this.inbox_stats.semi_negative_sentiment;
    const score: number =
      ((this.inbox_stats.positive_sentiment - totalNegative) /
        this.inbox_stats.total) *
      100;
    return Math.ceil(score);
  }

  // hack to make tests pass
  set socialEndorserScore(val) {}

  getFeed() {
    return services.models
      .get<ProfileModel>('profile')
      .getFeed(this.id, this.account_id)
      .then((feed: ProfileFeed) => {
        this.feed = feed.posts;
        this.feedNextPage = feed.next;
        return feed;
      });
  }

  getFeedNextPage(nextPageKey) {
    return services.models
      .get<ProfileModel>('profile')
      .getFeed(this.id, this.account_id, nextPageKey)
      .then((feed: ProfileFeed) => {
        this.feed = this.feed.concat(feed.posts);
        this.feedNextPage = feed.next;
        return feed;
      });
  }

  getPastActivity(offset) {
    return services.models
      .get<ProfileModel>('profile')
      .getPastActivity(this.id, this.account_id, offset)
      .then((activities) => {
        this.pastActivities = (this.pastActivities || []).concat(activities);
        return activities;
      });
  }

  getStatistics() {
    const promises = [];
    const apiParams = {
      params: {
        account_id: this.account_id,
        profile_id: this.id
      },
      cache: true,
      showLoading: true,
      timeout: 10000
    };

    const statsPromise = api
      .get('profile_v2/stats', Object.assign({}, apiParams))
      .then((result: { data: any }) => {
        this.statistics = result.data;
      });
    promises.push(statsPromise);

    if (this.support.klout) {
      const kloutPromise = api
        .get('profile_v2/klout', Object.assign({}, apiParams))
        .then((result: { data: any }) => {
          this.klout = result.data;
        });
      promises.push(kloutPromise);
    }

    return utils.Promise.all(promises).then(() => this.statistics);
  }

  unfollow() {
    return api
      .del('profile_v2/follow', {
        params: {
          account_id: this.account_id,
          profile_id: this.id
        },
        showLoading: true
      })
      .then(() => {
        this.info.relationship.following = false;
        return this;
      });
  }

  follow() {
    return api
      .post(
        'profile_v2/follow',
        {
          account_id: this.account_id,
          profile_id: this.id
        },
        {
          showLoading: true
        }
      )
      .then(() => {
        this.info.relationship.following = true;
        return this;
      });
  }

  block() {
    return api.post(
      'profile_v2/block',
      {
        account_id: this.account_id,
        profile_id: this.id
      },
      {
        showLoading: true
      }
    );
  }

  unblock() {
    return api.del('profile_v2/block', {
      params: {
        account_id: this.account_id,
        profile_id: this.id
      },
      showLoading: true
    });
  }

  toggleTag(tag) {
    const tagIndex = this.tags.findIndex(
      (tagItem) => tag.toLowerCase().trim() === tagItem.toLowerCase().trim()
    );
    if (tagIndex === -1) {
      return api
        .post(
          'profile_v2/tag',
          {
            account_id: this.account_id,
            profile_id: this.id,
            tag
          },
          {
            showLoading: true
          }
        )
        .then(() => {
          this.tags.push(tag);
          return this;
        });
    } else {
      return api
        .del('profile_v2/tag', {
          params: {
            account_id: this.account_id,
            profile_id: this.id,
            tag
          },
          showLoading: true
        })
        .then(() => {
          this.tags.splice(tagIndex, 1);
          return this;
        });
    }
  }
}

export class ProfileModel extends Model<Profile> {
  constructor() {
    super('profile', {
      endpoint: 'profile_v2/index',
      deserialize: (resourceConfig, attrs) => [attrs.data],
      relations: {
        belongsTo: {
          account: {
            localKey: 'account_id',
            localField: 'account'
          }
        }
      },
      recordClass: Profile
    });
  }

  findByIdAndAccountId(
    profileId,
    accountId,
    excludeInfo?,
    checkCache = false
  ): Promise<Profile> {
    const profileModel = services.models.get<ProfileModel>('profile');
    const cacheSearch = profileModel.filter({
      id: profileId,
      account_id: accountId
    });

    let promise;
    if (checkCache && cacheSearch.length > 0) {
      promise = utils.Promise.resolve(cacheSearch);
    } else {
      promise = profileModel.findAll(
        {},
        {
          params: {
            account_id: accountId,
            profile_id: profileId,
            exclude_info: excludeInfo
          },
          bypassCache: true,
          showLoading: true
        }
      );
    }

    return promise.then((results) => results[0]);
  }

  getPastActivity(profileId, accountId, offset?) {
    return api
      .get('activity/profile', {
        params: {
          account_id: accountId,
          profile_id: profileId,
          offset: offset || 0
        },
        showLoading: true
      })
      .then((result) =>
        services.models.get<ActivityModel>('activity').inject(result.data)
      );
  }

  search(
    accountId: string,
    searchText: string,
    { size, page }: { size?: number; page?: string } = {}
  ): Promise<{
    links: { next: { page: string } };
    results: any; // ProfileSearchResult[] | ProfileFeedItem[];
  }> {
    return api
      .get('profile_v2/search', {
        params: {
          account_id: accountId,
          q: searchText,
          size,
          page
        }
      })
      .then(({ data }) => data);
  }

  searchLocation(
    accountId: string,
    searchText: string,
    { size, page }: { size?: number; page?: number } = {}
  ) {
    return api
      .get('profile_v2/searchLocation', {
        params: {
          account_id: accountId,
          q: searchText,
          size,
          page
        }
      })
      .then(
        ({
          data
        }: {
          data: { links: any; results: ProfileSearchResult[] };
        }) => {
          const res = data.results.map((profile: ProfileSearchResult) => {
            function getLongName() {
              let longName = '';
              if (profile.name) {
                longName = profile.name;
              }
              if (profile.location.street) {
                longName = `${longName}, ${profile.location.street}`;
              }
              if (profile.location.city) {
                longName = `${longName}, ${profile.location.city}`;
              }
              if (profile.location.zip) {
                longName = `${longName}, ${profile.location.zip}`;
              }
              if (profile.location.country) {
                longName = `${longName}, ${profile.location.country}`;
              }

              return longName;
            }
            profile.location.fullLocation = getLongName();
            return profile;
          });
          return res;
        }
      );
  }

  getFeed(
    profileId: string,
    accountId: string,
    nextPageKey?: string
  ): Promise<ProfileFeed> {
    const account = services.models.get<AccountModel>('account').get(accountId);
    const profile = this.get(profileId);
    const params = {
      account_id: accountId,
      profile_id: profileId
    };
    if (nextPageKey) {
      params['next'] = nextPageKey;
    }
    return api
      .get('profile_v2/pagedPosts', { params, showLoading: true })
      .then((result: { data: ProfileFeed }) => {
        return {
          next: result.data.next,
          posts: result.data.posts.map((post) => {
            // hacky way of getting the feed links by making the post look like an outbox post
            if (account.socialNetwork.hasOwnProperty('getExternalLink')) {
              post.link = account.socialNetwork.getExternalLink(
                new Outbox({
                  account: new Account({
                    username: profile.info.username,
                    social_id: profile.id
                  }),
                  social_id: post.id,
                  response: ''
                })
              );
            }
            return post;
          })
        };
      });
  }
}

export function profileModelFactory(dataStore?) {
  return services.models.get('profile') || new ProfileModel();
}
