<div class="influencers-list">
  <ul class="influencers-list-labels">
    <li class="influencers-list-label"><p>Influencer</p></li>
    <li class="influencers-list-label">
      <p class="actionable" (click)="sortInfluencers(sortType.Followers)">Followers</p>
      <button
        [ngClass]="{'influencers-list-label-action-active': activeSort === sortType.Followers}"
        class="influencers-list-label-action"
        (click)="sortInfluencers(sortType.Followers)"
      >
        <i class="fa fa-caret-down"></i>
      </button>
    </li>
    <li class="influencers-list-label influencers-list-label-right">
      <p class="actionable" (click)="sortInfluencers(sortType.Engagements)">Engagements</p>
      <button
        [ngClass]="{'influencers-list-label-action-active': activeSort === sortType.Engagements}"
        class="influencers-list-label-action"
        (click)="sortInfluencers(sortType.Engagements)"
      >
        <i class="fa fa-caret-down"></i>
      </button>
    </li>
  </ul>

  <ol class="influencers-list-data">
    <li *ngFor="let influencer of influencers; let index = index" class="influencers-list-data-item">
      <div class="influencers-list-data-col influencer-meta">
        <div class="influencer-account">
          <div class="influencer-badge">
            <ssi-icon
              *ngIf="[0,1,2].includes(index)"
              [ngClass]="{
                'influencer-badge-wreathe-gold': index === 0,
                'influencer-badge-wreathe-silver': index === 1,
                'influencer-badge-wreathe-bronze': index === 2
              }"
              class="influencer-badge-wreathe"
              icon="wreathe"
              height="21"
              width="26"
            ></ssi-icon>
            <span class="influencer-badge-meta">{{index + 1}}</span>
          </div>
          <div class="influencer-account-avatar">
            <img [src]="influencer.avatar" ssiFallbackImage="brokenImageNew">
          </div>
          <div
            class="influencer-account-details"
            [ssiTooltip]="influencer.name + ' (' + influencer.username + ')'"
            tooltipPlacement="top"
            tooltipWindowClass="tooltip-chunky"
          >
            <span *ngIf="influencer.networkIcon" class="influencer-account-details-network">
              <i [class]="influencer.networkIcon"></i> <!-- include network color -->
            </span>
            <p class="influencer-account-name">{{influencer.name}}</p>
            <span *ngIf="influencer.username"  class="influencer-account-alias">
              (@{{influencer.username}})
            </span>
          </div>
        </div>
      </div>

      <div class="influencers-list-data-col influencer-followers">
        <p *ngIf="influencer.followers">{{influencer.followers | bigNumber}}</p>
        <p *ngIf="!influencer.followers">-</p>
      </div>

      <div class="influencers-list-data-col influencer-engagements">
        <button class="influencer-button" (click)="openDrilldownModal(influencer.id)">
          <span>View {{
            influencer.engagements > 9 ? influencer.engagements : '0' + influencer.engagements
          }} engagements</span>
        </button>
      </div>
    </li>
  </ol>
</div>