import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ConversationHoldComponent } from './conversation-hold.component';

import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, FormsModule, DropdownSelect2Module, TooltipModule],
  exports: [ConversationHoldComponent],
  declarations: [ConversationHoldComponent]
})
export class ConversationHoldModule {}
