<div class="notes-modal-container">
  <div class="head g-box-shadow-bottom">
    <h3>{{modalHeader}}</h3>
    <i class="close-icon ssi ssi-times"
       (click)="activeModal.dismiss()"></i>
  </div>

  <div class="note-create">
    <div class="add-note-wrapper">
      <ssi-add-note [disableTags]="disableTags"
                    (addNote)="addNote($event)"
                    (cancelNote)="cancelNote()"></ssi-add-note>
    </div>
  </div>

  <div class="note-list">
    <div class="note-list-item"
         *ngFor="let note of notes"
         [@listItemInsertRemoveAnimation]>
      <ssi-note [note]="note"
                (deleteNote)="deleteNote(note)"></ssi-note>
    </div>
  </div>
</div>