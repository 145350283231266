import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ValidateRepliesComponent } from './validate-replies.component';
import { FormsModule } from '@angular/forms';
import { ValidatesPostsBoxModule } from '../validate-posts/validate-posts-box/validate-posts-box.module';
import { ValidatePostsModalModule } from '../validate-posts/validate-posts-modal/validate-posts-modal.module';
import { ValidateRepliesFiltersModule } from './validate-replies-filters/validate-replies-filters.module';
import { ValidateReplyBoxModule } from './validate-reply-box/validate-reply-box.module';
import { ValidateReplyBoxContentModule } from './validate-reply-box-content/validate-reply-box-content.module';
import { TranslateModule } from '@ngx-translate/core';
import { ValidatePostsGalleryModule } from '../validate-posts/validate-posts-box/validate-posts-gallery/validate-posts-gallery.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.validateReplies',
          url: '/validate-replies',
          component: ValidateRepliesComponent,
          resolve: ValidateRepliesComponent.resolve
        }
      ]
    }),
    ValidatesPostsBoxModule,
    FormsModule,
    ValidatePostsModalModule,
    ValidateRepliesFiltersModule,
    ValidateReplyBoxModule,
    ValidateReplyBoxContentModule,
    ValidatePostsGalleryModule,
    TranslateModule
  ],
  declarations: [ValidateRepliesComponent],
  entryComponents: [ValidateRepliesComponent]
})
export class ValidateRepliesModule {}
