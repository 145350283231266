<div class="top-post-stats-container">
  <div class="top-post-interaction">
    <div class="rank" id="rank-{{index}}">
      <div>
        {{index + 1}}
      </div>
      <i class="ssi ssi-social-score"></i>
    </div>
   
    <span class="number">
      <ssi-big-number [number]="topPost.interactions.total"></ssi-big-number>
    </span>
    <span class="subtitle">Interactions</span>
  </div>

  <div class="top-post-score">
    <ul>
      <li>
        <i class="ssi ssi-fb-like"></i>
        <ssi-big-number [number]="topPost.interactions.like_count"></ssi-big-number>
        <span>Likes</span>
      </li>
      <li>
        <i class="ssi ssi-shares"></i>
        <ssi-big-number [number]="topPost.interactions.share_count"></ssi-big-number>
        <span>Shares</span>
      </li>
      <li *ngIf="topPost.interactions.comment_count">
        <i class="ssi ssi-total-engagement"></i>
        <ssi-big-number [number]="topPost.interactions.comment_count"></ssi-big-number>
        <span>Comments</span>
      </li>
      <li *ngIf="topPost.interactions.reaction_count">
        <i class="ssi ssi-reactions"></i>
        <ssi-big-number [number]="topPost.interactions.reaction_count"></ssi-big-number>
        <span>Reactions</span>
      </li>
    </ul>
  </div>
</div>