<form
  name="newNoteForm"
>
  <div
    class="form-inner"
  >
    <textarea
      (blur)="onBlurNoteForm()"
      (focus)="onFocusNoteForm()"
      [autofocus]="isNoteFormFocused"
      [formControl]="newNote"
      [placeholder]="'TYPE_YOUR_NOTE_HERE' | translate"
      class="form-control minimal"
      required
      rows="3"
    ></textarea>
    
    <span
      class="author"
    >
      <span
        translate="WRITTEN_BY"
      ></span>

      <span
        class="name"
      >
        {{ author }}
      </span>
    </span>
  </div>

  <ul
    class="form-footer"
  >
    <li class="footer-information">
      <button
        (click)="save()"
        [disabled]="! canSave"
        class="btn save-button"
        translate="SAVE_NOTE"
        type="submit"
      ></button>
    </li>
  </ul>
</form>
