import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { BlurImageDirective } from './blur-image.directive';
import { PhraseConfirmModalModule } from '../../components/phrase-confirm-modal/phrase-confirm-modal.module';

@NgModule({
  imports: [CommonModule, NgbModalModule.forRoot(), PhraseConfirmModalModule],
  exports: [BlurImageDirective],
  declarations: [BlurImageDirective]
})
export class BlurImageModule {}
