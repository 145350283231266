<div class="twitter-threads-header">
  <h1>X Thread</h1>
  <div class="twitter-threads-header-button">
    <p>Thread length: {{thread.length > 9 ? thread.length : '0' + thread.length}} Posts</p>
  </div>
</div>
<div class="twitter-threads-body">
  <orlo-button
    class="twitter-thread-back"
    (click)="window.history.back()"
    state="primary"
    text="Back"
  ></orlo-button>
  <div class="twitter-thread-container" *ngIf="thread.length">
    <div class="twitter-thread-container-offset">
      <div class="twitter-thread-row twitter-thread-tweet" *ngFor="let post of thread; let i = index">
        <div class="th-row-counter">
          <div><p>{{i > 9 ? i + 1 : '0' + (i + 1)}}</p></div>
        </div>
        <div class="th-row-item">
          <ssi-outbox-message
            [message]="post"
            [account]="account"
            [author]="getAuthor(post.user_id)"
            [campaign]="post.campaign"
          ></ssi-outbox-message>
        </div>
      </div>
      <div class="twitter-thread-row twitter-thread-add">
        <p *ngIf="!thread[thread.length - 1].social_id">Waiting for previous post to be published</p>
        <div class="action-button-wrap">
          <orlo-button
            *ngIf="displayAdd || !pendingPublish"
            state="primary"
            text="Add to thread"
            [disabled]="!thread[thread.length - 1].social_id || publisherOpen"
            [leadingIcon]="'ssi ssi-add-to-thread'"
            [ssiTriggerPublish]="{create: {accounts: this.account, replyToSocialId: thread[thread.length - 1].social_id}, disable: {previewPost: true, changeAccount: true}}">
          </orlo-button>
          <div *ngIf="!thread[thread.length - 1].social_id" class="action-button-wrap-overlay"></div>
        </div>
        <div *ngIf="!displayAdd && pendingPublish" class="loading"></div>
      </div>
    </div>
  </div>
</div>