<ng-container
  *ngIf="!! note"
>
  <p
    [ngClass]="{
      'self-penned': didActiveAgentAuthorNote
    }"
  >
    {{ content | nl2br }}
  </p>

  <div
    class="footer"
  >
    <ul
      *ngIf="!! canAddMore || !! canViewMore"
      class="footer-options"
    >
      <li>
        <button
          *ngIf="!! canAddMore"
          (click)="onClickAddNote()"
          class="add-button"
        >
          <i class="ssi ssi-plus button-icon"></i>

          <span
            class="button-text"
            translate="ADD_NOTE"
          ></span>
        </button>
      </li>

      <li
        *ngIf="!! canViewMore && message.notes.length > 1"
      >
        <button
          (click)="onClickMoreNotes()"
          class="view-more-button"
        >
          <span
            class="button-text"
          >
            <span
              translate="VIEW"
            ></span>
            
            <span>
              {{ message.notes.length - 1 }}
            </span>

            <span
              *ngIf="message.notes.length === 2"
              translate="MORE_NOTE"
            ></span>

            <span
              *ngIf="message.notes.length > 2"
              translate="MORE_NOTES"
            ></span>
          </span>
        </button>
      </li>
    </ul>

    <ul
      class="footer-information"
    >
      <li>
        {{ date | date: 'd MMM HH:mm' }}
      </li>

      <li
        *ngIf="! didActiveAgentAuthorNote"
      >
        <span
          class="author"
        >
          <span
            translate="WRITTEN_BY"
          ></span>

          <span
            class="name"
          >
            {{ author }}
          </span>
        </span>

        <i
          class="ssi ssi-feather-note information-icon"
        ></i>
      </li>

      <li
        *ngIf="!! didActiveAgentAuthorNote"
      >
        <button
          (click)="onClickDelete()"
          class="delete-button"
        >
          <i
            class="ssi ssi-trash button-icon"
          ></i>

          <span
            class="button-text"
            translate="DELETE"
          ></span>
        </button>
      </li>
    </ul>
  </div>


</ng-container>
