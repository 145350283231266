import { Injectable } from '@angular/core';

@Injectable()
export class ImageHelperService {
  preload(url: string): Promise<any> {
    const img = new Image();
    return new Promise((resolve, reject) => {
      img.style.display = 'none';
      img.onload = resolve;
      img.onerror = reject;
      img.src = url;
      document.body.appendChild(img);
    }).finally(() => {
      document.body.removeChild(img);
    });
  }
}
