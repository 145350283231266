<div
  [ngClass]="[
    'bg-'+theme,
    campaignHasAdvertising ? 'has-advertising' : '',
    showHeader ? 'container-marketing-no-header' : '',
    noMargin ? 'container-marketing-no-margin' : ''
  ]"
  class="container-fluid container-app-page container-flex"
>
  <ng-container *ngIf="showHeader">
    <ssi-alerts></ssi-alerts>

    <div class="row marketing-filter-menu-wrap" *ngIf="!isOutboxSearchState()">
      <div class="marketing-filter">
        <div class="d-flex marketing-filter-menu">
          <div
            *ngIf="!childCampaign"
            class="btn btn-primary btn-rounded btn-padding-sides marketing-filter-button marketing-filter-button-children"
            translate="ALL_CAMPAIGNS"
            (click)="toggleFilterByCampaignDropdown()">
          </div>
          <div
            *ngIf="childCampaign"
            class="btn btn-primary btn-rounded btn-padding-sides marketing-filter-button marketing-filter-button-inverse marketing-filter-button-children"
            translate="ALL_CAMPAIGNS"
            (click)="selectCampaign(null)">
          </div>
          <div *ngIf="parentCampaign" class="marketing-actions-menu-separator"></div>
          <div
            *ngIf="parentCampaign"
            class="btn btn-primary btn-rounded btn-padding-sides marketing-filter-button marketing-filter-button-inverse marketing-filter-button-children"
            (click)="selectCampaign(parentCampaign)">
            {{parentCampaign.name}}
          </div>
          <div *ngIf="childCampaign" class="marketing-actions-menu-separator"></div>
          <div
            *ngIf="childCampaign"
            [ngClass]="{'marketing-filter-button-children': childCampaignHasChildren}"
            class="btn btn-primary btn-rounded btn-padding-sides marketing-filter-button"
            (click)="childCampaignHasChildren ? toggleFilterByCampaignDropdown() : toggleAddCampaignDropdown()">
            {{childCampaign.name}}
          </div>
        </div>
        <div class="campaign-add-box-wrap" *ngIf="addCampaignActive">
          <ssi-campaign-add-box
            (toggleAddCampaignDropdown)="toggleAddCampaignDropdown()"
            [newCampaign]="newCampaign"
            [campaigns]="campaigns">
          </ssi-campaign-add-box>
        </div>
        <div class="marketing-filter-selecter" *ngIf="filterCampaignActive">
          <input placeholder="Search campaign name..." [(ngModel)]="filterTerm" (ngModelChange)="filterByTerm()" />
          <ul>
            <li *ngFor="let campaign of filteredCampaigns" (click)="selectCampaign(campaign)">
              {{ campaign.name }}
            </li>
          </ul>
        </div>
      </div>
      <div (click)="floatingMenu = !floatingMenu" class="marketing-tooltip-menu">
        <div class="marketing-tooltip-trigger" *ngIf="!floatingMenu">
          <p>Marketing Kit</p>
          <i class="ssi ssi-tool-kit"></i>
          <span class="marketing-tooltip-label" *ngIf="notificationManager.totalUnvalidatedPosts > 0">
            {{ notificationManager.totalUnvalidatedPosts }}
          </span>
        </div>
        <!-- <ul class="list-unstyled marketing-actions-menu">
          <li>
            <a><span class="marketing-actions-menu-label" translate="SEARCH_CAMPAIGNS"></span> <i class="ssi ss-fw ssi-search"></i></a>
          </li>
          <li>
            <a (click)="toggleAddCampaignDropdown()"><span class="marketing-actions-menu-label" translate="ADD_CAMPAIGN"></span> <i class="ssi ss-fw ssi-plus"></i></a>
          </li>
          <li>
            <a><span class="marketing-actions-menu-label" translate="BOOST_SUGGESTIONS"></span> <i class="ssi ss-fw ssi-wand"></i></a>
          </li>
        </ul>
        <div class="marketing-actions-menu-separator"></div> -->
        <ul class="list-unstyled marketing-floating-menu" *ngIf="floatingMenu" (mouseleave)="floatingMenu = false">
          <li *ngIf="permissions.validatePosts">
            <a uiSrefActive="active" uiSref=".validatePosts">
              <span class="marketing-floating-menu-label">
                <span class="marketing-floating-menu-label-pre" *ngIf="notificationManager.totalUnvalidatedPosts > 0">
                  {{ notificationManager.totalUnvalidatedPosts }}
                </span>
                <span translate="APPROVE_POSTS"></span>
              </span>
              <i class="ssi ss-fw ssi-tick marketing-floating-menu-label-icon"></i>
            </a>
          </li>
          <li *ngIf="permissions.publisher">
            <a uiSrefActive="active" uiSref=".contentLibrary">
              <span class="marketing-floating-menu-label" translate="ASSET_LIBRARY"></span> <i class="fa fa-folder-open marketing-floating-menu-label-icon"></i>
            </a>
          </li>
          <li *ngIf="permissions.bulkUploadPosts">
            <a uiSrefActive="active" uiSref=".bulkUpload">
              <span class="marketing-floating-menu-label" translate="BULK_UPLOAD"></span> <i class="ssi ss-fw ssi-correct-upload marketing-floating-menu-label-icon"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <ul class="marketing-nav list-unstyled spacer-top spacer-bottom">
      <li uiSrefActive="active">
        <a translate="OVERVIEW" uiSref=".index" [uiParams]="{query: childCampaignParams}"></a>
      </li>
      <li uiSrefActive="active">
        <a *ngIf="!advertisingEnabled" translate="BOOSTING" uiSref=".advertising.notPurchased" [uiParams]="{query: childCampaignParams}"></a>
        <a *ngIf="advertisingEnabled" translate="BOOSTING" uiSref=".advertising.index" [uiParams]="{query: childCampaignParams}"></a>
      </li>
      <li uiSrefActive="active" *ngIf="permissions.contentCalendar">
        <a translate="CONTENT_CALENDAR" uiSref=".contentCalendar" [uiParams]="{query: childCampaignParams}"></a>
      </li>
      <li *ngIf="childCampaign">
        <a [ssiTriggerPublish]="{create: {activeCampaignID: childCampaign.id}}">
          <span class="marketing-nav-icon">+</span>Campaign Post
        </a>
      </li>
      <li *ngIf="!childCampaign" class="compose-post">
        <a [ssiTriggerPublish]>
          <span class="marketing-nav-icon">+</span>Post
        </a>
      </li>
      <li uiSrefActive="active">
        <a translate="OUTBOX" uiSref=".outboxSearch" [uiParams]="{active_campaign_id: childCampaignParams}"></a>
      </li>
      <li uiSrefActive="active" *ngIf="permissions.contentGenerator">
        <a translate="RSS_FEEDS" uiSref=".contentGenerator" [uiParams]="{query: childCampaignParams}"></a>
      </li>
    </ul>

    <br>
  </ng-container>

  <ui-view class="campaign-page-wrap"></ui-view>

</div>
