<div class="content-container modal-popup">
	<div class="heading">
		<i class="ssi ssi-assign-correct"></i>
		<h3>Assign agent to chat</h3>
		<i
			class="close-icon ssi ssi-times"
			(click)="activeModal.dismiss()"
		></i>
	</div>
	<div
		class="search-field"
		[class.active]="!!searchTerm"
	>
		<input
			type="text"
			[(ngModel)]="searchTerm"
			(ngModelChange)="filterValues(searchTerm)"
			placeholder="Search team..."
		>
		<i class="ssi ssi-search-small"></i>
	</div>

  	<div class="content" *ngIf="availableAgentsModel.length > 0">
		<ul class="teams">
			<li
				*ngFor="let team of availableAgentsModel"
			>
				<div><i class="ssi ssi-teams"></i>{{team.name}}</div>
				<ul class="colleagues">
					<li
						*ngFor="let agent of team.activeAgents"
						(click)="assign(agent)"
						class="agent"
					>
						<i class="ssi ssi-user"></i>{{agent.displayName}}
						<div class="more-info">
							<span>({{quantityOfActiveChats(agent.id)}})</span>
							<svg
								height="8"
								width="8"
								[ngSwitch]="agent.networkStateValue"
							>
								<circle
									cx="4"
									cy="4"
									r="4"
									fill="#5c698d"
									*ngSwitchDefault
								/>
								<circle
									cx="4"
									cy="4"
									r="4"
									fill="#91cac7"
									*ngSwitchCase="'online'"
								/>
								<circle
									cx="4"
									cy="4"
									r="4"
									fill="#be3609"
									*ngSwitchCase="'busy'"
								/>
							</svg>
							<!-- <span class="status">{{agent.isOnline ? 'ONLINE' : 'OFFLINE'}}</span> -->
						</div>
						<i class="tick ssi ssi-tick"></i>
					</li>
				</ul>
			</li>
		</ul>
		
		<div
			class="assign-no-results"
			*ngIf="availableAgentsModel.length === 0"
		>
			<i class="ssi ssi-new-features"></i>
			<h3 translate="OH_DEAR_NO_RESULTS"></h3>
			<h6 translate="WE_CANT_FIND_ANY_USERS_OR_TEAMS_THAT_MATCH_YOUR_SEARCH_SORRY_HAVE_ANOTHER_GO"></h6>
		</div>
	</div>

	<div class="buttons">
		<button
			class="assign-to-me"
			(click)="assign(agent)"
		><i class="ssi ssi-assign-correct"></i>{{'ASSIGN_TO_ME' | translate}}</button>
		<button
			class="assign-to-me"
			(click)="assign(null)"
		>{{'UNASSIGN' | translate}}</button>
		<orlo-button
			textColor="#43537F"
			state="text"
			text="Cancel"
			textClass="uppercase"
			(btnClick)="activeModal.close()"
		></orlo-button>
	</div>
</div>