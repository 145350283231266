import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxPostNewComponent } from './outbox-post-new.component';
import { TranslateModule } from '@ngx-translate/core';
import { BigNumberModule } from '../../pipes/big-number/big-number.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TriggerPublishModule } from '../../directives/trigger-publish/trigger-publish.module';
import { MediaNavModule } from '../media-nav/media-nav.module';
import { UIRouterModule } from '@uirouter/angular';
import { Nl2brModule } from '../../pipes/nl2br/nl2br.module';
import { Nl2brPipe } from '../../pipes/nl2br/nl2br.pipe';
import { EmojiTextModule } from '../../directives/emoji-text/emoji-text.module';
import { LinkifyModule } from '../../pipes/linkify/linkify.module';
import { EscapeHtmlModule } from '../../pipes/escape-html/escape-html.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { IconModule } from '../icon/icon.module';
import { OrloComponentsModule } from 'orlo-components';
import { EnlargeImageModule } from '../../directives/enlarge-image/enlarge-image.module';
import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { FormsModule } from '@angular/forms';
import { SanitizedHtmlModule } from '../../pipes/sanitized/sanitized.module';
import { HtmlToTextModule } from '../../pipes/html-to-text/html-to-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    BigNumberModule,
    TooltipModule,
    OffClickModule,
    TriggerPublishModule,
    MediaNavModule,
    UIRouterModule,
    Nl2brModule,
    EmojiTextModule,
    LinkifyModule,
    EscapeHtmlModule,
    IconModule,
    OrloComponentsModule,
    FallbackImageModule,
    EnlargeImageModule,
    FormsModule,
    DropdownSelect2Module,
    SanitizedHtmlModule,
    HtmlToTextModule
  ],
  declarations: [OutboxPostNewComponent],
  exports: [OutboxPostNewComponent],
  providers: [Nl2brPipe]
})
export class OutboxPostNewModule {}
