import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KtdGridComponent } from './grid.component';
import { KtdGridItemComponent } from './grid-item/grid-item.component';
import { KtdGridDragHandleDirective } from './directives/drag-handle';
import { KtdGridResizeHandleDirective } from './directives/resize-handle';
import { KtdGridService } from './grid.service';

@NgModule({
  declarations: [
    KtdGridComponent,
    KtdGridItemComponent,
    KtdGridDragHandleDirective,
    KtdGridResizeHandleDirective
  ],
  exports: [
    KtdGridComponent,
    KtdGridItemComponent,
    KtdGridDragHandleDirective,
    KtdGridResizeHandleDirective
  ],
  providers: [KtdGridService],
  imports: [CommonModule]
})
export class KtdGridModule {}
