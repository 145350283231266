import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LinkClicksSectionComponent } from './link-clicks-section.component';

import { FactOverviewModule } from '../fact-overview/fact-overview.module';
import { SocialNetworkSelectModule } from '../social-network-select/social-network-select.module';

import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { HighchartModule } from '../../../../../common/components/highchart/highchart.module';
import { MapModule } from '../../../../../common/components/map/map.module';
import { ProgressBarModule } from '../../../../../common/components/progress-bar/progress-bar.module';
import { LoaderModule } from '../../../../../common/directives/loader';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { ToggleSwitch2Module } from '../../../../../common/components/toggle-switch-2/toggle-switch-2.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [LinkClicksSectionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FactOverviewModule,
    SocialNetworkSelectModule,
    BigNumberModule,
    HighchartModule,
    MapModule,
    ProgressBarModule,
    LoaderModule,
    TooltipModule,
    ToggleSwitch2Module,
    FormsModule
  ],
  exports: [LinkClicksSectionComponent]
})
export class LinkClicksSectionModule {}
