<div class="cl-dropdown-select-c-container">
  <h4>C</h4>
  <a target="_blank"
     href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-dropdown-select/cl-dropdown-select-c/cl-dropdown-select-c.component.html">
    View code on GitHub
  </a>
  <p>headButtonStyleVariant="default"; headButtonLabel template;</p>
  <p>LG size - multi-select version; optionIcon template</p>
  <ssi-dropdown-select-2 size="lg"
                         name="accounts"
                         [required]="true"
                         [multiple]="true"
                         [filterable]="true"
                         [placeholdersKeyword]="'accounts'"
                         [options]="accounts"
                         [(ngModel)]="selectedAccounts"
                         (ngModelChange)="selectedAccountsChanged($event)">
    <ng-template ssiTemplateSelector="headButtonLabel"
                 let-expanded="expanded">
      <div class="select-placeholder"
           *ngIf="!selectedAccounts.length">
        <span translate="SELECT_ACCOUNTS"></span>
      </div>
      <div class="select-accounts-label"
           *ngIf="selectedAccounts.length">
        <ssi-social-network-icon *ngFor="let accountTypeId of selectedAccountTypeIds"
                                 [accountTypeId]="accountTypeId"
                                 [size]="20"></ssi-social-network-icon>
        <span *ngIf="selectedAccounts.length !== 1">{{selectedAccounts.length}} accounts</span>
        <span *ngIf="selectedAccounts.length === 1">{{selectedAccounts[0].name}}</span>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="optionIcon"
                 let-account="option">
      <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                               [size]="20"></ssi-social-network-icon>
    </ng-template>
  </ssi-dropdown-select-2>
</div>