import {
  Directive,
  HostListener,
  Input,
  ElementRef,
  OnInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnlargeImageModalComponent } from '../../components/enlarge-image-modal/enlarge-image-modal.component';

@Directive({
  selector: '[ssiEnlargeImage]'
})
export class EnlargeImageDirective implements OnInit {
  @Input('ssiEnlargeImage') enlargeImage: string | string[];
  @Input('enlargeImageDisabled') disabled: string;
  @Input('modalClassName') modalClassName: string;
  @Input('galleryWrapperClassName') galleryWrapperClassName: string;
  @Input('altText') altText: string | string[];

  constructor(private modal: NgbModal, private elm: ElementRef) {}

  ngOnInit() {}

  @HostListener('click')
  onClick() {
    if (this.disabled) {
      return;
    }

    const modalRef = this.modal.open(EnlargeImageModalComponent, {
      windowClass: this.modalClassName,
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.imageSrc =
      this.enlargeImage || this.elm.nativeElement.getAttribute('src');
    modalRef.componentInstance.altText = this.altText;
    modalRef.componentInstance.wrapperClass = this.galleryWrapperClassName;
  }
}
