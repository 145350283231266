import { Component, DoCheck, Input, OnChanges, OnInit } from '@angular/core';
import { User, UserModel } from '@ui-resources-angular';

import strings from '../../../../../../library/constants/strings';
import { Agent } from '../../../../../../library/models/live-chat/agent';
import { Person } from '../../../../../../library/models/live-chat/person';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

// ----------------

const isDebug = false;

// ----------------

@Component({
  selector: 'ssi-live-chat-network-state',
  templateUrl: './live-chat-network-state.component.html',
  styles: []
})
export class LiveChatNetworkStateComponent
  implements DoCheck, OnChanges, OnInit {
  @Input() public disabled: boolean = false;
  @Input() public isAvatarAndMenuOnly: boolean = false;
  @Input() public isAvatarOnly: boolean = false;
  @Input() public isIgnoringClicks: boolean = false;
  @Input() public isTranscludeOnly: boolean = false;
  @Input() public user: Person;
  @Input() public hasConversationPushModeEnabled: boolean = false;

  protected _authUser: User;
  protected _options: string[] = [];
  protected _previousState: string;

  showMenu: boolean = false;

  constructor(
    private liveChatService: LiveChatService,
    private userModel: UserModel
  ) {}

  public get canChangeState(): boolean {
    try {
      return (
        !this.isIgnoringClicks && !!this.options.length
        // && (this.isActiveAgent || this.liveChatService.canAdministerAccounts)
      );
    } catch (error) {
      console.error(error);
    }
  }

  public get class(): object {
    try {
      return {
        'active-agent': this.isActiveAgent
      };
    } catch (error) {
      console.error(error);
    }
  }

  public get isAgent(): boolean {
    return !!this.user && this.user instanceof Agent;
  }

  public get isActiveAgent(): boolean {
    try {
      if (!this._authUser || !this.user || !(this.user instanceof Agent)) {
        return;
      }

      return this.user.id === this.liveChatService.agentModel.id;
    } catch (error) {
      console.error(error);
    }
  }

  public get name() {
    try {
      if (!this.user) {
        return;
      }

      return this.isActiveAgent ? strings.you : this.user.displayName;
    } catch (error) {
      console.error(error);
    }
  }

  public get options() {
    return this._options;
  }

  public get quantityOfActiveChats(): number {
    try {
      if (!this.isAgent || !this.user) {
        return;
      }

      const value = this.liveChatService.getQuantityOfConversationsFilteredByAgentId(
        this.user.id
      );

      return value;
    } catch (error) {
      console.error(error);
    }
  }

  public get quantityOfChatsResolvedToday(): number {
    try {
      if (!this.user) {
        if (isDebug) {
          console.log(`No user!`);
        }

        return;
      }

      if (!this.isAgent) {
        if (isDebug) {
          console.log(`Not an agent!`);
        }

        return;
      }

      const agent = this.user as Agent;

      return agent.quantityOfChatsResolvedToday;
    } catch (error) {
      console.error(error);
    }
  }

  public ngDoCheck(): boolean {
    try {
      if (!this.user) {
        return true;
      }

      if (this._previousState !== this.user.networkStateValue) {
        this._previousState = this.user.networkStateValue;
        this.setOptions();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async ngOnChanges(changes) {
    try {
      if (isDebug) {
        console.log(`in ngOnChanges`);
      }

      const hasChangesToUser = !!changes.user && !!this.user;

      if (
        hasChangesToUser &&
        this.canChangeState &&
        this.user instanceof Agent
      ) {
        this.setOptions();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async ngOnInit(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in onInit`);
      }

      this._authUser = await this.userModel.getAuthUser();
      this.setOptions();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public onClick(user: Person) {
    try {
      if (!!this.isIgnoringClicks) {
        return true;
      }

      if (user instanceof Agent) {
        return this.updateActiveFilter(user);
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async setStatus(value: string): Promise<boolean> {
    try {
      if (!this.canChangeState || !this.user) {
        return;
      }

      if (!(this.user instanceof Agent)) {
        return;
      }
      console.log(value);
      await this.liveChatService.setAgentNetworkState(value, true, this.user);
      // this.setOptions();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public setOptions(): boolean {
    try {
      if (isDebug) {
        console.log(`in setOptions`);
      }

      const options: string[] = [];

      if (!this.user) {
        return true;
      }

      if (isDebug) {
        console.log(`network state: ${this.user.networkStateValue}`);
      }

      this.liveChatService.config.states.network.forEach((option: string) => {
        if (option === this.user.networkStateValue) {
          return;
        }

        if (!this.isActiveAgent) {
          // can't set another agent to be online.
          if (option === strings.online) {
            return;
          }

          if (option === strings.offline) {
            // permission required to set another agent to be offline.
            if (!this.liveChatService.canAdministerAccounts) {
              return;
            }
          }

          if (option === strings.away || option === strings.busy) {
            // can't set a user as away if they are offline.
            if (this.user.networkStateValue === strings.offline) {
              return;
            }
          }
        }

        options.push(option);
      });

      if (isDebug) {
        console.dir(options);
      }

      this._options = options;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public toggleDropdown(): boolean {
    try {
      this.setOptions();
      // this.dropdownTriggerElementReference.toggle();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public updateActiveFilter(agent: Agent): boolean {
    try {
      if (!!this.disabled) {
        return;
      }

      this.liveChatService.activeFilter = `${strings.agent}-${agent.id}`;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}
