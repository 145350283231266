import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[ssiTemplateSelector]'
})
export class TemplateSelectorDirective {
  @Input('ssiTemplateSelector') selector = '';

  constructor(public templateRef: TemplateRef<any>) {}
}
