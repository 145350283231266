import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxPostNewNotesComponent } from './outbox-post-new-notes.component';
import { FormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { SanitizedHtmlModule } from '../../../common/pipes/sanitized/sanitized.module';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MentionModule,
    SanitizedHtmlModule,
    OrloComponentsModule,
    TooltipModule
  ],
  declarations: [OutboxPostNewNotesComponent],
  exports: [OutboxPostNewNotesComponent],
  providers: []
})
export class OutboxPostNewNotesModule {}
