import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../store';
import { ApiService } from '../api.service';
import { Tag, TagType } from './outbox-tag.model';

@Injectable({ providedIn: 'root' })
export class OutboxTagsService {
  // TODO: rename to TagsService, since it includes inbox tags as well...
  // https://github.com/orlo/orlo/blob/master/tags/tags-v2.md
  inboxTagsStore = new Store<Tag>(Tag);
  postTagsStore = new Store<Tag>(Tag);
  validationTagsStore = new Store<Tag>(Tag);

  endpoint = `${this.api.url}/company/tagsv2`;

  constructor(protected api: ApiService) {
    // pre-load tags
    this.getInboxTags();
    this.getPostTags();
    this.getValidationTags();
  }

  getInboxTags(opts = { refreshStore: false }): Promise<Tag[]> {
    if (this.inboxTagsStore.valueSet && !opts.refreshStore) {
      return Promise.resolve(this.inboxTagsStore.value);
    }

    const reqOpts: any = {
      params: {
        tag_type: TagType.Inbox,
        include_diabled: false
      }
    };

    return this.api
      .get(this.endpoint, reqOpts)
      .pipe(
        map((tags: any) => {
          this.inboxTagsStore.value = tags;
          return this.inboxTagsStore.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  getPostTags(opts = { refreshStore: false }): Promise<Tag[]> {
    if (this.postTagsStore.valueSet && !opts.refreshStore) {
      return Promise.resolve(this.postTagsStore.value);
    }

    const reqOpts: any = {
      params: {
        tag_type: TagType.Post,
        include_diabled: false
      }
    };

    return this.api
      .get(this.endpoint, reqOpts)
      .pipe(
        map((tags: any) => {
          this.postTagsStore.value = tags;
          return this.postTagsStore.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  getValidationTags(opts = { refreshStore: false }): Promise<Tag[]> {
    if (this.validationTagsStore.valueSet && !opts.refreshStore) {
      return Promise.resolve(this.validationTagsStore.value);
    }

    const reqOpts: any = {
      params: {
        tag_type: TagType.Validation,
        include_diabled: false
      }
    };

    return this.api
      .get(this.endpoint, reqOpts)
      .pipe(
        map((tags: any) => {
          this.validationTagsStore.value = tags;
          return this.validationTagsStore.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  createTag(name: string, type: TagType): Promise<Tag> {
    const payload = {
      tag: name,
      tag_type: type
    };

    return this.api
      .post(this.endpoint, payload)
      .pipe(
        map((response: any) => {
          if (type === TagType.Inbox) {
            this.inboxTagsStore.add(response.tag);
            return this.inboxTagsStore.find(response.tag.id);
          } else if (type === TagType.Post) {
            this.postTagsStore.add(response.tag);
            return this.postTagsStore.find(response.tag.id);
          } else if (type === TagType.Validation) {
            this.validationTagsStore.add(response.tag);
            return this.validationTagsStore.find(response.tag.id);
          } else {
            return response.tag;
          }
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  deleteTag(id: number, type: TagType, purge = false): Promise<any> {
    // Tags can be disabled (will not remove any usages) or purged (will remove usages)
    const reqOpts: any = {
      params: {
        id,
        purge
      }
    };

    return this.api
      .delete(this.endpoint, reqOpts)
      .pipe(
        map((response: any) => {
          if (type === TagType.Inbox) {
            this.inboxTagsStore.remove(id);
          } else if (type === TagType.Post) {
            this.postTagsStore.remove(id);
          } else if (type === TagType.Validation) {
            this.validationTagsStore.remove(id);
          }

          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }
}
