<ng-template
  #monthCellTemplate
  let-day="day"
  let-locale="locale">

  <ng-template #calendarDayTooltipScheduled>
    <div class="tooltip-schedule">
      <p class="tooltip-schedule-body text-center">{{ day.meta?.scheduledCount }} post{{ day.meta?.scheduledCount !== '1' && 's' }} already scheduled, double click to view</p>
    </div>
    <div data-popper-arrow class="tooltip-arrow"></div>
  </ng-template>

  <ng-template #calendarDayTooltipDisabled>
    <div class="tooltip-schedule">
      <p class="tooltip-schedule-title">Unavailable day to post</p>
      <p class="tooltip-schedule-body">This may be due to the same post being less than 48 hours apart or a date is in the past</p>
    </div>
    <div data-popper-arrow class="tooltip-arrow"></div>
  </ng-template>

  <button
    class="cal-cell-top"
    ssiTooltip
    [tooltipTemplate]="day.meta && day.meta.isDisabled ? calendarDayTooltipDisabled : calendarDayTooltipScheduled"
    [tooltipWindowClass]="day.meta?.isScheduled ? 'tooltip-chunky' : 'tooltip-chunky-big'"
    [tooltipDisabled]="!day.meta?.isScheduled && !day.meta?.isDisabled"
    (dblclick)="dayDoubleClicked(day)">
    <span class="cal-day-number">
      {{ day.date | calendarDate:'monthViewDayNumber':locale }}
    </span>
  </button>
</ng-template>

<ng-template #outboxPostListModalTemplate let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">View posts</h4>
  </div>
  <div class="modal-body">
    <ssi-outbox-post-list
      [outboxQuery]="outboxQuery"
      [maxHeight]="350"
      [disableAutoHeight]="true"
      [campaigns]="campaigns"
      [shouldRefreshPublisher]="true"
      (closeModal)="close()"
    ></ssi-outbox-post-list>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default btn-rounded"
      (click)="close()">
      Close
    </button>
  </div>
  <div data-popper-arrow class="tooltip-arrow"></div>
</ng-template>

<button
  class="g-btn g-btn-secondary bg-white jump-to-today-btn"
  (click)="calendarSelectToday()">
  Jump to Today
  </button>

<div class="publisher-schedule-date-picker-outer">
  <div class="publisher-schedule-date-picker-inner">
    <div class="d-flex publisher-schedule-date-picker-title-wrap">
      <div class="publisher-schedule-date-picker-title-nav publisher-schedule-date-picker-title-nav-prev">
        <a
          href="javascript:;"
          mwlCalendarPreviousView
          view="month"
          [(viewDate)]="calendarDate"
          (click)="monthChanged()">
          <i class="ssi ssi-chevron-left"></i>
        </a>
      </div>
      <div class="flex-1 publisher-schedule-date-picker-title">
        <p>{{ calendarDate | calendarDate:'monthViewTitle':'en' }}</p>
      </div>
      <div class="publisher-schedule-date-picker-title-nav publisher-schedule-date-picker-title-nav-next">
        <button
          mwlCalendarNextView
          view="month"
          [(viewDate)]="calendarDate"
          (click)="monthChanged()">
          <i class="ssi ssi-chevron-right"></i>
        </button>
      </div>
    </div>
    <mwl-calendar-month-view
      [viewDate]="calendarDate"
      [weekStartsOn]="1"
      [refresh]="refreshCalendar"
      [cellTemplate]="monthCellTemplate"
      (dayClicked)="dayClicked($event.day)"
      (beforeViewRender)="addCssClasses($event.body)">
    </mwl-calendar-month-view>
    
    <button
      class="g-btn select-schedule-time-btn"
      *ngIf="selectedDate"
      (click)="dateSelected.emit(selectedDate)">
      <span>Select time next</span>
      <i class="ssi ssi-line-arrow"></i>
    </button>
  </div>
</div>