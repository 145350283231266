import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonStringify'
})
export class JsonStringifyPipe implements PipeTransform {
  transform(string: any): string {
    return JSON.stringify(string);
  }
}
