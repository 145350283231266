import './top-post-list.component.scss';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Outbox } from '@ui-resources-angular';
import { isNumeric, mapToIterable } from '../../../../../common/utils';
import { AccountTypeId } from '../../../../../common/enums';

export interface SortType {
  key: 'clicks' | 'comments' | 'likes' | 'shares' | 'reach' | 'impressions';
  label: string;
  scoreLabel: string;
  outboxKey: string;
}

export interface SortTypes {
  clicks: SortType;
  comments: SortType;
  likes: SortType;
  shares: SortType;
  reach: SortType;
  impressions: SortType;
}

export const sortTypes: SortTypes = {
  clicks: {
    key: 'clicks',
    label: 'By Clicks',
    scoreLabel: 'Clicks',
    outboxKey: 'clicks'
  },
  comments: {
    key: 'comments',
    label: 'By Comments',
    scoreLabel: 'Comments',
    outboxKey: 'comment_count'
  },
  likes: {
    key: 'likes',
    label: 'By Likes',
    scoreLabel: 'Likes',
    outboxKey: 'like_count'
  },
  shares: {
    key: 'shares',
    label: 'By Shares',
    scoreLabel: 'Shares',
    outboxKey: 'share_count'
  },
  reach: {
    key: 'reach',
    label: 'By Reach',
    scoreLabel: 'Reach',
    outboxKey: 'reach'
  },
  impressions: {
    key: 'impressions',
    label: 'By Impressions',
    scoreLabel: 'Impressions',
    outboxKey: 'impressions'
  }
};

export const sortTypesIterable: SortType[] = mapToIterable(sortTypes);

@Component({
  selector: 'ssi-top-post-list',
  templateUrl: './top-post-list.component.html',
  styles: []
})
export class TopPostListComponent implements OnInit {
  @Input() posts: Outbox[] = [];
  @Input() selectedSortType: SortType;
  @Output() sortTypeChange = new EventEmitter<SortType>();

  sortTypes: SortTypes = sortTypes;
  sortTypesIterable: SortType[] = sortTypesIterable;
  AccountTypeId = AccountTypeId;

  constructor() {}

  ngOnInit() {}

  onSortTypeChange(sortType: SortType): void {
    this.sortTypeChange.emit(sortType);
  }

  isNumber(value: any): boolean {
    return isNumeric(value);
  }
}
