<div class="modal-popup" [ngClass]="options.customClass">

  <div class="modal-header">
    <button *ngIf="!options.hideClose" type="button" class="close pull-right" (click)="activeModal.dismiss()">
      <i class="fa fa-times"></i>
    </button>
  </div>

  <div class="modal-body" [class.text-danger]="options.isError">
    <h2 class="modal-title">
      <i *ngIf="options.iconClass" [ngClass]="options.iconClass"></i>
      <span *ngIf="!options.isError">{{ options.title }}</span>
      <span translate="SORRY__NAME__SOMETHING_HAS_GONE_WRONG" [translateParams]="{name: userFirstName}" *ngIf="options.isError"></span>
    </h2>
    <p class="modal-meta" [innerHtml]="options.message"></p>
    <div *ngIf="options.httpErrorMessage">
      {{ options.httpErrorMessage }}
    </div>

    <div class="text-center modal-link" *ngIf="options.link">
      <a
        target="_blank"
        [href]="options.link.href"
        [innerHtml]="options.link.label">
      </a>
    </div>

    <form [hidden]="options.type !== PopupType.Prompt">
      <div class="form-group" [class.has-error]="!confirmationText.valid && !confirmationText.pristine">
        <label>{{ options.subTitle }}</label>
        <textarea
          name="inputText"
          rows="3"
          class="form-control"
          [placeholder]="options.subTitle || ''"
          [(ngModel)]="inputText"
          #confirmationText="ngModel"
          [required]="options.type === PopupType.Prompt && options.inputRequired"
          autofocus>
        </textarea>
      </div>
      <div 
        class="form-row extra-margin-top"
        *ngIf="options.approvalTags">
        <div class="form-field">
          <label>Include tags with your response</label>
          <div class="input-wrapper">
            <ssi-dropdown-select-2 name="tagsToInclude"
                                    class="tag-selector"
                                    headButtonStyleVariant="analytics"
                                    size="large"
                                    [multiple]="true"
                                    [filterable]="true"
                                    [toggleAllEnabled]="false"
                                    [filterPlaceholder]="'Search tags to include...'"
                                    [headButtonIcon]="'ssi ssi-addsmall'"
                                    [toggleAllLabel]="'Select all tags'"
                                    [options]="options.approvalTags"
                                    [(ngModel)]="selectedTags"
                                    (ngModelChange)="onSelectedTagsChange($event)">
              <ng-template ssiTemplateSelector="headButtonLabel"
                            let-expanded="expanded">
                <div class="select-tags-placeholder"
                      *ngIf="!selectedTags.length">
                  <span>Search for tags</span>
                </div>
                <div class="select-tag-labels"
                      *ngIf="selectedTags.length">
                  <ng-container *ngFor="let tag of selectedTags">
                    <span (click)="$event.stopPropagation(); removeIncludedTag(tag)">
                      {{tag.label}} <i class="ssi ssi-times"></i>
                    </span>
                  </ng-container>
                </div>
              </ng-template>
            </ssi-dropdown-select-2>
          </div>
        </div>
      </div>
    </form>

    <div class="modal-action-buttons">
      <button
        class="btn btn-inverse"
        (click)="activeModal.dismiss()"
        *ngIf="options.type === PopupType.Confirm || options.type === PopupType.Prompt">
        {{ options.cancelText }}
      </button>
      <button
        class="btn btn-primary"
        (click)="activeModal.close({reason:inputText, tags:selectedTags})"
        [disabled]="confirmationText && !confirmationText.valid">
        {{ options.okText }}
      </button>
    </div>

  </div>

</div>