const DEFAULT_API_ENDPOINT = 'https://app.socialsignin.co.uk';

// web sockets: update in config.json also
// const DEFAULT_SOCKET_ENDPOINT = 'localhost:7010'; //when testing locally
// const DEFAULT_SOCKET_ENDPOINT = '20.90.114.58'; // Azure test instance
// const DEFAULT_SOCKET_ENDPOINT = '40.120.38.235'; // Test instance
// const DEFAULT_SOCKET_ENDPOINT = 'test-websocket.socialsignin.net'; // Test instance
const DEFAULT_SOCKET_ENDPOINT = 'https://ws.socialsignin.net'; // default one - production

export enum EnvironmentType {
  Test = 'test',
  Development = 'development',
  Production = 'production'
}

const appConfig = {
  apiEndpoint: DEFAULT_API_ENDPOINT,
  socketEndpoint: DEFAULT_SOCKET_ENDPOINT
};

if (process.env.SSI_ENV === EnvironmentType.Development) {
  try {
    const devConfig = require('../../../config.json'); // tslint:disable-line
    Object.assign(appConfig, devConfig);
  } catch (e) {}
}

if (typeof process.env.API_ENDPOINT !== 'undefined') {
  appConfig.apiEndpoint = process.env.API_ENDPOINT;
}

if (typeof process.env.SOCKET_ENDPOINT !== 'undefined') {
  appConfig.socketEndpoint = process.env.SOCKET_ENDPOINT;
}

export const API_ENDPOINT: string = appConfig.apiEndpoint;

export const SOCKET_ENDPOINT: string = appConfig.socketEndpoint;

export const APP_VERSION: string = process.env.APP_VERSION;

export const ENVIRONMENT: EnvironmentType = process.env
  .SSI_ENV as EnvironmentType;
