import { mapToIterable } from '../../../../../common/utils';

export interface Query {
  type: string;
  value: string;
  comparison: any;
}

export interface ConditionOption {
  type: string;
  label: string;
  comparisonOperators?: Array<{
    type: string;
    label: string;
    borderColor: string;
    backgroundColor: string;
  }>;
}

export interface ConditionOptions {
  text: ConditionOption;
  user: ConditionOption;
}

export const conditionOptions: ConditionOptions = {
  text: {
    type: 'text',
    label: 'Keyword',
    comparisonOperators: [
      {
        type: 'has',
        label: 'Includes',
        borderColor: '#12ACA4',
        backgroundColor: '#ECF5F5'
      },
      {
        type: 'has-not',
        label: 'Excludes',
        borderColor: '#F88C68',
        backgroundColor: '#FDF6F4'
      }
    ]
  },
  user: {
    type: 'user',
    label: 'Profile',
    comparisonOperators: [
      {
        type: 'involved',
        label: 'Mentioned',
        borderColor: '#12ACA4',
        backgroundColor: '#ECF5F5'
      },
      {
        type: 'not-involved',
        label: 'Not Mentioned',
        borderColor: '#F88C68',
        backgroundColor: '#FDF6F4'
      },
      {
        type: 'from',
        label: 'From',
        borderColor: '#c3c9d8',
        backgroundColor: '#f4f4fa'
      }
    ]
  }
};

export const conditionOptionsIterable: ConditionOption[] = mapToIterable(
  conditionOptions
);
