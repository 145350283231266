<div class="dashboard-widget dashboard-widget-marketing-posts">

  <div class="form-field">
    <div class="label-clear-container">
      <label class="field-title"
             translate="ACCOUNTS_TO_VIEW"></label>
      <!-- todo: add exclamation icon and hook up -->
      <span class="right">
        <button type="button"
                class="last-month-only-btn"
                (click)="toggleFilter()">
          {{lastMonthOnly ? 'Clear 30 day filter' : 'Add 30 day filter'}}
        </button>
        <span class="italic"
              [ssiTooltip]="'REMOVING_THIS_FILTER_COULD_RESULT_IN_LONGER_LOAD_TIMES_DEPENDING_ON_THE_AMOUNT_OF_HISTORIC_CONTENT' | translate"
              tooltipPlacement="top"
              tooltipWindowClass="tooltip-chunky-big"
              [tooltipAppendToBody]="true">i</span>
      </span>
    </div>
    <div class="input-wrapper">
      <ssi-dropdown-select-2 name="accounts"
                             class="accounts-selector"
                             size="lg"
                             [required]="true"
                             [multiple]="true"
                             [filterable]="true"
                             [placeholdersKeyword]="'accounts'"
                             [options]="accounts"
                             [(ngModel)]="selectedAccounts"
                             (ngModelChange)="selectedAccountsChanged($event)">
        <ng-template ssiTemplateSelector="headButtonLabel"
                     let-expanded="expanded">
          <div class="select-accounts-placeholder"
               *ngIf="!selectedAccounts.length">
            <span translate="SELECT_ACCOUNTS"></span>
          </div>
          <div class="select-accounts-label"
               *ngIf="selectedAccounts.length">
            <ssi-social-network-icon *ngFor="let accountTypeId of selectedAccountTypeIds"
                                     [accountTypeId]="accountTypeId"
                                     [size]="20"></ssi-social-network-icon>
            <span *ngIf="selectAllAccountsChecked"
                  translate="ALL_ACCOUNTS_SELECTED"></span>
            <span *ngIf="!selectAllAccountsChecked && selectedAccounts.length !== 1">{{selectedAccounts.length}} accounts</span>
            <span *ngIf="!selectAllAccountsChecked && selectedAccounts.length === 1">{{selectedAccounts[0].name}}</span>
          </div>
        </ng-template>

        <ng-template ssiTemplateSelector="optionIcon"
                     let-account="option">
          <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                   [size]="20"></ssi-social-network-icon>
        </ng-template>
      </ssi-dropdown-select-2>
    </div>
  </div>

  <div class="message-info"
       *ngIf="!outboxQuery?.outboxPosts.length">
    <i class="ssi ssi-no-results-option"></i>
    <h5>Oh dear, no results</h5>
    <p>Try widening your filters?</p>
  </div>

  <ssi-outbox-post-list *ngIf="outboxQuery"
                        [outboxQuery]="outboxQuery"
                        [maxHeight]="containerHeight"
                        [limit]="limit"
                        [queryOverrides]="queryOverrides"></ssi-outbox-post-list>

</div>