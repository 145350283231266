export enum ContentCheckFlag {
  NSFW_PENDING = 'nsfw:pending',
  NSFW_ERRORED = 'nsfw:errored',
  NSFW_NONE = 'nsfw:none',
  NSFW_FLAGGED_ADULT = 'nsfw:flagged:adult',
  NSFW_FLAGGED_RACY = 'nsfw:flagged:racy',
  NSFW_FLAGGED_GORE = 'nsfw:flagged:gore'
}

export const sensitiveContentFlags: ContentCheckFlag[] = [
  ContentCheckFlag.NSFW_FLAGGED_ADULT,
  ContentCheckFlag.NSFW_FLAGGED_GORE,
  ContentCheckFlag.NSFW_FLAGGED_RACY
];
