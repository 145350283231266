import './cl-dropdown-select-e.component.scss';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { orderBy } from 'lodash-es';

import { CampaignsService, Campaign } from '../../../../../common/services/api';

@Component({
  selector: 'ssi-cl-dropdown-select-e',
  templateUrl: './cl-dropdown-select-e.component.html',
  styles: []
})
export class ClDropdownSelectEComponent implements OnInit {
  campaigns: Array<Campaign | any> = [];
  selectedCampaign: Campaign;

  constructor(
    protected datePipe: DatePipe,
    protected changeDetectorRef: ChangeDetectorRef,
    protected campaignsService: CampaignsService
  ) {}

  async ngOnInit() {
    this.campaigns = await this.campaignsService.getAll();
    this.campaigns = orderBy(this.campaigns, [(c) => c.name.toLowerCase()]);

    this.campaigns = this.campaigns
      .filter((c) => !c.parent_id)
      .map((p) => {
        const children = this.campaigns.filter((c) => c.parent_id === p.id);
        return {
          ...p,
          _children: children.length
            ? {
                expanded: false,
                options: children
              }
            : undefined
        };
      });
  }

  selectedOptionsChanged(selectedCampaign: Campaign): void {
    // console.log('this.selectedCampaign: ', this.selectedCampaign);
    this.selectedCampaign = selectedCampaign;
  }

  deselectCampaign(): void {
    this.selectedCampaign = undefined;
  }

  expandCollapseCampaign(parent: Campaign | any): void {
    parent._children.expanded = !parent._children.expanded;
  }

  onChildCampaignToggle(
    campaign: Campaign | any,
    parent: Campaign | any
  ): void {
    // console.log('on child campaign toggle...');
  }

  getTooltipContent = (campaign: Campaign): string => {
    if (!campaign.started_at && !campaign.closed_at) {
      return '';
    }
    return (
      'This campaign runs ' +
      (campaign.started_at
        ? 'from ' + this.datePipe.transform(campaign.started_at, 'MMM d, y')
        : '') +
      (campaign.closed_at
        ? ' until ' + this.datePipe.transform(campaign.closed_at, 'MMM d, y')
        : '')
    );
  };
}
