import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSummaryCardComponent } from './account-summary-card.component';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';

@NgModule({
  declarations: [AccountSummaryCardComponent],
  imports: [CommonModule, BigNumberModule],
  exports: [AccountSummaryCardComponent]
})
export class AccountSummaryCardModule {}
