import './diagnostics.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { LocalStorageService } from 'angular-2-local-storage';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';

import { API, filestackApiKey } from '@ui-resources-angular';
import { LazyLoadService } from '../../../angular/common/services/lazy-load/lazy-load.service';
import { FilestackService } from '../../../angular/common/services/filestack/filestack.service';
import { APP_VERSION } from '../../../angular/environment';
import { KeyValueObject } from '../../../angular/common/util';
import { CompanyService } from '../../../angular/common/services/company/company.service';
import { SocketsService } from '../../../angular/common/services/sockets/sockets.service';
import { userAgent } from '../../common/constants';
import { PopupService } from '../../common/services/popup/popup.service';
import { mapToIterable } from '../../common/utils';

export async function latestBrowsersResolveFn(api: API): Promise<any> {
  return await api.get('diagnostics/latestBrowsers').then(({ data }) => data);
}

export async function socketInstanceResolveFn(
  socket: SocketsService
): Promise<any> {
  return await socket.getSocketInstance();
}

declare global {
  interface Window {
    ga: any;
    pendo: any;
  }
}

interface DiagnosticsFeature {
  label: string;
  loadingTracker?: AsyncTracker;
  supported?: boolean;
  checkSupport(): Promise<any>;
}

@Component({
  selector: 'ssi-diagnostics',
  templateUrl: './diagnostics.component.html',
  styles: []
})
export class DiagnosticsComponent implements OnInit {
  static resolve = [
    {
      token: 'latestBrowsers',
      resolveFn: latestBrowsersResolveFn,
      deps: [API]
    },
    {
      token: 'socketInstance',
      resolveFn: socketInstanceResolveFn,
      deps: [SocketsService]
    }
  ];

  @Input() latestBrowsers: KeyValueObject<string>;
  @Input() socketInstance;

  features: KeyValueObject<DiagnosticsFeature>;
  featuresIterable: DiagnosticsFeature[];
  currentBrowser;
  socketsConnected: boolean;
  currentOS;
  screen;
  shareUrl: string;
  currentBrowserOutdated: boolean;
  appVersion = APP_VERSION;

  constructor(
    private state: StateService,
    private lazyLoad: LazyLoadService,
    private localStorageService: LocalStorageService,
    private promiseTracker: AsyncTrackerFactory,
    private api: API,
    private popup: PopupService,
    private filestack: FilestackService,
    private company: CompanyService,
    private socket: SocketsService
  ) {}

  async ngOnInit() {
    const {
      state,
      lazyLoad,
      localStorageService,
      promiseTracker,
      api,
      filestack,
      latestBrowsers
    } = this;

    const enableCname = await this.company
      .hasFeatureAccess('FILESTACK_CNAME')
      .then((result) => !!result);
    const fileStackParams = enableCname
      ? {
          apikey: filestackApiKey,
          clientOptions: { cname: 'uploads.orlo.app' }
        }
      : {
          apikey: filestackApiKey,
          clientOptions: {}
        };

    this.features = {
      localStorage: {
        label: 'Local storage',
        checkSupport() {
          try {
            localStorageService.set('test', true);
            localStorageService.remove('test');
            return Promise.resolve();
          } catch (e) {
            return Promise.reject();
          }
        }
      },
      filestack: {
        label: 'Filestack',
        checkSupport() {
          const url =
            'https://www.orlo.app/assets/v1/img/socialsignin-logo.png';
          console.error('diagnostics.ctrl: upload (filestack): Size'); // only for tracksjs
          const promises = [
            filestack.getClient().then((client) => client.storeURL(url)),
            api.get('https://cloud.filestackapi.com/prefetch', {
              params: fileStackParams,
              skipAuthorization: true,
              skipBasePath: true
            }),
            lazyLoad.loadFileStackCss()
          ];
          return Promise.all(promises);
        }
      },
      filestackConversions: {
        label: 'Filestack image conversions',
        checkSupport() {
          const imageSrc =
            'https://cdn.uploads.orlo.app/AoX6QFadvSNSgoYeN6D61z/rotate=deg:exif/output=compress:true,quality:80/' +
            'https://cdn.filestackcontent.com/hse4mPfWSoiQEuqXfChl';
          return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = resolve;
            image.onerror = reject;
            image.src = imageSrc;
          });
        }
      },
      lazyLoading: {
        label: 'Lazy loading',
        checkSupport() {
          return lazyLoad.loadFileStackCss();
        }
      },
      apiDeletes: {
        label: 'API deletes',
        checkSupport() {
          return api.del('diagnostics/cache', { autoError: false });
        }
      },
      uncachedPostRequests: {
        label: 'No API caching',
        checkSupport() {
          function getServerTime() {
            return api
              .post('diagnostics/cache')
              .then(({ data: { time } }) => time);
          }

          return getServerTime()
            .then((time1) => {
              return Promise.all([Promise.resolve(time1), getServerTime()]);
            })
            .then(([time1, time2]) => {
              if (time1 === time2) {
                return Promise.reject();
              }
            });
        }
      },
      googleAnalytics: {
        label: 'Google Analytics',
        checkSupport() {
          return new Promise((resolve, reject) => {
            setTimeout(window.ga && window.ga.loaded ? resolve : reject, 1500);
          });
        }
      },
      pendo: {
        label: 'Pendo',
        checkSupport() {
          return new Promise((resolve, reject) => {
            setTimeout(
              window.pendo && window.pendo['VERSION'] ? resolve : reject,
              1500
            );
          });
        }
      }
    };

    this.featuresIterable = mapToIterable(this.features);

    if (state.params.restore) {
      try {
        const params: KeyValueObject<any> = JSON.parse(
          window.atob(state.params.restore)
        );
        Object.entries(params).forEach(([param, value]) => {
          if (this.features[param]) {
            this.features[param].supported = value;
          }
        });
        this.currentBrowser = params.browser;
        this.currentOS = params.os;
        this.screen = params.screen;
        this.socketsConnected = params.websockets;
        this.appVersion = params.version;
      } catch (e) {
        this.popup.alert({
          isError: true,
          message:
            "The diagnostics url is invalid, the client probably didn't copy it correctly"
        });
        return;
      }
    } else {
      const updateUrl = () => {
        // console.log('wsd this.socketInstance:', this.socketInstance);
        const params = {
          websockets: this.socketInstance.connected,
          browser: this.currentBrowser,
          os: this.currentOS,
          screen: {
            width: this.screen.width,
            height: this.screen.height
          },
          version: this.appVersion
        };
        for (const feature in this.features) {
          if (typeof this.features[feature].supported !== 'undefined') {
            params[feature] = this.features[feature].supported;
          }
        }
        const encodedParams = encodeURIComponent(
          window.btoa(JSON.stringify(params))
        );
        this.shareUrl = `${window.location.href}?restore=${encodedParams}`;
      };

      Object.values(this.features).forEach((feature) => {
        feature.loadingTracker = promiseTracker.create();
        const promise = feature
          .checkSupport()
          .then(() => {
            feature.supported = true;
          })
          .catch(() => {
            feature.supported = false;
          })
          .finally(updateUrl);
        feature.loadingTracker.add(promise);
      });

      this.screen = window.screen;
      this.socketsConnected = this.socket.getSocketInstance().connected;

      const browsers: Array<{
        apiKey: string;
        icon: string;
        userAgentKey: string;
        latestVersion?: string;
      }> = [
        {
          apiKey: 'chrome',
          icon: 'fa-chrome',
          userAgentKey: 'Chrome'
        },
        {
          apiKey: 'firefox',
          icon: 'fa-firefox',
          userAgentKey: 'Firefox'
        },
        {
          apiKey: 'opera',
          icon: 'fa-opera',
          userAgentKey: 'Opera'
        },
        {
          apiKey: 'safari',
          icon: 'fa-safari',
          userAgentKey: 'Safari'
        },
        {
          apiKey: 'ie',
          icon: 'fa-internet-explorer',
          userAgentKey: 'IE'
        },
        {
          apiKey: 'edge',
          icon: 'fa-edge',
          userAgentKey: 'Edge'
        }
      ];

      Object.entries(latestBrowsers).forEach(([browserName, browser]) => {
        const config = browsers.find(
          (iBrowser) => iBrowser.apiKey === browserName
        );
        if (config) {
          config.latestVersion = browser;
        }
      });

      this.currentOS = userAgent.getOS();

      this.currentBrowser = userAgent.getBrowser();
      const browserConfig = browsers.find(
        (browser) => browser.userAgentKey === this.currentBrowser.name
      );

      if (browserConfig) {
        Object.assign(this.currentBrowser, browserConfig);
      } else {
        this.currentBrowser.unknown = true;
      }
    }

    this.currentBrowserOutdated =
      +this.currentBrowser.major < +this.currentBrowser.latestVersion;
  }
}
