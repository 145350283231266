import { User as UserInterface } from '../../interfaces/live-chat/user';
import { User } from './user';

export class Robot extends User implements UserInterface {
  protected _data: UserInterface;

  public get displayName() {
    return `Automated reply`;
  }
}
