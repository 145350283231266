<div class="modal-header">
  <h3 class="modal-title"
      translate="EDIT_FILE"></h3>
  <h5 class="modal-title">Suggested post text and Media Guidance is an optional tool to help guide your colleagues where
    required. You can add one without the other too!</h5>
</div>

<div class="modal-body">
  <form #editFileForm="ngForm">
    <div class="form-group"
         [ngClass]="{'has-error': editFileForm.controls.filename?.invalid}">
      <label class="control-label"
             translate="FILENAME"></label>
      <input type="text"
             name="filename"
             class="form-control"
             required
             [(ngModel)]="resolve.file.name">
    </div>

    <div class="form-group">
      <label class="control-label">
        <span translate="SUGGESTED_POST_TEXT"></span>
        <i class="fa fa-info-circle"
           [ssiTooltip]="'WHEN_SELECTING_THIS_FILE_IN_THE_PUBLISHER_PREPOPULATE_THE_POST_TEXT_WITH_THIS_MESSAGE' | translate"
           tooltipWindowClass="tooltip-chunky"
           [tooltipAppendToBody]="true">
        </i>
      </label>
      <textarea class="form-control"
                name="metadata"
                rows="4"
                placeholder="Type the post copy you require with this media..."
                [(ngModel)]="resolve.file.metadata.outbox_post.text"></textarea>
    </div>

    <div class="form-group">
      <label class="control-label">
        <span>Media Guidance</span>
        <i class="fa fa-info-circle"
           [ssiTooltip]="'POP_A_NOTE_HERE_IF_YOU_NEED_TO_LET_YOUR_COLLEAGUES_KNOW_WHAT_THEY_SHOULD_BE_USING_THIS_MEDIA_FOR' | translate"
           tooltipWindowClass="tooltip-chunky"
           [tooltipAppendToBody]="true">
        </i>
      </label>
      <textarea class="form-control"
                name="note"
                maxlength="200"
                rows="4"
                placeholder="You can also just send a note with this file here..."
                [(ngModel)]="resolve.file.metadata.outbox_post.note">
    </textarea>
      <span class="char-count"
            [ngClass]="{'invalid': resolve.file.metadata.outbox_post.note?.length === 200}">{{200 - resolve.file.metadata.outbox_post.note?.length}}
      </span>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-default"
          type="button"
          (click)="activeModal.dismiss()"
          translate="CANCEL"></button>

  <button class="btn btn-primary"
          type="button"
          (click)="activeModal.close(resolve.file)"
          [disabled]="editFileForm?.invalid"
          translate="SAVE"></button>
</div>