<div class="marketing-add-campaign" (ssiOffClick)="toggleAddCampaignDropdown.emit()">
<div class="form-group">
    <input placeholder="Type campaign name" [(ngModel)]="newCampaign.name"/>
</div>
<div class="form-group">
    <div class="select-wrapper">
    <select [(ngModel)]="newCampaign.parent_id">
        <option selected="selected" value="null">No parent selected</option>
        <option *ngFor="let campaign of campaigns" [value]="campaign.id">{{campaign.name}}</option>
    </select>
    </div>
</div>
    <div class="form-group campaign-calendar-control" #startDate>
    <input
        class="form-control"
        type="text"
        mwlFlatpickr
        [(ngModel)]="newCampaign.started_at"
        [altInput]="true"
        [convertModelValue]="true"
        [enableTime]="true"
        dateFormat="Y-m-d H:i"
        [maxDate]="newCampaign.closed_at ? (newCampaign.closed_at | addSeconds:-60) : null"
        [time24hr]="true"
        [appendTo]="startDateElement"
        altFormat="M J Y, H:i"
        placeholder="Start"
        required
        name="start">
    </div>
    <div class="form-group campaign-calendar-control" #EndDate>
        <input
        class="form-control"
        type="text"
        mwlFlatpickr
        [(ngModel)]="newCampaign.closed_at"
        [altInput]="true"
        [convertModelValue]="true"
        [enableTime]="true"
        dateFormat="Y-m-d H:i"
        [minDate]="(newCampaign.started_at || now) | addSeconds:60"
        [time24hr]="true"
        [appendTo]="EndDateElement"
        altFormat="M J Y, H:i"
        placeholder="End"
        name="end">
    </div>
<div>
    <button class="btn btn-primary btn-rounded btn-padding-sides" [disabled]="formValid()" (click)="addCampaign()">Add</button>
    <button class="btn btn-primary btn-rounded btn-padding-sides" (click)="toggleAddCampaignDropdown.emit()">Cancel</button>
</div>
</div>