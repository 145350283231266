<div class="insights-widget insights-widget-swot">
  <div class="insights-swot-wrapper">
    <ng-container *ngIf="loading">
      <div class="insights-swot-loading">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABQCAMAAACEXWP5AAAA51BMVEVHcEwQveYUuuMUuuMAqtUUu+MUuuMTuuITuuIUuuQWueASvOQSueUSvucUueIUuuMUuuIUuuMUu+QUuuQTuuQUueMTuuMVuuMTuOMUuuMUuuQSyO0Av/8Uu+QUu+EUuuIUuOQAqv8Vv+oPtOEUxOsAzMwXudwUuuIUu+MUuuIkttsUuOAUuuIazOYUu+QWu+MYttsUvOMTuuIVv98as8wUu+MUueMVuOMgv98SudwUueETueYVvOMQt98UsdgXvOESueUXtOEYt98WueUUueUQv98q1f8Yt+cUuuQStuEVuuQUuuMTuOVRJk+QAAAATXRSTlMAH6LzBtj/a5+iOjkdK7vmc7yPc5CZXLl3v4IOBEtwckEDDBENBRaYs4IHGRoKcVMVqoYYCkCASAgdM1BIIA0iOiIgOk0QBiBoKjA/RDbrEjcAAAGSSURBVHgB7dQDgh1BFIXhM7Ydjj3Ptr3/9aTrqXmSquTG/S/gKxe0WlhcWlpcgEjLK6tWK8uQaG113BokWp9g65BoY4Jt/FVYiG1ubUthO7t7e3v7QtjBnupQBNvcm3QohU00AWx7j2kc4x0x7XswHNsawQS0kwl2AgntdIKdQkQ7U9YZIKOdX1ycAz+iHYFnrkFS25HUADntHWQ6UtZ7SLUDwcLCPnz89NnV2iX0u7q+mXZ7B9w/PPp6gm7PL692b4g8BhSFZrFXRy+IB2EJ3UW+uhLFRJcpegCyVyMszLAkvloK+qUzmUw2B9JVvlAovECzYkZVAsmyrMq61iSQFEU1YmXIvlUKXKMWnxnXqJUG6ZVrzCrCrlqr1WHXsDVzq9myqhlqxNpujds20oiFnQm2A6KZWGhOsCYMtDSxbIxor/DVZpaNMa3jw7q2xTCmVQg2sQjGtBR89WyLYETrw19SaWmYYHhVVgoI4rptmGHoVCYUj2O8EPtLsMEEG0Ciqv05CjRU1hAy3Q1Go8EdvtUXmTat1foNVo0AAAAASUVORK5CYII=" alt="loading spinner">
        <h2>Hang tight</h2>
        <p>I'm searching for themes
          <ng-container *ngIf="(loading | typeof) === 'string'"> across 27,000 results, phew!</ng-container>
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <ssi-highchart [config]="swotConfig" [(updateFlag)]="updateFlag"></ssi-highchart>
    </ng-container>
  </div>
</div>