import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import { Subject } from 'rxjs/Subject';

export interface Note {
  id: string;
  content: string;
  subject: string;
  subject_id: string;
  created_at: string;
  created_by: string;
  author?: any;
}

@Injectable()
export class NotesModelService {
  public $notes = new Subject();

  constructor(private api: API) {}

  set updateNotes(notes) {
    this.$notes.next(notes);
  }

  public async deleteNote(id, subjectId, subject) {
    return await this.api.post<{ data: { success: true } }>(
      'note/index',
      {},
      {
        params: {
          _method: 'DELETE',
          id,
          subject,
          subject_id: subjectId
        }
      }
    );
  }

  public async saveNote(content, subjectId, subject) {
    return await this.api.post<{ data: { success: true } }>(
      'note/index',
      {},
      {
        params: {
          content,
          subject,
          subject_id: subjectId
        }
      }
    );
  }

  public makeNote(string) {
    this.$notes.next(string);
  }

  public async fetchNotes(subjectId, subject) {
    const {
      data: { data }
    } = await this.api.get<{ data: { data: Note[] } }>(`note/index`, {
      params: {
        subject,
        subject_id: subjectId
      }
    });
    return data;
  }
}
