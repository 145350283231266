import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { WorkflowsComponent } from './workflows.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsModule } from '../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { EditWorkflowsModule } from './edit-workflows/edit-workflows.module';

@NgModule({
  imports: [
    CommonModule,
    EditWorkflowsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.workflows',
          url: '/workflows',
          component: WorkflowsComponent,
          resolve: WorkflowsComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    AlertsModule,
    TranslateModule,
    NgbDropdownModule
  ],
  declarations: [WorkflowsComponent],
  entryComponents: [WorkflowsComponent],
  providers: [WorkflowsComponent]
})
export class WorkflowsModule {}
