import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { EditWorkflowsComponent } from './edit-workflows.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { UniqueModuleModule } from '../../../../common/pipes/unique/unique.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.editWorkflow',
          url: '/workflows/:workflowId/edit',
          component: EditWorkflowsComponent,
          resolve: EditWorkflowsComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    AlertsModule,
    TranslateModule,
    UniqueModuleModule,
    TooltipModule
  ],
  declarations: [EditWorkflowsComponent],
  entryComponents: [EditWorkflowsComponent],
  providers: [EditWorkflowsComponent]
})
export class EditWorkflowsModule {}
