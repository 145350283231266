<div class="modal-header">
  <button class="btn btn-default pull-right"
          (click)="modalInstance.close()"
          translate="CLOSE"></button>
  <h3 class="modal-title"
      translate="VIEW_NOTES"></h3>
</div>
<div class="modal-body notes-component-wrapper">
  <div class="notes-list-wrapper">
    <div class="notes-empty"
         *ngIf="post.notes?.length === 0">
      <i class="ssi ssi-feather-microcopy notes-empty-icon"></i>
      <p>Need to keep your team members in the loop about anything? Drop them a note then!</p>
    </div>
    <ssi-notes *ngIf="post.notes?.length > 0"
               [notes]="post.notes"
               (onDelete)="deleteNote($event)"></ssi-notes>
  </div>
  <form class="notes-input-wrapper">
    <div class="notes-input form-group">
      <div class="">
        <ssi-notes-textarea [(ngModel)]="newNoteText"
                            name="newNote"
                            [borderless]="true"
                            [tagList]="tagList"
                            [placeholder]="'TYPE__TO_BRING_UP_YOUR_TEAM_MEMBERS' | translate"></ssi-notes-textarea>
      </div>
      <div class="notes-input-footer">
        <button type="submit"
                class="btn btn-primary submit-note"
                [disabled]="!newNoteText"
                (click)="post.addNote(newNoteText); newNoteText = ''"
                translate="ADD_NOTE"></button>
      </div>
    </div>
  </form>
</div>