
    <div
      ssiFileUploader
      [accept]="accept"
      [maxFiles]="maxFiles"
      [disabled]="disabled"
      [fromSources]="fromSources"
      [restrictions]="restrictions"
      (uploadStart)="uploadStart.emit($event)"
      (uploadFinally)="uploadFinally.emit($event)"
      (uploadSuccess)="uploadSuccess.emit($event)"
      (uploadError)="uploadError.emit($event)"
    >
      <ng-content></ng-content>
    </div>
  