import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ClMenuComponent } from './cl-menu.component';

import { MenuModule } from '../../../../common/components/menu/menu.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MenuModule,
    SocialNetworkIconModule
  ],
  exports: [ClMenuComponent],
  declarations: [ClMenuComponent]
})
export class ClMenuModule {}
