import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnDomEventDirective } from './on-dom-event.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [OnDomEventDirective],
  exports: [OnDomEventDirective]
})
export class OnDomEventModule {}
