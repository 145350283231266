import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Form, Validators } from '@angular/forms';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-export-conversation',
  templateUrl: './export-conversation.component.html'
})
export class ExportConversationComponent implements OnInit {
  @Input() close;

  form;
  showTamperCode: boolean;
  exportSuccess: boolean;
  showPassword = false;
  showCodeConfirmation = false;

  constructor(
    private api: API,
    private state: StateService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      tamper: new FormControl('', Validators.required),
      tamperCode: new FormControl('')
    });

    const tamperCodeControl = this.form.get('tamperCode');
    const tamperControl = this.form.get('tamper');

    tamperControl.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.showTamperCode = true;
        tamperCodeControl.setValidators([
          Validators.minLength(5),
          Validators.required
        ]);
        tamperCodeControl.updateValueAndValidity();
      } else {
        this.showTamperCode = false;
        tamperCodeControl.setValidators(null);
        tamperCodeControl.updateValueAndValidity();
      }
    });
  }

  exportMessages() {
    const params = { conversation_id: this.state.params.conversation };
    if (this.form.value.tamperCode.length >= 5) {
      params['password'] = this.form.value.tamperCode;
      this.showCodeConfirmation = true;
    }

    this.api
      .post('conversation/conversationExport', params)
      .then(({ status }) => {
        this.exportSuccess = status === 200 ? true : false;
      });
  }

  togglePasswordDisplay() {
    this.showPassword = !this.showPassword;
  }
}
