import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageSocialPolicyComponent } from './manage-social-policy.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TextEditorModule } from './text-editor/text-editor.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    AlertsModule,
    TextEditorModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageSocialPolicy',
          url: '/manage/social-policy',
          component: ManageSocialPolicyComponent,
          data: {
            permissions: {
              company: 'administer_company'
            }
          },
          resolve: ManageSocialPolicyComponent.resolve
        }
      ]
    })
  ],
  declarations: [ManageSocialPolicyComponent]
})
export class ManageSocialPolicyModule {}
