import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';

import { AccountService } from './account.service';
import { AccountComponent } from './account.component';
import { AccountBuildComponent } from './build/account-build.component';
import { AccountReportComponent } from './report/account-report.component';

import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { Checkbox2Module } from '../../../../common/components/checkbox-2/checkbox-2.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { ToggleSwitchModule } from '../../../../common/components/toggle-switch/toggle-switch.module';
import { TreetableModule } from '../../../../common/components/treetable';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { ReportSidebarModule } from '../common/report-sidebar/report-sidebar.module';
import { ReportHeaderModule } from '../common/report-header/report-header.module';
import { StatChangeModule } from '../common/stat-change/stat-change.module';
import { FactOverviewModule } from '../common/fact-overview/fact-overview.module';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';
import { AccountPillModule } from '../common/account-pill/account-pill.module';
import { ReportBuildFromModule } from '../common/report-build-from/report-build-from.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.account',
          url: '/account',
          abstract: true,
          component: AccountComponent
        },
        {
          name: 'auth.analytics.account.build',
          url:
            '?accountIds&startDate&endDate&previousStartDate&previousEndDate&dateRange&compareTo',
          component: AccountBuildComponent
        },
        {
          name: 'auth.analytics.account.report',
          url:
            '/report?accountIds&startDate&endDate&previousStartDate&previousEndDate&dateRange&compareTo',
          component: AccountReportComponent
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    OrloComponentsModule,
    TranslateModule,
    FlatpickrModule,
    DropdownSelect2Module,
    Checkbox2Module,
    TooltipModule,
    OffClickModule,
    SocialNetworkIconModule,
    ToggleSwitchModule,
    TreetableModule,
    BigNumberModule,
    ReportSidebarModule,
    ReportHeaderModule,
    StatChangeModule,
    FactOverviewModule,
    HighchartModule,
    AccountPillModule,
    ReportBuildFromModule
  ],
  declarations: [
    AccountComponent,
    AccountBuildComponent,
    AccountReportComponent
  ],
  entryComponents: [AccountComponent],
  providers: [AccountService]
})
export class AccountModule {}
