import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnInit,
  ElementRef
} from '@angular/core';
import { format } from 'date-fns';
import { Conversation } from '../../../../../../library/models/live-chat/conversation';
import { CompanyService } from '../../../../common/services/company/company.service';

const cssClassForExpandedSidebar = `is-fully-visible`;

@Component({
  selector: 'ssi-live-chat-conversation-sidebar',
  templateUrl: './live-chat-conversation-sidebar.component.html',
  styles: []
})
export class LiveChatConversationSidebarComponent implements OnChanges, OnInit {
  @Input() public isSidebarVisible: boolean;
  @Input() public conversation: Conversation;
  @Input() public isVisitorBlocked: boolean;
  @Input() public visitorIpLocation;
  @Output() public isSidebarVisibleChange = new EventEmitter<boolean>();
  @Output() public onBlockVisitor = new EventEmitter<string>();
  @Output() public onUnblockVisitor = new EventEmitter<string>();

  private _nativeElement: HTMLElement;
  private _isChatDetailsVisible = false;
  private _isVerifiedDetailsVisible = false;

  public latLngCenter: number[] = [];
  public latLngMarker = [];
  public visitorInformation = [];
  public blockingDisabled = false;

  constructor(private element: ElementRef, private company: CompanyService) {}

  // @todo perhaps move these getters to the visitor, but not until sorted Angularfire console data warning.
  public get firstSeen() {
    const date = this.conversation.visitor.createdAt;

    if (!date) {
      return;
    }

    return this.formatDate(date);
  }

  public get isChatDetailsVisible() {
    return this._isChatDetailsVisible;
  }

  public get isVerifiedDetailsVisible() {
    return this._isVerifiedDetailsVisible;
  }

  public get lastSeen() {
    const date = this.conversation.visitor.networkState.updatedAt;

    if (!date) {
      return;
    }

    return this.formatDate(date);
  }

  public get nativeElement() {
    return this._nativeElement;
  }

  public hideSidebar() {
    this.nativeElement.classList.remove(cssClassForExpandedSidebar);
  }

  public initialise() {
    this._isVerifiedDetailsVisible =
      !!this.conversation.visitor && !!this.conversation.visitor.isLoggedIn;
    this._isChatDetailsVisible = !this._isVerifiedDetailsVisible;

    this.visitorInformation = Object.keys(
      this.conversation.visitor.data.visitorFields
    ).map((slot) => this.conversation.visitor.data.visitorFields[slot]);
  }

  public ngOnChanges(changes) {
    if (!!changes.conversation && !!changes.conversation.currentValue) {
      // treat as a reset

      this.initialise();
      this.visitorIpLocation = null;
    }

    if (this.visitorIpLocation) {
      this.latLngCenter = [];
      this.latLngMarker = [];
      this.latLngCenter.push(
        this.visitorIpLocation.location.latitude,
        this.visitorIpLocation.location.longitude
      );
      this.latLngMarker.push({
        lat: this.visitorIpLocation.location.latitude,
        lng: this.visitorIpLocation.location.longitude
      });
    }
  }

  public ngOnInit() {
    this._nativeElement = this.element.nativeElement as HTMLElement;

    this.nativeElement.onmouseenter = () => this.showSidebar();
    this.nativeElement.onmouseleave = () => this.hideSidebar();

    this.company
      .hasFeatureAccess('LIVECHAT_HIDE_IP_BLOCKING')
      .then((blockingDisabled) => (this.blockingDisabled = !!blockingDisabled));
  }

  public showSidebar() {
    this.nativeElement.classList.add(cssClassForExpandedSidebar);
  }

  public toggleSidebar() {
    this.isSidebarVisibleChange.emit(false);
  }

  public toggleVisibilityOfChatDetails() {
    if (!!this.isVerifiedDetailsVisible) {
      this._isVerifiedDetailsVisible = false;
    }

    this._isChatDetailsVisible = !this._isChatDetailsVisible;
  }

  public toggleVisibilityOfVerifiedDetails() {
    if (!!this.isChatDetailsVisible) {
      this._isChatDetailsVisible = false;
    }

    this._isVerifiedDetailsVisible = !this._isVerifiedDetailsVisible;
  }

  private formatDate(date) {
    return format(date, `Do MMM 'YY, h:mm a`);
  }

  blockVisitor() {
    this.onBlockVisitor.emit(this.conversation.visitor.data.clientIp);
  }

  unblockVisitor() {
    this.onUnblockVisitor.emit(this.conversation.visitor.data.clientIp);
  }
}
