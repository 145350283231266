import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStore } from 'js-data';
import {
  profileModelFactory,
  ProfileModel
} from '../../modules/profile/services/profileModel';

@NgModule()
export class ProfileModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ProfileModule,
      providers: [
        {
          provide: ProfileModel,
          useFactory: profileModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
