import {
  Directive,
  Input,
  OnDestroy,
  Renderer2,
  AfterViewChecked,
  ElementRef
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

// temporary hack until https://github.com/ng-bootstrap/ng-bootstrap/pull/1602 is merged

@Directive({
  selector: '[ngbPopover]' // tslint:disable-line
})
export class PopoverDirective implements OnDestroy, AfterViewChecked {
  @Input() popoverClass: string;

  private shownSubscription: Subscription;

  private hiddenSubscription: Subscription;

  private popoverWindowElm: ElementRef;

  constructor(private renderer: Renderer2, popover: NgbPopover) {
    this.shownSubscription = popover.shown.subscribe(() => {
      this.popoverWindowElm = popover['_windowRef'].location;
    });
    this.hiddenSubscription = popover.hidden.subscribe(() => {
      this.popoverWindowElm = null;
    });
  }

  ngAfterViewChecked(): void {
    if (this.popoverWindowElm) {
      this.renderer.addClass(
        this.popoverWindowElm.nativeElement,
        this.popoverClass
      );
    }
  }

  ngOnDestroy() {
    if (!!this.shownSubscription) {
      this.shownSubscription.unsubscribe();
    }

    if (!!this.hiddenSubscription) {
      this.hiddenSubscription.unsubscribe();
    }
  }
}
