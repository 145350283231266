import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmPersonNotesComponent } from './crm-person-notes.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { MentionModule } from 'angular-mentions';
import { SanitizedHtmlModule } from '../../pipes/sanitized/sanitized.module';
import { NotesModule } from '../notes/notes.module';
import { NotesTextareaModule } from '../notes-textarea/notes-textarea.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    FormsModule,
    TranslateModule,
    NotesModule,
    NotesTextareaModule,
    MentionModule,
    SanitizedHtmlModule
  ],
  declarations: [CrmPersonNotesComponent],
  exports: [CrmPersonNotesComponent],
  entryComponents: [CrmPersonNotesComponent]
})
export class CrmPersonNotesModule {}
