import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  TemplateRef,
  Renderer2
} from '@angular/core';
import {
  FilestackService,
  FilestackAccess
} from '../../services/filestack/filestack.service';

/** https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types */
export enum FileMimeTypes {
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Ppt = 'application/vnd.ms-powerpoint',
  Pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}

export const base64ToArrayBuffer = (base64) => {
  /* global atob */
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

@Directive({
  selector: '[ssiFileDownload]'
})
export class FileDownloadDirective {
  @Input() data: any;
  @Input() filename: string;
  @Input() mimetype: FileMimeTypes;
  @Input() base64decode: boolean;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private filestack: FilestackService
  ) {}

  @HostListener('click', ['$event'])
  onClick(e: Event): void {
    const spinner: HTMLElement = this.renderer.createElement('i');
    spinner.className = 'fa fa-spin fa-spinner';
    this.elRef.nativeElement.setAttribute('disabled', 'disabled');
    this.elRef.nativeElement.append(spinner);

    let contents = this.data;
    if (this.base64decode) {
      contents = base64ToArrayBuffer(contents);
    }

    const blob = new window.Blob([contents], {
      type: this.mimetype ? `${this.mimetype};charset=utf-8` : ''
    });

    this.filestack
      .getClient()
      .then((client) => {
        return client.upload(
          blob,
          {},
          {
            filename: this.filename,
            access: FilestackAccess.Public
          }
        );
      })
      .then((file) => {
        console.error('file-download.directive: upload (filestack): Size'); // only for tracksjs
        window.open(file.url, '_blank');
      })
      .finally(() => {
        this.renderer.removeChild(this.elRef, spinner);
        this.elRef.nativeElement.removeAttribute('disabled');
      })
      .catch((err) => console.error('Download error:', err));
  }
}
