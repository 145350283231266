<div class="header">
  <h1>Bulk Editor</h1>
</div>
<div class="content grey">
    <div class="flex-grid">
      <div class="col">
        <div class="bulk-uploading" *ngIf="uploading">
          <div class="bulk-edit-buttons">
            <orlo-button *ngIf="selectedPosts.length > promiseQueue.length" [text]="'Cancel Updates'" [state]="'secondary'" [type]="'button'" [textColor]="'#14bae3'" [bgColor]="'#e6ebf4'" (btnClick)="cancelUpdate()"></orlo-button>
          </div>
          <div *ngIf="progressCount !== selectedPosts.length">
            <h2>We’re processing your changes</h2>
            <p>This may take a few minutes depending on how many posts you’ve edited. Any posts that fail to update will be available to edit once all the changes have been processed.</p>
          </div>
          <div *ngIf="progressCount === selectedPosts.length && successCount === selectedPosts.length">
            <h2>Success! Updates complete</h2>
            <p>Your posts have successfully updated</p>
          </div>
          <div *ngIf="progressCount === selectedPosts.length && failCount > 0">
            <h2>Some errors in your updates</h2>
            <p>Some of your posts have not updated because they contain errors. Click the button below to fix them and try again.</p>
          </div>
          <div class="flex-grid upload-progress-bars">
            <div class="col full">
              <label class="upload-progress-label">Update Progress</label>
              <span>{{calculatePercentageComplete()}}% completed...</span>
              <ngb-progressbar type="primary" [value]="progressCount" [max]="selectedPosts.length"></ngb-progressbar>
            </div>
            <div class="col one-half padding-right">
              <label class="upload-progress-label">Successful Updates</label>
              <span>{{successCount}} {{successCount !== 1 ? ' posts have' : ' post has'}} been updated...</span>
              <ngb-progressbar type="success" [value]="successCount" [max]="selectedPosts.length"></ngb-progressbar>
            </div>
            <div class="col one-half padding-left">
              <label class="upload-progress-label">Failed Updates</label>
              <span>{{failCount}} {{failCount !== 1 ? ' posts have' : ' post has'}} failed...</span>
              <ngb-progressbar type="danger" [value]="failCount" [max]="selectedPosts.length"></ngb-progressbar>
            </div>
          </div>
          <div class="align-right">
            <orlo-button *ngIf="postErrors.length === 0" [text]="'Okay thanks!'" [state]="'primary'" [type]="'button'" (btnClick)="returnToOutbox()"></orlo-button>
            <orlo-button *ngIf="postErrors.length > 0" [text]="'Correct errors'" [state]="'primary'" [type]="'button'" (btnClick)="returnToEditing()"></orlo-button>
          </div>
        </div>
        <div class="bulk-edit" *ngIf="!uploading">
          <div class="bulk-edit-buttons">
            <orlo-button [text]="'Cancel'" [state]="'primary'" [type]="'button'" [bgColor]="'#F78F6D'" (btnClick)="returnToOutbox()"></orlo-button>
            <orlo-button [disabled]="selectedPosts.length === 0" [text]="'Save changes'" [state]="'primary'" [type]="'button'" (btnClick)="bulkAction('PUT')"></orlo-button>
          </div>
          <div class="bulk-edit-filters clearfix">
            <ul>
              <li>
                <div class="bulk-actions" *ngIf="this.selectedPosts.length > 0">
                  <span (click)="toggleActions = !toggleActions">Actions <i class="ssi ssi-arrow-down-correct"></i></span>
                  <ul *ngIf="toggleActions">
                    <li 
                    (click)="editSchedule()">
                      <i class="ssi ssi-experience"></i>Edit Schedules
                    </li>
                    <li (click)="editCampaigns()">
                      <i class="ssi ssi-campaign"></i>Edit Campaigns
                    </li>
                    <li (click)="requireValidation()">
                      <i class="ssi ssi-request-validation"></i>Request Approval
                    </li>
                    <li (click)="removeFromEditor()">
                      <i class="ssi ssi-table-remove"></i>Remove From Editor
                    </li>
                    <hr />
                    <li (click)="openBulkDeleteModal()">
                      <i class="ssi ssi-delete"></i>
                      Delete Posts
                    </li>
                  </ul>
                </div>
              </li>
              <li (click)="filterByValidation = null">
                <div class="filter-select" [class.active]="!filterByValidation">
                  <span>All Posts</span>
                  <div></div>
                </div>
              </li>
              <li (click)="filterByValidation = true">
                <div class="filter-select" [class.active]="filterByValidation">
                  <span>Validation Required</span>
                  <div></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="table-container">
          <table class="table orlo-table">
            <thead>
              <tr>
                <th><input type="checkbox" [checked]="posts.length === selectedPosts.length" (change)="selectPost(posts,true)"/></th>
                <th><button class="orlo-button deselect" *ngIf="selectedPosts.length > 0" (click)="selectedPosts = []"><i class="ssi ssi-times"></i> Deselect {{selectedPosts.length}} post{{selectedPosts.length !== 1 ? 's' : '' }}</button>Account</th>
                <th>Edit Post</th>
                <th>Media Attached</th>
                <th>Edit Schedule</th>
                <th>Edit Campaign</th>
              </tr>
            </thead>
            <div class="no-results" *ngIf="loading">
              <i class="fa fa-spin fa-spinner"></i>
            </div>
            <div class="no-results" *ngIf="!loading && (posts | filter: {requiresValidation: filterByValidation}).length === 0">
              <i class="ssi ssi-confetti"></i>
              <p>All clear, no validations yet!</p>
            </div>
            <tbody>
              <tr *ngFor="let post of posts | filter: {requiresValidation: filterByValidation}" [class.selected]="isSelected(post.edit.id)" [class.schedule-warning]="checkSchedule(post.edit.send_at)">
                <td><input type="checkbox" [checked]="isSelected(post.edit.id)" (change)="selectPost(post,false)" /></td>
                <td>
                  <div class="post-handle">
                    <img [src]="fetchAccountImage(post.edit.account_id)"/>
                    <div>
                      <i [ngClass]="fetchAccountIcon(post.edit.account_id)"></i>
                      <span>{{fetchAccountName(post.edit.account_id)}}</span>
                      <span class="handle">@{{fetchAccountHandle(post.edit.account_id)}}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <ssi-publisher-landing
                    [textBoxOnly]="true"
                    [disable]="{editText: checkSchedule(post.edit.send_at) === 'Post already published'}"
                    [post]="post"
                    [accounts]="workflowAccounts"
                    [campaigns]="campaigns"
                    [slideAnimationCompleted]="true"        
                    (updateSelectedMentions)="updateSelectedMentions($event)">
                  </ssi-publisher-landing>
                  <div class="post-error" *ngIf="postErrors.length > 0 && checkError(post.edit.id)">
                    <i class="ssi ssi-warning"></i>
                    {{checkError(post.edit.id)}}
                  </div>
                </td>
                <td>
                  <div class="attachment-image">
                    <span *ngIf="post.edit.attachments.length === 0">No media</span>
                    <div *ngIf="post.edit.attachments.length > 1">{{post.edit.attachments.length - 1}}+</div>
                    <img *ngIf="post.edit.attachments.length > 0" [src]="post.edit.attachments[0].url"/>
                  </div>
                </td>
                <td>
                  <span
                    class="action"
                    [class.disabled]="checkSchedule(post.edit.send_at) === 'Post already published'"
                    (click)="editSchedule(post)">
                    {{ post.edit.send_at| date:'EEE d, MMM y, H:mm' }} <i class="ssi ssi-pencil"></i>
                  </span>
                  <div class="notify post-validation" *ngIf="post.edit.requires_validation">In Approvals</div>
                  <div class="notify schedule-warning" *ngIf="checkSchedule(post.edit.send_at)">
                    <i class="ssi ssi-warning"></i>{{checkSchedule(post.edit.send_at)}}
                  </div>
                </td>
                <td>
                  <span class="action" *ngIf="fetchCampaignName(post.edit.campaign_id)" (click)="editCampaigns(post)">{{fetchCampaignName(post.edit.campaign_id)}} <i class="ssi ssi-pencil"></i></span>
                  <div class="notify add-campaign" *ngIf="!fetchCampaignName(post.edit.campaign_id)" (click)="editCampaigns(post)"><i class="ssi ssi-plus"></i>Add campaign</div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>