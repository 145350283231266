import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HumanQueryPipe } from './human-query.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [HumanQueryPipe],
  exports: [HumanQueryPipe]
})
export class HumanQueryModule {}
