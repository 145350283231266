import { Injectable, Injector } from '@angular/core';
import { Auth, API } from '@ui-resources-angular';
import {
  routes,
  customerSupportWidgetNamespace,
  symbols
} from '../../../../../library/constants/live-chat';

/**
 * Dumb wrapper around satellizers $auth service
 * The plan is to drop satellizer though once the new oauth stuff is in place
 */
@Injectable()
export class AuthService {
  auth: Auth;

  constructor(private api: API, private injector: Injector) {
    this.auth = injector.get('$injector').get('$auth');

    this.setJWT();
  }

  isAuthenticated(): boolean {
    const cookie = document.cookie
      .split(';')
      .filter((c) => c.includes('satellizer_auth_token'))[0];
    if (cookie && localStorage.getItem('satellizer_auth_token') === null) {
      localStorage.setItem(
        'satellizer_auth_token',
        cookie.trim().split('=')[1]
      );
    }
    return this.auth.isAuthenticated();
  }

  clearJWT() {
    if (!window[customerSupportWidgetNamespace]) {
      window[customerSupportWidgetNamespace] = {};
    }
    window[customerSupportWidgetNamespace][symbols.widgetNamespaceForUser] = {};
  }

  setJWT() {
    if (!!this.isAuthenticated()) {
      this.api.get(routes.jwt).then((response) => {
        if (
          !!response.data &&
          !!response.data.jwt &&
          !!response.data.jwt.length
        ) {
          if (!window[customerSupportWidgetNamespace]) {
            window[customerSupportWidgetNamespace] = {};
          }

          window[customerSupportWidgetNamespace][
            symbols.widgetNamespaceForUser
          ] = response.data.jwt;
        }
      });
    }
  }
}
