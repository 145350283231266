import angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DataStore } from 'js-data';
import {
  ActivityTags,
  ActivityThreadFactory,
  DateDifference,
  Replace,
  SecondsToHumanTime,
  Video,
  GreaterThan,
  SocialPostCharactersRemaining,
  BlockingWord,
  NotificationPreferences,
  filestackApiKey,
  twitter,
  socialNetworkSettings,
  AccountModel,
  WorkflowModel,
  ActivityModel,
  CampaignModel,
  CrmExternalIntegrationModel,
  CrmPersonModel,
  API,
  NoteModel,
  ProfileModel,
  OutboxModel,
  UserModel,
  VanityDomainModel,
  ConversationModel
} from '@ui-resources-angular';
import moment from 'moment';

export default angular
  .module('ssi.ui-resources', [])
  .factory('accountModel', downgradeInjectable(AccountModel))
  .factory('workflowModel', downgradeInjectable(WorkflowModel))
  .factory('activityModel', downgradeInjectable(ActivityModel))
  .factory('activityTags', downgradeInjectable(ActivityTags))
  .factory('activityThreadFactory', downgradeInjectable(ActivityThreadFactory))
  .factory('conversationModel', downgradeInjectable(ConversationModel))
  .factory('campaignModel', downgradeInjectable(CampaignModel))
  .factory(
    'crmExternalIntegrationModel',
    downgradeInjectable(CrmExternalIntegrationModel)
  )
  .factory('crmPersonModel', downgradeInjectable(CrmPersonModel))
  .filter('dateDifference', () => {
    'ngInject';
    return new DateDifference().transform;
  })
  .filter('replace', () => {
    'ngInject';
    return new Replace().transform;
  })
  .filter('secondsToHumanTime', () => {
    'ngInject';
    return new SecondsToHumanTime().transform;
  })
  .factory('api', downgradeInjectable(API))
  .factory('video', downgradeInjectable(Video))
  .factory('DataStore', downgradeInjectable(DataStore))
  .factory('noteModel', downgradeInjectable(NoteModel))
  .factory('profileModel', downgradeInjectable(ProfileModel))
  .filter('greaterThan', () => {
    'ngInject';
    return new GreaterThan().transform;
  })
  .filter('socialPostCharactersRemaining', () => {
    'ngInject';
    return new SocialPostCharactersRemaining().transform;
  })
  .factory('outboxModel', downgradeInjectable(OutboxModel))
  .factory('blockingWord', downgradeInjectable(BlockingWord))
  .factory('userModel', downgradeInjectable(UserModel))
  .factory('vanityDomainModel', downgradeInjectable(VanityDomainModel))
  .factory(
    'notificationPreferences',
    downgradeInjectable(NotificationPreferences)
  )
  .constant('moment', moment)
  .constant('filestackApiKey', filestackApiKey)
  .constant('twitter', twitter)
  .constant('socialNetworkSettings', socialNetworkSettings).name;
