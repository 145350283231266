import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingBoxComponent } from './loading-box.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [LoadingBoxComponent],
  declarations: [LoadingBoxComponent],
  entryComponents: [LoadingBoxComponent]
})
export class LoadingBoxModule {}
