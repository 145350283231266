import { Component, Input } from '@angular/core';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { PopupService } from '../../../../../../angular/common/services/popup/popup.service';

export async function socialPolicyFn(api: API) {
  return api.get('settings/policy').then(({ data }) => data);
}

@Component({
  selector: 'ssi-manage-social-policy',
  templateUrl: './manage-social-policy.component.html',
  styleUrls: []
})
export class ManageSocialPolicyComponent {
  static resolve = [
    {
      token: 'socialPolicy',
      resolveFn: socialPolicyFn,
      deps: [API]
    }
  ];

  @Input() socialPolicy;
  loadingTracker = this.asyncTrackerFactory.create();

  constructor(
    private state: StateService,
    private api: API,
    private translate: TranslateService,
    private popup: PopupService,
    private asyncTrackerFactory: AsyncTrackerFactory
  ) {}

  savePolicy() {
    const promise = this.api
      .post('settings/policy', {
        policy: this.socialPolicy.policy
      })
      .then(() => {
        return this.state.go('auth.settings.index');
      })
      .then(() => {
        this.popup.alert({
          title: this.translate.instant('SETTINGS_SAVED'),
          message: this.translate.instant(
            'YOUR_SETTINGS_WERE_SAVED_SUCCESSFULLY'
          )
        });
      });
    this.loadingTracker.add(promise);
  }
}
