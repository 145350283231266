<div class="panel panel-default">
  <div class="panel-heading">
    1. <span translate="SOCIAL_ACCOUNT"></span>
  </div>

  <div class="panel-body">
    <p
      translate="START_BY_UPLOADING_YOUR_CSV_OF_POSTS_DONT_WORRY_YOULL_BE_ABLE_TO_CHECK_THEM_BEFORE_THEY_GO_OUT_IF_YOURE_NOT_SURE_WHAT_FORMAT_YOU_CAN_DOWNLOAD_OUR_EXAMPLE_SPREADSHEET"
    ></p>

    <hr/>

    <ul class="bu-action-list">
      <li>
        <select
          [(ngModel)]="selectedDateFormat"
          class="form-control"
          required
        >
          <option [value]="undefined" translate="PLEASE_SELECT_A_DATE_FORMAT"></option>
          <option *ngFor="let format of allowedDateFormats" [ngValue]="format.momentFormat">
            {{ format.momentFormat }}
          </option>
        </select>
      </li>
      <li> 
        <button
          class="btn btn-default btn-large btn-block"
          ssiFileUploader
          [accept]="['.csv', '.xlsx', '.xls']"
          [fromSources]="spreadsheetFileSources"
          [disabled]="loading || !selectedDateFormat"
          [ngClass]="{'disabled': loading || !selectedDateFormat}"
          (uploadSuccess)="csvUploaded($event)"
        >
          <i class="fa fa-file"></i> <span translate="UPLOAD_CSV__EXCEL_FILE"></span>
        </button>
      </li>
      <li>
        <button
          class="btn btn-default btn-large btn-block"
          ssiFileDownload
          filename="Orlo - Bulk upload example.xlsx"
          base64decode="true"
          [data]="sampleSpreadsheet"
          mimetype="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <i class="fa fa-download"></i> <span translate="DOWNLOAD_EXAMPLE_SHEET"></span>
        </button>
      </li>
    </ul>

    <ssi-progress-bar
      *ngIf="file && totalCsvRowsParsed < totalCsvRows"
      max="100"
      [progress]="(totalCsvRowsParsed / totalCsvRows) * 100"
      bgColor="#fff"
      color="#14bae3"
      [radius]="5"
      [thickness]="20"
    ></ssi-progress-bar>
  </div>
</div>

<ng-container *ngIf="fileParsed && !loading">
  
  <div class="bu-divider">
    <i class="fa fa-chevron-down text-muted fa-3x"></i>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">
      2. <span translate="SOCIAL_ACCOUNT"></span>
    </div>
    <div class="panel-body">
      <div *ngIf="!hasAccountsColumn">
        <p translate="SELECT_ONE_OR_MORE_SOCIAL_ACCOUNTS_TO_BULK_UPLOAD_TO"></p>
        <div class="text-center">
          <select
            multiple
            class="form-control"
            [(ngModel)]="selectedAccounts"
            (ngModelChange)="updateAllPostAccounts()"
          >
            <option *ngFor="let account of postAccounts" [ngValue]="account.id">
              {{ account.displayName + ' - ' + account.accountTypeLabel }}
            </option>
          </select>
        </div>
        <br/>
      </div>

      <div *ngIf="selectedAccounts.length > 0 || hasAccountsColumn">
        <p translate="OPTIONALLY_SELECT_CAMPAIGN"></p>
        <select
          *ngIf="campaigns.length > 0"
          class="form-control"
          [(ngModel)]="selectedCampaignID"
        >
          <option value="undefined" translate="SELECT_SOCIAL_CAMPAIGN_OPTIONAL"></option>
          <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">
            {{ campaign.name }}
          </option>
        </select>
        <p *ngIf="campaigns.length < 0" class="text-center">
          You have no campaigns created yet. Create the first now by going to <a uiSref="auth.campaigns">Campaigns</a>
        </p>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="fileParsed && (selectedAccounts.length > 0 || hasAccountsColumn)">

  <div class="bu-divider">
    <i class="fa fa-chevron-down text-muted fa-3x"></i>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">3. <span translate="CHECK_POSTS"></span></div>
    <div class="panel-body">
      <table class="table table-bordered table-striped">
        <thead>
        <tr>
          <th translate="TEXT"></th>
          <th translate="IMAGE"></th>
          <th translate="POST_DATE"></th>
          <th translate="ALT_TEXT" class="text-capitalize"></th>
          <th translate="ACCOUNTS" *ngIf="hasAccountsColumn"></th>
          <th translate="INFO"></th>
          <th translate="REMOVE" class="text-center"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let post of posts; trackBy: trackByIndex; let index = index">
          <td>
            <textarea
              [(ngModel)]="post.text"
              class="form-control"
              rows="5"
              (ngModelChange)="checkPostTextLengthValid(post)"
            ></textarea>
          </td>
          <td>
            <div
              *ngIf="post.files[0]?.url"
              class="img-window"
              [ngStyle]="{'background-image': 'url(' + post.files[0].url + ')'}"
              [ssiEnlargeImage]="post.files[0].url"
              [ssiTooltip]="'IMAGE_WILL_NOT_BE_CROPPED_WHEN_POSTED' | translate"
              tooltipPlacement="bottom"
              tooltipWindowClass="tooltip-chunky"
            ></div>

            <button
              *ngIf="!post.files[0]?.url" 
              class="btn btn-default btn-block"
              ssiFileUploader
              [accept]="['image/jpeg', 'image/pjpeg', 'image/png']"
              [fromSources]="outboxFileSources"
              [restrictions]="post.mediaRestrictions"
              (uploadStart)="imageUploadStart(post)"
              (uploadSuccess)="imageUploadSuccess(post, $event)"
              (uploadError)="imageUploadError(post, $event)"
            >
              <i class="fa fa-file"></i> {{ 'ADD_IMAGE' | translate }}
            </button>
          </td>
          <td>{{ post.schedules[0] | date:'MMM d HH:mm' }}</td>
          <td>
            <textarea
              [(ngModel)]="post.alt_text"
              class="form-control"
              rows="5"
            ></textarea>
          </td>
          <td *ngIf="hasAccountsColumn">
            <ul class="list-unstyled">
              <li *ngFor="let account of post.accounts">
                <i
                  [ngClass]="account.socialNetwork.icon.web" 
                  class="fa-fw"
                ></i> {{ account.displayName }}
              </li>
            </ul>
          </td>
          <td class="text-center" style="max-width: 200px">
            <div *ngIf="!post.loading">
              <div
                *ngIf="!post.text"
                translate="NO_TEXT_FOUND_FOR_THE_POST_IN_COLUMN_1"
                class="text-danger"
              ></div>
              <div
                *ngIf="post.errors.date?.isPast"
                translate="THE_DATE__DATE__IS_IN_THE_PAST"
                [translateParams]="{date: post.errors.date.given}"
                class="text-danger"
              ></div>
              <div
                *ngIf="post.errors.date?.invalid"
                translate="THE_DATE__DATE__DOES_NOT_MATCH_THE_FORMAT__FORMAT_"
                [translateParams]="{date: post.errors.date.given, format: selectedDateFormat.momentFormat}"
                class="text-danger"
              ></div>
              <div
                *ngIf="post.accounts.length === 0"
                class="text-danger"
                translate="ACCOUNT_IDS_MUST_BE_SPECIFIED_FOR_ALL_POSTS"
              ></div>
              <div
                *ngIf="post.errors.image && !post.status"
                class="text-danger"
              >
                <span>{{post.errors.image.message}}</span>
              </div>
              <div
                *ngIf="post.errors.textLength"
                class="text-danger"
                translate="THE_POST_IS_TOO_LONG_FOR_THE_ACCOUNTS_SELECTED_THE_MAXIMUM_IS__MAXIMUMCHARACTERS__CHARACTERS_THIS_POST_IS__CHARACTERSTOOMANY__CHARACTERS_TOO_MANY"
                [translateParams]="{maximumCharacters: post.errors.textLength}"
              ></div>
              <div
                *ngIf="postIsValid(post) && !post.status && !post.errors.image"
                translate="READY_TO_UPLOAD"
              ></div>
              <div *ngIf="post.status">
                {{ post.status }}
              </div>
            </div>
            <div *ngIf="post.loading" class="loading"></div>
          </td>
          <td class="text-center">
            <button
              class="btn btn-danger"
              [disabled]="post.status"
              (click)="posts.splice(index, 1)"
            >
              <i class="fa fa-times"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p
        *ngIf="posts.filter(postIsValid).length < posts.length"
        translate="PLEASE_RECTIFY_THE_FOLLOWING_ERRORS_AND_RELOAD_BEFORE_CONTINUING"
        class="text-danger text-center"
      ></p>
      <button
        *ngIf="
          !loading &&
          loadingPercentage != 100 &&
          posts.filter(postIsValid).length >= posts.length &&
          posts.length != 0"
        class="btn btn-success col-sm-6 col-sm-offset-3"
        (click)="startUpload()"
      >
        <i class="fa fa-check"></i> <span translate="BULK_UPLOAD"></span>
      </button>
      <div *ngIf="loading">
        <div class="loading"></div>
        <h3 class="text-center"> <span translate="SCHEDULING_POSTS"></span> - <span>{{ loadingPercentage | number: '1.0-0' }}% Complete</span></h3>
        <ssi-progress-bar
          max="100"
          [progress]="loadingPercentage"
          bgColor="#fff"
          color="#14bae3"
          [radius]="5"
          [thickness]="20"
        ></ssi-progress-bar>
      </div>
      <p
        *ngIf="loadingPercentage == 100"
        class="bu-complete text-center text-success"
      >
        <span translate="UPLOAD_COMPLETE"></span> <i class="fa fa-check"></i> <br/><small translate="SEE_INFO_COLUMN_FOR_ANY_ERRORS"></small>
      </p>
    </div>
  </div>
</ng-container>
