import { Injectable } from '@angular/core';
import {
  Model,
  Outbox,
  Record,
  Account,
  OutboxModel,
  API,
  UserModel
} from '@ui-resources-angular';
import { TargetingOption } from '../advertising-preset-model/advertising-preset-model.service';
import { AdvertisingType } from '../advertising-targeting/advertising-targeting.service';
import { appInjector } from '../../../../../../../apps/angular/app-injector';
import {
  ColleaguesService,
  Colleague
} from '../../../../../common/services/api';

interface Money {
  amount: string;
  currency: string;
}

interface AdvertData {
  account_id: string;
  bidding: object;
  budget: object;
  created_by: number;
  external_link: string;
  id: string;
  is_published: boolean;
  is_validated: boolean;
  locale: object;
  name: string;
  outbox: Outbox;
  validated_by: Colleague;
  outbox_id: string;
  schedule: object;
  targetingOptionsLabel: string;
  targeting_options: TargetingOption[];
}

/* tslint:disable variable-name */
export class Advert extends Record {
  id: string;
  name: string;
  account_id: string | number;
  account: Account;
  outbox_id: string | number;
  targeting_options: TargetingOption[];
  data: AdvertData[];
  budget: {
    daily_budget: Money;
    total_budget: Money;
  };
  bidding: {
    amount: Money;
    bidding_event: string;
  };
  schedule: {
    start: string;
    end: string;
  };
  locale: {
    language: string;
    country: string;
  };
  external_link: string;
  requires_validation?: boolean;
  is_validated: boolean;
  validated_by: string;
  targetingOptionsLabel: string;
  parameters: {
    special_ad_category: string;
  };

  get validatedBy(): Colleague {
    if (!this.validated_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.validated_by);
  }

  // setters must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set validatedBy(c: Colleague) {}

  get outbox(): Outbox {
    return this.injector.get(OutboxModel).get(this.outbox_id);
  }

  set outbox(value) {
    this.injector.get(OutboxModel).add(value);
  }

  async validate() {
    await this.injector.get(API).post<{ data: { success: true } }>(
      'advertising/advertisingValidateAdvert',
      {
        id: this.id,
        is_validated: true
      },
      {
        params: {
          _method: 'PATCH'
        }
      }
    );
    this.is_validated = true;
    const authUser = await this.injector.get(UserModel).getAuthUser();
    this.validated_by = authUser.id;
  }
}

@Injectable() // tslint:disable-line max-classes-per-file
export class AdvertModelService extends Model<Advert> {
  constructor(private api: API) {
    super('advert', {
      endpoint: 'advertising/advertisingAdverts_v2',
      recordClass: Advert,
      relations: {
        belongsTo: {
          account: {
            localKey: 'account_id',
            localField: 'account'
          }
          // colleague: {
          //   localKey: 'validated_by',
          //   localField: 'validatedBy'
          // }
        }
      },
      beforeAdd(adverts: Array<Partial<Advert>>) {
        const advertV2 = adverts[0].hasOwnProperty('data');
        if (advertV2) {
          return adverts.map((advertList) =>
            advertList.data.map((advert) => {
              advert.targetingOptionsLabel = advert.targeting_options
                .map((targetingOption) => {
                  return `${
                    targetingOption.option.type === AdvertisingType.Include
                      ? 'Include'
                      : 'Exclude'
                  } ${targetingOption.option.name}: ${targetingOption.label}`;
                })
                .join('\n');
              return advert;
            })
          );
        } else {
          return adverts.map((advert) => {
            advert.targetingOptionsLabel = advert.targeting_options
              .map((targetingOption) => {
                return `${
                  targetingOption.option.type === AdvertisingType.Include
                    ? 'Include'
                    : 'Exclude'
                } ${targetingOption.option.name}: ${targetingOption.label}`;
              })
              .join('\n');
            return advert;
          });
        }
      }
    });
  }

  async createBoostedPost(boostedPost: Partial<Advert>): Promise<Advert> {
    const { data } = await this.api.post<{ data: { id: number } }>(
      'advertising/advertisingBoostedPost',
      boostedPost
    );
    return (await this.findAll({ id: String(data.id) })) as any;
  }
}
