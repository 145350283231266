import { Record } from 'js-data';
import { api } from '../../core/services/api';
import { Model } from '../../model';
import { services } from '../../common';

export interface CrmExternalIntegrationSearchResult {
  external_id: string;
  name: string;
}

const configTranslationIds = Object.freeze({
  custom: 'CRM_INTEGRATION_CUSTOM',
  'pipe-drive': 'CRM_INTEGRATION_PIPEDRIVE',
  msdynamics: 'CRM_INTEGRATION_MSDYNAMICS',
  salesforce: 'CRM_INTEGRATION_SALESFORCE',
  zendesk: 'CRM_INTEGRATION_ZENDESK'
});

const configParamTranslationIds = Object.freeze({
  search_endpoint_url: 'CRM_INTEGRATION_PARAM_SEARCH_ENDPOINT_URL',
  search_endpoint_secret: 'CRM_INTEGRATION_PARAM_SEARCH_ENDPOINT_SECRET',
  'i-frame_endpoint_url': 'CRM_INTEGRATION_PARAM_IFRAME_ENDPOINT_URL',
  'i-frame_endpoint_secret': 'CRM_INTEGRATION_PARAM_IFRAME_ENDPOINT_SECRET',
  username: 'CRM_INTEGRATION_PARAM_USERNAME',
  password: 'CRM_INTEGRATION_PARAM_PASSWORD',
  api_key: 'CRM_INTEGRATION_PARAM_API_KEY',
  token: 'CRM_INTEGRATION_PARAM_TOKEN',
  sub_domain: 'CRM_INTEGRATION_PARAM_SUB_DOMAIN',
  email: 'CRM_INTEGRATION_PARAM_EMAIL'
});

export interface CrmExternalIntegrationPerson {
  name?: string;
  givenName?: string;
  familyName?: string;
  worksFor?: {
    '@type': 'Organization';
    name: string;
  };
  contactPoint?: Array<{
    '@type': 'ContactPoint';
    name: string;
    telephone?: string;
    email?: string;
  }>;
  '@reverse'?: {
    about: Array<{
      '@type': 'Comment';
      author: {
        '@type': 'Person';
        name: string;
        image: string;
        email: string;
      };
      text: string;
      dateCreated: string;
      dateModified: string;
    }>;
  };
  '@type': 'Person';
  deal?: {
    '@type': 'Deal';
    owner: {
      '@type': 'Person';
      name: string;
      email: string;
    };
    stage: {
      '@type': 'Stage';
      name: string;
    };
  };
  potentialAction?: {
    '@type': 'ViewAction';
    target: {
      '@type': 'EntryPoint';
      urlTemplate: string;
      httpMethod: string;
      contentType: string;
    };
  };
}

export class CrmExternalIntegration extends Record {
  id: string;
  is_disabled: boolean;
  name: string;
  type: string;

  get typeTranslationId() {
    return configTranslationIds[this.type];
  }

  set typeTranslationId(val) {}

  searchPeople(query: string): Promise<CrmExternalIntegrationSearchResult[]> {
    return api
      .get('crm/externalIntegrationSearch', {
        params: {
          integration_id: this.id,
          q: query
        }
      })
      .then(
        ({
          data
        }: {
          data: { people: CrmExternalIntegrationSearchResult[] };
        }) => data.people
      );
  }

  getExternalPerson(
    searchResult: CrmExternalIntegrationSearchResult
  ): Promise<CrmExternalIntegrationPerson> {
    return api
      .get('crm/externalIntegrationPerson', {
        params: {
          integration_id: this.id,
          external_id: searchResult.external_id
        }
      })
      .then(({ data }) => data);
  }

  disable(): Promise<any> {
    return api
      .post(
        'crm/externalIntegration',
        {
          id: this.id,
          is_disabled: true
        },
        {
          params: {
            _method: 'PUT'
          }
        }
      )
      .then(() => {
        this.is_disabled = true;
        return this;
      });
  }
}

export interface CrmExternalIntegrationType {
  name: string;
  translationId: string;
  params: Array<{ name: string; translationId: string }>;
}

export interface NewCrmExternalIntegration {
  type: string;
  name: string;
  configuration: {
    [key: string]: string;
  };
}

export class CrmExternalIntegrationModel extends Model<CrmExternalIntegration> {
  constructor() {
    super('crmExternalIntegration', {
      endpoint: 'crm/externalIntegration',
      deserialize(
        resourceConfig,
        result: { data: { integrations: CrmExternalIntegration[] } }
      ) {
        return result.data.integrations;
      },
      recordClass: CrmExternalIntegration
    });
  }

  getTypes(): Promise<CrmExternalIntegrationType[]> {
    return api
      .get('crm/externalIntegrationType')
      .then((result): CrmExternalIntegrationType[] => {
        return Object.entries(result.data.data).map(
          ([name, params]: [string, string[]]) => {
            return {
              name,
              translationId: configTranslationIds[name],
              params: params.map((paramName) => {
                return {
                  name: paramName,
                  translationId: configParamTranslationIds[paramName]
                };
              })
            };
          }
        );
      });
  }

  createNew(
    integration: NewCrmExternalIntegration
  ): Promise<CrmExternalIntegration> {
    return api
      .post('crm/externalIntegration', integration)
      .then(({ data }: { data: { success: true; uuid: string } }) => {
        return this.inject({
          id: data.uuid,
          is_disabled: false,
          name: integration.name,
          type: integration.type
        });
      });
  }

  findAllActive(): Promise<CrmExternalIntegration[]> {
    return this.findAll().then((integrations) => {
      return integrations.filter((integration) => !integration.is_disabled);
    });
  }
}

export function crmExternalIntegrationModelFactory(dataStore?) {
  return (
    services.models.get('crmExternalIntegration') ||
    new CrmExternalIntegrationModel()
  );
}
