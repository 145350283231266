import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePostsBoostComponent } from './validate-posts-boost.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ValidatePostsBoostComponent],
  exports: [ValidatePostsBoostComponent]
})
export class ValidatePostsBoostModule {}
