import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingComponent } from './advertising.component';
import { AdvertisingPresetModelService } from './advertising-preset-model/advertising-preset-model.service';
import { AdvertisingTargetingService } from './advertising-targeting/advertising-targeting.service';
import { AdvertisingIndexModule } from './advertising-index/advertising-index.module';
import { AdvertisingPresetListModule } from './advertising-preset-list/advertising-preset-list.module';
import { AdvertisingCreatePresetModule } from './advertising-create-preset/advertising-create-preset.module';
import { AdvertisingBoostPostModule } from './advertising-boost-post/advertising-boost-post.module';
import { AdvertisingAdvertEditModule } from './advertising-advert-edit/advertising-advert-edit.module';
import { AdvertModelService } from './advert-model/advert-model.service';
// import { AdvertisingAdvertListModule } from './advertising-advert-list/advertising-advert-list.module';
import { AdvertisingEditPresetModule } from './advertising-edit-preset/advertising-edit-preset.module';
import { AdvertisingNotPurchasedModule } from './advertising-not-purchased/advertising-not-purchased.module';
import { AdvertisingObjectivesModule } from './advertising-objectives/advertising-objectives.module';
import { AdvertisingBoostConfirmedModule } from './advertising-boost-confirmed/advertising-boost-confirmed.module';

@NgModule({
  imports: [
    CommonModule,
    AdvertisingIndexModule,
    AdvertisingPresetListModule,
    AdvertisingCreatePresetModule,
    AdvertisingBoostPostModule,
    // AdvertisingAdvertListModule,
    AdvertisingAdvertEditModule,
    AdvertisingObjectivesModule,
    AdvertisingEditPresetModule,
    AdvertisingNotPurchasedModule,
    AdvertisingBoostConfirmedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising',
          url: '/advertising?query&outbox_id&validate',
          component: AdvertisingComponent,
          resolve: AdvertisingComponent.resolve,
          params: {
            query: {
              dynamic: true
            },
            outbox_id: {
              dynamic: true
            },
            validate: {
              dynamic: true
            }
          }
        }
      ]
    })
  ],
  declarations: [AdvertisingComponent],
  entryComponents: [AdvertisingComponent],
  providers: [
    AdvertisingPresetModelService,
    AdvertisingTargetingService,
    AdvertModelService
  ]
})
export class AdvertisingModule {}
