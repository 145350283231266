<div
  class="date-time-picker-wrap"
  [class.disabled]="disabled"
>
  <div class="date-container">
    <label for="date">{{dateLabel}}</label>
    <input
      type="text"
      [class.active]="!!date.date"
      [disabled]="disabled"
      [attr.placeholder]="placeholder"
      [ngModel]="date.date | date: defaultDateFormat"
      (ngModelChange)="date.date = $event"
      (click)="$event.stopPropagation(); showDatePicker = true"
      cdkOverlayOrigin
      #datePickerTriggerOrigin="cdkOverlayOrigin"
    >
    <i class="ssi ssi-calendar-small calendar-icon"></i>
  </div>

  <ng-template
    #monthCellTemplate
    let-day="day"
    let-locale="locale"
  >

    <ng-template #calendarDayTooltipDisabled>
      <div class="tooltip-schedule">
        <p class="tooltip-schedule-title">{{pastDateTooltipTitle}}</p>
        <p class="tooltip-schedule-body">{{pastDateTooltipBody}}</p>
      </div>
      <div
        data-popper-arrow
        class="tooltip-arrow"
      ></div>
    </ng-template>
    <button
      class="cal-cell-top"
      ssiTooltip
      [tooltipTemplate]="day.meta && day.meta.isDisabled ? calendarDayTooltipDisabled : ''"
      [tooltipWindowClass]="day.meta?.isScheduled ? 'tooltip-chunky' : 'tooltip-chunky-big'"
      [tooltipDisabled]="!day.meta?.isDisabled"
      cdkOverlayOrigin
      #dateTriggerOrigin="cdkOverlayOrigin"
    >
      <span class="cal-day-number">
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
    </button>
  </ng-template>

  <ng-template
    #sentimentMenu="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="datePickerTriggerOrigin"
    [cdkConnectedOverlayOpen]="showDatePicker"
    [cdkConnectedOverlayFlexibleDimensions]="false"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayPush]="true"
    (backdropClick)="showDatePicker = false"
  >
    <div class="date-picker-outer">
      <div class="date-picker-header">
        <h3>{{calHeaderTitle}}</h3>
      </div>
      <div class="date-picker-inner">
        <div class="d-flex date-picker-title-wrap">
          <div class="date-picker-title-nav date-picker-title-nav-prev">
            <a
              href="javascript:;"
              mwlCalendarPreviousView
              view="month"
              [(viewDate)]="calendarDate"
              (click)="monthChanged()"
            >
              <i class="ssi ssi-chevron-small"></i>
            </a>
          </div>
          <div class="flex-1 date-picker-title">
            <p>{{ calendarDate | calendarDate:'monthViewTitle':'en' }}</p>
          </div>
          <div class="date-picker-title-nav date-picker-title-nav-next">
            <button
              mwlCalendarNextView
              view="month"
              [(viewDate)]="calendarDate"
              (click)="monthChanged()"
            >
              <i class="ssi ssi-chevron-small point-right"></i>
            </button>
          </div>
        </div>
        <mwl-calendar-month-view
          [viewDate]="calendarDate"
          [weekStartsOn]="1"
          [refresh]="refreshCalendar"
          [cellTemplate]="monthCellTemplate"
          (dayClicked)="dayClicked($event.day)"
          (beforeViewRender)="addCssClasses($event.body)"
        ></mwl-calendar-month-view>
      </div>
    </div>
  </ng-template>

  <div
    class="time-container"
    *ngIf="showTime"
  >
    <label for="date">{{timeLabel}}</label>
    <div class="select-container">
      <select
        [disabled]="disabled"
        [class.active]="date.hour && hourField.dirty"
        name="hour"
        id=""
        [(ngModel)]="date.hour"
        (ngModelChange)="onHourChange($event)"
        #hourField="ngModel"
      >
        <option
          *ngFor="let hour of hours"
          [value]="hour"
        >{{hour}}</option>
      </select>
      <span class="semicolon">:</span>
      <select
        [disabled]="disabled"
        [class.active]="date.minute && minuteField.dirty"
        name="minute"
        id=""
        [(ngModel)]="date.minute"
        (ngModelChange)="onMinuteChange($event)"
        #minuteField="ngModel"
      >
        <option
          *ngFor="let minute of minutes"
          [value]="minute"
        >{{minute}}</option>
      </select>
      <div class="meridian-toggle">
        <button
          [disabled]="disabled"
          [class.active]="meridian === 'am'"
          (click)="setMeridianTime('am')"
        >am</button>
        <button
          [disabled]="disabled"
          [class.active]="meridian === 'pm'"
          (click)="setMeridianTime('pm')"
        >pm</button>
      </div>
    </div>
  </div>
</div>