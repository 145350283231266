import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { OutboxPostComponent } from './outbox-post.component';

import { OutboxPostMenuModule } from '../../../../../common/components/outbox-post-menu/outbox-post-menu.module';
import { EnlargeImageModule } from '../../../../../common/directives/enlarge-image/enlarge-image.module';
import { FallbackImageModule } from '../../../../../common/directives/fallback-image/fallback-image.module';
import { LinkifyModule } from '../../../../../common/pipes/linkify/linkify.module';
import { ReverseModule } from '../../../../../common/pipes/reverse/reverse.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { SocialNetworkIconModule } from '../../../../../common/components/social-network-icon/social-network-icon.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { TriggerPublishModule } from '../../../../../common/directives/trigger-publish/trigger-publish.module';

@NgModule({
  declarations: [OutboxPostComponent],
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule,
    OutboxPostMenuModule,
    EnlargeImageModule,
    FallbackImageModule,
    LinkifyModule,
    ReverseModule,
    BigNumberModule,
    SocialNetworkIconModule,
    TooltipModule,
    TriggerPublishModule
  ],
  exports: [OutboxPostComponent]
})
export class OutboxPostModule {}
