<div class="dashboard-widget">
  <div class="dwm-form">
    <div class="dwm-form-half">
      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="widgetTitle"
        >Widget Title (Optional)</label>
        <div class="dwm-body-row-input">
          <input
            class="dwm-input"
            id="widgetTitle"
            type="text"
            placeholder="Type your widget title here..."
            [(ngModel)]="widget.title"
          >
          <span
            [ngClass]="{'dwm-body-row-input-meta-active':  widget.title.length}"
            class="dwm-body-row-input-meta"
          >
            {{widgetTitleMaxLength - widget.title.length}}
          </span>
        </div>
      </div>

      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="allOfTheseWords"
        >All of these words</label>
        <div class="dwm-body-row-input">
          <ssi-tags-input-2
            [tags]="widgetQuery.words.all"
            placeholder="Add a word here..."
            (onTagsChange)="widgetQuery.words.all = $event; onTagChange($event)"
          ></ssi-tags-input-2>
        </div>
      </div>

      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="noneOfTheseWords"
        >None of these words</label>
        <div class="dwm-body-row-input">
          <ssi-tags-input-2
            [tags]="widgetQuery.words.none"
            placeholder="Add a word here..."
            (onTagsChange)="widgetQuery.words.none = $event; onTagChange($event)"
          ></ssi-tags-input-2>
        </div>
      </div>

      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="anyOfTheseHashtags"
        >Any of these hashtags</label>
        <div class="dwm-body-row-input">
          <ssi-tags-input-2
            [tags]="widgetQuery.hashtags.any"
            placeholder="Add a hashtag here..."
            (onTagsChange)="widgetQuery.hashtags.any = $event; onTagChange($event)"
          ></ssi-tags-input-2>
        </div>
      </div>

      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="FromAnyOfTheseHandles"
        >From any of these handles</label>
        <div class="dwm-body-row-input">
          <ssi-tags-input-2
            [tags]="widgetQuery.author.any"
            placeholder="Add a handle here..."
            (onTagsChange)="widgetQuery.author.any = $event; onTagChange($event)"
          ></ssi-tags-input-2>
        </div>
      </div>

    </div>
    <div class="dwm-form-half">
      <div class="dwm-body-row">
        <label class="dwm-label">Account used for stream</label>
        <ssi-dropdown-select-2
          name="twitterAccounts"
          class="dwm-dropdown-selector"
          [required]="true"
          [multiple]="false"
          [filterable]="true"
          [options]="twitterAccounts"
          [placeholdersKeyword]="'account'"
          [(ngModel)]="selectedAccount"
          (ngModelChange)="onSelectedAccountsChange($event)"
        >
        </ssi-dropdown-select-2>
      </div>

      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="anyOfTheseWords"
        >Any of these words</label>
        <div class="dwm-body-row-input">
          <ssi-tags-input-2
            [tags]="widgetQuery.words.any"
            placeholder="Add a word here..."
            (onTagsChange)="widgetQuery.words.any = $event; onTagChange($event)"
          ></ssi-tags-input-2>
        </div>
      </div>

      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="allOfTheseHashtags"
        >All of these hashtags</label>
        <div class="dwm-body-row-input">
          <ssi-tags-input-2
            [tags]="widgetQuery.hashtags.all"
            placeholder="Add a hashtag here..."
            (onTagsChange)="widgetQuery.hashtags.all = $event; onTagChange($event)"
          ></ssi-tags-input-2>
        </div>
      </div>

      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="noneOfTheseHashtags"
        >None of these hashtags</label>
        <div class="dwm-body-row-input">
          <ssi-tags-input-2
            [tags]="widgetQuery.hashtags.none"
            placeholder="Add a hashtag here..."
            (onTagsChange)="widgetQuery.hashtags.none = $event; onTagChange($event)"
          ></ssi-tags-input-2>
        </div>
      </div>

    </div>
  </div>
</div>