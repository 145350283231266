import { Application as ApplicationInterface } from '../../interfaces/live-chat/application';
import Model from '../model';

export class Application extends Model implements ApplicationInterface {
  protected _data: ApplicationInterface;

  // @todo think further about this method, about `id`.
  static CreateFromSSIAccount(account): Application {
    const id = account.id;
    delete account.id;

    return new this(id, account);
  }

  public get agentsOfflineNotice(): string {
    return this._data.agentsOfflineNotice;
  }

  public set agentsOfflineNotice(value: string) {
    this._data.agentsOfflineNotice = value;
  }

  public get autoResolutionTimeout(): number {
    return this._data.autoResolutionTimeout;
  }

  public set autoResolutionTimeout(value: number) {
    this._data.autoResolutionTimeout = value;
  }

  public get appId(): string {
    return this._data.appId;
  }

  public get apiKey(): string {
    return this._data.apiKey;
  }

  public get baseFontSize(): number {
    return this._data.baseFontSize;
  }

  public set baseFontSize(value: number) {
    this._data.baseFontSize = value;
  }

  public get canAgentsUpload(): boolean {
    return !!this._data.canAgentsUpload;
  }

  public set canAgentsUpload(value: boolean) {
    this._data.canAgentsUpload = !!value;
  }

  public get canVisitorsUpload(): boolean {
    return !!this._data.canVisitorsUpload;
  }

  public set canVisitorsUpload(value: boolean) {
    this._data.canVisitorsUpload = !!value;
  }

  public get canVisitorsViewArchivedConversations(): boolean {
    return !!this._data.visitorViewArchivedConversations;
  }

  public set canVisitorsViewArchivedConversations(value: boolean) {
    this._data.visitorViewArchivedConversations = !!value;
  }

  public get chatbotId(): string {
    return this._data.chatbotId;
  }

  public set chatbotId(value: string) {
    this._data.chatbotId = value;
  }

  public get chatbotName(): string {
    return this._data.chatbotId;
  }

  public set chatbotName(value: string) {
    this._data.chatbotName = value;
  }

  public get companyId(): string {
    return this._data.companyId;
  }

  public get customerContactEmail(): string {
    return this._data.customerContactEmail;
  }

  public set customerContactEmail(value: string) {
    this._data.customerContactEmail = value;
  }

  public get customerContactLink(): string {
    return this._data.customerContactLink;
  }

  public set customerContactLink(value: string) {
    this._data.customerContactLink = value;
  }

  public get customIcon(): string {
    return this._data.customIcon;
  }

  public set customIcon(value: string) {
    this._data.customIcon = value;
  }

  public get disabledMessage(): string {
    return this._data.disabledMessage || '';
  }

  public set disabledMessage(value: string) {
    this._data.disabledMessage = value;
  }

  public get emailPrompt(): string {
    return this._data.emailPrompt;
  }

  public set emailPrompt(value: string) {
    this._data.emailPrompt = value;
  }

  public get firstResponse(): string {
    return this._data.firstResponse;
  }

  public set firstResponse(value: string) {
    this._data.firstResponse = value;
  }

  public get headerImage(): string {
    return !!this._data.headerImage ? this._data.headerImage : null;
  }

  public set headerImage(value: string) {
    this._data.headerImage = value;
  }

  public get incomingMessageBackground(): string {
    return this._data.incomingMessageBackground;
  }

  public set incomingMessageBackground(value: string) {
    this._data.incomingMessageBackground = value;
  }

  public get incomingMessageForeground(): string {
    return this._data.incomingMessageForeground;
  }

  public set incomingMessageForeground(value: string) {
    this._data.incomingMessageForeground = value;
  }

  public get isDisabled(): boolean {
    return !!this._data.isDisabled;
  }

  public set isDisabled(value: boolean) {
    this._data.isDisabled = !!value;
  }

  public get isTriggerHidden(): boolean {
    return this._data.isTriggerHidden;
  }

  public set isTriggerHidden(value: boolean) {
    this._data.isTriggerHidden = value;
  }

  public get lastAgentNotice(): string {
    return this._data.lastAgentNotice;
  }

  public set lastAgentNotice(value: string) {
    this._data.lastAgentNotice = value;
  }

  public get maximumQueueSize(): number {
    return this._data.maximumQueueSize;
  }

  public set maximumQueueSize(value: number) {
    this._data.maximumQueueSize = value;
  }

  public get maximumWaitingTime(): number {
    return this._data.maximumWaitingTime;
  }

  public set maximumWaitingTime(value: number) {
    this._data.maximumWaitingTime = value;
  }

  public get name(): string {
    return this._data.name;
  }

  public set name(value: string) {
    this._data.name = value;
  }

  public get namePrompt(): string {
    return this._data.namePrompt;
  }

  public set namePrompt(value: string) {
    this._data.namePrompt = value;
  }

  public get outgoingMessageBackground(): string {
    return this._data.outgoingMessageBackground;
  }

  public set outgoingMessageBackground(value: string) {
    this._data.outgoingMessageBackground = value;
  }

  public get outgoingMessageForeground(): string {
    return this._data.outgoingMessageForeground;
  }

  public set outgoingMessageForeground(value: string) {
    this._data.outgoingMessageForeground = value;
  }

  public get position(): string {
    return this._data.position;
  }

  public set position(value: string) {
    this._data.position = value;
  }

  public get primaryColour(): string {
    return this._data.primaryColour;
  }

  public set primaryColour(value: string) {
    this._data.primaryColour = value;
  }

  public get publicKey(): string {
    return this._data.publicKey;
  }

  public set publicKey(value: string) {
    this._data.publicKey = value;
  }

  public get queueGroup(): number | null {
    if (!this._data.queueGroup) {
      return null;
    }

    return Number(this._data.queueGroup);
  }

  public set queueGroup(value: number | null) {
    this._data.queueGroup = !value ? null : Number(value);
  }

  public get resolutionGracePeriod(): number {
    return this._data.resolutionGracePeriod;
  }

  public set resolutionGracePeriod(value: number) {
    this._data.resolutionGracePeriod = value;
  }

  public get resolutionNoticeMessage(): string {
    return this._data.resolutionNoticeMessage;
  }

  public set resolutionNoticeMessage(value: string) {
    this._data.resolutionNoticeMessage = value;
  }

  public get showEmailAddress(): boolean {
    return !!this._data.showEmailAddress;
  }

  public set showEmailAddress(value: boolean) {
    this._data.showEmailAddress = value;
  }

  public get showEstimatedWaitingTime(): boolean {
    return this._data.showEstimatedWaitingTime;
  }

  public set showEstimatedWaitingTime(value: boolean) {
    this._data.showEstimatedWaitingTime = value;
  }

  public get startPrompt(): string {
    return this._data.startPrompt;
  }

  public set startPrompt(value: string) {
    this._data.startPrompt = value;
  }

  public get useContactLink(): boolean {
    return this._data.useContactLink;
  }

  public set useContactLink(value: boolean) {
    this._data.useContactLink = value;
  }

  public get visitorMessagesPosition(): string {
    return this._data.visitorMessagesPosition;
  }

  public set visitorMessagesPosition(value: string) {
    this._data.visitorMessagesPosition = value;
  }

  public get welcomeMessage(): string {
    return this._data.welcomeMessage;
  }

  public set welcomeMessage(value: string) {
    this._data.welcomeMessage = value;
  }

  public get welcomeTitle(): string {
    return this._data.welcomeTitle;
  }

  public set welcomeTitle(value: string) {
    this._data.welcomeTitle = value;
  }

  public get widgetHorizontalPadding(): number {
    return this._data.widgetHorizontalPadding;
  }

  public set widgetHorizontalPadding(value: number) {
    this._data.widgetHorizontalPadding = value;
  }

  public get widgetVerticalPadding(): number {
    return this._data.widgetVerticalPadding;
  }

  public set widgetVerticalPadding(value: number) {
    this._data.widgetVerticalPadding = value;
  }

  public get widgetTitle(): string {
    return this._data.widgetTitle;
  }

  public set widgetTitle(value: string) {
    this._data.widgetTitle = value;
  }

  public get welcomeFormFields() {
    return this._data.welcomeFormFields;
  }
}
