import './content-calendar-nav.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { addDays, endOfWeek, startOfWeek } from 'date-fns';

import { Activity, Outbox } from '@ui-resources-angular';
import { Filter } from '../../../../../common/components/filters';
import { CalendarView } from '../../../../../common/components/calendar';
import { DropdownMenuItem } from '../../../../../common/components/dropdown-menu/dropdown-menu.component';
import moment from 'moment';
import { WEEK_STARTS_ON } from '../../../../../common/constants';

@Component({
  selector: 'ssi-content-calendar-nav',
  templateUrl: './content-calendar-nav.component.html',
  styles: []
})
export class ContentCalendarNavComponent implements OnInit, OnChanges {
  // @Input() post: Outbox;
  @Input() view: CalendarView = CalendarView.Day;
  @Input() viewDate: Date = new Date();
  @Input() totalPostsCountLabel = '';
  @Input() activeFilters: Filter[] = [];

  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() viewDateChange = new EventEmitter<Date>();
  @Output() createPostClicked = new EventEmitter();
  @Output() createEventClicked = new EventEmitter();
  @Output() filtersShow = new EventEmitter<void>();
  @Output() toggleCampaignsClicked = new EventEmitter();

  CalendarView = CalendarView;
  weekPeriod = '';
  campaignsToggled = false;

  timeslotMenuItems: DropdownMenuItem[] = [
    {
      iconClass: 'ssi ssi-compose-post',
      label: 'Create Post',
      action: () => this.createPostClicked.emit()
    },
    {
      iconClass: 'ssi ssi-calendar-event',
      label: 'Calendar Event',
      action: () => this.createEventClicked.emit()
    }
  ];

  constructor(protected datePipe: DatePipe) {}

  ngOnInit() {}

  toggleCampaigns() {
    this.campaignsToggled = !this.campaignsToggled;
    this.toggleCampaignsClicked.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['view'] || changes['viewDate']) &&
      this.viewDate &&
      this.view === CalendarView.Week
    ) {
      const startDate = startOfWeek(this.viewDate, {
        weekStartsOn: WEEK_STARTS_ON
      });
      const endDate = endOfWeek(this.viewDate, {
        weekStartsOn: WEEK_STARTS_ON
      });

      const isSameMonth = moment(startDate).isSame(endDate, 'month');

      const startTransformed = this.datePipe.transform(startDate, 'dd');
      const endTransformed = this.datePipe.transform(endDate, 'dd MMM, y');

      const startTransformedSameMonth = this.datePipe.transform(
        startDate,
        'dd MMM, y'
      );
      const endTransformedSameMonth = this.datePipe.transform(
        endDate,
        'dd MMM, y'
      );

      this.weekPeriod = isSameMonth
        ? `${startTransformed} - ${endTransformed}`
        : `${startTransformedSameMonth} - ${endTransformedSameMonth}`;
    }
  }
}
