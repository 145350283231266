import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { IconModule } from '../../../../../../common/components/icon/icon.module';

import { SentimentGaugeComponent } from './sentiment-gauge.component';
import { HighchartModule } from '../../../../../../common/components/highchart/highchart.module';

import HCSolidGauge from 'highcharts/modules/solid-gauge';
import * as Highcharts from 'highcharts/highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

import HC_solidgauge from 'highcharts/modules/solid-gauge';
HC_solidgauge(Highcharts);

@NgModule({
  declarations: [SentimentGaugeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    TooltipModule,
    IconModule,
    HighchartModule
  ],
  exports: [SentimentGaugeComponent]
})
export class SentimentGaugeModule {}
