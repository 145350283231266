import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import {
  AccountModel,
  Account,
  OutboxModel,
  Outbox,
  UserModel,
  User
} from '@ui-resources-angular';
import {
  ApiService,
  ColleaguesService,
  Colleague
} from '../../../../common/services/api';
import { HighchartsHelperService } from '../../../../common/services/highcharts-helper/highcharts-helper.service';
import { ReportSpreadsheetTransformersService } from '../../../../common/services/report-spreadsheet-transformers/report-spreadsheet-transformers.service';
import { groupBy, mapToIterable } from '../../../../common/utils';
import {
  NetworkConst,
  networks,
  NetworksConst,
  networksIterable,
  sentimentsIterable
} from '../../../../common/constants';
import { LinkClicksData } from '../analytics.service';

export interface DateRange {
  start: Date;
  end: Date;
}
export interface DateRanges {
  current: DateRange;
  previous: DateRange;
}

export interface SentimentTotals {
  positive: number;
  semi_positive: number;
  neutral: number;
  semi_negative: number;
  negative: number;
}

export interface Totals {
  connections: number;
  female_connections: number;
  male_connections: number;

  outbox_engagement_rate: number;
  klout_score: number;

  messages_in: number;
  messages_out: number;

  total_clicks: number;
  total_clicks_es: number;
  outbox_engagement: number;

  account_impressions: number;
  outbox_impressions: number;
  account_reach: number;
  outbox_reach: number;

  positive_sentiment: number;
  semi_positive_sentiment: number;
  neutral_sentiment: number;
  semi_negative_sentiment: number;
  negative_sentiment: number;

  /* formatted for 'Brand Sentiment' section */
  sentiments: SentimentTotals;
}

export interface RejectedPostsReportResponse {
  tags_by_account: { account_id: string; name: string; count: number }[];
  tags_by_user: { user_id: string; name: string; count: number }[];
  totals: {
    count_approved: number;
    count_currently_require_validation: number;
    count_never_required_validation: number;
    count_rejected: number;
  };
  rejected_posts_by_account: {
    account_id: string;
    account_type_id: string;
    count: number;
  }[];
}

export interface DisapprovalsData {
  totalsByAccount: {
    account: Account;
    count: number;
  }[];
  allTotals: { count: number };
  totalsByAccountType: any;
  validationsTagNames: string[];
  validationsTagsTotals: { name: string; count: number }[];
  totalsByAccountTypeIterable: any[];
}

@Injectable({ providedIn: 'root' })
export class DisapprovalsService {
  endpoint = `${this.api.url}/outbox/rejectedPostsReport`;
  networksConst: NetworksConst = networks;
  networksConstIterable: NetworkConst[] = networksIterable;
  colleagues: Colleague[];

  constructor(
    protected injector: Injector,
    protected api: ApiService,
    protected translate: TranslateService,
    protected outboxModel: OutboxModel,
    protected accountModel: AccountModel,
    protected userModel: UserModel,
    protected highchartsHelper: HighchartsHelperService,
    protected reportSpreadsheetTransformers: ReportSpreadsheetTransformersService,
    private colleaguesService: ColleaguesService
  ) {}

  async loadTotals(
    accountIds: string[],
    dateRanges: DateRanges,
    tags: { include_tags: string[]; exclude_tags: string[] }
  ): Promise<DisapprovalsData> {
    this.colleagues = await this.colleaguesService.getAllActive();
    const response = await this.api
      .post(this.endpoint, {
        account_ids: accountIds,
        start_date: dateRanges.current.start,
        end_date: dateRanges.current.end,
        include_tags: tags.include_tags,
        exclude_tags: tags.exclude_tags
      })
      .pipe(
        map((res: RejectedPostsReportResponse) => {
          return res;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();

    const data: {
      totalsByAccount: {
        account: Account;
        count: number;
      }[];
      totalsByUser: {
        user: Colleague;
      }[];
      allTotals: { count: number; totals: any };
      totalsByAccountType: any;
      validationsTagNames: string[];
      validationsTagsTotals: { name: string; count: number }[];
      totalsByAccountTypeIterable: any[];
    } = {
      totalsByAccount: response.rejected_posts_by_account.map(
        (currentAccountTotals) => {
          const accountId = +currentAccountTotals.account_id;
          return {
            account: this.accountModel.get(accountId),
            count: currentAccountTotals.count
          };
        }
      ),
      totalsByUser: response.tags_by_user
        .map((userTotal) => {
          if (userTotal.user_id) {
            return {
              user: this.colleagues.find(({ id }) => userTotal.user_id === id)
            };
          } else {
            return null;
          }
        })
        .filter((n) => n),
      allTotals: {
        count: 0,
        totals: {}
      },
      totalsByAccountType: {},
      validationsTagNames: [],
      validationsTagsTotals: [],
      totalsByAccountTypeIterable: []
    };

    const validationsTagNames = [
      ...new Set(response.tags_by_account.map((t) => t.name))
    ];

    data.allTotals.totals = response.totals;

    data.totalsByAccount.forEach((accStats) => {
      response.tags_by_account.forEach((tagObj) => {
        if (tagObj.account_id === accStats.account.id) {
          accStats[tagObj.name] = tagObj.count;
        }
      });
    });
    data.totalsByAccount.sort((a: any, b: any) => {
      return a.account.account_type_id - b.account.account_type_id;
    });

    data.totalsByUser = Array.from(
      new Set(data.totalsByUser.map((a) => a.user.id))
    ).map((id) => {
      return data.totalsByUser.find((a) => a.user.id === id);
    });

    data.totalsByUser.forEach((userStats) => {
      response.tags_by_user.forEach((tagObj) => {
        if (tagObj.user_id === userStats.user.id) {
          userStats[tagObj.name] = tagObj.count;
        }
      });
    });

    const groupes = groupBy(
      data.totalsByAccount,
      (item) => item.account.account_type_name
    );

    groupes.forEach((group) => {
      const accountTypeId = group[0].account.account_type_id;
      const accountTypeName = group[0].account.account_type_name;

      data.totalsByAccountType[accountTypeName] = group.reduce(
        (totalsSummed, accountTotals) => {
          validationsTagNames.forEach((tagName) => {
            if (accountTotals[tagName]) {
              totalsSummed[tagName] =
                (totalsSummed[tagName] || 0) + (accountTotals[tagName] || 0);
            }
          });

          data.allTotals.count += accountTotals.count || 0;
          totalsSummed.account = accountTotals.account;

          totalsSummed.disapprovals = totalsSummed.disapprovals || 0;
          totalsSummed.disapprovals += accountTotals.count;

          totalsSummed.totalAccounts = totalsSummed.totalAccounts || 0;
          totalsSummed.totalAccounts++;

          return totalsSummed;
        },
        {}
      );
    });

    data.validationsTagNames = validationsTagNames;

    const validationsTagsTotals = [];
    response.tags_by_account.reduce(function (res, value) {
      if (!res[value.name]) {
        res[value.name] = { name: value.name, count: 0 };
        validationsTagsTotals.push(res[value.name]);
      }
      res[value.name].count += value.count;
      return res;
    }, {});
    data.validationsTagsTotals = validationsTagsTotals;

    return data;
  }
}
