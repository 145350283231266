import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { BulkUploadComponent } from './bulk-upload.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from '../../../../common/components/progress-bar/progress-bar.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { SocialPostCharactersRemainingPipe } from '../../../../common/pipes/social-post-characters-remaining/social-post-characters-remaining.pipe';
import { FileDownloadModule } from '../../../../common/directives/file-download/file-download.module';
import { FileUploaderModule } from '../../../../common/directives/file-uploader/file-uploader.module';
import { EnlargeImageModule } from '../../../../common/directives/enlarge-image/enlarge-image.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.bulkUpload',
          url: '/bulk-upload?accounts',
          component: BulkUploadComponent,
          resolve: BulkUploadComponent.resolve
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    ProgressBarModule,
    FileDownloadModule,
    FileUploaderModule,
    EnlargeImageModule,
    TooltipModule
  ],
  declarations: [BulkUploadComponent],
  providers: [SocialPostCharactersRemainingPipe],
  entryComponents: [BulkUploadComponent]
})
export class BulkUploadModule {}
