import { HttpAdapter } from 'js-data-fetch';

export class API extends HttpAdapter {
  basePath = '';

  httpConfig: any;

  http: any;

  log: any;

  error: any;

  get<T = any>(url: string, config?: any): Promise<T> {
    const reqUrl = config && config.skipBasePath ? url : this.basePath + url;
    return this.GET(reqUrl, config);
  }

  post<T = any>(url: string, data?: any, config?: any, opts?: any): Promise<T> {
    return this.POST(this.basePath + url, data, config, opts);
  }

  put<T = any>(url: string, data?: any, config?: any, opts?: any): Promise<T> {
    return this.PUT(this.basePath + url, data, config, opts);
  }

  del<T = any>(url: string, config?: any, opts?: any): Promise<T> {
    return this.DEL(this.basePath + url, config, opts);
  }

  delete<T = any>(url: string, config?: any, opts?: any): Promise<T> {
    return this.DEL(this.basePath + url, config, opts);
  }

  DEL(...args): any {
    return super['DEL'](...args);
  }

  GET(...args): any {
    return super['GET'](...args);
  }

  POST(...args): any {
    return super['POST'](...args);
  }

  PUT(...args): any {
    return super['PUT'](...args);
  }
}

export const api = new API();
