import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-stat-card-icon',
  templateUrl: './stat-card-icon.component.html',
  providers: []
})
export class StatCardIconComponent {
  @Input() currentTotal: number;
  @Input() previousTotal: number;
  @Input() iconClass: string;
  @Input() iconBackgroundClass: string;
  @Input() labelTranslateKey: string;
  @Input() time?: boolean;
  @Input() totalChangeRound?: boolean;
  @Input() totalChangeLowerIsPositive?: boolean;

  constructor() {}
}
