import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PostBoxComponent } from './post-box.component';
import { SocialNetworkIconModule } from '../../../../../../../common/components/social-network-icon/social-network-icon.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { PostModalModule } from '../post-modal/post-modal.module';
import { UIRouterModule } from '@uirouter/angular';
import { FallbackImageModule } from '../../../../../../../common/directives/fallback-image/fallback-image.module';
import { TooltipModule } from '../../../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  declarations: [PostBoxComponent],
  imports: [
    CommonModule,
    NgbModule,
    SocialNetworkIconModule,
    OverlayModule,
    PostModalModule,
    UIRouterModule,
    FallbackImageModule,
    TooltipModule
  ],
  exports: [PostBoxComponent]
})
export class PostBoxModule {}
