import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { SurveySettingsComponent } from './survey-settings.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { ToggleSwitchModule } from '../../../../../common/components/toggle-switch/toggle-switch.module';
import { TooltipModule } from '../../../../../../angular/common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../../common/directives/off-click/off-click.module';
import { CheckboxModule } from '../../../../../common/components/checkbox/checkbox.module';
import { ConfirmActionModule } from '../../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../common/components/social-network-icon/social-network-icon.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.surveySettings',
          url: '/survey-settings',
          component: SurveySettingsComponent,
          resolve: SurveySettingsComponent.resolve
        }
      ]
    }),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AlertsModule,
    ToggleSwitchModule,
    TooltipModule,
    OffClickModule,
    CheckboxModule,
    ConfirmActionModule,
    DropdownSelect2Module,
    SocialNetworkIconModule
  ],
  declarations: [SurveySettingsComponent],
  entryComponents: [SurveySettingsComponent]
})
export class SurveySettingsModule {}
