import './edit-file-modal.component.scss';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { KeyValueObject } from '../../../../../common/util';

@Component({
  selector: 'ssi-edit-file-modal',
  templateUrl: './edit-file-modal.component.html',
  styles: []
})
export class EditFileModalComponent implements OnInit {
  @Input() resolve: { file: { name: string; metadata: KeyValueObject } };
  @ViewChild('editFileForm') editFileForm: NgForm;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
