import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatLineComponent } from './stat-line.component';

@NgModule({
  imports: [CommonModule],
  declarations: [StatLineComponent],
  exports: [StatLineComponent]
})
export class StatLineModule {}
