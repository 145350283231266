import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CampaignComponent } from './campaign.component';
import { CampaignListComponent } from './build/campaign-list/campaign-list.component';
import { CampaignBuildComponent } from './build/campaign-build/campaign-build.component';
import { CampaignReportComponent } from './report/campaign-report.component';
import { ReportHeaderModule } from './../common/report-header/report-header.module';
import { ReportSidebarModule } from './../common/report-sidebar/report-sidebar.module';
import { CampaignAnalyticsService } from './campaign-analytics.service';
import { FileDownloadModule } from '../../../../common/directives/file-download/file-download.module';
import { FactOverviewModule } from '../common/fact-overview/fact-overview.module';
import { ProgressBarModule } from '../../../../common/components/progress-bar/progress-bar.module';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';
import { FilterModule } from '../../../../common/pipes/filter/filter.module';
import { PostsBreakdownComponent } from './report/posts-breakdown/posts-breakdown.component';
import { AccountSummaryCardModule } from '../common/account-summary-card/account-summary-card.module';
import { SocialNetworkSelectModule } from '../common/social-network-select/social-network-select.module';
import { TreetableModule } from '../../../../common/components/treetable';
import { NoResultsModule } from '../../../../common/components/no-results/no-results.module';
import { LoaderModule } from '../../../../common/directives/loader';
import { OrloComponentsModule } from 'orlo-components';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { Checkbox2Module } from '../../../../common/components/checkbox-2/checkbox-2.module';
import { TemplateSelectorModule } from '../../../../common/directives/template-selector/template-selector.module';
import { VarModule } from '../../../../common/directives/var/var.module';
import { MenuItemModule } from '../common/menu-item/menu-item.module';
import { ConfirmActionModule } from '../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { FocusOnModule } from '../../../../common/directives/focus-on/focus-on.module';
import { MapModule } from '../../../../common/components/map/map.module';
import { SortByModule } from '../../../../common/pipes/sort-by/sort-by.module';
import { LinkClicksSectionModule } from '../common/link-clicks-section/link-clicks-section.module';
import { TopPostListModule } from '../common/top-post-list/top-post-list.module';
import { TopPostsSectionComponent } from './report/top-posts-section/top-posts-section.component';
import { InstagramStoryStatsModule } from '../common/instagram-story-stats/instagram-story-stats.module';
import { VideoMetricsModule } from '../common/video-metrics/video-metrics.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.campaign',
          url: '/campaign',
          component: CampaignComponent
        },
        {
          name: 'auth.analytics.campaign.index',
          url: '/overview',
          component: CampaignListComponent
        },
        {
          name: 'auth.analytics.campaign.build',
          url:
            '/build/:campaignId?name&startDate&endDate&dateRange&tagIdsToInclude&tagIdsToExclude&includeDeleted',
          component: CampaignBuildComponent
        },
        {
          name: 'auth.analytics.campaign.edit',
          url: '/edit/:campaignId',
          component: CampaignBuildComponent
        },
        {
          name: 'auth.analytics.campaign.report',
          url:
            '/report?:campaignId&campaignName&startDate&endDate&dateRange&tagIdsToInclude&tagIdsToExclude&includeDeleted&disablePopup&frequency',
          component: CampaignReportComponent,
          resolve: CampaignReportComponent.resolve
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    ReportHeaderModule,
    ReportSidebarModule,
    FileDownloadModule,
    FactOverviewModule,
    ProgressBarModule,
    BigNumberModule,
    HighchartModule,
    FilterModule,
    AccountSummaryCardModule,
    SocialNetworkSelectModule,
    TreetableModule,
    LoaderModule,
    OrloComponentsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlatpickrModule,
    VarModule,
    TemplateSelectorModule,
    DropdownSelect2Module,
    Checkbox2Module,
    MenuItemModule,
    ConfirmActionModule,
    TooltipModule,
    DropdownSelect2Module,
    OffClickModule,
    FocusOnModule,
    SortByModule,
    LinkClicksSectionModule,
    TopPostListModule,
    NoResultsModule,
    InstagramStoryStatsModule,
    VideoMetricsModule
  ],
  declarations: [
    CampaignComponent,
    CampaignListComponent,
    CampaignReportComponent,
    CampaignBuildComponent,
    PostsBreakdownComponent,
    TopPostsSectionComponent
  ],
  entryComponents: [CampaignComponent],
  providers: [CampaignAnalyticsService, DatePipe]
})
export class CampaignModule {}
