import { DataStore } from 'js-data';

export const services: {
  store: DataStore;
  models: {
    _items: any;
    get<ModelType>(name: string): ModelType;
    set<ModelType>(name: string, model: ModelType);
    reset(): void;
  };
} = {
  // set by angular on each run. Can't use singletons because it breaks tests as state in stored between tests
  store: undefined,
  models: {
    _items: {},
    get<ModelType>(name: string): ModelType {
      return this._items[name];
    },
    set<ModelType>(name: string, model: ModelType): void {
      this._items[name] = model;
    },
    reset() {
      this._items = {};
    }
  }
};

export const mapperDefaults = {
  notify: false,
  keepChangeHistory: false
};
