import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidateReplyBoxContentComponent } from './validate-reply-box-content.component';
import { TriggerPublishModule } from '../../../../../common/directives/trigger-publish/trigger-publish.module';
import { LinkifyModule } from '../../../../../common/pipes/linkify/linkify.module';
import { FallbackImageModule } from '../../../../../common/directives/fallback-image/fallback-image.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReplyBoxModule } from '../../../../../common/components/reply-box/reply-box.module';

@NgModule({
  imports: [
    CommonModule,
    TriggerPublishModule,
    LinkifyModule,
    FallbackImageModule,
    TranslateModule,
    ReplyBoxModule
  ],
  declarations: [ValidateReplyBoxContentComponent],
  exports: [ValidateReplyBoxContentComponent]
})
export class ValidateReplyBoxContentModule {}
