import './cl-dropdown-select-d.component.scss';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import { Account, AccountModel } from '@ui-resources-angular';
import { groupBy } from '../../../../../common/utils';
import { AccountTypeIdLabelMap } from '../../../../../common/enums';
import { DropdownSelect2Component } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.component';

@Component({
  selector: 'ssi-cl-dropdown-select-d',
  templateUrl: './cl-dropdown-select-d.component.html',
  styles: []
})
export class ClDropdownSelectDComponent implements OnInit {
  @Input() accounts: Account[] = [];

  @ViewChild('chipsContainer') chipsContainer: ElementRef;
  @ViewChild(DropdownSelect2Component)
  dropdownComponent: DropdownSelect2Component;

  maxVisibleChipsCount = 999;
  chipsContainerWidth = 460; // to be determined below based on the dropdown width
  viewMoreElementWidth = 110;
  marginOffset = 5; // margin between chips

  selectedAccounts: Account[] = [];
  // selectedAccountTypeIds: string[] = [];

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}

  selectedAccountsChanged(selectedAccounts: Account[]): void {
    console.log('selectedAccounts: ', selectedAccounts);
    this.selectedAccounts = selectedAccounts;
  }

  onDropdownWidthChange(width: string): void {
    this.chipsContainerWidth = parseFloat(width) - this.viewMoreElementWidth;
    if (this.selectedAccounts.length && !this.dropdownComponent.expanded) {
      this.setMaxVisibleChipsCount();
    }
  }

  setMaxVisibleChipsCount(): number {
    if (!this.chipsContainer) {
      // no selected accounts
      return;
    }

    this.maxVisibleChipsCount = 999;
    this.changeDetectorRef.detectChanges();

    const chips = this.chipsContainer.nativeElement.children;

    let i = 0;
    let takenWidth = 0;
    while (!!chips[i] && takenWidth <= this.chipsContainerWidth) {
      const chipWidth = parseFloat(getComputedStyle(chips[i]).width);
      takenWidth += chipWidth + this.marginOffset;
      i++;
    }

    if (takenWidth > this.chipsContainerWidth) {
      this.maxVisibleChipsCount = i - 1;
    } else {
      this.maxVisibleChipsCount = i;
    }

    this.changeDetectorRef.detectChanges();
  }

  onDropdownToggled(visible: boolean): void {
    if (!visible) {
      this.setMaxVisibleChipsCount();
    }
  }

  getGroupLabel = (accountTypeId: string): string => {
    return AccountTypeIdLabelMap[accountTypeId];
  };

  deselectAll(): void {
    this.selectedAccounts = [];
    this.selectedAccountsChanged(this.selectedAccounts);
  }

  deselectAccount(account: Account): void {
    this.selectedAccounts = this.selectedAccounts.filter(
      (selectedAccount) => selectedAccount !== account
    );
    this.selectedAccountsChanged(this.selectedAccounts);
  }
}
