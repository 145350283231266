<div class="dashboard-create-container no-widgets"
     *ngIf="!hasWidgets">
  <div class="create-card">
    <i class="ssi ssi-dash-create create-card-icon"></i>
    <h2>Your new Dashboard!</h2>
    <p>Start by adding ready made dashboard widgets</p>
    <button class="g-btn round-icon-only-button create-card-button"
            (click)="openAddWidgetModal()">
      <i class="ssi ssi-addsmall"></i>
    </button>

    <ng-container *ngIf="dashboardTemplates.length">
      <p class="g-margin-top-40">Or create from template</p>
      <ng-container *ngTemplateOutlet="dashboardTemplatesMenuTemplate"></ng-container>
    </ng-container>
  </div>
</div>

<div class="dashboard-create-container has-widgets"
     *ngIf="hasWidgets">
  <ssi-dashboard-navbar [dashboardName]="dashboardToCreate.name"
                        [mode]="'create'"
                        (dashboardNameChange)="dashboardToCreate.name = $event"
                        (widgetAdd)="openAddWidgetModal()"></ssi-dashboard-navbar>

  <div class="dashboard-content">
    <div class="actions-bar">
      <div class="left">
        <ng-container *ngIf="dashboardTemplates.length">
          <ng-container *ngTemplateOutlet="dashboardTemplatesMenuTemplate"></ng-container>
        </ng-container>
      </div>
      <div class="right">
        <button type="button"
                class="g-btn g-btn-secondary bg-white cancel-btn"
                (click)="cancel()">Cancel</button>
        <button *ngIf="authUser?.hasCompanyPermission('administer_company')"
                type="button"
                [disabled]="!dashboardToCreate.name"
                class="g-btn g-btn-secondary bg-white save-as-template-btn"
                (click)="saveAsTemplate()">Create {{selectedDashboardTemplate ? 'new' : ''}} dashboard template</button>
        <button type="button"
                [disabled]="!dashboardToCreate.name"
                class="g-btn save-btn"
                (click)="save()">Create dashboard</button>
      </div>
    </div>

    <ssi-widgets-grid #widgetsGrid
                      [apiWidgets]="dashboardToCreate.widgets"
                      (onEditWidget)="editWidget($event)"></ssi-widgets-grid>
  </div>
</div>

<ng-template #dashboardTemplatesMenuTemplate>
  <ssi-menu #templatesMenu
            class="dashboard-templates-menu"
            [headAlignment]="'top left'"
            [bodyAlignment]="'top left'">
    <ng-template ssiTemplateSelector="menuHead">
      <button type="button"
              class="g-btn g-btn-secondary bg-white templates-button">
        <i class="ssi ssi-dash-create"></i>
        <span class="head-button-label">{{selectedDashboardTemplate ? selectedDashboardTemplate.name : 'Dashboard templates'}}</span>
        <i class="ssi ssi-arrow-down-new templates-select-arrow-icon"></i></button>
    </ng-template>
    <ng-template ssiTemplateSelector="menuBody">
      <ul class="g-list">
        <li class="g-list-item templates-list-item"
            *ngFor="let template of dashboardTemplates"
            (click)="templatesMenu.close(); applyTemplate(template)">
          <span>{{template.name}}</span>
          <i *ngIf="authUser?.hasCompanyPermission('administer_company')"
             class="ssi ssi-small-delete delete-icon"
             (click)="$event.stopPropagation(); deleteTemplate(template);"></i>
        </li>
      </ul>
    </ng-template>
  </ssi-menu>
</ng-template>