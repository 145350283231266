import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import { Currency, ServerService } from '../server/server.service';
import { MONITORING_SOURCES } from '../../constants';

const configCache = Symbol('company config cache');
const officeHoursCache = Symbol('company office hours cache');
const inboxSnippetsCache = Symbol('company inbox snippets cache');

export interface CompanyPreferences {
  company_preferences: {
    all_users_can_create_tags: string;
    can_login_with_google: string;
    can_login_with_okta: string;
    can_login_with_password: string;
    custom_calendar_events: string;
    dashboard_templates: string;
    links_show_policy: string;
    require_strong_password: string;
    shortening_url: string;
    two_factor: string;
    engagement_report_slas: string;
    alt_text_required: string;
    exclude_last_out_of_hours: string;
    can_silence_inbox_threads: string;
  };
  facebook_settings: {
    delete_offensive_posts: string;
  };
  user_settings: {
    social_policy: string;
  };
}

export interface CompanyConfig {
  currency_code: string;
  feature_flags: string[];
  locked_by: string | null;
  monitoring: {
    premium_sources: boolean;
    version: number;
  };
  only_use_managed_files: boolean;
  use_utm_link_tracking: boolean;
  use_link_shortening: boolean;
}

@Injectable()
export class CompanyService {
  constructor(private api: API, private server: ServerService) {}

  getConfig(): Promise<CompanyConfig> {
    if (!this[configCache]) {
      this[configCache] = this.api
        .get('company/config')
        .then(({ data }) => data);
    }

    return this[configCache].then((config) => {
      config = Object.assign({}, config);
      if (config.monitoring.premium_sources) {
        MONITORING_SOURCES.forEach((source) => {
          if (
            source.key.startsWith('moreover_') &&
            source.key !== 'Google' &&
            source.key !== 'moreover_broadcast'
          ) {
            source.selectable = true;
          }
        });
      }
      return config;
    });
  }

  async saveConfig(config: Partial<CompanyConfig>) {
    await this.api.post('company/config', config);
    delete this[configCache];
  }

  async getCurrency(): Promise<Currency> {
    try {
      const [companyConfig, currencies] = await Promise.all([
        this.getConfig(),
        this.server.getSettings().then(({ currency }) => currency)
      ]);

      if (!(!!currencies && Array.isArray(currencies))) {
        throw new Error(
          `Value for 'company currencies' not in expected format.`
        );
      }

      return currencies.find(
        (iCurrency) => iCurrency.code === companyConfig.currency_code
      );
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  async getOfficeHours() {
    if (!this[officeHoursCache]) {
      this[officeHoursCache] = this.api
        .get('company/officeHours')
        .then(({ data }) => data);
    }
    const officeHours = await this[officeHoursCache];
    return { ...officeHours };
  }

  async saveOfficeHours(officeHours) {
    await this.api.post('company/officeHours', officeHours);
    delete this[officeHoursCache];
  }

  async lock() {
    await this.api.post('settings/lockdown', {});
    delete this[configCache];
  }

  async unlock() {
    await this.api.del('settings/lockdown');
    delete this[configCache];
  }

  async hasFeatureAccess(featureName): Promise<boolean> {
    const config = await this.getConfig();
    return config.feature_flags.includes(featureName);
  }

  async getInboxSnippets() {
    if (!this[inboxSnippetsCache]) {
      this[inboxSnippetsCache] = this.api
        .get('company/snippet')
        .then(({ data }) => data.data);
    }
    const snippets = await this[inboxSnippetsCache];
    return [...snippets];
  }

  async createInboxSnippet(title: string, body: string) {
    const { data } = await this.api.post('company/snippet', { title, body });
    this.clearInboxSnippetsCache();
    return data;
  }

  async deleteInboxSnippet(snippet) {
    await this.api.del('company/snippet', { params: { id: snippet.id } });
    this.clearInboxSnippetsCache();
  }

  clearInboxSnippetsCache() {
    delete this[inboxSnippetsCache];
  }

  getPreferences(): Promise<CompanyPreferences> {
    return this.api.get('settings/companyPreference').then(({ data }) => data);
  }
}
