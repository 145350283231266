import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IndexComponent } from './index.component';
import { CampaignBoxModule } from './campaign-box/campaign-box.module';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { HighchartControlsModule } from './highchart-controls/highchart-controls.module';
import { CampaignAddBoxModule } from '../campaign-add-box/campaign-add-box.module';
import { StatLineModule } from '../../../../common/components/stat-line/stat-line.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.index',
          url: '/overview?query',
          component: IndexComponent,
          resolve: IndexComponent.resolve,
          params: {
            query: {
              dynamic: true
            }
          }
        }
      ]
    }),
    BigNumberModule,
    HighchartModule,
    HighchartControlsModule,
    TooltipModule,
    CampaignBoxModule,
    CampaignAddBoxModule,
    StatLineModule
  ],
  declarations: [IndexComponent],
  entryComponents: [IndexComponent]
})
export class IndexModule {}
