<div
	class="checkbox-container"
	[class.active]="checked"
>
	<label [for]="id">
		<i
			class="leading-icon"
			*ngIf="leadingIcon"
			[ngClass]="leadingIcon"
		></i>
		<span>{{checkboxText}}</span>
		<input
			#checkboxRef
			type="checkbox"
			[checked]="isChecked"
			(change)="onChanged($event)"
			(blur)="onBlur($event)"
			name="name"
			class="checkbox-input"
			[id]="id"
			[ngClass]="{'disabled' : isDisabled, 'is-checked' : isChecked}"
			[value]="isChecked"
		>
		<i class="check-icon ssi ssi-tick-small"></i>
	</label>

</div>