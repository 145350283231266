import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContextMenuComponent } from './context-menu.component';
import { OffClickModule } from '../../directives/off-click/off-click.module';

@NgModule({
  imports: [CommonModule, OffClickModule],
  exports: [ContextMenuComponent, OffClickModule],
  declarations: [ContextMenuComponent],
  entryComponents: [ContextMenuComponent]
})
export class ContextMenuModule {}
