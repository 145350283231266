import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizedHtmlPipe } from './sanitized.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SanitizedHtmlPipe],
  exports: [SanitizedHtmlPipe]
})
export class SanitizedHtmlModule {}
