import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[ssiOnDomEventName]'
})
export class OnDomEventDirective implements OnInit {
  @Input('ssiOnDomEventName') eventName: string;

  @Output() onDomEvent = new EventEmitter();

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  ngOnInit(): void {
    this.renderer.listen(
      this.element.nativeElement,
      this.eventName,
      this.handleDomEvent
    );
  }

  handleDomEvent = () => {
    this.onDomEvent.emit();
  };
}
