<div
  *ngIf="! isNotesScreenVisible"
  (clickOutside)="onBlur()"
  (mouseover)="onFocus()"
  [delayClickOutsideInit]="true"
  class="inner"
>
  <div
    *ngIf="! isLoaded"
    class="container-when-loading"
  >
    <div class="loading"></div>
  </div>

  <div
    *ngIf="!! isLoaded && ! isAssignChatScreenActive"
    [ngClass]="{
      'has-sidebar': !! isSidebarVisible,
      'no-sidebar': ! isSidebarVisible
    }"
    class="container-when-loaded"
  >
    <nav class="conversation-navigation">
      <ul class="agent">
        <li
          [ngbPopover]="'LIVE_CHAT_TEAM' | translate"
          class="unassigned"
          container="body"
          placement="bottom"
          popoverClass="popover-dark"
          triggers="mouseenter mouseleave"
        >
          <button
            (click)="onCloseConversation()"
            [disabled]="!! isInterfaceDisabled"
            type="button"
          >
            <i class="ssi ssi-team"></i>
          </button>
        </li>

        <li
          class="network-state"
        >
          <ssi-live-chat-network-state
            [user]="agent"
            [disabled]="!! isInterfaceDisabled"
            [isTranscludeOnly]="true"
          >
            <i
              class="ssi ssi-new-chat icon-background"
            ></i>
            <i
              class="ssi ssi-status-bubble icon-foreground"
            ></i>
          </ssi-live-chat-network-state>
        </li>
      </ul>

      <ul
        *ngIf="!! conversation"
        class="conversation"
      >
        <li
          class="assign-message"
        >
          <button
            (click)="toggleChatAssignment()"
            [class.unavailable]="! canChangeAssignment"
            *ngIf="!assignOtherAgentsFeature"
            [disabled]="! canChangeAssignment"
            type="button"
          >
            <i
              class="ssi ssi-users-plus button-icon"
            ></i>

            <span
              *ngIf="! conversation.isAssigned"
              class="button-text"
              translate="ASSIGN_CHAT"
            ></span>

            <span
              *ngIf="!! conversation.isAssigned && !! conversation.agent"
              class="button-text"
            >
              {{ conversation.agent.displayName }}
            </span>
          </button>
          <button
            (click)="openAssignAgentModal()"
            [class.unavailable]="! canChangeAssignment"
            *ngIf="assignOtherAgentsFeature"
            [disabled]="! canChangeAssignment"
            type="button"
          >
            <i
              class="ssi ssi-users-plus button-icon"
            ></i>

            <span
              *ngIf="! conversation.isAssigned"
              class="button-text"
              translate="ASSIGN_CHAT"
            ></span>

            <span
              *ngIf="!! conversation.isAssigned && !! conversation.agent"
              class="button-text"
            >
              {{ conversation.agent.displayName }}
            </span>
          </button>
        </li>

        <li class="translate-messages" [class.toggled]="translationMode">
           <button
              class="translate"
              (click)="$event.stopPropagation(); translateMessages()"
              [ngbPopover]="'Translate messages: automatically detect and translate the current chat and provide smart translations for your replies'"
              container="body"
              placement="bottom"
              popoverClass="popover-dark"
              triggers="mouseenter mouseleave"
            >
              <i class="ssi ssi-translate"></i>
           </button>
        </li>

        <li
          *ngIf="!! selectedMessage"
          [ngClass]="{
            active: !! isTagsVisible,
            disabled: !! areTagsUpdating
          }"
          class="tags"
        >
          <button
            (click)="onOpenTags()"
            [disabled]="!! areTagsUpdating"
            [ngbPopover]="'MESSAGE_TAGS' | translate"
            container="body"
            data-pendo="liveChat_clickToAddTagFromTopBar"
            placement="bottom"
            popoverClass="popover-dark"
            triggers="mouseenter mouseleave"
            type="button"
          >
            <i class="ssi ssi-tag-test"></i>
          </button>

          <span
            class="emblem  tags-count"
          >
            {{ selectedMessage.quantityOfTags }}
          </span>
        </li>

        <!--
        <li class="options">
          <ul>

            <li
              [ngClass]="{ active: isDropDownOpen('sentiment') }"
              class="sentiment"
            >
              <button
                (click)="toggleDropdown('sentiment')"
                [ngbPopover]=""
                container="body"
                placement="bottom"
                popoverClass="popover-dark"
                popoverTitle="{{ 'SENTIMENT' | translate }}"
                triggers="mouseenter mouseleave"
                type="button"
              >
                <i class="ssi ssi-semi-positive"></i>
              </button>
            </li>

            <li
              [ngClass]="{ active: isDropDownOpen('language') }"
              class="language"
            >
              <button
                (click)="toggleDropdown('language')"
                [ngbPopover]=""
                container="body"
                placement="bottom"
                popoverClass="popover-dark"
                popoverTitle="{{ 'LANGUAGE' | translate }}"
                triggers="mouseenter mouseleave"
                type="button"
              >
                <i class="ssi ssi-language"></i>
              </button>
            </li>

            <li
              [ngClass]="{ active: isDropDownOpen('email') }"
              class="email"
            >
              <button
                (click)="toggleDropdown('email')"
                [ngbPopover]="'EMAIL_MESSAGE' | translate"
                container="body"
                placement="bottom"
                popoverClass="popover-dark"
                triggers="mouseenter mouseleave"
                type="button"
              >
                <i class="ssi ssi-correct-forward-email"></i>
              </button>
            </li>

          </ul>
        </li>
        -->

        <li
          *ngIf="! isSidebarVisible"
          class="sidebar-toggle"
        >
          <button
            (click)="toggleSidebar()"
            type="button"
          >
            <i class="ssi ssi-closed-crm"></i>
          </button>
        </li>

        <!--
        <li
          class="profile-toggle"
        >
          <button
            (click)="showProfileScreen()"
            type="button"
          >
            <i class="ssi ssi-closed-crm"></i>
          </button>
        </li> -->
      </ul>

      <ssi-live-chat-message-tags
        *ngIf="!! isLoaded && !! isTagsVisible"
        (messageUpdated)="onMessageUpdated($event)"
        [shouldIgnoreHotkeys]="shouldTagsIgnoreHotkeys"
        [message]="selectedMessage"
      ></ssi-live-chat-message-tags>
    </nav>

    <div class="conversation-content">
      <ssi-live-chat-activity-tree
        (isNoteFormFocusedChange)="onIsNoteFormFocusedChange($event)"
        (messageSelected)="selectMessage($event)"
        (openNotesScreen)="onOpenNotesScreen($event)"
        (openTags)="onOpenTags($event)"
        (translationDetectedLanguage)="onTranslationDetectedLangugage($event)"
        [conversationEvents]="conversationEvents"
        [isNoteFormFocused]="isNoteFormFocused"
        [messages]="messages"
        [selectedMessage]="selectedMessage"
        [translationMode]="translationMode"
      ></ssi-live-chat-activity-tree>

      <ssi-live-chat-conversation-indicators
        [conversationEvents]="conversationEvents"
      ></ssi-live-chat-conversation-indicators>

      <ssi-live-chat-conversation-response
        *ngIf="!! canReply"
        (isResponseFocusedChange)="onIsResponseFocusedChange($event)"
        [isPredictive]="areSuggestionsAvailable"
        [isResponseFocused]="isResponseFocused"
        [addition]="responseAddition"
        [translationMode]="translationMode"
        [translationLanguage]="translationLanguage"
      ></ssi-live-chat-conversation-response>
    </div>

    <div
      class="conversation-footer"
    >
      <ul>
        <li>
          <button
            *ngIf="!! canReply && !! isResolving"
            type="button"
          >
            <i class="ssi ssi-loading ssi-spin"></i>

            <span
              translate="PLEASE_WAIT_ELLIPSIS"
            ></span>
          </button>

          <button
            *ngIf="! isResolving && ! isConversationResolved && ! agent.isOffline"
            (click)="resolveConversation()"
            [disabled]="isInterfaceDisabled"
            data-pendo="liveChat_resolveConversation"
            type="button"
          >
            <i class="fa fa-fw fa-check-circle-o"></i>

            <span
              *ngIf="!isAgentAssignedToConversation"
              translate="RESOLVE_THIS_CONVERSATION"
            ></span>

            <span
              *ngIf="isAgentAssignedToConversation"
              translate="RESOLVE_YOUR_CONVERSATION"
            ></span>
          </button>

          <button
            *ngIf="! isResolving && !! isConversationResolved && ! agent.isOffline"
            (click)="unresolveConversation()"
            [disabled]="isInterfaceDisabled || isConversationGracePeriodExpired"
            type="button"
          >
            <i class="fa fa-fw fa-check-circle-o"></i>

            <span
              translate="RESOLVED_BY"
            ></span>

            <span>
              {{ conversation.nameOfResolvingAgent }}
            </span>

            <span
              translate="IN"
            ></span>

            <span>
              {{ conversation.formattedResolutionTime }}
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <ssi-live-chat-conversation-sidebar
    #sidebar
    *ngIf="!! isLoaded && !! isSidebarVisible"
    [(isSidebarVisible)]="!! isSidebarVisible"
    [conversation]="conversation"
    [isVisitorBlocked]="isVisitorBlocked"
    [visitorIpLocation]="visitorIpLocation"
    (onBlockVisitor)="blockVisitorByIp($event)"
    (onUnblockVisitor)="unblockVisitorByIp($event)"
  ></ssi-live-chat-conversation-sidebar>

  <!-- <div
    *ngIf="!! isLoaded && !! isAssignChatScreenActive"
  >
    <ssi-live-chat-assign-chat
      [(isAssignChatScreenActive)]="isAssignChatScreenActive"
      [message]="selectedMessage"
    ></ssi-live-chat-assign-chat>
  </div> -->
</div>

<ssi-live-chat-notes
  *ngIf="!! isNotesScreenVisible"
  (close)="onCloseNotesScreen()"
  (isNoteFormFocusedChange)="onIsNoteFormFocusedChange($event)"
  [isNoteFormFocused]="isNoteFormFocused"
  [message]="selectedMessage"
></ssi-live-chat-notes>
