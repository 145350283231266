import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { API, User, UserModel } from '@ui-resources-angular';
import firebase from 'firebase/app';
import 'firebase/auth';
import { of } from 'rxjs/observable/of';
import { take } from 'rxjs/operators';

import {
  config as liveChatConfig,
  orloAccountTypeName,
  routes
} from '../../../../../library/constants/live-chat';

// ----------------

const isDebug = false;

// ----------------

@Injectable()
export class LiveChatAuthenticationService {
  public user: firebase.User;
  public userPermissions: string[] = [];

  constructor(
    private api: API,
    private firebaseAuthentication: AngularFireAuth,
    private userModel: UserModel
  ) {}

  public async authenticate(
    orloUser: User,
    forceUserChange: boolean = true
  ): Promise<any> {
    try {
      const firebaseUser: firebase.User = await this.firebaseAuthentication.authState
        .pipe(take(1))
        .toPromise();

      if (!!firebaseUser && !forceUserChange) {
        return of(firebaseUser);
      }

      const permissions = await this.getPermissions();

      if (!permissions.length) {
        return false;
      }

      this.userPermissions = permissions;

      const token = await this.getToken(permissions);

      if (isDebug) {
        console.log(`Token:`);
        console.log(token);
      }

      if (!token) {
        console.error(`Malformed response in authentication.`);

        return false;
      }

      const firebaseUserResponse: firebase.auth.UserCredential = await this.firebaseAuthentication.auth.signInWithCustomToken(
        token
      );

      const isValidResponse: boolean =
        !!firebaseUserResponse &&
        !!firebaseUserResponse.user &&
        !!firebaseUserResponse.user.uid;

      if (!isValidResponse) {
        console.error(`Unexpected response in authentication.`);

        return false;
      }

      this.user = firebaseUserResponse.user;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async getPermissions(): Promise<string[]> {
    try {
      const token = await this.api.get(liveChatConfig.routes.accounts);
      const liveChatApplications = [];

      Object.keys(token.data).forEach((accountID: string) => {
        if (isDebug) {
          console.log(`account ID: ${accountID}`);
        }

        const account = token.data[accountID];

        if (account.account_type_name === orloAccountTypeName) {
          if (isDebug) {
            console.log(`... is Live Chat account type`);
          }

          liveChatApplications.push(account);
        }
      });

      if (isDebug) {
        console.log(`available chat applications permissions:`);
        console.dir(liveChatApplications);
      }

      if (!liveChatApplications.length) {
        return [];
      }

      const permissions = await this.userModel.getPermissions(true);

      if (isDebug) {
        console.log(`also permissions:`);
        console.dir(permissions);
      }

      const agentLiveChatPermissions = [];

      liveChatApplications.forEach((application) => {
        if (
          !!permissions[application.id] &&
          !!permissions[application.id].agent
        ) {
          agentLiveChatPermissions.push(application.social_id);
        }
      });

      if (!agentLiveChatPermissions.length) {
        return [];
      }

      return agentLiveChatPermissions;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  private async getToken(appId: string[]): Promise<string> {
    try {
      const tokenResponse = await this.api.post(routes.token, {
        appId,
        environment: 'productionuk'
      });

      if (
        !tokenResponse ||
        !tokenResponse.data ||
        !tokenResponse.data.success ||
        !tokenResponse.data.token
      ) {
        throw new Error(`Unexpected token response!`);
      }

      return tokenResponse.data.token;
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}
