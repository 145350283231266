import './cl-button.component.scss';

import { Component, OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';

import { Account, AccountModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-cl-button',
  templateUrl: './cl-button.component.html',
  styles: []
})
export class ClButtonComponent implements OnInit {
  disabled = false;

  constructor() {}

  ngOnInit() {}
}
