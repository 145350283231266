<div class="modal-header">
  <button
    type="button"
    class="modal-close-icon"
    (click)="activeModal.dismiss()"
  ><i class="ssi ssi-times"></i>
  </button>
  <div class="social-network-preview-nav d-flex">
    <ul>
      <li
        class="tab-nav"
        *ngFor="let network of post.socialNetworks | filter: filterSupportedPreviews"
        [class.active]="activeNetwork === network"
        (click)="setActiveNetwork(network)"
      >{{accountName[network.config.accountTypeId].accountName}}
      </li>
    </ul>
  </div>
</div>



<div class="modal-body">

  <div class="heading-container">
    <div class="content-left">
      <h3>Post preview</h3>
      <h5 *ngIf="!errorMessage">This is an estimation of what your post will look like when posted natively:</h5>
    </div>
    <div class="content-right">
      <span
        class="flex-1"
        ngbDropdown
      >

        <button
          type="button"
          class="dropdown-toggle"
          *ngIf="activeNetwork.accounts?.length > 1"
          ngbDropdownToggle
        >
          <i
            class="fa-fw"
            [ngClass]="activeNetwork.config.icon.web"
          ></i><span>{{activeAccount.name}}</span><i class="caret"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-inbox"
          ngbDropdownMenu
        >
          <a
            *ngFor="let account of activeNetwork.accounts"
            href="javascript:;"
            class="dropdown-item"
            [class.active]="activeAccount === account"
            (click)="setActiveNetwork(activeNetwork, account)"
          >
            {{ account.displayName }}
          </a>
        </div>
      </span>

    </div>
  </div>

  <div
    class="loading"
    *ngIf="loadingTracker.active"
  ></div>
  <div
    class="social-network-preview-body"
    *ngIf="errorMessage"
  >
    <ssi-alert
      icon="ssi ssi-warning"
      iconColour="#F88C68"
      [content]="errorMessage"
    ></ssi-alert>
    <div class="generic-message">
      <i class="ssi ssi-no-results"></i>
      <h3>Oops! We can't preview your post...</h3>
    </div>
  </div>
  <div
    class="social-network-preview-body"
    *ngIf="!loadingTracker.active && !errorMessage"
  >

    <div
      class="social-network-preview-window"
      [ngSwitch]="activeNetwork.config"
    >
      <ssi-publisher-facebook-post-preview
        *ngSwitchCase="socialNetworks.Facebook"
        [post]="post"
        [account]="activeAccount"
        [text]="text"
        [preview]="preview"
        [links]="links"
        [mentions]="mentions"
        [files]="files"
      >
      </ssi-publisher-facebook-post-preview>
      <ssi-publisher-facebook-post-preview
        *ngSwitchCase="socialNetworks['Facebook Group']"
        [post]="post"
        [account]="activeAccount"
        [text]="text"
        [preview]="preview"
        [links]="links"
        [mentions]="mentions"
      >
      </ssi-publisher-facebook-post-preview>
      <ssi-publisher-twitter-post-preview
        *ngSwitchCase="socialNetworks.Twitter"
        [post]="post"
        [account]="activeAccount"
        [text]="text"
        [preview]="preview"
        [links]="links"
        [mentions]="mentions"
        [files]="files"
      >
      </ssi-publisher-twitter-post-preview>
      <ssi-publisher-linkedin-post-preview
        *ngSwitchCase="socialNetworks.LinkedIn"
        [post]="post"
        [account]="activeAccount"
        [text]="text"
        [preview]="preview"
        [links]="links"
        [mentions]="mentions"
        [files]="files"
      >
      </ssi-publisher-linkedin-post-preview>
      <ssi-publisher-youtube-post-preview
        *ngSwitchCase="socialNetworks.YouTube"
        [post]="post"
        [account]="activeAccount"
        [text]="text"
        [preview]="preview"
        [links]="links"
        [mentions]="mentions"
        [files]="files"
      >
      </ssi-publisher-youtube-post-preview>
      <ssi-publisher-instagram-post-preview
        *ngSwitchCase="socialNetworks['Instagram Business']"
        [post]="post"
        [account]="activeAccount"
        [text]="text"
        [preview]="preview"
        [links]="links"
        [mentions]="mentions"
        [files]="files"
      >
      </ssi-publisher-instagram-post-preview>
    </div>

  </div>
  <div class="social-network-preview-footer">
    <orlo-button
      [text]="'Edit post'"
      (click)="activeModal.dismiss()"
      [textColor]="'#fff'"
      [bgColor]="'#838EAB'"
    ></orlo-button>
    <button
      class="orlo-button primary"
      (click)="activeModal.close()"
      *ngIf="!errorMessage"
    >
      <span *ngIf="post.schedules.length === 0">
        Publish now
      </span>
      <span *ngIf="post.schedules.length > 0">
        <span *ngIf="!post.edit">{{ 'Publish ' + post.schedules.length + ' schedules' }}</span>
        <span *ngIf="post.edit">{{ 'Update ' + post.schedules.length + ' schedules' }}</span>
      </span>
    </button>
  </div>

</div>