<div
  class="insights-post-container"
  [class.post-selected]="selected"
  [class.active]="showRepostMenu || actionsMenu.visible || accountSelector.expanded"
>

  <div class="post-head">
    <div class="post-details left">
      <ssi-social-network-icon
        [accountTypeId]="accountTypeId"
        [size]="26"
      ></ssi-social-network-icon>

      <img
        class="author-avatar"
        [src]="stream.author.avatar"
        [ssiFallbackImage]="'avatarIncoming'"
      >

      <a
        class="author-name"
        *ngIf="stream.author?.link && stream.author.name && variant === 'liveXStream'"
        [href]="stream.author.link"
        target="_blank"
      >{{stream.author.name}}</a>
      <a
        class="author-name"
        *ngIf="stream.author?.link && stream.author.name && variant === 'profileSearch'"
        (click)="viewProfile()"
      >{{stream.author.name}}</a>
      <a
        class="author-name inactive"
        *ngIf="!stream.author.link && !stream.author.name"
        role="button"
        [ssiTooltip]="'Network does not provide author\'s name'"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="false"
      >{{(accountTypeLabel ? accountTypeLabel : SocialType.Twitter) + ' user comment' }} <i
          class="ssi ssi-information"></i></a>

      <span
        class="author-username"
        *ngIf="stream.author.username"
      >@{{stream.author.username}}</span>
    </div>
    <div class="post-details time-visibility">
      <div class="timestamp">
        <span>{{stream.created_at | date: 'dd MMM yyyy' }}</span> &nbsp;&nbsp;|&nbsp;&nbsp;
        <span>{{stream.created_at | date: 'HH:mm'}}</span>
      </div>

      <div class="visibility">
        <i class="ssi ssi-public-correct"></i>
        <span>Public</span>
      </div>
    </div>
  </div>

  <div class="post-details time-visibility placed-below">
    <div class="timestamp">
      <span>{{stream.created_at | date: 'dd MMM yyyy' }}</span> &nbsp;&nbsp;|&nbsp;&nbsp;
      <span>{{stream.created_at | date: 'HH:mm'}}</span>
    </div>

    <div class="visibility">
      <i class="ssi ssi-public-correct"></i>
      <span>Public</span>
    </div>
  </div>

  <div class="post-body">
    <div class="left">
      <div class="content">
        <p
          *ngIf="fullTextView || !contentCropped"
          [innerHTML]="formattedContent"
        ></p>
        <ng-container *ngIf="contentCropped && !fullTextView">
          <span [innerHTML]="formattedContent"></span>
        </ng-container>
        <span
          *ngIf="contentCropped && !fullTextView"
          class="read-more-link"
          (click)="readMore()"
          translate="READ_MORE"
        ></span>
      </div>

      <!-- <ssi-message-link-preview
        *ngIf="stream.linkPreview"
        class="message-box-message-link-preview"
        [selfPenned]="false"
        [title]="stream.linkPreview.title"
        [description]="stream.linkPreview.description"
        [url]="stream.linkPreview.url"
        [thumb]="stream.linkPreview.media"
      ></ssi-message-link-preview> -->
    </div>
    <div class="right">
      <div
        class="media-gallery"
        *ngIf="stream.media && stream.media.length"
      >
        <ng-container *ngFor="let mediaItem of stream.media | slice:0:2 | reverse; let i=index">
          <ng-container
            *ngTemplateOutlet="mediaItemTemplate; context: {mediaItem: mediaItem, mediaItemIndex: i }"></ng-container>
        </ng-container>

        <div
          class="media-items-count"
          *ngIf="hasMultipleMediaItems()"
        >
          <span>{{stream.media.length - 1}}+</span>
        </div>
      </div>
      <!-- profileSearch widget only returns image_url, even if it's a video -->
      <div
        class="media-gallery"
        *ngIf="variant === 'profileSearch' && stream.image_url"
      >
        <img
          [ssiEnlargeImage]="stream.image_url"
          [src]="stream.image_url"
        >
      </div>

      <ng-template
        #mediaItemTemplate
        let-mediaItem="mediaItem"
        let-mediaItemIndex="mediaItemIndex"
      >
        <img
          *ngIf="mediaItem.type === 'photo' || mediaItem.type === 'gif' || mediaItem.type === 'animated_gif'"
          [src]="mediaItem.link"
          [class.stacked]="mediaItemIndex > 0"
          [style.top]="(mediaItemIndex * 7) + 'px'"
          [style.left]="(mediaItemIndex * 7) + 'px'"
          ssiFallbackImage="brokenImageNew512x340"
        >

        <ng-container *ngIf="mediaItem.type === 'video' && mediaItem.size?.thumb && !mediaItem.errorLoadingThumb">
          <img
            [src]="mediaItem.size?.thumb"
            [class.stacked]="mediaItemIndex > 0"
            [style.top]="(mediaItemIndex * 7) + 'px'"
            [style.left]="(mediaItemIndex * 7) + 'px'"
            ssiFallbackImage=""
            (fallbackImageSet)="mediaItem.errorLoadingThumb = true"
          >
          <i
            class="ssi ssi-correct-play play-icon"
            [style.top]="25 + (mediaItemIndex * 7) + 'px'"
            [style.left]="50 + (mediaItemIndex * 7) + 'px'"
          ></i>
        </ng-container>

        <div
          *ngIf="mediaItem.type === 'video' && (!mediaItem.size?.thumb || mediaItem.errorLoadingThumb)"
          class="video-icon-container"
          [class.stacked]="mediaItemIndex > 0"
          [style.top]="(mediaItemIndex * 7) + 'px'"
          [style.left]="(mediaItemIndex * 7) + 'px'"
        >
          <i class="ssi ssi-video-circle"></i>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="post-foot">
    <ul class="menu-list desktop-menu">
      <li
        *ngIf="variant === 'profileSearch' && accountTypeId === AccountTypeIdString.Twitter"
        [ssiTriggerPublish]="{create: {accounts: [account], replyToSocialId: stream.id, text: '@'+stream.author?.username + ' '}}"
        class="menu-list-item"
      >
        <i class="ssi ssi-reply"></i>
        <span translate="REPLY"></span>
      </li>
      <li
        *ngIf="accountTypeId === AccountTypeIdString.Twitter || variant === 'liveXStream'"
        class="menu-list-item"
        (click)="variant === 'liveXStream' ? openLike($event) : likeStream(stream)"
      >
        <ng-container *ngIf="stream.isLiked; else elseTemplate">
          <i class="ssi ssi-liked liked"></i>
          <span translate="LIKED"></span>
        </ng-container>
        <ng-template #elseTemplate>
          <i class="ssi ssi-twitter-preview-like"></i>
          <span translate="LIKE"></span>
        </ng-template>
        <span
          *ngIf="stream.like_count"
          class="count"
        >&nbsp;({{stream.like_count}})</span>
      </li>
      <li
        *ngIf="accountTypeId === AccountTypeIdString.Twitter"
        class="menu-list-item"
        (click)="showRepostMenu = true"
      >
        <i class="ssi ssi-retweet-new"></i>
        <span translate="REPOST"></span>
        <i class="ssi ssi-arrow-down-new"></i>
        <span
          *ngIf="stream.share_count"
          class="count"
        >&nbsp;({{stream.share_count}})</span>
      </li>
      <li
        *ngIf="variant === 'profileSearch' && accountTypeId === AccountTypeIdString.Facebook"
        [ssiTriggerPublish]="{create: {accounts: [account], shareId: stream.id}}"
        class="menu-list-item"
      >
        <i class="ssi ssi-shares-standard"></i>
        <span translate="SHARE"></span>
        <span
          *ngIf="stream.share_count"
          class="count"
        >&nbsp;({{stream.share_count}})</span>
      </li>
      <li
        *ngIf="variant === 'liveXStream'"
        class="menu-list-item"
        (click)="openPushToInbox($event)"
      >
        <i class="ssi ssi-push-to-inbox-correct"></i>
        <span translate="PUSH_TO_INBOX"></span>
      </li>
      <li
        class="menu-list-item"
        (click)="actionsMenu.close(); viewNatively()"
      >
        <i class="ssi ssi-view-natively"></i>
        <span translate="VIEW_NATIVELY"></span>
      </li>
    </ul>
    <div class="actions-menu-container">
      <ssi-menu
        #actionsMenu
        [headAlignment]="'top right'"
        [bodyAlignment]="'top right'"
        cdkOverlayOrigin
        #actionsMenuTriggerOrigin="cdkOverlayOrigin"
      >
        <ng-template ssiTemplateSelector="menuHead">
          <i class="ssi ssi-outbox-menu menu-trigger-icon"></i>
        </ng-template>

        <ng-template ssiTemplateSelector="menuBody">
          <ul class="menu-list">
            <li
              *ngIf="variant === 'profileSearch' && accountTypeId === AccountTypeIdString.Twitter"
              [ssiTriggerPublish]="{create: {accounts: [account], replyToSocialId: stream.id, text: '@'+stream.author?.username + ' '}}"
              class="menu-list-item"
            >
              <i class="ssi ssi-reply"></i>
              <span translate="REPLY"></span>
            </li>
            <li
              *ngIf="accountTypeId === AccountTypeIdString.Twitter || variant === 'liveXStream'"
              class="menu-list-item"
              (click)="variant === 'liveXStream' ? openLike($event) : likeStream(stream)"
            >
              <ng-container *ngIf="stream.isLiked; else elseTemplate">
                <i class="ssi ssi-liked liked"></i>
                <span translate="LIKED"></span>
              </ng-container>
              <ng-template #elseTemplate>
                <i class="ssi ssi-twitter-preview-like"></i>
                <span translate="LIKE"></span>
              </ng-template>
              <span
                *ngIf="stream.like_count"
                class="count"
              >&nbsp;({{stream.like_count}})</span>
            </li>
            <li
              *ngIf="accountTypeId === AccountTypeIdString.Twitter"
              class="menu-list-item"
              (click)="showRepostMenu = true; actionsMenu.close()"
              (ssiOffClick)="showRepostMenu = false"
            >
              <i class="ssi ssi-retweet-new"></i>
              <span translate="REPOST"></span>
              <i class="ssi ssi-arrow-down-new"></i>
              <span
                *ngIf="stream.share_count"
                class="count"
              >&nbsp;({{stream.share_count}})</span>
            </li>
            <li
              *ngIf="variant === 'profileSearch' && accountTypeId === AccountTypeIdString.Facebook"
              [ssiTriggerPublish]="{create: {accounts: [account], shareId: stream.id}}"
              class="menu-list-item"
            >
              <i class="ssi ssi-shares-standard"></i>
              <span translate="SHARE"></span>
              <span
                *ngIf="stream.share_count"
                class="count"
              >&nbsp;({{stream.share_count}})</span>
            </li>
            <li
              *ngIf="variant === 'liveXStream'"
              class="menu-list-item"
              (click)="openPushToInbox($event)"
            >
              <i class="ssi ssi-push-to-inbox-correct"></i>
              <span translate="PUSH_TO_INBOX"></span>
            </li>
            <li
              class="menu-list-item"
              (click)="actionsMenu.close(); viewNatively()"
            >
              <i class="ssi ssi-view-natively"></i>
              <span translate="VIEW_NATIVELY"></span>
            </li>
          </ul>
        </ng-template>
      </ssi-menu>
      <div
        class="repost-menu"
        (ssiOffClick)="showRepostMenu = false"
        *ngIf="showRepostMenu"
      >
        <ul class="menu-list">
          <li class="menu-list-item">
            <div
              class="toggle-expand-label"
              (click)="expandRepostMenu = !expandRepostMenu; expandQuoteRepostMenu = false"
            >
              <i class="ssi ssi-retweet-new"></i>
              <span translate="AUTO_REPOST"></span>
              <i class="ssi ssi-arrow-down-new toggle-expand-icon"></i>
            </div>
            <ul
              class="inner-list"
              *ngIf="expandRepostMenu"
            >
              <li
                *ngFor="let acc of accounts"
                (click)="selectRepostAccount(acc)"
              >{{acc.displayName}}</li>
            </ul>
          </li>
          <hr>
          <li class="menu-list-item">
            <div
              class="toggle-expand-label"
              (click)="expandQuoteRepostMenu = !expandQuoteRepostMenu; expandRepostMenu = false"
            >
              <i class="ssi ssi-quote"></i>
              <span translate="QUOTE_REPOST"></span>
              <i class="ssi ssi-arrow-down-new toggle-expand-icon"></i>
            </div>
            <ul
              class="inner-list"
              *ngIf="expandQuoteRepostMenu"
            >
              <li
                *ngFor="let acc of accounts"
                (click)="selectQuoteRepostAccount(acc)"
              >{{acc.displayName}}</li>
            </ul>
          </li>
        </ul>
      </div>
      <ssi-dropdown-select-2
        #accountSelector
        [headless]="true"
        [options]="accounts"
        [yOffset]="'-28px'"
        [xPlacement]="'right'"
        [filterable]="true"
        [filterPlaceholder]="'Search for an account...'"
        [(ngModel)]="selectedAccount"
        (ngModelChange)="onAccountSelected($event)"
      ></ssi-dropdown-select-2>
    </div>
  </div>
</div>