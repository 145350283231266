import './user-select-modal.component.scss';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague
} from '../../services/api';

@Component({
  selector: 'ssi-user-select-modal',
  templateUrl: './user-select-modal.component.html'
})
export class UserSelectModalComponent {
  @Input() teams;
  @Input() colleagues;

  selectedTeam = undefined;
  searchText = '';
  filterActiveUsers = 'false';

  constructor(
    public activeModal: NgbActiveModal,
    protected teamsService: TeamsService,
    protected colleaguesService: ColleaguesService
  ) {}

  filterByText(match): boolean {
    return (
      this.searchText === '' ||
      match.toUpperCase().includes(this.searchText.toUpperCase())
    );
  }

  getColleaguesByTeam(teamId: string): Colleague[] {
    return this.colleaguesService.store.filterByTeam(teamId);
  }
}
