<div>
  <div class="row">
    <div class="col-xs-10 col-xs-offset-1">
      <ssi-gauge-chart>
        <mwl-gauge
          *ngIf="total >= 0"
          [valueDialClass]="'value-'+sentiment"
          [min]="0"
          [max]="100"
          [dialStartAngle]="180"
          [dialEndAngle]="0"
          [value]="value"
          [showValue]="false"
          [animated]="true"
          [animationDuration]="1">
        </mwl-gauge>
        <span class="mwl-gauge-label">{{getLabel()}}</span>
      </ssi-gauge-chart>
    </div>
  </div>
  <div class="gauge-meta">
    <h5 class="text-muted text-italic">
      {{sentimentLabel[sentiment]}}
    </h5>
    <br>
    <ng-content></ng-content>
  </div>
</div>
