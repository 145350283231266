import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateEditListeningModalComponent } from './create-edit-listening-modal.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TagsInputModule } from '../../../../insights/common/components/tags-input/tags-input.module';
import { OrloComponentsModule } from 'orlo-components';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from '../../../../../../common/components/spinner/spinner.module';
import { OffClickModule } from '../../../../../../common/directives/off-click/off-click.module';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    FormsModule,
    TagsInputModule,
    OrloComponentsModule,
    TranslateModule,
    SpinnerModule,
    OffClickModule
  ],
  declarations: [CreateEditListeningModalComponent],
  exports: [CreateEditListeningModalComponent]
})
export class CreateEditListeningModalModule {}
