import { Injectable } from '@angular/core';
import { OutboxMessage, AccountModel, Account } from '@ui-resources-angular';
import { ApiService } from '../../../../common/services/api';
import { catchError, map } from 'rxjs/operators';
import { Colleague, ColleaguesService } from '../../../../common/services/api';
import { appInjector } from '../../../../../../apps/angular/app-injector';
import { WorkflowManagerService } from '../../../../common/services/workflow-manager/workflow-manager.service';

export enum DraftType {
  Private = 'private',
  Public = 'public'
}

export interface DraftExtra {
  // used to put all extras needed to recreate the composer state - backend treats it as JSON object simply, anything can be put inside
  isSplit: boolean;
  splitPostAccountId?: string;
  targeting?: any;
  igShareToFeed: boolean;
  scheduleFirstCommentToggled: boolean;
}

export interface Draft {
  id?: string; // api does not return it, we add it once draft is fetched
  title: string;
  draft_type: DraftType;
  expires_at: string;
  created_at: string;
  created_by: number;
  deleted_at?: string;
  deleted_by?: number;
  outbox_messages: OutboxMessage[];
  company_id: number;
  extra: DraftExtra;
}

export interface DraftListItem {
  id: string;
  draft_type: string;
  title: string;
  content: string;
  created_at: string;
  created_by: string;
  deleted_at?: string;
  deleted_by?: string;
  media?: any;
  author?: Colleague;
  accounts?: Account[];
}

export interface DraftListResponse {
  drafts: DraftListItem[];
  total: number;
  next: {
    offset: number | null;
  };
}

@Injectable({ providedIn: 'root' })
export class DraftsLibraryService {
  constructor(
    private accountModel: AccountModel,
    protected api: ApiService,
    private workflowManager: WorkflowManagerService
  ) {}

  getDraftList(
    limit: number,
    offset: number,
    draft_type: DraftType
  ): Promise<DraftListResponse> {
    const endpoint = `${this.api.url}/draft/search`;
    const payload = {
      limit,
      offset,
      draft_type
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: any) => {
          response.drafts.drafts.forEach(async (draft) => {
            draft['author'] = this._getAuthor(draft.created_by);
            draft['accounts'] = await this._getAccounts(draft.account_ids);
            draft['missingAccountAccess'] = await this._isMissingAccountAccess(
              draft.account_ids
            );
          });
          return response.drafts;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  private async _isMissingAccountAccess(accountIds: number[]) {
    const workflowAccounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId()
    );
    const workflowAccountIds = workflowAccounts.map((a) => Number(a.id));
    const missing = accountIds.some(
      (accId) => !workflowAccountIds.includes(accId)
    );
    return missing;
  }

  private _getAuthor(authorId): Colleague {
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(authorId);
  }

  private async _getAccounts(accountIds: number[]): Promise<Account[]> {
    const workflowManager = appInjector().get(WorkflowManagerService);
    const accounts = await this.accountModel.findAccounts(
      workflowManager.getCurrentId()
    );
    return accountIds.map((accountId) =>
      accounts.find((account) => +account.id === +accountId)
    );
  }

  getDraft(id: string): Promise<Draft> {
    const endpoint = `${this.api.url}/draft/draft`;
    const opts = { params: { id } };

    return this.api
      .get(endpoint, opts)
      .pipe(
        map((response: { draft: Draft }) => {
          response.draft.id = id;
          return response.draft;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  createOrUpdateDraft(
    title: string,
    draft_type: DraftType,
    outbox_messages: OutboxMessage[],
    extra: DraftExtra,
    id?: string // update if id defined
  ): Promise<any[]> {
    const endpoint = `${this.api.url}/draft/draft`;

    const payload = {
      title,
      draft_type,
      outbox_messages,
      extra,
      expires_at: null,
      id
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  deleteDraft(draftId: string) {
    const endpoint = `${this.api.url}/draft/draft?id=${draftId}`;
    return this.api
      .delete(endpoint)
      .pipe(
        map((response: { success: boolean }) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
