import './inbox-activity-crm.component.scss';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Account,
  Activity,
  ActivityAuthor,
  Conversation,
  CrmExternalIntegration,
  CrmPerson,
  Profile,
  ProfileModel
} from '@ui-resources-angular';
import { LinkedExternalIntegration } from '../inbox-activity.component';
import { Plugin } from '../../plugin/plugin.component';

@Component({
  selector: 'ssi-inbox-activity-crm',
  templateUrl: './inbox-activity-crm.component.html'
})
export class InboxActivityCrmComponent implements OnInit {
  @Input() account: Account;
  @Input() profile: Profile;
  @Input() crmPerson: CrmPerson;
  @Input() activityProfile: ActivityAuthor;
  @Input() linkedSocialProfiles: Array<{
    account_id: number;
    account_type_id: number;
    id: string;
    name: string;
    social_id: string;
  }>;
  @Input() externalIntegrations: {
    linked: LinkedExternalIntegration[];
    unlinked: CrmExternalIntegration[];
  };
  @Input() plugins: Plugin[];
  // @Input() plugins: { plugin_id: string; plugin_person_id: string }[];

  @Output() showExternalCrmPerson = new EventEmitter();
  @Output() linkIntegration = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(private profileModel: ProfileModel) {}

  ngOnInit() {}

  viewProfile(params: { accountId: string; profileId: string }) {
    console.log('view profile');
    this.profileModel['viewProfile'](params);
  }
}
