import './report-filter-bar.component.scss';

import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Filter } from '../../../reports/view/view-report.component';

@Component({
  selector: 'ssi-report-filter-bar',
  templateUrl: './report-filter-bar.component.html',
  styles: []
})
export class ReportFilterBarComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() activeFilters: Filter[];

  @Output() onFiltersChange = new EventEmitter<Filter[]>();
  @Output() onFilterRemove = new EventEmitter<Filter[]>();

  @ViewChild('filterItems') filterItems: ElementRef;

  filtersExpanded = false;
  filtersWrapped = false;
  collapsedFiltersAmount = 0;

  protected destroyed$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    merge(fromEvent(window, 'resize'), fromEvent(window, 'orientationchange'))
      .pipe(debounceTime(200), takeUntil(this.destroyed$))
      .subscribe(() => {
        this._handleWrap();
      });
  }

  ngAfterViewInit() {
    this._handleWrap();
  }

  ngOnChanges(changes) {
    if (changes.activeFilters) {
      this._handleWrap();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private _handleWrap() {
    let rowCount = 0;
    this.collapsedFiltersAmount = 0;
    this.cdr.detectChanges();
    Array.from(this.filterItems.nativeElement.children).forEach(
      (filterElm: any, index) => {
        if (index++ > this.activeFilters.length) {
          return;
        } // stop onChanges bug
        if (
          !filterElm.previousElementSibling ||
          filterElm.offsetLeft < filterElm.previousElementSibling.offsetLeft
        ) {
          rowCount++;
        }
        if (rowCount > 1) {
          this.collapsedFiltersAmount++;
        }
      }
    );
    if (rowCount === 1) {
      this.filtersExpanded = false;
    }
    this.filtersWrapped = rowCount > 1;
  }

  deleteFilterItem(index, filter) {
    // this.onFilterRemove.emit([...filter]);
    this.activeFilters.splice(index, 1);
    this.onFiltersChange.emit(this.activeFilters);
    this._handleWrap();
  }

  deleteAllFilterItems() {
    this.onFilterRemove.emit(this.activeFilters);
    this.activeFilters = [];
    this.onFiltersChange.emit(this.activeFilters);
    this._handleWrap();
  }
}
