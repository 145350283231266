import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Transition, StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  IftttService,
  IftttServiceModel
} from '../ifttt-utils/ifttt-service-model/ifttt-service-model';
import { AppletCollection } from '../ifttt-utils/applets/applet-collection';
import { Applet, AppletAction } from '../ifttt-utils/applets/applet';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { ConfirmationModalComponent } from '../../../../common/components/confirmation-modal/confirmation-modal.component';
import { CompanyService } from '../../../../common/services/company/company.service';

export async function servicesResolveFn(
  iftttServiceModel: IftttServiceModel
): Promise<IftttService[]> {
  try {
    return await iftttServiceModel.findAllActive();
  } catch (e) {
    console.log(e);
  }
}

export function serviceResolveFn(
  transition: Transition,
  services: IftttService[]
): IftttService {
  try {
    if (!Array.isArray(services)) {
      throw new Error(
        `Value for 'ifttt applet collection list services' not in expected format.`
      );
    }

    return services.find(
      (iService) => iService.name === transition.params().serviceName
    );
  } catch (error) {
    console.error(error);

    return null;
  }
}

export async function appletCollectionResolveFn(
  service: IftttService
): Promise<AppletCollection> {
  try {
    return await service.getAppletCollection();
  } catch (e) {
    console.log(e);
  }
}

@Component({
  templateUrl: './ifttt-applet-collection-list.component.html'
})
export class IftttAppletCollectionListComponent implements OnInit {
  static resolve = [
    {
      token: 'services',
      resolveFn: servicesResolveFn,
      deps: [IftttServiceModel]
    },
    {
      token: 'service',
      resolveFn: serviceResolveFn,
      deps: [Transition, 'services']
    },
    {
      token: 'appletCollection',
      resolveFn: appletCollectionResolveFn,
      deps: ['service']
    }
  ];

  @Input() services: IftttService[];
  @Input() service: IftttService;
  @Input() appletCollection: AppletCollection;

  @ViewChild('brokenTriggerFaqLinkRef') brokenTriggerFaqLinkRef: ElementRef;

  constructor(
    private state: StateService,
    private popup: PopupService,
    private modal: NgbModal,
    private company: CompanyService
  ) {}

  async ngOnInit() {
    if (!(await this.company.hasFeatureAccess('ORLO_INSIGHTS'))) {
      this.services = this.services.filter(
        (s) => s.name !== 'monitoring.imported'
      );
    }

    await this.validateAndCleanUpApplets();
  }

  deleteApplet(applet) {
    this.popup
      .confirm({
        title: 'Delete trigger',
        template: 'Are you sure you would like to delete this trigger?'
      })
      .then((shouldDelete) => {
        if (shouldDelete) {
          this.appletCollection.applets = this.appletCollection.applets.filter(
            (iApplet) => iApplet !== applet
          );
          this.saveChanges();
        }
      });
  }

  async saveChanges() {
    await this.appletCollection.service.saveAppletCollection(
      this.appletCollection
    );
  }

  async validateAndCleanUpApplets(): Promise<void> {
    for (let i = 0; i < this.appletCollection.applets.length; ++i) {
      const applet = this.appletCollection.applets[i];

      const invalidActions = applet.actions.filter((action) => {
        return Applet.actionInvalid(action);
      });

      if (invalidActions.length) {
        const shouldUpdate = await this.showInvalidAppletModal(
          applet,
          applet.actions,
          invalidActions
        );

        if (shouldUpdate) {
          // redirect to edit page
          this.state.go(
            'auth.manageIftttApplet.serviceSelected.triggersAndActions',
            { serviceName: this.service.name, appletIndex: i }
          );
        } else {
          // delete invalid actions
          applet.actions = applet.actions.filter((action) => {
            return !Applet.actionInvalid(action);
          });

          if (!applet.actions.length) {
            // delete also the trigger since it doesn't have any actions more
            this.appletCollection.applets.splice(i, 1);
          }

          await this.saveChanges();
          this.state.reload();
        }

        break;
      }
    }
  }

  showInvalidAppletModal(
    applet: Applet,
    allActions: AppletAction[],
    invalidActions: AppletAction[]
  ): Promise<boolean> {
    if (!invalidActions.length) {
      return Promise.resolve(false);
    }

    const invalidActionNames = invalidActions.map((a) => a.name);
    // error log it for the sole purpose of tracking it on TrackJs
    console.error('INVALID TRIGGER ACTIONS:', invalidActionNames.join(', '));

    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });

    if (invalidActions.length < allActions.length) {
      // some trigger actions are invalid
      if (invalidActions.length === 1) {
        confirmationModal.componentInstance.icon = 'ssi ssi-actions-fail';
        confirmationModal.componentInstance.title = 'Heads up';
        confirmationModal.componentInstance.info = `The action '${invalidActions[0].name}' in your trigger ${applet.name} is no longer valid. You can delete this action or update it now`;
        confirmationModal.componentInstance.confirmButton = `Update action`;
        confirmationModal.componentInstance.cancelButton = 'Delete action';
        confirmationModal.componentInstance.footerTemplate = this.brokenTriggerFaqLinkRef;
      } else {
        let invalidActionNamesString = ``;
        invalidActionNames.forEach((name, i) => {
          invalidActionNamesString +=
            i === invalidActionNames.length - 1
              ? ` and '${name}' `
              : `'${name}', `;
        });

        confirmationModal.componentInstance.icon = 'ssi ssi-actions-fail';
        confirmationModal.componentInstance.title = 'Heads up';
        confirmationModal.componentInstance.info = `Multiple actions in your trigger ${applet.name} are no longer valid. These include: ${invalidActionNamesString}. You can delete these actions or update them now`;
        confirmationModal.componentInstance.confirmButton = `Update actions`;
        confirmationModal.componentInstance.cancelButton = 'Delete actions';
        confirmationModal.componentInstance.footerTemplate = this.brokenTriggerFaqLinkRef;
      }
    } else {
      // all trigger actions are invalid
      if (invalidActions.length === 1) {
        confirmationModal.componentInstance.icon = 'ssi ssi-no-results';
        confirmationModal.componentInstance.title = 'No valid actions';
        confirmationModal.componentInstance.info = `Your trigger ${applet.name} needs to be updated as the only action in this trigger is no longer valid. You can delete this trigger permanently or update this action now`;
        confirmationModal.componentInstance.confirmButton = `Update action`;
        confirmationModal.componentInstance.cancelButton = 'Delete trigger';
        confirmationModal.componentInstance.footerTemplate = this.brokenTriggerFaqLinkRef;
      } else {
        confirmationModal.componentInstance.icon = 'ssi ssi-no-results';
        confirmationModal.componentInstance.title = 'No valid actions';
        confirmationModal.componentInstance.info = `Your trigger ${applet.name} needs to be updated as all the actions in this trigger are no longer valid. You can delete this trigger permanently or update these actions now`;
        confirmationModal.componentInstance.confirmButton = `Update actions`;
        confirmationModal.componentInstance.cancelButton = 'Delete trigger';
        confirmationModal.componentInstance.footerTemplate = this.brokenTriggerFaqLinkRef;
      }
    }

    return confirmationModal.result;
  }
}
