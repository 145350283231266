import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { StreamsSelectComponent } from './streams-select.component';
import { ListSelect2Module } from '../../../../../../common/components/list-select2/list-select2.module';

@NgModule({
  declarations: [StreamsSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet.serviceSelected.streams',
          url: '/streams',
          component: StreamsSelectComponent,
          resolve: StreamsSelectComponent.resolve
        }
      ]
    }),
    TranslateModule,
    ListSelect2Module
  ]
})
export class StreamsSelectModule {}
