import './account-summary-card.component.scss';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-account-summary-card',
  templateUrl: './account-summary-card.component.html',
  styles: []
})
export class AccountSummaryCardComponent {
  @Input() accountTitle: string;
  @Input() accountIcon: string;
  @Input() accountAmount: string;
  @Input() messageAmount: string;
  @Input() conversationAmount: string;
  @Input() postAmount?: number;

  constructor() {}
}
