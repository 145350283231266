<div class="prompt">
  <div class="prompt-header">
    <div class="prompt-header-left">
      <h2>Image size limit</h2>
    </div>
    <div class="prompt-header-right">
      <button (click)="activeModal.close('cancel')"
              class="prompt-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="prompt-body">
    <p>{{description}}</p>
    <p *ngIf="!isPngImage && !isGifImage">You can either auto-reduce the size, which may slightly affect the image quality, or crop your image in the editor to reduce the size. If that doesn’t work, please try again by uploading an image with a smaller file size.</p>
    <p *ngIf="isPngImage">You can either crop your image in the editor to reduce the size, or try again by uploading an image with a smaller file size.</p>
    <div class="prompt-body-row"></div>
  </div>

  <div class="prompt-footer">
    <div class="prompt-footer-left">
      <orlo-button *ngIf="!isPngImage && !isGifImage"
                   class="prompt-button prompt-button-link"
                   text="AUTO-REDUCE SIZE"
                   textColor="#43537F"
                   bgColor="#fff"
                   (click)="activeModal.close('auto-reduce-size')"></orlo-button>
    </div>
    <div class="prompt-footer-right">
      
      <button type="button"
              class="g-btn g-btn-lg g-btn-secondary bg-white"
              (click)="activeModal.close('cancel')">Cancel</button>

      <button *ngIf="!isGifImage"
              type="button"
              class="g-btn g-btn-lg"
              (click)="activeModal.close('open-editor')">Crop in editor</button>

    </div>
  </div>
</div>