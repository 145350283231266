<div class="spend-breakdown-container">
  <div class="section-body table-container">
    <ssi-treetable [data]="stats"
                   [bodyStyle]="{'max-height': ''}"
                   [rowStyle]="{'height': '60px'}"
                   [virtualScroll]="false"
                   [resizeMode]="'fit'"
                   [reorderable]="true"
                   [saveSettings]="true"
                   [settingsStorageKey]="'treetable-ad-stats-account-breakdown'"
                   [globalFilterInputRef]=""
                   id="treetable-ad-stats-account-breakdown">

      <ssi-column field="account.name"
                  header="AD ACCOUNTS"
                  width="15%"
                  [templateRefs]="{bodyCell: accountBodyCellTemplate}"></ssi-column>
      <ssi-column field="current.total_spend.amount"
                  header="TOTAL SPEND"
                  [staticCtx]="{ isCurrencyColumn: true }"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
      <ssi-column field="current.conversions"
                  header="CONVERSIONS"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
      <ssi-column field="current.cost_per_conversion.amount"
                  header="COST/CONVERSION"
                  [staticCtx]="{ isCurrencyColumn: true }"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
      <ssi-column field="current.cost_per_click.amount"
                  header="COST/CLICK"
                  [staticCtx]="{ isCurrencyColumn: true }"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
      <ssi-column field="current.cost_per_mille.amount"
                  header="COST/MILLE"
                  [staticCtx]="{ isCurrencyColumn: true }"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
      <ssi-column field="current.purchase_roas.amount"
                  header="PURCHASE ROAS"
                  [staticCtx]="{ isCurrencyColumn: true }"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    </ssi-treetable>


    <ng-template #accountBodyCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-fieldValue="fieldValue">
      <div class="body-cell account"
           [class.row-even]="rowIndex % 2 !== 0">
        <ssi-social-network-icon [accountTypeId]="rowData.account.account_type_id"
                                 [size]="20"></ssi-social-network-icon>
        <span title="{{fieldValue}}">{{fieldValue}}</span>
      </div>
    </ng-template>

    <ng-template #statBodyCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-column="column"
                 let-columnIndex="columnIndex"
                 let-columns="columns"
                 let-columnsMap="columnsMap"
                 let-fieldValue="fieldValue">
      <div class="body-cell stat"
           [class.row-even]="rowIndex % 2 !== 0">
        <span *ngIf="!isNumber(fieldValue)"
              [ssiTooltip]="'NOTHING_TO_SEE_HERE_NO_REALLY_THERE_ISNT_BECAUSE_THIS_NETWORK_DOESNT_PROVIDE_THIS_DATA' | translate"
              tooltipWindowClass="tooltip-chunky-big">N/A</span>
        <ng-container *ngIf="isNumber(fieldValue)">
          <span *ngIf="column.staticCtx.isCurrencyColumn"
                [innerHTML]="currencySymbol"></span>
          <ssi-big-number [ssiTooltip]="''"
                          tooltipWindowClass="tooltip-chunky-big"
                          [number]="fieldValue"
                          [maxFractionDigitsHover]="6"></ssi-big-number>

          <ssi-stat-change *ngIf="rowData.previous && column.field !== 'current.conversions'"
                           [current]="fieldValue"
                           [previous]="rowData.previous[splitColumnFieldValue(column.field)].amount"
                           [suffix]="''"></ssi-stat-change>

          <ssi-stat-change *ngIf="rowData.previous && column.field === 'current.conversions'"
                           [current]="fieldValue"
                           [previous]="rowData.previous[splitColumnFieldValue(column.field)]"
                           [suffix]="''"></ssi-stat-change>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>