import './outbox-post-modal.component.scss';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
import {
  API,
  Outbox,
  OutboxMessageType,
  User,
  UserModel
} from '@ui-resources-angular';
import {
  NotesModelService,
  Note
} from '../../../../validate-posts/validate-posts-box/validate-posts-notes/notes-model/notes-model.service';
import { Advert } from '../../../../advertising/advert-model/advert-model.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutboxPostSubmodalComponent } from './outbox-post-submodal/outbox-post-submodal.component';
import { format } from 'date-fns';
import {
  TeamsService,
  Team,
  Colleague,
  ColleaguesService
} from '../../../../../../../common/services/api';
import { PopupService } from '../../../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { OutboxTagsService } from '../../../../../../../common/services/api/outbox-tags';
import { ValidatePostsDissaproveComponent } from '../../../../validate-posts/validate-posts-box/validate-posts-dissaprove/validate-posts-dissaprove.component';

@Component({
  selector: 'ssi-outbox-post-modal',
  templateUrl: './outbox-post-modal.component.html'
})
export class OutboxPostModalComponent implements OnInit {
  @Output() refresh = new EventEmitter();
  post: Outbox | any;
  averageStats: any;
  averageAdStats: any;
  averageScore: number;
  notes: Note[];
  adNotes: Note[];
  colleagues: Colleague[];
  teams: Team[];
  user: User;
  notesActive = false;
  adverts: Advert[] = [];
  activeAdvert: any;
  adNotesActive = false;
  disablePostNotes = true;
  disableAdNotes = true;
  postExpanded = false;
  authUser: User;
  showConfirmation = false;
  postAction: string;

  OutboxMessageType = OutboxMessageType;
  disapprovalTags: { label: string; value: string }[];

  constructor(
    public modalInstance: NgbActiveModal,
    private api: API,
    private userModel: UserModel,
    public modal: NgbModal,
    public notesModelService: NotesModelService,
    private state: StateService,
    private popup: PopupService,
    private translate: TranslateService,
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService,
    protected outboxTagsService: OutboxTagsService
  ) {}

  async ngOnInit() {
    this.notes = await this.notesModelService.fetchNotes(
      this.post.id,
      'outbox'
    );

    const adverts = await this.api.get('advertising/advertisingAdverts_v2', {
      params: {
        outbox_id: this.post.id
      }
    });
    this.adverts = adverts.data.data;

    if (this.adverts.length > 0) {
      this.setActiveAdvert(this.adverts[0]);
    }

    const { data } = await this.api.get(
      `/outbox/averageStats?account_id=${this.post.account_id}`
    );
    this.averageStats = data;

    const avgStatData = await this.api.get(
      `advertising/advertisingAverageStats?account_id=${this.post.account_id}`
    );
    this.averageAdStats = avgStatData.data;

    this.averageScore = this.getScore(
      data.avg_comments,
      data.avg_shares,
      data.avg_likes,
      data.avg_clicks_es
    );

    this.authUser = await this.userModel.getAuthUser();
    this.teams = await this.teamsService.getAllActive();
    this.colleagues = await this.colleaguesService.getAllActive();

    const validationTags = await this.outboxTagsService.getValidationTags();
    this.disapprovalTags = validationTags.map((tag) => {
      return {
        label: tag.name,
        value: tag.name
      };
    });
  }

  getScore(
    comments: string | number = 0,
    shares: string | number = 0,
    likes: string | number = 0,
    clicks: string | number = 0
  ) {
    return Math.round(+comments * 4 + +shares * 3 + +clicks * 2 + +likes);
  }

  formatDate(unformattedDate) {
    const date = new Date(unformattedDate);
    return format(date, 'DD/MM/YYYY');
  }

  getTimeDuration(start, end) {
    if (end) {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(start);
      const secondDate = new Date(end);

      const duration = Math.round(
        Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
      );

      return duration;
    } else {
      return 'Indefinite';
    }
  }

  getPermissions(accountID) {
    this.userModel.getPermissions(true).then((permissions) => {
      if (permissions.hasOwnProperty(accountID)) {
        return !Object.values(permissions[accountID])[0] && true;
      }
    });
    return false;
  }

  public updateNotes(updatedNote) {
    const index = this.notes.indexOf(updatedNote);

    if (index > -1) {
      this.notes.splice(index, 1);
    } else {
      this.notes.unshift(updatedNote);
    }
  }

  public updateAdNotes(updatedNote) {
    const index = this.adNotes.indexOf(updatedNote);

    if (index > -1) {
      this.adNotes.splice(index, 1);
    } else {
      this.adNotes.unshift(updatedNote);
    }
  }

  public toggleNotes() {
    this.disablePostNotes = this.getPermissions(this.post.id);
    this.notesActive = !this.notesActive;
  }

  public toggleAdNotes() {
    this.disableAdNotes = this.getPermissions(this.activeAdvert.id);
    this.adNotesActive = !this.adNotesActive;
  }

  public closeModal() {
    this.modalInstance.close();
  }

  canManagePost() {
    return (
      !this.post.is_validated &&
      !this.post.deleted_at &&
      !this.post.rejected_at &&
      this.authUser &&
      this.authUser.hasAccountPermission(
        [this.post.account_id],
        [['post'], ['post_un-validated'], ['validate_posts']]
      )
    );
  }
  showNotification(confirmationType: string) {
    this.showConfirmation = true;
    this.postAction = confirmationType;
  }

  approvePost() {
    this.popup
      .confirm({
        title: this.translate.instant('APPROVE_POST'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WANT_TO_APPROVE_THIS_POST'
        )
      })
      .then((shouldApprove) => {
        if (shouldApprove) {
          this.post.approve();
          this.showNotification('approved');
          this.refresh.emit();
        }
      });
  }

  async disapprovePost() {
    const validationTags = await this.outboxTagsService.getValidationTags();
    const modal = this.modal.open(ValidatePostsDissaproveComponent);
    modal.componentInstance.disapprovalTags = validationTags.map((tag) => {
      return {
        label: tag.name,
        value: tag.name
      };
    });
    const result = await modal.result;

    if (result !== 'cancel') {
      this.post.disapprove(
        (result as any).reason,
        (result as any).disapprovalTags.map((t) => t.value)
      );
      this.showNotification('disapproved');
      this.refresh.emit();
    }

    // validateRequest['validator_note'] = result;
    // validateRequest['email_only'] = false;

    // if (result !== 'cancel') {
    //   this.post.disapprove(
    //     (result as any).reason,
    //     (result as any).tags.map((t) => t.value)
    //   );
    //   this.showNotification('disapproved');
    //   this.refresh.emit();
    // }

    // TODO: Replace approval Tags with API call and use feature flag check. Handle new disapprove method that accepts tags and reason *********************************
    // this.popup
    //   .prompt({
    //     title: this.translate.instant('NOTIFY_THE_USER'),
    //     template: this.translate.instant(
    //       'PLEASE_ENTER_SOME_NOTES_FOR_THE_USER_TO_REVIEW'
    //     ),
    //     approvalTags: this.disapprovalTags
    //   })
    //   .then((response) => {
    //     if (response !== false) {
    //       this.post.disapprove(
    //         (response as any).reason,
    //         (response as any).tags.map((t) => t.value)
    //       );
    //       this.showNotification('disapproved');
    //       this.refresh.emit();
    //     }
    //   });
    // **************************************************************************
  }

  public deletePost() {
    this.closeModal();
    this.state.go('auth.marketing.contentCalendar', {
      outboxId: '',
      query: '',
      note_id: ''
    });
    this.refresh.emit();
  }

  DeleteAd() {
    this.modalInstance.close();
    const deleteAdConfirmation = this.modal.open(OutboxPostSubmodalComponent, {
      windowClass: 'modal-vertical modal-border-none modal-background-none',
      size: 'lg'
    });
    deleteAdConfirmation.componentInstance.type = 'delete';
  }

  editAd() {
    this.modalInstance.close();
    this.state.go('auth.marketing.advertising.advertEdit', {
      advertId: this.activeAdvert.id
    });
  }

  public async setActiveAdvert(advert) {
    this.disableAdNotes = this.getPermissions(advert.id);
    this.activeAdvert = advert;

    this.adNotes = await this.notesModelService.fetchNotes(advert.id, 'advert');
  }

  async setAdStatus(newStatus) {
    await this.api.post(
      'advertising/advertisingExternalStatus',
      {
        external_status: newStatus
      },
      {
        params: {
          id: this.activeAdvert.id,
          _method: 'PATCH'
        }
      }
    );
    this.activeAdvert.external_status = newStatus;
  }

  toggleAdStatus() {
    const newStatus =
      this.activeAdvert.external_status === 'PAUSED' ? 'ACTIVE' : 'PAUSED';
    this.setAdStatus(newStatus);
    if (newStatus === 'PAUSED') {
      const pauseAdConfirmation = this.modal.open(OutboxPostSubmodalComponent, {
        windowClass: 'modal-vertical modal-border-none modal-background-none',
        size: 'lg'
      });
      pauseAdConfirmation.componentInstance.type = 'pause';
      pauseAdConfirmation.componentInstance.setAdStatus.subscribe(() => {
        this.setAdStatus('ACTIVE');
      });
    }
  }
}
