export const environment = {
  credentials: {
    firebase: {
      apiKey: 'AIzaSyDRL-3LGbi-MkwIv7hMAvQbWlWmRj5Zzt4',
      authDomain: "production-orlo-live-chat-uk.firebaseapp.com",
      databaseURL: 'https://production-orlo-live-chat-uk.firebaseio.com/',
      messagingSenderId: "467823975873",
      projectId: 'production-orlo-live-chat-uk',
      storageBucket: 'production-orlo-live-chat-uk.appspot.com'
    },
    messaging: {
      vapid: `BDYbAMmKI0oPEywWMSZw1yKC7YfKYIloyOY20hiSMvbBZ262LICNroD2wODBzHqyhUV2OI5_tdykzwVnm4jThCI`,
    },
  },
  production: true,
  routes: {
    token: 'https://europe-west1-production-orlo-live-chat-uk.cloudfunctions.net/widget-token',
  },
};
