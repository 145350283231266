<div
  [ngClass]="{ 'online': isAgentOnline, 'offline': !isAgentOnline }"
>
  <ul class="chat-statistics">
    <li
      (click)="filterByUnassigned()"
      [ngbPopover]="'ALL_NEW_CHATS' | translate"
      [ngClass]="{
        disabled: !! isInterfaceDisabled,
        empty: ! quantityOfUnassignedConversations
      }"
      class="unassigned"
      container="body"
      data-pendo="liveChat_viewAllLiveChats"
      placement="top"
      popoverClass="popover-dark"
      triggers="mouseenter mouseleave"
    >
      <span class="icon" [ngClass]="{'unhighlight': !quantityOfUnassignedConversations}">
        <i class="ssi ssi-new-chats"></i>

        <span
          class="unassigned-quantity"
          [ngClass]="{'quantity-hide': !quantityOfUnassignedConversations}"
          >
          {{ quantityOfUnassignedConversations }}
        </span>
      </span>

      <p class="widget-label">
        <ng-container *ngIf="!quantityOfUnassignedConversations">0 </ng-container>New Chats
      </p>

      <span class="statistic">
        {{ ageOfNewestUnassignedConversation }}
      </span>
    </li>

    <li
      (click)="filterByOwned()"
      [ngbPopover]="'MY_ASSIGNED_CHATS' | translate"
      [ngClass]="{
        disabled: !! isInterfaceDisabled,
        empty: ! quantityOfOwnedConversations
      }"
      class="assigned"
      container="body"
      data-pendo="liveChat_viewMyAssignedLiveChats"
      placement="top"
      popoverClass="popover-dark"
      triggers="mouseenter mouseleave"
    >
      <span class="icon">
        <i class="ssi ssi-assigned-small"></i>

        <span>
          {{ quantityOfOwnedConversations }}
        </span>
      </span>

      <span class="statistic">
        {{ ageOfNewestAssignedConversation }}
      </span>
    </li>

    <li
      (click)="filterByOngoing()"
      [ngbPopover]="'MY_ONGOING_CHATS' | translate"
      [ngClass]="{
        disabled: !! isInterfaceDisabled,
        empty: ! quantityOfOngoingConversations
      }"
      class="ongoing"
      container="body"
      data-pendo="liveChat_viewMyOngoingLiveChats"
      placement="top"
      popoverClass="popover-dark"
      triggers="mouseenter mouseleave"
    >
      <span class="icon" [ngClass]="{'unhighlight': !quantityOfOngoingConversations}">
        <i class="ssi ssi-new-replies-lc"></i>
        <span class="assigned-quantity" [ngClass]="{'quantity-hide': !quantityOfOngoingConversations}">
          {{ quantityOfOngoingConversations }}
        </span>
      </span>

      <p class="widget-label">
        <ng-container *ngIf="!quantityOfOngoingConversations">0 </ng-container>New Replies
      </p>


      <span class="statistic">
        {{ quantityOfOngoingConversations }}
      </span>
    </li>
  </ul>
</div>
