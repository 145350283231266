import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NotesComponent } from './notes.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { SanitizedHtmlModule } from '../../../../../../../common/pipes/sanitized/sanitized.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule,
    FormsModule,
    MentionModule,
    SanitizedHtmlModule
  ],
  declarations: [NotesComponent],
  exports: [NotesComponent],
  entryComponents: [NotesComponent]
})
export class NotesModule {}
