import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DrilldownModalModule } from './components/drilldown-modal/drilldown-modal.module';
import { DrilldownModalComponent } from './components/drilldown-modal/drilldown-modal.component';
import { DetectedThemeResultsModule } from '../theme-detectors/detected-theme-results/detected-theme-results.module';
import { DetectedThemeResultsComponent } from '../theme-detectors/detected-theme-results/detected-theme-results.component';
import { ModalListModule } from './components/modal-list/modal-list.module';
import { ModalListComponent } from './components/modal-list/modal-list.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DrilldownModalModule,
    DetectedThemeResultsModule,
    ModalListModule
  ],
  exports: [
    DrilldownModalComponent,
    DetectedThemeResultsComponent,
    ModalListComponent
  ],
  entryComponents: [
    DrilldownModalComponent,
    DetectedThemeResultsComponent,
    ModalListComponent
  ]
})
export class CommonInsightsModule {}
