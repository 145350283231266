import { Injectable } from '@angular/core';
import {
  CalendarDateFormatter,
  DateFormatterParams
} from 'angular-calendar/modules/common';
import { DatePipe } from '@angular/common';

@Injectable()
export class CalendarShortDateFormatterService extends CalendarDateFormatter {
  constructor(private formatDate: DatePipe) {
    super();
  }

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return this.formatDate.transform(date, 'EEE', undefined, locale);
  }
}
