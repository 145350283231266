import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Inject
} from '@angular/core';

import { ANIMATE_SCROLL_TO } from '../../services/services.module';
import { Debounce } from '../../decorators';

@Component({
  selector: '[ssiVirtualScroll]', // tslint:disable-line
  exportAs: 'virtualScroll',
  templateUrl: './virtual-scroll.component.html',
  styles: [
    `
      :host {
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        -webkit-overflow-scrolling: touch;
      }
      .scrollable-content {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .total-padding {
        width: 1px;
        opacity: 0;
      }
    `
  ]
})
export class VirtualScrollComponent implements OnChanges {
  @Input() items: any[];
  @Input() getItemHeight: (item: any) => number;
  @Input() bufferAmount = 0;

  @Output() viewPortItemsUpdated = new EventEmitter();

  containerHeight = 0;
  topPadding = 0;
  previousStart = 0;
  previousEnd = 0;

  constructor(
    private elm: ElementRef,
    @Inject(ANIMATE_SCROLL_TO) private animateScrollTo
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.items &&
      this.getItemHeight &&
      (changes.items || changes.getItemHeight)
    ) {
      this.containerHeight = 0;
      for (const item of this.items) {
        this.containerHeight += this.getItemHeight(item);
      }
      this.refresh();
    }
  }

  refresh() {
    requestAnimationFrame(() => this.updateViewportItems());
  }

  scrollToItem(item) {
    let index = this.items.indexOf(item);
    if (index > -1) {
      // atm buffer items are not being added up prior viewPort items, only after, so should not be included in this calculation neither
      // index = Math.min(this.items.length - 1, index + this.bufferAmount);
      index = Math.min(this.items.length - 1, index);

      const top = this.items
        .slice(0, index)
        .reduce((acc, iItem) => acc + this.getItemHeight(iItem), 0);
      const scrollTop = this.elm.nativeElement.scrollTop;
      const scrollBottom = scrollTop + this.elm.nativeElement.offsetHeight;

      if (top < scrollTop || top > scrollBottom) {
        this.animateScrollTo(top, {
          element: this.elm.nativeElement,
          speed: 0
        });
      }
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.refresh();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.refresh();
  }

  updateViewportItems() {
    if (!this.items || !this.items.length || !this.getItemHeight) {
      return;
    }

    const scrollTop = this.elm.nativeElement.scrollTop;

    let removedItemsAmount = 0;
    let removedItemsHeight = 0;
    let iHeight = this.getItemHeight(this.items[0]);
    while (scrollTop >= iHeight) {
      removedItemsAmount++;
      removedItemsHeight = iHeight;
      iHeight += this.getItemHeight(this.items[removedItemsAmount]);
    }

    const start = removedItemsAmount;
    let end = start;

    let viewPortItemsHeight = 0;
    const visibleContainerHeight = parseFloat(
      getComputedStyle(this.elm.nativeElement).height
    );
    while (visibleContainerHeight >= viewPortItemsHeight && !!this.items[end]) {
      viewPortItemsHeight += this.getItemHeight(this.items[end]);
      end++;
    }

    if (this.bufferAmount > 0) {
      end =
        end + this.bufferAmount >= this.items.length
          ? this.items.length
          : end + this.bufferAmount;
    }

    // console.log('viewPortItemsAmount: ', end - start);

    if (this.previousStart !== start || this.previousEnd !== end) {
      this.topPadding = removedItemsHeight;
      const viewPortItems = this.items.slice(start, end);
      this.viewPortItemsUpdated.next(viewPortItems);
      this.previousStart = start;
      this.previousEnd = end;
    }
  }
}
