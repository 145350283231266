import './publisher-actions-and-media.component.scss';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OutboxFileType,
  OutboxPublisher,
  MediaCategory
} from '@ui-resources-angular';
import { Stage as RootStages } from '../../publisher.component';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { Stage as ParentStages } from '../publisher-landing.component';
import { FilestackSources } from '../../../../services/filestack/filestack.service';
import { FileUploadFile } from '../../../../directives/file-uploader/file-uploader.directive';
import {
  FileUploaderService,
  FsFile
} from '../../../../directives/file-uploader/file-uploader.service';
import { AltTextModalComponent } from '../../alt-text-modal/alt-text-modal.component';
import { CompanyService } from '../../../../services/company/company.service';
import { UploadSubtitlesModalComponent } from '../../upload-subtitles-modal/upload-subtitles-modal.component';
import {
  PublisherActive,
  PUBLISHER_ACTIVE,
  PublisherDisableOptions
} from '../../publisher-active';
import { LANGUAGES } from '../../../../../common/constants';
import { AccountTypeId } from '../../../../../common/enums';
import { previewSupportedAccountTypeIds } from '../../publisher-social-network-preview-modal/publisher-social-network-preview-modal.component';

@Component({
  selector: 'ssi-publisher-actions-and-media',
  templateUrl: './publisher-actions-and-media.component.html',
  host: {
    // tslint:disable-line
    '[class.media-visible]': 'hasMedia'
  }
})
export class PublisherActionsAndMediaComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() post: OutboxPublisher;
  @Input() hasMedia: boolean;
  @Input() disable: PublisherDisableOptions;
  @Input() publishingLoadingTracker: AsyncTracker;
  @Input() postInvalidTooltipTemplate: TemplateRef<any>;
  @Input() fileUploadSources: FilestackSources[];
  @Input() disablePublish: boolean;

  @Output() changeStage = new EventEmitter<{ stage: string; root: boolean }>();
  @Output() publish = new EventEmitter();
  @Output() showPostPreview = new EventEmitter();
  @Output() fileUploadSuccess = new EventEmitter<FileUploadFile[]>();
  @Output() fileUploadError = new EventEmitter();
  @Output() saveAsDraft = new EventEmitter<void>();

  OutboxFileType = OutboxFileType;
  MediaCategory = MediaCategory;
  imageLoadingTracker = this.asyncTrackerFactory.create();
  videoResizingInProgress = false;
  stages = {
    parent: ParentStages,
    root: RootStages
  };
  actionTooltipPlacement: string;
  activeMediaItemIndex = 0;
  filename = '';
  languageName = 'Select your language';

  protipTooltips = {
    2: {
      accountName: 'X',
      description: 'POSTING_TO_X_PRO_TIP_DESCRIPTION'
    },
    3: {
      accountName: 'Facebook',
      description: 'POSTING_TO_FACEBOOK_PRO_TIP_DESCRIPTION'
    },
    4: {
      accountName: 'LinkedIn',
      description: 'POSTING_TO_LINKEDIN_PRO_TIP_DESCRIPTION'
    },
    6: {
      accountName: 'YouTube',
      description: 'POSTING_TO_YOUTUBE_PRO_TIP_DESCRIPTION'
    },
    12: {
      accountName: 'Instagram',
      description: 'POSTING_TO_INSTAGRAM_PRO_TIP_DESCRIPTION_2'
    }
  };

  destroyed$ = new Subject<void>();

  constructor(
    private asyncTrackerFactory: AsyncTrackerFactory,
    private fileUploaderService: FileUploaderService,
    private modal: NgbModal,
    private modalService: NgbModal,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive,
    private company: CompanyService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasMedia) {
      this.actionTooltipPlacement = this.hasMedia ? 'bottom' : 'top';
    }
  }

  async ngOnInit() {
    this.fileUploaderService.videoResizingInProgress$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((inProgress) => {
        this.videoResizingInProgress = inProgress;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  removeMediaItem(file) {
    this.post.removeFile(file);
    const maxIndex = Math.max(0, this.post.files.length - 1);
    if (maxIndex < this.activeMediaItemIndex) {
      this.activeMediaItemIndex = maxIndex;
    }
  }

  onActiveMediaItemIndexChange(index: number): void {
    this.activeMediaItemIndex = index;
  }

  async editImage(image: FsFile): Promise<void> {
    const editedImage = await this.fileUploaderService.editImage(
      image,
      this.post.isSplit
    );
    if (!editedImage) {
      // editor closed
      return;
    }
    if (this.post.isSplit) {
      const indexToDelete = image.accountIdsToLinkTo.findIndex(
        (id) => id === this.post.splitPostAccount.id
      );
      image.accountIdsToLinkTo.splice(indexToDelete, 1);
      this.post.addFile(
        {
          url: editedImage.url,
          type: OutboxFileType.Image,
          mediaCategory: editedImage.mediaCategory,
          filename: image.filename,
          edited: true
        },
        undefined,
        false
      );
    } else {
      image.url = editedImage.url;
      image.handle = editedImage.handle;
      image.size = editedImage.size;
      image.mimetype = editedImage.mimetype;
      image.filename = editedImage.filename;
      image.status = editedImage.status;
      image.filestackFile = { ...image.filestackFile, ...editedImage };
    }
  }

  canAddAltText(accounts) {
    const validAccount = accounts.find((account) =>
      [
        String(AccountTypeId.Twitter),
        String(AccountTypeId.Facebook),
        String(AccountTypeId.LinkedIn)
      ].includes(account.account_type_id)
    );
    return validAccount ? true : false;
  }

  postPreviewUnsupported(): boolean {
    const previewUnsupported =
      this.post.accounts.every(
        (acc) =>
          !previewSupportedAccountTypeIds.includes(Number(acc.account_type_id))
      ) ||
      !previewSupportedAccountTypeIds.includes(
        Number(this.post.splitPostAccount.account_type_id)
      );
    return previewUnsupported;
  }

  async addAltText(image) {
    const modal = await this.modalService.open(AltTextModalComponent, {});
    modal.componentInstance.altText = image.alt_text ? image.alt_text : '';

    modal.result.then((text) => {
      if (text) {
        image.alt_text = text;
      } else {
        return;
      }
    });
  }

  async addSubtitles(video) {
    const modal = await this.modalService.open(
      UploadSubtitlesModalComponent,
      {}
    );

    modal.componentInstance.selectedLocale.displayName = this.languageName;
    modal.componentInstance.subtitleFilename = this.filename;
    if (
      video.subtitles &&
      video.subtitles.length &&
      video.subtitles[0].locale
    ) {
      modal.componentInstance.selectedLocale = {
        locale: video.subtitles[0].locale,
        displayName: LANGUAGES[video.subtitles[0].locale]
      };
      modal.componentInstance.subtitleFile = {
        captions_url: video.subtitles[0].captions_url,
        locale: video.subtitles[0].locale
      };
      modal.componentInstance.subtitleFilename = 'Edit captions';
    }

    modal.result.then((subtitle) => {
      if (subtitle) {
        console.log('subtitle:', subtitle);
        const { subtitleFile, fileName, languageName } = subtitle;
        if (subtitleFile.locale && fileName) {
          video.subtitles = subtitleFile;
          this.filename = fileName;
        }
        this.languageName = languageName;
      } else {
        return;
      }
    });
  }

  get activeMediaItem() {
    // if there's no item at given index (i.e. when switching split accs with different amount of images attached use the last item)
    return this.post.files[this.activeMediaItemIndex] === undefined
      ? this.post.files[this.post.files.length - 1]
      : this.post.files[this.activeMediaItemIndex];
  }
}
