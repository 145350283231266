export enum TagType {
  Inbox = 'inbox',
  Post = 'post',
  Validation = 'validation'
}

export class Tag {
  id: number;
  name: string;
  disabled: boolean;

  constructor(props: any = {}) {
    this.id = props.id;
    this.name = props.name;
    this.disabled = props.disabled;
  }
}
