<div
  #actionPad
  class="action-pad-container"
  [ngClass]="'action-pad-container-' + layout"
  [class.silenced]="isSilenced"
>
  <div class="action-pad-container-left">
    <button
      class="hidden-on-large-screens back-button action-pad-button"
      (click)="inboxMode === InboxMode.Conversation ? closeConversation() : closeActivity()"
    >
      <i class="ssi ssi-line-arrow-small-down"></i>
      <span *ngIf="layout !== ActionPadLayout.Mobile" translate="BACK"></span>
    </button>
    <button class="assignment-action" (click)="changeStatus()">
      <ng-container *ngIf="activity?.statusText === 'unread'">
        <i
          [ssiTooltip]="'UNREAD' | translate"
          tooltipPlacement="bottom"
          tooltipWindowClass="tooltip-chunky"
          [tooltipDisabled]="layout !== ActionPadLayout.Mobile"
          class="ssi ssi-new-message"
        ></i>
        <span *ngIf="layout !== ActionPadLayout.Mobile" translate="UNREAD"></span>
      </ng-container>

      <ng-container *ngIf="activity?.statusText === 'unactioned'">
        <i
          [ssiTooltip]="'UNACTIONED' | translate"
          tooltipPlacement="bottom"
          tooltipWindowClass="tooltip-chunky"
          [tooltipDisabled]="layout !== ActionPadLayout.Mobile"
          class="ssi ssi-unactioned"
        ></i>
        <span *ngIf="layout !== ActionPadLayout.Mobile" translate="UNACTIONED"></span>
      </ng-container>

      <span
        *ngIf="activity?.statusText === 'actioned'"
        class="assignment-action-meta-wrap"
        [ssiTooltip]="activity?.actionedBy?.fullName + ' actioned this message'"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      >
        <span
          class="assignment-action-meta"
          *ngIf="layout !== ActionPadLayout.Mobile"
        >
          {{ activity.actionedBy?.fullName }}
        </span>
        <span
          *ngIf="layout !== ActionPadLayout.Mobile"
          class="assignment-action-meta assignment-action-meta-timestamp"
        >
          {{ activity.inbox.actioned_at | date:'d MMM HH:mm' }}
        </span>
        <i *ngIf="layout === ActionPadLayout.Mobile" class="ssi ssi-assigned"></i>
      </span>
    </button>

    <!-- conversation mode - just display what's there -->
    <ssi-inbox-priority-indicator
      *ngIf="conversation && conversation.priority"
      [priority]="conversation.priority"
      [showSingleValue]="layout === ActionPadLayout.Mobile"
      [type]="layout === ActionPadLayout.Mobile ? 'badge' : 'text'"
      [tooltipText]="layout === ActionPadLayout.Mobile ? 'P' + conversation.priority : 'Priority 0' + conversation.priority"
    ></ssi-inbox-priority-indicator>

    <!-- individual message mode because it CAN be changed from the action pad -->
    <ssi-inbox-priority-select
      *ngIf="!conversation"
      [showSingleValue]="layout === ActionPadLayout.Mobile"
      [selectedPriority]="activity?.inbox.priority"
      (prioritySelect)="activity.changePriority($event)"
    ></ssi-inbox-priority-select>
  </div>

  <div class="action-pad-container-right action-pad-actions">
    <orlo-button
      class="action-pad-actions-assign"
      [textColor]="isSilenced ? '#fff' : '#838EAB'"
      [bgColor]="isSilenced ? '#43537F' : '#fff'"
      state="primary"
      [leadingIcon]="activity?.assignedToUser?.fullName || conversation?.assignedToUser?.fullName  || activity?.assignedToTeam?.name || conversation?.assignedToTeam?.name ? 'ssi ssi-assigned' : 'ssi ssi-assign-correct'"
      text="{{ activity?.assignedToUser?.fullName || conversation?.assignedToUser?.fullName || activity?.assignedToTeam?.name || conversation?.assignedToTeam?.name || ('ASSIGN' | translate) }}"
      (btnClick)="openAssign()"
      [ssiTooltip]="
        (activity?.assignedToUser?.fullName || activity?.assignedToTeam?.name && !isPushMode ? 'Message assigned to ' + (activity?.assignedToUser?.fullName || activity?.assignedToTeam?.name) : '') +
        ((activity?.assignedToUser?.fullName || activity?.assignedToTeam?.name) && (conversation?.assignedToUser?.fullName || conversation?.assignedToTeam?.name) ? ', <br>' : '') +
        (conversation?.assignedToUser?.fullName || conversation?.assignedToTeam?.name ? 'Conversation assigned to ' + (conversation?.assignedToUser?.fullName || conversation?.assignedToTeam?.name) : '')
      "
      tooltipWindowClass="tooltip-chunky"
      [tooltipAppendToBody]="true"
      [tooltipDisabled]="conversation && !(activity?.assignedToUser?.fullName || conversation?.assignedToUser?.fullName  || activity?.assignedToTeam?.name || conversation?.assignedToTeam?.name)"
    ></orlo-button>

    <orlo-button
      class="unsilence"
      *ngIf="isSilenced"
      [ssiTooltip]="'THESE_MESSAGES_ARE_HIDDEN_FROM_THE_DEFAULT_INBOX_VIEW_RIGHT_NOW_CLICK_HERE_TO_UNSILENCE_THE_THREAD' | translate"
      tooltipTitle="This thread is silenced"
      tooltipPlacement="bottom"
      tooltipWindowClass="tooltip-chunky-big"
      [tooltipAppendToBody]="true"
      textColor="#f88c68"
      bgColor="#fff"
      state="primary"
      [leadingIcon]="'ssi ssi-silence-thread'"
      text="Unsilence"
      (btnClick)="unsilenceThread()"
    ></orlo-button>

    <div
      *ngIf="layout !== ActionPadLayout.Desktop"
      ngbDropdown
      #menuDropdown="ngbDropdown"
      autoClose="outside"
      placement="bottom-right"
    >
      <button ngbDropdownToggle class="action-pad-button ap-menu-button">
        <i class="ssi ssi-more-actions"></i>
      </button>
      <ul class="ap-menu-dropdown" ngbDropdownMenu>
        <li
          *ngIf="!isSilenced && !!activityThread?.thread?.current.canToggleSilenced && this.companyPreferencesHolder.company_preferences.can_silence_inbox_threads == '1'"
          (click)="silenceThread()"
        >
          <i class="ssi ssi-silence-thread"></i>
          <span class="ap-menu-label">Silence Thread</span>
        </li>
        <li (click)="pin()">
          <i class="ssi ssi-pin-correct"></i>
          <span class="ap-menu-label">{{ 'PIN_MESSAGE' | translate }}</span>
        </li>
        <li
          *ngIf="authUser?.hasCompanyPermission('can_redact') && actionRedact"
          (click)="redactMessage()"
        >
          <i class="ssi ssi-redacted"></i>
          <span class="ap-menu-label">Redact message</span>
        </li>
        <li
          *ngIf="authUser?.hasCompanyPermission('administer_company') && auditingEnabled"
          (click)="openAudit()"
        >
          <i class="ssi ssi-historyaudit"></i>
          <span class="ap-menu-label">{{ 'INBOX_AUDIT' | translate }}</span>
        </li>
        <li>
          <div class="tags-select-container">
            <ssi-dropdown-select-2 #tagsSelector
                                   xPlacement="right"
                                   [options]="tags"
                                   [filterable]="true"
                                   [multiple]="true"
                                   [toggleAllEnabled]="false"
                                   [filterPlaceholder]="'Type inbox tags...'"
                                   [(ngModel)]="selectedTags"
                                   (ngModelChange)="selectedTagsChange($event)">
              <ng-template ssiTemplateSelector="headButton"
                           let-expanded="expanded"
                           let-toggle="toggle">
                <div class="ap-menu-item-wrap"
                     (click)="toggle(); setTagsForDropdown()">
                  <i class="action-trigger ssi ssi-tag-test"
                     [class.action-trigger-active]="expanded"
                     [ssiTooltip]="'Tag Message'"
                     tooltipPlacement="bottom"
                     tooltipWindowClass="tooltip-chunky"
                     [tooltipAppendToBody]="true"></i>
                  <span class="ap-menu-label">Tag Message</span>
                </div>
              </ng-template>

              <ng-template ssiTemplateSelector="emptyState"
                           let-toggle="toggle">
                <div class="no-tags-state"
                     *ngIf="!tagsSelector.filterTerm">
                  <i class="ssi ssi-tags"></i>
                  <p class="title">No Post Tags yet!</p>
                  <p class="desc">Just start typing then create your new tags</p>
                </div>
                <div class="no-tags-state"
                     *ngIf="tagsSelector.filterTerm">
                  <button type="button"
                          class="g-btn"
                          *ngIf="tagsSelector.filterTerm"
                          (click)="createTag(tagsSelector.filterTerm)"><span>Create "{{tagsSelector.filterTerm}}"</span><i class="ssi ssi-plus"></i></button>
                </div>
              </ng-template>
            </ssi-dropdown-select-2>

            <div class="selected-tags-count"
                  *ngIf="activity && activity.tags && activity.tags.length">
              <span>{{activity?.tags.length}}</span>
            </div>
          </div>
          <!-- <i class="ssi ssi-tag-test"></i>
          <span>Tag Message</span> -->
        </li>
        <li
          *ngIf="conversation && activity?.account?.account_type_id !== '2'"
          (click)="openExportConversation()"
        >
          <i class="ssi ssi-export-convo"></i>
          <span class="ap-menu-label">{{ 'EXPORT_CONVERSATION' | translate }}</span>
        </li>
        <li *ngIf="conversation && !conversation.resolved">
          <ssi-conversation-hold
            class="action-trigger"
            [conversation]="conversation"
            [canReply]="false"
            [iconOnly]="true"
            [iconOnlyLabel]="true"
            [menuPlacementX]="'right'"
            (onHoldChange)="holdConversationStatusChange($event)"
          ></ssi-conversation-hold>
        </li>
        <li *ngIf="conversation">
          <ssi-conversation-resolve
            class="action-trigger no-pointer"
            [conversation]="conversation"
            [activityThread]="activityThread"
            [replyAccount]="activity?.account"
            [isPrivateThread]="activity?.interaction.is_private"
            [sessionInvalid]="activity?.replyToSessionExpired"
            [canReply]="false"
            [iconOnly]="true"
            [iconOnlyLabel]="true"
            [menuPlacementX]="'right'"
            (onResolve)="resolve($event)"
          ></ssi-conversation-resolve>
        </li>
        <li (click)="showLanguageSelector = !showLanguageSelector">
          <i class="ssi ssi-language-test"></i>
          <span class="ap-menu-label">{{ 'LANGUAGE' | translate }}</span>
          <ssi-inbox-language-selector
            class="action-triggers-dropdown"
            (ssiOffClick)="showLanguageSelector = false"
            (onChangeItem)="onChangeLanguage.emit($event); showLanguageSelector = false"
            *ngIf="showLanguageSelector"
            [placeholder]="'TYPE_LANGUAGE'"
            [items]="formattedLanguages"
            [activeItems]="activity?.properties.language"
            [canAdd]="false"
          ></ssi-inbox-language-selector>
        </li>
        <li (click)="openEmailExport()">
          <i class="ssi ssi-forward"></i>
          <span class="ap-menu-label">{{ 'EMAIL_MESSAGES' | translate }}</span>
        </li>
        <li
          *ngIf="layout === ActionPadLayout.Mobile"
          (click)="activity?.author?.id && showProfile(true)"
        >
          <i class="ssi ssi-socialprofile"></i>
          <span class="ap-menu-label">Social Profile</span>
        </li>
      </ul>
    </div>
    <div *ngIf="layout === ActionPadLayout.Desktop" class="action-triggers">
      <i
        class="action-trigger ssi ssi-silence-thread"
        (click)="silenceThread()"
        *ngIf="!isSilenced && !!activityThread?.thread.current.canToggleSilenced && this.companyPreferencesHolder.company_preferences.can_silence_inbox_threads == '1'"
        [ssiTooltip]="'TO_HIDE_THESE_MESSAGES_FROM_THE_DEFAULT_INBOX_VIEW_SILENCE_THE_THREAD_YOU_CAN_STILL_RESPOND_TO_THESE_MESSAGES_AND_YOU_CAN_UNSILENCE_THE_THREAD_AT_ANY_TIME' | translate"
        tooltipTitle="Silence this thread"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky-big"
        [tooltipAppendToBody]="true"
      ></i>
      <i
        class="action-trigger ssi ssi-pin-correct"
        (click)="pin()"
        [ssiTooltip]="'PIN_MESSAGE' | translate"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      ></i>
      <i
        class="action-trigger ssi ssi-redacted"
        *ngIf="authUser?.hasCompanyPermission('can_redact') && actionRedact"
        (click)="redactMessage()"
        [ssiTooltip]="'Redact Message'"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      ></i>
      <i
        *ngIf="authUser?.hasCompanyPermission('administer_company') && auditingEnabled"
        class="action-trigger ssi ssi-historyaudit"
        (click)="openAudit()"
        [ssiTooltip]="'INBOX_AUDIT' | translate"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      ></i>
      <div class="tags-select-container">
        <ssi-dropdown-select-2 #tagsSelector
                               [xPlacement]="'right'"
                               [options]="tags"
                               [filterable]="true"
                               [multiple]="true"
                               [toggleAllEnabled]="false"
                               [filterPlaceholder]="'Type inbox tags...'"
                               [(ngModel)]="selectedTags"
                               (ngModelChange)="selectedTagsChange($event)">
          <ng-template ssiTemplateSelector="headButton"
                       let-expanded="expanded"
                       let-toggle="toggle">
            <i class="action-trigger ssi ssi-tag-test"
               [class.action-trigger-active]="expanded"
               (click)="toggle(); setTagsForDropdown()"
               [ssiTooltip]="'Tag Message'"
               tooltipPlacement="bottom"
               tooltipWindowClass="tooltip-chunky"
               [tooltipAppendToBody]="true"></i>
          </ng-template>

          <ng-template ssiTemplateSelector="emptyState"
                       let-toggle="toggle">
            <div class="no-tags-state"
                 *ngIf="!tagsSelector.filterTerm">
              <i class="ssi ssi-tags"></i>
              <p class="title">No Post Tags yet!</p>
              <p class="desc">Just start typing then create your new tags</p>
            </div>
            <div class="no-tags-state"
                 *ngIf="tagsSelector.filterTerm">
              <button type="button"
                      class="g-btn"
                      *ngIf="tagsSelector.filterTerm"
                      (click)="createTag(tagsSelector.filterTerm)"><span>Create "{{tagsSelector.filterTerm}}"</span><i class="ssi ssi-plus"></i></button>
            </div>
          </ng-template>
        </ssi-dropdown-select-2>

        <div class="selected-tags-count"
              *ngIf="activity && activity.tags && activity.tags.length">
          <span>{{activity?.tags.length}}</span>
        </div>
      </div>
      <i
        *ngIf="conversation && activity?.account?.account_type_id !== '2'"
        class="action-trigger ssi ssi-export-convo"
        (click)="openExportConversation()"
        [ssiTooltip]="'EXPORT_CONVERSATION' | translate"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      ></i>
      <ssi-conversation-hold
        *ngIf="conversation && !conversation.resolved"
        class="action-trigger"
        [conversation]="conversation"
        [canReply]="false"
        [iconOnly]="true"
        [menuPlacementX]="'right'"
        (onHoldChange)="holdConversationStatusChange($event)"
      ></ssi-conversation-hold>
      <ssi-conversation-resolve
        *ngIf="conversation"
        class="action-trigger no-pointer"
        [conversation]="conversation"
        [activityThread]="activityThread"
        [replyAccount]="activity?.account"
        [isPrivateThread]="activity?.interaction.is_private"
        [sessionInvalid]="activity?.replyToSessionExpired"
        [canReply]="false"
        [iconOnly]="true"
        [menuPlacementX]="'right'"
        (onResolve)="resolve($event)"
      ></ssi-conversation-resolve>
      <i
        class="action-trigger ssi ssi-language-test"
        [class.action-trigger-active]="showLanguageSelector"
        (click)="showLanguageSelector = !showLanguageSelector"
        [tooltipTitle]="'LANGUAGE' | translate"
        [ssiTooltip]="languages[activity?.properties.language]"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      ></i>
      <i
        class="action-trigger ssi ssi-forward"
        (click)="openEmailExport()"
        [ssiTooltip]="'EMAIL_MESSAGES' | translate"
        tooltipPlacement="bottom"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      ></i>

      <ssi-inbox-language-selector
        class="action-triggers-dropdown"
        (ssiOffClick)="showLanguageSelector = false"
        (onChangeItem)="onChangeLanguage.emit($event); showLanguageSelector = false"
        *ngIf="showLanguageSelector"
        [placeholder]="'TYPE_LANGUAGE'"
        [items]="formattedLanguages"
        [activeItems]="activity?.properties.language"
        [canAdd]="false"
      ></ssi-inbox-language-selector>
    </div>

    <ng-container *ngIf="layout !== ActionPadLayout.Mobile">
      <button
        class="action-trigger-view-profile action-pad-button"
        (click)="activity?.author?.id && showProfile(true)"
        [class.unavailable]="!activity?.author?.id"
        [ssiTooltip]="'VIEW_PROFILE' | translate"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      >
        <i class="ssi ssi-socialprofile"></i>
      </button>
      <button
        class="action-trigger-toggle-profile action-pad-button"
        (click)="showProfile(false)"
        [ssiTooltip]="'TOGGLE_PROFILE' | translate"
        tooltipWindowClass="tooltip-chunky"
        [tooltipAppendToBody]="true"
      >
        <i class="ssi ssi-socialprofile"></i>
      </button>
    </ng-container>
  </div>
</div>
