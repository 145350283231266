import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxCrmExternalIntegrationPersonComponent } from './inbox-crm-external-integration-person.component';
import { OnDomEventModule } from '../../../../directives/on-dom-event/on-dom-event.module';

@NgModule({
  imports: [CommonModule, OnDomEventModule],
  declarations: [InboxCrmExternalIntegrationPersonComponent],
  exports: [InboxCrmExternalIntegrationPersonComponent]
})
export class InboxCrmExternalIntegrationPersonModule {}
