import { differenceInDays, startOfDay } from 'date-fns';
import { objKeysMatch } from '../../../utils';

export class Campaign {
  /**
   * Returned as string from API, but OutboxModel is using an old CampaignModel which has defined it as a string
   */
  id: string;
  name: string;
  created_at: Date;
  started_at: Date;
  closed_at: Date;
  deleted_at: string;
  is_closed: boolean;
  is_deleted: boolean;
  is_started: boolean;
  parent_id: string;

  constructor(props: any = {}) {
    this.id = props.id;
    this.name = props.name;
    this.created_at = props.created_at;
    this.started_at = props.started_at;
    this.closed_at = props.closed_at;
    this.deleted_at = props.deleted_at;
    this.is_closed = props.is_closed;
    this.is_deleted = props.is_deleted;
    this.is_started = props.is_started;
    this.parent_id = props.parent_id;
  }

  /**
   * Number of days between the campaign start and end date.
   */
  get age_days(): number {
    const start = this.started_at || this.created_at;
    const end = this.is_closed ? this.closed_at : new Date();
    const ageDays = differenceInDays(startOfDay(end), startOfDay(start));
    if (ageDays < 0) {
      return;
    }
    return ageDays;
  }

  // setter must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set age_days(value: number) {}

  /**
   * Checks whether the provided object is an instance of Campaign,
   * but in a way of checking if the object has a set of property keys defined on the Campaign class.
   *
   * Helpful in a situation where 'value instanceof Campaign' can't be used
   * (e.g. the actual instance of Campaign is re-mapped to plain JS object)
   */
  static is(value: Campaign): boolean {
    return objKeysMatch(value, new Campaign());
  }
}
