<div class="network-engagement-section">
  <section class="section-grid section-grid-thirds">
    <div class="section-grid-item">
      <article class="section-card">
        <p class="section-subtitle">{{'INBOUND_MESSAGES_BY_NETWORK' | translate}}</p>
        <ul class="chart-bar">
          <ng-container *ngFor="let source of sourcesData.values">
            <li *ngIf="source.y" class="chart-bar-row">
              <p class="chart-bar-row-key" [innerHTML]="source.name"></p>
              <p class="chart-bar-row-value" [innerHTML]="source.y | bigNumber"></p>
              <div class="chart-bar-row-progress">
                <span [style.width.%]="source.y * 100 / sourcesData.highestValue"></span>
              </div>
            </li>
          </ng-container>
        </ul>
      </article>
    </div>
    <div class="section-grid-item">
      <article class="section-card">
        <p class="section-subtitle">{{'LANGUAGES' | translate}}</p>
        <div class="chart-wrap">
          <ssi-highchart [config]="languageData.chart"></ssi-highchart>
          <ul class="chart-legend">
            <li *ngFor="let language of languageData.values">
              <span class="stat-graph-chart-legend" [style.background-color]="language.color"></span>
              <p class="stat-graph-chart-amount" [innerHTML]="language.y"></p>
              <p class="stat-graph-chart-name" [innerHTML]="language.name">text</p>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </section>
</div>