import { utils, Record } from 'js-data';
import { api } from '../../core/services/api';
import { Account } from '../../account/services/accountModel';
import { Campaign } from '../../campaign/services/campaignModel';
import { Profile } from '../../profile/services/profileModel';
import { Note, NoteModel } from '../../note/services/noteModel';
import { Activity, ActivityModel } from '../../activity/services/activityModel';
import { services } from '../../common';
import { Model } from '../../model';
import { UserModel } from '../../user/services/userModel';
import { AccountModel } from '../../account/services/accountModel';
import {
  ColleaguesService,
  Colleague,
  OutboxTagsService,
  Tag,
  TagType
} from '../../../../../apps/angular/common/services/api';
import { appInjector } from '../../../../../apps/angular/app-injector';
import {
  getOutboxMessageClass,
  OutboxMessage,
  OutboxMessageClass,
  OutboxMessageType,
  OutboxPublisher,
  OutboxPublisherMention,
  OutboxPublisherHighlightType,
  AutoComment
} from './outboxPublisher';
import addMonths from 'date-fns/add_months';
import addMinutes from 'date-fns/add_minutes';
import startOfDay from 'date-fns/start_of_day';
import isEqual from 'date-fns/is_equal';
import addDays from 'date-fns/add_days';
import setHours from 'date-fns/set_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInDays from 'date-fns/difference_in_days';
import { BooleanModel } from 'aws-sdk/clients/gamelift';
import { ImageMimetype, VideoMimetype } from '../../constants';

export class Outbox extends Record {
  id: string;
  activity_id?: string;
  account_id: string;
  account: Account;
  annotations: { links: any[]; mentions: any[] };
  attempts: string;
  campaign_id: string;
  campaign: Campaign | { [key: string]: any }; // cuz new campaign model cannot be imported here
  clicks: number;
  clicks_es: number;
  comment_count: string;
  created_at: string;
  deleted_at: string | Date;
  deleted_by: string;
  delete_at?: string | Date;
  in_reply_to_social_id?: string;
  is_draft: boolean;
  is_validated: boolean;
  last_attempt_at: string;
  like_count: string;
  link: any;
  link_data: any;
  link_prefix: any;
  media_path: any;
  media_type: any;
  note_count: number;
  impressions: any;
  paid_impressions?: any;
  outbox_files: {
    album_order: string;
    album_text: string;
    file_id: string;
    id: string;
    mimetype: string;
    public_url: string;
    alt_text?: string;
  }[];
  failed_files?: string;
  outbox_merge_candidate_id: string;
  paramaters: any;
  reach: string;
  paid_reach?: string;
  response: string;
  send_at: string;
  sent_at: string;
  reaction_count: string;
  share_count: string;
  target: any;
  target_data: any;
  text: string;
  type: OutboxMessageType;
  updated_at: string;
  user_id: string;
  validator_id: string;
  notes: Note[];
  externalLink: string;
  ad_count: number;
  seq: string;
  engagement_rate: number;
  engagement_rate_reactions: number;
  is_thread_parent?: boolean;
  view_count?: number | string;
  post_tags: Array<{ id: number; name: string }>;
  rejected_at?: Date;
  trigger_dt?: string | Date;
  /**
   * ID of a user
   */
  rejected_by?: string;
  validation_tags: { id: number; name: string }[];
  // Instagram story related metrics
  story_metrics?: {
    taps_back?: number;
    taps_forward?: number;
    exits?: number;
  };
  auto_comment?: AutoComment[];

  // For Inbox use
  in_reply_to_root_thread_id?: string;
  in_reply_to_status_id?: string;
  conversation_link?: string;

  // inbox validations, constructed in FE
  visibility?: 'all' | 'private' | 'public';
  inboxThreadLink?: string;

  get author(): Colleague {
    if (!this.user_id) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.user_id);
  }

  get validator(): Colleague {
    if (!this.validator_id) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.validator_id);
  }

  get deletedBy(): Colleague {
    if (!this.deleted_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.deleted_by);
  }

  get rejectedBy(): Colleague {
    if (!this.rejected_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.rejected_by);
  }

  get validationTags(): string[] {
    if (!this.validation_tags) {
      return undefined;
    }
    const tags = this.validation_tags.map((t) => ` ${t.name}`);
    return tags;
  }

  // setters must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set author(c: Colleague) {}
  set validator(c: Colleague) {}
  set deletedBy(c: Colleague) {}

  get scheduled(): boolean {
    return this.send_at && !this.sent_at && !this.deleted_at;
  }
  set scheduled(s: boolean) {}

  private _external_url: string;
  get external_url(): string {
    return this._external_url;
  }
  set external_url(url: string) {
    this._external_url = url;

    if (this.external_url) {
      // this.external_url is currently available for Instagram posts only
      // Once https://orlo.atlassian.net/browse/CT-385 is done, this.external_url will be available for all posts
      this.externalLink = this.external_url;
    }
  }

  private _social_id: string;
  get social_id(): string {
    return this._social_id;
  }
  set social_id(socialId) {
    this._social_id = socialId;

    if (this.external_url) {
      // this.external_url is currently available for Instagram posts only
      // Once https://orlo.atlassian.net/browse/CT-385 is done, this.external_url will be available for all posts
      this.externalLink = this.external_url;
    } else {
      // the relation isn't always available on first run
      const account = services.models
        .get<AccountModel>('account')
        .get(this.account_id);

      if (
        socialId &&
        account &&
        account.socialNetwork &&
        typeof account.socialNetwork.getExternalLink === 'function'
      ) {
        this.externalLink = account.socialNetwork.getExternalLink(
          Object.assign({}, this, { account, social_id: socialId })
        );
      }
    }
  }

  get score() {
    return services.models
      .get<OutboxModel>('outbox')
      .getScore(
        this.comment_count,
        this.share_count,
        this.like_count,
        this.clicks
      );
  }
  set score(val) {}

  get interactionLifetime() {
    return differenceInDays(this.updated_at, this.sent_at);
  }
  set interactionLifetime(val) {}

  get messageClass(): OutboxMessageClass {
    return getOutboxMessageClass(this.type);
  }
  set messageClass(val) {}

  get hasMedia(): boolean {
    return this.outbox_files.length > 0;
  }
  set hasMedia(val) {}

  approve() {
    return api
      .post('outbox_v2/validation', {
        account_id: this.account.id,
        approved: true,
        id: this.id,
        seq: this.seq
      })
      .then(function (newPost) {
        services.models.get<OutboxModel>('outbox').inject(newPost.data);
        return newPost;
      });
  }

  disapprove(note: string, tags?: string[]) {
    return api
      .post('outbox_v2/validation', {
        account_id: this.account.id,
        approved: false,
        id: this.id,
        seq: this.seq,
        validator_note: note,
        delete: false,
        tags: tags,
        email_only: false
      })
      .then(() => this.markAsRejected());
  }

  remove() {
    return api
      .del('outbox/index', {
        params: { account_id: this.account.id, id: this.id }
      })
      .then(() => this.markAsDeleted());
  }

  markAsRejected() {
    return services.models
      .get<UserModel>('user')
      .getAuthUser()
      .then((user) => {
        this.rejected_at = new Date();
        this.rejected_by = user.id;
        return this;
      });
  }

  markAsDeleted() {
    return services.models
      .get<UserModel>('user')
      .getAuthUser()
      .then((user) => {
        this.deleted_at = new Date();
        this.deleted_by = user.id;
        return this;
      });
  }

  retryFailed(outboxId: string) {
    return api.post('outbox_v2/retryFailedPost', {
      id: outboxId
    });
  }

  getNotes() {
    if (!this.notes) {
      return services.models
        .get<NoteModel>('note')
        .findAll(
          {
            subject: 'outbox',
            subject_id: this.id
          },
          {
            showLoading: true
          }
        )
        .then((notes) => {
          this.notes = notes;
          return this;
        });
    } else {
      return utils.Promise.resolve(this);
    }
  }

  addNote(content) {
    return services.models
      .get<NoteModel>('note')
      .create(
        {
          subject: 'outbox',
          subject_id: this.id,
          content
        },
        {
          showLoading: true
        }
      )
      .then((note) => {
        this.notes = this.notes || [];
        this.notes.push(note);
        return this;
      });
  }

  deleteNote(note) {
    return note.destroy().then(() => {
      this.notes = this.notes.filter((iNote) => iNote !== note);
      return this;
    });
  }

  updateTags(tags: Array<{ id: number; name: string }>) {
    return api
      .put('outbox_v2/editTags', {
        id: this.id,
        tags: tags.map((t) => t.name)
      })
      .then((response) => {
        this.post_tags = tags;
        return services.models.get<OutboxModel>('outbox').inject(this);
      });
  }

  changeScheduledTime(newStart) {
    return api
      .post('outbox/schedule', {
        id: this.id,
        schedule: newStart
      })
      .then(({ data: post }) => {
        return services.models.get<OutboxModel>('outbox').inject(post);
      });
  }

  toOutboxPublisherMessageFormat(): Promise<OutboxMessage> {
    return api
      .get<{ data: { message: OutboxMessage } }>('outbox_v2/indexOutboxv2', {
        params: {
          mention_tag: 1,
          id: this.id
        }
      })
      .then(({ data }) => data.message);
  }

  mergeInto(post: Outbox): Promise<Outbox> {
    return api
      .post('outbox/merge', {
        src: this.id,
        dst: post.id
      })
      .then(({ data }) => {
        return this.markAsDeleted().then(() => {
          return services.models.get<OutboxModel>('outbox').inject(data);
        });
      });
  }

  getMentions(): OutboxPublisherMention[] {
    if (!Array.isArray(this.annotations && this.annotations.mentions)) {
      return [];
    }

    const account = services.models
      .get<AccountModel>('account')
      .get(this.account_id);

    return this.annotations.mentions.map((m) => {
      const start = m.offset;
      const end = m.offset + m.len;

      // JS strings are UTF-16 - emojis are encoded above the BMP and therefore
      // are represented by a pair of code points.
      // A workaround to adjust the length of the string containing
      // emojis so the backend index references match.
      // (spread operator does it in an encoding-independent way)
      const noEmojisText = [...this.text]
        .map((c) => (c.length > 1 ? '*' : c))
        .join('');

      const label = noEmojisText.slice(start, end);
      const username = label.replace('@', '');

      let url = '';
      if (account.isLinkedin()) {
        const id = m.li;
        const idParts = id.split(':');
        url =
          idParts[2] === 'organization'
            ? account.socialNetwork.urlBases.username +
              idParts[idParts.length - 1]
            : '';
      } else {
        url = account.socialNetwork.urlBases.username + username;
      }

      return {
        indices: {
          start: start,
          end: end
        },
        cssClass: 'publisher-link-tag-primary',
        data: {
          type: OutboxPublisherHighlightType.Mention,
          username: username,
          id: m.fb || m.tw || m.li,
          account_id: this.account_id,
          account_type_id: account.account_type_id,
          url: url
        }
      };
    });
  }

  isPublished(): boolean {
    return !!this.social_id && !!this.sent_at;
  }

  canEditAutoComment(): boolean {
    return (
      Array.isArray(this.auto_comment) &&
      !!this.auto_comment.length &&
      !this.isPublished()
    );
  }

  convertToActivity(replyingToActivity?: Activity): Activity {
    const getActivityMediaType = (media: string) => {
      let activityMediaType;
      switch (media) {
        case ImageMimetype.Jpeg:
        case ImageMimetype.Pjpeg:
        case ImageMimetype.Png:
        case ImageMimetype.Gif:
          activityMediaType = 'photo';
          break;
        case VideoMimetype.Mp4:
        case VideoMimetype.Mov:
          activityMediaType = 'video';
          break;
      }
      return activityMediaType;
    };
    const model = services.models.get<ActivityModel>('activity');

    return model.inject({
      id: this.id,
      account_id: this.account_id,
      account: this.account,
      author: {
        avatar: this.account.picture,
        id: this.account.social_id,
        // influence: 0,
        link: this.account.externalUrl,
        name: this.account.name,
        username: this.account.username
      },
      campaign: {
        id: +this.campaign_id
      },
      isTempActivity: false,
      interaction: {
        content: this.text,
        created_at: this.created_at,
        deleted_at: null,
        deleted_by: null,
        redacted_at: null,
        redacted_by: null,
        entities: {
          hashtags: [
            // {
            //   text: string,
            //   indices: [number, number],
            // }
          ],
          symbols: [],
          urls: [
            // {
            //   display_url: string,
            //   expanded_url: string,
            //   indices: [number, number],
            //   url: string,
            // }
          ],
          user_mentions: []
          // [
          //   {
          //     id: number,
          //     id_str: string,
          //     indices: [number, number],
          //     name: string,
          //     screen_name: string,
          //   }
          // ]
        },
        id: this.id,
        id_int: +this.id,
        is_private: replyingToActivity
          ? replyingToActivity.interaction.is_private
          : false,
        link: null,
        object_type: null,
        social_type: null,
        source: 'Orlo',
        updated_at: this.updated_at
      },

      // export interface ActivityMedia {
      //   link: string;
      //   size: {
      //     full: string;
      //     thumb: string;
      //   };
      //   type: string;
      // }

      // StatusUpdate = 'status_update',
      // Picture = 'picture',
      // Album = 'album',
      // Video = 'video',
      // Reply = 'reply',
      // ReplyPicture = 'reply_picture',
      // ReplyAlbum = 'reply_album',
      // ReplyVideo = 'reply_video',
      // PrivateMessage = 'private_message',
      // PrivatePictureMessage = 'private_picture_message',
      // MultiImage = 'multi_image', // used for IG carousels too
      // Share = 'share',
      // InstagramStory = 'instagram_story',
      // InstagramReel = 'instagram_reel'

      media: this.outbox_files.map((media) => ({
        link: media.public_url,
        size: {
          full: media.public_url,
          thumb: media.public_url
        },
        type: getActivityMediaType(media.mimetype)
      })),
      notes: this.notes,
      tags: this.post_tags.map((tag) => tag.name),
      inbox: {
        requires_action: false,
        is_viewed: true
      },
      social_actions: {
        can_like: false,
        can_reply: false,
        can_share: false,
        can_delete: false,
        can_hide: false,
        is_hidden: false,
        reply_type: 'reply',
        is_liked: false,
        is_shared: false,
        is_commented: false,
        share_count: 0,
        like_count: 0
      },
      properties: {
        language: 'en',
        sentiment: 0
      },
      outbox: this,
      isConvertedToActivity: true
    });
  }
}

export class OutboxModel extends Model<Outbox> {
  constructor() {
    super('outbox', {
      endpoint: 'outbox/index',
      deserialize: (resourceConfig, result) => result.data.posts || result.data,
      relations: {
        belongsTo: {
          account: {
            localKey: 'account_id',
            localField: 'account'
          },
          // colleague: [
          //   {
          //     localKey: 'user_id',
          //     localField: 'author'
          //   },
          //   {
          //     localKey: 'validator_id',
          //     localField: 'validator'
          //   },
          //   {
          //     localKey: 'deleted_by',
          //     localField: 'deletedBy'
          //   }
          // ],
          campaign: {
            localKey: 'campaign_id',
            localField: 'campaign'
          }
        }
      },
      recordClass: Outbox
    });
  }

  privateMessage(fromAccount: Account, toProfile: Profile, text: string) {
    return services.models
      .get<UserModel>('user')
      .getAuthUser()
      .then((authUser) => {
        const message = new OutboxPublisher(
          [fromAccount],
          authUser,
          false,
          false,
          [],
          {
            privateMessageSocialId: toProfile.id
          }
        );

        message.accounts = [fromAccount];
        message.text = text;
        return message.publish();
      });
  }

  getSuggestedPostTime(topHours, outboxPosts, dailyLimit): Date {
    if (!topHours || topHours.length === 0) {
      topHours = [9, 13, 17];
    }

    const timeThreshold = addMinutes(new Date(), 10);
    const outboxSendAtTimes = outboxPosts.map((post) => new Date(post.send_at));
    const oneMonthFromNow = addMonths(new Date(), 1);

    for (
      let day = startOfDay(new Date());
      day < oneMonthFromNow;
      day = addDays(day, 1)
    ) {
      const postTimesToday = outboxSendAtTimes.filter((time) =>
        isEqual(startOfDay(time), day)
      );
      if (postTimesToday.length < dailyLimit) {
        const candidatePostTimes = topHours
          .map((hour) => setHours(day, hour))
          .filter((time) => time > timeThreshold)
          .filter(
            (time) =>
              !outboxSendAtTimes.find(
                (outboxTime) =>
                  Math.abs(differenceInMinutes(outboxTime, time)) <= 60
              )
          );

        if (candidatePostTimes.length > 0) {
          return candidatePostTimes[0];
        }
      }
    }
  }

  getScore(
    comments: string | number = 0,
    shares: string | number = 0,
    likes: string | number = 0,
    clicks: string | number = 0
  ) {
    return Math.round(+comments * 4 + +shares * 3 + +clicks * 2 + +likes);
  }

  getOutboxById(id: number) {
    return api
      .get('outbox_v2/indexOutboxv2', {
        params: {
          id: id
        }
      })
      .then(({ data }) => data.message);
  }
}

export function outboxModelFactory(dataStore?) {
  return services.models.get('outbox') || new OutboxModel();
}
