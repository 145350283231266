<div class="linkedin-post-header">
  <img class="pull-left"
       [src]="account.picture"
       ssiFallbackImage="avatar">
  <div>
    <div>
      <a class="linkedin-post-account-name"
         [href]="account.externalUrl"
         target="_blank">{{ account.name }}</a>
    </div>
    <div class="linkedin-post-date-time">
      Just now
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="linkedin-post-body">
  <div class="linkedin-post-text">
    <span *ngIf="isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br | truncate: limit"></span>
    <span  *ngIf="!isTruncated" [ssiEmojiText]="text | outboxPostTextLinkify:account:links:mentions | nl2br"></span>
    <span class="text-read-more" *ngIf="isTruncated" (click)="isTruncated = false; limit = text.length">Read More</span>
  </div>

  <div class="linkedin-post-image"
       *ngIf="files.length && files[0].type === 'image'">
    <img [src]="files[0].url">
  </div>

  <div class="linkedin-post-video"
       *ngIf="files.length && files[0].type === 'video'">
    <video controls>
      <source [src]="files[0].url"
              type="video/mp4">
      Sorry but we could not load the video preview.
    </video>
  </div>
  
  <a *ngIf="preview && !files.length"
     [href]="preview.url"
     target="_blank"
     class="linkedin-post-link-preview">
    <div class="linkedin-post-link-preview-img"
         [style.background-image]="'url(' + preview.image + ')'"></div>
    <div class="linkedin-post-link-preview-footer">
      <div class="linkedin-post-link-preview-title">
        {{ preview.title }}
      </div>
      <div class="linkedin-post-link-preview-hostname">
        {{ preview.hostname }}
      </div>
    </div>
  </a>

</div>
<div class="linkedin-post-footer">
  <span class="linkedin-post-footer-item"><i [class]="account.socialNetwork.socialInteractions.like.icon.web"></i> Like </span>
  <span class="linkedin-post-footer-item"><i class="fa fa-comment-o"></i> Comment </span>
  <span class="linkedin-post-footer-item"><i class="fa fa-share"></i> Share </span>
</div>