<ssi-inline-add-note
  *ngIf="shouldShowNoteForm"
  [isPublic]="!activity.interaction.is_private"
  [inverse]="alignRight"
  [showGuide]="(isExpanded && activity.notes.length > 1) || shouldShowNoteForm"
  (cancelNote)="toggleAddNoteFormVisibility()"
  (addNote)="submitNewNote($event)">
</ssi-inline-add-note>

<ssi-inline-note
  [isPublic]="!activity.interaction.is_private"
  [note]="activity.notes[0]"
  [inverse]="alignRight"
  [notesAmount]="activity.notes.length"
  [showGuide]="isExpanded && activity.notes.length > 1"
  [showAddNote]="!shouldShowNoteForm"
  [showDeleteNote]="activity.notes[0].created_by === user?.id"
  [canCloseNote]="true"
  (addNote)="toggleAddNoteFormVisibility()"
  (deleteNote)="deleteNote(activity.notes[0])"
  (closeNotes)="closeNotes.emit(activity.notes[0])">
</ssi-inline-note>

<ng-container *ngIf="activity.notes.length > 1 && isExpanded">
  <ng-container *ngFor="let note of activity.notes; let index = index">
      <ssi-inline-note
        *ngIf="index !== 0"
        [isPublic]="!activity.interaction.is_private"
        [note]="note"
        [inverse]="alignRight"
        [showGuide]="index + 1 !== activity.notes.length"
        [showAddNote]="false"
        [showDeleteNote]="note.created_by === user?.id"
        (deleteNote)="deleteNote(note)"
        (closeNotes)="closeNotes.emit()">
      </ssi-inline-note>
  </ng-container>
</ng-container>

<div
  *ngIf="activity.notes.length > 1 && !isExpanded"
  [ngClass]="{'footer-inverse': alignRight}"
  class="footer">
  <orlo-button
    class="footer-button"
    textColor="#fff"
    bgColor="#668AEA"
    state="primary"
    [text]="'View ' + (activity.notes.length - 1) + ' more'"
    (btnClick)="isExpanded = true">
  </orlo-button>
</div>
