import './ad-stats-build.component.scss';

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import moment from 'moment';
import { orderBy } from 'lodash-es';

import { AccountModel, Account, AccountClass } from '@ui-resources-angular';
import { groupBy, mapToIterable } from '../../../../../common/utils';
import { AccountTypeId } from '../../../../../common/enums';
import {
  DateRange,
  commonDateRanges,
  DateRanges,
  commonDateRangesIterable
} from '../../../../../common/constants/common-date-ranges';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import {
  CompareToOption,
  CompareToOptions,
  compareToOptions,
  compareToOptionsIterable
} from '../../../../../common/constants/compare-to-options';

export interface ReportBuildFormValue {
  accountIds: string[];
  dateRange: string;
  compareTo: string;
  startDate: string;
  endDate: string;
  previousStartDate: string;
  previousEndDate: string;
  currency: string;
}

export interface CurrencyOption {
  key: string;
  label: string;
  symbol: string;
}

export const currencyOptions: { [key: string]: CurrencyOption } = {
  GBP: {
    key: 'GBP',
    label: 'British Pounds',
    symbol: '£'
  },
  USD: {
    key: 'USD',
    label: 'United States Dollars',
    symbol: '$'
  },
  EUR: {
    key: 'EUR',
    label: 'Euros',
    symbol: '€'
  }
};

export const currencyOptionsIterable: CurrencyOption[] = mapToIterable(
  currencyOptions
);

@Component({
  selector: 'ssi-ad-stats-build',
  templateUrl: './ad-stats-build.component.html',
  styles: []
})
export class AdStatsBuildComponent implements OnInit {
  dateRanges: DateRanges = commonDateRanges;
  dateRangesIterable: DateRange[] = commonDateRangesIterable;
  selectedDateRange: DateRange = this.dateRanges.month;

  startDate: Date = this.selectedDateRange.start;
  endDate: Date = this.selectedDateRange.end;
  previousStartDate: Date;
  previousEndDate: Date;
  today: Date = moment().endOf('day').toDate();

  compareToOptions: CompareToOptions = compareToOptions;
  compareToOptionsIterable: CompareToOption[] = compareToOptionsIterable;
  selectedCompareToOption: CompareToOption;

  currencyOptionsIterable: CurrencyOption[] = currencyOptionsIterable;
  selectedCurrencyOption: CurrencyOption = currencyOptions.GBP;

  accounts: Account[] = [];
  selectedAccounts: Account[] = [];
  selectedAccountTypeIds: string[] = [];
  selectAllAccountsChecked = false;

  constructor(
    private state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  async ngOnInit() {
    if (this.state.params.compareTo) {
      this.selectedCompareToOption = this.compareToOptions[
        this.state.params.compareTo
      ];
    } else {
      this.selectedCompareToOption = this.compareToOptions.noComparison;
    }
    this.updateCompareToDates();

    if (this.state.params.startDate && this.state.params.endDate) {
      if (this.state.params.dateRange) {
        this.selectPresetDateRange(
          this.dateRanges[this.state.params.dateRange]
        );
      } else {
        this.onStartDatePicked(new Date(this.state.params.startDate));
        this.onEndDatePicked(new Date(this.state.params.endDate));
      }
    }

    if (this.state.params.previousStartDate) {
      this.previousStartDate =
        this.state.params.previousStartDate &&
        new Date(this.state.params.previousStartDate);
    }

    if (this.state.params.previousEndDate) {
      this.previousEndDate =
        this.state.params.previousEndDate &&
        new Date(this.state.params.previousEndDate);
    }

    if (this.state.params.dateRange) {
      this.selectedDateRange = commonDateRanges[this.state.params.dateRange];
    }

    if (this.state.params.currency) {
      this.selectedCurrencyOption = currencyOptionsIterable.find(
        (option) => option.key === this.state.params.currency
      );
    }

    await this.setAccounts();

    if (this.state.params.accountIds) {
      this.selectedAccounts = Array.isArray(this.state.params.accountIds)
        ? this.state.params.accountIds.map((id) => this.accountModel.get(id))
        : [this.accountModel.get(this.state.params.accountIds)];

      this.selectAllAccountsChecked =
        this.selectedAccounts.length &&
        this.selectedAccounts.length === this.accounts.length;
    }
  }

  async setAccounts(): Promise<void> {
    this.accounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId(),
      { accountClass: AccountClass.Advertising }
    );

    // TODO: only facebook ad accounts supported for now - remove once other are supported
    this.accounts = this.accounts.filter(
      (a) => a.account_type_id === String(AccountTypeId.FacebookAds)
    );

    this.accounts = this.accounts.filter(
      (a) => a.ad_currency === this.selectedCurrencyOption.key
    );

    this.accounts = orderBy(this.accounts, ['account_type_name', 'name']);
  }

  async currencySelect(option: any): Promise<void> {
    this.selectedCurrencyOption = option;
    await this.setAccounts();
    this.selectedAccounts = [];
    this.selectAllAccountsChecked = false;
  }

  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const params: ReportBuildFormValue = {
      accountIds: this.selectedAccounts.map((a) => a.id),
      startDate: this.startDate.toISOString(),
      endDate: this.endDate.toISOString(),
      dateRange: this.selectedDateRange && this.selectedDateRange.id,
      previousStartDate:
        this.previousStartDate && this.previousStartDate.toISOString(),
      previousEndDate:
        this.previousEndDate && this.previousEndDate.toISOString(),
      compareTo: this.selectedCompareToOption.id,
      currency: this.selectedCurrencyOption.key
    };

    this.state.go('auth.analytics.adStats.report', params);
  }

  selectPresetDateRange(range: DateRange): void {
    this.selectedDateRange = this.dateRanges[range.id];

    this.startDate = this.selectedDateRange.start;
    this.endDate = this.selectedDateRange.end;
    this.updateCompareToDates();
  }

  onStartDatePicked(startDate: Date): void {
    if (!startDate) {
      return;
    }
    this.startDate = startDate;
    this.selectedDateRange = undefined;
    this.updateCompareToDates();
  }

  onEndDatePicked(endDate: Date): void {
    if (!endDate) {
      return;
    }
    this.endDate = endDate;
    this.selectedDateRange = undefined;
    this.updateCompareToDates();
  }

  onPreviousStartDatePicked(previousStartDate: Date): void {
    if (!previousStartDate) {
      return;
    }
    this.previousStartDate = previousStartDate;
  }

  onPreviousEndDatePicked(previousEndDate: Date): void {
    if (!previousEndDate) {
      return;
    }
    this.previousEndDate = previousEndDate;
  }

  updateCompareToDates(): void {
    if (!this.selectedCompareToOption.getCompareToDates) {
      return;
    }

    const { start, end } = this.selectedCompareToOption.getCompareToDates(
      this.startDate,
      this.endDate
    );
    this.previousStartDate = start;
    this.previousEndDate = end;
  }

  selectCompareToOption(option: CompareToOption): void {
    this.selectedCompareToOption = option;
    this.updateCompareToDates();
  }

  onSelectedAccountsChange(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
    this.selectAllAccountsChecked =
      this.selectedAccounts.length &&
      this.selectedAccounts.length === this.accounts.length;

    this.setSelectedAccountTypeIds();
  }

  setSelectedAccountTypeIds(): void {
    this.selectedAccountTypeIds = [];

    const accountGroups = groupBy(
      this.selectedAccounts,
      (account) => account.account_type_id
    );

    accountGroups.forEach((accountGroup) => {
      this.selectedAccountTypeIds.push(accountGroup[0].account_type_id);
    });
  }

  onSelectAllAccountsChange(checked: boolean): void {
    this.selectedAccounts = checked ? [...this.accounts] : [];

    this.setSelectedAccountTypeIds();
  }

  onFlatpickrOpen() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 200);
  }
}
