<div class="cl-dropdown-select-h-container">
  <h4>H</h4>
  <a target="_blank"
     href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-dropdown-select/cl-dropdown-select-h/cl-dropdown-select-h.component.html">
    View code on GitHub
  </a>
  <p>headButtonStyleVariant="default"; headButtonLabel template; headButton height override</p>
  <p>MD size; optionIcon template; bodyHeader template</p>

  <ssi-dropdown-select-2 size="md"
                         name="options"
                         [required]="true"
                         [multiple]="false"
                         [filterable]="false"
                         [closeOnSelect]="false"
                         [options]="optionsIterable"
                         [(ngModel)]="selectedOption"
                         (ngModelChange)="selectedOptionChanged($event)">

    <ng-template ssiTemplateSelector="headButtonLabel"
                 let-toggle="toggle"
                 let-expanded="expanded">
      <div class="custom-head-button-label">
        <i class="ssi {{selectedOption.icon}}"></i>
        <span>{{selectedOption.label}}</span>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="bodyHeader"
                 let-toggle="toggle">
      <!-- <div class="menu-header">
        <strong><i class="ssi ssi-information-badge"></i><span>Who can reply?</span></strong>
        <div><span>Choose who reply to this post.</span></div>
        <div><span>Anyone mentioned can always reply.</span></div>
      </div> -->
      <div class="menu-header">
        <strong><i class="ssi ssi-information-badge"></i><span>Youtube Privacy Settings</span></strong>
        <div><span>{{selectedOption.description}}</span></div>
      </div>
    </ng-template>
  </ssi-dropdown-select-2>
</div>