import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FocusOnDirective } from './focus-on.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FocusOnDirective],
  exports: [FocusOnDirective]
})
export class FocusOnModule {}
