import './inline-note.component.scss';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Note } from '@ui-resources-angular';

@Component({
  selector: 'ssi-inline-note',
  templateUrl: './inline-note.component.html',
  styles: []
})
export class InlineNoteComponent {
  @Input() isPublic: boolean;
  @Input() note: Note;
  @Input() inverse = false;
  @Input() notesAmount: Note[];
  @Input() showGuide = false;
  @Input() showAddNote = false;
  @Input() showDeleteNote = false;
  @Input() canCloseNote = false;
  @Output() addNote = new EventEmitter();
  @Output() deleteNote = new EventEmitter();
  @Output() closeNotes = new EventEmitter();

  constructor() {}
}
