export interface KeyValueObject<T = any> {
  [key: string]: T;
}

export function objKeysMatch(
  obj: KeyValueObject,
  compareToObj: KeyValueObject
): boolean {
  if (!obj || !compareToObj) {
    return false;
  }

  return !Object.keys(compareToObj).some((key) => !obj.hasOwnProperty(key));
}
