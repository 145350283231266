<div class="modal-header">
  <h3 class="modal-title no-wrap">
    <span *ngIf="provider">Link {{provider.label}} with Orlo</span>
  </h3>
</div>
<div class="modal-body">
  <div
    class="loading"
    *ngIf="loadingTracker.active"
  >
  </div>
  <p>
    In order for you to use {{provider.label}} within Orlo, you must first
    <a
      href="https://www.twilio.com/signup"
      target="_blank">sign up
    </a>
    to Twilio and create a messaging service. For us to be able to link this account, we will need you to provide us with your Standard Api Key & an API Secret (you can find this 
    <a
      href="https://www.twilio.com/console/runtime/api-keys"
      target="_blank">here
    </a>
    ) and your Account Identifier/SID (you can find this 
    <a 
      href="https://www.twilio.com/docs/glossary/what-is-a-sid" 
      target="_blank">here
    </a>):
  </p>
  <form
    novalidate
    #submitForm="ngForm"
    [hidden]="loadingTracker.active"
  >

    <div *ngIf="provider">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>{{provider.label}} ACCOUNT NAME</label>
          <input
            class="form-control input-lg"
            type="text"
            name="name"
            placeholder="Name within Orlo"
            [(ngModel)]="name"
            required
          >
        </div>
        <div class="form-group col-md-6">
          <label>API KEY</label>
          <input
            class="form-control input-lg"
            type="text"
            name="apiKey"
            placeholder="This starts with SK..."
            [(ngModel)]="authCredentials.apiKey"
            required
          >
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>ACCOUNT IDENTIFIER / SID
          </label>
          <input
            class="form-control input-lg"
            type="text"
            name="sid"
            placeholder="This starts with AC..."
            [(ngModel)]="authCredentials.accountSid"
            required
          >
        </div>
        <div class="form-group col-md-6">
          <label>API SECRET</label>
          <input
            class="form-control input-lg"
            type="text"
            name="secret"
            placeholder="This is 32 characters long API key pair"
            [(ngModel)]="authCredentials.secret"
            required
          >
        </div>
      </div>
      <hr>

      <div class="text-center">
        <button
          class="btn btn-primary btn-lg text-uppercase"
          [disabled]="!submitForm.valid"
          (click)="find()"
          translate="ADD"
        >
        </button>
      </div>
    </div>
  </form>

  <ul *ngIf="linkedNumbers && !chosenNumber">
    By clicking on a number below, you're choosing it to communicate with your clients through Orlo:
    <li *ngFor="let number of linkedNumbers">
      <span (click)="chooseNumber(number)">{{number.number}}</span>
    </li>
  </ul>

  <div
    class="info-message"
    *ngIf="webhookConfig"
  >
    Webhook URL to be set in Twilio - WhatsApp Enabled Senders:<br>
    <pre>
      <code>
        {{webhookConfig | json}}
      </code>
    </pre>
  </div>
  <p
    class="success-message"
    *ngIf="accountCreated"
  >Congratulations! Your {{provider.name}} account is now linked with Orlo</p>
  <p
    *ngIf="needsAccountRegistration"
    class="error-message"
  >
    Your Twilio account doesn't have linked phone numbers or credentials are incorrect. <br>
    Please
    <a
      href="https://www.twilio.com/console"
      target="_blank"
    >go to Twilio</a> and add at least one phone number.
  </p>
</div>
<div class="modal-footer text-right">
  <button
    class="btn btn-default"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-times"></i> <span translate="{{ accountCreated ? 'SAVE_CHANGES' : 'CANCEL' }}"></span>
  </button>
</div>