import {
  TriggerConfig,
  ActionConfig,
  UIParam,
  ParamKeyValueMap,
  UIParamFormType
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import { AppletTriggerParam, AppletActionParam } from '../applets/applet';
import {
  IftttService,
  Ingredient
} from '../ifttt-service-model/ifttt-service-model';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const overlayDimensions = {
  select: {
    width: 400,
    height: 390
  },
  textInput: {
    width: 400,
    height: 190
  },
  textarea: {
    width: 400,
    height: 290
  },
  tagsInput: {
    width: 400,
    height: 350
  },
  notification: {
    width: 800,
    height: 500
  }
};

export function mapSelectOptions(
  param: AppletTriggerParam,
  options: any[]
): string[] {
  try {
    if (!(!!options && Array.isArray(options))) {
      throw new Error(`Value for 'applet params' not in expected format.`);
    }

    return param.value.map((key) => {
      return options.find((option) => option.value === key).label;
    });
  } catch (error) {
    console.error(error);

    return null;
  }
}

export enum IngredientType {
  Template = 'Template',
  UserId = 'UserId'
}

export interface IngredientConfig {
  type: IngredientType;
  api: {
    name: string;
    ingredient?: Ingredient;
  };
  translationIds: {
    label: string;
  };
}

export interface ServiceMapping {
  id: string;
  brand?: {
    img?: string;
    iconClass: string;
  };
  triggers: TriggerConfig[];
  actions: ActionConfig[];
  ingredients: IngredientConfig[];
  mutually_exclusive_triggers?: any;
  translationIds?: {
    title: string;
    triggers: string;
    actions: string;
  };
}

export function getParamByName(
  params: Array<AppletTriggerParam | AppletActionParam>,
  name: string
) {
  try {
    if (!Array.isArray(params)) {
      throw new Error(`Value for 'applet params' not in expected format.`);
    }

    return params.find((param) => param.name === name);
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function minShouldMatchParamFactory({
  orLabel = 'Match any of these tags',
  andLabel = 'Match all of these tags'
} = {}): UIParam {
  return {
    form: {
      type: UIParamFormType.SelectSingle,
      select: {
        options: [
          {
            value: 'or',
            label: orLabel,
            trackBy: 'or'
          },
          {
            value: 'and',
            label: andLabel,
            trackBy: 'and'
          }
        ]
      },
      defaultValue: 'or'
    },
    deserialise(count: number): 'or' | 'and' {
      return count === 1 ? 'or' : 'and';
    },
    serialise(operand: 'or' | 'and', otherParams: ParamKeyValueMap): number {
      return operand === 'or' ? 1 : otherParams.queries.length;
    }
  };
}

export function replaceTemplateIngredients(
  xmlTemplate: string,
  ingredients: IngredientConfig[],
  stringifyIngredient: (ingredients: IngredientConfig) => string
): string {
  const regexEscape = new RegExp('&', 'g');
  const escaped = xmlTemplate.replace(regexEscape, '&amp;');
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(
    `<template>${escaped}</template>`,
    'text/xml'
  );

  Array.from(xmlDoc.getElementsByTagName('ingredient')).forEach(
    (ingredientElement) => {
      const name = ingredientElement.getAttribute('name');
      const ingredient = ingredients.find(
        (iIngredient) => iIngredient.api.name === name
      );
      ingredientElement.parentNode.replaceChild(
        xmlDoc.createTextNode(stringifyIngredient(ingredient)),
        ingredientElement
      );
    }
  );

  return xmlDoc.getElementsByTagName('template').item(0).textContent;
}

export function replaceTemplateIngredientsWithHtml(
  text: string,
  injector: Injector,
  service: IftttService
): string {
  return replaceTemplateIngredients(
    text,
    service.getIngredients(IngredientType.Template),
    (ingredient) =>
      `<span class="ingredient-label">${injector
        .get(TranslateService)
        .instant(ingredient.translationIds.label)}</span>`
  );
}
