<div class="cc-nav-container">
  <div class="left">
    <div class="buttons">
      <button ssiCalendarPreviousView
              class="cc-nav-button"
              [view]="view"
              [viewDate]="viewDate"
              (viewDateChange)="viewDateChange.emit($event)">
        <i class="ssi ssi-arrow-down-new"></i>
      </button>
      <button ssiCalendarToday
              class="cc-nav-button"
              [viewDate]="viewDate"
              (viewDateChange)="viewDateChange.emit($event)">
        <span>Jump to Today</span>
      </button>
      <button ssiCalendarNextView
              class="cc-nav-button"
              [view]="view"
              [viewDate]="viewDate"
              (viewDateChange)="viewDateChange.emit($event)">
        <i class="ssi ssi-arrow-down-new"></i>
      </button>
    </div>

    <div class="date-info">
      <span *ngIf="view === CalendarView.Day">{{viewDate | date: 'EEE d, MMMM y'}}</span>
      <span *ngIf="view === CalendarView.Week">{{weekPeriod}}</span>
      <span *ngIf="view === CalendarView.Month">{{viewDate | date: 'MMMM y'}}</span>
    </div>
  </div>

  <div class="right">
    <div class="text"><span>{{totalPostsCountLabel}}</span></div>
    <div class="tabs">
      <button class="tab-link cc-nav-button"
              (click)="viewChange.emit(CalendarView.Day)"
              [class.active]="view === CalendarView.Day">Day</button>
      <button class="tab-link cc-nav-button"
              (click)="viewChange.emit(CalendarView.Week)"
              [class.active]="view === CalendarView.Week">Week</button>
      <button class="tab-link cc-nav-button"
              (click)="viewChange.emit(CalendarView.Month)"
              [class.active]="view === CalendarView.Month">Month</button>
    </div>

    <button
      class="campaign cc-nav-button"
      (click)="toggleCampaigns()"
      [class.active]="campaignsToggled"
      ssiTooltip="Toggle campaigns"
      tooltipWindowClass="tooltip-chunky"
      tooltipPlacement="bottom"
    >
      <i class="ssi ssi-starred"></i>
    </button>
    <div ngbDropdown
         #pickerDropdown="ngbDropdown"
         autoClose="outside">
      <button ngbDropdownToggle
              class="calendar-event cc-nav-button">
        <i class="ssi ssi-calendar-event"></i>
      </button>
      <div class="dropdown-menu-wrap dropdown-menu-right"
           ngbDropdownMenu>
        <ssi-dropdown-menu [menuList]="timeslotMenuItems"></ssi-dropdown-menu>
      </div>
    </div>
    <div class="g-count-badge-container filters-button-container">
      <div class="g-count-badge active-filters-count-badge"
           *ngIf="activeFilters.length">
        <span>{{activeFilters.length}}</span>
      </div>
      <button type="button"
              class="filters-button cc-nav-button"
              [class.active]="!!activeFilters.length"
              (click)="filtersShow.emit()"><i class="ssi ssi-filter-trimmed"></i><span>Filter</span></button>
    </div>
  </div>
</div>

<div class="cc-nav-container-mobile">
  <div
    class="input-wrapper date-info-wrapper"
    #viewDateContainer
  >
    <input
      mwlFlatpickr
      class="date-picker-input date-info"
      type="text"
      name="viewDate"
      [(ngModel)]="viewDate"
      (ngModelChange)="viewDateChange.emit($event)"
      [convertModelValue]="true"
      [enableTime]="false"
      [altInput]="true"
      dateFormat="Y-m-d"
      altFormat="D d, M Y"
      [time24hr]="true"
      [appendTo]="viewDateContainer.nativeElement"
      [static]="true"
    >
    <i class="ssi ssi-arrow-down-new"></i>
  </div>
  <div class="g-count-badge-container filters-button-container">
    <div class="g-count-badge active-filters-count-badge"
         *ngIf="activeFilters.length">
      <span>{{activeFilters.length}}</span>
    </div>
    <button
      class="filters-button cc-nav-button"
      [class.active]="!!activeFilters.length"
      (click)="filtersShow.emit()"
    >
      <i class="ssi ssi-filter-trimmed"></i>
    </button>
  </div>
</div>