<div class="social-push-mode-modal-body">
  <i class="ssi ssi-push-mode social-push-mode-modal-icon"></i>
  <h1
    class="social-push-mode-modal-heading"
    translate="ARE_YOU_SURE_YOU_WANT_TO_LEAVE"
  ></h1>
  <p class="social-push-mode-modal-meta">
    You are currently online in Social Push Mode. If you leave, we'll set you to Busy so you aren't assigned any new conversations. You'll still keep hold of your current assigned conversations
  </p>
  <div class="social-push-mode-modal-actions">
    <button
      class="social-push-mode-action social-push-mode-action-secondary"
      (click)="close(false)"
      translate="STAY"
    ></button>
    <button
      class="social-push-mode-action social-push-mode-action-primary"
      (click)="close(true)"
      translate="LEAVE"
    ></button>
  </div>
</div>