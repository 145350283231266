import { Component, OnInit, Input } from '@angular/core';
import { WorkflowModel, AccountModel, Account } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../common/services/workflow-manager/workflow-manager.service';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { MAXIMUM_WORKFLOW_ACCOUNTS } from '../../../../common/constants';
import { ConfirmationModalComponent } from '../../../../common/components/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export function allAccountsResolveFn(accountModel: AccountModel) {
  return accountModel.findAllAccounts();
}

@Component({
  selector: 'ssi-edit-workflows',
  templateUrl: './edit-workflows.component.html',
  styles: []
})
export class EditWorkflowsComponent implements OnInit {
  static resolve = [
    {
      token: 'allAccounts',
      resolveFn: allAccountsResolveFn,
      deps: [AccountModel]
    }
  ];

  @Input() allAccounts: Account[];
  workflow;
  accounts: {
    linked: Account[];
    unlinked: Account[];
  };
  maximumWorkflowAccounts = MAXIMUM_WORKFLOW_ACCOUNTS;
  trackById = (index, account: Account) => account.id;

  constructor(
    private state: StateService,
    private translate: TranslateService,
    private workflowModel: WorkflowModel,
    private popup: PopupService,
    private workflowManager: WorkflowManagerService,
    protected modal: NgbModal
  ) {}

  ngOnInit() {
    this.workflow = this.workflowModel.get(this.state.params.workflowId);
    this._updateWorkflowAccounts();
  }

  public deleteWorkflowConfirmationModal(): void {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    confirmationModal.componentInstance.title = this.translate.instant(
      'DELETE_WORKFLOW'
    );
    confirmationModal.componentInstance.info = this.translate.instant(
      'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GROUP'
    );
    confirmationModal.componentInstance.cancelButton = this.translate.instant(
      'CANCEL'
    );
    confirmationModal.componentInstance.confirmButton = this.translate.instant(
      'DELETE'
    );
    confirmationModal.result.then((shouldDelete) => {
      if (shouldDelete) {
        this._deleteWorkflow();
      }
    });
  }

  private _deleteWorkflow(): void {
    const activeWorkflow = this.workflowManager.getCurrentId();

    this.workflowModel.destroy(this.workflow).then(() => {
      if (activeWorkflow === this.workflow.id) {
        this.workflowManager.loadWorkflow();
      }
      this.state.go('auth.workflows', {}, { reload: true });
    });
  }

  public addAccount(account): void {
    if (this.workflow.accounts.length >= this.maximumWorkflowAccounts) {
      this.popup.alert({
        isError: true,
        message: this.translate.instant(
          'YOU_CAN_ONLY_ADD_A_MAXIMUM_OF__MAXIMUMACCOUNTS__PER_WORKFLOW',
          {
            maximumAccounts: this.maximumWorkflowAccounts
          }
        )
      });
    } else {
      this.workflow.addAccount(account).then(() => {
        this._updateWorkflowAccounts();
      });
    }
  }

  public removeAccount(account): void {
    this.workflow.removeAccount(account).then(() => {
      this._updateWorkflowAccounts();
    });
  }

  private _updateWorkflowAccounts() {
    this.accounts = {
      unlinked: this.allAccounts.filter(
        (account) => !this.workflow.accounts.includes(account)
      ),
      linked: [...this.workflow.accounts]
    };
  }
}
