import './network-inbound-comments.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import {
  NetworkConst,
  NetworksConst,
  networks,
  networksIterable
} from '../../../../../../../common/constants';
import { mapToIterable } from '../../../../../../../common/utils';
import { getHighestValue } from '../../../../../../../common/util';

import {
  DateRanges,
  TimeSeriesStats,
  TotalsStats
} from '../../../marketing.service';

@Component({
  selector: 'ssi-network-inbound-comments',
  templateUrl: './network-inbound-comments.component.html',
  styles: []
})
export class NetworkInboundCommentsComponent implements OnInit {
  @Input() dateRanges: DateRanges;
  @Input() stats: TotalsStats;

  networksConst: NetworksConst = networks;
  networksConstIterable: NetworkConst[] = networksIterable;

  totalsByAccountTypeIterable: any[] = [];
  highestMessagesInCount = 0;

  constructor() {}

  ngOnInit() {
    this.totalsByAccountTypeIterable = mapToIterable(
      this.stats.totalsByAccountType
    );

    this.totalsByAccountTypeIterable.sort(
      (a, b) =>
        this.networksConst[a.account.account_type_name].order -
        this.networksConst[b.account.account_type_name].order
    );

    this.highestMessagesInCount = getHighestValue(
      this.totalsByAccountTypeIterable,
      'current.messages_in'
    );
  }
}
