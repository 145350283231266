import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { API } from '@ui-resources-angular';

@Injectable()
export class PluginService {
  private $pluginWindow = new Subject<any>();
  private $plugins = new Subject<any>();
  private $pluginConnection = new Subject<any>();
  private $pluginPinned = new Subject<any>();
  public initialisedPlugins = {};

  constructor(private api: API) {}

  trigger(data, context) {
    context.postMessage(data, '*');
  }

  action(type, params) {
    this.$pluginWindow.next({ type, params });
  }

  listen() {
    return this.$pluginWindow.asObservable();
  }

  addInitialisedPlugin(pluginId, context, actions) {
    this.initialisedPlugins[pluginId] = {
      context,
      actions
    };
    this.$plugins.next(this.initialisedPlugins);
  }

  $initialisedPlugins() {
    return this.$plugins.asObservable();
  }

  getInitialisedPlugins() {
    return this.initialisedPlugins;
  }

  pluginConnection(type, params) {
    this.$pluginConnection.next({ type, params });
  }

  $pluginConnectionObservable() {
    return this.$pluginConnection.asObservable();
  }

  pluginPinned(bool) {
    this.$pluginPinned.next(bool);
  }

  $pluginPinnedObservable() {
    return this.$pluginPinned.asObservable();
  }

  async fetchInstalledPlugins() {
    return await this.api.get('plugin/plugin');
  }

  async setAppPluginData(pluginId, data) {
    return await this.api.put(`plugin/pluginAppData?id=${pluginId}`, { data });
  }

  async getAppPluginData(pluginId) {
    return await this.api.get(`plugin/pluginAppData?id=${pluginId}`);
  }

  async setUserPluginData(pluginId, data) {
    return await this.api.put(`plugin/pluginUserData?id=${pluginId}`, { data });
  }

  async getUserPluginData(pluginId) {
    return await this.api.get(`plugin/pluginUserData?id=${pluginId}`);
  }

  async linkOrloPersonToPlugin(pluginId, orloId, pluginPersonId) {
    return await this.api.post(`crm/personPlugin_v2?id=${orloId}`, {
      plugin_id: pluginId,
      plugin_person_id: pluginPersonId
    });
  }

  async unlinkOrloPersonFromPlugin(pluginId, orloId, pluginPersonId) {
    return await this.api.del(
      `crm/personPlugin?id=${orloId}&plugin_id=${pluginId}&plugin_person_id=${pluginPersonId}`
    );
  }

  async getCrmPerson(id) {
    return await this.api.get(`crm/person?id=${id}`);
  }
}
