import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AddWidgetModule } from './components/add-widget/add-widget.module';
import { WidgetsGridModule } from './components/widgets-grid/widgets-grid.module';
import { DashboardNavbarModule } from './components/dashboard-navbar/dashboard-navbar.module';
import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';

@NgModule({
  imports: [
    AngularCommonModule,
    FormsModule,
    AddWidgetModule,
    WidgetsGridModule,
    DashboardNavbarModule,
    DropdownSelect2Module,
    TranslateModule
  ],
  exports: [
    FormsModule,
    AddWidgetModule,
    WidgetsGridModule,
    DashboardNavbarModule,
    DropdownSelect2Module,
    TranslateModule
  ],
  declarations: [],
  entryComponents: [],
  providers: []
})
export class CommonModule {}
