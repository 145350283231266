import { utils } from 'js-data';
import { Account } from '../../account/services/accountModel';
import { api } from '../../core/services/api';
import secondsToHumanTime from '../../core/filters/secondsToHumanTime';

export interface Method {
  id: string;
  name: string;
  translationKey: string;
}

export interface Type {
  id: string;
  name: string;
  translationKey: string;
}

export type Period = string | null;

export interface Subscription {
  account_id: string;
  id: string;
  notification_method: string;
  notification_method_name: string;
  notification_period: string;
  notification_type_id: string;
  notification_type_name: string;
  user_id: string;
}

export interface PeriodOption {
  label: string;
  period: Period;
}

export interface NotificationsApiResult {
  methods: Method[];
  periods: Array<{ period: Period }>;
  subscriptions: Subscription[];
  types: Type[];
}

function getTranslationKey(apiLabel: string, suffix: string) {
  return `${apiLabel.replace(/ /g, '_')}_${suffix}`.toUpperCase();
}

function returnHttpData({ data }) {
  return data;
}

function subscribe(
  account: Account,
  type: Type,
  method: Method,
  periodInSeconds: Period
) {
  return api
    .post('notification/preferences', {
      account_id: account.id,
      notification_type_id: type.id,
      notification_period: periodInSeconds,
      notification_method: method.id
    })
    .then(returnHttpData);
}

function unsubscribe(subscription: Subscription) {
  return api
    .del('notification/preferences', {
      params: {
        id: subscription.id
      }
    })
    .then(returnHttpData);
}

export class NotificationPreferences {
  fetch(accounts: Account[]) {
    if (!(!!accounts && Array.isArray(accounts))) {
      throw new Error(
        `value for 'notification preferences fetch accounts' is in expected format.`
      );
    }

    return api
      .get('notification/preferences')
      .then(returnHttpData)
      .then((notifications: NotificationsApiResult) => {
        const types: Type[] = notifications.types.map((type) => {
          type.translationKey = getTranslationKey(
            type.name,
            'notification_description'
          );
          return type;
        });

        const periods: PeriodOption[] = notifications.periods.map(
          ({ period }) => {
            return {
              label: secondsToHumanTime(+period),
              period
            };
          }
        );

        const methods: Method[] = notifications.methods.map((method) => {
          method.translationKey = getTranslationKey(
            method.name,
            'notification_method'
          );
          return method;
        });

        const subscriptions: any = {};
        accounts.forEach((account) => {
          subscriptions[account.id] = {};
          notifications.types.forEach((type) => {
            subscriptions[account.id][type.id] = {};
            notifications.methods.forEach((method) => {
              subscriptions[account.id][type.id][method.id] = {
                period: null,
                subscription: null
              };
            });
          });
        });

        const notificationAccounts = {
          configured: [],
          unconfigured: []
        };
        const activeAccountIds = accounts.map((a) => a.id);
        notifications.subscriptions
          .filter((a) => activeAccountIds.includes(a.account_id))
          .forEach((subscription) => {
            const accountId = subscription.account_id;
            const typeId = subscription.notification_type_id;
            const methodId = subscription.notification_method;

            subscriptions[accountId][typeId][methodId] = {
              period: subscription.notification_period,
              subscription
            };
            const account: Account = accounts.find(
              (iAccount) => +iAccount.id === +accountId
            );
            if (account && !notificationAccounts.configured.includes(account)) {
              notificationAccounts.configured.push(account);
            }
          });

        notificationAccounts.unconfigured = accounts.filter(
          (account) => !notificationAccounts.configured.includes(account)
        );

        return {
          types,
          periods,
          methods,
          subscriptions,
          accounts: notificationAccounts,
          updateSubscription(
            account: Account,
            type: Type,
            method: Method,
            periodInSeconds: Period
          ) {
            const subscription: Subscription =
              subscriptions[account.id][type.id][method.id].subscription;
            let promise = utils.Promise.resolve();
            if (subscription) {
              promise = unsubscribe(subscription);
            }

            return promise
              .then(() => {
                if (periodInSeconds) {
                  return subscribe(account, type, method, periodInSeconds).then(
                    (newSubscription: Subscription) => {
                      subscriptions[account.id][type.id][method.id] = {
                        period: periodInSeconds,
                        subscription: newSubscription
                      };
                      return subscriptions;
                    }
                  );
                } else {
                  subscriptions[account.id][type.id][method.id] = {
                    period: null,
                    subscription: null
                  };
                  return subscriptions;
                }
              })
              .then((result) => {
                let isConfigured = false;
                Object.values(subscriptions[account.id]).forEach((iTypes) => {
                  Object.values(iTypes).forEach((iMethod) => {
                    if (iMethod.subscription) {
                      isConfigured = true;
                    }
                  });
                });

                if (
                  isConfigured &&
                  !notificationAccounts.configured.includes(account)
                ) {
                  notificationAccounts.configured.push(account);
                  notificationAccounts.unconfigured = notificationAccounts.unconfigured.filter(
                    (iAccount) => iAccount !== account
                  );
                } else if (
                  !isConfigured &&
                  !notificationAccounts.unconfigured.includes(account)
                ) {
                  notificationAccounts.unconfigured.push(account);
                  notificationAccounts.configured = notificationAccounts.configured.filter(
                    (iAccount) => iAccount !== account
                  );
                }

                return result;
              })
              .catch((e) => console.error('Error updating subscription:', e));
          }
        };
      });
  }
}
