import { Injectable, Inject, InjectionToken } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, from as fromPromise } from 'rxjs';
import axios from 'axios';
import {
  STATIC_FILES_ENDPOINT,
  DEFAULT_TRANSLATE_LANGUAGE
} from '../../constants';

export const DEFAULT_TRANSLATIONS = new InjectionToken('default translations');

@Injectable()
export class TranslateHttpLoaderService implements TranslateLoader {
  constructor(@Inject(DEFAULT_TRANSLATIONS) private defaultTranslations) {}

  getTranslation(lang: string): Observable<any> {
    if (
      typeof this.defaultTranslations !== 'undefined' &&
      lang === DEFAULT_TRANSLATE_LANGUAGE
    ) {
      return of(this.defaultTranslations);
    }

    const endpoint = `${STATIC_FILES_ENDPOINT}/translations/${lang}.json`;

    return fromPromise(axios.get(endpoint).then(({ data }) => data));
  }
}
