<div class="row outbox-wrap">
  <div class="col-sm-8 col-xs-12">
    <div class="form-check"
         *ngIf="selectedPosts.length">
      <label class="form-check-label mouse-pointer align-top"
             for="quickSelectCheckbox">
        <small>Quick select the first {{initialPostsLoadCount}} posts</small>
      </label>
      <input type="checkbox"
             class="form-check-input mouse-pointer"
             id="quickSelectCheckbox"
             [(ngModel)]="quickSelectCheckboxChecked"
             (ngModelChange)="quickSelectCheckboxToggle(quickSelectCheckboxChecked)">
    </div>

    <ssi-outbox-post-list *ngIf="outboxQuery"
                          [campaigns]="campaigns"
                          [outboxQuery]="outboxQuery"
                          [isSelectable]="true"
                          [selectedPosts]="selectedPosts"
                          [queryOverrides]="queryOverrides"
                          (onSelectToggle)="togglePostSelected($event)">
      <ssi-outbox-post-list-no-results no-results></ssi-outbox-post-list-no-results>
    </ssi-outbox-post-list>
  </div>

  <div class="col-sm-4 col-xs-12">
    <div class="panel panel-default actions-panel">
      <div>
        <button *ngIf="!outboxQuery?.loading.initial.active"
                type="button"
                class="g-btn g-btn-secondary bg-white"
                (click)="createQueryAndSearch(filters)"><i class="fa fa-refresh"></i><span>Reload</span></button>
      </div>
      <div>
        <button *ngIf="outboxQuery?.outboxPosts.length"
                type="button"
                class="g-btn g-btn-secondary bg-white"
                (click)="exportToFile()"><i class="ssi ssi-print"></i><span>Export results</span></button>
      </div>
      <div class="g-count-badge-container
                filters-button-container">
        <div class="g-count-badge active-filters-count-badge"
             *ngIf="activeFilters.length">
          <span>{{activeFilters.length}}</span>
        </div>
        <button type="button"
                class="g-btn g-btn-secondary bg-white filters-button"
                [class.active]="!!activeFilters.length"
                (click)="filtersVisible = true"><i class="ssi ssi-filter-trimmed"></i><span>Filter</span></button>

        <ssi-filters #filtersRef
                     [config]="'outbox'"
                     [(visible)]="filtersVisible"
                     [savedApiParams]="savedQuery"
                     (onFiltersChange)="activeFilters = $event;"
                     (onApply)="createQueryAndSearch($event)"></ssi-filters>
      </div>
    </div>

    <div class="panel panel-default"
         *ngIf="selectedPosts.length">
      <div class="panel-heading">
        <strong>Bulk Actions</strong>
        <span class="pull-right">
          <small>{{selectedPosts.length}}/{{outboxQuery.interactionTotals.total}} posts selected</small>
        </span>
      </div>
      <div class="panel-body">
        <button class="btn btn-default btn-xs"
                (click)="deselectAllPosts()">
          <i class="ssi ssi-times"></i>
          <span>Deselect Posts</span>
        </button>
        <button
          *ngIf="canBulkEditSelected()"
          class="btn btn-primary btn-xs"
          uiSref="auth.bulkEdit"
          [uiParams]="{posts: getSelectedPostIds()}"
        >
          <i class="ssi ssi-edit"></i>
          <span>Bulk Edit Posts</span>
        </button>
        <button
          *ngIf="onlyScheduledPostsSelected()"
          class="btn btn-primary btn-xs"
          uiSref="auth.bulkEdit"
          [uiParams]="{posts: getSelectedPostIds()}"
        >
          <i class="ssi ssi-edit"></i>
          <span>Bulk Edit Campaigns</span>
        </button>
        <button
          *ngIf="onlyScheduledPostsSelected()"
          class="btn btn-primary btn-xs"
          uiSref="auth.bulkEdit"
          [uiParams]="{posts: getSelectedPostIds()}"
        >
          <i class="ssi ssi-edit"></i>
          <span>Bulk Delete Posts</span>
        </button>
        <!-- <button class="btn btn-danger btn-xs" (click)="deleteSelectedPosts()">
          <i class="ssi ssi-delete"></i>
          <span>Bulk Delete Posts</span>
        </button> -->
      </div>
    </div>

    <div *ngIf="outboxQuery?.outboxPosts.length && !outboxQuery.loading.initial.active && !hideTotals">
      <div class="panel panel-default">
        <div class="panel-body outbox-analytics-section text-center">
          <div class="row border-separated">
            <div class="col-xs-6">
              <div class="statcard padding-none">
                <h2 class="statcard-number">
                  <i class="fa fa-search"></i>
                  <!-- <span ssi-big-number="outboxQuery.interactionTotals.total"></span> -->
                  <ssi-big-number [number]="outboxQuery.interactionTotals.total"></ssi-big-number>
                </h2>
                <small class="statcard-desc"
                       translate="TOTAL_RESULTS"></small>
              </div>
            </div>
            <div class="col-xs-6">
              <ssi-tooltip-wrap ssiTooltip="Please note, X doesn't provide this figure on the native platform and therefore we're unable to provide this insight here.">
                <div class="statcard padding-none">
                  <h2 class="statcard-number">
                    <i class="fa fa-bullhorn"></i>
                    <!-- <span ssi-big-number="outboxQuery.interactionTotals.reach"></span> -->
                    <ssi-big-number [number]="outboxQuery.interactionTotals.reach"></ssi-big-number>
                  </h2>
                  <small class="statcard-desc"
                         translate="TOTAL_REACH"></small>
                </div>
              </ssi-tooltip-wrap>
            </div>
          </div>
          <hr>
          <div class="row border-separated">
            <div class="col-xs-6">
              <div class="statcard padding-none">
                <h2 class="statcard-number">
                  <i class="ssi ssi-impressions"></i>
                  <!-- <span ssi-big-number="outboxQuery.interactionTotals.impressions"></span> -->
                  <ssi-big-number [number]="outboxQuery.interactionTotals.impressions"></ssi-big-number>
                </h2>
                <small class="statcard-desc">Total Impressions</small>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="statcard padding-none">
                <h2 class="statcard-number">
                  <i class="fa fa-mouse-pointer"></i>
                  <!-- <span ssi-big-number="outboxQuery.interactionTotals.clicks"></span> -->
                  <ssi-big-number [number]="outboxQuery.interactionTotals.clicks"></ssi-big-number>
                </h2>
                <small class="statcard-desc"
                       translate="TOTAL_CLICKS"></small>
              </div>
            </div>
          </div>
          <hr>
          <div class="row border-separated">
            <div class="col-xs-6">
              <div class="statcard padding-none">
                <h2 class="statcard-number">
                  <i class="fa fa-comments"></i>
                  <!-- <span ssi-big-number="outboxQuery.interactionTotals.comment_count"></span> -->
                  <ssi-big-number [number]="outboxQuery.interactionTotals.comment_count"></ssi-big-number>
                </h2>
                <small class="statcard-desc"
                       translate="TOTAL_COMMENTS"></small>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="statcard padding-none">
                <h2 class="statcard-number">
                  <i class="fa fa-thumbs-up"></i>
                  <!-- <span ssi-big-number="outboxQuery.interactionTotals.like_count"></span> -->
                  <ssi-big-number [number]="outboxQuery.interactionTotals.like_count"></ssi-big-number>
                </h2>
                <small class="statcard-desc"
                       translate="TOTAL_LIKES"></small>
              </div>
            </div>
          </div>
          <hr>
          <div class="row border-separated">
            <div class="col-xs-6">
              <div class="statcard padding-none">
                <h2 class="statcard-number">
                  <i class="fa fa-share-square-o"></i>
                  <!-- <span ssi-big-number="outboxQuery.interactionTotals.share_count"></span> -->
                  <ssi-big-number [number]="outboxQuery.interactionTotals.share_count"></ssi-big-number>
                </h2>
                <small class="statcard-desc">Total shares</small>
              </div>
            </div>
            <div class="row border-separated">
              <div class="col-xs-6">
                <div class="statcard padding-none">
                  <h2 class="statcard-number">
                    <i class="ssi ssi-engagement-analytics"></i> <span>{{outboxQuery.interactionTotals.engagement_rate_reactions | truncate:12}}%</span>
                  </h2>
                  <small class="statcard-desc">Engagement Rate</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>