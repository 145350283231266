<div class="panel panel-default">
  <div class="panel-heading panel-heading-btn">
    <h4 class="panel-title">
      <i [class]="selectedAccount.socialNetwork?.icon.web"></i>
      {{ selectedAccount.username }}
    </h4>
    <button
      *ngIf="showActions"
      class="btn btn-default pull-right"
      (click)="resetSelectedAccount.emit()">
      <i class="fa fa-times"></i> <span translate="BACK"></span>
    </button>
    <div class="clearfix"></div>
  </div>
  <div class="panel-body">
    <table class="table table-striped">
      <tbody>
        <tr *ngFor="let type of notifications.types; trackBy: trackById">
          <td translate="{{ type.translationKey }}"></td>
          <td *ngFor="let method of notifications.methods; trackBy: trackById">
            <label translate="{{ method.translationKey }}"></label>
            <select
              class="form-control"
              [(ngModel)]="notifications.subscriptions[selectedAccount.id][type.id][method.id].period"
              (ngModelChange)="updateSubscription(type, method)"
              >
              <option value="null" translate="DISABLED" disabled></option>
              <option value="" translate="DISABLED"></option>
              <option *ngFor="let period of notifications.periods" [value]="period.period">
                {{period.label}}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <footer *ngIf="showActions">
    <div class="panel-footer text-right">
      <button
        class="btn btn-success"
        (click)="resetSelectedAccount.emit()">
        <i class="fa fa-save"></i> <span translate="SAVE"></span>
      </button>
    </div>
  </footer>
</div>
