<div class="manage-ifttt-applet-streams manage-ifttt-applet-stage">

  <div>
    <div class="manage-ifttt-applet-stage-header">
      <h2 class="manage-ifttt-applet-stage-header-title">{{ service.translationIds.title | translate }}</h2>
      <p class="manage-ifttt-applet-stage-header-sub-title">Now select the stream(s) you would like to add triggers to</p>
    </div>

    <div class="manage-ifttt-applet-stage-body">
      <div class="manage-ifttt-applet-stage-body-inner">

        <ssi-list-select2 name="streams-select"
                          class="streams-select"
                          [required]="true"
                          [multiple]="true"
                          [filterable]="true"
                          [filterPlaceholder]="'Search for a stream...'"
                          [toggleAllLabel]="'Select all'"
                          [options]="streams"
                          [(ngModel)]="applet.streams"
                          (ngModelChange)="onSelectedStreamsChange($event)">

          <!-- <ng-template *ngIf="filter === filters.account"
                       ssiTemplateSelector="optionIcon"
                       let-account="option"
                       let-selected="selected">
            <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                     [color]="selected ? '#14bae3' : '#43537f'"
                                     variant="fa"></ssi-social-network-icon>
          </ng-template> -->
        </ssi-list-select2>

        <br>
        <div class="text-center">
          <button class="btn btn-primary btn-stage-next"
                  uiSref="^.name"
                  [disabled]="!applet.streams.length">
            Save
          </button>
        </div>
      </div>

    </div>
  </div>

</div>