import './instagram-story-stats.component.scss';
import { Account } from '@ui-resources-angular';
import { Component, Input, OnInit } from '@angular/core';
import {
  AnalyticsService,
  DateRanges,
  InstagramStoryStatsResponse
} from '../../analytics.service';
import { AccountTypeId } from '../../../../../common/enums';

export interface FactOverview {
  label: string;
  responseKey: string;
  current: number;
  previous: number;
  icon: string;
  iconColor: string;
  circleColor: string;
  tooltip?: string;
}

@Component({
  selector: 'ssi-instagram-story-stats',
  templateUrl: './instagram-story-stats.component.html',
  styles: []
})
export class InstagramStoryStatsComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() campaignIds: Array<string> = [];
  @Input() dateRanges: DateRanges;
  @Input() tagsToInclude: string[];
  @Input() tagsToExclude: string[];

  currentStats: InstagramStoryStatsResponse;
  previousStats: InstagramStoryStatsResponse;

  factList: Array<FactOverview> = [
    {
      label: 'Story Views',
      responseKey: 'sum_impressions',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-ig-stories',
      iconColor: '#F0B427',
      circleColor: 'rgba(240, 180, 39, 0.1)'
    },
    {
      label: 'Reach',
      responseKey: 'sum_reach',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-reach',
      iconColor: 'rgba(18, 172, 164, 1)',
      circleColor: 'rgba(18, 172, 164, 0.1)'
    },
    {
      label: 'Tap Back',
      responseKey: 'sum_taps_back',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-tap-back',
      iconColor: 'rgba(248, 140, 104, 1)',
      circleColor: 'rgba(248, 140, 104, 0.1)'
    },
    {
      label: 'Tap Forward',
      responseKey: 'sum_taps_forward',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-tap-forward',
      iconColor: 'rgba(255, 135, 184, 1)',
      circleColor: 'rgba(255, 135, 184, 0.1)'
    },
    {
      label: 'Exit Story',
      responseKey: 'sum_exits',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-exit',
      iconColor: 'rgba(178, 198, 20, 1)',
      circleColor: 'rgba(178, 198, 20, 0.1)'
    }
  ];

  constructor(private analyticsService: AnalyticsService) {}

  async ngOnInit() {
    const instagramAccountIds = this.accounts
      .filter((acc) => acc.account_type_id === String(AccountTypeId.Instagram))
      .map((a) => a.id);
    if (
      (!this.campaignIds || this.campaignIds.length === 0) &&
      instagramAccountIds &&
      instagramAccountIds.length === 0
    ) {
      return;
    }

    if (
      this.dateRanges.previous &&
      this.dateRanges.previous.start &&
      this.dateRanges.previous.end
    ) {
      this.previousStats = await this.analyticsService.getInstagramStoryMetrics(
        instagramAccountIds,
        this.campaignIds ? this.campaignIds : null,
        this.dateRanges.previous,
        this.tagsToInclude,
        this.tagsToExclude
      );
    }

    this.currentStats = await this.analyticsService.getInstagramStoryMetrics(
      instagramAccountIds,
      this.campaignIds ? this.campaignIds : null,
      this.dateRanges.current,
      this.tagsToInclude,
      this.tagsToExclude
    );

    this.factList.forEach((fact) => {
      fact.current = this.currentStats[fact.responseKey];
      fact.previous = this.previousStats
        ? this.previousStats[fact.responseKey]
        : 0;
    });
  }
}
