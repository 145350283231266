import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingCreatePresetComponent } from './advertising-create-preset.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AdvertisingTargetingBuilderModule } from '../advertising-targeting-builder/advertising-targeting-builder.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.createPreset',
          url: '/presets/new',
          component: AdvertisingCreatePresetComponent,
          resolve: AdvertisingCreatePresetComponent.resolve
        }
      ]
    }),
    AdvertisingTargetingBuilderModule
  ],
  declarations: [AdvertisingCreatePresetComponent],
  entryComponents: [AdvertisingCreatePresetComponent]
})
export class AdvertisingCreatePresetModule {}
