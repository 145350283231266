import { Injectable } from '@angular/core';

export enum LoaderVariant {
  Transparent = 'Transparent',
  White = 'white'
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor() {}

  appendLoader(
    hostElem: HTMLElement,
    variant = LoaderVariant.Transparent,
    text = ''
  ): HTMLElement {
    this.appendLoaderStyles(variant);

    const loaderElem = document.createElement('div');
    loaderElem.className = 'flx-loader';

    loaderElem.style['display'] = 'none';

    // TODO: if appending to button add min-height?

    const hostBorderRadius = getComputedStyle(hostElem)['border-radius'];
    if (parseInt(hostBorderRadius, 10)) {
      loaderElem.style['border-radius'] = hostBorderRadius;
    }

    loaderElem.innerHTML = `
      <span class="flx-spinner"></span>
      <span class="flx-spinner-text">${text}</span>
    `;

    hostElem.appendChild(loaderElem);

    return loaderElem;
  }

  appendLoaderStyles(variant: LoaderVariant): void {
    const styleElem = document.createElement('style');
    styleElem.innerHTML = this.getStylesAsString(variant);
    document.head.appendChild(styleElem);
  }

  getStylesAsString(variant: LoaderVariant): string {
    return `
      [ssiLoader] {
        position: relative;
      }

      .flx-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${
          variant === LoaderVariant.White ? '#fff' : 'rgba(244, 244, 250, 0.7)'
        };
        z-index: 999;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .flx-spinner {
        display: inline-block;
        width: 25px;
        height: 25px;
        border: 3px solid ${
          variant === LoaderVariant.White
            ? '#c7e8f5'
            : 'rgba(255, 255, 255, 0.3)'
        };
        border-radius: 50%;
        border-top-color: ${
          variant === LoaderVariant.White ? '#14bae3' : '#fff'
        };
        animation: flx-spin 1s ease-in-out infinite;
      }

      .flx-spinner-text {
        margin-top: 10px;
        color: #838eab;
        font-weight: 700;
        font-size: 14px;
      }

      @-webkit-keyframes flx-spin {
        to {
          -webkit-transform: rotate(360deg);
        }
      }
    `;
  }
}
