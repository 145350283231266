<div class="cl-context-menu-container">
  <div class="head">
    <h2>Context Menu</h2>
  </div>

  <div class="body"
       (click)="someContextMenu.show($event, {x: $event.clientX, y: $event.clientY})">

    <div>
      <h4>A</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-context-menu/cl-context-menu.component.html">
        View code on GitHub
      </a>
      <p>Click anywhere within the section</p>
      <p>[preventBackgroundScroll]="true"</p>

      <div style="color: green;">Click anywhere within the section to open the context menu (try window edges too)</div>

      <ssi-context-menu #someContextMenu
                        [preventBackgroundScroll]="true"
                        (ssiOffClick)="someContextMenu.hide();">
        <ng-template let-ctx>
          <div class="menu-body-content">
            <div>X: {{ctx.x}}</div>
            <div>Y: {{ctx.y}}</div>
            <br>
            <button type="button"
                    class="g-btn text-only-button"
                    (click)="$event.stopPropagation(); someContextMenu.hide();">Hide this menu</button>
          </div>
        </ng-template>
      </ssi-context-menu>
    </div>

    <div>
      <h4>B</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-context-menu/cl-context-menu.component.html">
        View code on GitHub
      </a>
      <p>Delete confirmation component used inside context menu</p>
      <p>Context provided to the template; offset can be provided too</p>

      <button type="button"
              class="g-btn g-btn-secondary bg-red"
              (click)="$event.stopPropagation(); confirmDeleteMenu.show($event, {message: 'Delete success!'});"><i class="ssi ssi-delete"></i><span>Delete</span></button>

      <ssi-context-menu #confirmDeleteMenu
                        [offsetLeft]="-2">
        <ng-template let-ctx>
          <ssi-confirm-action [title]="'Are you sure you want to delete?'"
                              dismissText="Cancel"
                              confirmText="Delete"
                              dismissTextColour="#838eab"
                              confirmTextColour="#f88c68"
                              (onDismiss)="confirmDeleteMenu.hide();"
                              (onConfirm)="confirmDeleteMenu.hide(); delete(ctx.message)"></ssi-confirm-action>
        </ng-template>
      </ssi-context-menu>
    </div>

  </div>
</div>