<div class="delete-confirmation">
  <div class="header">
      <h2>Are you sure you want to do this?</h2>
      <span>Warning!</span>
  </div>
  <div>
    <p class="info">{{info}}</p>
    <br/>
    <p class="info">If you’re ok with that, type "DELETE” below:</p>
    <input type="text" autofocus="autofocus" style="display:none" />
    <input type="text" class="orlo-text-input" [(ngModel)]="deleteString" (ngModelChange)="validate($event)" placeholder="Type 'DELETE'" />
  </div>
  <div class="footer">
    <orlo-button
    [state]="'secondary'"
    [text]="'Cancel'"
    (click)="activeModal.close(false)"
    [textColor]="'#FFFFFF'"
    [bgColor]="'#444652'"
    ></orlo-button>
    <orlo-button
      [state]="'primary'"
      [text]="'Delete posts'"
      (click)="isInvalid ? null : activeModal.close(true)"
      [textColor]="'#FFFFFF'"
      [bgColor]="'#F88C69'"
      [disabled]="isInvalid"
    ></orlo-button>
  </div>
</div>
