<h5>{{'INBOUND_MESSAGES_BY_NETWORK' | translate}}</h5>

<div class="network-info"
     *ngFor="let network of totalsByAccountTypeIterable">
   <span class="name">{{networksConst[network.account.account_type_name]?.label}}</span>
   <ssi-big-number class="comments-count"
                   [number]="network.current.messages_in"
                   [denotation]="{million: 'm', thousand: 'k'}"></ssi-big-number>
   <ssi-progress-bar class="bar"
                     [max]="highestMessagesInCount"
                     [progress]="network.current.messages_in"
                     [thickness]="4"
                     [color]="networksConst[network.account.account_type_name]?.color"
                     [bgColor]="'transparent'"></ssi-progress-bar>
</div>