import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { EnlargeImageModalModule } from '../../components/enlarge-image-modal/enlarge-image-modal.module';
import { EnlargeImageDirective } from './enlarge-image.directive';

@NgModule({
  imports: [CommonModule, NgbModalModule.forRoot(), EnlargeImageModalModule],
  exports: [EnlargeImageDirective],
  declarations: [EnlargeImageDirective]
})
export class EnlargeImageModule {}
