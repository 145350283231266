import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { EmojiFormControlContainerDirective } from './emoji-form-control-container.directive';
import { EmojiFormControlDirective } from './emoji-form-control/emoji-form-control.directive';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { EmojiFormControlTriggerComponent } from './emoji-form-control-trigger/emoji-form-control-trigger.component';

import { EmojiTextModule } from '../emoji-text/emoji-text.module';
import { FocusOnModule } from '../focus-on/focus-on.module';
import { PopoverOffClickModule } from '../popover-off-click/popover-off-click.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { VirtualScrollModule } from '../../components/virtual-scroll/virtual-scroll.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbPopoverModule.forRoot(),
    TooltipModule,
    EmojiTextModule,
    FocusOnModule,
    PopoverOffClickModule,
    VirtualScrollModule
  ],
  declarations: [
    EmojiFormControlContainerDirective,
    EmojiFormControlDirective,
    EmojiPickerComponent,
    EmojiFormControlTriggerComponent
  ],
  exports: [
    EmojiFormControlContainerDirective,
    EmojiFormControlDirective,
    EmojiFormControlTriggerComponent
  ]
})
export class EmojiFormControlContainerModule {}
