<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body modal-topic">
  <form #form="ngForm">
    <div class="form-field radio-group">
      <input
        id="enabled"
        name="enabled"
        [(ngModel)]="topicForm.is_enabled"
        type="checkbox"
      >
      <label for="enabled">Enabled</label>
    </div>
    <div class="form-field">
      <label for="name">Name: </label>
      <input
        id="name"
        type="text"
        class="form-control"
        name="name"
        [(ngModel)]="topicForm.name"
        required
      >
    </div>
    <div class="form-field">
      <label for="response">Response:</label>
      <textarea
        id="response"
        type="text"
        class="form-control"
        name="response"
        [(ngModel)]="topicForm.response"
        required
        rows="3"
      ></textarea>
    </div>
    <div class="form-field">
      <label for="link">Link:</label>
      <input
        id="link"
        type="text"
        class="form-control"
        name="link"
        [(ngModel)]="topicForm.link"
      >
    </div>
    <div class="form-field">
      <label for="link">Link text: (optional)</label>
      <input
        id="linkText"
        type="text"
        class="form-control"
        name="linkText"
        [(ngModel)]="topicForm.link_text"
      >
    </div>
    <div class="form-field">
      Response type:
      <br>
      <div class="radio-group">
        <input
          id="default"
          name="responseType"
          [(ngModel)]="responseType"
          type="radio"
          value="default"
        >
        <label 
          for="default" 
          ssiTooltip="The default flow means that this topic will be followed by asking the user if it was helpful or not. If it was helpful, the user can ask another question, if not the user will be asked if they want to chat to a human agent"
          [tooltipAppendToBody]="true">
            Default
        </label>
        <br>
        <input
          id="handover"
          name="responseType"
          [(ngModel)]="responseType"
          type="radio"
          value="handover"
        >
        <label 
          for="handover"
          ssiTooltip="When Handover is turned on, the Chat Bot will ask the customer if they would like to talk to a human about this Topic"
          [tooltipAppendToBody]="true">
            Force Handover
        </label>
        <br>
        <input
          id="no-handover"
          name="responseType"
          [(ngModel)]="responseType"
          type="radio"
          value="no-handover"
        >
        <label 
          for="no-handover"
          ssiTooltip="This option asks the user if the Topic was helpful or not. In both cases, the user is asked if they want to ask another question, but does not give them the option to chat to a human agent"
          [tooltipAppendToBody]="true">
            Prevent Handover
        </label>
        <br>
        <input
          id="conversational"
          name="responseType"
          [(ngModel)]="responseType"
          type="radio"
          value="conversational"
        >
        <label 
          for="conversational"
          ssiTooltip="Conversational topics will return the response and wait for more input, it will not ask for feedback"
          [tooltipAppendToBody]="true">
            Conversational
        </label>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="activeModal.close()"
    translate="CANCEL"
  >
  </button>
  <button
    [disabled]="form.invalid || form.pristine"
    type="button"
    class="btn btn-primary"
    (click)="submitTopicForm(topicForm)"
  >
    {{actionButton}}
  </button>
</div>