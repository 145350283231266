import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { MySettingsComponent } from './my-settings.component';
import { OrloComponentsModule } from 'orlo-components';
import { MyDetailsModule } from './my-details/my-details.module';
import { MyPasswordModule } from './my-password/my-password.module';
import { LoginActivityModule } from './login-activity/login-activity.module';
import { MyPreferencesModule } from './my-preferences/my-preferences.module';
import { NotificationSettingsModule } from './notification-settings/notification-settings.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.mysettings',
          url: '/my-settings',
          component: MySettingsComponent,
          resolve: MySettingsComponent.resolve
        }
      ]
    }),
    MyDetailsModule,
    MyPasswordModule,
    LoginActivityModule,
    MyPreferencesModule,
    NotificationSettingsModule
  ],
  declarations: [MySettingsComponent],
  entryComponents: [MySettingsComponent]
})
export class MySettingsModule {}
