import './campaign-list.component.scss';

import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import {
  Campaign,
  CampaignsService
} from '../../../../../../common/services/api/campaigns';
import { User, UserModel } from '@ui-resources-angular';
import { TooltipDirective } from '../../../../../../common/directives/tooltip/tooltip.directive';
import {
  commonDateRanges,
  DateRange,
  DateRanges
} from '../../../../../../common/constants';
import { Debounce } from '../../../../../../common/decorators';
import { mapToIterable } from '../../../../../../common/utils';
import { NotificationService } from '../../../../../../common/services/notification/notification.service';

@Component({
  selector: 'ssi-campaign-list',
  templateUrl: './campaign-list.component.html',
  styles: []
})
export class CampaignListComponent implements OnInit, OnDestroy {
  searchTerm = '';
  campaignsToShow: Campaign[] = [];
  activeCampaignsTab: 'live' | 'completed' = 'live';
  campaigns: {
    live: Campaign[];
    completed: Campaign[];
  } = {
    live: [],
    completed: []
  };
  authUser: User;

  // campaign management (edit, create)
  canManageCampaign: boolean;
  @ViewChildren('finishCampaignTooltip')
  finishCampaignTooltips: QueryList<TooltipDirective>;
  @ViewChildren('deleteCampaignTooltip')
  deleteCampaignTooltips: QueryList<TooltipDirective>;

  editing: boolean = false;
  creating: boolean = false;
  campaignForm: any;

  // date picker
  dateRanges: DateRanges = commonDateRanges;
  dateRangesIterable: DateRange[] = mapToIterable(this.dateRanges);
  selectedDateRange: DateRange = this.dateRanges.month;
  startDate: Date = this.selectedDateRange.start;
  endDate: Date = this.selectedDateRange.end;
  today: Date = moment().endOf('day').toDate();

  parentCampaigns: Campaign[] = [];
  parentCampaignsOptions: any;
  noParentCampaignOption = {
    name: 'No parent',
    unselectedValue: true
  };

  // form validations:
  validationMessage: {
    cantCloseBeforeStart: `Campaign can't close before it starts.`;
    childCantBeParent: `Child cannot be a parent to other campaign's`;
  };

  protected destroyed$ = new Subject<void>();

  constructor(
    private campaignsService: CampaignsService,
    private userModel: UserModel,
    private notification: NotificationService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    try {
      this.authUser = await this.userModel.getAuthUser();
      this.canManageCampaign = this.authUser.hasCompanyPermission(
        'manage_campaigns'
      );

      this.campaignsService.getAll();
      this.campaignsService.store.value$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((campaigns) => {
          const campaignsSortedByParent = this.campaignsService.sortByParent(
            campaigns
          );
          this.campaigns.live = campaignsSortedByParent.live;
          this.campaigns.completed = campaignsSortedByParent.completed;

          this.setCampaignsToShow();
        });
    } catch (error) {
      console.error('Error initialising Campaign list:', error);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeTab(tab: 'live' | 'completed') {
    this.activeCampaignsTab = tab;
    this.searchTerm = '';
    this.setCampaignsToShow();
  }

  // form
  generateForm(campaignToEdit?: Campaign) {
    this.campaignForm = {
      ...campaignToEdit,
      name: campaignToEdit ? campaignToEdit.name : null,
      parentCampaign: campaignToEdit
        ? this.campaigns.live.find((c) => c.id === campaignToEdit.parent_id)
        : null,
      started_at:
        campaignToEdit && campaignToEdit.started_at
          ? new Date(campaignToEdit.started_at)
          : null,
      closed_at:
        campaignToEdit && campaignToEdit.closed_at
          ? new Date(campaignToEdit.closed_at)
          : null
    };

    this.parentCampaigns = this.campaigns.live.filter((c) => !c.parent_id);

    this.parentCampaignsOptions = [
      this.noParentCampaignOption,
      ...this.parentCampaigns
    ];
  }

  onParentCampaignSelect(parent: Campaign | any) {
    this.campaignForm.parentCampaign = parent.unselectedValue ? null : parent;

    if (
      // Child campaign can't end after parent
      this.campaignForm.parentCampaign &&
      this.campaignForm.parentCampaign.closed_at &&
      moment(this.campaignForm.closed_at).isAfter(
        moment(this.campaignForm.parentCampaign.closed_at)
      )
    ) {
      this.campaignForm.closed_at = null;
    }
  }

  selectPresetDateRange(range: DateRange): void {
    this.selectedDateRange = this.dateRanges[range.id];

    this.startDate = this.selectedDateRange.start;
    this.endDate = this.selectedDateRange.end;
  }

  onStartDatePicked(startDate: Date): void {
    this.startDate.setHours(0, 0, 0, 0);
    this.selectedDateRange = undefined;
  }

  onEndDatePicked(endDate: Date): void {
    this.selectedDateRange = undefined;
    this.endDate.setHours(23, 59, 59, 999);
  }

  openEditCampaign(campaign: Campaign) {
    this.editing = true;
    this.generateForm(campaign);
  }

  openAddCampaign() {
    this.creating = true;
    this.generateForm();
  }

  // tooltips
  showFinishCampaignTooltip(rowIndex: number) {
    this.finishCampaignTooltips
      .toArray()
      .find((t) => t.tooltipId === rowIndex)
      .show();
  }

  hideFinishCampaignTooltip(rowIndex: number) {
    this.finishCampaignTooltips
      .toArray()
      .find((t) => t.tooltipId === rowIndex)
      .hide();
  }

  showDeleteCampaignTooltip(rowIndex: number) {
    this.deleteCampaignTooltips
      .toArray()
      .find((t) => t.tooltipId === rowIndex)
      .show();
  }

  hideDeleteCampaignTooltip(rowIndex: number) {
    this.deleteCampaignTooltips
      .toArray()
      .find((t) => t.tooltipId === rowIndex)
      .hide();
  }

  closeAllFinishCampaignTooltips() {
    this.finishCampaignTooltips.toArray().forEach((t) => t.hide());
  }

  closeAllDeleteCampaignTooltips() {
    this.deleteCampaignTooltips.toArray().forEach((t) => t.hide());
  }

  finishCampaign(campaign: Campaign, closedAt = new Date()) {
    const changes: any = {
      ...campaign,
      is_closed: true,
      closed_at: closedAt
    };
    this.campaignsService.createOrUpdate(changes);

    this.notification.open(
      this.translate.instant(
        campaign.parent_id
          ? 'CHILD_CAMPAIGN_FINISHED_NOTIFICATION'
          : 'CAMPAIGN_FINISHED_NOTIFICATION'
      ),
      {
        class: 'ssi ssi-completed-campaign-notification',
        color: '#F0B427'
      },
      5000
    );

    this.closeAllFinishCampaignTooltips();
  }

  reopenCampaign(campaign: Campaign) {
    const changes: any = {
      ...campaign,
      is_closed: false,
      closed_at: null
    };
    this.campaignsService.createOrUpdate(changes);
  }

  deleteCampaign(campaign: Campaign) {
    this.campaignsService.delete(campaign);
    this.closeAllDeleteCampaignTooltips();
  }

  async saveEdit(campaign?: Campaign) {
    try {
      const changes: any = {};

      if (
        this.campaignForm.started_at &&
        this.campaignForm.parentCampaign &&
        moment(this.campaignForm.started_at).isBefore(
          moment(this.campaignForm.parentCampaign.started_at)
        )
      ) {
        changes.started_at = null;
      }

      this.campaignForm = {
        ...this.campaignForm,
        parent_id: this.campaignForm.parentCampaign
          ? this.campaignForm.parentCampaign.id
          : null,
        ...changes
      };
      this.campaignsService.createOrUpdate({
        ...this.campaignForm
      });

      if (this.creating) {
        this.notification.open(
          this.translate.instant('CAMPAIGN_CREATED_NOTIFICATION'),
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          5000
        );
      } else {
        this.notification.open(
          this.translate.instant('CAMPAIGN_EDITED_NOTIFICATION'),
          {
            class: 'ssi ssi-edited-notification',
            color: '#89A4EA'
          },
          5000
        );
      }

      this.editing = false;
      this.creating = false;
    } catch (error) {
      console.error('Error saving campaign:', error);
    }
  }

  @Debounce(400, false)
  onFilterInput(event): void {
    this.setCampaignsToShow();
  }

  setCampaignsToShow(): void {
    if (!this.searchTerm) {
      this.campaignsToShow = [...this.campaigns[this.activeCampaignsTab]];
      return;
    }

    this.campaignsToShow = this.campaigns[this.activeCampaignsTab].filter(
      (c) =>
        (c.name || '').toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
    );

    this.campaignsToShow.forEach((c, i) => {
      // child campaign can't stand alone, add parent event if it doesn't match the filter
      if (c.parent_id) {
        const parent = this.campaigns[this.activeCampaignsTab].find(
          (x) => x.id === c.parent_id
        );
        if (this.campaignsToShow.indexOf(parent) === -1) {
          this.campaignsToShow.splice(i, 0, parent);
        }
      }
    });
  }
}
