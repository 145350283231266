import './streams-select.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import {
  MonitoringStream,
  MonitoringStreamsService
} from '../../../../../../common/services/api';
import { IftttService } from '../../../ifttt-utils/ifttt-service-model/ifttt-service-model';
import { Applet } from '../../../ifttt-utils/applets/applet';

export async function streamsResolveFn(service: MonitoringStreamsService) {
  try {
    return await service.getAll();
  } catch (e) {
    console.log(e);
  }
}

@Component({
  selector: 'ssi-streams-select',
  templateUrl: './streams-select.component.html',
  styles: []
})
export class StreamsSelectComponent implements OnInit {
  static resolve = [
    {
      token: 'streams',
      resolveFn: streamsResolveFn,
      deps: [MonitoringStreamsService]
    }
  ];

  @Input() service: IftttService;
  @Input() applet: Applet;
  @Input() streams: MonitoringStream[] = [];

  constructor() {}

  ngOnInit() {
    console.log('service: ', this.service);
    console.log('applet: ', this.applet);
    console.log('streams: ', this.streams);
  }

  onSelectedStreamsChange(selectedStreams: MonitoringStream[]): void {
    // console.log('selectedStreams: ', selectedStreams);
  }
}
