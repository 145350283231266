import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimePickerComponent } from './date-time-picker.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {
  DlDateTimeDateModule,
  DlDateTimePickerModule
} from 'angular-bootstrap-datetimepicker';
import {
  NgbDropdownModule,
  NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarCommonModule,
  CalendarDateFormatter,
  CalendarMonthModule
} from 'angular-calendar';
import { CalendarShortDateFormatterService } from '../publisher/publisher-schedule/publisher-schedule-date-picker/calendar-short-date-formatter/calendar-short-date-formatter.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    DlDateTimeDateModule, // <--- Determines the data type of the model
    DlDateTimePickerModule,
    CalendarCommonModule.forRoot({
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CalendarShortDateFormatterService
      }
    }),
    CalendarMonthModule,
    NgbTimepickerModule,
    OverlayModule,
    TooltipModule
  ],
  declarations: [DateTimePickerComponent],
  exports: [DateTimePickerComponent]
})
export class DateTimePickerModule {}
