import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { Outbox, OutboxMessageType, OutboxModel } from '@ui-resources-angular';
import { ApiService } from '../../../../common/services/api';
import { TIMEZONE_OFFSET } from '../../../../common/constants';
import { CustomCalendarEvent } from '../../../../common/components/calendar-utils';
import { CompanyPreferences } from '../../../../common/services/company/company.service';

export const MAX_POSTS_PER_LOAD = 500;

export interface OutboxSearchParams {
  start_date: Date | string;
  end_date: Date | string;
  types: OutboxMessageType[];
  accounts: string[];
  created_by: string | null;
  sort_dir: 'asc' | 'desc';
  deleted: 'include' | 'exclude' | 'exclusive';
  scheduled: 'include' | 'exclude' | 'exclusive';
  status: string | null; // published, publish_failed, etc... TODO: add proper types...
  validated: '0' | '1' | '2' | null;
  text: string | null;
  campaign_id: string[] | undefined; // if null is passed then posts that have a campaign tagged don't get returned by the backend
  post_tags: string[] | null;
}

export interface OutboxSearchResponse {
  posts: Outbox[];
}

export interface CalendarMonthViewTotals {
  [date: string]: { [socialNetwork: string]: number };
}

@Injectable({ providedIn: 'root' })
export class ContentCalendarService {
  constructor(
    protected injector: Injector,
    protected api: ApiService,
    protected outboxModel: OutboxModel
  ) {}

  outboxSearch(
    params: OutboxSearchParams,
    offset = 0,
    limit = MAX_POSTS_PER_LOAD
  ): Promise<OutboxSearchResponse> {
    const endpoint = `${this.api.url}/outbox/search`;

    const payload = {
      offset,
      limit,
      v: 2,
      tz_offset: TIMEZONE_OFFSET,
      // with: ['boosted_post_counts'],
      ...params
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: OutboxSearchResponse) => {
          response.posts = this.outboxModel.inject(response.posts);
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  getCalendarMonthTotals(
    params: OutboxSearchParams
  ): Promise<CalendarMonthViewTotals> {
    // const queryClone = Object.assign({}, this.apiQuery, {
    //   start_date: moment(calendarDate).startOf('month').format(),
    //   end_date: moment(calendarDate).endOf('month').format(),
    //   tz_offset: TIMEZONE_OFFSET
    // });

    const endpoint = `${this.api.url}/outbox/calendar`;

    const payload = {
      start_date: params.start_date,
      end_date: params.end_date,
      tz_offset: TIMEZONE_OFFSET,
      deleted: 'exclude',
      scheduled: 'include',
      //   sort_dir: 'asc',
      types: params.types,
      accounts: params.accounts
    };

    return this.api
      .post(endpoint, payload)
      .pipe(
        map((response: CalendarMonthViewTotals) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  saveCalendarEvents(
    events: CustomCalendarEvent[],
    companyPreferences: CompanyPreferences
  ): Promise<any> {
    companyPreferences.company_preferences.custom_calendar_events = JSON.stringify(
      events.map(({ title, start, end, colour }) => {
        return {
          title,
          startsAt: start,
          endsAt: end,
          colour
        };
      })
    );
    return this.api
      .post(`${this.api.url}/settings/companyPreference`, companyPreferences)
      .pipe(
        map((response: boolean) => {
          return response;
        }),
        catchError((e) =>
          this.api.mapError(e, `${this.api.url}/settings/companyPreference`)
        )
      )
      .toPromise();
  }
}
