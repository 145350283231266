import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';

export interface Feed {
  account_id: number;
  company_id: number;
  id: number;
  name: string;
  url: string;
  isSelected?: boolean;
  updated_at?: string;
}

const articleCache = {};

export interface RssArticle {
  id: string;
  url: string;
  title: string;
  date: Date;
  updated_date: Date;
  published_date: Date;
  author: string;
  content: string;
  img: string;
  logo: string;
}

@Injectable()
export class ContentGeneratorService {
  constructor(private api: API) {}

  async getRssFeed(): Promise<Feed[]> {
    return await this.api
      .get('contentGenerator/feed', { params: { v: 2 } })
      .then(({ data }) => data);
  }

  async createRssFeed(newFeed): Promise<Feed> {
    return await this.api
      .post('contentGenerator/feed', newFeed)
      .then(({ data }) => data);
  }

  async getFeedArticles(url): Promise<RssArticle[]> {
    if (!articleCache.hasOwnProperty(url)) {
      articleCache[url] = await this.api
        .get('contentGenerator/proxy', { params: { url }, autoError: false })
        .then(({ data }) => data);
    }
    return articleCache[url];
  }

  async testFeedArticles(url): Promise<any[]> {
    return await this.api
      .get('contentGenerator/proxy', { params: { url }, autoError: false })
      .then(({ data }) => data);
  }
}
