<a
  *ngIf="hasLink"
  #linkTrigger
  class="link-trigger"
  target="_blank"
>
  <ng-container
    *ngIf="! linkUrl"
  >
    <span
      class="spinner"
    >
      <i
        class="ssi ssi-loading ssi-spin"
      ></i>
    </span>
  </ng-container>

  <ng-container
    *ngIf="!! linkUrl"
  >
    <span
      *ngIf="linkTitle"
      class="link-title"
    >
      {{ linkTitle }}
    </span>

    <span
      *ngIf="linkDescription"
      class="link-description"
    >
      {{ linkDescription }}
    </span>

    <img
      *ngIf="linkImage"
      [src]="linkImage"
      class="link-preview"
    >
  </ng-container>
</a>
