import './publisher-facebook-post-preview.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import {
  OutboxPublisher,
  Account,
  SocialNetworkLinkPreview,
  OutboxPublisherLink,
  OutboxPublisherMention,
  OutboxPublisherFile
} from '@ui-resources-angular';
import { nl2br } from '../../../../util';
import { OutboxPostTextLinkifyPipe } from '../outbox-post-text-linkify/outbox-post-text-linkify.pipe';

@Component({
  selector: 'ssi-publisher-facebook-post-preview',
  templateUrl: './publisher-facebook-post-preview.component.html'
})
export class PublisherFacebookPostPreviewComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() account: Account;
  @Input() text: string;
  @Input() preview: SocialNetworkLinkPreview;
  @Input() links: OutboxPublisherLink[];
  @Input() mentions: OutboxPublisherMention[];
  @Input() files: OutboxPublisherFile[];
  textWithoutGifUrl: string;
  linksWithoutGifUrl: OutboxPublisherLink[];
  isTruncated = false;
  limit = 480;

  constructor(private outboxPostTextLinkifyPipe: OutboxPostTextLinkifyPipe) {}

  ngOnInit() {
    const formattedText = this.outboxPostTextLinkifyPipe.transform(
      nl2br(this.text),
      this.account,
      this.links,
      this.mentions
    );
    this.isTruncated = formattedText.length > this.limit;

    if (!this.files.length && this._isLastWordLink(this.text, this.links)) {
      this.linksWithoutGifUrl = this.links.splice(0, this.links.length - 1);
      this.textWithoutGifUrl = this._getTextWithRemovedLastLink(
        this.text,
        this.links
      );
      const formattedTextWithoutGifUrl = this.outboxPostTextLinkifyPipe.transform(
        nl2br(this.textWithoutGifUrl),
        this.account,
        this.links,
        this.mentions
      );
      this.isTruncated = formattedTextWithoutGifUrl.length > this.limit;
    }
  }

  private _getTextWithRemovedLastLink(text, links) {
    const lastLinkIndices = links[links.length - 1].indices;
    return (
      text.substr(0, lastLinkIndices.start) + text.substr(lastLinkIndices.end)
    );
  }

  private _isLastWordLink(text, links) {
    const linkUrls = links.map((link) => link.data.url);
    const textWords = text.split(' ');
    const lastWord = textWords[textWords.length - 1].trim();
    return linkUrls.includes(lastWord);
  }
}
