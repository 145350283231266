import { Injectable } from '@angular/core';
import delay from 'timeout-as-promise';
import {
  FILESTACK_CDN_URL,
  FILESTACK_CONTENT_CDN_URL,
  FilestackFile
} from '../filestack/filestack.service';
import { filestackApiKey } from '@ui-resources-angular';
import axios from 'axios';
import { userAgent } from '../../constants';
import { ENVIRONMENT, EnvironmentType } from '../../../environment';

export const FILESTACK_POLL_MS = 3000;

enum FILESTACK_VIDEO_CONVERSION_STATUSES {
  Started = 'started',
  Pending = 'pending',
  Completed = 'completed'
}

interface VideoDimensions {
  width: number;
  height: number;
}

function getMetadataUrl(apiKey: string, fileUrl: string) {
  return `https://cdn.uploads.orlo.app/${apiKey}/video_convert=u:false,preset:h264,access:private,a:preserve/${fileUrl}`;
}

function getResizeUrl(
  apiKey: string,
  fileUrl: string,
  dimensions: VideoDimensions
) {
  return `https://cdn.uploads.orlo.app/${apiKey}/video_convert=preset:h264,access:private,width:${dimensions.width},height:${dimensions.height},a:constrain/${fileUrl}`;
}

interface FileStackVideoConversionResult {
  data: {
    thumb: string;
    thumb100x100: string;
    thumb200x200: string;
    thumb300x300: string;
    url: string;
  };
  metadata: {
    result: {
      audio_bitrate: number;
      audio_channels: number;
      audio_codec: string;
      audio_sample_rate: number;
      created_at: string;
      duration: number;
      encoding_progress: number;
      encoding_time: number;
      extname: string;
      file_size: number;
      fps: number;
      height: number;
      mime_type: string;
      started_encoding_at: string;
      updated_at: string;
      video_bitrate: number;
      video_codec: string;
      width: number;
    };
    source: {
      audio_bitrate: number;
      audio_channels: number;
      audio_codec: string;
      audio_sample_rate: number;
      created_at: string;
      duration: number;
      extname: string;
      file_size: number;
      fps: number;
      height: number;
      mime_type: string;
      updated_at: string;
      video_bitrate: number;
      video_codec: string;
      width: number;
    };
  };
  status: string;
  timestamp: string;
  uuid: string;
}

function convertAndGetFilestackVideoMetadata(
  conversionUrl: string
): Promise<FileStackVideoConversionResult> {
  return axios
    .get(conversionUrl, { skipAuthorization: true } as any)
    .then(({ data }) => {
      switch (data.status) {
        case FILESTACK_VIDEO_CONVERSION_STATUSES.Started:
        case FILESTACK_VIDEO_CONVERSION_STATUSES.Pending:
          return delay(FILESTACK_POLL_MS).then(() =>
            convertAndGetFilestackVideoMetadata(conversionUrl)
          );
          break;

        case FILESTACK_VIDEO_CONVERSION_STATUSES.Completed:
          if (data.data.url) {
            return data;
          } else {
            return delay(FILESTACK_POLL_MS).then(() =>
              convertAndGetFilestackVideoMetadata(conversionUrl)
            );
          }
          break;

        default:
          return Promise.reject(data);
      }
    });
}

interface VideoMetadata {
  dimensions: {
    width: number;
    height: number;
  };
  duration: number;
}

function getVideoMetaDataNatively(url: string): Promise<VideoMetadata> {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    // should only pre-load the metadata and not the whole video
    video.preload = 'metadata';
    video.src = url;
    video.onloadedmetadata = () => {
      resolve({
        dimensions: {
          width: video.videoWidth,
          height: video.videoHeight
        },
        duration: Math.round(video.duration * 1000)
      });
    };
  });
}

// source: https://www.w3schools.com/tags/att_video_preload.asp
// IE doesnn't support preload="metadata"
const loadMetadataNatively = [
  'Chrome',
  'Firefox',
  'Safari',
  'Opera',
  'Edge'
].includes(userAgent.getBrowser().name);
@Injectable()
export class VideoInfoService {
  //
  async getMetadata(fileUrl: string): Promise<VideoMetadata> {
    if (loadMetadataNatively && ENVIRONMENT !== EnvironmentType.Test) {
      return await getVideoMetaDataNatively(fileUrl);
    } else {
      console.error(
        'Getting metadata from FILESTACK might not work this way anymore, please report if you see this error..'
      );
      const conversionUrl = getMetadataUrl(filestackApiKey, fileUrl);
      const result = await convertAndGetFilestackVideoMetadata(conversionUrl);
      return {
        dimensions: {
          width: result.metadata.source.width,
          height: result.metadata.source.height
        },
        duration: result.metadata.source.duration
      };
    }
  }

  async resizeVideo(
    fileUrl: string,
    newDimensions: VideoDimensions
  ): Promise<{
    url: string;
    size: number;
    mimetype: string;
    fsVideoMetadata: any;
  }> {
    const result = await convertAndGetFilestackVideoMetadata(
      getResizeUrl(filestackApiKey, fileUrl, newDimensions)
    );
    // manually replacing CNAME with our own since video processing through URL
    // doesn't support passing the cname in tasks within the URL
    result.data.url = result.data.url.replace(
      FILESTACK_CONTENT_CDN_URL,
      FILESTACK_CDN_URL
    );
    result.data.thumb = result.data.url.replace(
      FILESTACK_CONTENT_CDN_URL,
      FILESTACK_CDN_URL
    );
    console.log('Video resize result: ', result);

    return {
      url: result.data.url,
      size: result.metadata.result.file_size,
      mimetype: result.metadata.result.mime_type,
      fsVideoMetadata: result.metadata
    };
  }
}
