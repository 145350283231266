<ssi-sentiment
  [positiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
  [semiPositiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
  [neutralTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
  [semiNegativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
  [negativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative">
</ssi-sentiment>

<div class="text-center d-flex">
  <ssi-sentiment-text
    class="flex-1"
    sentiment="positive"
    [positiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
    [semiPositiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
    [neutralTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
    [semiNegativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
    [negativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative">
    <small class="font-weight-normal">
      <strong
        class="text-muted"
        translate="_TOTAL__POSITIVE_MESSAGES_IN_TOTAL"
        [translateParams]="{total: report.stats?.activity.period.current?.inbound.sentiment.positive}">
      </strong>
      <div class="spacer-top spacer-bottom">
        <ssi-total-change
          class="text-italic"
          [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
          [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.positive"
          increaseTranslationKey="UP_BY__AMOUNT__MESSAGES"
          decreaseTranslationKey="DOWN_BY__AMOUNT__MESSAGES"
          changeValueKey="amount">
        </ssi-total-change>
      </div>
      <ssi-total-change
        class="text-italic"
        [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
        [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.positive"
        increaseTranslationKey="UP__PERCENTAGE__FROM_COMPARISON_PERIOD"
        decreaseTranslationKey="DOWN__PERCENTAGE__FROM_COMPARISON_PERIOD"
        noChangeTranslationKey="NO_CHANGE_FROM_COMPARISON_PERIOD"
        changeValueKey="percentage"
        [showAsPercentageOf]="{current: report.stats?.activity.period.current?.inbound.count, previous: report.stats?.activity.period.previous?.inbound.count}">
      </ssi-total-change>
    </small>
  </ssi-sentiment-text>

  <ssi-sentiment-text
    class="flex-1"
    sentiment="semiPositive"
    [positiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
    [semiPositiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
    [neutralTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
    [semiNegativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
    [negativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative">
    <small class="font-weight-normal">
      <strong
        class="text-muted"
        translate="_TOTAL__SEMI_POSITIVE_MESSAGES_IN_TOTAL"
        [translateParams]="{total: report.stats?.activity.period.current?.inbound.sentiment.semi_positive}">
      </strong>
      <div class="spacer-top spacer-bottom">
        <ssi-total-change
          class="text-italic"
          [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
          [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.semi_positive"
          increaseTranslationKey="UP_BY__AMOUNT__MESSAGES"
          decreaseTranslationKey="DOWN_BY__AMOUNT__MESSAGES"
          changeValueKey="amount">
        </ssi-total-change>
      </div>
      <ssi-total-change
        class="text-italic"
        [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
        [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.semi_positive"
        increaseTranslationKey="UP__PERCENTAGE__FROM_COMPARISON_PERIOD"
        decreaseTranslationKey="DOWN__PERCENTAGE__FROM_COMPARISON_PERIOD"
        noChangeTranslationKey="NO_CHANGE_FROM_COMPARISON_PERIOD"
        changeValueKey="percentage"
        [showAsPercentageOf]="{current: report.stats?.activity.period.current?.inbound.count, previous: report.stats?.activity.period.previous?.inbound.count}">
      </ssi-total-change>
    </small>
  </ssi-sentiment-text>

  <ssi-sentiment-text
    class="flex-1"
    sentiment="neutral"
    [positiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
    [semiPositiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
    [neutralTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
    [semiNegativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
    [negativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative">
    <small class="font-weight-normal">
      <strong
        class="text-muted"
        translate="_TOTAL__NEUTRAL_MESSAGES_IN_TOTAL"
        [translateParams]="{total: report.stats?.activity.period.current?.inbound.sentiment.neutral}">
      </strong>
      <div class="spacer-top spacer-bottom">
        <ssi-total-change
          class="text-italic"
          [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
          [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.neutral"
          increaseTranslationKey="UP_BY__AMOUNT__MESSAGES"
          decreaseTranslationKey="DOWN_BY__AMOUNT__MESSAGES"
          changeValueKey="amount">
        </ssi-total-change>
      </div>
      <ssi-total-change
        class="text-italic"
        [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
        [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.neutral"
        increaseTranslationKey="UP__PERCENTAGE__FROM_COMPARISON_PERIOD"
        decreaseTranslationKey="DOWN__PERCENTAGE__FROM_COMPARISON_PERIOD"
        noChangeTranslationKey="NO_CHANGE_FROM_COMPARISON_PERIOD"
        changeValueKey="percentage"
        [showAsPercentageOf]="{current: report.stats?.activity.period.current?.inbound.count, previous: report.stats?.activity.period.previous?.inbound.count}">
      </ssi-total-change>
    </small>
  </ssi-sentiment-text>

  <ssi-sentiment-text
    class="flex-1"
    sentiment="semiNegative"
    [positiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
    [semiPositiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
    [neutralTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
    [semiNegativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
    [negativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative">
    <small class="font-weight-normal">
      <strong
        class="text-muted"
        translate="_TOTAL__SEMI_NEGATIVE_MESSAGES_IN_TOTAL"
        [translateParams]="{total: report.stats?.activity.period.current?.inbound.sentiment.semi_negative}">
      </strong>
      <div class="spacer-top spacer-bottom">
        <ssi-total-change
          class="text-italic"
          [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
          [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.semi_negative"
          increaseTranslationKey="UP_BY__AMOUNT__MESSAGES"
          decreaseTranslationKey="DOWN_BY__AMOUNT__MESSAGES"
          changeValueKey="amount"
          [lowerIsPositive]="true">
        </ssi-total-change>
      </div>
      <ssi-total-change
        class="text-italic"
        [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
        [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.semi_negative"
        increaseTranslationKey="UP__PERCENTAGE__FROM_COMPARISON_PERIOD"
        decreaseTranslationKey="DOWN__PERCENTAGE__FROM_COMPARISON_PERIOD"
        noChangeTranslationKey="NO_CHANGE_FROM_COMPARISON_PERIOD"
        changeValueKey="percentage"
        [showAsPercentageOf]="{current: report.stats?.activity.period.current?.inbound.count, previous: report.stats?.activity.period.previous?.inbound.count}"
        [lowerIsPositive]="true">
      </ssi-total-change>
    </small>
  </ssi-sentiment-text>

  <ssi-sentiment-text
    class="flex-1"
    sentiment="negative"
    [positiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.positive"
    [semiPositiveTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_positive"
    [neutralTotal]="report.stats?.activity.period.current?.inbound.sentiment.neutral"
    [semiNegativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.semi_negative"
    [negativeTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative">
    <small class="font-weight-normal">
      <strong
        class="text-muted"
        translate="_TOTAL__NEGATIVE_MESSAGES_IN_TOTAL"
        [translateParams]="{total: report.stats?.activity.period.current?.inbound.sentiment.negative}">
      </strong>
      <div class="spacer-top spacer-bottom">
        <ssi-total-change
          class="text-italic"
          [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative"
          [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.negative"
          increaseTranslationKey="UP_BY__AMOUNT__MESSAGES"
          decreaseTranslationKey="DOWN_BY__AMOUNT__MESSAGES"
          changeValueKey="amount"
          [lowerIsPositive]="true">
        </ssi-total-change>
      </div>
      <ssi-total-change
        class="text-italic"
        [currentTotal]="report.stats?.activity.period.current?.inbound.sentiment.negative"
        [previousTotal]="report.stats?.activity.period.previous?.inbound.sentiment.negative"
        increaseTranslationKey="UP__PERCENTAGE__FROM_COMPARISON_PERIOD"
        decreaseTranslationKey="DOWN__PERCENTAGE__FROM_COMPARISON_PERIOD"
        noChangeTranslationKey="NO_CHANGE_FROM_COMPARISON_PERIOD"
        changeValueKey="percentage"
        [showAsPercentageOf]="{current: report.stats?.activity.period.current?.inbound.count, previous: report.stats?.activity.period.previous?.inbound.count}"
        [lowerIsPositive]="true">
      </ssi-total-change>
    </small>
  </ssi-sentiment-text>
</div>
