// Very Distrusting (-100 to -50)
// Distrusting (-49 to -10)
// In the middle (-9 to 9)
// Trusting (10 - 49)
// Very Trusting (50+)

import { mapToIterable } from '../utils';

export interface Trustometer {
  key: TrustometerKey;
  label: string;
  icon: string;
  color: string;
  bgColor: string;
  iconColor: string;
  iconBgColor: string;
  fontColor: string;
}

export interface Trustometers {
  veryDistrusting: Trustometer;
  distrusting: Trustometer;
  middle: Trustometer;
  trusting: Trustometer;
  veryTrusting: Trustometer;
  noData?: Trustometer;
}

export enum TrustometerKey {
  veryDistrusting = 'veryDistrusting',
  distrusting = 'distrusting',
  middle = 'middle',
  trusting = 'trusting',
  veryTrusting = 'veryTrusting',
  noData = 'noData'
}

export const trustometers: Trustometers = {
  veryDistrusting: {
    key: TrustometerKey.veryDistrusting,
    label: 'VERY_DISTRUSTING',
    icon: 'ssi-negative-new',
    color: '#F05230',
    bgColor: 'rgba(248, 140, 104, 0.2)',
    iconColor: '#F05230',
    iconBgColor: '#F05230',
    fontColor: '#F05230'
  },
  distrusting: {
    key: TrustometerKey.distrusting,
    label: 'DISTRUSTING',
    icon: 'ssi-semi-negative-new',
    color: '#98efff',
    bgColor: 'rgba(248, 140, 104, 0.2)',
    iconColor: '#14bae3',
    iconBgColor: '#F05230',
    fontColor: '#F05230'
  },
  middle: {
    key: TrustometerKey.middle,
    label: 'IN_THE_MIDDLE',
    icon: 'ssi-neutral-new',
    color: '#d4e0fa',
    bgColor: 'rgba(192, 192, 216, 0.2)',
    iconColor: '#657fc8',
    iconBgColor: '#C0C0D8',
    fontColor: '#43537F'
  },
  trusting: {
    key: TrustometerKey.trusting,
    label: 'TRUSTING',
    icon: 'ssi-semi-positive-new',
    color: '#98efff',
    bgColor: 'rgba(42, 227, 216, 0.2)',
    iconColor: '#14bae3',
    iconBgColor: '#0DCDC2',
    fontColor: '#04988F'
  },
  veryTrusting: {
    key: TrustometerKey.veryTrusting,
    label: 'VERY_TRUSTING',
    icon: 'ssi-positive-new',
    color: '#00bde9',
    bgColor: 'rgba(42, 227, 216, 0.2)',
    iconColor: '#fff',
    iconBgColor: '#0DCDC2',
    fontColor: '#04988F'
  },
  noData: {
    key: TrustometerKey.noData,
    label: 'THERE_WAS_NOT_ENOUGH_DATA_TO_CALCULATE_TRUST',
    icon: 'ssi-neutral-new',
    color: '#C0C0D8',
    bgColor: 'rgba(192, 192, 216, 0.2)',
    iconColor: '#657fc8',
    iconBgColor: '#C0C0D8',
    fontColor: '#43537F'
  }
};

// specs:
// Very Distrusting (-100 to -50)
// Distrusting (-49 to -10)
// In the middle (-9 to 9)
// Trusting (10 - 49)
// Very Trusting (50+)
export const getTrustometerObj = (score: number) => {
  if (score === undefined || isNaN(score) || score < -100 || score > 100) {
    throw new Error(`Unsupported score value: ${score}`);
  }
  if (score === null) {
    return trustometers.noData;
  }
  if (score >= -100 && score <= -50) {
    return trustometers.veryDistrusting;
  } else if (score > -50 && score <= -10) {
    return trustometers.distrusting;
  } else if (score > -10 && score < 10) {
    return trustometers.middle;
  } else if (score >= 10 && score < 50) {
    return trustometers.trusting;
  } else if (score >= 50 && score <= 100) {
    return trustometers.veryTrusting;
  }
};

// Very Distrusting (-100 to -50)
// Distrusting (-49 to -10)
// In the middle (-9 to 9)
// Trusting (10 - 49)
// Very Trusting (50+)

// Very distrusting -> from -50 (including) to -100 (including)?
// Distrusting -> from -49.99 (including), to -10 (including)?
// Middle -> from -9.99 (including), up to 9.99 (including)?
// Trusting -> from 10 (including), to 49.99 (including)?
// Very trusting - from 50 (including), to 100 (including)

export const trustometersIterable: Trustometer[] = mapToIterable(trustometers);
