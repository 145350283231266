import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStore } from 'js-data';
import {
  accountModelFactory,
  AccountModel
} from '../../modules/account/services/accountModel';
import {
  workflowModelFactory,
  WorkflowModel
} from '../../modules/account/services/workflowModel';
import {
  vanityDomainModelFactory,
  VanityDomainModel
} from '../../modules/account/services/vanityDomainModel';

@NgModule()
export class AccountModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AccountModule,
      providers: [
        {
          provide: AccountModel,
          useFactory: accountModelFactory,
          deps: [DataStore]
        },
        {
          provide: WorkflowModel,
          useFactory: workflowModelFactory,
          deps: [DataStore]
        },
        {
          provide: VanityDomainModel,
          useFactory: vanityDomainModelFactory,
          deps: [DataStore]
        }
      ]
    };
  }
}
