import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ValidatePostsComponent } from './validate-posts.component';
import { ValidatesPostsBoxModule } from './validate-posts-box/validate-posts-box.module';
import { ValidatePostsFiltersModule } from './validate-posts-filters/validate-posts-filters.module';
import { FormsModule } from '@angular/forms';
import { ValidatePostsModalModule } from './validate-posts-modal/validate-posts-modal.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.validatePosts',
          url: '/validate',
          component: ValidatePostsComponent,
          resolve: ValidatePostsComponent.resolve
        }
      ]
    }),
    ValidatesPostsBoxModule,
    ValidatePostsFiltersModule,
    FormsModule,
    ValidatePostsModalModule
  ],
  declarations: [ValidatePostsComponent],
  entryComponents: [ValidatePostsComponent]
})
export class ValidatePostsModule {}
