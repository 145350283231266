import { Record } from 'js-data';
import { Model } from '../../model';
import { api } from '../../core/services/api';
import { services } from '../../common';

export class VanityDomain extends Record {
  id: number;
  domain: string;

  destroy() {
    return api
      .del('company/vanityDomain', {
        params: {
          id: this.id
        }
      })
      .then(() => {
        services.models.get<VanityDomainModel>('vanityDomain').eject(this.id);
      });
  }
}

export class VanityDomainModel extends Model<VanityDomain> {
  constructor() {
    super('vanityDomain', {
      endpoint: 'company/vanityDomain',
      deserialize(resourceConfig, attrs) {
        return attrs.data.vanity_domains;
      },
      recordClass: VanityDomain
    });
  }

  create(domain: string): Promise<VanityDomain> {
    return api
      .post<{ data: { id: number; domain: string } }>('company/vanityDomain', {
        domain
      })
      .then(({ data }) => {
        return this.add(data);
      });
  }
}

export function vanityDomainModelFactory(dataStore?) {
  return services.models.get('vanityDomain') || new VanityDomainModel();
}
