import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum KeyboardShortcut {
  MarkActioned = 'a',
  MarkUnread = 'u',
  AddResponse = 'r',
  AmendSentiment = 's',
  AmendLanguage = 'l',
  ShowTags = 't',
  AddFilter = 'f'
}

@Injectable()
export class InboxKeyboardShortcutsService {
  shortcutPressed$ = new Subject<KeyboardShortcut>();

  constructor() {}
}
