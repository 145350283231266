import './social-network-select.component.scss';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccountTypeId, AccountTypeName } from '../../../../../common/enums';

export interface NetworkSelectItem {
  key: string;
  label: string;
  account_type_id: AccountTypeId;
  account_type_name: string;
}

export const networkSelectItems: NetworkSelectItem[] = [
  {
    key: 'all',
    label: 'All Networks',
    account_type_id: undefined,
    account_type_name: undefined
  },
  {
    key: String(AccountTypeId.Twitter),
    label: 'X',
    account_type_id: AccountTypeId.Twitter,
    account_type_name: AccountTypeName.Twitter
  },
  {
    key: String(AccountTypeId.Facebook),
    label: 'Facebook',
    account_type_id: AccountTypeId.Facebook,
    account_type_name: AccountTypeName.Facebook
  },
  {
    key: String(AccountTypeId.Instagram),
    label: 'Instagram',
    account_type_id: AccountTypeId.Instagram,
    account_type_name: AccountTypeName.InstagramBusiness
  },
  {
    key: String(AccountTypeId.LinkedIn),
    label: 'LinkedIn',
    account_type_id: AccountTypeId.LinkedIn,
    account_type_name: AccountTypeName.LinkedIn
  },
  {
    key: String(AccountTypeId.YouTube),
    label: 'YouTube',
    account_type_id: AccountTypeId.YouTube,
    account_type_name: AccountTypeName.YouTube
  },
  {
    key: String(AccountTypeId.Nextdoor),
    label: 'Nextdoor',
    account_type_id: AccountTypeId.Nextdoor,
    account_type_name: AccountTypeName.Nextdoor
  },
  {
    key: String(AccountTypeId.NextdoorUS),
    label: 'Nextdoor US',
    account_type_id: AccountTypeId.NextdoorUS,
    account_type_name: AccountTypeName.NextdoorUS
  },
  {
    key: String(AccountTypeId.TikTokBusiness),
    label: 'TikTok',
    account_type_id: AccountTypeId.TikTokBusiness,
    account_type_name: AccountTypeName.TiktokBusiness
  }
];

@Component({
  selector: 'ssi-social-network-select',
  templateUrl: './social-network-select.component.html',
  styles: []
})
export class SocialNetworkSelectComponent implements OnInit {
  @Input() selectableNetworks: NetworkSelectItem[] = networkSelectItems;
  @Input() selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  @Output() networkSelect = new EventEmitter<NetworkSelectItem>();

  constructor() {}

  ngOnInit() {
    if (!this.selectedNetwork && Array.isArray(this.selectableNetworks)) {
      this.selectedNetwork =
        this.selectableNetworks && this.selectableNetworks[0];
    }
  }

  select(item: NetworkSelectItem): void {
    this.selectedNetwork = item;
    this.networkSelect.emit(item);
  }
}
