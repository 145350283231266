import './suggested-text-modal.component.scss';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface SuggestedTextModalResult {
  overwrite: boolean;
  addToPost: boolean;
  ignore: boolean;
}
@Component({
  selector: 'ssi-suggested-text-modal',
  templateUrl: './suggested-text-modal.component.html'
})
export class SuggestedTextModalComponent {
  suggestedText = '';
  mediaNote = '';

  constructor(public activeModal: NgbActiveModal) {}

  overwrite() {
    this.activeModal.close({ overwrite: true });
  }

  addToPost() {
    this.activeModal.close({ addToPost: true });
  }

  ignore() {
    this.activeModal.close({ ignore: true });
  }
}
