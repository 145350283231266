<ng-template #popoverContent>
  <ssi-emoji-picker
    (emojiSelected)="emojiSelected($event.emoji)"
    (ssiPopoverOffClick)="closePopover()"
    [popoverIsOpen]="popoverIsOpen">
  </ssi-emoji-picker>
</ng-template>

<span
  #popover="ngbPopover"
  (hidden)="popoverIsOpen = false"
  (shown)="popoverIsOpen = true"
  [ngbPopover]="popoverContent"
  [placement]="popoverPlacement"
  container="body"
  popoverClass="popover-emoji"
>
  <ng-content></ng-content>
</span>
