import { CompanyService } from '../../../../../common/services/company/company.service';

export async function schedulesAutomatedReportsFeatureResolveFn(
  company: CompanyService
) {
  try {
    return await company
      .hasFeatureAccess('SCHEDULED_REPORTS')
      .then((result) => !!result);
  } catch (e) {
    console.log('resolve error: ', e);
  }
}
