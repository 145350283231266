import { Pipe, PipeTransform } from '@angular/core';
import { ColleaguesService } from '../../../../../../common/services/api/colleagues';
import { socialNetworkSettingsIterable } from '@ui-resources-angular';
import { format } from 'date-fns';
import { Filter } from '../../../reports/view/view-report.component';
import { LANGUAGES } from '../../../../../../common/constants';
import { ageOptions } from '../../../../../../common/constants/age-options';
import {
  findSentimentConst,
  sentimentsIterable
} from '../../../../../../common/constants/sentiments';
import { commonDateRangesIterable } from '../../../../../../common/constants/common-date-ranges';

@Pipe({
  name: 'formatWidgetFilter'
})
export class FormatWidgetFilterPipe implements PipeTransform {
  constructor(private colleaguesService: ColleaguesService) {}

  async transform(filter: Filter): Promise<string> {
    let filterValue;
    switch (filter.field) {
      case 'Content':
        filterValue = filter.match ? filter.match : filter.query;
        break;
      case 'Created At':
        const gte = format(filter.range.gte, 'DD/MM/YY HH:mm');
        const lte = format(filter.range.lte, 'DD/MM/YY HH:mm');
        filterValue =
          gte === 'Invalid Date'
            ? commonDateRangesIterable.find(
                (dateObj) => dateObj.startString === filter.range.gte
              ).label
            : gte + ' - ' + lte;
        break;
      case 'Is Private':
      case 'Country':
      case 'State':
      case 'City':
      case 'Locality':
        filterValue = filter.eq;
        break;
      case 'Channel':
        const nonNetworkValues = {
          moreover: 'Press & Media',
          livechat: 'Live Chat'
        };
        filterValue = filter.in
          .map((networkKey) => {
            if (nonNetworkValues.hasOwnProperty(networkKey)) {
              return nonNetworkValues[networkKey];
            }
            const networkData = socialNetworkSettingsIterable.find(
              (network) => network.publishKey === networkKey
            );
            if (networkData) {
              return networkData.accountTypeLabel;
            }
            return 'Unknown Network';
          })
          .join(' & ');
        break;
      case 'Gender':
      case 'Emotion':
      case 'Tags':
        filterValue = filter.in.join(' & ');
        break;
      case 'Sentiment':
        filterValue = filter.in
          .map((value) => findSentimentConst('numericKey', value).key3)
          .join(' & ');
        break;
      case 'Language':
        filterValue = filter.in
          .map((languageCode) => LANGUAGES[languageCode])
          .join(' & ');
        break;
      case 'Age':
        filterValue = filter.in
          .map((ageKey) => ageOptions[ageKey].label)
          .join(' & ');
        break;
      case 'Assigned User':
        const colleagues = await this.colleaguesService.getAll();
        filterValue = filter.in
          .map(
            (colleagueId) =>
              colleagues.find(
                (colleague) => colleague.id === colleagueId.toString()
              ).fullName
          )
          .join(' & ');
        break;
      default:
        console.error('filter type not supported by widget filter tooltip');
    }
    return `${filter.field}: ${filterValue}`;
  }
}
