<div class="container">
  <div class="row">
    <div class="col-md-7 col-md-offset-1">
      <div class="well well-lg">
        <form
          *ngIf="!loadingTracker.active && !accountActivated && !loadError"
          #activateForm="ngForm"
          name="activateForm"
          novalidate
          (submit)="activate()"
        >
          <h3 class="text-center">
            <span
              translate="WELCOME__NAME_"
              [translateParams]="{name: firstName}"
            ></span>
            <br>
            <span translate="CHECK_YOUR_DETAILS_AND_SET_YOUR_SOCIALSIGNIN_PASSWORD"></span>
          </h3>
          <hr>
          <div class="form-group form-group-lg">
            <div class="row">
              <div class="col-xs-6">
                <label translate="FIRST_NAME"></label>
                <input
                  type="text"
                  placeholder="First name"
                  class="form-control"
                  [(ngModel)]="firstName"
                  name="firstName"
                  readonly
                >
              </div>
              <div class="col-xs-6">
                <label translate="LAST_NAME"></label>
                <input
                  type="text"
                  placeholder="Last name"
                  class="form-control"
                  [(ngModel)]="lastName"
                  name="lastName"
                  readonly
                >
              </div>
            </div>
          </div>

          <div class="form-group form-group-lg">
            <label translate="EMAIL_ADDRESS"></label>
            <input
              type="email"
              class="form-control"
              placeholder="Enter email"
              [(ngModel)]="emailAddress"
              name="emailAddress"
              readonly
            >
          </div>

          <div class="form-group form-group-lg">
            <label translate="BUSINESS_NAME"></label>
            <input
              type="text"
              class="form-control"
              placeholder="Your company name"
              [(ngModel)]="companyName"
              name="companyName"
              readonly
            >
          </div>

          <div class="form-group form-group-lg">
            <label translate="SET_YOUR_PASSWORD"></label>
            <ssi-password-input
              placeholder="Password"
              required
              [(ngModel)]="password"
              name="password"
            ></ssi-password-input>
          </div>

          <div class="text-center">
            <button
              type="submit"
              class="btn btn-primary btn-lg"
              translate="ACTIVATE_NOW"
              [disabled]="activateForm.invalid">
            </button>
          </div>
        </form>
        <div class="loading" *ngIf="loadingTracker.active"></div>
        <div class="text-center" *ngIf="accountActivated && !loadingTracker.active">
          <h1><i class="text-success fa fa-check-circle-o fa-4x"></i></h1>
          <h3 class="text-success text-center" translate="YOUR_ACCOUNT_HAS_BEEN_ACTIVATED"></h3>
          <div class="text-center">
            <a uiSref="entry.landing.login" class="btn btn-lg btn-success">
              <span translate="GET_STARTED_NOW"></span> <i class="icon-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 hidden-xs">
      <div class="well well-lg">
        <h3>
          "Orlo has helped us to understand where we are being most effective. Seeing all our data in one place makes helps us to make the most of our content across all our channels."
        </h3>
          <p>Pete Richardson<br>Digital Manager, London Fire Brigade
          </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="text-center">
        <h1
          translate="NEED_HELP_CALL__TELEPHONE_"
          [translateParams]="{telephone: serverSettings.telephone}"
        ></h1>
      </div>
    </div>
  </div>
</div>
