import { Pipe, PipeTransform } from '@angular/core';
import { addSeconds } from 'date-fns';

@Pipe({
  name: 'addSeconds'
})
export class AddSecondsPipe implements PipeTransform {
  transform(value: Date, seconds: number): Date {
    return addSeconds(value, seconds);
  }
}
