import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateSelectorDirective } from './template-selector.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TemplateSelectorDirective],
  exports: [TemplateSelectorDirective]
})
export class TemplateSelectorModule {}
