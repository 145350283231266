import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LiveChatNetworkStateModule } from '../live-chat-network-state/live-chat-network-state.module';
import { LiveChatTeamComponent } from './live-chat-team.component';
import './live-chat-team.scss';

@NgModule({
  declarations: [LiveChatTeamComponent],

  exports: [LiveChatTeamComponent],

  imports: [CommonModule, LiveChatNetworkStateModule]
})
export class LiveChatTeamModule {}
