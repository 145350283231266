import {
  Directive,
  Input,
  HostListener,
  ElementRef,
  Renderer2,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import avatar from './img/avatar.png';
import avatarRefresh from './img/avatar-refresh-512px.png';
import avatarIncoming from './img/avatar-incoming.png';
import avatarOutgoing from './img/avatar-outgoing.png';
import brokenImage from './img/broken-image-512px.png';
import brokenImageNew from './img/broken-image-new-512px.png';
import brokenImageNew512x340 from './img/broken-image-new-512x340.png';
import activityMedia from './img/activity-media.png';
import userAvatarDefault from './img/user-avatar-default.png';
import mediaSourceAvatarDefault from './img/media-source-avatar.png';

export const imgMap = {
  avatar,
  avatarRefresh,
  avatarIncoming,
  avatarOutgoing,
  brokenImage,
  brokenImageNew,
  brokenImageNew512x340,
  activityMedia,
  userAvatarDefault,
  mediaSourceAvatarDefault
};

@Directive({
  selector: '[ssiFallbackImage]'
})
export class FallbackImageDirective implements OnChanges {
  @Input() imageSrc: string; // can't watch attributes

  @Input('ssiFallbackImage') fallbackSrc: string;

  @Output() fallbackImageSet = new EventEmitter<void>();

  constructor(private elm: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imageSrc) {
      if (!this.imageSrc) {
        this.setFallbackSrc();
      } else {
        this.renderer.setAttribute(
          this.elm.nativeElement,
          'src',
          this.imageSrc
        );
      }
    }
  }

  @HostListener('error')
  onError(): void {
    this.setFallbackSrc();
  }

  private setFallbackSrc(): void {
    const fallbackSrc = imgMap[this.fallbackSrc] || this.fallbackSrc;
    this.renderer.setAttribute(this.elm.nativeElement, 'src', fallbackSrc);
    this.fallbackImageSet.emit();
  }
}
