import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitySnippetsDirective } from './activity-snippets.directive';
import { ActivitySnippetsDropdownComponent } from './activity-snippets-dropdown/activity-snippets-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { OffClickModule } from '../off-click/off-click.module';
import { SortByModule } from '../../pipes/sort-by/sort-by.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule,
    FormsModule,
    OffClickModule,
    SortByModule
  ],
  declarations: [ActivitySnippetsDirective, ActivitySnippetsDropdownComponent],
  entryComponents: [ActivitySnippetsDropdownComponent],
  exports: [ActivitySnippetsDirective]
})
export class ActivitySnippetsModule {}
