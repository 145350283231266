import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TransformOptions } from 'filestack-js';
import {
  UploadOptions,
  StoreUploadOptions
} from 'filestack-js/build/main/lib/api/upload';
import { Client, Security } from 'filestack-js/build/main/lib/client';
import { PickerOptions } from 'filestack-js/build/main/lib/picker';
import { StoreParams } from 'filestack-js/build/main/lib/filelink';
import {
  filestackApiKey,
  MediaCategory,
  OutboxPublisher
} from '@ui-resources-angular';
import { CompanyService } from '../../../common/services/company/company.service';
import { ApiService } from '../api';

interface FilestackSecurity {
  policy?: string;
  signature?: string;
}

export interface FilestackFile {
  url: string;
  filename: string;
  handle: string;
  size: number;
  originalFile?: File;
  type?: string;
  mimetype?: string;
  mediaCategory?: MediaCategory;
  originalPath?: string;
  source?: string;
  container?: string;
  key?: string;
  status?: string;
}

export enum FilestackSources {
  LocalFileSystem = 'local_file_system',
  Url = 'url',
  ImageSearch = 'imagesearch',
  Facebook = 'facebook',
  Googledrive = 'googledrive',
  Dropbox = 'dropbox',
  Webcam = 'webcam',
  Evernote = 'evernote',
  Box = 'box',
  Github = 'github',
  Gmail = 'gmail',
  Picasa = 'picasa',
  Onedrive = 'onedrive',
  Clouddrive = 'clouddrive',
  CustomSource = 'customsource'
}

export const CUSTOM_SOURCE_NAME = 'Content Library';
export const FILESTACK_CDN_URL = 'https://cdn.uploads.orlo.app';
export const FILESTACK_CONTENT_CDN_URL = 'https://cdn.filestackcontent.com';
export const FILESTACK_API_URL = 'https://api.uploads.orlo.app';

export const GIF_SOURCES = [
  FilestackSources.LocalFileSystem,
  FilestackSources.CustomSource,
  FilestackSources.Dropbox,
  FilestackSources.Url,
  FilestackSources.Googledrive
];

export const IMAGE_SOURCES = [
  FilestackSources.LocalFileSystem,
  FilestackSources.CustomSource,
  FilestackSources.ImageSearch,
  FilestackSources.Dropbox,
  FilestackSources.Url,
  FilestackSources.Webcam,
  FilestackSources.Googledrive
];

export const ALL_FILE_SOURCES = [
  FilestackSources.LocalFileSystem,
  FilestackSources.CustomSource,
  FilestackSources.Dropbox,
  FilestackSources.Url
];

export const MAX_FILE_SIZE_DEFAULT = 3 * 1024 * 1024;

enum FilestackFileTypes {
  Image = 'image/*',
  Video = 'video/*',
  Audio = 'audio/*',
  Application = 'application/*',
  Text = 'text/*'
}

export enum FilestackStorageLocations {
  S3 = 's3',
  Google = 'gcs',
  Rackspace = 'rackspace',
  Azure = 'azure',
  Dropbox = 'dropbox'
}

export enum FilestackAccess {
  Private = 'private',
  Public = 'public'
}

export interface FilestackPickOptions extends PickerOptions {
  fromSources?: FilestackSources[];
  accept?: Array<FilestackFileTypes | string> | FilestackFileTypes | string;
  storeTo?: {
    location?: FilestackStorageLocations;
    access?: FilestackAccess;
  };
}

interface FilestackStoreOptions extends StoreUploadOptions {
  location?: FilestackStorageLocations;
  access?: FilestackAccess;
}

enum FilestackColorspace {
  Rgb = 'rgb',
  Cymk = 'cymk',
  Input = 'input'
}

enum FilestackPageFormat {
  A3 = 'a3',
  A4 = 'a4',
  A5 = 'a5',
  B4 = 'b4',
  b5 = 'b5',
  Letter = 'letter',
  Legal = 'legal',
  Tabloid = 'tabloid'
}

enum FilestackPageOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait'
}

interface FilestackTransformOptions extends TransformOptions {
  roundedCorners?: {
    radius?: number | string;
    blur?: number;
    background?: string;
  };
  output?: {
    format: string;
    input?: boolean;
    strip?: boolean;
    colorspace?: FilestackColorspace;
    pageformat?: FilestackPageFormat;
    pageorientation?: FilestackPageOrientation;
  };
}

interface FilestackUploadToken {
  cancel?();
  pause?();
  resume?();
}

export interface FilestackClient extends Client {
  getSecurity(): FilestackSecurity;
  setSecurity(security: FilestackSecurity);
  storeURL(
    url: string,
    options?: StoreParams,
    token?: FilestackUploadToken,
    security?: Security
  ): Promise<FilestackFile>;
  transform(url: string, options?: FilestackTransformOptions): string;
  upload(
    file: File | Blob | string,
    options?: UploadOptions,
    storeOptions?: FilestackStoreOptions,
    token?: FilestackUploadToken,
    security?: Security
  );
}

interface Filestack {
  version: string;
  init(apiKey: string, clientOptions);
}

export function fileTypeNotRecognized(fsFile: FilestackFile): boolean {
  // Some browsers seemingly don't recognize file type set by Windows for CSV files so it ends up being empty string
  if (fsFile.originalFile) {
    return !fsFile.originalFile.type;
  }

  const type = fsFile.mimetype || fsFile.type;
  return !type || type === 'application/octet-stream';
}

export function isCsvFile(fsFile: FilestackFile): boolean {
  const type = fsFile.mimetype || fsFile.type;
  return type === 'text/csv';
}

@Injectable()
export class FilestackService {
  constructor(
    protected http: HttpClient,
    protected api: ApiService,
    protected company: CompanyService
  ) {}

  async getClient(
    apiKey: string = filestackApiKey,
    security?: FilestackSecurity
  ): Promise<FilestackClient> {
    const enableCname = await this.company
      .hasFeatureAccess('FILESTACK_CNAME')
      .then((result) => !!result);
    return import('filestack-js').then((module) => {
      const filestack: Filestack = module;
      const clientOptions: any = {};
      if (enableCname) {
        Object.assign(clientOptions, { cname: 'uploads.orlo.app' });
      }
      if (!!security) {
        Object.assign(clientOptions, { security: { ...security } });
      }
      const client = filestack.init(apiKey, clientOptions);
      // patch storeUrl until https://github.com/filestack/filestack-js/issues/56 is resolved
      const { storeURL } = client;
      client.storeURL = async (...args) => {
        const file = await storeURL.apply(client, args);
        file.mimetype = file.type;
        return file;
      };
      return client;
    });
  }

  resizeImage(
    url: string,
    width: number,
    height: number,
    fit: 'clip' | 'crop' | 'scale' | 'max' = 'clip'
  ): Promise<FilestackFile> {
    console.log('Resizing image...');

    // By default (fit:clip) filestack will resize the image without distorting, cropping, or changing the aspect ratio,
    // see: https://www.filestack.com/docs/api/processing/#resize and https://blog.filestack.com/api/resize-fit-and-align-images-with-filestack-and-react/
    const reqUrl = `${FILESTACK_CDN_URL}/${filestackApiKey}/resize=width:${width},height:${height},fit:${fit}/store/${url}`;

    return this.http
      .get(reqUrl)
      .pipe(
        map((response: FilestackFile) => {
          console.log('Image after resizing: ', response);
          return response;
        })
      )
      .toPromise();
  }

  compressImage(url: string): Promise<FilestackFile> {
    console.log('Compressing image...');

    // https://www.filestack.com/docs/api/processing/#compress
    const reqUrl = `${FILESTACK_CDN_URL}/${filestackApiKey}/compress/store/${url}`;

    return this.http
      .get(reqUrl)
      .pipe(
        map((response: FilestackFile) => {
          console.log('Image after compressing: ', response);
          return response;
        })
      )
      .toPromise();
  }

  reduceImageQuality(
    url: string,
    quality = 80 // 1 - 100
  ): Promise<FilestackFile> {
    console.log('Reducing image quality... ', quality);

    // https://www.filestack.com/docs/api/processing/#quality
    const reqUrl = `${FILESTACK_CDN_URL}/${filestackApiKey}/quality=value:${quality}/store/${url}`;

    return this.http
      .get(reqUrl)
      .pipe(
        map((response: FilestackFile) => {
          console.log('Image after quality reducing: ', response);
          return response;
        })
      )
      .toPromise();
  }

  convertImageToJpg(url: string): Promise<FilestackFile> {
    console.log('Converting to JPEG...');

    const reqUrl = `${FILESTACK_CDN_URL}/${filestackApiKey}/output=format:jpg/store/${url}`;

    return this.http
      .get(reqUrl)
      .pipe(
        map((response: FilestackFile) => {
          console.log('Image after converting to JPEG: ', response);
          return response;
        })
      )
      .toPromise();
  }

  getSecuritySignature(): Promise<{ policy: string; signature: string }> {
    // valid for 60s only

    const endpoint = `${this.api.url}/company/filestackSecurityPolicy`;

    return this.api
      .get(endpoint)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .toPromise();
  }

  async deleteFile(
    file: FilestackFile | any,
    newFile?: FilestackFile | any // pass for additional safety check that the right file is attempted to be deleted
  ): Promise<any> {
    // If it's not a Filestack CDN url it means that the file is stored to S3 (e.g. scheduled, drafted, or user is trying to copy/edit a post), or it already went live (published, replied, etc.)
    const storedLocallyOrPublished =
      file &&
      file.url &&
      file.url.indexOf(FILESTACK_CDN_URL) !== 0 &&
      file.url.indexOf(FILESTACK_CONTENT_CDN_URL) !== 0;

    if (!file || !file.handle || !file.url || storedLocallyOrPublished) {
      console.error(
        `Cannot delete fs file, handle or url missing, or file already stored locally / went live (not a filestack CDN url): ${JSON.stringify(
          file
        )}`
      );
      return;
    }

    if (newFile && (!newFile.handle || newFile.handle === file.handle)) {
      // handle isn't updated correctly after resizing, compressing, editing, etc.?
      console.error(
        `Something went wrong, attempting to delete wrong file?: ${JSON.stringify(
          file
        )}`
      );
      return;
    }

    const reqUrl = `${FILESTACK_API_URL}/api/file/${file.handle}`;
    const security = await this.getSecuritySignature();
    const opts: any = {
      responseType: 'text',
      params: {
        key: filestackApiKey,
        policy: security.policy,
        signature: security.signature
      }
    };

    return this.http
      .delete(reqUrl, opts)
      .pipe(
        map((response: any) => {
          console.error(`File deleted: ${JSON.stringify(file)}`); // trackjs
          return response;
        }),
        catchError((e) => {
          console.error(`Error deleting fs file: ${file}, error: ${e}`);
          // do not throw error further (don't interrupt upload process)
          return EMPTY;
        })
      )
      .toPromise();
  }

  getFileMetadata(
    url: string,
    file?: FilestackFile | any
  ): Promise<FilestackFile> {
    const handle = (file && file.handle) || this.getHandleFromUrl(url);
    if (!handle) {
      console.error(`Cannot get file metadata, handle missing: ${url}`);
      return;
    }

    const reqUrl = `${FILESTACK_API_URL}/api/file/${handle}/metadata`;

    return this.http
      .get(reqUrl)
      .pipe(
        map((response: FilestackFile | any) => {
          // returns: {
          //   filename: '1636126820921.jpg';
          //   mimetype: 'image/jpeg';
          //   size: 557918;
          //   uploaded: 1673550529019.805;
          //   writeable: true;
          // }

          // add missing props
          response.url = url;
          response.handle = handle;
          if (file) {
            response.source = file.source;
            response.originalPath = file.originalPath;
          }

          if (!response.type && response.mimetype) {
            OutboxPublisher.setFileType(response);
          }

          console.log('File metadata: ', response);
          return response;
        }),
        catchError((e) => {
          console.error(`Error getting file metadata: ${e}`);
          return throwError(e);
        })
      )
      .toPromise();
  }

  getHandleFromUrl(filestackURL: string): string {
    // expecting url to be e.g.: https://cdn.uploads.orlo.app/NVGlTsekS7G1gkZPJEfH or https://cdn.filestackcontent.com/AomvUh0CQUqrheejOVjs
    if (
      typeof filestackURL !== 'string' ||
      (filestackURL.indexOf(`${FILESTACK_CDN_URL}/`) !== 0 &&
        filestackURL.indexOf(`${FILESTACK_CONTENT_CDN_URL}/`) !== 0)
    ) {
      console.error(`Cannot get handle from URL: ${filestackURL}`);
      return '';
    }

    const handle =
      filestackURL.indexOf(`${FILESTACK_CDN_URL}/`) === 0
        ? filestackURL.split(`${FILESTACK_CDN_URL}/`)[1]
        : filestackURL.split(`${FILESTACK_CONTENT_CDN_URL}/`)[1];

    if (!handle || handle.indexOf('/') > -1 || handle.indexOf('?') > -1) {
      console.error(`Cannot get handle from URL: ${filestackURL}`);
      return '';
    }

    return handle;
  }
}
