import './dropdown-select.component.scss';
import {
  Component,
  Input,
  TemplateRef,
  OnChanges,
  forwardRef,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideIn, slideOut } from '../../animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

export const DROPDOWN_SELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownSelectComponent), //tslint:disable-line
  multi: true
};

export type DropdownItemsType = Array<{ option: any; isSelected: boolean }>;

@Component({
  selector: 'ssi-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  animations: [
    trigger('slideInOut', [
      transition(
        'void => *',
        useAnimation(slideIn, { params: { duration: '100ms' } })
      ),
      transition(
        '* => void',
        useAnimation(slideOut, { params: { duration: '100ms' } })
      )
    ])
  ],
  providers: [DROPDOWN_SELECT_CONTROL_VALUE_ACCESSOR],
  host: {
    // tslint:disable-line
    '[class.dd-active]': 'dropdownIsVisible'
  }
})
export class DropdownSelectComponent
  implements OnChanges, ControlValueAccessor {
  @ViewChild('searchTextInput') public searchTextInput: ElementRef;
  @Input() options: any[];
  @Input() optionTemplate: TemplateRef<any>;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() searchable = false;
  @Input() toggleAll = false;
  @Input() multiple = false;
  @Input() theme: string;
  @Input() searchPlaceholder = '';
  @Input() disabled = false;
  @Input() widthExtended = false;
  @Input() dropdownIsVisible = false;
  @Input() closeOnSelection = true;

  dropdownItems: DropdownItemsType;
  modelValue;
  searchText = '';
  searchId = uuidv4();

  private onChangeCallback: (_: any) => void = () => {};

  private onTouchCallback: () => void = () => {};

  constructor() {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    event.preventDefault();
    this.hideDropdown();
  }

  ngOnChanges(changes) {
    if (changes.options) {
      this.updateSelectedItems();
    }
  }

  showDropdown() {
    this.dropdownIsVisible = true;
  }

  hideDropdown() {
    this.dropdownIsVisible = false;
    this.onTouchCallback();
  }

  writeValue(value: any): void {
    this.modelValue = value;
    this.updateSelectedItems();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  toggleItem(item) {
    if (this.multiple) {
      if (item.isSelected) {
        this.modelValue = this.modelValue.filter(
          (option) => option !== item.option
        );
      } else {
        this.modelValue = [...this.modelValue, item.option];
      }
    } else {
      if (item.isSelected) {
        this.modelValue = null;
      } else {
        this.modelValue = item.option;
      }
      if (this.closeOnSelection) {
        this.hideDropdown();
      }
    }
    this.onChangeCallback(this.modelValue);
    this.updateSelectedItems();
    this.searchTextInput.nativeElement.focus();
  }

  updateSelectedItems() {
    this.dropdownItems = this.options
      .map((option) => {
        let isSelected = false;

        if (this.modelValue) {
          if (this.multiple) {
            isSelected = this.modelValue.includes(option);
          } else {
            isSelected = this.modelValue === option;
          }
        }

        return {
          option,
          isSelected
        };
      })
      .filter(({ option }) => {
        return Object.values(option).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(this.searchText.toLowerCase())
        );
      });
  }

  setModelValue(value: DropdownItemsType) {
    this.modelValue = [...value.map((item) => item.option)];
    this.onChangeCallback(this.modelValue);
    this.updateSelectedItems();
  }
}
