import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService } from '../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { Activity, User, UserModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-inline-notes',
  templateUrl: './inline-notes.component.html',
  providers: []
})
export class InlineNotesComponent implements OnInit {
  @Input() activity: Activity;
  @Input() isAddNoteButtonVisible = true;
  @Input() isExpanded = false;
  @Input() alignRight = true;
  @Output() closeNotes = new EventEmitter();

  user: User;

  private _isAddNoteFormVisible = false;

  constructor(
    private translate: TranslateService,
    private popup: PopupService,
    private userModel: UserModel
  ) {
    'ngInject';
  }

  async ngOnInit() {
    await this.userModel.getAuthUser().then((user) => {
      this.user = user;
    });
  }

  get isAddNoteFormVisible(): boolean {
    return !!this._isAddNoteFormVisible;
  }

  get shouldShowNoteForm() {
    if (this.isAddNoteButtonVisible && !this.isAddNoteFormVisible) {
      return;
    }

    return true;
  }

  deleteNote(note) {
    return this.popup
      .confirm({
        title: this.translate.instant('DELETE_NOTE'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_NOTE'
        )
      })
      .then((shouldDelete) => {
        if (shouldDelete) {
          return this.activity.deleteNote(note);
        }
      });
  }

  async submitNewNote(note) {
    await this.activity.addNote(note);
    this.toggleAddNoteFormVisibility();
  }

  toggleAddNoteFormVisibility(): void {
    this._isAddNoteFormVisible = !this._isAddNoteFormVisible;
  }
}
