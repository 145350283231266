import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetSwotComponent } from './widget-swot.component';
import { HighchartModule } from '../../../../../../common/components/highchart/highchart.module';
import { TypeofModule } from '../../../../../../common/pipes/type-of/type-of.module';

@NgModule({
  declarations: [WidgetSwotComponent],
  imports: [CommonModule, HighchartModule, TypeofModule],
  exports: [WidgetSwotComponent]
})
export class WidgetSwotModule {}
