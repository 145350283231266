import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuComponent } from './menu.component';
import { TemplateSelectorModule } from '../../directives/template-selector/template-selector.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';

@NgModule({
  declarations: [MenuComponent],
  imports: [CommonModule, TemplateSelectorModule, OffClickModule],
  exports: [MenuComponent, TemplateSelectorModule],
  entryComponents: [MenuComponent]
})
export class MenuModule {}
