<ng-container
  *ngIf="!! inboxQuery"
>

  <div
    class="inbox-no-results"
    *ngIf="inboxQuery.result.totalLoaded == 0 && !inboxQuery.loading.more.active">
    <ng-container *ngIf="inboxMode === 'activity'">
      <i class="ssi ssi-new-features"></i>
      <h3 translate="OH_DEAR_NO_RESULTS"></h3>
      <h6 translate="TRY_WIDENING_YOUR_FILTERS"></h6>
    </ng-container>
    <ng-container *ngIf="inboxMode === 'conversation'">
      <ng-container *ngIf="isSocialPushMode; else classicMode">
        <ng-container *ngIf="activeList; else onHoldList">
          <i class="ssi ssi-active-empty-state"></i>
          <h3 translate="ALL_DONE"></h3>
          <h6 translate="YOU_CURRENTLY_HAVE_NO_CONVERSATIONS_PUSHED_TO_YOU"></h6>
        </ng-container>
        <ng-template #onHoldList>
          <i class="ssi ssi-on-hold-empty-state"></i>
          <h3 translate="ALL_CLEAR"></h3>
          <h6 translate="THERE_ARE_CURRENTLY_NO_CONVERSATIONS_ON_HOLD"></h6>
        </ng-template>
      </ng-container>
      <ng-template #classicMode>
        <i class="ssi ssi-new-features"></i>
        <h3 translate="NO_MESSAGES_RIGHT_NOW"></h3>
        <h6 translate="CHOOSE_ANOTHER_CONVERSATION_OR_CHECK_YOUR_FILTERS"></h6>
      </ng-template>

    </ng-container>
  </div>

  <ng-template #assignTypeaheadTemplate let-result="result" let-term="term">
    <a>
      <i class="fa fa-fw" [class.fa-user]="!!result.fullName" [class.fa-users]="!result.fullName"></i>
      <ngb-highlight [result]="result.fullName || result.name" [term]="term"></ngb-highlight>
    </a>
  </ng-template>

  <div
    *ngIf="inboxQuery.result.totalLoaded > 0"
    class="inbox-query-result-list"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    [infiniteScrollDisabled]="inboxQuery.result.totalLoaded === inboxQuery.result.total"
    [scrollWindow]="false"
    (scrolled)="inboxQuery.loadNextPage()"
    ssiVirtualScroll
    [items]="inboxQuery.result.list"
    [bufferAmount]="4"
    [getItemHeight]="getHeight"
    (viewPortItemsUpdated)="viewPortItemsUpdated($event)"
  >
    <div
      *ngFor="let item of viewPortItems; trackBy: trackByUid; let last = last; let index = index;"
    >
      <div
        *ngIf="item.type === InboxQueryResultListItemType.Divider"
        class="inbox-query-result-list-divider"
        [class.conversation-mode]="isConversationThreadActive"
      >
        <div class="divider-label">
          {{ item.divider.label }}
        </div>
      </div>

      <div
        *ngIf="(item.type === InboxQueryResultListItemType.Activity || item.type === InboxQueryResultListItemType.Conversation) && item.result.account"
      >
        <div
          [class.inbox-query-result-list-item-selected]="inboxQuery.activeResult.value === item"
          [class.inbox-query-result-list-item-multi-selected]="multiSelectedResultIds[item.uid]"
          (mouseover)="setItemExpanded(item, true);"
          (mouseout)="setItemExpanded(item, false);"
          [ngClass]="{
            'expanded': item.expanded,
            'inbox-query-result-list-item-conversation-thread': isItemAConversationThread(item)
          }"
          class="inbox-query-result-list-item inbox-query-result-list-item-{{item.result.status}}"
        >
          <div
            *ngIf="!isItemAMidActionConversation(item)"
            (click)="toggleMultiSelectedResult(item, $event)"
            [class.mouse-pointer]="canMultiSelectResults"
            [class.shadow-on-hover]="canMultiSelectResults"
            title="{{(canMultiSelectResults ? 'CLICK_TO_BULK_ACTION' : '') | translate}}"
            class="inbox-query-result-list-item-icon"
          >
            <ssi-social-network-icon [size]="20"
                                     [accountTypeId]="item.result.account.account_type_id"></ssi-social-network-icon>
          </div>

          <div
            *ngIf="isItemAMidActionConversation(item)"
            class="inbox-query-result-list-item-icon spinner"
          >
            <i
              class="ssi ssi-loading"
            ></i>
          </div>

          <button
            *ngIf="item.type === InboxQueryResultListItemType.Activity"
            (click)="toggleActivityStatus(item.result.activity)"
            title="{{item.result.activity.statusText | uppercase | translate}}"
            class="inbox-query-result-list-item-status"
          ></button>

          <button
            *ngIf="item.type === InboxQueryResultListItemType.Conversation"
            (click)="toggleConversationStatus(item.result.conversation)"
            [disabled]="isItemAMidActionConversation(item)"
            [ngClass]="{ active: isItemAMidActionConversation(item) }"
            title="{{item.result.conversation.status | uppercase | translate}}"
            class="inbox-query-result-list-item-status"
          ></button>

          <div class="inbox-query-result-list-item-avatar-text-media-container">
            <div class="inbox-query-result-list-item-avatar-text-container">
              <div
                (click)="toggleMultiSelectedResult(item, $event)"
                [class.mouse-pointer]="canMultiSelectResults"
                [class.public-conversation]="item.type === InboxQueryResultListItemType.Conversation && item.result.visibility === ConversationVisibility.Public"
                class="inbox-query-result-list-item-avatar-container"
                >
                <div class="inbox-query-result-list-item-avatar-wrapper"
                    [class.P1]="item.result.activity?.inbox.priority === '1' && item.type !== InboxQueryResultListItemType.Conversation">
                  <img
                    *ngIf="!multiSelectedResultIds[item.uid]"
                    [imageSrc]="item.result.author.avatar"
                    class="inbox-query-result-list-item-avatar"
                    ssiFallbackImage="avatarOutgoing"
                  >
                  <img
                    *ngIf="multiSelectedResultIds[item.uid]"
                    class="inbox-query-result-list-item-avatar"
                    src="https://www.orlo.app/app/web/production/8e65724b377cd75321c729a33b19ee4e.png"
                  >
                  <img
                    *ngIf="item.type === InboxQueryResultListItemType.Conversation && item.result.visibility === ConversationVisibility.Public"
                    class="inbox-query-result-list-item-avatar multi-users-img"
                    src="https://www.orlo.app/app/web/production/a7a4f1b92e9fae4a3ee8c11247750635.png"
                  >
                  <ssi-inbox-priority-indicator
                    [priority]="item.result.activity?.inbox.priority || item.result.conversation?.priority"
                    [type]="'badge'"
                    class="priority-badge"
                  ></ssi-inbox-priority-indicator>
                </div>
              </div>

              <div
                *ngIf="isItemAMidActionConversation(item)"
                class="inbox-query-result-list-item-text-container mid-action-conversation"
              >
                <p>
                  <strong
                    translate="ONE_MOMENT_ELLIPSIS"
                  ></strong>

                  <em>
                    We're actioning {{ getTotalMessageCountForConversation(item) }} messages for you
                  </em>
                </p>
              </div>

              <div
                *ngIf="!isItemAMidActionConversation(item)"
                (click)="itemClicked(item)"
                class="inbox-query-result-list-item-text-container mouse-pointer"
              >
                <div class="inbox-query-result-list-item-from-to">
                  <div class="inbox-query-result-list-item-name">
                    <span *ngIf="item.type === InboxQueryResultListItemType.Activity">
                      {{ item.result.author.name }}
                      <i
                        *ngIf="item.result.author.verified"
                        class="verified ssi ssi-verified-icon"
                      ></i>
                    </span>
                    <span *ngIf="!item.result.author.name && ConversationVisibility.Public && item.result.account.account_type_id == 3">Personal Profile</span>

                    <span
                      *ngIf="item.type === InboxQueryResultListItemType.Conversation && item.result.visibility === ConversationVisibility.Private"
                      [translateParams]="{name: item.result.author.name}"
                      translate="YOU___NAME_"
                    ></span>

                    <span
                      *ngIf="item.type === InboxQueryResultListItemType.Conversation && item.result.visibility === ConversationVisibility.Public"
                      translate="YOU__OTHERS">
                    </span>
                  </div>

                  <div class="inbox-query-result-list-item-username">
                    {{ item.result.account.username }}

                    <div
                      *ngIf="item.result.activity?.properties.paid_post"
                      class="inbox-query-result-list-item-badge"
                    >
                      <i class="ssi ssi-ad-post"></i>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="item.result.text && item.result.text !== '[Redacted]'"
                  [ssiEmojiText]="item.result.text"
                  class="inbox-query-result-list-item-content"
                ></div>

                <div
                  *ngIf="!item.result.text || item.result.text === '[Redacted]'"
                  class="inbox-query-result-list-item-content"
                >
                  <em translate="NO_MESSAGE_TEXT"></em>
                </div>

                <div class="inbox-query-result-list-item-info">
                  <span class="left">
                    <span class="inbox-query-result-list-item-info-count">
                      <i class="ssi ssi-all-replies-lc"></i>

                      {{ item.result.responseCount }}
                    </span>

                    <span class="inbox-query-result-list-item-info-date">
                      {{ item.result.date | date:'d MMM y' }}
                    </span>

                    <span class="inbox-query-result-list-item-info-time">
                      {{ item.result.date | date:'HH:mm' }}
                    </span>
                  </span>

                  <span class="pull-right">
                    <span
                      class="inbox-query-result-list-item-visibility"
                      *ngIf="item.result.visibility === ConversationVisibility.Private">
                      <span translate="PRIVATE"></span> <i class="ssi ssi-private-correct"></i>
                    </span>
                    <span
                      class="inbox-query-result-list-item-visibility"
                      *ngIf="item.result.visibility === ConversationVisibility.Public">
                      <span translate="PUBLIC"></span> <i class="ssi ssi-public-correct"></i>
                    </span>
                    <span
                      *ngIf="item.result.activity?.interaction.object_type === 'review'"
                      class="inbox-query-result-list-item-source"
                    >
                      <span
                        class="inbox-query-result-list-item-source-icon"
                        [style.background-image]="'url(' + item.result.activity.interaction.source_icon + ')'"
                      ></span>
                    </span>
                  </span>
                </div>

                <ul
                  *ngIf="item.result.conversation"
                  class="inbox-query-result-list-item-conversation-statistics"
                >
                  <li
                    class="conversation-statistic unread"
                  >
                    <span
                      class="statistic-value"
                    >
                      {{ item.result.conversation.count_unread }}
                    </span>

                    <span
                      class="statistic-label"
                      translate="UNREAD"
                    ></span>
                  </li>

                  <li
                    class="conversation-statistic unactioned"
                  >
                    <span
                      class="statistic-value"
                    >
                      {{ item.result.conversation.count_unactioned }}
                    </span>

                    <span
                      class="statistic-label"
                      translate="READ"
                    ></span>
                  </li>

                  <li
                    class="conversation-statistic actioned"
                  >
                    <span
                      class="statistic-value"
                    >
                      {{ item.result.conversation.count_actioned }}
                    </span>

                    <span
                      class="statistic-label"
                      translate="ACTIONED"
                    ></span>
                  </li>
                </ul>
              </div>

              <ul
                *ngIf="isAssignToolVisibleForItem(item)"
                class="inbox-query-result-list-item-toolbar"
                [class.inbox-query-result-list-item-toolbar-visible]="(quickActionsMenu.assign.active && quickActionsMenu.assign.active.item === item) || (quickActionsMenu.sentiment.active && quickActionsMenu.sentiment.active.item === item)"
              >
                <li
                  *ngIf="item.type === InboxQueryResultListItemType.Activity && item.result.account.account_type_id !== '6'"
                  class="inbox-query-result-list-item-toolbar-item influence"
                  [ssiTooltip]="'INFLUENCE_SCORE' | translate"
                  tooltipPlacement="right"
                  tooltipWindowClass="tooltip-chunky"
                  [tooltipAppendToBody]="true"
                >
                  <i class="ssi ssi-starred"></i>

                  <span class="inbox-query-result-list-item-toolbar-item-text">{{ item.result.author.influence || '-' }}</span>
                </li>

                <li
                  *ngIf="item.type === InboxQueryResultListItemType.Activity"
                  class="inbox-query-result-list-item-toolbar-item assigned-to"
                  [class.assigned-to-open]="quickActionsMenu.assign.active && quickActionsMenu.assign.active.item === item"
                  [class.assigned-to-active]="!!(item.result.activity.assignedToUser || item.result.activity.assignedToTeam)"
                  (click)="startAssignTo(item)"
                  #assignToTrigger
                  [ssiTooltip]="((item.result.activity.assignedToUser || item.result.activity.assignedToTeam) ? 'ASSIGNED_TO__NAME_' : 'NOT_ASSIGNED') | translate:{name: item.result.activity?.assignedToUser?.fullName || item.result.activity?.assignedToTeam?.name}"
                  tooltipPlacement="right"
                  tooltipWindowClass="tooltip-chunky"
                  [tooltipAppendToBody]="true"
                >
                  <i class="ssi ssi-assigned assigned" *ngIf="item.result.activity.assignedToUser || item.result.activity.assignedToTeam"></i>
                  <i class="ssi ssi-assign-correct assign" *ngIf="!(item.result.activity.assignedToUser || item.result.activity.assignedToTeam)"></i>
                </li>

                <li
                  *ngIf="item.type === InboxQueryResultListItemType.Activity && !item.result.activity.account?.isYoutube()"
                  class="inbox-query-result-list-item-toolbar-item sentiment"
                  [ngClass]="item.result.activity.sentiment ? sentimentConfigObject[item.result.activity.sentiment.key].colorClassNew : 'no-sentiment'"
                  (click)="toggleSentimentMenu(item)"
                  #changeSentimentTrigger
                  [ssiTooltip]="'CHANGE_SENTIMENT' | translate"
                  tooltipPlacement="right"
                  tooltipWindowClass="tooltip-chunky"
                  [tooltipAppendToBody]="true"
                >
                  <i [ngClass]="item.result.activity.sentiment ? sentimentConfigObject[item.result.activity.sentiment.key].iconClassNew : 'ssi ssi-amend-sentiment-new'"></i>
                </li>

                <li
                  *ngIf="item.type === InboxQueryResultListItemType.Conversation"
                  class="inbox-query-result-list-item-toolbar-item assigned-to conversation-assigned-to"
                  [class.assigned-to-open]="quickActionsMenu.assign.active && quickActionsMenu.assign.active.item === item"
                  [class.assigned-to-active]="!!(item.result.conversation.assignedToUser || item.result.conversation.assignedToTeam)"
                  (click)="startAssignTo(item)"
                  #assignToTrigger
                  [ngbPopover]="((item.result.conversation.assignedToUser || item.result.conversation.assignedToTeam) ? 'ASSIGNED_TO__NAME_' : 'NOT_ASSIGNED') | translate:{name: item.result.conversation?.assignedToUser?.fullName || item.result.conversation?.assignedToTeam?.name}"
                  triggers="mouseenter mouseleave"
                  container="body"
                  popoverClass="popover-dark"
                  placement="right"
                >
                  <i class="ssi ssi-oval-user-icon"></i>
                </li>
              </ul>
            </div>

            <div
              *ngIf="item.result.media.total > 0"
              class="inbox-query-result-list-item-media-container"
            >
              <div
                *ngIf="item.result.media.image.length"
                class="inbox-query-result-list-item-media"
              >
                <div>
                  <img
                    [imageSrc]="item.result.media.image[0].size.full"
                    class="img-responsive"
                    ssiFallbackImage="activityMedia"
                    [ssiBlurImage]="item.result.activity.hasSensitiveContent && !item.result.activity.sensitiveContentRevealed"
                    (unblur)="onMediaRevealed(item.result.activity);"
                  >

                  <div
                    [ssiEnlargeImage]="item.result.media.image[0].size.full"
                    [enlargeImageDisabled]="item.result.activity.hasSensitiveContent && !item.result.activity.sensitiveContentRevealed"
                    class="inbox-query-result-list-item-media-overlay"
                  ></div>

                  <i
                    class="ssi ssi-image inbox-query-result-list-item-media-icon"
                  ></i>

                  <i
                    *ngIf="item.result.media.image.length - 1 > 0"
                    class="additional inbox-query-result-list-item-media-icon"
                  >
                    + {{ item.result.media.image.length - 1 }}
                  </i>
                </div>
              </div>

              <div
                *ngIf="item.result.media.video.length"
                class="inbox-query-result-list-item-media"
              >
                <a
                  [href]="item.result.media.video[0].link"
                  target="_blank"
                >
                  <img
                  [imageSrc]="item.result.media.video[0].size?.full"
                    class="img-responsive"
                    ssiFallbackImage="activityMedia"

                  >

                  <div class="inbox-query-result-list-item-media-overlay"></div>

                  <i class="ssi ssi-video-circle inbox-query-result-list-item-media-icon"></i>

                  <i
                    *ngIf="item.result.media.video.length - 1 > 0"
                    class="additional inbox-query-result-list-item-media-icon"
                  >
                    + {{ item.result.media.video.length - 1 }}
                  </i>
                </a>
              </div>
            </div>

            <div
              class="inbox-query-result-list-item-change-sentiment"
              *ngIf="quickActionsMenu.sentiment.active && quickActionsMenu.sentiment.active.item === item"
              (ssiOffClick)="quickActionsMenu.sentiment.active = null"
              [offClickIgnore]="changeSentimentTrigger"
            >
              <div
                class="sentiment"
                *ngFor="let sentiment of sentimentConfigArray; trackBy:trackByIndex"
                (click)="saveSentiment(item, sentiment.config.inbox.value)"
                [ngClass]="sentiment.config.colorClassNew"
              >
                <i
                  [ngClass]="sentiment.config.iconClassNew"
                ></i>
              </div>
            </div>

            <div
              *ngIf="quickActionsMenu.assign.active && quickActionsMenu.assign.active.item === item"
              (ssiOffClick)="quickActionsMenu.assign.active = null"
              [offClickIgnore]="assignToTrigger"
              class="inbox-query-result-list-item-assign-to"
            >
              <button
                *ngIf="isAssignToolVisibleForItem(item) && quickActionsMenu.assign.active.assignedToUserOrTeam && (!item.result.activity.assignedToUser && !item.result.activity.assignedToTeam)"
                (click)="saveAssignTo(item, undefined)"
                class="remove-button"
              >
                <i class="fa fa-times"></i>
              </button>

              <div
                *ngIf="isAssignToolVisibleForItem(item) && (!item.result.activity.assignedToUser && !item.result.activity.assignedToTeam)"
                [ngClass]="{ 'with-button': quickActionsMenu.assign.active.assignedToUserOrTeam }"
                class="input-container"
              >
                <input
                  class="form-control"
                  placeholder="{{ 'SEARCH_NAME__EMAIL' | translate }}"
                  type="text"
                  [(ngModel)]="quickActionsMenu.assign.active.assignedToUserOrTeam"
                  [ngbTypeahead]="getAssignTo"
                  [editable]="false"
                  [inputFormatter]="formatAssignTo"
                  [resultTemplate]="assignTypeaheadTemplate"
                >
              </div>

              <span
                *ngIf="isAssignToolVisibleForItem(item) && (!item.result.activity.assignedToUser && !item.result.activity.assignedToTeam)"
                class="input-group-btn"
              >
                <button
                  (click)="saveAssignTo(item, quickActionsMenu.assign.active.assignedToUserOrTeam)"
                  class="btn btn-primary action-assign"
                  type="button"
                  translate="ASSIGN"
                ></button>
              </span>

              <div
                *ngIf="isAssignToolVisibleForItem(item) && (item.result.activity.assignedToUser || item.result.activity.assignedToTeam)"
                class="conversation-assignment-preview"
              >
                <i
                  class="preview-icon ssi ssi-user-assign"
                ></i>

                <span
                  class="preview-name"
                >
                  <span
                    translate="ASSIGNED_TO"
                  ></span>

                  <span>
                    {{ formatAssignTo(quickActionsMenu.assign.active.assignedToUserOrTeam) }}
                  </span>
                </span>

                <button
                  *ngIf="quickActionsMenu.assign.active.assignedToUserOrTeam"
                  (click)="saveAssignTo(item, undefined)"
                  class="remove-button"
                >
                  <i class="fa fa-times"></i>
                </button>

              </div>
            </div>

          </div>
        </div>

        <div
          class="loading"
          *ngIf="inboxQuery.loading.more.active && last"
        ></div>
      </div>

    </div>
  </div>
</ng-container>
