import './top-post-stats.component.scss';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-top-post-stats',
  templateUrl: './top-post-stats.component.html',
  styles: []
})
export class TopPostStatsComponent implements OnInit {
  @Input() topPost: any;
  @Input() top = false;
  @Input() index: string;

  constructor() {}

  ngOnInit() {}
}
