import './post-scheduler.component.scss';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalendarMonthViewDay } from 'angular-calendar/modules/month';
import { isSameDay, endOfDay, isPast, startOfDay } from 'date-fns';
import { Subject } from 'rxjs/Subject';
import { UserModel } from '@ui-resources-angular';
import { OutboxQueryFactoryService } from '../../services/outbox-query-factory/outbox-query-factory.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyValueObject } from '../../util';
import { Account } from '@ui-resources-angular';
import { PostModalComponent } from '../../../modules/auth/marketing/content-calendar/common/components/post-modal/post-modal.component';

interface MonthDayMeta {
  isDisabled?: boolean;
  isScheduled?: boolean;
  scheduledCount?: number;
}

type MonthDay = CalendarMonthViewDay<MonthDayMeta>;

function markDisabled(day: MonthDay) {
  day.cssClass = 'disabled';
  day.meta = {
    isDisabled: true
  };
}

function markScheduled(day: MonthDay, scheduledCount: number) {
  day.cssClass = 'scheduled';
  day.meta = {
    isScheduled: true,
    scheduledCount
  };
}

const CLICK_DELAY = 300;

@Component({
  selector: 'ssi-post-scheduler',
  templateUrl: './post-scheduler.component.html'
})
export class PostSchedulerComponent implements OnInit {
  @Input() accounts: Account[];
  @Input() scheduledDate: Date;

  calendarDate = new Date();
  selectedDate: Date;
  refreshCalendar = new Subject();
  outboxQuery;
  monthViewTotals: KeyValueObject<KeyValueObject<number>> = {};
  preventDayClick = false;
  pendingClickHandler;

  dateTriggerOrigin: any;
  showDatePosts = false;
  selectedDayPosts;
  activeDay;
  activePost = 0;

  @Output() onViewPostClick = new EventEmitter();
  @Output() onCloseClick = new EventEmitter();
  @Output() onAddScheduleClick = new EventEmitter<Date>();

  constructor(
    private outboxQueryFactory: OutboxQueryFactoryService,
    private userModel: UserModel,
    private datePipe: DatePipe,
    private modal: NgbModal
  ) {}

  async ngOnInit() {
    this.selectedDate = this.scheduledDate;

    this.outboxQuery = this.outboxQueryFactory.create({
      allAccounts: this.accounts,
      authUser: await this.userModel.getAuthUser(),
      savedQuery: false
    });

    this.monthChanged();
  }

  dayClicked(day: MonthDay) {
    // horrible hack to get double clicks to work, as you cant anticipate when the user will double click
    if (!this.pendingClickHandler) {
      this.pendingClickHandler = setTimeout(() => {
        this.pendingClickHandler = undefined;
        if (this.preventDayClick) {
          this.preventDayClick = false;
        } else if (!day.meta || !day.meta.isDisabled) {
          if (isSameDay(this.selectedDate, day.date)) {
            this.selectedDate = null;
          } else {
            this.selectedDate = day.date;
          }
          this.refreshCalendar.next();
        }
      }, CLICK_DELAY);
    }
  }

  async openScheduledPostsOverlay(origin: any, day: MonthDay) {
    this.activePost = 0;

    if (day.meta && day.meta.isScheduled) {
      this.preventDayClick = true;
      this.dateTriggerOrigin = origin;
      this.activeDay = day;

      this.outboxQuery.apiQuery.start_date = startOfDay(day.date);
      this.outboxQuery.apiQuery.end_date = endOfDay(day.date);
      await this.outboxQuery.search();

      this.showDatePosts = true;
    }
  }

  addCssClasses(days: MonthDay[]) {
    days.forEach((day) => {
      if (isPast(endOfDay(day.date))) {
        markDisabled(day);
      } else if (isSameDay(day.date, this.selectedDate)) {
        day.cssClass = 'active';
      } else {
        const monthViewTotalKey = this.datePipe.transform(day.date, 'y-MM-dd');
        if (this.monthViewTotals[monthViewTotalKey]) {
          const total = Object.values(
            this.monthViewTotals[monthViewTotalKey]
          ).reduce((a, b) => a + b, 0);
          markScheduled(day, total);
        }
      }
    });
  }

  async monthChanged() {
    this.monthViewTotals = {}; // prevent screen flicker
    this.monthViewTotals = await this.outboxQuery.getCalendarMonthTotals(
      this.calendarDate
    );
    this.refreshCalendar.next();
  }

  calendarSelectToday() {
    this.calendarDate = new Date();
    this.monthChanged();
  }

  showNextScheduledPost() {
    const isLastPost =
      this.activePost === this.outboxQuery.outboxPosts.length - 1;

    if (isLastPost) {
      this.activePost = 0;
    } else {
      this.activePost = this.activePost + 1;
    }
  }

  async showPostModal() {
    this.onViewPostClick.emit();

    const modal = await this.modal.open(PostModalComponent, {
      windowClass: 'orlo-modal orlo-modal-1000 z-2001',
      backdropClass: 'orlo-modal-backdrop z-2000'
    });
    modal.componentInstance.post = this.outboxQuery.outboxPosts[
      this.activePost
    ];
  }
}
