import {
  ActionConfig,
  staticParams,
  UIParam,
  UIParamFormType
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import {
  ServiceMapping,
  overlayDimensions,
  getParamByName,
  replaceTemplateIngredientsWithHtml
} from './util';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../common/services/api';
import { AppletActionParam } from '../applets/applet';
import { Injector } from '@angular/core';
import { IftttService } from '../ifttt-service-model/ifttt-service-model';

const actions: ActionConfig[] = [
  {
    api: {
      name: 'email users'
    },
    translationIds: {
      title: 'NOTIFICATION_EMAIL_ACTION_EMAIL_USER_TITLE',
      description: 'NOTIFICATION_EMAIL_ACTION_EMAIL_USER_DESCRIPTION'
    },
    iconClass: 'ssi ssi-envelope-forward',
    overlayDimensions: overlayDimensions.notification,
    getLabel(
      params: AppletActionParam[],
      injector: Injector,
      iftttService: IftttService
    ) {
      const names: string = getParamByName(params, 'user_ids')
        .value.map((user: Colleague) => user.fullName)
        .join(', ');
      const subject: string = getParamByName(params, 'subject').value;

      const message = replaceTemplateIngredientsWithHtml(
        getParamByName(params, 'message').value,
        injector,
        iftttService
      );

      return `Email ${names} with the subject "${subject}" saying "${message}"`;
    },
    paramOverrides: {
      subject(): UIParam {
        return {
          form: {
            startColumn: true,
            type: UIParamFormType.TextInput,
            input: {
              placeholderTranslationKey: 'TYPE_YOUR_SUBJECT_LINE'
            }
          },
          serialise: staticParams.textarea().serialise,
          deserialise: staticParams.textarea().deserialise
        };
      },
      user_id(): UIParam {
        return {
          remove: true
        };
      }
    }
  },
  {
    api: {
      name: 'email teams'
    },
    translationIds: {
      title: 'NOTIFICATION_EMAIL_ACTION_EMAIL_TEAM_TITLE',
      description: 'NOTIFICATION_EMAIL_ACTION_EMAIL_TEAM_DESCRIPTION'
    },
    iconClass: 'ssi ssi-team-email',
    overlayDimensions: overlayDimensions.notification,
    getLabel(
      params: AppletActionParam[],
      injector: Injector,
      iftttService: IftttService
    ) {
      const names: string = getParamByName(params, 'team_ids')
        .value.map((team: Team) => (!!team ? team.name : 'unknown'))
        .join(', ');
      const subject: string = getParamByName(params, 'subject').value;
      const message = replaceTemplateIngredientsWithHtml(
        getParamByName(params, 'message').value,
        injector,
        iftttService
      );
      return `Email ${names} with the subject "${subject}" saying "${message}"`;
    },
    paramOverrides: {
      subject(): UIParam {
        return {
          form: {
            startColumn: true,
            type: UIParamFormType.TextInput,
            input: {
              placeholderTranslationKey: 'TYPE_YOUR_SUBJECT_LINE'
            }
          },
          serialise: staticParams.textarea().serialise,
          deserialise: staticParams.textarea().deserialise
        };
      }
    }
  }
];

const service: ServiceMapping = {
  id: 'notification.email',
  triggers: [],
  actions,
  ingredients: []
};

export default service;
