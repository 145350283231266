import './team-build.component.scss';

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import moment from 'moment';
import { AccountModel } from '@ui-resources-angular';
import {
  DateRange,
  commonDateRanges,
  DateRanges,
  commonDateRangesIterable
} from '../../../../../common/constants/common-date-ranges';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';

export interface ReportBuildFormValue {
  dateRange: string;
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'ssi-team-build',
  templateUrl: './team-build.component.html',
  styles: []
})
export class TeamBuildComponent implements OnInit {
  dateRanges: DateRanges = commonDateRanges;
  dateRangesIterable: DateRange[] = commonDateRangesIterable;
  selectedDateRange: DateRange = this.dateRanges.month;

  startDate: Date = this.selectedDateRange.start;
  endDate: Date = this.selectedDateRange.end;
  today: Date = moment().endOf('day').toDate();

  constructor(
    private state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  async ngOnInit() {
    if (this.state.params.startDate && this.state.params.endDate) {
      if (this.state.params.dateRange) {
        this.selectPresetDateRange(
          this.dateRanges[this.state.params.dateRange]
        );
      } else {
        this.onStartDatePicked(new Date(this.state.params.startDate));
        this.onEndDatePicked(new Date(this.state.params.endDate));
      }
    }

    if (this.state.params.dateRange) {
      this.selectedDateRange = commonDateRanges[this.state.params.dateRange];
    }
  }

  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const params: ReportBuildFormValue = {
      startDate: this.startDate.toISOString(),
      endDate: this.endDate.toISOString(),
      dateRange: this.selectedDateRange && this.selectedDateRange.id
    };

    this.state.go('auth.analytics.team.report', params);
  }

  selectPresetDateRange(range: DateRange): void {
    this.selectedDateRange = this.dateRanges[range.id];

    this.startDate = this.selectedDateRange.start;
    this.endDate = this.selectedDateRange.end;
  }

  onStartDatePicked(startDate: Date): void {
    if (!startDate) {
      return;
    }
    this.startDate = startDate;
    this.selectedDateRange = undefined;
  }

  onEndDatePicked(endDate: Date): void {
    if (!endDate) {
      return;
    }
    this.endDate = endDate;
    this.selectedDateRange = undefined;
  }

  onFlatpickrOpen() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 200);
  }
}
