import './campaign-report.component.scss';

import {
  Component,
  Input,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Outbox } from '@ui-resources-angular';
import {
  CampaignAnalyticsService,
  FilterPeriod
} from '../campaign-analytics.service';
import { SpreadsheetService } from '../../../../../common/services/spreadsheet/spreadsheet.service';
import {
  Campaign,
  CampaignsService
} from '../../../../../common/services/api/campaigns';
import { FileDownloadService } from '../../../../../common/services/file-download/file-download.service';
import { FileMimeTypes } from '../../../../../common/directives/file-download/file-download.directive';
import { ReportAutomationFrequency } from '../../../../../common/services/api';
import { AccountTypeId } from '../../../../../common/enums';
import {
  NetworkConst,
  commonDateRanges,
  compareToOptions,
  networksIterable
} from '../../../../../common/constants';
import { AnalyticsService } from '../../analytics.service';
import { Debounce } from '../../../../../common/decorators';
import { GlobalLoaderService } from '../../../../../common/directives';
import { isElementWithinViewport } from '../../../../../common/utils';
import { schedulesAutomatedReportsFeatureResolveFn } from '../../common/resolves/analytics-resolves';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { AutomateModalComponent } from '../../../../../common/components/automate-modal/automate-modal.component';

@Component({
  selector: 'ssi-campaign-report',
  templateUrl: './campaign-report.component.html',
  styles: []
})
export class CampaignReportComponent implements OnInit {
  static resolve = [
    {
      token: 'schedulesAutomatedReportsFeature',
      resolveFn: schedulesAutomatedReportsFeatureResolveFn,
      deps: [CompanyService]
    }
  ];
  @Input() schedulesAutomatedReportsFeature = false;
  navItems = this.getNavItems();
  reportActions;
  activeNavItemTag = 'overview';

  AccountTypeId = AccountTypeId;
  overviewNetworkId;

  activeNetworkFilter: {
    overview: string | AccountTypeId;
    linkClicks: string | AccountTypeId;
    postsBreakdown: string | AccountTypeId;
    topPosts: string | AccountTypeId;
  } = {
    overview: 'all',
    linkClicks: 'all',
    postsBreakdown: 'all',
    topPosts: 'all'
  };

  loading = true;
  campaign: Campaign;
  report: {
    filter: {
      period: FilterPeriod;
      includeDeleted: string;
      include_tags: string[];
      exclude_tags: string[];
    };
    postsData: any;
    mapData: any;
    clicksData: any;
    engagementData: any;
  } = {
    filter: {
      period: {
        start: '',
        end: ''
      },
      include_tags: [],
      exclude_tags: [],
      includeDeleted: 'false'
    },
    postsData: {
      account_type_stats: [],
      totals: {
        count_accounts: 0,
        count_posts: 0,
        sum_clicks: 0,
        sum_likes: 0,
        sum_shares: 0,
        sum_comments: 0,
        sum_impressions: 0,
        sum_reach: 0
      }
    },
    mapData: {},
    clicksData: {},
    engagementData: {}
  };

  campaignIds: string[];
  displayFilter: boolean;

  networks: NetworkConst[] = networksIterable;

  topPosts: Outbox[];

  constructor(
    protected renderer: Renderer2,
    public state: StateService,
    private elementRef: ElementRef,
    private campaignAnalytics: CampaignAnalyticsService,
    private campaignModel: CampaignsService,
    private spreadsheet: SpreadsheetService,
    private fileDownload: FileDownloadService,
    private analyticsService: AnalyticsService,
    private globalLoader: GlobalLoaderService,
    private modal: NgbModal
  ) {}

  async ngOnInit() {
    try {
      this.campaign = await this.campaignModel.getById(
        this.state.params.campaignId
      );

      if (this.state.params.includeDeleted) {
        this.report.filter.includeDeleted = this.state.params.includeDeleted;
      }

      // let tagsToInclude = [];
      // let tagIdsToExclude = [];

      if (this.state.params.tagIdsToInclude) {
        this.report.filter.include_tags = Array.isArray(
          this.state.params.tagIdsToInclude
        )
          ? this.state.params.tagIdsToInclude
          : [this.state.params.tagIdsToInclude];
      }

      if (this.state.params.tagIdsToExclude) {
        this.report.filter.exclude_tags = Array.isArray(
          this.state.params.tagIdsToExclude
        )
          ? this.state.params.tagIdsToExclude
          : [this.state.params.tagIdsToExclude];
      }

      // this.report.filter.tags = [
      //   ...tagsToInclude.map((tag) => tag),
      //   ...tagIdsToExclude.map((tag) => `-${tag}`)
      // ];

      if (this.state.params.startDate && this.state.params.endDate) {
        if (this.state.params.frequency) {
          // override report dates - frequency is set for automated reports (headless browser running on the server)
          const period =
            this.state.params.frequency === ReportAutomationFrequency.WEEKLY
              ? 'week'
              : 'month';

          const dateRange = commonDateRanges[period];
          this.state.params.startDate = dateRange.start.toISOString();
          this.state.params.endDate = dateRange.end.toISOString();

          this.state.params.frequency = undefined;
          this.state.go(this.state.current, this.state.params, {
            notify: false
          });
        }

        this.report.filter.period.start = new Date(
          this.state.params.startDate
        ).toISOString();
        this.report.filter.period.end = new Date(
          this.state.params.endDate
        ).toISOString();

        this.loadReport();
        this.displayFilter = false;
      } else {
        console.warn('No date range provided, redirecting to build page');
        this.state.go('^.build', {
          campaignId: this.state.params.campaignId
        });
      }
      this.populateNavItemElements();
    } catch (error) {
      console.error('Error loading Campaign report page', error);
    }

    if (this.state.params.disablePopup) {
      this.renderer.addClass(window.document.body, 'hide-pendo-popup');
    }

    this.reportActions = this.getReportActions();
  }

  async loadReport() {
    try {
      if (!Campaign.is(this.campaign)) {
        throw new Error('You must supply a valid campaign');
      }
      const childrenCampaigns = await this.campaignModel.getChildren(
        this.campaign.id
      );
      this.campaignIds = [
        this.campaign.id,
        ...childrenCampaigns.map((child) => child.id)
      ];

      // promises for analytics
      const outboxStatsPromise = this.campaignAnalytics.getOutboxStats(
        this.campaignIds,
        this.report.filter.period,
        this.report.filter.includeDeleted === 'true',
        this.report.filter.include_tags,
        this.report.filter.exclude_tags
      );

      const clicksDataPromise = this.analyticsService.getLinkClicksData(
        this.report.filter.period,
        [this.campaign.id],
        undefined,
        undefined,
        undefined,
        this.report.filter.include_tags,
        this.report.filter.exclude_tags
      );

      const mapDataPromise = this.campaignAnalytics.getMapData(
        this.campaignIds,
        this.report.filter.period,
        this.report.filter.include_tags,
        this.report.filter.exclude_tags
      );
      const engagementDataPromise = this.campaignAnalytics.getInboundStats(
        this.campaignIds,
        this.report.filter.period,
        this.report.filter.include_tags,
        this.report.filter.exclude_tags
      );

      const [
        postsData,
        clicksData,
        mapData,
        engagementData
      ] = await Promise.all([
        outboxStatsPromise,
        clicksDataPromise,
        mapDataPromise,
        engagementDataPromise
      ]);

      this.report = {
        filter: this.report.filter,
        clicksData,
        postsData,
        mapData,
        engagementData
      };
      this.loading = false;
    } catch (error) {
      console.error('Error loading Campaign report:', error);

      return this.state.go('auth.campaigns');
    }
  }

  async reportDateSelected(event) {
    try {
      this.loading = true;
      this.report.filter.period = {
        start: new Date(event.from).toISOString(),
        end: new Date(event.to).toISOString()
      };
      await this.loadReport();
    } catch (error) {
      console.error('Error loading Campaign report on date selected:', error);
    }
    this.analyticsService.updateUrlParams({
      startDate: new Date(event.from),
      endDate: new Date(event.to)
    });
    this.loading = false;
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Overview',
        tag: 'overview',
        element: undefined
      },
      {
        label: 'Video Metrics',
        tag: 'video-metrics',
        element: undefined
      },
      {
        label: 'Instagram Stories',
        tag: 'instagram-stories',
        element: undefined
      },
      {
        label: 'Link Clicks',
        tag: 'link-clicks',
        element: undefined
      },
      {
        label: 'Global Link Clicks',
        tag: 'global-link-clicks',
        element: undefined
      },
      {
        label: 'Posts Breakdown',
        tag: 'posts-breakdown',
        element: undefined
      },
      {
        label: 'Top Posts',
        tag: 'top-posts',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      {
        icon: 'ssi-print',
        label: 'Export PDF',
        func: () => {
          if (this.loading) {
            console.log('still loading');
            return;
          }
          this.openBrowserPrint();
        }
      },
      {
        icon: 'ssi-print',
        label: 'Download XLXS',
        func: () => this.downloadReport()
      },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => this.navigateToBuildPage()
      },
      {
        icon: 'ssi-line-arrow-small-down',
        label: 'All Campaigns',
        func: () => {
          this.state.go('auth.analytics.campaign.index');
        }
      },
      {
        icon: 'ssi ssi-small-schedule',
        label: 'Automate report',
        featureFlag: this.schedulesAutomatedReportsFeature,
        func: () => {
          this.openAutomateReportModal();
        }
      }
    ].filter(
      (action) => !action.hasOwnProperty('featureFlag') || action.featureFlag
    );
  }

  async downloadReport() {
    this.globalLoader.show();
    try {
      const topPosts =
        this.topPosts ||
        (await this.analyticsService.loadTopPosts(
          this.report.filter.period as any,
          'clicks',
          undefined,
          undefined,
          this.campaignIds,
          undefined,
          this.report.filter.include_tags,
          this.report.filter.exclude_tags
        ));

      const spreadsheetData = this.campaignAnalytics.createSpreadsheetFromReport(
        this.report.filter.period,
        this.report,
        this.campaign,
        topPosts
      );
      const xlsx = await this.spreadsheet.jsonToXlsx(spreadsheetData);

      const config = {
        base64decode: true,
        filename: `Orlo - ${this.campaign.name} - Campaign report.xlsx`,
        mimetype: FileMimeTypes.Xlsx
      };

      this.fileDownload.download(xlsx, config);
    } catch (e) {
      console.error('Error generating report: ', e);
    }
    this.globalLoader.hide();
  }

  onTopPostsLoaded(posts: Outbox[]): void {
    this.topPosts = posts;
  }

  navigateToBuildPage() {
    this.state.go('auth.analytics.campaign.build', {
      ...this.state.params,
      campaignId: this.campaign.id
    });
  }

  async openAutomateReportModal() {
    const automateModal = this.modal.open(AutomateModalComponent, {
      windowClass: 'orlo-modal'
    });
    automateModal.componentInstance.editMode = false;
    automateModal.componentInstance.reportUrl = this.state.href(
      this.state.current.name,
      this.state.params,
      { absolute: true }
    );
  }

  openBrowserPrint() {
    window.print();
  }
}
