import { Component, Input, OnInit } from '@angular/core';
import {
  SurveyReport,
  Aggregation
} from '../../../../../../common/services/api/surveys';

@Component({
  selector: 'ssi-survey-summary',
  templateUrl: './survey-summary.component.html',
  styles: []
})
export class SurveySummaryComponent implements OnInit {
  @Input() report: SurveyReport;
  @Input() aggregation: Aggregation;

  constructor() {}

  ngOnInit() {}
}
