import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopPostViewComponent } from './top-post-view/top-post-view.component';
import { TopPostStatsComponent } from './top-post-stats/top-post-stats.component';

import { LinkifyModule } from '../../../../../../common/pipes/linkify/linkify.module';
import { EnlargeImageModule } from '../../../../../../common/directives/enlarge-image/enlarge-image.module';
import { ReverseModule } from '../../../../../../common/pipes/reverse/reverse.module';
import { BigNumberModule } from '../../../../../../common/components/big-number/big-number.module';

@NgModule({
  declarations: [TopPostViewComponent, TopPostStatsComponent],
  imports: [
    CommonModule,
    LinkifyModule,
    EnlargeImageModule,
    ReverseModule,
    BigNumberModule
  ],
  exports: [TopPostViewComponent, TopPostStatsComponent]
})
export class TopPostsSectionModule {}
