import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LiveChatAvatarComponent } from './live-chat-avatar.component';
import './live-chat-avatar.scss';

@NgModule({
  declarations: [LiveChatAvatarComponent],

  exports: [LiveChatAvatarComponent],

  imports: [CommonModule]
})
export class LiveChatAvatarModule {}
