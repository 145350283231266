<div class="widget-monitoring-stream-config-container">
  <div class="dwc-form">

    <div class="dwc-form-half">
      <!-- Widget Text Input -->
      <div class="dwc-body-row">
        <label class="dwc-label"
               for="widgetTitle">Widget Title (Optional)</label>
        <div class="dwc-body-row-input">
          <input class="dwc-input"
                 id="widgetTitle"
                 type="text"
                 placeholder="Type your widget title here..."
                 [(ngModel)]="widget.title">
          <span [ngClass]="{'dwc-body-row-input-meta-active':  widget.title.length}"
                class="dwc-body-row-input-meta">
            {{widgetTitleMaxLength - widget.title.length}}
          </span>
        </div>
      </div>
    </div>

    <div class="dwc-form-half">
      <!-- Widget Dropdown Input -->
      <div class="dwc-body-row">
        <label class="dwc-label" for="widgetTitle">
          Insights Stream Filter
        </label>
        <ssi-global-filters
          *ngIf="globalFiltersModel"
          [functionalityMode]="GlobalFiltersFunctionalityMode.Full"
          [stylesMode]="GlobalFiltersStyleMode.Compact"
          [colleagues]="filteredColleagues"
          [teams]="filteredTeams"
          [accounts]="workflowAccounts"
          [filters]="globalFiltersModel"
          [monitoringStreams]="streams"
          (filterChanged)="updateQueryFilters($event)"
          (onClearFiltersClick)="updateQueryFilters($event)"
        ></ssi-global-filters>
      </div>

    </div>
  </div>
</div>