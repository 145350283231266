import './notes-modal.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { transition, trigger, useAnimation } from '@angular/animations';

import { Note } from '@ui-resources-angular';
import { ApiService, NotesService, NoteSubject } from '../../services/api';
import { listItemInsert, listItemRemove } from '../../animations';

@Component({
  selector: 'ssi-notes-modal',
  templateUrl: './notes-modal.component.html',
  styles: [],
  animations: [
    trigger('listItemInsertRemoveAnimation', [
      transition('void => *', [useAnimation(listItemInsert, {})]),
      transition('* => void', [useAnimation(listItemRemove, {})])
    ])
  ]
})
export class NotesModalComponent implements OnInit {
  @Input() subject: NoteSubject;
  @Input() subjectId: string;
  @Input() modalHeader: string = '';
  @Input() disableTags = false;

  notes: Note[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    protected api: ApiService,
    protected notesService: NotesService
  ) {}

  async ngOnInit() {
    this.notes = await this.notesService.getAll(this.subject, this.subjectId);
  }

  async addNote(noteWithTags: string): Promise<void> {
    const createdNote = await this.notesService.create(
      this.subject,
      this.subjectId,
      noteWithTags
    );

    this.notes.unshift(createdNote);
  }

  async deleteNote(note: Note): Promise<void> {
    const deleteResponse = await this.notesService.delete(
      this.subject,
      this.subjectId,
      note.id
    );

    this.notes = this.notes.filter((n) => n.id !== note.id);
  }

  cancelNote(): void {
    this.activeModal.dismiss();
  }
}
