import './publisher-secondary-view-nav.component.scss';
import { Component, Output, EventEmitter, Input, Inject } from '@angular/core';
import { OutboxPublisher } from '@ui-resources-angular';
import { PublisherActive, PUBLISHER_ACTIVE } from '../publisher-active';

@Component({
  selector: 'ssi-publisher-secondary-view-nav',
  templateUrl: './publisher-secondary-view-nav.component.html'
})
export class PublisherSecondaryViewNavComponent {
  @Input() post: OutboxPublisher;
  @Output() back = new EventEmitter();

  constructor(
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive
  ) {}
}
