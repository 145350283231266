import { mapToIterable } from '../utils';

export interface AgeOption {
  id: string;
  label: string;
}

export interface AgeOptions {
  0: AgeOption;
  19: AgeOption;
  30: AgeOption;
  40: AgeOption;
}

export const ageOptions: AgeOptions = {
  0: {
    id: '0',
    label: '18 & below'
  },
  19: {
    id: '19',
    label: '19 to 29'
  },
  30: {
    id: '30',
    label: '30 to 39'
  },
  40: {
    id: '40',
    label: '40 & above'
  }
};

export const ageOptionsIterable: AgeOption[] = mapToIterable(ageOptions);

export function findAgeConst(key: string, value: any): AgeOption {
  return ageOptionsIterable.find((s) => String(s[key]) === String(value));
}
