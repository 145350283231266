<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings active" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Notification settings</h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div *ngIf="!selectedAccount">
    <div class="panel panel-default">
      <div class="panel-heading panel-heading-btn">
        <h3 class="panel-title"><i class="fa fa-user"></i> <span translate="ACCOUNT_NOTIFICATIONS"></span></h3>
        <div class="clearfix"></div>
      </div>

      <div class="panel-body">
        <label translate="ADD_NOTIFICATIONS_TO_AN_ACCOUNT"></label>
        <select class="form-control" [(ngModel)]="selectedAccount">
          <option value="" translate="SELECT_AN_ACCOUNT"></option>
          <option *ngFor="let account of nonConfiguredAccounts | sortBy:'name'" [value]="account.id">
            {{ account.displayName }} ({{ account.accountTypeLabel }})
          </option>
        </select>

        <div *ngIf="notifications?.accounts.configured.length > 0">
          <hr>
          <label translate="FILTER_ACCOUNTS"></label>
          <input
            type="text"
            [(ngModel)]="searchText"
            (ngModelChange)="filterConfiguredAccounts()"
            class="form-control"
            placeholder="{{ 'ACCOUNT_NAME' | translate }}">
        </div>
      </div>
    </div>
    
    <ssi-account-notification-settings
      *ngFor="let account of displayedConfiguredAccounts | sortBy: 'name'"
      [account]="account.id"
      [accounts]="accounts"
      [notifications]="notifications"
      (updateSubscriptions)="updateSubscriptions()">
    </ssi-account-notification-settings>
  </div>

  <ssi-account-notification-settings
    *ngIf="selectedAccount"
    [account]="selectedAccount"
    [accounts]="accounts"
    [notifications]="notifications"
    [showActions]="true"
    (resetSelectedAccount)="updateSubscriptions(); selectedAccount = ''">
  </ssi-account-notification-settings>

</div>
