<ul class="notes-list-cont">
  <li *ngFor="let note of orderedNotes">
    <div *ngIf="!!note && !!note.author"
         class="note"
         [ngClass]="{'border-left': note.author?.id === authUser?.id, 'border-right': note.author?.id !== authUser?.id}">
      <div class="note-header">
        <span class="note-time">
          {{ note.created_at | date:'d MMM' }} {{ note.created_at | date:'HH:mm' }}
        </span>
        <span>&nbsp;|&nbsp;</span>
        <span *ngIf="note.author?.id !== authUser?.id"
            class="note-author">
          {{ note.author.fullName }}
        </span>
        <span *ngIf="note.author?.id === authUser?.id">
          <button class="delete-button"
                  (click)="onDelete.emit(note)">
            <i class="ssi ssi-trash button-icon"></i>
            <span class="button-text"
                  translate="DELETE"></span>
          </button>
        </span>
      </div>
      <p class="disabled-links note-content"
         [innerHTML]="getContentForNote(note) | nl2br"
         [ngClass]="{ 'self-penned': !isExpanded && note.author?.id === authUser?.id }">
      </p>
    </div>
  </li>
</ul>