import './outbox-post-new-notes.component.scss';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { User } from '@ui-resources-angular';
import {
  TeamsService,
  Team,
  Colleague,
  ColleaguesService
} from '../../../common/services/api';
import {
  mentionRegex,
  sanitizeWithAllowedTags
} from '../../../common/components/notes-textarea/notes-textarea.component';
import {
  NotesModelService,
  Note
} from '../../../modules/auth/marketing/validate-posts/validate-posts-box/validate-posts-notes/notes-model/notes-model.service';
import { NoteSubject } from '../../services/api/notes/notes.service';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

@Component({
  selector: 'ssi-outbox-post-new-notes',
  templateUrl: './outbox-post-new-notes.component.html'
})
export class OutboxPostNewNotesComponent implements OnInit {
  @Input() notes: Note[];
  @Input() postID: string;
  @Input() disablePosting: boolean;
  @Input() authUser: User;

  @Output() updateNotes: EventEmitter<any> = new EventEmitter();

  @ViewChild('deleteNoteTooltip') deleteNoteTooltip: TooltipDirective;

  colleagues: Colleague[];
  allColleagues: Colleague[];
  teams: Team[];
  content: string;
  tags: any = 'content';
  tagList: any[];
  teamTagList: any[];

  constructor(
    public notesModelService: NotesModelService,
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService
  ) {}

  async ngOnInit() {
    this.allColleagues = await this.colleaguesService.getAll();
    this.colleagues = await this.colleaguesService.getAllActive();
    this.teams = await this.teamsService.getAllActive();
    this.tagList = [...this.teams, ...this.colleagues];
  }

  public contentChange(value: string) {
    const matchedMentions = value.match(mentionRegex);
    let content = (' ' + value).slice(1);

    if (matchedMentions) {
      matchedMentions.map((tag) => {
        const mentionMatch = this.tagList.find(
          (tagItem) => tagItem['mentionName'] === tag.substring(1)
        );
        if (mentionMatch) {
          content = content.replace(
            tag,
            `<a ${mentionMatch.mentionAttribute}="${
              (mentionMatch as any).id
            }">@${mentionMatch['mentionName']}</a>`
          );
        }
      });
    }
    content = sanitizeWithAllowedTags(content);
    this.tags = content;
  }

  public showDeleteNoteTooltip(): void {
    this.deleteNoteTooltip.show();
  }

  public getAuthorName(createdByID: string): string {
    const author = this.allColleagues.find(
      (colleague) => createdByID === colleague.id
    );
    if (!author) {
      return 'Unknown';
    }

    return author.id === this.authUser.id
      ? 'You'
      : `${author.first_name} ${author.last_name}`;
  }

  public async saveNote() {
    const { data } = await this.notesModelService.saveNote(
      this.tags,
      this.postID,
      NoteSubject.Outbox
    );
    this.content = '';
    this.tags = '';
    this.updateNotes.emit(data);
  }

  public async deleteNote(note: Note) {
    const { data } = await this.notesModelService.deleteNote(
      note.id,
      this.postID,
      NoteSubject.Outbox
    );
    if (data.success) {
      this.updateNotes.emit(note);
    }
  }
}
