import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgbDropdownModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';

import { InboxSearchResultsComponent } from './inbox-search-results.component';
import { InboxQueryResultListModule } from '../inbox-query-result-list/inbox-query-result-list.module';
import { LiveChatModule } from '../../../../common/components/live-chat/live-chat/live-chat.module';

@NgModule({
  declarations: [InboxSearchResultsComponent],
  imports: [
    CommonModule,
    UIRouterModule,
    TranslateModule,
    NgbPopoverModule.forRoot(),
    NgbDropdownModule.forRoot(),
    LiveChatModule,
    InboxQueryResultListModule
  ],
  exports: [InboxSearchResultsComponent]
})
export class InboxSearchResultsModule {}
