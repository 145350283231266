<div
  *ngIf="! isReady"
  class="not-loaded"
>
  <div class="spinner">
    <i class="ssi ssi-loading ssi-spin"></i>
  </div>
</div>

<ul
  *ngIf="!! isReady"
  [class.push-mode]="isPushModeEnabled"
  class="loaded"
>
  <li
    *ngFor="let conversation of conversations"
    [ngClass]="{
      assigned: !! conversation.isAssigned,
      disabled: !! isInterfaceDisabled,
      unassigned: ! conversation.isAssigned
    }"
    class="spacer-bottom"
    data-pendo="liveChat_viewLiveChatConversation"
  >
    <ssi-live-chat-network-state
      [isAvatarOnly]="true"
      [user]="conversation.visitor"
    ></ssi-live-chat-network-state>

    <button
      *ngIf="!! canChangeAssignmentForConversation(conversation)"
      (click)="toggleAssignmentToConversationForAgent(conversation)"
      [disabled]="isAgentOffline || !! isInterfaceDisabled"
      class="assignment"
      type="button"
    ></button>

    <div
      (click)="selectConversation(conversation.id)"
      class="details"
    >
      <span
        *ngIf="!! conversation.authorName"
        class="name"
      >
        {{ conversation.authorName }}
      </span>

      <span
        *ngIf="! conversation.authorName"
        class="name"
      >
        <i class="ssi ssi-loading ssi-spin"></i>
      </span>

      <span
        class="application-name"
      >
        {{ conversation.applicationName }}
      </span>

      <span
        *ngIf="!! conversation.lastMessage.attachment"
        class="preview"
      >
        <em>A file was sent.</em>
      </span>

      <span
        *ngIf="!! conversation.lastMessage.text && ! conversation.lastMessage.attachment"
        class="preview"
      >
        {{ conversation.preview.text }}
      </span>

      <span
        class="assignment-status"
      >
        <span
          class="status"
          translate="{{ conversation.assignmentStatus.toUpperCase() }}"
        ></span>

        <span
          *ngIf="conversation.isAssigned"
          class="assignee"
        >
          {{ conversation.agentName }}
        </span>
      </span>
    </div>

    <span class="age">
      {{ getRelativeAgeForConversation(conversation) }}
    </span>
  </li>
</ul>
