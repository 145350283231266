import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { EntryComponent } from './entry.component';
import { PolicyModule } from './policy/policy.module';
import { LandingModule } from './landing/landing.module';
import { ActivateModule } from './activate/activate.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'entry',
          url: '/entry',
          component: EntryComponent,
          abstract: true
        }
      ]
    }),
    PolicyModule,
    LandingModule,
    ActivateModule
  ],
  declarations: [EntryComponent],
  exports: [EntryComponent]
})
export class EntryModule {}
