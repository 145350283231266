import {
  Activity,
  ActivityModel,
  ActivityTags,
  API,
  Campaign,
  CampaignModel,
  Conversation,
  ConversationVisibility,
  User,
  Account
} from '@ui-resources-angular';
import { appInjector } from '../../../../../angular/app-injector';
import { TeamsService, Team, ColleaguesService, Colleague } from '../../api';
import { TranslateService } from '@ngx-translate/core';
import { KeyValueObject } from '../../../util';
import { AsyncTracker } from 'angular-async-tracker';
import {
  AbstractQueryHelperService,
  QueryFilterOption,
  QueryPreset
} from '../../abstract-query-helper/abstract-query-helper.service';
import {
  inboxPriorities,
  LANGUAGES,
  SENTIMENT_CONFIG
} from '../../../constants';
import { Injector } from '@angular/core';
import {
  getAccountsWithViewInboxPermission,
  getSavedSortAndOrder,
  getTeamOptions,
  getUserOptions,
  InboxSortOption,
  NOT_ASSIGNED,
  OnInboxQuerySearch,
  sortByLabel,
  getAssignedFilter,
  getStatusFilter,
  getVisibilityFilter,
  InboxQueryResultListItem,
  InboxQueryResultListItemType,
  InboxQueryResultListItemModel,
  AbstractInboxQuery,
  InboxQueryResultGroupByType,
  addInboxQueryResultListDividers,
  InboxQueryType,
  localRefresh
} from './common';

enum InboxActivityQuerySortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

function getCampaignOptions(
  campaigns: Campaign[],
  campaignModel: CampaignModel
): Array<QueryFilterOption<Campaign>> {
  return campaigns
    .filter((campaign) => !!campaign && !campaign.is_closed)
    .filter(
      (campaign) =>
        !campaign.parent_id ||
        (!!campaignModel.get(campaign.parent_id) &&
          !campaignModel.get(campaign.parent_id).is_closed)
    )
    .map((campaign) => ({
      value: campaign,
      label: campaign.name,
      trackBy: campaign.id
    }))
    .sort(sortByLabel);
}

function fullTextMatch(searchSubject = '', searchText = '') {
  const normaliseText = (text) => text.toLowerCase().trim();
  const searchTokens = normaliseText(searchText)
    .split(/[^a-z?*0-9]/)
    .filter((token) => !!token);
  const matchAgainst = normaliseText(searchSubject);
  return searchTokens.every((token) => {
    const tokenRegexString = token.replace(/\*/g, '.*');
    // no need to escape this as all non a-z characters are already stripped
    const tokenRegex = new RegExp(tokenRegexString);
    return tokenRegex.test(matchAgainst);
  });
}

export const inboxActivityQuerySort: KeyValueObject<InboxSortOption> = {
  smart: {
    value: 'SMART',
    translateLabel: 'SMART_SORT',
    isDefault: false,
    order: 'desc',
    groupBy: InboxQueryResultGroupByType.Smart,
    sortResults(activities: Activity[]) {
      return activities.sort((a: Activity, b: Activity) => {
        const statusOrder = {
          unread: 0,
          unactioned: 1,
          actioned: 2
        };
        const statusDiff =
          statusOrder[a.statusText] - statusOrder[b.statusText];
        if (statusDiff !== 0) {
          return statusDiff;
        } else {
          return (
            new Date(b.interaction.created_at).getTime() -
            new Date(a.interaction.created_at).getTime()
          );
        }
      });
    }
  },
  newest: {
    value: 'DATE',
    translateLabel: 'SORT_BY_NEWEST',
    isDefault: true,
    order: 'desc',
    groupBy: InboxQueryResultGroupByType.Date
  },
  oldest: {
    value: 'DATE',
    translateLabel: 'SORT_BY_OLDEST',
    isDefault: false,
    order: 'asc',
    groupBy: InboxQueryResultGroupByType.Date
  },
  priorityNewest: {
    value: 'PRIORITY',
    translateLabel: 'SORT_BY_HIGHEST_PRIORITY_NEWEST',
    isDefault: false,
    order: InboxActivityQuerySortOrder.Desc,
    groupBy: InboxQueryResultGroupByType.Priority
  },
  priorityOldest: {
    value: 'PRIORITY',
    translateLabel: 'SORT_BY_LOWEST_PRIORITY_NEWEST',
    isDefault: false,
    order: InboxActivityQuerySortOrder.Asc,
    groupBy: InboxQueryResultGroupByType.Priority
  }
};

const activitiesValue = Symbol();

export class InboxActivityQuery extends AbstractInboxQuery {
  readonly type = InboxQueryType.Activity;

  conversation?: Conversation;

  result: {
    [activitiesValue]: Activity[];
    activities: Activity[];
    list: InboxQueryResultListItem[];
    total: number;
    totalLoaded: number;
    conversations?: Conversation[];
  };

  presets = [
    {
      key: 'all',
      label: this.injector.get(TranslateService).instant('ALL_MESSAGES'),
      icon: 'ssi ssi-all-messages-new',
      query: {}
    },
    {
      key: 'assignedToMe',
      label: this.injector.get(TranslateService).instant('ASSIGNED_TO_ME'),
      icon: 'ssi ssi-assigned',
      query: {
        assigned: 'me'
      }
    },
    {
      key: 'unactioned',
      label: this.injector.get(TranslateService).instant('UNACTIONED'),
      icon: 'ssi ssi-unactioned',
      query: {
        status: ['unactioned', 'unread']
      }
    }
  ];

  readonly params = {
    sort: getSavedSortAndOrder(this.savedQuery, inboxActivityQuerySort).sort,
    order: getSavedSortAndOrder(this.savedQuery, inboxActivityQuerySort).order,
    v: 4
  };

  constructor(
    injector: Injector,
    private allAccounts: Account[],
    colleagues: Colleague[],
    campaigns: Campaign[],
    teams: Team[],
    authUser: User,
    private savedQuery: KeyValueObject,
    private onSearch: OnInboxQuerySearch,
    public customPresets: QueryPreset[]
  ) {
    super(injector);

    const query = this;

    this.result = {
      [activitiesValue]: [],
      list: [],
      total: 0,
      totalLoaded: 0,
      get activities(): Activity[] {
        return this[activitiesValue];
      },
      set activities(activities: Activity[]) {
        this[activitiesValue] = activities;
        this.totalLoaded = activities.length;
        const mappedActivities: InboxQueryResultListItem[] = activities.map(
          (activity) => query.activityToResultListItem(activity)
        );
        this.list = addInboxQueryResultListDividers(
          mappedActivities,
          inboxActivityQuerySort,
          query.params.sort,
          injector
        );
      }
    };

    const abstractQueryHelper = injector.get(AbstractQueryHelperService);
    const translate = injector.get(TranslateService);
    const activityTags = injector.get(ActivityTags);
    const colleaguesService = injector.get(ColleaguesService);
    const campaignModel = injector.get(CampaignModel);

    const userOptions = getUserOptions(colleagues);
    const teamOptions = getTeamOptions(teams);
    const campaignOptions = getCampaignOptions(campaigns, campaignModel);

    this.filters = [
      abstractQueryHelper.getAccountsFilter({
        key: 'ac',
        allAccounts: getAccountsWithViewInboxPermission(allAccounts, authUser),
        isDefault(value) {
          return value.length === 0;
        },
        serialize(selectedAccountIds) {
          if (selectedAccountIds.length === 0) {
            selectedAccountIds = this.defaultValue;
          }
          const accountIds = {};
          selectedAccountIds.forEach((accountId) => {
            accountIds[accountId] = '1';
          });
          return accountIds;
        },
        deserialize(accountIds) {
          const accountIdsArray = Object.keys(accountIds || {}).filter(
            (accountId) => +accountIds[accountId] === 1
          );
          if (accountIdsArray.length === this.defaultValue.length) {
            return [];
          }
          return accountIdsArray;
        },
        matches(filterValue, activity) {
          return filterValue.some(
            (accountId) => +accountId === activity.account_id
          );
        }
      }),
      abstractQueryHelper.getFromDateTimeFilter({
        key: 'dt_after',
        matches(filterValue, activity) {
          return new Date(activity.interaction.created_at) >= filterValue;
        }
      }),
      abstractQueryHelper.getToDateTimeFilter({
        key: 'dt_before',
        matches(filterValue, activity) {
          return new Date(activity.interaction.created_at) <= filterValue;
        }
      }),
      {
        key: 'tags',
        type: 'tags',
        get defaultValue() {
          return {
            join: 'or',
            values: {
              include: [],
              exclude: []
            }
          };
        },
        isDefault(tags) {
          return (
            !!tags &&
            !!tags.values &&
            tags.values.hasOwnProperty('include') &&
            tags.values.hasOwnProperty('exclude') &&
            tags.values.include.length === 0 &&
            tags.values.exclude.length === 0
          );
        },
        serialize(tags) {
          const includeTags =
            tags && tags.values && tags.values.include.map((tag) => tag.text);
          const excludeTags =
            tags && tags.values && tags.values.exclude.map((tag) => tag.text);

          return {
            values: {
              include: includeTags,
              exclude: excludeTags
            },
            join: !!tags ? tags.join : null
          };
        },
        deserialize(tags) {
          if (!!tags && !!tags.values && Array.isArray(tags.values)) {
            const patchIncludeTags = tags.values
              .filter((tag) => tag.indexOf('-') !== 0)
              .map((tag) => ({ text: tag }));
            const patchExcludeTags = tags.values
              .filter((tag) => tag.indexOf('-') === 0)
              .map((tag) => ({ text: tag.substring(1) }));
            return {
              values: {
                include: patchIncludeTags,
                exclude: patchExcludeTags
              },
              join: !!tags ? tags.join : null
            };
          }

          const includeTags = tags.values.include.map((tag) => ({ text: tag }));
          const excludeTags = tags.values.exclude.map((tag) => ({ text: tag }));

          return {
            values: {
              include: includeTags,
              exclude: excludeTags
            },
            join: !!tags ? tags.join : null
          };
        },
        translateLabel: 'TAGS',
        icon: 'fa-tags',
        selectedText(tags) {
          return translate.instant('_AMOUNT__TAGS', {
            amount:
              (tags.values.include ? tags.values.include.length : 0) +
              (tags.values.exclude ? tags.values.exclude.length : 0)
          });
        },
        searchTags(searchText) {
          return activityTags.getTags(searchText);
        },
        searchFilteredTags(searchText, filterTags) {
          return activityTags.getTags(searchText).then((queryTags) => {
            const filterTagsText = filterTags.map((tag) => tag.text);
            return queryTags.filter(
              (queryTag) => !filterTagsText.includes(queryTag)
            );
          });
        },
        matches(filterValue, activity: Activity) {
          const normaliseTag = (tag) => tag.trim().toLowerCase();
          const matchTag = (tag) =>
            (activity.tags || []).some(
              (iTag) => normaliseTag(tag) === normaliseTag(iTag)
            );
          const allTags = filterValue.values.include.concat(
            filterValue.values.exclude
          );
          if (filterValue.join === 'or') {
            return allTags.some(matchTag);
          } else {
            return allTags.every(matchTag);
          }
        }
      },
      {
        key: 'priority',
        translateLabel: 'PRIORITY',
        type: 'selectMultiple',
        icon: 'ssi ssi-priority',
        get defaultValue() {
          // pass undefined to the backend if nothing selected
          return undefined;
        },
        isDefault(selectedValues) {
          // nothing selected (undefined) is the default - backend will ignore priority filter in that case
          return selectedValues === undefined;
        },
        selectedText(values: number[]) {
          return (
            values &&
            values
              .sort()
              .map((v) => this.options.find((o) => o.value === v).label)
              .join(', ')
          );
        },
        options: this.getPriorityFilterOptions(),
        matches(values, activity: Activity) {
          /* tslint:disable-next-line */
          return values.some((v) => activity.inbox.priority == v);
        }
      },
      {
        key: 'sentiment',
        type: 'selectMultiple',
        get defaultValue() {
          return this.options.map((o) => o.value);
        },
        isDefault(value) {
          return value.length === 0;
        },
        translateLabel: 'SENTIMENT',
        icon: 'fa-smile-o',
        selectedText(sentiments) {
          return sentiments
            .map((sentiment) => translate.instant(sentiment.toUpperCase()))
            .join(', ');
        },
        options: Object.values(SENTIMENT_CONFIG || {}).map(
          (sentiment: { translateKey: string }) => {
            return {
              value: sentiment.translateKey.toLowerCase(),
              label: sentiment.translateKey,
              sentiment
            };
          }
        ),
        matches(filterValue, activity: Activity) {
          return filterValue.some((sentimentOption) => {
            return (
              sentimentOption.sentiment &&
              sentimentOption.sentiment.inbox.value === activity.sentiment &&
              activity.sentiment.value
            );
          });
        },
        serialize(value) {
          if (value.length === 0) {
            return this.defaultValue;
          }
          return value;
        },
        deserialize(value) {
          if (value.length === this.options.length) {
            return [];
          }
          return value;
        }
      },
      getVisibilityFilter(
        {
          key: 'visibility',
          defaultValue: 'all',
          options: [
            {
              label: 'ALL_MESSAGES',
              value: 'all'
            },
            {
              label: 'PRIVATE_MESSAGES_ONLY',
              value: 'private'
            },
            {
              label: 'PUBLIC_MESSAGES_ONLY',
              value: 'public'
            }
          ],
          matches(filterValue, activity: Activity) {
            return (
              (filterValue === 'public' && !activity.interaction.is_private) ||
              (filterValue === 'private' && activity.interaction.is_private)
            );
          }
        },
        translate
      ),
      {
        key: 'lang',
        type: 'selectMultiple',
        showSearch: true,
        get defaultValue() {
          return [];
        },
        isDefault(value) {
          return value.length === 0;
        },
        translateLabel: 'LANGUAGE',
        icon: 'fa-language',
        selectedText(value) {
          return translate.instant('_AMOUNT__LANGUAGES', {
            amount: value.length
          });
        },
        options: Object.entries(LANGUAGES || {})
          .map(([value, label]) => ({ value, label }))
          .sort(sortByLabel),
        matches(filterValue, activity: Activity) {
          return filterValue.some(
            (languageKey) => languageKey === activity.properties.language
          );
        }
      },
      getAssignedFilter(
        {
          key: 'assigned',
          users: userOptions,
          teams: teamOptions,
          additionalOptions: [
            {
              value: NOT_ASSIGNED,
              label: translate.instant('UNASSIGNED_ONLY'),
              iconClass: 'fa fa-user-times'
            }
          ],
          matches(filterValue, activity) {
            if (activity.assignedToUser) {
              return filterValue === activity.assignedToUser;
            } else if (activity.assignedToTeam) {
              return (
                filterValue === activity.assignedToTeam ||
                colleaguesService.store
                  .filterByTeam(activity.assignedToTeam.id, true)
                  .includes(filterValue)
              );
            } else {
              return filterValue === NOT_ASSIGNED;
            }
          },
          localRefresh: true
        },
        translate,
        authUser,
        {
          prefixes: {
            team: 'group:'
          }
        }
      ),
      {
        key: 'actioned_by',
        type: 'user',
        isDefault(value) {
          return !value;
        },
        serialize(user) {
          if (user) {
            if (+user.id === +authUser.id) {
              return 'me';
            } else {
              return user.id;
            }
          } else {
            return user;
          }
        },
        deserialize(userId) {
          if (userId === 'me') {
            userId = authUser.id;
          }
          return userId ? colleaguesService.store.find(userId) : userId;
        },
        translateLabel: 'ACTIONED_BY',
        icon: 'fa-check',
        selectedText(user) {
          return user
            ? translate.instant('ACTIONED_BY__NAME_', { name: user.fullName })
            : '';
        },
        users: userOptions,
        matches(filterValue, activity: Activity) {
          return filterValue === activity.actionedBy;
        },
        localRefresh: true
      },
      getStatusFilter(
        {
          key: 'status',
          options: [
            {
              label: 'UNREAD',
              value: 'unread'
            },
            {
              label: 'UNACTIONED',
              value: 'unactioned'
            },
            {
              label: 'ACTIONED',
              value: 'actioned'
            }
          ],
          matches(filterValue, activity: Activity) {
            return filterValue.includes(activity.statusText);
          },
          localRefresh: true,
          get defaultValue() {
            return this.options.map((option) => option.value);
          },
          isDefault(value) {
            return value.length === 0;
          },
          serialize(value) {
            if (value.length === 0) {
              return this.defaultValue;
            }
            return value;
          },
          deserialize(value) {
            if (value.length === this.options.length) {
              return [];
            }
            return value;
          }
        },
        translate
      ),
      {
        key: 'is_silenced',
        type: 'selectSingle',
        defaultValue: 'exclude',
        isDefault(value) {
          return value === this.defaultValue;
        },
        translateLabel: 'SILENCED_MESSAGES',
        icon: 'fa-microphone-slash',
        selectedText(value) {
          if (!(!!this.options && Array.isArray(this.options))) {
            return;
          }

          return translate.instant(
            this.options.find((option) => option.value === value).label
          );
        },
        options: [
          {
            label: 'ALL_MESSAGES',
            value: 'include'
          },
          {
            label: 'SILENCED_MESSAGES_ONLY',
            value: 'exclusive'
          },
          {
            label: 'UNSILENCED_MESSAGES_ONLY',
            value: 'exclude'
          }
        ],
        matches(filterValue, activity: Activity) {
          switch (filterValue) {
            case 'exclude':
              return !activity.inbox.is_silenced;

            case 'exclusive':
              return !!activity.inbox.is_silenced;

            default:
              return true;
          }
        }
      },
      {
        key: 'campaign_ids',
        type: 'selectMultiple',
        showSearch: true,
        skipLabelTranslation: true,
        get defaultValue() {
          return [];
        },
        serialize(campaignsList: Campaign[]) {
          return !!campaignsList && Array.isArray(campaignsList)
            ? campaignsList.map((campaign) => campaign.id)
            : [];
        },
        deserialize(campaignIds: string[]) {
          return !!campaignIds && Array.isArray(campaignIds)
            ? campaignIds.map((campaignId) => campaignModel.get(campaignId))
            : [];
        },
        isDefault(value) {
          return value.length === 0;
        },
        translateLabel: 'CAMPAIGN',
        icon: 'fa-bookmark',
        selectedText(value) {
          return translate.instant('_AMOUNT__CAMPAIGNS', {
            amount: value.length
          });
        },
        options: campaignOptions,
        matches(filterValue: Campaign[], activity: Activity) {
          return filterValue.some(
            (campaign) => +campaign.id === +activity.campaign.id
          );
        },
        localRefresh: true
      },
      {
        key: 'author',
        type: 'text',
        isDefault(value) {
          return !value;
        },
        translateLabel: 'AUTHOR',
        icon: 'fa-user',
        selectedText(name) {
          return translate.instant('AUTHOR__NAME_', { name });
        },
        matches(filterValue, activity) {
          return (
            fullTextMatch(activity.author.name, filterValue) ||
            fullTextMatch(activity.author.username, filterValue)
          );
        }
      },
      abstractQueryHelper.getTextFilter({
        key: 'q',
        matches(filterValue, activity) {
          return fullTextMatch(activity.interaction.content, filterValue);
        }
      })
    ];
  }

  getPriorityFilterOptions() {
    const options = inboxPriorities.map((p) => {
      return {
        value: p,
        label: `P${p}`
      };
    });

    options.push({
      value: null,
      label: 'No priority set'
    });

    return options;
  }

  search(offset = 0, limit = 20, queryOverrides = {}) {
    const isNewSearch = offset === 0;
    if (isNewSearch) {
      this.result.activities = [];
      this.updateQueryState();
    }

    const api = this.injector.get(API);
    const activityModel = this.injector.get(ActivityModel);

    this.apiQuery = { ...this.apiQuery, ...queryOverrides };
    this.updateQueryState();

    const promise = api
      .post('inbox/search', {
        ...this.getQueryParams(),
        ...this.params,
        limit,
        offset
      })
      .then(({ data: { activities: results, missing } }) => {
        const activities = results.map((activityRaw) => {
          return activityModel.get(activityRaw.id)
            ? activityModel.get(activityRaw.id)
            : activityModel.inject(activityRaw);
        });

        if (isNewSearch) {
          this.result.activities = activities;
        } else {
          this.result.activities = [...this.result.activities, ...activities];
        }

        this.result.total = activities.length + missing;

        this.onSearch(
          Object.assign({}, this.apiQuery, {
            sort: this.params.sort,
            order: this.params.order
          }),
          { offset, limit }
        );
      });
    const tracker: AsyncTracker = isNewSearch
      ? this.loading.initial
      : this.loading.more;
    tracker.add(promise);
    return promise;
  }

  localRefresh() {
    const { records, totalResults } = localRefresh(
      this.filters,
      inboxActivityQuerySort,
      this.testResult.bind(this),
      this.params.sort,
      this.result.activities,
      this.result.total
    );
    this.result.activities = records;
    this.result.total = totalResults;
  }

  testResult(activity: Activity, filters = this.filters) {
    if (!(!!this.allAccounts && Array.isArray(this.allAccounts))) {
      return;
    }

    if (
      !this.allAccounts.find((account) => +account.id === +activity.account_id)
    ) {
      return false;
    }

    return filters
      .map((filter) => {
        return {
          filter,
          value: this.deserializeValue(filter, this.apiQuery[filter.key])
        };
      })
      .filter(({ filter, value }) => {
        return !filter.isDefault(value); // by default each filter includes all results
      })
      .every(({ filter, value }) => {
        if (!value) {
          return false;
        }
        return filter.matches(value, activity);
      });
  }

  activityToResultListItem(activity: Activity): InboxQueryResultListItem {
    return this.injector
      .get(InboxQueryResultListItemModel)
      .injectOrReturnExisting({
        uid: `activity-${activity.id}`,
        type: InboxQueryResultListItemType.Activity,
        result: {
          id: activity.id,
          account: activity.account,
          activity,
          text: activity.interaction.content,
          author: activity.author,
          date: new Date(activity.interaction.created_at),
          responseCount:
            activity.conversation.responses.length ||
            activity.conversation.length,
          get status() {
            // hack to ensure we can update the activity status and it will magically update here
            return activity.statusText;
          },
          visibility: activity.interaction.is_private
            ? ConversationVisibility.Private
            : ConversationVisibility.Public,
          media: {
            total: activity.media.length,
            get image() {
              return activity.mediaByType.image;
            },
            get video() {
              return activity.mediaByType.video;
            },
            get document() {
              return activity.mediaByType.document;
            }
          }
        }
      });
  }

  markAllActioned(conversation?: Conversation) {
    return this.injector
      .get(ActivityModel)
      .markAllActioned(this.getQueryParams(conversation));
  }

  markAllUnactioned(conversation?: Conversation) {
    return this.injector
      .get(ActivityModel)
      .markAllUnactioned(this.getQueryParams(conversation));
  }

  private getQueryParams(
    conversation: Conversation = this.conversation
  ): KeyValueObject<string> {
    const params = { ...this.apiQuery };

    if (conversation) {
      params.root_thread_id = conversation.thread_id;
      if (
        !this.apiQuery.dt_after ||
        new Date(this.apiQuery.dt_after) <
          new Date(this.conversation.time_start)
      ) {
        params.dt_after = conversation.time_start;
      }
      if (
        !this.apiQuery.dt_before ||
        new Date(this.apiQuery.dt_before) > new Date(conversation.time_end)
      ) {
        params.dt_before = conversation.time_end;
      }
    }

    return params;
  }
}
