import { AccountTypeId, AccountTypeName } from '../enums';
import { mapToIterable } from '../utils';

export interface NetworkConst {
  /* account_type_id */
  id: number;
  /* account_type_name */
  name: string;
  /* label */
  label: string;
  /* icon class */
  icon: string;
  /* assigned color used to differentiate networks visually */
  color: string;
  /* order */
  order: number;
  /* gradient color */
  gradientColor?: string;
}

export interface NetworksConst {
  Facebook: NetworkConst;
  Twitter: NetworkConst;
  Instagram: NetworkConst;
  'Instagram Business': NetworkConst; // so it can be accessed using bracket notation
  LinkedIn: NetworkConst;
  YouTube: NetworkConst;
  'Nextdoor Agency': NetworkConst;
  'TikTok Business': NetworkConst;
}

export const networks: NetworksConst = {
  Twitter: {
    id: AccountTypeId.Twitter,
    name: AccountTypeName.Twitter,
    label: 'X',
    icon: 'ssi ssi-x-logo',
    color: '#14BAE3',
    order: 1
  },
  Facebook: {
    id: AccountTypeId.Facebook,
    name: AccountTypeName.Facebook,
    label: 'Facebook',
    icon: 'fa fa-facebook',
    color: '#425DEC',
    order: 2
  },
  Instagram: {
    id: AccountTypeId.InstagramOld,
    name: AccountTypeName.Instagram,
    label: 'Instagram',
    icon: 'fa fa-instagram',
    color: '#FF8BBA',
    order: 3,
    gradientColor: 'linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%)'
  },
  'Instagram Business': {
    id: AccountTypeId.Instagram,
    name: AccountTypeName.InstagramBusiness,
    label: 'Instagram',
    icon: 'fa fa-instagram',
    color: '#FF8BBA',
    order: 3,
    gradientColor: 'linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%)'
  },
  LinkedIn: {
    id: AccountTypeId.LinkedIn,
    name: AccountTypeName.LinkedIn,
    label: 'LinkedIn',
    icon: 'fa fa-linkedin',
    color: '#F0B427',
    order: 4
  },
  YouTube: {
    id: AccountTypeId.YouTube,
    name: AccountTypeName.YouTube,
    label: 'YouTube',
    icon: 'fa fa-youtube-play',
    color: '#F40064',
    order: 5
  },
  'Nextdoor Agency': {
    id: AccountTypeId.Nextdoor,
    name: AccountTypeName.Nextdoor,
    label: 'Nextdoor',
    icon: 'ssi ssi-nextdoor',
    color: '#B8EB51',
    order: 6
  },
  'TikTok Business': {
    id: AccountTypeId.TikTokBusiness,
    name: AccountTypeName.TiktokBusiness,
    label: 'TikTok',
    icon: 'ssi ssi-tik-tok',
    color: '#14BAE3',
    order: 7
  }
};

export const networksIterable = mapToIterable(networks);
