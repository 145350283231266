<div class="posts-container"
     [ssiObserveWidthAndHeight]="true"
     (onWidthChange)="onContainerWidthChange($event)">

  <div *ngIf="currentResponse"
       class="posts-wrapper"
       [class.two-columns]="columns === 2"
       ssiLoader
       ssiLoaderText="Loading your search results..."
       [ssiLoaderVisible]="loading"
       infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="100"
       [infiniteScrollDisabled]="currentResponse && !currentResponse.next"
       [scrollWindow]="false"
       (scrolled)="load()"
       ssiVirtualScroll
       [items]="results"
       [bufferAmount]="5"
       [getItemHeight]="getHeight"
       (viewPortItemsUpdated)="viewPortItemsUpdated($event)">
    <!-- <div class="column"
         *ngIf="leftPosts.length">
      <div class="post-wrapper"
           *ngFor="let post of leftPosts; let index = index;">
        <ssi-insights-post [post]="post"
                           [selected]="isPostSelected(post)"
                           (toggleSelect)="onPostToggleSelect(post)"></ssi-insights-post>
      </div>
    </div>
    <div class="column"
         *ngIf="rightPosts.length">
      <div class="post-wrapper"
           *ngFor="let post of rightPosts; let index = index;">
        <ssi-insights-post [post]="post"
                           [selected]="isPostSelected(post)"
                           (toggleSelect)="onPostToggleSelect(post)"></ssi-insights-post>
      </div>
    </div> -->

    <div class="post-wrapper"
         *ngFor="let post of viewPortResults; trackBy: trackByUid; let index = index;">
      <ssi-insights-post [post]="post"
                         [selected]="isPostSelected(post)"
                         (toggleSelect)="onPostToggleSelect(post)"></ssi-insights-post>
    </div>
  </div>

  <!-- <div class="no-posts-wrapper"
       *ngIf="!viewPortResults.length">
    <ssi-no-results *ngIf="!loading && activeFilters"
                    title="No results"
                    description="Maybe widen your keyword search or filters?"></ssi-no-results>

    <ng-container *ngIf="!loading && !activeFilters && beforeLoadTemplate">
      <ng-container *ngTemplateOutlet="beforeLoadTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="loading && initalLoading && initialLoadingTemplate">
      <ng-container *ngTemplateOutlet="initialLoadingTemplate"></ng-container>
    </ng-container>
  </div> -->
</div>