import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExampleModalComponent } from './example-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [ExampleModalComponent],
  entryComponents: [ExampleModalComponent]
})
export class ExampleModalModule {}
