import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Agent as AgentModel } from '../../../../../../library/models/live-chat/agent';
import { Conversation } from '../../../../../../library/models/live-chat/conversation';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { LiveChatModalFactoryService } from '../../../services/live-chat-modal-factory/live-chat-modal-factory.service';
import { LiveChatModalComponent } from '../live-chat-modal/live-chat-modal.component';

// ----------------

const isDebug = false;

// ----------------

@Component({
  selector: 'ssi-live-chat',
  templateUrl: './live-chat.component.html',
  styles: []
})
export class LiveChatComponent
  implements OnInit, DoCheck, AfterViewInit, OnDestroy {
  @Input() public isLiveChatActive: boolean;
  @Input() public hasConversationPushModeEnabled: boolean = false;

  @Output()
  public isLiveChatActiveChange = new EventEmitter<boolean>();
  @Output()
  public isLiveChatPushModeActiveChange = new EventEmitter<boolean>();

  @ViewChild('container', { read: ViewContainerRef })
  private elementContainer: ViewContainerRef;

  private _activeModal: LiveChatModalComponent;

  constructor(
    private liveChatModalFactory: LiveChatModalFactoryService,
    private liveChatService: LiveChatService
  ) {}

  public get activeConversationId(): string {
    return this.liveChatService.activeConversationId;
  }

  public get activeViewOfConversations(): Conversation[] {
    return this.liveChatService.activeViewOfConversations;
  }

  public get agent(): AgentModel {
    return this.liveChatService.agentModel;
  }

  public get ageOfNewestAssignedConversation(): string {
    return '-';
  }

  public get ageOfNewestUnassignedConversation(): string {
    return '-';
  }

  public get isLiveChatEnabled(): boolean {
    return !!this.liveChatService.isEnabled;
  }

  public get isPushModeEnabled(): boolean {
    return !!this.liveChatService.isPushModeEnabled;
  }

  public get quantityOfAssignedConversations(): number {
    return this.liveChatService.quantityOfAssignedConversations;
  }

  public get quantityOfOngoingConversations(): number {
    return this.liveChatService.quantityOfOngoingConversations;
  }

  public get quantityOfOwnedConversations(): number {
    return this.liveChatService.quantityOfOwnedConversations;
  }

  public get quantityOfTotalConversations(): number {
    return this.liveChatService.quantityOfTotalConversations;
  }

  public get quantityOfUnassignedConversations(): number {
    return this.liveChatService.quantityOfUnassignedConversations;
  }

  public get quantityOfVisibleConversations(): number {
    return this.liveChatService.quantityOfVisibleConversations;
  }

  ngOnInit() {}

  ngDoCheck() {
    // this.showModal();
  }

  ngAfterViewInit() {
    this.showModal();
  }

  public async ngOnDestroy() {
    await this.liveChatService.closeInterface();
  }

  public onActiveConversationChange(id) {
    this.liveChatService.activeConversationId = id;
  }

  public showModal() {
    if (!!this._activeModal) {
      if (isDebug) {
        console.log(`not showing modal, already showing another.`);
        console.dir(this._activeModal);
      }

      return;
    }

    if (!this.elementContainer) {
      if (isDebug) {
        console.log(`not showing modal, element container not ready yet.`);
      }

      return requestAnimationFrame(() => this.showModal());
    }

    const modal = this.liveChatService.oldestPendingModal;

    if (!modal) {
      return;
    }

    if (isDebug) {
      console.log(`modal:`);
      console.dir(modal);
    }

    this._activeModal = this.liveChatModalFactory.create(
      modal,
      this.elementContainer
    );

    this._activeModal.isDestroyed.subscribe((value: boolean) => {
      if (isDebug) {
        console.log(`isDestroyed subscriber`);
        console.log(value);
      }

      if (value) {
        if (isDebug) {
          console.log(`destroyed modal ref.`);
        }

        this._activeModal = null;
      }
    });
  }

  public updateFilter(value) {
    this.liveChatService.activeFilter = value;
  }

  public toggleAssignmentToConversationForAgent(conversation: Conversation) {
    this.liveChatService.toggleAssignmentToConversationForAgent(conversation);
  }

  public updateLiveChatStatus(value) {
    this.isLiveChatActiveChange.emit(value);
  }
}
