import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TopPostListComponent } from './top-post-list.component';
import { OutboxPostModule } from '../outbox-post/outbox-post.module';

import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  declarations: [TopPostListComponent],
  imports: [
    CommonModule,
    FormsModule,
    OutboxPostModule,
    DropdownSelect2Module,
    BigNumberModule,
    TooltipModule
  ],
  exports: [TopPostListComponent]
})
export class TopPostListModule {}
