import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InsightsPostsComponent } from './insights-posts.component';
import { InsightsPostModule } from './insights-post/insights-post.module';

import { PaginatorModule } from '../../../../../../common/components/paginator';
import { NoResultsModule } from '../../../../../../common/components/no-results/no-results.module';

import { LoaderModule } from '../../../../../../common/directives';
import { ObserveWidthAndHeightModule } from '../../../../../../common/directives';

@NgModule({
  imports: [
    CommonModule,
    PaginatorModule,
    NoResultsModule,
    InsightsPostModule,
    LoaderModule,
    ObserveWidthAndHeightModule
  ],
  exports: [InsightsPostsComponent],
  declarations: [InsightsPostsComponent]
})
export class InsightsPostsModule {}
