<div class="top-times-to-post-container">

  <div class="section-head">
    <h2>
      <span
        [ssiTooltip]="'THIS_GRAPH_SHOWS_THE_BEST_TIMES_TO_POST_BASED_ON_THE_LEVEL_OF_ENGAGEMENT_ON_YOUR_PREVIOUS_POSTS_THE_POST_WITH_THE_MOST_ENGAGEMENT_IN_THE_REPORTED_TIME_PERIOD_WILL_HAVE_A_SCORE_OF_100_WITH_ALL_OTHER_POSTS_SCORED_AS_A_PERCENTAGE_OF_THIS_PEAK_ITS_SIMPLE_ONCE_YOU_KNOW' | translate"
        tooltipWindowClass="tooltip-chunky-big"
      >
        Top times to post
      </span>
      <i
        class="ssi ssi-help"
        [ssiTooltip]="'THIS_GRAPH_SHOWS_THE_BEST_TIMES_TO_POST_BASED_ON_THE_LEVEL_OF_ENGAGEMENT_ON_YOUR_PREVIOUS_POSTS_THE_POST_WITH_THE_MOST_ENGAGEMENT_IN_THE_REPORTED_TIME_PERIOD_WILL_HAVE_A_SCORE_OF_100_WITH_ALL_OTHER_POSTS_SCORED_AS_A_PERCENTAGE_OF_THIS_PEAK_ITS_SIMPLE_ONCE_YOU_KNOW' | translate"
        tooltipWindowClass="tooltip-chunky-big"
      ></i>
    </h2>
    <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
  </div>

  <div class="section-body"
       ssiLoader
       [ssiLoaderVisible]="loading">
    <div class="top-row">
      <div class="best-hours-container">
        <div class="left">
          <span>Best times to post</span>
        </div>
        <div class="middle">
          <div class="hours">
            <span *ngFor="let hour of statsToDisplay.topHours | slice:0:4; let i = index;">{{hour.label + (i === 3 ? ' ' : ', ')}}</span>
          </div>
          <div>
            <span>on days shown below</span>
          </div>
        </div>
        <div class="right">
          <i class="ssi ssi-clock"></i>
        </div>
      </div>

      <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                             [multiple]="true"
                             [selectedMin]="1"
                             [toggleAllEnabled]="selectedHeatmapStats.length < heatmapStatsIterable.length"
                             [toggleAllLabel]="'SELECT_ALL' | translate"
                             [xPlacement]="'right'"
                             [options]="heatmapStatsIterable"
                             [(ngModel)]="selectedHeatmapStats"
                             (ngModelChange)="onHeatmapStatsChange($event)">
        <ng-template ssiTemplateSelector="headButtonLabel"
                     let-expanded="expanded">
          <span *ngIf="selectedHeatmapStats.length === heatmapStatsIterable.length">Orlo Rating</span>
          <span *ngIf="selectedHeatmapStats.length !== heatmapStatsIterable.length">{{selectedHeatmapStats.length}} {{'SELECTED' | translate}}</span>
        </ng-template>
      </ssi-dropdown-select-2>
    </div>

    <ssi-highchart [config]="chartConfig"></ssi-highchart>

    <div class="chart-legend">
      <div class="legend-item">
        <span>Estimated success rate:</span>
      </div>
      <div class="legend-item great">
        <div class="circle"></div>
        <span>Great</span>
      </div>
      <div class="legend-item good">
        <div class="circle"></div>
        <span>Good</span>
      </div>
      <div class="legend-item okay">
        <div class="circle"></div>
        <span>Okay</span>
      </div>
      <div class="legend-item not-great">
        <div class="circle"></div>
        <span>Not great</span>
      </div>
      <div class="legend-item not-good">
        <div class="circle"></div>
        <span>Not good</span>
      </div>
    </div>
  </div>
</div>