import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { StreamBuilderComponent } from './stream-builder.component';
import { StreamBuilderService } from './stream-builder.service';

import { DescriptionStepComponent } from './description-step/description-step.component';
import { QueryBuilderStepComponent } from './query-builder-step/query-builder-step.component';
import { FinaliseStepComponent } from './finalise-step/finalise-step.component';

import { OrloComponentsModule } from 'orlo-components';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleSwitchModule } from '../../../../common/components/toggle-switch/toggle-switch.module';
import { RoundedBoxCheckModule } from '../../../../common/components/rounded-box-check/rounded-box-check.module';
import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { ContextMenuModule } from '../../../../common/components/context-menu/context-menu.module';
import { ConfirmActionModule } from '../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { TemplateSelectorModule } from '../../../../common/directives/template-selector/template-selector.module';
import { MultiSelectChipsModule } from '../common/components/multi-select-chips/multi-select-chips.module';

import { QueryBuilderConditionComponent } from './query-builder-step/query-builder-condition/query-builder-condition.component';
import { BranchClassModule } from '../common/pipes/branch-class/branch-class.module';
import { HumanQueryModule } from '../common/pipes/human-query/human-query.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.monitoringStreamBuilder',
          url: '/monitoring/stream-builder',
          component: StreamBuilderComponent,
          resolve: StreamBuilderComponent.resolve
        },
        {
          name: 'auth.monitoringStreamBuilder.desc',
          url: '/description',
          component: DescriptionStepComponent
        },
        {
          name: 'auth.monitoringStreamBuilder.query',
          url: '/query-builder',
          component: QueryBuilderStepComponent
        },
        {
          name: 'auth.monitoringStreamBuilder.finalise',
          url: '/finalise',
          component: FinaliseStepComponent
        }
      ]
    }),
    OrloComponentsModule,
    TranslateModule,
    BranchClassModule,
    HumanQueryModule,
    ToggleSwitchModule,
    RoundedBoxCheckModule,
    DropdownSelect2Module,
    ContextMenuModule,
    ConfirmActionModule,
    TemplateSelectorModule,
    MultiSelectChipsModule
  ],
  declarations: [
    StreamBuilderComponent,
    DescriptionStepComponent,
    QueryBuilderStepComponent,
    FinaliseStepComponent,
    QueryBuilderConditionComponent
  ],
  providers: [StreamBuilderService],
  entryComponents: [StreamBuilderComponent]
})
export class StreamBuilderModule {}
