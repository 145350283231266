import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { OutboxPostModalComponent } from './outbox-post-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { PostModule } from '../../../../../../../common/components/post/post.module';
import { StatLineModule } from '../../../../../../../common/components/stat-line/stat-line.module';
import { NotesModule } from '../../../../validate-posts/validate-posts-box/validate-posts-notes/notes/notes.module';
import { TooltipModule } from '../../../../../../../common/directives/tooltip/tooltip.module';
import { FallbackImageModule } from '../../../../../../../common/directives/fallback-image/fallback-image.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule,
    PostModule,
    StatLineModule,
    NotesModule,
    TooltipModule,
    FallbackImageModule
  ],
  declarations: [OutboxPostModalComponent],
  exports: [OutboxPostModalComponent],
  entryComponents: [OutboxPostModalComponent]
})
export class OutboxPostModalModule {}
