<div
  class="sentiment-option sentiment"
  *ngFor="let option of sentiment; trackBy:trackByValue"
  [class.active]="selectedValues[option.value]"
  [ngClass]="selectedValues[option.value] ? option.sentiment.colorClass : ''"
  (click)="toggleValue(option)"
  [ngbPopover]="option.sentiment.translateKey | translate"
  triggers="mouseenter mouseleave"
  popoverClass="popover-dark"
  placement="top">
  <i [ngClass]="option.sentiment.iconClass"></i>
</div>