import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlToText'
})
export class HtmlToTextPipe implements PipeTransform {
  // transform(value): string {
  // const tempElm = document.createElement('div');
  // tempElm.innerHTML = value;
  // return tempElm.textContent || tempElm.innerText || '';
  transform(str: string): any {
    return (str || '')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, `'`)
      .replace(/&amp;/g, '&');
  }
  // }
}
