import { Directive, ElementRef, HostListener } from '@angular/core';
import { EmojiFormControlContainerDirective } from '../emoji-form-control-container.directive';

@Directive({
  selector: 'textarea[ssiEmojiFormControl]'
})
export class EmojiFormControlDirective {
  emojiFormControlContainer: EmojiFormControlContainerDirective;

  constructor(private elm: ElementRef) {}

  @HostListener('blur')
  onBlur() {
    this.emojiFormControlContainer.caretPosition = this.elm.nativeElement.selectionStart;
  }
}
