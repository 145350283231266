import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { StreamCardComponent } from './stream-card.component';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule,
    TooltipModule
  ],
  declarations: [StreamCardComponent],
  exports: [StreamCardComponent]
})
export class StreamCardModule {}
