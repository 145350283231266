import './total-change.component.scss';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BigNumberPipe } from '../../pipes/big-number/big-number.pipe';
import { CompanyService } from '../../services/company/company.service';
import { SecondsToHumanTimePipe } from '../../pipes/seconds-to-human-time/seconds-to-human-time.pipe';

@Component({
  selector: 'ssi-total-change',
  templateUrl: './total-change.component.html'
})
export class TotalChangeComponent implements OnInit, OnChanges {
  @Input() currentTotal: number;
  @Input() previousTotal: number;
  @Input() increaseTranslationKey?: string;
  @Input() decreaseTranslationKey?: string;
  @Input() noChangeTranslationKey?: string;
  @Input() changeValueKey?: string;
  @Input() showAsPercentageOf?: { current: number; previous: number };
  @Input() bigNumber?: boolean;
  @Input() round?: boolean;
  @Input() delta?: boolean;
  @Input() currency?: boolean;
  @Input() time?: boolean;
  @Input() lowerIsPositive?: boolean;

  totalChange: any;
  text: string;
  colorClass: string;

  constructor(
    private company: CompanyService,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private bigNumberPipe: BigNumberPipe,
    private secondsToHumanTimePipe: SecondsToHumanTimePipe
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.currentTotal || changes.previousTotal) &&
      typeof this.currentTotal !== 'undefined' &&
      typeof this.previousTotal !== 'undefined'
    ) {
      let previousValue = this.previousTotal;
      let currentValue = this.currentTotal;

      if (this.showAsPercentageOf) {
        // compare the percentage change NOT the value change as the value changed
        // might have increased but the percentage has decreased
        const currentTotalPercentage = (currentValue =
          this.currentTotal / this.showAsPercentageOf.current);
        const previousTotalPercentage = (previousValue =
          this.previousTotal / this.showAsPercentageOf.previous);

        let percentageDiff =
          Math.abs(currentTotalPercentage - previousTotalPercentage) * 100;
        if (isNaN(previousTotalPercentage)) {
          // if there is no previous value then assume the diff is 100%
          percentageDiff = 100;
        }
        this.totalChange =
          this.decimalPipe.transform(percentageDiff, '1.2-3') + '%';
      } else {
        this.totalChange = Math.abs(this.currentTotal - this.previousTotal);
        if (typeof this.round !== 'undefined') {
          const decimalPlaces = typeof this.round === 'number' ? this.round : 2;

          this.totalChange = this.totalChange.toFixed(decimalPlaces);
          this.totalChange = +this.totalChange; // '0.0' -> 0
        }

        if (this.bigNumber) {
          this.totalChange = this.bigNumberPipe.transform(this.totalChange);
        } else if (this.currency) {
          this.company.getCurrency().then((currency) => {
            this.totalChange = this.currencyPipe.transform(
              +this.totalChange,
              currency.symbol
            );
          });
        } else if (this.time) {
          this.totalChange = this.secondsToHumanTimePipe.transform(
            this.totalChange
          );
        }
      }

      const translateValues = {
        [this.changeValueKey]: `<strong>${this.totalChange}</strong>`
      };

      const colorClasses = {
        positive: this.delta ? 'delta-positive' : 'text-success',
        negative: this.delta ? 'delta-negative' : 'text-total-change-negative'
      };

      if (this.lowerIsPositive) {
        const { positive, negative } = colorClasses;
        colorClasses.negative = `${positive} delta-inverse`;
        colorClasses.positive = `${negative} delta-inverse`;
      }

      let translationKey;
      if (currentValue > previousValue) {
        this.colorClass = colorClasses.positive;
        translationKey = this.increaseTranslationKey;
      } else if (currentValue < previousValue) {
        this.colorClass = colorClasses.negative;
        translationKey = this.decreaseTranslationKey;
      } else {
        this.colorClass = 'text-muted';
        translationKey = this.noChangeTranslationKey;
      }

      // translation keys are optional. For example we dont always display text when there is no change
      if (translationKey) {
        this.text = this.translate.instant(translationKey, translateValues);
      } else {
        this.text = '';
      }
    }
  }
}
