import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TotalStatCardComponent } from './total-stat-card.component';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';

@NgModule({
  imports: [CommonModule, BigNumberModule],
  declarations: [TotalStatCardComponent],
  exports: [TotalStatCardComponent]
})
export class TotalStatCardModule {}
