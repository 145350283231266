import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageUserPermissionsComponent } from './manage-user-permissions.component';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterModule } from '../../../../../common/pipes/filter/filter.module';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';
import { PermissionsModule } from '../../../../../common/components/permissions/permissions.module';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FilterModule,
    SortByModule,
    PermissionsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageUserPermissions',
          url: '/manage/users/:userId/permissions',
          component: ManageUserPermissionsComponent,
          data: {
            permissions: {
              company: 'administer_users'
            }
          }
        }
      ]
    })
  ],
  declarations: [ManageUserPermissionsComponent],
  providers: [],
  exports: [ManageUserPermissionsComponent]
})
export class ManageUserPermissionsModule {}
