import './validate-posts-dissaprove.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-validate-posts-dissaprove',
  templateUrl: './validate-posts-dissaprove.component.html',
  styles: []
})
export class ValidatePostsDissaproveComponent {
  reasoning: string;
  disapprovalTags: { label: string; value: string }[];
  selectedTags = [];
  constructor(public modalInstance: NgbActiveModal) {}

  onSelectedTagsChange(tags: any[]) {
    this.selectedTags = tags;
  }

  removeIncludedTag(tag) {
    this.selectedTags = this.selectedTags.filter((iTag) => iTag.id !== tag.id);
  }
}
