import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { SamlComponent } from './saml.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'saml',
          url: '/saml/:jwt',
          component: SamlComponent
        }
      ]
    })
  ],
  declarations: [SamlComponent],
  exports: [SamlComponent]
})
export class SamlModule {}
