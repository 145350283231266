<div class="export-messages">
    <div class="exit" (click)="activeModal.close(null)">
        <span><i class="ssi ssi-times"></i></span>
    </div>
    <div class="header">
        <i class="ssi ssi-forward"></i>
        <h2>Attach messages</h2>
        <p class="info">Simply select the messages you would like to attach to your CRM:</p>
    </div>

    <div class="scroll-container custom-scrollbar">
        <div *ngFor="let activity of threadActivities">
            <div class="message-box" (click)="selectActivity(activity)" [ngClass]="{'message-box-self-penned':isOutboundMessage(activity),'selected':isActivitySelected(activity)}">
            <div class="message-box-container">
                <div class="message-box-message-wrap">
                <div class="message-box-avatar-wrap">
                    <img class="message-box-avatar" [src]="activity.author.avatar" >
                </div>

                <div class="message-box-message" [innerHtml]="parseMessage(activity.interaction.original_content) | sanitizedHtml">
                    
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="buttons">
            <orlo-button
              textColor="#43537F"
              state="text"
              text="Cancel"
              textClass="uppercase"
              (btnClick)="activeModal.close(null)"
            ></orlo-button>
            <p *ngIf="selectedActivities.length > 0">
                {{selectedActivities.length}} message{{selectedActivities.length === 1 ? '' : 's'}} selected
            </p>
        </div>
        <div class="buttons">
            <div *ngFor="let action of pluginMessageActions">
                <orlo-button
                    state="primary"
                    [text]="action.name"
                    textClass="uppercase"
                    (btnClick)="exportMessagesToPlugin(action)"
                ></orlo-button>
            </div>
        </div>
    </div>
</div>