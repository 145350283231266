import './live-chat-assign-agent.component.scss';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatAssignAgentComponent } from './live-chat-assign-agent.component';
import { FormsModule } from '@angular/forms';
import { ButtonComponent, OrloComponentsModule } from 'orlo-components';

@NgModule({
  declarations: [LiveChatAssignAgentComponent],

  exports: [LiveChatAssignAgentComponent],

  imports: [CommonModule, FormsModule, OrloComponentsModule, TranslateModule]
})
export class LiveChatAssignAgentModule {}
