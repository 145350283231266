<div class="dashboard-widget dashboard-widget-social-wall">

  <div
    class="form-container"
    [class.box-shadowed]="profiles?.length > 0 || feedItems?.length > 0"
  >
    <div class="form-field">
      <label class="field-title">INTERACTING ACCOUNT</label>
      <div class="input-wrapper">
        <ssi-dropdown-select-2
          name="accounts"
          class="accounts-selector"
          size="lg"
          [required]="true"
          [canDeselect]="true"
          [filterable]="true"
          [filterPlaceholder]="'Select an account to search & interact from...'"
          [options]="accounts"
          [(ngModel)]="selectedAccount"
          (ngModelChange)="accountSelected()"
        >
          <ng-template
            ssiTemplateSelector="headButtonLabel"
            let-expanded="expanded"
          >
            <div
              *ngIf="selectedAccount"
              class="select-accounts-label"
            >
              <ssi-social-network-icon
                *ngIf="selectedAccount?.account_type_id"
                [accountTypeId]="selectedAccount.account_type_id"
                [size]="20"
              ></ssi-social-network-icon>
              <span>{{selectedAccount?.name}}</span>
            </div>
            <div
              *ngIf="!selectedAccount"
              class="select-accounts-label"
            >
              <span>Select an account to search & interact from...</span>
            </div>
          </ng-template>
          <ng-template
            ssiTemplateSelector="optionIcon"
            let-account="option"
          >
            <ssi-social-network-icon
              [accountTypeId]="account.account_type_id"
              [size]="20"
            ></ssi-social-network-icon>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>

    <div
      class="form-field"
      *ngIf="selectedAccount?.id"
    >
      <ssi-tags-input-2
        [tags]="searchText"
        placeholder="Enter user handle..."
        [showSubmit]="true"
        (onSubmit)="onTagChange($event)"
        [disabled]="!!activeSearchText"
        [disableOnBlur]="true"
        (onTagsChange)="searchText = $event; onTagChange($event)"
      >
      </ssi-tags-input-2>
    </div>
  </div>

  <div
    class="message-info view-{{ viewClass }}"
    *ngIf="!selectedAccount"
  >
    <i class="ssi ssi-select-microcopy"></i>
    <h5>Select an account</h5>
    <p class="view-{{ viewClass }}">Choose the X or Facebook account you want to use to run the search & interact from.
    </p>
  </div>
  <ng-container *ngIf="selectedAccount && !searchingProfiles && !profiles">
    <div class="message-info">
      <i class="ssi ssi-search-handle"></i>
      <h5>Search for a user handle</h5>
      <p>This account will be used to find and interact with profiles</p>
    </div>
  </ng-container>
  <ng-container
    *ngIf="selectedAccount && !searchingProfiles && searchText && (profiles?.length === 0 || feedItems?.length === 0)"
  >
    <div class="message-info">
      <i class="ssi ssi-no-results-option"></i>
      <h5>Oops no results...</h5>
      <p>Ensure you have the correct handle typed or the right account</p>
    </div>
  </ng-container>


  <div
    class="profiles-list"
    *ngIf="selectedAccount && profiles && profiles.length && currentView === 'profile'"
  >
    <div
      class="profile-container"
      *ngFor="let profile of profiles"
    >
      <div class="profile-box">
        <img
          [src]="profile.image_url"
          alt=""
        >
        <span class="name">{{profile.name}}</span>
        <span class="username">@{{profile.username}}</span>
        <span class="location"><i class="ssi ssi-location-filter"></i>
          {{profile.location ? profile.location : 'No location data found'}}</span>
        <p class="description">{{profile.description ? profile.description : 'No profile description'}}</p>
        <button (click)="loadFeed(profile)"><i class="ssi ssi-social-wall"></i>Load Feed</button>
      </div>


    </div>

    <button
      class="load-more-button"
      (click)="search(nextPage)"
      translate="LOAD_MORE"
    ></button>
  </div>

  <ng-container *ngIf="selectedAccount && searchingProfiles">
    <div class="message-info">
      <ssi-spinner
        [visible]="true"
        [size]="40"
      ></ssi-spinner>
      <h5>Hang tight</h5>
      <p>We're just searching through connected {{selectedAccount.accountTypeLabel}} profiles</p>
    </div>
  </ng-container>

  <div
    *ngIf="selectedAccount && feedItems && feedItems.length && currentView === 'feed'"
    class="article-grid view-{{ viewClass }}"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    [infiniteScrollDisabled]="feedItems[feedItems.length - 1].id === viewPortFeedItems[viewPortFeedItems.length - 1]?.id"
    [scrollWindow]="false"
    (scrolled)="onScrolled()"
    ssiVirtualScroll
    [items]="feedItems"
    [bufferAmount]="5"
    [getItemHeight]="getHeight"
    (viewPortItemsUpdated)="viewPortFeedItemsUpdated($event)"
  >
    <ssi-stream-post
      *ngFor="let post of viewPortFeedItems"
      [stream]="post"
      [account]="selectedAccount"
      [accountId]="selectedAccount.id"
      [accountTypeLabel]="profile.account.accountTypeLabel"
      [accountTypeId]="profile.account.account_type_id"
      [variant]="'profileSearch'"
    >
    </ssi-stream-post>

    <button
      class="load-more-button"
      (click)="getFeedNextPage()"
      translate="LOAD_MORE_POSTS"
    ></button>
  </div>

  <ng-container *ngIf="selectedAccount && searchingFeed">
    <div class="message-info">
      <ssi-spinner
        [visible]="true"
        [size]="40"
      ></ssi-spinner>
      <h5>Hang tight</h5>
      <p>We're just loading the feed for selected profile</p>
    </div>
  </ng-container>

</div>