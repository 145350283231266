import { NgModule } from '@angular/core';
import { AuthModule } from './auth/auth.module';
import { EntryModule } from './entry/entry.module';
import { SamlModule } from './saml/saml.module';
import { ExternalModule } from './external/external.module';
import { DiagnosticsModule } from './diagnostics/diagnostics.module';

@NgModule({
  imports: [
    AuthModule,
    EntryModule,
    ExternalModule,
    SamlModule,
    DiagnosticsModule
  ]
})
export class ModulesModule {}
