import {
  Component,
  Inject,
  ContentChildren,
  QueryList,
  Output,
  Input,
  OnInit,
  AfterContentInit,
  forwardRef
} from '@angular/core';

import { TemplateSelectorDirective } from '../../../directives/template-selector/template-selector.directive';
import { mapToIterable } from '../../../utils';

import { PaginationTemplates } from './pagination-templates';

@Component({
  selector: 'ssi-pagination',
  template: ``
})
export class PaginationComponent implements OnInit, AfterContentInit {
  @Input() itemsPerPage = 10;
  @Input() itemsPerPageOptions?: number[];
  @Input() pageLinksSize = 7;

  @Input() templateRefs: any = {};

  @ContentChildren(TemplateSelectorDirective)
  templateList: QueryList<TemplateSelectorDirective>;

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    this._collectTemplateRefs();
  }

  private _collectTemplateRefs(): void {
    this.templateList.toArray().forEach((t: TemplateSelectorDirective) => {
      if (!PaginationTemplates[t.selector]) {
        console.warn(
          `Unknown template type: ${
            t.selector
          }. Possible value/s: ${mapToIterable(PaginationTemplates).join(
            ', '
          )}.`
        );
        return;
      }

      this.templateRefs[t.selector] = t.templateRef;
    });
  }
}
