<div class="campaigns-list-header">
  <h1 translate="CAMPAIGNS"></h1>
  <div class="main-actions">
    <ssi-menu-item (click)="openAddCampaign()"
                   [label]="'NEW_CAMPAIGN' | translate"
                   iconClass="ssi-addsmall"></ssi-menu-item>
  </div>
</div>
<div>
  <div class="edit-create-campaign"
       *ngIf="editing || creating">
    <form *ngIf="campaignForm"
          #form="ngForm">
      <div class="form-field">
        <label translate="CAMPAIGN_NAME"></label>
        <input [ssiFocusOn]="true"
               #name
               type="text"
               class="orlo-text-input"
               [class.invalid]="name.errors?.required"
               [placeholder]="'NAME_YOUR_CAMPAIGN' | translate"
               [(ngModel)]="campaignForm.name"
               name="name"
               required />
      </div>

      <div class="form-field">
        <label translate="PARENT"></label>
        <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                               [options]="parentCampaignsOptions"
                               [(ngModel)]="campaignForm.parentCampaign"
                               name="parentCampaign"
                               (ngModelChange)="onParentCampaignSelect($event)"></ssi-dropdown-select-2>
      </div>

      <div class="form-field">
        <label translate="START_DATE"></label>
        <div class="input-wrapper"
             #startDateContainer>
          <input mwlFlatpickr
                 class="date-picker-input"
                 type="text"
                 name="startDate"
                 [(ngModel)]="campaignForm.started_at"
                 (ngModelChange)="onStartDatePicked($event)"
                 [altInput]="true"
                 [convertModelValue]="true"
                 dateFormat="Y-m-d H:i"
                 altFormat="M J Y"
                 [minDate]="campaignForm.parent_id && campaignForm?.parentCampaign?.started_at ? campaignForm?.parentCampaign?.started_at : null"
                 [time24hr]="true"
                 [appendTo]="startDateContainer.nativeElement"
                 [placeholder]="'SELECT' | translate"
                 [required]="true">
        </div>
      </div>
      <div class="form-field">
        <label translate="END_DATE"></label>
        <div class="input-wrapper"
             #endDateContainer>
          <input mwlFlatpickr
                 class="date-picker-input"
                 type="text"
                 name="endDate"
                 [(ngModel)]="campaignForm.closed_at"
                 (ngModelChange)="onEndDatePicked($event)"
                 [altInput]="true"
                 [convertModelValue]="true"
                 dateFormat="Y-m-d H:i"
                 altFormat="M J Y"
                 [minDate]="campaignForm.started_at"
                 [maxDate]="campaignForm.parent_id && campaignForm?.parentCampaign?.closed_at ? campaignForm?.parentCampaign?.closed_at : null"
                 [time24hr]="true"
                 [appendTo]="endDateContainer.nativeElement"
                 [placeholder]="'THIS_IS_OPTIONAL' | translate">
        </div>
      </div>
      <div class="form-actions-container">
        <div class="form-action">
          <orlo-button state="primary"
                       [text]="'SAVE' | translate"
                       (btnClick)="saveEdit()"
                       type="submit"
                       [disabled]="form.invalid"></orlo-button>
        </div>
        <div class="form-action">
          <orlo-button state="primary"
                       [bgColor]="'#F8F9FD'"
                       [textColor]="'#14BAE3'"
                       [text]="'CANCEL' | translate"
                       (btnClick)="editing = false; creating = false"
                       type="button"></orlo-button>
        </div>
      </div>

    </form>

    <!-- <ng-template #parentCampaignsOptionTemplate let-campaign="option" let-isSelected="isSelected">
      <div class="d-flex" *ngIf="!campaign.parent_id">
        <div>
          {{ campaign.name }}
        </div>
        <div class="flex-1 text-right" *ngIf="isSelected">
          <i class="ssi ssi-tick"></i>
        </div>
      </div>
      <div
        class="publisher-landing-dropdown-item-indent"
        [class.underline]="!isSelected"
        *ngIf="campaign.parent_id">
        <i
          class="fa"
          [class.fa-circle-thin]="!isSelected"
          [class.fa-circle]="isSelected">
        </i>{{ campaign.name }}
      </div>
    </ng-template> -->
  </div>
  <div class="heading-info-container">
    <div class="page-description">
      <div class="text">
        <h2 translate="WELCOME_TO_CAMPAIGN_REPORTS"></h2>
        <p translate="CAMPAIGNS_LANDING_GUIDE_DESCRIPTION_SUBCOPY"></p>
      </div>
      <img src="https://www.orlo.app/assets/v2/img/campaigns-header-person-note.png"
           alt="A person sitting at a desk">
    </div>
    <div class="campaigns-overview-container">
      <div class="campaigns-stats-card">
        <div class="upper">
          <h4 translate="LIVE_CAMPAIGNS"></h4>
          <i class="ssi ssi-live-campaigns live"></i>
        </div>
        <span>{{campaigns.live.length}}</span>
      </div>
      <div class="campaigns-stats-card">
        <div class="upper">
          <h4 translate="COMPLETED_CAMPAIGNS"></h4>
          <i class="ssi ssi-emoji-flag completed"></i>
        </div>
        <span>{{campaigns.completed.length}}</span>
      </div>
    </div>
  </div>

  <div class="tabs-container">
    <div class="tabs">
      <div class="live"
           [class.active]="activeCampaignsTab === 'live'"
           (click)="changeTab('live')"
           translate="LIVE_CAMPAIGNS"></div>
      <div class="completed"
           [class.active]="activeCampaignsTab === 'completed'"
           (click)="changeTab('completed')"
           translate="COMPLETED_CAMPAIGNS"></div>
    </div>
    <div class="table-container">
      <ssi-treetable *ngIf="activeCampaignsTab === 'live'"
                     [data]="campaignsToShow"
                     [bodyStyle]="{'max-height': ''}"
                     [rowStyle]="{'height': '68px'}"
                     [virtualScroll]="false"
                     [resizeMode]="'fit'"
                     [reorderable]="false"
                     [saveSettings]="true"
                     [settingsStorageKey]="'treetable-campaign-analytics-list-live'"
                     [globalFilterInputRef]=""
                     [templateRefs]="{noResults: noResultsTemplate}"
                     id="treetable-campaign-analytics-list-live">
        <ssi-column [header]="'NAME' | translate"
                    [sortable]="false"
                    field="name"
                    width="30%"
                    [templateRefs]="{bodyCell: nameCellTemplate, headCell: nameHeadCellTemplate}">
        </ssi-column>
        <ssi-column [header]="'STARTS' | translate"
                    [sortable]="false"
                    field="started_at"
                    [templateRefs]="{bodyCell: dateCellTemplate}"></ssi-column>
        <ssi-column [header]="'ENDS' | translate"
                    [sortable]="false"
                    field="closed_at"
                    [templateRefs]="{bodyCell: dateCellTemplate}"></ssi-column>
        <ssi-column [header]="'DATE_CREATED' | translate"
                    [sortable]="false"
                    field="created_at"
                    [templateRefs]="{bodyCell: dateCellTemplate}"></ssi-column>
        <ssi-column [header]="'DAYS_LIVE' | translate"
                    [sortable]="false"
                    field="age_days"
                    [templateRefs]="{bodyCell: daysLiveCellTemplate}"></ssi-column>

        <ssi-column header=""
                    [sortable]="false"
                    [field]="' '"
                    width="30%"
                    [templateRefs]="{bodyCell: liveActionsCellTemplate}"></ssi-column>

        <ssi-pagination [itemsPerPage]="10"
                        [pageLinksSize]="5"></ssi-pagination>
      </ssi-treetable>

      <ssi-treetable *ngIf="activeCampaignsTab === 'completed'"
                     [data]="campaignsToShow"
                     [bodyStyle]="{'max-height': ''}"
                     [rowStyle]="{'height': '68px'}"
                     [virtualScroll]="false"
                     [resizeMode]="'fit'"
                     [reorderable]="false"
                     [saveSettings]="true"
                     [settingsStorageKey]="'treetable-campaign-analytics-list-completed'"
                     [globalFilterInputRef]=""
                     [templateRefs]="{noResults: noResultsTemplate}"
                     id="treetable-campaign-analytics-list-completed">
        <ssi-column [header]="'NAME' | translate"
                    [sortable]="false"
                    field="name"
                    width="30%"
                    [templateRefs]="{bodyCell: nameCellTemplate, headCell: nameHeadCellTemplate}">
        </ssi-column>
        <ssi-column [header]="'STARTS' | translate"
                    [sortable]="false"
                    field="started_at"
                    [templateRefs]="{bodyCell: dateCellTemplate}"></ssi-column>
        <ssi-column [header]="'ENDS' | translate"
                    [sortable]="false"
                    field="closed_at"
                    [templateRefs]="{bodyCell: dateCellTemplate}"></ssi-column>
        <ssi-column [header]="'DATE_CREATED' | translate"
                    [sortable]="false"
                    field="created_at"
                    [templateRefs]="{bodyCell: dateCellTemplate}"></ssi-column>
        <ssi-column [header]="'DAYS_LIVE' | translate"
                    [sortable]="false"
                    field="age_days"
                    [templateRefs]="{bodyCell: daysLiveCellTemplate}"></ssi-column>

        <ssi-column header=""
                    [sortable]="false"
                    [field]="' '"
                    width="30%"
                    [templateRefs]="{bodyCell: completedActionsCellTemplate}"></ssi-column>

        <ssi-pagination [itemsPerPage]="10"
                        [pageLinksSize]="5"></ssi-pagination>
      </ssi-treetable>
    </div>

    <ng-template #nameCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData">
      <div class="table-cell campaign-name"
           [class.parent]="!rowData.parent_id">
        <ng-container *ngIf="!rowData.parent_id">
          <i class="ssi ssi-campaign"></i>
          <span>{{rowData.name}}</span>
        </ng-container>
        <ng-container *ngIf="rowData.parent_id">
          <span><small>└────</small> <i class="ssi ssi-child-campaign"></i> {{rowData.name}}</span>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #nameHeadCellTemplate
                 ssiTemplateSelector="headCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData">
      <div class="table-head-cell"
           (mousedown)="$event.stopPropagation()">
        <div class="filter-container"
             [class.extend-width]="activeCampaignsTab === 'completed'">
          <input type="search"
                 class="form-control form-control-minimal filter"
                 [placeholder]="'Search ' + activeCampaignsTab + ' campaigns...'"
                 [(ngModel)]="searchTerm"
                 (input)="onFilterInput($event)">
          <i class="ssi ssi-search-small"
             [class.active]="searchTerm && campaignsToShow.length"></i>
        </div>
      </div>
    </ng-template>

    <ng-template #dateCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-fieldValue="fieldValue">
      <div class="table-cell date-cell"
           [class.parent]="!rowData.parent_id">
        <ng-container *ngIf="fieldValue">
          <span class="date-value">{{fieldValue | date:'MMM d, y'}}</span>
        </ng-container>
        <ng-container *ngIf="!fieldValue">
          <span class="no-date"
                translate="NO_DATE_SET"></span>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #daysLiveCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-fieldValue="fieldValue">
      <div class="table-cell date-cell"
           [class.parent]="!rowData.parent_id">
        <ng-container *ngIf="fieldValue">
          <span class="date-value">{{fieldValue}}</span>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #liveActionsCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData">
      <div class="table-cell actions-cell"
           [class.parent]="!rowData.parent_id">
        <ng-container *ngIf="activeCampaignsTab === 'live'">
          <orlo-button state="primary"
                       [text]="'VIEW' | translate"
                       leadingIcon="ssi ssi-view-report"
                       uiSref="^.build"
                       [uiParams]="{campaignId: rowData.id, name: rowData.name}"></orlo-button>
          <orlo-button state="primary"
                       [text]="'EDIT' | translate"
                       leadingIcon="ssi ssi-correctedit"
                       textColor="#43537F"
                       bgColor="#FFF"
                       (btnClick)="openEditCampaign(rowData)"></orlo-button>
          <orlo-button *ngIf="canManageCampaign && ! rowData.is_closed"
                       state="primary"
                       [text]="'FINISH' | translate"
                       leadingIcon="ssi ssi-emoji-flag"
                       bgColor="#F0B427"
                       (btnClick)="showFinishCampaignTooltip(rowIndex)"
                       #finishCampaignTooltip="ssiTooltip"
                       [ssiTooltip]
                       [ssiTooltipId]="rowIndex"
                       tooltipWindowClass="confirm-action-tooltip-window"
                       tooltipPlacement="right"
                       tooltipTrigger="manual"
                       [tooltipTemplate]="finishCampaignTooltipTemplate"></orlo-button>
          <orlo-button *ngIf="canManageCampaign && rowData.is_closed"
                       state="primary"
                       [text]="'GO_LIVE' | translate"
                       leadingIcon="ssi ssi-go-live"
                       textColor="#fff"
                       bgColor="#B2C614"
                       (btnClick)="reopenCampaign(rowData)"></orlo-button>
        </ng-container>
      </div>

      <ng-template #finishCampaignTooltipTemplate>
        <ssi-confirm-action (ssiOffClick)="closeAllFinishCampaignTooltips()"
                            [title]="'CAMPAIGN_FINISH_CONFIRMATION_PROMPT' | translate"
                            dismissText="Cancel"
                            confirmText="Finish"
                            confirmTextColour="#14BAE3"
                            dismissTextColour="#43537f"
                            (onDismiss)="hideFinishCampaignTooltip(rowIndex)"
                            (onConfirm)="finishCampaign(rowData)"></ssi-confirm-action>
      </ng-template>
    </ng-template>
    <ng-template #completedActionsCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData">
      <div class="table-cell actions-cell"
           [class.parent]="!rowData.parent_id">
        <ng-container *ngIf="activeCampaignsTab === 'completed'">
          <orlo-button state="primary"
                       [text]="'VIEW' | translate"
                       leadingIcon="ssi ssi-view-report"
                       uiSref="^.build"
                       [uiParams]="{campaignId: rowData.id, name: rowData.name}"></orlo-button>
          <orlo-button *ngIf="canManageCampaign && rowData.is_closed"
                       state="primary"
                       [text]="'GO_LIVE' | translate"
                       leadingIcon="ssi ssi-go-live"
                       textColor="#fff"
                       bgColor="#B2C614"
                       (btnClick)="reopenCampaign(rowData)"></orlo-button>
          <orlo-button *ngIf="canManageCampaign"
                       state="primary"
                       [text]="'DELETE' | translate"
                       leadingIcon="ssi ssi-delete"
                       bgColor="#F88C68"
                       (btnClick)="showDeleteCampaignTooltip(rowIndex)"
                       #deleteCampaignTooltip="ssiTooltip"
                       [ssiTooltip]
                       [ssiTooltipId]="rowIndex"
                       tooltipWindowClass="confirm-action-tooltip-window"
                       tooltipPlacement="right"
                       tooltipTrigger="manual"
                       [tooltipTemplate]="deleteCampaignTooltipTemplate"></orlo-button>
        </ng-container>
      </div>

      <ng-template #deleteCampaignTooltipTemplate>
        <ssi-confirm-action (ssiOffClick)="closeAllDeleteCampaignTooltips()"
                            title="Are you sure you want to delete this Campaign permanently?"
                            dismissText="Cancel"
                            confirmText="Delete"
                            confirmTextColour="#F88C68"
                            dismissTextColour="#43537f"
                            (onDismiss)="hideDeleteCampaignTooltip(rowIndex)"
                            (onConfirm)="deleteCampaign(rowData)"></ssi-confirm-action>
      </ng-template>
    </ng-template>

    <ng-template #noResultsTemplate
                 ssiTemplateSelector="noResults">
      <ssi-no-results *ngIf="!!searchTerm"
                      title="No results!"
                      description="How about you try searching through your {{activeCampaignsTab === 'live' ? 'Completed' : 'Live'}} Campaigns?"></ssi-no-results>
    </ng-template>
  </div>
</div>