import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSelectorComponent } from './account-selector.component';
import { FormsModule } from '@angular/forms';
import { SortByModule } from '../../pipes/sort-by/sort-by.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterByStringModule } from '../../pipes/filter-by-string/filter-by-string.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SortByModule,
    NgbDropdownModule,
    FilterByStringModule
  ],
  declarations: [AccountSelectorComponent],
  exports: [AccountSelectorComponent]
})
export class AccountSelectorModule {}
