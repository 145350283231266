<div class="password-input">
  <input
    ngModel
    (ngModelChange)="contentChange($event); checkInput($event)"
    [type]="showPassword ? 'text' : 'password'"
    [placeholder]="placeholder"
    [ngClass]="class"
    class="form-control">
  <i
    class="ssi show-hide"
    [ngClass]="showPassword ? 'ssi-eyeclosed' : 'ssi-eye'"
    (click)="togglePasswordDisplay()">
  </i>
  <p class="help-block" *ngIf="endsWithWhitespace || startsWithWhitespace">
    <span class="text-warning">
      <i class="fa fa-warning"></i>
      <span *ngIf="endsWithWhitespace" translate="YOUR_PASSWORD_ENDS_WITH_A_SPACE"></span>
      <span *ngIf="startsWithWhitespace" translate="YOUR_PASSWORD_STARTS_WITH_A_SPACE"></span>
    </span>
  </p>
</div>
