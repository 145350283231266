<button class="btn btn-style-1 btn-default">
    <i [ngClass]="socialAccount.icon"></i>
    {{socialAccount.name}}
    <!-- <span class="fa fa-chevron-down fa-fw btn-style-1-icon"></span> -->
</button>

<button class="btn btn-default btn-style-1"
        *ngIf="campaignName"
        [ssiTriggerPublish]="{edit: {post: validation}}">
    {{campaignName}}
    <span class="ssi ssi-edit btn-style-1-icon"></span>
</button>

<button class="btn btn-default btn-style-1"
        [ssiTriggerPublish]="{edit: {post: validation}}">
    {{scheduledDate}}
    <span class="ssi ssi-edit btn-style-1-icon"></span>
</button>

<button
  *ngIf="validation.in_reply_to_social_id"
  class="btn btn-default btn-style-1"
  uiSref="auth.twitterThreads"
  [uiParams]="{outboxId: validation.id }"
>
    <span class="btn-style-1-badge">
      <i class="ssi ssi-x-logo"></i>
    </span>
    View Thread
</button>

<button
  *ngIf="validation.conversation_link"
  class="btn btn-default btn-style-1"
  uiSref="auth.inbox"
  [uiParams]="{
    mode: inboxLink.mode,
    conversation: inboxLink.conversation,
    activity: inboxLink.activity,
    reply: 'true'
  }"
  [uiOptions]="{reload: true}"
>
    <span class="btn-style-1-badge">
      <i class="ssi ssi-thread"></i>
    </span>
    View in Inbox
</button>
