
<div [ngClass]="{'campaigns-stats-ad': campaignHasAdvertising}" class="campaigns-stats">
  <div class="campaign-stats-row">
    <div class="campaign-stats-col campaign-stats-col-chart">
      <ssi-highchart [config]="chartOptions"></ssi-highchart>
      <ssi-highchart-controls
        (chartChangeDate)="chartChangeDate($event.timestamp)"
        (chartChangeDateCustom)="chartChangeDateCustom()"
        [now]="now"
        [chartSearchDates]="chartSearchDates"
        [chartDefaultStartDate]="chartDefaultStartDate">
      </ssi-highchart-controls>
    </div>
    <div class="campaign-stats-col campaign-stats-col-filters">
      <div class="graph-filters">
        <div class="graph-filter-title">
          <ssi-big-number [number]="
            +(this.activeCampaignId ?
              this.campaignStats[this.activeCampaignId]?.outbox_messages :
              this.campaignStats.total?.outbox_messages) +
            +(this.activeCampaignId ?
              this.campaignStats[this.activeCampaignId]?.advert_count :
              this.campaignStats.total?.advert_count)">
          </ssi-big-number>
          <p>Total Stats <span class="floating-label" [ssiTooltip]="'Total statistics are calculated by combining both paid and organic post metrics'" [tooltipAppendToBody]="true">?</span></p>
        </div>
        <ul class="graph-filters-posts">
          <li>
            <span class="stat-number">
              <ssi-big-number [number]="
                +(this.activeCampaignId ?
                  this.campaignStats[this.activeCampaignId]?.outbox_reach :
                  this.campaignStats.total?.outbox_reach) +
                +(this.activeCampaignId ?
                  this.campaignStats[this.activeCampaignId]?.advert_engagement :
                  this.campaignStats.total?.advert_engagement)">
              </ssi-big-number>
            </span>
            <span class="stat-number-label">Reach</span>
          </li>
          <li>
            <span class="stat-number">
              <ssi-big-number [number]="
                +(this.activeCampaignId ?
                  this.campaignStats[this.activeCampaignId]?.outbox_impressions :
                  this.campaignStats.total?.outbox_impressions) +
                +(this.activeCampaignId ?
                  this.campaignStats[this.activeCampaignId]?.advert_impressions :
                  this.campaignStats.total?.advert_impressions)">
              </ssi-big-number>
            </span>
            <span class="stat-number-label">Impressions</span>
          </li>
          <li>
            <span class="stat-number">
              <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.outbox_clicks : this.campaignStats.total?.outbox_clicks"></ssi-big-number>
            </span>
            <span class="stat-number-label">Clicks</span>
          </li>
          <li>
            <span class="stat-number">
              <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.outbox_comments : this.campaignStats.total?.outbox_comments"></ssi-big-number>
            </span>
            <span class="stat-number-label">Comments</span>
          </li>
          <li>
            <span class="stat-number">
              <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.outbox_likes : this.campaignStats.total?.outbox_likes"></ssi-big-number>
            </span>
            <span class="stat-number-label">Likes</span>
          </li>
          <li>
            <span class="stat-number">
              <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.outbox_shares : this.campaignStats.total?.outbox_shares"></ssi-big-number>
            </span>
            <span class="stat-number-label">Shares</span>
          </li>
        </ul>
      </div>
      <div class="graph-filters">
        <div class="graph-filter-title">
          <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.outbox_messages : this.campaignStats.total?.outbox_messages"></ssi-big-number>
          <p>Organic Stats <span class="floating-label" [ssiTooltip]="'Organic statistics are calculated solely by looking at unpaid post metrics'" [tooltipAppendToBody]="true">?</span></p>
        </div>
        <ul class="graph-filters-posts">
            <li>
              <span class="stat-number">
                <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.outbox_impressions : this.campaignStats.total?.outbox_impressions"></ssi-big-number>
              </span>
              <span class="stat-number-label">Impressions</span>
            </li>
          <li>
            <span class="stat-number">
              <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.outbox_reach : this.campaignStats.total?.outbox_reach"></ssi-big-number>
            </span>
            <span class="stat-number-label">Reach</span>
          </li>
        </ul>
      </div>
      <div class="graph-filters">
          <div class="graph-filter-title">
            <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.advert_count : this.campaignStats.total?.advert_count"></ssi-big-number>
            <p>Ad Stats <span class="floating-label" [ssiTooltip]="'Advertising statistics are calculated solely by looking at paid social media posts (i.e. boosting via Orlo)'" [tooltipAppendToBody]="true">?</span></p>
          </div>
          <ul class="graph-filters-posts">
            <li>
              <span class="stat-number">
                <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.advert_impressions : this.campaignStats.total?.advert_impressions"></ssi-big-number>
              </span>
              <span class="stat-number-label">Impressions</span>
            </li>
            <li>
              <span class="stat-number">
                <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.advert_clicks : this.campaignStats.total?.advert_clicks"></ssi-big-number>
              </span>
              <span class="stat-number-label">Clicks</span>
            </li>
            <li>
              <span class="stat-number">
                <ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.advert_engagement : this.campaignStats.total?.advert_engagement"></ssi-big-number>
              </span>
              <span class="stat-number-label">Engagements</span>
            </li>
            <li>
              <span class="stat-number">
                &pound;<ssi-big-number [number]="this.activeCampaignId ? this.campaignStats[this.activeCampaignId]?.advert_cost : this.campaignStats.total?.advert_cost"></ssi-big-number>
              </span>
              <span class="stat-number-label">Total cost</span>
            </li>
          </ul>
        </div>
    </div>
  </div>
</div>
<div *ngIf="!this.childCampaign?.parent_id" class="campaigns-completed-filter">
  <div #campaignSelectWrapper class="campaigns-anchor">
    <div class="campaigns-select-container" [class.fixed]="fixCampaignHeader">
      <div [ngClass]="{'campaigns-select-wrapper-ad' : campaignHasAdvertising}" class="campaigns-select-wrapper">
        <select class="btn btn-rounded btn-padding-sides" #select (change)="filterByCompletedCampaigns(select.value)">
          <option [value]="false">All Live <span *ngIf="campaignHasAdvertising">Child</span> Campaigns</option>
          <option [value]="true">Completed <span *ngIf="campaignHasAdvertising">Child</span> Campaigns</option>
        </select>
        <i class="ssi ssi-arrow-down-correct campaigns-select-icon"></i>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!this.childCampaign?.parent_id">
  <!-- <div *ngIf="activeCampaignId && currentCampaignsList.length === 0">
    <p class="campaign-message">Sorry, you do not have any nested campaigns matching your selection</p>
  </div> -->
  <ul class="campaigns-list list-unstyled">
    <li class="campaign campaign-add">
      <div>
        <i [ngClass]="campaignHasAdvertising ? 'ssi-add-child-campaign' : 'ssi-coast-microcopy'"
            class="ssi campaign-add-icon"></i>
        <a
          class="btn btn-primary btn-rounded campaign-add-btn" 
          (click)="toggleAddCampaignDropdown()">
          <i class="ssi ss-fw ssi-plus"></i>
          <p>Add new campaign</p>
        </a>
        <div class="campaign-add-box-wrap" *ngIf="addCampaignActive">
          <ssi-campaign-add-box
            (toggleAddCampaignDropdown)="toggleAddCampaignDropdown()"
            [newCampaign]="newCampaign"
            [campaigns]="campaigns"
            [activeCampaignId]="activeCampaignId">
          </ssi-campaign-add-box>
        </div>
      </div>
    </li>
    <li class="campaign" *ngFor="let campaign of currentCampaignsList; trackBy:trackById; let first = first">
      <ssi-campaign-box
        [campaign]="campaign"
        [children]="fetchChildren(campaign)"
        [stats]="campaignStats[campaign.id]"
        [hasAdvertising]="campaignHasAdvertising"
        (selectCampaign)="selectCampaign($event)">
      </ssi-campaign-box>
    </li>
  </ul>
  <div class="campaign-bottom">
    <i class="ssi ssi-sherlock-microcopy campaign-bottom-icon"></i>
    <p>No more Campaigns!</p>
  </div>
</div>
