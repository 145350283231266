import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { Location } from '@angular/common';
import {
  API,
  ProfileModel,
  Account,
  AccountModel
} from '@ui-resources-angular';
import { workflowAccounts } from '../../../../../../common-resolves';
import './create-edit-competitor.component.scss';
import { StateService } from '@uirouter/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Competitor } from '../../../competitor-analysis.component';
import {
  competitorsFn,
  ProfileGroup,
  ProfileGroupType
} from '../../../competitor-analysis.component';
import { SpinnerComponent } from '../../../../../../../common/components/spinner/spinner.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupService } from '../../../../../../../common/services/popup/popup.service';
import { authUser } from '../../../../../../common-resolves';
import { User } from '@ui-resources-angular';

export function assignCompetitorToProfileGroup() {
  return ProfileGroupType.Competitor;
}
export function assignBrandToProfileGroup() {
  return ProfileGroupType.Brand;
}
@Component({
  selector: 'ssi-create-edit-competitor',
  templateUrl: './create-edit-competitor.component.html',
  styles: []
})
export class CreateEditCompetitorComponent implements OnInit, OnDestroy {
  static competitorsResolve = [
    {
      token: 'profileGroupType',
      resolveFn: assignCompetitorToProfileGroup
    },
    {
      token: 'profileGroups',
      resolveFn: competitorsFn,
      deps: [API]
    },
    workflowAccounts,
    authUser
  ];

  static brandsResolve = [
    {
      token: 'profileGroupType',
      resolveFn: assignBrandToProfileGroup
    },
    {
      token: 'profileGroups',
      resolveFn: competitorsFn,
      deps: [API]
    },
    workflowAccounts,
    authUser
  ];

  @Input() profileGroups: ProfileGroup[];
  @Input() profileGroupType: ProfileGroupType;
  @Input() workflowAccounts: Account[];
  @Input() authUser: User;

  @ViewChild('profileSearchSpinner') profileSearchSpinner: SpinnerComponent;

  competitor: Competitor = {
    name: '',
    colour: '#435DEC',
    profiles: [],
    type: ProfileGroupType.Competitor
  };

  selectedAccount;
  profileSearchString: string;
  profiles = [];
  selectedProfiles = {};
  competitorForm: FormGroup;
  adminMode: { id: string; label: string };
  networkChannelSelected: string;
  profileGroup = {
    [ProfileGroupType.Competitor]: {
      id: 'competitor',
      label: 'Competitor',
      type: ProfileGroupType.Competitor
    },
    [ProfileGroupType.Brand]: {
      id: 'brand',
      label: 'Brand',
      type: ProfileGroupType.Brand
    }
  };
  profileGroupMode: any;

  availableChannels = [
    {
      name: 'Twitter',
      id: '2',
      icon: 'twitter-stylised',
      disabled: true
    },
    {
      name: 'Facebook',
      id: '3',
      icon: 'facebook-stylised',
      disabled: true
    },
    {
      name: 'Instagram',
      id: '12',
      icon: 'instagram-stylised',
      disabled: true
    }
  ];

  adminModes = {
    edit: {
      id: 'edit',
      label: 'Edit'
    },
    create: {
      id: 'create',
      label: 'Create a new'
    }
  };

  showAccountsDropdown = false;

  constructor(
    private api: API,
    private state: StateService,
    private location: Location,
    private profileModel: ProfileModel,
    private renderer: Renderer2,
    private modal: NgbModal,
    private popup: PopupService,
    public accountModel: AccountModel
  ) {}

  async ngOnInit() {
    this.checkAvailableChannels();
    this.profileGroupMode = this.profileGroup[this.profileGroupType];

    if (this.state && this.state.params && this.state.params.id) {
      this.adminMode = this.adminModes.edit;

      this.competitor = this.profileGroups.find(
        (profileGroup: ProfileGroup) => profileGroup.id === this.state.params.id
      );
    } else {
      this.adminMode = this.adminModes.create;
    }

    if (this.competitor) {
      this.competitor.profiles.map((profile) => {
        this.selectedProfiles[profile.account_type_id] = profile;
      });
    }

    if (!this.competitor) {
      this.state.go('^.competitorList');
      return;
    }

    this.competitorForm = new FormGroup({
      name: new FormControl(this.competitor.name, [
        Validators.required,
        Validators.maxLength(50)
      ])
    });
  }

  checkAvailableChannels() {
    this.availableChannels = this.availableChannels.map((channel) => {
      const accountAvailable = this.workflowAccounts.some(
        (account: Account) => channel.id === account.account_type_id
      );
      return {
        ...channel,
        disabled: !accountAvailable
      };
    });
  }

  async searchForProfiles(event) {
    this.profileSearchSpinner.show();
    event.preventDefault();
    this.profiles = [];
    if (this.selectedAccount.account_type_id === '12') {
      await this.api
        .get(`competitiveAnalysis/instagramLookup`, {
          params: {
            account_id: this.selectedAccount.id,
            username: this.profileSearchString
          }
        })
        .then(({ data }) => {
          this.showAccountsDropdown = true;
          this.profileSearchSpinner.hide();
          this.profiles.push(data);
        });
    } else {
      await this.profileModel
        .search(this.selectedAccount.id, this.profileSearchString)
        .then(({ results }) => {
          this.showAccountsDropdown = true;
          this.profiles = results;
          this.profileSearchSpinner.hide();
        });
    }
  }

  addProfile(profile) {
    this.selectedProfiles[this.selectedAccount.account_type_id] = {
      account_type_id: parseInt(this.selectedAccount.account_type_id, 10),
      social_id: profile.id,
      username: profile.username,
      avatar_url: profile.image_url,
      name: profile.name
    };
    this.profiles = [];
    this.networkChannelSelected = null;
  }

  addOrRemoveProfile(profileId: string) {
    this.showAccountsDropdown = false;
    this.profileSearchSpinner && this.profileSearchSpinner.hide(); // tslint:disable-line

    this.selectedProfiles[profileId]
      ? (delete this.selectedProfiles[profileId],
        (this.networkChannelSelected = null))
      : (this.openProfileSearch(profileId),
        (this.networkChannelSelected = profileId));
  }

  openProfileSearch(accountTypeId: string) {
    this.selectedAccount = this.workflowAccounts.find(
      (account: Account) => account.account_type_id === accountTypeId
    );
  }

  backToCompanies() {
    if (this.adminMode === this.adminModes.edit) {
      this.state.go('auth.analytics.competitorAnalysis.competitorList');
    } else {
      this.state.go('auth.analytics.competitorAnalysis.index');
    }
  }

  checkIfCompetitorIsValid() {
    return (
      this.competitorForm.controls.name.invalid &&
      Object.keys(this.selectedProfiles).length === 0
    );
  }

  async openDeleteModal() {
    const shouldDeleteProfile = await this.popup.confirm({
      title: 'Delete for all eternity?',
      message: `All collected data for this ${this.profileGroupMode.label} will be deleted permanently.`,
      okText: 'Delete please',
      windowClass: 'modal-rounded-corners',
      hideClose: true,
      iconClass: 'ssi ssi-bin',
      backdrop: true
    });

    if (shouldDeleteProfile) {
      this.api.delete(
        `competitiveAnalysis/profileGroup?id=${this.competitor.id}`
      );
      this.state.go('^.competitorList', null, { reload: true });
    }
  }

  saveCompetitor() {
    if (!this.authUser.hasCompanyPermission('manage_competitor_analytics')) {
      this.popup.confirm({
        title: 'Error saving new competitor',
        message: `You do not have the required company permission to manage Competitor Analytics.`,
        okText: 'Ok',
        windowClass: 'modal-rounded-corners',
        hideClose: true,
        iconClass: 'ssi ssi-permissions',
        backdrop: true
      });
      return;
    }

    const newCompetitor = Object.assign({}, this.competitor);
    newCompetitor.profiles = Object.keys(this.selectedProfiles).map((key) => {
      return this.selectedProfiles[key];
    });
    newCompetitor.name = this.competitorForm.value.name;
    newCompetitor.type = this.profileGroupMode.type;
    if (this.competitor.id) {
      this.api
        .put(
          `competitiveAnalysis/profileGroup?id=${this.competitor.id}`,
          newCompetitor
        )
        .then(
          (res) => {
            if (res.status === 200) {
              this.state.go('auth.analytics.competitorAnalysis.index');
            }
          },
          (error) => console.error('Error editing competitor:', error)
        );
    } else {
      this.api.post('competitiveAnalysis/profileGroup', newCompetitor).then(
        (res) => {
          if (res.status === 200) {
            this.state.go('auth.analytics.competitorAnalysis.index');
          }
        },
        (error) => console.error('Error saving new competitor:', error)
      );
    }
  }

  get validAccounts() {
    return this.workflowAccounts.filter(
      (account: Account) =>
        account.account_type_id === this.selectedAccount.account_type_id
    );
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'grey-bg');
  }
}
