import './confirmation-modal.component.scss';
import { Component, ElementRef, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
  @Input() title: string;
  @Input() info: string;
  @Input() icon: string;
  @Input() cancelButton: string;
  @Input() confirmButton: string;
  @Input() negativeConfirmation: string;
  @Input() footerTemplate: ElementRef;

  constructor(public activeModal: NgbActiveModal) {}
}
