<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Inbox settings</h1>
</div>

<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-envelope"></i> <span translate="INBOX_SNIPPETS"></span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <table class="table">
        <thead>
          <tr>
            <th translate="TITLE"></th>
            <th translate="BODY"></th>
            <th translate="ACTIONS"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="snippets.length === 0">
            <td colspan="3" translate="YOU_HAVE_NO_SAVED_SNIPPETS"></td>
          </tr>
          <tr *ngFor="let snippet of snippets">
            <td>{{ snippet.title }}</td>
            <td [innerHTML]="snippet.body | nl2br"></td>
            <td>
              <button
                class="btn btn-danger"
                type="button"
                (click)="handlePopup('DELETE_SNIPPET', 'ARE_YOU_SURE', 'deleteSnippet', [snippet])">
                <i class="fa fa-trash"></i> <span translate="DELETE"></span>
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3">
              <form class="table-form-snippet" #newSnippetForm="ngForm">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ 'TITLE' | translate }}"
                  [(ngModel)]="newSnippet.title"
                  name="title"
                  required>
                <textarea
                  class="form-control"
                  placeholder="{{ 'BODY' | translate }}"
                  [(ngModel)]="newSnippet.body"
                  name="body"
                  required>
                </textarea>
                <button
                  class="btn btn-primary"
                  type="button"
                  translate="CREATE_NEW_SNIPPET"
                  [disabled]="!newSnippetForm.valid"
                  (click)="createSnippet()">
                </button>
              </form>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-bell-slash"></i> <span translate="SILENCING_THREADS"></span>
      </h3>
      <button class="btn btn-success" (click)="saveSettings()">
        <i class="fa fa-save"></i> <span translate="SAVE_CHANGES"></span>
      </button>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <div class="form-horizontal" role="form">
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="ALLOW_USERS_TO_SILENCE_INBOX_THREADS"></label>
          <div class="col-sm-9">
            <select
              [(ngModel)]="settings.company_preferences.can_silence_inbox_threads"
              (ngModelChange)="saveSettings()"
              class="form-control">
              <option value="1" translate="YES"></option>
              <option value="0" translate="NO"></option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-tags"></i> <span translate="INBOX_TAGS"></span>
      </h3>
      <button
        class="btn btn-default"
        ssiFileDownload
        filename="inbox_tags_export.xlsx"
        base64decode="true"
        [data]="tagsExport"
        mimetype="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
        <i class="fa fa-download"></i> <span translate="EXPORT"></span>
      </button>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <div class="form-horizontal" role="form">
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="ALLOW_ALL_USERS_TO_CREATE_TAGS"></label>
          <div class="col-sm-9">
            <select
              [(ngModel)]="settings.company_preferences.all_users_can_create_tags"
              (ngModelChange)="saveSettings()"
              class="form-control">
              <option value="1" translate="ON"></option>
              <option value="0" translate="OFF"></option>
            </select>
          </div>
        </div>
        <div class="alert alert-info">
          <span translate="ENABLING_THIS_OPTION_WILL_ALLOW_ALL_USERS_TO_CREATE_NEW_TAGS_INLINE_ON_INBOX_MESSAGES"></span>
        </div>
      </div>
      <hr>
      <table class="table">
        <thead>
          <tr>
            <th translate="TAG"></th>
            <th translate="ACTIONS"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="tags.length === 0">
            <td colspan="3" translate="YOU_HAVE_NO_SAVED_TAGS"></td>
          </tr>
          <tr *ngFor="let tag of tags; let index = index">
            <ng-container *ngIf="index >= (currentTagsPage - 1) * 10 && index < (currentTagsPage - 1) * 10 + 10">
              <td>{{ tag }}</td>
              <td>
                <button
                  class="btn btn-warning"
                  type="button"
                  (click)="handlePopup('DELETE_TAG', 'ARE_YOU_SURE', 'deleteTag', [tag])">
                  <i class="fa fa-times"></i> <span translate="DELETE"></span>
                </button>
                <button
                  class="btn btn-danger"
                  type="button"
                  (click)="handlePopup('DELETE_TAG', 'ARE_YOU_SURE', 'deleteTag', [tag, true])">
                  <i class="fa fa-trash"></i> <span translate="DELETE_AND_REMOVE_FROM_ALL_MESSAGES"></span>
                </button>
              </td>
            </ng-container>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="2" class="text-center">
              <ngb-pagination
                [(page)]="currentTagsPage"
                [ellipses]="true"
                [maxSize]="8"
                [collectionSize]="tags.length">
              </ngb-pagination>
            </th>
          </tr>
          <tr>
            <th colspan="2">
              <form class="row" #newTagForm="ngForm">
                <div class="col-md-6 col-md-offset-3">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ 'TAG' | translate }}"
                      [(ngModel)]="newTag"
                      name="tag"
                      required>
                    <span class="input-group-btn">
                      <button
                        class="btn btn-primary"
                        type="button"
                        translate="CREATE_NEW_TAG"
                        [disabled]="!newTagForm.valid"
                        (click)="createTag()">
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <!-- <div class="panel panel-default" *ngIf="hasValidatePostPermission">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-tags"></i> <span translate="INBOX_APPROVAL_TAGS"></span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <table class="table">
        <thead>
          <tr>
            <th translate="TAG"></th>
            <th translate="ACTIONS"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="tags.length === 0">
            <td colspan="3" translate="YOU_HAVE_NO_SAVED_TAGS"></td>
          </tr>
          <tr *ngFor="let tag of approvalTags; let index = index">
            <ng-container *ngIf="index >= (currentTagsPage - 1) * 10 && index < (currentTagsPage - 1) * 10 + 10">
              <td>{{ tag }}</td>
              <td>
                <button
                  class="btn btn-warning"
                  type="button"
                  (click)="handlePopup('DELETE_TAG', 'ARE_YOU_SURE', 'deleteApprovalTag', [tag])">
                  <i class="fa fa-times"></i> <span translate="DELETE"></span>
                </button>
              </td>
            </ng-container>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="2" class="text-center">
              <ngb-pagination
                [(page)]="currentTagsPage"
                [ellipses]="true"
                [maxSize]="8"
                [collectionSize]="tags.length">
              </ngb-pagination>
            </th>
          </tr>
          <tr>
            <th colspan="2">
              <form class="row" #newApprovalTagForm="ngForm">
                <div class="col-md-6 col-md-offset-3">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ 'TAG' | translate }}"
                      [(ngModel)]="newApprovalTag"
                      name="tag"
                      required>
                    <span class="input-group-btn">
                      <button
                        class="btn btn-primary"
                        type="button"
                        translate="CREATE_NEW_TAG"
                        [disabled]="!newApprovalTagForm.valid"
                        (click)="createApprovalTag()">
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div> -->

  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="panel-title">
        <i class="fa fa-user"></i> <span translate="CONFIGURE_CRM_INTEGRATIONS"></span>
      </h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <form
        class="form-horizontal"
        role="form"
        #newIntegrationForm="ngForm"
        (submit)="saveNewCrmIntegration()"
      >
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="ADD_A_CRM"></label>
          <div class="col-sm-9">
            <select
              class="form-control"
              name="integrationType"
              [(ngModel)]="newIntegrationType"
              (ngModelChange)="beginCreateNewCrmIntegration(newIntegrationType)">
              <option value="undefined" translate="SELECT_A_CRM"></option>
              <option
                *ngFor="let type of crmExternalIntegrationTypes"
                [value]="type.name">
                {{ type.translationId | translate }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="newIntegration?.type">
          <div class="form-group">
            <label class="col-sm-3 control-label" translate="NAME"></label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                required
                name="form"
                [(ngModel)]="newIntegration.form.name">
            </div>
          </div>
          <div class="form-group" *ngFor="let param of newIntegration.type.params">
            <label class="col-sm-3 control-label">{{ param.translationId | translate }}</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                required
                [name]="param.name"
                [(ngModel)]="newIntegration.form.configuration[param.name]">
            </div>
          </div>
          <div class="text-right">
            <button
              type="submit"
              class="btn btn btn-primary"
              [disabled]="!newIntegrationForm.valid || newIntegrationLoadingTracker.active">
              <i
                ng-class="{
                'fa fa-save': !newIntegrationLoadingTracker.active,
                'fa fa-spin fa-spinner': newIntegrationLoadingTracker.active}">
              </i> <span translate="SAVE"></span>
            </button>
          </div>
        </div>
      </form>

      <div *ngIf="configuredCrmExternalIntegrations.length > 0">
        <hr>
        <table class="table">
          <thead>
            <tr>
              <th translate="TYPE"></th>
              <th translate="LABEL"></th>
              <th translate="ID"></th>
              <th translate="DELETE"></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let integration of configuredCrmExternalIntegrations">
              <td>
                {{ integration.typeTranslationId | translate }}
              </td>
              <td>
                {{ integration.name }}
              </td>
              <td>
                {{ integration.id }}
              </td>
              <td>
                <button
                  class="btn btn-danger"
                  type="button"
                  (click)="handlePopup(
                    'DELETE_INTEGRATION',
                    'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_CRM_INTEGRATION_ONCE_DELETED_IT_CANNOT_BE_UNDONE',
                    'disableCrmIntegration',
                    [integration]
                  )">
                  <i class="fa fa-trash"></i> <span translate="DELETE"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-bar-chart"></i> <span translate="ENGAGEMENT_REPORT_SLAS"></span>
      </h3>
      <button class="btn btn-success" (click)="saveSlas()">
        <i class="fa fa-save"></i> <span translate="SAVE_CHANGES"></span>
      </button>
      <button class="btn btn-primary spacer-sides" (click)="addSla()">
        <i class="fa fa-plus"></i> <span translate="ADD_NEW"></span>
      </button>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">

      <div
        class="alert alert-info"
        *ngIf="engagementReportSLAs.length === 0"
        translate="YOU_HAVE_NO_SLAS_CONFIGURED">
      </div>

      <table class="table" *ngIf="engagementReportSLAs.length > 0">
        <thead>
          <tr>
            <th translate="FROM_SECONDS"></th>
            <th translate="TO_SECONDS"></th>
            <th translate="DELETE"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sla of orderedSLAs; let index = index">
            <td>
              <span *ngIf="index === 0">0</span>
              <span *ngIf="index > 0">{{ orderedSLAs[index - 1].threshold + 1 }}</span>
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="sla.threshold"
                [ngModelOptions]="{ updateOn: 'blur' }">
            </td>
            <td>
              <button class="btn btn-danger" (click)="deleteSla(sla)">
                <i class="fa fa-trash"></i> <span translate="DELETE"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-bar-chart"></i> <span>Response time SLA's</span>
      </h3>
      <button class="btn btn-success" (click)="saveResponseSlas()">
        <i class="fa fa-save"></i> <span translate="SAVE_CHANGES"></span>
      </button>
      <button class="btn btn-primary spacer-sides" (click)="addResponseSla()">
        <i class="fa fa-plus"></i> <span translate="ADD_NEW"></span>
      </button>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">

      <div
        class="alert alert-info"
        *ngIf="responseTimeSLAs.length === 0">
        You have no Response time SLAs configured.
      </div>

      <table class="table" *ngIf="responseTimeSLAs.length > 0">
        <thead>
          <tr>
            <th translate="FROM_SECONDS"></th>
            <th translate="TO_SECONDS"></th>
            <th translate="DELETE"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sla of responseTimeSLAs; let index = index">
            <td>
              <span *ngIf="index === 0">0</span>
              <span *ngIf="index > 0">{{ responseTimeSLAs[index - 1].threshold + 1 }}</span>
            </td>
            <td>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="sla.threshold"
                [ngModelOptions]="{ updateOn: 'blur' }">
            </td>
            <td>
              <button class="btn btn-danger" (click)="deleteResponseSla(sla)">
                <i class="fa fa-trash"></i> <span translate="DELETE"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->

</div>
