import './sentiment.component.scss';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'ssi-sentiment',
  templateUrl: './sentiment.component.html',
  styles: []
})
export class SentimentComponent implements OnInit, OnChanges {
  @Input() positiveTotal: number;
  @Input() semiPositiveTotal: number;
  @Input() neutralTotal: number;
  @Input() semiNegativeTotal: number;
  @Input() negativeTotal: number;
  total: number;

  constructor() {}

  ngOnInit() {
    this.calculateValues();
  }

  ngOnChanges() {
    this.calculateValues();
  }

  calculateValues() {
    this.total =
      (this.positiveTotal || 0) +
      (this.semiPositiveTotal || 0) +
      (this.neutralTotal || 0) +
      (this.semiNegativeTotal || 0) +
      (this.negativeTotal || 0);
  }
}
