import { NgModule } from '@angular/core';

import { LoadingBoxModule } from '../../common/components/loading-box/loading-box.module';
import { InboxModule } from './inbox/inbox.module';
import { OutboxModule } from './outbox/outbox.module';
import { MonitoringModule } from './monitoring/monitoring.module';
import { IftttAppletCollectionListModule } from './ifttt/ifttt-applet-collection-list/ifttt-applet-collection-list.module';
import { IftttUtilsModule } from './ifttt/ifttt-utils/ifttt-utils.module';
import { ManageIftttAppletModule } from './ifttt/manage-ifttt-applet/manage-ifttt-applet.module';
import { PublisherModule } from '../../common/components/publisher/publisher.module';
import { MarketingModule } from './marketing/marketing.module';
import { SettingsModule } from './settings/settings.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { InsightsModule } from './insights/insights.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ComponentLibraryModule } from './component-library/component-library.module';
import { WorkflowsModule } from './workflows/workflows.module';
import { ComposerModule } from '../../common/components/composer/composer.module';

@NgModule({
  imports: [
    LoadingBoxModule,
    InboxModule,
    OutboxModule,
    MonitoringModule,
    IftttAppletCollectionListModule,
    IftttUtilsModule.forRoot(),
    ManageIftttAppletModule,
    PublisherModule,
    MarketingModule,
    SettingsModule,
    AnalyticsModule,
    InsightsModule,
    WorkflowsModule,
    DashboardModule,
    WorkflowsModule,
    ComposerModule,
    ComponentLibraryModule,
    WorkflowsModule
  ],
  declarations: []
})
export class AuthModule {}
