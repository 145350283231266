import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AnalyticsComponent } from './analytics.component';
import { AnalyticsDirective } from './analytics.directive';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.campaignAnalytics',
          url: '/campaign-analytics/:campaign_id',
          component: AnalyticsComponent,
          params: {
            campaign_id: {
              dynamic: true
            }
          }
        }
      ]
    })
  ],
  declarations: [AnalyticsComponent, AnalyticsDirective],
  entryComponents: [AnalyticsComponent]
})
export class AnalyticsModule {}
