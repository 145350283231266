<article class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">{{article.title}}</h3>
  </div>
  <div class="panel-body">
    <div class="row">
      <div class="col-sm-7 no-wrap">
        <a [href]="article.feed.url" target="_blank">
          <small>
            <i class="fa fa-tags"></i> {{ article.feed.name }}
          </small>
        </a>
      </div>
      <div class="col-sm-5">
        <small class="pull-right">{{ article.created_at | date:'MMM dd HH:mm' }}</small>
      </div>
    </div>
    <p class="spacer-top" [innerHTML]="article.description | truncate: 200"></p>
    <img
      *ngIf="article.img"
      class="img-preview"
      [src]="article.img"
      [ssiEnlargeImage]="article.img"
    >
  </div>
  <div class="panel-footer text-center">
    <div class="row border-separated">
      <div class="col-md-6">
        <a [href]="article.url" target="_blank">
          <i class="fa fa-external-link"></i> <span translate="VIEW"></span>
        </a>
      </div>
      <div class="col-md-6">
        <a href="javascript:;" (click)="createPost(article)">
          <i class="fa fa-plus"></i>
          <span translate="CREATE_POST"></span>
          <i
            class="fa fa-info-circle"
            [ssiTooltip]="'WE_AUTOMATICALLY_PICK_THE_BEST_TIME_TO_SCHEDULE_THIS_POST_BASED_ON_PREVIOUSLY_SUCCESSFUL_POST_TIMES' | translate"
            [tooltipAppendToBody]="true"
            tooltipWindowClass="tooltip-chunky-big"
          ></i>
        </a>
      </div>
    </div>
  </div>
</article>
