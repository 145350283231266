import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedBoxCheckComponent } from './rounded-box-check.component';

@NgModule({
  imports: [CommonModule],
  declarations: [RoundedBoxCheckComponent],
  exports: [RoundedBoxCheckComponent]
})
export class RoundedBoxCheckModule {}
