import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

import 'core-js/es7/array';
import 'core-js/es7/object';
import 'core-js/es7/reflect';
import mapPolyfill from 'core-js/library/es6/map';
import weakMapPolyfill from 'core-js/library/es6/weak-map';
import setPolyfill from 'core-js/library/es6/set';
import weakSetPolyfill from 'core-js/library/es6/weak-set';
import 'zone.js';
import 'promise.prototype.finally/auto';
import 'url-polyfill';
import { polyfill as keyboardEventKeyPolyfill } from 'keyboardevent-key-polyfill';
import { UAParser } from 'ua-parser-js';

keyboardEventKeyPolyfill();

const userAgent = new UAParser();
const browser = userAgent.getBrowser();
const isEdge = browser.name === 'Edge';
const isOldIE = browser.name === 'IE' && +browser.major <= 11;

if (isOldIE) {
  window['Set'] = setPolyfill;
  window['WeakSet'] = weakSetPolyfill;
  window['Map'] = mapPolyfill;
  window['WeakMap'] = weakMapPolyfill;
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype['msMatchesSelector'];
}

export function lazyLoadPolyfills(): Promise<any[]> {
  const LOAD_RAF_POLYFILL = typeof window.requestAnimationFrame === 'undefined';
  const LOAD_REQUEST_IDLE_CALLBACK_POLYFILL =
    typeof window['requestIdleCallback'] === 'undefined';

  const polyfillsLoaded = [];

  if (LOAD_RAF_POLYFILL) {
    polyfillsLoaded.push(
      (async () => {
        const {
          default: { polyfill }
        } = await import('raf');
        polyfill();
      })()
    );
  }

  if (LOAD_REQUEST_IDLE_CALLBACK_POLYFILL) {
    polyfillsLoaded.push(
      (async () => {
        const { request: requestIdleCallback } = await import(
          'requestidlecallback'
        );
        window['requestIdleCallback'] = requestIdleCallback;
      })()
    );
  }

  return Promise.all(polyfillsLoaded);
}
