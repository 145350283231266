import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {
  transform(array: any[], property?: string): any {
    if (!Array.isArray(array)) {
      return array;
    }
    if (property) {
      return array.filter((item, i, arr) => {
        return (
          arr.indexOf(arr.find((t) => t[property] === item[property])) === i
        );
      });
    } else {
      return Array.from(new Set(array));
    }
  }
}
