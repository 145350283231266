<div class="survey-analytics-report-container">
  <ssi-report-header report="Survey Report"
                     [name]=""
                     [dateRangesTemplate]="dateRangesRef"
                     [canChangeDates]="false"
                     (dateSelected)="reportDateChanged($event)"></ssi-report-header>

  <ssi-report-sidebar icon="ssi-survey"
                      color="#D41D68"
                      [navList]="navItems"
                      [actionList]="reportActions"
                      [active]="activeNavItemTag"
                      (navClicked)="scrollTo($event)"></ssi-report-sidebar>

  <div class="report-body-loading"
       *ngIf="loading && false">
    <div class="loading-container">
      <!-- <i class="ssi ssi-confetti"></i> -->
      <img src="https://www.orlo.app/app/web/production/f44ceeab6551db9be89255d952d86c0d.gif">
      <h3>We're building your report!</h3>
      <p>This may take a few moments
      </p>
    </div>
  </div>

  <div class="report-body"
       [hidden]="loading && false">
    <div class="section-container">
      <div class="account-list">
        <div class="account-list-item"
             *ngFor="let account of accounts">
          <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                   variant="fa"></ssi-social-network-icon>
          <span>{{account.name}}</span>
        </div>
      </div>
    </div>

    <div class="section-delimiter"></div>

    <ssi-filters id="filters"
                 [startDate]="dateRange.start"
                 [endDate]="dateRange.end"
                 [report]="report"
                 (formSubmit)="onFiltersSubmit($event)"></ssi-filters>

    <div class="section-delimiter"></div>

    <ssi-survey-summary id="survey-summary"
                        [aggregation]="aggregation"
                        [report]="report"></ssi-survey-summary>

    <div class="section-delimiter"></div>

    <ssi-survey-responses id="survey-responses"
                        [itemsPerPage]="itemsPerPage"
                        [accounts]="accounts"
                        [dateRange]="dateRange"
                        [report]="report"
                        [itemsCount]="reportsPerPage['1'] ? reportsPerPage['1'].aggregation.count_responses : 0"
                        [loading]="loading"
                        (pageChange)="onPageChange($event)"
                        (serverSortParamsChange)="onSort($event)"></ssi-survey-responses>

    <div class="section-delimiter margin-bottom-100"></div>
  </div>

  <ng-template #dateRangesRef>
    <div class="selected-date-ranges">
      <span class="current">{{dateRange.start | date: 'dd MMM yy'}} - {{dateRange.end | date: 'dd MMM yy'}}</span>
    </div>
  </ng-template>
</div>