import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimeModalComponent } from './datetime-modal.component';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [CommonModule, FormsModule, FlatpickrModule, OrloComponentsModule],
  declarations: [DatetimeModalComponent],
  exports: [DatetimeModalComponent],
  providers: []
})
export class DatetimeModalModule {}
