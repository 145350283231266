import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSelectModalComponent } from './user-select-modal.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SortByModule } from '../../pipes/sort-by/sort-by.module';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, SortByModule],
  declarations: [UserSelectModalComponent],
  exports: [UserSelectModalComponent],
  providers: []
})
export class UserSelectModalModule {}
