import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialPostCharactersRemainingPipe } from './social-post-characters-remaining.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SocialPostCharactersRemainingPipe],
  exports: [SocialPostCharactersRemainingPipe]
})
export class SocialPostCharactersRemainingModule {}
