import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { AutomatedReportsComponent } from './automated-reports.component';

import { OrloComponentsModule } from 'orlo-components';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TooltipModule } from '../../../../../../angular/common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../../common/directives/off-click/off-click.module';
import { CheckboxModule } from '../../../../../common/components/checkbox/checkbox.module';
import { ConfirmActionModule } from '../../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../common/components/social-network-icon/social-network-icon.module';
import { TreetableModule } from '../../../../../common/components/treetable';
import { NoResultsModule } from '../../../../../common/components/no-results/no-results.module';
import { ContextMenuModule } from '../../../../../common/components/context-menu/context-menu.module';

@NgModule({
  declarations: [AutomatedReportsComponent],
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.automatedReports',
          url: '/automated-reports',
          component: AutomatedReportsComponent
          // resolve: AutomatedReportsComponent.resolve
        }
      ]
    }),
    TranslateModule,
    FormsModule,
    AlertsModule,
    TooltipModule,
    OffClickModule,
    CheckboxModule,
    ConfirmActionModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    TreetableModule,
    NoResultsModule,
    ContextMenuModule
  ]
})
export class AutomatedReportsModule {}
