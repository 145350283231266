import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxLinkExternalCrmModalComponent } from './inbox-link-external-crm-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ListSelectModule } from '../../../list-select/list-select.module';
import { InboxCrmExternalIntegrationPersonModule } from '../inbox-crm-external-integration-person/inbox-crm-external-integration-person.module';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ListSelectModule,
    InboxCrmExternalIntegrationPersonModule
  ],
  declarations: [InboxLinkExternalCrmModalComponent],
  exports: [InboxLinkExternalCrmModalComponent]
})
export class InboxLinkExternalCrmModalModule {}
