import { Component, OnInit, Input } from '@angular/core';
import './office-hours.component.scss';
import moment from 'moment';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { CompanyService } from '../../../../../common/services/company/company.service';
import {
  companyPreferencesResolveFn,
  serverSettings
} from '../../../../common-resolves';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { Account, AccountModel, API } from '@ui-resources-angular';
import { OfficeHoursService } from './office-hours.service';
import { NotificationService } from '../../../../../common/services/notification/notification.service';

export async function officeHoursFn(company: CompanyService) {
  return company.getOfficeHours();
}

interface AccountWithOfficeHours extends Account {
  officeHours: {
    schedule: any;
    timezone: any;
  };
  scheduleFormatted: any[];
}

@Component({
  selector: 'ssi-office-hours',
  templateUrl: './office-hours.component.html',
  styleUrls: []
})
export class OfficeHoursComponent implements OnInit {
  static resolve = [
    {
      token: 'officeHours',
      resolveFn: officeHoursFn,
      deps: [CompanyService]
    },
    {
      token: 'settings',
      resolveFn: companyPreferencesResolveFn,
      deps: [CompanyService]
    },
    serverSettings
  ];

  @Input() settings;
  @Input() officeHours;
  @Input() serverSettings;
  loadingTracker = this.asyncTrackerFactory.create();
  daysOfTheWeek = [];
  scheduleFormatted = [];
  trackByIndex(index: number) {
    return index;
  }
  accounts: Account[];
  accountsWithOfficeHours: Partial<Account>[];
  filteredAccounts: Partial<Account>[];
  searchText: string;
  exclude_last_out_of_hours: boolean;

  constructor(
    private company: CompanyService,
    private asyncTrackerFactory: AsyncTrackerFactory,
    private accountModel: AccountModel,
    private officeHoursService: OfficeHoursService,
    private notification: NotificationService,
    private api: API,
    private translate: TranslateService,
    private popup: PopupService
  ) {}

  async ngOnInit() {
    for (let counter = 0; counter < 7; counter++) {
      const day = moment().startOf('week').add(counter, 'days').toDate();
      this.daysOfTheWeek.push(day);
      const scheduleKey = moment(day).format('ddd').toLowerCase();
      this.officeHours.schedule[scheduleKey].forEach((period) => {
        this.scheduleFormatted.push({
          day,
          open: {
            hour: Number(period.open.split(':')[0]),
            minute: Number(period.open.split(':')[1])
          },
          close: {
            hour: Number(period.close.split(':')[0]),
            minute: Number(period.close.split(':')[1])
          }
        });
      });
    }

    this.accounts = await this.accountModel.findAll();

    const officeHoursToUse = await this.getOfficeHoursToUse();

    const formattedAccountsData = this.accounts.map(async (acc: Account) => {
      return {
        ...acc,
        officeHours: officeHoursToUse.accountsOfficeHoursKeyed[acc.id],
        scheduleFormatted:
          officeHoursToUse.accountsOfficeHoursKeyed[acc.id].scheduleFormatted,
        openingPeriod: {}
      };
    });

    this.accountsWithOfficeHours = await Promise.all(formattedAccountsData);
    this.filteredAccounts = this.accountsWithOfficeHours;
  }

  formatSchedule(officeHoursToUse: { schedule: any; timezone: string }) {
    const scheduleFormatted = [];
    for (let counter = 0; counter < 7; counter++) {
      const day = moment().startOf('week').add(counter, 'days').toDate();
      // this.daysOfTheWeek.push(day);
      const scheduleKey = moment(day).format('ddd').toLowerCase();
      officeHoursToUse.schedule[scheduleKey].forEach((period) => {
        scheduleFormatted.push({
          day,
          open: {
            hour: Number(period.open.split(':')[0]),
            minute: Number(period.open.split(':')[1])
          },
          close: {
            hour: Number(period.close.split(':')[0]),
            minute: Number(period.close.split(':')[1])
          }
        });
      });
    }
    return scheduleFormatted;
  }

  async getOfficeHoursToUse() {
    const accountsOfficeHours = await this.officeHoursService.getAccountsOfficeHours();
    const accountsOfficeHoursKeyed = [];

    const accountsOfficeHoursFormatted = accountsOfficeHours.map(
      (accountOfficeHours) => {
        if (accountOfficeHours.timezone) {
          accountOfficeHours.scheduleFormatted = this.formatSchedule(
            accountOfficeHours
          );
        } else {
          accountOfficeHours.scheduleFormatted = [];
        }
        accountsOfficeHoursKeyed[accountOfficeHours.id] = accountOfficeHours;
        return accountOfficeHours;
      }
    );

    return {
      accountsOfficeHoursKeyed
    };
  }

  addOfficeHour() {
    this.scheduleFormatted.push({
      day: this.daysOfTheWeek[0],
      open: {
        hour: 9,
        minute: 0
      },
      close: {
        hour: 17,
        minute: 0
      }
    });
  }

  addOfficeHoursForAccount(account: AccountWithOfficeHours) {
    account.scheduleFormatted.push({
      day: this.daysOfTheWeek[0],
      open: {
        hour: 9,
        minute: 0
      },
      close: {
        hour: 17,
        minute: 0
      }
    });
  }

  async saveOfficeHoursForAccount(account: AccountWithOfficeHours) {
    account.officeHours.schedule = {};
    account.scheduleFormatted.forEach((schedule) => {
      const dowKey = moment(schedule.day).format('ddd').toLowerCase();
      account.officeHours.schedule[dowKey] =
        account.officeHours.schedule[dowKey] || [];
      account.officeHours.schedule[dowKey].push({
        open: moment()
          .set('hours', schedule.open.hour)
          .set('minute', schedule.open.minute)
          .format('HH:mm'),
        close: moment()
          .set('hours', schedule.close.hour)
          .set('minute', schedule.close.minute)
          .format('HH:mm')
      });
    });

    await this.officeHoursService
      .saveAccountOfficeHours({ id: account.id, ...account.officeHours })
      .then(() => {
        this.notification.open(
          `Your opening hours have been saved for ${account.name}. See the accounts below.`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#F0B427'
          },
          3000
        );
      })
      .catch((err) =>
        console.error('Error saving office hours for account:', err)
      );
  }

  saveOfficeHours() {
    this.officeHours.schedule = {};
    this.scheduleFormatted.forEach((schedule) => {
      const dowKey = moment(schedule.day).format('ddd').toLowerCase();
      this.officeHours.schedule[dowKey] =
        this.officeHours.schedule[dowKey] || [];
      this.officeHours.schedule[dowKey].push({
        open: moment()
          .set('hours', schedule.open.hour)
          .set('minute', schedule.open.minute)
          .format('HH:mm'),
        close: moment()
          .set('hours', schedule.close.hour)
          .set('minute', schedule.close.minute)
          .format('HH:mm')
      });
    });

    const promise = this.company.saveOfficeHours(this.officeHours).then(() => {
      this.notification.open(
        `Your opening hours for the company have been saved. See all the accounts below.`,
        {
          class: 'ssi ssi-completed-notification',
          color: '#F0B427'
        },
        3000
      );
    });
    this.loadingTracker.add(promise);
  }

  search() {
    this.filteredAccounts = this.accountsWithOfficeHours.filter((account) =>
      account.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  saveSettings() {
    const promise = this.api
      .post('settings/companyPreference', this.settings)
      .then(() => {
        // return this.state.go('auth.settings.index');
      })
      .then(() => {
        this.popup.alert({
          title: this.translate.instant('SETTINGS_SAVED'),
          message: this.translate.instant(
            'YOUR_SETTINGS_WERE_SAVED_SUCCESSFULLY'
          )
        });
      });
    this.loadingTracker.add(promise);
  }
}
