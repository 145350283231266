import { defaults } from '../../constants/live-chat';
import strings from '../../constants/strings';
import { NetworkState } from '../../interfaces/live-chat/network-state';
import { Person as PersonInterface } from '../../interfaces/live-chat/person';
import { User } from './user';
import { timestamp_to_date } from '../../helpers/datetimes';

export class Person extends User implements PersonInterface {
  protected _data: PersonInterface;
  protected _forename: string;
  protected _networkState: any;
  protected _networkStateUpdatedAt: Date;
  protected _surname: string;

  public get avatar(): string {
    return !!this._data.avatar ? this._data.avatar : defaults.avatar;
  }

  public get forename() {
    return this._forename;
  }

  public get surname() {
    return this._surname;
  }

  public get hasNetworkState() {
    return !!this._data.networkState && !!this._data.networkState.value;
  }

  public get isOffline(): boolean {
    return this.hasNetworkState && this.networkStateValue === strings.offline;
  }

  public get isOnline(): boolean {
    return this.hasNetworkState && this.networkStateValue === strings.online;
  }

  public get networkState(): NetworkState {
    return this._networkState;
  }

  public get networkStateUpdatedAt(): Date {
    return this._networkStateUpdatedAt;
  }

  public get networkStateValue(): string {
    return !!this.hasNetworkState
      ? this._data.networkState.value
      : strings.offline;
  }

  protected initialise() {
    try {
      this._forename = this.displayName
        .slice(0, this.displayName.indexOf(' ') || this.displayName.length)
        .trim();

      this._surname = this.displayName
        .slice(this.forename.length, this.displayName.length)
        .trim();

      this._networkState =
        !!this._data.networkState && !!this._data.networkState.value
          ? {
              updatedAt: this._networkStateUpdatedAt,
              value: this._data.networkState.value
            }
          : {
              updatedAt: new Date(),
              value: strings.offline
            };

      this._networkStateUpdatedAt = timestamp_to_date(
        this.networkState.updatedAt
      );

      return this;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
