import './cl-menu.component.scss';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-cl-menu',
  templateUrl: './cl-menu.component.html',
  styles: []
})
export class ClMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
