import './widget-monitoring-stream-config.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Colleague,
  ColleaguesService,
  Team,
  TeamsService,
  MonitoringStreamsService
} from '../../../../../../../common/services/api';
import { Account, AccountModel } from '@ui-resources-angular';
import { widgetTypes } from '../../../constants';
import {
  KeyValueObject,
  mapToIterable
} from '../../../../../../../common/utils';
import {
  commonDateRanges,
  DateRanges
} from '../../../../../../../common/constants';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import { orderBy } from 'lodash-es';
import {
  GlobalFiltersStyleMode,
  GlobalFiltersFunctionalityMode,
  InsightsFilter
} from '../../../../../../../modules/auth/insights/common/components/global-filters/global-filters.component';
import { InsightsFiltersService } from '../../../../../../auth/insights/common/components/global-filters/insights-filters.service';

export interface Option {
  key: string;
  label: string;
  icon?: string;
  query?: KeyValueObject;
}

export interface MarketingPostsConfigQuery {
  validated: string | null;
}

@Component({
  selector: 'ssi-widget-monitoring-stream-config',
  templateUrl: './widget-monitoring-stream-config.component.html',
  styles: []
})
export class WidgetMonitoringStreamConfigComponent implements OnInit {
  @Input() widget: DashboardWidget;

  colleagues: Colleague[];
  teams: Team[];
  workflowAccounts: Account[];
  streamIds: string[];
  streams: any[];
  filteredColleagues: Colleague[] = [];
  filteredTeams: Team[] = [];
  globalFiltersModel: InsightsFilter;

  widgetTypes = widgetTypes;
  widgetTitleMaxLength = 60;

  GlobalFiltersStyleMode = GlobalFiltersStyleMode;
  GlobalFiltersFunctionalityMode = GlobalFiltersFunctionalityMode;

  constructor(
    protected translate: TranslateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected colleaguesService: ColleaguesService,
    protected teamsService: TeamsService,
    private monitoringStreamsService: MonitoringStreamsService,
    private insightsFiltersService: InsightsFiltersService
  ) {}

  async ngOnInit() {
    if (!this.widget.config) {
      // new widget - set default config
      this.widget.config = {};
    }

    const [colleagues, teams, workflowAccounts] = await Promise.all([
      this.colleaguesService.getAll(),
      this.teamsService.getAll(),
      this.accountModel.findAllAccounts(this.workflowManager.getCurrentId())
    ]);

    this.workflowAccounts = orderBy(workflowAccounts, [
      'account_type_name',
      'name'
    ]);
    this.filteredColleagues = colleagues.sort(this._sortByFullName);
    this.filteredTeams = teams.sort(this._sortByTeamName);

    await this.monitoringStreamsService.getAll().then((streams) => {
      this.streams = streams;
      this.streams.sort((a, b) => a.name.localeCompare(b.name));
      this.streamIds = this.streams.map((s) => s.id);
    });

    this.globalFiltersModel = await this.insightsFiltersService.generateInsightsFiltersModel();

    if (
      this.widget &&
      this.widget.apiSearchParams &&
      this.widget.apiSearchParams.hasOwnProperty('filters')
    ) {
      this.globalFiltersModel = this.widget.apiSearchParams.filters;
    }
  }

  updateQueryFilters(updatedFilters: InsightsFilter) {
    this.widget.apiSearchParams = {
      ...this.widget.apiSearchParams,
      filters: updatedFilters
    };
  }

  /* accessed outside of this component - from the parent */
  public getConfig() {
    return this.widget.config;
  }

  private _sortByFullName(itemA: Colleague, itemB: Colleague) {
    return itemA.fullName.localeCompare(itemB.fullName);
  }

  private _sortByTeamName(itemA: Team, itemB: Team) {
    return itemA.name.localeCompare(itemB.name);
  }
}
