import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatChangeComponent } from './stat-change.component';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
@NgModule({
  imports: [CommonModule, BigNumberModule],
  declarations: [StatChangeComponent],
  exports: [StatChangeComponent]
})
export class StatChangeModule {}
