<div class="top-tags-list-box-container" [class.slim]="slim">
  <div class="body"
       *ngIf="tags.length">
    <div class="tag-bar"
         *ngFor="let tag of tags | slice:0:10; let i = index;"
         [class.top]="i === 0"
         [class.g-stat-box]="i === 0"
         [style.border-color]="i === 0 && competitor.colour">

      <ssi-medal-score *ngIf="i === 0"
                       [place]="1"
                       [color]="competitor.colour"></ssi-medal-score>
      <div class="progress-bar-wrapper">
        <div><span>#{{tag.word}}</span></div>
        <ssi-progress-bar [max]="topTagCount"
                          [progress]="tag.count"
                          [color]="competitor.colour"></ssi-progress-bar>
      </div>
      <div class="tag-count">
        <span>{{tag.count}}</span>
      </div>
    </div>
  </div>

  <div class="body-no-results"
       *ngIf="!tags.length">
       <h4>No data to display</h4>
       <p>This is either because we are still gathering data or there were no posts during the report period.</p>  </div>
</div>