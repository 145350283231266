import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatTeamStatisticsComponent } from './live-chat-team-statistics.component';
import './live-chat-team-statistics.scss';

@NgModule({
  declarations: [LiveChatTeamStatisticsComponent],

  exports: [LiveChatTeamStatisticsComponent],

  imports: [CommonModule, TranslateModule]
})
export class LiveChatTeamStatisticsModule {}
