import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import { Activity } from '@ui-resources-angular';
import { FieldName } from '../../../../angular/modules/auth/insights/common/constants/filters-field-definitions';

export interface WidgetSchema {
  name: FieldName;
  type: string;
  can_be_measure: boolean;
  can_be_dimension: boolean;
  supported_aggregate_functions: string[];
}

export interface Widget {
  name: string;
  type: string;
  break_down_by: {
    field: string;
  };
  group_by: null;
  values: [];
  filters: WidgetFilter[];
}

export interface WidgetFilter {
  field: string;
  eq: boolean;
}

export interface WidgetData {
  data: {} | number;
}

// export interface InsightsReport {
//   id: string;
//   name: string;
//   created_by: number;
//   is_shared: boolean;
//   created_at: string;
//   updated_at: string;
//   widgets?: {
//     type: string;
//     name: string;
//     description: string;
//     display_properties: {
//       x: number;
//       y: number;
//       w: number;
//       h: number;
//     };
//     filters: WidgetFilter[];
//     group_by: null;
//     values: [];
//   }[];
// }

@Injectable()
export class InsightsService {
  constructor(private api: API) {}

  // fetches the data to build the fields for creating widgets
  getWidgetBuildSchema(): Promise<WidgetSchema[]> {
    return this.api.get('insights/insightsSchema').then((res) => {
      return res.data.fields;
    });
  }

  // fetch widget list
  getWidgetWallet(): Promise<Widget[]> {
    return this.api.get('insights/insightsWidgetWallet').then((res) => {
      return res.data.widgets;
    });
  }

  // get preview data when creating widget
  getWidgetAggregateData(widget: Widget): Promise<WidgetData> {
    return this.api.post('insights/insightsWidgetData', widget).then((res) => {
      return res.data.data;
    });
  }

  // getReports(): Promise<InsightsReport> {
  //   return this.api.get('insights/insightsReport').then((res) => {
  //     return res.data.reports;
  //   });
  // }

  // getReport(id: string): Promise<InsightsReport> {
  //   return this.api.get(`insights/insightsReport?id=${id}`).then((res) => {
  //     return res.data;
  //   });
  // }

  // createReport(report: InsightsReport): Promise<InsightsReport> {
  //   return this.api.post('insights/insightsReport', report).then((res) => {
  //     return res.data;
  //   });
  // }

  // updateReport(id: string, report: InsightsReport): Promise<InsightsReport> {
  //   return this.api
  //     .post(`insights/insightsReport?id=${id}`, report)
  //     .then((res) => {
  //       return res.data;
  //     });
  // }
}
