import { Pipe, PipeTransform } from '@angular/core';

// date pipe should do this using 'c'/'cc' but doesn't, possibly cus ng version
@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {
  transform(value: Date): number {
    return value.getDay();
  }
}
