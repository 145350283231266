<ng-template
  #editWebhookModal
  let-close="close"
  let-dismiss="dismiss"
>
  <div class="modal-header">
    <h4
      class="modal-title"
      translate="EDIT_WEBHOOK"
      *ngIf="editing.webhook.uuid"
    >
    </h4>
    <h4
      class="modal-title"
      translate="CREATE_WEBHOOK"
      *ngIf="!editing.webhook.uuid"
    >
    </h4>
  </div>
  <div class="modal-body">
    <form
      novalidate
      #editWebhookForm="ngForm"
    >
      <div class="form-group">
        <label translate="NAME"></label>
        <input
          type="text"
          class="form-control"
          name="name"
          required
          [(ngModel)]="editing.webhook.name"
        >
      </div>
      <div class="form-group">
        <label translate="URL"></label>
        <input
          type="url"
          class="form-control"
          name="url"
          required
          pattern="https?://.+"
          [(ngModel)]="editing.webhook.destination_url"
        >
      </div>
      <div class="form-group">
        <label translate="DEVELOPER_EMAIL"></label>
        <input
          type="email"
          class="form-control"
          name="email"
          required
          [(ngModel)]="editing.webhook.dev_email"
          email
        >
      </div>
      <div class="form-group">
        <label translate="SECRET"></label>
        <input
          type="text"
          class="form-control"
          name="secret"
          [required]="false"
          placeholder="{{ editing.webhook.uuid ? ('ENTER_A_NEW_SECRET_OR_LEAVE_BLANK_TO_KEEP_THE_ORIGINAL' | translate) : '' }}"
          [(ngModel)]="editing.webhook.secret"
        >
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default"
      (click)="dismiss()"
      translate="CANCEL"
    >
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="close(editing.webhook)"
      [disabled]="!editWebhookForm.form.valid"
      translate="SAVE"
    >
    </button>
  </div>
</ng-template>
<div class="manage-webhooks">
  <div class="flex-grid container">
    <ssi-alerts></ssi-alerts>
    <div
      class="loading"
      *ngIf="webhooksLoadingTracker.active"
    ></div>
    <table
      class="orlo-table"
      *ngIf="!webhooksLoadingTracker.active"
    >
      <thead>
        <tr>
          <td colspan="7">
            <ul class="actions-menu">
              <a (click)="editWebhook({})">
                <i class="fa fa-plus"></i> <span translate="CREATE_NEW"></span>
              </a>
            </ul>
          </td>
        </tr>
        <tr>
          <th translate="NAME"></th>
          <th translate="URL"></th>
          <th translate="DEVELOPER_EMAIL"></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let webhook of webhooks">
          <td>{{ webhook.name }}</td>
          <td class="destination">{{ webhook.destination_url }}</td>
          <td class="email"><a [href]="'mailto:' + webhook.dev_email">{{ webhook.dev_email }}</a></td>
          <td class="text-center">
            <button
              class="action-button disabled"
              *ngIf="webhook.status === 'disabled'"
              [disabled]="saving.webhook === webhook && saving.tracker.active"
              (click)="saveWebhookChanges(webhook, {status: WebhookStatus.Enabled}, 'status')"
            >
              <i
                class="fa fa-spin fa-spinner"
                *ngIf="saving.webhook === webhook && saving.tracker.active && saving.mode === 'status'"
              >
              </i>
              <i class="ssi ssi-disable"></i> <span translate="DISABLED"></span>
            </button>
            <button
              class="action-button enabled"
              *ngIf="webhook.status === 'enabled'"
              [disabled]="saving.webhook === webhook && saving.tracker.active"
              (click)="saveWebhookChanges(webhook, {status: WebhookStatus.Disabled}, 'status')"
            >
              <i
                class="fa fa-spin fa-spinner"
                *ngIf="saving.webhook === webhook && saving.tracker.active && saving.mode === 'status'"
              >
              </i>
              <i class="ssi ssi-tick"></i> <span translate="ENABLED"></span>
            </button>
          </td>
          <td class="text-center">
            <orlo-button
              text="{{ 'TEST' | translate }}"
              (btnClick)="sendTestNotification(webhook)"
              [textColor]="'#00b9e5'"
              [bgColor]="'#f0f3f9'"
              leadingIcon="ssi ssi-test"
              [disabled]="webhook.status === 'disabled'"
            >
            </orlo-button>
          </td>
          <td class="text-center">
            <button
              class="action-button edit"
              [disabled]="saving.webhook === webhook && saving.tracker.active"
              (click)="editWebhook(webhook)"
            >
              <i
                class="fa fa-spin fa-spinner"
                *ngIf="saving.webhook === webhook && saving.tracker.active && saving.mode === 'all'; else editIcon"
              >
              </i>
            </button>
          </td>
          <td class="text-center">
            <button
              class="action-button delete"
              [disabled]="saving.webhook === webhook"
              (click)="deleteWebhook(webhook)"
            >
              <i class="ssi ssi-delete"></i>
            </button>
          </td>
        </tr>
        <tr
          class="bg-info"
          *ngIf="webhooks.length === 0"
        >
          <td colspan="5">
            <i class="fa fa-info"></i> <span translate="YOU_HAVENT_CREATED_ANY_WEBHOOKS_YET"></span>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>

<ng-template #editIcon>
  <i class="ssi ssi-correctedit"></i>
</ng-template>

<ng-template
  #deleteWebhookModal
  let-close="close"
  let-dismiss="dismiss"
>
  <div class="modal-header">
    <h4 class="modal-title">
      Delete Webhook
    </h4>
  </div>
  <div class="modal-body">
    Are you sure you want to delete this webhook?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default"
      (click)="dismiss()"
      translate="CANCEL"
    >
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="close(true)"
      translate="DELETE"
    >
    </button>
  </div>
</ng-template>