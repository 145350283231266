import './report-action-bar.component.scss';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  OnChanges,
  Renderer2
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { User, UserModel } from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsightsService, InsightsReport } from '../../../insights.service';

import {
  commonDateRanges,
  commonDateRangesIterable,
  DateRange,
  DateRanges
} from '../../../../../../common/constants';
import {
  Filter,
  Match,
  Query
} from '../../../reports/view/view-report.component';
import { AutomateModalComponent } from '../../../../../../common/components/automate-modal/automate-modal.component';
import { CompanyService } from '../../../../../../common/services/company/company.service';

export enum ReportView {
  Graph = 'graph',
  Comment = 'comment'
}

@Component({
  selector: 'ssi-report-action-bar',
  templateUrl: './report-action-bar.component.html',
  styles: []
})
export class ReportActionBarComponent implements OnInit, OnChanges {
  @Input() report: InsightsReport;
  @Input() reportView: ReportView = ReportView.Graph;
  @Input() showSaveButton = false;
  @Input() showPinButton = false;
  @Input() userOwnsReport = false;
  @Input() activeFilters: Filter[];
  @Input() pdfExportDisabled: boolean;
  @Input() widgetsLoaded: boolean;
  @Input() templateMode: boolean;

  @Output() onSaveClick = new EventEmitter();
  @Output() onRefreshClick = new EventEmitter();
  @Output() onFiltersClick = new EventEmitter();
  @Output() onDateChange = new EventEmitter<string>();
  @Output() onDateCustomClick = new EventEmitter();
  @Output() onAddWidgetClick = new EventEmitter();
  @Output() onShareClick = new EventEmitter();
  @Output() onEditReportClick = new EventEmitter();
  @Output() onCopyReportClick = new EventEmitter();
  @Output() onSettingsClick = new EventEmitter();
  @Output() onDeleteClick = new EventEmitter();
  @Output() onReportViewChange = new EventEmitter<ReportView>();
  @Output() onPinClick = new EventEmitter<boolean>();
  @Output() onKeywordFilter = new EventEmitter();
  @Output() onExportClick = new EventEmitter();

  pinned: boolean = true;
  tags: Array<Match | Query> = [];
  tagsCondition: string;
  dateOptions = commonDateRangesIterable.map((date) => date.label);
  dateRanges: DateRanges = commonDateRanges;
  dateRangesIterable: DateRange[] = commonDateRangesIterable;
  date: string = this.dateRanges.month.label;
  warningBanner = false;
  activeFilterTotal: number;
  browserlessMode = false;
  privateReports: InsightsReport[] = [];
  publicReports: InsightsReport[] = [];
  schedulesAutomatedReportsFeature = false;
  ReportView = ReportView;

  // dateOptions: string[] = [
  //   'Today',
  //   'This week',
  //   'Last 7d',
  //   'Last 14 days',
  //   'This month',
  //   'Last month',
  //   'Last 90 days',
  //   'This year'
  // ];

  constructor(
    protected renderer: Renderer2,
    protected state: StateService,
    protected insightsService: InsightsService,
    public modal: NgbModal,
    private company: CompanyService
  ) {}

  ngOnInit(): void {
    this.onPinClick.emit(this.pinned);
    this.onReportViewChange.emit(this.reportView);
    this.activeFilterTotal = this.activeFilters.filter(
      (filter) =>
        filter.all !== 'deleted' &&
        filter.all !== 'permission' &&
        filter.key !== 'keyword'
    ).length;

    this.insightsService.getReports().then((reports) => {
      const sortByNewestUpdated = (a, b) => {
        const dateA = Date.parse(a.updated_at);
        const dateB = Date.parse(b.updated_at);
        return dateA > dateB ? -1 : 1;
      };
      const sortedReports = reports.sort(sortByNewestUpdated);
      this.privateReports = sortedReports
        .filter((report) => !report.is_shared)
        .slice(0, 6);
      this.publicReports = sortedReports
        .filter((report) => report.is_shared)
        .slice(0, 6);
    });

    const fetchBrowserlessCookie = document.cookie.match(
      new RegExp('(^| )' + 'browserless_io' + '=([^;]+)')
    );
    this.browserlessMode = fetchBrowserlessCookie
      ? fetchBrowserlessCookie[2] === 'true'
      : false;

    console.log('params: ', this.state.params);
    if (this.state.params.disablePopup) {
      this.renderer.addClass(window.document.body, 'hide-pendo-popup');
    }

    this.company
      .hasFeatureAccess('SCHEDULED_REPORTS')
      .then((result) => (this.schedulesAutomatedReportsFeature = !!result));
  }

  refreshReport() {
    this.state.reload();
  }

  ngOnChanges(changes: SimpleChanges) {
    const keywordFilter = this.activeFilters.find(
      (filter) => filter.key === 'keyword'
    );
    if (keywordFilter) {
      this.tags = [keywordFilter.match || keywordFilter.query];
      this.tagsCondition = keywordFilter.match ? 'match' : 'query';
    } else {
      this.tags = [];
    }

    this.date = this.activeFilters.find(
      (filter) => filter.field === 'Created At'
    )
      ? this.activeFilters.find((filter) => filter.field === 'Created At').label
      : this.dateRanges.month.label;

    this.warningBanner = this.activeFilters.find(
      (filter) => filter.all === 'deleted' || filter.all === 'permission'
    )
      ? true
      : false;

    this.activeFilterTotal = this.activeFilters.filter(
      (filter) =>
        filter.all !== 'deleted' &&
        filter.all !== 'permission' &&
        filter.key !== 'keyword'
    ).length;
  }

  dateSelected(date) {
    this.date = date;
    this.onDateChange.emit(
      this.dateRangesIterable.find((range) => range.label === date).id
    );
  }

  async openAutomateReportModal() {
    const automateModal = this.modal.open(AutomateModalComponent, {
      windowClass: 'orlo-modal'
    });
    automateModal.componentInstance.editMode = false;
    automateModal.componentInstance.reportUrl = this.state.href(
      this.state.current.name,
      this.state.params,
      { absolute: true }
    );
  }
}
