import { Pipe, PipeTransform } from '@angular/core';
import substring from 'unicode-substring';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(text, length = 10, end = '...'): string {
    if (!text) {
      return '';
    }
    if (text.toString().length - end.length <= length) {
      return text;
    }
    return `${substring(text + '', 0, length - end.length)}${end}`;
  }
}
