<div class="dashboard-widget dashboard-widget-social-wall">

  <div class="form-field">
    <label class="field-title">ACCOUNT</label>
    <div class="input-wrapper">
      <ssi-dropdown-select-2
        name="accounts"
        class="accounts-selector"
        size="lg"
        [required]="true"
        [filterable]="true"
        [filterPlaceholder]="'Search for an account...'"
        [options]="accounts"
        [(ngModel)]="selectedAccount"
        (ngModelChange)="accountSelected()"
      >
        <ng-template
          ssiTemplateSelector="headButtonLabel"
          let-expanded="expanded"
        >
          <div
            *ngIf="selectedAccount"
            class="select-accounts-label"
          >
            <ssi-social-network-icon
              *ngIf="selectedAccount?.account_type_id"
              [accountTypeId]="selectedAccount.account_type_id"
              [size]="20"
            ></ssi-social-network-icon>
            <span>{{selectedAccount?.name}}</span>
          </div>
          <div
            *ngIf="!selectedAccount"
            class="select-accounts-label"
          >
            <span>Select an account...</span>
          </div>
        </ng-template>
        <ng-template
          ssiTemplateSelector="optionIcon"
          let-account="option"
        >
          <ssi-social-network-icon
            [accountTypeId]="account.account_type_id"
            [size]="20"
          ></ssi-social-network-icon>
        </ng-template>
      </ssi-dropdown-select-2>
    </div>
  </div>

  <div class="form-field" *ngIf="socialListsOptions">
    <label class="field-title">Select a list (optional)</label>
    <div class="input-wrapper">
      <ssi-dropdown-select-2
        name="accounts"
        class="accounts-selector"
        size="lg"
        [filterable]="true"
        [filterPlaceholder]="'Search for a list...'"
        [options]="socialListsOptions"
        [(ngModel)]="selectedList"
        (ngModelChange)="selectedListChanged()"
      >
      </ssi-dropdown-select-2>
    </div>
  </div>

  <div
    class="message-info view-{{ viewClass }}"
    *ngIf="!selectedAccount"
  >
    <i class="ssi ssi-select-microcopy"></i>
    <h5>Select an account</h5>
    <p class="view-{{ viewClass }}">Choose an account to interact with</p>
  </div>

  <div
    *ngIf="selectedAccount && activities && activities.length"
    class="article-grid view-{{ viewClass }}"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    [infiniteScrollDisabled]="activities[activities.length - 1].id === viewPortActivities[viewPortActivities.length - 1]?.id"
    [scrollWindow]="false"
    (scrolled)="onScrolled()"
    ssiVirtualScroll
    [items]="activities"
    [bufferAmount]="5"
    [getItemHeight]="getHeight"
    (viewPortItemsUpdated)="viewPortActivitiesUpdated($event)"
  >
    <ssi-activity
      *ngFor="let activity of viewPortActivities"
      class="social-wall"
      [isSocialWall]="true"
      [activity]="activity"
      [showReply]="!activity.interaction.deleted_at"
      [isParent]="true"
    >
    </ssi-activity>
  </div>

</div>