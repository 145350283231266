import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingObjectivesComponent } from './advertising-objectives.component';
import { AdvertisingNavModule } from '../advertising-nav/advertising-nav.module';
import { TranslateModule } from '@ngx-translate/core';
import { AdvertisingTableFiltersModule } from '../advertising-table/advertising-table-filters/advertising-table-filters.module';
import { PostModule } from '../../../../../common/components/post/post.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.adObjectives',
          url: '/ad-objectives',
          component: AdvertisingObjectivesComponent,
          resolve: AdvertisingObjectivesComponent.resolve
        }
      ]
    }),
    AdvertisingNavModule,
    AdvertisingTableFiltersModule,
    PostModule,
    TooltipModule
  ],
  declarations: [AdvertisingObjectivesComponent],
  exports: [AdvertisingObjectivesComponent]
})
export class AdvertisingObjectivesModule {}
