import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInWeeks,
  differenceInYears
} from 'date-fns';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import strings from '../constants/strings';
import symbols from '../constants/symbols';

export function get_relative_age_of_date(date: Date): string {
  const now = Date.now();

  const yearsDifference = differenceInYears(now, date);
  if (yearsDifference) {
    return `${yearsDifference}${symbols.year} ${strings.ago}`;
  }

  const weeksDifference = differenceInWeeks(now, date);
  if (weeksDifference) {
    return `${weeksDifference}${symbols.week} ${strings.ago}`;
  }

  const daysDifference = differenceInDays(now, date);
  if (daysDifference) {
    return `${daysDifference}${symbols.day} ${strings.ago}`;
  }

  const hoursDifference = differenceInHours(now, date);
  if (hoursDifference) {
    return `${hoursDifference}${symbols.hour} ${strings.ago}`;
  }

  const minutesDifference = differenceInMinutes(now, date);
  if (minutesDifference) {
    return `${minutesDifference}${symbols.minute} ${strings.ago}`;
  }

  return strings.now;
}

export function timestamp_to_date(
  timestamp: firebase.firestore.Timestamp
): Date {
  return !!timestamp ? new Date(timestamp.seconds * 1e3) : new Date();
}
