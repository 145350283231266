import { Component } from '@angular/core';

@Component({
  selector: 'ssi-team',
  templateUrl: './team.component.html',
  styles: []
})
export class TeamComponent {
  constructor() {}
}
