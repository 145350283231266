import './report.component.scss';

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import moment from 'moment';

import {
  AccountModel,
  Account,
  Outbox,
  UserModel
} from '@ui-resources-angular';
import { isElementWithinViewport } from '../../../../../common/utils';
import { Debounce } from '../../../../../common/decorators';
import {
  FileDownloadService,
  SpreadsheetService
} from '../../../../../common/services';
import { GlobalLoaderService } from '../../../../../common/directives';
import { ServerSortParams } from '../../../../../common/components/treetable';
import { AnalyticsService } from '../../analytics.service';
import {
  PageChangeParams,
  PaginatorComponent
} from '../../../../../common/components/paginator';
import {
  SurveyService,
  SurveyReport,
  DateRange,
  SurveyType,
  SortField,
  SortOrder,
  Aggregation
} from '../../../../../common/services/api/surveys';
import { FiltersFormValue } from './filters/filters.component';
import { SurveyResponsesComponent } from './survey-responses/survey-responses.component';
import { SnackbarService } from '../../../../../common/services/snackbar/snackbar.service';

@Component({
  selector: 'ssi-report',
  templateUrl: './report.component.html',
  styles: []
})
export class ReportComponent implements OnInit, AfterViewInit {
  @ViewChild(SurveyResponsesComponent) summarySection: SurveyResponsesComponent;

  reportActions = this.getReportActions();
  navItems = this.getNavItems();
  activeNavItemTag = 'account-summary';

  accountIds: string[] = [];
  accounts: Account[] = [];
  dateRange: DateRange;
  filters: FiltersFormValue;
  pageChangeParams: PageChangeParams;
  sortParams: ServerSortParams;

  report: SurveyReport;
  reportsPerPage: { [key: number]: SurveyReport } = {};
  loading = false;
  readonly itemsPerPage = 20;
  aggregation: Aggregation;
  // readonly pagesToLoadInAdvance = 2;

  constructor(
    protected state: StateService,
    protected elementRef: ElementRef,
    protected analyticsService: AnalyticsService,
    protected surveyService: SurveyService,
    protected spreadsheetService: SpreadsheetService,
    protected fileDownloadService: FileDownloadService,
    protected globalLoader: GlobalLoaderService,
    protected accountModel: AccountModel,
    private userModel: UserModel,
    private snackbar: SnackbarService
  ) {}

  async ngOnInit(): Promise<void> {
    console.log('this.state.params: ', this.state.params);

    this.setAccounts();
    this.setDateRange();
  }

  ngAfterViewInit(): void {
    this.populateNavItemElements();
  }

  async getReport(
    filters: FiltersFormValue,
    pageChangeParams: PageChangeParams,
    sortParams: ServerSortParams
  ): Promise<SurveyReport> {
    this.loading = true;
    try {
      const report = await this.surveyService.getReport(
        this.accountIds,
        filters.surveyType.key,
        filters.startDate,
        filters.endDate,
        filters.colleagues.map((c) => Number(c.id)),
        filters.minScore,
        filters.maxScore,
        filters.surveyee,
        filters.comment,
        pageChangeParams.skip,
        pageChangeParams.take,
        sortParams.field ? (sortParams.field as SortField) : SortField.DT,
        sortParams.orderStr
          ? (sortParams.orderStr.toLowerCase() as SortOrder)
          : SortOrder.DESC
      );

      this.loading = false;
      return report;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);
      // window.history.back();
      this.loading = false;
      return undefined;
    }
  }

  async loadAndSetReport(): Promise<void> {
    if (this.reportsPerPage[this.pageChangeParams.currentPage]) {
      this.report = this.reportsPerPage[this.pageChangeParams.currentPage];
      return;
    }

    const report = await this.getReport(
      this.filters,
      this.pageChangeParams,
      this.sortParams
    );
    if (report && report.surveys.length) {
      this.reportsPerPage[this.pageChangeParams.currentPage] = report;
    }

    if (report.aggregation) {
      this.aggregation = report.aggregation;
    }

    this.report = this.reportsPerPage[this.pageChangeParams.currentPage];

    setTimeout(() => {
      this.scrollTo('survey-summary');
    });
  }

  setAccounts(): void {
    this.accountIds = Array.isArray(this.state.params.accountIds)
      ? this.state.params.accountIds
      : [this.state.params.accountIds];

    this.accounts = this.accountModel
      .filter({
        where: { id: { in: this.accountIds } }
      })
      .sort((a1, a2) => Number(a1.id) - Number(a2.id));
  }

  setDateRange(): void {
    this.dateRange = {
      start: new Date(this.state.params.startDate),
      end: new Date(this.state.params.endDate)
    };
  }

  async reportDateChanged(dateRange): Promise<void> {
    // from report top bar
    this.dateRange.start = new Date(dateRange.from);
    this.dateRange.end = new Date(dateRange.to);
  }

  onFiltersSubmit(filters: FiltersFormValue): void {
    // will also fire on filters component init
    this.filters = filters;

    this.reportsPerPage = {}; // reset cached reports
    setTimeout(() => {
      this.summarySection.paginator.init();
    });
  }

  onSort(params: ServerSortParams): void {
    // will also fire on treetable component init
    this.sortParams = params;

    this.reportsPerPage = {}; // reset cached reports
    setTimeout(() => {
      this.summarySection.paginator.init();
    });
  }

  async onPageChange(pageChangeParams: PageChangeParams): Promise<void> {
    // will also fire on survey-responses component init
    this.pageChangeParams = pageChangeParams;

    // do not load report unless filters and sortParams are ready - wait for them to be set
    // sortParams will be emitted from treetable (as it saves sorting choices to localStorage)
    if (this.filters && this.sortParams) {
      await this.loadAndSetReport();
    }
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -170;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });

    // const viewPortItem = this.navItems.find((item) => {
    //   return item && isElementWithinViewport(item.element);
    // });

    // if (viewPortItem) {
    //   this.activeNavItemTag = viewPortItem.tag;
    // }
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });

      this.setActiveNavItem();
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Filters',
        tag: 'filters',
        element: undefined
      },
      {
        label: 'Summary',
        tag: 'survey-summary',
        element: undefined
      },
      {
        label: 'Survey Responses',
        tag: 'survey-responses',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      {
        icon: 'ssi-print',
        label: 'Export PDF',
        func: async () => {
          if (this.loading) {
            console.log('still loading');
            return;
          }
          this.openBrowserPrint();
        }
      },
      // {
      //   icon: 'ssi-print',
      //   label: 'Download XLSX',
      //   func: () => {} // this.downloadXlsx.bind(this) // TODO...
      // },
      {
        icon: 'ssi-print',
        label: 'Download CSV',
        func: async () => {
          const user = await this.userModel.getAuthUser();
          this.surveyService
            .exportCSV(
              user.company_id,
              this.accountIds,
              this.filters.surveyType.key,
              this.filters.startDate,
              this.filters.endDate,
              this.filters.colleagues.map((c) => Number(c.id)),
              this.filters.surveyee,
              this.filters.comment
            )
            .then(() => {
              this.snackbar.open(
                'Success! A CSV download of this Survey Report has been emailed to you.',
                {
                  duration: 5,
                  position: 'top',
                  snackbarClass: 'snackbar-style-1'
                }
              );
            });
        }
      },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => {
          this.state.go('auth.analytics.survey.build', this.state.params);
        }
      }
    ];
  }

  openBrowserPrint() {
    window.print();
  }
}
