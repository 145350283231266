import { Injectable } from '@angular/core';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import { Activity, ActivityModel } from '@ui-resources-angular';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class InboxPinnedActivitiesService {
  constructor(
    private workflowManager: WorkflowManagerService,
    private activityModel: ActivityModel,
    private localStorageService: LocalStorageService
  ) {}

  getStorageKey() {
    return `inbox-pinned-tabs-${this.workflowManager.getCurrentId() || 'all'}`;
  }

  persist(activities) {
    const ids = activities.map((activity) => ({ id: activity.id }));
    this.localStorageService.set(this.getStorageKey(), ids);
  }

  async restore() {
    try {
      const ids: any = this.localStorageService.get(this.getStorageKey()) || [];
      const storedPinnedActivitiesPromise: Activity[] = ids.map(
        async ({ id }) => {
          if (this.activityModel.get(id)) {
            return this.activityModel.get(id);
          } else {
            return await this.activityModel
              .findAll({ id }, { autoError: false })
              .catch(() => {}); // tslint:disable-line
          }
        }
      );
      const storedPinnedActivities = await Promise.all(
        storedPinnedActivitiesPromise
      );
      return storedPinnedActivities;
    } catch (error) {
      console.error('Error Restoring pinned activities:', error);
    }
  }

  add(existing: Activity[] = [], activity: Activity) {
    const newList = [...existing];
    if (!existing.includes(activity)) {
      newList.unshift(activity);
      this.persist(newList);
    }
    return newList;
  }

  remove(existing: Activity[], activity: Activity) {
    const newList = existing.filter((iActivity) => iActivity !== activity);
    this.persist(newList);
    return newList;
  }

  toggle(existing: Activity[] = [], activity: Activity) {
    if (existing.includes(activity)) {
      return this.remove(existing, activity);
    } else {
      return this.add(existing, activity);
    }
  }
}
