import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ServiceComponent } from './service.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet.service',
          url: '/service',
          component: ServiceComponent
        }
      ]
    })
  ],
  declarations: [ServiceComponent]
})
export class ServiceModule {}
