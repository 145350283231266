<div class="post-errors-container">
  <ul class="errors-list">
    <li
      class="post-error"
      *ngIf="post.blockingWordMatches.length > 0"
    >
      <div class="error-message">
        <i class="ssi ssi-warning"></i>
        <p> We have caught the blocked {{ post.exactBlockingMatchesInText.length === 1 ? 'word' : 'words' }} <span>
            {{ post.exactBlockingMatchesInText.join(', ') }}</span>&nbsp;in your caption
          used on your&nbsp;<span [innerHTML]="post.accountsContainingBlockingWordsLabel">
          </span>
          {{ post.accountsContainingBlockingWords.length === 1 ? ' account' : ' accounts'}}.
        </p>
      </div>
      <a
        class="help-link"
        href="https://support.orlo.tech/profanity-filter"
        target="_blank"
        rel="noopener noreferrer"
      >Help doc link</a>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.videoRequired"
    >
      <i class="ssi ssi-warning"></i>
      <p>You must attach a video to the post</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.linkRequired"
    >
      <i class="ssi ssi-warning"></i>
      <p>You must include one link in the post</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.videoTitleRequired"
    >
      <i class="ssi ssi-warning"></i>
      <p>You must include a video title in the post</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.gifTitleRequired"
    >
      <i class="ssi ssi-warning"></i>
      <p>You must include a gif title in the post</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.textRequired"
    >
      <i class="ssi ssi-warning"></i>
      <p>
        <span *ngIf="!post.isSplit">The {{ post.isDraft ? 'draft' : 'post' }} must include some text</span>
        <span *ngIf="post.isSplit">This {{ post.isDraft ? 'draft' : 'post' }} has been split between social networks -
          you must include text for each
          account.</span>
      </p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.accountsRequired"
    >
      <i class="ssi ssi-warning"></i>
      <p>You need to select at least one account, write your post and add
        content before you can preview your post.</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.characterLimitExceeded"
    >
      <i class="ssi ssi-warning"></i>
      <p>Your {{ post.isDraft ? 'draft' : 'post' }} exceeds the character count</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.mediaRequired"
    >
      <i class="ssi ssi-warning"></i>
      <p>You must attach either an image or a video</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.linksAlreadyShortened"
    >
      <i class="ssi ssi-warning"></i>
      <p>Some of the links you have added have already been shortened
      </p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.albumCannotBeMultiScheduled"
    >
      <i class="ssi ssi-warning"></i>
      <p>You cannot schedule a post with multiple images more
        than once</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.schedulingErrors"
    >
      <i class="ssi ssi-warning"></i>
      <p>There are scheduling errors that need to be addressed. Please check the scheduling section for more details</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.expiryDateMissing"
    >
      <i class="ssi ssi-warning"></i>
      <p>You have enabled post expiry, but haven't provided a time or date</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.expiryDateInPast"
    >
      <i class="ssi ssi-warning"></i>
      <p>Post expiry date or time can't be set in past</p>
    </li>
    <li
      class="post-error"
      *ngIf="post.validity.errors.duplicatePostText"
    >
      <i class="ssi ssi-warning"></i>
      <p>
        In order to comply with X's spamming rules, all posts must have unique text. Posts on the following accounts
        have the same text:
      </p>
      <ul
        class="duplicates-text-account-list"
        *ngFor="let accountGroup of post.validity.duplicatePostTextAccounts"
      >
        <li *ngFor="let account of accountGroup.accounts">
          <i
            class="fa-fw"
            [ngClass]="account.socialNetwork.icon.web"
          ></i> {{ account.displayName }}
        </li>
      </ul>
    </li>

    <ng-container *ngIf="shareFirstCommentErrors?.length">
      <li
        class="post-error"
        *ngFor="let error of shareFirstCommentErrors"
      >
        <i class="ssi ssi-warning"></i>
        <p>{{error.message}}</p>
      </li>
    </ng-container>
  </ul>
</div>