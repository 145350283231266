<div class="dashboard-widget">
  <div class="dwm-form">
    <div class="dwm-form-half">
      <!-- Widget Text Input -->
      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="widgetTitle"
        >Widget Title (Optional)</label>
        <div class="dwm-body-row-input">
          <input
            class="dwm-input"
            id="widgetTitle"
            type="text"
            placeholder="Type your widget title here..."
            [(ngModel)]="widget.title"
          >
          <span
            [ngClass]="{'dwm-body-row-input-meta-active':  widget.title.length}"
            class="dwm-body-row-input-meta"
          >
            {{widgetTitleMaxLength - widget.title.length}}
          </span>
        </div>
      </div>

      <!-- Widget Dropdown Input -->
      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="allOfTheseWords"
        >Account Feeds</label>
        <ssi-dropdown-select-2
          name="accountFeeds"
          class="dwm-dropdown-selector"
          size="lg"
          [required]="true"
          [multiple]="true"
          [toggleAllEnabled]="false"
          [filterable]="true"
          [placeholdersKeyword]="'account feeds'"
          [options]="accountFeeds"
          [(ngModel)]="selectedAccountFeeds"
          (ngModelChange)="onSelectedAccountFeedsChange($event)"
        >
        </ssi-dropdown-select-2>
      </div>
    </div>
    <div class="dwm-form-half">
      <!-- Widget Text Input w/o Count -->
      <div class="dwm-body-row">
        <label
          class="dwm-label"
          for="allOfTheseWords"
        >Company Feeds</label>
        <ssi-dropdown-select-2
          name="companyFeeds"
          class="dwm-dropdown-selector"
          [required]="true"
          [multiple]="true"
          [toggleAllEnabled]="false"
          [filterable]="true"
          [placeholdersKeyword]="'company feeds'"
          [options]="companyFeeds"
          [(ngModel)]="selectedCompanyFeeds"
          (ngModelChange)="onSelectedCompanyFeedsChange($event)"
        >
        </ssi-dropdown-select-2>
      </div>
    </div>
  </div>
</div>