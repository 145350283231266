import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { EnableSocialChatbotsComponent } from './enable-social-chatbots.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.enableSocialChatbots',
          url: '/enable-social-chatbots',
          component: EnableSocialChatbotsComponent,
          resolve: EnableSocialChatbotsComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  declarations: [EnableSocialChatbotsComponent],
  entryComponents: [EnableSocialChatbotsComponent]
})
export class EnableSocialChatbotsModule {}
