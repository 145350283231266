<div class="inbox-crm-sidebar-container">
  <div
    *ngIf="!selectedIntegration"
    class="inbox-crm-sidebar-collapse"
  >
    <button (click)="onHide.emit()">
      <ssi-icon
        icon="minimize"
        height="27"
        width="18"
      ></ssi-icon>
    </button>
  </div>

  <div *ngIf="!selectedIntegration" class="verified-wrap">
    <img
      *ngIf="activityProfile?.avatar"
      class="inbox-crm-sidebar-img"
      [src]="activityProfile.avatar"
      ssiFallbackImage="avatarOutgoing"
    >
    <img
      *ngIf="!activityProfile?.avatar"
      class="inbox-crm-sidebar-img"
      ssiFallbackImage="avatarOutgoing"
    >
    <i *ngIf="activityProfile?.verified" class="verified ssi ssi-verified-icon"></i>
  </div>

  <div
    *ngIf="!selectedIntegration"
    class="inbox-crm-sidebar-stats"
    >
    <div *ngIf="!profile" class="loading"></div>

    <div *ngIf="profileAvailable">
      <ul class="stats">
        <li>
          <span>
            <i class="fa fa-fw fa-diamond"></i>
            <span class="stat-name" translate="SOCIAL_SCORE"></span>
          </span>
          <span class="stat-value">
            {{ profile.socialEndorserScore }}%
          </span>
        </li>
        <li>
          <span>
            <i class="fa fa-fw" [ngClass]="account.socialNetwork.icon.web"></i>
            <span class="stat-name" translate="INTERACTIONS"></span>
          </span>
          <span class="stat-value">{{ profile.inbox_stats.total | bigNumber }} </span>
        </li>
      </ul>

      <ssi-crm-profile-sentiment-bar
        [thin]="true"
        [profile]="profile"
      ></ssi-crm-profile-sentiment-bar>
    </div>
  </div>

  <div
    *ngIf="!profileAvailable"
    class="inbox-crm-sidebar-profile-unavailable"
  >
    <i class="ssi ssi-confetti"></i>
    <h5>Profile Unavailable</h5>
    <div><span>This is due to network privacy restrictions.</span></div>
    <button (click)="onHide.emit()">
      <span translate="CLOSE"></span>
    </button>
  </div>

  <div
    *ngIf="profileAvailable"
    class="inbox-crm-sidebar-bottom"
    [ngClass]="{
      'inbox-crm-sidebar-notes-active': notesOpen,
      'inbox-crm-sidebar-external-integration-person-active': selectedIntegration
    }"
  >
    <div class="inbox-crm-sidebar-tags">
      <ssi-crm-person-tags [crmPerson]="crmPerson"></ssi-crm-person-tags>
    </div>

    <ul
      *ngIf="!notesOpen && !selectedIntegration"
      class="modals"
    >
      <li
        class="inbox-crm-sidebar-stats-profile"
        [ngClass]="{'btn-group': linkedSocialProfiles?.length > 0}"
        ngbDropdown
      >
        <button
          [ngClass]="{'btn-block': linkedSocialProfiles?.length === 0}"
          (click)="viewProfile({accountId: account.id, profileId: activityProfile.id})"
        >
          <span translate="VIEW_FULL_PROFILE"></span>
        </button>
        <ul
          class="dropdown-menu dropdown-menu-right dropdown-menu-inbox"
          ngbDropdownMenu
        >
          <li *ngFor="let profile of linkedSocialProfiles">
            <button
              (click)="viewProfile({
                accountId: profile.account_id,
                profileId: profile.social_id
              })"
            >
              <i [ngClass]="profile.account.socialNetwork.icon.web"></i>
              {{ profile.name }}
            </button>
          </li>
        </ul>
      </li>

      <li
        (click)="showInboxListWithThisName()"
        [ngbPopover]="'FILTER_YOUR_INBOX_BY_THIS_AUTHORS_NAME_TO_VIEW_YOUR_PREVIOUS_MESSAGES' | translate"
        triggers="mouseenter"
        popoverClass="popover-dark"
        container="body"
      >
        <button class="inner" translate="PREVIOUS_MESSAGES"></button>
      </li>

      <li
        *ngFor="let integration of externalIntegrations?.linked"
        class="active"
        (click)="showExternalCrmPerson.emit({integration: integration})"
      >
        <button class="inner">
          {{ integration.integration.name }}
        </button>
      </li>

      <li
        *ngFor="let integration of externalIntegrations?.unlinked"
        (click)="linkIntegration.emit({integration: integration})"
      >
        <button class="inner">
          {{ integration.name }}
        </button>
      </li>
      <div
        *ngFor="let plugin of plugins"
        class="plugins"
      >
        <li *ngFor="let action of initialisedPlugins[plugin.id]?.actions | filter: {type: checkIfLinkedInPlugin(initialisedPlugins[plugin.id]?.context)}">
          <button
            class="inner"
            (click)="onHide.emit(); handlePluginCallback.emit({
              type: action.type,
              callbackId: action.callbackId,
              context: initialisedPlugins[plugin.id]?.context
            })"
          >{{action.name}}</button>
        </li>
      </div>
    </ul>

    <ssi-crm-person-notes
      *ngIf="notesOpen && !selectedIntegration"
      [crmPerson]="crmPerson"
      [closable]="true"
      (onClose)="toggleNotes()"
    ></ssi-crm-person-notes>

    <div
      *ngIf="crmPerson?.notes && !notesOpen && ! selectedIntegration"
      class="inbox-crm-sidebar-profile-notes"
      [ngClass]="{'active': crmPerson.notes.length > 0}"
    >
      <div
        *ngIf="crmPerson?.notes.length"
        class="profile-note"
        >
        <div class="profile-note-meta">
          <span>{{ crmPerson?.notes[0].created_at | date:'d MMM HH:mm' }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ crmPerson?.notes[0].author.fullName }}</span>
        </div>
        <p
          class="profile-note-content disabled-links"
          [innerHTML]="crmPerson?.notes[0].content | nl2br"
        ></p>
      </div>

      <button *ngIf="!notesOpen" (click)="toggleNotes()">
        <span class="profile-notes-button-label" translate="PROFILE_NOTES"></span>
        <span *ngIf="crmPerson?.notes.length > 1">&nbsp;({{crmPerson?.notes.length - 1}})</span>
      </button>
    </div>

    <div
      *ngIf="selectedIntegration"
      class="inbox-crm-sidebar-external-integration-person-header"
    >
      <div class="inner">
        <div class="inbox-crm-sidebar-external-integration-person-header-left">
          <button (click)="closeSelectedIntegration.emit()">
            <i class="fa fa-chevron-left"></i>
            <span translate="BACK"></span>
          </button>
        </div>

        <div class="inbox-crm-sidebar-external-integration-person-header-right">
          <span
            class="inbox-crm-sidebar-unlink-external-integration-person"
            (click)="unlinkIntegration.emit({integration: selectedIntegration})"
            [ngbPopover]="'UNLINK_CRM' | translate"
            triggers="mouseenter"
            container="body"
            popoverClass="popover-dark"
            placement="left"
          >
            <i class="fa fa-chain-broken"></i>
          </span>

          <span
            class="inbox-crm-sidebar-expand-external-integration-person"
            (click)="onExpandCrmProfile.emit()"
            [ngbPopover]="'EXPAND_PROFILE' | translate"
            triggers="'mouseenter'"
            container="true"
            popoverClass="popover-dark"
            placement="left"
          >
            <i class="fa fa-expand"></i>
          </span>
        </div>
      </div>
    </div>

    <div
      *ngIf="selectedIntegration"
      class="inbox-crm-sidebar-external-integration-person-container"
    >
      <div
        class="loading"
        *ngIf="personLoadingTracker.active"
      ></div>

      <ssi-inbox-crm-external-integration-person
        *ngIf="!personLoadingTracker.active && externalIntegrationPerson"
        [activity]="activity"
        [crmPerson]="crmPerson"
        [externalIntegrationPerson]="externalIntegrationPerson"
      ></ssi-inbox-crm-external-integration-person>
    </div>
  </div>
</div>
