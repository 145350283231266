import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetRssFeedsConfigComponent } from './widget-rss-feeds-config.component';
import { InboxQueryResultListModule } from '../../../../../../auth/inbox/inbox-query-result-list/inbox-query-result-list.module';
import { AutoHeightModule } from '../../../../../../../common/directives/auto-height/auto-height.module';
import { InboxActivityModalModule } from '../../../../../../../common/components/inbox-activity/inbox-activity-modal/inbox-activity-modal.module';
import { FormsModule } from '@angular/forms';
import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';

@NgModule({
  imports: [
    CommonModule,
    InboxQueryResultListModule,
    AutoHeightModule,
    InboxActivityModalModule,
    FormsModule,
    DropdownSelect2Module
  ],
  exports: [WidgetRssFeedsConfigComponent],
  declarations: [WidgetRssFeedsConfigComponent]
})
export class WidgetRssFeedsConfigModule {}
