<div class="cl-accordion-container">
  <div class="head">
    <h2>Accordion</h2>
  </div>

  <div class="body">
    <div>
      <h4>A - individual</h4>
			<ssi-accordion
        label="Header"
		    [isOpen]="false"
		    [isValid]="true"
      >
        <p>body</p>
      </ssi-accordion>

      <ssi-accordion
        label="Orlo Tags & Campaigns"
		    [isOpen]="false"
		    [isValid]="true"
      >
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </ssi-accordion>

      <ssi-accordion
        [label]="customHeading"
		    [isOpen]="false"
		    [isValid]="true"
      >
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </ssi-accordion>

      <ng-template #customHeading>
        <div class="toggle-label">
          <p>This is a template label</p>
          <ssi-toggle-switch-2></ssi-toggle-switch-2>
        </div>
      </ng-template>

      <ssi-accordion
        label="Invalid"
		    [isOpen]="false"
		    [isValid]="false"
      >
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </ssi-accordion>
		</div>

    <div>
      <h4>B - group</h4>

			<ssi-accordion
        label="Header"
		    [isOpen]="accordionOpen === 1"
		    [isValid]="true"
        (onAccordionOpenClose)="accordionOpen = $event ? 1 : 0"
      >
        <p>body</p>
      </ssi-accordion>
      <ssi-accordion
        label="Header"
		    [isOpen]="accordionOpen === 2"
		    [isValid]="true"
        (onAccordionOpenClose)="accordionOpen = $event ? 2 : 0"
      >
        <p>body</p>
      </ssi-accordion>
      <ssi-accordion
        label="Header"
		    [isOpen]="accordionOpen === 3"
		    [isValid]="true"
        (onAccordionOpenClose)="accordionOpen = $event ? 3 : 0"
      >
        <p>body</p>
      </ssi-accordion>

      <h5>(optional: Control externally)</h5>
      <div class="btn-group" role="group">
        <button class="btn btn-default" (click)="accordionOpen = 0">close all</button>
        <button class="btn btn-default" (click)="accordionOpen = 1">open first</button>
        <button class="btn btn-default" (click)="accordionOpen = 3">open last</button>
      </div>
    </div>
  </div>
</div>