import './cl-dropdown-select-c.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import { Account, AccountModel } from '@ui-resources-angular';
import { groupBy } from '../../../../../common/utils';

@Component({
  selector: 'ssi-cl-dropdown-select-c',
  templateUrl: './cl-dropdown-select-c.component.html',
  styles: []
})
export class ClDropdownSelectCComponent implements OnInit {
  @Input() accounts: Account[] = [];
  selectedAccounts: Account[] = [];
  selectedAccountTypeIds: string[] = [];

  constructor() {}

  ngOnInit() {}

  selectedAccountsChanged(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
    this.setSelectedAccountTypeIds();
  }

  setSelectedAccountTypeIds(): void {
    this.selectedAccountTypeIds = [];

    const accountGroups = groupBy(
      this.selectedAccounts,
      (account) => account.account_type_id
    );

    accountGroups.forEach((accountGroup) => {
      this.selectedAccountTypeIds.push(accountGroup[0].account_type_id);
    });
  }
}
