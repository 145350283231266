<div class="email-to">
  <div class="exit"
       (click)="activeModal.close(null)">
    <span><i class="ssi ssi-times"></i></span>
  </div>
  <div class="header">
    <i class="ssi ssi-forward"></i>
    <h2>Email messages</h2>
    <p class="info">To a person who doesn't use Orlo</p>

    <orlo-button textColor="#14bae3"
                 state="text"
                 [text]="'Select all messages'"
                 (btnClick)="selectAllActivities()"></orlo-button><span>or tap messages individually to add to email</span>
  </div>



  <div class="scroll-container custom-scrollbar">
    <div *ngFor="let activity of threadActivities">
      <div class="message-box"
           (click)="selectActivity(activity)"
           [ngClass]="{'message-box-self-penned':isOutboundMessage(activity),'selected':isActivitySelected(activity)}">
        <div class="message-box-container">
          <div class="message-box-message-wrap">
            <div class="message-box-avatar-wrap">
              <img class="message-box-avatar"
                   [src]="activity.author.avatar">
            </div>

            <div
              class="message-box-message"
              [ssiEmojiText]="parseMessage(activity.interaction.original_content || activity.interaction.content)"
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="send-to-container">
    <form *ngIf="sendToForm"
          class="content-form"
          [formGroup]="sendToForm">
      <div class="form-field">
        <orlo-text-input [label]="'Email Address'"
                         [name]="'emailAddress'"
                         [parentForm]="sendToForm"
                         [type]="'text'"
                         [placeholder]="'Enter multiple addresses seperated by commas...'"></orlo-text-input>
        <div class="recent-email-list">
          <div *ngFor="let recentEmail of recentEmails"
               class="recent-email"
               (click)="addEmail(recentEmail)">
            <i class="ssi ssi-addsmall"></i>
            {{recentEmail}}
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="orlo-textarea">
          <label class="control-label text-muted spacer-top">Email Body</label>
          <textarea formControlName="emailBody"
                    name="emailBody"
                    rows="4"
                    placeholder="type your email body message here…"></textarea>
        </div>
      </div>
      <div class="form-field">
        <div class="orlo-tickbox"
             [class.selected]="sendToForm.value.sendCopy">
          <i class="tick ssi ssi-tick"></i>
          <input type="checkbox"
                 name="sendCopy"
                 formControlName="sendCopy">
          <label for="sendCopy">Send me a copy</label>
        </div>
        <!-- <input type="checkbox" name="sendCopy" formControlName="sendCopy"/><span class="inline-label">Send me a copy</span> -->
      </div>
      <div class="buttons">
        <orlo-button textColor="#43537F"
                     state="text"
                     text="Cancel"
                     textClass="uppercase"
                     (btnClick)="activeModal.close()"></orlo-button>
        <orlo-button textColor="#14bae3"
                     state="text"
                     [text]="selectedActivityIds.length > 1 ? 'Email ' + selectedActivityIds.length + ' messages' : 'Email message'"
                     textClass="uppercase"
                     (btnClick)="submit()"
                     [disabled]="sendToForm.invalid || sendToForm.invalid && selectedActivityIds.length === 0"></orlo-button>
      </div>
    </form>
  </div>
</div>