<div class="scheduling-posts-form">
  <div class="scheduling-posts-form-left">
    <label class="scheduling-posts-label">Schedule Date</label>
    <input
      type="text"
      [ngModel]="scheduledPostTime | date: 'EEEE d MMM y'"
      class="scheduling-posts-date"
      [attr.placeholder]="'No schedule date set...'"
      (click)="$event.stopPropagation(); showPostScheduler = true"
      cdkOverlayOrigin
      #postSchedulerTrigger="cdkOverlayOrigin"
    >

    <ng-template
      #actionsMenu="cdkConnectedOverlay"
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="postSchedulerTrigger"
      [cdkConnectedOverlayOpen]="showPostScheduler"
      [cdkConnectedOverlayFlexibleDimensions]="false"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      [cdkConnectedOverlayPush]="true"
      (backdropClick)="showPostScheduler = false"
    >
      <ssi-post-scheduler
        [scheduledDate]="scheduledPostTime"
        [accounts]="accounts"
        (onCloseClick)="showPostScheduler = false"
        (onAddScheduleClick)="
          scheduledPostTime = $event;
          scheduledPostTimesUpdated(scheduledPostTime);
          showPostScheduler = false
        "
      ></ssi-post-scheduler>
    </ng-template>
  </div>

  <div class="scheduling-posts-form-right">
    <ssi-time-picker
      name="scheduledPostTime"
      [(ngModel)]="scheduledPostTime"
      [accounts]="accounts"
      [fullWidth]="fullWidth"
      [dayOfTheWeek]="scheduledPostTime ? (scheduledPostTime | day) : undefined"
      (ngModelChange)="scheduledPostTimesUpdated($event)"
    ></ssi-time-picker>

    <div class="scheduling-posts-form-right-inner" [ngClass]="{'send-right': fullWidth}">
  
      <button
        class="scheduling-posts-delete"
        [ngClass]="{'scheduling-posts-delete-active': !! scheduledPostTime}"
        (click)="!! scheduledPostTime && onScheduledPostTimesDeleted.emit()"
      >
        <i class="ssi ssi-small-delete"></i>
        <p *ngIf="fullWidth">Delete Schedule</p>
      </button>
    </div>
  </div>

  <div class="post-error" *ngIf="error">
    <div class="error-message">
      <i class="ssi ssi-warning"></i>
      <p>{{error}}</p>
    </div>
  </div>
</div>
