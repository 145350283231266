import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import './report-sidebar.component.scss';

@Component({
  selector: 'ssi-report-sidebar',
  templateUrl: './report-sidebar.component.html',
  styles: []
})
export class ReportSidebarComponent implements OnInit {
  @Input() icon: string;
  @Input() color: string;
  @Input() actionList: any[];
  @Input() navList: { label: string; tag: string }[];
  @Input() active: string;

  @Output() navClicked = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
