import './nextdoor-options.component.scss';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AccountTypeId,
  AccountTypeIdString,
  AccountTypeName,
  getAccountTypeIdFromName
} from '../../../../enums';
import { OutboxPublisher, socialNetworkSettings } from '@ui-resources-angular';
import { TargetingOptionsService } from '../targeting-options.service';

@Component({
  selector: 'ssi-nextdoor-options',
  templateUrl: './nextdoor-options.component.html'
})
export class NextdoorOptionsComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() accountTypeName: AccountTypeName;
  @Input() accountTypeIdString: AccountTypeIdString;

  @Output() select = new EventEmitter();

  boundaries = [];

  constructor(public targetingService: TargetingOptionsService) {}

  async ngOnInit() {
    console.log('Should initialise Nextdoor targeting...');
    this.targetingService.initialiseTargeting(
      this.accountTypeName,
      this.post.targeting[this.accountTypeName]
    );

    this.boundaries = await socialNetworkSettings[
      this.accountTypeName
    ].targeting.boundaries(
      this.post.accounts.find(
        (account) => account.account_type_id === this.accountTypeIdString
      ).id
    );

    this.buildFromPost();
  }

  async buildFromPost() {
    this.targetingService.options[
      this.accountTypeName
    ].selectedBoundaries = this.post.targeting[
      this.accountTypeName
    ].group_ids.map((id) =>
      this.boundaries.find((boundary) => boundary.group_id === id)
    );
  }

  async copyToPost() {
    this.post.targeting[
      this.accountTypeName
    ].group_ids = this.targetingService.options[
      this.accountTypeName
    ].selectedBoundaries.map((boundary) => boundary.group_id);
  }

  onSelectedBoundariesChange(boundaries: any[]): void {
    this.targetingService.options[
      this.accountTypeName
    ].selectedBoundaries = boundaries;
    this.select.emit();
  }

  removeBoundary(id): void {
    this.targetingService.options[
      this.accountTypeName
    ].selectedBoundaries = this.targetingService.options[
      this.accountTypeName
    ].selectedBoundaries.filter(
      (boundary): boolean => boundary.group_id !== id
    );
    this.select.emit();
  }

  public readonly AccountTypeId = AccountTypeId;

  deselectAll() {
    this.targetingService.options[this.accountTypeName].selectedBoundaries = [];
    this.select.emit();
  }
}
