<div class="publisher-secondary-view-schedule-left">

  <ssi-publisher-secondary-view-nav class="g-hide-if-large-screen"
                                    [post]="post"
                                    (back)="changeStage.emit({stage: stages.Landing})">
    <ssi-publisher-schedule-action-buttons [post]="post"
                                           [disable]="disable"
                                           [postInvalidTooltipTemplate]="postInvalidTooltipTemplate"
                                           [publishingLoadingTracker]="publishingLoadingTracker"
                                           (publish)="publish.emit()"
                                           (showPostPreview)="showPostPreview.emit()"></ssi-publisher-schedule-action-buttons>
  </ssi-publisher-secondary-view-nav>

  <div class="publisher-secondary-view-left-body">
    <ng-container *ngIf="!selectedDate">
      <button
        class="btn btn-block btn-rounded schedule-btn schedule-btn-light back-btn g-hide-if-small-screen"
        (click)="changeStage.emit({stage: stages.Landing})">
        <i class="ssi ssi-chevron-left"></i>
        Back
      </button>
      <ssi-publisher-schedule-date-picker
        [post]="post"
        [campaigns]="campaigns"
        (dateSelected)="selectedDate = $event"
        *ngIf="!selectedDate">
      </ssi-publisher-schedule-date-picker>
    </ng-container>

    <ng-container *ngIf="selectedDate">
      <button
        [style.z-index]="2"
        class="btn btn-block btn-rounded schedule-btn schedule-btn-light back-btn g-hide-if-small-screen"
        (click)="selectedDate = null">
        <i class="ssi ssi-chevron-left"></i>
        Back
      </button>
      <ssi-publisher-schedule-time-picker
        [topPostTimes]="topPostTimes"
        [scheduleDate]="selectedDate"
        (timeSelected)="addSchedule($event, post)"
        *ngIf="selectedDate">
      </ssi-publisher-schedule-time-picker>
    </ng-container>
  </div>
</div>


<div class="publisher-secondary-view-right">
  <div class="publisher-secondary-view-right-body d-flex flex-column">
    <ssi-publisher-secondary-view-nav class="g-hide-if-small-screen" 
                                      [post]="post"
                                      (back)="changeStage.emit({stage: stages.Landing})">
      <ssi-publisher-schedule-action-buttons [post]="post"
                                             [disable]="disable"
                                             [postInvalidTooltipTemplate]="postInvalidTooltipTemplate"
                                             [publishingLoadingTracker]="publishingLoadingTracker"
                                             (publish)="publish.emit()"
                                             (showPostPreview)="showPostPreview.emit()"></ssi-publisher-schedule-action-buttons>
    </ssi-publisher-secondary-view-nav>
    
    <div class="publisher-secondary-view-right-body-copy">
      <div *ngIf="post.schedules.length === 0">
        <div class="publisher-schedule-body">
          <h3 class="publisher-schedule-title" [class.text-center]="post.schedules.length > 0">Schedule Post</h3>
          Dates that are checked green have posts already schedule that day.
          <span *ngIf="!post.hasTwitterSelected()">Dates that are checked grey are not available to post out on. You</span>
          <span *ngIf="post.hasTwitterSelected()">Dates checked grey are not available to post out on. As you have selected a X account, you</span>
          <span *ngIf="post.scheduleCount.maximum > 1">
            can schedule your post to go out up to {{ post.scheduleCount.maximum }} times<b *ngIf="post.hasTwitterSelected()"> given they're apart by 7 days</b>.
          </span>
          <span *ngIf="post.scheduleCount.maximum === 1">You can only schedule your post once when editing a post or creating an album.</span>
          <div class="publisher-schedule-tip">
            <span
              [ssiTooltip]="'Posting at the same time repetitively will skew data. So try & post out on a range of occasions to see what the best times are for your company. Build your data, the more you post, the more information we have to build your analytics!'"
              [tooltipWindowClass]="'tooltip-chunky-big'">
              <i class="ssi ssi-pro-tip"></i>
              <span class="text-uppercase">Pro tip</span>
            </span>
          </div>
        </div>
      </div>
      <div class="publisher-schedule-list custom-scrollbar" *ngIf="post.schedules.length > 0">
        <div class="publisher-schedule-list-item" *ngFor="let time of post.schedules">
            {{ time | date:'EEEE d MMM yyyy, HH:mm' }}
          <a
            href="javascript:;"
            class="publisher-schedule-list-item-remove"
            (click)="post.removeSchedule(time)">
            <i class="ssi ssi-small-delete"></i>
          </a>
        </div>
      </div>
      <div class="publisher-schedule-list-remaining text-center" *ngIf="post.schedules.length > 0">
        <div>
          <!-- <div *ngIf="post.schedules.length <= 12">
            <i class="ssi ssi-plus publisher-schedule-icon"></i>
          </div> -->
          <div class="publisher-schedule-list-meta">
            <p>{{ post.scheduleCount.remaining }} more schedule slots available</p>
            <p *ngIf="post.hasTwitterSelected()">make sure your schedules are 7 days apart!</p>
          </div>
        </div>
      </div>
    </div>

    <ssi-publisher-schedule-action-buttons class="g-hide-if-large-screen"
                                          [post]="post"
                                          [disable]="disable"
                                          [postInvalidTooltipTemplate]="postInvalidTooltipTemplate"
                                          [publishingLoadingTracker]="publishingLoadingTracker"
                                          (publish)="publish.emit()"
                                          (showPostPreview)="showPostPreview.emit()"></ssi-publisher-schedule-action-buttons>
  </div>

</div>