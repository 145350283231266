<div
  class="content-wrapper sales-section"
  *ngIf="!competitorAnalysisEnabled"
>
  <div class="dark-bg"></div>
  <div class="content-form">
    <div class="landing-info">
      <h1 translate="BENCHMARKING"></h1>
      <p>Feeling competitive? See how you scrub up against the competition with our powerful <span translate="BENCHMARKING"></span> tool!
      </p>
      <div class="steps">
        <div class="step">
          <i class="ssi ssi-benchmark-new"></i>
          <h3>Benchmark</h3>
          <p>Keep an eye on your competitors and see how your brand measures up against them.</p>
        </div>
        <div class="step">
          <i class="ssi ssi-understand"></i>
          <h3>Understand</h3>
          <p>Learn what works best for you on social with custom reports that are easy to share and digest.</p>
        </div>
        <div class="step">
          <i class="ssi ssi-deliver-new"></i>
          <h3>Deliver</h3>
          <p>Use the insight you gain to adapt your content and campaigns and get even better results.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="help-content">
    <img src="https://www.orlo.app/assets/v2/img/teammates-desk.png" />
    <div class="text-container">
      <h3>Want to see it in action?</h3>
      <p>To find out more, jump on live chat or speak to your Customer Success Manager.
      </p>
      <orlo-button
        [state]="'primary'"
        [text]="'Chat to us!'"
        (click)="toggleLiveChat()"
      ></orlo-button>
    </div>
  </div>
</div>
<div class="page-menu">
  <h1 translate="BENCHMARKING"></h1>
  <ul class="box-menu">
    <li>
      <ssi-menu-item
        uiSref="^.competitorList"
        label="Manage companies"
        iconClass="ssi-settings"
      >
      </ssi-menu-item>
    </li>
    <li>
      <ssi-menu-item
        uiSref="^.brandCreate"
        label="My Brand"
        iconClass="ssi-addsmall"
      >
      </ssi-menu-item>
    </li>
    <li>
      <ssi-menu-item
        uiSref="^.competitorCreate"
        label="Competitor"
        iconClass="ssi-addsmall"
      >
      </ssi-menu-item>
    </li>
    <li *ngIf="reports.length !== 0">
      <ssi-menu-item
        uiSref="^.reportCreate"
        label="New report"
        iconClass="ssi-addsmall"
      >
      </ssi-menu-item>
    </li>
  </ul>
</div>
<div
  class="content-wrapper"
  *ngIf="competitorAnalysisEnabled"
>
  <div class="report-list">
    <table
      class="orlo-table"
      *ngIf="reports.length !== 0"
    >
      <thead>
        <tr>
          <th>Report name</th>
          <th>Description</th>
          <th>Date Created</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let report of reports; let i = index"
          class="has-delete"
          [class.delete-triggered]="rowToDeleteIndex === i"
        >
          <td class="name">
            <a
              (mouseenter)="focusDelete(i)"
              (mouseleave)="rowToDeleteIndex = null"
              (click)="deleteReportTooltip.show()"
              #deleteReportTooltip="ssiTooltip"
              [ssiTooltip]
              tooltipPlacement="right"
              tooltipTrigger="manual"
              [tooltipTemplate]="deleteReportTooltipTemplate"
            >
            <span class="icon-wrap">
              <ssi-icon icon="bin" height="14" width="14"></ssi-icon>
            </span>
          </a> <strong>{{report.name}}</strong>
          </td>
          <ng-template #deleteReportTooltipTemplate>
            <div
              class="delete-report-tooltip"
              (ssiOffClick)="deleteReportTooltip.hide()"
            >
              <h5>Are you sure you want to delete this Report permanently?</h5>
              <div class="buttons-container">
                <orlo-button
                  [state]="'text'"
                  [text]="'Cancel'"
                  [textColor]="'#43537F'"
                  (btnClick)="deleteReportTooltip.hide()"
                ></orlo-button>
                <orlo-button
                  [state]="'text'"
                  [text]="'Delete'"
                  [textColor]="'#F88C68'"
                  (btnClick)="deleteReport(report)"
                ></orlo-button>
              </div>
            </div>
          </ng-template>

          <td class="description">{{report.description}}</td>
          <td class="created-at">{{report.created_at | date: 'd MMM y'}}</td>

          <td class="actions">
            <button
              class="edit"
              (click)="editReport(report)"
            ><i class="ssi ssi-correctedit"></i></button>
            <button
              class="view orlo-button primary"
              (click)="viewReport(report)"
            >View</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      *ngIf="reports.length === 0"
      class="landing-info"
    >
      <h1 class="landing-info-title">Welcome to <span translate="BENCHMARKING"></span></h1>
      <p class="landing-info-body">
        This is where you can create brand profiles out of your
        <br>social accounts, then compare your performance against your rivals.
        <br>Getting started is a simple 3 step process:
      </p>
      <div class="steps">
        <div class="step">
          <i
            class="ssi ssi-companysettings"
            [class.active]="usedFeatures.brands"
          ></i>
          <h3>Add your brand</h3>
          <p>Define your first brand profile</p>
          <button
            [disabled]="usedFeatures.brands"
            uiSref="^.brandCreate"
            class="orlo-button primary"
            [ngClass]="{'completed': usedFeatures.brands}"
            type="button"
          >
            <i [ngClass]="usedFeatures.brands ? 'completed ssi ssi-tick' : 'ssi ssi-addsmall'">
            </i>
          </button>
        </div>
        <div class="step">
          <i
            class="ssi ssi-competitor"
            [class.active]="usedFeatures.competitors"
          ></i>
          <h3>Or a competitor</h3>
          <p>Start adding your competitors</p>
          <button
            [disabled]="usedFeatures.competitors"
            uiSref="^.competitorCreate"
            class="orlo-button primary"
            [ngClass]="{'completed': usedFeatures.competitors}"
            type="button"
          >
            <i [ngClass]="usedFeatures.competitors ? 'completed ssi ssi-tick' : 'ssi ssi-addsmall'">
            </i>
          </button>
        </div>
        <div class="step">
          <i class="ssi ssi-competitor-analysis"></i>
          <h3>Good to go!</h3>
          <p>Now create your first report!</p>
          <button
            [disabled]="!usedFeatures.brands || !usedFeatures.competitors"
            uiSref="^.reportCreate"
            class="orlo-button primary"
            type="button"
          >
            <i class="ssi ssi-addsmall"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>