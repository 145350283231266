import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AdvertisingTableFiltersComponent } from './advertising-table-filters.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [AdvertisingTableFiltersComponent],
  exports: [AdvertisingTableFiltersComponent]
})
export class AdvertisingTableFiltersModule {}
