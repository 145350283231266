import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { IconModule } from '../../../../../../common/components/icon/icon.module';
import { PaginatorModule } from '../../../../../../common/components/paginator';
import { SpinnerModule } from '../../../../../../common/components/spinner/spinner.module';

import { InsightsPostsModule } from '../insights-posts/insights-posts.module';
import { DrilldownModalComponent } from './drilldown-modal.component';
import { BigNumberModule } from '../../../../../../common/components/big-number/big-number.module';

@NgModule({
  declarations: [DrilldownModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    IconModule,
    FormsModule,
    InsightsPostsModule,
    TranslateModule,
    PaginatorModule,
    SpinnerModule,
    BigNumberModule
  ],
  exports: [DrilldownModalComponent]
})
export class DrilldownModalModule {}
