import { Author } from './author.model';
import { Media } from './media.model';

export class ShareView {
  id: string;
  text: string;
  created_at: string;
  external_link: string;
  author: Author;
  media: Media[]; // both video and gif come with 'image_url' representing the thumbnail

  constructor(props: any) {
    this.id = props.id;
    this.text = props.text;
    this.created_at = props.created_at;
    this.external_link = props.external_link;
    this.author = props.author && new Author(props.author);
    this.media =
      (Array.isArray(props.media) && props.media.map((m) => new Media(m))) ||
      [];
  }
}
