import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { authUser } from '../../../common-resolves';
import { User } from '@ui-resources-angular';
import { CompanyService } from '../../../../common/services/company/company.service';

export async function featureFlagsFn(company: CompanyService) {
  const companyConfig = await company.getConfig();
  return companyConfig.feature_flags;
}

@Component({
  selector: 'ssi-my-settings',
  templateUrl: './my-settings.component.html',
  styles: []
})
export class MySettingsComponent {
  static resolve = [
    {
      token: 'featureFlags',
      resolveFn: featureFlagsFn,
      deps: [CompanyService]
    },
    authUser
  ];

  @Input() authUser: User;
  @Input() featureFlags;

  mySettings = [
    {
      link: '.mydetails',
      header: 'My Details',
      text: 'Here you can pop in your name & email settings',
      icon: 'ssi ssi-mydetails',
      permission: null
    },
    {
      link: '.mypassword',
      header: 'My Password',
      text: 'Need to change your password? Click right here!',
      icon: 'ssi ssi-mypassword',
      permission: null
    },
    {
      link: '.mypreferences',
      header: 'My Preferences',
      text: 'All Orlo preferences, like email selections are in this section',
      icon: 'ssi ssi-mypreferences',
      permission: null
    },
    {
      link: '.loginactivity',
      header: 'Login Activity',
      text: 'View your login activity right here',
      icon: 'ssi ssi-loginactivity',
      permission: null
    },
    {
      link: 'auth.notificationSettings',
      header: 'Notifications',
      text: 'Choose the notifications you want to see & when',
      icon: 'ssi ssi-notifications',
      permission: null
    }
  ];

  constructor(public location: Location) {}

  checkPermission(permission) {
    return permission ? this.authUser.hasCompanyPermission(permission) : true;
  }

  checkFlag(flag) {
    return flag ? this.featureFlags.includes(flag) : true;
  }
}
