import {
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgModule,
  Output
} from '@angular/core';
import { InboxQueryResultListComponent } from '../modules/auth/inbox/inbox-query-result-list/inbox-query-result-list.component';
import { InboxUsefulThingsComponent } from '../modules/auth/inbox/inbox-useful-things/inbox-useful-things.component';
import { InboxSearchResultsComponent } from '../modules/auth/inbox/inbox-search-results/inbox-search-results.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { ActivityTextComponent } from './components/activity-text/activity-text.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { PublisherComponent } from './components/publisher/publisher.component';
import { EmojiPickerComponent } from './directives/emoji-form-control-container/emoji-picker/emoji-picker.component';
import { HighchartComponent } from './components/highchart/highchart.component';
import { HighchartModule } from './components/highchart/highchart.module';
import { UpgradeComponent } from '@angular/upgrade/static';
import { InboxQueryResultListModule } from '../modules/auth/inbox/inbox-query-result-list/inbox-query-result-list.module';
import { InboxUsefulThingsModule } from '../modules/auth/inbox/inbox-useful-things/inbox-useful-things.module';
import { InboxSearchResultsModule } from '../modules/auth/inbox/inbox-search-results/inbox-search-results.module';
import { TagsInputModule } from './components/tags-input/tags-input.module';
import { UserSelectModalModule } from './components/user-select-modal/user-select-modal.module';
import { UserSelectModalComponent } from './components/user-select-modal/user-select-modal.component';
import { ActivityTextModule } from './components/activity-text/activity-text.module';
import { AlertsModule } from './components/alerts/alerts.module';
import { MediaNavModule } from './components/media-nav/media-nav.module';
import { MediaNavComponent } from './components/media-nav/media-nav.component';
import { Outbox } from '@ui-resources-angular';
import { OutboxQuery } from './services/outbox-query-factory/outbox-query-factory.service';
import { BigNumberModule as BigNumberPipeModule } from './pipes/big-number/big-number.module';
import { BigNumberModule as BigNumberComponentModule } from './components/big-number/big-number.module';
import { Advert } from '../modules/auth/marketing/advertising/advert-model/advert-model.service';
import { ExportConversationComponent } from './components/export-conversation/export-conversation.component';
import { ExportConversationModule } from './components/export-conversation/export-conversation.module';
import { ExportMessagesComponent } from './components/export-messages/export-messages.component';
import { ExportMessagesModule } from './components/export-messages/export-messages.module';
import { OrloCrmPersonComponent } from './components/orlo-crm-person/orlo-crm-person.component';
import { OrloCrmPersonModule } from './components/orlo-crm-person/orlo-crm-person.module';
import { LiveChatComponent } from './components/live-chat/live-chat/live-chat.component';
import { LiveChatModule } from './components/live-chat/live-chat/live-chat.module';
import { LiveChatDashboardComponent } from './components/live-chat/live-chat-dashboard/live-chat-dashboard.component';
import { LiveChatDashboardModule } from './components/live-chat/live-chat-dashboard/live-chat-dashboard.module';
import { LiveChatAssignAgentComponent } from './components/live-chat/live-chat-assign-agent/live-chat-assign-agent.component';
import { LiveChatAssignAgentModule } from './components/live-chat/live-chat-assign-agent/live-chat-assign-agent.module';
import { PluginModule } from './components/plugin/plugin.module';
import { PluginComponent } from './components/plugin/plugin.component';
import { SocialPostComponent } from './components/social-post/social-post.component';
import { SocialPostModule } from './components/social-post/social-post.module';
import { TotalChangeComponent } from './components/total-change/total-change.component';
import { TotalChangeModule } from './components/total-change/total-change.module';
import { TextInputAutocompleteModule } from './components/text-input-autocomplete';
import { TextInputHighlightModule } from './components/text-input-highlight';
import { ContextMenuModule } from './components/context-menu/context-menu.module';
import { SocialPushModeMenuModule } from './components/social-push-mode-menu/social-push-mode-menu.module';
import { SecondsToHumanTimeModule } from './pipes/seconds-to-human-time/seconds-to-human-time.module';
import { SocialPostCharactersRemainingModule } from './pipes/social-post-characters-remaining/social-post-characters-remaining.module';
import { MapComponent } from './components/map/map.component';
import { MapModule } from './components/map/map.module';
import { CountdownComponent } from './components/countdown/countdown.component';
import { GraphModule } from './components/graph/graph.module';
import { GraphComponent } from './components/graph/graph.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { PieChartModule } from './components/pie-chart/pie-chart.module';
import { GaugeChartModule } from './components/gauge-chart/gauge-chart.module';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { CountdownModule } from './components/countdown/countdown.module';
import { ActivityLinkPreviewComponent } from './components/activity-link-preview/activity-link-preview.component';
import { ActivityLinkPreviewModule } from './components/activity-link-preview/activity-link-preview.module';
import { ModalComponent } from './components/modal/modal.component';
import { ModalModule } from './components/modal/modal.module';
import { ActionPadModule } from './components/action-pad/action-pad.module';
import { ActionPadComponent } from './components/action-pad/action-pad.component';
import { InboxPinnedActivitiesComponent } from './components/inbox-pinned-activities/inbox-pinned-activities.component';
import { InboxPinnedActivitiesModule } from './components/inbox-pinned-activities/inbox-pinned-activities.module';
import { InboxLanguageSelectorComponent } from './components/inbox-language-selector/inbox-language-selector.component';
import { InboxLanguageSelectorModule } from './components/inbox-language-selector/inbox-language-selector.module';
import { ReplyBoxComponent } from './components/reply-box/reply-box.component';
import { ReplyBoxModule } from './components/reply-box/reply-box.module';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { MessageBoxModule } from './components/message-box/message-box.module';
import { AssignMessageComponent } from './components/assign-message/assign-message.component';
import { AssignMessageModule } from './components/assign-message/assign-message.module';
import { AuditComponent } from './components/audit/audit.component';
import { AuditModule } from './components/audit/audit.module';
import { SocialPushModeModalModule } from './components/social-push-mode-modal/social-push-mode-modal.module';
import { SocialPushModeModalComponent } from './components/social-push-mode-modal/social-push-mode-modal.component';
import { SpinnerModule } from './components/spinner/spinner.module';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NavbarModule } from './components/navbar/navbar.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotesModule } from './components/notes/notes.module';
import { NotesComponent } from './components/notes/notes.component';
import { MultiSelectModule } from './components/multi-select/multi-select.module';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { NewVersionModalComponent } from './components/navbar/new-version-modal/new-version-modal.component';
import { KeyboardNavListModule } from './components/keyboard-nav-list/keyboard-nav-list.module';
import { SentimentModule } from './components/sentiment/sentiment.module';
import { SentimentComponent } from './components/sentiment/sentiment.component';
import { SentimentListModule } from './components/sentiment-list/sentiment-list.module';
import { SentimentListComponent } from './components/sentiment-list/sentiment-list.component';
import { SentimentTextModule } from './components/sentiment/sentiment-text/sentiment-text.module';
import { SentimentTextComponent } from './components/sentiment/sentiment-text/sentiment-text.component';
import { StatCardIconModule } from './components/stat-card-icon/stat-card-icon.module';
import { StatCardIconComponent } from './components/stat-card-icon/stat-card-icon.component';
import { ProfileInboxStatsComponent } from './components/profile-inbox-stats/profile-inbox-stats.component';
import { ProfileInboxStatsModule } from './components/profile-inbox-stats/profile-inbox-stats.module';
import { CrmProfileSentimentBarModule } from './components/profile-inbox-stats/crm-profile-sentiment-bar/crm-profile-sentiment-bar.module';
import { CrmProfileSentimentBarComponent } from './components/profile-inbox-stats/crm-profile-sentiment-bar/crm-profile-sentiment-bar.component';
import { EngagementAnalyticsSentimentComponent } from './components/engagement-analytics-sentiment/engagement-analytics-sentiment.component';
import { EngagementAnalyticsSentimentModule } from './components/engagement-analytics-sentiment/engagement-analytics-sentiment.module';
import { OutboxPostComponent } from './components/outbox-post/outbox-post.component';
import { OutboxPostModule } from './components/outbox-post/outbox-post.module';
import { OutboxPostNewModule } from './components/outbox-post-new/outbox-post-new.module';
import { OutboxPostNewStatModule } from './components/outbox-post-new-stat/outbox-post-new-stat.module';
import { OutboxPostNewNotesModule } from './components/outbox-post-new-notes/outbox-post-new-notes.module';
import { EnlargeImageModalComponent } from './components/enlarge-image-modal/enlarge-image-modal.component';
import { EnlargeImageModalModule } from './components/enlarge-image-modal/enlarge-image-modal.module';
import { OutboxNotesModalModule } from './components/outbox-notes-modal/outbox-notes-modal.module';
import { OutboxNotesModalComponent } from './components/outbox-notes-modal/outbox-notes-modal.component';
import { OutboxCampaignModalComponent } from './components/outbox-campaign-modal/outbox-campaign-modal.component';
import { OutboxCampaignModalModule } from './components/outbox-campaign-modal/outbox-campaign-modal.module';
import { CrmPersonNotesComponent } from './components/crm-person-notes/crm-person-notes.component';
import { CrmPersonNotesModule } from './components/crm-person-notes/crm-person-notes.module';
import { NotesTextareaModule } from './components/notes-textarea/notes-textarea.module';
import { DateTimePickerModule } from './components/date-time-picker/date-time-picker.module';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { InlineNotesModule } from './components/inline-notes/inline-notes.module';
import { InlineNotesComponent } from './components/inline-notes/inline-notes.component';
import { QueryPresetsModule } from './components/query-presets/query-presets.module';
import { QueryPresetsComponent } from './components/query-presets/query-presets.component';
import { OutboxPostListComponent } from './components/outbox-post-list/outbox-post-list.component';
import { OutboxPostListNoResultsModule } from './components/outbox-post-list/outbox-post-list-no-results/outbox-post-list-no-results.module';
import { OutboxPostListNoResultsComponent } from './components/outbox-post-list/outbox-post-list-no-results/outbox-post-list-no-results.component';
import { DatetimeModalModule } from './components/datetime-modal/datetime-modal.module';
import { DatetimeModalComponent } from './components/datetime-modal/datetime-modal.component';
import { DashboardWidgetModalModule } from './components/dashboard-widget-modal/dashboard-widget-modal.module';
import { DashboardWidgetModalComponent } from './components/dashboard-widget-modal/dashboard-widget-modal.component';
import { CampaignModalModule } from './components/campaign-modal/campaign-modal.module';
import { CampaignModalComponent } from './components/campaign-modal/campaign-modal.component';
import { DeleteModalModule } from './components/delete-modal/delete-modal.module';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { RedactModalModule } from './components/redact-modal/redact-modal.module';
import { RedactModalComponent } from './components/redact-modal/redact-modal.component';
import { ConfirmationModalModule } from './components/confirmation-modal/confirmation-modal.module';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { EditLinksModalModule } from './components/edit-links-modal/edit-links-modal.module';
import { EditLinksModalComponent } from './components/edit-links-modal/edit-links-modal.component';
import { SaveDraftModalModule } from './components/save-draft-modal/save-draft-modal.module';
import { SaveDraftModalComponent } from './components/save-draft-modal/save-draft-modal.component';
import { ReduceImageSizePromptModule } from './components/reduce-image-size-prompt/reduce-image-size-prompt.module';
import { PrivateMessageModalModule } from './components/private-message-modal/private-message-modal.module';
import { PrivateMessageModalComponent } from './components/private-message-modal/private-message-modal.component';
import { OutboxPostListModule } from './components/outbox-post-list/outbox-post-list.module';
import { ViewProfileModalComponent } from './components/view-profile-modal/view-profile-modal.component';
import { ViewProfileModalModule } from './components/view-profile-modal/view-profile-modal.module';
import { PasswordInputModule } from './components/password-input/password-input.module';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { EmailToModalModule } from './components/email-to-modal/email-to-modal.module';
import { EmailToModalComponent } from './components/email-to-modal/email-to-modal.component';
import { ClockModule } from './components/clock/clock.module';
import { ClockComponent } from './components/clock/clock.component';
import { IconModule } from './components/icon/icon.module';
import { IconComponent } from './components/icon/icon.component';
import { GifSearchModalComponent } from './directives/gif-search/gif-search-modal.component';
import { InboxActivityComponent } from './components/inbox-activity/inbox-activity.component';
import { InboxActivityModule } from './components/inbox-activity/inbox-activity.module';
import { InboxSidebarModule } from '../modules/auth/inbox/inbox-sidebar/inbox-sidebar.module';
import { InboxSidebarComponent } from '../modules/auth/inbox/inbox-sidebar/inbox-sidebar.component';
import { InboxDashboardComponent } from '../modules/auth/inbox/inbox-dashboard/inbox-dashboard.component';
import { InboxDashboardModule } from '../modules/auth/inbox/inbox-dashboard/inbox-dashboard.module';
import { InboxSearchResultsOverlayComponent } from '../modules/auth/inbox/inbox-search-results-overlay/inbox-search-results-overlay.component';
import { InboxSearchResultsOverlayModule } from '../modules/auth/inbox/inbox-search-results-overlay/inbox-search-results-overlay.module';
import { ComposerComponent } from './components/composer/composer.component';
import { InboxAlertModalComponent } from './components/inbox-alert-modal/inbox-alert-modal.component';
import { InboxAlertModalModule } from './components/inbox-alert-modal/inbox-alert-modal.module';
import { ConfirmLeaveModalComponent } from './components/confirm-leave-modal/confirm-leave-modal.component';
import { ConfirmLeaveModalModule } from './components/confirm-leave-modal/confirm-leave-modal.module';
import { BulkProgressModalComponent } from './components/bulk-progress-modal/bulk-progress-modal.component';
import { BulkProgressModalModule } from './components/bulk-progress-modal/bulk-progress-modal.module';
import { ComposerCancelModalComponent } from './components/composer-cancel-modal/composer-cancel-modal.component';
import { ComposerCancelModalModule } from './components/composer-cancel-modal/composer-cancel-modal.module';

@NgModule({
  // tslint:disable-line
  entryComponents: [
    // these are all listed here so they can be downgraded to be used with angularjs
    InboxQueryResultListComponent,
    InboxUsefulThingsComponent,
    InboxSearchResultsComponent,
    TagsInputComponent,
    UserSelectModalComponent,
    ActivityTextComponent,
    AlertsComponent,
    PublisherComponent,
    EmojiPickerComponent,
    HighchartComponent,
    MediaNavComponent,
    LiveChatComponent,
    LiveChatDashboardComponent,
    LiveChatAssignAgentComponent,
    ExportConversationComponent,
    ExportMessagesComponent,
    OrloCrmPersonComponent,
    PluginComponent,
    MapComponent,
    SocialPostComponent,
    TotalChangeComponent,
    MapComponent,
    GraphComponent,
    PieChartComponent,
    GaugeChartComponent,
    CountdownComponent,
    ActivityLinkPreviewComponent,
    ModalComponent,
    ActionPadComponent,
    InboxPinnedActivitiesComponent,
    InboxLanguageSelectorComponent,
    ReplyBoxComponent,
    MessageBoxComponent,
    AssignMessageComponent,
    AuditComponent,
    SocialPushModeModalComponent,
    SpinnerComponent,
    NavbarComponent,
    NotesComponent,
    MultiSelectComponent,
    NewVersionModalComponent,
    SentimentComponent,
    SentimentTextComponent,
    SentimentListComponent,
    StatCardIconComponent,
    ProfileInboxStatsComponent,
    EnlargeImageModalComponent,
    CrmProfileSentimentBarComponent,
    EngagementAnalyticsSentimentComponent,
    OutboxPostComponent,
    OutboxNotesModalComponent,
    OutboxCampaignModalComponent,
    CrmPersonNotesComponent,
    QueryPresetsComponent,
    OutboxPostListComponent,
    OutboxPostListNoResultsComponent,
    DatetimeModalComponent,
    DashboardWidgetModalComponent,
    CampaignModalComponent,
    DeleteModalComponent,
    ConfirmationModalComponent,
    EditLinksModalComponent,
    SaveDraftModalComponent,
    PrivateMessageModalComponent,
    DateTimePickerComponent,
    ViewProfileModalComponent,
    PasswordInputComponent,
    InlineNotesComponent,
    EmailToModalComponent,
    ClockComponent,
    IconComponent,
    GifSearchModalComponent,
    RedactModalComponent,
    InboxActivityComponent,
    InboxSidebarComponent,
    InboxDashboardComponent,
    InboxSearchResultsOverlayComponent,
    ComposerComponent,
    ConfirmLeaveModalComponent,
    ComposerCancelModalComponent,
    BulkProgressModalComponent
  ],
  imports: [
    InboxQueryResultListModule,
    InboxUsefulThingsModule,
    InboxSearchResultsModule,
    TagsInputModule,
    UserSelectModalModule,
    ActivityTextModule,
    AlertsModule,
    MediaNavModule,
    LiveChatModule,
    LiveChatDashboardModule,
    LiveChatAssignAgentModule,
    BigNumberPipeModule, // remove this once the component is actually used somewhere, hack to fix aot
    BigNumberComponentModule, // remove as well once used
    ExportConversationModule,
    ExportMessagesModule,
    OrloCrmPersonModule,
    PluginModule,
    MapModule,
    SocialPostModule,
    TotalChangeModule,
    TextInputAutocompleteModule,
    TextInputHighlightModule,
    ContextMenuModule,
    SocialPushModeMenuModule,
    SecondsToHumanTimeModule,
    SocialPostCharactersRemainingModule,
    MapModule,
    GraphModule,
    PieChartModule,
    GaugeChartModule,
    CountdownModule,
    ActivityLinkPreviewModule,
    ModalModule,
    ActionPadModule,
    InboxPinnedActivitiesModule,
    InboxLanguageSelectorModule,
    ReplyBoxModule,
    MessageBoxModule,
    AssignMessageModule,
    AuditModule,
    SocialPushModeModalModule,
    SpinnerModule,
    NavbarModule,
    NotesModule,
    MultiSelectModule,
    KeyboardNavListModule,
    SentimentModule,
    SentimentListModule,
    SentimentTextModule,
    StatCardIconModule,
    ProfileInboxStatsModule,
    CrmProfileSentimentBarModule,
    EnlargeImageModalModule,
    EngagementAnalyticsSentimentModule,
    OutboxPostModule,
    OutboxPostNewModule,
    OutboxPostNewNotesModule,
    OutboxPostNewStatModule,
    OutboxNotesModalModule,
    OutboxCampaignModalModule,
    NotesTextareaModule,
    CrmPersonNotesModule,
    QueryPresetsModule,
    OutboxPostListModule,
    OutboxPostListNoResultsModule,
    DatetimeModalModule,
    DashboardWidgetModalModule,
    CampaignModalModule,
    DeleteModalModule,
    ConfirmationModalModule,
    EditLinksModalModule,
    SaveDraftModalModule,
    ReduceImageSizePromptModule,
    PrivateMessageModalModule,
    DateTimePickerModule,
    ViewProfileModalModule,
    PasswordInputModule,
    InlineNotesModule,
    EmailToModalModule,
    ClockModule,
    IconModule,
    RedactModalModule,
    InboxActivityModule,
    InboxSidebarModule,
    InboxDashboardModule,
    InboxSearchResultsOverlayModule,
    InboxAlertModalModule,
    ConfirmLeaveModalModule,
    BulkProgressModalModule,
    ComposerCancelModalModule
  ],
  declarations: [],
  exports: []
})
export class NgUpgradeModule {}
