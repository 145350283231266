import './facebook-options.component.scss';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountTypeId, AccountTypeName } from '../../../../enums';
import { Suggestion } from '../../../../../../../library/interfaces/suggestion';
import { OutboxPublisher, socialNetworkSettings } from '@ui-resources-angular';
import {
  MultiValueInput,
  TargetingOptionsService
} from '../targeting-options.service';

@Component({
  selector: 'ssi-facebook-options',
  templateUrl: './facebook-options.component.html'
})
export class FacebookOptionsComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() previewToggled: boolean;

  @Output() select = new EventEmitter();

  private _citiesCache = {};

  constructor(public targetingService: TargetingOptionsService) {}

  getFacebookTargetingInput(key: string): MultiValueInput {
    return this.targetingService.options.Facebook.multiValueInputs.find(
      (input) => input.key === key
    );
  }

  ngOnInit() {
    console.log('Should initialise Facebook targeting...');
    this.targetingService.initialiseTargeting(
      AccountTypeName.Facebook,
      this.post.targeting.Facebook
    );
    this.buildFromPost();
  }

  async buildFromPost() {
    const countryTargeting = this.getFacebookTargetingInput('countries');
    await this.onFacebookCountriesChange();

    this.targetingService.options.Facebook.multiValueInputs.forEach((input) => {
      input.value = (this.post.targeting.Facebook[input.key] || []).map(
        (value) => {
          if (input.key === 'cities') {
            return value;
          }

          if (!(!!input && !!input.options && Array.isArray(input.options))) {
            return;
          }

          return input.options.find((option) =>
            typeof option.code === 'string'
              ? option.code.toLocaleLowerCase() === value.toLocaleLowerCase()
              : option.code === value
          );
        }
      );
    });

    if (
      !(
        !!socialNetworkSettings &&
        !!socialNetworkSettings.Facebook &&
        !!socialNetworkSettings.Facebook.targeting
      )
    ) {
      throw new Error(`Value for 'Facebook targeting' missing.`);
    }

    const targeting = socialNetworkSettings.Facebook.targeting;

    if (!(!!targeting.gender && Array.isArray(targeting.gender))) {
      throw new Error(
        `Value for 'Facebook gender targeting' not in expected format.`
      );
    }

    this.targetingService.options.Facebook.singleValueInputs.gender = targeting.gender.find(
      (gender) => gender.value === +this.post.targeting.Facebook.gender
    );

    if (
      !(
        !!targeting.relationshipStatus &&
        Array.isArray(targeting.relationshipStatus)
      )
    ) {
      throw new Error(
        `Value for 'Facebook relationship status' not in expected format.`
      );
    }

    this.targetingService.options.Facebook.singleValueInputs.relationship_status = targeting.relationshipStatus.find(
      (status) =>
        status.value === +this.post.targeting.Facebook.relationship_status
    );

    if (!(!!targeting.ages.min && Array.isArray(targeting.ages.min))) {
      throw new Error(`Value for 'Facebook min age' not in expected format.`);
    }

    this.targetingService.options.Facebook.singleValueInputs.min_age = targeting.ages.min.find(
      (value) => value === +this.post.targeting.Facebook.min_age
    );

    if (!(!!targeting.ages.max && Array.isArray(targeting.ages.max))) {
      throw new Error(`Value for 'Facebook max age' not in expected format.`);
    }

    this.targetingService.options.Facebook.singleValueInputs.max_age = targeting.ages.max.find(
      (value) => value === +this.post.targeting.Facebook.max_age
    );

    const cityTargeting = this.getFacebookTargetingInput('cities');
    await this.onFacebookCitiesChange();
  }

  getAgeGreaterThanMin() {
    return socialNetworkSettings.Facebook.targeting.ages.max.filter(
      (age) =>
        age > this.targetingService.options.Facebook.singleValueInputs.min_age
    );
  }

  setSingleValueInput(
    key: string,
    event: any // { value: number; label: string } | number
  ): void {
    const value = !event ? null : event.value ? event.value : event;
    this.targetingService.options.Facebook.singleValueInputs[key] = value;
    this.select.emit();
  }

  async copyToPost() {
    this.targetingService.options.Facebook.multiValueInputs.forEach((input) => {
      if (input.key === 'cities') {
        this.post.targeting.Facebook[input.key] = input.value;
      } else {
        this.post.targeting.Facebook[input.key] = input.value.map(
          (value) => value.code
        );
      }
    });

    ['gender', 'relationship_status'].forEach((targetingKey) => {
      this.post.targeting.Facebook[targetingKey] = this.targetingService.options
        .Facebook.singleValueInputs[targetingKey]
        ? this.targetingService.options.Facebook.singleValueInputs[targetingKey]
            .value
        : null;
    });

    ['min_age', 'max_age'].forEach((targetingKey) => {
      this.post.targeting.Facebook[targetingKey] = this.targetingService.options
        .Facebook.singleValueInputs[targetingKey]
        ? this.targetingService.options.Facebook.singleValueInputs[targetingKey]
        : null;
    });
  }

  async onFacebookCitiesFilterChange(filter: string) {
    const foundCities = await this.getCitiesForCountryCode(filter);
    const cityTargeting = this.getFacebookTargetingInput('cities');
    cityTargeting.options = foundCities;
  }

  async getCitiesForCountryCode(text: string): Promise<Suggestion[]> {
    if (!text) {
      return [];
    }

    const fb = socialNetworkSettings.Facebook;

    if (
      !(
        !!this.post &&
        !!this.post.accounts &&
        Array.isArray(this.post.accounts)
      )
    ) {
      return;
    }

    const account = this.post.accounts.find(
      (iAccount) => iAccount.socialNetwork === fb
    );
    const options = await fb.targeting.city(text, account);

    const countryTargeting = this.getFacebookTargetingInput('countries');

    if (countryTargeting.value.length > 0) {
      const { code } = countryTargeting.value[0];
      const filteredOptions = options.filter(
        (option) =>
          option.country.code.toLocaleLowerCase() === code.toLocaleLowerCase()
      );

      return filteredOptions;
    }

    return options;
  }

  async onFacebookCitiesChange() {
    try {
      requestAnimationFrame(() => {
        // the change won't actually be registered until after the change handler fires
        // so here we bounce it on to the next frame.

        const countryTargeting = this.getFacebookTargetingInput('countries');
        const cityTargeting = this.getFacebookTargetingInput('cities');

        if (!cityTargeting.value.length) {
          countryTargeting.disable = false;
          return;
        }

        if (
          !(
            !!countryTargeting &&
            !!countryTargeting.options &&
            Array.isArray(countryTargeting.options)
          )
        ) {
          throw new Error(
            `Options for 'publisher country targeting' not in expected format.`
          );
        }

        countryTargeting.value = [
          countryTargeting.options.find(
            (option) =>
              option.code.toLocaleLowerCase() ===
              cityTargeting.value[0].country.code.toLocaleLowerCase()
          )
        ];
        countryTargeting.disable = true;

        this.select.emit();
      });
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async onFacebookCountriesChange() {
    requestAnimationFrame(async () => {
      const countryTargeting = this.getFacebookTargetingInput('countries');
      const cityTargeting = this.getFacebookTargetingInput('cities');

      let options = [];

      for (const suggestion of countryTargeting.value) {
        const isInCache: boolean =
          !!this._citiesCache[suggestion.code] &&
          this._citiesCache[suggestion.code].length;

        if (!isInCache) {
          this._citiesCache[
            suggestion.code
          ] = await this.getCitiesForCountryCode(suggestion.code);
        }

        options = options.concat(this._citiesCache[suggestion.code]);
      }

      cityTargeting.options = options;

      if (countryTargeting.value.length) {
        this.select.emit();
      }
    });
  }

  public readonly AccountTypeId = AccountTypeId;
  public readonly socialNetworkSettings = socialNetworkSettings;
}
