import './dashboard-widget-modal.component.scss';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountModel, Account } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../services/workflow-manager/workflow-manager.service';
import { Filter, FiltersComponent } from '../filters';
import { DashboardWidget } from '../../services/api/dashboard';
import {
  WidgetType,
  WidgetTypes,
  widgetTypes,
  widgetTypesIterable
} from '../../../../angular/modules/auth/dashboard/common/constants';
import { Option } from '../../../../angular/modules/auth/dashboard/common/components/widgets/widget-inbox-message-config/widget-inbox-message-config.component';

@Component({
  selector: 'ssi-dashboard-widget-modal',
  templateUrl: './dashboard-widget-modal.component.html'
})
export class DashboardWidgetModalComponent implements OnInit {
  @Input() edit = false;
  @Input() widget: DashboardWidget;

  @ViewChild('configComponent') configComponent: {
    getConfig: () => any;
    getQuery: () => any;
  };
  @ViewChild(FiltersComponent) filtersComponent: FiltersComponent;

  accounts: Account[] = [];
  selectedAccounts: Account[] = [];
  liveChatTally: boolean;
  widgetTypes = widgetTypes;

  activeFilters: Filter[] = [];

  widgetTitleMaxLength = 60;
  formValid: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    protected changeDetectorRef: ChangeDetectorRef,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  async ngOnInit() {
    console.log('widget: ', this.widget);
    this.widget.typeConst = widgetTypesIterable.find(
      (type) => type.key === this.widget.type
    );

    this.accounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId()
    );
  }

  onSelectedAccountsChange(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
  }

  onSelectedPresetChange(preset: Option): void {
    // console.log('selectedPreset: ', preset);
    const apiSearchParams = this.filtersComponent.toApiParams();
    this.widget.apiSearchParams = {
      ...apiSearchParams,
      ...preset.query
    };
  }

  onActiveFiltersChange(activeFilters: Filter[]): void {
    this.activeFilters = activeFilters;
  }

  async addWidget(event: MouseEvent): Promise<void> {
    if (this.configComponent) {
      const config = this.configComponent.getConfig();
      const configQuery = this.configComponent.getQuery();
      console.log('configQuery: ', configQuery);

      this.widget.config = config;
      this.widget.apiSearchParams = {
        ...configQuery
      };
    }

    if (this.filtersComponent) {
      const apiSearchParams = this.filtersComponent.toApiParams();
      this.widget.apiSearchParams = {
        ...this.widget.apiSearchParams,
        ...apiSearchParams
      };

      this.widget.activeFilters = this.filtersComponent
        .getActiveFilters()
        .map((f) => {
          // few props only needed to recreate filters state - others are cut off in order not to be saved on the backend
          return {
            key: f.key,
            title: f.title
          };
        });
    }

    console.log('widget config: ', this.widget.config);
    console.log('widget activeFilters: ', this.widget.activeFilters);
    console.log('widget apiSearchParams: ', this.widget.apiSearchParams);

    this.activeModal.close(this.widget);
  }
}
