<div class="campaign-analytics-build-container">
  <div class="head">
    <h3 translate="CAMPAIGN_REPORT"></h3>
    <h2>{{campaign.name}}</h2>
  </div>

  <div class="body">
    <div class="body-inner">
      <form
        #form="ngForm"
        (submit)="submit(form)"
      >
        <div class="form-row">
          <div class="form-field half">
            <label translate="START_DATE"></label>
            <div
              class="input-wrapper"
              #startDateContainer
            >
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="startDate"
                [(ngModel)]="startDate"
                (ngModelChange)="onStartDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="true"
                [defaultHour]="00"
                [defaultMinute]="00"
                [defaultSeconds]="00"
                dateFormat="Y-m-d H:i"
                altFormat="M J Y, H:i"
                [maxDate]="endDate"
                [time24hr]="true"
                [appendTo]="startDateContainer.nativeElement"
                placeholder="Select..."
                [required]="true"
                (flatpickrOpen)="onFlatpickrOpen()"
              >
            </div>
          </div>
          <div class="form-field half">
            <label translate="END_DATE"></label>
            <div
              class="input-wrapper"
              #endDateContainer
            >
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="endDate"
                [(ngModel)]="endDate"
                (ngModelChange)="onEndDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="true"
                [defaultHour]="23"
                [defaultMinute]="59"
                [defaultSeconds]="59"
                dateFormat="Y-m-d H:i"
                altFormat="M J Y, H:i"
                [minDate]="startDate"
                [maxDate]="today"
                [time24hr]="true"
                [appendTo]="endDateContainer.nativeElement"
                placeholder="Select..."
                [required]="true"
                (flatpickrOpen)="onFlatpickrOpen()"
              >
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
            <div class="input-wrapper">
              <div
                *ngFor="let range of dateRangesIterable"
                class="inline-select-item"
                [class.selected]="range.id === selectedDateRange?.id"
                (click)="selectPresetDateRange(range)"
              >
                <i
                  *ngIf="range.id === selectedDateRange?.id"
                  class="ssi ssi-date-preset"
                ></i>
                <span>{{range.label}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row extra-margin-top">
          <div class="form-field">
            <label>Include deleted posts</label>
            <div class="input-wrapper">
              <div *ngFor="let option of includeDeletedOptionsIterable"
                  class="inline-select-item"
                  [class.selected]="option.key === selectedIncludeDeletedOption.key"
                  (click)="selectedIncludeDeletedOption = option">
                <i *ngIf="option.key === selectedIncludeDeletedOption.key"
                  class="ssi ssi-tick-small"></i>
                <span>{{option.label}}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-row extra-margin-top" *ngIf="postTags.length">
          <div class="form-field">
            <label>Include Post Tags</label>
            <div class="input-wrapper">
              <ssi-dropdown-select-2
                name="tagsToInclude"
                class="tag-selector"
                headButtonStyleVariant="analytics"
                size="large"
                [multiple]="true"
                [filterable]="true"
                [toggleAllEnabled]="false"
                [filterPlaceholder]="'Search tags to include...'"
                [headButtonIcon]="'ssi ssi-addsmall'"
                [toggleAllLabel]="'Select all tags'"
                [options]="postTags"
                [(ngModel)]="selectedTagsToInclude"
              >
                <ng-template ssiTemplateSelector="headButtonLabel"
                            let-expanded="expanded">
                  <div class="select-tags-placeholder"
                      *ngIf="!selectedTagsToInclude.length">
                    <span>Build your report with these tags only</span>
                  </div>
                  <div class="select-tag-labels"
                      *ngIf="selectedTagsToInclude.length">
                    <ng-container *ngFor="let tag of selectedTagsToInclude">
                      <span (click)="$event.stopPropagation(); removeIncludedTag(tag)">
                        {{tag.name}} <i class="ssi ssi-times"></i>
                      </span>
                    </ng-container>
                  </div>
                </ng-template>
              </ssi-dropdown-select-2>
            </div>
          </div>
        </div>

        <div class="form-row" *ngIf="postTags.length">
          <div class="form-field">
            <label>Exclude Post Tags</label>
            <div class="input-wrapper">
              <ssi-dropdown-select-2
                name="tagsToExclude"
                class="tag-selector"
                headButtonStyleVariant="analytics"
                size="large"
                [multiple]="true"
                [filterable]="true"
                [toggleAllEnabled]="false"
                [filterPlaceholder]="'Search tags to exclude...'"
                [headButtonIcon]="'ssi ssi-addsmall'"
                [toggleAllLabel]="'Select all tags'"
                [options]="postTags"
                [(ngModel)]="selectedTagsToExclude"
              >
                <ng-template ssiTemplateSelector="headButtonLabel" let-expanded="expanded">
                  <div class="select-tags-placeholder" *ngIf="!selectedTagsToExclude.length">
                    <span>Exclude these tags from your report...</span>
                  </div>
                  <div class="select-tag-labels" *ngIf="selectedTagsToExclude.length">
                    <ng-container *ngFor="let tag of selectedTagsToExclude">
                      <span (click)="$event.stopPropagation(); removeExcludedTag(tag)">
                        {{tag.name}} <i class="ssi ssi-times"></i>
                      </span>
                    </ng-container>
                  </div>
                </ng-template>
              </ssi-dropdown-select-2>
            </div>
          </div>
        </div>

        <button
          class="btn submit-button"
          type="submit"
          [disabled]="form.invalid"
        >Build analytics</button>
      </form>
    </div>
  </div>
</div>