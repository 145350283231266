<ng-template
  #agentTypingTemplate
  let-displayName="displayName"
  let-label="label"
>
  <div
    class="indicator agent-typing"
  >
    <div
      class="indicator-inner"
    >
      <span
        class="indicator-blobs"
      >
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>

      <span
        class="indicator-text"
      >
        <strong>{{ displayName }}</strong>
        
        <span
          translate="{{ label }}"
        ></span>
      </span>
    </div>
  </div>
</ng-template>

<ng-template
  #agentViewingTemplate
  let-displayName="displayName"
  let-label="label"
>
  <div
    class="indicator agent-viewing"
  >
    <div
      class="indicator-inner"
    >
      <i
        class="indicator-icon ssi ssi-eye"
      ></i>

      <span
        class="indicator-text"
      >
        <strong>{{ displayName }}</strong>

        <span
          translate="{{ label }}"
        ></span>
      </span>
    </div>
  </div>
</ng-template>

<ul
  *ngIf="!! isVisitorTyping"
>
  <li>
    <div
      class="indicator visitor-typing"
    >
      <div
        class="indicator-inner"
      >
        <img
          *ngIf="!! visitorAvatar"
          [src]="visitorAvatar"
          class="indicator-avatar"
        >

        <span
          class="indicator-blobs"
        >
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </div>
    </div>
  </li>
</ul>

<ul
  *ngIf="!! areActiveEvents"
>
  <ng-container
    *ngIf="!! areMultipleAgentsTyping"
  >
    <li
      (mouseenter)="toggleVisibilityOfTypingAgents()"
      (mouseleave)="toggleVisibilityOfTypingAgents()"
    >
      <ng-container
        *ngTemplateOutlet="agentTypingTemplate; context: { displayName: '', label: 'MULTIPLE_AGENTS_ARE_TYPING' }"
      ></ng-container>

      <div
        *ngIf="areTypingAgentsVisible"
        class="multiple-agents-container"
      >
        <ul
          class="multiple-agents typing"
        >
          <li
            *ngFor="let agent of typingAgents; let index = index"
          >
            <img
              [src]="agent.avatar"
              class="agent-avatar"
            >

            <strong
              class="agent-name"
            >
              {{ agent.displayName }}
            </strong>

            <span
              class="indicator-blobs agent-status-icon"
            >
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          </li>
        </ul>
      </div>
    </li>
  </ng-container>

  <ng-container
    *ngIf="!! isSingleAgentTyping"
  >
    <li>
      <ng-container
        *ngTemplateOutlet="agentTypingTemplate; context: { displayName: typingAgents[0].displayName, label: 'IS_TYPING' }"
      ></ng-container>
    </li>
  </ng-container>

  <ng-container
    *ngIf="!! areMultipleAgentsViewing"
  >
    <li
      (mouseenter)="toggleVisibilityOfViewingAgents()"
      (mouseleave)="toggleVisibilityOfViewingAgents()"
    >
      <ng-container
        *ngTemplateOutlet="agentTypingTemplate; context: { displayName: '', label: 'MULTIPLE_AGENTS_ARE_VIEWING' }"
      ></ng-container>

      <div
        *ngIf="areViewingAgentsVisible"
        class="multiple-agents-container"
      >
        <ul
          class="multiple-agents viewing"
        >
          <li
            *ngFor="let agent of viewingAgents; let index = index"
          >
            <img
              [src]="agent.avatar"
              class="agent-avatar"
            >

            <strong
              class="agent-name"
            >
              {{ agent.displayName }}
            </strong>

            <i
              class="agent-status-icon ssi ssi-eye"
            ></i>
          </li>
        </ul>
      </div>
    </li>
  </ng-container>

  <ng-container
    *ngIf="!! isSingleAgentViewing"
  >
    <li>
      <ng-container
        *ngTemplateOutlet="agentViewingTemplate; context: { displayName: viewingAgents[0].displayName, label: 'IS_VIEWING_THIS_CONVERSATION' }"
      ></ng-container>
    </li>
  </ng-container>
</ul>
