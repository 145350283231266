
<ng-template #linkConfigTooltipTemplate>
  <div
    class="publisher-link-config-tooltip-wrap"
    [class.publisher-link-config-tooltip-link-preview-visible]="showLinkPreview"
    (ssiOffClick)="handleOffClick()">
    <div class="publisher-link-config-tooltip-top">
      <ul class="publisher-link-config-togglers">
        <li
          class="publisher-link-config-toggler"
          [class.active]="activeLink.data.shorten"
          (click)="toggleLinkTracking()"
          [ssiTooltip]="'Link tracking'"
          [tooltipWindowClass]="'tooltip-chunky'"
          [tooltipAppendToBody]="true"
          [tooltipDisabled]="!showLinkPreview"
          tooltipPlacement="top">
          <i class="ssi ssi-link-tracking"></i>
        </li>
        <li
          class="publisher-link-config-toggler"
          [class.active]="activeLink.data.utm.enabled"
          [class.disabled]="utmTrackingDisabled()"
          (click)="toggleUtmTracking()"
          [ssiTooltip]="'UTM tracking'"
          [tooltipWindowClass]="'tooltip-chunky'"
          [tooltipAppendToBody]="true"
          [tooltipDisabled]="!showLinkPreview"
          tooltipPlacement="top">
          <i class="ssi ssi-utm"></i>
        </li>
      </ul>
      <ng-container *ngIf="activeLink.data.shorten && !showLinkWarning">
        <div class="publisher-link-config-prefix">
          <div>
            <div class="input-group">
              <span class="input-group-addon">http://{{ post.vanityDomains?.primary.domain || 'socsi.in' }}/</span>
              <div class="form-control-wrap">
                <input
                  type="text"
                  class="form-control form-control-no-border"
                  [placeholder]="'type option custom prefix...'"
                  [(ngModel)]="activeLink.data.prefix"
                  (ngModelChange)="post.updateCharactersRemaining();"
                  autofocus>
                <div class="info-wrap">
                  <i
                    [ssiTooltip]="'A custom prefix can be useful to give your audience an idea of what they are clicking on, e.g. you could type the name of your event or launch. It\'s optional, so no fear if nothing comes to mind!'"
                    tooltipPlacement="top"
                    [tooltipWindowClass]="'tooltip-chunky-big'"
                    [tooltipPlacement]="'bottom'"
                    [tooltipAppendToBody]="true"
                    class="ssi ssi-information info info-light">
                  </i>
                </div>
              </div>
              <span class="input-group-addon">
                {{ activeLink.data.link || 'xxxxx' }}
                <i
                  [ssiTooltip]="'This is a set of randomised alpha numerical characters to ensure your link is unique.'"
                  tooltipPlacement="top"
                  [tooltipWindowClass]="'tooltip-chunky-big'"
                  [tooltipPlacement]="'bottom'"
                  [tooltipAppendToBody]="true"
                  class="ssi ssi-information info info-dark">
                </i>
              </span>
            </div>
          </div>
        </div>
        <div class="publisher-link-config-prefix-actions">
          <button
            class="btn btn-primary btn-rounded"
            [disabled]="!activeLink.data.prefix"
            (click)="linkConfigTooltip.hide()">
            Save edit
          </button>
          <!-- <button
            class="btn btn-success btn-rounded"
            (click)="getLinkPreview()"
            *ngIf="post.features.customiseLinkPreview">
            Link preview
          </button> -->
        </div>
      </ng-container>
      <div class="publisher-link-config-prefix-alert text-danger" *ngIf="!activeLink.data.shorten">
        <div>
          <a href="javascript:;" (click)="activeLink.data.shorten = true"><i class="ssi ssi-warning"></i></a>
          <p>Link Tracking is highly recommended, it allows us to provide accurate data for your reports.</p>
        </div>
      </div>
    </div>

    <div
      class="publisher-link-config-tooltip-bottom"
      *ngIf="showLinkPreview"
    >
      <div
        class="loading"
        *ngIf="linkPreviewLoadingTracker.active">
      </div>

      <div
        class="publisher-link-config-tooltip-bottom-form"
        *ngIf="!linkPreviewLoadingTracker.active">
        <input
          *ngIf="editTitle"
          type="text"
          class="form-control form-control-no-border publisher-link-config-tooltip-bottom-form-title"
          [placeholder]="'Type your title...'"
          [(ngModel)]="activeLink.data.preview.title"
        >
        <div
          *ngIf="!editTitle"
          class="form-control form-control-no-border publisher-link-config-tooltip-bottom-form-title-disabled">
          {{activeLink.data.preview.title || 'No title'}}
        </div>
        <!-- <textarea
          class="form-control form-control-no-border publisher-link-config-tooltip-bottom-form-textarea"
          [placeholder]="'Description...'"
          [(ngModel)]="activeLink.data.preview.description"
        ></textarea> -->
        <div class="form-control form-control-no-border publisher-link-config-tooltip-bottom-form-textarea">
          {{activeLink.data.preview.description || 'No description'}}
        </div>

        <!-- [disabled]="linkedInLinkInput.invalid" -->

        <button
          *ngIf="canSetCustomLinkedInImage && supportsCustomLinkOption()"
          (click)="addLinkedInImageUrl()"
          class="btn btn-primary btn-block btn-rounded trigger-linkedin-image"
        >
          <i
            class="ssi ssi-addsmall"
          ></i>

          Add new image
        </button>
      </div>
      <div
        class="media-nav-wrap"
        *ngIf="!linkPreviewLoadingTracker.active && activeLink.data.preview.images.length > 0">
        <ng-template #previewWithoutNav>
          <div
            class="media-nav-img"
            [style.background-image]="'url(' + activeLink.data.preview.images[0].url + ')'"
            [ssiEnlargeImage]="activeLink.data.preview.images[0].url">
          </div>
        </ng-template>

        <ssi-media-nav
          *ngIf="canSetCustomLinkedInImage && supportsCustomLinkOption(); else previewWithoutNav"
          class="nav-btn-sm nav-btn-dark"
          [(activeFileIndex)]="activeLink.data.preview.selectedImageIndex"
          [files]="activeLink.data.preview.images"
          [useBackgroundImage]="true"
        ></ssi-media-nav>
      </div>
    </div>
  </div>
  <div data-popper-arrow class="tooltip-arrow"></div>
</ng-template>

<div class="publisher-landing-left" *ngIf="!textBoxOnly">
  <div class="d-flex publisher-btns">
    <div class="publisher-close-compose">
      <button
        class="publisher-close-btn"
        (click)="publisherActive.next({isActive: false})"
      >
        <span
          translate="CANCEL_POST"
          *ngIf="!post.edit"
        ></span>
        <span
          translate="CANCEL_EDIT"
          *ngIf="post.edit"
        ></span>
      </button>
    </div>
    <div class="flex-1">
      <ng-template #accountLabelTemplate>
        <span *ngIf="post.accounts.length === 0" class="font-bold">Select account</span>
        <span *ngIf="post.accounts.length === 1">
          {{ post.accounts[0].displayName || post.accounts[0].name }} <i class="fa-fw" [ngClass]="post.accounts[0].socialNetwork.icon.web"></i>
        </span>
        <span *ngIf="post.accounts.length > 1">
          {{ post.accounts.length }} accounts selected
          <i
            class="fa-fw"
            *ngFor="let network of post.socialNetworks"
            [ngClass]="network.config.icon.web">
          </i>
        </span>
      </ng-template>
      <ng-template #accountOptionTemplate let-account="option" let-isSelected="isSelected">
        <div class="account-option"
            title="{{account.displayName}}">
          <div class="account-option-left">
            <i [ngClass]="account.socialNetwork.icon.web"></i>
            <span>{{ account.displayName }}</span>
          </div>
          <div class="account-option-right">
            <div>
              <div class="account-type" *ngIf="account.accountTypeDisplayInfo">
                <i class="ssi ssi-{{account.accountTypeDisplayInfo.icon}}"></i>
                <span>{{account.accountTypeDisplayInfo.label}}</span>
              </div>
            </div>
            <div>
              <i class="ssi ssi-tick" *ngIf="isSelected"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ssi-dropdown-select
        class="accounts-dd"
        [(ngModel)]="post.accounts"
        [labelTemplate]="accountLabelTemplate"
        [optionTemplate]="accountOptionTemplate"
        [options]="post.selectableAccounts"
        [searchable]="true"
        [toggleAll]="true"
        [multiple]="true"
        [disabled]="!!disable?.changeAccount || !post.features.changeAccounts"
        [widthExtended]="true"
        theme="primary"
        searchPlaceholder="Search for an account or network...">
      </ssi-dropdown-select>
    </div>
    <div class="flex-1">
      <ng-template #campaignLabelTemplate>
        <span *ngIf="post.campaign">{{ post.campaign.name }}</span>
        <span *ngIf="!post.campaign" class="font-bold">Select Campaign</span> <span>(Optional)</span>
      </ng-template>
      <ng-template #campaignOptionTemplate let-campaign="option" let-isSelected="isSelected">
        <div class="d-flex" *ngIf="!campaign.parent_id">
          <div>
            {{ campaign.name }}
          </div>
          <div class="flex-1 text-right" *ngIf="isSelected">
            <i class="ssi ssi-tick"></i>
          </div>
        </div>
        <div
          class="publisher-landing-dropdown-item-indent"
          [class.underline]="!isSelected"
          *ngIf="campaign.parent_id">
          <i
            class="fa"
            [class.fa-circle-thin]="!isSelected"
            [class.fa-circle]="isSelected">
          </i>{{ campaign.name }}
        </div>
      </ng-template>
      <ssi-dropdown-select
        class="campaigns-dd"
        [(ngModel)]="post.campaign"
        [labelTemplate]="campaignLabelTemplate"
        [optionTemplate]="campaignOptionTemplate"
        [options]="campaigns"
        [searchable]="true"
        [widthExtended]="true"
        [disabled]="!!disable?.changeCampaign"
        theme="inverse"
        searchPlaceholder="Search for a campaign...">
      </ssi-dropdown-select>
    </div>
    <div class="tags-select-container">
      <ssi-dropdown-select-2 #tagsSelector
                             [xOffset]="'6px'"
                             [options]="tags"
                             [filterable]="true"
                             [multiple]="true"
                             [toggleAllEnabled]="false"
                             [filterPlaceholder]="'Type post tags...'"
                             [(ngModel)]="selectedTags"
                             (ngModelChange)="postTagsChange($event)"
                             (optionsFiltered)="onOptionsFiltered($event)">
        <ng-template ssiTemplateSelector="headButton"
                     let-expanded="expanded"
                     let-toggle="toggle">
          <button type="button"
                  class="tags-selector-btn"
                  (click)="toggle()">
            <span>Post Tags</span>
            <i class="ssi ssi-tags"></i>
          </button>
        </ng-template>
      
        <ng-template ssiTemplateSelector="emptyState"
                     let-toggle="toggle">
          <div class="no-tags-state"
               *ngIf="!tagsSelector.filterTerm">
            <i class="ssi ssi-tags"></i>
            <p class="title">No Post Tags yet!</p>
            <p class="desc">Just start typing then create your new tags</p>
          </div>
        </ng-template>
      
        <ng-template ssiTemplateSelector="bodyFooter"
                     let-toggle="toggle">
          <div class="no-tags-state"
               *ngIf="tagsSelector.filterTerm && createTagButtonVisible">
            <button type="button"
                    class="g-btn"
                    *ngIf="tagsSelector.filterTerm"
                    (click)="createTag(tagsSelector.filterTerm)"><span>Create "{{tagsSelector.filterTerm}}"</span><i class="ssi ssi-plus"></i></button>
          </div>
        </ng-template>
      </ssi-dropdown-select-2>

      <div class="selected-tags-count"
           *ngIf="selectedTags.length">
        <span>{{selectedTags.length}}</span>
      </div>
    </div>
  </div>

  <!--
  <ng-template #linkedInImageTooltipTemplate>
    <div
      (ssiOffClick)="linkedInImageTooltip.hide()"
      [offClickIgnore]="linkedInImageTooltipTrigger">
      <input
        [ssiFocusOn]="true"
        type="url"
        required
        class="form-control form-control-no-border spacer-bottom"
        [placeholder]="'Add your link (needs to start with http)'"
        [(ngModel)]="linkedInLink"
        #linkedInLinkInput="ngModel">
      <div class="text-danger">
        <i class="ssi ssi-fw ssi-feather-note"></i> To be able to upload an image along with your LinkedIn post, you also need to attach a link (above) before you hit save!
      </div>
      <br>
      <div class="row">
        <div class="col-xs-6 col-xs-offset-3">
          <button
            class="btn btn-primary btn-block btn-rounded"
            [disabled]="linkedInLinkInput.invalid"
            (click)="addLinkedInImageUrl()">
            <i class="fa fa-plus"></i> Add image
          </button>
        </div>
      </div>
    </div>
  </ng-template> -->

  <input
    class="form-control form-control-no-border publisher-video-title-form-control"
    *ngIf="post.files.length > 0 && post.files[0].type === OutboxFileType.Video && post.mediaRestrictions.video.title.length.min > 0"
    type="text"
    [disabled]="!!disable?.editMedia"
    required
    [maxlength]="post.mediaRestrictions.video.title.length.max"
    [(ngModel)]="post.files[0].video.title"
    placeholder="Type your video title here..."
    ssiElementLifecycle
    (init)="refreshTextInputHighlight()"
    (destroy)="refreshTextInputHighlight()">

  <input
    class="form-control form-control-no-border publisher-video-title-form-control"
    *ngIf="post.files.length > 0 && post.files[0].type === OutboxFileType.Gif && post.mediaRestrictions.gif.title.length.min > 0"
    type="text"
    required
    [maxlength]="post.mediaRestrictions.gif.title.length.max"
    [(ngModel)]="post.files[0].gif.title"
    placeholder="Type your gif title here..."
    ssiElementLifecycle
    (init)="refreshTextInputHighlight()"
    (destroy)="refreshTextInputHighlight()">

  <div
    class="publisher-textarea-container"
    [class.disabled]="post.accounts.length === 0"
    ssiEmojiFormControlContainer
    ssiTextInputHighlightContainer
    [(formControlValue)]="post.text">
    <span
      *ngIf="post.edit && post.edit.social_id"
      class="publisher-textarea-container-badge"
    >Live Post</span>
    <label class="a11y-label" for="publisherTextArea">Publisher text:</label>
    <textarea
      #textarea
      class="publisher-text-form-control"
      [disabled]="disable?.editText || post.accounts.length === 0"
      [placeholder]="post.accounts.length === 0 ? '' : (post.isSplit && post.splitPostAccount.socialNetwork.accountTypeGroupName === 'instagram' ? 'Type away your Instagram caption, but remember no more than 30 hashtags!' : 'Type away... Need some inspiration? Head over to the Content Generator!')"
      [(ngModel)]="post.text"
      (ngModelChange)="textareaValueChanged()"
      id="publisherTextArea"
      name="publisherTextArea"
      ssiEmojiFormControl
      #linkConfigTooltip="ssiTooltip"
      [ssiTooltip]
      tooltipPlacement="bottom"
      tooltipTrigger="manual"
      tooltipWindowClass="publisher-tooltip-window"
      [tooltipHost]="linkConfigTooltipHost"
      [tooltipTemplate]="linkConfigTooltipTemplate"
      ssiTextInputAutocomplete
      [findChoices]="getAutocompleteMentions"
      [getChoiceLabel]="getChoiceLabel"
      [menuComponent]="mentionsMenuComponent"
      [searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
      [selectedChoices]="getSelectedChoices()"
      (selectedChoicesChange)="post.onSelectedChoicesChange($event)">
    </textarea>

    <ssi-emoji-autocomplete [textInputElement]="textarea"></ssi-emoji-autocomplete>

    <ssi-text-input-highlight
      #textInputHighlight
      [tags]="post.highlightEntities"
      [textInputElement]="textarea"
      [textInputValue]="post.text"
      (tagClick)="highlightEntityClicked($event)"
      (tagMouseEnter)="highlightTagMouseEnter($event)"
      (tagMouseLeave)="highlightTagMouseLeave($event)"
      ssiTooltip="Click to edit your link"
      [tooltipAppendToBody]="true"
      #linkHighlightTagHoverTooltip="ssiTooltip"
      tooltipTrigger="manual"
      [tooltipHost]="highlightTagHoverElement"
      [tooltipWindowClass]="'tooltip-chunky'"
      [tooltipPlacement]="'bottom'"
      [tooltipDisabled]="!highlightTagHoverElement">
    </ssi-text-input-highlight>

    <div class="publisher-textarea-footer"
        [class.mobile-device]="isMobile">
      <div class="publisher-textarea-footer-left">
        <button
          *ngIf="supportsContentExpiry"
          [ngClass]="{'btn-left-action-active': expiryDateToggle}"
          class="btn btn-left-action"
          ssiTooltip="Set post expiry date"
          tooltipWindowClass="tooltip-chunky"
          (click)="setPostExpiryDate()"
        >
          <i class="ssi ssi-expiry"></i>
        </button>
        <button
          *ngIf="post.scheduleFirstCommentToggleVisible()"
          [ngClass]="{'btn-left-action-active': post.scheduleFirstCommentToggled}"
          class="btn btn-left-action"
          [ssiTooltip]="post.scheduleFirstCommentToggled ? 'Scheduling your first comment will take you to a new page after you publish your post' : 'Schedule first comment'"
          tooltipWindowClass="tooltip-chunky"
          (click)="post.scheduleFirstCommentToggled = !post.scheduleFirstCommentToggled"
        >
          <i class="ssi ssi-first-comment-temporary"></i>
        </button>
        <ng-container *ngIf="!isMobile" >
          <ng-container *ngTemplateOutlet="composerFooterButtons"></ng-container>
        </ng-container>
        <a
          *ngIf="post.reply?.socialId && post.mentions.length === 0 && post.accounts.length === 1"
          [href]="'https://twitter.com/' + post.accounts[0].username + '/status/' + post.reply.socialId"
          target="_blank"
          class="btn btn-split-post"
          [ssiTooltip]="'Replying to @' + post.accounts[0].username">
          <i class="fa fa-fw fa-reply"></i>
        </a>
      </div>
      <div class="publisher-textarea-footer-right">
        <span
          *ngIf="post.accounts.length > 0"
          class="publisher-textarea-character-count"
          [class.active]="post.text"
          [class.invalid]="post.charactersRemaining < 0">
          {{ post.charactersRemaining }}
        </span>
        <ssi-emoji-form-control-trigger popoverPlacement="top">
          <a href="javascript:;" class="publisher-textarea-footer-right-icon" [hidden]="post.accounts.length === 0">
            <i class="ssi ssi-emoji"></i>
            <span class="a11y-label">Open emoji picker</span>
          </a>
        </ssi-emoji-form-control-trigger>
        <ng-container *ngIf="!disable?.editMedia">
          <a
            *ngIf="!post.hasInstagramSelected() && post.mediaRestrictions.gif.remaining > 0"
            [hidden]="!!fileUploadActive"
            [ssiTooltip]="'SELECT_A_GIF_FROM_GIPHY' | translate"
            tooltipPlacement="top"
            [tooltipAppendToBody]="true"
            [tooltipWindowClass]="'tooltip-chunky'"
            href="javascript:;"
            class="publisher-textarea-footer-right-icon"
            ssiGifSearch
            (onGifFileUploaded)="gifUploadSuccess($event)"
            >
            <i class="ssi ssi-gif"></i>
            <span class="a11y-label">{{'SELECT_A_GIF_FROM_GIPHY' | translate}}</span>
          </a>
          <span
            class="add-more"
            *ngIf="!post.instagramAccountsOnly() && post.files.length > 0 && post.mediaRestrictions.image.remaining > 0"
            [hidden]="!!fileUploadActive"
            ssiFileUploader
            [mediaCategory]="mediaCategory.Post"
            [accept]="post.mediaRestrictions.image.mimetypes"
            [maxFiles]="post.mediaRestrictions.image.remaining"
            [fromSources]="fileUploadSources"
            [restrictions]="post.mediaRestrictions"
            (uploadStart)="imageUploadStarted()"
            (uploadFinally)="fileUploadComplete()"
            (uploadSuccess)="onUploadSuccess($event)"
            (onClose)="onPickerClosed()"
            (uploadError)="fileUploadError.emit($event)">
            <i class="ssi ssi-addsmall"></i>
            Add {{ post.mediaRestrictions.image.remaining }} more <i class="ssi ssi-add-image-correct"></i>
            <span class="a11y-label">Add Image</span>
          </span>
          <a
            *ngIf="!post.instagramAccountsOnly() && post.files.length === 0 && post.mediaRestrictions.image.remaining > 0 && (!post.mediaRestrictions.image.linkRequired || post.links.length > 0)"
            [hidden]="!!fileUploadActive"
            href="javascript:;"
            class="publisher-textarea-footer-right-icon"
            [ssiTooltip]="(post.isSplit && post.splitPostAccount.socialNetwork.accountTypeGroupName === 'instagram') ? 'Don’t forget your aspect ratios! 1:1 (square), 4:5 (portrait) and 1.91:1 (landscape)' : 'Upload a jpeg or GIF'"
            [tooltipAppendToBody]="true"
            tooltipPlacement="top"
            [tooltipWindowClass]="'tooltip-chunky'"
            ssiFileUploader
            #singleImageUploader="ssiFileUploader"
            [mediaCategory]="mediaCategory.Post"
            [accept]="post.mediaRestrictions.image.mimetypes"
            [maxFiles]="post.mediaRestrictions.image.remaining"
            [fromSources]="fileUploadSources"
            [restrictions]="post.mediaRestrictions"
            (uploadStart)="imageUploadStarted()"
            (uploadFinally)="fileUploadComplete()"
            (uploadSuccess)="onUploadSuccess($event)"
            (onClose)="onPickerClosed()"
            (uploadError)="fileUploadError.emit($event)">
            <i class="ssi ssi-add-image-correct"></i>
            <span class="a11y-label">Add Image</span>
          </a>

          <!--
          <a
            #linkedInImageTooltip="ssiTooltip"
            #linkedInImageTooltipTrigger
            [hidden]="!post.mediaRestrictions.image.linkRequired || post.mediaRestrictions.image.remaining === 0 || !!fileUploadActive || post.links.length > 0 && (!post.isSplit || !post.splitPostAccount.socialNetwork.accountTypeGroupName === 'instagram')"
            [ssiTooltip]
            [tooltipTemplate]="linkedInImageTooltipTemplate"
            class="publisher-textarea-footer-right-icon"
            href="javascript:;"
            tooltipPlacement="top"
            tooltipTrigger="click"
            tooltipWindowClass="publisher-tooltip-window publisher-linkedin-image-tooltip"
          >
            <i class="ssi ssi-picture-paperclip"></i>
          </a> -->

          <a
            *ngIf="!post.instagramAccountsOnly() && post.mediaRestrictions.video.remaining > 0"
            [hidden]="!!fileUploadActive"
            href="javascript:;"
            class="publisher-textarea-footer-right-icon"
            [ssiTooltip]="(post.isSplit && post.splitPostAccount.socialNetwork.accountTypeGroupName === 'instagram') ? 'Instagram videos need to be in the correct ratio before uploading. Check out our Help Centre for the info.' : 'Check your video size to make sure your video looks great on each social network.'"
            [tooltipAppendToBody]="true"
            tooltipIconClass="ssi ssi-pro-tip"
            tooltipPlacement="top"
            [tooltipWindowClass]="'dark rounded'"
            ssiFileUploader
            [mediaCategory]="mediaCategory.Post"
            [accept]="post.mediaRestrictions.video.mimetypes"
            [maxFiles]="post.mediaRestrictions.video.remaining"
            [restrictions]="post.mediaRestrictions"
            [fromSources]="fileUploadSources"
            (uploadStart)="videoUploadStarted()"
            (uploadFinally)="fileUploadComplete()"
            (uploadSuccess)="onUploadSuccess($event)"
            (onClose)="onPickerClosed()"
            (uploadError)="fileUploadError.emit($event)">
            <i class="ssi ssi-video"></i>
            <span class="a11y-label">Add Video</span>
          </a>

          <a
            *ngIf="post.instagramAccountsOnly() && post.mediaRestrictions.reel.remaining > 0"
            [hidden]="!!fileUploadActive"
            href="javascript:;"
            class="publisher-textarea-footer-right-icon"
            [ssiTooltip]="'Upload a single Reel video'"
            [tooltipAppendToBody]="true"
            tooltipPlacement="top"
            [tooltipWindowClass]="'dark rounded'"
            ssiFileUploader
            [mediaCategory]="mediaCategory.Reel"
            [accept]="post.mediaRestrictions.reel.mimetypes"
            [maxFiles]="post.mediaRestrictions.reel.remaining"
            [restrictions]="post.mediaRestrictions"
            [fromSources]="fileUploadSources"
            (uploadStart)="videoUploadStarted()"
            (uploadFinally)="fileUploadComplete()"
            (uploadSuccess)="onUploadSuccess($event)"
            (onClose)="onPickerClosed()"
            (uploadError)="fileUploadError.emit($event)">
            <i class="ssi ssi-ig-reels"></i>
            <span class="a11y-label">Add Reel</span>
          </a>

          <a
            *ngIf="post.instagramAccountsOnly() && post.mediaRestrictions.imageStory.remaining > 0"
            [hidden]="!!fileUploadActive"
            href="javascript:;"
            class="publisher-textarea-footer-right-icon"
            [ssiTooltip]="'Upload a single story'"
            [tooltipAppendToBody]="true"
            tooltipPlacement="top"
            [tooltipWindowClass]="'dark rounded'"
            ssiFileUploader
            [mediaCategory]="mediaCategory.Story"
            [accept]="getInstagramStoryMimetypes()"
            [maxFiles]="post.mediaRestrictions.imageStory.remaining"
            [restrictions]="post.mediaRestrictions"
            [fromSources]="fileUploadSources"
            (uploadStart)="videoUploadStarted()"
            (uploadFinally)="fileUploadComplete()"
            (uploadSuccess)="onUploadSuccess($event)"
            (onClose)="onPickerClosed()"
            (uploadError)="fileUploadError.emit($event)">
            <i class="ssi ssi-ig-stories"></i>
            <span class="a11y-label">Add Story</span>
          </a>

          <a
            *ngIf="post.instagramAccountsOnly() && post.mediaRestrictions.image.remaining > 0"
            [hidden]="!!fileUploadActive"
            href="javascript:;"
            class="publisher-textarea-footer-right-icon"
            [ssiTooltip]="'Upload up to 10 images, videos, or a mix of the two for a carousel post. Remaining: ' + post.mediaRestrictions.image.remaining"
            [tooltipAppendToBody]="true"
            tooltipPlacement="top"
            [tooltipWindowClass]="'dark rounded'"
            ssiFileUploader
            [mediaCategory]="mediaCategory.Post"
            [accept]="getInstagramCarouselMimetypes()"
            [maxFiles]="post.mediaRestrictions.image.remaining"
            [restrictions]="post.mediaRestrictions"
            [fromSources]="fileUploadSources"
            (uploadStart)="videoUploadStarted()"
            (uploadFinally)="fileUploadComplete()"
            (uploadSuccess)="onUploadSuccess($event)"
            (onClose)="onPickerClosed()"
            (uploadError)="fileUploadError.emit($event)">
            <i class="ssi ssi-ig-carousel"></i>
            <span class="a11y-label">Add Carousel</span>
          </a>
        <ssi-menu
          *ngIf="post.accounts.length > 0 && post.metaAccountsOnly()"
          #actionsMenu
          [headAlignment]="'top right'"
          [bodyAlignment]="'top right'"
          cdkOverlayOrigin
          #locationTagMenuOrigin="cdkOverlayOrigin"
          class="publisher-textarea-footer-right-icon"
        >
          <ng-template ssiTemplateSelector="menuHead">
            <i
              [class.active]="selectedLocationProfile"
              class="ssi ssi-location menu-trigger-icon"
              [ssiTooltip]="selectedLocationProfile ? selectedLocationProfile.location.fullLocation : 'Add location tag'"
              [tooltipAppendToBody]="true"
              tooltipPlacement="top"
              [tooltipWindowClass]="'dark rounded'"
            ></i>
            <span class="a11y-label">Add Location</span>
          </ng-template>

          <ng-template ssiTemplateSelector="menuBody">
            <div class="heading">
              <span>Location search</span>
            </div>
            <hr>
            <div class="tags-input-input-container custom-scrollbar">
              <label for="locationInput">
                <input
                  id="locationInput"
                  class="tags-input-input"
                  type="text"
                  [placeholder]="'Search location...'"
                  [(ngModel)]="locationInput"
                  (keyup)="onKeyUp($event)"
                >
                <i
                  class="ssi ssi-correct-search tags-input-input-container-icon"
                  (click)="searchLocation()"
                ></i>
              </label>
            </div>
            <ul>
              <ssi-spinner [visible]="searchingLocation && !locationTags"></ssi-spinner>
              <p *ngIf="!searchingLocation && locationTags && locationTags.length === 0">No results for your search, try narrowing your search with a more specific location.</p>
              <li
                *ngFor="let profile of locationTags"
                (click)="setLocationTag(profile); actionsMenu.close()"
                [class.selected]="selectedLocationProfile === profile"
                title="{{profile.location.fullLocation}}"
              ><span>{{profile.location.fullLocation}}</span>
                  <i class="select-icon ssi ssi-tick"></i>
              </li>
            </ul>
          </ng-template>
        </ssi-menu>


          <a
            *ngIf="post.accounts.length > 0"
            href="javascript:;"
            (click)="openAiContextMenu()"
            class="publisher-textarea-footer-right-icon"
            [ssiTooltip]="hasWritingToolEnabled ? ('LOST_FOR_WORDS_USE_THIS_TOOL_TO_WRITE_A_POST_REPHRASE_AN_EXISTING_ONE_AND_ADD_TOPICAL_HASHTAGS' | translate) : ('LOST_FOR_WORDS_WE_HAVE_JUST_THE_THING_CONTACT_YOUR_CUSTOMER_SUCCESS_MANAGER_TO_UPGRADE_YOUR_PACKAGE_TO_INCLUDE_GENERATE_OUR_AIPOWERED_CAPTION_WRITER' | translate)"
            tooltipTitle="Generate"
            [tooltipAppendToBody]="true"
            tooltipPlacement="top"
            tooltipTrailingIconClass="ssi ssi-enhance"
            [tooltipWindowClass]="'tooltip-chunky-big'"
          >
            <i class="ssi ssi-ai-writing-tool ai-writing-tool-icon"></i>
            <span class="a11y-label">AI Tool</span>
          </a>
          <ng-container *ngIf="showAiContextMenu">
            <ul class="ai-writing-tool-context-menu" (ssiOffClick)="showAiContextMenu = false">
              <li
                class="menu-item"
                (click)="openAiWritingToolModal('generate')"
              >
                <i class="ssi ssi-ai-writing-tool"></i>
                <span translate="GENERATE_POST"></span>
              </li>
              <li
                class="menu-item"
                (click)="openAiWritingToolModal('rephrase')"
                [class.disabled]="!post.text"
                [ssiTooltip]="!post.text ? 'Whoa there! Add some text in the compose area first.' : ''"
                [tooltipAppendToBody]="true"
                tooltipPlacement="top"
                [tooltipWindowClass]="'tooltip-chunky'"
              >
                <ssi-icon icon="reload" height="14" width="14" color="#838eab"></ssi-icon>
                <span translate="REPHRASE_POST"></span>
              </li>
              <li
                class="menu-item"
                (click)="openAiWritingToolModal('hashtags')"
                [class.disabled]="!post.text"
                [ssiTooltip]="!post.text ? 'Whoa there! Add some text in the compose area first.' : ''"
                [tooltipAppendToBody]="true"
                tooltipPlacement="top"
                [tooltipWindowClass]="'tooltip-chunky'"
              >
                <i class="ssi ssi-populate-hashtags"></i>
                <span translate="POPULATE_HASHTAGS"></span>
              </li>
              <!-- <hr>
              <li
                class="menu-item"
              >
                <a
                  href="https://support.orlo.tech/communicate#outbox"
                  target="_blank"
                >
                  <i class="ssi ssi-information"></i>
                  <span translate="HOW_ORLO_AI_WORKS"></span>
                </a>
              </li> -->
            </ul>
          </ng-container>
          <span class="label active" *ngIf="!!fileUploadActive">
            <i class="fa fa-fw fa-spinner fa-spin"></i>
            <span *ngIf="fileUploadActive === OutboxFileType.Video">Loading video</span>
            <span *ngIf="fileUploadActive === OutboxFileType.Image">Loading image</span>
            <span *ngIf="fileUploadActive === OutboxFileType.Gif">Loading gif</span>
          </span>
        </ng-container>
      </div>
    </div>

    <div class="publisher-textarea-footer publisher-textarea-footer-addl-if-mobile"
        *ngIf="isMobile">
      <div class="publisher-textarea-footer-left">
        <ng-container *ngTemplateOutlet="composerFooterButtons"></ng-container>
      </div>
      <div class="publisher-textarea-footer-right">

      </div>
    </div>

    <div class="no-accounts-wrap">
      <p>Select some accounts to get started!</p>
      <i class="ssi ssi-select-microcopy"></i>
    </div>
  </div>
</div>
<ssi-publisher-actions-and-media
  #publisherActionsAndMedia
  class="publisher-landing-right"
  [post]="post"
  [disable]="disable"
  [disablePublish]="disablePublish"
  [hasMedia]="post.files.length > 0"
  [publishingLoadingTracker]="publishingLoadingTracker"
  [postInvalidTooltipTemplate]="postInvalidTooltipTemplate"
  [fileUploadSources]="fileUploadSources"
  (fileUploadSuccess)="onUploadSuccess($event)"
  (fileUploadError)="fileUploadError.emit($event)"
  (changeStage)="handleChangeStage($event)"
  (publish)="publish.emit()"
  (saveAsDraft)="saveAsDraft.emit()"
  (showPostPreview)="showPostPreview.emit()"
  *ngIf="stage === stages.ActionsAndMedia && !textBoxOnly">
</ssi-publisher-actions-and-media>

<ssi-publisher-targeting
  *ngIf="stage === stages.Targeting && !textBoxOnly"
  (changeStage)="handleChangeStage($event)"
  [post]="post"
  class="publisher-landing-right"
></ssi-publisher-targeting>

<div *ngIf="textBoxOnly">
  <div
      class="publisher-textarea-container"
      [class.disabled]="post.accounts.length === 0"
      ssiEmojiFormControlContainer
      ssiTextInputHighlightContainer
      [(formControlValue)]="post.text">
      <label class="a11y-label" for="publisherText">Publisher text:</label>
      <textarea
        class="publisher-text-form-control"
        [disabled]="disable?.editText || post.accounts.length === 0"
        [placeholder]="post.accounts.length === 0 ? '' : (post.isSplit && post.splitPostAccount.socialNetwork.accountTypeGroupName === 'instagram' ? 'Type away your Instagram caption, but remember no more than 30 hashtags!' : 'Type away... Need some inspiration? Head over to the Content Generator!')"
        [(ngModel)]="post.text"
        (ngModelChange)="textareaValueChanged()"
        id="publisherText"
        name="publisherText"
        ssiEmojiFormControl
        #textarea
        #linkConfigTooltip="ssiTooltip"
        [ssiTooltip]
        tooltipPlacement="bottom"
        tooltipTrigger="manual"
        tooltipWindowClass="publisher-tooltip-window"
        [tooltipHost]="linkConfigTooltipHost"
        [tooltipTemplate]="linkConfigTooltipTemplate"
        ssiTextInputAutocomplete
        [findChoices]="getAutocompleteMentions"
        [getChoiceLabel]="getChoiceLabel"
        [menuComponent]="mentionsMenuComponent"
        [searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
        [selectedChoices]="getSelectedChoices()"
        (selectedChoicesChange)="post.onSelectedChoicesChange($event)">
      </textarea>
      <ssi-text-input-highlight
        #textInputHighlight
        [tags]="post.highlightEntities"
        [textInputElement]="textarea"
        [textInputValue]="post.text"
        (tagClick)="highlightEntityClicked($event)"
        (tagMouseEnter)="highlightTagMouseEnter($event)"
        (tagMouseLeave)="highlightTagMouseLeave($event)"
        ssiTooltip="Click to edit your link"
        [tooltipAppendToBody]="true"
        #linkHighlightTagHoverTooltip="ssiTooltip"
        tooltipTrigger="manual"
        [tooltipHost]="highlightTagHoverElement"
        [tooltipWindowClass]="'tooltip-chunky'"
        [tooltipPlacement]="'bottom'"
        [tooltipDisabled]="!highlightTagHoverElement">
      </ssi-text-input-highlight>

      <div class="publisher-textarea-footer">
        <div class="publisher-textarea-footer-left">
          <span *ngIf="post.isSplit">
            <button
              class="btn btn-split-post btn-split-post-action"
              (click)="setIsSplit(false)"
              [ssiTooltip]="'This auto selects the post with the least amount of characters due to network limits'"
              [tooltipWindowClass]="'tooltip-chunky-big'"
              tooltipTitle="Combine posts"
              *ngIf="post.features.combinePostText">
              <i class="ssi ssi-combine-post"></i> Combine
            </button>
            <span *ngFor="let network of post.socialNetworks" ngbDropdown>
              <button
                class="btn dropdown-toggle"
                [ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
                [tooltipOffset]="[0, 20]"
                [tooltipWindowClass]="'tooltip-chunky-big'"
                [tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image.min || network.config.mediaRestrictions.post.gif.min || network.config.mediaRestrictions.post.video.min)"
                ngbDropdownToggle
                [class.btn-primary]="post.splitPostAccount.socialNetwork === network.config"
                [class.btn-split-post]="post.splitPostAccount.socialNetwork !== network.config"
                *ngIf="network.accounts.length > 1">
                <i class="fa-fw" [ngClass]="network.config.icon.web"></i> <span *ngIf="post.splitPostAccount.socialNetwork === network.config">{{ post.splitPostAccount.displayName | truncate:14 }}</span> <span class="caret"></span>
              </button>
              <button
                class="btn"
                [ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
                [tooltipOffset]="[0, 20]"
                [tooltipWindowClass]="'tooltip-chunky-big'"
                [tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image.min || network.config.mediaRestrictions.post.gif.min || network.config.mediaRestrictions.post.video.min)"
                [class.btn-primary]="post.splitPostAccount.socialNetwork === network.config"
                [class.btn-split-post]="post.splitPostAccount.socialNetwork !== network.config"
                (click)="post.splitPostAccount = network.accounts[0]"
                *ngIf="network.accounts.length === 1">
                <i class="fa-fw" [ngClass]="network.config.icon.web"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-inbox" ngbDropdownMenu>
                <a
                  *ngFor="let account of network.accounts"
                  href="javascript:;"
                  class="dropdown-item"
                  [class.active]="post.splitPostAccount === account"
                  (click)="post.splitPostAccount = account">
                  {{ account.displayName }}
                </a>
              </div>
            </span>
          </span>
          <button
            class="btn btn-split-post btn-rounded btn-split-post-action"
            *ngIf="!post.isSplit && post.features.changeAccounts && post.accounts.length > 1"
            [disabled]="post.accounts.length < 2"
            (click)="setIsSplit(true)"
            [ssiTooltip]="'Produce multiple copies of your post & make the network specific changes you need.'"
            tooltipTitle="Split posts"
            [tooltipWindowClass]="'tooltip-chunky-big'">
            <i class="ssi ssi-split-post"></i> Split posts
          </button>
          <a
            href="https://support.orlo.tech/blocking-words"
            target="_blank"
            *ngIf="post.blockingWordMatches.length > 0"
            class="btn btn-danger"
            [ssiTooltip]="'THE_POST_MATCHES_THE_FOLLOWING_PROFANITIES_AND_WILL_BE_SENT_FOR_VALIDATION__MATCHEDWORDS_' | translate:{matchedWords: post.blockingWordMatches.join(', ')}">
            <i class="fa fa-fw fa-exclamation-circle"></i>
          </a>
          <a
            *ngIf="post.reply?.socialId && post.mentions.length === 0 && post.accounts.length === 1"
            [href]="'https://twitter.com/' + post.accounts[0].username + '/status/' + post.reply.socialId"
            target="_blank"
            class="btn btn-split-post"
            [ssiTooltip]="'Replying to @' + post.accounts[0].username">
            <i class="fa fa-fw fa-reply"></i>
          </a>
        </div>
        <div class="publisher-textarea-footer-right">
          <span
            *ngIf="post.accounts.length > 0"
            class="publisher-textarea-character-count"
            [class.active]="post.text"
            [class.invalid]="post.charactersRemaining < 0">
            {{ post.charactersRemaining }}
          </span>
          <ssi-emoji-form-control-trigger popoverPlacement="bottom">
            <a href="javascript:;" class="publisher-textarea-footer-right-icon" [hidden]="post.accounts.length === 0">
              <i class="ssi ssi-emoji"></i>
            </a>
          </ssi-emoji-form-control-trigger>
        </div>
      </div>
      <i class="ssi ssi-select-microcopy no-accounts-icon"></i>
    </div>
  </div>

<ssi-context-menu #mentionClickContextMenu
                  [offsetTop]="20"
                  [offsetLeft]="-20"
                  [skipViewPortCheck]="true">
  <ng-template let-ctx>
    <div class="publisher-mention-click-context-menu">
      <button type="button"
              translate="EDIT_HANDLE"
              (click)="editMention(ctx)"></button>
      <ng-container *ngIf="ctx.tag.data.url">
        <div class="vertical-line"></div>
        <a href="{{ctx.tag.data.url}}"
            target="_blank">
          <i class="ssi ssi-new-tab"></i>
        </a>
      </ng-container>
    </div>
  </ng-template>
</ssi-context-menu>

<ng-template #composerFooterButtons>
  <span *ngIf="post.isSplit">
    <button
      class="btn btn-split-post btn-split-post-action"
      (click)="setIsSplit(false)"
      [ssiTooltip]="'This auto selects the post with the least amount of characters due to network limits'"
      [tooltipWindowClass]="'tooltip-chunky-big'"
      tooltipTitle="Combine posts"
      *ngIf="post.features.combinePostText">
      <i class="ssi ssi-combine-post"></i> Combine
    </button>
    <span *ngFor="let network of post.socialNetworks" ngbDropdown>
      <button
        class="btn dropdown-toggle"
        [ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
        [tooltipOffset]="[0, 20]"
        [tooltipWindowClass]="'tooltip-chunky-big'"
        [tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image.min || network.config.mediaRestrictions.post.gif.min || network.config.mediaRestrictions.post.video.min)"
        ngbDropdownToggle
        [class.btn-primary]="post.splitPostAccount.socialNetwork === network.config"
        [class.btn-split-post]="post.splitPostAccount.socialNetwork !== network.config"
        *ngIf="network.accounts.length > 1">
        <i class="fa-fw" [ngClass]="network.config.icon.web"></i> <span class="dropdown-toggle-meta" *ngIf="post.splitPostAccount.socialNetwork === network.config">{{ post.splitPostAccount.displayName | truncate:14 }}</span> <span class="caret"></span>
      </button>
      <button
        class="btn"
        [ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
        [tooltipOffset]="[0, 20]"
        [tooltipWindowClass]="'tooltip-chunky-big'"
        [tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image.min || network.config.mediaRestrictions.post.gif.min || network.config.mediaRestrictions.post.video.min)"
        [class.btn-primary]="post.splitPostAccount.socialNetwork === network.config"
        [class.btn-split-post]="post.splitPostAccount.socialNetwork !== network.config"
        (click)="post.splitPostAccount = network.accounts[0]"
        *ngIf="network.accounts.length === 1">
        <i class="fa-fw" [ngClass]="network.config.icon.web"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-inbox split-post-account-select-menu" ngbDropdownMenu>
        <a
          *ngFor="let account of network.accounts"
          href="javascript:;"
          class="dropdown-item"
          [class.active]="post.splitPostAccount === account"
          (click)="post.splitPostAccount = account">
          {{ account.displayName }}
        </a>
      </div>
    </span>
  </span>
  <button
    class="btn btn-split-post btn-rounded btn-split-post-action"
    *ngIf="!post.isSplit && post.features.changeAccounts && post.accounts.length > 1"
    [disabled]="post.accounts.length < 2"
    (click)="setIsSplit(true)"
    [ssiTooltip]="'Produce multiple copies of your post & make the network specific changes you need.'"
    [tooltipWindowClass]="'tooltip-chunky-big'"
    tooltipTitle="Split posts">
    <i class="ssi ssi-split-post"></i> Split posts
  </button>
  <div class="video-visibility"
    *ngIf="checkYoutubeAccountSelected() && post.videoFiles.length">
    <select
      [(ngModel)]="post.youtubeVisibility"
      [ssiTooltip]="'Private - Only you and people who you choose can watch your video, Unlisted - Anyone with the video link can watch your video, Public - Everyone can watch your video'"
      [tooltipWindowClass]="'tooltip-chunky-big'"
      tooltipTitle="Youtube privacy settings"
    >
      <option
        [value]="'unlisted'"
      >Unlisted</option>
      <option
        [value]="'public'"
      >Public</option>
      <option
        [value]="'private'"
      >Private</option>
    </select>
    <i class="ssi ssi-arrow-down-new"></i>
  </div>
  <a
    class="post-info"
    *ngIf="checkTikTokAccountSelected()"
    [ssiTooltip]="'Please note TikTok videos will be sent to the account holders app for approval, text entered here will not be submitted'">
    <i class="ssi ssi-information"></i>
  </a>
  <a
    href="https://support.orlo.tech/blocking-words"
    target="_blank"
    *ngIf="post.blockingWordMatches.length > 0"
    class="btn btn-danger"
    [ssiTooltip]="'THE_POST_MATCHES_THE_FOLLOWING_PROFANITIES_AND_WILL_BE_SENT_FOR_VALIDATION__MATCHEDWORDS_' | translate:{matchedWords: post.blockingWordMatches.join(', ')}">
    <i class="fa fa-fw fa-exclamation-circle"></i>
  </a>
</ng-template>