import './cl-date-time-picker.component.scss';

import { Component, OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';

import { Account, AccountModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-cl-date-time-picker',
  templateUrl: './cl-date-time-picker.component.html',
  styles: []
})
export class ClDateTimePickerComponent implements OnInit {
  minDate: Date;
  dateOnly: Date;
  dateAndTime: string;

  constructor() {}

  ngOnInit() {}

  datesChanged($event) {
    console.log('dateChanged:', $event);
  }
}
