<div class="dashboard-widget dashboard-widget-rss-feed">

  <div class="form-field">
    <label class="field-title">POSTING ACCOUNT</label>
    <div class="input-wrapper">
      <ssi-dropdown-select-2 name="accounts"
                             class="accounts-selector"
                             size="lg"
                             [required]="true"
                             [multiple]="false"
                             [filterable]="true"
                             [filterPlaceholder]="'Search for an account...'"
                             [options]="accounts"
                             [(ngModel)]="selectedAccount"
                             (ngModelChange)="selectedAccountChanged($event)">
        <ng-template ssiTemplateSelector="headButtonLabel"
                     let-expanded="expanded">
          <div *ngIf="selectedAccount"
               class="select-accounts-label">
            <span>{{selectedAccount?.name}}</span>
            <ssi-social-network-icon *ngIf="selectedAccount?.account_type_id"
                                     [accountTypeId]="selectedAccount.account_type_id"
                                     variant="fa"></ssi-social-network-icon>
          </div>
          <div *ngIf="!selectedAccount"
               class="select-accounts-label">
            <span>Select an account to post out on...</span>
          </div>
        </ng-template>
        <ng-template ssiTemplateSelector="optionIcon"
                     let-account="option">
          <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                   variant="fa"></ssi-social-network-icon>
        </ng-template>
      </ssi-dropdown-select-2>
    </div>
  </div>

  <div class="message-info view-{{ viewClass }}"
       *ngIf="!selectedAccount">
    <i class="ssi ssi-select-microcopy"></i>
    <h5>Select an account</h5>
    <p class="view-{{ viewClass }}">This will be the account you can post content from</p>
  </div>

  <div *ngIf="selectedAccount && articles.length"
       class="article-grid view-{{ viewClass }}"
       infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="100"
       [infiniteScrollDisabled]="articles[articles.length - 1].url === viewPortArticles[viewPortArticles.length - 1]?.url"
       [scrollWindow]="false"
       (scrolled)="onScrolled()"
       ssiVirtualScroll
       [items]="articles"
       [bufferAmount]="5"
       [getItemHeight]="getHeight"
       (viewPortItemsUpdated)="viewPortItemsUpdated($event)">
    <ssi-rss-feed-article *ngFor="let article of viewPortArticles"
                          [article]="article"
                          [postToAccount]="selectedAccount"
                          [viewClass]="'view-' + viewClass"></ssi-rss-feed-article>
  </div>

</div>