<div class="engagement-summary-container">
  <div class="section-head">
    <h2 class="section-title">Engagement Summary</h2>
  </div>

  <div class="section-body">
    <div class="facts-row">
      <ssi-fact-overview [label]="'CLICKS' | translate"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.clicks"
                         [previousValue]="report.totalsStats.allTotals.previous?.clicks"
                         iconClass="ssi-select-microcopy"
                         iconColor="#F88C68"
                         circleColor="#FFF1ED"
                         ssiTooltip="This tells you the total number of times that ads in your account have been clicked during the reporting period"
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
      <ssi-fact-overview [label]="'COMMENTS' | translate"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.comments"
                         [previousValue]="report.totalsStats.allTotals.previous?.comments"
                         iconClass="ssi-commentscorrect"
                         iconColor="#B2C614"
                         circleColor="#b2c6141a"
                         ssiTooltip="This tells you the total number of comments that ads in your account have received during the reporting period"
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
      <ssi-fact-overview [label]="'SHARES' | translate"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.shares"
                         [previousValue]="report.totalsStats.allTotals.previous?.shares"
                         iconClass="ssi-shares"
                         iconColor="#D41D68"
                         circleColor="#d41d681a"
                         ssiTooltip="This tells you the total number of times that ads in your account have been shared during the reporting period."
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
      <ssi-fact-overview [label]="'REACTIONS' | translate"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.reactions"
                         [previousValue]="report.totalsStats.allTotals.previous?.reactions"
                         iconClass="ssi-reactions-correct"
                         iconColor="#FF87B8"
                         circleColor="#ff87b81a"
                         ssiTooltip="This tells you the total number of reactions that ads in your account have received during the reporting period. Reactions include Likes."
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
    </div>

    <div class="facts-row">
      <ssi-fact-overview [label]="'IMPRESSIONS' | translate"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.impressions"
                         [previousValue]="report.totalsStats.allTotals.previous?.impressions"
                         iconClass="ssi-impressions"
                         iconColor="#F0B427"
                         circleColor="#FBF3E2"
                         ssiTooltip="This tells you the number of times that ads in your account have been viewed during the reporting period."
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
      <ssi-fact-overview [label]="'REACH' | translate"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.reach"
                         [previousValue]="report.totalsStats.allTotals.previous?.reach"
                         iconClass="ssi-reach"
                         iconColor="#12ACA4"
                         circleColor="#EAF9F8"
                         ssiTooltip="This tells you the total number of unique people who have seen ads in your account during the reporting period."
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
      <ssi-fact-overview [label]="'Video Views'"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.video_views"
                         [previousValue]="report.totalsStats.allTotals.previous?.video_views"
                         iconClass="ssi-video-views"
                         iconColor="#DD0909"
                         circleColor="#dd09091a"
                         ssiTooltip="This tells you the total number of times that videos in your account have been viewed during the reporting period."
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
      <ssi-fact-overview [label]="'Engagements'"
                         [width]="'auto'"
                         [number]="report.totalsStats.allTotals.current.engagement"
                         [previousValue]="report.totalsStats.allTotals.previous?.engagement"
                         iconClass="ssi-engagements-ads"
                         iconSize="28px"
                         iconColor="#425DEC"
                         circleColor="#F3F6FD"
                         ssiTooltip="This tells you the total number of times people have engaged with ads in your account during the reporting period."
                         tooltipWindowClass="tooltip-chunky-big"></ssi-fact-overview>
    </div>
  </div>
</div>