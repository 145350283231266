import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatCardIconComponent } from './stat-card-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { BigNumberModule } from '../../components/big-number/big-number.module';
import { SecondsToHumanTimeModule } from '../../pipes/seconds-to-human-time/seconds-to-human-time.module';
import { TotalChangeModule } from '../total-change/total-change.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    BigNumberModule,
    TotalChangeModule,
    SecondsToHumanTimeModule
  ],
  declarations: [StatCardIconComponent],
  exports: [StatCardIconComponent]
})
export class StatCardIconModule {}
