import './crm-person-notes.component.scss';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague
} from '../../services/api';
import {
  CrmPerson,
  User,
  Outbox,
  Note,
  UserModel,
  NoteModel
} from '@ui-resources-angular';

@Component({
  selector: 'ssi-crm-person-notes',
  templateUrl: './crm-person-notes.component.html'
})
export class CrmPersonNotesComponent implements OnInit, OnChanges {
  @Input() crmPerson: CrmPerson;
  @Input() teams: Team[] = [];
  @Input() closable = false;

  @Output() onClose = new EventEmitter();

  post: Outbox | any;
  notes: any[];
  adNotes: Note[];
  colleagues: Colleague[];
  authUser: User;

  tagList: any[];

  content: string = '';
  tags: any;
  matchedMentions: any;

  constructor(
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService,
    private userModel: UserModel,
    private noteModel: NoteModel
  ) {}

  async ngOnInit() {
    this.authUser = await this.userModel.getAuthUser();
    this.teams = await this.teamsService.getAllActive();
    this.colleagues = await this.colleaguesService.getAllActive();
    this.notes = this.noteModel.getAll();

    this.tagList = [...this.teams, ...this.colleagues];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.crmPerson && this.crmPerson) {
      this.crmPerson.getNotes();
    }
  }

  close() {
    this.onClose.emit();
  }

  addNewNote() {
    this.crmPerson.addNote(this.content);
    this.content = '';
  }

  deleteNote(note: Note) {
    this.crmPerson.deleteNote(note);
  }
}
