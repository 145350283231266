import './loading-box.component.scss';

import { Component, OnInit } from '@angular/core';
import { TransitionService, Transition } from '@uirouter/angular';

const LOADING_DELAY_MS = 200;

@Component({
  selector: 'ssi-loading-box',
  templateUrl: './loading-box.component.html',
  styles: []
})
export class LoadingBoxComponent implements OnInit {
  stateIsLoading = false;

  constructor(protected transitions: TransitionService) {}

  ngOnInit() {
    this.transitions.onStart({}, (transition: Transition) => {
      if (transition.to().resolve) {
        let timer;
        timer = setTimeout(() => {
          this.stateIsLoading = true;
        }, LOADING_DELAY_MS); // If the request takes < LOADING_DELAY_MS then don't show the loading page

        transition.promise.finally(() => {
          if (timer) {
            clearTimeout(timer);
          }
          this.stateIsLoading = false;
        });
      }
    });
  }
}
