import './top-posts.component.scss';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Account, Outbox } from '@ui-resources-angular';
import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';
import {
  SortTypes,
  SortType,
  sortTypes
} from '../../../common/top-post-list/top-post-list.component';
import { AnalyticsService, DateRange } from '../../../analytics.service';

@Component({
  selector: 'ssi-top-posts',
  templateUrl: './top-posts.component.html',
  styles: []
})
export class TopPostsComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() dateRange: DateRange;
  @Input() includeDeletedInTopPosts: string;
  @Input() tagsToInclude: string[];
  @Input() tagsToExclude: string[];

  @Output() postsLoaded = new EventEmitter<Outbox[]>();

  postsToDisplay: Outbox[] = [];
  loading = false;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  sortTypes: SortTypes = sortTypes;
  selectedSortType: SortType = this.sortTypes.clicks;

  constructor(
    protected elementRef: ElementRef,
    protected translate: TranslateService,
    protected analyticsService: AnalyticsService
  ) {}

  async ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return this.accounts.some((a) => a.account_type_id === n.key);
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);
    this.selectedNetwork = this.selectableNetworks[0];

    const posts = await this.loadPosts(
      this.selectedNetwork,
      this.selectedSortType
    );
    if (posts) {
      this.postsToDisplay = posts;
      this.postsLoaded.emit(posts);
    }
  }

  async onNetworkSelect(nsi: NetworkSelectItem): Promise<void> {
    const posts = await this.loadPosts(nsi, this.selectedSortType);
    if (posts) {
      this.postsToDisplay = posts;
      this.selectedNetwork = nsi;
    }
  }

  async onSortTypeChange(sortType: SortType): Promise<void> {
    const posts = await this.loadPosts(this.selectedNetwork, sortType);
    if (posts) {
      this.postsToDisplay = posts;
      this.selectedSortType = sortType;
    }
  }

  async loadPosts(
    nsi: NetworkSelectItem,
    sortType: SortType
  ): Promise<Outbox[]> {
    this.loading = true;
    try {
      let filteredAccounts =
        nsi.key === 'all'
          ? this.accounts.slice()
          : this.accounts.filter((a) => a.account_type_id === nsi.key);

      if (sortType.key === sortTypes.reach.key) {
        filteredAccounts = filteredAccounts.filter(
          (a) => a.socialNetwork.statistics.reach.outbox
        );
      }

      if (!filteredAccounts.length) {
        // case when user e.g. selects 'by reach' and selects Twitter as social network
        // no posts should be shown as Twitter does not provide reach statistic
        // see CT-1656
        this.loading = false;
        return [];
      }

      const accountIds = filteredAccounts.map((a) => a.id);

      const posts = await this.analyticsService.loadTopPosts(
        this.dateRange,
        sortType.key,
        accountIds,
        undefined,
        undefined,
        { include_deleted: this.includeDeletedInTopPosts === 'true' },
        this.tagsToInclude,
        this.tagsToExclude
      );

      this.loading = false;
      return posts;
    } catch (e) {
      console.error('Error loading/mapping top posts: ', e);
      this.loading = false;
      return undefined;
    }
  }
}
