import { Component, OnInit } from '@angular/core';
import './finalise-step.component.scss';

import { StreamBuilderService, StreamQuery } from '../stream-builder.service';
import { LANGUAGES } from '../../../../../common/constants';
import {
  ListeningGroup,
  ListeningStreamService
} from '../../../../../common/services/api/listening-stream';
import { sourceTypeName } from '../../monitoring-streams/monitoring-streams.service';
import { CompanyService } from '../../../../../common/services/company/company.service';

@Component({
  selector: 'ssi-finalise-step',
  templateUrl: './finalise-step.component.html',
  styles: []
})
export class FinaliseStepComponent implements OnInit {
  streamQuery: StreamQuery;
  includeRetweets: boolean;
  languages: {
    code: string;
    label: string;
  }[];

  socialListeningEnabled = false;
  listeningGroupsIterable: ListeningGroup[];
  listeningGroups: {
    [key: string]: {
      id: string;
      label: string;
      icon: string;
      color: string;
      name: string;
      hideOption: boolean;
    };
  };
  selectedLanguage: { code: string; label: string };
  twitterSource: boolean;

  constructor(
    private streamBuilderService: StreamBuilderService,
    private listeningStreamService: ListeningStreamService,
    private company: CompanyService
  ) {}

  async ngOnInit() {
    this.streamQuery = this.streamBuilderService.fetchStreamQuery();
    this.includeRetweets = this.streamQuery['include-retweets'];
    this.twitterSource = this.streamQuery.sources.includes(
      sourceTypeName.Twitter
    );
    this.loadLanguages();

    this.performSocialListeningChecks();
  }

  async performSocialListeningChecks() {
    this.socialListeningEnabled = await this.company.hasFeatureAccess(
      'SOCIAL_LISTENING'
    );
    if (this.socialListeningEnabled) {
      this.loadListeningGroups();
    }
  }

  async loadLanguages() {
    this.languages = Object.entries(LANGUAGES).map((codeLabelPair) => {
      return {
        code: codeLabelPair[0],
        label: codeLabelPair[1]
      };
    });
    this.languages.unshift({ code: null, label: 'Any language' });
  }

  async loadListeningGroups() {
    this.listeningGroupsIterable = await this.listeningStreamService.listGroups();
    this.listeningGroups = this.listeningGroupsIterable
      .map((g) => {
        return {
          id: g.id,
          label: g.name,
          icon: 'fa fa-facebook',
          color: '#0866ff',
          name: g.id,
          hideOption: false
        };
      })
      .reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
  }

  onSelectedLanguageChange($event: { code: string; label: string }) {
    this.streamQuery.lang = $event.code;
  }

  onSelectedSourcesChange(sources: sourceTypeName[]) {
    this.streamQuery.sources = sources;
    this.twitterSource = sources.includes(sourceTypeName.Twitter);
  }

  onSelectedListeningGroupChange(groups: string[]) {
    this.streamQuery.listening_group_ids = groups;
  }

  onTwitterSourceToggle(toggled: boolean) {
    if (toggled) {
      this.streamQuery.sources.push(sourceTypeName.Twitter);
    } else {
      this.streamQuery.sources = this.streamQuery.sources.filter(
        (source) => source !== sourceTypeName.Twitter
      );
    }
  }

  deleteStream(streamQuery) {
    this.streamBuilderService.deleteSearchStream(this.streamQuery.id, true);
  }

  saveStep() {
    // remove Facebook just before API call since it's not accepted in `sources` array
    // `listening_group_ids` is used for that purpose
    const fbIndex = this.streamQuery.sources.findIndex(
      (s) => s === sourceTypeName.Facebook
    );
    if (fbIndex > -1) {
      this.streamQuery.sources.splice(fbIndex, 1);
    }
    this.streamQuery['include-retweets'] = this.includeRetweets;
    this.streamBuilderService.saveStreamQuery(this.streamQuery);
  }
}
