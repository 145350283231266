<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title"><i class="fa fa-user"></i> <span translate="USER_PERMISSIONS"></span></h3>
      <a class="btn btn-default" uiSref="auth.manageUsers">
        <i class="fa fa-cog"></i> <span translate="BACK_TO_USERS"></span>
      </a>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <label translate="FILTER_ACCOUNTS"></label>
      <input type="text" [(ngModel)]="searchText" class="form-control" placeholder="{{ 'ACCOUNT_NAME' | translate }}">
    </div>
  </div>

  <ssi-permissions
    [accounts]="userPermissions?.accounts"
    [permissions]="userPermissions?.permissions"
    [searchText]="searchText"
    (onTogglePermission)="togglePermission($event)">
  </ssi-permissions>
</div>
