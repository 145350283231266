import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { HighchartsHelperService } from '../../../../common/services/highcharts-helper/highcharts-helper.service';
import { ReportSpreadsheetTransformersService } from '../../../../common/services/report-spreadsheet-transformers/report-spreadsheet-transformers.service';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague
} from '../../../../common/services/api';
import {
  API,
  socialNetworkSettings,
  AccountModel
} from '@ui-resources-angular';
import { SecondsToHumanTimePipe } from '../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';
import {
  sentimentsIterable,
  SENTIMENT_CONFIG,
  TIMEZONE_OFFSET
} from '../../../../common/constants';
import { CompanyService } from '../../../../common/services/company/company.service';
import { LANGUAGES } from '../../../../common/constants';
import { visibilityOptions } from '../common/report-build-from/report-build-from.component';
import { isEmptyObj } from '../../../../common/util';
import { humanizeSeconds } from '../../../../common/utils';

function getSentiment(key): any {
  return Object.values(SENTIMENT_CONFIG).find(
    (sentiment: any) => sentiment.engagementReport.key === key
  );
}

@Injectable()
export class EngagementAnalyticsService {
  languages = LANGUAGES;

  constructor(
    private translate: TranslateService,
    private highchartsHelper: HighchartsHelperService,
    private reportSpreadsheetTransformers: ReportSpreadsheetTransformersService,
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService,
    private api: API,
    private secondsToHumanTimePipe: SecondsToHumanTimePipe,
    private accountModel: AccountModel,
    private company: CompanyService
  ) {}

  public createSpreadsheetExportFromReport(report, allColleagues) {
    const formattedReport = [
      () => {
        return {
          name: 'Overview',
          rows: [
            ['Engagement Analytics - Overview'],
            ['Report start', 'Report end'],
            [report.filter.range.start, report.filter.range.end],
            [],
            ...this.reportSpreadsheetTransformers.accountsList(
              report.filter.accounts
            ),
            [],
            [
              'Inbound messages',
              report.stats.activity.period.current.inbound.count
            ],
            [
              'Users engaged',
              report.stats.activity.period.current.inbound.users_engaged
            ],
            ['Replies', report.stats.activity.period.current.replies.count],
            [
              'Average reply time',
              report.stats.activity.period.current.inbound.average_response_time
                .total || 0
            ],
            [
              'Average conversation length',
              report.stats.activity.period.current.replies
                .average_conversation_length
            ],
            [
              'Avg first response time',
              (report.stats.conversation.period.current &&
                report.stats.conversation.period.current
                  .avg_first_response_time / 60) ||
                undefined
            ],
            [
              'Average Handling time',
              (report.stats.conversation.period.current &&
                report.stats.conversation.period.current.avg_handling_time /
                  60) ||
                undefined
            ],
            [
              'Resolution time',
              (report.stats.conversation.period.current &&
                (report.stats.conversation.period.current
                  .avg_first_response_time +
                  report.stats.conversation.period.current.avg_handling_time) /
                  60) ||
                undefined
            ],
            [
              'Total Conversations',
              report.stats.conversation.period.current &&
                report.stats.conversation.period.current.count_conversations
            ]
          ]
        };
      },
      () => {
        return {
          name: 'Activity',
          rows: [
            ['Engagement Analytics - Activity'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              {
                header: 'Messages In',
                data:
                  report.stats.activity.period.current.inbound.time_series
                    .values
              },
              {
                header: 'Messages Out',
                data:
                  report.stats.activity.period.current.replies.time_series
                    .values
              },
              {
                header: 'Unactioned messages',
                data:
                  report.stats.activity.period.current.inbound
                    .unactioned_time_series.values
              }
            ])
          ]
        };
      },
      () => {
        const dataRows = report.busyHoursChart.xAxis.categories.map(
          (category, x) => {
            return [
              category,
              ...report.busyHoursChart.yAxis.categories.map((dayOfWeek, y) => {
                const point = report.busyHoursChart.series[0].data.find(
                  (iPoint) => iPoint.x === x && iPoint.y === y
                );
                if (!point) {
                  return '0 (0%)';
                }
                return `${point.data.total} (${point.value}%)`;
              })
            ];
          }
        );
        return {
          name: 'Engagement times',
          rows: [
            ['Engagement Analytics - Engagement times'],
            [],
            ['', ...report.busyHoursChart.yAxis.categories],
            ...dataRows
          ]
        };
      },
      () => {
        return {
          name: 'Inbox Sentiment',
          rows: [
            ['Engagement Analytics - Inbox Sentiment'],
            [],
            ['Sentiment', 'Comments'],
            ...sentimentsIterable.map((sentiment) => {
              return [
                this.translate.instant(sentiment.label),
                report.stats.activity.period.current.inbound.sentiment[
                  sentiment.key
                ]
              ];
            })
          ]
        };
      },
      () => {
        const dataRows = report.busyHoursChart.xAxis.categories.map(
          (category, x) => {
            return [
              category,
              ...report.busyHoursChart.yAxis.categories.map((dayOfWeek, y) => {
                const point = report.busyHoursChart.series[0].data.find(
                  (iPoint) => iPoint.x === x && iPoint.y === y
                );
                if (!point) {
                  return 0;
                }
                return point.data.total;
              })
            ];
          }
        );
        return {
          name: 'Engagement times (Numbers)',
          rows: [
            ['Engagement Analytics - Engagement times'],
            [],
            ['', ...report.busyHoursChart.yAxis.categories],
            ...dataRows
          ]
        };
      },
      () => {
        const dataRows = report.busyHoursChart.xAxis.categories.map(
          (category, x) => {
            return [
              category,
              ...report.busyHoursChart.yAxis.categories.map((dayOfWeek, y) => {
                const point = report.busyHoursChart.series[0].data.find(
                  (iPoint) => iPoint.x === x && iPoint.y === y
                );
                if (!point) {
                  return '0%';
                }
                return `${point.value}%`;
              })
            ];
          }
        );
        return {
          name: 'Engagement times (Percentages)',
          rows: [
            ['Engagement Analytics - Engagement times'],
            [],
            ['', ...report.busyHoursChart.yAxis.categories],
            ...dataRows
          ]
        };
      },
      () => {
        return {
          name: 'Sources',
          rows: [
            ['Engagement Analytics - Sources'],
            [],
            ...this.reportSpreadsheetTransformers.pieChart(
              report.sourcesData.values
            )
          ]
        };
      },
      () => {
        return {
          name: 'Languages',
          rows: [
            ['Engagement Analytics - Languages'],
            [],
            ...this.reportSpreadsheetTransformers.pieChart(
              report.languageData.values
            )
          ]
        };
      },
      () => {
        return {
          name: 'Tags',
          rows: [
            ['Engagement Analytics - Tags'],
            [],
            ['Tag name', 'Total messages'],
            ...report.tags.map((tag) => [tag.value, tag.amount])
          ]
        };
      },
      () => {
        return {
          name: 'Sentiment by Tags',
          rows: [
            ['Engagement Analytics - Sentiment by Tags'],
            [],
            ['', '', '', 'Sentiment'],
            [
              'Tag name',
              'Negative',
              'Semi Negative',
              'Neutral',
              'Semi Positive',
              'Positive'
            ],
            ...Object.entries(
              report.stats.activity.period.current.tag_report
            ).map((tag: any) => [
              tag[0],
              tag[1].sentiment.negative,
              tag[1].sentiment.semi_negative,
              tag[1].sentiment.neutral,
              tag[1].sentiment.semi_positive,
              tag[1].sentiment.positive
            ])
          ]
        };
      },
      () => {
        const topUrls = (report && report.topUrls) || [
          { url: 'N/A', visits: 'N/A' }
        ];
        return {
          name: 'Top originating URLs',
          rows: [
            [
              'Engagement Analytics - Top URLs where Live Chat was originated most frequently'
            ],
            [],
            ['URL', 'Total visits'],
            ...topUrls.map((item) => [item.url, item.visits])
          ]
        };
      },
      () => {
        return {
          name: 'Team performance',
          rows: [
            ['Engagement Analytics - Team performance'],
            [],
            [
              'Team',
              'Total messages assigned',
              'Total messages actioned',
              'Total replies sent',
              'Avg first response time',
              'Avg response time (s)',
              'Avg handling time',
              report.stats.conversation.period.current
                ? 'Total conversations assigned'
                : '',
              'Avg response time (h, m, s)'
            ],
            ...report.performance.teams.map((team) => {
              let values = [
                team.team.name,
                team.assigned,
                team.actioned,
                team.replies,
                team.avgFirstResponseTime,
                team.avgResponseTime,
                team.avgHandlingTime
              ];
              if (report.stats.conversation.period.current) {
                values = values.concat([team.conversations.assigned || 0]);
              }
              const avgResponseTimeHuman = humanizeSeconds(
                team.avgResponseTime
              );

              values.push(avgResponseTimeHuman);
              return values;
            })
          ]
        };
      },
      () => {
        return {
          name: 'User performance',
          rows: [
            ['Engagement Analytics - User performance'],
            [],
            [
              'User',
              'Total messages assigned',
              'Total messages actioned',
              'Total messages published',
              'Total replies sent',
              'Avg first response time',
              'Avg response time (s)',
              'Avg handling time',
              report.stats.conversation.period.current
                ? 'Total conversations resolved'
                : '',
              report.stats.conversation.period.current
                ? 'Total conversations assigned'
                : '',
              'Avg response time (h, m, s)'
            ],
            ...report.performance.users.map((user) => {
              let values = [
                user.user.fullName,
                user.assigned,
                user.actioned,
                user.totalOutboxPosts,
                user.replies,
                user.avgFirstResponseTime,
                user.avgResponseTime,
                user.avgHandlingTime
              ];
              if (report.stats.conversation.period.current) {
                values = values.concat([
                  user.conversations.resolved || 0,
                  user.conversations.assigned || 0
                ]);
              }

              const avgResponseTimeHuman = humanizeSeconds(
                user.avgResponseTime
              );

              values.push(avgResponseTimeHuman);
              return values;
            })
          ]
        };
      },
      () => {
        return {
          name: 'Account performance',
          rows: [
            ['Engagement Analytics - Account performance'],
            [],
            ['Account', 'Avg response time (s)', 'Avg response time (h, m, s)'],
            ...Object.entries(
              report.stats.activity.period.current.inbound.average_response_time
                .by_account
            ).map(([key, val]: [string, number]) => {
              const formattedSeconds = humanizeSeconds(val);
              return [
                this.accountModel.get(key).name,
                val.toFixed(0),
                formattedSeconds
              ];
            })
          ]
        };
      }
    ];
    if (
      report.stats.activity.period.current &&
      report.stats.activity.period.current.inbound.response_time_sla &&
      !isEmptyObj(
        report.stats.activity.period.current.inbound.response_time_sla
      )
    ) {
      formattedReport.push(() => {
        const formatSlaLabel = (sla) =>
          this.secondsToHumanTimePipe.transform(sla, true, true, {
            secondLabel: 's',
            minuteLabel: 'm',
            dayLabel: 'd',
            hourLabel: 'h'
          });
        const colleaguesWithData = allColleagues.filter((colleague) =>
          Object.keys(
            report.stats.activity.period.current.inbound.response_time_sla
              .by_user
          ).includes(colleague.id)
        );
        return {
          name: 'Response Time SLAs',
          rows: [
            ['Engagement Analytics - Response Time SLAs'],
            [],
            ['SLA', 'Responses'],
            ...report.stats.activity.period.current.inbound.response_time_sla.top_level.map(
              (sla) => {
                const from = formatSlaLabel(sla.from || 0);
                const to = sla.to && formatSlaLabel(sla.to);
                return [from + (to ? ' - ' + to : '+'), sla.value];
              }
            ),
            [],
            [
              'Users',
              ...report.stats.activity.period.current.inbound.response_time_sla.top_level.map(
                (sla) => {
                  const from = formatSlaLabel(sla.from || 0);
                  const to = sla.to && formatSlaLabel(sla.to);
                  return from + (to ? ' - ' + to : '+');
                }
              )
            ],
            ...colleaguesWithData.map((colleague) => [
              colleague.fullName,
              ...report.stats.activity.period.current.inbound.response_time_sla.by_user[
                colleague.id
              ].map((sla) => sla.value)
            ]),
            []
          ]
        };
      });
    }
    return formattedReport.map((fn) => fn());
  }

  public async buildReport(
    report,
    { withPrevious = true, withConversations = true } = {}
  ) {
    const accountIds = report.filter.accounts.map((account) => account.id);
    const loadConversationStats =
      report.filter.tags.include.length === 0 &&
      report.filter.tags.exclude.length === 0 &&
      withConversations;
    const params = {
      account_ids: accountIds,
      start: report.filter.range.start,
      end: report.filter.range.end,
      tz_offset: TIMEZONE_OFFSET,
      tags: [
        ...report.filter.tags.include.map((tag) => tag),
        ...report.filter.tags.exclude.map((tag) => `-${tag}`)
      ],
      tags_join: 'or',
      visibilities:
        report.filter.visibility === 'all'
          ? ['PUBLIC', 'PRIVATE']
          : [report.filter.visibility.toUpperCase()],
      exclude_import_delay_from_response_time: report.filter.excludeImportDelay
    };
    const slas: number[] = await this.company
      .getPreferences()
      .then((preferences) => {
        const paredReport = JSON.parse(
          preferences.company_preferences.engagement_report_slas
        );
        return paredReport.map((sla) => sla.threshold);
      });
    const activityStats = await this.api.post('activity/aggregation', params);
    const teams: Team[] = await this.teamsService.getAllActive();
    const users: Colleague[] = await this.colleaguesService.getAllActive();
    let conversationEngagementStats;
    let conversationTeamStats;

    if (loadConversationStats) {
      const conversationQueryParams = {
        account_ids: accountIds,
        since: report.filter.range.start,
        until: report.filter.range.end,
        sla: slas,
        visibility:
          report.filter.visibility === visibilityOptions.all.key
            ? visibilityOptions.all.key
            : report.filter.visibility.toUpperCase()
      };
      conversationEngagementStats = await this.api.post(
        'conversation/conversationEngagementAggregation',
        conversationQueryParams
      );
      // if there are no conversations found the api returns an empty response, TODO: this should be a backend fix?
      conversationEngagementStats.data.by_account =
        conversationEngagementStats.data.by_account || [];
      conversationEngagementStats.data.sentiment =
        conversationEngagementStats.data.sentiment || [];
      conversationEngagementStats.data.sla =
        conversationEngagementStats.data.sla || [];

      conversationTeamStats = await this.api.post(
        'conversation/conversationTeamAggregation_v2',
        conversationQueryParams
      );
    }

    report.stats.activity.period.current = activityStats.data;
    report.stats.conversation.period.current = loadConversationStats
      ? conversationEngagementStats.data
      : undefined;
    report.stats.avgFirstResponseTimesData =
      conversationTeamStats && conversationTeamStats.data;

    report.socialAccounts = [];
    const colors = ['#425DEC', '#14BAE3', '#FB6340', '#F0B427', '#F40064'];
    report.responseTimesByAccountTypeChart = this.highchartsHelper.generateChart(
      {
        chart: {
          type: 'bar',
          height: 350
        },
        colors,
        series: [
          {
            data: [],
            dataLabels: {
              enabled: true
            },
            showInLegend: false
          }
        ],
        xAxis: {
          categories: [],
          labels: {
            style: {
              color: '#838EAB',
              fontSize: '10px'
            },
            enabled: true
          },
          name: '',
          tickLength: 10,
          tickWidth: 2,
          tickColor: '#E0E7F4',
          lineColor: '#E0E7F4'
        },
        yAxis: {
          gridLineWidth: 2,
          gridLineColor: '#E0E7F4',
          tickAmount: 8
        },
        tooltip: {
          enabled: false
        },
        exporting: {
          enabled: false
        }
      }
    );

    const secondsToHumanTimePipe = this.secondsToHumanTimePipe;
    report.responseTimesByAccountTypeChart.yAxis.labels = {
      style: {
        color: '#838EAB',
        fontSize: '10px'
      },
      y: 14,
      formatter() {
        return secondsToHumanTimePipe.transform(this.value, true, true, {
          secondLabel: 's',
          minuteLabel: 'm',
          dayLabel: 'd',
          hourLabel: 'h'
        });
      }
    };
    report.responseTimesByAccountTypeChart.plotOptions.bar = {
      colorByPoint: true,
      borderRadius: 5,
      dataLabels: {
        fontFamily: 'Lato, Arial, sans-serif',
        formatter() {
          return secondsToHumanTimePipe.transform(this.y, true);
        }
      }
    };

    Object.keys(socialNetworkSettings).forEach((key) => {
      const socialNetwork = socialNetworkSettings[key];
      socialNetwork['account_type_name'] = key;
      const accounts = report.filter.accounts.filter(
        (account) => account.account_type_name === key
      );
      if (accounts.length > 0) {
        const networkStats = {
          socialNetwork,
          totals: {
            activity:
              report.stats.activity.period.current.inbound.source
                .by_account_type[socialNetwork.publishKey],
            conversation: 0
          },
          accounts
        };

        if (loadConversationStats) {
          networkStats.totals.conversation = report.stats.conversation.period.current.by_account
            .filter(
              (totals) =>
                this.accountModel.get(totals.account_id).socialNetwork ===
                socialNetwork
            )
            .reduce(
              (runningTotal, accountStats) =>
                runningTotal + accountStats.count_conversations,
              0
            );
        }

        report.socialAccounts.push(networkStats);
      }
      const responseTime =
        report.stats.activity.period.current.inbound.average_response_time
          .by_account_type[socialNetwork.publishKey];
      if (responseTime) {
        report.responseTimesByAccountTypeChart.series[0].data.push({
          y: responseTime,
          name: key
          // color: socialNetwork.brand.color
        });
        report.responseTimesByAccountTypeChart.xAxis.categories.push(key);
      }
    });

    report.performance = {
      teams: teams.map((team) => {
        return {
          team,
          assigned:
            report.stats.activity.period.current.assigned_groups[team.id] || 0,
          actioned:
            report.stats.activity.period.current.inbound.actioned_by.by_group[
              team.id
            ] || 0,
          replies:
            report.stats.activity.period.current.replies.by_team[team.id] || 0,
          avgFirstResponseTime:
            (report.stats.avgFirstResponseTimesData &&
              report.stats.avgFirstResponseTimesData
                .average_first_response_time_by_group[team.id]) ||
            0,
          avgResponseTime:
            report.stats.activity.period.current.inbound.average_response_time
              .by_group[team.id] || 0,
          avgHandlingTime:
            (report.stats.avgFirstResponseTimesData &&
              report.stats.avgFirstResponseTimesData.handling_time_by_group[
                team.id
              ]) ||
            0,
          conversations: {
            assigned: 0
          }
        };
      }),
      users: users.map((user) => {
        return {
          user,
          assigned:
            report.stats.activity.period.current.assigned_users[user.id] || 0,
          actioned:
            report.stats.activity.period.current.inbound.actioned_by.by_user[
              user.id
            ] || 0,
          replies:
            report.stats.activity.period.current.replies.by_user[user.id] || 0,
          avgFirstResponseTime:
            (report.stats.avgFirstResponseTimesData &&
              report.stats.avgFirstResponseTimesData
                .average_first_response_time_by_user[user.id]) ||
            0,
          avgResponseTime:
            report.stats.activity.period.current.inbound.average_response_time
              .by_user[user.id] || 0,
          avgHandlingTime:
            (report.stats.avgFirstResponseTimesData &&
              report.stats.avgFirstResponseTimesData.handling_time_by_user[
                user.id
              ]) ||
            0,
          conversations: {
            resolved: 0,
            assigned: 0
          },
          totalOutboxPosts: activityStats.data.outbound.by_user[user.id] || 0
        };
      })
    };

    report.sourcesData = {
      highestValue: 0,
      values: []
    };
    report.socialAccounts.forEach((account) => {
      report.sourcesData.values.push({
        name:
          account.socialNetwork.accountTypeGroupName ||
          account.socialNetwork.accountTypeLabel,
        color: account.socialNetwork.brand.color,
        y:
          activityStats.data.inbound.source.by_account_type[
            account.socialNetwork.publishKey
          ] || 0
      });
    });
    report.sourcesData.highestValue = Math.max(
      ...report.sourcesData.values.map((source) => source.y)
    );

    const colours = [
      '#3CC5D8',
      '#D41D68',
      '#F0B427',
      '#89A4EA',
      '#425DEC',
      '#14BAE3',
      '#F40064',
      '#FB6340'
    ];
    report.languageData = {
      values: [],
      chart: {}
    };
    if (
      activityStats.data.inbound.language.en &&
      activityStats.data.inbound.language['en-gb']
    ) {
      activityStats.data.inbound.language.en +=
        activityStats.data.inbound.language['en-gb'];
      delete activityStats.data.inbound.language['en-gb'];
    }
    Object.keys(activityStats.data.inbound.language).forEach(
      (langKey, index) => {
        report.languageData.values.push({
          name: this.languages[langKey],
          y: activityStats.data.inbound.language[langKey],
          color: colours[index]
        });
      }
    );

    if (report.languageData.values.find((lang) => lang.name === undefined)) {
      const firstUnknownLanguageColor = report.languageData.values.find(
        (lang) => lang.name === undefined
      ).color;
      const unknownLanguageValue = report.languageData.values.reduce(
        (acc, val) => {
          return acc + (val.name ? 0 : val.y);
        },
        0
      );
      const validLanguageValues = report.languageData.values.filter(
        (lang) => lang.name !== undefined
      );
      validLanguageValues.push({
        name: 'Unknown',
        y: unknownLanguageValue,
        color: firstUnknownLanguageColor
      });
      report.languageData.values = validLanguageValues;
    }

    report.languageData.chart = this.highchartsHelper.generateChart({
      chart: {
        type: 'pie',
        height: 235,
        width: 235,
        backgroundColor: null
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: 'Languages',
          data: report.languageData.values,
          innerSize: '20%'
        }
      ],
      exporting: {
        enabled: false
      }
    });

    report.tags = Object.keys(report.stats.activity.period.current.tags).map(
      (tag) => {
        return {
          value: tag,
          amount: report.stats.activity.period.current.tags[tag]
        };
      }
    );

    const hasLiveChatUrls =
      !!report.stats.activity.period.current.top_livechat_origins &&
      !!Object.keys(report.stats.activity.period.current.top_livechat_origins)
        .length;

    if (hasLiveChatUrls) {
      report.topUrls = Object.keys(
        report.stats.activity.period.current.top_livechat_origins
      ).map((url) => {
        return {
          url,
          visits: report.stats.activity.period.current.top_livechat_origins[url]
        };
      });
    }

    report.messagesChart = this.highchartsHelper.generateChart({
      chart: {
        type: 'areaspline',
        height: 398, // 315 + legend height
        zoomType: 'x'
      },
      legend: {
        useHTML: true,
        itemStyle: {
          color: '#43537F',
          textTransform: 'uppercase',
          fontSize: '10px',
          letterSpacing: '1px'
        },
        margin: 60
      },
      series: [
        {
          data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
            activityStats.data.inbound.time_series,
            report.filter.range
          ),
          name: 'Inbound messages',
          color: '#425DEC',
          fillOpacity: 0.55
        },
        {
          data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
            activityStats.data.replies.time_series,
            report.filter.range
          ),
          name: 'Replies',
          color: '#14BAE3',
          fillOpacity: 0.55
        },
        {
          data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
            activityStats.data.inbound.unactioned_time_series,
            report.filter.range
          ),
          name: 'Unactioned',
          color: '#AEBDE3',
          fillOpacity: 0.55
        }
      ],
      yAxis: {
        title: {
          text: 'messages',
          style: {
            color: '#838EAB',
            fontSize: '10px'
          },
          x: -30
        },
        tickAmount: 8,
        labels: {
          style: {
            color: '#838EAB',
            fontSize: '10px'
          }
        },
        gridLineWidth: 40,
        gridLineColor: '#F4F4FA'
      },
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: '#838EAB',
            fontSize: '10px'
          }
        },
        min: moment.utc(report.filter.range.start).valueOf(),
        tickLength: 0,
        maxPadding: 0,
        endOnTick: false,
        startOnTick: false
      },
      exporting: {
        buttons: {
          contextButton: {
            height: 34,
            width: 16,
            symbolStroke: 'transparent',
            x: 48,
            y: -58
          }
        }
      },
      time: {
        useUTC: false
      }
    });

    report.stats.activity.period.current.inbound[
      'unactioned_count'
    ] = Object.values(
      activityStats.data.inbound.unactioned_time_series.values
    ).pop(); // take last value as total count, see CT-1492

    report.messagesChart.series[0].zIndex =
      '-' + activityStats.data.inbound.count;
    report.messagesChart.series[1].zIndex =
      '-' + activityStats.data.replies.count;
    report.messagesChart.series[2].zIndex =
      '-' +
      report.messagesChart.series[2].data
        .map((value) => value[1])
        .reduce((acc: number, val: number) => acc + val);

    let maxCount = 0;
    Object.keys(activityStats.data.inbound.busy_hours).forEach((day) => {
      Object.keys(activityStats.data.inbound.busy_hours[day]).forEach(
        (hour) => {
          maxCount = Math.max(
            maxCount,
            activityStats.data.inbound.busy_hours[day][hour]
          );
        }
      );
    });

    function iterateData(weekData, action) {
      Object.values(weekData).forEach((dayData) => {
        Object.entries(dayData).forEach(([hourKey, hourData]) => {
          dayData[hourKey] = action(hourData);
        });
      });
    }

    function aggregateHours(weekData) {
      const hours = {};
      const keys = ['total'];
      Object.values(weekData).forEach((dayData) => {
        Object.entries(dayData).forEach(([hourKey, hourData]) => {
          hours[hourKey] = hours[hourKey] || {};
          keys.forEach((key) => {
            hours[hourKey][key] = hours[hourKey][key] || 0;
            hours[hourKey][key] += hourData;
          });
        });
      });
      return hours;
    }

    function calculateScore(weekData) {
      const clone = JSON.parse(JSON.stringify(weekData));

      const keys = ['total'];
      const sums = {};
      iterateData(clone, (hourData) => {
        keys.forEach((key) => {
          sums[key] = sums[key] || 0;
          sums[key] += hourData[key];
        });
        return hourData;
      });

      const scores = [];

      iterateData(clone, (hourData) => {
        hourData.score = 0;
        keys.forEach((key) => {
          hourData.score +=
            (sums[key] > 0 ? hourData[key] / sums[key] : 0) * 10;
        });
        scores.push(hourData.score);
        return hourData;
      });

      const maxScore = Math.max(...scores);

      iterateData(clone, (hourData) => {
        const normalisedScore =
          (maxScore > 0 ? hourData.score / maxScore : 0) * 100;
        delete hourData.score;
        return {
          value: normalisedScore,
          data: {
            totals: hourData
          }
        };
      });

      return clone;
    }

    const busyHoursNormalised = {};
    Object.keys(activityStats.data.inbound.busy_hours).forEach((day) => {
      busyHoursNormalised[day] = {};
      Object.keys(activityStats.data.inbound.busy_hours[day]).forEach(
        (hour) => {
          busyHoursNormalised[day][hour] = {
            value:
              (activityStats.data.inbound.busy_hours[day][hour] / maxCount) *
              100,
            data: {
              total: activityStats.data.inbound.busy_hours[day][hour]
            }
          };
        }
      );
    });

    report.showEngagementTimesChart =
      Object.keys(activityStats.data.inbound.busy_hours).length > 0;

    const getAggregatedScores = (data) =>
      calculateScore({
        all: aggregateHours(data)
      }).all;
    const getTopHours = (aggregatedScores) => {
      const topHours = Object.entries(aggregatedScores).map(
        ([hour, { value }]: [string, { value: number }]) => {
          return {
            label: moment()
              .hours(+hour)
              .format('ha'),
            percent: value,
            hour: +hour
          };
        }
      );
      return topHours.sort((a, b) => b.percent - a.percent);
    };

    report.busyHoursNormalised = {
      chartData: busyHoursNormalised,
      topHours: getTopHours(
        getAggregatedScores(activityStats.data.inbound.busy_hours)
      )
    };
    report.busyHoursChart = this.highchartsHelper.generateWeekSummaryHeatmapChart(
      {
        chartData: busyHoursNormalised,
        tooltipFormatter() {
          if (this.point.value === 100) {
            return `
        At <b>${this.series.xAxis.categories[this.point.x]}</b> on
        <b>${
          this.series.yAxis.categories[this.point.y]
        }</b> this is your Peak Time (100%)
          with <b>${this.point.data.total}</b> inbound messages
      `;
          } else {
            return `
        At <b>${this.series.xAxis.categories[this.point.x]}</b> on <b>${
              this.series.yAxis.categories[this.point.y]
            }</b>,
        <b>${this.point.data.total}</b> incoming messages rate at <b>${
              this.point.value
            }</b>% of the peak time
      `;
          }
        }
      }
    );

    const avgResponseTimesData = {};
    Object.entries(
      activityStats.data.inbound.avg_response_time_by_dow_hod
    ).forEach(([day, hours]) => {
      avgResponseTimesData[day] = {};
      Object.entries(hours).forEach(([hour, value]) => {
        avgResponseTimesData[day][hour] = { value };
      });
    });

    report.avgResponseTimesData = {
      chartData: avgResponseTimesData,
      topHours: getTopHours(
        getAggregatedScores(
          activityStats.data.inbound.avg_response_time_by_dow_hod
        )
      )
    };
    report.responseTimesHeatmapChart = this.highchartsHelper.generateWeekSummaryHeatmapChart(
      {
        chartData: avgResponseTimesData,
        tooltipFormatter() {
          return `
        At <b>${this.series.xAxis.categories[this.point.x]}</b> on <b>${
            this.series.yAxis.categories[this.point.y]
          }</b>,
        the average response time is ${this.secondsToHumanTimePipe.transform(
          this.point.value,
          true
        )}
      `;
        }
      }
    );

    report.responseTimesHeatmapChart.plotOptions.heatmap = {
      dataLabels: {
        formatter() {
          return this.secondsToHumanTimePipe
            .transform(this.point.value, false, true)
            .replace(' ', '<br>');
        }
      }
    };

    report.conversationSentimentChangeChart = undefined;
    if (loadConversationStats) {
      const nodes = [];
      const series = [
        {
          keys: ['from', 'to', 'weight'],
          data: report.stats.conversation.period.current.sentiment.map(
            (row) => {
              const fromSentiment = getSentiment(row.from);
              const toSentiment = getSentiment(row.to);
              const fromLabel = `Starting sentiment: ${this.translate.instant(
                fromSentiment.translateKey
              )}`;
              const toLabel = `End sentiment: ${this.translate.instant(
                toSentiment.translateKey
              )}`;
              nodes.push(
                {
                  id: fromLabel,
                  color: fromSentiment.color
                },
                {
                  id: toLabel,
                  color: toSentiment.color
                }
              );

              return [fromLabel, toLabel, row.value];
            }
          ),
          name: 'Sentiment change',
          nodes
        }
      ];
      report.conversationSentimentChangeChart = this.highchartsHelper.generateChart(
        {
          chart: {
            type: 'sankey',
            height: 370
          },
          series,
          exporting: {
            enabled: false
          },
          lang: {
            noData: ''
          }
        }
      );

      report.performance.users.map((user) => {
        user.conversations.resolved =
          conversationTeamStats.data.resolved_by_user[+user.user.id] || 0;
      });

      report.performance.users.map((user) => {
        user.conversations.assigned =
          conversationTeamStats.data.assigned_to_user[+user.user.id] || 0;
      });

      report.performance.teams.map((team) => {
        team.conversations.assigned =
          conversationTeamStats.data.assigned_to_group[+team.team.id] || 0;
      });
    }

    const topAuthorPostTotal = activityStats.data.inbound.top_authors.reduce(
      (runningtotal, author) => runningtotal + author.count,
      0
    );
    report.topUserInboundPercentage =
      (topAuthorPostTotal / activityStats.data.inbound.count) * 100;

    if (
      report.filter.range.compareTo.start &&
      report.filter.range.compareTo.end
    ) {
      params.start = report.filter.range.compareTo.start;
      params.end = report.filter.range.compareTo.end;
    } else {
      const diff = moment(report.filter.range.end).diff(
        moment(report.filter.range.start),
        'seconds'
      );
      params.start = moment(report.filter.range.start)
        .subtract(diff, 'seconds')
        .toDate();
      params.end = moment(report.filter.range.end)
        .subtract(diff, 'seconds')
        .toDate();
    }

    if (withPrevious) {
      const previousPeriodActivityStats = await this.api.post(
        'activity/aggregation',
        params
      );
      let previousPeriodConversationStats;
      if (loadConversationStats) {
        const previousPeriodConversationStatsParams = {
          account_ids: accountIds,
          since: params.start,
          until: params.end,
          sla: slas,
          visibility:
            report.filter.visibility === visibilityOptions.all.key
              ? visibilityOptions.all.key
              : report.filter.visibility.toUpperCase()
        };

        previousPeriodConversationStats = await this.api.post(
          'conversation/conversationEngagementAggregation',
          previousPeriodConversationStatsParams
        );
        // if there are no conversations found the api returns an empty response, TODO: this should be a backend fix?
        previousPeriodConversationStats.data.by_account =
          previousPeriodConversationStats.data.by_account || [];
        previousPeriodConversationStats.data.sentiment =
          previousPeriodConversationStats.data.sentiment || [];
        previousPeriodConversationStats.data.sla =
          previousPeriodConversationStats.data.sla || [];
      }

      report.stats.activity.period.previous = previousPeriodActivityStats.data;
      if (loadConversationStats) {
        report.stats.conversation.period.previous =
          previousPeriodConversationStats.data;
      }
      report.stats.activity.period.previous.inbound[
        'unactioned_count'
      ] = Object.values(
        previousPeriodActivityStats.data.inbound.unactioned_time_series.values
      ).pop(); // take last value as total count, see CT-1492
    }
  }
}
