import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostComponent } from './post.component';
import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { EnlargeImageModule } from '../../directives/enlarge-image/enlarge-image.module';
import { ReverseModule } from '../../../common/pipes/reverse/reverse.module';
import { TriggerPublishModule } from '../../../common/directives/trigger-publish/trigger-publish.module';
import { LinkifyModule } from '../../../common/pipes/linkify/linkify.module';
import { UIRouterModule } from '@uirouter/angular';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule,
    EnlargeImageModule,
    ReverseModule,
    TriggerPublishModule,
    LinkifyModule,
    UIRouterModule
  ],
  declarations: [PostComponent],
  exports: [PostComponent]
})
export class PostModule {}
