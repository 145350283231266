import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ShareFirstCommentComponent } from './share-first-comment.component';

import { ReplyBoxModule } from '../reply-box/reply-box.module';
import { SocialNetworkIconModule } from '../social-network-icon/social-network-icon.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { LinkifyModule } from '../../pipes/linkify/linkify.module';
import { ReverseModule } from '../../pipes/reverse/reverse.module';
import { MenuModule } from '../menu/menu.module';
import { ContextMenuModule } from '../context-menu/context-menu.module';
import { ConfirmActionModule } from '../../directives/tooltip/confirm-action/confirm-action.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TruncateModule } from '../../pipes/truncate/truncate.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReplyBoxModule,
    SocialNetworkIconModule,
    FallbackImageModule,
    LinkifyModule,
    ReverseModule,
    ReplyBoxModule,
    MenuModule,
    ContextMenuModule,
    ConfirmActionModule,
    TooltipModule,
    TruncateModule
  ],
  exports: [ShareFirstCommentComponent],
  declarations: [ShareFirstCommentComponent],
  entryComponents: [ShareFirstCommentComponent]
})
export class ShareFirstCommentModule {}
