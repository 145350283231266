import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountPillComponent } from './account-pill.component';

@NgModule({
  declarations: [AccountPillComponent],
  imports: [CommonModule],
  exports: [AccountPillComponent]
})
export class AccountPillModule {}
