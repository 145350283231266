<div class="top-urls-section">
  <div class="section-tu-wrap">
    <ul class="tu-list tu-list-head">
      <li class="tu-list-item">
        <p>URL</p>
        <p>{{countLabel}}</p>
      </li>
      <li class="tu-list-item">
        <p>URL</p>
        <p>{{countLabel}}</p>
      </li>
    </ul>
    <ul
      class="tu-list tu-list-body"
      [style.height.px]="topURLs.length % 2 ? 68 * ((topURLs.length + 1) / 2) : 68 * (topURLs.length / 2)"
    >
      <li *ngFor="let url of topURLs; let i = index" class="tu-list-item tu-list-body-item">
        <p>{{i + 1}}.</p>
        <p title="{{url.url}}">{{url.url}}</p>
        <p>{{url.visits}}</p>
      </li>
    </ul>
  </div>
</div>