import './tags-table.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import {
  SecondsToHumanTimeLabels,
  SecondsToHumanTimePipe
} from '../../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';

@Component({
  selector: 'ssi-tags-table',
  templateUrl: './tags-table.component.html',
  styles: []
})
export class TagsTableComponent implements OnInit {
  @Input() tagData;

  dataToDisplay: any[] = [];
  timeLabels: SecondsToHumanTimeLabels = {
    secondLabel: 's',
    minuteLabel: 'm',
    hourLabel: 'h',
    dayLabel: 'd'
  };

  constructor(private secondsToHumanTimePipe: SecondsToHumanTimePipe) {}

  ngOnInit() {
    this.dataToDisplay = this._getTableConfig(this.tagData);
  }

  private _getTableConfig(data) {
    const tableConfig = Object.keys(data).map((tag) => {
      Object.keys(data[tag].sentiment).forEach((sentimentKey) => {
        data[tag].sentiment[sentimentKey] = `${Math.round(
          (data[tag].sentiment[sentimentKey] * 100) / data[tag].count
        )}%`;
      });
      data[tag].avg_response_time = this._secondsToHumanTime(
        data[tag].avg_response_time
      );
      data[tag].sum_response_time = this._secondsToHumanTime(
        data[tag].sum_response_time
      );
      return {
        tag: { name: tag },
        current: Object.assign({}, data[tag])
      };
    });
    return tableConfig;
  }

  private _secondsToHumanTime(seconds: number): string {
    return this.secondsToHumanTimePipe.transform(
      seconds,
      true,
      false,
      this.timeLabels
    );
  }
}
