import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { InsightsNotPurchasedComponent } from './insights-not-purchased.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights.notPurchased',
          url: '/not-purchased',
          component: InsightsNotPurchasedComponent
        }
      ]
    })
  ],
  declarations: [InsightsNotPurchasedComponent],
  exports: [InsightsNotPurchasedComponent]
})
export class InsightsNotPurchasedModule {}
