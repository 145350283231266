import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ssiVar]'
})
export class VarDirective {
  @Input() set ssiVar(ctx: any) {
    this.ctx.$implicit = this.ctx.ssiVar = ctx;
    this.updateView();
  }

  ctx: any = {};

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) {}

  updateView() {
    this._viewContainerRef.clear();
    this._viewContainerRef.createEmbeddedView(this._templateRef, this.ctx);
  }
}
