import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { OrloComponentsModule } from 'orlo-components';
import { TwitterThreadsComponent } from './twitter-threads.component';
import { TriggerPublishModule } from '../../../../common/directives/trigger-publish/trigger-publish.module';
import { OutboxMessageModule } from '../../../../common/components/outbox-message/outbox-message.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.twitterThreads',
          url: '/twitter-thread?outboxId',
          component: TwitterThreadsComponent,
          resolve: TwitterThreadsComponent.resolve,
          params: {
            posts: {
              array: true
            }
          }
        }
      ]
    }),
    OrloComponentsModule,
    TriggerPublishModule,
    OutboxMessageModule
  ],
  declarations: [TwitterThreadsComponent],
  entryComponents: [TwitterThreadsComponent],
  providers: [{ provide: 'Window', useValue: window }]
})
export class TwitterThreadsModule {}
