import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StateService } from '@uirouter/angular';
import moment from 'moment';

import {
  AccountModel,
  Account,
  Outbox,
  OutboxModel
} from '@ui-resources-angular';
import { ApiService } from '../../../common/services/api';
import { HighchartsHelperService } from '../../../common/services/highcharts-helper/highcharts-helper.service';
import { groupBy } from '../../../common/utils';
import { CountryFinderService } from '../../../common/services/country-finder/country-finder.service';
import { AccountTypeId } from '../../../common/enums';

export enum LinkClickGroupByParams {
  datetime = 'datetime',
  // geohash = 'geohash',
  countryCity = 'country,city',
  outbox = 'outbox',
  accountType = 'account_type'
}
export interface LinkClicksReqParams {
  since: string; // '2018-01-01T00:00:00+00:00'
  until: string;
  group_by: LinkClickGroupByParams[];
  account_ids?: string[];
  account_types?: AccountTypeId[];
  campaign_ids?: string[];
  excludePostsOutsideRange?: boolean;
  tags?: any;
  include_tags?: string[];
  exclude_tags?: string[];
}
export interface LinkClicksData {
  locations: { count: number; country: any; byCity: any }[];
  mapMarkers: { lat: number; lng: number }[];
  totalClicks: number;
  clicksByLocation: { city: string; count: number; country: any }[];
  /**
   * Raw response from the API
   */
  response: LinkClicksResponse;
}

export interface LinkClicksResponse {
  by_account_type: {
    [key: number]: { count: number };
  };
  /**
   * `GB`, `US`, `CA`, `ID`, etc as a key...
   */
  by_country: {
    [key: string]: {
      by_city: { [key: string]: { count: number } };
      count: number;
    };
  };
  by_datetime: {
    [key: string]: { count: number };
  };
  by_outbox: {
    [key: number]: { count: number };
  };
}

export interface VideoMetricStatsResponse {
  video_views: number;
  average_view_time: number;
  impressions: number;
  reach: number;
  link_clicks: number;
  likes: number;
  comments: number;
  shares: number;
}

export interface InstagramStoryStatsResponse {
  count_stories: number;
  sum_likes: number;
  sum_impressions: number;
  sum_reach: number;
  sum_taps_forward: number;
  sum_taps_back: number;
  sum_exits: number;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export interface DateRanges {
  current: DateRange;
  previous: DateRange;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  API_DATE_FORMAT = 'YYYY-MM-DD';

  constructor(
    protected injector: Injector,
    protected api: ApiService,
    protected accountModel: AccountModel,
    protected highchartsHelper: HighchartsHelperService,
    protected countryFinder: CountryFinderService,
    protected state: StateService
  ) {}

  /**
   * https://github.com/orlo/orlo/blob/master/outbox/top-posts-by.md
   */
  loadTopPosts(
    dateRange: DateRange,
    by:
      | 'clicks'
      | 'comments'
      | 'likes'
      | 'shares'
      | 'reach'
      | 'impressions' = 'clicks',
    accountIds?: string[],
    accountTypeIds?: number[],
    campaignIds?: string[],
    params?,
    include_tags?: string[],
    exclude_tags?: string[]
  ): Promise<Outbox[]> {
    const endpoint = `${this.api.url}/outbox/topPostsBy`;

    return this.api
      .post(endpoint, {
        start: dateRange.start,
        end: dateRange.end,
        sort: by,
        account_ids: accountIds,
        account_type_ids: accountTypeIds,
        campaign_ids: campaignIds,
        ...params,
        include_tags,
        exclude_tags
      })
      .pipe(
        map((response: Outbox[]) => {
          response = this.injector.get(OutboxModel).inject(response);
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  /**
   * Get the link clicks data, API doc: https://github.com/orlo/orlo/blob/master/marketing/linkclicks.md
   */
  getLinkClicksData(
    filterPeriod: { start: string; end: string },
    campaign_ids?: string[],
    account_types?: AccountTypeId[],
    account_ids?: string[],
    excludePostsOutsideRange?: boolean,
    include_tags?: string[],
    exclude_tags?: string[]
  ): Promise<LinkClicksData> {
    const endpoint = `${this.api.url}/stats/eslinkclicks`;

    const reqParams: LinkClicksReqParams = {
      account_ids,
      account_types,
      campaign_ids,
      since: filterPeriod.start,
      until: filterPeriod.end,
      group_by: Object.values(LinkClickGroupByParams),
      excludePostsOutsideRange,
      include_tags,
      exclude_tags
    };

    return this.api
      .post(endpoint, reqParams)
      .pipe(
        map((response: LinkClicksResponse) => {
          const linkClicksData: LinkClicksData = {
            locations: [],
            mapMarkers: [],
            totalClicks: 0,
            clicksByLocation: [],
            response
          };

          const colours = [
            '#D41D68',
            '#B2C614',
            '#425DEC',
            '#F0B427',
            '#89A4EA',
            '#F88C68'
          ];

          linkClicksData.locations = Object.entries(response.by_country).map(
            ([countryCode, countAndCity]) => {
              return {
                count: countAndCity.count,
                byCity: countAndCity.by_city,
                country: this.countryFinder.getCountryFromCode(countryCode)
              };
            }
          );

          const repeatedColours = Array(linkClicksData.locations.length)
            .fill(colours)
            .reduce((acc, val) => acc.concat(val), []);
          linkClicksData.locations.map(
            (loc, index) => (loc['colour'] = repeatedColours[index])
          );

          linkClicksData.totalClicks = Object.values(response.by_account_type)
            .map(({ count }) => count)
            .reduce((n, count) => n + count, 0);

          return linkClicksData;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  getInstagramStoryMetrics(
    account_ids: string[],
    campaign_ids: string[],
    dateRange: DateRange,
    include_tags?: string[],
    exclude_tags?: string[]
  ): Promise<InstagramStoryStatsResponse> {
    const endpoint = `${this.api.url}/stats/instagramStoryMetrics`;

    const reqParams = {
      start_date: dateRange.start,
      end_date: dateRange.end,
      account_ids,
      campaign_ids,
      include_tags,
      exclude_tags
    };

    return this.api
      .post(endpoint, reqParams)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          catchError((e) => this.api.mapError(e, endpoint))
        )
      )
      .toPromise();
  }

  getVideoMetrics(
    account_ids: string[],
    campaign_ids: string[],
    dateRange: DateRange,
    include_tags?: string[],
    exclude_tags?: string[]
  ): Promise<VideoMetricStatsResponse> {
    const endpoint = `${this.api.url}/stats/videoMetrics`;

    const reqParams = {
      start_date: dateRange.start,
      end_date: dateRange.end,
      account_ids,
      campaign_ids,
      include_tags,
      exclude_tags
    };

    return this.api
      .post(endpoint, reqParams)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          catchError((e) => this.api.mapError(e, endpoint))
        )
      )
      .toPromise();
  }

  updateUrlParams(newParams: { [key: string]: string | number | Date }) {
    Object.assign(this.state.params, newParams);
    this.state.go(this.state.current.name, newParams, { notify: false });
  }
}
