import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { IconModule } from '../../../../../../common/components/icon/icon.module';
import { ReverseModule } from '../../../../../../common/pipes/reverse/reverse.module';
import { WidgetBigComponent } from './widget-big.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownSelect2Module } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { LoaderModule } from '../../../../../../common/directives';

@NgModule({
  declarations: [WidgetBigComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    TooltipModule,
    IconModule,
    ReverseModule,
    TranslateModule,
    FormsModule,
    TooltipModule,
    OverlayModule,
    DropdownSelect2Module,
    LoaderModule
  ],
  providers: [],
  exports: [WidgetBigComponent]
})
export class WidgetBigModule {}
