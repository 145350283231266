<div class="ifttt-applet-collection-list container-fluid container-app-page bg-light">

  <ssi-alerts></ssi-alerts>

  <div class="ifttt-applet-collection-list-header">
    <button class="btn btn-primary pull-right"
            uiSref="auth.manageIftttApplet.service">
      <i class="fa fa-plus"></i> Create Trigger
    </button>
    <h2 translate="AUTOMATION">s</h2>
    <p>Here are all the triggers you have made. Select the module you want to check, within the organised tabs below:</p>

    <div class="ifttt-services-tabs">

      <a class="ifttt-services-tab"
         *ngFor="let service of services"
         uiSref="auth.iftttAppletCollectionList"
         [uiParams]="{serviceName: service.name}"
         uiSrefActive="active">
        {{ service.translationIds.title | translate }}
      </a>

    </div>

  </div>

  <div class="ifttt-applet-collection-list-body">

    <div class="alert alert-info"
         *ngIf="appletCollection.applets.length === 0">
      You have no triggers created yet. Why not <a uiSref="auth.manageIftttApplet.service">create one now</a>?
    </div>

    <table class="table ifttt-applet-collection-list"
           *ngIf="appletCollection.applets.length > 0">
      <thead>
        <tr>
          <th>Trigger name</th>
          <th *ngIf="service.name !== 'monitoring.imported'">Is on account</th>
          <th *ngIf="service.name === 'monitoring.imported'">Is on stream</th>
          <th>
            <span *ngIf="service.name === 'activity.imported.incoming'">
              If a new message contains
            </span>
            <span *ngIf="service.name === 'conversation.added_incoming_message'">
              If a new message in a conversation contains
            </span>
            <span *ngIf="service.name === 'outbox.published'">
              If a new post contains
            </span>
            <span *ngIf="service.name === 'monitoring.imported'">
              If a monitoring result contains
            </span>
          </th>
          <th colspan="2">The action is</th>
        </tr>
      </thead>
      <tbody>
        <tr class="ifttt-applet-collection-list-item"
            *ngFor="let applet of appletCollection.applets; let index = index"
            [class.disabled]="!applet.enabled">
          <td class="ifttt-applet-collection-list-column-name">
            <div class="ifttt-applet-collection-list-item-name-container">
              <div class="toggle toggle-sm">
                <input type="checkbox"
                       id="toggle{{ index }}"
                       [(ngModel)]="applet.enabled"
                       (change)="saveChanges()">
                <label for="toggle{{ index }}"></label>
              </div>
              <div class="ifttt-applet-collection-list-item-name">
                {{ applet.name }}
              </div>
              <div>
                <a class="ifttt-applet-collection-list-item-edit"
                   uiSref="auth.manageIftttApplet.serviceSelected.triggersAndActions"
                   [uiParams]="{serviceName: service.name, appletIndex: index}">
                  <i class="ssi ssi-pencil"></i>
                </a>
              </div>
            </div>
          </td>
          <td *ngIf="service.name !== 'monitoring.imported'"
              class="ifttt-applet-collection-list-column-accounts">
            <span class="ifttt-applet-collection-list-item-label-account"
                  *ngFor="let account of applet.accounts">
              <i [class]="account.socialNetwork.icon.web"></i> {{ account.displayName }}
            </span>
          </td>
          <td *ngIf="service.name === 'monitoring.imported'"
              class="ifttt-applet-collection-list-column-accounts">
            <span class="ifttt-applet-collection-list-item-label-account"
                  *ngFor="let stream of applet.streams">
                  {{ stream.name }}
            </span>
          </td>
          <td class="ifttt-applet-collection-list-column-triggers">
            <span class="ifttt-applet-collection-list-item-label-trigger"
                  *ngFor="let label of applet.labels.triggers">
              {{ label }}
            </span>
          </td>
          <td>
            <span class="ifttt-applet-collection-list-item-label-action"
                  *ngFor="let label of applet.labels.actions"
                  [innerHtml]="label | nl2br"></span>
          </td>
          <td class="ifttt-applet-collection-list-item-delete">
            <a (click)="deleteApplet(applet)">
              <i class="ssi ssi-trash"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

  </div>

</div>

<ng-template #brokenTriggerFaqLinkRef>
  <a href="https://support.orlo.tech/invalid-triggers"
     target="_blank"
     class="trigger-broken-faq-link">What does this mean?</a>
</ng-template>