import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ServiceSelectedComponent } from './service-selected.component';
import { AccountsModule } from './accounts/accounts.module';
import { StreamsSelectModule } from './streams-select/streams-select.module';
import { NameModule } from './name/name.module';
import { TriggersAndActionsModule } from './triggers-and-actions/triggers-and-actions.module';
import { SummaryModule } from './summary/summary.module';

@NgModule({
  imports: [
    CommonModule,
    AccountsModule,
    StreamsSelectModule,
    NameModule,
    TriggersAndActionsModule,
    SummaryModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet.serviceSelected',
          abstract: true,
          url: '/:serviceName',
          component: ServiceSelectedComponent,
          resolve: ServiceSelectedComponent.resolve
        }
      ]
    })
  ],
  declarations: [ServiceSelectedComponent]
})
export class ServiceSelectedModule {}
