import { Injectable } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable()
export class MissingTranslationHandlerService
  implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.warn(
      `No translation found for "${params.key}" for the language "${
        params.translateService.currentLang
      }"`
    );
    return params.translateService
      .getTranslation(params.translateService.defaultLang)
      .pipe(
        map((translation) => {
          return translation[params.key] || params.key;
        })
      );
  }
}
