import { Component, Input, OnInit } from '@angular/core';
import { AppletCollection } from '../../ifttt-utils/applets/applet-collection';
import { Transition, StateService } from '@uirouter/angular';
import { Applet } from '../../ifttt-utils/applets/applet';
import { IftttService } from '../../ifttt-utils/ifttt-service-model/ifttt-service-model';

export function serviceResolveFn(
  transition: Transition,
  services: IftttService[]
): IftttService {
  try {
    if (!Array.isArray(services)) {
      throw new Error(
        `Value for 'service resolve services' not in expected format.`
      );
    }

    return services.find(
      (iService) => iService.name === transition.params().serviceName
    );
  } catch (error) {
    console.error(error);
    return;
  }
}

export async function appletCollectionResolveFn(
  service: IftttService
): Promise<AppletCollection> {
  try {
    return await service.getAppletCollection();
  } catch (e) {
    console.log(e);
  }
}

export async function appletResolveFn(
  transition: Transition,
  appletCollection: AppletCollection
): Promise<Applet> {
  try {
    if (transition.params().appletIndex) {
      return appletCollection.applets[transition.params().appletIndex];
    } else {
      return await appletCollection.service.createEmptyApplet(appletCollection);
    }
  } catch (e) {
    console.log(e);
  }
}

@Component({
  selector: 'ssi-service-selected',
  templateUrl: './service-selected.component.html'
})
export class ServiceSelectedComponent implements OnInit {
  static resolve = [
    {
      token: 'service',
      resolveFn: serviceResolveFn,
      deps: [Transition, 'services']
    },
    {
      token: 'appletCollection',
      resolveFn: appletCollectionResolveFn,
      deps: ['service']
    },
    {
      token: 'applet',
      resolveFn: appletResolveFn,
      deps: [Transition, 'appletCollection']
    }
  ];

  @Input() applet: Applet;

  constructor(private state: StateService) {}

  ngOnInit() {
    // const startState = 'auth.manageIftttApplet.serviceSelected.accounts';
    // if (
    //   this.state.current.name !== startState &&
    //   this.applet.accounts.length === 0
    // ) {
    //   this.state.go(startState);
    // }
  }
}
