<div class="trigger-or-action-card-inner" (click)="delayCardClicked()">
  <div class="trigger-or-action-card-header">
    <div class="trigger-or-action-card-title">
      <a href="javascript:;" (click)="$event.stopPropagation(); clearValue.emit()">
        <i class="fa fa-times trigger-or-action-card-remove"></i>
      </a> <ng-template [ngTemplateOutlet]="cardTitleTemplate" [ngTemplateOutletContext]="{triggerOrAction: triggerOrAction}"></ng-template>
    </div>
    <div class="trigger-or-action-card-icon">
      <ng-template
        [ngTemplateOutlet]="cardIconTemplate"
        [ngTemplateOutletContext]="{triggerOrAction: triggerOrAction}">
      </ng-template>
    </div>
  </div>

  <div class="trigger-or-action-card-description">
    <ng-template
      *ngIf="!hasValue"
      [ngTemplateOutlet]="cardDescriptionTemplate"
      [ngTemplateOutletContext]="{triggerOrAction: triggerOrAction}">
    </ng-template>
    <ng-template
      *ngIf="hasValue"
      [ngTemplateOutlet]="cardValueLabelTemplate"
      [ngTemplateOutletContext]="{triggerOrAction: triggerOrAction}">
    </ng-template>
  </div>
</div>

<div
  class="trigger-or-action-card-overlay"
  (ssiOffClick)="overlayOffClick()"
  [style.width.px]="overlayWidth"
  [style.height.px]="overlayHeight"
  [style.left.px]="overlayPosition?.left"
  [style.top.px]="overlayPosition?.top"
  [style.bottom.px]="overlayPosition?.bottom"
  [style.right.px]="overlayPosition?.right"
  [class.visible]="overlayVisible">

  <div class="trigger-or-action-card-overlay-inner" *ngIf="overlayVisible">
    <div class="trigger-or-action-card-overlay-title">
      <ng-template
        [ngTemplateOutlet]="cardTitleTemplate"
        [ngTemplateOutletContext]="{triggerOrAction: triggerOrAction}">
      </ng-template>
    </div>

    <div class="trigger-or-action-card-overlay-description">
      <ng-template
        [ngTemplateOutlet]="cardDescriptionTemplate"
        [ngTemplateOutletContext]="{triggerOrAction: triggerOrAction}">
      </ng-template>
    </div>

    <ng-template
      class="trigger-or-action-card-overlay-body"
      [ngTemplateOutlet]="cardOverlayTemplate"
      [ngTemplateOutletContext]="{triggerOrAction: triggerOrAction}">
    </ng-template>
  </div>

</div>