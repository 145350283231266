import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CustomWidgetFieldComponent } from './/custom-widget-field.component';

import { ClickOutsideModule } from 'ng-click-outside';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterModule } from '../../../../../../common/pipes/filter/filter.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule,
    NgbDropdownModule.forRoot(),
    FilterModule
  ],
  declarations: [CustomWidgetFieldComponent],
  exports: [CustomWidgetFieldComponent],
  providers: []
})
export class CustomWidgetFieldModule {}
