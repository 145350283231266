<div
  class="modal-container"
>
  <div
    class="modal-header"
  >
    <i
      *ngIf="!! headerIcon"
      class="modal-header-icon {{ headerIcon }}"
    ></i>

    <h6
      *ngIf="!! headerText"
      class="modal-header-text"
    >
      {{ headerText }}
    </h6>
  </div>
  
  <div
    *ngIf="!! bodyContent"
    class="modal-body"
  >
    <p
      [innerHtml]="bodyContent"
      class="modal-body-inner"
    ></p>
  </div>

  <ul
    class="modal-footer"
  >
    <li
      *ngIf="!! secondaryButton" 
    >
      <button
        (click)="onClickSecondaryButton($event)"
        class="btn btn-secondary"
        type="button"
      >
        <i
          *ngIf="!! iconForSecondaryButton"
          class="modal-button-icon {{ iconForSecondaryButton }}"
        ></i>

        <span
          *ngIf="!! textForSecondaryButton"
          class="modal-button-text"
        >
          {{ textForSecondaryButton }}
        </span>
      </button>
    </li>
    
    <li
      *ngIf="!! primaryButton"
    >
      <button
        (click)="onClickPrimaryButton($event)"
        class="btn btn-primary"
        type="button"
      >
        <i
          *ngIf="!! iconForPrimaryButton"
          class="modal-button-icon {{ iconForPrimaryButton }}"
        ></i>

        <span
          *ngIf="!! textForPrimaryButton"
          class="modal-button-text"
        >
          {{ textForPrimaryButton }}
        </span>
      </button>
    </li>
  </ul>    
</div>
