import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleInputModalComponent } from './single-input-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [SingleInputModalComponent],
  entryComponents: [SingleInputModalComponent]
})
export class SingleInputModalModule {}
