import './outbox-message.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import {
  Outbox,
  Account,
  Campaign,
  OutboxPublisherMention
} from '@ui-resources-angular';
import { Colleague } from '../../services/api/colleagues';

export interface OutboxWithStatus extends Outbox {
  status: 'Awaiting Approval' | 'Scheduled' | 'Live';
}

@Component({
  selector: 'ssi-outbox-message',
  templateUrl: './outbox-message.component.html',
  styles: []
})
export class OutboxMessageComponent implements OnInit {
  @Input() message: OutboxWithStatus;
  @Input() account: Account;
  @Input() author: Colleague;
  @Input() campaign?: Campaign;
  mentions: OutboxPublisherMention[] = [];

  constructor() {}

  ngOnInit() {
    this.message['status'] = this.getMessageStatus(this.message);
  }

  private getMessageStatus(message) {
    if (message.social_id) {
      return 'Live';
    }
    const isScheduled =
      new Date(message.send_at).getTime() - new Date().getTime() > 0;
    const requiresValidation =
      message.is_validated === '0' ? true : !message.is_validated;
    return requiresValidation
      ? 'Awaiting Approval'
      : isScheduled
      ? 'Scheduled'
      : 'Live';
  }
}
