import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardNavListComponent } from './keyboard-nav-list.component';
import { FormsModule } from '@angular/forms';
import { FilterByStringModule } from '../../pipes/filter-by-string/filter-by-string.module';

@NgModule({
  imports: [CommonModule, FormsModule, FilterByStringModule],
  declarations: [KeyboardNavListComponent],
  exports: [KeyboardNavListComponent]
})
export class KeyboardNavListModule {}
