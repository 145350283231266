import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { DataStore } from 'js-data';
import { AccountModule } from './modules/account.module';
import { ActivityModule } from './modules/activity.module';
import { CampaignModule } from './modules/campaign.module';
import { CoreModule } from './modules/core.module';
import { NoteModule } from './modules/note.module';
import { ProfileModule } from './modules/profile.module';
import { PublishModule } from './modules/publish.module';
import { UserModule } from './modules/user.module';
import { CrmModule } from './modules/crm.module';
import { AccountModel } from '../modules/account/services/accountModel';
import { WorkflowModel } from '../modules/account/services/workflowModel';
import { ActivityModel } from '../modules/activity/services/activityModel';
import { CampaignModel } from '../modules/campaign/services/campaignModel';
import { NoteModel } from '../modules/note/services/noteModel';
import { ProfileModel } from '../modules/profile/services/profileModel';
import { OutboxModel } from '../modules/publish/services/outboxModel';
import { UserModel } from '../modules/user/services/userModel';
import { CrmPersonModel } from '../modules/crm/services/crmPersonModel';
import { CrmExternalIntegrationModel } from '../modules/crm/services/crmExternalIntegrationModel';
import { VanityDomainModel } from '../modules/account/services/vanityDomainModel';
import { ConversationModel } from '../modules/activity/services/conversationModel';
export * from './modules/account.module';
export * from './modules/activity.module';
export * from './modules/campaign.module';
export * from './modules/core.module';
export * from './modules/note.module';
export * from './modules/profile.module';
export * from './modules/publish.module';
export * from './modules/user.module';
export * from './modules/crm.module';
export * from '../modules/exports';
export * from './record';

export function noop() {} // tslint:disable-line

export function modelsFactory(store, ...models) {
  return noop;
}

@NgModule({
  imports: [
    AccountModule.forRoot(),
    ActivityModule.forRoot(),
    CampaignModule.forRoot(),
    CoreModule.forRoot(),
    NoteModule.forRoot(),
    ProfileModule.forRoot(),
    PublishModule.forRoot(),
    UserModule.forRoot(),
    CrmModule.forRoot()
  ],
  exports: [
    AccountModule,
    ActivityModule,
    CampaignModule,
    CoreModule,
    NoteModule,
    ProfileModule,
    PublishModule,
    UserModule,
    CrmModule
  ]
})
export class UIResourcesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UIResourcesModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: modelsFactory,
          deps: [
            DataStore,
            AccountModel,
            WorkflowModel,
            ActivityModel,
            CampaignModel,
            NoteModel,
            ProfileModel,
            OutboxModel,
            UserModel,
            CrmPersonModel,
            CrmExternalIntegrationModel,
            VanityDomainModel,
            ConversationModel
          ]
        }
      ]
    };
  }
}
