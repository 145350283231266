<div class="inbox-dashboard-inner">
  <ssi-conversation-resolution-confirmation
    *ngIf="hasNewlyResolvedConversation && !isSocialPushMode"
    (onDismiss)="onDismissConversationResolutionConfirmation.emit()"
  ></ssi-conversation-resolution-confirmation>

  <div
    class="social-push-mode-dashboard"
    *ngIf="hasConversationPushModeEnabled && isSocialPushMode"
  >
    <div class="header-container">
      <h1>Social Push Mode Dashboard</h1>
    </div>

    <ul class="total-stats-list">
      <li class="single-stat">
        <i class="ssi ssi-conversation-mode"></i>
        <span class="value">{{socialPushModeStats?.count_conversations_to_assign}}</span>
        <span>Conversations <br> waiting</span>
      </li>
      <li class="single-stat">
        <i class="ssi ssi-on-hold"></i>
        <span class="value">{{socialPushModeStats?.count_conversations_on_hold}}</span>
        <span>Conversations <br> On Hold</span>
      </li>
    </ul>

    <ul
      class="agents-list"
      *ngIf="agentsStats"
    >

      <li
        class="agent-container"
        *ngFor="let agent of agentsStats"
        [ngClass]="agent.status?.id"
      >
        <img
          class="avatar"
          [src]="agent.user.avatar_url"
          alt="avatar for {{ agent.user.fullName }}"
          (click)="openAssigned(agent)"
        >
        <div class="agent-details">
          <button
            *ngIf="!agent.isManageable || agent.isManageable && !isPushModeAdmin"
            disabled
            class="status-button {{ agent.status?.id }}"
          >
            {{agent.status?.label}}
          </button>
          <ng-container *ngIf="isPushModeAdmin || !isPushModeAdmin && agentIsUser(agent) ">
            <button
              *ngIf="agent.isManageable"
              class="status-button {{ agent.status?.id }}"
              ngbDropdown
              ngbDropdownToggle
              type="button"
            >
              <span class="status-text">
                {{ agent.status?.label }}
              </span>

              <i class="ssi ssi-arrow-down-correct status-icon"></i>

              <ul
                class="dropdown-menu"
                ngbDropdownMenu
              >
                <li
                  *ngFor="let status of filteredStatuses"
                  (click)="changeAgentStatus(status, agent.user.id)"
                  [ngClass]="{ selected: status == agent.status }"
                >
                  <button type="button">
                    <span
                      *ngIf="agent.user.id === authUser.id"
                      translate="SET_YOURSELF_AS"
                    ></span>

                    <strong>
                      {{ status.label }}
                    </strong>
                  </button>
                </li>
              </ul>
            </button>

          </ng-container>

          <dl class="resolutions">
            <dt translate="ASSIGNED"></dt>

            <dd>
              {{ agent.assigned }}
            </dd>
          </dl>

          <span class="name">
            {{ agent.user.fullName }}
          </span>
        </div>
      </li>

    </ul>
  </div>

  <div
    class="dashboard-container"
    *ngIf="!isSocialPushMode && !hasNewlyResolvedConversation"
  >
    <div class="inbox-clock">
      <ssi-clock></ssi-clock>
    </div>

    <h2
      translate="HELLO__NAME_"
      [translateParams]="{name: authUser.first_name}"
    ></h2>

    <div
      *ngIf="hasConversationPushModeEnabled"
      class="social-status"
    >
      <label>{{'SOCIAL_PUSH_MODE_STATUS' | translate}}:</label>
      <ssi-social-push-mode-menu
        [items]="statuses"
        [selectedItem]="currentStatus"
        (itemChange)="changeAgentStatus($event, authUser.id)"
      ></ssi-social-push-mode-menu>

      <p
        *ngIf="currentStatus.id === 'ONLINE'"
        class="description"
        translate="YOU_ARE_CURRENTLY_BEING_PUSHED_CONVERSATIONS_IN_SOCIAL_PUSH_MODE"
      ></p>
    </div>

    <div class="stats-and-avatar">
      <ssi-tooltip-wrap
        [ssiTooltip]="'TOTAL_UNACTIONED_MESSAGES_ASSIGNED_TO_YOU_OVER_ALL_TIME' | translate"
        [tooltipWindowClass]="'tooltip-chunky'"
      >
        <div
          class="stat"
          [class.active]="userEngagementStatistics?.assigned_to_user > 0"
        >
          <i class="ssi ssi-assigned"></i>
          <span>{{ userEngagementStatistics?.assigned_to_user }}</span>&nbsp;<span>msgs</span>
        </div>
      </ssi-tooltip-wrap>

      <img
        class="avatar"
        [src]="userAvatar"
        ssiFallbackImage="avatarOutgoing"
      >

      <ssi-tooltip-wrap
        [ssiTooltip]="'AVG_RESPONSE_TIME' | translate"
        [tooltipWindowClass]="'tooltip-chunky'"
      >
        <div
          class="stat"
          [class.active]="userEngagementStatistics?.average_response_time > 0"
        >
          <i class="ssi ssi-fw ssi-user-av-response-time"></i>
          <span>{{ userEngagementStatistics?.average_response_time | secondsToHumanTime }}</span>
        </div>
      </ssi-tooltip-wrap>
    </div>
  </div>

</div>