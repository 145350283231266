import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';
import { LinkifyModule } from '../../pipes/linkify/linkify.module';
import { Nl2brModule } from '../../pipes/nl2br/nl2br.module';
import { EscapeHtmlModule } from '../../pipes/escape-html/escape-html.module';

@NgModule({
  imports: [CommonModule, LinkifyModule, Nl2brModule, EscapeHtmlModule],
  declarations: [AlertComponent],
  exports: [AlertComponent]
})
export class AlertModule {}
