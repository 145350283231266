import './report-build-from.component.scss';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';

import moment from 'moment';
import { orderBy } from 'lodash-es';

import { AccountModel, Account, ActivityTags } from '@ui-resources-angular';
import { groupBy, mapToIterable } from '../../../../../common/utils';
import {
  DateRange,
  DateRanges,
  commonDateRanges,
  commonDateRangesIterable
} from '../../../../../common/constants/common-date-ranges';
import {
  CompareToOption,
  CompareToOptions,
  compareToOptions,
  compareToOptionsIterable
} from '../../../../../common/constants/compare-to-options';
import { PUBLISH_ALLOWED_CHANNELS } from '../../../../../common/constants';
import { AccountTypeId } from '../../../../../common/enums';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import { OutboxTagsService } from '../../../../../common/services/api';
import { LocalStorageService } from 'angular-2-local-storage';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export enum Report {
  Marketing = 'marketing',
  Engagement = 'engagement',
  Survey = 'survey',
  Account = 'account'
}

export const EXCLUDE_IMPORT_DELAY_LS_KEY = 'excludeImportDelay';

export interface ReportBuildFormValue {
  accounts: Account[];
  dateRange: DateRange;
  compareToOption: CompareToOption;
  startDate: Date;
  endDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;

  // Engagement report related
  visibilityOption: VisibilityOption;
  tagsToInclude: string[];
  tagsToExclude: string[];
  excludeImportDelay?: boolean;

  // Marketing report related
  includeDeletedInTopPosts?: boolean;
}

export interface VisibilityOption {
  key: 'all' | 'public' | 'private';
  label: string;
}
export interface ImportDelayOption {
  key: boolean;
  label: string;
}
export interface IncludeDeletedInTopPostsOption {
  key: boolean;
  label: string;
}
export interface VisibilityOptions {
  all: VisibilityOption;
  public: VisibilityOption;
  private: VisibilityOption;
}

export const visibilityOptions: VisibilityOptions = {
  all: {
    key: 'all',
    label: 'All messages'
  },
  public: {
    key: 'public',
    label: 'Public messages only'
  },
  private: {
    key: 'private',
    label: 'Private messages only'
  }
};

export const includeDeletedInTopPostsOptions: any = {
  include: {
    key: true,
    label: 'Include deleted posts in Top Posts'
  },
  exclude: {
    key: false,
    label: 'Exclude deleted posts in Top Posts'
  }
};

export const importDelayOptions: any = {
  include: {
    key: false,
    label: 'Include network import delay'
  },
  exclude: {
    key: true,
    label: 'Exclude network import delay'
  }
};

export const visibilityOptionsIterable: VisibilityOption[] = mapToIterable(
  visibilityOptions
);

export const importDelayOptionsIterable: ImportDelayOption[] = mapToIterable(
  importDelayOptions
);

export const includeDeletedInTopPostsOptionsIterable: IncludeDeletedInTopPostsOption[] = mapToIterable(
  includeDeletedInTopPostsOptions
);

@Component({
  selector: 'ssi-report-build-from',
  templateUrl: './report-build-from.component.html',
  styles: []
})
export class ReportBuildFromComponent implements OnInit {
  @Input() report: Report; // report type
  @Input() formValue?: ReportBuildFormValue;
  @Input() showCompareToSection = false;
  @Input() showVisibilitySection = false;
  @Input() showTagsSection = false;
  @Input() showImportDelayOptions = false;
  @Input() showIncludeDeletedInTopPostsOptions = false;
  @Input() allChannels = false;
  /** Pass channels you only want listed in "Accounts included" dropdown */
  @Input() accountTypeIds?: AccountTypeId[];
  /** Pass channels you don't want listed in "Accounts included" dropdown */
  @Input() accountTypeIdsToExclude?: AccountTypeId[];
  @Input() includeLiveChatAccounts? = false;
  @Input() tags?: any[];

  @Output() formSubmit = new EventEmitter<ReportBuildFormValue>();

  dateRanges: DateRanges = commonDateRanges;
  dateRangesIterable: DateRange[] = commonDateRangesIterable;
  selectedDateRange: DateRange = this.dateRanges.month;

  startDate: Date = this.selectedDateRange.start;
  endDate: Date = this.selectedDateRange.end;
  previousStartDate: Date;
  previousEndDate: Date;
  today: Date = moment().endOf('day').toDate();

  compareToOptions: CompareToOptions = compareToOptions;
  compareToOptionsIterable: CompareToOption[] = compareToOptionsIterable;
  selectedCompareToOption: CompareToOption;

  accounts: Account[] = [];
  selectedAccounts: Account[] = [];
  selectedAccountTypeIds: string[] = [];
  selectAllAccountsChecked = false;

  visibilityOptions: VisibilityOptions = visibilityOptions;
  visibilityOptionsIterable: VisibilityOption[] = visibilityOptionsIterable;
  selectedVisibilityOption: VisibilityOption;
  importDelayOptionsIterable: ImportDelayOption[] = importDelayOptionsIterable;
  selectedImportDelayOption: ImportDelayOption;
  includeDeletedInTopPostsOptionsIterable: IncludeDeletedInTopPostsOption[] = includeDeletedInTopPostsOptionsIterable;
  selectedIncludeDeletedInTopPostsOption: IncludeDeletedInTopPostsOption;

  selectedTagsToInclude = [];
  selectedTagsToExclude = [];

  Report = Report;

  destroyed$ = new Subject<void>();

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected outboxTagsService: OutboxTagsService,
    protected activityTags: ActivityTags,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnInit() {
    if (this.showVisibilitySection) {
      this.selectedVisibilityOption = this.visibilityOptions.all;
    }
    if (this.showImportDelayOptions) {
      const excludeImportDelayPreference = this.localStorageService.get(
        EXCLUDE_IMPORT_DELAY_LS_KEY
      );
      this.selectedImportDelayOption =
        excludeImportDelayPreference !== null
          ? importDelayOptionsIterable.find(
              (o) => o.key === excludeImportDelayPreference
            )
          : importDelayOptions.include;
    }
    if (this.showIncludeDeletedInTopPostsOptions) {
      this.selectedIncludeDeletedInTopPostsOption =
        includeDeletedInTopPostsOptions.exclude;
    }

    if (this.showCompareToSection) {
      this.selectedCompareToOption = this.compareToOptions.previousPeriod;
    }
    this.updateCompareToDates();

    this.accounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId()
    );
    this.accounts = orderBy(this.accounts, ['account_type_name', 'name']);
    if (!this.allChannels) {
      this.accounts = this.accounts.filter((account) =>
        this.includeLiveChatAccounts
          ? [...PUBLISH_ALLOWED_CHANNELS, 'Live Chat'].includes(
              account.account_type_name
            )
          : PUBLISH_ALLOWED_CHANNELS.includes(account.account_type_name)
      );
    }

    if (this.accountTypeIdsToExclude) {
      this.accounts = this.accounts.filter(
        (a) =>
          this.accountTypeIdsToExclude.indexOf(Number(a.account_type_id)) === -1
      );
    }

    if (this.accountTypeIds && !this.accountTypeIdsToExclude) {
      this.accounts = this.accounts.filter(
        (a) => this.accountTypeIds.indexOf(Number(a.account_type_id)) > -1
      );
    }

    if (this.showTagsSection) {
      const tags = this.tags ? this.tags : await this.activityTags.getTags();
      this.tags = tags.map((t) => ({ id: t, label: t }));
    }

    if (this.formValue) {
      this.rePopulateForm(this.formValue);
    }
  }

  rePopulateForm(value: ReportBuildFormValue): void {
    if (!value) {
      return;
    }

    if (value.accounts) {
      this.onSelectedAccountsChange(value.accounts);
    }

    if (value.compareToOption) {
      this.selectCompareToOption(value.compareToOption);
    }

    if (value.dateRange) {
      this.selectPresetDateRange(value.dateRange);
    } else {
      this.onStartDatePicked(value.startDate);
      this.onEndDatePicked(value.endDate);
    }

    if (
      value.compareToOption &&
      value.compareToOption.id === compareToOptions.custom.id
    ) {
      this.onPreviousStartDatePicked(value.previousStartDate);
      this.onPreviousEndDatePicked(value.previousEndDate);
    }

    if (value.visibilityOption) {
      this.selectVisibilityOption(value.visibilityOption);
    }

    if (value.tagsToInclude) {
      const tags = value.tagsToInclude.map((t) => ({
        id: t,
        label: t
      }));
      this.onSelectedTagsToIncludeChange(tags);
    }

    if (value.tagsToExclude) {
      const tags = value.tagsToExclude.map((t) => ({
        id: t,
        label: t
      }));
      this.onSelectedTagsToExcludeChange(tags);
    }

    if (value.includeDeletedInTopPosts) {
      this.selectIncludeDeletedInTopPostsOption(value.includeDeletedInTopPosts);
    }
  }

  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.formSubmit.emit({
      accounts: this.selectedAccounts,
      dateRange: this.selectedDateRange,
      compareToOption: this.selectedCompareToOption,
      startDate: this.startDate,
      endDate: this.endDate,
      previousStartDate: this.previousStartDate,
      previousEndDate: this.previousEndDate,

      // engagement report related
      visibilityOption: this.selectedVisibilityOption,
      tagsToInclude: this.selectedTagsToInclude.map((t) => t.id),
      tagsToExclude: this.selectedTagsToExclude.map((t) => t.id),
      excludeImportDelay:
        this.showImportDelayOptions && this.selectedImportDelayOption.key,

      // marketing report related
      includeDeletedInTopPosts:
        this.showIncludeDeletedInTopPostsOptions &&
        this.selectedIncludeDeletedInTopPostsOption.key
    });
  }

  updateCompareToDates(): void {
    if (
      !this.showCompareToSection ||
      !this.selectedCompareToOption.getCompareToDates
    ) {
      return;
    }

    const { start, end } = this.selectedCompareToOption.getCompareToDates(
      this.startDate,
      this.endDate
    );
    this.previousStartDate = start;
    this.previousEndDate = end;
  }

  selectPresetDateRange(range: DateRange): void {
    this.selectedDateRange = this.dateRanges[range.id];

    this.startDate = this.selectedDateRange.start;
    this.endDate = this.selectedDateRange.end;
    this.updateCompareToDates();
  }

  onStartDatePicked(startDate: Date): void {
    if (!startDate) {
      return;
    }
    this.startDate = startDate;
    this.selectedDateRange = undefined;
    this.updateCompareToDates();
  }

  onEndDatePicked(endDate: Date): void {
    if (!endDate) {
      return;
    }
    this.endDate = endDate;
    this.selectedDateRange = undefined;
    this.updateCompareToDates();
  }

  onPreviousStartDatePicked(previousStartDate: Date): void {
    if (!previousStartDate) {
      return;
    }
    this.previousStartDate = previousStartDate;
  }

  onPreviousEndDatePicked(previousEndDate: Date): void {
    if (!previousEndDate) {
      return;
    }
    this.previousEndDate = previousEndDate;
  }

  selectCompareToOption(option: CompareToOption): void {
    this.selectedCompareToOption = option;
    this.updateCompareToDates();
  }

  selectVisibilityOption(option: VisibilityOption): void {
    this.selectedVisibilityOption = option;
  }

  selectIncludeDeletedInTopPostsOption(option: boolean): void {
    this.selectedIncludeDeletedInTopPostsOption = option
      ? includeDeletedInTopPostsOptions.include
      : includeDeletedInTopPostsOptions.exclude;
  }

  selectImportDelayOption(option: ImportDelayOption): void {
    this.selectedImportDelayOption = option;
    this.localStorageService.set(EXCLUDE_IMPORT_DELAY_LS_KEY, option.key);
  }

  onSelectedAccountsChange(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
    this.selectAllAccountsChecked =
      this.selectedAccounts.length === this.accounts.length;

    this.setSelectedAccountTypeIds();
  }

  setSelectedAccountTypeIds(): void {
    this.selectedAccountTypeIds = [];

    const accountGroups = groupBy(
      this.selectedAccounts,
      (account) => account.account_type_id
    );

    accountGroups.forEach((accountGroup) => {
      this.selectedAccountTypeIds.push(accountGroup[0].account_type_id);
    });
  }

  onSelectAllAccountsChange(checked: boolean): void {
    this.selectedAccounts = checked ? [...this.accounts] : [];

    this.setSelectedAccountTypeIds();
  }

  onSelectedTagsToIncludeChange(tags: any[]) {
    this.selectedTagsToInclude = tags;
  }

  onSelectedTagsToExcludeChange(tags: any) {
    this.selectedTagsToExclude = tags;
  }

  removeIncludedTag(tag) {
    this.selectedTagsToInclude = this.selectedTagsToInclude.filter(
      (iTag) => iTag.id !== tag.id
    );
  }

  removeExcludedTag(tag) {
    this.selectedTagsToExclude = this.selectedTagsToExclude.filter(
      (t) => t.id !== tag.id
    );
  }

  onFlatpickrOpen() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 200);
  }
}
