import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ssi-video-modal',
  templateUrl: './video-modal.component.html'
})
export class VideoModalComponent {
  sanitizedUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  setVideoUrl(url: string) {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
