<div class="add-insights-widget-content-container modal-popup">
  <div class="heading">
    <h2 class="title">Add your widgets</h2>
    <div class="heading-right">
      <div
        class="search-field"
        [class.active]="!!searchTerm"
      >
        <i class="ssi ssi-correct-search"></i>
        <input
          type="text"
          [(ngModel)]="searchTerm"
          (ngModelChange)="filterValues()"
          placeholder="Search widgets..."
        >
      </div>
      <i
        class="close-icon ssi ssi-delete-close"
        (click)="activeModal.dismiss()"
      ></i>
    </div>
  </div>

  <div class="content">

    <div class="filters-pane">
      <ul>
        <li
        (click)="selectWidgetType(undefined)"
        [class.active]="!selectedWidgetType"
        >
          All widgets
        </li>
        <li
          *ngFor="let widgetType of widgetTypesIterable"
          (click)="selectWidgetType(widgetType)"
          [class.active]="selectedWidgetType === widgetType"
        >
          {{widgetType.label}}
        </li>
      </ul>
      <!-- <div class="action-button">
        <orlo-button
          textColor="#FFF"
          state="text"
          leadingIcon="ssi ssi-addsmall"
          text="Create Custom"
          [bgColor]="'#14BAE3'"
        ></orlo-button>
      </div> -->
    </div>
    <div class="items-list-container">
      <div class="items-list insights-reports-grid">
        <div
          class="item-box insights-reports-grid-item"
          *ngFor="let widget of filteredWidgets; let index = index;"
        >
          <div
            class="thumbnail-action"
            >
            <!-- [tooltipTitle]="widget.typeConst.label"
            [ssiTooltip]="widget.typeConst.tooltipExplainer"
            [tooltipIconClass]="widget.typeConst.iconClassName"
            [tooltipWindowClass]="'dark-new'" -->
            <div class="item-thumb {{widget.typeConst.iconClassName}}">
            </div>
            <button type="button" (click)="widgetAdded.emit(widget)"><i class="ssi ssi-addsmall"></i></button>
          </div>
          <h4 class="title">
            {{widget.name}}
          </h4>
          <p class="description">
            {{widget.description}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>