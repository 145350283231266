<article class="sbt">
  <div class="sbt-header">
    <p class="sbt-title" [innerHTML]="title"></p>
    <div class="sbt-header-right">
      <p class="sbt-value">
        <ssi-big-number [number]="stat"></ssi-big-number>
      </p>
      <i
        class="sbt-icon"
        [ngClass]="iconClass"
        [style.font-size.px]="iconSize"
      ></i>
    </div>
  </div>
  <div *ngIf="progressPercentage" class="sbt-progress">
    <span
      [ngStyle]="{
        width: progressPercentage + '%',
        backgroundColor: progressColor
      }"
    ></span>
  </div>
</article>
