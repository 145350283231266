import './survey-responses.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { deepValue } from '@jsier/deep-value';

import { Account } from '@ui-resources-angular';
import {
  PageChangeParams,
  PaginatorComponent
} from '../../../../../../common/components/paginator';
import { ServerSortParams } from '../../../../../../common/components/treetable';
import {
  DateRange,
  SurveyReport
} from '../../../../../../common/services/api/surveys';

@Component({
  selector: 'ssi-survey-responses',
  templateUrl: './survey-responses.component.html',
  styles: []
})
export class SurveyResponsesComponent {
  @Input() accounts: Account[] = [];
  @Input() dateRange: DateRange;
  @Input() itemsPerPage: number;
  @Input() report: SurveyReport;
  @Input() itemsCount: number;
  @Input() loading = false;

  @Output() pageChange = new EventEmitter<PageChangeParams>();
  @Output() serverSortParamsChange = new EventEmitter<ServerSortParams>();

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  deepValue = deepValue;

  constructor() {}

  onPageChange(params: PageChangeParams): void {
    this.pageChange.emit(params);
  }

  onServerSortParamsChange(params: ServerSortParams): void {
    this.serverSortParamsChange.emit(params);
  }
}
