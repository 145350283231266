import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ALL_FILE_SOURCES,
  CUSTOM_SOURCE_NAME,
  FilestackClient,
  FilestackFile,
  FilestackPickOptions,
  FilestackService,
  FilestackSources,
  GIF_SOURCES,
  IMAGE_SOURCES,
  fileTypeNotRecognized,
  isCsvFile
} from '../../services/filestack/filestack.service';
import {
  bytesToMb,
  FileUploaderService,
  FsFile,
  isGifImage,
  isImage,
  isVideo,
  ValidateFileErrors
} from './file-uploader.service';
import { PickerFileMetadata } from 'filestack-js/build/main/lib/picker';
import {
  API,
  OutboxFileType,
  UserModel,
  getMimetypeFromUrl,
  ImageMimetype,
  MediaRestrictions,
  MediaCategory
} from '@ui-resources-angular';
import { TranslateService } from '@ngx-translate/core';
import { KeyValueObject } from '../../util';
import { PopupService } from '../../services/popup/popup.service';
import bytes from 'bytes';

interface CustomSourceInfo {
  last_modified: string;
  metadata: {
    created_by?: string;
    outbox_post?: {
      text: string;
    };
  };
  mimetype: string;
  name: string;
  size: number;
  url: string;
}

export interface FileUploadFile {
  url: string;
  handle?: string;
  mimetype: string;
  filestackFile: FilestackFile;
  customSource?: CustomSourceInfo;
  validateErrors?: ValidateFileErrors;
}

export interface ValidateAndConvertFileResult {
  file?: FsFile;
  errors?: ValidateFileErrors;
}

export const CUSTOM_SOURCE_PREFIX = 'company-files/';

export const MAX_IMAGE_UPLOAD_SIZE = 25 * 1024 * 1024;

@Directive({
  selector: '[ssiFileUploader]',
  exportAs: 'ssiFileUploader'
})
export class FileUploaderDirective implements OnInit, OnChanges {
  @Input() accept: string[] = [];
  @Input() fromSources: FilestackSources[];
  @Input() disabled = false;
  @Input() maxFiles: number;
  @Input() restrictions: MediaRestrictions;
  @Input() mediaCategory: MediaCategory;

  @Output() uploadStart = new EventEmitter<void>();
  @Output() uploadSuccess = new EventEmitter<FileUploadFile[]>();
  @Output() uploadError = new EventEmitter<Error>();
  @Output() uploadFinally = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();

  uploadActive = false;

  constructor(
    private fileUploaderService: FileUploaderService,
    private filestack: FilestackService,
    private userModel: UserModel,
    private translate: TranslateService,
    private api: API,
    private popup: PopupService,
    private elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.elementRef.nativeElement.style['cursor'] = this.disabled
        ? 'not-allowed'
        : 'pointer';
    }
  }

  ngOnInit(): void {}

  @HostListener('click')
  onClick() {
    if (this.disabled) {
      return;
    }

    this.chooseFile();
  }

  async chooseFile() {
    if (this.uploadActive) {
      const error = 'Another file upload is already in progress';
      await this.popup.confirm({
        title: this.translate.instant('FILE_UPLOAD_FAILED'),
        message: error
      });
      throw new Error(error);
    }

    this.uploadActive = true;
    this.uploadStart.emit();
    try {
      const [client, authUser] = await Promise.all([
        this.filestack.getClient(),
        this.userModel.getAuthUser()
      ]);

      const onlyAcceptsImages = this.accept.every((mime) =>
        mime.startsWith(OutboxFileType.Image)
      );

      const onlyAcceptsGifs =
        this.accept.length === 1 && this.accept[0] === ImageMimetype.Gif;

      let fromSources = this.fromSources;
      if (!fromSources) {
        if (onlyAcceptsGifs) {
          fromSources = GIF_SOURCES;
        } else if (onlyAcceptsImages) {
          fromSources = IMAGE_SOURCES;
        } else {
          fromSources = ALL_FILE_SOURCES;
        }
      }

      const params: FilestackPickOptions = {
        accept: [...this.accept],
        exposeOriginalFile: true,
        fromSources: [...fromSources],
        lang: this.translate.currentLang,
        disableTransformer: true,
        maxFiles: this.maxFiles,
        customSourcePath: `${CUSTOM_SOURCE_PREFIX}${authUser.company_id}/`,
        onFileSelected: this.onFileSelected.bind(this),
        onUploadDone: (result) => {
          this.validateAndSaveFiles(result.filesUploaded);
        },
        onClose: () => this.onClose.emit(),
        customSourceName: CUSTOM_SOURCE_NAME,
        uploadConfig: {
          timeout: 1200000
        }
      };

      await client.picker(params).open();
    } catch (e) {
      // user cancelled if the error is an empty array, wtf filestack
      if (!Array.isArray(e)) {
        this.uploadError.emit(e);
      }
    } finally {
      this.uploadActive = false;
      this.uploadFinally.emit();
    }
  }

  async onFileSelected(file: FilestackFile): Promise<void> {
    // https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#onfileselected

    if (!this.restrictions) {
      return;
    }

    if (await isVideo(file)) {
      if (this.mediaCategory === MediaCategory.Reel && this.restrictions.reel) {
        this.validateFileSize(file, this.restrictions.reel.size.max);
      } else if (
        this.mediaCategory === MediaCategory.Story &&
        this.restrictions.videoStory
      ) {
        this.validateFileSize(file, this.restrictions.videoStory.size.max);
      } else if (this.restrictions.video) {
        this.validateFileSize(file, this.restrictions.video.size.max);
      }
    } //
    else if (await isGifImage(file)) {
      if (this.restrictions.gif) {
        this.validateFileSize(file, this.restrictions.gif.size.max);
      }
    } //
    else if (await isImage(file)) {
      this.validateFileSize(file, MAX_IMAGE_UPLOAD_SIZE, true);
    } //
    else {
      console.error(`Other upload (filestack): Size OK.`); // for tackjs only
    }
  }

  validateFileSize(file: FilestackFile, maxSize: number, image = false): void {
    let size = file.size;
    if (!size || !maxSize) {
      return;
    }

    if (typeof size === 'string') {
      // temp solution for a known bug - https://github.com/filestack/filestack-js/issues/257
      size = bytes(size);
    }

    if (size >= maxSize) {
      const sizeMb = bytesToMb(size);
      const maxSizeMb = bytesToMb(maxSize);

      if (image) {
        console.error(
          `Image upload (filestack): Max size exceeded. Size: ${sizeMb}MB, max size: ${maxSizeMb}MB.`
        ); // for tackjs only
      } else {
        console.error(
          `Video or GIF upload (filestack): Max size exceeded. Size: ${sizeMb}MB, max size: ${maxSizeMb}MB.`
        ); // for tackjs only
      }

      throw new Error(
        `File ${file.filename} is ${sizeMb}MB. The accepted file size ${
          image ? '' : 'for chosen social network/s'
        } is less than ${maxSizeMb}MB.`
      );
    } else {
      if (image) {
        console.error(
          `Image upload (filestack): Size OK. Size: ${bytesToMb(
            size
          )}MB, max size: ${bytesToMb(maxSize)}MB.`
        ); // for tackjs only
      } else {
        console.error(
          `Video or GIF upload (filestack): Size OK. Size: ${bytesToMb(
            size
          )}MB, max size: ${bytesToMb(maxSize)}MB.`
        ); // for tackjs only
      }
    }
  }

  async validateAndSaveFiles(files): Promise<void> {
    if (this.mediaCategory) {
      files.forEach((f) => {
        // add media category so later on correct restrictions can be applied
        f.mediaCategory = this.mediaCategory;
      });
    }

    const filePromises = files.map(async (filestackFile) => {
      const extension = filestackFile.filename.split('.').pop();

      const [customSource, _, validateImageOrVideoResult] = await Promise.all([
        this.getCustomSourceFile(filestackFile),
        extension === 'srt'
          ? this.validateSrtMime(filestackFile)
          : this.validateMime(filestackFile),
        this.validateAndConvertIfImageOrVideo(filestackFile)
      ]);

      // update filestackfile with new props that came out from validation (url, size, etc.)
      const fsFile = { ...filestackFile, ...validateImageOrVideoResult.file };

      return {
        ...fsFile,
        validateErrors: validateImageOrVideoResult.errors,
        filestackFile: fsFile,
        customSource
      };
    });

    const result = await Promise.all(filePromises);

    // console.log('save file result::::::', result);

    this.uploadSuccess.emit(result as any);
  }

  async getCustomSourceFile(file: FilestackFile) {
    if (file.source !== FilestackSources.CustomSource) {
      return undefined;
    }

    const { data } = await this.api.get<{
      data: CustomSourceInfo;
    }>('company/file', { params: { file: file.filename } });

    return data;
  }

  async validateMime(file: FilestackFile) {
    if (fileTypeNotRecognized(file) || isCsvFile(file)) {
      // Some browsers seemingly don't recognize file type set by Windows for CSV files so it ends up being empty string - skip validation
      // Also, the API in some cases returns text/plain for CSV files - skip validation (until we find a way to fix it on the backend?)
      return;
    }

    const mime = await getMimetypeFromUrl(file.url);

    if (mime !== file.mimetype) {
      const error = `The file "${file.filename}" does not look like a ${file.mimetype} (we think it's: ${mime}). Please convert/re-encode.`;
      await this.popup.confirm({
        title: this.translate.instant('FILE_UPLOAD_FAILED'),
        message: error
      });
      throw new Error(error);
    }
  }

  async validateSrtMime(file: FilestackFile) {
    const mime = await getMimetypeFromUrl(file.url);

    if (mime !== 'text/plain') {
      const error = `The file extension of ${file.filename} does not match the contents of the file`;
      await this.popup.confirm({
        title: this.translate.instant('FILE_UPLOAD_FAILED'),
        message: error
      });
      throw new Error(error);
    }
  }

  async validateAndConvertIfImageOrVideo(
    file: FilestackFile
  ): Promise<ValidateAndConvertFileResult> {
    let result;

    if (await isImage(file)) {
      result = await this.fileUploaderService.validateAndConvertImage(
        file,
        this.restrictions
      );
    } //
    else if (await isVideo(file)) {
      result = await this.fileUploaderService.validateAndConvertVideo(
        file,
        this.restrictions
      );
    } //
    else {
      result = {};
    }

    if (result.errors) {
      const error = Object.values(result.errors).find((e) => !!e);
      throw new Error((error as any).message);
    }

    return result;
  }
}
