import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MinDirective } from './min.directive';

@NgModule({
  imports: [CommonModule],
  exports: [MinDirective],
  declarations: [MinDirective]
})
export class MinModule {}
