<div class="top-post-view-container">
  <div class="left">
    <img [src]="topPost.avatar">
  </div>

  <div class="view-post-link">
    <a [href]="topPost.link"
       target="_blank">View post</a>
  </div>

  <div class="middle">
    <div class="metadata">
      <a [href]="topPost.link"
         target="_blank">{{topPost.username}}</a>
      <div class="icon-container">
        <i class="post-head-soc-icon"
           [ngClass]="{
              'ssi-x-logo': topPost.type === 2,
              'fa-facebook': topPost.type === 3,
              'fa-instagram': topPost.type === 12,
              'fa': topPost.type !== 2,
              'ssi': topPost.type === 2
            }"></i>
      </div>
      <div class="timestamp">
        <span>{{createdAtDate(topPost.created_at)}}</span> &nbsp;&nbsp;|&nbsp;&nbsp; <span>{{createdAtTime(topPost.created_at)}}</span>
      </div>
    </div>

    <div class="content">
      <p *ngIf="topPost.content"
         [innerHTML]="topPost.content | linkify"></p>
    </div>
  </div>

  <div class="right">
    <div class="gallery"
         *ngIf="topPost.media.length">
      <ng-container *ngFor="let mediaItem of topPost.media | slice:0:2 | reverse; let i=index">
        <ng-container *ngTemplateOutlet="mediaItemTemplate; context: {mediaItem: mediaItem, mediaItemIndex: i }"></ng-container>
      </ng-container>

      <div class="media-items-count"
           *ngIf="topPost.media.length > 1">
        <span>{{topPost.media.length - 1}}+</span>
      </div>
    </div>

    <ng-template #mediaItemTemplate
                 let-mediaItem="mediaItem"
                 let-mediaItemIndex="mediaItemIndex">
      <img *ngIf="mediaItem.type === 'image'"
           [src]="mediaItem.url"
           [class.stacked]="mediaItemIndex > 0"
           [style.top]="(mediaItemIndex * 7) + 'px'"
           [style.left]="(mediaItemIndex * 7) + 'px'">

      <div *ngIf="mediaItem.type !== 'image'"
           class="video-icon-container"
           [class.stacked]="mediaItemIndex > 0"
           [style.top]="(mediaItemIndex * 7) + 'px'"
           [style.left]="(mediaItemIndex * 7) + 'px'">
        <i class="ssi ssi-video-circle"></i>
      </div>
    </ng-template>
  </div>
</div>