import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../../store';
import { ApiService } from '../../api.service';
import { ThemeDetector } from './theme-detector.model';
import { DetectedTheme } from './detected-theme.model';

export interface DetectedThemesResponse {
  detected_themes: DetectedTheme[];
  pagination: {
    next: string;
  };
}

@Injectable({ providedIn: 'root' })
export class ThemeDetectorsService {
  store = new Store<ThemeDetector>(ThemeDetector);
  endpoint = `${this.api.url}/insights/insightsThemeDetector`;
  detectedThemesEndpoint = `${this.api.url}/insights/insightsDetectedThemes`;

  constructor(protected api: ApiService) {}

  getAll(opts = { refreshStore: false }): Promise<ThemeDetector[]> {
    if (this.store.value.length && !opts.refreshStore) {
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(this.endpoint)
      .pipe(
        map((response: any[]) => {
          this.store.value = response;
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  create(themeDetector: ThemeDetector): Promise<ThemeDetector> {
    return this.api
      .post(this.endpoint, themeDetector)
      .pipe(
        map((response: any) => {
          themeDetector.id = response.id;
          this.store.add(themeDetector);
          return this.store.find(themeDetector.id);
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  update(themeDetector: ThemeDetector): Promise<ThemeDetector> {
    return this.api
      .put(this.endpoint, themeDetector, {
        params: { id: themeDetector.id }
      })
      .pipe(
        map((response: any) => {
          this.store.update(themeDetector);
          return this.store.find(themeDetector.id);
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  delete(id: number): Promise<{ success: boolean }> {
    return this.api
      .delete(this.endpoint, {
        params: { id }
      })
      .pipe(
        map((response: any) => {
          this.store.remove(id);
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  getDetectedThemes(
    theme_detector_id: number,
    page: string | null
  ): Promise<DetectedThemesResponse> {
    // const detectedThemes = [
    //   {
    //     ai_theme: 'bla blaaa someting',
    //     first_seen_at: '2342342342',
    //     first_seen_size: 45
    //   }
    // ];
    // return Promise.resolve({
    //   detected_themes: detectedThemes,
    //   pagination: {
    //     next: null
    //   }
    // } as any);

    return this.api
      .get(this.detectedThemesEndpoint, {
        params: {
          theme_detector_id,
          page: page || ''
        }
      })
      .pipe(
        map((response: DetectedThemesResponse) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.detectedThemesEndpoint))
      )
      .toPromise();
  }
}
