import {
  Component,
  Inject,
  ContentChildren,
  QueryList,
  Input,
  OnInit,
  AfterContentInit,
  forwardRef
} from '@angular/core';

import { TemplateSelectorDirective } from '../../../directives/template-selector/template-selector.directive';
import { mapToIterable } from '../../../utils';

import { TreetableComponent } from '../treetable.component';
import { ColumnTemplates } from '../shared/constants';

@Component({
  selector: 'ssi-column',
  template: ``
})
export class ColumnComponent implements OnInit, AfterContentInit {
  @Input() field: string | Array<string>;
  @Input() header = '';
  @Input() tooltip: string;
  /* Initial width. Expects string - percetage, e.g. '20%'. The rest of the columns will split the rest of the available space. */
  @Input() width = '';
  @Input() expander = false;
  @Input() sortable = true;
  @Input() sortComparator: (a, b) => number;
  @Input() sortCollator: Intl.Collator;
  @Input() templateRefs: any = {};
  @Input() staticCtx: any = {};
  @Input() rowExpandableIndicatorProperty = '';

  @ContentChildren(TemplateSelectorDirective)
  templateList: QueryList<TemplateSelectorDirective>;

  id = '';
  dragged = false;

  // storable/inheritable properties
  metas = {
    width: {
      value: ''
    },
    position: {
      value: -1
    },
    visibility: {
      value: true
    },
    sortOrder: {
      value: 0
    },
    sortIndex: {
      value: -1
    }
  };

  constructor(
    @Inject(forwardRef(() => TreetableComponent))
    private _dtcRef: TreetableComponent
  ) {}

  ngOnInit() {
    if (!this.expander && !this._isFieldInputParamValid()) {
      throw new Error(`Mandatory parameter is missing or invalid: 'field'.`);
    }

    if (this.sortable) {
      if (!this.sortCollator) {
        this.sortCollator = new Intl.Collator(undefined, {
          sensitivity: 'accent',
          numeric: true
        });
      } else {
        if (!(this.sortCollator instanceof Intl.Collator)) {
          throw new Error(`Invalid parameter: 'sortCollator'.`);
        }
      }
    }

    this._setId();
  }

  ngAfterContentInit() {
    this._collectTemplateRefs();
  }

  toggle(): void {
    this._dtcRef.toggleColumnVisibility(this);
  }

  sort(event: any): void {
    this._dtcRef.sortColumn(this, false, event);
  }

  isVisible(): boolean {
    return this.metas.visibility.value;
  }

  isSorted(): boolean {
    return this.metas.sortOrder.value !== 0;
  }

  isReorderable(): boolean {
    return !this.expander;
  }

  private _setId(): void {
    this.id =
      this.expander && !this._isFieldInputParamValid()
        ? 'expander'
        : Array.isArray(this.field)
        ? this.field.join(',')
        : this.field;
  }

  private _collectTemplateRefs(): void {
    this.templateList.toArray().forEach((t: TemplateSelectorDirective) => {
      if (!ColumnTemplates[t.selector]) {
        console.warn(
          `Unknown template type: ${
            t.selector
          }. Possible value/s: ${mapToIterable(ColumnTemplates).join(', ')}.`
        );
        return;
      }

      this.templateRefs[t.selector] = t.templateRef;
    });
  }

  private _isFieldInputParamValid(): boolean {
    const isFieldArrayValid = (field: Array<string>): boolean => {
      if (!Array.isArray(field) || !field.length) {
        return false;
      }

      for (const filedSegment of field) {
        if (!filedSegment || typeof filedSegment !== 'string') {
          return false;
        }
      }

      return true;
    };

    if (
      !this.field ||
      (typeof this.field !== 'string' && !Array.isArray(this.field)) ||
      (Array.isArray(this.field) && !isFieldArrayValid(this.field))
    ) {
      return false;
    }

    return true;
  }
}
