<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Manage Teams</h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title"><i class="fa fa-users"></i> <span translate="TEAM_SETTINGS"></span></h3>
      <div class="pull-right">
        <button
          class="btn btn-primary"
          (click)="newTeam = {}"
          *ngIf="!newTeam">
          <i class="fa fa-plus"></i> <span translate="NEW_TEAM"></span>
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body" *ngIf="newTeam">
      <form class="well col-sm-6 col-md-offset-3" #newTeamForm="ngForm" name="newTeamForm">
        <div class="form-group">
          <label translate="TEAM_NAME"></label>
          <div class="controls">
            <input
              type="text"
              [(ngModel)]="newTeam.name"
              placeholder="{{ 'GROUP_NAME' | translate }}"
              name="groupName"
              class="form-control"
              required>
          </div>
        </div>
        <div class="form-group">
          <label translate="TEAM_DESCRIPTION"></label>
          <div class="controls">
            <textarea
              [(ngModel)]="newTeam.description"
              name="groupDesc"
              placeholder="{{ 'GROUP_DESCRIPTION' | translate }}"
              class="form-control">
            </textarea>
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-success"
            [disabled]="!newTeamForm.valid"
            (click)="saveTeam(newTeam)">
            <i class="fa fa-floppy-o"></i> <span translate="CREATE_TEAM"></span>
          </button>
          <button
            (click)="newTeam = null"
            class="btn btn-default"
            translate="CANCEL">
          </button>
        </div>
      </form>
    </div>
    <table class="table table-striped" *ngIf="!newTeam">
      <thead>
        <tr>
          <th translate="NAME"></th>
          <th translate="DESCRIPTION"></th>
          <th>
            <div class="input-group pull-right" style="width: 300px">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input
                class="form-control"
                placeholder="{{ 'SEARCH' | translate }}"
                type="search"
                [(ngModel)]="searchText"
                (ngModelChange)="updateTeamsTable()">
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let team of teams | sortBy: 'name' trackBy: trackById">
          <td>
            <div *ngIf="team.id !== teamEditing?.id">{{ team.name }}</div>
            <div *ngIf="team.id === teamEditing?.id">
              <input
                type="text"
                [(ngModel)]="teamEditing.name"
                class="form-control"
                required>
            </div>
          </td>
          <td>
            <div *ngIf="team.id !== teamEditing?.id">{{ team.description }}</div>
            <div *ngIf="team.id === teamEditing?.id">
              <textarea
                [(ngModel)]="teamEditing.description"
                class="form-control">
              </textarea>
            </div>
          </td>
          <td class="text-right">
            <div *ngIf="team.id !== teamEditing?.id">
              <button
                class="btn btn-sm btn-primary"
                (click)="editTeam(team)"
                *ngIf="!team.is_admins">
                <i class="fa fa-pencil-square-o"></i> <span translate="EDIT"></span>
              </button>
              <button
                class="btn btn-sm btn-danger"
                *ngIf="!team.is_admins"
                (click)="deletePopup(team)">
                <i class="fa fa-times"></i> <span translate="DELETE"></span>
              </button>
              <a
                class="btn btn-default btn-sm"
                uiSref="auth.manageTeam"
                [uiParams]="{teamId: team.id}">
                <i class="fa fa-lock"></i> <span>Users &amp; Permissions</span>
              </a>
            </div>
            <div *ngIf="team.id === teamEditing?.id">
              <button
                class="btn btn-sm btn-success"
                [disabled]="!teamEditing?.name"
                (click)="saveTeam(team, teamEditing)">
                <i class="fa fa-save"></i> <span translate="SAVE"></span>
              </button>
              <button
                class="btn btn-sm btn-default"
                (click)="teamEditing = null">
                <i class="fa fa-times"></i> <span translate="CANCEL"></span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
