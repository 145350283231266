import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsComponent } from './permissions.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { ReplaceModule } from '../../pipes/replace/replace.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownModule.forRoot(),
    TooltipModule,
    ReplaceModule
  ],
  declarations: [PermissionsComponent],
  exports: [PermissionsComponent]
})
export class PermissionsModule {}
