import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrloComponentsModule } from 'orlo-components';

import { ReduceImageSizePromptComponent } from './reduce-image-size-prompt.component';

@NgModule({
  imports: [CommonModule, OrloComponentsModule],
  exports: [ReduceImageSizePromptComponent],
  declarations: [ReduceImageSizePromptComponent],
  entryComponents: [ReduceImageSizePromptComponent]
})
export class ReduceImageSizePromptModule {}
