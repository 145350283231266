import './datetime-modal.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-datetime-modal',
  templateUrl: './datetime-modal.component.html'
})
export class DatetimeModalComponent {
  @Input() title: string;
  @Input() info: string;
  schedule;

  constructor(public activeModal: NgbActiveModal) {}
}
