<div
  *ngIf="priority"
  class="inbox-priority-container"
  [class.single-value]="showSingleValue"
>
  <div
    *ngIf="type === 'dot'"
    class="inbox-priority-dot P{{priority}}"
  >
  </div>

  <div
    *ngIf="type === 'badge'"
    class="inbox-priority-badge P{{priority}} {{size}}"
  >
    <span>P{{priority}}</span>
  </div>

  <div
    *ngIf="type === 'text'"
    class="inbox-priority-text"
    [ssiTooltip]="tooltipText"
    [tooltipWindowClass]="'tooltip-chunky'"
    [tooltipAppendToBody]="true"
  >
    <div class="inbox-priority-dot P{{priority}}"></div>
    <span>Priority 0{{priority}}</span>
  </div>
</div>
