<div class="no-results size-{{size}}">
  <i *ngIf="icon !== 'spinner'"
     class="ssi {{icon}}"></i>

  <ssi-spinner *ngIf="icon === 'spinner'"
               [visible]="true"></ssi-spinner>

  <p class="title">{{title}}</p>
  <p class="desc"
     *ngIf="description">{{description}}</p>

  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>