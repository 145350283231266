import './response-times.component.scss';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-response-times',
  templateUrl: './response-times.component.html',
  styles: []
})
export class ResponseTimesComponent implements OnInit {
  @Input() chartData;
  @Input() socialAccounts;
  @Input() activityStats;
  chartNetworks;

  constructor() {}

  ngOnInit() {
    this.chartNetworks = this.getChartNetworks();
  }

  getChartNetworks() {
    const networksInChart = this.chartData.series[0].data.map(
      (data) => data.name
    );
    return networksInChart.map((accountName) => {
      return this.socialAccounts.find((account) => {
        const {
          accountTypeName,
          publishKey,
          accountTypeGroupName
        } = account.socialNetwork;
        const networkNames = [
          (accountTypeName || '').toUpperCase(),
          (publishKey || '').toUpperCase(),
          (accountTypeGroupName || '').toUpperCase()
        ];
        return networkNames.includes(accountName.toUpperCase());
      });
    });
  }
}
