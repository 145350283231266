import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPartial'
})
export class FilterPartialPipe implements PipeTransform {
  transform(
    items: Array<any>,
    filter: { [key: string]: any } | any // Function, tslint didn't accept it
  ): Array<any> {
    if (typeof filter === 'function') {
      return items && items.filter((item) => filter(item));
    } else if (Object.values(filter)[0] == null) {
      return items;
    } else {
      return (
        items &&
        items.filter((item) =>
          Object.keys(filter).find((key) =>
            item[key].toLowerCase().includes(filter[key].toLowerCase())
          )
        )
      );
    }
  }
}
