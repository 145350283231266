import './add-custom-provider-modal.component.scss';
import { Component, OnInit } from '@angular/core';
import { AccountModel, API, Account } from '@ui-resources-angular';
import { PopupService } from '../../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncTrackerFactory } from 'angular-async-tracker';

export interface TwilioNumber {
  name: string;
  number: string;
  sid: string;
  type: string;
}

@Component({
  selector: 'ssi-add-custom-provider-modal',
  templateUrl: './add-custom-provider-modal.component.html'
})
export class AddCustomProviderModalComponent {
  provider: any;
  name: string;
  authCredentials = {
    apiKey: '',
    accountSid: '',
    secret: ''
  };
  chosenNumber: TwilioNumber;
  linkedNumbers: TwilioNumber[];
  needsAccountRegistration = false;
  webhookConfig: any;
  accountCreated = false;

  loadingTracker = this.asyncTracker.create();

  confirmationText: string;

  constructor(
    private api: API,
    private popup: PopupService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private asyncTracker: AsyncTrackerFactory
  ) {}

  async find() {
    try {
      const response = await this.getNumbers();
      if (response.status === 200) {
        this.linkedNumbers = response.data;
      }
    } catch (error) {
      this.needsAccountRegistration = true;
      console.error(error);
    }
  }

  getNumbers() {
    return this.api.post('account/twilioList', {
      account_sid: this.authCredentials.accountSid,
      api_key: this.authCredentials.apiKey,
      api_secret: this.authCredentials.secret
    });
  }

  async chooseNumber(number: TwilioNumber) {
    this.chosenNumber = number;

    try {
      const response = await this.createAccount(this.provider.id);
      if (this.provider.id === 'whatsapp') {
        this.webhookConfig = response.data;
      }
      this.accountCreated = true;
    } catch (error) {
      console.error(error);
    }
  }

  createAccount(type: 'whatsapp' | 'sms') {
    return this.api.post('account/twilioCreate', {
      account_sid: this.authCredentials.accountSid,
      api_key: this.authCredentials.apiKey,
      api_secret: this.authCredentials.secret,
      type,
      sid: this.chosenNumber.sid,
      name: this.name
    });
  }
}
