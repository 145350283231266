import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailToModalComponent } from './email-to-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';
import { EmojiTextModule } from '../../directives/emoji-text/emoji-text.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule,
    OrloComponentsModule,
    EmojiTextModule
  ],
  declarations: [EmailToModalComponent],
  exports: [EmailToModalComponent],
  providers: []
})
export class EmailToModalModule {}
