import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OrloComponentsModule } from 'orlo-components';
import { ColorPickerModule } from 'ngx-color-picker';

import { CompetitorAnalysisComponent } from './competitor-analysis.component';
import { CompetitorReportListComponent } from './build/report/report-list/competitor-report-list.component';
import { CompetitorListComponent } from './build/competitor/competitor-list/competitor-list.component';
import { CreateEditCompetitorComponent } from './build/competitor/create-edit-competitor/create-edit-competitor.component';
import { CompetitorReportComponent } from './report/competitor-report.component';
import { CreateEditReportComponent } from './build/report/create-edit-report/create-edit-report.component';

import { CommonAnalyticsModule } from '../common/common-analytics.module';
import { TopPostsSectionModule } from './report/top-posts-section/top-posts-section.module';
import { TopTagsListBoxModule } from './report/top-tags-list-box/top-tags-list-box.module';

import { ListSelectModule } from '../../../../common/components/list-select/list-select.module';
import { FilterModule } from '../../../../common/pipes/filter/filter.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { OffClickModule } from '../../../../common/directives/off-click/off-click.module';
import { SpinnerModule } from '../../../../common/components/spinner/spinner.module';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';
import { ProgressBarModule } from '../../../../common/components/progress-bar/progress-bar.module';
import { HighchartControlsModule } from '../../marketing/index/highchart-controls/highchart-controls.module';
import { LinkifyModule } from '../../../../common/pipes/linkify/linkify.module';
import { EnlargeImageModule } from '../../../../common/directives/enlarge-image/enlarge-image.module';
import { ReverseModule } from '../../../../common/pipes/reverse/reverse.module';
import { ReportHeaderModule } from '../common/report-header/report-header.module';
import { ReportSidebarModule } from '../common/report-sidebar/report-sidebar.module';
import { StatGraphModule } from '../common/stat-graph/stat-graph.module';
import { StatChangeModule } from '../common/stat-change/stat-change.module';
import { MenuItemModule } from '../common/menu-item/menu-item.module';
import { MedalScoreModule } from '../common/medal-score/medal-score.module';
import { FilterByStringModule } from '../../../../common/pipes/filter-by-string/filter-by-string.module';
import { IconModule } from '../../../../common/components/icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.competitorAnalysis',
          url: '/benchmarking',
          component: CompetitorAnalysisComponent
        },
        {
          name: 'auth.analytics.competitorAnalysis.index',
          url: '/overview',
          component: CompetitorReportListComponent,
          resolve: CompetitorReportListComponent.resolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.competitorList',
          url: '/benchmarking/list',
          component: CompetitorListComponent,
          resolve: CompetitorListComponent.resolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.competitorCreate',
          url: '/benchmarking/create',
          component: CreateEditCompetitorComponent,
          resolve: CreateEditCompetitorComponent.competitorsResolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.competitorEdit',
          url: '/benchmarking/edit/:id',
          component: CreateEditCompetitorComponent,
          resolve: CreateEditCompetitorComponent.competitorsResolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.brandCreate',
          url: '/brand/create',
          component: CreateEditCompetitorComponent,
          resolve: CreateEditCompetitorComponent.brandsResolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.brandEdit',
          url: '/brand/edit/:id',
          component: CreateEditCompetitorComponent,
          resolve: CreateEditCompetitorComponent.brandsResolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.report',
          url: '/report?:reportId:reportName',
          component: CompetitorReportComponent,
          resolve: CompetitorReportComponent.resolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.reportCreate',
          url: '/report/create',
          component: CreateEditReportComponent,
          resolve: CreateEditReportComponent.resolve
        },
        {
          name: 'auth.analytics.competitorAnalysis.reportEdit',
          url: '/report/edit/:reportId',
          component: CreateEditReportComponent,
          resolve: CreateEditReportComponent.resolve
        }
      ]
    }),
    FormsModule,
    ListSelectModule,
    ReactiveFormsModule,
    OrloComponentsModule,
    ColorPickerModule,
    FilterModule,
    TooltipModule,
    OffClickModule,
    SpinnerModule,
    FormsModule,
    BigNumberModule,
    HighchartModule,
    ProgressBarModule,
    HighchartControlsModule,
    LinkifyModule,
    EnlargeImageModule,
    ReverseModule,
    CommonAnalyticsModule,
    TopPostsSectionModule,
    TopTagsListBoxModule,
    ReportHeaderModule,
    ReportSidebarModule,
    StatGraphModule,
    StatChangeModule,
    MenuItemModule,
    MedalScoreModule,
    FilterByStringModule,
    IconModule
  ],
  declarations: [
    CompetitorAnalysisComponent,
    CompetitorReportListComponent,
    CompetitorListComponent,
    CreateEditCompetitorComponent,
    CompetitorReportComponent,
    CreateEditReportComponent
  ],
  providers: [DatePipe],
  entryComponents: [CompetitorAnalysisComponent]
})
export class CompetitorAnalysisModule {}
