import {
  Directive,
  Input,
  HostListener,
  Output,
  EventEmitter
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FilestackFile,
  FilestackService
} from '../../services/filestack/filestack.service';
import { GifSearchModalComponent } from './gif-search-modal.component';

@Directive({
  selector: '[ssiGifSearch]'
})
export class GifSearchDirective {
  @Output()
  onGifFileUploaded: EventEmitter<FilestackFile> = new EventEmitter<any>();

  constructor(private filestack: FilestackService, private modal: NgbModal) {}

  @HostListener('click', ['$event'])
  onClick(e: Event): void {
    this.openDialog();
  }

  async addGifToFilestack(gifUrl: string) {
    try {
      const filestackClient = await this.filestack.getClient();
      const file = await filestackClient.storeURL(gifUrl);
      console.error('Giphy upload (filestack): Size ...'); // only for trackjs
      this.onGifFileUploaded.emit(file);
    } catch (error) {
      console.error('Gif saving error:', error);
    }
  }

  async openDialog() {
    const gifSearchModal = this.modal.open(GifSearchModalComponent, {
      windowClass: 'modal rounded-corners-15 gif-search-modal',
      centered: true
    });

    const gifUrl = await gifSearchModal.result;
    this.addGifToFilestack(gifUrl);
  }
}
