import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VideoMetricsComponent } from './video-metrics.component';
import { StatChangeModule } from '../stat-change/stat-change.module';
import { BigNumberModule } from '../../../../../common/components/big-number/big-number.module';
import { IconModule } from '../../../../../common/components/icon/icon.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { FactOverviewModule } from '../fact-overview/fact-overview.module';
import { SocialNetworkSelectModule } from '../../common/social-network-select/social-network-select.module';

@NgModule({
  declarations: [VideoMetricsComponent],
  imports: [
    CommonModule,
    BigNumberModule,
    IconModule,
    StatChangeModule,
    TooltipModule,
    FactOverviewModule,
    SocialNetworkSelectModule
  ],
  exports: [VideoMetricsComponent]
})
export class VideoMetricsModule {}
