import './disapprovals.component.scss';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-disapprovals',
  templateUrl: './disapprovals.component.html',
  styles: []
})
export class DisapprovalsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
