import './top-post-view.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'ssi-top-post-view',
  templateUrl: './top-post-view.component.html',
  styles: []
})
export class TopPostViewComponent implements OnInit {
  @Input() topPost: any;

  constructor() {}

  ngOnInit() {}

  createdAtDate(postDate) {
    return moment(postDate).format('DD MMM YYYY');
  }

  createdAtTime(postDate) {
    return moment(postDate).format('HH:mm');
  }
}
