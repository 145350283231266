<br>
<div class="sentiment-progressbar" *ngIf="total > 0">
  <div
    class="sentiment-bar sentiment-bar-positive"
    [style.width.%]="(((positiveTotal + semiPositiveTotal) / total) * 100).toFixed(0)"
    [ssiTooltip]="'_AMOUNT__POSITIVE' | translate:{amount: (positiveTotal + semiPositiveTotal | bigNumber)}"
    *ngIf="positiveTotal + semiPositiveTotal > 0">
    <i class="fa fa-smile-o"></i>
    <span
      translate="_AMOUNT__POSITIVE"
      [translateParams]="{amount: (positiveTotal + semiPositiveTotal | bigNumber)}">
    </span>
  </div>
  <div
    class="sentiment-bar sentiment-bar-neutral"
    [style.width.%]="((neutralTotal / total) * 100).toFixed(0)"
    [ssiTooltip]="'_AMOUNT__NEUTRAL' | translate:{amount: (neutralTotal | bigNumber)}">
    <i class="fa fa-meh-o"></i>
    <span
      translate="_AMOUNT__NEUTRAL"
      [translateParams]="{amount: (neutralTotal | bigNumber)}">
    </span>
  </div>
  <div class="sentiment-bar sentiment-bar-negative"
    [style.width.%]="(((negativeTotal + semiNegativeTotal) / total) * 100).toFixed(0)"
    [ssiTooltip]="'_AMOUNT__NEGATIVE' | translate:{amount: (negativeTotal + semiNegativeTotal | bigNumber)}"
    *ngIf="negativeTotal + semiNegativeTotal > 0">
    <i class="fa fa-frown-o"></i>
    <span
      translate="_AMOUNT__NEGATIVE"
      [translateParams]="{amount: (negativeTotal + semiNegativeTotal | bigNumber)}">
    </span>
  </div>
</div>
