import './top-urls.component.scss';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-top-urls',
  templateUrl: './top-urls.component.html',
  styles: []
})
export class TopUrlsComponent {
  @Input() topURLs: { url: string; visits: number }[];
  @Input() countLabel?: string = 'Total visits';

  constructor() {}
}
