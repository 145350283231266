import { Applet } from './applet';
import { AppletCollection as APIAppletCollection } from './api-applet-interfaces';
import { IftttService } from '../ifttt-service-model/ifttt-service-model';
import {
  ActionConfig,
  TriggerConfig
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class AppletCollection {
  applets: Applet[];

  constructor(
    public version: number,
    public service: IftttService,
    apiCollection: APIAppletCollection,
    triggers: TriggerConfig[],
    actions: ActionConfig[],
    injector: Injector
  ) {
    this.applets = apiCollection.applets.map(
      (apiApplet) => new Applet(this, apiApplet, triggers, actions, injector)
    );
  }

  serialise(): APIAppletCollection {
    return {
      type: '@AppletCollection',
      triggerService: this.service.name,
      applets: this.applets.map((applet) => applet.serialise())
    };
  }
}
