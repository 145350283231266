import { Component } from '@angular/core';

@Component({
  selector: 'ssi-ad-stats',
  templateUrl: './ad-stats.component.html',
  styles: []
})
export class AdStatsComponent {
  constructor() {}
}
