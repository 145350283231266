<div class="menu-container"
     (ssiOffClick)="close()">
    <div #headRef
         class="menu-head"
         *ngIf="!headless"
         (click)="open($event)">
        <ng-container *ngIf="templateRefs[MenuTemplates.menuHead] else defaultMenuHeadTemplateRef">
            <ng-container *ngTemplateOutlet="templateRefs[MenuTemplates.menuHead]; context: context"></ng-container>
        </ng-container>

        <ng-template #defaultMenuHeadTemplateRef>
            <i class="ssi ssi-outbox-menu menu-trigger-icon"></i>
        </ng-template>
    </div>
    <div #bodyRef
         class="menu-body"
         [ngStyle]="bodyStyle"
         [@fadeIn]
         [hidden]="!visible && onlyHideMenuBody"
         *ngIf="visible || onlyHideMenuBody">
        <ng-container *ngTemplateOutlet="templateRefs[MenuTemplates.menuBody]; context: context"></ng-container>
    </div>
</div>