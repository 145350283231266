<div class="message-link-preview"
     [ngClass]="{'message-link-preview-self-penned': selfPenned}">
    <div class="message-link-preview-thumb">
        <img *ngIf="thumb && validThumb"
             [src]="thumb"
             (error)="handleThumbError()">
    </div>
    <div class="message-link-preview-body">
        <a class="message-link-preview-title"
           target="_blank"
           rel="noopener noreferrer"
           [href]="url"
           [innerHTML]="title"></a>
        <p class="message-link-preview-subtitle"
           [innerHTML]="description"></p>
    </div>
</div>