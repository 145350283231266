<div class="settings container-flex">
  <ng-container *ngIf="activeUrl === 'auth.settings.index'; else settingsPageMenuTemplate">
    <div
      class="settings-nav page-menu"
      [ngSwitch]="activeUrl"
    >
      <h1 *ngSwitchDefault>All Settings</h1>
      <h1 *ngSwitchCase="'auth.settings.mysettings'">My Settings</h1>
      <h1 *ngSwitchCase="'auth.settings.accountsettings'">Account Settings</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings'">Company Settings</h1>

      <ul>
        <li><i
            class="ssi ssi-dashboard"
            uiSref=".index"
            [ngClass]="{ active: activeUrl.includes('index') }"
          ></i></li>
        <li><i
            class="ssi ssi-mysettings"
            uiSref=".mysettings"
            [ngClass]="{ active: activeUrl.includes('mysettings') }"
          ></i></li>
        <li><i
            class="ssi ssi-websitewidget"
            uiSref=".accountsettings"
            [ngClass]="{ active: activeUrl.includes('accountsettings') }"
          ></i></li>
        <li><i
            class="ssi ssi-companysettings"
            uiSref=".companysettings"
            [ngClass]="{ active: activeUrl.includes('companysettings') }"
          ></i></li>
      </ul>

    </div>
  </ng-container>
  <ng-template #settingsPageMenuTemplate>
    <div
      class="settings-nav"
      [ngSwitch]="activeUrl"
    >
      <orlo-button
        leadingIcon="ssi ssi-chevron-left"
        [state]="'primary'"
        textColor="#14bae3"
        bgColor="#f8f9fc"
        [text]="'Back'"
        (btnClick)="return()"
      ></orlo-button>

      <ul>
        <li><i
            class="ssi ssi-dashboard"
            uiSref=".index"
            [ngClass]="{ active: activeUrl.includes('index') }"
          ></i></li>
        <li><i
            class="ssi ssi-mysettings"
            uiSref=".mysettings"
            [ngClass]="{ active: activeUrl.includes('mysettings') }"
          ></i></li>
        <li><i
            class="ssi ssi-websitewidget"
            uiSref=".accountsettings"
            [ngClass]="{ active: activeUrl.includes('accountsettings') }"
          ></i></li>
        <li><i
            class="ssi ssi-companysettings"
            uiSref=".companysettings"
            [ngClass]="{ active: activeUrl.includes('companysettings') }"
          ></i></li>
      </ul>

      <h1 *ngSwitchDefault>All settings</h1>
      <h1 *ngSwitchCase="'auth.settings.mysettings'">My settings</h1>
      <h1 *ngSwitchCase="'auth.settings.accountsettings'">Account settings</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings'">Company settings</h1>
      <h1 *ngSwitchCase="'auth.settings.mysettings.mydetails'">My details</h1>
      <h1 *ngSwitchCase="'auth.settings.mysettings.mypassword'">My password</h1>
      <h1 *ngSwitchCase="'auth.settings.mysettings.mypreferences'">My preferences</h1>
      <h1 *ngSwitchCase="'auth.settings.mysettings.loginactivity'">Login activity</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings.companylockdown'">Company lockdown</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings.publishSettings'">Publish settings</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings.socialPushMode'">Social push mode</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings.internationalization'">Internationalization</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings.manageWebhooks'">Manage webhooks</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings.chatbot'">Chat Bot</h1>
      <h1 *ngSwitchCase="'auth.settings.companysettings.automatedReports'">Automated Reports</h1>
    </div>
  </ng-template>

  <ui-view></ui-view>
</div>