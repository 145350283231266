
<ng-container
  *ngIf="isAgentOffline"
>
  <div
    class="unavailable-unassigned-chats"
  >
    <div class="encouragement">
      <i class="ssi ssi-sherlock-microcopy encouragement-icon"></i>

      <span
        class="encouragement-message"
        translate="YOU_ARE_NOT_ONLINE_AND_CANT_TAKE_ON_NEW_CHATS"
      >
      </span>
    </div>

    <button
      [disabled]="true"
      class="btn"
      type="button"
    >
      <span
        translate="SORRY_ABOUT_THAT"
      >
      </span>
    </button>
  </div>
</ng-container>
