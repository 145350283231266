import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { FallbackImageModule } from '../../../../common/directives/fallback-image/fallback-image.module';
import { ReverseModule } from '../../../../common/pipes/reverse/reverse.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { EnlargeImageModule } from '../../../../common/directives/enlarge-image/enlarge-image.module';
import { LinkifyModule } from '../../../../common/pipes/linkify/linkify.module';

import { MessageSharePreviewComponent } from './message-share-preview.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SocialNetworkIconModule,
    FallbackImageModule,
    ReverseModule,
    TooltipModule,
    EnlargeImageModule,
    LinkifyModule
  ],
  declarations: [MessageSharePreviewComponent],
  exports: [MessageSharePreviewComponent]
})
export class MessageSharePreviewModule {}
