<div class="confirmation-modal-container">
  <i class="ssi ssi-times close-icon"
     (click)="activeModal.dismiss()"></i>

  <div class="head">
    <h3>{{title}}</h3>
  </div>

  <div class="body">
    <p class="info">{{info}}</p>
  </div>

  <div class="foot">
    <div class="left">
      <div class="addl-footer-content-container"
           *ngIf="footerTemplate">
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </div>
    </div>

    <div class="right">
      <ng-container *ngIf="!negativeConfirmation">
        <button *ngIf="cancelButton"
                class="g-btn g-btn-secondary bg-white"
                (click)="activeModal.close(false)"><span>{{cancelButton}}</span></button>

        <button *ngIf="confirmButton"
                class="g-btn"
                (click)="activeModal.close(true)"><span>{{confirmButton}}</span></button>
      </ng-container>

      <ng-container *ngIf="negativeConfirmation">
        <button class="g-btn"
                (click)="activeModal.close(true)"><span>{{confirmButton}}</span></button>

        <button class="g-btn g-btn-secondary bg-white"
                (click)="activeModal.close(false)"><span>{{cancelButton}}</span></button>
      </ng-container>
    </div>
  </div>
</div>