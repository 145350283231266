import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartControlsComponent } from './highchart-controls.component';
import flatpickr from 'flatpickr';
import { TranslateModule } from '@ngx-translate/core';
import { FlatpickrModule } from 'angularx-flatpickr';
import { FormsModule } from '@angular/forms';
import { AddSecondsModule } from '../../../../../common/pipes/add-seconds/add-seconds.module';

export function flatpickrFactory() {
  return flatpickr;
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlatpickrModule.forRoot(),
    AddSecondsModule,
    FormsModule
  ],
  declarations: [HighchartControlsComponent],
  exports: [HighchartControlsComponent]
})
export class HighchartControlsModule {}
