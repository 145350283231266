import { Injectable, Injector } from '@angular/core';
import axios from 'axios';
import { get } from 'lodash-es';
import { userAgent } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../popup/popup.service';
import { ServerService } from '../server/server.service';
import { UserModel } from '@ui-resources-angular';
import { AuthService } from '../auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../../components/confirmation-modal/confirmation-modal.component';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const browser = userAgent.getBrowser();
const isBuggyIE = browser.name === 'IE' && +browser.major === 10;

@Injectable()
export class ErrorHandlerService implements HttpInterceptor {
  constructor(
    // private injector: Injector,
    private translate: TranslateService,
    private popup: PopupService,
    private server: ServerService,
    private userModel: UserModel,
    private modal: NgbModal
  ) {}

  addAngularInterceptors() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        this.http(error, false);
        return Promise.reject(error);
      }
    );
  }

  async http(err, angularHttpClient?) {
    let statusCode = get(err, 'status') || get(err, 'response.status');

    if (
      typeof statusCode !== 'undefined' &&
      (angularHttpClient || err.config.autoError !== false)
    ) {
      if (statusCode === -1 && isBuggyIE) {
        statusCode = 401;
      }

      // const authService = this.injector.get(AuthService);

      switch (statusCode) {
        case 405:
          const { telephone } = await this.server.getSettings();
          this.popup.alert({
            isError: true,
            message: this.translate.instant(
              'YOUR_ACCOUNT_IS_CURRENTLY_DEACTIVATED_PLEASE_CONTACT_US_TO_ARRANGE_' +
                'YOUR_ACCOUNT_ACTIVATION__TELEPHONE_',
              { telephone }
            )
          });
          this.userModel.logout(); // .finally(() => authService.clearJWT());
          break;

        case 401:
          this.userModel.logout(); // .finally(() => authService.clearJWT());
          break;

        default:
          const httpErrorMessage =
            get(err, 'data.message') ||
            get(err, 'response.data.message') ||
            get(err, 'error.message');

          if (httpErrorMessage) {
            const confirmationModal = this.modal.open(
              ConfirmationModalComponent,
              {
                windowClass: 'orlo-modal'
              }
            );
            confirmationModal.componentInstance.icon = 'ssi ssi-fail-microcopy';
            confirmationModal.componentInstance.title = 'Oops! Something’s up';
            confirmationModal.componentInstance.info = httpErrorMessage;
            confirmationModal.componentInstance.confirmButton = `Okay thanks`;
          }
      }
    }
  }

  error(err) {
    const alertConfig = Object.assign({ isError: true }, err);
    return this.popup.alert(alertConfig);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        this.http(err, true);
        return throwError(err);
      })
    );
  }
}
