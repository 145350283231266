import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { DeviceService } from '../../services/device/device.service';
import moment from 'moment';

import { slideIn, slideOutSmooth } from '../../animations';
import {
  PUBLISHER_ACTIVE,
  PublisherActive,
  PublisherActiveParams,
  PublisherDisableOptions
} from '../publisher/publisher-active';
import { NotificationService } from '../../services/notification/notification.service';
import { WorkflowManagerService } from '../../services/workflow-manager/workflow-manager.service';
import {
  Account,
  AccountModel,
  BlockingWord,
  CampaignModel,
  OutboxMessageAttachmentType,
  OutboxMessageAttachmentVideoWithTitle,
  ImageRestrictions,
  MediaCategory,
  OutboxFileType,
  OutboxPublisher,
  User,
  UserModel,
  VideoRestrictions
} from '@ui-resources-angular';
import { CompanyService } from '../../services/company/company.service';
import {
  ComposerLayout,
  ComposerService,
  defaultComposerLayout
} from './composer.service';
import {
  Campaign,
  CampaignsService,
  getOrderedOpenCampaigns,
  OutboxTagsService,
  Tag
} from '../../services/api';
import { StorageService } from '../../services/storage';
import { AccordionComponent } from '../accordion/accordion.component';
import { get, orderBy } from 'lodash-es';
import { AccountTypeIdString, AccountTypeName } from '../../enums';
import {
  Draft,
  DraftExtra,
  DraftsLibraryService,
  DraftType
} from '../../../modules/auth/marketing/drafts-library/drafts-library.service';
import { FileUploadFile } from '../../directives/file-uploader/file-uploader.directive';
import {
  FilestackService,
  FilestackSources
} from '../../services/filestack/filestack.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import {
  SuggestedTextModalComponent,
  SuggestedTextModalResult
} from '../publisher/suggested-text-modal/suggested-text-modal.component';
import { ShareFirstCommentComponent } from '../share-first-comment/share-first-comment.component';
import { PopupService } from '../../services/popup/popup.service';
import { SaveDraftModalComponent } from '../save-draft-modal/save-draft-modal.component';
import { ComposerCancelModalComponent } from '../composer-cancel-modal/composer-cancel-modal.component';
import { groupBy } from '../../utils';
import { TargetingOptionsComponent } from './targeting-options/targeting-options.component';
import { FileUploaderService } from '../../directives/file-uploader/file-uploader.service';
import { QuoteRetweetModalComponent } from '../publisher/quote-retweet-modal/quote-retweet-modal.component';
import { FacebookShareModalComponent } from '../publisher/facebook-share-modal/facebook-share-modal.component';
import { PublisherSocialNetworkPreviewModalComponent } from '../publisher/publisher-social-network-preview-modal/publisher-social-network-preview-modal.component';
import { DatePipe } from '@angular/common';
import differenceInHours from 'date-fns/difference_in_hours';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ssi-composer',
  templateUrl: './composer.component.html',
  animations: [
    trigger('slideInOutComposer', [
      transition('void => *', [
        style({ opacity: 0, height: '*' }),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({ opacity: 0, height: '0' }))
      ])
    ])
  ]
})
export class ComposerComponent implements OnInit, OnDestroy {
  @ViewChild('draftSavedMessageTemplate')
  draftSavedMessageTemplate: TemplateRef<any>;

  post: OutboxPublisher;
  accounts: Account[] = [];
  campaigns: Campaign[] = [];
  allCampaigns: Campaign[] = [];
  ComposerLayout = ComposerLayout;
  layout: ComposerLayout = defaultComposerLayout;
  tempAnimationClass: 'from-left' | '';

  isActive = false;
  publisherActiveSubscription: Subscription;
  disable: PublisherDisableOptions;
  params: PublisherActiveParams;
  showPreview = false;
  requiresApproval = false;
  expiryTime: Date;
  // disablePublish = false;
  MediaCategory = MediaCategory;
  fileUploadSources: FilestackSources[];
  isDraftPublic = false;
  authUser: User;
  selectedAccountTypeIds: string[] = [];
  targetingEnabled = false;

  scheduledPostTimes: {
    time: Date;
    error: { message: string; key: string };
  }[] = [undefined];
  tags: Tag[] = [];
  selectedTags: Tag[] = [];
  isMobile = false;

  destroyed$ = new Subject<void>();
  postInitialised: boolean = true;

  loadingTrackers = {
    initial: this.asyncTrackerFactory.create(),
    publishing: this.asyncTrackerFactory.create(),
    savingDraft: this.asyncTrackerFactory.create()
  };

  @ViewChild('schedulePostsAccordion')
  schedulePostsAccordion: AccordionComponent;
  @ViewChild(ShareFirstCommentComponent)
  shareFirstCommentComponent: ShareFirstCommentComponent;

  @ViewChild('targetingOptionsComponent')
  targetingOptionsComponent: TargetingOptionsComponent;

  constructor(
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive,
    private modal: NgbModal,
    private popup: PopupService,
    private translate: TranslateService,
    public composerService: ComposerService,
    private filestackService: FilestackService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private asyncTrackerFactory: AsyncTrackerFactory,
    private workflowManager: WorkflowManagerService,
    private campaignsService: CampaignsService,
    private userModel: UserModel,
    private accountModel: AccountModel,
    private company: CompanyService,
    private blockingWord: BlockingWord,
    private draftsLibraryService: DraftsLibraryService,
    private fileUploaderService: FileUploaderService,
    private datePipe: DatePipe,
    private outboxTagsService: OutboxTagsService,
    private device: DeviceService
  ) {
    this.publisherActiveSubscription = this.publisherActive.subscribe(
      async (params) => {
        this.params = params;
        const promise = this.setupPost(params);
        this.loadingTrackers.initial.add(promise);
        try {
          await promise;
        } catch (e) {
          console.error(e);
        }
      }
    );

    this.loadUser();
  }

  private async loadUser() {
    this.authUser = await this.userModel.getAuthUser();
  }

  ngOnInit() {
    this.device.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        if (isMobile) {
          this.layout = this.ComposerLayout.Full;
        }
      });
  }

  changeLayout(layout: ComposerLayout) {
    if (
      this.layout === ComposerLayout.FixedLeft &&
      layout === ComposerLayout.Full
    ) {
      this.tempAnimationClass = 'from-left';
      setTimeout(() => {
        this.tempAnimationClass = '';
      }, 300);
    }
    this.layout = layout;
  }

  private async setupPost({
    isActive,
    create,
    edit,
    draft,
    stage,
    disable
  }: PublisherActiveParams) {
    this.postInitialised = false;
    this.targetingEnabled = false;

    console.log('should Init!!!');

    if (
      this.isActive === isActive &&
      this.layout !== ComposerLayout.Minimised
    ) {
      return;
    }
    console.log('should Init after!!!');
    if (this.layout === ComposerLayout.Minimised) {
      this.changeLayout(ComposerLayout.Full);
      return;
    }

    this.isActive = isActive;
    if (!this.isActive) {
      return;
    }

    this.disable = disable;

    const [
      accounts,
      campaigns,
      authUser,
      companyConfig,
      blockingWords
    ] = await Promise.all([
      this.accountModel.findAccounts(this.workflowManager.getCurrentId()),
      this.campaignsService.getAll(),
      this.userModel.getAuthUser(),
      this.company.getConfig(),
      this.blockingWord.getBlockingWords()
    ]);

    const blockingWordsRaw = blockingWords.words.map(
      (blockingWord) => blockingWord.word
    );

    if (companyConfig.only_use_managed_files) {
      this.fileUploadSources = [FilestackSources.CustomSource];
    } else {
      this.fileUploadSources = undefined; // use defaults
    }

    this.accounts = orderBy(accounts, ['account_type_name', 'name']).filter(
      (account) =>
        !account.socialNetwork.inboundInitiated &&
        account.account_type_id !== AccountTypeIdString.LiveChat
    );

    this.allCampaigns = campaigns;

    this.campaigns = getOrderedOpenCampaigns(campaigns)
      .filter((campaign) => !campaign.parent_id)
      .reduce((build, parent) => {
        let children = [];
        this.campaignsService
          .getChildren(parent.id)
          .then((childCampaigns) => (children = childCampaigns));
        return [...build, parent, ...getOrderedOpenCampaigns(children)];
      }, []);

    console.log('composer this.campaigns:', this.campaigns);

    this.post = new OutboxPublisher(
      this.accounts,
      authUser,
      companyConfig.use_utm_link_tracking,
      companyConfig.use_link_shortening,
      blockingWordsRaw,
      { replyToSocialId: create ? create.replyToSocialId : undefined },
      true
    );

    if (create) {
      if (!create.copy) {
        if (create.accounts) {
          this.post.accounts = [...create.accounts];
        }
        this.post.text = create.text;
        if (create.schedules) {
          create.schedules.forEach((date, index) => {
            this.updateSchedule(date, index);
          });
        }

        if (create.activeCampaignID && Array.isArray(this.campaigns)) {
          this.post.campaign = this.campaigns.find((item) => {
            const id = parseInt(item.id as any, 10);
            const passedId = parseInt(create.activeCampaignID as any, 10);
            return id === passedId;
          });
        }

        if (create.files) {
          console.log('create.files: ', create.files);
          const filestackClient = await this.filestackService.getClient();
          const filesAdded = create.files.map(async (file) => {
            // copy images across to filestack if added from the content generator etc
            if (!file.mimetype) {
              file = await filestackClient.storeURL(file.url);
              console.error('create.files: upload (filestack): Size'); // only for tracksjs
            }
            this.post.addFile({
              url: file.url,
              type: OutboxFileType.Image // TODO - handle videos
            });
          });
          await Promise.all(filesAdded);
        }
        if (create.quoteRetweet) {
          const modal = this.modal.open(QuoteRetweetModalComponent, {
            windowClass: 'modal-vertical orlo-modal-dark'
          });
          this.post.quoteRetweetUrl = create.quoteRetweet;
          modal.componentInstance.post = this.post;
        }
        if (create.shareId) {
          const modal = this.modal.open(FacebookShareModalComponent, {
            windowClass: 'modal-vertical'
          });
          this.post.shareId = create.shareId;
          modal.componentInstance.post = this.post;
        }
      } else {
        const copyMessage = await create.copy.toOutboxPublisherMessageFormat();

        const filesUrls = Array.isArray(create.copy.outbox_files)
          ? create.copy.outbox_files.map((f) => f.public_url)
          : [];
        const videoGifUrls = await OutboxPublisher.getVideoGifUrls(filesUrls);

        const originalPost = new OutboxPublisher(
          this.accounts,
          authUser,
          companyConfig.use_utm_link_tracking,
          companyConfig.use_link_shortening,
          blockingWordsRaw,
          { edit: copyMessage, videoGifUrls }
        );

        this.post.accounts = [...originalPost.accounts];
        this.post.text = originalPost.text;
        this.post.allMentions = originalPost.allMentions;
        this.post.deserializeAttachments(copyMessage, videoGifUrls);
        this.post.album.name = originalPost.album.name;
        this.post.requiresValidation = originalPost.requiresValidation;
        this.post.campaign = originalPost.campaign;
        this.post.youtubeVisibility = originalPost.youtubeVisibility;
        this.post.mediaCategory = originalPost.mediaCategory;
        this.post.scheduleFirstCommentToggled =
          originalPost.scheduleFirstCommentToggled;
        this.post.autoCommentByAccountId = originalPost.autoCommentByAccountId;
        this.post.targeting.Facebook = {
          ...originalPost.targeting.Facebook
        };
        this.post.targeting.LinkedIn = {
          ...originalPost.targeting.LinkedIn
        };
        this.post.targeting[AccountTypeName.Nextdoor] = {
          ...originalPost.targeting[AccountTypeName.Nextdoor]
        };
        this.post.targeting[AccountTypeName.NextdoorUS] = {
          ...originalPost.targeting[AccountTypeName.NextdoorUS]
        };

        if (create.copy.send_at) {
          const isScheduled =
            new Date(create.copy.send_at).getTime() - new Date().getTime() > 0;
          if (isScheduled) {
            this.updateSchedule(new Date(create.copy.send_at), 0);
          }
        }
        if (Array.isArray(originalPost.tags)) {
          this.post.tags = originalPost.tags;
        }
      }
    } else if (edit && edit.post) {
      const editMessage = await edit.post.toOutboxPublisherMessageFormat();
      if (edit.post.delete_at) {
        this.expiryDateChanged(edit.post.delete_at);
      }

      const filesUrls = Array.isArray(edit.post.outbox_files)
        ? edit.post.outbox_files.map((f) => f.public_url)
        : [];
      const videoGifUrls = await OutboxPublisher.getVideoGifUrls(filesUrls);

      this.post = new OutboxPublisher(
        this.accounts,
        authUser,
        companyConfig.use_utm_link_tracking,
        companyConfig.use_link_shortening,
        blockingWordsRaw,
        { edit: editMessage, videoGifUrls }
      );

      if (editMessage.youtube_visibility) {
        this.post.youtubeVisibility = editMessage.youtube_visibility;
      }
      if (edit.post.social_id) {
        this.post.edit.social_id = edit.post.social_id;
      }
      if (this.post.schedules.length) {
        this.populateSchedules();
      }
    } else if (draft) {
      const videoWithTitleFilesUrls = [];
      draft.outbox_messages.forEach((m) => {
        m.attachments
          .filter((a) => a.type === OutboxMessageAttachmentType.VideoWithTitle)
          .forEach((a: OutboxMessageAttachmentVideoWithTitle) => {
            if (videoWithTitleFilesUrls.indexOf(a.url) === -1) {
              videoWithTitleFilesUrls.push(a.url);
            }
          });
      });
      const videoGifUrls = await OutboxPublisher.getVideoGifUrls(
        videoWithTitleFilesUrls
      );

      this.post.fromDraft(draft, videoGifUrls);

      if (this.post.schedules.length) {
        this.populateSchedules();
      }
    }

    this.subscribeToSelectedTags();
    this.setSelectedAccountTypeIds();
    this.postInitialised = true;
  }

  async publish() {
    console.log('this.post.validity: ', this.post.validity.errors);
    if (!this.post.validity.isValid || this.loadingTrackers.publishing.active) {
      return;
    }

    // if (this.post.delete_at) {
    //   const modal = await this._setExpiryDateModal();
    //   if (!modal) {
    //     return;
    //   }
    // }

    this.post.multiImage = true;

    if (!(await this.validateFiles())) {
      return;
    }

    if (this.post.schedules.length > 1) {
      const { accounts, schedules } = this.post;
      const momentSchedules = schedules.map((schedule) => moment(schedule));
      const datesValid = momentSchedules.every((schedule) =>
        momentSchedules.every((otherSchedule) => {
          const difference = moment
            .duration(schedule.diff(otherSchedule))
            .abs()
            .asHours();
          return difference === 0 || difference > 168;
        })
      );
      if (this.post.hasTwitterSelected() && !datesValid) {
        this.popup.alert({
          isError: true,
          message:
            'Scheduled dates must be at least 7 days apart from other scheduled times to prevent social network spamming rules.'
        });
        return;
      }
    }

    if (this.targetingOptionsComponent) {
      await this.targetingOptionsComponent.copyToPost();
    }

    if (this.shareFirstCommentComponent) {
      this.shareFirstCommentComponent.addOrEditFirstComment();
      if (this.shareFirstCommentComponent.errors.length) {
        console.log(
          'this.shareFirstCommentComponent.errors: ',
          this.shareFirstCommentComponent.errors
        );
        return;
      }
    }

    const publishPromise = this.post.publish();
    this.loadingTrackers.publishing.add(publishPromise);
    const publishedPosts = await publishPromise;

    this.reset();
    this.publisherActive.next({ isActive: false, published: true });
  }

  async validateFiles(): Promise<boolean> {
    if (!Array.isArray(this.post.files)) {
      return true;
    }

    if (
      !this.post.edit &&
      (!(await this.validateImageFiles()) || !(await this.validateVideoFiles()))
    ) {
      return false;
    }

    return true;
  }

  async validateImageFiles(): Promise<boolean> {
    const promises = this.post.imageFiles.map((file) =>
      this.fileUploaderService.validateAndConvertImage(
        file,
        this.post.mediaRestrictions
      )
    );

    const results = await Promise.all(promises);

    let hasErrors = false;
    results.forEach((result, i) => {
      // update the outbox file with new props that might have changed during the validation procedure (url, size, etc.)
      this.post.imageFiles[i].url = result.file.url;
      this.post.imageFiles[i].handle = result.file.handle;
      this.post.imageFiles[i].size = result.file.size;
      this.post.imageFiles[i].mimetype = result.file.mimetype;
      this.post.imageFiles[i].filestackFile = {
        ...this.post.imageFiles[i].filestackFile,
        ...result.file
      };

      if (result.errors) {
        hasErrors = true;
      }
    });

    return !hasErrors;
  }

  async validateVideoFiles(): Promise<boolean> {
    if (this.post.instagramAccountsOnly()) {
      const isSingleVideoCarousel =
        this.post.files.length === 1 &&
        this.post.files[0].type === OutboxFileType.Video &&
        this.post.files[0].mediaCategory !== MediaCategory.Reel &&
        this.post.files[0].mediaCategory !== MediaCategory.Story;

      if (isSingleVideoCarousel) {
        await this.showInstagramVideoUpdatesInfoModal();
        this.post.files[0].mediaCategory = MediaCategory.Reel;
      }
    }

    const promises = this.post.videoFiles.map((file) =>
      this.fileUploaderService.validateAndConvertVideo(
        file,
        this.post.mediaRestrictions
      )
    );

    const results = await Promise.all(promises);

    let hasErrors = false;
    results.forEach((result, i) => {
      // update the outbox file with new props that might have changed during the validation procedure (url, size, etc.)
      this.post.videoFiles[i].url = result.file.url;
      this.post.videoFiles[i].handle = result.file.handle;
      this.post.videoFiles[i].size = result.file.size;
      this.post.videoFiles[i].mimetype = result.file.mimetype;
      this.post.videoFiles[i].filestackFile = {
        ...this.post.videoFiles[i].filestackFile,
        ...result.file
      };

      if (result.errors) {
        hasErrors = true;
      }
    });

    return !hasErrors;
  }

  async showInstagramVideoUpdatesInfoModal(): Promise<void> {
    const preventConfirmation = this.storageService.get(
      'orlo-prevent-instagram-updates-info-confirmation'
    );
    if (preventConfirmation) {
      return;
    }

    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    // confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
    confirmationModal.componentInstance.negativeConfirmation = true;
    confirmationModal.componentInstance.title =
      'Instagram Updates to Video Posts';
    confirmationModal.componentInstance.info = `All new single video posts under fifteen minutes will now be shared as reels on Instagram. Ensure your video meets the sizing and formatting criteria.`;
    confirmationModal.componentInstance.cancelButton = `Don't show again`;
    confirmationModal.componentInstance.confirmButton = `Okay thanks!`;
    const allowConfirmation = await confirmationModal.result;

    if (!allowConfirmation) {
      this.storageService.set(
        'orlo-prevent-instagram-updates-info-confirmation',
        true
      );
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  async subscribeToSelectedTags() {
    this.outboxTagsService.postTagsStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags) => {
        this.tags = tags;

        this.selectedTags = this.post.tags
          .map((tagName) => {
            return this.outboxTagsService.postTagsStore.find(tagName, 'name');
          })
          .filter((tag) => !!tag);
      });
  }

  onPostTagsChange(selectedTags: Tag[]): void {
    console.log('selectedTags: ', selectedTags);
    console.log('post.tags: ', this.post.tags);
    this.post.tags = selectedTags.map((t) => t.name);
  }

  onPostCampaignChange(campaign: any): void {
    console.log('campaign: ', campaign);
    console.log('post.campaign: ', this.post.campaign);
    this.post.campaign = campaign;
  }

  expiryDateChanged(date: Date | string) {
    this.post.expiryEnabled = true;
    this.expiryTime = new Date(date);
    this.post.delete_at = new Date(date);
    this.post.updateValidity();
  }

  postExpiryToggleChange(enabled: boolean) {
    this.post.updateValidity();
  }

  addSchedule() {
    if (this.scheduledPostTimes.length === 12) {
      return;
    }

    this.scheduledPostTimes[this.scheduledPostTimes.length] = undefined;
    setTimeout(() => this.schedulePostsAccordion.accordionHeightRefresh());
  }

  populateSchedules() {
    this.post.schedules.forEach((date) => {
      if (this.scheduledPostTimes[0]) {
        this.scheduledPostTimes.push({ time: date, error: undefined });
      } else {
        this.scheduledPostTimes[0] = { time: date, error: undefined };
      }
    });
  }

  updateSchedule(date, index, checkSiblings = false) {
    if (!!this.scheduledPostTimes[index]) {
      this.post.removeSchedule(this.scheduledPostTimes[index].time);
    }
    this.scheduledPostTimes[index] = { time: date, error: undefined };

    const { isValid, error } = this.post.addSchedule(date, this.post);
    if (!isValid) {
      if (error.dateInPast) {
        this.scheduledPostTimes[index]['error'] = {
          key: 'dateInPast',
          message: 'Sorry but that date is in the past'
        };
      } else if (error.dateTooCloseTo) {
        this.scheduledPostTimes[index]['error'] = {
          key: 'dateTooCloseTo',
          message: `
            The time and date selected is too close to ${this.datePipe.transform(
              error.dateTooCloseTo,
              'medium'
            )}. 
            X prevents the same post going out within a 7-day window, as part of their spam and duplicate content rules
          `
        };
      } else if (error.maxSchedulesReached) {
        this.scheduledPostTimes[index]['error'] = {
          key: 'maxSchedulesReached',
          message: `Sorry but you can only add a maximum of ${this.post.scheduleCount.maximum} schedules`
        };
      }
    } else {
      this.scheduledPostTimes[index].error = undefined;
    }

    this.post.updateValidity();

    if (checkSiblings) {
      this.checkSchedules();
    }
  }

  resetSchedules() {
    this.scheduledPostTimes.forEach((schedule, index) => {
      this.updateSchedule(schedule.time, index);
    });
    this.checkScheduleErrors();
  }

  checkSchedules() {
    this.scheduledPostTimes.forEach((schedule, index) => {
      if (schedule.error) {
        this.updateSchedule(schedule.time, index);
      }
    });
    this.checkScheduleErrors();
  }

  checkScheduleErrors() {
    const hasErrors = !this.scheduledPostTimes.every(
      (schedule) => !schedule.error
    );
    this.post.schedulingErrors = hasErrors;
  }

  deleteSchedule(scheduleIndex: number) {
    if (this.scheduledPostTimes[scheduleIndex]) {
      this.post.removeSchedule(this.scheduledPostTimes[scheduleIndex].time);
    }

    if (this.scheduledPostTimes.length === 1) {
      this.scheduledPostTimes = [undefined];
    } else {
      this.scheduledPostTimes.splice(scheduleIndex, 1);
    }

    this.post.updateValidity();
    this.checkSchedules();
    // setTimeout(() => this.schedulePostsAccordion.accordionHeightRefresh());
  }

  async confirmCancelPost() {
    const confirmCancel = this.modal.open(ComposerCancelModalComponent, {
      windowClass: 'orlo-modal'
    });
    Object.assign(confirmCancel.componentInstance, {
      isDraft: this.post.isDraft
    });

    const result = await confirmCancel.result;
    if (!result) {
      return;
    }

    // save as draft
    if (result === 1) {
      let isDraftPublic = false;
      let replaceDraft = false;
      let draftTitle = (this.params.draft && this.params.draft.title) || '';
      const user = await this.userModel.getAuthUser();
      const modalParams = {
        title: 'Save new draft',
        meta: 'Add a title for your new draft:',
        userIsAdmin: user.hasCompanyPermission('manage_public_drafts'),
        isDraftPublic,
        showSecondaryAction: false,
        primaryActionButtonText: 'Save current draft',
        draftTitle
      };
      const saveDraftModal = this.modal.open(SaveDraftModalComponent, {
        windowClass: 'orlo-modal'
      });
      Object.assign(saveDraftModal.componentInstance, modalParams);
      saveDraftModal.componentInstance.onDraftTitleChange.subscribe(
        (newDraftTitle: string) => (draftTitle = newDraftTitle)
      );
      saveDraftModal.componentInstance.onPublicDraftToggle.subscribe(
        (isPublic: boolean) => (isDraftPublic = isPublic)
      );

      const draftResult = await saveDraftModal.result;
      if (!draftResult) {
        return;
      }
      replaceDraft = this.params.draft && this.params.draft.id && result === 2;
    }

    // cancel
    if (result === 2) {
      this.reset();
    }
  }

  async changeDraftState(draft: Draft) {
    if (this.post.isDraft) {
      this.params.draft = draft;
      this.isDraftPublic = draft.draft_type === 'public';
    } else {
      this.params.draft = undefined;
    }
  }

  async saveDraft(): Promise<void> {
    const user = await this.userModel.getAuthUser();
    let draftTitle = (this.params.draft && this.params.draft.title) || '';
    const modalParams = {
      title: 'Create a new draft?',
      userIsAdmin: user.hasCompanyPermission('manage_public_drafts'),
      showSecondaryAction: false,
      primaryActionButtonText: 'Create new draft',
      draftTitle,
      isDraftPublic: this.isDraftPublic
    };
    let replaceDraft = false;

    if (this.params.draft && this.params.draft.id) {
      Object.assign(modalParams, {
        title: 'Save current draft or create a new one?',
        meta:
          'Would you like to save the changes made to the current draft you have selected? Or create a shiny brand new one?',
        draftType: this.params.draft.draft_type,
        showSecondaryAction: true,
        primaryActionButtonText: 'Save current draft'
      });
    }

    const confirmationModal = this.modal.open(SaveDraftModalComponent, {
      windowClass: 'orlo-modal'
    });
    Object.assign(confirmationModal.componentInstance, modalParams);
    confirmationModal.componentInstance.onDraftTitleChange.subscribe(
      (newDraftTitle: string) => (draftTitle = newDraftTitle)
    );
    confirmationModal.componentInstance.onPublicDraftToggle.subscribe(
      (isPublic: boolean) => (this.isDraftPublic = isPublic)
    );
    const result = await confirmationModal.result;
    if (!result) {
      return;
    }
    replaceDraft = this.params.draft && this.params.draft.id && result === 2;

    this.post.multiImage = true;

    if (
      !this.post.validity.isValid ||
      this.loadingTrackers.savingDraft.active
    ) {
      return;
    }

    // if (this.shareFirstCommentComponent) {
    //   this.shareFirstCommentComponent.addOrEditFirstComment();
    //   if (this.shareFirstCommentComponent.errors.length) {
    //     return;
    //   }
    // }

    const outboxMessages = await this.post.toOutboxMessages();
    const extra: DraftExtra = {
      isSplit: this.post.isSplit,
      splitPostAccountId: this.post.splitPostAccount.id,
      igShareToFeed: this.post.igShareToFeed,
      scheduleFirstCommentToggled: this.post.scheduleFirstCommentToggled
    };

    if (this.post.hasTargetingSet()) {
      extra.targeting = this.post.targeting;
    }

    const savingDraft = this.draftsLibraryService.createOrUpdateDraft(
      draftTitle || 'Untitled draft',
      this.isDraftPublic ? DraftType.Public : DraftType.Private,
      outboxMessages,
      extra,
      replaceDraft ? this.params.draft.id : undefined
    );
    this.loadingTrackers.publishing.add(savingDraft);
    await savingDraft;

    this.publisherActive.next({ isActive: false });

    this.notificationService.open(
      this.draftSavedMessageTemplate,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      5000
    );
  }

  fileUploadError(err) {
    console.error(err);
    const message =
      err.message ||
      this.translate.instant(
        'SORRY_BUT_WE_COULD_NOT_UPLOAD_THE_FILE_YOU_SELECTED_PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT_IF_THE_PROBLEM_CONTINUES'
      );
    this.popup.alert({
      title: this.translate.instant('FILE_UPLOAD_FAILED'),
      message
    });
  }

  async fileUploadSuccess(files: FileUploadFile[]): Promise<void> {
    try {
      if (!Array.isArray(files)) {
        throw new Error(
          `Value for 'publisher file uploa files' not in expected format.`
        );
      }

      let addToAll = false;
      OutboxPublisher.setFileType(files[0]);
      const isImage = (files[0] as any).type === OutboxFileType.Image;

      if (
        this.post.isSplit &&
        this.post.splitPostAccount &&
        !this.post.splitPostAccount.isInstagram() &&
        !this.post.hasVideoAttachedToAnyPost() &&
        isImage
      ) {
        const confirmationModal = this.modal.open(ConfirmationModalComponent, {
          centered: true,
          windowClass: 'orlo-modal'
        });
        confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
        confirmationModal.componentInstance.title = 'Attach to all?';
        confirmationModal.componentInstance.info = `Would you like to attach this image to all your split posts?`;
        confirmationModal.componentInstance.cancelButton = 'No thanks';
        confirmationModal.componentInstance.confirmButton = `Yes please`;
        addToAll = await confirmationModal.result;
      }

      files.forEach((file) => {
        this.post.addFile(file, {}, addToAll);
      });

      const fileWithCustomPostText = files.find((file) =>
        get(file, 'customSource.metadata.outbox_post.text')
      );

      const fileWithInternalNote = files.find((file) =>
        get(file, 'customSource.metadata.outbox_post.note')
      );

      if (fileWithCustomPostText || fileWithInternalNote) {
        const postText: string = get(
          fileWithCustomPostText,
          'customSource.metadata.outbox_post.text'
        );
        const postTextNote: string = get(
          fileWithInternalNote,
          'customSource.metadata.outbox_post.note'
        );

        const modal = this.modal.open(SuggestedTextModalComponent, {
          centered: true,
          windowClass: 'orlo-modal'
        });
        modal.componentInstance.suggestedText = postText ? postText : '';
        modal.componentInstance.mediaNote = postTextNote ? postTextNote : '';
        const result: SuggestedTextModalResult = await modal.result;
        if (result.overwrite) {
          this.post.text = postText;
        } else if (result.addToPost) {
          this.post.text = this.post.text.concat(' ', postText);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  onSelectedAccountsChanged(selectedAccounts: Account[]): void {
    this.post.accounts = selectedAccounts;
    this.setSelectedAccountTypeIds();

    if (this.post.hasTwitterSelected()) {
      if (this.scheduledPostTimes.some((schedule) => !!schedule)) {
        this.resetSchedules();
      }
    } else {
      this.scheduledPostTimes.forEach((schedule) => {
        if (
          schedule &&
          schedule.error &&
          schedule.error.key === 'dateTooCloseTo'
        ) {
          schedule.error = undefined;
          this.checkScheduleErrors();
        }
      });
    }
  }

  setSelectedAccountTypeIds(): void {
    this.selectedAccountTypeIds = [];

    const accountGroups = groupBy(
      this.post.accounts,
      (account) => account.account_type_id
    );

    accountGroups.forEach((accountGroup) => {
      this.selectedAccountTypeIds.push(accountGroup[0].account_type_id);
    });
  }

  onTargetingFormChange($event: boolean) {
    this.targetingEnabled = $event;
  }

  hasMultipleNextdoorAccountTypes(): boolean {
    return (
      this.accounts.filter(
        (account: Account): boolean =>
          account.account_type_id === AccountTypeIdString.Nextdoor ||
          account.account_type_id === AccountTypeIdString.NextdoorUS
      ).length > 1
    );
  }

  hasAnyTargetableAccounts(): boolean {
    return (
      this.selectedAccountTypeIds.filter((item): boolean =>
        [
          AccountTypeIdString.Facebook.toString(),
          AccountTypeIdString.LinkedIn.toString(),
          AccountTypeIdString.Nextdoor.toString(),
          AccountTypeIdString.NextdoorUS.toString()
        ].includes(item)
      ).length > 0
    );
  }

  hasMultipleTargetableAccounts(): boolean {
    return (
      this.selectedAccountTypeIds.filter((item): boolean =>
        [
          AccountTypeIdString.Facebook.toString(),
          AccountTypeIdString.LinkedIn.toString(),
          AccountTypeIdString.Nextdoor.toString(),
          AccountTypeIdString.NextdoorUS.toString()
        ].includes(item)
      ).length > 1
    );
  }

  reset() {
    this.scheduledPostTimes = [undefined];
    this.expiryTime = undefined;
    this.post.expiryEnabled = false;
    this.requiresApproval = false;
    this.showPreview = false;
    this.params = undefined;
    this.disable = undefined;
    this.isActive = false;
    this.isDraftPublic = false;
  }

  ngOnDestroy(): void {
    if (this.publisherActiveSubscription) {
      this.publisherActiveSubscription.unsubscribe();
    }
    this.destroyed$.next();
    this.destroyed$.complete();
    this.reset();
  }

  // Remove after inline preview implementation
  async showPostPreview() {
    if (this.post.validity.isValid) {
      const modal = this.modal.open(
        PublisherSocialNetworkPreviewModalComponent,
        {
          centered: true
        }
      );
      modal.componentInstance.post = this.post;
      try {
        await modal.result;
        await this.publish();
      } catch (e) {}
    }
  }

  imageUploadStarted() {
    if (this.composerService.fileUploadActive) {
      throw new Error('File upload already in progress');
    }
    this.composerService.fileUploadActiveSource.next(OutboxFileType.Image);
  }

  videoUploadStarted() {
    if (this.composerService.fileUploadActive) {
      throw new Error('File upload already in progress');
    }
    this.composerService.fileUploadActiveSource.next(OutboxFileType.Video);
  }

  // gifUploadStarted() {
  //   if (this.composerService.fileUploadActive) {
  //     throw new Error('File upload already in progress');
  //   }
  //   this.composerService.fileUploadActive = OutboxFileType.Gif;
  // }

  onUploadSuccess(files: FileUploadFile[]): void {
    this.fileUploadSuccess(files);
    this.composerService.fileUploadActiveSource.next(undefined);
  }

  gifUploadSuccess(gifObj: FileUploadFile): void {
    this.fileUploadSuccess([gifObj]);
    this.composerService.fileUploadActiveSource.next(undefined);
  }

  onUploadError(error): void {
    this.fileUploadError(error);
    this.composerService.fileUploadActiveSource.next(undefined);
  }

  fileUploadComplete() {
    this.composerService.fileUploadActiveSource.next(undefined);
  }

  onPickerClosed() {
    // if (!this.post.files.length) {
    //   // this.disablePublish = false;
    // }
  }

  getImageCategoryRestrictions(): ImageRestrictions {
    return this.post.mediaCategory === MediaCategory.Post
      ? this.post.mediaRestrictions.image
      : this.post.mediaRestrictions.imageStory;
  }

  getVideoCategoryRestrictions(): VideoRestrictions {
    return this.post.mediaCategory === MediaCategory.Post
      ? this.post.mediaRestrictions.video
      : this.post.mediaCategory === MediaCategory.Reel
      ? this.post.mediaRestrictions.reel
      : this.post.mediaRestrictions.videoStory;
  }
}
