import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { AccountModel, Account } from '@ui-resources-angular';
import { ApiService, Colleague, ColleaguesService } from '..';
import { PageChangeParams } from '../../../components/paginator';

export enum SurveyType {
  NPS = 'nps',
  CSAT = 'csat'
}

export enum SortField {
  DT = 'dt',
  Score = 'score'
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export interface DateRange {
  start: Date;
  end: Date;
}

export interface Survey {
  dt: string;
  user_id: number;
  user: Colleague; // set on FE side
  recipient_name: string;
  score: number;
  comment: string;
}

export interface SurveyReport {
  aggregation: Aggregation;
  surveys: Survey[];
  next: {
    offset: number;
  };
}

export interface Aggregation {
  response_rate: number;
  avg_score: number;
  avg_score_by_account: {
    [accountId: string]: number;
  };
  count_sent: number;
  count_responses: number;
  count_have_comment: number;
}

export interface SurveyConfig {
  accounts: Array<{ id: number; enabled: boolean; message: string }>;
  company: {
    enabled: boolean;
    type: SurveyType;
    score_min: number;
    score_max: number;
    send_automatically: boolean;
    send_delay: number;
  };
}

@Injectable({ providedIn: 'root' })
export class SurveyService {
  surveyConfig: SurveyConfig; // cache

  constructor(
    protected injector: Injector,
    protected api: ApiService,
    protected translate: TranslateService,
    protected colleaguesService: ColleaguesService
  ) {}

  /** https://orlo.atlassian.net/browse/CT-1983?focusedCommentId=16808 */
  async getReport(
    account_ids: string[],
    type: SurveyType,
    since: Date,
    until: Date,
    user_ids?: number[],
    score_gte?: number,
    score_lte?: number,
    name_query?: string,
    comment_query?: string,
    offset?: number,
    limit?: number,
    sort_field?: SortField,
    sort_direction?: SortOrder
  ): Promise<SurveyReport> {
    // console.log('service call: ', pageChangeParams);
    const colleagues = await this.colleaguesService.getAll();

    const url = `${this.api.url}/survey/surveyReport`;
    const payload = {
      account_ids,
      type,
      since,
      until,
      user_ids,
      score_gte,
      score_lte,
      name_query,
      comment_query,
      limit,
      offset,
      sort_field,
      sort_direction
    };

    Object.keys(payload).forEach((key) => {
      if (payload[key] === undefined) {
        // cuz backend sees undefined as a value?
        payload[key] = null;
      }
    });

    return this.api
      .post(url, payload)
      .pipe(
        map((response: { report: SurveyReport }) => {
          response.report.surveys.forEach((s) => {
            s.user = colleagues.find((c) => c.id === String(s.user_id));
          });

          return response.report;
        }),
        catchError((e) => this.api.mapError(e, url))
      )
      .toPromise();
  }

  getConfig(fresh = false): Promise<SurveyConfig> {
    if (this.surveyConfig && !fresh) {
      return Promise.resolve(this.surveyConfig);
    }

    const url = `${this.api.url}/survey/surveyConfig`;
    return this.api
      .get(url)
      .pipe(
        map((response: any) => {
          console.log('config: ', response);
          this.surveyConfig = response;
          return response;
        }),
        catchError((e) => this.api.mapError(e, url))
      )
      .toPromise();
  }

  patchConfig(config): Promise<any> {
    const url = `${this.api.url}/survey/surveyConfig`;
    return this.api
      .post(url, config)
      .pipe(
        map((response: any) => {
          console.log('post config: ', response);
          this.surveyConfig = undefined;
          return response;
        }),
        catchError((e) => this.api.mapError(e, url))
      )
      .toPromise();
  }

  async hasSurveysEnabled(accountId: number | string): Promise<boolean> {
    const companyConfig = await this.getConfig();
    const configAccount = companyConfig.accounts.find(
      (a) => a.id === Number(accountId)
    );

    return !!(configAccount && configAccount.enabled);
  }

  async hasSendAutomatically(): Promise<boolean> {
    const companyConfig = await this.getConfig();
    return companyConfig.company.send_automatically;
  }

  exportCSV(
    company_id,
    account_ids,
    type,
    since,
    until,
    user_ids,
    name_query,
    comment_query
  ) {
    const url = `${this.api.url}/survey/surveyExport`;
    const payload = {
      company_id,
      account_ids,
      type,
      since,
      until,
      user_ids,
      name_query,
      comment_query
    };
    return this.api
      .post(url, payload)
      .pipe(
        map((response: any) => response),
        catchError((e) => this.api.mapError(e, url))
      )
      .toPromise();
  }
}
