<div class="detected-themes-container">
  <div class="section-head">
    <div class="title">
      <!-- <i class="ssi ssi-survey"></i> -->
      <h2>Detected themes</h2>
    </div>
  </div>


  <div class="section-body table-container">
    <ssi-treetable [data]="detectedThemesResponse?.detected_themes"
                   [bodyStyle]="{'max-height': ''}"
                   [rowStyle]="{'height': '60px'}"
                   [virtualScroll]="false"
                   [resizeMode]="'fit'"
                   [reorderable]="true"
                   [saveSettings]="true"
                   [serverSorting]="false"
                   [settingsStorageKey]="'treetable-insights-detected-themes'"
                   [globalFilterInputRef]=""
                   id="treetable-survey-analytics-survey-summary">

      <ssi-column field="ai_theme"
                  header="AI THEME"
                  width="30%"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>

      <ssi-column field="first_seen_at"
                  header="FIRST SEEN AT"
                  width="20%"
                  [templateRefs]="{bodyCell: dateBodyCellTemplate}"></ssi-column>

      <ssi-column field="first_seen_size"
                  header="SIZE (FIRST SEEN)"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>

      <ssi-column field="last_seen_at"
                  header="LAST SEEN AT"
                  width="20%"
                  [templateRefs]="{bodyCell: dateBodyCellTemplate}"></ssi-column>

      <ssi-column field="last_seen_size"
                  header="SIZE (LAST SEEN)"
                  [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>

      <ng-template ssiTemplateSelector="noResults">
        <ssi-no-results *ngIf="!detectedThemesResponse"
                        icon="ssi-theme-alerts"
                        title="Create an alert"
                        description="Once you create an alert, your detected emerging themes will list here"></ssi-no-results>

        <ssi-no-results *ngIf="detectedThemesResponse && !detectedThemesResponse.detected_themes.length"
                        icon="ssi-theme-alerts"
                        title="No results so far..."
                        description="Your alert has been created, results can take up to an hour to pull in">
          <ng-template>
            <button type="button"
                    class="g-btn g-btn-secondary bg-white g-margin-top-20"
                    [disabled]="refreshDisabled"
                    (click)="onRefresh()">Refresh</button>
          </ng-template>
        </ssi-no-results>
      </ng-template>
    </ssi-treetable>

    <ssi-paginator [itemsCount]="itemsCount"
                   [pageLinksSize]="5"
                   [itemsPerPage]="itemsPerPage"
                   [reInitOnChanges]="false"
                   [autoInit]="false"
                   (onPageChange)="onPageChange($event)"></ssi-paginator>
  </div>
</div>

<ng-template #dateBodyCellTemplate
             ssiTemplateSelector="bodyCell"
             let-rowIndex="rowIndex"
             let-rowData="rowData"
             let-column="column"
             let-columnIndex="columnIndex"
             let-columns="columns"
             let-columnsMap="columnsMap"
             let-fieldValue="fieldValue">
  <div class="body-cell"
       [class.row-even]="rowIndex % 2 !== 0">
    <span>{{fieldValue | date: 'dd MMM y'}} | {{fieldValue | date: 'HH:mm'}}</span>
  </div>
</ng-template>

<ng-template #bodyCellTemplate
             ssiTemplateSelector="bodyCell"
             let-rowIndex="rowIndex"
             let-rowData="rowData"
             let-column="column"
             let-columnIndex="columnIndex"
             let-columns="columns"
             let-columnsMap="columnsMap"
             let-fieldValue="fieldValue">
  <div class="body-cell"
       [class.row-even]="rowIndex % 2 !== 0">
    <span *ngIf="column.field === 'ai_theme'"
          class="theme-name"
          (click)="openDrilldownModal.emit(detectedThemesResponse.detected_themes[rowIndex])">{{fieldValue}}</span>
    <span *ngIf="column.field !== 'ai_theme'">{{fieldValue}}</span>
  </div>
</ng-template>