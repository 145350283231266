import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TwitterThreadService {
  public newOutbox = new Subject();
  public displayAdd = new Subject();

  constructor() {}
}
