import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion.component';
import { TypeofModule } from '../../pipes/type-of/type-of.module';

@NgModule({
  imports: [CommonModule, TypeofModule],
  declarations: [AccordionComponent],
  exports: [AccordionComponent],
  entryComponents: [AccordionComponent]
})
export class AccordionModule {}
