<div class="validate-post-box post-box panel">
  <div
    *ngIf="validationStatus"
    class="post-box-validation"
    [ngClass]="validationStatus"
  >
    <p
      *ngIf="validationStatus === OutboxValidationStatus.Approved"
      translate="YOU_HAVE_APPROVED_THIS_REPLY"
    ></p>
    <p
      *ngIf="validationStatus === OutboxValidationStatus.Disapproved"
      translate="YOU_HAVE_DISAPPROVED_THIS_REPLY"
    ></p>
  </div>
  <div class="panel-top post-box-wrap">
    <div class="panel-header post-box-header">
      <button
        *ngIf="validation.conversation_link"
        class="btn btn-default btn-style-1"
        uiSref="auth.inbox"
        [uiParams]="{
          mode: inboxLink.mode,
          conversation: inboxLink.conversation,
          activity: inboxLink.activity,
          reply: 'true'
        }"
        [uiOptions]="{reload: true}"
      >
          <span class="btn-style-1-badge">
            <i class="ssi ssi-thread"></i>
          </span>
          View in Inbox
      </button>
    </div>
    <div class="panel-body post-box-panel-body">
      <ssi-validate-reply-box-content
        [validation]="validation"
        [user]="user"
        [selected]="selected"
        [selectMode]="selectMode"
        (addToSelected)="addToSelected.emit(validation)"
        [(editMode)]="editMode"
      >
      </ssi-validate-reply-box-content>
    </div>
    <ssi-validate-posts-gallery
      *ngIf="validation.outbox_files && validation.outbox_files.length > 0 && !editMode"
      [validation]="validation"
    >
    </ssi-validate-posts-gallery>
  </div>

  <div
    *ngIf="canValidate"
    [ngClass]="{'post-box-footer-selected': getSelectedStatus()}"
    class="panel-footer post-box-footer"
  >
    <button
      class="btn btn-style-1 btn-danger"
      [disabled]="getSelectedStatus()"
      (click)="validatePost(false)"
      translate="DISAPPROVE_POST"
    >
    </button>
    <button
      class="btn btn-style-1 btn-primary"
      [disabled]="getSelectedStatus()"
      (click)="validatePost(true)"
      translate="APPROVE__REPLY"
    >
    </button>
  </div>
</div>