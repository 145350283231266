import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { OutboxFileType } from '@ui-resources-angular';
import { FileUploaderService } from '../../directives/file-uploader/file-uploader.service';

export enum ComposerLayout {
  Full = 'full',
  Split = 'split',
  Minimised = 'minimised',
  FixedRight = 'fixedRight',
  FixedLeft = 'fixedLeft'
}

export const defaultComposerLayout = ComposerLayout.Full;

@Injectable({ providedIn: 'root' })
export class ComposerService {
  isActive = new Subject<string>();
  fileUploadActiveSource = new BehaviorSubject<OutboxFileType>(undefined);
  // layoutChangeSource = new BehaviorSubject<ComposerLayout>(defaultComposerLayout);

  constructor(private fileUploaderService: FileUploaderService) {}

  async test() {}

  loadComposer() {}

  get fileUploadActive(): boolean {
    return !!this.fileUploadActiveSource.getValue();
  }

  get videoResizingInProgress(): boolean {
    return this.fileUploaderService.videoResizingInProgress$.getValue();
  }

  get publishDisabled() {
    return this.fileUploadActive || this.videoResizingInProgress;
  }
}
