<div
  class="{{colorClass}}"
  [innerHTML]="text"
  *ngIf="!delta">
</div>

<small
  class="delta-indicator {{colorClass}}"
  *ngIf="delta">
  {{ totalChange }}
</small>
