import './advertising.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/angular';
import { CompanyService } from '../../../../common/services/company/company.service';

export async function advertisingEnableResolveFn(company) {
  return await company.hasFeatureAccess('ADVERTS').then((result) => !!result);
}

@Component({
  selector: 'ssi-advertising',
  templateUrl: './advertising.component.html'
})
export class AdvertisingComponent implements OnInit {
  static resolve = [
    {
      token: 'advertisingEnabled',
      resolveFn: advertisingEnableResolveFn,
      deps: [CompanyService]
    }
  ];

  @Input() advertisingEnabled = false;

  constructor(
    private state: StateService,
    private transition: TransitionService
  ) {}

  ngOnInit() {
    if (!this.advertisingEnabled) {
      this.redirectToPurchase();
    }
  }

  redirectToPurchase() {
    this.state.go('auth.marketing.advertising.notPurchased');
    this.transition.onStart({}, (transition) => {
      if (transition.to().name.startsWith('auth.marketing.advertising')) {
        this.state.go('auth.marketing.advertising.notPurchased');
      }
    });
  }
}
