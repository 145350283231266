import { Component, OnInit, Input, Inject, Injector } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Outbox } from '@ui-resources-angular';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague
} from '../../services/api';
import { PopupService } from '../../services/popup/popup.service';

@Component({
  selector: 'ssi-outbox-notes-modal',
  templateUrl: './outbox-notes-modal.component.html'
})
export class OutboxNotesModalComponent implements OnInit {
  @Input() post: Outbox;

  colleagues: Colleague[];
  teams: Team[];
  tagList: any[];
  newNoteText: string;

  constructor(
    private popup: PopupService,
    private translate: TranslateService,
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService,
    public modalInstance: NgbActiveModal
  ) {}

  async ngOnInit() {
    this.teams = await this.teamsService.getAllActive();
    this.colleagues = await this.colleaguesService.getAllActive();
    this.tagList = [...this.teams, ...this.colleagues];
  }

  deleteNote(note) {
    this.popup
      .confirm({
        title: this.translate.instant('DELETE_NOTE'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_NOTE'
        )
      })
      .then((shouldDelete) => {
        if (shouldDelete) {
          return note.destroy();
        }
      });
  }
}
