import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgbModalModule,
  NgbModule,
  NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';

import { ContentCalendarService } from './content-calendar.service';
import { ContentCalendarComponent } from './content-calendar.component';
import { ContentCalendarNavComponent } from './content-calendar-nav/content-calendar-nav.component';
import { PostBoxModule } from './common/components/post-box/post-box.module';
import { FiltersModule } from '../../../../common/components/filters';

import { VarModule } from '../../../../common/directives/var/var.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import {
  CalendarModule,
  DateAdapter
} from '../../../../common/components/calendar';
import { adapterFactory } from '../../../../common/components/calendar/date-adapters/date-fns';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { ContextMenuModule } from '../../../../common/components/context-menu/context-menu.module';
import { TriggerPublishModule } from '../../../../common/directives/trigger-publish/trigger-publish.module';
import { DropdownMenuModule } from '../../../../common/components/dropdown-menu/dropdown-menu.module';
import { CalendarEventModalModule } from './common/components/calendar-event-modal/calendar-event-modal.module';
import { FlatpickrModule } from 'angularx-flatpickr';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    NgbModule,
    NgbModalModule,
    NgbDropdownModule,
    DropdownMenuModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.contentCalendar',
          url: '/content-calendar?outboxId',
          params: {
            outboxId: {
              dynamic: true
            },
            query: {
              dynamic: true
            },
            note_id: {
              dynamic: true
            }
          },
          component: ContentCalendarComponent,
          resolve: ContentCalendarComponent.resolve
        }
      ]
    }),
    VarModule,
    TooltipModule,
    TranslateModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    PostBoxModule,
    FiltersModule,
    SocialNetworkIconModule,
    ContextMenuModule,
    TriggerPublishModule,
    CalendarEventModalModule,
    FlatpickrModule
  ],
  exports: [ContentCalendarComponent, ContentCalendarNavComponent],
  declarations: [ContentCalendarComponent, ContentCalendarNavComponent],
  providers: [ContentCalendarService]
})
export class ContentCalendarModule {}
