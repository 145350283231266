import './cl-dropdown-select-g.component.scss';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-cl-dropdown-select-g',
  templateUrl: './cl-dropdown-select-g.component.html',
  styles: []
})
export class ClDropdownSelectGComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onSelectedTagsChange(selectedTags: any): void {
    console.log('onSelectedTagsChange: ', selectedTags);
  }
}
