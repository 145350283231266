import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import './publish-settings.component.scss';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  serverSettings,
  companyPreferences,
  companyConfigResolveFn
} from '../../../../common-resolves';
import { StateService } from '@uirouter/angular';
import { UserPreferencesService } from '../../../../../common/services/user-preferences/user-preferences.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { VanityDomain, API, VanityDomainModel } from '@ui-resources-angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../../../../angular/common/services/popup/popup.service';
import {
  CompanyService,
  CompanyConfig,
  CompanyPreferences
} from '../../../../../../angular/common/services/company/company.service';
import { TooltipDirective } from '../../../../../../angular/common/directives/tooltip/tooltip.directive';
import {
  Tag,
  TagType,
  OutboxTagsService
} from '../../../../../../angular/common/services/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export function vanityDomainsFn(vanityDomainModel: VanityDomainModel) {
  return vanityDomainModel.findAll();
}

@Component({
  selector: 'ssi-publish-settings',
  templateUrl: './publish-settings.component.html',
  styles: []
})
export class PublishSettingsComponent implements OnInit, OnDestroy {
  static resolve = [
    companyPreferences,
    {
      token: 'companyConfig',
      resolveFn: companyConfigResolveFn,
      deps: [CompanyService]
    },
    {
      token: 'vanityDomains',
      resolveFn: vanityDomainsFn,
      deps: [VanityDomainModel]
    }
  ];

  @Input() companyPreferences: CompanyPreferences;
  @Input() companyConfig: CompanyConfig;
  @Input() vanityDomains: VanityDomain[];

  @ViewChild('deleteDomainTooltip') deleteDomainTooltip: TooltipDirective;

  newVanityUrl: string;

  publishSettingsForm: FormGroup;

  newPostTag: string;

  postTags: Tag[] = [];
  newDisapprovalTag: string;
  disapprovalTags: Tag[] = [];
  hasValidationTagging: boolean;
  protected destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private state: StateService,
    private fb: FormBuilder,
    private api: API,
    private company: CompanyService,
    private vanityDomainModel: VanityDomainModel,
    private outboxTagsService: OutboxTagsService
  ) {}

  async ngOnInit() {
    this.publishSettingsForm = this.fb.group({
      use_link_shortening: [this.companyConfig.use_link_shortening],
      use_utm_link_tracking: [this.companyConfig.use_utm_link_tracking],
      only_use_managed_files: [this.companyConfig.only_use_managed_files],
      alt_text_required: [
        this.companyPreferences.company_preferences.alt_text_required === '1'
          ? true
          : false
      ],
      shortening_url: [
        this.companyPreferences.company_preferences.shortening_url
      ]
    });

    this.publishSettingsForm.valueChanges.subscribe((latestFormValue) => {
      if (
        this.companyConfig.use_link_shortening &&
        !latestFormValue.use_link_shortening &&
        latestFormValue.use_utm_link_tracking
      ) {
        this.companyConfig.use_link_shortening = false;
        // force turning off use_utm_link_tracking
        this.publishSettingsForm.patchValue({
          use_utm_link_tracking: false
        });
        return;
      }
      this.toggleUtmTracking(latestFormValue);

      if (
        latestFormValue.alt_text_required !==
        this.companyPreferences.company_preferences.alt_text_required
      ) {
        this.toggleAltTextRequired(latestFormValue.alt_text_required);
      }
    });

    this.hasValidationTagging = await this.company.hasFeatureAccess(
      'OUTBOX_VALIDATION_TAGGING'
    );

    this.getPostTags();
    this.getDisapprovalTags();
  }

  toggleUtmTracking(configChanges: Partial<CompanyConfig>) {
    this.companyConfig = {
      ...this.companyConfig,
      ...configChanges
    };
    this.company.saveConfig(this.companyConfig);
  }

  addVanityDomain() {
    const promise = this.vanityDomainModel
      .create(this.newVanityUrl)
      .then((domain) => {
        this.vanityDomains.push(domain);
        this.newVanityUrl = '';
      });
  }

  changeDefaultVanityDomain(vanityDomain: VanityDomain) {
    const promise = this.api
      .post('settings/companyPreference', {
        company_preferences: { shortening_url: vanityDomain.domain }
      })
      .then(() => {
        this.companyPreferences.company_preferences.shortening_url =
          vanityDomain.domain;
      });
  }

  toggleAltTextRequired(altTextRequired: boolean) {
    const promise = this.api
      .post('settings/companyPreference', {
        company_preferences: { alt_text_required: altTextRequired ? '1' : '0' }
      })
      .then(() => {
        this.companyPreferences.company_preferences.alt_text_required = altTextRequired
          ? '1'
          : '0';
      });
  }

  deleteVanityDomain(vanityDomain: VanityDomain) {
    const promise = vanityDomain.destroy().then(() => {
      this.vanityDomains = this.vanityDomains.filter(
        (domain) => domain !== vanityDomain
      );
    });
  }

  // Post tags CRUD
  async getPostTags() {
    this.outboxTagsService.postTagsStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags) => {
        console.log('tags:', tags);
        this.postTags = tags.filter((t) => !t.disabled);
      });
  }

  async addPostTag() {
    await this.outboxTagsService.createTag(this.newPostTag, TagType.Post);
    this.newPostTag = '';
  }

  async removePostTag(deleteTag: Tag) {
    await this.outboxTagsService.deleteTag(deleteTag.id, TagType.Post);
  }

  // Validation/Disapproval tags CRUD
  async getDisapprovalTags() {
    this.outboxTagsService.validationTagsStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags) => {
        this.disapprovalTags = tags.filter((t) => !t.disabled);
      });
  }

  async addDisapprovalTag() {
    await this.outboxTagsService.createTag(
      this.newDisapprovalTag,
      TagType.Validation
    );
    this.newDisapprovalTag = '';
  }

  async removeDisapprovalTag(deleteTag: Tag) {
    await this.outboxTagsService.deleteTag(deleteTag.id, TagType.Validation);
  }

  return() {
    this.state.go('^');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
