import {
  ConversationUserEvent as ConversationUserEventInterface,
  ConversationUserEventItems
} from '../../interfaces/live-chat/conversation-user-event';
import Model from '../model';
import { Conversation } from '../../models/live-chat/conversation';
import { Agent } from './agent';
import { Visitor } from './visitor';

export class ConversationUserEvent extends Model
  implements ConversationUserEventInterface {
  public conversation: Conversation = null;
  public visitor: Visitor = null;

  public get agents(): Agent[] {
    return this._data.agents || {};
  }

  public get isVisitorTyping(): boolean {
    return this.doEventsExistForVisitor() && !!this.items.visitor.lastTypedAt;
  }

  public get items(): ConversationUserEventItems {
    return this._data.items;
  }

  public get lastUpdatedAt(): any {
    return this._data.lastUpdatedAt;
  }

  public doEventsExistForAgent(id: string): boolean {
    if (!this.agents || !this.agents.hasOwnProperty(id)) {
      return false;
    }

    if (!this.items || !this.items.agents || !this.items.agents[id]) {
      return false;
    }

    return true;
  }

  public doEventsExistForVisitor(): boolean {
    if (!this._data || !this._data.visitor || !this._data.visitor.id) {
      return false;
    }

    const id: string = this._data.visitor.id;

    if (!this.visitor || this.visitor.id !== id) {
      return false;
    }

    if (!this.items || !this.items.visitor) {
      return false;
    }

    return true;
  }

  public isAgentMatchingIdTyping(id: string) {
    return (
      this.doEventsExistForAgent(id) && !!this.items.agents[id].lastTypedAt
    );
  }

  public isAgentMatchingIdViewing(id: string) {
    return this.doEventsExistForAgent && !!this.items.agents[id].lastViewedAt;
  }
}
