import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { OutboxPostMenuComponent } from './outbox-post-menu.component';

import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { TriggerPublishModule } from '../../../common/directives/trigger-publish/trigger-publish.module';

@NgModule({
  declarations: [OutboxPostMenuComponent],
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule,
    TooltipModule,
    TriggerPublishModule
  ],
  exports: [OutboxPostMenuComponent]
})
export class OutboxPostMenuModule {}
