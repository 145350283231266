<div class="insights-navbar" *ngIf="mode !== 'view'">
  <div class="insights-navbar-column">
    <div class="insights-navbar-item">
      <span class="insights-navbar-name insights-navbar-button">
        <i class="ssi ssi-text"></i>
        <input
          required
          placeholder="Type the dashboard name..."
          type="text"
          [(ngModel)]="dashboardName"
          (ngModelChange)="onDashboardNameChange()"
        >
      </span>
    </div>
    <div class="insights-navbar-item">
      <button type="button" class="insights-navbar-button insights-navbar-button-long" (click)="widgetAdd.emit()">
        <i class="ssi ssi-addsmall"></i>
        <span>Add widget</span>
      </button>
    </div>
  </div>
  <div class="insights-navbar-column insights-navbar-column-right">
    <div class="insights-navbar-item">
      <button *ngIf="mode !== 'create'" type="button" class="insights-navbar-button insights-navbar-button-long delete-button" (click)="dashboardDelete.emit()">
        <i class="ssi ssi-small-delete insights-navbar-item-icon-red"></i>
        <span>Delete Dashboard</span>
      </button>
    </div>
  </div>
</div>

<div class="insights-navbar" *ngIf="mode === 'view'">
  <div class="insights-navbar-column">
    <div class="insights-navbar-item">
      <!-- <span class="insights-navbar-name insights-navbar-button">
        <i class="ssi ssi-text"></i>
        <input
          required
          placeholder="Type the dashboard name..."
          type="text"
          [(ngModel)]="dashboardName"
          (ngModelChange)="onDashboardNameChange()"
        >
      </span> -->
      <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                               [options]="dashboards"
                               [(ngModel)]="selectedDashboard"
                               (ngModelChange)="onDashboardChange($event)"></ssi-dropdown-select-2>
    </div>
  </div>
  <div class="insights-navbar-column insights-navbar-column-right">
    <div class="insights-navbar-item">
      <button type="button" class="insights-navbar-button insights-navbar-button-long border" (click)="refresh()">
        <i class="ssi ssi-refresh-to-check"></i>
        <span>Refresh</span>
      </button>
    </div>
    <div class="insights-navbar-item">
      <button type="button" class="insights-navbar-button insights-navbar-button-long border" (click)="edit()">
        <i class="ssi ssi-correct-edit-small"></i>
        <span>Customise dashboard</span>
      </button>
    </div>
    <div class="insights-navbar-item">
      <button type="button" class="insights-navbar-button insights-navbar-button-long border" (click)="create()">
        <i class="ssi ssi-addsmall"></i>
        <span>New dashboard</span>
      </button>
    </div>
  </div>
</div>