<div class="facts-summary-container">
  <div class="section-head">
    <h3 class="section-title">Video Metrics</h3>
    <ssi-social-network-select *ngIf="accounts.length !== 0"
                               [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
  </div>

  

  <div class="section-body">
    <div class="facts-row">
      <ssi-fact-overview
        *ngFor="let fact of factList"
        [label]="fact.label"
        [width]="'auto'"
        [number]="fact.current"
        [previousValue]="fact.previous"
        [iconClass]="fact.icon"
        [iconColor]="fact.iconColor"
        [circleColor]="fact.circleColor"
        [ssiTooltip]="fact.tooltip ? fact.tooltip : ''"
        tooltipWindowClass="tooltip-chunky-big"
      ></ssi-fact-overview>
    </div>
  </div>
</div>