import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { API } from '@ui-resources-angular';
import { StateService, TransitionService } from '@uirouter/angular';
import './live-chat-widgets.scss';
import { Application as Widget } from '../../../../../../../library/models/live-chat/application';
import liveChatConfig from '../../../../../../../library/constants/live-chat';
import { ApplicationFragment } from '../../../../../../../library/interfaces/live-chat/application';
import { LiveChatService } from '../../../../../common/services/live-chat/live-chat.service';
import {
  ChatBotService,
  ChatBot
} from '../../../../../common/services/chat-bot/chat-bot.service';

export async function loginFn(api: API) {
  return api.get('settings/logins').then(({ data }) => data);
}

@Component({
  selector: 'ssi-live-chat-widgets',
  templateUrl: './live-chat-widgets.component.html',
  styles: []
})
export class LiveChatWidgetsComponent implements OnInit, OnDestroy {
  static resolve = [
    {
      token: 'logins',
      resolveFn: loginFn,
      deps: [API]
    }
  ];

  private $routeChange;
  widgets: Widget[];
  _defaultValues: ApplicationFragment = {};
  livechatLoading = true;

  @Input() logins: any;
  @Input() chatBots: ChatBot[];

  constructor(
    public api: API,
    public location: Location,
    public transition: TransitionService,
    public state: StateService,
    private liveChatService: LiveChatService
  ) {}

  async ngOnInit() {
    await this.getWidgets();

    console.log(this.widgets);

    this.$routeChange = this.transition.onSuccess({}, (transition) => {
      if (transition.to().url === '/live-chat-widgets') {
        this.getWidgets();
      }
    });

    await this.liveChatService.login();

    this.livechatLoading = false;
  }

  ngOnDestroy() {
    this.$routeChange();
  }

  public get defaultValues() {
    return this._defaultValues;
  }

  public get isPushModeEnabled() {
    return this.liveChatService.isPushModeEnabled;
  }

  public async togglePushMode() {
    if (!(await this.liveChatService.togglePushMode())) {
      throw new Error(`Unable to toggle push mode!`);
    }
  }

  public getWidgets(): Promise<any> {
    this.widgets = [];

    return Promise.all([
      this.api.get(liveChatConfig.routes.widget).then((response) => {
        this.widgets = response.data.applications.map((account) =>
          Widget.CreateFromSSIAccount(account)
        );
      }),
      this.api.get(liveChatConfig.routes.widgetDefaults).then((response) => {
        Object.keys(response.data.properties).forEach((propertyName) => {
          if (
            response.data.properties[propertyName].hasOwnProperty('default')
          ) {
            this.defaultValues[propertyName] =
              response.data.properties[propertyName].default;
          }
        });
      })
    ]);
  }

  return() {
    this.state.go('^');
  }
}
