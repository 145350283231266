<div
    class="inline-note-container"
    [ngClass]="{
        'inline-note-container-inverse': inverse,
        'inline-note-container-public': isPublic
    }">
    <div class="inline-note-left">
        <div
            class="inline-notes-avatar-wrap"
            [ngClass]="{'inline-notes-avatar-wrap-pointer': canCloseNote}"
            (click)="canCloseNote && closeNotes.emit()">
            <i class="ssi ssi-message-notes inline-notes-avatar-icon"></i>
            <span class="inline-notes-avatar-badge" *ngIf="notesAmount">{{notesAmount}}</span>
        </div>
    
        <span *ngIf="showGuide" class="inline-notes-avatar-guide"></span>
    </div>
    <div class="inline-note-right">
        <div class="inline-note-wrap">
            <div class="inline-note">
                <div class="inline-note-body">
                    <p [innerHTML]="note.content"></p>
                </div>
                <div class="inline-note-footer">
                    <span *ngIf="showDeleteNote" (click)="deleteNote.emit()" class="inline-note-footer-delete">
                        <i class="ssi ssi-delete"></i>
                        <p>Delete note</p>
                    </span>
                    <ul class="inline-note-footer-meta">
                        <li><p>{{note.created_at | date:'HH:mm'}}</p></li>
                        <li><p>{{note.created_at | date:'d MMM yyyy'}}</p></li>
                        <li class="inline-note-footer-concat"><p>By {{note.author.fullName}}</p></li>
                    </ul>
                    <div
                        *ngIf="showAddNote"
                        (click)="addNote.emit()"
                        class="inline-note-footer-add">
                        <p>Add Note</p>
                        <div class="inline-note-footer-add-icon">
                            <i class="ssi ssi-addsmall"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>