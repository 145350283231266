import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatGraphComponent } from './stat-graph.component';
import { HighchartModule } from '../../../../../common/components/highchart/highchart.module';
import { IconModule } from '../../../../../common/components/icon/icon.module';

@NgModule({
  imports: [CommonModule, HighchartModule, IconModule],
  declarations: [StatGraphComponent],
  exports: [StatGraphComponent]
})
export class StatGraphModule {}
