import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportHeaderModule } from './report-header/report-header.module';
import { ReportSidebarModule } from './report-sidebar/report-sidebar.module';
import { MedalScoreModule } from './medal-score/medal-score.module';
import { SocialNetworkSelectModule } from './social-network-select/social-network-select.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReportHeaderModule,
    ReportSidebarModule,
    MedalScoreModule,
    SocialNetworkSelectModule
  ],
  exports: [
    ReportHeaderModule,
    ReportSidebarModule,
    MedalScoreModule,
    SocialNetworkSelectModule
  ]
})
export class CommonAnalyticsModule {}
