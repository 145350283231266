<div class="inbox-alert-modal">
  <div class="inbox-alert-modal-header">
    <div class="inbox-alert-modal-header-left">
      <h2 translate="REPLY_CANT_BE_SENT"></h2>
    </div>
    <div class="inbox-alert-modal-header-right">
      <button
        (click)="close()"
        class="inbox-alert-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="inbox-alert-modal-body">
    <p translate="THE_POSTCOMMENT_YOU_ARE_REPLYING_TO_NO_LONGER_EXISTS"></p>
  </div>

  <div class="inbox-alert-modal-footer">
    <div class="inbox-alert-modal-footer-left"></div>
    <div class="inbox-alert-modal-footer-right">
      <button
        class="inbox-alert-modal-button"
        (click)="close()"
        translate="OK"
      ></button>
    </div>
  </div>
</div>