import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetChartLineComponent } from './widget-chart-line.component';
import { HighchartModule } from '../../../../../../common/components/highchart/highchart.module';

@NgModule({
  declarations: [WidgetChartLineComponent],
  imports: [CommonModule, HighchartModule],
  exports: [WidgetChartLineComponent]
})
export class WidgetChartLineModule {}
