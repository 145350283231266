import { Component, Input } from '@angular/core';
import {
  AccountModel,
  OutboxModel,
  Profile,
  Outbox
} from '@ui-resources-angular';
import { Optional } from '@angular/core';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-private-message-modal',
  templateUrl: './private-message-modal.component.html',
  styles: []
})
export class PrivateMessageModalComponent {
  @Input()
  resolve: {
    profile: Profile;
  };
  @Input() dismiss;
  @Input() close;
  message = '';
  loadingTracker = this.asyncTrackerFactory.create();
  result: Outbox;

  constructor(
    private outboxModel: OutboxModel,
    private accountModel: AccountModel,
    private asyncTrackerFactory: AsyncTrackerFactory,
    @Optional() public activeModal?: NgbActiveModal
  ) {}

  send() {
    const promise = this.outboxModel
      .privateMessage(
        this.accountModel.get(this.resolve.profile.account_id),
        this.resolve.profile,
        this.message
      )
      .then((results) => {
        this.result = results[0];
      });
    this.loadingTracker.add(promise);
  }
}
