import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanQuery',
  pure: false
})
export class HumanQueryPipe implements PipeTransform {
  transform(queryData): string {
    const listify = (list: string[], operator: string): string => {
      return list.reduce(
        (a, b, i, array) =>
          a + (i < array.length - 1 ? ', ' : ` ${operator} `) + b
      );
    };
    const conditionSentance = (
      conditionsName: string,
      conditions,
      operator
    ): string => {
      return `${conditionsName}${conditions.length === 1 ? ':' : 's;'} ${
        conditions.length > 2
          ? listify(
              conditions.map((condition) => condition.value || ''),
              operator
            )
          : conditions.length > 1
          ? conditions
              .map((condition) => condition.value || '')
              .join(' ' + operator + ' ')
          : conditions[0].value || ''
      } `;
    };

    let rootSentance = '';
    let rootKeyWords = [];
    let rootUsers = [];
    if (queryData.conditions) {
      const operatorLabel = queryData.operator.slice(1);
      rootKeyWords = queryData.conditions.filter(
        (condition) => condition.type === 'text'
      );
      rootUsers = queryData.conditions.filter(
        (condition) => condition.type === 'user'
      );
      const rootKeyWordsInclude = queryData.conditions.filter(
        (condition) =>
          condition.type === 'text' && condition.comparison === 'has'
      );
      const rootUsersInclude = queryData.conditions.filter(
        (condition) =>
          condition.type === 'user' && condition.comparison === 'involved'
      );
      const rootKeyWordsExclude = queryData.conditions.filter(
        (condition) =>
          condition.type === 'text' && condition.comparison === 'has-not'
      );
      const rootUsersExclude = queryData.conditions.filter(
        (condition) =>
          condition.type === 'user' && condition.comparison === 'not-involved'
      );

      console.log('queryData.conditions:', queryData.conditions);
      const rootUsersFrom = queryData.conditions.filter(
        (condition) =>
          condition.type === 'user' && condition.comparison === 'from'
      );

      if (rootKeyWords.length || rootUsers.length) {
        rootSentance += 'Messages that <i>must</i> ';
      }

      if (rootKeyWordsInclude.length || rootUsersInclude.length) {
        rootSentance += 'include ';
      }

      if (rootKeyWordsInclude.length) {
        rootSentance += conditionSentance(
          'keyword',
          rootKeyWordsInclude,
          operatorLabel
        );
      }

      if (rootUsersInclude.length) {
        rootSentance += rootKeyWordsInclude.length ? ` ${operatorLabel} ` : '';
        rootSentance += conditionSentance(
          'user',
          rootUsersInclude,
          operatorLabel
        );
      }

      if (rootKeyWordsInclude.length + rootUsersInclude.length > 1) {
        rootSentance +=
          operatorLabel === 'or'
            ? '<i>separately</i> '
            : operatorLabel === 'and'
            ? '<i>together</i> '
            : '';
      }

      if (rootKeyWordsExclude.length || rootUsersExclude.length) {
        if (rootKeyWordsInclude.length || rootUsersInclude.length) {
          rootSentance += `${operatorLabel} exclude `;
        } else {
          rootSentance += 'exclude ';
        }
      }

      if (rootUsersFrom.length) {
        if (rootKeyWordsInclude.length || rootUsersInclude.length) {
          rootSentance += `${operatorLabel} be from `;
        } else {
          rootSentance += 'be from ';
        }
      }

      if (rootUsersFrom.length) {
        rootSentance += conditionSentance('user', rootUsersFrom, operatorLabel);
      }

      if (rootKeyWordsExclude.length) {
        rootSentance += conditionSentance(
          'keyword',
          rootKeyWordsExclude,
          operatorLabel
        );
      }

      if (rootUsersExclude.length) {
        rootSentance += rootKeyWordsExclude.length ? ` ${operatorLabel} ` : '';
        rootSentance += conditionSentance(
          'user',
          rootUsersExclude,
          operatorLabel
        );
      }

      if (rootKeyWordsExclude.length + rootUsersExclude.length > 1) {
        rootSentance +=
          operatorLabel === 'or'
            ? '<i>separately</i> '
            : operatorLabel === 'and'
            ? '<i>together</i> '
            : '';
      }
    }

    if (queryData.subQueries.length) {
      queryData.subQueries.forEach((subQuery, index) => {
        const operatorLabel = subQuery.operator.slice(1);
        console.log('operatorLabel:', operatorLabel);

        const keyWordsInclude = subQuery.conditions.filter(
          (condition) =>
            condition.type === 'text' && condition.comparison === 'has'
        );
        const usersInclude = subQuery.conditions.filter(
          (condition) =>
            condition.type === 'user' && condition.comparison === 'involved'
        );
        const keyWordsExclude = subQuery.conditions.filter(
          (condition) =>
            condition.type === 'text' && condition.comparison === 'has-not'
        );
        const usersExclude = subQuery.conditions.filter(
          (condition) =>
            condition.type === 'user' && condition.comparison === 'not-involved'
        );

        const usersFrom = subQuery.conditions.filter(
          (condition) =>
            condition.type === 'user' && condition.comparison === 'from'
        );

        if (keyWordsInclude.length || usersInclude.length || usersFrom.length) {
          if (!rootKeyWords.length && !rootUsers.length && index < 1) {
            rootSentance += 'Messages that <i>must</i> ';
            if (keyWordsInclude.length || usersInclude.length) {
              rootSentance += 'include ';
            }
            if (usersFrom.length) {
              rootSentance += 'be from ';
            }
          } else {
            rootSentance += ` ${queryData.operator.slice(1)} <i>${
              operatorLabel === 'or' ? 'any' : 'every'
            }</i> `;
          }
        }

        if (keyWordsInclude.length) {
          rootSentance += conditionSentance(
            'keyword',
            keyWordsInclude,
            operatorLabel
          );
        }

        if (usersInclude.length) {
          rootSentance += keyWordsInclude.length ? ` ${operatorLabel} ` : '';
          rootSentance += conditionSentance(
            'user',
            usersInclude,
            operatorLabel
          );
        }

        if (keyWordsInclude.length + usersInclude.length > 1) {
          rootSentance +=
            operatorLabel === 'or'
              ? '<i>separately</i> '
              : operatorLabel === ' and'
              ? '<i>together</i> '
              : '';
        }

        if (keyWordsExclude.length || usersExclude.length) {
          if (keyWordsInclude.length || usersInclude.length) {
            rootSentance += `${operatorLabel} exclude `;
          } else {
            rootSentance += 'exclude ';
          }
        }

        if (usersFrom.length) {
          if (keyWordsInclude.length || usersInclude.length) {
            rootSentance += `${operatorLabel} to be from `;
          } else {
            rootSentance += 'to be from ';
          }
        }

        if (keyWordsExclude.length) {
          rootSentance += conditionSentance(
            'keyword',
            keyWordsExclude,
            operatorLabel
          );
        }

        if (usersExclude.length) {
          rootSentance += keyWordsExclude.length ? ` ${operatorLabel} ` : '';
          rootSentance += conditionSentance(
            'user',
            usersExclude,
            operatorLabel
          );
        }

        if (usersFrom.length) {
          rootSentance += keyWordsExclude.length ? ` ${operatorLabel} ` : '';
          rootSentance += conditionSentance('user', usersFrom, operatorLabel);
        }

        if (keyWordsExclude.length + usersExclude.length > 1) {
          rootSentance +=
            operatorLabel === 'or'
              ? '<i>separately</i> '
              : operatorLabel === 'and'
              ? '<i>together</i> '
              : '';
        }
      });
    }
    return rootSentance;
  }
}
