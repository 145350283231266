<div class="listening-modal">
  <div class="listening-modal-header">
    <div class="listening-modal-header-left">
      <h2>{{variants[variant].title}}</h2>
    </div>
    <div class="listening-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="listening-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <ng-container *ngIf="variant === modalVariant.createGroup">
    <div class="listening-modal-body">
      <p>
        {{variants[variant].description}}
      </p>
      <div class="listening-modal-action listening-modal-body-row">
        <div class="input-wrapper">
          <label>GROUP NAME<span>&nbsp;(This will show in your Insights sources list)</span></label>
          <input
            type="text"
            placeholder="Type the group name"
            [(ngModel)]="groupName"
            #groupNameRef
            [maxlength]="65"
          >
          <span class="char-counter">{{65 - groupNameRef.value.length}}</span>
        </div>
        <div class="input-wrapper">
          <label>Connect first Facebook page</label>
          <div class="input-tags-wrap">
            <ul class="tags-input-tags">
              <li
                *ngFor="let page of selectedPages"
                class="tags-input-tag"
              >
                <span>{{page.name}}&nbsp;@{{page.username}}</span>
                <button
                  class="tags-input-tag-action"
                  (click)="removePage(page)"
                >
                  <i class="ssi ssi-delete-close"></i>
                </button>
              </li>
            </ul>
            <input
              type="text"
              [attr.placeholder]="selectedPages && selectedPages.length > 0 ? '' : 'Search Facebook page handle...'"
              [(ngModel)]="pageSearchString"
              (keydown.enter)="searchForPages($event)"
              [disabled]="selectedPages && selectedPages.length > 0"
            >
            <ssi-spinner #pageSearchSpinner></ssi-spinner>
            <i
              class="ssi ssi-correct-search search-icon"
              [class.active]="!pageSearchString && selectedPages && selectedPages.length === 0"
              (click)="searchForPages($event)"
            ></i>
            <div
              class="accounts-dropdown"
              *ngIf="showAccountsDropdown"
              (ssiOffClick)="showAccountsDropdown = false"
            >
              <ul
                class="accounts-list"
                *ngIf="pageResults.length > 0 && pageSearchString"
              >
                <li
                  class="item"
                  *ngFor="let page of pageResults"
                >
                  <div class="item-left">
                    <img
                      class="page item-img"
                      [src]="page.image_url"
                    >
                  </div>
                  <div class="item-body">
                    <div class="page-details">
                      <div class="page-info">
                        <a href="javascript:;">
                          <strong>{{ page.name }}</strong>
                          <small>@{{ page.username }}</small>
                        </a>
                        <br>
                        <small>{{ page.description }}</small>
                        <div
                          class="location"
                          *ngIf="page.location"
                        >
                          <small>
                            <i class="fa fa-map-marker"></i>
                            {{ page.location.city ? page.location.city : page.location }}
                          </small>
                        </div>
                        <div
                          class="location no-location"
                          *ngIf="!page.location"
                        >
                          <small>
                            <i class="fa fa-map-marker"></i>
                            No location data available
                          </small>
                        </div>
                      </div>
                      <div class="plus-container">
                        <button
                          class="orlo-button primary plus"
                          (click)="addPage(page)"
                          type="button"
                        >
                          <i class="ssi ssi-addsmall"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="listening-modal-footer">
      <div class="listening-modal-footer-left"></div>
      <div class="listening-modal-footer-right">
        <button
          class="listening-modal-button cancel"
          (click)="activeModal.close(false)"
        >Cancel</button>
        <button
          class="listening-modal-button confirm"
          [disabled]="!(selectedPages.length && groupName)"
          (click)="saveGroup(); activeModal.close()"
        >Save group</button>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="variant === modalVariant.addPage">
    <div class="listening-modal-body">
      <p>
        {{variants[variant].description}}
      </p>
      <div class="listening-modal-action listening-modal-body-row">
        <div class="input-wrapper">
          <label>Connect another page</label>
          <div class="input-tags-wrap">
            <ul class="tags-input-tags">
              <li
                *ngFor="let page of selectedPages"
                class="tags-input-tag"
              >
                <span>{{page.name}}&nbsp;@{{page.username}}</span>
                <button
                  class="tags-input-tag-action"
                  (click)="removePage(page)"
                >
                  <i class="ssi ssi-delete-close"></i>
                </button>
              </li>
            </ul>
            <input
              type="text"
              [attr.placeholder]="selectedPages && selectedPages.length > 0 ? '' : 'Search Facebook page handle...'"
              [(ngModel)]="pageSearchString"
              (keydown.enter)="searchForPages($event)"
              [disabled]="selectedPages && selectedPages.length > 0"
            >
            <ssi-spinner #pageSearchSpinner></ssi-spinner>
            <i
              class="ssi ssi-correct-search search-icon"
              [class.active]="!pageSearchString && selectedPages && selectedPages.length === 0"
              (click)="searchForPages($event)"
            ></i>
            <div
              class="accounts-dropdown"
              *ngIf="showAccountsDropdown"
              (ssiOffClick)="showAccountsDropdown = false"
            >
              <ul
                class="accounts-list"
                *ngIf="pageResults.length > 0 && pageSearchString"
              >
                <li
                  class="item"
                  *ngFor="let page of pageResults"
                >
                  <div class="item-left">
                    <img
                      class="page item-img"
                      [src]="page.image_url"
                    >
                  </div>
                  <div class="item-body">
                    <div class="page-details">
                      <div class="page-info">
                        <a href="javascript:;">
                          <strong>{{ page.name }}</strong>
                          <small>@{{ page.username }}</small>
                        </a>
                        <br>
                        <small>{{ page.description }}</small>
                        <div
                          class="location"
                          *ngIf="page.location"
                        >
                          <small>
                            <i class="fa fa-map-marker"></i>
                            {{ page.location.city ? page.location.city : page.location }}
                          </small>
                        </div>
                        <div
                          class="location no-location"
                          *ngIf="!page.location"
                        >
                          <small>
                            <i class="fa fa-map-marker"></i>
                            No location data available
                          </small>
                        </div>
                      </div>
                      <div class="plus-container">
                        <button
                          class="orlo-button primary plus"
                          (click)="addPage(page)"
                          type="button"
                        >
                          <i class="ssi ssi-addsmall"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="listening-modal-footer">
      <div class="listening-modal-footer-left">
      </div>
      <div class="listening-modal-footer-right">
        <button
          class="listening-modal-button cancel"
          (click)="activeModal.close(false)"
        >Cancel</button>
        <button
          class="listening-modal-button confirm"
          [disabled]="!selectedPages.length"
          (click)="addPageToGroup(); activeModal.close()"
        >Add Page</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="variant === modalVariant.editGroup">
    <div class="listening-modal-body">
      <p>
        {{variants[variant].description}}
      </p>
      <div class="listening-modal-action listening-modal-body-row">
        <div class="input-wrapper">
          <label>GROUP NAME<span>&nbsp;(This will show in your Insights sources list)</span></label>
          <input
            type="text"
            placeholder="Type the group name"
            [(ngModel)]="groupName"
            #groupNameRef
            [maxlength]="65"
          >
          <span class="char-counter">{{65 - groupNameRef.value.length}}</span>
        </div>
      </div>
    </div>

    <div class="listening-modal-footer">
      <div class="listening-modal-footer-left">
        <button
          class="text-btn"
          (click)="openDeleteGroupModal()"
        ><i class="ssi ssi-small-delete"></i>Delete group</button>
      </div>
      <div class="listening-modal-footer-right">
        <button
          class="listening-modal-button cancel"
          (click)="activeModal.close(false)"
        >Cancel</button>
        <button
          class="listening-modal-button confirm"
          [disabled]="false"
          (click)="updateGroup(); activeModal.close()"
        >Save changes</button>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="variant === modalVariant.deleteGroup">
    <div class="listening-modal-body">
      <p>
        {{variants[variant].description}}
      </p>
      <div class="listening-modal-action listening-modal-body-row">
      </div>
    </div>

    <div class="listening-modal-footer">
      <div class="listening-modal-footer-left"></div>
      <div class="listening-modal-footer-right">
        <button
          class="listening-modal-button cancel"
          (click)="activeModal.close(false)"
        >Cancel</button>
        <button
          class="listening-modal-button confirm delete"
          [disabled]="false"
          (click)="deleteGroup(); activeModal.close()"
        >Delete group</button>
      </div>
    </div>
  </ng-container>
</div>