import './advertising-preset-list.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { AdvertisingPreset } from '../advertising-preset-model/advertising-preset-model.service';
import { trackByProperty } from '../../../../../common/util';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { Account, API } from '@ui-resources-angular';
import {
  advertisingTargetingPresets,
  workflowAccounts
} from '../../../../common-resolves';
import icons from './preset-icons.json';

@Component({
  selector: 'ssi-advertising-preset-list',
  templateUrl: './advertising-preset-list.component.html'
})
export class AdvertisingPresetListComponent implements OnInit {
  static resolve = [advertisingTargetingPresets, workflowAccounts];

  @Input() advertisingTargetingPresets: AdvertisingPreset[];
  @Input() workflowAccounts: Account[];

  trackById = trackByProperty('id');
  currentPresetTargets = [];
  activeSocialFilter = 0;

  constructor(private popup: PopupService, private api: API) {}

  ngOnInit() {}

  async deletePreset(preset: AdvertisingPreset) {
    const confirm = await this.popup.confirm({
      title: 'Delete preset',
      message: 'Are you sure you would like to delete this preset?'
    });
    if (confirm) {
      await preset.destroy();
      this.advertisingTargetingPresets = this.advertisingTargetingPresets.filter(
        (iPreset) => iPreset !== preset
      );
    }
  }

  async filterAccount(accountTypeId) {
    this.activeSocialFilter = accountTypeId;
    const accounts = this.workflowAccounts.filter((account) => {
      if (accountTypeId === 3) {
        return (
          account.account_type_id === '3' || account.account_type_id === '9'
        );
      } else if (accountTypeId === 4) {
        return (
          account.account_type_id === '4' || account.account_type_id === '8'
        );
      } else {
        return account.account_type_id === String(accountTypeId);
      }
    });

    const { data } = await this.api.get('advertising/advertisingPreset', {
      params: {
        account_ids: Array.from(
          accountTypeId === 0 ? this.workflowAccounts : accounts,
          (obj) => obj.id
        )
      }
    });
    const presets = [...data.presets];
    presets.forEach((preset) => {
      preset.account = this.workflowAccounts.filter((account) => {
        return preset.account_id === account.id;
      })[0];
    });
    this.advertisingTargetingPresets = presets;
  }

  presetList(preset) {
    const targetItems = preset.targeting_options.map((option) => {
      const key = option.option.data.entityType || option.option.name;
      const icon = icons
        .filter(
          (item) =>
            (option.option.data.entityType
              ? item.linkedinLabel
              : item.facebookLabel) === key
        )
        .map((item) => item.class);
      return { type: key, value: [option.label], icon };
    });
    const targets = [];

    targetItems.forEach((item) => {
      if (targets.find((target) => target.type === item.type)) {
        targets.find(
          (target) => target.type === item.type && target.value.push(item.value)
        );
      } else {
        targets.push(item);
      }
    });

    this.currentPresetTargets = targets;
  }
}
