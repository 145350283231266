<div class="cl-dropdown-select-a-container">
  <h4>A</h4>
  <a target="_blank"
     href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-dropdown-select/cl-dropdown-select-a/cl-dropdown-select-a.component.html">
    View code on GitHub
  </a>
  <p>headButtonStyleVariant="inbox" (should be removed and usages (3) should be replaced with headButton template)</p>
  <p>SM size - Basic multi-select version</p>

  <ssi-dropdown-select-2 size="sm"
                         name="accounts"
                         headButtonStyleVariant="inbox"
                         [required]="true"
                         [multiple]="true"
                         [filterable]="true"
                         [placeholdersKeyword]="'accounts'"
                         [toggleAllLabel]="'all'"
                         [options]="accounts"
                         [(ngModel)]="selectedAccounts"
                         (ngModelChange)="selectedAccountsChanged($event)"></ssi-dropdown-select-2>
</div>