import { Md5 } from 'ts-md5';

export function get_email_hash_for_gravatar(email: string): string {
  email = String(email).toLowerCase();

  return String(Md5.hashStr(email));
}

export function capitalize(str: string): string {
  if (!str || !str.length) {
    return '';
  }

  return str
    .split(' ')
    .map((chunk: string) => chunk[0].toUpperCase() + chunk.slice(1))
    .join();
}

export function normalise_url(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = `http://${url}`;
  }
  const urlParsed = new URL(url);
  // force IE / Edge to url encode params
  urlParsed.search = urlParsed.searchParams.toString();

  return urlParsed.toString();
}
