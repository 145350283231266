<div class="enable-social-chatbots grey">
  <div class="flex-grid container">
    <div class="col">
        <!-- <div class="return-nav">
          <orlo-button [state]="'primary'" [text]="'Back to settings'" (btnClick)="return()"></orlo-button>
        </div> -->
        <div class="segment">
          <h1>Your Social Accounts  <i *ngIf="loading" class="ssi ssi-loading ssi-spin"></i></h1>
          <p>Configure which chatbots are enabled for your social accounts.</p>
          <ul class="account-list" *ngIf="enabledAccounts">
            <li *ngFor="let account of workflowAccounts">
              <span class="account-name"><i [class]="account.socialNetwork.icon.web"></i>{{account.name}}</span>
              <div class="form-field orlo-select">
                <select (change)="enableChatbotOnAccount($event, account.id)">
                  <option value="disable">Disabled</option>
                  <option [value]="chatbot.id" *ngFor="let chatbot of chatBots" [selected]="checkIfBotAssigned(chatbot.id, account.id)">{{chatbot.name}}</option>
                </select>
                <i class="ssi ssi-arrow-down-correct"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
  </div>
</div>