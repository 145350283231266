<div class="modal-header">
    <button
      type="button"
      class="btn btn-default pull-right"
      (click)="modalInstance.close('cancel')">
      <span translate="CLOSE"></span> <i class="fa fa-times"></i>
    </button>
    <h4 class="modal-title">Notify the user</h4>
  </div>
  <div class="modal-body">
    <div class="dissaprove-posts">
        <div 
          class="form-row extra-margin-top" *ngIf="disapprovalTags">
          <div class="form-field">
            <label>Include tags with your response</label>
            <div class="input-wrapper">
              <ssi-dropdown-select-2 name="tagsToInclude"
                                      class="tag-selector"
                                      headButtonStyleVariant="analytics"
                                      size="large"
                                      [multiple]="true"
                                      [filterable]="true"
                                      [toggleAllEnabled]="false"
                                      [filterPlaceholder]="'Search tags to include...'"
                                      [headButtonIcon]="'ssi ssi-addsmall'"
                                      [toggleAllLabel]="'Select all tags'"
                                      [options]="disapprovalTags"
                                      [(ngModel)]="selectedTags"
                                      (ngModelChange)="onSelectedTagsChange($event)">
                <ng-template ssiTemplateSelector="headButtonLabel"
                              let-expanded="expanded">
                  <div class="select-tags-placeholder"
                        *ngIf="!selectedTags.length">
                    <span>Search for tags</span>
                  </div>
                  <div class="select-tag-labels"
                        *ngIf="selectedTags.length">
                    <ng-container *ngFor="let tag of selectedTags">
                      <span (click)="$event.stopPropagation(); removeIncludedTag(tag)">
                        {{tag.label}} <i class="ssi ssi-times"></i>
                      </span>
                    </ng-container>
                  </div>
                </ng-template>
              </ssi-dropdown-select-2>
            </div>
          </div>
        </div>
        <p>Please enter some notes for the user to review</p>
        <textarea [(ngModel)]="reasoning"></textarea>
        <div class="buttons-container">
          <button
            class="btn btn-style-1 btn-primary"
            (click)="modalInstance.close('cancel')"
          >Cancel</button>
          <button
            class="btn btn-style-1 btn-danger"
            (click)="modalInstance.close({reason:reasoning,disapprovalTags:selectedTags})"
          >Disapprove post</button>
        </div>
    </div>
  </div>