import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[ssiTimeToDetails]'
})
export class TimeToDetailsDirective {
  @Input('ssiTimeToDetails') time: string;
  @Input('timeText') text?: string = 'to respond';

  constructor(private elm: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  mouseover() {
    this.elm.nativeElement.innerHTML = this.time;
  }

  @HostListener('mouseleave')
  mouseleave() {
    this.elm.nativeElement.innerHTML = `${this.time.slice(0, 6)} ${this.text}`;
  }
}
