import './create.component.scss';

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User, UserModel } from '@ui-resources-angular';
import {
  AddWidgetComponent,
  GridLayoutItem,
  WidgetsGridComponent
} from '../common/components';
import {
  Dashboard,
  DashboardService,
  DashboardWidget
} from '../../../../common/services/api/dashboard';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { DashboardWidgetModalComponent } from '../../../../common/components/dashboard-widget-modal/dashboard-widget-modal.component';
import { Filter } from '../../../../common/components/filters';

@Component({
  selector: 'ssi-create',
  templateUrl: './create.component.html',
  styles: []
})
export class CreateComponent implements OnInit, OnDestroy {
  hasWidgets = false;
  authUser: User;
  dashboardToCreate: any = {
    name: 'Main Dashboard',
    widgets: []
  };
  dashboardTemplates: Dashboard[] = [];
  selectedDashboardTemplate: Dashboard;

  protected destroyed$ = new Subject<void>();

  @ViewChild('widgetsGrid') widgetsGrid: WidgetsGridComponent;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected state: StateService,
    protected modal: NgbModal,
    protected popup: PopupService,
    protected userModel: UserModel,
    protected dashboardService: DashboardService,
    protected notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.userModel.getAuthUser().then((user) => {
      this.authUser = user;
    });

    this.dashboardService.templatesStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((templates) => {
        console.log('subscribe dashboard tempaltes: ', templates);
        this.dashboardTemplates = templates;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  applyTemplate(template: Dashboard): void {
    this.dashboardToCreate = { ...template };
    this.selectedDashboardTemplate = template;
    this.hasWidgets = true;
  }

  async openAddWidgetModal() {
    const widgetWalletModal = this.modal.open(AddWidgetComponent, {
      windowClass: 'xxl-modal'
    });

    widgetWalletModal.componentInstance.widgetAdded.subscribe(
      async (widget: DashboardWidget) => {
        const modal = this.modal.open(DashboardWidgetModalComponent, {
          windowClass: 'xxl-modal'
        });
        modal.componentInstance.widget = widget;

        const widgetToAdd = await modal.result;
        if (widgetToAdd) {
          this.hasWidgets = true;
          this.cdRef.detectChanges();
          this.widgetsGrid.addNewWidget(widgetToAdd);
          console.log('widget added: ', widgetToAdd);
          // modal.close();
          widgetWalletModal.close();
        }
      }
    );
  }

  async editWidget(widget: GridLayoutItem) {
    const modal = this.modal.open(DashboardWidgetModalComponent, {
      windowClass: 'xxl-modal'
    });
    modal.componentInstance.edit = true;
    modal.componentInstance.widget = widget.apiData;

    this.widgetsGrid.updateWidget(widget, await modal.result);
    this.widgetsGrid.reRenderWidgets(); // so new config and filters are applied
  }

  async save(): Promise<void> {
    const widgetsToSave = this.widgetsGrid.toApiWidgets(
      this.widgetsGrid.widgets
    );
    // widgetsToSave.forEach((w) => {
    //   // strip props not need saving
    //   delete w.typeConst;
    // });
    this.dashboardToCreate.widgets = widgetsToSave;

    this.dashboardService
      .create(this.dashboardToCreate)
      .then((createdDashboard) => {
        console.log('created dashboard: ', createdDashboard);
        this.notificationService.open(
          `Your dashboard has been saved, success!`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          2000
        );
        this.state.go('auth.dashboard.view', {
          id: createdDashboard.id
        });
      })
      .catch(() => {
        this.notificationService.open(
          `There was an error creating your dashboard!`,
          {
            class: 'ssi ssi-small-delete',
            color: '#F88C68'
          },
          2000
        );
      });
  }

  async saveAsTemplate(): Promise<void> {
    const widgetsToSave = this.widgetsGrid.toApiWidgets(
      this.widgetsGrid.widgets
    );
    // widgetsToSave.forEach((w) => {
    //   // strip props not need saving
    //   delete w.typeConst;
    // });
    this.dashboardToCreate.widgets = widgetsToSave;

    this.dashboardService
      .createTemplate(this.dashboardToCreate)
      .then((createdDashboard) => {
        console.log('created template: ', createdDashboard);
        this.selectedDashboardTemplate = createdDashboard;
        this.notificationService.open(
          `Dashboard template has been saved, success!`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          2000
        );
      })
      .catch(() => {
        this.notificationService.open(
          `There was an error creating your dashboard template!`,
          {
            class: 'ssi ssi-small-delete',
            color: '#F88C68'
          },
          2000
        );
      });
  }

  cancel(): void {
    window.history.go(-1);
  }

  async deleteTemplate(template: Dashboard): Promise<void> {
    const shouldDelete = await this.popup.confirm({
      title: `Delete '${template.name}' dashboard template?`,
      message: `You cannot undo this action`,
      okText: 'Delete template',
      windowClass: 'modal-rounded-corners',
      hideClose: true,
      iconClass: 'ssi ssi-delete-microcopy',
      backdrop: true
    });

    if (shouldDelete) {
      await this.dashboardService.deleteTemplate(template);
      if (this.selectedDashboardTemplate === template) {
        this.selectedDashboardTemplate = undefined;
      }
      this.notificationService.open(
        `Dashboard template has been successfully deleted!`,
        {
          class: 'ssi ssi-small-delete',
          color: '#F88C68'
        },
        2000
      );
    }
  }
}
