import './advertising-not-purchased.component.scss';
import { Component } from '@angular/core';

@Component({
  selector: 'ssi-advertising-not-purchased',
  templateUrl: './advertising-not-purchased.component.html',
  styles: []
})
export class AdvertisingNotPurchasedComponent {
  constructor() {}
}
