<div class="notification-container">
  <i *ngIf="iconClass"
     [ngStyle]="{'color': iconColor}"
     class="{{iconClass}}"></i>

  <p *ngIf="isStringMessage">{{message}}</p>
  <ng-container *ngIf="!isStringMessage">
    <ng-container *ngTemplateOutlet="message"></ng-container>
  </ng-container>

  <button (click)="dismiss()"><i class="ssi ssi-times"></i></button>
</div>