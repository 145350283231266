import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AccountSettingsComponent } from './account-settings.component';
import { OrloComponentsModule } from 'orlo-components';
import { LiveChatWidgetsModule } from './live-chat-widgets/live-chat-widgets.module';
import { BlockingWordsModule } from './blocking-words/blocking-words.module';
import { ManageSocialPolicyModule } from './manage-social-policy/manage-social-policy.module';
import { AuthPopupModule } from './auth-popup/auth-popup.module';
import { ManageAccountsModule } from './manage-accounts/manage-accounts.module';
import { ReauthAccountsModule } from './reauth-accounts/reauth-accounts.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.accountsettings',
          url: '/account-settings',
          component: AccountSettingsComponent,
          resolve: AccountSettingsComponent.resolve
        }
      ]
    }),
    LiveChatWidgetsModule,
    BlockingWordsModule,
    ManageSocialPolicyModule,
    AuthPopupModule,
    ManageAccountsModule,
    ReauthAccountsModule
  ],
  declarations: [AccountSettingsComponent],
  entryComponents: [AccountSettingsComponent]
})
export class AccountSettingsModule {}
