import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { WidgetMonitoringStreamComponent } from './widget-monitoring-stream.component';
import { InsightsPostModule } from '../../../../../../auth/insights/common/components/insights-posts/insights-post/insights-post.module';
import { VirtualScrollModule } from '../../../../../../../common/components/virtual-scroll/virtual-scroll.module';
import {
  LoaderModule,
  ObserveWidthAndHeightModule
} from '../../../../../../../common/directives';
import { NoResultsModule } from '../../../../../../../common/components/no-results/no-results.module';

@NgModule({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    VirtualScrollModule,
    InsightsPostModule,
    ObserveWidthAndHeightModule,
    NoResultsModule,
    LoaderModule
  ],
  exports: [WidgetMonitoringStreamComponent],
  declarations: [WidgetMonitoringStreamComponent]
})
export class WidgetMonitoringStreamModule {}
