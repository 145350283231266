<div class="cl-menu-container">
  <div class="head">
    <h2>Menu</h2>
  </div>

  <div class="body">

    <div>
      <h4>A</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-menu/cl-menu.component.html">
        View code on GitHub
      </a>
      <p>Basic version</p>
      <p>[headAlignment]="'top left'" [bodyAlignment]="'bottom left'</p>

      <ssi-menu #someMenu
                [headAlignment]="'top left'"
                [bodyAlignment]="'top left'">
        <ng-template ssiTemplateSelector="menuHead">
          <button class="g-btn g-btn-secondary bg-white">
            <span>Cick to open menu</span>
          </button>
        </ng-template>

        <ng-template ssiTemplateSelector="menuBody">
          <div class="menu-body-content">
            <span>Some content here; Some content here;</span>
          </div>
        </ng-template>
      </ssi-menu>
    </div>

    <div>
      <h4>B</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-menu/cl-menu.component.html">
        View code on GitHub
      </a>
      <p>List inside body - g-list and g-list-item global classes used</p>
      <p>[headAlignment]="'top right'" [bodyAlignment]="'bottom right'</p>

      <ssi-menu #someOtherMenu
                [headAlignment]="'top right'"
                [bodyAlignment]="'bottom right'">
        <ng-template ssiTemplateSelector="menuHead">
          <button class="g-btn g-btn-secondary bg-white">
            <span>Cick to open menu</span>
          </button>
        </ng-template>

        <ng-template ssiTemplateSelector="menuBody">
          <ul class="g-list">
            <li class="g-list-item"
                (click)="someOtherMenu.close();">
              <i class="ssi ssi-image-correct"></i>
              <span>Upload Image</span>
            </li>
            <li class="g-list-item"
                (click)="someOtherMenu.close();">
              <i class="ssi ssi-video-correct"></i>
              <span>Upload Video</span>
            </li>
          </ul>
        </ng-template>
      </ssi-menu>

    </div>
  </div>
</div>