import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageTeamComponent } from './manage-team.component';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterModule } from '../../../../../common/pipes/filter/filter.module';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';
import { PermissionsModule } from '../../../../../common/components/permissions/permissions.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FilterModule,
    SortByModule,
    PermissionsModule,
    NgbDropdownModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageTeam',
          url: '/team/:teamId/manage',
          component: ManageTeamComponent,
          resolve: ManageTeamComponent.resolve
        }
      ]
    })
  ],
  declarations: [ManageTeamComponent],
  providers: [],
  exports: [ManageTeamComponent]
})
export class ManageTeamModule {}
