import { mapToIterable } from '../utils';

export interface AccessOption {
  key: string;
  label: string;
}

export interface AccessOptions {
  private: AccessOption;
  public: AccessOption;
}

export const accessOptions: AccessOptions = {
  private: {
    key: 'private',
    label: 'Private'
  },
  public: {
    key: 'public',
    label: 'Public'
  }
};

export const accessOptionsIterable: AccessOption[] = mapToIterable(
  accessOptions
);
