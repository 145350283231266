import './content-generator.component.scss';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { API, Account } from '@ui-resources-angular';
import { workflowAccounts } from '../../../common-resolves';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { ContentGeneratorService, Feed } from './content-generator.service';
import { orderBy } from 'lodash-es';
import stripTags from 'strip';

export interface NewFeedForm {
  name: string;
  url: string;
  account_id: string;
}

@Component({
  selector: 'ssi-content-generator',
  templateUrl: './content-generator.component.html'
})
export class ContentGeneratorComponent implements OnInit {
  static resolve = [workflowAccounts];

  @Input() workflowAccounts: Account[];
  accounts: Account[];
  selectedAccount: Account;
  activeFeeds: Feed[] = [];
  selectedFeeds: Feed[] = [];
  articles;
  campaignId: string;
  newFeedForm: NgForm;
  model: NewFeedForm;
  totalFeedsLoaded = 0;
  totalFeedsFailed = 0;
  loading = false;

  constructor(
    private translate: TranslateService,
    private popup: PopupService,
    private api: API,
    private contentGeneratorService: ContentGeneratorService,
    private state: StateService
  ) {}

  ngOnInit(): void {
    this.accounts = this.workflowAccounts;
    if (this.state.params.query) {
      this.campaignId = JSON.parse(this.state.params.query).campaign_id;
    }
  }

  getAllFeedArticles() {
    let allArticles = [];
    let totalFeedsLoaded = (this.totalFeedsLoaded = 0);
    let totalFeedsFailed = 0;
    this.loading = true;
    const feedPromises = [];
    this.selectedFeeds.forEach((feed: Feed) => {
      feedPromises.push(
        this.contentGeneratorService
          .getFeedArticles(feed.url)
          .then((feedItems) => {
            const articles = feedItems.map((item) => {
              return {
                title: stripTags(item.title),
                description: stripTags(item.content),
                url: item.url,
                created_at: item.published_date
                  ? new Date(item.published_date)
                  : new Date(),
                img: item.img,
                feed
              };
            });
            allArticles = [...allArticles, ...articles];
          })
          .catch(() => {
            totalFeedsFailed++;
          })
          .finally(() => {
            totalFeedsLoaded++;
            this.totalFeedsLoaded = totalFeedsLoaded;
            this.totalFeedsFailed = totalFeedsFailed;
          })
      );
    });

    Promise.all(feedPromises)
      .then(() => {
        this.articles = orderBy(allArticles, ['created_at'], ['desc']);
        this.articles = this.articles.slice(0, 250);
      })
      .finally(() => (this.loading = false));
  }

  public selectedFeedsChange(selectedFeeds: Feed[]): void {
    this.selectedFeeds = selectedFeeds;
    this.getAllFeedArticles();
  }

  public async selectedAccountChanged(): Promise<void> {
    this.model = this.initFormModel();
    await this.setActiveFeeds();
    await this.getAllFeedArticles();
  }

  public initFormModel(): NewFeedForm {
    return {
      name: '',
      url: '',
      account_id: this.selectedAccount ? this.selectedAccount.id : undefined
    };
  }

  public async setActiveFeeds(): Promise<void> {
    if (!this.selectedAccount) {
      this.activeFeeds = [];
    }
    const allRssFeeds = await this.contentGeneratorService.getRssFeed();
    this.activeFeeds = allRssFeeds.filter((feed) => {
      return (
        !feed.account_id ||
        (this.selectedAccount && +this.selectedAccount.id === +feed.account_id)
      );
    });
  }

  public deleteFeed(feed: Feed): void {
    this.popup
      .confirm({
        title: this.translate.instant('DELETE_FEED'),
        message: this.translate.instant(
          'ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_RSS_FEED'
        )
      })
      .then((shouldDelete) => {
        if (shouldDelete) {
          this.api
            .del('contentGenerator/feed', { params: { feed_id: feed.id } })
            .then(() => this.setActiveFeeds());
        }
      });
  }

  public createFeed(feedForm: NgForm): void {
    this.contentGeneratorService
      .testFeedArticles(feedForm.form.value.url)
      .then(() => {
        this.contentGeneratorService
          .createRssFeed(feedForm.form.value)
          .then(() => {
            this.setActiveFeeds();
            this.model = this.initFormModel();
          });
      })
      .catch(() => {
        this.popup.alert({
          message: this.translate.instant(
            'THE_FEED_URL_YOU_ENTERED_WAS_INVALID'
          ),
          isError: true
        });
      });
  }
}
