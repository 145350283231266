import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageIftttAppletComponent } from './manage-ifttt-applet.component';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceModule } from './service/service.module';
import { ServiceSelectedModule } from './service-selected/service-selected.module';
import { VideoModalModule } from '../../../../common/components/video-modal/video-modal.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet',
          url: '/automation/manage?appletIndex',
          component: ManageIftttAppletComponent,
          resolve: ManageIftttAppletComponent.resolve,
          abstract: true
        }
      ]
    }),
    ServiceModule,
    ServiceSelectedModule,
    VideoModalModule
  ],
  declarations: [ManageIftttAppletComponent]
})
export class ManageIftttAppletModule {}
