import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PostSchedulerModule } from '../../../../common/components/post-scheduler/post-scheduler.module';
import { OverlayModule } from '@angular/cdk/overlay';

import { ClPostSchedulerComponent } from './cl-post-scheduler.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    PostSchedulerModule,
    OverlayModule
  ],
  exports: [ClPostSchedulerComponent],
  declarations: [ClPostSchedulerComponent]
})
export class ClPostSchedulerModule {}
