import { Injectable, Injector } from '@angular/core';
import { QueryPreset } from '../../../common/services/abstract-query-helper/abstract-query-helper.service';
import { Campaign, User, Account } from '@ui-resources-angular';
import { TeamsService, Team, Colleague } from '../../services/api';
import { KeyValueObject } from '../../util';
import { InboxActivityQuery, inboxActivityQuerySort } from './queries/activity';
import { OnInboxQuerySearch } from './queries/common';
import { InboxConversationQuery } from './queries/conversation';

export { InboxActivityQuery, inboxActivityQuerySort } from './queries/activity';
export {
  InboxConversationQuery,
  inboxConversationQuerySort
} from './queries/conversation';

@Injectable()
export class InboxQueryFactoryService {
  constructor(private injector: Injector) {}

  createActivityQuery({
    allAccounts,
    colleagues,
    campaigns,
    teams,
    authUser,
    savedQuery,
    onSearch = () => {},
    customPresets = []
  }: {
    allAccounts: Account[];
    colleagues: Colleague[];
    campaigns: Campaign[];
    teams: Team[];
    authUser: User;
    savedQuery?: KeyValueObject;
    onSearch?: OnInboxQuerySearch;
    customPresets?: QueryPreset[];
  }): InboxActivityQuery {
    const query = new InboxActivityQuery(
      this.injector,
      allAccounts,
      colleagues,
      campaigns,
      teams,
      authUser,
      savedQuery,
      onSearch,
      customPresets
    );
    query.reset();

    if (savedQuery) {
      if (Array.isArray(savedQuery.ac)) {
        const ac = {};
        savedQuery.ac.forEach((accountId) => {
          ac[accountId] = '1';
        });
        savedQuery.ac = ac;
      }

      if (savedQuery.ac) {
        if (!Array.isArray(allAccounts)) {
          throw new Error(
            `Value for 'inbox query factory all accounts' not in expected format.`
          );
        }

        Object.keys(savedQuery.ac).forEach((accountId) => {
          if (!allAccounts.find((account) => +account.id === +accountId)) {
            delete savedQuery.ac[accountId];
          }
        });
      }
      delete savedQuery.sort;
      Object.assign(query.apiQuery, savedQuery);
    }

    query.updateQueryState();

    return query;
  }

  createConversationQuery({
    allAccounts,
    colleagues,
    teams,
    authUser,
    savedQuery,
    onSearch = () => {},
    customPresets = []
  }: {
    allAccounts: Account[];
    colleagues: Colleague[];
    teams: Team[];
    authUser: User;
    savedQuery?: KeyValueObject;
    onSearch?: OnInboxQuerySearch;
    customPresets?: QueryPreset[];
  }): InboxConversationQuery {
    const query = new InboxConversationQuery(
      this.injector,
      allAccounts,
      colleagues,
      teams,
      authUser,
      savedQuery,
      onSearch,
      customPresets
    );

    query.reset();

    if (savedQuery) {
      delete savedQuery.sort;
      const accountIds = query.apiQuery.account_ids;
      Object.assign(query.apiQuery, savedQuery);
      query.apiQuery.account_ids = accountIds;
    }
    query.updateQueryState();
    return query;
  }
}
