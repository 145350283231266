import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ContentGeneratorComponent } from './content-generator.component';
import { ContentGeneratorFeedComponent } from './content-generator-feed/content-generator-feed.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OrloComponentsModule } from 'orlo-components';
import { AccountSelectorModule } from '../../../../common/components/account-selector/account-selector.module';
import { Checkbox2Module } from '../../../../common/components/checkbox-2/checkbox-2.module';
import { ProgressBarModule } from '../../../../common/components/progress-bar/progress-bar.module';
import { EnlargeImageModule } from '../../../../common/directives/enlarge-image/enlarge-image.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { TruncateModule } from '../../../../common/pipes/truncate/truncate.module';
import { SortByModule } from '../../../../common/pipes/sort-by/sort-by.module';
import { FilterModule } from '../../../../common/pipes/filter/filter.module';
import { ContentGeneratorService } from './content-generator.service';
import { ContentGeneratorArticleModule } from './content-generator-article/content-generator-article.module';

@NgModule({
  imports: [
    CommonModule,
    ContentGeneratorArticleModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.contentGenerator',
          url: '/rss-feeds?query&outbox_id&validate',
          component: ContentGeneratorComponent,
          resolve: ContentGeneratorComponent.resolve,
          params: {
            query: {
              dynamic: true
            },
            outbox_id: {
              dynamic: true
            },
            validate: {
              dynamic: true
            }
          }
        }
      ]
    }),
    FormsModule,
    TranslateModule,
    OrloComponentsModule,
    AccountSelectorModule,
    Checkbox2Module,
    ProgressBarModule,
    EnlargeImageModule,
    TooltipModule,
    TruncateModule,
    SortByModule,
    FilterModule
  ],
  declarations: [ContentGeneratorComponent, ContentGeneratorFeedComponent],
  providers: [ContentGeneratorService],
  entryComponents: [ContentGeneratorComponent]
})
export class ContentGeneratorModule {}
