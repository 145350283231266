import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { OutboxCampaignModalComponent } from './outbox-campaign-modal.component';

import { OrloComponentsModule } from 'orlo-components';
import { ListSelect2Module } from '../list-select2/list-select2.module';
import { MenuModule } from '../menu/menu.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    OrloComponentsModule,
    ListSelect2Module,
    MenuModule
  ],
  declarations: [OutboxCampaignModalComponent],
  exports: [OutboxCampaignModalComponent]
})
export class OutboxCampaignModalModule {}
