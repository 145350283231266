import { utils, Record } from 'js-data';
import { Note, NoteModel } from '../../note/services/noteModel';
import { Account } from '../../account/services/accountModel';
import {
  CrmExternalIntegration,
  CrmExternalIntegrationSearchResult
} from './crmExternalIntegrationModel';
import { api } from '../../core/services/api';
import { services } from '../../common';
import { Model } from '../../model';

interface CrmPersonExternal {
  external_id: string;
  id: string;
  integration_uuid: string;
}

export interface SocialProfile {
  id: string;
  name: string;
  username?: string;
}

export interface CrmPersonSocialProfile extends SocialProfile {
  account_id: number;
  account_type_id: number;
  social_id: string;
}

function findProfile(params): Promise<CrmPerson> {
  return api
    .get('crm/person', {
      params
    })
    .then(({ data }) => {
      if (typeof data.results !== 'undefined') {
        if (data.results.length > 0) {
          return services.models
            .get<CrmPersonModel>('crmPerson')
            .inject(data.results[0]);
        }
      } else {
        return services.models.get<CrmPersonModel>('crmPerson').inject(data);
      }
    });
}

export class CrmPerson extends Record {
  company_id: number;
  external: Array<{
    external_id: string;
    id: string;
    integration_uuid: string;
  }>;
  merged_into: string;
  name: string;
  profiles: Array<{
    account_id: number;
    account_type_id: number;
    id: string;
    name: string;
    social_id: string;
  }>;
  tags: string[];
  uuid: string;
  notes?: Note[];
  plugins: Array<{
    plugin_id: string;
    plugin_person_id: string;
  }>;

  linkExternalIntegration(
    integration: CrmExternalIntegration,
    integrationPerson: CrmExternalIntegrationSearchResult
  ): Promise<void> {
    return api
      .post('crm/personExternalIntegrationPerson', {
        person_id: this.uuid,
        integration_id: integration.id,
        external_id: integrationPerson.external_id
      })
      .then(({ data }: { data: { external: CrmPersonExternal } }) => {
        this.external.push(data.external);
      });
  }

  unlinkExternalIntegration(
    integration: CrmExternalIntegration,
    integrationPerson: CrmExternalIntegrationSearchResult
  ): Promise<void> {
    return api
      .del('crm/personExternalIntegrationPerson', {
        params: {
          person_id: this.uuid,
          integration_id: integration.id,
          external_id: integrationPerson.external_id
        }
      })
      .then(() => {
        this.external = this.external.filter(
          (external) =>
            !(
              external.integration_uuid === integration.id &&
              external.external_id === integrationPerson.external_id
            )
        );
      });
  }

  linkSocialProfile(
    socialProfile: SocialProfile,
    account: Account
  ): Promise<void> {
    return api
      .post('crm/personProfile', {
        person_id: this.uuid,
        account_id: account.id,
        account_type_id: account.account_type_id,
        social_id: socialProfile.id,
        name: socialProfile.name
      })
      .then(({ data }: { data: { profile: CrmPersonSocialProfile } }) => {
        this.profiles.push(data.profile);
      });
  }

  unlinkSocialProfile(crmSocialProfile: CrmPersonSocialProfile): Promise<void> {
    return api
      .del('crm/personProfile', {
        params: {
          person_id: this.uuid,
          profile_id: crmSocialProfile.id
        }
      })
      .then(() => {
        this.profiles = this.profiles.filter(
          (profile) => profile.id !== crmSocialProfile.id
        );
      });
  }

  mergeWith(person: CrmPerson): Promise<CrmPerson> {
    return api
      .post('crm/mergePeople', {
        keep: this.uuid,
        remove: person.uuid
      })
      .then(() => {
        services.models.get<CrmPersonModel>('crmPerson').eject(person);
        return findProfile({ id: this.uuid });
      });
  }

  getNotes(): Promise<Note[]> {
    if (!this.notes) {
      return services.models
        .get<NoteModel>('note')
        .findAll({
          subject: 'crm_person',
          subject_id: this.uuid
        })
        .then((notes) => {
          this.notes = notes;
          return this.notes;
        });
    } else {
      return utils.Promise.resolve(this.notes);
    }
  }

  addNote(content: string): Promise<Note> {
    return services.models
      .get<NoteModel>('note')
      .create({
        subject: 'crm_person',
        subject_id: this.uuid,
        content
      })
      .then((note) => {
        this.notes = this.notes || [];
        this.notes.push(note);
        return note;
      });
  }

  deleteNote(note: Note): Promise<void> {
    return note.destroy().then(() => {
      this.notes = this.notes.filter((iNote) => iNote !== note);
    });
  }

  setTags(tags: string[]): Promise<void> {
    return api
      .post(
        'crm/personTags',
        {
          id: this.uuid,
          tags
        },
        {
          params: {
            _method: 'PUT'
          }
        }
      )
      .then(({ data }) => {
        this.tags = data.tags;
      });
  }
}

const bannedNames = Object.freeze(['0']);

export class CrmPersonModel extends Model<CrmPerson> {
  constructor() {
    super('crmPerson', {
      endpoint: 'crm/person',
      idAttribute: 'uuid',
      recordClass: CrmPerson
    });
  }

  findFromSocialProfile(
    socialProfile: SocialProfile,
    account: Account
  ): Promise<CrmPerson> {
    const cachedProfile = this.getAll().find((person: CrmPerson) => {
      return person.profiles.some((profile) => {
        return (
          String(profile.account_type_id) === String(account.account_type_id) &&
          String(profile.social_id) === String(socialProfile.id)
        );
      });
    });

    if (cachedProfile) {
      return utils.Promise.resolve(cachedProfile);
    }

    return findProfile({
      profile_social_id: socialProfile.id,
      profile_account_type_id: account.account_type_id
    });
  }

  findFromExternalIntegration(
    integration: CrmExternalIntegration,
    integrationPerson: CrmExternalIntegrationSearchResult
  ): Promise<CrmPerson> {
    const cachedProfile = this.getAll().find((person: CrmPerson) => {
      return person.external.some((external) => {
        return (
          String(external.external_id) ===
            String(integrationPerson.external_id) &&
          String(external.integration_uuid) === String(integration.id)
        );
      });
    });

    if (cachedProfile) {
      return utils.Promise.resolve(cachedProfile);
    }

    return findProfile({
      external_external_id: integrationPerson.external_id,
      external_integration_uuid: integration.id
    });
  }

  createFromSocialProfile(
    socialProfile: SocialProfile,
    account: Account
  ): Promise<CrmPerson> {
    let name = socialProfile.name;
    if (bannedNames.includes(name) && socialProfile.username) {
      name = socialProfile.username;
    }
    return api
      .post('crm/person', {
        profiles: [
          {
            account_id: account.id,
            account_type_id: account.account_type_id,
            social_id: socialProfile.id,
            name
          }
        ],
        name
      })
      .then((response: { data: { uuid: string } }) => {
        return findProfile({ id: response.data.uuid });
      });
  }

  findOrCreateFromSocialProfile(
    socialProfile: SocialProfile,
    account: Account
  ): Promise<CrmPerson> {
    return this.findFromSocialProfile(socialProfile, account).then((person) => {
      if (person) {
        return person;
      } else {
        return this.createFromSocialProfile(socialProfile, account);
      }
    });
  }
}

export function crmPersonModelFactory(dataStore?) {
  return services.models.get('crmPerson') || new CrmPersonModel();
}
