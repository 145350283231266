<div class="advert-table-filters" [ngClass]="{'advert-table-filters-two-col': sortEnabled && !statusEnabled || !sortEnabled && statusEnabled, 'advert-table-filters-three-col': sortEnabled && statusEnabled}">
  <div class="advert-table-filters-sort" *ngIf="sortEnabled">
    <span class="advert-sort-action" (click)="showSortBox = !showSortBox">
      <i class="ssi ssi-sorting advert-table-filters-sort-icon"></i>
      <p>Sort by <span class="advertising-highlight">{{sortLabel}}</span></p>
    </span>
  </div>
  <div class="advert-sort-box" *ngIf="showSortBox">
    <!-- <div class="advert-sort-box-input">
      <input type="text" placeholder="Search campaign name...">
      <i class="ssi ssi-magnifying-glass"></i>
    </div> -->
    <ul class="advert-sort-box-options">
      <li class="advert-sort-box-option" [ngClass]="{active: activeSort === 'datetime'}" (click)="sortAccountAction('datetime')">
        <p translate="RECENTLY_POSTED"></p>
        <i class="ssi ssi-tick"></i>
      </li>
      <li class="advert-sort-box-option" [ngClass]="{active: activeSort === 'impressions'}" (click)="sortAccountAction('impressions')">
        <p translate="BEST_IMPRESSION_RATE"></p>
        <i class="ssi ssi-tick"></i>
      </li>
      <li class="advert-sort-box-option" [ngClass]="{active: activeSort === 'clicks'}" (click)="sortAccountAction('clicks')">
        <p translate="BEST_CLICK_RATE"></p>
        <i class="ssi ssi-tick"></i>
      </li>
      <li class="advert-sort-box-option" [ngClass]="{active: activeSort === 'engagements'}" (click)="sortAccountAction('engagements')">
        <p translate="BEST_ENGAGEMENT_RATE"></p>
        <i class="ssi ssi-tick"></i>
      </li>
    </ul>
  </div>
  <ul class="advert-table-filters-list advert-table-filters-soc">
    <li [ngClass]="{active: activeSocialFilter === 0}" (click)="filterAccount.emit(0)">
      <p translate="ALL_NETWORKS"></p>
    </li>
    <li [ngClass]="{active: activeSocialFilter === 3}" (click)="filterAccount.emit(3)">
      <p>Facebook</p>
    </li>
    <li [ngClass]="{active: activeSocialFilter === 4}" (click)="filterAccount.emit(4)">
      <p>Linkedin</p>
    </li>
    <!-- <li [ngClass]="{active: activeSocialFilter === 2}" (click)="filterAccount.emit(2)"><p>Twitter</p></li> -->
  </ul>
  <ul class="advert-table-filters-list advert-table-filters-status" *ngIf="statusEnabled">
    <li class="table-filter-status-item" [ngClass]="{active: activeStatusFilter === 'all'}" (click)="filterStatus.emit('all')"><p>All</p></li>
    <li class="table-filter-status-item-live" [ngClass]="{active: activeStatusFilter === 'live'}" (click)="filterStatus.emit('live')"><p>Live</p></li>
    <li class="table-filter-status-item-scheduled" [ngClass]="{active: activeStatusFilter === 'scheduled'}" (click)="filterStatus.emit('scheduled')"><p>Scheduled</p></li>
    <li class="table-filter-status-item-paused" [ngClass]="{active: activeStatusFilter === 'paused'}" (click)="filterStatus.emit('paused')"><p>Paused</p></li>
    <li class="table-filter-status-item-completed" [ngClass]="{active: activeStatusFilter === 'completed'}" (click)="filterStatus.emit('completed')"><p>Complete</p></li>
  </ul>
</div>