import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';

import { ClDateTimePickerComponent } from './cl-date-time-picker.component';
import { DateTimePickerModule } from '../../../../common/components/date-time-picker/date-time-picker.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    DateTimePickerModule
  ],
  exports: [ClDateTimePickerComponent],
  declarations: [ClDateTimePickerComponent]
})
export class ClDateTimePickerModule {}
