import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TagsInput2Component } from './tags-input-2.component';

@NgModule({
  declarations: [TagsInput2Component],
  imports: [CommonModule, NgbModule, FormsModule],
  exports: [TagsInput2Component]
})
export class TagsInput2Module {}
