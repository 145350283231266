<div class="cl-dropdown-select-b-container">
  <h4>B</h4>
  <a target="_blank"
     href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-dropdown-select/cl-dropdown-select-b/cl-dropdown-select-b.component.html">
    View code on GitHub
  </a>
  <p>headButtonStyleVariant="analytics"</p>
  <p>MD size - Basic single-select version</p>
  <ssi-dropdown-select-2 size="md"
                         name="accounts"
                         headButtonStyleVariant="analytics"
                         [required]="true"
                         [multiple]="false"
                         [filterable]="true"
                         [placeholdersKeyword]="'account'"
                         [options]="accounts"
                         [(ngModel)]="selectedAccounts"
                         (ngModelChange)="selectedAccountsChanged($event)"></ssi-dropdown-select-2>
</div>