<div class="sentiment-change-section">
  <div class="section-chart-wrap">
    <ul class="section-chart-meta">
      <li
        ssiTooltip="This is the total number of conversations that took place during the reported time period. Conversations are groups of inbound messages and outbound replies from the same thread"
        tooltipWindowClass="tooltip-chunky-big"
        class="section-chart-meta-item total-tile"
      >
        <i class="ssi ssi-total-conversations-stat total-tile-icon"></i>
        <p class="total-tile-meta">{{'TOTAL_CONVERSATIONS' | translate}}</p>
        <p class="total-tile-amount">
          <ssi-big-number 
            [number]="totalConversations">
          </ssi-big-number></p>
      </li>
      <li
        ssiTooltip="This is the total number of inbound messages and outbound replies sent in all conversations"
        tooltipWindowClass="tooltip-chunky-big"
        class="section-chart-meta-item total-tile"
      >
        <i class="ssi ssi-av-total-messages-stat total-tile-icon"></i>
        <p class="total-tile-meta">{{'TOTAL_MESSAGES' | translate}}</p>
        <p class="total-tile-amount">
          <ssi-big-number 
            [number]="totalMessages">
          </ssi-big-number></p>
      </li>
    </ul>
    <ssi-highchart
      [config]="chartConfig"
    ></ssi-highchart>
  </div>

  <div class="section-grid section-grid-thirds">
    <div *ngFor="let data of chartConfig.series[0].data" class="section-grid-item">
      <div class="comparison-tile">
        <div class="comparison-tile-icons">
          <i class="comparison-tile-icon ssi" [ngClass]="'ssi-' + data[0].split(':')[1].trim().replace(' ', '-').toLowerCase() + '-new'"></i>
          <i class="comparison-tile-break ssi ssi-line-arrow-small-down"></i>
          <i class="comparison-tile-icon ssi" [ngClass]="'ssi-' + data[1].split(':')[1].trim().replace(' ', '-').toLowerCase() + '-new'"></i>
        </div>
        <div class="comparison-tile-meta">
          <p
            class="comparison-tile-meta-amount"
            [ngClass]="'comparison-tile-meta-amount-' + data[1].split(':')[1].trim().replace(' ', '-').toLowerCase()"
            [innerHTML]="data[2] | bigNumber"
          ></p>
          <p class="comparison-tile-meta-copy">
            <span [innerHTML]="data[0].split(':')[1] === data[1].split(':')[1] ? 'Conversations stayed' : 'Conversations went from'"></span>
            <span [innerHTML]="data[0].split(':')[1]"></span>
            <span *ngIf="data[0].split(':')[1] !== data[1].split(':')[1]">To {{data[1].split(':')[1]}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- <ul>
    <li>
      <p>551</p>
      <p>Messages stayed Negative</p>
      <i class="ssi ssi-line-arrow-small-down"></i>
      <i class="ssi ssi-positive-new"></i>
      <i class="ssi ssi-semi-positive-new"></i>
      <i class="ssi ssi-neutral-new"></i>
      <i class="ssi ssi-semi-negative-new"></i>
      <i class="ssi ssi-negative-new"></i>
    </li>
  </ul> -->
</div>