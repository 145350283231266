import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';

import { MarketingComponent } from './marketing.component';
import { MarketingService } from './marketing.service';

import { BuildModule } from './build/build.module';
import { ReportModule } from './report/report.module';

@NgModule({
  declarations: [MarketingComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.marketing',
          url: '/marketing',
          abstract: true, // will auto redirect to 'auth.analytics.marketing.build' child state
          component: MarketingComponent
        }
      ]
    }),
    BuildModule,
    ReportModule
  ],
  providers: [MarketingService],
  entryComponents: [MarketingComponent]
})
export class MarketingModule {}
