import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingPresetListComponent } from './advertising-preset-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { AdvertisingNavModule } from '../advertising-nav/advertising-nav.module';
import { AdvertisingTableFiltersModule } from '../advertising-table/advertising-table-filters/advertising-table-filters.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.presetList',
          url: '/presets',
          component: AdvertisingPresetListComponent,
          resolve: AdvertisingPresetListComponent.resolve
        }
      ]
    }),
    AdvertisingTableFiltersModule,
    AdvertisingNavModule,
    TooltipModule
  ],
  declarations: [AdvertisingPresetListComponent],
  entryComponents: [AdvertisingPresetListComponent]
})
export class AdvertisingPresetListModule {}
