import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import moment from 'moment';
import { filter } from 'rxjs-compat/operator/filter';

export enum FilterType {
  eq = 'eq',
  match = 'match',
  range = 'range',
  in = 'in',
  any = 'any',
  all = 'all',
  noneOf = 'none_of',
  semanticSearch = 'semantic_search'
}

export enum AggregateFunction {
  MIN = 'MIN',
  MAX = 'MAX',
  SUM = 'SUM',
  AVG = 'AVG',
  COUNT = 'COUNT'
}

export enum FieldType {
  MIN = 'BOOL',
  NUMBER = 'NUMBER',
  INT = 'INT',
  BOOL = 'BOOL',
  STRING = 'STRING',
  DATETIME = 'DATETIME',
  TEXT = 'TEXT',
  VECTOR = 'VECTOR'
}

export enum FieldName {
  ID = 'ID',
  ResponseTime = 'Response Time',
  CreatedAt = 'Created At',
  IsPrivate = 'Is Private',
  Tags = 'Tags',
  Sentiment = 'Sentiment',
  Language = 'Language',
  Channel = 'Channel',
  Age = 'Age',
  Gender = 'Gender',
  Location = 'Location',
  Country = 'Country',
  State = 'State',
  City = 'City',
  Locality = 'Locality',
  Emotion = 'Emotion',
  ContentVector = 'Content Vector',
  Classification = 'Classification',
  DiscoveredTheme = 'Discovered Theme',
  Interests = 'Interests',
  Industries = 'Industries',
  BioKeywords = 'Bio Keywords',
  Author = 'Author',
  Visibility = 'Visibility'
}

// as returned by `insights/insightsSchema` endpoint
export interface DefinedField {
  name: FieldName;
  type: FieldType;
  can_be_measure: boolean;
  can_be_dimension: boolean;
  supported_aggregate_functions: AggregateFunction[];
}
interface FilterField extends DefinedField {
  supportedFilterTypes: FilterType[];
  preferedFilterType: FilterType;
}

export const formatFilterTypeData = (filterData: any, type: FilterType) => {
  switch (type) {
    case FilterType.eq:
      return filterData;
      break;
    case FilterType.match:
      return filterData;
      break;
    case FilterType.range:
      let range;
      if (!filterData.pointClicked) {
        range = determineRangeAndBucket(filterData, null);
      } else {
        range = determineRangeAndBucket(
          filterData.pointClicked,
          filterData.allPoints
        );
      }
      return {
        gte: range.start,
        lte: range.end
      };
      break;
    case FilterType.in:
      return [filterData];
      break;
    case FilterType.any:
      return filterData;
      break;
    case FilterType.all:
      return [filterData];
      break;
    case FilterType.noneOf:
      return filterData;
      break;
    case FilterType.semanticSearch:
      return {
        query: filterData,
        min_score: 0.65
      };
      break;

    default:
      break;
  }
};

export const getBucketSize = (date: string): 'hour' | 'day' => {
  if (date.includes('T')) {
    return 'hour';
  }

  return 'day';
};

/** Handling click event on the Highcharts graph.
 * Also determining the bucket size/aggregation to send the right date in the API call for drilldown
 * @param {string} pointClicked - this is a single category from xAxis `categories` from the highchart click event
 * @param {string} allPoints - this is all the values from xAxis `categories` from the highchart
 * @returns Start and end date to be passed to the BE
 */
export const determineRangeAndBucket = (
  pointClicked: string,
  allPoints: Array<string>
): { start: Date | string; end: Date | string } => {
  let formatted: { start: Date | string; end: Date | string };

  if (!allPoints) {
    formatted = {
      start: moment(pointClicked).toISOString(true),
      end: moment(pointClicked).add(1, 'day').toISOString(true)
    };
    return formatted;
  }

  const first3Dates = allPoints
    .slice(0, 3)
    .map((d) => moment(d).format('YYYY-MM-DD'));
  const datesFromSameMonth = first3Dates.every((d, i, arr) => {
    return d.split('-').pop() === arr[0].split('-').pop();
  });

  if (pointClicked.includes('T')) {
    // hour - aggregated by hour across 24 hours in a day (array of 24 items),
    // sometimes, if you ask for the last two days, we'll receive 48 items across 48 hours so can't always rely on 24
    formatted = {
      start: moment(pointClicked, 'DD/MM/YYYYTHH').toISOString(true),
      end: moment(pointClicked, 'DD/MM/YYYYTHH')
        .add(1, 'hour')
        .toISOString(true)
    };
    return formatted;
  } else if (!datesFromSameMonth) {
    // month - aggregated by month across 12 months (sometimes array of 13 items - zero-based)
    // i.e. Last year would return 13 items across last year months until now
    formatted = {
      start: startOfMonth(moment(pointClicked, 'DD/MM/YYYY').toISOString(true)),
      end: endOfMonth(moment(pointClicked, 'DD/MM/YYYY').toISOString(true))
    };

    return formatted;
  } else {
    // day - aggregated by day across 7 days (sometimes array of 8 items - zero-based)
    // day - aggregated by day across 3 months (sometimes array of ~90 items - zero-based)
    // treat as day aggregation, i.e. "last quarter" would return 93 points for a graph
    formatted = {
      start: startOfDay(moment(pointClicked, 'DD/MM/YYYY').toISOString(true)),
      end: endOfDay(moment(pointClicked, 'DD/MM/YYYY').toISOString(true))
    };

    return formatted;
  }
};

export type FiltersFieldDefinition = {
  [name in FieldName]: FilterField;
};

export const filtersFieldDefinitions: FiltersFieldDefinition = {
  [FieldName.ID]: {
    name: FieldName.ID,
    type: FieldType.STRING,
    can_be_measure: true,
    can_be_dimension: false,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [],
    supported_aggregate_functions: [AggregateFunction.COUNT]
  },
  [FieldName.ResponseTime]: {
    name: FieldName.ResponseTime,
    type: FieldType.NUMBER,
    can_be_measure: true,
    can_be_dimension: false,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: [
      AggregateFunction.SUM,
      AggregateFunction.AVG,
      AggregateFunction.MIN,
      AggregateFunction.MAX
    ]
  },
  [FieldName.CreatedAt]: {
    name: FieldName.CreatedAt,
    type: FieldType.DATETIME,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.range,
    supportedFilterTypes: [FilterType.range],
    supported_aggregate_functions: []
  },
  [FieldName.IsPrivate]: {
    name: FieldName.IsPrivate,
    type: FieldType.BOOL,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.Tags]: {
    name: FieldName.Tags,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.all,
    supportedFilterTypes: [FilterType.all, FilterType.any, FilterType.noneOf],
    supported_aggregate_functions: []
  },
  [FieldName.Sentiment]: {
    name: FieldName.Sentiment,
    type: FieldType.INT,
    can_be_measure: true,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: [
      AggregateFunction.SUM,
      AggregateFunction.AVG,
      AggregateFunction.MIN,
      AggregateFunction.MAX
    ]
  },
  [FieldName.Language]: {
    name: FieldName.Language,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.in,
    supportedFilterTypes: [FilterType.in],
    supported_aggregate_functions: []
  },
  [FieldName.Channel]: {
    name: FieldName.Channel,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.in,
    supportedFilterTypes: [FilterType.in],
    supported_aggregate_functions: []
  },
  [FieldName.Age]: {
    name: FieldName.Age,
    type: FieldType.INT,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.Gender]: {
    name: FieldName.Gender,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.Location]: {
    name: FieldName.Location,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.all,
    supportedFilterTypes: [FilterType.all],
    supported_aggregate_functions: []
  },
  [FieldName.Country]: {
    name: FieldName.Country,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.State]: {
    name: FieldName.State,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.City]: {
    name: FieldName.City,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.Locality]: {
    name: FieldName.Locality,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.Emotion]: {
    name: FieldName.Emotion,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.in,
    supportedFilterTypes: [FilterType.in],
    supported_aggregate_functions: []
  },
  [FieldName.ContentVector]: {
    name: FieldName.ContentVector,
    type: FieldType.VECTOR,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.semanticSearch,
    supportedFilterTypes: [FilterType.semanticSearch],
    supported_aggregate_functions: []
  },
  [FieldName.Classification]: {
    name: FieldName.Classification,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.Interests]: {
    name: FieldName.Interests,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.in,
    supportedFilterTypes: [FilterType.in],
    supported_aggregate_functions: []
  },
  [FieldName.Industries]: {
    name: FieldName.Industries,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.in,
    supportedFilterTypes: [FilterType.in],
    supported_aggregate_functions: []
  },
  [FieldName.BioKeywords]: {
    name: FieldName.BioKeywords,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.in,
    supportedFilterTypes: [FilterType.in],
    supported_aggregate_functions: []
  },
  [FieldName.Author]: {
    name: FieldName.Author,
    type: FieldType.STRING,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.in,
    supportedFilterTypes: [FilterType.in],
    supported_aggregate_functions: []
  },
  [FieldName.Visibility]: {
    name: FieldName.IsPrivate,
    type: FieldType.BOOL,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  },
  [FieldName.DiscoveredTheme]: {
    name: FieldName.DiscoveredTheme,
    type: FieldType.INT,
    can_be_measure: false,
    can_be_dimension: true,
    preferedFilterType: FilterType.eq,
    supportedFilterTypes: [FilterType.eq],
    supported_aggregate_functions: []
  }
};
