<div class="stream-builder-nav">
  <div class="back">
    <a uiSref="^.query"><i class="ssi ssi-line-arrow-small-up left"></i> Back</a>
    <span *ngIf="streamQuery.id" (click)="deleteMenu.hide(); deleteMenu.show($event, {streamQuery: streamQuery});" class="stream-builder-nav-action">
      <i class="ssi ssi-delete"></i><span>Delete stream</span>
    </span>
  </div>
  <div class="nav-list">
    <h3 class="active">Description<i class="ssi ssi-tick"></i></h3>
    <div class="line active completed"></div>
    <h3 class="active">Build Query<i class="ssi ssi-tick"></i></h3>
    <div class="line active completed final"></div>
    <h3 class="active">Sources & Save</h3>
  </div>
  <div [class.disabled]="!streamQuery.sources.length && !streamQuery.listening_group_ids.length " class="next">
    <orlo-button
      [state]="'Primary'"
      [text]="'Save Stream'"
      (click)="saveStep()"
      [disabled]="!streamQuery.sources.length && !streamQuery.listening_group_ids.length"
      [textColor]="'#FFFFFF'"
      [bgColor]="'#12ACA4'"
    ></orlo-button>
  </div>
</div>
<div class="step-content">

  <div class="row-content language-filtering">
    <div class="form-field">
      <div class="form-field">
        <h2>Specify sources</h2>
        <p>Messages will be collected from these sources</p>
      </div>
      <div class="dropdown-container">
        <label>Include these Sources:</label>
        <div class="input-wrapper">
          <ssi-multi-select-chips
            [selectedSources]="streamQuery.sources"
            (onSelectedSourcesChange)="onSelectedSourcesChange($event)"
          ></ssi-multi-select-chips>
        </div>
      </div>
      <div class="dropdown-container" *ngIf="socialListeningEnabled && listeningGroupsIterable?.length > 0">
        <label>Include these Facebook Listening groups:</label>
        <div class="input-wrapper">
          <ssi-multi-select-chips
            [providedSources]="listeningGroups"
            [selectedSources]="streamQuery.listening_group_ids"
            (onSelectedSourcesChange)="onSelectedListeningGroupChange($event)"
          ></ssi-multi-select-chips>
        </div>
      </div>
      <div class="row-content">
        <ssi-toggle-switch
          [(ngModel)]="twitterSource"
          (ngModelChange)="onTwitterSourceToggle($event)"
          label="X"
          [disabled]="false"
          toggleText="X source {{ twitterSource ? 'on' : 'off'}}"
        ></ssi-toggle-switch>

        <ssi-rounded-box-check
          (click)="includeRetweets = true"
          [selected]="includeRetweets"
          text="Include Reposts"
          borderColor="#14BAE3"
          bgColor="#F8F9FD"
        > </ssi-rounded-box-check>
        <ssi-rounded-box-check
          (click)="includeRetweets = false"
          [selected]="!includeRetweets"
          text="No Reposts"
          borderColor="#14BAE3"
          bgColor="#F8F9FD"
        ></ssi-rounded-box-check>
      </div>
    </div>
  </div>

  <hr>

  <div class="row-content language-filtering">
    <div class="form-field">
      <h2>Filter selected languages</h2>
      <p>Messages will be collected in the languages you choose</p>
      <div class="dropdown-container">
        <label>Languages</label>
        <div class="input-wrapper">
          <ssi-dropdown-select-2
            name="languages"
            class="languages-selector"
            headButtonStyleVariant="analytics"
            size="lg"
            [required]="true"
            [filterable]="true"
            [filterPlaceholder]="'Search for a language...'"
            [options]="languages"
            [(ngModel)]="selectedLanguage"
            (ngModelChange)="onSelectedLanguageChange($event)"
          >
            <ng-template
              ssiTemplateSelector="headButtonLabel"
              let-expanded="expanded"
            >
              <div
                class="select-language-placeholder"
                *ngIf="!selectedLanguage"
              >
                <span>Any language</span>
              </div>
              <div
                class="select-language-label"
                *ngIf="selectedLanguage"
              >
                <span>{{selectedLanguage.label}}</span>
              </div>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
      </div>
    </div>
  </div>
</div>

<ssi-context-menu #deleteMenu
                  (ssiOffClick)="deleteMenu.hide();">
  <ng-template let-ctx>
    <ssi-confirm-action title="Are you sure you want to delete?"
                        dismissText="Cancel"
                        confirmText="Delete"
                        dismissTextColour="#43537f"
                        confirmTextColour="#F88C68"
                        (onDismiss)="deleteMenu.hide();"
                        (onConfirm)="deleteMenu.hide(); deleteStream(ctx.streamQuery);"></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>