import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { IconModule } from '../../../../../../common/components/icon/icon.module';
import { DropdownSelect2Module } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';

import { DashboardNavbarComponent } from './dashboard-navbar.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DashboardNavbarComponent],
  imports: [
    CommonModule,
    NgbModule,
    TooltipModule,
    IconModule,
    FormsModule,
    DropdownSelect2Module
  ],
  exports: [DashboardNavbarComponent]
})
export class DashboardNavbarModule {}
