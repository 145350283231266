import './publisher-schedule-action-buttons.component.scss';

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { OutboxPublisher } from '@ui-resources-angular';
import { AsyncTracker } from 'angular-async-tracker';
import {
  PublisherActive,
  PUBLISHER_ACTIVE,
  PublisherDisableOptions
} from '../publisher-active';

@Component({
  selector: 'ssi-publisher-schedule-action-buttons',
  templateUrl: './publisher-schedule-action-buttons.component.html',
  styles: []
})
export class PublisherScheduleActionButtonsComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() postInvalidTooltipTemplate: TemplateRef<any>;
  @Input() publishingLoadingTracker: AsyncTracker;
  @Input() disable: PublisherDisableOptions;

  @Output() back = new EventEmitter();
  @Output() publish = new EventEmitter();
  @Output() showPostPreview = new EventEmitter();

  constructor(
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive
  ) {}

  ngOnInit() {}
}
