import angular from 'angular';
import controller from './auth.ctrl';
import template from './auth.html';

export default angular
  .module('states.auth', [])
  .component('ssiAuthContainer', {
    template,
    controller,
    controllerAs: 'vm'
  })
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider.state('auth', {
      abstract: true,
      url: '?showPublisher',
      component: 'ssiAuthContainer'
    });
  });
