import './topic-usage.component.scss';

import { Component, Input, OnInit } from '@angular/core';
import { FilterPeriod } from '../../chatbot-analytics.service';

@Component({
  selector: 'ssi-topic-usage',
  templateUrl: './topic-usage.component.html',
  styles: []
})
export class TopicUsageComponent implements OnInit {
  @Input() topicUsage: { name: string; count: number; percent: number }[];
  @Input() filterPeriod: FilterPeriod;
  proTipOpen = false;

  constructor() {}

  ngOnInit(): void {}
}
