import 'exports-loader?trackJs!trackjs';
import { lazyLoadPolyfills } from './apps/angular/polyfills';
import angular from 'angular';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';
import { bootstrap } from './apps/angular/bootstrap';
import { AppModule } from './apps/angular/app.module';

lazyLoadPolyfills().then(() => {
  angular.element(document).ready(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule, { preserveWhitespaces: true })
      .then((platformRef) => {
        const upgrade = platformRef.injector.get<UpgradeModule>(UpgradeModule);
        bootstrap(upgrade);
      });
  });
});
