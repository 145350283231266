import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AdvertisingNavComponent } from './advertising-nav.component';

@NgModule({
  imports: [CommonModule, UIRouterModule, TranslateModule],
  declarations: [AdvertisingNavComponent],
  exports: [AdvertisingNavComponent]
})
export class AdvertisingNavModule {}
