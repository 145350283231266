import { transform, isEqual, isObject } from 'lodash-es';

export function diff(object: any = {}, base: any = {}) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key]) ? diff(value, base[key]) : value;
    }
  });
}

export function merge(base: any = {}, additional: any = {}) {
  return Object.assign({}, base, additional);
}
