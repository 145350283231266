<div
  class="message-box-footer"
  *ngIf="!isPrivate">
  <ssi-message-actions-inline
    *ngIf="!isPrivate"
    [activity]="activity"
    [account]="account"
    [selfPenned]="selfPenned"
    [assignedSuccess]="assignedSuccess"
    [tagsTooltip]="tagsTooltip"
    [sentiment]="sentiment"
    [shouldEnableSentimentShortcut]="!selfPenned && isActive"
    [sentimentOptions]="sentimentOptions"
    [likeConfig]="likeConfig"
    [hideCommentConfig]="hideCommentConfig"
    [isInbox]="isInbox"
    [activeTags]="activeTags"
    [showConversationAssignTo]="showConversationAssignTo"
    [showDelete]="showDelete"
    [showRedact]="showRedact"
    [showViewNatively]="showViewNatively"
    [isConvertedToActivity]="isConvertedToActivity"
    [notes]="notes"
    (viewNativelyClicked)="viewNativelyClicked.emit()"
    (showActionsChange)="showActionsChange.emit($event)"
    (toggleLikeClicked)="toggleLikeClicked.emit()"
    (toggleShowHideCommentClicked)="toggleShowHideCommentClicked.emit($event)"
    (conversationAssignToClicked)="conversationAssignToClicked.emit(); showActions = false"
    (deleteClicked)="deleteClicked.emit()"
    (redactConfirmed)="redactConfirmed.emit()"
    (hideActivity)="hideActivity.emit()"
  ></ssi-message-actions-inline>

  <ul class="message-box-footer-actions">
    <li
      *ngIf="stats && isRoot && account.account_type_id !== '6'"
      class="message-box-footer-action message-box-footer-action-button"
      (click)="$event.stopPropagation(); showStats.emit()">
      <p>{{statsShown ? 'Close' : 'View'}} post stats</p>
      <i
        class="ssi"
        [ngClass]="{'ssi-arrow-down-correct': !statsShown, 'ssi-arrow-up': statsShown}">
      </i>
    </li>

    <li *ngIf="retweetConfig.enabled" class="message-box-footer-action">
      <p (click)="$event.stopPropagation(); retweetMenuVisible = !retweetMenuVisible; retweetRequested = false;"><i class="ssi ssi-retweet-new"></i> Repost</p>
      <div class="retweet-confirmation retweet-box" *ngIf="retweetSuccess && retweetRequested">
        <span (click)="$event.stopPropagation(); retweetMenuVisible = false; retweetRequested = false;" class="retweet-box-close"><i class="ssi ssi-times"></i></span>
        <i class="ssi ssi-microcopy-bird"></i>
        <p>This has been reposted in the X-verse!</p>
      </div>
      <div *ngIf="retweetMenuVisible && !retweetRequested" class="retweet-box" (ssiOffClick)="retweetMenuVisible = false;">
        <ul class="retweet-box-actions">
          <li class="retweet-box-action" [ngClass]="{'retweet-box-action-active': retweetExpanded}">
            <p (click)="$event.stopPropagation(); retweetExpanded = !retweetExpanded">
              <i class="ssi ssi-retweet-new retweet-box-action-icon">
              </i>Auto repost <i class="ssi ssi-arrow-down-correct"></i>
            </p>
            <ul *ngIf="retweetExpanded" class="retweet-box-sub-actions custom-scrollbar">
              <li class="retweet-box-sub-action" [ngClass]="{'retweet-box-sub-action-disabled': retweetConfig.currentAccount.disabled}">
                <p (click)="retweet.emit(); retweetRequested = true; retweetMenuVisible = false;">
                  <i
                    [ngClass]="account.socialNetwork.icon.web"
                    class="retweet-box-action-icon"
                  ></i> {{retweetConfig.currentAccount.name}}
                </p>
              </li>
              <ng-container *ngFor="let account of retweetConfig.otherAccounts">
                <li *ngIf="account.id !== retweetConfig.currentAccount.accountId && account.account_type_name === 'Twitter'" class="retweet-box-sub-action">
                  <p (click)="retweet.emit(account); retweetRequested = true; retweetMenuVisible = false;">
                    <i
                      [ngClass]="account.socialNetwork.icon.web"
                      class="retweet-box-action-icon"
                    ></i> {{account.displayName}}
                  </p>
                </li>
              </ng-container>
            </ul>
          </li>
          <li class="retweet-box-action">
            <p (click)="quoteRetweet.emit(); retweetMenuVisible = false;"><i class="ssi ssi-quote-retweet retweet-box-action-icon"></i> Quote repost</p>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>