import { mapToIterable } from '../../../../../common/utils';

export interface WidgetType {
  key: string;
  label: string;
  iconClassName: string;
  tooltipExplainer: string;
  defaultSize: { w: number; h: number };
}

export interface WidgetTypes {
  bar: WidgetType;
  donut: WidgetType;
  line: WidgetType;
  pie: WidgetType;
  kpi: WidgetType;
  table: WidgetType;
  sentiment: WidgetType;
  swot: WidgetType;
  big: WidgetType;
  influencers: WidgetType;
  interests: WidgetType;
  industries: WidgetType;
  trustometer: WidgetType;
  positive_themes: WidgetType;
  negative_themes: WidgetType;
  theme_cloud: WidgetType;
  trustometer_over_time: WidgetType;
  // totals: WidgetType;
}

export const widgetTypes: WidgetTypes = {
  bar: {
    key: 'bar',
    label: 'Bar Chart',
    iconClassName: 'ssi ssi-bar-chart-vertical',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  donut: {
    key: 'donut',
    label: 'Donut Chart',
    iconClassName: 'ssi ssi-doughnut-chart',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  line: {
    key: 'line',
    label: 'Line Chart',
    iconClassName: 'ssi ssi-line-chart',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  pie: {
    key: 'pie',
    label: 'Pie Chart',
    iconClassName: 'ssi ssi-pie-chart-vis',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  kpi: {
    key: 'kpi',
    label: 'Totals',
    iconClassName: 'ssi ssi-line-chart',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 1,
      h: 1
    }
  },
  table: {
    key: 'table',
    label: 'Table',
    iconClassName: 'ssi ssi-table',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  sentiment: {
    // add icon
    key: 'sentiment',
    label: 'Sentiment Gauge',
    iconClassName: 'ssi ssi-gauge-chart',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 1,
      h: 1
    }
  },
  swot: {
    // add icon
    key: 'swot',
    label: 'SWOT',
    iconClassName: 'ssi ssi-swot-chart',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  big: {
    // add icon
    key: 'big',
    label: 'Theme Map',
    iconClassName: 'ssi ssi-theme-mapping',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 3,
      h: 2
    }
  },
  influencers: {
    key: 'influencers',
    label: 'Test influencers',
    iconClassName: 'ssi ssi-theme-mapping',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  positive_themes: {
    // add icon
    key: 'positive_themes',
    label: 'Top Positive Themes',
    iconClassName: 'ssi ssi-later',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 1,
      h: 1
    }
  },
  negative_themes: {
    // add icon
    key: 'negative_themes',
    label: 'Top Negative Themes',
    iconClassName: 'ssi ssi-later',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 1,
      h: 1
    }
  },
  theme_cloud: {
    // add icon
    key: 'theme_cloud',
    label: 'Theme Cloud',
    iconClassName: 'ssi ssi-theme-cloud',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 2,
      h: 2
    }
  },
  interests: {
    // add icon
    key: 'interests',
    label: 'Top Interests',
    iconClassName: 'ssi ssi-audienceanalysis',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 4,
      h: 2
    }
  },
  industries: {
    key: 'industries',
    label: 'Top Industries',
    iconClassName: 'ssi ssi-audienceanalysis',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 4,
      h: 2
    }
  },
  trustometer: {
    // add icon
    key: 'trustometer',
    label: 'Trustometer',
    iconClassName: 'ssi ssi-gauge-chart',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 1,
      h: 3
    }
  },
  trustometer_over_time: {
    // add icon
    key: 'trustometer_over_time',
    label: 'Trustometer Over Time',
    iconClassName: 'ssi ssi-gauge-chart',
    tooltipExplainer:
      'Full metric explainer right here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tal sunom aliqua.',
    defaultSize: {
      w: 3,
      h: 1
    }
  }
  // totals: {
  //   // missing in widget wallet
  //   key: 'totals',
  //   label: 'Totals',
  //   iconClassName: 'ssi ssi-totals-chart',
  // }
};

export const widgetTypesIterable: WidgetType[] = mapToIterable(widgetTypes);
