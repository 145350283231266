<div class="validate-post-boost">
    <div class="boost-header">
        <dl class="boost-summary">
            <dt class="text-warning">Total Budget:</dt>
            <dd class="text-warning">
                <span *ngIf="validation.budget.total_budget">£{{validation.budget.total_budget.amount}}</span>
                <span *ngIf="!validation.budget.total_budget">No Set</span>
            </dd>
            <dt>Daily Limit:</dt>
            <dd>
                <span *ngIf="validation.budget.daily_budget">£{{validation.budget.daily_budget.amount}}</span>
                <span *ngIf="!validation.budget.daily_budget">Not Set</span>
            </dd>
            <dt>Duration:</dt>
            <dd>7 days</dd>
            <dt>Estimated Reach:</dt>
            <dd>2'500+</dd>
        </dl>
        <span (click)="toggleTargets()" class="boost-summary-action">
            <span class="fa fa-chevron-down fa-fw"></span>
        </span>
    </div>
    <div *ngIf="showTargets" class="boost-content">
        <div class="boost-content-left">
            <p class="boost-content-title">Included Audience</p>
            <span class="label label-default label-style-1 label-style-1-yellow" *ngFor="let label of labels">
                <p>{{label}}</p>
                <!-- <span class="fa fa-times label-style-1-close"></span> -->
            </span>
        </div>
        <div class="boost-content-right">
            <p class="boost-content-title">Excluded Audience</p>
        </div>
    </div>
    <div class="boost-footer">
        <!-- <div class="boost-footer-body">
            <p class="boost-footer-title">Your Post is live!</p>
            <p>Jane has also boosted this post, shall we go ahead and boost it for you? You can <a href="#">click here</a> to review the audience</p>
        </div> -->
        <button class="btn btn-style-1 btn-secondary" (click)="validateAdvert.emit(false)">Disapprove boost</button>
        <button class="btn btn-style-1 btn-warning" (click)="validateAdvert.emit(true)">Go ahead and boost!</button>
    </div>
</div>