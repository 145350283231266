import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import './inbox-search-results-overlay.component.scss';

@Component({
  selector: 'ssi-inbox-search-results-overlay',
  templateUrl: './inbox-search-results-overlay.component.html'
})
export class InboxSearchResultsOverlayComponent implements OnInit {
  @Input() active = new EventEmitter();
  @Output() onClose = new EventEmitter();

  @HostListener('window:keydown.escape', ['$event'])
  onEsc(event: KeyboardEvent) {
    if (this.active) {
      this.onClose.emit();
    }
  }

  constructor() {}

  async ngOnInit() {}
}
