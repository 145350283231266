import './notification.component.scss';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-notification',
  templateUrl: './notification.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  message: string | TemplateRef<any>;
  iconClass?: string;
  iconColor?: string;
  dismissable = true;

  isStringMessage = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.isStringMessage = typeof this.message === 'string';
  }

  dismiss() {
    this.activeModal.close();
  }
}
