<ng-container *ngIf="attachments.document.length">
  <div
    *ngFor="let document of attachments.document"
    class="message-box-gallery-item">
    <a [href]="document.link" target="_blank">
      <ssi-icon-file fileType="{{document.type || '?'}}"></ssi-icon-file>
    </a>
  </div>
</ng-container>
<ng-container *ngIf="attachments.image.length === 1">
    <div
      [ssiEnlargeImage]="attachments.image[0].size.full || attachments.image[0].size.thumb"
      [enlargeImageDisabled]="activity.hasSensitiveContent && !activity.sensitiveContentRevealed"
      class="message-box-gallery-item message-box-gallery-item-image">
      <img
        [src]="attachments.image[0].size.thumb || attachments.image[0].size.full"
        ssiFallbackImage="brokenImage"
        [ssiBlurImage]="activity.hasSensitiveContent && !activity.sensitiveContentRevealed"
        [iconCoverOnly]="true"
        (unblur)="onMediaRevealed(activity);">
      <!-- <span
      ssiEnlargeImage="{{ image.size.full || image.size.thumb }}"
      class="message-box-gallery-item-action">
          <i class="ssi ssi-correct-expand"></i>
      </span> -->
  </div>
</ng-container>
<ng-container *ngIf="attachments.image.length > 1">
  <div [ssiEnlargeImage]="attachments.image | imageUrls" class="message-box-gallery-item-multiple">
    <div
      class="message-box-gallery-item message-box-gallery-item-image message-box-gallery-item-image-foreground">
      <img [src]="attachments.image[1].size.thumb" ssiFallbackImage="brokenImage">
    </div>
    <div
      class="message-box-gallery-item message-box-gallery-item-image message-box-gallery-item-image-forefront">
      <img [src]="attachments.image[0].size.thumb" ssiFallbackImage="brokenImage">
    </div>
    <span class="message-box-gallery-item-badge">
        <p [innerHTML]="attachments.image.length > 3 ? '3+' : attachments.image.length"></p>
    </span>
  </div>
</ng-container>
<ng-container *ngIf="attachments.video.length">
  <div
    *ngFor="let video of attachments.video"
    class="message-box-gallery-item message-box-gallery-item-image">
    <img
      [src]="video.size?.thumb"
      ssiFallbackImage="brokenImage">
    <span class="message-box-gallery-item-image-tint"></span>
    <a
      [href]="video.link"
      target="_blank"
      class="message-box-gallery-item-action">
        <i class="ssi ssi-correct-play"></i>
    </a>
  </div>
</ng-container>