import './home.component.scss';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { User, UserModel } from '@ui-resources-angular';
import { InsightsService, InsightsReport } from '../../insights.service';

import {
  AccessOptions,
  AccessOption,
  accessOptions,
  accessOptionsIterable
} from '../../../../../common/constants';
import { mapToIterable } from '../../../../../common/utils';
import { Debounce } from '../../../../../common/decorators';
import { PaginatorComponent } from '../../../../../common/components/paginator';

@Component({
  selector: 'ssi-insights-reports-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  authUser: User;
  templateReports: Partial<InsightsReport>[];
  reports: InsightsReport[] = [];
  filteredReports: InsightsReport[] = [];
  reportsToRender: InsightsReport[] = [];
  filterTerm = '';
  loading = false;

  accessOptions: AccessOptions = accessOptions;
  accessOptionsIterable: AccessOption[] = accessOptionsIterable;
  selectedAccessOption = this.accessOptions.public;

  sortOptions = {
    name: {
      key: 'name',
      label: 'Name'
    },
    newest: {
      key: 'newest',
      label: 'Newest'
    },
    oldest: {
      key: 'oldest',
      label: 'Oldest'
    },
    lastUpdated: {
      key: 'lastUpdated',
      label: 'Last updated'
    }
  };
  sortOptionsIterable = mapToIterable(this.sortOptions);
  selectedSortOption = this.sortOptions.newest;

  constructor(
    protected state: StateService,
    protected userModel: UserModel,
    protected insightsService: InsightsService
  ) {}

  async ngOnInit() {
    this.userModel.getAuthUser().then((authUser) => {
      // TODO: make this request parallel with getReports request in a proper way xD?
      this.authUser = authUser;
    });

    this.insightsService.getTemplateReports().then((templateReports) => {
      // TODO: make this request parallel with getReports request in a proper way xD?
      this.templateReports = templateReports;
    });

    this.loading = true;
    this.insightsService.getReports().then((reports) => {
      this.reports = reports;
      this.sortReportList();
      this.filterReports();
      // this.paginator.init();

      this.loading = false;
    });
  }

  ngOnDestroy() {}

  trackById = (index: number, report) => report.id;

  onSortOptionChange(): void {
    this.sortReportList();
    this.filterReports();
    this.paginator.init();
  }

  onAccessOptionChange(): void {
    this.filterReports();
    this.paginator.init();
  }

  @Debounce(600)
  onFilterTermChange(): void {
    this.filterReports();
    this.paginator.init();
  }

  onPageChange(opts: any): void {
    // will also fire on paginator init
    this.reportsToRender = this.filteredReports.slice(
      opts.startIndex,
      opts.endIndex
    );
  }

  filterReports(): void {
    this.filteredReports = this.reports
      .filter((r) => {
        const shared = this.selectedAccessOption === this.accessOptions.public;
        return r.is_shared === shared;
      })
      .filter((r) => {
        return r.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1;
      });
  }

  sortReportList() {
    const sortByName = (a, b) => {
      const dateA = a.name;
      const dateB = b.name;
      return dateA > dateB ? 1 : -1;
    };

    const sortByNewestCreated = (a, b) => {
      const dateA = Date.parse(a.created_at);
      const dateB = Date.parse(b.created_at);
      return dateA > dateB ? -1 : 1;
    };

    const sortByOldestCreated = (a, b) => {
      const dateA = Date.parse(a.created_at);
      const dateB = Date.parse(b.created_at);
      return dateA > dateB ? 1 : -1;
    };

    const sortByNewestUpdated = (a, b) => {
      const dateA = Date.parse(a.updated_at);
      const dateB = Date.parse(b.updated_at);
      return dateA > dateB ? -1 : 1;
    };

    switch (this.selectedSortOption.key) {
      case this.sortOptions.name.key:
        this.reports.sort(sortByName);
        break;
      case this.sortOptions.newest.key:
        this.reports.sort(sortByNewestCreated);
        break;
      case this.sortOptions.oldest.key:
        this.reports.sort(sortByOldestCreated);
        break;
      case this.sortOptions.lastUpdated.key:
        this.reports.sort(sortByNewestUpdated);
        break;
      default:
        break;
    }
  }

  createReport(): void {
    this.state.go('auth.insights.reports.create');
  }

  viewReport(report: InsightsReport): void {
    this.state.go('auth.insights.reports.view', {
      id: report.id
    });
  }

  viewTemplateReport(report: string): void {
    this.state.go('auth.insights.reports.view', {
      id: report,
      template: 'true'
    });
  }

  editReport(report: InsightsReport): void {
    this.state.go('auth.insights.reports.edit', {
      id: report.id,
      reportName: report.name
    });
  }
}
