import './inbox-alert-modal.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-inbox-alert-modal',
  templateUrl: './inbox-alert-modal.component.html'
})
export class InboxAlertModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }
}
