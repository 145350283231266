import './advertising-nav.component.scss';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'ssi-advertising-nav',
  templateUrl: './advertising-nav.component.html',
  styles: []
})
export class AdvertisingNavComponent implements OnInit {
  @Input() showSuggestions: boolean;

  @Output() changeGraphState: EventEmitter<boolean> = new EventEmitter();

  currentAdvertisingPage: string;

  constructor(private state: StateService) {}

  ngOnInit() {
    this.currentAdvertisingPage = this.state.current.name.split('.')[3];
  }
}
