import {
  Component,
  ContentChildren,
  Input,
  forwardRef,
  AfterContentInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListSelectOptionComponent } from './list-select-option/list-select-option.component';

export const LIST_SELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ListSelectComponent), //tslint:disable-line
  multi: true
};

@Component({
  selector: 'ssi-list-select',
  templateUrl: './list-select.component.html',
  providers: [LIST_SELECT_CONTROL_VALUE_ACCESSOR]
})
export class ListSelectComponent
  implements ControlValueAccessor, AfterContentInit {
  @Input() multiple: boolean;

  @Input() allowUnselect: boolean;

  @ContentChildren(ListSelectOptionComponent)
  options: ListSelectOptionComponent[] = [];

  modelValue: any;

  private onChangeCallback: (_: any) => void = () => {};

  writeValue(newValue: any): void {
    this.modelValue = newValue;
    this.options.forEach((option) => {
      if (!this.multiple) {
        option.isSelected = option.value === newValue;
      } else {
        const modelValue = newValue || [];
        option.isSelected = modelValue.includes(option.value);
      }
      option.cdr.detectChanges(); // hacky fix to make sure ui updates
    });
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  optionClicked({ value, isSelected }) {
    if (!this.multiple) {
      if (isSelected && this.allowUnselect) {
        this.updateModelValue(undefined);
      } else {
        this.updateModelValue(value);
      }
    } else {
      let modelValue = this.modelValue || [];
      if (modelValue.includes(value)) {
        modelValue = modelValue.filter((iValue) => iValue !== value);
      } else {
        modelValue.push(value);
      }
      this.updateModelValue(modelValue);
    }
  }

  ngAfterContentInit() {
    this.writeValue(this.modelValue);
  }

  private updateModelValue(newValue: any) {
    this.writeValue(newValue);
    this.onChangeCallback(newValue);
  }
}
