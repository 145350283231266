import './example-modal.component.scss';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ChatBotService,
  Topic,
  Example,
  Metabase
} from '../../../../../../common/services/chat-bot/chat-bot.service';
import { PopupService } from '../../../../../../common/services/popup/popup.service';

@Component({
  selector: 'ssi-example-modal',
  templateUrl: './example-modal.component.html',
  styles: []
})
export class ExampleModalComponent {
  @Input() metabase: Metabase;
  @Input() topic: Topic;
  @Input() examples: Partial<Example>[];
  editing: Example | undefined;
  create = {
    text: ''
  };

  constructor(
    public activeModal: NgbActiveModal,
    private popup: PopupService,
    public chatBot: ChatBotService
  ) {}

  async addExample(
    createdExample: Partial<Example>,
    metabase: Metabase,
    topic: Topic
  ) {
    await this.chatBot.createExample(
      metabase.id,
      topic.id,
      createdExample.text
    );
    this.examples.push(createdExample);
    this.create = {
      text: ''
    };
  }

  async editExample(example: Example, topic: Topic, metabase: Metabase) {
    this.editing = undefined;
    await this.chatBot.editExample(
      metabase.id,
      topic.id,
      example.id,
      example.text
    );
  }

  deleteExample(example: Example, topic: Topic, metabase: Metabase) {
    return this.popup
      .confirm({
        title: 'Delete Example',
        template: 'Delete this Example?'
      })
      .then(async (shouldDelete) => {
        if (shouldDelete) {
          await this.chatBot.deleteExample(metabase.id, topic.id, example.id);
          this.examples = this.examples.filter(
            (iExample) => iExample.id !== example.id
          );
        }
      });
  }
}
