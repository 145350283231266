
    <div class="cal-week-view" role="grid">
      <div>
        <ssi-calendar-week-view-header
          #calendarWeekViewHeader
          [days]="days"
          [locale]="locale"
          [customTemplate]="headerTemplate"
          (dayHeaderClicked)="dayHeaderClicked.emit($event)"
          (eventDropped)="
            eventDropped({ dropData: $event }, $event.newStart, true)
          "
          (dragEnter)="dateDragEnter($event.date)"
        >
        </ssi-calendar-week-view-header>
      </div>
      <div
        class="cal-all-day-events"
        #allDayEventsContainer
        *ngIf="view.allDayEventRows.length > 0"
        ssiDroppable
        (dragEnter)="dragEnter('allDay')"
        (dragLeave)="dragLeave('allDay')"
      >
        <div class="cal-day-columns">
          <div
            class="cal-time-label-column"
            [ngTemplateOutlet]="allDayEventsLabelTemplate"
          ></div>
          <div
            class="cal-day-column"
            *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
            ssiDroppable
            dragOverClass="cal-drag-over"
            (drop)="eventDropped($event, day.date, true)"
            (dragEnter)="dateDragEnter(day.date)"
          ></div>
        </div>
        <div
          *ngFor="let eventRow of view.allDayEventRows; trackBy: trackById"
          #eventRowContainer
          class="cal-events-row"
        >
          <div
            *ngFor="
              let allDayEvent of eventRow.row;
              trackBy: trackByWeekAllDayEvent
            "
            #event
            class="cal-event-container"
            [class.cal-draggable]="
              allDayEvent.event.draggable && allDayEventResizes.size === 0
            "
            [class.cal-starts-within-week]="!allDayEvent.startsBeforeWeek"
            [class.cal-ends-within-week]="!allDayEvent.endsAfterWeek"
            [ngClass]="allDayEvent.event?.cssClass"
            [style.width.%]="(100 / days.length) * allDayEvent.span"
            [style.marginLeft.%]="
              rtl ? null : (100 / days.length) * allDayEvent.offset
            "
            [style.marginRight.%]="
              rtl ? (100 / days.length) * allDayEvent.offset : null
            "
            ssiResizable
            [resizeSnapGrid]="{ left: dayColumnWidth, right: dayColumnWidth }"
            [validateResize]="validateResize"
            (resizeStart)="
              allDayEventResizeStarted(eventRowContainer, allDayEvent, $event)
            "
            (resizing)="
              allDayEventResizing(allDayEvent, $event, dayColumnWidth)
            "
            (resizeEnd)="allDayEventResizeEnded(allDayEvent)"
            ssiDraggable
            dragActiveClass="cal-drag-active"
            [dropData]="{ event: allDayEvent.event, calendarId: calendarId }"
            [dragAxis]="{
              x: allDayEvent.event.draggable && allDayEventResizes.size === 0,
              y:
                !snapDraggedEvents &&
                allDayEvent.event.draggable &&
                allDayEventResizes.size === 0
            }"
            [dragSnapGrid]="snapDraggedEvents ? { x: dayColumnWidth } : {}"
            [validateDrag]="validateDrag"
            [touchStartLongPress]="{ delay: 300, delta: 30 }"
            (dragStart)="
              dragStarted(eventRowContainer, event, allDayEvent, false)
            "
            (dragging)="allDayEventDragMove()"
            (dragEnd)="dragEnded(allDayEvent, $event, dayColumnWidth)"
          >
            <div
              class="cal-resize-handle cal-resize-handle-before-start"
              *ngIf="
                allDayEvent.event?.resizable?.beforeStart &&
                !allDayEvent.startsBeforeWeek
              "
              ssiResizeHandle
              [resizeEdges]="{ left: true }"
            ></div>
            <ssi-calendar-week-view-event
              [locale]="locale"
              [weekEvent]="allDayEvent"
              [tooltipPlacement]="tooltipPlacement"
              [tooltipTemplate]="tooltipTemplate"
              [tooltipAppendToBody]="tooltipAppendToBody"
              [tooltipDelay]="tooltipDelay"
              [customTemplate]="eventTemplate"
              [eventTitleTemplate]="eventTitleTemplate"
              [eventActionsTemplate]="eventActionsTemplate"
              [daysInWeek]="daysInWeek"
              (eventClicked)="
                eventClicked.emit({
                  event: allDayEvent.event,
                  sourceEvent: $event.sourceEvent
                })
              "
            >
            </ssi-calendar-week-view-event>
            <div
              class="cal-resize-handle cal-resize-handle-after-end"
              *ngIf="
                allDayEvent.event?.resizable?.afterEnd &&
                !allDayEvent.endsAfterWeek
              "
              ssiResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="cal-time-events"
        ssiDroppable
        [style.paddingTop.px]="getHeaderHeight()"
        (dragEnter)="dragEnter('time')"
        (dragLeave)="dragLeave('time')"
      >
        <div
          class="cal-time-label-column"
          *ngIf="view.hourColumns.length > 0 && daysInWeek !== 1"
        >
          <div
            *ngFor="
              let hour of view.hourColumns[0].hours;
              let hourIndex = index;
              trackBy: trackByHour;
              let odd = odd
            "
            class="cal-hour"
            [class.cal-hour-odd]="odd"
          >
            <ssi-calendar-week-view-hour-segment
              *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
              [style.height.px]="hour.rowHeight"
              [segment]="segment"
              [segmentHeight]="hour.rowHeight"
              [columnEvents]="view.hourColumns[0].events"
              [locale]="locale"
              [customTemplate]="hourSegmentTemplate"
              [isTimeLabel]="true"
              [daysInWeek]="daysInWeek"
            >
            </ssi-calendar-week-view-hour-segment>
          </div>
        </div>
        <div
          class="cal-day-columns"
          [class.cal-resize-active]="timeEventResizes.size > 0"
          #dayColumns
        >
          <div
            class="cal-day-column"
            *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn"
          >
            <ssi-calendar-week-view-current-time-marker
              *ngIf="currentTimeMarkerVisible"
              [columnDate]="column.date"
              [dayStartHour]="dayStartHour"
              [dayStartMinute]="dayStartMinute"
              [dayEndHour]="dayEndHour"
              [dayEndMinute]="dayEndMinute"
              [hourSegments]="hourSegments"
              [hourDuration]="hourDuration"
              [hourSegmentHeight]="hourSegmentHeight"
              [customTemplate]="currentTimeMarkerTemplate"
            ></ssi-calendar-week-view-current-time-marker>
            <div class="cal-events-container">
              <ng-container
                *ngFor="
                  let timeEvent of column.events;
                  trackBy: trackByWeekTimeEvent
                "
              >
                <div
                  *ngIf="!timeEvent.hidden"
                  #event
                  class="cal-event-container"
                  [class.cal-draggable]="
                    timeEvent.event.draggable && timeEventResizes.size === 0
                  "
                  [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
                  [class.cal-ends-within-day]="!timeEvent.endsAfterDay"
                  [ngClass]="timeEvent.event.cssClass"
                  [hidden]="timeEvent.height === 0 && timeEvent.width === 0"
                  [style.top.px]="timeEvent.top"
                  [style.height.px]="timeEvent.height"
                  [style.left.%]="timeEvent.left"
                  [style.width.%]="
                    calendarView === CalendarView.Week && timeEvent.width
                  "
                  [style.max-width.%]="
                    calendarView === CalendarView.Day && timeEvent.width
                  "
                  ssiResizable
                  [resizeSnapGrid]="{
                    left: dayColumnWidth,
                    right: dayColumnWidth,
                    top: eventSnapSize || hourSegmentHeight,
                    bottom: eventSnapSize || hourSegmentHeight
                  }"
                  [validateResize]="validateResize"
                  [allowNegativeResizes]="true"
                  (resizeStart)="
                    timeEventResizeStarted(dayColumns, timeEvent, $event)
                  "
                  (resizing)="timeEventResizing(timeEvent, $event)"
                  (resizeEnd)="timeEventResizeEnded(timeEvent)"
                  ssiDraggable
                  dragActiveClass="cal-drag-active"
                  [dropData]="{
                    event: timeEvent.event,
                    calendarId: calendarId
                  }"
                  [dragAxis]="{
                    x: timeEvent.event.draggable && timeEventResizes.size === 0,
                    y: timeEvent.event.draggable && timeEventResizes.size === 0
                  }"
                  [dragSnapGrid]="
                    snapDraggedEvents
                      ? {
                          x: dayColumnWidth,
                          y: eventSnapSize || hourSegmentHeight
                        }
                      : {}
                  "
                  [touchStartLongPress]="{ delay: 300, delta: 30 }"
                  [ghostDragEnabled]="!snapDraggedEvents"
                  [ghostElementTemplate]="weekEventTemplate"
                  [validateDrag]="validateDrag"
                  (dragStart)="dragStarted(dayColumns, event, timeEvent, true)"
                  (dragging)="dragMove(timeEvent, $event)"
                  (dragEnd)="dragEnded(timeEvent, $event, dayColumnWidth, true)"
                >
                  <div
                    class="cal-resize-handle cal-resize-handle-before-start"
                    *ngIf="
                      timeEvent.event?.resizable?.beforeStart &&
                      !timeEvent.startsBeforeDay
                    "
                    ssiResizeHandle
                    [resizeEdges]="{
                      left: true,
                      top: true
                    }"
                  ></div>
                  <ng-template
                    [ngTemplateOutlet]="weekEventTemplate"
                  ></ng-template>
                  <ng-template #weekEventTemplate>
                    <ssi-calendar-week-view-event
                      [locale]="locale"
                      [weekEvent]="timeEvent"
                      [tooltipPlacement]="tooltipPlacement"
                      [tooltipTemplate]="tooltipTemplate"
                      [tooltipAppendToBody]="tooltipAppendToBody"
                      [tooltipDisabled]="
                        dragActive || timeEventResizes.size > 0
                      "
                      [tooltipDelay]="tooltipDelay"
                      [customTemplate]="eventTemplate"
                      [eventTitleTemplate]="eventTitleTemplate"
                      [eventActionsTemplate]="eventActionsTemplate"
                      [column]="column"
                      [daysInWeek]="daysInWeek"
                      (eventClicked)="
                        eventClicked.emit({
                          event: timeEvent.event,
                          sourceEvent: $event.sourceEvent
                        })
                      "
                    >
                    </ssi-calendar-week-view-event>
                  </ng-template>
                  <div
                    class="cal-resize-handle cal-resize-handle-after-end"
                    *ngIf="
                      timeEvent.event?.resizable?.afterEnd &&
                      !timeEvent.endsAfterDay
                    "
                    ssiResizeHandle
                    [resizeEdges]="{
                      right: true,
                      bottom: true
                    }"
                  ></div>
                </div>
              </ng-container>
            </div>

            <div
              *ngFor="
                let hour of column.hours;
                trackBy: trackByHour;
                let odd = odd
              "
              class="cal-hour"
              [class.cal-hour-odd]="odd"
            >
              <ssi-calendar-week-view-hour-segment
                *ngFor="
                  let segment of hour.segments;
                  trackBy: trackByHourSegment
                "
                [style.height.px]="hour.rowHeight"
                [segment]="segment"
                [segmentHeight]="hour.rowHeight"
                [columnEvents]="column.events"
                [locale]="locale"
                [customTemplate]="hourSegmentTemplate"
                [daysInWeek]="daysInWeek"
                (ssiClick)="
                  hourSegmentClicked.emit({
                    date: segment.date,
                    sourceEvent: $event
                  })
                "
                [clickListenerDisabled]="
                  hourSegmentClicked.observers.length === 0
                "
                ssiDroppable
                [dragOverClass]="
                  !dragActive || !snapDraggedEvents ? 'cal-drag-over' : null
                "
                dragActiveClass="cal-drag-active"
                (drop)="eventDropped($event, segment.date, false)"
                (dragEnter)="dateDragEnter(segment.date)"
                [isTimeLabel]="daysInWeek === 1"
              >
              </ssi-calendar-week-view-hour-segment>
            </div>
          </div>
        </div>
      </div>
    </div>
  