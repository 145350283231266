import { Injectable, Injector } from '@angular/core';
import { Account, AccountModel } from '@ui-resources-angular';
import moment from 'moment';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../../../../common/services/api';

export interface DateRange {
  start: Date;
  end: Date;
}
export interface DateRanges {
  current: DateRange;
  previous: DateRange;
}

export enum Currency {
  GBP = 'GBP',
  USD = 'USD',
  EUR = 'EUR'
}

export interface Cost {
  currency: Currency;
  amount: number; // float
}

export interface Totals {
  clicks: number;
  comments: number;
  shares: number;
  reactions: number;
  impressions: number;
  reach: number;
  video_views: number;
  engagement: number;
  conversions: number;
  total_spend: Cost;
  cost_per_conversion: Cost;
  cost_per_click: Cost;
  cost_per_mille: Cost;
  purchase_roas: Cost;
}

export interface TotalsStats {
  /** for all accounts, merged */
  allTotals: {
    current: Totals;
    previous?: Totals;
  };
  /** per account */
  totals: Array<{
    current: Totals;
    previous?: Totals;
    account: Account;
  }>;
  // totalsByAccountType: {
  //   /** per social network */
  //   [account_type: string]: {
  //     current: Totals;
  //     previous?: Totals;
  //     totalAccounts: number;
  //     account: {
  //       // should not really be an 'account', but keep the data format consistent so it's easier to display data in the table later
  //       account_type_id: number;
  //       account_type_name: string;
  //       nme: string;
  //     };
  //   };
  // };
}

export interface SpendApiTimeSeries {
  currency: Currency;
  values: { [key: string]: number }; // e.g. '2000-01-01T00': 0.02
}
export interface ApiTimeSeries {
  values: { [key: string]: number }; // e.g. '2000-01-01T00': 2
}
export interface ApiTimeSeriesStats {
  spend: SpendApiTimeSeries;
  engagement: ApiTimeSeries;
  conversions: ApiTimeSeries;
}

export interface TimeSeriesStats {
  current: ApiTimeSeriesStats;
  previous?: ApiTimeSeriesStats;
}

export interface Report {
  totalsStats: TotalsStats;
  timeSeriesStats: TimeSeriesStats;
}

@Injectable({ providedIn: 'root' })
export class AdStatsService {
  endpoint = `${this.api.url}/advertising/adAccountStats`;

  constructor(
    protected injector: Injector,
    protected api: ApiService,
    protected accountModel: AccountModel
  ) {}

  loadStatsForAccount(
    account,
    dateRange: DateRange
  ): Promise<{
    account: Account;
    accountId: string;
    totals: Totals;
    timeseries: ApiTimeSeriesStats;
  }> {
    return this.api
      .post(this.endpoint, {
        account_id: account.id,
        start: moment(dateRange.start).toISOString(true), // explicitly set local date string (not UTC), see: CT-3591 and https://www.geeksforgeeks.org/moment-js-moment-toisostring-method/
        end: moment(dateRange.end).toISOString(true)
      })
      .pipe(
        map((response: any) => {
          return {
            account,
            accountId: response.account_id,
            totals: response.totals,
            timeseries: response.timeseries
          };
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  async loadReport(
    accounts: Account[],
    dateRanges: DateRanges
  ): Promise<Report> {
    const currentPromises = Promise.all(
      accounts.map((a) => this.loadStatsForAccount(a, dateRanges.current))
    );

    let previousPromises;
    if (dateRanges.previous.start && dateRanges.previous.end) {
      previousPromises = Promise.all(
        accounts.map((a) => this.loadStatsForAccount(a, dateRanges.previous))
      );
    }

    const [currentResponses, previousResponses] = await Promise.all([
      currentPromises,
      previousPromises
    ]);

    return this.generateReport(currentResponses, previousResponses);
  }

  protected generateReport(
    statsPerAccountCurrent: Array<{
      account: Account;
      totals: Totals;
      timeseries: ApiTimeSeriesStats;
    }>,
    statsPerAccountPrevious?: Array<{
      account: Account;
      totals: Totals;
      timeseries: ApiTimeSeriesStats;
    }>
  ): Report {
    const accumulateStats = (
      statsPerAccount: Array<{
        account: Account;
        totals: Totals;
        timeseries: ApiTimeSeriesStats;
      }>
    ): {
      totals: Totals;
      timeseries: ApiTimeSeriesStats;
    } => {
      if (!statsPerAccount) {
        return undefined;
      }

      return JSON.parse(JSON.stringify(statsPerAccount)).reduce((a, c) => {
        // totals...
        [
          'clicks',
          'comments',
          'shares',
          'reactions',
          'impressions',
          'reach',
          'video_views',
          'engagement',
          'conversions'
        ].forEach((k) => {
          if (c.totals[k]) {
            a.totals[k] = (a.totals[k] || 0) + c.totals[k];
          }
        });

        [
          'total_spend',
          'cost_per_conversion',
          'cost_per_click',
          'cost_per_mille',
          'purchase_roas'
        ].forEach((k) => {
          if (c.totals[k].amount) {
            a.totals[k].amount = (a.totals[k].amount || 0) + c.totals[k].amount;
          }
        });

        // timeseries...
        for (const k in c.timeseries.spend.values) {
          if (c.timeseries.spend.values[k]) {
            a.timeseries.spend.values[k] =
              (a.timeseries.spend.values[k] || 0) +
              c.timeseries.spend.values[k];
          }
        }

        for (const k in c.timeseries.engagement.values) {
          if (c.timeseries.engagement.values[k]) {
            a.timeseries.engagement.values[k] =
              (a.timeseries.engagement.values[k] || 0) +
              c.timeseries.engagement.values[k];
          }
        }

        for (const k in c.timeseries.conversions.values) {
          if (c.timeseries.conversions.values[k]) {
            a.timeseries.conversions.values[k] =
              (a.timeseries.conversions.values[k] || 0) +
              c.timeseries.conversions.values[k];
          }
        }

        return a;
      });
    };

    const accumulatedStatsCurrent = accumulateStats(statsPerAccountCurrent);
    const accumulatedStatsPrevious = accumulateStats(statsPerAccountPrevious);

    return {
      totalsStats: {
        allTotals: {
          current: accumulatedStatsCurrent.totals,
          previous: accumulatedStatsPrevious && accumulatedStatsPrevious.totals
        },
        // per account
        totals: statsPerAccountCurrent.map((s) => {
          return {
            account: s.account,
            current: s.totals,
            previous:
              statsPerAccountPrevious &&
              statsPerAccountPrevious.find(
                (stats) => stats.account.id === s.account.id
              ).totals
          };
        })
      },
      timeSeriesStats: {
        current: accumulatedStatsCurrent.timeseries,
        previous:
          accumulatedStatsPrevious && accumulatedStatsPrevious.timeseries
      }
    };
  }
}
