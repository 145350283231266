import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { InboxSettingsComponent } from './inbox-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { Nl2brModule } from '../../../../../common/pipes/nl2br/nl2br.module';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';
import { FileDownloadModule } from '../../../../../common/directives/file-download/file-download.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    AlertsModule,
    Nl2brModule,
    NgbPaginationModule,
    SortByModule,
    FileDownloadModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.inboxSettings',
          url: '/settings/inbox',
          component: InboxSettingsComponent,
          data: {
            permissions: {
              company: 'administer_company'
            }
          },
          resolve: InboxSettingsComponent.resolve
        }
      ]
    })
  ],
  declarations: [InboxSettingsComponent]
})
export class InboxSettingsModule {}
