import { Component, Inject, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Auth, API } from '@ui-resources-angular';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { serverSettings } from '../../common-resolves';

@Component({
  selector: 'ssi-activate',
  templateUrl: './activate.component.html'
})
export class ActivateComponent implements OnInit {
  static resolve = [serverSettings];

  @Input() serverSettings;
  firstName: string;
  lastName: string;
  emailAddress: string;
  companyName: string;
  loadingTracker: AsyncTracker = this.asyncTrackerFactory.create();
  password: string;
  accountActivated = false;
  loadError = false;

  constructor(
    private state: StateService,
    private asyncTrackerFactory: AsyncTrackerFactory,
    private api: API,
    @Inject('$auth') protected $auth: Auth
  ) {}

  ngOnInit(): void {
    const promise = this.api
      .get('company/activate', {
        params: {
          uuid: this.state.params.companyUUID,
          signature: this.state.params.signature
        }
      })
      .then(({ data }) => {
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.emailAddress = data.email_address;
        this.companyName = data.company_name;
      })
      .catch(() => (this.loadError = true));
    this.loadingTracker.add(promise);
  }

  activate() {
    const promise = this.api
      .post(
        'company/activate',
        { password: this.password },
        {
          params: {
            uuid: this.state.params.companyUUID,
            signature: this.state.params.signature
          }
        }
      )
      .then(() => {
        this.accountActivated = true;
        this.$auth.logout();
      });

    this.loadingTracker.add(promise);
  }
}
