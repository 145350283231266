import './survey.component.scss';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-survey',
  templateUrl: './survey.component.html',
  styles: []
})
export class SurveyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
