<div class="marketing-analytics-build-container">
  <div class="head">
    <h3 translate="MARKETING_REPORT"></h3>
    <h2>Build your report</h2>
  </div>

  <div class="body">
    <div class="body-inner">
      <ssi-report-build-from
        *ngIf="postTags"
        report="marketing"
        [showCompareToSection]="true"
        [showIncludeDeletedInTopPostsOptions]="true"
        [showTagsSection]="true"
        [tags]="postTags"
        [allChannels]="true"
        [accountTypeIdsToExclude]="[
          AccountTypeId.YouTube,
          AccountTypeId.LiveChat,
          AccountTypeId.TwilioSMS,
          AccountTypeId.TwilioWhatsApp,
          AccountTypeId.FacebookGroup,
          AccountTypeId.TikTok,
          AccountTypeId.ReviewTrackers
        ]"
        [formValue]="formValue"
        (formSubmit)="onFormSubmit($event)"
      ></ssi-report-build-from>
    </div>
  </div>
</div>