import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class BrowserlessPDFExportService {
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(protected api: ApiService) {}

  getLoading(): Observable<boolean> {
    return this.loading$;
  }

  downloadPDF(
    url: string,
    config: string,
    filename: string,
    insights_filters = '[]',
    height,
    width
  ): void {
    this.loading$.next(true);
    this.api
      .post(
        `${this.api.url}/server/pdf`,
        { url, config, insights_filters, height, width, scale: 1 },
        {
          headers: new HttpHeaders({ Accept: 'application/pdf' }),
          responseType: 'blob'
        }
      )
      .subscribe((result: any) => {
        // maybe there's a more angular way to do this
        const blob = new Blob([result], {
          type: 'application/pdf'
        });
        const URL = window.URL || (window as any).webkitURL;
        const downloadUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.target = '_blank';
        downloadLink.download = filename;
        downloadLink.href = downloadUrl;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadUrl);

        this.loading$.next(false);
      });
  }
}
