import { Account, Campaign } from '@ui-resources-angular';
import outboxImg from './img/outbox-infographic.png';
import {
  TriggerConfig,
  ActionConfig,
  UIParam,
  UIParamFormType
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import {
  overlayDimensions,
  ServiceMapping,
  getParamByName,
  minShouldMatchParamFactory,
  IngredientConfig,
  IngredientType
} from './util';
import { AppletTriggerParam, AppletActionParam } from '../applets/applet';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../common/services/api';
import { Webhook } from '../../../../../common/services/webhook-model/webhook-model';

const triggers: TriggerConfig[] = [
  {
    api: {
      name: 'from account'
    },
    isAccountTrigger: true,
    getLabel(params: AppletTriggerParam[]) {
      const accounts: Account[] = getParamByName(params, 'accounts').value;
      return (
        'Is on account ' +
        accounts
          .map(
            (account) => `${account.displayName} (${account.account_type_name})`
          )
          .join(' or ')
      );
    }
  },
  {
    api: {
      name: 'from author'
    },
    translationIds: {
      title: 'OUTBOX_TRIGGER_FROM_AUTHOR_TITLE',
      description: 'OUTBOX_TRIGGER_FROM_AUTHOR_DESCRIPTION'
    },
    iconClass: 'ssi ssi-person-outline',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const colleague: Colleague = getParamByName(params, 'user_id').value;
      return `Created by ${colleague.fullName}`;
    }
  },
  {
    api: {
      name: 'from author in team'
    },
    translationIds: {
      title: 'OUTBOX_TRIGGER_FROM_AUTHOR_IN_TEAM_TITLE',
      description: 'OUTBOX_TRIGGER_FROM_AUTHOR_IN_TEAM_DESCRIPTION'
    },
    iconClass: 'ssi ssi-users-group',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const team: Team = getParamByName(params, 'team_id').value;
      return `Created by a member of the team ${team.name}`;
    }
  },
  {
    api: {
      name: 'queries match text'
    },
    translationIds: {
      title: 'OUTBOX_TRIGGER_CONTAINING_WORD_TITLE',
      description: 'OUTBOX_TRIGGER_CONTAINING_WORD_DESCRIPTION'
    },
    iconClass: 'ssi ssi-document-help',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const mode: number = getParamByName(params, 'mode').value;
      const queries: string[] = getParamByName(params, 'queries').value;
      const operand: string =
        getParamByName(params, 'min_should_match').value === 'or'
          ? 'OR'
          : 'AND';
      return `${
        mode === 0 ? 'Contains the text' : 'Matches word(s)'
      } ${queries.map((query) => `"${query}"`).join(` ${operand} `)}`;
    },
    paramOverrides: {
      min_should_match: () => minShouldMatchParamFactory(),
      mode(): UIParam {
        return {
          form: {
            type: UIParamFormType.Hidden,
            defaultValue: 0,
            showAsCheckboxInput: true
          }
        };
      }
    }
  },
  {
    api: {
      name: 'with image'
    },
    translationIds: {
      title: 'OUTBOX_TRIGGER_WITH_IMAGE_TITLE',
      description: 'OUTBOX_TRIGGER_WITH_IMAGE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-picture-paperclip',
    getLabel() {
      return 'Has an image';
    }
  },
  {
    api: {
      name: 'in campaign'
    },
    translationIds: {
      title: 'OUTBOX_TRIGGER_IN_CAMPAIGN_TITLE',
      description: 'OUTBOX_TRIGGER_IN_CAMPAIGN_DESCRIPTION'
    },
    iconClass: 'fa fa-bookmark',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const campaign: Campaign = getParamByName(params, 'campaign_id').value;
      return `Is part of the campaign ${campaign.name}`;
    }
  }
];

const actions: ActionConfig[] = [
  {
    api: {
      name: 'create note'
    },
    translationIds: {
      title: 'OUTBOX_ACTION_CREATE_NOTE_TITLE',
      description: 'OUTBOX_ACTION_CREATE_NOTE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-feather-note',
    overlayDimensions: overlayDimensions.textarea,
    getLabel(params: AppletActionParam[]) {
      const text: string = getParamByName(params, 'body').value;
      return `Add the note "${text}"`;
    }
  },
  {
    api: {
      name: 'send webhook message'
    },
    translationIds: {
      title: 'OUTBOX_ACTION_SEND_WEBHOOK_MESSAGE_TITLE',
      description: 'OUTBOX_ACTION_SEND_WEBHOOK_MESSAGE_DESCRIPTION'
    },
    iconClass: 'fa fa-plug',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const webhook: Webhook = getParamByName(params, 'webhook_id').value;
      return `Send a webhook notification to "${webhook && webhook.name}"`;
    }
  }
];

const ingredients: IngredientConfig[] = [
  {
    type: IngredientType.Template,
    api: {
      name: 'validator.name'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_VALIDATOR_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'author.name'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_AUTHOR_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'account.username'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_ACCOUNT_USERNAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'account.name'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_ACCOUNT_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'account_type.name'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_ACCOUNT_TYPE_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'author.team_names'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_AUTHOR_TEAM_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'campaign.name'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_CAMPAIGN_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'text'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_TEXT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'type'
    },
    translationIds: {
      label: 'OUTBOX_INGREDIENT_TYPE_LABEL'
    }
  }
];

const service: ServiceMapping = {
  id: 'outbox.published',
  brand: {
    img: outboxImg,
    iconClass: 'ssi ssi-correct-forward-email'
  },
  triggers,
  actions,
  ingredients,
  translationIds: {
    title: 'OUTBOX',
    triggers: 'OUTBOX_TRIGGERS',
    actions: 'OUTBOX_ACTIONS'
  }
};

export default service;
