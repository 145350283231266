<div class="plugin-background">
  <div *ngFor="let plugin of plugins">
      <iframe #iframe style="width: 100%; height: 100%;" [src]="plugin.sanitizedURL" [id]="plugin.id" [attr.pluginId]="plugin.id"></iframe>
  </div>
</div>
<div class="plugin-container" [ngClass]="{'open' : windowOpen, 'pinned': crmPinned}">
  <div class="plugin" *ngFor="let plugin of plugins" [ngClass]="plugin.name === activePlugin.name ? 'visible' : 'hidden'">
    <div class="plugin-window">
      <iframe #iframeView style="width: 100%; height: 100%;" [id]="plugin.id" [attr.pluginId]="plugin.id"></iframe>
    </div>
  </div>
</div>
