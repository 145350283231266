import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderDirective } from './file-uploader.directive';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploaderService } from './file-uploader.service';

@NgModule({
  imports: [CommonModule],
  declarations: [FileUploaderDirective, FileUploaderComponent],
  exports: [FileUploaderDirective, FileUploaderComponent],
  providers: [FileUploaderService],
  entryComponents: [FileUploaderComponent]
})
export class FileUploaderModule {}
