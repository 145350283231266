import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePostsContentComponent } from './validate-posts-content.component';
import { TriggerPublishModule } from '../../../../../../common/directives/trigger-publish/trigger-publish.module';
import { LinkifyModule } from '../../../../../../common/pipes/linkify/linkify.module';
import { FallbackImageModule } from '../../../../../../common/directives/fallback-image/fallback-image.module';

@NgModule({
  imports: [
    CommonModule,
    TriggerPublishModule,
    LinkifyModule,
    FallbackImageModule
  ],
  declarations: [ValidatePostsContentComponent],
  exports: [ValidatePostsContentComponent]
})
export class ValidatePostsContentModule {}
