import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownComponent } from './countdown.component';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { TimeToDetailsModule } from '../../directives/time-to-details/time-to-details.module';

@NgModule({
  imports: [CommonModule, TooltipModule, TranslateModule, TimeToDetailsModule],
  declarations: [CountdownComponent],
  exports: [CountdownComponent]
})
export class CountdownModule {}
