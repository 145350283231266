<div class="status-menu">
    <ul [class.active]="active">
        <li (click)="active = !active"
            class="initial {{selectedItem?.id | lowercase}}">{{selectedItem?.label}}
            <i class="ssi ssi-arrow-down-new"></i>
        </li>
        <li *ngFor="let item of items"
            (click)="select(item)"
            [hidden]="item === selectedItem">
            {{ item.label }}
        </li>
    </ul>
</div>