import './validate-posts-boost.component.scss';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-validate-posts-boost',
  templateUrl: './validate-posts-boost.component.html',
  styles: []
})
export class ValidatePostsBoostComponent implements OnInit {
  @Input() validation: any;
  @Input() campaignName: string;
  @Output() validateAdvert: EventEmitter<boolean> = new EventEmitter();

  labels = [];
  showTargets = false;

  constructor() {}

  ngOnInit() {
    this.assignLabels();
  }

  assignLabels() {
    this.validation.targeting_options.forEach((item) => {
      this.labels.push(item.label);
    });
  }

  toggleTargets() {
    this.showTargets = !this.showTargets;
  }
}
