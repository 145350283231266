<div
  *ngIf="(content | typeof) === 'string'"
  class="snackbar-container"
>
  <i *ngIf="options?.icon" class="ssi" [ngClass]="options.icon"></i>
  <i *ngIf="!options?.icon" class="ssi ssi-completed-notification"></i>
  <p>{{content}}</p>
  <button (click)="dismiss()"><i class="ssi ssi-times"></i></button>
</div>

<ng-template
  *ngIf="(content | typeof) === 'object'"
  [ngTemplateOutlet]="content"
  [ngTemplateOutletContext]="{
    dismiss: dismiss,
    close: close
  }"
></ng-template>