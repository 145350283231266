<div class="cl-date-time-picker-container">
  <div class="head">
    <h2>Datetime Picker</h2>
  </div>

  <div class="body">
    <div>
      <h4>A</h4>
      <a
        target="_blank"
        href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-date-time-picker/cl-date-time-picker.component.html"
      >
        View code on GitHub
      </a>
      <p>Date and time picker</p>
      <div>
        <ssi-date-time-picker
          [dateLabel]="'EXPIRY_DATE' | translate"
          [timeLabel]="'EXPIRY_TIME' | translate"
          [pastDateTooltipTitle]="'Unavailable day to set the expiry'"
          [pastDateTooltipBody]="'Content can\'t be set to expiry in the past'"
          [placeholder]="'NO_EXPIRY_DATE_SET' | translate"
          [calHeaderTitle]="'SELECT_POST_EXPIRY_DATE' | translate"
          name="dateAndTime"
          [(ngModel)]="dateAndTime"
          (ngModelChange)="datesChanged($event)"
        >
        </ssi-date-time-picker>
        Date: {{dateAndTime | json}}
      </div>
    </div>
    <div>
      <h4>B</h4>
      <a
        target="_blank"
        href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-date-time-picker/cl-date-time-picker.component.html"
      >
        View code on GitHub
      </a>
      <p>Date picker only</p>
      <div>
        <ssi-date-time-picker
          [showTime]="false"
          [dateLabel]="'EXPIRY_DATE' | translate"
          [timeLabel]="'EXPIRY_TIME' | translate"
          [pastDateTooltipTitle]="'Unavailable day to set the expiry'"
          [pastDateTooltipBody]="'Content can\'t be set to expiry in the past'"
          [placeholder]="'NO_EXPIRY_DATE_SET' | translate"
          name="dateOnly"
          [(ngModel)]="dateOnly"
          (ngModelChange)="datesChanged($event)"
        >
        </ssi-date-time-picker>
        Date: {{dateOnly | json}}
      </div>
    </div>

    <!-- <div>
      <h4>B</h4>
      <a
        target="_blank"
        href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-date-time-picker/cl-date-time-picker.component.html"
      >
        View code on GitHub
      </a>
      <p>Date and Time picker</p>
      <div>
        <label
          class="text-muted"
          translate="END_DATE"
        ></label>
        <ssi-date-time-picker
          ng-model="vm.end"
          ng-change="vm.datesChanged()"
          max-date="vm.today"
          min-date="vm.start"
          datetimepicker-config="{minView: vm.precision}"
          required="true"
        >
        </ssi-date-time-picker>
      </div>
    </div> -->
  </div>
</div>