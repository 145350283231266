import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxActivityComponent } from './inbox-activity.component';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { EmojiTextModule } from '../../directives/emoji-text/emoji-text.module';
import { InboxPinnedActivitiesModule } from '../inbox-pinned-activities/inbox-pinned-activities.module';
import { ActionPadModule } from '../action-pad/action-pad.module';
import { InboxCrmSidebarModule } from './inbox-crm-sidebar/inbox-crm-sidebar.module';
import { InboxActivityThreadModule } from './inbox-activity-thread/inbox-activity-thread.module';
import { InboxActivityCrmModule } from './inbox-activity-crm/inbox-activity-crm.module';
import { InboxActivityExternalCrmPersonModule } from './inbox-activity-external-crm-person/inbox-activity-external-crm-person.module';
import { InboxLinkExternalCrmModalModule } from './inbox-crm-sidebar/inbox-link-external-crm-modal/inbox-link-external-crm-modal.module';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    OrloComponentsModule,
    TooltipModule,
    OffClickModule,
    FallbackImageModule,
    EmojiTextModule,
    InboxPinnedActivitiesModule,
    ActionPadModule,
    InboxCrmSidebarModule,
    InboxActivityThreadModule,
    InboxActivityCrmModule,
    InboxActivityExternalCrmPersonModule,
    InboxLinkExternalCrmModalModule
  ],
  declarations: [InboxActivityComponent],
  exports: [InboxActivityComponent]
})
export class InboxActivityModule {}
