const ONE_SECOND = 1;
const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

const SECOND_LABEL = 'secs';
const MINUTE_LABEL = 'mins';
const HOUR_LABEL = 'hrs';
const DAY_LABEL = 'days';

export default function secondsToHumanTime(
  seconds: number = 0,
  isLong: boolean = false,
  altRound?: boolean
): string {
  seconds = Math.round(seconds);

  let interval: number;
  let label: string;

  if (seconds < ONE_MINUTE) {
    interval = ONE_SECOND;
    label = SECOND_LABEL;
  } else if (seconds < 2 * ONE_HOUR) {
    interval = ONE_MINUTE;
    label = MINUTE_LABEL;
  } else if (seconds < 2 * ONE_DAY) {
    interval = ONE_HOUR;
    label = HOUR_LABEL;
  } else {
    interval = ONE_DAY;
    label = DAY_LABEL;
  }

  if (isLong) {
    const remainder = seconds % interval;
    let result = `${Math.floor(seconds / interval)} ${label}`;
    if (remainder > 0) {
      result += `, ${secondsToHumanTime(remainder, isLong)}`;
    }
    return result;
  } else {
    if (altRound) {
      const num: number = Math.floor(seconds / interval);
      if (seconds % interval > 0) {
        const remainder = secondsToHumanTime(seconds % interval, true)
          .slice(0, 2)
          .trim();
        return `${num}:${
          remainder.length > 1 ? remainder : `0${remainder}`
        } ${label}`;
      }
      return `${num} ${label}`;
    }
    const num: number = seconds / interval;
    const numberRounded: string =
      seconds % interval !== 0 ? num.toFixed(2) : num + '';
    return `${numberRounded} ${label}`;
  }
}
