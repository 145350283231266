import { Component, Input, OnInit } from '@angular/core';
import './social-push-mode.component.scss';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { API } from '@ui-resources-angular';
import { isEqual } from 'lodash-es';

export interface ConversationPushModeCompanyStatus {
  enabled: boolean;
  max_queue_size: number;
  conversation_rules_enabled: boolean;
  modify_on_hold_internal_status_after: number;
  modify_on_hold_internal_status_to: string;
  modify_on_hold_external_status_after: number;
  modify_on_hold_external_status_to: string;
}

export async function socialPushModeFn(api: API) {
  return api
    .get('conversation/conversationPushModeCompanyStatus')
    .then(({ data }) => data);
}

@Component({
  selector: 'ssi-social-push-mode',
  templateUrl: './social-push-mode.component.html',
  styles: []
})
export class SocialPushModeComponent implements OnInit {
  static resolve = [
    {
      token: 'socialPushModeStatus',
      resolveFn: socialPushModeFn,
      deps: [API]
    }
  ];

  @Input() socialPushModeStatus: ConversationPushModeCompanyStatus;

  socialPushModeForm: FormGroup;
  equalsCurrentValues = true;
  processingSubmit = false;

  constructor(private api: API, private state: StateService) {}

  ngOnInit() {
    this.socialPushModeForm = new FormGroup({
      enabled: new FormControl(this.socialPushModeStatus.enabled),
      max_queue_size: new FormControl(
        this.socialPushModeStatus.max_queue_size,
        Validators.required
      ),
      conversation_rules_enabled: new FormControl(
        this.socialPushModeStatus.conversation_rules_enabled
      ),
      modify_on_hold_internal_status_after: new FormControl(
        this.socialPushModeStatus.modify_on_hold_internal_status_after /
          (24 * 60) // convert to days
      ),
      modify_on_hold_internal_status_to: new FormControl(
        this.socialPushModeStatus.modify_on_hold_internal_status_to
      ),
      modify_on_hold_external_status_after: new FormControl(
        this.socialPushModeStatus.modify_on_hold_external_status_after /
          (24 * 60) // convert to days
      ),
      modify_on_hold_external_status_to: new FormControl(
        this.socialPushModeStatus.modify_on_hold_external_status_to
      )
    });
    this.onFormChanges();
  }

  onFormChanges(): void {
    this.socialPushModeForm.valueChanges.subscribe(() => {
      if (
        this.socialPushModeForm.value.enabled !==
        this.socialPushModeStatus.enabled
      ) {
        this.socialPushModeForm.value.enabled =
          this.socialPushModeForm.value.enabled === 'true';
      }
      if (
        this.socialPushModeForm.value.max_queue_size !==
        this.socialPushModeStatus.max_queue_size
      ) {
        this.socialPushModeForm.value.max_queue_size = parseInt(
          this.socialPushModeForm.value.max_queue_size,
          10
        );
      }
      if (
        this.socialPushModeForm.value.conversation_rules_enabled !==
        this.socialPushModeStatus.conversation_rules_enabled
      ) {
        this.socialPushModeForm.value.conversation_rules_enabled =
          this.socialPushModeForm.value.conversation_rules_enabled === 'true';
      }
      if (
        this.socialPushModeForm.value.modify_on_hold_internal_status_after !==
        this.socialPushModeStatus.modify_on_hold_internal_status_after
      ) {
        this.socialPushModeForm.value.modify_on_hold_internal_status_after = parseInt(
          this.socialPushModeForm.value.modify_on_hold_internal_status_after,
          10
        );
      }
      if (
        this.socialPushModeForm.value.modify_on_hold_external_status_after !==
        this.socialPushModeStatus.modify_on_hold_external_status_after
      ) {
        this.socialPushModeForm.value.modify_on_hold_external_status_after = parseInt(
          this.socialPushModeForm.value.modify_on_hold_external_status_after,
          10
        );
      }
      this.equalsCurrentValues =
        this.socialPushModeStatus.enabled ===
          this.socialPushModeForm.value.enabled &&
        this.socialPushModeStatus.max_queue_size ===
          this.socialPushModeForm.value.max_queue_size &&
        this.socialPushModeForm.value.conversation_rules_enabled ===
          this.socialPushModeForm.value.conversation_rules_enabled &&
        this.socialPushModeStatus.modify_on_hold_internal_status_after ===
          this.socialPushModeForm.value.modify_on_hold_internal_status_after &&
        this.socialPushModeStatus.modify_on_hold_internal_status_to ===
          this.socialPushModeForm.value.modify_on_hold_internal_status_to &&
        this.socialPushModeStatus.modify_on_hold_external_status_after ===
          this.socialPushModeForm.value.modify_on_hold_external_status_after &&
        this.socialPushModeStatus.modify_on_hold_external_status_to ===
          this.socialPushModeForm.value.modify_on_hold_external_status_to;
    });
  }

  onSubmit() {
    if (this.processingSubmit) {
      return;
    }
    this.processingSubmit = true;

    const settings = Object.assign({}, this.socialPushModeForm.value);
    settings.modify_on_hold_internal_status_after =
      settings.modify_on_hold_internal_status_after * (24 * 60); // convert to mins
    settings.modify_on_hold_external_status_after =
      settings.modify_on_hold_external_status_after * (24 * 60); // convert to mins
    this.api
      .post('conversation/conversationPushModeCompanyStatus', settings)
      .then((result) => {
        this.processingSubmit = false;
        if (result.status === 200) {
          this.state.go('auth.settings.index');
        }
      });
  }

  return() {
    this.state.go('^');
  }
}
