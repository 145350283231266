import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxUsefulThingsComponent } from './inbox-useful-things.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [InboxUsefulThingsComponent],
  exports: [InboxUsefulThingsComponent]
})
export class InboxUsefulThingsModule {}
