<div class="not-purchased-wrap">
    <h1 class="not-purchased-title">Looks like you're missing out...</h1>
    <p class="not-purchased-meta">Why waste your time between countless logins, shared computers, confused work emails and multiple sets of analytics across several different networks? Phew! We're tired just thinking about it</p>
    <ul class="not-purchased-cards">
        <li class="not-purchased-card">
            <i class="ssi ssi-empower-microcopy"></i>
            <h2 class="not-purchased-card-title">Empower your campaigns</h2>
            <p>One place to create and analyse all your organic and paid social media campaigns, built on clear insights</p>
        </li>
        <li class="not-purchased-card">
            <i class="ssi ssi-strategic-microcopy"></i>
            <h2 class="not-purchased-card-title">Drive <i>real</i> outcomes</h2>
            <p>Like web traffic, form complications and ecommerce sales, then track these back to specific campaigns, posts or adverts</p>
        </li>
        <li class="not-purchased-card">
            <i class="ssi ssi-strategic"></i>
            <h2 class="not-purchased-card-title"><i>Strategic</i> advertising</h2>
            <p>Recommendations based on the outcomes you want your paid activity to achieve, using universal presets for your team</p>
        </li>
    </ul>
    <p class="not-purchased-cta">Work smarter, not harder and contact our team today to talk about streaming all your advertising workflows into one powerful module.</p>
    <a href="mailto:sales@orlo.tech?subject=Interested in Advertising" class="btn btn-primary btn-style-2">Work Smarter</a>
</div>