import {
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { EmojiFormControlTriggerComponent } from './emoji-form-control-trigger/emoji-form-control-trigger.component';
import { EmojiFormControlDirective } from './emoji-form-control/emoji-form-control.directive';
import { JoyPixelsEmoji } from './emoji-picker/emoji-picker.component';

@Directive({
  selector: '[ssiEmojiFormControlContainer]'
})
export class EmojiFormControlContainerDirective {
  @Input() formControlValue: string;

  @Output() formControlValueChange = new EventEmitter();

  caretPosition: number;

  @ContentChild(EmojiFormControlTriggerComponent)
  set emojiFormControlTrigger(
    emojiFormControlTrigger: EmojiFormControlTriggerComponent
  ) {
    if (emojiFormControlTrigger) {
      emojiFormControlTrigger.emojiFormControlContainer = this;
    }
  }

  @ContentChild(EmojiFormControlDirective)
  set emojiFormControl(emojiFormControl: EmojiFormControlDirective) {
    if (emojiFormControl) {
      emojiFormControl.emojiFormControlContainer = this;
    }
  }

  addEmoji(emoji: JoyPixelsEmoji) {
    const text = this.formControlValue || '';
    const position = isNaN(this.caretPosition)
      ? text.length
      : this.caretPosition;

    const newText = emoji
      ? `${text.slice(0, position)}${emoji.char} ${text.slice(position)}`
      : text;

    this.formControlValueChange.emit(newText);
  }
}
