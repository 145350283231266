import './action-pad.component.scss';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  Injector,
  AfterViewChecked,
  AfterViewInit,
  OnInit,
  OnChanges,
  HostListener,
  OnDestroy,
  ViewChild,
  ElementRef,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { LANGUAGES, inboxPriorities } from '../../constants';
import { AssignMessageComponent } from '../assign-message/assign-message.component';
import { AuditComponent } from '../audit/audit.component';
import { EmailToModalComponent } from '../email-to-modal/email-to-modal.component';
import { ExportConversationComponent } from './../export-conversation/export-conversation.component';
import { PopupService } from '../../services/popup/popup.service';
import {
  CompanyPreferences,
  CompanyService
} from '../../services/company/company.service';
import { Debounce } from '../../decorators';
import {
  ActivityThread,
  ActivityTags,
  User,
  UserModel,
  Activity,
  Account,
  Conversation,
  ConversationStatus
} from '@ui-resources-angular';
import { DropDownItem } from '../../components/inbox-language-selector/inbox-language-selector.component';
import {
  DropdownSelect2Component,
  Option
} from '../dropdown-select-2/dropdown-select-2.component';
import {
  InboxKeyboardShortcutsService,
  KeyboardShortcut
} from '../../services/inbox-keyboard-shortcuts/inbox-keyboard-shortcuts.service';
import { Colleague, Team } from '../../services/api';
import { NotificationService } from '../../services/notification/notification.service';
import { RedactModalComponent } from '../redact-modal/redact-modal.component';
import { InboxMode } from '../../../modules/auth/inbox/inbox.component';

export enum ActionPadLayout {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  Hidden = 'hidden'
}

const MOBILE_WIDTH = 480;
const TABLET_WIDTH = 768;

const getRootActivity = (thread) => {
  console.log('wip thread:', thread);
  if (thread.parents.length > 0) {
    return thread.parents[0];
  }

  return thread.current;
};

export enum ActivityStatus {
  Unread = 'unread',
  Unactioned = 'unactioned',
  Actioned = 'actioned'
}

@Component({
  selector: 'ssi-action-pad',
  templateUrl: './action-pad.component.html'
})
export class ActionPadComponent
  implements OnInit, OnChanges, AfterViewChecked, AfterViewInit, OnDestroy {
  /* Most recent inbound message (thread.current activity) */
  @Input() activity: Activity;
  @Input() activityThread: ActivityThread;
  @Input() conversation: Conversation;
  @Input() crmHidden = false;
  @Input() inboxMode: InboxMode;
  @Input() isPushMode = false;
  @Input() actionRedact;

  InboxMode = InboxMode;

  layout: ActionPadLayout = ActionPadLayout.Hidden;
  ActionPadLayout = ActionPadLayout;

  languages = LANGUAGES;
  formattedLanguages: DropDownItem[];

  tags: Option[] = [];
  selectedTags: Option[] = [];
  priorities: string[] = inboxPriorities.map((p) => String(p));

  authUser: User;

  starred = false;
  showLanguageSelector = false;
  isSilenced = false;
  auditingEnabled = false;

  companyPreferencesHolder: CompanyPreferences;

  _keyboardShortcutSubsription: Subscription;

  @Output() onReloadResults = new EventEmitter<any>();
  @Output() onPin = new EventEmitter<any>();
  @Output() onResolveConversation = new EventEmitter<any>();
  @Output() onHoldConversationStatusChange = new EventEmitter<any>();
  @Output() onExitConversation = new EventEmitter<any>();
  @Output() onExitActivity = new EventEmitter<any>();
  @Output() onShowProfile = new EventEmitter<any>();
  @Output() onChangeLanguage = new EventEmitter<any>();
  @Output() onRedactConfirmed = new EventEmitter<void>();

  @ViewChild('actionPad') actionPad: ElementRef;
  @ViewChild('tagsSelector') tagsSelector: DropdownSelect2Component;

  constructor(
    private injector: Injector,
    private modal: NgbModal,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private popup: PopupService,
    private activityTags: ActivityTags,
    private userModel: UserModel,
    private inboxKeyboardShortcutsService: InboxKeyboardShortcutsService
  ) {}

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.keyCode === 84 || ['t', 'T'].includes(event.key)) {
      const activeElementFocusable =
        (document as any).activeElement.tabIndex !== -1;
      if (!activeElementFocusable) {
        this.tagsSelector.show();
      }
    }
  }

  async ngOnInit() {
    this.formattedLanguages = Object.entries(LANGUAGES)
      .map(([code, label]) => ({ value: code, label }))
      .sort((itemA: any, itemB) => itemA.label.localeCompare(itemB.label));

    this.companyService.hasFeatureAccess('AUDIT_LOG').then((enabled) => {
      this.auditingEnabled = enabled;
    });

    this.userModel.getAuthUser().then((user) => {
      this.authUser = user;
    });

    this._keyboardShortcutSubsription = this.inboxKeyboardShortcutsService.shortcutPressed$.subscribe(
      (key) => {
        if (key === KeyboardShortcut.AmendLanguage) {
          this.showLanguageSelector = true;
        }
      }
    );

    this.companyPreferencesHolder = await this.companyService.getPreferences();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activity'] && changes['activity'].currentValue) {
      this.setTagsForDropdown();
    }
  }

  ngAfterViewChecked(): void {
    this.isSilenced =
      this.activityThread &&
      this.activityThread.thread &&
      this.activityThread.thread.root &&
      this.activityThread.thread.root.inbox.is_silenced;
  }

  ngAfterViewInit(): void {
    this.layout = this._getLayoutClass();
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(200, false)
  onResize(event) {
    this.layout = this._getLayoutClass();
  }

  ngOnDestroy(): void {
    if (this._keyboardShortcutSubsription) {
      this._keyboardShortcutSubsription.unsubscribe();
    }
  }

  changeStatus() {
    this.activity.changeStatus(
      this.activity.statusText === ActivityStatus.Actioned
        ? ActivityStatus.Unread
        : ActivityStatus.Actioned
    );
  }

  private _getLayoutClass(): ActionPadLayout {
    const actionPadWidth = this.actionPad.nativeElement.clientWidth;
    return actionPadWidth > MOBILE_WIDTH
      ? actionPadWidth > TABLET_WIDTH
        ? ActionPadLayout.Desktop
        : ActionPadLayout.Tablet
      : ActionPadLayout.Mobile;
  }

  pin() {
    this.onPin.emit({ activity: this.activity });
  }

  async redactMessage() {
    const modal = this.modal.open(RedactModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.title = 'Redact message?';
    modal.componentInstance.info =
      'Redacting this message cannot be undone.  The text of the message will be removed and any attached media will be deleted. A timestamp will be placed within the message box to show who has redacted the content and when.';
    const confirmed = await modal.result;

    if (confirmed) {
      this.onRedactConfirmed.emit();
    }
  }

  setTagsForDropdown(): void {
    this.activityTags.getTags().then((tags) => {
      this.tags = tags.map((tag) => {
        return { key: tag, label: tag };
      });
      this.selectedTags = (this.activity.tags || [])
        .map((tag) => {
          return this.tags.find((t) => t.key === tag);
        })
        .filter((el) => el !== undefined);
    });
  }

  selectedTagsChange(selectedTags: Option[]): void {
    const activityTags = selectedTags.map((t) => t.key);
    this.activity.setTags(activityTags);
  }

  createTag(newTag: string) {
    const activityTags = [...this.selectedTags.map((t) => t.key), newTag];
    this.activity.setTags(activityTags).then((activity) => {
      this.setTagsForDropdown();
    });
  }

  showProfile(inPlaceOfMessage: boolean = false) {
    this.onShowProfile.emit(inPlaceOfMessage);
  }

  closeConversation() {
    this.onExitConversation.emit();
  }

  closeActivity() {
    this.onExitActivity.emit();
  }

  async holdConversationStatusChange(option: any): Promise<void> {
    this.onHoldConversationStatusChange.emit(option);
  }

  async resolve(withSurvey = true) {
    // default 'withSurvey' flag to 'true' excpet user explicitly selects 'without survey' option on UI - backend will
    // not try to send a survey if it's e.g. public convo or surveys aren't enabled for account
    // (it's safer that way since surveyConfig is being cached locally)

    this.onResolveConversation.emit({ with_survey: withSurvey });
  }

  openAssign() {
    const modal = this.modal.open(AssignMessageComponent, {
      windowClass: 'rounded-corners-40',
      centered: true
    });
    modal.componentInstance.activity = this.activity;
    modal.componentInstance.conversation = this.conversation;
    modal.componentInstance.showAssignMe = this.inboxMode;
    modal.componentInstance.isPushMode = this.isPushMode;
    modal.componentInstance.onAssigned.subscribe(
      (assignedUserOrTeam: Colleague | Team) => {
        if (this.isPushMode) {
          this.closeConversation();

          this.notificationService.open(
            assignedUserOrTeam
              ? `Conversation has been assigned & pushed to ${assignedUserOrTeam.name}`
              : `Conversation has been unassigned`,
            {
              class: 'ssi ssi-completed-notification',
              color: '#B2C614'
            },
            2000
          );
        }
      }
    );
  }

  openEmailExport() {
    const modal = this.modal.open(EmailToModalComponent, {
      windowClass: 'xl-modal orlo-modal'
    });
    modal.componentInstance.activity = this.activity;
    modal.componentInstance.activityThread = this.activityThread;
  }

  silenceThread() {
    this.popup
      .confirm({
        title: this.translate.instant('ARE_YOU_SURE'),
        message: this.translate.instant('CONFIRM_SILENCE_MODAL_DESCRIPTION'),
        cancelText: this.translate.instant('CANCEL'),
        okText: this.translate.instant('SILENCE_THREAD'),
        windowClass: 'modal-rounded-corners white-bg',
        hideClose: true,
        iconClass: 'ssi ssi-silence-thread'
      })
      .then((shouldSilence) => {
        if (shouldSilence) {
          getRootActivity(this.activityThread.thread).toggleSilenced(true);
          this.isSilenced = true;
          this.onReloadResults.emit();
        }
      });
  }

  unsilenceThread() {
    this.popup
      .confirm({
        title: this.translate.instant('ONE_MOMENT'),
        message: this.translate.instant('CONFIRM_UNSILENCE_MODAL_DESCRIPTION'),
        cancelText: this.translate.instant('CANCEL'),
        okText: this.translate.instant('UNSILENCE'),
        windowClass: 'modal-rounded-corners white-bg',
        hideClose: true,
        iconClass: 'ssi ssi-silence-thread'
      })
      .then((shouldUnsilence) => {
        if (shouldUnsilence) {
          getRootActivity(this.activityThread.thread).toggleSilenced(false);
          this.isSilenced = false;
          this.onReloadResults.emit();
        }
      });
  }

  openExportConversation() {
    const modal = this.modal.open(ExportConversationComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.activity = this.activity;
    modal.componentInstance.activityThread = this.activityThread;
  }

  openAudit(): void {
    const modal = this.modal.open(AuditComponent, {
      windowClass: 'm-modal rounded-corners-15',
      centered: true
    });
    modal.componentInstance.activity = this.activity;
  }
}
