<div class="toggle-switch-2-container">
	<span class="toggle-switch-2-label" [class.active]="modelValue">{{label}}</span>

	<label class="switch"
		   [for]="id">
		<span class="a11y-label">{{label}}</span>
		<input #inputRef
			   type="checkbox"
			   [id]="id"
				 [name]="id"
			   [disabled]="disabled"
			   [checked]="modelValue"
			   [value]="modelValue"
			   (change)="inputChange($event)">

		<span class="slider round"
			  [class.disabled]="disabled"></span>
	</label>
</div>