<div class="inbox-activity-section">
  <div class="section-chart-wrap">
    <ssi-highchart [config]="messagesChart"></ssi-highchart>
    <span class="section-chart-context-menu-icon ssi ssi-outbox-menu"></span>
    <!-- <ul class="section-chart-legend">
      <li *ngFor="let data of messagesChart.series">
        <span class="section-chart-legend-key" [ngStyle]="{'background-color': data.color}"></span>
        <p [innerHTML]="data.name"></p>
      </li>
    </ul> -->
  </div>

  <div class="section-grid section-grid-thirds">
    <div class="section-grid-item">
      <ssi-stat-bar-tile
        [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_PUBLIC_COMMENTS_OR_MENTIONS_AND_PRIVATE_OR_DIRECT_MESSAGES_RECEIVED_EACH_DAY' | translate"
        tooltipWindowClass="tooltip-chunky-big"
        [title]="'TOTAL_INBOUND_MESSAGES' | translate"
        iconClass="ssi ssi-inbound-messages-stat"
        [stat]="activityStats.current.inbound.count"
        [progressPercentage]="activityStats.previous && activityStats.current.inbound.count * 100 / activityStats.previous.inbound.count"
        progressColor="#425DEC"
      ></ssi-stat-bar-tile>
    </div>
    <div class="section-grid-item">
      <ssi-stat-bar-tile
        [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_OUTBOUND_REPLIES_SENT_BY_YOU_EACH_DAY' | translate"
        tooltipWindowClass="tooltip-chunky-big"
        [title]="'TOTAL_OUTBOUND_REPLIES' | translate"
        iconClass="ssi ssi-our-replies-stat"
        [stat]="activityStats.current.replies.count"
        [progressPercentage]="activityStats.previous && activityStats.current.replies.count * 100 / activityStats.previous.replies.count"
        progressPercentage="20"
        progressColor="#14BAE3"
      ></ssi-stat-bar-tile>
    </div>
    <div class="section-grid-item">
      <ssi-stat-bar-tile
        [ssiTooltip]="'THE_NUMBER_OF_INBOUND_MESSAGES_WITH_NO_ACTION_TAKEN_AT_THE_END_OF_THE_TIME_PERIOD_SELECTED' | translate"
        tooltipWindowClass="tooltip-chunky-big"
        [title]="'UNACTIONED_MESSAGES' | translate"
        iconClass="ssi ssi-unactioned"
        iconSize="32"
        [stat]="activityStats.current.inbound.unactioned_count"
        [progressPercentage]="activityStats.previous && activityStats.current.inbound.unactioned_count * 100 / activityStats.previous.inbound.unactioned_count"
        progressColor="#AEBDE3"
      ></ssi-stat-bar-tile>
    </div>
  </div>
</div>