import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { IconModule } from '../../../../../../common/components/icon/icon.module';

import { InsightsNavbarComponent } from './insights-navbar.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [InsightsNavbarComponent],
  imports: [CommonModule, NgbModule, TooltipModule, IconModule, FormsModule],
  exports: [InsightsNavbarComponent]
})
export class InsightsNavbarModule {}
