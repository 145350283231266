<div
  class="insights-widget"
  (click)="openDrilldownModal()"
>
  <div
    class="kpi"
    *ngFor="let kpi of data"
  >
    <span>{{kpi.label}}</span>
    <div class="divider"></div>
    <div>
      <ng-container *ngFor="let kpiVal of kpi.val; let i = index">
        <ssi-big-number [number]="kpiVal"></ssi-big-number>
        <!-- <span *ngIf="kpi.val.length > 1 && i + 1 < kpi.val.length"> / </span> -->
      </ng-container>
    </div>
  </div>
</div>