<ng-template #editAppModal let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <h4
      class="modal-title"
    >
    Install application
    </h4>
  </div>
  <div class="modal-body">
    <form novalidate #editAppForm="ngForm">
      <div class="form-group">
        <label translate="NAME"></label>
        <input
          type="text"
          class="form-control"
          name="name"
          required
          [(ngModel)]="editing.app.name">
      </div>
      <div class="form-group">
        <label translate="URL"></label>
        <input
          type="url"
          class="form-control"
          name="url"
          required
          pattern="https?://.+"
          [(ngModel)]="editing.app.url">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default"
      (click)="dismiss()"
      translate="CANCEL">
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="close(editing.app)"
      [disabled]="!editAppForm.form.valid"
      translate="SAVE">
    </button>
  </div>
</ng-template>
<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Manage apps</h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title">
        <i class="fa fa-link"></i> <span>Manage apps</span>
      </h3>
      <button class="btn btn-primary spacer-sides" (click)="editApps({})">
        <i class="fa fa-plus"></i> <span translate="CREATE_NEW"></span>
      </button>
      <div class="clearfix"></div>
    </div>
    <!-- <div class="loading" *ngIf="webhooksLoadingTracker.active"></div> -->
    <table class="table">
      <thead>
        <tr>
          <th translate="NAME"></th>
          <th translate="URL"></th>
          <!-- <th class="text-center" translate="STATUS"></th>
          <th class="text-center" translate="SEND_TEST_NOTIFICATION"></th>
          <th class="text-center" translate="EDIT"></th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let app of apps">
          <td>{{ app.name }}</td>
          <td>{{ app.url }}</td>
          <td class="text-right">
            <button
              class="btn btn-success"
              (click)="openSettings(app)">
              <i class="fa fa-settings"></i> <span translate="SETTINGS"></span>
            </button>
            <button
              class="btn btn-default"
              (click)="deleteApp(app)">
              <span translate="REMOVE"></span>
            </button>
          </td>
        </tr>
        <tr class="bg-info" *ngIf="apps.length === 0">
          <td colspan="5">
            <i class="fa fa-info"></i> <span>No Apps Installed Yet</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>