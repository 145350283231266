<div class="dashboard-widget dashboard-widget-inbox-message">
  <ssi-live-chat *ngIf="isLiveChatEnabled && widget.config?.liveChatEnabled"
                 [ngClass]="{ 'expanded': isLiveChatActive }"
                 [isLiveChatActive]="isLiveChatActive"
                 [hasConversationPushModeEnabled]="hasConversationPushModeEnabled"
                 (isLiveChatActiveChange)="updateLiveChatStatus($event)"></ssi-live-chat>

  <ssi-inbox-query-result-list *ngIf="inboxQuery"
                               [class.live-chat-tally-visible]="isLiveChatEnabled && widget.config?.liveChatEnabled"
                               [inboxQuery]="inboxQuery"></ssi-inbox-query-result-list>
</div>