<div *ngIf="loading" class="loading"></div>
<form
  #loginForm="ngForm"
  *ngIf="!loading && !loginSuccess"
  name="loginForm"
  class="login-form"
  autocomplete="off"
  novalidate
  (submit)="login(loginForm)"
>
  <div class="landing-container-inner border-bottom-rounded login-field">
    <div class="form-group">
      <label>Email</label>
      <input
        type="email"
        [(ngModel)]="email_address"
        name="email"
        required
        class="form-control"
        placeholder="email@company.com"
      >
    </div>

    <div class="form-group login-field">
      <label>Password</label>
      <ssi-password-input
        [(ngModel)]="password"
        [ngClass]="{'form-control-password-empty': !(password && password.length)}"
        name="password"
        placeholder="Enter your password..."
        class="form-control-password"
        required
      ></ssi-password-input>
      <div class="form-meta">
        <a
          href="javascript:;"
          translate="FORGOT_YOUR_PASSWORD_QUESTION_MARK"
          (click)="onChangeForm.emit('forgotPassword')"
        ></a>
      </div>
    </div>

    <div class="form-group form-group-authentication" *ngIf="twoFactorAuthenticationRequired">
      <label>Authentication</label>
      <input
        type="text"
        [(ngModel)]="two_factor_authentication_code"
        placeholder="Enter SMS code (6 digits)"
        class="form-control login-field"
        name="authentication"
        [required]="twoFactorAuthenticationRequired"
      >
      <small
        class="help-block text-light"
        translate="A_CODE_HAS_BEEN_SENT_TO_YOUR_MOBILE_ENTER_IT_HERE_TO_LOGIN">
      </small>
    </div>

    <div class="form-spacer" *ngIf="!twoFactorAuthenticationRequired"></div>
  </div>

  <div class="login-buttons">
    <button
      type="button"
      class="btn btn-google"
      (click)="loginWithGoogle()">
      <img src="https://www.orlo.app/app/web/production/4598ae00adfccb2efafd936ed4a998db.svg">
      Sign in with Google
    </button>
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="loginForm.invalid">
      Sign in with Email
    </button>
  </div>

  <div class="text-right login-terms-footer spacer-top">
    <p>
      By logging in you agree to <a target="_blank" href="https://www.orlo.tech/terms-and-conditions?hsLang=en">Orlo's T&C's</a>, view privacy policy <a href="https://www.orlo.tech/privacy-policy?hsLang=en" target="_blank" rel="noopener noreferrer">here</a>.
    </p>
  </div>

  <p class="chat-toggle-trigger" (click)="toggleChat()">
    <span translate="NEED_HELP_QUESTIONMARK"></span>
    <strong translate="CLICK_HERE"></strong>
    <span>to chat with our support team.</span>
  </p>
</form>
