<div class="section-heading">
  <h3 translate="TOP_POSTS"></h3>
  <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                             [selectedNetwork]="selectedNetwork"
                             (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
</div>
<div class="section-content"
     ssiLoader
     [ssiLoaderVisible]="loading">
  <ssi-top-post-list [posts]="postsToDisplay"
                     [selectedSortType]="selectedSortType"
                     (sortTypeChange)="onSortTypeChange($event)"></ssi-top-post-list>
</div>