<ssi-live-chat-avatar
  *ngIf="!! user && !! isAvatarOnly && !isTranscludeOnly"
  [user]="user"
  class="{{ user.networkStateValue }}"
></ssi-live-chat-avatar>

<ssi-live-chat-avatar
  *ngIf="!! user && !isAvatarOnly && !isAvatarAndMenuOnly && !isTranscludeOnly"
  (click)="onClick(user)"
  [statistic]="quantityOfActiveChats"
  [user]="user"
  class="{{ user.networkStateValue }}"
></ssi-live-chat-avatar>

<div
  *ngIf="!! user && !isAvatarOnly"
  [ngClass]="class"
>
  <span
    *ngIf="! canChangeState && !isAvatarAndMenuOnly && !isAvatarOnly"
    class="status"
  >
    <span
      *ngIf="!! user.networkStateValue"
      class="status-text"
    >
      {{ user.networkStateValue }}
    </span>
  </span>

  <!-- [disabled]="disabled" -->

  <button
    *ngIf="!! canChangeState && !! user.networkStateValue && !hasConversationPushModeEnabled"
    [ngClass]="{ self: canChangeState, unstyled: isTranscludeOnly || isAvatarAndMenuOnly }"
    class="status {{ user.networkStateValue }}"
    ngbDropdown
    ngbDropdownToggle
    type="button"
  >
    <span
      *ngIf="!isTranscludeOnly && !isAvatarAndMenuOnly"
      class="status-text"
    >
      {{ user.networkStateValue.toUpperCase() | translate }}
    </span>

    <i
      *ngIf="canChangeState && !isTranscludeOnly && !isAvatarAndMenuOnly"
      class="ssi ssi-arrow-down-correct status-icon"
    ></i>

    <ng-content
      *ngIf="isTranscludeOnly && !isAvatarAndMenuOnly"
    ></ng-content>

    <ssi-live-chat-avatar
      *ngIf="isAvatarAndMenuOnly && !isTranscludeOnly"
      [user]="user"
      class="{{ user.networkStateValue }}"
    ></ssi-live-chat-avatar>

    <ul
      class="dropdown-menu"
      ngbDropdownMenu
    >
      <li
        *ngFor="let option of options"
        (click)="setStatus(option)"
        [ngClass]="{ selected: option == user.networkStateValue }"
      >
        <button
          type="button"
        >
          <span
            *ngIf="isActiveAgent"
            translate="SET_YOURSELF_AS"
          ></span>

          <span
            *ngIf="! isActiveAgent"
            translate="SET_THEM_AS"
          ></span>

          <strong>
            {{ option }}
          </strong>
        </button>
      </li>
    </ul>
  </button>

  <!-- State change if Conversation Push Mode is enabled -->
  <div *ngIf="hasConversationPushModeEnabled"
    class="social-status"
    >
      <label>Live Chat</label>
      <div class="status-menu">
        <ul [class.active]="showMenu">
          <li (click)="showMenu = !showMenu" 
              class="initial {{user.networkStateValue}}">{{user.networkStateValue}}<i class="ssi ssi-arrow-down-correct"></i>
          </li>
          <li
            *ngFor="let option of options"
            (click)="setStatus(option); showMenu = false"
            [hidden]="user.networkStateValue === option"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </div>

  <dl
    *ngIf="!isTranscludeOnly && !isAvatarAndMenuOnly && !! isAgent"
    class="resolutions"
  >
    <dt
      translate="RESOLVED"
    ></dt>

    <dd>
      {{ quantityOfChatsResolvedToday }}
    </dd>
  </dl>

  <span
    *ngIf="!isTranscludeOnly && !isAvatarAndMenuOnly"
    class="name"
  >
    {{ name }}
  </span>
</div>
