<form #advertForm="ngForm" *ngIf="activeAdvert">
  <div class="advertising-section">
    <div class="row">
      <div class="col-xs-8 col-xs-offset-2">
        <ssi-outbox-post [post]="activeAdvert.outboxPost"></ssi-outbox-post>
      </div>
    </div>
  </div>

  <div class="advertising-section">
    <div class="advertising-preset-info">
      <div class="form-group">
        <label class="advertising-label advertising-label-block" translate="ADVERT_NAME"></label>
        <input type="text"
               class="preset-form-input"
               [(ngModel)]="activeAdvert.name"
               placeholder="Enter advert name"
               maxlength="50"
               required
               name="name">
      </div>
      
      <div class="form-group" *ngIf="activeAdvert.advertisingAccounts.length > 1">
        <label class="advertising-label advertising-label-block" translate="ACCOUNT"></label>
        <div class="preset-form-dropdown">
          <select [(ngModel)]="activeAdvert.account" required (ngModelChange)="accountUpdated()" name="account">
            <option [ngValue]="null" translate="SELECT_AN_ACCOUNT" disabled></option>
            <option *ngFor="let account of activeAdvert.advertisingAccounts; trackBy:trackById" [ngValue]="account">
              {{ account.name }} ({{ account.accountTypeLabel }})
            </option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>
      <div class="form-group" *ngIf="activeAdvert.account?.account_type_id === '9'">
        <label class="advertising-label advertising-label-block">Special Ad Category</label>
        <div class="preset-form-dropdown">
          <select [(ngModel)]="activeAdvert.specialAdCategory" name="specialAdCategory" required>
            <option [ngValue]="undefined" disabled>Please select one</option>
            <option [ngValue]="'NONE'">None</option>
            <option [ngValue]="'HOUSING'">Housing</option>
            <option [ngValue]="'CREDIT'">Credit</option>
            <option [ngValue]="'EMPLOYMENT'">Employment</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>

      <div class="form-group" *ngIf="activeAdvert.targetingPresets.length > 0">
        <label class="advertising-label advertising-label-block">Saved Target audience</label>
        <div class="preset-form-dropdown">
          <select [(ngModel)]="activeAdvert.preset" (ngModelChange)="getBudgetSuggestion()" name="preset">
            <option [ngValue]="null">No saved target audience</option>
            <option *ngFor="let preset of activeAdvert.targetingPresets; trackBy:trackById" [ngValue]="preset">
              {{ preset.name }}
            </option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>
      
      <div class="form-group" *ngIf="canCreateAdUnvalidated">
        <label class="advertising-label"><span translate="WAITING_FOR_APPROVAL"></span>?</label>
        <div class="checkbox spacer-sides">
          <div class="abc-checkbox">
            <input
            id="requiresValidation"
            type="checkbox"
            [checked]="activeAdvert.requiresValidation"
            (change)="activeAdvert.requiresValidation = !!$event.target.checked">
            <label for="requiresValidation"></label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="advertising-section">
    <div class="advertising-section-bullet-wrap">
      <span class="advertising-section-bullet">
        <i class="ssi ssi-audience"></i>
      </span>
    </div>
    <ssi-advertising-targeting-builder
      [account]="activeAdvert.account"
      [(targetingOptions)]="activeAdvert.targetingOptions"
      (targetingOptionsChange)="getBudgetSuggestion()"
      *ngIf="activeAdvert.account">
    </ssi-advertising-targeting-builder>
  </div>

  <div class="advertising-section advertising-section-wrap">
    <div class="advertising-section-bullet-wrap">
      <span class="advertising-section-bullet">
        <i class="ssi ssi-budget"></i>
      </span>
    </div>
    <div class="advertising-preset-wrap">
      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="DAILY_BUDGET"></span>:</p>
        <div class="preset-form-input-group input-group">
          <span class="input-group-addon">{{ currencySymbols[activeAdvert.account?.ad_currency] }}</span>
          <input type="text"
                 class="preset-form-input"
                 (ngModelChange)="activeAdvert.budget.daily=$event"
                 [ngModel]="activeAdvert.budget.daily | formatBudget"
                 [ngModelOptions]="{updateOn: 'blur'}"
                 required
                 name="dailyBudget">
        </div>
        <div class="col-sm-6" *ngIf="budgetSuggestion">
          <p class="form-control-static"><em><span translate="MINIMUM_BUDGET"></span> {{ budgetSuggestion.dailyBudget.min.amount | currency:budgetSuggestion.dailyBudget.min.currency }}</em></p>
        </div>
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="TOTAL_BUDGET"></span>:</p>
        <div class="preset-form-input-group input-group">
          <span class="input-group-addon">{{ currencySymbols[activeAdvert.account?.ad_currency] }}</span>
          <input type="text"
                 class="preset-form-input"
                 (ngModelChange)="activeAdvert.budget.total=$event"
                 [ngModel]="activeAdvert.budget.total | formatBudget"
                 [ngModelOptions]="{updateOn: 'blur'}"
                 required
                 name="totalBudget">
        </div>
        <div class="col-sm-6">
          <p class="form-control-static"><em><span translate="MINIMUM_BUDGET"></span> {{ activeAdvert.budget.daily | currency: activeAdvert.account?.ad_currency }}</em></p>
        </div>
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="BID_TYPE"></span>:</p>
        <div class="preset-form-dropdown">
          <select
            [(ngModel)]="activeAdvert.bidding.event"
            (ngModelChange)="getBudgetSuggestion()"
            required
            name="bidType">
            <option *ngIf="activeAdvert.account?.account_type_id !== '9'" value="CPC">Cost per click (CPC)</option>
            <option value="CPM">Cost per thousand impressions (CPM)</option>
          </select>
          <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="BID_AMOUNT"></span>:</p>
        <div class="preset-form-input-group input-group">
          <span class="input-group-addon">{{ currencySymbols[activeAdvert.account?.ad_currency] }}</span>
          <input type="text"
                 class="preset-form-input"
                 (ngModelChange)="activeAdvert.bidding.amount=$event"
                 [ngModel]="activeAdvert.bidding.amount | formatBudget"
                 [ngModelOptions]="{updateOn: 'blur'}"
                 required
                 name="bidAmount">
        </div>
        <div class="col-sm-6" *ngIf="budgetSuggestion">
          <p class="form-control-static">
            <em>
              The suggested bid to reach your audience and spend your budget is: {{ budgetSuggestion.suggestedBid.default.amount | currency:budgetSuggestion.suggestedBid.default.currency }}.
              (Other similar advertisers are bidding between {{ budgetSuggestion.suggestedBid.min.amount | currency:budgetSuggestion.suggestedBid.min.currency }} – {{ budgetSuggestion.suggestedBid.max.amount | currency:budgetSuggestion.suggestedBid.max.currency }}.)
            </em>
          </p>
          <div>
            <p class="form-control-static">
              <em>
                <span translate="MINIMUM_BID"></span> {{ budgetSuggestion.bid.min.amount | currency:budgetSuggestion.bid.min.currency }}.
              </em>
            </p>
          </div>
        </div>
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="START"></span>:</p>
        <input
          class="preset-form-input"
          type="text"
          mwlFlatpickr
          [(ngModel)]="activeAdvert.start"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-d H:i"
          [minDate]="now"
          [maxDate]="activeAdvert.end | addSeconds:-60"
          [time24hr]="true"
          altFormat="M J Y, H:i"
          placeholder="Start"
          required
          name="start">
        <div class="col-sm-6">
          <p class="form-control-static">
            <input type="checkbox" [(ngModel)]="activeAdvert.specifiedEndDate" name="specifiedEndDate"/>
            <em>
              <span translate="DO_YOU_REQUIRE_AN_END_DATE_FOR_THE_CAMPAIGN"></span>?
            </em>
          </p>
        </div>
      </div>

      <div class="advertising-preset-wrap" *ngIf="activeAdvert.specifiedEndDate">
        <p class="advertising-label"><span translate="END"></span>:</p>
        <input
          class="preset-form-input"
          type="text"
          mwlFlatpickr
          [(ngModel)]="activeAdvert.end"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-d H:i"
          [minDate]="(activeAdvert.start || now) | addSeconds:60"
          [time24hr]="true"
          altFormat="M J Y, H:i"
          placeholder="End"
          name="end">
      </div>

      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="LANGUAGE"></span>:</p>
          <div class="preset-form-dropdown">
            <select name="language" [(ngModel)]="activeAdvert.language">
              <option *ngFor="let language of advertLanguages" [ngValue]="language">
                {{ language.label }}
              </option>
            </select>
            <i class="fa fa-chevron-down preset-form-dropdown-icon"></i>
        </div>
      </div>
    </div>

    <div class="advertising-preset-wrap">
      <div class="advertising-section-bullet-wrap">
        <span class="advertising-section-bullet">
          <i class="ssi ssi-ad-schedule"></i>
        </span>
      </div>
      <div class="advertising-preset-wrap">
        <p class="advertising-label"><span translate="SCHEDULE"></span>:</p>
        <button
          class="btn btn-warning btn-advertising-preset"
          (click)="loadingTracker.add(saveBoostedPost())"
          [disabled]="loadingTracker.active || advertForm.invalid">
          <i class="fa fa-spinner fa-spin" *ngIf="loadingTracker.active"></i> <span translate="BOOST_POST"></span>
        </button>
      </div>
    </div>
  </div>

</form>
