<div class="user-performance-section">
  <div class="user-performance-filters">
    <label for="teamFilter">Filter by Team: </label>
    <select [(ngModel)]="selectedTeam" (ngModelChange)="filterByTeam(selectedTeam)">
      <option id="teamFilter" [value]="undefined">All Teams</option>
      <option *ngFor="let team of teams" [value]="team.id">{{team.name}}</option>
    </select>
  </div>
  <!-- <div
    *ngIf="tableLoading"
    class="loading"
  ></div> -->
  <ssi-treetable
    [data]="dataToDisplay"
    [bodyStyle]="{'max-height': '408px'}"
    [rowStyle]="{'height': '68px'}"
    [virtualScroll]="false"
    [resizeMode]="'fit'"
    [reorderable]="true"
    [saveSettings]="true"
    [settingsStorageKey]="'treetable-engagement-analytics-user-performance'"
    [globalFilterInputRef]=""
    id="treetable-engagement-analytics-user-performance"
  >
    <ssi-column header="User"
                field="user.fullName"
                [templateRefs]="{bodyCell: userBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.assigned"
                header="Assigned messages"
                [tooltip]="'THE_SHOWS_THE_NUMBER_OF_INBOUND_MESSAGES_THAT_WERE_ASSIGNED_TO_THIS_USER' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.actioned"
                header="Actioned messages"
                [tooltip]="'THE_SHOWS_THE_NUMBER_OF_INBOUND_MESSAGES_THAT_THIS_USER_HAS_RESPONDED_TO_OR_MARKED_AS_ACTIONED' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.totalOutboxPosts"
                header="Total messages published"
                [tooltip]="'THIS_TELLS_YOU_THE_NUMBER_OF_SOCIAL_POSTS_THE_USER_PUBLISHED' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.replies"
                header="Replies sent"
                [tooltip]="'THIS_TELLS_YOU_THE_NUMBER_OF_OUTBOUND_REPLIES_THIS_USER_SENT' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.avgFirstResponseTime"
                header="Av. First response"
                [tooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FOR_A_CUSTOMER_TO_RECEIVE_A_REPLY_FROM_THIS_USER_TO_THEIR_FIRST_INBOUND_MESSAGE' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.avgResponseTime"
                header="Av. response time"
                [tooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FOR_A_CUSTOMER_TO_RECEIVE_A_REPLY_FROM_THIS_USER_ACROSS_ALL_INBOUND_MESSAGES' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.avgHandlingTime"
                header="Av. handling time"
                [tooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FROM_A_CUSTOMER_RECEIVING_THEIR_FIRST_REPLY_TO_THIS_USER_RESOLVING_THE_CONVERSATION' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.conversations.assigned"
                header="Assigned conversations"
                [tooltip]="'THE_SHOWS_THE_NUMBER_OF_CONVERSATIONS_THAT_WERE_ASSIGNED_TO_THIS_USER' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
    <ssi-column field="current.conversations.resolved"
                header="Resolved conversations"
                [tooltip]="'THE_SHOWS_THE_NUMBER_OF_CONVERSATIONS_THAT_WERE_RESOLVED_BY_THIS_USER' | translate"
                [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>
  </ssi-treetable>

  <ng-template #userBodyCellTemplate
               ssiTemplateSelector="bodyCell"
               let-rowIndex="rowIndex"
               let-rowData="rowData"
               let-fieldValue="fieldValue">
    <div class="body-cell body-cell-primary">
      <i class="ssi ssi-user"></i>
      <p>{{fieldValue}}</p>
    </div>
  </ng-template>

  <ng-template #statBodyCellTemplate
               ssiTemplateSelector="bodyCell"
               let-rowIndex="rowIndex"
               let-rowData="rowData"
               let-column="column"
               let-columnIndex="columnIndex"
               let-columns="columns"
               let-columnsMap="columnsMap"
               let-fieldValue="fieldValue">
    <div class="body-cell stat"
         [class.row-even]="rowIndex % 2 !== 0">
      <p *ngIf="isTimeField(column.field)"
         [innerHTML]="secondsToHumanTime(fieldValue)"></p>
      <p *ngIf="!isTimeField(column.field)"
         [innerHTML]="fieldValue"></p>
    </div>
  </ng-template>
</div>