import {
  Component,
  OnInit,
  Inject,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  Webhook,
  WebhookModel,
  WebhookStatus
} from '../../../../../common/services/webhook-model/webhook-model';
import './manage-webhooks.component.scss';
import { API } from '@ui-resources-angular';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../../../common/services/popup/popup.service';

@Component({
  selector: 'ssi-manage-webhooks',
  templateUrl: './manage-webhooks.component.html'
})
export class ManageWebhooksComponent implements OnInit {
  @ViewChild('editWebhookModal') editWebhookModal: TemplateRef<any>;
  @ViewChild('deleteWebhookModal') deleteWebhookModal: TemplateRef<any>;

  webhooks: Webhook[];

  WebhookStatus = WebhookStatus; // tslint:disable-line

  webhooksLoadingTracker: AsyncTracker;

  saving: {
    tracker: AsyncTracker;
    webhook?: Webhook;
    mode?: 'status' | 'all';
  };

  editing: {
    webhook: {
      uuid: string;
      name: string;
      destination_url: string;
      dev_email: string;
      secret: string;
    };
  };

  constructor(
    private asyncTrackerFactory: AsyncTrackerFactory,
    private api: API,
    private modalService: NgbModal,
    private popup: PopupService,
    private translate: TranslateService,
    private webhookModel: WebhookModel
  ) {}

  async ngOnInit() {
    this.saving = {
      tracker: this.asyncTrackerFactory.create()
    };
    this.webhooksLoadingTracker = this.asyncTrackerFactory.create();
    const webhooksLoading: any = this.webhookModel.findAll();
    this.webhooksLoadingTracker.add(webhooksLoading);
    this.webhooks = await webhooksLoading;
  }

  saveWebhookChanges(
    webhook: Webhook,
    changes: object,
    mode: 'status' | 'all'
  ) {
    this.saving.webhook = webhook;
    this.saving.mode = mode;
    const webhookSaved: Promise<Webhook> = webhook.update(changes);
    this.saving.tracker.add(webhookSaved);
  }

  async editWebhook(webhook) {
    this.editing = {
      webhook: Object.assign({}, webhook)
    };
    const changes = await this.modalService.open(this.editWebhookModal, {
      backdrop: true
    }).result;
    if (webhook.uuid) {
      this.saveWebhookChanges(webhook, changes, 'all');
    } else {
      const webhookSaved = this.webhookModel.createNew(changes);
      this.webhooksLoadingTracker.add(webhookSaved);
      const newWebhook: Webhook = await webhookSaved;
      this.webhooks.push(newWebhook);
    }
  }

  async deleteWebhook(webhook) {
    const modal = await this.modalService.open(this.deleteWebhookModal, {
      backdrop: true
    });
    modal.result.then(async (confirm) => {
      if (confirm) {
        await this.api.del(`webhook/subscription?uuid=${webhook.uuid}`);
        this.webhooks = this.webhooks.filter(
          ($webhook) => webhook.uuid !== $webhook.uuid
        );
      }
    });
  }

  async sendTestNotification(webhook: Webhook) {
    await webhook.sendNotification({ timestamp: Date.now() });
    this.popup.alert({
      title: this.translate.instant(
        'A_TEST_MESSAGE_HAS_BEEN_SENT_TO_THIS_WEBHOOK'
      )
    });
  }
}
