<div class="section-heading">
  <h3 translate="TOPIC_FEEDBACK"></h3>
  <div class="section-tip-wrap">
    <div class="section-tip">
      <button
        class="section-tip-icon"
        (click)="proTipOpen = !proTipOpen"
      ><i class="ssi ssi-pro-tip"></i></button>
      <p [style.display]="proTipOpen ? 'inline-flex' : 'none'">
        {{'CHATBOT_TOPIC_FEEDBACK_PRO_TIP' | translate}}
      </p>
    </div>
  </div>
</div>
<div class="section-content">
  <div class="table-container">
    <ssi-treetable
      [data]="topicFeedback"
      [bodyStyle]="{'max-height': '408px'}"
      [rowStyle]="{'height': '68px'}"
      [virtualScroll]="false"
      [resizeMode]="'fit'"
      [reorderable]="true"
      [saveSettings]="true"
      [settingsStorageKey]="'treetable-chatbot-report-topic-feedback'"
      [globalFilterInputRef]=""
      id="treetable-chatbot-report-topic-feedback"
    >
      <ssi-column
        header=""
        [field]="' '"
        [sortable]="false"
        width="10%"
        [templateRefs]="{bodyCell: numberedOrderCellTemplate}"
      ></ssi-column>
      <ssi-column
        header="Topic"
        field="name"
        [templateRefs]="{bodyCell: statBodyCellTemplate}"
      ></ssi-column>
      <ssi-column
        header="Score"
        field="score"
        [templateRefs]="{bodyCell: statBodyCellTemplate}"
      ></ssi-column>
      <ssi-pagination
        [itemsPerPage]="7"
        [pageLinksSize]="5"
      ></ssi-pagination>
    </ssi-treetable>

    <ng-template
      #statBodyCellTemplate
      ssiTemplateSelector="bodyCell"
      let-rowIndex="rowIndex"
      let-rowData="rowData"
      let-fieldValue="fieldValue"
    >
      <div
        class="body-cell stat"
        [class.row-even]="rowIndex % 2 !== 0"
      >
        <p>
          {{fieldValue}}
        </p>
      </div>
    </ng-template>

    <ng-template
      #numberedOrderCellTemplate
      ssiTemplateSelector="bodyCell"
      let-rowIndex="rowIndex"
      let-rowData="rowData"
      let-fieldValue="fieldValue"
    >
      <div
        class="table-cell order-number-cell"
        [class.row-even]="rowIndex % 2 !== 0"
      >
        {{rowIndex + 1}}. {{fieldValue}}
      </div>
    </ng-template>
  </div>
</div>