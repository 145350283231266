import { Record, API, Model } from '@ui-resources-angular';
import { Injectable } from '@angular/core';

export enum WebhookStatus {
  Failed = 'failed',
  PendingValidation = 'pending-validation',
  Enabled = 'enabled',
  Disabled = 'disabled'
}

export interface NewWebhook {
  destination_url: string;
  dev_email: string;
  name: string;
  secret: string;
}

export class Webhook extends Record {
  uuid: string;
  destination_url: string;
  dev_email: string;
  name: string;
  status: WebhookStatus;
  secret?: string;

  update(changes): Promise<Webhook> {
    return this.injector
      .get(API)
      .post(
        'webhook/subscription',
        Object.assign({}, changes, { uuid: this.uuid }),
        { params: { _method: 'PUT' } }
      )
      .then(() => Object.assign(this, changes));
  }

  sendNotification(data: object): Promise<void> {
    return this.injector
      .get(API)
      .post('webhook/notification', { uuid: this.uuid, data })
      .then((result) => result.data);
  }
}

@Injectable()
export class WebhookModel extends Model<Webhook> {
  // tslint:disable-line
  constructor(private api: API) {
    super('webhook', {
      idAttribute: 'uuid',
      endpoint: 'webhook/subscription',
      deserialize(resourceConfig, result) {
        return result.data.list;
      },
      recordClass: Webhook
    });
  }

  createNew(webhook: NewWebhook): Promise<Webhook> {
    return this.api
      .post('webhook/subscription', webhook)
      .then(({ data: { uuid } }) => {
        delete webhook.secret;
        return this.inject(
          Object.assign({}, webhook, { uuid, status: 'enabled' })
        );
      });
  }
}
