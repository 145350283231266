import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { Nl2brModule } from '../../../../../../common/pipes/nl2br/nl2br.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    Nl2brModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet.serviceSelected.summary',
          url: '/summary',
          component: SummaryComponent
        }
      ]
    })
  ],
  declarations: [SummaryComponent]
})
export class SummaryModule {}
