import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BigNumberPipe } from './big-number.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [BigNumberPipe],
  exports: [BigNumberPipe]
})
export class BigNumberModule {}
