import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetChartBarComponent } from './widget-chart-bar.component';
import { HighchartModule } from '../../../../../../common/components/highchart/highchart.module';

@NgModule({
  declarations: [WidgetChartBarComponent],
  imports: [CommonModule, HighchartModule],
  exports: [WidgetChartBarComponent]
})
export class WidgetChartBarModule {}
