import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateMessageModalComponent } from './private-message-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [PrivateMessageModalComponent],
  exports: [PrivateMessageModalComponent]
})
export class PrivateMessageModalModule {}
