import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LiveChatConversationStatisticsModule } from '../live-chat-conversation-statistics/live-chat-conversation-statistics.module';
import { LiveChatNetworkStateModule } from '../live-chat-network-state/live-chat-network-state.module';
import { LiveChatTallyTileComponent } from './live-chat-tally-tile.component';
import './live-chat-tally-tile.scss';

@NgModule({
  declarations: [LiveChatTallyTileComponent],

  exports: [LiveChatTallyTileComponent],

  imports: [
    CommonModule,
    LiveChatConversationStatisticsModule,
    LiveChatNetworkStateModule
  ]
})
export class LiveChatTallyTileModule {}
