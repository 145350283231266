import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutboxPostStatsComponent } from './outbox-post-stats.component';
import { TranslateModule } from '@ngx-translate/core';
import { BigNumberModule } from '../../../components/big-number/big-number.module';
import { TooltipModule } from '../../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, TranslateModule, BigNumberModule, TooltipModule],
  declarations: [OutboxPostStatsComponent],
  exports: [OutboxPostStatsComponent],
  providers: []
})
export class OutboxPostStatsModule {}
