<div class="opnn">
  <div *ngIf="!disablePosting" class="opnn-input">
    <div class="opnn-input-wrap">
      <textarea
        class="opnn-input-textbox"
        role="textbox"
        [(ngModel)]="content"
        (ngModelChange)="contentChange($event)"
        #textarea
        [mention]="tagList"
        [mentionConfig]="{ labelKey: 'mentionName' }"
        placeholder="Type @ to bring up your team members..."
      ></textarea>
      <div
        class="opnn-tag"
        [innerHTML]="tags | sanitizedHtml"
      ></div>
    </div>
    <div class="opnn-input-footer">
      <button
        class="opnn-input-action"
        (click)="content = ''"
      >
        Cancel
      </button>
      <button
        class="opnn-input-action"
        [disabled]="disablePosting || !content"
        type="submit"
        (click)="saveNote()"
      >
        Save note
      </button>
    </div>
  </div>

  <ul *ngIf="notes?.length" class="opnn-list">
    <li
      *ngFor="let note of notes"
      class="opnn-note"
      [ngClass]="{ 'note-own' : note.created_by === authUser.id }"
    >
      <div class="opnn-note-body">
        <p
          class="opnn-note-content disabled-links"
          [innerHTML]="note.content | sanitizedHtml"
        ></p>
      </div>
      <div class="opnn-note-footer">
        <ng-container *ngIf="note.created_by === authUser.id">
          <button
            class="delete-button"
            (click)="showDeleteNoteTooltip()"
            #deleteNoteTooltip="ssiTooltip"
            [ssiTooltip]
            tooltipPlacement="right"
            tooltipTrigger="manual"
            [tooltipTemplate]="deleteNoteTooltipTemplate"
            [tooltipTemplateContext]="{ note: note }"
          >
            <i class="delete ssi ssi-small-delete"></i>
          </button>
        </ng-container>
        <ul class="opnn-note-meta">
          <li class="opnn-note-time">
            <p>{{ note.created_at | date: 'HH:mm' }}</p>
          </li>
          <li class="opnn-note-date">
            <p>{{ note.created_at | date: 'd MMM yyyy' }}</p>
          </li>
          <li class="opnn-note-author">
            <span><p>By {{ getAuthorName(note.created_by) }}</p></span>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>

<ng-template #deleteNoteTooltipTemplate let-note="note">
  <div
    class="delete-note-tooltip"
    (ssiOffClick)="deleteNoteTooltip.hide()"
  >
    <h5>Would you like to delete this note?</h5>
    <div class="buttons-container">
      <orlo-button
        state="text"
        text="Cancel"
        textColor="#43537F"
        (btnClick)="deleteNoteTooltip.hide()"
      ></orlo-button>
      <orlo-button
        state="text"
        text="Delete Note"
        textColor="#E34916"
        (btnClick)="deleteNote(note); deleteNoteTooltip.hide()"
      ></orlo-button>
    </div>
  </div>
</ng-template>