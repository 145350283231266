<div class="cl-button-container">
  <div class="head">
    <h2>Button</h2>
    <button type="button"
            class="g-btn g-btn-sm g-btn-secondary"
            (click)="disabled = !disabled"><span>Enable/Disable</span></button>
  </div>

  <div class="body">
    <div>
      <h4>A</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Primary</p>
      <p>SM size - defaults</p>

      <button type="button"
              class="g-btn g-btn-sm"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>

    <div>
      <h4>B</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Primary</p>
      <p>MD size - defaults</p>

      <button type="button"
              class="g-btn"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>

    <div>
      <h4>C</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Primary</p>
      <p>LG size - with icon</p>

      <button type="button"
              class="g-btn g-btn-lg"
              [disabled]="disabled"><i class="ssi ssi-correctedit"></i><span>Click meee</span></button>
    </div>

    <div>
      <h4>D</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Secondary</p>
      <p>MD size - defaults</p>

      <button type="button"
              class="g-btn g-btn-secondary"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>

    <div>
      <h4>E</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Secondary</p>
      <p>MD size - white background</p>

      <button type="button"
              class="g-btn g-btn-secondary bg-white"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>

    <div>
      <h4>F</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Secondary</p>
      <p>MD size - red background - with icon</p>

      <button type="button"
              class="g-btn g-btn-secondary bg-red"
              [disabled]="disabled"><i class="ssi ssi-delete"></i><span>Click meee</span></button>
    </div>

    <div>
      <h4>G</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Primary</p>
      <p>MD size - text only button - underlined</p>

      <button type="button"
              class="g-btn text-only-button underlined"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>

    <div>
      <h4>H</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Secondary</p>
      <p>MD size - text only button</p>

      <button type="button"
              class="g-btn g-btn-secondary text-only-button"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>

    <div>
      <h4>I</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Primary</p>
      <p>MD size - round icon only button</p>

      <button type="button"
              class="g-btn round-icon-only-button"
              [disabled]="disabled"><i class="ssi ssi-correctedit"></i></button>
    </div>

    <div>
      <h4>J</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Secondary</p>
      <p>MD size - black background</p>

      <button type="button"
              class="g-btn g-btn-secondary bg-black"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>

    <div>
      <h4>K</h4>
      <a target="_blank"
         href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-button/cl-button.component.html">
        View code on GitHub
      </a>
      <p>Primary</p>
      <p>MD size - red background</p>

      <button type="button"
              class="g-btn bg-red"
              [disabled]="disabled"><span>Click meee</span></button>
    </div>
  </div>
</div>