import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ShareType, SharePreview } from '@ui-resources-angular';
import { ApiService } from '../api';
import { ShareView } from './';
@Injectable({ providedIn: 'root' })
export class SocialService {
  constructor(protected api: ApiService) {}

  viewShare(
    accountId: string | number,
    sharePreview: SharePreview
  ): Promise<ShareView> {
    const endpoint =
      sharePreview.type === ShareType.QuotedTweet
        ? `${this.api.url}/social/viewTweet`
        : `${this.api.url}/social/viewShare`;

    const opts = {
      params: {
        account_id: accountId,
        share_id: sharePreview.social_id,
        tweet_id: sharePreview.social_id // /viewTweet endpoint expects tweet_id
      }
    };

    return this.api
      .get(endpoint, opts)
      .pipe(
        map((response: any) => {
          return new ShareView(response);
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
