import './plugin.component.scss';
import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  ViewChildren,
  QueryList,
  OnDestroy
} from '@angular/core';
import { API } from '@ui-resources-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { PluginService } from '../../services/plugin/plugin.service';
import { ActivityModel, ActivityThreadFactory } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportMessagesComponent } from '../export-messages/export-messages.component';
import { OrloCrmPersonComponent } from '../orlo-crm-person/orlo-crm-person.component';

export interface Plugin {
  id: number;
  url: string;
  name: string;
}

enum CrmModalOption {
  Relink = 'relink',
  Merge = 'merge'
}

@Component({
  selector: 'ssi-plugin',
  templateUrl: './plugin.component.html',
  styles: []
})
export class PluginComponent implements OnDestroy, OnInit {
  subscription: Subscription;
  plugins: any;
  windowOpen: boolean;
  activePluginContext: ElementRef;
  activePluginView: ElementRef;
  activePlugin: Plugin = {
    id: 0,
    url: '',
    name: ''
  };
  initialisedPlugins = {};
  crmPinned = false;

  @ViewChildren('iframe') iframes: QueryList<ElementRef>;
  @ViewChildren('iframe') iframe: ElementRef;
  @ViewChildren('iframeView') iframeViews: QueryList<ElementRef>;
  @ViewChildren('iframeView') iframeView: ElementRef;

  constructor(
    private plugin: PluginService,
    private sanitizer: DomSanitizer,
    private activityModel: ActivityModel,
    private state: StateService,
    private modal: NgbModal,
    private activityThreadFactory: ActivityThreadFactory,
    private api: API
  ) {}

  async ngOnInit() {
    const {
      data: { plugins }
    } = await this.plugin.fetchInstalledPlugins();
    this.plugins = plugins.map((plugin) => ({
      ...plugin,
      sanitizedURL: this.sanitizeURL(plugin.url)
    }));
  }

  init(event) {
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    this.plugin.trigger(
      { callbackId: event.data.params.callbackId },
      this.activePluginContext.nativeElement.contentWindow
    );
    // the SDK init is called with no actions in quite a few places so this stops overwriting
    if (event.data.params.actions.length > 0) {
      this.plugin.addInitialisedPlugin(
        this.activePluginContext.nativeElement.id,
        this.activePluginContext,
        event.data.params.actions
      );
    }
  }

  ngOnDestroy() {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  sanitizeURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setAppData(event) {
    // check whether the sender was from the view or the long running script. Janky...
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    const pluginId = this.activePluginContext.nativeElement.id;

    this.plugin.getAppPluginData(pluginId).then(({ data }) => {
      const existingData = JSON.parse(data.data);
      this.plugin
        .setAppPluginData(
          pluginId,
          JSON.stringify({ ...existingData, ...event.data.params.data })
        )
        .then((res) => {
          this.plugin.trigger(
            { callbackId: event.data.params.callbackId, params: res.data },
            this.activePluginContext.nativeElement.contentWindow
          );
        });
    });
  }

  getAppData(event) {
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    const pluginId = this.activePluginContext.nativeElement.id;

    this.plugin.getAppPluginData(pluginId).then(({ data }) => {
      this.plugin.trigger(
        { callbackId: event.data.params.callbackId, params: data },
        this.activePluginContext.nativeElement.contentWindow
      );
    });
  }

  setUserData(event) {
    // check whether the sender was from the view or the long running script. Janky...
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    const pluginId = this.activePluginContext.nativeElement.id;

    this.plugin.getUserPluginData(pluginId).then(({ data }) => {
      const existingData = JSON.parse(data.data);
      this.plugin
        .setUserPluginData(
          pluginId,
          JSON.stringify({ ...existingData, ...event.data.params.data })
        )
        .then((res) => {
          this.plugin.trigger(
            { callbackId: event.data.params.callbackId, params: res.data },
            this.activePluginContext.nativeElement.contentWindow
          );
        });
    });
  }

  getUserData(event) {
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    const pluginId = this.activePluginContext.nativeElement.id;

    this.plugin.getUserPluginData(pluginId).then(({ data }) => {
      this.plugin.trigger(
        { callbackId: event.data.params.callbackId, params: data },
        this.activePluginContext.nativeElement.contentWindow
      );
    });
  }

  linkOrloPersonToPlugin(event) {
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    const pluginId = this.activePluginContext.nativeElement.id;

    this.plugin
      .linkOrloPersonToPlugin(
        pluginId,
        event.data.params.orloId,
        event.data.params.pluginId
      )
      .then(async ({ data }) => {
        if (data.success) {
          this.plugin.trigger(
            { callbackId: event.data.params.callbackId, params: data },
            this.activePluginContext.nativeElement.contentWindow
          );

          this.plugin.pluginConnection('link', {
            pluginId,
            personPluginId: event.data.params.pluginId
          });
        } else {
          // If request to link fails show modal to handle relinking/merging
          const modal = this.modal.open(OrloCrmPersonComponent);
          modal.componentInstance.selectedPersonId = data.person_uuid;
          modal.componentInstance.linkedPersonId = data.error.match;
          const result = await modal.result;

          if (result === CrmModalOption.Relink) {
            await this.plugin.unlinkOrloPersonFromPlugin(
              pluginId,
              data.error.match,
              event.data.params.pluginId
            );
            this.plugin.pluginConnection('unlink', {
              pluginId,
              personPluginId: data.error.match
            });

            this.plugin
              .linkOrloPersonToPlugin(
                pluginId,
                event.data.params.orloId,
                event.data.params.pluginId
              )
              .then(async (res) => {
                if (res.data.success) {
                  this.plugin.pluginConnection('link', {
                    pluginId,
                    personPluginId: event.data.params.pluginId
                  });
                }
              });
          } else if (result === CrmModalOption.Merge) {
            this.api
              .post('crm/mergePeople', {
                keep: data.error.match,
                remove: data.person_uuid
              })
              .then((res) => console.log(res));
          } else {
            return;
          }
        }
      });
  }

  unlinkOrloPersonFromPlugin(event) {
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    const pluginId = this.activePluginContext.nativeElement.id;

    this.plugin
      .unlinkOrloPersonFromPlugin(
        pluginId,
        event.data.params.orloId,
        event.data.params.pluginId
      )
      .then((res) => {
        this.plugin.trigger(
          { callbackId: event.data.params.callbackId, params: res.data },
          this.activePluginContext.nativeElement.contentWindow
        );

        this.plugin.pluginConnection('unlink', {
          pluginId,
          personPluginId: event.data.params.pluginId
        });
      });
  }

  public async exportMessagesFromOrlo(event) {
    const isSentFromViewFrame = (this.activePluginContext = this.iframeViews.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    ));

    this.activePluginContext = isSentFromViewFrame
      ? isSentFromViewFrame
      : this.iframes.find(
          (iframe) => iframe.nativeElement.contentWindow === event.source
        );

    const activities = await this.activityModel
      .findOneById(this.state.params.activity)
      .then(async (activity) => {
        const activitiesArr = [];

        await this.activityThreadFactory
          .create(activity)
          .then((activityThread) => {
            activitiesArr.push(activityThread.thread.siblings.older.activities);
            activitiesArr.push(activityThread.thread.current);
            activitiesArr.push(activityThread.thread.siblings.newer.activities);
          });

        return activitiesArr.reduce((acc, val) => acc.concat(val), []);
      });

    const modal = this.modal.open(ExportMessagesComponent);
    modal.componentInstance.activities = activities;
    const result = await modal.result;

    this.plugin.trigger(
      {
        callbackId: event.data.params.callbackId,
        params: { exportedMessages: result }
      },
      this.activePluginContext.nativeElement.contentWindow
    );
  }

  pinCrm(event) {
    this.plugin.pluginPinned(event.data.params.bool);
    this.crmPinned = event.data.params.bool;
  }

  showURL(event) {
    this.activePluginContext = this.iframes.find(
      (iframe) => iframe.nativeElement.contentWindow === event.source
    );
    const pluginId = this.activePluginContext.nativeElement.id;

    this.activePlugin = this.plugins.find(
      (plugin) => plugin.id === parseInt(pluginId, 10)
    );

    // find the iframe view that corresponds to the long running script
    this.activePluginView = this.iframeViews.find(
      (iframe) => iframe.nativeElement.id === pluginId
    );

    this.activePluginView.nativeElement.contentWindow.location =
      event.data.params.url;
  }

  closeURL() {
    this.windowOpen = false;
    this.activePluginView = null;
    this.activePlugin = {
      id: 0,
      url: '',
      name: ''
    };
    this.plugin.pluginPinned(false);
  }

  @HostListener('window:message', ['$event'])
  handleMessage(event) {
    const installedPlugin = this.plugins
      ? this.plugins.find((plugin) =>
          event.origin === new URL(plugin.url).origin ? plugin : null
        )
      : null;
    if (installedPlugin) {
      const type = event.data.action;
      switch (type) {
        case 'init':
          this.init(event);
          break;
        case 'set_user_data':
          this.setUserData(event);
          break;
        case 'get_user_data':
          this.getUserData(event);
          break;
        case 'set_app_data':
          this.setAppData(event);
          break;
        case 'get_app_data':
          this.getAppData(event);
          break;
        case 'link_orlo_person_to_plugin':
          this.linkOrloPersonToPlugin(event);
          break;
        case 'unlink_orlo_person_from_plugin':
          this.unlinkOrloPersonFromPlugin(event);
          break;
        case 'export_messages_from_orlo':
          this.exportMessagesFromOrlo(event);
          break;
        case 'pin_crm':
          this.pinCrm(event);
          break;
        case 'show':
          this.showURL(event);
          break;
        case 'close':
          this.closeURL();
          break;
        default:
          break;
      }
    } else {
      return;
    }
  }
}
