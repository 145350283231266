import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ssi-chatbot',
  templateUrl: './chatbot.component.html',
  styles: []
})
export class ChatbotComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
