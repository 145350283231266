import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NameComponent } from './name.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet.serviceSelected.name',
          url: '/name',
          component: NameComponent
        }
      ]
    })
  ],
  declarations: [NameComponent]
})
export class NameModule {}
