import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { TotalChangeComponent } from './total-change.component';
import { BigNumberPipe } from '../../pipes/big-number/big-number.pipe';
import { SecondsToHumanTimePipe } from '../../pipes/seconds-to-human-time/seconds-to-human-time.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TotalChangeComponent],
  exports: [TotalChangeComponent],
  providers: [CurrencyPipe, DecimalPipe, BigNumberPipe, SecondsToHumanTimePipe]
})
export class TotalChangeModule {}
