import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePostsDissaproveComponent } from '././validate-posts-dissaprove.component';
import { FormsModule } from '@angular/forms';
import { DropdownSelect2Module } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';

@NgModule({
  imports: [CommonModule, FormsModule, DropdownSelect2Module],
  declarations: [ValidatePostsDissaproveComponent],
  exports: [ValidatePostsDissaproveComponent],
  entryComponents: [ValidatePostsDissaproveComponent]
})
export class ValidatePostsDissaproveModule {}
