import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostModalComponent } from './post-modal.component';
import { OrloComponentsModule } from 'orlo-components';
import { OutboxPostNewModule } from '../../../../../../../common/components/outbox-post-new/outbox-post-new.module';
import { OutboxPostNewStatModule } from '../../../../../../../common/components/outbox-post-new-stat/outbox-post-new-stat.module';
import { OutboxPostNewNotesModule } from '../../../../../../../common/components/outbox-post-new-notes/outbox-post-new-notes.module';

@NgModule({
  declarations: [PostModalComponent],
  imports: [
    CommonModule,
    OrloComponentsModule,
    OutboxPostNewModule,
    OutboxPostNewNotesModule,
    OutboxPostNewStatModule
  ],
  exports: [PostModalComponent],
  entryComponents: [PostModalComponent]
})
export class PostModalModule {}
