import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageUsersComponent } from './manage-users.component';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterModule } from '../../../../../common/pipes/filter/filter.module';
import { SortByModule } from '../../../../../common/pipes/sort-by/sort-by.module';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FilterModule,
    SortByModule,
    TooltipModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageUsers',
          url: '/manage/users',
          component: ManageUsersComponent,
          data: {
            permissions: {
              company: 'administer_users'
            }
          },
          resolve: ManageUsersComponent.resolve
        }
      ]
    })
  ],
  declarations: [ManageUsersComponent],
  providers: [],
  exports: [ManageUsersComponent]
})
export class ManageUsersModule {}
