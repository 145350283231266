import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamsService, ColleaguesService } from '../api';
import { UserSelectModalComponent } from '../../components/user-select-modal/user-select-modal.component';

@Injectable()
export class UserSelectorModalService {
  constructor(
    private modal: NgbModal,
    private teamsService: TeamsService,
    private colleaguesService: ColleaguesService
  ) {}

  async showSelector(canSelectGroup) {
    const colleagues = await this.colleaguesService.getAll();
    const teams = await this.teamsService.getAll();
    const modal = this.modal.open(UserSelectModalComponent, {});
    modal.componentInstance.colleagues = colleagues;
    modal.componentInstance.teams = canSelectGroup ? teams : [];
    const result = await modal.result;
    return result;
  }

  selectUserOrGroup() {
    return this.showSelector(true);
  }

  selectUser() {
    return this.showSelector(false);
  }
}
