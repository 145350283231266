import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Note, NoteModel } from '@ui-resources-angular';
import { ApiService } from '../api.service';

export enum NoteSubject {
  CrmPerson = 'crm_person',
  Activity = 'activity',
  Outbox = 'outbox',
  Draft = 'draft'
}

@Injectable({ providedIn: 'root' })
export class NotesService {
  endpoint = `${this.api.url}/note/index`;

  constructor(protected injector: Injector, protected api: ApiService) {}

  getAll(subject: NoteSubject, subject_id: string): Promise<Note[]> {
    const opts = {
      params: {
        subject,
        subject_id
      }
    };

    return this.api
      .get(this.endpoint, opts)
      .pipe(
        map((response: any) => {
          const noteModel = this.injector.get(NoteModel); // TODO: replace this js-data model with in-houce Note model
          const notes = response.data.map((n) => noteModel.inject(n));
          notes.sort(
            (nA, nB) => Date.parse(nB.created_at) - Date.parse(nA.created_at)
          );
          return notes;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  create(
    subject: NoteSubject,
    subject_id: string,
    content: string
  ): Promise<Note> {
    const payload = {
      subject,
      subject_id,
      content
    };

    return this.api
      .post(this.endpoint, payload)
      .pipe(
        map((createdNote: any) => {
          const noteModel = this.injector.get(NoteModel); // TODO: replace this js-data model with in-houce Note model
          return noteModel.inject(createdNote);
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  delete(subject: NoteSubject, subject_id: string, id: string): Promise<any> {
    const opts = {
      params: {
        subject,
        subject_id,
        id
      }
    };

    return this.api
      .delete(this.endpoint, opts)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }
}
