import { Component, Input } from '@angular/core';

import { Agent } from '../../../../../../library/models/live-chat/agent';

@Component({
  selector: 'ssi-live-chat-offline-agent-notice',
  templateUrl: './live-chat-offline-agent-notice.component.html',
  styles: []
})
export class LiveChatOfflineAgentNoticeComponent {
  @Input() agent: Agent;

  public get isAgentOffline(): boolean {
    return this.agent ? this.agent.isOffline : true;
  }
}
