import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ssi-file-uploader',
  template: `
    <div
      ssiFileUploader
      [accept]="accept"
      [maxFiles]="maxFiles"
      [disabled]="disabled"
      [fromSources]="fromSources"
      [restrictions]="restrictions"
      (uploadStart)="uploadStart.emit($event)"
      (uploadFinally)="uploadFinally.emit($event)"
      (uploadSuccess)="uploadSuccess.emit($event)"
      (uploadError)="uploadError.emit($event)"
    >
      <ng-content></ng-content>
    </div>
  `
})
export class FileUploaderComponent implements OnInit {
  /*
  This is just a temporary wrapper component for file-uploader.directive, so it can be downgraded and used in angularjs templates
  */
  @Input() accept;
  @Input() fromSources;
  @Input() disabled;
  @Input() maxFiles;
  @Input() restrictions;

  @Output() uploadStart = new EventEmitter<any>();
  @Output() uploadSuccess = new EventEmitter<any>();
  @Output() uploadError = new EventEmitter<any>();
  @Output() uploadFinally = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
