import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Account } from '@ui-resources-angular';
import { groupBy, uniqBy, orderBy } from 'lodash-es';
import { debounceTime } from 'rxjs/operators/debounceTime';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs';

import './advertising-targeting-builder.component.scss';

import {
  AdvertisingTargetingOption,
  AdvertisingTargetingOptionSuggestedValue,
  AdvertisingTargetingService,
  AdvertisingType
} from '../advertising-targeting/advertising-targeting.service';
import { trackByProperty } from '../../../../../common/util';
import { TargetingOption } from '../advertising-preset-model/advertising-preset-model.service';

interface TargetingOptionGroup {
  name: string;
  options: AdvertisingTargetingOption[];
  isActive: boolean;
  isCompulsory: boolean;
  isDisabled: boolean;
}

interface OptionGroupValue {
  optionGroup: TargetingOptionGroup;
  INCLUDE: AdvertisingTargetingOptionSuggestedValue[];
  EXCLUDE: AdvertisingTargetingOptionSuggestedValue[];
}

interface OptionPanelOpenValue {
  value: AdvertisingTargetingOptionSuggestedValue;
  type: AdvertisingType | string;
}

@Component({
  selector: 'ssi-advertising-targeting-builder',
  templateUrl: './advertising-targeting-builder.component.html'
})
export class AdvertisingTargetingBuilderComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() account: Account;

  @Input() targetingOptions: TargetingOption[];

  @Output() targetingOptionsChange = new EventEmitter<TargetingOption[]>();

  trackById = trackByProperty('id');

  trackByName = trackByProperty('name');

  targetingOptionGroups: TargetingOptionGroup[];

  optionGroupValues: OptionGroupValue[] = [];

  optionPanelOpen: {
    queryText?: string;
    optionGroup: TargetingOptionGroup;
    values?: OptionPanelOpenValue[];
    filteredValues?: OptionPanelOpenValue[];
  };

  AdvertisingType = AdvertisingType;

  optionsSubject = new Subject<string>();

  optionCurrentValue = '';

  estimatedAudienceSize: number;

  // used for being able to show old campaigns/saved audiences on edit
  oldLinkedInCompulsoryOptions = {
    'Locations / Country Group': 'Locations',
    'Locations / Country': 'Locations',
    'Locations / State': 'Locations',
    'Locations / Region': 'Locations'
  };

  linkedInCompulsoryOptions = ['Locations'];

  facebookCompulsoryOptions = ['Country', 'Region', 'City'];

  _optionsSubjectSubscription: Subscription;

  constructor(private targeting: AdvertisingTargetingService) {}

  ngOnDestroy() {
    if (!!this._optionsSubjectSubscription) {
      this._optionsSubjectSubscription.unsubscribe();
    }
  }

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.account && this.account) {
      this.optionGroupValues = [];
      this.updateEstimatedAudienceSize();
      this.loadTargetingOptions();
    }

    if (
      changes.targetingOptions &&
      this.targetingOptions &&
      this.targetingOptionGroups
    ) {
      this.updateOptionGroupValues();
    }
  }

  assignCompulsoryStatus(name) {
    try {
      switch (this.account.account_type_id) {
        case '8':
          return this.linkedInCompulsoryOptions.indexOf(name) !== -1 && true;
          break;
        case '9':
          return this.facebookCompulsoryOptions.includes(name);

          // @todo: find out if the next few lines are needed. They will never execute, because of
          // the return statement above...

          const compulsoryOption = this.targetingOptionGroups.filter((option) =>
            this.facebookCompulsoryOptions.includes(option.name)
          );
          return compulsoryOption[0].isActive && true;
          break;
        default:
          return false;
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  disableTargetingOptions(name) {
    switch (this.account.account_type_id) {
      case '8':
        if (this.linkedInCompulsoryOptions.indexOf(name) !== -1) {
          return false;
        }
        const compulsoryOptions = this.targetingOptionGroups.filter(
          (option) => this.linkedInCompulsoryOptions.indexOf(option.name) !== -1
        );
        const activeCompulsoryOptions = compulsoryOptions.filter(
          (option) => option.isActive
        );
        if (activeCompulsoryOptions.length) {
          return false;
        }
        return true;
        break;
      case '9':
        if (this.facebookCompulsoryOptions.includes(name)) {
          return false;
        }
        const facebookCompulsoryOptions = this.targetingOptionGroups.filter(
          (option) => this.facebookCompulsoryOptions.includes(option.name)
        );
        const facebookActiveCompulsoryOption = facebookCompulsoryOptions.filter(
          (option) => option.isActive
        );
        if (facebookActiveCompulsoryOption.length) {
          return false;
        }
        return true;
        break;
      case '10':
        return false;
        break;
      default:
        return true;
    }
  }

  async loadTargetingOptions() {
    this.targetingOptionGroups = Object.entries(
      groupBy(await this.targeting.getOptions(this.account), 'name')
    ).map(([name, options]) => {
      return {
        name,
        options,
        isActive: false,
        isCompulsory: false,
        isDisabled: true
      };
    });
    if (this.targetingOptions) {
      this.updateOptionGroupValues();
    }
    this.targetingOptionGroups.forEach((option) => {
      option.isCompulsory = this.assignCompulsoryStatus(option.name);
      option.isDisabled = this.disableTargetingOptions(option.name);
    });
  }

  private updateOptionGroupValues() {
    try {
      if (
        !(
          !!this.targetingOptionGroups &&
          Array.isArray(this.targetingOptionGroups)
        )
      ) {
        throw new Error(
          `Value for 'advertising targeting option groups' not in expected format.`
        );
      }

      this.targetingOptionGroups.forEach(
        (option) =>
          (option.isDisabled = this.disableTargetingOptions(option.name))
      );
      this.targetingOptionGroups.forEach(
        (optionGroup) => (optionGroup.isActive = false)
      );
      this.optionGroupValues = Object.entries(
        groupBy(this.targetingOptions, (option) => option.option.name)
      ).map(([name, options]) => {
        const optionGroupValue = {
          optionGroup: this.targetingOptionGroups.find(
            (group) =>
              group.name === name ||
              group.name === this.oldLinkedInCompulsoryOptions[name]
          ),
          INCLUDE: [],
          EXCLUDE: []
        };
        optionGroupValue.optionGroup.isActive = true;
        [AdvertisingType.Include, AdvertisingType.Exclude].forEach((type) => {
          optionGroupValue[type] = options
            .filter((option) => option.option.type === type)
            .map((option) => {
              return {
                id: option.value,
                name: option.label
              };
            });
        });
        return optionGroupValue;
      });
      this.updateEstimatedAudienceSize();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async showOptionPanel(optionGroup: TargetingOptionGroup) {
    this.optionPanelOpen = {
      optionGroup
    };

    if (optionGroup.options[0].supports_list) {
      await this.getOptionValues();
    }
  }

  searchOptionValues() {
    this.optionsSubject.next();

    this._optionsSubjectSubscription = this.optionsSubject
      .pipe(debounceTime(500))
      .subscribe(async () => {
        if (this.optionCurrentValue === this.optionPanelOpen.queryText) {
          return;
        }
        this.optionCurrentValue = this.optionPanelOpen.queryText;
        await this.getOptionValues();
      });
  }

  async getOptionValues() {
    try {
      if (
        !(!!this.optionGroupValues && Array.isArray(this.optionGroupValues))
      ) {
        throw new Error(
          `Value for 'advertising targeting builder option group values' not in expected format.`
        );
      }

      if (
        this.optionPanelOpen.optionGroup.options[0].supports_query &&
        !this.optionPanelOpen.queryText
      ) {
        return;
      }
      const optionGroupValue = this.optionGroupValues.find(
        (option) => option.optionGroup === this.optionPanelOpen.optionGroup
      );
      let suggestedValues = await this.targeting.getSuggestedValues(
        this.account,
        this.optionPanelOpen.optionGroup.options[0],
        { query: this.optionPanelOpen.queryText }
      );
      // only order values if there is one a-z character, stops re-ordering of age ranges, staff sizes etc
      const orderValuesByName = suggestedValues.some((value) =>
        /[A-Za-z]/.test(value.name)
      );
      if (orderValuesByName) {
        suggestedValues = orderBy(suggestedValues, 'name');
      }
      let newValues = suggestedValues.map((value) => {
        let type = '';
        if (
          optionGroupValue &&
          optionGroupValue.INCLUDE.some((i) => i.id === value.id)
        ) {
          type = AdvertisingType.Include;
        } else if (
          optionGroupValue &&
          optionGroupValue.EXCLUDE.some((i) => i.id === value.id)
        ) {
          type = AdvertisingType.Exclude;
        }
        return { value, type };
      });

      if (this.optionPanelOpen.values) {
        const existingSelectedValues = this.optionPanelOpen.values.filter(
          (value) => !!value.type
        );
        newValues = uniqBy(
          [...newValues, ...existingSelectedValues],
          (val) => val.value.id
        );
      }
      this.optionPanelOpen.values = newValues;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  purgeFromAudience(audienceId, array) {
    array = array.filter((option) => option.id !== audienceId);
    return array;
  }

  checkIfValueExists(audienceId, array) {
    if (array.filter((option) => option.id === audienceId).length > 0) {
      return true;
    }
    return false;
  }

  closeOptionPanel() {
    try {
      if (
        !(!!this.optionGroupValues && Array.isArray(this.optionGroupValues))
      ) {
        throw new Error(
          `Value for 'advertising targeting builder option group values' not in expected format.`
        );
      }

      let optionGroupValue: OptionGroupValue = this.optionGroupValues.find(
        (option) => option.optionGroup === this.optionPanelOpen.optionGroup
      );
      let audienceInclude = optionGroupValue
        ? [...optionGroupValue.INCLUDE]
        : [];
      let audienceExclude = optionGroupValue
        ? [...optionGroupValue.EXCLUDE]
        : [];
      if (!optionGroupValue) {
        optionGroupValue = {
          optionGroup: this.optionPanelOpen.optionGroup,
          INCLUDE: [],
          EXCLUDE: []
        };
        this.optionGroupValues = [...this.optionGroupValues, optionGroupValue];
      }
      (this.optionPanelOpen.values || []).forEach((value) => {
        switch (value.type) {
          case 'INCLUDE':
            {
              if (this.checkIfValueExists(value.value.id, audienceInclude)) {
                return false;
              }
              if (this.checkIfValueExists(value.value.id, audienceExclude)) {
                audienceExclude = this.purgeFromAudience(
                  value.value.id,
                  audienceExclude
                );
              }
              audienceInclude.push(value.value);
            }
            break;
          case 'EXCLUDE':
            {
              if (this.checkIfValueExists(value.value.id, audienceExclude)) {
                return false;
              }
              if (this.checkIfValueExists(value.value.id, audienceInclude)) {
                audienceInclude = this.purgeFromAudience(
                  value.value.id,
                  audienceInclude
                );
              }
              audienceExclude.push(value.value);
            }
            break;
          case 'Remove':
            {
              if (value.type === 'Remove') {
                audienceInclude = this.purgeFromAudience(
                  value.value.id,
                  audienceInclude
                );
                audienceExclude = this.purgeFromAudience(
                  value.value.id,
                  audienceExclude
                );
              }
            }
            break;
          default:
            return false;
        }
      });
      optionGroupValue.INCLUDE = audienceInclude;
      optionGroupValue.EXCLUDE = audienceExclude;
      this.optionGroupUpdated(optionGroupValue);
      this.optionPanelOpen = null;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  removeOptionGroupValue(
    optionGroupValue: OptionGroupValue,
    type: AdvertisingType,
    value: AdvertisingTargetingOptionSuggestedValue
  ) {
    optionGroupValue[type] = optionGroupValue[type].filter((i) => i !== value);
    this.optionGroupUpdated(optionGroupValue);
  }

  private optionGroupUpdated(optionGroupValue: OptionGroupValue) {
    if (
      optionGroupValue.INCLUDE.length === 0 &&
      optionGroupValue.EXCLUDE.length === 0
    ) {
      this.optionGroupValues = this.optionGroupValues.filter(
        (i) => i !== optionGroupValue
      );
      optionGroupValue.optionGroup.isActive = false;
    } else {
      optionGroupValue.optionGroup.isActive = true;
    }
    this.updateEstimatedAudienceSize();
    this.serialiseTargetingOptions();
  }

  private async updateEstimatedAudienceSize() {
    this.estimatedAudienceSize = undefined;
    const targetingOptions = new Map<
      AdvertisingTargetingOption,
      AdvertisingTargetingOptionSuggestedValue[]
    >();
    this.optionGroupValues.forEach((ogValue) => {
      [AdvertisingType.Include, AdvertisingType.Exclude].forEach((type) => {
        if (ogValue[type].length > 0) {
          if (
            !(
              !!ogValue &&
              !!ogValue.optionGroup &&
              !!ogValue.optionGroup.options &&
              Array.isArray(ogValue.optionGroup.options)
            )
          ) {
            return;
          }

          const option = ogValue.optionGroup.options.find(
            (i) => i.type === type
          );
          targetingOptions.set(option, ogValue[type]);
        }
      });
    });
    if (targetingOptions.size > 0) {
      this.estimatedAudienceSize = await this.targeting.getEstimatedAudienceSize(
        this.account,
        targetingOptions
      );
    }
  }

  private serialiseTargetingOptions() {
    const targetingOptions = [];
    this.optionGroupValues.forEach((optionGroupValue) => {
      [AdvertisingType.Include, AdvertisingType.Exclude].forEach((type) => {
        optionGroupValue[type].forEach((value) => {
          if (
            !(
              !!optionGroupValue &&
              !!optionGroupValue.optionGroup &&
              !!optionGroupValue.optionGroup.options &&
              Array.isArray(optionGroupValue.optionGroup.options)
            )
          ) {
            return;
          }

          const option = optionGroupValue.optionGroup.options.find(
            (i) => i.type === type
          );
          targetingOptions.push({
            id: option.id,
            value: value.id,
            label: value.name,
            option
          });
        });
      });
    });
    this.targetingOptionsChange.emit(targetingOptions);
  }

  toggleOptionRadioValue(value: OptionPanelOpenValue, type: AdvertisingType) {
    // hack to work around native radios not allowing an indeterminate state
    if (value.type === type) {
      value.type = 'Remove';
    }
  }

  filterLocalOptions() {
    if (this.optionPanelOpen.queryText) {
      this.optionPanelOpen.filteredValues = this.optionPanelOpen.values.filter(
        (value) =>
          value.value.name
            .toLowerCase()
            .includes(this.optionPanelOpen.queryText.toLowerCase())
      );
    } else {
      this.optionPanelOpen.filteredValues = undefined;
    }
  }

  supportsExclude(optionGroup) {
    if (
      !(
        !!optionGroup &&
        !!optionGroup.options &&
        Array.isArray(optionGroup.options)
      )
    ) {
      return false;
    }

    const excludeSupported = optionGroup.options.find(
      (option) => option.type === 'EXCLUDE'
    );
    return excludeSupported ? true : false;
  }
}
