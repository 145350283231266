import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as EmojiToolkit from 'emoji-toolkit';
import 'emoji-assets/sprites/joypixels-sprite-24.css';

function containsEmoji(str: string): boolean {
  return /[^\u0000-\u00ff]/.test(str);
}

@Directive({
  selector: '[ssiEmojiText]',
  host: {
    // tslint:disable-line
    '[innerHtml]': 'emojiedText'
  }
})
export class EmojiTextDirective implements OnChanges {
  @Input('ssiEmojiText') text: string;

  @Input() sprites = false;

  @Input() spriteSize = '32';

  @Input() greedyMatch = false;

  emojiedText = '';

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.text && this.text) {
      if (containsEmoji(this.text)) {
        try {
          EmojiToolkit.sprites = this.sprites;
          EmojiToolkit.spriteSize = this.spriteSize;
          EmojiToolkit.greedyMatch = this.greedyMatch;
          this.emojiedText = EmojiToolkit.toImage(this.text);
          // workaround bug with emojione, remove once https://github.com/emojione/emojione/pull/540 is merged in joypixels repo
          if (
            this.sprites &&
            !this.emojiedText.includes(`emojione-${this.spriteSize}-`)
          ) {
            this.emojiedText = this.emojiedText.replace(
              `joypixels-32-`,
              `joypixels-${this.spriteSize}-`
            );
          }
        } catch (e) {
          this.emojiedText = this.text;
        }
      } else {
        this.emojiedText = this.text;
      }
    }
  }
}
