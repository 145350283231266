import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';

import { NetworkState } from '../interfaces/live-chat/network-state';

export function get_database_network_state(value: string): NetworkState {
  return {
    updatedAt: firebase.database.ServerValue.TIMESTAMP,
    value
  };
}

export function get_firestore_network_state(
  value: string
): { networkState: NetworkState } {
  return {
    networkState: {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      value
    }
  };
}
