<div
    class="inline-note-container"
    [ngClass]="{
        'inline-note-container-inverse': inverse,
        'inline-note-container-public': isPublic
    }">
    <div class="inline-note-left">
        <div class="inline-notes-avatar-wrap">
            <i class="ssi ssi-message-notes inline-notes-avatar-icon"></i>
        </div>
    
        <span *ngIf="showGuide" class="inline-notes-avatar-guide"></span>
    </div>
    <div class="inline-note-right">
        <div class="inline-note-wrap">
            <div class="inline-note inline-add-note">
                <ssi-add-note
                    (addNote)="addNote.emit($event)"
                    (cancelNote)="cancelNote.emit()">
                </ssi-add-note>
            </div>
        </div>
    </div>
</div>