<div class="cl-post-scheduler-container">
  <div class="head">
    <h2>Post Scheduler</h2>
  </div>

  <div class="body">
    <div>
      
      <button
        type="button"
        class="g-btn g-btn-sm"
        (click)="showPostScheduler = true"
        cdkOverlayOrigin
        #postSchedulerTrigger="cdkOverlayOrigin"
      ><span>Open Scheduler (Facebook Friendsnaps)</span></button>

      <br>

      output:
      <code>
        {{scheduledDate}}
      </code>
    </div>

    <ng-template
      #actionsMenu="cdkConnectedOverlay"
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="postSchedulerTrigger"
      [cdkConnectedOverlayOpen]="showPostScheduler"
      [cdkConnectedOverlayFlexibleDimensions]="false"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      [cdkConnectedOverlayPush]="true"
      (backdropClick)="showPostScheduler = false"
    >
      <ssi-post-scheduler
        [scheduledDate]="scheduledDate"
        [accounts]="facebookFriendsnaps"
        (onCloseClick)="showPostScheduler = false"
        (onAddScheduleClick)="scheduledDate = $event; showPostScheduler = false"
      ></ssi-post-scheduler>
    </ng-template>

  </div>
</div>