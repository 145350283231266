<ng-template #defaultTemplate>

  <div class="tooltip-icon" *ngIf="iconClass" [class.tooltip-icon-padding]="!!title">
    <i [ngClass]="iconClass"></i>
  </div>
  <div class="tooltip-body">
    <div class="tooltip-body-title" *ngIf="title">
      {{ title }}
    </div>
    <div class="tooltip-body-contents" 
         [innerHTML]="contents">
    </div>
    <i
      class="trailing-icon"
      *ngIf="trailingIconClass"
      [ngClass]="trailingIconClass"
    ></i>
  </div>
  <div data-popper-arrow class="tooltip-arrow"></div>
</ng-template>

<ng-template
  [ngTemplateOutlet]="template || defaultTemplate"
  [ngTemplateOutletContext]="templateContext || ''">
</ng-template>
