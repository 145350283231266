import './view-report.component.scss';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';

import { Account, User, UserModel } from '@ui-resources-angular';
import {
  InsightsReport,
  InsightsService,
  LocationTier,
  dedupeAndMergeFiltersForAPI,
  networksWithInsightsSources,
  sanitizeFiltersForAPI
} from '../../insights.service';
import { SaveReportModalComponent } from '../../common/components/save-report-modal/save-report-modal.component';
import { KeywordSearchType } from '../../common/components/tags-input/tags-input.component';
import { NotificationService } from '../../../../../common/services/notification/notification.service';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { BrowserlessPDFExportService } from '../../../../../common/services/browserless-pdf-export/browserless-pdf-export.service';
import {
  colleagues,
  teams,
  authUser,
  workflowAccounts,
  accounts
} from '../../../../common-resolves';
import {
  sentiments,
  findSentimentConst
} from '../../../../../common/constants/sentiments';
import { ageOptions } from '../../../../../common/constants/age-options';
import { LANGUAGES } from '../../../../../common/constants';
import { emotions } from '../../../../../common/constants/emotions';
import { industries } from '../../../../../common/constants/industries';
import { interests } from '../../../../../common/constants/interests';
import {
  Colleague,
  Team,
  MonitoringStreamsService,
  ReportAutomationFrequency
} from '../../../../../common/services/api';
import { commonDateRanges, DateRanges } from '../../../../../common/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessOptions, accessOptions } from '../../../../../common/constants';
import { Observable } from 'rxjs';
import { orderBy } from 'lodash-es';
import { format } from 'date-fns';
import { InsightsFiltersService } from '../../common/components/global-filters/insights-filters.service';
import { InsightsFilter } from '../../common/components/global-filters/global-filters.component';
import { FieldName } from '../../common/constants/filters-field-definitions';
import { WidgetsGridComponent } from '../../common/components/widgets-grid/widgets-grid.component';

// semanticFunction(queryString: string)<SemanticSearch> => {
//   {
//     field: "Content Vector",
//     semantic_search: { query:"foo", min_score:0.6}
//   }
// }

export interface Eq {
  eq: string | boolean | number; // int
}
export interface SemanticSearch {
  query: string;
  min_score: number; // float, min = 0, max = 1
}
export interface Match {
  match: string;
}
export interface Query {
  query: string;
}
export interface Range {
  gte: string;
  lte: string;
}
export interface In {
  in: (string | number | null)[];
}
export interface Any {
  any: (string | number)[];
}
export interface All {
  all: (string | number)[];
}
export interface NoneOf {
  none_of: (string | number)[];
}

export interface Filter {
  key: string;
  label: string;
  disabled?: boolean;
  field: string;
  range?: Range;
  all?: (string | number)[] | string;
  any?: Any;
  none_of?: NoneOf;
  eq?: Eq;
  match?: Match;
  in?: (string | number | null)[];
  semantic_search?: SemanticSearch;
  query?: Query;
}

@Component({
  selector: 'ssi-view-report',
  templateUrl: './view-report.component.html',
  styles: []
})
export class ViewReportComponent implements OnInit {
  static resolve = [authUser, colleagues, teams, workflowAccounts, accounts];

  @Input() colleagues: Colleague[];
  @Input() teams: Team[];
  @Input() workflowAccounts: Account[];
  @Input() accounts: Account[];

  report: InsightsReport;
  reportView: 'graph' | 'comment' = 'graph';
  filtersBarPinned = false;
  authUser: User;
  streamIds: string[];
  streams: any[];
  filteredTeams: Team[] = [];
  filteredColleagues: Colleague[] = [];
  showGlobalFilters = false;
  globalFiltersModel;
  activeFilters: Filter[] = []; // USED BY API
  dateRanges: DateRanges = commonDateRanges;
  templateMode = false;
  accessOptions: AccessOptions = accessOptions;
  gridDimensions: { height: number; width: number };
  @ViewChild('widgetsGridRef') widgetsGridRef: WidgetsGridComponent;

  public loading$: Observable<boolean>;

  constructor(
    private state: StateService,
    private translate: TranslateService,
    private userModel: UserModel,
    private popup: PopupService,
    private notification: NotificationService,
    private insightsService: InsightsService,
    private monitoringStreamsService: MonitoringStreamsService,
    private browserlessPDFExportService: BrowserlessPDFExportService,
    protected modal: NgbModal,
    protected insightsFiltersService: InsightsFiltersService
  ) {}

  async ngOnInit() {
    this.reportView = this.state.params.mode || 'graph';
    this.templateMode = this.state.params.template === 'true';
    this.loading$ = this.browserlessPDFExportService.getLoading();
    this.workflowAccounts = orderBy(this.workflowAccounts, [
      'account_type_name',
      'name'
    ]);
    // this.browserlessMode = localStorage.getItem('browserless-io') === 'true';
    // const fetchBrowserlessCookie = document.cookie.match(new RegExp('(^| )' + 'browserless-io' + '=([^;]+)'));
    const fetchFiltersCookie = document.cookie.match(
      new RegExp('(^| )' + 'insights_filters' + '=([^;]+)')
    );
    const browserlessAppliedFilters = fetchFiltersCookie
      ? JSON.parse(fetchFiltersCookie[2])
      : [];

    const reportId = this.state.params.id;
    let savedKeywordFilter;

    if (this.templateMode) {
      await this.insightsService
        .getTemplateReports()
        .then((templateReports) => {
          this.report = templateReports.find(
            (r: any) => r.template_key === reportId
          );
        });
    } else {
      await this.insightsService.getReport(reportId).then((report) => {
        this.report = report;

        // if (this.state.params.frequency) {
        //   // override report dates - frequency is set for automated reports (headless browser running on the server)
        //   this.report.filters = (this.report.filters || []).filter(
        //     (f) => f.field !== FieldName.CreatedAt
        //   );
        //   this.report.filters.push({
        //     field: FieldName.CreatedAt,
        //     range:
        //       this.state.params.frequency === ReportAutomationFrequency.WEEKLY
        //         ? { gte: '-7 days', lte: 'now' }
        //         : { gte: '-30 days', lte: 'now' }
        //   } as any);
        // }
      });
    }

    await this.userModel.getAuthUser().then((user) => {
      this.authUser = user;
    });

    await this.monitoringStreamsService.getAll().then((streams) => {
      this.streams = streams;
      this.streams.sort((a, b) => a.name.localeCompare(b.name));
      this.streamIds = this.streams.map((s) => s.id);
    });
    // console.log('Report:', this.report);
    this.filteredTeams = this.teams.sort(this.sortByTeamName);
    this.filteredColleagues = this.colleagues.sort(this.sortByFullName);

    if (browserlessAppliedFilters.length > 0) {
      // Allows exported reports to use the currently applied filters

      this.globalFiltersModel = await this.insightsFiltersService.generateInsightsFiltersModel(
        browserlessAppliedFilters.filter(
          ({ field }) =>
            field && field !== 'Saved Streams' && field !== 'Inbox Accounts'
        ),
        await this.insightsService.convertAccountFilters(
          browserlessAppliedFilters
        ),
        await this.insightsService.convertSavedStreamFilters(
          browserlessAppliedFilters
        )
      );

      savedKeywordFilter = browserlessAppliedFilters.find(
        ({ field }) => field === 'Content'
      );
    } else {
      let accIds = this.report.account_ids;
      if (
        Array.isArray(this.report.account_ids) &&
        this.report.account_ids.length === this.workflowAccounts.length
      ) {
        // if no accounts are selected when creating/updating a report then all accounts are sent to the backend (see CT-4933)
        // in that case keep treating it as no account filters are applied (in the UI)
        accIds = [];
      }

      this.globalFiltersModel = await this.insightsFiltersService.generateInsightsFiltersModel(
        this.report.filters,
        accIds,
        this.report.search_stream_ids
      );

      savedKeywordFilter = this.report.filters.find(
        ({ field }) => field === 'Content'
      );
    }

    if (savedKeywordFilter) {
      this.onKeywordFilter({
        condition: savedKeywordFilter.match ? 'match' : 'query',
        tags: [savedKeywordFilter.match || savedKeywordFilter.query]
      });
      // Keyword search isn't in the filter panel so is added manually to active filters
    }

    this.onApplyGlobalFilters(this.globalFiltersModel);
  }

  sortByFullName(itemA: Colleague, itemB: Colleague) {
    return itemA.fullName.localeCompare(itemB.fullName);
  }

  sortByTeamName(itemA: Team, itemB: Team) {
    return itemA.name.localeCompare(itemB.name);
  }

  calculateDateRange(dateFilter) {
    const filterSelection = {
      label: 'Last month',
      start: this.dateRanges.month.startString,
      end: this.dateRanges.month.endString
    };
    if (!dateFilter) {
      return filterSelection;
    }
    switch (dateFilter.range.gte) {
      case '-1 day':
        filterSelection.label = 'Yesterday';
        break;
      case '-7 days':
        filterSelection.label = 'Last week';
        break;
      case '-30 days':
        filterSelection.label = 'Last month';
        break;
      case '-3 months':
        filterSelection.label = 'Last quarter';
        break;
      case '-1 year':
        filterSelection.label = 'Last year';
        break;
      default:
        filterSelection.label = `Listening period: ${format(
          dateFilter.range.gte,
          'DD/MM/YY HH:mm'
        )} - ${format(dateFilter.range.lte, 'DD/MM/YY HH:mm')}`;
        break;
    }
    filterSelection.start = dateFilter.range.gte;
    filterSelection.end = dateFilter.range.lte;
    return filterSelection;
  }

  onKeywordFilter(search) {
    // console.log(search);
    for (const filter of this.activeFilters) {
      if (filter.key !== 'keyword') {
        continue;
      } else if (!search.tags.includes(filter.all)) {
        // remove keywords that aren't selected
        this.activeFilters.splice(
          this.activeFilters.findIndex(
            (activeFilter) => filter.all === activeFilter.all
          ),
          1
        );
      }
    }
    // TODO Call main apply filter function instead and update model
    search.tags.map((tag) => {
      if (
        !this.activeFilters.find((filter) => filter.label === `Keyword: ${tag}`)
      ) {
        this.activeFilters.push({
          key: 'keyword',
          label: `Keyword: ${tag}`,
          field: 'String',
          all: tag,
          [search.condition]: tag
        });
        // this.globalFiltersModel.keyword.filterSelections.push({label: `Keyword: ${tag}`,value: tag});
      }
    });

    this.activeFilters = Object.assign([], this.activeFilters);

    // console.log('this.activeFilters: ', this.activeFilters);
  }

  onDateChange(range) {
    // TODO Call main apply filter function instead
    this.activeFilters = this.activeFilters.filter(
      (filter) => filter.field !== FieldName.CreatedAt
    );
    this.activeFilters.push({
      key: 'listeningPeriod',
      label: this.dateRanges[range].label,
      field: FieldName.CreatedAt,
      range: {
        gte: this.dateRanges[range].start,
        lte: this.dateRanges[range].end
      }
    });

    this.globalFiltersModel.listeningPeriod.dateFilterSelections = {
      label: this.dateRanges[range].label,
      start: this.dateRanges[range].startString,
      end: this.dateRanges[range].endString
    };
  }

  onApplyGlobalFiltersPartial(partialFilter) {
    console.log('on Apply Global Filters Partial:', partialFilter);

    const filters = Object.assign({}, this.globalFiltersModel);

    partialFilter.map((filter) => {
      const chosenFilter: any = Object.values(filters).find(
        (f: any) => f.label === filter.field
      );

      switch (filter.field) {
        case FieldName.CreatedAt:
          filters.listeningPeriod.filterSelections = {
            label: `Listening period: ${format(
              filter.range.gte,
              'DD/MM/YY HH:mm'
            )} - ${format(filter.range.lte, 'DD/MM/YY HH:mm')}`,
            start: new Date(filter.range.gte),
            end: new Date(filter.range.lte)
          };
          break;
        case 'Author Followers':
          chosenFilter.filterSelections = {
            label: filter.range.gte
              ? filter.range.lte
                ? `More than ${filter.range.gte} and Less than ${filter.range.lte}`
                : `More than ${filter.range.gte}`
              : `Less than ${filter.range.lte}`,
            value: true
          };
          break;
        case 'Channel':
          chosenFilter.filterSelections = [
            {
              label: filter.in[0],
              value: true
            }
          ];
          chosenFilter.options[filter.in[0]] = true;
          break;
        case 'Gender':
          chosenFilter.filterSelections = [
            {
              label: filter.eq,
              value: true,
              constant:
                filter.eq === 'org'
                  ? 'Organisation'
                  : filter.eq.charAt(0).toUpperCase() + filter.eq.slice(1)
            }
          ];
          chosenFilter.options[filter.eq] = true;
          break;
        case 'Sentiment':
          const sentimentObj = Object.values(sentiments).find(
            (sentiment) => sentiment.numericKey === filter.eq
          );
          chosenFilter.filterSelections = [
            {
              label: sentimentObj.key2,
              value: true,
              constant: sentimentObj
            }
          ];
          chosenFilter.options[sentimentObj.key2] = true;
          break;
        case 'Emotion':
          chosenFilter.filterSelections = [
            {
              label: emotions[filter.in[0]].key,
              value: true,
              constant: emotions[filter.in[0]].label
            }
          ];
          chosenFilter.options[filter.in[0]] = true;
          break;
        case 'Age':
          chosenFilter.filterSelections = [
            {
              label: Object.values(ageOptions).find(
                (option) => parseInt(option.id, 10) === filter.eq
              ).label,
              id: Object.values(ageOptions).find(
                (option) => parseInt(option.id, 10) === filter.eq
              ).id
            }
          ];
          break;
        case 'Language':
          chosenFilter.filterSelections = [
            {
              label: LANGUAGES[filter.in[0]],
              id: filter.in[0]
            }
          ];
          break;
        case 'Interests':
          chosenFilter.filterSelections = [
            {
              label: filter.in[0],
              id: filter.in[0]
            }
          ];
          break;
        case 'Industries':
          chosenFilter.filterSelections = [
            {
              label: filter.in[0],
              id: filter.in[0]
            }
          ];
          break;
        case 'Bio Keywords':
          chosenFilter.filterSelections = [
            {
              label: filter.in[0],
              id: filter.in[0]
            }
          ];
          break;
        case 'Country':
          chosenFilter.filterSelections = [
            {
              label: filter.eq,
              id: filter.eq
            }
          ];
          break;
        case 'City':
          chosenFilter.filterSelections = [
            {
              label: filter.eq,
              id: filter.eq
            }
          ];
          break;
        default:
          console.error('filter type not supported');
      }
    });

    this.onApplyGlobalFilters(filters);
  }

  /**
   * Converts filter model into API consumable filter array.
   * Should be refactored to be reused by `onApplyWidgetFilters` from `custom-widget.component`
   */
  async onApplyGlobalFilters(filters: InsightsFilter) {
    console.log('APPLIED FILTERS', filters);
    // Converts filter model into API consumable filter array
    this.globalFiltersModel = filters;
    const activeFilters = await this.insightsFiltersService.getOnlyActiveFilters(
      filters
    );

    const keywordFilters = this.activeFilters.filter(
      (filter) => filter.key === 'keyword'
    );

    this.activeFilters = activeFilters;

    // keywords come from report action bar only currently so need to be seperated from existing filter model so they're not overwritten.
    this.activeFilters = this.activeFilters.concat(keywordFilters);
    this.showGlobalFilters = false;
  }

  onRemoveGlobalFilters(filters: Filter[]) {
    // Updates filter model to match filters removed from API filter array
    filters.map((filter) => {
      if (filter.key === 'listeningPeriod') {
        // NOTE default to last month so date is never empty
        this.globalFiltersModel[filter.key].dateFilterSelections = {
          label: this.dateRanges.month.label,
          start: this.dateRanges.month.startString,
          end: this.dateRanges.month.endString
        };
      }
      if (filter.key === 'location') {
        const newFilterSelections = this.globalFiltersModel[
          filter.key
        ].filterSelections.filter((f) => f.label !== filter.field);
        this.globalFiltersModel[
          filter.key
        ].filterSelections = newFilterSelections;
        this.globalFiltersModel[filter.key].options[filter.field] = undefined;
      } else {
        const key = filter.all as any;
        this.globalFiltersModel[filter.key].filterSelections = [];

        if (this.globalFiltersModel[filter.key].options) {
          this.globalFiltersModel[filter.key].options[key] = false;
        }
      }
    });
  }

  onActiveFiltersChange(activeFilters) {
    this.activeFilters = Object.assign([], activeFilters);
    this.updateGlobalFilterModel();
  }

  async updateGlobalFilterModel() {
    let filters = sanitizeFiltersForAPI([...this.activeFilters]);
    filters = dedupeAndMergeFiltersForAPI(filters);
    const account_ids = await this.insightsService.convertAccountFilters(
      filters
    );
    const search_stream_ids = await this.insightsService.convertSavedStreamFilters(
      filters
    );
    this.globalFiltersModel = Object.assign(
      {},
      await this.insightsFiltersService.generateInsightsFiltersModel(
        filters,
        account_ids,
        search_stream_ids
      )
    );
  }

  async openDeleteReportModal() {
    const shouldDelete = await this.popup.confirm({
      title: 'Delete custom report?',
      message: `You cannot undo this action`,
      okText: 'Delete report',
      windowClass: 'modal-rounded-corners',
      hideClose: true,
      iconClass: 'ssi ssi-delete-microcopy',
      backdrop: true
    });

    if (shouldDelete) {
      await this.insightsService.deleteReport(this.report.id);
      this.notification.open(
        `Your report ${this.report.name} has been deleted`,
        {
          class: 'ssi ssi-small-delete',
          color: '#F88C68'
        },
        2000
      );
      this.state.go('^.home');
    }
  }

  copyShareLinkToClipboard() {
    const temp = document.createElement('input');
    const text = window.location.href;

    document.body.appendChild(temp);
    temp.value = text;
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);

    this.notification.open(
      'Your Report link has been copied ready to share with your Orlo team now!',
      {
        class: 'ssi ssi-completed-notification',
        color: '#F0B427'
      },
      3000
    );
  }

  extractIdsFromFilters(filters, fieldType) {
    const accountFilters = filters.filter(
      ({ field }) => field && field === fieldType
    );
    return accountFilters.map(({ all }) => all);
  }

  async onSaveReport() {
    console.log('onSaveReport:', this.activeFilters);
    const modal = this.modal.open(SaveReportModalComponent, {
      windowClass: 'm-modal rounded-corners-15',
      centered: true
    });
    modal.componentInstance.reportTitle = this.report.name;
    modal.componentInstance.access = this.report.is_shared
      ? this.accessOptions.public
      : this.accessOptions.private;
    modal.componentInstance.mode =
      this.authUser.id === this.report.created_by ? 'edit' : 'create';

    const updatedReport = await modal.result;
    if (!updatedReport) {
      return;
    }

    this.report.name = updatedReport.title;
    this.report.is_shared =
      updatedReport.access.key === 'public' ? true : false;
    this.report.filters = sanitizeFiltersForAPI([...this.activeFilters]);
    this.report.filters = dedupeAndMergeFiltersForAPI(this.report.filters);
    this.report.account_ids = await this.insightsService.convertAccountFilters(
      this.report.filters
    );
    this.report.search_stream_ids = await this.insightsService.convertSavedStreamFilters(
      this.report.filters
    );
    this.report.filters = this.report.filters.filter(
      ({ field }) =>
        field && field !== 'Saved Streams' && field !== 'Inbox Accounts'
    );

    if (updatedReport.mode === 'edit') {
      this.insightsService.updateReport(
        this.report.id,
        this.report,
        this.workflowAccounts
      );
      this.notification.open(
        `Your report '${this.report.name}' has been updated, success!`,
        {
          class: 'ssi ssi-completed-notification',
          color: '#B2C614'
        },
        1000
      );
    } else {
      const newReport = {
        name: this.report.name,
        filters: this.report.filters,
        is_shared: this.report.is_shared,
        widgets: this.report.widgets,
        account_ids: this.report.account_ids,
        search_stream_ids: this.report.search_stream_ids
      };
      this.insightsService.createReport(newReport, this.workflowAccounts);

      this.notification.open(
        `Your report '${this.report.name}' has been created, success!`,
        {
          class: 'ssi ssi-completed-notification',
          color: '#B2C614'
        },
        1000
      );
    }
  }

  goToCreateReport(): void {
    this.state.go('auth.insights.reports.create', {
      reportIdToCopy: this.report.id
    });
  }

  goToAddWidget() {
    this.state.get(
      'auth.insights.reports.edit'
    ).data.shouldOpenAddWidget = true;

    this.state.go('auth.insights.reports.edit', {
      id: this.report.id,
      reportName: this.report.name
    });
  }

  goToEditReport() {
    this.state.go('auth.insights.reports.edit', {
      id: this.report.id,
      reportName: this.report.name
    });
  }

  onReportViewChange(mode: 'graph' | 'comment'): void {
    this.reportView = mode;
    this.state.go('.', { mode }, { notify: false });
  }

  totalGridHeight(gridDimensions: { height: number; width: number }) {
    this.gridDimensions = gridDimensions;
  }

  areWidgetsLoaded(): boolean {
    return (
      this.widgetsGridRef &&
      Array.isArray(this.widgetsGridRef.loaders) &&
      this.widgetsGridRef.loaders.every((loaded) => loaded === true)
    );
  }

  onExportReport() {
    let appliedFilters = sanitizeFiltersForAPI([...this.activeFilters]);
    appliedFilters = dedupeAndMergeFiltersForAPI(appliedFilters);
    appliedFilters = JSON.stringify(appliedFilters);

    this.browserlessPDFExportService.downloadPDF(
      window.location.href,
      'insights',
      `Orlo-${this.report.name}-Report`,
      appliedFilters,
      230 + this.gridDimensions.height,
      1124
    );
  }
}
