import {
  Directive,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Directive({
  selector: '[ssiOffClick]'
})
export class OffClickDirective {
  @Input() offClickIgnore: HTMLElement;

  // tslint:disable-next-line
  @Output('ssiOffClick') offClick = new EventEmitter();

  constructor(private elm: ElementRef) {}

  @HostListener('document:mouseup', ['$event'])
  onClick(event: MouseEvent) {
    if (
      !this.elm.nativeElement.contains(event.target) &&
      (!this.offClickIgnore ||
        !this.offClickIgnore.contains(event.target as Node))
    ) {
      this.offClick.next();
    }
  }
}
