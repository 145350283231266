import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlatpickrModule } from 'angularx-flatpickr';

import { ReportBuildFromComponent } from './report-build-from.component';

import { VarModule } from '../../../../../common/directives/var/var.module';
import { TemplateSelectorModule } from '../../../../../common/directives/template-selector/template-selector.module';

import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../common/components/social-network-icon/social-network-icon.module';
import { Checkbox2Module } from '../../../../../common/components/checkbox-2/checkbox-2.module';
import { ToggleSwitchModule } from '../../../../../common/components/toggle-switch/toggle-switch.module';

@NgModule({
  declarations: [ReportBuildFromComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    FlatpickrModule.forRoot(),
    VarModule,
    TemplateSelectorModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    Checkbox2Module,
    ToggleSwitchModule
  ],
  exports: [ReportBuildFromComponent]
})
export class ReportBuildFromModule {}
