import { Component } from '@angular/core';
import './analytics.component.scss';

@Component({
  selector: 'ssi-analytics',
  templateUrl: './analytics.component.html',
  styles: []
})
export class AnalyticsComponent {
  constructor() {}
}
