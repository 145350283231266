import { Injectable } from '@angular/core';
import { AccountModel } from '@ui-resources-angular';
import { url } from 'inspector';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../../../common/services/api';

export interface TopicReportMetrics {
  topics: {
    id: string;
    name: string;
    count: number;
  }[];
  urls: {
    url: string;
    count: number;
  }[];
  rows: {
    query: string;
    topic: string;
    confidence: number;
    feedback: string;
    handover: string;
    count: number;
  }[];
}

export interface FilterPeriod {
  start: string;
  end: string;
}

@Injectable()
export class TopicReportService {
  constructor(private api: ApiService) {}

  async getTopicReport(reqParams) {
    // POST /chatbot/topicSuggestionReport?with[]=topics&with[]=urls&with[]=rows
    // https://github.com/orlo/orlo/blob/master/chatbot/topic-report.md
    const endpoint = `${this.api.url}/chatbot/topicSuggestionReport?with[]=topics&with[]=urls&with[]=rows`;
    const reportParams = reqParams;

    return this.api
      .post(endpoint, reportParams)
      .pipe(
        map((response: TopicReportMetrics) => {
          const formattedData = {
            ...response,
            urls: response.urls.map((u) => {
              return {
                id: u.url,
                name: u.url,
                count: u.count
              };
            })
          };
          return formattedData;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }
}
