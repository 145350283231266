import './widget-marketing-posts-config.component.scss';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AccountModel } from '@ui-resources-angular';
import { widgetTypes } from '../../../constants';
import {
  KeyValueObject,
  mapToIterable
} from '../../../../../../../common/utils';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';

export interface Option {
  key: string;
  label: string;
  icon?: string;
  query?: KeyValueObject;
}

export interface MarketingPostsConfigQuery {
  validated: string | null;
}

@Component({
  selector: 'ssi-widget-marketing-posts-config',
  templateUrl: './widget-marketing-posts-config.component.html',
  styles: []
})
export class WidgetMarketingPostsConfigComponent implements OnInit {
  @Input() widget: DashboardWidget;
  @Output() selectedPresetChange = new EventEmitter<Option>();

  widgetTypes = widgetTypes;
  widgetTitleMaxLength = 60;

  presets: KeyValueObject<Option> = this.getPresets();
  presetsIterable: Option[] = mapToIterable(this.presets);
  selectedPreset: Option = this.presets.all;

  constructor(
    protected translate: TranslateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  async ngOnInit() {
    if (!this.widget.config) {
      // new widget - set default config
      this.widget.config = {};
    }

    this.parseApiSearchParams();
  }

  parseApiSearchParams(): void {
    if (!this.widget.apiSearchParams) {
      // new widget - set default search params
      this.widget.apiSearchParams = {
        // ...this.presets.all.query
      };
    } else {
      // edit mode - re-populate fields
      if (
        this.widget.apiSearchParams.validated ===
        this.presets.unvalidated.query.validated
      ) {
        this.selectedPreset = this.presets.unvalidated;
      }
    }
  }

  /* accessed outside of this component - from the parent */
  public getConfig() {
    return this.widget.config;
  }

  /* accessed outside of this component - from the parent */
  public getQuery(): MarketingPostsConfigQuery {
    return {
      ...this.selectedPreset.query
    } as MarketingPostsConfigQuery;
  }

  onSelectedPresetChange(preset: Option): void {
    this.selectedPresetChange.emit(preset);
  }

  getPresets(): KeyValueObject<Option> {
    return {
      all: {
        key: 'all',
        label: 'All posts',
        query: { validated: null }
      },
      unvalidated: {
        key: 'unvalidated',
        label: 'Unvalidated posts',
        query: { validated: '0' }
      }
    };
  }
}
