import './export-messages.component.scss';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportMessagesComponent } from './export-messages.component';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrloComponentsModule } from 'orlo-components';
import { SanitizedHtmlPipe } from '../../pipes/sanitized/sanitized.pipe';
import { SanitizedHtmlModule } from '../../pipes/sanitized/sanitized.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    FormsModule,
    ReactiveFormsModule,
    OrloComponentsModule,
    SanitizedHtmlModule
  ],
  declarations: [ExportMessagesComponent],
  exports: [ExportMessagesComponent],
  providers: [SanitizedHtmlPipe]
})
export class ExportMessagesModule {}
