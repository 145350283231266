import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import './rounded-box-check.component.scss';

@Component({
  selector: 'ssi-rounded-box-check',
  templateUrl: './rounded-box-check.component.html'
})
export class RoundedBoxCheckComponent implements OnInit {
  @Input() text: string;
  @Input() selected: boolean = false;
  @Input() leadingIcon?: string = 'ssi ssi-tick-small';

  constructor() {}

  ngOnInit() {}
}
