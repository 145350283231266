import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GraphComponent } from './graph.component';
import { HighchartModule } from '../highchart/highchart.module';

@NgModule({
  imports: [CommonModule, TranslateModule, HighchartModule],
  declarations: [GraphComponent],
  exports: [GraphComponent]
})
export class GraphModule {}
