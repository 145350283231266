import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetKpiComponent } from './widget-kpi.component';
import { BigNumberModule } from '../../../../../../common/components/big-number/big-number.module';

@NgModule({
  declarations: [WidgetKpiComponent],
  imports: [CommonModule, BigNumberModule],
  exports: [WidgetKpiComponent]
})
export class WidgetKpiModule {}
