import './modal-list.component.scss';
import { Component, Input } from '@angular/core';
import { Influencer } from '../../../../../../common/components/influencers-list/influencers-list.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-modal-list',
  templateUrl: './modal-list.component.html',
  styles: []
})
export class ModalListComponent {
  @Input() title: string;
  @Input() authorsByFollowers: Influencer[];
  @Input() authorsByEngagements: Influencer[];
  @Input() drillDownParams;

  constructor(public modalInstance: NgbActiveModal) {}
}
