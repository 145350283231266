import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { PolicyComponent } from './policy.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'entry.policy',
          url: '/policy',
          component: PolicyComponent,
          resolve: PolicyComponent.resolve
        }
      ]
    }),
    TranslateModule
  ],
  declarations: [PolicyComponent],
  exports: [PolicyComponent]
})
export class PolicyModule {}
