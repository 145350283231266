<div class="post-box-content-body">
    <div class="post-box-content-status">
        <div [ngClass]="{'post-box-selected': isSelected()}"
             (click)="!selectMode && addToSelected.emit()">
            <i *ngIf="isSelected()"
               class="ssi ssi-tick post-box-selected-icon"></i>
            <img class="img-circle"
                 [src]="(validation.account || validation.outbox?.account)?.picture"
                 ssiFallbackImage="avatar">
        </div>
    </div>
    <div class="post-box-content">
        <div class="post-box-content-meta">
            <a *ngIf="!validation.outbox"
               href="javascript:;"
               [ssiTriggerPublish]="{edit: {post: validation}}">Edit post</a>
            <span *ngIf="!validation.outbox"
                  class="post-box-content-divide"
                  [innerHTML]="pipe"></span>
            <i class="text-primary ssi ssi-fw ssi-user-minimal"></i>
            <span>{{user.first_name}}&nbsp;{{user.last_name}}</span>
        </div>
        <p [innerHtml]="validation.outbox ? validation.outbox.text : validation.text | linkify: {mentions: mentions}"></p>
    </div>
</div>