import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeToDetailsDirective } from './time-to-details.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TimeToDetailsDirective],
  exports: [TimeToDetailsDirective]
})
export class TimeToDetailsModule {}
