<div class="inbox-activity-header">

  <div class="inbox-activity-header-left inbox-activity-header-back" (click)="onClose.emit()">
    <i class="fa fa-chevron-left"></i> <span translate="BACK_TO_MESSAGE"></span>
  </div>

  <div class="inbox-activity-header-right inbox-activity-header-crm">
    <div class="inbox-activity-header-crm-inner">
      <button
        *ngIf="linkedSocialProfiles.length === 0"
        class="btn btn-default btn-outline btn-rounded"
        translate="VIEW_FULL_PROFILE"
        (click)="viewProfile({accountId: account.id, profileId: activityProfile.id})"
      ></button>
      <div
        *ngIf="linkedSocialProfiles.length > 0"
        class="btn-group"
        ngbDropdown
      >
        <button
          class="btn btn-default btn-outline btn-rounded"
          translate="VIEW_FULL_PROFILE"
          (click)="viewProfile({accountId: account.id, profileId: activityProfile.id})"
        >
        </button>
        <button
          type="button"
          class="btn btn-default btn-outline btn-rounded"
          ngbDropdownToggle
        >
          <span class="caret"></span>
        </button>
        <ul
          class="dropdown-menu dropdown-menu-right dropdown-menu-inbox"
          uib-dropdown-menu
        >
          <li *ngFor="let profile of linkedSocialProfiles">
            <a
              href="javascript:;"
              (click)="viewProfile({accountId: account.id, profileId: activityProfile.id})"
            >
              <i [ngClass]="profile.account.socialNetwork.icon.web"></i> {{ profile.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="verified-wrap">
        <img [src]="activityProfile.avatar">
        <i *ngIf="activityProfile.verified" class="verified ssi ssi-verified-icon"></i>
      </div>
    </div>
  </div>

</div>

<div class="inbox-activity-crm-body">
  <div class="inbox-activity-crm-body-inner">
    <label translate="KEY_METRICS"></label>
    <div class="inbox-activity-crm-section inbox-activity-crm-section-statistics">

      <div class="inbox-activity-crm-section-stat">
        <i class="fa fa-fw fa-diamond"></i> <span translate="SOCIAL_SCORE"></span> <span class="inbox-activity-crm-section-stat-value">{{ profile.socialEndorserScore }}%</span>
      </div>

      <div class="inbox-activity-crm-section-stat">
        <ssi-crm-profile-sentiment-bar
          [thin]="true"
          [profile]="profile"
        ></ssi-crm-profile-sentiment-bar>
      </div>

      <div class="inbox-activity-crm-section-stat">
        <i class="fa fa-fw" [ngClass]="account.socialNetwork.icon.web"></i> <span translate="INTERACTIONS"></span>
        <span
          class="inbox-activity-crm-section-stat-value"
        >{{profile.inbox_stats.total | bigNumber}}</span>
      </div>

    </div>

    <label translate="PROFILE_TAGS"></label>
    <div class="inbox-activity-crm-section">
      <ssi-crm-person-tags [crmPerson]="crmPerson"></ssi-crm-person-tags>
    </div>

    <label translate="PROFILE_NOTES"></label>
    <div class="inbox-activity-crm-section">
      <ssi-crm-person-notes [crmPerson]="crmPerson"></ssi-crm-person-notes>
    </div>

    <div *ngIf="externalIntegrations.linked.length + externalIntegrations.unlinked.length > 0">
      <label translate="CRMS"></label>

      <div class="inbox-activity-crm-integrations-container">
        <div
          class="inbox-activity-crm-integration active"
          (click)="showExternalCrmPerson.emit({integration: integration})"
          *ngFor="let integration of externalIntegrations.linked">
          {{ integration.integration.name }}
        </div>

        <div
          *ngFor="let integration of externalIntegrations.unlinked"
          class="inbox-activity-crm-integration"
          (click)="linkIntegration.emit({integration: integration})"
        >
          {{ integration.name }}
        </div>
      </div>
    </div>
  </div>
</div>
