import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ToggleSwitch2Component } from './toggle-switch-2.component';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, TooltipModule],
  declarations: [ToggleSwitch2Component],
  exports: [ToggleSwitch2Component]
})
export class ToggleSwitch2Module {}
