import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { Nl2brModule } from '../../pipes/nl2br/nl2br.module';
import { AuditEventComponent } from './audit-event.component';

@NgModule({
  imports: [CommonModule, TranslateModule, Nl2brModule],
  exports: [AuditEventComponent],
  declarations: [AuditEventComponent]
})
export class AuditEventModule {}
