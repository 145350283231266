<div class="report-header">
  <h1>{{report}}</h1>
  
  <div
    *ngIf="supportsKeyword"
    class="keyword-search-input"
    (click)="toggleSearch = !toggleSearch"
  >
    <input
      *ngIf="toggleSearch"
      id="keyword"
      type="text"
      [disabled]="false"
      (click)="$event.stopPropagation()"
      [(ngModel)]="keyword"
      (keypress)="keywordInput($event)"
      placeholder="Search keyword/hashtag..."
    >
    <i class="ssi ssi-correct-search"></i>
  </div>

  <div class="report-date-selector">
    <div class="control-selector"
         *ngIf="!toggleControls && canChangeDates"
         (click)="toggleControls = !toggleControls">
      <i class="ssi ssi-date-preset"></i>
    </div>
    <ssi-highchart-controls *ngIf="toggleControls"
                            (chartChangeDate)="reportChangeDate($event.timestamp)"
                            (chartChangeDateCustom)="reportChangeDateCustom()"
                            [now]="now"
                            [chartSearchDates]="reportSearchDates"
                            [chartDefaultStartDate]="reportDefaultStartDate"
                            [reportMode]="true"></ssi-highchart-controls>
  </div>
  <div class="report-name">
    <ng-container *ngIf="!dateRangesTemplate">
      <div class="report-date-range"
           (click)="onDateRangeClicked.emit()">{{reportStartDate | date:'d MMM yy'}} - {{reportEndDate | date:'d MMM yy'}}</div>
      <h2 *ngIf="name">{{name}}</h2>
    </ng-container>

    <ng-container *ngTemplateOutlet="dateRangesTemplate"></ng-container>
  </div>
</div>