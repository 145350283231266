import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InlineAddNoteComponent } from './inline-add-note.component';
import { AddNoteModule } from '../../add-note/add-note.module';

@NgModule({
  imports: [CommonModule, TranslateModule, AddNoteModule],
  declarations: [InlineAddNoteComponent],
  exports: [InlineAddNoteComponent]
})
export class InlineAddNoteModule {}
