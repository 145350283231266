import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { authUser } from '../../../common-resolves';
import { User } from '@ui-resources-angular';
import { CompanyService } from '../../../../common/services/company/company.service';

export async function featureFlagsFn(company: CompanyService) {
  const companyConfig = await company.getConfig();
  return companyConfig.feature_flags;
}

@Component({
  selector: 'ssi-account-settings',
  templateUrl: './account-settings.component.html',
  styles: []
})
export class AccountSettingsComponent {
  static resolve = [
    {
      token: 'featureFlags',
      resolveFn: featureFlagsFn,
      deps: [CompanyService]
    },
    authUser
  ];

  @Input() authUser: User;
  @Input() featureFlags;

  accountSettings = [
    {
      link: 'auth.manageAccounts',
      header: 'Manage Social Accounts',
      text: 'Add new or delete existing accounts from Orlo',
      icon: 'ssi ssi-managesocialaccounts',
      permission: 'administer_accounts',
      flag: null
    },
    {
      link: 'auth.settings.accountsettings.livechatwidgets',
      header: 'Manage Live Chat Widgets',
      text: 'Amend your brand colours & chat view for your customers',
      icon: 'ssi ssi-livechat',
      permission: 'administer_accounts',
      flag: null
    },
    {
      link: 'auth.reAuthAccount',
      header: 'Re-Authenticate Accounts',
      text: 'Stay up to speed with social network updates',
      icon: 'ssi ssi-reauthaccounts',
      permission: 'administer_accounts',
      flag: null
    },
    {
      link: 'auth.blockingWords',
      header: 'Profanity Filter',
      text: 'Safeguard your company accounts from bad language',
      icon: 'ssi ssi-blockedwords',
      permission: 'administer_accounts',
      flag: null
    },
    {
      link: 'auth.manageSocialPolicy',
      header: 'Social Policy',
      text: `Setup your company's social policy for users to agree to`,
      icon: 'ssi ssi-socialpolicy',
      permission: 'administer_accounts',
      flag: null
    }
  ];

  constructor(public location: Location) {}

  checkPermission(permission) {
    return permission ? this.authUser.hasCompanyPermission(permission) : true;
  }

  checkFlag(flag) {
    return flag ? this.featureFlags.includes(flag) : true;
  }
}
