<ul class="pinned-activities-container">
  <li *ngFor="let activity of pinnedActivities | slice: 0:maxVisibleActivities"
      [ngClass]="{'active': activity.id === activeActivity?.id}"
      [ssiTooltip]
      [tooltipTemplate]="tooltipTemplate"
      [tooltipTemplateContext]="{activity: activity}"
      [tooltipPlacement]="'bottom'"
      [tooltipWindowClass]="'tooltip-chunky-basic'">
    <div class="author-info"
         (click)="open(activity)">
      <img [imageSrc]="activity.author.avatar"
           class="avatar"
           ssiFallbackImage="avatar">
      <span class="name">{{ activity.author.name }}</span>
    </div>
    <button class="ssi unpin-button ssi-times"
            (click)="unpin(activity)"></button>
  </li>
  <li (click)="toggleAdditional()"
      class="additional-trigger"
      (ssiOffClick)="showAdditional = false"
      *ngIf="pinnedActivities.length > maxVisibleActivities">
    <span *ngIf="additionalActivities === 1"
          translate="__VALUE__MORE_PIN_ELLIPSIS"
          [translateParams]="{value: additionalActivities}"></span>
    <span *ngIf="additionalActivities !== 1"
          translate="__VALUE__MORE_PIN_ELLIPSIS"
          [translateParams]="{value: additionalActivities}"></span>
    <i [ngClass]="showAdditional ? 'ssi ssi-arrow-up' : 'ssi ssi-arrow-down-correct'"></i>

    <ul class="additional-activities"
        *ngIf="showAdditional">
      <li [ngClass]="{'active': activity.id === activeActivity?.id}"
          class="additional-activity"
          *ngFor="let activity of pinnedActivities | slice: maxVisibleActivities:pinnedActivities.length"
          [ssiTooltip]
          [tooltipTemplate]="tooltipTemplate"
          [tooltipTemplateContext]="{activity: activity}"
          [tooltipPlacement]="'bottom'"
          [tooltipWindowClass]="'tooltip-chunky-basic'"
          [tooltipAppendToBody]="true">
        <div class="author-info"
             (click)="open(activity)">
          <img [imageSrc]="activity.author.avatar"
               class="avatar"
               ssiFallbackImage="avatar">
          <span class="name">{{ activity.author.name }}</span>
        </div>
        <button class="ssi unpin-button ssi-times"
                (click)="unpin(activity)"></button>
      </li>
    </ul>
  </li>
</ul>


<ng-template #tooltipTemplate
             let-activity="activity">
  <div class="pinned-activities-tooltip-content">
    <div class="main-info">
      <span class="author-name">{{activity.author.name}}</span>
      <span>|</span>
      <span>{{activity.interaction.created_at | date:'HH:mm'}}</span>
      <span>|</span>
      <span>{{activity.interaction.created_at | date:'d MMM y'}}</span>
    </div>
    <div class="message-preview"
         [ssiEmojiText]="shorten(activity.interaction.content)"></div>
  </div>

  <div data-popper-arrow
       class="tooltip-arrow"></div>
</ng-template>