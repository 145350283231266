<div
  class="insights-widget"
  *ngIf="themesData && themesData.length > 0"
>
  <div
    class="theme"
    [ngClass]="widgetName === 'positive_themes' ? 'positive' : 'negative'"
    *ngFor="let theme of themesData"
    (click)="openDrilldownModal(theme)"
  >
    <span>"{{theme.openai_theme}}"</span><ssi-big-number [number]="theme.size"></ssi-big-number>
  </div>
</div>

<div
  *ngIf="!themesData || themesData && themesData.length === 0"
  class="no-results-state"
>
  <i class="ssi ssi-no-results-option"></i>
  <h4>No data to display</h4>
  <p>This may be due to filters applied or too many varying themes</p>
</div>