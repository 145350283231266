import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { InboxActivityExternalCrmPersonComponent } from './inbox-activity-external-crm-person.component';
import { InboxCrmExternalIntegrationPersonModule } from '../inbox-crm-sidebar/inbox-crm-external-integration-person/inbox-crm-external-integration-person.module';

@NgModule({
  imports: [
    CommonModule,
    NgbPopoverModule,
    TranslateModule,
    InboxCrmExternalIntegrationPersonModule
  ],
  declarations: [InboxActivityExternalCrmPersonComponent],
  entryComponents: [InboxActivityExternalCrmPersonComponent],
  exports: [InboxActivityExternalCrmPersonComponent]
})
export class InboxActivityExternalCrmPersonModule {}
