import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatBotModalComponent } from './chat-bot-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TranslateModule, FormsModule],
  declarations: [ChatBotModalComponent],
  entryComponents: [ChatBotModalComponent]
})
export class ChatBotModalModule {}
