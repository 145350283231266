<div class="tags-input">
  <div
    class="tags-input-input-wrap"
    [ngClass]="{'tags-input-input-wrap-focused': inputFocused || tagsInput && tagsInput.length}"
  >
    <div
      class="tags-input-input-container custom-scrollbar"
      [ngClass]="{'tags-input-input-container-noPadding': !!tags.length}"
    >
      <ul class="tags-input-tags" [ngClass]="{'tags-input-tags-noPadding': !tags.length}">
        <li *ngFor="let tag of tags" class="tags-input-tag">
          <span>{{tag}}</span>
          <button class="tags-input-tag-action" (click)="deleteTag(tag)">
            <i class="ssi ssi-delete-close"></i>
          </button>
        </li>
      </ul>
      <input
        id="tagsInput"
        class="tags-input-input"
        type="text"
        [disabled]="disabled"
        [placeholder]="placeholder ? placeholder : 'Add tags...'"
        [(ngModel)]="tagsInput"
        (focus)="inputFocused = true"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      >
      <span
        *ngIf="showSubmit"
        class="search-submit"
      >
        <button
          [disabled]="disabled"
          (click)="submitSearch($event)"
        ><i class="ssi ssi-correct-search"></i><span>{{submitText}}</span></button>
      </span>
    </div>
  </div>
</div>
