import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ActivateComponent } from './activate.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PasswordInputModule } from '../../../common/components/password-input/password-input.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'entry.active',
          url: '/activate/:companyUUID/:signature',
          component: ActivateComponent,
          resolve: ActivateComponent.resolve
        }
      ]
    }),
    TranslateModule,
    FormsModule,
    PasswordInputModule
  ],
  declarations: [ActivateComponent],
  exports: [ActivateComponent]
})
export class ActivateModule {}
