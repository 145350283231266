<div class="ad-stats-build-container">
  <div class="head">
    <h3>Advertising Report</h3>
    <h2>Build your report</h2>
  </div>

  <div class="body">
    <div class="body-inner">
      <form
        #form="ngForm"
        (submit)="submit(form)"
      >
        <div class="form-row">
          <div class="form-field half">
            <label translate="START_DATE"></label>
            <div
              class="input-wrapper"
              #startDateContainer
            >
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="startDate"
                [(ngModel)]="startDate"
                (ngModelChange)="onStartDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="false"
                [defaultHour]="00"
                [defaultMinute]="00"
                [defaultSeconds]="00"
                dateFormat="Y-m-d"
                altFormat="M J Y"
                [maxDate]="endDate"
                [time24hr]="true"
                [appendTo]="startDateContainer.nativeElement"
                placeholder="Select..."
                [required]="true"
                (flatpickrOpen)="onFlatpickrOpen()"
              >
            </div>
          </div>
          <div class="form-field half">
            <label translate="END_DATE"></label>
            <div class="input-wrapper" #endDateContainer>
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="endDate"
                [(ngModel)]="endDate"
                (ngModelChange)="onEndDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="false"
                [defaultHour]="23"
                [defaultMinute]="59"
                [defaultSeconds]="59"
                dateFormat="Y-m-d"
                altFormat="M J Y"
                [minDate]="startDate"
                [maxDate]="today"
                [time24hr]="true"
                [appendTo]="endDateContainer.nativeElement"
                placeholder="Select..."
                [required]="true"
                (flatpickrOpen)="onFlatpickrOpen()"
              >
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
            <div class="input-wrapper">
              <div
                *ngFor="let range of dateRangesIterable"
                class="inline-select-item"
                [class.selected]="range.id === selectedDateRange?.id"
                (click)="selectPresetDateRange(range)"
              >
                <i
                  *ngIf="range.id === selectedDateRange?.id"
                  class="ssi ssi-date-preset"
                ></i>
                <span>{{range.label}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row extra-margin-top">
          <div class="form-field">
            <label translate="COMPARE_TO"></label>
            <div class="input-wrapper">
              <div
                *ngFor="let option of compareToOptionsIterable"
                class="inline-select-item"
                [class.selected]="option.id === selectedCompareToOption.id"
                (click)="selectCompareToOption(option)"
              >
                <i *ngIf="option.id === selectedCompareToOption.id"
                  class="ssi ssi-tick-small"></i>
                <span>{{option.label}}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="form-row"
          *ngIf="selectedCompareToOption === compareToOptions.custom"
        >
          <div class="form-field half">
            <label>Previous start date</label>
            <div class="input-wrapper" #previousStartDateContainer>
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="previousStartDate"
                [ngModel]="previousStartDate"
                (ngModelChange)="onPreviousStartDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="false"
                [defaultHour]="00"
                [defaultMinute]="00"
                [defaultSeconds]="00"
                dateFormat="Y-m-d"
                altFormat="M J Y"
                [maxDate]="previousEndDate || startDate"
                [time24hr]="true"
                [appendTo]="previousStartDateContainer.nativeElement"
                placeholder="Select..."
                [required]="selectedCompareToOption === compareToOptions.custom"
              >
            </div>
          </div>
          <div class="form-field half">
            <label>Previous end date</label>
            <div class="input-wrapper" #previousEndDateContainer>
              <input
                mwlFlatpickr
                class="date-picker-input"
                type="text"
                name="previousEndDate"
                [ngModel]="previousEndDate"
                (ngModelChange)="onPreviousEndDatePicked($event)"
                [altInput]="true"
                [convertModelValue]="true"
                [enableTime]="false"
                [defaultHour]="23"
                [defaultMinute]="59"
                [defaultSeconds]="59"
                dateFormat="Y-m-d"
                altFormat="M J Y"
                [minDate]="previousStartDate"
                [maxDate]="startDate"
                [time24hr]="true"
                [appendTo]="previousEndDateContainer.nativeElement"
                placeholder="Select..."
                [required]="selectedCompareToOption === compareToOptions.custom"
              >
            </div>
          </div>
        </div>

        <div class="form-row extra-margin-top">
          <div class="form-field">
            <label>
              Currency
              <i
                class="ssi ssi-information"
                ssiTooltip="You are only able to run one report in one type of currency at a time. Selecting a currency type will affect the type of Ad accounts you can select for your report."
                tooltipTitle="Currency Selection"
                [tooltipAppendToBody]="true"
                tooltipPlacement="top"
                tooltipTrailingIconClass="ssi ssi-information"
                [tooltipWindowClass]="'tooltip-chunky-big'"
              ></i>
            </label>
            <div class="input-wrapper">
              <div
                *ngFor="let option of currencyOptionsIterable"
                class="inline-select-item"
                [class.selected]="option.key === selectedCurrencyOption.key"
                (click)="currencySelect(option)"
              >
                <i
                  *ngIf="option.key === selectedCurrencyOption.key"
                  class="ssi ssi-tick-small"
                ></i>
                <span>{{option.label}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row extra-margin-top">
          <div class="form-field">
            <label>Ad Accounts included</label>
            <div class="input-wrapper">
              <ssi-dropdown-select-2
                name="accounts"
                class="accounts-selector"
                headButtonStyleVariant="analytics"
                size="lg"
                [required]="true"
                [multiple]="true"
                [filterable]="true"
                [filterPlaceholder]="'Select your Facebook ad accounts...'"
                [toggleAllLabel]="'Select all accounts'"
                [options]="accounts"
                [(ngModel)]="selectedAccounts"
                (ngModelChange)="onSelectedAccountsChange($event)"
              >
                <ng-template ssiTemplateSelector="headButtonLabel" let-expanded="expanded">
                  <div class="select-accounts-placeholder" *ngIf="!selectedAccounts.length">
                    <span>Select ad accounts...</span>
                  </div>
                  <div class="select-accounts-label" *ngIf="selectedAccounts.length">
                    <span>{{selectedAccounts.length}} ad accounts</span>
                    <ssi-social-network-icon
                      *ngFor="let accountTypeId of selectedAccountTypeIds"
                      [accountTypeId]="accountTypeId"
                      variant="fa"
                    ></ssi-social-network-icon>
                  </div>
                </ng-template>
      
                <ng-template ssiTemplateSelector="optionIcon" let-account="option">
                  <ssi-social-network-icon
                    [accountTypeId]="account.account_type_id"
                    variant="fa"
                  ></ssi-social-network-icon>
                </ng-template>
              </ssi-dropdown-select-2>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
            <div class="input-wrapper select-all-accounts">
              <ssi-checkbox-2
                name="selectAllAccountsChecked"
                label="Select all ad accounts"
                [(ngModel)]="selectAllAccountsChecked"
                (ngModelChange)="onSelectAllAccountsChange($event)"    
              ></ssi-checkbox-2>
            </div>
          </div>
        </div>
      
        <button
          class="btn submit-button"
          type="submit"
          [disabled]="form.invalid"
        >Build analytics</button>
      </form>
    </div>
  </div>
</div>