import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-outbox-post-submodal',
  templateUrl: './outbox-post-submodal.component.html'
})
export class OutboxPostSubmodalComponent {
  @Input() type: 'pause' | 'delete';
  @Output() setAdStatus = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  unpause() {
    this.setAdStatus.emit();
    this.activeModal.dismiss();
  }
}
