<div class="conversation-resolve-container">
  <ng-container *ngIf="!conversation.resolved">
    <ssi-dropdown-select-2 size="sm"
                           [options]="resolveOptionsIterable"
                           [yPlacement]="menuPlacementY"
                           [xPlacement]="menuPlacementX"
                           [(ngModel)]="selectedResolveOption"
                           (ngModelChange)="onResolveOptionChange($event)"
                           [disabled]="!activityThread?.thread.current.tags">
      <ng-template ssiTemplateSelector="headButton"
                   let-expanded="expanded"
                   let-toggleResolveOptions="toggle">

        <button *ngIf="!iconOnly"
                type="button"
                class="g-btn border-radius-100 resolve-btn"
                (click)="resolveClick()"
                [ssiTooltip]="tooltip"
                [tooltipWindowClass]="'tooltip-chunky'">
          <i class="ssi ssi-request-validation"></i>
          <span>{{canReply ? 'Reply + Resolve' : 'Resolve'}}</span>
        </button>

        <span *ngIf="iconOnly"
              (click)="resolveClick()">
          <i class="ssi ssi-request-validation resolve-icon-standalone"
             [class.resolved]="conversation.resolved"
             [ssiTooltip]="getTooltip()"
             [tooltipWindowClass]="'tooltip-chunky'"
             [tooltipAppendToBody]="true"
             tooltipPlacement="bottom"></i>
          <span *ngIf="iconOnlyLabel"
                class="resolve-label">Resolve conversation</span>
        </span>
      </ng-template>
    </ssi-dropdown-select-2>
  </ng-container>

  <ng-container *ngIf="conversation.resolved">
    <div class="resolved-description"
         *ngIf="!iconOnly">
      <i class="ssi ssi-request-validation"></i>
      {{tooltip}}
    </div>
    <i *ngIf="iconOnly"
       class="ssi ssi-request-validation resolve-icon-standalone"
       [class.resolved]="conversation.resolved"
       [ssiTooltip]="getTooltip()"
       [tooltipWindowClass]="'tooltip-chunky'"
       tooltipPlacement="bottom"></i>
  </ng-container>

  <!-- <ng-template #iconOnlyTemplate>
    <i class="ssi ssi-request-validation resolve-icon-standalone"
       [class.resolved]="conversation.resolved"
       [ssiTooltip]="tooltip"
       [tooltipWindowClass]="'tooltip-chunky'"
       tooltipPlacement="bottom"
       (click)="resolveClick()"></i>
  </ng-template> -->

</div>