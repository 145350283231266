<div class="widget-inbox-message-config-container">
  <div class="dwm-form">

    <div class="dwm-form-half">
      <!-- Widget Text Input -->
      <div class="dwm-body-row">
        <label class="dwm-label"
               for="widgetTitle">Widget Title (Optional)</label>
        <div class="dwm-body-row-input">
          <input class="dwm-input"
                 id="widgetTitle"
                 type="text"
                 placeholder="Type your widget title here..."
                 [(ngModel)]="widget.title">
          <span [ngClass]="{'dwm-body-row-input-meta-active':  widget.title.length}"
                class="dwm-body-row-input-meta">
            {{widgetTitleMaxLength - widget.title.length}}
          </span>
        </div>
      </div>

      <!-- Widget Dropdown Input -->
      <div class="dwm-body-row">
        <label class="dwm-label"
               for="sort-options">Sort by</label>
        <ssi-dropdown-select-2 name="sort-options"
                               class="dwm-dropdown-selector"
                               [required]="true"
                               [multiple]="false"
                               [toggleAllEnabled]="false"
                               [filterable]="false"
                               [placeholdersKeyword]="'sort'"
                               [options]="sortOptionsIterable"
                               [(ngModel)]="selectedSortOption"
                               (ngModelChange)="onSelectedSortOptionChange($event)"></ssi-dropdown-select-2>
      </div>
    </div>

    <div class="dwm-form-half">
      <!-- Widget Dropdown Input -->
      <div class="dwm-body-row">
        <label class="dwm-label"
               for="presets">Preset filters / Default filters</label>
        <ssi-dropdown-select-2 name="presets"
                               class="dwm-dropdown-selector"
                               [required]="true"
                               [multiple]="false"
                               [toggleAllEnabled]="false"
                               [filterable]="false"
                               [placeholdersKeyword]="'preset'"
                               [options]="presetsIterable"
                               [(ngModel)]="selectedPreset"
                               (ngModelChange)="onSelectedPresetChange($event)"></ssi-dropdown-select-2>
      </div>

      <!-- Widget Toggle Input -->
      <div class="dwm-body-row">
        <label class="dwm-label"
               for="live-chat-tally">Live Chat Tally</label>
        <div class="dwm-body-row-input">
          <ssi-toggle-switch-2 name="live-chat-tally"
                               class="dwm-input-toggle"
                               [class.toggled]="widget.config.liveChatEnabled"
                               [label]="'Live Chat Tally ' + (widget.config.liveChatEnabled ? 'on': 'off')"
                               [(ngModel)]="widget.config.liveChatEnabled"></ssi-toggle-switch-2>
        </div>
      </div>
    </div>
  </div>
</div>