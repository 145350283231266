import { Pipe, PipeTransform } from '@angular/core';
import twitterText from 'twitter-text';
import { OutboxPublisher, OutboxPublisherMention } from '@ui-resources-angular';
import { getChoiceIndex as getMentionIndex } from '../../../../../apps/angular/common/components/text-input-autocomplete';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  transform(
    value: string,
    annotations?: { mentions: OutboxPublisherMention[] }
  ): any {
    value = twitterText.autoLinkUrlsCustom(value, {
      targetBlank: true,
      suppressNoFollow: true
    });

    if (Array.isArray(annotations && annotations.mentions)) {
      value = this.linkMentions(value, annotations.mentions);
    } else {
      value = twitterText.autoLinkUsernamesOrLists(value, {
        usernameIncludeSymbol: true,
        targetBlank: true,
        suppressNoFollow: true
      });
    }

    value = twitterText.autoLinkHashtags(value, {
      targetBlank: true,
      suppressNoFollow: true
    });

    return value;
  }

  linkMentions(value: string, mentions: OutboxPublisherMention[]): string {
    mentions
      .sort((mA, mB) => mB.indices.start - mA.indices.start)
      .forEach((mention) => {
        const label = OutboxPublisher.getMentionLabel(mention.data);
        const labels = mentions.map((m) =>
          OutboxPublisher.getMentionLabel(m.data)
        );

        const index = getMentionIndex(value, label, labels);
        if (index === -1) {
          console.error(
            `Mention label (${label}) not found in the text: ${value}`
          );
          return;
        }

        const link = `<a href="${
          mention.data.url
        }" target="_blank">${label}</a>`;

        const partBefore = value.slice(0, index);
        const partAfter = value.slice(index + label.length);

        value = partBefore + link + partAfter;
      });

    return value;
  }
}
