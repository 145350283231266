import { Injectable } from '@angular/core';
import { Subject, interval } from 'rxjs';

import {
  ActivityModel,
  Conversation,
  Activity,
  User
} from '@ui-resources-angular';
import { Socket } from 'socket.io-client';
import { PageVisibilityService } from '../page-visibility/page-visibility.service';
import { appInjector } from '../../../app-injector';
import { ColleaguesService } from '../api';

export enum RealtimeSocketEvent {
  View = 'activity:view',
  Close = 'activity:close',
  Viewers = 'activity:viewers'
}

@Injectable({ providedIn: 'root' })
export class RealtimeInboxHelperService {
  newConversation = new Subject<Partial<Conversation>>();

  constructor() {}

  create(params: { socket: Socket; authUser: User }) {
    const socket = params.socket;
    const authUser = params.authUser;

    const activityModel = appInjector().get(ActivityModel);
    const pageVisibility = appInjector().get(PageVisibilityService);
    const colleaguesService = appInjector().get(ColleaguesService);

    const manager = {
      meta: {},
      viewHeartbeatEnabled: true,
      activeActivity: undefined,
      socket,
      setOpenActivity(activity: Activity) {
        if (activity) {
          if (typeof activity === 'string' && !activityModel.is(activity)) {
            activity = activityModel.get(activity);
          }
          this.activityClosed();
          this.emitActivityEvent(RealtimeSocketEvent.View, activity.id);
          this.activeActivity = activity;
        }
      },
      activityClosed() {
        this.meta = {};
        if (this.activeActivity) {
          this.emitActivityEvent(
            RealtimeSocketEvent.Close,
            this.activeActivity.id
          );
          this.activeActivity = null;
        }
      },
      emitActivityEvent(event: RealtimeSocketEvent, activityId: string) {
        if (activityId) {
          socket.emit(event, {
            activity: {
              id: activityId
            },
            user: {
              id: this.authUser.id,
              meta: this.meta
            }
          });
        }
      },
      onActivityViewersEvent(data: {
        activity: Partial<Activity>;
        heartbeat: { interval: number };
        users: Array<{ expiresAt: string; id: number; meta: any }>;
      }) {
        try {
          const activity = activityModel.get(data.activity.id);
          if (activity) {
            if (!Array.isArray(data.users)) {
              throw new Error(
                `value for 'realtime inbox helper data users' is not in the expected format.`
              );
            }

            activityModel.getAll().forEach((iActivity) => {
              delete iActivity['usersTypingNow'];
              delete iActivity['usersViewingNow'];
              delete iActivity['usersViewingNowNamesLabel'];
            });

            activity['usersViewingNow'] = data.users
              .map((user) => colleaguesService.store.find(user.id))
              .filter((user) => user && user.id !== this.authUser.id);

            activity['usersViewingNowNamesLabel'] = activity['usersViewingNow']
              .map((user) => user.fullName)
              .join(', ');

            for (const user of activity['usersViewingNow']) {
              const meta = data.users.find(
                (socketUser) => +socketUser.id === +user.id
              ).meta;

              if (meta && meta.typingReplies) {
                meta.typingReplies.forEach((activityId) => {
                  const typingActivity = activityModel.get(activityId);
                  if (typingActivity) {
                    typingActivity['usersTypingNow'] =
                      typingActivity['usersTypingNow'] || [];

                    typingActivity['usersTypingNow'].push(user);

                    typingActivity['usersTypingNowNamesLabel'] = typingActivity[
                      'usersTypingNow'
                    ]
                      .map((u) => u.fullName)
                      .join(', ');
                  }
                });
              }
            }
          }

          if (!this.heartbeatInterval) {
            this.heartbeatInterval = setInterval(() => {
              if (this.viewHeartbeatEnabled && this.activeActivity) {
                this.emitActivityEvent(
                  RealtimeSocketEvent.View,
                  this.activeActivity.id
                );
              }
            }, data.heartbeat.interval);
          }

          return true;
        } catch (error) {
          console.error(error);

          return false;
        }
      },
      destroy() {
        this.activityClosed();
        this.deregisterSocketListener();
        if (this.heartbeatInterval) {
          clearInterval(this.heartbeatInterval);
        }
        this.deregisterVisibilityListener();
      }
    };

    const wrapViewersEvent = (data) => manager.onActivityViewersEvent(data);

    Object.assign(manager, {
      authUser,
      deregisterSocketListener() {
        socket.removeListener(RealtimeSocketEvent.View, wrapViewersEvent);
      },
      deregisterVisibilityListener: pageVisibility.delayedHide({
        onHide() {
          manager.viewHeartbeatEnabled = false;
          if (manager.activeActivity) {
            manager.emitActivityEvent(
              RealtimeSocketEvent.Close,
              manager.activeActivity.id
            );
          }
        },
        onShow() {
          manager.viewHeartbeatEnabled = true;
          if (manager.activeActivity) {
            manager.emitActivityEvent(
              RealtimeSocketEvent.View,
              manager.activeActivity.id
            );
          }
        },
        delay: 60 * 1000
      })
    });

    manager.socket.on(RealtimeSocketEvent.Viewers, wrapViewersEvent);

    return manager;
  }
}
