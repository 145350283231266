import { isSameDay } from 'date-fns';

import strings from '../../constants/strings';
import { Agent as AgentInterface } from '../../interfaces/live-chat/agent';
import { Person } from './person';
import { timestamp_to_date } from '../../helpers/datetimes';

export class Agent extends Person implements AgentInterface {
  protected _data: AgentInterface;

  protected _dateOfLatestResolution: Date;
  protected _quantityOfChatsResolvedToday: number;

  public get dateOfLatestResolution(): Date {
    return this._dateOfLatestResolution;
  }

  public get userID(): string {
    return this._data.user ? this._data.user.id : undefined;
  }

  public get hasAnyWritePermission(): boolean {
    return !!this._data.hasAnyWritePermission;
  }

  public get isAtPushTarget(): boolean {
    return !!this._data.statistics.isAtPushTarget;
  }

  public get isAway(): boolean {
    return (
      !!this.hasNetworkState &&
      (this.networkStateValue === strings.away ||
        this.networkStateValue === strings.busy)
    );
  }

  public get isBusy(): boolean {
    return this.isAway;
  }

  public get lastFulfilledPushQuotaAt(): Date {
    return this._data.statistics.lastFulfilledPushQuotaAt || null;
  }

  public get quantityOfChatsResolvedToday(): number {
    return this._quantityOfChatsResolvedToday;
  }

  public get quantityOfChatsResolvedToDate(): number {
    return this._data.statistics
      ? this._data.statistics.quantityOfChatsResolvedToDate
      : 0;
  }

  public hasAnyPermissionForApplicationId(id: string): boolean {
    return (
      this.hasReadPermissionForApplicationID(id) ||
      this.hasWritePermissionForApplicationID(id)
    );
  }

  public hasReadPermissionForApplicationID(id: string): boolean {
    return (
      this._data.permissions &&
      this._data.permissions.read &&
      this._data.permissions.read[id]
    );
  }

  public hasWritePermissionForApplicationID(id: string): boolean {
    return (
      this._data.permissions &&
      this._data.permissions.write &&
      this._data.permissions.write[id]
    );
  }

  protected initialise() {
    super.initialise();

    this._dateOfLatestResolution = this._data.statistics
      ? timestamp_to_date(this._data.statistics.latestResolutionAt)
      : undefined;

    this._quantityOfChatsResolvedToday =
      !!this._data.statistics &&
      !!this._data.statistics.quantityOfChatsResolvedToday &&
      isSameDay(this.dateOfLatestResolution, new Date())
        ? this._data.statistics.quantityOfChatsResolvedToday
        : 0;

    return this;
  }
}
