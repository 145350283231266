import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomateModalComponent } from './automate-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrloComponentsModule } from 'orlo-components';
import { Checkbox2Module } from '../checkbox-2/checkbox-2.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OrloComponentsModule,
    Checkbox2Module
  ],
  declarations: [AutomateModalComponent],
  exports: [AutomateModalComponent],
  providers: [],
  entryComponents: [AutomateModalComponent]
})
export class AutomateModalModule {}
