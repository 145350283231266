import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-new-version-modal',
  templateUrl: './new-version-modal.component.html'
})
export class NewVersionModalComponent {
  constructor(public modalInstance: NgbActiveModal) {}
}
