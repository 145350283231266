<div
  class="time-picker-wrap"
  [class.disabled]="disabled"
>
  <div class="time-container" [ngClass]="{'push-right': fullWidth}">
    <label for="time">
      Schedule Time
      <button
        *ngIf="! fullWidth"
        class="schedule-action"
        cdkOverlayOrigin
        #timesMenuTrigger="cdkOverlayOrigin"
        (click)="triggerTimesMenu($event, timesMenuTrigger)"
      >
        <i class="ssi ssi-small-schedule"></i>
      </button>

      <ng-template
        #timesMenu="cdkConnectedOverlay"
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="triggerOrigin"
        [cdkConnectedOverlayOpen]="showTimesMenu"
        [cdkConnectedOverlayFlexibleDimensions]="false"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayPush]="true"
        (backdropClick)="showTimesMenu = false"
      >
        <div class="time-menu-container">
          <div class="time-menu-header">
            <i class="ssi ssi-information-badge"></i>
            <p>Orlo Smart Schedules</p>
          </div>
          <div class="time-menu-body">
            <ng-container *ngIf="!suggestedTimes.length">
              <p>We don’t have enough data yet to suggest optimal times - try posting out more for this day!</p>
            </ng-container>
            <ng-container *ngIf="suggestedTimes.length">
              <p>These are our recommended schedule times for {{daysOfTheWeek[+dayOfTheWeek]}}, based on your previous posts from this account:</p>
              <ul>
                <ng-container *ngFor="let suggestedTime of suggestedTimes">
                  <li (click)="setCustomHour(suggestedTime.hour)">
                    <p>{{suggestedTime.label}}</p>
                    <span class="time-menu-stars">
                      <ng-container *ngFor="let f of [].constructor(suggestedTime.stars)">
                        <i class="ssi ssi-starred"></i>
                      </ng-container>
                    </span>
                  </li>
                </ng-container>
              </ul>
              <p *ngIf="suggestedTimes.length < 5">Publish more content to see additional time recommendations</p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </label>
    <div class="select-container">
      <select
        [disabled]="disabled"
        [class.active]="selectedTime"
        name="hour"
        id="hour"
        [ngModel]="selectedTime ? (selectedTime | timeToSelectValue: 'hours') : hours[0]"
        (ngModelChange)="onHourChange($event)"
        #hourField="ngModel"
      >
        <option
          *ngFor="let hour of hours"
          [value]="hour"
        >{{hour}}</option>
      </select>
      <span class="semicolon">:</span>
      <select
        [disabled]="disabled"
        [class.active]="selectedTime"
        name="minute"
        id="minute"
        [ngModel]="selectedTime ? (selectedTime | timeToSelectValue: 'mins') : minutes[0]"
        (ngModelChange)="onMinuteChange($event)"
        #minuteField="ngModel"
      >
        <option
          *ngFor="let minute of minutes"
          [value]="minute"
        >{{minute}}</option>
      </select>
      <div class="meridian-toggle">
        <button
          [disabled]="disabled || isMeridianDisabled"
          [class.active]="meridian === 'am'"
          (click)="meridian === 'pm' && setMeridianTime('am')"
        >am</button>
        <button
          [disabled]="disabled || isMeridianDisabled"
          [class.active]="meridian === 'pm'"
          (click)="meridian === 'am' && setMeridianTime('pm')"
        >pm</button>
      </div>
    </div>
  </div>

  <button
    *ngIf="fullWidth"
    class="show-times-button"
    cdkOverlayOrigin
    #timesMenuSecondTrigger="cdkOverlayOrigin"
    (click)="triggerTimesMenu($event, timesMenuSecondTrigger)"
  >
    <i class="ssi ssi-small-schedule"></i>
    <span>User Orlo Smart Schedule</span>
    <i class="ssi ssi-information-badge"></i>
  </button>
</div>