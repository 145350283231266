import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { PushNotification } from '../../../../../library/interfaces/push-notifications/push-notification';
import { Permission } from '../../../../../library/types/push-notifications/permission';
import { Subscriber } from 'rxjs/Subscriber';
import strings from '../../../../../library/constants/strings';
import { capitalize } from '../../../../../library/helpers/strings';
import { Subscription } from 'rxjs/Subscription';

// ----------------

const isDebug = false;

// ----------------

@Injectable()
export class PushNotificationsService {
  public permission: Permission | string;

  constructor() {
    this.permission = this.isSupported
      ? ('default' as Permission)
      : ('denied' as Permission);
  }

  public get isAvailable(): boolean {
    return this.isPermitted && this.isSupported;
  }

  public get isPermitted(): boolean {
    return this.permission === strings.granted;
  }

  public get isSupported(): boolean {
    return window.hasOwnProperty(capitalize(strings.notification));
  }

  public create(title: string, options?: PushNotification): Observable<{}> {
    try {
      return new Observable((subscriber: Subscriber<{}>) => {
        if (!this.isSupported) {
          if (isDebug) {
            console.log('Notifications are not available in this environment');
          }

          return subscriber.complete();
        }

        if (!this.isPermitted) {
          if (isDebug) {
            console.log(
              "The user hasn't granted you permission to send push notifications"
            );
          }

          return subscriber.complete();
        }

        const notification = new Notification(title, options);

        notification.onclick = (event) =>
          subscriber.next({
            event,
            notification
          });

        notification.onclose = () => subscriber.complete();

        notification.onerror = (event) =>
          subscriber.error({
            event,
            notification
          });

        notification.onshow = (event) =>
          subscriber.next({
            event,
            notification
          });
      });
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public emitTestNotification() {
    this.generateNotification(
      `${capitalize(strings.notification)} are enabled.`,
      {
        body: `You have enabled ${strings.notifications}.`
      }
    );
  }

  public generateNotification(
    title: string,
    parameters: PushNotification
  ): Subscription {
    return this.create(title, parameters).subscribe();
  }

  public async requestPermission(): Promise<NotificationPermission> {
    if (isDebug) {
      console.log(`in pushNotificationsService->requestPermission`);
    }

    const denied: NotificationPermission = 'denied' as Permission;

    try {
      if (!this.isSupported) {
        if (isDebug) {
          console.log(`returning - permissions not suppported.`);
        }

        return denied;
      }

      const status = await Notification.requestPermission();
      this.permission = status;

      if (isDebug) {
        console.log(`returning - status: ${status}`);
      }

      return status;
    } catch (error) {
      if (error instanceof TypeError) {
        return Notification.requestPermission(
          () => (this.permission = 'granted')
        );
      }

      console.error(error);

      return denied;
    }
  }
}
