
<ssi-live-chat-team-dashboard
  *ngIf="!hasActiveConversation"
  [quantityOfAssignedConversations]="quantityOfAssignedConversations"
  [quantityOfTotalConversations]="quantityOfTotalConversations"
  [quantityOfUnassignedConversations]="quantityOfUnassignedConversations"
  [stalestConversation]="stalestConversation"
  [teams]="teams"
></ssi-live-chat-team-dashboard>

<ssi-live-chat-conversation-dashboard
  *ngIf="hasActiveConversation"
  [activeConversation]="activeConversation"
></ssi-live-chat-conversation-dashboard>

<!-- [conversation]="activeConversation" -->