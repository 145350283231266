import './ad-account-breakdown.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import { Account, AccountModel } from '@ui-resources-angular';
import { Report, Totals } from '../../ad-stats.service';
import { CURRENCY_SYMBOLS } from '../../../../../../common/constants';
import { isNumeric } from '../../../../../../common/utils';

@Component({
  selector: 'ssi-ad-account-breakdown',
  templateUrl: './ad-account-breakdown.component.html',
  styles: []
})
export class AdAccountBreakdownComponent implements OnInit {
  @Input() report: Report;

  currencySymbol: string;

  totals: Array<{
    current: Totals;
    previous?: Totals;
    account: Account;
  }> = [];

  constructor() {}

  ngOnInit() {
    this.totals = this.report.totalsStats.totals;
    this.currencySymbol =
      CURRENCY_SYMBOLS[
        this.report.totalsStats.allTotals.current.total_spend.currency
      ];
  }

  isNumber(value: any): boolean {
    return isNumeric(value);
  }

  splitColumnFieldValue(field: string): string {
    return field.split('.')[1];
  }
}
