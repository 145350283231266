import differenceInDays from 'date-fns/difference_in_days';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import subDays from 'date-fns/sub_days';
import subHours from 'date-fns/sub_hours';

export default function dateDifference(
  earlierDate: Date | string,
  laterDate: Date | string
): string {
  const dayDifference = differenceInDays(laterDate, earlierDate);
  laterDate = subDays(laterDate, dayDifference);
  const hourDifference = differenceInHours(laterDate, earlierDate);
  laterDate = subHours(laterDate, hourDifference);
  const minuteDifference = differenceInMinutes(laterDate, earlierDate);
  return `${dayDifference}d ${hourDifference}h ${minuteDifference}m`;
}
