<div class="save-report-modal">
  <div class="save-report-modal-header">
    <div class="save-report-modal-header-left">
      <h2 translate="CREATE_NEW_REPORT" *ngIf="mode === 'create'"></h2>
      <h2 translate="SAVE_CURRENT_REPORT_OR_CREATE_A_NEW_ONE" *ngIf="mode === 'edit'"></h2>
    </div>
    <div class="save-report-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="save-report-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="save-report-modal-body">

    <div class="save-report-modal-action save-report-modal-body-row">
      <div
        class="input-wrapper"
        #reportTitleContainer
      >
        <label translate="TITLE"></label>
        <input
          class="field-input title"
          [class.has-value]="reportTitle"
          type="text"
          name="reportTitle"
          placeholder="{{'TYPE_A_NEW_REPORT_NAME' | translate}}"
          [(ngModel)]="reportTitle"
        >
        <span
          [class.invalid-value]="reportTitle?.length > 60"
          class="char-counter"
        >{{60 - reportTitle?.length}}
        </span>
      </div>
    </div>
    <div class="save-report-modal-action save-report-modal-body-row">
      <div
        class="input-wrapper"
      >
          <label>Report access</label>
          <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                               [options]="accessOptionsIterable"
                               [(ngModel)]="selectedAccessOption">
          <ng-template ssiTemplateSelector="headButtonLabel">
            <span class="head-button-label">Access: <span>{{selectedAccessOption.label}}</span></span>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>
  </div>

  <div class="save-report-modal-footer">
    <div class="save-report-modal-footer-left">
      <orlo-button
        *ngIf="mode === 'edit'"
        class="save-report-modal-button-text"
        [text]="'SAVE_AS_A_NEW_REPORT' | translate"
        state="text"
        textColor="#43537F"
        bgColor="#fff"
        (click)="mode = 'create'"
      ></orlo-button>
    </div>
    <div class="save-report-modal-footer-right">
      <orlo-button
        class="save-report-modal-button save-report-modal-button-secondary"
        [text]="'CANCEL' | translate"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        class="save-report-modal-button"
        [text]="'SAVE' | translate"
        [textColor]="(reportTitle.length > 60 || !reportTitle) ? '#838EAB' : '#FFF'"
        [bgColor]="(reportTitle.length > 60 || !reportTitle) ? '#F4F4FA' : '#14BAE3'"
        [disabled]="reportTitle.length > 60 || !reportTitle"
        (click)="saveReport()"
      ></orlo-button>
    </div>
  </div>
</div>