import './conversation-hold.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {
  Conversation,
  ConversationModel,
  ConversationStatus
} from '@ui-resources-angular';
import { mapToIterable } from '../../utils';
import {
  DropdownSelect2Component,
  Option
} from '../dropdown-select-2/dropdown-select-2.component';

@Component({
  selector: 'ssi-conversation-hold',
  templateUrl: './conversation-hold.component.html',
  styles: []
})
export class ConversationHoldComponent implements OnInit {
  @Input() conversation: Conversation;
  @Input() canReply = false;
  @Input() iconOnly = false;
  @Input() iconOnlyLabel = false;
  @Input() menuPlacementY = 'top';
  @Input() menuPlacementX = 'left';

  @Output() onHoldChange = new EventEmitter<Option>();

  @ViewChild(DropdownSelect2Component)
  holdOptionsMenu: DropdownSelect2Component;

  holdOptions: { [key: string]: Option } = {
    OFF: {
      key: 'OFF',
      label: 'Push back to queue',
      icon: 'ssi-active-pushmode'
    },
    INTERNAL: {
      key: 'INTERNAL',
      label: 'On Hold Internally',
      icon: 'ssi-on-hold-internally'
    },
    EXTERNAL: {
      key: 'EXTERNAL',
      label: 'On Hold Externally',
      icon: 'ssi-user-small'
    }
  };
  holdOptionsIterable: Option[] = mapToIterable(this.holdOptions);
  selectedHoldOption: Option;

  ConversationStatus = ConversationStatus;

  constructor(protected conversationModel: ConversationModel) {}

  ngOnInit() {
    if (this.conversation.onHold) {
      this.selectedHoldOption = this.holdOptionsIterable.find(
        (o) => o.key === this.conversation.on_hold_label
      );
    } else {
      this.holdOptionsIterable = this.holdOptionsIterable.filter(
        (o) => o !== this.holdOptions.OFF
      );
    }
  }

  onHoldOptionChange(holdOption: Option): void {
    this.onHoldChange.emit(holdOption);
  }

  toggleHoldOptions(): void {
    this.holdOptionsMenu.toggle();
  }
}
