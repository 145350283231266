import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PhraseConfirmModalComponent } from './phrase-confirm-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [PhraseConfirmModalComponent],
  declarations: [PhraseConfirmModalComponent],
  entryComponents: [PhraseConfirmModalComponent]
})
export class PhraseConfirmModalModule {}
