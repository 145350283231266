import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';

import { CreateComponent } from './create.component';
import { CommonModule as DashboardCommonModule } from '../common/common.module';
import { MenuModule } from '../../../../common/components/menu/menu.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardCommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.dashboard.create',
          url: '/create',
          // data: {
          //   shouldOpenAddWidget: false
          // },
          component: CreateComponent
          // resolve: CreateComponent.resolve
        }
      ]
    }),
    MenuModule
  ],
  declarations: [CreateComponent]
})
export class CreateModule {}
