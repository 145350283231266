<div class="account-build-container">
  <div class="head">
    <h3>Account Report</h3>
    <h2>Build your report</h2>
  </div>

  <div class="body">
    <div class="body-inner">
      <ssi-report-build-from
        report="Report.Account"
        [showCompareToSection]="true"
        [showIncludeDeletedInTopPostsOptions]="false"
        [showTagsSection]="false"
        [includeLiveChatAccounts]="true"
        [allChannels]="true"
        [formValue]="formValue"
        (formSubmit)="onFormSubmit($event)"
      ></ssi-report-build-from>
    </div>
  </div>
</div>