<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Manage users</h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title"><i class="fa fa-user"></i> <span translate="USER_SETTINGS"></span></h3>
      <div class="pull-right">
        <button
          class="btn btn-warning"
          (click)="showDisabled = !showDisabled">
          <i class="fa fa-ban"></i>
          <span translate="SHOW_ENABLED_USERS" *ngIf="showDisabled"></span>
          <span translate="SHOW_DISABLED_USERS" *ngIf="!showDisabled"></span>
        </button>
        <button
          class="btn btn-primary"
          (click)="newUser = {}"
          *ngIf="!newUser">
          <i class="fa fa-plus"></i> <span translate="NEW_USER"></span>
        </button>
      </div>
      <div class="clearfix"></div>
    </div>

    <div class="panel-body" *ngIf="newUser">
      <div class="well col-sm-6 col-md-offset-3">
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group"
              >
              <label translate="FIRST_NAME"></label>
              <div class="controls">
                <input
                  type="text"
                  [(ngModel)]="newUser.first_name"
                  class="form-control"
                  placeholder="{{ 'FIRST_NAME' | translate }}"
                  required
                  name="firstName">
              </div>
            </div>
            <div
              class="form-group"
              >
              <label translate="LAST_NAME"></label>
              <div class="controls">
                <input
                  type="text"
                  [(ngModel)]="newUser.last_name"
                  class="form-control"
                  placeholder="{{ 'LAST_NAME' | translate }}"
                  required
                  name="lastName">
              </div>
            </div>
            <div
              class="form-group"
              >
              <label translate="EMAIL"></label>
              <div class="controls">
                <input
                  type="email"
                  [(ngModel)]="newUser.email_address"
                  class="form-control"
                  placeholder="{{ 'EMAIL_ADDRESS' | translate }}"
                  required
                  name="email">
              </div>
            </div>
            <div class="form-group">
              <label translate="MOBILE"></label>
              <div class="controls">
                <input
                  [(ngModel)]="newUser.mobile"
                  class="form-control"
                  placeholder="{{ 'MOBILE' | translate }}">
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h3><i class="fa fa-info-circle"></i> <span translate="CREATING_A_USER"></span></h3>
            <p translate="ON_ADDING_A_NEW_USER_THEY_WILL_BE_EMAILED_WITH_A_USERNAME_PASSWORD_AND_WITH_INSTRUCTIONS_ON_HOW_TO_LOGIN"></p>
          </div>
        </div>
        <div class="text-right">
          <button
            (click)="saveUser(newUser); newUser = {}"
            class="btn btn-success">
            <i class="fa fa-floppy-o"></i> <span translate="ADD_USER"></span>
          </button>
          <button (click)="newUser = null" class="btn btn-default">
            <i class="fa fa-times"></i> <span translate="CANCEL"></span>
          </button>
        </div>
      </div>

    </div>

    <table class="table table-striped" *ngIf="!newUser">
      <thead>
        <tr>
          <th translate="NAME"></th>
          <th translate="EMAIL"></th>
          <th translate="ACTIVE"></th>
          <th>
            <div class="input-group pull-right" style="width: 300px">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input
                class="form-control"
                placeholder="{{ 'SEARCH' | translate }}"
                type="search"
                [(ngModel)]="searchText"
                (ngModelChange)="updateUserTable()">
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users | filter: {is_active: !showDisabled} | sortBy: 'first_name' trackBy: trackById">
          <td colspan="10" *ngIf="userEditing?.id === user.id">
            <h4>
              <strong translate="EDIT_USER"></strong>: {{ user.first_name }} {{ user.last_name }}
            </h4>
            <div role="form">
              <div class="row">
                <div class="col-md-6">
									<div
										class="form-group"
										[ssiTooltip]="'Administer users permission required'"
										[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')">
                    <label translate="FIRST_NAME">First Name</label>
                    <input
											[disabled]="!authUser.hasCompanyPermission('administer_users')"
                      type="text"
                      [(ngModel)]="userEditing.first_name"
                      class="form-control"
                      placeholder="{{ 'FIRST_NAME' | translate }}">
                  </div>
                </div>
                <div class="col-md-6">
									<div
										class="form-group"
										[ssiTooltip]="'Administer users permission required'"
										[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')">
                    <label translate="LAST_NAME"></label>
										<input
											[disabled]="!authUser.hasCompanyPermission('administer_users')"
                      type="text"
                      [(ngModel)]="userEditing.last_name"
                      class="form-control"
                      placeholder="{{ 'LAST_NAME' | translate }}">
                  </div>
                </div>
                <div class="col-md-6">
									<div
										class="form-group"
										[ssiTooltip]="'Administer users permission required'"
										[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')">
                    <label translate="EMAIL_ADDRESS"></label>
										<input
											[disabled]="!authUser.hasCompanyPermission('administer_users')"
                      type="text"
                      [(ngModel)]="userEditing.email_address"
                      class="form-control"
                      placeholder="{{ 'EMAIL_ADDRESS' | translate }}">
                  </div>
                </div>
                <div class="col-md-6">
									<div
										class="form-group"
										[ssiTooltip]="'Administer users permission required'"
										[tooltipDisabled]="authUser.hasCompanyPermission('administer_users')">
                    <label translate="MOBILE"></label>
										<input
											[disabled]="!authUser.hasCompanyPermission('administer_users')"
                      type="text"
                      [(ngModel)]="userEditing.mobile"
                      class="form-control"
                      placeholder="{{ 'MOBILE' | translate }}">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      <span translate="IPS_ALLOWED"></span> <small>(<span translate="SEPARATED_BY_COMMAS__12123121232342323234"></span>)</small>
                    </label>
                    <textarea
                      [(ngModel)]="userEditing.restricted_ips"
                      class="form-control">
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <hr class="thin">
            <div *ngIf="userEditing.id === user.id" class="text-right">
              <button class="btn btn-sm btn-success" (click)="saveUser(user, userEditing)">
                <i class="fa fa-floppy-o"></i> <span translate="SAVE"></span>
              </button>
              <button class="btn btn-sm btn-default" (click)="userEditing = false">
                <i class="fa fa-times"></i> <span translate="CANCEL"></span>
              </button>
            </div>
          </td>

          <td *ngIf="userEditing?.id !== user.id">
            {{ user.first_name }} {{ user.last_name }}
          </td>
          <td *ngIf="userEditing?.id !== user.id">
            {{ user.email_address }}
          </td>
          <td class="text-center" *ngIf="userEditing?.id !== user.id">
            <i *ngIf="user.is_active" class="fa fa-check"></i>
            <i *ngIf="!user.is_active" class="fa fa-times"></i>
          </td>
          <td *ngIf="userEditing?.id !== user.id" class="text-right">
            <button
              class="btn btn-sm btn-primary"
              (click)="editUser(user)">
              <i class="fa fa-pencil-square-o"></i> <span translate="EDIT"></span>
            </button>
            <a
              class="btn btn-sm btn-default"
              uiSref="auth.manageUserPermissions" [uiParams]="{userId: user.id}">
              <i class="fa fa-lock"></i> <span translate="PERMISSIONS"></span>
            </a>
            <button
              class="btn btn-sm btn-info"
              (click)="saveUser(user, {resend_welcome_email: true})"
              *ngIf="user.is_active && !user.last_login"
              [disabled]="user.resend_welcome_email">
              <i class="fa fa-envelope"></i> <span translate="RESEND_WELCOME_EMAIL"></span>
            </button>
            <button
              class="btn btn-sm btn-danger"
              (click)="saveUser(user, {is_active: !user.is_active})"
              *ngIf="user.is_active">
              <i class="fa fa-times"></i> <span translate="DISABLE"></span>
            </button>
            <button
              class="btn btn-sm btn-success"
              (click)="saveUser(user, {is_active: !user.is_active})"
              *ngIf="!user.is_active">
              <i class="fa fa-check"></i> <span translate="ENABLE"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
