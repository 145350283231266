<div class="survey-settings">
  <ssi-alerts></ssi-alerts>

  <div class="flex-grid container">
    <div class="col full">
      <label class="field-title">Enable Company Surveys</label>
        <p class="description">This setting enables company wide customer satisfaction surveys
        </p>
      <form
        [formGroup]="surveySettingsForm"
        *ngIf="surveySettingsForm"
      >
        <div class="col one-half">
          <label
              class="form-label text-muted spacer-top"
            >Enable company-wide survey</label>
          <ssi-toggle-switch
            parentNgForm="surveySettingsForm"
            formControlName="enabled"
            toggleText="Surveys {{ surveySettingsForm.controls.enabled.value ? 'On' : 'Off'}}"
          >
          </ssi-toggle-switch>

          <div class="orlo-select">
            <label
              class="form-label text-muted"
            >Survey Type</label>
            <select
              parentNgForm="surveySettingsForm"
              formControlName="type"
              name="surveyType"
            >
              <option [ngValue]="'csat'">CSAT</option>
              <option [ngValue]="'nps'">NPS</option>
              <option [ngValue]="'csat_emoji'">CSAT (emoji)</option>
            </select>
            <i class="ssi ssi-arrow-down-correct"></i>
          </div>

          <div class="orlo-switch">
            <label
              class="form-label text-muted spacer-top"
            >Survey On Resolution</label>
            <ssi-toggle-switch
              parentNgForm="surveySettingsForm"
              formControlName="send_automatically"
              toggleText="{{ surveySettingsForm.controls.send_automatically.value ? 'Automatic' : 'Manual'}}"
            >
            </ssi-toggle-switch>
          </div>
        </div>

        <div class="col one-half">
          <orlo-text-input
            [label]="'Min Score'"
            [name]="'score_min'"
            [parentForm]="surveySettingsForm"
            [type]="'number'"
            [disabled]="disableUserInput"
          ></orlo-text-input>

          <orlo-text-input
            [label]="'Max Score'"
            [name]="'score_max'"
            [parentForm]="surveySettingsForm"
            [type]="'number'"
            [disabled]="disableUserInput"
            [invalid]="surveySettingsForm.controls.score_max?.touched && surveySettingsForm.controls.score_max?.invalid ? true : false"
						[invalidMessage]="'Sorry can only have a maximum of 5 when using CSAT with emojis'">
          ></orlo-text-input>

          <orlo-text-input
            [label]="'Send Delay (1 - 24 Hours)'"
            [name]="'send_delay'"
            [parentForm]="surveySettingsForm"
            [type]="'number'"
            [invalid]="surveySettingsForm.controls.send_delay?.touched && surveySettingsForm.controls.send_delay?.invalid ? true : false"
            [invalidMessage]="'Sorry survey send delay has to be between 1 and 24 hours'">
            ></orlo-text-input>
        </div>
      </form>
    </div>
    <div class="col full">
      <div class="form-row extra-margin-top">
        <div class="form-field">
          <label class="field-title">Configure accounts</label>
          <p class="description">Select an account to enable surveys and set a message
          </p>
          <div class="input-wrapper">
            <ssi-dropdown-select-2
              name="accounts"
              class="accounts-selector"
              headButtonStyleVariant="analytics"
              size="lg"
              [required]="true"
              [multiple]="false"
              [filterable]="true"
              [filterPlaceholder]="'Search for an account...'"
              [toggleAllLabel]="'Select all accounts'"
              [options]="filteredSurveyAccounts"
              [(ngModel)]="selectedAccount"
              (ngModelChange)="onSelectedAccountsChange($event)"
            >
              <ng-template
                ssiTemplateSelector="headButtonLabel"
                let-expanded="expanded"
              >
                <div
                  class="select-accounts-label"
                >
                  <span>{{selectedAccount?.name}}</span>
                  <ssi-social-network-icon
                    *ngIf="selectedAccount?.account_type_id"
                    [accountTypeId]="convertStringToNumber(selectedAccount.account_type_id)"
                    variant="fa"
                  ></ssi-social-network-icon>
                </div>
              </ng-template>
              <ng-template
                ssiTemplateSelector="optionIcon"
                let-account="option"
              >
                <ssi-social-network-icon
                  [accountTypeId]="account.account_type_id"
                  variant="fa"
                ></ssi-social-network-icon>
              </ng-template>
            </ssi-dropdown-select-2>
          </div>
        </div>
      </div>

      <div class="flex-grid" *ngIf="accountSurveySettings.length">
          <div class="form-row col one-half">
            <label
              class="form-label text-muted spacer-top"
            >Enable account survey</label>
            <ssi-toggle-switch
              name="enableAccountSurvey"
              [(ngModel)]="accountSurveySettings[selectedAccountIndex].enabled"
              [ngModelOptions]="{standalone: true}"
              toggleText="Enable account survey"
              >
            </ssi-toggle-switch>
          </div>
          <div class="form-row col one-half">
            <label
              class="form-label text-muted spacer-top"
            >Score Question</label>
            <input type="text" class="orlo-text-input" [(ngModel)]="accountSurveySettings[selectedAccountIndex].score_question" />
          </div>
          <div class="form-row col one-half">
            <label
              class="form-label text-muted spacer-top"
            >Comment Question</label>
            <input type="text" class="orlo-text-input" [(ngModel)]="accountSurveySettings[selectedAccountIndex].comment_question" />
          </div>
          <div class="form-row col one-half">
            <label
              class="form-label text-muted spacer-top"
            >End Of Survey Message</label>
            <input type="text" class="orlo-text-input" [(ngModel)]="accountSurveySettings[selectedAccountIndex].end_survey_message" />
          </div>
      </div>

      <button
            *ngIf="surveySettingsForm"
            class="btn submit-button"
            type="submit"
            [disabled]="surveySettingsForm.invalid"
            (click)="saveSurveySettings()">Save</button>
    </div>
  </div>
</div>