import './sentiment-text.component.scss';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'ssi-sentiment-text',
  templateUrl: './sentiment-text.component.html',
  styles: []
})
export class SentimentTextComponent implements OnInit, OnChanges {
  @Input() sentiment: number;
  @Input() positiveTotal: number;
  @Input() semiPositiveTotal: number;
  @Input() neutralTotal: number;
  @Input() semiNegativeTotal: number;
  @Input() negativeTotal: number;
  total: number;
  value: number;
  sentimentLabel = {
    positive: 'Positive',
    semiPositive: 'Semi Positive',
    neutral: 'Neutral',
    semiNegative: 'Semi Negative',
    negative: 'Negative'
  };

  constructor() {}

  ngOnInit() {
    this.calculateValues();
  }

  ngOnChanges() {
    this.calculateValues();
  }

  calculateValues() {
    this.total =
      (this.positiveTotal || 0) +
      (this.semiPositiveTotal || 0) +
      (this.neutralTotal || 0) +
      (this.semiNegativeTotal || 0) +
      (this.negativeTotal || 0);
    this.value = this[`${this.sentiment}Total`] / this.total * 100 || 0;
  }

  getLabel(): string {
    return this.value.toFixed(1) + '%';
  }
}
