<div class="content-container modal-popup">
	<div class="heading">
		<i class="ssi ssi-assign-correct"></i>
		<!-- <h3 translate="{{isMonitoringActivity ? 'ASSIGN_TO_USER' : 'ASSIGN_TO_USER__TEAM'}}"></h3> -->
		<h3 translate="{{isMonitoringActivity ? 'ASSIGN_TO_USER' : isPushMode ? 'ASSIGN_CONVERSATION_TO_USER' : conversation ? 'ASSIGN_CONVERSATION_TO_USERTEAM' : 'ASSIGN_MESSAGE_TO_USERTEAM'}}"></h3>
		<i
			class="close-icon ssi ssi-times"
			(click)="activeModal.dismiss()"
		></i>
	</div>
	<div
		class="search-field"
		[class.active]="!!searchTerm"
	>
		<input
			type="text"
			[(ngModel)]="searchTerm"
			(ngModelChange)="filterValues(searchTerm)"
			placeholder="{{isMonitoringActivity ? 'Search name...' : (isPushMode ? 'Search name' : 'Search name / team...')}}"
		>
		<i class="ssi ssi-search-small"></i>
	</div>

	<div class="content">
		<ul class="teams" *ngIf="!isPushMode">
			<li
				*ngFor="let team of filteredTeams"
				(click)="selectValue(team)"
				[class.selected]="[conversationSelectedUserOrTeam, selectedUserOrTeam].includes(team)"
			>
				<i class="ssi ssi-teams"></i>
				{{team.name}}
				<i class="tick ssi ssi-tick"></i>
			</li>
		</ul>
		<hr *ngIf="filteredTeams.length > 0 && filteredColleagues.length > 0 && !isPushMode">
		<ul class="colleagues">
			<li
				*ngFor="let colleague of filteredColleagues"
				(click)="selectValue(colleague)"
				[class.selected]="[conversationSelectedUserOrTeam, selectedUserOrTeam].includes(colleague)"
			>
				<i class="ssi ssi-user"></i>{{colleague.fullName}}
				<!-- <div class="more-info">
					<span>{{colleague.assignedConversations}} chats</span>
					<svg
						height="8"
						width="8"
					>
						<circle
							cx="4"
							cy="4"
							r="4"
							[attr.fill]="colleague.online ? '#8ED9E3' : '#F4A894'"
						/>
					</svg>
					<span class="status">{{colleague.online ? 'ONLINE' : 'OFFLINE'}}</span>
				</div> -->
				<i class="tick ssi ssi-tick"></i>
			</li>
		</ul>
		<div
			class="assign-no-results"
			*ngIf="filteredTeams.length === 0 && filteredColleagues.length === 0"
		>
			<i class="ssi ssi-new-features"></i>
			<h3 translate="OH_DEAR_NO_RESULTS"></h3>
			<h6 translate="{{isPushMode ? 'WE_CANT_FIND_ANY_USERS_THAT_MATCH_YOUR_SEARCH_SORRY_HAVE_ANOTHER_GO' : 'WE_CANT_FIND_ANY_USERS_OR_TEAMS_THAT_MATCH_YOUR_SEARCH_SORRY_HAVE_ANOTHER_GO'}}"></h6>
		</div>
  </div>

	<!-- <ng-container *ngIf="!!conversation">
   	 	<hr>
		<ul class="assignment-type">
			<li
				(click)="changeAssignmentType(AssignmentType.Message)"
				[class.selected]="AssignmentType.Message === selectedAssignmentType"
			>
				<i class="tick ssi ssi-tick"></i>
				<input
					type="radio"
					id="message"
					name="message"
					value="message"
					[checked]="AssignmentType.Message === selectedAssignmentType"
				>
				<label for="message">{{ 'ASSIGN_SELECTED_MESSAGE' | translate }}</label>
      		</li>

      		<li
				(click)="changeAssignmentType(AssignmentType.Conversation)"
				[class.selected]="AssignmentType.Conversation === selectedAssignmentType"
			>
				<i class="tick ssi ssi-tick"></i>
				<input
					type="radio"
					id="conversation"
					name="conversation"
					value="conversation"
					[checked]="AssignmentType.Conversation === selectedAssignmentType"
				>
				<label for="conversation">{{ conversationAssignedUserOrTeam ? conversationSelectedUserOrTeam && conversationAssignedUserOrTeam !== conversationSelectedUserOrTeam ? 'Re-Assign Whole Conversation' : 'Un-Assign Whole Conversation' : ('ASSIGN_WHOLE_CONVERSATION' | translate) }}</label>
			</li>
    	</ul>
	</ng-container> -->

	<div class="buttons">
		<button
			*ngIf="showAssignMe"
			class="assign-to-me"
			(click)="assign(authUser)"
			[disabled]="assignedUserOrTeam && assignedUserOrTeam.id === authUser.id"
		><i class="ssi ssi-assign-correct"></i>{{'ASSIGN_TO_ME' | translate}}</button>
		<orlo-button
			textColor="#43537F"
			state="text"
			text="Cancel"
			textClass="uppercase"
			(btnClick)="activeModal.close()"
		></orlo-button>

	<ng-container *ngIf="!conversation">
		<orlo-button
		*ngIf="selectedAssignmentType === AssignmentType.Message"
			  [textColor]="!selectedUserOrTeam && !assignedUserOrTeam ? '#b4bbce' : '#14bae3'"
			  state="text"
			  text="{{(assignedUserOrTeam ? selectedUserOrTeam && assignedUserOrTeam !== selectedUserOrTeam ? 'ASSIGN' : 'UNASSIGN' : 'ASSIGN') | translate}}"
			  textClass="uppercase"
			  [disabled]="!selectedUserOrTeam && !assignedUserOrTeam"
			  (btnClick)="assign(selectedUserOrTeam)"
	  ></orlo-button>
    <orlo-button
      *ngIf="selectedAssignmentType === AssignmentType.Conversation"
		  [textColor]="!conversationSelectedUserOrTeam && !conversationAssignedUserOrTeam ? '#b4bbce' : '#14bae3'"
		  state="text"
		  text="{{(conversationAssignedUserOrTeam ? conversationSelectedUserOrTeam && conversationAssignedUserOrTeam !== conversationSelectedUserOrTeam ? 'REASSIGN' : 'UNASSIGN' : 'ASSIGN') | translate}}"
		  textClass="uppercase"
		  [disabled]="!conversationSelectedUserOrTeam && !conversationAssignedUserOrTeam"
		  (btnClick)="assign(conversationSelectedUserOrTeam)"
	  ></orlo-button>
	</ng-container>


	<ng-container *ngIf="conversation">
		<orlo-button
			[textColor]="!conversationSelectedUserOrTeam && !conversationAssignedUserOrTeam ? '#b4bbce' : '#14bae3'"
			state="text"
			text="{{(isPushMode ? 'PUSHASSIGN' : 'ASSIGN') | translate}}"
			textClass="uppercase"
			[disabled]="!conversationSelectedUserOrTeam && !conversationAssignedUserOrTeam"
			(btnClick)="assign(conversationSelectedUserOrTeam)"
		></orlo-button>
	</ng-container>

	</div>
</div>