<div class="segment">
  <h1>Embed your widget</h1>
  <div class="embed-nav">
    <ul>
      <li (click)="activeView = 'website'" [class.active]="activeView === 'website'"><i class="ssi ssi-websitewidget"></i>Your website</li>
      <li (click)="activeView = 'data'" [class.active]="activeView === 'data'"><i class="ssi ssi-customerdata"></i>Customer data</li>
      <li (click)="activeView = 'app'" [class.active]="activeView === 'app'"><i class="ssi ssi-mobilewidget"></i>Your Mobile App</li>
      <li (click)="activeView = 'actions'" [class.active]="activeView === 'actions'"><i class="ssi ssi-customactions"></i>Custom Actions</li>
    </ul>
  </div>

  <div class="embed-view" [ngSwitch]="activeView">
    <div *ngSwitchCase="'website'">
      <p>
        Copy &amp; paste the following block of HTML, somewhere into the <em>&lt;head&gt;</em> section of your web-page.
      </p>
      <textarea>
        &lt;script
          async
          src="https://live-chat-cdn.orlo.app/app/{{ widget?.appId }}/launch-iframe.js"
          type="text/javascript"
        &gt;&lt;/script&gt;
      </textarea>
    </div>
    <div *ngSwitchCase="'data'">
      <p>
        If you are providing extra information via JWT token, you will also need to add something similar to the following.
      </p>

      <p>
        In your code, replace <em>JWT_TOKEN</em> with the actual token. 
      </p>

      <p>
        You may set this whenever necessary, at any point within the lifecycle of the page.
      </p>

      <textarea>
        &lt;script async&gt;
        window['_orlo_{{ widget?.appId }}'] = { u: 'JWT_TOKEN' };
        &lt;/script&gt;
      </textarea>
    </div>
    <div *ngSwitchCase="'app'">
      <p>
        A special app-ready version of your widget can be found at:
        <a
          href="https://live-chat-cdn.orlo.app/app/{{ widget?.appId }}/launch.html"
          target="_blank"
        >https://live-chat-cdn.orlo.app/app/{{ widget?.appId }}/launch.html</a>
      </p>

      <p>
        You can set the JWT token by passing it in as the `olcmd` parameter, like so:
        <a
          href="https://live-chat-cdn.orlo.app/app/{{ widget?.appId }}/launch.html#olcmd=JWT_TOKEN"
          target="_blank"
        >https://live-chat-cdn.orlo.app/app/{{ widget?.appId }}/launch.html#olcmd=JWT_TOKEN</a>
      </p>
    </div>
    <div *ngSwitchCase="'actions'">
      <h4>
          Opening &amp; closing the Widget
      </h4>

      <p>
        You can set any piece of HTML on your page to trigger the opening or closing of your widget.
        This can be done in response to any <em>event</em>, such as clicking a button or hovering over
        an image.
      </p>

      <p>
        You will need a piece of <em>Javascript</em> code, such as the following. This example would
        allow for any button with an <em>id</em> of <em>"trigger-orlo-widget"</em> to act as a new
        trigger for your Live Chat widget.
      </p>

      <p>
        You will probably want to disable the default trigger button, which you can do via the
        Settings page for this widget.
      </p>

      <textarea class="col-md-12">
        &lt;script type="text/javascript"&gt;
        document.onload = function(event) {
        var button = document.querySelector('#trigger-orlo-widget');
        button.onclick = function(event) {
        window.postMessage({ id: '_orlo_{{ widget?.appId }}-widget-toggle' }, '*');
        }
        }
        &lt;/script&gt;
      </textarea>
    </div>
    
  </div>
</div>