<div class="brand-sentiment-container">
  <div class="section-head">
    <h2>
      <span>Brand Sentiment</span>
      <i
        class="ssi ssi-help"
        [ssiTooltip]="'THESE_FIGURES_SHOW_THE_SENTIMENT_OF_THE_INBOUND_PUBLIC_COMMENTS_OR_MENTIONS_AND_PRIVATE_OR_DIRECT_MESSAGES_RECEIVED_FOR_THE_REPORTED_TIME_PERIOD' | translate"
          tooltipWindowClass="tooltip-chunky-big"
      ></i>
    </h2>
    <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
  </div>

  <div class="section-body" *ngIf="currentSentiments">
    <div class="sentiments">
      <div class="pie-chart-container">
        <ssi-highchart [config]="chartConfig"></ssi-highchart>
      </div>
      <div class="cards-container">
        <ssi-fact-overview [label]="'TOTAL_MESSAGES' | translate"
                           [number]="currentSentimentsTotal"
                           iconClass="ssi-quote"
                           iconColor="#89A4EA"
                           iconSize="46px"
                           circleColor="#EDF1FC"
                           [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_PUBLIC_COMMENTS_OR_MENTIONS_AND_PRIVATE_OR_DIRECT_MESSAGES_RECEIVED_FOR_THE_REPORTED_TIME_PERIOD' | translate"
                           tooltipWindowClass="tooltip-chunky-big"
                           width="100%"></ssi-fact-overview>

        <ssi-sentiment-card *ngFor="let sentiment of sentimentsConstIterable"
                            label="messages"
                            [sentiment]="sentiment"
                            [currentValue]="currentSentiments[sentiment.key]"
                            [previousValue]="previousSentiments && previousSentiments[sentiment.key]"
                            [showStatChange]="!!previousSentiments"></ssi-sentiment-card>
      </div>
    </div>

    <div class="network-inbound-comments">
      <ssi-network-inbound-comments [dateRanges]="dateRanges"
                                    [stats]="stats"></ssi-network-inbound-comments>
    </div>
  </div>
</div>