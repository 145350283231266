import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { LoginActivityComponent } from './login-activity.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.mysettings.loginactivity',
          url: '/login-activity',
          component: LoginActivityComponent,
          resolve: LoginActivityComponent.resolve
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [LoginActivityComponent],
  entryComponents: [LoginActivityComponent]
})
export class LoginActivityModule {}
