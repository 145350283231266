import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ssi-campaign',
  templateUrl: './campaign.component.html',
  styles: []
})
export class CampaignComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
