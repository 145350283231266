import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { IndexComponent } from './index.component';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.index',
          url: '/overview',
          component: IndexComponent,
          resolve: IndexComponent.resolve
        }
      ]
    })
  ],
  declarations: [IndexComponent],
  entryComponents: [IndexComponent]
})
export class IndexModule {}
