<div class="ai-writing-tool-modal">
  <div class="ai-writing-tool-modal-header">
    <div class="ai-writing-tool-modal-header-left">
      <i [ngClass]="variants[variant].titleIconClass"></i>
      <h2 [translate]="variants[variant].title"></h2>
    </div>
    <div class="ai-writing-tool-modal-header-right">
      <button
        (click)="activeModal.close(false)"
        class="ai-writing-tool-modal-close"
      >
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="ai-writing-tool-modal-body">
    <ng-container *ngIf="variant === 'generate'">
      <p
        translate="WRITE_A_FEW_WORDS_ON_WHAT_THE_POST_IS_ABOUT_CHOOSE_YOUR_TONE_AND_LET_GENERATE_DO_THE_HARD_WORK_TO_CRAFT_YOUR_POST_FOR_YOU__SIMPLE_YOU_CAN_ALSO_GET_HASHTAG_SUGGESTIONS_OR_REPHRASE_AN_EXISTING_POST">
      </p>
      <div class="ai-writing-tool-modal-action ai-writing-tool-modal-body-row">
        <div class="input-wrapper">
          <label translate="TONE"></label>
          <div
            *ngFor="let option of postToneOptionsIterable"
            class="inline-select-item"
            (click)="selectedTone = option.key"
            [class.selected]="option.key === selectedTone"
          >
            <i class="ssi {{option.icon}}"></i>
            <span>{{option.label}}</span>
            <i
              *ngIf="selectedTone === option.key"
              class="ssi ssi ssi-tick-small tick"
            ></i>
          </div>
        </div>
      </div>


      <div class="ai-writing-tool-modal-action ai-writing-tool-modal-body-row">
        <div class="input-wrapper">
          <label translate="YOUR_TOPIC"></label>
          <textarea
            class="field-input"
            rows="4"
            placeholder="e.g. announcing an event for the local community "
            type="text"
            name="topicText"
            [(ngModel)]="topicText"
          ></textarea>
        </div>
        <div class="body-action-button">
          <orlo-button
            [disabled]="rateLimit.usage >= rateLimit.limit"
            class="ai-writing-tool-modal-button ai-writing-tool-modal-button-secondary generate"
            [text]="'Generate now'"
            [leadingIcon]="'ssi ssi-enhance'"
            [textColor]="topicText ? '#FFF' : '#838EAB'"
            [bgColor]="topicText ? '#14BAE3' : '#F0F3F9'"
            (btnClick)="generate()"
          ></orlo-button>
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="suggestionRequested"
      class="ai-writing-tool-modal-action ai-writing-tool-modal-body-row"
    >
      <ng-container *ngIf="searching; else elseTemplate">
        <span
          class="suggestion-status-text"
          translate="IM_HAVING_A_THINK"
        ></span>
        <div
          class="spinner-wrapper"
          [ngStyle]="{'display: none': !searching}"
        >
          <ssi-spinner [visible]="searching"></ssi-spinner>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <span class="suggestion-status-text"><i
            class="ssi ssi-enhance"></i>{{'IVE_COME_UP_WITH_THIS_VERSION_HOW_DOES_THIS_WORK_FOR_YOU' |
          translate}}</span>
      </ng-template>

      <p
        class="suggestion-text"
        *ngIf="suggestedText"
        [innerHTML]="suggestedText"
      ></p>
    </div>
    <div
      class="rate-limit"
      *ngIf="rateLimit"
    >
      <p
        *ngIf="rateLimit.usage >= rateLimit.limit"
        translate="WOW_YOU_WORK_QUICKLY_YOUVE_UTILISED_ALL__LIMIT__OF_YOUR_FREE_USES_WANT_TO_SAY_GOODBYE_TO_WRITERS_BLOCK_FOR_GOOD_CONTACT_YOUR_CUSTOMER_SUCCESS_MANAGER_TO_UPGRADE_YOUR_PACKAGE"
        [translateParams]="{limit: rateLimit.limit}"
      >
      </p>
      {{rateLimit.usage}} / {{rateLimit.limit}} {{'USES' | translate}}
    </div>
  </div>

  <div class="ai-writing-tool-modal-footer">
    <div class="ai-writing-tool-modal-footer-left"></div>
    <div class="ai-writing-tool-modal-footer-right">
      <orlo-button
        class="ai-writing-tool-modal-button ai-writing-tool-modal-button-secondary"
        [text]="variants[variant].cancelButtonText"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        class="ai-writing-tool-modal-button"
        [text]="variants[variant].actionButtonText"
        textColor="#fff"
        bgColor="#14BAE3"
        [disabled]="!topicText || !suggestedText"
        (click)="topicText && suggestedText && activeModal.close(suggestedText)"
      ></orlo-button>
    </div>
  </div>
</div>