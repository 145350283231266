<div class="datetime">
  <div class="exit" (click)="activeModal.close(null)">
    <span><i class="ssi ssi-times"></i></span>
  </div>
  <div class="header">
      <h2 *ngIf="title">{{title}}</h2>
      <p *ngIf="info" class="info">{{info}}</p>
  </div>
  <input
    class="datetime-trigger"
    type="hidden"
    mwlFlatpickr
    [(ngModel)]="schedule"
    [convertModelValue]="true"
    [inline]="true"
    [enableTime]="true"
    [dateFormat]="'Z'">
  <div class="footer">
    <orlo-button
      [state]="'primary'"
      [text]="'Confirm'"
      (click)="activeModal.close(schedule)"
      [disabled]="!schedule"
    ></orlo-button>
    <button class="orlo-button text" (click)="activeModal.close(null)">Cancel</button>
  </div>
</div>
