import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventModalComponent } from './calendar-event-modal.component';
import { SocialNetworkIconModule } from '../../../../../../../common/components/social-network-icon/social-network-icon.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { OrloComponentsModule } from 'orlo-components';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ConfirmActionModule } from '../../../../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { TooltipModule } from '../../../../../../../common/directives/tooltip/tooltip.module';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  declarations: [CalendarEventModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    SocialNetworkIconModule,
    OverlayModule,
    OrloComponentsModule,
    FlatpickrModule,
    FormsModule,
    TranslateModule,
    ConfirmActionModule,
    OrloComponentsModule,
    TooltipModule,
    ColorPickerModule
  ],
  exports: [CalendarEventModalComponent],
  entryComponents: [CalendarEventModalComponent]
})
export class CalendarEventModalModule {}
