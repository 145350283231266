import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { HighchartComponent } from './highchart.component';

@NgModule({
  imports: [CommonModule, HighchartsChartModule],
  declarations: [HighchartComponent],
  exports: [HighchartComponent]
})
export class HighchartModule {}
