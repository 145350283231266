import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarComponent } from './navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TriggerPublishModule } from '../../directives/trigger-publish/trigger-publish.module';
import { UIRouterModule } from '@uirouter/angular';
import { NewVersionModalComponent } from './new-version-modal/new-version-modal.component';
import { SortByModule } from '../../pipes/sort-by/sort-by.module';
import { IconModule } from '../icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    TriggerPublishModule,
    UIRouterModule,
    SortByModule,
    IconModule
  ],
  declarations: [NavbarComponent, NewVersionModalComponent],
  exports: [NavbarComponent, NewVersionModalComponent]
})
export class NavbarModule {}
