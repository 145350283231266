import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User, UserModel } from '@ui-resources-angular';

import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { Message } from '../../../../../../library/models/live-chat/message';

@Component({
  selector: 'ssi-live-chat-notes',
  templateUrl: './live-chat-notes.component.html',
  styles: []
})
export class LiveChatNotesComponent implements OnInit {
  @Input() isNoteFormFocused = false;
  @Input() message: Message;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  isNoteFormFocusedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _isInitialised = false;
  private _user: User;

  public get isInitialised(): boolean {
    return this._isInitialised;
  }

  public get user(): User {
    return this._user;
  }

  constructor(
    private liveChatService: LiveChatService,
    private userModel: UserModel
  ) {}

  public onIsNoteFormFocusedChange(value: boolean) {
    this.isNoteFormFocusedChange.emit(value);
  }

  public ngOnInit() {
    this.userModel.getAuthUser().then((user) => {
      this._user = user;

      if (!this.isInitialised) {
        this._isInitialised = true;
      }
    });
  }

  public onClose() {
    this.close.emit(true);
  }
}
