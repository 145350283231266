import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfluencersListComponent } from './influencers-list.component';
import { BigNumberModule } from '../../pipes/big-number/big-number.module';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';

@NgModule({
  imports: [
    CommonModule,
    BigNumberModule,
    IconModule,
    TooltipModule,
    FallbackImageModule
  ],
  declarations: [InfluencersListComponent],
  exports: [InfluencersListComponent]
})
export class InfluencersListModule {}
