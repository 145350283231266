import { Component, Output, EventEmitter } from '@angular/core';
import { UserModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  @Output() onChangeForm = new EventEmitter();
  email_address: string;
  sent = '';

  constructor(private userModel: UserModel) {}

  resetPassword() {
    const email = this.email_address;
    this.userModel.resetPassword(email).then(() => {
      this.sent = email;
    });
  }

  toggleChat(): boolean {
    try {
      window.postMessage(
        { id: '_orlo_369d2bba-098d-4683-b82c-c31d003a243b-widget-toggle' },
        '*'
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}
