import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-query-presets',
  templateUrl: './query-presets.component.html',
  styles: []
})
export class QueryPresetsComponent {
  @Input() query;
  @Output() onSelected = new EventEmitter();

  constructor() {}
}
