<div class="engagement-times-section">
  <div class="section-chart-wrap">
    <div class="top-row">
      <div class="best-hours-container">
        <div class="left">
          <span>{{'HIGHEST_ENGAGEMENT' | translate}}</span>
        </div>
        <div class="middle">
          <div class="hours">
            <span *ngFor="let hour of stats.topHours | slice:0:4; let i = index;">
              {{hour.label + (i === 3 ? ' ' : ', ')}}
            </span>
          </div>
          <div>
            <span>{{'ON_DAYS_SHOWN_BELOW' | translate}}</span>
          </div>
        </div>
        <div class="right">
          <i class="ssi ssi-experience"></i>
        </div>
      </div>

      <div class="section-tip-wrap">
        <div class="section-tip">
          <button
            class="section-tip-icon"
            (click)="proTipOpen = !proTipOpen"
          ><i class="ssi ssi-pro-tip"></i></button>
          <p [style.display]="proTipOpen ? 'inline-flex' : 'none'">
            {{'ENGAGEMENT_TIMES_PRO_TIP_2' | translate}}
          </p>
        </div>
      </div>
    </div>

    <ssi-highchart [config]="chartConfig"></ssi-highchart>

    <div class="chart-legend">
      <div class="legend-item great">
        <div class="circle"></div>
        <span>Very High</span>
      </div>
      <div class="legend-item good">
        <div class="circle"></div>
        <span>High</span>
      </div>
      <div class="legend-item okay">
        <div class="circle"></div>
        <span>Medium</span>
      </div>
      <div class="legend-item not-great">
        <div class="circle"></div>
        <span>Low</span>
      </div>
      <div class="legend-item not-good">
        <div class="circle"></div>
        <span>Very Low</span>
      </div>
    </div>
  </div>
</div>