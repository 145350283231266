<div
  class="multiple-accounts"
  *ngIf="multipleAccounts"
>
  <div
    class="targeting-options-item accordion-content"
    *ngIf="hasFacebookAccountType()"
  >
    <ssi-accordion
      #facebookPostsAccordion
      [label]="facebookTargetingOptionsHeading"
      [isOpen]="false"
      [isValid]="true"
    >
      <ng-container *ngTemplateOutlet="facebookOptions"></ng-container>
    </ssi-accordion>
    <ng-template #facebookTargetingOptionsHeading>
      <div
        class="toggle-label"
        [class.toggled]="facebookTargetingOptionsEnabled"
      >
        <p translate="FACEBOOK_TARGETING"></p>
        <ssi-toggle-switch-2 [(ngModel)]="facebookTargetingOptionsEnabled"></ssi-toggle-switch-2>
      </div>
    </ng-template>
  </div>

  <div
    class="targeting-options-item accordion-content"
    *ngIf="hasLinkedInAccountType()"
  >
    <ssi-accordion
      #linkedinPostsAccordion
      [label]="linkedinTargetingOptionsHeading"
      [isOpen]="false"
      [isValid]="true"
    >
      <ng-container *ngTemplateOutlet="linkedinOptions"></ng-container>
    </ssi-accordion>
    <ng-template #linkedinTargetingOptionsHeading>
      <div
        class="toggle-label"
        [class.toggled]="linkedinTargetingOptionsEnabled"
      >
        <p translate="LINKEDIN_TARGETING"></p>
        <ssi-toggle-switch-2 [(ngModel)]="linkedinTargetingOptionsEnabled"></ssi-toggle-switch-2>
      </div>
    </ng-template>
  </div>

  <div
    class="targeting-options-item accordion-content"
    *ngIf="hasNextdoorAccountType()"
  >
    <ssi-accordion
      #nextdoorPostsAccordion
      [label]="nextdoorTargetingOptionsHeading"
      [isOpen]="false"
      [isValid]="true"
    >
      <ng-container *ngTemplateOutlet="nextdoorOptions"></ng-container>
    </ssi-accordion>
    <ng-template #nextdoorTargetingOptionsHeading>
      <div
        class="toggle-label"
        [class.toggled]="nextdoorTargetingOptionsEnabled"
      >
        <p>{{ multipleNextdoorAccountTypes ? 'Nextdoor UK Targeting' : 'Nextdoor Targeting' }}</p>
        <ssi-toggle-switch-2 [(ngModel)]="nextdoorTargetingOptionsEnabled"></ssi-toggle-switch-2>
      </div>
    </ng-template>
  </div>

  <div
    class="targeting-options-item accordion-content"
    *ngIf="hasNextdoorUSAccountType()"
  >
    <ssi-accordion
      #nextdoorUSPostsAccordion
      [label]="nextdoorUSTargetingOptionsHeading"
      [isOpen]="false"
      [isValid]="true"
    >
      <ng-container *ngTemplateOutlet="nextdoorUSOptions"></ng-container>
    </ssi-accordion>
    <ng-template #nextdoorUSTargetingOptionsHeading>
      <div
        class="toggle-label"
        [class.toggled]="nextdoorUSTargetingOptionsEnabled"
      >
        <p>{{ multipleNextdoorAccountTypes ? 'Nextdoor US Targeting' : 'Nextdoor Targeting' }}</p>
        <ssi-toggle-switch-2 [(ngModel)]="nextdoorUSTargetingOptionsEnabled"></ssi-toggle-switch-2>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="!multipleAccounts">
  <div
    class="targeting-options-item"
    *ngIf="hasFacebookAccountType()"
  >
    <ng-container *ngTemplateOutlet="facebookOptions"></ng-container>
  </div>

  <div
    class="targeting-options-item"
    *ngIf="hasLinkedInAccountType()"
  >
    <ng-container *ngTemplateOutlet="linkedinOptions"></ng-container>
  </div>

  <div
    class="targeting-options-item"
    *ngIf="hasNextdoorAccountType()"
  >
    <ng-container *ngTemplateOutlet="nextdoorOptions"></ng-container>
  </div>

  <div
    class="targeting-us-options-item"
    *ngIf="hasNextdoorUSAccountType()"
  >
    <ng-container *ngTemplateOutlet="nextdoorUSOptions"></ng-container>
  </div>
</div>

<ng-template #facebookOptions>
  <ssi-facebook-options
    #facebookTargetingOptions
    [post]="post"
    (select)="enable(AccountTypeIdString.Facebook)"
    [previewToggled]="previewToggled"
  ></ssi-facebook-options>
</ng-template>

<ng-template #linkedinOptions>
  <ssi-linkedin-options
    #linkedinTargetingOptions
    [post]="post"
    (select)="enable(AccountTypeIdString.LinkedIn)"
    [previewToggled]="previewToggled"
  ></ssi-linkedin-options>
</ng-template>

<ng-template #nextdoorOptions>
  <ssi-nextdoor-options
    #nextdoorTargetingOptions
    [post]="post"
    [accountTypeName]="AccountTypeName.Nextdoor"
    [accountTypeIdString]="AccountTypeIdString.Nextdoor"
    (select)="enable(AccountTypeIdString.Nextdoor)"
  ></ssi-nextdoor-options>
</ng-template>

<ng-template #nextdoorUSOptions>
  <ssi-nextdoor-options
    #nextdoorUSTargetingOptions
    [post]="post"
    [accountTypeName]="AccountTypeName.NextdoorUS"
    [accountTypeIdString]="AccountTypeIdString.NextdoorUS"
    (select)="enable(AccountTypeIdString.NextdoorUS)"
  ></ssi-nextdoor-options>
</ng-template>