<div class="dashboard-view-container">
  <ssi-dashboard-navbar [dashboardName]="dashboard?.name"
                        [dashboardID]="dashboard?.id"
                        [mode]="'view'"
                        [dashboards]="dashboards"
                        (dashboardChange)="onDashboardChange($event)"></ssi-dashboard-navbar>

  <div class="dashboard-content">
    <ssi-widgets-grid #widgetsGrid
                      [apiWidgets]="dashboard?.widgets"
                      [viewMode]="true"
                      [currentDashboard]="dashboard"></ssi-widgets-grid>
  </div>
</div>