import {
  subDays,
  startOfDay,
  endOfDay,
  subWeeks,
  subMonths,
  subSeconds,
  subYears,
  differenceInSeconds
} from 'date-fns';
import { mapToIterable } from '../utils';

export interface DateRange {
  id: string;
  label: string;
  start: Date;
  end: Date;
  startString?: string;
  endString?: string;
}

export interface DateRanges {
  yesterday: DateRange;
  week: DateRange;
  month: DateRange;
  quarter: DateRange;
  year: DateRange;
  allTime?: DateRange;
}

export const commonDateRanges: DateRanges = {
  get yesterday() {
    return {
      id: 'yesterday',
      label: 'Yesterday',
      start: startOfDay(subDays(new Date(), 1)),
      end: endOfDay(subDays(new Date(), 1)),
      startString: '-1 day midnight',
      endString: 'midnight'
    };
  },
  get week() {
    return {
      id: 'week',
      label: 'Last week',
      start: startOfDay(subWeeks(new Date(), 1)),
      end: endOfDay(new Date()),
      startString: '-7 days',
      endString: 'now'
    };
  },
  get month() {
    return {
      id: 'month',
      label: 'Last month',
      start: startOfDay(subMonths(new Date(), 1)),
      end: endOfDay(new Date()),
      startString: '-30 days',
      endString: 'now'
    };
  },
  get quarter() {
    return {
      id: 'quarter',
      label: 'Last quarter',
      start: startOfDay(subMonths(new Date(), 3)),
      end: endOfDay(new Date()),
      startString: '-3 months',
      endString: 'now'
    };
  },
  get year() {
    return {
      id: 'year',
      label: 'Last year',
      start: startOfDay(subMonths(new Date(), 12)),
      end: endOfDay(new Date()),
      startString: '-1 year',
      endString: 'now'
    };
  }
};

export const commonDateRangesIterable: DateRange[] = mapToIterable(
  commonDateRanges
);
