<div
  class="panel panel-default"
  [ngClass]="{'mouse-pointer': isSelectable, 'no-media': !post.outbox_files.length, 'has-bottom-stats': !post.deleted_at && !minimal && post.sent_at && post.social_id}"
  (click)="isSelectable && onSelectToggle.emit({isSelected: !isSelected})"
>
  <div
    class="panel-body"
    [ngClass]="{
      'bg-success': isSelected,
      'panel-body-failed': post.last_attempt_at && !post.social_id
    }"
  >
    <div class="media">
      <div class="media-left">
        <img
          width="42"
          height="42"
          class="media-object img-circle"
          [src]="post.account.picture"
          ssiFallbackImage="avatar"
        >
        <div
          class="outbox-post-advert-icon"
          *ngIf="post.ad_count > 0 || advert"
        >
          <span
            class="fa-stack fa-lg"
            ssiTooltip="This is a boosted post"
            [tooltipAppendToBody]="true"
          >
            <i class="fa fa-circle fa-stack-2x text-warning"></i>
            <i class="ssi ssi-ads ssi-stack-1x ssi-inverse"></i>
          </span>
        </div>
      </div>
      <div class="media-body">
        <div
          class="loading"
          [hidden]="!loadingTracker?.active"
        ></div>
        <div [hidden]="!!loadingTracker?.active">
          <div
            class="outbox-menu"
            *ngIf="post.social_id && !post.account.socialNetwork.inboundInitiated"
          >
            <a
              class="btn btn-default btn-xs btn-rounded outbox-menu-trigger"
              href="javascript:;"
              (click)="$event.stopPropagation()"
            >
              <i class="fa fa-ellipsis-v ssi-lg text-primary"></i>
            </a>
            <div
              class="outbox-menu-items"
              (click)="$event.stopPropagation()"
            >
            <a
                class="text-danger"
                href="javascript:;"
                (click)="deletePost()"
                ssiTooltip="{{ 'DELETE' | translate }}"
                [hidden]="post.deleted_at"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-delete"></i>
              </a>
              <a
                ssiTooltip="{{ 'BOOST_POST' | translate }}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                *ngIf="post.account.promoted_by.length > 0 && !advert && ['3','4','8','9'].includes(post.account.account_type_id)"
                (click)="boostPost()"
              >
                <i class="ssi ssi-ads"></i>
              </a>
              <a
                *ngIf="authUser?.hasCompanyPermission('administer_company') && auditingEnabled"
                ssiTooltip="{{ 'OUTBOX_AUDIT' | translate }}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                (click)="openAudit()"
              >
                <i class="ssi ssi-historyaudit"></i>
              </a>
              <a
                ssiTooltip="Edit Live Post"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                (click)="triggerPublish({
                  edit: {post: post},
                  disable: {
                    previewPost: true,
                    changeAccount: true,
                    changeCampaign: true,
                    changeTargeting: true,
                    requestingValidation: true,
                    scheduling: true,
                    editMedia: true
                  }
                })"
                [hidden]="post.deleted_at || !authUser?.hasAccountPermission(post.account_id, 'post_edit')"
              >
                <i class="ssi ssi-correctedit"></i>
              </a>
              <a
                ssiTooltip="{{post.campaign_id ? 'Change Campaign' : 'Add Campaign'}}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                (click)="showCampaignModal()"
              >
                <i class="ssi ssi-campaign"></i>
              </a>
              <a
                href="javascript:;"
                (click)="triggerPublish({create: {copy: post}})"
                ssiTooltip="{{ 'COPY' | translate }}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-copy-paste"></i>
              </a>
              <a
                uiSref="auth.twitterThreads"
                [uiParams]="{outboxId: post.id }"
                *ngIf="post.type !== OutboxMessageType.PrivateMessage && post.account.socialNetwork.outbox.threadReplies"
                ssiTooltip="Create/Edit Thread"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-plus"></i>
              </a>
              <!-- <a
                *ngIf="post.account.socialNetwork.outbox.threadReplies"
                href="javascript:;"
                [ssiTriggerPublish]="{create: {accounts: [post.account], replyToSocialId: post.social_id}}"
                ssiTooltip="{{ 'ADD_TO_THREAD' | translate }}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-plus"></i>
              </a> -->
              <a
                [href]="post.externalLink"
                target="_blank"
                *ngIf="post.externalLink"
                [ssiTooltip]="'VIEW_POST_NATIVELY' | translate"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-view-natively"></i>
              </a>
              <a
                [href]="advert?.external_link"
                target="_blank"
                *ngIf="advert?.external_link"
                [ssiTooltip]="'VIEW_ADVERT_NATIVELY' | translate"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="fa fa-external-link"></i>
              </a>
              <a
                uiSref="auth.inbox"
                [uiParams]="{activity: post.activity_id }"
                [uiOptions]="{reload: true}"
                (click)="closeModal.emit()"
                *ngIf="post.activity_id"
                ssiTooltip="View in inbox"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-toolbar-inbox"></i>
              </a>
              <a
                *ngIf="post.canEditAutoComment()"
                ssiTooltip="Edit first comment"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                [tooltipWindowClass]="'tooltip-chunky'"
                (click)="editAutoComment(post)"
              >
                <i class="ssi ssi-first-comment-temporary"></i>
              </a>
              <a
                *ngIf="post.last_attempt_at && !post.social_id"
                ssiTooltip="Retry"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                [tooltipWindowClass]="'tooltip-chunky'"
                (click)="retryFailed(post.id)"
              >
                <i class="fa fa-repeat"></i>
              </a>

              <i class="fa fa-ellipsis-v ssi-lg text-primary"></i>
            </div>
          </div>
          <div
            class="outbox-menu"
            *ngIf="!post.social_id && !post.account.socialNetwork.inboundInitiated"
          >
            <a
              class="btn btn-default btn-xs btn-rounded outbox-menu-trigger"
              href="javascript:;"
              (click)="$event.stopPropagation()"
            >
              <i class="fa fa-ellipsis-v ssi-lg text-primary"></i>
            </a>
            <div
              class="outbox-menu-items"
              (click)="$event.stopPropagation()"
            >
            <a
                class="text-danger"
                href="javascript:;"
                (click)="deletePost()"
                ssiTooltip="{{ 'DELETE' | translate }}"
                [hidden]="post.deleted_at"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-delete"></i>
              </a>
              <a
                ssiTooltip="{{ 'BOOST_POST' | translate }}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                *ngIf="post.account.promoted_by.length > 0 && !advert && ['3','4','8','9'].includes(post.account.account_type_id)"
                (click)="boostPost()"
              >
                <i class="ssi ssi-ads"></i>
              </a>
              <a
                *ngIf="authUser?.hasCompanyPermission('administer_company') && auditingEnabled"
                ssiTooltip="{{ 'OUTBOX_AUDIT' | translate }}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                (click)="openAudit()"
              >
                <i class="ssi ssi-historyaudit"></i>
              </a>
              <a
                ssiTooltip="Edit Post"
                (click)="triggerPublish({edit: {post: post}})"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                [hidden]="post.deleted_at"
              >
                <i class="ssi ssi-correctedit"></i>
              </a>
              <a
                ssiTooltip="{{post.campaign_id ? 'Change Campaign' : 'Add Campaign'}}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                (click)="showCampaignModal()"
              >
                <i class="ssi ssi-campaign"></i>
              </a>
              <a
                href="javascript:;"
                (click)="triggerPublish({create: {copy: post}})"
                ssiTooltip="{{ 'COPY' | translate }}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-copy-paste"></i>
              </a>
              <a
                uiSref="auth.inbox"
                [uiParams]="{activity: post.activity_id }"
                [uiOptions]="{reload: true}"
                (click)="closeModal.emit()"
                *ngIf="post.activity_id"
                ssiTooltip="View in inbox"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-toolbar-inbox"></i>
              </a>
              <a
                *ngIf="post.canEditAutoComment()"
                ssiTooltip="Edit first comment"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                [tooltipWindowClass]="'tooltip-chunky'"
                (click)="editAutoComment(post)"
              >
                <i class="ssi ssi-first-comment-temporary"></i>
              </a>
              <a
                *ngIf="post.last_attempt_at && !post.social_id"
                ssiTooltip="Retry"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
                [tooltipWindowClass]="'tooltip-chunky'"
                (click)="retryFailed(post.id)"
              >
                <i class="fa fa-repeat"></i>
              </a>

              <i class="fa fa-ellipsis-v ssi-lg text-primary"></i>
            </div>
          </div>

          <h4 class="media-heading">
            <a
              href="javascript:;"
              class="text-primary font-weight-normal"
              (click)="viewProfile({accountId: post.account.id, profileId: post.account.social_id, loadFeed: true}); $event.stopPropagation()"
            >
              <i [ngClass]="post.account.socialNetwork.icon.web"></i> {{ post.account.displayName }}
            </a>
            <small class="font-weight-normal">
              @{{ post.account.username }}
            </small>
            <small *ngIf="post?.rejected_by">
              <span
                ssiTooltip="Disapproved by: {{ post?.rejectedBy?.fullName }} {{post?.validationTags.length > 0 ? ':' + post?.validationTags : ''}}"
                tooltipPlacement="bottom"
                [tooltipAppendToBody]="true"
                class="fa-stack"
              >

                <i class="fa fa-circle fa-stack-2x rejected"></i>
                <i class="fa fa-times fa-inverse fa-stack-1x"></i>
              </span>
            </small>
            <small>
              <span
                *ngIf="post?.is_validated && post.validator_id"
                class="fa-stack"
                [ssiTooltip]="'APPROVED_BY__NAME_' | translate:{name: post.validator?.fullName}"
                tooltipPlacement="bottom"
                [tooltipAppendToBody]="true"
                [hidden]="!post?.is_validated && post.validator_id"
              >
                <i class="fa fa-circle fa-stack-2x approved"></i>
                <i class="fa fa-check fa-inverse fa-stack-1x"></i>
              </span>
              <span
                *ngIf="canManagePost()"
                class="unvalidated"
              >Awaiting approval</span>
              <span
                *ngIf="post.last_attempt_at && !post.social_id"
                class="outbox-post-failed-chip"
              >
                <i class="ssi ssi-delete-close"></i>
                <span class="outbox-post-failed-chip-label">Failed Post</span>
              </span>
              <span
                *ngIf="post.in_reply_to_social_id || post.is_thread_parent"
                class="twitter-thread-badge"
                uiSref="auth.twitterThreads"
                [uiParams]="{outboxId: post.id }"
                (click)="$event.stopPropagation()"
              >
                <span class="twitter-thread-badge-icon">
                  <i class="ssi ssi-x-logo"></i>
                </span>
                <p>View Thread</p>
              </span>
              <span
                *ngIf="advert?.is_validated && advert.validated_by"
                class="fa-stack"
                [ssiTooltip]="'ADVERT_VALIDATED_BY__NAME_' | translate:{name: advert?.validatedBy?.fullName}"
                tooltipPlacement="bottom"
                [tooltipAppendToBody]="true"
                [hidden]="!advert?.is_validated && advert?.validated_by"
              >
                <i class="fa fa-circle fa-stack-2x text-warning"></i>
                <i class="fa fa-check fa-inverse fa-stack-1x"></i>
              </span>
              <span
                *ngIf="post.campaign_id && minimal"
                class="outbox-post-stat"
                [ssiTooltip]="'PART_OF_THE__CAMPAIGNNAME__CAMPAIGN' | translate:{campaignName: post.campaign?.name}"
                tooltipPlacement="top"
                [tooltipAppendToBody]="true"
              >
                  <i class="ssi ssi-campaign"></i>
              </span>
            </small>
            <div class="media-sub-heading">
              <small class="font-weight-normal children-separated">
                <span *ngIf="!post.deleted_at">
                  <i class="text-primary ssi ssi-fw ssi-person-outline"></i> {{ post.author?.fullName }}
                </span>
                <span *ngIf="!post.deleted_at">{{ (post.sent_at || post.send_at) | date:'d MMM yyyy' }}</span>
                <span *ngIf="!post.deleted_at">{{ (post.sent_at || post.send_at) | date:'H:mm' }}</span>
                <span *ngIf="post.deleted_at">
                  <i class="text-danger fa fa-trash-o"></i> Deleted at {{ post.deleted_at | date:'MMM d yyyy H:mm' }} by
                  <i class="ssi ssi-person-outline"></i> {{ post.deletedBy?.fullName || 'Unknown user' }}
                </span>
              </small>
            </div>
          </h4>
          <p class="media-content">
            <em
              class="text-wrap font-weight-normal"
              [ssiEmojiText]="post.text | escapeHtml | nl2br | htmlToText | linkify: { mentions: mentions }"
            ></em>
          </p>
          <div class="outbox-post-btns">
            <ng-container *ngIf="!post.deleted_at && !post.sent_at && !post.social_id">
              <a
                href="javascript:;"
                class="outbox-post-stat"
                (click)="showNotes(); $event.stopPropagation()"
                *ngIf="!minimal"
                [ssiTooltip]="'THIS_POST_HAS__COUNT__NOTES_ATTACHED' | translate:{count: post.notes?.length || post.note_count}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-feather-note"></i> <span
                  class="outbox-post-stat-count">{{ post.notes?.length || post.note_count }}</span>
              </a>
              <div class="tags-container"
                   *ngIf="!minimal"
                   (click)="$event.stopPropagation()">
                <ng-container *ngTemplateOutlet="tagsMenuTemplate; context: { postLive: false }"></ng-container>
              </div>
              <span
                class="outbox-post-stat"
                *ngIf="post.campaign_id"
                [ssiTooltip]="'PART_OF_THE__CAMPAIGNNAME__CAMPAIGN' | translate:{campaignName: post.campaign?.name}"
                [tooltipPositionFixed]="true"
                [tooltipAppendToBody]="true"
              >
                <i class="ssi ssi-campaign"></i>
              </span>
              <span *ngIf="advert">
                <span
                  class="outbox-post-stat"
                  #adTargetingTooltip="ssiTooltip"
                  [ssiTooltip]
                  [tooltipTemplate]="adTargetingTooltipTemplate"
                  [tooltipPositionFixed]="true"
                  [tooltipAppendToBody]="true"
                >
                  <i class="fa fa-users"></i>
                </span>
                <span
                  class="outbox-post-stat"
                  #adSpendTooltip="ssiTooltip"
                  [ssiTooltip]
                  [tooltipTemplate]="adSpendTooltipTemplate"
                  [tooltipPositionFixed]="true"
                  [tooltipAppendToBody]="true"
                >
                  <i class="fa fa-money"></i>
                </span>
              </span>
              <button
                class="btn btn-info btn-xs btn-rounded"
                *ngIf="!post?.is_validated && !authUser?.hasAccountPermission([post.account_id], [['post'], ['post_un-validated'], ['validate_posts']])"
              >
                <span translate="WAITING_FOR_APPROVAL"></span>
              </button>
              <button
                class="btn btn-success btn-xs btn-rounded"
                *ngIf="canManagePost()"
                (click)="approve(); $event.stopPropagation()"
              >
                <span translate="APPROVE"></span>
              </button>
              <button
                class="btn btn-danger btn-xs btn-rounded"
                *ngIf="canManagePost()"
                (click)="disapprove(); $event.stopPropagation()"
              >
                <span translate="DISAPPROVE"></span>
              </button>
              <a
                uiSref="auth.reAuthAccount"
                class="btn btn-inverse btn-xs btn-rounded"
                ssiTooltip="{{ 'ACCOUNT_REAUTHENTICATION_REQUIRED_BEFORE_THIS_POST_CAN_BE_PUBLISHED' | translate }}"
                tooltipPlacement="top"
                [tooltipAppendToBody]="true"
                *ngIf="!post?.is_validated && !post.deleted_at && post.account.is_reauth_required"
              >
                <span translate="REAUTH_REQUIRED"></span>
              </a>
            </ng-container>
            <button
              class="btn btn-warning btn-xs btn-rounded"
              *ngIf="advert && !advert?.is_validated && authUser.hasAccountPermission(advert.account_id, 'validate_ad')"
              uiSref="auth.marketing.validatePosts"
            >
              <span translate="VALIDATE_ADVERT"></span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="media-right media-middle"
        *ngIf="post.outbox_files.length > 0"
      >
        <a
          [href]="post.outbox_files[0].public_url"
          target="_blank"
          *ngIf="post.type === OutboxMessageType.Video || post.type === OutboxMessageType.ReplyVideo || ((post.type === OutboxMessageType.InstagramStory || post.type === OutboxMessageType.InstagramReel) && hasVideo)"
        >
          <i class="ssi ssi-video-circle ssi-5x"></i>
        </a>
        <img
          *ngIf="post.type === OutboxMessageType.Picture || post.type === OutboxMessageType.ReplyPicture || ((post.type === OutboxMessageType.InstagramStory || post.type === OutboxMessageType.InstagramReel) && hasImage)"
          [src]="post.outbox_files[0].public_url"
          (click)="$event.stopPropagation()"
          ssiFallbackImage="brokenImageNew"
          ssi-enlarge-image
        >
        <ssi-media-nav
          class="nav-btn-sm"
          *ngIf="post.outbox_files.length > 1"
          [files]="post.outbox_files"
          [fileKey]="'public_url'"
          [activeFileIndex]="activeImageIndex"
          (activeFileIndexChange)="activeImageIndex = $event"
          (click)="$event.stopPropagation()"
        >
        </ssi-media-nav>
      </div>
    </div>
    <div
      class="outbox-post-stats-container"
      *ngIf="!post.deleted_at && !minimal && post.sent_at && post.social_id"
    >
      <ssi-outbox-post-stats
        *ngIf="post.sent_at && post.social_id"
        [post]="post"
        [clicks]="clicks"
      >
      </ssi-outbox-post-stats>
      <div class="tags-container"
           (click)="$event.stopPropagation()">
        <ng-container *ngTemplateOutlet="tagsMenuTemplate; context: { postLive: true }"></ng-container>
      </div>
      <span *ngIf="true">
        <span
          class="outbox-post-stat"
          #adTargetingTooltip="ssiTooltip"
          [ssiTooltip]
          [tooltipTemplate]="adTargetingTooltipTemplate"
          tooltipPlacement="top"
          [tooltipAppendToBody]="true"
        >
          <i class="fa fa-users"></i>
        </span>
        <span
          class="outbox-post-stat"
          #adSpendTooltip="ssiTooltip"
          [ssiTooltip]
          [tooltipTemplate]="adSpendTooltipTemplate"
          tooltipPlacement="top"
          [tooltipAppendToBody]="true"
        >
          <i class="fa fa-money"></i>
        </span>
      </span>
    </div>
    <ng-template #adTargetingTooltipTemplate>
      <div
        class="ad-budget-tooltip"
        (ssiOffClick)="adTargetingTooltip.hide()"
      >
        {{adTooltips.targeting}}
      </div>
    </ng-template>
    <ng-template #adSpendTooltipTemplate>
      <div
        class="ad-budget-tooltip"
        (ssiOffClick)="adSpendTooltip.hide()"
      >
        {{adTooltips.spend}}
      </div>
    </ng-template>
  </div>
</div>

<ng-template #publisherConfrimationModalTemplate let-close="close">
  <div class="pub-confirm-modal">
    <div class="pub-confirm-modal-header">
      <div class="pub-confirm-modal-header-left">
        <h2>Confirm Navigation</h2>
      </div>
      <div class="pub-confirm-modal-header-right">
        <button (click)="close(false)" class="pub-confirm-modal-close">
          <i class="ssi ssi-addsmall"></i>
        </button>
      </div>
    </div>

    <div class="pub-confirm-modal-body">
      <p>If you continue with the scheduled post, the original post will be lost.</p>
      <p>Do you want to continue?</p>
    </div>

    <div class="pub-confirm-modal-footer">
      <div class="pub-confirm-modal-footer-left"></div>
      <div class="pub-confirm-modal-footer-right">
        <orlo-button
          class="pub-confirm-modal-button pub-confirm-modal-button-secondary"
          text="No"
          textColor="#43537F"
          bgColor="#fff"
          (click)="close(false)"
        ></orlo-button>
        <orlo-button
          class="pub-confirm-modal-button"
          text="Yes"
          textColor="#fff"
          bgColor="#14BAE3"
          (click)="close(true)"
        ></orlo-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tagsMenuTemplate
             let-postLive="postLive">
  <ssi-dropdown-select-2 #tagsSelector
                         [options]="tags"
                         [filterable]="true"
                         [multiple]="true"
                         [toggleAllEnabled]="false"
                         [filterPlaceholder]="'Type post tags...'"
                         [(ngModel)]="selectedTags"
                         (ngModelChange)="postTagsChange($event)"
                         (optionsFiltered)="onOptionsFiltered($event)">
    <ng-template ssiTemplateSelector="headButton"
                 let-expanded="expanded"
                 let-toggle="toggle">
      <a href="javascript:;"
         class="outbox-post-stat"
         (click)="toggle();"
         [ssiTooltip]="getTagsTooltip()"
         [tooltipPositionFixed]="true"
         [tooltipAppendToBody]="true">
        <i class="ssi ssi-tag-test"></i> <span class="outbox-post-stat-count">{{ selectedTags.length }}</span>
      </a>
    </ng-template>

    <ng-template ssiTemplateSelector="emptyState"
                 let-toggle="toggle">
      <div class="no-tags-state"
           *ngIf="!tagsSelector.filterTerm">
        <i class="ssi ssi-tags"></i>
        <p class="title">No Post Tags yet!</p>
        <p class="desc">Just start typing then create your new tags</p>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="bodyFooter"
                 let-toggle="toggle">
      <div class="no-tags-state"
           *ngIf="tagsSelector.filterTerm && createTagButtonVisible">
        <button type="button"
                class="g-btn"
                *ngIf="tagsSelector.filterTerm"
                (click)="createTag(tagsSelector.filterTerm)"><span>Create "{{tagsSelector.filterTerm}}"</span><i class="ssi ssi-plus"></i></button>
      </div>
    </ng-template>
  </ssi-dropdown-select-2>
</ng-template>