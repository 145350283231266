import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OutboxNotesModalComponent } from './outbox-notes-modal.component';
import { NotesModule } from '../notes/notes.module';
import { NotesTextareaModule } from '../notes-textarea/notes-textarea.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NotesTextareaModule,
    NotesModule
  ],
  declarations: [OutboxNotesModalComponent],
  exports: [OutboxNotesModalComponent]
})
export class OutboxNotesModalModule {}
