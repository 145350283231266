import { Pipe, PipeTransform } from '@angular/core';
import twitterText from 'twitter-text';

@Pipe({
  name: 'socialPostCharactersRemaining'
})
export class SocialPostCharactersRemainingPipe implements PipeTransform {
  transform(input: string, maxLength: number, hasTwitter: boolean): number {
    return this.socialPostCharactersRemaining(input, maxLength, hasTwitter);
  }

  socialPostCharactersRemaining(
    input: string,
    maxLength: number,
    hasTwitter: boolean
  ): number {
    if (!input || input.length === 0) {
      return maxLength;
    }
    if (hasTwitter) {
      return maxLength - twitterText.parseTweet(input).weightedLength;
    } else {
      return maxLength - input.length;
    }
  }
}
