import './report.component.scss';

import {
  Component,
  Input,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

import { AccountModel, Account, Outbox } from '@ui-resources-angular';
import { isElementWithinViewport } from '../../../../../common/utils';
import { Debounce } from '../../../../../common/decorators';
import {
  FileDownloadService,
  SpreadsheetService
} from '../../../../../common/services';
import {
  FileMimeTypes,
  GlobalLoaderService
} from '../../../../../common/directives';
import {
  commonDateRanges,
  compareToOptions
} from '../../../../../common/constants';
import {
  AnalyticsService,
  LinkClicksData,
  InstagramStoryStatsResponse,
  VideoMetricStatsResponse
} from '../../analytics.service';
import {
  MarketingService,
  DateRanges,
  TotalsStats,
  TimeSeriesStats
} from '../marketing.service';
import { BrowserlessPDFExportService } from '../../../../../common/services/browserless-pdf-export/browserless-pdf-export.service';
import { ReportAutomationFrequency } from '../../../../../common/services/api/automated-reports';
import { AutomateModalComponent } from '../../../../../common/components/automate-modal/automate-modal.component';
import { schedulesAutomatedReportsFeatureResolveFn } from '../../common/resolves/analytics-resolves';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { AccountTypeId } from '../../../../../common/enums';

@Component({
  selector: 'ssi-report',
  templateUrl: './report.component.html',
  styles: []
})
export class ReportComponent implements OnInit {
  static resolve = [
    {
      token: 'schedulesAutomatedReportsFeature',
      resolveFn: schedulesAutomatedReportsFeatureResolveFn,
      deps: [CompanyService]
    }
  ];
  @Input() schedulesAutomatedReportsFeature = false;

  reportActions;
  navItems = this.getNavItems();
  activeNavItemTag = 'account-summary';

  accountIds: string[] = [];
  accounts: Account[] = [];

  dateRanges: DateRanges;

  tags;
  tagsToInclude = [];
  tagIdsToExclude = [];

  totalsStats: TotalsStats;
  timeSeriesStats: TimeSeriesStats;
  linkClicksStats: LinkClicksData;
  topTimesToPostStats: any;
  linkClicksLocationStats: { lat: number; lng: number }[];
  topPosts: Outbox[];
  videoMetricsCurrentStats: VideoMetricStatsResponse;
  videoMetricsPreviousStats: VideoMetricStatsResponse;
  instagramStoriesCurrentStats: InstagramStoryStatsResponse = {
    count_stories: 0,
    sum_likes: 0,
    sum_impressions: 0,
    sum_reach: 0,
    sum_taps_forward: 0,
    sum_taps_back: 0,
    sum_exits: 0
  };
  instagramStoriesPreviousStats: InstagramStoryStatsResponse;

  loading = false;

  constructor(
    public state: StateService,
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    protected analyticsService: AnalyticsService,
    protected marketingService: MarketingService,
    protected spreadsheetService: SpreadsheetService,
    protected fileDownloadService: FileDownloadService,
    protected globalLoader: GlobalLoaderService,
    protected accountModel: AccountModel,
    protected browserlessPDFExportService: BrowserlessPDFExportService,
    private modal: NgbModal
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    if (this.state.params.disablePopup) {
      this.renderer.addClass(window.document.body, 'hide-pendo-popup');
    }
    this.reportActions = this.getReportActions();
    this.setAccounts();
    this.setDateRanges();
    this.setTags();
    await this.loadReports();
  }

  async loadReports(): Promise<void> {
    const instagramAccountIds = this.accounts
      .filter((acc) => acc.account_type_id === String(AccountTypeId.Instagram))
      .map((a) => a.id);

    try {
      const totalsStatsPromise = this.marketingService.loadTotals(
        this.accountIds,
        this.dateRanges,
        this.tagsToInclude,
        this.tagIdsToExclude
      );

      const timeSeriesStatsPromise = this.marketingService.loadTimeSeries(
        this.accountIds,
        this.dateRanges,
        this.tagsToInclude,
        this.tagIdsToExclude
      );

      const linkClicksStatsPromise = this.analyticsService.getLinkClicksData(
        this.dateRanges.current as any,
        undefined,
        undefined,
        this.accountIds,
        false,
        this.tagsToInclude,
        this.tagIdsToExclude
      );

      const topTimesToPostStatsPromise = this.marketingService.getTopPostTimes(
        this.accountIds,
        this.dateRanges,
        ['clicks', 'shares', 'comments', 'likes'],
        false,
        this.tagsToInclude,
        this.tagIdsToExclude
      );

      const linkClicksLocationStatsPromise = this.marketingService.getLinkClicksLocations(
        this.accountIds,
        this.dateRanges,
        this.tagsToInclude,
        this.tagIdsToExclude
      );

      const videoMeticsCurrentStatsPromise = this.analyticsService.getVideoMetrics(
        this.accounts.length
          ? this.accounts.map((account) => account.id)
          : null,
        null,
        this.dateRanges.current,
        this.tagsToInclude,
        this.tagIdsToExclude
      );

      const [
        totalsStats,
        timeSeriesStats,
        linkClicksStats,
        topTimesToPostStats,
        linkClicksLocationStats,
        videoMeticsCurrentStats
      ] = await Promise.all([
        totalsStatsPromise,
        timeSeriesStatsPromise,
        linkClicksStatsPromise,
        topTimesToPostStatsPromise,
        linkClicksLocationStatsPromise,
        videoMeticsCurrentStatsPromise
      ]);
      this.totalsStats = totalsStats;
      this.timeSeriesStats = timeSeriesStats;
      this.linkClicksStats = linkClicksStats;
      this.topTimesToPostStats = topTimesToPostStats;
      this.linkClicksLocationStats = linkClicksLocationStats;
      this.videoMetricsCurrentStats = videoMeticsCurrentStats;

      if (this.dateRanges.previous.start) {
        const videoMetricsPreviousStatsPromise = this.analyticsService.getVideoMetrics(
          this.accounts.length
            ? this.accounts.map((account) => account.id)
            : null,
          null,
          this.dateRanges.previous,
          this.tagsToInclude,
          this.tagIdsToExclude
        );
        const videoMetricsPreviousStats = await Promise.resolve(
          videoMetricsPreviousStatsPromise
        );
        this.videoMetricsPreviousStats = videoMetricsPreviousStats;
      }

      if (instagramAccountIds.length) {
        const instagramStoriesCurrentStatsPromise = await this.analyticsService.getInstagramStoryMetrics(
          instagramAccountIds,
          null,
          this.dateRanges.current,
          this.tagsToInclude,
          this.tagIdsToExclude
        );

        const instagramStoriesCurrentStats = await Promise.resolve(
          instagramStoriesCurrentStatsPromise
        );
        this.instagramStoriesCurrentStats = instagramStoriesCurrentStats;

        if (this.dateRanges.previous.start) {
          const instagramStoriesPreviousStatsPromise = await this.analyticsService.getInstagramStoryMetrics(
            instagramAccountIds,
            null,
            this.dateRanges.previous,
            this.tagsToInclude,
            this.tagIdsToExclude
          );

          const instagramStoriesPreviousStats = await Promise.resolve(
            instagramStoriesPreviousStatsPromise
          );
          this.instagramStoriesPreviousStats = instagramStoriesPreviousStats;
        }
      }

      this.populateNavItemElements();
      this.loading = false;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);
      window.history.back();
      return;
    }

    // console.log('totalsStats: ', this.totalsStats);
    // console.log('linkClicksLocationStats: ', this.linkClicksLocationStats);
    // console.log('timeSeriesStats: ', this.timeSeriesStats);
  }

  setAccounts(): void {
    this.accountIds = Array.isArray(this.state.params.accountIds)
      ? this.state.params.accountIds
      : [this.state.params.accountIds];

    this.accounts = this.accountModel
      .filter({
        where: { id: { in: this.accountIds } }
      })
      .sort((a1, a2) => Number(a1.id) - Number(a2.id));
  }

  setDateRanges(): void {
    if (this.state.params.frequency) {
      // override report dates - frequency is set for automated reports (headless browser running on the server)
      const period =
        this.state.params.frequency === ReportAutomationFrequency.WEEKLY
          ? 'week'
          : 'month';

      const dateRange = commonDateRanges[period];
      this.state.params.startDate = dateRange.start.toISOString();
      this.state.params.endDate = dateRange.end.toISOString();

      const compareToOption = compareToOptions[this.state.params.compareTo];
      if (
        compareToOption &&
        compareToOption !== compareToOptions.custom &&
        this.state.params.previousStartDate &&
        this.state.params.previousEndDate
      ) {
        const compareToDateRange = compareToOption.getCompareToDates(
          new Date(this.state.params.startDate),
          new Date(this.state.params.endDate)
        );
        this.state.params.previousStartDate = compareToDateRange.start.toISOString();
        this.state.params.previousEndDate = compareToDateRange.end.toISOString();
      }

      this.state.params.frequency = undefined;
      this.state.go(this.state.current, this.state.params, { notify: false });
    }

    this.dateRanges = {
      current: {
        start: new Date(this.state.params.startDate),
        end: new Date(this.state.params.endDate)
      },
      previous: {
        start:
          this.state.params.previousStartDate &&
          new Date(this.state.params.previousStartDate),
        end:
          this.state.params.previousEndDate &&
          new Date(this.state.params.previousEndDate)
      }
    };
  }

  setTags(): void {
    if (this.state.params.tagIdsToInclude) {
      this.tagsToInclude = Array.isArray(this.state.params.tagIdsToInclude)
        ? this.state.params.tagIdsToInclude
        : [this.state.params.tagIdsToInclude];
    }

    if (this.state.params.tagIdsToExclude) {
      this.tagIdsToExclude = Array.isArray(this.state.params.tagIdsToExclude)
        ? this.state.params.tagIdsToExclude
        : [this.state.params.tagIdsToExclude];
    }

    // this.tags = [
    //   ...this.tagsToInclude.map((tag) => tag),
    //   ...this.tagIdsToExclude.map((tag) => `-${tag}`)
    // ];
  }

  async reportDateChanged(dateRanges): Promise<void> {
    this.dateRanges.current.start = new Date(dateRanges.from);
    this.dateRanges.current.end = new Date(dateRanges.to);

    this.updateCompareToDates();
    await this.loadReports();
  }

  updateCompareToDates(): void {
    const selectedCompareToOption =
      compareToOptions[this.state.params.compareTo];

    if (!selectedCompareToOption.getCompareToDates) {
      return;
    }

    const { start, end } = selectedCompareToOption.getCompareToDates(
      this.dateRanges.current.start,
      this.dateRanges.current.end
    );
    this.dateRanges.previous.start = start;
    this.dateRanges.previous.end = end;
    this.analyticsService.updateUrlParams({
      startDate: this.dateRanges.current.start,
      endDate: this.dateRanges.current.end
    });
  }

  onTopPostsLoaded(posts: Outbox[]): void {
    this.topPosts = posts;
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });

      this.setActiveNavItem();
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Account Summary',
        tag: 'account-summary',
        element: undefined
      },
      {
        label: 'Video Metrics',
        tag: 'video-metrics',
        element: undefined
      },
      {
        label: 'Instagram Stories',
        tag: 'instagram-stories',
        element: undefined
      },
      {
        label: 'Link Clicks',
        tag: 'link-clicks',
        element: undefined
      },
      {
        label: 'Organic Reach',
        tag: 'organic-reach',
        element: undefined
      },
      {
        label: 'Brand Sentiment',
        tag: 'brand-sentiment',
        element: undefined
      },
      {
        label: 'Followers',
        tag: 'followers',
        element: undefined
      },
      {
        label: 'Account Engagement',
        tag: 'post-engagement',
        element: undefined
      },
      {
        label: 'Top times to post',
        tag: 'top-times-to-post',
        element: undefined
      },
      {
        label: 'Top Posts',
        tag: 'top-posts',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      {
        icon: 'ssi-print',
        label: 'Export PDF',
        func: async () => {
          if (this.loading) {
            console.log('still loading');
            return;
          }
          this.openBrowserPrint();
        }
      },
      {
        icon: 'ssi-print',
        label: 'Download XLSX',
        func: this.downloadXlsx.bind(this)
      },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => {
          this.state.go('auth.analytics.marketing.build', this.state.params);
        }
      },
      {
        icon: 'ssi ssi-small-schedule',
        label: 'Automate report',
        featureFlag: this.schedulesAutomatedReportsFeature,
        func: () => {
          this.openAutomateReportModal();
        }
      }
    ].filter(
      (action) => !action.hasOwnProperty('featureFlag') || action.featureFlag
    );
  }

  async downloadXlsx(): Promise<void> {
    this.globalLoader.show();
    try {
      const topPosts =
        this.topPosts ||
        (await this.analyticsService.loadTopPosts(
          this.dateRanges.current,
          'clicks',
          this.accountIds,
          null,
          null,
          null,
          this.tagsToInclude,
          this.tagIdsToExclude
        ));

      const spreadsheetData = this.marketingService.createSpreadsheetExport(
        this.accounts,
        this.dateRanges,
        this.totalsStats,
        this.timeSeriesStats,
        this.linkClicksStats,
        this.topTimesToPostStats,
        topPosts,
        {
          currentStats: this.videoMetricsCurrentStats,
          previousStats: this.videoMetricsPreviousStats
        },
        {
          currentStats: this.instagramStoriesCurrentStats,
          previousStats: this.instagramStoriesPreviousStats
        }
      );

      const xlsx = await this.spreadsheetService.jsonToXlsx(spreadsheetData);

      const downloadConfig = {
        base64decode: true,
        filename: `Orlo - Marketing report.xlsx`,
        mimetype: FileMimeTypes.Xlsx
      };

      this.fileDownloadService.download(xlsx, downloadConfig);
    } catch (e) {
      console.error('Error generating report: ', e);
    }
    this.globalLoader.hide();
  }

  openBrowserPrint() {
    window.print();
  }

  async openAutomateReportModal() {
    const automateModal = this.modal.open(AutomateModalComponent, {
      windowClass: 'orlo-modal'
    });
    automateModal.componentInstance.editMode = false;
    automateModal.componentInstance.reportUrl = this.state.href(
      this.state.current.name,
      this.state.params,
      { absolute: true }
    );
  }

  exportPDF() {
    // this.browserlessPDFExportService.downloadPDF(
    //   window.location.href,
    //   'orlo-marketing-report.pdf'
    // );
  }
}
