import './spend-breakdown.component.scss';

import { Component, Input, OnInit } from '@angular/core';

import { CURRENCY_SYMBOLS } from '../../../../../../common/constants';
import { isNumeric } from '../../../../../../common/utils';
import { Report } from '../../ad-stats.service';

@Component({
  selector: 'ssi-spend-breakdown',
  templateUrl: './spend-breakdown.component.html',
  styles: []
})
export class SpendBreakdownComponent implements OnInit {
  @Input() report: Report;

  stats;
  currencySymbol: string;

  constructor() {}

  ngOnInit() {
    this.stats = this.report.totalsStats.totals;
    this.currencySymbol =
      CURRENCY_SYMBOLS[
        this.report.totalsStats.allTotals.current.total_spend.currency
      ];
  }

  isNumber(value: any): boolean {
    return isNumeric(value);
  }

  splitColumnFieldValue(field: string): string {
    return field.split('.')[1];
  }
}
