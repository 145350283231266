import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentimentComponent } from './sentiment.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { BigNumberModule } from '../../../common/pipes/big-number/big-number.module';
import { SentimentTextModule } from './sentiment-text/sentiment-text.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    BigNumberModule,
    TooltipModule,
    SentimentTextModule
  ],
  declarations: [SentimentComponent],
  exports: [SentimentComponent]
})
export class SentimentModule {}
