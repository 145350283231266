<div class="publisher-height-spacer" *ngIf="isActive" [@slideInOut]></div>

<div
  class="publisher-body-container"
  [@slideInOut]
  (@slideInOut.done)="slideAnimationCompleted = !slideAnimationCompleted"
  *ngIf="isActive && post">
  <div class="publisher-body" *ngIf="!loadingTrackers.initial.active">
    <ssi-publisher-landing
      *ngIf="stage === stages.Landing"
      [post]="post"
      [accounts]="accounts"
      [campaigns]="campaigns"
      [disable]="disable"
      [slideAnimationCompleted]="slideAnimationCompleted"
      [publishingLoadingTracker]="loadingTrackers.publishing"
      [postInvalidTooltipTemplate]="postInvalidTooltipTemplate"
      [fileUploadSources]="fileUploadSources"
      (changeStage)="changeStage($event.stage)"
      (publish)="publish()"
      (saveAsDraft)="saveAsDraft()"
      (showPostPreview)="showPostPreview()"
      (fileUploadSuccess)="fileUploadSuccess($event)"
      (fileUploadError)="fileUploadError($event)">
    </ssi-publisher-landing>
    <ssi-publisher-schedule
      *ngIf="stage === stages.Schedule"
      [post]="post"
      [postInvalidTooltipTemplate]="postInvalidTooltipTemplate"
      [publishingLoadingTracker]="loadingTrackers.publishing"
      [disable]="disable"
      [campaigns]="allCampaigns"
      (changeStage)="changeStage($event.stage)"
      (publish)="publish()"
      (showPostPreview)="showPostPreview()">
    </ssi-publisher-schedule>
  </div>
  <div class="publisher-body" *ngIf="loadingTrackers.initial.active">
    <div class="loading"></div>
  </div>
</div>

<ng-template #postInvalidTooltipTemplate>
  <div class="tooltip-icon tooltip-icon-padding">
    <i class="ssi ssi-forbidden"></i>
  </div>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      First things first...
    </div>
    <div class="tooltip-body-contents">
      <div>
        Please correct the following errors:
        <ul class="list-no-padding spacer-top text-primary">
          <li *ngIf="post.validity.errors.videoRequired">You must attach a video to the post</li>
          <li *ngIf="post.validity.errors.linkRequired">You must include one link in the post</li>
          <li *ngIf="post.validity.errors.videoTitleRequired">You must include a video title in the post</li>
          <li *ngIf="post.validity.errors.gifTitleRequired">You must include a gif title in the post</li>
          <li *ngIf="post.validity.errors.textRequired">
            <span *ngIf="!post.isSplit">The post must include some text</span>
            <span *ngIf="post.isSplit">This post has been split between social networks - you must include text for each account.</span>
          </li>
          <li *ngIf="post.validity.errors.accountsRequired">You need to select at least one account, write your post and add content before you can preview your post.</li>
          <li *ngIf="post.validity.errors.characterLimitExceeded">Your post exceeds the character count</li>
          <li *ngIf="post.validity.errors.mediaRequired">You must attach either an image or a video</li>
          <li *ngIf="post.validity.errors.linksAlreadyShortened">Some of the links you have added have already been shortened</li>
          <li *ngIf="post.validity.errors.albumCannotBeMultiScheduled">You cannot schedule a post with multiple images more than once</li>
          <li *ngIf="post.validity.errors.duplicatePostText">
            In order to comply with social network spamming rules, all accounts of the same network must have different text. The following accounts have the same text:
            <ul class="list-no-padding" *ngFor="let accountGroup of post.validity.duplicatePostTextAccounts">
              <li *ngFor="let account of accountGroup.accounts">
                <i class="fa-fw" [ngClass]="account.socialNetwork.icon.web"></i> {{ account.displayName }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div data-popper-arrow class="tooltip-arrow"></div>
</ng-template>

<ng-template #postInvalidCharacters let-close="close">
  <div class="modal-popup">
    <div class="modal-header">
      <button class="close pull-right" (click)="close()">
        <i class="fa fa-times"></i>
      </button>
    </div>
      
    <div class="modal-body">
      <h2 class="modal-title">Your post contains illegal characters</h2>
      <p>These characters have been removed.</p>
      <div class="modal-action-buttons">
        <button
          type="button"
          class="btn btn-primary"
          (click)="close()">
          Ok
        </button>
      </div>
      
    </div>
  </div>
  
</ng-template>


<ng-template #draftSavedMessageTemplate>
  <p>
    Your draft has been saved! You can find it in your <a uiSref="auth.marketing.draftsLibrary" [uiOptions]="{reload: true}">Drafts Library</a>
  </p>
</ng-template>