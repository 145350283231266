import './account-selector.component.scss';
import { Component, Input, forwardRef, ViewChild } from '@angular/core';
import { Account } from '@ui-resources-angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-account-selector',
  templateUrl: './account-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountSelectorComponent), //tslint:disable-line
      multi: true
    }
  ]
})
export class AccountSelectorComponent implements ControlValueAccessor {
  @Input() selectedAccount: Account;
  @Input() accounts: Account[];
  @Input() title: string;
  @Input() multiple = false; // todo
  @Input() required = false; // todo
  @Input() idsOnly = false; // todo
  @ViewChild('accountSelectorDropdown')
  public accountSelectorDropdown: NgbDropdown;
  filterTerm = '';
  filteredAccounts: Account[];
  trackById = (index: number, account: Account) => account.id;

  constructor() {}

  public sortAccountsFn(account1: Account, account2: Account): number {
    return account1.account_type_name > account2.account_type_name
      ? 1
      : account1.account_type_name < account2.account_type_name
      ? -1
      : account1.name < account2.name
      ? -1
      : account1.name > account2.name
      ? 1
      : 0;
  }

  public accountChange(account: Account) {
    this.accountSelectorDropdown.close();
    this.selectedAccount = account;
    this.propagateChange(account);
  }

  private onChangeCallback: (_: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any) {
    this.selectedAccount = value;
    this.onChangeCallback(value);
  }

  private propagateChange = (_: any) => {};
}
