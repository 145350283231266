<div class="automate-modal">
  <div class="automate-modal-header">
    <div class="automate-modal-header-left">
      <h2 *ngIf="!editMode">Share &amp; Automate your report</h2>
      <h2 *ngIf="editMode">Edit Automation of '{{reportName}}'</h2>
    </div>
    <div class="automate-modal-header-right">
      <button (click)="activeModal.close(false)" class="automate-modal-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="automate-modal-body">
    <div class="automate-modal-body-row">
      <label class="automate-modal-label" for="email">Email To:</label>
      <div class="automate-modal-body-row-input">
        <input
          class="automate-modal-input"
          id="email"
          type="text"
          [disabled]="false"
          [(ngModel)]="emailTo"
          [placeholder]="'Enter multiple addresses seperated by commas...'"
        >
        <ssi-checkbox-2
          class="automate-modal-checkbox"
          name="copy"
          label="Send me a copy"
          variant="square"
          [reversed]="true"
          [(ngModel)]="copy"
          (ngModelChange)="copy = $event"
        ></ssi-checkbox-2>
      </div>
    </div>

    <div class="automate-modal-body-row">
      <label class="automate-modal-label" for="reportName">Report Name:</label>
      <div class="automate-modal-body-row-input">
        <input
          class="automate-modal-input"
          id="reportName"
          type="text"
          [disabled]="false"
          [(ngModel)]="reportName"
        >
      </div>
    </div>

    <div class="automate-modal-body-row">
      <label class="automate-modal-label" for="frequency">Frequency:</label>
      <div class="automate-modal-body-row-input">
        <div class="input-wrapper">
          <div
            *ngFor="let option of automatedModalFrequencyOptionsIterable"
            class="inline-select-item"
            [class.selected]="option === selectedAutomatedModalFrequencyOption"
            (click)="selectedAutomatedModalFrequencyOption = option"
          >
            <i
              *ngIf="option === selectedAutomatedModalFrequencyOption"
              class="ssi ssi-custom-date"
            ></i>
            <span>{{option.label}}</span>
            <i
              *ngIf="option === selectedAutomatedModalFrequencyOption"
              class="ssi ssi-tick-small"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="selectedAutomatedModalFrequencyOption">
      <span class="sub-heading">Automation summary:</span>
      <ng-container
        *ngIf="selectedAutomatedModalFrequencyOption === automatedModalFrequencyOptions.WEEKLY"
      >
        A PDF export of your report will be emailed every following Monday. This will include a report of the previous week with the filters that you have added today to this report.
      </ng-container>
      <ng-container
        *ngIf="selectedAutomatedModalFrequencyOption === automatedModalFrequencyOptions.MONTHLY"
      >
      A PDF export of your report will be emailed on the first of every following month. This will include a report of the previous month with the filters that you have added today to this report.
      </ng-container>
    </p>
  </div>

  <div class="automate-modal-footer">
    <div class="automate-modal-footer-left">
      <orlo-button
        *ngIf="editMode"
        class="publisher-expiry-modal-button publisher-expiry-modal-button-secondary"
        text="Edit Report"
        textColor="#43537F"
        bgColor="#fff"
        (click)="linkToReport()"
      ></orlo-button>
    </div>
    <div class="automate-modal-footer-right">
      <orlo-button
        class="automate-modal-button automate-modal-button-secondary"
        text="Cancel"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        class="automate-modal-button"
        [disabled]="!emailTo || !reportName || !selectedAutomatedModalFrequencyOption"
        text="Schedule Report"
        textColor="#fff"
        bgColor="#14BAE3"
        (click)="submit()"
      ></orlo-button>
    </div>
  </div>
</div>