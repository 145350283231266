<div class="social-push-mode-modal-body" *ngIf="isEnabled">
    <i class="ssi ssi-push-mode social-push-mode-modal-icon"></i>
    <h1 class="social-push-mode-modal-heading">Social Push Mode Time!</h1>
    <p class="social-push-mode-modal-meta">Your manager has turned on Social Push Mode,<br>
        going online will automatically start assigning you messages</p>
    <div class="social-push-mode-modal-actions">
        <button
            class="social-push-mode-action social-push-mode-action-secondary"
            (click)="changeAgentStatus('BUSY')">Set to Unavailable</button>
        <button
            class="social-push-mode-action social-push-mode-action-primary"
            (click)="changeAgentStatus('ONLINE')">Go Online</button>
    </div>
</div>

<div class="social-push-mode-modal-body" *ngIf="!isEnabled">
    <i class="ssi ssi-push-mode social-push-mode-modal-icon"></i>
    <h1 class="social-push-mode-modal-heading">Social Push Turned Off!</h1>
    <p class="social-push-mode-modal-meta">Your manager has turned off Social Push Mode,<br>
        you will be redirected to Classic Inbox</p>
    <div class="social-push-mode-modal-actions">
        <button
            class="social-push-mode-action social-push-mode-action-primary"
            (click)="changeAgentStatus('OFFLINE')">OK</button>
    </div>
</div>
    