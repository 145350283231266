<div
  class="as-dropdown-container dropdown-container"
  (ssiOffClick)="cancel()"
>
  <label
    class="as-label"
    translate="KEEP_TYPING_TO_SEARCH_FOR_YOUR_SNIPPET_OF_CHOICE"
  ></label>

  <input
    class="as-filter-input"
    type="text"
    [(ngModel)]="filterTerm"
  >
  <ul class="as-list custom-scrollbar">
    <li
      *ngFor="let snippet of filteredSnippets | sortBy: 'title'; index as i"
      class="as-list-item"
      [ngClass]="{ selected: isSelected(i) }"
      #snippetEl
    >
      <button
        (click)="select(snippet)"
        type="button"
      >
        {{ snippet.title }}
      </button>
    </li>

    <li
      *ngIf="canAdministerCompany"
      class="administer"
    >
      <a uiSref="auth.inboxSettings">
        <i class="ssi ssi-correctedit"></i>

        <span translate="MANAGE_SNIPPETS"></span>
      </a>
    </li>
  </ul>
</div>