import './validate-posts-box.component.scss';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { API, Campaign, Account, Outbox } from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../common/services/api';
import { ValidatePostsDissaproveComponent } from './validate-posts-dissaprove/validate-posts-dissaprove.component';
import { OutboxTagsService } from '../../../../../common/services/api/outbox-tags';
import { CompanyService } from '../../../../../common/services/company/company.service';

@Component({
  selector: 'ssi-validate-posts-box',
  templateUrl: './validate-posts-box.component.html',
  styles: []
})
export class ValidateBoxComponent implements OnInit, OnChanges {
  @Input() validation: Outbox | any;
  @Input() campaigns: Campaign[];
  @Input() teams: Team[];
  @Input() selected: Array<Outbox | any>;
  @Input() accounts: Account[];
  @Input() colleagues: Colleague[];
  @Input() postWithBoost: any;
  @Input() validated: any;
  @Input() selectMode = false;
  @Output() postValidated = new EventEmitter<Outbox | any>();
  @Output() addToSelected = new EventEmitter<Outbox | any>();

  showBoost = false;
  pipe = '&vert;';
  type = 'post';
  validationStatus: string = null;
  scheduledDate: string;
  parentCampaign: Campaign;
  account: Account;
  user: Colleague;
  notes: any[];
  disapprovalTags: { label: string; value: string }[];
  hasValidationTagging: boolean;

  // @ViewChild('outboxPostListModalTemplate')
  // outboxPostListModalTemplate: TemplateRef<any>;

  constructor(
    public modal: NgbModal,
    private api: API,
    protected outboxTagsService: OutboxTagsService,
    protected compayService: CompanyService
  ) {}

  async ngOnInit() {
    try {
      if (!Array.isArray(this.accounts)) {
        throw new Error(
          `Value for 'validate posts box accounts' not in expected format.`
        );
      }

      if (!Array.isArray(this.campaigns)) {
        throw new Error(
          `Value for 'validate posts box campaigns' not in expected format.`
        );
      }

      if (!Array.isArray(this.colleagues)) {
        throw new Error(
          `Value for 'validate posts box colleagues' not in expected format.`
        );
      }

      this.scheduledDate = moment(
        this.validation.outbox
          ? this.validation.outbox.send_at
          : this.validation.send_at
      ).format('D MMM YY H:mm');
      this.parentCampaign = this.campaigns.find((campaign) => {
        const campaignId = this.validation.outbox
          ? this.validation.outbox.campaign_id
          : this.validation.campaign_id;
        return campaignId === campaign.id;
      });
      this.account = this.accounts.find((account) => {
        const accountId = this.validation.outbox
          ? this.validation.outbox.account_id
          : this.validation.account_id;
        return accountId === account.id;
      });
      this.user = this.colleagues.find((user) => {
        const userId = this.validation.outbox
          ? this.validation.outbox.user_id
          : this.validation.user_id;
        return user.id === userId;
      });
      this.type = this.validation.outbox ? 'advert' : 'post';
      this.hasValidationTagging = await this.compayService.hasFeatureAccess(
        'OUTBOX_VALIDATION_TAGGING'
      );
      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  ngOnChanges() {
    if (this.validated) {
      this.validationStatus = this.validated;
    }
  }

  public async validatePost(approved) {
    const validateRequest = {
      id: this.validation.id,
      account_id: this.validation.account_id,
      approved,
      seq: this.validation.seq,
      delete: false
    };
    let result;

    if (!approved) {
      const validationTags = await this.outboxTagsService.getValidationTags();
      this.disapprovalTags = validationTags.map((tag) => {
        return {
          label: tag.name,
          value: tag.name
        };
      });

      const modal = this.modal.open(ValidatePostsDissaproveComponent);
      modal.componentInstance.disapprovalTags = this.hasValidationTagging
        ? this.disapprovalTags
        : null;
      result = await modal.result;
      validateRequest['validator_note'] = result.reason;
      validateRequest['email_only'] = false;
      validateRequest['tags'] =
        result.disapprovalTags && result.disapprovalTags.map((t) => t.value);
    }
    if (result !== 'cancel') {
      await this.api
        .post('outbox_v2/validation', validateRequest)
        .then((res) => {
          if (res.status === 200) {
            // const { data } = res;
            // this.postValidated.emit(data);
            this.validationStatus = approved ? 'approved' : 'disapproved';
            this.validation['validated'] = this.validationStatus;

            if (this.postWithBoost) {
            }
          }
        });
    }
  }

  public async validateAdvert(approved) {
    const validateRequest = {
      approved
    };

    const id = this.postWithBoost ? this.postWithBoost.id : this.validation.id;
    let result;

    if (!approved) {
      const modal = this.modal.open(ValidatePostsDissaproveComponent);
      result = await modal.result;

      validateRequest['validator_note'] = result;
      validateRequest['email_only'] = false;
    }

    if (result !== 'cancel') {
      await this.api
        .post(
          `advertising/advertisingValidateAdvert?id=${id}`,
          validateRequest,
          {
            params: {
              _method: 'PATCH'
            }
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            // const { data } = res;
            // this.postValidated.emit(data);
            this.validationStatus = approved ? 'approved' : 'disapproved';
            this.validation['validated'] = this.validationStatus;
          }
        });
    }
  }

  public showNotes() {
    // This will open notes modal. Needs rewrite
    this.modal.open('');
  }

  getSelectedStatus() {
    return this.selected.includes(this.validation);
  }

  public toggleBoost() {
    this.showBoost = !this.showBoost;

    if (this.postWithBoost) {
      this.validationStatus = null;
      this.validation = this.postWithBoost;
    }
  }
}
