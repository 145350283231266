import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { filter } from 'rxjs/operators/filter';
import { PUBLISHER_ACTIVE } from '../../components/publisher/publisher-active';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { timer } from 'rxjs/observable/timer';

function combineHeights(collection: HTMLElement[]): number {
  return collection.reduce((count, elm) => elm.offsetHeight + count, 0);
}

function siblings(elm: HTMLElement): HTMLElement[] {
  return Array.from(elm.parentElement.children).filter(
    (sibling) => sibling !== elm
  ) as HTMLElement[];
}

export const TIMEOUT_DELAY = 1000;

@Directive({
  selector: '[ssiAutoHeight]',
  host: {
    '[class.auto-height]': 'true'
  }
})
export class AutoHeightDirective implements OnInit, OnDestroy {
  @Input() autoHeightDisabled: boolean;

  @Input() additionalHeight = 0;

  onDestroy = new Subject();

  constructor(
    private elm: ElementRef,
    private renderer: Renderer2,
    @Inject(PUBLISHER_ACTIVE) private publisherActive
  ) {}

  ngOnInit(): void {
    timer(TIMEOUT_DELAY)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.onResize());

    // force auto-height directive to resize when the publisher is closed
    this.publisherActive
      .pipe(
        filter(({ isActive }) => !isActive),
        takeUntil(this.onDestroy),
        mergeMap(() => timer(TIMEOUT_DELAY))
      )
      .subscribe(() => this.onResize());
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  refresh() {
    setTimeout(() => this.onResize());
  }

  @HostListener('window:resize')
  onResize() {
    if (this.autoHeightDisabled !== true) {
      const parentHeight =
        window.innerHeight -
        this.elm.nativeElement.parentElement.getBoundingClientRect().top;
      const siblingHeights = combineHeights(siblings(this.elm.nativeElement));
      this.renderer.setStyle(
        this.elm.nativeElement,
        'height',
        parentHeight - siblingHeights - this.additionalHeight + 'px'
      );
    }
  }
}
