import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import angular from 'angular';
import twitterText from 'twitter-text';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../popup/popup.service';

@Injectable()
export class TranslationService {
  constructor(
    private api: API,
    private translate: TranslateService,
    private popup: PopupService
  ) {}

  escapeEntities(str) {
    const escapedMentions = twitterText
      .autoLinkUsernamesOrLists(str)
      .replace(/@<a /g, '<a ');
    return twitterText
      .autoLinkHashtags(escapedMentions)
      .replace(/#<a /g, '<a ');
  }

  unescapeEntities(str) {
    const htmlTranslation: any = angular.element(`<span>${str}</span>`);
    Array.from(htmlTranslation.find('a')).forEach((a: any) => {
      const aElm = angular.element(a);
      if (aElm.hasClass('username')) {
        aElm.text('@' + aElm.attr('data-screen-name'));
      } else if (aElm.hasClass('hashtag')) {
        aElm.text(
          '#' +
            aElm.attr('href').replace('https://twitter.com/search?q=%23', '')
        );
      }
    });
    return htmlTranslation.text();
  }

  translateText({ text, target = this.translate.currentLang }) {
    return this.api
      .post('translation/text', {
        q: this.escapeEntities(text),
        target
      })
      .then(
        ({
          data: {
            data: { translations }
          }
        }: any) => {
          if (translations.length === 0) {
            this.popup.alert({
              title: this.translate.instant('TRANSLATION_FAILED'),
              message: this.translate.instant(
                'SORRY_BUT_WE_COULDNT_AUTOMATICALLY_TRANSLATE_THIS_MESSAGE'
              )
            });
            return Promise.reject('No translations detected');
          } else {
            return translations.map((translation) => {
              translation.translatedText = this.unescapeEntities(
                translation.translatedText
              );
              return translation;
            });
          }
        }
      );
  }
}
