<div class="engagement-analytics-report-container">
  <ssi-report-header
    report="Inbox Report"
    [dateRangesTemplate]="dateRangesRef"
    (dateSelected)="reportDateSelected($event)"
  ></ssi-report-header>
  <ssi-report-sidebar
    icon="ssi-analytics"
    color="#14BAE3"
    [navList]="navList"
    [actionList]="reportActions"
    [active]="activeNav"
    (navClicked)="scrollTo($event)"
  ></ssi-report-sidebar>

  <div class="report-body">
    <ng-container *ngIf="isLoading">
      <div class="loading-container">
        <!-- <i class="ssi ssi-confetti"></i> -->
        <img src="https://www.orlo.app/app/web/production/f44ceeab6551db9be89255d952d86c0d.gif">
        <h3>We're building your report!</h3>
        <p>This may take a few moments
        </p>
      </div>
    </ng-container>

    <div class="report-container" *ngIf="!isLoading">
      <section class="section-container">
        <ul class="account-list">
          <li *ngFor="let account of report.filter.accounts | unique: 'id' | sortBy: 'account_type_name'">
            <ssi-account-pill
              [icon]="account.socialNetwork.icon.web"
              [label]="account.displayName"
            ></ssi-account-pill>
          </li>
        </ul>
      </section>
  
      <div class="section-delimiter"></div>
      
      <section id="account-summary" class="section-container section-container-account-summary">
        <h2 class="section-title">Account Summary</h2>
        <ssi-account-summary
          [socialAccounts]="report.socialAccounts"
          [stats]="report.stats"
        ></ssi-account-summary>
      </section>
  
      <div class="section-delimiter"></div>
  
      <section id="inbox-activity" class="section-container section-container-inbox-activity">
        <h2 class="section-title">Inbox Activity</h2>
        <ssi-engagement-inbox-activity
          [messagesChart]="report.messagesChart"
          [activityStats]="report.stats.activity.period"
        ></ssi-engagement-inbox-activity>
      </section>
  
      <div class="section-delimiter"></div>
  
      <section id="inbox-sentiment" class="section-container section-container-inbox-sentiment">
        <h2 class="section-title">
          <span
            [ssiTooltip]="'THESE_FIGURES_SHOW_THE_SENTIMENT_OF_THE_INBOUND_PUBLIC_COMMENTS_OR_MENTIONS_AND_PRIVATE_OR_DIRECT_MESSAGES_RECEIVED_FOR_THE_REPORTED_TIME_PERIOD' | translate"
            tooltipWindowClass="tooltip-chunky-big"
          >
          Inbox Sentiment
        </span>
        </h2>
        <ssi-inbox-sentiment
          [socialAccounts]="report.socialAccounts"
          [stats]="report.stats"
      ></ssi-inbox-sentiment>
      </section>

      <div class="section-delimiter"></div>
  
      <section *ngIf="report.showEngagementTimesChart" id="engagement-times" class="section-container section-container-engagement-times">
        <h2 class="section-title">Engagement Times</h2>
        <ssi-engagement-times
          [stats]="report.busyHoursNormalised"
        ></ssi-engagement-times>
      </section>

      <div class="section-delimiter"></div>

      <ng-container *ngIf="report.stats.conversation.period.current?.sla.length > 0">
        <section id="conversation-slas" class="section-container section-container-conversation-slas">
          <h2 class="section-title">Conversation SLAs</h2>
          <div class="conversation-slas-section">
            <ul class="time-range-list">
              <li *ngFor="let sla of report.stats.conversation.period.current.sla; let i = index">
                <div class="time-range-list-item">
                  <div class="time-range-list-range">
                    <span
                      [innerHTML]="(sla.from || '0') | secondsToHumanTime:true:true:{secondLabel: 's', minuteLabel: 'm', dayLabel: 'd', hourLabel: 'h'}"
                    ></span>
                    <ng-container *ngIf="sla.to">
                      <span class="time-range-list-range-hyphen">-</span>
                      <span
                        [innerHTML]="(sla.to || '0') | secondsToHumanTime:true:true:{secondLabel: 's', minuteLabel: 'm', dayLabel: 'd', hourLabel: 'h'}">
                      </span>
                    </ng-container>
                    <ng-container *ngIf="!sla.to">&nbsp;+</ng-container>
                  </div>
                  <div class="time-range-list-progress">
                    <span
                      *ngIf="report.stats.conversation.period.previous?.sla && report.stats.conversation.period.previous?.sla[i].value"
                      [style.width.%]="sla.value * 100 / report.stats.conversation.period.previous?.sla[i].value"
                    ></span>
                  </div>
                  <div class="time-range-list-amount">
                    <p>{{sla.value === 1 ? (sla.value | bigNumber) + ' conversation' : (sla.value | bigNumber) + ' conversations'}}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
  
        <div class="section-delimiter"></div>
      </ng-container>
  
      <ng-container *ngIf="report.stats.conversation.period.current">
        <section id="sentiment-change" class="section-container section-container-sentiment-change">
          <h2 class="section-title">Conversation Sentiment Change</h2>
          <ssi-sentiment-change
            [totalMessages]="report.stats.activity.period.current.inbound.count"
            [totalConversations]="report.stats.conversation.period.current?.count_conversations"
            [chartConfig]="report.conversationSentimentChangeChart"
          ></ssi-sentiment-change>
        </section>
    
        <div class="section-delimiter"></div>
      </ng-container>
  
      <ng-container *ngIf="report.stats.activity.period.current.inbound.top_authors.length">
        <section id="top-engagers" class="section-container section-container-top-engagers">
          <h2 class="section-title">Top Engagers</h2>
          <ssi-top-engagers
            [topEngagers]="report.stats.activity.period.current.inbound.top_authors"
            [inboundCount]="report.stats.activity.period.current.inbound.count"
            [socialNetworks]="report.socialAccounts"
          >
          </ssi-top-engagers>
        </section>
      </ng-container>

      <ng-container *ngIf="report.stats.activity.period.current.inbound.count > 0">
        <div class="section-delimiter"></div>
    
        <section id="network-engagement" class="section-container section-container-network-engagement">
          <h2 class="section-title">Network Activity</h2>
          <ssi-network-engagement
            [sourcesData]="report.sourcesData"
            [languageData]="report.languageData"
          ></ssi-network-engagement>
        </section>
      </ng-container>
  
      <div class="section-delimiter"></div>
  
      <section id="tags" class="section-container section-container-tags">
        <h2 class="section-title">Tags</h2>
        <ssi-tags-table
          [tagData]="report.stats.activity.period.current.tag_report"
        ></ssi-tags-table>

        <ssi-top-tags
          [tags]="report.tags"
        ></ssi-top-tags>
      </section>

      <ng-container *ngIf="report.topUrls && report.topUrls.length > 0">
        <div class="section-delimiter"></div>
  
        <section id="top-urls" class="section-container section-container-top-urls">
          <h2 class="section-title">Top URLs promoting Live Chats</h2>
          <ssi-top-urls [topURLs]="report.topUrls"></ssi-top-urls>
        </section>
      </ng-container>
  
      <div class="section-delimiter"></div>
  
      <section id="response-times" class="section-container section-container-response-times">
        <h2 class="section-title">
          <span
            [ssiTooltip]="'THIS_TELLS_YOU_THE_AVERAGE_TIME_FOR_A_USER_TO_RECEIVE_A_REPLY_TO_ANY_INBOUND_MESSAGE' | translate"
            tooltipWindowClass="tooltip-chunky-big"
          >
            Account Response Times
          </span>
        </h2>
        <ssi-response-times
          [chartData]="report.responseTimesByAccountTypeChart"
          [socialAccounts]="report.socialAccounts"
          [activityStats]="report.stats.activity.period"
        ></ssi-response-times>
      </section>
  
      <div class="section-delimiter"></div>

      <section id="average-response-time" class="section-container section-container-average-response-time">
        <h2 class="section-title">Average First Response Time</h2>
        <ssi-average-response-time
          [stats]="report.avgResponseTimesData"
        ></ssi-average-response-time>
      </section>

      <div class="section-delimiter"></div>
  
      <section id="team-performance" class="section-container section-container-team-performance">
        <h2 class="section-title">Team Performance</h2>
        <ssi-team-performance
          [teamData]="report.performance.teams"
        ></ssi-team-performance>
      </section>
  
      <div class="section-delimiter"></div>
  
      <section id="user-performance" class="section-container section-container-user-performance">
        <h2 class="section-title">User Performance</h2>
        <ssi-user-performance
          [userData]="report.performance.users"
          (usersFiltered)="setXlsxExport(report, $event)"
        ></ssi-user-performance>
      </section>

      <ng-container *ngIf="report.stats.activity.period.current?.inbound.response_time_sla">
  
        <div class="section-delimiter"></div>

        <section id="response-time-slas" class="section-container section-container-conversation-slas">
          <h2 class="section-title">Response Time SLAs</h2>
          <ssi-response-time-sla
            [slaData]="report.stats.activity.period.current.inbound.response_time_sla"
            [slaDataPrevious]="report.stats.activity.period.previous?.inbound.response_time_sla"
          ></ssi-response-time-sla>
        </section>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #dateRangesRef>
  <div class="selected-date-ranges">
    <span class="current">{{report.filter.range.start | date: 'dd MMM yy'}} - {{report.filter.range.end | date: 'dd MMM yy'}}</span>
    <span class="previous">vs</span>
    <span class="previous">{{report.filter.range.compareTo.start | date: 'dd MMM yy'}} - {{report.filter.range.compareTo.end | date: 'dd MMM yy'}}</span>
  </div>
</ng-template>
