import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { WidgetRssFeedsComponent } from './widget-rss-feeds.component';
import { DropdownSelect2Module } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../../../../common/components/social-network-icon/social-network-icon.module';
import { TemplateSelectorModule } from '../../../../../../../common/directives/template-selector/template-selector.module';
import { VirtualScrollModule } from '../../../../../../../common/components/virtual-scroll/virtual-scroll.module';
import { NoResultsModule } from '../../../../../../../common/components/no-results/no-results.module';
import { RssFeedArticleModule } from '../../../../../marketing/rss-feeds/rss-feed-article/rss-feed-article.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    InfiniteScrollModule,
    RssFeedArticleModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    TemplateSelectorModule,
    VirtualScrollModule,
    NoResultsModule
  ],
  exports: [WidgetRssFeedsComponent],
  declarations: [WidgetRssFeedsComponent]
})
export class WidgetRssFeedsModule {}
