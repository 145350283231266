import { ModuleWithProviders, NgModule } from '@angular/core';
import { IftttServiceModel } from './ifttt-service-model/ifttt-service-model';
import { IftttTriggerActionParams } from './ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [TranslateModule]
})
export class IftttUtilsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IftttUtilsModule,
      providers: [IftttTriggerActionParams, IftttServiceModel]
    };
  }
}
