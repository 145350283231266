<div class="conversation-hold-container">
  <ssi-dropdown-select-2 size="sm"
                         [options]="holdOptionsIterable"
                         [yPlacement]="menuPlacementY"
                         [xPlacement]="menuPlacementX"
                         [(ngModel)]="selectedHoldOption"
                         (ngModelChange)="onHoldOptionChange($event)">
    <ng-template ssiTemplateSelector="headButton"
                 let-expanded="expanded"
                 let-toggleHoldOptions="toggle">

      <button *ngIf="!iconOnly; else iconOnlyTemplate"
              type="button"
              class="g-btn border-radius-100 hold-btn"
              (click)="toggleHoldOptions()"
              [ssiTooltip]="'On Hold conversation'"
              [tooltipAppendToBody]="true"
              [tooltipWindowClass]="'tooltip-chunky'">
        <i class="ssi ssi-on-hold"></i>
        <span>{{canReply ? 'Reply + Hold' : 'On Hold'}}</span>
      </button>
    </ng-template>
  </ssi-dropdown-select-2>

  <ng-template #iconOnlyTemplate>
    <span (click)="toggleHoldOptions()">
      <i
        class="ssi ssi-on-hold hold-icon-standalone"
        [ssiTooltip]="'On Hold conversation'"
        [tooltipAppendToBody]="true"
        [tooltipWindowClass]="'tooltip-chunky'"
        tooltipPlacement="bottom"
      ></i>
      <span class="on-hold-label" *ngIf="iconOnlyLabel">On hold conversation</span>
    </span>
  </ng-template>
</div>