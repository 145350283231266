import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojiTextDirective } from './emoji-text.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [EmojiTextDirective],
  exports: [EmojiTextDirective]
})
export class EmojiTextModule {}
