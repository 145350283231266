<div class="manage-ifttt-applet-name manage-ifttt-applet-stage">

  <div>
    <div class="manage-ifttt-applet-stage-header">
      <h2 class="manage-ifttt-applet-stage-header-title">{{ service.translationIds.title | translate }}</h2>
    </div>

    <form novalidate class="manage-ifttt-applet-stage-body" #nameForm="ngForm">

      <div class="manage-ifttt-applet-stage-body-inner">

        <a href="javascript:;" class="accounts-label" uiSref="^.accounts" *ngIf="!applet.streams.length">
          <span class="accounts-amount">{{ applet.accounts.length }} accounts</span> <i class="ssi ssi-pencil"></i>
        </a>

        <a href="javascript:;" class="accounts-label" uiSref="^.streams" *ngIf="applet.streams.length">
          <span class="accounts-amount">{{ applet.streams.length }} streams</span> <i class="ssi ssi-pencil"></i>
        </a>

        <br>

        <input
          type="text"
          class="form-control form-control-minimal spacer-top text-center form-control-text-lg font-weight-normal"
          placeholder="Name your trigger"
          [(ngModel)]="applet.name"
          name="serviceName"
          required
          #nameInput
          autocomplete="off">
        <br>

        <div class="text-center">
          <button
            class="btn btn-primary btn-stage-next"
            [disabled]="!nameForm.valid"
            uiSref="^.triggersAndActions">
            Save
          </button>
        </div>
      </div>

    </form>
  </div>

</div>