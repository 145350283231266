import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AccountSelectorComponent } from './account-selector.component';
import { SocialNetworkIconModule } from '../../social-network-icon/social-network-icon.module';
import { DropdownSelect2Module } from '../../dropdown-select-2/dropdown-select-2.module';
import { ObserveWidthAndHeightModule } from '../../../directives';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    ObserveWidthAndHeightModule
  ],
  declarations: [AccountSelectorComponent],
  exports: [AccountSelectorComponent]
})
export class AccountSelectorModule {}
