<div class="modal-header">
  <h3 class="modal-title no-wrap">
    <span translate="REMOVE_ACCOUNT__ACCOUNTNAME_" [translateParams]="{accountName: account.displayName}"></span>
    <span *ngIf="account">({{ account.accountTypeLabel }})</span>
  </h3>
</div>
<div class="modal-body">
  <div
    class="loading"
    *ngIf="loadingTracker.active">
  </div>
  <form
    novalidate
    #submitForm="ngForm"
    [hidden]="loadingTracker.active">

    <div *ngIf="account">

      <br>

      <p
        class="text-danger"
        [innerHtml]="'STRONGWARNINGSTRONG_REMOVING_A_SOCIAL_ACCOUNT_IS_NOT_REVERSIBLE_ONCE_DELETED_THE_ACCOUNT_STATISTICS_INBOX_DATA_AND_ANY_META_DATA_WILL_BE_REMOVED_FROM_OUR_SYSTEM' | translate">
      </p>

      <h3>
      <span
        translate="TYPE_REMOVE_TO_REMOVE_THE_SOCIAL_ACCOUNT__ACCOUNTNAME_"
        [translateParams]="{accountName: account.displayName}">
      </span> ({{ account.accountTypeLabel }})
      </h3>

      <input
        class="form-control input-lg"
        type="text"
        name="confirmationText"
        placeholder="{{ 'TYPE_REMOVE_TO_REMOVE_THE_ACCOUNT' | translate }}"
        [(ngModel)]="confirmationText"
        required
        pattern="^REMOVE$">

      <hr>

      <div class="text-center">
        <button
          class="btn btn-danger btn-lg text-uppercase"
          [disabled]="!submitForm.valid"
          (click)="removeAccount()"
          translate="REMOVE_ACCOUNT">
        </button>
      </div>

    </div>

  </form>
</div>
<div class="modal-footer text-right">
  <button class="btn btn-default" (click)="activeModal.dismiss()">
    <i class="fa fa-times"></i> <span translate="CANCEL"></span>
  </button>
</div>
