<div class="drafts-library-wrap">

  <div class="drafts-library-header">
    <h1>Drafts Library</h1>
    <button type="button"
            class="create-draft-btn"
            [ssiTriggerPublish]>
      <i class="ssi ssi-plus"></i><span>New Draft</span>
    </button>
  </div>

  <div class="drafts-library-container">
    <ssi-alerts></ssi-alerts>

    <div class="drafts-library-nav">
      <p class="drafts-library-nav-item"
         [ngClass]="{'drafts-library-nav-item-active': selectedDraftType === draftType.Private}"
         (click)="onFilterTypeChange(draftType.Private)">My Drafts</p>
      <p class="drafts-library-nav-item"
         [ngClass]="{'drafts-library-nav-item-active': selectedDraftType === draftType.Public}"
         (click)="onFilterTypeChange(draftType.Public)">Public Drafts</p>
    </div>

    <div class="drafts-library-body"
         ssiLoader
         ssiLoaderVariant="transparent"
         ssiLoaderText=""
         [ssiLoaderVisible]="loading">
      <div class="drafts-library-actions">
        <!-- sort, search, filter -->
      </div>

      <div class="drafts-library-list">
        <ng-container *ngIf="currentPageResponse?.total === 0 && !loading && !filters">
          <div [style.max-width.px]="205"
               class="drafts-library-list-empty">
            <h2>Make your first draft</h2>
            <p>Create drafts for collaboration or for you to work on later</p>
            <button type="button"
                    [ssiTriggerPublish]><span>New Draft</span></button>
          </div>
        </ng-container>

        <ng-container *ngIf="currentPageResponse?.total === 0 && !loading && filters">
          <div class="drafts-library-list-empty">
            <h2>No results</h2>
            <p>Maybe widen your keyword search or filters?</p>
            <button>Clear filters</button>
          </div>
        </ng-container>

        <ng-container *ngIf="currentPageResponse?.total > 0">
          <ssi-draft *ngFor="let draft of currentPageResponse?.drafts; trackBy:trackById; let index = index"
                     [draft]="draft"
                     [missingAccountAccess]="draft.missingAccountAccess"
                     [showDelete]="selectedDraftType === draftType.Private || authUser?.hasCompanyPermission('manage_public_drafts')"
                     (onDeleteDraft)="deleteDraft($event)"
                     (onShowDraftNotes)="showDraftNotes($event)"></ssi-draft>
        </ng-container>

        <!-- paginator should always be rendered, just hidden when no results - so it can be referenced in the component -->
        <ssi-paginator [hidden]="!currentPageResponse || currentPageResponse.total === 0"
                       [itemsCount]="currentPageResponse?.total"
                       [pageLinksSize]="5"
                       [itemsPerPage]="itemsPerPage"
                       [autoInit]="false"
                       [reInitOnChanges]="false"
                       (onPageChange)="onPageChange($event)"></ssi-paginator>
      </div>
    </div>
  </div>
</div>