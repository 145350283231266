import './top-tags.component.scss';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-top-tags',
  templateUrl: './top-tags.component.html',
  styles: []
})
export class TopTagsComponent implements OnInit {
  @Input() tags: {
    value: string;
    amount: number;
  }[];

  topTagCount = 0;

  constructor() {}

  ngOnInit(): void {
    for (const tag of this.tags) {
      this.topTagCount += tag.amount;
    }
  }
}
