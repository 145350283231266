<article class="asc">
  <div class="asc-left">
    <div class="asc-thumb">
      <i [ngClass]="accountIcon"></i>
    </div>
  </div>
  <div class="asc-right">
    <p class="asc-title" [innerHTML]="accountTitle"></p>
    <p class="asc-meta">{{accountAmount}} {{accountAmount === '1' ? 'Account' : 'Accounts'}}</p>
    <ul class="asc-stats">
      <li *ngIf="conversationAmount" class="asc-stat">
        <p class="asc-stat-data" [innerHTML]="conversationAmount"></p>
        <p class="asc-stat-label">Conversations</p>
      </li>
      <li class="asc-stat">
        <p class="asc-stat-data" [innerHTML]="messageAmount || '0'"></p>
        <p class="asc-stat-label">Messages</p>
      </li>
      <li class="asc-stat" *ngIf="postAmount">
        <p class="asc-stat-data">
          <ssi-big-number [number]="postAmount"></ssi-big-number>
        </p>
        <p class="asc-stat-label">Posts</p>
      </li>
    </ul>
  </div>
</article>

