export const COLORS = Object.freeze({
  'primary-active': '#14bae3',
  'secondary-active': '#434552',
  'primary-red': '#fff1ed',
  'secondary-red': '#f88c68',
  'grey-background': '#f8f9fd',
  'mgrey-background': '#f0f3f9',
  'dgrey-background': '#e7ecf8',
  'lmode-title-text': '#101525',
  'lmode-body-text': '#43537f',
  'lmode-helper-text': '#838eab',
  'lmode-disabled': '#cfd3de',
  'lmode-border-color': '#ecf0fb'
});
