<form novalidate
      #tagsInputForm="ngForm"
      (submit)="addTag(tagsInputForm)"
      [class.has-error]="!tagsInputForm.valid && tagsInputForm.submitted">
  <input autocomplete="off"
         class="form-control form-control-minimal"
         name="newTagValue"
         type="text"
         (paste)="onPaste($event)"
         [(ngModel)]="newTagValue"
         [pattern]="allowedTagsPattern"
         [placeholder]="placeholder"
         [minlength]="minLength"
         [ngbTypeahead]="typeaheadSearch">
</form>

<ng-container *ngTemplateOutlet="belowInputContent"></ng-container>

<ul>
  <li class="tag"
      *ngFor="let tag of modelValue">
    <span>{{ tag }}</span>
    <button (click)="removeTag(tag)"
            type="button">
      <i class="fa fa-times"></i>
    </button>
  </li>
</ul>