<div class="post-modal">
  <div class="post-modal-header">
    <div class="post-modal-header-left">
      <h2>{{title}}</h2>
    </div>
    <div class="post-modal-header-right">
      <button (click)="activeModal.close()" class="post-modal-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="post-modal-body custom-scrollbar-2">
    <ssi-outbox-post-new
      [post]="post"
      [account]="account"
      [authUser]="authUser"
      (closeModal)="activeModal.close()"
      (onDeletedPost)="title = PostModalTitle.Deleted"
    ></ssi-outbox-post-new>
    
    <div class="stat-grid">
      <ng-container *ngFor="let stat of stats">
        <div *ngIf="!stat.hidden" class="stat-grid-item">
          <ssi-outbox-post-new-stat
            [statLabel]="stat.label"
            [statFigure]="stat.figure"
            [dividerColor]="stat.dividerColor"
          ></ssi-outbox-post-new-stat>
        </div>
      </ng-container>
    </div>

    <div class="post-modal-notes">
      <ssi-outbox-post-new-notes
        [notes]="notes"
        [postID]="post.id"
        [authUser]="authUser"
        [disablePosting]="disableNotePosting"
        (updateNotes)="updateNotes($event)"
      ></ssi-outbox-post-new-notes>
    </div>
  </div>

  <div *ngIf="!post.is_validated && !post.deleted_at" class="post-modal-footer">
    <button
      (click)="disapprovePost()"
      class="post-modal-action post-modal-action-warning post-modal-action-iconless"
    >
      <span>Disapprove Post</span>
    </button>
    <button
      (click)="approvePost()"
      class="post-modal-action post-modal-action-primary post-modal-action-iconless"
    >
      <span>Approve Post</span>
    </button>
  </div>

  <div *ngIf="post.last_attempt_at && !post.social_id" class="post-modal-footer">
    <button (click)="retryFailedPost(post.id)" class="post-modal-action">
      <i class="ssi ssi-refresh-to-check"></i>
      <span>Retry Post</span>
    </button>
  </div>
</div>

<ng-template #retrySnackbarTemplate let-dismiss="dismiss">
  <div class="snackbar-container">
    <i class="ssi ssi-completed-notification"></i>
    <p>Post resubmitted for publishing</p>
    <button (click)="dismiss()"><i class="ssi ssi-times"></i></button>
  </div>
</ng-template>

<ng-template #disapprovedSnackbarTemplate let-dismiss="dismiss">
  <div class="snackbar-container">
    <i class="ssi ssi-completed-notification"></i>
    <p>Post disapproved</p>
    <button (click)="dismiss()"><i class="ssi ssi-times"></i></button>
  </div>
</ng-template>

<ng-template #approvedSnackbarTemplate let-dismiss="dismiss">
  <div class="snackbar-container">
    <i class="ssi ssi-completed-notification"></i>
    <p>Post approved</p>
    <button (click)="dismiss()"><i class="ssi ssi-times"></i></button>
  </div>
</ng-template>