import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { IconModule } from '../../../../../../common/components/icon/icon.module';

import { WidgetTopInterestsIndustriesComponent } from './widget-top-interests-industries.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownSelect2Module } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { LoaderModule } from '../../../../../../common/directives';
import { BigNumberModule } from '../../../../../../common/components/big-number/big-number.module';
import { FallbackImageModule } from '../../../../../../common/directives/fallback-image/fallback-image.module';

@NgModule({
  declarations: [WidgetTopInterestsIndustriesComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    TooltipModule,
    IconModule,
    TranslateModule,
    FormsModule,
    TooltipModule,
    OverlayModule,
    DropdownSelect2Module,
    LoaderModule,
    BigNumberModule,
    FallbackImageModule
  ],
  providers: [],
  exports: [WidgetTopInterestsIndustriesComponent]
})
export class WidgetTopInterestsIndustriesModule {}
