import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownSelectComponent } from './dropdown-select.component';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { FocusOnModule } from '../../directives/focus-on/focus-on.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    OffClickModule,
    FocusOnModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [DropdownSelectComponent],
  exports: [DropdownSelectComponent]
})
export class DropdownSelectModule {}
