import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarComponent } from './snackbar.component';

export interface SnackbarOptions {
  duration?: number;
  position?: 'top' | 'bottom';
  snackbarClass?: string;
  icon?: string;
}

@Injectable()
export class SnackbarService {
  constructor(private ngbModal: NgbModal) {}

  open(content: any, options?: SnackbarOptions) {
    const snackbar = this.ngbModal.open(SnackbarComponent, {
      backdrop: false,
      windowClass: `
        snackbar-window
        ${
          options.position
            ? 'snackbar-window-' + options.position
            : 'snackbar-window-bottom'
        }
        ${options.snackbarClass}
      `
    });
    snackbar.componentInstance.content = content;
    snackbar.componentInstance.close.subscribe(() => {
      snackbar.dismiss();
    });
    if (options.duration) {
      setTimeout(() => {
        snackbar.dismiss();
      }, options.duration * 1000);
    }
  }
}
